import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function BulletedList({ color, size }: Props) {
  return (
    <Icon color={color} size={size}>
      <circle cx={4} cy={4} r={2} />
      <rect x={8} y={3} width={10} height={2} />
      <circle cx={4} cy={10} r={2} />
      <rect x={8} y={9} width={10} height={2} />
      <circle cx={4} cy={16} r={2} height={2} />
      <rect x={8} y={15} width={10} height={2} />
    </Icon>
  );
}
