import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FolderMove = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.081 2.834c.174.25.46.399.764.399h5.108a2.05 2.05 0 0 1 1.448.599c.383.384.599.905.599 1.447v4.556a11.25 11.25 0 0 0-.548-.602l-.476-.479c-.166-.166-.335-.336-.513-.517l-.157-.16-.005-.005a2.6 2.6 0 0 0-3.696-.002 2.613 2.613 0 0 0-.758 1.927h-.641A2.611 2.611 0 0 0 6.6 12.613a2.62 2.62 0 0 0 1.647 2.434h-6.2a2.05 2.05 0 0 1-1.448-.6A2.05 2.05 0 0 1 0 13V3.047C0 1.917.916 1 2.047 1h3.688c.669 0 1.296.327 1.679.877l.667.957Z"
    />
    <path d="M11.499 9.84c0 .242.09.482.273.666l.98.99H9.067a.937.937 0 0 0-.932.94c0 .52.417.942.932.942h3.685l-.955.964a.944.944 0 0 0 0 1.33.925.925 0 0 0 1.318 0l.203-.204c.165-.167.331-.332.495-.5l.639-.646c.21-.21.416-.429.63-.638.206-.203.408-.41.602-.626a.916.916 0 0 0 .144-1.055c-.117-.21-.31-.377-.476-.546-.202-.207-.408-.41-.613-.616-.244-.245-.486-.492-.728-.738-.207-.206-.413-.414-.619-.621l-.297-.301-.005-.005a.926.926 0 0 0-1.318 0 .938.938 0 0 0-.273.665Z" />
  </Icon>
);
FolderMove.displayName = 'FolderMove';

export default FolderMove;
