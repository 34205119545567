import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CaretTop = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={12}
    viewBoxHeight={12}
  >
    <path d="m1.637 7.944 3.699-4.645c.247-.31.745-.392 1.112-.183.085.049.158.11.216.183l3.7 4.645c.246.31.15.731-.217.94A.907.907 0 0 1 9.699 9H2.301c-.443 0-.801-.303-.801-.678 0-.135.048-.267.137-.378Z" />
  </Icon>
);

CaretTop.displayName = 'CaretTop';

export default CaretTop;
