import {
  Asterisk,
  FieldWrapper,
  Label,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import {
  ErrorMessage,
  UnauthorisedTaskAssigneesError,
} from 'ms-pages/Teacher/components/EditTaskFlyout';

import type { CombinedState } from '.';

type Props = {
  state: CombinedState;
  errorKeys: string[];
};

export default function CustomTaskDetailsForm({
  state: { values, updaters },
  errorKeys,
}: Props) {
  const {
    taskStartDate,
    taskDueDate,
    selectedClasses,
    selectedStudents,
    excludedStudents,
  } = values;
  const {
    updateTaskStartDate,
    updateTaskDueDate,
    setAssignedClasses,
    setAssignedStudents,
    setExcludedStudents,
  } = updaters;

  return (
    <TaskCreationFormWrapper>
      <UnauthorisedTaskAssigneesError errorKeys={errorKeys} />
      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        {/* It is possible to edit a task after the due date has passed.
            We don't want to suprise the use by automatically changing the due date
            but we need to communicate the invalid state.
         */}
        {values.taskDueDate <= new Date() && (
          <ErrorMessage>Due date cannot be in the past.</ErrorMessage>
        )}
        <TaskDatePicker
          value={[taskStartDate, taskDueDate]}
          onChange={([startDate, endDate]) => {
            if (startDate != null) updateTaskStartDate(startDate);
            if (endDate != null) updateTaskDueDate(endDate);
          }}
          errorKeys={errorKeys}
        />
      </FieldWrapper>

      <FieldWrapper>
        <TaskAssignment
          selectedClasses={selectedClasses}
          selectedStudents={selectedStudents}
          excludedStudents={excludedStudents}
          onChangeClasses={setAssignedClasses}
          onChangeStudents={setAssignedStudents}
          onChangeExcludedStudents={setExcludedStudents}
        />
      </FieldWrapper>
    </TaskCreationFormWrapper>
  );
}
