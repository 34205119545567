import Histogram from 'ms-components/math/Histogram';
import type {
  CategoryData,
  IncrementData,
  LabelData,
} from 'ms-components/math/Histogram';

type Props = {
  value?: CategoryData;
  size?: number;
  padding?: number;
  labels?: LabelData;
  increment?: IncrementData;
  doesDrawBars?: boolean;
  doesDrawLines?: boolean;
  hasGapBetweenBars?: boolean;
  dragHandleAlignment?: 'center' | 'right';
};

const HistogramReadOnly = (props: Props) => <Histogram {...props} readOnly />;

export default HistogramReadOnly;
