import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Bell = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={394}
    viewBoxHeight={422}
  >
    <path
      fillRule="evenodd"
      d="M255.793 352.109h89.398c26.582 0 48.129-21.511 48.129-48.047v-.484a48.55 48.55 0 00-17.5-37.316l-9.293-7.735a93.571 93.571 0 01-33.726-71.914v-51.429C332.801 60.68 272.305.285 197.684.285 123.059.285 62.566 60.68 62.566 135.184v50.496c0 28.312-12.836 55.105-34.91 72.879l-9.543 7.687A48.556 48.556 0 000 304.062c0 26.536 21.547 48.047 48.129 48.047h89.398a60.272 60.272 0 00-.836 10.02c0 33.066 26.848 59.871 59.969 59.871s59.969-26.805 59.969-59.871c0-3.414-.286-6.762-.836-10.02z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Bell;
