import { map, pipe, toPairs, reject, fromPairs } from 'ramda';
import { createElement, type ReactNode } from 'react';

import {
  deserializeProbabilityTree,
  deserializeHistogram,
  deserializeCoordinateValues,
  mapValueRecursive,
  fromOldNumberLineValue,
  pointToSegment,
} from 'ms-components/math/private-shared/legacyTransformers';
import convertElementStyleToStyleObject from 'ms-helpers/style/convertElementStyleToStyleObject';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';
import { unwrap, assertUnreachable } from 'ms-utils/typescript-utils';

// Each node in a MathContent tree that represents a serialization of a
// rich math content type (basically anything that maps to a React component,
// as opposed to html nodes for layout/style purposes) should have a type.
export type MathContentType =
  // This first group are types that can be dynamic (which currently corresponds
  // to the fact that they can be used to input answers on the work page)
  | 'PROBABILITY_TREE_STATIC'
  | 'HISTOGRAM_STATIC'
  | 'NUMBER_LINE_STATIC'
  | 'NUMBER_BUILDER_STATIC'
  | 'BOX_PLOT_STATIC'
  | 'LATEX_INPUT_STATIC_OR_DYNAMIC'
  | 'GRAPHPLOT_STATIC'
  // This second group are types that are always static
  | 'LATEX_EXPRESSION'
  | 'GEOGEBRA_EMBED'
  | 'VIDEO_EMBED'
  | 'PROBLEM_PREVIEW'
  | 'VIDEO_LAUNCHER'
  | 'CHAPTER_LINK'
  | 'HINT_CONTEXT'
  | 'IMAGE'
  | 'TRANSLATED_MULTIPLE_CHOICE_OPTION';
export type NodeRenderer = (props: any) => ReactNode;
export type NodeRendererMap = Record<MathContentType, NodeRenderer>;

const capitalizeWord = (str: string) =>
  str.length > 0 ? unwrap(str[0]).toUpperCase() + str.slice(1) : str;

const kebabToCamel = (str: string) =>
  str
    .split('-')
    .map((segment, i) => (i > 0 ? capitalizeWord(segment) : segment))
    .join('');

// Element attributes are a NamedNodeMap which are annoying to work with.
// This converts one into a plain object.
const attributes = (element: Element) =>
  Array.from(element.attributes).reduce(
    // eslint-disable-next-line
    (acc, { name, value }) => ((acc[name] = value), acc),
    {} as Record<string, string>,
  );

// Taken from kangax html minifier thread:
// https://github.com/kangax/html-minifier/issues/63#issuecomment-37772698
const isBooleanAttribute = (attrName: string) =>
  /^(?:allowfullscreen|async|autofocus|autoplay|checked|compact|controls|declare|default|defaultchecked|defaultmuted|defaultselected|defer|disabled|draggable|enabled|formnovalidate|hidden|indeterminate|inert|ismap|itemscope|loop|multiple|muted|nohref|noresize|noshade|novalidate|nowrap|open|pauseonexit|readonly|required|reversed|scoped|seamless|selected|sortable|spellcheck|translate|truespeed|typemustmatch|visible)$/.test(
    attrName,
  );

// given a CSSStyleDeclaration (produced by node.style)
// produces a plain object with the inline styles set for the
// node as key-value pairs. Importantly we turn this into a shape
// that React can use in its inline styles prop.
// TODO vendor prefixes arent going to be handled correctly right now.
// WebkitBlah - pascal case
// msBlah - camel case
const inlineStyleObject = (
  style: CSSStyleDeclaration,
): { [styleProperty: string]: string } =>
  Array.from(style).reduce(
    (acc, styleName) => ({
      ...acc,
      // @ts-expect-error CSSStyleDeclaration type doesn't allow string indexing
      [kebabToCamel(styleName)]: style[styleName],
    }),
    {},
  );

// React requires all props (other than data-* and aria-*) to be camelCase.
const shouldCamelCaseProp = (prop: string) => {
  const prefix = prop.toLowerCase().slice(0, 5);
  return prefix !== 'data-' && prefix !== 'aria-';
};

// See line blah of the source code.
const isReservedReactProp = ([propName]: [string]) =>
  propName === 'key' || propName === 'ref';

// We need to turn boolean attribute values into actual booleans for React
// See here for the semantics of boolean attributes in HTML:
// https://www.w3.org/TR/html5/infrastructure.html#boolean-attributes
const booleanAttributeAsBooleanValue = ([propName, propValue]: [
  string,
  string,
]): [string, string | boolean] => [
  propName,
  isBooleanAttribute(propName)
    ? propValue === '' || propValue.toLowerCase() === propName.toLowerCase()
    : propValue,
];

const reactifyPropName = ([propName, propValue]: [
  string,
  string | boolean,
]) => {
  // 'class' -> 'className'
  if (propName === 'class') return ['className', propValue];

  // 'for' -> 'htmlFor'
  if (propName === 'for') return ['htmlFor', propValue];

  // 'value' -> 'defaultValue'
  // 'checked' -> 'defaultChecked'
  // In React the semantics of `value` and `checked` props diverges from
  // HTML. To get the same semantics we must use defaultValue/defaultChecked
  if (propName === 'value' || propName === 'checked')
    return [`default${capitalizeWord(propName)}`, propValue];

  return [
    shouldCamelCaseProp(propName) ? kebabToCamel(propName) : propName,
    propValue,
  ];
};

const reactifyPropValue =
  (node: Element) =>
  ([propName, propValue]: [string, string | boolean]) => [
    propName,
    propName === 'style' && node instanceof HTMLElement
      ? inlineStyleObject(node.style)
      : propValue,
  ];

// Converts the attributes of a Node into something that is consumable as props
// in a React element.
const attributesToReactProps = (
  node: Element,
): Record<string, string | boolean> =>
  // @ts-expect-error Ramda + TS is no fun
  pipe(
    attributes,
    toPairs,
    // @ts-expect-error Ramda + TS is no fun
    reject(isReservedReactProp),
    map(booleanAttributeAsBooleanValue),
    map(reactifyPropName),
    map(reactifyPropValue(node)),
    fromPairs,
  )(node);

// TODO we should upstream this transform to the Soup layer.
export const formatAspectRatio = (ratio: string) => ratio.replace('-', ':'); // 4-3 -> 4:3

// Note that we indicate that a node does not have a math content type by
// producing undefined. Typical examples are html elements for layout/style.
const getMathContentType = (node: Node): MathContentType | undefined => {
  // Math content types are always serialized as HTMLElements
  if (!(node instanceof HTMLElement)) return undefined;

  const { classList } = node;

  if (classList.contains('probtree-instance')) return 'PROBABILITY_TREE_STATIC';
  if (classList.contains('histograminstance')) return 'HISTOGRAM_STATIC';
  if (classList.contains('numberlineinstance')) return 'NUMBER_LINE_STATIC';
  if (classList.contains('numberbuilderinstance'))
    return 'NUMBER_BUILDER_STATIC';
  if (classList.contains('boxplot-instance')) return 'BOX_PLOT_STATIC';
  if (node.nodeName === 'MC-ANSWER') return 'LATEX_INPUT_STATIC_OR_DYNAMIC';
  if (classList.contains('graphplot-instance')) return 'GRAPHPLOT_STATIC';
  if (classList.contains('mathquill-embedded-latex')) return 'LATEX_EXPRESSION';
  if (classList.contains('js-geogebra-player')) return 'GEOGEBRA_EMBED';
  if (
    classList.contains('js-wistia-container') ||
    classList.contains('js-youtube-player') ||
    classList.contains('js-bunny-container')
  )
    return 'VIDEO_EMBED';
  if (node.nodeName === 'PROBLEM') return 'PROBLEM_PREVIEW';
  if (classList.contains('js-movie-play')) return 'VIDEO_LAUNCHER';
  if (node.nodeName === 'CHAPTERLINK') return 'CHAPTER_LINK';
  if (classList.contains('hint-context')) return 'HINT_CONTEXT';
  if (node.nodeName === 'IMG') return 'IMAGE';
  if (node.nodeName === 'TRANSLATED-MULTIPLE-CHOICE-OPTION')
    return 'TRANSLATED_MULTIPLE_CHOICE_OPTION';

  return undefined;
};

// Produces the props for the math content type if the serialized content was
// well-formed. Otherwise produces an error describing how the content was
// malformed. There is no meaningful data integrity on the server, so we have
// to treat it as a hostile data source.
const getProps = (
  mathContentType: MathContentType,
  node: HTMLElement,
): Object | InvariantViolation => {
  // TODO add a math content element validator for each of types. If the
  // validation fails, then we error out. We can't trust the server, and we
  // don't actually have our data invariants specified anywhere, so some type
  // of data schema would be a nice improvement.
  switch (mathContentType) {
    case 'PROBABILITY_TREE_STATIC':
      return {
        // TODO I really dislike that there is that insane string being passed
        // as the second argument. That function needs a refactor.
        value: deserializeProbabilityTree(
          node.getAttribute('data') || '["",[]]',
        ),
        // Using ! (not unwrap) as this stuff looks fragile
        rowHeight: parseInt(node.getAttribute('yDist')!, 10),
        padding: parseInt(node.getAttribute('xOffset')!, 10),
      };
    case 'HISTOGRAM_STATIC': {
      // Using ! (not unwrap) as this stuff looks fragile
      const datum = JSON.parse(node.dataset.datum!);
      const { state } = datum;
      return {
        value: deserializeHistogram(state.value),
        labels: {
          main: state.charttitle,
          xAxis: state.xaxistitle,
          yAxis: state.yaxistitle,
        },
        increment: {
          min: state.ymin,
          max: state.ymax,
          step: state.yincrement,
          // TODO this data is not provided from the server stupidly enough.
          // The better way to solve this though is a default value in the
          // Histogram component when no tick value is provided.
          tick: 5,
        },
        hasGapBetweenBars: state.hasgapbetweenbars,
        doesDrawBars: state.bargraph,
        doesDrawLines: state.linegraph,
        dragHandleAlignment: state.edgemode ? 'right' : 'center',
      };
    }
    case 'NUMBER_LINE_STATIC': {
      // Using ! (not unwrap) as this stuff looks fragile
      const datum = JSON.parse(node.dataset.datum!);
      const value = pipe(
        map(element => mapValueRecursive(element, deserializeCoordinateValues)),
        map(pointToSegment),
        fromOldNumberLineValue,
      )(datum.value);
      return {
        value,
        mode: datum.mode,
        start: deserializeCoordinateValues(datum.start),
        unit: datum.unit,
        end: deserializeCoordinateValues(datum.end),
        majorTicks: deserializeCoordinateValues(datum.majorTicks),
        minorTicks: deserializeCoordinateValues(datum.minorTicks),
      };
    }
    case 'NUMBER_BUILDER_STATIC': {
      // Using ! (not unwrap) as this stuff looks fragile
      const datum = JSON.parse(node.dataset.datum!);
      return {
        dropZones: datum.dropZones,
        itemType: datum.itemType,
        value: datum.value,
      };
    }
    case 'BOX_PLOT_STATIC': {
      const rawData = node.getAttribute('boxplotrequestdatum');
      if (!rawData) {
        return new InvariantViolation(
          "<MathContent>: BOX_PLOT_STATIC requires a 'boxplotrequestdatum' attribute",
        );
      }
      const data = JSON.parse(rawData);
      const { eye_candy: eyeCandy, interactivity } = data;
      return {
        axisTitle: eyeCandy.axis_title,
        axisMin: parseFloat(eyeCandy.min),
        axisMax: parseFloat(eyeCandy.max),
        axisMajorTickInterval: parseFloat(eyeCandy.major_unit),
        axisMinorTickInterval: parseFloat(eyeCandy.minor_unit),
        value: {
          min: {
            value: parseFloat(interactivity.min.value),
            substatus: 'UNKNOWN',
          },
          q1: {
            value: parseFloat(interactivity.q1.value),
            substatus: 'UNKNOWN',
          },
          median: {
            value: parseFloat(interactivity.median.value),
            substatus: 'UNKNOWN',
          },
          q3: {
            value: parseFloat(interactivity.q3.value),
            substatus: 'UNKNOWN',
          },
          max: {
            value: parseFloat(interactivity.max.value),
            substatus: 'UNKNOWN',
          },
        },
      };
    }

    // NOTE this is the one node that is being used for both static and dynamic
    // use cases. We are returning only a single data shape. This means that for
    // both cases, unecessary data is being provided. This also adds some confusion
    // to the props extraction/transforms here as some of the logic/attributes
    // are only for one of the two modes.
    case 'LATEX_INPUT_STATIC_OR_DYNAMIC': {
      // TODO change 'data-key' -> 'data-answer-key' at the Soup layer.
      const answerKey = node.getAttribute('data-key');
      const isPrimary = node.hasAttribute('data-primary');
      // TODO this should be exported from some MathQuill type module so it
      // isn't litered around our codebase.
      const MATHQUILL_LATEX_EDITABLE_MACRO = '\\editable{}';

      // If there is no latex already in the input field (pretty common) we
      // inject the MATHQUILL_LATEX_EDITABLE_MACRO so that a placeholder field area
      // is rendered. Otherwise, you will just get a 0-width blank space rendered
      // NOTE: Using ! (not unwrap) as this stuff looks fragile
      const latex = node.textContent!.trim() || MATHQUILL_LATEX_EDITABLE_MACRO;

      // PONDER: The abstraction is a tad leaky here as the 'answerKey' isn't actually
      // a prop for Latex, its necessary for a custom node renderer that needs
      // to color-code a latex-expression provided as an answer to a question.
      return answerKey != null
        ? {
            answerKey,
            latex, // Only to be used in static mode
            isPrimary, // Only to be used in dynamic mode
          }
        : new InvariantViolation(
            "<MathContent>: LATEX_INPUT_STATIC_OR_DYNAMIC requires a 'data-key' attribute",
          );
    }
    case 'GRAPHPLOT_STATIC': {
      const rawDatum = node.getAttribute('graphplotrequestdatum');
      return rawDatum
        ? {
            datum: JSON.parse(rawDatum),
          }
        : new InvariantViolation(
            "<MathContent>: GRAPHPLOT_STATIC requires a 'graphplotrequestdatum' attribute",
          );
    }
    case 'LATEX_EXPRESSION': {
      // Using ! (not unwrap) as this stuff looks fragile
      const latex = node.textContent!.trim();
      return latex !== ''
        ? { latex }
        : new InvariantViolation(
            '<MathContent>: LATEX_EXPRESSION requires a non-empty expression',
          );
    }
    case 'GEOGEBRA_EMBED': {
      const { dataset } = node;
      return {
        embedId: dataset.geogebraId,
        // Using ! (not unwrap) as this stuff looks fragile
        width: parseInt(dataset.width!, 10),
        height: parseInt(dataset.height!, 10),
        attributionText: dataset.attributionText,
        attributionUrl: dataset.attributionLink,
      };
    }
    case 'VIDEO_EMBED': {
      let videoHostingService = '';
      if (node.classList.contains('js-wistia-container')) {
        videoHostingService = 'wistia';
      } else if (node.classList.contains('js-youtube-player')) {
        videoHostingService = 'youtube';
      } else if (node.classList.contains('js-bunny-container')) {
        videoHostingService = 'bunny';
      }
      return {
        id: node.dataset.videoId,
        videoHostingService,
        // Using ! (not unwrap) as this stuff looks fragile
        aspectRatio: formatAspectRatio(node.dataset.aspectRatio!),
      };
    }
    case 'PROBLEM_PREVIEW': {
      const rawProps = node.getAttribute('props');
      if (!rawProps) {
        return new InvariantViolation(
          "<MathContent>: PROBLEM_PREVIEW requires a 'props' attribute",
        );
      }
      const props = rawProps ? JSON.parse(rawProps) : {};
      return {
        attachment: props.attachment,
        hasSolution: props.has_solution,
        id: props.id,
        instruction: props.instruction,
        isStaticQuestion: props.is_static_question,
        // previewWorkoutCreationToken: props.preview_workout_creation_token, // TODO re-add it and remove template_id https://trello.com/c/0u4Nxh8z/1102-problempreview-fixes
        subproblems: props.subproblems,
        template_id: props.template_id,
      };
    }
    case 'VIDEO_LAUNCHER': {
      // This logic is ridiculous but comes from the legacy widget renderer.
      // Once serialization format is improved we can nuke it.
      const isHyperlinkLauncher = !node.classList.contains('explain-video');

      return {
        id: node.dataset.videoId,
        videoHostingService: node.dataset.source,
        // Using ! (not unwrap) as this stuff looks fragile
        aspectRatio: formatAspectRatio(node.dataset.aspectRatio!),
        // We must only provide a launchLinkText prop if it is a hyperlink launcher
        ...(isHyperlinkLauncher ? { launchLinkText: node.textContent } : {}),
      };
    }
    case 'CHAPTER_LINK': {
      const rawProps = node.getAttribute('props');
      return rawProps
        ? JSON.parse(rawProps)
        : new InvariantViolation(
            "<MathContent>: CHAPTER_LINK requires a 'props' attribute",
          );
    }
    case 'HINT_CONTEXT': {
      return {
        children: node.innerHTML,
        messageType: node.getAttribute('data-message-type'),
      };
    }
    case 'IMAGE': {
      return {
        src: node.getAttribute('src') ?? undefined,
        alt: node.getAttribute('alt') ?? undefined,
        width: node.getAttribute('width') ?? undefined,
        style: convertElementStyleToStyleObject(node.style),
      };
    }
    case 'TRANSLATED_MULTIPLE_CHOICE_OPTION': {
      return {
        optionLabel: node.dataset.optionLabel,
        children: node.innerHTML,
      };
    }
    default:
      assertUnreachable(
        mathContentType,
        `Unrecognised mathContentType in \`renderNode\` in MathContent: ${mathContentType}`,
      );
  }
};

/**
 * Transform a single DOM node into a React Element, using the provided set
 * of nodeRenderers.
 *
 * @param {*} node A DOM node that is the root of a DOM tree.
 * @param {*} key A unique value
 * @param {*} nodeRenderers A map of MathContentType to node renderer
 */
const renderNode = (
  node: Node,
  key: number,
  nodeRenderers: NodeRendererMap,
): ReactNode => {
  // === CASE: Unhandled node ===
  // We will only handle element and text nodes.
  if (!(node instanceof Element) && !(node instanceof Text)) return null;

  // === CASE: Text node ===
  if (node instanceof Text) return node.data;

  const mathContentType = getMathContentType(node);

  // === CASE: Standard DOM node ===
  if (!mathContentType) {
    return createElement(
      node.tagName.toLowerCase(),
      {
        ...attributesToReactProps(node),
        key,
      },
      null,
    );
  }

  // Math content types are always serialized as HTMLElements. Flow can't track
  // that refinement in getMathContentType(), so we have to do it again here.
  if (!(node instanceof HTMLElement)) return null;

  // === CASE: Serialized MathContent node ===
  const props = getProps(mathContentType, node);
  if (props instanceof Error) {
    Logger.error(props, {
      tags: { component: 'ms-components/math/MathContent' },
      extra: {
        node: node.outerHTML,
      },
    });
    return null;
  }

  const config = { ...props, key };
  switch (mathContentType) {
    // Manual delegation via switch used for Flow's exhaustiveness checks.
    case 'PROBABILITY_TREE_STATIC':
      return nodeRenderers.PROBABILITY_TREE_STATIC(config);
    case 'HISTOGRAM_STATIC':
      return nodeRenderers.HISTOGRAM_STATIC(config);
    case 'NUMBER_LINE_STATIC':
      return nodeRenderers.NUMBER_LINE_STATIC(config);
    case 'NUMBER_BUILDER_STATIC':
      return nodeRenderers.NUMBER_BUILDER_STATIC(config);
    case 'BOX_PLOT_STATIC':
      return nodeRenderers.BOX_PLOT_STATIC(config);
    case 'LATEX_INPUT_STATIC_OR_DYNAMIC':
      return nodeRenderers.LATEX_INPUT_STATIC_OR_DYNAMIC(config);
    case 'GRAPHPLOT_STATIC':
      return nodeRenderers.GRAPHPLOT_STATIC(config);
    case 'LATEX_EXPRESSION':
      return nodeRenderers.LATEX_EXPRESSION(config);
    case 'GEOGEBRA_EMBED':
      return nodeRenderers.GEOGEBRA_EMBED(config);
    case 'VIDEO_EMBED':
      return nodeRenderers.VIDEO_EMBED(config);
    case 'PROBLEM_PREVIEW':
      return nodeRenderers.PROBLEM_PREVIEW(config);
    case 'VIDEO_LAUNCHER':
      return nodeRenderers.VIDEO_LAUNCHER(config);
    case 'CHAPTER_LINK':
      return nodeRenderers.CHAPTER_LINK(config);
    case 'HINT_CONTEXT':
      return nodeRenderers.HINT_CONTEXT(config);
    case 'IMAGE':
      return nodeRenderers.IMAGE(config);
    case 'TRANSLATED_MULTIPLE_CHOICE_OPTION':
      return nodeRenderers.TRANSLATED_MULTIPLE_CHOICE_OPTION(config);
    default:
      assertUnreachable(
        mathContentType,
        `Unrecognised mathContentType in \`config\` in MathContent: ${mathContentType}`,
      );
  }
};

export default renderNode;
