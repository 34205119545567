import styled from '@emotion/styled';

import AccessibilityIcon from 'ms-components/icons/Accessibility';
import { HeadingS, BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import { VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { useToggleAccessibilityMode } from 'ms-utils/accessibility';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const Wrapper = styled.div({
  padding: 32,
  color: colors.grey,
  textAlign: 'center',
});

// Offset the top area at the bottom
const TertiaryButtonWrapper = styled.div({
  marginBottom: -8,
});

export default function AccessibilityModeWarningModal({
  isOpen,
  onClose,
}: Props) {
  const toggleAccessibilityMode = useToggleAccessibilityMode();

  return (
    <Modal
      width={512}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
    >
      <Wrapper>
        <VStack center>
          <AccessibilityIcon size={40} />

          <VSpacer height={16} />

          <HeadingS>You’re currently in Accessibility mode</HeadingS>

          <VSpacer height={8} />

          <BodyM>
            You will only have access to content that meets accessibility
            guidelines.
          </BodyM>

          <VSpacer height={32} />

          <Button onClick={onClose} type="primary" size="lanternSmall">
            Ok, got it
          </Button>

          <VSpacer height={8} />
          <TertiaryButtonWrapper>
            <Button
              onClick={toggleAccessibilityMode}
              type="tertiary"
              size="lanternSmall"
            >
              Disable Accessibility mode
            </Button>
          </TertiaryButtonWrapper>
        </VStack>
      </Wrapper>
    </Modal>
  );
}
