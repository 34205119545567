/* eslint-disable react/no-danger */
/* global __PAGE_ORIGIN__, __CONTENT_ORIGIN__ */
import { StyleSheet, css } from 'aphrodite';
import { isEmpty, equals } from 'ramda';
import { Component } from 'react';

import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import genUniqueId from 'ms-utils/id-generator';

import localScopeIdentifiers from './styles.css';
import type { LegacyDatum } from '../GraphPlot';

const GRAPH_PLOT_SIZE = 350;

const MESSAGE_TARGET_ORIGIN = __PAGE_ORIGIN__;
const IFRAME_SRC = `${__CONTENT_ORIGIN__}/graphplot_iframe/readonly/`;

const styles = StyleSheet.create({
  root: {
    width: GRAPH_PLOT_SIZE,
    height: GRAPH_PLOT_SIZE,
    background: colors.white,
    border: `1px solid ${colors.nevada}`,
    borderRadius: borderRadiusUI,
    overflow: 'hidden',
    userSelect: 'none',
    // Some of the GraphPlot labels are positioned absolutely. But we never
    // want this stacking context to go above anything else.
    position: 'relative',
    zIndex: 0,
  },
  iframe: {
    position: 'fixed',
    visibility: 'hidden',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  loadingMessage: {
    background: colors.athensGray,
    boxShadow: `inset 0 0 1px ${colors.ironLight}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontFamily.body,
    fontSize: fontSize.large,
    color: colors.mako,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    transition: `opacity ${transition}`,
  },
});

// We will use singleton state to coordinate between all instances of
// GraphPlotReadOnly that will be sharing a single iframe rendering
// environment.
let iframe: HTMLIFrameElement | undefined;
let isIframeLoaded = false;

type Props = {
  datum: LegacyDatum;
};

type State = {
  iframeRenderOutput: string; // HTML string
  instanceId: number;
};

class GraphPlotReadOnly extends Component<Props, State> {
  override state: State = {
    iframeRenderOutput: '',
    // We need an identifier for the graph instance that we can pass
    // to the iframe, so that we can identify the return 'renderComplete'
    // message that is in reply to our 'render' message.
    instanceId: genUniqueId(),
  };

  override componentDidMount() {
    window.addEventListener('message', this.handleMessage);
    this.injectIframe();
  }

  override componentDidUpdate(prevProps: Props) {
    if (!equals(this.props.datum, prevProps.datum)) {
      this.rerender();
    }
  }

  override componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
    // NOTE we are going to leave the iframe resident in memory regardless of
    // whether we are unmounting the final GraphPlotReadOnly as this will
    // ensure any future GraphPlotReadOnlys that get mounted during the
    // session, will render lightning quick ⚡️
  }

  rerender = () => {
    // we use this trick to update the graph when the value is updated from the parent
    this.setState(
      () => ({
        instanceId: genUniqueId(),
        iframeRenderOutput: '',
      }),
      () => {
        this.renderGraphInto(iframe);
      },
    );
  };

  handleMessage = (event: MessageEvent) => {
    const { data } = event;

    if (
      !(data instanceof Object) ||
      data.type == null ||
      data.type !== 'renderComplete' ||
      data.graphId == null ||
      data.graphId !== this.state.instanceId ||
      data.serializedRenderOutput == null
    )
      return;

    this.setState(state => ({
      ...state,
      iframeRenderOutput: data.serializedRenderOutput,
    }));
  };

  injectIframe() {
    if (iframe === undefined) {
      // This is the first GraphPlotReadOnly to mount in this realm
      iframe = document.createElement('iframe');
      iframe.width = `${GRAPH_PLOT_SIZE}px`;
      iframe.height = `${GRAPH_PLOT_SIZE}px`;
      iframe.src = IFRAME_SRC;
      iframe.frameBorder = 'none';
      iframe.classList.add(css(styles.iframe));

      iframe.addEventListener('load', () => {
        isIframeLoaded = true;
        this.renderGraphInto(iframe);
      });

      if (document.body) document.body.appendChild(iframe);
    } else if (!isIframeLoaded) {
      // This case occurs when you render multiple GraphPlotReadOnly components
      // in a single render pass, when there is currently no cached iframe. This
      // is because the first GraphPlotReadOnly will inject the iframe, but the
      // iframe won't have finished loading by the time the next
      // GraphPlotReadOnly has finished mounting.
      iframe.addEventListener('load', () => {
        this.renderGraphInto(iframe);
      });
    } else {
      // Fully loaded iframe is available so we can pass messages immediately
      this.renderGraphInto(iframe);
    }
  }

  renderGraphInto(targetIframe: HTMLIFrameElement | undefined) {
    if (targetIframe === undefined) return;
    targetIframe.contentWindow?.postMessage(
      {
        type: 'render',
        graphId: this.state.instanceId,
        datum: {
          ...this.props.datum,
          eye_candy: {
            ...this.props.datum.eye_candy,
            // We need to explicitally set what dimensions we want the CanJS
            // component to render the graph plot at.
            width: GRAPH_PLOT_SIZE,
            height: GRAPH_PLOT_SIZE,
          },
          interactivity: {
            ...this.props.datum.interactivity,
            // This enforces that the GraphPlot is read-only
            unlock: false,
          },
        },
        // We need to tell our iframe which origin to post messages back to.
        parentOrigin: window.location.origin,
      },
      MESSAGE_TARGET_ORIGIN,
    );
  }

  override render() {
    return (
      <div className={`${localScopeIdentifiers.root} ${css(styles.root)}`}>
        <div
          dangerouslySetInnerHTML={{ __html: this.state.iframeRenderOutput }}
        />
        <div
          className={css(styles.loadingMessage)}
          style={{
            opacity: isEmpty(this.state.iframeRenderOutput) ? 1 : 0,
          }}
        >
          Loading Graph...
        </div>
      </div>
    );
  }
}

export default GraphPlotReadOnly;
