import { zipObj } from 'ramda';

import MathContent from 'ms-components/math/MathContent';
import MultipleChoiceReadOnly from 'ms-components/math/MultipleChoiceReadOnly';

import type { Subproblem } from '../types';

type Props = {
  subproblem: Subproblem | null | undefined;
};

const SubproblemInstruction = ({ subproblem }: Props) => {
  if (subproblem == null) return null;

  if (subproblem.type === 'multiple-choice') {
    const keys = Object.values(subproblem.available_values).map(v => v.value);
    const values = Object.values(subproblem.available_values).map(v => ({
      value: v.label,
      selected: false,
    }));
    const options = zipObj(keys, values);

    return (
      <div>
        <MathContent content={subproblem.instruction} />
        <MultipleChoiceReadOnly value={options} />
      </div>
    );
  }

  return <MathContent content={subproblem.instruction} />;
};

export default SubproblemInstruction;
