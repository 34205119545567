import { useContext } from 'react';

import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import Input from 'ms-ui-primitives/Input';
import { styled } from 'ms-utils/emotion';

import { StateContext, UpdatersContext } from '../state';

const InputWrapper = styled({
  maxWidth: 320,
});

type Props = {
  errorMessages: ErrorMessages;
};

const ClassDisplayName = ({ errorMessages }: Props) => {
  const { displayName, useTitleForDisplayName } = useContext(StateContext);
  const { updateDisplayName } = useContext(UpdatersContext);

  return (
    <FieldGroup
      isFieldRequired
      title="Class display name"
      description="This is the class name students in this class will see"
    >
      <InputWrapper>
        <Input
          value={displayName}
          onChange={e => updateDisplayName(e.target.value)}
          showErrorState={errorMessages.length > 0}
          disabled={useTitleForDisplayName}
        />
      </InputWrapper>
      <FormFieldError messages={errorMessages} />
    </FieldGroup>
  );
};

export default ClassDisplayName;
