/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type SunflowerStudentDashboardHeaderWithLearningFocus_lantern = {
    readonly substrand: {
        readonly id: string;
        readonly title: string;
        readonly canStartCheckIn: boolean;
        readonly strand: {
            readonly id: string;
        };
        readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_substrand">;
    } | null;
    readonly viewer: ({
        readonly __typename: "LanternStudent";
        readonly learningFocus: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"SuggestedLearningFocusNotification_learningFocus">;
        } | null;
        readonly suggestedLearningFocus: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"SuggestedLearningFocusNotification_suggestedLearningFocus">;
        } | null;
        readonly strandStatus: {
            readonly status: StudentStrandStatusEnum;
            readonly lastWorkedOnDiagnosticCheckIn: {
                readonly id: string;
            } | null;
            readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_student">;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    }) | null;
    readonly " $refType": "SunflowerStudentDashboardHeaderWithLearningFocus_lantern";
};
export type SunflowerStudentDashboardHeaderWithLearningFocus_lantern$data = SunflowerStudentDashboardHeaderWithLearningFocus_lantern;
export type SunflowerStudentDashboardHeaderWithLearningFocus_lantern$key = {
    readonly " $data"?: SunflowerStudentDashboardHeaderWithLearningFocus_lantern$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SunflowerStudentDashboardHeaderWithLearningFocus_lantern">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "strandId"
    },
    {
      "kind": "RootArgument",
      "name": "substrandId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SunflowerStudentDashboardHeaderWithLearningFocus_lantern",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "substrandId"
        }
      ],
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "substrand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canStartCheckIn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Strand",
          "kind": "LinkedField",
          "name": "strand",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StudentSkillsMapSubstrandProficiencyIndicator_substrand"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Substrand",
              "kind": "LinkedField",
              "name": "learningFocus",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SuggestedLearningFocusNotification_learningFocus"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Substrand",
              "kind": "LinkedField",
              "name": "suggestedLearningFocus",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SuggestedLearningFocusNotification_suggestedLearningFocus"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "strandId",
                  "variableName": "strandId"
                }
              ],
              "concreteType": "StudentStrandStatus",
              "kind": "LinkedField",
              "name": "strandStatus",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "CheckIn",
                  "kind": "LinkedField",
                  "name": "lastWorkedOnDiagnosticCheckIn",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudentSkillsMapSubstrandProficiencyIndicator_student"
            }
          ],
          "type": "LanternStudent",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Lantern",
  "abstractKey": null
};
})();
(node as any).hash = '1ee343dc62deda24e484062c6856e9d3';
export default node;
