import styled from '@emotion/styled';
import type { SyntheticEvent } from 'react';
import { useEffect } from 'react';
import { useFragment, graphql } from 'relay-hooks';

import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import MasteryLevelRibbonIcon from 'ms-components/icons/MasteryLevelRibbon';
import ShuffleIcon from 'ms-components/icons/Shuffle';
import TextbookTeacherIcon from 'ms-components/icons/TextbookTeacher';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { RECOMMENDATION_MODAL_TRIGGER } from 'ms-pages/DemoStudent';
import {
  Card as CardBase,
  CardBody,
  CardHeader as CardHeaderBase,
  CardTitle,
  CARD_PADDING,
  useGetBadgeBackground,
} from 'ms-pages/Lantern/components/Card';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { borderRadiusUILarge } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { InlinePopoverAnchor } from 'ms-ui-primitives/Popover/InlinePopover';
import { HStack, HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString } from 'ms-utils/colors';
import { onHover } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import useIsExclusivelyTouchScreen from 'ms-utils/hooks/useIsExclusivelyTouchScreen';
import { noop } from 'ms-utils/misc';
import { assertUnreachable } from 'ms-utils/typescript-utils';
import {
  getContentBrowsingSubtopicDetailViewUrl,
  getSelfDirectedAdaptiveTaskUrl,
} from 'ms-utils/urls';

import RecommendationSkillPopover from './RecommendationSkillPopover';
import type {
  SubstrandSubtopicRecommendationCategory,
  SubtopicRecommendationCard_substrandSubtopicRecommendation$key,
} from './__generated__/SubtopicRecommendationCard_substrandSubtopicRecommendation.graphql';
import algebra from './assets/algebra.svg';
import inequalities from './assets/inequalities.svg';
import patterns from './assets/patterns.svg';

type Props = {
  substrandSubtopicRecommendation: SubtopicRecommendationCard_substrandSubtopicRecommendation$key;
  isShuffleable: boolean;
  onShuffle: () => void;
  urlToNavigateToWhenOutcomeIsClicked: string | null | undefined;
  inDashboard: boolean;
};
const SHUFFLE_BUTTON_SIZE = 32;
const SHUFFLE_ICON_SIZE = SHUFFLE_BUTTON_SIZE / 2;
const Box1 = styled.div({
  backgroundColor: colors.iron,
  width: 194,
  height: 10,
  borderRadius: 4,
});
const Box2 = styled.div({
  backgroundColor: colors.iron,
  width: 64,
  height: 10,
  borderRadius: 4,
});
const Box3 = styled.div({
  backgroundColor: colors.seashell,
  width: 60,
  height: 6,
  borderRadius: 3,
});
const ShuffleButton = styled.div({
  position: 'absolute',
  top: CARD_PADDING / 2,
  right: CARD_PADDING / 2,
  width: SHUFFLE_BUTTON_SIZE,
  height: SHUFFLE_BUTTON_SIZE,
  borderRadius: borderRadiusUILarge,
  backgroundColor: hexStringToRgbaString(colors.white, 0.2),
  ...onHover({
    backgroundColor: hexStringToRgbaString(colors.white, 0.4),
  }),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const Card = styled(CardBase, {
  shouldForwardProp: p => {
    if (
      typeof p === 'string' &&
      ['isVisible', 'shuffleButtonAlwaysVisible'].includes(p)
    ) {
      return false;
    }
    return true;
  },
})<{
  href: string;
  shuffleButtonAlwaysVisible: boolean;
  isVisible: boolean;
}>(({ shuffleButtonAlwaysVisible, isVisible }) => ({
  display: 'block',
  opacity: isVisible ? 1 : 0.5,
  transition: 'opacity .5s ease-in-out',
  // @ts-expect-error this is ok according to https://emotion.sh/docs/styled#targeting-another-emotion-component
  [ShuffleButton]: {
    visibility: shuffleButtonAlwaysVisible ? 'visible' : 'hidden',
  },
  ...onHover({
    // @ts-expect-error this is ok according to https://emotion.sh/docs/styled#targeting-another-emotion-component
    [ShuffleButton]: {
      visibility: 'visible',
    },
  }),
}));
const CardHeader = styled(CardHeaderBase)({
  position: 'relative', // to position the ShuffleButton
});
const CategoryButtonWrapper = styled.div({
  // align to the bottom
  marginTop: 'auto',
});
// A hack to make a non-interactive `Button`: otherwise we would
// have to create a new element and copy `Button`'s styles.
const CategoryButton = styled(Button, {
  shouldForwardProp: p => {
    if (typeof p === 'string' && ['isClickable'].includes(p)) {
      return false;
    }
    return true;
  },
})<{
  isClickable: boolean;
}>(({ isClickable }) => ({
  pointerEvents: isClickable ? 'auto' : 'none',
}));
function RecommendationCategoryButtonInactive({
  title,
  onClick,
}: {
  title: string;
  onClick?: ((event: SyntheticEvent<HTMLElement>) => void) | void;
}) {
  return (
    <CategoryButton
      isClickable={onClick != null}
      onClick={onClick != null ? onClick : noop}
      isBlock
      size="tiny"
      type="primary"
      color="almond50"
      label={title}
    >
      <MasteryLevelRibbonIcon color={colors.grey10} size={16} />
    </CategoryButton>
  );
}
export function RecommendationCategoryButton({
  title,
  onClick,
}: {
  title: string;
  onClick?: ((event: SyntheticEvent<HTMLElement>) => void) | void;
}) {
  return (
    <CategoryButton
      isClickable={onClick != null}
      onClick={onClick != null ? onClick : noop}
      isBlock
      size="tiny"
      type="primary"
      color="almond50"
      label={title}
    >
      <MasteryLevelRibbonIcon color={colors.grey90} size={16} />
    </CategoryButton>
  );
}
const SUBTOPIC_RECOMMENDATION_CARD_SUBSTRAND_SUBTOPIC_RECOMMENDATION_FRAGMENT = graphql`
  fragment SubtopicRecommendationCard_substrandSubtopicRecommendation on SubstrandSubtopicRecommendation {
    subtopic {
      id
      title
      topic {
        id
        badgeBackground
        badgeUrl
        syllabus {
          id
        }
      }
      hasAdaptivePractice
      gamifiedMastery
    }
    category
    outcomes {
      id
      title
    }
  }
`;
function EmptyStateCard({
  inDashboard,
  category,
  headerImage,
}: {
  inDashboard: boolean;
  category: SubstrandSubtopicRecommendationCategory;
  headerImage: string;
}) {
  const url = `/${RECOMMENDATION_MODAL_TRIGGER}`;
  const categoryMessage: string = mapCategoryToMessage(category);
  // animate the card in with opacity
  const isVisible = useBoolean();
  useEffect(() => {
    const timeout = setTimeout(isVisible.setTrue, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible.setTrue]);
  const isExclusivelyTouchScreen = useIsExclusivelyTouchScreen();
  const { trackStructEvent } = useSnowplow();
  return (
    <>
      <Card
        isVisible={isVisible.value}
        shuffleButtonAlwaysVisible={isExclusivelyTouchScreen}
        href={url}
        onClick={() => {
          trackStructEvent(
            inDashboard
              ? {
                  category: 'student_dashboard',
                  action: 'started_recommendation',
                }
              : {
                  category: 'student_skills_map',
                  action: 'started_recommendation',
                },
          );
        }}
      >
        <CardHeader
          style={{
            backgroundColor: '#b0b0b0',
          }}
        >
          <img src={headerImage} alt="subtopic badge" />
        </CardHeader>
        <CardBody>
          <Box1 />
          <VSpacer height={8} />
          <Box2 />
          <VSpacer height={16} />
          <HStack>
            <Box3 />
            <HSpacer width={6} />
            <Box3 />
            <HSpacer width={6} />
            <Box3 />
          </HStack>
          <VSpacer height={8} grow />
          <HStack>
            <CategoryButtonWrapper>
              <InlinePopoverAnchor>
                <RecommendationCategoryButtonInactive title={categoryMessage} />
              </InlinePopoverAnchor>
            </CategoryButtonWrapper>
            <HSpacer width={8} grow />
            <HStack center>
              <MasteryLevelVolumeIndicator
                inAssignedTaskCard
                masteryLevel={0}
                showTooltip
              />
            </HStack>
          </HStack>
        </CardBody>
      </Card>
    </>
  );
}
export function SubtopicRecommendationCardEmptyState() {
  return (
    <HStack>
      <EmptyStateCard
        inDashboard={false}
        category="PRIOR_SKILLS_MASTERED"
        headerImage={algebra}
      />
      <HSpacer width={20} />
      <EmptyStateCard
        inDashboard={false}
        category="SKILL_ALMOST_MASTERED"
        headerImage={inequalities}
      />
      <HSpacer width={20} />
      <EmptyStateCard
        inDashboard={false}
        category="SKILL_WORKED_ON_RECENTLY"
        headerImage={patterns}
      />
    </HStack>
  );
}
export default function SubtopicRecommendationCard({
  substrandSubtopicRecommendation: substrandSubtopicRecommendationRef,
  isShuffleable,
  onShuffle,
  urlToNavigateToWhenOutcomeIsClicked,
  inDashboard,
}: Props) {
  const substrandSubtopicRecommendation = useFragment(
    SUBTOPIC_RECOMMENDATION_CARD_SUBSTRAND_SUBTOPIC_RECOMMENDATION_FRAGMENT,
    substrandSubtopicRecommendationRef,
  );
  const {
    subtopic: {
      id: subtopicId,
      title,
      topic: {
        id: topicId,
        badgeUrl,
        badgeBackground,
        syllabus: { id: syllabusId },
      },
      hasAdaptivePractice,
      gamifiedMastery,
    },
    category,
    outcomes,
  } = substrandSubtopicRecommendation;
  const url = hasAdaptivePractice
    ? getSelfDirectedAdaptiveTaskUrl({
        subtopicId,
        isSubtopicRecommendation: true,
        recommendationCategory: category,
      })
    : getContentBrowsingSubtopicDetailViewUrl({
        syllabusId,
        topicId,
        subtopicId,
      });
  const categoryMessage: string = mapCategoryToMessage(category);
  const isSkillsPopoverOpen = useBoolean();
  // animate the card in with opacity
  const isVisible = useBoolean();
  useEffect(() => {
    const timeout = setTimeout(isVisible.setTrue, 0);
    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible.setTrue]);
  const isExclusivelyTouchScreen = useIsExclusivelyTouchScreen();
  const { trackStructEvent } = useSnowplow();
  return (
    <>
      <Card
        isVisible={isVisible.value}
        shuffleButtonAlwaysVisible={isExclusivelyTouchScreen}
        href={url}
        onClick={() => {
          trackStructEvent(
            inDashboard
              ? {
                  category: 'student_dashboard',
                  action: 'started_recommendation',
                }
              : {
                  category: 'student_skills_map',
                  action: 'started_recommendation',
                },
          );
        }}
      >
        <CardHeader
          style={{
            backgroundImage: `url(${badgeUrl})`,
            backgroundColor: useGetBadgeBackground({
              badgeUrl,
              badgeBackground,
            }),
          }}
        >
          {isShuffleable && (
            <Tooltip
              backgroundColor={colors.grey}
              content="Generate new recommendation"
              placement="bottom"
              delay={500}
            >
              <ShuffleButton
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation(); // For Snowplow events
                  onShuffle();
                  trackStructEvent(
                    inDashboard
                      ? {
                          category: 'student_dashboard',
                          action: 'shuffled_recommendation',
                        }
                      : {
                          category: 'student_skills_map',
                          action: 'shuffled_recommendation',
                        },
                  );
                }}
              >
                <ShuffleIcon color={colors.white} size={SHUFFLE_ICON_SIZE} />
              </ShuffleButton>
            </Tooltip>
          )}
        </CardHeader>
        <CardBody>
          <CardTitle title={title} />
          <VSpacer height={8} grow />
          <HStack>
            <CategoryButtonWrapper>
              <InlinePopoverAnchor>
                <RecommendationCategoryButton
                  title={categoryMessage}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation(); // For Snowplow events
                    isSkillsPopoverOpen.setTrue();
                    trackStructEvent(
                      inDashboard
                        ? {
                            category: 'student_dashboard',
                            action: 'viewed_recommendation_category',
                          }
                        : {
                            category: 'student_skills_map',
                            action: 'viewed_recommendation_category',
                          },
                    );
                  }}
                />

                {isSkillsPopoverOpen.value && (
                  <RecommendationSkillPopover
                    onDismiss={isSkillsPopoverOpen.setFalse}
                    categoryMessage={categoryMessage}
                    outcomes={outcomes}
                    urlToNavigateToWhenOutcomeIsClicked={
                      urlToNavigateToWhenOutcomeIsClicked
                    }
                    inDashboard={inDashboard}
                  />
                )}
              </InlinePopoverAnchor>
            </CategoryButtonWrapper>
            <HSpacer width={8} grow />
            <HStack center>
              {hasAdaptivePractice ? (
                <MasteryLevelVolumeIndicator
                  inAssignedTaskCard
                  masteryLevel={gamifiedMastery}
                  showTooltip
                />
              ) : (
                <TextbookTeacherIcon size={24} color={colors.grey90} />
              )}
            </HStack>
          </HStack>
        </CardBody>
      </Card>
    </>
  );
}
export function mapCategoryToMessage(
  category: SubstrandSubtopicRecommendationCategory,
): string {
  switch (category) {
    case 'PRIOR_SKILLS_MASTERED':
      return 'Ready to learn';
    case 'SKILL_ALMOST_MASTERED':
      return 'Almost mastered';
    case 'SKILL_WORKED_ON_RECENTLY':
      return 'Recent work';
    default: {
      assertUnreachable(category, `Unknown category: ${category}`);
    }
  }
}
