import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = <path d="M11.303 8l11.394 7.997L11.303 24z" />;

const CaretRight = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={32}
    viewBoxHeight={32}
  >
    {path}
  </Icon>
);

CaretRight.displayName = 'CaretRight';

export default CaretRight;
