import holidayDates from './holidays.json';

// Now that we are not calculating the dates on the fly
// To make sure that whoever is updating the dates in the future
// we need to make sure they always get local time format
// For a date string to be evaluated as local time, it needs to be in the format of
// 2023-04-09T00:00:00.000
// and not 2023-04-09T00:00:00.000Z
// so, we need to first get UTC date and time and remove the Z at the end

function getEasterDates(Y: number) {
  const C = Math.floor(Y / 100);
  const N = Y - 19 * Math.floor(Y / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  I =
    I -
    Math.floor(I / 28) *
      (1 -
        Math.floor(I / 28) *
          Math.floor(29 / (I + 1)) *
          Math.floor((21 - N) / 11));
  let J = Y + Math.floor(Y / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const M = 3 + Math.floor((L + 40) / 44);
  const D = L + 28 - 31 * Math.floor(M / 4);

  return {
    start: new Date(Date.UTC(Y, M - 1, D)).toISOString().slice(0, -1),
    end: new Date(Date.UTC(Y, M - 1, D + 1)).toISOString().slice(0, -1),
  };
}

async function getRamadanDates(hijriYear: number) {
  const { default: momentHijri } = await import('moment-hijri');
  // ramadan month is 9. month in hijri calendar
  // convert start of ramadan in current hijri year to gregorian date
  const startLocal = momentHijri(`${hijriYear}-9-1`, 'iYYYY-iM-iD').toDate();
  const start = new Date(
    Date.UTC(
      startLocal.getFullYear(),
      startLocal.getMonth(),
      startLocal.getDate(),
    ),
  )
    .toISOString()
    .slice(0, -1);

  // convert end of ramadan in current hijri year to gregorian date
  const endLocal = momentHijri(`${hijriYear}-10-1`, 'iYYYY-iM-iD').toDate();
  const end = new Date(
    Date.UTC(endLocal.getFullYear(), endLocal.getMonth(), endLocal.getDate()),
  )
    .toISOString()
    .slice(0, -1);

  return { start, end };
}

async function getYomKippurDates(hebrewYear: number) {
  const { HDate, months } = await import('@hebcal/core');
  const startLocal = new HDate(10, months.TISHREI, hebrewYear).greg();
  const start = new Date(
    Date.UTC(
      startLocal.getFullYear(),
      startLocal.getMonth(),
      startLocal.getDate(),
    ),
  )
    .toISOString()
    .slice(0, -1);
  const endLocal = new HDate(11, months.TISHREI, hebrewYear).greg();
  const end = new Date(
    Date.UTC(endLocal.getFullYear(), endLocal.getMonth(), endLocal.getDate()),
  )
    .toISOString()
    .slice(0, -1);
  return { start, end };
}

async function getThanksgivingDates(year: number) {
  const { default: dayjs } = await import('dayjs');
  const firstDayOfNov = new Date(year, 10, 1);
  const firstThursdayOfNov = dayjs(firstDayOfNov).day(4);
  const thanksgivingDay = firstThursdayOfNov.add(3, 'week').date();
  const start = new Date(Date.UTC(year, 10, thanksgivingDay))
    .toISOString()
    .slice(0, -1);
  const end = new Date(Date.UTC(year, 10, thanksgivingDay + 1))
    .toISOString()
    .slice(0, -1);
  return { start, end };
}

async function getEidAlFitrDates(hijriYear: number) {
  const { default: momentHijri } = await import('moment-hijri');
  // eid al-fitr is the first 3 days of 10. month in hijri calendar
  // convert start of eid al-fitr in current hijri year to gregorian date
  const startLocal = momentHijri(`${hijriYear}-10-1`, 'iYYYY-iM-iD').toDate();
  const start = new Date(
    Date.UTC(
      startLocal.getFullYear(),
      startLocal.getMonth(),
      startLocal.getDate(),
    ),
  )
    .toISOString()
    .slice(0, -1);
  // convert end of eid al-fitr in current hijri year to gregorian date
  const endLocal = momentHijri(`${hijriYear}-10-4`, 'iYYYY-iM-iD').toDate();
  const end = new Date(
    Date.UTC(endLocal.getFullYear(), endLocal.getMonth(), endLocal.getDate()),
  )
    .toISOString()
    .slice(0, -1);
  return { start, end };
}

const holidays = [
  'easter',
  'halloween',
  'saint-patricks-day',
  'april-fools-day',
  'earth-day',
  'ramadan',
  'pi-day',
  'talk-like-a-pirate-day',
  'yom-kippur',
  'thanksgiving',
  'christmas',
  'eid-al-fitr',
  'youth-day',
  'festive',
] as const;

type HolidayTypes = (typeof holidays)[number];

type HolidayFormat = { start: string; end: string };

async function getHolidayDate({
  type,
  date,
  yearShift = 0,
}: {
  type: HolidayTypes;
  date?: Date;
  yearShift?: number;
}): Promise<HolidayFormat> {
  const today = date ?? new Date();
  const year = today.getFullYear() + yearShift;
  const { default: momentHijri } = await import('moment-hijri');
  const hijriYear = momentHijri(today).iYear() + yearShift;
  const { HDate } = await import('@hebcal/core');
  const hebrewYear = new HDate(today).getFullYear() + yearShift;
  switch (type) {
    case 'easter':
      return getEasterDates(year);
    case 'halloween':
      return {
        start: new Date(Date.UTC(year, 9, 31)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 10, 1)).toISOString().slice(0, -1),
      };
    case 'saint-patricks-day':
      return {
        start: new Date(Date.UTC(year, 2, 17)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 2, 18)).toISOString().slice(0, -1),
      };
    case 'april-fools-day':
      return {
        start: new Date(Date.UTC(year, 3, 1)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 3, 2)).toISOString().slice(0, -1),
      };
    case 'earth-day':
      return {
        start: new Date(Date.UTC(year, 3, 22)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 3, 23)).toISOString().slice(0, -1),
      };
    case 'ramadan':
      return await getRamadanDates(hijriYear);
    case 'pi-day':
      return {
        start: new Date(Date.UTC(year, 2, 14)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 2, 15)).toISOString().slice(0, -1),
      };
    case 'talk-like-a-pirate-day':
      return {
        start: new Date(Date.UTC(year, 8, 19)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 8, 20)).toISOString().slice(0, -1),
      };
    case 'yom-kippur':
      return await getYomKippurDates(hebrewYear);
    case 'thanksgiving':
      return await getThanksgivingDates(year);
    case 'christmas':
      return {
        start: new Date(Date.UTC(year, 11, 24)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 11, 26)).toISOString().slice(0, -1),
      };
    case 'eid-al-fitr':
      return await getEidAlFitrDates(hijriYear);
    case 'youth-day':
      return {
        start: new Date(Date.UTC(year, 7, 12)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year, 7, 20)).toISOString().slice(0, -1),
      };
    case 'festive':
      return {
        start: new Date(Date.UTC(year, 12, 1)).toISOString().slice(0, -1),
        end: new Date(Date.UTC(year + 1, 1, 1)).toISOString().slice(0, -1),
      };
    default:
      throw new Error(`getHolidayDate cannot handle holiday type ${type}`);
  }
}

type HolidayDate = { type: HolidayTypes; dates: HolidayFormat };

// @ts-expect-error unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getHolidayDates(): Promise<HolidayDate[]> {
  const holidayArr = [];

  for (let i = 0; i < holidays.length; i++) {
    const holiday = holidays[i];

    if (holiday !== undefined) {
      holidayArr.push({
        type: holiday,
        dates: await getHolidayDate({ type: holiday }),
      });
    }
  }

  return holidayArr;
}

// @ts-expect-error unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getHolidayDatesFor5Years(): Promise<HolidayDate[]> {
  const holidayArr = [];

  for (let i = 0; i < 5; i++) {
    for (let j = 0; j < holidays.length; j++) {
      const holiday = holidays[j];

      if (holiday !== undefined) {
        holidayArr.push({
          type: holiday,
          dates: await getHolidayDate({ type: holiday, yearShift: i }),
        });
      }
    }
  }
  return holidayArr;
}

export default function getTodaysHoliday(): HolidayTypes | null {
  // TODO: calculate holidays again in 2027 at latest and update holidays.json file
  // We decided to keep holiday days in a json file instead of calculating them
  // holidays.json file holds 5 year of holiday dates until 2028

  // WE NEED TO UPDATE holidays.json file WHENEVER WE ADD A NEW HOLIDAY
  // OR UPDATE AN EXISTING ONE
  // to generate holidays.json file,
  // convert this function to async function
  // uncomment the lines below and copy the output to holidays.json file

  // const dates = await getHolidayDatesFor5Years();
  // console.log('datesFor5Years', JSON.stringify(dates, null, 2));

  const dates = holidayDates as HolidayDate[];

  const today = new Date();
  let holiday = null;

  for (let i = 0; i < dates.length; i++) {
    const date = dates[i];
    if (
      date !== undefined &&
      new Date(date.dates.start) <= today &&
      new Date(date.dates.end) > today
    ) {
      holiday = date.type;
      return holiday;
    }
  }

  return holiday;
}
