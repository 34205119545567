import { useState } from 'react';

import FeedbackModal from 'ms-components/FeedbackModal';
import JsxContent from 'ms-components/JsxContent';
import KebabDropdown from 'ms-components/KebabDropdown';
import { fontWeight, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Modal, {
  ModalBody,
  MODAL_LATERAL_PADDING,
} from 'ms-ui-primitives/Modal';
import Stack from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';

import { PrintButton } from './DetailHeader';

const BOUNDING_BOX_WIDTH = 700;
const MODAL_WIDTH = BOUNDING_BOX_WIDTH + 2 * MODAL_LATERAL_PADDING;

type AnswerKey = string;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  answerKey: AnswerKey;
  subtopicId?: string | undefined;
  topicId?: string | undefined;
  answerKeyPdfUrl?: string | null;
};

const ModalHeader = styled({
  height: 60,
  borderBottom: `2px solid ${colors.ironLight}`,
  display: 'flex',
  alignItems: 'center',
  fontWeight: fontWeight.semibold,
  fontFamily: fontFamily.body,
  fontSize: 20,
  paddingLeft: 38,
  paddingRight: 68, // ALERT this is to leave space for the close button, may god forgive me 🙏
});

const AlignToRight = styled({
  marginLeft: 'auto',
});

export default function AnswerKeyModal({
  isOpen,
  onClose,
  answerKey,
  answerKeyPdfUrl,
  subtopicId,
  topicId,
}: Props) {
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  return (
    <>
      <Modal
        closeOnOverlayTap
        renderHeader={() => (
          <ModalHeader>
            Answer key
            <AlignToRight>
              <Stack.H center gap={16}>
                {answerKeyPdfUrl != null && (
                  <PrintButton pdfUrl={answerKeyPdfUrl} />
                )}
                <KebabDropdown
                  horizontal
                  items={[
                    {
                      key: 'submit-feedback',
                      label: 'Submit feedback',
                      action: () => {
                        setFeedbackModalOpen(true);
                      },
                    },
                  ]}
                />
              </Stack.H>
            </AlignToRight>
          </ModalHeader>
        )}
        isOpen={isOpen}
        onClose={onClose}
        width={MODAL_WIDTH}
      >
        <ModalBody>
          <JsxContent transpiledJsx={answerKey} />
        </ModalBody>
      </Modal>
      <FeedbackModal
        feedbackType="ANSWER_KEY_SUBMIT_FEEDBACK"
        feedbackData={{ subtopicId, topicId }}
        isOpen={isFeedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
      />
    </>
  );
}
