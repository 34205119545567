import styled from '@emotion/styled';
import { Fragment } from 'react';

const VIEWBOX_WIDTH = 35;
const VIEWBOX_HEIGHT = 40;
const VIEWBOX_TEXT_WIDTH = 138;
const VIEWBOX_TEXT_HEIGHT = 40;

const DEFAULT_LOGO_WIDTH = 35;
const DEFAULT_TEXT_LOGO_WIDTH = 137;

const Svg = styled.svg({ flexShrink: 0 });

const getDimensions = ({
  hasText,
  width,
  height,
}: {
  hasText: boolean;
  width?: number | undefined;
  height?: number | undefined;
}): { width: number; height: number } => {
  // If both width and height are provided, use the provided values
  if (width != null && height != null) {
    return { width, height };
  }

  const viewboxHeight = hasText ? VIEWBOX_TEXT_HEIGHT : VIEWBOX_HEIGHT;
  const viewboxWidth = hasText ? VIEWBOX_TEXT_WIDTH : VIEWBOX_WIDTH;

  // If either width or height is provided,
  // calculate the dimensions by keeping the ratio the same
  if (width != null) {
    return {
      width,
      height: (width * viewboxHeight) / viewboxWidth,
    };
  }

  if (height != null) {
    return {
      width: (height * viewboxWidth) / viewboxHeight,
      height,
    };
  }

  return { width: viewboxWidth, height: viewboxHeight };
};

function LanternLogo({
  hasText = false,
  height,
  width,
}: {
  hasText?: boolean | undefined;
  height?: number | undefined;
  width?: number | undefined;
}) {
  const dimensions = getDimensions({
    hasText,
    width:
      width || height
        ? width
        : hasText
        ? DEFAULT_TEXT_LOGO_WIDTH
        : DEFAULT_LOGO_WIDTH,
    height,
  });
  return (
    <Svg
      width={dimensions.width}
      height={dimensions.height}
      viewBox={`0 0 ${hasText ? VIEWBOX_TEXT_WIDTH : VIEWBOX_WIDTH} ${
        hasText ? VIEWBOX_TEXT_HEIGHT : VIEWBOX_HEIGHT
      }`}
      aria-labelledby="lantern-logo"
    >
      <title id="lantern-logo">Waypoints Logo</title>
      {hasText ? (
        <Fragment>
          <g transform="translate(-5, -4)">
            <path
              d="M57.8475 18.64L56.3561 24.1914L54.7404 18.64H51.8404L50.2247 24.1914L48.7332 18.64H45.4189L48.7332 28.9971H51.6332L53.2904 23.4664L54.9475 28.9971H57.8475L61.1618 18.64H57.8475Z"
              fill="#21374D"
            />
            <path
              d="M69.3025 18.64V19.6136C68.6189 18.8264 67.6039 18.35 66.2161 18.35C63.5025 18.35 61.2654 20.7321 61.2654 23.8186C61.2654 26.905 63.5025 29.2871 66.2161 29.2871C67.6039 29.2871 68.6189 28.8107 69.3025 28.0236V28.9971H72.4097V18.64H69.3025ZM66.8375 26.3457C65.4082 26.3457 64.3725 25.3721 64.3725 23.8186C64.3725 22.265 65.4082 21.2914 66.8375 21.2914C68.2668 21.2914 69.3025 22.265 69.3025 23.8186C69.3025 25.3721 68.2668 26.3457 66.8375 26.3457Z"
              fill="#21374D"
            />
            <path
              d="M81.0071 18.64L79.0599 24.9786L76.7606 18.64H73.3428L77.4649 28.5207C77.0092 29.7843 76.3256 30.24 74.8756 30.24V33.14C77.7756 33.285 79.6192 31.98 80.7792 28.6864L84.3213 18.64H81.0071Z"
              fill="#21374D"
            />
            <path
              d="M91.4527 18.35C90.0649 18.35 89.0499 18.8264 88.3663 19.6136V18.64H85.2592V33.14H88.3663V28.0236C89.0499 28.8107 90.0649 29.2871 91.4527 29.2871C94.1663 29.2871 96.4034 26.905 96.4034 23.8186C96.4034 20.7321 94.1663 18.35 91.4527 18.35ZM90.8313 26.3457C89.402 26.3457 88.3663 25.3721 88.3663 23.8186C88.3663 22.265 89.402 21.2914 90.8313 21.2914C92.2606 21.2914 93.2963 22.265 93.2963 23.8186C93.2963 25.3721 92.2606 26.3457 90.8313 26.3457Z"
              fill="#21374D"
            />
            <path
              d="M102.929 29.2871C105.974 29.2871 108.419 26.905 108.419 23.8186C108.419 20.7321 105.974 18.35 102.929 18.35C99.8844 18.35 97.4401 20.7321 97.4401 23.8186C97.4401 26.905 99.8844 29.2871 102.929 29.2871ZM102.929 26.2629C101.583 26.2629 100.547 25.2893 100.547 23.8186C100.547 22.3479 101.583 21.3743 102.929 21.3743C104.276 21.3743 105.312 22.3479 105.312 23.8186C105.312 25.2893 104.276 26.2629 102.929 26.2629Z"
              fill="#21374D"
            />
            <path
              d="M111.523 17.7286C112.538 17.7286 113.387 16.8793 113.387 15.8643C113.387 14.8493 112.538 14 111.523 14C110.508 14 109.658 14.8493 109.658 15.8643C109.658 16.8793 110.508 17.7286 111.523 17.7286ZM109.969 28.9971H113.076V18.64H109.969V28.9971Z"
              fill="#21374D"
            />
            <path
              d="M121.212 18.35C119.845 18.35 118.809 18.8471 118.25 19.6136V18.64H115.143V28.9971H118.25V23.3421C118.25 21.8714 119.037 21.2086 120.176 21.2086C121.171 21.2086 121.978 21.8093 121.978 23.0936V28.9971H125.086V22.6379C125.086 19.8414 123.304 18.35 121.212 18.35Z"
              fill="#21374D"
            />
            <path
              d="M133.081 21.6229V18.64H130.948V15.74L127.841 16.6721V18.64H126.184V21.6229H127.841V25.2893C127.841 28.1893 129.021 29.4114 133.081 28.9971V26.18C131.714 26.2629 130.948 26.18 130.948 25.2893V21.6229H133.081Z"
              fill="#21374D"
            />
            <path
              d="M137.474 21.6643C137.474 21.2914 137.805 21.0843 138.344 21.0843C139.027 21.0843 139.442 21.4571 139.732 21.9957L142.383 20.5664C141.513 19.0957 140.022 18.35 138.344 18.35C136.19 18.35 134.284 19.4893 134.284 21.7471C134.284 25.3307 139.38 24.73 139.38 25.8693C139.38 26.2836 139.007 26.5114 138.261 26.5114C137.35 26.5114 136.77 26.0764 136.5 25.2893L133.807 26.8221C134.615 28.5 136.19 29.2871 138.261 29.2871C140.498 29.2871 142.57 28.2721 142.57 25.89C142.57 22.0579 137.474 22.845 137.474 21.6643Z"
              fill="#21374D"
            />
            <path
              d="M120.419 34.0542H122.026V37.5042H122.048C122.233 37.2542 122.469 37.0792 122.755 36.9792C123.048 36.8721 123.362 36.8185 123.698 36.8185C124.069 36.8185 124.401 36.8971 124.694 37.0542C124.987 37.2042 125.233 37.4078 125.433 37.6649C125.64 37.9221 125.798 38.2185 125.905 38.5542C126.019 38.8828 126.076 39.2292 126.076 39.5935C126.076 39.9863 126.015 40.3471 125.894 40.6756C125.773 41.0042 125.598 41.2899 125.369 41.5328C125.148 41.7685 124.88 41.9542 124.565 42.0899C124.251 42.2185 123.901 42.2828 123.515 42.2828C123.33 42.2828 123.155 42.2578 122.99 42.2078C122.826 42.1578 122.673 42.0935 122.53 42.0149C122.394 41.9363 122.273 41.8506 122.165 41.7578C122.065 41.6578 121.983 41.5613 121.919 41.4685H121.898V42.1542H120.419V34.0542ZM121.898 39.5506C121.898 39.9435 122.008 40.2613 122.23 40.5042C122.458 40.7471 122.776 40.8685 123.183 40.8685C123.59 40.8685 123.905 40.7471 124.126 40.5042C124.355 40.2613 124.469 39.9435 124.469 39.5506C124.469 39.1578 124.355 38.8399 124.126 38.5971C123.905 38.3542 123.59 38.2328 123.183 38.2328C122.776 38.2328 122.458 38.3542 122.23 38.5971C122.008 38.8399 121.898 39.1578 121.898 39.5506Z"
              fill="#D25564"
            />
            <path
              d="M131.946 41.2542C131.689 41.5828 131.364 41.8363 130.971 42.0149C130.578 42.1935 130.171 42.2828 129.75 42.2828C129.35 42.2828 128.971 42.2185 128.614 42.0899C128.264 41.9613 127.957 41.7792 127.693 41.5435C127.436 41.3006 127.232 41.0113 127.082 40.6756C126.932 40.3399 126.857 39.9649 126.857 39.5506C126.857 39.1363 126.932 38.7613 127.082 38.4256C127.232 38.0899 127.436 37.8042 127.693 37.5685C127.957 37.3256 128.264 37.1399 128.614 37.0113C128.971 36.8828 129.35 36.8185 129.75 36.8185C130.121 36.8185 130.457 36.8828 130.757 37.0113C131.064 37.1399 131.321 37.3256 131.528 37.5685C131.743 37.8042 131.907 38.0899 132.021 38.4256C132.136 38.7613 132.193 39.1363 132.193 39.5506V40.0542H128.464C128.528 40.3613 128.668 40.6078 128.882 40.7935C129.096 40.9721 129.361 41.0613 129.675 41.0613C129.939 41.0613 130.161 41.0042 130.339 40.8899C130.525 40.7685 130.686 40.6149 130.821 40.4292L131.946 41.2542ZM130.586 38.9613C130.593 38.6899 130.503 38.4578 130.318 38.2649C130.132 38.0721 129.893 37.9756 129.6 37.9756C129.421 37.9756 129.264 38.0042 129.128 38.0613C128.993 38.1185 128.875 38.1935 128.775 38.2863C128.682 38.3721 128.607 38.4756 128.55 38.5971C128.5 38.7113 128.471 38.8328 128.464 38.9613H130.586Z"
              fill="#D25564"
            />
            <path
              d="M136.716 38.2328H135.302V39.9685C135.302 40.1113 135.309 40.2435 135.324 40.3649C135.338 40.4792 135.37 40.5792 135.42 40.6649C135.47 40.7506 135.545 40.8185 135.645 40.8685C135.752 40.9113 135.891 40.9328 136.063 40.9328C136.149 40.9328 136.259 40.9256 136.395 40.9113C136.538 40.8899 136.645 40.8471 136.716 40.7828V42.1221C136.538 42.1863 136.352 42.2292 136.159 42.2506C135.966 42.2721 135.777 42.2828 135.591 42.2828C135.32 42.2828 135.07 42.2542 134.841 42.1971C134.613 42.1399 134.413 42.0506 134.241 41.9292C134.07 41.8006 133.934 41.6363 133.834 41.4363C133.741 41.2363 133.695 40.9935 133.695 40.7078V38.2328H132.666V36.9471H133.695V35.4042H135.302V36.9471H136.716V38.2328Z"
              fill="#D25564"
            />
            <path
              d="M140.801 41.5006H140.78C140.601 41.7792 140.362 41.9792 140.062 42.1006C139.769 42.2221 139.458 42.2828 139.13 42.2828C138.887 42.2828 138.651 42.2471 138.422 42.1756C138.201 42.1113 138.005 42.0113 137.833 41.8756C137.662 41.7399 137.526 41.5721 137.426 41.3721C137.326 41.1721 137.276 40.9399 137.276 40.6756C137.276 40.3756 137.33 40.1221 137.437 39.9149C137.551 39.7078 137.701 39.5363 137.887 39.4006C138.08 39.2649 138.297 39.1613 138.54 39.0899C138.783 39.0113 139.033 38.9542 139.29 38.9185C139.555 38.8828 139.815 38.8613 140.072 38.8542C140.337 38.8471 140.58 38.8435 140.801 38.8435C140.801 38.5578 140.697 38.3328 140.49 38.1685C140.29 37.9971 140.051 37.9113 139.772 37.9113C139.508 37.9113 139.265 37.9685 139.044 38.0828C138.83 38.1899 138.637 38.3399 138.465 38.5328L137.608 37.6542C137.908 37.3756 138.258 37.1685 138.658 37.0328C139.058 36.8899 139.472 36.8185 139.901 36.8185C140.372 36.8185 140.758 36.8792 141.058 37.0006C141.365 37.1149 141.608 37.2863 141.787 37.5149C141.972 37.7435 142.101 38.0256 142.172 38.3613C142.244 38.6899 142.28 39.0721 142.28 39.5078V42.1542H140.801V41.5006ZM140.405 39.8721C140.283 39.8721 140.13 39.8792 139.944 39.8935C139.765 39.9006 139.59 39.9292 139.419 39.9792C139.255 40.0292 139.112 40.1042 138.99 40.2042C138.876 40.3042 138.819 40.4435 138.819 40.6221C138.819 40.8149 138.901 40.9578 139.065 41.0506C139.23 41.1435 139.401 41.1899 139.58 41.1899C139.737 41.1899 139.887 41.1685 140.03 41.1256C140.18 41.0828 140.312 41.0221 140.426 40.9435C140.54 40.8649 140.63 40.7649 140.694 40.6435C140.765 40.5221 140.801 40.3792 140.801 40.2149V39.8721H140.405Z"
              fill="#D25564"
            />
            <path
              d="M39.2894 14.5782C39.2278 14.4703 39.1386 14.3806 39.0311 14.3184C38.9236 14.2562 38.8014 14.2236 38.6772 14.224L26.8383 14.2571C26.7461 14.2566 26.6548 14.2746 26.5696 14.31C26.4845 14.3455 26.4074 14.3977 26.3428 14.4635C26.2777 14.5289 26.2261 14.6064 26.1911 14.6918C26.1561 14.7772 26.1383 14.8686 26.1388 14.9609L26.1562 21.2225L19.1975 21.2422C19.1053 21.2418 19.014 21.2598 18.9289 21.2953C18.8438 21.3307 18.7667 21.3828 18.702 21.4486C18.6369 21.5139 18.5853 21.5915 18.5503 21.6769C18.5153 21.7622 18.4975 21.8537 18.498 21.946L18.5175 28.9099C18.5177 29.002 18.5361 29.0933 18.5715 29.1784C18.607 29.2634 18.6589 29.3407 18.7243 29.4057C18.7897 29.4706 18.8672 29.5221 18.9525 29.5571C19.0378 29.592 19.1292 29.6098 19.2213 29.6094L38.7199 29.5551C38.7464 29.5553 38.773 29.5538 38.7994 29.5506C38.916 29.5369 39.0274 29.4942 39.1232 29.4263C39.2191 29.3584 39.2963 29.2676 39.348 29.1621C39.3996 29.0566 39.424 28.9399 39.4188 28.8226C39.4136 28.7052 39.3791 28.5911 39.3184 28.4906L35.3383 21.8992L39.2824 15.2856C39.3461 15.1788 39.3802 15.0571 39.3814 14.9328C39.3826 14.8085 39.3509 14.6862 39.2894 14.5782V14.5782Z"
              fill="#3F4694"
            />
            <path
              d="M27.5134 21.6672C27.462 21.5351 27.3718 21.4217 27.2546 21.3419C27.1375 21.2621 26.9989 21.2197 26.8572 21.2202L19.1971 21.2412C19.1049 21.2408 19.0136 21.2589 18.9285 21.2943C18.8434 21.3298 18.7662 21.3819 18.7016 21.4476C18.6364 21.513 18.5849 21.5906 18.5499 21.6759C18.5148 21.7613 18.4971 21.8528 18.4976 21.945L18.5171 28.9089C18.5176 29.0448 18.5575 29.1776 18.632 29.2912C18.7065 29.4049 18.8124 29.4944 18.9368 29.549C19.0612 29.6037 19.1988 29.621 19.3328 29.599C19.4669 29.577 19.5917 29.5165 19.6921 29.4249L27.3326 22.44C27.4374 22.3446 27.5107 22.2197 27.543 22.0817C27.5753 21.9437 27.5649 21.7992 27.5134 21.6672V21.6672Z"
              fill="#36A6B2"
            />
            <path
              d="M27.3169 7.49653C27.2515 7.43138 27.1739 7.3798 27.0886 7.34479C27.0032 7.30977 26.9117 7.292 26.8195 7.2925L8.71324 7.34327C8.62106 7.34342 8.52982 7.36177 8.44475 7.39725C8.35967 7.43272 8.28243 7.48464 8.21745 7.55002C8.15248 7.61539 8.10103 7.69295 8.06608 7.77824C8.03112 7.86353 8.01334 7.95488 8.01374 8.04705L8.0531 21.9741C8.05327 22.0663 8.07162 22.1575 8.1071 22.2426C8.14258 22.3277 8.19449 22.4049 8.25987 22.4699C8.32524 22.5349 8.4028 22.5863 8.48808 22.6213C8.57337 22.6562 8.66472 22.674 8.7569 22.6737L26.8622 22.6237C26.8888 22.6239 26.9154 22.6224 26.9418 22.6192C27.113 22.5993 27.2709 22.517 27.3853 22.388C27.4997 22.259 27.5625 22.0924 27.5618 21.92L27.5233 7.99206C27.5233 7.89993 27.505 7.80873 27.4695 7.72369C27.4341 7.63866 27.3822 7.56146 27.3169 7.49653V7.49653Z"
              fill="#398984"
            />
            <rect
              x="5.25391"
              y="4.22607"
              width="4.21143"
              height="39.3066"
              rx="2.10571"
              fill="#1ABC9C"
            />
          </g>
        </Fragment>
      ) : (
        <Fragment>
          <path
            d="M34.6283 10.5504C34.5657 10.4408 34.4751 10.3497 34.3658 10.2865C34.2565 10.2233 34.1324 10.1902 34.0062 10.1906L21.9754 10.2247C21.8817 10.2242 21.7889 10.2425 21.7024 10.2786C21.6159 10.3146 21.5375 10.3676 21.4719 10.4345C21.4057 10.5009 21.3533 10.5798 21.3177 10.6665C21.2821 10.7533 21.264 10.8462 21.2645 10.94L21.2825 17.3031L14.211 17.3234C14.1174 17.323 14.0245 17.3413 13.9381 17.3774C13.8516 17.4134 13.7732 17.4664 13.7075 17.5332C13.6413 17.5996 13.5889 17.6784 13.5534 17.7652C13.5178 17.8519 13.4997 17.9449 13.5002 18.0386L13.5204 25.1154C13.5206 25.2091 13.5392 25.3018 13.5753 25.3883C13.6114 25.4747 13.6641 25.5532 13.7306 25.6192C13.797 25.6853 13.8758 25.7376 13.9625 25.7731C14.0492 25.8086 14.142 25.8267 14.2356 25.8263L34.0502 25.7702C34.0772 25.7704 34.1042 25.7689 34.1311 25.7656C34.2496 25.7517 34.3627 25.7083 34.4601 25.6393C34.5575 25.5703 34.6361 25.478 34.6885 25.3708C34.741 25.2636 34.7657 25.145 34.7605 25.0258C34.7552 24.9065 34.7201 24.7905 34.6584 24.6884L30.6135 17.9903L34.6213 11.2694C34.6859 11.1608 34.7206 11.0371 34.7219 10.9108C34.7231 10.7846 34.6908 10.6602 34.6283 10.5504V10.5504Z"
            fill="#3F4694"
          />
          <path
            d="M22.6628 17.7553C22.6105 17.6211 22.5189 17.5058 22.3998 17.4247C22.2808 17.3436 22.14 17.3005 21.9959 17.3011L14.2116 17.3228C14.118 17.3223 14.0251 17.3407 13.9387 17.3767C13.8522 17.4128 13.7738 17.4657 13.7081 17.5325C13.6419 17.5989 13.5895 17.6778 13.5539 17.7645C13.5184 17.8513 13.5003 17.9442 13.5008 18.038L13.521 25.1148C13.5215 25.2529 13.5621 25.3878 13.6378 25.5033C13.7135 25.6187 13.8211 25.7097 13.9475 25.7652C14.0739 25.8208 14.2137 25.8384 14.35 25.816C14.4862 25.7936 14.613 25.7321 14.715 25.6391L22.4791 18.5406C22.5856 18.4436 22.6601 18.3167 22.6929 18.1765C22.7257 18.0362 22.7152 17.8894 22.6628 17.7553V17.7553Z"
            fill="#36A6B2"
          />
          <path
            d="M22.4611 3.35452C22.3947 3.28832 22.3159 3.23591 22.2291 3.20033C22.1424 3.16475 22.0494 3.1467 21.9557 3.14721L3.55594 3.19962C3.46227 3.19979 3.36955 3.21843 3.2831 3.25449C3.19665 3.29055 3.11816 3.34331 3.05213 3.40975C2.9861 3.47619 2.93383 3.555 2.89831 3.64167C2.86279 3.72835 2.84472 3.82118 2.84514 3.91485L2.88577 18.0676C2.88594 18.1613 2.90459 18.254 2.94065 18.3405C2.97671 18.4269 3.02947 18.5054 3.09591 18.5714C3.16235 18.6375 3.24116 18.6898 3.32783 18.7253C3.4145 18.7608 3.50733 18.7789 3.601 18.7785L21.9998 18.7269C22.0268 18.7271 22.0538 18.7256 22.0807 18.7223C22.2547 18.7021 22.4151 18.6184 22.5314 18.4874C22.6476 18.3563 22.7115 18.1869 22.7107 18.0118L22.671 3.85808C22.6709 3.76446 22.6523 3.67178 22.6163 3.58537C22.5803 3.49895 22.5275 3.4205 22.4611 3.35452V3.35452Z"
            fill="#398984"
          />
          <rect
            x="0.0410156"
            y="0.0322266"
            width="4.27969"
            height="39.9438"
            rx="2.13985"
            fill="#1ABC9C"
          />
        </Fragment>
      )}
    </Svg>
  );
}

export default LanternLogo;
