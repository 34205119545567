import moment from 'moment';

export function setMeridiem(date: Date, meridiem: 'AM' | 'PM'): Date {
  const momented = moment(date).local();
  if (meridiem === 'PM' && momented.hour() < 12) {
    momented.hours(momented.hour() + 12);
  } else if (meridiem === 'AM' && momented.hour() >= 12) {
    momented.hours(momented.hour() - 12);
  }
  return momented.toDate();
}

export function setHoursWithMeridiem(date: Date, hours: string) {
  const momented = moment(date).local();
  // we are going to receive a 12 hour date format and we have to update an existing date
  // with a given meridem
  const parsed = parseInt(hours, 10);
  if (Number.isNaN(parsed)) return date;
  const meridiem = momented.format('A');

  // 12 is a special snowflake. We don't want to change the meridiem when entering 12
  if (parsed === 12) {
    if (meridiem === 'AM') return momented.hours(0).toDate();
    return momented.hours(12).toDate();
  }
  if (meridiem === 'AM') {
    return momented.hours(parsed).toDate();
  }
  return momented.hours((parsed + 12) % 24).toDate();
}
