import styled from '@emotion/styled';
import { useEffect } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import type { QuestionSetSkillsDemonstrated } from 'ms-pages/Lantern/components/StudentSkillsDemonstrated';
import { QuestionSetStudentSkillsDemonstrated } from 'ms-pages/Lantern/components/StudentSkillsDemonstrated';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { VStack } from 'ms-pages/Lantern/primitives/Stack';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import MiloAnimated from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/Milo/MiloAnimated';

import type { QuestionSetCompleteQuery } from './__generated__/QuestionSetCompleteQuery.graphql';

const QuestionSetRoot = styled(VStack)({
  textAlign: 'center',
});
const MiloWrapper = styled.div({
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 9,
});
type Props = {
  checkInId: string;
  onContinue: () => void;
};
function QuestionSetCompletePresentational({
  onContinue,
  skillsDemonstrated,
  checkInId,
}: {
  onContinue: () => void;
  skillsDemonstrated: QuestionSetSkillsDemonstrated;
  checkInId: string;
}) {
  const { trackStructEvent } = useSnowplow();
  useEffect(() => {
    trackStructEvent({
      category: 'student_check_in',
      action: 'discovered_skills',
      value: skillsDemonstrated.length,
    });
  }, [skillsDemonstrated.length, trackStructEvent]);
  return (
    <QuestionSetRoot>
      <HeadingS>Some skills you've collected!</HeadingS>
      <QuestionSetStudentSkillsDemonstrated
        skills={skillsDemonstrated.map(({ id, title }) => ({
          id,
          title,
        }))}
        checkInId={checkInId}
        primaryButton={{
          label: 'Continue',
          onClick: onContinue,
        }}
      />
      <MiloWrapper>
        <MiloAnimated variant="adventurer" width={225} />
      </MiloWrapper>
    </QuestionSetRoot>
  );
}
function QuestionSetComplete({ onContinue, checkInId }: Props) {
  const { props, error } = useQuery<QuestionSetCompleteQuery>(
    graphql`
      query QuestionSetCompleteQuery($checkInId: ID!) {
        lantern {
          checkIn(id: $checkInId) {
            skillsDemonstrated {
              id
              title
            }
          }
        }
      }
    `,
    { checkInId },
  );
  if (error != null)
    throw new Error(`QuestionSetCompleteQuery failed: ${error.message}`);
  if (props == null) return <LoadingSpinner />;
  if (props.lantern.checkIn == null) {
    throw new Error(
      `QuestionSetCompleteQuery failed: No checkin found with id ${checkInId}`,
    );
  }
  const { skillsDemonstrated } = props.lantern.checkIn;
  return (
    <QuestionSetCompletePresentational
      onContinue={onContinue}
      checkInId={checkInId}
      skillsDemonstrated={skillsDemonstrated.map(({ id, title }) => ({
        id,
        title,
      }))}
    />
  );
}
export { QuestionSetComplete };
