import { graphql, useQuery } from 'relay-hooks';

import { InvariantViolation } from 'ms-utils/app-logging';

import type { useTemporaryLearningFocusQuery } from './__generated__/useTemporaryLearningFocusQuery.graphql';

const QUERY = graphql`
  query useTemporaryLearningFocusQuery {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          lastActiveStrandStatus {
            strand {
              id
              substrands {
                id
              }
            }
            lastWorkedOnSubstrandGrowthCheckIn {
              substrand {
                id
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * As soon as the student starts a check-in or a task, the learning
 * focus will be set (in most cases), but until then we try to guess
 * what their learning focus could be (since they have worked on a strand).
 * The idea is that we don't want to show an "empty" dashboard for
 * the student who has used Lantern before.
 * This hook is also used in the Substrand View of the Skills Map so that
 * there is consistency between what substrand we use as the learning focus.
 **/
export default function useTemporaryLearningFocus():
  | {
      substrandId: string;
      strandId: string;
    }
  | null
  | undefined {
  const { props, error } = useQuery<useTemporaryLearningFocusQuery>(QUERY, {});
  if (props == null) return undefined;
  if (error != null) return null;
  const {
    lantern: { viewer },
  } = props;
  // The student doesn't have a Lantern profile
  if (viewer == null) return null;
  if (viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('User is not a student');
  }
  const { lastActiveStrandStatus } = viewer;
  const strand = lastActiveStrandStatus?.strand;
  const substrand =
    lastActiveStrandStatus?.lastWorkedOnSubstrandGrowthCheckIn?.substrand ||
    strand?.substrands[0];
  return strand != null && substrand != null
    ? {
        strandId: strand.id,
        substrandId: substrand.id,
      }
    : null;
}
