import { map } from 'ramda';
import { useState, useMemo } from 'react';

import useCreateWorksheetTask from 'ms-pages/Teacher/components/CreateTask/CreateWorksheetTask/state/createWorksheetTask';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { CreateWorksheetTaskVariables } from 'ms-pages/Teacher/components/CreateTask/types';

import type { State } from './State';
import { getInitialState } from './State';
import * as updaters from './updaters';

type Updaters = typeof updaters;
type CallbackUpdater<I extends readonly any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};
export const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export function createWorksheetTaskVariables({
  selectedStudents,
  selectedClasses,
  excludedStudents,
  taskDueDate,
  taskName,
  activeSubtopic: selectedSubtopic,
  taskStartDate,
}: State) {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );

  return {
    title: taskName,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    startDate: taskStartDate.toISOString(),
    subtopicId: selectedSubtopic != null ? selectedSubtopic[0] : '',
  };
}

export type CombinedState = {
  values: State;
  updaters: TransformedUpdaters;
};

export function useCreateWorksheetTaskState({
  selectedClasses = [],
  selectedStudents = [],
  excludedStudents = [],
  taskName: providedTaskName,
  taskStartDate: providedTaskStartDate,
  taskDueDate: providedTaskDueDate,
  activeSubtopic: providedActiveSubtopic,
}: CreateWorksheetTaskVariables) {
  const {
    taskStartDate,
    taskName,
    taskDueDate,
    activeSubtopic,
    ...initialState
  } = getInitialState();

  const [state, setState] = useState<State>({
    ...initialState,
    selectedClasses,
    selectedStudents,
    excludedStudents,
    taskName: providedTaskName != null ? providedTaskName : taskName,
    taskStartDate:
      providedTaskStartDate != null ? providedTaskStartDate : taskStartDate,
    taskDueDate:
      providedTaskDueDate != null ? providedTaskDueDate : taskDueDate,
    activeSubtopic:
      providedActiveSubtopic != null ? providedActiveSubtopic : activeSubtopic,
  });

  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);
  const [createWorksheetTask, mutationState] = useCreateWorksheetTask();

  const mutation = [
    () => {
      createWorksheetTask({ variables: createWorksheetTaskVariables(state) });
    },
    mutationState,
  ] as const;

  return [{ values: state, updaters: transformedUpdaters }, mutation] as const;
}
