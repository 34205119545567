/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FolderViewHeaderQueryVariables = {
    templateGroupId: string;
};
export type FolderViewHeaderQueryResponse = {
    readonly taskTemplateGroup: {
        readonly id: string;
        readonly title: string;
        readonly isArchived: boolean;
        readonly isDistrictShared: boolean;
        readonly owningSchool: {
            readonly id: string;
        } | null;
        readonly ancestorGroups: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
            readonly owningSchool: {
                readonly id: string;
            } | null;
        }>;
    } | null;
};
export type FolderViewHeaderQuery = {
    readonly response: FolderViewHeaderQueryResponse;
    readonly variables: FolderViewHeaderQueryVariables;
};



/*
query FolderViewHeaderQuery(
  $templateGroupId: ID!
) {
  taskTemplateGroup(id: $templateGroupId) {
    id
    title
    isArchived
    isDistrictShared
    owningSchool {
      id
    }
    ancestorGroups {
      id
      title
      owningSchool {
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "templateGroupId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "School",
  "kind": "LinkedField",
  "name": "owningSchool",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "templateGroupId"
      }
    ],
    "concreteType": "TaskTemplateGroup",
    "kind": "LinkedField",
    "name": "taskTemplateGroup",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isArchived",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDistrictShared",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplateGroup",
        "kind": "LinkedField",
        "name": "ancestorGroups",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderViewHeaderQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FolderViewHeaderQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d19565c868f3c5b688fb66295f006a94",
    "id": null,
    "metadata": {},
    "name": "FolderViewHeaderQuery",
    "operationKind": "query",
    "text": "query FolderViewHeaderQuery(\n  $templateGroupId: ID!\n) {\n  taskTemplateGroup(id: $templateGroupId) {\n    id\n    title\n    isArchived\n    isDistrictShared\n    owningSchool {\n      id\n    }\n    ancestorGroups {\n      id\n      title\n      owningSchool {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6fa7038e27c536fa0a211785c38d4c87';
export default node;
