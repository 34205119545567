import { useCallback, memo, type ComponentType } from 'react';

import keyDownMap, { type Action } from 'ms-utils/keyDownMap';

import {
  type ButtonStyleProps,
  type CommonProps,
  useButtonStyle,
  useCommonProps,
} from '.';

export type Props = ButtonStyleProps &
  CommonProps & {
    target?: string | undefined;
    href: string;
    rel?: string | undefined;
    trackingId?: string | undefined;
    role?: string | undefined;
  };

const AnchorButton = (props: Props) => {
  const cssStyles = useButtonStyle(props);
  const commonProps = useCommonProps(props);
  const {
    href,
    target,
    children,
    onClick,
    isDisabled,
    trackingId,
    role = 'button',
  } = props;
  const trackingProps = trackingId ? { 'data-tracking-id': trackingId } : {};

  const action = useCallback<Action<HTMLAnchorElement>>(
    e => {
      if (isDisabled) return;

      if (onClick != null) {
        onClick(e);
      }
      if (href != null) {
        if (target === '_blank' && window != null) {
          const newWin = window.open(href, '_blank');
          if (newWin != null) newWin.focus();
        } else {
          window.location.assign(href);
        }
      }
    },
    [href, isDisabled, onClick, target],
  );

  return (
    <a
      {...commonProps}
      {...trackingProps}
      onKeyDown={keyDownMap({
        SPACE: [action, { preventDefault: true, stopPropagation: true }],
        ENTER: [action, { stopPropagation: true }],
      })}
      css={cssStyles}
      href={href}
      target={target}
      role={role}
    >
      {children}
    </a>
  );
};

// TODO remove ComponentType typing once Flow backwards compat no longer required
const MemoizedAnchorButton: ComponentType<Props> = memo<Props>(AnchorButton);

export default MemoizedAnchorButton;
