import styled from '@emotion/styled';

import { VStack } from 'ms-pages/Lantern/primitives/Stack';
import { breakpoints, colors, PAGE_PADDING } from 'ms-pages/Lantern/styles';

const Root = styled(VStack)({
  padding: PAGE_PADDING,

  '@media (min-width: 768px)': {
    paddingTop: 1.5 * PAGE_PADDING,
  },
});

const Overview = styled.div({
  display: 'flex',
  color: colors.grey90,
  fontSize: 16,

  flexDirection: 'column',

  [`@media (min-width: ${breakpoints.large}px)`]: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
});

const OverviewMain = styled.div({
  paddingTop: 8,
});

const InfoIconWrapper = styled.div({
  float: 'right',

  // According to the design, the `InfoIcon` is vertically centered
  // within the title's line height on screens < 768px only
  // (it is shown at the very top of the title otherwise).
  [`@media (min-width: ${breakpoints.large}px)`]: {
    display: 'flex',
  },
});

export { InfoIconWrapper, Overview, OverviewMain, Root };
