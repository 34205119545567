const WIDTH = 210;
const HEIGHT = 223;

export default function EmptyContentBrowsingIllustration() {
  return (
    <svg
      width={WIDTH}
      height={HEIGHT}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <polygon
          id="path-d3c6227661"
          points="0.833906667 0.0225777778 181.671533 0.0225777778 181.671533 15.8883556 0.833906667 15.8883556"
        />
        <polygon
          id="path-4a9d13dc2c"
          points="0.251448387 0.6873 26.3830935 0.6873 26.3830935 3 0.251448387 3"
        />
      </defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.801897321"
      >
        <g transform="translate(-800.000000, -241.000000)">
          <g transform="translate(800.000000, 240.500000)">
            <g>
              <g>
                <g transform="translate(28.000000, 207.000000)">
                  <mask id="mask-be3726993e" fill="white">
                    <use xlinkHref="#path-d3c6227661" />
                  </mask>
                  <g />
                  <path
                    d="M176.599107,15.8883556 L0.833906667,15.8883556 L0.833906667,0.0225777778 L176.599107,0.0225777778 C179.400173,0.0225777778 181.671707,2.35146667 181.671707,5.22524444 L181.671707,10.6856889 C181.671707,13.5594667 179.400173,15.8883556 176.599107,15.8883556"
                    fill="#7A7E92"
                    mask="url(#mask-be3726993e)"
                  />
                </g>
                <rect fill="#FDFEFE" x="38" y="207" width="3" height="16" />
                <path
                  d="M190.92285,208 L15,208 L15,182 L190.92285,182 C193.726429,182 196,184.237918 196,186.998587 L196,203.001413 C196,205.762082 193.726429,208 190.92285,208"
                  fill="#ACAFB9"
                />
                <rect fill="#DBDEE0" x="24" y="182" width="28" height="26" />
                <rect fill="#DBDEE0" x="59" y="182" width="4" height="26" />
                <path
                  d="M153,55.8624559 C153,60.6599388 147.956922,75.849041 143.569253,70.2961718 C138.410784,63.7678067 133.422213,35.8434786 127.753793,35.8434786 C117.264817,35.8434786 112,21.4888689 112,11.0511093 C112,0.612488794 113.937757,3.4997668 124.112712,0.968018256 C147.530161,-4.85732768 153,45.4246963 153,55.8624559 L148.019878,48.4382795 L153,55.8624559 Z"
                  fill="#7A7E92"
                />
                <path
                  d="M119.181937,8.5 C119.181937,8.5 115.10976,13.3887921 114.513335,15.2219802 C113.917759,17.0560396 111.335797,14.9170297 111.037584,16.1394455 C110.739371,17.3609901 112.32899,18.3795248 111.831968,20.3155248 C111.335797,22.2506535 110.739371,27.6482772 115.605932,31.5185347 C120.473343,35.3887921 120.770706,41.5 120.770706,41.5 L131,37.8336238 C131,37.8336238 126.332247,32.8428911 127.027228,30.7038812 C127.723058,28.5648713 131.69583,26.7316832 128.915059,25.0004356 C126.134288,23.2691881 124.842883,26.527802 124.842883,26.527802 C122.558284,26.8336238 123.750285,17.9726337 123.750285,17.9726337 C123.750285,17.9726337 118.386703,11.0467723 119.181937,8.5"
                  fill="#ACAFB9"
                />
                <path
                  d="M118,33.7002127 C118,33.7002127 123.363791,33.2587453 124,32.5 L123.564748,39.033546 L121.14493,40.5 C121.14493,40.5 119.40392,33.9222222 118,33.7002127"
                  fill="#7A7E92"
                />
                <path
                  d="M112,23.4998559 C112,23.4998559 115.259749,23.5551416 116,21.5"
                  stroke="#7A7E92"
                  strokeLinecap="round"
                />
                <path
                  d="M121,15.5 C121,15.5 120.28263,13.0807348 119,12.5"
                  stroke="#7A7E92"
                  strokeLinecap="round"
                />
                <path
                  d="M117.927239,16.5251794 C117.927239,16.5251794 118.415491,15.048652 116.862426,15.6395859 C115.31021,16.2297124 116.197554,17.7901976 116.862426,17.4527518 C117.528146,17.115306 117.927239,16.5251794 117.927239,16.5251794"
                  fill="#818598"
                />
                <path
                  d="M81,53.7946627 L67.2958677,46.3908501 L67.2873579,46.4039758 L66.1598135,45.2576581 C65.3598952,44.44386 64.1361903,44.2662245 63.1473552,44.8192572 L61.3441351,45.5114231 C60.6369732,45.4405439 59.9230036,45.4309183 59.2115869,45.5017975 L56,45.8194413 L63.6051807,50.9647454 C63.9889713,51.1677574 64.6357138,51.2482622 65.1931037,51.1633822 L78.9695691,59.5 L81,53.7946627 Z"
                  fill="#ACAFB9"
                />
                <path
                  d="M124.593522,44.5321014 C122.486906,42.0984116 118.779607,41.8096399 116.312104,43.8881149 L99.626668,61.124976 L73.8793298,49.7078448 L71,54.7856273 L98.2064289,69.828164 C100.110154,70.875068 102.559527,70.7140714 104.314752,69.2352875 L104.574626,69.017218 L123.939953,52.7020413 C126.407457,50.6235664 126.699275,46.9657911 124.593522,44.5321014"
                  fill="#ACAFB9"
                />
                <path
                  d="M154.653363,190.5 C154.419449,190.324071 140,180.362103 140,180.362103 L144.685292,173.5 L158,185.598624 L154.653363,190.5 Z"
                  fill="#ACAFB9"
                />
                <path
                  d="M151.886041,190.455221 L153.09664,198.989476 C153.165916,199.471901 153.154659,199.962234 153.063734,200.440264 L150,210.5 L165,188.632729 L160.959474,186.681063 L157.087807,183.686341 C156.811569,183.401631 156.359543,183.449083 156.143921,183.788274 L151.886041,190.455221 Z"
                  fill="#5B617B"
                />
                <path
                  d="M150,209.450424 L151.098351,210.262378 C151.648398,210.670516 152.413757,210.539946 152.807768,209.970109 L166.77077,190.083725 C167.164782,189.513888 167.038384,188.720957 166.487465,188.313683 L165.389986,187.5 L150,209.450424 Z"
                  fill="#FAFAFA"
                />
                <path
                  d="M127.654625,212.5 C127.654625,212.20465 127,194.5 127,194.5 L135,194.5 L133.368662,212.5 L127.654625,212.5 Z"
                  fill="#ACAFB9"
                />
                <path
                  d="M123.155902,37.5 L119.898871,38.9923297 C114.33449,41.4034155 108.687426,52.7168032 109.013474,56.5683115 C111.508301,64.2315341 112.857076,69.3742469 113.059797,71.9964498 C113.364975,75.9439357 110.599028,78.8754093 110.840568,80.1822739 C111.77865,85.2578198 111.169618,92.6970618 109.013474,102.5 L139.999996,94.5969678 L139.999996,51.5936484 C139.999996,46.1935776 140.025873,42.3749438 135.941646,40.9924841 L123.155902,37.5 Z"
                  fill="#7A7E92"
                />
                <path
                  d="M112.184453,94.5 L101.239111,145.419866 C100.407268,148.982008 101.789032,152.706067 104.711792,154.781687 L145.36418,185.5 L151,176.516269 L117.52617,145.861688 L140.800358,94.5 L112.184453,94.5 Z"
                  fill="#5B617B"
                />
                <polygon
                  fill="#5B617B"
                  points="154 177.476035 146.440658 189.5 141 185.523086 148.559342 173.5"
                />
                <path
                  d="M127.072529,211.702149 L121.290391,217.455347 C120.964215,217.780905 120.58496,218.044211 120.22882,218.216664 L111,221.5 L136,221.5 L135.156736,217.082681 L135.202966,212.22119 C135.260325,211.835064 134.964112,211.489317 134.583145,211.500253 L127.072529,211.702149 Z"
                  fill="#5B617B"
                />
                <g transform="translate(110.000000, 220.500000)">
                  <mask id="mask-14875957d1" fill="white">
                    <use xlinkHref="#path-4a9d13dc2c" />
                  </mask>
                  <g />
                  <path
                    d="M0.251448387,0.6873 L0.251448387,1.8993 C0.251448387,2.50755 0.800158065,3.0003 1.47602903,3.0003 L25.1585129,3.0003 C25.8343839,3.0003 26.3830935,2.50755 26.3830935,1.8993 L26.3830935,0.6873 L0.251448387,0.6873 Z"
                    fill="#FFFFFF"
                    mask="url(#mask-14875957d1)"
                  />
                </g>
                <polygon
                  fill="#D4D7D9"
                  points="104 73.0141498 18.3793328 22.5 0 52.9858502 85.6197977 103.5"
                />
                <path
                  d="M37.3750458,54.8892605 C34.1218663,60.3436727 27.0639184,62.1282252 21.6103601,58.8750458 C16.1559479,55.6218663 14.3722492,48.5639184 17.6245748,43.1103601 C20.8769004,37.6559479 27.9348483,35.8722492 33.3892605,39.1245748 C38.8436727,42.3769004 40.6282252,49.4357022 37.3750458,54.8892605"
                  fill="#FAFAFA"
                />
                <path
                  d="M44,54.5 L59,63.5"
                  stroke="#FAFAFA"
                  strokeWidth="2.288"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M42,60.5 L76,80.5"
                  stroke="#FAFAFA"
                  strokeWidth="2.288"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M129.095571,35.8787486 L118.896806,36.9062386 L116,43.5 L133,39.9735737 L131.131093,36.1923769 C130.765246,35.4238615 129.696795,35.2599003 129.095571,35.8787486"
                  fill="#7A7E92"
                />
                <path
                  d="M142.685173,103.092681 L140.398573,94.5 L112,94.5 L120.571297,112.207259 L126.098686,201.5 L136.539503,201.5 L139.671489,114.661816 C142.648905,111.386765 143.763709,107.144951 142.685173,103.092681 L162,102.406253"
                  fill="#646981"
                />
                <path
                  d="M116,86.8151116 L113.39414,81.5 L98.9006845,85.984043 L96.6768534,84.9886244 L93.3910511,87.3530613 L86.45757,88.9906307 C86.1281135,89.0685699 85.9344702,89.3837152 86.0203392,89.6929303 C86.1088368,90.0038398 86.4461792,90.1919103 86.7747594,90.1148183 L90.7878194,89.166841 L90.9683194,89.7818826 L87.6492209,90.6442963 C87.3223931,90.7290128 87.1357596,91.0475467 87.2295145,91.3567619 C87.3250218,91.6651298 87.6676214,91.8464231 87.9944492,91.7617066 L91.2977759,90.9035287 L91.3967881,91.2423946 L88.7874229,92.0632973 C88.4667286,92.1641099 88.2967431,92.4919626 88.4106509,92.7969419 C88.5236825,93.0993798 88.8750443,93.2637297 89.1957386,93.1629171 L91.7262445,92.3657351 L91.855048,92.8062608 L90.237557,93.378097 C89.9203675,93.4899227 89.7644015,93.8228585 89.8879476,94.1236019 C90.01237,94.4226511 90.3698653,94.5751408 90.6870548,94.463315 L92.1862569,93.933837 L98.8121869,91.9209736 C99.234522,91.7871216 99.7584979,91.383024 100.101098,90.9289437 L116,86.8151116 Z"
                  fill="#ACAFB9"
                />
                <polygon
                  fill="#6432B2"
                  points="144 69.5 138 71.6520624 140.170928 77.5"
                />
                <path
                  d="M106,83.9997225 L107.019192,89.5 L146.673278,81.1817221 C146.901016,81.1260407 147.159927,81.0468687 147.384201,80.9607366 C150.289374,79.8392785 151.739795,76.5636466 150.62362,73.6455937 L150.521441,73.377627 L139.660166,44.974027 C138.492902,41.9219907 132.963982,38.8047028 132.048702,41.571372 C131.020851,44.6729995 129.273418,48.9517665 130.439816,52.0029327 L137.34209,71.9186005 L106,83.9997225 Z"
                  fill="#ACAFB9"
                />
                <polygon
                  fill="#5B617B"
                  points="125 204.5 138 204.5 138 198.5 125 198.5"
                />
                <path
                  d="M59.9596316,49.5 C59.6103325,49.5 59.2742644,49.3301866 59.1049073,49.0351652 C58.8641026,48.6172834 59.0519832,48.104713 59.5238898,47.8910768 L64.606368,45.592727 C65.0782745,45.3798733 65.6560294,45.5457739 65.8950699,45.9644383 C66.1358746,46.3831026 65.947994,46.8948905 65.4769695,47.1085267 L60.3944913,49.4068765 C60.2551245,49.4694805 60.106055,49.5 59.9596316,49.5"
                  fill="#ACAFB9"
                />
              </g>
              <path
                d="M109.829323,101 C116.089966,105.422186 123.117739,105.422186 130.912644,101 C138.707548,96.5778135 144.925667,94.5832903 149.567,95.0164302 C146.590576,86.7482802 145.430243,81.0732181 146.086,77.991244 C146.546241,75.8281706 140.778085,81.568248 129.698161,85.5621 C126.560235,86.6931922 120.964814,87.3371581 112.9119,87.4939976 L109.829323,101 Z"
                fill="#7A7E92"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
