/**
 * @module toLatex
 * @memberof math
 */

import * as msnumber from './number';

export type Mode = 'decimal' | 'fraction' | 'improper' | 'simplified';

const MODES: Mode[] = ['decimal', 'fraction', 'improper', 'simplified'];

/**
 * Convert a js number into a string representation.  If mode is set to
 * 'fraction' or 'improper', we use ms-utils/math/number to guess whether it
 * should be interpreted as a fraction.
 * @function
 * @param {number} number - Number to be interpreted as Latex
 * @param {string} mode - Should be one of MODES: 'decimal', 'fraction', 'improper', 'simplified'
 * @param {number} fixedDenominator - the fixed denominator to render fractions with
 * @return {string} Num, serialized in Latex format
 */
const toLatex = (
  number: number,
  mode: Mode = 'fraction',
  fixedDenominator: number = 10,
): string => {
  // Validate the mode string.
  if (MODES.indexOf(mode) === -1) {
    throw new Error(`Mode should be one of (${MODES.join(', ')})`);
  }

  // Handle null cases.
  if (!msnumber.is(number)) return '';
  if (msnumber.equal(number, 0)) return '0';

  const fraction = msnumber.toFraction(number);
  const scaling = Math.max(fixedDenominator / fraction[1], 1);

  if (mode === 'improper') {
    const numerator = fraction[0] * scaling;
    const denominator = fraction[1] * scaling;
    return `\\frac{${numerator}}{${denominator}}`;
  } else if (mode === 'fraction') {
    // Extract the largest possible whole part from the fraction.
    const sign = msnumber.sign(number) === -1 ? '-' : '';
    const quotient = Math.floor(Math.abs(fraction[0] / fraction[1]));
    const numerator =
      Math.abs(quotient !== 0 ? fraction[0] % fraction[1] : fraction[0]) *
      scaling;
    const denominator = fraction[1] * scaling;

    if (msnumber.equal(fraction[1], 1)) {
      // Fraction is a whole number.
      return `${fraction[0]}`;
    } else if (msnumber.equal(quotient, 0)) {
      // Fraction has no whole part.
      return `${sign}\\frac{${numerator}}{${denominator}}`;
    }
    // Fraction as a mixed number.
    return `${sign}${quotient}\\frac{${numerator}}{${denominator}}`;
  } else if (mode === 'simplified') {
    // Extract the largest possible whole part from the fraction.
    const sign = msnumber.sign(number) === -1 ? '-' : '';
    const quotient = Math.floor(Math.abs(fraction[0] / fraction[1]));
    const numerator = Math.abs(
      quotient !== 0 ? fraction[0] % fraction[1] : fraction[0],
    );
    const denominator = fraction[1];

    if (msnumber.equal(fraction[1], 1)) {
      // Fraction is a whole number.
      return `${fraction[0]}`;
    } else if (msnumber.equal(quotient, 0)) {
      // Fraction has no whole part.
      return `${sign}\\frac{${numerator}}{${denominator}}`;
    }
    // Fraction as a mixed number.
    return `${sign}${quotient}\\frac{${numerator}}{${denominator}}`;
  }
  return `${msnumber.round(number, 9)}`;
};

export default toLatex;
