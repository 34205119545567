import { forwardRef } from 'react';

import diagnosePhaseIcon from './diagnose_phase.svg';

type Props = {
  height: number;
  width: number;
  marginLeft?: number | undefined;
};

// Forward ref to ensure this component is compatible with tooltip library
// @tippyjs/react
const DiagnosePhaseIcon = forwardRef<HTMLImageElement, Props>(
  ({ height, width, marginLeft = 0 }, ref) => {
    return (
      <img
        src={diagnosePhaseIcon}
        role="presentation"
        alt="Progress animation"
        css={{ height, width, marginLeft }}
        ref={ref}
      />
    );
  },
);

DiagnosePhaseIcon.displayName = 'DiagnosePhaseIcon';

export default DiagnosePhaseIcon;
