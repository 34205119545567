// Discrepancies between how the old REST endpoint supplied number builder
// data, and how GraphQL supplies number builder data requires a bunch of
// data adapters between the different formats.
import type {
  DropZoneItem,
  Value as InternalValue,
} from 'ms-components/math/NumberBuilder';
import type { subproblemFragments_NumberBuilderInputConfig } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_NumberBuilderInputConfig.graphql';
import { unwrap } from 'ms-utils/typescript-utils';

type DropZoneItemList = ReadonlyArray<{
  readonly key: string;
  readonly value: ReadonlyArray<DropZoneItem>;
}>;
type GraphQlValue = NonNullable<
  subproblemFragments_NumberBuilderInputConfig['value']
>;
type GraphQlValueWithNumbers = {
  itemsBankItems: GraphQlValue['itemsBankItems'];
  values: DropZoneItemList;
};
export function toInternalValueFormat(data: { value: GraphQlValue | null }): {
  value: InternalValue;
} {
  return {
    value:
      data.value == null
        ? { itemsBankItems: [], values: {} }
        : {
            ...data.value,
            values: listToObjectMap(data.value.values),
          },
  };
}
function listToObjectMap(
  list: ReadonlyArray<{
    readonly key: string;
    readonly value: ReadonlyArray<{
      readonly data: {
        readonly value: number;
      };
    }>;
  }>,
): {
  [dropZoneId: string]: ReadonlyArray<DropZoneItem>;
} {
  const objectMap: {
    [dropZoneId: string]: ReadonlyArray<DropZoneItem>;
  } = {};
  for (const { key, value } of list) {
    objectMap[key] = value.map(v => ({
      ...v,
      data: {
        value: v.data.value,
      },
    }));
  }
  return objectMap;
}
export function fromInternalValueFormat(data: InternalValue): {
  value: GraphQlValueWithNumbers;
} {
  return {
    value: {
      ...data,
      values: objectMapToList(data.values),
    },
  };
}
function objectMapToList(objectMap: {
  [dropZoneId: string]: ReadonlyArray<DropZoneItem>;
}) {
  return Object.keys(objectMap).map(key => ({
    key,
    value: unwrap(objectMap[key]),
  }));
}
