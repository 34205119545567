import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M6.00133 0.5C6.55361 0.5 7 0.947709 7 1.49999C7 3.03086 7 5.85594 7 6.59375C7 7.5 7.5 8 8.3125 8C8.89109 8 11.5297 8 13.0011 8C13.5534 8 14 8.44593 14 8.99822C14 10.2985 14 12.3505 14 13.801C14 14.7399 13.2389 15.5 12.3 15.5L2.39999 15.5C1.07451 15.5 0 14.4304 0 13.1049C0 10.0751 0 4.92434 0 2.19439C0 1.25551 0.760412 0.500002 1.6993 0.500002C3.02641 0.500001 4.83519 0.5 6.00133 0.5ZM13.8856 5.32971C14.1207 5.58627 13.9387 6 13.5907 6H10.5C9.67157 6 9 5.32843 9 4.5V1.02832C9 0.663517 9.44835 0.489115 9.69486 0.758031L13.8856 5.32971Z" />
);

const PaperSheet = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => {
  const sizeToNumber = typeof size === 'string' ? parseFloat(size) : size;
  return (
    <Icon
      color={color}
      // the original size of this icon is larger than we use,
      // so we need to scale it down a little for consistency.
      size={sizeToNumber ? sizeToNumber * 0.9 : undefined}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={16}
      viewBoxWidth={14}
    >
      {path}
    </Icon>
  );
};
PaperSheet.displayName = 'PaperSheet';

export default PaperSheet;
