import { StyleSheet } from 'aphrodite';

import { transition, fontFamily, fontWeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { onHover } from 'ms-utils/emotion';

export default StyleSheet.create({
  link: {
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
    transition: `color ${transition}`,
    fontFamily: fontFamily.body,

    color: colors.matisse,
    ...onHover({
      color: alternateColors.matisse,
    }),
  },
  active: {
    fontWeight: fontWeight.semibold,
  },
});
