import emotionStyled from '@emotion/styled';

// Usage inside an emotion.styled declaration:
//  ...onHover({
//    myCssProp: myCssValue
//  })
export const onHover = (decl: { [key: string]: any }) => ({
  '@media(hover: hover)': {
    ':hover': allowNestedClassNames(decl),
  },
});

// same as above, but for `pressed` state
export const onPress = (decl: { [key: string]: any }) => ({
  ':active': allowNestedClassNames(decl),
});

// same as above, but for `focus` state
export const onFocus = (decl: { [key: string]: any }) => ({
  ':focus': allowNestedClassNames(decl),
});

// Util shorthand to add an alternate style which is identical for hover on pointer devices and for
// active (pressed) on touch devices. NB active state will be interpreted also by pointer devices
//
// Usage is identical to the `onHover` util above
export const onPressOrHover = (decl: { [key: string]: any }) => ({
  ...onHover(decl),
  ...onPress(decl),
});

export const minBreak = (width: number, style: {} | undefined) => ({
  [`@media (min-width: ${width}px)`]: { ...style },
});

export const maxBreak = (width: number, style: {}) => ({
  [`@media (max-width: ${width}px)`]: style,
});

// It improves tap UX on "clickable" elements on touch devices
//
// It doesn't fix all the possible issues on all browsers or devices, feel free to add rules
export const tappable = {
  cursor: 'pointer',
  userSelect: 'none' as 'none', // to prevent the text selection (e.g. selecting text triggers the copy-paste popup menu on iOS and Android)
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', // to prevent the default tap highlight colour being applied
  WebkitTouchCallout: 'none' as 'none', // to prevent the context menu from showing, at least on iOS for anchor elements
};

// this was obtained by testing different browsers with https://muffinman.io/get-scrollbar-width-in-javascript/
// the actual values differ by 1-3 pixels on different devices, but for the purposes of visual alignment, it
// should be ok to be off by this margin

const sharedScrollbarStyles = {
  // for firefox ^64
  // C1C1C1 is the scrollbar thumb color on chrome
  scrollbarColor: `#C1C1C1 transparent`,
  WebkitOverflowScrolling: 'touch',
  scrollbarWidth: 'thin',
  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    backgroundColor: '#C1C1C1',
  },
} as const;

export const VERTICAL_SCROLLBAR_WIDTH = 12;
// contains and scrollbar styles for various browsers
export const styledVerticallyScrollable = {
  // overflow: auto causes width calculation issues in some edge cases where the scroll
  // track popping in and out of existence causes inconsistent width measurements.
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: 8,
    backgroundColor: 'transparent',
  },
  ...sharedScrollbarStyles,
} as const;

export const styledHorizontallyScrollable = {
  // overflow: auto causes height calculation issues in some edge cases where the scroll
  // track popping in and out of existence causes inconsistent height measurements.
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    height: 8,
    backgroundColor: 'transparent',
  },
  ...sharedScrollbarStyles,
} as const;

export const verticallyScrollable = {
  overflowY: 'auto',
  WebkitOverflowScrolling: 'touch',
} as const;

// multiline `text-overflow: ellipsis.
// See https://css-tricks.com/almanac/properties/l/line-clamp/
export const multilineTextOverflow = (numberOflines: number) =>
  ({
    display: '-webkit-box',
    WebkitLineClamp: numberOflines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  }) as const;

const isAtRuleOrPseudoClass = (key: string) =>
  key.startsWith(':') || key.startsWith('@media');

const isNestedClassName = <T,>(key: Extract<keyof T, string>, style: T) =>
  typeof style[key] === 'object' && !isAtRuleOrPseudoClass(key);

const allowNestedClassNames = <T extends Record<string, any>>(style: T) =>
  (Object.keys(style) as Extract<keyof T, string>[]).reduce(
    (acc, key) => ({
      ...acc,
      [isNestedClassName<T>(key, style) ? `:nth-of-type(1n) ${key}` : key]:
        style[key],
    }),
    {},
  );

type Props = Record<string, any>;
type ComponentToStyle<P extends Props> = React.ElementType<
  Omit<P, 'className'> & { className: string }
>;

type BasicCssRecord = Record<string, string | number | {}>;
type NestedCssRecord = Record<string, BasicCssRecord | string | number>;
type DynamicStyleDefintion = Record<string, NestedCssRecord> & {
  default: NestedCssRecord;
};
type StyleDefintion = NestedCssRecord | DynamicStyleDefintion;

const styledDyn = <P extends Props>(
  styles: DynamicStyleDefintion,
  Component: ComponentToStyle<P>,
) => {
  const unnestedStyles = Object.fromEntries(
    Object.entries(styles).map(([key, value]) => [
      key,
      allowNestedClassNames(value),
    ]),
  );
  const generateCss = (props: P) => {
    return Object.keys(unnestedStyles)
      .filter(clx => props[clx] === true)
      .map(clx => unnestedStyles[clx])
      .reduce((acc: any, newCss) => {
        if (acc == null || newCss == null) {
          return {};
        }
        return {
          ...acc,
          ...Object.fromEntries(
            Object.entries(newCss).map(([key, value]) => {
              if (typeof value !== 'object' || !isAtRuleOrPseudoClass(key)) {
                return [key, value];
              }
              return [
                key,
                {
                  ...(key in acc ? acc[key] : {}),
                  ...value,
                },
              ];
            }),
          ),
        };
      }, unnestedStyles.default);
  };
  // see ln 144
  return typeof Component === 'string'
    ? emotionStyled(Component)(generateCss)
    : emotionStyled(Component)(generateCss);
};

// We need this shim to emotion.styled because our styling API
// was originally based on a propped up version of aphrodite.
// This conforms to the legacy API. We should attempt to migrate
// to raw emotion.styled as much as possible.
export const styled = <P extends Props>(
  style: StyleDefintion,
  Component: ComponentToStyle<P> = 'div',
): // this function produces a massive union because of the emotion return types.
// It's cast to `any` since it's basically useless for type-checking anyway.
any => {
  if (
    (function (obj): obj is DynamicStyleDefintion {
      return 'default' in obj && typeof obj.default === 'object';
    })(style)
  ) {
    return styledDyn(style, Component);
  }

  const unnestedStyle = allowNestedClassNames(style);
  // This looks really dumb, but apparently emotions overloads can't handle the
  // union type of ComponentType and keyof JSX.IntrinsicElements so you need
  // to split them out.
  return typeof Component === 'string'
    ? emotionStyled(Component)(unnestedStyle)
    : emotionStyled(Component)(unnestedStyle);
};
