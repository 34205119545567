/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GameSetup_task = {
    readonly id: string;
    readonly assignedStudents: ReadonlyArray<{
        readonly id: string;
        readonly user: {
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar: string;
        };
    }>;
    readonly " $refType": "GameSetup_task";
};
export type GameSetup_task$data = GameSetup_task;
export type GameSetup_task$key = {
    readonly " $data"?: GameSetup_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"GameSetup_task">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GameSetup_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Student",
      "kind": "LinkedField",
      "name": "assignedStudents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
})();
(node as any).hash = '2f65f373ff29658d1409c2764ac35076';
export default node;
