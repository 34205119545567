import { css } from 'aphrodite';
import { isEmpty } from 'ramda';
import { useCallback, useState } from 'react';
import { graphql, useMutation } from 'react-relay';

import CaptureEnter from 'ms-components/CaptureEnter';
import PasswordInput from 'ms-components/PasswordInput';
import { withSnackbar, type SnackbarContext } from 'ms-components/Snackbar';
import Button from 'ms-ui-primitives/Button';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import type { RelayCommitFunction } from 'ms-utils/typescript-utils';

import type { ChangePasswordMutation } from './__generated__/ChangePasswordMutation.graphql';
import styles from './styles';

type EnqueueMessage = SnackbarContext['enqueueMessage'];
type Props = {
  onChangePasswordSuccess?: (() => void) | undefined;
};
type PropsWithSnackbar = Props & {
  enqueueMessage: EnqueueMessage;
};
type FormValues = {
  newPasswordInputValue1: string;
  newPasswordInputValue2: string;
  oldPasswordInputValue: string;
};
type State = {
  isLoading: boolean;
  validationErrorMessages: string[];
} & FormValues;
type FormValuesUpdaters = {
  updateNewPasswordInputValue1: (v: string) => void;
  updateNewPasswordInputValue2: (v: string) => void;
  updateOldPasswordInputValue: (v: string) => void;
};
type Updaters = {
  doChangePassword: RelayCommitFunction<ChangePasswordMutation>;
  setIsLoading: (next: boolean) => void;
  enqueueValidationErrorMessage: (p: string) => void;
  clearValidationErrorMessages: () => void;
} & FormValuesUpdaters;
type RenderProps = PropsWithSnackbar & State & Updaters;
export const changePasswordRender = ({
  enqueueMessage,
  doChangePassword,
  onChangePasswordSuccess,
  validationErrorMessages,
  isLoading,
  newPasswordInputValue1,
  newPasswordInputValue2,
  oldPasswordInputValue,
  setIsLoading,
  enqueueValidationErrorMessage,
  clearValidationErrorMessages,
  updateNewPasswordInputValue1,
  updateNewPasswordInputValue2,
  updateOldPasswordInputValue,
}: RenderProps) => {
  const isValidForm =
    newPasswordInputValue1.length >= 6 &&
    newPasswordInputValue1 === newPasswordInputValue2;
  const formAction = () => {
    setIsLoading(true);
    clearValidationErrorMessages();
    doChangePassword({
      variables: {
        currentPassword: oldPasswordInputValue,
        newPassword: newPasswordInputValue1,
      },
      onCompleted: ({ checkAndChangeCurrentPassword }, errors) => {
        if (errors != null) {
          setIsLoading(false);
          errors.map(err => ({ text: err.message })).forEach(enqueueMessage);
        } else if (checkAndChangeCurrentPassword.errors.length > 0) {
          setIsLoading(false);
          checkAndChangeCurrentPassword.errors
            .map(err => err.message)
            .forEach(enqueueValidationErrorMessage);
        } else onChangePasswordSuccess?.();
      },
      onError: error => {
        setIsLoading(false);
        enqueueMessage({ text: error.message });
      },
    });
  };
  return (
    <div className={css(styles.formWrapper)}>
      <h1 className={css(styles.title)}>Change your password</h1>

      {!isEmpty(validationErrorMessages) && (
        <div className={css(styles.validationErrorMessages)}>
          <h2 className={css(styles.errorsTitle)}>Errors</h2>
          <ul>
            {validationErrorMessages.map(errorMessage => (
              <li key={errorMessage}>{errorMessage}</li>
            ))}
          </ul>
        </div>
      )}

      <CaptureEnter isDisabled={!isValidForm} action={formAction}>
        <FieldGroup title="Old password*">
          <PasswordInput
            disabled={isLoading}
            value={oldPasswordInputValue}
            onChange={e => {
              updateOldPasswordInputValue(e.target.value);
            }}
            blockTracking
          />
        </FieldGroup>

        <FieldGroup title="New password*">
          <PasswordInput
            disabled={isLoading}
            value={newPasswordInputValue1}
            onChange={e => {
              updateNewPasswordInputValue1(e.target.value);
            }}
            errorMessage={
              newPasswordInputValue1.length > 0 &&
              newPasswordInputValue1.length < 6
                ? 'Minimum 6 characters'
                : ''
            }
            blockTracking
          />
        </FieldGroup>

        <FieldGroup title="Repeat new password*">
          <PasswordInput
            disabled={isLoading}
            value={newPasswordInputValue2}
            onChange={e => {
              updateNewPasswordInputValue2(e.target.value);
            }}
            errorMessage={
              newPasswordInputValue2.length > 0 &&
              newPasswordInputValue2 !== newPasswordInputValue1
                ? "The passwords don't match"
                : ''
            }
            blockTracking
          />
        </FieldGroup>

        {/** TODO button positive */}
        <Button
          type="primary"
          isBlock
          isDisabled={!isValidForm}
          onClick={formAction}
        >
          {isLoading ? 'Changing...' : 'Change password'}
        </Button>
      </CaptureEnter>
    </div>
  );
};

const ChangePassword = ({
  enqueueMessage,
  onChangePasswordSuccess,
}: PropsWithSnackbar) => {
  const [doChangePassword] = useMutation<ChangePasswordMutation>(graphql`
    mutation ChangePasswordMutation(
      $currentPassword: String!
      $newPassword: String!
    ) {
      checkAndChangeCurrentPassword(
        currentPassword: $currentPassword
        newPassword: $newPassword
      ) {
        errors {
          key
          message
        }
      }
    }
  `);
  // TODO: use the isInFlight flag from the useMutation hook once this no longer
  // does a full page reload on submit
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrorMessages, setValidationErrorMessages] = useState<
    string[]
  >([]);
  const [newPasswordInputValue1, setNewPasswordInputValue1] = useState('');
  const [newPasswordInputValue2, setNewPasswordInputValue2] = useState('');
  const [oldPasswordInputValue, setOldPasswordInputValue] = useState('');

  const enqueueValidationErrorMessage = useCallback(
    (newValidationErrorMessage: string) =>
      setValidationErrorMessages(prevValidationErrorMessages =>
        prevValidationErrorMessages.concat(newValidationErrorMessage),
      ),
    [],
  );
  const clearValidationErrorMessages = useCallback(
    () => setValidationErrorMessages([]),
    [],
  );

  return changePasswordRender({
    enqueueMessage,
    doChangePassword,
    onChangePasswordSuccess,
    isLoading,
    validationErrorMessages,
    newPasswordInputValue1,
    newPasswordInputValue2,
    oldPasswordInputValue,
    setIsLoading,
    enqueueValidationErrorMessage,
    clearValidationErrorMessages,
    updateNewPasswordInputValue1: setNewPasswordInputValue1,
    updateNewPasswordInputValue2: setNewPasswordInputValue2,
    updateOldPasswordInputValue: setOldPasswordInputValue,
  });
};

export default withSnackbar(ChangePassword);
