/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ClassAndStudentSelectorQueryVariables = {};
export type ClassAndStudentSelectorQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly " $fragmentRefs": FragmentRefs<"SelectorsWithSearch_teacher">;
        };
    };
};
export type ClassAndStudentSelectorQuery = {
    readonly response: ClassAndStudentSelectorQueryResponse;
    readonly variables: ClassAndStudentSelectorQueryVariables;
};



/*
query ClassAndStudentSelectorQuery {
  viewer {
    profile {
      __typename
      ... on Teacher {
        ...SelectorsWithSearch_teacher
        id
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    id
  }
}

fragment ClassSelector_teacher on Teacher {
  classes(first: 1000) {
    edges {
      node {
        id
        title
        displayName
        students(first: 1000) {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
}

fragment SelectorsWithSearch_teacher on Teacher {
  ...ClassSelector_teacher
  ...StudentSelector_teacher_2dizYt
}

fragment StudentSelector_teacher_2dizYt on Teacher {
  studentSearch(first: 10, searchQuery: "") {
    edges {
      node {
        id
        user {
          id
          firstName
          lastName
          avatar
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "searchQuery",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClassAndStudentSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SelectorsWithSearch_teacher"
                  }
                ],
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClassAndStudentSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v1/*: any*/),
                    "concreteType": "ClassConnection",
                    "kind": "LinkedField",
                    "name": "classes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClassEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Class",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "displayName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": (v1/*: any*/),
                                "concreteType": "StudentConnection",
                                "kind": "LinkedField",
                                "name": "students",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "StudentEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Student",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              (v3/*: any*/),
                                              (v4/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "students(first:1000)"
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "classes(first:1000)"
                  },
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "StudentConnection",
                    "kind": "LinkedField",
                    "name": "studentSearch",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Student",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "avatar",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "studentSearch(first:10,searchQuery:\"\")"
                  },
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "filters": [
                      "searchQuery"
                    ],
                    "handle": "connection",
                    "key": "StudentSelectorFragment_studentSearch",
                    "kind": "LinkedHandle",
                    "name": "studentSearch"
                  },
                  (v2/*: any*/)
                ],
                "type": "Teacher",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad3f06a7edd304a0e3814295bd116f7d",
    "id": null,
    "metadata": {},
    "name": "ClassAndStudentSelectorQuery",
    "operationKind": "query",
    "text": "query ClassAndStudentSelectorQuery {\n  viewer {\n    profile {\n      __typename\n      ... on Teacher {\n        ...SelectorsWithSearch_teacher\n        id\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment ClassSelector_teacher on Teacher {\n  classes(first: 1000) {\n    edges {\n      node {\n        id\n        title\n        displayName\n        students(first: 1000) {\n          edges {\n            node {\n              id\n              user {\n                id\n                firstName\n                lastName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SelectorsWithSearch_teacher on Teacher {\n  ...ClassSelector_teacher\n  ...StudentSelector_teacher_2dizYt\n}\n\nfragment StudentSelector_teacher_2dizYt on Teacher {\n  studentSearch(first: 10, searchQuery: \"\") {\n    edges {\n      node {\n        id\n        user {\n          id\n          firstName\n          lastName\n          avatar\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3e13b5643db83234853945c9c5c2940d';
export default node;
