import type { ClassList } from 'ms-pages/Teacher/TeacherContext/SchoolContext';
import type {
  SchoolList,
  StaticTeacherContext,
  TeacherId,
  TeacherStateContext,
  TeacherPreference,
} from 'ms-pages/Teacher/TeacherContext/types';
import { InvariantViolation } from 'ms-utils/app-logging';
import extractNode from 'ms-utils/relay/extractNode';

import type { useTeacherContextDataQueryResponse } from './__generated__/useTeacherContextDataQuery.graphql';

export function extractSchoolListFromResponse(
  response: useTeacherContextDataQueryResponse,
): SchoolList {
  const viewer = response.viewer;
  if (viewer == null || viewer.profile == null)
    throw new InvariantViolation('You must be logged in');
  const schools = viewer.profile.schools;
  if (schools == null)
    throw new InvariantViolation(
      `User ${viewer.profile.id} does not have any schools`,
    );
  return schools;
}
export function extractTeacherIdFromResponse(
  response: useTeacherContextDataQueryResponse,
): TeacherId {
  const viewer = response.viewer;
  if (viewer == null || viewer.profile == null || viewer.profile.id == null)
    throw new InvariantViolation('You must be logged in');
  return viewer.profile.id;
}
export function extractStateContextFromClassNode(
  node: ClassList[number],
): TeacherStateContext {
  const school = node.school;
  if (school == null)
    throw new InvariantViolation(`Class ${node.id} does not have a school`);
  return {
    classId: node.id,
    schoolId: school.id,
  };
}
export function extractTeacherPreferenceFromResponse(
  response: useTeacherContextDataQueryResponse,
): TeacherPreference {
  const user = response.viewer;
  if (user == null || user.profile == null)
    throw new InvariantViolation('You must be logged in');
  const teacherPreference = user.profile.teacherPreference;
  if (teacherPreference == null)
    throw new InvariantViolation(
      `Teacher preference must be available for logged in user`,
    );
  return teacherPreference;
}
export function getStateContextFromSchoolId(
  context: StaticTeacherContext,
  schoolId?: string,
): TeacherStateContext {
  if (schoolId == null)
    return {
      classId: context.classId,
      schoolId: context.schoolId,
    };
  const { classId } = context;
  // The first thing we want to determine is if the returned class id is in
  // the correct school. If it is, It's valid and we can move on with our lives
  const maybeSchool = context.schoolList.find(s => s.id === schoolId);
  if (maybeSchool == null) {
    return { classId, schoolId };
  }
  const { classes: classNodes } = maybeSchool;
  if (classNodes == null) {
    return { classId, schoolId };
  }
  const schoolClasses = extractNode(classNodes);
  const maybeClass = schoolClasses && schoolClasses.find(c => c.id === classId);
  // if it isn't we return null for the class id
  if (maybeClass != null) {
    return { classId, schoolId };
  }
  return {
    schoolId,
  };
}
