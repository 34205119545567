import { graphql } from 'relay-hooks';

import ExclamationMarkFilled from 'ms-components/icons/ExclamationMarkFilled';
import { useLanguageSelectorContext } from 'ms-pages/Textbooks/components/LanguageSelector/LanguageSelectorContext';
import { borderRadiusUI, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Separator from 'ms-ui-primitives/Separator';
import { styled } from 'ms-utils/emotion';

import type { SyllabusLocale } from './__generated__/NoLanguageAvailable_document.graphql';

graphql`
  fragment NoLanguageAvailable_document on JsxContent {
    locale
  }
`;
const Container = styled({
  padding: 4 * BASE_UNIT,
  backgroundColor: colors.seashell,
  color: colors.cloudBurst,
  fontFamily: fontFamily.body,
  borderRadius: borderRadiusUI,
  display: 'flex',
  alignItems: 'center',
  marginTop: 12,
  marginBottom: 24,
});
export default function NoLanguageAvailable({
  locale,
}: {
  locale: SyllabusLocale | null | undefined;
}) {
  const [selectedLocale] = useLanguageSelectorContext();
  if (selectedLocale !== locale) {
    return (
      <Container>
        <ExclamationMarkFilled size={18} color={colors.cinnabar} />
        <Separator size={4} />
        This is not available in your language
      </Container>
    );
  }
  return null;
}
