/**
 * ## Number Utils
 *
 * A number is a js-number. e.g. 5.12
 *
 * This file is derived from Khan Academy's kmath utility.
 * {@link https://github.com/Khan/kmath/blob/master/number.js}
 *
 * @module number
 * @memberof math
 */

const DEFAULT_TOLERANCE = 1e-9;
const EPSILON = Math.pow(2, -42);

/**
 * Is a value a number?
 * @param {any} x - Value to check
 * @return {boolean} true iff the value is a js number
 */
export function is(x: any): boolean {
  return Object.prototype.toString.call(x) === '[object Number]' && !isNaN(x);
}

/**
 * Check if two numbers are equal withing a tolerance.  Designed to account
 * for javascript errors in float manipulation.
 * @param {number} x - Value to be compared
 * @param {number} y - Value to be compared
 * @param {number} tolerance - (Optional) Equality tolerance
 * @return {boolean} true if the values are equal within the tolerance
 */
export function equal(
  x: number,
  y: number,
  tolerance: number = DEFAULT_TOLERANCE,
): boolean {
  // Checking for undefined makes this function behave nicely
  // with vectors of different lengths that are _.zip'd together
  if (x == null || y == null) {
    return x === y;
  }
  // We check === here so that +/-Infinity comparisons work correctly
  if (x === y) {
    return true;
  }
  return Math.abs(x - y) < tolerance;
}

/**
 * Is a value positive or negative?
 * @param {number} x - Value to interrogate
 * @param {number} tolerance - (Optional) Tolerance within which to declare a value is zero
 * @return {number} One of: -1, 0, 1
 */
export function sign(x: number, tolerance?: number): number {
  return equal(x, 0, tolerance) ? 0 : Math.abs(x) / x;
}

/**
 * @param {number} num - Number to check
 * @param {number} tolerance - (Optional) Range within which to accept a float
 * @return {boolean} true iff the number is within tolerance of an integer
 */
export function isInteger(num: number, tolerance?: number): boolean {
  return equal(Math.round(num), num, tolerance);
}

/**
 * Round a number to a power of ten
 * @param {number} num - Number to round
 * @param {number} precision - Power of ten to round to
 * @return {number} The rounded number
 */
export function round(num: number, precision: number): number {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
}

/**
 * Round num to the nearest multiple of increment
 * @param {number} num - Number to round
 * @param {number} increment - Increment to round to
 * @return {number} The number, rounded to nearest multiple of increment
 * @example roundTo(83, 5); // -> 85
 */
export function roundTo(num: number, increment: number): number {
  return Math.round(num / increment) * increment;
}

/**
 * Get the nearest multiple of increment less than num
 * @param {number} num - Number to floor
 * @param {number} increment - Increment to round to
 * @return {number} The number, floored to nearest multiple of increment
 * @example floorTo(83, 5); // -> 80
 */
export function floorTo(num: number, increment: number): number {
  return Math.floor(num / increment) * increment;
}

/**
 * Get the nearest multiple of increment greater than num
 * @param {number} num - Number to ceil
 * @param {number} increment - Increment to round to
 * @return {number} The number, ceiled to nearest multiple of increment
 * @example ceilTo(82, 5); // -> 85
 */
export function ceilTo(num: number, increment: number): number {
  return Math.ceil(num / increment) * increment;
}

/**
 * Returns a `[numerator, denominator]` array rational representation
 * of `decimal`
 *
 * See [http://en.wikipedia.org/wiki/Continued_fraction](http://en.wikipedia.org/wiki/Continued_fraction)
 * for implementation details.
 *
 * @example toFraction(4/8); // -> [1, 2]
 * @example toFraction(0.66); // -> [33, 50]
 * @example toFraction(0.66, 0.01); // -> [2/3]
 * @example toFraction(283 + 1/3); // -> [850, 3]
 *
 * @param {number} decimal - A decimal value that we want to interpret as a fraction
 * @param {number} tolerance - (Optional) Used to guess if an inexact float is equal to a fraction
 * @param {number} maxDenominator -
 *   (Optional) If we don't find a denominator smaller than this, just return the decimal
 * @return {Array} An array of the form: [numerator, denominator]
 */
export function toFraction(
  decimal: number,
  tolerance: number = EPSILON,
  maxDenominator: number = 1000,
): [number, number] {
  // Initialize everything to compute successive terms of
  // continued-fraction approximations via recurrence relation
  let n: [number, number] = [1, 0];
  let d: [number, number] = [0, 1];
  let a = Math.floor(decimal);
  let rem = decimal - a;

  while (d[0] <= maxDenominator) {
    if (equal(n[0] / d[0], decimal, tolerance)) {
      return [n[0], d[0]];
    }
    n = [a * n[0] + n[1], n[0]];
    d = [a * d[0] + d[1], d[0]];
    a = Math.floor(1 / rem);
    rem = 1 / rem - a;
  }

  // We failed to find a nice rational representation,
  // so return an irrational "fraction"
  return [decimal, 1];
}
