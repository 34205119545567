/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HiddenNonAccessibleTasksLabelFragment = {
    readonly profile: {
        readonly __typename: "Student";
        readonly totalUpcomingTasks?: {
            readonly count: number;
        } | undefined;
        readonly totalUpcomingAccessibleTasks?: {
            readonly count: number;
        } | undefined;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
    readonly " $refType": "HiddenNonAccessibleTasksLabelFragment";
};
export type HiddenNonAccessibleTasksLabelFragment$data = HiddenNonAccessibleTasksLabelFragment;
export type HiddenNonAccessibleTasksLabelFragment$key = {
    readonly " $data"?: HiddenNonAccessibleTasksLabelFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"HiddenNonAccessibleTasksLabelFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "dueAfter",
  "variableName": "dueAfter"
},
v1 = {
  "kind": "Literal",
  "name": "excludeEnded",
  "value": true
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v3 = [
  {
    "alias": "count",
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "dueAfter"
    },
    {
      "kind": "RootArgument",
      "name": "hasEnabledAccessibilityMode"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HiddenNonAccessibleTasksLabelFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "condition": "hasEnabledAccessibilityMode",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "totalUpcomingTasks",
                  "args": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "includeNonAccessibleTasks",
                      "value": true
                    }
                  ],
                  "concreteType": "AssignedTaskConnection",
                  "kind": "LinkedField",
                  "name": "assignedTaskConnection",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": "totalUpcomingAccessibleTasks",
                  "args": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "Literal",
                      "name": "includeNonAccessibleTasks",
                      "value": false
                    }
                  ],
                  "concreteType": "AssignedTaskConnection",
                  "kind": "LinkedField",
                  "name": "assignedTaskConnection",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ]
            }
          ],
          "type": "Student",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'd15f24173df0de74e557128685d69e33';
export default node;
