import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { ModalBody } from 'ms-pages/Teacher/components/Modal';
import Modal from 'ms-ui-primitives/Modal';

import ResetStudentTemporaryPasswordForm from './ResetStudentTemporaryPasswordForm';
import type { ResetStudentTemporaryPasswordModal_student$key } from './__generated__/ResetStudentTemporaryPasswordModal_student.graphql';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  trackingLabel: 'CLASS_SETTINGS' | 'STUDENT_SETTINGS' | 'CLASS_SETTINGS_MODAL';
  student: ResetStudentTemporaryPasswordModal_student$key;
};
export default function ResetStudentTemporaryPasswordModal({
  student: ref,
  isOpen,
  onClose,
  trackingLabel,
}: Props) {
  const student = useFragment(
    graphql`
      fragment ResetStudentTemporaryPasswordModal_student on Student {
        id
        user {
          firstName
          lastName
        }
      }
    `,
    ref,
  );
  if (student == null) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose} theme="dark" width={480}>
      {student != null && (
        <ModalBody white large>
          <ResetStudentTemporaryPasswordForm
            student={student}
            onClose={onClose}
            trackingLabel={trackingLabel}
          />
        </ModalBody>
      )}
    </Modal>
  );
}
