import { graphql, useMutation } from 'relay-hooks';

import type { createLessonTaskMutation } from './__generated__/createLessonTaskMutation.graphql';

export const createLessonTaskMutationQuery = graphql`
  mutation createLessonTaskMutation(
    $title: String!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $dueDate: DateTime!
    $startDate: DateTime!
    $subtopicId: ID!
  ) {
    createLessonTask(
      title: $title
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      dueDate: $dueDate
      startDate: $startDate
      subtopicId: $subtopicId
    ) {
      task {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateLessonTask() {
  return useMutation<createLessonTaskMutation>(createLessonTaskMutationQuery);
}
