import styled from '@emotion/styled';

import { BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { FieldWrapper } from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import { colors } from 'ms-styles/colors';

const Wrapper = styled(FieldWrapper)({
  width: '100%',
  padding: 12,
  borderRadius: 8,
  backgroundColor: colors.ironLight,
});

export function EmptyTemplatesWarningWrapper({ message }: { message: string }) {
  return (
    <Wrapper>
      <BodyS bold color="cinnabar">
        {message}
      </BodyS>
    </Wrapper>
  );
}
