/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EngageTaskFlowInstructionQueryVariables = {
    subtopicId: string;
};
export type EngageTaskFlowInstructionQueryResponse = {
    readonly subtopic: {
        readonly id: string;
        readonly engageActivityTeacherNoteJsx: {
            readonly transpiledJsx: string | null;
        };
    } | null;
};
export type EngageTaskFlowInstructionQuery = {
    readonly response: EngageTaskFlowInstructionQueryResponse;
    readonly variables: EngageTaskFlowInstructionQueryVariables;
};



/*
query EngageTaskFlowInstructionQuery(
  $subtopicId: ID!
) {
  subtopic(id: $subtopicId) {
    id
    engageActivityTeacherNoteJsx {
      transpiledJsx
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subtopicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngageTaskFlowInstructionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EngageActivityTeacherNote",
            "kind": "LinkedField",
            "name": "engageActivityTeacherNoteJsx",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngageTaskFlowInstructionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EngageActivityTeacherNote",
            "kind": "LinkedField",
            "name": "engageActivityTeacherNoteJsx",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f23ec8f3b2e8164f85b0edd0626d445f",
    "id": null,
    "metadata": {},
    "name": "EngageTaskFlowInstructionQuery",
    "operationKind": "query",
    "text": "query EngageTaskFlowInstructionQuery(\n  $subtopicId: ID!\n) {\n  subtopic(id: $subtopicId) {\n    id\n    engageActivityTeacherNoteJsx {\n      transpiledJsx\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '69ad78b5701a0d47b482e0ccafc301e9';
export default node;
