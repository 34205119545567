import { css } from '@emotion/css';
import { StyleSheet } from 'aphrodite';
import { motion } from 'framer-motion';
import type { ReactNode, MutableRefObject } from 'react';
import {
  Fragment,
  useState,
  createContext,
  useContext,
  useRef,
  useEffect,
  useLayoutEffect,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { useQuery, graphql } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import * as Icons from 'ms-components/icons';
import { SortRight } from 'ms-components/icons';
import { BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { FilterPopover, Filter } from 'ms-pages/Teacher/components/FilterForm';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  SearchModeEmptyState,
  EmptyStateSyllabusWithNoTopics,
} from 'ms-pages/Textbooks/components/SearchModeEmptyState';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import RadioButton from 'ms-ui-primitives/RadioButton';
import SearchInput from 'ms-ui-primitives/SearchInput';
import { HSpacer, HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import extractNode from 'ms-utils/relay/extractNode';
import type { SetState } from 'ms-utils/typescript-utils';

import type { SkillsGridQuery } from './__generated__/SkillsGridQuery.graphql';
import { grades, domains } from './va-crosswalk';

const GROUPING_OPTIONS = [
  {
    label: 'Grade',
    value: 'grade',
  },
  {
    label: 'Domain',
    value: 'domain',
  },
] as const;
type GroupingOption = (typeof GROUPING_OPTIONS)[number];
export type Topic = NonNullable<
  SkillsGridQuery['response']['syllabus']
>['topics']['edges'][0]['node'];
export type Subtopic = Topic['subtopics']['edges'][0]['node'];
type ScrollFocus = {
  topicItem: HTMLDivElement | null;
  subtopicItem: HTMLDivElement | null;
} | null;
const TOPIC_EXPAND_DURATION = 0.25;
export const MOVE_DURATION = 0.3;
export const FADE_DURATION = 0.125;
const GRID_GAP = 2 * BASE_UNIT;
type SkillsGridState = {
  previewOpen: boolean;
  setPreviewOpen: SetState<boolean>;
  selectedGrouping: GroupingOption;
  setSelectedGrouping: (group: GroupingOption['value']) => void;
  activeSubtopicId: string | null;
  activeTopicId: string | null;
  setActiveTopicId: SetState<string | null>;
  previewFadeOut: boolean;
  groupingFadeOut: boolean;
  onClickSubtopic: (arg: { subtopic: Subtopic; topic: Topic }) => void;
  selectedSubtopicIds: string[] | null | undefined;
  onSelectSubtopic: ((subtopic: Subtopic) => void) | undefined;
  searchQueryString: string;
  onSearchQueryStringChange: (value: string) => void;
  setSearchString: SetState<string>;
  enableMultipleSelection: boolean;
};
const SkillsGridContext = createContext<SkillsGridState>({
  previewOpen: false,
  setPreviewOpen: () => {},
  selectedGrouping: GROUPING_OPTIONS[0],
  setSelectedGrouping: () => {},
  activeSubtopicId: null,
  activeTopicId: null,
  setActiveTopicId: () => {},
  previewFadeOut: false,
  groupingFadeOut: false,
  onClickSubtopic: () => {},
  selectedSubtopicIds: null,
  onSelectSubtopic: undefined,
  searchQueryString: '',
  onSearchQueryStringChange: () => {},
  setSearchString: () => {},
  enableMultipleSelection: false,
});

export function useSkillsGridContext() {
  return useContext(SkillsGridContext);
}

const styles = StyleSheet.create({
  rotated: {
    transform: 'rotate(90deg)',
  },
  rotatingIcon: {
    transition: `transform ${TOPIC_EXPAND_DURATION}s ease-in-out`,
  },
});

export function GroupCard({
  children,
  color,
}: {
  children: ReactNode;
  color: string;
}) {
  const { previewFadeOut, groupingFadeOut } = useSkillsGridContext();
  return (
    <motion.div
      layout="position"
      initial={{
        opacity: groupingFadeOut || previewFadeOut ? 0 : 1,
      }}
      animate={{
        opacity: groupingFadeOut || previewFadeOut ? 0 : 1,
      }}
      transition={{
        opacity: { duration: FADE_DURATION },
        layout: { duration: MOVE_DURATION },
      }}
      style={{
        borderRadius: 2 * BASE_UNIT,
        backgroundColor: color,
        padding: `${2 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
        gridColumn: '1 / -1',
      }}
    >
      <BodyS lineHeight="18px" color="white" bold>
        {children}
      </BodyS>
    </motion.div>
  );
}

export function TopicCard({
  color,
  topic,
  expansionState,
}: {
  color: string;
  topic: Topic;
  expansionState: {
    expandedTopicIds: Set<Topic['id']>;
    expandTopic: (topicId: Topic['id']) => void;
    collapseTopic: (topicId: Topic['id']) => void;
  };
}) {
  const {
    previewOpen,
    activeTopicId,
    previewFadeOut,
    groupingFadeOut,
    setActiveTopicId,
    onClickSubtopic,
  } = useSkillsGridContext();
  const { title, id } = topic;
  const subtopics = topic.subtopics?.edges?.map(({ node }) => node) ?? null;
  const [scrollFocus, _setScrollFocus] = useState<ScrollFocus>(null);
  const expanded = expansionState.expandedTopicIds.has(id);
  const active = activeTopicId !== null && activeTopicId === id;
  const topicRef = useRef<HTMLDivElement>(null);
  const setScrollFocus = useCallback<
    (subtopicRef: MutableRefObject<HTMLDivElement | null>) => void
  >(
    subtopicRef => {
      _setScrollFocus({
        topicItem: topicRef.current,
        subtopicItem: subtopicRef.current,
      });
    },
    [_setScrollFocus],
  );
  useLayoutEffect(() => {
    if (active && scrollFocus !== null) {
      const focusItem = expanded
        ? scrollFocus.subtopicItem
        : scrollFocus.topicItem;
      if (focusItem !== null)
        focusItem.scrollIntoView({
          block: 'center',
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewOpen]);
  return (
    <motion.div
      layout="position"
      initial={{
        height: expanded ? 'min-content' : 42, // line height and padding
        opacity: groupingFadeOut || (!active && previewFadeOut) ? 0 : 1,
      }}
      animate={{
        opacity: groupingFadeOut || (!active && previewFadeOut) ? 0 : 1,
        height: expanded ? 'min-content' : 42, // line height and padding
      }}
      transition={{
        opacity: { duration: FADE_DURATION },
        layout: { duration: MOVE_DURATION },
        height: { duration: TOPIC_EXPAND_DURATION },
      }}
      style={{
        borderRadius: 2 * BASE_UNIT,
        cursor: 'pointer',
        overflow: 'hidden',
      }}
    >
      <TopicItem
        ref={topicRef}
        color={color}
        expanded={expanded}
        toggleExpanded={() => {
          expanded
            ? expansionState.collapseTopic(id)
            : expansionState.expandTopic(id);
        }}
      >
        {title}
      </TopicItem>
      {subtopics && (
        <div>
          {subtopics.map((subtopic, index) => (
            <SubtopicItem
              setScrollFocus={setScrollFocus}
              key={subtopic.id}
              last={index === subtopics.length - 1}
              onActivateTopic={() => {
                if (!active) {
                  setActiveTopicId(id);
                  expansionState.expandTopic(id);
                }
              }}
              onActivate={subtopic => {
                onClickSubtopic({ subtopic, topic });
              }}
              subtopic={subtopic}
              topicExpanded={expanded}
            />
          ))}
        </div>
      )}
    </motion.div>
  );
}

type TopicItemProps = {
  children: ReactNode;
  expanded: boolean;
  toggleExpanded: () => void;
  color: string;
};
const TopicItem = forwardRef<HTMLDivElement, TopicItemProps>(function (
  { children, expanded, toggleExpanded, color }: TopicItemProps,
  ref,
) {
  return (
    <div
      ref={ref}
      onClick={() => {
        toggleExpanded();
      }}
      style={{
        backgroundColor: color,
        padding: 3 * BASE_UNIT,
        height: 42, // line height and padding
      }}
    >
      <HStack center>
        <SortRight
          size={3 * BASE_UNIT}
          aphroditeStyles={[
            styles.rotatingIcon,
            ...(expanded ? [styles.rotated] : []),
          ]}
        />
        <HSpacer width={2 * BASE_UNIT} />
        <BodyS lineHeight="18px" color="grey" bold>
          <div
            style={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {children}
          </div>
        </BodyS>
      </HStack>
    </div>
  );
});

function SubtopicItem({
  setScrollFocus,
  last,
  onActivateTopic,
  onActivate,
  subtopic,
  topicExpanded,
}: {
  setScrollFocus: (
    subtopicRef: MutableRefObject<HTMLDivElement | null>,
  ) => void;
  last: boolean;
  onActivateTopic: () => void;
  onActivate: (subtopic: Subtopic) => void;
  subtopic: Subtopic;
  topicExpanded: boolean;
}) {
  const {
    setPreviewOpen,
    activeSubtopicId,
    enableMultipleSelection,
    selectedSubtopicIds,
    onSelectSubtopic,
  } = useSkillsGridContext();
  const { title, id } = subtopic;
  const ref = useRef<HTMLDivElement>(null);
  const active = activeSubtopicId !== null && activeSubtopicId === id;
  const selected =
    selectedSubtopicIds != null && selectedSubtopicIds.includes(id);
  useEffect(() => {
    if (active) {
      setScrollFocus(ref);
    }
  }, [active, setScrollFocus]);
  useEffect(() => {
    if (active) {
      onActivateTopic();
    }
  });
  useLayoutEffect(() => {
    if (topicExpanded && active && ref.current !== null) {
      ref.current.scrollIntoView({
        block: 'center',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      ref={ref}
      onClick={() => {
        setPreviewOpen(true);
        onActivate(subtopic);
      }}
      style={{
        border: `1px solid ${colors.ironLight}`,
        ...(last
          ? {
              borderBottomLeftRadius: 2 * BASE_UNIT,
              borderBottomRightRadius: 2 * BASE_UNIT,
            }
          : {
              borderBottom: 'none',
            }),
      }}
    >
      <div
        style={{
          padding: '12px 8px',
          width: 70 * BASE_UNIT,
          borderLeft: `3px solid ${active ? 'black' : 'transparent'}`,
        }}
      >
        <HStack center>
          {enableMultipleSelection && (
            <Checkbox
              onChange={() => {
                onSelectSubtopic && onSelectSubtopic(subtopic);
              }}
              checked={selected}
            />
          )}
          <BodyS lineHeight="18px" color="grey" bold>
            {title}
          </BodyS>
        </HStack>
      </div>
    </div>
  );
}

const SKILLS_GRID_QUERY = graphql`
  query SkillsGridQuery(
    $syllabusId: ID!
    $search: String!
    $isSearch: Boolean!
  ) {
    syllabus(id: $syllabusId) @skip(if: $isSearch) {
      id
      topics(first: 1000) {
        edges {
          node {
            id
            title
            subtopics(first: 1000) {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
    textbookSearch(syllabusId: $syllabusId, query: $search)
      @include(if: $isSearch) {
      id
      topics(first: 1000) {
        edges {
          node {
            topic {
              id
              title
            }
            subtopics(first: 1000) {
              edges {
                node {
                  subtopic {
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function SkillsGrid({
  syllabusId,
  previewOpen,
  setPreviewOpen,
  previewFadeOut,
  activeSubtopicId,
  onClickSubtopic,
  selectedSubtopicIds,
  onSelectSubtopic,
  searchQueryString,
  onSearchQueryStringChange,
  enableMultipleSelection = false,
}: {
  syllabusId: string;
  previewOpen: boolean;
  setPreviewOpen: SetState<boolean>;
  previewFadeOut: boolean;
  activeSubtopicId: string | null;
  onClickSubtopic: (arg: { subtopic: Subtopic; topic: Topic }) => void;
  selectedSubtopicIds?: string[] | null | undefined;
  onSelectSubtopic?: ((subtopic: Subtopic) => void) | undefined;
  searchQueryString: string;
  onSearchQueryStringChange: (value: string) => void;
  enableMultipleSelection?: boolean | undefined;
}) {
  const {
    state: selectedGrouping,
    setState: _setSelectedGrouping,
    fadeOut: groupingFadeOut,
  } = useGridAnimation<GroupingOption>(GROUPING_OPTIONS[0]);
  const setSelectedGrouping = useCallback(
    (grouping: GroupingOption['value']) => {
      _setSelectedGrouping(GROUPING_OPTIONS.find(g => g.value === grouping)!);
    },
    [_setSelectedGrouping],
  );
  const [searchString, setSearchString] = useState(searchQueryString);
  const { props, error, retry } = useQuery<SkillsGridQuery>(SKILLS_GRID_QUERY, {
    syllabusId,
    isSearch: searchString !== '',
    search: searchString,
  });
  const { syllabus, textbookSearch } = props ?? {};
  const realTopics = useMemo(() => {
    if (searchString === '' && syllabus != null) {
      return extractNode(syllabus.topics);
    } else if (searchString !== '' && textbookSearch != null) {
      return extractNode(textbookSearch.topics).map(({ topic, subtopics }) => ({
        ...topic,
        subtopics: {
          edges: extractNode(subtopics).map(({ subtopic }) => ({
            node: subtopic,
          })),
        },
      }));
    }
    return null;
  }, [textbookSearch, searchString, syllabus]);
  const [expandedTopicIds, setExpandedTopicIds] = useState<Set<Topic['id']>>(
    new Set([]),
  );
  const expandTopic = (topicId: Topic['id']) => {
    setExpandedTopicIds(prev => new Set(prev).add(topicId));
  };
  const collapseTopic = (topicId: Topic['id']) => {
    setExpandedTopicIds(prev => {
      const newSet = new Set(prev);
      newSet.delete(topicId);
      return newSet;
    });
  };
  const [activeTopicId, setActiveTopicId] = useState<string | null>(null);
  const groupTopics = useMemo(() => {
    if (realTopics === null) return null;
    return realTopics.reduce(
      (acc, topic) => {
        const grade = acc.byGrade.find(g => {
          const regex = new RegExp(`^${g.affix}\\.`);
          return regex.test(topic.title);
        });
        if (grade !== undefined) {
          grade.topics.push(topic);
        }
        const domain = acc.byDomain.find(d => {
          const regex = new RegExp(`\\. ${d.affix}$`);
          return regex.test(topic.title);
        });
        if (domain !== undefined) {
          domain.topics.push(topic);
        }
        return acc;
      },
      {
        byGrade: grades.map(grade => ({ ...grade, topics: [] as Topic[] })),
        byDomain: domains.map(domain => ({
          ...domain,
          topics: [] as Topic[],
        })),
      },
    );
  }, [realTopics]);
  const groups = useMemo(() => {
    if (groupTopics === null) return null;
    switch (selectedGrouping.value) {
      case 'grade':
        return groupTopics.byGrade;
      case 'domain':
        return groupTopics.byDomain;
    }
  }, [groupTopics, selectedGrouping.value]);
  return (
    <SkillsGridContext.Provider
      value={{
        previewOpen,
        setPreviewOpen,
        selectedGrouping,
        setSelectedGrouping,
        activeSubtopicId,
        activeTopicId,
        setActiveTopicId,
        previewFadeOut,
        groupingFadeOut,
        onClickSubtopic,
        selectedSubtopicIds,
        onSelectSubtopic,
        searchQueryString,
        onSearchQueryStringChange,
        setSearchString,
        enableMultipleSelection,
      }}
    >
      <Filters />
      {groups !== null ? (
        realTopics !== null && realTopics.length === 0 ? (
          // empty states
          searchString !== '' ? (
            // if in search mode, legitimate "not found" message
            <SearchModeEmptyState
              searchString={searchString}
              enableMultiTextbookSearch={false}
              isCentered
            />
          ) : (
            // if not in search mode, it's the unexpected case of a syllabus with no topics
            <EmptyStateSyllabusWithNoTopics />
          )
        ) : (
          <TopicsGrid>
            {groups
              .filter(({ topics }) => topics.length > 0)
              .map(({ title, topics, colors }) => (
                <Fragment key={title}>
                  <GroupCard color={colors.grade}>{title}</GroupCard>
                  {topics.map(topic => (
                    <TopicCard
                      color={colors.topic}
                      topic={topic}
                      expansionState={{
                        expandedTopicIds,
                        expandTopic,
                        collapseTopic,
                      }}
                      key={topic.id}
                    />
                  ))}
                </Fragment>
              ))}
          </TopicsGrid>
        )
      ) : (
        <MinorSpinner />
      )}
      {error != null && <Retry retry={retry} />}
    </SkillsGridContext.Provider>
  );
}

export function TopicsGrid({ children }: { children: ReactNode }) {
  const { previewOpen } = useSkillsGridContext();
  return (
    <div
      className={css({
        '::-webkit-scrollbar': {
          display: 'none',
        },
      })}
      style={{
        padding: 4 * BASE_UNIT,
        paddingTop: 0,
        overflowY: 'scroll',
      }}
    >
      <div
        style={{
          width: previewOpen ? 70 * BASE_UNIT : '100%',
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            70 * BASE_UNIT
          }px, 1fr))`,
          gap: GRID_GAP,
        }}
      >
        {children}
      </div>
    </div>
  );
}

function Filters() {
  const {
    previewOpen,
    setPreviewOpen,
    activeSubtopicId,
    searchQueryString,
    onSearchQueryStringChange,
    setSearchString,
  } = useSkillsGridContext();
  const viewButtonDisabled = activeSubtopicId === null;
  return (
    <HStack
      width={previewOpen ? 70 * BASE_UNIT : '100%'}
      wrap
      style={{ padding: BASE_UNIT * 4 }}
      gap={BASE_UNIT * 4}
    >
      <div
        // this enables the correct wrapping behaviour
        style={{
          flexGrow: 1,
          minWidth: 70 * BASE_UNIT,
        }}
      >
        <SearchInput
          searchString={searchQueryString}
          placeholder="Search"
          onSubmit={(
            currentValue: string,
            debouncedValue: string | undefined,
          ) => {
            onSearchQueryStringChange(currentValue);
            setSearchString(
              (debouncedValue ?? currentValue)
                .split(' ')
                .filter(s => s !== '')
                .join(' '),
            );
          }}
          searchIconOnLeft
          whiteBackground
          withBorder
          height={32}
          borderColor={colors.iron}
          placeholderColor={colors.grey90}
          type="dynamic"
        />
      </div>
      <HStack gap={BASE_UNIT * 4}>
        <GroupByFilter />
        <Filter
          label={
            <>
              Grid <Icons.MenuSquares />
            </>
          }
          selected={!viewButtonDisabled && !previewOpen}
          onClick={() => {
            setPreviewOpen(prev => !prev);
          }}
          disabled={viewButtonDisabled}
        />
      </HStack>
    </HStack>
  );
}

function GroupByFilter() {
  const { selectedGrouping, setSelectedGrouping } = useSkillsGridContext();
  const [popoverOpen, setpopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  return (
    <Filter
      label={`Group by: ${selectedGrouping.label}`}
      popoverAnchorRef={popoverRef}
      selected
      popover={
        popoverOpen && (
          <FilterPopover
            onDismiss={() => {
              setpopoverOpen(false);
            }}
            anchorElementRef={popoverRef}
          >
            <VStack>
              <RadioButton
                label="Grade"
                checked={selectedGrouping.value === 'grade'}
                onChange={() => {
                  setSelectedGrouping('grade');
                  setpopoverOpen(false);
                }}
              />
              <VSpacer height={4} />
              <RadioButton
                label="Domain"
                checked={selectedGrouping.value === 'domain'}
                onChange={() => {
                  setSelectedGrouping('domain');
                  setpopoverOpen(false);
                }}
              />
            </VStack>
          </FilterPopover>
        )
      }
      onClick={() => {
        setpopoverOpen(true);
      }}
    />
  );
}

export function SkillsGridPreview({
  children,
  shown,
  fadeOut,
}: {
  children: ReactNode;
  shown: boolean;
  fadeOut: boolean;
}) {
  return (
    <motion.div
      transition={{ duration: FADE_DURATION }}
      animate={{
        opacity: fadeOut ? 0 : 1,
      }}
      style={{
        ...(shown ? { flexGrow: 1 } : { width: 0 }),
        overflowY: 'scroll',
      }}
    >
      {children}
    </motion.div>
  );
}

export function useGridAnimation<T>(intialState: T) {
  const [intendState, setIntendState] = useState<T>(intialState);
  const [actualState, setActualState] = useState<T>(intialState);
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const [animationStep, setAnimationStep] = useState<
    'static' | 'fadingOut' | 'moving' | 'fadingIn'
  >('static');
  const stateSettled = actualState === intendState;
  useEffect(() => {
    if (stateSettled) return;
    setAnimationStep('fadingOut');
  }, [stateSettled]);
  useEffect(() => {
    switch (animationStep) {
      case 'fadingOut': {
        if (fadeOut) {
          setTimeout(() => {
            setAnimationStep('moving');
          }, FADE_DURATION * 1000);
        } else {
          setFadeOut(true);
        }
        break;
      }
      case 'moving': {
        if (stateSettled) {
          setTimeout(() => {
            setAnimationStep('fadingIn');
          }, MOVE_DURATION * 1000);
        } else {
          setActualState(intendState);
        }
        break;
      }
      case 'fadingIn': {
        if (!fadeOut) {
          setTimeout(() => {
            setAnimationStep('static');
          }, FADE_DURATION * 1000);
        } else {
          setFadeOut(false);
        }
        break;
      }
    }
  }, [animationStep, fadeOut, intendState, setFadeOut, stateSettled]);
  return {
    setState: setIntendState,
    state: actualState,
    fadeOut,
    animationMoving: animationStep === 'moving',
  };
}
