import { useEffect, useState } from 'react';
import { graphql } from 'react-relay';
import { useRefetch } from 'relay-hooks';

import Center from 'ms-ui-primitives/Center';
import LoadingSpinner from 'ms-ui-primitives/LoadingSpinner';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';
import _ from 'ms-utils/relay/extractNode';
import { unwrap } from 'ms-utils/typescript-utils';

import LeaderboardPresentational from './LeaderboardPresentational';
import type { Leaderboard_student$key } from './__generated__/Leaderboard_student.graphql';

export const LEADERBOARD_TOP_N = 5;

type Props = {
  studentKey: Leaderboard_student$key;
};
export const refetchQuery = graphql`
  query LeaderboardRefetchQuery(
    $fetchLeaderboard: Boolean!
    $numberOfClasses: Int!
    $classId: ID
    $topN: Int
  ) {
    viewer {
      student {
        ...Leaderboard_student
          @arguments(
            fetchLeaderboard: $fetchLeaderboard
            numberOfClasses: $numberOfClasses
            classId: $classId
            topN: $topN
          )
      }
    }
  }
`;
const fragment = graphql`
  fragment Leaderboard_student on Student
  @argumentDefinitions(
    fetchLeaderboard: { type: "Boolean!" }
    numberOfClasses: { type: "Int!" }
    classId: { type: "ID" }
    topN: { type: "Int" }
  ) {
    id
    leaderboard @include(if: $fetchLeaderboard) {
      currentWeek(classId: $classId, topN: $topN) {
        points
        rank
        name
        avatarUrl
        studentId
      }
    }
    user {
      avatar
      firstName
      lastName
      points {
        current
      }
    }
    classes(first: $numberOfClasses) {
      edges {
        node {
          hasEnabledLeaderboard
          displayName
          id
          title
        }
      }
    }
  }
`;
export default function Leaderboard({ studentKey }: Props) {
  const [student, refetch] = useRefetch(fragment, studentKey);
  const classesWithLeaderboardEnabled = _(student.classes).filter(
    klass => klass.hasEnabledLeaderboard,
  );
  const [selectedClassId, onSelectClassId] = useState(() =>
    classesWithLeaderboardEnabled.length > 0
      ? unwrap(classesWithLeaderboardEnabled[0]).id
      : null,
  );
  useEffect(() => {
    if (selectedClassId != null) {
      refetch(refetchQuery, v => ({
        ...v,
        fetchLeaderboard: true,
        classId: selectedClassId,
      }));
    }
  }, [refetch, selectedClassId]);
  const weekly = student.leaderboard?.currentWeek;
  const leaders = weekly?.filter(Boolean) || [];
  const isLoading = !weekly;
  if (classesWithLeaderboardEnabled.length === 0) {
    return <Center>The leaderboard is disabled for your classes</Center>;
  }
  if (isLoading) {
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );
  }
  return (
    <LeaderboardPresentational
      studentId={student.id}
      classes={student.classes}
      user={student.user}
      leaders={leaders}
      selectedClassId={selectedClassId}
      onSelectClass={onSelectClassId}
    />
  );
}
type LeaderboardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  studentKey: Leaderboard_student$key;
};
export function LeaderboardModal({
  isOpen,
  onClose,
  studentKey,
}: LeaderboardModalProps) {
  return (
    <Modal
      title="Leaderboard"
      width={450}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
    >
      <ModalBody>
        <Leaderboard studentKey={studentKey} />
      </ModalBody>
    </Modal>
  );
}
