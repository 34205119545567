import { useEffect, useCallback, useMemo } from 'react';

import { useRouter } from 'ms-utils/hooks/useRouter';
import { serialize, deserialize } from 'ms-utils/urls/queryParams';

export default function useQueryParamState<S extends {}>(
  initialDefaultState: S,
): [
  S,
  (updater: (state: S) => S) => void,
  (updater: (state: S) => S) => string,
] {
  const {
    history,
    location: { pathname, search, state: _state },
  } = useRouter();

  const state: S = useMemo(
    () => ({
      ...initialDefaultState,
      ...deserialize(search),
    }),
    [initialDefaultState, search],
  );

  useEffect(() => {
    const newSearch = serialize(state);
    if (search !== newSearch) {
      history.replace({
        pathname,
        search: newSearch,
        state: _state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinkBySetState = (stateUpdater: (state: S) => S) =>
    `${pathname}${serialize({
      ...deserialize(search),
      ...stateUpdater(state),
    })}`;

  const setQueryParamState = useCallback(
    (stateUpdater: (state: S) => S) => {
      const newSearch = serialize({
        ...deserialize(search),
        ...stateUpdater(state),
      });
      if (search !== newSearch) {
        history.replace({
          pathname,
          search: newSearch,
          state: _state,
        });
      }
    },
    [history, pathname, search, state, _state],
  );

  return [state, setQueryParamState, getLinkBySetState];
}
