import styled from '@emotion/styled';
import type { ReactElement, ReactNode } from 'react';

import { fontWeight, fontSize, fontFamily, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';

import type { ActionWithoutArgs } from './types';

const MODAL_WIDTH = 120 * BASE_UNIT;
const BUTTONS_LATERAL_MARGIN = 3 * BASE_UNIT;
const CONTENT_MARGIN_BOTTOM = 5 * BASE_UNIT;

export type ConfirmModalUiRequiredProps = {
  action: ActionWithoutArgs;
  isOpen: boolean;
  onClose: () => void;
  cancelAction?: (() => void) | null | void;
};

type ConfirmModalUiOptionalProps = {
  cancelButtonLabel?: ReactElement<any> | string;
  confirmButtonLabel?: ReactElement<any> | string;
  children?: ReactNode;
  isActionDestructive?: boolean;
  centerContent?: boolean;
};

export type ConfirmModalUiProps = ConfirmModalUiRequiredProps &
  ConfirmModalUiOptionalProps;

const Content = styled.div<{ center: boolean }>(({ center }) => ({
  display: 'flex',
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  lineHeight: lineHeight.body,
  fontWeight: fontWeight.normal,
  color: colors.mako,
  marginBottom: CONTENT_MARGIN_BOTTOM,
  ...(center ? { alignItems: 'center', justifyContent: 'center' } : {}),
}));

const Actions = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
});

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: '50%',
  ':not(:first-child)': {
    paddingLeft: BUTTONS_LATERAL_MARGIN,
  },
  ':not(:last-child)': {
    paddingRight: BUTTONS_LATERAL_MARGIN,
  },
});

export default function ConfirmModalUi({
  action,
  isOpen,
  onClose,
  cancelButtonLabel,
  confirmButtonLabel,
  children,
  isActionDestructive = true,
  cancelAction,
  centerContent = false,
}: ConfirmModalUiProps) {
  return (
    <Modal
      showCloseButton={false}
      width={MODAL_WIDTH}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalBody>
        <Content center={centerContent}>{children}</Content>

        <Actions>
          <ButtonWrapper>
            <Button
              isBlock
              color={isActionDestructive ? 'cinnabar' : 'brand'}
              type="secondary"
              onClick={() => {
                action();
                onClose();
              }}
              label={
                typeof confirmButtonLabel === 'string'
                  ? confirmButtonLabel
                  : 'Confirm'
              }
            >
              {confirmButtonLabel ?? 'Confirm'}
            </Button>
          </ButtonWrapper>

          <ButtonWrapper>
            <Button
              isBlock
              type="primary"
              onClick={() => {
                onClose();
                if (cancelAction != null) {
                  cancelAction();
                }
              }}
              label="Cancel"
            >
              {cancelButtonLabel ?? 'Cancel'}
            </Button>
          </ButtonWrapper>
        </Actions>
      </ModalBody>
    </Modal>
  );
}
