import { useSnowplow } from 'ms-helpers/Snowplow';
import FormErrorMessage from 'ms-pages/Teacher/components/FormErrorMessage';
import { Button } from 'ms-pages/Teacher/components/Link';
import Separator from 'ms-pages/Teacher/components/Separator';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

import useResetClassCode from './useResetClassCode';

type Props = {
  classId: string;
  buttonText?: string | undefined;
};

export default function ResetClassCode({
  classId,
  buttonText = 'Reset code',
}: Props) {
  const { withTrackStructEvent } = useSnowplow();

  const [mutation, { response, loading, errors }] = useResetClassCode({
    classId,
  });

  const responseErrors = response?.resetClassCode.errors;

  return (
    <>
      <Separator size={4 * BASE_UNIT} />
      <div>
        <Button
          onClick={withTrackStructEvent(mutation, {
            category: 'teacher_admin',
            action: 'clicked_reset_class_code',
          })}
          isDisabled={loading}
        >
          {buttonText}
        </Button>
      </div>
      {errors != null && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            Oops, there was an error. Please try again.
          </FormErrorMessage>
        </>
      )}
      {responseErrors != null && responseErrors.length > 0 && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            {responseErrors.map(({ key, message }) => (
              <div key={key}>{message}</div>
            ))}
          </FormErrorMessage>
        </>
      )}
    </>
  );
}
