import { LinkTab, TabContent, Tabs } from 'ms-components/Tabs';
import {
  TeacherOverview as TeacherOverviewIcon,
  Worksheet as WorksheetIcon,
} from 'ms-components/icons';
import { useTopicTabsState } from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import Stack from 'ms-ui-primitives/Stack';

export { TAB_HEIGHT_V2 as TAB_HEIGHT } from 'ms-components/Tabs';
type Props = {
  shouldRenderAssessmentTab: boolean;
  shouldRenderOverviewTab: boolean;
};

export default function SubtopicTabs({
  shouldRenderAssessmentTab,
  shouldRenderOverviewTab,
}: Props) {
  const [topicTab, , getTabLink] = useTopicTabsState();

  const shouldRenderTabs = shouldRenderOverviewTab || shouldRenderAssessmentTab;

  return (
    shouldRenderTabs && (
      <Tabs borderWidth={0} v2>
        {shouldRenderOverviewTab && (
          <LinkTab
            v2
            active={topicTab === 'overview'}
            to={getTabLink('overview')}
          >
            <TopicTabContent>
              <TeacherOverviewIcon />
              Overview
            </TopicTabContent>
          </LinkTab>
        )}

        {shouldRenderAssessmentTab && (
          <LinkTab
            v2
            active={topicTab === 'assessment'}
            to={getTabLink('assessment')}
          >
            <TopicTabContent>
              <WorksheetIcon />
              Assessment
            </TopicTabContent>
          </LinkTab>
        )}
      </Tabs>
    )
  );
}
function TopicTabContent({ children }: { children: React.ReactNode }) {
  return (
    <TabContent v2>
      <Stack.H center gap={8}>
        {children}
      </Stack.H>
    </TabContent>
  );
}
