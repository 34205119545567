import { forwardRef, useEffect } from 'react';

import Input from 'ms-ui-primitives/Input';

import {
  TASK_TITLE_MAX_LENGTH,
  TASK_TEMPLATE_TITLE_MAX_LENGTH,
} from '../helpers';

type Props = {
  placeholder?: string;
  value: string;
  onChange: (name: string) => void;
  type?: 'task' | 'template';
};

const mapTypeToMaxLength = {
  task: TASK_TITLE_MAX_LENGTH,
  template: TASK_TEMPLATE_TITLE_MAX_LENGTH,
} as const;

const TaskNameInput = forwardRef<HTMLInputElement, Props>(
  function TaskNameInput({ value, onChange, type = 'task', placeholder }, ref) {
    const maxLength = mapTypeToMaxLength[type];

    // Use maxLength on Input doesn't really solve the problem, because if
    // the value comes from the API or internal state (for instance in the flow
    // of creating a task from a template), the input would be still valorised
    // with a string longer than the maxLength prop.
    // Thus, we just rely on this `useEffect`
    useEffect(() => {
      if (value.length > maxLength) {
        onChange(value.slice(0, maxLength));
      }
    }, [value, maxLength, onChange]);

    return (
      <Input
        placeholder={placeholder}
        ref={ref}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
      />
    );
  },
);

export default TaskNameInput;
