import { useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { InfoButton } from 'ms-pages/Lantern/components/InfoButton';
import { InfoModal } from 'ms-pages/Lantern/components/InfoModal';
import { TeacherReportHeader } from 'ms-pages/Lantern/components/TeacherReportHeader';
import { useSnowplowForLanternTeacher } from 'ms-pages/Lantern/helpers/Snowplow';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { VStack } from 'ms-pages/Lantern/primitives/Stack';
import EditStudentModal from 'ms-pages/Teacher/TeacherStudentReport/views/TeacherStudentSettings/EditStudentModal';
import { NotFoundError } from 'ms-utils/app-logging';

import { SkillsReportTable } from './SkillsReportTable';
import type { TeacherSkillsReportQuery } from './__generated__/TeacherSkillsReportQuery.graphql';
import type { Curriculum, Student } from './types';
import { LanternTeacherQueryWrapper } from '../components';

type TeacherSkillsReportProps = {
  studentId?: string | null | undefined;
  inModal?: boolean | null | undefined;
};

function TeacherSkillsReport(props: TeacherSkillsReportProps) {
  return (
    <LanternTeacherQueryWrapper
      fallback={<LoadingSpinner />}
      queryName="TeacherSkillsReportQuery"
    >
      {({ fetchKey }) => (
        <TeacherSkillsReportQueryContent fetchKey={fetchKey} {...props} />
      )}
    </LanternTeacherQueryWrapper>
  );
}

function TeacherSkillsReportQueryContent({
  studentId,
  inModal,
  fetchKey,
}: TeacherSkillsReportProps & { fetchKey: number }) {
  const { studentId: studentIdParam } = useParams<{
    studentId?: string;
  }>();
  // The original code was incorrect and made the assumption that at least
  // one of studentId or studentIdParam would be defined. This is not logically
  // true. I'm going to do an unsafe coercion here to maintain the current
  // implementation, but this should be fixed. I won't introduce an assertion
  // here as that may lead to runtime failures if the assumption does not
  // always hold.
  // Note: I'm also using the sloppy || operator (sloppy since '' gets coerced
  // to false in JS) to maintain the previous behavior. It's unclear whether
  // this behaviour was being relied upon or not.
  const unsafelyCoercedStudentId = (studentId || studentIdParam)!;
  const data = useLazyLoadQuery<TeacherSkillsReportQuery>(
    graphql`
      query TeacherSkillsReportQuery($studentId: ID!) {
        lantern {
          student(id: $studentId) {
            id
            sanaUserId
            firstName
            lastName
            mathspaceStudent {
              id
            }
            curriculum {
              sanaCatalogId
              grades {
                id
                title
              }
              strands {
                id
                title
                gradeStrands {
                  id
                  grade {
                    id
                  }
                }
              }
              outcomes {
                id
                code
                sanaTopicId
                skill {
                  description
                }
                gradeStrand {
                  id
                }
              }
            }
          }
        }
      }
    `,
    { studentId: unsafelyCoercedStudentId },
    { fetchKey },
  );
  if (data.lantern.student == null) {
    throw new NotFoundError(
      `Student '${studentId || studentIdParam}' not found`,
    );
  }
  return (
    <TeacherSkillsReportMain
      student={data.lantern.student}
      curriculum={data.lantern.student.curriculum}
      inModal={inModal}
    />
  );
}
function TeacherSkillsReportMain({
  student,
  curriculum,
  inModal,
}: {
  student: Student;
  curriculum: Curriculum;
  inModal: boolean | null | undefined;
}) {
  const { withTrackStructEvent } = useSnowplowForLanternTeacher();
  const [showInfoModal, setInfoModal] = useState(false);
  const proficiencyColorLegendIcon = (
    <InfoButton
      onClick={withTrackStructEvent(() => setInfoModal(true), {
        category: 'teacher_skills',
        action: 'clicked_info_icon',
      })}
    />
  );
  const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState(false);
  return (
    <>
      <TeacherReportHeader
        title={`${student.firstName} ${student.lastName}`}
        infoIcon={proficiencyColorLegendIcon}
      />
      <VStack css={{ flexGrow: 1, overflowX: 'auto', padding: 32 }}>
        {inModal ? (
          <SkillsReportTable studentId={student.id} curriculum={curriculum} />
        ) : (
          <VStack css={{ flexGrow: 1, width: 800, margin: '0 auto' }}>
            <SkillsReportTable studentId={student.id} curriculum={curriculum} />
          </VStack>
        )}
      </VStack>
      <InfoModal
        isOpen={showInfoModal}
        variant="OutcomeColors"
        closeModal={() => setInfoModal(false)}
      />
      <EditStudentModal
        studentId={student.mathspaceStudent.id}
        requireStudentInClass={false}
        isOpen={isEditStudentModalOpen}
        closeModal={() => setIsEditStudentModalOpen(false)}
        // Need to reload the page in order to reflect the student detail changes
        onSubmit={() => window.location.reload()}
      />
    </>
  );
}
export { TeacherSkillsReport };
