const getFolderTitleWithPath = ({
  ancestorTitles,
  title,
}: {
  ancestorTitles: Array<string>;
  title: string;
}) => {
  return ancestorTitles.length > 0
    ? `${ancestorTitles.join('/')}/${title}`
    : title;
};

export { getFolderTitleWithPath };
