import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M5.31255 0H8.85408C9.24359 0 9.56255 0.318958 9.56255 0.708264V2.47924C9.56255 2.86854 9.24359 3.1875 8.85408 3.1875H5.31255C4.92304 3.1875 4.60408 2.86854 4.60408 2.47924V0.708264C4.60408 0.318958 4.92304 0 5.31255 0ZM10.625 1.41671H12.75C13.5288 1.41671 14.1665 2.05442 14.1665 2.83324V15.5832C14.1665 16.3623 13.5288 17 12.75 17H1.41653C0.637708 17 0 16.3623 0 15.5832V2.83324C0 2.05442 0.637708 1.41671 1.41653 1.41671H3.54153V2.83324C3.54153 3.61227 4.17944 4.24997 4.95826 4.24997H9.20826C9.98709 4.24997 10.625 3.61227 10.625 2.83324V1.41671ZM7.08326 7.08324H2.83326V8.49997H7.08326V7.08324ZM2.83326 14.1667H9.91653V12.75H2.83326V14.1667ZM2.83326 11.3332H11.3333V9.91671H2.83326V11.3332Z" />
);

const Tasks = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Tasks.displayName = 'Tasks';

export default Tasks;
