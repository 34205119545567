import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import {
  ADAPTIVE_REASSIGN,
  CHECK_IN_TASK_REASSIGN,
  CUSTOM_REASSIGN,
  ENGAGE_TASK_REASSIGN,
  LESSON_REASSIGN,
  WORKSHEET_REASSIGN,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import CreateTaskModalContent from 'ms-pages/Teacher/components/CreateTaskModalContent';
import { excludedStudents as getExcludedStudents } from 'ms-pages/Teacher/components/EditTaskFlyout';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  useProblemsCollection,
  useProblemsCollectionUpdaters,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import { InvariantViolation } from 'ms-utils/app-logging';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  ReassignTaskModalQuery,
  ReassignTaskModalQueryResponse,
} from './__generated__/ReassignTaskModalQuery.graphql';
// We need to dynamically switch the effect hook depending on SSR
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;
type Props = {
  onClose: () => void;
  isOpen: boolean;
  taskId: string;
  trackingEventOnCreation: SupportedStructEvent;
  prefilledClasses?: readonly ClassSelectionPayload[] | undefined;
  prefilledStudents?: readonly StudentSelectionPayload[] | undefined;
};
export default function ReassignTaskModal({
  onClose,
  isOpen,
  taskId,
  trackingEventOnCreation,
  prefilledStudents,
  prefilledClasses,
}: Props) {
  const reAssignTaskModalInner = (
    <ReassignTaskModalInner
      isOpen={isOpen}
      taskId={taskId}
      onClose={onClose}
      trackingEventOnCreation={trackingEventOnCreation}
      prefilledStudents={prefilledStudents}
      prefilledClasses={prefilledClasses}
    />
  );
  // We are loading the task modal only when it's opened
  // so that the confirm prompt inside the below
  // ReassignTaskFlyoutInnerUi's useIsomorphicLayoutEffect
  // for clearing the cart (for custom task re-assignments)
  // wouldn't run prematurely
  // for task flyout this is prevented by the flyout provided separately right below
  return isOpen ? reAssignTaskModalInner : null;
}
graphql`
  fragment ReassignTaskModal_assignmentsTask on TaskInterface
  @relay(mask: false) {
    assignedClasses {
      id
      title
      displayName
      students(first: 100) {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
    partiallyAssignedClasses {
      assignedClass {
        id
        students(first: 100) {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
      assignedStudents {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    individuallyAssignedStudents {
      id
      user {
        firstName
        lastName
      }
    }
  }
`;

function ReassignTaskModalInner({
  isOpen,
  taskId,
  trackingEventOnCreation,
  onClose,
  prefilledStudents,
  prefilledClasses,
}: {
  onClose: () => void;
  isOpen: boolean;
  taskId: string;
  trackingEventOnCreation: SupportedStructEvent;
  prefilledClasses?: readonly ClassSelectionPayload[] | undefined;
  prefilledStudents?: readonly StudentSelectionPayload[] | undefined;
}) {
  const { props } = useQuery<ReassignTaskModalQuery>(
    graphql`
      query ReassignTaskModalQuery($taskId: ID!) {
        task(id: $taskId) {
          __typename
          ... on CustomTask {
            assignedProblems {
              problemContent {
                problemTemplateId
                id
                previewWorkoutCreationToken
                estimatedCompletionTime
              }
            }
            id
            title
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)
          }
          ... on AdaptiveTask {
            targetMastery
            areaOfStudy {
              id
              title
              topic {
                title
              }
            }
            id
            title
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)
          }
          ... on NewAdaptiveExperienceTask {
            newAdaptiveTargetMastery: targetMastery
            areaOfStudy {
              id
              title
              topic {
                title
              }
            }
            id
            title
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)
          }
          ... on LessonTask {
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)

            areaOfStudy {
              id
              title
            }
            id
            title
          }
          ... on EngageTask {
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)

            areaOfStudy {
              id
              title
            }
            id
            title
          }
          ... on WorksheetTask {
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)

            areaOfStudy {
              id
              title
            }
            id
            title
          }
          ... on CheckInTask {
            id
            title
            strand: areaOfStudy {
              id
            }
            ...ReassignTaskModal_assignmentsTask @relay(mask: false)
          }
        }
      }
    `,
    { taskId },
  );
  if (props == null) return <MinorSpinner />;
  const task = props.task;
  if (task == null)
    throw new InvariantViolation(
      `ReassignTaskModal: impossible to find a task with id ${taskId}`,
    );
  if (task.__typename === '%other')
    throw new InvariantViolation(
      `Task type not valid, it should be AdaptiveTask, CustomTask, LessonTask or WorksheetTask; got ${task.__typename}`,
    );
  return (
    <ReassignTaskModalInnerUi
      isOpen={isOpen}
      task={task}
      onClose={onClose}
      trackingEventOnCreation={trackingEventOnCreation}
      prefilledStudents={prefilledStudents}
      prefilledClasses={prefilledClasses}
    />
  );
}

function ReassignTaskModalInnerUi({
  isOpen,
  task,
  onClose,
  prefilledStudents: providedPrefilledStudents,
  prefilledClasses: providedPrefilledClasses,
  trackingEventOnCreation,
}: {
  isOpen: boolean;
  task: ReassignTaskModalQueryResponse['task'];
  onClose: () => void;
  trackingEventOnCreation: SupportedStructEvent;
  prefilledClasses?: readonly ClassSelectionPayload[] | undefined;
  prefilledStudents?: readonly StudentSelectionPayload[] | undefined;
}) {
  const { problemsCollection } = useProblemsCollection();
  const initialized = useRef(false);
  const { clearCart, addMultipleProblemContents } =
    useProblemsCollectionUpdaters();
  if (task == null)
    throw new InvariantViolation(
      `ReassignTaskModal: impossible to find the task}`,
    );
  // Type narrow away this fake member of the union as it prevents indexing
  // into otherwise common properties of all the members.
  if (task.__typename === '%other') throw Error();
  const taskType = useMemo(
    () => (task != null && task?.__typename != null ? task.__typename : null),
    [task],
  );
  if (taskType == null)
    throw new InvariantViolation(
      `ReassignTaskModal: impossible to find the task type}`,
    );
  const prefilledQuestions =
    taskType === 'CustomTask' &&
    task.__typename === 'CustomTask' &&
    task.assignedProblems != null
      ? task.assignedProblems.map<[string, number, string, string]>(
          ({ problemContent }) => [
            problemContent.id,
            problemContent.estimatedCompletionTime,
            problemContent.problemTemplateId,
            problemContent.previewWorkoutCreationToken,
          ],
        )
      : null;
  // TODO ask @Selcuk how to refactor this because it's not clear to me (@Francesco)
  // TODO: refactor this
  // Executing this in this way is a bit hacky and causing problems
  // because, it executes the confirm prompt before the flyout or the modal is opened
  // the premature execution for the task flyout is prevented
  // by the provided separate flyout component up in the ReassignTaskModal function
  // which is not the case for the task modal,
  // since the portal component needed for the modal is already bundled
  // with CreateTaskModalContentInner component
  // and if providing a separate flyout for reassigning tasks was necessary or not is up for debate
  // since we will be getting rid of the task flyout, we should refactor this
  // We should pass down a prop for "reassigning tasks" like "isReassign" to the CreateTaskModalContent
  // and handle this inside the custom task flow
  useIsomorphicLayoutEffect(() => {
    if (initialized.current) return;
    if (prefilledQuestions != null) {
      if (problemsCollection.length !== 0) {
        if (
          // eslint-disable-next-line no-restricted-globals
          confirm(
            'Reassigning this task will clear any questions you have collected. Continue?',
          )
        )
          clearCart();
        else return onClose();
      }
      addMultipleProblemContents(prefilledQuestions);
      initialized.current = true;
    }
  }, [
    addMultipleProblemContents,
    clearCart,
    onClose,
    prefilledQuestions,
    problemsCollection.length,
  ]);
  const prefilledStartDate = useMemo(() => new Date(), []);
  const prefilledDueDate = useMemo(
    () =>
      new Date(
        // beware, setDate mutates the date!
        new Date(prefilledStartDate).setDate(prefilledStartDate.getDate() + 7),
      ),
    [prefilledStartDate],
  );
  const prefilledClasses = useMemo(
    () => providedPrefilledClasses || task?.assignedClasses || [],
    [providedPrefilledClasses, task],
  );
  const prefilledStudents = useMemo(() => {
    return (
      providedPrefilledStudents ||
      (task?.individuallyAssignedStudents != null
        ? task?.individuallyAssignedStudents?.map(student => ({
            id: student.id,
            firstName: student.user.firstName,
            lastName: student.user.lastName,
          }))
        : []) ||
      []
    );
  }, [providedPrefilledStudents, task]);
  const taskName = useMemo(() => task?.title || '', [task]);
  const prefilledExcludedStudents = useMemo(
    () =>
      getExcludedStudents(
        task?.partiallyAssignedClasses != null
          ? task.partiallyAssignedClasses
          : [],
      ),
    [task],
  );
  const subtopicId = useMemo(
    () => ('areaOfStudy' in task ? task.areaOfStudy.id : null),
    [task],
  );
  const subtopicTitle = useMemo(
    () => ('areaOfStudy' in task ? task.areaOfStudy.title : null),
    [task],
  );
  const topicTitle = useMemo(
    () =>
      'areaOfStudy' in task && 'topic' in task.areaOfStudy
        ? task.areaOfStudy.topic.title
        : null,
    [task],
  );
  const prefilledSubtopic = useMemo<[string, string] | null>(
    () =>
      subtopicId != null && subtopicTitle != null && topicTitle != null
        ? [subtopicId, `${subtopicTitle} < ${topicTitle}`]
        : null,
    [subtopicId, subtopicTitle, topicTitle],
  );
  const prefilledSubtopicWithoutTopic = useMemo<[string, string] | null>(
    () =>
      subtopicId != null && subtopicTitle != null
        ? [subtopicId, subtopicTitle]
        : null,
    [subtopicId, subtopicTitle],
  );
  const taskTargetMastery = useMemo(() => {
    if (
      taskType === 'AdaptiveTask' &&
      task.__typename === 'AdaptiveTask' &&
      task?.targetMastery != null
    ) {
      return task.targetMastery;
    } else if (
      taskType === 'NewAdaptiveExperienceTask' &&
      task.__typename === 'NewAdaptiveExperienceTask' &&
      task?.newAdaptiveTargetMastery != null
    ) {
      return task.newAdaptiveTargetMastery;
    } else {
      return null;
    }
  }, [task, taskType]);

  const prefilledStrandId = useMemo(() => {
    if (taskType === 'CheckInTask' && task.__typename === 'CheckInTask') {
      return task.strand.id;
    } else {
      return null;
    }
  }, [task, taskType]);
  const componentProps = useMemo(() => {
    const commonProps = {
      isOpen,
      prefilledClasses,
      prefilledStudents,
      prefilledExcludedStudents,
      taskName,
      prefilledStartDate,
      prefilledDueDate,
      onAbort: onClose,
      onClose,
      trackingEventOnCreation,
    };
    switch (taskType) {
      case 'AdaptiveTask': {
        return {
          ...commonProps,
          preselection: ADAPTIVE_REASSIGN,
          prefilledSubtopic,
          prefilledTargetMastery: taskTargetMastery,
        };
      }
      case 'NewAdaptiveExperienceTask': {
        return {
          ...commonProps,
          preselection: ADAPTIVE_REASSIGN,
          prefilledSubtopic,
          prefilledTargetMastery: taskTargetMastery,
        };
      }
      case 'CustomTask': {
        return {
          ...commonProps,
          preselection: CUSTOM_REASSIGN,
        };
      }
      case 'LessonTask': {
        return {
          ...commonProps,
          preselection: LESSON_REASSIGN,
          prefilledSubtopic: prefilledSubtopicWithoutTopic,
        };
      }
      case 'WorksheetTask': {
        return {
          ...commonProps,
          preselection: WORKSHEET_REASSIGN,
          prefilledSubtopic: prefilledSubtopicWithoutTopic,
        };
      }
      case 'EngageTask': {
        return {
          ...commonProps,
          preselection: ENGAGE_TASK_REASSIGN,
          prefilledSubtopic: prefilledSubtopicWithoutTopic,
        };
      }
      case 'CheckInTask': {
        return {
          ...commonProps,
          preselection: CHECK_IN_TASK_REASSIGN,
          prefilledStrandId,
        };
      }
      default: {
        if (taskType === '%other') throw Error(); // type narrowing
        assertUnreachable(
          taskType,
          `Task type not valid, task type: ${taskType}`,
        );
      }
    }
  }, [
    isOpen,
    prefilledClasses,
    prefilledStudents,
    prefilledExcludedStudents,
    taskName,
    prefilledStartDate,
    prefilledDueDate,
    onClose,
    trackingEventOnCreation,
    taskType,
    prefilledSubtopic,
    taskTargetMastery,
    prefilledSubtopicWithoutTopic,
    prefilledStrandId,
  ]);
  return <CreateTaskModalContent {...componentProps} />;
}
