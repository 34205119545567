import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode, TouchEvent as SyntheticTouchEvent } from 'react';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { fontSize } from 'ms-styles/theme/Work';
import { onHover } from 'ms-utils/emotion';

function noop() {}

const ACTION_PADDING = 12;

const styles = StyleSheet.create({
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    padding: ACTION_PADDING,
    color: colors.white,
    cursor: 'pointer',
    fontFamily: fontFamily.body,
    textAlign: 'center',
    // reset
    outline: 'none',
  },
  disabled: {
    background: colors.iron,
    color: colors.dustyGray,
    cursor: 'default',
    ...onHover({
      background: colors.iron,
      color: colors.dustyGray,
    }),
  },
  medium: {
    fontSize: fontSize.actionMedium,
    fontWeight: fontWeight.semibold,
  },
  large: {
    fontSize: fontSize.actionLarge,
  },
});

const getColorStyles = (color: keyof typeof alternateColors) =>
  StyleSheet.create({
    color: {
      background: colors[color],
      ...onHover({
        background: alternateColors[color],
      }),
    },
  });

type Props = {
  children: ReactNode;
  color: keyof typeof alternateColors;
  disabled?: boolean | undefined;
  size?: 'medium' | 'large' | undefined;
  aphroditeStyles?: CSSInputTypes[] | undefined;
  onClick?: (() => void) | undefined;
  onMouseDown?: (() => void) | undefined;
  onTouchStart?:
    | ((event: SyntheticTouchEvent<HTMLAnchorElement>) => void)
    | undefined;
};

const Action = ({
  children,
  color,
  disabled = false,
  size = 'medium',
  aphroditeStyles = [],
  onClick,
  onMouseDown = noop,
  onTouchStart = noop,
}: Props) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    className={css(
      styles.action,
      size === 'medium' && styles.medium,
      size === 'large' && styles.large,
      getColorStyles(color).color,
      disabled && styles.disabled,
      ...aphroditeStyles,
    )}
    onClick={disabled ? noop : onClick}
    onMouseDown={disabled ? noop : onMouseDown}
    onTouchStart={disabled ? noop : onTouchStart}
    role="link"
    tabIndex={0}
  >
    {children}
  </a>
);

export default Action;
