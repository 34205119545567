import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FolderOpen = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.424 1.783h5.158c.59 0 1.089 1.331 1.165 1.917H1.91V1.676c0-.649.527-1.175 1.176-1.176h4.163a.196.196 0 0 1 .157.07l1.02 1.213Zm-6.51 3.281h12.84a1.589 1.589 0 0 1 1.58 1.614l-.912 6.453a1.589 1.589 0 0 1-1.588 1.569H2.84c-.87 0-1.576-.7-1.587-1.569L.334 6.678v-.026c0-.874.706-1.583 1.58-1.588Z"
    />
  </Icon>
);
FolderOpen.displayName = 'FolderOpen';

export default FolderOpen;
