/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SkillPopover_outcome = {
    readonly id: string;
    readonly title: string;
    readonly code: string;
    readonly curriculum: {
        readonly id: string;
    };
    readonly skill: {
        readonly id: string;
        readonly description: string;
        readonly canStartCheckIn: boolean;
        readonly strand: {
            readonly id: string;
        };
    };
    readonly gradeStrand: {
        readonly grade: {
            readonly sanaTopicId: string;
        };
    };
    readonly subtopics: ReadonlyArray<{
        readonly id: string;
        readonly hasAdaptivePractice: boolean;
        readonly " $fragmentRefs": FragmentRefs<"OutcomeSubtopicRecommendationCard_subtopic">;
    }>;
    readonly " $refType": "SkillPopover_outcome";
};
export type SkillPopover_outcome$data = SkillPopover_outcome;
export type SkillPopover_outcome$key = {
    readonly " $data"?: SkillPopover_outcome$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SkillPopover_outcome">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SkillPopover_outcome",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternCurriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canStartCheckIn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Strand",
          "kind": "LinkedField",
          "name": "strand",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GradeStrand",
      "kind": "LinkedField",
      "name": "gradeStrand",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternGrade",
          "kind": "LinkedField",
          "name": "grade",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sanaTopicId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtopic",
      "kind": "LinkedField",
      "name": "subtopics",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAdaptivePractice",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OutcomeSubtopicRecommendationCard_subtopic"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternOutcome",
  "abstractKey": null
};
})();
(node as any).hash = 'f4c435a2ea1f68508a15d2e95e9ea89e';
export default node;
