/* eslint-disable jsx-a11y/no-redundant-roles */
import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode, MouseEvent, AnchorHTMLAttributes } from 'react';

import { alternateColors, colors } from 'ms-styles/colors';
import { useColorTheme } from 'ms-styles/themes';
import { onHover } from 'ms-utils/emotion';

import styles from '../styles';

type Props = {
  children: ReactNode;
  aphroditeStyles?: CSSInputTypes[];
  onClick?: ((event: MouseEvent) => void) | undefined;
  href?: string | undefined;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Anchor = ({
  children,
  aphroditeStyles = [],
  onClick,
  href,
  ...rest
}: Props) => {
  const { primary: primaryColorName } = useColorTheme();
  const { linkColor: linkColorStyles } = StyleSheet.create({
    linkColor: {
      color: colors[primaryColorName],
      ...onHover({
        color: alternateColors[primaryColorName],
      }),
    },
  });
  return (
    <a
      {...rest}
      href={href}
      className={css(styles.link, linkColorStyles, ...aphroditeStyles)}
      onClick={onClick}
      role="link"
      tabIndex={0}
    >
      {children}
    </a>
  );
};

export default Anchor;
