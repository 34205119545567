/*
 * The flyout layout is a two part navigation and scrollable content area.
 * The header and the content area
 */
import { css, cx } from '@emotion/css';
import type { ReactNode } from 'react';

import { FLOATING_FOOTER_HEIGHT } from 'ms-pages/Teacher/components/Flyout/components/FloatingFooter';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import {
  styled,
  VERTICAL_SCROLLBAR_WIDTH,
  styledVerticallyScrollable,
} from 'ms-utils/emotion';

// const FLYOUT_HEADER_HEIGHT = 150;
const FLYOUT_BODY_BACKGROUND = colors.porcelain;
export const BODY_PADDING = 6 * BASE_UNIT;
export const FLYOUT_CONTENT_PADDING = {
  horizontal: 4 * BASE_UNIT,
  vertical: 5 * BASE_UNIT,
};

export const FLYOUT_CONTENT_PADDING_RIGHT =
  FLYOUT_CONTENT_PADDING.horizontal + VERTICAL_SCROLLBAR_WIDTH;

export const Root = styled({
  default: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.seashell,
  },
  fullWidth: {
    width: '100%',
  },
});

export const Header = styled({
  padding: `${FLYOUT_CONTENT_PADDING.vertical}px ${FLYOUT_CONTENT_PADDING.horizontal}px`,
  backgroundColor: 'white',
});

export const RightAligned = styled({
  marginLeft: 'auto',
});

const styles = {
  default: css({
    flexGrow: 1,
    backgroundColor: FLYOUT_BODY_BACKGROUND,
    padding: BODY_PADDING,
    paddingRight: FLYOUT_CONTENT_PADDING_RIGHT,
  }),
  verticalOverflowHidden: css({
    overflowY: 'hidden',
  }),
  scrollable: css({
    ...styledVerticallyScrollable,
  }),
  whiteBackground: css({
    backgroundColor: 'white',
  }),
  noHorizontalPadding: css({
    paddingRight: 0,
    paddingLeft: 0,
  }),
};

export function Body({
  children,
  whiteBackground = true,
  style,
  isVerticallyScrollable = true,
  isVerticalOverflowHidden = false,
  noFooterSpaceAtBottom = false,
  noHorizontalPadding = false,
}: {
  children: ReactNode;
  whiteBackground?: boolean;
  isVerticallyScrollable?: boolean;
  isVerticalOverflowHidden?: boolean;
  style?: {};
  noFooterSpaceAtBottom?: boolean;
  noHorizontalPadding?: boolean | undefined;
}) {
  return (
    <div
      className={cx(
        styles.default,
        isVerticallyScrollable && styles.scrollable,
        isVerticalOverflowHidden && styles.verticalOverflowHidden,
        whiteBackground && styles.whiteBackground,
        noHorizontalPadding && styles.noHorizontalPadding,
      )}
      style={style}
    >
      {children}
      {!noFooterSpaceAtBottom && (
        <div style={{ height: FLOATING_FOOTER_HEIGHT }} />
      )}
    </div>
  );
}
