import { EventType } from '@rive-app/react-canvas';

import { useSnackbar } from 'ms-components/Snackbar';
import { DEBUG_RIVE } from 'ms-experiments/gamification/RiveDebugger';

import { useRiveGeneratedEventListener } from './useRiveGeneratedEventListener';
import type { TypedRive } from './useTypedRive';

// For debugging purposes, this will alert us (via a toast) whenever a Rive
// event is received from Rive. Only works in development mode.
export function useRiveEventNotifier(rive: TypedRive | null) {
  const { enqueueMessage } = useSnackbar();

  useRiveGeneratedEventListener(rive, EventType.RiveEvent, event => {
    if (!DEBUG_RIVE) return;
    // Bail early if we're not receiving a Rive event
    if (typeof event.data !== 'object' || !('name' in event.data)) return;
    enqueueMessage({ text: `Received Rive event: ${event.data.name}` });
  });
}
