import AccessibilityIcon from 'ms-components/icons/Accessibility';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import { HStack, HSpacer } from 'ms-ui-primitives/Stack';

type Props = {
  label?: string | undefined;
};

export default function AccessibilityModeLabel({ label }: Props) {
  return (
    <HStack center>
      <AccessibilityIcon size={20} color={colors.grey} />

      {label != null && (
        <>
          <HSpacer width={8} />
          <BodyM>
            <Bold>{label}</Bold>
          </BodyM>
        </>
      )}
    </HStack>
  );
}
