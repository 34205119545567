import { useCallback, useContext, useState, useEffect, useMemo } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import { MultipleCustomTaskDetailsForm } from 'ms-pages/Teacher/components/CreateTask/CreateBulkCustomTask/MultipleCustomTaskDetailsForm';
import useCreateBulkCustomTaskState from 'ms-pages/Teacher/components/CreateTask/CreateBulkCustomTask/state';
import useCreateBulkCustomTask from 'ms-pages/Teacher/components/CreateTask/CreateBulkCustomTask/state/useCreateBulkCustomTask';
import { useCreateCustomTaskState } from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import SubtopicNavbar from 'ms-pages/Teacher/components/CreateTask/components/SubtopicNavbar';
import TaskTemplateTitle from 'ms-pages/Teacher/components/CreateTask/flows/BulkAdaptiveFlow/components/SubtopicTitle';
import TaskTemplateQuestionList from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow/components/TaskTemplateQuestionList';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';
import {
  getStepHeaderInfo,
  STEP_NAME_MAPS,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import { useCreateTaskState } from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { FlyoutContext } from 'ms-pages/Teacher/components/Flyout';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { styledVerticallyScrollable } from 'ms-utils/emotion';

type TaskTemplateId = string;
type TaskTemplateTitleType = string;
export type TaskTemplateTuple = [TaskTemplateId, TaskTemplateTitleType];

export type BulkCustomFlowProps = {
  taskTemplates: readonly TaskTemplateTuple[];
  prefilledTaskTemplateGroupId: string;
  taskCreationCallback?: () => void;
};

export default function BulkCustomFlow({
  taskTemplates: _taskTemplates,
  prefilledTaskTemplateGroupId,
  taskCreationCallback,
}: BulkCustomFlowProps) {
  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();

  const [taskTemplates, setTaskTemplates] = useState<
    BulkCustomFlowProps['taskTemplates']
  >(_taskTemplates ?? []);
  const [hasEmptyTemplates, setHasEmptyTemplates] = useState(false);

  const [previewSelectedTemplate, setPreviewSelectedTemplate] = useState<
    // TODO do we really want to support undefined here? Feels dodgy but
    // its required with the current implementation as the indexation
    // into taskTemplates is not guaranteed to produce a result.
    TaskTemplateTuple | null | undefined
  >(taskTemplates[0] ?? null);

  const previewActiveSelectedTemplateIndex = useMemo(() => {
    return previewSelectedTemplate != null
      ? taskTemplates.findIndex(([id]) => id === previewSelectedTemplate[0])
      : -1;
  }, [previewSelectedTemplate, taskTemplates]);

  const { trackStructEvent } = useSnowplow();

  const handlePreviewActiveSelectedTemplateIndexIncrease = useCallback(() => {
    if (previewActiveSelectedTemplateIndex < taskTemplates.length - 1) {
      setPreviewSelectedTemplate(
        taskTemplates[previewActiveSelectedTemplateIndex + 1],
      );
    }
  }, [previewActiveSelectedTemplateIndex, taskTemplates]);

  const handlePreviewActiveSelectedTemplateIndexDecrease = useCallback(() => {
    if (previewActiveSelectedTemplateIndex > 0) {
      setPreviewSelectedTemplate(
        taskTemplates[previewActiveSelectedTemplateIndex - 1],
      );
    }
  }, [previewActiveSelectedTemplateIndex, taskTemplates]);

  const [
    isPreviewStepFloatingButtonEnabled,
    setIsPreviewStepFloatingButtonEnabled,
  ] = useState<boolean>(false);

  const [seedState] = useCreateCustomTaskState({
    problemContentIds: [],
    selectedClasses: [],
    selectedStudents: [],
  });

  const [getState, createBulkStateInstance] = useCreateBulkCustomTaskState(
    seedState.values,
  );

  const { stepNumber } = useCreateTaskState();
  const { closeFlyout } = useContext(FlyoutContext);

  const [saved, setSaved] = useState<Record<string, string>>({});
  const [saving, setSaving] = useState(false);

  const save = useCreateBulkCustomTask({
    taskTemplatesIds: taskTemplates.map(([id]) => id),
    getState,
    // we remove each item from the list as it's saved
    onSave: (taskTemplateId, task) =>
      setSaved(prevSaved => ({ ...prevSaved, [taskTemplateId]: task.id })),
    onError: () => {},
    onComplete: () => {
      setSaving(false);
    },
  });

  const { enqueueMessage } = useContext(SnackbarContext);
  const numberOfSavedItems = Object.keys(saved).length;
  const allSaved = numberOfSavedItems === taskTemplates.length;

  const subtopicsIds = Object.keys(saved);
  const assignedClasses = subtopicsIds
    .map(getState)
    .map(s => s.selectedClasses)
    .flat();

  const classTasksReportUrl =
    useGetFirstAvailableClassTasksReportUrl(assignedClasses);

  useEffect(() => {
    if (saving === false) {
      if (numberOfSavedItems > 0 && allSaved) {
        closeFlyout();
        enqueueMessage({
          text: `${numberOfSavedItems} task${
            numberOfSavedItems > 1 ? 's' : ''
          } created`,
          actionLabel: 'View tasks',
          href: classTasksReportUrl,
        });
        taskCreationCallback?.();
      }
    }
  }, [
    allSaved,
    closeFlyout,
    enqueueMessage,
    numberOfSavedItems,
    saving,
    taskCreationCallback,
    classTasksReportUrl,
  ]);

  const stepNames = STEP_NAME_MAPS.bulkCustom();

  const nextButtonDisabledStates = {
    preview_and_task_details:
      saving ||
      Object.keys(saved).length === taskTemplates.length ||
      !isPreviewStepFloatingButtonEnabled ||
      hasEmptyTemplates,
  };

  const createBulkCustomTaskAction = useCallback(() => {
    setSaving(true);
    save();
  }, [save]);

  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({ selectedFlow: 'bulkCustom' })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={createBulkCustomTaskAction}
        numberOfSelections={taskTemplates.length}
        isPreviousButtonDisabledOnFirstStep
      />

      {stepNumber === 0 && (
        <>
          {taskTemplates.length > 1 &&
            previewSelectedTemplate != null &&
            previewActiveSelectedTemplateIndex >= 0 && (
              <SubtopicNavbar
                activeSubtopicIndex={previewActiveSelectedTemplateIndex}
                numberOfTasks={taskTemplates.length}
                onRightArrowClick={
                  handlePreviewActiveSelectedTemplateIndexIncrease
                }
                onLeftArrowClick={
                  handlePreviewActiveSelectedTemplateIndexDecrease
                }
              />
            )}
          <Body
            noFooterSpaceAtBottom
            isVerticallyScrollable={false}
            isVerticalOverflowHidden
            whiteBackground={false}
            style={{
              backgroundColor: colors.seashell,
              padding: 0,
            }}
          >
            {previewSelectedTemplate != null &&
              previewActiveSelectedTemplateIndex >= 0 && (
                <div
                  style={{
                    display: 'flex',
                    height: '100%',
                    overflowY: 'hidden', // to hand over the scrolling to child elements
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                      height: '100%',
                      width: '55%',
                      borderRight: `1px solid ${colors.ironLight}`,
                      backgroundColor: colors.white,
                    }}
                  >
                    <TaskTemplateTitle
                      title={previewSelectedTemplate[1]}
                      showRemoveButton={taskTemplates.length > 1}
                      onRemoveSubtopic={() => {
                        const templateId = previewSelectedTemplate[0];
                        const templateIndex = taskTemplates.findIndex(
                          ([id]) => id === templateId,
                        );

                        if (templateIndex > 0) {
                          setPreviewSelectedTemplate(
                            taskTemplates[templateIndex - 1],
                          );
                        } else if (
                          templateIndex === 0 &&
                          taskTemplates.length > 1
                        ) {
                          setPreviewSelectedTemplate(
                            taskTemplates[templateIndex + 1],
                          );
                        } else {
                          setPreviewSelectedTemplate(null);
                        }

                        setTaskTemplates(
                          taskTemplates.filter(
                            ([tempId]) => tempId !== templateId,
                          ),
                        );
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        padding: '8px 16px 16px 16px',
                        width: '100%',
                      }}
                    >
                      <Button
                        size="regular"
                        type="tertiary"
                        onClick={() => {
                          trackStructEvent({
                            category: 'create_task_from_anywhere',
                            action: `clicked_${
                              questionsCollapsed ? 'expand' : 'collapse'
                            }_all_questions`,
                            label: 'bulk_custom_flow',
                            value: taskTemplates.map(([id]) => id).join(','),
                          });
                          setQuestionsCollapsed(s => !s);
                        }}
                        padding={0}
                        height={22}
                      >
                        {questionsCollapsed ? 'Expand All' : 'Collapse All'}
                      </Button>
                    </div>

                    <div
                      style={{
                        flexGrow: 1,
                        width: '100%',
                        height: '100%',
                        position: 'relative', // for empty state positioning
                        ...styledVerticallyScrollable,
                        paddingLeft: 16,
                      }}
                    >
                      <TaskTemplateQuestionList
                        templateId={previewSelectedTemplate[0]}
                        questionsCollapsed={questionsCollapsed}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      flexGrow: 1,
                      height: '100%',
                      padding: '24px 16px',
                      ...styledVerticallyScrollable,
                    }}
                  >
                    <MultipleCustomTaskDetailsForm
                      templates={taskTemplates}
                      templateGroupId={prefilledTaskTemplateGroupId}
                      activeTemplate={previewSelectedTemplate}
                      stateFactory={createBulkStateInstance}
                      onFormCompletionChange={
                        setIsPreviewStepFloatingButtonEnabled
                      }
                      hasEmptyTemplates={hasEmptyTemplates}
                      setHasEmptyTemplates={setHasEmptyTemplates}
                    />
                  </div>
                </div>
              )}
          </Body>
        </>
      )}
    </>
  );
}
