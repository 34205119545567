import Separator from 'ms-pages/Teacher/components/Separator';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const Message = styled({
  color: colors.brickRed,
});

type ErrorMessage = string;
export type ErrorMessages = readonly ErrorMessage[];

type Props = {
  messages: ErrorMessages;
};

export default function FormFieldError({ messages }: Props) {
  return messages.length === 0 ? null : (
    <>
      <Separator size={2 * BASE_UNIT} />
      {messages.map(m => (
        <Message key={m}>{m}</Message>
      ))}
    </>
  );
}
