import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M14.315 7.118 12.478 5.576C11.962 5.143 11.218 5.18 10.812 5.659L4.623 12.965C4.465 13.151 4.376 13.386 4.368 13.636L4.302 15.747C4.281 16.392 4.797 16.968 5.453 17.033 5.603 17.048 5.752 17.035 5.893 16.995L7.772 16.457C7.98 16.397 8.164 16.279 8.302 16.117L14.517 8.78C14.923 8.3 14.835 7.558 14.319 7.121 14.318 7.12 14.317 7.119 14.315 7.118ZM16.597 4.497 14.985 3.145C14.608 2.828 14.064 2.857 13.769 3.208 13.746 3.235 13.726 3.263 13.707 3.293L13.643 3.395C13.392 3.793 13.494 4.336 13.881 4.66L15.493 6.013C15.87 6.329 16.414 6.301 16.709 5.95 16.732 5.923 16.752 5.894 16.771 5.864L16.835 5.763C17.086 5.364 16.984 4.822 16.597 4.497Z" />
);

const Pencil = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Pencil.displayName = 'Pencil';

export default Pencil;
