import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import { memo, useEffect, useRef } from 'react';
import type {
  ComponentType,
  MouseEvent as SyntheticMouseEvent,
  ChangeEvent,
} from 'react';

import { colors } from 'ms-styles/colors';
import Checkbox from 'ms-ui-primitives/Checkbox';
import { usePrevious } from 'ms-utils/hooks/usePrevious';

import type { OptionLabel } from '../';
import { OPTION_PADDING } from '../styles';

// Checkbox label has alignItems: 'flex-start'.
// While it's probably ok to change it there, there's some risk of breaking other components.
const overrideCheckboxStyles = StyleSheet.create({
  label: {
    alignItems: 'center',
    // This is to make sure the whole Option is clickable
    width: '100%',
    height: '100%',
    // the padding is omitted from Option when it's a multiselect
    padding: OPTION_PADDING,
    // Finally, remove the right margin from the checkbox label
    marginRight: 0,
  },
});

type Props<T extends string | number> = {
  active?: boolean | undefined;
  disabled?: boolean | undefined;
  icon?: ComponentType<any> | undefined;
  index: number;
  onClick: (
    event: SyntheticMouseEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>,
  ) => void;
  onMouseEnter: (event: SyntheticMouseEvent<HTMLDivElement>) => void;
  selected?: boolean | undefined;
  styles: {
    option: CSSInputTypes;
    optionActive: CSSInputTypes;
    multipleSelectOption: CSSInputTypes;
    optionSelected: CSSInputTypes;
    optionText: CSSInputTypes;
    icon: CSSInputTypes;
    optionDisabled: CSSInputTypes;
  };
  value: T;
  label: OptionLabel;
  multi: boolean | undefined;
};

function Option<T extends string | number>(props: Props<T>) {
  const {
    active,
    selected,
    icon: Icon,
    label,
    styles,
    onClick,
    onMouseEnter,
    disabled,
    multi,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const prevIsActive = usePrevious(active);

  // Scroll to the option when it becomes active
  useEffect(() => {
    if (active && !prevIsActive && ref.current) {
      try {
        ref.current.scrollIntoView({
          block: 'nearest',
        });
      } catch (e) {
        // Do nothing if the scrollIntoView fails for any reason.
        // It's not mission-critical functionality.
      }
    }
  }, [active, prevIsActive]);

  const optionLabel = (
    <>
      <span className={css(styles.optionText)}>{label}</span>
      {Icon !== undefined && (
        <Icon
          aphroditeStyles={[styles.icon]}
          color={selected ? colors.matisse : 'transparent'}
        />
      )}
    </>
  );

  return (
    <div
      ref={ref}
      className={css(
        props.styles.option,
        Boolean(selected) && styles.optionSelected,
        Boolean(active) && styles.optionActive,
        Boolean(disabled) && styles.optionDisabled,
        Boolean(multi) && styles.multipleSelectOption,
      )}
      onClick={
        multi
          ? // if multiselect, we delegate the click handler to the checkbox
            // we have to stop the propagation since the checkbox works with an `onChange` event
            e => {
              e.stopPropagation();
            }
          : onClick
      }
      onMouseEnter={onMouseEnter}
    >
      {
        /** if multiselect, we wrap the option with a checkbox */
        /** NB: in multiselect the icon in `optionLabel` is omitted from the parent Select */
        multi ? (
          <Checkbox
            checked={selected}
            disabled={disabled}
            aphroditeStyles={[overrideCheckboxStyles.label]}
            onChange={onClick}
            label={optionLabel}
          />
        ) : (
          optionLabel
        )
      }
    </div>
  );
}

function propsAreEqual<T extends string | number>(
  prevProps: Readonly<Props<T>>,
  nextProps: Readonly<Props<T>>,
): boolean {
  return (
    prevProps.active === nextProps.active &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.selected === nextProps.selected &&
    prevProps.value === nextProps.value
  );
}

export default memo(Option, propsAreEqual);
