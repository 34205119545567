import { map } from 'ramda';

import type { CategoryData } from 'ms-components/math/Histogram';

export type SerializedData = ReadonlyArray<readonly [string, number]>;

/**
 * Converts histogram input into an array of category objects.
 * @param {Array} serializedData - serialized data from the server that describes a hisotgram.
 * @return {Array} array of category objects.
 */
export const deserializeHistogram: (
  serializedData: SerializedData,
) => CategoryData = map(([title, value]) => ({ title, value }));

/**
 * Converts deserialized representation of histogram data into serialized version that
 * is recognized by the server.
 * @param {Array} deserializedData - deserialized representation of a histogram data.
 * @return {Array} serialized data recognized by the server.
 */
export const serializeHistogram: (
  deserializedData: CategoryData,
) => ReadonlyArray<any> = map(({ title, value }) => [title, value]);
