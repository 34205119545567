import { css } from '@emotion/css';

import { fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

export function TranslatedMultipleChoiceOption({
  optionLabel,
  children,
}: {
  // Should be a single ascii uppercase letter - "A", "B", "C", "D", etc.
  optionLabel: string;
  children: React.ReactNode;
}) {
  return (
    <div className={styles.optionContainer}>
      {children}
      <div className={styles.optionLabel}>{optionLabel}</div>
    </div>
  );
}

const styles = {
  optionContainer: css({
    padding: 8,
    border: `1px solid ${colors.iron}`,
    borderRadius: 8,
    background: colors.white,
    marginBottom: 8,
    position: 'relative',
  }),
  optionLabel: css({
    position: 'absolute',
    top: 3,
    right: 8,
    fontSize: 14,
    fontWeight: fontWeight.semibold,
    color: colors.iron,
  }),
};
