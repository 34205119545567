import { init } from 'ramda';

/**
 * No Op.
 *
 * This function is a well-typed do-nothing helper.  It is intended primarily
 * to clarify and reduce boilerplate in callsites.  It can also provide a small
 * performance win.
 * @param {*} anything - anything
 * @returns {undefined}
 */
export const noop = (..._xs: Array<any>): void => {}; // eslint-disable-line no-unused-vars

const trimTrailingSlash = (str: string) => str.replace(/\/$/, '');

export const appendToPath = (
  path: string,
  segment: string,
  queryString: string = '',
) => `${trimTrailingSlash(path)}/${segment}${queryString}`;

export const replaceLastFromPath = (
  path: string,
  segment: string,
  queryString: string = '',
) =>
  `${init(trimTrailingSlash(path).split('/'))
    .concat(segment)
    .join('/')
    .concat(queryString)}`;
