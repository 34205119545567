import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M6.36363636,2 L14.6363636,2 C15.941764,2 17,3.02334914 17,4.28571429 L17,15.7142857 C17,16.9766509 15.941764,18 14.6363636,18 L6.36363636,18 C5.05823605,18 4,16.9766509 4,15.7142857 L4,4.28571429 C4,3.02334914 5.05823605,2 6.36363636,2 Z M6.4375,14 C6.88623136,14 7.25,13.6162441 7.25,13.1428571 C7.25,12.6694702 6.88623136,12.2857143 6.4375,12.2857143 C5.98876864,12.2857143 5.625,12.6694702 5.625,13.1428571 C5.625,13.6162441 5.98876864,14 6.4375,14 Z M6.4375,10.5714286 C6.88623136,10.5714286 7.25,10.1876726 7.25,9.71428571 C7.25,9.24089879 6.88623136,8.85714286 6.4375,8.85714286 C5.98876864,8.85714286 5.625,9.24089879 5.625,9.71428571 C5.625,10.1876726 5.98876864,10.5714286 6.4375,10.5714286 Z M6.4375,7.14285714 C6.88623136,7.14285714 7.25,6.75910121 7.25,6.28571429 C7.25,5.81232736 6.88623136,5.42857143 6.4375,5.42857143 C5.98876864,5.42857143 5.625,5.81232736 5.625,6.28571429 C5.625,6.75910121 5.98876864,7.14285714 6.4375,7.14285714 Z M8.33333333,12.2857143 C8.03417907,12.2857143 7.79166667,12.6694702 7.79166667,13.1428571 C7.79166667,13.6162441 8.03417907,14 8.33333333,14 L14.8333333,14 C15.1324876,14 15.375,13.6162441 15.375,13.1428571 C15.375,12.6694702 15.1324876,12.2857143 14.8333333,12.2857143 L8.33333333,12.2857143 Z M8.33333333,8.85714286 C8.03417907,8.85714286 7.79166667,9.24089874 7.79166667,9.71428571 C7.79166667,10.1876727 8.03417907,10.5714286 8.33333333,10.5714286 L14.8333333,10.5714286 C15.1324876,10.5714286 15.375,10.1876727 15.375,9.71428571 C15.375,9.24089874 15.1324876,8.85714286 14.8333333,8.85714286 L8.33333333,8.85714286 Z M8.33333333,5.42857143 C8.03417907,5.42857143 7.79166667,5.81232731 7.79166667,6.28571429 C7.79166667,6.75910126 8.03417907,7.14285714 8.33333333,7.14285714 L14.8333333,7.14285714 C15.1324876,7.14285714 15.375,6.75910126 15.375,6.28571429 C15.375,5.81232731 15.1324876,5.42857143 14.8333333,5.42857143 L8.33333333,5.42857143 Z" />
);

const QuestionSet = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

QuestionSet.displayName = 'QuestionSet';

export default QuestionSet;
