import { useCallback, useContext } from 'react';

import ClassIcon from 'ms-components/icons/Class';
import TextbookIcon from 'ms-components/icons/TextbookTeacher';
import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector, {
  type Item,
  type Items,
} from 'ms-pages/Teacher/components/InputSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import {
  StateContext,
  UpdatersContext,
  EMPTY_ITEM,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import type { SchoolClasses } from 'ms-pages/Teacher/components/StudentCreateEdit/types';
import { getClassSyllabusFocus } from 'ms-pages/Teacher/components/StudentCreateEdit/utils';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';

type ClassInputSelectorProps = {
  schoolClasses: SchoolClasses;
  errorMessages: ErrorMessages;
};

const ClassInputSelector = ({
  schoolClasses,
  errorMessages,
}: ClassInputSelectorProps) => {
  const { classes, syllabusFocusSelectedClass } = useContext(StateContext);
  const { removeSyllabusFocusSelectedClass, updateSyllabusFocusSelectedClass } =
    useContext(UpdatersContext);

  const onDropdownSelect = useCallback(
    (klass: Item) => {
      const syllabusFocus = getClassSyllabusFocus(schoolClasses, klass);
      updateSyllabusFocusSelectedClass(syllabusFocus, klass);
    },
    [schoolClasses, updateSyllabusFocusSelectedClass],
  );

  const removeSelectedItem = useCallback(() => {
    removeSyllabusFocusSelectedClass();
  }, [removeSyllabusFocusSelectedClass]);

  return (
    <InputSelector
      items={classes}
      selectedItems={
        syllabusFocusSelectedClass.id !== EMPTY_ITEM.id
          ? [syllabusFocusSelectedClass]
          : []
      }
      removeSelectedItem={removeSelectedItem}
      onDropdownSelect={onDropdownSelect}
      icon={<ClassIcon size={3.5 * BASE_UNIT} />}
      showErrorState={errorMessages.length > 0}
    />
  );
};

type TextbookInputSelectorProps = {
  items: Items;
  errorMessages: ErrorMessages;
};

const TextbookInputSelector = ({
  items,
  errorMessages,
}: TextbookInputSelectorProps) => {
  const { syllabusFocus } = useContext(StateContext);
  const { updateSyllabusFocus } = useContext(UpdatersContext);

  return (
    <InputSelector
      items={items}
      selectedItems={syllabusFocus.id !== EMPTY_ITEM.id ? [syllabusFocus] : []}
      removeSelectedItem={() => updateSyllabusFocus(EMPTY_ITEM)}
      onDropdownSelect={updateSyllabusFocus}
      icon={<TextbookIcon size={3.5 * BASE_UNIT} />}
      showErrorState={errorMessages.length > 0}
    />
  );
};

type Props = {
  schoolClasses?: SchoolClasses | undefined;
  textbooks: Items;
  errorMessages: ErrorMessages;
};

const StudentTextbookFocus = ({
  schoolClasses,
  textbooks,
  errorMessages,
}: Props) => {
  const { useClassSyllabusFocus } = useContext(StateContext);
  const { removeSyllabusFocusSelectedClass, toggleUseClassSyllabusFocus } =
    useContext(UpdatersContext);

  return (
    <FieldGroup
      isFieldRequired
      title="Textbook focus"
      description="The student will see this textbook by default"
    >
      {schoolClasses != null && (
        <>
          <Separator size={2 * BASE_UNIT} />
          <Checkbox
            label="Apply the textbook focus from one of the classes above"
            checked={useClassSyllabusFocus}
            onChange={() => {
              toggleUseClassSyllabusFocus();
              removeSyllabusFocusSelectedClass();
            }}
          />
          <Separator size={3 * BASE_UNIT} />
        </>
      )}
      {useClassSyllabusFocus && schoolClasses != null ? (
        <ClassInputSelector
          schoolClasses={schoolClasses}
          errorMessages={errorMessages}
        />
      ) : (
        <TextbookInputSelector
          items={textbooks}
          errorMessages={errorMessages}
        />
      )}
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
};

export default StudentTextbookFocus;
