import { map } from 'ramda';
import { useState, useMemo } from 'react';

import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import useCreateCustomTask from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state/createCustomTask';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type {
  CreateCustomTaskVariables,
  CreateCustomTaskFromTaskTemplateVariables,
} from 'ms-pages/Teacher/components/CreateTask/types';

import type { State } from './State';
import { initialState as getInitialState } from './State';
import type { createCustomTaskFromTaskTemplateMutationVariables } from './__generated__/createCustomTaskFromTaskTemplateMutation.graphql';
import type { createCustomTaskMutationVariables } from './__generated__/createCustomTaskMutation.graphql';
import * as updaters from './updaters';

type Updaters = typeof updaters;
type CallbackUpdater<I extends any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};
export const transformUpdaters = (
  setState: (stateUpdater: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export function createCustomTaskVariables(
  {
    selectedStudents,
    selectedClasses,
    excludedStudents,
    extensionDays,
    hideHelpOptions,
    hideCalculator,
    hideRetry,
    questionVariation,
    taskDueDate,
    taskName,
    taskStartDate,
    saveThisAsTemplate,
    templateGroupId,
  }: State,
  // we only take the problemContentIds from the create task variables
  // we ignore the selected students because the user may change the selection
  // after it has been seeded.
  { problemContentIds, sourceTaskTemplateId }: CreateCustomTaskVariables,
  schoolId: string,
): createCustomTaskMutationVariables {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  return {
    title: taskName,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    enableHelp: !hideHelpOptions,
    enableCalculator: !hideCalculator,
    enableRetry: !hideRetry,
    extensionDays,
    // TODO remove this useless spread once we upgrade relay. Currently the 3rd party
    // TS type generation outputs mutable arrays for mutation inputs erroneously.
    problemContentIds: [...problemContentIds],
    randomise: questionVariation === 'varyForEachStudent',
    startDate: taskStartDate.toISOString(),
    sourceTaskTemplateId,
    shouldCreateTemplate: saveThisAsTemplate,
    templateGroupIds: templateGroupId != null ? [templateGroupId] : [],
    schoolId,
  };
}

export function createCustomTaskFromTaskTemplateVariables(
  {
    selectedStudents,
    selectedClasses,
    excludedStudents,
    extensionDays,
    hideHelpOptions,
    hideCalculator,
    hideRetry,
    questionVariation,
    taskDueDate,
    taskName,
    taskStartDate,
  }: State,
  // we only take the problemContentIds from the create task variables
  // we ignore the selected students because the user may change the selection
  // after it has been seeded.
  { sourceTaskTemplateId }: CreateCustomTaskFromTaskTemplateVariables,
): createCustomTaskFromTaskTemplateMutationVariables {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  return {
    title: taskName,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    enableHelp: !hideHelpOptions,
    enableCalculator: !hideCalculator,
    enableRetry: !hideRetry,
    extensionDays,
    randomise: questionVariation === 'varyForEachStudent',
    startDate: taskStartDate.toISOString(),
    sourceTaskTemplateId,
  };
}

export type CombinedState = {
  values: State;
  updaters: TransformedUpdaters;
};

/*
 * This returns the mutation hooks as well as managing
 * internal state for the entire form.
 *
 * Usage:
 * const [state, [createTask, {response, error}]] = useCreateCustomTaskState({...})
 * Pass problemContentIds into the hook
 * Pass the state variable into the form component you need to render
 * Call createTask to fire the mutation
 */
export function useCreateCustomTaskState({
  problemContentIds,
  selectedClasses = [],
  selectedStudents = [],
  excludedStudents = [],
  taskName: providedTaskName,
  taskStartDate: providedTaskStartDate,
  taskDueDate: providedTaskDueDate,
  sourceTaskTemplateId,
}: CreateCustomTaskVariables): [
  CombinedState,
  ReturnType<typeof useCreateCustomTask>,
] {
  const { taskStartDate, taskName, taskDueDate, ...initialState } =
    getInitialState();
  const [state, setState] = useState<State>({
    ...initialState,
    selectedClasses,
    selectedStudents,
    excludedStudents,
    taskName: providedTaskName != null ? providedTaskName : taskName,
    taskStartDate:
      providedTaskStartDate != null ? providedTaskStartDate : taskStartDate,
    taskDueDate:
      providedTaskDueDate != null ? providedTaskDueDate : taskDueDate,
  });
  const { schoolId } = useMaybeTeacherContext();
  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);
  const mutation = useCreateCustomTask(
    createCustomTaskVariables(
      state,
      {
        problemContentIds,
        sourceTaskTemplateId,
      },
      schoolId,
    ),
  );
  return [{ values: state, updaters: transformedUpdaters }, mutation];
}
