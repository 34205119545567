import moment from 'moment';
import {
  useCallback,
  useMemo,
  useState,
  type ComponentType,
  type ReactNode,
} from 'react';
import { Redirect } from 'react-router-dom';
import { graphql, useQuery } from 'relay-hooks';

import {
  FeedbackComponent,
  FeedbackWrapper,
} from 'ms-components/Feedback/FeedbackComponent';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import { MOMENT_FORMAT_DATE } from 'ms-pages/Teacher/TaskReport/TaskDetail';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import StudentLesson from 'ms-pages/Textbooks/StudentTextbook/components/StudentLesson';
import StudentWorksheets from 'ms-pages/Textbooks/StudentTextbook/components/StudentWorksheets';
import { useLanguageSelectorContext } from 'ms-pages/Textbooks/components/LanguageSelector/LanguageSelectorContext';
import NoLanguageAvailable from 'ms-pages/Textbooks/components/SubtopicDetailView/NoLanguageAvailable';
import Questions from 'ms-pages/Textbooks/components/SubtopicDetailView/Questions';
import SubtopicDetailHeader from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader';
import {
  ContentHeading,
  ContentTitle,
} from 'ms-pages/Textbooks/components/elements';
import useTextbookTabs from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import { breakPoints, fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import { styled } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type {
  StudentSubtopicDetailViewQuery,
  StudentSubtopicDetailViewQueryResponse,
} from './__generated__/StudentSubtopicDetailViewQuery.graphql';

const MainContentWrapper = styled({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const Wrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
});
const ContentWrapper = styled({
  // just a stopgap so that spinner/empty  don't render too close to the tabs
  // seems to give a bit of breath to the content too by the way
  paddingTop: 4 * BASE_UNIT,
});
const HasAssignedLessonTask: ComponentType<{
  isUnstarted: boolean;
  children: ReactNode;
}> = styled({
  default: {
    color: colors.santasGray,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    marginTop: 8 * BASE_UNIT,
    marginBottom: 8 * BASE_UNIT,
  },
  isUnstarted: {
    color: colors.crusta,
  },
});
const HasAssignedWorksheetTask: ComponentType<{
  isUnstarted: boolean;
  children: ReactNode;
}> = styled({
  default: {
    color: colors.santasGray,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    marginTop: 8 * BASE_UNIT,
    marginBottom: 8 * BASE_UNIT,
  },
  isUnstarted: {
    color: colors.crusta,
  },
});
const Bold = styled(
  {
    fontWeight: fontWeight.semibold,
  },
  'b',
);

type Props = {
  subtopicId: string;
};
function SubtopicDetailViewInner({
  subtopic,
}: {
  subtopic: NonNullable<StudentSubtopicDetailViewQueryResponse['subtopic']>;
}) {
  const {
    hasLessons,
    worksheetJsx: { transpiledJsx: worksheet },
    answerKeyJsx: { transpiledJsx: answerKey },
    earliestDueAssignedLessonTask,
    earliestDueAssignedWorksheetTask,
    id: subtopicId,
  } = subtopic;

  const [
    {
      // rename it to make it more explicit
      worksheetAnswerKeys: canAccessWorksheetAnswerKeys,
    },
  ] = useFeatureFlags();
  const hasInteractiveContent = subtopic.hasExercise;

  // We will show the worksheet tab even if there is only
  // an answer key with no worksheet
  // but the school must have access to it
  const hasWorksheetContent =
    subtopic.hasWorksheet ||
    (canAccessWorksheetAnswerKeys && subtopic.hasWorksheetAnswerKey);

  const showPracticeTab = hasInteractiveContent || hasWorksheetContent;

  const [activeTab, , getTabLink] = useTextbookTabs({
    overview: {
      shouldRender: false,
    },
    lesson: {
      shouldRender: hasLessons,
    },
    interactive: {
      shouldRender: hasInteractiveContent,
      fallbacks: ['worksheet'],
    },
    worksheet: {
      shouldRender: hasWorksheetContent,
      fallbacks: ['interactive'],
    },
    engageActivity: {
      shouldRender: false,
    },
  });

  const [scrollingElement, _setScrollingElement] = useState<HTMLDivElement>();
  const refCallback = useCallback<(element: HTMLDivElement) => void>(
    element => {
      _setScrollingElement(element);
    },
    [],
  );

  // These are used to hide the feedback component when lesson or questions tab are selected. They are changed through a callback on the Lesson and Questions components
  const hasLessonLoaded = useBoolean();
  const hasQuestionsLoaded = useBoolean();

  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  return (
    <MainContentWrapper ref={refCallback}>
      <SubtopicDetailHeader
        subtopicKey={subtopic}
        showLesson={hasLessons}
        showEngage={false}
        showOverview={false}
        showPractice={showPracticeTab}
        difficultyLevelProps={null}
      />

      <div>
        <Wrapper>
          <ContentWrapper>
            {activeTab === 'lesson' && (
              <>
                {earliestDueAssignedLessonTask != null && (
                  <HasAssignedLessonTask
                    isUnstarted={
                      earliestDueAssignedLessonTask.status === 'NOT_STARTED'
                    }
                  >
                    <Bold>Task due: </Bold>
                    {moment(
                      // TODO remove type coercion once relay is generating
                      // correct types for DateTime custom scalar
                      earliestDueAssignedLessonTask.task.dueDate as string,
                    ).format(MOMENT_FORMAT_DATE)}
                  </HasAssignedLessonTask>
                )}
                <StudentLesson
                  lessonWorkoutIdToTrack={
                    earliestDueAssignedLessonTask?.workout?.id
                  }
                  subtopicId={subtopicId}
                  scrollingElement={scrollingElement}
                  onLoad={hasLessonLoaded.setTrue}
                />
                <VSpacer height={40} />
                {hasLessonLoaded.value && (
                  <FeedbackWrapper>
                    <FeedbackComponent
                      feedbackType="LESSON"
                      subtopicId={subtopicId}
                    />
                  </FeedbackWrapper>
                )}
                <VSpacer height={85} />
              </>
            )}
            {(activeTab === 'interactive' || activeTab === 'worksheet') && (
              <>
                {activeTab === 'worksheet' && (
                  <NoLanguageAvailable locale={subtopic.worksheetJsx.locale} />
                )}
                <ContentHeading>
                  <ContentTitle>Practice</ContentTitle>
                </ContentHeading>

                {activeTab === 'interactive' &&
                  (hasEnabledAccessibilityMode ? (
                    <Redirect to={getTabLink('worksheet')} />
                  ) : (
                    <>
                      <Questions
                        onLoad={hasQuestionsLoaded.setTrue}
                        subtopicId={subtopicId}
                        shouldShowProblemMetaData={false}
                      />
                      <VSpacer height={40} />
                      {hasQuestionsLoaded.value && (
                        <FeedbackWrapper>
                          <FeedbackComponent
                            feedbackType="PRACTICE_QUESTIONS"
                            subtopicId={subtopicId}
                          />
                        </FeedbackWrapper>
                      )}
                      <VSpacer height={85} />
                    </>
                  ))}

                {worksheet != null && activeTab === 'worksheet' && (
                  <>
                    {earliestDueAssignedWorksheetTask != null && (
                      <ContentHeading>
                        <HasAssignedWorksheetTask
                          isUnstarted={
                            earliestDueAssignedWorksheetTask.status ===
                            'NOT_STARTED'
                          }
                        >
                          <Bold>Task due: </Bold>
                          {moment(
                            // TODO remove type coercion once relay is generating
                            // correct types for DateTime custom scalar
                            earliestDueAssignedWorksheetTask.task
                              .dueDate as string,
                          ).format(MOMENT_FORMAT_DATE)}
                        </HasAssignedWorksheetTask>
                      </ContentHeading>
                    )}

                    <StudentWorksheets
                      hideContentHeading
                      scrollingElement={scrollingElement}
                      subtopicId={subtopicId}
                      worksheet={worksheet}
                      worksheetWorkoutIdToTrack={
                        earliestDueAssignedWorksheetTask?.workout?.id
                      }
                      answerKey={answerKey}
                      subtopic={subtopic}
                    />
                    <VSpacer height={40} />
                    <FeedbackWrapper>
                      <FeedbackComponent
                        feedbackType="WORKSHEET"
                        subtopicId={subtopicId}
                      />
                    </FeedbackWrapper>
                    <VSpacer height={85} />
                  </>
                )}
              </>
            )}
          </ContentWrapper>
        </Wrapper>
      </div>
    </MainContentWrapper>
  );
}
export default function StudentSubtopicDetailView({ subtopicId }: Props) {
  const [language] = useLanguageSelectorContext();
  const { props: data, error } = useQuery<StudentSubtopicDetailViewQuery>(
    query,
    useMemo(() => ({ subtopicId, locale: language }), [language, subtopicId]),
    {
      networkCacheConfig: {
        // @ts-expect-error
        // I don't trust the TS relay types, I don't think they are accurate
        // for our version so leaving this here until we update to latest
        // version of relay.
        useCache: true,
        ttl: Infinity,
      },
      fetchPolicy: 'network-only',
    },
  );
  if (error != null) throw error;
  if (data == null) return <MinorSpinner />;
  const { subtopic } = data;
  if (subtopic == null) return <>Subtopic not found</>;
  return <SubtopicDetailViewInner subtopic={subtopic} />;
}
const query = graphql`
  query StudentSubtopicDetailViewQuery(
    $subtopicId: ID!
    $locale: SyllabusLocale
  ) {
    subtopic(id: $subtopicId, syllabusLocale: $locale) {
      id
      ...SubtopicDetailHeader_subtopic
      worksheetJsx {
        pdfAvailable
        transpiledJsx
        locale
      }
      answerKeyJsx {
        pdfAvailable
        transpiledJsx
        locale
      }
      hasLessons
      earliestDueAssignedLessonTask {
        id
        task {
          dueDate
        }
        status
        workout {
          id
          status
        }
      }
      earliestDueAssignedWorksheetTask {
        id
        task {
          dueDate
        }
        status
        workout {
          id
          status
        }
      }
      hasExercise
      hasAdaptivePractice
      hasWorksheet
      hasWorksheetAnswerKey
      ...jsxPrintKebabLinks_subtopic
    }
  }
`;
