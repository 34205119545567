import { useCallback, useContext, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import JsxContent from 'ms-components/JsxContent';
import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import SubtopicEmptyView from 'ms-pages/Teacher/components/CreateTask/components/SubtopicEmptyView';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import EngageTaskDetailsForm from 'ms-pages/Teacher/components/CreateTask/createEngageTask';
import { useCreateEngageTaskState } from 'ms-pages/Teacher/components/CreateTask/createEngageTask/state';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { assignableSubtopicForEngageTask } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import { colors } from 'ms-styles/colors';
import { styledVerticallyScrollable } from 'ms-utils/emotion/index';

import type { EngageTaskFlowInstructionQuery } from './__generated__/EngageTaskFlowInstructionQuery.graphql';

type Props = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic: SelectedSubtopic | null | undefined;
  taskName: string | null | undefined;
  taskCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
};
export default function EngageTaskFlow({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  taskName,
  taskCreationCallback,
  currentSyllabusId,
  setCurrentSyllabusId,
}: Props) {
  const { enqueueMessage } = useContext(SnackbarContext);
  const { stepNumber } = useCreateTaskState();
  const dispatch = useCreateTaskDispatch();
  const [state, [createEngageTask, { data, loading, error: mutationError }]] =
    useCreateEngageTaskState({
      taskName,
      selectedClasses: prefilledClasses != null ? prefilledClasses : [],
      selectedStudents: prefilledStudents != null ? prefilledStudents : [],
      excludedStudents:
        prefilledExcludedStudents != null ? prefilledExcludedStudents : [],
      taskStartDate: prefilledStartDate,
      taskDueDate: prefilledDueDate,
      activeSubtopic: prefilledSubtopic,
    });
  const { activeSubtopic } = state.values;
  const { setActiveSubtopic } = state.updaters;
  const classTasksReportUrl = useGetFirstAvailableClassTasksReportUrl(
    state.values.selectedClasses,
  );
  useEffect(
    () => {
      if (
        data?.createEngageTask.task != null &&
        data?.createEngageTask.errors.length === 0
      ) {
        enqueueMessage({
          text: 'Task Created Successfully',
          href: classTasksReportUrl,
          actionLabel: 'View tasks',
        });
        taskCreationCallback();
      } else if (
        mutationError != null ||
        (data != null && data.createEngageTask.errors.length > 0)
      ) {
        enqueueMessage({ text: 'Unable to create task' });
      }
    },
    // A lot of the objects used here don't follow good memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
  const stepNames = STEP_NAME_MAPS.engageTask();
  const nextButtonDisabledStates = {
    assign_engage_task: activeSubtopic == null,
    preview_and_task_details:
      loading ||
      !state.values.taskName ||
      (state.values.selectedClasses.length === 0 &&
        state.values.selectedStudents.length === 0),
  };
  const createEngageTaskAction = useCallback(() => {
    createEngageTask();
    dispatch({ type: 'create' });
  }, [createEngageTask, dispatch]);
  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({ selectedFlow: 'engageTask' })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={createEngageTaskAction}
      />

      {stepNumber === 0 && (
        <SidebarAndPreview
          taskType="engageTask"
          activeSubtopic={activeSubtopic}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          subtopicFilter={assignableSubtopicForEngageTask}
          disableSkillsBookTab
        >
          {activeSubtopic == null ? (
            <SubtopicEmptyView />
          ) : (
            <>
              <HeadingS>{activeSubtopic[1]}</HeadingS>
              <EngageTaskInstructions subtopicId={activeSubtopic[0]} />
            </>
          )}
        </SidebarAndPreview>
      )}
      {stepNumber === 1 && (
        <Body
          noFooterSpaceAtBottom
          isVerticallyScrollable={false}
          isVerticalOverflowHidden
          whiteBackground={false}
          style={{
            backgroundColor: colors.seashell,
            padding: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '55%',
                borderRight: `1px solid ${colors.ironLight}`,
                overflowY: 'hidden',
              }}
            >
              {activeSubtopic != null && (
                <>
                  <div
                    style={{
                      padding: 16,
                    }}
                  >
                    <HeadingS>{activeSubtopic[1]}</HeadingS>
                  </div>
                  <div
                    css={{
                      padding: '0 16px',
                      ...styledVerticallyScrollable,
                      // usually not a good practice to override styles of child
                      // elements but, we need it here since jsx-content comes
                      // with a fixed width
                      '& .jsx-content > div': {
                        width: 'unset !important',
                      },
                    }}
                  >
                    <EngageTaskInstructions subtopicId={activeSubtopic[0]} />
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                flexGrow: 1,
                padding: '24px 16px',
                ...styledVerticallyScrollable,
              }}
            >
              <EngageTaskDetailsForm state={state} />
            </div>
          </div>
        </Body>
      )}
    </>
  );
}
// TODO: This has to be moved somewhere else once we have the work for the engage task
// textbook done
function EngageTaskInstructions({ subtopicId }: { subtopicId: string }) {
  const { props: data } = useQuery<EngageTaskFlowInstructionQuery>(
    graphql`
      query EngageTaskFlowInstructionQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          id
          engageActivityTeacherNoteJsx {
            transpiledJsx
          }
        }
      }
    `,
    { subtopicId },
  );
  const content = data?.subtopic?.engageActivityTeacherNoteJsx.transpiledJsx;
  return content ? <JsxContent transpiledJsx={content} /> : null;
}
