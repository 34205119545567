import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  editCustomTaskMutationVariables,
  editCustomTaskMutationResponse,
} from './__generated__/editCustomTaskMutation.graphql';

export type { editCustomTaskMutationVariables };
const mutation = graphql`
  mutation editCustomTaskMutation(
    $taskId: ID!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $title: String!
    $startDate: DateTime!
    $dueDate: DateTime!
    $extensionDays: Int!
    $enableHelp: Boolean!
    $enableCalculator: Boolean!
    $enableRetry: Boolean!
    $first: Int!
  ) {
    editCustomTask(
      taskId: $taskId
      title: $title
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      startDate: $startDate
      dueDate: $dueDate
      extensionDays: $extensionDays
      enableHelp: $enableHelp
      enableCalculator: $enableCalculator
      enableRetry: $enableRetry
    ) {
      task {
        title
        startDate
        dueDate
        expiryDate
        allowHints
        allowCalculator
        allowRetry
        assignedTasks(first: 1000, orderBy: STUDENT_NAME_ASCENDING) {
          edges {
            node {
              id
              status
            }
          }
        }
        ...EditTaskFlyout_assignments @arguments(first: $first)
      }
      errors {
        key
      }
    }
  }
`;
export default function useEditCustomTask(
  variables: editCustomTaskMutationVariables,
): UseMutationResponse<editCustomTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
