import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';

import MiloStarEyeRecommendation from 'ms-components/illustrations/MiloStarEyeRecommendation';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { FooterLinks } from 'ms-pages/Signup/Sunflower/FooterLinks';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import { helpUrl } from 'ms-utils/urls';

import { formStyles } from './styles';

const Text = styled({
  fontSize: 16,
  textAlign: 'center',
});

const styles = StyleSheet.create({
  titleNoMargin: {
    marginBottom: 0,
  },
  container: {
    maxWidth: 480,
  },
});

function StudentSignupContactTeacher() {
  const { trackStructEvent } = useSnowplow();
  const trackingIdPrefix = 'SignupPage/StudentSignupContactTeacher';

  React.useEffect(() => {
    trackStructEvent({
      category: 'signup_sunflower',
      action: 'submitted_dob_underage',
      label: 'student',
    });
    // Only send the event on initial render (it's effectively a page view,
    // but dashboard builders find it more convenient to consider as an event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={css(formStyles.container, styles.container)}>
      <MiloStarEyeRecommendation />
      <VSpacer height={16} />
      <div className={css(formStyles.titleContainer)}>
        <div className={css(formStyles.title, styles.titleNoMargin)}>
          Ask an adult to help you sign up
        </div>
      </div>
      <VSpacer height={8} />
      <Text>
        You need to be above 13 years old to sign-up yourself.
        <br />
        To continue you can either:
        <br />
        - Ask your teacher to give you a join code
        <br />- Ask your parent/carer to create an account for you
      </Text>
      <FooterLinks
        showJoinClassButton
        showLoginLink={false}
        showParentSignupLink
        trackingIdPrefix={trackingIdPrefix}
      />
      <VSpacer height={24} />
      <Text>
        For any help or information contact our{' '}
        <AnchorButton
          href={helpUrl}
          target="_blank"
          isInline
          size="regular"
          trackingId={`${trackingIdPrefix}/HelpCenter`}
        >
          help center
        </AnchorButton>
      </Text>
    </div>
  );
}

export { StudentSignupContactTeacher };
