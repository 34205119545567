import { map } from 'ramda';
import type { ReactNode } from 'react';
import { useMemo, createContext } from 'react';

import useQueryParamState from 'ms-utils/hooks/useQueryParamState';

import type { State } from './State';
import { initialState } from './State';
import * as updaters from './updaters';

type Updaters = typeof updaters;
type TransformedUpdaters = {
  [P in keyof Updaters]: (...args: Parameters<Updaters[P]>) => void;
};

const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export const StateContext = createContext<State>(initialState);

export const UpdatersContext = createContext<TransformedUpdaters>(
  transformUpdaters(() => {}),
);

type Props = { children: ReactNode };

export function StateProvider({ children }: Props) {
  const [state, setState] = useQueryParamState(initialState);
  const transformedUpdates = useMemo(
    () => transformUpdaters(setState),
    [setState],
  );
  return (
    <StateContext.Provider value={state}>
      <UpdatersContext.Provider value={transformedUpdates}>
        {children}
      </UpdatersContext.Provider>
    </StateContext.Provider>
  );
}
