/* eslint-disable react/no-danger, jsx-a11y/no-static-element-interactions, react/sort-comp */
import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';
import MathQuill, { type IMathField } from 'mathquill';
import { test } from 'ramda';
import {
  createRef,
  Component,
  Fragment,
  type MouseEvent as SyntheticMouseEvent,
  type KeyboardEvent as SyntheticKeyboardEvent,
} from 'react';
import 'mathquill/build/mathquill-mathspace.css';

import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  fontWeight,
  mathquillStyles,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';
import { MATHQUILL_CONFIG } from 'ms-utils/misc/latexConfig/mathquill';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import Disabled from './Disabled';

type Latex = string;

type RequiredMathDisplayFeaturesType = {
  multiplicationNotation: 'CROSS' | 'DOT';
};

type Props = {
  aphroditeStyles: CSSInputTypes[];
  block?: boolean | undefined;
  value: Latex;
  requiredMathDisplayFeatures: RequiredMathDisplayFeaturesType;
  onChange: (latex: Latex) => void;
  onClick?: (
    event: SyntheticMouseEvent<HTMLDivElement>,
  ) => void | null | undefined;
  onFocus?: (event: FocusEvent) => void | null | undefined;
  onBlur?: (event: FocusEvent) => void | null | undefined;
  placeholder?: string | undefined;
  readOnly?: boolean | undefined;
  disabled?: boolean | undefined;
  tabIndex: number;
  // TODO: Do we need an onEnter?
  onKeyDown?:
    | ((event: SyntheticKeyboardEvent<HTMLDivElement>) => void)
    | undefined;
};

type DefaultProps = {
  aphroditeStyles: CSSInputTypes[];
  readOnly: boolean;
  disabled: boolean;
  tabIndex: number;
  requiredMathDisplayFeatures: RequiredMathDisplayFeaturesType;
};

// TODO: Support 'keyCommandsMap' feature.  (Keyboard shortcuts)

const MATHQUILL_EDITABLE_REGEX = /\\editable\{.*?\}/g;

const styles = StyleSheet.create({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  mathField: {
    display: 'inline-block',
    fontSize: 18,
  },
  block: {
    display: 'block',
    width: '100%',
  },
  disabled: {
    display: 'none',
  },
  placeholder: {
    color: colors.dustyGray,
    fontFamily: fontFamily.body,
    fontSize: fontSize.input,
    fontWeight: fontWeight.normal,
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    left: mathquillStyles.padding.horizontal,
    overflow: 'hidden',
    right: 0,
    transform: 'translateY(-50%)',
    whiteSpace: 'nowrap',
  },
});

const isMultiInputExpression = test(MATHQUILL_EDITABLE_REGEX);

// MathQuill internally supports a \editable{} macro, which changes the
// behaviour of a MathField.  We want to insulate this component from that
// behaviour.
// See class comment for LatexInput.
const sanitizeValue = (value: Latex): Latex => {
  if (isMultiInputExpression(value)) {
    Logger.error(
      new InvariantViolation('LatexInput received \\editable{} in latex'),
      {
        tags: { component: 'LatexInput' },
        extra: { latex: value },
      },
    );
  }
  return value.replace(MATHQUILL_EDITABLE_REGEX, '');
};

/**
 * This component is a declarative wrapper for the MathQuill.MathField API.
 * LatexInput should behave like a "controlled input".
 *
 * LatexInput should NEVER have sub-editable fields.  Use LatexMultiInput
 * instead.
 */
class LatexInput extends Component<Props> {
  static defaultProps: DefaultProps;
  textarea: HTMLTextAreaElement | null = null;
  MQ: IMathField | null | undefined;
  node: { readonly current: HTMLDivElement | null } = createRef();

  override componentDidMount() {
    const node = this.node.current;
    if (node === null) return;

    const multiplicationDisplaySymbol = getMultiplicationDisplaySymbol(
      this.props.requiredMathDisplayFeatures.multiplicationNotation,
    );

    const config = {
      ...MATHQUILL_CONFIG,
      multiplicationDisplaySymbol,
      handlers: {
        // During initialisation, we don't want to trigger onChange.
        // eslint-disable-next-line no-unused-vars
        edit: (_mathField: IMathField) => {},
      },
    };

    const MQ = MathQuill.MathField(node, config);

    // Set custom tab-index
    const textarea = (this.textarea = node.querySelector('textarea'));
    if (textarea === null) return;
    textarea.setAttribute('tabindex', String(this.props.tabIndex));
    textarea.addEventListener('blur', this.handleBlur);
    textarea.addEventListener('focus', this.handleFocus);

    this.handleReadOnlyPropChange();

    // Set the initial value.
    MQ.latex(sanitizeValue(this.props.value));

    // Connect edit handler to onChange prop.
    config.handlers.edit = mathField => {
      if (this.props.value === mathField.latex()) {
        // MathQuill will fire this 'edit' handler even when no changes have occurred.
        // Invoking .reflow() triggers this, for example. We want to make a stricter API
        // where our onChange only fires when an actual change event has occurred
        // so we bail-out here if the latex is unchanged.
        return;
      }

      this.props.onChange(mathField.latex());
    };

    // Retain the reference for later use.
    this.MQ = MQ;
  }

  override componentDidUpdate(prevProps: Props) {
    if (this.props.readOnly !== prevProps.readOnly) {
      this.handleReadOnlyPropChange();
    }

    const nextValue = sanitizeValue(this.props.value);
    // If the incoming latex value doesn't match what's currently rendered in
    // the MathField, update it.
    if (this.MQ != null && nextValue !== this.MQ.latex()) {
      this.MQ.latex(nextValue);
    }

    // If brackets or other variable size characters are rendered while in
    // disabled mode, we need to reflow to resize them correctly.
    if (
      this.MQ != null &&
      prevProps.disabled === true &&
      this.props.disabled === false
    ) {
      this.MQ.reflow();
    }
  }

  override componentWillUnmount() {
    this.blur();
  }

  blur = () => {
    if (this.textarea != null) this.textarea.blur();
  };

  focus = () => {
    if (this.textarea != null) this.textarea.focus();
  };

  select = () => {
    if (this.MQ != null) this.MQ.select();
  };

  handleBlur = (event: FocusEvent) => {
    if (this.props.onBlur != null) this.props.onBlur(event);
  };

  handleFocus = (event: FocusEvent) => {
    if (this.props.onFocus != null) this.props.onFocus(event);
  };

  handleReadOnlyPropChange = () => {
    const textarea = this.textarea;
    if (!textarea) return;

    if (this.props.readOnly) {
      textarea.setAttribute('readonly', '');
    } else {
      textarea.removeAttribute('readonly');
    }
  };

  override render() {
    const { aphroditeStyles, block, disabled, onClick, placeholder, value } =
      this.props;

    const mathFieldClass = css(
      styles.mathField,
      Boolean(block) && styles.block,
      ...aphroditeStyles,
    );

    const isFocused = window.document.activeElement === this.textarea;

    return (
      <Fragment>
        {disabled && (
          <Disabled
            aphroditeStyles={aphroditeStyles}
            block={block}
            value={value}
          />
        )}
        <div
          onClick={onClick}
          className={css(
            styles.root,
            Boolean(block) && styles.block,
            Boolean(disabled) && styles.disabled,
          )}
        >
          <style type="text/css">{`

            .${mathFieldClass}.mq-editable-field {
              border: 1px solid ${colors.iron};
              border-radius: ${borderRadiusUI}px;
              transition: border-color ${transition};
            }
            .${mathFieldClass}.mq-editable-field .mq-root-block {
              background: ${colors.white};
              border-radius: ${borderRadiusUI}px;
              padding: ${mathquillStyles.padding.shorthand};
            }

            .${mathFieldClass}.mq-editable-field.mq-focused {
              border-color: ${colors.lochmara};
            }
          `}</style>
          {value === '' && (
            <span className={css(styles.placeholder)}>{placeholder}</span>
          )}
          <div
            className={`${mathFieldClass} mq-editable-field mq-math-mode${
              isFocused ? ' mq-focused' : ''
            }`}
            ref={this.node}
            onKeyDown={event => {
              if (this.props.onKeyDown) this.props.onKeyDown(event);
            }}
          />
        </div>
      </Fragment>
    );
  }
}

LatexInput.defaultProps = {
  aphroditeStyles: [],
  disabled: false,
  readOnly: false,
  tabIndex: 0,
  requiredMathDisplayFeatures: {
    multiplicationNotation: 'CROSS',
  },
};

function getMultiplicationDisplaySymbol(
  notation: RequiredMathDisplayFeaturesType['multiplicationNotation'],
): (typeof MATHQUILL_CONFIG)['multiplicationDisplaySymbol'] {
  switch (notation) {
    case 'CROSS':
      return 'cross';
    case 'DOT':
      return 'dot';
    default:
      assertUnreachable(notation);
  }
}

export default LatexInput;
export type { RequiredMathDisplayFeaturesType };
