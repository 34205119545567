import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import ErrorCard from 'ms-pages/Teacher/components/ErrorCard';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { ModalBody } from 'ms-pages/Teacher/components/Modal';
import Modal from 'ms-ui-primitives/Modal';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';

import ClassCodeModalContent from './ClassCodeModalContent';
import type { ClassCodeModalQuery } from './__generated__/ClassCodeModalQuery.graphql';

const QUERY = graphql`
  query ClassCodeModalQuery($classId: ID!) {
    class(id: $classId) {
      pk
      id
      code
    }
  }
`;
type WrapperProps = {
  isOpen: boolean;
  closeModal: () => void;
  classId: string | null | undefined;
};
export default function Wrapper({ isOpen, closeModal, classId }: WrapperProps) {
  return typeof classId === 'string' ? (
    <ClassCodeModal isOpen={isOpen} closeModal={closeModal} classId={classId} />
  ) : null;
}
type ClassCodeModalProps = WrapperProps & {
  classId: string;
};
function ClassCodeModal({ isOpen, closeModal, classId }: ClassCodeModalProps) {
  const { props, error } = useQuery<ClassCodeModalQuery>(
    QUERY,
    { classId },
    {
      skip: !(isOpen && classId),
    },
  );
  if (error != null) return <ErrorCard />;
  if (!isOpen) return null;
  if (props == null) return <MinorSpinner />;
  if (props?.class == null) {
    Logger.error(
      new InvariantViolation(
        `Class \'${classId}\' is not found or the access is restricted`,
        {
          extra: { classId },
        },
      ),
    );
    return null;
  }
  return (
    <Modal width={560} isOpen={isOpen} onClose={closeModal} theme="dark">
      <ModalBody large white>
        <ClassCodeModalContent class={props.class} closeModal={closeModal} />
      </ModalBody>
    </Modal>
  );
}
