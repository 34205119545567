/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ChangeStudentTextbookFocusModalQueryVariables = {
    schoolId: string;
    studentId: string;
    numberOfSyllabuses: number;
};
export type ChangeStudentTextbookFocusModalQueryResponse = {
    readonly school: {
        readonly availableSyllabuses: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
        }> | null;
    } | null;
    readonly student: {
        readonly id: string;
        readonly user: {
            readonly firstName: string;
            readonly lastName: string;
            readonly syllabusFocus: {
                readonly id: string;
                readonly title: string;
            };
        };
    } | null;
};
export type ChangeStudentTextbookFocusModalQuery = {
    readonly response: ChangeStudentTextbookFocusModalQueryResponse;
    readonly variables: ChangeStudentTextbookFocusModalQueryVariables;
};



/*
query ChangeStudentTextbookFocusModalQuery(
  $schoolId: ID!
  $studentId: ID!
  $numberOfSyllabuses: Int!
) {
  school(id: $schoolId) {
    availableSyllabuses(limit: $numberOfSyllabuses) {
      id
      title
    }
    id
  }
  student(id: $studentId) {
    id
    user {
      firstName
      lastName
      syllabusFocus {
        id
        title
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfSyllabuses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studentId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "limit",
      "variableName": "numberOfSyllabuses"
    }
  ],
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "availableSyllabuses",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "studentId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabusFocus",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeStudentTextbookFocusModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Student",
        "kind": "LinkedField",
        "name": "student",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeStudentTextbookFocusModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Student",
        "kind": "LinkedField",
        "name": "student",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6935a896294c9ed247aff2d8b5f0682c",
    "id": null,
    "metadata": {},
    "name": "ChangeStudentTextbookFocusModalQuery",
    "operationKind": "query",
    "text": "query ChangeStudentTextbookFocusModalQuery(\n  $schoolId: ID!\n  $studentId: ID!\n  $numberOfSyllabuses: Int!\n) {\n  school(id: $schoolId) {\n    availableSyllabuses(limit: $numberOfSyllabuses) {\n      id\n      title\n    }\n    id\n  }\n  student(id: $studentId) {\n    id\n    user {\n      firstName\n      lastName\n      syllabusFocus {\n        id\n        title\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9cf6630d3a5dd51095889738534a055';
export default node;
