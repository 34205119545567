import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = <path d="M20.697 24L9.303 16.003 20.697 8z" />;

const CaretLeft = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={32}
    viewBoxHeight={32}
  >
    {path}
  </Icon>
);

CaretLeft.displayName = 'CaretLeft';

export default CaretLeft;
