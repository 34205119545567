import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Logout = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={14}
    viewBoxHeight={14}
  >
    <path d="M5.289 10.325a.737.737 0 0 1-.224-.564.805.805 0 0 1 .243-.564l1.42-1.42H.778a.75.75 0 0 1-.554-.223A.75.75 0 0 1 0 7c0-.22.075-.405.224-.555a.752.752 0 0 1 .554-.223h5.95l-1.44-1.439a.757.757 0 0 1-.232-.553c0-.215.077-.4.233-.555a.757.757 0 0 1 .554-.233c.214 0 .399.077.554.233l2.78 2.78a.686.686 0 0 1 .166.253c.033.091.049.188.049.292 0 .104-.016.2-.049.292a.686.686 0 0 1-.165.252l-2.8 2.8a.725.725 0 0 1-.535.214.757.757 0 0 1-.554-.233ZM1.556 14a1.5 1.5 0 0 1-1.1-.457A1.5 1.5 0 0 1 0 12.444v-2.333c0-.22.075-.405.224-.554a.752.752 0 0 1 .554-.224c.22 0 .405.075.554.224.15.149.224.334.224.554v2.333h10.888V1.556H1.556v2.333c0 .22-.075.405-.224.554a.753.753 0 0 1-.554.224.75.75 0 0 1-.554-.224A.75.75 0 0 1 0 3.889V1.556a1.5 1.5 0 0 1 .457-1.1A1.5 1.5 0 0 1 1.556 0h10.888a1.5 1.5 0 0 1 1.1.457A1.5 1.5 0 0 1 14 1.556v10.888a1.5 1.5 0 0 1-.457 1.1 1.5 1.5 0 0 1-1.099.456H1.556Z" />
  </Icon>
);

Logout.displayName = 'Logout';

export default Logout;
