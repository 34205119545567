import styled from '@emotion/styled';

import { fontFamily, fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable } from 'ms-utils/emotion';

const OPTION_HEIGHT = 36;
const OPTION_HEIGHT_SMALL = 30;
const THUMB_PADDING_SMALL = 1;
const THUMB_PADDING = 4; // the hollow space between wrapper and thumb
const ROOT_HEIGHT_SMALL = OPTION_HEIGHT_SMALL + 2 * THUMB_PADDING_SMALL;
const ROOT_HEIGHT = OPTION_HEIGHT + 2 * THUMB_PADDING;
const BORDER_RADIUS = 8 * BASE_UNIT;
const OPTION_PADDING = 2 * BASE_UNIT;
const OPTION_Z_INDEX = 1;

const getOptionWidth = (numberOfOptions: number) =>
  `${(1 / numberOfOptions) * 100}%`;

const getThumbTransform = (selectedOptionIndex: number, small: boolean) =>
  `translate3d(calc((100% + ${
    2 * (small ? THUMB_PADDING_SMALL : THUMB_PADDING)
  }px) * ${selectedOptionIndex}), 0, 0)`;

const Root = styled.div<{
  small: boolean;
  disabled: boolean;
  backgroundColor: string | undefined;
}>(({ small, disabled, backgroundColor }) => ({
  borderRadius: BORDER_RADIUS,
  borderWidth: 1,
  backgroundColor,
  borderColor: colors.iron,
  borderStyle: 'solid',

  fontFamily: fontFamily.body,
  height: small ? ROOT_HEIGHT_SMALL : ROOT_HEIGHT,
  ...tappable,
  ...(disabled
    ? {
        opacity: 0.6,
        cursor: 'disabled',
        pointerEvents: 'none',
      }
    : {}),
}));

const SwitchBase = styled.div({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  height: '100%',
});

const SwitchThumb = styled.div<{
  small: boolean;
  disabled: boolean;
}>(({ small, disabled }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  transition: `transform .3s ease`,
  backgroundColor: disabled ? colors.grayChateau : colors.white,
  borderRadius: BORDER_RADIUS,
  boxShadow: '0px 1px 7px rgba(33, 55, 77, 0.12)',
  margin: small ? THUMB_PADDING_SMALL : THUMB_PADDING,
}));

const Option = styled.div<{ selected: boolean; activeColor: string }>(
  ({ selected, activeColor }) => ({
    paddingLeft: OPTION_PADDING,
    paddingRight: OPTION_PADDING,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: fontSize.small,
    lineHeight: lineHeight.title,
    color: selected ? activeColor : colors.shuttleGray,
    transition: `color .3s ease`,
    zIndex: OPTION_Z_INDEX,
    fontWeight: fontWeight.semibold,
  }),
);

type OptionType<T> = {
  label: string;
  value: T;
};

type Props<T> = {
  width: number | string;
  options: ReadonlyArray<OptionType<T>>;
  onChange: (value: T) => void;
  value: T;
  disabled?: boolean;
  small?: boolean | undefined;
  activeColor?: string | undefined;
  backgroundColor?: string | undefined;
};

function Switch<T>({
  width,
  backgroundColor,
  options,
  value,
  onChange,
  disabled = false,
  small = false,
  activeColor = colors.grey,
}: Props<T>) {
  const OPTION_WIDTH = getOptionWidth(options.length);

  const selectedOptionIndex = options.findIndex(o => o.value === value);
  const thumbTransform = getThumbTransform(selectedOptionIndex, small);

  return (
    <Root
      disabled={disabled}
      style={{ width }}
      small={small}
      backgroundColor={backgroundColor}
    >
      <SwitchBase>
        {options.map((option, i) => (
          <Option
            activeColor={activeColor}
            style={{ width: OPTION_WIDTH }}
            key={i}
            selected={value === option.value}
            onClick={() => {
              onChange(option.value);
            }}
          >
            {option.label}
          </Option>
        ))}
        <SwitchThumb
          small={small}
          disabled={disabled}
          style={{
            width: `calc(${OPTION_WIDTH} - ${
              2 * (small ? THUMB_PADDING_SMALL : THUMB_PADDING)
            }px)`,
            transform: thumbTransform,
          }}
        />
      </SwitchBase>
    </Root>
  );
}

export default Switch;
