import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const PIP = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={17}
    viewBoxHeight={17}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.58218 14.9264V2.12637H15.3822V14.9264H2.58218ZM0.982178 1.16637C0.982178 0.812905 1.26872 0.526367 1.62218 0.526367H16.3422C16.6956 0.526367 16.9822 0.812905 16.9822 1.16637V15.8864C16.9822 16.2398 16.6956 16.5264 16.3422 16.5264H1.62218C1.26872 16.5264 0.982178 16.2398 0.982178 15.8864V1.16637ZM10.1335 2.92637C9.78004 2.92637 9.49351 3.2129 9.49351 3.56637V7.37508C9.49351 7.72854 9.78004 8.01508 10.1335 8.01508H13.9422C14.2957 8.01508 14.5822 7.72854 14.5822 7.37508V3.56637C14.5822 3.2129 14.2957 2.92637 13.9422 2.92637H10.1335Z"
      fill={color}
    />
  </Icon>
);

PIP.displayName = 'PIP';

export default PIP;
