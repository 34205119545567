import { createContext, useContext, useMemo, type ReactNode } from 'react';

type OpenGraphTags = {
  metaOgTitle: string;
  metaOgType: string;
  metaOgSiteName: string;
  metaOgImages: Array<string>;
  metaOgURL: string;
};

type TwitterCardTags = {
  metaTwitterCard: string;
};

export type SsrContextType = {
  meta: {
    pageTitle?: string | undefined;
    metaDescription?: string | undefined;
    metaFeatureImageUrl?: string | undefined;
    metaOpenGraph?: OpenGraphTags | undefined;
    metaTwitter?: TwitterCardTags | undefined;
  };
};

const DEFAULT = () => ({
  meta: {
    pageTitle: undefined,
    metaDescription: undefined,
    metaFeatureImageUrl: undefined,
    metaOpenGraph: undefined,
    metaTwitter: undefined,
  },
});

export const SSRContextP = createContext<SsrContextType>(DEFAULT());

// This context device is used to retrieve and/or pass values into an SSR render.
export function SSRContext({
  value: _value,
  children,
}: {
  value: SsrContextType | null | undefined;
  children: ReactNode;
}) {
  let value = useMemo(() => _value || DEFAULT(), [_value]);
  return <SSRContextP.Provider value={value}>{children}</SSRContextP.Provider>;
}

type SetSsrContext = (
  setter: (context: SsrContextType) => SsrContextType,
) => void;

export function useSSRContext(): [SetSsrContext] {
  const data = useContext(SSRContextP);
  return [
    setter => {
      Object.assign(data.meta, setter(data).meta);
    },
  ];
}
