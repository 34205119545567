import { useCallback } from 'react';

import { BodyXL } from 'ms-pages/Lantern/primitives/Typography';
import { FLYOUT_CONTENT_PADDING } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import TaskTypePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskTypePicker';
import type { TaskType } from 'ms-pages/Teacher/components/CreateTask/components/TaskTypePicker';
import { useCreateTaskDispatch } from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { VStack, VSpacer, HStack, HSpacer } from 'ms-ui-primitives/Stack';
import { styledVerticallyScrollable } from 'ms-utils/emotion';

export function FlowSelector({ onClose }: { onClose: () => void }) {
  const dispatch = useCreateTaskDispatch();
  const selectFlow = useCallback(
    (flowType: TaskType) => {
      dispatch({ type: 'select_flow', flowType });
    },
    [dispatch],
  );
  return (
    <HStack style={{ height: '100vh', overflowY: 'hidden' }}>
      <VStack grow>
        <VSpacer height={FLYOUT_CONTENT_PADDING.horizontal} />
        <div style={{ padding: `0 ${FLYOUT_CONTENT_PADDING.vertical}px` }}>
          <HStack center>
            <BodyXL bold>Create a task</BodyXL>
            <HSpacer width={16} grow />
            <Button size="regular" height={22} padding={0} onClick={onClose}>
              Cancel
            </Button>
          </HStack>
          <VSpacer height={8} />
        </div>
        <div
          style={{
            padding: `${BASE_UNIT}px ${FLYOUT_CONTENT_PADDING.vertical}px`,
            height: '100%',
            ...styledVerticallyScrollable,
          }}
        >
          <TaskTypePicker onSelect={selectFlow} />
        </div>
        <VSpacer height={FLYOUT_CONTENT_PADDING.horizontal} />
      </VStack>
    </HStack>
  );
}
