import moment from 'moment-timezone';
import { useEffect } from 'react';
import { graphql } from 'react-relay';

import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useMutation from 'ms-utils/relay/useMutationWithArgs';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';

import type {
  useValidateSunflowerUserDetailsMutationResponse,
  useValidateSunflowerUserDetailsMutationVariables,
} from './__generated__/useValidateSunflowerUserDetailsMutation.graphql';

const mutation = graphql`
  mutation useValidateSunflowerUserDetailsMutation(
    $country: ID!
    $countrySubdivision: ID
    $parentEmail: String
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $schoolTitle: String
    $timezone: String!
  ) {
    validateSunflowerUserDetails(
      userDetails: {
        parentEmail: $parentEmail
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
      }
      schoolDetails: {
        country: $country
        countrySubdivision: $countrySubdivision
        schoolTitle: $schoolTitle
        timezone: $timezone
      }
    ) {
      errors {
        key
        message
      }
    }
  }
`;
export default function useValidateSunflowerUserDetails(): UseMutationResponse<
  {},
  useValidateSunflowerUserDetailsMutationResponse
> {
  const { dispatch, state } = useSignupState();
  const [mutate, results] = useMutation<
    useValidateSunflowerUserDetailsMutationResponse,
    useValidateSunflowerUserDetailsMutationVariables
  >({
    mutation,
  });
  useEffect(() => {
    if (
      results.response &&
      results.response.validateSunflowerUserDetails?.errors
    ) {
      dispatch({
        type: 'setErrors',
        value: results.response.validateSunflowerUserDetails?.errors,
      });
    }
  }, [dispatch, results.response]);
  return [
    () => {
      mutate({
        parentEmail: state.parentEmail !== '' ? state.parentEmail : null,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        password: state.password,
        timezone: moment.tz.guess(),
        countrySubdivision: state.countrySubdivision?.id,
        country: state.country?.id ?? state.location,
        schoolTitle: state.schoolTitle,
      });
    },
    results,
  ];
}
