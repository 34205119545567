import type { ReactElement, ReactNode } from 'react';
import { Fragment } from 'react';

import { TableCellPlaceholder } from 'ms-pages/Teacher/components/Placeholder';
import { TooltipContent } from 'ms-pages/Teacher/components/Tooltip';
import { fontFamily, fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { styled } from 'ms-utils/emotion';

const ITEM_SPACE_RIGHT = 10 * BASE_UNIT;
const TOOLTIP_MAX_WIDTH = 180;

const Container = styled({
  fontFamily: fontFamily.body,
});

const Title = styled({
  fontSize: fontSize.headerTitle,
  fontWeight: fontWeight.semibold,
  color: colors.cloudBurst,
  marginBottom: 6 * BASE_UNIT,
});

const Items = styled({
  display: 'flex',
  alignItems: 'flex-end',
});

const Item = styled({
  default: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    ':not(:last-child)': {
      marginRight: ITEM_SPACE_RIGHT,
    },
  },
  hasBorder: {
    paddingRight: ITEM_SPACE_RIGHT,
    borderRight: `1px solid ${colors.iron}`,
  },
  alignRight: {
    marginLeft: 'auto',
  },
});

const ItemValue = styled({
  default: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.title,
  },
  lightTheme: {
    color: colors.cloudBurst,
  },
  darkTheme: {
    color: colors.white,
  },
});

const ItemContent = styled({
  display: 'flex',
  alignItems: 'center',
  marginTop: BASE_UNIT,
});

const ItemLabel = styled({
  color: colors.grayChateau,
  fontSize: fontSize.small,
  fontWeight: fontWeight.semibold,
  lineHeight: lineHeight.title,
});

const renderTooltip = (content: ReactNode, tooltipDescription: string) => (
  <Tooltip
    renderTooltipContent={() => (
      <TooltipContent maxWidth={TOOLTIP_MAX_WIDTH}>
        {tooltipDescription}
      </TooltipContent>
    )}
  >
    {content}
  </Tooltip>
);

export const EMPTY_PLACEHOLDER: ReactElement<any> = <TableCellPlaceholder />; // em dash

export type ItemProps = {
  label: string;
  value: number | string | ReactElement<any>;
  theme?: 'light' | 'dark' | undefined; // prop can be removed if design changes back to being consistently light themed for content
  hasBorder?: boolean | undefined;
  alignRight?: boolean | undefined;
  renderIcon?: (() => ReactNode) | undefined;
  tooltipDescription?: string | undefined;
};

export const SummaryItem = ({
  label,
  value,
  theme = 'light',
  hasBorder,
  alignRight,
  renderIcon,
  tooltipDescription,
}: ItemProps) => {
  const content = (
    <Fragment>
      <ItemValue lightTheme={theme === 'light'} darkTheme={theme === 'dark'}>
        {value}
      </ItemValue>
      <ItemContent>
        <ItemLabel>{label}</ItemLabel>
        {renderIcon != null && renderIcon()}
      </ItemContent>
    </Fragment>
  );

  return (
    <Item hasBorder={hasBorder} alignRight={alignRight}>
      {tooltipDescription != null
        ? renderTooltip(content, tooltipDescription)
        : content}
    </Item>
  );
};

type ChildrenArray<C> = C | C[];

type Props = {
  children: ChildrenArray<ReactElement<typeof SummaryItem> | false | null>;
  title?: string | undefined;
  marginTop?: number | string | undefined;
  marginBottom?: number | string | undefined;
  padding?: number | string | undefined;
  borderRadius?: number | string | undefined;
  background?: number | string | undefined;
};

const SummaryItems = ({ children, title, ...styleProps }: Props) => (
  <Container style={{ ...styleProps }}>
    {title && <Title>{title}</Title>}
    <Items>{children}</Items>
  </Container>
);

export default SummaryItems;
