import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

const MILLISECONDS_IN_HOUR = 3600000;
const MILLISECONDS_IN_MINUTE = 60000;
const MILLISECONDS_IN_SECOND = 1000;
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * MINUTES_IN_HOUR;

// Extend dayjs
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

/**
 * Returns a timestamp from now using dayjs. The returned timestamp is also abbreviated
 * in certain cases
 * @param {dayjs.ConfigType} timestamp A date
 * @param {dayjs.Dayjs | Date} now The current date
 * @param {boolean} showSeconds Whether to show number of seconds if < 1 minute or not. Defaults to false.
 * @return {string} The relative time of that timestamp from now
 */
export const getRelativeTime = (
  timestamp: dayjs.ConfigType,
  now: dayjs.Dayjs | Date = new Date(),
  showSeconds: boolean = false,
  withoutSuffix: boolean = false,
): string => {
  // Update locale abbreviations
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      // NOTE: The entire config object in the updateLocale plugin is untyped
      // so it's unclear if there are any type invariants that hold here.
      s(number: any) {
        if (number === 0) return 'now';
        return showSeconds ? `${number}s` : 'less than 1 min';
      },
      m: '1 min',
      mm: '%d mins',
      h: '1 hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years',
    },
  });
  return dayjs(timestamp).from(now, withoutSuffix);
};

/**
 * Formats the duration to hours, minutes, and seconds, e.g. "2 hours 35 minites",
 * "1 minute", "2 minutes 35 seconds", "5 seconds".
 * @param {number} durationMS Duration in milliseconds
 * @return {string} Formatted duration
 */
export const formatCheckInDuration = (durationMS: number): string => {
  const hours = Math.floor(durationMS / MILLISECONDS_IN_HOUR);
  const minutes =
    Math.floor(durationMS / MILLISECONDS_IN_MINUTE) - hours * MINUTES_IN_HOUR;
  const seconds =
    Math.floor(durationMS / MILLISECONDS_IN_SECOND) -
    hours * SECONDS_IN_HOUR -
    minutes * SECONDS_IN_MINUTE;

  const h = hours > 0 ? `${hours} hour${hours === 1 ? '' : 's'}` : null;
  const m = minutes > 0 ? `${minutes} minute${minutes === 1 ? '' : 's'}` : null;
  const s =
    hours === 0 && minutes === 0
      ? `${seconds} second${seconds === 1 ? '' : 's'}`
      : null;

  return [h, m, s].filter(value => value != null).join(' ');
};
