import { css, StyleSheet } from 'aphrodite';

import { fontSize, fontFamily, lineHeight } from 'ms-styles/base';

type Props = {
  majorTickPosition: ReadonlyArray<number>;
  majorTickLabel: ReadonlyArray<number>;
  axisTitle: string;
};

const styles = StyleSheet.create({
  tickLabelsBox: {
    position: 'relative',
    width: '100%',
    height: 15,
  },
  tickLabel: {
    fontSize: fontSize.label,
    lineHeight: lineHeight.body,
    position: 'absolute',
    transform: 'translateX(-50%)',
    fontFamily: fontFamily.body,
  },
  axisTitle: {
    textAlign: 'center',
    fontSize: fontSize.small,
    paddingTop: 14,
    fontFamily: fontFamily.body,
  },
});

const AxisLabels = ({
  majorTickPosition,
  majorTickLabel,
  axisTitle,
}: Props) => (
  <div>
    <div className={css(styles.tickLabelsBox)}>
      {majorTickPosition.map((tick, idx) => (
        <div
          key={tick}
          className={css(styles.tickLabel)}
          style={{ left: tick }}
        >
          {majorTickLabel[idx]}
        </div>
      ))}
    </div>
    <div className={css(styles.axisTitle)}>{axisTitle}</div>
  </div>
);

export default AxisLabels;
