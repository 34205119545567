import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // eslint-disable-line import/no-extraneous-dependencies
import type { ReactNode, ReactElement } from 'react';

import { colors, fonts } from 'ms-pages/Lantern/styles';
import { borderRadiusUI } from 'ms-styles/base';

type Placement =
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'top-start'
  | 'top'
  | 'top-end';

type Props = {
  backgroundColor?: string | undefined;
  children: ReactElement<any>;
  content: string | ReactNode;
  placement?: Placement | undefined;
  delay?: number | undefined;
  maxWidth?: number | undefined;
  padding?: number | string | undefined;
  borderRadius?: number | string | undefined;
  fontSize?: number | undefined;
  color?: string | undefined;
  boxShadow?: string | undefined;
};

export default function Tooltip({
  backgroundColor = colors.grey,
  children,
  content,
  placement = 'bottom-start',
  delay = 200,
  maxWidth = 300,
  padding = '4px 8px',
  borderRadius = borderRadiusUI,
  fontSize = 14,
  color = colors.almond50,
  boxShadow = '0px 4px 4px rgba(0, 0, 0, 0.25)',
}: Props) {
  return (
    <Tippy
      content={content}
      arrow={false}
      delay={[delay, 0]}
      maxWidth={maxWidth}
      offset={[0, 6]}
      placement={placement}
      zIndex={2147483647000} // necessary to beat portal's z-index
      css={{
        background: backgroundColor,
        boxShadow,
        color,
        fontSize,
        ...fonts.body,

        '.tippy-content': {
          padding,
          borderRadius,
        },
      }}
    >
      {children}
    </Tippy>
  );
}
