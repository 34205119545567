import { styled } from 'ms-utils/emotion';

// There is 5px vertical margin on MathContent's paragraphs
// which affects the spacing of some components that render MathContent.
export const MATH_CONTENT_MARGIN = 5;

const MathContentWrapper = styled({
  marginTop: -MATH_CONTENT_MARGIN,
  marginBottom: -MATH_CONTENT_MARGIN,
});

export default MathContentWrapper;
