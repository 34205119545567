/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TextbookType = "CORE_TEXTBOOK" | "LEGACY" | "SKILLS_BOOK";
export type TopicOverviewQueryVariables = {
    topicId: string;
};
export type TopicOverviewQueryResponse = {
    readonly topic: {
        readonly id: string;
        readonly title: string;
        readonly badgeUrl: string;
        readonly syllabus: {
            readonly id: string;
            readonly coverImageUrl: string;
            readonly textbookType: TextbookType;
        };
        readonly overviewJsx: {
            readonly transpiledJsx: string | null;
            readonly pdfUrl: string | null;
            readonly pdfAvailable: boolean;
        };
        readonly assessmentJsx: {
            readonly transpiledJsx: string | null;
            readonly pdfUrl: string | null;
            readonly pdfAvailable: boolean;
        };
        readonly answerKeyJsx: {
            readonly transpiledJsx: string | null;
            readonly pdfUrl: string | null;
            readonly pdfAvailable: boolean;
        };
    } | null;
};
export type TopicOverviewQuery = {
    readonly response: TopicOverviewQueryResponse;
    readonly variables: TopicOverviewQueryVariables;
};



/*
query TopicOverviewQuery(
  $topicId: ID!
) {
  topic(id: $topicId) {
    id
    title
    badgeUrl
    syllabus {
      id
      coverImageUrl
      textbookType
    }
    overviewJsx {
      transpiledJsx
      pdfUrl
      pdfAvailable
      id
    }
    assessmentJsx {
      transpiledJsx
      pdfUrl
      pdfAvailable
      id
    }
    answerKeyJsx {
      transpiledJsx
      pdfUrl
      pdfAvailable
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "topicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabus",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coverImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfAvailable",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicOverview",
            "kind": "LinkedField",
            "name": "overviewJsx",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicAssessment",
            "kind": "LinkedField",
            "name": "assessmentJsx",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicAnswerKey",
            "kind": "LinkedField",
            "name": "answerKeyJsx",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopicOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicOverview",
            "kind": "LinkedField",
            "name": "overviewJsx",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicAssessment",
            "kind": "LinkedField",
            "name": "assessmentJsx",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicAnswerKey",
            "kind": "LinkedField",
            "name": "answerKeyJsx",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25a865861198fe34a56a4c298fb9d1e1",
    "id": null,
    "metadata": {},
    "name": "TopicOverviewQuery",
    "operationKind": "query",
    "text": "query TopicOverviewQuery(\n  $topicId: ID!\n) {\n  topic(id: $topicId) {\n    id\n    title\n    badgeUrl\n    syllabus {\n      id\n      coverImageUrl\n      textbookType\n    }\n    overviewJsx {\n      transpiledJsx\n      pdfUrl\n      pdfAvailable\n      id\n    }\n    assessmentJsx {\n      transpiledJsx\n      pdfUrl\n      pdfAvailable\n      id\n    }\n    answerKeyJsx {\n      transpiledJsx\n      pdfUrl\n      pdfAvailable\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '44f1d5b7f547df3fde98800944246d9f';
export default node;
