/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaskTemplateGroupList_templateGroups = ReadonlyArray<{
    readonly id: string;
    readonly title: string;
    readonly thumbnailImageUrl: string;
    readonly taskTemplateCount: number;
    readonly " $refType": "TaskTemplateGroupList_templateGroups";
}>;
export type TaskTemplateGroupList_templateGroups$data = TaskTemplateGroupList_templateGroups;
export type TaskTemplateGroupList_templateGroups$key = ReadonlyArray<{
    readonly " $data"?: TaskTemplateGroupList_templateGroups$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskTemplateGroupList_templateGroups">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TaskTemplateGroupList_templateGroups",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taskTemplateCount",
      "storageKey": null
    }
  ],
  "type": "TaskTemplateGroup",
  "abstractKey": null
};
(node as any).hash = '7a93bbc5f3b77d4f177ed94ad5b47d9d';
export default node;
