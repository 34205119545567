import type { Event, EventType } from '@rive-app/react-canvas';
import { useEffect } from 'react';

import { assert } from 'ms-utils/typescript-utils';

import type {
  ArtboardName,
  Source,
  StateMachineName,
  TypedRive,
} from './useTypedRive';

/**
 * This hook listens to all events generated by Rive, not just RiveEvents. It
 * captures both RiveEvents and other event types specified by the `eventType`
 * parameter. Users of this hook may need to perform additional checks in their
 * event callback to handle specific event types, even if it seems redundant at
 * first glance. This is because the hook captures a broader range of events
 * than its name might suggest.
 */
export function useRiveGeneratedEventListener<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
>(
  rive: TypedRive<S, A, SM> | null,
  eventType: EventType,
  eventCallback: (event: Event, rive: TypedRive<S, A, SM>) => void,
) {
  useEffect(() => {
    if (rive === null) return;
    function handleRiveEvent(event: Event) {
      assert(rive !== null);
      eventCallback(event, rive);
    }
    rive.on(eventType, handleRiveEvent);
    return () => {
      rive.off(eventType, handleRiveEvent);
    };
  }, [eventCallback, eventType, rive]);
}
