import { HeadingSmall } from 'ms-pages/Lantern/primitives/Typography';
import Button from 'ms-ui-primitives/Button';
import { HSpacer } from 'ms-ui-primitives/Stack';

type Props = {
  title: string;
  showRemoveButton: boolean;
  onRemoveSubtopic: () => void;
};

export default function SubtopicTitle({
  title,
  showRemoveButton,
  onRemoveSubtopic,
}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px 16px 8px 16px',
        width: '100%',
      }}
    >
      <HeadingSmall>{title}</HeadingSmall>
      <HSpacer width={0} grow />
      {showRemoveButton && (
        <Button
          size="regular"
          color="eggplant"
          type="tertiary"
          height={24}
          padding={0}
          onClick={onRemoveSubtopic}
        >
          Remove
        </Button>
      )}
    </div>
  );
}
