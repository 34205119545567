import type { AppEnv } from 'ms-helpers/AppEnv';
import type { RuntimeType } from 'ms-helpers/AppEnv/utils';

const APP_NAMES: Partial<Record<RuntimeType, string>> = {
  ANDROID_WEBVIEW: 'Mathspace for Android',
  IOS_WEBVIEW: 'Mathspace for iOS',
  WIN8_WEBVIEW: 'Mathspace for Windows 8',
};

type Args = {
  // TODO this type was converted from Flows nullable type, which includes
  // both null and undefined. I've kept them type equivalent, but this is
  // probably not how we actually want to model this moving forward.
  workoutId: string | null | undefined;
  problemIdStr: string | null | undefined;
  subproblemId: string | null | undefined;
  workEventId: string | null | undefined;
  questionId?: string | null | undefined;
  subtopicId?: string | null | undefined;
  checkInId?: string | null | undefined;
  APP_ENV: AppEnv;
  extra?: object | undefined;
};

export default ({
  workoutId,
  problemIdStr,
  subproblemId,
  workEventId,
  questionId,
  subtopicId,
  checkInId,
  APP_ENV,
  extra = {},
}: Args) => {
  // Get the problemId number from "Problem-<number>""
  const problemId = problemIdStr == null ? '' : problemIdStr.split('-')[1];
  const problemTemplatePage = `${window.location.host}/admin/problemtoproblemtemplateadmin/${problemId}`;
  const cookie = document.cookie || '';
  const hash = window.location.hash || '';
  const screenWidth = window.screen.width || '';
  const screenHeight = window.screen.height || '';
  const resolution = `${screenWidth} x ${screenHeight}`;
  const windowWidth = window.innerWidth || '';
  const windowHeight = window.innerHeight || '';
  const windowSize = `${windowWidth} x ${windowHeight}`;
  const language = navigator.language || '';
  const platform = navigator.platform || '';
  const platformVersion = APP_ENV.RUNTIME.VERSION;
  const pageLocation = window.document.URL || '';
  const userAgent = navigator.userAgent || '';
  const name = APP_ENV.RUNTIME.TYPE;
  const nativeAppContext = [
    'ANDROID_WEBVIEW',
    'IOS_WEBVIEW',
    'WIN8_WEBVIEW',
  ].includes(APP_ENV.RUNTIME.TYPE)
    ? {
        app_name: APP_NAMES[APP_ENV.RUNTIME.TYPE],
        app_version: APP_ENV.RUNTIME.VERSION,
      }
    : undefined;

  // The field 'interactions' was previously used for storing mouse coordinates history.
  // As it doesn't seem to be used, we have decided not to provide it in the debug info.
  const debugInfo = {
    ...extra,
    workout_id: workoutId,
    problem_id: problemId,
    subproblem_id: subproblemId,
    work_event_id: workEventId,
    question_id: questionId,
    checkin_id: checkInId,
    subtopic_id: subtopicId,
    os: { name: platform },

    browserDiagnostics: {
      cookie,
      platform,
      location: pageLocation,
      size: {
        screen: resolution,
        window: windowSize,
      },
      language,
      userAgent,
      hash,
      problemTemplatePage,
    },
    browser: { version: platformVersion, name },
    resolution,
    flavor: {
      version: platformVersion,
      name: platform,
    },
    frontend_release: APP_ENV.WEB_APP_VERSION,
    platform: {
      version: platformVersion,
      name: platform,
    },
    document_size: windowSize,
    bot: 'false',
    user_agent: userAgent,
    page_url: pageLocation,
    interactions: [],
    window_size: windowSize,
    native_app_context: nativeAppContext,
  };

  return JSON.stringify(debugInfo);
};
