import { useSnowplow } from 'ms-helpers/Snowplow';
import FormErrorMessage from 'ms-pages/Teacher/components/FormErrorMessage';
import { ModalFooter, ModalTitle } from 'ms-pages/Teacher/components/Modal';
import type { ResetStudentTemporaryPasswordModal_student as Student } from 'ms-pages/Teacher/components/ResetStudentTemporaryPasswordModal/__generated__/ResetStudentTemporaryPasswordModal_student.graphql';
import useResetStudentTemporaryPassword from 'ms-pages/Teacher/components/ResetStudentTemporaryPasswordModal/useResetStudentTemporaryPassword';
import Separator from 'ms-pages/Teacher/components/Separator';
import { fontWeight, transition } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button, { sizeToFontSize } from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

const Bold = styled({
  display: 'inline',
  fontWeight: fontWeight.semibold,
});
const Loading = styled({
  default: {
    color: colors.grayChateau,
    fontSize: sizeToFontSize.medium,
    fontWeight: fontWeight.semibold,
    visibility: 'hidden',
  },
  visible: {
    transition: `visibility ${transition} 0.5s`, // with a delay
    visibility: 'visible',
  },
});
type Props = {
  student: Student;
  onClose: () => void;
  trackingLabel: 'CLASS_SETTINGS' | 'STUDENT_SETTINGS' | 'CLASS_SETTINGS_MODAL';
};
export default function ResetStudentTemporaryPasswordForm({
  student,
  onClose,
  trackingLabel,
}: Props) {
  const { withTrackStructEvent } = useSnowplow();
  const [mutation, { response, loading, errors }] =
    useResetStudentTemporaryPassword({
      studentId: student.id,
    });
  const responseErrors = response?.resetStudentTemporaryPassword.errors;
  const responseSuccessful = Boolean(
    response?.resetStudentTemporaryPassword.student,
  );
  return responseSuccessful ? (
    <>
      <ModalTitle center>Password has been reset successfully</ModalTitle>
      <ModalFooter center>
        <Button type="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  ) : (
    <>
      <ModalTitle>Are you sure?</ModalTitle>
      <Separator size={2 * BASE_UNIT} />
      <div>
        Are you sure you want to reset the password for{' '}
        <Bold>
          {student.user.lastName}, {student.user.firstName}
        </Bold>
        ?
      </div>
      {errors != null && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            Oops, there was an error. Please try again.
          </FormErrorMessage>
        </>
      )}
      {responseErrors != null && responseErrors.length > 0 && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            {responseErrors.map(({ key, message }) => (
              <div key={key}>{message}</div>
            ))}
          </FormErrorMessage>
        </>
      )}
      <ModalFooter>
        <Loading visible={Boolean(loading)}>Submitting...</Loading>
        <Button
          isDisabled={loading}
          type="secondary"
          onClick={withTrackStructEvent(mutation, {
            category: 'teacher_admin',
            action: 'clicked_reset_student_temporary_password',
            label: trackingLabel,
          })}
        >
          Reset password
        </Button>
        <Button isDisabled={loading} type="primary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
}
