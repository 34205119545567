import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  editAdaptiveTaskMutationVariables,
  editAdaptiveTaskMutationResponse,
} from './__generated__/editAdaptiveTaskMutation.graphql';

export type { editAdaptiveTaskMutationVariables };
const mutation = graphql`
  mutation editAdaptiveTaskMutation(
    $taskId: ID!
    $title: String!
    $startDate: DateTime!
    $dueDate: DateTime!
    $targetMastery: Int!
    $minimumQuestionTarget: Int!
    $allowCalculator: Boolean!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $first: Int!
  ) {
    editAdaptiveTask(
      taskId: $taskId
      title: $title
      startDate: $startDate
      dueDate: $dueDate
      targetMastery: $targetMastery
      minimumQuestionTarget: $minimumQuestionTarget
      allowCalculator: $allowCalculator
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
    ) {
      task {
        title
        startDate
        dueDate
        expiryDate
        targetMastery
        minimumQuestionTarget
        allowCalculator
        ...EditTaskFlyout_assignments @arguments(first: $first)
      }
      errors {
        key
      }
    }
  }
`;
export default function useEditAdaptiveTask(
  variables: editAdaptiveTaskMutationVariables,
): UseMutationResponse<editAdaptiveTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
