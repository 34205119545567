/**
 * @module symbols
 * @memberof latex
 */

/**
 * A map of latex symbols to unicode characters.
 * @name symbols
 */
const symbols: Record<string, string> = {
  '\\pi': 'π',
};

export default symbols;
