import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

import { fontFamily, lineHeight } from 'ms-styles/base';

export const MODAL_LATERAL_PADDING = 40;
export const MODAL_VERTICAL_PADDING = 28;
export const MODAL_VERTICAL_PADDING_MEDIUM = 48;
export const MODAL_VERTICAL_PADDING_LARGE = 64;

const styles = StyleSheet.create({
  root: {
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
  },
  verticalPaddingSmall: {
    paddingTop: MODAL_VERTICAL_PADDING,
    paddingBottom: MODAL_VERTICAL_PADDING,
  },
  verticalPaddingMedium: {
    paddingTop: MODAL_VERTICAL_PADDING_MEDIUM,
    paddingBottom: MODAL_VERTICAL_PADDING_MEDIUM,
  },
  verticalPaddingLarge: {
    paddingTop: MODAL_VERTICAL_PADDING_LARGE,
    paddingBottom: MODAL_VERTICAL_PADDING_LARGE,
  },
  horizontalPaddingSmall: {
    paddingLeft: MODAL_LATERAL_PADDING,
    paddingRight: MODAL_LATERAL_PADDING,
  },
  flex: {
    display: 'flex',
  },
  flexGrow: {
    flex: '1 1 0%',
  },
});

type Props = {
  children: ReactNode;
  verticalPadding?: 'none' | 'small' | 'medium' | 'large';
  horizontalPadding?: 'none' | 'small';
  maxHeight?: number;
  flex?: boolean;
  flexGrow?: boolean;
};

const ModalBody = ({
  children,
  verticalPadding = 'small',
  horizontalPadding = 'small',
  maxHeight,
  flex,
  flexGrow,
}: Props) => (
  <div
    className={css(
      styles.root,
      verticalPadding === 'small' && styles.verticalPaddingSmall,
      verticalPadding === 'medium' && styles.verticalPaddingMedium,
      verticalPadding === 'large' && styles.verticalPaddingLarge,
      horizontalPadding === 'small' && styles.horizontalPaddingSmall,
      flex && styles.flex,
      flexGrow && styles.flexGrow,
    )}
    style={{ maxHeight }}
  >
    {children}
  </div>
);

export default ModalBody;
