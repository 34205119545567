import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Calendar = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={14}
    viewBoxHeight={14}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9103 1.69384H11.8461V0.967466C11.8461 0.433154 11.4129 0 10.8786 0H10.8399C10.3056 0 9.87243 0.433154 9.87243 0.967466V1.69384H4.88031V0.967466C4.88031 0.433154 4.44715 0 3.91284 0H3.87414C3.33983 0 2.90668 0.433154 2.90668 0.967466V1.69384H1.84247C1.30815 1.69384 0.875 2.12699 0.875 2.6613V13.0325C0.875 13.5669 1.30815 14 1.84247 14H12.9103C13.4446 14 13.8777 13.5669 13.8777 13.0325V2.6613C13.8777 2.12699 13.4445 1.69384 12.9103 1.69384ZM12.2524 12.4714H2.50034V4.61558H12.2524V12.4714ZM3.82591 8.17088H5.50922C5.5947 8.17088 5.66401 8.10157 5.66401 8.01609V6.33277C5.66401 6.24729 5.5947 6.17798 5.50922 6.17798H3.82591C3.74042 6.17798 3.67111 6.24729 3.67111 6.33277V8.01609C3.67111 8.10157 3.74042 8.17088 3.82591 8.17088ZM8.21807 8.17088H6.53475C6.44927 8.17088 6.37996 8.10157 6.37996 8.01609V6.33277C6.37996 6.24729 6.44927 6.17798 6.53475 6.17798H8.21807C8.30355 6.17798 8.37286 6.24729 8.37286 6.33277V8.01609C8.37286 8.10157 8.30355 8.17088 8.21807 8.17088ZM9.24371 8.17088H10.927C11.0125 8.17088 11.0818 8.10157 11.0818 8.01609V6.33277C11.0818 6.24729 11.0125 6.17798 10.927 6.17798H9.24371C9.15822 6.17798 9.08891 6.24729 9.08891 6.33277V8.01609C9.08891 8.10157 9.15822 8.17088 9.24371 8.17088ZM5.50922 10.8796H3.82591C3.74042 10.8796 3.67111 10.8103 3.67111 10.7248V9.04151C3.67111 8.95603 3.74042 8.88672 3.82591 8.88672H5.50922C5.5947 8.88672 5.66401 8.95603 5.66401 9.04151V10.7248C5.66401 10.8103 5.5947 10.8796 5.50922 10.8796ZM6.53475 10.8796H8.21807C8.30355 10.8796 8.37286 10.8103 8.37286 10.7248V9.04151C8.37286 8.95603 8.30355 8.88672 8.21807 8.88672H6.53475C6.44927 8.88672 6.37996 8.95603 6.37996 9.04151V10.7248C6.37996 10.8103 6.44927 10.8796 6.53475 10.8796ZM10.927 10.8796H9.24371C9.15822 10.8796 9.08891 10.8103 9.08891 10.7248V9.04151C9.08891 8.95603 9.15822 8.88672 9.24371 8.88672H10.927C11.0125 8.88672 11.0818 8.95603 11.0818 9.04151V10.7248C11.0818 10.8103 11.0125 10.8796 10.927 10.8796Z"
      fill={color}
    />
  </Icon>
);

Calendar.displayName = 'Calendar';

export default Calendar;
