import styled from '@emotion/styled';
import { useCallback } from 'react';
import { graphql, useMutation } from 'relay-hooks';

import {
  SnackbarContextProvider,
  Snackbar,
} from 'ms-components/Snackbar/SnackbarNew';
import { ModalContent, ModalFooter } from 'ms-pages/Lantern/primitives/Modal';
import { BodyM, HeadingM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import { VSpacer, VStack } from 'ms-ui-primitives/Stack';

import type { ConfirmUpdateLearningFocusModalMutation } from './__generated__/ConfirmUpdateLearningFocusModalMutation.graphql';

const STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_UPDATE_LEARNING_FOCUS_MUTATION = graphql`
  mutation ConfirmUpdateLearningFocusModalMutation($substrandId: ID!) {
    updateLearningFocus(substrandId: $substrandId) {
      errors {
        key
        message
      }

      student {
        id
        learningFocus {
          id
          ...SunflowerStudentDashboard_learningFocus
        }
      }
    }
  }
`;
type Props = {
  isOpen: boolean;
  onClose: () => void;
  currentLearningFocusTitle?: string | undefined;
  nextLearningFocusTitle: string;
  nextSubstrandId: string;
};
export default function ConfirmUpdateLearningFocusModal({
  isOpen,
  onClose,
  currentLearningFocusTitle,
  nextLearningFocusTitle,
  nextSubstrandId,
}: Props) {
  const [_updateLearningFocus, { data, error, loading }] =
    useMutation<ConfirmUpdateLearningFocusModalMutation>(
      STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_UPDATE_LEARNING_FOCUS_MUTATION,
    );
  const updateLearningFocus = useCallback(async () => {
    await _updateLearningFocus({ variables: { substrandId: nextSubstrandId } });
    onClose();
  }, [_updateLearningFocus, nextSubstrandId, onClose]);
  const isSuccess =
    data != null &&
    data.updateLearningFocus.student?.learningFocus?.id === nextSubstrandId &&
    error == null &&
    !loading;
  const isError =
    error != null ||
    (data != null && data.updateLearningFocus.errors.length > 0);
  return (
    <>
      <ConfirmUpdateLearningFocusModalPresentational
        isOpen={isOpen}
        onClose={onClose}
        currentLearningFocusTitle={currentLearningFocusTitle}
        nextLearningFocusTitle={nextLearningFocusTitle}
        onConfirm={updateLearningFocus}
        isConfirmButtonDisabled={loading}
      />
      <SnackbarContextProvider>
        <Snackbar
          id="update-learning-focus-success"
          isOpen={isSuccess}
          message="Your skills focus was updated"
        />
        <Snackbar
          id="update-learning-focus-error"
          isOpen={isError}
          message="Something went wrong"
        />
      </SnackbarContextProvider>
    </>
  );
}
const ConfirmUpdateLearningFocusDescription = styled.div({
  maxWidth: 400,
});
export function ConfirmUpdateLearningFocusModalPresentational({
  isOpen,
  onClose,
  currentLearningFocusTitle,
  nextLearningFocusTitle,
  onConfirm,
  isConfirmButtonDisabled,
}: {
  isOpen: Props['isOpen'];
  onClose: Props['onClose'];
  currentLearningFocusTitle?: Props['currentLearningFocusTitle'];
  nextLearningFocusTitle: Props['nextLearningFocusTitle'];
  onConfirm: () => Promise<void>;
  isConfirmButtonDisabled: boolean;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} width={544}>
      <ModalContent isCentered>
        <HeadingM>Are you sure?</HeadingM>
        <VSpacer height={16} />
        <ConfirmUpdateLearningFocusDescription>
          <BodyM>
            Change your skills focus to <Bold>'{nextLearningFocusTitle}'</Bold>
            {currentLearningFocusTitle != null && (
              <>
                {' '}
                from <Bold>'{currentLearningFocusTitle}'</Bold>
              </>
            )}
            ?
          </BodyM>
        </ConfirmUpdateLearningFocusDescription>
      </ModalContent>
      <ModalFooter isCentered hasSpacer={false}>
        <VStack justify="center">
          <VSpacer height={24} />
          <Button
            size="lanternSmall"
            type="primary"
            isDisabled={isConfirmButtonDisabled}
            onClick={onConfirm}
          >
            Yes, change
          </Button>
          <VSpacer height={16} />
          <Button size="lanternSmall" isInline onClick={onClose}>
            Cancel
          </Button>
        </VStack>
      </ModalFooter>
    </Modal>
  );
}
