/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type StartSubstrandGrowthCheckInQueryVariables = {
    substrandId: string;
};
export type StartSubstrandGrowthCheckInQueryResponse = {
    readonly lantern: {
        readonly substrand: {
            readonly id: string;
            readonly title: string;
            readonly badgeUrl: string;
        } | null;
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly checkIns: ReadonlyArray<{
                readonly id: string;
                readonly type: LanternCheckInType;
            }>;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type StartSubstrandGrowthCheckInQuery = {
    readonly response: StartSubstrandGrowthCheckInQueryResponse;
    readonly variables: StartSubstrandGrowthCheckInQueryVariables;
};



/*
query StartSubstrandGrowthCheckInQuery(
  $substrandId: ID!
) {
  lantern {
    substrand(id: $substrandId) {
      id
      title
      badgeUrl
    }
    viewer {
      __typename
      ... on LanternStudent {
        checkIns {
          id
          type
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "substrandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "substrandId"
    }
  ],
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badgeUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "checkIns",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StartSubstrandGrowthCheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StartSubstrandGrowthCheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v1/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "235380d86295c233f817f57907cd198e",
    "id": null,
    "metadata": {},
    "name": "StartSubstrandGrowthCheckInQuery",
    "operationKind": "query",
    "text": "query StartSubstrandGrowthCheckInQuery(\n  $substrandId: ID!\n) {\n  lantern {\n    substrand(id: $substrandId) {\n      id\n      title\n      badgeUrl\n    }\n    viewer {\n      __typename\n      ... on LanternStudent {\n        checkIns {\n          id\n          type\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0255c33fbbac1d434a403e4bad1aa687';
export default node;
