import styled from '@emotion/styled';
import { useState, useEffect, Suspense } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import CheckMark from 'ms-components/icons/CheckMarkFilled';
import Lock from 'ms-components/icons/Lock';
import SettingsCog from 'ms-components/icons/SettingsCog';
import { useSnowplow } from 'ms-helpers/Snowplow';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import type {
  Background,
  HolidayBackgroundPreferenceType,
} from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/SunflowerStudentDashboard';
import {
  getHolidayImages,
  getIsBackgroundReleased,
} from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/urls';
import Modal from 'ms-pages/Sunflower/ui-primitives/Modal';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { ModalBody } from 'ms-ui-primitives/Modal';
import { HSpacer, HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  DashboardCustomisationButtonThemePickerQuery,
  BackgroundEnum,
  HowToUnlock,
} from './__generated__/DashboardCustomisationButtonThemePickerQuery.graphql';
import useUpdateDashboardBackgroundSelection from './useUpdateDashboardBackgroundSelection';

const ThemeSelectionBox = styled.div<{
  selected: boolean;
  image: string;
}>(
  {
    width: 120,
    height: 86,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  props => ({
    border: `2px solid ${props.selected ? colors.cloudBurst : colors.iron}`,
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'cover',
  }),
);
const ThemeGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridColumnGap: 8,
  gridRowGap: 20,
});
const ButtonContent = styled.div({
  color: colors.eggplant,
  display: 'flex',
  alignItems: 'center',
});

function getUnlockingMessage(unlockReason: HowToUnlock) {
  if (unlockReason === 'COMPLETE_A_DISCOVERY_CHECKIN') {
    return 'Complete a discovery checkin to unlock this background';
  }
  if (
    unlockReason === 'COMPLETE_CLASS_MONTHLY_EXPEDITION_CHALLENGE_AND_COLLECT'
  ) {
    return 'Complete the class monthly expedition challenge and collect it as a reward';
  }

  assertUnreachable(unlockReason);
}

function ThemeSelector({
  onClick,
  image,
  selected,
  locked,
  howToUnlock,
}: {
  onClick: () => void;
  image: string;
  selected: boolean;
  locked: boolean;
  howToUnlock: HowToUnlock | undefined | null;
}) {
  const child = (
    <ThemeSelectionBox
      onClick={() => {
        !locked && onClick();
      }}
      selected={selected && !locked}
      image={image}
    >
      {selected && !locked ? <CheckMark color={colors.grey} size={24} /> : null}
      {locked ? <Lock color={colors.grey} size={24} /> : null}
    </ThemeSelectionBox>
  );
  if (locked && howToUnlock != null) {
    return (
      <Tooltip content={getUnlockingMessage(howToUnlock)}>{child}</Tooltip>
    );
  } else {
    return <>{child}</>;
  }
}

type ThemePickerProps = {
  retry: () => void;
  onClose: () => void;
  isOpen: boolean;
  background: Props['background'];
  holidayBackgroundPreference: Props['holidayBackgroundPreference'];
  setHolidayBackgroundPreference: Props['setHolidayBackgroundPreference'];
};

function ThemePicker(props: ThemePickerProps) {
  const { onClose, isOpen } = props;
  return (
    <Modal
      title="Pick your theme"
      onClose={onClose}
      isOpen={isOpen}
      width={696}
      noMinHeight
    >
      <ModalBody>
        <ErrorBoundary
          name="DashboardCustomisationButton:ThemePicker"
          fallback={<BodyM>Failed to load themes</BodyM>}
        >
          <Suspense fallback={<LoadingSpinner />}>
            <ThemePickerQueryContent {...props} />
          </Suspense>
        </ErrorBoundary>
      </ModalBody>
    </Modal>
  );
}

function ThemePickerQueryContent({
  onClose,
  retry,
  background,
  holidayBackgroundPreference,
  setHolidayBackgroundPreference,
}: ThemePickerProps) {
  const props = useLazyLoadQuery<DashboardCustomisationButtonThemePickerQuery>(
    graphql`
      query DashboardCustomisationButtonThemePickerQuery {
        viewer {
          profile {
            __typename
            ... on Student {
              studentPreference {
                availableDashboardBackgrounds {
                  name
                  url
                  type
                  isUnlocked
                  howToUnlock
                }
              }
            }
          }
        }
      }
    `,
    {},
  );

  const { viewer } = props;
  if (
    viewer == null ||
    viewer.profile == null ||
    viewer.profile.__typename !== 'Student'
  ) {
    throw new Error('User is not a student');
  }
  const {
    profile: { studentPreference },
  } = viewer;

  if (studentPreference == null)
    throw new Error('StudentPreference does not exist');

  const _availableBackgrounds = studentPreference.availableDashboardBackgrounds;
  const availableBackgrounds = [
    ..._availableBackgrounds,
    ...getHolidayImages().filter(image => getIsBackgroundReleased(image.name)),
  ];
  const [selectedBackgroundName, setSelectedBackgroundName] =
    useState<BackgroundEnum | null>(null);
  const [updateStudentBackground, { response, errors, loading }] =
    useUpdateDashboardBackgroundSelection();
  const { trackStructEvent } = useSnowplow();
  useEffect(() => {
    if (response != null && !loading && errors == null) {
      retry();
    }
  });
  return (
    <VStack>
      <ThemeGrid>
        {availableBackgrounds.map(theme => {
          return (
            <ThemeSelector
              key={theme.name}
              onClick={() => {
                setSelectedBackgroundName(theme.name);
              }}
              image={theme.url}
              selected={
                selectedBackgroundName != null
                  ? selectedBackgroundName === theme.name
                  : background?.name === theme.name
              }
              locked={!theme.isUnlocked}
              howToUnlock={theme.howToUnlock}
            />
          );
        })}
      </ThemeGrid>
      <VSpacer height={24} />
      <HStack>
        <HSpacer width={4} grow />
        <HStack>
          <Button borderRadius={4} type="secondary" onClick={onClose}>
            Cancel
          </Button>
          <HSpacer width={12} />
          <Button
            borderRadius={4}
            type="secondary"
            isDisabled={selectedBackgroundName == null}
            onClick={() => {
              if (selectedBackgroundName != null) {
                // For Flow. This button is disabled if selectedIndex is null
                trackStructEvent({
                  category: 'student_dashboard',
                  action: 'changed_background',
                  label: selectedBackgroundName,
                });
                // If the current theme is a holiday theme, and user changes the theme,
                // we want the user to be able to change back to any theme if they want to
                if (background && background.type === 'holiday') {
                  setHolidayBackgroundPreference({
                    ...holidayBackgroundPreference,
                    [`${background.name}`]: {
                      isOverridden: true,
                      date: new Date().toISOString(),
                    },
                  });
                }
                updateStudentBackground({
                  background: selectedBackgroundName,
                });
                // Push to the end of the event loop so that updates can be made
                // before modal closes
                setTimeout(() => {
                  onClose();
                }, 0);
              }
            }}
          >
            Save
          </Button>
          <HSpacer width={28} />
        </HStack>
      </HStack>
    </VStack>
  );
}
type Props = {
  background?: Background | null | undefined;
  retry: () => void;
  holidayBackgroundPreference: HolidayBackgroundPreferenceType;
  setHolidayBackgroundPreference: (
    holidayBackgroundPreference: HolidayBackgroundPreferenceType,
  ) => void;
};
function CustomiseButton({
  retry,
  background,
  holidayBackgroundPreference,
  setHolidayBackgroundPreference,
}: Props) {
  const showThemePicker = useBoolean();
  const { trackStructEvent } = useSnowplow();

  return (
    <div style={{ color: colors.eggplant }}>
      <Button
        type="primary"
        onClick={() => {
          showThemePicker.setTrue();
          trackStructEvent({
            category: 'student_dashboard',
            action: 'clicked_customise_background',
          });
        }}
        color="white"
        label="Customise"
      >
        <ButtonContent>
          <SettingsCog />
          <HSpacer width={4} />
          <Bold>Customise</Bold>
        </ButtonContent>
      </Button>
      <ThemePicker
        onClose={showThemePicker.setFalse}
        isOpen={showThemePicker.value}
        retry={retry}
        background={background}
        holidayBackgroundPreference={holidayBackgroundPreference}
        setHolidayBackgroundPreference={setHolidayBackgroundPreference}
      />
    </div>
  );
}
export { CustomiseButton };
