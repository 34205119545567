/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TugOfWarInstructions_task = {
    readonly assignedStudentsClasses?: ReadonlyArray<{
        readonly id: string;
    }> | undefined;
    readonly " $refType": "TugOfWarInstructions_task";
};
export type TugOfWarInstructions_task$data = TugOfWarInstructions_task;
export type TugOfWarInstructions_task$key = {
    readonly " $data"?: TugOfWarInstructions_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TugOfWarInstructions_task">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TugOfWarInstructions_task",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Class",
          "kind": "LinkedField",
          "name": "assignedStudentsClasses",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "CustomTask",
      "abstractKey": null
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
(node as any).hash = '22ec930306f17fd24c9fb635400f1426';
export default node;
