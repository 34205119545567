import { useState, useCallback, useEffect } from 'react';

export default function useScrollPercent(
  scrollingElement: HTMLElement | null | undefined,
) {
  const [scrollPercent, _setScrollPercent] = useState(0);

  const setScrollPercent = useCallback(() => {
    if (scrollingElement != null) {
      const { scrollTop, scrollHeight, clientHeight } = scrollingElement;

      // scrollTop can be a fractional value, while scrollHeight and
      // clientHeight are integers. Therefore the only safe way to determine
      // whether the user is scrolled to the bottom is to check if they are
      // close enough by a threshold. In this case, a threshold of 1 pixel will
      // do the trick.
      const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
      if (isAtBottom) {
        _setScrollPercent(1);
      } else {
        _setScrollPercent((scrollTop + clientHeight) / scrollHeight);
      }
    } else {
      _setScrollPercent(0);
    }
  }, [scrollingElement]);

  useEffect(() => {
    if (scrollingElement == null) return;
    scrollingElement.addEventListener('scroll', setScrollPercent);
    return () => {
      scrollingElement.removeEventListener('scroll', setScrollPercent);
    };
  }, [scrollingElement, setScrollPercent]);

  return scrollPercent;
}

export function useMaxScrollPercent(
  scrollingElement: HTMLElement | null | undefined,
  callback: (maxScrollPercent: number) => void,
) {
  const scrollPercent = useScrollPercent(scrollingElement);
  const [maxScrollPercent, setMaxScrollPercent] = useState(0);

  useEffect(() => {
    if (scrollPercent > maxScrollPercent) {
      setMaxScrollPercent(scrollPercent);
      if (callback != null) callback(scrollPercent);
    }
    // Only runs when scrollPercent changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPercent]);

  // Reset max scroll value when scrolling element or callback change.
  // Make sure to use React.useCallback when creating the callback to be passed
  // to this hook
  useEffect(() => {
    setMaxScrollPercent(0);
    if (callback != null) callback(0);
  }, [scrollingElement, callback]);

  return maxScrollPercent;
}
