import { useContext } from 'react';

import InfoFilled from 'ms-components/icons/InfoFilled';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import { colors } from 'ms-styles/colors';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Tooltip from 'ms-ui-primitives/Tooltip';

import { StateContext, UpdatersContext } from '../state';

const ClassShowLeaderboard = () => {
  const { showLeaderboardToStudents } = useContext(StateContext);
  const { toggleShowLeaderboardToStudents } = useContext(UpdatersContext);

  return (
    <FieldGroup>
      <Checkbox
        label="Show leaderboard to students"
        checked={showLeaderboardToStudents}
        onChange={() => {
          toggleShowLeaderboardToStudents();
        }}
      />
      <Tooltip
        maxWidth={260}
        title="The leaderboard shows the top 5 students with the most points over the current week"
      >
        <InfoFilled color={colors.shuttleGray} />
      </Tooltip>
    </FieldGroup>
  );
};

export default ClassShowLeaderboard;
