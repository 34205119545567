import type { ReactNode } from 'react';

import { borderRadiusUI, zIndex, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled, styledVerticallyScrollable } from 'ms-utils/emotion';

const MAX_HEIGHT = 150;
const LATERAL_PADDING = 4 * BASE_UNIT;
const VERTICAL_PADDING = 2 * BASE_UNIT;
const DropdownElement = styled({
  marginTop: BASE_UNIT,
  paddingLeft: LATERAL_PADDING,
  paddingRight: LATERAL_PADDING,
  paddingTop: VERTICAL_PADDING,
  paddingBottom: VERTICAL_PADDING,
  maxHeight: MAX_HEIGHT,
  width: '100%',
  borderRadius: borderRadiusUI,
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
  backgroundColor: colors.white,
  zIndex: zIndex.popover,
  color: colors.shuttleGray,
  fontFamily: fontFamily.body,
});

const InnerWrapper = styled({
  ...styledVerticallyScrollable,
  maxHeight: MAX_HEIGHT - 2 * VERTICAL_PADDING,
});

export function Dropdown(props: { children: ReactNode }) {
  return (
    <DropdownElement>
      <InnerWrapper>{props.children}</InnerWrapper>
    </DropdownElement>
  );
}
