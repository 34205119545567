import type { LegacyDatum } from 'ms-components/math/GraphPlot';
import type { subproblemFragments_GraphPlotConfig } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_GraphPlotConfig.graphql';

type RequestDatum = subproblemFragments_GraphPlotConfig['requestDatum'];
// NOTE we are just going to leave the `value` type as Object because we don't
// actually have a well-defined type for the legacy graphplot datum.
export const toInternalValueFormat = (
  requestDatum: RequestDatum,
): LegacyDatum => JSON.parse(requestDatum);
export const fromInternalValueFormat = (value: Object): string =>
  JSON.stringify(value);
