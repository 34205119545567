import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Toolbox2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={15}
    viewBoxHeight={14}
  >
    <g clipPath="url(#clip0_4186_3453)">
      <path
        d="M6.37883 2.35156H8.62104C9.12857 2.35156 9.55199 2.71742 9.64211 3.19922H10.4706C10.3745 2.26354 9.58166 1.53125 8.62104 1.53125H6.37883C5.41819 1.53125 4.62541 2.26354 4.5293 3.19922H5.35776C5.44786 2.71742 5.87127 2.35156 6.37883 2.35156V2.35156Z"
        fill={color}
      />
      <path d="M6.625 6.76971H8.375V8.13687H6.625V6.76971Z" fill={color} />
      <path
        d="M12.75 4.01953H2.25C1.28504 4.01953 0.5 4.80457 0.5 5.76953V7.41016H5.80469V6.35955C5.80469 6.13304 5.98833 5.9494 6.21484 5.9494H8.78516C9.01167 5.9494 9.19531 6.13304 9.19531 6.35955V7.41016H14.5V5.76953C14.5 4.80457 13.715 4.01953 12.75 4.01953Z"
        fill={color}
      />
      <path
        d="M9.19519 8.54703C9.19519 8.77354 9.01155 8.95718 8.78503 8.95718H6.21472C5.98821 8.95718 5.80457 8.77354 5.80457 8.54703V8.23047H1.01758C1.20926 9.29674 1.40597 10.3901 1.59997 11.4582C1.70631 12.0437 2.21545 12.4688 2.81059 12.4688H12.1878C12.7831 12.4688 13.2923 12.0437 13.3985 11.458C13.5182 10.7983 13.9045 8.64511 13.9793 8.23047H9.19519V8.54703Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4186_3453">
        <rect width="14" height="14" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);

Toolbox2.displayName = 'Toolbox2';

export default Toolbox2;
