import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';

import InfoFilled from 'ms-components/icons/InfoFilled';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import FieldTitle from 'ms-ui-primitives/FieldTitle';
import { HSpacer } from 'ms-ui-primitives/Stack';
import Tooltip from 'ms-ui-primitives/Tooltip';

type Props = {
  aphroditeStyles?: CSSInputTypes[] | undefined;
  title?: string | undefined;
  description?: string | undefined;
  children?: ReactNode | undefined;
  infoTooltipText?: string | undefined;
};

const styles = StyleSheet.create({
  root: {
    marginBottom: 5 * BASE_UNIT,
  },
  childContainer: {
    marginTop: 2 * BASE_UNIT,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

const FieldGroup = ({
  aphroditeStyles = [],
  title,
  description,
  children,
  infoTooltipText,
  ...rest
}: Props) => (
  <div {...rest} className={css(styles.root, ...aphroditeStyles)}>
    {title && (
      <div className={css(styles.titleContainer)}>
        <FieldTitle title={title} description={description} />
        {infoTooltipText && (
          <Tooltip maxWidth={260} title={infoTooltipText} isInlineFlex>
            <HSpacer width={6} />
            <InfoFilled color={colors.grayChateau} />
          </Tooltip>
        )}
      </div>
    )}
    <div className={css(styles.childContainer)}>{children}</div>
  </div>
);

export default FieldGroup;
