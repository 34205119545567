import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import StartCheckInButton from 'ms-pages/Lantern/components/StartCheckInButton';
import {
  getCheckInStrandUrl,
  getCheckInSubstrandGrowthUrl,
} from 'ms-pages/Lantern/utils/urls';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { StudentSkillsMapSubstrandHeader_studentStrandStatus } from './__generated__/StudentSkillsMapSubstrandHeader_studentStrandStatus.graphql';

type Props = {
  studentStrandStatus: StudentSkillsMapSubstrandHeader_studentStrandStatus;
  strandId: string;
  substrandId: string;
  canStartCheckIn?: boolean | undefined;
};
function CheckInButton({
  studentStrandStatus,
  strandId,
  substrandId,
  canStartCheckIn,
}: Props) {
  const { trackStructEvent } = useSnowplow();

  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();

  const { label, url, event } = makeCheckInButtonConfig({
    studentStrandStatus,
    strandId,
    substrandId,
    enableOptInDiscoveryCheckIn,
  });
  return (
    <StartCheckInButton
      size="lanternSmall"
      checkInUrl={url}
      label={label}
      isDisabled={!canStartCheckIn}
      onClick={() => {
        trackStructEvent(event);
      }}
      tooltipContent={
        !canStartCheckIn ? START_CHECK_IN_DISABLED_TOOLTIP_CONTENT : undefined
      }
    />
  );
}
function makeCheckInButtonConfig({
  studentStrandStatus,
  strandId,
  substrandId,
  enableOptInDiscoveryCheckIn,
}: Props & { enableOptInDiscoveryCheckIn: boolean }) {
  switch (studentStrandStatus.status) {
    case 'DIAGNOSTIC': {
      const isCheckInToBeResumed =
        studentStrandStatus.lastWorkedOnDiagnosticCheckIn != null;
      return {
        label: enableOptInDiscoveryCheckIn
          ? isCheckInToBeResumed
            ? 'Resume discovery check-in'
            : 'Start discovery check-in'
          : isCheckInToBeResumed
          ? 'Resume check-in'
          : 'Start check-in to unlock',
        url: getCheckInStrandUrl({ strandId }),
        event: isCheckInToBeResumed
          ? ({
              category: 'student_skills_map_substrand',
              action: 'clicked_resume_check_in',
              label: 'strand',
            } as const)
          : ({
              category: 'student_skills_map_substrand',
              action: 'clicked_start_check_in',
              label: 'strand',
            } as const),
      };
    }
    case 'GROWTH': {
      return {
        label: 'Start check-in',
        url: getCheckInSubstrandGrowthUrl({ substrandId }),
        event: {
          category: 'student_skills_map_substrand',
          action: 'clicked_start_check_in',
          label: 'substrand',
        } as const,
      };
    }
    default: {
      assertUnreachable(
        studentStrandStatus.status,
        `Unknown status: ${JSON.stringify(studentStrandStatus.status)}`,
      );
    }
  }
}
export { CheckInButton };
