import type { Item, Items } from 'ms-pages/Teacher/components/InputSelector';

export type Title = string;
export type DisplayName = string;
export type Teacher = Item;
export type Teachers = Items;
export type Textbook = Item;
export type Textbooks = Items;
export type SyllabusFocus = Item;
export type ClassGrade = Item;
export type ApplyClassSyllabusFocus = boolean;
export type ApplyClassGrade = boolean;
export type UseTitleForDisplayName = boolean;
export type ShowLeaderboardToStudents = boolean;

export type State = {
  title: Title;
  displayName: DisplayName;
  teachers: Teachers;
  textbooks: Textbooks;
  syllabusFocus: SyllabusFocus;
  classGrade: ClassGrade;
  applyClassSyllabusFocus: ApplyClassSyllabusFocus;
  applyClassGrade: ApplyClassGrade;
  useTitleForDisplayName: UseTitleForDisplayName;
  showLeaderboardToStudents: ShowLeaderboardToStudents;
};

export type Updater<I extends any[]> = (...args: I) => (state: State) => State;

// a copycat of `syllabusFocus` from `StudentCreateEdit`
export const EMPTY_SYLLABUS_FOCUS = { id: '', title: '' };

export const EMPTY_CLASS_GRADE = { id: '', title: '' };

export const initialState: State = {
  title: '',
  displayName: '',
  teachers: [],
  textbooks: [],
  syllabusFocus: EMPTY_SYLLABUS_FOCUS,
  classGrade: EMPTY_CLASS_GRADE,
  applyClassSyllabusFocus: false,
  applyClassGrade: false,
  useTitleForDisplayName: true,
  showLeaderboardToStudents: true,
};
