import { css } from '@emotion/css';
import { useEffect, useRef, useState, Fragment, useCallback } from 'react';
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { graphql, useFragment } from 'react-relay';

import type { SupportedAnimationTarget } from 'ms-components/AddProblemToCartButton';
import AddProblemToCartButton, {
  ADD_BUTTON_MAX_WIDTH,
} from 'ms-components/AddProblemToCartButton';
import PreviewProblemAttachment from 'ms-components/PreviewProblem/components/PreviewProblemAttachment';
import PreviewProblemInstruction from 'ms-components/PreviewProblem/components/PreviewProblemInstruction';
import ProblemDifficultyLabel from 'ms-components/PreviewProblem/components/ProblemDifficultyLabel';
import PreviewProblemSubproblem from 'ms-components/PreviewProblem/components/Subproblems/PreviewProblemSubproblem';
import { isThereInputToRender } from 'ms-components/PreviewProblem/components/Subproblems/SubproblemInput';
import DndHandleIcon from 'ms-components/icons/DndHandle';
import DuplicateIcon from 'ms-components/icons/Duplicate';
import EllipsisHorizontalIcon from 'ms-components/icons/EllipsisHorizontal';
import TrashIcon from 'ms-components/icons/Trash2';
import TriangleDownIcon from 'ms-components/icons/TriangleDown';
import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { BodyM, BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { TextOverflowEllipsis } from 'ms-pages/Teacher/components/List';
import {
  PROBLEM_ITEM_DEFAULT_BOTTOM_MARGIN,
  PROBLEM_PADDING,
  EXPAND_BUTTON_WIDTH,
  EXPAND_BUTTON_SPACER_WIDTH,
} from 'ms-pages/Textbooks/components/ProblemItem/constants';
import { observeAndSetWidth } from 'ms-pages/Textbooks/components/ProblemItem/helpers';
import {
  ProblemWrapper,
  AttributesWrapper,
  InnerAttributesWrapper,
  problemStyles,
} from 'ms-pages/Textbooks/components/ProblemItem/sharedStyles';
import SolutionModal from 'ms-pages/Textbooks/components/SolutionModal';
import { fontFamily, fontSize, fontWeight, transition } from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import DropdownMenu from 'ms-ui-primitives/DropdownMenu';
import { HSpacer, HStack, VStack } from 'ms-ui-primitives/Stack';
import {
  onHover,
  styled,
  styledHorizontallyScrollable,
} from 'ms-utils/emotion';
import { getSubproblemIndex } from 'ms-utils/misc/getIndex';
import { formatQuestionDuration } from 'ms-utils/time';
import { getProblemPreviewUrl } from 'ms-utils/urls';

import type { ProblemItem_problemContent$key } from './__generated__/ProblemItem_problemContent.graphql';

const INDEX_BUBBLE_SIZE = 24;
// These values are handpicked and will depend on the icon used for the toggle
const DROPDOWN_MENU_HORIZONTAL_OFFSET = -10;
const DROPDOWN_MENU_VERTICAL_OFFSET = -2;
const DROPDOWN_MENU_ELLIPSIS_SIZE = 16;

const DRAG_HANDLE_SIZE = 16;
const DRAG_HANDLE_SPACER_WIDTH = 8;
const APPROXIMATE_PROBLEM_INDEX_WIDTH = 10;
const PROBLEM_INDEX_SPACER_WIDTH = 8;

const DropdownMenuWrapper = styled({
  position: 'relative', // must be relative for the dropdown menu to position properly
});

const DragHandle = styled({
  color: colors.grey10,
  transition: `all ${transition}`,
  cursor: 'grab',
  ':active': {
    cursor: 'grabbing',
    color: alternateColors.grayChateau,
  },
  ...onHover({
    color: alternateColors.grayChateau,
  }),
  userSelect: 'none',
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  WebkitTouchCallout: 'none',
});

const ActionGroupWrapper = styled({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

const indexBubble = {
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '50%',
  display: 'flex',
  fontFamily: fontFamily.body,
  fontSize: INDEX_BUBBLE_SIZE / 2,
  fontWeight: fontWeight.semibold,
  height: INDEX_BUBBLE_SIZE,
  justifyContent: 'center',
  width: INDEX_BUBBLE_SIZE,
};

const SubproblemIndex = styled({
  ...indexBubble,
  backgroundColor: colors.ironLight,
  color: colors.grayChateau,
});

const SUBPROBLEM_CONTAINER_MARGIN_RIGHT = INDEX_BUBBLE_SIZE / 2;

const SubproblemMarkerContainer = styled({
  marginRight: SUBPROBLEM_CONTAINER_MARGIN_RIGHT,
  // To force alignment with the subproblems
  transform: `translateY(${BASE_UNIT}px)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Subproblem = styled({
  display: 'flex',
});

const VerticalLine = styled({
  height: '100%',
  borderLeft: `2px solid ${colors.ironLight}`,
  width: 0,
});

export type Props = {
  problemContent: ProblemItem_problemContent$key;
  showTryProblem: boolean;
  shouldShowProblemMetaData?: boolean | undefined;
  index?: number | undefined;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  collapsedInBulk?: boolean | undefined;
  onDuplicateProblem?: (() => void) | undefined;
  onRemoveProblem?: (() => void) | undefined;
  showAddButton?: boolean | undefined;
  addToCollectionAnimationTarget?: SupportedAnimationTarget;
  useTransientProblemsCollection?: boolean | undefined;
  structEventToTrackOnAddToCollection: SupportedStructEvent | null;
};

export default function ProblemItem({
  problemContent: problemContentKey,
  showTryProblem,
  shouldShowProblemMetaData = true,
  index,
  dragHandleProps,
  collapsedInBulk,
  onDuplicateProblem,
  onRemoveProblem,
  showAddButton = false,
  addToCollectionAnimationTarget,
  useTransientProblemsCollection = false,
  structEventToTrackOnAddToCollection,
}: Props) {
  const { role } = useMaybeViewer() || { role: 'Other' };
  const isStudent = role === 'Student';
  const isProblemDraggable = dragHandleProps != null;

  const problemRef = useRef<HTMLDivElement | null>(null);
  const problemContentRef = useRef<HTMLDivElement | null>(null);
  const menuButtonRef = useRef(null);

  // User's question collapse state preference is kept in UserPreferenceContext
  const { questionsCollapsed } = useUserPreference();
  // We should use user preference collapse state
  // as the initial collapse state instead of a static value here,
  // so that the question is loaded correctly, as collapsed or expanded,
  // in the first load, based on the user's preference.
  // Otherwise, if we use a static value here,
  // the question will always be loaded as expanded or collapsed
  // (based on the default value) in the first load
  // and the user's preference will be applied only after the first load
  // which would cause a sudden change in the question's collapse state in the first load.
  const [isCompressed, setCompressed] = useState(questionsCollapsed);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isSolutionOpen, setSolutionOpen] = useState(false);
  const [problemWidth, setProblemWidth] = useState(0);
  const onOpenMenu = () => {
    setMenuOpen(true);
  };
  const onCloseMenu = () => {
    setMenuOpen(false);
  };
  const openSolution = useCallback(() => {
    setSolutionOpen(true);
  }, []);
  const closeSolution = useCallback(() => {
    setSolutionOpen(false);
  }, []);

  const problemContent = useFragment(
    graphql`
      fragment ProblemItem_problemContent on ProblemContent {
        id
        attachment
        hasSolution
        difficultyLevel
        estimatedCompletionTime
        ...PreviewProblemInstruction_problemContent
        ...PreviewProblemAttachment_problemContent
        ...PreviewProblemAttributes_problemContent
        ...SolutionModal_problemContent
        previewWorkoutCreationToken
        problemTemplateId
        isStaticQuestion
        subproblems {
          instruction
          subproblemType
          ...PreviewProblemSubproblem_subproblem
        }
      }
    `,
    problemContentKey,
  );

  if (problemContent == null) {
    throw new Error('Problem content not found');
  }

  const {
    id: problemContentId,
    problemTemplateId,
    isStaticQuestion,
    hasSolution,
    previewWorkoutCreationToken,
    estimatedCompletionTime,
  } = problemContent;

  // Subproblem parts and attachments are shown when expanded
  // so, it only makes sense to expand (and show the triangle) if at least one of those is present
  const hasSubproblemContent =
    problemContent.subproblems.some(
      ({ instruction, subproblemType }) =>
        (instruction !== null && instruction !== '') ||
        isThereInputToRender(subproblemType),
    ) || !!problemContent.attachment;

  // setting resize observer for the problem to get the width changes
  useEffect(() => {
    if (problemRef.current == null) return;
    observeAndSetWidth({
      ref: problemRef,
      widthSetter: setProblemWidth,
    });
  }, [problemRef]);

  // sets the compressed state to the collapsedInBulk prop only if it changes
  useEffect(() => {
    if (collapsedInBulk != null) {
      setCompressed(collapsedInBulk);
    }
  }, [collapsedInBulk]);

  const showProblemIndex = index != null;

  const canUseProblemWidth = problemWidth > 0;

  const problemContentWidth =
    problemWidth -
    PROBLEM_PADDING * 2 -
    (hasSubproblemContent
      ? EXPAND_BUTTON_WIDTH + EXPAND_BUTTON_SPACER_WIDTH
      : 0) -
    (showProblemIndex
      ? APPROXIMATE_PROBLEM_INDEX_WIDTH + PROBLEM_INDEX_SPACER_WIDTH
      : 0) -
    (isProblemDraggable ? DRAG_HANDLE_SIZE + DRAG_HANDLE_SPACER_WIDTH : 0);

  // Problem instruction can overflow too, although in very rare cases.
  // So we need to set a specific width for it
  // so that horizontal overflow can be handled properly by child MathContent component
  const problemInstructionWidth =
    canUseProblemWidth && showAddButton
      ? problemContentWidth - (ADD_BUTTON_MAX_WIDTH + 5) // 5 is just a little extra offset to prevent the text from touching the add button
      : !canUseProblemWidth && showAddButton
      ? '90%' // When the problem width is not available on component mount, we should set the width to 90% to prevent it overlapping with the add button, that can happen momentarily, in rare cases, when the problem width is being calculated
      : canUseProblemWidth
      ? problemContentWidth
      : undefined;

  return (
    <ProblemWrapper
      style={{
        // WE SHOULD NOT SET A MARGIN FOR THIS ELEMENT IF THE PROBLEM ITEM IS A DRAGGABLE ELEMENT
        // WE NEED TO SET THE MARGIN FOR THE DRAGGABLE ELEMENT TO ITS HIGHEST LEVEL WRAPPER ELEMENT
        // FOR DRAG AND DROP TO WORK PROPERLY
        // SEE local_modules/ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/ContentList.tsx:30
        // FOR FURTHER EXPLANATION
        ...(!isProblemDraggable
          ? { marginBottom: PROBLEM_ITEM_DEFAULT_BOTTOM_MARGIN }
          : {}),
      }}
      ref={problemRef}
    >
      <SolutionModal
        hintsVisibilityStrategy="show"
        open={isSolutionOpen}
        onClose={closeSolution}
        problemContentsKeys={[problemContent]}
      />
      <HStack style={{ padding: PROBLEM_PADDING }}>
        {isProblemDraggable && (
          <>
            <DragHandle {...dragHandleProps}>
              <DndHandleIcon color={colors.grey10} size={DRAG_HANDLE_SIZE} />
            </DragHandle>
            <HSpacer width={DRAG_HANDLE_SPACER_WIDTH} />
          </>
        )}
        {showProblemIndex && (
          <>
            <BodyM bold color="neutralGray" lineHeight="20px">
              {index}
            </BodyM>
            <HSpacer width={PROBLEM_INDEX_SPACER_WIDTH} />
          </>
        )}
        {hasSubproblemContent && (
          <>
            <Button
              color="neutralGray"
              padding={0}
              onClick={() => setCompressed(compressed => !compressed)}
              label={isCompressed ? 'Expand' : 'Collapse'}
              height={EXPAND_BUTTON_WIDTH}
            >
              <TriangleDownIcon
                aphroditeStyles={[
                  problemStyles.triangle,
                  isCompressed && problemStyles.triangleRight,
                  !isCompressed && problemStyles.chevronBottom,
                ]}
                size={16}
              />
            </Button>
            <HSpacer width={EXPAND_BUTTON_SPACER_WIDTH} />
          </>
        )}
        <VStack style={{ marginTop: '-8px' }} ref={problemContentRef}>
          <PreviewProblemInstruction
            problemContent={problemContent}
            width={problemInstructionWidth}
          />
          {hasSubproblemContent && !isCompressed && (
            <VStack
              style={
                canUseProblemWidth
                  ? {
                      width: problemContentWidth,
                      ...styledHorizontallyScrollable,
                      // to prevent the vertical scrollbar from showing up unnecessarily
                      overflowY: 'hidden',
                    }
                  : {
                      // although I didn't see any content overflow on mount,
                      // it's better to prevent overflow on mount,
                      // until problem width is available
                      // as a precaution
                      overflowX: 'hidden',
                    }
              }
            >
              <PreviewProblemAttachment
                problemContent={problemContent}
                noInternalOverflow
              />
              {problemContent.subproblems.map((subproblem, idx, arr) => (
                <Subproblem key={idx}>
                  <SubproblemMarkerContainer>
                    {arr.length > 1 ? (
                      <Fragment>
                        <SubproblemIndex>
                          {getSubproblemIndex(idx)}
                        </SubproblemIndex>
                        {idx < arr.length - 1 ? <VerticalLine /> : null}
                      </Fragment>
                    ) : null}
                  </SubproblemMarkerContainer>
                  <PreviewProblemSubproblem subproblemKey={subproblem} />
                </Subproblem>
              ))}
            </VStack>
          )}
        </VStack>
      </HStack>
      <AttributesWrapper>
        <InnerAttributesWrapper>
          {shouldShowProblemMetaData && role !== 'Student' && (
            <>
              {problemContent.difficultyLevel != null && (
                <>
                  <ProblemDifficultyLabel
                    difficulty={problemContent.difficultyLevel}
                    iconColor={colors.grey90}
                    textColor={colors.grey90}
                    useUpdatedIcon
                    iconSize={17}
                    hasBoldLabel={false}
                    iconTextSpacing={6}
                    textFontSize={fontSize.small}
                  />
                  <HSpacer width={16} />
                </>
              )}
              <BodyS color="grey90">
                <TextOverflowEllipsis>
                  {formatQuestionDuration(
                    problemContent.estimatedCompletionTime,
                    true,
                  )}
                </TextOverflowEllipsis>
              </BodyS>
              <HSpacer width={16} />
            </>
          )}
          {showTryProblem && !isStaticQuestion && (
            <>
              <AnchorButton
                type="tertiary"
                color={isStudent ? 'eggplant' : 'grey90'}
                padding={0}
                href={getProblemPreviewUrl({
                  previewWorkoutCreationToken,
                })}
                target="_blank"
                label="Try question"
                height={14}
                styles={{
                  // to align the text when it's wrapped on small screens
                  textAlign: 'center',
                }}
              >
                Try question
              </AnchorButton>
              <HSpacer width={16} />
            </>
          )}
          {hasSolution && (
            <>
              {isStudent ? (
                <DropdownMenuWrapper
                  ref={menuButtonRef}
                  style={{ height: DROPDOWN_MENU_ELLIPSIS_SIZE }}
                >
                  <Button
                    isCircle
                    onClick={onOpenMenu}
                    label="Menu"
                    padding={0}
                    height={DROPDOWN_MENU_ELLIPSIS_SIZE}
                  >
                    <EllipsisHorizontalIcon
                      useUpdatedPath
                      size={DROPDOWN_MENU_ELLIPSIS_SIZE}
                    />
                  </Button>
                  {isMenuOpen && (
                    <DropdownMenu
                      anchorRef={menuButtonRef}
                      onDismiss={onCloseMenu}
                      menuPosition="bottom-right"
                      items={[
                        {
                          key: 'reveal-solution',
                          label: 'Reveal solution',
                          action: openSolution,
                        },
                      ]}
                      hOffset={DROPDOWN_MENU_HORIZONTAL_OFFSET}
                      vOffset={DROPDOWN_MENU_VERTICAL_OFFSET + 5}
                    />
                  )}
                </DropdownMenuWrapper>
              ) : (
                <Button
                  type="tertiary"
                  color="grey90"
                  padding={0}
                  onClick={openSolution}
                  label="Reveal Solution"
                  height={14}
                >
                  Reveal Solution
                </Button>
              )}
            </>
          )}
          {(onDuplicateProblem != null || onRemoveProblem != null) && (
            <ActionGroupWrapper>
              {onDuplicateProblem != null && (
                <>
                  <Button
                    isCircle
                    onClick={() => {
                      onDuplicateProblem?.();
                    }}
                    label="Duplicate"
                    color="grey90"
                    padding={0}
                    height={14}
                    styles={{ marginBottom: -1 }} // to push down slightly for visual alignment
                  >
                    <DuplicateIcon size={14} />
                  </Button>
                  <HSpacer width={16} />
                </>
              )}
              {onRemoveProblem != null && (
                <>
                  <Button
                    isCircle
                    label="Remove"
                    onClick={() => {
                      onRemoveProblem?.();
                    }}
                    color="grey90"
                    padding={0}
                    height={14}
                  >
                    <TrashIcon size={14} />
                  </Button>
                  <HSpacer width={16} />
                </>
              )}
            </ActionGroupWrapper>
          )}
        </InnerAttributesWrapper>
      </AttributesWrapper>
      {showAddButton &&
        addToCollectionAnimationTarget != null &&
        structEventToTrackOnAddToCollection != null && (
          <div
            className={css({
              position: 'absolute',
              top: PROBLEM_PADDING,
              right: PROBLEM_PADDING,
            })}
          >
            <AddProblemToCartButton
              animationTarget={addToCollectionAnimationTarget}
              problemContent={[
                problemContentId,
                estimatedCompletionTime,
                problemTemplateId,
                previewWorkoutCreationToken,
              ]}
              useTransientProblemsCollection={useTransientProblemsCollection}
              structEventToTrackOnAdd={structEventToTrackOnAddToCollection}
            />
          </div>
        )}
    </ProblemWrapper>
  );
}
