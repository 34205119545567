import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10.1997 5.4H7.80026C6.97318 5.4 6.29995 4.7277 6.29995 3.90061V1.50031C6.29995 0.673185 6.97318 0 7.80026 0H10.1997C11.0268 0 11.6999 0.673185 11.7 1.50031V3.90061C11.7 4.7277 11.0268 5.4 10.1997 5.4ZM1.50031 12.6H3.89968C4.72681 12.6 5.4 13.2732 5.40004 14.1003V16.5006C5.40004 17.3277 4.72681 18 3.89973 18H1.50031C0.673185 18 0 17.3277 0 16.5006V14.1003C0 13.2732 0.673185 12.6 1.50031 12.6ZM16.4998 12.6H14.1003C13.2733 12.6 12.6 13.2732 12.6 14.1003V16.5006C12.6 17.3277 13.2732 18 14.1003 18H16.4998C17.3268 18 18.0001 17.3277 18.0001 16.5006V14.1003C18.0001 13.2732 17.3268 12.6 16.4998 12.6ZM6.39508 5.89843L8.00501 6.70358L5.30453 12.1033L3.6946 11.2982L6.39508 5.89843ZM9.99527 6.70354L12.695 12.1031L14.3049 11.2981L11.6051 5.89861L9.99527 6.70354ZM7.20005 14.4H10.8V16.2H7.20005V14.4Z" />
);

const Topics = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Topics.displayName = 'Topics';

export default Topics;
