import Icon from 'ms-ui-primitives/Icon';
import { hexStringToRgbaString } from 'ms-utils/colors';

import type { Props as CommonProps } from '../types';

type Props = CommonProps & {
  color: string; // color is not optional for this icon
};

const RectangleBorderedFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={30}
    viewBoxHeight={30}
  >
    <rect
      x="6"
      y="9"
      width="18"
      height="12"
      stroke={color}
      strokeWidth="3"
      fill={hexStringToRgbaString(color, 0.3)}
    />
  </Icon>
);

RectangleBorderedFilled.displayName = 'RectangleBorderedFilled';

export default RectangleBorderedFilled;
