import type { ComponentType, ReactNode } from 'react';

export const reduceComponents =
  (providers: ReadonlyArray<any>) =>
  ({ children }: { children: ReactNode }) =>
    providers.reduceRight(
      (acc, Component) => <Component>{acc}</Component>,
      children,
    );

export function withProps<Props extends { children: ReactNode }>(
  Component: ComponentType<Props>,
  partiallyAppliedProps: Omit<Props, 'children'>,
): ComponentType<Pick<Props, 'children'>> {
  const component = ({ children }: Pick<Props, 'children'>) => {
    // Coercion required as TS doesn't seem to be able to understand that
    // the children prop here must have the same type as in the concrete
    // type of Props. Seems to think that they could be different
    // subtypes of ReactNode. Not sure that logically checks out.
    const props = {
      ...partiallyAppliedProps,
      children,
    } as Props;
    return <Component {...props} />;
  };
  const displayName = Component.displayName || Component.name || 'component';
  component.displayName = `withProps(${displayName})`;
  return component;
}
