import { useSnowplow } from 'ms-helpers/Snowplow';
import type { ButtonStyleProps } from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';

type Props = ButtonStyleProps & {
  snowplowEventLabel: string;
  trackingId: string;
  buttonLabel?: string | undefined;
};

export default function FreeTextbooksBookDemoButton({
  snowplowEventLabel,
  buttonLabel,
  isRound = true,
  type = 'secondary',
  ...buttonProps
}: Props) {
  const { trackStructEvent } = useSnowplow();
  const label = buttonLabel ? buttonLabel : 'Book a Demo';

  return (
    <>
      <AnchorButton
        {...buttonProps}
        href="https://calendly.com/d/g63-tjb-bw8"
        target="_blank"
        type={type}
        label={label}
        isRound={isRound}
        onClick={() => {
          trackStructEvent({
            category: 'free_textbook',
            action: 'clicked_book_demo',
            label: snowplowEventLabel,
          });
        }}
      >
        {label}
      </AnchorButton>
    </>
  );
}
