import { useCallback, useState, useEffect } from 'react';

export default function useScrollTop() {
  const isClient = typeof window === 'object';

  const getScrollTop = useCallback(
    () => (isClient ? window.document.scrollingElement?.scrollTop : undefined),
    [isClient],
  );

  const [scrollTop, setScrollTop] = useState<number | null | undefined>(
    getScrollTop,
  );

  useEffect(() => {
    if (!isClient) {
      return () => {};
    }

    function handleScroll() {
      setScrollTop(getScrollTop());
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [getScrollTop, isClient]);

  return scrollTop;
}
