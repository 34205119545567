import { StyleSheet, css } from 'aphrodite';

import type { CategoryData, SpacingData } from '../index';
import { getXScreenValue } from '../utils';

const styles = StyleSheet.create({
  label: {
    cursor: 'default',
    fontSize: 10,
    userSelect: 'none',
  },

  graphMark: {
    stroke: '#7e7e7e',
    strokeWidth: 1,
  },
});

type Props = {
  categories: CategoryData;
  spacing: SpacingData;
  hasGapBetweenBars: boolean;
};

const Categories = ({ categories, spacing, hasGapBetweenBars }: Props) => {
  const axisYScreenCoord = spacing.maxBound;
  const contentWidth = spacing.maxBound - spacing.minBound;
  const tickMarkHeight = 10;
  const categoryLength = hasGapBetweenBars
    ? categories.length
    : categories.length + 1;

  return (
    <g>
      {categories.map((category, index) => {
        const xScreenValue = getXScreenValue(
          index,
          categoryLength,
          contentWidth,
        );
        return (
          <g key={`categoryLabel_${index}`}>
            <line
              className={css(styles.graphMark)}
              x1={xScreenValue + 0.5}
              y1={axisYScreenCoord}
              x2={xScreenValue + 0.5}
              y2={axisYScreenCoord + tickMarkHeight}
            />
            <text
              className={css(styles.label)}
              transform={`translate(${xScreenValue},${
                axisYScreenCoord + tickMarkHeight * 2
              })`}
            >
              <tspan textAnchor="middle" alignmentBaseline="middle">
                {category.title}
              </tspan>
            </text>
          </g>
        );
      })}
    </g>
  );
};

export default Categories;
