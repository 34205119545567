import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ArrowLeftNew from 'ms-components/icons/ArrowLeftNew';
import ArrowRight from 'ms-components/icons/ArrowRight';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { LinkButton } from 'ms-pages/Lantern/primitives/Button';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { useContainerBottom } from 'ms-pages/Teacher/components/FilterForm/FilterPopover';
import TeacherSubtopicDetailView from 'ms-pages/Textbooks/TeacherTextbook/components/TeacherSubtopicDetailView';
import { SearchModeEmptyState } from 'ms-pages/Textbooks/components/SearchModeEmptyState';
import SunflowerSubtopicDetailView from 'ms-pages/Textbooks/components/SunflowerSubtopicDetailView/SunflowerSubtopicDetailView';
import { colors } from 'ms-styles/colors';
import { boxShadow } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import SearchInput from 'ms-ui-primitives/SearchInput';
import { HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import useDebouncedValue from 'ms-utils/hooks/useDebouncedValue';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import { getContentBrowsingSubtopicDetailViewUrl } from 'ms-utils/urls';

import MTSSearchResults from './MTSSearchResults';
import { TextbookFilter } from './TextbookFilter';
import type { TextbookOptionsArray } from './TextbookFilter';
import type { TextbookSearchFilterSubtopicContentEnum } from './__generated__/MTSSearchResultsQuery.graphql';
import {
  SEARCH_INPUT_AND_RESULTS_SPACER_HEIGHT,
  SEARCH_INPUT_HEIGHT,
} from './constants';

const ModalWrapper = styled.div<{
  showTextbook: boolean;
}>(({ showTextbook }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: showTextbook ? '60px 20px' : 20,
}));
const GoBackButtonContainer = styled.div({
  position: 'absolute',
  top: 20,
  left: 20,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  zIndex: 3,
});
const GoToTextbookButtonContainer = styled.div({
  position: 'absolute',
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 3,
  boxShadow: boxShadow.slightUp,
});
const TextbookWrapper = styled.div({
  width: '100%',
  height: '100%',
});
const SearchWrapper = styled.div({
  flexGrow: 1,
});

export type Props = {
  searchString: string;
  classicUi: boolean;
  onClose?: () => void | undefined;
  onSearchTermUpdate?: ((searchTerm: string) => void) | undefined;
  renderInsideModal?: boolean | undefined;
  isInCATFA: boolean;
  filterBySubtopicContent?: TextbookSearchFilterSubtopicContentEnum | undefined;
  onResultClick?:
    | (({
        syllabusId,
        subtopicId,
        title,
      }: {
        syllabusId: string;
        subtopicId: string;
        title: string;
      }) => void)
    | undefined;
};
export default function MultiTextbookSearchModal({
  searchString,
  onClose,
  classicUi,
  isInCATFA,
  onSearchTermUpdate,
  onResultClick,
  filterBySubtopicContent,
  renderInsideModal = true,
}: Props) {
  const [searchTerm, setSearchTerm] = useState(searchString);
  const [selectedTextbookIds, setSelectedTextbookIds] =
    useState<TextbookOptionsArray>([]);
  const [activeTextbookData, setActiveTextbookData] = useState<
    | {
        syllabusId: string;
        subtopicId: string;
        topicId: string;
        textbookName: string;
      }
    | null
    | undefined
  >(null);
  const [snowplowSearchData, setSnowplowSearchData] = useState<
    | {
        numberOfResults: number;
        searchTerm: string;
      }
    | null
    | undefined
  >(null);
  const { width: _windowWidth, height: _windowHeight } = useWindowSize();
  const windowWidth = _windowWidth ?? 0;
  const windowHeight = _windowHeight ?? 0;
  const { trackStructEvent } = useSnowplow();
  const showTextbook = useMemo(() => {
    return activeTextbookData != null;
  }, [activeTextbookData]);
  const {
    textbookName: activeTextbookName,
    syllabusId: activeSyllabusId,
    subtopicId: activeSubtopicId,
    topicId: activeTopicId,
  } = activeTextbookData ?? {};
  const clearActiveTextbookData = useCallback(() => {
    setActiveTextbookData(null);
  }, []);

  const snowplowSearchDataDebounced = useDebouncedValue(
    snowplowSearchData,
    // using a longer debounce time to avoid sending too many events
    // because the search input is dynamic
    2000,
  );
  const filterContainerRef = useRef<HTMLDivElement>(null);
  const containerBottom = useContainerBottom(filterContainerRef.current);

  useEffect(() => {
    if (snowplowSearchDataDebounced != null) {
      const { numberOfResults, searchTerm } = snowplowSearchDataDebounced;
      trackStructEvent({
        category: isInCATFA ? 'create_task_from_anywhere' : 'textbook',
        action: 'multi_textbook_search',
        label: searchTerm,
        value: numberOfResults,
      });
    }
  }, [isInCATFA, snowplowSearchDataDebounced, trackStructEvent]);

  const multiTextbookSearch = (
    <>
      <SearchWrapper>
        <SearchInput
          searchString={searchTerm}
          placeholder="Search"
          onSubmit={searchTerm => {
            setSearchTerm(searchTerm);
            onSearchTermUpdate?.(searchTerm);
          }}
          searchIconOnLeft
          whiteBackground
          withBorder
          height={SEARCH_INPUT_HEIGHT}
          borderColor={colors.ironLight}
          placeholderColor={colors.grey90}
          trackingId={
            classicUi
              ? 'Textbooks/TopicSidebar/MultiTextbookSearchInput'
              : 'Textbooks/SunflowerTopicSidebar/MultiTextbookSearchInput'
          }
          type="dynamic"
          autoFocus
        />
      </SearchWrapper>
      <VSpacer height={SEARCH_INPUT_AND_RESULTS_SPACER_HEIGHT} />
      <TextbookFilter
        selectedTextbookIds={selectedTextbookIds}
        setSelectedTextbookIds={setSelectedTextbookIds}
        containerBottom={containerBottom}
      />
      {searchTerm !== '' ? (
        <MTSSearchResults
          searchTerm={searchTerm}
          selectedTextbookIds={selectedTextbookIds}
          filterBySubtopicContent={filterBySubtopicContent}
          setActiveTextbookData={setActiveTextbookData}
          setSnowplowSearchData={setSnowplowSearchData}
          onResultClick={onResultClick}
        />
      ) : (
        <SearchModeEmptyState
          searchString=""
          isCentered
          isSearchModal
          enableMultiTextbookSearch
        />
      )}
    </>
  );
  if (!renderInsideModal) {
    return multiTextbookSearch;
  }
  return (
    <Modal
      isOpen
      onClose={() => onClose?.()}
      theme="twilight"
      width={windowWidth * 0.8}
      height={windowHeight * 0.9}
      closeOnOverlayTap
      showCloseButton
      animateClose
      padding={0}
      ref={filterContainerRef}
    >
      <ModalWrapper showTextbook={showTextbook}>
        {showTextbook && (
          <GoBackButtonContainer>
            <Button
              onClick={clearActiveTextbookData}
              type="tertiary"
              label="close modal"
              isCircle
              height={20}
            >
              <ArrowLeftNew size={20} color={colors.grey} />
            </Button>
            <HeadingS>{activeTextbookName}</HeadingS>
          </GoBackButtonContainer>
        )}

        {showTextbook &&
        /* To make type system happy, checking all variables */
        activeSyllabusId != null &&
        activeSubtopicId != null &&
        activeTopicId != null ? (
          <>
            <TextbookWrapper>
              {!classicUi ? (
                <SunflowerSubtopicDetailView subtopicId={activeSubtopicId} />
              ) : (
                <TeacherSubtopicDetailView subtopicId={activeSubtopicId} />
              )}
            </TextbookWrapper>
            <GoToTextbookButtonContainer>
              <LinkButton
                size="lanternSmall"
                onClick={() => {
                  trackStructEvent({
                    category: 'textbook',
                    action: 'clicked_go_to_textbook_in_search_modal',
                  });
                  onClose?.();
                }}
                padding={0}
                height={52}
                borderRadius={0}
                to={getContentBrowsingSubtopicDetailViewUrl({
                  syllabusId: activeSyllabusId,
                  topicId: activeTopicId,
                  subtopicId: activeSubtopicId,
                })}
                label="Go to textbook"
                isBlock
              >
                Go to textbook
                <HSpacer width={8} />
                <ArrowRight size={18} color={colors.eggplant} />
              </LinkButton>
            </GoToTextbookButtonContainer>
          </>
        ) : (
          multiTextbookSearch
        )}
      </ModalWrapper>
    </Modal>
  );
}
