/* eslint-disable new-cap */
import { DragDropContext } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';

const dndContext = DragDropContext(TouchBackend({ enableMouseEvents: true }));

/**
 * Returns a DragDropContext HOC partially applied to a backend instance. We
 * have pulled this into its own module to ensure that only a single backend is
 * initialized. We have to guard against multiple backend initializations
 * because the root of the app is not controlled by React. This means that each
 * leaf math component that needs DnD in its subtree has to setup its own
 * DragDropContext as it doesn't share a common react root with any other math
 * component. By having each of these components fetch the same partially
 * applied DragDropContext, we can enforce only a single backend is initialized.
 *
 * @return {Function} DragDropContext HOC partially applied to a backend instance.
 */
// TODO: Remove this escaping when extra exports are added
export const getInitializedDragDropContext = () => dndContext;
