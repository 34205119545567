import { StyleSheet, css } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import { filter, test } from 'ramda';
import { useRef, useCallback, forwardRef, useMemo } from 'react';
import type {
  ReactNode,
  MouseEvent as SyntheticMouseEvent,
  FocusEvent as SyntheticFocusEvent,
  ChangeEvent as SyntheticInputEvent,
} from 'react';

import CaptureEnter from 'ms-components/CaptureEnter';
import {
  fontFamily,
  fontSize,
  borderRadiusUI,
  transition,
  inputHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import {
  useAccessibilityMode,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
} from 'ms-utils/accessibility';

function noop() {}

const getPlaceholderStyles = (placeholderColor: string | undefined) => ({
  '::-webkit-input-placeholder': {
    color: placeholderColor,
    textOverflow: 'ellipsis',
  },
  ':-moz-placeholder': {
    color: placeholderColor,
    textOverflow: 'ellipsis',
  },
  '::-moz-placeholder': {
    color: placeholderColor,
    textOverflow: 'ellipsis',
  },
  ':-ms-input-placeholder': {
    color: placeholderColor,
    textOverflow: 'ellipsis',
  },
});

export const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  input: {
    boxSizing: 'border-box', // force a consistent way to calculate height
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: fontSize.input,
    height: inputHeight,
    lineHeight: 1,
    borderRadius: borderRadiusUI,
    border: `1px solid ${colors.iron}`,
    padding: '0 12px',
    width: '100%',
    transition: `border-color ${transition}`,
    resize: 'none',
    ':focus': {
      outline: 'none',
      borderColor: colors.matisse,
    },
    ':disabled': {
      background: colors.athensGray,
      color: colors.iron,
    },
    ':invalid': {
      boxShadow: 'none',
    },
    ':-moz-submit-invalid': {
      boxShadow: 'none',
    },
    ':-moz-ui-invalid': {
      boxShadow: 'none',
    },

    ...getPlaceholderStyles(colors.grayChateau),

    '::-ms-clear': {
      display: 'none',
    },
  },
  accessibilityMode: {
    ':focus': {
      outline: 'none',
      borderColor: colors.cloudBurst,
      borderWidth: FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
    },
  },
  error: {
    border: `1px solid ${colors.cinnabar}`,
    ':focus': {
      border: `1px solid ${colors.cinnabar}`,
    },
  },
  errorMessage: {
    textAlign: 'right',
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    marginTop: 8,
  },
});

export type Props = {
  value?: string | undefined;
  defaultValue?: string | undefined;
  onClick?:
    | ((event: SyntheticMouseEvent<HTMLInputElement>) => void)
    | undefined;
  onFocus?:
    | ((event: SyntheticFocusEvent<HTMLInputElement>) => void)
    | undefined;
  onChange?:
    | ((event: SyntheticInputEvent<HTMLInputElement>) => void)
    | undefined;
  // If we want to show error state without an errorMessage
  showErrorState?: boolean | undefined;
  errorMessage?: ReactNode | undefined;
  // A regex string that will be placed inside a character set: /[<goes in here>]/
  // Only characters in this character set will be allowed in the input value.
  allowedCharSet?: string | undefined;
  onEnter?: () => void | undefined;
  aphroditeStyles?: CSSInputTypes[] | undefined;
  placeholder?: string | undefined;
  disabled?: boolean | undefined;
  type?: 'text' | 'password' | 'email' | 'number' | undefined;
  readOnly?: boolean | undefined;
  max?: string | number | undefined;
  min?: string | number | undefined;
  onBlur?: ((event: SyntheticFocusEvent<HTMLInputElement>) => void) | undefined;
  autoFocus?: boolean | undefined;
  id?: string | undefined;
  // To distinguish the element for tracking in Posthog
  trackingId?: string | undefined;
  // Prevents input from being recorded by session recording
  blockTracking?: boolean | undefined;
  placeholderColor?: string | undefined;
};

export default forwardRef<HTMLInputElement, Props>(function Input(
  {
    errorMessage,
    showErrorState,
    onEnter = () => {},
    aphroditeStyles = [],
    allowedCharSet,
    onClick,
    value,
    defaultValue,
    onFocus,
    onChange = noop,
    placeholder,
    type,
    disabled,
    readOnly,
    max,
    min,
    onBlur,
    autoFocus,
    id,
    trackingId,
    blockTracking,
    placeholderColor,
  }: Props,
  forwardedRef,
) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const trackingProps = trackingId ? { 'data-tracking-id': trackingId } : {};

  const handleChange = useCallback(
    (event: SyntheticInputEvent<HTMLInputElement>) => {
      // We want to prevent entering empty strings in inputs.
      //
      // If a string is trimmed, and then has zero length, then it consisted
      // only of whitespace.  This check still permits spaces to be present
      // with and at the end of strings.
      //
      // Mutate the event object directly so the consuonBlurg component can consume
      // the change event without any knowledge of what we are doing internally.
      const trimmedString = event.target.value.trim();
      if (trimmedString.length === 0) {
        event.target.value = trimmedString; // eslint-disable-line
      }

      if (!allowedCharSet) {
        onChange(event);
        return;
      }

      const re = new RegExp(`[${allowedCharSet}]`);
      const cleanedValue = filter(test(re), [...event.target.value]).join('');
      if (cleanedValue !== event.target.value) {
        const inputElement = inputRef.current;
        if (inputElement === null) return;

        // Determine the appropriate cursor position in the cleaned value
        const numRemovedValues =
          event.target.value.length - cleanedValue.length;
        const newSelectionPos =
          inputElement.selectionStart ?? 0 - numRemovedValues;

        // Mutate the event object directly so the consuming component can consume
        // the change event without any knowledge of what we are doing internally.
        event.target.value = cleanedValue; // eslint-disable-line

        // Manually place the cursor in the correct place on the next frame
        requestAnimationFrame(() => {
          inputElement.setSelectionRange(newSelectionPos, newSelectionPos);
        });
      }

      onChange(event);
    },
    [allowedCharSet, onChange],
  );

  const renderStyles = useMemo(
    () =>
      StyleSheet.create({
        placeholderColor: getPlaceholderStyles(placeholderColor),
      }),
    [placeholderColor],
  );
  return (
    <div className={css(styles.root)}>
      <CaptureEnter action={onEnter}>
        <input
          value={value}
          defaultValue={defaultValue}
          ref={ref => {
            if (forwardedRef != null && typeof forwardedRef === 'object')
              forwardedRef.current = ref;
            inputRef.current = ref;
          }}
          onChange={handleChange}
          onFocus={onFocus}
          onClick={onClick}
          className={
            (blockTracking ? 'block-tracking ' : '') +
            css(
              styles.input,
              (Boolean(showErrorState) || Boolean(errorMessage)) &&
                styles.error,
              hasEnabledAccessibilityMode && styles.accessibilityMode,
              placeholderColor != null && renderStyles.placeholderColor,
              ...aphroditeStyles,
            )
          }
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          readOnly={readOnly}
          max={max}
          min={min}
          onBlur={onBlur}
          autoFocus={autoFocus}
          id={id}
          {...trackingProps}
        />
      </CaptureEnter>
      {errorMessage && (
        <div className={css(styles.errorMessage)}>{errorMessage}</div>
      )}
    </div>
  );
});
