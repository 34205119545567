import { css } from 'aphrodite';
import type { ComponentType } from 'react';
import * as React from 'react';

import MathContent from 'ms-components/math/MathContent';
import { formatAspectRatio } from 'ms-components/math/MathContent/renderNode';
import VideoLauncher from 'ms-components/math/VideoLauncher';
import { PrintModeConsumer } from 'ms-helpers/PrintMode';
import { SubtopicPkContext } from 'ms-pages/Textbooks/components/SubtopicDetailView/Lesson';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import type { Props as VideoEmbedProps } from 'ms-ui-primitives/VideoEmbed';
import {
  previewWorkoutLaunchUrl,
  previewWorkoutWithSubtopicLaunchUrl,
} from 'ms-utils/urls';

import SubproblemInstruction from './SubproblemInstruction';
import SubproblemSolution from './SubproblemSolution';
import styles from './styles';
import type { Subproblem, HelpVideo } from './types';

type Html = string;

type Props = {
  id: string | null | undefined;
  attachment: Html | null | undefined;
  instruction: Html;
  subproblems: Subproblem[];
  template_id: number;
  hasSolution: boolean;
  isStaticQuestion: boolean;
  hideProblemPreview?: boolean | undefined;
};

type SubproblemListProps = {
  items: Subproblem[];
  subproblemComponent: ComponentType<any>;
};

const SubproblemList = ({
  items,
  subproblemComponent: Component,
}: SubproblemListProps) => (
  <ol
    className={css(
      styles.list,
      items.length <= 1
        ? styles.listWithoutStyleType
        : styles.listWithStyleType,
    )}
  >
    {items.map((subproblem, index) => (
      <li
        className={css(
          styles.listItem,
          index !== 0 && styles.listItemTopMargin,
        )}
        key={index}
      >
        <Component subproblem={subproblem} />
      </li>
    ))}
  </ol>
);

const mapHelpVideoToVideoEmbed = (video: HelpVideo): VideoEmbedProps => ({
  id: video.video_id,
  videoHostingService: video.source,
  aspectRatio: formatAspectRatio(video.aspect_ratio),
});

function ProblemPreview({
  attachment,
  instruction,
  subproblems,
  template_id,
  hasSolution,
  isStaticQuestion,
  hideProblemPreview,
}: Props) {
  const [isSolutionOpen, setIsSolutionOpen] = React.useState(false);

  const helpVideos = subproblems.reduce<VideoEmbedProps[]>(
    (videos, subproblem) =>
      subproblem.help_video != null
        ? [...videos, mapHelpVideoToVideoEmbed(subproblem.help_video)]
        : videos,
    [],
  );

  // This means multi-subproblem problems won't have a very sensible
  // video walkthrough (as it'll only be for 1 of n subproblems).
  // This ProblemPreview component only exists for legacy lessons
  // in old textbooks though, so we don't need to bother trying to
  // synthesize a stitched-together video for this deprecated use case.
  const firstHelpVideo = helpVideos[0];

  return (
    <div className={css(styles.root, styles.column)}>
      <div className={css(styles.container)}>
        <div className={css(styles.instruction)}>
          <MathContent content={instruction} />
          {attachment && <MathContent content={attachment} />}
        </div>
        <SubproblemList
          items={subproblems}
          subproblemComponent={SubproblemInstruction}
        />
      </div>
      <PrintModeConsumer>
        {printMode =>
          !printMode && (
            <div className={css(styles.solution)}>
              <div className={css(styles.row, styles.container)}>
                <Button
                  isDisabled={!hasSolution}
                  type="primary"
                  color="cloudBurst"
                  onClick={() => {
                    setIsSolutionOpen(!isSolutionOpen);
                  }}
                >
                  {!hasSolution
                    ? 'Solution unavailable'
                    : isSolutionOpen
                    ? 'Hide Solution'
                    : 'Reveal Solution'}
                </Button>
                {firstHelpVideo !== undefined && (
                  <>
                    <span className={css(styles.spacer)} />
                    <VideoLauncher {...firstHelpVideo} />
                  </>
                )}
                {!hideProblemPreview ? (
                  !isStaticQuestion ? (
                    <SubtopicPkContext.Consumer>
                      {subtopicPk => (
                        <AnchorButton
                          href={
                            subtopicPk
                              ? previewWorkoutWithSubtopicLaunchUrl(
                                  subtopicPk,
                                  template_id,
                                )
                              : previewWorkoutLaunchUrl(template_id)
                          }
                        >
                          Try this problem!
                        </AnchorButton>
                      )}
                    </SubtopicPkContext.Consumer>
                  ) : (
                    <div className={css(styles.notInteractive)}>
                      Not interactive
                    </div>
                  )
                ) : null}
              </div>
              {isSolutionOpen && (
                <div className={css(styles.container)}>
                  <SubproblemList
                    items={subproblems}
                    subproblemComponent={SubproblemSolution}
                  />
                </div>
              )}
            </div>
          )
        }
      </PrintModeConsumer>
    </div>
  );
}

export default ProblemPreview;
