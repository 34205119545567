/* eslint-disable no-underscore-dangle */
import { uniqBy } from 'ramda';
import { useContext, useEffect } from 'react';
import type { ComponentProps } from 'react';
import { graphql } from 'react-relay';
import { useFragment, useQuery } from 'relay-hooks';

import SnackbarComponent from 'ms-components/Snackbar/SnackbarComponent';
import { taskRefetchBus } from 'ms-helpers/Tasks/signaling';
import type { StudentSelectionPayload } from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { Label } from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import type { ErrorEnum } from 'ms-pages/Teacher/components/EditTaskFlyout/EditCustomTaskFlyout/state/__generated__/editCustomTaskMutation.graphql';
import EditEngageTaskFlyout from 'ms-pages/Teacher/components/EditTaskFlyout/EditEngageTaskFlyout';
import EditLessonTaskFlyout from 'ms-pages/Teacher/components/EditTaskFlyout/EditLessonTaskFlyout';
import EditWorksheetTaskFlyout from 'ms-pages/Teacher/components/EditTaskFlyout/EditWorksheetTaskFlyout';
import Flyout, { FlyoutContext } from 'ms-pages/Teacher/components/Flyout';
import { FlyoutBody } from 'ms-pages/Teacher/components/Flyout/components/FlyoutBody';
import { Footer } from 'ms-pages/Teacher/components/Flyout/components/Footer';
import { BackHeader } from 'ms-pages/Teacher/components/Flyout/components/Header';
import { Root } from 'ms-pages/Teacher/components/Flyout/components/Root';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { InvariantViolation } from 'ms-utils/app-logging';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import { useEditAdaptiveTaskState } from './EditAdaptiveTaskFlyout';
import EditAdaptiveTaskForm from './EditAdaptiveTaskFlyout/EditAdaptiveTaskForm';
import { useEditCheckInTaskState } from './EditCheckInTaskFlyout';
import EditCheckInTaskForm from './EditCheckInTaskFlyout/EditCheckInTaskForm';
import { useEditCustomTaskState } from './EditCustomTaskFlyout';
import EditCustomTaskForm from './EditCustomTaskFlyout/EditCustomTaskForm';
import type { EditTaskFlyoutQuery } from './__generated__/EditTaskFlyoutQuery.graphql';
import type {
  EditTaskFlyout_adaptiveTask$key,
  EditTaskFlyout_adaptiveTask$data,
} from './__generated__/EditTaskFlyout_adaptiveTask.graphql';
import type {
  EditTaskFlyout_assignments$key,
  EditTaskFlyout_assignments$data,
} from './__generated__/EditTaskFlyout_assignments.graphql';
import type { EditTaskFlyout_checkInTask$key } from './__generated__/EditTaskFlyout_checkInTask.graphql';
import type { EditTaskFlyout_customTask$key } from './__generated__/EditTaskFlyout_customTask.graphql';
import type {
  EditTaskFlyout_newAdaptiveExperienceTask$key,
  EditTaskFlyout_newAdaptiveExperienceTask$data,
} from './__generated__/EditTaskFlyout_newAdaptiveExperienceTask.graphql';
import useConfirmRemovedStudents, {
  ConfirmRemovedStudentsModal,
} from './useConfirmRemovedStudents';

type PartialClasses =
  EditTaskFlyout_assignments$data['partiallyAssignedClasses'];
const DUE_DATE_IN_THE_PAST_ERROR_KEY: ErrorEnum = 'DUE_DATE_IN_THE_PAST';
const UNAUTHORIZED_TASK_ASSIGNEES_ERROR_KEY = 'UNAUTHORIZED_TASK_ASSIGNEES';

const ADAPTIVE_TASK_FRAGMENT = graphql`
  fragment EditTaskFlyout_adaptiveTask on AdaptiveTask
  @argumentDefinitions(first: { type: "Int!" }) {
    __typename
    id
    title
    startDate
    dueDate
    expiryDate
    targetMastery
    minimumQuestionTarget
    areaOfStudy {
      id
      correctQuestionTarget
    }
    allowCalculator
    ...EditTaskFlyout_assignments @arguments(first: $first)
  }
`;

export const EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT = graphql`
  fragment EditTaskFlyout_assignments on TaskInterface
  @argumentDefinitions(first: { type: "Int!" }) {
    assignedStudents {
      id
      user {
        id
        firstName
        lastName
      }
    }
    assignedClasses {
      id
      title
      displayName
      students(first: $first) {
        edges {
          node {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
    partiallyAssignedClasses {
      assignedClass {
        id
        students(first: $first) {
          edges {
            node {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
        }
      }
      assignedStudents {
        id
        user {
          id
          firstName
          lastName
        }
      }
    }
    individuallyAssignedStudents {
      id
      user {
        firstName
        lastName
      }
    }
  }
`;

const NEW_ADAPTIVE_EXPERIENCE_TASK_FRAGMENT = graphql`
  fragment EditTaskFlyout_newAdaptiveExperienceTask on NewAdaptiveExperienceTask
  @argumentDefinitions(first: { type: "Int!" }) {
    __typename
    id
    title
    startDate
    dueDate
    expiryDate
    newAdaptiveTargetMastery: targetMastery
    newAdaptiveMinimumQuestionTarget: minimumQuestionTarget
    areaOfStudy {
      id
      correctQuestionTarget
    }
    allowCalculator
    ...EditTaskFlyout_assignments @arguments(first: $first)
  }
`;

export const ErrorMessage = (props: ComponentProps<typeof Label>) => {
  return <Label {...props} css={{ color: colors.cinnabar }} />;
};

export function excludedStudents(
  partialClasses: PartialClasses,
): readonly StudentSelectionPayload[] {
  // An empty `partial` here implies we only have full/complete class assignments. Leaving this
  // check out means in those cases where `partial` is empty the resulting list of excluded
  // students will be the entire class.
  if (partialClasses.length === 0) return [];
  // The excluded students for each partial class are those from the full class roll
  // who are not in the partial class `assignedStudents` list.
  // The excluded students for a task is the summation of excluded students lists
  // across each partial class.
  let excludedStudents = partialClasses.reduce<StudentSelectionPayload[]>(
    (excluded, partialClass) => {
      let excludedStudents =
        partialClass.assignedClass?.students?.edges
          // Students from the class roll who are not found in the `assignedStudents` list
          ?.filter(
            ({ node }) =>
              !partialClass.assignedStudents.some(({ id }) => node.id === id),
          )
          // Convert to the type our UI expects
          .map(({ node }) => ({
            id: node.id,
            firstName: node.user.firstName,
            lastName: node.user.lastName,
          })) ?? [];
      return [...excluded, ...excludedStudents];
    },
    [],
  );
  return uniqBy(s => s.id, excludedStudents);
}

export function UnauthorisedTaskAssigneesError({
  errorKeys,
}: {
  errorKeys: Array<string>;
}) {
  return errorKeys.includes(UNAUTHORIZED_TASK_ASSIGNEES_ERROR_KEY) ? (
    <ErrorMessage>
      You don't have permission to edit this task as it is assigned to other
      classes you don't have access to.
    </ErrorMessage>
  ) : null;
}
function EditAdaptiveTaskFlyout({
  adaptiveTaskKey,
}: {
  adaptiveTaskKey: EditTaskFlyout_adaptiveTask$key;
}) {
  const legacyAdaptiveTask = useFragment(
    ADAPTIVE_TASK_FRAGMENT,
    adaptiveTaskKey,
  );

  return (
    <EditAdaptiveTasksCommonFlyout
      adaptiveLegacyOrNewExperienceTask={legacyAdaptiveTask}
    />
  );
}

function EditNewExperienceAdaptiveTaskFlyout({
  newAdaptiveExperienceTaskKey,
}: {
  newAdaptiveExperienceTaskKey: EditTaskFlyout_newAdaptiveExperienceTask$key;
}) {
  const newAdaptiveExperienceTask = useFragment(
    NEW_ADAPTIVE_EXPERIENCE_TASK_FRAGMENT,
    newAdaptiveExperienceTaskKey,
  );

  return (
    <EditAdaptiveTasksCommonFlyout
      adaptiveLegacyOrNewExperienceTask={newAdaptiveExperienceTask}
    />
  );
}

function EditAdaptiveTasksCommonFlyout({
  adaptiveLegacyOrNewExperienceTask: currentAdaptiveTask,
}: {
  adaptiveLegacyOrNewExperienceTask:
    | EditTaskFlyout_adaptiveTask$data
    | EditTaskFlyout_newAdaptiveExperienceTask$data;
}) {
  const { closeFlyout } = useContext(FlyoutContext);

  const adaptiveTaskAssignments = useFragment<EditTaskFlyout_assignments$key>(
    EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT,
    currentAdaptiveTask,
  );

  const _state = useEditAdaptiveTaskState(
    currentAdaptiveTask,
    adaptiveTaskAssignments,
  );
  if (_state == null)
    throw new Error(
      `Unknown adaptive task type: ${currentAdaptiveTask.__typename}. Edit adaptive task may only be called with AdaptiveTask | NewAdaptiveExperienceTask`,
    );
  const [state, [editAdaptiveTask, { response }]] = _state;
  let responseTask;
  if (response != null) {
    if ('editAdaptiveTask' in response) {
      responseTask = response.editAdaptiveTask;
    } else if ('editNewAdaptiveExperienceTask' in response) {
      responseTask = response.editNewAdaptiveExperienceTask;
    } else {
      assertUnreachable(response);
    }
  }
  const taskEditSuccessful = responseTask != null && responseTask.task != null;
  const taskEditFailed = responseTask != null && responseTask.task == null;
  useEffect(() => {
    if (taskEditSuccessful) {
      taskRefetchBus.signal();
      closeFlyout();
    }
  }, [closeFlyout, taskEditSuccessful]);
  const errorKeys =
    responseTask != null ? responseTask.errors.map(e => e.key) : [];
  const {
    studentsThatWereRemoved,
    resetTaskAssignment,
    isConfirmOpen,
    openConfirm,
    closeConfirm,
  } = useConfirmRemovedStudents({
    originalAssignedStudents: adaptiveTaskAssignments.assignedStudents.map(
      s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      }),
    ),
    originalAssignedClasses: adaptiveTaskAssignments.assignedClasses,
    originalIndividuallyAssignedStudents:
      adaptiveTaskAssignments.individuallyAssignedStudents.map(s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      })),
    originalPartialClasses: adaptiveTaskAssignments.partiallyAssignedClasses,
    currentAssignedClasses: state.values?.selectedClasses ?? [],
    currentIndividualAssignedStudents: state.values?.selectedStudents ?? [],
    currentExcludedStudents: state.values?.excludedStudents ?? [],
    setIndividualAssignedStudents: state.updaters.updateSelectedStudents,
    setExcludedStudents: state.updaters.updateExcludedStudents,
    setAssignedClasses: state.updaters.updateSelectedClasses,
  });
  return (
    <Root>
      <SnackbarComponent
        isOpen={taskEditSuccessful}
        message="Task edited successfully"
      />
      <SnackbarComponent
        isOpen={taskEditFailed}
        message={
          errorKeys.includes(DUE_DATE_IN_THE_PAST_ERROR_KEY)
            ? "Due date can't be in the past"
            : 'Unable to edit task'
        }
      />

      <BackHeader title="Edit task" onClick={closeFlyout} />
      <FlyoutBody>
        {state.values !== null && (
          <EditAdaptiveTaskForm
            state={{
              values: state.values,
              updaters: state.updaters,
            }}
            errorKeys={errorKeys}
            typename={currentAdaptiveTask.__typename}
          />
        )}
      </FlyoutBody>
      <Footer>
        <Button
          isBlock
          isDisabled={
            state.values === null ||
            (state.values.selectedClasses.length === 0 &&
              state.values.selectedStudents.length === 0) ||
            state.values.taskDueDate <= new Date()
          }
          type="primary"
          onClick={
            studentsThatWereRemoved.length > 0 ? openConfirm : editAdaptiveTask
          }
        >
          Save
        </Button>
      </Footer>

      <ConfirmRemovedStudentsModal
        studentsThatWereRemoved={studentsThatWereRemoved}
        isConfirmOpen={isConfirmOpen}
        closeConfirm={closeConfirm}
        resetTaskAssignment={resetTaskAssignment}
        editTask={editAdaptiveTask}
      />
    </Root>
  );
}

const EDIT_TASK_FLYOUT_CUSTOM_TASK_FRAGMENT = graphql`
  fragment EditTaskFlyout_customTask on CustomTask
  @argumentDefinitions(first: { type: "Int!" }) {
    __typename
    id
    title
    startDate
    dueDate
    expiryDate
    allowHints
    allowCalculator
    allowRetry
    ...EditTaskFlyout_assignments @arguments(first: $first)
  }
`;

function EditCustomTaskFlyout({
  customTask: customTaskKey,
}: {
  customTask: EditTaskFlyout_customTask$key;
}) {
  const customTask = useFragment(
    EDIT_TASK_FLYOUT_CUSTOM_TASK_FRAGMENT,
    customTaskKey,
  );

  const customTaskAssignments = useFragment<EditTaskFlyout_assignments$key>(
    EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT,
    customTask,
  );

  const { closeFlyout } = useContext(FlyoutContext);
  const [state, [editCustomTask, { response }]] = useEditCustomTaskState(
    customTask,
    customTaskAssignments,
  );
  const taskEditSuccessful =
    response != null && response.editCustomTask.task != null;
  const taskEditFailed =
    response != null && response.editCustomTask.task == null;
  const {
    studentsThatWereRemoved,
    resetTaskAssignment,
    isConfirmOpen,
    openConfirm,
    closeConfirm,
  } = useConfirmRemovedStudents({
    originalAssignedStudents: customTaskAssignments.assignedStudents.map(s => ({
      id: s.id,
      firstName: s.user.firstName,
      lastName: s.user.lastName,
    })),
    originalAssignedClasses: customTaskAssignments.assignedClasses,
    originalIndividuallyAssignedStudents:
      customTaskAssignments.individuallyAssignedStudents.map(s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      })),
    originalPartialClasses: customTaskAssignments.partiallyAssignedClasses,
    currentAssignedClasses: state.values.selectedClasses,
    currentIndividualAssignedStudents: state.values.selectedStudents,
    currentExcludedStudents: state.values.excludedStudents,
    setIndividualAssignedStudents: state.updaters.setAssignedStudents,
    setExcludedStudents: state.updaters.setExcludedStudents,
    setAssignedClasses: state.updaters.setAssignedClasses,
  });
  useEffect(() => {
    if (taskEditSuccessful) {
      taskRefetchBus.signal();
      closeFlyout();
    }
  }, [closeFlyout, taskEditSuccessful]);
  const errorKeys =
    response != null ? response.editCustomTask.errors.map(e => e.key) : [];
  return (
    <Root>
      <SnackbarComponent
        isOpen={taskEditSuccessful}
        message="Task edited successfully"
      />
      <SnackbarComponent
        isOpen={taskEditFailed}
        message={
          errorKeys.includes(DUE_DATE_IN_THE_PAST_ERROR_KEY)
            ? "Due date can't be in the past"
            : 'Unable to edit task'
        }
      />
      <BackHeader title="Edit task" onClick={closeFlyout} />
      <FlyoutBody>
        <EditCustomTaskForm state={state} errorKeys={errorKeys} />
      </FlyoutBody>
      <Footer>
        <Button
          isBlock
          type="primary"
          isDisabled={
            (state.values.selectedClasses.length === 0 &&
              state.values.selectedStudents.length === 0) ||
            state.values.taskDueDate <= new Date()
          }
          onClick={
            studentsThatWereRemoved.length > 0 ? openConfirm : editCustomTask
          }
        >
          Save
        </Button>
      </Footer>
      <ConfirmRemovedStudentsModal
        studentsThatWereRemoved={studentsThatWereRemoved}
        isConfirmOpen={isConfirmOpen}
        closeConfirm={closeConfirm}
        resetTaskAssignment={resetTaskAssignment}
        editTask={editCustomTask}
      />
    </Root>
  );
}

const EDIT_TASK_FLYOUT_CHECK_IN_TASK_FRAGMENT = graphql`
  fragment EditTaskFlyout_checkInTask on CheckInTask
  @argumentDefinitions(first: { type: "Int!" }) {
    __typename
    id
    startDate
    dueDate
    expiryDate
    ...EditTaskFlyout_assignments @arguments(first: $first)
  }
`;

function EditCheckInTaskFlyout({
  checkInTaskKey,
}: {
  checkInTaskKey: EditTaskFlyout_checkInTask$key;
}) {
  const checkInTask = useFragment(
    EDIT_TASK_FLYOUT_CHECK_IN_TASK_FRAGMENT,
    checkInTaskKey,
  );

  const checkInTaskAssignments = useFragment<EditTaskFlyout_assignments$key>(
    EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT,
    checkInTask,
  );

  const { closeFlyout } = useContext(FlyoutContext);
  const [state, [editCheckInTask, { response }]] = useEditCheckInTaskState(
    checkInTask,
    checkInTaskAssignments,
  );
  const taskEditSuccessful =
    response != null && response.editCheckInTask.task != null;
  const taskEditFailed =
    response != null && response.editCheckInTask.task == null;
  const {
    studentsThatWereRemoved,
    resetTaskAssignment,
    isConfirmOpen,
    openConfirm,
    closeConfirm,
  } = useConfirmRemovedStudents({
    originalAssignedStudents: checkInTaskAssignments.assignedStudents.map(
      s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      }),
    ),
    originalAssignedClasses: checkInTaskAssignments.assignedClasses,
    originalIndividuallyAssignedStudents:
      checkInTaskAssignments.individuallyAssignedStudents.map(s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      })),
    originalPartialClasses: checkInTaskAssignments.partiallyAssignedClasses,
    currentAssignedClasses: state.values.selectedClasses,
    currentIndividualAssignedStudents: state.values.selectedStudents,
    currentExcludedStudents: state.values.excludedStudents,
    setIndividualAssignedStudents: state.updaters.setAssignedStudents,
    setExcludedStudents: state.updaters.setExcludedStudents,
    setAssignedClasses: state.updaters.setAssignedClasses,
  });
  useEffect(() => {
    if (taskEditSuccessful) {
      taskRefetchBus.signal();
      closeFlyout();
    }
  }, [closeFlyout, taskEditSuccessful]);
  const errorKeys =
    response != null ? response.editCheckInTask.errors.map(e => e.key) : [];
  return (
    <Root>
      <SnackbarComponent
        isOpen={taskEditSuccessful}
        message="Task edited successfully"
      />
      <SnackbarComponent
        isOpen={taskEditFailed}
        message={
          errorKeys
            .map(
              errorKey =>
                ({
                  [DUE_DATE_IN_THE_PAST_ERROR_KEY]:
                    "Due date can't be in the past",
                })[errorKey],
            )
            .find(Boolean) ?? 'Unable to edit task'
        }
      />
      <BackHeader title="Edit task" onClick={closeFlyout} />
      <FlyoutBody>
        <EditCheckInTaskForm state={state} errorKeys={errorKeys} />
      </FlyoutBody>
      <Footer>
        <Button
          isBlock
          type="primary"
          isDisabled={
            (state.values.selectedClasses.length === 0 &&
              state.values.selectedStudents.length === 0) ||
            state.values.taskDueDate <= new Date()
          }
          onClick={
            studentsThatWereRemoved.length > 0 ? openConfirm : editCheckInTask
          }
        >
          Save
        </Button>
      </Footer>
      <ConfirmRemovedStudentsModal
        studentsThatWereRemoved={studentsThatWereRemoved}
        isConfirmOpen={isConfirmOpen}
        closeConfirm={closeConfirm}
        resetTaskAssignment={resetTaskAssignment}
        editTask={editCheckInTask}
      />
    </Root>
  );
}

type ExternalProps = {
  isOpen: boolean;
  onClose: () => void;
  taskId: string;
};
const EDIT_TASK_FLYOUT_QUERY = graphql`
  query EditTaskFlyoutQuery($taskId: ID!, $first: Int!) {
    task(id: $taskId) {
      __typename
      ... on AdaptiveTask {
        ...EditTaskFlyout_adaptiveTask @arguments(first: $first)
      }
      ... on NewAdaptiveExperienceTask {
        ...EditTaskFlyout_newAdaptiveExperienceTask @arguments(first: $first)
      }
      ... on CustomTask {
        ...EditTaskFlyout_customTask @arguments(first: $first)
      }
      ... on LessonTask {
        ...EditLessonTaskFlyout_lessonTask @arguments(first: $first)
      }
      ... on WorksheetTask {
        ...EditWorksheetTaskFlyout_worksheetTask @arguments(first: $first)
      }
      ... on EngageTask {
        ...EditEngageTaskFlyout_engageTask @arguments(first: $first)
      }
      ... on CheckInTask {
        ...EditTaskFlyout_checkInTask @arguments(first: $first)
      }
    }
  }
`;
export default function EditTaskFlyout({
  isOpen,
  onClose,
  taskId,
}: ExternalProps) {
  return (
    <Flyout isOpen={isOpen} onClose={onClose}>
      <EditTaskFlyoutInner taskId={taskId} />
    </Flyout>
  );
}

function EditTaskFlyoutInner({ taskId }: { taskId: string }) {
  const { props, error } = useQuery<EditTaskFlyoutQuery>(
    EDIT_TASK_FLYOUT_QUERY,
    { taskId, first: RELAY_CONNECTION_MAX },
  );

  if (error != null) throw new InvariantViolation('Unable to edit task');
  if (props == null) return <MinorSpinner />;
  if (props.task == null)
    throw new InvariantViolation('Unable to retrieve task');
  return (
    <>
      {props.task.__typename === 'AdaptiveTask' && (
        <EditAdaptiveTaskFlyout adaptiveTaskKey={props.task} />
      )}

      {props.task.__typename === 'NewAdaptiveExperienceTask' && (
        <EditNewExperienceAdaptiveTaskFlyout
          newAdaptiveExperienceTaskKey={props.task}
        />
      )}

      {props.task.__typename === 'CustomTask' && (
        <EditCustomTaskFlyout customTask={props.task} />
      )}

      {props.task.__typename === 'LessonTask' && (
        <EditLessonTaskFlyout lessonTask={props.task} />
      )}

      {props.task.__typename === 'WorksheetTask' && (
        <EditWorksheetTaskFlyout worksheetTask={props.task} />
      )}

      {props.task.__typename === 'EngageTask' && (
        <EditEngageTaskFlyout engageTask={props.task} />
      )}

      {props.task.__typename === 'CheckInTask' && (
        <EditCheckInTaskFlyout checkInTaskKey={props.task} />
      )}
    </>
  );
}
