import { StyleSheet, css, type CSSInputTypes } from 'aphrodite';
import { useEffect, useRef, forwardRef, useCallback } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';

import ArrowLeftNewIcon from 'ms-components/icons/ArrowLeftNew';
import CrossBoldIcon from 'ms-components/icons/CrossBold';
import { lineHeight, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import type { CommonProps } from 'ms-ui-primitives/Button';
import Button from 'ms-ui-primitives/Button';
import Overlay, {
  exitAnimationDuration,
  type Theme,
} from 'ms-ui-primitives/Overlay';
import { unwrap } from 'ms-utils/typescript-utils';

export { exitAnimationDuration };

const isScrollingDown = (startY: number, curY: number) => curY < startY;
const isScrollingUp = (startY: number, curY: number) =>
  !isScrollingDown(startY, curY);

const MODAL_MAX_WIDTH = 600;
const MODAL_BORDER_RADIUS = 4 * BASE_UNIT;
const DEFAULT_PADDING_X = 32;
const DEFAULT_PADDING_Y = 40;
const ICON_SIZE = 16;

const boxEnterKeyframes = {
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
};

const boxExitKeyframes = {
  '0%': {
    opacity: 1,
    transform: 'scale(1)',
  },
  '100%': {
    opacity: 0,
    transform: 'scale(0.8)',
  },
};

const styles = StyleSheet.create({
  boxWrapper: {
    // https://codepen.io/amonks/pen/GjjdoA
    // to fix Safari it is also necessary to unset `will-change` rule
    ':-webkit-full-screen-ancestor': {
      animation: 'none',
      animationFillMode: 'none',
      willChange: 'unset',
    },
    margin: 'auto',
    animationName: boxEnterKeyframes,
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    position: 'relative',
  },
  boxWrapperExit: {
    animationName: boxExitKeyframes,
    animationDuration: `${exitAnimationDuration}ms`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
  },
  box: {
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  boxInner: {
    backgroundColor: colors.white,
    borderRadius: MODAL_BORDER_RADIUS,
    boxShadow: '0 0 24px rgba(0, 0, 0, 0.18)',
    height: '100%',
  },
  scrollingContainer: {
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    WebkitOverflowScrolling: 'touch', // momentum scrolling for iOS Safari
    display: 'flex',
    alignItems: 'flex-start',
    ':focus': {
      outline: 'none',
    },
  },
  modalTitle: {
    fontSize: 22,
    padding: '28px 40px 0 40px',
    lineHeight: lineHeight.heading,
    fontFamily: fontFamily.body,
    color: colors.mako,
  },
  modalTitleCentered: {
    textAlign: 'center',
  },
  isOverflowHidden: {
    overflow: 'hidden',
  },
  notScrollable: {
    overflow: 'hidden',
  },
});

const modalButtonStyles = {
  position: 'absolute',
  bottom: '100%',
};

export type Props = {
  title?: string | undefined;
  titleCentered?: boolean | undefined;
  renderHeader?: (() => ReactNode) | undefined;
  onBack?: CommonProps['onClick'] | undefined;
  onClose: () => void;
  animateClose?: boolean | undefined;
  width?: number | string | null | undefined;
  fullWidth?: boolean | undefined;
  height?: number | string | undefined;
  closeOnOverlayTap?: boolean | undefined; // Close modal if user taps/clicks the overlay?
  disableCloseOnEscape?: boolean | undefined; // Disable closing the modal when pressing the escape key
  allowNavigation?: boolean | undefined; // E.g. show back button when history exists ('←')
  showCloseButton?: boolean | undefined; // Show the default modal close button ('X')
  children: ReactNode;
  sizeToContent?: boolean | undefined;
  theme?: Theme | undefined;
  isOverflowHidden?: boolean | undefined;
  modalBoxStyles?: CSSInputTypes;
  isScrollable?: boolean | undefined;
  padding?: number | string | undefined;
};

const Modal = forwardRef<HTMLDivElement, Props>(function Modal(
  {
    animateClose,
    theme = 'twilight',
    fullWidth = false,
    closeOnOverlayTap = false,
    disableCloseOnEscape = false,
    onBack,
    onClose,
    isOverflowHidden = true,
    modalBoxStyles = false,
    sizeToContent = false,
    width,
    height,
    allowNavigation = false,
    showCloseButton = false,
    renderHeader,
    title,
    titleCentered = false,
    children,
    isScrollable = true,
    padding,
  },
  ref,
) {
  // const ref = _ref as MutableRefObject<HTMLDivElement | null>;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const boxWrapperRef = useRef<HTMLDivElement | null>(null);
  const startY = useRef(0);
  const shouldRenderBackButton = allowNavigation && window.history.length >= 2;

  useEffect(() => {
    if (scrollContainerRef.current === null) return;
    scrollContainerRef.current.focus();
  }, [scrollContainerRef]);

  const handleBackButtonClick = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      window.history.back();
      onBack?.(e);
    },
    [onBack],
  );

  return (
    // Do NOT use Overlay for any behaviour — only for its visuals.
    // The "modal overlay" behaviour should all be specified on the
    // child div in this component.
    <Overlay animateClose={animateClose} theme={theme}>
      <div
        className={css(
          styles.scrollingContainer,
          !isScrollable && styles.notScrollable,
        )}
        style={{
          padding:
            padding != null
              ? padding
              : fullWidth
              ? `${DEFAULT_PADDING_Y}px 0`
              : `${DEFAULT_PADDING_Y}px ${DEFAULT_PADDING_X}px`,
        }}
        ref={scrollContainerRef}
        onClick={event => {
          // 🚨 Modal overlays should make all other aspects of the page
          // effectively inert. We do not want interactions within the
          // scope of the modal to be observable by these "inert" parts
          // of the UI.
          event.stopPropagation();
        }}
        onMouseDown={event => {
          // See 🚨 comment in onClick for why.
          event.stopPropagation();

          const targetIsValid =
            event.target === scrollContainerRef.current ||
            event.target === boxWrapperRef.current;
          if (closeOnOverlayTap && targetIsValid) {
            onClose();
          }
        }}
        onPointerDown={event => {
          // See 🚨 comment in onClick for why.
          event.stopPropagation();
        }}
        onTouchStart={event => {
          // See 🚨 comment in onClick for why.
          event.stopPropagation();
          startY.current = unwrap(event.touches[0]).clientY;
        }}
        onTouchMove={event => {
          const scrollContainer = scrollContainerRef.current;
          if (scrollContainer == null) return;
          const { scrollHeight, clientHeight, scrollTop } = scrollContainer;

          const curY = unwrap(event.touches[0]).clientY;

          // Disable scroll propagation when container isn't scrollable
          if (scrollHeight === clientHeight) {
            event.preventDefault();
            return;
          }

          // Disable scroll propagation when we are scrolled to the top of the container
          // and we are trying to scroll up further.
          if (scrollTop === 0 && isScrollingUp(startY.current, curY)) {
            event.preventDefault();
            return;
          }

          // Disable scroll propagation when we are scrolled to the bottom of the container
          // and we are trying to scroll down further.
          if (
            scrollTop + clientHeight >= scrollHeight &&
            isScrollingDown(startY.current, curY)
          ) {
            event.preventDefault();
            return;
          }
        }}
        tabIndex={0}
        onKeyDown={event => {
          // Try to make sure the keydown events are not propagated to elements
          // up on the tree when a modal is open, because we should treat the
          // modal as an independent layer of the application and keydown events
          // should not interact with the underlying content. This is achieved
          // by forcing focus on the scrolling container on mount. If other
          // elements steal focus this intent would fail, but it's the safest
          // approach we could keep at the moment
          event.stopPropagation();
          if (event.keyCode === 27 && !disableCloseOnEscape) {
            // Esc
            event.preventDefault();
            onClose();
          }
        }}
      >
        <div
          ref={boxWrapperRef}
          className={css(
            styles.boxWrapper,
            Boolean(animateClose) && styles.boxWrapperExit,
            modalBoxStyles ?? false,
          )}
          style={sizeToContent ? {} : { flex: '1 1 0%' }}
        >
          <div
            id="modal"
            className={css(styles.box)}
            style={
              sizeToContent
                ? {}
                : {
                    maxWidth: width || MODAL_MAX_WIDTH,
                    height,
                  }
            }
            ref={ref}
          >
            <div
              className={css(
                styles.boxInner,
                Boolean(isOverflowHidden) && styles.isOverflowHidden,
              )}
            >
              {renderHeader
                ? renderHeader()
                : title && (
                    <div
                      className={css(
                        styles.modalTitle,
                        titleCentered && styles.modalTitleCentered,
                      )}
                    >
                      {title}
                    </div>
                  )}

              {children}
            </div>

            {shouldRenderBackButton && (
              <Button
                color="white"
                type="tertiary"
                label="Go back"
                isCircle
                styles={{ ...modalButtonStyles, left: 0 }}
                onClick={handleBackButtonClick}
              >
                <ArrowLeftNewIcon size={ICON_SIZE} />
              </Button>
            )}

            {showCloseButton && (
              <Button
                color="white"
                type="tertiary"
                label="Close Modal"
                isCircle
                styles={{ ...modalButtonStyles, right: 0 }}
                onClick={onClose}
              >
                <CrossBoldIcon size={ICON_SIZE} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </Overlay>
  );
});

export default Modal;
