/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DifficultyLevel = "EASY" | "HARD" | "MEDIUM";
export type Questions_subtopic = {
    readonly id: string;
    readonly problemContents: ReadonlyArray<{
        readonly id: string;
        readonly estimatedCompletionTime: number;
        readonly problemTemplateId: string;
        readonly previewWorkoutCreationToken: string;
        readonly isStaticQuestion: boolean;
        readonly difficultyLevel: DifficultyLevel | null;
        readonly " $fragmentRefs": FragmentRefs<"ProblemItem_problemContent">;
    }>;
    readonly " $refType": "Questions_subtopic";
};
export type Questions_subtopic$data = Questions_subtopic;
export type Questions_subtopic$key = {
    readonly " $data"?: Questions_subtopic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Questions_subtopic">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Questions_subtopic",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProblemContent",
      "kind": "LinkedField",
      "name": "problemContents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimatedCompletionTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemTemplateId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "previewWorkoutCreationToken",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isStaticQuestion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "difficultyLevel",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProblemItem_problemContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subtopic",
  "abstractKey": null
};
})();
(node as any).hash = '716019ea93ebebb8082ce61918c3bfec';
export default node;
