/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ChapterType = "INVESTIGATION" | "LESSON" | "QUESTIONS";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type LessonQueryVariables = {
    subtopicId: string;
    locale: SyllabusLocale;
};
export type LessonQueryResponse = {
    readonly subtopic: {
        readonly id: string;
        readonly title: string;
        readonly hasLessons: boolean;
        readonly hasLessonsTeacherNotes: boolean;
        readonly rslJsx: {
            readonly id: string | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
            readonly transpiledJsx: string | null;
            readonly locale: SyllabusLocale | null;
        };
        readonly rslTeacherNoteJsx: {
            readonly id: string | null;
            readonly transpiledJsx: string | null;
            readonly locale: SyllabusLocale | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
        };
        readonly investigationJsx: {
            readonly id: string | null;
            readonly transpiledJsx: string | null;
            readonly locale: SyllabusLocale | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
        };
        readonly investigationTeacherNoteJsx: {
            readonly id: string | null;
            readonly transpiledJsx: string | null;
            readonly locale: SyllabusLocale | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
        };
        readonly solidifyLessonJsx: {
            readonly id: string | null;
            readonly locale: SyllabusLocale | null;
            readonly pdfAvailable: boolean;
            readonly transpiledJsx: string | null;
            readonly pdfUrl: string | null;
        };
        readonly solidifyTeacherNoteJsx: {
            readonly transpiledJsx: string | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
            readonly locale: SyllabusLocale | null;
        };
        readonly teacherNoteJsx: {
            readonly id: string | null;
            readonly transpiledJsx: string | null;
            readonly pdfAvailable: boolean;
            readonly pdfUrl: string | null;
            readonly locale: SyllabusLocale | null;
        };
        readonly hasInvestigations: boolean;
        readonly pk: number;
        readonly chapters: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly content: string;
                    readonly chapterType: ChapterType;
                    readonly title: string;
                };
            }>;
        };
    } | null;
};
export type LessonQuery = {
    readonly response: LessonQueryResponse;
    readonly variables: LessonQueryVariables;
};



/*
query LessonQuery(
  $subtopicId: ID!
  $locale: SyllabusLocale!
) {
  subtopic(id: $subtopicId, syllabusLocale: $locale) {
    id
    title
    hasLessons
    hasLessonsTeacherNotes
    rslJsx {
      id
      pdfAvailable
      pdfUrl
      transpiledJsx
      locale
    }
    rslTeacherNoteJsx {
      id
      transpiledJsx
      locale
      pdfAvailable
      pdfUrl
    }
    investigationJsx {
      id
      transpiledJsx
      locale
      pdfAvailable
      pdfUrl
    }
    investigationTeacherNoteJsx {
      id
      transpiledJsx
      locale
      pdfAvailable
      pdfUrl
    }
    solidifyLessonJsx {
      id
      locale
      pdfAvailable
      transpiledJsx
      pdfUrl
    }
    solidifyTeacherNoteJsx {
      transpiledJsx
      pdfAvailable
      pdfUrl
      locale
      id
    }
    teacherNoteJsx {
      id
      transpiledJsx
      pdfAvailable
      pdfUrl
      locale
    }
    hasInvestigations
    pk
    chapters(first: 1000) {
      edges {
        node {
          id
          content
          chapterType
          title
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subtopicId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  },
  {
    "kind": "Variable",
    "name": "syllabusLocale",
    "variableName": "locale"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLessons",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLessonsTeacherNotes",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfAvailable",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "RetrofittedStudentLesson",
  "kind": "LinkedField",
  "name": "rslJsx",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "RetrofittedStudentLessonTeacherNote",
  "kind": "LinkedField",
  "name": "rslTeacherNoteJsx",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Investigation",
  "kind": "LinkedField",
  "name": "investigationJsx",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "InvestigationTeacherNote",
  "kind": "LinkedField",
  "name": "investigationTeacherNoteJsx",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SolidifyLesson",
  "kind": "LinkedField",
  "name": "solidifyLessonJsx",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v10/*: any*/),
    (v7/*: any*/),
    (v9/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "TeacherNote",
  "kind": "LinkedField",
  "name": "teacherNoteJsx",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v9/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasInvestigations",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1000
    }
  ],
  "concreteType": "ChapterConnection",
  "kind": "LinkedField",
  "name": "chapters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ChapterEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Chapter",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chapterType",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "chapters(first:1000)"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LessonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SolidifyTeacherNote",
            "kind": "LinkedField",
            "name": "solidifyTeacherNoteJsx",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LessonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SolidifyTeacherNote",
            "kind": "LinkedField",
            "name": "solidifyTeacherNoteJsx",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "176f55cc1af29a5f279bc440541f2767",
    "id": null,
    "metadata": {},
    "name": "LessonQuery",
    "operationKind": "query",
    "text": "query LessonQuery(\n  $subtopicId: ID!\n  $locale: SyllabusLocale!\n) {\n  subtopic(id: $subtopicId, syllabusLocale: $locale) {\n    id\n    title\n    hasLessons\n    hasLessonsTeacherNotes\n    rslJsx {\n      id\n      pdfAvailable\n      pdfUrl\n      transpiledJsx\n      locale\n    }\n    rslTeacherNoteJsx {\n      id\n      transpiledJsx\n      locale\n      pdfAvailable\n      pdfUrl\n    }\n    investigationJsx {\n      id\n      transpiledJsx\n      locale\n      pdfAvailable\n      pdfUrl\n    }\n    investigationTeacherNoteJsx {\n      id\n      transpiledJsx\n      locale\n      pdfAvailable\n      pdfUrl\n    }\n    solidifyLessonJsx {\n      id\n      locale\n      pdfAvailable\n      transpiledJsx\n      pdfUrl\n    }\n    solidifyTeacherNoteJsx {\n      transpiledJsx\n      pdfAvailable\n      pdfUrl\n      locale\n      id\n    }\n    teacherNoteJsx {\n      id\n      transpiledJsx\n      pdfAvailable\n      pdfUrl\n      locale\n    }\n    hasInvestigations\n    pk\n    chapters(first: 1000) {\n      edges {\n        node {\n          id\n          content\n          chapterType\n          title\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '16ec858ea6467f0ffd0702037dc07816';
export default node;
