import { Fragment } from 'react';

import ChevronBottomIcon from 'ms-components/icons/ChevronBottom';
import ChevronRightIcon from 'ms-components/icons/ChevronRight';
import {
  Asterisk,
  FieldWrapper,
  Label,
  Row,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import {
  ErrorMessage,
  UnauthorisedTaskAssigneesError,
} from 'ms-pages/Teacher/components/EditTaskFlyout';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Input from 'ms-ui-primitives/Input';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';

import type { CombinedState } from '.';
import TaskNameInput from '../../CreateTask/components/TaskNameInput';

const RightAligned = styled({
  marginLeft: 'auto',
});

const AdvancedOptionsButton = styled({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 10 * BASE_UNIT,
  color: colors.cloudBurst,
  fontSize: fontSize.small,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
});

type Props = {
  state: CombinedState;
  errorKeys: string[];
};

export default function CustomTaskDetailsForm({
  state: { values, updaters },
  errorKeys,
}: Props) {
  const {
    taskName,
    taskStartDate,
    taskDueDate,
    isAdvancedOptionsVisible,
    extensionDays,
    disableHelp,
    disableCalculator,
    disableRetry,
    selectedClasses,
    selectedStudents,
    excludedStudents,
  } = values;
  const {
    updateTaskName,
    updateTaskStartDate,
    updateTaskDueDate,
    toggleAdvancedOptions,
    updateExtensionDays,
    toggleDisableHelp,
    toggleDisableCalculator,
    toggleDisableRetry,
    setAssignedClasses,
    setAssignedStudents,
    setExcludedStudents,
  } = updaters;

  return (
    <TaskCreationFormWrapper>
      <UnauthorisedTaskAssigneesError errorKeys={errorKeys} />
      <FieldWrapper>
        <Label>Task name</Label>
        <TaskNameInput value={taskName} onChange={updateTaskName} />
      </FieldWrapper>
      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        {/* It is possible to edit a task after the due date has passed.
            We don't want to suprise the use by automatically changing the due date
            but we need to communicate the invalid state.
         */}
        {values.taskDueDate <= new Date() && (
          <ErrorMessage>Due date cannot be in the past.</ErrorMessage>
        )}
        <TaskDatePicker
          value={[taskStartDate, taskDueDate]}
          onChange={([startDate, endDate]) => {
            if (startDate != null) updateTaskStartDate(startDate);
            if (endDate != null) updateTaskDueDate(endDate);
          }}
          errorKeys={errorKeys}
        />
      </FieldWrapper>

      <FieldWrapper>
        <TaskAssignment
          selectedClasses={selectedClasses}
          selectedStudents={selectedStudents}
          excludedStudents={excludedStudents}
          onChangeClasses={setAssignedClasses}
          onChangeStudents={setAssignedStudents}
          onChangeExcludedStudents={setExcludedStudents}
        />
      </FieldWrapper>

      <FieldWrapper>
        <AdvancedOptionsButton onClick={toggleAdvancedOptions}>
          Advanced Options
          <RightAligned>
            {isAdvancedOptionsVisible ? (
              <ChevronBottomIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </RightAligned>
        </AdvancedOptionsButton>
      </FieldWrapper>

      {isAdvancedOptionsVisible && (
        <Fragment>
          <FieldWrapper>
            <Label>Extension days</Label>
            <Row>
              <div style={{ width: 70 }}>
                <Input
                  type="number"
                  min={0}
                  max={999}
                  value={extensionDays.toString()}
                  onChange={e => {
                    updateExtensionDays(parseInt(e.target.value, 10));
                  }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>days</div>
            </Row>
          </FieldWrapper>

          <FieldWrapper>
            <Label>Help options</Label>
            <Checkbox
              checked={disableHelp}
              onChange={() => toggleDisableHelp()}
              label="Hide videos, hints and examples"
            />
            <VSpacer height={8} />
            <Checkbox
              checked={disableRetry}
              onChange={() => {
                toggleDisableRetry();
              }}
              label="Hide retry questions"
            />
            <VSpacer height={8} />
            <Checkbox
              checked={disableCalculator}
              onChange={() => toggleDisableCalculator()}
              label="Hide calculator tools"
            />
          </FieldWrapper>
        </Fragment>
      )}
    </TaskCreationFormWrapper>
  );
}
