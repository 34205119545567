/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TeacherEditStudentQueryVariables = {
    studentId: string;
    schoolId: string;
    numberOfSyllabuses: number;
    numberOfClasses: number;
};
export type TeacherEditStudentQueryResponse = {
    readonly student: {
        readonly id: string;
        readonly user: {
            readonly firstName: string;
            readonly lastName: string;
            readonly email: string;
            readonly syllabusFocus: {
                readonly id: string;
                readonly title: string;
            };
        };
        readonly lanternStudent: {
            readonly selfReportedGrade: {
                readonly title: string;
                readonly id: string;
            };
            readonly curriculum: {
                readonly grades: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                }>;
            };
        };
        readonly guardians: ReadonlyArray<{
            readonly id: string;
            readonly email: string;
            readonly receiveNotifications: boolean;
        }>;
        readonly classes: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly school: {
                        readonly id: string;
                    };
                    readonly classGrade: {
                        readonly id: string;
                        readonly title: string;
                    } | null;
                };
            }>;
        };
        readonly " $fragmentRefs": FragmentRefs<"StudentPasswordReset_student">;
    } | null;
    readonly school: {
        readonly availableSyllabuses: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
        }> | null;
        readonly classes: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly classSyllabusFocus: {
                        readonly id: string;
                        readonly title: string;
                    };
                    readonly classGrade: {
                        readonly id: string;
                        readonly title: string;
                    } | null;
                };
            }>;
        } | null;
    } | null;
};
export type TeacherEditStudentQuery = {
    readonly response: TeacherEditStudentQueryResponse;
    readonly variables: TeacherEditStudentQueryVariables;
};



/*
query TeacherEditStudentQuery(
  $studentId: ID!
  $schoolId: ID!
  $numberOfSyllabuses: Int!
  $numberOfClasses: Int!
) {
  student(id: $studentId) {
    id
    user {
      firstName
      lastName
      email
      syllabusFocus {
        id
        title
      }
      id
    }
    ...StudentPasswordReset_student
    lanternStudent {
      selfReportedGrade {
        title
        id
      }
      curriculum {
        grades {
          id
          title
        }
        id
      }
      id
    }
    guardians {
      id
      email
      receiveNotifications
    }
    classes(first: $numberOfClasses) {
      edges {
        node {
          id
          title
          school {
            id
          }
          classGrade {
            id
            title
          }
        }
      }
    }
  }
  school(id: $schoolId) {
    availableSyllabuses(limit: $numberOfSyllabuses) {
      id
      title
    }
    classes(first: $numberOfClasses) {
      edges {
        node {
          id
          title
          classSyllabusFocus {
            id
            title
          }
          classGrade {
            id
            title
          }
        }
      }
    }
    id
  }
}

fragment ResetStudentTemporaryPasswordModal_student on Student {
  id
  user {
    firstName
    lastName
    id
  }
}

fragment StudentPasswordReset_student on Student {
  temporaryPassword
  ...ResetStudentTemporaryPasswordModal_student
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfClasses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfSyllabuses"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studentId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "studentId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabusFocus",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "selfReportedGrade",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "grades",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Guardian",
  "kind": "LinkedField",
  "name": "guardians",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receiveNotifications",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numberOfClasses"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "classGrade",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v15/*: any*/),
  "concreteType": "ClassConnection",
  "kind": "LinkedField",
  "name": "classes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClassEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Class",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "School",
              "kind": "LinkedField",
              "name": "school",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v16/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "limit",
      "variableName": "numberOfSyllabuses"
    }
  ],
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "availableSyllabuses",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": (v15/*: any*/),
  "concreteType": "ClassConnection",
  "kind": "LinkedField",
  "name": "classes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClassEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Class",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Syllabus",
              "kind": "LinkedField",
              "name": "classSyllabusFocus",
              "plural": false,
              "selections": (v10/*: any*/),
              "storageKey": null
            },
            (v16/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherEditStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Student",
        "kind": "LinkedField",
        "name": "student",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "lanternStudent",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v17/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StudentPasswordReset_student"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeacherEditStudentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Student",
        "kind": "LinkedField",
        "name": "student",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v11/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "temporaryPassword",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "lanternStudent",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v19/*: any*/),
          (v20/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0cc800ed90a972373fd1b6c51967b43",
    "id": null,
    "metadata": {},
    "name": "TeacherEditStudentQuery",
    "operationKind": "query",
    "text": "query TeacherEditStudentQuery(\n  $studentId: ID!\n  $schoolId: ID!\n  $numberOfSyllabuses: Int!\n  $numberOfClasses: Int!\n) {\n  student(id: $studentId) {\n    id\n    user {\n      firstName\n      lastName\n      email\n      syllabusFocus {\n        id\n        title\n      }\n      id\n    }\n    ...StudentPasswordReset_student\n    lanternStudent {\n      selfReportedGrade {\n        title\n        id\n      }\n      curriculum {\n        grades {\n          id\n          title\n        }\n        id\n      }\n      id\n    }\n    guardians {\n      id\n      email\n      receiveNotifications\n    }\n    classes(first: $numberOfClasses) {\n      edges {\n        node {\n          id\n          title\n          school {\n            id\n          }\n          classGrade {\n            id\n            title\n          }\n        }\n      }\n    }\n  }\n  school(id: $schoolId) {\n    availableSyllabuses(limit: $numberOfSyllabuses) {\n      id\n      title\n    }\n    classes(first: $numberOfClasses) {\n      edges {\n        node {\n          id\n          title\n          classSyllabusFocus {\n            id\n            title\n          }\n          classGrade {\n            id\n            title\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ResetStudentTemporaryPasswordModal_student on Student {\n  id\n  user {\n    firstName\n    lastName\n    id\n  }\n}\n\nfragment StudentPasswordReset_student on Student {\n  temporaryPassword\n  ...ResetStudentTemporaryPasswordModal_student\n}\n"
  }
};
})();
(node as any).hash = '37f38ae8236fa10293ae3f11675e0e66';
export default node;
