/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SunflowerSubtopicDetailHeaderSubtopic = {
    readonly id: string;
    readonly hasAdaptivePractice: boolean;
    readonly pk: number;
    readonly title: string;
    readonly outcomes: ReadonlyArray<{
        readonly id: string;
        readonly code: string;
        readonly description: string;
    }>;
    readonly gamifiedMastery: number;
    readonly topic: {
        readonly id: string;
        readonly pk: number;
        readonly title: string;
        readonly badgeUrl: string;
        readonly syllabus: {
            readonly id: string;
            readonly pk: number;
            readonly title: string;
            readonly coverImageUrl: string;
            readonly " $fragmentRefs": FragmentRefs<"LanguageSelector_availableLanguages">;
        };
    };
    readonly " $refType": "SunflowerSubtopicDetailHeaderSubtopic";
};
export type SunflowerSubtopicDetailHeaderSubtopic$data = SunflowerSubtopicDetailHeaderSubtopic;
export type SunflowerSubtopicDetailHeaderSubtopic$key = {
    readonly " $data"?: SunflowerSubtopicDetailHeaderSubtopic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SunflowerSubtopicDetailHeaderSubtopic">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SunflowerSubtopicDetailHeaderSubtopic",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAdaptivePractice",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Outcome",
      "kind": "LinkedField",
      "name": "outcomes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamifiedMastery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badgeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Syllabus",
          "kind": "LinkedField",
          "name": "syllabus",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverImageUrl",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LanguageSelector_availableLanguages"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subtopic",
  "abstractKey": null
};
})();
(node as any).hash = '3ce3682708fce4175f608d2747983b0e';
export default node;
