import Helmet from 'react-helmet';

import { useSSRContext } from 'ms-helpers/SSR/SSRContext';

type Props = {
  description: string;
};

export default function PageDescription(props: Props) {
  const [setSSRData] = useSSRContext();

  setSSRData(context => ({
    ...context,
    meta: {
      ...context.meta,
      metaDescription: props.description,
    },
  }));

  return (
    <Helmet defer={false}>
      <meta name="description" content={props.description} />
      <meta name="og:description" content={props.description} />
    </Helmet>
  );
}
