import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

export default function CreateTemplateGroupIcon(props: Props) {
  return (
    <Icon viewBoxHeight={26} viewBoxWidth={26} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1569 14.3324C20.8878 14.3584 20.6149 14.3717 20.3389 14.3717C15.9081 14.3717 12.2792 10.9381 11.9679 6.58655H8.46462C8.46462 6.58655 8.41654 4.42386 8.46462 4.42386H2.69539C1.54154 4.42386 0.387695 6.58655 0.387695 6.58655V20.2572H21.1569V14.3324Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3388 11.4429C23.3563 11.4429 25.8026 8.99667 25.8026 5.9791C25.8026 2.96154 23.3563 0.51532 20.3388 0.51532C17.3212 0.51532 14.875 2.96154 14.875 5.9791C14.875 8.99667 17.3212 11.4429 20.3388 11.4429ZM24.0689 5.9752C24.0683 5.57305 23.7749 5.24307 23.3361 5.24247L21.0774 5.23941L21.0743 2.98068C21.0737 2.54191 20.7438 2.2485 20.3416 2.24795C19.9339 2.2474 19.5947 2.5488 19.5953 2.99427L19.5983 5.2374L17.3552 5.23435C16.9097 5.23375 16.6083 5.57294 16.6089 5.98067C16.6094 6.38283 16.9028 6.71281 17.3416 6.7134L19.6003 6.71647L19.6034 8.9752C19.604 9.41397 19.934 9.70738 20.3361 9.70793C20.7439 9.70848 21.0831 9.40707 21.0825 8.96161L21.0794 6.71848L23.3225 6.72152C23.768 6.72213 24.0694 6.38294 24.0689 5.9752Z"
      />
    </Icon>
  );
}
