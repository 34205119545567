import { useEffect } from 'react';

export default function useStartPosthogRecording({
  isEnabled,
  deps,
  eventName,
}: {
  isEnabled: boolean;
  deps: ReadonlyArray<unknown>;
  eventName?: string;
}) {
  useEffect(
    () => {
      if (isEnabled && window.posthog != null) {
        window.posthog.startSessionRecording();
        if (eventName != null) {
          window.posthog.capture(eventName);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
}
