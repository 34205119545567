import { fontFamily, fontSize, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const GuideTitle = styled({
  marginTop: 6 * BASE_UNIT,
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
});

const GuideText = styled({
  marginTop: 2 * BASE_UNIT,
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  lineHeight: lineHeight.body,
  color: colors.shuttleGray,
});

export default function Guide() {
  return (
    <>
      <GuideTitle>Adaptive Tasks</GuideTitle>
      <GuideText>
        Teachers can assign coursework through Adaptive tasks, which cover an
        entire subtopic and allow students to work through at their own pace.
        The task automatically generates questions, beginning with the
        introductory material and moving through to the most difficult
        questions, adapting the experience as each student demonstrates their
        understanding.
      </GuideText>
      <GuideTitle>Question sets and Custom tasks</GuideTitle>
      <GuideText>
        Teachers can design a Custom task, choosing questions from within a
        single question set or drawing from across the curriculum. Whether it be
        for assessment, revision, or regular instruction, teachers can create a
        complete experience for their students. Each task can be saved as a
        template to reuse and share with other teachers.
      </GuideText>
      <GuideTitle>Task templates</GuideTitle>
      <GuideText>
        The Task Template Library comes with a range of topic tests created by
        our in-house mathematicians and curriculum experts that can be assigned
        to students immediately. Teachers can add or remove questions from
        templates as desired when assigned as a task. Teachers can create their
        own task templates to add to the library, which will then be shared with
        all teachers at the school.
      </GuideText>
      <GuideTitle>Outcome Search</GuideTitle>
      <GuideText>
        Teachers can search for relevant topics and subtopics by entering a
        curriculum outcome code into the search box. The outcome codes are also
        displayed in the header of each subtopic.
      </GuideText>
      <GuideTitle>Detailed Question information</GuideTitle>
      <GuideText>
        Details for every question have been included to assist teachers in
        planning and task creation. These details include:
      </GuideText>
      <GuideText>
        <ul>
          <li>Question difficulty: easy, medium, hard</li>
          <li>Estimated time to complete a question</li>
        </ul>
      </GuideText>
      <GuideTitle>Lesson</GuideTitle>
      <GuideText>
        All lessons on Mathspace include detailed explanations, worked questions
        and interactive practice questions that students can try out themselves.
        Lessons can also be shared directly with students to guide them towards
        more conceptual learning before they practice what they have learned.
      </GuideText>
      <GuideTitle>Investigations</GuideTitle>
      <GuideText>
        Mathspace investigations are designed to be open-ended tasks that
        students can complete either individually or in groups. The are often
        more conceptual, encourage strong mathematical discourse by asking
        students to reflect and discuss their learning, and promote discovery
        through hands-on, student-driven experimentation. Teachers can find
        investigations by typing the word Investigation into the search box.
      </GuideText>
      <GuideTitle>Student Workbook: Step-by-step support</GuideTitle>
      <GuideText>
        We want to be the right help at the right time for every student, which
        is why we provide personalised support at every step. With hints, video
        demonstrations, and instant feedback after every line of working, the
        student workbook will assist each of your students along each of their
        learning paths.
      </GuideText>
    </>
  );
}
