import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode, ChangeEvent } from 'react';

import { fontFamily, fontSize, transition } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { useColorTheme } from 'ms-styles/themes';

const MARGIN = 10;
const RADIO_BUTTON_SIZE = 16;
const RADIO_BUTTON_BORDER_RADIUS = '50%';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    marginRight: MARGIN,
    fontFamily: fontFamily.body,
    fontSize: fontSize.small,
    cursor: 'pointer',
    userSelect: 'none',
  },
  labelDisabled: {
    cursor: 'default',
  },
  input: {
    display: 'none',
  },
  checked: { boxShadow: `inset 0 0 0 2px ${colors.white}` },
  // Styles for disabled RadioButton
  disabled: {
    backgroundColor: colors.athensGray,
    borderColor: colors.iron,
  },
  checkedAndDisabled: {
    backgroundColor: colors.iron,
    borderColor: colors.iron,
  },
  displayedRadioButton: {
    border: `1px solid ${colors.iron}`,
    transition: `background-color ${transition}, border-color ${transition}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: RADIO_BUTTON_BORDER_RADIUS,
    marginRight: MARGIN,
    height: RADIO_BUTTON_SIZE,
    width: RADIO_BUTTON_SIZE,
  },
});

type Props = {
  label: ReactNode;
  checked: boolean;
  disabled?: boolean | undefined;
  aphroditeStyles?: CSSInputTypes[] | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const RadioButton = ({
  label,
  disabled = false,
  aphroditeStyles = [],
  onChange,
  checked,
}: Props) => {
  const { primary: primaryColorName } = useColorTheme();
  return (
    <div className={css(styles.row)}>
      {/*  eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        className={css(
          styles.label,
          disabled && styles.labelDisabled,
          ...aphroditeStyles,
        )}
      >
        <input
          type="radio"
          checked={checked}
          className={css(styles.input)}
          disabled={disabled}
          onChange={onChange}
        />
        <div
          style={{
            backgroundColor: checked ? colors[primaryColorName] : undefined,
          }}
          className={css(
            styles.displayedRadioButton,
            checked && styles.checked,
            disabled && styles.disabled,
            disabled && checked && styles.checkedAndDisabled,
          )}
        />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
