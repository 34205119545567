/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type SubtopicDetailHeader_JsxContent = {
    readonly id: string | null;
    readonly transpiledJsx: string | null;
    readonly locale: SyllabusLocale | null;
    readonly pdfAvailable: boolean;
    readonly pdfUrl: string | null;
};
export type SubtopicDetailHeader_JsxContent$data = SubtopicDetailHeader_JsxContent;
export type SubtopicDetailHeader_JsxContent$key = {
    readonly " $data"?: SubtopicDetailHeader_JsxContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubtopicDetailHeader_JsxContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "SubtopicDetailHeader_JsxContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transpiledJsx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdfAvailable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pdfUrl",
      "storageKey": null
    }
  ],
  "type": "JsxContent",
  "abstractKey": "__isJsxContent"
};
(node as any).hash = '8708be1ab9b407007b722138f4682978';
export default node;
