import { css, StyleSheet } from 'aphrodite';
import { head, toUpper } from 'ramda';
import { useCallback, useEffect, useRef, useState } from 'react';

import CrossIcon from 'ms-components/icons/Cross';
import Portal from 'ms-helpers/Portal';
import {
  borderRadiusUI,
  breakPoints,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  zIndex,
} from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { onHover } from 'ms-utils/emotion';
import { usePrevious } from 'ms-utils/hooks/usePrevious';

import { useSnackbar } from '..';

export const DISPLAY_DURATION = 7000;
const GUTTER_SMALL = 14;
const GUTTER_LARGE = 24;
export const TRANSITION_DURATION = 300;

const styles = StyleSheet.create({
  root: {
    bottom: 0,
    boxSizing: 'border-box',
    left: 0,
    pointerEvents: 'none',
    position: 'fixed',
    right: 0,
    transform: 'translateY(100%)',
    transition: `transform ${TRANSITION_DURATION}ms`,
    zIndex: zIndex.snackbar,
  },
  rightAligned: {
    left: 'auto',
    right: 0,
  },
  rightAlignedWithIntercom: {
    left: 'auto',
    // TEMP to fix the issue of having intercom bubble over the snackbar
    right: 100, // TODO find a more solid solution https://mathspace.slack.com/files/U8Z2ABJRX/FKBU5NU6N/image.png
  },
  action: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    fontWeight: fontWeight.semibold,
    margin: `${GUTTER_LARGE}px 0 0`,
    whiteSpace: 'nowrap',
    display: 'flex',
    textDecoration: 'none',
    ':focus': {
      outline: 'none',
    },

    color: colors.java,
    ...onHover({
      color: alternateColors.java,
    }),

    [`@media (min-width: ${breakPoints.small}px)`]: {
      alignSelf: 'center',
      margin: `0 0 0 ${GUTTER_LARGE}px`,
    },
  },
  dismissAction: {
    color: colors.white,
    ...onHover({
      color: 'red', // cinnabar is not much visible with a dark background
    }),
  },
  message: {},
  open: {
    transform: 'translateY(0%)',
  },
  snackbar: {
    alignItems: 'stretch',
    background: colors.mako,
    color: colors.athensGray,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: `${fontFamily.body}`,
    fontSize: fontSize.small,
    lineHeight: lineHeight.body,
    padding: `${GUTTER_SMALL}px ${GUTTER_LARGE}px`,
    pointerEvents: 'auto',

    [`@media (min-width: ${breakPoints.small}px)`]: {
      alignItems: 'center',
      borderRadius: borderRadiusUI,
      display: 'inline-flex',
      flexDirection: 'row',
      margin: GUTTER_LARGE,
      // These sizes are derived from Google's Material Design snackbar spec
      // https://material.io/guidelines/components/snackbars-toasts.html#
      maxWidth: 560,
      minWidth: 280,
    },
  },
});

export default function Snackbar({
  rightAligned,
  rightAlignedWithIntercom,
}: {
  rightAligned?: boolean | undefined;
  rightAlignedWithIntercom?: boolean | undefined;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const { messages, dequeueMessage } = useSnackbar();
  const prevMessageCount = usePrevious(messages.length) ?? 0;
  const isOpenRef = useRef(isOpen);
  const timers = useRef<number[]>([]);
  const activeTimer = useRef<number | undefined>(undefined);

  // Store reference to isOpen to ensure that we're checking against its most
  // recent value in the hide function.
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    // If aphrodite's styles haven't flushed onto the page yet, the component
    // won't animate in.
    const timerId = window.setTimeout(() => {
      setIsReady(true);
    });
    return () => {
      window.clearTimeout(timerId);
    };
  }, []);

  const _setTimeout = useCallback((callback: VoidFunction, delay: number) => {
    const timerId = window.setTimeout(() => {
      callback();
      timers.current = timers.current.filter(id => id !== timerId);
    }, delay);
    timers.current.push(timerId);
  }, []);

  const hide = useCallback(() => {
    // We don't want hide to be called multiple times before the snackbar has
    // completed resolving the last hide call.
    if (!isOpenRef.current) return;

    setIsOpen(false);

    _setTimeout(() => {
      dequeueMessage();
      setIsReady(true);
    }, TRANSITION_DURATION);
  }, [_setTimeout, dequeueMessage]);

  const wait = useCallback(() => {
    // Clear any existing timers before setting a new one
    window.clearTimeout(activeTimer.current);

    if (messages.length > 1) {
      // If messages are waiting in the queue, close the snackbar immediately.
      hide();
    } else {
      activeTimer.current = window.setTimeout(hide, DISPLAY_DURATION);
    }
  }, [hide, messages.length]);

  const show = useCallback(() => {
    setIsOpen(true);
    setIsReady(false);

    const message = head(messages);
    if (message != null && message.persistent) return;
    _setTimeout(wait, TRANSITION_DURATION);
  }, [_setTimeout, messages, wait]);

  useEffect(() => {
    // If a new message comes in while the snackbar is open, close it early.
    if (isOpen && messages.length > 1 && messages.length > prevMessageCount) {
      hide();
    }
    if (messages.length > 0 && isReady) {
      show();
    }
  }, [hide, isOpen, isReady, messages.length, prevMessageCount, show]);

  useEffect(() => {
    // Clear all timer timeouts
    return () => {
      timers.current.forEach(timeoutId => clearTimeout(timeoutId));
      timers.current = [];
      window.clearTimeout(activeTimer.current);
    };
  }, []);

  const message = head(messages);

  return (
    <Portal isOpen portalStyles={[styles.root]}>
      <div
        className={css(
          styles.root,
          !!rightAligned && styles.rightAligned,
          !!rightAlignedWithIntercom && styles.rightAlignedWithIntercom,
          isOpen && styles.open,
        )}
      >
        {message && (
          <div className={css(styles.snackbar)} data-testid="snackbar">
            <div className={css(styles.message)}>{message.text}</div>

            {message.actionLabel && (
              <a
                className={css(styles.action)}
                role="button"
                tabIndex={0}
                onClick={message.handleAction}
                href={message.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {message.actionLabel && toUpper(message.actionLabel)}
              </a>
            )}

            {message.dismissable && (
              <div
                className={css(styles.action, styles.dismissAction)}
                role="button"
                tabIndex={0}
                onClick={hide}
              >
                <CrossIcon />
              </div>
            )}
          </div>
        )}
      </div>
    </Portal>
  );
}
