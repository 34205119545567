import styled from '@emotion/styled';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { onHover } from 'ms-utils/emotion';

export const Root = styled.div({
  fontSize: 16,
  lineHeight: 1,
  fontWeight: fontWeight.normal,
  overflow: 'hidden',
  transition: 'height 200ms ease-out',
});

export const ListItem = styled.div({
  fontFamily: fontFamily.body,
  display: 'flex',
  position: 'relative',
  color: colors.mako,
  userSelect: 'none',
  ...onHover({
    cursor: 'pointer',
    background: colors.polar,
  }),
  ':not(:last-of-type)': {
    boxShadow: 'inset 0 -1px 0 rgba(0, 0, 0, 0.1)',
  },
  ':focus': {
    outline: 'none',
  },
  minHeight: 51,
});

export const AnimationRect = styled.div({
  transition: 'transform 200ms ease-out',
  display: 'flex',
  alignItems: 'flex-start',
});

export const Back = styled.span({
  marginLeft: -15,
  padding: '0 10px',
  ...onHover({ cursor: 'pointer' }),
});

export const Close = styled.div({
  position: 'absolute',
  padding: 15,
  boxSizing: 'content-box',
  top: 0,
  right: 0,
  ...onHover({ cursor: 'pointer' }),
});

export const SelectionBar = styled.span({
  width: 5,
  height: '100%',
  borderTop: `1px solid ${colors.matisse}`,
  position: 'absolute',
  left: 0,
  top: -1,
  background: colors.matisse,
  boxSizing: 'content-box',
});

export const ItemInner = styled.span({
  flexGrow: 1,
  padding: '13px 13px 13px 19px',
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
});

export const Screen = styled.div({
  flexShrink: 0,
  transition: 'opacity 200ms ease-in',
});
export const ItemText = styled.span({ flexGrow: 1 });

export const Header = styled.div({
  padding: '0 20px',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
});
export const Title = styled.h5({
  flexGrow: 1,
  cursor: 'default',
  margin: '15px 0',
  fontFamily: fontFamily.body,
  fontSize: 19,
  fontWeight: fontWeight.normal,
  lineHeight: 1,
});
