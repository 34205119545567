/**
 * Check if a cookie is present. Inspired by:
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 *
 * @param {string} name The name of the cookie you want to check.
 * @returns {boolean} True if the the cookie is present.
 */
export function hasCookie(name: string): boolean {
  if (!name || /^(?:expires|max\-age|path|domain|secure)$/i.test(name)) {
    return false;
  }
  return new RegExp(
    `(?:^|;\\s*)${encodeURIComponent(name).replace(
      /[\-\.\+\*]/g,
      '\\$&',
    )}\\s*\\=`,
  ).test(document.cookie);
}

/**
 * Get the value of a cookie. Inspired by:
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 *
 * @param {string} name The name of the cookie you want to collect.
 * @returns {string} The value of the cookie, or undefined.
 */
export function getCookie(name: string): string | undefined {
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${encodeURIComponent(name).replace(
            /[\-\.\+\*]/g,
            '\\$&',
          )}\\s*\\=\\s*([^;]*).*$)|^.*$`,
        ),
        '$1',
      ),
    ) || undefined
  );
}

/**
 * Set a cookie. If a cookie with the same name already exists it will be overwritten. Inspired by:
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 *
 * @param {string} name The name of the cookie you want to set. Cannot be: 'expires', 'max-age',
 *                      'path', 'domain' or 'secure'.
 * @param {string} value A value to set.
 * @param {string} [expires] GMT formatted date for the cookie to expire (use date.toUTCString). If
 *                           omitted the cookie will expire at the end of the session.
 * @param {string} [path] Absolute path for the cookie. Defaults to '/'.
 * @param {string} [domain] Eg "example.com" or "subdomain.example.com". Defaults to the host
 *                          portion of the current document location, but not including subdomains.
 * @param {boolean} [secure] The cookie will be transmitted only over https.
 * @returns {boolean} True if the cookie was successfully set, false if an invalid name was passed.
 */
export function setCookie(
  name: string,
  value: string,
  // TODO These were converted from Flow nullable types (which includes both
  // null and undefined). Probably not the API we actually want.
  expires?: string | null | undefined,
  path?: string | null | undefined,
  domain?: string | null | undefined,
  secure?: boolean | null | undefined,
): boolean {
  if (!name || /^(?:expires|max\-age|path|domain|secure)$/i.test(name))
    return false;
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${
    expires ? `; expires=${expires}` : ''
  }${domain ? `; domain=${domain}` : ''}${
    path === undefined || path === null ? '; path=/' : `; path=${path}`
  }${secure ? '; secure' : ''}`;
  return true;
}

/**
 * Delete a cookie. Inspired by:
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework
 *
 * @param {string} name The name of the cookie you want to delete.
 * @param {string} [path] Absolute path for the cookie. Defaults to '/'.
 * @param {string} [domain] Cookie's domain. Required when deleting cookies from a subdomain.
 * @returns {boolean} True if the cookie was successfully deleted, false the cookie wasn't found.
 */
export function deleteCookie(
  name: string,
  // TODO These were converted from Flow nullable types (which includes both
  // null and undefined). Probably not the API we actually want.
  path?: string | null | undefined,
  domain?: string | null | undefined,
): boolean {
  if (!hasCookie(name)) return false;
  return setCookie(name, '', 'Thu, 01 Jan 1970 00:00:00 GMT', path, domain);
}
