import { memo, type ReactNode } from 'react';

import { ProficiencyIcon } from 'ms-pages/Lantern/components/ProficiencyIcon';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { colors } from 'ms-pages/Lantern/styles';
import { mapSanaProficiencyValueToMasteryLevel } from 'ms-utils/masteryLevel';
import type { MasteryLevel, SanaProficiencyValue } from 'ms-utils/masteryLevel';
import { assertUnreachable } from 'ms-utils/typescript-utils';

export type Value = SanaProficiencyValue;
export const INDICATOR_SIZE = 24;

function getProficiencyColor(masteryLevel: MasteryLevel) {
  switch (masteryLevel) {
    case 'NOT_STARTED':
      return colors.porcelain;
    case 'EXPLORING':
      return colors.white;
    case 'EMERGING':
      return colors.almond60;
    case 'FAMILIAR':
      return colors.almond60;
    case 'PROFICIENT':
      return colors.eggplant;
    case 'MASTERED':
      return colors.eggplant;
    default: {
      assertUnreachable(masteryLevel);
    }
  }
}

type Props = {
  height?: number | undefined;
  width?: number | undefined;
  value: Value;
  tooltipContent?: ReactNode | undefined;
  circle?: boolean | undefined;
};

export default memo<Props>(function ProficiencyIndicator({
  value,
  height = INDICATOR_SIZE,
  width,
  tooltipContent,
  circle = false,
}: Props) {
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(value);
  return (
    <ProficiencyIndicatorPresentational
      masteryLevel={masteryLevel}
      height={height}
      width={width}
      circle={circle}
      tooltipContent={tooltipContent}
    />
  );
});

type PresentationalProps = {
  masteryLevel: MasteryLevel | null | undefined;
  height: number;
  width?: number | undefined;
  tooltipContent?: ReactNode | undefined;
  circle?: boolean | undefined;
};

export function ProficiencyIndicatorPresentational({
  masteryLevel,
  height,
  width,
  tooltipContent,
  circle = false,
}: PresentationalProps) {
  const content = (
    <div
      css={[
        {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height,
          minWidth: INDICATOR_SIZE,
          borderRadius: circle ? '500px' : 4,
          backgroundColor:
            masteryLevel != null
              ? getProficiencyColor(masteryLevel)
              : colors.almond50,
          flexShrink: 0,
        },
        masteryLevel === 'EXPLORING' && {
          border: `2px solid ${colors.almond}`,
        },
        width != null ? { width } : {},
      ]}
    >
      {masteryLevel != null && (
        <ProficiencyIcon
          size={height / 2}
          masteryLevel={masteryLevel}
          proficiencyColorMap={{ EMERGING: 'eggplant' }}
        />
      )}
    </div>
  );

  if (tooltipContent != null) {
    return (
      <Tooltip backgroundColor={colors.grey} content={tooltipContent}>
        {content}
      </Tooltip>
    );
  }

  return content;
}
