import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';

import { useAnimateOnCartItemsIncreasing } from 'ms-components/AddProblemToCartButton';
import ChevronLeftThick from 'ms-components/icons/ChevronLeftThick/index';
import ChevronRightThick from 'ms-components/icons/ChevronRightThick/index';
import { BodyM, BodyS } from 'ms-pages/Lantern/primitives/Typography';
import {
  useCreateTaskDispatch as useCreateTaskModalDispatch,
  useCreateTaskState as useCreateTaskModalState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HSpacer } from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString } from 'ms-utils/colors';

const BUTTON_WIDTH = 220;
const BUTTON_WIDTH_LARGE = 300;
const BUTTON_HEIGHT = 40;
const BUTTON_ICON_SIZE = 16;
const BUTTON_SIZE = 'regular';

export const PRIMARY_CATFA_ACTION_BUTTON_ID = 'primary-catfa-action-button';

const Wrapper = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 16px',
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.ironLight}`,

  // to position step info
  position: 'relative',
});

const StepInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  // this needs to be positioned with absolute to be centered properly
  // the spaces between the buttons and the step info are not always the same
  // and on the right side there will be two buttons depending on the usage
  // but the step info will always be centered
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

const RightActionGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

const ANIMATING_INNER_BORDER_WIDTH = 3;
const ANIMATING_INNER_BORDER_COLOR = colors.white;
const ANIMATING_OUTER_BORDER_WIDTH = 4;
const ANIMATING_OUTER_BORDER_COLOR = hexStringToRgbaString(colors.crusta, 0.25);
const ButtonContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonIconWrapper = styled.div({
  height: BUTTON_ICON_SIZE,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonLabelWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  stepTitles: ReadonlyArray<string>;
  steps: ReadonlyArray<string>;
  nextButtonDisabledStates: { [p: string]: boolean };
  onLastStepAction: () => Promise<void> | void;
  withSkillCheckinStep?: boolean;
  numberOfSelections?: number | undefined;
  selectionTypeString?: string | undefined;
  secondaryLastStepActionLabel?: string | undefined;
  onSecondaryLastStepAction?: (() => void) | undefined;
  isSecondaryLastStepActionDisabled?: boolean | undefined;
  isPreviousButtonDisabledOnFirstStep?: boolean | undefined;
  isPreviousButtonDisabledOnSecondStep?: boolean | undefined;
  hideStepNumber?: boolean | undefined;
  hidePreviousButtonOnFirstStep?: boolean | undefined;
  hidePreviousButtonOnSecondStep?: boolean | undefined;
  hasLargeButtonOnFirstStep?: boolean | undefined;
};

export default function CreateTaskModalHeader({
  stepTitles,
  steps,
  nextButtonDisabledStates,
  onLastStepAction,
  withSkillCheckinStep = false,
  numberOfSelections,
  selectionTypeString,
  secondaryLastStepActionLabel,
  onSecondaryLastStepAction,
  isSecondaryLastStepActionDisabled = false,
  isPreviousButtonDisabledOnFirstStep:
    _isPreviousButtonDisabledOnFirstStep = false,
  isPreviousButtonDisabledOnSecondStep:
    _isPreviousButtonDisabledOnSecondStep = false,
  hideStepNumber = false,
  hidePreviousButtonOnFirstStep = false,
  hidePreviousButtonOnSecondStep = false,
  hasLargeButtonOnFirstStep: _hasLargeButtonOnFirstStep = false,
}: Props) {
  const { stepNumber } = useCreateTaskModalState();
  const dispatch = useCreateTaskModalDispatch();

  // we don't have proper typing for step names yet,
  // as a result, we don't have a proper type match between step number
  // and step title, so it causes step number to not match with step title
  // hopefully we'll fix this soon in refactoring CATFA state
  const leftButtonLabel = stepTitles[stepNumber] ?? '';
  const currentStepTitle = stepTitles[stepNumber + 1];
  const rightButtonLabel = stepTitles[stepNumber + 2] ?? '';
  const displayedCurrentStepNumber = stepNumber + 1;
  const numberOfSteps = steps.length;
  const isLastStep = stepNumber === steps.length - 1;
  const showSelection =
    numberOfSelections != null && numberOfSelections > 0 && !isLastStep;
  const showPluralSelection =
    numberOfSelections != null && numberOfSelections > 1;
  const isPreviousButtonDisabledOnFirstStep =
    stepNumber === 0 && _isPreviousButtonDisabledOnFirstStep;
  const isPreviousButtonDisabledOnSecondStep =
    stepNumber === 1 && _isPreviousButtonDisabledOnSecondStep;

  const isPreviousButtonDisabled =
    isPreviousButtonDisabledOnFirstStep || isPreviousButtonDisabledOnSecondStep;

  const isPreviousButtonHidden =
    (stepNumber === 0 && hidePreviousButtonOnFirstStep) ||
    (stepNumber === 1 && hidePreviousButtonOnSecondStep);

  const hasLargeButtonOnFirstStep =
    stepNumber === 0 && _hasLargeButtonOnFirstStep;

  const showSecondaryLastStepAction =
    isLastStep &&
    secondaryLastStepActionLabel != null &&
    onSecondaryLastStepAction != null;

  // To provide a seamless flow experience,
  // we need to use a combined disabled state for the next button
  // that takes into account all previous steps and the current step
  const isNextButtonDisabled = useMemo(() => {
    const disabledStatesCombined = [];

    for (let i = 0; i < stepNumber + 1; i++) {
      // we don't have proper typing for step names yet,
      // hopefully we'll fix this soon in refactoring CATFA state
      const stepName = steps[i] ?? '';
      const disabledState = nextButtonDisabledStates?.[stepName] ?? false;
      disabledStatesCombined.push(disabledState);
    }

    return disabledStatesCombined.some(disabledState => disabledState);
  }, [nextButtonDisabledStates, stepNumber, steps]);

  const goBack = useCallback(() => {
    dispatch({
      type: 'backward',
      withSkillCheckinStep,
    });
  }, [dispatch, withSkillCheckinStep]);

  const next = useCallback(() => {
    dispatch({
      type: 'forward',
      withSkillCheckinStep,
    });
  }, [dispatch, withSkillCheckinStep]);

  const nextButtonAction = useCallback(() => {
    if (isLastStep) {
      onLastStepAction();
    } else {
      next();
    }
  }, [isLastStep, next, onLastStepAction]);

  const isAnimating = useAnimateOnCartItemsIncreasing();

  return (
    <Wrapper>
      {!isPreviousButtonHidden && (
        <Button
          size={BUTTON_SIZE}
          type="secondary"
          isRound
          height={BUTTON_HEIGHT}
          isDisabled={isPreviousButtonDisabled}
          width={BUTTON_WIDTH}
          onClick={goBack}
          label={leftButtonLabel}
        >
          <ButtonContentWrapper>
            <ButtonIconWrapper>
              <ChevronLeftThick size={BUTTON_ICON_SIZE} />
            </ButtonIconWrapper>
            <HSpacer width={10} />
            <ButtonLabelWrapper>{leftButtonLabel}</ButtonLabelWrapper>
          </ButtonContentWrapper>
        </Button>
      )}
      <StepInfo>
        {!hideStepNumber && (
          <BodyS bold color="grey90">
            Step {displayedCurrentStepNumber} of {numberOfSteps}
          </BodyS>
        )}
        <BodyM bold color="neutralGray">
          {currentStepTitle}
        </BodyM>
      </StepInfo>
      <RightActionGroup>
        {showSecondaryLastStepAction && (
          <>
            <Button
              size={BUTTON_SIZE}
              type="secondary"
              isRound
              height={BUTTON_HEIGHT}
              width={156}
              padding={0}
              isDisabled={isSecondaryLastStepActionDisabled}
              onClick={onSecondaryLastStepAction}
              label={secondaryLastStepActionLabel}
            >
              {secondaryLastStepActionLabel}
            </Button>
            <HSpacer width={11} />
          </>
        )}
        <Button
          size={BUTTON_SIZE}
          type="primary"
          isRound
          height={BUTTON_HEIGHT}
          width={
            hasLargeButtonOnFirstStep
              ? BUTTON_WIDTH_LARGE
              : showSecondaryLastStepAction
              ? 140
              : BUTTON_WIDTH
          }
          isDisabled={isNextButtonDisabled}
          onClick={nextButtonAction}
          label={rightButtonLabel}
          styles={{
            // this is to perform an "holo" effect on the "Preview (x questions)" button.
            // Since this button is defined as a primary button, we need to apply the effect only when it's used as "Preview (x questions)" button; which is when `showSelection` is true.
            boxShadow: !showSelection
              ? undefined
              : `0 0 0 ${ANIMATING_INNER_BORDER_WIDTH}px ${ANIMATING_INNER_BORDER_COLOR}${
                  isAnimating
                    ? `, 0 0 0 ${
                        ANIMATING_INNER_BORDER_WIDTH +
                        ANIMATING_OUTER_BORDER_WIDTH
                      }px ${ANIMATING_OUTER_BORDER_COLOR}`
                    : ''
                }`,
          }}
        >
          <ButtonContentWrapper id={PRIMARY_CATFA_ACTION_BUTTON_ID}>
            <ButtonLabelWrapper
              style={
                !showSecondaryLastStepAction
                  ? {
                      // to slightly move the label up to align with the icon perfectly
                      marginTop: -1,
                    }
                  : {}
              }
            >
              {rightButtonLabel}
              {showSelection && (
                <>
                  &nbsp;({numberOfSelections}&nbsp;
                  {selectionTypeString != null
                    ? selectionTypeString
                    : showPluralSelection
                    ? 'questions'
                    : 'question'}
                  )
                </>
              )}
            </ButtonLabelWrapper>
            {!showSecondaryLastStepAction && (
              <>
                <HSpacer width={10} />
                <ButtonIconWrapper>
                  <ChevronRightThick size={BUTTON_ICON_SIZE} />
                </ButtonIconWrapper>
              </>
            )}
          </ButtonContentWrapper>
        </Button>
      </RightActionGroup>
    </Wrapper>
  );
}
