import { StyleSheet } from 'aphrodite';

import {
  ATTRIBUTES_WRAPPER_PADDING_VERTICAL,
  ATTRIBUTES_WRAPPER_PADDING_HORIZONTAL,
  PROBLEM_ITEM_DEFAULT_BORDER_RADIUS,
  BORDER_WIDTH,
} from 'ms-pages/Textbooks/components/ProblemItem/constants';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT, boxShadow } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

export const ProblemWrapper = styled({
  width: '100%',
  border: `${BORDER_WIDTH}px solid ${colors.ironLight}`,
  borderRadius: PROBLEM_ITEM_DEFAULT_BORDER_RADIUS,
  boxShadow: boxShadow.tinyLight,
  backgroundColor: colors.white,

  // To position the add button
  position: 'relative',
});

export const AttributesWrapper = styled({
  padding: `${ATTRIBUTES_WRAPPER_PADDING_VERTICAL}px 0 ${ATTRIBUTES_WRAPPER_PADDING_VERTICAL}px ${ATTRIBUTES_WRAPPER_PADDING_HORIZONTAL}px`,
  borderTop: `1px solid ${colors.ironLight}`,
});

export const InnerAttributesWrapper = styled({
  display: 'flex',
  alignItems: 'center',
});

export const problemStyles = StyleSheet.create({
  chevron: {
    transition: 'transform 0.1s linear',
    marginRight: 4 * BASE_UNIT,
  },
  chevronBottom: {
    transform: `rotate(0deg)`,
  },
  chevronTop: {
    transform: `rotate(-180deg)`,
  },
  triangle: {
    transition: 'transform 0.1s linear',
  },
  triangleRight: {
    transform: `rotate(-90deg)`,
  },
});
