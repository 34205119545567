import styled from '@emotion/styled';
import {
  useEffect,
  useState,
  useCallback,
  type ReactNode,
  type KeyboardEvent as SyntheticKeyboardEvent,
  type ComponentType,
} from 'react';

import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import { VStack, type StackProps } from 'ms-ui-primitives/Stack';
import {
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR,
  useAccessibilityMode,
} from 'ms-utils/accessibility';
import { Logger } from 'ms-utils/app-logging';
import {
  multilineTextOverflow,
  tappable,
  onPressOrHover,
} from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';

export const CARD_WIDTH = 226;
export const CARD_HEADER_HEIGHT = 64;
export const CARD_BODY_HEIGHT = 142;
const CARD_BODY_HEIGHT_SMALL = 106;
export const CARD_HEIGHT_SMALL = CARD_HEADER_HEIGHT + CARD_BODY_HEIGHT_SMALL;
const BOX_SHADOW = '0px 1px 7px rgba(62, 62, 76, 0.12)';

const ACCESSIBILITY_BOX_SHADOW = `0 0 0 ${FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH}px ${colors.grey10}`; // This behaves like a border
const ACCESSIBILITY_BOX_SHADOW_FOCUS = `${ACCESSIBILITY_BOX_SHADOW}, 0 0 0 ${
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH * 2
}px ${FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR}`;
export const CARD_BORDER_RADIUS = 16;
export const CARD_PADDING = 16;
const CARD_BOX_SHADOW_HOVER = '0px 8.75px 49px rgba(62, 62, 76, 0.15)';

const CARD_BODY_BACKGROUND = 'white';
export const CARD_HEIGHT = CARD_HEADER_HEIGHT + CARD_BODY_HEIGHT;

export const CardBody = styled(VStack, {
  shouldForwardProp: (prop): prop is keyof StackProps => prop !== 'small',
})<{ small?: boolean }>(({ small }) => ({
  height: small ? CARD_BODY_HEIGHT_SMALL : CARD_BODY_HEIGHT,
  padding: CARD_PADDING,
  backgroundColor: colors[CARD_BODY_BACKGROUND],
  borderBottomLeftRadius: CARD_BORDER_RADIUS,
  borderBottomRightRadius: CARD_BORDER_RADIUS,
}));

export const CardBodyFullHeight = styled(VStack, {
  shouldForwardProp: (prop): prop is keyof StackProps => prop !== 'small',
})<{ small?: boolean }>(({ small }) => ({
  height: small ? CARD_HEIGHT_SMALL : CARD_HEIGHT,
  padding: CARD_PADDING,
  backgroundColor: colors[CARD_BODY_BACKGROUND],
  borderRadius: CARD_BORDER_RADIUS,
}));

type ColorName = keyof typeof colors;

type CardProps = {
  href: string | undefined;
  isDisabled?: boolean | undefined;
  backgroundOnHover?: ColorName | undefined;
  children: ReactNode;
  onClick?: (() => void) | undefined;
  isNotInteractive?: boolean;
};

type CardElProps = CardProps & {
  accessibilityMode: boolean;
  onKeyDown: (event: SyntheticKeyboardEvent<any>) => void;
};

const CardEl = styled('a', {
  shouldForwardProp: p => {
    if (typeof p !== 'string') return true;
    return ![
      'backgroundOnHover',
      'isDisabled',
      'accessibilityMode',
      'isNotInteractive',
    ].includes(p);
  },
})<CardElProps>(
  ({ isDisabled = false, accessibilityMode, isNotInteractive = false }) => ({
    display: 'block',
    textDecoration: 'none',
    width: CARD_WIDTH,
    borderRadius: CARD_BORDER_RADIUS,
    ...tappable,
    boxShadow: accessibilityMode ? ACCESSIBILITY_BOX_SHADOW : BOX_SHADOW,
    ...onPressOrHover({
      boxShadow: accessibilityMode
        ? ACCESSIBILITY_BOX_SHADOW
        : CARD_BOX_SHADOW_HOVER,
    }),
    ':focus': {
      boxShadow: accessibilityMode
        ? ACCESSIBILITY_BOX_SHADOW_FOCUS
        : BOX_SHADOW,
      ...(accessibilityMode ? { outline: 'none' } : {}),
    },

    opacity: isDisabled ? 0.6 : 1,
    pointerEvents: isDisabled || isNotInteractive ? 'none' : 'auto',
    filter: isDisabled ? 'grayscale(1)' : 'none',
  }),
);

export function Card({
  children,
  href,
  onClick,
  isNotInteractive = false,
  ...restProps
}: CardProps) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const action = useCallback(() => {
    if (onClick != null) {
      onClick();
    }
    if (href != null) {
      window.location.assign(href);
    }
  }, [href, onClick]);

  return (
    <CardEl
      isNotInteractive={isNotInteractive}
      href={href}
      accessibilityMode={hasEnabledAccessibilityMode}
      onClick={onClick}
      onKeyDown={keyDownMap({
        SPACE: [action, { preventDefault: true }],
        ENTER: action,
      })}
      {...restProps}
    >
      {children}
    </CardEl>
  );
}

export const CardHeader = styled.div({
  height: CARD_HEADER_HEIGHT,
  borderTopLeftRadius: CARD_BORDER_RADIUS,
  borderTopRightRadius: CARD_BORDER_RADIUS,
  backgroundPosition: 'center center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
});

export const CardTitleEl = styled.div({
  textAlign: 'left',
  height: 48,
  // NB this is a util for ms-utils/emotion/styled but
  // it works as well with emotion/styled as the API are almost indentical
  ...multilineTextOverflow(2),
});

export const CardTitle: ComponentType<{ title: string }> = ({
  title,
}: {
  title: string;
}) => (
  <CardTitleEl>
    <BodyM>
      <Bold>{title}</Bold>
    </BodyM>
  </CardTitleEl>
);

// WARNING This is a hack because
// - there's no guarantee that the resource is actually a svg file
// - there's no guarantee that, even if it is a svg file, the structure of the
//   XML is such that the first rect is the background color
const DEFAULT_BG_COLOR = colors.eggplant;
export function useGetBadgeBackground(topic: {
  readonly badgeUrl: string;
  readonly badgeBackground: string | null | undefined;
}) {
  const [rectColor, setRectColor] = useState<string>(DEFAULT_BG_COLOR);

  useEffect(() => {
    async function fetchSvgRectColor() {
      let svgBackgroundColor: string = DEFAULT_BG_COLOR;
      if (topic.badgeBackground != null) {
        setRectColor(topic.badgeBackground);
        return;
      }
      const svgUrl = topic.badgeUrl;
      if (svgUrl == null) return;
      if (svgUrl.endsWith('.svg')) {
        try {
          const svg = await fetch(svgUrl).then(res => res.text());
          const parsedSvg = new DOMParser().parseFromString(
            svg,
            'image/svg+xml',
          );
          svgBackgroundColor =
            parsedSvg?.documentElement
              ?.querySelector('rect')
              ?.getAttribute('fill') ?? svgBackgroundColor;
        } catch (e) {
          Logger.info(`Cannot parse the background color of ${svgUrl}`);
        }
      }

      setRectColor(svgBackgroundColor);
    }
    fetchSvgRectColor();
  }, [topic]);

  return rectColor;
}
