/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ChapterIntroViewQueryVariables = {
    syllabusId: string;
};
export type ChapterIntroViewQueryResponse = {
    readonly syllabus: {
        readonly id: string;
        readonly title: string;
        readonly thumbnailImageUrl: string;
        readonly coverImageUrl: string;
        readonly overview: {
            readonly description: string;
            readonly taskTemplateGroups: ReadonlyArray<{
                readonly " $fragmentRefs": FragmentRefs<"TaskTemplateGroupList_templateGroups">;
            }>;
        } | null;
    } | null;
};
export type ChapterIntroViewQuery = {
    readonly response: ChapterIntroViewQueryResponse;
    readonly variables: ChapterIntroViewQueryVariables;
};



/*
query ChapterIntroViewQuery(
  $syllabusId: ID!
) {
  syllabus(id: $syllabusId) {
    id
    title
    thumbnailImageUrl
    coverImageUrl
    overview {
      description
      taskTemplateGroups {
        ...TaskTemplateGroupList_templateGroups
        id
      }
      id
    }
  }
}

fragment TaskTemplateGroupList_templateGroups on TaskTemplateGroup {
  id
  title
  thumbnailImageUrl
  taskTemplateCount
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syllabusId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syllabusId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImageUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coverImageUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChapterIntroViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SyllabusOverview",
            "kind": "LinkedField",
            "name": "overview",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskTemplateGroup",
                "kind": "LinkedField",
                "name": "taskTemplateGroups",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TaskTemplateGroupList_templateGroups"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChapterIntroViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SyllabusOverview",
            "kind": "LinkedField",
            "name": "overview",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskTemplateGroup",
                "kind": "LinkedField",
                "name": "taskTemplateGroups",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taskTemplateCount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "246b1719ddc219af172865286119eb7f",
    "id": null,
    "metadata": {},
    "name": "ChapterIntroViewQuery",
    "operationKind": "query",
    "text": "query ChapterIntroViewQuery(\n  $syllabusId: ID!\n) {\n  syllabus(id: $syllabusId) {\n    id\n    title\n    thumbnailImageUrl\n    coverImageUrl\n    overview {\n      description\n      taskTemplateGroups {\n        ...TaskTemplateGroupList_templateGroups\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment TaskTemplateGroupList_templateGroups on TaskTemplateGroup {\n  id\n  title\n  thumbnailImageUrl\n  taskTemplateCount\n}\n"
  }
};
})();
(node as any).hash = 'ad5fbb8da4945eef93ee47ebaa8e8bf8';
export default node;
