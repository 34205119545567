import { StyleSheet, css } from 'aphrodite';

import { fontFamily, fontSize, lineHeight, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const styles = StyleSheet.create({
  root: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    fontSize: fontSize.sectionTitle,
    color: colors.cloudBurst,
    marginTop: 48,
    marginBottom: 15,
    lineHeight: lineHeight.heading,
  },
});

type Props = {
  children?: string | undefined;
};

const SectionTitle = ({ children }: Props) => (
  <h2 className={css(styles.root)}>{children}</h2>
);

export default SectionTitle;
