import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Home = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <path d="M9.271 1.047a2 2 0 0 0-2.542 0l-6 4.941A2 2 0 0 0 0 7.532V15a1 1 0 0 0 1 1h4v-4.647a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1V16h4a1 1 0 0 0 1-1V7.532a2 2 0 0 0-.729-1.544l-6-4.941Z" />
  </Icon>
);

Home.displayName = 'Home';

export default Home;
