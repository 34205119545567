import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const EngageTask = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.93272 4.24579C3.33961 1.87735 5.40566 0 8.00511 0C10.8233 0 13.1672 2.26884 13.1672 5.15583C13.1672 7.06517 12.3141 8.04895 11.5614 8.91697L11.5609 8.91754C10.9474 9.62475 10.4133 10.2407 10.3593 11.3123H5.66127C5.60005 10.137 5.03059 9.50135 4.37725 8.77212L4.37622 8.77097L4.37612 8.77086C3.50267 7.79616 2.51241 6.69111 2.93272 4.24579ZM5.66689 13.1874V12.2499H10.3546V13.6562C10.3546 14.9484 9.29265 15.6738 8.0001 15.6738C6.86787 15.6738 5.93159 15.1934 5.71408 14.1249H8.01073C8.26949 14.1249 8.4795 13.9149 8.4795 13.6562C8.4795 13.3974 8.26949 13.1874 8.01073 13.1874H5.66689ZM9.81355 6.29589L8.61732 7.49211C8.06227 8.04676 7.21096 7.20661 7.77119 6.64598L8.38087 6.03671L6.73048 5.21171C6.36603 5.02909 6.28668 4.54223 6.57497 4.25354L7.77119 3.05732C8.33063 2.49828 9.17716 3.34321 8.61732 3.90344L8.00765 4.51272L9.65804 5.33772C10.0225 5.52034 10.1018 6.0072 9.81355 6.29589Z"
    />
  </Icon>
);

EngageTask.displayName = 'EngageTask';

export default EngageTask;
