import styled from '@emotion/styled';

const ASSET_WHITESPACE_FRACTION = 12 / 89; // assume 12px unused perimeter on a 89px image

const Img = styled.img({
  flexShrink: 0, // never shrink
});
type Props = {
  size: string | number;
  src: string;
  alt: string;
};

const BadgeImg = ({ size, src, alt }: Props) => (
  <Img
    style={{
      width: size,
      height: size,
      transform: `scale(${1 / (1 - 2 * ASSET_WHITESPACE_FRACTION)})`,
    }}
    src={src}
    alt={alt}
  />
);

export default BadgeImg;
