/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SkillRecommendationsQueryVariables = {
    skillId: string;
};
export type SkillRecommendationsQueryResponse = {
    readonly lantern: {
        readonly skill: {
            readonly outcome: {
                readonly subtopics: ReadonlyArray<{
                    readonly id: string;
                    readonly hasAdaptivePractice: boolean;
                    readonly topic: {
                        readonly id: string;
                        readonly syllabus: {
                            readonly id: string;
                        };
                    };
                    readonly gamifiedMastery: number;
                    readonly " $fragmentRefs": FragmentRefs<"OutcomeSubtopicRecommendationCard_subtopic">;
                }>;
            };
        } | null;
    };
};
export type SkillRecommendationsQuery = {
    readonly response: SkillRecommendationsQueryResponse;
    readonly variables: SkillRecommendationsQueryVariables;
};



/*
query SkillRecommendationsQuery(
  $skillId: ID!
) {
  lantern {
    skill(id: $skillId) {
      outcome {
        subtopics {
          id
          hasAdaptivePractice
          topic {
            id
            syllabus {
              id
            }
          }
          gamifiedMastery
          ...OutcomeSubtopicRecommendationCard_subtopic
        }
        id
      }
      id
    }
  }
}

fragment OutcomeSubtopicRecommendationCard_subtopic on Subtopic {
  id
  title
  topic {
    id
    badgeUrl
    badgeBackground
    syllabus {
      id
    }
  }
  hasAdaptivePractice
  gamifiedMastery
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "skillId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAdaptivePractice",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabus",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gamifiedMastery",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillRecommendationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternOutcome",
                "kind": "LinkedField",
                "name": "outcome",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subtopic",
                    "kind": "LinkedField",
                    "name": "subtopics",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutcomeSubtopicRecommendationCard_subtopic"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SkillRecommendationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Skill",
            "kind": "LinkedField",
            "name": "skill",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternOutcome",
                "kind": "LinkedField",
                "name": "outcome",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subtopic",
                    "kind": "LinkedField",
                    "name": "subtopics",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeBackground",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1315aee2154dd240637ae4c54aaf567f",
    "id": null,
    "metadata": {},
    "name": "SkillRecommendationsQuery",
    "operationKind": "query",
    "text": "query SkillRecommendationsQuery(\n  $skillId: ID!\n) {\n  lantern {\n    skill(id: $skillId) {\n      outcome {\n        subtopics {\n          id\n          hasAdaptivePractice\n          topic {\n            id\n            syllabus {\n              id\n            }\n          }\n          gamifiedMastery\n          ...OutcomeSubtopicRecommendationCard_subtopic\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment OutcomeSubtopicRecommendationCard_subtopic on Subtopic {\n  id\n  title\n  topic {\n    id\n    badgeUrl\n    badgeBackground\n    syllabus {\n      id\n    }\n  }\n  hasAdaptivePractice\n  gamifiedMastery\n}\n"
  }
};
})();
(node as any).hash = '15fd9a5a0b08f70f0966c6e44ce93de4';
export default node;
