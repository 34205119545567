import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const StraightHorizontalLine = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={30}
    viewBoxHeight={30}
  >
    <line
      x1="5"
      y1="15"
      x2="25"
      y2="15"
      fill="transparent"
      strokeWidth="3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    ></line>{' '}
  </Icon>
);

StraightHorizontalLine.displayName = 'StraightHorizontalLine';

export default StraightHorizontalLine;
