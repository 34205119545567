import { StyleSheet, css } from 'aphrodite';
import type { ReactElement } from 'react';

import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const paddingBottom = 8;
const marginTop = 5;

const styles = StyleSheet.create({
  root: {
    marginTop: 16 * 3,
    marginBottom: 30,
    borderBottom: `1px solid ${colors.iron}`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  text: {
    textTransform: 'uppercase',
    color: colors.cloudBurst,
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
    fontFamily: fontFamily.body,
    flexGrow: 1,
    flexShrink: 0,
    lineHeight: 1,
  },
  action: {
    flexShrink: 0,
    paddingBottom,
    marginTop,
  },
});

type Props = {
  children?: string | undefined;
  action?: ReactElement<any> | undefined;
};

const SubsectionTitle = ({ children, action }: Props) => (
  <div className={css(styles.root)}>
    <div
      className={css(styles.text)}
      style={{
        paddingBottom: action ? 0 : paddingBottom,
        marginTop: action ? 0 : marginTop,
      }}
    >
      {children}
    </div>
    <div className={css(styles.action)}>{action && action}</div>
  </div>
);

export default SubsectionTitle;
