import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';

import { FooterWrapper } from './modalSharedStyles';

const ModalFooter = ({
  onCancel,
  onSubmit,
  isDisabled = false,
  submitButtonLabel,
}: {
  onCancel: () => void;
  onSubmit: () => void;
  isDisabled?: boolean;
  submitButtonLabel: string;
}) => (
  <FooterWrapper>
    <Stack.H center justify="end">
      <Button type="tertiary" onClick={onCancel}>
        Cancel
      </Button>
      <Button type="primary" isRound onClick={onSubmit} isDisabled={isDisabled}>
        {submitButtonLabel}
      </Button>
    </Stack.H>
  </FooterWrapper>
);

export default ModalFooter;
