/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TextbookType = "CORE_TEXTBOOK" | "LEGACY" | "SKILLS_BOOK";
export type TextbookTypeTabsQueryVariables = {
    schoolId: string;
    isTeacher: boolean;
    isStudent: boolean;
    currentSyllabusId: string;
};
export type TextbookTypeTabsQueryResponse = {
    readonly school?: {
        readonly availableSyllabuses: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
            readonly textbookType: TextbookType;
        }> | null;
    } | null | undefined;
    readonly viewer?: {
        readonly profile: {
            readonly availableSyllabuses?: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly textbookType: TextbookType;
            }> | undefined;
        };
    } | undefined;
    readonly currentSyllabus: {
        readonly textbookType: TextbookType;
    } | null;
};
export type TextbookTypeTabsQuery = {
    readonly response: TextbookTypeTabsQueryResponse;
    readonly variables: TextbookTypeTabsQueryVariables;
};



/*
query TextbookTypeTabsQuery(
  $schoolId: ID!
  $isTeacher: Boolean!
  $isStudent: Boolean!
  $currentSyllabusId: ID!
) {
  school(id: $schoolId) @include(if: $isTeacher) {
    availableSyllabuses {
      id
      title
      textbookType
    }
    id
  }
  viewer @include(if: $isStudent) {
    profile {
      __typename
      ... on Student {
        availableSyllabuses {
          id
          title
          textbookType
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
  currentSyllabus: syllabus(id: $currentSyllabusId) {
    textbookType
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "currentSyllabusId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isStudent"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTeacher"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "currentSyllabusId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textbookType",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "availableSyllabuses",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "kind": "InlineFragment",
  "selections": (v9/*: any*/),
  "type": "Student",
  "abstractKey": null
},
v11 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TextbookTypeTabsQuery",
    "selections": [
      {
        "alias": "currentSyllabus",
        "args": (v4/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "isTeacher",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "School",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isStudent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TextbookTypeTabsQuery",
    "selections": [
      {
        "alias": "currentSyllabus",
        "args": (v4/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "isTeacher",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "School",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "isStudent",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v11/*: any*/),
                    "type": "Teacher",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "90c27ef51dd08e5bb24f9242c662198e",
    "id": null,
    "metadata": {},
    "name": "TextbookTypeTabsQuery",
    "operationKind": "query",
    "text": "query TextbookTypeTabsQuery(\n  $schoolId: ID!\n  $isTeacher: Boolean!\n  $isStudent: Boolean!\n  $currentSyllabusId: ID!\n) {\n  school(id: $schoolId) @include(if: $isTeacher) {\n    availableSyllabuses {\n      id\n      title\n      textbookType\n    }\n    id\n  }\n  viewer @include(if: $isStudent) {\n    profile {\n      __typename\n      ... on Student {\n        availableSyllabuses {\n          id\n          title\n          textbookType\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n  currentSyllabus: syllabus(id: $currentSyllabusId) {\n    textbookType\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '64ddfefc76da47e53912641ad93762aa';
export default node;
