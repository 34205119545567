import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

import { MATH_CONTENT_MARGIN } from 'ms-pages/Work/layout';
import { fontFamily, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const styles = StyleSheet.create({
  hintContext: {
    color: colors.lochmara,
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.body,
    margin: `${MATH_CONTENT_MARGIN}px 0`,
  },
});

const HintContext = ({ children }: { children: ReactNode }) => (
  <p className={css(styles.hintContext)}>{children}</p>
);

export default HintContext;
