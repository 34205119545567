import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Worksheet = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <path d="M14.9431 5.05589C14.6007 4.71326 14.0564 4.69173 13.6884 4.99164C13.6352 5.03501 13.6764 4.99679 12.866 5.8072L14.9696 7.91086L15.7256 7.16427C16.0912 6.79873 16.0912 6.20398 15.7256 5.83842L14.9431 5.05589Z" />
    <path d="M8.55217 10.1608L7.31474 12.8125C7.23139 12.9912 7.26867 13.2028 7.40805 13.3422C7.54742 13.4816 7.75917 13.5188 7.93774 13.4355L10.5894 12.198C10.6943 12.1491 10.3767 12.4463 14.3026 8.56947L12.2031 6.46997C8.3107 10.3624 8.60211 10.0538 8.55217 10.1608Z" />
    <path d="M11.3791 12.7741C11.2643 12.8874 11.132 12.9795 10.9858 13.0477L8.33415 14.2852C7.81271 14.5285 7.17568 14.4358 6.74515 14.0052C6.32856 13.5887 6.21603 12.9501 6.46515 12.4161L7.70262 9.76437C7.77212 9.61547 7.86631 9.48103 7.98253 9.36478L11.969 5.37831V1.40625C11.969 0.630844 11.3382 0 10.5627 0H1.40649C0.631088 0 0.000244141 0.630844 0.000244141 1.40625V14.5938C0.000244141 15.3692 0.631088 16 1.40649 16H10.5627C11.3382 16 11.969 15.3692 11.969 14.5938V12.1915L11.3791 12.7741ZM2.50028 3.03125H9.50027C9.75915 3.03125 9.96902 3.24113 9.96902 3.5C9.96902 3.75887 9.75915 3.96875 9.50027 3.96875H2.50028C2.2414 3.96875 2.03153 3.75887 2.03153 3.5C2.03153 3.24113 2.2414 3.03125 2.50028 3.03125ZM2.50028 5.53125H8.00027C8.25915 5.53125 8.46902 5.74113 8.46902 6C8.46902 6.25887 8.25915 6.46875 8.00027 6.46875H2.50028C2.2414 6.46875 2.03153 6.25887 2.03153 6C2.03153 5.74113 2.2414 5.53125 2.50028 5.53125ZM2.03153 8.5C2.03153 8.24113 2.2414 8.03125 2.50028 8.03125H6.50028C6.75915 8.03125 6.96903 8.24113 6.96903 8.5C6.96903 8.75887 6.75915 8.96875 6.50028 8.96875H2.50028C2.2414 8.96875 2.03153 8.75887 2.03153 8.5Z" />
  </Icon>
);

Worksheet.displayName = 'Worksheet';

export default Worksheet;
