import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';

// Define an ad-hoc util as it differs from regular feature flag checks but
// there are at least 3 code paths that uses this
export function useCanAccessCalculator() {
  const { isFloridaUser } = useMaybeViewer() || {
    isFloridaUser: false,
  };
  return isFloridaUser;
}

// Define an ad-hoc util as it differs from regular feature flag checks but
// there are at least 3 code paths that uses this
export function useCanAccessNotes() {
  const { isFloridaUser } = useMaybeViewer() || {
    isFloridaUser: false,
  };

  return isFloridaUser;
}
