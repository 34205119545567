import ChevronBottomIcon from 'ms-components/icons/ChevronBottom';
import ChevronTopIcon from 'ms-components/icons/ChevronTop';
import { fontWeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { styled, tappable, onPressOrHover } from 'ms-utils/emotion';

const AccordionControlElement = styled({
  height: 48,
  padding: 16,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 16,
  fontWeight: fontWeight.semibold,
  marginBottom: 16,
  color: colors.eggplant,
  borderRadius: 4,
  backgroundColor: colors.seashell,
  ...tappable,
  ...onPressOrHover({
    color: alternateColors.eggplant,
    opacity: 0.8,
  }),
});

type Props = {
  isOpen: boolean;
  label: string;
  onToggle: () => void;
};

export default function AccordionControl({ isOpen, label, onToggle }: Props) {
  return (
    <AccordionControlElement onClick={onToggle}>
      {label}
      {isOpen ? <ChevronTopIcon /> : <ChevronBottomIcon />}
    </AccordionControlElement>
  );
}
