import { useState } from 'react';

import Input from 'ms-ui-primitives/Input';

import ConfirmationPrompt from './ConfirmationPrompt';

type Props = {
  noteTitle: string;
  isOpen: boolean;
  onSave: (note: string) => void;
  onCancel: () => void;
};

export default function SaveNote({
  noteTitle,
  isOpen,
  onSave,
  onCancel,
}: Props) {
  const [title, setTitle] = useState(noteTitle);

  return (
    <ConfirmationPrompt
      isOpen={isOpen}
      onConfirm={() => {
        onSave(title);
      }}
      onCancel={onCancel}
      onClose={onCancel}
      header="Update note"
      body={
        <>
          <div style={{ marginBottom: 12 }}>Title</div>
          <div>
            <Input value={title} onChange={e => setTitle(e.target.value)} />
          </div>
        </>
      }
      confirmLabel="Save"
    />
  );
}
