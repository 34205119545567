import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnowplow } from 'ms-helpers/Snowplow';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useValidateSunflowerUserDetails from 'ms-pages/Signup/Sunflower/useValidateSunflowerUserDetails';
import {
  getSunflowerSchoolSelectionUrl,
  signupSunflowerStudentContactTeacherUrl,
} from 'ms-utils/urls';

import { FooterLinks } from './FooterLinks';
import { ProgressStepper } from './ProgressStepper';
import { UserForm } from './UserForm';

function StudentSignup() {
  const { trackStructEvent } = useSnowplow();
  const { state, dispatch } = useSignupState();

  const [validateUserDetails, { response: validationResponse }] =
    useValidateSunflowerUserDetails();

  const { push } = useHistory();

  useEffect(() => {
    const errors = validationResponse?.validateSunflowerUserDetails?.errors;
    if (validationResponse != null && errors?.length === 0) {
      push(
        state.ageConfirmed
          ? getSunflowerSchoolSelectionUrl({ origin: 'student' })
          : signupSunflowerStudentContactTeacherUrl,
      );
    }
  }, [push, state.ageConfirmed, validationResponse]);

  const trackingIdPrefix = 'SignupPage/StudentSignup';

  return (
    <>
      <ProgressStepper step={2} />
      <UserForm
        role="student"
        title="Student sign up"
        firstName={state.firstName}
        lastName={state.lastName}
        email={state.email}
        password={state.password}
        ageConfirmed={state.ageConfirmed ?? false}
        showAgeConfirmed
        onFirstNameChange={value => dispatch({ type: 'setFirstName', value })}
        onLastNameChange={value => dispatch({ type: 'setLastName', value })}
        onEmailChange={value => dispatch({ type: 'setEmail', value })}
        onPasswordChange={value => dispatch({ type: 'setPassword', value })}
        onAgeConfirmedChange={value =>
          dispatch({ type: 'setAgeConfirmed', value })
        }
        isDisabled={
          state.firstName === '' ||
          state.lastName === '' ||
          state.email === '' ||
          state.password === ''
        }
        onSubmit={() => {
          trackStructEvent({
            category: 'signup_sunflower',
            action: 'submitted_account_details',
            label: 'student',
          });
          dispatch({ type: 'clearErrors' });
          validateUserDetails({});
        }}
        submitButtonLabel="Continue"
        errorMessage={
          state.hasErrors ? 'Please correct the errors to continue.' : undefined
        }
        errors={state.responseErrors}
        trackingIdPrefix={trackingIdPrefix}
      />
      <FooterLinks
        showLoginLink={false}
        showJoinClassLink
        trackingIdPrefix={trackingIdPrefix}
      />
    </>
  );
}

export { StudentSignup };
