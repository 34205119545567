import { useState, useEffect } from 'react';
// takes a value and returns the same value
// changes will only propagate after specific delay
export default function useDebouncedValue<T>(
  value: T,
  debouncePeriod: number,
): T {
  const [_value, setValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(value), debouncePeriod);
    return () => clearTimeout(timeoutId);
  }, [debouncePeriod, value]);
  return _value;
}
