import type { CSSInputTypes } from 'aphrodite';
import { useState } from 'react';

import PlayIcon from 'ms-components/icons/Play';
import Button from 'ms-ui-primitives/Button';
import { Anchor } from 'ms-ui-primitives/Link';
import Modal from 'ms-ui-primitives/Modal';
import VideoEmbed from 'ms-ui-primitives/VideoEmbed';
import type { Props as VideoEmbedProps } from 'ms-ui-primitives/VideoEmbed';

const BUTTON_ICON_SIZE = 20;
const BUTTON_ICON_GUTTER = 5;

type Props = VideoEmbedProps & {
  launchLinkText?: string | undefined;
  onLaunch?: (() => void) | undefined;
  launchLinkStyles?: CSSInputTypes[] | undefined;
};

function VideoLauncher({
  id,
  videoHostingService,
  aspectRatio,
  onLaunch,
  launchLinkText,
  launchLinkStyles = [],
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {launchLinkText ? (
        <Anchor
          key="launchLink"
          onClick={() => {
            onLaunch?.();
            setIsModalOpen(true);
          }}
          aphroditeStyles={launchLinkStyles}
        >
          {launchLinkText}
        </Anchor>
      ) : (
        <Button
          key="button"
          type="secondary"
          color="cloudBurst"
          onClick={() => {
            onLaunch?.();
            setIsModalOpen(true);
          }}
          label="Watch video"
        >
          <PlayIcon size={BUTTON_ICON_SIZE} />
          <span style={{ marginLeft: BUTTON_ICON_GUTTER }}>Watch video</span>
        </Button>
      )}
      <Modal
        width={768}
        key="modal"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        showCloseButton={false}
        closeOnOverlayTap
      >
        <VideoEmbed
          id={id}
          videoHostingService={videoHostingService}
          aspectRatio={aspectRatio}
          autoPlay
        />
      </Modal>
    </>
  );
}

export default VideoLauncher;
