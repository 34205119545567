import { trim } from 'ramda';

import type { Meta } from 'ms-components/math/ProbabilityTree/utils';
import genUniqueId from 'ms-utils/id-generator';
import type { Tree } from 'ms-utils/tree';
import { unwrap } from 'ms-utils/typescript-utils';

/**
 * Recursively maps a probability tree as an array using the visit function.
 *
 * @param {Array} node - A probability tree as an array to map.
 * @param {Function} visit - Function to map each node with.
 * @param {Number} index - Current node's index.
 * @return {*} - Result of the visit function.
 */
function mapArrayDepthFirst(node: Array<any>, visit: Function): any {
  return visit([
    node[0],
    node[1].map((child: Array<any>): any => mapArrayDepthFirst(child, visit)),
  ]);
}

/**
 * Takes in node data and splits the label from the probabilty (if any).
 *
 * @private Private function exported for testing
 * @param {String} nodeData: Data related to a given node.
 * @return {ParsedNodeData} returns: Map containing the probability and label for the node.
 */
export function deserializeProbabilityMeta(nodeData: string): Meta {
  const probabilityRegex = /\(([^()]*)\)/g;
  let probabilityMatch = probabilityRegex.exec(nodeData);
  let probabilityRegexResult = '';
  let probability = '';
  while (probabilityMatch) {
    probabilityRegexResult = probabilityMatch[0];
    probability = unwrap(probabilityMatch[1]);
    probabilityMatch = probabilityRegex.exec(nodeData);
  }

  const labelMatch = nodeData.match(/(.*)\(.*\)/);
  const labelRegexResult = labelMatch ? unwrap(labelMatch[1]) : '';
  const label = probabilityRegexResult ? labelRegexResult : nodeData;
  return {
    probability,
    label,
    id: genUniqueId(),
  };
}

/**
 * Takes a probability tree encoded as a string and produces a Tree
 * representation of the probability tree.
 *
 * @param {String} probabilityTreeString - A probabilty tree as a probability tree as a string.
 * @return {Tree} returns - A probability tree as a Tree.
 */
export function deserializeProbabilityTree(
  probabilityTreeString: string,
): Tree<Meta> {
  const probabilityTree = JSON.parse(probabilityTreeString);

  return mapArrayDepthFirst(
    probabilityTree,
    (node: Array<any>): Tree<Meta> => ({
      meta: deserializeProbabilityMeta(node[0]),
      children: node[1],
    }),
  );
}

/**
 * Takes a probability tree as an immutable map returns a probability tree as a string.
 *
 * @param {Tree} probabilityTreeMap The root of the probability tree.
 * @return {Array} The array serialization format for the probability tree.
 */
export function serializeProbabilityTree(
  probabilityTreeMap: Tree<Meta>,
): [string, Array<any>] {
  let label = trim(probabilityTreeMap.meta.label || '');

  if (probabilityTreeMap.meta.probability) {
    const formattedProbability = `(${probabilityTreeMap.meta.probability})`;
    label = label.concat(formattedProbability);
  }

  return [label, probabilityTreeMap.children.map(serializeProbabilityTree)];
}
