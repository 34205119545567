/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type useCreateSelfDirectedTaskMutationVariables = {
    token: string;
    recommendationCategory?: SubstrandSubtopicRecommendationCategory | null | undefined;
};
export type useCreateSelfDirectedTaskMutationResponse = {
    readonly createWorkoutFromToken: {
        readonly workout: {
            readonly id: string;
        } | null;
    };
};
export type useCreateSelfDirectedTaskMutation = {
    readonly response: useCreateSelfDirectedTaskMutationResponse;
    readonly variables: useCreateSelfDirectedTaskMutationVariables;
};



/*
mutation useCreateSelfDirectedTaskMutation(
  $token: ID!
  $recommendationCategory: SubstrandSubtopicRecommendationCategory
) {
  createWorkoutFromToken(token: $token, recommendationCategory: $recommendationCategory) {
    workout {
      __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recommendationCategory"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = [
  {
    "kind": "Variable",
    "name": "recommendationCategory",
    "variableName": "recommendationCategory"
  },
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateSelfDirectedTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "WorkoutCreationResult",
        "kind": "LinkedField",
        "name": "createWorkoutFromToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "workout",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useCreateSelfDirectedTaskMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "WorkoutCreationResult",
        "kind": "LinkedField",
        "name": "createWorkoutFromToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "workout",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03251629965b58d55e1f64e398353ba9",
    "id": null,
    "metadata": {},
    "name": "useCreateSelfDirectedTaskMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateSelfDirectedTaskMutation(\n  $token: ID!\n  $recommendationCategory: SubstrandSubtopicRecommendationCategory\n) {\n  createWorkoutFromToken(token: $token, recommendationCategory: $recommendationCategory) {\n    workout {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e6c897991da52f6c7488a6aa70aa33c';
export default node;
