import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M10,17 C13.8659932,17 17,13.8659932 17,10 C17,6.13400675 13.8659932,3 10,3 C6.13400675,3 3,6.13400675 3,10 C3,13.8659932 6.13400675,17 10,17 Z M12.1534547,6.46768332 C12.4474451,6.00014926 13.0647826,5.85946431 13.5323167,6.1534547 C13.9998507,6.44744508 14.1405357,7.06478262 13.8465453,7.53231668 L10.0736815,13.5323167 C9.69716295,14.1310957 8.83500138,14.160402 8.41869184,13.5885726 L6.1915556,10.5294495 C5.86649595,10.082958 5.96493593,9.45749223 6.41142745,9.13243259 C6.85791896,8.80737294 7.48338476,8.90581293 7.8084444,9.35230444 L9.16655498,11.2177616 L12.1534547,6.46768332 Z" />
);

const CheckMarkOutlined = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CheckMarkOutlined.displayName = 'CheckMarkOutlined';

export default CheckMarkOutlined;
