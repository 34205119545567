/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaskDetail_assignedEntities = {
    readonly assignedStudents: ReadonlyArray<{
        readonly id: string;
        readonly classes: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                };
            }>;
        };
        readonly user: {
            readonly firstName: string;
            readonly lastName: string;
            readonly avatar: string;
        };
    }>;
    readonly assignedClasses: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
    }>;
};
export type TaskDetail_assignedEntities$data = TaskDetail_assignedEntities;
export type TaskDetail_assignedEntities$key = {
    readonly " $data"?: TaskDetail_assignedEntities$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskDetail_assignedEntities">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "TaskDetail_assignedEntities",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Student",
      "kind": "LinkedField",
      "name": "assignedStudents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ClassConnection",
          "kind": "LinkedField",
          "name": "classes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClassEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Class",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Class",
      "kind": "LinkedField",
      "name": "assignedClasses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
})();
(node as any).hash = '87d08f052dcded7e3d43f342b644564c';
export default node;
