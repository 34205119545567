import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  useResetStudentTemporaryPasswordMutationVariables,
  useResetStudentTemporaryPasswordMutationResponse,
} from './__generated__/useResetStudentTemporaryPasswordMutation.graphql';

const mutation = graphql`
  mutation useResetStudentTemporaryPasswordMutation($studentId: ID!) {
    resetStudentTemporaryPassword(studentId: $studentId) {
      student {
        id
        temporaryPassword
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useResetStudentTemporaryPassword({
  studentId,
}: useResetStudentTemporaryPasswordMutationVariables): UseMutationResponse<useResetStudentTemporaryPasswordMutationResponse> {
  return useMutation({
    mutation,
    variables: {
      studentId,
    },
  });
}
