import { css, cx } from '@emotion/css';
import { StyleSheet } from 'aphrodite';
import { useState, createContext, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import Icons from 'ms-components/icons';
import { borderRadiusUI } from 'ms-styles/base';
import Button, { type Props as ButtonProps } from 'ms-ui-primitives/Button';
import Input, { type Props as InputProps } from 'ms-ui-primitives/Input';
import { noop } from 'ms-utils/misc';

import { Panel } from './primitives';

const DebugPanelContext = createContext({
  isExpanded: false,
  expand: noop,
  collapse: noop,
});

export function DebugPanel({
  children,
  renderToggler,
}: {
  children: React.ReactNode;
  renderToggler?: (expand: VoidFunction) => React.ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const expand = () => setIsExpanded(true);
  const collapse = () => setIsExpanded(false);
  const contextValue = useMemo(
    () => ({ isExpanded, expand, collapse }),
    [isExpanded],
  );
  return (
    <DebugPanelContext.Provider value={contextValue}>
      {createPortal(
        <Panel className={styles.debugPanel} aria-expanded={isExpanded}>
          {isExpanded
            ? children
            : renderToggler?.(expand) ?? (
                <DebugPanelToggler>
                  <Icons.SettingsCog />
                </DebugPanelToggler>
              )}
        </Panel>,
        document.body,
      )}
    </DebugPanelContext.Provider>
  );
}

export function DebugPanelCollapse(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { collapse } = useContext(DebugPanelContext);
  return (
    <button
      className={cx(styles.linkButton, styles.debugPanelCollapse)}
      onClick={collapse}
      {...props}
    />
  );
}

export function DebugPanelToggler(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
) {
  const { expand } = useContext(DebugPanelContext);
  return (
    <button
      className={cx(styles.linkButton, styles.debugPanelToggler)}
      onClick={expand}
      {...props}
    />
  );
}

export function PanelButton(props: ButtonProps) {
  return (
    <Button
      type="primary"
      size="small"
      styles={{ borderRadius: borderRadiusUI }}
      {...props}
    />
  );
}

export function PanelInput(props: InputProps) {
  return <Input aphroditeStyles={[aphroditeStyles.panelInput]} {...props} />;
}

const styles = {
  debugPanel: css({
    position: 'fixed',
    right: 12,
    top: 12,
    zIndex: 9999,

    '&[aria-expanded="true"]': {
      width: 240,
    },
  }),
  debugPanelCollapse: css({
    fontSize: 14,
    height: 32,
    lineHeight: '16px',
    margin: -8,
    marginLeft: 'auto',
    padding: 8,
    width: 32,

    '::before': {
      content: '"—"',
    },
  }),
  debugPanelToggler: css({
    display: 'flex',
    margin: -8,
    padding: 8,
  }),
  linkButton: css({
    backgroundColor: 'transparent',
    border: 0,
    color: 'inherit',
    cursor: 'pointer',
    font: 'inherit',
    padding: 0,
  }),
} as const;

const aphroditeStyles = StyleSheet.create({
  panelInput: {
    fontSize: 14,
    height: 32,
  },
});

export * from './primitives';
