/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CheckInSessionMathExpressionQuestion = {
    readonly id: string;
    readonly sanaItemId: string;
    readonly transpiledText: string;
    readonly __typename: "MathExpressionQuestion";
};
export type CheckInSessionMathExpressionQuestion$data = CheckInSessionMathExpressionQuestion;
export type CheckInSessionMathExpressionQuestion$key = {
    readonly " $data"?: CheckInSessionMathExpressionQuestion$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInSessionMathExpressionQuestion">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "CheckInSessionMathExpressionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sanaItemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transpiledText",
      "storageKey": null
    }
  ],
  "type": "MathExpressionQuestion",
  "abstractKey": null
};
(node as any).hash = '6ccfb99f3f36eb7053c8647e78dd06ab';
export default node;
