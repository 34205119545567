// !!! These MUST be in sync with the tab names in `msproblem/interface/textbooks/sitemap.py` !!!
import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { sessionStorageDb } from 'ms-utils/localStorageDb';
import { contentBrowsingEmptyViewPath } from 'ms-utils/urls';

export const SUBTOPIC_TAB_QUERY_PARAM_NAME = 'subtopicTab';
export const LEGACY_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME = 'activeTab';
export const CORE_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME =
  'coreTextbookSubtopicActiveTab';

export const SUBTOPIC_TABS = [
  'lesson',
  'overview',
  'engageActivity',
  'interactive',
  'worksheet',
] as const;

export type SubtopicTab = (typeof SUBTOPIC_TABS)[number];
export const DEFAULT_SUBTOPIC_TAB: SubtopicTab = 'overview';
export const MTS_DEFAULT_SUBTOPIC_TAB: SubtopicTab = 'interactive';

export const SUBTOPIC_LESSON_TABS = ['content', 'teacherGuide'] as const;
export type SubtopicLessonTab = (typeof SUBTOPIC_LESSON_TABS)[number];
export const DEFAULT_SUBTOPIC_LESSON_TAB = 'content';

export const LEGACY_TEXTBOOK_SUBTOPIC_ACTIVE_TAB = [
  'interactive',
  'worksheet',
  'theory',
  'teacherNotes',
  'engageActivity',
] as const;

export type LegacyTextbookSubtopicActiveTab =
  (typeof LEGACY_TEXTBOOK_SUBTOPIC_ACTIVE_TAB)[number];

export const CORE_TEXTBOOK_SUBTOPIC_ACTIVE_TAB = [
  'solidifyLesson',
  'subtopicOverview',
  'engageActivity',
  'interactive',
  'worksheet',
] as const;

export type CoreTextbookSubtopicActiveTab =
  (typeof CORE_TEXTBOOK_SUBTOPIC_ACTIVE_TAB)[number];

export const validateCoreTextbookSubtopicActiveTab = (
  value: CoreTextbookSubtopicActiveTab | string | null | undefined,
): value is CoreTextbookSubtopicActiveTab => {
  return CORE_TEXTBOOK_SUBTOPIC_ACTIVE_TAB.includes(value as any);
};

export const validateLegacyTextbookSubtopicActiveTab = (
  value: LegacyTextbookSubtopicActiveTab | string | null | undefined,
): value is LegacyTextbookSubtopicActiveTab => {
  return LEGACY_TEXTBOOK_SUBTOPIC_ACTIVE_TAB.includes(value as any);
};

export type QueryParamState = {
  subtopicTab: SubtopicTab;
};

export type LegacyTextbookQueryParamState = {
  activeTab: LegacyTextbookSubtopicActiveTab;
};

const LAST_ACTIVE_PATH_KEY = 'textbookLastActivePath';

function getLastActivePath(syllabusId: string): {
  syllabusId: string;
  path: string;
} | null | void {
  const lastActivePath = sessionStorageDb.get(LAST_ACTIVE_PATH_KEY);
  if (lastActivePath != null && lastActivePath.syllabusId === syllabusId) {
    return lastActivePath;
  }
  return null;
}

// SAFETY: This persists paths to session storage. Risks that this may become out of sync
// with our own routing are identical to users keeping bookmarks of textbook pages.
// All considerations with migrating routes apply to session storage as much as user bookmarking.
// We will generally have a redirector / catch-all route if we decide to change routes.
function useSetLastActivePath() {
  return useCallback((syllabusId: string, path: string | null) => {
    const existing = getLastActivePath(syllabusId);
    if (!(existing && existing.path === path)) {
      sessionStorageDb.set(LAST_ACTIVE_PATH_KEY, {
        syllabusId,
        path,
      });
    }
  }, []);
}

/**
 * If a user has navigated away from the textbook, when they return make sure they
 * are sent back to the last active subtopic or topic.
 *
 * This component will synchronize changes with the session store as well as emit
 * redirects when the user returns to the textbook.
 */
export function LastActiveTextbookContentRedirector() {
  return (
    <Switch>
      <Route
        path={contentBrowsingEmptyViewPath}
        exact
        render={({
          match: {
            params: { syllabusId },
          },
        }) => <RedirectToLastActivePathInner syllabusId={syllabusId} />}
      />
      <Route
        path={contentBrowsingEmptyViewPath}
        render={({
          location: { pathname },
          match: {
            params: { syllabusId },
          },
        }) => (
          <SynchronizeLastActivePath
            pathname={pathname}
            syllabusId={syllabusId}
          />
        )}
      />
    </Switch>
  );
}

function SynchronizeLastActivePath({
  pathname,
  syllabusId,
}: {
  pathname: string | null;
  syllabusId: string | undefined;
}) {
  const setlastActivePath = useSetLastActivePath();

  useEffect(() => {
    if (syllabusId !== undefined) setlastActivePath(syllabusId, pathname);
  }, [syllabusId, setlastActivePath, pathname]);
  return null;
}

function RedirectToLastActivePathInner({
  syllabusId,
}: {
  syllabusId: string | undefined;
}) {
  if (syllabusId === undefined) return null;
  const lastActivePath = getLastActivePath(syllabusId);

  if (lastActivePath != null) {
    if (lastActivePath.path != null) {
      return <Redirect to={lastActivePath.path} />;
    }
  }

  return null;
}
