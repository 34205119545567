import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import {
  FeedbackComponent,
  FeedbackWrapper,
} from 'ms-components/Feedback/FeedbackComponent';
import PageNotFoundView from 'ms-components/PageNotFoundView';
import { Eye } from 'ms-components/icons/Eye';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  getGeneralMaterials,
  getProductVisionId,
  textbookMaterials,
} from 'ms-pages/Textbooks/components/MaterialsGuideView/CoreTextbookMappings';
import { breakPoints, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Stack from 'ms-ui-primitives/Stack';
import WistiaVideo from 'ms-ui-primitives/VideoEmbed/WistiaVideo';
import { styled } from 'ms-utils/emotion';

import type { MaterialsGuideViewQuery } from './__generated__/MaterialsGuideViewQuery.graphql';
import DetailHeader from '../DetailHeader';

const MainWrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
  paddingBottom: 12 * BASE_UNIT,
});
const Root = styled({
  background: colors.white,
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const ContentWrapper = styled({
  marginTop: 6 * BASE_UNIT,
});
const ContentHeading = styled({
  flexDirection: 'column',
  display: 'flex',
  paddingTop: 6 * BASE_UNIT,
  paddingBottom: 6 * BASE_UNIT,
  borderBottom: '2px solid #E5E6E8',
});
export const ContentTitle = styled({
  color: colors.cloudBurst,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: 16,
  lineHeight: '22px',
});
const VideoWrapper = styled({
  width: '100%',
  minWidth: 680,
  maxWidth: 380,
  margin: '0 auto',
});
const GuideContainer = styled({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: 6 * BASE_UNIT,
});
const GuideBox = styled(
  {
    flexBasis: '33.333333%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 200,
    textDecoration: 'none',
    filter: 'drop-shadow(0px 1px 7px rgba(33, 55, 77, 0.12))',
    marginBottom: 30,
  },
  'a',
);
const GuideImage = styled({
  backgroundSize: 'cover',
  height: 120,
  width: 220,
  backgroundColor: 'white',
  borderRadius: '8px 8px 0px 0px',
});
const GuideDetails = styled({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: 14,
  width: 220,
  height: 80,
  backgroundColor: colors.almond50,
  color: colors.cloudBurst,
  borderRadius: '0px 0px 8px 8px',
});
const GuideTitle = styled({
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: 14,
  lineHeight: '18px',
  width: '80%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
const GuildIcon = styled({
  color: colors.cloudBurst,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

const query = graphql`
  query MaterialsGuideViewQuery($syllabusId: ID!) {
    syllabus(id: $syllabusId) {
      id
      title
      thumbnailImageUrl
      coverImageUrl
    }
  }
`;
type Props = {
  syllabusId: string;
};
export default function MaterialsGuideView({ syllabusId }: Props) {
  const { error, props } = useQuery<MaterialsGuideViewQuery>(query, {
    syllabusId,
  });

  if (error != null) throw error;
  if (props == null) return <MinorSpinner />;
  const { syllabus } = props;
  if (syllabus == null) return <PageNotFoundView />;
  const coverImageUrl = syllabus.coverImageUrl;
  const textbookGuides = textbookMaterials[syllabusId.toString()] ?? [];
  const generalGuides = getGeneralMaterials(syllabusId.toString());
  const productVisionId = getProductVisionId(syllabusId.toString());
  return (
    <Root>
      <DetailHeader
        coverImageUrl={coverImageUrl}
        title="Materials Guide"
        subtitleContent={
          <BodyM color="grey90" bold>
            {syllabus.title}
          </BodyM>
        }
        asideContent={null}
        tabs={null}
      />

      <MainWrapper>
        <ContentWrapper>
          {productVisionId && (
            <ContentHeading>
              <ContentTitle>Product Vision Video</ContentTitle>
              <VideoWrapper>
                <WistiaVideo
                  id={productVisionId}
                  aspectRatio="16:9"
                  useIframeEmbed={false}
                />
              </VideoWrapper>
            </ContentHeading>
          )}
          {textbookGuides.length > 0 && (
            <>
              <ContentHeading>
                <ContentTitle>Textbook Guides</ContentTitle>
                <GuideContainer>
                  {textbookGuides.map(guide => (
                    <GuideBox
                      key={guide.link}
                      href={guide.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GuideImage
                        style={{
                          backgroundImage: guide.thumbnailUrl
                            ? `url("${guide.thumbnailUrl}")`
                            : null,
                        }}
                      />
                      <GuideDetails>
                        <GuideTitle>{guide.title}</GuideTitle>
                        <GuildIcon>
                          <Eye size={16} />
                        </GuildIcon>
                      </GuideDetails>
                    </GuideBox>
                  ))}
                </GuideContainer>
              </ContentHeading>
              <ContentHeading>
                <ContentTitle>General Guides</ContentTitle>
                <GuideContainer>
                  {generalGuides.map(guide => (
                    <GuideBox
                      key={guide.link}
                      href={guide.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GuideImage
                        style={{
                          backgroundImage: guide.thumbnailUrl
                            ? `url("${guide.thumbnailUrl}")`
                            : null,
                        }}
                      />
                      <GuideDetails>
                        <GuideTitle>{guide.title}</GuideTitle>
                        <GuildIcon>
                          <Eye size={16} />
                        </GuildIcon>
                      </GuideDetails>
                    </GuideBox>
                  ))}
                </GuideContainer>
              </ContentHeading>
            </>
          )}
        </ContentWrapper>

        <Stack.Spacer.V height={40} />

        <FeedbackWrapper>
          <FeedbackComponent feedbackType="GENERAL" />
        </FeedbackWrapper>
      </MainWrapper>
    </Root>
  );
}
