/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateOrEditFolderModalCreateFolderMutationVariables = {
    schoolId: string;
    title: string;
    description: string;
    taskTemplateIds: Array<string>;
    isDistrictShared?: boolean | null | undefined;
    taskTemplateGroupId?: string | null | undefined;
};
export type CreateOrEditFolderModalCreateFolderMutationResponse = {
    readonly createSchoolTaskTemplateGroup: {
        readonly taskTemplateGroup: {
            readonly id: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly message: string;
        }>;
    };
};
export type CreateOrEditFolderModalCreateFolderMutation = {
    readonly response: CreateOrEditFolderModalCreateFolderMutationResponse;
    readonly variables: CreateOrEditFolderModalCreateFolderMutationVariables;
};



/*
mutation CreateOrEditFolderModalCreateFolderMutation(
  $schoolId: ID!
  $title: String!
  $description: String!
  $taskTemplateIds: [ID!]!
  $isDistrictShared: Boolean
  $taskTemplateGroupId: ID
) {
  createSchoolTaskTemplateGroup(schoolId: $schoolId, title: $title, description: $description, taskTemplateIds: $taskTemplateIds, isDistrictShared: $isDistrictShared, taskTemplateGroupId: $taskTemplateGroupId) {
    taskTemplateGroup {
      id
    }
    errors {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDistrictShared"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskTemplateGroupId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskTemplateIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "isDistrictShared",
        "variableName": "isDistrictShared"
      },
      {
        "kind": "Variable",
        "name": "schoolId",
        "variableName": "schoolId"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateGroupId",
        "variableName": "taskTemplateGroupId"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateIds",
        "variableName": "taskTemplateIds"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "CreateSchoolTaskTemplateGroupResult",
    "kind": "LinkedField",
    "name": "createSchoolTaskTemplateGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplateGroup",
        "kind": "LinkedField",
        "name": "taskTemplateGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrEditFolderModalCreateFolderMutation",
    "selections": (v6/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateOrEditFolderModalCreateFolderMutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "1bd93570c09546ec7daa7d1a268cbdd1",
    "id": null,
    "metadata": {},
    "name": "CreateOrEditFolderModalCreateFolderMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrEditFolderModalCreateFolderMutation(\n  $schoolId: ID!\n  $title: String!\n  $description: String!\n  $taskTemplateIds: [ID!]!\n  $isDistrictShared: Boolean\n  $taskTemplateGroupId: ID\n) {\n  createSchoolTaskTemplateGroup(schoolId: $schoolId, title: $title, description: $description, taskTemplateIds: $taskTemplateIds, isDistrictShared: $isDistrictShared, taskTemplateGroupId: $taskTemplateGroupId) {\n    taskTemplateGroup {\n      id\n    }\n    errors {\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '70b0648b463c1e3d1bc42a59d4ed7fc2';
export default node;
