/* eslint-disable jsx-a11y/anchor-is-valid */
import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { AppLauncherButton } from 'ms-components/AppLauncher';
import ChevronLeftIcon from 'ms-components/icons/ChevronLeft';
import HamburgerIcon from 'ms-components/icons/Hamburger';
import Logo from 'ms-components/logos/Logo';
import { usePrintMode } from 'ms-helpers/PrintMode';
import {
  shadowColors,
  transition,
  lineHeight,
  fontSize,
  zIndex,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { HEADER_SIZE } from 'ms-styles/theme/Numero';
import { noop } from 'ms-utils/misc';

type Props = {
  primaryActionType?: 'menu' | 'back' | 'appLauncher' | undefined;
  onPrimaryActionClick?: (() => void) | undefined;
  onLogoClick: () => void;
  overlay?: boolean;
  sticky?: boolean;
  title?: string | undefined;
  rightContent?: ReactNode | undefined;
  marginTop?: number | undefined;
};

export { HEADER_SIZE };
const GUTTER_LATERAL = 20;
const ICON_SIZE = 25;

const styles = StyleSheet.create({
  header: {
    height: HEADER_SIZE,
    fill: colors.mako,
    flexShrink: 0,
  },
  overlay: {
    height: 0,
    color: colors.white,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: HEADER_SIZE,
  },
  sticky: {
    transition: `background-color ${transition}, box-shadow ${transition}`,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: zIndex.header,
  },
  isScrolled: {
    backgroundColor: 'rgba(255, 255, 255, 0.96)',
    boxShadow: `0px 2px 26px ${shadowColors.header}`,
    color: colors.mako,
  },
  primaryActionBox: {
    cursor: 'pointer',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    paddingLeft: GUTTER_LATERAL,
    paddingRight: GUTTER_LATERAL,
    fontSize: ICON_SIZE,
  },
  logoBox: {
    display: 'flex',
    alignSelf: 'stretch',
    lineHeight: 0, // remove the space below the inline SVG element
  },
  logoBoxDefault: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  logoLink: {
    cursor: 'pointer',
    display: 'block',
    outline: 'none',
    marginTop: -2, // to visually center the icon
  },
  titleBox: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: lineHeight.heading,
    fontSize: fontSize.headerTitle,
    paddingLeft: GUTTER_LATERAL,
    paddingRight: GUTTER_LATERAL,
  },
  rightContent: {
    marginLeft: 'auto',
    marginRight: GUTTER_LATERAL,
  },
});

export default function Header({
  primaryActionType,
  onPrimaryActionClick,
  onLogoClick,
  overlay = false,
  sticky = true,
  title,
  rightContent = null,
  marginTop,
}: Props) {
  const [isAtTop, setIsAtTop] = useState(true);
  const printMode = usePrintMode();

  useEffect(() => {
    if (!sticky) {
      return;
    }

    function handleScroll() {
      if (sticky) {
        setIsAtTop(window.scrollY === 0);
      }
    }

    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sticky]);

  if (printMode) {
    return null;
  }

  return (
    <div className={css(styles.header, overlay && styles.overlay)}>
      <div
        className={css(
          styles.wrapper,
          sticky && styles.sticky,
          sticky && !isAtTop && styles.isScrolled,
        )}
        style={
          sticky != null && marginTop != null
            ? {
                marginTop,
              }
            : undefined
        }
      >
        <a
          className={css(styles.primaryActionBox)}
          onClick={onPrimaryActionClick}
          role="link"
          tabIndex={0}
        >
          {primaryActionType === 'menu' && <HamburgerIcon />}
          {primaryActionType === 'back' && <ChevronLeftIcon />}
          {primaryActionType === 'appLauncher' && (
            <AppLauncherButton onClick={noop} />
          )}
        </a>
        <div className={css(styles.logoBox, styles.logoBoxDefault)}>
          <a
            role="link"
            tabIndex={0}
            onClick={onLogoClick}
            className={css(styles.logoLink)}
          >
            <Logo />
          </a>
        </div>
        {title && <div className={css(styles.titleBox)}>{title}</div>}
        {rightContent != null && (
          <div className={css(styles.rightContent)}>{rightContent}</div>
        )}
      </div>
    </div>
  );
}
