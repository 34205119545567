import styled from '@emotion/styled';
import { uniqBy } from 'ramda';
import { useState, useCallback, useMemo } from 'react';

import { BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import type {
  StudentSelectionPayload,
  ClassSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import Stack from 'ms-ui-primitives/Stack';

import { excludedStudents } from '.';
import type { EditTaskFlyout_assignments } from './__generated__/EditTaskFlyout_assignments.graphql';

type PartialClasses = EditTaskFlyout_assignments['partiallyAssignedClasses'];
export default function useConfirmRemovedStudents({
  originalAssignedStudents,
  originalAssignedClasses,
  originalIndividuallyAssignedStudents,
  originalPartialClasses,
  currentAssignedClasses,
  currentIndividualAssignedStudents,
  currentExcludedStudents,
  setIndividualAssignedStudents,
  setExcludedStudents,
  setAssignedClasses,
}: {
  originalAssignedStudents: readonly StudentSelectionPayload[];
  originalAssignedClasses: readonly ClassSelectionPayload[];
  originalIndividuallyAssignedStudents: readonly StudentSelectionPayload[];
  originalPartialClasses: PartialClasses;
  currentAssignedClasses: readonly ClassSelectionPayload[];
  currentIndividualAssignedStudents: readonly StudentSelectionPayload[];
  currentExcludedStudents: readonly StudentSelectionPayload[];
  setExcludedStudents: (
    excludedStudents: readonly StudentSelectionPayload[],
  ) => void;
  setIndividualAssignedStudents: (
    individualAssignedStudents: readonly StudentSelectionPayload[],
  ) => void;
  setAssignedClasses: (
    assignedClasses: readonly ClassSelectionPayload[],
  ) => void;
}) {
  // The students that were removed from the task can be found by comparing the
  // original task'a assigned students (computed by individually assigned or belonging to a class and not excluded) with the computed assigned students
  // of the current state
  const studentsThatWereRemoved: readonly StudentSelectionPayload[] =
    useMemo(() => {
      // the assigned students with the current state
      // We need to parse all the classes' students, remove the excluded students and add the individually assigned students
      const currentlyAssignedStudents = [
        ...currentIndividualAssignedStudents,
        ...currentAssignedClasses.reduce<StudentSelectionPayload[]>(
          (acc, klass) => [
            ...acc,
            ...(klass.students ?? { edges: [] }).edges
              .map(({ node }) => ({
                id: node.id,
                firstName: node.user.firstName,
                lastName: node.user.lastName,
              }))
              .filter(
                student =>
                  !currentExcludedStudents.some(
                    excludedStudent => excludedStudent.id === student.id,
                  ),
              ),
          ],
          [],
        ),
      ];
      const removed = originalAssignedStudents.filter(
        (student: StudentSelectionPayload) =>
          !currentlyAssignedStudents.some(
            currentlyAssignedStudent =>
              currentlyAssignedStudent.id === student.id,
          ),
      );
      return uniqBy(s => s.id, removed);
    }, [
      originalAssignedStudents,
      currentAssignedClasses,
      currentExcludedStudents,
      currentIndividualAssignedStudents,
    ]);
  const resetTaskAssignment = useCallback(() => {
    setIndividualAssignedStudents(originalIndividuallyAssignedStudents);
    setExcludedStudents(excludedStudents(originalPartialClasses));
    setAssignedClasses(originalAssignedClasses);
  }, [
    originalAssignedClasses,
    originalIndividuallyAssignedStudents,
    originalPartialClasses,
    setAssignedClasses,
    setIndividualAssignedStudents,
    setExcludedStudents,
  ]);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const openConfirm = useCallback(() => {
    setConfirmOpen(true);
  }, []);
  const closeConfirm = useCallback(() => {
    setConfirmOpen(false);
  }, []);
  return {
    studentsThatWereRemoved,
    resetTaskAssignment,
    isConfirmOpen,
    openConfirm,
    closeConfirm,
  };
}
const RemovedStudentsList = styled.ul({
  listStyle: 'inside',
  maxHeight: 320,
  overflowY: 'auto',
  padding: 0,
  paddingLeft: 16,
});
export function ConfirmRemovedStudentsModal({
  studentsThatWereRemoved,
  isConfirmOpen,
  closeConfirm,
  resetTaskAssignment,
  editTask,
}: {
  studentsThatWereRemoved: readonly StudentSelectionPayload[];
  isConfirmOpen: boolean;
  closeConfirm: () => void;
  resetTaskAssignment: () => void;
  editTask: () => void;
}) {
  return (
    <Modal
      showCloseButton={false}
      closeOnOverlayTap={false}
      width={480}
      isOpen={isConfirmOpen}
      onClose={() => {}}
      isOverflowHidden
    >
      <div css={{ padding: 32, paddingTop: 40, display: 'flex' }}>
        <Stack.V>
          <HeadingM>Are you sure?</HeadingM>
          <Stack.Spacer.V height={8} />
          <BodyM>
            Are you sure you want to remove the following student(s) from the
            task? Removing these students will also remove their work history
            for this task.
          </BodyM>
          <RemovedStudentsList>
            {studentsThatWereRemoved.map(student => (
              <li key={student.id}>
                <BodyM inline bold>
                  {student.lastName}, {student.firstName}
                </BodyM>
              </li>
            ))}
          </RemovedStudentsList>
          <Stack.Spacer.V height={24} />
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="lanternSmall"
              onClick={() => {
                closeConfirm();
                resetTaskAssignment();
              }}
            >
              Reset changes
            </Button>
            <Button
              size="lanternSmall"
              isRound
              type="primary"
              onClick={() => {
                closeConfirm();
                editTask();
              }}
            >
              Save changes
            </Button>
          </div>
        </Stack.V>
      </div>
    </Modal>
  );
}
