import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M16.1317569,2.50386106 C16.4057668,2.02434362 17.0166215,1.85774689 17.4961389,2.13175686 C17.9756564,2.40576683 18.1422531,3.01662149 17.8682431,3.49613894 L9.86824314,17.4961389 C9.52514735,18.0965566 8.69078625,18.1758414 8.2407434,17.6507914 L2.2407434,10.6507914 C1.88132125,10.2314655 1.92988278,9.60016555 2.34920863,9.2407434 C2.76853447,8.88132125 3.39983445,8.92988278 3.7592566,9.34920863 L8.83578045,15.2718198 L16.1317569,2.50386106 Z" />
);

const CheckMark = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CheckMark.displayName = 'CheckMark';

export default CheckMark;
