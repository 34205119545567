/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type UserStatusFilterInput = {
    curriculumNodeIds: Array<string>;
};
export type StudentSkillsMapSubstrandSkillsQueryVariables = {
    filters: Array<UserStatusFilterInput>;
    substrandId: string;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type StudentSkillsMapSubstrandSkillsQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly userStatuses: ReadonlyArray<{
                readonly trueProficiency: number | null;
                readonly userStatusFilter: {
                    readonly curriculumNodeIds: ReadonlyArray<string>;
                };
            }> | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
        readonly substrand: {
            readonly studentSubtopicRecommendations: ReadonlyArray<{
                readonly category: SubstrandSubtopicRecommendationCategory;
                readonly outcomes: ReadonlyArray<{
                    readonly id: string;
                    readonly code: string;
                }>;
            }>;
        } | null;
    };
};
export type StudentSkillsMapSubstrandSkillsQuery = {
    readonly response: StudentSkillsMapSubstrandSkillsQueryResponse;
    readonly variables: StudentSkillsMapSubstrandSkillsQueryVariables;
};



/*
query StudentSkillsMapSubstrandSkillsQuery(
  $filters: [UserStatusFilterInput!]!
  $substrandId: ID!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
          trueProficiency
          userStatusFilter {
            curriculumNodeIds
          }
        }
      }
      id
    }
    substrand(id: $substrandId) {
      studentSubtopicRecommendations {
        category
        outcomes {
          id
          code
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "substrandId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "growthPeriod",
          "variableName": "growthPeriod"
        },
        {
          "kind": "Variable",
          "name": "previewingWithProblemData",
          "variableName": "previewingWithProblemData"
        }
      ],
      "concreteType": "UserStatus",
      "kind": "LinkedField",
      "name": "userStatuses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trueProficiency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStatusFilter",
          "kind": "LinkedField",
          "name": "userStatusFilter",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "curriculumNodeIds",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "substrandId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternOutcome",
  "kind": "LinkedField",
  "name": "outcomes",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentSkillsMapSubstrandSkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubstrandSubtopicRecommendation",
                "kind": "LinkedField",
                "name": "studentSubtopicRecommendations",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentSkillsMapSubstrandSkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubstrandSubtopicRecommendation",
                "kind": "LinkedField",
                "name": "studentSubtopicRecommendations",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b487f2fea11075f3b3d6844ff714ca3",
    "id": null,
    "metadata": {},
    "name": "StudentSkillsMapSubstrandSkillsQuery",
    "operationKind": "query",
    "text": "query StudentSkillsMapSubstrandSkillsQuery(\n  $filters: [UserStatusFilterInput!]!\n  $substrandId: ID!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n          trueProficiency\n          userStatusFilter {\n            curriculumNodeIds\n          }\n        }\n      }\n      id\n    }\n    substrand(id: $substrandId) {\n      studentSubtopicRecommendations {\n        category\n        outcomes {\n          id\n          code\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9ee07e0c15f4c59cc216b5ef87b3d48d';
export default node;
