// Unlike for the other subtopic tabs switches, this one is using top level subtopic tabs state but refinements were created in `ms-pages/Textbooks/utils/hooks/useTextbookTab` to ensure type safety

import Switch from 'ms-pages/Teacher/components/Switch';
import {
  useTextbookTabsState,
  SUBTOPIC_PRACTICE_TABS,
  type SubtopicPracticeTab,
} from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';

const SUBTOPIC_PRACTICE_TABS_LABELS: Record<SubtopicPracticeTab, string> = {
  worksheet: 'Worksheet',
  interactive: 'Questions',
};

const SWITCH_OPTIONS = SUBTOPIC_PRACTICE_TABS.map(subtab => ({
  label: SUBTOPIC_PRACTICE_TABS_LABELS[subtab],
  value: subtab,
}));

export default function SubtopicPracticeTabSwitch({
  small,
  activeColor,
  backgroundColor,
}: {
  small?: boolean;
  activeColor?: string;
  backgroundColor?: string;
}) {
  const [subtopicPracticeTab, setSubtopicPracticeTab] = useTextbookTabsState();

  if (
    subtopicPracticeTab !== 'interactive' &&
    subtopicPracticeTab !== 'worksheet'
  ) {
    return null;
  }

  return (
    <Switch<SubtopicPracticeTab>
      backgroundColor={backgroundColor}
      activeColor={activeColor}
      width={284}
      small={small}
      options={SWITCH_OPTIONS}
      value={subtopicPracticeTab}
      onChange={setSubtopicPracticeTab}
    />
  );
}
