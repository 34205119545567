import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M2.049 0h6.746c1.13 0 2.049.92 2.049 2.049v6.746c0 1.13-.92 2.049-2.049 2.049H2.05C.919 10.844 0 9.924 0 8.795V2.05C0 .919.92 0 2.049 0zm13.156 0h6.746C23.081 0 24 .92 24 2.049v6.746c0 1.13-.92 2.049-2.049 2.049h-6.746c-1.13 0-2.049-.92-2.049-2.049V2.05c0-1.13.92-2.049 2.049-2.049zm-6.41 13.156H2.05c-1.13 0-2.049.92-2.049 2.049v6.746C0 23.081.92 24 2.049 24h6.746c1.13 0 2.049-.92 2.049-2.049v-6.746c0-1.13-.92-2.049-2.049-2.049zm6.41 0h6.746c1.13 0 2.049.92 2.049 2.049v6.746c0 1.13-.92 2.049-2.049 2.049h-6.746c-1.13 0-2.049-.92-2.049-2.049v-6.746c0-1.13.92-2.049 2.049-2.049z" />
);

const MenuSquares = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={24}
    viewBoxHeight={24}
  >
    {path}
  </Icon>
);

MenuSquares.displayName = 'MenuSquares';

export default MenuSquares;
