import styled from '@emotion/styled';
import { useContext, useMemo, useState } from 'react';
import type { ComponentType } from 'react';

import Layout from 'ms-components/Layout/Layout';
import ChevronRight from 'ms-components/icons/ChevronRight';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import CollapsibleSidebar, {
  SidebarWrapper,
} from 'ms-pages/Teacher/components/CollapsibleSidebar';
import { CUSTOM_TASK_PREVIEW_SCREEN } from 'ms-pages/Teacher/components/CreateTask';
import CreateTaskModal from 'ms-pages/Teacher/components/CreateTaskModal';
import { NAVBAR_BREAKPOINT } from 'ms-pages/Teacher/components/Navbar/SunflowerNavbar/constants';
import { useStudentTextbookContext } from 'ms-pages/Textbooks/StudentTextbook/StudentTextbookContext';
import { StateContext as ProblemsCollectionState } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import LeftSidebar from 'ms-pages/Textbooks/components/LeftSidebar';
import { QuestionsCounter } from 'ms-pages/Textbooks/components/ProblemCollectionSidebar';
import useTeacherSidebarsCollapsiness from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookLayout/useTeacherSidebarsCollapsiness';
import { breakPoints } from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import { HEADER_SIZE } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { HSpacer } from 'ms-ui-primitives/Stack';
import { onPressOrHover } from 'ms-utils/emotion';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

import type { Props as LayoutProps } from '..';

type StudentLayoutProps = LayoutProps & {
  LeftAside: ComponentType;
};

export const ASIDE_WIDTH = 312;

export const ASIDE_PEAK = 24;
const ASIDE_PEAK_MOBILE = 0;
const TOPICS_BUTTON_OFFSET = 16;
const TOPICS_BUTTON_OFF_SCREEN_SHIFT = 15;

export function TeacherTextbookLayout({ children }: LayoutProps) {
  const { isLeftSidebarCollapsed, setLeftSidebarCollapsed } =
    useTeacherSidebarsCollapsiness();

  const {
    featureFlags: { classicUi },
  } = useViewer();

  const { problemsCollection } = useContext(ProblemsCollectionState);

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);

  const overflow = (() => {
    let overflow = 0;
    // no overflow because we don't have a right aside
    if (isLeftSidebarCollapsed) {
      overflow += ASIDE_WIDTH - ASIDE_PEAK;
    }

    return overflow;
  })();

  const translation = (() => {
    if (isLeftSidebarCollapsed) {
      return -(ASIDE_WIDTH - ASIDE_PEAK);
    }
    return 0;
  })();

  const { width: windowWidth } = useWindowSize();

  const isMobileView = windowWidth != null && windowWidth < NAVBAR_BREAKPOINT;
  const { trackStructEvent } = useSnowplow();

  return (
    <Layout direction="row" overflow={overflow} translate={translation}>
      <Layout.Fixed size={classicUi || !isMobileView ? ASIDE_WIDTH : 0}>
        {classicUi ? (
          <CollapsibleSidebar
            isOpen={!isLeftSidebarCollapsed}
            onOpen={() => {
              setLeftSidebarCollapsed(false);
            }}
            onClose={() => {
              setLeftSidebarCollapsed(true);
            }}
          >
            <LeftSidebar />
          </CollapsibleSidebar>
        ) : !classicUi && !isMobileView ? (
          <SidebarWrapper>
            <LeftSidebar />
          </SidebarWrapper>
        ) : null}
      </Layout.Fixed>
      <Layout.Fill>{children}</Layout.Fill>
      {/* Collapsible Right Sidebar's QuestionsCounter is used to show
      the number of questions collected here
      for the new CATFA Modal, this QuestionsCounter is disabled for Sunflower
      just as the right sidebar is disabled for Sunflower too. */}
      {classicUi && (
        <>
          <Layout.Fixed size={0}>
            <QuestionsCounter
              open={false}
              onClick={() => {
                setIsTaskModalOpen(true);
                trackStructEvent({
                  action: 'begin_create_task',
                  category: 'textbook',
                  label: 'CUSTOM_TASK',
                });
              }}
              length={problemsCollection.length}
            />
          </Layout.Fixed>
          {isTaskModalOpen && (
            <CreateTaskModal
              isOpen={isTaskModalOpen}
              onClose={() => setIsTaskModalOpen(false)}
              preselection={CUSTOM_TASK_PREVIEW_SCREEN}
              canCreateOnlyTemplate
              trackingEventOnCreation={{
                category: 'textbook',
                action: 'create_task',
                property: 'CUSTOM_TASK',
              }}
            />
          )}
        </>
      )}
      {/* Collapsible Right Sidebar is disabled for Sunflower for now.*/}
    </Layout>
  );
}

const topicsButtonStyles = {
  border: `2px solid ${colors.ironLight}`,
  ...onPressOrHover({
    borderColor: alternateColors.ironLight,
  }),
};

const TopicsButton = styled.div({
  position: 'absolute',
  top: TOPICS_BUTTON_OFFSET,
  left: 0,
  marginLeft: -TOPICS_BUTTON_OFF_SCREEN_SHIFT,
  zIndex: 1,
});

export function StudentTextbookLayout({
  children,
  Navbar,
  LeftAside,
}: StudentLayoutProps & { Navbar: ComponentType<any> }) {
  const { isSidebarCollapsed, setSidebarCollapsed } =
    useStudentTextbookContext();

  const { width: windowWidth } = useWindowSize();

  // medium breakpoint is used here rather than small to make textbook layout better
  const isMobileView = windowWidth != null && windowWidth < breakPoints.medium;

  const asidePeak = useMemo(
    () => (isMobileView ? ASIDE_PEAK_MOBILE : ASIDE_PEAK),
    [isMobileView],
  );

  const overflow = useMemo(
    () => (isSidebarCollapsed ? ASIDE_WIDTH - asidePeak : 0),
    [asidePeak, isSidebarCollapsed],
  );

  const translate = useMemo(
    () => (isSidebarCollapsed ? -(ASIDE_WIDTH - asidePeak) : 0),
    [asidePeak, isSidebarCollapsed],
  );

  const collapsibleSidebar = (
    <CollapsibleSidebar
      isOpen={!isSidebarCollapsed}
      onOpen={() => {
        setSidebarCollapsed(false);
      }}
      onClose={() => {
        setSidebarCollapsed(true);
      }}
      noControl={isMobileView && isSidebarCollapsed}
    >
      <LeftAside />
    </CollapsibleSidebar>
  );

  return (
    <Layout direction="column">
      <Layout.Fixed size={HEADER_SIZE}>
        <Navbar />
      </Layout.Fixed>

      <Layout.Fill>
        {isMobileView ? (
          <>
            <Layout direction="row" overflow={overflow} translate={translate}>
              <Layout.Fixed size={ASIDE_WIDTH}>
                {collapsibleSidebar}
              </Layout.Fixed>
            </Layout>
            <Layout direction="row">
              <Layout.Fill>
                <TopicsButton>
                  <Button
                    type="primary"
                    onClick={() => setSidebarCollapsed(false)}
                    label="topics-button"
                    color="white"
                    size="regular"
                    styles={topicsButtonStyles}
                  >
                    <HSpacer width={10} />
                    <ChevronRight size={16} color={colors.eggplant} />
                    <HSpacer width={13} />
                    <BodyM color="eggplant" bold>
                      Topics
                    </BodyM>
                    <HSpacer width={5} />
                  </Button>
                </TopicsButton>
                {children}
              </Layout.Fill>
            </Layout>
          </>
        ) : (
          <Layout direction="row" overflow={overflow} translate={translate}>
            <Layout.Fixed size={ASIDE_WIDTH}>{collapsibleSidebar}</Layout.Fixed>
            <Layout.Fill>{children}</Layout.Fill>
          </Layout>
        )}
      </Layout.Fill>
    </Layout>
  );
}
