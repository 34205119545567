import type { Item, Items } from '.';

export const addItem = ({
  items,
  item,
}: {
  items: Items;
  item: Item;
}): Items =>
  items.find(i => i.id === item.id) == null ? [...items, item] : items;

export const removeItem = ({
  items,
  itemId,
}: {
  items: Items;
  itemId: Item['id'];
}): Items => items.filter(i => i.id !== itemId);

export const mapToIds = (items: Items): readonly string[] =>
  items.map(s => s.id);
