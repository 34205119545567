import { useContext } from 'react';

import TextbookIcon from 'ms-components/icons/TextbookTeacher';
import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector, {
  type Items,
} from 'ms-pages/Teacher/components/InputSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';

import { StateContext, UpdatersContext, EMPTY_SYLLABUS_FOCUS } from '../state';

type Props = {
  items: Items;
  errorMessages: ErrorMessages;
  showApplyClassTextbookFocus: boolean;
};

const TextbookFocus = ({
  items,
  errorMessages,
  showApplyClassTextbookFocus = false,
}: Props) => {
  const { syllabusFocus, applyClassSyllabusFocus } = useContext(StateContext);
  const { updateSyllabusFocus, toggleApplyClassSyllabusFocus } =
    useContext(UpdatersContext);

  return (
    <FieldGroup
      isFieldRequired
      title="Focus textbook"
      validationAdvice="One maximum"
      description="If applied to the students of this class, they will see this textbook by default"
    >
      <InputSelector
        items={items}
        selectedItems={
          syllabusFocus.id !== EMPTY_SYLLABUS_FOCUS.id ? [syllabusFocus] : []
        }
        removeSelectedItem={() => updateSyllabusFocus(EMPTY_SYLLABUS_FOCUS)}
        onDropdownSelect={updateSyllabusFocus}
        placeholder="Select from the list"
        icon={<TextbookIcon size={3.5 * BASE_UNIT} />}
        showErrorState={errorMessages.length > 0}
      />
      <FormFieldError messages={errorMessages} />
      {showApplyClassTextbookFocus && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <Checkbox
            label="Apply this textbook focus to all students in this class"
            checked={applyClassSyllabusFocus}
            onChange={() => toggleApplyClassSyllabusFocus()}
          />
        </>
      )}
    </FieldGroup>
  );
};

export default TextbookFocus;
