import { useEffect } from 'react';

export default function HardRedirect(props: { to: string }) {
  useEffect(() => {
    window.location.assign(props.to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
