import { css } from '@emotion/css';
import type { ReactElement } from 'react';
import { Fragment } from 'react';

import JsxContent from '../../../../ms-components/JsxContent';
import keyDownMap from '../../../../ms-utils/keyDownMap';
import { colors, tappable } from '../../styles';
import type { TranspiledJsxContent } from '../../types';

type SelectedOptions = readonly number[];
export { SelectedOptions as MultipleChoiceQuestionAnswer };

type Props = {
  text: TranspiledJsxContent;
  options: readonly TranspiledJsxContent[];
  selectedOptions: SelectedOptions | null; // selected indexes in options list
  numberOfAnswers: number;
  onChange: (selectedOptions: SelectedOptions) => void;
  accessibilityMode: boolean;
};

export default function MultipleChoice({
  text,
  options,
  selectedOptions: selected,
  numberOfAnswers,
  onChange,
  accessibilityMode,
}: Props) {
  const selectedOptions = selected || [];

  const hasMultipleAnswers = numberOfAnswers > 1;

  return (
    <Fragment>
      {text !== '' ? (
        <JsxContent transpiledJsx={text} />
      ) : (
        <ErrorMessage>Question text failed to render</ErrorMessage>
      )}
      <div className={css({ height: 30 })} />

      {options.map((option, i) => {
        const isSelected = selectedOptions.includes(i);
        const onClick = () => {
          if (hasMultipleAnswers) {
            if (isSelected) {
              // can deselect
              onChange(selectedOptions.filter(s => s !== i));
            } else {
              // add to the current selection
              onChange(selectedOptions.concat(i));
            }
          } else {
            // if there is a single answer, only allow one selected at the time
            // and no possibility to deselect
            onChange([i]);
          }
        };
        return (
          <Option
            isSelected={isSelected}
            isTappable
            accessibilityMode={accessibilityMode}
            role="button"
            tabIndex={0}
            onKeyDown={keyDownMap({
              SPACE: [onClick, { stopPropagation: true, preventDefault: true }],
              ENTER: [onClick, { stopPropagation: true }],
            })}
            key={i}
            onClick={onClick}
          >
            {option !== '' ? (
              <JsxContent transpiledJsx={option} />
            ) : (
              <ErrorMessage>Option text failed to render</ErrorMessage>
            )}
          </Option>
        );
      })}
    </Fragment>
  );
}

const OPTION_PADDING = 10;
const BORDER_WIDTH = 2;
const BACKGROUND_COLOR = colors.white;

function Option({
  isSelected,
  isTappable,
  accessibilityMode,
  children,
  ...rest
}: {
  isSelected: boolean;
  isTappable: boolean;
  accessibilityMode: boolean;
  children: ReactElement;
} & JSX.IntrinsicElements['div']) {
  return (
    <div
      {...rest}
      className={css({
        // The minimum width requirement for JSX Content is 260px
        // (e.g. `NumberLine` and `CartesianPlane` components and some images).
        // This style will work for 375px-wide screens.
        // Unfortunately, we will have to come up with something else
        // in the future to support 320px-wide screens (if needed).
        marginLeft: -OPTION_PADDING,
        marginRight: -OPTION_PADDING,
        padding: OPTION_PADDING,
        boxShadow: '0 1px 3px rgba(0,0,0,.3)',
        borderWidth: BORDER_WIDTH,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: isSelected ? colors.eggplant : BACKGROUND_COLOR,
        backgroundColor: BACKGROUND_COLOR,
        transition: 'border-color 0.15s ease',
        marginBottom: 10,
        ...(isTappable && tappable),

        ...(accessibilityMode && {
          ':focus': {
            outline: 'none',
            boxShadow: `0 0 0 2px white, 0 0 0 4px ${colors.cloudBurst}`,
          },
        }),

        'figure > img': {
          maxWidth: '100%',
        },

        [`@media (min-width: 410px)`]: {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 16,
          paddingRight: 16,
        },

        [`@media (min-width: 460px)`]: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      })}
    >
      {children}
    </div>
  );
}

function ErrorMessage({ children }: { children: string }) {
  return <div style={{ color: colors.peachPink }}>{children}</div>;
}
