import { useContext } from 'react';

import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import Separator from 'ms-pages/Teacher/components/Separator';
import {
  StateContext,
  UpdatersContext,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Input from 'ms-ui-primitives/Input';

type Props = {
  errorMessages: ErrorMessages;
};

const StudentParentEmail = ({ errorMessages }: Props) => {
  const { guardianEmails } = useContext(StateContext);
  const { updateGuardianEmails } = useContext(UpdatersContext);

  return (
    <FieldGroup
      title="Parent/guardian email(s)"
      description="Parent/guardians that will receive email notifications for this student. Separated by commas."
    >
      <Input
        placeholder="parent1@email.com, parent2@email.com"
        value={guardianEmails}
        onChange={e => updateGuardianEmails(e.target.value)}
        showErrorState={errorMessages.length > 0}
        blockTracking
      />
      <FieldError messages={errorMessages} />
      <Separator size={4 * BASE_UNIT} />
    </FieldGroup>
  );
};
export default StudentParentEmail;
