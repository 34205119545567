import { useCallback, useContext, useEffect } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import type { CombinedState } from 'ms-pages/Teacher/components/CreateTaskTemplate/state';
import useEditTaskTemplateMutation from 'ms-pages/Teacher/components/CreateTaskTemplate/state/editTaskTemplate';
import { UpdatersContext } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';

export default function useEditTaskTemplateWithSnackbar({
  taskTemplateFormState,
  problemContentIds,
  templateId,
  taskTemplateCreationCallback,
}: {
  taskTemplateFormState: CombinedState;
  problemContentIds: Array<string>;
  templateId: string;
  taskTemplateCreationCallback: () => void;
}) {
  const { clearCart } = useContext(UpdatersContext);

  const {
    values: { title, description, taskTemplateGroupIds },
    updaters: { reset: resetState },
  } = taskTemplateFormState;

  const [editTaskTemplateMutation, { data, error }] =
    useEditTaskTemplateMutation();

  const editTaskTemplate = useCallback(
    () =>
      editTaskTemplateMutation({
        variables: {
          templateId,
          title,
          description,
          problemContentIds,
          // we need to send string "none" if no template group is selected
          // to empty the template group
          taskTemplateGroupId: taskTemplateGroupIds[0] || 'none',
        },
      }),
    [
      description,
      editTaskTemplateMutation,
      problemContentIds,
      taskTemplateGroupIds,
      templateId,
      title,
    ],
  );

  const { enqueueMessage } = useContext(SnackbarContext);

  useEffect(
    () => {
      if (data?.editTaskTemplateDetails?.taskTemplate?.id != null) {
        enqueueMessage({
          text: 'Template has been updated',
          dismissable: true,
        });
        resetState();
        clearCart('transient');
        taskTemplateCreationCallback();
      }
    },
    // Many of the methods in the function above cannot be guaranteed constant.
    // we have to disable exhaustive deps check here just to be certain
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  // Generic error message
  useEffect(() => {
    if (error != null) {
      enqueueMessage({
        text:
          error?.message ||
          'The template has not been updated. Please try again',
      });
    }
  }, [enqueueMessage, error]);

  // Specific error messages
  useEffect(() => {
    if (data != null && data.editTaskTemplateDetails?.errors?.length > 0) {
      data.editTaskTemplateDetails.errors.forEach(error => {
        enqueueMessage({
          text: error.message,
        });
      });
    }
  }, [data, enqueueMessage]);

  return { editTaskTemplate };
}
