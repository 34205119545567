import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M1.16663 0H6.16663C6.81006 0 7.33337 0.523315 7.33337 1.16663V4.16663C7.33337 4.81006 6.81006 5.33337 6.16663 5.33337H1.16663C0.523315 5.33337 0 4.81006 0 4.16663V1.16663C0 0.523315 0.523315 0 1.16663 0ZM1.16663 6.66663H6.16663C6.81006 6.66663 7.33337 7.18994 7.33337 7.83337V14.8334C7.33337 15.4767 6.81006 16 6.16663 16H1.16663C0.523315 16 0 15.4767 0 14.8334V7.83337C0 7.18994 0.523315 6.66663 1.16663 6.66663ZM14.8334 10.6666H9.83337C9.18994 10.6666 8.66663 11.1899 8.66663 11.8334V14.8334C8.66663 15.4767 9.18994 16 9.83337 16H14.8334C15.4767 16 16 15.4767 16 14.8334V11.8334C16 11.1899 15.4767 10.6666 14.8334 10.6666ZM9.83337 0H14.8334C15.4767 0 16 0.523315 16 1.16663V8.16663C16 8.81006 15.4767 9.33337 14.8334 9.33337H9.83337C9.18994 9.33337 8.66663 8.81006 8.66663 8.16663V1.16663C8.66663 0.523315 9.18994 0 9.83337 0Z" />
);

const Dashboard = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Dashboard.displayName = 'Dashboard';

export default Dashboard;
