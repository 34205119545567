import { useState } from 'react';
import { commitMutation } from 'react-relay';
import type { Environment, Store } from 'relay-runtime';

import { publicEnvironment } from 'ms-utils/relay';

export type UseMutationArgs = {
  environment?: Environment;
  mutation: any; // tagged template literal
  updater?: ((store: Store) => void) | null | undefined;
};

export type UseMutationResponse<V, T> = [
  (v: V) => void,
  {
    loading: boolean;
    response: T | null;
    errors: null | any;
  },
];

export default function useMutation<T, V extends {}>({
  environment = publicEnvironment,
  mutation,
  updater = noop,
}: UseMutationArgs): UseMutationResponse<V, T> {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error | null>(null);
  const [response, setResponse] = useState<T | null>(null);
  const mutate = (variables: V) => {
    // todo talk with Kevon
    setLoading(true);
    setResponse(null);
    setErrors(null);

    commitMutation(environment, {
      mutation,
      variables,
      // TODO fix this
      // @ts-expect-error the .d.ts does not like how updater has been typed
      updater,
      // TODO fix this
      // @ts-expect-error the .d.ts does not like how updater has been typed
      onCompleted(completed: T) {
        setLoading(false);
        setResponse(completed);
      },
      onError(e) {
        setLoading(false);
        setErrors(e);
      },
    });
  };

  return [mutate, { loading, response, errors }];
}

function noop() {}
