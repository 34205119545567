import DetailHeader, {
  PrintButton,
  ActionButton,
} from 'ms-pages/Textbooks/components/DetailHeader';
import {
  useTopicAssessmentSubtabsState,
  useTopicTabsState,
  type TopicTab,
  type TopicAssessmentSubtab,
} from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';

import TopicViewTabs from './TopicViewTabs';

type Props = {
  coverImageUrl: string | null;
  topicTitle: string;
  topicBadgeUrl: string;
  overviewPdfAvailable: boolean;
  overviewPdfUrl: string | null;
  assessmentPdfAvailable: boolean;
  assessmentPdfUrl: string | null;
  answerKeyPdfAvailable: boolean;
  answerKeyPdfUrl: string | null;
  shouldRenderAssessmentTab: boolean;
  shouldRenderOverviewTab: boolean;
  answerKeyAvailable: boolean;
};

export default function TopicDetailHeader({
  coverImageUrl,
  topicTitle,
  topicBadgeUrl,
  overviewPdfAvailable,
  overviewPdfUrl,
  assessmentPdfAvailable,
  assessmentPdfUrl,
  answerKeyPdfAvailable,
  answerKeyPdfUrl,
  shouldRenderAssessmentTab,
  shouldRenderOverviewTab,
  answerKeyAvailable,
}: Props) {
  const [topicTab] = useTopicTabsState();
  const [topicAssessmentSubtab, setTopicAssessmentSubtab] =
    useTopicAssessmentSubtabsState();

  const pdfUrl = getPdfUrl({
    topicTab,
    topicAssessmentSubtab,
    overviewPdfAvailable,
    overviewPdfUrl,
    assessmentPdfAvailable,
    assessmentPdfUrl,
    answerKeyPdfAvailable,
    answerKeyPdfUrl,
  });

  const tabs = (
    <TopicViewTabs
      shouldRenderAssessmentTab={shouldRenderAssessmentTab}
      shouldRenderOverviewTab={shouldRenderOverviewTab}
    />
  );

  const secondaryActionsContent = (
    <>
      {topicTab === 'assessment' && answerKeyAvailable && (
        <ActionButton
          onClick={() => {
            setTopicAssessmentSubtab('answer-key');
          }}
        >
          Answer key
        </ActionButton>
      )}

      {pdfUrl != null && <PrintButton pdfUrl={pdfUrl} />}
    </>
  );

  return (
    <DetailHeader
      title={`${topicTitle} - Topic Overview`}
      coverImageUrl={coverImageUrl}
      badgeUrl={topicBadgeUrl}
      tabs={tabs}
      secondaryActionsContent={secondaryActionsContent}
    />
  );
}

// Since the answer key opens in a modal, this button will never be used
// to print the answer key.
// Since this might be subject to change, depending on a refactor on how we
// handle the jsx docs PDFs, we can keep this generic enough for now.
// This is subject to change. See https://mathspace.slack.com/archives/C1ACKB10T/p1713116588869839?thread_ts=1712732110.916879&cid=C1ACKB10T for udpates.
function getPdfUrl({
  topicTab,
  topicAssessmentSubtab,
  overviewPdfAvailable,
  overviewPdfUrl,
  assessmentPdfAvailable,
  assessmentPdfUrl,
  answerKeyPdfAvailable,
  answerKeyPdfUrl,
}: {
  topicTab: TopicTab;
  topicAssessmentSubtab: TopicAssessmentSubtab;
  overviewPdfAvailable: boolean;
  overviewPdfUrl: string | null;
  assessmentPdfAvailable: boolean;
  assessmentPdfUrl: string | null;
  answerKeyPdfAvailable: boolean;
  answerKeyPdfUrl: string | null;
}) {
  if (topicTab === 'overview' && !!overviewPdfUrl && overviewPdfAvailable) {
    return overviewPdfUrl;
  }

  if (topicTab === 'assessment') {
    if (
      topicAssessmentSubtab === 'worksheet' &&
      !!assessmentPdfUrl &&
      assessmentPdfAvailable
    ) {
      return assessmentPdfUrl;
    }

    if (
      topicAssessmentSubtab === 'answer-key' &&
      !!answerKeyPdfUrl &&
      answerKeyPdfAvailable
    ) {
      return answerKeyPdfUrl;
    }
  }

  return null;
}
