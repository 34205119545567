/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EngageActivityNextAction = "CLASS_DISCUSSION" | "HARDER_QUESTIONS" | "MORE_EXPLANATION" | "PERSONAL_REVISION" | "PRACTICE_QUESTIONS";
export type EngageActivitySentiment = "CONFIDENT" | "EXHAUSTED" | "FRUSTRATED" | "INTRIGUED" | "LOST" | "PROUD";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type EngageActivityQueryVariables = {
    engageWorkoutId: string;
};
export type EngageActivityQueryResponse = {
    readonly workout: {
        readonly id: string;
        readonly __typename: string;
        readonly responses?: string | null | undefined;
        readonly sentiment?: EngageActivitySentiment | null | undefined;
        readonly nextAction?: EngageActivityNextAction | null | undefined;
        readonly progress?: number | undefined;
        readonly studentTask?: ({
            readonly __typename: "AssignedTask";
            readonly __typename: "AssignedTask";
            readonly student: {
                readonly user: {
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                };
            };
            readonly task: {
                readonly __typename: "EngageTask";
                readonly jsxContent: {
                    readonly id: string | null;
                    readonly availableLocales: ReadonlyArray<{
                        readonly id: string | null;
                        readonly locale: SyllabusLocale | null;
                    }>;
                    readonly locale: SyllabusLocale | null;
                    readonly transpiledJsx: string | null;
                } | null;
                readonly areaOfStudy: {
                    readonly title: string;
                    readonly engageActivityCoverImageUrl: string;
                };
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            };
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null | undefined;
    } | null;
};
export type EngageActivityQuery = {
    readonly response: EngageActivityQueryResponse;
    readonly variables: EngageActivityQueryVariables;
};



/*
query EngageActivityQuery(
  $engageWorkoutId: ID!
) {
  workout(id: $engageWorkoutId) {
    id
    __typename
    ... on EngageWorkout {
      responses
      sentiment
      nextAction
      progress
      studentTask {
        __typename
        ... on AssignedTask {
          __typename
          student {
            user {
              id
              firstName
              lastName
            }
            id
          }
          task {
            __typename
            ... on EngageTask {
              jsxContent {
                id
                availableLocales {
                  __typename
                  id
                  locale
                }
                locale
                transpiledJsx
              }
              areaOfStudy {
                title
                engageActivityCoverImageUrl
                id
              }
            }
            id
          }
          id
        }
        ... on SelfDirectedTask {
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "engageWorkoutId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "engageWorkoutId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responses",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sentiment",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nextAction",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "progress",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "engageActivityCoverImageUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EngageActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "workout",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "studentTask",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Student",
                        "kind": "LinkedField",
                        "name": "student",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "task",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngageActivity",
                                "kind": "LinkedField",
                                "name": "jsxContent",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "availableLocales",
                                    "plural": true,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v9/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Subtopic",
                                "kind": "LinkedField",
                                "name": "areaOfStudy",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v12/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "EngageTask",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "AssignedTask",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "EngageWorkout",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EngageActivityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "workout",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "studentTask",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Student",
                        "kind": "LinkedField",
                        "name": "student",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "task",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EngageActivity",
                                "kind": "LinkedField",
                                "name": "jsxContent",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "availableLocales",
                                    "plural": true,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v2/*: any*/),
                                      (v9/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v9/*: any*/),
                                  (v10/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Subtopic",
                                "kind": "LinkedField",
                                "name": "areaOfStudy",
                                "plural": false,
                                "selections": [
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "EngageTask",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "type": "AssignedTask",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "SelfDirectedTask",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "EngageWorkout",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9945ba59355e687344beaacffdf71ad",
    "id": null,
    "metadata": {},
    "name": "EngageActivityQuery",
    "operationKind": "query",
    "text": "query EngageActivityQuery(\n  $engageWorkoutId: ID!\n) {\n  workout(id: $engageWorkoutId) {\n    id\n    __typename\n    ... on EngageWorkout {\n      responses\n      sentiment\n      nextAction\n      progress\n      studentTask {\n        __typename\n        ... on AssignedTask {\n          __typename\n          student {\n            user {\n              id\n              firstName\n              lastName\n            }\n            id\n          }\n          task {\n            __typename\n            ... on EngageTask {\n              jsxContent {\n                id\n                availableLocales {\n                  __typename\n                  id\n                  locale\n                }\n                locale\n                transpiledJsx\n              }\n              areaOfStudy {\n                title\n                engageActivityCoverImageUrl\n                id\n              }\n            }\n            id\n          }\n          id\n        }\n        ... on SelfDirectedTask {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f49ab5b1451600a995f3638d9ef2c09f';
export default node;
