import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { getCheckInUrl } from 'ms-pages/Lantern/utils/urls';
import useCreateSubstrandGrowthCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/useCreateSubstrandGrowthCheckIn';

import { CHECK_IN_CREATION_ERROR } from './utils';

function useStartSubstrandGrowthCheckIn({
  substrandId,
  replace = false,
}: {
  substrandId: string;
  replace?: boolean;
}) {
  const history = useHistory();
  const [
    createSubstrandGrowthCheckIn,
    {
      data: createCheckInResponse,
      loading: createCheckInLoading,
      error: createCheckInError,
    },
  ] = useCreateSubstrandGrowthCheckIn();

  const errors = useMemo(
    () => [
      ...(createCheckInError != null ? [CHECK_IN_CREATION_ERROR] : []),
      ...(createCheckInResponse?.createSubstrandGrowthCheckIn.errors || []),
    ],
    [createCheckInError, createCheckInResponse],
  );

  useEffect(() => {
    if (
      createCheckInResponse != null &&
      createCheckInResponse.createSubstrandGrowthCheckIn.checkIn?.id != null
    ) {
      const checkInUrl = getCheckInUrl({
        checkInId:
          createCheckInResponse.createSubstrandGrowthCheckIn?.checkIn?.id,
      });
      if (replace) {
        history.replace(checkInUrl);
      } else {
        history.push(checkInUrl);
      }
    }
  }, [createCheckInResponse, history, replace]);

  return {
    loading: createCheckInLoading,
    errors,
    start: () => {
      createSubstrandGrowthCheckIn({ variables: { substrandId } });
    },
  };
}

export default useStartSubstrandGrowthCheckIn;
