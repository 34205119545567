import { graphql, useMutation } from 'relay-hooks';

import type { useCreatePreTopicTestCheckInMutation } from './__generated__/useCreatePreTopicTestCheckInMutation.graphql';

const USE_CREATE_PRE_TOPIC_TEST_CHECK_IN_MUTATION = graphql`
  mutation useCreatePreTopicTestCheckInMutation($skillIds: [ID!]!) {
    createPreTopicTestCheckIn(skillIds: $skillIds) {
      checkIn {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreatePreTopicTestCheckIn() {
  return useMutation<useCreatePreTopicTestCheckInMutation>(
    USE_CREATE_PRE_TOPIC_TEST_CHECK_IN_MUTATION,
  );
}
