/**
 * Theme styles for Numero
 */

export const boxShadow = Object.freeze({
  regular: '0 2px 8px 0 rgba(0, 0, 0, 0.16)',
  slight: '0px 8.75px 49px rgba(62, 62, 76, 0.15)',
  slightUp: '0px -8.75px 49px rgba(62, 62, 76, 0.15)',
  tiny: '0px 1px 7px rgba(62, 62, 76, 0.12)',
  tinyLight: '0px 1px 7px 0px rgba(62, 62, 76, 0.06)',
});

export const PROGRESS_BAR = {
  HEIGHT: {
    MEDIUM: 10,
    SMALL: 5,
  },
  SPEED: {
    DEFAULT: 100, // percents per second
  },
};

export const BASE_UNIT = 4;

// this size normalises headers across all the platform
// it should be used in headers as required it to match the theme
// it shall not be used separately
export const HEADER_SIZE = 16 * BASE_UNIT;
export const LEFT_NAV_WIDTH = HEADER_SIZE;
export const ONBOARDING_ICON_SIZE = 50;
