import type { ReactNode } from 'react';
import { createContext, useState, useEffect, useMemo } from 'react';

import { setCookie } from 'ms-utils/cookies';
import localStorageDb, {
  isLocalStorageAvailable,
} from 'ms-utils/localStorageDb';

import {
  privateHelperGetDevice,
  privateHelperGetRuntime,
  privateHelperGetWebAppVersion,
} from '../utils';
import type { AppEnv } from '../utils';

declare global {
  interface Window {
    // Defined in react_app.html
    ENVIRONMENT_NAME: string;
  }
}

// eslint-disable-next-line no-unused-vars
type AppEnvContextType = {
  appEnv: AppEnv;
};

const envFromLocalStorage = () => {
  if (isLocalStorageAvailable()) {
    return localStorageDb.get('mathspaceAppEnv');
  }
  return null;
};

const envFromHelpers = () => ({
  DEVICE: privateHelperGetDevice(),
  RUNTIME: privateHelperGetRuntime(),
});

// This is a chain of responsibility.
//
// We return the first definition of appEnv that we find, searching
// in order.
//
// - If appEnv was passed in props, we respect that.
// - If no appEnv is found, we check whether there's anything corresponding to the app
// running in a React Native environment.
// - If there's still no environment information, we're either running in a web
// browser, or legacy mobile app, so we determine environment info using the
// user agent string.
//
// Web app version (i.e. the release tag) is constant across all environments.
export const getEnv = (appEnv?: AppEnv) => ({
  APPLICATION_ENVIRONMENT: window.ENVIRONMENT_NAME ?? 'unknown',
  WEB_APP_VERSION: privateHelperGetWebAppVersion(),
  ...(appEnv || envFromLocalStorage() || envFromHelpers()),
});

export const AppEnvContext = createContext<AppEnvContextType>({
  appEnv: getEnv(),
});

export default function AppEnvProvider({
  children,
  appEnv: appEnvProp,
}: {
  children: ReactNode;
  appEnv?: AppEnv;
}) {
  const [appEnv, setAppEnv] = useState(() => getEnv(appEnvProp));

  useEffect(() => {
    setAppEnv(getEnv(appEnvProp));
  }, [appEnvProp]);

  useEffect(() => {
    setCookie('appEnv', JSON.stringify(appEnv), null, '/');
  }, [appEnv]);

  const contextValue = useMemo(() => ({ appEnv }), [appEnv]);
  return (
    <AppEnvContext.Provider value={contextValue}>
      {children}
    </AppEnvContext.Provider>
  );
}
