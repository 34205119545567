import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Accessibility = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={19}
    viewBoxHeight={19}
  >
    <path
      d="M9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25329 14.7467 0 9.5 0C4.25329 0 0 4.25329 0 9.5C0 14.7467 4.25329 19 9.5 19Z"
      fill={color}
      mask="url(#mask-ce32726e93e)"
    />
    <mask id="mask-ce32726e93e">
      <rect x="0" y="0" width="19" height="19" fill="white" />
      <path
        d="M9.49999 6.22417C10.4046 6.22417 11.1379 5.49084 11.1379 4.58623C11.1379 3.68163 10.4046 2.9483 9.49999 2.9483C8.59539 2.9483 7.86206 3.68163 7.86206 4.58623C7.86206 5.49084 8.59539 6.22417 9.49999 6.22417Z"
        fill="black"
      />
      <path
        d="M14.0863 7.20869L10.646 7.51744C10.5194 7.5288 10.3926 7.53448 10.2654 7.53448H8.73483C8.60775 7.53448 8.48088 7.5288 8.35421 7.51744L4.9139 7.20869C4.68051 7.19075 4.45529 7.29868 4.32306 7.49183C4.19082 7.68498 4.17168 7.934 4.27284 8.14509C4.37399 8.35617 4.58008 8.49726 4.81346 8.5152L7.59379 8.76745C7.93128 8.79806 8.18973 9.08099 8.18976 9.41987V10.1727C8.18977 10.3776 8.15133 10.5806 8.07645 10.7714L6.49454 14.8C6.39721 15.0137 6.42167 15.2631 6.55864 15.4538C6.69562 15.6445 6.92417 15.7473 7.15774 15.7233C7.3913 15.6993 7.59417 15.5521 7.68951 15.3376L9.20272 11.6642C9.2523 11.5439 9.36951 11.4655 9.49955 11.4655H9.50067C9.63073 11.4655 9.74795 11.544 9.79749 11.6643L11.3107 15.3376C11.4592 15.6676 11.8471 15.8147 12.177 15.6662C12.507 15.5177 12.6542 15.1299 12.5057 14.7999L10.9238 10.7714C10.8489 10.5806 10.8104 10.3776 10.8105 10.1727V9.41987C10.8105 9.08096 11.0689 8.798 11.4064 8.76738L14.1868 8.51514C14.4215 8.49901 14.6296 8.35819 14.7319 8.14624C14.8342 7.93428 14.8149 7.68376 14.6815 7.48993C14.548 7.2961 14.3208 7.18875 14.0863 7.20869Z"
        fill="black"
      />
    </mask>
  </Icon>
);

Accessibility.displayName = 'Accessibility';

export default Accessibility;
