import { useState, useCallback, useEffect } from 'react';

import { useMaxScrollPercent } from 'ms-utils/hooks/useScrollPercent';

export type Quartile = '0' | '25' | '50' | '75' | '100';

export default function useTrackScrollDepthQuartiles(
  scrollingElement: HTMLElement | null | undefined,
  track: (quartile: Quartile) => void,
) {
  const [quartile, setQuartile] = useState<Quartile>('0');

  const trackQuartiles = useCallback<(maxScroll: number) => void>(
    maxScroll => {
      if (0.25 <= maxScroll && maxScroll < 0.5) setQuartile('25');
      if (0.5 <= maxScroll && maxScroll < 0.75) setQuartile('50');
      if (0.75 <= maxScroll && maxScroll < 1) setQuartile('75');
      if (maxScroll >= 1) setQuartile('100');
    },
    // Redefine this every time `track` ref change.
    // See comments on useMaxScrollPercent.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [track],
  );

  useMaxScrollPercent(scrollingElement, trackQuartiles);

  useEffect(() => {
    if (quartile !== '0') track(quartile);
    // Only run when quartile changes.
    // When `track` ref changes, reset the quartile (see below).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quartile]);

  // Reset max scroll value when `track` ref change.
  // Make sure to use React.useCallback correctly when creating it
  // and pass it this hook.
  useEffect(() => {
    setQuartile('0');
    track('0');
  }, [track]);
}
