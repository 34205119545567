/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandRecommendationsQueryVariables = {
    substrandId: string;
    strandId: string;
};
export type StudentSkillsMapSubstrandRecommendationsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandRecommendationsFragment">;
};
export type StudentSkillsMapSubstrandRecommendationsQuery = {
    readonly response: StudentSkillsMapSubstrandRecommendationsQueryResponse;
    readonly variables: StudentSkillsMapSubstrandRecommendationsQueryVariables;
};



/*
query StudentSkillsMapSubstrandRecommendationsQuery(
  $substrandId: ID!
  $strandId: ID!
) {
  ...StudentSkillsMapSubstrandRecommendationsFragment
}

fragment StudentSkillsMapSubstrandRecommendationsFragment on Query {
  lantern {
    substrand(id: $substrandId) {
      strand {
        id
      }
      studentSubtopicRecommendations {
        id
        ...SubtopicRecommendationCard_substrandSubtopicRecommendation
        category
      }
      id
    }
    viewer {
      __typename
      ... on LanternStudent {
        strandStatus(strandId: $strandId) {
          status
          lastWorkedOnDiagnosticCheckIn {
            id
          }
          strand {
            id
          }
          id
        }
      }
      id
    }
  }
}

fragment SubtopicRecommendationCard_substrandSubtopicRecommendation on SubstrandSubtopicRecommendation {
  subtopic {
    id
    title
    topic {
      id
      badgeBackground
      badgeUrl
      syllabus {
        id
      }
    }
    hasAdaptivePractice
    gamifiedMastery
  }
  category
  outcomes {
    id
    title
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "strandId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "substrandId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentSkillsMapSubstrandRecommendationsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "StudentSkillsMapSubstrandRecommendationsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentSkillsMapSubstrandRecommendationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "substrandId"
              }
            ],
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SubstrandSubtopicRecommendation",
                "kind": "LinkedField",
                "name": "studentSubtopicRecommendations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subtopic",
                    "kind": "LinkedField",
                    "name": "subtopic",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeBackground",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Syllabus",
                            "kind": "LinkedField",
                            "name": "syllabus",
                            "plural": false,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasAdaptivePractice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gamifiedMastery",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternOutcome",
                    "kind": "LinkedField",
                    "name": "outcomes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "strandId",
                        "variableName": "strandId"
                      }
                    ],
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "lastWorkedOnDiagnosticCheckIn",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a9f6edda7eaf0caf41f8f4dd51d7c653",
    "id": null,
    "metadata": {},
    "name": "StudentSkillsMapSubstrandRecommendationsQuery",
    "operationKind": "query",
    "text": "query StudentSkillsMapSubstrandRecommendationsQuery(\n  $substrandId: ID!\n  $strandId: ID!\n) {\n  ...StudentSkillsMapSubstrandRecommendationsFragment\n}\n\nfragment StudentSkillsMapSubstrandRecommendationsFragment on Query {\n  lantern {\n    substrand(id: $substrandId) {\n      strand {\n        id\n      }\n      studentSubtopicRecommendations {\n        id\n        ...SubtopicRecommendationCard_substrandSubtopicRecommendation\n        category\n      }\n      id\n    }\n    viewer {\n      __typename\n      ... on LanternStudent {\n        strandStatus(strandId: $strandId) {\n          status\n          lastWorkedOnDiagnosticCheckIn {\n            id\n          }\n          strand {\n            id\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment SubtopicRecommendationCard_substrandSubtopicRecommendation on SubstrandSubtopicRecommendation {\n  subtopic {\n    id\n    title\n    topic {\n      id\n      badgeBackground\n      badgeUrl\n      syllabus {\n        id\n      }\n    }\n    hasAdaptivePractice\n    gamifiedMastery\n  }\n  category\n  outcomes {\n    id\n    title\n  }\n}\n"
  }
};
})();
(node as any).hash = 'afc232bf5b493d0f915e9706f30fb536';
export default node;
