import { findLastIndex } from 'ramda';

import type {
  Problem,
  Updater,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import {
  MAX_NUMBER_OF_QUESTIONS,
  reorderList,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';

export type CollectionType = 'main' | 'transient';

const getCollectionKey = (
  collectionType?: CollectionType | undefined,
): 'transientProblemsCollection' | 'problemsCollection' =>
  collectionType === 'transient'
    ? 'transientProblemsCollection'
    : 'problemsCollection';

export const addProblemContent: Updater<[Problem, CollectionType?]> =
  (problemContent, collectionType) => state => {
    const key = getCollectionKey(collectionType);
    return {
      ...state,
      [key]: [...state[key], problemContent].slice(0, MAX_NUMBER_OF_QUESTIONS),
    };
  };

export const removeProblemContent: Updater<[number, CollectionType?]> =
  (problemContentIndex, collectionType) => state => {
    const key = getCollectionKey(collectionType);
    return {
      ...state,
      [key]: [
        ...state[key].slice(0, problemContentIndex),
        ...state[key].slice(problemContentIndex + 1),
      ],
    };
  };

// remove a problem content from the collection by ID
// since multiple items in the collection can have the same ID,
// we remove the last item with the given ID
export const removeProblemContentById: Updater<[string, CollectionType?]> =
  (problemContentId, collectionType) => state => {
    const key = getCollectionKey(collectionType);

    const lastIndex = findLastIndex<Problem>(
      ([pcId]) => pcId === problemContentId,
    )(state[key]);

    if (lastIndex === -1) {
      return state;
    }

    return {
      ...state,
      [key]: [
        ...state[key].slice(0, lastIndex),
        ...state[key].slice(lastIndex + 1),
      ],
    };
  };

export const reorderProblemContent: Updater<
  [number, number, CollectionType?]
> = (originalIndex, finalIndex, collectionType) => state => {
  const key = getCollectionKey(collectionType);
  const newProblemsCollection = reorderList(
    state[key],
    originalIndex,
    finalIndex,
  );
  return {
    ...state,
    [key]: newProblemsCollection,
  };
};

export const addMultipleProblemContents: Updater<
  [Problem[], CollectionType?]
> = (problemContents, collectionType) => state => {
  const key = getCollectionKey(collectionType);
  return {
    ...state,
    [key]: [...state[key], ...problemContents].slice(
      0,
      MAX_NUMBER_OF_QUESTIONS,
    ),
  };
};

export const duplicateProblemContent: Updater<[number, CollectionType?]> =
  (index, collectionType) => state => {
    const key = getCollectionKey(collectionType);
    return {
      ...state,
      [key]: [
        ...state[key].slice(0, index + 1),
        ...state[key].slice(index),
      ].slice(0, MAX_NUMBER_OF_QUESTIONS),
    };
  };

export const clearCart: Updater<[CollectionType?]> =
  collectionType => state => {
    const key = getCollectionKey(collectionType);
    return {
      ...state,
      [key]: [],
    };
  };
