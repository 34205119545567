import type { SortDir } from 'ms-pages/Teacher/components/Table';

export type StudentTabSortingField =
  | 'studentName'
  | 'completion'
  | 'completedAt'
  | 'correctQuestions'
  | 'result'
  | 'timeSpent'
  | 'lastSeen'
  | 'scrollDepth';

export type AdaptiveTaskQuestionTabSortingField =
  | 'correct'
  | 'partial'
  | 'incorrect';
export type CustomTaskQuestionTabSortingField =
  | AdaptiveTaskQuestionTabSortingField
  | 'incomplete'
  | 'question';

export type GridViewSortingField =
  | 'studentName'
  | 'completion'
  | 'result'
  | 'timeSpent';

export type State = {
  filterByClass: string | null | undefined;
  studentsSortBy: StudentTabSortingField;
  // Adaptive & Custom tasks have different default sorting requirements and so must be
  // kept seperate in state
  customTaskquestionsSortBy: CustomTaskQuestionTabSortingField;
  adaptiveTaskquestionsSortBy: AdaptiveTaskQuestionTabSortingField;
  gridViewSortBy: GridViewSortingField;
  sortDir: SortDir;
};

export const initialState: State = {
  filterByClass: null,
  studentsSortBy: 'studentName',
  gridViewSortBy: 'studentName',
  customTaskquestionsSortBy: 'question',
  adaptiveTaskquestionsSortBy: 'correct',
  sortDir: 'asc',
};

export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;
