import { omit } from 'ramda';
import type { HTMLAttributes, ReactNode, CSSProperties } from 'react';
import { forwardRef } from 'react';

export type StackProps = {
  center?: boolean;
  justify?: 'center' | 'start' | 'end' | 'space-between' | 'space-around';
  wrap?: boolean;
  children: ReactNode;
  gap?: number;
  style?: CSSProperties;
  width?: number | string;
  height?: number | string;
  maxWidth?: number | string;
  grow?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const omitNonForwardedProps = omit([
  'center',
  'justify',
  'wrap',
  'width',
  'maxWidth',
  'height',
  'grow',
  'style',
  'gap',
]);

export const HStack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  return (
    <div
      ref={ref}
      css={{
        display: 'flex',
        ...(props.center && { alignItems: 'center' }),
        ...(props.justify && { justifyContent: props.justify }),
        ...(props.wrap && { flexWrap: 'wrap' }),
        ...(props.width && { width: props.width }),
        ...(props.maxWidth && { maxWidth: props.maxWidth }),
        ...(props.height && { height: props.height }),
        ...(props.grow && { flexGrow: 1 }),
        ...(props.gap && { gap: props.gap }),
        ...props.style,
      }}
      {...omitNonForwardedProps(props)}
    />
  );
});

export const VStack = forwardRef<HTMLDivElement, StackProps>((props, ref) => {
  return (
    <div
      ref={ref}
      css={{
        display: 'flex',
        flexDirection: 'column',
        ...(props.center && { alignItems: 'center' }),
        ...(props.justify != null && { justifyContent: props.justify }),
        ...(props.width && { width: props.width }),
        ...(props.maxWidth && { maxWidth: props.maxWidth }),
        ...(props.height && { height: props.height }),
        ...(props.grow && { flexGrow: 1 }),
        ...(props.gap && { gap: props.gap }),
        ...props.style,
      }}
      {...omitNonForwardedProps(props)}
    />
  );
});
