import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const DndHandle = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={9}
    viewBoxHeight={14}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5542 3.54175C5.72577 3.54175 5.0542 2.87018 5.0542 2.04175C5.0542 1.21332 5.72577 0.541748 6.5542 0.541748C7.38263 0.541748 8.0542 1.21332 8.0542 2.04175C8.0542 2.87018 7.38263 3.54175 6.5542 3.54175ZM1.5542 3.54175C0.725772 3.54175 0.0541992 2.87018 0.0541992 2.04175C0.0541992 1.21332 0.725772 0.541748 1.5542 0.541748C2.38263 0.541748 3.0542 1.21332 3.0542 2.04175C3.0542 2.87018 2.38263 3.54175 1.5542 3.54175ZM0.0541992 7.04175C0.0541992 7.87018 0.725772 8.54175 1.5542 8.54175C2.38263 8.54175 3.0542 7.87018 3.0542 7.04175C3.0542 6.21332 2.38263 5.54175 1.5542 5.54175C0.725772 5.54175 0.0541992 6.21332 0.0541992 7.04175ZM1.5542 13.5417C0.725772 13.5417 0.0541992 12.8702 0.0541992 12.0417C0.0541992 11.2133 0.725772 10.5417 1.5542 10.5417C2.38263 10.5417 3.0542 11.2133 3.0542 12.0417C3.0542 12.8702 2.38263 13.5417 1.5542 13.5417ZM5.0542 7.04175C5.0542 7.87018 5.72577 8.54175 6.5542 8.54175C7.38263 8.54175 8.0542 7.87018 8.0542 7.04175C8.0542 6.21332 7.38263 5.54175 6.5542 5.54175C5.72577 5.54175 5.0542 6.21332 5.0542 7.04175ZM6.5542 13.5417C5.72577 13.5417 5.0542 12.8702 5.0542 12.0417C5.0542 11.2133 5.72577 10.5417 6.5542 10.5417C7.38263 10.5417 8.0542 11.2133 8.0542 12.0417C8.0542 12.8702 7.38263 13.5417 6.5542 13.5417Z"
      fill="currentColor"
    />
  </Icon>
);

DndHandle.displayName = 'DndHandle';

export default DndHandle;
