import { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import type { TextbookFilterQuery } from 'ms-components/MultiTextbookSearchModal/__generated__/TextbookFilterQuery.graphql';
import { TextbookTeacher } from 'ms-components/icons';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import ScrollableCheckBoxFilter from 'ms-pages/Teacher/components/ScrollableCheckBoxFilter';
import { useRecentlyUsedTextbooksForUser } from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import type { SetState } from 'ms-utils/typescript-utils';

const query = graphql`
  query TextbookFilterQuery($schoolId: ID!) {
    school(id: $schoolId) {
      availableSyllabuses {
        id
        title
      }
    }
  }
`;

export type TextbookOptionsArray = ReadonlyArray<string>;

type Props = {
  selectedTextbookIds: TextbookOptionsArray;
  setSelectedTextbookIds: SetState<TextbookOptionsArray>;
  containerBottom: number;
};

export function TextbookFilter(props: Props) {
  return (
    <ErrorBoundary name="TextbookFilter" fallback="Failed to load textbooks">
      <Suspense>
        <TextbookFilterInner {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}

function TextbookFilterInner({
  selectedTextbookIds,
  setSelectedTextbookIds,
  containerBottom,
}: Props) {
  const { userId } = useViewer();
  const { schoolId } = useMaybeTeacherContext();

  const data = useLazyLoadQuery<TextbookFilterQuery>(query, { schoolId });

  if (data.school == null) {
    throw new Error(`TextbookFilterQuery: School-${schoolId} doesn't exist`);
  }

  const recentlyUsedTextbooks = useRecentlyUsedTextbooksForUser(userId);

  const availableTextbooks = data?.school?.availableSyllabuses;

  const options = useMemo(() => {
    if (availableTextbooks == null) {
      return undefined;
    }

    const sortedTextbooks = availableTextbooks
      .map(({ id, title }) => ({
        id,
        title,
        isRecentlyUsed: recentlyUsedTextbooks.includes(id),
      }))
      .sort((a, b) => {
        if (a.isRecentlyUsed === b.isRecentlyUsed) return 0;
        return a.isRecentlyUsed ? -1 : 0;
      });

    return sortedTextbooks.map(({ id, title }) => ({
      key: id,
      label: title,
    }));
  }, [availableTextbooks, recentlyUsedTextbooks]);

  return (
    <ScrollableCheckBoxFilter
      icon={<TextbookTeacher />}
      label="Textbooks"
      options={options}
      prevSelectedKeys={selectedTextbookIds}
      submitSelection={setSelectedTextbookIds}
      maxWidth={300}
      suspendable
      searchable
      containerBottom={containerBottom}
    />
  );
}
