import type { Dataset } from 'ms-utils/dataset';
import { unwrap } from 'ms-utils/typescript-utils';

export type Tree<T> = {
  meta: T;
  children: Array<Tree<T>>;
};

export type TreeDimensions = {
  depth: number;
  maxDepth: number;
  leafNodeCount: number;
};

/**
 * A helper function which creates a single tree node (with no children).
 *
 * @param {T} meta
 * This parameter will be inserted into the tree node as metadata.
 * @returns {Tree<T>}
 * A single tree node.
 */
export function create<T>(meta: T): Tree<T> {
  return {
    children: [],
    meta,
  };
}

/**
 * Recursively reduces a tree to a single value using a depth first approach.
 *
 * @param {Tree<T>} tree - A tree to reduce.
 * @param {Function} reducer - Function that gets passed the accumulator value and current node.
 * @param {any} initial - The initial value of the accumulator.
 * @return {any} - The accumulated value.
 */
export function reduce<T>(
  tree: Tree<T>,
  reducer: (acc: any, node: Tree<T>) => any,
  initial: any,
): any {
  const accumulator = reducer(initial, tree);

  return tree.children.reduce(
    (acc, child) => reduce(child, reducer, acc),
    accumulator,
  );
}

/**
 * Recursively maps a tree to a new tree using a depth first approach. Note: The node passed to the
 * mapper function will have it's children already mapped.
 *
 * @param {Tree<*>} tree - A tree to map.
 * @param {Function} mapper - Function that transforms a node from old to new.
 * @param {number} depth - Depth of the current node.
 * @return {Tree<*>} - The new tree.
 */
export function map<A, B>(
  tree: Tree<A>,
  mapper: (
    node: {
      meta: A;
      children: Array<Tree<B>>;
    },
    depth: number,
  ) => Tree<B>,
  depth: number = 0,
): Tree<B> {
  return mapper(
    {
      meta: tree.meta,
      children: tree.children.map(child => map(child, mapper, depth + 1)),
    },
    depth,
  );
}

/**
 * Recursively filters the nodes a tree has using a depth first approach. If a node is filtered out
 * of a tree, all of it's descendants are filtered out as well.
 *
 * @param {Tree<*>} tree - A tree to filter.
 * @param {Function} predicate - Filter function that gets passed the current node. It must return
 * true for nodes to keep and false for those to reject.
 * @return {Tree<*>} - The new tree.
 */
// TODO: Don't use a depth first. Inefficient to check nodes that'll get dropped anyways.
export function filter<T>(
  tree: Tree<T>,
  predicate: (tree: Tree<T>) => boolean,
): Tree<T> {
  return {
    meta: tree.meta,
    children: tree.children
      .filter(predicate)
      .map(child => filter(child, predicate)),
  };
}

/**
 * Takes a tree and flattens it into an array of it's nodes.
 *
 * @param {Tree<*>} tree - deserialized probability tree data.
 * @return {Array<Tree>} resultList: Flat array of all the tree nodes.
 */
export function flatten<T>(tree: Tree<T>): Array<Tree<T>> {
  return reduce(
    tree,
    (acc: Array<Tree<T>>, node: Tree<T>): Array<Tree<T>> => acc.concat([node]),
    [],
  );
}

/**
 * Calculates the maxDepth and leafNodeCount for every node in a tree. The maxDepth is the maximum
 * number of generations below and including a given node. The leadNodeCount in the  number of leaf
 * nodes that are descendants of a give node.
 *
 * @param {Tree<*>} tree - A tree to calculate dimensions of.
 * @return {Tree<TreeDimensions>} - A tree with maxDepth and leafNodeCount information in each
 * node's meta data.
 */
// TODO: Rename 'describe()'?
export function getDimensions(tree: Tree<any>): Tree<TreeDimensions> {
  return map(tree, (node, depth) => {
    let leafNodeCount = 1;
    let maxDepth = 1;
    if (node.children.length) {
      leafNodeCount = node.children.reduce(
        (count, child) => count + (child.meta.leafNodeCount || 0),
        0,
      );
      maxDepth =
        node.children.reduce(
          (max, child) => Math.max(child.meta.maxDepth || 0, max),
          0,
        ) + 1;
    }
    return {
      children: node.children,
      meta: {
        depth,
        leafNodeCount,
        maxDepth,
      },
    };
  });
}

/**
 * Map the elements in a tree which match a predicate.
 *
 * @param {Tree<T>} tree A tree to be updated
 * @param {Predicate<Tree<T>>} predicate
 * A function which is called on each tree node.  If it returns true, the node
 * will be updated.  If false, the node will be passed over.
 * @param {Function} mapper
 * The mapper function will be applied to all nodes which match the predicate.
 * @returns {Tree<T>}
 * Returns a tree of the same type as the input with the update transformation
 * applied.
 */
export function update<T>(
  tree: Tree<T>,
  predicate: (tree: Tree<T>) => boolean,
  mapper: (node: Tree<T>) => Tree<T>,
): Tree<T> {
  return map(tree, node => (predicate(node) ? mapper(node) : node));
}

/**
 * Zip two trees together by combining each node's metadata with a `zipper` function.
 *
 * @param {Tree<A>} a The first tree
 * @param {Tree<B>} b The second tree
 * @param {Function} zipper
 * A function that is passed the metadata of each node.  The return value will be
 * saved as the metadata of the new tree.
 * @returns {Tree<C>}
 * A resulting tree with zipped metadata.
 */
export function zip<A, B, C>(
  a: Tree<A>,
  b: Tree<B>,
  zipper: (a: A, b: B) => C,
): Tree<C> {
  return {
    meta: zipper(a.meta, b.meta),
    // NOTE This implementation is buggy, it'll blow up if the two trees
    // you are trying to zip have different shapes.
    children: a.children.map((child, i) =>
      zip(child, unwrap(b.children[i]), zipper),
    ),
  };
}

/**
 * Convert a tree to a Dataset with the same structure.
 *
 * @param {Tree<T>} tree The tree to be translated.
 * @returns {Dataset<T>}
 * A nested array with elements of a type that match the type of the tree's
 * metadata.
 */
export function toDataset<T>(tree: Tree<T>): Dataset<T> {
  return [tree.meta, tree.children.map(toDataset)];
}
