/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OnboardingStepStatus = "ACTIVE" | "BLOCKED" | "COMPLETED";
export type StudentOnboardingStepType = "CHOOSE_AVATAR" | "CHOOSE_GRADE" | "COMPLETE_CHECKIN" | "COMPLETE_INTRO_TASK" | "SEE_WELCOME_MESSAGE" | "WORKED_ON_RECOMMENDATION";
export type WithStudentOnboardingFragment_profile = {
    readonly __typename: "Student";
    readonly hasCompletedOnboarding: boolean;
    readonly onboardingChecklist: ReadonlyArray<{
        readonly status: OnboardingStepStatus;
        readonly stepType: StudentOnboardingStepType;
    }>;
    readonly " $refType": "WithStudentOnboardingFragment_profile";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "WithStudentOnboardingFragment_profile";
};
export type WithStudentOnboardingFragment_profile$data = WithStudentOnboardingFragment_profile;
export type WithStudentOnboardingFragment_profile$key = {
    readonly " $data"?: WithStudentOnboardingFragment_profile$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"WithStudentOnboardingFragment_profile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithStudentOnboardingFragment_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasCompletedOnboarding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentOnboardingStep",
          "kind": "LinkedField",
          "name": "onboardingChecklist",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "stepType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "Student",
      "abstractKey": null
    }
  ],
  "type": "Profile",
  "abstractKey": "__isProfile"
};
(node as any).hash = '83bdb3d2f82649c8ef5736dbfb0ae60d';
export default node;
