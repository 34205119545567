import { keyframes } from '@emotion/react';

import { colors } from 'ms-pages/Lantern/styles';

const HEIGHT = 28;
const SQUARE_GAP = 8;
const SQUARE_WIDTH = HEIGHT;
const WIDTH = 3 * SQUARE_WIDTH + 2 * SQUARE_GAP;
const BORDER_RADIUS = 4;
const ANIMATION_DURATION = 3000; // milliseconds

const opacityAnimation = keyframes`
  0% {
    opacity: 0.4;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
`;

const styles = {
  root: {
    height: '100%',
    width: '100%',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    color: colors.almond,
  },
  dot: {
    animationName: opacityAnimation,
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationIterationCount: 'infinite',
  },
  firstDot: {
    animationDelay: '500ms',
  },
  secondDot: {
    animationDelay: '700ms',
  },
  thirdDot: {
    animationDelay: '900ms',
  },
};

export default function LoadingSpinner() {
  return (
    <div css={styles.root}>
      <svg height={HEIGHT} viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
        <rect
          css={[styles.dot, styles.firstDot]}
          fill="currentColor"
          style={{ opacity: 0.4 }}
          x="0"
          y="0"
          height={HEIGHT}
          width={SQUARE_WIDTH}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
        />
        <rect
          css={[styles.dot, styles.secondDot]}
          fill="currentColor"
          style={{ opacity: 0.4 }}
          x={SQUARE_WIDTH + SQUARE_GAP}
          y="0"
          height={HEIGHT}
          width={SQUARE_WIDTH}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
        />
        <rect
          css={[styles.dot, styles.thirdDot]}
          fill="currentColor"
          style={{ opacity: 0.4 }}
          x={2 * SQUARE_WIDTH + 2 * SQUARE_GAP}
          y="0"
          height={HEIGHT}
          width={SQUARE_WIDTH}
          rx={BORDER_RADIUS}
          ry={BORDER_RADIUS}
        />
      </svg>
    </div>
  );
}
