import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M14.3125,8 L13.5758929,8 L13.5758929,6.57142857 C13.5758929,4.6 11.9258929,3 9.89285714,3 C7.85982143,3 6.20982143,4.6 6.20982143,6.57142857 L6.20982143,8 L5.47321429,8 C4.66294643,8 4,8.64285714 4,9.42857143 L4,16.5714286 C4,17.3571429 4.66294643,18 5.47321429,18 L14.3125,18 C15.1227679,18 15.7857143,17.3571429 15.7857143,16.5714286 L15.7857143,9.42857143 C15.7857143,8.64285714 15.1227679,8 14.3125,8 Z M9.89285714,14.4285714 C9.08258929,14.4285714 8.41964286,13.7857143 8.41964286,13 C8.41964286,12.2142857 9.08258929,11.5714286 9.89285714,11.5714286 C10.703125,11.5714286 11.3660714,12.2142857 11.3660714,13 C11.3660714,13.7857143 10.703125,14.4285714 9.89285714,14.4285714 Z M12.1763393,8 L7.609375,8 L7.609375,6.57142857 C7.609375,5.35 8.63325893,4.35714286 9.89285714,4.35714286 C11.1524554,4.35714286 12.1763393,5.35 12.1763393,6.57142857 L12.1763393,8 Z"
    transform="translate(0.2, -1)"
  />
);

const Lock = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Lock.displayName = 'Lock';

export default Lock;
