import type { ReactNode } from 'react';
import { useContext } from 'react';

import { AppEnvContext } from '../Provider';
import type { AppEnv } from '../utils';

type Props = {
  render: ({ appEnv }: { appEnv: AppEnv }) => ReactNode;
};

export default function AppEnvRenderer({ render }: Props) {
  return <AppEnvContext.Consumer>{render}</AppEnvContext.Consumer>;
}

export function useAppEnv() {
  return useContext(AppEnvContext);
}
