import { useContext } from 'react';

import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import { StateContext } from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import Input from 'ms-ui-primitives/Input';

type Props = {
  errorMessages: ErrorMessages;
  onBlur?: (() => void) | undefined;
  onChange: (e: string) => void;
};

const StudentLastName = ({ errorMessages, onBlur, onChange }: Props) => {
  const { lastName } = useContext(StateContext);

  return (
    <FieldGroup isFieldRequired title="Last name">
      <Input
        placeholder="Last name"
        value={lastName}
        onChange={e => onChange(e.target.value)}
        showErrorState={errorMessages.length > 0}
        onBlur={onBlur}
      />
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
};
export default StudentLastName;
