import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { useCollapse } from 'ms-pages/Teacher/components/Collapse/useCollapse';

type AccordionChildrenArgs = {
  openItem: string | null;
  setOpenItem: (item: string) => void;
};

export default function Accordion(props: {
  children: (args: AccordionChildrenArgs) => ReactNode;
}) {
  const [openItem, setOpenItem] = useState<string | null>(null);

  return props.children({
    openItem,
    setOpenItem,
  });
}

export const ACCORDION_ANIMATION_DURATION = 250;

const styles = StyleSheet.create({
  root: {
    transition: `height ${ACCORDION_ANIMATION_DURATION / 1000}s linear`,
    overflow: 'hidden',
  },
});

// eslint-disable-next-line react/no-multi-comp
export function AccordionItem({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: ReactNode | null | undefined;
}) {
  const [outer, inner] = useCollapse<HTMLDivElement, HTMLDivElement>({
    isOpen,
  });

  return (
    <div ref={outer} className={css(styles.root)}>
      <div ref={inner}>{isOpen && children}</div>
    </div>
  );
}
