import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { useMultiTextbookSearchModalContext } from 'ms-components/MultiTextbookSearchModal/MultiTextbookSearchModalContext';
import type {
  SubtopicTab,
  SubtopicLessonTab,
  CoreTextbookSubtopicActiveTab as CoreTextbookSubtopicTab,
  LegacyTextbookSubtopicActiveTab as LegacyTextbookSubtopicTab,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import {
  DEFAULT_SUBTOPIC_TAB,
  SUBTOPIC_LESSON_TABS,
  DEFAULT_SUBTOPIC_LESSON_TAB,
  LEGACY_TEXTBOOK_SUBTOPIC_ACTIVE_TAB,
  CORE_TEXTBOOK_SUBTOPIC_ACTIVE_TAB,
  SUBTOPIC_TABS,
  SUBTOPIC_TAB_QUERY_PARAM_NAME,
  LEGACY_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME,
  CORE_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import useTabsFallback from 'ms-utils/hooks/useTabsFallback';

// This hooks aims to encapsulate all the state and business logic of textbook tabs:
// - Encode the state in the query string safely
// - Handle not available content by not displaying the tab, with fallback logic to switch to other available content
// - Redirect of legacy urls to current ones

const LEGACY_TO_CURRENT_TAB_MAP: Record<
  LegacyTextbookSubtopicTab,
  SubtopicTab
> = {
  theory: 'lesson',
  teacherNotes: 'lesson',
  interactive: 'interactive',
  engageActivity: 'engageActivity',
  worksheet: 'worksheet',
};

const CORE_TO_CURRENT_TAB_MAP: Record<CoreTextbookSubtopicTab, SubtopicTab> = {
  solidifyLesson: 'lesson',
  subtopicOverview: 'overview',
  engageActivity: 'engageActivity',
  interactive: 'interactive',
  worksheet: 'worksheet',
};

function validateTab<TabType extends string | null>(
  validTabs: readonly TabType[],
) {
  return function (maybeTab: string | null | undefined): maybeTab is TabType {
    return validTabs.find(tab => tab === maybeTab) != null;
  };
}

export function useTextbookTabsState() {
  // The state of the textbook tabs is encoded in the URL query params.
  // When textbook is used inside the MultiTextbookSearchModal,
  // we don't want the tab switching to change URL query params.
  // Instead, we want to keep the tab state in the MultiTextbookSearchModalContext.

  const {
    isInsideMultiTextbookSearchModal,
    activeSubtopicTab: activeTabInMTS,
    setActiveSubtopicTab: setActiveTabInMTS,
  } = useMultiTextbookSearchModalContext();

  const [activeTabQueryParam, setActiveTabQueryParam, getTabLink] =
    useQueryParam<SubtopicTab | null>(
      SUBTOPIC_TAB_QUERY_PARAM_NAME,
      // This ensures no query param is set when inside the MultiTextbookSearchModal
      isInsideMultiTextbookSearchModal ? null : DEFAULT_SUBTOPIC_TAB,
      validateTab([...SUBTOPIC_TABS, null]),
    );

  const activeTab = isInsideMultiTextbookSearchModal
    ? activeTabInMTS
    : activeTabQueryParam ?? DEFAULT_SUBTOPIC_TAB;
  const setActiveTab = isInsideMultiTextbookSearchModal
    ? setActiveTabInMTS
    : setActiveTabQueryParam;

  return [activeTab, setActiveTab, getTabLink] as const;
}

function useMaybeLegacyTextbookTabsState() {
  return useQueryParam<LegacyTextbookSubtopicTab | null>(
    LEGACY_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME,
    null,
    validateTab([...LEGACY_TEXTBOOK_SUBTOPIC_ACTIVE_TAB, null]),
  );
}

function useMaybeCoreTextbookTabsState() {
  return useQueryParam<CoreTextbookSubtopicTab | null>(
    CORE_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME,
    null,
    validateTab([...CORE_TEXTBOOK_SUBTOPIC_ACTIVE_TAB, null]),
  );
}

function useTextbookTabsRedirects() {
  // The state of the textbook tabs is encoded in the URL query params.
  // When textbook is used inside the MultiTextbookSearchModal,
  // we don't want the tab switching to change URL query params.
  // Instead, we want to keep the tab state in the MultiTextbookSearchModalContext.
  const {
    isInsideMultiTextbookSearchModal,
    setActiveSubtopicTab: setActiveTabInMTS,
  } = useMultiTextbookSearchModalContext();
  const [legacyTab] = useMaybeLegacyTextbookTabsState();
  const [coreTab] = useMaybeCoreTextbookTabsState();

  const history = useHistory();
  const searchString = history.location.search;
  const urlParams = useMemo(
    () => new URLSearchParams(searchString),
    [searchString],
  );

  const tabToRedirectTo =
    legacyTab != null
      ? LEGACY_TO_CURRENT_TAB_MAP[legacyTab]
      : coreTab != null
      ? CORE_TO_CURRENT_TAB_MAP[coreTab]
      : null;

  useEffect(() => {
    if (tabToRedirectTo != null) {
      if (isInsideMultiTextbookSearchModal) {
        setActiveTabInMTS(tabToRedirectTo);
      } else {
        urlParams.delete(LEGACY_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME);
        urlParams.delete(CORE_TEXTBOOK_SUBTOPIC_TAB_QUERY_PARAM_NAME);
        urlParams.set(SUBTOPIC_TAB_QUERY_PARAM_NAME, tabToRedirectTo);
        history.replace({
          search: urlParams.toString(),
        });
      }
    }
  }, [
    urlParams,
    history,
    tabToRedirectTo,
    isInsideMultiTextbookSearchModal,
    setActiveTabInMTS,
  ]);
}

export default function useTextbookTabs(
  tabConfig: Record<
    SubtopicTab,
    {
      shouldRender: boolean;
      fallbacks?: SubtopicTab[];
    }
  >,
) {
  const [activeTab, setActiveTab, getTabLink] = useTextbookTabsState();

  useTextbookTabsRedirects();

  useTabsFallback<SubtopicTab>(tabConfig, { activeTab, setActiveTab });

  return [activeTab, setActiveTab, getTabLink] as const;
}

export const SUBTOPIC_PRACTICE_TABS = ['interactive', 'worksheet'] as const;
//  I would like to use `satisfies satisfies [ SubtopicTab, SubtopicTab]` but babel breaks. Yet, the installed version of @babel/preset-typescript in the project is 7.21.5 and it should support it https://github.com/babel/babel/commit/df733b18ae88f370caddecc30c6d96844007c411
export type SubtopicPracticeTab = (typeof SUBTOPIC_PRACTICE_TABS)[number];

export function useSubtopicLessonTabsState() {
  // The state of the textbook tabs is encoded in the URL query params.
  // When textbook is used inside the MultiTextbookSearchModal,
  // we don't want the tab switching to change URL query params.
  // Instead, we want to keep the state in the MultiTextbookSearchModalContext.
  const {
    isInsideMultiTextbookSearchModal,
    activeSubtopicLessonTab: activeTabInMTS,
    setActiveSubtopicLessonTab: setActiveTabInMTS,
  } = useMultiTextbookSearchModalContext();

  const [activeTabQueryParam, setActiveTabQueryParam, getTabLink] =
    useQueryParam<SubtopicLessonTab | null>(
      'activeLessonTab',
      // This ensures no query param is set when inside the MultiTextbookSearchModal
      isInsideMultiTextbookSearchModal ? null : DEFAULT_SUBTOPIC_LESSON_TAB,
      validateTab([...SUBTOPIC_LESSON_TABS, null]),
    );

  const activeTab = isInsideMultiTextbookSearchModal
    ? activeTabInMTS
    : activeTabQueryParam ?? DEFAULT_SUBTOPIC_LESSON_TAB;
  const setActiveTab = isInsideMultiTextbookSearchModal
    ? setActiveTabInMTS
    : setActiveTabQueryParam;
  return [activeTab, setActiveTab, getTabLink] as const;
}

export function useSubtopicLessonTabs(
  tabConfig: Record<
    SubtopicLessonTab,
    {
      shouldRender: boolean;
      fallbacks?: SubtopicLessonTab[];
    }
  >,
) {
  const [activeTab, setActiveTab, getTabLink] = useSubtopicLessonTabsState();
  const [, setSubtopicTab] = useTextbookTabsState();
  useTabsFallback<SubtopicLessonTab>(tabConfig, {
    activeTab,
    setActiveTab,
    handleNoAvailableTabs: () => {
      // This is not available in student textbook, but the fallback logic will correctly select the practice tab
      setSubtopicTab('overview');
    },
  });

  return [activeTab, setActiveTab, getTabLink] as const;
}

const TOPIC_TABS = ['overview', 'assessment'] as const;
export type TopicTab = (typeof TOPIC_TABS)[number];

export function useTopicTabsState() {
  return useQueryParam<TopicTab>(
    // we may want to rename the query param name as done for the subtopic tabs
    // in case this needs to be addressed in the future, we can use the same approach as for the subtopic tabs (useTextbookTabsRedirects)
    'activeTopicContentTab',
    'overview',
    validateTab(TOPIC_TABS),
  );
}

export const TOPIC_ASSESSMENT_SUBTABS = ['worksheet', 'answer-key'] as const;
export type TopicAssessmentSubtab = (typeof TOPIC_ASSESSMENT_SUBTABS)[number];

export function useTopicAssessmentSubtabsState() {
  return useQueryParam<TopicAssessmentSubtab>(
    'activeTopicContentSubtab',
    'worksheet',
    validateTab(TOPIC_ASSESSMENT_SUBTABS),
  );
}

export function useTopicTabs(
  tabConfig: Record<
    TopicTab,
    {
      shouldRender: boolean;
      fallbacks?: TopicTab[];
    }
  >,
) {
  const [activeTab, setActiveTab, getTabLink] = useTopicTabsState();

  useTabsFallback<TopicTab>(tabConfig, {
    activeTab,
    setActiveTab,
  });

  return [activeTab, setActiveTab, getTabLink] as const;
}

export function useTopicAssessmentSubTabs(
  tabConfig: Record<
    TopicAssessmentSubtab,
    {
      shouldRender: boolean;
      fallbacks?: TopicAssessmentSubtab[];
    }
  >,
) {
  const [activeTab, setActiveTab, getTabLink] =
    useTopicAssessmentSubtabsState();

  const [, setTopicTab] = useTopicTabsState();

  useTabsFallback<TopicAssessmentSubtab>(tabConfig, {
    activeTab,
    setActiveTab,
    handleNoAvailableTabs: () => {
      setTopicTab('overview');
    },
  });

  return [activeTab, setActiveTab, getTabLink] as const;
}
