import { zipWith, flatten as rFlatten } from 'ramda';

// NOTE we have to use Array, not ReadonlyArray as TS chokes on ReadonlyArray
// in a recursive type like this for some reason and loses all the type
// information. Sigh.
export type Dataset<T> = Array<T | Dataset<T>>;

export function map<A, B>(
  dataset: Dataset<A>,
  mapper: (item: A) => B,
): B | Dataset<B> {
  return dataset.map(child => mapHelper(child, mapper));
}

function mapHelper<A, B>(
  item: A | Dataset<A>,
  mapper: (item: A) => B,
): B | Dataset<B> {
  if (Array.isArray(item)) {
    return item.map(child => mapHelper(child, mapper));
  } else {
    return mapper(item);
  }
}

export function zip<A, B, C>(
  a: Dataset<A>,
  b: Dataset<B>,
  zipper: (a: A, b: B) => C,
): C | Dataset<C> {
  return zipWith((_a, _b) => zipHelper(_a, _b, zipper), a, b);
}

function zipHelper<A, B, C>(
  a: A | Dataset<A>,
  b: B | Dataset<B>,
  zipper: (a: A, b: B) => C,
): C | Dataset<C> {
  if (Array.isArray(a) && Array.isArray(b)) {
    return zipWith((_a, _b) => zipHelper(_a, _b, zipper), a, b);
  } else if (!Array.isArray(a) && !Array.isArray(b)) {
    return zipper(a, b);
  } else {
    throw new Error('dataset.zip cannot zip datasets with different shapes!');
  }
}

export function flatten<T>(dataset: Dataset<T>): ReadonlyArray<T> {
  if (!Array.isArray(dataset)) return [dataset];
  return rFlatten(dataset);
}
