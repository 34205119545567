import type { ReactNode } from 'react';

import { VStack } from 'ms-pages/Lantern/primitives/Stack';
import {
  LARGE_PAGE_PADDING,
  PAGE_PADDING,
  MOBILE_PAGE_PADDING,
} from 'ms-pages/Lantern/styles';

export default function Main({
  children,
  overflowX = false,
  hasLargePadding = false,
  hasMobilePadding = false,
}: {
  children: ReactNode;
  overflowX?: boolean | undefined;
  hasLargePadding?: boolean | undefined;
  hasMobilePadding?: boolean | undefined;
}) {
  return (
    <VStack
      css={[
        {
          flexGrow: 1,
          padding: hasMobilePadding
            ? MOBILE_PAGE_PADDING
            : hasLargePadding
            ? LARGE_PAGE_PADDING
            : PAGE_PADDING,
        },
        overflowX ? { overflowX: 'auto' } : {},
      ]}
    >
      {children}
    </VStack>
  );
}
