import { useState, useCallback } from 'react';

import { createCustomTaskFromTaskTemplateVariables } from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state';
import type { State } from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state/State';
import type {
  createCustomTaskFromTaskTemplateMutation,
  createCustomTaskFromTaskTemplateMutationResponse,
} from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state/__generated__/createCustomTaskFromTaskTemplateMutation.graphql';
import { customTaskFromTaskTemplateMutation } from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state/createCustomTaskFromTaskTemplate';
import { InvariantViolation } from 'ms-utils/app-logging';
import { promiseMutation } from 'ms-utils/relay/promiseMutation';

type Task = NonNullable<
  createCustomTaskFromTaskTemplateMutationResponse['createCustomTaskFromTaskTemplate']['task']
>;
type TaskTemplateId = string;
type Arguments = {
  taskTemplatesIds: readonly TaskTemplateId[];
  getState: (taskTemplateId: string) => State;
  onError: (error: Error) => void;
  onSave: (taskTemplateId: string, task: Task) => void;
  onComplete?: (() => void) | undefined;
};
export default function useCreateBulkCustomTask({
  taskTemplatesIds,
  getState,
  onError,
  onSave,
  onComplete,
}: Arguments) {
  const [running, setRunning] = useState(false);
  return useCallback(async () => {
    if (running === true) return;
    setRunning(true);
    for (const taskTemplateId of taskTemplatesIds) {
      try {
        const variables = getState(taskTemplateId);
        const response =
          await promiseMutation<createCustomTaskFromTaskTemplateMutation>({
            mutation: customTaskFromTaskTemplateMutation,
            variables: createCustomTaskFromTaskTemplateVariables(variables, {
              sourceTaskTemplateId: taskTemplateId,
            }),
          });
        const task = response.createCustomTaskFromTaskTemplate.task;
        if (task == null)
          onError(
            new InvariantViolation('A task failed to save, please try again.'),
          );
        else if (response.createCustomTaskFromTaskTemplate.errors.length) {
          onError(
            new InvariantViolation('A task failed to save, please try again.'),
          );
        } else onSave(taskTemplateId, task);
      } catch (e) {
        onError(
          new InvariantViolation('Unable to create tasks. Please try again'),
        );
      }
    }
    if (onComplete != null) onComplete();
    setRunning(false);
  }, [getState, onComplete, onError, onSave, running, taskTemplatesIds]);
}
