// KeyboardEvent.key values:
// https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
import type {
  KeyboardEvent as ReactKeyboardEvent,
  SyntheticEvent,
} from 'react';

import type { Values } from './typescript-utils';

const keyCodeMap: { [K in ReactKeyboardEvent['key']]: string } = {
  Tab: 'TAB',
  ' ': 'SPACE', // Yes, the space key's KeyboardEvent.key value is ' '
  Escape: 'ESC',
  Enter: 'ENTER',
  ArrowLeft: 'ARROW_LEFT',
  ArrowUp: 'ARROW_UP',
  ArrowRight: 'ARROW_RIGHT',
  ArrowDown: 'ARROW_DOWN',
  j: 'J',
  k: 'K',
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
};

type KeyValues = Values<typeof keyCodeMap>;

/*
 * We are attempting to fit a keyboard event sized peg into a
 * mouse event sized hole. Not a good idea, but for most of our use
 * cases it works.
 * As a mitigation we coerce the KeyboardEvent into a SyntheticEvent.
 * That way, if we ever need to access MouseEvent specific properties,
 * we will get a warning and be able to provide a type assertion
 * rather than let the app explode.
 */
export type Action<T> = (e: SyntheticEvent<T>) => void;

type Options = {
  stopPropagation?: boolean | undefined;
  preventDefault?: boolean | undefined;
};

export default function keyDownMap<T>(map: {
  [K in KeyValues]: Action<T> | [Action<T>, Options];
}) {
  return (e: ReactKeyboardEvent) => {
    const keyCode = e.key;
    const keyName = keyCodeMap[keyCode];
    let handler = keyName != null ? map[keyName] : null;
    if (typeof handler === 'function') {
      handler(e as unknown as SyntheticEvent<T>);
    }
    if (Array.isArray(handler)) {
      const [callback, { stopPropagation = false, preventDefault = false }] =
        handler;
      if (stopPropagation) {
        e.stopPropagation();
      }
      if (preventDefault) {
        e.preventDefault();
      }
      callback(e as unknown as SyntheticEvent<T>);
    }
  };
}
