/**
 * @module ramda-extensions
 * @desc
 * A module for composite data transformations not provided out-of-the-box by
 * Ramda.
 */

import { contains, curry, keys, omit } from 'ramda';

const rebindKeyBase = <K extends string, T extends { [K: string]: unknown }>(
  key: K,
  newKey: string,
  obj: T,
): {} => {
  if (!contains(key, keys(obj))) return obj;
  return {
    [newKey]: obj[key],
    ...omit([key])(obj),
  };
};

export const rebindKey = curry(rebindKeyBase);
