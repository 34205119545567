import moment from 'moment';

export type DateISOString = string;

/**
 * Check if a Date object is a valid date.
 * The logic of this method could be improved. e.g.
 * atm, `isValidDate('2020')` is `true`.
 *
 * @param {Date} date A Date object.
 * @returns {boolean}
 */
export const isValidDate = (date: Date): boolean =>
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date.getTime());

/**
 * Check if a string can be converted to a valid date.
 *
 * @param {Date} s A string.
 * @returns {boolean}
 */
export const isValidDateString = (s: string | null | undefined): boolean =>
  s != null ? isValidDate(new Date(s)) : false;

/**
 * Make start of day for a date.
 *
 * @param {Date} date A Date object.
 * @returns {Date}
 */
export const makeStartOfDay = (date: Date): Date =>
  moment(date).startOf('day').toDate();

/**
 * Make end of day for a date.
 *
 * @param {Date} date A Date object.
 * @returns {Date}
 */
export const makeEndOfDay = (date: Date): Date =>
  moment(date).endOf('day').toDate();
