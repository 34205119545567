import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function TwitterRoundIcon({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={24}
      viewBoxWidth={24}
    >
      <circle cx="12" cy="12" r="12" />
      <path
        d="M18.384 8.0318C17.904 8.2398 17.408 8.3838 16.88 8.4478C17.424 8.1278 17.84 7.6158 18.032 6.9918C17.52 7.2958 16.96 7.5038 16.368 7.6318C15.888 7.1198 15.2 6.7998 14.448 6.7998C12.992 6.7998 11.824 7.9838 11.824 9.4238C11.824 9.6318 11.84 9.8238 11.888 10.0158C9.71199 9.9198 7.79199 8.8638 6.49599 7.2798C5.56798 8.9438 6.60799 10.3198 7.29599 10.7838C6.87999 10.7838 6.46399 10.6558 6.11198 10.4638C6.11198 11.7598 7.02399 12.8318 8.20799 13.0718C7.95199 13.1518 7.37599 13.1998 7.02399 13.1198C7.35999 14.1598 8.33599 14.9278 9.47199 14.9438C8.57599 15.6478 7.26399 16.2078 5.58398 16.0318C6.75199 16.7838 8.12799 17.2158 9.61599 17.2158C14.448 17.2158 17.072 13.2158 17.072 9.7598C17.072 9.6478 17.072 9.5358 17.056 9.4238C17.6 9.0238 18.048 8.5598 18.384 8.0318Z"
        fill="white"
      />
    </Icon>
  );
}
