import styled from '@emotion/styled';
import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { PAGE_PADDING } from 'ms-components/Layout/styles';
import {
  BaseTabs,
  InnerTab,
  TAB_CONTENT_PADDING_VERTICAL,
} from 'ms-components/Tabs';
import { fontSize, fontWeight, transition, lineHeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable, onPressOrHover } from 'ms-utils/emotion';

const BORDER_HEIGHT = 4;
const GUTTER = 8 * BASE_UNIT;

export const Root = styled.div({
  margin: `${-PAGE_PADDING}px ${-PAGE_PADDING}px 0`,
  padding: `${PAGE_PADDING}px ${PAGE_PADDING}px 0`,
  backgroundColor: colors.white,
});

export const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const Title = styled.div({
  color: colors.cloudBurst,
  fontSize: fontSize.large,
  fontWeight: fontWeight.semibold,
  lineHeight: '26px',
});

export const ClassDisplayName = styled.div({
  color: colors.grey90,
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal,
});

export const Info = styled.div({
  color: colors.shuttleGray,
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semibold,
});

export const Tabs = styled(BaseTabs)({
  color: colors.cloudBurst,
  borderBottomColor: 'transparent',
  borderBottomWidth: BORDER_HEIGHT,
});

export const TabContent = styled.div({
  transition: `color ${transition}`,
  paddingTop: TAB_CONTENT_PADDING_VERTICAL,
  paddingBottom: TAB_CONTENT_PADDING_VERTICAL / 2,
  fontSize: fontSize.medium,
  lineHeight: lineHeight.title,
  whiteSpace: 'nowrap',
});

const tabWrapperStyle = {
  default: {
    ...tappable,
    position: 'relative',
    display: 'inline-block',
    transform: `translateY(${BORDER_HEIGHT / 2}px)`,
    textDecoration: 'none',
    color: 'inherit',
    marginRight: GUTTER,

    ':nth-child(1n) > *': {
      borderBottom: `${BORDER_HEIGHT}px solid transparent`,
    },
  },
  active: {
    color: colors.eggplant,
    ...onPressOrHover({
      color: alternateColors.eggplant,
    }),

    ':nth-child(1n) > *': {
      borderColor: 'currentColor',
    },
  },
} as const;

const navLinkTabStyles = StyleSheet.create(tabWrapperStyle);

export const Tab = ({
  children,
  active,
}: {
  children: ReactNode;
  active: boolean;
}) => (
  <div
    className={css(navLinkTabStyles.default, active && navLinkTabStyles.active)}
  >
    <InnerTab>{children}</InnerTab>
  </div>
);

type Location = {
  pathname: string;
  search: string;
  hash: string;
  state: unknown;
  key: string;
};

export const NavLinkTab = ({
  to,
  children,
  trackingId,
}: {
  children: ReactNode;
  // TODO Replace with LinkProps['to'] once TS refactor is done
  // Add import type { LinkProps } from 'react-router-dom';
  to: string | Location;
  trackingId?: string | undefined;
}) => (
  <NavLink
    to={to}
    className={css(navLinkTabStyles.default)}
    activeClassName={css(navLinkTabStyles.active)}
    data-tracking-id={trackingId}
  >
    <InnerTab>{children}</InnerTab>
  </NavLink>
);
