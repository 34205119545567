import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useBoolean } from './useBoolean';
import useWindowSize from './useWindowSize';

// NB this is currently used for horizontal scrolling only
// but could be easily extended to support vertical scrolling as well
export default function useScroll(
  sentinelValue: unknown = undefined, // This is a sentinel value that can be used to force the hook to recompute its state
) {
  const isScrollable = useBoolean();
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);
  const syncScroll = useCallback<(e: Event) => void>(e => {
    if (!(e.target instanceof HTMLElement)) return;
    setScrollLeft(e.target.scrollLeft);
    setScrollRight(
      e.target.scrollWidth - e.target.clientWidth - e.target.scrollLeft,
    );
  }, []);
  const doSmoothScrollOnLeft = useCallback(() => {
    const scrollableRefEl = scrollableWrapperRef.current;
    if (scrollableRefEl) {
      scrollableRefEl.scroll({
        left: scrollableRefEl.scrollLeft - 100,
        behavior: 'smooth',
      });
    }
  }, []);
  const doSmoothScrollOnRight = useCallback(() => {
    const scrollableRefEl = scrollableWrapperRef.current;
    if (scrollableRefEl) {
      scrollableRefEl.scroll({
        left: scrollableRefEl.scrollLeft + 100,
        behavior: 'smooth',
      });
    }
  }, []);
  const { width: windowWidth } = useWindowSize();
  const scrollableWrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const scrollableRefEl = scrollableWrapperRef.current;
    if (scrollableRefEl) {
      const { clientWidth, scrollWidth, scrollLeft } = scrollableRefEl;
      if (clientWidth < scrollWidth) {
        setScrollLeft(scrollLeft);
        setScrollRight(scrollWidth - clientWidth - scrollLeft);
        isScrollable.setTrue();
      } else {
        isScrollable.setFalse();
        setScrollLeft(0);
        setScrollRight(0);
      }
    }
  }, [isScrollable, windowWidth, sentinelValue]);
  useEffect(() => {
    const scrollableRefEl = scrollableWrapperRef.current;
    if (scrollableRefEl) {
      scrollableRefEl.addEventListener('scroll', syncScroll);
      return () => {
        scrollableRefEl.removeEventListener('scroll', syncScroll);
      };
    }
    return;
  }, [syncScroll]);
  return useMemo(
    () => ({
      isScrollable,
      scrollLeft,
      scrollRight,
      doSmoothScrollOnLeft,
      doSmoothScrollOnRight,
      scrollableWrapperRef,
    }),
    [
      doSmoothScrollOnLeft,
      doSmoothScrollOnRight,
      isScrollable,
      scrollLeft,
      scrollRight,
    ],
  );
}
