import type { CombinedState } from 'ms-pages/Teacher/components/CreateTask/CreateWorksheetTask/state';
import {
  Asterisk,
  FieldWrapper,
  Label,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';

import TaskNameInput from '../components/TaskNameInput';

export { useCreateWorksheetTaskState } from './state';

type Props = {
  state: CombinedState;
};

export default function WorksheetTaskDetailsForm({ state }: Props) {
  const {
    taskName,
    taskStartDate,
    taskDueDate,
    selectedStudents,
    selectedClasses,
    excludedStudents,
  } = state.values;
  const {
    updateTaskName,
    updateTaskStartDate,
    updateTaskDueDate,
    setSelectedClasses,
    setSelectedStudents,
    setExcludedStudents,
  } = state.updaters;

  return (
    <TaskCreationFormWrapper>
      <FieldWrapper>
        <Label>
          Task name<Asterisk> *</Asterisk>
        </Label>
        <TaskNameInput value={taskName} onChange={updateTaskName} />
      </FieldWrapper>
      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        <TaskDatePicker
          value={[taskStartDate, taskDueDate]}
          onChange={([startDate, endDate]) => {
            if (startDate != null) updateTaskStartDate(startDate);
            if (endDate != null) updateTaskDueDate(endDate);
          }}
        />
      </FieldWrapper>

      <FieldWrapper>
        <TaskAssignment
          selectedClasses={selectedClasses}
          selectedStudents={selectedStudents}
          excludedStudents={excludedStudents}
          onChangeClasses={setSelectedClasses}
          onChangeStudents={setSelectedStudents}
          onChangeExcludedStudents={setExcludedStudents}
        />
      </FieldWrapper>
    </TaskCreationFormWrapper>
  );
}
