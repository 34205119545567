import styled from '@emotion/styled';

import { LinkButton } from 'ms-pages/Lantern/primitives/Button';
import { font } from 'ms-styles/base';
import Button from 'ms-ui-primitives/Button';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';
import { VSpacer, VStack } from 'ms-ui-primitives/Stack';
import { signupSunflowerPath } from 'ms-utils/urls';

import checkinRecoImage from './assets/checkinRecommendationSignup.svg';
import checkinSignupImage from './assets/checkinSignupImage.svg';

const Title = styled.h1({
  ...font.gilroyLargeHeading,
  textAlign: 'center',
});

const Description = styled.p({
  textAlign: 'center',
});

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export function CheckInSignup({ isOpen, onClose }: Props) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      theme="dark"
      width={600}
      showCloseButton
    >
      <ModalBody>
        <VStack center>
          <img src={checkinSignupImage} alt="student check in reports" />
          <VSpacer height={24} />
          <Title>Student check-ins help you track skill knowledge gaps</Title>
          <VSpacer height={24} />
          <Description>
            Sign up to get access to Mathspace where you can view reports,
            analyse student gaps to see how you can help your students grow
          </Description>
          <VSpacer height={24} />
          <LinkButton
            onClick={onClose}
            to={signupSunflowerPath}
            label="Sign up for free"
          >
            <Button
              size="large"
              type="primary"
              color="eggplant"
              trackingId="DemoStudent/CheckInSignup/SignUpForFree"
            >
              Sign up for free
            </Button>
          </LinkButton>
        </VStack>
      </ModalBody>
    </Modal>
  );
}

export function CheckInSignupRecommendation({ isOpen, onClose }: Props) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      theme="dark"
      width={600}
      showCloseButton
    >
      <ModalBody>
        <VStack center>
          <img src={checkinRecoImage} alt="student check in reports" />
          <VSpacer height={24} />
          <Title>
            Recommendations are personalised to help students grow their skills
          </Title>
          <VSpacer height={24} />
          <Description>
            Sign up to get access to Mathspace where you can view reports,
            analyse student gaps to see how you can help your students grow
          </Description>
          <VSpacer height={24} />
          <LinkButton
            onClick={onClose}
            to={signupSunflowerPath}
            label="Sign up for free"
          >
            <Button
              size="large"
              type="primary"
              color="eggplant"
              trackingId="DemoStudent/CheckInSignupRecommendation/SignUpForFree"
            >
              Sign up for free
            </Button>
          </LinkButton>
        </VStack>
      </ModalBody>
    </Modal>
  );
}
