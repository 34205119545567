import { StyleSheet, css } from 'aphrodite';

import Latex from 'ms-components/Latex';
import { fontFamily, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

type Props = {
  readonly maxWidth?: number;
  readonly selectedReason: string | null | undefined;
  readonly statement: string;
};

const VERTICAL_GUTTER = 7.5;

// TODO: Review these styles with UX in mind.
const styles = StyleSheet.create({
  instruction: {
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    margin: `${VERTICAL_GUTTER}px 0`,
  },
  statement: {
    margin: `${VERTICAL_GUTTER}px 0`,
  },
});

const GeometryReasonReadOnly = ({
  maxWidth = 600,
  statement,
  selectedReason,
}: Props) => (
  <div style={{ maxWidth }}>
    <div className={css(styles.instruction)}>State your reason:</div>
    <div className={css(styles.statement)}>
      <Latex latex={statement} />
    </div>
    <div className={css(styles.instruction)}>({selectedReason})</div>
  </div>
);

export default GeometryReasonReadOnly;
