import { useEffect } from 'react';

import type { TypedRive } from './useTypedRive';

// Helper hook to set the value of a text run in a Rive instance
export function useTextRunEffect(
  rive: TypedRive | null,
  textRunName: string,
  textRunValue: string | null | undefined,
) {
  useEffect(() => {
    if (textRunValue == null) return;
    rive?.setTextRunValue(textRunName, textRunValue);
  }, [rive, textRunName, textRunValue]);
}
