import {
  type ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import type { AppEnv } from 'ms-helpers/AppEnv';
import { AppEnvRenderer } from 'ms-helpers/AppEnv';
import { getAppName, isNativeAppHost } from 'ms-helpers/AppEnv/utils';
import { Logger } from 'ms-utils/app-logging';

import type {
  CustomStructEvent,
  SupportedStructEvent,
  TrackStructEvent,
  TrackPageView,
  EnableActivityTracking,
  ActivityTrackingOptions,
  SetUserId,
  UserId,
} from '../Types';

export type SnowplowContextType = {
  trackPageView: TrackPageView;
  trackStructEvent: TrackStructEvent;
  enableActivityTracking: EnableActivityTracking;
  setUserId: SetUserId;
};

const defaultSnowplowContextType = {
  trackPageView: () => {
    Logger.error(
      'trackPageView failed - Snowplow is missing the provider above in the render tree',
    );
  },
  trackStructEvent: () => {
    Logger.error(
      'trackStructEvent failed - Snowplow is missing the provider above in the render tree',
    );
  },
  enableActivityTracking: () => {
    Logger.error(
      'enableActivityTracking failed - Snowplow is missing the provider above in the render tree',
    );
  },
  setUserId: () => {
    Logger.error(
      'setUserId failed - Snowplow is missing the provider above in the render tree',
    );
  },
};

export const SnowplowContext = createContext<SnowplowContextType>(
  defaultSnowplowContextType,
);

// Nullable fields can not be `undefined` as it is not a valid type in JSON schema
type AppEnvContextData = {
  device_type: string;
  device_os: string | null;
  app_name: string;
  react_native_app_binary_version: string | null;
  react_native_app_code_push_version: string | null;
  web_app_runtime: string | null;
  web_app_version: string;
  application_environment: string;
};

const makeAppEnvContextData = (appEnv: AppEnv): AppEnvContextData => {
  const {
    DEVICE: { TYPE, OS },
    RUNTIME: { TYPE: RUNTIME_TYPE, VERSION: RUNTIME_VERSION },
    WEB_APP_VERSION,
    APPLICATION_ENVIRONMENT,
    REACT_NATIVE_APP,
  } = appEnv;

  return {
    device_type: TYPE,
    device_os: OS || null,
    app_name: getAppName(appEnv),
    react_native_app_binary_version: REACT_NATIVE_APP?.binaryVersion || null,
    react_native_app_code_push_version:
      REACT_NATIVE_APP?.codePushVersion || null,
    web_app_runtime: !isNativeAppHost(appEnv)
      ? `${RUNTIME_TYPE} ${RUNTIME_VERSION || ''}`
      : null,
    web_app_version: WEB_APP_VERSION,
    application_environment: APPLICATION_ENVIRONMENT,
  };
};

type PublicProps = {
  children: ReactNode;
  snowplow: (arg0: string, ...args: Array<any>) => void;
};

type Props = PublicProps & {
  appEnv: AppEnv;
};

function SnowplowProvider({ children, snowplow, appEnv }: Props) {
  const hasLoggedErrorRef = useRef(false);
  const globalContextsHashRef = useRef<string | null>(null);

  const reportSnowplowMethodCall = useCallback(
    <M extends keyof SnowplowContextType>(
      method: M | 'addGlobalContexts',
      ...args: Parameters<SnowplowContextType[M]>
    ) => {
      if (!hasLoggedErrorRef.current) {
        Logger.error(
          [
            'Snowplow is not setup correctly, so no events will be sent.',
            'They will be logged to the console instead.',
          ].join(' '),
        );
        hasLoggedErrorRef.current = true;
      }
      try {
        const logParts: unknown[] = [
          `❄️ Snowplow.${method}() called, but message was not sent.`,
        ];
        if (args.length > 0) {
          logParts.push('Args:', ...args);
        }
        // eslint-disable-next-line no-console
        console.log(...logParts);
      } catch (e) {
        // swallow errors if console.log() fails
      }
    },
    [],
  );

  const trackPageView: TrackPageView = useCallback(() => {
    if (typeof snowplow === 'undefined') {
      reportSnowplowMethodCall('trackPageView');
    } else {
      snowplow('trackPageView');
    }
  }, [reportSnowplowMethodCall, snowplow]);

  const trackStructEvent: TrackStructEvent = useCallback(
    (event: SupportedStructEvent) => {
      const { category, action, label, property, value } =
        event as Partial<CustomStructEvent>;
      if (typeof snowplow === 'undefined') {
        reportSnowplowMethodCall('trackStructEvent', event);
      } else {
        snowplow('trackStructEvent', category, action, label, property, value);
      }
    },
    [reportSnowplowMethodCall, snowplow],
  );

  const enableActivityTracking: EnableActivityTracking = useCallback(
    (options: ActivityTrackingOptions) => {
      if (typeof snowplow === 'undefined') {
        reportSnowplowMethodCall('enableActivityTracking', options);
      } else {
        snowplow(
          'enableActivityTracking',
          options.minimumVisitLength,
          options.heartBeat,
        );
      }
    },
    [reportSnowplowMethodCall, snowplow],
  );

  const setUserId: SetUserId = useCallback(
    (userId: UserId) => {
      if (typeof snowplow === 'undefined') {
        reportSnowplowMethodCall('setUserId', userId);
      } else {
        snowplow('setUserId', userId);
      }
    },
    [reportSnowplowMethodCall, snowplow],
  );

  const addGlobalContexts = useCallback(() => {
    if (typeof snowplow === 'undefined') {
      reportSnowplowMethodCall('addGlobalContexts');
      return;
    }

    const globalContexts = [
      {
        schema: 'iglu:co.mathspace/app_env/jsonschema/1-0-0',
        data: makeAppEnvContextData(appEnv),
      },
    ];

    // In the upcoming async mode the constructor might be called multiple times
    // (this is already happening in development mode since we are using StrictMode).
    // Therefore we need to ensure that all global contexts are set once only.
    const globalContextsHash = JSON.stringify(globalContexts);

    if (globalContextsHashRef.current == null) {
      // No global context has been assigned yet
      snowplow('addGlobalContexts', globalContexts);
      globalContextsHashRef.current = globalContextsHash;
    } else if (globalContextsHash !== globalContextsHashRef.current) {
      // The global contexts have been previously assigned
      // but the values are different this time, however
      // we expect the global context to only contain static data.
      Logger.error(
        'Invalid application state: SnowplowProvider has received a different global context than expected',
        {
          extra: {
            receivedContext: globalContextsHash,
            expectedContext: globalContextsHashRef.current,
          },
        },
      );
    }
  }, [appEnv, reportSnowplowMethodCall, snowplow]);

  useEffect(() => {
    addGlobalContexts();
  }, [addGlobalContexts]);

  const contextValue = useMemo(
    () => ({
      trackPageView,
      trackStructEvent,
      enableActivityTracking,
      setUserId,
    }),
    [trackPageView, trackStructEvent, enableActivityTracking, setUserId],
  );

  return (
    <SnowplowContext.Provider value={contextValue}>
      {children}
    </SnowplowContext.Provider>
  );
}

const SnowplowProviderWithAppEnv = ({ children, snowplow }: PublicProps) => (
  <AppEnvRenderer
    render={({ appEnv }: { appEnv: AppEnv }) => (
      <SnowplowProvider snowplow={snowplow} appEnv={appEnv}>
        {children}
      </SnowplowProvider>
    )}
  />
);

export { makeAppEnvContextData as makeAppEnvSnowplowContextData };
export default SnowplowProviderWithAppEnv;
