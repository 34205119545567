import { useEffect } from 'react';
import { graphql } from 'react-relay';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import Center from 'ms-ui-primitives/Center';
import LoadingSpinner from 'ms-ui-primitives/LoadingSpinner';
import Page from 'ms-ui-primitives/Page';
import { InvariantViolation } from 'ms-utils/app-logging';
import { getWorkoutUrl } from 'ms-utils/urls';

import type { SelfDirectedTaskRedirectorQuery } from './__generated__/SelfDirectedTaskRedirectorQuery.graphql';
import type { SubstrandSubtopicRecommendationCategory } from './__generated__/useCreateSelfDirectedTaskMutation.graphql';
import useCreateSelfDirectedTask from './useCreateSelfDirectedTask';

function Loading() {
  return (
    <Page>
      <Center>
        <LoadingSpinner />
      </Center>
    </Page>
  );
}
function WorkoutRedirector({
  workoutId,
  isSubtopicRecommendation,
}: {
  workoutId: string;
  isSubtopicRecommendation: boolean;
}) {
  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      getWorkoutUrl(workoutId, '', isSubtopicRecommendation),
    );
    window.location.reload();
  }, [isSubtopicRecommendation, workoutId]);
  return null;
}
function SelfDirectedTaskFromSubtopicCreator({
  token,
  isSubtopicRecommendation,
  recommendationCategory,
}: {
  token: string;
  isSubtopicRecommendation: boolean;
  recommendationCategory: SubstrandSubtopicRecommendationCategory | null;
}) {
  const [createSelfDirectedTask, { response, loading, errors }] =
    useCreateSelfDirectedTask({
      token,
      recommendationCategory:
        isSubtopicRecommendation && recommendationCategory != null
          ? recommendationCategory
          : null,
    });
  useEffect(createSelfDirectedTask, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (response != null) {
    const {
      createWorkoutFromToken: { workout },
    } = response;
    if (workout == null) {
      return <Retry retry={() => window.location.reload()} />;
    }
    return (
      <WorkoutRedirector
        workoutId={workout.id}
        isSubtopicRecommendation={isSubtopicRecommendation}
      />
    );
  }
  if (errors != null) return <Retry retry={() => window.location.reload()} />;
  if (loading != null) return <Loading />;
  return null;
}
type Props = {
  subtopicId: string;
};
export default function SelfDirectedTaskRedirector({ subtopicId }: Props) {
  const { search } = useLocation();
  const isSubtopicRecommendation =
    new URLSearchParams(search).get('isSubtopicRecommendation') === 'true';
  const recommendationCategory = new URLSearchParams(search).get(
    'recommendationCategory',
  );
  assertIsRecommendationCategory(recommendationCategory);

  const { props, error, retry } = useQuery<SelfDirectedTaskRedirectorQuery>(
    graphql`
      query SelfDirectedTaskRedirectorQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          workoutCreationToken
        }
      }
    `,
    { subtopicId },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  if (error != null) {
    new InvariantViolation(error);
    return <Retry retry={retry} />;
  }
  if (props == null) return <Loading />;
  const { subtopic } = props;
  if (subtopic == null) return 'Subtopic does not exist';
  const { workoutCreationToken } = subtopic;
  return (
    <SelfDirectedTaskFromSubtopicCreator
      token={workoutCreationToken}
      isSubtopicRecommendation={isSubtopicRecommendation}
      recommendationCategory={recommendationCategory}
    />
  );
}
// Defining this record type ensures we exhaustively check for each
// recommendation category in the union.
const recommendationCategory: Record<
  SubstrandSubtopicRecommendationCategory,
  true
> = {
  PRIOR_SKILLS_MASTERED: true,
  SKILL_ALMOST_MASTERED: true,
  SKILL_WORKED_ON_RECENTLY: true,
};
function assertIsRecommendationCategory(
  value: any,
): asserts value is SubstrandSubtopicRecommendationCategory | null {
  if (
    value !== null &&
    typeof value !== 'string' &&
    Object.keys(recommendationCategory).includes(value) === false
  ) {
    throw new Error(`Invalid recommendation category: ${value}`);
  }
}
