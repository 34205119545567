// Create a custom Popover because we heavily rely on a good scrolling behaviour
// for the SkillPopover and  ms-ui-primitives/Popover doesn't handle the
// scrolling well when inside a custom scrollable wrapper and unfortunately
// our ms-components/Layout at the moment relies on that.
// This simply renders and element absolutely positioned on the anchorElement.

import styled from '@emotion/styled';
import { useRef, useEffect, type ReactNode } from 'react';

import { zIndex } from 'ms-styles/base';
import keyDownMap from 'ms-utils/keyDownMap';

type PopoverProps = {
  children: ReactNode;
  onDismiss: () => void;
  // Set popover position from top - defaults to the existing 120%.
  top?: string | number;
};

const PopoverWrapper = styled.div({
  position: 'absolute',

  // Preserve existing positioning, top with 20px offset
  marginBottom: 20,
  zIndex: zIndex.popover,

  // We want to remove the default outline
  // when we focus the PopoverWrapper.
  ':focus': {
    outline: 'none',
  },
});

export default function InlinePopover({
  children,
  onDismiss,
  top = '120%',
}: PopoverProps) {
  const popoverRef = useRef<HTMLDivElement | null>(null);

  // Autofocus the popover so we can attach
  // keyDown event listeners to it
  useEffect(() => {
    const popoverElement = popoverRef.current;
    if (popoverElement == null) return;
    popoverElement.focus();
  }, []);

  // Handle on Click out
  useEffect(() => {
    function onClickOut(e: MouseEvent) {
      const popoverElement = popoverRef.current;
      if (popoverElement === null) return;
      if (e.target instanceof Node && popoverElement.contains(e.target)) return;
      onDismiss();
    }

    // Setting the third paramater, useCapture, to `true`
    // gives this listener priority over all other events
    window.addEventListener('click', onClickOut, true);

    return () => {
      window.removeEventListener('click', onClickOut, true);
    };
  }, [onDismiss]);

  return (
    <PopoverWrapper
      style={{ top }}
      ref={popoverRef}
      // Make the PopoverWrapper focusable
      tabIndex={0}
      onKeyDown={keyDownMap({
        ESC: onDismiss,
      })}
    >
      {children}
    </PopoverWrapper>
  );
}

// We export InlinePopoverAnchor to make sure that
// the Anchor is always relatively positioned.
export function InlinePopoverAnchor({ children }: { children: ReactNode }) {
  return (
    <div
      css={{
        // The PopoverWrapper positioning is relying on
        // InlinePopoverAnchor to be relatively positioned.
        position: 'relative',

        // The following flex properties are to center
        // the popover. Will need to change if, in future,
        // we want dynamic positioning.
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}
