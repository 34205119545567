import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g clipPath="url(#clip0)">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2207 3.07856C17.9395 3.79736 17.9989 4.85676 17.5801 5.73124C17.37 6.17 17.7 7.48 18.349 7.62268C19.3142 7.8348 20 8.75624 20 9.78932V10.1739C20 11.4146 18.9943 12.4204 17.7536 12.4204C17.3067 12.4267 16.8 13.4134 17.1941 13.7712C18.0714 14.6484 18.0714 16.0708 17.1941 16.948L16.9704 17.1718C16.289 17.8531 15.2151 17.9524 14.3432 17.5856C13.4267 17.2 12.5054 17.8099 12.3151 18.412C12.0241 19.3326 11.1631 20 10.1462 20H9.82612C8.78884 20 7.84596 19.3132 7.65736 18.3414C7.56004 17.84 6.38668 17.2266 5.75828 17.5548C4.8808 18.013 3.7854 17.9275 3.05196 17.1941L2.80592 16.948C2.06876 16.2109 1.95104 15.0889 2.45268 14.2288C2.551 14.0602 2.62664 12.6533 1.66752 12.313C0.74312 11.9851 0 11.224 0 10.2038V9.83192C0 8.68 0.84644 7.89824 1.90924 7.63692C2.49332 7.49332 2.84 6.42664 2.48448 5.824C1.9662 4.94552 2.0536 3.80428 2.80588 3.05196L3.05192 2.80592C3.9292 1.92864 5.35156 1.92864 6.22884 2.80592C6.74 3.14 7.62 2.76 7.5796 2.24648C7.5796 1.06248 8.50752 0.0862 9.69 0.02612L10.2042 0C11.2141 0 12.1242 0.66784 12.3201 1.61096C12.4134 2.06004 13.6734 2.75336 14.2688 2.41992C15.1148 1.94616 16.2027 2.06052 16.9215 2.77932L17.2207 3.07856ZM5.64339 10.4462L8.25023 13.053C8.46671 13.2931 9.05335 13.3331 9.38163 13.053L14.3566 8.07797C14.6691 7.76557 14.669 7.25905 14.3566 6.94661C14.0442 6.63417 13.5377 6.63417 13.2253 6.94661L8.81591 11.356L6.77475 9.31481C6.46235 9.00237 5.95583 9.00237 5.64339 9.31481C5.33095 9.62721 5.33095 10.1337 5.64339 10.4462Z"
    />
  </g>
);

const CheckMarkSquiggly = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CheckMarkSquiggly.displayName = 'CheckMarkSquiggly';

export default CheckMarkSquiggly;
