/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditTaskFlyout_checkInTask = {
    readonly id: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly expiryDate: string;
    readonly __typename: "CheckInTask";
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
    readonly " $refType": "EditTaskFlyout_checkInTask";
};
export type EditTaskFlyout_checkInTask$data = EditTaskFlyout_checkInTask;
export type EditTaskFlyout_checkInTask$key = {
    readonly " $data"?: EditTaskFlyout_checkInTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_checkInTask">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTaskFlyout_checkInTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryDate",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EditTaskFlyout_assignments"
    }
  ],
  "type": "CheckInTask",
  "abstractKey": null
};
(node as any).hash = 'c7dacb28da2d3dd7637103ca0ee317a5';
export default node;
