import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import type { SkillProficiencyProviderUserStatusQuery } from './__generated__/SkillProficiencyProviderUserStatusQuery.graphql';

type MaybeProficiency = number | null;
export function useSkillProficiency(skillId: string): MaybeProficiency;
export function useSkillProficiency(
  skillId: string,
  pastProficiencyTimestamp: string,
): [MaybeProficiency, MaybeProficiency];
export function useSkillProficiency(
  skillId: string,
  pastProficiencyTimestamp?: string,
): MaybeProficiency | [MaybeProficiency, MaybeProficiency] {
  const { props: proficiencyProps } =
    useQuery<SkillProficiencyProviderUserStatusQuery>(
      graphql`
        query SkillProficiencyProviderUserStatusQuery(
          $filters: [UserStatusFilterInput!]!
          $timestamp: DateTime
          $fetchPastProficiency: Boolean!
          $previewingWithProblemData: Boolean!
          $growthPeriod: Int!
        ) {
          lantern {
            viewer {
              ... on LanternStudent {
                userStatuses(
                  filters: $filters
                  previewingWithProblemData: $previewingWithProblemData
                  growthPeriod: $growthPeriod
                ) {
                  trueProficiency
                }
                pastProficiency: userStatuses(
                  filters: $filters
                  timestamp: $timestamp
                  previewingWithProblemData: $previewingWithProblemData
                  growthPeriod: $growthPeriod
                ) @include(if: $fetchPastProficiency) {
                  trueProficiency
                }
              }
            }
          }
        }
      `,
      {
        filters: [
          {
            curriculumNodeIds: [skillId],
          },
        ],
        fetchPastProficiency: Boolean(pastProficiencyTimestamp),
        timestamp: pastProficiencyTimestamp,
        previewingWithProblemData: false,
        growthPeriod: 120,
      },
    );
  const proficiency =
    proficiencyProps?.lantern?.viewer?.userStatuses?.[0]?.trueProficiency ??
    null;
  const pastProficiency =
    proficiencyProps?.lantern?.viewer?.pastProficiency?.[0]?.trueProficiency ??
    null;
  return pastProficiencyTimestamp != null
    ? [proficiency, pastProficiency]
    : proficiency;
}
