import { isNotNull } from 'ms-utils/typescript-utils';
import { PERMALINK_URL } from 'ms-utils/urls';

const MS_TAGLINE =
  'Mathspace is an all-in-one learning resource, wherever you are';
const MS_TAGLINE_SECONDARY =
  'We bring all of your learning tools together in one place, from video lessons, textbooks, to adaptive practice. Encourage your students to become self-directed learners';

const seo = {
  INDEX: {
    title: `Textbooks`,
    description: `${MS_TAGLINE}. ${MS_TAGLINE_SECONDARY}.`,
  },
  CURRICULUM_SELECTOR: {
    title: (countryName: string) => [`Available curriculums in ${countryName}`],
    description: (countryName: string) =>
      `Select one of the available curriculums available in ${countryName}. ${MS_TAGLINE}`,
  },
  TEXTBOOK_SELECTOR: {
    title: (curriculumName: string) => [curriculumName, 'Select a textbook'],
    description: (curriculumName: string) =>
      `Select one of the textbooks in ${curriculumName}. ${MS_TAGLINE}`,
  },
  TEXTBOOK_INTRO: {
    title: (textbookName: string) => [`${textbookName}`, 'Textbook overview'],
    description: (textbookIntro: string) => textbookIntro.slice(0, 160),
  },
  SUBTOPIC_LESSON: {
    title: (
      subtopicTitle: string,
      curriculumLevelTitle: string | null | undefined,
      curriculumtitle: string,
      localizedMath: 'Math' | 'Maths',
    ) =>
      [
        subtopicTitle,
        curriculumLevelTitle != null
          ? `${curriculumLevelTitle} ${localizedMath}`
          : null,
        curriculumtitle,
      ].filter(isNotNull),
    description: (
      curriculumTitle: string,
      curriculumLevel: string,
      topicTitle: string,
      subtopicTitle: string,
    ) => {
      const subtopicOrderPattern = /^\d+\.\d+/;
      const trimmedSubtopicTitle = subtopicTitle
        .replace(subtopicOrderPattern, '')
        .trim();

      const topicOrderPattern = /^\d+\./;
      const trimmedTopicTitle = topicTitle
        .replace(topicOrderPattern, '')
        .trim();

      return `Free lesson on ${trimmedSubtopicTitle}, taken from the ${trimmedTopicTitle} topic of our ${curriculumTitle} ${curriculumLevel} textbook. Learn with worked examples, get interactive applets, and watch instructional videos.`;
    },
    images: [
      `${PERMALINK_URL}/opengraph/og_image_facebook_1.png`,
      `${PERMALINK_URL}/opengraph/og_image_twitter_1.png`,
      `${PERMALINK_URL}/opengraph/og_image_linkedin_1.png`,
    ],
  },
  SUBTOPIC_PRACTICE: {
    title: (subtopicTitle: string) => [subtopicTitle, 'Adaptive practice'],
    description: (subtopicTitle: string) =>
      `Adaptive practice: ${subtopicTitle}. ${MS_TAGLINE}. ${MS_TAGLINE_SECONDARY}.`,
  },
  SUBTOPIC_WORKSHEET: {
    title: (subtopicTitle: string) => [subtopicTitle, 'Worksheet'],
    description: (subtopicTitle: string) =>
      `Worksheet: ${subtopicTitle}. ${MS_TAGLINE}. ${MS_TAGLINE_SECONDARY}.`,
  },
};

export default seo;

export const LOCALIZATION = {
  MATH: (country: string | null | undefined) => {
    switch (country) {
      case 'US':
      case 'CA':
        return 'Math';
      default:
        return 'Maths';
    }
  },
};
