import moment from 'moment';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { useMutation, graphql, useQuery } from 'relay-hooks';

import { useSnackbar } from 'ms-components/Snackbar';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import {
  FieldWrapper,
  Label,
  Asterisk,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Select from 'ms-ui-primitives/Select';
import Stack from 'ms-ui-primitives/Stack';
import { unwrap } from 'ms-utils/typescript-utils';

import type { AssignDiscoveryCheckInFlowMutation } from './__generated__/AssignDiscoveryCheckInFlowMutation.graphql';
import type { AssignDiscoveryCheckInFlowQuery } from './__generated__/AssignDiscoveryCheckInFlowQuery.graphql';

type Props = {
  taskCreationCallback: () => void;
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledStrandId?: string | null | undefined;
};
const ASSIGN_DISCOVERY_CHECK_IN_FLOW_QUERY = graphql`
  query AssignDiscoveryCheckInFlowQuery($schoolId: ID!) {
    school(id: $schoolId) {
      lanternCurriculum {
        strands {
          id
          title
        }
      }
    }
  }
`;
const ASSIGN_DISCOVERY_CHECK_IN_FLOW_MUTATION = graphql`
  mutation AssignDiscoveryCheckInFlowMutation(
    $assignedClassIds: [ID!]!
    $strandId: ID!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $startDate: DateTime!
    $dueDate: DateTime!
  ) {
    createStrandDiagnosticCheckInTask(
      strandId: $strandId
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      startDate: $startDate
      dueDate: $dueDate
    ) {
      errors {
        __typename
        ... on InvalidClassError {
          message
        }
        ... on InvalidStudentError {
          message
        }
        ... on InvalidStrandError {
          message
        }
        ... on PermissionError {
          message
        }
        ... on NoAssigneesError {
          message
        }
        ... on StrandDiagnosticAlreadyExistsError {
          message
        }
        ... on DueDateInThePastError {
          message
        }
        ... on DueDateBeforeStartDateError {
          message
        }
      }
    }
  }
`;
const getDefaultTaskDueDate = () =>
  moment().startOf('hour').add(14, 'days').toDate();
const getDefaultTaskStartDate = () => moment().startOf('minute').toDate();
export default function AssignDiscoveryCheckInFlow({
  taskCreationCallback,
  prefilledClasses = [],
  prefilledStudents = [],
  prefilledExcludedStudents = [],
  prefilledStartDate = getDefaultTaskStartDate(),
  prefilledDueDate = getDefaultTaskDueDate(),
  prefilledStrandId,
}: Props) {
  const { schoolId } = useMaybeTeacherContext();
  const { props } = useQuery<AssignDiscoveryCheckInFlowQuery>(
    ASSIGN_DISCOVERY_CHECK_IN_FLOW_QUERY,
    {
      schoolId,
    },
  );
  const assignableStrands = props?.school?.lanternCurriculum?.strands ?? [];
  return props == null ? (
    <MinorSpinner />
  ) : (
    <AssignDiscoveryCheckInFlowContent
      assignableStrands={assignableStrands}
      taskCreationCallback={taskCreationCallback}
      prefilledClasses={prefilledClasses}
      prefilledStudents={prefilledStudents}
      prefilledExcludedStudents={prefilledExcludedStudents}
      prefilledStartDate={prefilledStartDate}
      prefilledDueDate={prefilledDueDate}
      prefilledStrandId={prefilledStrandId}
    />
  );
}

function AssignDiscoveryCheckInFlowContent({
  assignableStrands,
  taskCreationCallback,
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledStrandId,
}: {
  assignableStrands: ReadonlyArray<
    Readonly<{
      id: string;
      title: string;
    }>
  >;
  taskCreationCallback: () => void;
  prefilledClasses: ReadonlyArray<ClassSelectionPayload>;
  prefilledStudents: ReadonlyArray<StudentSelectionPayload>;
  prefilledExcludedStudents: ReadonlyArray<StudentSelectionPayload>;
  prefilledStartDate: Date;
  prefilledDueDate: Date;
  prefilledStrandId: string | null | undefined;
}) {
  const [selectedStrand, setSelectedStrand] = useState<string>(
    prefilledStrandId ?? unwrap(assignableStrands[0]).id,
  );
  const [selectedClasses, setSelectedClasses] =
    useState<ReadonlyArray<ClassSelectionPayload>>(prefilledClasses);
  const [excludedStudents, setExcludedStudents] = useState<
    ReadonlyArray<StudentSelectionPayload>
  >(prefilledExcludedStudents);
  const [selectedStudents, setSelectedStudents] =
    useState<ReadonlyArray<StudentSelectionPayload>>(prefilledStudents);
  const [taskStartDate, updateTaskStartDate] =
    useState<Date>(prefilledStartDate);
  const [taskDueDate, updateTaskDueDate] = useState<Date>(prefilledDueDate);
  const [assignCheckinMutation, { data, error: assignCheckinGenericError }] =
    useMutation<AssignDiscoveryCheckInFlowMutation>(
      ASSIGN_DISCOVERY_CHECK_IN_FLOW_MUTATION,
    );
  const assignCheckinErrors = useMemo(
    () => data?.createStrandDiagnosticCheckInTask?.errors ?? [],
    [data?.createStrandDiagnosticCheckInTask?.errors],
  );
  const successfullyAssignedCheckin =
    data != null &&
    assignCheckinErrors.length === 0 &&
    assignCheckinGenericError == null;
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  const assignCheckin = useCallback(async () => {
    await assignCheckinMutation({
      variables: {
        assignedClassIds: completeClassIds,
        assignedStudentIds: selectedStudents.map(s => s.id),
        partiallyAssignedClasses: partialClasses,
        strandId: selectedStrand,
        startDate: taskStartDate.toISOString(),
        dueDate: taskDueDate.toISOString(),
      },
    });
    taskCreationCallback();
  }, [
    assignCheckinMutation,
    completeClassIds,
    selectedStrand,
    taskCreationCallback,
    partialClasses,
    selectedStudents,
    taskDueDate,
    taskStartDate,
  ]);
  const isFormValid =
    selectedStrand != null &&
    selectedClasses.length + selectedStudents.length > 0;
  const { enqueueMessage } = useSnackbar();
  useEffect(() => {
    if (successfullyAssignedCheckin) {
      enqueueMessage({ text: 'Check-in assigned' });
    }
  }, [enqueueMessage, successfullyAssignedCheckin]);
  useEffect(() => {
    if (assignCheckinGenericError != null) {
      enqueueMessage({ text: 'Something went wrong. Please try again.' });
    }
  }, [assignCheckinGenericError, enqueueMessage]);
  useEffect(() => {
    assignCheckinErrors.forEach(error => {
      if (error.__typename !== '%other') {
        enqueueMessage({ text: error.message });
      }
    });
  }, [assignCheckinErrors, enqueueMessage]);
  return (
    <>
      <CreateTaskModalHeader
        hideStepNumber
        stepTitles={getStepHeaderInfo({ selectedFlow: 'discoveryCheckIn' })}
        steps={STEP_NAME_MAPS.discoveryCheckIn}
        nextButtonDisabledStates={{
          [STEP_NAME_MAPS.discoveryCheckIn.at(-1) as string]: !isFormValid,
        }}
        onLastStepAction={assignCheckin}
      />

      <Body>
        <TaskCreationFormWrapper>
          <Stack.V maxWidth={540} css={{ margin: '0 auto' }}>
            <BodyM color="grey90">
              Check-ins quickly assess student gaps and progress across
              curriculum standards.
            </BodyM>

            <Stack.Spacer.V height={32} />

            <BodyM color="grey90">
              Discovery check-ins are the first check-in your students complete.
              They are longer in length as they assess skills across an entire
              strand.
            </BodyM>

            <Stack.Spacer.V height={24} />

            <BodyM bold>Select a strand</BodyM>

            <Stack.Spacer.V height={8} />

            <Select
              block
              value={selectedStrand}
              onChange={setSelectedStrand}
              options={assignableStrands.map(({ id, title }) => ({
                value: id,
                label: title,
              }))}
            />

            <Stack.Spacer.V height={24} />

            <FieldWrapper>
              <TaskAssignment
                selectedClasses={selectedClasses}
                selectedStudents={selectedStudents}
                excludedStudents={excludedStudents}
                onChangeClasses={setSelectedClasses}
                onChangeStudents={setSelectedStudents}
                onChangeExcludedStudents={setExcludedStudents}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Label>
                Start date / Due date<Asterisk> *</Asterisk>
              </Label>
              <TaskDatePicker
                value={[taskStartDate, taskDueDate]}
                onChange={([startDate, endDate]) => {
                  if (startDate != null && startDate !== taskStartDate)
                    updateTaskStartDate(startDate);
                  if (endDate != null && endDate !== taskDueDate)
                    updateTaskDueDate(endDate);
                }}
              />
            </FieldWrapper>
          </Stack.V>
        </TaskCreationFormWrapper>
      </Body>
    </>
  );
}
