import { css, cx } from '@emotion/css';
import riveWasm from '@rive-app/canvas-advanced/rive.wasm';
import { useId, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'react-relay';
import { useFragment, useMutation } from 'relay-hooks';

import Icons from 'ms-components/icons';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { transition } from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HStack, VStack } from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString, shadeHexString } from 'ms-utils/colors';
import type { Entries } from 'ms-utils/typescript-utils';
import { assert, assertUnreachable } from 'ms-utils/typescript-utils';

import type { GameSetupMutation } from './__generated__/GameSetupMutation.graphql';
import type {
  GameSetup_task,
  GameSetup_task$key,
} from './__generated__/GameSetup_task.graphql';
import type { GameModalContent as TGameModalContent } from '../GameModal';
import { GameModalContent, GameModalTitle } from '../GameModal/primitives';
import type { TugOfWarTeam } from '../TugOfWar';
import { teamConfig } from '../TugOfWar';
import tugOfWarRiv from '../TugOfWar/tug_of_war.riv';

type Student = GameSetup_task['assignedStudents'][number];

const gameSetupMutation = graphql`
  mutation GameSetupMutation(
    $taskId: ID!
    $gameType: GameType!
    $duration: Int!
    $teamAName: String!
    $teamAStudents: [ID!]!
    $teamBName: String!
    $teamBStudents: [ID!]!
  ) {
    createGame(
      taskId: $taskId
      gameType: $gameType
      duration: $duration
      teamAName: $teamAName
      teamAStudents: $teamAStudents
      teamBName: $teamBName
      teamBStudents: $teamBStudents
    ) {
      game {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;

type GameConfig = {
  gameId: string;
  gameDurationMs: number;
};

export function GameSetup({
  taskKey,
  onContinue,
}: {
  taskKey: GameSetup_task$key;
  onContinue: (newContent: TGameModalContent, gameConfig: GameConfig) => void;
}) {
  const { trackStructEvent } = useSnowplow();
  const { assignedStudents: students, id: taskId } = useFragment(
    graphql`
      fragment GameSetup_task on TaskInterface {
        id
        assignedStudents {
          id
          user {
            firstName
            lastName
            avatar
          }
        }
      }
    `,
    taskKey,
  );
  const initialTeams = useMemo(() => {
    const mid = Math.ceil(students.length / 2);
    return [
      {
        team: 'teamA',
        students: students.slice(0, mid).sort(sortStudents),
      },
      {
        team: 'teamB',
        students: students.slice(mid).sort(sortStudents),
      },
    ] as const;
  }, [students]);
  const [teams, setTeams] = useState(initialTeams);
  const [teamA, teamB] = teams;
  const [removedStudents, setRemovedStudents] = useState<Student[]>([]);
  const [gameDurationMinutes, setGameDurationMinutes] = useState(5);
  const [createGame, isCreatingGame] =
    useMutation<GameSetupMutation>(gameSetupMutation);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const gameDurationLabelId = useId();

  function swapTeam(student: Student) {
    setTeams(prevTeams => {
      const currTeam = prevTeams.find(t =>
        t.students.some(s => s.id === student.id),
      );
      assert(currTeam !== undefined, 'Student not found in any team');

      const [prevTeamA, prevTeamB] = prevTeams;
      const isMovingToTeamB = currTeam.team === 'teamA';

      const newTeamA = {
        ...prevTeamA,
        students: isMovingToTeamB
          ? prevTeamA.students.filter(s => s.id !== student.id)
          : [...prevTeamA.students, student],
      };

      const newTeamB = {
        ...prevTeamB,
        students: isMovingToTeamB
          ? [...prevTeamB.students, student]
          : prevTeamB.students.filter(s => s.id !== student.id),
      };

      trackStructEvent({
        category: 'gamification_tug_of_war',
        action: 'swapped_tug_of_war_team',
        label: student.id,
        property: isMovingToTeamB ? 'teamB' : 'teamA',
      });

      return [newTeamA, newTeamB];
    });
  }

  function removeStudent(student: Student) {
    setTeams(([prevTeamA, prevTeamB]) => [
      {
        ...prevTeamA,
        students: prevTeamA.students.filter(s => s.id !== student.id),
      },
      {
        ...prevTeamB,
        students: prevTeamB.students.filter(s => s.id !== student.id),
      },
    ]);
    setRemovedStudents(prev => [...prev, student]);

    trackStructEvent({
      category: 'gamification_tug_of_war',
      action: 'removed_student_from_tug_of_war',
      label: student.id,
    });
  }

  function addStudentToTeam(student: Student, team: TugOfWarTeam) {
    setRemovedStudents(prev => prev.filter(s => s.id !== student.id));
    setTeams(([prevTeamA, prevTeamB]) => {
      switch (team) {
        case 'teamA':
          return [
            { ...prevTeamA, students: [...prevTeamA.students, student] },
            prevTeamB,
          ];
        case 'teamB':
          return [
            prevTeamA,
            { ...prevTeamB, students: [...prevTeamB.students, student] },
          ];
        default:
          assertUnreachable(team, 'Invalid team');
      }
    });

    trackStructEvent({
      category: 'gamification_tug_of_war',
      action: 'added_student_to_tug_of_war_team',
      label: student.id,
      property: team,
    });
  }

  function handleContinue() {
    setErrorMessage(null);
    createGame({
      variables: {
        taskId,
        gameType: 'TUG_OF_WAR',
        // ! The backend expects seconds
        duration: gameDurationMinutes * 60,
        teamAName: teamConfig.teamA.name,
        teamAStudents: teamA.students.map(s => s.id),
        teamBName: teamConfig.teamB.name,
        teamBStudents: teamB.students.map(s => s.id),
      },
      onCompleted: response => {
        const { game, errors } = response.createGame;
        if (errors.length > 0) {
          setErrorMessage('Something went wrong. Please try again.');
        } else if (game !== null) {
          onContinue('TugOfWar', {
            gameId: game.id,
            // ! TugOfWar expects milliseconds
            gameDurationMs: gameDurationMinutes * 60 * 1000,
          });
        }
      },
      onError: error => {
        setErrorMessage(error.message);
      },
    });
  }

  return (
    <GameModalContent>
      <GameModalTitle>Game setup</GameModalTitle>
      <VStack gap={8}>
        <span className={styles.formLabel}>Team allocation</span>
        <div className={styles.transfer}>
          {teams.map((t, i) => {
            const { name, color } = teamConfig[t.team];
            return (
              <div
                key={t.team}
                className={styles.transferList}
                style={{ borderColor: color }}
              >
                <h3 className={styles.transferListTitle}>
                  <span
                    style={{
                      color,
                      fontSize: 20,
                      lineHeight: 1,
                      marginTop: '-.1em',
                    }}
                  >
                    ⬣
                  </span>{' '}
                  {name} Team{' '}
                  <small className={styles.transferListTitleSmall}>
                    {t.students.length === 1 ? (
                      <>(1 member)</>
                    ) : (
                      <>({t.students.length} members)</>
                    )}
                  </small>
                </h3>
                <div className={styles.transferListBody}>
                  {t.students.map(s => (
                    <div key={s.id} className={styles.transferListItem}>
                      <div
                        className={styles.transferListAvatar}
                        style={{ backgroundImage: `url(${s.user.avatar})` }}
                      />
                      <div>
                        {s.user.lastName}, {s.user.firstName}
                      </div>
                      <HStack
                        className={cx(
                          styles.transferListActions,
                          'transfer-list-actions',
                        )}
                        gap={4}
                      >
                        <button
                          className={styles.transferListBadge}
                          disabled={
                            isCreatingGame.loading || t.students.length === 1
                          }
                          onClick={() => swapTeam(s)}
                        >
                          {i === 0 ? (
                            <Icons.ChevronRight />
                          ) : (
                            <Icons.ChevronLeft />
                          )}
                        </button>
                        <button
                          className={styles.transferListBadge}
                          disabled={
                            isCreatingGame.loading || t.students.length === 1
                          }
                          onClick={() => removeStudent(s)}
                        >
                          <Icons.Cross />
                        </button>
                      </HStack>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}

          {removedStudents.length > 0 && (
            <div className={styles.transferList}>
              <h3 className={styles.transferListTitle}>
                Unassigned students{' '}
                <small className={styles.transferListTitleSmall}>
                  {removedStudents.length === 1 ? (
                    <>(1 member)</>
                  ) : (
                    <>({removedStudents.length} members)</>
                  )}
                </small>
              </h3>
              <div className={styles.transferListBody}>
                {removedStudents.map(s => (
                  <div key={s.id} className={styles.transferListItem}>
                    <div
                      className={styles.transferListAvatar}
                      style={{ backgroundImage: `url(${s.user.avatar})` }}
                    />
                    <div>
                      {s.user.lastName}, {s.user.firstName}
                    </div>
                    <HStack
                      className={cx(
                        styles.transferListActions,
                        'transfer-list-actions',
                      )}
                      gap={4}
                    >
                      {(
                        Object.entries(teamConfig) as Entries<typeof teamConfig>
                      ).map(([team, { color }]) => (
                        <button
                          key={team}
                          className={styles.transferListBadge}
                          disabled={isCreatingGame.loading}
                          style={{ color }}
                          onClick={() => addStudentToTeam(s, team)}
                        >
                          ⬣
                        </button>
                      ))}
                    </HStack>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </VStack>
      <VStack gap={8}>
        <span className={styles.formLabel} id={gameDurationLabelId}>
          Game duration
        </span>
        <div
          className={styles.buttonGroup}
          role="group"
          aria-labelledby={gameDurationLabelId}
        >
          {[0.5, 1, 2, 3, 5, 10, 15].map(minutes => (
            <button
              key={minutes}
              className={styles.button}
              aria-pressed={gameDurationMinutes === minutes || undefined}
              onClick={() => setGameDurationMinutes(minutes)}
            >
              {minutes === 0.5 ? <>30 sec</> : <>{minutes} min</>}
            </button>
          ))}
        </div>
      </VStack>
      <div>
        <Button
          type="primary"
          isDisabled={isCreatingGame.loading}
          isRound
          onClick={handleContinue}
        >
          {isCreatingGame.loading ? 'Loading...' : 'Continue'}
        </Button>
      </div>

      {errorMessage !== null && (
        <div className={styles.errorMessage}>{errorMessage}</div>
      )}

      <Helmet>
        <link
          rel="preload"
          href={riveWasm}
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          // @ts-ignore This is actually a string at runtime. See the
          // useTypedRive hook for more details.
          href={tugOfWarRiv}
          as="fetch"
          crossOrigin="anonymous"
        />
      </Helmet>
    </GameModalContent>
  );
}

function sortStudents(a: Student, b: Student) {
  const lastNameComp = a.user.lastName.localeCompare(b.user.lastName);
  return lastNameComp !== 0
    ? lastNameComp
    : a.user.firstName.localeCompare(b.user.firstName);
}

const styles = {
  formLabel: css({
    color: colors.grey,
  }),
  transfer: css({
    display: 'flex',
    gap: 20,
  }),
  transferList: css({
    border: `3px solid ${colors.ironLight}`,
    borderRadius: 12,
    flex: '1 1 0%',
    overflow: 'hidden',
  }),
  transferListTitle: css({
    alignItems: 'center',
    borderBottom: `1px solid ${colors.ironLight}`,
    color: colors.grey,
    display: 'flex',
    fontWeight: 600,
    gap: 4,
    padding: 12,
  }),
  transferListTitleSmall: css({
    color: colors.grey90,
    fontSize: 12,
    fontWeight: 400,
  }),
  transferListBody: css({
    height: 280,
    overflow: 'auto',
  }),
  transferListItem: css({
    alignItems: 'center',
    border: `0 solid ${colors.ironLight}`,
    color: colors.grey,
    display: 'flex',
    fontWeight: 600,
    gap: 12,
    padding: '12px 16px',

    ':hover': {
      backgroundColor: colors.seashell,

      '.transfer-list-actions': {
        opacity: 1,
      },
    },

    '& + &': {
      borderTopWidth: 1,
    },
  }),
  transferListAvatar: css({
    background: `center/cover ${colors.ironLight}`,
    borderRadius: 9999,
    flexShrink: 0,
    height: 32,
    width: 32,
  }),
  transferListActions: css({
    marginLeft: 'auto',
    opacity: 0,
    transition: 'opacity .2s',
  }),
  transferListBadge: css({
    alignItems: 'center',
    backgroundColor: 'white',
    border: `2px solid ${colors.porcelain}`,
    borderRadius: 8,
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    flexShrink: 0,
    height: 24,
    justifyContent: 'center',
    width: 24,
    wordBreak: 'break-all',

    ':hover:not(:disabled)': {
      borderColor: colors.ironLight,
    },

    ':disabled': {
      cursor: 'not-allowed',
      opacity: 0.4,
    },
  }),
  buttonGroup: css({
    display: 'flex',

    button: {
      flex: '1 1 auto',
      position: 'relative',
    },

    'button + button': {
      marginLeft: -1,
    },

    'button:not(:last-of-type)': {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },

    'button:not(:first-of-type)': {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
    },
  }),
  button: css({
    backgroundColor: 'white',
    border: `1px solid ${colors.iron}`,
    borderRadius: 4,
    color: colors.eggplant,
    cursor: 'pointer',
    font: 'inherit',
    fontWeight: 600,
    padding: 12,
    transition: `border-color ${transition}, color ${transition}`,

    ':hover:not([aria-pressed="true"])': {
      borderColor: alternateColors.iron,
      color: shadeHexString(colors.eggplant, 0.2),
      zIndex: 1,
    },

    '&[aria-pressed="true"]': {
      backgroundColor: colors.eggplant,
      borderColor: colors.eggplant,
      color: 'white',
      zIndex: 2,
    },
  }),
  errorMessage: css({
    backgroundColor: hexStringToRgbaString(colors.brickRed, 0.1),
    borderRadius: 8,
    color: colors.brickRed,
    fontWeight: 600,
    padding: '12px 16px',
  }),
} as const;
