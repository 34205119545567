import { equals, pick } from 'ramda';
import { useState, useEffect } from 'react';

import { useRouter } from 'ms-utils/hooks/useRouter';
import type { Db } from 'ms-utils/localStorageDb';
import { serialize, deserialize } from 'ms-utils/urls/queryParams';

type StateUpdater<S> = (state: S) => S;
// We only allow the updater pattern for this hook. Ie. you can't pass a state object directly.
type RestrictedSetStateFunction<S> = (updater: StateUpdater<S>) => void;

export function useQueryParamSessionSyncedState<S extends Record<string, any>>({
  db,
  dbKey,
  fallbackValue,
  pickKeys,
}: {
  db: Db;
  dbKey: string;
  fallbackValue: S;
  pickKeys: ReadonlyArray<string>;
}): [S, RestrictedSetStateFunction<S>, (updater: StateUpdater<S>) => string] {
  const { history, location } = useRouter();

  // Casting location to `any` so we can access `state` which is not present
  // under `Location` type from React Router
  const { pathname, search, state: _state } = location as any;

  const initialState: S = {
    ...fallbackValue,
    ...db.get(dbKey),
    ...deserialize(search),
  };

  const [state, setState] = useState(initialState);

  const getLinkBySetState = (stateUpdater: StateUpdater<S>) =>
    `${pathname}${serialize({
      ...deserialize(search),
      ...stateUpdater(state),
    })}`;

  // Make sure that the query param state is reset on route change,
  // e.g. when changing the class via the sidepanel in the class reports,
  // the query param state should be set to the correct filter values from local storage.
  // This fixes a bug in the class mastery report:
  // https://mathspace.atlassian.net/browse/CORE-2884
  useEffect(
    () => {
      const newState: S = {
        ...fallbackValue,
        ...db.get(dbKey),
        ...deserialize(search),
      };
      setState(() => newState);
    },
    [pathname], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      db.set(dbKey, state);
      const newSearch = serialize(
        // to avoid extraneous field in the query params
        pickKeys != null
          ? pick(pickKeys)({ ...deserialize(search), ...state })
          : state,
      );

      if (search !== newSearch) {
        history.replace({
          pathname,
          search: newSearch,
          state: _state,
        });
      }
    },
    [state], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      const newState: S = {
        ...state,
        ...deserialize(search),
      };

      if (!equals(state, newState)) {
        setState(() => newState);
      }
      db.set(dbKey, newState);
    },
    [search], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [state, setState, getLinkBySetState];
}
