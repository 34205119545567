export function DoubleChevronUpSvg({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M8 7.7c.16 0 .32.048.44.136l6 4c.216.144.36.384.36.664v2.4c0 .44-.36.8-.8.8a.755.755 0 0 1-.44-.136L8 11.86l-5.56 3.704A.755.755 0 0 1 2 15.7c-.44 0-.8-.36-.8-.8v-2.4c0-.28.144-.52.36-.664l6-4A.754.754 0 0 1 8 7.7Z" />
      <path d="M8 .4c.16 0 .32.048.44.136l6 4c.216.144.36.384.36.664v2.4c0 .44-.36.8-.8.8a.755.755 0 0 1-.44-.136L8 4.56 2.44 8.264A.754.754 0 0 1 2 8.4c-.44 0-.8-.36-.8-.8V5.2c0-.28.144-.52.36-.664l6-4A.755.755 0 0 1 8 .4Z" />
    </svg>
  );
}
