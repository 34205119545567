import { createContext, useContext, type ReactNode } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer/index';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';

const StudentTextbookContext = createContext<{
  isSidebarCollapsed: boolean;
  setSidebarCollapsed: (collapsed: boolean) => void;
}>({
  isSidebarCollapsed: false,
  setSidebarCollapsed: () => {},
});

export function useStudentTextbookContext() {
  return useContext(StudentTextbookContext);
}

export function StudentTextbookContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { userId } = useViewer();

  const [isSidebarCollapsed, setSidebarCollapsed] = useLocalSyncedState({
    dbKey: JSON.stringify({ userId, page: 'TEXTBOOK' }),
    fallbackValue: false,
  });

  return (
    <StudentTextbookContext.Provider
      value={{
        isSidebarCollapsed,
        setSidebarCollapsed,
      }}
    >
      {children}
    </StudentTextbookContext.Provider>
  );
}
