import { useMemo } from 'react';
import { graphql, useFragment, useQuery } from 'relay-hooks';

import { PageTimeErrorThrower } from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { InvariantViolation } from 'ms-utils/app-logging';
import { getStartOfWeek } from 'ms-utils/time';

import type { StudentSkillsMapSubstrandProficiencyIndicatorQuery } from './__generated__/StudentSkillsMapSubstrandProficiencyIndicatorQuery.graphql';
import type { StudentSkillsMapSubstrandProficiencyIndicator_student$key } from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_student.graphql';
import type { StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$key } from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus.graphql';
import type { StudentSkillsMapSubstrandProficiencyIndicator_substrand$key } from './__generated__/StudentSkillsMapSubstrandProficiencyIndicator_substrand.graphql';
import SubstrandProficiencyIndicator from '../SubstrandProficiencyIndicator';
import {
  getGradeSubstrands,
  getGrowthStartDate,
  isStrandLocked,
} from '../utils';

type Props = {
  size?: number | undefined;
  student: StudentSkillsMapSubstrandProficiencyIndicator_student$key;
  studentStrandStatus: StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus$key;
  substrand: StudentSkillsMapSubstrandProficiencyIndicator_substrand$key;
};
const PROFICIENCY_INDICATOR_SIZE = 104;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENT_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_student on LanternStudent {
    selfReportedGrade {
      order
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENTSTRANDSTATUS_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus on StudentStrandStatus {
    status
    lastWorkedOnDiagnosticCheckIn {
      endedAt
      hasFinishedDiagnosticPhase
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_SUBSTRAND_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandProficiencyIndicator_substrand on Substrand {
    id
    badgeUrl
    gradeSubstrands {
      id
      gradeStrand {
        grade {
          id
          order
        }
      }
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_QUERY = graphql`
  query StudentSkillsMapSubstrandProficiencyIndicatorQuery(
    $filters: [UserStatusFilterInput!]!
    $timestamp: DateTime
    $isLocked: Boolean!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) @skip(if: $isLocked) {
            trueProficiency
          }
          previousStatuses: userStatuses(
            filters: $filters
            timestamp: $timestamp
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) @skip(if: $isLocked) {
            trueProficiency
          }
        }
      }
    }
  }
`;
function StudentSkillsMapSubstrandProficiencyIndicator({
  size = PROFICIENCY_INDICATOR_SIZE,
  student: studentRef,
  studentStrandStatus: studentStrandStatusRef,
  substrand: substrandRef,
}: Props) {
  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();

  const student = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENT_FRAGMENT,
    studentRef,
  );
  const studentStrandStatus = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_STUDENTSTRANDSTATUS_FRAGMENT,
    studentStrandStatusRef,
  );
  const substrand = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_SUBSTRAND_FRAGMENT,
    substrandRef,
  );
  const gradeSubstrands = getGradeSubstrands({
    substrand,
    grade: { order: student.selfReportedGrade.order },
  });
  const isLocked = isStrandLocked(
    studentStrandStatus,
    enableOptInDiscoveryCheckIn,
  );
  const diagnosticCheckInCompleteTimestamp =
    studentStrandStatus.lastWorkedOnDiagnosticCheckIn?.endedAt;
  const startOfWeek = useMemo(getStartOfWeek, []);
  const previousStatusTimestamp = getGrowthStartDate({
    startOfWeek,
    checkInCompletedAt: diagnosticCheckInCompleteTimestamp,
  });
  const { props, error } =
    useQuery<StudentSkillsMapSubstrandProficiencyIndicatorQuery>(
      STUDENT_SKILLS_MAP_SUBSTRAND_PROFICIENCY_INDICATOR_QUERY,
      {
        filters: [
          {
            curriculumNodeIds: gradeSubstrands.map(
              gradeSubstrand => gradeSubstrand.id,
            ),
          },
        ],
        timestamp: previousStatusTimestamp,
        isLocked,
        previewingWithProblemData: false,
        growthPeriod: 120,
      },
    );
  if (isLocked) {
    return (
      <SubstrandProficiencyIndicator
        currentProficiency={0}
        pastProficiency={0}
        size={size}
        locked
        badgeUrl={substrand.badgeUrl}
      />
    );
  }
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentSkillsMapSubstrandHeader"
        componentName="StudentSkillsMapSubstrandHeader"
        error={error}
      />
    );
  }
  if (props == null) {
    return (
      <SubstrandProficiencyIndicator
        currentProficiency={0}
        pastProficiency={0}
        size={size}
        locked={false}
        badgeUrl={substrand.badgeUrl}
      />
    );
  }
  const { viewer } = props.lantern;
  if (viewer == null || viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('The logged in user must be a LanternStudent');
  }
  const currentProficiency = viewer.userStatuses?.[0]?.trueProficiency ?? 0;
  const pastProficiency = viewer.previousStatuses?.[0]?.trueProficiency ?? 0;
  return (
    <SubstrandProficiencyIndicator
      currentProficiency={currentProficiency}
      pastProficiency={pastProficiency}
      locked={false}
      size={size}
      badgeUrl={substrand.badgeUrl}
    />
  );
}
export { StudentSkillsMapSubstrandProficiencyIndicator };
