/**
 * @module probability-tree
 */

import { StyleSheet, css } from 'aphrodite';
import { zipWith } from 'ramda';
import type { ReactElement } from 'react';

import type { Coordinate } from 'ms-utils/coordinate';
import * as dataset from 'ms-utils/dataset';
import type { Dataset } from 'ms-utils/dataset';
import { flatten, map, reduce } from 'ms-utils/tree';
import type { Tree } from 'ms-utils/tree';
import { unwrap } from 'ms-utils/typescript-utils';

import { LatexProbabilityInput } from './LatexProbabilityInput';
import { Node } from './Node';
import { ProbabilityInput } from './ProbabilityInput';

export type Meta = {
  id: number;
  label: string;
  probability: string;
};

const styles = StyleSheet.create({
  label: {
    cursor: 'default',
    fill: '#7f8c8d',
    fontSize: 15,
    fontFamily: 'sans-serif',
    fontWeight: 200,
  },
  edge: {
    stroke: '#000',
  },
  probability: {
    background: '#F5F5F5',
    border: '1px dashed #CCC',
    fontSize: '13px',
    height: '25px',
    opacity: 0.8,
    outline: 'none',
    position: 'absolute',
    textAlign: 'center',
    width: '45px',
  },
});

export function layoutNodes<T>(
  root: Tree<T>,
  colSize: number,
  rowSize: number,
): Tree<Coordinate<T>> {
  // TODO: Might be nicer to use the algorithm D3 uses here:
  // https://github.com/d3/d3-hierarchy/blob/master/src/tree.js

  // This offset is used for calculating the y position of the leaf nodes below.
  let offset = -rowSize;

  return map(root, (node, depth) => {
    const firstChild = node.children[0];
    const lastChild = node.children[node.children.length - 1];
    const x = colSize * depth;
    const y =
      node.children.length > 0
        ? // get midpoint of the y values of each
          // Unwraping position is not typesafe, its typed as an array, no guarantees
          (unwrap(unwrap(firstChild).meta.position[1]) +
            unwrap(unwrap(lastChild).meta.position[1])) /
          2
        : (offset += rowSize);
    return {
      children: [...node.children],
      meta: {
        position: [x, y],
        meta: node.meta,
      },
    };
  });
}

export function getNodeById<T extends { id: number }>(
  tree: Tree<Coordinate<T>>,
  id: number,
): Tree<Coordinate<T>> | null | undefined {
  return flatten(tree).filter(node => node.meta.meta.id === id)[0];
}

export function layoutEdges<T>(
  node: Tree<Coordinate<T>>,
): Array<[Coordinate<T>, Coordinate<T>]> {
  return reduce(
    node,
    (acc, n) => {
      const edges = n.children.map(child => [n.meta, child.meta]);
      return acc.concat(edges);
    },
    [],
  );
}

export function layoutMidpoints<T>(
  node: Tree<Coordinate<T>>,
): Array<Coordinate<T>> {
  return reduce(
    node,
    (acc, n) => {
      const midPoints = n.children.map(child => ({
        meta: child.meta.meta,
        position: zipWith(
          (a, b) => (a + b) / 2,
          child.meta.position,
          n.meta.position,
        ),
      }));
      return acc.concat(midPoints);
    },
    [],
  );
}

export function renderTreeNodes(
  nodes: Dataset<Coordinate<Meta>>,
  canShowMenu: boolean,
  readOnly: boolean,
  id: number | null | undefined,
  openMenuCallback: Function,
): Array<ReactElement<typeof Node>> {
  const flatNodeList: ReadonlyArray<Coordinate<any>> = dataset.flatten(nodes);
  return flatNodeList.map((node, i) => {
    const nodeIsFocused = canShowMenu && node.meta.id === id && !readOnly;
    return (
      <Node
        key={`node_${node.meta.id || i}`}
        id={node.meta.id}
        isFocused={nodeIsFocused}
        cx={unwrap(node.position[0])}
        cy={unwrap(node.position[1])}
        onOpenMenu={openMenuCallback}
        readOnly={readOnly}
      />
    );
  });
}

export function renderNodeLabels(
  nodes: Dataset<Coordinate<Meta>>,
): Array<ReactElement<any, 'g'> | undefined> {
  const flatNodeList: ReadonlyArray<Coordinate<any>> = dataset.flatten(nodes);
  return flatNodeList.map((node, i) => {
    if (!node.meta.label) {
      return undefined;
    }
    // This is not type safe, position is not guranteed to have any elements
    const x = unwrap(node.position[0]) - 15;
    const y = unwrap(node.position[1]) - 10;
    return (
      <g key={`label_${i}`} transform={`translate(${x},${y})`}>
        <text className={css(styles.label)} textAnchor="end">
          {node.meta.label}
        </text>
      </g>
    );
  });
}

export function renderTreeEdges(
  edges: Dataset<Coordinate<Meta>>,
): Array<ReactElement<'line'>> {
  let prev: Coordinate<any> | undefined;

  // Flatten nested array.
  const list: ReadonlyArray<Coordinate<any>> = dataset.flatten(edges);
  // Group into pairs by sequence
  return (
    list
      .reduce(
        (
          acc: Array<[Coordinate<any>, Coordinate<any>]>,
          node: Coordinate<any>,
        ) => {
          if (prev) {
            const pair: [Coordinate<any>, Coordinate<any>] = [prev, node];
            prev = undefined;
            return acc.concat([pair]);
          }
          prev = node;
          return acc;
        },
        [],
      )
      // Map to react elements!
      .map((nodePair: [Coordinate<any>, Coordinate<any>], i: number) => {
        const startNodeCoords = nodePair[0].position;
        const endNodeCoords = nodePair[1].position;
        return (
          <line
            key={`edge_${i}`}
            className={css(styles.edge)}
            x1={startNodeCoords[0]}
            y1={startNodeCoords[1]}
            x2={endNodeCoords[0]}
            y2={endNodeCoords[1]}
            strokeWidth="0.5"
          />
        );
      })
  );
}

export function renderProbability(
  midpoints: Dataset<Coordinate<Meta>>,
  readOnly: boolean,
  useLatexProbabilityInput: boolean,
  onChange: (id: number, value: string) => void,
): Array<ReactElement<typeof LatexProbabilityInput | typeof ProbabilityInput>> {
  const flatList: ReadonlyArray<Coordinate<any>> = dataset.flatten(midpoints);
  return flatList.map((midpoint, i) => {
    const xCoord = unwrap(midpoint.position[0]);
    const yCoord = unwrap(midpoint.position[1]);
    const Component = useLatexProbabilityInput
      ? LatexProbabilityInput
      : ProbabilityInput;
    return (
      <Component
        key={`probability_${i}`}
        id={midpoint.meta.id}
        x={xCoord}
        y={yCoord}
        disabled={readOnly}
        value={midpoint.meta.probability}
        onChange={onChange}
      />
    );
  });
}
