import { Fragment, useContext, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import ErrorCard from 'ms-pages/Teacher/components/ErrorCard';
import FormErrorMessage from 'ms-pages/Teacher/components/FormErrorMessage';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Separator from 'ms-pages/Teacher/components/Separator';
import useUpdateStudent from 'ms-pages/Teacher/components/StudentCreateEdit/TeacherEditStudent/updateStudent';
import StudentYearLevel from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudenYearLevel';
import StudentClasses from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentClasses';
import StudentEmail from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentEmail';
import StudentFirstName from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentFirstName';
import StudentLastName from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentLastName';
import StudentParentEmail from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentParentEmail';
import StudentPasswordReset from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentPasswordReset';
import StudentTextbookFocus from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentTextbookFocus';
import {
  StateProvider,
  StateContext,
  EMPTY_ITEM,
  UpdatersContext,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import {
  Root,
  Loading,
  Footer,
} from 'ms-pages/Teacher/components/StudentCreateEdit/styles';
import { mapItemsToIds } from 'ms-pages/Teacher/components/StudentCreateEdit/utils';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';
import { styled } from 'ms-utils/emotion';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import extractNode, { type CallExtractNode } from 'ms-utils/relay/extractNode';
import { extractResponseErrorMessages } from 'ms-utils/relay/extractResponseErrorMessages';

import type {
  TeacherEditStudentQuery,
  TeacherEditStudentQueryResponse,
} from './__generated__/TeacherEditStudentQuery.graphql';

type Student = NonNullable<TeacherEditStudentQueryResponse['student']>;
type School = NonNullable<TeacherEditStudentQueryResponse['school']>;
type SchoolClasses = CallExtractNode<NonNullable<School['classes']>>;
type AvailableSyllabuses = NonNullable<School['availableSyllabuses']>;
type LanternStudent = Student['lanternStudent'];
type Grades = LanternStudent['curriculum']['grades'];
const query = graphql`
  query TeacherEditStudentQuery(
    $studentId: ID!
    $schoolId: ID!
    $numberOfSyllabuses: Int!
    $numberOfClasses: Int!
  ) {
    student(id: $studentId) {
      id
      user {
        firstName
        lastName
        email
        syllabusFocus {
          id
          title
        }
      }
      ...StudentPasswordReset_student
      lanternStudent {
        selfReportedGrade {
          title
          id
        }
        curriculum {
          grades {
            id
            title
          }
        }
      }
      guardians {
        id
        email
        receiveNotifications
      }
      classes(first: $numberOfClasses) {
        edges {
          node {
            id
            title
            school {
              id
            }
            classGrade {
              id
              title
            }
          }
        }
      }
    }
    school(id: $schoolId) {
      availableSyllabuses(limit: $numberOfSyllabuses) {
        id
        title
      }
      classes(first: $numberOfClasses) {
        edges {
          node {
            id
            title
            classSyllabusFocus {
              id
              title
            }
            classGrade {
              id
              title
            }
          }
        }
      }
    }
  }
`;
const StudentName = styled({
  display: 'flex',
  alignItems: 'flex-start',
});
const StudentNameInput = styled({
  flex: 1,
});
type Props = {
  student: Student;
  schoolClasses: SchoolClasses;
  otherSchoolClassIds: ReadonlyArray<string>;
  availableSyllabuses: AvailableSyllabuses;
  grades: Grades;
  // Anticipate students with no class. In Waypoints, students can have no classes as
  // they can join by using the school join code instead.
  requireStudentInClass: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};
const TeacherEditStudent = ({
  student,
  schoolClasses,
  otherSchoolClassIds,
  availableSyllabuses,
  grades,
  requireStudentInClass,
  onCancel,
  onSubmit,
}: Props) => {
  const {
    firstName,
    lastName,
    classes,
    syllabusFocus,
    email,
    guardianEmails,
    selfReportedGradeId,
  } = useContext(StateContext);
  const {
    featureFlags: { classicUi },
  } = useViewer();
  const { updateFirstName, updateLastName } = useContext(UpdatersContext);
  const [updateStudentMutation, { response, loading, errors }] =
    useUpdateStudent({
      studentId: student.id,
      firstName,
      lastName,
      classIds: [...mapItemsToIds(classes), ...otherSchoolClassIds],
      syllabusFocusId: syllabusFocus.id,
      email,
      guardianEmails: guardianEmails
        .split(',')
        .map(g => g.trim())
        .filter(g => g !== ''),
      parentNotifications: true,
      // When there's no change for the student's grade, we don't want to submit
      // a value everytime a student is requested to be updated as this will
      // change `Student.grade_set_by_student_at`
      gradeId:
        student.lanternStudent.selfReportedGrade.id !== selfReportedGradeId
          ? selfReportedGradeId
          : null,
      isSunflowerUi: !classicUi,
    });
  const updatedStudent = response?.updateStudent.student;
  const responseErrors = response?.updateStudent.errors;
  const permissionErrors = extractResponseErrorMessages(responseErrors, [
    'PERMISSION',
    'INVALID_STUDENT',
  ]);
  useEffect(() => {
    if (updatedStudent != null) onSubmit();
  }, [onSubmit, updatedStudent]);
  const isSubmitting = loading || updatedStudent != null; // either the mutation or the redirect to student settings is in progress
  const isFormValid =
    firstName !== '' &&
    lastName !== '' &&
    (requireStudentInClass ? classes.length > 0 : true) &&
    syllabusFocus.id !== EMPTY_ITEM.id &&
    selfReportedGradeId !== '';
  return (
    <Root>
      <Separator size={4 * BASE_UNIT} />
      {(errors != null ||
        extractResponseErrorMessages(responseErrors, ['STUDENT_UPDATE']) // TODO
          .length > 0) && (
        <>
          <FormErrorMessage>
            Oops, there was an error. Please try again.
          </FormErrorMessage>
          <Separator size={4 * BASE_UNIT} />
        </>
      )}
      {permissionErrors.length !== 0 &&
        permissionErrors.map(error => (
          <Fragment key={error}>
            <FormErrorMessage>{error}</FormErrorMessage>
            <Separator size={4 * BASE_UNIT} />
          </Fragment>
        ))}
      <StudentName>
        <StudentNameInput>
          <StudentFirstName
            errorMessages={extractResponseErrorMessages(responseErrors, [
              'INVALID_FIRST_NAME_LENGTH',
            ])}
            onChange={value => {
              updateFirstName(value);
            }}
          />
        </StudentNameInput>
        <Separator size={4 * BASE_UNIT} />
        <StudentNameInput>
          <StudentLastName
            errorMessages={extractResponseErrorMessages(responseErrors, [
              'INVALID_LAST_NAME_LENGTH',
            ])}
            onChange={value => {
              updateLastName(value);
            }}
          />
        </StudentNameInput>
      </StudentName>
      <StudentEmail
        errorMessages={extractResponseErrorMessages(responseErrors, [
          'INVALID_EMAIL_LENGTH',
          'INVALID_EMAIL',
          'DUPLICATE_EMAIL',
        ])}
      />
      <StudentPasswordReset student={student} />

      <StudentClasses
        schoolClasses={schoolClasses}
        requireStudentInClass={requireStudentInClass}
        errorMessages={extractResponseErrorMessages(responseErrors, [
          'CLASS_IN_DIFFERENT_SCHOOL',
        ])}
      />
      <StudentTextbookFocus
        textbooks={availableSyllabuses}
        errorMessages={extractResponseErrorMessages(responseErrors, [
          'INVALID_SYLLABUS',
        ])}
      />
      <StudentYearLevel
        grades={grades}
        errorMessages={extractResponseErrorMessages(responseErrors, [
          'INVALID_STUDENT',
          'INVALID_GRADE',
        ])}
      />
      <StudentParentEmail
        errorMessages={extractResponseErrorMessages(responseErrors, [
          'INVALID_PARENT_EMAIL_LENGTH',
          'INVALID_PARENT_EMAIL',
        ])}
      />
      <Footer>
        <Button type="secondary" isDisabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          isDisabled={!isFormValid || isSubmitting}
          onClick={updateStudentMutation}
        >
          Submit
        </Button>
        <Loading visible={isSubmitting}>Submitting...</Loading>
      </Footer>
    </Root>
  );
};
type ExternalProps = {
  studentId: string;
  // Anticipate students with no class. In Waypoints, students can have no classes as
  // they can join by using the school join code instead.
  requireStudentInClass?: boolean | undefined;
  onCancel: () => void;
  onSubmit: () => void;
};
export default function TeacherEditStudentWrapper({
  studentId,
  requireStudentInClass = true,
  onCancel,
  onSubmit,
}: ExternalProps) {
  const { schoolId } = useMaybeTeacherContext();
  const { props, error } = useQuery<TeacherEditStudentQuery>(query, {
    studentId,
    schoolId,
    numberOfSyllabuses: RELAY_CONNECTION_MAX,
    numberOfClasses: RELAY_CONNECTION_MAX,
  });
  if (error != null) return <ErrorCard />;
  if (props == null) return <MinorSpinner />;
  const student = props.student;
  const availableSyllabuses = props.school?.availableSyllabuses;
  const schoolClasses = props.school?.classes;
  if (student == null || availableSyllabuses == null || schoolClasses == null) {
    Logger.error(
      new InvariantViolation('Access is restricted', {
        extra: { studentId, schoolId },
      }),
    );
    return null;
  }
  const {
    lanternStudent: {
      selfReportedGrade,
      curriculum: { grades },
    },
  } = student;
  const studentClasses = extractNode(student.classes);
  const initialValue = {
    firstName: student.user.firstName,
    lastName: student.user.lastName,
    email: student.user.email,
    classes: studentClasses
      .filter(c => c.school != null && c.school.id === schoolId)
      .map(c => ({ id: c.id, title: c.title })),
    // Only show those who are subscribed to receive notifications
    guardianEmails: student.guardians
      .filter(g => g.receiveNotifications)
      .map(g => g.email)
      .join(', '),
    syllabusFocus: {
      id: student.user.syllabusFocus.id,
      title: student.user.syllabusFocus.title,
    },
    useClassSyllabusFocus: false,
    selfReportedGradeId: selfReportedGrade.id,
    useClassGrade: false,
  };
  return (
    <StateProvider {...initialValue}>
      <TeacherEditStudent
        student={student}
        schoolClasses={extractNode<NonNullable<School['classes']>>(
          schoolClasses,
        )}
        otherSchoolClassIds={studentClasses
          .filter(c => c.school != null && c.school.id !== schoolId)
          .map(c => c.id)}
        availableSyllabuses={availableSyllabuses}
        grades={grades}
        requireStudentInClass={requireStudentInClass}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </StateProvider>
  );
}
