/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingsContainerLanternStudent = {
    readonly curriculum: {
        readonly grades: ReadonlyArray<{
            readonly id: string;
            readonly shortTitle: string;
        }>;
    };
    readonly selfReportedGrade: {
        readonly id: string;
        readonly title: string;
    };
    readonly " $refType": "SettingsContainerLanternStudent";
};
export type SettingsContainerLanternStudent$data = SettingsContainerLanternStudent;
export type SettingsContainerLanternStudent$key = {
    readonly " $data"?: SettingsContainerLanternStudent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SettingsContainerLanternStudent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingsContainerLanternStudent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternCurriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternGrade",
          "kind": "LinkedField",
          "name": "grades",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "shortTitle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternGrade",
      "kind": "LinkedField",
      "name": "selfReportedGrade",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
})();
(node as any).hash = '961cf337fd000237b62ddcbc96577baf';
export default node;
