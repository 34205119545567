import { useState, useEffect } from 'react';

import localStorageDb, { sessionStorageDb } from 'ms-utils/localStorageDb';

type Options<S> = {
  dbKey: string;
  // if the storage value returns null, return this instead
  // seed the session storage with this value
  fallbackValue: S;
};

type CompleteOptions<S> = Options<S> & {
  db: { get: (key: string) => S; set: (key: string, value: S) => void };
};

type ReturnType<S> = [S, (stateOrUpdater: ((value: S) => S) | S) => void];
/*
 * This syncs a particular state with session storage.
 * Will seed itself with the passed session storage key.
 *
 * If passed an initial state will overwrite the set session storage with initial state.
 */
export function useSyncedState<S>({
  db,
  dbKey,
  fallbackValue,
}: CompleteOptions<S>): ReturnType<S> {
  const syncedInitialState = db.get(dbKey) ?? fallbackValue;

  const [state, setState] = useState<S>(syncedInitialState);

  useEffect(
    () => {
      db.set(dbKey, state);
    },
    [state, dbKey], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [state, setState];
}

export default function useSessionSyncedState<S>({
  dbKey,
  fallbackValue,
}: Options<S>): ReturnType<S> {
  return useSyncedState<S>({
    db: sessionStorageDb,
    dbKey,
    fallbackValue,
  });
}

export function useLocalSyncedState<S>({
  dbKey,
  fallbackValue,
}: Options<S>): ReturnType<S> {
  return useSyncedState<S>({
    db: localStorageDb,
    dbKey,
    fallbackValue,
  });
}
