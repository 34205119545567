import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm2.856-5a.86.86 0 0 1-.367-.082l-2.304-1.086-2.304 1.086a.86.86 0 0 1-.874-.084.87.87 0 0 1-.35-.81l.316-2.539-1.74-1.866a.873.873 0 0 1-.19-.863.866.866 0 0 1 .658-.584L8.2 7.689l1.228-2.24a.864.864 0 0 1 1.514 0l1.228 2.24 2.499.483a.872.872 0 0 1 .468 1.447l-1.74 1.867.316 2.537a.87.87 0 0 1-.857.977z"
  />
);
const StarFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

StarFilled.displayName = 'StarFilled';

export default StarFilled;
