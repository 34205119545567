import { serialize } from 'ms-utils/urls/queryParams';

export type StudentSkillsMapActiveTab = 'topics' | 'skills';
export const STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME =
  'student-skills-map-skill-tooltip-shown';

export const homePath = '/lantern';
export const homeUrl = '/lantern';

export const supportEmail = 'lanternsupport@mathspace.co';
export const supportEmailUrl = `mailto:${supportEmail}`;

// Student urls

export const studentPath = `${homePath}/student`;

export const tutorialStrandPath = `${studentPath}/tutorial/strand`;
export const tutorialStrandUrl = `${studentPath}/tutorial/strand`;

export const checkInOldPath = `${studentPath}/check-in`;
export const checkInOldUrl = `${studentPath}/check-in`;

export const checkInGrowthOldPath = `${checkInOldPath}/growth/`;
export const checkInGrowthOldUrl = `${checkInOldPath}/growth/`;

export const checkInBasePath = `${studentPath}/check-in`;

export const checkInPath = `${checkInBasePath}/:checkInId`;
export function getCheckInUrl({ checkInId }: { checkInId: string }) {
  return `${checkInBasePath}/${checkInId}`;
}

export const checkInStrandPath = `${checkInBasePath}/strand/:strandId`;
export function getCheckInStrandUrl({ strandId }: { strandId: string }) {
  return `${checkInBasePath}/strand/${strandId}`;
}

export const checkInStrandDiagnosticPath = `${checkInBasePath}/strand/:strandId/diagnostic`;
// TODO: to be deprecated
export function getCheckInStrandDiagnosticUrl({
  strandId,
}: {
  strandId: string;
}) {
  return `${checkInBasePath}/strand/${strandId}/diagnostic`;
}

// Strand growth check-ins are already deprecated. The URL path was kept around so that
// we can redirect requests for a deprecated feature. Hence, no get function exists for
// building the path.
export const checkInStrandGrowthPath = `${checkInBasePath}/strand/:strandId/growth`;

export type CheckInSubstrandGrowthPathParams = { substrandId: string };
export const checkInSubstrandGrowthPath = `${checkInBasePath}/substrand/:substrandId/growth`;
export const getCheckInSubstrandGrowthUrl = ({
  substrandId,
}: CheckInSubstrandGrowthPathParams) =>
  `${checkInBasePath}/substrand/${substrandId}/growth`;

export type CheckInSkillPathParams = {
  strandId: string;
  skillId: string;
  skipWelcome?: boolean;
};
export const checkInSkillPath = `${checkInBasePath}/strand/:strandId/skill/:skillId`;
export const getCheckInSkillUrl = ({
  strandId,
  skillId,
}: CheckInSkillPathParams) =>
  `${checkInBasePath}/strand/${strandId}/skill/${skillId}`;

export const checkInPreTopicTestPath = `${checkInBasePath}/skills/:skillIds`;

export const studentSkillsPath = `${studentPath}/skills`;
export const studentSkillsSubstrandPath = `${studentPath}/skills/strand/:strandId/substrand/:substrandId`;
export type StudentSkillSubstrandUrlParams = {
  strandId: string;
  substrandId: string;
};
export function getStudentSkillsUrl({
  strandId,
  activeTab,
}:
  | {
      strandId?: string;
      activeTab?: never;
    }
  | { strandId?: never; activeTab?: StudentSkillsMapActiveTab }): string {
  return `${studentSkillsPath}${strandId != null ? `/strand/${strandId}` : ''}${
    activeTab != null ? `${serialize({ activeTab })}` : ''
  }`;
}

export function getStudentSkillsSubstrandUrl({
  strandId,
  substrandId,
}: StudentSkillSubstrandUrlParams): string {
  return `${studentSkillsPath}/strand/${strandId}/substrand/${substrandId}`;
}

// This is to link a skill to its skills map substrand view and skill popover open
type StudentSkillUrlParams = {
  strandId: string;
  substrandId: string;
  outcomeId: string;
};

export function getStudentSkillUrl({
  strandId,
  substrandId,
  outcomeId,
}: StudentSkillUrlParams): string {
  return `${getStudentSkillsSubstrandUrl({ strandId, substrandId })}${serialize(
    {
      [STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME]: outcomeId,
    },
  )}`;
}

// Teacher urls

export const teacherPath = `${homePath}/teacher`;
export const teacherUrl = `${homePath}/teacher`;

export function getTeacherDownloadCSVUrlUsingClass({
  curriculumId,
  classId,
  previewingWithProblemData,
  growthPeriod,
}: {
  curriculumId: string;
  classId?: string | null;
  previewingWithProblemData: boolean;
  growthPeriod: number;
}) {
  return `${homePath}/export-student-data/?curriculumId=${curriculumId}${
    classId != null ? `&classId=${classId}` : ''
  }&previewingWithProblemData=${
    previewingWithProblemData ? 'Y' : ''
  }&growthPeriod=${growthPeriod}`;
}

export const teacherStudentPath = `${teacherPath}/student/:studentId`;
export function getTeacherStudentUrl({
  studentId,
}: {
  studentId: string;
}): string {
  return `${teacherPath}/student/${studentId}`;
}
export const teacherStudentSkillsPath = `${teacherStudentPath}/skills`;
export function getTeacherStudentSkillsUrl({
  studentId,
}: {
  studentId: string;
}): string {
  return `${getTeacherStudentUrl({ studentId })}/skills`;
}

// Other urls

function getMathspaceSyllabusId(curriculumId: string): string {
  switch (curriculumId) {
    case 'LanternCurriculum-1':
      return 'Syllabus-1076';
    case 'LanternCurriculum-2':
      return 'Syllabus-1079';
    case 'LanternCurriculum-3':
      return 'Syllabus-1084';
    case 'LanternCurriculum-4':
      return 'Syllabus-1079';
    case 'LanternCurriculum-6':
      return 'Syllabus-1086';
    case 'LanternCurriculum-8':
      return 'Syllabus-1087';
    default:
      return 'Syllabus-1076'; // Default to Australian Curriculum
  }
}
export const getMathspaceTextbookUrl = ({
  curriculumId,
  outcomeCode,
}: {
  curriculumId: string;
  outcomeCode: string;
}): string =>
  `/textbooks/syllabuses/${getMathspaceSyllabusId(
    curriculumId,
  )}?searchString=${outcomeCode}`;
