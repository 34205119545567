import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M12.5851612,11.3111803 C12.8383522,11.1160518 13.1556234,11 13.5,11 C13.7223195,11 13.9333426,11.048366 14.1231211,11.1351498 L15.416677,8.53750563 C15.1585793,8.26808252 15,7.90256035 15,7.5 C15,6.67157288 15.6715729,6 16.5,6 C17.3284271,6 18,6.67157288 18,7.5 C18,8.32842712 17.3284271,9 16.5,9 C16.4354551,9 16.3718624,8.99592331 16.3094653,8.98801336 L14.9475759,11.7228806 C14.9228609,11.772512 14.891007,11.8162339 14.8538766,11.853422 C14.9475471,12.0492068 15,12.2684779 15,12.5 C15,13.3284271 14.3284271,14 13.5,14 C12.6715729,14 12,13.3284271 12,12.5 C12,12.3827585 12.0134508,12.2686585 12.0388934,12.1591591 L8.17646928,8.88121893 C8.1660908,8.87241098 8.15616116,8.8632705 8.14668273,8.85382647 C8.02819203,8.91052803 7.90109733,8.95213193 7.76788955,8.97614727 L4.9,12.8 C4.88566788,12.8191095 4.87023046,12.836901 4.85384322,12.8533523 C4.94753464,13.0491547 5,13.2684504 5,13.5 C5,14.3284271 4.32842712,15 3.5,15 C2.67157288,15 2,14.3284271 2,13.5 C2,12.6715729 2.67157288,12 3.5,12 C3.72973174,12 3.9474012,12.0516448 4.14203171,12.1439577 L6.68189737,8.75747017 C6.27137,8.48983705 6,8.02660946 6,7.5 C6,6.67157288 6.67157288,6 7.5,6 C8.32842712,6 9,6.67157288 9,7.5 C9,7.73094927 8.94780634,7.94970806 8.85457113,8.14512427 L12.5851612,11.3111803 Z" />
);

const Scorecard = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Scorecard.displayName = 'Scorecard';

export default Scorecard;
