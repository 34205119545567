/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UndiscoveredSkillsQueryVariables = {
    checkInId: string;
};
export type UndiscoveredSkillsQueryResponse = {
    readonly viewer: {
        readonly username: string;
    };
    readonly lantern: {
        readonly checkIn: {
            readonly strand: {
                readonly gradeStrands: ReadonlyArray<{
                    readonly grade: {
                        readonly order: number;
                    };
                    readonly outcomes: ReadonlyArray<{
                        readonly skill: {
                            readonly id: string;
                            readonly title: string;
                        };
                    }>;
                }>;
            } | null;
        } | null;
    };
};
export type UndiscoveredSkillsQuery = {
    readonly response: UndiscoveredSkillsQueryResponse;
    readonly variables: UndiscoveredSkillsQueryVariables;
};



/*
query UndiscoveredSkillsQuery(
  $checkInId: ID!
) {
  viewer {
    username
    id
  }
  lantern {
    checkIn(id: $checkInId) {
      strand {
        gradeStrands {
          grade {
            order
            id
          }
          outcomes {
            skill {
              id
              title
            }
            id
          }
          id
        }
        id
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkInId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "checkInId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UndiscoveredSkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Strand",
                "kind": "LinkedField",
                "name": "strand",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradeStrand",
                    "kind": "LinkedField",
                    "name": "gradeStrands",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternGrade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternOutcome",
                        "kind": "LinkedField",
                        "name": "outcomes",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UndiscoveredSkillsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Strand",
                "kind": "LinkedField",
                "name": "strand",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradeStrand",
                    "kind": "LinkedField",
                    "name": "gradeStrands",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternGrade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternOutcome",
                        "kind": "LinkedField",
                        "name": "outcomes",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d02f2d118a69e3652e5d14780a202f7b",
    "id": null,
    "metadata": {},
    "name": "UndiscoveredSkillsQuery",
    "operationKind": "query",
    "text": "query UndiscoveredSkillsQuery(\n  $checkInId: ID!\n) {\n  viewer {\n    username\n    id\n  }\n  lantern {\n    checkIn(id: $checkInId) {\n      strand {\n        gradeStrands {\n          grade {\n            order\n            id\n          }\n          outcomes {\n            skill {\n              id\n              title\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '03db11594c0f2a61fca603b88cf8e74c';
export default node;
