import { graphql, useMutation } from 'react-relay';

import type {
  UpdatableByUserFeatureFlagEnum,
  featureFlagsMutation,
} from './__generated__/featureFlagsMutation.graphql';

export const useFeatureFlagsMutation = () => {
  const [commit] = useMutation<featureFlagsMutation>(graphql`
    mutation featureFlagsMutation(
      $featureName: UpdatableByUserFeatureFlagEnum!
      $featureValue: Boolean!
    ) {
      updateFeatureFlag: updateTypedFeatureFlag(
        feature: $featureName
        value: $featureValue
      ) {
        viewer {
          featureFlags: typedFeatureFlags {
            ...featureFlags @relay(mask: false)
          }
        }
      }
    }
  `);
  return (
    featureName: UpdatableByUserFeatureFlagEnum,
    featureValue: boolean,
  ) => {
    commit({
      variables: { featureName, featureValue },
      onCompleted: ({ updateFeatureFlag }) => {
        if (!(updateFeatureFlag && updateFeatureFlag.viewer)) {
          throw new Error(
            'empty mutation result: feature flags may be misconfigured',
          );
        }
      },
    });
  };
};
