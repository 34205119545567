import EmptyContentBrowsingIllustration from 'ms-components/illustrations/Empty';
import { fontFamily, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const EmptyDetail = styled({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minHeight: 1,
  height: '100%',
});

const EmptyMessageTitle = styled({
  color: colors.santasGray,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: 4 * BASE_UNIT,
  marginBottom: 2 * BASE_UNIT,
  marginTop: 10 * BASE_UNIT,
  textAlign: 'center',
  lineHeight: lineHeight.title,
});

export default function SubtopicEmptyView() {
  return (
    <EmptyDetail data-test-id="empty-state">
      <EmptyContentBrowsingIllustration />
      <EmptyMessageTitle>
        You don't have any subtopics selected
      </EmptyMessageTitle>
    </EmptyDetail>
  );
}
