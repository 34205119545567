import { StyleSheet } from 'aphrodite';

import Toolbox from 'ms-components/Toolbox';
import type { Props } from 'ms-components/Toolbox/Toolbox';
import { borderRadiusUILarge } from 'ms-styles/base';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';

const styles = StyleSheet.create({
  modal: {
    borderRadius: borderRadiusUILarge,
  },
});

function ToolboxModal({ onClose, isOpen, ...rest }: Props) {
  return (
    <Modal
      theme="dark"
      onClose={onClose}
      isOpen={isOpen}
      modalBoxStyles={styles.modal}
    >
      <ModalBody>
        <Toolbox noPopover isOpen onClose={onClose} {...rest} />
      </ModalBody>
    </Modal>
  );
}

export default ToolboxModal;
