import { compose, defaultTo, find, groupBy, map, reduce, pathOr } from 'ramda';

import {
  Hierarchical as HierarchicalSelector,
  Flat as FlatSelector,
} from 'ms-components/Selector';
import type { Hierarchy } from 'ms-components/Selector/Hierarchical';
import type {
  CohortType,
  AccountType,
} from 'ms-pages/Settings/__generated__/SettingsContainerQuery.graphql';
import Modal from 'ms-ui-primitives/Modal';
import { rebindKey } from 'ms-utils/ramda-extensions';
import { unwrap } from 'ms-utils/typescript-utils';

const WIDTH = 300;
const SYLLABUS_COUNT_HIEARCHICAL_FALLBACK = 20;
// This matches the shape of the syllabus returned by GraphQL — 24.01.17
type Syllabus = {
  readonly pk: number;
  readonly title: string;
  readonly curriculum: {
    readonly countrySubdivision: string;
    readonly country:
      | {
          readonly name: string;
        }
      | null
      | undefined;
  };
};
type Props = {
  isOpen: boolean;
  syllabusList: ReadonlyArray<Syllabus>;
  selection: Syllabus | null | undefined;
  onSelect: (selectionId: number) => void;
  onClose: () => void;
  viewer:
    | {
        readonly cohortType: CohortType;
        readonly accountType: AccountType;
      }
    | null
    | undefined;
};
const countryName = (syllabus: Syllabus | null | undefined): string =>
  pathOr('International', ['curriculum', 'country', 'name'], syllabus);
const stateName = (name: string) => name || 'National';
// @ts-expect-error Ramda and TS don't work well together
const getSyllabusHierarchy: (
  syllabusList: ReadonlyArray<Syllabus>,
) => Hierarchy =
  // TODO refactor this implementation to not use Ramda
  // @ts-expect-error Ramda and TS don't work well together
  compose(
    map(
      map(
        reduce(
          (acc, s) => ({
            ...acc,
            // @ts-expect-error Ramda and TS don't work well together
            [s.title]: null,
          }),
          {},
        ),
      ),
    ),
    map(rebindKey('', 'National')),
    rebindKey('', 'International'),
    map(groupBy(pathOr('', ['curriculum', 'countrySubdivision']))),
    groupBy(pathOr('', ['curriculum', 'country', 'name'])),
  );
const getSyllabusPath = (syllabusFocus: Syllabus | null | undefined) => {
  if (!syllabusFocus || syllabusFocus.curriculum.country == null) {
    return [];
  }
  return [
    countryName(syllabusFocus),
    stateName(syllabusFocus.curriculum.countrySubdivision),
    syllabusFocus.title,
  ];
};
const getId = (
  [country, state, title]: string[],
  syllabusList: ReadonlyArray<Syllabus>,
) =>
  compose(
    // @ts-expect-error Ramda and TS don't work well together
    obj => obj.pk,
    defaultTo({ pk: NaN }),
    find<(typeof syllabusList)[number]>(
      s =>
        s.title === title &&
        countryName(s) === country &&
        stateName(s.curriculum.countrySubdivision) === state,
    ),
  )(syllabusList);
const comparator = (a: string, b: string): number =>
  a === 'National'
    ? -1
    : b === 'National'
    ? 1
    : a === 'International'
    ? 1
    : b === 'International'
    ? -1
    : a.localeCompare(b);
const SyllabusSelector = (props: Props) => (
  <div>
    <Modal
      isOpen={props.isOpen}
      showCloseButton={false}
      closeOnOverlayTap
      onClose={props.onClose}
      width={WIDTH}
    >
      {props.viewer == null ||
      props.viewer.cohortType === 'SELF_PAID' ||
      props.viewer.accountType === 'STAFF' ||
      props.syllabusList.length > SYLLABUS_COUNT_HIEARCHICAL_FALLBACK ? (
        <HierarchicalSelector
          segmentTitles={['Select Country', 'Select State', 'Select Syllabus']}
          hierarchy={getSyllabusHierarchy(props.syllabusList)}
          selection={getSyllabusPath(props.selection)}
          onSelect={selection => {
            props.onSelect(getId(selection, props.syllabusList));
          }}
          onClose={props.onClose}
          width={WIDTH}
          comparator={comparator}
        />
      ) : (
        <FlatSelector
          title="Select a textbook"
          items={props.syllabusList.map(s => s.title)}
          onSelect={selection =>
            props.onSelect(unwrap(props.syllabusList[selection]).pk)
          }
          selectedIndex={
            props.selection != null
              ? props.syllabusList.findIndex(
                  syllabus =>
                    props.selection != null &&
                    syllabus.pk === props.selection.pk,
                )
              : null
          }
          onClose={props.onClose}
          width={WIDTH}
        />
      )}
    </Modal>
  </div>
);
export default SyllabusSelector;
