import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g>
    <g transform="translate(4.000000, 1.000000)">
      <path
        d="M0.861596853,4.34865341 C0.666554478,4.54369576 0.350328308,4.54369576 0.155285933,4.34865341 C-0.0397564375,4.15361101 -0.0397564375,3.83738484 0.155285933,3.64234247 L3.65134661,0.146281789 C3.84638899,-0.0487605863 4.16261514,-0.0487605863 4.35765754,0.146281789 C4.55269989,0.341324164 4.55269989,0.657550334 4.35765754,0.852592709 L0.861596853,4.34865341 Z M0.508441393,3.49606069 L7.50056274,3.49606069 C8.16647909,3.49606069 8.16647909,4.49493516 7.50056274,4.49493516 L0.508441393,4.49493516 C-0.157474927,4.49493516 -0.157474927,3.49606069 0.508441393,3.49606069 Z M0.861596853,3.64234247 L4.35765754,7.13840316 C4.82853149,7.60927711 4.12222054,8.31558801 3.65134661,7.84471406 L0.155285933,4.34865341 C-0.315588012,3.87777944 0.390722908,3.17146852 0.861596853,3.64234247 Z"
        transform="translate(4.000000, 4.000000) scale(-1, 1) rotate(90.000000) translate(-4.000000, -4.000000) "
      />
    </g>
    <path d="M1,0.99609375 L1,9" stroke="currentColor" strokeLinecap="square" />
  </g>
);

export default function MoveToTop({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      viewBoxHeight={10}
      viewBoxWidth={12}
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
    >
      {path}
    </Icon>
  );
}
