import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Minus = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    <g style={{ color }}>
      <line
        x1="4"
        y1="10"
        x2="16"
        y2="10"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);

Minus.displayName = 'Minus';

export default Minus;
