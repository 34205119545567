import moment from 'moment';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';

export type TaskName = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;

export type State = {
  taskName: TaskName;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  activeSubtopic: SelectedSubtopic | null | undefined;
  selectedClasses: ReadonlyArray<ClassSelectionPayload>;
  selectedStudents: ReadonlyArray<StudentSelectionPayload>;
  excludedStudents: ReadonlyArray<StudentSelectionPayload>;
};

export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;

// this has to be recreated each time to keep the dates synced
export const getInitialState = (): State => ({
  taskName: 'Engage Task',
  taskStartDate: moment().seconds(0).milliseconds(0).toDate(),
  taskDueDate: moment()
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .add(7, 'days')
    .toDate(),
  selectedClasses: [],
  selectedStudents: [],
  excludedStudents: [],
  activeSubtopic: null,
});
