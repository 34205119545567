import { useEffect } from 'react';

import useFlagTimeout from './useFlagTimeout';
import { usePrevious } from './usePrevious';

// This hook is used to animate a component depending on a value change, to start when a condition is met and stop the animation when another condition is met.
export default function useAnimationOnChange<T>({
  animationTime,
  observedValue,
  turnOnWhen,
  turnOffWhen,
}: {
  animationTime: number;
  observedValue: T;
  turnOnWhen: (value: T, prevValue: T) => boolean;
  turnOffWhen: (value: T, prevValue: T) => boolean;
}) {
  const [isAnimating, setIsAnimatingTemporarily] =
    useFlagTimeout(animationTime);

  const prevValue = usePrevious(observedValue);

  useEffect(() => {
    if (prevValue != null) {
      if (turnOnWhen(observedValue, prevValue)) {
        setIsAnimatingTemporarily(true);
      } else if (turnOffWhen(observedValue, prevValue)) {
        setIsAnimatingTemporarily(false);
      }
    }
  }, [
    turnOnWhen,
    turnOffWhen,
    prevValue,
    setIsAnimatingTemporarily,
    observedValue,
  ]);

  return isAnimating;
}
