import type { ComponentType } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import StudentTextbookRedirector from 'ms-pages/Textbooks/StudentTextbook/components/StudentTextbookRedirector';
import TeacherTextbookRedirector from 'ms-pages/Textbooks/TeacherTextbook/components/TeacherTextbookRedirector';

export const TextbookRedirector: ComponentType<{}> = () => {
  const { role } = useViewer();

  return (
    <>
      {role === 'Teacher' && <TeacherTextbookRedirector />}
      {role === 'Student' && <StudentTextbookRedirector />}
    </>
  );
};
