import styled from '@emotion/styled';
import { useQuery, graphql } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import {
  StudentLayout,
  StudentCheckInLayout as Layout,
} from 'ms-pages/Lantern/components/StudentLayout';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import Main from 'ms-pages/Lantern/primitives/Main';
import {
  HStack,
  HSpacer,
  VStack,
  VSpacer,
} from 'ms-pages/Lantern/primitives/Stack';
import { BodyL, HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import { JustStartCheckIn } from 'ms-pages/Lantern/views/Student/CheckIn/CheckInSubstrand/StartSubstrandGrowthCheckIn';
import useStartSkillCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/useStartSkillCheckIn';
import { NotFoundError } from 'ms-utils/app-logging';
import { useUncheckedQueryParam } from 'ms-utils/hooks/useQueryParam';
import { studentDashboardPath } from 'ms-utils/urls';

import type { StartSkillCheckInQuery } from './__generated__/StartSkillCheckInQuery.graphql';
import { CheckInUnavailableError } from '../CheckInUnavailableError';
import { SkillCheckInWelcomeScreen } from '../CheckInWelcomeScreen';

const SKILL_CHECKIN_NUMBER_OF_QUESTIONS = 2;
const RootVStack = styled(VStack)({
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  textAlign: 'center',
});
const START_SKILL_CHECK_IN_QUERY = graphql`
  query StartSkillCheckInQuery($skillId: ID!) {
    lantern {
      skill(id: $skillId) {
        title
      }

      viewer {
        __typename
        ... on LanternStudent {
          checkIns {
            id
            type
          }
        }
      }
    }
  }
`;
type Props = {
  skillId: string;
  strandId: string;
  skipWelcome?: boolean | undefined;
};
export default function StartSkillCheckIn({ skillId, strandId }: Props) {
  const { loading, errors, start } = useStartSkillCheckIn({
    strandId,
    skillId,
    replace: true,
  });
  const { props, error } = useQuery<StartSkillCheckInQuery>(
    START_SKILL_CHECK_IN_QUERY,
    {
      skillId,
    },
  );
  const { trackStructEvent } = useSnowplow();
  const [skipWelcome] = useUncheckedQueryParam('skipWelcome');
  if (error != null) {
    // we can fail silently if this query doesn't resolve and don't block the UX
    return <JustStartCheckIn start={start} />;
  }
  if (props == null) return <LoadingSpinner />;
  if (
    props.lantern.viewer == null ||
    props.lantern.viewer.__typename !== 'LanternStudent'
  ) {
    throw new Error('User is not logged in or is not a student');
  }
  const { checkIns } = props.lantern.viewer;
  if (errors.find(e => e.key === 'CHECK_IN_NOT_AVAILABLE') !== undefined) {
    return <CheckInUnavailableError />;
  }
  if (errors.length > 0) {
    return (
      <StudentLayout>
        <Main>
          <RootVStack>
            <HeadingL>There was an error loading this page</HeadingL>
            <VSpacer height={24} />
            <BodyL color="peachPink">
              {errors.map(e => e.message).join('')}
            </BodyL>
            <VSpacer height={40} />
            <HStack>
              <Button
                size="lanternMedium"
                onClick={start}
                type="primary"
                isDisabled={loading}
              >
                Try again
              </Button>
              <HSpacer width={32} />
              <Button
                size="lanternMedium"
                onClick={() => {
                  if (window.history.length > 1) window.history.back();
                  else window.location.assign(studentDashboardPath);
                }}
                type="primary"
              >
                Go back
              </Button>
            </HStack>
          </RootVStack>
        </Main>
      </StudentLayout>
    );
  }
  const { skill } = props.lantern;
  if (skill == null) {
    throw new NotFoundError(
      `Skill for student not found. Skill id: ${skillId}`,
    );
  }
  const isFirstSkillCheckIn =
    checkIns.filter(checkIn => checkIn.type === 'SKILL_CHECKIN').length === 0;
  return skipWelcome != null ? (
    <JustStartCheckIn start={start} />
  ) : (
    <Layout backgroundColor="almond50">
      <SkillCheckInWelcomeScreen
        skillId={skillId}
        checkInTitle={skill.title}
        isLoading={loading}
        onSubmit={() => {
          trackStructEvent({
            category: 'student_skills_map_substrand',
            action: 'clicked_start_check_in',
            label: 'skill',
          });
          start();
        }}
        onClose={() => {
          trackStructEvent({
            category: 'student_check_in',
            action: 'exit_checkin',
            value: 0,
          });
          window.location.assign(studentDashboardPath);
        }}
        checkInType="SKILL_CHECKIN"
        numberOfQuestions={SKILL_CHECKIN_NUMBER_OF_QUESTIONS}
        isFirstSkillCheckIn={isFirstSkillCheckIn}
      />
    </Layout>
  );
}
