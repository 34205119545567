// THIS FILE IS AUTOGENERATED. DO NOT EDIT MANUALLY
//
// This index file is generated to make sure that icons can be easily
// imported in the following ways:

// import SchoolIcon from 'ms-components/icons/School';
// or
// import { School } from 'ms-components/icons';
// or
// import Icons from 'ms-components/icons';
// import * as Icons from 'ms-components/icons';
// and then used in JSX (typings are safe!)
// <Icons.School color={ICON_COLOR} size={ICON_SIZE} />

import Accessibility from './Accessibility';
import Activity from './Activity';
import AdaptiveTask from './AdaptiveTask';
import AdminReports from './AdminReports';
import Archive from './Archive';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowLeftNew from './ArrowLeftNew';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import Bell from './Bell';
import BetaBadge from './BetaBadge';
import BugReport from './BugReport';
import BulletedList from './BulletedList';
import Calculator from './Calculator';
import Calendar from './Calendar';
import CaretBottom from './CaretBottom';
import CaretLeft from './CaretLeft';
import CaretRight from './CaretRight';
import CaretTop from './CaretTop';
import CaretTopAndBottom from './CaretTopAndBottom';
import ChatBubble from './ChatBubble';
import CheckMark from './CheckMark';
import CheckMarkDashed from './CheckMarkDashed';
import CheckMarkFilled from './CheckMarkFilled';
import CheckMarkOutlined from './CheckMarkOutlined';
import CheckMarkSquiggly from './CheckMarkSquiggly';
import CheckMarkThick from './CheckMarkThick';
import Checkpoints from './Checkpoints';
import ChevronBottom from './ChevronBottom';
import ChevronLeft from './ChevronLeft';
import ChevronLeftThick from './ChevronLeftThick';
import ChevronRight from './ChevronRight';
import ChevronRightBold from './ChevronRightBold';
import ChevronRightThick from './ChevronRightThick';
import ChevronTop from './ChevronTop';
import Circle from './Circle';
import CircleHollow from './CircleHollow';
import Class from './Class';
import ClassAssignee from './ClassAssignee';
import Clear from './Clear';
import Clipboard from './Clipboard';
import Clock from './Clock';
import ClockFilled from './ClockFilled';
import Confetti from './Confetti';
import Copy from './Copy';
import CopyThick from './CopyThick';
import CorrectFinalStep from './CorrectFinalStep';
import CreateNote from './CreateNote';
import CreateTemplateGroupIcon from './CreateTemplateGroupIcon';
import CreateTemplateIcon from './CreateTemplateIcon';
import Cross from './Cross';
import CrossBold from './CrossBold';
import CrossFilled from './CrossFilled';
import CrossOutlined from './CrossOutlined';
import CrossThick from './CrossThick';
import CrossedCircle from './CrossedCircle';
import Dashboard from './Dashboard';
import Dictionary from './Dictionary';
import DifficultyEasy from './DifficultyEasy';
import DifficultyHard from './DifficultyHard';
import DifficultyMedium from './DifficultyMedium';
import District from './District';
import DndHandle from './DndHandle';
import DoubleRight from './DoubleRight';
import DoubleRightChevron from './DoubleRightChevron';
import Download from './Download';
import Drag from './Drag';
import Duplicate from './Duplicate';
import EllipsisHorizontal from './EllipsisHorizontal';
import EllipsisVertical from './EllipsisVertical';
import EllipsisVertical2 from './EllipsisVertical2';
import EngageTask from './EngageTask';
import Eraser from './Eraser';
import Eraser2 from './Eraser2';
import ExclamationMark from './ExclamationMark';
import ExclamationMarkFilled from './ExclamationMarkFilled';
import ExclamationMarkOutlined from './ExclamationMarkOutlined';
import Eye from './Eye';
import Facebook from './Facebook';
import FacebookRound from './FacebookRound';
import FacebookSimple from './FacebookSimple';
import FeedbackNegative from './FeedbackNegative';
import FeedbackPositive from './FeedbackPositive';
import Filter from './Filter';
import Flag from './Flag';
import FlyoutToggle from './FlyoutToggle';
import Folder from './Folder';
import FolderDistrict from './FolderDistrict';
import FolderMathspace from './FolderMathspace';
import FolderMove from './FolderMove';
import FolderOpen from './FolderOpen';
import FolderRemove from './FolderRemove';
import FreehandDraw from './FreehandDraw';
import GeometryCalculatorIcon from './GeometryCalculatorIcon';
import Glossary from './Glossary';
import Google from './Google';
import GraduateIcon from './GraduateIcon';
import GraphingCalculatorIcon from './GraphingCalculatorIcon';
import Hamburger from './Hamburger';
import HandPalm from './HandPalm';
import Highlighter from './Highlighter';
import Hint from './Hint';
import Home from './Home';
import InfoFilled from './InfoFilled';
import Intercom from './Intercom';
import Internationalisation from './Internationalisation';
import Investigation from './Investigation';
import Lantern from './Lantern';
import Lesson from './Lesson';
import Link from './Link';
import Linkedin from './Linkedin';
import Live from './Live';
import Location from './Location';
import Lock from './Lock';
import Lock2 from './Lock2';
import Logout from './Logout';
import Loop from './Loop';
import LowerScore from './LowerScore';
import Mail from './Mail';
import MasteryLevelRibbon from './MasteryLevelRibbon';
import Mathspace from './Mathspace';
import MathspacePremium from './MathspacePremium';
import Megaphone from './Megaphone';
import MenuSquares from './MenuSquares';
import Minus from './Minus';
import MoveToBottom from './MoveToBottom';
import MoveToTop from './MoveToTop';
import MutedSound from './MutedSound';
import NewFolder from './NewFolder';
import NewFolder2 from './NewFolder2';
import NewTemplate from './NewTemplate';
import NextStep from './NextStep';
import NextStepFilled from './NextStepFilled';
import NextStepOutlined from './NextStepOutlined';
import NotAvailableInAccessibilityMode from './NotAvailableInAccessibilityMode';
import NotLoadedImagePlaceholder from './NotLoadedImagePlaceholder';
import NoteIcon from './NoteIcon';
import PIP from './PIP';
import PIPMode from './PIPMode';
import PaperSheet from './PaperSheet';
import PaperSheetThumbsUp from './PaperSheetThumbsUp';
import Pdf from './Pdf';
import Pencil from './Pencil';
import Pencil2 from './Pencil2';
import Play from './Play';
import PlayingSound from './PlayingSound';
import PlayingSoundMuted from './PlayingSoundMuted';
import Plus from './Plus';
import Practice from './Practice';
import Print from './Print';
import Profile from './Profile';
import QuestionMark from './QuestionMark';
import QuestionMarkFilled from './QuestionMarkFilled';
import QuestionMarkOutlined from './QuestionMarkOutlined';
import QuestionSet from './QuestionSet';
import RecommendedPractice from './RecommendedPractice';
import RectangleBorderedFilled from './RectangleBorderedFilled';
import Redo from './Redo';
import Refresh from './Refresh';
import Resize from './Resize';
import Restore from './Restore';
import Retry from './Retry';
import School from './School';
import ScientificCalculatorIcon from './ScientificCalculatorIcon';
import Scorecard from './Scorecard';
import Search from './Search';
import SearchMinus from './SearchMinus';
import SearchPlus from './SearchPlus';
import SettingsCog from './SettingsCog';
import Share from './Share';
import Shuffle from './Shuffle';
import Sketchpad from './Sketchpad';
import SketchpadBasic from './SketchpadBasic';
import Skill from './Skill';
import SkillsMap from './SkillsMap';
import SortRight from './SortRight';
import SpellBook from './SpellBook';
import Star from './Star';
import StarFilled from './StarFilled';
import StatisticsCalculatorIcon from './StatisticsCalculatorIcon';
import StraightHorizontalLine from './StraightHorizontalLine';
import StudentExitedTask from './StudentExitedTask';
import StudyNotes from './StudyNotes';
import Tasks from './Tasks';
import TeacherOverview from './TeacherOverview';
import TemplateDuplicate from './TemplateDuplicate';
import TemplateMove from './TemplateMove';
import TemplateRemove from './TemplateRemove';
import Templates from './Templates';
import Templates2 from './Templates2';
import Textbook from './Textbook';
import TextbookTeacher from './TextbookTeacher';
import ThreeDCalculatorIcon from './ThreeDCalculatorIcon';
import ThumbsDown from './ThumbsDown';
import ThumbsUp from './ThumbsUp';
import Toolbox from './Toolbox';
import Toolbox2 from './Toolbox2';
import Topics from './Topics';
import Trash from './Trash';
import Trash2 from './Trash2';
import TriangleDown from './TriangleDown';
import Twitter from './Twitter';
import TwitterRound from './TwitterRound';
import TwoHorizontalBars from './TwoHorizontalBars';
import Undo from './Undo';
import UnmutedSound from './UnmutedSound';
import User from './User';
import UserCheck from './UserCheck';
import Video from './Video';
import View from './View';
import Waypoints from './Waypoints';
import WorkedSolution from './WorkedSolution';
import Worksheet from './Worksheet';

export { Accessibility };
export { Activity };
export { AdaptiveTask };
export { AdminReports };
export { Archive };
export { ArrowDown };
export { ArrowLeft };
export { ArrowLeftNew };
export { ArrowRight };
export { ArrowUp };
export { Bell };
export { BetaBadge };
export { BugReport };
export { BulletedList };
export { Calculator };
export { Calendar };
export { CaretBottom };
export { CaretLeft };
export { CaretRight };
export { CaretTop };
export { CaretTopAndBottom };
export { ChatBubble };
export { CheckMark };
export { CheckMarkDashed };
export { CheckMarkFilled };
export { CheckMarkOutlined };
export { CheckMarkSquiggly };
export { CheckMarkThick };
export { Checkpoints };
export { ChevronBottom };
export { ChevronLeft };
export { ChevronLeftThick };
export { ChevronRight };
export { ChevronRightBold };
export { ChevronRightThick };
export { ChevronTop };
export { Circle };
export { CircleHollow };
export { Class };
export { ClassAssignee };
export { Clear };
export { Clipboard };
export { Clock };
export { ClockFilled };
export { Confetti };
export { Copy };
export { CopyThick };
export { CorrectFinalStep };
export { CreateNote };
export { CreateTemplateGroupIcon };
export { CreateTemplateIcon };
export { Cross };
export { CrossBold };
export { CrossFilled };
export { CrossOutlined };
export { CrossThick };
export { CrossedCircle };
export { Dashboard };
export { Dictionary };
export { DifficultyEasy };
export { DifficultyHard };
export { DifficultyMedium };
export { District };
export { DndHandle };
export { DoubleRight };
export { DoubleRightChevron };
export { Download };
export { Drag };
export { Duplicate };
export { EllipsisHorizontal };
export { EllipsisVertical };
export { EllipsisVertical2 };
export { EngageTask };
export { Eraser };
export { Eraser2 };
export { ExclamationMark };
export { ExclamationMarkFilled };
export { ExclamationMarkOutlined };
export { Eye };
export { Facebook };
export { FacebookRound };
export { FacebookSimple };
export { FeedbackNegative };
export { FeedbackPositive };
export { Filter };
export { Flag };
export { FlyoutToggle };
export { Folder };
export { FolderDistrict };
export { FolderMathspace };
export { FolderMove };
export { FolderOpen };
export { FolderRemove };
export { FreehandDraw };
export { GeometryCalculatorIcon };
export { Glossary };
export { Google };
export { GraduateIcon };
export { GraphingCalculatorIcon };
export { Hamburger };
export { HandPalm };
export { Highlighter };
export { Hint };
export { Home };
export { InfoFilled };
export { Intercom };
export { Internationalisation };
export { Investigation };
export { Lantern };
export { Lesson };
export { Link };
export { Linkedin };
export { Live };
export { Location };
export { Lock };
export { Lock2 };
export { Logout };
export { Loop };
export { LowerScore };
export { Mail };
export { MasteryLevelRibbon };
export { Mathspace };
export { MathspacePremium };
export { Megaphone };
export { MenuSquares };
export { Minus };
export { MoveToBottom };
export { MoveToTop };
export { MutedSound };
export { NewFolder };
export { NewFolder2 };
export { NewTemplate };
export { NextStep };
export { NextStepFilled };
export { NextStepOutlined };
export { NotAvailableInAccessibilityMode };
export { NotLoadedImagePlaceholder };
export { NoteIcon };
export { PIP };
export { PIPMode };
export { PaperSheet };
export { PaperSheetThumbsUp };
export { Pdf };
export { Pencil };
export { Pencil2 };
export { Play };
export { PlayingSound };
export { PlayingSoundMuted };
export { Plus };
export { Practice };
export { Print };
export { Profile };
export { QuestionMark };
export { QuestionMarkFilled };
export { QuestionMarkOutlined };
export { QuestionSet };
export { RecommendedPractice };
export { RectangleBorderedFilled };
export { Redo };
export { Refresh };
export { Resize };
export { Restore };
export { Retry };
export { School };
export { ScientificCalculatorIcon };
export { Scorecard };
export { Search };
export { SearchMinus };
export { SearchPlus };
export { SettingsCog };
export { Share };
export { Shuffle };
export { Sketchpad };
export { SketchpadBasic };
export { Skill };
export { SkillsMap };
export { SortRight };
export { SpellBook };
export { Star };
export { StarFilled };
export { StatisticsCalculatorIcon };
export { StraightHorizontalLine };
export { StudentExitedTask };
export { StudyNotes };
export { Tasks };
export { TeacherOverview };
export { TemplateDuplicate };
export { TemplateMove };
export { TemplateRemove };
export { Templates };
export { Templates2 };
export { Textbook };
export { TextbookTeacher };
export { ThreeDCalculatorIcon };
export { ThumbsDown };
export { ThumbsUp };
export { Toolbox };
export { Toolbox2 };
export { Topics };
export { Trash };
export { Trash2 };
export { TriangleDown };
export { Twitter };
export { TwitterRound };
export { TwoHorizontalBars };
export { Undo };
export { UnmutedSound };
export { User };
export { UserCheck };
export { Video };
export { View };
export { Waypoints };
export { WorkedSolution };
export { Worksheet };

export const allIcons = [
  Accessibility,
  Activity,
  AdaptiveTask,
  AdminReports,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowLeftNew,
  ArrowRight,
  ArrowUp,
  Bell,
  BetaBadge,
  BugReport,
  BulletedList,
  Calculator,
  Calendar,
  CaretBottom,
  CaretLeft,
  CaretRight,
  CaretTop,
  CaretTopAndBottom,
  ChatBubble,
  CheckMark,
  CheckMarkDashed,
  CheckMarkFilled,
  CheckMarkOutlined,
  CheckMarkSquiggly,
  CheckMarkThick,
  Checkpoints,
  ChevronBottom,
  ChevronLeft,
  ChevronLeftThick,
  ChevronRight,
  ChevronRightBold,
  ChevronRightThick,
  ChevronTop,
  Circle,
  CircleHollow,
  Class,
  ClassAssignee,
  Clear,
  Clipboard,
  Clock,
  ClockFilled,
  Confetti,
  Copy,
  CopyThick,
  CorrectFinalStep,
  CreateNote,
  CreateTemplateGroupIcon,
  CreateTemplateIcon,
  Cross,
  CrossBold,
  CrossFilled,
  CrossOutlined,
  CrossThick,
  CrossedCircle,
  Dashboard,
  Dictionary,
  DifficultyEasy,
  DifficultyHard,
  DifficultyMedium,
  District,
  DndHandle,
  DoubleRight,
  DoubleRightChevron,
  Download,
  Drag,
  Duplicate,
  EllipsisHorizontal,
  EllipsisVertical,
  EllipsisVertical2,
  EngageTask,
  Eraser,
  Eraser2,
  ExclamationMark,
  ExclamationMarkFilled,
  ExclamationMarkOutlined,
  Eye,
  Facebook,
  FacebookRound,
  FacebookSimple,
  FeedbackNegative,
  FeedbackPositive,
  Filter,
  Flag,
  FlyoutToggle,
  Folder,
  FolderDistrict,
  FolderMathspace,
  FolderMove,
  FolderOpen,
  FolderRemove,
  FreehandDraw,
  GeometryCalculatorIcon,
  Glossary,
  Google,
  GraduateIcon,
  GraphingCalculatorIcon,
  Hamburger,
  HandPalm,
  Highlighter,
  Hint,
  Home,
  InfoFilled,
  Intercom,
  Internationalisation,
  Investigation,
  Lantern,
  Lesson,
  Link,
  Linkedin,
  Live,
  Location,
  Lock,
  Lock2,
  Logout,
  Loop,
  LowerScore,
  Mail,
  MasteryLevelRibbon,
  Mathspace,
  MathspacePremium,
  Megaphone,
  MenuSquares,
  Minus,
  MoveToBottom,
  MoveToTop,
  MutedSound,
  NewFolder,
  NewFolder2,
  NewTemplate,
  NextStep,
  NextStepFilled,
  NextStepOutlined,
  NotAvailableInAccessibilityMode,
  NotLoadedImagePlaceholder,
  NoteIcon,
  PIP,
  PIPMode,
  PaperSheet,
  PaperSheetThumbsUp,
  Pdf,
  Pencil,
  Pencil2,
  Play,
  PlayingSound,
  PlayingSoundMuted,
  Plus,
  Practice,
  Print,
  Profile,
  QuestionMark,
  QuestionMarkFilled,
  QuestionMarkOutlined,
  QuestionSet,
  RecommendedPractice,
  RectangleBorderedFilled,
  Redo,
  Refresh,
  Resize,
  Restore,
  Retry,
  School,
  ScientificCalculatorIcon,
  Scorecard,
  Search,
  SearchMinus,
  SearchPlus,
  SettingsCog,
  Share,
  Shuffle,
  Sketchpad,
  SketchpadBasic,
  Skill,
  SkillsMap,
  SortRight,
  SpellBook,
  Star,
  StarFilled,
  StatisticsCalculatorIcon,
  StraightHorizontalLine,
  StudentExitedTask,
  StudyNotes,
  Tasks,
  TeacherOverview,
  TemplateDuplicate,
  TemplateMove,
  TemplateRemove,
  Templates,
  Templates2,
  Textbook,
  TextbookTeacher,
  ThreeDCalculatorIcon,
  ThumbsDown,
  ThumbsUp,
  Toolbox,
  Toolbox2,
  Topics,
  Trash,
  Trash2,
  TriangleDown,
  Twitter,
  TwitterRound,
  TwoHorizontalBars,
  Undo,
  UnmutedSound,
  User,
  UserCheck,
  Video,
  View,
  Waypoints,
  WorkedSolution,
  Worksheet,
];

export default {
  Accessibility,
  Activity,
  AdaptiveTask,
  AdminReports,
  Archive,
  ArrowDown,
  ArrowLeft,
  ArrowLeftNew,
  ArrowRight,
  ArrowUp,
  Bell,
  BetaBadge,
  BugReport,
  BulletedList,
  Calculator,
  Calendar,
  CaretBottom,
  CaretLeft,
  CaretRight,
  CaretTop,
  CaretTopAndBottom,
  ChatBubble,
  CheckMark,
  CheckMarkDashed,
  CheckMarkFilled,
  CheckMarkOutlined,
  CheckMarkSquiggly,
  CheckMarkThick,
  Checkpoints,
  ChevronBottom,
  ChevronLeft,
  ChevronLeftThick,
  ChevronRight,
  ChevronRightBold,
  ChevronRightThick,
  ChevronTop,
  Circle,
  CircleHollow,
  Class,
  ClassAssignee,
  Clear,
  Clipboard,
  Clock,
  ClockFilled,
  Confetti,
  Copy,
  CopyThick,
  CorrectFinalStep,
  CreateNote,
  CreateTemplateGroupIcon,
  CreateTemplateIcon,
  Cross,
  CrossBold,
  CrossFilled,
  CrossOutlined,
  CrossThick,
  CrossedCircle,
  Dashboard,
  Dictionary,
  DifficultyEasy,
  DifficultyHard,
  DifficultyMedium,
  District,
  DndHandle,
  DoubleRight,
  DoubleRightChevron,
  Download,
  Drag,
  Duplicate,
  EllipsisHorizontal,
  EllipsisVertical,
  EllipsisVertical2,
  EngageTask,
  Eraser,
  Eraser2,
  ExclamationMark,
  ExclamationMarkFilled,
  ExclamationMarkOutlined,
  Eye,
  Facebook,
  FacebookRound,
  FacebookSimple,
  FeedbackNegative,
  FeedbackPositive,
  Filter,
  Flag,
  FlyoutToggle,
  Folder,
  FolderDistrict,
  FolderMathspace,
  FolderMove,
  FolderOpen,
  FolderRemove,
  FreehandDraw,
  GeometryCalculatorIcon,
  Glossary,
  Google,
  GraduateIcon,
  GraphingCalculatorIcon,
  Hamburger,
  HandPalm,
  Highlighter,
  Hint,
  Home,
  InfoFilled,
  Intercom,
  Internationalisation,
  Investigation,
  Lantern,
  Lesson,
  Link,
  Linkedin,
  Live,
  Location,
  Lock,
  Lock2,
  Logout,
  Loop,
  LowerScore,
  Mail,
  MasteryLevelRibbon,
  Mathspace,
  MathspacePremium,
  Megaphone,
  MenuSquares,
  Minus,
  MoveToBottom,
  MoveToTop,
  MutedSound,
  NewFolder,
  NewFolder2,
  NewTemplate,
  NextStep,
  NextStepFilled,
  NextStepOutlined,
  NotAvailableInAccessibilityMode,
  NotLoadedImagePlaceholder,
  NoteIcon,
  PIP,
  PIPMode,
  PaperSheet,
  PaperSheetThumbsUp,
  Pdf,
  Pencil,
  Pencil2,
  Play,
  PlayingSound,
  PlayingSoundMuted,
  Plus,
  Practice,
  Print,
  Profile,
  QuestionMark,
  QuestionMarkFilled,
  QuestionMarkOutlined,
  QuestionSet,
  RecommendedPractice,
  RectangleBorderedFilled,
  Redo,
  Refresh,
  Resize,
  Restore,
  Retry,
  School,
  ScientificCalculatorIcon,
  Scorecard,
  Search,
  SearchMinus,
  SearchPlus,
  SettingsCog,
  Share,
  Shuffle,
  Sketchpad,
  SketchpadBasic,
  Skill,
  SkillsMap,
  SortRight,
  SpellBook,
  Star,
  StarFilled,
  StatisticsCalculatorIcon,
  StraightHorizontalLine,
  StudentExitedTask,
  StudyNotes,
  Tasks,
  TeacherOverview,
  TemplateDuplicate,
  TemplateMove,
  TemplateRemove,
  Templates,
  Templates2,
  Textbook,
  TextbookTeacher,
  ThreeDCalculatorIcon,
  ThumbsDown,
  ThumbsUp,
  Toolbox,
  Toolbox2,
  Topics,
  Trash,
  Trash2,
  TriangleDown,
  Twitter,
  TwitterRound,
  TwoHorizontalBars,
  Undo,
  UnmutedSound,
  User,
  UserCheck,
  Video,
  View,
  Waypoints,
  WorkedSolution,
  Worksheet,
};
