import type { ReactNode } from 'react';
import { Component } from 'react';

import {
  PageTimeErrorRecorder,
  usePageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';

type ThrowerProps = {
  pageName: string;
  componentName: string;
  error: Error;
};

export const PageTimeErrorThrower = (props: ThrowerProps) => {
  /*
    This component is a wrapper over the usePageTimeRecorder hook
    to captures an error timing and then rethrows the provided error
   */
  const { pageName, componentName, error } = props;
  usePageTimeRecorder(pageName, componentName, true);
  throw error;
};

type BoundaryProp = {
  pageName: string;
  componentName: string;
  children?: ReactNode | null | undefined;
};

type BoundaryState = {
  hasError: boolean;
};

export class PageTimeErrorBoundary extends Component<
  BoundaryProp,
  BoundaryState
> {
  /* This Error boundary is designed to catch rendering errors
     Record the error in the PageTimeErrorRecorder and then rethrow
     the error back to the tree so the regular error boundaries can
     process the event
   */
  static getDerivedStateFromError() {
    // Update state so the next render will record the error and rethrow
    return { hasError: true };
  }

  constructor(props: BoundaryProp) {
    super(props);
    this.state = { hasError: false };
  }

  override render() {
    const children = this.props.children ?? null;
    if (this.state.hasError) {
      const { pageName, componentName } = this.props;
      return (
        <PageTimeErrorRecorder
          pageName={pageName}
          componentName={componentName}
        >
          {children}
        </PageTimeErrorRecorder>
      );
    }

    return children;
  }
}
