import { graphql } from 'react-relay';

import type { UseMutationResponse } from 'ms-utils/relay/useMutation';
import useMutation from 'ms-utils/relay/useMutation';

import type {
  createTaskTemplateMutationResponse,
  createTaskTemplateMutationVariables,
} from './__generated__/createTaskTemplateMutation.graphql';

const customTaskMutation = graphql`
  mutation createTaskTemplateMutation(
    $schoolId: ID!
    $title: String!
    $description: String!
    $problemContentIds: [ID!]!
    $taskTemplateGroupIds: [ID!]!
  ) {
    createSchoolTaskTemplate(
      title: $title
      description: $description
      schoolId: $schoolId
      problemContentIds: $problemContentIds
      taskTemplateGroupIds: $taskTemplateGroupIds
    ) {
      errors {
        message
      }
      taskTemplate {
        id
      }
    }
  }
`;
export default function useCreateTaskTemplateMutation(
  variables: createTaskTemplateMutationVariables,
): UseMutationResponse<createTaskTemplateMutationResponse> {
  return useMutation({
    mutation: customTaskMutation,
    variables,
  });
}
