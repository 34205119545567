import invariant from 'invariant';
import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay';

import type { Subscription } from 'ms-helpers/Snowplow/Types/lantern/teacher';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import useMathspaceTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';

import type { TeacherContextLanternQuery as TeacherContextLanternQueryType } from './__generated__/TeacherContextLanternQuery.graphql';
import type { TeacherContext_lanternCurriculum$key } from './__generated__/TeacherContext_lanternCurriculum.graphql';
import { LanternTeacherQueryWrapper } from './components';

type TeacherContextType = {
  // As opposed to `ViewerPayload` in `ms-helpers/Viewer/ViewerProvider`,
  // `lanternProfileId` is not nullable here since we want to ensure
  // that we have a lantern profile whenever we use this context.
  lanternProfileId: string;
  classGradeId: string | null;
  lanternCurriculum: {
    id: string;
    title: string;
    sanaCatalogId: string;
    countryCode: string;
    strands: ReadonlyArray<{
      id: string;
      title: string;
      substrands: ReadonlyArray<{
        id: string;
        title: string;
      }>;
    }>;
    grades: ReadonlyArray<{
      id: string;
    }>;
  };
  subscription: Subscription;
};

type TeacherContextProviderProps = {
  children: ReactNode;
  lanternProfileId: string;
};

const TeacherContext = createContext<TeacherContextType | undefined>(undefined);

function TeacherContextProvider(props: TeacherContextProviderProps) {
  return (
    <LanternTeacherQueryWrapper
      fallback={<LoadingSpinner />}
      queryName="TeacherContextLanternQuery"
    >
      {({ fetchKey }) => (
        <TeacherContextProviderContentQuery fetchKey={fetchKey} {...props} />
      )}
    </LanternTeacherQueryWrapper>
  );
}

function TeacherContextProviderContentQuery({
  children,
  lanternProfileId,
  fetchKey,
}: TeacherContextProviderProps & { fetchKey: number }) {
  const { classId } = useMathspaceTeacherContext();
  const data = useLazyLoadQuery<TeacherContextLanternQueryType>(
    graphql`
      query TeacherContextLanternQuery($classId: ID!) {
        lantern {
          viewer {
            __typename
          }
        }
        class(id: $classId) {
          classGrade {
            id
          }
          school {
            lanternCurriculum {
              ...TeacherContext_lanternCurriculum
            }
          }
        }
      }
    `,
    { classId },
    { fetchKey },
  );
  const {
    lantern: { viewer },
    class: class_,
  } = data;
  invariant(viewer != null, 'User not found');
  invariant(viewer.__typename === 'LanternTeacher', 'User is not a teacher');
  invariant(class_ != null, 'Class not found');
  const { classGrade } = class_;
  const { lanternCurriculum } = class_.school;
  invariant(
    lanternCurriculum != null,
    'Lantern curriculum is not set for the school',
  );
  return (
    <TeacherContextProviderMain
      lanternProfileId={lanternProfileId}
      classGradeId={classGrade?.id ?? null}
      lanternCurriculum={lanternCurriculum}
    >
      {children}
    </TeacherContextProviderMain>
  );
}
const LANTERN_CURRICULUM_FRAGMENT = graphql`
  fragment TeacherContext_lanternCurriculum on LanternCurriculum {
    id
    sanaCatalogId
    title
    strands {
      id
      title
      substrands {
        id
        title
      }
    }
    grades {
      id
    }
    country {
      code
    }
  }
`;
function TeacherContextProviderMain({
  lanternProfileId,
  classGradeId,
  lanternCurriculum: lanternCurriculumRef,
  children,
}: {
  lanternProfileId: string;
  classGradeId: string | null;
  lanternCurriculum: TeacherContext_lanternCurriculum$key;
  children: ReactNode;
}) {
  const lanternCurriculum = useFragment<TeacherContext_lanternCurriculum$key>(
    LANTERN_CURRICULUM_FRAGMENT,
    lanternCurriculumRef,
  );

  if (lanternCurriculum === undefined) {
    return null;
  }
  return (
    <TeacherContext.Provider
      value={{
        lanternProfileId,
        classGradeId,
        lanternCurriculum: {
          id: lanternCurriculum.id,
          title: lanternCurriculum.title,
          sanaCatalogId: lanternCurriculum.sanaCatalogId,
          strands: lanternCurriculum.strands.map(s => ({ ...s })),
          grades: lanternCurriculum.grades.map(g => ({ ...g })),
          countryCode: lanternCurriculum.country.code,
        },
        subscription: 'nte_or_sunflower',
      }}
    >
      {children}
    </TeacherContext.Provider>
  );
}
function useTeacherContext() {
  const context = useContext(TeacherContext);
  if (context == null) {
    throw new Error('Missing `TeacherContextProvider`.');
  }
  return context;
}
export function useMaybeTeacherContext() {
  return useContext(TeacherContext);
}
export { TeacherContext, TeacherContextProvider, useTeacherContext };
export type { Subscription };
