import styled from '@emotion/styled';
import type { ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { colors } from 'ms-pages/Lantern/styles';
import { breakPoints } from 'ms-styles/base';
import { HStack, VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { tappable } from 'ms-utils/emotion';

const CONTAINER_WIDTH = 854;

const RootVStack = styled(VStack)({
  flexGrow: 1,
  backgroundColor: colors.almond50,
});

const SectionVStack = styled(VStack, {
  shouldForwardProp: prop =>
    prop !== 'hasWhiteBackground' && prop !== 'hasShadow',
})<{
  hasWhiteBackground?: boolean;
  hasShadow?: boolean;
}>(({ hasWhiteBackground = false, hasShadow = false }) => ({
  padding: 24,

  [`@media (min-width: ${breakPoints.medium}px)`]: {
    padding: 32,
  },

  [`@media (min-width: ${breakPoints.large}px)`]: {
    padding: 40,
  },

  ...(hasWhiteBackground && { backgroundColor: colors.white }),
  ...(hasShadow && { boxShadow: '0px 1px 7px rgba(62, 62, 76, 0.12)' }),
}));

const ContainerVStack = styled(VStack, {
  shouldForwardProp: (prop): prop is keyof ComponentProps<typeof VStack> =>
    prop !== 'isCentered',
})<{
  isCentered?: boolean;
  children: ReactNode;
}>(
  {
    width: '100%',
    maxWidth: CONTAINER_WIDTH,
    margin: '0 auto',
  },
  ({ isCentered = false }) =>
    isCentered && { alignItems: 'center', textAlign: 'center' },
);

const GroupVStack = styled(VStack)({
  alignItems: 'center',
  width: '100%',
  maxWidth: CONTAINER_WIDTH,
  padding: 24,
  background: colors.white,
  borderRadius: 24,
});

const GroupVSpacer = () => <VSpacer height={24} />;

const GroupHeadingVSpacer = () => <VSpacer height={32} />;

const GRID_GAP_X = 24;
const GRID_GAP_Y = 40;

const Grid = styled(HStack)({
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
  margin: `-${GRID_GAP_Y / 2}px -${GRID_GAP_X / 2}px`,
});
const GridItem = styled(VStack)({
  alignItems: 'center',
  width: '100%',
  padding: `${GRID_GAP_Y / 2}px ${GRID_GAP_X / 2}px`,

  [`@media (min-width: ${breakPoints.small}px)`]: {
    width: '50%',

    ':nth-of-type(3n)': {
      width: '50.1%', // make sure 2 of these won't fit in one row
    },
  },

  [`@media (min-width: ${breakPoints.medium}px)`]: {
    width: '33.5%', // make sure three of these won't fit in one row

    ':nth-of-type(5n + 1), :nth-of-type(5n + 2), :nth-of-type(5n + 3)': {
      width: '33.3%',
    },
  },
});

const gridItemTappableWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textDecoration: 'none',
  ...tappable,
} as const;

const GridItemLink = styled(Link)(gridItemTappableWrapperStyle);

const GridItemButton = styled.div(gridItemTappableWrapperStyle);

const GridItemTitle = styled.div({
  maxWidth: 160,
  textAlign: 'center',
});

export {
  ContainerVStack,
  CONTAINER_WIDTH,
  Grid,
  GridItem,
  GridItemButton,
  GridItemLink,
  GridItemTitle,
  GroupVStack,
  GroupVSpacer,
  GroupHeadingVSpacer,
  RootVStack,
  SectionVStack,
};
