/**
 * This is unfortunately (at least) the 3rd tooltip component
 * in the codebase as of July 2022.
 * Unlike ms-ui-primitives/Tooltip and ms-pagest/Lantern/primitives/Tooltip,
 * this is built on top of our own Popover primitive, thus giving us
 * the ability to better control the behavior of the tooltip,
 * especially for touch screens support.
 * It has currently just a few props to handle the behavior needed for
 * the learning focus UX implemented for https://app.clickup.com/t/6924803/OCTO-8599
 * but we can add more props as needed.
 * Also, we should consider consolidate all the tooltip components ASAP
 */

import { css } from '@emotion/css';
import { type ReactNode, useRef } from 'react';

import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import Popover from 'ms-ui-primitives/Popover/PopoverInternal';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import useIsExclusivelyTouchScreen from 'ms-utils/hooks/useIsExclusivelyTouchScreen';

const TOOLTIP_PADDING_Y = 8;
const TOOLTIP_PADDING_X = 12;
const styles = {
  tooltip: css({
    color: colors.white,
    textAlign: 'left',
    backgroundColor: colors.grey,
    borderRadius: 8,
    padding: `${TOOLTIP_PADDING_Y}px ${TOOLTIP_PADDING_X}px`,
  }),
};

// Pass content for simple text content that it won't be wrapped into styled Tooltip and BodyM.
// Pass styled content for custom styled content
type ContentProps =
  | {
      content: ReactNode;
      styledContent?: never;
    }
  | { content?: never; styledContent: ReactNode };

type Props = {
  children: ReactNode;
  hOffset?: number;
  vOffset?: number;
  popoverPosition?: keyof typeof popoverPositionToAnchorOrigin;
  wrapperStyles?: {};
} & ContentProps;

const popoverPositionToAnchorOrigin = {
  'bottom-left': ['bottom', 'left'],
  'bottom-right': ['bottom', 'right'],
  'bottom-center': ['bottom', 'center'],
  'middle-left': ['middle', 'left'],
  'middle-right': ['middle', 'right'],
  'middle-center': ['middle', 'center'],
  'top-left': ['top', 'left'],
  'top-right': ['top', 'right'],
  'top-center': ['top', 'center'],
} as const;

// Taken from local_modules/ms-ui-primitives/Popover/index.tsx:59
const popoverPositionToNewApiPopoverOrigin = {
  'bottom-left': ['top', 'right'],
  'bottom-right': ['top', 'left'],
  'bottom-center': ['top', 'center'],
  'middle-left': ['middle', 'right'],
  'middle-right': ['middle', 'left'],
  'middle-center': ['middle', 'center'],
  'top-left': ['bottom', 'right'],
  'top-right': ['bottom', 'left'],
  'top-center': ['bottom', 'center'],
} as const;

export default function TooltipNew(props: Props) {
  const tooltipVisible = useBoolean();

  return (
    <ControlledTooltip
      isTooltipVisible={tooltipVisible.value}
      onShowTooltip={tooltipVisible.setTrue}
      onHideTooltip={tooltipVisible.setFalse}
      onToggleTooltip={tooltipVisible.toggle}
      {...props}
    />
  );
}

export function ControlledTooltip({
  isTooltipVisible,
  onShowTooltip,
  onHideTooltip,
  onToggleTooltip,
  children,
  content,
  styledContent,
  hOffset = 0,
  vOffset = 0,
  popoverPosition = 'bottom-center',
  wrapperStyles = {},
}: Props & {
  isTooltipVisible: boolean;
  onShowTooltip: () => void;
  onHideTooltip: () => void;
  onToggleTooltip: () => void;
}) {
  const tooltipAnchorRef = useRef<HTMLDivElement>(null);
  const isExclusivelyTouchScreen = useIsExclusivelyTouchScreen();

  return (
    <div
      className={css(wrapperStyles)}
      onMouseEnter={isExclusivelyTouchScreen ? undefined : onShowTooltip}
      onMouseLeave={isExclusivelyTouchScreen ? undefined : onHideTooltip}
      onClick={isExclusivelyTouchScreen ? onToggleTooltip : undefined}
      ref={tooltipAnchorRef}
    >
      {children}
      {isTooltipVisible && (
        <Popover
          anchorElementRef={tooltipAnchorRef}
          onDismiss={onHideTooltip}
          anchorOrigin={popoverPositionToAnchorOrigin[popoverPosition]}
          popoverOrigin={popoverPositionToNewApiPopoverOrigin[popoverPosition]}
          shouldDismissOnTapOut={isExclusivelyTouchScreen}
          hOffset={hOffset ?? 0}
          vOffset={vOffset ?? 0}
          renderOverlay={false}
          shouldDismissOnEsc
          shouldDismissOnOwnScroll={false}
          shouldDismissOnScroll={false}
          shouldDismissOnTab
        >
          {styledContent != null ? (
            styledContent
          ) : (
            <div className={styles.tooltip}>
              <BodyM color="white">{content}</BodyM>
            </div>
          )}
        </Popover>
      )}
    </div>
  );
}
