import styled from '@emotion/styled';

import { Button } from 'ms-pages/Lantern/primitives/Button';
import { ModalContent, ModalFooter } from 'ms-pages/Lantern/primitives/Modal';
import { VSpacer, VStack } from 'ms-pages/Lantern/primitives/Stack';
import { BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import Modal from 'ms-ui-primitives/Modal';

const Wrapper = styled.div({
  maxWidth: 480,
  margin: '0 auto',
});

function ExitCheckInModal({
  isOpen,
  closeModal,
  onSubmit,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalContent isCentered>
        <Wrapper>
          <HeadingM>Exit check-in</HeadingM>
          <VSpacer height={24} />
          <BodyM>
            We’ll save the progress you’ve made on this check-in so you can
            resume it later.
          </BodyM>
        </Wrapper>
      </ModalContent>
      <ModalFooter isCentered>
        <VStack>
          <Button onClick={onSubmit} type="primary" size="lanternMedium">
            Save progress
          </Button>
          <VSpacer height={28} />
          <Button isInline onClick={closeModal} size="regular">
            Cancel
          </Button>
        </VStack>
      </ModalFooter>
    </Modal>
  );
}

export { ExitCheckInModal };
