import { StyleSheet, css } from 'aphrodite';
import { clamp, map, append, compose, without, assocPath } from 'ramda';
import {
  type MouseEvent as ReactMouseEvent,
  type TouchEvent as ReactTouchEvent,
  useState,
  useLayoutEffect,
} from 'react';

import { roundTo } from 'ms-utils/math/number';
import { unwrap } from 'ms-utils/typescript-utils';

import Axis from './Axis';
import AxisLabels from './AxisLabels';
import InteractiveEdge from './InteractiveEdge';
import ValueTracker from './ValueTracker';
import {
  range,
  scale,
  scaleEdge,
  getValue,
  getBoundingValue,
  getSubStatusColor,
  FILL_PRIMARY,
  FILL_SECONDARY,
  getScaledPositions,
  getQuartileXCoord,
} from './utils';
import type { Value } from './utils';

// Static configuration for svg
// NB: There is a webpack hoisting bug which is preventing this from being exported correctly
// As such: leave this as a var declaration
export var BOX_AND_WHISKER_STROKE_WIDTH = 2; // eslint-disable-line no-var
const MAJOR_TICK_HEIGHT = 20;
const BOX_HEIGHT = 80;
const WHISKER_HEIGHT = Math.round(BOX_HEIGHT / 2); // Should be <= BOX_HEIGHT
// +0.5 usage here ensures the path lies halfway between pixels.
// This ensures that the stroke lies exactly ON the pixels;
// thus avoiding uncrisp svgs due to antialiasing.
const PIXEL_ADJUSTMENT = 0.5;
// Used to postion elements in the middle of an edge
const EDGE_ADJUSTMENT = BOX_AND_WHISKER_STROKE_WIDTH / 2;

export type Edge = 'min' | 'q1' | 'median' | 'q3' | 'max';

export default function BoxPlot({
  width = 400,
  height = 180,
  axisTitle,
  axisMin,
  axisMax,
  axisMajorTickInterval,
  axisMinorTickInterval,
  value,
  onChange,
  readOnly,
}: {
  // Plot layout dimensions
  width?: number | undefined;
  height?: number | undefined;

  // Axis related props
  axisTitle: string;
  axisMin: number;
  axisMax: number;
  axisMajorTickInterval: number;
  axisMinorTickInterval: number;

  value: Value;
  onChange?: ((value: Value) => void) | undefined;
  readOnly?: boolean | undefined;
}) {
  // Main purpose of this data structure is to define the various
  // box plot positions in the svg coordinate space.
  const [scaledValues, setScaledValues] = useState(() =>
    getScaledPositions(
      axisMin,
      axisMax,
      axisMajorTickInterval,
      axisMinorTickInterval,
      value,
      width,
    ),
  );
  // The edge currently being dragged
  const [target, setTarget] = useState<Edge | null>(null);
  // This defines the order in which we layer the interactive edges in the box
  // plot. We dynamically shuffle this order so you can grab the correct thing
  // when edges coincide. The order is from lowest to highest in the stack. In
  // other words, subsequent elements are drawn on top of previous elements.
  const [stackOrder, setStackOrder] = useState<Edge[]>([
    'min',
    'q1',
    'median',
    'q3',
    'max',
  ]);

  // Ensure we sync the local scaledValues when upstream values change.
  // This is expected to occur when you do a reveal solution (for example)
  // as the upstream will pass the values for the "correct answer"
  // that you should have plotted. We must blow away your local state when
  // this happens as otherwise you won't see the "correct answer".
  // NOTE we use useLayoutEffect so that never commit the now-stale
  // internal values once we've received new upstream props.
  useLayoutEffect(() => {
    setScaledValues(
      getScaledPositions(
        axisMin,
        axisMax,
        axisMajorTickInterval,
        axisMinorTickInterval,
        value,
        width,
      ),
    );
  }, [
    axisMin,
    axisMax,
    axisMajorTickInterval,
    axisMinorTickInterval,
    value,
    width,
  ]);

  const isDragging = target !== null;
  const majorTicks = range(axisMin, axisMax, Math.abs(axisMajorTickInterval));
  // Top yCoord for Q1, Median and Q3 edges
  const boxTop = (height - BOX_HEIGHT - MAJOR_TICK_HEIGHT) / 2;
  // Top yCoord for Min and Max edges
  const whiskerTop = boxTop + (BOX_HEIGHT - WHISKER_HEIGHT) / 2;
  // Convert our data values into svg coordinate space values
  const scaleFactor = width / (axisMax - axisMin);
  const originValue = axisMin * scaleFactor;

  function snapEdgeValue(edge: Edge, logicalEdgeValue: number) {
    const boundingValue = getBoundingValue(edge, value, axisMin, axisMax);
    const clampedValue = clamp(
      boundingValue.min,
      boundingValue.max,
      logicalEdgeValue,
    );
    const snappedValue = roundTo(clampedValue, Math.abs(axisMinorTickInterval));
    const scaledValue = scale(snappedValue, scaleFactor, originValue);
    return scaledValue;
  }

  function onEdgeDragStart(
    edge: Edge,
    event: ReactMouseEvent<SVGRectElement> | ReactTouchEvent<SVGRectElement>,
  ) {
    if (readOnly) return;

    // We capture the x-coord at drag start so we can compute the delta from
    // the initial grab point on each "drag" event.
    const dragStartX = getClientX(event.nativeEvent);

    function onEdgeDrag(event: MouseEvent | TouchEvent) {
      // Ensure that on touch devices we don't pan ancestor scroll containers
      // while performing and edge drag operation.
      event.preventDefault();
      const dx = getClientX(event) - dragStartX;
      const initEdgeX = scaleEdge(value[edge], scaleFactor, originValue).value;
      const rawEdgeValue = getValue(initEdgeX + dx, scaleFactor, originValue);
      const snappedValue = snapEdgeValue(edge, rawEdgeValue);
      setScaledValues(vs => assocPath([edge, 'value'], snappedValue, vs));
    }

    function onEdgeDragEnd(event: MouseEvent | TouchEvent) {
      window.removeEventListener('touchmove', onEdgeDrag);
      window.removeEventListener('mousemove', onEdgeDrag);
      window.removeEventListener('touchend', onEdgeDragEnd);
      window.removeEventListener('touchcancel', onEdgeDragEnd);
      window.removeEventListener('mouseup', onEdgeDragEnd);

      const dx = getClientX(event) - dragStartX;
      const scaleFactor = width / (axisMax - axisMin);
      const originValue = axisMin * scaleFactor;
      const initEdgeX = scaleEdge(value[edge], scaleFactor, originValue).value;
      const rawEdgeValue = getValue(initEdgeX + dx, scaleFactor, originValue);
      const snappedValue = snapEdgeValue(edge, rawEdgeValue);
      const updatedValue = compose(
        assocPath([edge, 'substatus'], 'UNKNOWN'),
        assocPath(
          [edge, 'value'],
          getValue(snappedValue, scaleFactor, originValue),
        ),
      )(value) as Value; // TODO remove ramda. TS chokes on it all the time

      onChange?.(updatedValue);
      setTarget(null);
    }

    // non-passive is required to prevent scrolling on touch devices
    window.addEventListener('touchmove', onEdgeDrag, { passive: false });
    window.addEventListener('mousemove', onEdgeDrag);

    window.addEventListener('touchend', onEdgeDragEnd);
    window.addEventListener('touchcancel', onEdgeDragEnd);
    window.addEventListener('mouseup', onEdgeDragEnd);

    // As soon as we start dragging, we want to remove the previous "grading"
    // color indicator (eg. an edge will be red if it was marked incorrect)
    setScaledValues(assocPath([edge, 'substatus'], 'UNKNOWN', scaledValues));
    // Ensure the edge being dragged is rendered atop all others
    setStackOrder(compose(append(edge), without([edge]))(stackOrder));
    setTarget(edge);
  }

  return (
    <div className={css(styles.root)}>
      <div
        className={css(styles.shrinkwrap)}
        // We want to prevent a long press from launching the context menu
        onContextMenu={event => event.preventDefault()}
      >
        {/* +3 width so user can select edges when they are pushed all the way to the right edge */}
        <svg width={width + 3} height={height}>
          <g transform={`translate(${1},0)`}>
            {/* Box - Top*/}
            <line
              x1={
                getQuartileXCoord(scaledValues.q1.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y1={boxTop}
              x2={getQuartileXCoord(scaledValues.q3.value, PIXEL_ADJUSTMENT)}
              y2={boxTop}
              strokeWidth={BOX_AND_WHISKER_STROKE_WIDTH}
              stroke={FILL_PRIMARY}
            />
            {/* Box - Bottom*/}
            <line
              x1={
                getQuartileXCoord(scaledValues.q1.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y1={boxTop + BOX_HEIGHT}
              x2={getQuartileXCoord(scaledValues.q3.value, PIXEL_ADJUSTMENT)}
              y2={boxTop + BOX_HEIGHT}
              strokeWidth={BOX_AND_WHISKER_STROKE_WIDTH}
              stroke={FILL_PRIMARY}
            />
          </g>
          <g transform={`translate(${BOX_AND_WHISKER_STROKE_WIDTH},0)`}>
            {/* Left whisker */}
            <line
              x1={
                getQuartileXCoord(scaledValues.min.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y1={whiskerTop + WHISKER_HEIGHT / 2}
              x2={
                getQuartileXCoord(scaledValues.q1.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y2={whiskerTop + WHISKER_HEIGHT / 2}
              strokeWidth={BOX_AND_WHISKER_STROKE_WIDTH}
              stroke={FILL_PRIMARY}
            />
            {/* Right whisker */}
            <line
              x1={
                getQuartileXCoord(scaledValues.q3.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y1={whiskerTop + WHISKER_HEIGHT / 2}
              x2={
                getQuartileXCoord(scaledValues.max.value, PIXEL_ADJUSTMENT) -
                BOX_AND_WHISKER_STROKE_WIDTH
              }
              y2={whiskerTop + WHISKER_HEIGHT / 2}
              strokeWidth={BOX_AND_WHISKER_STROKE_WIDTH}
              stroke={FILL_PRIMARY}
            />
          </g>
          {/* Value tracker */}
          {isDragging && (
            <ValueTracker
              xCoord={getQuartileXCoord(
                scaledValues[target].value,
                PIXEL_ADJUSTMENT,
              )}
              yCoord={
                EDGE_ADJUSTMENT +
                getSelectedEdgeYCoord(target, boxTop, whiskerTop)
              }
              // We'll just draw it under the edge as well to simplify calcs.
              height={
                height -
                (EDGE_ADJUSTMENT +
                  getSelectedEdgeYCoord(target, boxTop, whiskerTop))
              }
            />
          )}
          <g>
            {map(
              name => (
                <InteractiveEdge
                  key={name}
                  name={name}
                  xCoord={getQuartileXCoord(
                    scaledValues[name].value,
                    PIXEL_ADJUSTMENT,
                  )}
                  yCoord={
                    getSelectedEdgeYCoord(name, boxTop, whiskerTop) -
                    EDGE_ADJUSTMENT -
                    PIXEL_ADJUSTMENT
                  }
                  width={BOX_AND_WHISKER_STROKE_WIDTH}
                  height={
                    name === 'min' || name === 'max'
                      ? WHISKER_HEIGHT
                      : BOX_HEIGHT + BOX_AND_WHISKER_STROKE_WIDTH
                  }
                  fill={getSubStatusColor(scaledValues[name].substatus)}
                  onInteract={onEdgeDragStart}
                  readOnly={readOnly}
                />
              ),
              stackOrder,
            )}
          </g>
          {/* x-axis ticks */}
          <Axis
            minorTicks={scaledValues.minorTicks}
            majorTicks={scaledValues.majorTicks}
            height={height}
            width={width}
            primaryFill={FILL_PRIMARY}
            secondaryFill={FILL_SECONDARY}
          />
        </svg>
        {/* x-axis tick labels */}
        <AxisLabels
          majorTickPosition={scaledValues.majorTicks}
          majorTickLabel={majorTicks}
          axisTitle={axisTitle}
        />
      </div>
    </div>
  );
}

function getSelectedEdgeYCoord(
  edgeName: Edge,
  boxTop: number,
  whiskerTop: number,
): number {
  let yCoord = boxTop + PIXEL_ADJUSTMENT;
  if (edgeName === 'min' || edgeName === 'max') {
    yCoord = whiskerTop;
  }
  return yCoord;
}

// Helper for abstracting over the differences between MouseEvent and TouchEvent.
// Note that we use the *native* event (not React's fake synthetic event) so that
// we can branch via instanceof checks which we can't do with the synthetic event.
function getClientX(event: MouseEvent | TouchEvent) {
  return event instanceof MouseEvent
    ? event.clientX
    : unwrap(event.changedTouches[0]).clientX;
}

const styles = StyleSheet.create({
  root: {
    userSelect: 'none',
  },
  shrinkwrap: {
    display: 'inline-block',
  },
});
