// specifies helper functions to apply styles at particular breakpoints

import { breakPoints } from 'ms-styles/base';
import { minBreak } from 'ms-utils/emotion';

type MobileFirstPayload = {
  default: Record<string, any>;
  tabletPortrait?: Record<string, any>;
  tabletLandscape?: Record<string, any>;
  desktop1024?: Record<string, any>;
  desktop1280?: Record<string, any>;
};

export const mobileFirst = ({
  desktop1280,
  desktop1024,
  default: base,
  tabletLandscape,
  tabletPortrait,
}: MobileFirstPayload) => ({
  ...base,
  ...minBreak(breakPoints.small, tabletPortrait),
  ...minBreak(breakPoints.medium, tabletLandscape),
  ...minBreak(breakPoints.large, desktop1024),
  ...minBreak(breakPoints.extraLarge, desktop1280),
});
