/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type jsxPrintKebabLinks_subtopic = {
    readonly id: string;
    readonly answerKeyJsx: {
        readonly pdfAvailable: boolean;
    };
    readonly worksheetJsx: {
        readonly pdfAvailable: boolean;
    };
    readonly " $refType": "jsxPrintKebabLinks_subtopic";
};
export type jsxPrintKebabLinks_subtopic$data = jsxPrintKebabLinks_subtopic;
export type jsxPrintKebabLinks_subtopic$key = {
    readonly " $data"?: jsxPrintKebabLinks_subtopic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"jsxPrintKebabLinks_subtopic">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "pdfAvailable",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "jsxPrintKebabLinks_subtopic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerKey",
      "kind": "LinkedField",
      "name": "answerKeyJsx",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheetJsx",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Subtopic",
  "abstractKey": null
};
})();
(node as any).hash = '5bd6a893f4840423aa01554ebcae94e2';
export default node;
