import { StyleSheet } from 'aphrodite';

import {
  fontFamily,
  fontSize,
  transition,
  borderRadiusUI,
  fontWeight,
  zIndex,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { onHover, styledVerticallyScrollable } from 'ms-utils/emotion';

import type { State, Props } from '.';

export const SELECT_ICON_GUTTER = 2 * BASE_UNIT;
const OPTION_VERTICAL_PADDING = 8;
const OPTION_HORIZONTAL_PADDING = 12;
const SEARCH_WRAPPER_HEIGHT = 50;
export const OPTION_PADDING = `${OPTION_VERTICAL_PADDING}px ${OPTION_HORIZONTAL_PADDING}px`;

export default function getStyle<T extends string | number>(
  state: State,
  props: Props<T>,
) {
  const borderWidth = props.theme === 'dark' ? 0 : 1;
  const optionHeight =
    props.optionHeight != null ? props.optionHeight - 2 * borderWidth : 36;
  const optionLineHeight = optionHeight - 2 * OPTION_VERTICAL_PADDING; // use pixel for line height to avoid subpixel rendering
  const optionItemsShown = props.optionItemsShown ? props.optionItemsShown : 6;
  const loadingIndicatorWrapperHeight = optionHeight;
  const optionListMaxHeight = optionItemsShown * optionHeight + borderWidth;

  const isOptionListShown = state.isFocused;
  const showSearchInput = props.withSearch != null;

  const searchAndOptionsSharedStyles = {
    position: 'absolute',
    left: -borderWidth,
    width: state.width || 'auto',
    background:
      props.theme === 'dark' ? colors.pickledBluewood : colors.athensGray,
    border:
      borderWidth === 0 ? 'none' : `${borderWidth}px solid ${colors.matisse}`,
    borderTop: '0',
    zIndex: zIndex.selectOptions,
  } as const;

  return StyleSheet.create({
    icon: {
      display: 'block',
      marginLeft: SELECT_ICON_GUTTER,
      transition: `transform ${transition}`,
      fontSize: fontSize.xSmall,
    },
    iconOpen: {
      transform: 'rotateZ(180deg)',
    },
    input: {
      width: props.block || state.width == null ? '100%' : state.width,
      background: props.disabled
        ? colors.athensGray
        : props.theme === 'dark'
        ? colors.pickledBluewood
        : colors.white,
      borderRadius: isOptionListShown
        ? `${borderRadiusUI}px ${borderRadiusUI}px 0 0`
        : `${borderRadiusUI}px`,
      cursor: props.disabled ? 'auto' : 'pointer',
      display: props.block ? 'block' : 'inline-block',
      outline: 'none',
      padding: 0,
      position: 'relative',
      textAlign: 'left',
      transition: `border-color ${transition}`,
      userSelect: 'none',
      border:
        borderWidth === 0
          ? 'none'
          : isOptionListShown && !props.disabled
          ? `${borderWidth}px solid ${colors.matisse}`
          : `${borderWidth}px solid ${colors.iron}`,
      ...onHover({
        border:
          borderWidth === 0
            ? 'none'
            : props.disabled
            ? `${borderWidth}px solid ${colors.iron}`
            : `${borderWidth}px solid ${colors.matisse}`,
      }),

      // Since optionsListWrapper is hidden using `visibility: hidden`, if inner elements have `visibility: visible` (example, checkboxes) they will be visible when the the option list is closed. Thus we need this overflow handling
      overflow: isOptionListShown ? 'visible' : 'hidden',

      // This shouldn't be necessary because there is always a rendered selected value (or a placeholder) but in https://app.clickup.com/t/6924803/OCTO-10465 we saw that after an expensive rendering, (selecting "All students") the button was "collapsing", hiding the selected option.
      // While the change makes sense, it's weird that it wasn't necessary unless when `All students` was selected in the student nav.
      // This is likely due to a a combination of the `setTimeout` on the `onBlur` `Select` method and an expensive rendering in the component that renders the `Select` (`StudentNav` ) when `All students` is selected.
      minHeight: optionHeight,
    },
    option: {
      alignItems: 'center',
      color: props.disabled
        ? colors.iron
        : props.theme === 'dark'
        ? colors.white
        : colors.cloudBurst,
      display: 'flex',
      fontFamily: fontFamily.body,
      fontSize: `${fontSize.input}px`,
      lineHeight: `${optionLineHeight}px`,
      padding: OPTION_PADDING,
      transition: `color ${transition}, background-color ${transition}`,
      whiteSpace: props.noWrap ? 'nowrap' : 'normal',
      userSelect: 'none',
    },
    multipleSelectOption: {
      padding: 0,
    },
    optionSelected: {
      background:
        props.theme === 'dark'
          ? colors.shuttleGray
          : props.multi
          ? colors.eggplant10
          : 'transparent',
    },
    optionDisabled: {
      cursor: 'not-allowed',
      color: colors.iron,
    },
    optionActive: {
      background:
        props.theme === 'dark' ? colors.shuttleGray : colors.porcelain,
    },
    optionList: {
      ...searchAndOptionsSharedStyles,
      borderTop: '0',
      borderRadius: `0 0 ${borderRadiusUI}px ${borderRadiusUI}px`,
      maxHeight: `${optionListMaxHeight}px`,
      top: showSearchInput ? SEARCH_WRAPPER_HEIGHT : 0,
      ...(props.theme === 'dark'
        ? styledVerticallyScrollable
        : { overflow: 'auto', WebkitOverflowScrolling: 'touch' }),
    },
    optionListWrapper: {
      position: 'relative',
      visibility: isOptionListShown ? 'visible' : 'hidden',
    },
    searchWrapper: {
      ...searchAndOptionsSharedStyles,
      borderBottom: '0',
      height: SEARCH_WRAPPER_HEIGHT,
      padding: 4,
      cursor: 'default',
      top: 0,
    },
    emptySearch: {
      padding: '8px 0 12px',
      textAlign: 'center',
    },
    optionText: {
      flexGrow: 1,
    },
    optionTextInput: {
      fontWeight: props.theme === 'dark' ? fontWeight.semibold : 'normal',
    },
    optionTextOverflow: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    placeholderText: {
      color: colors.iron,
    },
    loadingIndicatorWrapper: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      height: loadingIndicatorWrapperHeight,
    },
    searchInputWrapper: {
      padding: 2 * BASE_UNIT,
    },
  });
}
