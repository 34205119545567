/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SchoolContext_class = {
    readonly id: string;
    readonly pk: number;
    readonly code: string | null;
    readonly title: string;
    readonly displayName: string | null;
    readonly isPartnerClass: boolean;
    readonly isUnsyncedClass: boolean;
    readonly studentCount: number;
    readonly students: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly user: {
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly avatar: string;
                };
            };
        }>;
    } | null;
    readonly hasTasks: boolean;
    readonly tasksCount: number;
    readonly classSyllabusFocus: {
        readonly id: string;
    };
    readonly classGrade: {
        readonly id: string;
    } | null;
    readonly school: {
        readonly id: string;
        readonly title: string;
    };
    readonly " $refType": "SchoolContext_class";
};
export type SchoolContext_class$data = SchoolContext_class;
export type SchoolContext_class$key = {
    readonly " $data"?: SchoolContext_class$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SchoolContext_class">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "numberOfStudents"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolContext_class",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pk",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPartnerClass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnsyncedClass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studentCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "numberOfStudents"
        }
      ],
      "concreteType": "StudentConnection",
      "kind": "LinkedField",
      "name": "students",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Student",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tasksCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Syllabus",
      "kind": "LinkedField",
      "name": "classSyllabusFocus",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternGrade",
      "kind": "LinkedField",
      "name": "classGrade",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "School",
      "kind": "LinkedField",
      "name": "school",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Class",
  "abstractKey": null
};
})();
(node as any).hash = '2a00b4916c921d138ca022b46a53b08b';
export default node;
