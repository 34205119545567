import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Waypoints = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={18}
    viewBoxHeight={20}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.32297 0.015625C0.741752 0.0156514 0.270604 0.486839 0.27063 1.06805L0.271415 18.9352C0.271441 19.5164 0.74263 19.9876 1.32384 19.9875C1.90506 19.9875 2.37621 19.5163 2.37618 18.9351L2.37576 9.38835L6.89101 9.37548L6.89994 12.5576C6.90002 12.6044 6.9092 12.6508 6.92693 12.694C6.94467 12.7373 6.97061 12.7765 7.00329 12.8095C7.03596 12.8425 7.07472 12.8687 7.11735 12.8864C7.15997 12.9042 7.20563 12.9132 7.25169 12.913L16.9966 12.885C17.0099 12.8851 17.0231 12.8843 17.0363 12.8827C17.0946 12.8757 17.1503 12.854 17.1982 12.8195C17.2461 12.7851 17.2847 12.7389 17.3105 12.6853C17.3363 12.6317 17.3485 12.5724 17.3459 12.5128C17.3433 12.4532 17.326 12.3952 17.2957 12.3441L15.3064 8.99505L17.2774 5.63457C17.3092 5.58032 17.3263 5.51847 17.3269 5.45532C17.3275 5.39217 17.3116 5.32999 17.2809 5.27512C17.2501 5.2203 17.2055 5.17476 17.1518 5.14315C17.098 5.11154 17.037 5.095 16.9749 5.09521L11.4087 5.11125L11.3999 1.92915C11.3999 1.88234 11.3908 1.836 11.373 1.79279C11.3553 1.74958 11.3294 1.71036 11.2967 1.67737C11.2641 1.64427 11.2253 1.61807 11.1826 1.60028C11.14 1.58249 11.0943 1.57346 11.0482 1.57371L2.37542 1.59883L2.3754 1.06796C2.37537 0.486745 1.90418 0.0155986 1.32297 0.015625Z"
    />
  </Icon>
);

Waypoints.displayName = 'Waypoints';

export default Waypoints;
