import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { useSnowplow } from 'ms-helpers/Snowplow';
import ProblemItemStandalone from 'ms-pages/Textbooks/components/ProblemItem/ProblemItemStandalone';

type Props = {
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  problemContentId: string;
  index: number;
  onRemoveProblem: () => void;
  onDuplicateProblem: () => void;
  collapsedInBulk?: boolean | undefined;
  showTryProblem?: boolean | undefined;
};

export default function Item({
  problemContentId,
  index,
  onRemoveProblem,
  onDuplicateProblem,
  dragHandleProps,
  collapsedInBulk,
  showTryProblem = false,
}: Props) {
  const { withTrackStructEvent } = useSnowplow();

  const removeProblemAction = withTrackStructEvent(onRemoveProblem, {
    category: 'create_task_from_anywhere',
    action: 'removed_question',
    label: problemContentId,
  });

  return (
    <ProblemItemStandalone
      showTryProblem={showTryProblem}
      problemContentId={problemContentId}
      index={index}
      dragHandleProps={dragHandleProps}
      collapsedInBulk={collapsedInBulk}
      onDuplicateProblem={onDuplicateProblem}
      onRemoveProblem={removeProblemAction}
    />
  );
}
