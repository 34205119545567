import { useState } from 'react';

import type { TaskDetailQueryResponse } from 'ms-pages/Teacher/TaskReport/__generated__/TaskDetailQuery.graphql';
import Modal from 'ms-ui-primitives/Modal';
import useStartPosthogRecording from 'ms-utils/hooks/useStartPosthogRecording';
import { noop } from 'ms-utils/misc';
import { assert, assertUnreachable } from 'ms-utils/typescript-utils';

import { GameSelector } from '../GameSelector';
import { GameSetup } from '../GameSetup';
import type { GameState } from '../TugOfWar';
import { TUG_OF_WAR_HEIGHT, TUG_OF_WAR_WIDTH, TugOfWar } from '../TugOfWar';
import { TugOfWarInstructions } from '../TugOfWarInstructions';

export type GameModalContent =
  | 'GameSelector'
  | 'TugOfWarInstructions'
  | 'GameSetup'
  | 'TugOfWar';

// Note: Some routing would be nice
export function GameModal({
  content,
  task,
  onClose,
  onContinue,
}: {
  content: GameModalContent;
  task: NonNullable<TaskDetailQueryResponse['task']>;
  onClose: (gameState?: GameState) => void;
  onContinue: (newContent: GameModalContent) => void;
}) {
  const [gameId, setGameId] = useState<string | null>(null);
  const [gameDurationMs, setGameDurationMs] = useState<number | null>(null);

  useStartPosthogRecording({
    isEnabled: true,
    deps: [],
    eventName: 'Started class game',
  });

  switch (content) {
    case 'GameSelector':
      return (
        <Modal isOpen width={650} onClose={onClose}>
          <GameSelector onContinue={onContinue} />
        </Modal>
      );
    case 'TugOfWarInstructions':
      return (
        <Modal isOpen width={650} onClose={onClose}>
          <TugOfWarInstructions taskKey={task} onContinue={onContinue} />
        </Modal>
      );
    case 'GameSetup':
      return (
        <Modal isOpen width={896} onClose={onClose}>
          <GameSetup
            taskKey={task}
            onContinue={(newContent, gameConfig) => {
              setGameId(gameConfig.gameId);
              setGameDurationMs(gameConfig.gameDurationMs);
              onContinue(newContent);
            }}
          />
        </Modal>
      );
    case 'TugOfWar':
      assert(gameId != null, 'Game ID is null but required for TugOfWar');
      assert(
        gameDurationMs != null,
        'Game duration is null but required for TugOfWar',
      );

      return (
        <Modal
          isOpen
          showCloseButton={false}
          width={TUG_OF_WAR_WIDTH}
          height={TUG_OF_WAR_HEIGHT}
          onClose={noop}
        >
          <TugOfWar
            gameId={gameId}
            gameDurationMs={gameDurationMs}
            taskId={task.id}
            taskStartDate={task.startDate}
            onQuit={onClose}
          />
        </Modal>
      );
    default:
      assertUnreachable(content);
  }
}
