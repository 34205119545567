import { createContext, useContext, useReducer, type ReactNode } from 'react';

import type {
  ActiveTab,
  AgeConfirmed,
  Country,
  CountrySubdivision,
  Email,
  FirstName,
  LastName,
  Password,
  SchoolReference,
  SchoolTitle,
  StudentJoinCode,
  TeacherSignupFormStep,
  SyllabusFocus,
  ClassName,
} from 'ms-pages/Signup/Sunflower/types';
import {
  extractResponseErrorMessages,
  type ResponseErrors,
} from 'ms-utils/relay/extractResponseErrorMessages';
import { assertUnreachable, unwrap } from 'ms-utils/typescript-utils';

// PUBLIC API =====================================================================================
type State = {
  activeTab: ActiveTab;
  location: string;
  firstName: FirstName;
  lastName: LastName;
  parentEmail: Email;
  parentEmailNotifications: boolean;
  email: Email;
  password: Password;
  ageConfirmed?: AgeConfirmed;
  schoolReference: SchoolReference;
  schoolTitle: SchoolTitle;
  studentJoinCode: StudentJoinCode;
  country: Country | null | undefined;
  countrySubdivision: CountrySubdivision | null | undefined;
  formStep: TeacherSignupFormStep;
  hasErrors: boolean;
  responseErrors: {
    [key: string]: ReadonlyArray<string>;
  };
  isSubmitting: boolean;
  loginFail: boolean;
  unexpectedSubmissionError: boolean;
  syllabusFocus: SyllabusFocus | null | undefined;
  className: ClassName | null | undefined;
};

type Action =
  | { type: 'setFirstName'; value: FirstName }
  | { type: 'setLastName'; value: LastName }
  | { type: 'setParentEmail'; value: Email }
  | { type: 'setParentEmailNotifications'; value: boolean }
  | { type: 'setEmail'; value: Email }
  | { type: 'setIsSubmitting'; value: boolean }
  | { type: 'setLoginFail'; value: boolean }
  | { type: 'setUnexpectedSubmissionError'; value: boolean }
  | { type: 'setPassword'; value: Password }
  | { type: 'setAgeConfirmed'; value: AgeConfirmed }
  | { type: 'setSchoolReference'; value: SchoolReference }
  | { type: 'setSchoolTitle'; value: SchoolTitle }
  | { type: 'setCountry'; value: Country }
  | {
      type: 'setCountrySubdivision';
      value: CountrySubdivision | null | undefined;
    }
  | { type: 'clearErrors' }
  | { type: 'setErrors'; value: ResponseErrors }
  | { type: 'setActiveTab'; value: ActiveTab }
  | { type: 'setStudentJoinCode'; value: StudentJoinCode }
  | { type: 'setSyllabusFocus'; value: SyllabusFocus }
  | { type: 'setClassName'; value: ClassName };

export function SignupStateProvider({
  children,
  location,
}: {
  children: ReactNode;
  location: string;
}) {
  const [state, dispatch] = useReducer(reducer, { ...initialState, location });
  return (
    <SignupStateContext.Provider value={{ state, dispatch }}>
      {children}
    </SignupStateContext.Provider>
  );
}

type SignupStateHook = {
  state: State;
  dispatch: (action: Action) => void;
};

export type Dispatch = (action: Action) => void;

export function useSignupState(): SignupStateHook {
  const { state, dispatch } = useContext(SignupStateContext);
  return {
    state,
    dispatch,
  };
}

// HELPERS =====================================================================================

const initialState: State = {
  firstName: '',
  lastName: '',
  parentEmail: '',
  parentEmailNotifications: true,
  email: '',
  password: '',
  ageConfirmed: false,
  schoolReference: null,
  schoolTitle: null,
  studentJoinCode: null,
  country: null,
  countrySubdivision: null,
  formStep: 'intro',
  responseErrors: {},
  location: '',
  hasErrors: false,
  activeTab: null,
  isSubmitting: false,
  loginFail: false,
  unexpectedSubmissionError: false,
  className: null,
  syllabusFocus: null,
};

function isFormErrorsEmpty(
  errors: Record<string, ReadonlyArray<string>>,
): boolean {
  return (
    Object.keys(errors).filter(key => unwrap(errors[key]).length > 0).length ===
    0
  );
}

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'setFirstName': {
      return {
        ...state,
        firstName: action.value,
      };
    }
    case 'setLastName': {
      return {
        ...state,
        lastName: action.value,
      };
    }
    case 'setParentEmail': {
      return {
        ...state,
        parentEmail: action.value,
      };
    }
    case 'setEmail': {
      return {
        ...state,
        email: action.value,
      };
    }
    case 'setPassword': {
      return {
        ...state,
        password: action.value,
      };
    }
    case 'setAgeConfirmed': {
      return {
        ...state,
        ageConfirmed: action.value,
      };
    }
    case 'setSchoolReference': {
      return {
        ...state,
        schoolReference: action.value,
      };
    }
    case 'setSchoolTitle': {
      return {
        ...state,
        schoolTitle: action.value,
      };
    }
    case 'setCountry': {
      return {
        ...state,
        country: action.value,
      };
    }
    case 'setCountrySubdivision': {
      return {
        ...state,
        countrySubdivision: action.value,
      };
    }
    case 'setIsSubmitting': {
      return {
        ...state,
        isSubmitting: action.value,
      };
    }
    case 'setLoginFail': {
      return {
        ...state,
        loginFail: action.value,
      };
    }
    case 'setUnexpectedSubmissionError': {
      return {
        ...state,
        unexpectedSubmissionError: action.value,
      };
    }
    case 'setErrors': {
      const errors = extractErrorMessages(action.value);
      return {
        ...state,
        responseErrors: errors,
        hasErrors: !isFormErrorsEmpty(errors),
      };
    }

    case 'clearErrors':
      return {
        ...state,
        responseErrors: {},
        hasErrors: false,
      };
    case 'setActiveTab':
      return {
        ...state,
        activeTab: action.value,
      };
    case 'setStudentJoinCode':
      return {
        ...state,
        studentJoinCode: action.value,
      };
    case 'setSyllabusFocus':
      return {
        ...state,
        syllabusFocus: action.value,
      };
    case 'setClassName':
      return {
        ...state,
        className: action.value,
      };
    case 'setParentEmailNotifications':
      return {
        ...state,
        parentEmailNotifications: action.value,
      };
    default:
      assertUnreachable(
        action,
        `Exhaustiveness guarantee violated at runtime by action: ${JSON.stringify(
          action,
        )}`,
      );
  }
}

const SignupStateContext = createContext<{
  state: State;
  dispatch: (action: Action) => void;
}>({ state: initialState, dispatch: () => {} });

function extractErrorMessages(responseErrors: ResponseErrors) {
  return {
    firstName: extractResponseErrorMessages(responseErrors, [
      'INVALID_FIRST_NAME_LENGTH',
    ]),
    lastName: extractResponseErrorMessages(responseErrors, [
      'INVALID_LAST_NAME_LENGTH',
    ]),
    parentEmail: extractResponseErrorMessages(responseErrors, [
      'INVALID_PARENT_EMAIL',
    ]),
    email: extractResponseErrorMessages(responseErrors, [
      'INVALID_EMAIL',
      'DUPLICATE_EMAIL',
      'INVALID_EMAIL_LENGTH',
    ]),
    password: extractResponseErrorMessages(responseErrors, [
      'INVALID_PASSWORD',
    ]),
    school: extractResponseErrorMessages(responseErrors, [
      'EMPTY_SCHOOL_TITLE',
      'INVALID_CREATE_TEACHER_FOR_LANTERN_ARGS',
      'INVALID_SCHOOL_REFERENCE',
    ]),
    studentJoinCode: extractResponseErrorMessages(responseErrors, [
      'INVALID_CLASS_CODE',
      'CANNOT_JOIN_CLASS',
    ]),
    other: extractResponseErrorMessages(responseErrors, ['INVALID_TIMEZONE']),
  };
}
