import { useState } from 'react';
import { commitMutation } from 'react-relay';
import type { Environment } from 'relay-runtime';

import { publicEnvironment } from 'ms-utils/relay';

export type UseMutationArgs<V extends {}> = {
  environment?: Environment;
  mutation: any; // tagged template literal
  variables: V;
  onCompleted?: (() => void) | undefined;
};

export type UseMutationResponse<T> = [
  () => void,
  {
    loading: boolean;
    response: T | null;
    errors: null | any;
  },
];

export default function useMutation<T extends {}, V extends {}>({
  environment = publicEnvironment,
  mutation,
  variables,
  onCompleted,
}: UseMutationArgs<V>): UseMutationResponse<T> {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Error | null>(null);
  const [response, setResponse] = useState<T | null>(null);
  const mutate = () => {
    setLoading(true);
    setResponse(null);
    setErrors(null);

    // This entire function was incorrectly defined, the type parameter T
    // should have been the MutationParameters type, but has erroneously
    // been defined as the Response type (ie. MutationParameters["response"]).
    // We cannot forward this type through to the commitMutation type parameter
    // because its wrong, so we need to synthetise the correct type which should
    // always have been passed to useMutation. Then we can pass this synthesized
    // type as the type argument. I cannot easily correct this function as ~300
    // callsites are passing the incorrect type to as the type argument to type
    // parameter T.
    type CorrectT = {
      readonly response: T;
      readonly variables: {};
      readonly rawResponse?: {};
    };

    commitMutation<CorrectT>(environment, {
      mutation,
      variables: variables ?? {},
      onCompleted(response) {
        setLoading(false);
        setResponse(response);
        onCompleted?.();
      },
      onError(e) {
        setLoading(false);
        setErrors(e);
      },
    });
  };

  return [mutate, { loading, response, errors }];
}
