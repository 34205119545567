import { StyleSheet, css } from 'aphrodite';
import type {
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
} from 'react';

import Rect from '../Rect';
import type { SpacingData, CategoryData, IncrementData } from '../index';
import { getYScreenValue, getXScreenValue, calculateBinWidth } from '../utils';

type Props = {
  categories: CategoryData;
  increment: IncrementData;
  spacing: SpacingData;
  dragHandleAlignment: 'center' | 'right';
  dragHandleSize: number;
  hasGapBetweenBars: boolean;
  onInteract: (
    index: number,
    e: SyntheticMouseEvent<any> | SyntheticTouchEvent<any>,
  ) => void;
};

const DragHandles = ({
  categories,
  increment,
  spacing,
  onInteract,
  dragHandleAlignment,
  dragHandleSize,
  hasGapBetweenBars,
}: Props) => {
  const categoryListLength = hasGapBetweenBars
    ? categories.length
    : categories.length + 1;
  const contentWidth = spacing.maxBound - spacing.minBound;
  const binWidth = calculateBinWidth(spacing, categories, hasGapBetweenBars);
  const halfBinWidth = binWidth / 2;

  const styles = StyleSheet.create({
    dragHandleHit: {
      cursor: 'pointer',
      fill: 'transparent',
      height: dragHandleSize * 4,
      width: dragHandleSize * 4,
    },

    dragHandle: {
      fill: '#fff',
    },
  });

  return (
    <g transform={`translate(0,${spacing.minBound - dragHandleSize / 2})`}>
      {categories.map((category, index) => {
        let xScreenValue = getXScreenValue(
          index,
          categoryListLength,
          contentWidth,
        );
        if (dragHandleAlignment === 'right') xScreenValue += halfBinWidth;
        const yScreenValue = getYScreenValue(
          category.value,
          increment,
          spacing,
        );

        return (
          <g
            key={`dragHandle_${index}`}
            transform={`translate(${xScreenValue},${yScreenValue})`}
            onMouseDown={e => onInteract(index, e)}
            onTouchStart={e => onInteract(index, e)}
          >
            <Rect
              className={css(styles.dragHandle)}
              height={dragHandleSize}
              stroke="#333"
              strokeWidth={1}
              fill="#"
              width={dragHandleSize}
              x={-dragHandleSize / 2}
              y={0}
            />
            <rect
              className={css(styles.dragHandleHit)}
              height={dragHandleSize * 4}
              width={dragHandleSize * 4}
              x={-dragHandleSize * 2}
              y={-dragHandleSize}
            />
          </g>
        );
      })}
    </g>
  );
};

export default DragHandles;
