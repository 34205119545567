import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M13.1364004,10.6746466 C12.9203519,7.82689103 11.5776781,6.25 9.19451417,6.25 C6.68219581,6.25 5.38011947,8.28798805 5.25046365,11.9736324 C5.2359012,12.3875899 5.55967486,12.7349739 5.97363236,12.7495364 C6.38758985,12.7640988 6.73497391,12.4403251 6.74953635,12.0263676 C6.85440457,9.04534528 7.68200434,7.75 9.19451417,7.75 C10.6138187,7.75 11.3947467,8.56127261 11.6085905,10.4371102 L10.5862985,9.74756555 C10.2428999,9.51594026 9.77675094,9.60655044 9.54512565,9.94994905 C9.31350037,10.2933477 9.40411055,10.7594966 9.74750916,10.9911219 L12.23663,12.6700551 C12.5128199,12.8563476 12.8787926,12.8381252 13.1351107,12.6253181 L15.5026257,10.6596989 C15.8213167,10.395107 15.8651728,9.92226273 15.6005809,9.60357165 C15.335989,9.28488058 14.8631447,9.24102454 14.5444536,9.50561644 L13.1364004,10.6746466 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M10.5,13 C10.2238576,13 10,13.2238576 10,13.5 C10,13.7761424 10.2238576,14 10.5,14 L14.5,14 C14.7761424,14 15,13.7761424 15,13.5 C15,13.2238576 14.7761424,13 14.5,13 L10.5,13 Z" />
);

const NextStepFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

NextStepFilled.displayName = 'NextStepFilled';

export default NextStepFilled;
