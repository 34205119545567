import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const GraduateIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <path
      d="M15.4307 11.9353C15.6016 11.8096 15.7363 11.6411 15.8213 11.4468C15.9062 11.2524 15.9384 11.0391 15.9146 10.8283C15.8907 10.6176 15.8117 10.4168 15.6855 10.2463C15.5593 10.0759 15.3904 9.94169 15.1958 9.85736V8.19995L14.2268 8.61176V9.85736C14.0321 9.94165 13.863 10.0758 13.7367 10.2464C13.6105 10.4169 13.5314 10.6177 13.5076 10.8285C13.4837 11.0394 13.516 11.2528 13.601 11.4472C13.686 11.6416 13.8208 11.8102 13.9918 11.9358C13.8397 12.0475 13.716 12.1934 13.6306 12.3617C13.5452 12.53 13.5005 12.716 13.5001 12.9048V14.1155C13.5001 14.244 13.5511 14.3672 13.642 14.4581C13.7328 14.5489 13.8561 14.6 13.9846 14.6H15.438C15.5665 14.6 15.6897 14.5489 15.7806 14.4581C15.8714 14.3672 15.9225 14.244 15.9225 14.1155V12.9043C15.9221 12.7155 15.8774 12.5295 15.792 12.3612C15.7066 12.1929 15.5828 12.047 15.4307 11.9353Z"
      fill="#6F6F82"
    />

    <path
      d="M13 9V11.4667C13.0018 11.7534 12.929 12.0357 12.7888 12.2858C12.6485 12.536 12.4456 12.7453 12.2 12.8933C10.9255 13.6377 9.47602 14.03 8.00003 14.03C6.52404 14.03 5.07457 13.6377 3.80003 12.8933C3.55443 12.7453 3.35155 12.536 3.21129 12.2858C3.07104 12.0357 2.99824 11.7534 3.00003 11.4667V9L7.09003 10.73C7.37816 10.851 7.68753 10.9133 8.00003 10.9133C8.31254 10.9133 8.62191 10.851 8.91003 10.73L13 9Z"
      fill="#6F6F82"
    />

    <path
      d="M15.3453 5.07898L8.64935 2.24565C8.44357 2.15909 8.22258 2.1145 7.99935 2.1145C7.77611 2.1145 7.55512 2.15909 7.34935 2.24565L0.653345 5.07898C0.472358 5.15554 0.317938 5.28372 0.209372 5.44752C0.100807 5.61132 0.0429077 5.80347 0.0429077 5.99998C0.0429077 6.1965 0.100807 6.38865 0.209372 6.55245C0.317938 6.71625 0.472358 6.84443 0.653345 6.92099L7.34935 9.75432C7.5551 9.84097 7.77609 9.88561 7.99935 9.88561C8.2226 9.88561 8.4436 9.84097 8.64935 9.75432L15.3453 6.92099C15.5263 6.84443 15.6808 6.71625 15.7893 6.55245C15.8979 6.38865 15.9558 6.1965 15.9558 5.99998C15.9558 5.80347 15.8979 5.61132 15.7893 5.44752C15.6808 5.28372 15.5263 5.15554 15.3453 5.07898Z"
      fill="#6F6F82"
    />
  </Icon>
);

GraduateIcon.displayName = 'Graduate';

export default GraduateIcon;
