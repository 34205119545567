import { StyleSheet, css } from 'aphrodite';

import type { IncrementData, SpacingData } from '../index';
import { getYScreenValue } from '../utils';

const styles = StyleSheet.create({
  label: {
    cursor: 'default',
    fontSize: 15,
    userSelect: 'none',
  },

  valueMark: {
    stroke: '#000',
    strokeWidth: 1,
    pointerEvents: 'none',
  },
});

type Props = {
  increment: IncrementData;
  spacing: SpacingData;
  targetValue: number;
};

const ValueTracker = ({ increment, spacing, targetValue }: Props) => {
  const yCoord = getYScreenValue(targetValue, increment, spacing);
  return (
    <g transform={`translate(${0},${spacing.minBound + yCoord})`}>
      <line
        className={css(styles.valueMark)}
        strokeDasharray="2,2"
        x1={spacing.minBound}
        y1={-0.5}
        x2={spacing.maxBound}
        y2={-0.5}
      />
      <text
        transform={`translate(${spacing.maxBound},0)`}
        className={css(styles.label)}
        dy={`${1 / 3}em`}
      >
        {targetValue}
      </text>
    </g>
  );
};

export default ValueTracker;
