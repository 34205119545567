import { useCallback } from 'react';
import { useFragment, graphql } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import ResetStudentTemporaryPasswordModal from 'ms-pages/Teacher/components/ResetStudentTemporaryPasswordModal';
import Button from 'ms-ui-primitives/Button';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { StudentPasswordReset_student$key } from './__generated__/StudentPasswordReset_student.graphql';

type Props = {
  student: StudentPasswordReset_student$key;
};
export default function StudentPasswordReset({ student: studentRef }: Props) {
  const student = useFragment(
    graphql`
      fragment StudentPasswordReset_student on Student {
        temporaryPassword
        ...ResetStudentTemporaryPasswordModal_student
      }
    `,
    studentRef,
  );
  const { trackStructEvent } = useSnowplow();
  const { temporaryPassword } = student;
  const resetPasswordModalOpen = useBoolean();
  const openResetStudentTemporaryPasswordModal = useCallback(() => {
    resetPasswordModalOpen.setTrue();
    trackStructEvent({
      category: 'teacher_admin',
      action: 'clicked_open_reset_student_temporary_password_modal',
      label: 'CLASS_SETTINGS_MODAL',
    });
  }, [resetPasswordModalOpen, trackStructEvent]);
  return (
    <FieldGroup title="Password">
      {temporaryPassword != null ? (
        <div>{temporaryPassword}</div>
      ) : (
        <Button padding={0} onClick={openResetStudentTemporaryPasswordModal}>
          Reset password
        </Button>
      )}
      <ResetStudentTemporaryPasswordModal
        student={student}
        isOpen={resetPasswordModalOpen.value}
        onClose={resetPasswordModalOpen.setFalse}
        trackingLabel="CLASS_SETTINGS_MODAL"
      />
    </FieldGroup>
  );
}
