import type { ReactNode } from 'react';

import Separator from 'ms-pages/Teacher/components/Separator';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

import type { Items } from './';
import Pill from './Pill';

const PILL_GAP = BASE_UNIT;

const Root = styled({
  margin: -PILL_GAP,
  display: 'flex',
  flexWrap: 'wrap',
});

const PillWrapper = styled({
  margin: PILL_GAP,
});

type Props = {
  items: Items;
  removeItem?: ((arg: string) => void) | null | undefined;
  icon?: ReactNode;
  pillColor?: 'blue' | 'eggplant';
};

const PillsList = ({
  items,
  removeItem,
  icon,
  pillColor = 'eggplant',
}: Props) => {
  if (items.length <= 0) return null;
  return (
    <>
      <Root>
        {items.map(({ id, title }) => (
          <PillWrapper key={id}>
            <Pill
              label={title}
              icon={icon}
              isBlue={pillColor === 'blue'}
              onClose={
                removeItem == null
                  ? null
                  : () => {
                      removeItem(id);
                    }
              }
            />
          </PillWrapper>
        ))}
      </Root>
      <Separator size={3 * BASE_UNIT} />
    </>
  );
};

export default PillsList;
