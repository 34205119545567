import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.075 0a2.34 2.34 0 012.343 2.341V14.37c0 .054-.002.108-.005.161l-.006 4.319-4.948-2.139H2.344A2.34 2.34 0 010 14.37V2.341A2.344 2.344 0 012.344 0h11.73zM3.21 11.777c-.43-.394-.697-.122-.84.058-.141.18-.136.502-.008.742s2.714 1.78 5.933 1.78c3.218 0 5.608-1.507 5.809-1.78.2-.272.268-.468 0-.716-.269-.25-.374-.147-.723.01-.348.157-2.632 1.516-5.136 1.493-2.504-.023-4.606-1.192-5.035-1.587z"
  />
);

const Intercom = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={17}
    viewBoxHeight={19}
  >
    {path}
  </Icon>
);

Intercom.displayName = 'Intercom';

export default Intercom;
