import riveWasm from '@rive-app/canvas-advanced/rive.wasm';
import type {
  RiveState,
  UseRiveOptions,
  UseRiveParameters,
  StateMachineInputType,
  Bounds,
  EventCallback,
  EventType,
  FontAsset,
} from '@rive-app/react-canvas';
import { RuntimeLoader, decodeFont, useRive } from '@rive-app/react-canvas';
import { useMemo, useState } from 'react';

import gilroyExtraBoldUrl from 'ms-experiments/gamification/Gilroy-ExtraBold.otf';
import { Logger } from 'ms-utils/app-logging';
import { useUnmountEffect } from 'ms-utils/hooks/useUnmountEffect';
import type { Expand } from 'ms-utils/typescript-utils';
import { assert, assertUnreachable } from 'ms-utils/typescript-utils';

// Loading Rive runtime internally (vs external URL) ensures the WASM file is
// bundled with the app and loaded before dependent code runs, reducing
// potential race conditions.
RuntimeLoader.setWasmUrl(riveWasm);

export type StateMachineInput = {
  name: string;
  type: StateMachineInputType;
};

type StateMachine = {
  name: string;
  inputs: readonly StateMachineInput[];
};

type Artboard = {
  name: string;
  stateMachines: readonly StateMachine[];
};

// The Source type is derived from the Rive file contents generated by the
// generate-rive-types script. It defines the structure of Rive animation data,
// including artboards, state machines, etc.
export type Source = {
  artboards: readonly Artboard[];
};

export type ArtboardName<S extends Source> = S['artboards'][number]['name'];
type ArtboardByName<S extends Source, A extends ArtboardName<S>> = Extract<
  S['artboards'][number],
  { name: A }
>;

export type StateMachineName<
  S extends Source,
  A extends ArtboardName<S>,
> = Extract<ArtboardByName<S, A>, { name: A }>['stateMachines'][number]['name'];

export type StateMachineByName<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
> = Extract<ArtboardByName<S, A>['stateMachines'][number], { name: SM }>;

type StateMachineInputName<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
  T extends StateMachineInputType,
> = Extract<
  StateMachineByName<S, A, SM>['inputs'][number],
  { type: T }
>['name'];

export type TypedRive<
  S extends Source = any,
  A extends ArtboardName<S> = any,
  SM extends StateMachineName<S, A> = any,
> = {
  /**
   * Cleans up any Wasm-generated objects that need to be manually destroyed:
   * artboard instances, animation instances, state machine instances.
   *
   * Once this is called, things will need to be reinitialized or bad things
   * might happen.
   */
  cleanupInstances(): void;
  getTextRunValue(textRunName: string): string | undefined;
  setTextRunValue(textRunName: string, textRunValue: string): void;
  play(): void;
  pause(): void;
  scrub(): void;
  stop(): void;
  /**
   * Resets the animation
   */
  reset(params?: { autoplay?: boolean }): void;
  /**
   * Returns the inputs for the instanced state machine, or an empty list if the
   * state machine is not instanced
   * @returns the inputs for the state machine
   */
  stateMachineInputs(): StateMachineByName<S, A, SM>['inputs'];
  /**
   * Sets the value of the input
   */
  value(
    inputName: StateMachineInputName<S, A, SM, StateMachineInputType.Number>,
    value: number,
  ): void;
  value(
    inputName: StateMachineInputName<S, A, SM, StateMachineInputType.Boolean>,
    value: boolean,
  ): void;
  /**
   * Fires a trigger; does nothing on Number or Boolean input types
   */
  fire(
    inputName: StateMachineInputName<S, A, SM, StateMachineInputType.Trigger>,
  ): void;
  bounds: Bounds;
  /**
   * Subscribe to Rive-generated events
   * @param type the type of event to subscribe to
   * @param callback callback to fire when the event occurs
   */
  on(type: EventType, callback: EventCallback): void;
  /**
   * Unsubscribes from a Rive-generated event
   * @param type the type of event to unsubscribe from
   * @param callback the callback to unsubscribe
   */
  off(type: EventType, callback: EventCallback): void;
};

// Rather than omitting the properties we want to override from RiveState, we
// instead pick the properties we want to keep. This is so that we can vet any
// new properties added to RiveState in the future; for type safety, etc.
type TypedRiveState<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
> = Expand<
  Pick<
    RiveState,
    | 'canvas'
    | 'container'
    | 'setCanvasRef'
    | 'setContainerRef'
    | 'RiveComponent'
  > & {
    rive: TypedRive<S, A, SM> | null;
    isLoaded: boolean;
  }
>;

type UseTypedRiveParameters<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
> = Expand<
  Omit<
    Exclude<UseRiveParameters, null>,
    'src' | 'artboard' | 'stateMachines'
  > & {
    src: S;
    artboard: A;
    // We only support a single state machine because running multiple can cause
    // issues apparently.
    // See: https://rive.app/community/doc/rive-parameters/docHI9ASztXP#parameters
    stateMachine: SM;
  }
>;

// This hook wraps around Rive's useRive hook, providing a narrow subset of the
// Rive API that also provides type-safe access to state machines and their
// inputs.
export function useTypedRive<
  S extends Source,
  A extends ArtboardName<S>,
  SM extends StateMachineName<S, A>,
>(
  riveParams: UseTypedRiveParameters<S, A, SM>,
  opts?: Partial<UseRiveOptions>,
): TypedRiveState<S, A, SM> {
  const [isLoaded, setIsLoaded] = useState(false);
  const abortController = useMemo(() => new AbortController(), []);

  useUnmountEffect(() => {
    abortController.abort();
  });

  // 🚨 We allow src to be a string because we expect it to be a string during
  // runtime, i.e. Webpack module resolution will resolve this as a string,
  // while TS will resolve it as the Source type during compile time. We do
  // this so that we can know for sure what strings to use when accessing
  // artboards, state machines, etc. Else we'd simply need to blindly trust
  // these values haven't been changed by animators prior to handover, which
  // obviously opens us up to failure.
  const src: unknown = riveParams.src;
  assert(typeof src === 'string');

  const { rive: riveInstance, ...rest } = useRive(
    {
      ...riveParams,
      src,
      stateMachines: riveParams.stateMachine,
      onLoad: () => {
        setIsLoaded(true);
      },
      assetLoader: _asset => {
        // Load fonts at runtime to keep Rive file sizes down
        if (_asset.name === 'Gilroy-ExtraBold') {
          const asset = _asset as FontAsset;
          fetch(gilroyExtraBoldUrl, { signal: abortController.signal })
            .then(async res => {
              if (abortController.signal.aborted) return;
              try {
                // Create Rive-specific font object for setFont API
                const font = await decodeFont(
                  new Uint8Array(await res.arrayBuffer()),
                );
                if (abortController.signal.aborted) return;
                asset.setFont(font);

                // Release any references to clean up when no longer used
                font.unref();
              } catch (error) {
                Logger.error('Error setting font in Rive', {
                  extra: { asset, error },
                });
              }
            })
            .catch(error => {
              // Abort errors are expected when the component unmounts
              if (error instanceof Error && error.name === 'AbortError') return;
              Logger.error('Error fetching font for Rive', {
                extra: { asset, error },
              });
            });
          // Return true for assets that we've handled (as opposed to handled by
          // the Rive runtime).
          return true;
        } else {
          return false;
        }
      },
    },
    opts,
  );

  // Ensure we're working with the original Rive instance
  const riveProxy = useMemo(() => {
    if (riveInstance === null) {
      return null;
    }

    return new Proxy(riveInstance as unknown as TypedRive<S, A, SM>, {
      get(target, prop) {
        assertIsTypedRiveProperty(prop);
        switch (prop) {
          case 'cleanupInstances':
          case 'getTextRunValue':
          case 'play':
          case 'pause':
          case 'scrub':
          case 'stop':
          case 'on':
          case 'off': {
            const value = target[prop];
            return typeof value === 'function' ? value.bind(target) : value;
          }
          case 'setTextRunValue': {
            const setTextRunValue: TypedRive<S, A, SM>['setTextRunValue'] = (
              textRunName,
              textRunValue,
            ) => {
              if (target.getTextRunValue(textRunName) === undefined) {
                throw Error(
                  `text run name ${textRunName} does not exist in .riv file`,
                );
              }
              target.setTextRunValue(textRunName, textRunValue);
            };
            return setTextRunValue;
          }
          case 'reset': {
            const reset: TypedRive<S, A, SM>['reset'] = params => {
              riveInstance.reset({
                artboard: riveParams.artboard,
                stateMachines: riveParams.stateMachine,
                autoplay: params?.autoplay ?? false,
              });
            };
            return reset;
          }
          case 'stateMachineInputs': {
            return () => {
              return riveInstance.stateMachineInputs(riveParams.stateMachine);
            };
          }
          case 'value':
            const value: TypedRive<S, A, SM>['value'] = (inputName, value) => {
              const input = riveInstance
                .stateMachineInputs(riveParams.stateMachine)
                .find(t => t.name === inputName);
              assert(
                input !== undefined,
                `Input ${inputName} not found in state machine ${riveParams.stateMachine}`,
              );
              input.value = value;
            };
            return value;
          case 'fire':
            const fire: TypedRive<S, A, SM>['fire'] = inputName => {
              const triggerInput = riveInstance
                .stateMachineInputs(riveParams.stateMachine)
                .find(t => t.name === inputName);
              assert(
                triggerInput !== undefined,
                `Input ${inputName} not found in state machine ${riveParams.stateMachine}`,
              );
              triggerInput.fire();
            };
            return fire;
          case 'bounds':
            return target[prop];

          default: {
            assertUnreachable(prop);
          }
        }
      },
    });
  }, [riveInstance, riveParams.artboard, riveParams.stateMachine]);

  return {
    ...rest,
    rive: riveProxy,
    isLoaded,
  };
}

const typedRiveProperties: Record<keyof TypedRive, true> = {
  cleanupInstances: true,
  getTextRunValue: true,
  setTextRunValue: true,
  play: true,
  pause: true,
  scrub: true,
  stop: true,
  reset: true,
  stateMachineInputs: true,
  value: true,
  fire: true,
  bounds: true,
  on: true,
  off: true,
};

function assertIsTypedRiveProperty(
  prop: string | symbol,
): asserts prop is keyof TypedRive {
  assert(typeof prop === 'string');
  assert(Object.keys(typedRiveProperties).includes(prop));
}
