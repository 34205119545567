/* eslint-disable react/sort-comp */
import { StyleSheet } from 'aphrodite';
import MQ, { type IMathField } from 'mathquill';
import { compose, filter, findIndex, sort, test } from 'ramda';
import * as React from 'react';
import type { KeyboardEvent as SyntheticKeyboardEvent } from 'react';

import LatexInput from 'ms-components/LatexInput';
import type { RequiredMathDisplayFeaturesType as LatexInputRequiredMathDisplayFeaturesType } from 'ms-components/LatexInput';
import LatexMultiInput from 'ms-components/LatexMultiInput';
import type { RequiredMathDisplayFeaturesType as LatexMultiInputRequiredMathDisplayFeaturesType } from 'ms-components/LatexMultiInput';
import MathContent from 'ms-components/math/MathContent';
import { AppEnvRenderer, type AppEnv } from 'ms-helpers/AppEnv';
import type { FocusedLatexInput } from 'ms-pages/Work/Workout/types';
import { colors } from 'ms-styles/colors';
import { lessThanOrEqual } from 'ms-utils/misc/versioning';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import * as shortcutInserters from './mathShortcutInserters';
import { matchesShortcut } from './shortcutKeys';

function noop() {}

type Html = string;
type Latex = string;

export type Correctness =
  | 'CORRECT_FINAL'
  | 'CORRECT_INCOMPLETE'
  | 'ERROR'
  | 'INCORRECT'
  | 'UNKNOWN';

export type Value = {
  [key: string]: {
    readonly latex: Latex;
    readonly substatus: Correctness;
  };
};

export type MathShortcut =
  | 'ADD'
  | 'SUBTRACT'
  | 'MULTIPLY'
  | 'DIVIDE'
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'LESS_THAN'
  | 'GREATER_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'GREATER_THAN_OR_EQUAL'
  | 'OPEN_PARENTHESIS'
  | 'CLOSE_PARENTHESIS'
  | 'OPEN_BRACE'
  | 'CLOSE_BRACE'
  | 'ABSOLUTE_VALUE_VERTICAL_BAR'
  | 'EXPONENT'
  | 'SUBSCRIPT'
  | 'FRACTION'
  | 'MIXED_FRACTION'
  | 'SQUARE_ROOT'
  | 'NTH_ROOT'
  | 'SINE'
  | 'COSINE'
  | 'TANGENT'
  | 'INVERSE_SINE'
  | 'INVERSE_COSINE'
  | 'INVERSE_TANGENT'
  | 'LOGARITHM_BASE_10'
  | 'LOGARITHM_BASE_B'
  | 'NATURAL_LOGARITHM'
  | 'LOWERCASE_PI'
  | 'LOWERCASE_ALPHA'
  | 'LOWERCASE_BETA'
  | 'LOWERCASE_GAMMA'
  | 'LOWERCASE_DELTA'
  | 'LOWERCASE_THETA'
  | 'LOWERCASE_OMEGA'
  | 'SUMMATION'
  | 'SIMILAR_EQUAL'
  | 'SIMILAR'
  | 'APPROXIMATELY'
  | 'EQUIVALENT'
  | 'CONGRUENT'
  | 'IS_COMMON'
  | 'PARALLEL'
  | 'PERPENDICULAR'
  | 'DEGREES'
  | 'MINUTES'
  | 'SECONDS'
  | 'TRIANGLE'
  | 'ANGLE'
  | 'PLUS_MINUS'
  | 'BINOMIAL_COEFFICIENT'
  | 'NCR_BINOMIAL_COEFFICIENT'
  | 'FACTORIAL'
  | 'RECURRING_DECIMAL'
  | 'NPR_PERMUTATION'
  | 'UNION'
  | 'INTERSECTION'
  | 'INDEFINITE_INTEGRAL'
  | 'DEFINITE_INTEGRAL'
  | 'DEFINITE_INTEGRAL_EVALUATION_BRACKETS'
  | 'DIFFERENTIAL_OPERATOR_LEIBNIZ_NOTATION'
  | 'DERIVATIVE_OF_F_LAGRANGE_NOTATION'
  | 'LIMIT'
  | 'INFINITY';

export type Action =
  | {
      type: 'INSERT_SHORTCUT';
      shortcut: MathShortcut;
    }
  | {
      type: 'REPLACE_VALUE';
      latex: Latex;
    }
  | {
      type: 'SELECT_NEXT';
    }
  | {
      type: 'SELECT_PREV';
    };

type InputMode = 'default' | 'keyboard' | 'handwriting' | 'sketchpad';

// This type cannot be exact, because flow currently will never infer the
// result of an object spread as exact.
// We currently do an object spread in our `<AnswerInput />` and
// `<AnswerAttempt />` components.
//
// See also: https://github.com/facebook/flow/issues/2405#issuecomment-337577068
type PublicProps = {
  disabled?: boolean | undefined;
  template: Html;
  onChange: (value: Value) => void;
  onFocus?: ((input: FocusedLatexInput) => void) | undefined;
  onBlur?: ((event: Event) => void) | undefined;
  value: Value;
  inputMode?: InputMode | undefined;
  showIncorrectStyles?: boolean | undefined;
  requiredMathDisplayFeatures:
    | LatexInputRequiredMathDisplayFeaturesType
    | LatexMultiInputRequiredMathDisplayFeaturesType;
};

type Props = PublicProps & {
  appEnv: AppEnv;
};

type DefaultProps = {
  onBlur: (event: Event) => void;
  onFocus: (input: FocusedLatexInput) => void;
  requiredMathDisplayFeatures:
    | LatexInputRequiredMathDisplayFeaturesType
    | LatexMultiInputRequiredMathDisplayFeaturesType;
};

// This won't fit the smallest mobile screens (< 375px)
// but we don't want to make it too small for larger screens
// TODO: check if the width can be made responsive to the parent container
const PRIMARY_LATEX_INPUT_MIN_WIDTH = 265;

// MathQuill internally supports a \editable{} macro, which changes the
// behaviour of a MathField.
// See class comments for LatexInput and LatexMultiInput.
const MATHQUILL_EDITABLE_REGEX = /\\editable\{.*?\}/g;

export const getMqFromTextarea = (
  textarea: HTMLTextAreaElement,
): IMathField | null | undefined => MQ(textarea?.parentElement?.parentElement);

const isMultiInputExpression = test(MATHQUILL_EDITABLE_REGEX);

const shouldDisableNativeKeyboard = (
  inputMode: InputMode | null | undefined,
): boolean => inputMode !== 'keyboard';

/**
 * For the specified math shortcut, performs the necessary side effect on the
 * provided MathQuill math field in order to insert the shortcuts corresponding
 * math expression.
 */
// eslint-disable-next-line no-unused-vars
const runSideEffectFor = (
  mathShortcut: MathShortcut,
  mathField: IMathField,
): void => {
  // We are using a switch statement here to manually perform the delegation
  // (rather than performing a dynamic lookup using the shortcut name) so that
  // we can lean on Flow's exhaustiveness checking ability.
  switch (mathShortcut) {
    case 'ADD':
      shortcutInserters.insertAdd(mathField);
      break;
    case 'SUBTRACT':
      shortcutInserters.insertSubtract(mathField);
      break;
    case 'MULTIPLY':
      shortcutInserters.insertMultiply(mathField);
      break;
    case 'DIVIDE':
      shortcutInserters.insertDivide(mathField);
      break;
    case 'EQUALS':
      shortcutInserters.insertEquals(mathField);
      break;
    case 'NOT_EQUALS':
      shortcutInserters.insertNotEquals(mathField);
      break;
    case 'LESS_THAN':
      shortcutInserters.insertLessThan(mathField);
      break;
    case 'GREATER_THAN':
      shortcutInserters.insertGreaterThan(mathField);
      break;
    case 'LESS_THAN_OR_EQUAL':
      shortcutInserters.insertLessThanOrEqual(mathField);
      break;
    case 'GREATER_THAN_OR_EQUAL':
      shortcutInserters.insertGreaterThanOrEqual(mathField);
      break;
    case 'OPEN_PARENTHESIS':
      shortcutInserters.insertOpenParenthesis(mathField);
      break;
    case 'CLOSE_PARENTHESIS':
      shortcutInserters.insertCloseParenthesis(mathField);
      break;
    case 'OPEN_BRACE':
      shortcutInserters.insertOpenBrace(mathField);
      break;
    case 'CLOSE_BRACE':
      shortcutInserters.insertCloseBrace(mathField);
      break;
    case 'ABSOLUTE_VALUE_VERTICAL_BAR':
      shortcutInserters.insertAbsoluteValueVerticalBar(mathField);
      break;
    case 'EXPONENT':
      shortcutInserters.insertExponent(mathField);
      break;
    case 'SUBSCRIPT':
      shortcutInserters.insertSubscript(mathField);
      break;
    case 'FRACTION':
      shortcutInserters.insertFraction(mathField);
      break;
    case 'MIXED_FRACTION':
      shortcutInserters.insertMixedFraction(mathField);
      break;
    case 'SQUARE_ROOT':
      shortcutInserters.insertSquareRoot(mathField);
      break;
    case 'NTH_ROOT':
      shortcutInserters.insertNthRoot(mathField);
      break;
    case 'SINE':
      shortcutInserters.insertSine(mathField);
      break;
    case 'COSINE':
      shortcutInserters.insertCosine(mathField);
      break;
    case 'TANGENT':
      shortcutInserters.insertTangent(mathField);
      break;
    case 'INVERSE_SINE':
      shortcutInserters.insertInverseSine(mathField);
      break;
    case 'INVERSE_COSINE':
      shortcutInserters.insertInverseCosine(mathField);
      break;
    case 'INVERSE_TANGENT':
      shortcutInserters.insertInverseTangent(mathField);
      break;
    case 'LOGARITHM_BASE_10':
      shortcutInserters.insertLogarithmBase10(mathField);
      break;
    case 'LOGARITHM_BASE_B':
      shortcutInserters.insertLogarithmBaseB(mathField);
      break;
    case 'NATURAL_LOGARITHM':
      shortcutInserters.insertNaturalLogarithm(mathField);
      break;
    case 'LOWERCASE_PI':
      shortcutInserters.insertLowercasePi(mathField);
      break;
    case 'LOWERCASE_ALPHA':
      shortcutInserters.insertLowercaseAlpha(mathField);
      break;
    case 'LOWERCASE_BETA':
      shortcutInserters.insertLowercaseBeta(mathField);
      break;
    case 'LOWERCASE_GAMMA':
      shortcutInserters.insertLowercaseGamma(mathField);
      break;
    case 'LOWERCASE_DELTA':
      shortcutInserters.insertLowercaseDelta(mathField);
      break;
    case 'LOWERCASE_THETA':
      shortcutInserters.insertLowercaseTheta(mathField);
      break;
    case 'LOWERCASE_OMEGA':
      shortcutInserters.insertLowercaseOmega(mathField);
      break;
    case 'SUMMATION':
      shortcutInserters.insertSummation(mathField);
      break;
    case 'SIMILAR_EQUAL':
      shortcutInserters.insertSimilarEqual(mathField);
      break;
    case 'SIMILAR':
      shortcutInserters.insertSimilar(mathField);
      break;
    case 'APPROXIMATELY':
      shortcutInserters.insertApproximately(mathField);
      break;
    case 'EQUIVALENT':
      shortcutInserters.insertEquivalent(mathField);
      break;
    case 'CONGRUENT':
      shortcutInserters.insertCongruent(mathField);
      break;
    case 'IS_COMMON':
      shortcutInserters.insertIsCommon(mathField);
      break;
    case 'PARALLEL':
      shortcutInserters.insertParallel(mathField);
      break;
    case 'PERPENDICULAR':
      shortcutInserters.insertPerpendicular(mathField);
      break;
    case 'DEGREES':
      shortcutInserters.insertDegrees(mathField);
      break;
    case 'MINUTES':
      shortcutInserters.insertMinutes(mathField);
      break;
    case 'SECONDS':
      shortcutInserters.insertSeconds(mathField);
      break;
    case 'TRIANGLE':
      shortcutInserters.insertTriangle(mathField);
      break;
    case 'ANGLE':
      shortcutInserters.insertAngle(mathField);
      break;
    case 'PLUS_MINUS':
      shortcutInserters.insertPlusMinus(mathField);
      break;
    case 'BINOMIAL_COEFFICIENT':
      shortcutInserters.insertBinomialCoefficient(mathField);
      break;
    case 'NCR_BINOMIAL_COEFFICIENT':
      shortcutInserters.insertNCrBinomialCoefficient(mathField);
      break;
    case 'FACTORIAL':
      shortcutInserters.insertFactorial(mathField);
      break;
    case 'RECURRING_DECIMAL':
      shortcutInserters.insertRecurringDecimal(mathField);
      break;
    case 'NPR_PERMUTATION':
      shortcutInserters.insertNPrPermutation(mathField);
      break;
    case 'UNION':
      shortcutInserters.insertUnion(mathField);
      break;
    case 'INTERSECTION':
      shortcutInserters.insertIntersection(mathField);
      break;
    case 'INDEFINITE_INTEGRAL':
      shortcutInserters.insertIndefiniteIntegral(mathField);
      break;
    case 'DEFINITE_INTEGRAL':
      shortcutInserters.insertDefiniteIntegral(mathField);
      break;
    case 'DEFINITE_INTEGRAL_EVALUATION_BRACKETS':
      shortcutInserters.insertDefiniteIntegralEvaluationBrackets(mathField);
      break;
    case 'DIFFERENTIAL_OPERATOR_LEIBNIZ_NOTATION':
      shortcutInserters.insertDifferentialOperatorLeibnizNotation(mathField);
      break;
    case 'DERIVATIVE_OF_F_LAGRANGE_NOTATION':
      shortcutInserters.insertDerivativeOfFLagrangeNotation(mathField);
      break;
    case 'LIMIT':
      shortcutInserters.insertLimit(mathField);
      break;
    case 'INFINITY':
      shortcutInserters.insertInfinity(mathField);
      break;
    default:
      assertUnreachable(mathShortcut);
  }
};

// Users have a number of shortcuts for inserting various latex constructs
// into MathQuill. By using the key in this shorcuts list, along with the
// platform specific modifier keys, they can perform the mathShortcut action.
const keyboardShortcuts: Array<{ key: string; mathShortcut: MathShortcut }> = [
  { key: '/', mathShortcut: 'MIXED_FRACTION' },
  { key: 'v', mathShortcut: 'SQUARE_ROOT' },
  { key: 'u', mathShortcut: 'UNION' },
  { key: 'n', mathShortcut: 'INTERSECTION' },
  { key: 'p', mathShortcut: 'LOWERCASE_PI' },
  { key: 'a', mathShortcut: 'ANGLE' },
  { key: 't', mathShortcut: 'TRIANGLE' },
  { key: '-', mathShortcut: 'PLUS_MINUS' },
  { key: 'm', mathShortcut: 'IS_COMMON' },
];
const isMac = test(/Mac/, navigator.userAgent);
const modifierKeys = isMac ? { ctrlKey: true, altKey: true } : { altKey: true };

/**
 * Sort an array of textareas by the order a browser would navigate through
 * them with tab.  This function will also omit textareas with tabIndex "-1"
 * from the result.
 *
 * Note that this implementation does not currently handle disabled or hidden
 * elements.
 *
 * @see {@link https://www.w3.org/TR/html4/interact/forms.html#tabbing-navigation}
 * @param {*} textareas Array of textareas to be sorted
 * @returns Array of sorted textareas
 */
const sortByBrowserTabOrder = (textareas: ReadonlyArray<HTMLTextAreaElement>) =>
  compose(
    sort<HTMLTextAreaElement>((a, b) => {
      const getTabIndex = ({ tabIndex }: HTMLTextAreaElement) =>
        tabIndex === 0 ? Infinity : tabIndex;
      return getTabIndex(a) - getTabIndex(b);
    }),
    filter<HTMLTextAreaElement>(textarea => textarea.tabIndex !== -1),
  )(textareas);

const styles = StyleSheet.create({
  primaryLatexInput: {
    minWidth: PRIMARY_LATEX_INPUT_MIN_WIDTH,
  },
  INCORRECT: {
    color: colors.cinnabar,
    borderColor: colors.cinnabar,
  },
});

/**
 * This component is the entry point for rendering an interactive
 * "WorkedSolution" or "Inline" subproblem type.
 */
class LatexAnswer extends React.Component<Props, {}> {
  static defaultProps: DefaultProps;

  firstInput: LatexMultiInput | LatexInput | null = null;

  // This is a hack to update hasBeenAnswered status
  // when answer is loaded as filled
  override componentDidMount() {
    if (this.props.value) {
      this.props.onChange(this.props.value);
    }
  }

  textareas: Set<HTMLTextAreaElement> = new Set();

  blur = () => {
    if (this.firstInput) {
      this.firstInput.blur();
    }
  };

  focus = () => {
    if (this.firstInput) {
      this.firstInput.focus();
    }
  };

  select = () => {
    if (this.firstInput) this.firstInput.select();
  };

  /**
   * This is an escape hatch which allows components elsewhere in the react
   * tree to imperatively drive LatexAnswer.
   *
   * At time of writing, the primary use cases for this are to receive input
   * from HandwritingPanel and MathToolbar.
   *
   * @param {*} action A flux-style object which describes the intended effect.
   */
  dispatchLatexAnswerAction = (action: Action) => {
    const mq = this.getActiveMathQuillController();
    if (!mq) return;

    switch (action.type) {
      case 'INSERT_SHORTCUT':
        runSideEffectFor(action.shortcut, mq);
        break;
      case 'REPLACE_VALUE':
        mq.latex(action.latex);
        break;
      case 'SELECT_NEXT':
        this.selectNext();
        break;
      case 'SELECT_PREV':
        this.selectPrevious();
        break;
      default:
      // Pass
    }
  };

  // Produces a MathQuill controller for the currently focused MathQuill input.
  // If no MathQuill input has focus, produces null.
  getActiveMathQuillController = (): IMathField | null => {
    // Check if the activeElement is one of our textareas.
    const activeElement = document.activeElement;
    if (!(activeElement instanceof HTMLTextAreaElement)) return null;
    if (!this.textareas.has(activeElement)) return null;

    // Attempt to extract the MathQuill controller from the activeElement
    const mq = getMqFromTextarea(activeElement);
    if (!(mq instanceof MQ.MathField)) return null;
    return mq;
  };

  selectNext() {
    const textareas = sortByBrowserTabOrder(Array.from(this.textareas));
    const activeIndex = findIndex(
      textarea => textarea === document.activeElement,
      textareas,
    );

    const nextIndex =
      activeIndex === -1
        ? 0
        : activeIndex + 1 < textareas.length
        ? activeIndex + 1
        : activeIndex;

    textareas[nextIndex]?.focus();
  }

  selectPrevious() {
    const textareas = sortByBrowserTabOrder(Array.from(this.textareas));
    const activeIndex = findIndex(
      textarea => textarea === document.activeElement,
      textareas,
    );

    const nextIndex =
      activeIndex === -1
        ? textareas.length - 1
        : activeIndex >= 1
        ? activeIndex - 1
        : activeIndex;

    textareas[nextIndex]?.focus();
  }

  // TODO: REMOVE THIS
  // In our iOS app prior to version 2.6.7, we attempt to close the handwriting
  // panel on each navigation action. This included client-side navigation
  // events. In this case, it was possible for a race condition to occur and
  // leave the <HandwritingPanel> component mounted but the native panel
  // closed.
  // We need a recovery story for this scenario, so here we cause the panel
  // to close and open on each latex input click.
  handleClick = () => {
    const { appEnv, inputMode } = this.props;
    if (
      appEnv.RUNTIME.TYPE === 'IOS_WEBVIEW' &&
      lessThanOrEqual(appEnv.RUNTIME.VERSION || '', '2.6.6') &&
      inputMode === 'handwriting'
    ) {
      this.blur();
      // Use a timeout to prevent React from batching state updates.
      window.setTimeout(this.focus);
    }
  };

  handleKeyDown = (event: SyntheticKeyboardEvent<any>) => {
    keyboardShortcuts.forEach(({ key, mathShortcut }) => {
      if (!matchesShortcut({ key, ...modifierKeys }, event)) return;
      event.preventDefault();
      const mq = this.getActiveMathQuillController();
      if (!mq) return;
      runSideEffectFor(mathShortcut, mq);
    });
  };

  override render() {
    const {
      inputMode,
      onChange,
      template,
      value,
      disabled,
      showIncorrectStyles = true,
    } = this.props;

    return (
      <MathContent
        key="math-content"
        content={template}
        nodeRenderers={{
          LATEX_INPUT_STATIC_OR_DYNAMIC: ({
            key,
            answerKey,
            isPrimary,
          }: {
            key: string;
            answerKey: string;
            isPrimary: boolean;
          }) => {
            // Using ! not unwrap as unsure of the safety of this one
            const valueForKey = value[answerKey]!;
            const isMultiInput = isMultiInputExpression(valueForKey.latex);
            const Component = isMultiInput ? LatexMultiInput : LatexInput;
            const readOnly = shouldDisableNativeKeyboard(inputMode);

            return (
              // TODO TS doesn't understand this type of dynamic component
              // usage unfortunately. Need to see if there is an alternative
              // pattern that allows it to understand what's happening.
              // @ts-expect-error
              <Component
                disabled={disabled}
                key={key}
                // capture refs to all MathQuill textarea fields.
                ref={(node: LatexMultiInput | LatexInput | null) => {
                  if (node == null) return;
                  if (answerKey === '1') this.firstInput = node;
                  if (isMultiInput && 'textareas' in node) {
                    node.textareas.forEach(textarea => {
                      this.textareas.add(textarea);
                    });
                  }
                  if (
                    !isMultiInput &&
                    'textarea' in node &&
                    node.textarea != null
                  ) {
                    this.textareas.add(node.textarea);
                  }
                }}
                aphroditeStyles={[
                  isPrimary &&
                    !isMultiInputExpression(valueForKey.latex) &&
                    styles.primaryLatexInput,
                  ((valueForKey.substatus === 'INCORRECT' &&
                    showIncorrectStyles) ||
                    valueForKey.substatus === 'ERROR') &&
                    styles.INCORRECT,
                ]}
                onChange={(nextLatex: string) => {
                  // We only want to invalidate the substatus validation coloring
                  // if we have actually changed the latex.
                  if (nextLatex === valueForKey.latex) return;

                  onChange({
                    ...value,
                    // The inline validation coloring needs to be reset once we
                    // have modified the input value.
                    [answerKey]: { latex: nextLatex, substatus: 'UNKNOWN' },
                  });
                }}
                onClick={this.handleClick}
                onKeyDown={this.handleKeyDown}
                onFocus={() => {
                  if (this.props.onFocus != null) this.props.onFocus(answerKey);
                }}
                onBlur={this.props.onBlur}
                placeholder={isPrimary ? 'Enter your next step here' : ''}
                readOnly={readOnly}
                tabIndex={parseInt(answerKey, 10)}
                value={valueForKey.latex}
                requiredMathDisplayFeatures={
                  this.props.requiredMathDisplayFeatures
                }
              />
            );
          },
        }}
      />
    );
  }
}
LatexAnswer.defaultProps = {
  onBlur: noop,
  onFocus: noop,
  requiredMathDisplayFeatures: {
    multiplicationNotation: 'CROSS',
  },
};

type PublicDefaultProps = {
  requiredMathDisplayFeatures:
    | LatexInputRequiredMathDisplayFeaturesType
    | LatexMultiInputRequiredMathDisplayFeaturesType;
};

// eslint-disable-next-line react/no-multi-comp
class LatexAnswerPublic extends React.Component<PublicProps> {
  static defaultProps: PublicDefaultProps;
  latexAnswer: LatexAnswer | null = null;
  _dispatchLatexAnswerAction: ((action: Action) => void) | null = null;
  _focus: (() => void) | null = null;

  dispatchLatexAnswerAction(action: Action) {
    this._dispatchLatexAnswerAction?.(action);
  }

  focus() {
    this._focus?.();
  }

  override componentDidMount() {
    const latexAnswer = this.latexAnswer;
    if (latexAnswer == null) return;
    this._dispatchLatexAnswerAction = latexAnswer.dispatchLatexAnswerAction;
    this._focus = latexAnswer.focus;
  }

  select = () => {
    if (this.latexAnswer != null) {
      this.latexAnswer.select();
    }
  };

  override render() {
    return (
      <AppEnvRenderer
        render={({ appEnv }) => (
          <LatexAnswer
            {...this.props}
            ref={node => (this.latexAnswer = node)}
            appEnv={appEnv}
          />
        )}
      />
    );
  }
}

LatexAnswerPublic.defaultProps = {
  requiredMathDisplayFeatures: {
    multiplicationNotation: 'CROSS',
  },
};

export default LatexAnswerPublic;
