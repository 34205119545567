import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M5.36854 15.8402L12.8396 8.3686C12.9465 8.26185 13 8.13904 13 8.00011C13 7.86119 12.9465 7.73815 12.8396 7.63135L5.36855 0.160377C5.26151 0.0533465 5.13871 -3.43628e-07 4.99972 -3.49703e-07C4.8608 -3.55776e-07 4.73782 0.0535149 4.63101 0.160377L3.82963 0.961814C3.72277 1.06868 3.66937 1.19165 3.66937 1.33058C3.66937 1.46951 3.72277 1.59249 3.82963 1.69935L10.1303 8.00011L3.82924 14.3011C3.72237 14.4079 3.66931 14.5309 3.66931 14.6696C3.66931 14.8088 3.72271 14.9318 3.82924 15.0386L4.63096 15.8401C4.73776 15.9469 4.8608 16 4.99967 16C5.1387 16.0001 5.26168 15.947 5.36854 15.8402Z" />
);

const ChevronRightThick = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

ChevronRightThick.displayName = 'ChevronRightThick';

export default ChevronRightThick;
