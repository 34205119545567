import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

type LowerScoreProps = Props & {
  circleColor?: string;
  arrowColor?: string;
};

const LowerScore = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
  circleColor = colors.iron,
  arrowColor = color,
}: LowerScoreProps) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={56}
    viewBoxHeight={56}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill={circleColor}
    />
    <g clipPath="url(#clip0_1651_54033)">
      <path
        d="M37.7952 33.8134C37.659 33.6665 37.4686 33.5818 37.2683 33.5792H32.3918V23.8267C32.3918 23.3878 32.2942 22.9001 31.9041 22.9001H24.1017C23.6069 22.9581 23.2096 23.3355 23.1263 23.8267V33.5792H18.7375C18.3335 33.5845 18.0104 33.9162 18.0157 34.3202C18.0183 34.5205 18.103 34.7109 18.2499 34.8471L27.5153 43.9175C27.7792 44.1868 28.2114 44.1913 28.4807 43.9274C28.4841 43.9242 28.4873 43.9209 28.4906 43.9175L37.756 34.8471C38.0523 34.5725 38.0698 34.1097 37.7952 33.8134Z"
        fill={arrowColor}
      />
      <path
        d="M24.1026 21.4001H31.9051C32.3091 21.4001 32.6366 20.9971 32.6366 20.5001C32.6366 20.0031 32.3091 19.6001 31.9051 19.6001H24.1026C23.6986 19.6001 23.3711 20.0031 23.3711 20.5001C23.3711 20.9971 23.6986 21.4001 24.1026 21.4001Z"
        fill={arrowColor}
      />
      <path
        d="M24.1026 18.1H31.9051C32.3091 18.1 32.6366 17.6971 32.6366 17.2C32.6366 16.703 32.3091 16.3 31.9051 16.3H24.1026C23.6986 16.3 23.3711 16.703 23.3711 17.2C23.3711 17.6971 23.6986 18.1 24.1026 18.1Z"
        fill={arrowColor}
      />
      <path
        d="M24.1026 14.8H31.9051C32.3091 14.8 32.6366 14.397 32.6366 13.9C32.6366 13.403 32.3091 13 31.9051 13H24.1026C23.6986 13 23.3711 13.403 23.3711 13.9C23.3711 14.3971 23.6986 14.8 24.1026 14.8Z"
        fill={arrowColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1651_54033">
        <rect
          width="32"
          height="30"
          fill="white"
          transform="translate(12.002 13)"
        />
      </clipPath>
    </defs>
  </Icon>
);

LowerScore.displayName = 'LowerScore';

export default LowerScore;
