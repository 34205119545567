import { useContext } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import DuplicateStudentCard from 'ms-pages/Teacher/components/StudentCreateEdit/components/DuplicateStudentCard';
import { StateContext } from 'ms-pages/Teacher/components/StudentCreateEdit/state';

import type { DuplicateStudentsQuery } from './__generated__/DuplicateStudentsQuery.graphql';
import type { StudentFound } from './types';

type Props = {
  firstName: string;
  lastName: string;
  schoolId: string;
  onSubmitted?: ((student: StudentFound) => void) | undefined;
  disableButtons: boolean;
  onSubmit: () => void;
};
export default function DuplicateStudents({
  schoolId,
  firstName,
  lastName,
  onSubmitted,
  disableButtons,
  onSubmit,
}: Props) {
  const { classes } = useContext(StateContext);
  const studentsDetails = [{ firstName, lastName, uniqueId: '' }];
  const { props } = useQuery<DuplicateStudentsQuery>(
    graphql`
      query DuplicateStudentsQuery(
        $schoolId: ID!
        $studentsDetails: [DuplicateStudentsSearchInput!]!
      ) {
        school(id: $schoolId) {
          duplicateStudentsSearch(studentsDetails: $studentsDetails) {
            studentsFound {
              id
              user {
                firstName
                lastName
                avatar
                email
              }
            }
          }
        }
      }
    `,
    { schoolId, studentsDetails },
  );
  if (props == null || props.school == null) return null;
  // Since this component only deals with a check for a single set of details, we can "cheat"
  // a little and instead of doing two loops (since the result is a list of lists) we can assume
  // that if the outer list isn't empty it's got exactly one element.
  // We should only ever also search results if a class has been selected
  const searchResults =
    classes.length > 0 &&
    props.school.duplicateStudentsSearch &&
    props.school.duplicateStudentsSearch[0];
  return (
    <div>
      {searchResults &&
        searchResults.studentsFound.map(student => (
          <DuplicateStudentCard
            student={student}
            key={student.id}
            onSubmitted={() => {
              if (onSubmitted != null) onSubmitted(student);
            }}
            disableButtons={disableButtons}
            onSubmit={onSubmit}
          />
        ))}
    </div>
  );
}
