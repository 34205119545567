import { css, StyleSheet } from 'aphrodite';

import Root from 'ms-components/Root';
import Logo from 'ms-components/logos/Logo';
import {
  breakPoints,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Container from 'ms-ui-primitives/Container';
import Page from 'ms-ui-primitives/Page';

import image from './image.svg';

const PAGE_PADDING_BOTTOM = 60; // to fit intercom launcher
const HEADER_SIZE_MEDIUM = 0.8;
const WRAPPER_MAX_WIDTH_LARGE = 690;
const MEDIA_SIZE = 85;
const MEDIA_SIZE_MEDIUM = 205;
const CONTENT_MARGIN_TOP = 10;
const CONTENT_MARGIN_MEDIUM = 20;
const CONTENT_MARGIN_TOP_LARGE = -5; // to offset the text's line height
const CONTENT_MARGIN_LEFT_LARGE = 60;
const TITLE_MARGIN_BOTTOM = 5;
const TITLE_MARGIN_BOTTOM_MEDIUM = 10;
const DESCRIPTION_MAX_WIDTH = 250; // to wrap the text on mobile
const DESCRIPTION_MARGIN_TOP = 10;
const DESCRIPTION_MARGIN_TOP_MEDIUM = 16;
const ERROR_MARGIN_BOTTOM = 5;
const LINKS_MARGIN_TOP = 15;
const LINKS_MARGIN_TOP_MEDIUM = 30;
const LINK_SIZE = 280;
const LINK_SIZE_MEDIUM = 170;
const LINK_MARGIN_VERTICAL = 8;

const backgroundColor = colors.seashell;

const styles = StyleSheet.create({
  headerWrapper: {
    display: 'flex',
    flexGrow: HEADER_SIZE_MEDIUM,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor,
  },
  page: {
    justifyContent: 'center',
    paddingBottom: PAGE_PADDING_BOTTOM,
    backgroundColor,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      justifyContent: 'flex-start',
      paddingBottom: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [`@media (min-width: ${breakPoints.large}px)`]: {
      flexDirection: 'row',
      maxWidth: WRAPPER_MAX_WIDTH_LARGE,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  media: {
    flexShrink: 0,
    width: MEDIA_SIZE,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      width: MEDIA_SIZE_MEDIUM,
    },
  },
  content: {
    marginTop: CONTENT_MARGIN_TOP,
    textAlign: 'center',

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      marginTop: CONTENT_MARGIN_MEDIUM,
    },

    [`@media (min-width: ${breakPoints.large}px)`]: {
      marginTop: CONTENT_MARGIN_TOP_LARGE,
      marginLeft: CONTENT_MARGIN_LEFT_LARGE,
      textAlign: 'left',
    },
  },
  title: {
    marginBottom: TITLE_MARGIN_BOTTOM,
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: fontSize.notFoundTitle,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.heading,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      marginBottom: TITLE_MARGIN_BOTTOM_MEDIUM,
      fontSize: fontSize.notFoundTitleMedium,
    },
  },
  lead: {
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: fontSize.notFoundLead,
    lineHeight: lineHeight.body,

    [`@media (min-width: ${breakPoints.small}px)`]: {
      fontSize: fontSize.notFoundLeadSmall,
    },

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      fontSize: fontSize.notFoundLeadMedium,
    },
  },
  error: {
    marginBottom: ERROR_MARGIN_BOTTOM,
  },
  description: {
    maxWidth: DESCRIPTION_MAX_WIDTH,
    marginTop: DESCRIPTION_MARGIN_TOP,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: colors.dustyGray,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    lineHeight: lineHeight.body,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      maxWidth: 'none',
      marginTop: DESCRIPTION_MARGIN_TOP_MEDIUM,
      fontSize: fontSize.notFoundDescriptionMedium,
    },
  },
  links: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: LINKS_MARGIN_TOP,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      marginTop: LINKS_MARGIN_TOP_MEDIUM,
    },

    [`@media (min-width: ${breakPoints.large}px)`]: {
      justifyContent: 'left',
    },
  },
  link: {
    width: LINK_SIZE,
    marginTop: LINK_MARGIN_VERTICAL,
    marginBottom: LINK_MARGIN_VERTICAL,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      width: LINK_SIZE_MEDIUM,
      marginTop: 0,
      marginBottom: 0,
    },
  },
});

type Props = {
  friendlyErrorMessage?: string | null | undefined;
};

const PageNotFoundView = ({ friendlyErrorMessage }: Props) => (
  <Root>
    <div className={css(styles.headerWrapper)}>
      <a href="/">
        <Logo hasText />
      </a>
    </div>
    <Page aphroditeStyles={[styles.page]}>
      <Container>
        <div className={css(styles.wrapper)}>
          <div className={css(styles.media)}>
            <img
              src={image}
              alt="calculator illustration"
              role="presentation"
            />
          </div>
          <div className={css(styles.content)}>
            <div className={css(styles.title)}>Sorry…</div>
            <div className={css(styles.lead)}>
              {friendlyErrorMessage ||
                `We’ll find this page for you, as soon as we find the last digit of π`}
            </div>
            <div className={css(styles.description)}>
              <div className={css(styles.error)}>Error 404</div>
              <div>We can't seem to find the page you're looking for.</div>
              <div className={css(styles.links)}>
                <div className={css(styles.link)}>
                  {/* This button doesn't take you to the dashboard since we also show this page for users that are not logged in*/}
                  <AnchorButton href="/" type="primary" isBlock>
                    Back to home
                  </AnchorButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Page>
  </Root>
);

export default PageNotFoundView;
