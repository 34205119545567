import { useState, useCallback, useMemo } from 'react';

export function useBoolean(initialValue?: boolean): {
  value: boolean;
  toggle: () => void;
  setTrue: () => void;
  setFalse: () => void;
} {
  const [value, setValue] = useState(!!initialValue);
  const toggle = useCallback(() => setValue(s => !s), []);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return useMemo(
    () => ({
      value,
      toggle,
      setTrue,
      setFalse,
    }),
    [value, setFalse, setTrue, toggle],
  );
}
