import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path
      d="M0 4.8829C0 3.00413 1.87653 1.70369 3.63568 2.36337C3.87368 2.45262 4.08153 2.60747 4.23516 2.80998L12.7377 14.0178C13.8983 15.5477 16.1775 15.6084 17.4179 14.1425L25.6022 4.47006C25.8647 4.15991 26.1874 3.9063 26.5508 3.72461L27.6584 3.17081C29.6531 2.17346 32 3.62395 32 5.85409V20C32 21.6569 30.6569 23 29 23H3C1.34315 23 0 21.6569 0 20V4.8829Z"
      fill="#1ABC9C"
    />
    <path
      d="M16 13.4279C16 12.5245 16.4071 11.6692 17.1082 11.0996L27.1082 2.97454C29.0688 1.3816 32 2.77678 32 5.30288V20C32 21.6569 30.6569 23 29 23H19C17.3431 23 16 21.6569 16 20V13.4279Z"
      fill="#66B1E2"
    />
    <path
      d="M25.281 1.43895C26.8216 -0.144365 29.3194 -0.144365 30.86 1.43895C32.4005 3.02228 32.4005 5.58935 30.86 7.17268L19.0583 19.3017C17.5177 20.885 15.0199 20.885 13.4793 19.3017C11.9388 17.7184 11.9388 15.1513 13.4793 13.568L25.281 1.43895Z"
      fill="#3F4694"
    />
    <path
      d="M1.1558 7.21418C-0.384778 5.63086 -0.384779 3.06378 1.1558 1.48046C2.69638 -0.102862 5.19415 -0.102862 6.73473 1.48046L18.755 13.8343C20.2956 15.4176 20.2956 17.9847 18.755 19.568C17.2144 21.1513 14.7166 21.1513 13.1761 19.568L1.1558 7.21418Z"
      fill="#398984"
    />
    <path
      d="M15.9999 11L22.8786 17.8787C24.7685 19.7686 23.43 23 20.7573 23H11.2425C8.56988 23 7.23137 19.7686 9.12126 17.8787L15.9999 11Z"
      fill="#9EDAE2"
    />
    <path
      d="M16 11L21.6569 16.6569L19.5355 18.7782C17.5829 20.7308 14.4171 20.7308 12.4645 18.7782L10.3431 16.6569L16 11Z"
      fill="#36A6B3"
    />
    <path
      d="M-6.00815e-05 4.5L8.45104 5.56768L3.8633 9.998L-0.567017 5.41027L-6.00815e-05 4.5Z"
      fill="#398984"
    />
    <path
      d="M29.5355 1.46447L26 5L29.5355 8.53553L32 6.07107V3.92893L29.5355 1.46447Z"
      fill="#3F4694"
    />
  </>
);

const Mathspace = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={34}
    viewBoxHeight={23}
  >
    {path}
  </Icon>
);

Mathspace.displayName = 'Mathspace';

export default Mathspace;
