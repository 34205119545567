import { useRef, useCallback, useEffect } from 'react';

export default function useUpdateLessonWorkoutTimeSpent(
  scrollingElement: HTMLElement | null | undefined,
  callback: () => void,
  interval: number, // time in milliseconds
  timeout: number, // time in milliseconds,
) {
  // ==============================
  // Set up for tracking 'time active time spent' (by calling a generic callback)
  // We define active time any time during a mousemove or a scroll happen
  // withing {timeout} milliseconds.

  const lastActive = useRef(Date.now()); // useRef so that the value is not lost between renders.
  const activityListener = useCallback(() => {
    lastActive.current = Date.now();
  }, []);

  useEffect(() => {
    const maybeCallback = () => {
      if (Date.now() - lastActive.current < timeout) {
        callback();
      }
    };
    const intervalId = setInterval(maybeCallback, interval);

    window.addEventListener('mousemove', activityListener);
    // The scrolling element is buried in the component hierarchy.  It's not the page root, which has a fixed size.
    // useEffect will be called when the scrolling element is updated, so we re-register everything at that point.
    if (scrollingElement != null) {
      scrollingElement.addEventListener('scroll', activityListener);
    }
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('mousemove', activityListener);
      if (scrollingElement != null) {
        scrollingElement.removeEventListener('scroll', activityListener);
      }
    };
  }, [activityListener, callback, interval, scrollingElement, timeout]);
}
