import ConfirmationPrompt from './ConfirmationPrompt';

type Props = {
  noteTitle: string;
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
  onClose: () => void;
};

export default function SaveConfirmation({
  noteTitle,
  isOpen,
  onSave,
  onCancel,
  onClose,
}: Props) {
  return (
    <ConfirmationPrompt
      isOpen={isOpen}
      onConfirm={onSave}
      onCancel={onCancel}
      onClose={onClose}
      header="Are you sure?"
      body={`Save changes to the document
“${noteTitle}” before closing?`}
      cancelLabel="Don't save"
      confirmLabel="Save"
    />
  );
}
