import { invertObj } from 'ramda';

import type { Value } from 'ms-components/math/NumberLineIntervals';
import {
  deserializeCoordinateValues,
  serializeCoordinateValues,
} from 'ms-components/math/private-shared/legacyTransformers/numberLine';
import type {
  subproblemFragments_NumberLineConfig,
  NumberLineMode,
  NumberLineSubstatus,
  NumberLineUnit,
} from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_NumberLineConfig.graphql';
import type { Mode } from 'ms-utils/math/toLatex';
import { unwrap } from 'ms-utils/typescript-utils';

export type Props = {
  value: Value;
};
const toInternalStatus: Record<
  NumberLineSubstatus,
  'correct' | 'incorrect' | 'unknown'
> = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  UNKNOWN: 'unknown',
};
const fromInternalStatus = invertObj(toInternalStatus) as Record<
  'correct' | 'incorrect' | 'unknown',
  NumberLineSubstatus
>;
export const toInternalMode: Record<NumberLineMode, Mode> = {
  DECIMAL: 'decimal',
  FRACTION: 'fraction',
  IMPROPER: 'improper',
  SIMPLIFIED_FRACTION: 'simplified',
};
/**
 * Transforms an AnswerInputNumberLineConfig into the props for the corresponding
 * `NumberLineIntervals` component.
 *
 * @param {NumberLineValues} Subproblem's answer input config.
 * @returns {Props} Props for `NumberLineIntervals` component.
 */
export const toInternalValueFormat = (
  numberLineValues: subproblemFragments_NumberLineConfig['numberLineValues'],
): Props => ({
  value: numberLineValues.map(segment => [
    {
      position: [deserializeCoordinateValues(segment.lowerBound.value)],
      meta: {
        inclusive: segment.lowerBound.inclusive,
        status: toInternalStatus[segment.substatus],
      },
    },
    {
      position: [deserializeCoordinateValues(segment.upperBound.value)],
      meta: {
        inclusive: segment.upperBound.inclusive,
        status: toInternalStatus[segment.substatus],
      },
    },
  ]),
});
/**
 * Transforms the props of `NumberLineIntervals` component into
 * the shape of the input value state of the subproblem.
 *
 * @param {Props} props The subset of `NumberLineIntervals` component props.
 * @returns {InputValue} The input config.
 */
export const fromInternalValueFormat = (
  props: Props,
): subproblemFragments_NumberLineConfig['numberLineValues'] => {
  const result = props.value.map(segment => ({
    // TODO coercing with ! as I'm not confident if these metadata fields
    // are meant to always be present or not. They were untyped before,
    // so potentially this indexation was producing undefineds before.
    // I typed them as optional when refactoring to TS as there was code
    // that empty objects for metadata...
    substatus: fromInternalStatus[segment[0].meta.status!],
    lowerBound: {
      value: serializeCoordinateValues(unwrap(segment[0].position[0])),
      // Dodgy ! coercion (see comment above)
      inclusive: segment[0].meta.inclusive!,
    },
    upperBound: {
      value: serializeCoordinateValues(unwrap(segment[1].position[0])),
      // Dodgy ! coercion (see comment above)
      inclusive: segment[1].meta.inclusive!,
    },
  }));
  return result;
};
/**
 * Transforms a GraphQL NumberLineUnit enum value into a valid latex string.
 *
 * @param {*} unit The axis unit, as specified by GraphQL response.
 */
export const graphQlUnitEnumToLatex = (unit: NumberLineUnit): string => {
  switch (unit) {
    case 'PI':
      return '\\pi';
    case 'NO_UNIT':
    default:
      return '';
  }
};
