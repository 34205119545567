type Connection = {
  readonly edges: ReadonlyArray<{
    readonly node: any;
  }>;
};

export default function extractNode<C extends Connection>(
  connection: C,
): ReadonlyArray<C['edges'][number]['node']> {
  return connection.edges.map(edge => edge.node);
}

// The @babel/parser + typescript plugin is choking on this valid
// TS type.
//
// export type CallExtractNode<C extends Connection> = ReturnType<
//   typeof extractNode<C>
//                     ^
// Specifically it fails at this token. To avoid this issue for the
// moment, we're re-exporting this type from a .d.ts file so that
// only tsc consumes it (which doesn't have a broken parser).
//
// TODO look into upgrading the babel+typescript plugin stack
// so it can parse Typescript correctly.
export type { CallExtractNode } from './extractNodeHelperHack';
