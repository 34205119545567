import type {
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
} from 'react';

import type { Dimensions } from 'ms-utils/math/cartesian';
import type { Mode } from 'ms-utils/math/toLatex';

import Scale from '../Scale';
import type { ScaleIncrements } from '../Scale';

type Props = {
  color?: string | undefined;
  dimensions: Dimensions;
  mode?: Mode | undefined;
  onMouseDown?:
    | ((event: SyntheticTouchEvent | SyntheticMouseEvent) => void)
    | undefined;
  onMouseLeave?:
    | ((event: SyntheticTouchEvent | SyntheticMouseEvent) => void)
    | undefined;
  onMouseUp?:
    | ((event: SyntheticTouchEvent | SyntheticMouseEvent) => void)
    | undefined;
  readOnly?: boolean | undefined;
  scale: ScaleIncrements;
  unit?: string | undefined;
  width: number;
  y: number;
};

export default function Axis({
  color = '#666',
  dimensions,
  mode,
  onMouseDown,
  onMouseLeave,
  onMouseUp,
  readOnly,
  scale,
  unit,
  width,
  y,
}: Props) {
  const height = 25;
  const leftArrow = <path d="M-4,0 l12,-4 q-4,4 0,8 l-12,-4" fill={color} />;
  const rightArrow = (
    <path d={`M${width + 4},0 l-12,-4 q4,4 0,8 l12,-4`} fill={color} />
  );

  return (
    <g
      cursor={readOnly ? undefined : 'pointer'}
      onMouseDown={onMouseDown}
      onMouseLeave={onMouseLeave}
      onMouseUp={onMouseUp}
      onTouchEnd={onMouseUp}
      onTouchStart={onMouseDown}
      transform={`translate(${dimensions.margin},${y})`}
    >
      <rect
        fillOpacity="0"
        height={height * 2}
        width="100%"
        x={-dimensions.margin}
        y={-height}
      />
      <line
        key="axis_line"
        stroke={color}
        strokeWidth={2}
        x1={0}
        x2={width}
        y1={0}
        y2={0}
      />
      {leftArrow}
      {rightArrow}
      <Scale
        color={color}
        dimensions={dimensions}
        mode={mode}
        scale={scale}
        unit={unit}
      />
    </g>
  );
}
