import moment from 'moment';
import { map } from 'ramda';
import { useState, useMemo } from 'react';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { EditTaskFlyout_assignments as Assignments } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_assignments.graphql';
import type { EditTaskFlyout_customTask as CustomTask } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_customTask.graphql';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import editCustomTask from './state/editCustomTask';
import type { editCustomTaskMutationVariables } from './state/editCustomTask';
import * as updaters from './state/updaters';
import { excludedStudents } from '../';

export type TaskId = string;
export type TaskName = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;
export type ExtensionDays = number;
export type DisableHelp = boolean;
export type DisableCalculator = boolean;
export type DisableRetry = boolean;
export type State = {
  taskId: TaskId;
  taskName: TaskName;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  extensionDays: ExtensionDays;
  disableHelp: DisableHelp;
  disableCalculator: DisableCalculator;
  disableRetry: DisableRetry;
  isAdvancedOptionsVisible: boolean;
  selectedClasses: readonly ClassSelectionPayload[];
  selectedStudents: readonly StudentSelectionPayload[];
  excludedStudents: readonly StudentSelectionPayload[];
};
type Updaters = typeof updaters;
export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;
type CallbackUpdater<I extends readonly any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};
export type CombinedState = {
  values: State;
  updaters: TransformedUpdaters;
};
const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );
function getEditCustomTaskVariables({
  taskId,
  taskName,
  taskDueDate,
  taskStartDate,
  extensionDays,
  disableHelp,
  disableCalculator,
  disableRetry,
  selectedClasses,
  selectedStudents,
  excludedStudents,
}: State): editCustomTaskMutationVariables {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  return {
    taskId,
    title: taskName,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    enableHelp: !disableHelp,
    enableCalculator: !disableCalculator,
    enableRetry: !disableRetry,
    extensionDays,
    startDate: taskStartDate.toISOString(),
    first: RELAY_CONNECTION_MAX,
  };
}
export function useEditCustomTaskState(
  task: CustomTask,
  assignments: Assignments,
) {
  const [state, setState] = useState<State>({
    taskId: task.id,
    taskName: task.title,
    taskDueDate: new Date(task.dueDate),
    taskStartDate: new Date(task.startDate),
    extensionDays: moment(task.expiryDate).diff(moment(task.dueDate), 'days'),
    disableHelp: !task.allowHints,
    disableCalculator: !(task.allowCalculator ?? true),
    disableRetry: !task.allowRetry,
    selectedClasses: assignments.assignedClasses,
    selectedStudents: assignments.individuallyAssignedStudents.map(student => ({
      id: student.id,
      ...student.user,
    })),
    excludedStudents: excludedStudents(assignments.partiallyAssignedClasses),
    isAdvancedOptionsVisible: false,
  });
  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);
  const mutation = editCustomTask(getEditCustomTaskVariables(state));
  return [{ values: state, updaters: transformedUpdaters }, mutation] as const;
}
