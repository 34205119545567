/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type helpersUseIsWithSkillCheckinStepQueryVariables = {
    subtopicId: string;
    singleSubtopic: boolean;
};
export type helpersUseIsWithSkillCheckinStepQueryResponse = {
    readonly subtopic?: {
        readonly skills: ReadonlyArray<{
            readonly id: string;
        }>;
    } | null | undefined;
};
export type helpersUseIsWithSkillCheckinStepQuery = {
    readonly response: helpersUseIsWithSkillCheckinStepQueryResponse;
    readonly variables: helpersUseIsWithSkillCheckinStepQueryVariables;
};



/*
query helpersUseIsWithSkillCheckinStepQuery(
  $subtopicId: ID!
  $singleSubtopic: Boolean!
) {
  subtopic(id: $subtopicId) @include(if: $singleSubtopic) {
    skills {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "singleSubtopic"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subtopicId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skills",
  "plural": true,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "helpersUseIsWithSkillCheckinStepQuery",
    "selections": [
      {
        "condition": "singleSubtopic",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Subtopic",
            "kind": "LinkedField",
            "name": "subtopic",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "helpersUseIsWithSkillCheckinStepQuery",
    "selections": [
      {
        "condition": "singleSubtopic",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Subtopic",
            "kind": "LinkedField",
            "name": "subtopic",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "64507c280d00815f4f0340cd1175360f",
    "id": null,
    "metadata": {},
    "name": "helpersUseIsWithSkillCheckinStepQuery",
    "operationKind": "query",
    "text": "query helpersUseIsWithSkillCheckinStepQuery(\n  $subtopicId: ID!\n  $singleSubtopic: Boolean!\n) {\n  subtopic(id: $subtopicId) @include(if: $singleSubtopic) {\n    skills {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '049a7d56a22044e53fb72e7884cad234';
export default node;
