import styled from '@emotion/styled';
import MathProse from 'jsx-content/jsx-activity/components/MathProse/MathProse';
import moment from 'moment';
import { sort } from 'ramda';
import { useEffect, useReducer, useMemo, useState } from 'react';
import { graphql, useQuery, useMutation } from 'relay-hooks';
import uuidv4 from 'uuid-browser';

import KebabDropdown from 'ms-components/KebabDropdown';
import Retry from 'ms-components/Retry';
import DeleteNote from 'ms-components/StudyNotesModal/DeleteNote';
import SaveConfirmation from 'ms-components/StudyNotesModal/SaveConfirmation';
import SaveNote from 'ms-components/StudyNotesModal/SaveNote';
import UpdateNote from 'ms-components/StudyNotesModal/UpdateNote';
import { HEADER_SIZE } from 'ms-components/curriculum-mapping/Header';
import CreateNoteIcon from 'ms-components/icons/CreateNote';
import CrossThickIcon from 'ms-components/icons/CrossThick';
import HighlighterIcon from 'ms-components/icons/Highlighter';
import StudyNotesIcon from 'ms-components/icons/StudyNotes';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  font,
  fontSize,
  fontFamily,
  fontWeight,
  borderRadiusUI,
} from 'ms-styles/base';
import { colors, lanternColors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';
import Separator from 'ms-ui-primitives/Separator';
import {
  useAccessibilityMode,
  accessibilityModeStyle,
} from 'ms-utils/accessibility';
import { onPressOrHover, onPress, onHover, tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import keyDownMap from 'ms-utils/keyDownMap';
import { assertUnreachable, unwrap } from 'ms-utils/typescript-utils';

import type { CanvasState } from './Canvas';
import HighlightingNoteEditor, {
  MAIN_CONTENT_PADDING,
  WIDTH as EDITOR_MIN_WIDTH,
} from './HighlightingNoteEditor';
import type { SyllabusLocale } from './__generated__/HighlightingNoteEditorQuery.graphql';
import type { StudyNotesModalCreateHighlightedStudyNoteMutation } from './__generated__/StudyNotesModalCreateHighlightedStudyNoteMutation.graphql';
import type { StudyNotesModalCreateStudyNoteMutation } from './__generated__/StudyNotesModalCreateStudyNoteMutation.graphql';
import type { StudyNotesModalDeleteStudyNoteMutation } from './__generated__/StudyNotesModalDeleteStudyNoteMutation.graphql';
import type {
  StudyNotesModalQuery,
  StudyNotesModalQueryResponse,
  JsxContentType,
} from './__generated__/StudyNotesModalQuery.graphql';
import type { StudyNotesModalUpdateHighlightedStudyNoteMutation } from './__generated__/StudyNotesModalUpdateHighlightedStudyNoteMutation.graphql';
import type { StudyNotesModalUpdateStudyNoteMutation } from './__generated__/StudyNotesModalUpdateStudyNoteMutation.graphql';

const ASIDE_WIDTH = 294;
const EMPTY_HIGHLIGHTING_NOTE_STATE: CanvasState = {
  drawings: [],
  undoes: [],
};
const ErrorContainter = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
const ModalHeader = styled.div({
  height: HEADER_SIZE,
  padding: '20px 40px',
  borderBottom: `2px solid ${colors.seashell}`,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  background: colors.white,
});
const ModalTitle = styled.div({
  ...font.gilroyHeading,
  color: lanternColors.grey,
});
const ActionButtonWrapper = styled.div({
  paddingLeft: 6,
  paddingRight: 6,
  marginLeft: 'auto',
});
const ActionWrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
});
const CloseButtonWrapper = styled.div({
  paddingLeft: 24,
  marginLeft: 'auto',
});
const ASIDE_TOGGLER_SIZE = 32;
const AsideToggler = styled.div<{
  isSidebarCollapsed: boolean;
}>(({ isSidebarCollapsed }) => ({
  width: ASIDE_TOGGLER_SIZE,
  height: ASIDE_TOGGLER_SIZE,
  backgroundColor: colors.almond50,
  position: 'absolute',
  right: -ASIDE_TOGGLER_SIZE,
  top: 24,
  borderTopRightRadius: borderRadiusUI,
  borderBottomRightRadius: borderRadiusUI,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '.3s transform',
  color: colors.white,
  zIndex: 4, // Must be over the svg canvas
  ...tappable,
  ...onHover({
    opacity: 0.9,
  }),
  ...onPress({
    transform: 'scale(.9)',
  }),
  ...(isSidebarCollapsed && {
    right: -ASIDE_WIDTH - ASIDE_TOGGLER_SIZE,
  }),
}));
const NotesCounter = styled.div({
  backgroundColor: colors.cloudBurst,
  color: colors.almond50,
  fontSize: fontSize.xSmall,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: ASIDE_TOGGLER_SIZE / 2,
  width: ASIDE_TOGGLER_SIZE / 2,
});
const Aside = styled.div<{
  isCollapsed: boolean;
}>(({ isCollapsed }) => ({
  width: ASIDE_WIDTH,
  flexShrink: 0,
  background: colors.almond50,
  transition: '.3s transform, .3s width',
  position: 'relative', // IMPORTANT to position the counter/toggler
  overflowX: 'visible',
  ...(isCollapsed && {
    transform: `translateX(-${ASIDE_WIDTH}px)`,
    width: 0,
  }),
}));
const NotesList = styled.div({
  padding: 20,
  paddingLeft: 32,
  height: '100%',
  overflowY: 'auto',
});
const NoteEditor = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: 40,
  background: 'white',
});
const Menu = styled.div({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 10,
  // Work around KebabDropdown not calculating correctly
  '& > div > div:last-child': {
    // emotion types aren't allowing us to add !important to position property
    // see https://github.com/emotion-js/emotion/issues/2444
    position: 'absolute !important' as 'absolute',
    top: '20px !important',
    right: '0px !important',
    left: 'revert !important',
  },
});
const ModalContentLayouContainer = styled.div({
  display: 'flex',
  overflow: 'hidden',
});
const ModalContentScrollingContainer = styled.div({
  overflow: 'auto',
  flexGrow: 1,
  padding: MAIN_CONTENT_PADDING,
  paddingTop: 2 * MAIN_CONTENT_PADDING, // addtional space to leave space for the sidebar toggle
  minHeight: '80vh',
  minWidth: EDITOR_MIN_WIDTH,
});
const NoteEntry = styled.div<{
  selected: boolean;
  accessibilityMode: boolean;
}>(({ selected, accessibilityMode }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 4,
  borderRadius: 4,
  padding: 8,
  overflow: 'hidden',
  color: lanternColors.grey,
  ...tappable,
  ...onPressOrHover({
    background: 'rgba(0, 0, 0, .05)',
  }),
  ...(selected && {
    color: colors.eggplant,
  }),
  ...(accessibilityMode && accessibilityModeStyle),
}));
const NoteEntryTitle = styled.div({
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: 16,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '60%',
});
const NoteEntryUpdatedDate = styled.div({
  marginLeft: 'auto',
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  lineHeight: '24px',
  color: colors.grey90,
  whiteSpace: 'nowrap',
});
const NoteTitle = styled.div({
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.headerTitle,
  color: lanternColors.grey,
  lineHeight: '18px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '60%',
  whiteSpace: 'nowrap',
});
const NoteCreationDate = styled.div({
  paddingLeft: 12,
  paddingRight: 6,
  whiteSpace: 'nowrap',
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  color: colors.grey90,
});
type EditorMode = 'highlightNote' | 'studentNote';
type EditingMode = 'creating' | 'editing';
type Props = {
  isOpen: boolean;
  onClose: () => void;
  editorMode?: EditorMode;
  editingMode?: EditingMode;
  subtopicId: string | null;
  jsxContentType: JsxContentType | null;
  locale: SyllabusLocale | null;
};

function getEmptyDocument(): Note {
  return {
    doc: {
      type: 'doc',
    },
    selection: {
      type: 'all',
    },
  };
}

const getEmptyNote = () => ({
  id: uuidv4(),
  createdAt: moment().format(),
  updatedAt: moment().format(),
  title: 'Untitled Note',
  note: getEmptyDocument(),
  jsxContentType: null,
  jsxContent: '',
  jsxContentLocale: null,
  subtopic: null,
  highlightedData: '',
});

function jsonKeySorter(_key: string, value: any) {
  if (value == null || value.constructor !== Object) {
    return value;
  }
  return Object.keys(value)
    .sort()
    .reduce((s, k) => {
      // @ts-expect-error Typescript sucks at building up data structures
      s[k] = value[k];
      return s;
    }, {});
}

function getNoteContents(contents: string | Note): Note {
  let jsonNote;
  if (typeof contents === 'string') {
    try {
      jsonNote = JSON.parse(contents);
    } catch (e) {
      jsonNote = getEmptyDocument();
    }
  } else {
    jsonNote = contents;
  }
  return jsonNote;
}

type User = NonNullable<StudyNotesModalQueryResponse['viewer']>;
type Student = NonNullable<User['profile']>;
type StudentNoteFromApi = NonNullable<Student['studyNotes']>[number];
type Note = {
  doc: {};
  selection:
    | {
        type: 'text';
        anchor: number;
        head: number;
      }
    | {
        type: 'node';
        anchor: number;
      }
    | {
        type: 'all';
      };
};
type StudentNote = Omit<StudentNoteFromApi, 'note'> & {
  note: Note;
};
type State = {
  dirty: boolean;
  requestedNote?: string | null | undefined;
  currentNote: StudentNote;
  editorMode: EditorMode;
  editingMode: EditingMode;
};
type Action<T extends string, Payload> = {
  type: T;
  payload: Payload;
};
type SetDirtyAction = Action<'SetDirty', boolean>;
type RequestNoteAction = Action<'RequestNote', string | null | undefined>;
type GetNewNoteAction = Action<'GetNewNote', null>;
type SetNoteAction = Action<'SetNote', StudentNote | StudentNoteFromApi>;
type SetTitleAction = Action<'SetTitle', string>;
type SetContentsAction = Action<'SetContents', Note>;
type SetEditorMode = Action<'SetEditorMode', EditorMode>;
type SetEditingMode = Action<'SetEditingMode', EditingMode>;
type Actions =
  | SetDirtyAction
  | RequestNoteAction
  | GetNewNoteAction
  | SetNoteAction
  | SetTitleAction
  | SetContentsAction
  | SetEditorMode
  | SetEditingMode;

// typings for reducer should be inferred by flow but it's not working
function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'SetDirty':
      return { ...state, dirty: action.payload };
    case 'RequestNote':
      return {
        ...state,
        requestedNote: action.payload,
      };
    case 'GetNewNote':
      const newNote = getEmptyNote();
      return {
        ...state,
        editorMode: 'studentNote',
        requestedNote: null,
        currentNote: newNote,
        editingMode: 'creating',
      };
    case 'SetNote':
      const currentNote = {
        ...action.payload,
        note: getNoteContents(action.payload.note),
      };
      return {
        ...state,
        editorMode:
          currentNote.jsxContentType == null ? 'studentNote' : 'highlightNote',
        editingMode: 'editing',
        requestedNote: null,
        currentNote,
        dirty: false,
      };
    case 'SetTitle':
      return {
        ...state,
        currentNote: { ...state.currentNote, title: action.payload },
      };
    case 'SetContents':
      const note = getNoteContents(action.payload);
      return {
        ...state,
        currentNote: { ...state.currentNote, note },
      };
    case 'SetEditorMode': {
      return {
        ...state,
        editingMode: 'creating',
        editorMode: action.payload,
      };
    }
    case 'SetEditingMode': {
      return {
        ...state,
        editingMode: action.payload,
      };
    }
    default:
      assertUnreachable(action);
  }
}

graphql`
  fragment StudyNotesModalQuery_studyNote on StudyNote @relay(mask: false) {
    id
    title
    note
    createdAt
    updatedAt
    jsxContentType
    jsxContent
    jsxContentLocale
    subtopic {
      id
    }
    highlightedData
  }
`;
export default function StudyNotesModal({ isOpen, onClose, ...rest }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      closeOnOverlayTap={false}
      sizeToContent
      fullWidth
    >
      <StudyNotesModalContent {...rest} isOpen={isOpen} onClose={onClose} />
    </Modal>
  );
}
const emptyList = [] as const; // Needed for referential equality
function StudyNotesModalContent({
  onClose,
  editorMode = 'studentNote',
  editingMode = 'editing',
  subtopicId,
  jsxContentType,
  locale,
}: Props) {
  const { props, error, retry } = useQuery<StudyNotesModalQuery>(
    graphql`
      query StudyNotesModalQuery {
        viewer {
          profile {
            ... on Student {
              id
              studyNotes {
                ...StudyNotesModalQuery_studyNote @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: 'network-only',
    },
  );
  const isLoading = error == null && props == null;
  const loadModal = !isLoading && error == null;
  const unsortedStudyNotes = props?.viewer?.profile?.studyNotes ?? emptyList;
  const studyNotes = useMemo(
    () =>
      sort(
        (n1, n2) => (moment(n1.updatedAt).isBefore(n2.updatedAt) ? 1 : -1),
        unsortedStudyNotes,
      ),
    [unsortedStudyNotes],
  );
  const showMathProse = useBoolean(true);
  const saveConfirmation = useBoolean();
  const saveNote = useBoolean();
  const updateNote = useBoolean();
  const deleteNote = useBoolean();
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const [state, dispatch] = useReducer(reducer, {
    dirty: false,
    currentNote: getEmptyNote(),
    editingMode: 'creating',
    editorMode,
  });
  const [highlightNoteState, setHighlightingNoteState] = useState(
    EMPTY_HIGHLIGHTING_NOTE_STATE,
  );
  useEffect(() => {
    let data;
    try {
      data = JSON.parse(state.currentNote.highlightedData);
    } catch {
      data = EMPTY_HIGHLIGHTING_NOTE_STATE;
    }
    setHighlightingNoteState(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentNote.id]);
  const isAsideCollapsed = useBoolean(editorMode === 'highlightNote');
  // TODO eventually refactor this in an healthier way 🥴
  useEffect(() => {
    dispatch({ type: 'SetEditingMode', payload: editingMode });
  }, [editingMode]);
  useEffect(() => {
    dispatch({ type: 'SetEditorMode', payload: editorMode });
  }, [editorMode]);
  useEffect(() => {
    // if the user opens the modal in editing mode,
    // we want to make sure that there is
    // a selected note to edit and the list is visible
    if (editingMode === 'editing' && studyNotes.length > 0) {
      const studyNote = unwrap(studyNotes[0]);
      dispatch({ type: 'SetNote', payload: studyNote });
      isAsideCollapsed.setFalse();
    }
  }, [editingMode, isAsideCollapsed, studyNotes]);
  useEffect(() => {
    // If we select the same note we need to refresh the contents of MathProse
    // but there is no way to do that so instead we force it to rerender
    if (!showMathProse.value) {
      showMathProse.setTrue();
    }
  }, [showMathProse]);

  function closeOrShowConfirmation() {
    if (state.dirty) {
      saveConfirmation.setTrue();
    } else {
      onClose();
    }
  }

  function cancelSaveConfirmation() {
    saveConfirmation.setFalse();
    dispatch({ type: 'SetDirty', payload: false });
    const studyNote = studyNotes.find(s => s.id === state.requestedNote);
    if (studyNote != null) {
      if (studyNote.id === state.currentNote.id) {
        // Same note so force MathProse to rerender
        showMathProse.setFalse();
      }
      dispatch({
        type: 'SetNote',
        payload: studyNote,
      });
    } else {
      dispatch({ type: 'GetNewNote', payload: null });
    }
  }

  function showSaveDialog() {
    saveConfirmation.setFalse();
    saveNote.setTrue();
  }

  function showUpdateDialog() {
    saveConfirmation.setFalse();
    updateNote.setTrue();
  }

  function saveNoteToServer(noteTitle: string) {
    saveNote.setFalse();
    dispatch({ type: 'SetDirty', payload: false });
    dispatch({ type: 'SetTitle', payload: noteTitle });
    const note = state.currentNote;
    if (state.editorMode === 'studentNote') {
      createStudyNote({
        variables: {
          title: noteTitle,
          note: JSON.stringify(note.note),
        },
      });
    } else if (
      state.editorMode === 'highlightNote' &&
      subtopicId != null &&
      jsxContentType != null
    ) {
      createHighlitedStudyNote({
        variables: {
          highlightedData: JSON.stringify(highlightNoteState),
          title: noteTitle,
          subtopicId,
          note: JSON.stringify(note.note),
          documentType: jsxContentType,
          locale,
        },
      });
    }
  }

  function updateNoteToServer(noteTitle: string) {
    updateNote.setFalse();
    dispatch({ type: 'SetDirty', payload: false });
    dispatch({ type: 'SetTitle', payload: noteTitle });
    const note = state.currentNote;
    if (state.editorMode === 'studentNote') {
      updateStudyNote({
        variables: {
          studyNoteId: note.id,
          title: noteTitle,
          note: JSON.stringify(note.note),
        },
      });
    } else if (state.editorMode === 'highlightNote') {
      updateHighlightedStudyNote({
        variables: {
          highlightedData: JSON.stringify(highlightNoteState),
          title: noteTitle,
          studyNoteId: note.id,
          note: JSON.stringify(note.note),
        },
      });
    }
  }

  function deleteNoteFromServer() {
    deleteNote.setFalse();
    const note = state.currentNote;
    if (state.editingMode === 'editing') {
      dispatch({ type: 'SetDirty', payload: false });
      dispatch({ type: 'GetNewNote', payload: null });
      deleteStudyNote({
        variables: {
          studyNoteId: note.id,
        },
      });
    }
  }

  const {
    createStudyNote: [createStudyNote],
    updateStudyNote: [updateStudyNote],
    deleteStudyNote: [deleteStudyNote],
    createHighlitedStudyNote: [createHighlitedStudyNote],
    updateHighlightedStudyNote: [updateHighlightedStudyNote],
  } = useStudyNoteMutations();

  function changeNote(id: string | null) {
    if (state.dirty) {
      saveConfirmation.setTrue();
      if (state.requestedNote !== id) {
        dispatch({ type: 'RequestNote', payload: id });
      }
    } else {
      const studyNote = studyNotes.find(studyNote => studyNote.id === id);
      if (studyNote != null) {
        dispatch({
          type: 'SetNote',
          payload: studyNote,
        });
      } else {
        dispatch({ type: 'GetNewNote', payload: null });
      }
    }
  }

  return (
    <>
      <ModalHeader>
        <ModalTitle>Study Notes</ModalTitle>

        <ActionWrapper>
          {loadModal && (
            <>
              <ActionButtonWrapper>
                <Button
                  onClick={() => {
                    changeNote(null);
                  }}
                  label="Create new note"
                  size="regular"
                >
                  <CreateNoteIcon size={20} />
                  <Separator size={2} />
                  Create new note
                </Button>
              </ActionButtonWrapper>
              <ActionButtonWrapper>
                {state.editingMode === 'creating' ? (
                  <Button
                    type="primary"
                    onClick={showSaveDialog}
                    size="large"
                    isDisabled={!state.dirty}
                  >
                    Save note
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={showUpdateDialog}
                    size="large"
                    isDisabled={!state.dirty}
                  >
                    Update note
                  </Button>
                )}
              </ActionButtonWrapper>
            </>
          )}

          <CloseButtonWrapper>
            <Button
              onClick={closeOrShowConfirmation}
              label="Close notes modal"
              color="cloudBurst"
              padding={0}
            >
              <CrossThickIcon size={20} />
            </Button>
          </CloseButtonWrapper>
        </ActionWrapper>
      </ModalHeader>
      {error != null && (
        <ErrorContainter>
          <Retry retry={retry} />
        </ErrorContainter>
      )}
      {isLoading && <MinorSpinner />}
      {loadModal && (
        <ModalBody verticalPadding="none" horizontalPadding="none">
          <ModalContentLayouContainer>
            <Aside isCollapsed={isAsideCollapsed.value}>
              <AsideToggler
                isSidebarCollapsed={isAsideCollapsed.value}
                onClick={isAsideCollapsed.toggle}
                onKeyDown={keyDownMap({
                  ENTER: [isAsideCollapsed.toggle, { preventDefault: true }],
                  SPACE: [isAsideCollapsed.toggle, { preventDefault: true }],
                })}
              >
                <NotesCounter>
                  {isAsideCollapsed.value ? (
                    studyNotes.length
                  ) : (
                    <CrossThickIcon />
                  )}
                </NotesCounter>
              </AsideToggler>

              <NotesList>
                {studyNotes.map(studyNote => {
                  const selectNote = () => {
                    changeNote(studyNote.id);
                  };
                  return (
                    <NoteEntry
                      tabIndex={0}
                      key={studyNote.id}
                      selected={state.currentNote.id === studyNote.id}
                      onClick={selectNote}
                      accessibilityMode={hasEnabledAccessibilityMode}
                      onKeyDown={keyDownMap({
                        ENTER: [selectNote, { preventDefault: true }],
                        SPACE: [selectNote, { preventDefault: true }],
                      })}
                    >
                      {studyNote.jsxContentType == null ? (
                        <StudyNotesIcon size={14} />
                      ) : (
                        <HighlighterIcon size={14} />
                      )}
                      <Separator size={1} />
                      <NoteEntryTitle>{studyNote.title}</NoteEntryTitle>
                      <NoteEntryUpdatedDate>
                        {moment(studyNote.updatedAt).format('DD MMM, ha')}
                      </NoteEntryUpdatedDate>
                    </NoteEntry>
                  );
                })}
              </NotesList>
            </Aside>

            <SaveConfirmation
              isOpen={saveConfirmation.value}
              noteTitle={state.currentNote.title}
              onSave={
                state.editingMode === 'creating'
                  ? showSaveDialog
                  : showUpdateDialog
              }
              onCancel={cancelSaveConfirmation}
              onClose={saveConfirmation.setFalse}
            />
            <ModalContentScrollingContainer>
              <NoteEditor>
                <NoteTitle>{state.currentNote.title}</NoteTitle>
                <NoteCreationDate>
                  {moment(state.currentNote.createdAt).format('LL')}
                </NoteCreationDate>
                {state.editingMode === 'editing' && (
                  <Menu>
                    <KebabDropdown
                      horizontal
                      items={[
                        {
                          key: 'delete-study-note',
                          label: 'Delete note',
                          action: deleteNote.setTrue,
                        },
                      ]}
                    />
                  </Menu>
                )}
              </NoteEditor>

              {showMathProse.value && (
                <MathProse
                  key={`Editing-${state.currentNote.id}`}
                  minLinesVisible={5}
                  maxLinesVisible={10}
                  initialState={state.currentNote.note}
                  initializationDelay={0}
                  // editorState is a ProseMirror state object, it's got a complex
                  // type that isn't easily exposed from our custom .d.ts file
                  // so we'll just type is as any for now.
                  // TODO remove this once JsxContent is implemented in Typescript
                  // and this type is being exposed properly in the exported types
                  onStateChange={(editorState: any) => {
                    const value = editorState.toJSON();
                    if (
                      JSON.stringify(value.doc, jsonKeySorter) !==
                      JSON.stringify(state.currentNote.note.doc, jsonKeySorter)
                    ) {
                      dispatch({ type: 'SetDirty', payload: true });
                    }
                    dispatch({ type: 'SetContents', payload: value });
                  }}
                />
              )}

              <Separator size={12} />

              {state.editingMode === 'creating' &&
                state.editorMode === 'highlightNote' &&
                subtopicId != null &&
                jsxContentType != null &&
                locale != null && (
                  <HighlightingNoteEditor
                    subtopicId={subtopicId}
                    jsxContentType={jsxContentType}
                    locale={locale}
                    state={highlightNoteState}
                    onChange={newState => {
                      setHighlightingNoteState(newState);
                      dispatch({ type: 'SetDirty', payload: true });
                    }}
                  />
                )}
              {state.editingMode === 'editing' &&
                state.editorMode === 'highlightNote' &&
                state.currentNote.subtopic != null &&
                state.currentNote.jsxContentType != null &&
                state.currentNote.jsxContentLocale != null && (
                  <HighlightingNoteEditor
                    subtopicId={state.currentNote.subtopic.id}
                    jsxContentType={state.currentNote.jsxContentType}
                    locale={state.currentNote.jsxContentLocale}
                    jsxContent={state.currentNote.jsxContent}
                    state={highlightNoteState}
                    onChange={newState => {
                      setHighlightingNoteState(newState);
                      dispatch({ type: 'SetDirty', payload: true });
                    }}
                  />
                )}
            </ModalContentScrollingContainer>
            <SaveNote
              key={`Saving-${state.currentNote.id}`}
              noteTitle={state.currentNote.title}
              isOpen={saveNote.value}
              onSave={saveNoteToServer}
              onCancel={saveNote.setFalse}
            />

            <UpdateNote
              key={`Updating-${state.currentNote.id}`}
              noteTitle={state.currentNote.title}
              isOpen={updateNote.value}
              onSave={updateNoteToServer}
              onCancel={updateNote.setFalse}
            />

            <DeleteNote
              key={`Deleting-${state.currentNote.id}`}
              noteTitle={state.currentNote.title}
              isOpen={deleteNote.value}
              onDelete={deleteNoteFromServer}
              onCancel={deleteNote.setFalse}
            />
          </ModalContentLayouContainer>
        </ModalBody>
      )}
    </>
  );
}

const useStudyNoteMutations = () => ({
  createStudyNote: useMutation<StudyNotesModalCreateStudyNoteMutation>(graphql`
    mutation StudyNotesModalCreateStudyNoteMutation(
      $title: String!
      $note: String!
    ) {
      createStudyNote(title: $title, note: $note) {
        errors {
          key
          message
        }
        student {
          id
          studyNotes {
            ...StudyNotesModalQuery_studyNote @relay(mask: false)
          }
        }
      }
    }
  `),
  createHighlitedStudyNote:
    useMutation<StudyNotesModalCreateHighlightedStudyNoteMutation>(graphql`
      mutation StudyNotesModalCreateHighlightedStudyNoteMutation(
        $title: String!
        $note: String!
        $subtopicId: ID!
        $documentType: JsxContentType!
        $highlightedData: String!
        $locale: SyllabusLocale
      ) {
        createHighlightedStudyNote(
          title: $title
          note: $note
          documentType: $documentType
          subtopicId: $subtopicId
          highlightedData: $highlightedData
          locale: $locale
        ) {
          errors {
            key
            message
          }
          student {
            id
            studyNotes {
              ...StudyNotesModalQuery_studyNote @relay(mask: false)
            }
          }
        }
      }
    `),
  updateHighlightedStudyNote:
    useMutation<StudyNotesModalUpdateHighlightedStudyNoteMutation>(graphql`
      mutation StudyNotesModalUpdateHighlightedStudyNoteMutation(
        $studyNoteId: ID!
        $title: String!
        $note: String!
        $highlightedData: String!
      ) {
        updateHighlightedStudyNote(
          studyNoteId: $studyNoteId
          title: $title
          highlightedData: $highlightedData
          note: $note
        ) {
          errors {
            key
            message
          }
          student {
            id
            studyNotes {
              ...StudyNotesModalQuery_studyNote @relay(mask: false)
            }
          }
        }
      }
    `),
  updateStudyNote: useMutation<StudyNotesModalUpdateStudyNoteMutation>(graphql`
    mutation StudyNotesModalUpdateStudyNoteMutation(
      $studyNoteId: ID!
      $title: String!
      $note: String!
    ) {
      updateStudyNote(studyNoteId: $studyNoteId, title: $title, note: $note) {
        errors {
          key
          message
        }
        student {
          id
          studyNotes {
            ...StudyNotesModalQuery_studyNote @relay(mask: false)
          }
        }
      }
    }
  `),
  deleteStudyNote: useMutation<StudyNotesModalDeleteStudyNoteMutation>(graphql`
    mutation StudyNotesModalDeleteStudyNoteMutation($studyNoteId: ID!) {
      deleteStudyNote(studyNoteId: $studyNoteId) {
        errors {
          key
          message
        }
        student {
          id
          studyNotes {
            ...StudyNotesModalQuery_studyNote @relay(mask: false)
          }
        }
      }
    }
  `),
});
