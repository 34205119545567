import * as React from 'react';

import useCreateTaskTemplateMutation from 'ms-pages/Teacher/components/CreateTaskTemplate/state/createTaskTemplate';

type State = {
  title: string;
  description: string;
  taskTemplateGroupIds: string[];
};

type InitialTaskTemplateGroupId = string | undefined;

const getInitialState = (
  initialTaskTemplatedGroupId?: InitialTaskTemplateGroupId,
  initialTaskTemplateTitle?: string | null | undefined,
  initialTaskTemplateDescription?: string | null | undefined,
): State => ({
  title: initialTaskTemplateTitle || '',
  description: initialTaskTemplateDescription || '',
  taskTemplateGroupIds:
    initialTaskTemplatedGroupId != null ? [initialTaskTemplatedGroupId] : [],
});

type Updaters = {
  setTaskTemplateTitle: (title: string) => void;
  setTaskTemplateDescription: (description: string) => void;
  setTaskTemplateGroupIds: (ids: string[]) => void;
  reset: () => void;
};

export type CombinedState = {
  values: State;
  updaters: Updaters;
};

export type ExternalVariables = {
  schoolId: string;
  problemContentIds: Array<string>;
  initialTaskTemplatedGroupId?: InitialTaskTemplateGroupId;
  initialTaskTemplateTitle?: string | null | undefined;
  initialTaskTemplateDescription?: string | null | undefined;
};

type MutationHook = ReturnType<typeof useCreateTaskTemplateMutation>;

export function useCreateTaskTemplateState(
  variables: ExternalVariables,
): [CombinedState, MutationHook] {
  const {
    initialTaskTemplatedGroupId,
    initialTaskTemplateTitle,
    initialTaskTemplateDescription,
  } = variables;
  const [state, setState] = React.useState(
    getInitialState(
      initialTaskTemplatedGroupId,
      initialTaskTemplateTitle,
      initialTaskTemplateDescription,
    ),
  );

  const combinedState: CombinedState = React.useMemo(
    () => ({
      values: state,
      updaters: {
        setTaskTemplateTitle: title => setState({ ...state, title }),
        setTaskTemplateDescription: description =>
          setState({ ...state, description }),
        setTaskTemplateGroupIds: ids =>
          setState({ ...state, taskTemplateGroupIds: ids }),
        reset: () => setState(getInitialState()),
      },
    }),
    [state],
  );

  const mutationVariables = React.useMemo(
    () => ({
      ...variables,
      ...state,
    }),
    [state, variables],
  );

  return [combinedState, useCreateTaskTemplateMutation(mutationVariables)];
}
