import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import AdminReportsLogo from 'ms-components/icons/AdminReports';
import Lock from 'ms-components/icons/Lock';
import MenuSquaresIcon from 'ms-components/icons/MenuSquares';
import LanternLogo from 'ms-components/logos/LanternLogo';
import MathspaceLogo from 'ms-components/logos/Logo';
import {
  borderRadiusUILarge,
  fontSize,
  fontWeight,
  inputHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Separator from 'ms-ui-primitives/Separator';
import { tappable } from 'ms-utils/emotion';
import {
  lanternAppUrl,
  dashboardRedirectUrl,
  schoolAdminReportBasePath,
} from 'ms-utils/urls';

const LAUNCHER_ICON_SIZE = 24;
const TAP_AREA = inputHeight;

const LauncherButton = styled.button({
  height: TAP_AREA,
  width: TAP_AREA,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  margin: -(TAP_AREA - LAUNCHER_ICON_SIZE) / 2,
  // reset <button> styles
  padding: 0,
  backgroundColor: 'transparent',
  border: 0,
  color: colors.shuttleGray,
  ...tappable,
});

function AppLauncherButton({ onClick }: { onClick: () => void }) {
  return (
    <LauncherButton onClick={onClick}>
      <MenuSquaresIcon size={LAUNCHER_ICON_SIZE} />
    </LauncherButton>
  );
}

const Apps = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

const Title = styled.div({
  textAlign: 'center',
  fontSize: fontSize.xLarge,
  color: colors.cloudBurst,
  fontWeight: fontWeight.semibold,
});

const LOGO_SIZE = 52;

const appImageStyle = {
  height: 100,
  width: 100,
  borderRadius: borderRadiusUILarge,
  backgroundColor: colors.almond50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const AppLogoDisabled = styled.div({
  position: 'relative',
});

const AppLogoLock = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  transform: 'translate(-50% ,-50%)',
  width: 32,
  height: 32,
  backgroundColor: colors.almond50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
});

const AppImage = styled.div<{ isSelected?: boolean }>(({ isSelected }) => ({
  ...appImageStyle,
  ...(isSelected ? { backgroundColor: colors.cloudBurst } : {}),
}));

const AppLink = styled.a({
  ...appImageStyle,
  ...tappable,
});

const AppTitle = styled.div({
  textAlign: 'center',
  color: colors.cloudBurst,
  fontWeight: fontWeight.semibold,
});

function AppLogo({
  isSelected,
  title,
  url,
  children,
  disabled = false,
}: {
  isSelected: boolean;
  title: string;
  url: string;
  children: ReactNode;
  disabled?: boolean;
}) {
  if (disabled) {
    return (
      <AppLogoDisabled>
        <AppLogoLock>
          <Lock color={colors.cloudBurst} size={24} />
        </AppLogoLock>
        <AppImage>{children}</AppImage>
        <Separator size={6} />
        <AppTitle>{title}</AppTitle>
      </AppLogoDisabled>
    );
  }
  return (
    <div>
      {isSelected ? (
        <AppImage isSelected>{children}</AppImage>
      ) : (
        <AppLink href={url} target="_blank">
          {children}
        </AppLink>
      )}
      <Separator size={6} />
      <AppTitle>{title}</AppTitle>
    </div>
  );
}

const Root = styled.div({
  paddingTop: 8 * BASE_UNIT,
  paddingBottom: 12 * BASE_UNIT,
});

function AppLauncher({
  app,
  hasAdminReport,
  hasMathspaceApp,
}: {
  app?: 'mathspace' | 'lantern' | 'district_report';
  hasAdminReport: boolean;
  hasMathspaceApp: boolean;
}) {
  return (
    <Root>
      <Title>Mathspace apps</Title>
      <Separator size={11} />
      <Apps>
        <AppLogo
          isSelected={app === 'mathspace'}
          url={dashboardRedirectUrl}
          title="Mathspace"
          disabled={!hasMathspaceApp}
        >
          <MathspaceLogo width={LOGO_SIZE} />
        </AppLogo>
        <Separator size={11} />
        <AppLogo
          isSelected={app === 'lantern'}
          url={lanternAppUrl}
          title="Waypoints"
        >
          <LanternLogo width={LOGO_SIZE} />
        </AppLogo>
        {hasAdminReport && (
          <>
            <Separator size={11} />
            <AppLogo
              isSelected={app === 'district_report'}
              url={schoolAdminReportBasePath}
              title="District Report"
            >
              <AdminReportsLogo
                color={colors.pickledBluewood}
                size={LOGO_SIZE}
              />
            </AppLogo>
          </>
        )}
      </Apps>
    </Root>
  );
}

export { AppLauncherButton, AppLauncher };
