import styled from '@emotion/styled';
import { StyleSheet } from 'aphrodite';
import { useCallback, useRef, useState, useLayoutEffect } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import { TriangleDown } from 'ms-components/icons';
import CrossIcon from 'ms-components/icons/Cross';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { BodyM, HeadingXSGilroy } from 'ms-pages/Lantern/primitives/Typography';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { TextbookRedirector } from 'ms-pages/Textbooks/components/TextbookRedirector';
import type { Book } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import { useTextbookTypeTabsContext } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT, boxShadow } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Highlighter from 'ms-ui-primitives/Highlighter';
import Input from 'ms-ui-primitives/Input';
import { VStack, HStack, HSpacer } from 'ms-ui-primitives/Stack';
import { styledVerticallyScrollable } from 'ms-utils/emotion';
import { useRecentlyUsedTextbooksForUser } from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { TextbookSelectorButtonQuery } from './__generated__/TextbookSelectorButtonQuery.graphql';

type Props = {
  syllabusId: string;
  onSelectSyllabus: (textbookId: string) => void;
  popoverBottomEdgeBuffer: number;
};
type AnnotatedBook = Book & {
  isSelected: boolean;
  isRecentlyUsed: boolean;
};
const HEADER_BORDER_WIDTH = 1;
const TextbookHeader = styled('div', {
  shouldForwardProp: prop => prop !== 'asButton',
})<{
  asButton: boolean;
}>(({ asButton }) => ({
  border: `${HEADER_BORDER_WIDTH}px solid ${colors.iron}`,
  borderRadius: 4,
  padding: 8,
  cursor: asButton ? 'pointer' : 'auto',
  position: 'relative',
  isolation: 'isolate',
  zIndex: 1,
}));
function UsageLabel({ children }: { children: string }) {
  return (
    <span
      style={{
        color: colors.eggplant,
        fontSize: fontSize.small,
        fontWeight: fontWeight.semibold,
        textTransform: 'uppercase',
      }}
    >
      {children}
    </span>
  );
}
const HORIZONTAL_PADDING = 12;
const TextbookItemWrapper = styled(HStack)({
  padding: `9px ${HORIZONTAL_PADDING}px`,
  ':hover': {
    backgroundColor: colors.seashell,
  },
});
function TextbookItem({
  textbook,
  onSelectTextbook,
  highlightedTokens,
}: {
  textbook: AnnotatedBook;
  onSelectTextbook: (textbookId: string) => void;
  highlightedTokens: string[];
}) {
  return (
    <TextbookItemWrapper
      center
      onClick={() => {
        onSelectTextbook(textbook.id);
      }}
    >
      <BodyM>
        <Highlighter highlights={highlightedTokens}>
          {textbook.title}
        </Highlighter>{' '}
        {textbook.isSelected && <UsageLabel>selected</UsageLabel>}
        {textbook.isRecentlyUsed && !textbook.isSelected && (
          <UsageLabel>recent</UsageLabel>
        )}
      </BodyM>
    </TextbookItemWrapper>
  );
}
const AvailableTextbooksPopOver = styled('div', {
  shouldForwardProp: p =>
    !['topEdge', 'bottomEdge', 'bottomEdgeBuffer'].some(e => e === p),
})<{
  topEdge: number;
  bottomEdgeBuffer: number;
}>(({ topEdge, bottomEdgeBuffer }) => ({
  position: 'absolute',
  // The border messes with aligning the popover nicely.
  // This is the most elegant solution I can think of with the
  // time we have to spend.
  width: `calc(100% + ${2 * HEADER_BORDER_WIDTH}px)`,
  top: `calc(100% + ${HEADER_BORDER_WIDTH + 8}px)`,
  left: -HEADER_BORDER_WIDTH,
  border: `1px solid ${colors.ironLight}`,
  borderRadius: 4,
  backgroundColor: colors.white,
  maxHeight: `calc(100vh - ${topEdge + 4 * BASE_UNIT + bottomEdgeBuffer}px)`,
  boxShadow: boxShadow.tiny,
  ...styledVerticallyScrollable,
}));
const SearchInputWrapper = styled('div')({
  padding: HORIZONTAL_PADDING, // it's fine as overall padding
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: colors.white,
  borderBottom: `1px solid ${colors.ironLight}`,
});
const CLEAR_BUTTON_WIDTH = 32;
// to leave space for the clear button
const styles = StyleSheet.create({
  paddingRight: {
    paddingRight: CLEAR_BUTTON_WIDTH,
  },
});
const ClearButtonWrapper = styled('div')({
  position: 'absolute',
  right: HORIZONTAL_PADDING,
  top: 0,
  bottom: 0,
  height: '100%',
  width: CLEAR_BUTTON_WIDTH,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
const PopoverBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0,0,0,0.05)',
  cursor: 'default',
});
// icon button to clear search input
const CROSS_ICON_SIZE = 16;
// Return max 20 results if search is active
// as with the current methods many or all items can pass the search
const MAX_SEARCH_RESULTS = 20;

function AvailableBooks({
  currentlySelectedTextbookId,
  isOpen,
  onDismiss,
  books,
  onSelectTextbook,
  topEdge,
  popoverBottomEdgeBuffer,
}: {
  currentlySelectedTextbookId: string;
  isOpen: boolean;
  onDismiss: () => void;
  books: readonly Book[];
  onSelectTextbook: (textbookId: string) => void;
  topEdge: number;
  popoverBottomEdgeBuffer: number;
}) {
  const { userId } = useViewer();
  const recentlyUsedTextbooks = useRecentlyUsedTextbooksForUser(userId);
  const [searchString, setSearchString] = useState<string>('');
  const isSearchEmpty = searchString.trim() === '';
  const sortedAndFilteredTextbooks: AnnotatedBook[] =
    // toSorted is supported in node v20+
    [
      ...books.map(book => ({
        ...book,
        isSelected: book.id === currentlySelectedTextbookId,
        isRecentlyUsed: recentlyUsedTextbooks.includes(book.id),
      })),
    ]
      .filter(book => {
        if (isSearchEmpty) return true;
        return searchBook(book.title, searchString) > 0;
      })
      .sort((a, b) => {
        // always return the selected book first
        const numberOfMatchesDifference =
          searchBook(b.title, searchString) - searchBook(a.title, searchString);
        if (!isSearchEmpty && numberOfMatchesDifference !== 0) {
          return numberOfMatchesDifference;
        }
        if (a.isSelected) return -1;
        if (a.isRecentlyUsed === b.isRecentlyUsed) return 0;
        return a.isRecentlyUsed ? -1 : 0;
      })
      .slice(0, !isSearchEmpty ? MAX_SEARCH_RESULTS : Infinity);
  if (!isOpen) return null;
  return (
    <>
      <PopoverBackdrop onClick={onDismiss} />
      <AvailableTextbooksPopOver
        topEdge={topEdge}
        bottomEdgeBuffer={popoverBottomEdgeBuffer}
      >
        <SearchInputWrapper>
          <Input
            autoFocus
            value={searchString}
            placeholder="Search for a textbook"
            onChange={e => {
              setSearchString(e.target.value);
            }}
            aphroditeStyles={[styles.paddingRight]}
          />
          {searchString.trim().length > 0 && (
            <ClearButtonWrapper>
              <Button
                isInline
                isCircle
                styles={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                label="Clear"
                onClick={() => {
                  setSearchString('');
                }}
              >
                <CrossIcon size={CROSS_ICON_SIZE} />
              </Button>
            </ClearButtonWrapper>
          )}
        </SearchInputWrapper>
        {sortedAndFilteredTextbooks.map(textbook => (
          <TextbookItem
            key={textbook.id}
            textbook={textbook}
            onSelectTextbook={textbookId => {
              onSelectTextbook(textbookId);
              onDismiss();
            }}
            highlightedTokens={searchString.trim().split(' ')}
          />
        ))}

        {sortedAndFilteredTextbooks.length === 0 &&
          searchString.trim() !== '' && (
            <div
              style={{
                padding: HORIZONTAL_PADDING,
                textAlign: 'center',
              }}
            >
              <BodyM>No textbooks found</BodyM>

              <Button
                onClick={() => {
                  setSearchString('');
                }}
              >
                Clear search
              </Button>
            </div>
          )}
      </AvailableTextbooksPopOver>
    </>
  );
}
export default function TextbookSelectorButton({
  syllabusId,
  onSelectSyllabus,
  popoverBottomEdgeBuffer,
}: Props) {
  const { trackStructEvent } = useSnowplow();
  const { schoolId } = useMaybeTeacherContext();
  const { selectedTab, availableTextbooks, availableSkillsBooks } =
    useTextbookTypeTabsContext();
  const [textbookSelectorOpen, setTextbookSelectorOpen] =
    useState<boolean>(false);
  const { props, error, retry } = useQuery<TextbookSelectorButtonQuery>(
    graphql`
      query TextbookSelectorButtonQuery($syllabusId: ID!) {
        syllabus(id: $syllabusId) {
          title
          thumbnailImageUrl
        }
      }
    `,
    {
      syllabusId,
    },
  );
  const availableBooks = (() => {
    switch (selectedTab) {
      case 'textbook':
        return availableTextbooks;
      case 'skillsbook':
        return availableSkillsBooks.length > 0
          ? availableSkillsBooks
          : availableTextbooks;
      case 'search':
        return availableTextbooks;
      default:
        assertUnreachable(selectedTab);
    }
  })();
  const isLoading = props == null;
  const syllabus = props?.syllabus;
  const notFound = !isLoading && syllabus == null;
  const hasMultipleBooks = availableBooks.length > 1;
  const action = useCallback(() => {
    setTextbookSelectorOpen(prev => {
      if (!prev) {
        trackStructEvent({
          category: 'textbook',
          action: 'clicked_on_switch_textbook',
          label: schoolId,
        });
      }
      if (!hasMultipleBooks) return false;
      return !prev;
    });
  }, [hasMultipleBooks, schoolId, trackStructEvent]);
  const bottomRef = useRef<HTMLDivElement>(null);
  const [availableTextbooksTopEdge, setAvailableTextbookTopEdge] =
    useState<number>(0);
  useLayoutEffect(() => {
    if (bottomRef.current == null) return;
    const { bottom } = bottomRef.current.getBoundingClientRect();
    setAvailableTextbookTopEdge(bottom);
  }, [syllabus]);
  return (
    <>
      {notFound && <TextbookRedirector />}

      <VStack>
        {
          <TextbookHeader asButton={hasMultipleBooks} ref={bottomRef}>
            <HStack center onClick={action}>
              {syllabus != null && (
                <>
                  <img
                    src={syllabus.thumbnailImageUrl}
                    height={64}
                    style={{
                      borderTopLeftRadius: 2,
                      borderBottomLeftRadius: 2,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                    alt="texbook cover"
                  />
                  <HSpacer width={8} />
                </>
              )}
              <HeadingXSGilroy>
                {syllabus != null
                  ? syllabus.title
                  : 'Please select another textbook'}
              </HeadingXSGilroy>
              <HSpacer width={4} grow />
              {hasMultipleBooks && <TriangleDown />}
            </HStack>

            <AvailableBooks
              currentlySelectedTextbookId={syllabusId}
              isOpen={textbookSelectorOpen}
              onDismiss={() => {
                setTextbookSelectorOpen(false);
              }}
              books={availableBooks}
              onSelectTextbook={onSelectSyllabus}
              topEdge={availableTextbooksTopEdge}
              popoverBottomEdgeBuffer={popoverBottomEdgeBuffer}
            />
          </TextbookHeader>
        }

        {isLoading && <MinorSpinner scale={0.5} noPadding />}
        {error != null && <Retry retry={retry} />}
      </VStack>
    </>
  );
}
// SEARCH BOOK LOGIC
// Returns the number of matches
// map(x => number).reduce(sum) is less performant than
// some() but it allows to use it for sorting
// to improve performance we use memoization
const searchBook = (() => {
  const cache = new Map<string, number>();
  return (bookTitle: string, searchString: string): number => {
    const key = `${bookTitle}-${searchString}`;
    if (cache.has(key)) {
      return cache.get(key)!;
    }
    const result = _searchBook(bookTitle, searchString);
    cache.set(key, result);
    return result;
  };
})();
function _searchBook(bookTitle: string, searchString: string): number {
  return bookTitle
    .trim()
    .split(' ')
    .map(token =>
      searchString
        .trim()
        .split(' ')
        .map(searchToken =>
          token.toLowerCase().includes(searchToken.toLowerCase()) ? 1 : 0,
        )
        .reduce(sum, 0),
    )
    .reduce(sum, 0);
}
function sum(a: number, b: number): number {
  return a + b;
}
