import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

const PrintModeContext = createContext<boolean>(false);

// Deprecated. You should be using `usePrintMode` instead
export const PrintModeConsumer = PrintModeContext.Consumer;

export function usePrintMode() {
  return useContext(PrintModeContext);
}

export function PrintModeProvider({ children }: { children: ReactNode }) {
  const [inPrintMode, setInPrintMode] = useState(false);

  // The 'print' media query should match when the browser generates
  // its print preview. Anecdotally, it seems to only momentarily flick
  // on. Once you're sitting in the print dialog it seems to have reverted
  // to the standard value.
  useEffect(() => {
    const mql = window.matchMedia('print');
    const onMediaQueryChange = () => setInPrintMode(mql.matches);
    mql.addEventListener('change', onMediaQueryChange);
    return () => {
      mql.removeEventListener('change', onMediaQueryChange);
    };
  }, []);

  // beforeprint/afterprint fire when opening/closing the print dialog
  // in the browser. It's unclear why we have these in addition to the
  // 'print' media query. Perhaps that media query is unreliable in
  // certain situations (or was so in the past)?.
  useEffect(() => {
    const onBeforePrint = () => setInPrintMode(true);
    const onAfterPrint = () => setInPrintMode(false);
    window.addEventListener('beforeprint', onBeforePrint);
    window.addEventListener('afterprint', onAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', onBeforePrint);
      window.removeEventListener('afterprint', onAfterPrint);
    };
  }, []);

  return (
    <PrintModeContext.Provider value={inPrintMode}>
      {children}
    </PrintModeContext.Provider>
  );
}
