import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import PageErrorMessageView from 'ms-components/PageErrorMessageView';
import Root from 'ms-components/Root';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import Center from 'ms-ui-primitives/Center';
import LoadingSpinner from 'ms-ui-primitives/LoadingSpinner';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import SettingsPresentational from './SettingsPresentational';
import type { SettingsContainerQuery } from './__generated__/SettingsContainerQuery.graphql';

// The actual fields that we want to be returned after queries and mutations.
// eslint-disable-next-line no-unused-expressions
graphql`
  fragment SettingsContainerFragment on User {
    pk
    accountType
    cohortType
    userType
    firstName
    lastName
    username
    email
    country {
      code
      name
    }
    timezone
    student {
      school {
        title
      }
      classes(first: $numClasses) {
        edges {
          node {
            id
            title
          }
        }
      }
    }
    profile {
      __typename
      ... on Student {
        id
        classes(first: $numClasses) {
          edges {
            node {
              id
              title
              displayName
              hasEnabledLeaderboard
            }
          }
        }
        leaderboardClass {
          class {
            id
          }
        }
        guardians {
          id
          firstName
          lastName
          email
          receiveNotifications
        }
      }
    }

    syllabusFocus {
      pk
      title
      curriculum {
        countrySubdivision
        country {
          code
          name
        }
      }
    }
    activeSubscription {
      ...SubscriptionDetails_subscription
    }
    addOnSubscriptions {
      ...AddOnSubscriptionDetails_subscription
    }
  }
`;
graphql`
  fragment SettingsContainerLanternStudent on LanternStudent {
    curriculum {
      grades {
        id
        shortTitle
      }
    }
    selfReportedGrade {
      id
      title
    }
  }
`;
const query = graphql`
  query SettingsContainerQuery(
    $numClasses: Int
    $numTimezones: Int
    $numSyllabuses: Int
  ) {
    user: viewer {
      ...SettingsContainerFragment @relay(mask: false)
      ...PaymentModal_user
      # Used by SyllabusSelector
      accountType
      cohortType
    }
    # Used by timezone selector.
    # TODO turn into a fragment container; use Relay correctly
    availableTimezones(limit: $numTimezones) {
      name
      displayName
    }
    # Used by SyllabusSelector
    # TODO turn into a fragment container; use Relay correctly
    availableSyllabuses(limit: $numSyllabuses) {
      pk
      title
      curriculum {
        countrySubdivision
        country {
          code
          name
        }
      }
    }
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          ...SettingsContainerLanternStudent @relay(mask: false)
        }
      }
    }
  }
`;

type Props = {
  onChangePasswordClick: () => void;
};
export default function SettingsContainer({ onChangePasswordClick }: Props) {
  const { props, error } = useQuery<SettingsContainerQuery>(query, {
    numClasses: RELAY_CONNECTION_MAX,
    numTimezones: RELAY_CONNECTION_MAX,
    numSyllabuses: RELAY_CONNECTION_MAX,
  });

  if (error)
    return (
      <PageTimeErrorThrower
        pageName="Settings"
        componentName="SettingsContainer"
        error={error}
      />
    );
  if (props == null)
    return (
      <Root>
        <Center>
          <LoadingSpinner />
        </Center>
      </Root>
    );
  const { user, availableTimezones, availableSyllabuses, lantern } = props;
  if (user == null)
    return (
      <PageErrorMessageView>
        You must be logged in to access this page
      </PageErrorMessageView>
    );
  const grades =
    lantern.viewer?.__typename === 'LanternStudent'
      ? lantern.viewer?.curriculum.grades
      : null;
  const selfReportedGrade =
    lantern.viewer?.__typename === 'LanternStudent'
      ? lantern.viewer?.selfReportedGrade
      : null;
  return (
    <PageTimeRecorder pageName="Settings" componentName="SettingsContainer">
      <SettingsPresentational
        user={user}
        availableTimezones={availableTimezones}
        availableSyllabuses={availableSyllabuses}
        onChangePasswordClick={onChangePasswordClick}
        // Lantern-specific fields
        grades={grades}
        selfReportedGrade={selfReportedGrade}
      />
    </PageTimeRecorder>
  );
}
