import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Archive = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path d="M15.191 13.625V5.19c-.06.004-.118.008-.178.008H.987c-.059 0-.12-.004-.177-.008v8.435c0 .544.442.987.986.987h12.41c.544 0 .986-.441.986-.987ZM11.295 8.44c0 .273-.22.493-.494.493H5.198a.493.493 0 0 1-.493-.493V7.13c0-.273.22-.493.493-.493H10.8c.273 0 .493.22.493.493V8.44h.002ZM16 3.225v-.85a.987.987 0 0 0-.986-.987H.986A.987.987 0 0 0 0 2.375v.85a.987.987 0 0 0 .986.986h14.026A.987.987 0 0 0 16 3.225Z" />
  </Icon>
);
Archive.displayName = 'Archive';

export default Archive;
