import { Redirect } from 'react-router-dom';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { setRecentlyUsedTextbooksForUser } from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import { getContentBrowsingEmptyViewUrl } from 'ms-utils/urls';

type RedirectorProps = { textbookId: string };
function RedirectToTextbook({ textbookId }: RedirectorProps) {
  return (
    <Redirect to={getContentBrowsingEmptyViewUrl({ syllabusId: textbookId })} />
  );
}
export default function RedirectToFocusTextbookForStudent() {
  const { userId, syllabusFocusId } = useViewer();
  setRecentlyUsedTextbooksForUser(userId, syllabusFocusId);
  return <RedirectToTextbook textbookId={syllabusFocusId} />;
}
