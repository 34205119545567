import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path d="M4.32873 20.1429V35.8095H10.8024V20.1429H4.32873ZM17.2761 20.1429V35.8095H23.7498V20.1429H17.2761ZM30.2235 20.1429V35.8095H36.6972V20.1429H30.2235ZM20.5129 0L0.0129395 11.1905V15.6667H41.0129V11.1905L20.5129 0Z" />
    <path d="M41.0129 47H0.0129395V40.2857H41.0129V47Z" />
  </>
);

const AdminReports = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={42}
    viewBoxHeight={47}
  >
    {path}
  </Icon>
);

AdminReports.displayName = 'AdminReports';

export default AdminReports;
