import { useCallback, useRef, useState } from 'react';
import { graphql, useMutation } from 'relay-hooks';

import FeedbackModal from 'ms-components/FeedbackModal';
import JsxContent from 'ms-components/JsxContent';
import AccessibilityModeLabel from 'ms-components/accessibility/AccessibilityModeLabel';
import EllipsisHorizontalIcon from 'ms-components/icons/EllipsisHorizontal';
import { useSnowplow } from 'ms-helpers/Snowplow';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import useTrackActiveTimeSpent from 'ms-pages/Textbooks/StudentTextbook/components/useTrackActiveTimeSpent';
import type { Quartile } from 'ms-pages/Textbooks/StudentTextbook/components/useTrackScrollDepthQuartiles';
import useTrackScrollDepthQuartiles from 'ms-pages/Textbooks/StudentTextbook/components/useTrackScrollDepthQuartiles';
import AnswerKeyModal from 'ms-pages/Textbooks/components/AnswerKeyModal';
import type { jsxPrintKebabLinks_subtopic$key } from 'ms-pages/Textbooks/components/JSXPrintButton/__generated__/jsxPrintKebabLinks_subtopic.graphql';
import { useJsxPrintKebabLinks } from 'ms-pages/Textbooks/components/JSXPrintButton/jsxPrintKebabLinks';
import {
  ContentHeading,
  ContentHeadingRight,
} from 'ms-pages/Textbooks/components/elements';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import DropdownMenu from 'ms-ui-primitives/DropdownMenu';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import { styled } from 'ms-utils/emotion';

import type {
  StudentWorksheetsUpdateWorksheetWorkoutProgressMutation,
  ProgressQuartile,
} from './__generated__/StudentWorksheetsUpdateWorksheetWorkoutProgressMutation.graphql';
import type { StudentWorksheetsUpdateWorksheetWorkoutTimeSpentMutation } from './__generated__/StudentWorksheetsUpdateWorksheetWorkoutTimeSpentMutation.graphql';

const quartileToProgressQuartileMap: Record<Quartile, ProgressQuartile> = {
  '0': 'PROGRESS_0',
  '25': 'PROGRESS_25',
  '50': 'PROGRESS_50',
  '75': 'PROGRESS_75',
  '100': 'PROGRESS_100',
};
const Header = styled(
  {
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    fontSize: fontSize.large,
    color: colors.cloudBurst,
    marginBottom: 4 * BASE_UNIT,
  },
  'h1',
);
const SubHeader = styled({
  display: 'flex',
  alignItems: 'center',
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.normal,
  fontSize: fontSize.medium,
  color: colors.shuttleGray,
  marginBottom: 8 * BASE_UNIT,
});
const DropdownWrapper = styled({
  position: 'relative',
});
const PING_INTERVAL = 30 * 1000; // Update time spent every 30 seconds.
const TIMEOUT_INTERVAL = 5 * 60 * 1000; // Timeout, after which we decide user is inactive on the tabl. 5 min in milliseconds.
const DROPDOWN_ICON_SIZE = 8 * BASE_UNIT;
type Props = {
  worksheet: string;
  subtopicId: string;
  scrollingElement: HTMLElement | null | undefined;
  worksheetWorkoutIdToTrack: string | null | undefined;
  answerKey: string | null | undefined;
  subtopic: jsxPrintKebabLinks_subtopic$key;
  hideContentHeading?: boolean | undefined;
};
export default function StudentWorksheets({
  worksheet,
  subtopicId,
  scrollingElement,
  worksheetWorkoutIdToTrack,
  subtopic,
  answerKey,
  hideContentHeading,
}: Props) {
  const [updateWorksheetWorkoutProgress] =
    useMutation<StudentWorksheetsUpdateWorksheetWorkoutProgressMutation>(
      graphql`
        mutation StudentWorksheetsUpdateWorksheetWorkoutProgressMutation(
          $progress: ProgressQuartile!
          $workoutId: ID!
        ) {
          updateWorksheetWorkoutProgress(
            progress: $progress
            workoutId: $workoutId
          ) {
            errors {
              key
            }
            workout {
              ... on WorksheetWorkout {
                progress
              }
            }
          }
        }
      `,
    );
  useTrackScrollDepthQuartiles(
    scrollingElement,
    useCallback(
      quartile => {
        if (worksheetWorkoutIdToTrack != null) {
          updateWorksheetWorkoutProgress({
            variables: {
              progress: quartileToProgressQuartileMap[quartile],
              workoutId: worksheetWorkoutIdToTrack,
            },
          });
        }
      },
      // Need to redefine this every time subtopicId changes
      // to ensure the correct tracking.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [subtopicId],
    ),
  );
  const [updateWorksheetWorkoutActiveTimeSpent] =
    useMutation<StudentWorksheetsUpdateWorksheetWorkoutTimeSpentMutation>(
      graphql`
        mutation StudentWorksheetsUpdateWorksheetWorkoutTimeSpentMutation(
          $workoutId: ID!
          $intervalRate: Int!
        ) {
          updateWorksheetWorkoutTimeSpent(
            workoutId: $workoutId
            intervalRate: $intervalRate
          ) {
            errors {
              key
              message
            }
          }
        }
      `,
    );
  useTrackActiveTimeSpent(
    scrollingElement,
    useCallback(
      () => {
        if (worksheetWorkoutIdToTrack != null) {
          updateWorksheetWorkoutActiveTimeSpent({
            variables: {
              intervalRate: PING_INTERVAL / 1000,
              workoutId: worksheetWorkoutIdToTrack,
            },
          });
        }
      },
      // Need to redefine this every time subtopicId changes
      // to ensure the correct tracking.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [subtopicId],
    ),
    PING_INTERVAL,
    TIMEOUT_INTERVAL,
  );
  const { trackStructEvent } = useSnowplow();
  useTrackScrollDepthQuartiles(
    scrollingElement,
    useCallback(
      (quartile: Quartile) => {
        if (quartile === '25') {
          trackStructEvent({
            category: 'student_textbook',
            action: 'scrolled_to_25_percent_depth_in_worksheet',
            property: 'subtopic',
            label: subtopicId,
          });
        }
        if (quartile === '50') {
          trackStructEvent({
            category: 'student_textbook',
            action: 'scrolled_to_50_percent_depth_in_worksheet',
            property: 'subtopic',
            label: subtopicId,
          });
        }
        if (quartile === '75') {
          trackStructEvent({
            category: 'student_textbook',
            action: 'scrolled_to_75_percent_depth_in_worksheet',
            property: 'subtopic',
            label: subtopicId,
          });
        }
        if (quartile === '100') {
          trackStructEvent({
            category: 'student_textbook',
            action: 'scrolled_to_100_percent_depth_in_worksheet',
            property: 'subtopic',
            label: subtopicId,
          });
        }
      },
      [subtopicId, trackStructEvent],
    ),
  );
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const [isDropdownMenuOpen, setDropdownMenuOpen] = useState(false);
  const dropdownToggle = useRef(null);
  const [isWorksheetFeedbackModalOpen, setWorksheetFeedbackModalOpen] =
    useState(false);
  const openWorksheetFeedbackModal = useCallback(() => {
    setWorksheetFeedbackModalOpen(true);
  }, []);
  const closeWorksheetFeedabackModal = useCallback(() => {
    setWorksheetFeedbackModalOpen(false);
  }, []);
  const [isAnswerKeyModalOpen, setAnswerKeyModalOpen] = useState(false);
  const jsxPrintLinks = useJsxPrintKebabLinks(subtopic);
  const [{ worksheetAnswerKeys }] = useFeatureFlags();
  return (
    <>
      {!hideContentHeading && (
        <ContentHeading>
          <div>
            <Header>Worksheet Practice</Header>
            <SubHeader>
              <AccessibilityModeLabel label="Accessible" />
            </SubHeader>
          </div>
          {!hasEnabledAccessibilityMode && (
            <ContentHeadingRight>
              <DropdownWrapper ref={dropdownToggle}>
                <Button
                  onClick={() => {
                    setDropdownMenuOpen(true);
                  }}
                  label="Menu"
                >
                  <EllipsisHorizontalIcon size={DROPDOWN_ICON_SIZE} />
                </Button>
                {isDropdownMenuOpen && (
                  <DropdownMenu
                    anchorRef={dropdownToggle}
                    menuPosition="bottom-left"
                    onDismiss={() => {
                      setDropdownMenuOpen(false);
                    }}
                    items={[
                      ...(answerKey != null && worksheetAnswerKeys
                        ? [
                            {
                              key: 'open-answer-key',
                              label: 'Launch answer key',
                              action: () => setAnswerKeyModalOpen(true),
                            },
                          ]
                        : []),
                      ...jsxPrintLinks,
                      {
                        key: 'submit-feedback',
                        label: 'Submit feedback',
                        action: openWorksheetFeedbackModal,
                      },
                    ]}
                  />
                )}
              </DropdownWrapper>
            </ContentHeadingRight>
          )}
        </ContentHeading>
      )}

      <JsxContent transpiledJsx={worksheet} />
      <FeedbackModal
        feedbackType="WORKSHEET_SUBMIT_FEEDBACK"
        feedbackData={{ subtopicId }}
        isOpen={isWorksheetFeedbackModalOpen}
        onClose={closeWorksheetFeedabackModal}
      />
      {answerKey != null && (
        <AnswerKeyModal
          answerKey={answerKey}
          isOpen={isAnswerKeyModalOpen}
          subtopicId={subtopicId}
          onClose={() => {
            setAnswerKeyModalOpen(false);
          }}
        />
      )}
    </>
  );
}
