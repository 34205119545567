import { css, StyleSheet } from 'aphrodite';

import { colors } from 'ms-styles/colors';

import { BOX_AND_WHISKER_STROKE_WIDTH } from '..';

type Props = {
  xCoord: number;
  yCoord: number;
  height: number;
};

const styles = StyleSheet.create({
  valueMark: {
    stroke: colors.matisse,
    strokeWidth: BOX_AND_WHISKER_STROKE_WIDTH,
    pointerEvents: 'none',
  },
});

/*
  The value tracker component is a perforated line that appears under a
  selected edge and trails down to the axis to show the value that the egde is
  at.
*/
const ValueTracker = ({ xCoord, yCoord, height }: Props) => (
  <g>
    <line
      className={css(styles.valueMark)}
      strokeDasharray="2,2"
      x1={xCoord}
      y1={yCoord}
      x2={xCoord}
      y2={yCoord + height}
    />
  </g>
);

export default ValueTracker;
