import { css, StyleSheet } from 'aphrodite';
import { range } from 'ramda';

import { colors } from 'ms-styles/colors';

const isHighDpiDevice =
  'matchMedia' in window // useless check cause Jest environment sucks
    ? window.matchMedia(
        '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx)',
      ).matches
    : false;

const getSvgData = (
  numX: number,
  numY: number,
  blockWidth: number,
  lineWidth: number,
) => {
  const width = numX * blockWidth + (numX - 1) * lineWidth;
  const height = numY * blockWidth + (numY - 1) * lineWidth;
  const verticalLines = range(1, numX).map(v => ({
    from: {
      x: v * blockWidth + (v - 1) * lineWidth + lineWidth / 2,
      y: 0,
    },
    to: {
      x: v * blockWidth + (v - 1) * lineWidth + lineWidth / 2,
      y: height,
    },
  }));
  const horizontalLines = range(1, numY).map(v => ({
    from: {
      x: 0,
      y: v * blockWidth + (v - 1) * lineWidth + lineWidth / 2,
    },
    to: {
      x: width,
      y: v * blockWidth + (v - 1) * lineWidth + lineWidth / 2,
    },
  }));

  return {
    lines: [...verticalLines, ...horizontalLines],
    block: { width, height },
  };
};

const getBlockDimensions = (value: BlockValue) => {
  if (value === 100) {
    return { numX: 10, numY: 10 };
  } else if (value === 10) {
    return { numX: 1, numY: 10 };
  }
  return { numX: 1, numY: 1 };
};

const styles = StyleSheet.create({
  block: {
    display: 'flex',
    ':active svg': {
      boxShadow: '0 3px 20px rgba(33, 55 , 77, .5)',
    },
  },

  disabled: {
    boxShadow: 'none',
  },
});

// It's either a 1s block, a 10s block or a 100s block
type BlockValue = 1 | 10 | 100;

export default function CountingBlock({
  value,
  isDisabled = false,
}: {
  value: BlockValue;
  isDisabled?: boolean;
}) {
  const scale = isHighDpiDevice ? 1.35 : 1;
  const blockWidth = 20 * scale;
  const lineWidth = 2;
  const fill = colors.lochmara;
  const stroke = colors.astronaut;
  const { numX, numY } = getBlockDimensions(value);
  const { lines, block } = getSvgData(numX, numY, blockWidth, lineWidth);
  const svgStyle = isDisabled ? css(styles.disabled) : undefined;

  return (
    <div className={css(styles.block)}>
      <svg width={block.width} height={block.height} className={svgStyle}>
        <rect
          x="0"
          y="0"
          width={block.width}
          height={block.height}
          fill={fill}
        />
        {lines.map(({ from, to }, i) => (
          <line
            key={i}
            x1={from.x}
            y1={from.y}
            x2={to.x}
            y2={to.y}
            strokeWidth={lineWidth}
            stroke={stroke}
          />
        ))}
      </svg>
    </div>
  );
}
