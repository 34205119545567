import React from 'react';
import { useQuery, graphql } from 'relay-hooks';

import { usePreviousNonNull } from 'ms-pages/Debug/VideoRecordingEnvironment/utilityHooks';
import useTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import Button from 'ms-ui-primitives/Button';
import LinkButton from 'ms-ui-primitives/Button/ReactRouterLinkButton';
import { HSpacer, HStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { getTaskReportUrl } from 'ms-utils/urls';

import type { AdaptiveTaskTeacherActionQuery } from './__generated__/AdaptiveTaskTeacherActionQuery.graphql';

const AssignNewAdaptiveExperienceFlyout = React.lazy(
  () =>
    import('ms-pages/Sunflower/components/AssignNewAdaptiveExperienceFlyout'),
);
const ADAPTIVE_TASK_TEACHER_ACTION_QUERY = graphql`
  query AdaptiveTaskTeacherActionQuery($subtopicId: ID!, $classId: ID!) {
    subtopic(id: $subtopicId) {
      mostRecentlyAssignedNewAdaptiveExperienceTask(classId: $classId) {
        id
      }
    }
  }
`;
type Props = {
  subtopicId: string;
  subtopicTitle: string;
};
export default function AdaptiveTaskAction({
  subtopicId,
  subtopicTitle,
}: Props) {
  const flyoutOpen = useBoolean();
  const { classId } = useTeacherContext();
  const { props, retry } = useQuery<AdaptiveTaskTeacherActionQuery>(
    ADAPTIVE_TASK_TEACHER_ACTION_QUERY,
    { subtopicId, classId },
  );
  const prevProps = usePreviousNonNull(props);
  const mostRecentlyAssignedNewAdaptiveExperienceTask = (props ?? prevProps)
    ?.subtopic?.mostRecentlyAssignedNewAdaptiveExperienceTask;
  const assignTaskLabel = `${
    mostRecentlyAssignedNewAdaptiveExperienceTask == null
      ? 'Assign'
      : 'Reassign'
  } adaptive practice`;
  return (
    <React.Suspense
      fallback={
        <Button type="primary" size="lanternSmall" isRound isDisabled>
          Loading
        </Button>
      }
    >
      <HStack center>
        <Button
          type="primary"
          size="lanternSmall"
          isRound
          onClick={flyoutOpen.setTrue}
          trackingId="Textbooks/SunflowerSubtopicDetailView/AssignAdaptivePractice"
        >
          {assignTaskLabel}
        </Button>

        {mostRecentlyAssignedNewAdaptiveExperienceTask != null && (
          <>
            <HSpacer width={8} />

            <LinkButton
              size="lanternSmall"
              isRound
              to={getTaskReportUrl({
                taskId: mostRecentlyAssignedNewAdaptiveExperienceTask.id,
                taskReportType: 'students',
              })}
              trackingId="Textbooks/SunflowerSubtopicDetailView/ViewTaskReport"
            >
              View task report
            </LinkButton>
          </>
        )}

        <AssignNewAdaptiveExperienceFlyout
          isOpen={flyoutOpen.value}
          onClose={flyoutOpen.setFalse}
          subtopicId={subtopicId}
          subtopicTitle={subtopicTitle}
          onSuccessfulAssignment={retry}
        />
      </HStack>
    </React.Suspense>
  );
}
