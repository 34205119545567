import styled from '@emotion/styled';
import {
  useMemo,
  useEffect,
  useRef,
  useState,
  useCallback,
  type ReactNode,
  type MouseEvent as ReactMouseEvent,
} from 'react';
import ReactDOM from 'react-dom';

import { zIndex } from 'ms-styles/base';
import useScrollTop from 'ms-utils/hooks/useScrollTop';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import keyDownMap from 'ms-utils/keyDownMap';

import { calculateOffset, getMeasurements } from './helpers';
import type { PopoverOrigin, AnchorOrigin } from './helpers';

export type { PopoverOrigin, AnchorOrigin };

function noop() {}

// local_modules/ms-utils/emotion/index.tsx is garbage; it types this as any.
const Overlay = styled.div({
  WebkitTapHighlightColor: 'transparent',
  cursor: 'default',
  position: 'fixed',
  color: 'transparent',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: zIndex.popoverOverlay, // must be less than Popover's zIndex
});

type Props = {
  onDismiss: () => void;
  popoverOrigin: PopoverOrigin;
  anchorOrigin: AnchorOrigin;
  anchorElementRef: { current: HTMLElement | null | undefined };
  children: ReactNode;
  hOffset: number;
  vOffset: number;
  shouldDismissOnTapOut: boolean;
  shouldDismissOnScroll: boolean;
  shouldDismissOnOwnScroll: boolean;
  shouldDismissOnEsc: boolean;
  shouldDismissOnTab: boolean;
  renderOverlay: boolean;
  // HACK: See `useMutationObserver` implementation
  __observeAnchorChanges?: true | undefined;
  hasVerticalTransition?: boolean | undefined;
};

// TODO why do we have this useless _ref param?
const useMutationObserver = (
  _ref: any,
  callback: MutationCallback,
  _opt?: MutationObserverInit,
) => {
  const options = useMemo(
    () =>
      _opt ?? {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      },
    // Safety: we don't want a new object to be created at each render (default args)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_opt == null],
  );
  useEffect(() => {
    const head = document.querySelector('head');
    // Only required due to TS issue to make return type consistent
    if (head === null) return () => {};

    // EXTRA SPICY HACKS. There is an edge case where if we happen to be observing a
    // container that conatins a button, the styles don't propagate to the head until the next
    // 2 or 3 ticks. This causes measurement to be off (by a lot). We want to eventually solve this
    // here. https://app.clickup.com/t/860q9jg2v
    // Get angry at lindsay if it's not done by 2024
    const observer = new MutationObserver(callback);
    observer.observe(head, options);
    return () => observer.disconnect();
  }, [callback, options]);
};

/**
 * anchorElement must an HTML element (positioned and not inline)
 * that Popover will use to positio next to.
 * It is typed as optional only because in the first render the ref.current
 * value might still be null.
 */

export default function Popover({
  onDismiss,
  popoverOrigin,
  anchorOrigin,
  children,
  anchorElementRef,
  vOffset,
  hOffset,
  renderOverlay,
  shouldDismissOnScroll,
  shouldDismissOnOwnScroll,
  shouldDismissOnTapOut,
  shouldDismissOnEsc,
  shouldDismissOnTab,
  hasVerticalTransition = true,
  __observeAnchorChanges,
}: Props) {
  const div = useMemo(() => document.createElement('div'), []);

  // put these two in effect deps so that it will recaculate positions on scroll or on window resize
  const windowSize = useWindowSize();
  const scrollingElementScrollTop = useScrollTop();

  // A hack to trigger the element re-measuring whenever its anchor is mutated.
  // Whenever a "mutation" occurs, according to mutation observer, the mutation count increases.
  // This piece of state can then become a dependency in the useEffect dependency array, ultimately triggering
  // a re-computation of the anchor's position.

  const [mutationCount, setMutationCount] = useState(0);
  const incrementMutationCount = useCallback(() => {
    return setMutationCount(mutationCount => mutationCount + 1);
  }, []);

  // Safety, this prop can only be declared as "true" this means that this prop
  // is static and cannot be bound to a runtime variable
  // <Popover __observeAnchorChanges={someVariable} /> is impossible therefore
  // this value does not change between renders. Safe to call conditionally
  if (__observeAnchorChanges) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMutationObserver(anchorElementRef, incrementMutationCount);
  }

  useEffect(() => {
    const anchor = anchorElementRef.current;

    if (anchor == null) return;

    div.style.position = 'fixed';
    div.style.zIndex = `${zIndex.popover}`;
    anchor.appendChild(div);
    const measurements = getMeasurements({
      anchorElement: anchor,
      popoverElement: div,
    });

    const { left: nextLeft, top: nextTop } = calculateOffset({
      ...measurements,
      popoverOrigin,
      anchorOrigin,
      hOffset,
      vOffset,
    });

    div.style.left = `${nextLeft}px`;
    div.style.top = `${nextTop}px`;
    div.style.transition = `${
      hasVerticalTransition ? 'top 0.5s cubic-bezier(0.4, 0, 0.3, 1), ' : ''
    }left 0.5s cubic-bezier(0.4, 0, 0.3, 1)`;
    if (shouldDismissOnOwnScroll) {
      div.addEventListener('wheel', onDismiss);
    }
    return () => {
      anchor.removeChild(div);
    };
  }, [
    anchorElementRef,
    anchorOrigin,
    div,
    hOffset,
    hasVerticalTransition,
    mutationCount,
    onDismiss,
    popoverOrigin,
    scrollingElementScrollTop,
    shouldDismissOnOwnScroll,
    vOffset,
    windowSize,
  ]);

  // so that it can be a target for keydown events
  // and we can prevent the view to be scrolled with arrow keys
  // if shouldDismissOnScroll is true
  const overlayRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const overlayEl = overlayRef.current;
    if (shouldDismissOnScroll && overlayEl != null) {
      overlayEl.focus();
    }
  }, [shouldDismissOnScroll]);

  // Handle on Click/tap out without overlay
  useEffect(() => {
    if (!shouldDismissOnTapOut || renderOverlay) {
      return;
    }

    function onTapOut(e: MouseEvent) {
      e.stopPropagation();
      // @ts-expect-error e.target types are too generic here
      if (!div.contains(e.target)) {
        onDismiss();
      }
    }

    window.addEventListener('click', onTapOut);

    return () => {
      window.removeEventListener('click', onTapOut);
    };
  }, [div, onDismiss, renderOverlay, shouldDismissOnTapOut]);

  return (
    <>
      {renderOverlay && (
        <Overlay
          ref={overlayRef}
          tabIndex={0} // so that it can be a target for keydown events
          onClick={(e: ReactMouseEvent) => {
            if (shouldDismissOnTapOut) {
              // the event can be propagated to the parent,
              // (possibly) the anchor, that being clicked will
              // re-open the popover
              e.stopPropagation();
              onDismiss();
            }
          }}
          onWheel={shouldDismissOnScroll ? onDismiss : undefined}
          onKeyDown={keyDownMap({
            ARROW_UP: shouldDismissOnScroll ? onDismiss : noop,
            ARROW_DOWN: shouldDismissOnScroll ? onDismiss : noop,
            ARROW_LEFT: shouldDismissOnScroll ? onDismiss : noop,
            ARROW_RIGHT: shouldDismissOnScroll ? onDismiss : noop,
            ESC: shouldDismissOnEsc ? onDismiss : noop,
            TAB: shouldDismissOnTab ? onDismiss : noop,
          })}
          onTouchMove={shouldDismissOnScroll ? onDismiss : undefined}
        />
      )}
      {ReactDOM.createPortal(children, div)}
    </>
  );
}
