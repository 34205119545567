// NB ArrowLeftNew was created instead to update ArrowLeft in order not to break other views that implement ArrowLeft

import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M1.72319 8.69731C1.33311 9.08739 0.700657 9.08739 0.310572 8.69731C-0.0795128 8.30722 -0.0795128 7.67477 0.310572 7.28469L7.30269 0.292564C7.69278 -0.0975212 8.32523 -0.0975212 8.71531 0.292564C9.1054 0.682648 9.1054 1.3151 8.71531 1.70519L1.72319 8.69731ZM1.01688 6.99212H15.0011C16.333 6.99212 16.333 8.98987 15.0011 8.98987H1.01688C-0.31495 8.98987 -0.31495 6.99212 1.01688 6.99212ZM1.72319 7.28469L8.71531 14.2768C9.65706 15.2186 8.24444 16.6312 7.30269 15.6894L0.310572 8.69731C-0.631176 7.75556 0.781446 6.34294 1.72319 7.28469Z" />
);

const ArrowLeftNew = ({ color, size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    {path}
  </Icon>
);

ArrowLeftNew.displayName = 'ArrowLeftNew';

export default ArrowLeftNew;
