import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function Clipboard({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0001 0C15.1047 0 16 0.82068 16 1.83338V10.1666C16 11.1793 15.1047 12 14.0001 12H6.36369C5.25892 12 4.36363 11.1793 4.36363 10.1666V1.83338C4.36363 0.82068 5.25892 0 6.36369 0H14.0001ZM3.27274 10.1666C3.27274 11.7286 4.65969 13 6.36371 13H13.091V14.1666C13.091 15.178 12.1942 16 11.0909 16H2.00005C0.896754 16 0 15.178 0 14.1666V5.16661C0 4.15526 0.896754 3.33336 2.00005 3.33336H3.27274V10.1666Z"
        transform="translate(2, 2)"
      />
    </Icon>
  );
}
