/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BoxPlotSubstatus = "CORRECT" | "INCORRECT" | "UNKNOWN";
export type DragHandleAlignment = "CENTER" | "RIGHT";
export type MultiplicationNotation = "CROSS" | "DOT";
export type NumberLineMode = "DECIMAL" | "FRACTION" | "IMPROPER" | "SIMPLIFIED_FRACTION";
export type NumberLineSubstatus = "CORRECT" | "INCORRECT" | "UNKNOWN";
export type NumberLineUnit = "NO_UNIT" | "PI";
export type Substatus = "CORRECT_FINAL" | "CORRECT_INCOMPLETE" | "ERROR" | "INCORRECT" | "UNKNOWN";
export type AnswerAttempt_data = {
    readonly __typename: "LegacyGraphPlotConfig";
    readonly requestDatum: string;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "LatexAnswerConfig";
    readonly template: string;
    readonly fields: ReadonlyArray<{
        readonly answerContent: string;
        readonly answerKey: string;
        readonly substatus: Substatus;
    }>;
    readonly requiredMathInputFeatures: ReadonlyArray<string>;
    readonly requiredMathDisplayFeatures: {
        readonly multiplicationNotation: MultiplicationNotation;
    };
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "MultipleChoiceConfig";
    readonly hasMultipleAnswers: boolean;
    readonly options: ReadonlyArray<{
        readonly optionContent: string;
        readonly optionKey: string;
        readonly selected: boolean;
    }>;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "NumberBuilderConfig";
    readonly itemType: string;
    readonly dropZones: ReadonlyArray<{
        readonly id: string;
    }>;
    readonly value: {
        readonly itemsBankItems: ReadonlyArray<{
            readonly value: number;
            readonly shouldRespawn: boolean;
        }>;
        readonly values: ReadonlyArray<{
            readonly key: string;
            readonly value: ReadonlyArray<{
                readonly data: {
                    readonly value: number;
                };
            }>;
        }>;
    } | null;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "HistogramConfig";
    readonly doesDrawBars: boolean;
    readonly doesDrawLines: boolean;
    readonly dragHandleAlignment: DragHandleAlignment;
    readonly hasGapBetweenBars: boolean;
    readonly mainLabel: string | null;
    readonly histogramValues: ReadonlyArray<{
        readonly title: string;
        readonly value: number;
    }>;
    readonly xAxisLabel: string;
    readonly yAxisLabel: string;
    readonly yIncrement: number;
    readonly yMax: number;
    readonly yMin: number;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "NumberLineConfig";
    readonly end: string;
    readonly majorTicks: string;
    readonly minorTicks: string;
    readonly mode: NumberLineMode;
    readonly start: string;
    readonly unit: NumberLineUnit;
    readonly numberLineValues: ReadonlyArray<{
        readonly lowerBound: {
            readonly inclusive: boolean;
            readonly value: string;
        };
        readonly substatus: NumberLineSubstatus;
        readonly upperBound: {
            readonly inclusive: boolean;
            readonly value: string;
        };
    }>;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "BoxPlotConfig";
    readonly axisMajorTickInterval: number;
    readonly axisMax: number;
    readonly axisMin: number;
    readonly axisMinorTickInterval: number;
    readonly axisTitle: string;
    readonly min: {
        readonly substatus: BoxPlotSubstatus;
        readonly value: number;
    };
    readonly q1: {
        readonly substatus: BoxPlotSubstatus;
        readonly value: number;
    };
    readonly median: {
        readonly substatus: BoxPlotSubstatus;
        readonly value: number;
    };
    readonly q3: {
        readonly substatus: BoxPlotSubstatus;
        readonly value: number;
    };
    readonly max: {
        readonly substatus: BoxPlotSubstatus;
        readonly value: number;
    };
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "ProbabilityTreeConfig";
    readonly tree: string;
    readonly availableNodeLabels: ReadonlyArray<string>;
    readonly areProbabilitiesEditable: boolean;
    readonly " $refType": "AnswerAttempt_data";
} | {
    readonly __typename: "GeometryReasonConfig";
    readonly reasonList: ReadonlyArray<string>;
    readonly selectedReason: string | null;
    readonly statement: string;
    readonly " $refType": "AnswerAttempt_data";
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
    readonly " $refType": "AnswerAttempt_data";
};
export type AnswerAttempt_data$data = AnswerAttempt_data;
export type AnswerAttempt_data$key = {
    readonly " $data"?: AnswerAttempt_data$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AnswerAttempt_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "substatus",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  (v2/*: any*/)
],
v4 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnswerAttempt_data",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestDatum",
          "storageKey": null
        }
      ],
      "type": "LegacyGraphPlotConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "template",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LatexAnswerField",
          "kind": "LinkedField",
          "name": "fields",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answerContent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answerKey",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiredMathInputFeatures",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LatexAnswerDisplayFeatures",
          "kind": "LinkedField",
          "name": "requiredMathDisplayFeatures",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "multiplicationNotation",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "LatexAnswerConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasMultipleAnswers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MultipleChoiceOption",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optionContent",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "optionKey",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "selected",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "MultipleChoiceConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NumberBuilderDropZone",
          "kind": "LinkedField",
          "name": "dropZones",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NumberBuilderValue",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NumberBuilderItemBank",
              "kind": "LinkedField",
              "name": "itemsBankItems",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "shouldRespawn",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BlockValues",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DropzoneItem",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DropzoneItemData",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "NumberBuilderConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doesDrawBars",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "doesDrawLines",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dragHandleAlignment",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasGapBetweenBars",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HistogramDatum",
          "kind": "LinkedField",
          "name": "histogramValues",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xAxisLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yAxisLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yIncrement",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "yMin",
          "storageKey": null
        }
      ],
      "type": "HistogramConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "majorTicks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minorTicks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "start",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "NumberLineSegment",
          "kind": "LinkedField",
          "name": "numberLineValues",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "NumberLinePoint",
              "kind": "LinkedField",
              "name": "lowerBound",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "NumberLinePoint",
              "kind": "LinkedField",
              "name": "upperBound",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "NumberLineConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axisMajorTickInterval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axisMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axisMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axisMinorTickInterval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "axisTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoxPlotValue",
          "kind": "LinkedField",
          "name": "min",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoxPlotValue",
          "kind": "LinkedField",
          "name": "q1",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoxPlotValue",
          "kind": "LinkedField",
          "name": "median",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoxPlotValue",
          "kind": "LinkedField",
          "name": "q3",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BoxPlotValue",
          "kind": "LinkedField",
          "name": "max",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "BoxPlotConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tree",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "availableNodeLabels",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "areProbabilitiesEditable",
          "storageKey": null
        }
      ],
      "type": "ProbabilityTreeConfig",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reasonList",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statement",
          "storageKey": null
        }
      ],
      "type": "GeometryReasonConfig",
      "abstractKey": null
    }
  ],
  "type": "AnswerInputConfig",
  "abstractKey": "__isAnswerInputConfig"
};
})();
(node as any).hash = 'f1b2967b20df20e212225ae32eafb02a';
export default node;
