/* eslint-disable jsx-a11y/no-static-element-interactions */
import styled from '@emotion/styled';
import { css, StyleSheet } from 'aphrodite';
import { useCallback, useEffect, useRef, useState } from 'react';

import MathContent from 'ms-components/math/MathContent';
import Measurer, { type Rect } from 'ms-helpers/Measurer';
import type { Dimensions } from 'ms-helpers/ResizeDetector';
import ResizeDetector from 'ms-helpers/ResizeDetector';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable } from 'ms-utils/emotion';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

type Html = string;

type Props = {
  label: string;
  value: Html;
  selected?: boolean | undefined;
  disabled?: boolean | undefined;
  onToggle?: ((label: string) => void) | null | undefined;
  hasMultipleAnswers?: boolean | undefined;
  selectionColor?: string | undefined;
  opacity?: number | undefined;
  minWidth?: number | undefined;
  onMinWidthChange?: ((width: number) => void) | undefined;
};

const BORDER_WIDTH = 2;
const BORDER_RADIUS = 6 * BASE_UNIT;
const LATERAL_PADDING = 2 * BASE_UNIT;
const VERTICAL_PADDING = 2 * BASE_UNIT;
const ELEMENT_SPACING = 2 * BASE_UNIT;
const WRAPPER_WIDTH = 6 * BASE_UNIT;
const LABEL_WIDTH = 4 * BASE_UNIT;
const MIN_WIDTH_WITHOUT_CONTENT =
  2 * LATERAL_PADDING + 2 * ELEMENT_SPACING + WRAPPER_WIDTH + LABEL_WIDTH;
// Latex strings wrap its content and we want to prevent this - at least
// for short expressions. 200px is small enough to fit mobile screens.
export const MULTI_CHOICE_OPTION_MIN_WIDTH = 275;

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexBasis: '100%',
    height: '100%',
    outline: 'none',
    backgroundColor: 'white',
    padding: `${VERTICAL_PADDING}px ${LATERAL_PADDING}px`,
    borderRadius: BORDER_RADIUS,
    borderStyle: 'solid',
    borderWidth: BORDER_WIDTH,
  },
  active: {
    ...tappable,
  },
  disabled: {
    paddingLeft: LATERAL_PADDING + WRAPPER_WIDTH,
  },
});

const Label = styled.div({
  display: 'flex',
  boxSizing: 'content-box',
  userSelect: 'none',
  flexShrink: 0,
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  fontWeight: fontWeight.semibold,
});

const Control = styled.div({
  display: 'flex',
  justifyContent: 'center',
  boxSizing: 'content-box',
  width: WRAPPER_WIDTH,
  height: WRAPPER_WIDTH,
  paddingRight: ELEMENT_SPACING,
});

const Value = styled.div({
  ...tappable,
  pointerEvents: 'none',
});

const Option = ({
  label,
  value,
  onToggle,
  selected = false,
  disabled,
  hasMultipleAnswers,
  selectionColor = colors.bayOfMany,
  opacity = 1,
  minWidth = MULTI_CHOICE_OPTION_MIN_WIDTH,
  onMinWidthChange,
}: Props) => {
  const [isTall, setIsTall] = useState(false);
  const [rootWidth, setRootWidth] = useState(0);
  const [valueWidth, setValueWidth] = useState(0);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const valueRef = useRef<HTMLDivElement | null>(null);

  const { width: _windowWidth, height: _windowHeight } = useWindowSize();
  const windowWidth = _windowWidth ?? 0;
  const windowHeight = _windowHeight ?? 0;

  const handleOnRootMeasure = useCallback<(rect: Rect) => void>(rect => {
    setRootWidth(rect.width);
    setIsTall(rect.height > 80);
  }, []);

  const handleResize = useCallback<(dimensions: Dimensions) => void>(
    dimensions => {
      const { width } = dimensions;
      setValueWidth(width);
    },
    [setValueWidth],
  );

  useEffect(() => {
    if (rootRef.current == null || valueRef.current == null) return;
    const { right: rootRight } = rootRef.current.getBoundingClientRect();
    const { right: valueRight } = valueRef.current.getBoundingClientRect();

    if (
      rootRight - (LABEL_WIDTH + ELEMENT_SPACING + LATERAL_PADDING) <
      valueRight
    ) {
      onMinWidthChange?.(valueWidth + MIN_WIDTH_WITHOUT_CONTENT);
    }
  }, [
    onMinWidthChange,
    minWidth,
    rootWidth,
    valueWidth,
    rootRef,
    valueRef,
    windowWidth,
    windowHeight,
  ]);

  const controlContent = hasMultipleAnswers ? (
    <Checkbox color={selectionColor} checked={selected} size={WRAPPER_WIDTH} />
  ) : (
    <RadioButton
      color={selectionColor}
      checked={selected}
      size={WRAPPER_WIDTH}
    />
  );

  const valueElement = (
    <Value ref={(ref: HTMLDivElement | null) => (valueRef.current = ref)}>
      <MathContent content={value} />
    </Value>
  );

  return (
    // Vertical alignment of option content is tricky.
    // Ideally we'd like all elements in the `Option` to be
    // top aligned, but some `MathContent` is wrapped in a
    // padded `<p>`, and some isn't.  This is outside our control
    // because it's part of the content itself.
    //
    // For short `Option`s, the appearance is more balanced when
    // the control and label are vertically centered.  For taller
    // variants, we prefer top alignment.  This measurement deals
    // with that.
    <Measurer onMeasure={handleOnRootMeasure}>
      <div
        role="button"
        tabIndex={0}
        className={css(
          styles.root,
          !disabled && styles.active,
          disabled && styles.disabled,
        )}
        style={{
          minWidth,
          borderColor: selected ? selectionColor : colors.iron,
          opacity,
          alignItems: isTall ? 'flex-start' : 'center',
        }}
        onClick={() => {
          if (!disabled && onToggle) onToggle(label);
        }}
        ref={ref => (rootRef.current = ref)}
      >
        {!disabled && <Control>{controlContent}</Control>}
        <ResizeDetector onResize={handleResize}>{valueElement}</ResizeDetector>

        <Label
          style={{
            width: LABEL_WIDTH,
            height: LABEL_WIDTH,
            color: selected ? selectionColor : colors.iron,
            alignItems: isTall ? 'flex-start' : 'center',
            justifyContent: 'center',
            marginLeft: ELEMENT_SPACING,
          }}
        >
          {label}
        </Label>
      </div>
    </Measurer>
  );
};

export default Option;
