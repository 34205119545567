import { Redirect, useParams } from 'react-router-dom';
import { useQuery, graphql } from 'relay-hooks';

import { StudentLayout } from 'ms-pages/Lantern/components/StudentLayout';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import {
  getCheckInStrandDiagnosticUrl,
  getCheckInUrl,
} from 'ms-pages/Lantern/utils/urls';
import { NotFoundError } from 'ms-utils/app-logging';

import type { CheckInStrandQuery } from './__generated__/CheckInStrandQuery.graphql';

graphql`
  fragment CheckInStrandStatus on StudentStrandStatus {
    status
    strand {
      id
    }
    lastWorkedOnDiagnosticCheckIn {
      id
    }
  }
`;
graphql`
  fragment CheckInStrandCheckIns on LanternStudent {
    checkIns {
      type
      strand {
        id
      }
    }
  }
`;
function CheckInStrand() {
  const { strandId } = useParams<{
    strandId: string;
  }>();
  const { props, error } = useQuery<CheckInStrandQuery>(
    graphql`
      query CheckInStrandQuery($strandId: ID!) {
        lantern {
          viewer {
            ... on LanternStudent {
              strandStatus(strandId: $strandId) {
                ...CheckInStrandStatus @relay(mask: false)
              }
            }
          }
        }
      }
    `,
    { strandId },
  );
  if (error != null) throw new Error(`CheckInStrand failed: ${error.message}`);
  if (props == null)
    return (
      <StudentLayout>
        <LoadingSpinner />
      </StudentLayout>
    );
  if (props.lantern.viewer == null) {
    throw new Error('User not found');
  }
  if (props.lantern.viewer.strandStatus == null) {
    throw new NotFoundError('Strand status for student not found');
  }
  const {
    lantern: {
      viewer: {
        strandStatus: { status, lastWorkedOnDiagnosticCheckIn },
      },
    },
  } = props;

  if (status === 'GROWTH' && lastWorkedOnDiagnosticCheckIn?.id != null) {
    return (
      <Redirect
        to={getCheckInUrl({ checkInId: lastWorkedOnDiagnosticCheckIn.id })}
      />
    );
  }
  return <Redirect to={getCheckInStrandDiagnosticUrl({ strandId })} />;
}
export default CheckInStrand;
