/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useSubstrandProficiencyGradeStrandQueryVariables = {
    substrandId: string;
};
export type useSubstrandProficiencyGradeStrandQueryResponse = {
    readonly lantern: {
        readonly substrand: {
            readonly gradeSubstrands: ReadonlyArray<{
                readonly id: string;
                readonly gradeStrand: {
                    readonly id: string;
                    readonly grade: {
                        readonly order: number;
                    };
                };
            }>;
        } | null;
    };
};
export type useSubstrandProficiencyGradeStrandQuery = {
    readonly response: useSubstrandProficiencyGradeStrandQueryResponse;
    readonly variables: useSubstrandProficiencyGradeStrandQueryVariables;
};



/*
query useSubstrandProficiencyGradeStrandQuery(
  $substrandId: ID!
) {
  lantern {
    substrand(id: $substrandId) {
      gradeSubstrands {
        id
        gradeStrand {
          id
          grade {
            order
            id
          }
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "substrandId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "substrandId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSubstrandProficiencyGradeStrandQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GradeSubstrand",
                "kind": "LinkedField",
                "name": "gradeSubstrands",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradeStrand",
                    "kind": "LinkedField",
                    "name": "gradeStrand",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternGrade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSubstrandProficiencyGradeStrandQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GradeSubstrand",
                "kind": "LinkedField",
                "name": "gradeSubstrands",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradeStrand",
                    "kind": "LinkedField",
                    "name": "gradeStrand",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternGrade",
                        "kind": "LinkedField",
                        "name": "grade",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "09148ee763fa7e01749bf897f24b412f",
    "id": null,
    "metadata": {},
    "name": "useSubstrandProficiencyGradeStrandQuery",
    "operationKind": "query",
    "text": "query useSubstrandProficiencyGradeStrandQuery(\n  $substrandId: ID!\n) {\n  lantern {\n    substrand(id: $substrandId) {\n      gradeSubstrands {\n        id\n        gradeStrand {\n          id\n          grade {\n            order\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4b0851dc3710d147193df27eb01a0e8a';
export default node;
