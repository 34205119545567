import { css } from 'aphrodite';

import LinkButton from 'ms-ui-primitives/Button/ReactRouterLinkButton';
import Link from 'ms-ui-primitives/Link/Link';
import Separator from 'ms-ui-primitives/Separator';
import {
  signupSunflowerJoinClassUrl,
  signupSunflowerParentUrl,
} from 'ms-utils/urls';

import { LoginLink } from './LoginLink';
import { formStyles } from './styles';

type Props = {
  showLoginLink: boolean;
  showJoinClassLink?: boolean;
  showJoinClassButton?: boolean;
  showParentSignupLink?: boolean;
  trackingIdPrefix: string;
};
function FooterLinks({
  showLoginLink = true,
  showJoinClassLink = false,
  showJoinClassButton = false,
  showParentSignupLink = false,
  trackingIdPrefix,
}: Props) {
  return (
    <div className={css(formStyles.footerLinks)}>
      {showLoginLink && (
        <LoginLink aphroditeStyles={[formStyles.footerLink]}>
          Use my Mathspace account
        </LoginLink>
      )}
      {showLoginLink && showJoinClassLink && <Separator size={4} />}
      {showJoinClassLink && (
        <Link
          to={signupSunflowerJoinClassUrl}
          aphroditeStyles={[formStyles.footerLink]}
          data-tracking-id={`${trackingIdPrefix}/JoinCodeLink`}
        >
          I have a join code
        </Link>
      )}
      <div>
        {showJoinClassButton && (
          <LinkButton
            type="primary"
            size="large"
            isRound
            to={signupSunflowerJoinClassUrl}
            trackingId={`${trackingIdPrefix}/JoinCodeButton`}
          >
            I have a join code
          </LinkButton>
        )}

        {showJoinClassButton && showParentSignupLink && <Separator size={4} />}

        {showParentSignupLink && (
          <LinkButton
            type="secondary"
            size="large"
            isRound
            to={signupSunflowerParentUrl}
            trackingId={`${trackingIdPrefix}/ParentSignup`}
          >
            Parent signup
          </LinkButton>
        )}
      </div>
    </div>
  );
}

export { FooterLinks };
