import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  useAddStudentsToClassMutationVariables,
  useAddStudentsToClassMutationResponse,
} from './__generated__/useAddStudentsToClassMutation.graphql';

export const mutation = graphql`
  mutation useAddStudentsToClassMutation(
    $classId: ID!
    $studentIds: [ID!]!
    $applyClassSyllabusFocus: Boolean
    $applyClassGrade: Boolean
    $numberOfStudents: Int!
    $isSunflowerUi: Boolean!
  ) {
    addStudentsToClass(
      classId: $classId
      studentIds: $studentIds
      applyClassSyllabusFocus: $applyClassSyllabusFocus
      applyClassGrade: $applyClassGrade
    ) {
      errors {
        key
        message
      }
      class {
        id
        studentCount
        ...ClassSettingsStudents_class
          @arguments(numberOfStudents: $numberOfStudents)
        ...ClassSettingsModalStudents_class
          @arguments(numberOfStudents: $numberOfStudents)
      }
    }
  }
`;
export default function useAddStudentsToClass(
  variables: useAddStudentsToClassMutationVariables,
): UseMutationResponse<useAddStudentsToClassMutationResponse> {
  return useMutation({ mutation, variables });
}
