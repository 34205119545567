import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M8.09701 12.8411L6.43843 14.4044L5.0081 14.4181L1.18667 10.7278L3.57146 8.31248L8.09701 12.8411ZM15.6921 5.8214L11.0582 1.18437C10.655 0.780899 10.0038 0.772228 9.58996 1.16482L0.332435 9.94798C-0.108662 10.3947 -0.111116 11.1078 0.326953 11.5407C0.328947 11.5427 1.60577 12.7758 4.15741 15.2399C4.36532 15.4406 4.64456 15.5513 4.93628 15.5485L6.3666 15.5348C6.65325 15.532 6.93015 15.42 7.14081 15.2214L15.6624 7.33729C16.0889 6.9427 16.1148 6.27709 15.7202 5.8506C15.711 5.84069 15.7017 5.83095 15.6921 5.8214Z" />
);

const Eraser2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

Eraser2.displayName = 'Eraser2';

export default Eraser2;
