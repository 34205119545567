import styled from '@emotion/styled';
import { useCallback, useEffect, Fragment } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import SkillIcon from 'ms-components/icons/Skill';
import { useSnowplow } from 'ms-helpers/Snowplow';
import {
  BodyM,
  HeadingXSGilroy,
  Bold,
} from 'ms-pages/Lantern/primitives/Typography';
import type { AssignedCheckinSkillIds } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/state';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { colors } from 'ms-styles/colors';
import { VStack, VSpacer, HStack, HSpacer } from 'ms-ui-primitives/Stack';
import {
  onPressOrHover,
  tappable,
  multilineTextOverflow,
} from 'ms-utils/emotion';

import type { SkillSelectionQuery } from './__generated__/SkillSelectionQuery.graphql';

type Props = {
  subtopicId: string;
  assignedCheckinSkillIds: AssignedCheckinSkillIds;
  setAssignedCheckinSkillIds: (p: AssignedCheckinSkillIds) => void;
};
const SKILL_SELECTION_QUERY = graphql`
  query SkillSelectionQuery($subtopicId: ID!) {
    subtopic(id: $subtopicId) {
      skills {
        id
        title
        outcome {
          code
        }
      }
    }
  }
`;
export default function SkillSelection({
  subtopicId,
  assignedCheckinSkillIds,
  setAssignedCheckinSkillIds,
}: Props) {
  const { props, error, retry } = useQuery<SkillSelectionQuery>(
    SKILL_SELECTION_QUERY,
    { subtopicId },
  );
  const firstSkillId = props?.subtopic?.skills[0]?.id;
  const selectedSkillId = assignedCheckinSkillIds[0]; // For now, support single selection
  const setSelectedSkillId = useCallback(
    (skillId: string | null) => {
      if (skillId != null) {
        setAssignedCheckinSkillIds([skillId]);
      } else {
        setAssignedCheckinSkillIds([]);
      }
    },
    [setAssignedCheckinSkillIds],
  );
  useEffect(() => {
    if (firstSkillId != null) setAssignedCheckinSkillIds([firstSkillId]);
  }, [firstSkillId, setAssignedCheckinSkillIds]);
  // Removing this behavior as it makes teacher puzzled if the view is skipped
  // https://mathspace.slack.com/archives/CPNEU7KK5/p1681327382833499
  // useEffect(() => {
  //   // in case there are no skills associated to the subtopics, skip this view and create the task
  //   if (props != null && props.subtopic?.skills.length === 0) {
  //     save();
  //   }
  // }, [props, save]);
  const { trackStructEvent } = useSnowplow();
  if (props != null && props.subtopic?.skills.length === 0)
    return <BodyM>No skills associated to this subtopic</BodyM>;
  return (
    <VStack>
      <HStack center>
        <HeadingXSGilroy>Check-in (2 questions)</HeadingXSGilroy>
        <HSpacer width={12} />
        <ExperimentBadge />
      </HStack>

      <BodyM color="grey90">
        Check-ins are a quick way to assess student progress across curriculum
        standards.
      </BodyM>

      <VSpacer height={16} />

      <BodyM color="grey90">
        Check-ins are prompted at the end of a task only if the student has
        achieved <Bold>at least Proficient</Bold>.
      </BodyM>

      <VSpacer height={16} />

      <BodyM>
        <Bold>Select a skill for the check-in</Bold>
      </BodyM>

      <VSpacer height={16} />

      {error != null && <Retry retry={retry} />}
      {props == null && error == null && <MinorSpinner />}

      {props?.subtopic?.skills.map(skill => {
        const isSelected = selectedSkillId === skill.id;
        return (
          <Fragment key={skill.id}>
            <SkillCard
              isSelected={isSelected}
              key={skill.id}
              onClick={() => {
                if (isSelected) {
                  setSelectedSkillId(null);
                } else {
                  trackStructEvent({
                    category: 'skill_check_ins_after_adaptive_task_in_catfa',
                    action: 'selected_a_skill',
                    label: 'skill_id',
                    value: skill.id,
                  });
                  setSelectedSkillId(skill.id);
                }
              }}
            >
              <HStack center>
                <SkillIcon size={20} />

                <HSpacer width={24} />

                <SkillLabel
                  color={isSelected ? 'eggplant' : 'grey90'}
                  title={`${skill.title} (${skill.outcome.code})`}
                >
                  {isSelected ? <Bold>{skill.title}</Bold> : skill.title} (
                  {skill.outcome.code})
                </SkillLabel>
              </HStack>
            </SkillCard>

            <VSpacer height={8} />
          </Fragment>
        );
      })}
    </VStack>
  );
}
const SkillCard = styled('div', {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{
  isSelected: boolean;
}>(({ isSelected }) => ({
  padding: 12,
  height: 68,
  overflow: 'hidden', // needed for "multi line textoverflow" of the skill label
  borderRadius: 8,
  // use box shadow instead of border to avoid jumps in size when selection is toggled
  boxShadow: `inset 0 0 0 ${isSelected ? 2 : 1}px ${
    isSelected ? colors.eggplant : colors.iron
  }`,
  background: isSelected ? colors.eggplant10 : colors.white,
  color: isSelected ? colors.eggplant : colors.grey90,
  ...tappable,
  ...onPressOrHover({
    opacity: 0.9,
  }),
}));
const SkillLabel = styled(BodyM)({
  // we want to make sure that the label doesn't span over more than two lines
  ...multilineTextOverflow(2),
});
function ExperimentBadge() {
  return (
    <svg
      width="64"
      height="22"
      viewBox="0 0 64 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="22" rx="4" fill="#EFEEF7" />
      <path
        d="M16.9615 12.3836L16.5678 13.8984H9.53772L9.40649 11.8093L10.9733 9.21719V6.05078C10.9733 5.82107 11.1537 5.64062 11.3834 5.64062H14.6619C14.8917 5.64062 15.0721 5.82107 15.0721 6.05078V9.21719L15.3838 9.74219L16.9615 12.3836Z"
        fill="#D7D6E8"
      />
      <path
        d="M16.6662 18H9.33256C7.74115 18 6.75678 16.2609 7.57709 14.891L9.40641 11.8093C9.84115 11.6043 10.3005 11.3828 10.9485 11.3828C11.8837 11.3828 12.4826 11.752 13.024 12.1375C13.6392 12.5641 14.1806 13.0234 15.0501 13.0234C15.8131 13.0234 16.297 12.6215 16.9615 12.3836L18.4216 14.891C19.242 16.2609 18.2658 18 16.6662 18Z"
        fill="#5D51AE"
      />
      <path
        d="M14.6391 6.46094H11.3606C10.6821 6.46094 10.1301 5.90898 10.1301 5.23047C10.1301 4.55196 10.6821 4 11.3606 4H14.6418C15.3204 4 15.8723 4.55196 15.8723 5.23047C15.8723 5.90898 15.3176 6.46094 14.6391 6.46094Z"
        fill="#8C83C7"
      />
      <path
        d="M15.0722 7.28125V8.10156H13.8198C13.5984 8.10156 13.4097 7.92107 13.4097 7.69141C13.4097 7.46169 13.5984 7.28125 13.8198 7.28125H15.0722Z"
        fill="#8C83C7"
      />
      <path
        d="M15.3839 9.74219H13.8198C13.5984 9.74219 13.4097 9.56169 13.4097 9.33203C13.4097 9.10232 13.5984 8.92188 13.8198 8.92188H15.0722V9.21719L15.3839 9.74219Z"
        fill="#8C83C7"
      />
      <path
        d="M10.5405 14.7188C10.7671 14.7188 10.9507 14.5351 10.9507 14.3086C10.9507 14.0821 10.7671 13.8984 10.5405 13.8984C10.314 13.8984 10.1304 14.0821 10.1304 14.3086C10.1304 14.5351 10.314 14.7188 10.5405 14.7188Z"
        fill="#8C83C7"
      />
      <path
        d="M15.4624 15.5391C15.6889 15.5391 15.8726 15.3554 15.8726 15.1289C15.8726 14.9024 15.6889 14.7188 15.4624 14.7188C15.2359 14.7188 15.0522 14.9024 15.0522 15.1289C15.0522 15.3554 15.2359 15.5391 15.4624 15.5391Z"
        fill="#8C83C7"
      />
      <path
        d="M13.0239 16.3594C12.7974 16.3594 12.6138 16.1757 12.6138 15.9492C12.6138 15.7227 12.7974 15.5391 13.0239 15.5391C13.2504 15.5391 13.4341 15.7227 13.4341 15.9492C13.4341 16.1757 13.2504 16.3594 13.0239 16.3594Z"
        fill="#8C83C7"
      />
      <path
        d="M24.994 15H29.712C31.462 15 32.428 13.922 32.428 12.48C32.428 11.332 31.616 10.338 30.622 10.184C31.49 10.002 32.246 9.218 32.246 8.042C32.246 6.726 31.294 5.662 29.586 5.662H24.994V15ZM26.632 9.526V7.104H29.25C30.09 7.104 30.566 7.622 30.566 8.322C30.566 9.022 30.09 9.526 29.25 9.526H26.632ZM26.632 13.558V10.968H29.32C30.258 10.968 30.748 11.556 30.748 12.256C30.748 13.068 30.216 13.558 29.32 13.558H26.632ZM34.0721 15H40.4701V13.558H35.7101V10.968H40.3721V9.526H35.7101V7.104H40.4701V5.662H34.0721V15ZM44.3782 15H46.0162V7.104H48.8442V5.662H41.5502V7.104H44.3782V15ZM55.9243 15H57.7163L54.0483 5.662H52.0043L48.3363 15H50.1283L50.8143 13.208H55.2383L55.9243 15ZM53.0263 7.048L54.7763 11.766H51.2763L53.0263 7.048Z"
        fill="#5D51AE"
      />
    </svg>
  );
}
