/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SunflowerUserMenu_UserInfo_viewer = {
    readonly avatar: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly profile: {
        readonly school?: {
            readonly title: string;
        } | null | undefined;
        readonly classes?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly displayName: string | null;
                    readonly hasEnabledLeaderboard: boolean;
                };
            }>;
        } | undefined;
        readonly lanternStudent?: {
            readonly selfReportedGrade: {
                readonly id: string;
                readonly title: string;
            };
        } | undefined;
    };
    readonly " $refType": "SunflowerUserMenu_UserInfo_viewer";
};
export type SunflowerUserMenu_UserInfo_viewer$data = SunflowerUserMenu_UserInfo_viewer;
export type SunflowerUserMenu_UserInfo_viewer$key = {
    readonly " $data"?: SunflowerUserMenu_UserInfo_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SunflowerUserMenu_UserInfo_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "numberOfClasses"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SunflowerUserMenu_UserInfo_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "School",
              "kind": "LinkedField",
              "name": "school",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "first",
                  "variableName": "numberOfClasses"
                }
              ],
              "concreteType": "ClassConnection",
              "kind": "LinkedField",
              "name": "classes",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClassEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Class",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasEnabledLeaderboard",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LanternStudent",
              "kind": "LinkedField",
              "name": "lanternStudent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LanternGrade",
                  "kind": "LinkedField",
                  "name": "selfReportedGrade",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Student",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'ca0bb11829c5dccfff9e57a93b88a046';
export default node;
