import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

export function GeometryCalculatorIcon() {
  return (
    <Icon size={32} viewBoxWidth={32} viewBoxHeight={32}>
      <rect width="32" height="32" rx="4" fill="currentColor" />
      <path
        d="M20.9721 6.04485C20.5527 5.31838 19.5041 5.31838 19.0847 6.04485L15.1384 12.8799C14.0802 12.3202 12.8737 12.0032 11.5931 12.0032C7.39956 12.0032 4 15.4027 4 19.5963C4 23.7899 7.39956 27.1894 11.5931 27.1894C15.7867 27.1894 19.1863 23.7899 19.1863 19.5963V19.5963H13.1497C12.3108 19.5963 11.7865 18.6882 12.206 17.9617L15.1395 12.8806H15.1397H15.1399C17.5467 14.1542 19.1866 16.6837 19.1867 19.5963V19.5966H26.9088C27.7476 19.5966 28.2719 18.6885 27.8525 17.962L20.9721 6.04485Z"
        fill={colors.white}
      />
    </Icon>
  );
}

export default GeometryCalculatorIcon;
