import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from './types';

const path = (
  <path d="M1.41 0.5L6 5.08L10.59 0.5L12 1.91L6 7.91L0 1.91L1.41 0.5Z" />
);

const ChevronDown = ({ color, size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={8}
    viewBoxWidth={12}
  >
    {path}
  </Icon>
);

ChevronDown.displayName = 'ChevronDown';

export default ChevronDown;
