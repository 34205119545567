import { css } from '@emotion/css';
import { useMemo } from 'react';

import ArrowRight from 'ms-components/icons/ArrowRight';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import TemplateFolderTitle from 'ms-pages/Teacher/TaskTemplates/views/TemplatesMain/TemplateFolderTitle';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { Cell, Row } from 'ms-pages/Teacher/components/Table';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';
import extractNode from 'ms-utils/relay/extractNode';

import type { AddOrMoveTemplateToFolderModal_school$data } from '../AddOrMoveTemplateToFolderModal/__generated__/AddOrMoveTemplateToFolderModal_school.graphql';
import { ModalTableWrapper } from '../ModalTableComponents';
import type { MoveFolderToFolderModal_school$data } from '../MoveFolderToFolderModal/__generated__/MoveFolderToFolderModal_school.graphql';
import type { SelectAFolderModal_school$data } from '../SelectAFolderModal/__generated__/SelectAFolderModal_school.graphql';
import type {
  Folder,
  NullableFolderId,
  ParentGroupId,
} from '../SelectAFolderModal/types';

const styles = {
  arrowButtonWrapper: css({
    marginLeft: 'auto',
    marginRight: 8,
  }),
};

type Props = {
  data:
    | SelectAFolderModal_school$data
    | AddOrMoveTemplateToFolderModal_school$data
    | MoveFolderToFolderModal_school$data
    | undefined;
  searchString: string;
  selectedFolderId: NullableFolderId;
  unselectableFolderId?: string | undefined;
  hiddenFolderId?: string | undefined;
  handleFolderClick: (p: Folder) => void;
  onParentGroupChange: (id: ParentGroupId) => void;
  hasNext: boolean;
  nextRef: (element: Element | null) => void;
};

const ModalSelectAFolderTable = ({
  data: school,
  searchString,
  selectedFolderId,
  unselectableFolderId,
  hiddenFolderId,
  handleFolderClick,
  onParentGroupChange,
  hasNext,
  nextRef,
}: Props) => {
  const templateGroups = useMemo(() => {
    return school?.taskTemplatesAndGroups != null
      ? extractNode(school.taskTemplatesAndGroups)
      : [];
  }, [school?.taskTemplatesAndGroups]);

  const filteredTemplateGroups = useMemo(() => {
    return hiddenFolderId != null && hiddenFolderId !== ''
      ? templateGroups.filter(group => {
          if (group.__typename !== 'TaskTemplateGroup') {
            return false;
          }
          const { id, ancestorGroups } = group;
          // We are filtering out the hidden folder and its descendant folders
          const ancestorIds = ancestorGroups.map(({ id }) => id);
          return (
            id !== hiddenFolderId &&
            // If a folder has the hidden folder as an ancestor,
            // then it's a descendant of the hidden folder
            !ancestorIds.includes(hiddenFolderId)
          );
        })
      : templateGroups;
  }, [hiddenFolderId, templateGroups]);

  const hasSearchTerm = searchString !== '';
  const showEmptyState = filteredTemplateGroups.length === 0;
  const noSearchResults = showEmptyState && hasSearchTerm;

  return (
    <ModalTableWrapper>
      {filteredTemplateGroups.length > 0 && (
        <>
          {filteredTemplateGroups.map(group => {
            if (group.__typename !== 'TaskTemplateGroup') {
              return null;
            }
            const {
              id,
              title,
              taskTemplateCount,
              taskTemplateGroupCount,
              isDistrictShared,
              owningSchool,
              ancestorGroups,
            } = group;

            const ancestorIds = ancestorGroups.map(({ id }) => id);
            const ancestorTitles = ancestorGroups.map(({ title }) => title);

            return (
              <Row
                key={id}
                height={48}
                onClick={() => {
                  // We show the current folder but as unselectable
                  // User can only navigate to subfolders
                  if (unselectableFolderId !== id) {
                    handleFolderClick({
                      id,
                      title,
                      ancestorIds,
                      ancestorTitles,
                    });
                  }
                }}
                backgroundColor={
                  id === selectedFolderId ? colors.eggplant10 : undefined
                }
              >
                <Cell hasLeftPadding>
                  <Stack.H center style={{ width: '100%' }}>
                    <TemplateFolderTitle
                      id={id}
                      isArchived={false}
                      title={title}
                      templateCount={taskTemplateCount}
                      templateGroupCount={taskTemplateGroupCount}
                      isContentOnly
                      isDistrictShared={isDistrictShared}
                      owningSchoolId={owningSchool?.id}
                      isCurrentFolder={unselectableFolderId === id}
                    />
                    {taskTemplateGroupCount > 0 && (
                      <div className={styles.arrowButtonWrapper}>
                        <Button
                          type="secondary"
                          color="grey90"
                          height={30}
                          isSquare
                          onClick={e => {
                            e.stopPropagation();
                            onParentGroupChange(id);
                          }}
                        >
                          <ArrowRight size={22} />
                        </Button>
                      </div>
                    )}
                  </Stack.H>
                </Cell>
              </Row>
            );
          })}
          {hasNext && (
            <Row>
              <Cell hasBottomBorder={false}>
                <div ref={nextRef} />
                <MinorSpinner />
              </Cell>
            </Row>
          )}
        </>
      )}
      {showEmptyState && (
        <Stack.V center>
          <Stack.Spacer.V height={20} />
          <BodyM bold color="grey10">
            {noSearchResults
              ? `No folders found for "${searchString}"`
              : 'No folders'}
          </BodyM>
          <Stack.Spacer.V height={20} />
        </Stack.V>
      )}
    </ModalTableWrapper>
  );
};

export default ModalSelectAFolderTable;
