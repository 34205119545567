import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';

import { transition } from 'ms-styles/base';

type Props = {
  color?: string | undefined;
  size?: number | string | undefined;
  aphroditeStyles?: CSSInputTypes[];
  title?: string | undefined;
  titleId?: string | undefined;
  children: ReactNode;
  viewBoxWidth?: number | undefined;
  viewBoxHeight?: number | undefined;
};

const VIEWBOX_WIDTH = 20;
const VIEWBOX_HEIGHT = 20;

const styles = StyleSheet.create({
  base: {
    display: 'inline-block',
    verticalAlign: 'middle',
    transition: `fill ${transition}`,
    flexShrink: 0,
  },
});

const getTitleId = (titleId: string): string => `${titleId}-icon-title`;

const Icon = ({
  color,
  size,
  aphroditeStyles = [],
  title,
  titleId,
  children,
  viewBoxWidth,
  viewBoxHeight,
}: Props) =>
  children ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox={`0 0 ${viewBoxWidth || VIEWBOX_WIDTH} ${
        viewBoxHeight || VIEWBOX_HEIGHT
      }`}
      className={css(styles.base, ...aphroditeStyles)}
      aria-labelledby={title && titleId ? getTitleId(titleId) : undefined}
    >
      {title && titleId && <title id={getTitleId(titleId)}>{title}</title>}
      {children}
    </svg>
  ) : null;

Icon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  aphroditeStyles: [],
};

export default Icon;
