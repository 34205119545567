import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CopyThick = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color="none"
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={13}
    viewBoxHeight={13}
  >
    <path
      d="M2.708 8.125h-.541a1.083 1.083 0 0 1-1.084-1.083V2.167a1.083 1.083 0 0 1 1.084-1.084h4.875a1.083 1.083 0 0 1 1.083 1.084v.541M5.958 4.875h4.875c.599 0 1.084.485 1.084 1.083v4.875c0 .599-.485 1.084-1.084 1.084H5.958a1.083 1.083 0 0 1-1.083-1.084V5.958c0-.598.485-1.083 1.083-1.083Z"
      stroke={color || 'currentColor'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

CopyThick.displayName = 'CopyThick';

export default CopyThick;
