/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandHeader_substrand = {
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly canStartCheckIn: boolean;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_substrand">;
    readonly " $refType": "StudentSkillsMapSubstrandHeader_substrand";
};
export type StudentSkillsMapSubstrandHeader_substrand$data = StudentSkillsMapSubstrandHeader_substrand;
export type StudentSkillsMapSubstrandHeader_substrand$key = {
    readonly " $data"?: StudentSkillsMapSubstrandHeader_substrand$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandHeader_substrand">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandHeader_substrand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canStartCheckIn",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudentSkillsMapSubstrandProficiencyIndicator_substrand"
    }
  ],
  "type": "Substrand",
  "abstractKey": null
};
(node as any).hash = 'd3a0737cde2ca4c138228be8efb10d43';
export default node;
