import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';

export type QuestionsCollapseType = boolean;
export const QUESTIONS_COLLAPSE_DEFAULT: QuestionsCollapseType = false;
export type QuestionsCollapseUpdaterType = React.Dispatch<
  React.SetStateAction<QuestionsCollapseType>
>;
type ReturnType = [QuestionsCollapseType, QuestionsCollapseUpdaterType];

export const useCollapseQuestionsState = (): ReturnType => {
  const { userId } = useMaybeViewer() || { userId: 'Unknown-User' };
  return useLocalSyncedState<QuestionsCollapseType>({
    dbKey: `QUESTIONS_COLLAPSED:${userId}`,
    fallbackValue: QUESTIONS_COLLAPSE_DEFAULT,
  });
};
