/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AssignedTaskStatus = "COMPLETED" | "COMPLETED_LATE" | "INCOMPLETE_ENDED_BY_STUDENT" | "INCOMPLETE_EXPIRED" | "IN_PROGRESS" | "NOT_STARTED";
export type StatusEnum = "ENDED_COMPLETE_MARKED" | "ENDED_INCOMPLETE_MARKED" | "ENDED_INCOMPLETE_NOT_MARKED" | "ENDED_NOT_STARTED_MARKED" | "ENDED_NOT_STARTED_NOT_MARKED" | "IN_PROGRESS" | "READY";
export type CreateTaskContentQueryVariables = {
    subtopicId: string;
};
export type CreateTaskContentQueryResponse = {
    readonly subtopic: {
        readonly id: string;
        readonly title: string;
        readonly hasExercise: boolean;
        readonly hasAdaptivePractice: boolean;
        readonly hasLessons: boolean;
        readonly hasInvestigations: boolean;
        readonly hasWorksheet: boolean;
        readonly hasEngageActivity: boolean;
        readonly gamifiedMastery: number;
        readonly earliestDueAssignedLessonTask: {
            readonly id: string;
            readonly status: AssignedTaskStatus;
            readonly workout: {
                readonly status: StatusEnum;
            } | null;
        } | null;
        readonly earliestDueAssignedWorksheetTask: {
            readonly id: string;
            readonly status: AssignedTaskStatus;
            readonly workout: {
                readonly status: StatusEnum;
            } | null;
        } | null;
    } | null;
};
export type CreateTaskContentQuery = {
    readonly response: CreateTaskContentQueryResponse;
    readonly variables: CreateTaskContentQueryVariables;
};



/*
query CreateTaskContentQuery(
  $subtopicId: ID!
) {
  subtopic(id: $subtopicId) {
    id
    title
    hasExercise
    hasAdaptivePractice
    hasLessons
    hasInvestigations
    hasWorksheet
    hasEngageActivity
    gamifiedMastery
    earliestDueAssignedLessonTask {
      id
      status
      workout {
        __typename
        status
        id
      }
    }
    earliestDueAssignedWorksheetTask {
      id
      status
      workout {
        __typename
        status
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subtopicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasExercise",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAdaptivePractice",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLessons",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasInvestigations",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasWorksheet",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasEngageActivity",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gamifiedMastery",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = [
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "workout",
    "plural": false,
    "selections": [
      (v11/*: any*/)
    ],
    "storageKey": null
  }
],
v13 = [
  (v2/*: any*/),
  (v11/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "workout",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      (v11/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTaskContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignedTask",
            "kind": "LinkedField",
            "name": "earliestDueAssignedLessonTask",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignedTask",
            "kind": "LinkedField",
            "name": "earliestDueAssignedWorksheetTask",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTaskContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignedTask",
            "kind": "LinkedField",
            "name": "earliestDueAssignedLessonTask",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssignedTask",
            "kind": "LinkedField",
            "name": "earliestDueAssignedWorksheetTask",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8870b2a4bd285327ed200d3acfbd0e41",
    "id": null,
    "metadata": {},
    "name": "CreateTaskContentQuery",
    "operationKind": "query",
    "text": "query CreateTaskContentQuery(\n  $subtopicId: ID!\n) {\n  subtopic(id: $subtopicId) {\n    id\n    title\n    hasExercise\n    hasAdaptivePractice\n    hasLessons\n    hasInvestigations\n    hasWorksheet\n    hasEngageActivity\n    gamifiedMastery\n    earliestDueAssignedLessonTask {\n      id\n      status\n      workout {\n        __typename\n        status\n        id\n      }\n    }\n    earliestDueAssignedWorksheetTask {\n      id\n      status\n      workout {\n        __typename\n        status\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5cf09abbd9b96077ad0c1fcb59408184';
export default node;
