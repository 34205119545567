import { Trans } from '@lingui/macro';
import { useState, Fragment } from 'react';

import ProficiencyIndicator from 'ms-pages/Lantern/components/ProficiencyIndicator';
import RotatingChevron from 'ms-pages/Lantern/components/RotatingChevron';
import { Accordion } from 'ms-pages/Lantern/primitives/Accordion';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { colors } from 'ms-pages/Lantern/styles';

import {
  gradeLevelTitleStyle,
  gradeLevelRowStyle,
  skillsReportTable,
  accordionButtonStyle,
  proficiencyIndicatorWrapper,
} from './styles';
import type {
  Curriculum,
  GradeLevelTitle,
  ProgressByStrand,
  StudentId,
} from './types';
import { useProgress } from './useProgress';
import { LanternTeacherQueryWrapper } from '../components';

type SkillReportTableProps = {
  studentId: StudentId;
  curriculum: Curriculum;
};

function SkillsReportTable(props: SkillReportTableProps) {
  return (
    <LanternTeacherQueryWrapper fallback={null} queryName="useProgressQuery">
      {({ fetchKey }) => (
        <SkillsReportTableQueryContent fetchKey={fetchKey} {...props} />
      )}
    </LanternTeacherQueryWrapper>
  );
}

function SkillsReportTableQueryContent({
  studentId,
  curriculum,
  fetchKey,
}: SkillReportTableProps & { fetchKey: number }) {
  const { progress } = useProgress({
    studentId,
    strands: curriculum.strands,
    outcomes: curriculum.outcomes,
    grades: curriculum.grades,
    fetchKey,
  });
  if (progress.length === 0) return <LoadingSpinner />;
  return (
    // marginBottom is so that the accordions don't overlap with the
    // feedback button
    <div css={{ marginBottom: 32 }}>
      {progress.map((p, index) => (
        <Fragment key={p.gradeLevelId}>
          {index !== 0 && <VSpacer height={16} />}
          <SkillsReportGrade
            title={p.gradeLevelTitle}
            progress={p.gradeStrands}
          />
        </Fragment>
      ))}
    </div>
  );
}

function SkillsReportGrade({
  title,
  progress,
}: {
  title: GradeLevelTitle;
  progress: ProgressByStrand;
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div onClick={() => setIsOpen(!isOpen)} css={gradeLevelRowStyle}>
        <div css={gradeLevelTitleStyle}>{title}</div>
        <div css={accordionButtonStyle}>
          <RotatingChevron color={colors.grey90} size={13} isDown={!isOpen} />
        </div>
      </div>
      <Accordion isOpen={isOpen}>
        <table css={skillsReportTable.table}>
          <tbody>
            {progress.map((strand, idx) => (
              <Fragment key={strand.strandId}>
                <tr>
                  {idx === 0 ? (
                    <>
                      <th css={skillsReportTable.th}>{strand.strandTitle}</th>
                      <th css={skillsReportTable.th}>
                        <Trans>Outcome</Trans>
                      </th>
                      <th css={skillsReportTable.th}>Proficiency</th>
                    </>
                  ) : (
                    <th colSpan={3} css={skillsReportTable.th}>
                      {strand.strandTitle}
                    </th>
                  )}
                </tr>
                {strand.outcomes.map(outcome => (
                  <tr key={outcome.id} css={skillsReportTable.tr}>
                    <td css={skillsReportTable.td}>{outcome.description}</td>
                    <td css={skillsReportTable.td}>{outcome.code}</td>
                    <td css={skillsReportTable.td}>
                      <div css={proficiencyIndicatorWrapper}>
                        <ProficiencyIndicator
                          value={outcome.proficiency}
                          width={59}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </Accordion>
    </>
  );
}

export { SkillsReportTable };
