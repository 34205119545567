import type { State } from 'ms-pages/Teacher/state/State';
import { isValidDateString, type DateISOString } from 'ms-utils/datetime';
import {
  deserializeBool,
  deserializeInt,
  serializeBool,
  serializeInt,
} from 'ms-utils/urls/queryParams';
import type {
  QueryStringBoolean,
  QueryStringInt,
} from 'ms-utils/urls/queryParams';

import type { Value } from './types';

export type QueryParamState = {
  period: QueryStringInt;
  useCustomPeriod: QueryStringBoolean;
  customStartDate: DateISOString;
  customEndDate: DateISOString;
};

const isValidPeriod = (period: QueryStringInt): boolean =>
  !Number.isNaN(deserializeInt(period));

export const validateDateRangeState = (
  state: State,
  initialState: State,
): QueryParamState => {
  const newState = isQueryParamStateValid(state) ? state : initialState;
  const { period, useCustomPeriod, customStartDate, customEndDate } = newState;
  return {
    period,
    useCustomPeriod,
    customStartDate,
    customEndDate,
  };
};

export const isQueryParamStateValid = (o: State): boolean => {
  if (
    o.customStartDate === '' ||
    !isValidDateString(o.customStartDate) ||
    o.customEndDate === '' ||
    !isValidDateString(o.customEndDate) ||
    !isValidPeriod(o.period)
  )
    return false;
  return true;
};

export const isDateRangeQueryParamStateValid = (
  o: QueryParamState,
): boolean => {
  const useCustomPeriod = deserializeBool(o.useCustomPeriod);

  if (
    (o.customStartDate !== '' && !isValidDateString(o.customStartDate)) ||
    (o.customEndDate !== '' && !isValidDateString(o.customEndDate))
  )
    return false;

  if (!useCustomPeriod) return isValidPeriod(o.period);

  return true;
};

export const valueToQueryParamState = (v: Value): QueryParamState => ({
  period: v.period != null ? serializeInt(v.period) : '',
  useCustomPeriod: serializeBool(v.useCustomPeriod),
  customStartDate:
    v.customStartDate != null ? v.customStartDate.toISOString() : '',
  customEndDate: v.customEndDate != null ? v.customEndDate.toISOString() : '',
});

export const queryParamStateToValue = (o: QueryParamState): Value => {
  const useCustomPeriod = deserializeBool(o.useCustomPeriod);

  if (useCustomPeriod)
    return {
      period: deserializeInt(o.period),
      useCustomPeriod: true,
      customStartDate:
        o.customStartDate !== '' ? new Date(o.customStartDate) : null,
      customEndDate: o.customEndDate !== '' ? new Date(o.customEndDate) : null,
    };

  return {
    period: deserializeInt(o.period),
    useCustomPeriod: false,
    customStartDate:
      o.customStartDate !== '' ? new Date(o.customStartDate) : null,
    customEndDate: o.customEndDate !== '' ? new Date(o.customEndDate) : null,
  };
};
