import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import PlanCard from 'ms-components/PlanCard';
import { fontFamily, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { VStack } from 'ms-ui-primitives/Stack';

export default function SubscriptionCard({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <PlanCard highlighted fullWidth>
      <VStack gap={25}>{children}</VStack>
    </PlanCard>
  );
}

export const SubscriptionCardTitle = styled.h2({
  color: colors.cloudBurst,
  fontSize: 23,
  fontWeight: fontWeight.semibold,
  fontFamily: fontFamily.body,
});

export const SubscriptionCardText = styled.p({
  color: colors.mako,
  fontSize: 13,
  lineHeight: lineHeight.subscriptionCard,
  fontFamily: fontFamily.body,
});
