import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import {
  FeedbackComponent,
  FeedbackWrapper,
} from 'ms-components/Feedback/FeedbackComponent';
import JsxContent from 'ms-components/JsxContent';
import PageNotFoundView from 'ms-components/PageNotFoundView';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  useTopicAssessmentSubTabs,
  useTopicTabs,
} from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Separator from 'ms-ui-primitives/Separator';
import Stack from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';

import TopicDetailHeader from './TopicDetailHeader';
import type { TopicOverviewQuery } from './__generated__/TopicOverviewQuery.graphql';
import AnswerKeyModal from '../AnswerKeyModal';
import { ContentHeading, ContentTitle } from '../elements';

const MainWrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
  paddingBottom: 12 * BASE_UNIT,
});
const Root = styled({
  background: colors.white,
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const ContentWrapper = styled({
  marginTop: 6 * BASE_UNIT,
});

const query = graphql`
  query TopicOverviewQuery($topicId: ID!) {
    topic(id: $topicId) {
      id
      title
      badgeUrl
      syllabus {
        id
        coverImageUrl
        textbookType
      }
      overviewJsx {
        transpiledJsx
        pdfUrl
        pdfAvailable
      }
      assessmentJsx {
        transpiledJsx
        pdfUrl
        pdfAvailable
      }
      answerKeyJsx {
        transpiledJsx
        pdfUrl
        pdfAvailable
      }
    }
  }
`;
type Props = {
  topicId: string;
};
export default function TopicOverview({ topicId }: Props) {
  const { error, props } = useQuery<TopicOverviewQuery>(query, { topicId });
  if (error != null) throw error;
  if (props == null) return <MinorSpinner />;
  const { topic } = props;
  if (topic == null) return <PageNotFoundView />;

  return <TopicOverviewInner topic={topic} />;
}

type Topic = NonNullable<TopicOverviewQuery['response']['topic']>;
function TopicOverviewInner({ topic }: { topic: Topic }) {
  const [
    {
      // rename it for clarity
      topicAssessment: canAccessTopicAccessment,
    },
  ] = useFeatureFlags();
  const coverImageUrl = topic.syllabus.coverImageUrl;
  const overview = topic.overviewJsx;
  const assessment = topic.assessmentJsx;
  const answerKey = topic.answerKeyJsx;

  const canRenderOverviewTab = overview.transpiledJsx !== null;
  const canRenderAssessmentTab =
    canAccessTopicAccessment &&
    (assessment.transpiledJsx !== null || answerKey.transpiledJsx !== null);

  const [topicTab] = useTopicTabs({
    overview: { shouldRender: canRenderOverviewTab },
    assessment: {
      shouldRender: canRenderAssessmentTab,
    },
  });

  const answerKeyAvailable = answerKey.transpiledJsx !== null;

  return (
    <Root>
      <TopicDetailHeader
        coverImageUrl={coverImageUrl}
        topicTitle={topic.title}
        topicBadgeUrl={topic.badgeUrl}
        overviewPdfAvailable={overview.pdfAvailable}
        overviewPdfUrl={overview.pdfUrl}
        assessmentPdfAvailable={assessment.pdfAvailable}
        assessmentPdfUrl={assessment.pdfUrl}
        answerKeyPdfAvailable={answerKey.pdfAvailable}
        answerKeyPdfUrl={answerKey.pdfUrl}
        shouldRenderAssessmentTab={canRenderAssessmentTab}
        shouldRenderOverviewTab={canRenderOverviewTab}
        answerKeyAvailable={answerKeyAvailable}
      />

      <MainWrapper>
        {!canRenderAssessmentTab && !canRenderOverviewTab && (
          <ContentWrapper>
            <BodyM>No topic overview content available</BodyM>
          </ContentWrapper>
        )}

        {canRenderOverviewTab && topicTab === 'overview' && (
          <TopicOverviewOverview overview={overview} />
        )}

        {canRenderAssessmentTab && topicTab === 'assessment' && (
          <TopicOverviewAssessment
            assessment={assessment}
            answerKey={answerKey}
            topicId={topic.id}
          />
        )}

        <Stack.Spacer.V height={40} />

        <FeedbackWrapper>
          <FeedbackComponent feedbackType="GENERAL" />
        </FeedbackWrapper>
      </MainWrapper>
    </Root>
  );
}

function TopicOverviewOverview({
  overview,
}: {
  overview: Topic['overviewJsx'];
}) {
  return (
    <ContentWrapper>
      <ContentHeading>
        <ContentTitle>Topic overview</ContentTitle>
      </ContentHeading>
      {overview.transpiledJsx !== null ? (
        <JsxContent transpiledJsx={overview.transpiledJsx} />
      ) : (
        'No overview available'
      )}
    </ContentWrapper>
  );
}

function TopicOverviewAssessment({
  assessment,
  answerKey,
  topicId,
}: {
  assessment: Topic['assessmentJsx'];
  answerKey: Topic['answerKeyJsx'];
  topicId: string;
}) {
  const [topicAssessmentSubtab, setTopicAssessmentSubtab] =
    useTopicAssessmentSubTabs({
      worksheet: { shouldRender: assessment.transpiledJsx !== null },
      'answer-key': { shouldRender: answerKey.transpiledJsx !== null },
    });
  return (
    <ContentWrapper>
      <ContentHeading>
        <ContentTitle>Topic assessment</ContentTitle>
      </ContentHeading>

      <Separator size={2} />
      {topicAssessmentSubtab === 'worksheet' &&
        (assessment.transpiledJsx !== null ? (
          <JsxContent transpiledJsx={assessment.transpiledJsx} />
        ) : (
          'No assessment available'
        ))}

      {topicAssessmentSubtab === 'answer-key' &&
        answerKey.transpiledJsx !== null && (
          <AnswerKeyModal
            isOpen
            onClose={() => {
              setTopicAssessmentSubtab('worksheet');
            }}
            answerKey={answerKey.transpiledJsx}
            answerKeyPdfUrl={
              answerKey.pdfAvailable && answerKey.pdfUrl !== ''
                ? answerKey.pdfUrl
                : null
            }
            topicId={topicId}
          />
        )}

      <Stack.Spacer.V height={40} />
    </ContentWrapper>
  );
}
