/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type featureFlags = {
    readonly canAccessNewDistrictReport: boolean;
    readonly canAccessNewDistrictReportImpact: boolean;
    readonly canAccessNewDistrictReportSchoolLevel: boolean;
    readonly canAccessNewDistrictReportTemplates: boolean;
    readonly canAssignDiscoveryCheckIn: boolean;
    readonly canChooseSkillCheckin: boolean;
    readonly canvasEnableGradePassback: boolean;
    readonly checkinsEnableGrowthPeriodFilter: boolean;
    readonly classReportsEnableCheckInActivityDetail: boolean;
    readonly enableAiOpenPrompt: boolean;
    readonly enableAiPremiumTrial: boolean;
    readonly enableAiSuggestQuestions: boolean;
    readonly enableCATFAv2AssignCheckin: boolean;
    readonly enableFeatureFlagsV2: boolean;
    readonly enableMTSInCATFA: boolean;
    readonly enableOptInDiscoveryCheckIn: boolean;
    readonly gamificationEnableChallengesDaily: boolean;
    readonly gamificationEnableCustomisation: boolean;
    readonly gamificationEnableLeaderboardDock: boolean;
    readonly gamificationEnableLeaderboardNotification: boolean;
    readonly gamificationEnableLeaderboardTab: boolean;
    readonly gamificationEnableLiveModePromote: boolean;
    readonly gamificationEnablePointsPromote: boolean;
    readonly gamificationEnablePointsQuestionStreak: boolean;
    readonly gamificationEnablePointsUpdate: boolean;
    readonly gamificationEnableTugOfWar: boolean;
    readonly hasDismissedOnboardingModalInAdaptiveIntro: boolean;
    readonly hasEnabledAccessibilityMode: boolean;
    readonly hasStudentAppSpa: boolean;
    readonly hasUpdatedCorrectStepIcons: boolean;
    readonly insightsEnableLearningHighlights: boolean;
    readonly perfEnableModelDataLoaders: boolean;
    readonly perfEnableOptimisedPaginateQueryset: boolean;
    readonly perfEnableTextbookOptimisation: boolean;
    readonly printEnablePageBreaksAfterPTs: boolean;
    readonly problemContentsShortcutKillswitchEnabled: boolean;
    readonly showLoginV2: boolean;
    readonly showMaintenanceBanner: boolean;
    readonly showSkillsBookByDefault: boolean;
    readonly showSkillsBookGridView: boolean;
    readonly showWorkbookOnboarding: boolean;
    readonly skillsReportEnableSearchStandard: boolean;
    readonly skillsReportEnableSort: boolean;
    readonly supportEnableHubspotBubble: boolean;
    readonly textbookDisableSecondaryAssignCTA: boolean;
    readonly useSanaConfidenceInterval: boolean;
    readonly useSanaCorrelationsUpdator: boolean;
    readonly useSanaTimeDependentUncertaintyInjection: boolean;
    readonly workbookEnableIncorrectStepPrompt: boolean;
    readonly workbookEnableSpanishSupport: boolean;
    readonly adminReports: boolean;
    readonly advancedReports: boolean;
    readonly allowMultipleTeachersInClass: boolean;
    readonly assignTasksToIndividuals: boolean;
    readonly assignableTasks: boolean;
    readonly bulkAssignTasks: boolean;
    readonly classicUi: boolean;
    readonly demoCheckin: boolean;
    readonly diagnostics: boolean;
    readonly engageLite: boolean;
    readonly engageTasks: boolean;
    readonly primaryTeacherNotes: boolean;
    readonly printableWorksheets: boolean;
    readonly schoolReports: boolean;
    readonly teacherReporting: boolean;
    readonly templates: boolean;
    readonly textbook: boolean;
    readonly textbookOverviews: boolean;
    readonly topicAssessment: boolean;
    readonly unlimitedClasses: boolean;
    readonly unlimitedOpenPrompt: boolean;
    readonly worksheetAnswerKeys: boolean;
    readonly worksheets: boolean;
    readonly canEditLanternQuestions: boolean;
};
export type featureFlags$data = featureFlags;
export type featureFlags$key = {
    readonly " $data"?: featureFlags$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"featureFlags">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "featureFlags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportImpact",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportSchoolLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAccessNewDistrictReportTemplates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canAssignDiscoveryCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canChooseSkillCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canvasEnableGradePassback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checkinsEnableGrowthPeriodFilter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classReportsEnableCheckInActivityDetail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiPremiumTrial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableAiSuggestQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableCATFAv2AssignCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableFeatureFlagsV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableMTSInCATFA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableOptInDiscoveryCheckIn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableChallengesDaily",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableCustomisation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardDock",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardNotification",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLeaderboardTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableLiveModePromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsPromote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsQuestionStreak",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnablePointsUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamificationEnableTugOfWar",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasDismissedOnboardingModalInAdaptiveIntro",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEnabledAccessibilityMode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasStudentAppSpa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUpdatedCorrectStepIcons",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "insightsEnableLearningHighlights",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perfEnableModelDataLoaders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perfEnableOptimisedPaginateQueryset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perfEnableTextbookOptimisation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printEnablePageBreaksAfterPTs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemContentsShortcutKillswitchEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showLoginV2",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showMaintenanceBanner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSkillsBookByDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showSkillsBookGridView",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showWorkbookOnboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsReportEnableSearchStandard",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillsReportEnableSort",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supportEnableHubspotBubble",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookDisableSecondaryAssignCTA",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaConfidenceInterval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaCorrelationsUpdator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useSanaTimeDependentUncertaintyInjection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableIncorrectStepPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workbookEnableSpanishSupport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "advancedReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowMultipleTeachersInClass",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignTasksToIndividuals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignableTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bulkAssignTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "classicUi",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "demoCheckin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnostics",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageLite",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "engageTasks",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "primaryTeacherNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "printableWorksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolReports",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teacherReporting",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templates",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbook",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textbookOverviews",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topicAssessment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedClasses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlimitedOpenPrompt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetAnswerKeys",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheets",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEditLanternQuestions",
      "storageKey": null
    }
  ],
  "type": "TypedFeatureFlag",
  "abstractKey": null
};
(node as any).hash = 'acf9c7b8cf82d39e500ced8d1f6c0212';
export default node;
