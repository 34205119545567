import { assertUnreachable } from 'ms-utils/typescript-utils';

import {
  teacherClassSkillsPath,
  teacherClassSkillsUrl,
  type TeacherClassUrlParams,
} from './class';

// Deprecated Waypoints URLs that we still want to support in
// case it is boorkmarked or linked from an email/article.
export const deprecatedWayspacePath = '/teacher/waypoints/:classId';
export const deprecatedClasslessWayspacePath = '/teacher/waypoints';

export const classlessWayspacePath = '/teacher/skills';

// Overview
type ClassSkillsOverviewUrlParams = TeacherClassUrlParams & {
  strandId?: string | undefined | null;
  gradeId?: string | undefined | null;
};
export const getClassSkillsOverviewBaseUrl = ({
  classId,
}: TeacherClassUrlParams) => `${teacherClassSkillsUrl({ classId })}/overview`;
export const getClassSkillsOverviewUrl = ({
  classId,
  strandId,
}: ClassSkillsOverviewUrlParams) =>
  `${teacherClassSkillsUrl({ classId })}/overview/strand/${
    strandId != null ? `${strandId}/` : 'all'
  }`;
export const classSkillsOverviewPath = `${teacherClassSkillsPath}/overview`;
export const classSkillsOverviewAllPath = `${classSkillsOverviewPath}/strand/all`;
export const classSkillsOverviewStrandPath = `${classSkillsOverviewPath}/strand/:strandId`;

// Growth
type ClassSkillsGrowthUrlParams = TeacherClassUrlParams & {
  strand?: string | undefined | null;
};
export const getClassSkillsGrowthBaseUrl = ({
  classId,
}: TeacherClassUrlParams) => `${teacherClassSkillsUrl({ classId })}/growth`;
export const getClassSkillsGrowthUrl = ({
  classId,
  strand,
}: ClassSkillsGrowthUrlParams) =>
  `${teacherClassSkillsUrl({ classId })}/growth/strand/${
    strand ? `${strand}` : 'all'
  }`;
export const classSkillsGrowthPath = `${teacherClassSkillsPath}/growth`;
export const classSkillsGrowthAllPath = `${teacherClassSkillsPath}/growth/strand/all`;
export const classSkillsGrowthStrandPath = `${classSkillsGrowthPath}/strand/:strandId`;

// Activity
type ClassSkillsActivityUrlParams = TeacherClassUrlParams;
export const getClassSkillsActivityUrl = ({
  classId,
}: ClassSkillsActivityUrlParams) =>
  `${teacherClassSkillsUrl({ classId })}/activity`;
export const classSkillsActivityPath = `${teacherClassSkillsPath}/activity`;

// Curriculum
type ClassSkillsCurriculumUrlParams = TeacherClassUrlParams & {
  strandId?: string | undefined | null;
  gradeId?: string | undefined | null;
  substrandIds?: string | undefined | null;
};
export const getClassSkillsCurriculumBaseUrl = ({
  classId,
}: TeacherClassUrlParams) => `${teacherClassSkillsUrl({ classId })}/curriculum`;
export const getClassSkillsCurriculumUrl = ({
  classId,
  gradeId,
  strandId,
  substrandIds,
}: ClassSkillsCurriculumUrlParams) => {
  return `${teacherClassSkillsUrl({ classId })}/curriculum/grade/${
    gradeId != null ? `${gradeId}` : 'all'
  }/strand/${strandId != null ? `${strandId}` : 'all'}/substrands/${
    substrandIds != null ? `${substrandIds}` : 'all'
  }`;
};
export const classSkillsCurriculumPath = `${teacherClassSkillsPath}/curriculum`;
// Enumerate all possible paths for the curriculum view
// we're starting to get into unmanageable x factorial numbers of permutations
export const classSkillsCurriculumAllPaths = [
  '/teacher/classes/:classId/skills/curriculum/grade/all/strand/all/substrands/all',
  '/teacher/classes/:classId/skills/curriculum/grade/all/strand/all/substrands/:substrandIds',
  '/teacher/classes/:classId/skills/curriculum/grade/all/strand/:strandId/substrands/all',
  '/teacher/classes/:classId/skills/curriculum/grade/all/strand/:strandId/substrands/:substrandIds',
  '/teacher/classes/:classId/skills/curriculum/grade/:gradeId/strand/all/substrands/all',
  '/teacher/classes/:classId/skills/curriculum/grade/:gradeId/strand/all/substrands/:substrandIds',
  '/teacher/classes/:classId/skills/curriculum/grade/:gradeId/strand/:strandId/substrands/all',
  '/teacher/classes/:classId/skills/curriculum/grade/:gradeId/strand/:strandId/substrands/:substrandIds',
] as const;

export type ClassSkillsView = 'overview' | 'growth' | 'activity' | 'curriculum';
type GetClassSkillsUrlFunction = (p: ClassSkillsOverviewUrlParams) => string;

export const classSkillsViewPath = `${teacherClassSkillsPath}/:view`;
export const classSkillsViewStrandPath = `${teacherClassSkillsPath}/:view/strand/:strandId`;
export const classSkillsViewGradeStrandPath = `${teacherClassSkillsPath}/:view/grade/:gradeId/strand/:strandId`;

type MakeClassSkillsUrlParams = {
  view?: ClassSkillsView | undefined;
};

export const makeClassSkillsUrl = ({
  view,
}: MakeClassSkillsUrlParams): GetClassSkillsUrlFunction => {
  if (view == null) return ({ classId }) => teacherClassSkillsUrl({ classId });
  switch (view) {
    case 'overview':
      return ({ classId, strandId }) =>
        getClassSkillsOverviewUrl({ classId, strandId });
    case 'growth':
      return ({ classId, strandId }) =>
        getClassSkillsGrowthUrl({ classId, strand: strandId });
    case 'activity':
      return ({ classId }) => getClassSkillsActivityUrl({ classId });
    case 'curriculum':
      return ({ classId, strandId, gradeId }) =>
        getClassSkillsCurriculumUrl({
          classId,
          strandId,
          gradeId,
        });
    default: {
      assertUnreachable(view, `Incorrect view for Class skills: ${view}`);
    }
  }
};

export const makeClassSkillsBaseUrl = ({
  view,
}: MakeClassSkillsUrlParams): GetClassSkillsUrlFunction => {
  if (view == null) return ({ classId }) => teacherClassSkillsUrl({ classId });
  switch (view) {
    case 'overview':
      return ({ classId }) => getClassSkillsOverviewBaseUrl({ classId });
    case 'growth':
      return ({ classId }) => getClassSkillsGrowthBaseUrl({ classId });
    case 'activity':
      return ({ classId }) => getClassSkillsActivityUrl({ classId });
    case 'curriculum':
      return ({ classId }) => getClassSkillsCurriculumBaseUrl({ classId });
    default: {
      assertUnreachable(view, `Incorrect view for Class skills: ${view}`);
    }
  }
};
