import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M15.421875,17.25 L4.509375,17.25 C4.00714106,17.25 3.6,16.8428589 3.6,16.340625 C3.6,13.3272213 6.07217546,11.2371259 9.08557912,11.2371259 L10.9043291,11.2371259 C13.9177328,11.2371259 16.33125,13.3272213 16.33125,16.340625 C16.33125,16.8428589 15.9241089,17.25 15.421875,17.25 Z M9.965625,2.7 C7.95668922,2.7 6.328125,4.32856422 6.328125,6.3375 C6.328125,8.34643578 7.95668922,9.975 9.965625,9.975 C11.9745608,9.975 13.603125,8.34643578 13.603125,6.3375 C13.603125,4.32856422 11.9745608,2.7 9.965625,2.7 L9.965625,2.7 Z" />
);

const Profile = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);
Profile.displayName = 'Profile';

export default Profile;
