import { css, cx } from '@emotion/css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import Icons from 'ms-components/icons';
import RelativeTimestamp from 'ms-components/time/RelativeTimestamp';
import { useSnowplow } from 'ms-helpers/Snowplow';
import AvatarCircle, {
  AVATAR_MARGIN,
  DEFAULT_AVATAR_SIZE_SMALL,
} from 'ms-pages/Avatar/AvatarCircle';
import { BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { breakPoints, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import type { Props as ButtonProps } from 'ms-ui-primitives/Button';
import Button from 'ms-ui-primitives/Button';
import LoadingSpinner from 'ms-ui-primitives/LoadingSpinner';
import Modal, {
  MODAL_LATERAL_PADDING,
  MODAL_VERTICAL_PADDING,
  ModalBody,
  ModalHeader,
} from 'ms-ui-primitives/Modal';
import { HStack, VStack } from 'ms-ui-primitives/Stack';
import { usePrevious } from 'ms-utils/hooks/usePrevious';
import extractNode from 'ms-utils/relay/extractNode';
import { assert } from 'ms-utils/typescript-utils';
import { settingsUrl } from 'ms-utils/urls';

import type {
  LeaderboardModal_student,
  LeaderboardModal_student$key,
} from './__generated__/LeaderboardModal_student.graphql';
import { DoubleChevronUpSvg } from '../DoubleChevronUpSvg';
import { TrophySvg } from '../TrophySvg';
import { formatOrdinals } from '../utils';

type LeaderboardEntry = NonNullable<
  LeaderboardModal_student['leaderboard']
>['currentWeek'][number];

dayjs.extend(advancedFormat);
dayjs.extend(weekOfYear);

const DISMISSED_NEW_WEEK_ALERT = 'mathspace:dismissedNewWeekAlert';

// Should be odd so that we can show the current user in the middle
const LEADERBOARD_ENTRIES_TO_SHOW = 5;

const LEADERBOARD_ENTRY_HEIGHT =
  AVATAR_MARGIN + DEFAULT_AVATAR_SIZE_SMALL + AVATAR_MARGIN;
const MODAL_BODY_PADDING = 20;
const SCROLL_VIEW_HEIGHT =
  MODAL_BODY_PADDING + LEADERBOARD_ENTRY_HEIGHT * LEADERBOARD_ENTRIES_TO_SHOW;

export function LeaderboardModal({
  isOpen,
  studentKey,
  onClose,
}: {
  isOpen: boolean;
  studentKey: LeaderboardModal_student$key;
  onClose: () => void;
}) {
  const student = useFragment(
    graphql`
      fragment LeaderboardModal_student on Student
      @argumentDefinitions(
        fetchLeaderboard: { type: "Boolean!" }
        numberOfClasses: { type: "Int!" }
        classId: { type: "ID" }
      ) {
        id
        leaderboard @include(if: $fetchLeaderboard) {
          currentWeek(classId: $classId) {
            points
            rank
            name
            avatarUrl
            studentId
          }
          previousWeek(classId: $classId) {
            points
            rank
            name
            avatarUrl
            studentId
          }
        }
        user {
          avatar
          firstName
          lastName
          points {
            current
          }
        }
        classes(first: $numberOfClasses) {
          edges {
            node {
              hasEnabledLeaderboard
            }
          }
        }
        leaderboardClass {
          class {
            title
            displayName
          }
        }
      }
    `,
    studentKey,
  );
  assert(student != null, 'student must be defined');

  const { id, leaderboard, classes, leaderboardClass } = student;
  const validLeaderboardClasses = extractNode(classes).filter(
    c => c.hasEnabledLeaderboard,
  );
  const classTitle =
    leaderboardClass != null
      ? leaderboardClass.class.displayName ?? leaderboardClass.class.title
      : null;

  const [showLastWeek, setShowLastWeek] = useState(false);
  const { trackStructEvent } = useSnowplow();
  // We need scrollElement in state so we know when to scroll
  const [scrollElement, setScrollElement] = useState<HTMLElement | null>(null);
  const [isScrolledToTop, setIsScrolledToTop] = useState(true);
  const prevIsScrolledToTop = usePrevious(isScrolledToTop);
  const scrollRef = useCallback(
    (element: HTMLElement | null) => setScrollElement(element),
    [],
  );

  const leaderboardEntries: LeaderboardEntry[] | null = useMemo(() => {
    const currWeek = leaderboard?.currentWeek ?? null;
    const prevWeek = leaderboard?.previousWeek ?? null;
    const weekInView = showLastWeek ? prevWeek : currWeek;

    if (weekInView == null) {
      return null;
    }

    const studentIndex = weekInView.findIndex(s => s.studentId === id);

    // Truncate the number of entries to only show enough entries to fill the
    // scroll view, with the current user in the middle, i.e. to protect the
    // identity of students with a much lower rank.
    const numEntries = Math.max(
      LEADERBOARD_ENTRIES_TO_SHOW,
      studentIndex + Math.ceil(LEADERBOARD_ENTRIES_TO_SHOW / 2),
    );

    return weekInView.slice(0, numEntries);
  }, [id, leaderboard?.currentWeek, leaderboard?.previousWeek, showLastWeek]);

  const studentPrevWeek = leaderboard?.previousWeek.find(
    s => s.studentId === id,
  );
  const studentPrevWeekRank = studentPrevWeek?.rank ?? null;
  const studentPrevWeekPoints = studentPrevWeek?.points ?? 0;
  const currUserIsTopStudent =
    studentPrevWeekRank != null && studentPrevWeekRank <= 3;
  const currWeekKey = dayjs().format('YYYY-w');

  const showNewWeekAlert = useMemo(() => {
    // Don't show the alert if we're showing the previous week
    if (showLastWeek) return false;

    // Don't show the alert if the student finished the last week unranked
    if (studentPrevWeekRank == null) return false;

    // Don't show the alert if the student already dismissed it this week
    const didDismissNewWeekAlertThisWeek =
      localStorage.getItem(DISMISSED_NEW_WEEK_ALERT) === currWeekKey;
    if (didDismissNewWeekAlertThisWeek) return false;

    // Don't show the alert from Wednesday onwards
    const isAfterTuesday = dayjs().day() > 2;
    if (isAfterTuesday) return false;

    // Show the alert
    return true;
  }, [currWeekKey, showLastWeek, studentPrevWeekRank]);

  // Scroll to the bottom of the leaderboard when the modal is opened and
  // whenever the week in view changes.
  useEffect(() => {
    if (isOpen && scrollElement != null) {
      scrollElement.scrollTo({ top: scrollElement.scrollHeight });
    }
  }, [isOpen, scrollElement, showLastWeek]);

  // Track the event when the student scrolls to the top of the leaderboard,
  // whether it be by clicking the CTA or by scrolling manually.
  useEffect(() => {
    if (
      isScrolledToTop &&
      !prevIsScrolledToTop &&
      (leaderboardEntries?.length ?? 0) > LEADERBOARD_ENTRIES_TO_SHOW
    ) {
      trackStructEvent({
        category: 'gamification',
        action: 'scrolled_to_top_of_leaderboard_modal',
        label: currWeekKey,
      });
    }
  }, [
    currWeekKey,
    isScrolledToTop,
    leaderboardEntries?.length,
    prevIsScrolledToTop,
    trackStructEvent,
  ]);

  function handleScroll(e: React.UIEvent<HTMLDivElement>) {
    setIsScrolledToTop(e.currentTarget.scrollTop === 0);
  }

  function handleShowLastWeek() {
    setShowLastWeek(true);
    localStorage.setItem(DISMISSED_NEW_WEEK_ALERT, currWeekKey);
  }

  function scrollToTop() {
    scrollElement?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const startOfWeekTimeJsx = useMemo(() => {
    const startOfWeek = dayjs().startOf('week');
    return (
      <RelativeTimestamp
        // NB key is important to make sure React doesn't reuse the DOM element
        // of the other timestamp when switching weeks
        key={startOfWeek.toDate().toISOString()}
        date={startOfWeek}
      />
    );
  }, []);

  const endOfWeekTimeJsx = useMemo(() => {
    const endOfWeek = dayjs().endOf('week');
    return (
      <RelativeTimestamp
        // NB key is important to make sure React doesn't reuse the DOM element
        // of the other timestamp when switching weeks
        key={endOfWeek.toDate().toISOString()}
        date={endOfWeek}
      />
    );
  }, []);

  return (
    <Modal isOpen={isOpen} width={500} onClose={onClose}>
      <ModalHeader>
        <div className={styles.modalHeaderInner}>
          <TrophySvg style={{ flexShrink: 0 }} />
          <div>
            {classTitle != null && (
              <h2 className={styles.modalTitle}>
                {validLeaderboardClasses.length > 1 ? (
                  <a href={settingsUrl} className={styles.modalTitleLink}>
                    {classTitle} <Icons.Pencil color={colors.grey90} />
                  </a>
                ) : (
                  classTitle
                )}
              </h2>
            )}

            <h3 className={styles.modalSubtitle}>
              {showLastWeek ? 'Last Week' : 'Weekly Leaderboard'}
            </h3>
            <div className={styles.modalMeta}>
              <span
                className={cx(styles.badge, showLastWeek && styles.badgeGrey)}
              >
                {showLastWeek ? 'Closed' : 'Open'}
              </span>
              <span
                className={cx(
                  styles.endDate,
                  showLastWeek && styles.endDateGrey,
                )}
              >
                {showLastWeek ? (
                  <>Ended {startOfWeekTimeJsx}</>
                ) : (
                  <>Ends {endOfWeekTimeJsx}</>
                )}
              </span>
            </div>
          </div>
        </div>
      </ModalHeader>

      {showNewWeekAlert && studentPrevWeekRank != null && (
        <div className={styles.modalAlert}>
          <BodyS center color="lochmara">
            {currUserIsTopStudent && studentPrevWeekPoints > 0 ? (
              <>
                You rocked last week and finished{' '}
                <strong>{formatOrdinals(studentPrevWeekRank)}</strong>!{' '}
                <AlertLink onClick={handleShowLastWeek}>
                  Check out the leaderboard
                </AlertLink>
                .
              </>
            ) : (
              <>
                New week, new leaderboard! You ranked{' '}
                <strong>{formatOrdinals(studentPrevWeekRank)}</strong> last
                week.{' '}
                <AlertLink onClick={handleShowLastWeek}>
                  See how you stacked up
                </AlertLink>
                .
              </>
            )}
          </BodyS>
        </div>
      )}

      <ModalBody>
        {leaderboardClass == null ? (
          <p style={{ textAlign: 'center' }}>
            The leaderboard is disabled for your classes
          </p>
        ) : leaderboardEntries == null ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <VStack className={styles.stack}>
            <div
              ref={scrollRef}
              className={styles.scrollView}
              onScroll={handleScroll}
            >
              {leaderboardEntries.map(s => {
                const isCurrUser = s.studentId === id;
                const hasPoints = s.points > 0;

                return (
                  <div
                    key={s.studentId}
                    className={cx(
                      styles.row,
                      isCurrUser && styles.rowIsHighlighted,
                    )}
                  >
                    <div
                      className={cx(
                        styles.rank,
                        // We check for non-zero points because we don't want
                        // students to be ranked unless and until they've
                        // started to participate by earning points.
                        s.rank === 1 && hasPoints && styles.rankIsFirst,
                        s.rank === 2 && hasPoints && styles.rankIsSecond,
                        s.rank === 3 && hasPoints && styles.rankIsThird,
                      )}
                    >
                      {hasPoints ? s.rank : '-'}
                    </div>
                    <div className={styles.avatarWrapper}>
                      <AvatarCircle
                        avatarUrl={s.avatarUrl}
                        isAvatarLoading={false}
                        size="small"
                      />
                    </div>
                    <div className={styles.nameWrapper}>
                      <div
                        className={styles.name}
                        style={{
                          // Allow for more characters by reducing the font size
                          // when the name is too long. We also utilise
                          // `textOverflow: ellipsis` CSS to truncate if even
                          // longer.
                          fontSize: s.name.length > 30 ? 14 : undefined,
                        }}
                      >
                        {isCurrUser ? `${s.name} (you)` : s.name}
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.points,
                        isCurrUser && styles.pointsAreHighlighted,
                      )}
                    >
                      {s.points} points
                    </div>
                  </div>
                );
              })}
            </div>

            <HStack
              center
              gap={8}
              justify="center"
              style={{ paddingBottom: MODAL_BODY_PADDING }}
            >
              {showLastWeek ? (
                <Button
                  type="secondary"
                  isRound
                  onClick={() => {
                    setShowLastWeek(false);
                    trackStructEvent({
                      category: 'gamification',
                      action: 'leaderboard_clicked_see_current_week',
                    });
                  }}
                >
                  View this week
                </Button>
              ) : (
                <Button
                  type="secondary"
                  isRound
                  onClick={() => {
                    handleShowLastWeek();
                    trackStructEvent({
                      category: 'gamification',
                      action: 'leaderboard_clicked_see_previous_week',
                    });
                  }}
                >
                  Show me last week
                </Button>
              )}

              <Button
                type="primary"
                isDisabled={
                  leaderboardEntries.length <= LEADERBOARD_ENTRIES_TO_SHOW ||
                  isScrolledToTop
                }
                isRound
                styles={{ gap: 8 }}
                onClick={scrollToTop}
              >
                <DoubleChevronUpSvg style={{ height: '1em', width: 'auto' }} />{' '}
                View podium
              </Button>
            </HStack>
          </VStack>
        )}
      </ModalBody>
    </Modal>
  );
}

function AlertLink(props: ButtonProps) {
  return (
    <Button
      color="lochmara"
      isInline
      styles={{ textDecoration: 'underline' }}
      {...props}
    />
  );
}

const styles = {
  modalHeaderInner: css({
    alignItems: 'center',
    display: 'flex',
    gap: 12,
    textAlign: 'left',
  }),
  modalTitle: css({
    fontFamily: fontFamily.heading,
    fontSize: 16,
    fontWeight: 800,
    lineHeight: 1.2,
  }),
  modalTitleLink: css({
    alignItems: 'center',
    color: 'inherit',
    display: 'inline-flex',
    gap: 4,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
  modalSubtitle: css({
    fontFamily: fontFamily.heading,
    fontSize: 24,
    fontWeight: 800,
    lineHeight: 1.2,
  }),
  modalMeta: css({
    alignItems: 'center',
    display: 'flex',
    fontSize: 14,
    gap: 6,
    marginTop: 4,
  }),
  badge: css({
    backgroundColor: '#00856b',
    borderRadius: 16,
    color: 'white',
    fontSize: 14,
    padding: '4px 12px',
  }),
  badgeGrey: css({
    backgroundColor: colors.grayChateau,
  }),
  endDate: css({
    color: colors.brickRed,
  }),
  endDateGrey: css({
    color: colors.grey10,
  }),
  modalAlert: css({
    backgroundColor: colors.tropicalBlue,
    color: colors.lochmara,
    marginTop: -1,
    padding: 8,

    strong: {
      fontWeight: fontWeight.semibold,
    },
  }),
  stack: css({
    color: colors.neutralGray,
    gap: 8,
    // Undo the default padding on ModalBody, so that spacing can be handled by
    // the children. The main reason for this is so that the scrollable area can
    // fill as much space as possible.
    margin: `-${MODAL_VERTICAL_PADDING}px -${MODAL_LATERAL_PADDING}px`,
  }),
  scrollView: css({
    height: SCROLL_VIEW_HEIGHT,
    overflowY: 'auto',
    padding: `${MODAL_BODY_PADDING}px ${MODAL_BODY_PADDING}px 0`,
    scrollbarWidth: 'thin',
  }),
  row: css({
    alignItems: 'center',
    borderRadius: 12,
    display: 'flex',
    gap: 17,
    padding: '8px 20px',
  }),
  rowIsHighlighted: css({
    backgroundColor: '#deedd8',
    color: '#00856b',
  }),
  rank: css({
    alignItems: 'center',
    display: 'flex',
    fontFamily: fontFamily.heading,
    fontSize: 20,
    fontWeight: 800,
    height: 32,
    justifyContent: 'center',
    width: 32,
  }),
  rankIsFirst: css({
    backgroundColor: colors.tangerine,
    borderRadius: '4px 4px 16px 16px',
    boxShadow: '0 4px #d4862b',
    color: 'white',
  }),
  rankIsSecond: css({
    backgroundColor: colors.shuttleGray,
    borderRadius: '4px 4px 16px 16px',
    boxShadow: '0 4px #3f4a58',
    color: 'white',
  }),
  rankIsThird: css({
    backgroundColor: '#de590f',
    borderRadius: '4px 4px 16px 16px',
    boxShadow: '0 4px #bc4402',
    color: 'white',
  }),
  avatarWrapper: css({
    display: 'none',
    margin: -AVATAR_MARGIN,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      display: 'block',
    },
  }),
  nameWrapper: css({
    flex: '1 1 0%',
    // Needed for text overflow to work
    overflow: 'hidden',
  }),
  name: css({
    fontFamily: fontFamily.heading,
    fontWeight: 800,
    overflow: 'hidden',
    // We need the name to be on a single line so that the height of the row is
    // predictable. This makes it easier for us to size the scrollable area so
    // that the current user is shown in the middle (assuming there are enough
    // entries).
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  points: css({
    color: colors.grey90,
    fontSize: 14,
  }),
  pointsAreHighlighted: css({
    color: 'inherit',
  }),
} as const;
