import type { ComponentType } from 'react';

import { useSnackbar } from '.';
import type { Message } from './Provider';

export type SnackbarContextType = {
  enqueueMessage: (message: Message) => void;
};

function getDisplayName(WrappedComponent: ComponentType<any>): string {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function withSnackbar<P extends Record<string, any>>(
  WrappedComponent: ComponentType<SnackbarContextType & P>,
): ComponentType<P> {
  function WithSnackbar(props: P) {
    const { enqueueMessage } = useSnackbar();
    return <WrappedComponent enqueueMessage={enqueueMessage} {...props} />;
  }

  WithSnackbar.displayName = `WithSnackbar(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithSnackbar;
}
