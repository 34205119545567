import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M17.1172414,2 L2.88275862,2 C2.3952245,2 2,2.3952245 2,2.88275862 L2,17.1172414 C2,17.6047755 2.3952245,18 2.88275862,18 L10.5517241,18 L10.5517241,11.8124138 L8.46896552,11.8124138 L8.46896552,9.39034483 L10.5517241,9.39034483 L10.5517241,7.60827586 C10.5517241,5.54206897 11.8151724,4.41655172 13.657931,4.41655172 C14.2789169,4.41519747 14.8995442,4.44650508 15.5172414,4.51034483 L15.5172414,6.67034483 L14.2482759,6.67034483 C13.2441379,6.67034483 13.0482759,7.14482759 13.0482759,7.84551724 L13.0482759,9.38758621 L15.4482759,9.38758621 L15.1365517,11.8096552 L13.0344828,11.8096552 L13.0344828,18 L17.1172414,18 C17.6047755,18 18,17.6047755 18,17.1172414 L18,2.88275862 C18,2.3952245 17.6047755,2 17.1172414,2 Z" />
);

const Facebook = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Facebook.displayName = 'Facebook';

export default Facebook;
