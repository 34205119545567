import moment from 'moment-timezone';
import { useEffect } from 'react';
import { graphql } from 'react-relay';

import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';
import useMutation from 'ms-utils/relay/useMutationWithArgs';

import type {
  useCreateStudentForSunflowerMutationResponse,
  useCreateStudentForSunflowerMutationVariables,
} from './__generated__/useCreateStudentForSunflowerMutation.graphql';

const mutation = graphql`
  mutation useCreateStudentForSunflowerMutation(
    $country: ID!
    $countrySubdivision: ID
    $parentEmail: String
    $parentNotifications: Boolean
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $schoolReference: ID
    $schoolTitle: String
    $timezone: String!
    $studentJoinCode: String
  ) {
    createStudentForSunflower(
      country: $country
      countrySubdivision: $countrySubdivision
      parentEmail: $parentEmail
      parentNotifications: $parentNotifications
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      schoolReference: $schoolReference
      schoolTitle: $schoolTitle
      timezone: $timezone
      studentJoinCode: $studentJoinCode
    ) {
      student {
        id
        user {
          snowplowId
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateStudentForSunflower(): UseMutationResponse<
  {},
  useCreateStudentForSunflowerMutationResponse
> {
  const { dispatch, state } = useSignupState();
  const [mutate, results] = useMutation<
    useCreateStudentForSunflowerMutationResponse,
    useCreateStudentForSunflowerMutationVariables
  >({
    mutation,
  });
  useEffect(() => {
    if (
      results.response &&
      results.response.createStudentForSunflower?.errors
    ) {
      dispatch({
        type: 'setErrors',
        value: results.response.createStudentForSunflower?.errors,
      });
    }
  }, [dispatch, results.response]);
  return [
    () => {
      mutate({
        parentEmail: state.parentEmail !== '' ? state.parentEmail : null,
        parentNotifications: state.parentEmailNotifications,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        password: state.password,
        timezone: moment.tz.guess(),
        countrySubdivision: state.countrySubdivision?.id,
        country: state.country?.id ?? state.location,
        studentJoinCode: state.studentJoinCode,
        // If there is a student join code, we don't need school references or titles
        // If there isn't one, prefer the school reference over the title
        ...(state.studentJoinCode != null
          ? {}
          : state.schoolReference != null
          ? { schoolReference: state.schoolReference }
          : { schoolTitle: state.schoolTitle }),
      });
    },
    results,
  ];
}
