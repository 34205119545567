import styled from '@emotion/styled';

import { fontWeight } from 'ms-styles/base';

const Bold = styled.div({
  display: 'inline',
  fontWeight: fontWeight.semibold,
});

export default Bold;
