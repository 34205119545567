import { StyleSheet, css } from 'aphrodite';
import type { ComponentType, MouseEvent as SyntheticMouseEvent } from 'react';

import type { Props as IconProps } from 'ms-components/icons/types';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { noop } from 'ms-utils/misc';

type Props = {
  href?: string | undefined;
  icon: ComponentType<IconProps>;
  isActive?: boolean | null | undefined;
  isDisabled?: boolean | null | undefined;
  onClick?: (event: SyntheticMouseEvent<any>) => void;
  tooltip?: string | undefined;
  iconSize?: number | string;
  trackingId?: string | undefined;
  target?: string | undefined;
};

export const ICON_COLOR = colors.nevada;
export const ICON_COLOR_ACTIVE = colors.lochmara;
export const ICON_COLOR_DISABLED = colors.iron;
export const ICON_GUTTER = 4 * BASE_UNIT;
export const ICON_SIZE = 4 * BASE_UNIT;
export const ICON_SIZE_LG = 6 * BASE_UNIT;

export const styles = StyleSheet.create({
  iconWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: `0 ${ICON_GUTTER}px`,
  },
});

const renderIcon = ({
  Icon,
  isDisabled,
  isActive,
  iconSize,
}: {
  Icon: ComponentType<IconProps>;
  isDisabled: boolean | null | undefined;
  isActive: boolean | null | undefined;
  iconSize: number | string;
}) => (
  <Icon
    color={
      isDisabled
        ? ICON_COLOR_DISABLED
        : isActive
        ? ICON_COLOR_ACTIVE
        : ICON_COLOR
    }
    size={iconSize}
  />
);

const IconButton = ({
  href,
  icon: Icon,
  isActive,
  isDisabled,
  onClick = noop,
  tooltip,
  iconSize = ICON_SIZE,
  trackingId,
  target,
}: Props) => (
  <a
    className={css(styles.iconWrapper)}
    href={href}
    onClick={isDisabled ? noop : onClick}
    data-tracking-id={trackingId}
    target={target}
  >
    {tooltip ? (
      <Tooltip title={tooltip}>
        {renderIcon({ isDisabled, isActive, Icon, iconSize })}
      </Tooltip>
    ) : (
      renderIcon({ isDisabled, isActive, Icon, iconSize })
    )}
  </a>
);

export default IconButton;
