import styled from '@emotion/styled';
import { useMemo } from 'react';

import Tooltip from 'ms-ui-primitives/TooltipNew';
import getTodaysHoliday from 'ms-utils/holidays';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import { MiloDynamic } from './MiloDynamic';
import miloCollecting from './assets/milo-collecting.svg';
import miloWaving from './assets/milo-waving.svg';
import miloWithFlag from './assets/milo-with-flag.svg';
import milo from './assets/milo.svg';
import miloLNYDragon from './assets/milo_LNY_dragon.svg';
import miloLNYRabbit from './assets/milo_LNY_rabbit.svg';
import miloAprilFools from './assets/milo_april_fools.svg';
import miloCelebrating from './assets/milo_celebrating.svg';
import miloEarthDay from './assets/milo_earth_day.svg';
import miloEaster from './assets/milo_easter.svg';
import miloEidAlFitr from './assets/milo_eid_al_fitr.svg';
import miloExplorer from './assets/milo_explorer.svg';
import miloFestive from './assets/milo_festive.svg';
import miloHalloween from './assets/milo_halloween.svg';
import miloHanukkah from './assets/milo_hanukkah.svg';
import miloOlympics2024 from './assets/milo_olympics_2024.svg';
import miloPencil from './assets/milo_pencil.svg';
import miloPi from './assets/milo_pi.svg';
import miloProfessor from './assets/milo_professor.svg';
import miloRamadan from './assets/milo_ramadan.svg';
import miloSaintPatrick from './assets/milo_saint_patrick.svg';
import miloPirate from './assets/milo_talk_like_a_pirate.svg';
import miloThanksgiving from './assets/milo_thanksgiving.svg';
import miloYouth from './assets/milo_youth.svg';
import type {
  HolidayVariant,
  PropsWithHoliday,
  VariantWithHoliday,
  Variant,
} from './types';

// Eye-picked value from Figma
const MILO_WITH_FLAG_RIGHT_OFFSET = -20;

const defaultHeights = (variant: VariantWithHoliday) => {
  switch (variant) {
    case 'explorer':
      return 168;
    default:
      return 136;
  }
};

const Img = styled('img', {
  shouldForwardProp: prop => prop !== 'variant',
})<{ variant: VariantWithHoliday }>(({ variant, height }) => {
  return {
    height: height ?? defaultHeights(variant),
    ...(variant === 'withFlag' && { marginRight: MILO_WITH_FLAG_RIGHT_OFFSET }),
  };
});

function mapVariantToSrc(variant: VariantWithHoliday): string {
  switch (variant) {
    case 'withFlag':
      return miloWithFlag;
    case 'waving':
      return miloWaving;
    case 'regular':
      return milo;
    case 'pencil':
      return miloPencil;
    case 'professor':
      return miloProfessor;
    case 'explorer':
      return miloExplorer;
    case 'celebrating':
      return miloCelebrating;
    case 'LNYrabbit':
      return miloLNYRabbit;
    case 'lunar-new-year-dragon':
      return miloLNYDragon;
    case 'collecting':
      return miloCollecting;
    case 'easter':
      return miloEaster;
    case 'halloween':
      return miloHalloween;
    case 'saint-patricks-day':
      return miloSaintPatrick;
    case 'april-fools-day':
      return miloAprilFools;
    case 'earth-day':
      return miloEarthDay;
    case 'ramadan':
      return miloRamadan;
    case 'pi-day':
      return miloPi;
    case 'talk-like-a-pirate-day':
      return miloPirate;
    case 'hanukkah':
      return miloHanukkah;
    case 'thanksgiving':
      return miloThanksgiving;
    case 'eid-al-fitr':
      return miloEidAlFitr;
    case 'youth-day':
      return miloYouth;
    case 'festive':
      return miloFestive;
    case 'olympics-2024':
      return miloOlympics2024;
    default:
      assertUnreachable(
        variant,
        `Unexpected variant: ${JSON.stringify(variant)}`,
      );
  }
}

export const miloSupportedHolidays = [
  'easter',
  'halloween',
  'saint-patricks-day',
  'april-fools-day',
  'earth-day',
  'ramadan',
  'pi-day',
  'talk-like-a-pirate-day',
  'hanukkah',
  'thanksgiving',
  'eid-al-fitr',
  'youth-day',
  'festive',
  'LNYrabbit',
  'lunar-new-year-dragon',
  'olympics-2024',
] as const;

function isSupportedHoliday(holiday: string | null): holiday is HolidayVariant {
  return (
    holiday !== null &&
    miloSupportedHolidays.find(h => h === holiday) !== undefined
  );
}

export const getMiloSupportedHoliday = () => {
  const todaysHoliday = getTodaysHoliday();
  return isSupportedHoliday(todaysHoliday) ? todaysHoliday : null;
};

const mapHolidayToTooltip = (holiday: HolidayVariant) => {
  switch (holiday) {
    case 'easter':
      return 'Easter Sunday';
    case 'halloween':
      return 'Halloween';
    case 'saint-patricks-day':
      return 'St. Patrick’s Day';
    case 'april-fools-day':
      return 'April Fool’s Day';
    case 'earth-day':
      return 'Earth Day';
    case 'ramadan':
      return 'Ramadan';
    case 'pi-day':
      return 'Pi Day';
    case 'talk-like-a-pirate-day':
      return 'Talk Like a Pirate Day';
    case 'hanukkah':
      return 'Hanukkah';
    case 'thanksgiving':
      return 'Thanksgiving Day';
    case 'eid-al-fitr':
      return 'Eid al-Fitr';
    case 'youth-day':
      return 'Youth Day';
    case 'festive':
      return 'Happy Holidays!';
    case 'LNYrabbit':
    case 'lunar-new-year-dragon':
      return 'Lunar New Year';
    case 'olympics-2024':
      return 'Olympics 2024';
    default:
      assertUnreachable(
        holiday,
        `Unexpected holiday: ${JSON.stringify(holiday)}`,
      );
  }
};

export default function Milo({
  variant = 'regular',
  dynamic,
  height,
  showHolidayMiloOnHoliday = false,
}: PropsWithHoliday) {
  const todaysHoliday = useMemo(() => {
    const _todaysHoliday = getMiloSupportedHoliday();
    // We are only overriding if showHolidayMiloOnHoliday prop is true
    // If showHolidayMiloOnHoliday is false, we will use the provided variant
    // regardless of whether it is a holiday or not
    return showHolidayMiloOnHoliday && _todaysHoliday != null
      ? _todaysHoliday
      : null;
  }, [showHolidayMiloOnHoliday]);

  // Holiday variants are not supported by dynamic Milo
  if (dynamic && todaysHoliday == null) {
    // Dangerous to use `as` here, but we know that `variant` is not a holiday at this point
    return <MiloDynamic variant={variant as Variant} height={height} />;
  }

  const currentVariant = todaysHoliday ?? variant;

  const miloImage = (
    <Img
      variant={currentVariant}
      src={mapVariantToSrc(currentVariant)}
      role="presentation"
      height={height}
    />
  );

  return todaysHoliday != null ? (
    <Tooltip content={<span>{mapHolidayToTooltip(todaysHoliday)}</span>}>
      {miloImage}
    </Tooltip>
  ) : (
    miloImage
  );
}
