import { useState, useCallback, useEffect } from 'react';

/*
 * Usage:
 * const ref = useScrollToBottomRef(() => alert('hit bottom!'))
 * ...
 * <div ref={ref} />
 */
export default function useScrollToBottomRef(onScrollToBottom: () => void) {
  const [node, setNode] = useState<HTMLElement | null>(null);
  const callbackRef = useCallback(
    (refNode: HTMLElement | null) => setNode(refNode),
    [setNode],
  );

  const scrollListener = useCallback(
    (e: Event) => {
      const target = e.target;
      if (target === null || !(target instanceof HTMLElement)) return;
      const { scrollTop, clientHeight, scrollHeight } = target;
      // scrollTop is a non-rounded number, while scrollHeight and clientHeight are rounded,
      // so the only way to determine if the scroll area is scrolled to the bottom is
      // by seeing if the scroll amount is close enough to 1 pixel from the bottom
      // see: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
      const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) <= 1;
      if (atBottom) onScrollToBottom();
    },
    [onScrollToBottom],
  );

  useEffect(() => {
    if (node !== null) {
      node.addEventListener('scroll', scrollListener);
    }
    return () => {
      if (node !== null) node.removeEventListener('scroll', scrollListener);
    };
  }, [node, scrollListener]);

  return callbackRef;
}
