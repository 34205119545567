/* eslint-disable react/no-danger */
import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';
import MathQuill, { type IStaticMath } from 'mathquill';
import { useRef, useEffect, memo } from 'react';
import 'mathquill/build/mathquill-mathspace.css';

import { borderRadiusUI, mathquillStyles } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { MATHQUILL_CONFIG } from 'ms-utils/misc/latexConfig/mathquill';

const BORDER_WIDTH = 1;

const styles = StyleSheet.create({
  mathField: {
    display: 'inline-block',
    // to ensure any mathField does not break
    minWidth: 'max-content',
  },
});

const mathFieldClass = css(styles.mathField);

if (!window.inSSR) {
  // Inject a style tag into the document head. We want to do
  // this synchronously, so that the style is present before
  // `latexToMathQuillHtml` is invoked.
  const styleTag = document.createElement('style');
  const cssText = document.createTextNode(
    `
    .${mathFieldClass} .mq-editable-field {
      border: none !important;
    }
    .${mathFieldClass} .mq-editable-field .mq-root-block {
      background: ${colors.athensGray} !important;
      border: ${BORDER_WIDTH}px solid ${colors.iron} !important;
      border-radius: ${borderRadiusUI}px !important;
      padding: ${mathquillStyles.padding.shorthand};
    }
  `,
  );
  styleTag.appendChild(cssText);
  if (document.head != null) document.head.appendChild(styleTag);
}

const Latex = memo(function Latex({
  aphroditeStyles = [],
  latex,
}: {
  aphroditeStyles?: CSSInputTypes[] | undefined;
  latex: string;
}) {
  const spanRef = useRef<HTMLSpanElement>(null);
  const staticMathRef = useRef<IStaticMath | null>(null);

  useEffect(() => {
    if (spanRef.current === null) return;
    staticMathRef.current = MathQuill.StaticMath(
      spanRef.current,
      MATHQUILL_CONFIG,
    );
  }, []);

  useEffect(() => {
    if (staticMathRef.current === null) return;
    staticMathRef.current.latex(latex);
  }, [latex]);

  if (window.inSSR) {
    const span = document.createElement('span');
    span.textContent = latex;
    MathQuill.StaticMath(span, MATHQUILL_CONFIG);
    return (
      <span
        className={`${mathFieldClass} ${css(...aphroditeStyles)}`}
        dangerouslySetInnerHTML={{
          __html: span.outerHTML,
        }}
      />
    );
  }

  return (
    <span
      className={`${mathFieldClass} ${css(...aphroditeStyles)}`}
      ref={spanRef}
    />
  );
});

export default Latex;
