import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Practice = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={17}
    viewBoxHeight={16}
  >
    <g clipPath="url(#clip0_3233_23868)">
      <path d="M12.9878 8.99942C12.7511 9.23608 12.7524 9.62008 12.9904 9.85808L13.2058 10.0734C13.2451 10.1127 13.2458 10.1774 13.2064 10.2167C13.1671 10.2561 13.1031 10.2561 13.0631 10.2161L12.5611 9.71408C12.3231 9.47608 11.9391 9.47542 11.7024 9.71142C11.4658 9.94808 11.4671 10.3321 11.7051 10.5701L12.2071 11.0721C12.2464 11.1114 12.2471 11.1761 12.2078 11.2154C12.1684 11.2547 12.1044 11.2547 12.0644 11.2147L11.2751 10.4254C11.0371 10.1874 10.6531 10.1867 10.4164 10.4227C10.1798 10.6594 10.1811 11.0434 10.4191 11.2814L11.2084 12.0707C11.2478 12.1101 11.2484 12.1747 11.2091 12.2141C11.1698 12.2534 11.1058 12.2534 11.0658 12.2134L8.26776 9.41542C8.02976 9.17742 7.64576 9.17675 7.40909 9.41275C7.17242 9.64942 7.17376 10.0334 7.41176 10.2714L10.7791 13.6387C10.3378 13.5701 9.59509 13.5407 9.13909 13.9961C8.71109 14.4241 8.72243 14.7201 8.85509 14.8534C9.21709 15.2154 9.49576 14.6434 11.1451 15.4314C12.7944 16.2201 13.8544 16.1547 14.5791 15.4421L15.5778 14.4434C15.5724 14.4487 15.5831 14.4381 15.5778 14.4434C16.5931 13.4281 16.8838 12.0401 15.8591 11.0161C15.8571 11.0141 15.8558 11.0127 15.8538 11.0107L13.8451 9.00208C13.6084 8.76408 13.2244 8.76342 12.9878 8.99942Z" />
      <path d="M13.1667 0H2.5C1.39533 0 0.5 0.895333 0.5 2C0.5 3.10467 1.39533 4 2.5 4H13.1667C14.2713 4 15.1667 3.10467 15.1667 2C15.1667 0.895333 14.2713 0 13.1667 0ZM2.5 2.66667C2.132 2.66667 1.83333 2.368 1.83333 2C1.83333 1.632 2.132 1.33333 2.5 1.33333C2.868 1.33333 3.16667 1.632 3.16667 2C3.16667 2.368 2.868 2.66667 2.5 2.66667Z" />
      <path d="M13.1667 5.33325H2.5C1.39333 5.33325 0.5 6.22659 0.5 7.33325C0.5 8.43992 1.39333 9.33325 2.5 9.33325H5.96667C6.05333 9.01325 6.22667 8.71325 6.46667 8.47325C7.22667 7.71325 8.45333 7.71325 9.21333 8.47325L9.9 9.15992C10.12 9.03325 10.3667 8.95325 10.62 8.92658C10.8873 8.57059 11.3527 8.28325 11.9067 8.21325C12.4873 7.43858 13.81 7.07658 14.9533 8.21992C15.0933 7.95325 15.1667 7.65325 15.1667 7.33325C15.1667 6.22659 14.2733 5.33325 13.1667 5.33325ZM2.5 7.99992C2.13333 7.99992 1.83333 7.69992 1.83333 7.33325C1.83333 6.96659 2.13333 6.66659 2.5 6.66659C2.86667 6.66659 3.16667 6.96659 3.16667 7.33325C3.16667 7.69992 2.86667 7.99992 2.5 7.99992Z" />
      <path d="M6.47333 11.2134C6.31333 11.0534 6.18 10.8667 6.08667 10.6667H2.5C1.39333 10.6667 0.5 11.5601 0.5 12.6667C0.5 13.7734 1.39333 14.6667 2.5 14.6667H7.44C7.44 14.0001 7.90067 13.2347 8.25333 13.0001L6.47333 11.2134ZM2.5 13.3334C2.13333 13.3334 1.83333 13.0334 1.83333 12.6667C1.83333 12.3001 2.13333 12.0001 2.5 12.0001C2.86667 12.0001 3.16667 12.3001 3.16667 12.6667C3.16667 13.0334 2.86667 13.3334 2.5 13.3334Z" />
    </g>
    <defs>
      <clipPath id="clip0_3233_23868">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);

export default Practice;
