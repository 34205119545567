export type HexString = string;
export type Rgba = [number, number, number, number];
export type RgbaString = string;

const parse16 = (s: HexString): number => parseInt(s, 16);
const to16 = (n: number): HexString => {
  const s16 = n.toString(16).toUpperCase();
  return s16.length === 1 ? `0${s16}` : s16;
};

const hexStringToRgba = (hexString: HexString, alpha: number): Rgba => [
  parse16(hexString.slice(1, 3)),
  parse16(hexString.slice(3, 5)),
  parse16(hexString.slice(5, 7)),
  alpha,
];

const rgbaToRgbaString = (rgba: Rgba): RgbaString => {
  const [r, g, b, alpha] = rgba;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const hexStringToRgbaString = (
  hexString: HexString,
  alpha: number = 1,
): RgbaString => rgbaToRgbaString(hexStringToRgba(hexString, alpha));

export const shadeHexString = (hexString: HexString, percent: number) => {
  const t = percent < 0 ? 0 : 255;
  const p = Math.abs(percent);
  const R = parse16(hexString.slice(1, 3));
  const G = parse16(hexString.slice(3, 5));
  const B = parse16(hexString.slice(5, 7));
  const newR = to16(Math.round((t - R) * p) + R);
  const newG = to16(Math.round((t - G) * p) + G);
  const newB = to16(Math.round((t - B) * p) + B);
  return `#${newR}${newG}${newB}`;
};
