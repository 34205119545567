/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentPasswordReset_student = {
    readonly temporaryPassword: string | null;
    readonly " $fragmentRefs": FragmentRefs<"ResetStudentTemporaryPasswordModal_student">;
    readonly " $refType": "StudentPasswordReset_student";
};
export type StudentPasswordReset_student$data = StudentPasswordReset_student;
export type StudentPasswordReset_student$key = {
    readonly " $data"?: StudentPasswordReset_student$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentPasswordReset_student">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentPasswordReset_student",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "temporaryPassword",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResetStudentTemporaryPasswordModal_student"
    }
  ],
  "type": "Student",
  "abstractKey": null
};
(node as any).hash = '01c7403e9b374ab61e0986e6055a2170';
export default node;
