import Pencil from 'ms-components/icons/Pencil';
import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

export const ReviewCard = styled({
  default: {
    border: `1px solid ${colors.iron}`,
    padding: 6 * BASE_UNIT,
    marginBottom: 3 * BASE_UNIT,
    borderRadius: borderRadiusUI,
  },
  error: {
    border: `1px solid ${colors.cinnabar}`,
  },
});

export const Title = styled({
  marginRight: 4 * BASE_UNIT,
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  lineHeight: lineHeight.body,
  fontWeight: fontWeight.semibold,
  marginBottom: 2 * BASE_UNIT,
});

export const Detail = styled({
  fontFamily: fontFamily.body,
  lineHeight: lineHeight.body,
  color: colors.santasGray,
  fontSize: fontSize.small,
});

export const DetailArea = styled({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
});

const PenciIconWrapper = styled({
  marginLeft: 2 * BASE_UNIT,
  marginRight: 2 * BASE_UNIT,
});

const Flipper = styled({
  transform: 'scaleX(-1)', // flip horizontally
});

export const ErrorMessage = styled({
  color: colors.cinnabar,
  fontFamily: fontFamily.ui,
  textAlign: 'right',
  fontSize: fontSize.small,
  marginBottom: 3 * BASE_UNIT,
});

type Props = {
  // This button will render differently if the underlying data has been "edited" in some way
  isFilled: boolean;
  onClick: () => void;
};

export const EditButton = (
  { onClick, isFilled }: Props, // todo check this
) => (
  <Button
    onClick={onClick}
    isRound
    type={isFilled ? 'primary' : 'secondary'}
    padding={0}
    size="small"
    label="Edit"
  >
    <PenciIconWrapper>
      <Flipper>
        <Pencil />
      </Flipper>
    </PenciIconWrapper>
  </Button>
);
