import { graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import {
  FeedbackComponent,
  FeedbackWrapper,
} from 'ms-components/Feedback/FeedbackComponent';
import PageNotFoundView from 'ms-components/PageNotFoundView';
import { LinkTab, TabContent, Tabs } from 'ms-components/Tabs';
import PageDescription from 'ms-helpers/PageDescription';
import PageTitle from 'ms-helpers/PageTitle';
import SEO from 'ms-pages/FreeTextbooks/seo';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import MajorSpinner from 'ms-pages/Teacher/components/MajorSpinner';
import Guide from 'ms-pages/Textbooks/components/ChapterIntroView/Guide';
import {
  breakPoints,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Stack from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import { getContentBrowsingEmptyViewUrl } from 'ms-utils/urls';

import TaskTemplateGroupList from './TaskTemplateGroupList';
import type {
  ChapterIntroViewQueryResponse,
  ChapterIntroViewQuery,
} from './__generated__/ChapterIntroViewQuery.graphql';
import DetailHeader from '../DetailHeader';

const COVER_WIDTH = 140;
const COVER_HEIGHT = 240;
const MainWrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
  paddingBottom: 12 * BASE_UNIT,
});
const Root = styled({
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const OverviewWrapper = styled({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 6 * BASE_UNIT,
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  color: colors.mako,
  lineHeight: lineHeight.body,
  whiteSpace: 'pre-wrap',
});
const OverviewContentWrapper = styled({
  display: 'flex',
  marginBottom: 12 * BASE_UNIT,
  fontSize: fontSize.medium,
  color: colors.shuttleGray,
  lineHeight: lineHeight.body,
});
const OverviewTitle = styled({
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.large,
  marginBottom: 4 * BASE_UNIT,
});
const TaskTemplateGroupWrapper = styled({
  marginBottom: 12 * BASE_UNIT,
});
const SyllabusCover = styled({
  flexShrink: 0,
  position: 'relative',
  width: COVER_WIDTH,
  height: COVER_HEIGHT,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom center',
  backgroundRepeat: 'no-repeat',
  boxSizing: 'content-box',
  borderRadius: 11,
  marginLeft: 8 * BASE_UNIT,
});

type Props = {
  syllabus: ChapterIntroViewQueryResponse['syllabus'];
};

type TextbookIntroTab = 'overview' | 'guide';
const ChapterIntroInner = ({ syllabus }: Props) => {
  // !!! This MUST be in sync with the tab names in `msproblem/interface/textbooks/sitemap.py` !!!
  const [textbookIntroActiveTab, , getTextbookIntroTabLink] =
    useQueryParam<TextbookIntroTab>(
      'textbookIntroActiveTab',
      'overview',
      (value): value is TextbookIntroTab =>
        value === 'overview' || value === 'guide',
    );
  const syllabusBackgroundUrl = syllabus ? syllabus.thumbnailImageUrl : '';
  const coverImageUrl = syllabus?.coverImageUrl;
  const overview = syllabus?.overview;
  if (overview == null) return <PageNotFoundView />;
  return (
    <Root
      className={{
        backgroundColor: colors.white,
      }}
    >
      <PageTitle subtitles={SEO.TEXTBOOK_INTRO.title(syllabus?.title ?? '')} />
      <PageDescription
        description={SEO.TEXTBOOK_INTRO.description(overview.description)}
      />
      <DetailHeader
        title="Introduction"
        subtitleContent={
          <BodyM color="grey90" bold>
            {syllabus?.title ?? ''}
          </BodyM>
        }
        coverImageUrl={coverImageUrl ?? ''}
        tabs={
          <Tabs borderWidth={0} v2>
            <LinkTab
              v2
              to={getTextbookIntroTabLink('overview')}
              active={textbookIntroActiveTab === 'overview'}
            >
              <TabContent>Overview</TabContent>
            </LinkTab>
            <LinkTab
              v2
              to={getTextbookIntroTabLink('guide')}
              active={textbookIntroActiveTab === 'guide'}
            >
              <TabContent>Guide</TabContent>
            </LinkTab>
          </Tabs>
        }
      />

      <MainWrapper>
        {textbookIntroActiveTab === 'overview' && (
          <OverviewWrapper>
            <OverviewContentWrapper>
              {overview.description}
              <SyllabusCover
                style={{
                  backgroundImage: `url("${syllabusBackgroundUrl}")`,
                }}
              />
            </OverviewContentWrapper>
            <TaskTemplateGroupWrapper>
              <OverviewTitle>Task templates</OverviewTitle>
              <TaskTemplateGroupList
                templateGroups={overview.taskTemplateGroups}
              />
            </TaskTemplateGroupWrapper>
          </OverviewWrapper>
        )}
        {textbookIntroActiveTab === 'guide' && <Guide />}

        <Stack.Spacer.V height={40} />

        <FeedbackWrapper>
          <FeedbackComponent feedbackType="GENERAL" />
        </FeedbackWrapper>
      </MainWrapper>
    </Root>
  );
};
const query = graphql`
  query ChapterIntroViewQuery($syllabusId: ID!) {
    syllabus(id: $syllabusId) {
      id
      title
      thumbnailImageUrl
      coverImageUrl
      overview {
        description
        taskTemplateGroups {
          ...TaskTemplateGroupList_templateGroups
        }
      }
    }
  }
`;
type ExternalProps = {
  syllabusId: string;
};
const ChapterIntro = ({ syllabusId }: ExternalProps) => {
  const { props, error } = useQuery<ChapterIntroViewQuery>(
    query,
    { syllabusId },
    {
      networkCacheConfig: {
        // @ts-expect-error
        // I don't trust the TS relay types, I don't think they are accurate
        // for our version so leaving this here until we update to latest
        // version of relay.
        useCache: true,
        ttl: Infinity,
      },
    },
  );
  if (error != null) throw error;
  if (props == null) return <MajorSpinner />;
  if (props.syllabus != null && props.syllabus.overview == null)
    return <Redirect to={getContentBrowsingEmptyViewUrl({ syllabusId })} />;
  return <ChapterIntroInner syllabus={props.syllabus} />;
};
export default ChapterIntro;
