import { useMemo } from 'react';

import Panko from 'ms-components/Panko';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const PankoContainer = styled({
  marginBottom: 6 * BASE_UNIT,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
});

export default function ProgressStepper({ step }: { step: number }) {
  return (
    <PankoContainer>
      <Panko steps={useStepArray(step, 3)} />
    </PankoContainer>
  );
}

function useStepArray(stepCount: number, totalSteps: number) {
  const instanceArray = useMemo(
    () =>
      new Array(totalSteps).fill({
        active: false,
        completed: false,
      }),
    [totalSteps],
  );

  return useMemo(
    () =>
      instanceArray.map((_instance, index) => ({
        active: index === stepCount - 1,
        completed: index < stepCount - 1,
      })),
    [instanceArray, stepCount],
  );
}

export { ProgressStepper };
