import { useContext, useEffect } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';

type Props = {
  isOpen: boolean;
  message: string;
  href?: string | undefined;
  actionLabel?: string | undefined;
  persistent?: boolean | undefined;
  dismissable?: boolean | undefined;
};

export default function SnackbarComponent({
  isOpen,
  message,
  href,
  actionLabel,
  persistent,
  dismissable,
}: Props) {
  const { enqueueMessage } = useContext(SnackbarContext);
  useEffect(
    () => {
      if (isOpen)
        enqueueMessage({
          text: message,
          href,
          actionLabel,
          persistent,
          dismissable,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueMessage, isOpen],
  );

  return null;
}
