/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type LanguageSelectorContextTypeGeneratorQueryVariables = {
    id: string;
    locale?: SyllabusLocale | null | undefined;
};
export type LanguageSelectorContextTypeGeneratorQueryResponse = {
    readonly subtopic: {
        readonly id: string;
    } | null;
};
export type LanguageSelectorContextTypeGeneratorQuery = {
    readonly response: LanguageSelectorContextTypeGeneratorQueryResponse;
    readonly variables: LanguageSelectorContextTypeGeneratorQueryVariables;
};



/*
query LanguageSelectorContextTypeGeneratorQuery(
  $id: ID!
  $locale: SyllabusLocale
) {
  subtopic(id: $id, syllabusLocale: $locale) {
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "syllabusLocale",
        "variableName": "locale"
      }
    ],
    "concreteType": "Subtopic",
    "kind": "LinkedField",
    "name": "subtopic",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LanguageSelectorContextTypeGeneratorQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LanguageSelectorContextTypeGeneratorQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7bd416e627782d56d1588ec59332b2ef",
    "id": null,
    "metadata": {},
    "name": "LanguageSelectorContextTypeGeneratorQuery",
    "operationKind": "query",
    "text": "query LanguageSelectorContextTypeGeneratorQuery(\n  $id: ID!\n  $locale: SyllabusLocale\n) {\n  subtopic(id: $id, syllabusLocale: $locale) {\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '69fbe2349b060566fee45b6648d7d3e8';
export default node;
