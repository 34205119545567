import type {
  Title,
  DisplayName,
  Teacher,
  Textbook,
  SyllabusFocus,
  ClassGrade,
  Updater,
} from './State';
import { addItem, removeItem } from '../utils';

export const updateTitle: Updater<[Title]> = title => state => ({
  ...state,
  title,
});

export const updateDisplayName: Updater<[DisplayName]> =
  displayName => state => ({
    ...state,
    displayName,
  });

export const updateSyllabusFocus: Updater<[SyllabusFocus]> =
  syllabusFocus => state => ({
    ...state,
    syllabusFocus,
  });

export const updateClassGrade: Updater<[ClassGrade]> = classGrade => state => ({
  ...state,
  classGrade,
});

export const addTextbook: Updater<[Textbook]> = textbook => state => ({
  ...state,
  textbooks: addItem({ items: state.textbooks, item: textbook }),
});

export const removeTextbook: Updater<[Textbook['id']]> =
  textbookId => state => ({
    ...state,
    textbooks: removeItem({ items: state.textbooks, itemId: textbookId }),
  });

export const addTeacher: Updater<[Teacher]> = teacher => state => ({
  ...state,
  teachers: addItem({ items: state.teachers, item: teacher }),
});

export const removeTeacher: Updater<[Textbook['id']]> = teacherId => state => ({
  ...state,
  teachers: removeItem({ items: state.teachers, itemId: teacherId }),
});

export const toggleApplyClassSyllabusFocus: Updater<[]> = () => state => ({
  ...state,
  applyClassSyllabusFocus: !state.applyClassSyllabusFocus,
});

export const toggleApplyClassGrade: Updater<[]> = () => state => ({
  ...state,
  applyClassGrade: !state.applyClassGrade,
});

export const toggleUseTitleForDisplayName: Updater<[]> = () => state => ({
  ...state,
  useTitleForDisplayName: !state.useTitleForDisplayName,
});

export const toggleShowLeaderboardToStudents: Updater<[]> = () => state => ({
  ...state,
  showLeaderboardToStudents: !state.showLeaderboardToStudents,
});
