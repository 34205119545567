import moment from 'moment';

export const formatQuestionDuration = (
  durationSeconds: number,
  isShort?: boolean | undefined,
): string => {
  return durationSeconds < 60
    ? isShort
      ? '< 1min'
      : 'Less than a minute'
    : isShort
    ? `${Math.floor(durationSeconds / 60)}min`
    : `Approx ${moment.duration(durationSeconds, 'seconds').humanize()}`;
};

export const formatTaskDuration = (durationSeconds: number): string => {
  const momentized = moment.duration(durationSeconds, 'seconds');
  const minutes = momentized.minutes();
  const hours = momentized.hours();
  return `${hours > 0 ? `${hours}h ` : ''}${minutes}m`;
};

export const getStartOfWeek = (date?: string): string => {
  // Returns the start of the week
  // We need to make sure that the start of the week is on Sunday to keep it consistent with the leaderboard.
  // See msproblem/accounts/rewards/managers:WeeklyRewardsManager.current_week_lookup
  const momentized = date != null ? moment(date) : moment();
  return momentized
    .startOf('week') // set to the first day of this week, 12:00 am
    .day(0) // set to this Sunday (0)
    .format();
};
