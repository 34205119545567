/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaskDetail_customTask = {
    readonly assignedProblems: ReadonlyArray<{
        readonly problemContent: {
            readonly id: string;
            readonly estimatedCompletionTime: number;
            readonly problemTemplateId: string;
            readonly previewWorkoutCreationToken: string;
        };
    }>;
    readonly " $refType": "TaskDetail_customTask";
};
export type TaskDetail_customTask$data = TaskDetail_customTask;
export type TaskDetail_customTask$key = {
    readonly " $data"?: TaskDetail_customTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskDetail_customTask">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskDetail_customTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AssignedProblem",
      "kind": "LinkedField",
      "name": "assignedProblems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProblemContent",
          "kind": "LinkedField",
          "name": "problemContent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedCompletionTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "problemTemplateId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "previewWorkoutCreationToken",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomTask",
  "abstractKey": null
};
(node as any).hash = 'c709457670fd5112c10c23022c743f90';
export default node;
