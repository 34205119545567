import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useSnowplow } from 'ms-helpers/Snowplow';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useValidateSunflowerUserDetails from 'ms-pages/Signup/Sunflower/useValidateSunflowerUserDetails';
import { getSunflowerSchoolSelectionUrl } from 'ms-utils/urls';

import { FooterLinks } from './FooterLinks';
import { ProgressStepper } from './ProgressStepper';
import { UserForm } from './UserForm';

export default function ParentSignup() {
  const { trackStructEvent } = useSnowplow();
  const { state, dispatch } = useSignupState();
  const trackingIdPrefix = 'SignupPage/ParentSignup';

  const [validateUserDetails, { response: validationResponse }] =
    useValidateSunflowerUserDetails();

  const { push } = useHistory();

  useEffect(() => {
    const errors = validationResponse?.validateSunflowerUserDetails?.errors;
    if (validationResponse != null && errors?.length === 0) {
      push(
        getSunflowerSchoolSelectionUrl({
          origin: 'parent',
        }),
      );
    }
  }, [push, state.ageConfirmed, validationResponse]);

  return (
    <>
      <ProgressStepper step={2} />
      <UserForm
        role="parent"
        title="Enter student details"
        firstName={state.firstName}
        lastName={state.lastName}
        parentEmail={state.parentEmail}
        parentEmailNotifications={state.parentEmailNotifications}
        email={state.email}
        password={state.password}
        ageConfirmed={state.ageConfirmed ?? false}
        showAgeConfirmed={false}
        onFirstNameChange={value => dispatch({ type: 'setFirstName', value })}
        onLastNameChange={value => dispatch({ type: 'setLastName', value })}
        onParentEmailChange={value =>
          dispatch({ type: 'setParentEmail', value })
        }
        onParentEmailNotificationsChange={value =>
          dispatch({ type: 'setParentEmailNotifications', value })
        }
        onEmailChange={value => dispatch({ type: 'setEmail', value })}
        onPasswordChange={value => dispatch({ type: 'setPassword', value })}
        onAgeConfirmedChange={value =>
          dispatch({ type: 'setAgeConfirmed', value })
        }
        isDisabled={
          state.parentEmail === '' ||
          state.firstName === '' ||
          state.lastName === '' ||
          state.email === '' ||
          state.password === ''
        }
        onSubmit={() => {
          trackStructEvent({
            category: 'signup_sunflower',
            action: 'submitted_account_details',
            label: 'parent',
          });
          dispatch({ type: 'clearErrors' });
          validateUserDetails({});
        }}
        submitButtonLabel="Create student account"
        errorMessage={
          state.hasErrors ? 'Please correct the errors to continue.' : undefined
        }
        errors={state.responseErrors}
        showDisclaimer
        trackingIdPrefix={trackingIdPrefix}
      />
      <FooterLinks
        showLoginLink={false}
        showJoinClassLink={false}
        trackingIdPrefix={trackingIdPrefix}
      />
    </>
  );
}
