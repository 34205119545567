import type { ReactNode } from 'react';

import { VStack } from 'ms-pages/Lantern/primitives/Stack';

export default function ModalContent({
  children,
  isCentered,
}: {
  children: ReactNode;
  isCentered?: boolean;
}) {
  return (
    <VStack
      css={[
        { padding: '40px 40px 0 40px' },
        isCentered
          ? {
              alignItems: 'center',
              textAlign: 'center',
            }
          : {},
      ]}
    >
      {children}
    </VStack>
  );
}
