/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AssignedClassPicker_assignedStudentsClasses = ReadonlyArray<{
    readonly id: string;
    readonly title: string;
    readonly " $refType": "AssignedClassPicker_assignedStudentsClasses";
}>;
export type AssignedClassPicker_assignedStudentsClasses$data = AssignedClassPicker_assignedStudentsClasses;
export type AssignedClassPicker_assignedStudentsClasses$key = ReadonlyArray<{
    readonly " $data"?: AssignedClassPicker_assignedStudentsClasses$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AssignedClassPicker_assignedStudentsClasses">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AssignedClassPicker_assignedStudentsClasses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Class",
  "abstractKey": null
};
(node as any).hash = '4a6024c5e8231c446bcb3fb43041f21e';
export default node;
