import Lottie from 'react-lottie';

import { assertUnreachable } from 'ms-utils/typescript-utils';

import miloAdventurer from './assets/lottie/milo-adventurer.json';
import type { AnimatedProps as Props, AnimatedVariant } from './types';

function mapVariantToSrc(variant: AnimatedVariant): any {
  switch (variant) {
    case 'adventurer':
      return miloAdventurer;
    default:
      assertUnreachable(
        variant,
        `Unexpected variant in AnimatedMilo: ${JSON.stringify(variant)}`,
      );
  }
}
export default function MiloAnimated({ variant, width, height }: Props) {
  return (
    <Lottie
      width={width}
      height={height}
      options={{
        autoplay: true,
        loop: true,
        animationData: mapVariantToSrc(variant),
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
    />
  );
}
