import { type ComponentType, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import DnDCustomDragLayer from 'ms-components/DnDCustomDragLayer';

const PORTAL_NODE_ID = 'mathspace-dnd-portal';

/**
 * DnDRoot is a HOC that appends a custom drag layer portal onto the document
 * body. This allows the portal to sit above any other layers in the app even
 * when the react component is mounted close to the leaves of the DOM tree.
 * react-dnd will render drag previews into this portal as you drag items around
 */
const DnDRoot = (ComposedComponent: ComponentType<any>): ComponentType<any> => {
  return function Component(props) {
    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
      null,
    );

    // The reason we don't have an effect cleanup to remove the portalNode is
    // because there can be multiple DnDRoot's rendered on a page. Only once all
    // of them have been unmounted, would it be safe to remove the portalNode.
    // This requires a bunch of coordination to do robustly, so for simplicity
    // we're just leaving this element on the page.
    useEffect(() => {
      let portalNode = document.getElementById(PORTAL_NODE_ID);
      if (!portalNode) {
        portalNode = document.createElement('div');
        portalNode.id = PORTAL_NODE_ID;
        if (document.body) document.body.appendChild(portalNode);
        setPortalContainer(portalNode);
      }
    }, []);

    const portal =
      portalContainer != null
        ? createPortal(<DnDCustomDragLayer />, portalContainer)
        : null;

    return (
      <>
        <ComposedComponent {...props} />
        {portal}
      </>
    );
  };
};

export default DnDRoot;
