/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionState = "ACTIVE" | "CANCELED" | "INCOMPLETE" | "INCOMPLETE_EXPIRED" | "PAST_DUE" | "TRIALING" | "UNPAID";
export type AddOnSubscriptionDetails_subscription = ReadonlyArray<{
    readonly addOnKey: string;
    readonly startDate: string;
    readonly endDate: string | null;
    readonly cancelAt: string | null;
    readonly subscriptionState: SubscriptionState;
    readonly stripeSubscriptionId: string | null;
    readonly " $refType": "AddOnSubscriptionDetails_subscription";
}>;
export type AddOnSubscriptionDetails_subscription$data = AddOnSubscriptionDetails_subscription;
export type AddOnSubscriptionDetails_subscription$key = ReadonlyArray<{
    readonly " $data"?: AddOnSubscriptionDetails_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AddOnSubscriptionDetails_subscription">;
}>;



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AddOnSubscriptionDetails_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addOnKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cancelAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stripeSubscriptionId",
      "storageKey": null
    }
  ],
  "type": "AddOnSubscription",
  "abstractKey": null
};
(node as any).hash = '4915d64b8fda194e83468db2fbdbb7de';
export default node;
