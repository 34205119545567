/**
 * Theme styles for the /Work page.
 */
export const colors = Object.freeze({
  astronaut: '#26276D',
});

export const hoverColors = Object.freeze({
  astronaut: '#202168',
});

export const fontSize = Object.freeze({
  xxlarge: 24,
  xlarge: 22,
  large: 20,
  default: 16,
  small: 14,
  xsmall: 12,
});

/**
 * Some of these styles are copy-paste from base styles.
 * Once we start to use Proxima font, these will need to be udpated.
 */
export const fontFamily = Object.freeze({
  body: '"Proxima Nova", Helvetica, Arial, sans-serif',
});

export const lineHeight = Object.freeze({
  body: 1.375,
  heading: 1.125,
});
