/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LeaderboardRefetchQueryVariables = {
    fetchLeaderboard: boolean;
    numberOfClasses: number;
    classId?: string | null | undefined;
    topN?: number | null | undefined;
};
export type LeaderboardRefetchQueryResponse = {
    readonly viewer: {
        readonly student: {
            readonly " $fragmentRefs": FragmentRefs<"Leaderboard_student">;
        } | null;
    };
};
export type LeaderboardRefetchQuery = {
    readonly response: LeaderboardRefetchQueryResponse;
    readonly variables: LeaderboardRefetchQueryVariables;
};



/*
query LeaderboardRefetchQuery(
  $fetchLeaderboard: Boolean!
  $numberOfClasses: Int!
  $classId: ID
  $topN: Int
) {
  viewer {
    student {
      ...Leaderboard_student_1rMwew
      id
    }
    id
  }
}

fragment Leaderboard_student_1rMwew on Student {
  id
  leaderboard @include(if: $fetchLeaderboard) {
    currentWeek(classId: $classId, topN: $topN) {
      points
      rank
      name
      avatarUrl
      studentId
    }
  }
  user {
    avatar
    firstName
    lastName
    points {
      current
    }
    id
  }
  classes(first: $numberOfClasses) {
    edges {
      node {
        hasEnabledLeaderboard
        displayName
        id
        title
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "classId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchLeaderboard"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfClasses"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topN"
},
v4 = {
  "kind": "Variable",
  "name": "classId",
  "variableName": "classId"
},
v5 = {
  "kind": "Variable",
  "name": "topN",
  "variableName": "topN"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LeaderboardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Student",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v4/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "fetchLeaderboard",
                    "variableName": "fetchLeaderboard"
                  },
                  {
                    "kind": "Variable",
                    "name": "numberOfClasses",
                    "variableName": "numberOfClasses"
                  },
                  (v5/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "Leaderboard_student"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "LeaderboardRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Student",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PointsObject",
                    "kind": "LinkedField",
                    "name": "points",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "current",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "numberOfClasses"
                  }
                ],
                "concreteType": "ClassConnection",
                "kind": "LinkedField",
                "name": "classes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClassEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Class",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasEnabledLeaderboard",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "condition": "fetchLeaderboard",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Leaderboard",
                    "kind": "LinkedField",
                    "name": "leaderboard",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "concreteType": "CurrentWeekLeaderboardUser",
                        "kind": "LinkedField",
                        "name": "currentWeek",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "points",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rank",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatarUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "studentId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "50b6d5142475eed0a04cd9b126ea7bcb",
    "id": null,
    "metadata": {},
    "name": "LeaderboardRefetchQuery",
    "operationKind": "query",
    "text": "query LeaderboardRefetchQuery(\n  $fetchLeaderboard: Boolean!\n  $numberOfClasses: Int!\n  $classId: ID\n  $topN: Int\n) {\n  viewer {\n    student {\n      ...Leaderboard_student_1rMwew\n      id\n    }\n    id\n  }\n}\n\nfragment Leaderboard_student_1rMwew on Student {\n  id\n  leaderboard @include(if: $fetchLeaderboard) {\n    currentWeek(classId: $classId, topN: $topN) {\n      points\n      rank\n      name\n      avatarUrl\n      studentId\n    }\n  }\n  user {\n    avatar\n    firstName\n    lastName\n    points {\n      current\n    }\n    id\n  }\n  classes(first: $numberOfClasses) {\n    edges {\n      node {\n        hasEnabledLeaderboard\n        displayName\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'abdb773e4a094cc4625677e3f92064b0';
export default node;
