import styled from '@emotion/styled';
import { css, StyleSheet } from 'aphrodite';
import React from 'react';
import type { ComponentType, ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';

import Layout from 'ms-components/Layout/Layout';
import { HEADER_SIZE } from 'ms-components/curriculum-mapping/Header';
import ArrowLeftIcon from 'ms-components/icons/ArrowLeft';
import ChevronLeftIcon from 'ms-components/icons/ChevronLeft';
import FlyoutToggleIcon from 'ms-components/icons/FlyoutToggle';
import MathspaceLogo from 'ms-components/logos/Logo';
import FreeTextbooksCTANavbar from 'ms-pages/FreeTextbooks/FreeTextbooksCTANavbar';
import {
  HORIZONTAL_PADDING as TOPIC_SIDEBAR_LATERAL_PADDING,
  MAX_SCREEN_WIDHT_FOR_FULL_SCREEN_FLYOUT,
  MIN_SCREEN_WIDTH_FOR_SIDEBAR,
} from 'ms-pages/FreeTextbooks/Layout/constants';
import CollapsibleSidebar from 'ms-pages/Teacher/components/CollapsibleSidebar';
import Flyout from 'ms-pages/Teacher/components/Flyout';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { useRouter } from 'ms-utils/hooks/useRouter';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

import {
  freeTextbooksEmptyViewPath,
  freeTextbooksRootSelector,
  freeTextbooksSubtopicDetailViewPath,
  freeTextbooksTextbookSelectorPath,
  freeTextbooksTopicEmptyViewPath,
} from '../urls';

const ASIDE_WIDTH = 240;
const ASIDE_PEAK = 24;

type Props = {
  children: ReactNode;
  LeftAside: ComponentType<any> | null | undefined;
};

export const DesktopOnly = styled.div({
  [`@media(max-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR}px)`]: {
    display: 'none',
  },
});

const MobileTopBar = styled.div({
  height: HEADER_SIZE,
  paddingTop: 16,
  paddingBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [`@media(min-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR + 1}px)`]: {
    display: 'none',
  },
});

const TopBarLeftAligned = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
});

const FlyoutCloserWrapper = styled.div({
  paddingLeft: TOPIC_SIDEBAR_LATERAL_PADDING,
  paddingTop: 8,
});

const styles = StyleSheet.create({
  Layout: {
    // This has an annoying side effect that manually shrinking or growing
    // the page makes this transition in and out. Ok as an edge case but still
    // kind of annoying
    // In the event that the left sidebar is closed and the screen size is reduced,
    // this media query will reset the offsets so the content isn't off screen
    [`@media(max-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR}px)`]: {
      left: 0,
      width: '100%',
    },
  },
  Aside: {
    backgroundColor: colors.seashell,
    [`@media(max-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR}px)`]: {
      display: 'none',
    },
  },
  InPageAsideControls: {
    [`@media(min-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR + 1}px)`]: {
      display: 'none',
    },
  },
  TopBar: {
    [`@media(min-width: ${MIN_SCREEN_WIDTH_FOR_SIDEBAR + 1}px)`]: {
      display: 'none',
    },
  },
});

const FlyoutContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  position: 'relative',
  backgroundColor: colors.seashell,
});

const FlyoutCloseButtonStyle = {
  button: {
    color: colors.eggplant,
  },
};

export default function FreeTextbooksLayout({ children, LeftAside }: Props) {
  const [isSidebarCollapsed, setSidebarCollapsed] = useLocalSyncedState({
    dbKey: JSON.stringify({ page: 'FREE_TEXTBOOK' }),
    fallbackValue: false,
  });

  const collapseLeftSidebar = React.useCallback(() => {
    setSidebarCollapsed(true);
  }, [setSidebarCollapsed]);
  const expandLeftSidebar = React.useCallback(() => {
    setSidebarCollapsed(false);
  }, [setSidebarCollapsed]);

  const { history } = useRouter();

  const [isLeftFlyoutOpen, setLeftFlyoutOpen] = React.useState(false);

  const closeFlyout = React.useCallback(() => {
    setLeftFlyoutOpen(false);
  }, []);

  const toggleFlyout = React.useCallback(() => {
    setLeftFlyoutOpen(o => !o);
  }, []);

  const windowSize = useWindowSize();
  const windowWidth =
    typeof windowSize.width === 'number' ? windowSize.width : 0;

  const shouldRenderFullScreenFlyout =
    windowWidth <= MAX_SCREEN_WIDHT_FOR_FULL_SCREEN_FLYOUT;

  return (
    <Layout direction="column">
      <Layout.Fixed size={HEADER_SIZE} className={css(styles.TopBar)}>
        <MobileTopBar>
          <div
            style={{ width: '33%', marginRight: 'auto' }}
            className={css(styles.InPageAsideControls)}
          >
            <TopBarLeftAligned>
              <Route
                path={[
                  freeTextbooksEmptyViewPath,
                  freeTextbooksSubtopicDetailViewPath,
                  freeTextbooksTopicEmptyViewPath,
                ]}
                exact
                render={({ match: { url } }) =>
                  url === freeTextbooksRootSelector ? null : (
                    <Button
                      size="large"
                      color="cloudBurst"
                      onClick={toggleFlyout}
                      label="Open topic tree"
                    >
                      <FlyoutToggleIcon size={24} />
                    </Button>
                  )
                }
              />
            </TopBarLeftAligned>
          </div>
          <Route
            exact
            path={freeTextbooksTextbookSelectorPath}
            render={() => (
              <TopBarLeftAligned>
                {history.length > 1 && (
                  <Button
                    size="large"
                    color="cloudBurst"
                    label="Open topic tree"
                    onClick={history.goBack}
                  >
                    <ArrowLeftIcon size={24} />
                  </Button>
                )}
              </TopBarLeftAligned>
            )}
          />
          <AnchorButton label="Home page" href={freeTextbooksRootSelector}>
            <MathspaceLogo />
          </AnchorButton>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 20,
              width: '33%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Switch>
              <Route
                path={freeTextbooksRootSelector}
                render={() => (
                  <FreeTextbooksCTANavbar
                    hideAuthbuttons
                    context="curriculum_selector"
                  />
                )}
              />
              <Route
                path={[
                  freeTextbooksEmptyViewPath,
                  freeTextbooksSubtopicDetailViewPath,
                  freeTextbooksTopicEmptyViewPath,
                ]}
                exact
                render={() => (
                  <FreeTextbooksCTANavbar hideAuthbuttons context="textbook" />
                )}
              />
            </Switch>
          </div>
        </MobileTopBar>
      </Layout.Fixed>
      <Layout.Fill>
        <Layout
          direction="row"
          overflow={isSidebarCollapsed ? ASIDE_WIDTH - ASIDE_PEAK : 0}
          translate={isSidebarCollapsed ? -(ASIDE_WIDTH - ASIDE_PEAK) : 0}
          className={css(styles.Layout)}
        >
          {LeftAside != null && (
            <Layout.Fixed size={ASIDE_WIDTH} className={css(styles.Aside)}>
              <CollapsibleSidebar
                noShadow
                isOpen={!isSidebarCollapsed}
                onOpen={expandLeftSidebar}
                onClose={collapseLeftSidebar}
              >
                <LeftAside />
              </CollapsibleSidebar>
            </Layout.Fixed>
          )}
          <Layout.Fill>
            {LeftAside != null && (
              <div className={css(styles.InPageAsideControls)}>
                <Flyout
                  isOpen={isLeftFlyoutOpen}
                  onClose={closeFlyout}
                  side="left"
                  initialFlyoutWidth={
                    shouldRenderFullScreenFlyout ? windowWidth : ASIDE_WIDTH
                  }
                >
                  <FlyoutContent>
                    <Route
                      path={[
                        freeTextbooksEmptyViewPath,
                        freeTextbooksTopicEmptyViewPath,
                      ]}
                      exact
                    >
                      <FlyoutCloserWrapper>
                        <Button
                          size="large"
                          padding={0}
                          onClick={closeFlyout}
                          label="Open topic tree"
                          styles={FlyoutCloseButtonStyle.button}
                        >
                          <ChevronLeftIcon size={24} />
                        </Button>
                      </FlyoutCloserWrapper>
                    </Route>
                    <LeftAside />
                  </FlyoutContent>
                </Flyout>
              </div>
            )}

            {children}
          </Layout.Fill>
        </Layout>
      </Layout.Fill>
    </Layout>
  );
}
