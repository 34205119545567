import Lottie from 'react-lottie';

import { mapSanaProficiencyValueToMasteryLevel } from 'ms-utils/masteryLevel';
import type { MasteryLevel, SanaProficiencyValue } from 'ms-utils/masteryLevel';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import emerging from './assets/emerging.svg';
import emergingNoGrowth from './assets/emergingNoGrowth.json';
import emergingWithGrowth from './assets/emergingWithGrowth.json';
import exploringAnimation from './assets/exploring.json';
import exploring from './assets/exploring.svg';
import familiar from './assets/familiar.svg';
import familiarNoGrowth from './assets/familiarNoGrowth.json';
import familiarWithGrowth from './assets/familiarWithGrowth.json';
import loading from './assets/loading.svg';
import masteredAnimation from './assets/mastered.json';
import mastered from './assets/mastered.svg';
import proficient from './assets/proficient.svg';
import proficientNoGrowth from './assets/proficientNoGrowth.json';
import proficientWithGrowth from './assets/proficientWithGrowth.json';
import { Image } from './styles';

type LottieJsonFile = Record<string, unknown>;

function SkillProficiencyIndicatorLoading({
  width,
}: {
  width?: number | undefined;
}) {
  return <Image src={loading} width={width} role="presentation" alt="" />;
}

export function SkillGrowthAnimation({
  width,
  animationData,
}: {
  width?: number | undefined;
  animationData: LottieJsonFile;
}) {
  const options = {
    autoplay: true,
    loop: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  // The animated files are have padding around the icon hardcoded into it
  // whereas the static icon images extend right to the edge.
  // this means that a 75px width static icon is significantly larger than the
  // animated version.
  // A ratio of 1:1.5 can be used to make the animated icon the same size.
  return (
    <Lottie options={options} width={width && width * 1.5} height="auto" />
  );
}

function SkillProficiencyIndicatorPresentational({
  masteryLevel,
  width,
  didGrow = false,
  shouldAnimate = false,
}: {
  masteryLevel: MasteryLevel;
  width?: number | undefined;
  didGrow?: boolean | undefined;
  shouldAnimate?: boolean | undefined;
}) {
  if (shouldAnimate) {
    switch (masteryLevel) {
      case 'NOT_STARTED': {
        return <Image src={exploring} width={width} alt="No activity" />;
      }
      case 'EXPLORING': {
        return (
          <SkillGrowthAnimation
            animationData={exploringAnimation}
            width={width}
          />
        );
      }
      case 'EMERGING': {
        return (
          <SkillGrowthAnimation
            animationData={didGrow ? emergingWithGrowth : emergingNoGrowth}
            width={width}
          />
        );
      }
      case 'FAMILIAR': {
        return (
          <SkillGrowthAnimation
            animationData={didGrow ? familiarWithGrowth : familiarNoGrowth}
            width={width}
          />
        );
      }
      case 'PROFICIENT': {
        return (
          <SkillGrowthAnimation
            animationData={didGrow ? proficientWithGrowth : proficientNoGrowth}
            width={width}
          />
        );
      }
      case 'MASTERED': {
        return (
          <SkillGrowthAnimation
            animationData={masteredAnimation}
            width={width}
          />
        );
      }
      default: {
        assertUnreachable(masteryLevel);
      }
    }
  } else {
    switch (masteryLevel) {
      case 'NOT_STARTED': {
        return <Image src={exploring} width={width} alt="No activity" />;
      }
      case 'EXPLORING': {
        return (
          <Image src={exploring} width={width} alt="Exploring Proficiency" />
        );
      }
      case 'EMERGING': {
        return (
          <Image src={emerging} width={width} alt="Emerging Proficiency" />
        );
      }
      case 'FAMILIAR': {
        return (
          <Image src={familiar} width={width} alt="Familiar Proficiency" />
        );
      }
      case 'PROFICIENT': {
        return (
          <Image src={proficient} width={width} alt="Proficient Proficiency" />
        );
      }
      case 'MASTERED': {
        return (
          <Image src={mastered} width={width} alt="Mastered Proficiency" />
        );
      }
      default: {
        assertUnreachable(masteryLevel);
      }
    }
  }
}

function SkillProficiencyIndicator({
  value,
  width,
  didGrow = false,
  shouldAnimate = false,
}: {
  value: SanaProficiencyValue;
  width?: number | undefined;
  didGrow?: boolean | undefined;
  shouldAnimate?: boolean | undefined;
}) {
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(value);
  if (masteryLevel == null) {
    return <SkillProficiencyIndicatorLoading width={width} />;
  }
  return (
    <SkillProficiencyIndicatorPresentational
      masteryLevel={masteryLevel}
      width={width}
      didGrow={didGrow}
      shouldAnimate={shouldAnimate}
    />
  );
}

export {
  SkillProficiencyIndicator,
  SkillProficiencyIndicatorLoading,
  SkillProficiencyIndicatorPresentational,
};
