/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditTaskFlyout_customTask = {
    readonly id: string;
    readonly title: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly expiryDate: string;
    readonly allowHints: boolean;
    readonly allowCalculator: boolean | null;
    readonly allowRetry: boolean;
    readonly __typename: "CustomTask";
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
    readonly " $refType": "EditTaskFlyout_customTask";
};
export type EditTaskFlyout_customTask$data = EditTaskFlyout_customTask;
export type EditTaskFlyout_customTask$key = {
    readonly " $data"?: EditTaskFlyout_customTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_customTask">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTaskFlyout_customTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowHints",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowCalculator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowRetry",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EditTaskFlyout_assignments"
    }
  ],
  "type": "CustomTask",
  "abstractKey": null
};
(node as any).hash = '50e00454d3f21070dd3fe643ad393f85';
export default node;
