import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { fontFamily, fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

import { MODAL_LATERAL_PADDING } from '../ModalBody';

const VERTICAL_PADDING = 6 * BASE_UNIT;

const Root = styled.div({
  padding: `${VERTICAL_PADDING}px ${MODAL_LATERAL_PADDING}px`,
  borderBottom: `1px solid ${colors.iron}`,
  textAlign: 'center',
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.headerTitle,
  lineHeight: lineHeight.heading,
  color: colors.cloudBurst,
});

type Props = {
  children: ReactNode;
};

const ModalHeader = ({ children }: Props) => <Root>{children}</Root>;

export default ModalHeader;
