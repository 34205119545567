import { without, append, findIndex, propEq } from 'ramda';
import { useEffect, useState } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import { useAppEnv } from 'ms-helpers/AppEnv';
import { unwrap } from 'ms-utils/typescript-utils';

import type { FeedbackModalContainerQuery } from './__generated__/FeedbackModalContainerQuery.graphql';
import { useSendFeedbackMutation } from './sendFeedback';
import FeedbackModal from '../FeedbackModal';
import CONFIG from '../config';
import getDebugInfo from '../getDebugInfo';
import type {
  FeedbackType,
  FeedbackSubtype,
  FeedbackData,
  OnOptionCheck,
  PublicIssueNumber,
} from '../types';

type Props = {
  initiallySelectedFeedbackSubtype?: FeedbackSubtype | null | undefined;
  feedbackType: FeedbackType;
  feedbackData: FeedbackData;
  callback?: (() => void) | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  multiple?: boolean | undefined;
  isTextFeedbackRequired?: boolean | undefined;
};

const getInitialCheckedOptions = (props: Props) => {
  const feedbackConfig = CONFIG[props.feedbackType];
  return props.initiallySelectedFeedbackSubtype != null &&
    feedbackConfig !== undefined
    ? [
        findIndex(propEq('key', props.initiallySelectedFeedbackSubtype))(
          feedbackConfig.options,
        ),
      ]
    : [];
};

export default function FeedbackModalContainer(props: Props) {
  const feedbackConfig = CONFIG[props.feedbackType];
  const [checkedOptions, setCheckedOptions] = useState<ReadonlyArray<number>>(
    getInitialCheckedOptions(props),
  );

  useEffect(() => {
    if (feedbackConfig === undefined) return;

    // Only update checkedOptions if empty
    if (
      props.initiallySelectedFeedbackSubtype != null &&
      checkedOptions.length === 0
    ) {
      const nextCheckedOptions = [
        findIndex(propEq('key', props.initiallySelectedFeedbackSubtype))(
          feedbackConfig.options,
        ),
      ];
      setCheckedOptions(nextCheckedOptions);
    }
  }, [
    checkedOptions.length,
    feedbackConfig,
    props.initiallySelectedFeedbackSubtype,
  ]);

  const [freeTextValue, setFreeTextValue] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [createdPublicIssueNumber, setCreatedPublicIssueNumber] = useState<
    PublicIssueNumber | null | undefined
  >(null);

  const { appEnv } = useAppEnv();
  const query = useQuery<FeedbackModalContainerQuery>(
    graphql`
      query FeedbackModalContainerQuery {
        viewer {
          firstName
          email
          accountType
        }
      }
    `,
    {},
    {
      networkCacheConfig: {
        // relay types are suspect; leaving until we've updated to latest relay
        // @ts-expect-error
        useCache: true,
      },
    },
  );

  const [commitSendFeedbackMutation, isSubmitting] = useSendFeedbackMutation();

  if (query.props == null) return null;
  const { viewer } = query.props;
  if (viewer == null) return null;

  const onOptionCheck: OnOptionCheck = optionIndex => () => {
    setCheckedOptions([optionIndex]);
  };

  const onMultipleSelectionOptionCheck: OnOptionCheck = optionIndex => () => {
    const nextCheckedOptions = checkedOptions.includes(optionIndex)
      ? without([optionIndex], checkedOptions)
      : append(optionIndex, checkedOptions);
    setCheckedOptions(nextCheckedOptions);
  };

  function clearState() {
    setCheckedOptions([]);
    setFreeTextValue('');
    setIsSubmitted(false);
    setShowErrorMessage(false);
    setCreatedPublicIssueNumber(null);
  }

  function onSend() {
    // Condition shouldn't occur as we throw in render if this is hit
    if (feedbackConfig === undefined) return;

    if (checkedOptions.length === 0) {
      setShowErrorMessage(true); // TODO check that the error message makes sense, anyway this shouldn't happen because we will disable the send button if no option is selected
      return;
    }

    const { feedbackType, feedbackData } = props;

    setShowErrorMessage(false);
    commitSendFeedbackMutation({
      variables: {
        feedback: {
          feedbackSubtype: unwrap(
            feedbackConfig.options[unwrap(checkedOptions[0])],
          ).key,
          feedbackType,
          problemId: feedbackData.problemId,
          supportingText: freeTextValue,
          workoutId: feedbackData.workoutId,
          subproblemId: feedbackData.subproblemId,
          workEventId: feedbackData.workEventId,
          subtopicId: feedbackData.subtopicId,
          rawDebugInfo: getDebugInfo({
            problemIdStr: feedbackData.problemId,
            workoutId: feedbackData.workoutId,
            subproblemId: feedbackData.subproblemId,
            workEventId: feedbackData.workEventId,
            APP_ENV: appEnv,
          }),
        },
      },
      onCompleted: ({ submitFeedback: { feedback } }) => {
        if (feedback != null) {
          setCreatedPublicIssueNumber(feedback.publicIssueNumber);
        }
        if (props.callback != null) {
          props.callback();
        }
        setIsSubmitted(true);
        if (feedbackConfig.thankYouMessage == null) onClose();
      },
      onError: () => {
        setShowErrorMessage(true);
      },
    });
  }

  function onClose() {
    clearState();
    props.onClose();
  }

  if (feedbackConfig === undefined) {
    throw Error(`Unsupported feedbackType ${props.feedbackType}`);
  }

  const { isOpen, multiple = false, isTextFeedbackRequired } = props;
  const title = feedbackConfig.title;
  const options = feedbackConfig.options;
  const descriptionConfig = feedbackConfig.description;
  const ctaButtonLabelConfig = feedbackConfig.ctaButtonLabel;
  const description =
    typeof descriptionConfig === 'function'
      ? descriptionConfig(multiple)
      : descriptionConfig;
  const thankYouMessage = feedbackConfig.thankYouMessage;
  const ctaButtonLabel = ctaButtonLabelConfig || 'Submit';
  const canUseIntercom =
    ['TEACHER', 'STAFF'].includes(viewer.accountType) &&
    window != null &&
    window.Intercom != null;

  return (
    <FeedbackModal
      multiple={multiple}
      title={title}
      description={description}
      thankYouMessage={thankYouMessage}
      ctaButtonLabel={ctaButtonLabel}
      options={options}
      isOpen={isOpen}
      checkedOptions={checkedOptions}
      freeTextValue={freeTextValue}
      isSubmitting={isSubmitting}
      isSubmitted={isSubmitted}
      onOptionCheck={onOptionCheck}
      onMultipleSelectionOptionCheck={onMultipleSelectionOptionCheck}
      freeTextValueChange={setFreeTextValue}
      onSend={onSend}
      onClose={onClose}
      firstName={viewer.firstName}
      canUseIntercom={canUseIntercom}
      createdPublicIssueNumber={createdPublicIssueNumber}
      showErrorMessage={showErrorMessage}
      isTextFeedbackRequired={isTextFeedbackRequired}
    />
  );
}
