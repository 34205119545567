import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10.25,14.35 C8.02124325,14.35 6.2,12.6328278 6.2,10.5 C6.2,8.3671722 8.02124325,6.65 10.25,6.65 C12.4787568,6.65 14.3,8.3671722 14.3,10.5 C14.3,12.6328278 12.4787568,14.35 10.25,14.35 Z M10.25,5 C13.8981325,5 17.1331088,7.11930441 18.4574588,10.287902 C18.5141804,10.4236123 18.5141804,10.5763877 18.4574588,10.712098 C17.1331088,13.8806956 13.8981325,16 10.25,16 C6.60186747,16 3.36689119,13.8806956 2.04254118,10.712098 C1.98581961,10.5763877 1.98581961,10.4236123 2.04254118,10.287902 C3.36689119,7.11930441 6.60186747,5 10.25,5 Z M10.25,6.1 C7.12527626,6.1 4.35629244,7.8610622 3.15022245,10.5 C4.35629244,13.1389378 7.12527626,14.9 10.25,14.9 C13.3747237,14.9 16.1437076,13.1389378 17.3497775,10.5 C16.1437076,7.8610622 13.3747237,6.1 10.25,6.1 Z" />
);

const View = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

View.displayName = 'View';

export default View;
