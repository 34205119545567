import styled from '@emotion/styled';
import { useRef, useEffect, type ReactNode, type MouseEvent } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { SkillProficiencyIndicator } from 'ms-pages/Lantern/components/SkillProficiencyIndicator';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { HeadingS, BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import { STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME } from 'ms-pages/Lantern/utils/urls';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import InlinePopover from 'ms-ui-primitives/Popover/InlinePopover';
import { VStack, HStack, VSpacer } from 'ms-ui-primitives/Stack';
import { verticallyScrollable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { useUncheckedQueryParam } from 'ms-utils/hooks/useQueryParam';
import { serialize } from 'ms-utils/urls/queryParams';

import type { RecommendationSkillPopoverQuery } from './__generated__/RecommendationSkillPopoverQuery.graphql';

type Props = {
  onDismiss: () => void;
  outcomes: ReadonlyArray<{
    readonly id: string;
    readonly title: string;
  }>;
  categoryMessage: string;
  urlToNavigateToWhenOutcomeIsClicked: string | null | undefined;
  inDashboard: boolean;
};
const WIDTH = 420;
const WIDTH_FOR_NARROW_SCREENS = 300;
const BASE_PADDING = 8;
const PADDING = 3 * BASE_PADDING;
const TRIANGLE_POINTER_WIDTH = 40;
const TRIANGLE_POINTER_HEIGHT = 23;
const TOOLTIP_BACKGROUND_COLOR = colors.white;
const BOX_SHADOW = '0px 8.75px 49px rgba(62, 62, 76, 0.15)';
// We need TRIANGLE_POINTER_OFFSET to position the triangle pointer because
// we need to hide base of the triangle svg which has rounded borders at each corner
const TRIANGLE_POINTER_BORDER_RADIUS = 8;
const TRIANGLE_POINTER_OFFSET =
  TRIANGLE_POINTER_HEIGHT - TRIANGLE_POINTER_BORDER_RADIUS;
const PopoverContentRoot = styled(VStack)({
  width: WIDTH,
  [`@media (max-width: ${breakPoints.medium}px)`]: {
    width: WIDTH_FOR_NARROW_SCREENS,
  },
  cursor: 'default', // reset the parent's style
  background: TOOLTIP_BACKGROUND_COLOR,
  borderRadius: 8,
  padding: PADDING,
  textAlign: 'center',
  position: 'relative',
  boxShadow: BOX_SHADOW,
});
const PopoverTrianglePointerWrapper = styled.div({
  position: 'absolute',
  width: TRIANGLE_POINTER_WIDTH,
  height: TRIANGLE_POINTER_HEIGHT,
  bottom: 'auto',
  top: -TRIANGLE_POINTER_OFFSET,
  right: '50%',
  marginRight: -TRIANGLE_POINTER_WIDTH / 2,
});
const SkillProficiencyItemElement = styled.div({
  width: 220,
  padding: BASE_PADDING,
  marginBottom: BASE_PADDING * 2,
});
const SkillPopoverContainer = styled(VStack)({
  borderRadius: 16,
  padding: 10,
  backgroundColor: colors.almond50,
});
function SkillProficiencyItem({
  children,
  onClick,
}: {
  children: ReactNode;
  onClick: () => void;
}) {
  const tooltipAnchorRef = useRef<HTMLDivElement>(null);
  const tooltipVisible = useBoolean();
  // Dismiss it after 5 seconds regardless of whether the user has interacted with it
  useEffect(() => {
    if (tooltipVisible.value) {
      const timeout = setTimeout(tooltipVisible.setFalse, 5000);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      return () => {}; // only to appease TS
    }
  }, [tooltipVisible]);
  return (
    <SkillProficiencyItemElement
      ref={tooltipAnchorRef}
      onMouseEnter={tooltipVisible.setTrue}
      onMouseLeave={tooltipVisible.setFalse}
      onClick={tooltipVisible.setTrue}
    >
      {children}

      <VSpacer height={BASE_PADDING / 2} />

      <BodyM color="eggplant">
        <Bold>
          <Button isInline onClick={onClick} size="regular">
            View in skills map
          </Button>
        </Bold>
      </BodyM>
    </SkillProficiencyItemElement>
  );
}
function SkillProficiencyContent({
  outcome: { outcomeTitle, proficiency },
}: {
  outcome: {
    outcomeTitle: string;
    proficiency: number | null | undefined;
  };
}) {
  return (
    <>
      <SkillProficiencyIndicator value={proficiency} />
      <VSpacer height={BASE_PADDING / 2} />
      <BodyM>{outcomeTitle}</BodyM>
    </>
  );
}
const ScrollableGridWrapper = styled.div({
  ...verticallyScrollable,
  // in supported browsers, avoid the overscroll of the underlying content
  // https://developer.mozilla.org/en-US/docs/Web/CSS/overscroll-behavior
  overscrollBehaviorY: 'contain',
});
const triangleShape = (
  <svg
    width={TRIANGLE_POINTER_WIDTH}
    height={TRIANGLE_POINTER_HEIGHT}
    viewBox={`0 0 ${TRIANGLE_POINTER_WIDTH} ${TRIANGLE_POINTER_HEIGHT}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2858 1.50727C18.8184 0.0915567 21.1816 0.0915581 22.7142 1.50727L38.4699 16.0618C41.1441 18.5321 39.3962 23 35.7557 23H4.24427C0.603751 23 -1.14408 18.5321 1.53007 16.0618L17.2858 1.50727Z"
      fill={TOOLTIP_BACKGROUND_COLOR}
    />
  </svg>
);
const RECOMMENDATION_SKILL_POPOVER_QUERY = graphql`
  query RecommendationSkillPopoverQuery(
    $filters: [UserStatusFilterInput!]!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
            userStatusFilter {
              curriculumNodeIds
            }
          }
        }
      }
    }
  }
`;
export default function RecommendationSkillPopover({
  onDismiss,
  outcomes,
  categoryMessage,
  urlToNavigateToWhenOutcomeIsClicked,
  inDashboard,
}: Props) {
  const { trackStructEvent } = useSnowplow();
  const { props, error, retry } = useQuery<RecommendationSkillPopoverQuery>(
    RECOMMENDATION_SKILL_POPOVER_QUERY,
    {
      filters: outcomes.map(outcome => ({
        curriculumNodeIds: [outcome.id],
      })),
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  const [, setSkillsMapSkillTooltipVisibleForOutcomeId] =
    useUncheckedQueryParam(
      STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME,
    );
  const lanternStudent =
    props?.lantern?.viewer != null &&
    props.lantern.viewer.__typename === 'LanternStudent'
      ? props.lantern.viewer
      : null;
  if (props != null && lanternStudent == null)
    throw new Error(
      'RecommendationsSkillPopover: user is not a lantern student',
    );
  const outcomesProficiencies =
    lanternStudent?.userStatuses?.map(userStatus => {
      const outcomeId = userStatus.userStatusFilter.curriculumNodeIds[0];
      return {
        outcomeId,
        outcomeTitle: outcomes.find(o => o.id === outcomeId)?.title ?? '',
        proficiency: userStatus.trueProficiency,
      };
    }) ?? [];
  return (
    <InlinePopover onDismiss={onDismiss} top={50}>
      <PopoverContentRoot
        onClick={(event: MouseEvent) => {
          // Prevent the click handler to propagate to
          // the Card, which causes the page to redirect
          event.preventDefault();
        }}
      >
        <PopoverTrianglePointerWrapper>
          {triangleShape}
        </PopoverTrianglePointerWrapper>

        <BodyM color="grey">
          <Bold>Working on this subtopic will help you grow this skill</Bold>
        </BodyM>

        <VSpacer height={BASE_PADDING * 2} />

        <ScrollableGridWrapper>
          <SkillPopoverContainer>
            <VSpacer height={BASE_PADDING * 2} />

            <HeadingS>{categoryMessage}</HeadingS>

            <VSpacer height={2 * BASE_PADDING} />

            {error != null && <Retry retry={retry} />}

            {props == null && <LoadingSpinner />}

            <HStack justify="center" wrap>
              {outcomesProficiencies.map(outcomeProficiency => (
                <SkillProficiencyItem
                  key={outcomeProficiency.outcomeId}
                  onClick={() => {
                    trackStructEvent(
                      inDashboard
                        ? {
                            category: 'student_dashboard',
                            action: 'clicked_view_in_skills_map',
                          }
                        : {
                            category: 'student_skills_map',
                            action: 'clicked_view_in_skills_map',
                          },
                    );
                    if (urlToNavigateToWhenOutcomeIsClicked != null) {
                      window.location.assign(
                        `${urlToNavigateToWhenOutcomeIsClicked}${serialize({
                          [STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME]:
                            outcomeProficiency.outcomeId,
                        })}`,
                      );
                    } else {
                      onDismiss();
                      setTimeout(() => {
                        const { outcomeId } = outcomeProficiency;
                        if (outcomeId === undefined) {
                          throw new Error('outcomeId is undefined');
                        }
                        setSkillsMapSkillTooltipVisibleForOutcomeId(outcomeId);
                      }, 0);
                    }
                  }}
                >
                  <SkillProficiencyContent outcome={outcomeProficiency} />
                </SkillProficiencyItem>
              ))}
            </HStack>
          </SkillPopoverContainer>
        </ScrollableGridWrapper>
      </PopoverContentRoot>
    </InlinePopover>
  );
}
