import { useEffect } from 'react';

import { useRouter } from 'ms-utils/hooks/useRouter';

import { sendPageView } from './index';

/**
 * Format the pathname to replace all numeric ids with ":id" string.
 * It's necessary to avoid including the variable part in the url, e.g. the user's id.
 *
 * @param {string} pathname The pathname
 * @returns {string} A string without numeric ids
 */
export function formatPathname(pathname: string): string {
  return pathname
    .split('/')
    .map(str => str.replace(new RegExp(/^\d+$/g), ':id'))
    .join('/');
}

/**
 * Manually sends page views to Google Analytics. One use case if the page is an SPA.
 * See `https://developers.google.com/analytics/devguides/collection/analyticsjs
 * /single-page-applications`
 */
export function useGoogleAnalyticsTracking() {
  const {
    location: { pathname, search: queryString },
  } = useRouter();

  useEffect(() => {
    sendPageView({ page: pathname + queryString });
  }, [pathname, queryString]);
}
