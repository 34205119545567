import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from './types';

const Star = ({ color = '#E9B84E', size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={14}
    viewBoxWidth={14}
  >
    <path
      d="M9.73539 12.8931C9.88723 12.9648 10.0501 13 10.2123 13C10.4455 13 10.6773 12.9268 10.8728 12.7843C11.2038 12.5424 11.378 12.1388 11.3272 11.7305L10.9168 8.43129L13.1777 6.0043C13.4576 5.7041 13.553 5.27456 13.4264 4.88325C13.2999 4.49195 12.9718 4.20071 12.5698 4.12304L9.32148 3.49606L7.72436 0.58427C7.52676 0.22403 7.14999 0 6.74053 0C6.33137 0 5.9543 0.22403 5.75671 0.58427L4.15988 3.49576L0.911568 4.12304C0.50954 4.20071 0.181499 4.49195 0.0549178 4.88325C-0.071366 5.27456 0.0237183 5.7038 0.303326 6.0043L2.56455 8.43099L2.1542 11.7305C2.10339 12.1388 2.27751 12.5424 2.60853 12.784C2.93954 13.026 3.37544 13.0678 3.74597 12.8931L6.74083 11.4811L9.73539 12.8931Z"
      fill={color}
    />
  </Icon>
);

Star.displayName = 'Star';

export default Star;
