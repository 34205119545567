import { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import { Empty } from 'ms-pages/Teacher/components/Empty';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import ProblemItem from 'ms-pages/Textbooks/components/ProblemItem';

import type { TaskTemplateQuestionListQuery } from './__generated__/TaskTemplateQuestionListQuery.graphql';

type Props = {
  templateId: string;
  questionsCollapsed?: boolean | undefined;
};

export default function TaskTemplateQuestionList(props: Props) {
  return (
    <ErrorBoundaryWithRetry
      fallback={({ error, retry }) => (
        <Retry retry={retry} message={error.message} />
      )}
      name="TaskTemplateQuestionsList"
    >
      {({ fetchKey }) => (
        <Suspense fallback={<MinorSpinner />}>
          <TaskTemplateQuestionListInner {...props} fetchKey={fetchKey} />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
}

function TaskTemplateQuestionListInner({
  templateId,
  questionsCollapsed,
  fetchKey,
}: Props & { fetchKey: number }) {
  const data = useLazyLoadQuery<TaskTemplateQuestionListQuery>(
    graphql`
      query TaskTemplateQuestionListQuery($templateId: ID!) {
        taskTemplate(id: $templateId) {
          problemContents {
            id
            ...ProblemItem_problemContent
          }
        }
      }
    `,
    { templateId },
    {
      fetchKey,
      fetchPolicy: 'store-and-network',
    },
  );

  if (data.taskTemplate == null) {
    throw new Error(
      `TaskTemplateQuestionListQuery failed, templateId:${templateId}`,
    );
  }

  if (data.taskTemplate.problemContents.length === 0) return <Empty />;

  return (
    <>
      {data.taskTemplate.problemContents.map((content, index) => (
        <div
          key={content.id}
          style={{
            width: '99.8%', // hack to prevent unnecessary horizontal scrollbar
          }}
        >
          <ProblemItem
            index={index + 1}
            shouldShowProblemMetaData
            showTryProblem
            problemContent={content}
            collapsedInBulk={questionsCollapsed}
            structEventToTrackOnAddToCollection={null}
          />
        </div>
      ))}
    </>
  );
}
