import styled from '@emotion/styled';

import { PAGE_PADDING } from 'ms-components/Layout/styles';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

export default styled.div({
  maxWidth: breakPoints.medium,
  width: '100%',
  margin: 'auto',
  position: 'relative',
  minHeight: '100%', // will fill the container it's in
});

type ResponsiveContainerStyleOptions = {
  withTwoColumnGrid?: boolean | undefined;
  withFullWidthTable?: boolean | undefined;
  narrow?: boolean | undefined;
  extraNarrow?: boolean | undefined;
  ultraNarrow?: boolean | undefined;
};
// TODO: consider using resize observer since the sidebar can now be toggled
export const ResponsiveContainer = styled.div<ResponsiveContainerStyleOptions>(
  {
    width: '100%',
    maxWidth: 1280,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',

    [`@media (max-width: ${breakPoints.large - 1}px)`]: {
      maxWidth: 768,
    },
  },
  ({ withTwoColumnGrid }) =>
    withTwoColumnGrid && {
      [`@media (max-width: ${breakPoints.extraLarge - 1}px)`]: {
        maxWidth: 768,
      },
    },
  ({ withFullWidthTable }) =>
    withFullWidthTable && {
      // undo the default style
      [`@media (max-width: ${breakPoints.large - 1}px)`]: {
        maxWidth: 'none',
      },
    },
  ({ narrow }) =>
    narrow && {
      maxWidth: 1024,
    },
  ({ extraNarrow }) =>
    extraNarrow && {
      maxWidth: 768,
    },
  ({ ultraNarrow }) =>
    ultraNarrow && {
      maxWidth: 720,
    },
);

type WrapperStyleOptions = {
  withLightGrayBackground?: boolean;
};

export const WRAPPER_PADDING = PAGE_PADDING;
export const Wrapper = styled.div<WrapperStyleOptions>(
  {
    padding: WRAPPER_PADDING,
    minHeight: '100%',
  },
  ({ withLightGrayBackground }) =>
    withLightGrayBackground && {
      background: colors.porcelain,
    },
);
