import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  useChangeStudentTextbookFocusMutationVariables,
  useChangeStudentTextbookFocusMutationResponse,
} from './__generated__/useChangeStudentTextbookFocusMutation.graphql';

const mutation = graphql`
  mutation useChangeStudentTextbookFocusMutation(
    $studentId: ID!
    $syllabusFocusId: ID
  ) {
    updateStudent(studentId: $studentId, syllabusFocusId: $syllabusFocusId) {
      student {
        user {
          syllabusFocus {
            id
            title
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useChangeStudentTextbookFocus({
  studentId,
  syllabusFocusId,
}: useChangeStudentTextbookFocusMutationVariables): UseMutationResponse<useChangeStudentTextbookFocusMutationResponse> {
  return useMutation({
    mutation,
    variables: {
      studentId,
      syllabusFocusId,
    },
  });
}
