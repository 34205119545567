/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TeacherCreateClassModalQueryVariables = {
    schoolId: string;
    numberOfSyllabuses: number;
    numberOfTeachers: number;
};
export type TeacherCreateClassModalQueryResponse = {
    readonly school: {
        readonly availableSyllabuses: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
        }> | null;
        readonly lanternCurriculum: {
            readonly grades: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
        } | null;
        readonly teachers: ReadonlyArray<{
            readonly id: string;
            readonly user: {
                readonly firstName: string;
                readonly lastName: string;
            };
        }> | null;
    } | null;
};
export type TeacherCreateClassModalQuery = {
    readonly response: TeacherCreateClassModalQueryResponse;
    readonly variables: TeacherCreateClassModalQueryVariables;
};



/*
query TeacherCreateClassModalQuery(
  $schoolId: ID!
  $numberOfSyllabuses: Int!
  $numberOfTeachers: Int!
) {
  school(id: $schoolId) {
    availableSyllabuses(limit: $numberOfSyllabuses) {
      id
      title
    }
    lanternCurriculum {
      grades {
        id
        title
      }
      id
    }
    teachers(limit: $numberOfTeachers) {
      id
      user {
        firstName
        lastName
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfSyllabuses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfTeachers"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "limit",
      "variableName": "numberOfSyllabuses"
    }
  ],
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "availableSyllabuses",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "grades",
  "plural": true,
  "selections": (v5/*: any*/),
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "numberOfTeachers"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherCreateClassModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeacherCreateClassModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb941b8f552e43956358a6b77ad24268",
    "id": null,
    "metadata": {},
    "name": "TeacherCreateClassModalQuery",
    "operationKind": "query",
    "text": "query TeacherCreateClassModalQuery(\n  $schoolId: ID!\n  $numberOfSyllabuses: Int!\n  $numberOfTeachers: Int!\n) {\n  school(id: $schoolId) {\n    availableSyllabuses(limit: $numberOfSyllabuses) {\n      id\n      title\n    }\n    lanternCurriculum {\n      grades {\n        id\n        title\n      }\n      id\n    }\n    teachers(limit: $numberOfTeachers) {\n      id\n      user {\n        firstName\n        lastName\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a606817ee9c3332592f0783e738bf82b';
export default node;
