import styled from '@emotion/styled';
import { StyleSheet } from 'aphrodite';
import { useEffect } from 'react';

import CheckMarkIcon from 'ms-components/icons/CheckMarkThick';
import ClipboardIcon from 'ms-components/icons/Clipboard';
import FacebookIcon from 'ms-components/icons/FacebookRound';
import LinkedinIcon from 'ms-components/icons/Linkedin';
import TwitterIcon from 'ms-components/icons/TwitterRound';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import SunflowerModal from 'ms-pages/Sunflower/ui-primitives/Modal';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Input from 'ms-ui-primitives/Input';
import { VStack, VSpacer, HStack, HSpacer } from 'ms-ui-primitives/Stack';
import { copyToClipboard } from 'ms-utils/clipboard';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { assertUnreachable } from 'ms-utils/typescript-utils';

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
};

const INPUT_HEIGHT = 48;
const styles = StyleSheet.create({
  customInput: {
    border: `2px solid ${colors.ironLight}`,
    height: INPUT_HEIGHT,
    paddingRight: 48, // Space for clipboard button
  },
});

const Wrapper = styled.div({
  position: 'relative',
});

const ButtonWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  height: INPUT_HEIGHT,
  display: 'flex',
  alignItems: 'center',
  padding: `0 ${BASE_UNIT}px`,
});

export default function ShareModal({ title, isOpen, onClose }: Props) {
  return (
    <SunflowerModal
      width={600}
      title={title}
      noMinHeight
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack>
        <Wrapper>
          <Input
            value={window.location.href}
            readOnly
            autoFocus
            aphroditeStyles={[styles.customInput]}
          />

          <ButtonWrapper>
            <CopyUrlToClipboardButton />
          </ButtonWrapper>
        </Wrapper>

        <VSpacer height={24} />

        <HStack center>
          <BodyM>Or, share with your social community</BodyM>
          <HSpacer width={24} grow />
          <HStack center>
            <SocialButton social="facebook" />
            <HSpacer width={16} grow />
            <SocialButton social="twitter" />
            <HSpacer width={16} grow />
            <SocialButton social="linkedin" />
          </HStack>
        </HStack>
      </VStack>
    </SunflowerModal>
  );
}

const COPY_TO_CLIPBOARD_BUTTON_SIZE = 24;

function CopyUrlToClipboardButton() {
  const isCopied = useBoolean();

  const handleButtonClick = () => {
    copyToClipboard(window.location.href);
    isCopied.setTrue();
  };

  // Re-enable CopyUrlToClipboardButton after 2 seconds
  useEffect(() => {
    if (isCopied.value) {
      const timer = setTimeout(isCopied.setFalse, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return; // appease TS
  }, [isCopied.value, isCopied.setFalse]);

  return (
    <Button
      color="grey90"
      onClick={handleButtonClick}
      label="Copy to clipboard"
      isCircle
      isDisabled={isCopied.value}
    >
      {isCopied.value ? (
        <CheckMarkIcon size={COPY_TO_CLIPBOARD_BUTTON_SIZE} />
      ) : (
        <ClipboardIcon size={COPY_TO_CLIPBOARD_BUTTON_SIZE} />
      )}
    </Button>
  );
}

type SocialButtonProps = {
  social: 'facebook' | 'twitter' | 'linkedin';
};

function SocialButton({ social }: SocialButtonProps) {
  return (
    <AnchorButton
      color="grey90"
      href={getSocialShareUrl(social)}
      target="_blank"
      label={getSocialButtonLabel(social)}
      isInline
      isRound
    >
      {getSocialIcon(social)}
    </AnchorButton>
  );
}

function getSocialShareUrl(social: SocialButtonProps['social']) {
  switch (social) {
    case 'facebook':
      return `http://www.facebook.com/share.php?u=${window.location.href}`;
    case 'twitter':
      return `https://twitter.com/intent/tweet?text=${window.location.href}`;
    case 'linkedin':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`;
    default: {
      assertUnreachable(social, `Unexpected social ${social}`);
    }
  }
}

const ICON_SIZE = 24;

function getSocialIcon(social: SocialButtonProps['social']) {
  switch (social) {
    case 'facebook':
      return <FacebookIcon size={ICON_SIZE} />;
    case 'twitter':
      return <TwitterIcon size={ICON_SIZE} />;
    case 'linkedin':
      return <LinkedinIcon size={ICON_SIZE} />;
    default: {
      assertUnreachable(social, `Unexpected social ${social}`);
    }
  }
}

function getSocialButtonLabel(social: SocialButtonProps['social']) {
  switch (social) {
    case 'facebook':
      return 'Share on Facebook';
    case 'twitter':
      return 'Share on Twitter';
    case 'linkedin':
      return 'Share on Linkedin';
    default: {
      assertUnreachable(social, `Unexpected social ${social}`);
    }
  }
}
