import ShareIcon from 'ms-components/icons/Share';
import { useSnowplow } from 'ms-helpers/Snowplow';
import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import Button from 'ms-ui-primitives/Button';
import { HSpacer } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import ShareModal from './ShareModal';

type Props = {
  title: string;
  eventToTrack: SupportedStructEvent;
};

export default function ShareButton({ title, eventToTrack }: Props) {
  const { trackStructEvent } = useSnowplow();
  const shareModalOpen = useBoolean();
  return (
    <>
      <Button
        type="secondary"
        size="regular"
        isRound
        label="Share"
        onClick={() => {
          trackStructEvent(eventToTrack);
          shareModalOpen.setTrue();
        }}
      >
        <ShareIcon size={18} />
        <HSpacer width={12} />
        Share
      </Button>

      <ShareModal
        isOpen={shareModalOpen.value}
        onClose={shareModalOpen.setFalse}
        title={title}
      />
    </>
  );
}
