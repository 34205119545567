import { test } from 'ramda';

import type { subproblemFragments_FullInputConfiguration } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_FullInputConfiguration.graphql';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  InputValue,
  LatexAnswerInputValue,
  MultipleChoiceInputValue,
  NumberLineInputValue,
} from './types';
import { prepareInputValue as prepareProbabilityTreeValue } from '../utils/ProbabilityTree';

type InputConfiguration = subproblemFragments_FullInputConfiguration;
/**
 * Transform the input configuration returned from the server
 * to be saved as the input value state on the client.
 *
 * @param {InputConfiguration} inputConfiguration The input configuration
 *     returned from the server.
 * @param {answerValue} answerValue The answer value returned from the answer input
 * @returns {*} A representation of the client-local state of
 *     the input value - it has a different shape for each input type.
 */
// TODO: As we add more subproblem types we will see if there are any patterns,
// e.g. if we can move the input value state for all subproblems under `value` key.
export const getInputValue = (
  inputConfiguration: InputConfiguration | null | undefined,
  answerValue?: InputValue['value'],
): InputValue => {
  if (inputConfiguration == null) return { value: null, isAnswered: false };
  const { __typename } = inputConfiguration;
  switch (__typename) {
    case 'LegacyGraphPlotConfig': {
      return {
        value: inputConfiguration.requestDatum,
        isAnswered: !!answerValue,
      };
    }
    case 'LatexAnswerConfig': {
      const MATHQUILL_EDITABLE_REGEX = /\\editable\{\}/g;
      const answer = answerValue as LatexAnswerInputValue | undefined; // coerce answerValue type
      return {
        value: inputConfiguration.fields,
        isAnswered: answer
          ? answer.every(
              option =>
                option.answerContent &&
                !test(MATHQUILL_EDITABLE_REGEX)(option.answerContent),
            )
          : false,
      };
    }
    case 'MultipleChoiceConfig': {
      const answer = answerValue as MultipleChoiceInputValue | undefined; // coerce answerValue type
      return {
        value: { options: inputConfiguration.options },
        isAnswered: answer
          ? answer.options.some(option => option.selected)
          : false,
      };
    }
    case 'NumberBuilderConfig': {
      return {
        value: { value: inputConfiguration.value },
        isAnswered: !!answerValue,
      };
    }
    case 'HistogramConfig': {
      return {
        value: inputConfiguration.histogramValues,
        isAnswered: !!answerValue,
      };
    }
    case 'NumberLineConfig': {
      const answer = answerValue as NumberLineInputValue | undefined; // coerce answerValue type
      return {
        value: inputConfiguration.numberLineValues,
        isAnswered: !!answer && answer.length > 0,
      };
    }
    case 'BoxPlotConfig': {
      return {
        value: {
          min: inputConfiguration.min,
          q1: inputConfiguration.q1,
          median: inputConfiguration.median,
          q3: inputConfiguration.q3,
          max: inputConfiguration.max,
        },
        isAnswered: !!answerValue,
      };
    }
    case 'ProbabilityTreeConfig': {
      return {
        value: {
          tree: prepareProbabilityTreeValue(inputConfiguration).tree,
        },
        isAnswered: !!answerValue,
      };
    }
    case 'GeometryReasonConfig': {
      // NOTE because we are using null to represent the uninitialized state
      // rather than modelling this as a union type or state, we need to
      // ensure that the "no reason selected" (represented as null by graphql)
      // is mapped to the empty string.
      // TODO rearchitect how we track that states that the inputConfig can be
      // in `type InputConfig<T> = Uninitialized | Maybe<T>` so that null isnt
      // having its meaning overloaded.
      return {
        value: inputConfiguration.selectedReason || '',
        isAnswered:
          !!answerValue &&
          inputConfiguration.reasonList.findIndex(
            reason => reason === answerValue,
          ) !== -1,
      };
    }
    default:
      if (__typename === '%other') throw Error('type narrowing');
      assertUnreachable(__typename);
  }
};
