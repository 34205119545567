import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';

import Latex from 'ms-components/Latex';
import { borderRadiusUI, mathquillStyles } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

type Props = {
  aphroditeStyles?: CSSInputTypes[] | undefined;
  block?: boolean | undefined;
  value: string;
};

const styles = StyleSheet.create({
  root: {
    background: colors.athensGray,
    border: `1px solid ${colors.iron}`,
    borderRadius: borderRadiusUI,
    color: colors.iron,
    display: 'inline-block',
    padding: mathquillStyles.padding.shorthand,
    // There is some MathQuill behaviour that causes cell-collapse
    // when these cells are tapped (even though they are static math).
    // By preventing MathQuill from receiving the events we prevent this behaviour.
    pointerEvents: 'none',
  },
  block: {
    display: 'block',
  },
});

const Disabled = ({ aphroditeStyles = [], block = false, value }: Props) => {
  const rootClass = css(styles.root, ...aphroditeStyles, block && styles.block);
  return (
    <div className={rootClass}>
      <style>{`
        .${rootClass} .mq-selectable + .mq-root-block {
          padding: 0;
        }
      `}</style>
      <Latex aphroditeStyles={[styles.block]} latex={value} />
    </div>
  );
};

export default Disabled;
