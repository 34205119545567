import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M6.76074917,3.22792981 L14.5017364,9.39334284 C15.0189755,9.80530486 15.155638,10.634703 14.8069805,11.2458554 C14.7258039,11.3881477 14.6221632,11.5106061 14.5017364,11.6065215 L6.76074917,17.7719346 C6.24351008,18.1838966 5.54156223,18.0224209 5.19290475,17.4112684 C5.06717108,17.1908732 5,16.9311364 5,16.6653453 L5,4.33451918 C5,3.59748458 5.50567153,3 6.12944899,3 C6.35439709,3 6.57422119,3.0793671 6.76074917,3.22792981 Z"
    transform="rotate(90.000000) translate(0, -20.500000)"
  />
);

const TriangleDown = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

TriangleDown.displayName = 'TriangleDown';

export default TriangleDown;
