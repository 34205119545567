import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10.6072125,14.3937509 L9.03067786,15.8797072 L7.67111173,15.8927357 L4.03871851,12.3849744 L6.30553656,10.0891681 L10.6072125,14.3937509 Z M17.8265959,7.72132679 L13.4219309,3.31368533 C13.0386832,2.93017858 12.4196317,2.92193656 12.0263096,3.29510409 L3.22674075,11.6437664 C2.80746418,12.0684047 2.80513096,12.7462268 3.22152937,13.1577245 C3.22342513,13.1595979 4.43708263,14.3316454 6.86250187,16.6738669 C7.06012118,16.8647056 7.32554806,16.9698805 7.60283729,16.9672233 L8.96240342,16.9541947 C9.23487556,16.9515836 9.49807823,16.845044 9.69831581,16.6563109 L17.7983674,9.16222463 C18.203761,8.78715861 18.2283463,8.15447112 17.8532803,7.74907758 C17.8445644,7.73965694 17.8356679,7.73040496 17.8265959,7.72132679 Z" />
);

const Eraser = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Eraser.displayName = 'Eraser';

export default Eraser;
