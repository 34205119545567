import { useState, useCallback } from 'react';

import { createNewAdaptiveExperienceTaskVariables } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import type {
  createNewAdaptiveExperienceTaskMutation,
  createNewAdaptiveExperienceTaskMutationResponse,
} from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/__generated__/createNewAdaptiveExperienceTaskMutation.graphql';
import { mutation as newAdaptiveExperienceTaskMutation } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/createNewAdaptiveExperienceTask';
import type { State } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/state';
import { InvariantViolation } from 'ms-utils/app-logging';
import { promiseMutation } from 'ms-utils/relay/promiseMutation';
import { isNotNullOrUndefined } from 'ms-utils/typescript-utils';

type Task = NonNullable<
  createNewAdaptiveExperienceTaskMutationResponse['createNewAdaptiveExperienceTask']['task']
>;
type Arguments = {
  subtopicIds: ReadonlyArray<string>;
  getState: (id: string) => State | null | undefined;
  onError: (subtopicId: string, errorMessages: ReadonlyArray<Error>) => void;
  onSave: (subtopicId: string, task: Task) => void;
  onComplete?: () => void;
};
export default function useCreateBulkAdaptiveTask({
  subtopicIds,
  getState,
  onError,
  onSave,
  onComplete,
}: Arguments) {
  const values = subtopicIds
    .map(id => getState(id))
    .filter(isNotNullOrUndefined);
  const [running, setRunning] = useState(false);
  return [
    useCallback(async () => {
      if (running === true) return;
      setRunning(true);
      for (const value of values) {
        const subtopicId = value.subtopicId;
        if (subtopicId == null)
          throw new InvariantViolation(
            'Attempted to save a task without a subtopic id',
          );
        try {
          let errors: readonly {
            readonly message: string;
          }[] = [];
          let task:
            | {
                readonly id: string;
              }
            | null
            | undefined = null;
          const { errorResponse, taskResponse } =
            await promiseMutation<createNewAdaptiveExperienceTaskMutation>({
              mutation: newAdaptiveExperienceTaskMutation,
              variables: createNewAdaptiveExperienceTaskVariables(value),
            }).then(response => ({
              taskResponse: response.createNewAdaptiveExperienceTask.task,
              errorResponse: response.createNewAdaptiveExperienceTask.errors,
            }));
          task = taskResponse;
          errors = errorResponse;
          if (errors.length) {
            onError(
              subtopicId,
              errors.map(message => new InvariantViolation(message.message)),
            );
          } else if (task == null) {
            onError(subtopicId, [
              new InvariantViolation('Unable to create task. Please try again'),
            ]);
          } else if (subtopicId) onSave(subtopicId, task);
        } catch (e) {
          onError(subtopicId, [
            new InvariantViolation('Unable to create task. Please try again.'),
          ]);
        }
      }
      if (onComplete != null) onComplete();
      setRunning(false);
    }, [onComplete, onError, onSave, running, values]),
    running,
  ] as const;
}
