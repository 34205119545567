import { compose, map, pick, zipObj } from 'ramda';

import Latex from 'ms-components/Latex';
import BoxPlotReadOnly from 'ms-components/math/BoxPlotReadOnly';
import GraphPlotReadOnly from 'ms-components/math/GraphPlotReadOnly';
import HistogramReadOnly from 'ms-components/math/HistogramReadOnly';
import MathContent from 'ms-components/math/MathContent';
import MultipleChoiceReadOnly from 'ms-components/math/MultipleChoiceReadOnly';
import type {
  DropZone,
  ItemType,
  Value as NumberBuilderValue,
} from 'ms-components/math/NumberBuilder';
import NumberBuilderReadOnly from 'ms-components/math/NumberBuilderReadOnly';
import NumberLineIntervalsReadOnly from 'ms-components/math/NumberLineIntervalsReadOnly';
import ProbabilityTreeReadOnly from 'ms-components/math/ProbabilityTreeReadOnly';
import { deserializeHistogram } from 'ms-components/math/private-shared/legacyTransformers/histogram';
import {
  deserializeCoordinateValues,
  fromOldNumberLineValue,
  mapValueRecursive,
  pointToSegment,
} from 'ms-components/math/private-shared/legacyTransformers/numberLine';
import { deserializeProbabilityTree } from 'ms-components/math/private-shared/legacyTransformers/probabilityTree';
import { Logger } from 'ms-utils/app-logging';
import type { Mode } from 'ms-utils/math/toLatex';

type Html = string;
type LatexString = string;
type Json = string;
type NumericString = string; // stringified number

export type RequestDatum =
  | {
      // `worked-solution` subproblem type
      type: 'LatexString';
      prefix: Html;
      suffix: Html;
      value: LatexString;
    }
  | {
      // `geometry` subproblem type
      type: 'LatexReason';
      reason: string;
      value: Html;
    }
  | {
      // `inline` subproblem type
      type: 'LatexMap';
      value: Html;
    }
  | {
      // `box-plot` subproblem type
      type: 'BoxablePlot';
      eye_candy: {
        axis_title: string;
        major_unit: NumericString;
        max: NumericString;
        min: NumericString;
        minor_unit: NumericString;
      };
      interactivity: {
        max: { value: NumericString };
        median: { value: NumericString };
        min: { value: NumericString };
        q1: { value: NumericString };
        q3: { value: NumericString };
      };
    }
  | {
      // `graph-plot` subproblem type
      type: 'PlottableGraph';
      eye_candy: {
        axes: { [key: string]: unknown };
        axis_intersection?: string;
        background_layer?: Array<{ [key: string]: unknown }>;
        foreground_layer?: Array<{ [key: string]: unknown }>;
        tickLabelFormat?: string;
        width?: number;
        height?: number;
      };
      interactivity: {
        interactive_layer: Array<{
          [key: string]: unknown;
          plots?: Array<{ [key: string]: unknown }>;
        }>;
        unlock?: boolean; // client-local property
      };
    }
  | {
      // `histogram` subproblem type
      type: 'Histogram';
      state: {
        bargraph: boolean;
        charttitle: string | null | undefined;
        edgemode: boolean;
        hasgapbetweenbars: boolean;
        linegraph: boolean;
        // serialized histogram data format
        value: Array<any>;
        xaxistitle: string;
        yaxistitle: string;
        yincrement: number;
        ymax: number;
        ymin: number;
      };
    }
  | {
      // `multiple-choice` subproblem type
      type: 'MultipleChoice';
      available_values: {
        [index: NumericString]: { label: Html; value: string };
      };
      // values here should match a "value" from available_values
      values: ReadonlyArray<string>;
    }
  | {
      // `number-builder` subproblem type
      type: 'NumberBuilder';
      dropZones: DropZone[];
      itemType: ItemType;
      onlyAcceptLeastItems: true;
      value: NumberBuilderValue;
    }
  | {
      // `number-line` subproblem type
      type: 'NumberLine';
      end: NumericString;
      majorTicks: NumericString;
      minorTicks: NumericString;
      mode: Mode;
      start: NumericString;
      unit: LatexString;
      // server-side numberline data format
      value: ReadonlyArray<any>;
    }
  | {
      // `probability-tree` subproblem type
      type: 'ProbabilityTree';
      state: {
        value: Json;
        unequal_probabilities: boolean;
      };
    };

type Props = {
  datum: RequestDatum;
};

// Show histogram darker strokes every 5 "increments".
const HISTOGRAM_MAJOR_INCREMENT = 5;

const Answer = ({ datum }: Props) => {
  switch (datum.type) {
    case 'LatexString':
      return (
        <div>
          <MathContent content={datum.prefix} inline />
          <Latex latex={datum.value} />
          <MathContent content={datum.suffix} inline />
        </div>
      );
    case 'LatexReason':
      return (
        <div>
          <Latex latex={datum.value} />
          <MathContent content={`<p>(${datum.reason})</p>`} />
        </div>
      );
    case 'LatexMap':
      return <MathContent content={datum.value} />;
    case 'BoxablePlot':
      return (
        <BoxPlotReadOnly
          axisTitle={datum.eye_candy.axis_title}
          axisMax={parseFloat(datum.eye_candy.max)}
          axisMin={parseFloat(datum.eye_candy.min)}
          axisMajorTickInterval={parseFloat(datum.eye_candy.major_unit)}
          axisMinorTickInterval={parseFloat(datum.eye_candy.minor_unit)}
          value={{
            min: {
              value: parseFloat(datum.interactivity.min.value),
              substatus: 'UNKNOWN',
            },
            q1: {
              value: parseFloat(datum.interactivity.q1.value),
              substatus: 'UNKNOWN',
            },
            median: {
              value: parseFloat(datum.interactivity.median.value),
              substatus: 'UNKNOWN',
            },
            q3: {
              value: parseFloat(datum.interactivity.q3.value),
              substatus: 'UNKNOWN',
            },
            max: {
              value: parseFloat(datum.interactivity.max.value),
              substatus: 'UNKNOWN',
            },
          }}
        />
      );
    case 'PlottableGraph':
      return <GraphPlotReadOnly datum={datum} />;
    case 'Histogram':
      return (
        <HistogramReadOnly
          value={deserializeHistogram(datum.state.value)}
          labels={{
            main: datum.state.charttitle || '',
            xAxis: datum.state.xaxistitle,
            yAxis: datum.state.yaxistitle,
          }}
          increment={{
            max: datum.state.ymax,
            min: datum.state.ymin,
            step: datum.state.yincrement,
            tick: HISTOGRAM_MAJOR_INCREMENT,
          }}
          hasGapBetweenBars={datum.state.hasgapbetweenbars}
          doesDrawBars={datum.state.bargraph}
          doesDrawLines={datum.state.linegraph}
          dragHandleAlignment={datum.state.edgemode ? 'right' : 'center'}
        />
      );
    case 'MultipleChoice': {
      // TODO: Review this for improvement.
      const keys = Object.values(datum.available_values).map(v => v.value);
      const values = Object.values(datum.available_values).map(v => ({
        value: v.label,
        selected: false,
      }));
      const options = zipObj(keys, values);
      const selectedOptions = pick(datum.values, options);

      return <MultipleChoiceReadOnly value={selectedOptions} />;
    }
    case 'NumberBuilder':
      return (
        <NumberBuilderReadOnly
          dropZones={datum.dropZones}
          itemType={datum.itemType}
          value={datum.value}
        />
      );
    case 'NumberLine':
      return (
        <NumberLineIntervalsReadOnly
          end={deserializeCoordinateValues(datum.end)}
          majorTicks={deserializeCoordinateValues(datum.majorTicks)}
          minorTicks={deserializeCoordinateValues(datum.minorTicks)}
          mode={datum.mode}
          start={deserializeCoordinateValues(datum.start)}
          unit={datum.unit}
          value={compose(
            fromOldNumberLineValue,
            map(element =>
              mapValueRecursive(element, deserializeCoordinateValues),
            ),
            map(pointToSegment),
          )(datum.value)}
        />
      );
    case 'ProbabilityTree':
      return (
        <ProbabilityTreeReadOnly
          // Note that the ProbabilityTree component calls this "inEqualProbability",
          // but the request datum calls this "unequal_probabilities"
          inEqualProbability={datum.state.unequal_probabilities}
          value={deserializeProbabilityTree(datum.state.value)}
          useLatexProbabilityInput
        />
      );
    default:
      Logger.error('<Answer> component was given an unknown datum type!', {
        extra: { datum },
        tags: { component: 'Answer' },
      });
      return <span>This Answer could not be rendered.</span>;
  }
};

export default Answer;
