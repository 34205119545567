import { useId } from 'react';

export function SandcastleSvg({ style }: { style?: React.CSSProperties }) {
  const clipPathId = useId();
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <ellipse
        cx="11.4537"
        cy="18.0478"
        rx="10.8428"
        ry="3.07212"
        fill="#E5E6E8"
      />
      <g clipPath={`url(#${clipPathId})`}>
        <path
          d="M8.63451 1.93175L10.2661 6.32055C10.2933 3.34573 10.9442 2.54045 13.251 2.7122C11.3262 1.3306 10.3148 1.12779 8.63451 1.93175Z"
          fill="#E56565"
        />
        <path
          d="M11.3101 9.12896C11.1696 9.18123 11.0139 9.10993 10.9616 8.96934L8.29536 1.79754C8.24309 1.65695 8.31439 1.50127 8.45498 1.449C8.59557 1.39673 8.75125 1.46803 8.80351 1.60862L11.4691 8.77873C11.5214 8.91932 11.4501 9.075 11.3095 9.12727L11.3101 9.12896Z"
          fill="#AA8852"
        />
        <path
          d="M14.6072 7.49417V8.68145H13.3946V7.49417H11.6977V8.68145H10.4869V7.49417H8.79002V8.68145H7.57743V7.49417H5.71067L3.98125 17.8779C9.5879 18.7416 12.7033 18.7254 18.2034 17.8779L16.4739 7.49417H14.6072Z"
          fill="#D9AD69"
        />
        <path
          d="M12.444 17.8779C11.2911 18.2093 10.6794 18.1856 9.63396 17.8779V15.1257C9.66468 13.2788 12.4115 13.2788 12.444 15.1257V17.8779Z"
          fill="#AA8852"
        />
        <path
          d="M15.1367 11.0813C15.3442 11.0813 15.5125 10.913 15.5125 10.7054C15.5125 10.4978 15.3442 10.3296 15.1367 10.3296C14.9291 10.3296 14.7608 10.4978 14.7608 10.7054C14.7608 10.913 14.9291 11.0813 15.1367 11.0813Z"
          fill="#AA8852"
        />
        <path
          d="M15.5125 13.0619C15.7201 13.0619 15.8884 12.8936 15.8884 12.686C15.8884 12.4785 15.7201 12.3102 15.5125 12.3102C15.3049 12.3102 15.1367 12.4785 15.1367 12.686C15.1367 12.8936 15.3049 13.0619 15.5125 13.0619Z"
          fill="#AA8852"
        />
        <path
          d="M13.8211 12.3084C14.0287 12.3084 14.197 12.1401 14.197 11.9325C14.197 11.7249 14.0287 11.5566 13.8211 11.5566C13.6135 11.5566 13.4452 11.7249 13.4452 11.9325C13.4452 12.1401 13.6135 12.3084 13.8211 12.3084Z"
          fill="#AA8852"
        />
        <path
          d="M5.99981 16.1865C6.20741 16.1865 6.3757 16.0182 6.3757 15.8106C6.3757 15.603 6.20741 15.4347 5.99981 15.4347C5.79222 15.4347 5.62393 15.603 5.62393 15.8106C5.62393 16.0182 5.79222 16.1865 5.99981 16.1865Z"
          fill="#AA8852"
        />
        <path
          d="M6.3757 14.4932C6.58329 14.4932 6.75158 14.3249 6.75158 14.1173C6.75158 13.9097 6.58329 13.7414 6.3757 13.7414C6.16811 13.7414 5.99982 13.9097 5.99982 14.1173C5.99982 14.3249 6.16811 14.4932 6.3757 14.4932Z"
          fill="#AA8852"
        />
        <path
          d="M7.6443 15.5485C7.85189 15.5485 8.02018 15.3803 8.02018 15.1727C8.02018 14.9651 7.85189 14.7968 7.6443 14.7968C7.43671 14.7968 7.26842 14.9651 7.26842 15.1727C7.26842 15.3803 7.43671 15.5485 7.6443 15.5485Z"
          fill="#AA8852"
        />
      </g>
      <defs>
        <clipPath id={clipPathId}>
          <rect
            width="18.0713"
            height="18.0713"
            fill="white"
            transform="translate(2.05666 0.880081)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
