/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SchoolContextQueryVariables = {
    schoolId: string;
    numberOfClasses: number;
    numberOfStudents: number;
};
export type SchoolContextQueryResponse = {
    readonly school: {
        readonly schoolClasses: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly pk: number;
                    readonly code: string | null;
                    readonly title: string;
                    readonly displayName: string | null;
                    readonly isPartnerClass: boolean;
                    readonly isUnsyncedClass: boolean;
                    readonly studentCount: number;
                    readonly students: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly user: {
                                    readonly id: string;
                                    readonly firstName: string;
                                    readonly lastName: string;
                                    readonly avatar: string;
                                };
                            };
                        }>;
                    } | null;
                    readonly hasTasks: boolean;
                    readonly tasksCount: number;
                    readonly classSyllabusFocus: {
                        readonly id: string;
                    };
                    readonly classGrade: {
                        readonly id: string;
                    } | null;
                    readonly school: {
                        readonly id: string;
                        readonly title: string;
                    };
                };
            }>;
        } | null;
    } | null;
};
export type SchoolContextQuery = {
    readonly response: SchoolContextQueryResponse;
    readonly variables: SchoolContextQueryVariables;
};



/*
query SchoolContextQuery(
  $schoolId: ID!
  $numberOfClasses: Int!
  $numberOfStudents: Int!
) {
  school(id: $schoolId) {
    schoolClasses: classes(first: $numberOfClasses) {
      edges {
        node {
          id
          pk
          code
          title
          displayName
          isPartnerClass
          isUnsyncedClass
          studentCount
          students(first: $numberOfStudents) {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  avatar
                }
              }
            }
          }
          hasTasks
          tasksCount
          classSyllabusFocus {
            id
          }
          classGrade {
            id
          }
          school {
            id
            title
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfClasses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfStudents"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v4/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ClassEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Class",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPartnerClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUnsyncedClass",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "studentCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "first",
                "variableName": "numberOfStudents"
              }
            ],
            "concreteType": "StudentConnection",
            "kind": "LinkedField",
            "name": "students",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Student",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatar",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasTasks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tasksCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Syllabus",
            "kind": "LinkedField",
            "name": "classSyllabusFocus",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternGrade",
            "kind": "LinkedField",
            "name": "classGrade",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "School",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numberOfClasses"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": "schoolClasses",
            "args": null,
            "concreteType": "ClassConnection",
            "kind": "LinkedField",
            "name": "__School_schoolClasses_connection",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SchoolContextQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": "schoolClasses",
            "args": (v8/*: any*/),
            "concreteType": "ClassConnection",
            "kind": "LinkedField",
            "name": "classes",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": "schoolClasses",
            "args": (v8/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "School_schoolClasses",
            "kind": "LinkedHandle",
            "name": "classes"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9364413c9561f8411d2c00408bf51ec5",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "numberOfClasses",
          "cursor": null,
          "direction": "forward",
          "path": [
            "school",
            "schoolClasses"
          ]
        }
      ]
    },
    "name": "SchoolContextQuery",
    "operationKind": "query",
    "text": "query SchoolContextQuery(\n  $schoolId: ID!\n  $numberOfClasses: Int!\n  $numberOfStudents: Int!\n) {\n  school(id: $schoolId) {\n    schoolClasses: classes(first: $numberOfClasses) {\n      edges {\n        node {\n          id\n          pk\n          code\n          title\n          displayName\n          isPartnerClass\n          isUnsyncedClass\n          studentCount\n          students(first: $numberOfStudents) {\n            edges {\n              node {\n                id\n                user {\n                  id\n                  firstName\n                  lastName\n                  avatar\n                }\n              }\n            }\n          }\n          hasTasks\n          tasksCount\n          classSyllabusFocus {\n            id\n          }\n          classGrade {\n            id\n          }\n          school {\n            id\n            title\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '1fa31d5b3a3d87d10411a671d99dfec4';
export default node;
