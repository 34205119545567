import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import type { AlternateColorName } from 'ms-styles/colors';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import { InvariantViolation } from 'ms-utils/app-logging';

// colors in themes must have an alternate color
export type SupportedColorName = AlternateColorName;

type ColorTheme = {
  primary: SupportedColorName;

  dark: SupportedColorName;
  darkGray: SupportedColorName;
  gray: SupportedColorName;
  lightGray: SupportedColorName;
  light: SupportedColorName;

  gradingPositive: SupportedColorName;
  gradingWarning: SupportedColorName;
  gradingNegative: SupportedColorName;

  danger: SupportedColorName;
  warning: SupportedColorName;
  notification: SupportedColorName;
};

const teacherColorTheme: ColorTheme = {
  primary: 'eggplant',

  dark: 'cloudBurst',
  darkGray: 'shuttleGray',
  gray: 'grayChateau',
  lightGray: 'iron',
  light: 'white',

  gradingPositive: 'mountainMeadow',
  gradingWarning: 'saffron',
  gradingNegative: 'brickRed',

  warning: 'crusta',
  danger: 'cinnabar',
  notification: 'crusta',
};

const studentColorTheme: ColorTheme = {
  primary: 'eggplant',

  dark: 'cloudBurst',
  darkGray: 'shuttleGray',
  gray: 'grayChateau',
  lightGray: 'iron',
  light: 'white',

  gradingPositive: 'mountainMeadow',
  gradingWarning: 'saffron',
  gradingNegative: 'brickRed',

  warning: 'crusta',
  danger: 'cinnabar',
  notification: 'crusta',
};

const studentColorThemeAccessible: ColorTheme = {
  primary: 'cloudBurst',

  dark: 'cloudBurst',
  darkGray: 'shuttleGray',
  gray: 'grayChateau',
  lightGray: 'iron',
  light: 'white',

  gradingPositive: 'mountainMeadow',
  gradingWarning: 'saffron',
  gradingNegative: 'brickRed',

  warning: 'crusta',
  danger: 'cinnabar',
  notification: 'crusta',
};

const colorThemes = {
  teacherColorTheme,
  studentColorTheme,
  studentColorThemeAccessible,
};
type ColorThemeName = keyof typeof colorThemes;

const initialColorThemeName: ColorThemeName = 'teacherColorTheme';
type ColorThemeContextType = ColorTheme;
const initialColorThemeContextValue = colorThemes[initialColorThemeName];
const ColorThemeContext = createContext<ColorThemeContextType>(
  initialColorThemeContextValue,
);

type ColorThemeContextProviderProps = {
  children: ReactNode;
  defaultColorThemeName?: ColorThemeName;
};

export function ColorThemeContextProvider({
  children,
  defaultColorThemeName = initialColorThemeName,
}: ColorThemeContextProviderProps) {
  const viewer = useMaybeViewer();
  const { role } = viewer ?? {};
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const colorThemeName = (() => {
    if (viewer == null) return defaultColorThemeName;
    switch (role) {
      case 'Teacher':
        return 'teacherColorTheme';
      case 'Student': {
        return hasEnabledAccessibilityMode
          ? 'studentColorThemeAccessible'
          : 'studentColorTheme';
      }
      case 'Other':
        return 'teacherColorTheme';
      default: {
        const _exhaustiveCheck: never | void = role;
        throw new InvariantViolation(
          `Role must be either Teacher or Student, got ${_exhaustiveCheck}`,
        );
      }
    }
  })();

  return (
    <ColorThemeContext.Provider value={colorThemes[colorThemeName]}>
      {children}
    </ColorThemeContext.Provider>
  );
}

export function useColorTheme() {
  return useContext(ColorThemeContext);
}
