import styled from '@emotion/styled';
import { css, StyleSheet } from 'aphrodite';
import moment from 'moment';
import { useState, useCallback, useMemo, useEffect } from 'react';

import Calendar from 'ms-pages/Teacher/components/Calendar';
import { zIndex, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { useClickOutsideRef } from 'ms-ui-primitives/ClickOutside/hook';
import {
  setHoursWithMeridiem,
  setMeridiem,
} from 'ms-ui-primitives/DateTimeInput/helpers';
import { styles as inputStyles } from 'ms-ui-primitives/Input';

const Wrapper = styled.div({
  position: 'relative',
});

const Dropdown = styled.div({
  boxShadow: `0 8px 15px 0 rgba(0,0,0,0.1)`,
  borderRadius: '0 0 8px 8px',
  zIndex: zIndex.popover,
  background: colors.white,
  position: 'absolute',
});

const TimeWrapper = styled.div({
  display: 'flex',
  padding: 4 * BASE_UNIT,
});

const TimeInput = styled.input({
  border: 0,
  fontSize: fontSize.small,
  textAlign: 'center',
  borderBottom: `1px solid ${colors.mako}`,
  width: 50,
  margin: `0 ${2 * BASE_UNIT}px`,
});

const DateTimeInputField = styled.div();

const DateTimeInputStyle = StyleSheet.create({
  input: {
    display: 'flex',
    backgroundColor: colors.white,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: fontSize.small,
  },
  active: {
    borderColor: colors.lochmara,
  },
  error: {
    borderColor: colors.brickRed,
  },
});

const ErrorMessage = styled.div({
  fontSize: fontSize.small,
  color: colors.cinnabar,
  margin: 3 * BASE_UNIT,
});

type Props = {
  calendarPosition?: 'left' | 'right';
  onChange: (value: Date) => void;
  value: Date;
  minDate?: Date | null;
  showTime?: boolean;
  hasValidationError?: boolean;
};

const HOUR_MATCH_SOURCE = '^(1[0-2]|\\d|0\\d)$';
const MINUTE_MATCH_SOURCE = '^([0-5]\\d|\\d|0\\d)$';

const HOUR_MATCH_REGEXP = new RegExp(HOUR_MATCH_SOURCE);
const MINUTE_MATCH_REGEXP = new RegExp(MINUTE_MATCH_SOURCE);

export default function DateInput({
  calendarPosition = 'left',
  value,
  onChange,
  minDate,
  showTime = true,
  hasValidationError = false,
}: Props) {
  const [focused, setFocus] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [innerValue, setInnerValue] = useState<Date>(value);
  const innerOnChange = useCallback<(nextValue: Date) => void>(
    nextValue => {
      if (dirty === false) setDirty(true);
      setInnerValue(nextValue);

      // We don't propagate changes to the parent unless the input is valid
      if (minDate != null) {
        if (moment(minDate).isSameOrBefore(moment(nextValue), 'minute'))
          onChange(nextValue);
      } else {
        onChange(nextValue);
      }
    },
    [dirty, minDate, onChange],
  );

  const innerAndOuterSynced = useMemo(
    () => moment(value).isSame(moment(innerValue), 'second'),
    [innerValue, value],
  );

  useEffect(
    () => {
      if (!innerAndOuterSynced) {
        setInnerValue(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  );

  const clickOutsideRef = useClickOutsideRef(() => {
    setFocus(false);
    // we synchronize the external state with the internal one whenever the flyout is closed
    // to prevent inconsistencies with rendering
    if (!innerAndOuterSynced) {
      setInnerValue(value);
    }
  });
  return (
    <Wrapper>
      <DateTimeInputField
        className={css(
          inputStyles.input,
          DateTimeInputStyle.input,
          focused && DateTimeInputStyle.active,
          hasValidationError && DateTimeInputStyle.error,
        )}
        onClick={() => setFocus(true)}
      >
        {value
          ? moment(value).format(showTime ? 'DD MMM YY hh:mm A' : 'DD MMM YY')
          : ''}
      </DateTimeInputField>

      {focused && (
        <Dropdown
          ref={clickOutsideRef}
          style={{
            right: calendarPosition === 'right' ? 0 : undefined,
          }}
        >
          {/* For some bonkers reason ReactCalendar is mutating the min date that is passed in here
            We have to isolate it from the rest of the app by wrappping it in new Date()*/}
          <Calendar
            value={innerValue}
            onChange={date => {
              if (date instanceof Date) {
                // we want to persist the time when we switch dates
                if (showTime) {
                  date.setHours(innerValue.getHours());
                  date.setMinutes(innerValue.getMinutes());
                }
                innerOnChange(date);
              }
            }}
            // react-calendar doesn't support explicitly passing in nullish values
            {...(minDate != null ? { minDate: new Date(minDate) } : {})}
          />
          {showTime && (
            <TimeWrapper>
              <TimeInput
                defaultValue={moment(innerValue).format('hh')}
                onChange={e =>
                  HOUR_MATCH_REGEXP.test(e.target.value)
                    ? innerOnChange(
                        setHoursWithMeridiem(innerValue, e.target.value),
                      )
                    : null
                }
                placeholder="12"
                maxLength={2}
                pattern={HOUR_MATCH_SOURCE}
                type="text"
              />
              :
              <TimeInput
                defaultValue={moment(innerValue).format('mm')}
                onChange={e =>
                  MINUTE_MATCH_REGEXP.test(e.target.value)
                    ? innerOnChange(
                        moment(innerValue)
                          .minutes(parseInt(e.target.value))
                          .toDate(),
                      )
                    : null
                }
                maxLength={2}
                pattern={MINUTE_MATCH_SOURCE}
                placeholder="00"
                type="text"
              />
              <select
                value={moment(innerValue).format('A')}
                onChange={e => {
                  const validValue = e.target.value === 'PM' ? 'PM' : 'AM';
                  return innerOnChange(setMeridiem(innerValue, validValue));
                }}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </TimeWrapper>
          )}
          {minDate != null &&
            dirty &&
            moment(minDate).isAfter(moment(innerValue), 'minute') && (
              <ErrorMessage>
                You can't set a date before{' '}
                {moment(minDate).format('DD MMM YY hh:mm A')}
              </ErrorMessage>
            )}
        </Dropdown>
      )}
    </Wrapper>
  );
}
