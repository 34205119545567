import { useEffect, useMemo, useState } from 'react';

type Props = {
  sheet: {
    uri: string;
    width: number;
    height: number;
    topMargin: number;
    leftMargin: number;
  };
  frame: {
    height: number;
    width: number;
    verticalGap: number;
    horizontalGap: number;
  };
  frameRate: number;
  activeAnimation: number;
};

export default function SpriteSheet({
  sheet,
  frame,
  frameRate,
  activeAnimation,
}: Props) {
  const intervalMs = useMemo(() => 1000 / frameRate, [frameRate]);
  const [currentFrame, setFrame] = useState(0);

  const maxFrames = useMemo(() => {
    return Math.floor(
      (sheet.width + frame.horizontalGap - sheet.leftMargin) /
        (frame.width + frame.horizontalGap),
    );
  }, [frame.horizontalGap, frame.width, sheet.leftMargin, sheet.width]);

  const numberOfAnimations = useMemo(() => {
    return Math.floor(
      (sheet.height + frame.verticalGap - sheet.topMargin) /
        (frame.height + frame.verticalGap),
    );
  }, [frame.height, frame.verticalGap, sheet.height, sheet.topMargin]);

  if (activeAnimation >= numberOfAnimations) {
    throw new Error(
      `There are only ${numberOfAnimations} in ${sheet.uri}. Got ${activeAnimation}`,
    );
  }

  const backgroundPosition = useMemo(() => {
    let left = sheet.leftMargin * -1;
    let top = sheet.topMargin * -1;

    left -= currentFrame * (frame.width + frame.horizontalGap);
    top -= activeAnimation * (frame.height + frame.verticalGap);

    if (left + frame.width > sheet.width) left = sheet.leftMargin * -1;
    if (top + frame.height > sheet.height) top = sheet.topMargin * -1;
    return `${left}px ${top}px`;
  }, [
    activeAnimation,
    currentFrame,
    frame.height,
    frame.horizontalGap,
    frame.verticalGap,
    frame.width,
    sheet.height,
    sheet.leftMargin,
    sheet.topMargin,
    sheet.width,
  ]);

  useEffect(() => {
    let timeoutHandle: NodeJS.Timeout;
    function updateFrame() {
      setFrame(frame => {
        if (frame + 1 >= maxFrames) return 0;
        return frame + 1;
      });
      timeoutHandle = setTimeout(updateFrame, intervalMs);
    }
    timeoutHandle = setTimeout(updateFrame, intervalMs);
    return () => clearTimeout(timeoutHandle);
  }, [intervalMs, maxFrames]);
  return (
    <div
      style={{
        height: frame.height,
        width: frame.width,
        backgroundImage: `url(${sheet.uri})`,
        backgroundPosition,
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
}
