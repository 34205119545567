import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from './types';

const path = (
  <path
    fillRule="evenodd"
    d="M42.5 21.875c0 11.598-9.402 21-21 21s-21-9.402-21-21 9.402-21 21-21 21 9.402 21 21zM30.681 9.015l-4.486 4.487-3.051 3.052-.561.56-2.304 2.305-1.63 1.63-1.908 1.908-1.614 1.613-.678.678-5.807 5.807a15.728 15.728 0 01-2.94-9.18c0-8.726 7.073-15.8 15.798-15.8 3.424 0 6.594 1.09 9.181 2.94zm6.617 12.86c0 8.725-7.073 15.798-15.798 15.798-3.424 0-6.594-1.09-9.181-2.94l5.807-5.806 2.292-2.292.854-.856.882-.882.171-.17 3.676-3.677.259-.257.375-.375 2.173-2.174 2.6-2.6 2.95-2.95a15.728 15.728 0 012.94 9.18z"
    clipRule="evenodd"
  />
);

const Stop = ({ color, size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={43}
    viewBoxWidth={43}
  >
    {path}
  </Icon>
);

Stop.displayName = 'Stop';

export default Stop;
