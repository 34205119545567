import moment from 'moment';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

import type { SelectedFolderId } from '../../types';

export type TaskName = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;
export type IsAdvancedOptionsVisible = boolean;
export type ExtensionDays = number;
export type HelpOptions = boolean;
export type HideCalculator = boolean;
export type HideRetry = boolean;
export type QuestionVariation = 'varyForEachStudent' | 'sameForEveryStudent';
export type SaveThisAsTemplate = boolean;

export type State = {
  taskName: TaskName;
  edited: boolean;
  templateId?: string | null | undefined;
  templateGroupId?: SelectedFolderId;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  isAdvancedOptionsVisible: IsAdvancedOptionsVisible;
  extensionDays: ExtensionDays;
  hideHelpOptions: HelpOptions;
  hideCalculator: HideCalculator;
  hideRetry: HideRetry;
  questionVariation: QuestionVariation;
  saveThisAsTemplate: SaveThisAsTemplate;
  selectedClasses: readonly ClassSelectionPayload[];
  selectedStudents: readonly StudentSelectionPayload[];
  excludedStudents: readonly StudentSelectionPayload[];
};

export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;

// this has to be recreated each time to keep the dates synced
export const initialState = (): State => ({
  taskName: 'Untitled task',
  edited: false,
  templateId: null,
  templateGroupId: null,
  taskStartDate: moment().seconds(0).milliseconds(0).toDate(),
  taskDueDate: moment()
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .add(7, 'days')
    .toDate(),
  isAdvancedOptionsVisible: false,
  extensionDays: 0,
  hideHelpOptions: false,
  hideCalculator: false,
  hideRetry: false,
  questionVariation: 'varyForEachStudent',
  saveThisAsTemplate: false,
  selectedClasses: [],
  selectedStudents: [],
  excludedStudents: [],
});
