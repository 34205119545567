import { StyleSheet, css } from 'aphrodite';
import { useState } from 'react';

import { color } from 'ms-styles/theme/Bootstrap';

export function Node({
  id = 0,
  isFocused = false,
  readOnly = false,
  cx = 0,
  cy = 0,
  onOpenMenu,
}: {
  id: number;
  isFocused: boolean;
  cx: number;
  cy: number;
  onOpenMenu: Function;
  readOnly?: boolean | undefined;
}) {
  const [isHovered, setIsHovered] = useState(false);

  const radius = 5;
  const isReadOnly = readOnly || false;

  const eventHandlers = readOnly
    ? {}
    : {
        onMouseEnter: () => setIsHovered(true),
        onMouseLeave: () => setIsHovered(false),
        onClick: (event: React.MouseEvent) => {
          event.stopPropagation();
          onOpenMenu(id);
        },
      };

  return (
    <circle
      data-testid="probability-tree-node"
      className={css(styles.node, isReadOnly && styles.readOnly)}
      r={isFocused || isHovered ? radius * 2 : radius}
      cx={cx}
      cy={cy}
      {...eventHandlers}
    />
  );
}

const styles = StyleSheet.create({
  node: {
    cursor: 'pointer',
    fill: color.interactive,
    outline: 'none',
    stroke: '#fff',
    strokeOpacity: 0,
    strokeWidth: 25,
    transition: 'all 0.1s ease-in-out',
  },
  readOnly: {
    cursor: 'auto',
    fill: color.readOnly,
  },
});
