import { css, StyleSheet } from 'aphrodite';
import { forwardRef, Children, type ReactNode } from 'react';

import { styledVerticallyScrollable } from 'ms-utils/emotion';

import type { NamedColor, OneOrMoreElements, StyleValue } from '../helpers';
import {
  backgroundToStyles,
  findReactChildByType,
  findReactChildrenByType,
} from '../helpers';

const maxWidthToStyles = (maxWidth: StyleValue | undefined) => ({
  maxWidth,
  margin: maxWidth ? '0 auto' : undefined,
});

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    ...styledVerticallyScrollable,
  },
  header: {
    position: 'relative',
    maxWidth: '100%',
    minWidth: 0, // https://stackoverflow.com/a/43312314
  },
  main: {
    position: 'relative',
    width: '100%',
    minWidth: 0, // https://stackoverflow.com/a/43312314
  },
});

type MainProps = {
  padding?: StyleValue | undefined;
  paddingTop?: StyleValue | undefined;
  paddingBottom?: StyleValue | undefined;
  paddingLeft?: StyleValue | undefined;
  paddingRight?: StyleValue | undefined;
  background?: NamedColor | undefined;
  children: ReactNode;
  style?: {} | undefined;
};

export const Main = forwardRef<HTMLDivElement, MainProps>(
  ({ children, background, style, ...styleProps }, ref) => (
    <div
      className={css(styles.main)}
      ref={ref}
      style={{
        ...backgroundToStyles(background),
        ...styleProps,
        ...style,
      }}
    >
      {children}
    </div>
  ),
);

type HeaderProps = {
  maxWidth?: StyleValue | undefined;
  height?: StyleValue | undefined;
  paddingTop?: StyleValue | undefined;
  paddingBottom?: StyleValue | undefined;
  paddingLeft?: StyleValue | undefined;
  paddingRight?: StyleValue | undefined;
  background?: NamedColor | undefined;
  children: ReactNode;
};

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ children, maxWidth, background, ...styleProps }, ref) => (
    <div
      className={css(styles.header)}
      ref={ref}
      style={{
        ...maxWidthToStyles(maxWidth),
        ...backgroundToStyles(background),
        ...styleProps,
      }}
    >
      {children}
    </div>
  ),
);

type Props = {
  gutter?: StyleValue | undefined;
  maxWidth?: StyleValue | undefined;
  children: OneOrMoreElements<typeof Main | typeof Header>;
  paddingLeft?: StyleValue | undefined;
  paddingRight?: StyleValue | undefined;
};

export const Layout = forwardRef<HTMLDivElement, Props>(
  ({ children, gutter, maxWidth, ...styleProps }: Props, ref) => {
    const headers = findReactChildrenByType(children, Header);
    const count = Children.count(headers);

    return (
      <div
        ref={ref}
        className={css(styles.root)}
        style={{
          gridTemplateRows: `repeat(${count}, max-content) 1fr`,
          gridGap: gutter,
          ...maxWidthToStyles(maxWidth),
          ...styleProps,
        }}
      >
        {headers}
        {findReactChildByType(children, Main)}
      </div>
    );
  },
);

// @ts-expect-error after having wrapped Layout in forwardRef this is failing to typecheck
Layout.Main = Main;
// @ts-expect-error after having wrapped Layout in forwardRef this is failing to typecheck
Layout.Header = Header;

const ExportedLayout = Layout as typeof Layout & {
  Main: typeof Main;
  Header: typeof Header;
};

export default ExportedLayout;
