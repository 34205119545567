import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const StudentExitedTask = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <defs>
      <path
        d="M8.26721357,9.22986517 L6.38739335,11.1161348 C6.22480908,11.27 6.14361806,11.4625618 6.14361806,11.6936629 C6.14361806,11.9245169 6.22483155,12.1170787 6.38739335,12.2710337 L6.38739335,12.2710337 L6.86858436,12.7585169 C7.02685402,12.9167865 7.21932593,12.9959775 7.44595514,12.9959775 C7.66838211,12.9959775 7.86301132,12.9167865 8.02979784,12.7585169 L8.02979784,12.7585169 L12.2065169,8.57532584 C12.3647866,8.41714607 12.4439102,8.22467416 12.4439102,7.99795506 C12.4439102,7.77555056 12.3647866,7.58092135 12.2065169,7.41411236 L12.2065169,7.41411236 L8.02979784,3.24377528 C7.8673484,3.08123596 7.67267424,3 7.44595514,3 C7.22355065,3 7.03105627,3.08132584 6.86856188,3.24377528 L6.86856188,3.24377528 L6.38737087,3.71858427 C6.2247866,3.88110112 6.14359559,4.07575281 6.14359559,4.30238202 C6.14359559,4.5291236 6.22480908,4.72377528 6.38737087,4.88622472 L6.38737087,4.88622472 L8.2671911,6.76608989 L0,6.766 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L12,0 C14.209139,-4.05812251e-16 16,1.790861 16,4 L16,12 C16,14.209139 14.209139,16 12,16 L4,16 C1.790861,16 2.705415e-16,14.209139 0,12 L0,9.229 L8.26721357,9.22986517 Z"
        id="path-1"
      />
    </defs>

    <mask id="mask-2" fill="white">
      <use xlinkHref="#path-1" />
    </mask>

    <use id="Combined-Shape" fill={color} xlinkHref="#path-1" />
  </Icon>
);

StudentExitedTask.displayName = 'StudentExitedTask';

export default StudentExitedTask;
