import styled from '@emotion/styled';
import { useRef, type ReactNode } from 'react';

import KebabDropdown from 'ms-components/KebabDropdown';
import InfoIcon from 'ms-pages/Lantern/components/icons/Info';
import {
  BodyM,
  HeadingM,
  HeadingS,
} from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import type { MenuItem } from 'ms-ui-primitives/DropdownMenu';
import Popover from 'ms-ui-primitives/Popover/PopoverInternal';
import { HSpacer, VStack, VSpacer, HStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

const TOOLTIP_PADDING_X = 12;
const TOOLTIP_PADDING_Y = 8;
const TOOLTIP_WIDTH = 340;

const Tooltip = styled.div({
  width: TOOLTIP_WIDTH,
  color: 'white',
  textAlign: 'left',
  backgroundColor: colors.grey,
  borderRadius: 8,
  padding: `${TOOLTIP_PADDING_Y}px ${TOOLTIP_PADDING_X}px`,
});

const InfoIconWrapper = styled.div({
  // attempt to center the icon vertically (there is no easy way
  // to center the icon for both small and medium headings)
  display: 'flex',
  position: 'relative',
  top: '0.03em',
});

const Root = styled(VStack)({
  position: 'relative',
  alignItems: 'center',
  width: '100%',
});

const KebabMenuWrapper = styled.div({
  position: 'absolute',
  top: 12,
  height: 32,
  right: 0,
  display: 'flex',
  alignItems: 'center',
});

function Header({
  title,
  titleSize = 'small',
  description,
  children,
  tooltipContent,
  dropdownMenuItems = [],
}: {
  title: string;
  dropdownMenuItems?: ReadonlyArray<MenuItem> | undefined;
  titleSize?: 'small' | 'medium' | undefined;
  description?: ReactNode | undefined;
  children?: ReactNode | undefined;
  tooltipContent?: ReactNode | undefined;
}) {
  const heading =
    titleSize === 'medium' ? (
      <HeadingM>{title}</HeadingM>
    ) : (
      <HeadingS>{title}</HeadingS>
    );
  return (
    <Root>
      <VSpacer height={12} />

      {tooltipContent != null ? (
        <Tooltipped tooltipContent={tooltipContent}>{heading}</Tooltipped>
      ) : (
        heading
      )}

      {description != null && (
        <>
          <VSpacer height={8} />
          <BodyM color="shuttleGray">{description}</BodyM>
        </>
      )}
      {children != null && (
        <>
          <VSpacer height={24} />
          {children}
        </>
      )}
      <VSpacer height={24} />

      {dropdownMenuItems.length > 0 && (
        <KebabMenuWrapper>
          <KebabDropdown
            items={dropdownMenuItems}
            activeColor={colors.eggplant}
          />
        </KebabMenuWrapper>
      )}
    </Root>
  );
}

function Tooltipped({
  children,
  tooltipContent,
}: {
  children: ReactNode;
  tooltipContent: ReactNode;
}) {
  const isTooltipVisible = useBoolean();
  const tooltipAnchorRef = useRef<HTMLDivElement>(null);

  return (
    <div
      onMouseEnter={isTooltipVisible.setTrue}
      onMouseLeave={isTooltipVisible.setFalse}
      onClick={isTooltipVisible.toggle}
    >
      <HStack center>
        {children}
        <HSpacer width={8} />
        <InfoIconWrapper ref={tooltipAnchorRef}>
          <InfoIcon color={colors.grey90} size={16} />
        </InfoIconWrapper>
      </HStack>
      {isTooltipVisible.value && (
        <Popover
          anchorElementRef={tooltipAnchorRef}
          onDismiss={isTooltipVisible.setFalse}
          anchorOrigin={['bottom', 'center']}
          popoverOrigin={['top', 'center']}
          shouldDismissOnTapOut
          hOffset={0}
          vOffset={8}
          renderOverlay={false}
          shouldDismissOnEsc
          shouldDismissOnOwnScroll={false}
          shouldDismissOnScroll={false}
          shouldDismissOnTab
        >
          <Tooltip>{tooltipContent}</Tooltip>
        </Popover>
      )}
    </div>
  );
}

export default Header;
