import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function Duplicate({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      viewBoxHeight={15}
      viewBoxWidth={14}
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7803 0.5C12.6686 0.5 13.3886 1.21809 13.3886 2.1042V9.3958C13.3886 10.2819 12.6686 11 11.7803 11H5.63912C4.75068 11 4.03069 10.2819 4.03069 9.3958V2.1042C4.03069 1.21809 4.75068 0.5 5.63912 0.5H11.7803ZM3.15341 9.39579C3.15341 10.7626 4.26879 11.875 5.63915 11.875H11.0492V12.8958C11.0492 13.7807 10.328 14.5 9.44075 14.5H2.12992C1.24265 14.5 0.521484 13.7807 0.521484 12.8958V5.02079C0.521484 4.13586 1.24265 3.4167 2.12992 3.4167H3.15341V9.39579Z"
        fill={color}
      />
    </Icon>
  );
}
