import { css, StyleSheet } from 'aphrodite';

type Props = {
  value: number;
};

const styles = StyleSheet.create({
  counter: {
    alignItems: 'center',
    backgroundColor: '#f2483b',
    borderRadius: '25px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
    color: '#fff',
    display: 'flex',
    fontFamily: 'Helvetica, sans-serif',
    fontSize: '20px',
    fontWeight: 'bold',
    height: '50px',
    justifyContent: 'center',
    userSelect: 'none',
    width: '50px',
  },
});

const Counter = ({ value }: Props) => (
  <div className={css(styles.counter)}>{value}</div>
);

export default Counter;
