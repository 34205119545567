import Modal from 'ms-pages/Sunflower/ui-primitives/Modal';
import { TeacherCreateStudent } from 'ms-pages/Teacher/components/StudentCreateEdit';
import { styled } from 'ms-utils/emotion';

import { EMPTY_ITEM } from '../state';
import type {
  Classes,
  SyllabusFocusSelectedClass,
  GradeSelectedClass,
} from '../state/State';

type Props = {
  initialClasses?: Classes | undefined;
  initialSyllabusFocusSelectedClass?: SyllabusFocusSelectedClass | undefined;
  initialGradeSelectedClass?: GradeSelectedClass | undefined;
  isOpen: boolean;
  onClose: () => void;
  onStudentCreated: () => void;
  onBack?: (() => void) | undefined;
};

const Container = styled({
  maxHeight: '78vh',
  paddingRight: '12px',
  overflowX: 'hidden',
  overflowY: 'auto',
});

const CreateStudentModal = ({
  initialClasses = [],
  initialSyllabusFocusSelectedClass = EMPTY_ITEM,
  initialGradeSelectedClass = EMPTY_ITEM,
  isOpen,
  onClose,
  onStudentCreated,
  onBack,
}: Props) => (
  <Modal
    onBack={onBack}
    isOpen={isOpen}
    onClose={onClose}
    title="Create a new student"
    noMinHeight
  >
    <Container>
      <TeacherCreateStudent
        initialClasses={initialClasses}
        initialSyllabusFocusSelectedClass={initialSyllabusFocusSelectedClass}
        initialGradeSelectedClass={initialGradeSelectedClass}
        onCancel={onClose}
        onStudentCreated={onStudentCreated}
      />
    </Container>
  </Modal>
);

export default CreateStudentModal;
