/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PartiallyAssignedClassInput = {
    classId: string;
    studentIds: Array<string>;
};
export type createNewAdaptiveExperienceTaskMutationVariables = {
    title: string;
    subtopicId: string;
    targetMastery?: number | null | undefined;
    assignedClassIds: Array<string>;
    assignedStudentIds: Array<string>;
    partiallyAssignedClasses: Array<PartiallyAssignedClassInput>;
    dueDate: string;
    startDate: string;
    allowCalculator: boolean;
    assignedCheckinSkillIds: Array<string>;
};
export type createNewAdaptiveExperienceTaskMutationResponse = {
    readonly createNewAdaptiveExperienceTask: {
        readonly errors: ReadonlyArray<{
            readonly message: string;
        }>;
        readonly task: {
            readonly id: string;
        } | null;
    };
};
export type createNewAdaptiveExperienceTaskMutation = {
    readonly response: createNewAdaptiveExperienceTaskMutationResponse;
    readonly variables: createNewAdaptiveExperienceTaskMutationVariables;
};



/*
mutation createNewAdaptiveExperienceTaskMutation(
  $title: String!
  $subtopicId: ID!
  $targetMastery: Int
  $assignedClassIds: [ID!]!
  $assignedStudentIds: [ID!]!
  $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
  $dueDate: DateTime!
  $startDate: DateTime!
  $allowCalculator: Boolean!
  $assignedCheckinSkillIds: [ID!]!
) {
  createNewAdaptiveExperienceTask(title: $title, assignedClassIds: $assignedClassIds, targetMastery: $targetMastery, assignedStudentIds: $assignedStudentIds, partiallyAssignedClasses: $partiallyAssignedClasses, dueDate: $dueDate, startDate: $startDate, subtopicId: $subtopicId, allowCalculator: $allowCalculator, assignedCheckinSkillIds: $assignedCheckinSkillIds) {
    errors {
      message
    }
    task {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allowCalculator"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignedCheckinSkillIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignedClassIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignedStudentIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dueDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partiallyAssignedClasses"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subtopicId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetMastery"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "allowCalculator",
        "variableName": "allowCalculator"
      },
      {
        "kind": "Variable",
        "name": "assignedCheckinSkillIds",
        "variableName": "assignedCheckinSkillIds"
      },
      {
        "kind": "Variable",
        "name": "assignedClassIds",
        "variableName": "assignedClassIds"
      },
      {
        "kind": "Variable",
        "name": "assignedStudentIds",
        "variableName": "assignedStudentIds"
      },
      {
        "kind": "Variable",
        "name": "dueDate",
        "variableName": "dueDate"
      },
      {
        "kind": "Variable",
        "name": "partiallyAssignedClasses",
        "variableName": "partiallyAssignedClasses"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "subtopicId",
        "variableName": "subtopicId"
      },
      {
        "kind": "Variable",
        "name": "targetMastery",
        "variableName": "targetMastery"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "CreateNewAdaptiveExperienceTaskResult",
    "kind": "LinkedField",
    "name": "createNewAdaptiveExperienceTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NewAdaptiveExperienceTask",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createNewAdaptiveExperienceTaskMutation",
    "selections": (v10/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "createNewAdaptiveExperienceTaskMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "a72fdb72f00ab41c781a3a9dea81864d",
    "id": null,
    "metadata": {},
    "name": "createNewAdaptiveExperienceTaskMutation",
    "operationKind": "mutation",
    "text": "mutation createNewAdaptiveExperienceTaskMutation(\n  $title: String!\n  $subtopicId: ID!\n  $targetMastery: Int\n  $assignedClassIds: [ID!]!\n  $assignedStudentIds: [ID!]!\n  $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!\n  $dueDate: DateTime!\n  $startDate: DateTime!\n  $allowCalculator: Boolean!\n  $assignedCheckinSkillIds: [ID!]!\n) {\n  createNewAdaptiveExperienceTask(title: $title, assignedClassIds: $assignedClassIds, targetMastery: $targetMastery, assignedStudentIds: $assignedStudentIds, partiallyAssignedClasses: $partiallyAssignedClasses, dueDate: $dueDate, startDate: $startDate, subtopicId: $subtopicId, allowCalculator: $allowCalculator, assignedCheckinSkillIds: $assignedCheckinSkillIds) {\n    errors {\n      message\n    }\n    task {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3e866f353e0b3d8871a2c339a0a634a2';
export default node;
