import type { ReactNode } from 'react';

import MathExpressionQuestion from 'ms-pages/Lantern/components/shared-with-diagnostic-questions-editor/MathExpressionQuestion';
import MultipleChoiceQuestion from 'ms-pages/Lantern/components/shared-with-diagnostic-questions-editor/MultipleChoiceQuestion';
import { VStack } from 'ms-pages/Lantern/primitives/Stack';
import { colors } from 'ms-pages/Lantern/styles';
import {
  useAccessibilityMode,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
} from 'ms-utils/accessibility';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { StudentAnswer, Question } from './types';

function CheckInSessionQuestionWrapper({ children }: { children: ReactNode }) {
  return (
    <VStack
      css={{
        padding: 24,
        backgroundColor: colors.white,
        borderRadius: 8,
        boxShadow: '0px 8.75px 49px rgba(33, 55, 77, 0.15)',
      }}
    >
      {children}
    </VStack>
  );
}

function CheckInSessionQuestion({
  question,
  answer,
  onChange,
}: {
  question: Question;
  answer: StudentAnswer;
  onChange: (answer: StudentAnswer) => void;
}) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const { __typename } = question;

  switch (__typename) {
    case 'MathExpressionQuestion':
      return (
        // `MathInput` used in `MathExpressionQuestion` is an uncontrolled input
        <MathExpressionQuestion
          // Simple (but not performant) way to ensure the stateful
          // MathInput is cleared out when we change between questions.
          key={question.id}
          text={question.transpiledText}
          onChange={onChange}
          // For users who are not using screen readers to interact with the
          // page, we want to autoFocus the math input for convenience. For
          // those using screen readers, it is important that they read the
          // question first. Thus we don't want to force the focus to teleport
          // them to the math input when the question mounts.
          autoFocusMathInput={hasEnabledAccessibilityMode === false}
          accessibilityModeConfig={
            hasEnabledAccessibilityMode
              ? {
                  borderColor: FOCUSED_ACCESSIBILITY_MODE_BORDER_COLOR,
                  borderWidth: FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
                }
              : null
          }
        />
      );
    case 'MultipleChoiceQuestion': {
      return (
        <MultipleChoiceQuestion
          text={question.transpiledText}
          options={question.transpiledOptions}
          // This is to avoid type casting of `answer` - it will work until
          // we add another array to the `answer` union type.
          selectedOptions={
            answer === null || Array.isArray(answer) ? answer : []
          }
          onChange={onChange}
          numberOfAnswers={question.numberOfAnswers}
          accessibilityMode={hasEnabledAccessibilityMode}
        />
      );
    }
    default:
      if (__typename === '%other') throw Error(); // type narrowing
      assertUnreachable(
        __typename,
        `Exhaustiveness guarantee violated at runtime by question type: ${JSON.stringify(
          __typename,
        )}`,
      );
  }
}

export { CheckInSessionQuestion, CheckInSessionQuestionWrapper };
