import Latex from 'ms-components/Latex';
import type { Value } from 'ms-components/math/LatexAnswer';
import MathContent from 'ms-components/math/MathContent';
import { unwrap } from 'ms-utils/typescript-utils';

type Html = string;

type Props = {
  template: Html;
  value: Value;
};

const LatexAnswerReadOnly = ({ template, value }: Props) => (
  <div>
    <MathContent
      content={template}
      nodeRenderers={{
        LATEX_INPUT_STATIC_OR_DYNAMIC: ({ key, answerKey }) => (
          <Latex key={key} latex={unwrap(value[answerKey]).latex} />
        ),
      }}
    />
  </div>
);

export default LatexAnswerReadOnly;
