import styled from '@emotion/styled';
import { Fragment } from 'react';

import CheckMarkIcon from 'ms-components/icons/CheckMarkThick';
import { fontSize, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { Logger } from 'ms-utils/app-logging';

export type Step = {
  active: boolean;
  completed: boolean;
};

type Props = {
  steps: ReadonlyArray<Step>;
};

const DEFAULT_COLOR = colors.iron;
const DARK_COLOR = colors.cloudBurst;

const Line = styled.div<{ dark: boolean }>(({ dark }) => ({
  height: 2,
  flex: 1,
  backgroundColor: DEFAULT_COLOR,
  ...(dark ? { backgroundColor: DARK_COLOR } : {}),
}));

const CrumbTray = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const CrumbStyle = styled.div<{ active: boolean; filled: boolean }>(
  ({ active, filled }) => ({
    borderRadius: '50%',
    borderWidth: 2,
    borderStyle: 'solid',
    width: 6 * BASE_UNIT,
    height: 6 * BASE_UNIT,
    padding: 0,
    fontSize: fontSize.small,
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    lineHeight: 1,
    outline: 'none',
    WebkitFontSmoothing: 'subpixel-antialiased',
    borderColor: DEFAULT_COLOR,
    color: DEFAULT_COLOR,
    ...(active ? { borderColor: DARK_COLOR, color: DARK_COLOR } : {}),
    ...(filled ? { backgroundColor: DARK_COLOR, color: colors.white } : {}),
  }),
);

export function getPankoStep(active: number, max: number): ReadonlyArray<Step> {
  if (max < active)
    Logger.error(
      'Invalid panko configuration. Max number of steps must be greater than active step',
    );
  if (active < 1)
    Logger.error(
      'Panko steps are 1 indexed. Ensure that you do not pass a value less than 1 into getPankStep',
    );
  // every past step must be completed by default
  // be conservative in case an invalid value is passed in
  const numberOfPast = active > 0 ? active - 1 : 0;
  const past = new Array(numberOfPast).fill({
    active: false,
    completed: true,
  });
  // the -1 here makes space for the "current" one
  // be conservative in case an invalid value is passed in
  const numberOfFuture = max >= active ? max - active : 0;
  const future = new Array(numberOfFuture).fill({
    active: false,
    completed: false,
  });

  return past.concat({ active: true, completed: false }).concat(future);
}

function Crumb({
  step: { active, completed },
  index,
}: {
  step: Step;
  index: number;
}) {
  return (
    <CrumbStyle filled={completed} active={active || completed}>
      {completed ? <CheckMarkIcon /> : index + 1}
    </CrumbStyle>
  );
}

function Panko({ steps }: Props) {
  return (
    <CrumbTray>
      {steps.map((step, i) => (
        <Fragment key={i}>
          {i > 0 && <Line dark={step.completed || step.active} />}
          <Crumb step={step} index={i} />
        </Fragment>
      ))}
    </CrumbTray>
  );
}

export default Panko;
