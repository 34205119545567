export const color = Object.freeze({
  correct: '#2ECC71',
  incorrect: '#E74C3C',
  interactive: '#3498DB',
  readOnly: '#444',
});

export const font = Object.freeze({
  body: 'Proxima Nova',
  serif: 'TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif',
});
