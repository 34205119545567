/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandSkills_skillsBadge = {
    readonly id: string;
    readonly title: string;
    readonly code: string;
    readonly skill: {
        readonly id: string;
    };
    readonly " $fragmentRefs": FragmentRefs<"SkillPopover_outcome">;
    readonly " $refType": "StudentSkillsMapSubstrandSkills_skillsBadge";
};
export type StudentSkillsMapSubstrandSkills_skillsBadge$data = StudentSkillsMapSubstrandSkills_skillsBadge;
export type StudentSkillsMapSubstrandSkills_skillsBadge$key = {
    readonly " $data"?: StudentSkillsMapSubstrandSkills_skillsBadge$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandSkills_skillsBadge">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandSkills_skillsBadge",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SkillPopover_outcome"
    }
  ],
  "type": "LanternOutcome",
  "abstractKey": null
};
})();
(node as any).hash = '95cec77c219ee3ee26ef884f22d3f1a4';
export default node;
