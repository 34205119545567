type Props = {
  sizeRatio?: number | undefined;
};

export default function MiloOnLargeScreen({ sizeRatio = 1 }: Props) {
  return (
    <svg
      width={sizeRatio * 81}
      height={sizeRatio * 77}
      viewBox="0 0 81 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.1998 69.4009L48.3068 62.7771L40.4999 57.9481L32.6931 62.7771L31.3136 67.6059L31.3133 67.6061L30.7998 69.4008C30.0706 71.9554 27.3004 74.0447 24.6444 74.0447V74.0447H15.5501C14.8834 74.0447 14.3429 74.5851 14.3429 75.252C14.3429 75.9188 14.8834 76.4592 15.5501 76.4592H65.4497C66.1164 76.4592 66.657 75.9188 66.657 75.252C66.657 74.5851 66.1164 74.0447 65.4497 74.0447H56.3461C53.6929 74.04 50.9281 71.9525 50.1998 69.4009Z"
        fill="#DADADA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.32911 62.7776C2.67317 62.7776 0.500122 60.6045 0.500122 57.9486V48.2906L75.6714 28.9746V43.4623L80.4999 48.2908V57.9488C80.4999 60.6047 78.3268 62.7778 75.6709 62.7778H70.8419V62.7776H5.32911Z"
        fill="#E5E6E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.8419 0H70.8424H75.6709C78.3268 0 80.4999 2.17305 80.4999 4.82899V48.2899H75.6714H75.6709H0.500122V4.82899C0.500122 2.17305 2.67317 0 5.32911 0H70.8419ZM75.1599 43.18L40.5003 24.1429L71.5511 7.09164L75.1599 43.18Z"
        fill="#3E3E4C"
      />
      <path
        d="M76.3332 4.89988L4.68176 4.89636C4.67735 4.89828 4.67366 43.229 4.66626 43.2298C4.66626 43.2298 76.3297 43.2266 76.3314 43.2266C76.3323 43.2266 76.3258 4.90051 76.3332 4.89988Z"
        fill="#D7D6E8"
      />
      <mask
        id="mask0_1293_44664"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="4"
        width="66"
        height="40"
      >
        <path
          d="M70.843 4.82825L5.34388 4.82471C5.33985 4.82664 5.33647 43.4602 5.32971 43.461C5.32971 43.461 70.8398 43.4578 70.8414 43.4578C70.8422 43.4578 70.8363 4.82889 70.843 4.82825Z"
          fill="#5D51AE"
        />
      </mask>
      <g mask="url(#mask0_1293_44664)">
        <path
          d="M32.2288 22.7934C31.6686 22.5903 29.027 21.3004 25.8185 22.6952C22.6065 24.0935 21.0447 31.7553 23.6896 35.6217C26.338 39.4915 30.4429 28.5491 30.8978 27.3235C31.3528 26.0978 33.1286 23.1185 32.2288 22.7934Z"
          fill="#C17F50"
        />
        <path
          d="M53.3371 23.5811C49.2956 19.5466 33.3707 19.2075 29.2192 23.8814C28.3542 24.8564 26.9964 26.1954 26.3051 28.1808C25.4188 30.7244 24.9438 38.0479 25.1281 38.0797H25.121C25.004 44.679 25.2805 66.3563 29.4639 70.3095C33.4947 74.1214 50.2883 74.669 55.3933 70.4755C62.232 64.8584 56.6377 26.8772 53.3371 23.5811Z"
          fill="#E3A263"
        />
        <path
          d="M24.5222 38.0162C24.6959 38.0515 24.8767 38.0868 25.0611 38.1151H25.054C25.054 38.1186 25.054 38.1257 25.054 38.1292H31.5736V28.3822C31.5736 28.3822 18.616 28.5518 18.7081 32.83C18.7294 33.9428 19.3994 36.9422 24.5222 38.0162Z"
          fill="#E3A263"
        />
        <path
          d="M29.5527 32.5756C29.5704 33.8686 27.4327 36.3734 24.7632 36.4052C22.0901 36.437 19.8956 33.9887 19.8779 32.6957C19.8602 31.4027 22.0157 30.4842 24.6887 30.4524C27.3618 30.4206 29.5385 31.2826 29.5527 32.5756Z"
          fill="#3B1C0C"
        />
        <path
          d="M24.7881 31.491C24.7916 31.8372 24.1783 32.1234 23.4126 32.134C22.6503 32.1446 22.0264 31.8725 22.0228 31.5263C22.0193 31.1801 22.6326 30.894 23.3984 30.8834C24.1641 30.8728 24.7845 31.1448 24.7881 31.491Z"
          fill="#6C442F"
        />
        <path
          d="M50.7862 24.7698C51.246 24.3916 53.3026 22.2957 56.7945 22.541C60.2908 22.7884 64.3339 29.4885 63.1388 34.0138C61.9416 38.5433 54.4009 29.5989 53.5608 28.5954C52.7207 27.592 50.0473 25.3759 50.7862 24.7698Z"
          fill="#C17F50"
        />
        <path
          d="M25.4763 39.1196C25.4975 39.1302 26.1543 39.4504 27.1995 39.7741C28.2447 40.0943 29.6853 40.418 31.2814 40.418C32.0017 40.418 32.7539 40.3512 33.5131 40.1858C34.0816 40.0626 34.6571 39.8832 35.2256 39.6369C36.0766 39.2674 36.91 38.7396 37.6868 38.0253C38.4636 37.3075 39.1769 36.4031 39.7984 35.2771C39.9608 34.9851 39.8514 34.6191 39.5583 34.4573C39.2652 34.2954 38.898 34.4045 38.7355 34.6965C38.2341 35.6079 37.6762 36.3398 37.083 36.9274C36.6381 37.3708 36.1755 37.7297 35.7023 38.0253C34.989 38.4686 34.2475 38.7642 33.5025 38.9507C32.7574 39.1372 32.0053 39.2111 31.2814 39.2111C29.8513 39.2111 28.5236 38.9155 27.5596 38.62C27.0759 38.4722 26.6875 38.3244 26.4191 38.2153C26.2849 38.159 26.1825 38.1133 26.1154 38.0851C26.0801 38.071 26.0554 38.057 26.0413 38.0499C26.0342 38.0464 26.0271 38.0429 26.0236 38.0429H26.0201L25.9318 38.2188L26.0201 38.0429L25.9318 38.2188L26.0201 38.0429L25.8576 38.3736L26.0201 38.0429L25.8576 38.3736L26.0201 38.0429L25.7552 38.5742L26.0201 38.0429L25.7552 38.5742L26.0201 38.0429C25.7199 37.8951 25.3562 38.0182 25.2079 38.3173C25.0526 38.6094 25.1761 38.9718 25.4763 39.1196Z"
          fill="#C17F50"
        />
        <path
          d="M40.6645 29.8515C40.7798 29.8677 40.9011 29.8425 41.0045 29.7714C41.2024 29.6355 41.6063 29.507 42.147 29.554C42.6871 29.6021 43.063 29.7977 43.2342 29.9659C43.4238 30.1522 43.7284 30.1497 43.9148 29.961C44.1015 29.7722 44.0993 29.4683 43.9101 29.2821C43.5285 28.9068 42.9188 28.6573 42.2309 28.5975C41.5454 28.5378 40.8993 28.6775 40.4582 28.9808C40.2393 29.1313 40.1841 29.4302 40.335 29.6485C40.4147 29.7638 40.5357 29.8335 40.6645 29.8515Z"
          fill="#3B1C0C"
        />
        <path
          d="M32.9972 29.1142C32.8808 29.1142 32.7642 29.0724 32.6717 28.9877C32.4948 28.8256 32.1127 28.6423 31.5707 28.6139C31.0292 28.5865 30.6296 28.728 30.4367 28.8708C30.2229 29.029 29.9216 28.9843 29.7633 28.7715C29.6048 28.5587 29.6493 28.258 29.8628 28.0999C30.293 27.7812 30.9316 27.6187 31.6211 27.655C32.3082 27.691 32.9286 27.9191 33.3231 28.2806C33.5189 28.46 33.5318 28.7637 33.3519 28.959C33.2569 29.062 33.1274 29.1142 32.9972 29.1142Z"
          fill="#3B1C0C"
        />
      </g>
      <path
        d="M40.5003 57.9475C41.8338 57.9475 42.9148 56.8665 42.9148 55.533C42.9148 54.1995 41.8338 53.1185 40.5003 53.1185C39.1668 53.1185 38.0858 54.1995 38.0858 55.533C38.0858 56.8665 39.1668 57.9475 40.5003 57.9475Z"
        fill="#7267BF"
      />
    </svg>
  );
}
