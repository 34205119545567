import { useContext, useCallback, useEffect } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import EyeIcon from 'ms-components/icons/Eye';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import LessonTaskDetailsForm, {
  useCreateLessonTaskState,
} from 'ms-pages/Teacher/components/CreateTask/CreateLessonTask';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import SubtopicEmptyView from 'ms-pages/Teacher/components/CreateTask/components/SubtopicEmptyView';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { assignableSubtopicForTheory } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import Lesson from 'ms-pages/Textbooks/components/SubtopicDetailView/Lesson';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { styledVerticallyScrollable } from 'ms-utils/emotion/index';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { noop } from 'ms-utils/misc';

import { TaskPreviewDrawer } from './components/TaskPreviewDrawer';

type Props = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic: SelectedSubtopic | null | undefined;
  taskName: string | null | undefined;
  taskCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
};

export default function LessonFlow({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  taskName,
  taskCreationCallback,
  currentSyllabusId,
  setCurrentSyllabusId,
}: Props) {
  const { enqueueMessage } = useContext(SnackbarContext);
  const { stepNumber } = useCreateTaskState();
  const dispatch = useCreateTaskDispatch();
  const [state, [createLessonTask, { data, loading, error: mutationError }]] =
    useCreateLessonTaskState({
      taskName,
      selectedClasses: prefilledClasses != null ? prefilledClasses : [],
      selectedStudents: prefilledStudents != null ? prefilledStudents : [],
      excludedStudents:
        prefilledExcludedStudents != null ? prefilledExcludedStudents : [],
      taskStartDate: prefilledStartDate,
      taskDueDate: prefilledDueDate,
      activeSubtopic: prefilledSubtopic,
    });

  const { activeSubtopic } = state.values;
  const { setActiveSubtopic } = state.updaters;

  const classTasksReportUrl = useGetFirstAvailableClassTasksReportUrl(
    state.values.selectedClasses,
  );

  useEffect(
    () => {
      if (
        data != null &&
        data.createLessonTask.task != null &&
        data.createLessonTask.errors.length === 0
      ) {
        enqueueMessage({
          text: 'Task Created Successfully',
          href: classTasksReportUrl,
          actionLabel: 'View tasks',
        });
        taskCreationCallback();
      } else if (
        mutationError != null ||
        (data != null && data.createLessonTask.errors.length > 0)
      ) {
        enqueueMessage({ text: 'Unable to create task' });
      }
    },
    // A lot of the objects used here don't follow good memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const stepNames = STEP_NAME_MAPS.lesson();

  const nextButtonDisabledStates = {
    subtopic_selector: activeSubtopic == null,
    preview_and_task_details:
      loading ||
      !state.values.taskName ||
      (state.values.selectedClasses.length === 0 &&
        state.values.selectedStudents.length === 0),
  };

  const createLessonTaskAction = useCallback(() => {
    createLessonTask();
    dispatch({ type: 'create' });
  }, [createLessonTask, dispatch]);

  const isTaskPreviewOpen = useBoolean(false);
  const { trackStructEvent } = useSnowplow();

  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({ selectedFlow: 'lesson' })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={createLessonTaskAction}
      />

      {stepNumber === 0 && (
        <SidebarAndPreview
          taskType="lesson"
          activeSubtopic={activeSubtopic}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          subtopicFilter={assignableSubtopicForTheory}
          disableSkillsBookTab
        >
          {activeSubtopic == null ? (
            <SubtopicEmptyView />
          ) : (
            <>
              <HeadingS>{activeSubtopic[1]}</HeadingS>
              <Lesson subtopicId={activeSubtopic[0]} onLoad={noop} />
            </>
          )}
        </SidebarAndPreview>
      )}
      {stepNumber === 1 && (
        <Body
          noFooterSpaceAtBottom
          isVerticallyScrollable={false}
          isVerticalOverflowHidden
          whiteBackground={false}
          style={{
            backgroundColor: colors.seashell,
            padding: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            <TaskPreviewDrawer open={isTaskPreviewOpen.value}>
              {activeSubtopic != null && (
                <>
                  <div
                    style={{
                      padding: 16,
                      paddingBottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      gap: 16,
                    }}
                  >
                    <Button
                      styles={{ gap: 8 }}
                      isRound
                      type="secondary"
                      onClick={() => {
                        trackStructEvent({
                          category: 'create_task_from_anywhere',
                          action: 'toggled_questions_preview',
                          label: isTaskPreviewOpen.value
                            ? 'hide_questions'
                            : 'show_questions',
                        });
                        isTaskPreviewOpen.toggle();
                      }}
                    >
                      <EyeIcon size={12} />
                      {isTaskPreviewOpen.value ? 'Hide task' : 'Preview task'}
                    </Button>
                    {isTaskPreviewOpen.value && (
                      <HeadingS>{activeSubtopic[1]}</HeadingS>
                    )}
                  </div>
                  <div
                    style={{
                      // lesson already has a top margin of 16px
                      // so, we give it 0px top padding here
                      padding: '0 16px 0px 16px',
                      display: isTaskPreviewOpen.value ? 'block' : 'none',
                      ...styledVerticallyScrollable,
                    }}
                  >
                    <Lesson subtopicId={activeSubtopic[0]} onLoad={noop} />
                  </div>
                </>
              )}
            </TaskPreviewDrawer>

            <div
              style={{
                flexGrow: 1,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  padding: '24px 16px',
                  ...styledVerticallyScrollable,
                  width: isTaskPreviewOpen.value ? '100%' : '50%',
                }}
              >
                <LessonTaskDetailsForm state={state} />
              </div>
            </div>
          </div>
        </Body>
      )}
    </>
  );
}
