import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  ModalBody,
  ModalDescription,
  ModalTitle,
  Separator,
} from 'ms-pages/Teacher/components/Modal';
import { Body, Cell, Row, Table } from 'ms-pages/Teacher/components/Table';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import {
  getMasteryLevelProps,
  MASTERY_LEVELS_DESCENDING,
  TEACHER_REPORT_MASTERY_LEVEL_DESCRIPTIONS,
} from 'ms-utils/masteryLevel';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  showNumericDescription?: boolean;
};

const MasteryModal = ({
  isOpen,
  onClose,
  showNumericDescription = true,
}: Props) => {
  const {
    featureFlags: { classicUi },
  } = useViewer();
  return (
    <Modal isOpen={isOpen} onClose={onClose} theme="dark" width={480}>
      <ModalBody>
        <ModalTitle>Mastery Levels</ModalTitle>
        <Separator medium />
        <ModalDescription>
          Mastery is now displayed with labels, to easily identify what level a
          student is performing at.
        </ModalDescription>
        <Separator large />

        <Table>
          <Body>
            {MASTERY_LEVELS_DESCENDING.map(level => {
              const descriptionText =
                TEACHER_REPORT_MASTERY_LEVEL_DESCRIPTIONS[level];
              return (
                <Row key={level} height={100}>
                  <Cell hasBottomBorder={false}>
                    <MasteryLevelVolumeIndicator
                      masteryLevel={getMasteryLevelProps(level).score}
                    />
                  </Cell>
                  <Cell hasLeftPadding hasBottomBorder={false}>
                    <h2>
                      {getMasteryLevelProps(level).title}
                      {showNumericDescription &&
                        ` (${getMasteryLevelProps(level).description})`}
                    </h2>
                    <p>{descriptionText}</p>
                  </Cell>
                </Row>
              );
            })}
          </Body>
        </Table>
        <Separator large />
        {classicUi && (
          <ModalDescription>
            You can still view the score when exporting to csv. If you have
            thoughts, please send feedback.
          </ModalDescription>
        )}
        <Separator large />
        <Button onClick={onClose} isBlock type="primary">
          Close
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default MasteryModal;
