import { compose } from 'ramda';

import DnDRoot from 'ms-components/DnDRoot';
import type { Props as NumberBuilderProps } from 'ms-components/math/NumberBuilder';
import Counter from 'ms-components/math/NumberBuilder/Counter';
import CountingBlock from 'ms-components/math/NumberBuilder/CountingBlock';
import ItemsDropZone from 'ms-components/math/NumberBuilder/ItemsDropZone';
import { getInitializedDragDropContext } from 'ms-utils/react-dnd/dnd-backend';

const itemTypeToComponent = {
  blocks: CountingBlock,
  counter: Counter,
};

// NOTE not using Pick so that its backwards compatible with Flow
type Props = {
  dropZones: NumberBuilderProps['dropZones'];
  itemType: NumberBuilderProps['itemType'];
  value: NumberBuilderProps['value'];
};

const NumberBuilderReadOnly = ({
  dropZones,
  value: { values },
  itemType,
}: Props) => {
  const itemComponent = itemTypeToComponent[itemType];

  return (
    <div>
      {dropZones.map(({ id: key }, id) => (
        <ItemsDropZone
          key={key}
          values={(values[key] || []).map(v => ({ id, ...v }))}
          itemType={itemType}
          itemComponent={itemComponent}
          isReadOnly
        />
      ))}
    </div>
  );
};

const enhance = compose(getInitializedDragDropContext(), DnDRoot);

export { NumberBuilderReadOnly as OnlyForTestsNumberBuilderReadOnly };

// HOC stuff is a mess and breaking the types, manually declaring the
// type to be the wrapped component.
export default enhance(NumberBuilderReadOnly) as typeof NumberBuilderReadOnly;
