import type { ReactNode, FunctionComponent } from 'react';

import { fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

// Table Styles
export const Separator: FunctionComponent<{
  xSmall?: boolean | undefined;
  small?: boolean | undefined;
  medium?: boolean | undefined;
  large?: boolean | undefined;
}> = styled({
  default: {
    flexShrink: 0,
  },
  xSmall: {
    height: BASE_UNIT / 2,
    width: BASE_UNIT / 2,
  },
  small: {
    height: BASE_UNIT,
    width: BASE_UNIT,
  },
  medium: {
    height: 2 * BASE_UNIT,
    width: 2 * BASE_UNIT,
  },
  large: {
    height: 4 * BASE_UNIT,
    width: 4 * BASE_UNIT,
  },
});

export const Title = styled({
  fontSize: fontSize.small,
  fontWeight: fontWeight.semibold,
  lineHeight: lineHeight.title,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const CellText: FunctionComponent<{
  children?: ReactNode | undefined;
  isBold?: boolean | undefined;
  isInfo?: boolean | undefined;
  isNegative?: boolean | undefined;
  isPositive?: boolean | undefined;
  whiteText?: boolean | undefined;
  isPositiveAlt?: boolean | undefined;
}> = styled({
  default: {
    lineHeight: lineHeight.title,
    color: colors.cloudBurst,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  isBold: {
    fontWeight: fontWeight.semibold,
  },
  isInfo: {
    color: colors.shuttleGray,
  },
  isNegative: {
    color: colors.cinnabar,
  },
  isPositive: {
    color: colors.genoa,
  },
  isPositiveAlt: {
    color: colors.niagra,
  },
  whiteText: {
    color: colors.white,
  },
});

export const LINK_ICON_SIZE = 4.5 * BASE_UNIT;
const LINK_ICON_HORIZONTAL_OFFSET = -0.1 * LINK_ICON_SIZE; // depends on the particular icon used

export const LinkIcon = styled({
  marginLeft: LINK_ICON_HORIZONTAL_OFFSET,
  marginRight: LINK_ICON_HORIZONTAL_OFFSET,
});

const ASSISTANCE_ICON_OFFSET = BASE_UNIT / 2;
export const AssistanceCell = styled({
  marginTop: ASSISTANCE_ICON_OFFSET,
});

export const PROGRESS_BAR_HEIGHT = 2 * BASE_UNIT;
export const PROGRESS_BAR_WIDTH = 140;
