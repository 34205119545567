import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const NewFolder = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path d="M13.248 2.283H8.091l-1.02-1.212A.196.196 0 0 0 6.915 1H2.752c-.65 0-1.176.526-1.177 1.175V4.2H14.414c-.077-.586-.575-1.916-1.166-1.917Zm1.172 3.281H1.58A1.589 1.589 0 0 0 0 7.178l.918 6.453A1.589 1.589 0 0 0 2.505 15.2h6.587a2.683 2.683 0 0 1-.025-.372v-.395h-.395C7.172 14.433 6 13.197 6 11.75c0-1.447 1.172-2.683 2.672-2.683h.395v-.395C9.067 7.172 10.303 6 11.75 6c1.447 0 2.683 1.172 2.683 2.672v.395h.395c.311 0 .608.053.884.15L16 7.178v-.026c-.001-.874-.707-1.583-1.58-1.588Z" />
    <path d="M14.923 10.82h-2.144V8.679a.97.97 0 0 0-1.938 0v2.143H8.698a.97.97 0 0 0 0 1.938h2.143v2.143a.97.97 0 0 0 1.938 0V12.76h2.144a.97.97 0 0 0 0-1.938Z" />
  </Icon>
);
NewFolder.displayName = 'NewFolder';

export default NewFolder;
