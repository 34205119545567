import { useContext, useCallback } from 'react';

import { SnowplowContext } from 'ms-helpers/Snowplow/Provider';
import type { SnowplowContextType } from 'ms-helpers/Snowplow/Provider';
import type { TeacherEventWithoutSubscription } from 'ms-helpers/Snowplow/Types/lantern/teacher';
import { useMaybeTeacherContext } from 'ms-pages/Lantern/views/Teacher/TeacherContext';

type TrackStructEvent = (event: TeacherEventWithoutSubscription) => void;

type WithTrackStructEvent =
  | TeacherEventWithoutSubscription
  | ((...args: any) => TeacherEventWithoutSubscription);

type WithTrackStructEventHook = <T extends (...args: any[]) => any>(
  callback: T,
  event: WithTrackStructEvent,
) => T;

type LanternSnowplowHook = {
  trackStructEvent: TrackStructEvent;
  withTrackStructEvent: WithTrackStructEventHook;
};

export default function useSnowplowForLanternTeacher(): LanternSnowplowHook {
  const context: SnowplowContextType = useContext(SnowplowContext);

  const maybeTeacher = useMaybeTeacherContext();
  const subscription = maybeTeacher?.subscription || 'nte_or_sunflower';

  const trackStructEvent = useCallback(
    (structEvent: TeacherEventWithoutSubscription) => {
      context.trackStructEvent({
        ...structEvent,
        property: subscription,
      });
    },
    [context, subscription],
  );

  // @ts-expect-error Not sure how to solve this type issue atm
  const withTrackStructEvent: WithTrackStructEventHook = useCallback(
    <T extends (...args: any[]) => any>(
      cb: T,
      structuredEvent: WithTrackStructEvent,
    ) =>
      (...args: Parameters<T>) => {
        const decoratedStructEvent =
          typeof structuredEvent === 'function'
            ? structuredEvent(...args)
            : structuredEvent;
        trackStructEvent(decoratedStructEvent);
        return cb(...args);
      },
    [trackStructEvent],
  );

  return {
    trackStructEvent,
    withTrackStructEvent,
  };
}
