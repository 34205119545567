/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TeacherEditClassQueryVariables = {
    schoolId: string;
    classId: string;
    numberOfSyllabuses: number;
    numberOfTeachers: number;
};
export type TeacherEditClassQueryResponse = {
    readonly school: {
        readonly availableSyllabuses: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
        }> | null;
        readonly lanternCurriculum: {
            readonly grades: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
        } | null;
        readonly teachers: ReadonlyArray<{
            readonly id: string;
            readonly user: {
                readonly firstName: string;
                readonly lastName: string;
            };
        }> | null;
    } | null;
    readonly class: {
        readonly title: string;
        readonly displayName: string | null;
        readonly hasEnabledLeaderboard: boolean;
        readonly syllabuses: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                };
            }>;
        } | null;
        readonly classSyllabusFocus: {
            readonly id: string;
            readonly title: string;
        };
        readonly classGrade: {
            readonly id: string;
            readonly title: string;
        } | null;
        readonly teachers: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly user: {
                        readonly firstName: string;
                        readonly lastName: string;
                    };
                };
            }>;
        } | null;
    } | null;
};
export type TeacherEditClassQuery = {
    readonly response: TeacherEditClassQueryResponse;
    readonly variables: TeacherEditClassQueryVariables;
};



/*
query TeacherEditClassQuery(
  $schoolId: ID!
  $classId: ID!
  $numberOfSyllabuses: Int!
  $numberOfTeachers: Int!
) {
  school(id: $schoolId) {
    availableSyllabuses {
      id
      title
    }
    lanternCurriculum {
      grades {
        id
        title
      }
      id
    }
    teachers(limit: $numberOfTeachers) {
      id
      user {
        firstName
        lastName
        id
      }
    }
    id
  }
  class(id: $classId) {
    title
    displayName
    hasEnabledLeaderboard
    syllabuses(first: $numberOfSyllabuses) {
      edges {
        node {
          id
          title
        }
      }
    }
    classSyllabusFocus {
      id
      title
    }
    classGrade {
      id
      title
    }
    teachers(first: $numberOfTeachers) {
      edges {
        node {
          id
          user {
            firstName
            lastName
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "classId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfSyllabuses"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfTeachers"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "availableSyllabuses",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "grades",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "limit",
    "variableName": "numberOfTeachers"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "storageKey": null
  }
],
v14 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "classId"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasEnabledLeaderboard",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "numberOfSyllabuses"
    }
  ],
  "concreteType": "SyllabusConnection",
  "kind": "LinkedField",
  "name": "syllabuses",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SyllabusEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Syllabus",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v7/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "classSyllabusFocus",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "classGrade",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v20 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numberOfTeachers"
  }
],
v21 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v11/*: any*/),
      (v12/*: any*/),
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherEditClassQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": true,
            "selections": (v13/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Class",
        "kind": "LinkedField",
        "name": "class",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "TeacherConnection",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeacherEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Teacher",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v13/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TeacherEditClassQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v10/*: any*/),
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": true,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "Class",
        "kind": "LinkedField",
        "name": "class",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "TeacherConnection",
            "kind": "LinkedField",
            "name": "teachers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TeacherEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Teacher",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v21/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "819ff97f011412edcf53caad5797ef20",
    "id": null,
    "metadata": {},
    "name": "TeacherEditClassQuery",
    "operationKind": "query",
    "text": "query TeacherEditClassQuery(\n  $schoolId: ID!\n  $classId: ID!\n  $numberOfSyllabuses: Int!\n  $numberOfTeachers: Int!\n) {\n  school(id: $schoolId) {\n    availableSyllabuses {\n      id\n      title\n    }\n    lanternCurriculum {\n      grades {\n        id\n        title\n      }\n      id\n    }\n    teachers(limit: $numberOfTeachers) {\n      id\n      user {\n        firstName\n        lastName\n        id\n      }\n    }\n    id\n  }\n  class(id: $classId) {\n    title\n    displayName\n    hasEnabledLeaderboard\n    syllabuses(first: $numberOfSyllabuses) {\n      edges {\n        node {\n          id\n          title\n        }\n      }\n    }\n    classSyllabusFocus {\n      id\n      title\n    }\n    classGrade {\n      id\n      title\n    }\n    teachers(first: $numberOfTeachers) {\n      edges {\n        node {\n          id\n          user {\n            firstName\n            lastName\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f5cb8352d4f3113f36d7f87b274bc67b';
export default node;
