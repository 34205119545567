import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const NewTemplate = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path d="M14.327 8.126a2.688 2.688 0 0 0-1.36-1.632V3.428a1 1 0 0 0-1-1H4.86A.714.714 0 1 1 4.86 1h8.468a1 1 0 0 1 1 1v6.126ZM11.782 6.2V4.647a1 1 0 0 0-1-1H2.6a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h6.467v-.367h-.395C7.172 14.633 6 13.397 6 11.95c0-.835.39-1.6 1.01-2.098H4.8a.636.636 0 1 1 0-1.273h3.783c.194 0 .368.087.485.224C9.105 7.337 10.325 6.2 11.75 6.2h.032Zm-7.62.412c0-.351.286-.636.637-.636h3.784a.636.636 0 0 1 0 1.273H4.799a.636.636 0 0 1-.636-.637Z" />
    <path d="M14.982 10.95H12.84V8.809a.97.97 0 0 0-1.938 0v2.143H8.757a.97.97 0 0 0 0 1.938h2.144v2.143a.97.97 0 0 0 1.938 0V12.89h2.143a.97.97 0 0 0 0-1.938Z" />
  </Icon>
);
NewTemplate.displayName = 'NewTemplate';

export default NewTemplate;
