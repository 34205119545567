import styled from '@emotion/styled';
import { StyleSheet } from 'aphrodite';

import CheckMarkIcon from 'ms-components/icons/CheckMarkThick';
import { transition, borderRadiusUI } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const styles = StyleSheet.create({
  checkIcon: {
    display: 'block',
    height: '.66em',
    width: '.66em',
  },
});

const Wrapper = styled.div({
  borderStyle: 'solid',
  borderWidth: 1,
  transition: `background-color ${transition}, border-color ${transition}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: borderRadiusUI,
});

type Props = {
  size: number;
  checked: boolean;
  color: string;
};

const Checkbox = ({ checked, color, size }: Props) => (
  <Wrapper
    style={{
      ...(checked ? { backgroundColor: color } : {}),
      borderColor: checked ? color : colors.iron,
      width: size,
      height: size,
      fontSize: size,
    }}
  >
    {checked && (
      <CheckMarkIcon
        color={colors.white}
        aphroditeStyles={[styles.checkIcon]}
      />
    )}
  </Wrapper>
);

export default Checkbox;
