import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M10,16.4285714 C13.550402,16.4285714 16.4285714,13.550402 16.4285714,10 C16.4285714,6.44959804 13.550402,3.57142857 10,3.57142857 C6.44959804,3.57142857 3.57142857,6.44959804 3.57142857,10 C3.57142857,13.550402 6.44959804,16.4285714 10,16.4285714 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M8.71428571,3.57142857 L11.2857143,3.57142857 L11.2857143,16.4285714 L8.71428571,16.4285714 L8.71428571,3.57142857 Z"
    transform="translate(10.000000, 10.000000) rotate(45.000000) translate(-10.000000, -10.000000)"
  />
);

const Clear = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Clear.displayName = 'Clear';

export default Clear;
