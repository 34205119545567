import { css } from '@emotion/css';

import NewFolder from 'ms-components/icons/NewFolder2';
import CreateOrEditFolderModal from 'ms-pages/Teacher/TaskTemplates/components/CreateOrEditFolderModal';
import { TEMPLATES_BASE_BUTTON_HEIGHT } from 'ms-pages/Teacher/TaskTemplates/components/TemplatesBaseButton';
import FolderViewHeader from 'ms-pages/Teacher/TaskTemplates/views/TemplatesMain/FolderViewHeader';
import Button from 'ms-ui-primitives/Button';
import { HStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { FolderId, ParentGroupId } from './SelectAFolderModal/types';

const CREATE_FOLDER_BUTTON_MAX_WIDTH = 160;

const styles = {
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  }),
  breadcrumbsWrapper: css({
    maxWidth: `calc(100% - ${CREATE_FOLDER_BUTTON_MAX_WIDTH}px)`,
  }),
  buttonWrapper: css({
    maxWidth: CREATE_FOLDER_BUTTON_MAX_WIDTH,
    marginLeft: 'auto',
  }),
};

const ModalActionsHeader = ({
  parentGroupId,
  ancestorGroupIds,
  onParentGroupChange,
  onFolderCreation,
}: {
  parentGroupId: string | undefined | null;
  ancestorGroupIds: FolderId[];
  onParentGroupChange: (newParentGroupId: ParentGroupId) => void;
  onFolderCreation?: () => void;
}) => {
  const isCreateFolderModalOpen = useBoolean();

  return (
    <>
      <div className={styles.wrapper}>
        {parentGroupId != null && (
          <div className={styles.breadcrumbsWrapper}>
            <FolderViewHeader
              templateGroupId={parentGroupId}
              isRoutingDisabled
              onTemplateGroupChange={onParentGroupChange}
              isCompact
            />
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            type="primary"
            isRound
            height={TEMPLATES_BASE_BUTTON_HEIGHT}
            onClick={isCreateFolderModalOpen.setTrue}
            padding={14}
          >
            <HStack gap={8}>
              <NewFolder />
              Create folder
            </HStack>
          </Button>
        </div>
      </div>
      <CreateOrEditFolderModal
        type="create"
        isOpen={isCreateFolderModalOpen.value}
        onClose={isCreateFolderModalOpen.setFalse}
        taskTemplateGroupId={parentGroupId}
        ancestorGroupIds={ancestorGroupIds}
        // Sharing is disabled for subfolders.
        isSharingDisabled={parentGroupId != null}
        retry={onFolderCreation}
      />
    </>
  );
};

export default ModalActionsHeader;
