/**
 * This file just encapsulates and DRYs out logic for manipulating pixel-based
 * screen positions and user-space 'values'.
 *
 * @module cartesian
 * @memberof math
 *
 * @example
 * // A point at zero on the number line axis would have a value of 0 and a
 * // position of something like 240.
 *
 * math.cartesian.toScreen(0, ...); // => 240
 * math.cartesian.fromScreen(240, ...); // => 0
 */

export type Dimensions = {
  contentWidth: number;
  end: number;
  lowerBound: number;
  margin: number;
  padding: number;
  scalingFactor: number;
  snapIncrement: number;
  start: number;
  upperBound: number;
  zeroOffset: number;
};

// This fudge factor is used to account for the discreteness of pixels.
// We want to position entities on the screen in positions that will render
// most crisply.
const fudgeFactor = 0.5;

/**
 * Translate a value from cartesian value to pixel position
 * @param {number} value - The cartesian x value
 * @param {Dimensions} dimensions - The dimensions object
 * @return {number} Screen x in pixels
 */
export function toScreen(value: number, dimensions: Dimensions): number {
  const { scalingFactor, zeroOffset, lowerBound, upperBound } = dimensions;
  if (value === -Infinity) return lowerBound;
  if (value === Infinity) return upperBound;
  return Math.round(value * scalingFactor + zeroOffset) + fudgeFactor;
}

/**
 * Translate a value from cartesian value to pixel position
 * @param {number} position - Screen x in pixels
 * @param {Dimensions} dimensions - The dimensions object
 * @return {number} The cartesian x value
 */
export function fromScreen(position: number, dimensions: Dimensions): number {
  const { contentWidth, scalingFactor, snapIncrement, zeroOffset } = dimensions;
  if (position - fudgeFactor < -snapIncrement / 3) return -Infinity;
  if (position - fudgeFactor > contentWidth + snapIncrement / 3)
    return Infinity;
  return (position - zeroOffset - fudgeFactor) / scalingFactor;
}

/**
 * Snap a cartesian value to the nearest multiple of snapIncrement
 * @param {number} value - The cartesian x value
 * @param {Dimensions} dimensions - The dimensions object
 * @return {number} The snapped cartesian x value
 */
export function snapValue(value: number, dimensions: Dimensions): number {
  const { snapIncrement } = dimensions;
  if (snapIncrement > 0) {
    return Math.round(value / snapIncrement) * snapIncrement;
  }
  return value;
}
