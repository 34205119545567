import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M1.8457 0H2.82568V21H1.8457C0.828003 21 0 20.172 0 19.1543V1.8457C0 0.828003 0.828003 0 1.8457 0ZM14.9897 0H4.05615V21H14.9897C16.0074 21 16.8354 20.172 16.8354 19.1543V1.8457C16.8354 0.828003 16.0074 0 14.9897 0ZM9.04663 7.7963C9.04663 7.45651 9.32202 7.18106 9.66187 7.18106H10.5916C10.9313 7.18106 11.2068 7.45651 11.2068 7.7963C11.2068 8.13596 10.9313 8.41153 10.5916 8.41153H9.66187C9.32202 8.41153 9.04663 8.13596 9.04663 7.7963ZM9.03394 10.3545C8.72375 10.493 8.35999 10.3539 8.22131 10.0436L8.05115 9.66238H6.00745L5.8468 10.0359C5.74646 10.2688 5.51965 10.408 5.28137 10.408C5.20007 10.408 5.11768 10.3918 5.03845 10.3577C4.72632 10.2234 4.58215 9.86157 4.71655 9.54947L6.43494 5.55659C6.53149 5.33215 6.75146 5.18617 6.99585 5.18463H7C7.24255 5.18463 7.46277 5.32716 7.56177 5.5489L9.34497 9.5419C9.4834 9.85208 9.34436 10.216 9.03394 10.3545ZM11.0616 9.44308L13.1832 6.41202H11.4392C11.0994 6.41202 10.824 6.13644 10.824 5.79678C10.824 5.457 11.0994 5.18155 11.4392 5.18155H14.365C14.5944 5.18155 14.8047 5.30908 14.9106 5.51263C15.0167 5.71603 15.0006 5.96162 14.8691 6.14966L12.7473 9.18045H14.4094C14.7491 9.18045 15.0247 9.45602 15.0247 9.79568C15.0247 10.1355 14.7491 10.4109 14.4094 10.4109H11.5657C11.3363 10.4109 11.126 10.2834 11.0201 10.0798C10.9141 9.87643 10.9302 9.63098 11.0616 9.44308ZM6.53711 8.43188H7.50183L7.01062 7.33203L6.53711 8.43188Z" />
);

const GlossaryIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={21}
    viewBoxWidth={17}
  >
    {path}
  </Icon>
);

GlossaryIcon.displayName = 'Folder';

export default GlossaryIcon;
