import { css } from 'aphrodite';

import { privacyPolicyUrl, tosUrl } from 'ms-utils/urls';

import { formStyles } from './styles';

type Props = {
  trackingIdPrefix: string;
};
function Disclaimer({ trackingIdPrefix }: Props) {
  return (
    <div className={css(formStyles.disclaimer)}>
      <span>By clicking &#34;Create account&#34; I agree to the Mathspace</span>
      <br />
      <a
        className={css(formStyles.linkStyle)}
        href={tosUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-tracking-id={`${trackingIdPrefix}/TermsAndConditions`}
      >
        Terms and Conditions
      </a>
      <span> and </span>
      <a
        className={css(formStyles.linkStyle)}
        href={privacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
        data-tracking-id={`${trackingIdPrefix}/PrivacyPolicy`}
      >
        Privacy Policy
      </a>
    </div>
  );
}

export { Disclaimer };
