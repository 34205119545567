import { useMemo, useEffect } from 'react';
import { graphql } from 'react-relay';
import {
  usePagination,
  type ConnectionConfig,
  type PaginationFunction,
} from 'relay-hooks';

import ProfileIcon from 'ms-components/icons/Profile';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled, tappable, styledVerticallyScrollable } from 'ms-utils/emotion';
import useScrollToBottomRef from 'ms-utils/hooks/useScrollToBottomRef';

import type {
  StudentSelector_teacher$data,
  StudentSelector_teacher$key,
} from './__generated__/StudentSelector_teacher.graphql';

const PAGE_SIZE = 10;
const AUTOLOAD_THRESHOLD = 5;
type ExternalProps = {
  searchTerm: string;
  listHeight: number;
  selectedStudents: ReadonlyArray<string>;
  onClick: (payload: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  }) => void;
};
type FragmentProps = {
  teacher: StudentSelector_teacher$data;
};
export type Props = FragmentProps &
  ExternalProps & {
    loadMore: PaginationFunction['loadMore'];
    refetchConnection: PaginationFunction['refetchConnection'];
  };
const ItemWrapper = styled({
  position: 'relative',
});
const StudentItem = styled({
  backgroundColor: colors.snuff,
  color: colors.grey,
  padding: `${1.5 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
  borderRadius: 4 * BASE_UNIT,
  marginBottom: BASE_UNIT,
  display: 'inline-block',
  width: '100%',
  ...tappable,
});
const StudentItemInner = styled({
  display: 'flex',
  alignItems: 'center',
});
const Name = styled(
  {
    overflow: 'hidden',
    width: '100%',
    textOverflow: 'ellipsis',
    fontSize: fontSize.medium,
  },
  'span',
);
const Container = styled({
  paddingRight: BASE_UNIT,
  ...styledVerticallyScrollable,
});
const IconWrapper = styled({
  marginRight: BASE_UNIT,
});
/**
 * This is a valid case in which a fragment container needs to take in props that are nullable
 * Whenever a search is made the queryrender that is calling this will rerender and props will
 * return to null. We want to prevent flashing of content by keeping this component rendered.
 */
const StudentSelector = ({
  teacher,
  listHeight,
  onClick,
  searchTerm,
  selectedStudents,
  loadMore,
  refetchConnection,
}: Props) => {
  const students = teacher.studentSearch?.edges;
  // We have to use 'relay-hooks' ConnectionConfig as theirs is incompatible
  // with the real 'react-relay` ConnectionConfig type. 'relay-hooks'
  // type sucks too as it isn't generic over Props like the real type. Sigh.
  const connectionConfig: ConnectionConfig = useMemo(
    () => ({
      getVariables: (props, paginationInfo) => ({
        cursor: paginationInfo.cursor,
        // Coercion required due to shitty 'relay-hooks' types
        searchTerm: (props as Props).searchTerm,
      }),
      query: graphql`
        query StudentSelectorQuery($searchTerm: String!, $cursor: ID) {
          viewer {
            profile {
              ... on Teacher {
                ...StudentSelector_teacher
                  @arguments(searchTerm: $searchTerm, cursor: $cursor)
              }
            }
          }
        }
      `,
    }),
    [],
  );
  const ref = useScrollToBottomRef(() => {
    // NOTE types are wrong, callback and options are optional in the implementation.
    loadMore(
      connectionConfig,
      10,
      () => {}, // error Callback
      {},
    );
  });
  const selectedStudentsIndex = useMemo(() => {
    const index: Record<string, number> = {};
    selectedStudents.forEach(
      (studentId, position) => (index[studentId] = position),
    );
    return index;
  }, [selectedStudents]);
  const filteredList = useMemo(() => {
    if (students != null)
      return students.filter(
        student => selectedStudentsIndex[student.node.id] == null,
      );
    return null;
  }, [selectedStudentsIndex, students]);
  // We want to make sure that if the teacher selects students sequentally, we continue to load more students
  useEffect(() => {
    if (filteredList != null && filteredList.length < AUTOLOAD_THRESHOLD)
      // Because the list is guaranteed unique, if we fetch a page larger than the currently
      // selected set, we are guaranteed at least a page of data.
      // NOTE types are wrong, callback and options are optional in the implementation.
      loadMore(
        connectionConfig,
        PAGE_SIZE + selectedStudents.length,
        () => {}, // error callback
        {},
      );
  }, [connectionConfig, filteredList, loadMore, selectedStudents]);
  useEffect(() => {
    // NOTE types are wrong, callback is optional in the implementation
    refetchConnection(
      connectionConfig,
      PAGE_SIZE,
      () => {}, // error callback
      {
        searchTerm,
      },
    );
  }, [connectionConfig, refetchConnection, searchTerm]);
  return (
    <Container style={{ maxHeight: listHeight }} ref={ref}>
      {filteredList == null ? (
        <MinorSpinner />
      ) : (
        filteredList.map(student => {
          const user = student?.node.user;
          if (user == null) return null;
          return (
            <ItemWrapper key={user.id}>
              <StudentItem
                onClick={() =>
                  onClick({
                    id: student.node.id,
                    firstName: student.node.user.firstName,
                    lastName: student.node.user.lastName,
                    avatar: student.node.user.avatar,
                  })
                }
                key={student.node.id}
              >
                <StudentItemInner>
                  <IconWrapper>
                    <ProfileIcon size={5 * BASE_UNIT} />
                  </IconWrapper>
                  <Name>
                    {user.lastName}, {user.firstName}
                  </Name>
                </StudentItemInner>
              </StudentItem>
            </ItemWrapper>
          );
        })
      )}
    </Container>
  );
};
export default function StudentSelectorContainer({
  teacher,
  searchTerm,
  listHeight,
  selectedStudents,
  onClick,
}: ExternalProps & {
  teacher: StudentSelector_teacher$key;
}) {
  const [data, { loadMore, refetchConnection }] =
    usePagination<StudentSelector_teacher$key>(
      graphql`
        fragment StudentSelector_teacher on Teacher
        @argumentDefinitions(
          searchTerm: { type: "String", defaultValue: "" }
          pageSize: { type: "Int", defaultValue: 10 }
          cursor: { type: "ID" }
        ) {
          studentSearch(
            first: $pageSize
            searchQuery: $searchTerm
            after: $cursor
          ) @connection(key: "StudentSelectorFragment_studentSearch") {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  avatar
                }
              }
            }
          }
        }
      `,
      teacher,
    );
  return (
    <StudentSelector
      teacher={data}
      searchTerm={searchTerm}
      listHeight={listHeight}
      selectedStudents={selectedStudents}
      onClick={onClick}
      loadMore={loadMore}
      refetchConnection={refetchConnection}
    />
  );
}
