import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import { compose, multiply } from 'ramda';
import { useEffect, useState } from 'react';

import { colors } from 'ms-styles/colors';

export const DEFAULT_SIZE = 60;
// There's no need to show a loading spinner in cases where the page loads
// faster than 1 second.
const DELAY = 1000;

const bounceKeyframes = {
  '0%': {
    transform: 'scale(0.0)',
  },
  '50%': {
    transform: 'scale(1.0)',
  },
  '100%': {
    transform: 'scale(0.0)',
  },
};

const rotateKeyframes = {
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
};

// curried version of Math.pow
const power = (exponent: number) => (base: number) => Math.pow(base, exponent);
// use pythagorean theorem to get length of the diagonal of a square with given size
const lengthOfDiagonal: (size: number) => number = compose(
  Math.sqrt,
  multiply(2),
  power(2),
);

const styles = StyleSheet.create({
  root: {
    height: DEFAULT_SIZE,
    margin: (lengthOfDiagonal(DEFAULT_SIZE) - DEFAULT_SIZE) / 2,
    position: 'relative',
    userSelect: 'none',
    width: DEFAULT_SIZE,
  },
  chasingDots: {
    textAlign: 'center',
    animationName: rotateKeyframes,
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  dot: {
    width: '60%',
    height: '60%',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    borderRadius: '100%',
    animationName: bounceKeyframes,
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',
  },
  dot2: {
    top: 'auto',
    bottom: 0,
    animationDelay: '-1.0s',
  },
  hide: {
    visibility: 'hidden',
  },
});

/**
 * This component is based on http://tobiasahlin.com/spinkit/.
 * Specifically, the "Chasing Dots" spinner.
 *
 * @param {*} props
 */
export default function LoadingSpinner({
  aphroditeStyles = [],
}: {
  aphroditeStyles?: CSSInputTypes[];
}) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, DELAY);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={css(
        styles.root,
        styles.chasingDots,
        ...aphroditeStyles,
        !isVisible && styles.hide,
      )}
    >
      <div
        className={css(styles.dot)}
        style={{ backgroundColor: colors.java }}
      />
      <div
        className={css(styles.dot, styles.dot2)}
        style={{ backgroundColor: colors.mountainMeadow }}
      />
    </div>
  );
}
