import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M12.1534547,6.46768332 L9.16655498,11.2177616 L7.8084444,9.35230444 C7.48338476,8.90581293 6.85791896,8.80737294 6.41142745,9.13243259 C5.96493593,9.45749223 5.86649595,10.082958 6.1915556,10.5294495 L8.41869184,13.5885726 C8.83500138,14.160402 9.69716295,14.1310957 10.0736815,13.5323167 L13.8465453,7.53231668 C14.1405357,7.06478262 13.9998507,6.44744508 13.5323167,6.1534547 C13.0647826,5.85946431 12.4474451,6.00014926 12.1534547,6.46768332 Z" />
);

const CheckMarkFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CheckMarkFilled.displayName = 'CheckMarkFilled';

export default CheckMarkFilled;
