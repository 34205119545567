import { assertUnreachable } from 'ms-utils/typescript-utils';
import { serialize } from 'ms-utils/urls/queryParams';

export const teacherClassBaseRedirectorPath = '/teacher/classes/';

export const teacherClassBasePath = '/teacher/classes/:classId/';

export const teacherClassViewTypePath = '/teacher/classes/:classId/:view/';

export const teacherClassActivityPath = '/teacher/classes/:classId/activity/';

export const teacherClassActivityLivePath =
  '/teacher/classes/:classId/activity/live/';

export const teacherClassActivityRedirectorPath = '/teacher/classes/activity';

export const teacherClassInsightsPath = '/teacher/classes/:classId/insights/';

export const teacherClassPlannerPath = '/teacher/classes/:classId/planner/';

export const teacherClassInsightsRedirectorPath = '/teacher/classes/insights/';

export const teacherJoinClassUrl = `/teacher/classes/:classId/join`;

export const teacherClassInsightsChallengingProblemsPath =
  '/teacher/classes/:classId/insights/top-questions-to-address';

export const teacherClassMasteryPath = '/teacher/classes/:classId/mastery/';

export const teacherClassMasteryTopicPath =
  '/teacher/classes/:classId/mastery/topics/:topicId/';

export const teacherClassMasteryRedirectorPath = '/teacher/classes/mastery';

export const teacherClassTasksPath = '/teacher/classes/:classId/tasks/';

export const teacherClassSkillsPath = '/teacher/classes/:classId/skills';

export const teacherClassTasksRedirectorPath = '/teacher/classes/tasks';

export const teacherClassSettingsRedirectorPath = '/teacher/classes/settings/';

export const teacherClassPlannerRedirectorPath = '/teacher/classes/planner/';

export const teacherClassSettingsPath = '/teacher/classes/:classId/settings/';

export type TeacherClassUrlParams = {
  classId: string;
  showNewClassAssignAdaptiveTaskPrompt?: 'true' | undefined;
  classSettingsModalOpen?: 'true' | undefined;
  editClassSettingsModalOpen?: 'true' | undefined;
};

export const teacherClassBaseUrl = ({
  classId,
  classSettingsModalOpen,
  showNewClassAssignAdaptiveTaskPrompt,
  editClassSettingsModalOpen,
}: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/${serialize({
    classSettingsModalOpen,
    showNewClassAssignAdaptiveTaskPrompt,
    editClassSettingsModalOpen,
  })}`;

export const teacherClassTasksUrl = ({
  classId,
  classSettingsModalOpen,
  showNewClassAssignAdaptiveTaskPrompt,
}: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/tasks/${serialize({
    classSettingsModalOpen,
    showNewClassAssignAdaptiveTaskPrompt,
  })}`;

export const teacherClassSkillsUrl = ({ classId }: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/skills`;

export const teacherClassTasksExportUrl = ({
  classId,
  exportView,
  startDate,
  endDate,
}: TeacherClassUrlParams & {
  exportView: 'tasks' | 'students' | 'gradebook';
  startDate: string | null | undefined;
  endDate: string | null | undefined;
}) => {
  const groupBy = (() => {
    switch (exportView) {
      case 'students':
        return 'bystudent';
      case 'tasks':
        return 'bytask';
      case 'gradebook':
        return 'gradebook';
      default:
        throw Error(
          `Unknown export view ${exportView} in the class tasks report`,
        );
    }
  })();
  return [
    `/teacher/classes/${classId}/`,
    `tasks/${groupBy}/download/?`,
    [
      ...(startDate != null ? [`startDate=${startDate}`] : []),
      ...(endDate != null ? [`endDate=${endDate}`] : []),
    ].join('&'),
  ].join('');
};

export const teacherClassInsightsUrl = ({
  classId,
  classSettingsModalOpen,
  showNewClassAssignAdaptiveTaskPrompt,
  editClassSettingsModalOpen,
}: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/insights/${serialize({
    classSettingsModalOpen,
    showNewClassAssignAdaptiveTaskPrompt,
    editClassSettingsModalOpen,
  })}`;

export const teacherClassPlannerUrl = ({ classId }: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/planner/`;

export const teacherClassInsightsChallengingProblemsUrl = ({
  classId,
}: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/insights/top-questions-to-address`;

export const teacherClassMasteryUrl = ({ classId }: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/mastery/`;

export const getTeacherMasteryCsvDownloadUrl = (params: {
  classId: string;
  syllabusId: string;
}) =>
  `/teacher/classes/${params.classId}/mastery-download/${params.syllabusId}/download/`;

export const teacherClassMasterySyllabusUrl = ({
  classId,
  syllabusIds,
}: TeacherClassUrlParams & { syllabusIds: string }) =>
  `/teacher/classes/${classId}/mastery/?syllabusIds=${syllabusIds}`;

export const teacherClassMasteryTopicUrl = ({
  classId,
  topicId,
}: TeacherClassUrlParams & {
  topicId: string;
}) => `/teacher/classes/${classId}/mastery/topics/${topicId}/`;

export const teacherClassActivityUrl = ({
  classId,
  classSettingsModalOpen,
  editClassSettingsModalOpen,
  showNewClassAssignAdaptiveTaskPrompt,
}: TeacherClassUrlParams) =>
  `/teacher/classes/${classId}/activity/${serialize({
    classSettingsModalOpen,
    editClassSettingsModalOpen,
    showNewClassAssignAdaptiveTaskPrompt,
  })}`;

export const teacherClassActivityLiveUrl = ({
  classId,
}: TeacherClassUrlParams) => `/teacher/classes/${classId}/activity/live/`;

export const teacherClassSettingsUrl = ({
  classId,
  showNewClassAssignAdaptiveTaskPrompt,
}: TeacherClassUrlParams) => {
  const innerShowNewClassAssignAdaptiveTaskPrompt =
    showNewClassAssignAdaptiveTaskPrompt ?? false;

  return `/teacher/classes/${classId}/settings/${
    innerShowNewClassAssignAdaptiveTaskPrompt
      ? serialize({ showNewClassAssignAdaptiveTaskPrompt: 'true' })
      : ''
  }`;
};

export const teacherClassSettingsExportUrl = ({
  classId,
}: TeacherClassUrlParams) => `/teacher/classes/${classId}/settings/download/`;

const teacherClassViewTypes = [
  'activity',
  'insights',
  'mastery',
  'tasks',
  'planner',
  'skills',
  'settings',
] as const;

type TeacherClassViewType = (typeof teacherClassViewTypes)[number];

type Url = (p: TeacherClassUrlParams) => string;

export const makeTeacherClassUrl = ({
  view,
}: {
  view: TeacherClassViewType | null | undefined;
}): Url => {
  if (view == null) return teacherClassBaseUrl;
  switch (view) {
    case 'activity':
      return teacherClassActivityUrl;
    case 'insights':
      return teacherClassInsightsUrl;
    case 'mastery':
      return teacherClassMasteryUrl;
    case 'tasks':
      return teacherClassTasksUrl;
    case 'planner':
      return teacherClassPlannerUrl;
    case 'skills':
      return teacherClassSkillsUrl;
    case 'settings':
      return teacherClassSettingsUrl;
    default:
      assertUnreachable(view, `Incorrect view for Teacher Class: ${view}`);
  }
};

export const isValidTeacherClassView = (
  view: string,
): view is TeacherClassViewType => {
  return teacherClassViewTypes.includes(view as TeacherClassViewType);
};
