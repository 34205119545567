import type { CustomMiloMonthlyChallengeState } from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/SunflowerStudentDashboardHeader/SunflowerStudentDashboardHeader';

import { DESTINATIONS_MONTH_MAP, type Destination } from './config';
import type { MonthYear } from './types';

export function getDestination(monthYear: MonthYear): Destination {
  return DESTINATIONS_MONTH_MAP[monthYear] ?? 'Tokyo';
}

export type CustomMiloMonthlyExpeditionItemsState = Record<
  '11-2024' | '12-2024',
  {
    get: CustomMiloMonthlyChallengeState;
    set: (v: CustomMiloMonthlyChallengeState) => void;
  }
>;
