export const reorderList = <T extends {}>(
  list: readonly T[],
  originalIndex: number,
  finalIndex: number,
) => {
  const elem = list[originalIndex];

  if (elem === undefined) return list;

  const newList =
    originalIndex < finalIndex
      ? [
          ...list.slice(0, originalIndex),
          ...list.slice(originalIndex + 1, finalIndex + 1),
          elem,
          ...list.slice(finalIndex + 1),
        ]
      : [
          ...list.slice(0, finalIndex),
          elem,
          ...list.slice(finalIndex, originalIndex),
          ...list.slice(originalIndex + 1),
        ];
  return newList;
};

export const MAX_NUMBER_OF_QUESTIONS = 60;
