type StorageKey = string;
type StorageKeyParams = {
  teacherId: string;
};
type StorageKeyVersion = number;

const STORAGE_KEY: StorageKey = 'NTE_REPORT_FILTERS';
const STORAGE_VERSION: StorageKeyVersion = 0;

const getStorageKeyForVersion = ({
  version,
  teacherId,
}: StorageKeyParams & {
  version: StorageKeyVersion;
}): StorageKey => `mathspace:${teacherId}:${STORAGE_KEY}:v${version}`;

export const getStorageKey = ({ teacherId }: StorageKeyParams): StorageKey =>
  getStorageKeyForVersion({
    teacherId,
    version: STORAGE_VERSION,
  });
