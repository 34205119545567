import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getCheckInUrl } from 'ms-pages/Lantern/utils/urls';

import useCreatePreTopicTestCheckIn from './useCreatePreTopicTestCheckIn';
import { CHECK_IN_CREATION_ERROR } from './utils';

function useStartPreTopicTestCheckIn({ skillIds }: { skillIds: string[] }) {
  const history = useHistory();
  const { search: queryString } = useLocation();
  const [
    createPreTopicTestCheckIn,
    {
      data: createCheckInResponse,
      loading: createCheckInLoading,
      error: createCheckInError,
    },
  ] = useCreatePreTopicTestCheckIn();

  const errors = useMemo(
    () => [
      ...(createCheckInError != null ? [CHECK_IN_CREATION_ERROR] : []),
      ...(createCheckInResponse?.createPreTopicTestCheckIn.errors || []),
    ],
    [createCheckInError, createCheckInResponse],
  );

  useEffect(() => {
    if (
      createCheckInResponse != null &&
      createCheckInResponse.createPreTopicTestCheckIn.checkIn?.id != null
    ) {
      const checkInUrl = getCheckInUrl({
        checkInId: createCheckInResponse.createPreTopicTestCheckIn?.checkIn?.id,
      });

      history.push({ pathname: checkInUrl, search: queryString });
    }
  }, [createCheckInResponse, history, queryString]);

  return {
    loading: createCheckInLoading,
    errors,
    start: () => {
      createPreTopicTestCheckIn({ variables: { skillIds } });
    },
  };
}

export default useStartPreTopicTestCheckIn;
