import { formatLabel } from 'ms-pages/Teacher/components/DateRangeInput/formatLabel';
import { makeStartDateForPeriod } from 'ms-pages/Teacher/components/DateRangeInput/helpers';
import {
  validateDateRangeState,
  queryParamStateToValue as dateRangeQueryParamStateToValue,
  valueToQueryParamState as valueToDateRangeQueryParamState,
} from 'ms-pages/Teacher/components/DateRangeInput/queryParamState';
import {
  makeStartOfDay,
  makeEndOfDay,
  type DateISOString,
} from 'ms-utils/datetime';
import localStorageDb from 'ms-utils/localStorageDb';

import { initialState } from './State';
import type { DateRangeState, SyllabusIds } from './State';
import { getStorageKey } from './localStorage';

export { dateRangeQueryParamStateToValue, valueToDateRangeQueryParamState };

export const makeStartDate = (date: Date): DateISOString =>
  makeStartOfDay(date).toISOString();

export const makeEndDate = (date: Date): DateISOString =>
  makeEndOfDay(date).toISOString();

export const makeDateRange = (dateRangeValue: DateRangeState) => {
  const { period, useCustomPeriod, customStartDate, customEndDate } =
    dateRangeQueryParamStateToValue(dateRangeValue);

  if (useCustomPeriod && customStartDate != null && customEndDate != null) {
    return {
      startDate: makeStartDate(customStartDate),
      endDate: makeEndDate(customEndDate),
    };
  }

  if (!useCustomPeriod && period != null) {
    const today = new Date(new Date().setHours(0, 0, 0));
    return {
      startDate: makeStartDate(
        makeStartDateForPeriod({ endDate: today, period }),
      ),
      endDate: makeEndDate(today),
    };
  }

  throw new Error('DateRangeQueryParamState is invalid');
};

export const makeFilterLabelFromLocalStorage = ({
  teacherId,
}: {
  teacherId: string;
}) => {
  const storageValue = localStorageDb.get(getStorageKey({ teacherId })) || {};
  const state = validateDateRangeState(storageValue, initialState);
  const { period, useCustomPeriod, customStartDate, customEndDate } = state;

  return formatLabel({
    value: dateRangeQueryParamStateToValue({
      period,
      useCustomPeriod,
      customStartDate,
      customEndDate,
    }),
  });
};

type SyllabusIdsArray = ReadonlyArray<string>;

export const syllabusIdsToArray = (s: SyllabusIds | null): SyllabusIdsArray =>
  (s || '').split(',');

export const syllabusIdsFromArray = (a: SyllabusIdsArray): SyllabusIds =>
  a.join(',');
