import { useContext } from 'react';

import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import {
  StateContext,
  UpdatersContext,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import Input from 'ms-ui-primitives/Input';

type Props = {
  errorMessages: ErrorMessages;
};

const StudentEmail = ({ errorMessages }: Props) => {
  const { email } = useContext(StateContext);
  const { updateEmail } = useContext(UpdatersContext);

  return (
    <FieldGroup title="Email">
      <Input
        placeholder="name@email.com"
        value={email}
        onChange={e => updateEmail(e.target.value)}
        showErrorState={errorMessages.length > 0}
        blockTracking
      />
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
};
export default StudentEmail;
