import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from './types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M13.6572 2.34303C10.533 -0.780835 5.46758 -0.781182 2.34303 2.34303C-0.781182 5.46723 -0.780835 10.5327 2.34303 13.6572C5.46723 16.7807 10.5327 16.7811 13.6572 13.6572C16.7811 10.5327 16.7807 5.46758 13.6572 2.34303ZM9.04325 11.4784C9.04325 12.0548 8.57611 12.5219 7.99976 12.5219C7.42341 12.5219 6.95627 12.0548 6.95627 11.4784V7.30447C6.95627 6.72812 7.42341 6.26098 7.99976 6.26098C8.57611 6.26098 9.04325 6.72812 9.04325 7.30447V11.4784ZM6.99213 4.56948C6.97961 5.09506 7.38031 5.5208 7.98136 5.5208C8.60745 5.5208 9.0078 5.09506 9.00815 4.56948C8.99528 4.01887 8.6071 3.60565 7.99353 3.60565C7.38031 3.60565 6.97961 4.01852 6.99213 4.56948Z"
  />
);

const Info = ({ color, size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    {path}
  </Icon>
);

Info.displayName = 'Info';

export default Info;
