import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

const styles = StyleSheet.create({
  // Extra wrapper div is required to overcome IE 10-11 bug in which flex
  // children don't know the height of their parent, if that parent's
  // height has been set via min-height.
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
  },
  // Another extra wrapper div is required to overcome Chrome 56 issue wherein
  // a flex container w/ a min-height and justify-content set to space-around
  // or space-between will be taller than the specified min-height.
  fullHeight: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});

/**
 * The main purpose of the Root component is to ensure that it fills the page,
 * and its children can fill its height, in a cross-browser compatible way.
 *
 * @param {*} props Should only contain children
 */
const Root = ({ children }: Props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.fullHeight)}>{children}</div>
  </div>
);

export default Root;
