import { useContext } from 'react';

import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import type { Items } from 'ms-pages/Teacher/components/InputSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Select from 'ms-ui-primitives/Select';
import { titleForGradeSelector } from 'ms-utils/grades';

import { StateContext, UpdatersContext } from '../state';

type Props = {
  classGrades: Items;
  errorMessages: ErrorMessages;
  showApplyClassGrade: boolean;
};

export default function ClassGrade({
  classGrades,
  errorMessages,
  showApplyClassGrade,
}: Props) {
  const { classGrade, applyClassGrade } = useContext(StateContext);
  const { updateClassGrade, toggleApplyClassGrade } =
    useContext(UpdatersContext);

  return (
    <FieldGroup
      isFieldRequired
      title="Class grade"
      description="If applied to the students of this class, will determine what skills are shown in the students' skills map and check-ins"
    >
      <Select
        block
        options={classGrades.map(grade => ({
          label: titleForGradeSelector(grade, classGrades),
          value: grade.id,
        }))}
        value={classGrade.id}
        onChange={value => {
          updateClassGrade({
            id: value,
            title: titleForGradeSelector(
              classGrades.find(grade => grade.id === value),
              classGrades,
            ),
          });
        }}
        optionItemsShown={3}
      />

      <FormFieldError messages={errorMessages} />
      {showApplyClassGrade && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <Checkbox
            label="Apply this class grade to all students in the class"
            checked={applyClassGrade}
            onChange={() => toggleApplyClassGrade()}
          />
        </>
      )}
    </FieldGroup>
  );
}
