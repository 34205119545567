import moment from 'moment';

import {
  Half,
  Row,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import DateTimeInput from 'ms-ui-primitives/DateTimeInput';

type Props = {
  onChange: ([startDate, endDate]: [Date, Date]) => void;
  value: [Date, Date];
  errorKeys?: Array<string>;
};

export default function TaskDatePicker({
  onChange,
  value,
  errorKeys = [],
}: Props) {
  const [startDate, endDate] = value;
  return (
    <Row>
      <Half>
        <DateTimeInput
          calendarPosition="left"
          value={startDate}
          onChange={date => {
            // there are cases where we can set the start date after the end date.
            // we want to move the end date forward in these cases to prevent invalid date
            // combinations
            if (moment(date).isAfter(moment(endDate))) {
              onChange([date, moment(date).add(7, 'days').toDate()]);
            } else onChange([date, endDate]);
          }}
          minDate={new Date()}
        />
      </Half>
      <Half>
        <DateTimeInput
          minDate={
            // We want the min date to be either the start date or today, whichever is later
            new Date(
              Math.max.apply(
                null,
                [startDate, new Date()].map(d => d.getTime()),
              ),
            )
          }
          calendarPosition="right"
          value={endDate}
          onChange={date => onChange([startDate, date])}
          hasValidationError={errorKeys.includes('dueDate')}
        />
      </Half>
    </Row>
  );
}
