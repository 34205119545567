import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FolderMathspace = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={23}
    viewBoxWidth={24}
  >
    <path
      fill="#3E3E4C"
      fillRule="evenodd"
      d="M10.101 3.235c.218.311.574.498.955.498h6.386c.678 0 1.33.27 1.81.748.478.48.748 1.132.748 1.81v6.65c0 .038-.756.79-1.31 1.337-.627.62-.583.55-1.248 0l-2.473-2.045C13.5 11 10 11.5 10 15.185V18.5H2.558c-.678 0-1.33-.27-1.81-.749A2.562 2.562 0 0 1 0 15.941V3.5A2.558 2.558 0 0 1 2.558.942h4.61c.837 0 1.62.41 2.099 1.096l.834 1.197Z"
      clipRule="evenodd"
    />
    <path
      fill="#1ABC9C"
      d="M12 15.736c0-.704.703-1.192 1.363-.944.089.033.167.091.224.167l3.152 4.154a1.171 1.171 0 0 0 1.828.049l2.995-3.541a1.38 1.38 0 0 1 2.432.89v2.866a3.15 3.15 0 0 1-3.15 3.15h-7.626A1.218 1.218 0 0 1 12 21.309v-5.573Z"
    />
    <path
      fill="#66B1E2"
      d="M17.997 19.24c0-.53.238-1.03.65-1.364l3.437-2.793c.766-.622 1.91-.077 1.91.91v5.362c0 .647-.524 1.172-1.171 1.172h-3.069c-.97 0-1.757-.787-1.757-1.758V19.24Z"
    />
    <path
      fill="#3F4694"
      d="M21.476 14.445a1.451 1.451 0 0 1 2.09 0 1.55 1.55 0 0 1 0 2.15l-4.423 4.545a1.45 1.45 0 0 1-2.09 0 1.55 1.55 0 0 1 0-2.149l4.423-4.546Z"
    />
    <path
      fill="#186C75"
      d="M12.433 16.61a1.55 1.55 0 0 1 0-2.15 1.45 1.45 0 0 1 2.091 0l4.506 4.631a1.55 1.55 0 0 1 0 2.15 1.45 1.45 0 0 1-2.091 0l-4.506-4.631Z"
    />
    <path
      fill="#9EDAE2"
      d="m17.997 18.029 2.498 2.498c.738.738.215 2-.828 2h-3.34c-1.043 0-1.566-1.262-.828-2l2.498-2.498Z"
    />
    <path
      fill="#0694A4"
      d="m17.997 18.029 2.12 2.12a2.999 2.999 0 0 1-4.24 0l2.12-2.12Z"
    />
  </Icon>
);

FolderMathspace.displayName = 'FolderMathspace';

export default FolderMathspace;
