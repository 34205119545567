import { css } from 'aphrodite';
import { useEffect, useState } from 'react';

import PasswordInput from 'ms-components/PasswordInput';
import Button from 'ms-ui-primitives/Button';
import Checkbox from 'ms-ui-primitives/Checkbox';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Input from 'ms-ui-primitives/Input';
import Separator from 'ms-ui-primitives/Separator';
import { getCSRFToken } from 'ms-utils/misc/csrfToken';
import { loginUrl } from 'ms-utils/urls';

import { Disclaimer } from './Disclaimer';
import { makeErrorList } from './ErrorList';
import { formStyles } from './styles';
import type { Errors, Role } from './types';

type Props = {
  role: Role;
  title: string;
  firstName: string;
  lastName: string;
  parentEmail?: string;
  parentEmailNotifications?: boolean;
  email: string;
  password: string;
  ageConfirmed?: boolean;
  showAgeConfirmed?: boolean;
  isDisabled: boolean;
  showDisclaimer?: boolean;
  submitButtonLabel: React.ReactNode;
  onSubmit: () => void;
  onFirstNameChange: (value: string) => void;
  onLastNameChange: (value: string) => void;
  onParentEmailChange?: (value: string) => void;
  onParentEmailNotificationsChange?: (value: boolean) => void;
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onAgeConfirmedChange: (value: boolean) => void;
  errorMessage?: string | undefined;
  errors: Errors;
  trackingIdPrefix: string;
};

function UserForm({
  title,
  firstName,
  lastName,
  role,
  parentEmail,
  parentEmailNotifications,
  email,
  password,
  ageConfirmed,
  showAgeConfirmed,
  isDisabled,
  showDisclaimer,
  submitButtonLabel,
  onSubmit,
  onFirstNameChange,
  onLastNameChange,
  onParentEmailChange,
  onParentEmailNotificationsChange,
  onEmailChange,
  onPasswordChange,
  onAgeConfirmedChange,
  errorMessage,
  errors,
  trackingIdPrefix,
}: Props) {
  let loginUrl = useAccountExists(email);
  return (
    <div className={css(formStyles.container)}>
      <div className={css(formStyles.formContainer)}>
        <div className={css(formStyles.titleContainer)}>
          <div className={css(formStyles.title)}>{title}</div>
        </div>
        <Separator size={5} />
        {errorMessage != null && (
          <div className={css(formStyles.formError)}>{errorMessage}</div>
        )}
        {role === 'parent' && (
          <FieldGroup title="Parent email">
            {parentEmail != null && onParentEmailChange != null && (
              <Input
                value={parentEmail}
                onChange={e => onParentEmailChange(e.target.value)}
                type="email"
                placeholder="Enter your email address"
                errorMessage={makeErrorList(errors.parentEmail)}
                blockTracking
              />
            )}
            {parentEmailNotifications != null &&
              onParentEmailNotificationsChange != null && (
                <>
                  <Separator size={2} />
                  <Checkbox
                    checked={parentEmailNotifications}
                    onChange={() =>
                      onParentEmailNotificationsChange(
                        !parentEmailNotifications,
                      )
                    }
                    label="Receive emails about tasks and progress"
                    trackingId={`${trackingIdPrefix}/EmailNotifications`}
                  />
                </>
              )}
          </FieldGroup>
        )}
        <div className={css(formStyles.formRow)}>
          <FieldGroup
            aphroditeStyles={[formStyles.formRowField]}
            title={role === 'parent' ? 'Student first name' : 'First name'}
          >
            <Input
              value={firstName}
              onChange={e => onFirstNameChange(e.target.value)}
              placeholder={
                role === 'parent'
                  ? 'Enter the student first name'
                  : 'Enter your first name'
              }
              errorMessage={makeErrorList(errors.firstName)}
              blockTracking
            />
          </FieldGroup>
          <FieldGroup
            aphroditeStyles={[formStyles.formRowField]}
            title={role === 'parent' ? 'Student last name' : 'Last name'}
          >
            <Input
              value={lastName}
              onChange={e => onLastNameChange(e.target.value)}
              placeholder={
                role === 'parent'
                  ? 'Enter the student last name'
                  : 'Enter your last name'
              }
              errorMessage={makeErrorList(errors.lastName)}
              blockTracking
            />
          </FieldGroup>
        </div>
        <FieldGroup title={role === 'parent' ? 'Student email' : 'Email'}>
          <Input
            value={email}
            onChange={e => onEmailChange(e.target.value)}
            type="email"
            placeholder={
              role === 'parent'
                ? 'Enter the student email address'
                : 'Enter your email address'
            }
            errorMessage={makeErrorList([
              ...(errors.email ?? []),
              ...(loginUrl != null
                ? [
                    <span key="fixed">
                      Email already in use.{' '}
                      <a href={loginUrl}>click here to log in</a>
                    </span>,
                  ]
                : []),
            ])}
            blockTracking
          />
        </FieldGroup>
        <FieldGroup title="Password">
          <PasswordInput
            value={password}
            onChange={e => onPasswordChange(e.target.value)}
            placeholder={
              role === 'parent'
                ? 'Enter the student password'
                : 'Enter your password'
            }
            errorMessage={makeErrorList(errors.password)}
            blockTracking
            trackingId={`${trackingIdPrefix}/Password`}
          />
          {showAgeConfirmed && (
            <div className={css(formStyles.checkboxContainer)}>
              <Checkbox
                label="I am over 13 years old"
                onChange={e => onAgeConfirmedChange(e.target.checked)}
                checked={ageConfirmed}
                trackingId={`${trackingIdPrefix}/AgeConfirmed`}
              />
            </div>
          )}
        </FieldGroup>
        {(errors?.other?.length ?? 0) > 0 && (
          <div className={css(formStyles.formError)}>
            {makeErrorList(errors.other)}
          </div>
        )}
        <div className={css(formStyles.actionWrapper)}>
          <Button
            size="large"
            type="primary"
            isRound
            isDisabled={isDisabled}
            onClick={onSubmit}
            trackingId={`${trackingIdPrefix}/Continue`}
          >
            {submitButtonLabel}
          </Button>
        </div>
        {showDisclaimer && (
          <>
            <Separator size={8} />
            <Disclaimer trackingIdPrefix={`${trackingIdPrefix}/Disclaimer`} />
          </>
        )}
      </div>
    </div>
  );
}

function useAccountExists(email: string): string | null {
  const [accountSignupUrl, setAccountSignupUrl] = useState<string | null>(null);

  useEffect(() => {
    // Run verification at most once per second.
    const timeout = setTimeout(() => {
      if (email === '') {
        setAccountSignupUrl(null);
      } else {
        const params = new URLSearchParams();
        params.append('username', email);
        fetch(`/accounts/login_method/?username=${email}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': getCSRFToken(),
          },
          body: params.toString(),
        })
          .then(response => response.json())
          .then(json => {
            switch (json.method) {
              case 'none': {
                setAccountSignupUrl(null);
                break;
              }
              case 'password': {
                setAccountSignupUrl(loginUrl);
                break;
              }
              case 'sso': {
                setAccountSignupUrl(json.sso_url);
                break;
              }
              default:
                setAccountSignupUrl(null);
            }
          });
      }
    }, 1500);
    return () => clearTimeout(timeout);
  }, [email]);

  return accountSignupUrl;
}

export { UserForm };
