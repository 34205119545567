import styled from '@emotion/styled';
import { useRef } from 'react';
import { graphql, useQuery, useFragment } from 'relay-hooks';

import { CrossBold, GraduateIcon } from 'ms-components/icons';
import ClassIcon from 'ms-components/icons/Class';
import SchoolIcon from 'ms-components/icons/School';
import ToolboxIcon from 'ms-components/icons/Toolbox';
import { LeaderboardModal as LeaderboardModalV2 } from 'ms-experiments/gamification/LeaderboardModal';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { HeadingS, BodyM } from 'ms-pages/Lantern/primitives/Typography';
import {
  LeaderboardModal as LeaderboardModalV1,
  LEADERBOARD_TOP_N,
} from 'ms-pages/StudentDashboard/Leaderboard';
import { fontSize, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT, HEADER_SIZE } from 'ms-styles/theme/Numero';
import Avatar from 'ms-ui-primitives/Avatar';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import LinkButton from 'ms-ui-primitives/Button/ReactRouterLinkButton';
import DropdownMenu, { Menu } from 'ms-ui-primitives/DropdownMenu';
import { HStack, HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import Tooltip from 'ms-ui-primitives/Tooltip';
import {
  useAccessibilityMode,
  useToggleAccessibilityMode,
  accessibilityModeStyle,
  FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH,
} from 'ms-utils/accessibility';
import { tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import useToggle from 'ms-utils/hooks/useToggle';
import keyDownMap from 'ms-utils/keyDownMap';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import _ from 'ms-utils/relay/extractNode';
import {
  logoutUrl,
  settingsUrl,
  studyJoinClassUrl,
  accountsAvatarUrl,
  getStudentActivityUrl,
  exitStudentExperienceRedirectUrl,
} from 'ms-utils/urls';

import type { SunflowerUserMenuStudentQuery } from './__generated__/SunflowerUserMenuStudentQuery.graphql';
import type {
  SunflowerUserMenu_UserInfo_viewer$key,
  SunflowerUserMenu_UserInfo_viewer$data,
} from './__generated__/SunflowerUserMenu_UserInfo_viewer.graphql';

const AVATAR_WRAPPER_PADDING = 4 * BASE_UNIT;
const MENU_WIDTH = 320;
const MENU_PADDING = 24;
const CONTENT_WIDTH = MENU_WIDTH - MENU_PADDING * 2;
const ICON_COLOR = colors.grey90;
const ICON_SIZE = 16;
const AVATAR_SIZE = 10 * BASE_UNIT;
const OVERFLOW_HIDDEN_STYLE = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
const AvatarWrapper = styled.div({
  display: 'flex', // to center the avatar vertically
  paddingLeft: AVATAR_WRAPPER_PADDING,
  paddingRight: AVATAR_WRAPPER_PADDING,
});
const SunflowerUserMenuTriggerWrapper = styled.div<{
  accessibilityMode: boolean;
}>(({ accessibilityMode }) => ({
  height: HEADER_SIZE,
  fontFamily: fontFamily.body,
  display: 'flex',
  borderLeft: `1px solid ${colors.iron}`,
  alignItems: 'center',
  border: `${FOCUSED_ACCESSIBILITY_MODE_BORDER_WIDTH}px solid transparent`,
  ...tappable,
  ':focus': {
    outline: 'none',
    backgroundColor: colors.ironLight,
  },
  ...(accessibilityMode
    ? {
        ...accessibilityModeStyle,
        ':focus': {
          ...accessibilityModeStyle[':focus'],
          background: 'transparent',
        },
      }
    : {}),
}));
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: CONTENT_WIDTH,
  overflow: 'hidden',
});
const List = styled.div({
  color: colors.shuttleGray,
  fontSize: fontSize.medium,
  ...OVERFLOW_HIDDEN_STYLE,
});
const SUNFLOWER_USER_MENU_STUDENT_QUERY = graphql`
  query SunflowerUserMenuStudentQuery(
    $numberOfClasses: Int!
    $topN: Int
    $classId: ID
    $fetchLeaderboardModalData: Boolean!
  ) {
    viewer {
      ...SunflowerUserMenu_UserInfo_viewer
        @arguments(numberOfClasses: $numberOfClasses)
      profile {
        __typename
        ... on Student {
          ...Leaderboard_student
            @arguments(
              fetchLeaderboard: false
              numberOfClasses: $numberOfClasses
              topN: $topN
            )
          ...LeaderboardModal_student
            @arguments(
              fetchLeaderboard: $fetchLeaderboardModalData
              numberOfClasses: $numberOfClasses
              classId: $classId
            )
          classes(first: $numberOfClasses) {
            edges {
              node {
                id
                hasEnabledLeaderboard
              }
            }
          }
        }
      }
    }
  }
`;
type Props = {
  avatar: string;
  isOnboarding: boolean;
  onToolboxClick: () => void;
  renderMode?: 'fullscreen' | 'popover' | undefined;
};
type Classes = NonNullable<
  NonNullable<SunflowerUserMenu_UserInfo_viewer$data['profile']>['classes']
>;
const getClassesTitles = (classes: Classes) =>
  _(classes)
    .map(c => c.displayName ?? c.title)
    .join(', ');
export default function SunflowerUserMenu({
  avatar,
  isOnboarding = false,
  onToolboxClick,
  renderMode = 'popover',
}: Props) {
  const [isOpen, open, close] = useToggle(false);
  const menuAnchorRef = useRef<HTMLDivElement>(null);
  const {
    isTeacherStudent,
    featureFlags: { hasStudentAppSpa },
    featureFlagsV2: { gamificationEnableLeaderboardModalV2 },
    leaderboardClassId,
  } = useViewer();
  const { props, error } = useQuery<SunflowerUserMenuStudentQuery>(
    SUNFLOWER_USER_MENU_STUDENT_QUERY,
    {
      numberOfClasses: RELAY_CONNECTION_MAX,
      topN: LEADERBOARD_TOP_N,
      classId: leaderboardClassId,
      // We only need to fetch the leaderboard data up front for the new leaderboard modal, which is only enabled for users
      // who have the feature flag enabled and who belong to a valid class with the leaderboard features enabled.
      // For all other users, the leaderboard data will be fetched lazily when the modal is opened, if needed.
      fetchLeaderboardModalData:
        gamificationEnableLeaderboardModalV2 && leaderboardClassId != null,
    },
  );
  const LeaderboardModal = gamificationEnableLeaderboardModalV2
    ? LeaderboardModalV2
    : LeaderboardModalV1;
  const isLeaderboardOpen = useBoolean();
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const toggleAccessibilityMode = useToggleAccessibilityMode();
  if (error)
    return (
      <PageTimeErrorThrower
        componentName="SunflowerUserMenu"
        pageName="Student"
        error={error}
      />
    );
  if (props == null) return null; // silent loading
  const { viewer } = props;
  if (
    viewer == null ||
    viewer.profile == null ||
    viewer.profile.__typename !== 'Student'
  ) {
    throw new Error('User is not a student');
  }
  const {
    profile: student,
    profile: { classes },
  } = viewer;
  // Copied from `local_modules/ms-pages/StudentDashboard/Leaderboard/index.jsx`
  const showLeaderboard =
    _(classes).filter(klass => klass.hasEnabledLeaderboard).length > 0;
  // DropdownMenu items
  const toolboxItem = {
    key: 'toolbox',
    label: 'Toolbox',
    action: onToolboxClick,
    icon: <ToolboxIcon />,
  };
  const leaderboardItem = {
    key: 'leaderboard',
    label: 'Class leaderboard',
    action: isLeaderboardOpen.setTrue,
  };
  const activityItem = {
    key: 'student-activity',
    label: 'Activity',
    ...(hasStudentAppSpa
      ? { to: getStudentActivityUrl() }
      : { link: getStudentActivityUrl() }),
  };
  const toggleAccessibilityModeItem = {
    key: 'toggle-accessibility-mode',
    label: hasEnabledAccessibilityMode
      ? 'Disable accessibility mode'
      : 'Enable accessibility mode',
    action: toggleAccessibilityMode,
  };
  const joinClassItem = {
    key: 'join-class',
    label: 'Join a class',
    ...(hasStudentAppSpa
      ? { to: studyJoinClassUrl }
      : { link: studyJoinClassUrl }),
  };
  const settingsItem = {
    key: 'settings',
    label: 'Settings',
    ...(hasStudentAppSpa ? { to: settingsUrl } : { link: settingsUrl }),
  };
  const logoutItem = {
    key: 'logout',
    label: 'Logout',
    link: logoutUrl,
  };
  const exitStudentViewItem = {
    key: 'exit-student-experience',
    label: 'Exit Student Experience',
    link: exitStudentExperienceRedirectUrl,
  };
  const items = isOnboarding
    ? [
        toggleAccessibilityModeItem,
        isTeacherStudent ? exitStudentViewItem : logoutItem,
      ]
    : [
        toolboxItem,
        'divider' as const,
        activityItem,
        showLeaderboard && leaderboardItem,
        showLeaderboard && ('divider' as const),
        toggleAccessibilityModeItem,
        joinClassItem,
        settingsItem,
        !isTeacherStudent && logoutItem,
        isTeacherStudent && ('divider' as const),
        isTeacherStudent && exitStudentViewItem,
      ];
  return (
    <PageTimeRecorder componentName="SunflowerUserMenu" pageName="Student">
      <SunflowerUserMenuTriggerWrapper
        accessibilityMode={hasEnabledAccessibilityMode}
        ref={menuAnchorRef}
        onClick={open}
        tabIndex={0}
        onKeyDown={keyDownMap({
          ENTER: [open, { preventDefault: true }],
          SPACE: [open, { preventDefault: true }],
        })}
      >
        <HStack center>
          <AvatarWrapper>
            <Avatar src={avatar} size={AVATAR_SIZE} />
          </AvatarWrapper>
        </HStack>
      </SunflowerUserMenuTriggerWrapper>

      {isOpen &&
        (renderMode === 'popover' ? (
          <DropdownMenu
            onDismiss={close}
            anchorRef={menuAnchorRef}
            menuPosition="bottom-left"
            anchorOrigin="right"
            hOffset={AVATAR_WRAPPER_PADDING}
            vOffset={0}
            minWidth={MENU_WIDTH}
            items={items}
            header={<UserInfo viewerRef={viewer} />}
          />
        ) : (
          <>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'white',
                zIndex: 20,
                padding: 24,
              }}
            >
              <HStack justify="end">
                <Button
                  onClick={close}
                  color="grey90"
                  label="close student menu"
                >
                  Close
                  <HSpacer width={8} />
                  <CrossBold size={14} />
                </Button>
              </HStack>
              <Menu
                isInline
                items={items}
                close={close}
                header={<UserInfo viewerRef={viewer} />}
                minWidth={MENU_WIDTH}
              />
            </div>
          </>
        ))}

      {showLeaderboard && (
        <LeaderboardModal
          isOpen={isLeaderboardOpen.value}
          onClose={isLeaderboardOpen.setFalse}
          studentKey={student}
        />
      )}
    </PageTimeRecorder>
  );
}
const USER_INFO_FRAGMENT = graphql`
  fragment SunflowerUserMenu_UserInfo_viewer on User
  @argumentDefinitions(numberOfClasses: { type: "Int!" }) {
    avatar
    firstName
    lastName
    profile {
      ... on Student {
        school {
          title
        }
        classes(first: $numberOfClasses) {
          edges {
            node {
              id
              title
              displayName
              hasEnabledLeaderboard
            }
          }
        }
        lanternStudent {
          selfReportedGrade {
            id
            title
          }
        }
      }
    }
  }
`;
// IMPORTANT:
// Tooltip from ms-pages/Lantern/primitives/Tooltip  was causinng popover content measurements issues, so ms-ui-primitives/Tooltip was used
function UserInfo({
  viewerRef,
}: {
  viewerRef: SunflowerUserMenu_UserInfo_viewer$key;
}) {
  const viewer = useFragment(USER_INFO_FRAGMENT, viewerRef);
  const {
    featureFlags: { hasStudentAppSpa },
  } = useViewer();
  if (viewer == null || viewer.profile == null) {
    throw new Error('User is not found');
  }
  const {
    avatar,
    firstName,
    lastName,
    profile: { school, classes, lanternStudent },
  } = viewer;
  const schoolTitle = school?.title ?? '';
  const classesTitles = classes ? getClassesTitles(classes) : '';
  const gradeTitle = lanternStudent?.selfReportedGrade.title ?? '';
  return (
    <Wrapper>
      <HStack center>
        {/* Avatar */}
        <Tooltip title="Change avatar" sunflowerStyle>
          <div
            style={{
              height:
                AVATAR_SIZE /** Not sure why but if this is not specified, an extra 2px is added */,
            }}
          >
            {hasStudentAppSpa ? (
              <LinkButton isInline to={accountsAvatarUrl} label="Change avatar">
                <Avatar src={avatar} size={AVATAR_SIZE} />
              </LinkButton>
            ) : (
              <AnchorButton
                isInline
                href={accountsAvatarUrl}
                label="Change avatar"
                role="menuitem"
              >
                <Avatar src={avatar} size={AVATAR_SIZE} />
              </AnchorButton>
            )}
          </div>
        </Tooltip>

        {/* User name */}
        <HSpacer width={16} />

        <HeadingS>
          {firstName} {lastName}
        </HeadingS>
      </HStack>

      {/* School title */}
      <VSpacer height={16} />

      <BodyM>
        <HStack center>
          <SchoolIcon color={ICON_COLOR} size={ICON_SIZE} />
          <HSpacer width={12} />
          <List title={schoolTitle}>{schoolTitle}</List>
        </HStack>
      </BodyM>

      {/* Classes */}
      {classesTitles !== '' && (
        <>
          <VSpacer height={4} />
          <BodyM>
            <HStack center>
              <ClassIcon color={ICON_COLOR} size={ICON_SIZE} />
              <HSpacer width={12} />
              <List title={classesTitles}>{classesTitles}</List>
            </HStack>
          </BodyM>
        </>
      )}

      {/* Grade level */}
      <VSpacer height={4} />

      <BodyM>
        <HStack center>
          <GraduateIcon color={ICON_COLOR} size={ICON_SIZE} />
          <HSpacer width={12} />
          <List title={gradeTitle}>{gradeTitle}</List>
        </HStack>
      </BodyM>
    </Wrapper>
  );
}
