import { useContext, useEffect } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { taskRefetchBus } from 'ms-helpers/Tasks/signaling';
import type { TaskDetail_assignedEntities as AssignedEntities } from 'ms-pages/Teacher/TaskReport/__generated__/TaskDetail_assignedEntities.graphql';
import type { TaskDetail_task as Task } from 'ms-pages/Teacher/TaskReport/__generated__/TaskDetail_task.graphql';
import useDeleteTask from 'ms-pages/Teacher/TaskReport/components/TaskReportHeader/useDeleteTask';
import Flyout, { FlyoutContext } from 'ms-pages/Teacher/components/Flyout';
import { FlyoutBody } from 'ms-pages/Teacher/components/Flyout/components/FlyoutBody';
import { Footer } from 'ms-pages/Teacher/components/Flyout/components/Footer';
import { BackHeader } from 'ms-pages/Teacher/components/Flyout/components/Header';
import { Root } from 'ms-pages/Teacher/components/Flyout/components/Root';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

const Title = styled({
  fontFamily: fontFamily.body,
  paddingTop: 5 * BASE_UNIT,
  color: colors.cloudBurst,
  fontWeight: fontWeight.semibold,
});
const Label = styled({
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  color: colors.grayChateau,
  textTransform: 'uppercase',
  marginBottom: 2 * BASE_UNIT,
  fontSize: fontSize.xSmall,
});
const Detail = styled({
  paddingTop: 5 * BASE_UNIT,
  fontFamily: fontFamily.body,
});
type FlyoutContentsProps = {
  task: Task;
  assignedEntities: AssignedEntities;
  onTaskMutated?: (() => void) | undefined;
};
function FlyoutContents({
  task,
  assignedEntities,
  onTaskMutated,
}: FlyoutContentsProps) {
  const [deleteTask, { response }] = useDeleteTask({
    taskId: task.id,
  });
  const { closeFlyout } = useContext(FlyoutContext);
  const { enqueueMessage } = useContext(SnackbarContext);
  // Instead, let's use the context and mark it dirty.
  useEffect(() => {
    if (response != null) {
      if (response.deleteTask.taskId != null) {
        taskRefetchBus.signal();
        if (onTaskMutated != null) onTaskMutated();
      } else {
        enqueueMessage({ text: 'Unable to delete the task' });
      }
    }
  }, [enqueueMessage, onTaskMutated, response]);
  return (
    <Root>
      <BackHeader title="Delete task" onClick={closeFlyout} />
      <FlyoutBody>
        <Title>Are you sure you would like to delete the following task?</Title>
        <Detail>
          <Label>Area of study</Label>
          {task.title}
        </Detail>
        {task.__typename === 'AdaptiveTask' && (
          <Detail>
            <Label>Target</Label>
            Achieve {task.targetMastery}% mastery
          </Detail>
        )}
        <Detail>
          <Label>Assigned to</Label>
          {assignedEntities.assignedClasses.map(c => (
            <p key={c.id}>{c.title}</p>
          ))}
          {assignedEntities.assignedStudents.map(s => (
            <p key={s.id}>
              {s.user.firstName} {s.user.lastName}
            </p>
          ))}
        </Detail>
      </FlyoutBody>
      <Footer>
        <Button isBlock type="primary" onClick={deleteTask}>
          Delete
        </Button>
      </Footer>
    </Root>
  );
}
type Props = FlyoutContentsProps & {
  isOpen: boolean;
  onClose: (p: boolean) => void;
};
export default function DeleteTaskFlyout({ isOpen, onClose, ...props }: Props) {
  return (
    <Flyout isOpen={isOpen} onClose={() => onClose(false)}>
      <FlyoutContents {...props} />
    </Flyout>
  );
}
