import { useState, useCallback, useEffect } from 'react';

type Options = {
  stopPropagation?: boolean;
};

export function useClickOutsideRef<T extends HTMLElement>(
  callback: () => void,
  options: Options = {},
) {
  const { stopPropagation = false } = options;
  const [node, setNode] = useState<T | null>(null);

  const wrappedOnClick = useCallback(
    (e: MouseEvent) => {
      if (!(e.target instanceof Element) || node === null) return;
      // if the target is a descendent of the node, then we're clicking
      // within the subtree (ie. not clicking outside), so ignore the click.
      if (node.contains(e.target)) return;

      if (stopPropagation) e.stopPropagation();
      callback();
    },
    [callback, stopPropagation, node],
  );

  useEffect(() => {
    document.addEventListener('click', wrappedOnClick, { capture: true });

    return () => {
      document.removeEventListener('click', wrappedOnClick, {
        capture: true,
      });
    };
  }, [wrappedOnClick]);

  return useCallback((refNode: typeof node) => setNode(refNode), []);
}
