import moment from 'moment';

import type { TaskTypes } from 'ms-pages/Teacher/TaskReport/components/TaskReportTasks/__generated__/TaskReportTasks_task.graphql';
import type { QueryParamState as DateRangeQueryParamState } from 'ms-pages/Teacher/components/DateRangeInput/queryParamState';
import type { DateISOString } from 'ms-utils/datetime';
import type {
  QueryStringBoolean,
  QueryStringPositiveFlag,
} from 'ms-utils/urls/queryParams';

import { makeStartDate, makeEndDate } from './helpers';

type TaskAssigneeType = 'CLASS' | 'STUDENTS';
export { TaskTypes };
export const PERIOD_OPTIONS = ['7', '14', '30'];
export type DateRangeState = DateRangeQueryParamState;
export type SyllabusIds = string; // comma-separated list of syllabuses ids
export type WorkedOn = QueryStringBoolean;
export type StartDate = DateISOString;
export type EndDate = DateISOString;
export type TaskTypeSelector = {
  [K in TaskTypes]?: boolean;
};
export type SkillsSortBy = 'DOMAIN_DESCENDING' | 'DOMAIN_ASCENDING';
export type State = DateRangeState & {
  syllabusIds: SyllabusIds | null;
  workedOn: WorkedOn;
  selectedAssignees: TaskAssigneeType | null;
  taskTypeSelector: TaskTypeSelector;
  showActivityChart: boolean;
  growthPeriod: number;
  skillsSortBy: SkillsSortBy;
};
export type SerializedState = DateRangeState & {
  syllabusIds: SyllabusIds | null;
  workedOn: WorkedOn;
  selectedAssignees: TaskAssigneeType | null;
  taskTypeSelector: TaskTypeSelector;
  showActivityChart: QueryStringPositiveFlag;
  growthPeriod: string;
  skillsSortBy: SkillsSortBy;
};

export type Updater<I extends any[]> = (
  ...args: I
) => (state: SerializedState) => SerializedState;
export const initialState: State = {
  period: '14',
  useCustomPeriod: 'false',
  customStartDate: makeStartDate(moment().subtract(28, 'days').toDate()),
  customEndDate: makeEndDate(new Date()),
  syllabusIds: null,
  workedOn: 'false',
  taskTypeSelector: {},
  selectedAssignees: null,
  showActivityChart: false,
  growthPeriod: 120,
  skillsSortBy: 'DOMAIN_DESCENDING',
};

export const serializeState = (state: State): SerializedState => ({
  ...state,
  showActivityChart: state.showActivityChart ? 'Y' : '',
  growthPeriod: state.growthPeriod.toString(),
});

export const deserializeState = (state: SerializedState): State => ({
  ...state,
  showActivityChart: state.showActivityChart === 'Y',
  growthPeriod: parseInt(state.growthPeriod),
});
