import styled from '@emotion/styled';

import { transition } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const Wrapper = styled.div({
  borderStyle: 'solid',
  borderWidth: 1,
  transition: `background-color ${transition}, border-color ${transition}`,
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
});

type Props = {
  size: number;
  checked: boolean;
  color: string;
};

const RadioButton = ({ checked, color, size }: Props) => (
  <Wrapper
    style={{
      borderColor: checked ? color : colors.iron,
      width: size,
      height: size,
      fontSize: size,
      ...(checked && {
        backgroundColor: colors.white,
        boxShadow: `inset 0 0 0 ${size / 3 - 1}px ${color}`,
      }),
    }}
  />
);

export default RadioButton;
