import { StyleSheet } from 'aphrodite';

import {
  fontFamily,
  fontSize,
  transition,
  borderRadiusUI,
  fontWeight,
  inputHeight,
  zIndex,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { onHover } from 'ms-utils/emotion';

export const BORDER_WIDTH = 1;
export const INPUT_HEIGHT = inputHeight;
const ICON_TAP_AREA = 36; // big enough for tapping
const LEFT_PADDING = 12;
const LIST_ITEM_PADDING = 10;
const OPTION_LIST_SHADOW =
  '1px 1px 1px rgba(0,0,0,.05), -1px -1px 1px rgba(0,0,0,.05), 0 1px 3px rgba(0, 0, 0, 0.2)';
const RIGHT_PADDING = 22;
const VERTICAL_PADDING = 9;

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.normal,
  },
  inputWrapper: {
    position: 'relative',
  },
  input: {
    color: colors.cloudBurst,
    outline: 'none',
    border: `${BORDER_WIDTH}px solid ${colors.iron}`,
    borderRadius: borderRadiusUI,
    fontSize: fontSize.input,
    fontWeight: fontWeight.normal,
    fontFamily: fontFamily.body,
    padding: `${VERTICAL_PADDING}px ${RIGHT_PADDING}px ${VERTICAL_PADDING}px ${LEFT_PADDING}px`,
    display: 'block',
    height: INPUT_HEIGHT,
    width: '100%',
    transition: `border-color ${transition}`,
    cursor: 'default',
    boxSizing: 'border-box',

    '::-webkit-input-placeholder': {
      color: colors.grayChateau,
      textOverflow: 'ellipsis',
    },
    ':-moz-placeholder': {
      color: colors.grayChateau,
      textOverflow: 'ellipsis',
    },
    '::-moz-placeholder': {
      color: colors.grayChateau,
      textOverflow: 'ellipsis',
    },
    ':-ms-input-placeholder': {
      color: colors.grayChateau,
      textOverflow: 'ellipsis',
    },
  },
  inputFocused: {
    border: `${BORDER_WIDTH}px solid ${colors.matisse}`,
    cursor: 'text',
  },
  noResults: {
    borderColor: colors.cinnabar,
  },
  iconWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: ICON_TAP_AREA,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.dustyGray,
    ...onHover({
      color: colors.mako,
    }),
  },
  arrow: {
    transition: `transform ${transition}, color ${transition}`,
    display: 'block',
  },
  arrowOpen: {
    transform: 'rotateZ(180deg)',
  },
  popover: {
    background: colors.white,
    boxShadow: OPTION_LIST_SHADOW,
    position: 'absolute',
    width: '100%',
    borderRadius: borderRadiusUI,
    overflow: 'hidden',
    fontSize: fontSize.input,
    fontFamily: fontFamily.body,
    zIndex: zIndex.selectOptions,
  },
  list: {
    background: colors.athensGray,
    overflowY: 'auto',
  },
  listItem: {
    fontWeight: fontWeight.normal,
    padding: LIST_ITEM_PADDING,
    transition: `background ${transition}`,
    ...onHover({
      cursor: 'default',
    }),
  },
  listItemActive: {
    background: colors.polar,
    color: colors.mako,
  },
  listItemDisabled: {
    fontWeight: fontWeight.normal,
    padding: LIST_ITEM_PADDING,
    color: colors.grayChateau,
  },
});

export default styles;
