/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SelectorsWithSearch_teacher = {
    readonly " $fragmentRefs": FragmentRefs<"ClassSelector_teacher" | "StudentSelector_teacher">;
    readonly " $refType": "SelectorsWithSearch_teacher";
};
export type SelectorsWithSearch_teacher$data = SelectorsWithSearch_teacher;
export type SelectorsWithSearch_teacher$key = {
    readonly " $data"?: SelectorsWithSearch_teacher$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SelectorsWithSearch_teacher">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectorsWithSearch_teacher",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClassSelector_teacher"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "kind": "FragmentSpread",
      "name": "StudentSelector_teacher"
    }
  ],
  "type": "Teacher",
  "abstractKey": null
};
(node as any).hash = '7f7dffbd57474625feff2271da23c954';
export default node;
