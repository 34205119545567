import { Route, Switch } from 'react-router-dom';

import Navbar from 'ms-components/StudentNavbar';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import StudentSubtopicDetailView from 'ms-pages/Textbooks/StudentTextbook/components/StudentSubtopicDetailView';
import DetailEmptyView from 'ms-pages/Textbooks/components/DetailEmptyView';
import LeftSidebar from 'ms-pages/Textbooks/components/LeftSidebar';
import { LastActiveTextbookContentRedirector } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import SunflowerSubtopicDetailView from 'ms-pages/Textbooks/components/SunflowerSubtopicDetailView/SunflowerSubtopicDetailView';
import { TextbookRedirector } from 'ms-pages/Textbooks/components/TextbookRedirector';
import { StudentTextbookLayout } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookLayout';
import SubtopicRedirector from 'ms-pages/Textbooks/routing/SubtopicRedirector';
import {
  contentBrowsingEmptyViewPath,
  contentBrowsingSubtopicDetailViewPath,
  contentBrowsingSubtopicRedirectorPath,
} from 'ms-utils/urls';

export default function StudentTextbook() {
  const {
    featureFlags: { classicUi },
  } = useViewer();

  return (
    <>
      <LastActiveTextbookContentRedirector />
      <Switch>
        <Route>
          <StudentTextbookLayout LeftAside={LeftSidebar} Navbar={Navbar}>
            <Switch>
              <Route
                path={contentBrowsingEmptyViewPath}
                exact
                render={() => (
                  <DetailEmptyView
                    title="Select a topic to start exploring"
                    actionDescription="browse"
                  />
                )}
              />

              <Route
                path={contentBrowsingSubtopicRedirectorPath}
                exact
                render={({ match: { params } }) => (
                  <SubtopicRedirector
                    syllabusId={params.syllabusId}
                    topicId={params.topicId}
                  />
                )}
              />
              <Route
                path={contentBrowsingSubtopicDetailViewPath}
                render={({ match: { params } }) =>
                  !classicUi ? (
                    <SunflowerSubtopicDetailView
                      subtopicId={params.subtopicId}
                    />
                  ) : (
                    <StudentSubtopicDetailView subtopicId={params.subtopicId} />
                  )
                }
              />
              <Route component={TextbookRedirector} />
            </Switch>
          </StudentTextbookLayout>
        </Route>
      </Switch>
    </>
  );
}
