import { map } from 'ramda';
import type { ReactNode } from 'react';
import { useMemo, createContext, useState } from 'react';

import type {
  State,
  UseClassSyllabusFocus,
  Email,
  Classes,
  GuardianEmails,
  SyllabusFocusSelectedClass,
  StudentName,
  SyllabusFocus,
  SelfReportedGradeId,
  UseClassGrade,
  GradeSelectedClass,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state/State';
import {
  initialState,
  EMPTY_ITEM,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state/State';
import * as updaters from 'ms-pages/Teacher/components/StudentCreateEdit/state/updaters';

export { EMPTY_ITEM };

type Updaters = typeof updaters;
type TransformedUpdaters = {
  [P in keyof Updaters]: (...args: Parameters<Updaters[P]>) => void;
};

const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export const StateContext = createContext<State>(initialState);

export const UpdatersContext = createContext<TransformedUpdaters>(
  transformUpdaters(() => {}),
);

type Props = {
  classes: Classes;
  useClassSyllabusFocus?: UseClassSyllabusFocus | undefined;
  email?: Email | undefined;
  firstName?: StudentName | undefined;
  lastName?: StudentName | undefined;
  guardianEmails?: GuardianEmails | undefined;
  syllabusFocusSelectedClass?: SyllabusFocusSelectedClass | undefined;
  syllabusFocus: SyllabusFocus | null;
  useClassGrade?: UseClassGrade | undefined;
  gradeSelectedClass?: GradeSelectedClass | undefined;
  selfReportedGradeId?: SelfReportedGradeId | undefined;
  children: ReactNode;
};

export function StateProvider({
  classes,
  email,
  firstName,
  lastName,
  guardianEmails,
  syllabusFocus,
  syllabusFocusSelectedClass,
  useClassSyllabusFocus,
  useClassGrade,
  gradeSelectedClass,
  selfReportedGradeId,
  children,
}: Props) {
  const i = initialState; // aliasing for brevity

  const [state, setState] = useState({
    classes,
    email: email != null ? email : i.email,
    firstName: firstName != null ? firstName : i.firstName,
    lastName: lastName != null ? lastName : i.lastName,
    guardianEmails: guardianEmails != null ? guardianEmails : i.guardianEmails,
    syllabusFocus: syllabusFocus != null ? syllabusFocus : i.syllabusFocus,
    syllabusFocusSelectedClass:
      syllabusFocusSelectedClass != null
        ? syllabusFocusSelectedClass
        : i.syllabusFocusSelectedClass,
    useClassSyllabusFocus:
      useClassSyllabusFocus != null
        ? useClassSyllabusFocus
        : i.useClassSyllabusFocus,
    gradeSelectedClass:
      gradeSelectedClass != null ? gradeSelectedClass : i.gradeSelectedClass,
    useClassGrade: useClassGrade != null ? useClassGrade : i.useClassGrade,

    buttonsDisabled: i.buttonsDisabled,
    shouldCheckForDuplicates: i.shouldCheckForDuplicates,
    selfReportedGradeId:
      selfReportedGradeId != null ? selfReportedGradeId : i.selfReportedGradeId,
  });

  return (
    <StateContext.Provider value={state}>
      <UpdatersContext.Provider
        value={useMemo(() => transformUpdaters(setState), [])}
      >
        {children}
      </UpdatersContext.Provider>
    </StateContext.Provider>
  );
}
