import { css } from 'aphrodite';
import { useEffect } from 'react';

import { colors } from 'ms-styles/colors';
import { sessionStorageDb } from 'ms-utils/localStorageDb';

import type { AspectRatio } from '.';
import { styles, paddingBottom } from './styles';

const PLAYER_COLOR = colors.mountainMeadow.slice(1); // Remove hexcode

declare global {
  interface Window {
    // wq stands for WistiaQueue. This is an idiotic mechanism by which
    // you can latch onto a video that exists (or will exist) in Wistia's
    // runtime. You push objects into this global array, which Wistia polls
    // for changes; removes objects it finds in there; and then modifies
    // the video model to change its configuration and register various
    // event handlers.
    _wq?: object[];
  }
}

// https://wistia.com/support/developers/player-api#list-of-player-methods
type WistiaPlayer = {
  // Only typing the subset of methods currently in use by us
  play(): void;
  time(seekTo: number): void;
  bind(eventType: string, cb: (...args: any[]) => void): void;
  remove(): void;
};

type Props = {
  id: string;
  aspectRatio: AspectRatio;
  hasPlaylist?: boolean | undefined;
  useIframeEmbed?: boolean | undefined;
  autoPlay?: boolean | undefined;
  controlsVisibleOnLoad?: boolean | undefined;
  fullscreenButton?: boolean | undefined;
  playButton?: boolean | undefined;
  // A context token that is used to uniquely identify this video
  // in a rendering context. This is to make sure videos with the same ID, but
  // rendered in different places, do not pick up each other's resume state.
  // It's generally safe to omit the videoId from this token. It will be added automatically
  resumableWith?: { resumeToken: string } | undefined;
  silentAutoPlay?: boolean | undefined;
};

export default function WistiaVideo({
  id,
  aspectRatio,
  hasPlaylist = false,
  autoPlay = false,
  controlsVisibleOnLoad = false,
  fullscreenButton = true,
  playButton = true,
  silentAutoPlay = false,
  resumableWith,
  useIframeEmbed = true,
}: Props) {
  const resumeToken = resumableWith?.resumeToken ?? null;

  // Lazy load the wistia SDK
  useEffect(() => {
    // Only if we're configuring wistia via dom attributes + the wistia SDK
    // should we load the SDK, otherwise we're just using a iframe embed,
    // and that iframe will load the SDK in its execution context.
    if (shouldRenderInDiv({ resumeToken, useIframeEmbed }) === false) return;
    loadWistiaSdk();
  }, [resumeToken, useIframeEmbed]);

  // Configure the wistia video if we're using the wistia SDK for this video to:
  // a) autoPlay once the player is ready (if the component is configured for autoPlay)
  // b) resume from a previous time (if the component is configured to resume)
  useEffect(() => {
    if (shouldRenderInDiv({ resumeToken, useIframeEmbed }) === false) return;
    let staleEffect = false;
    let _video: WistiaPlayer | null = null;
    const videoConfig = {
      id,
      onReady: (video: WistiaPlayer) => {
        if (staleEffect) return;
        _video = video;
        if (autoPlay) {
          video.play();
        }
        if (resumeToken !== null) {
          const token = `${id}-${resumeToken}`;
          const lastTime = sessionStorageDb.get(token);
          if (lastTime != null) {
            video.time(lastTime);
          } else {
            // We don't have a value in our own resumable data store, however
            // wistia will store a bunch of resume data in local storage. We want
            //  to force it to reset as we don't have time data for this video token.
            video.time(0);
          }
          let requestId: number;
          video.bind('timechange', (time: number) => {
            if (requestId) {
              cancelAnimationFrame(requestId);
            }
            requestId = requestAnimationFrame(() => {
              sessionStorageDb.set(token, time);
            });
          });
        } else {
          // all non-resumable videos always start from the beginning.
          video.time(0);
        }
      },
    };
    window._wq = window._wq ?? [];
    window._wq.push(videoConfig);

    return () => {
      staleEffect = true;
      // We have to manually revoke any prior videoConfig that was passed
      // to Wistia's runtime 🙄. Without manually revoking these we would:
      //   a) be leaking memory
      //   b) end up with multiple event handlers registered for a given video
      //    for every time that video is displayed. This is particular bad
      //    when we try to change the config (eg maybe autoPlay is on, and
      //    then off. Without revokation it would continue to autoPlay)
      window._wq?.push({ revoke: videoConfig });
      if (_video !== null) {
        // Frees resources, stops data streaming etc
        _video.remove();
      }
    };
  }, [id, resumeToken, useIframeEmbed, autoPlay]);

  return shouldRenderInDiv({ resumeToken, useIframeEmbed }) ? (
    <div
      className={`wistia_responsive_padding ${css(styles.paddingWrapper)}`}
      style={{
        paddingBottom: `${paddingBottom(aspectRatio)}%`,
      }}
    >
      <div className={`wistia_responsive_wrapper ${css(styles.wrapper)}`}>
        <div
          className={`${makeInlineClassNames({
            id,
            hasPlaylist,
            autoPlay,
            controlsVisibleOnLoad,
            fullscreenButton,
            playButton,
            silentAutoPlay,
          })} ${css(styles.player)}`}
        >
          &nbsp;
        </div>
      </div>
    </div>
  ) : (
    <div
      className={css(styles.paddingWrapper)}
      style={{ paddingBottom: `${paddingBottom(aspectRatio)}%` }}
    >
      <iframe
        className={css(styles.wrapper)}
        src={makeIframeUrl({
          id,
          autoPlay,
          controlsVisibleOnLoad,
          fullscreenButton,
          playButton,
          silentAutoPlay,
        })}
        title="Wistia video player"
        frameBorder="0"
        scrolling="no"
        allowFullScreen
      />
    </div>
  );
}

type WistiaOptions = {
  autoPlay: boolean;
  controlsVisibleOnLoad: boolean;
  fullscreenButton: boolean;
  playButton: boolean;
  silentAutoPlay: boolean;
};

function makeIframeUrl({
  id,
  autoPlay,
  controlsVisibleOnLoad,
  fullscreenButton,
  playButton,
}: WistiaOptions & {
  id: string;
}): string {
  return (
    'https://fast.wistia.net/embed/iframe/' + // eslint-disable-line prefer-template
    id +
    `?autoplay=${autoPlay.toString()}` +
    `&controlsVisibleOnLoad=${controlsVisibleOnLoad.toString()}` +
    `&fullscreenButton=${fullscreenButton.toString()}` +
    `&playButton=${playButton.toString()}` +
    `&playerColor=${PLAYER_COLOR}`
  );
}

function makeInlineClassNames({
  id,
  hasPlaylist,
  autoPlay,
  controlsVisibleOnLoad,
  fullscreenButton,
  playButton,
  silentAutoPlay,
}: WistiaOptions & {
  id: string;
  hasPlaylist: boolean;
}): string {
  return [
    'wistia_embed',
    `wistia_async_${id}`,
    `${hasPlaylist ? 'playlistLinks=auto' : ''}`,
    `autoPlay=${autoPlay.toString()}`,
    `controlsVisibleOnLoad=${controlsVisibleOnLoad.toString()}`,
    `fullscreenButton=${fullscreenButton.toString()}`,
    `playButton=${playButton.toString()}`,
    `playerColor=${PLAYER_COLOR}`,
    `silentAutoPlay=${silentAutoPlay.toString()}`,
  ].join(' ');
}

function shouldRenderInDiv({
  resumeToken,
  useIframeEmbed,
}: {
  resumeToken: string | null;
  useIframeEmbed: Props['useIframeEmbed'];
}): boolean {
  return !useIframeEmbed || resumeToken !== null;
}

// We use module-scope to track if any component instance has loaded
// the wistia sdk. Only the first WistiaVideo that mounts should load it.
let hasLoadedWistiaSdk = false;

function loadWistiaSdk() {
  if (hasLoadedWistiaSdk) return;
  const script = document.createElement('script');
  script.src = '//fast.wistia.com/assets/external/E-v1.js';
  script.async = true;
  if (document.body) document.body.appendChild(script);
}
