import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Drag = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
    color={color}
  >
    <rect y="10" width="16" height="2" rx="1" fill={color} />
    <rect y="4" width="16" height="2" rx="1" fill={color} />
  </Icon>
);

Drag.displayName = 'Drag';

export default Drag;
