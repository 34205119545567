import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getCheckInUrl } from 'ms-pages/Lantern/utils/urls';
import useCreateSkillCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/useCreateSkillCheckIn';

import { CHECK_IN_CREATION_ERROR } from './utils';

function useStartSkillCheckIn({
  skillId,
  strandId,
  replace = false,
}: {
  skillId: string;
  strandId: string;
  replace?: boolean;
}) {
  const history = useHistory();
  const { search: queryString } = useLocation();
  const [
    createSkillCheckIn,
    {
      data: createCheckInResponse,
      loading: createCheckInLoading,
      error: createCheckInError,
    },
  ] = useCreateSkillCheckIn();

  const errors = useMemo(
    () => [
      ...(createCheckInError != null ? [CHECK_IN_CREATION_ERROR] : []),
      ...(createCheckInResponse?.createSkillCheckIn.errors || []),
    ],
    [createCheckInError, createCheckInResponse],
  );

  useEffect(() => {
    if (
      createCheckInResponse != null &&
      createCheckInResponse.createSkillCheckIn.checkIn?.id != null
    ) {
      const checkInUrl = getCheckInUrl({
        checkInId: createCheckInResponse.createSkillCheckIn?.checkIn?.id,
      });
      if (replace) {
        history.replace({ pathname: checkInUrl, search: queryString });
      } else {
        history.push({ pathname: checkInUrl, search: queryString });
      }
    }
  }, [createCheckInResponse, history, replace, queryString]);

  return {
    loading: createCheckInLoading,
    errors,
    start: () => {
      createSkillCheckIn({ variables: { skillId, strandId } });
    },
  };
}

export default useStartSkillCheckIn;
