import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay';

import SubproblemInput, {
  isThereInputToRender,
  InputWrapper,
} from 'ms-components/PreviewProblem/components/Subproblems/SubproblemInput';
import { SUBPROBLEM_INPUT_VERTICAL_MARGIN } from 'ms-components/PreviewProblem/components/constants';
import MathContent from 'ms-components/math/MathContent';
import { fontSize } from 'ms-styles/base';

import type { PreviewProblemSubproblem_subproblem$key } from './__generated__/PreviewProblemSubproblem_subproblem.graphql';

const Instruction = styled.div({
  fontSize: fontSize.medium,
  marginBottom: SUBPROBLEM_INPUT_VERTICAL_MARGIN,
});
const SubproblemContent = styled.div({
  flexGrow: 1,
  width: '100%',
});
export default function PreviewProblemSubproblem({
  subproblemKey,
}: {
  subproblemKey: PreviewProblemSubproblem_subproblem$key;
}) {
  const subproblem = useFragment(
    graphql`
      fragment PreviewProblemSubproblem_subproblem on SubproblemContent {
        instruction
        subproblemType
        ...SubproblemInput_subproblem
      }
    `,
    subproblemKey,
  );

  if (subproblem == null) {
    throw new Error('Subproblem not found');
  }

  return (
    <SubproblemContent>
      {subproblem.instruction != null && (
        <Instruction>
          <MathContent content={subproblem.instruction} />
        </Instruction>
      )}
      {isThereInputToRender(subproblem.subproblemType) && (
        <InputWrapper>
          <SubproblemInput subproblemKey={subproblem} />
        </InputWrapper>
      )}
    </SubproblemContent>
  );
}
