import { getCookie } from 'ms-utils/cookies';

declare global {
  interface Window {
    // Set in ms_ssr/browserGlobals.js
    inSSR?: boolean;
  }
}

/**
 * Get the CSRF Token for POST calls
 *
 * This will look for a meta tag with the name csrf-token and if it can't
 * find it will fallback to the csrf token in the cookie
 *
 * @returns {string} The value of the CSRF token or empty string
 */
export function getCSRFToken(): string {
  const csrfMeta = document.querySelector('meta[name=csrf-token]');
  let csrf = '';
  if (window.inSSR === true) return csrf;
  if (csrfMeta instanceof HTMLMetaElement) {
    csrf = csrfMeta ? csrfMeta.content : '';
  } else {
    csrf = getCookie('csrftoken') || '';
  }
  return csrf;
}
