import { css } from '@emotion/css';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { usePrevious } from 'ms-pages/Debug/VideoRecordingEnvironment/utilityHooks';
import Item from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/Item';
import {
  StateContext as ProblemsCollectionState,
  UpdatersContext as ProblemsCollectionUpdaters,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type {
  Problem,
  State,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import {
  MAX_NUMBER_OF_QUESTIONS,
  reorderList,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';
import type { CollectionType } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/updaters';
import EmptyView from 'ms-pages/Textbooks/components/ContentCollection/components/EmptyView';
import { PROBLEM_ITEM_DEFAULT_BOTTOM_MARGIN } from 'ms-pages/Textbooks/components/ProblemItem/constants';
import { styledVerticallyScrollable } from 'ms-utils/emotion';

const styles = {
  problemsCollectionWrapper: css({
    height: '100%',
    ...styledVerticallyScrollable,
  }),
  problemWrapper: css({
    // WE NEED TO SET THE MARGIN FOR THE DRAGGABLE ELEMENT AT THE HIGHEST LEVEL
    // AND ONLY TO THIS ELEMENT FOR DRAG AND DROP TO WORK PROPERLY,
    // OTHERWISE DURING THE DRAG, THE ELEMENT'S SIZE CAN'T BE CALCULATED PROPERLY BY react-beautiful-dnd
    // AND react-beautiful-dnd IGNORES ANY MARGINS SET IN THE CHILDREN WRAPPER ELEMENTS
    // IF WE SET MARGIN TO THE PROBLEM ITEM ITSELF, IT'S IGNORED BY react-beautiful-dnd DURING THE DRAG CALCULATIONS
    // WHICH CAUSES THE ELEMENTS TO OVERLAP AND GET DELAYED IN PROPER POSITIONING AFTER THE DROP
    // THERE SHOULDN'T BE ANY MARGIN ON THE PROBLEM ITEM ITSELF IF THE PROBLEM ITEM IS DRAGGABLE
    // THIS IS VALID FOR ANY MARGINS SET DIRECTLY TO ANY PROBLEM COMPONENT DIRECT WRAPPERS THAT SEPARATES THE PROBLEM ITEMS
    // ANY INNER MARGINS ARE EXEMPT FROM THIS RULE
    marginBottom: PROBLEM_ITEM_DEFAULT_BOTTOM_MARGIN,
  }),
};

// Ad hoc type.
// Flow built-in type for `HTMLElement` doesn't have `scrollHeight`/`scrollTo` props.
// It's safe to define them inline as long as we check at runtime that they exist
type ScrollableEl = HTMLDivElement & {
  scrollHeight?: number;
  scrollTo?: (options: {
    left: number;
    top: number;
    behavior: 'smooth';
  }) => void;
};

function useScrollListAfterElementAdded(
  scrollableEl: ScrollableEl | null,
  problemsCollection: State['problemsCollection'],
) {
  const prevList = usePrevious(problemsCollection);

  useEffect(() => {
    if (prevList != null) {
      // This is a lame way to state that one or more elements were added to the list
      // It's working correctly in most cases though
      // I'm open to suggestions for alternatives
      const prevListFootprint = prevList.map(([pId]) => pId).join(',');
      const currentListFootPrint = problemsCollection
        .map(([pId]) => pId)
        .join(',');
      if (
        currentListFootPrint.startsWith(prevListFootprint) &&
        currentListFootPrint !== prevListFootprint
      ) {
        if (
          scrollableEl != null &&
          scrollableEl.scrollTo != null &&
          scrollableEl.scrollHeight
        ) {
          scrollableEl.scrollTo({
            left: 0,
            top: scrollableEl.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [prevList, problemsCollection, scrollableEl]);
}

/*
 * The content list component is very context dependent.
 * If you're running in to any bugs, double check the following things:
 *
 * 1. Is the content list the only scrolling container in the tree it belongs to?
 * 2. Does it have the correct context (ContentBrowsing/state)?
 */
export default function ContentList({
  prefilledProblems,
  setPrefilledProblems,
  collapsedInBulk,
  showTryProblem = false,
  useTransientProblemsCollection = false,
}: {
  collapsedInBulk?: boolean | undefined;
  prefilledProblems?: readonly Problem[] | undefined;
  setPrefilledProblems?:
    | React.Dispatch<React.SetStateAction<readonly Problem[]>>
    | undefined;
  showTryProblem?: boolean | undefined;
  useTransientProblemsCollection?: boolean | undefined;
}) {
  const shouldUsePrefilledProblems =
    prefilledProblems != null && setPrefilledProblems != null;
  const {
    problemsCollection: _problemsCollection,
    transientProblemsCollection,
  } = useContext(ProblemsCollectionState);
  const {
    removeProblemContent: _removeProblemContent,
    reorderProblemContent: _reorderProblemContent,
    duplicateProblemContent: _duplicateProblemContent,
  } = useContext(ProblemsCollectionUpdaters);

  const problemCollectionType: CollectionType = useTransientProblemsCollection
    ? 'transient'
    : 'main';

  const removeProblemContent = useCallback<(problemIndex: number) => void>(
    problemIndex => {
      if (shouldUsePrefilledProblems) {
        setPrefilledProblems(prevPrefilledProblems => [
          ...prevPrefilledProblems.slice(0, problemIndex),
          ...prevPrefilledProblems.slice(problemIndex + 1),
        ]);
      } else {
        _removeProblemContent(problemIndex, problemCollectionType);
      }
    },
    [
      _removeProblemContent,
      problemCollectionType,
      setPrefilledProblems,
      shouldUsePrefilledProblems,
    ],
  );

  const reorderProblemContent = useCallback<
    (originalIndex: number, finalIndex: number) => void
  >(
    (originalIndex, finalIndex) => {
      if (shouldUsePrefilledProblems) {
        setPrefilledProblems(prevPrefilledProblems =>
          reorderList(prevPrefilledProblems, originalIndex, finalIndex),
        );
      } else {
        _reorderProblemContent(
          originalIndex,
          finalIndex,
          problemCollectionType,
        );
      }
    },
    [
      _reorderProblemContent,
      setPrefilledProblems,
      shouldUsePrefilledProblems,
      problemCollectionType,
    ],
  );

  const duplicateProblemContent = useCallback<(problemIndex: number) => void>(
    problemIndex => {
      if (shouldUsePrefilledProblems) {
        setPrefilledProblems(prevPrefilledProblems => {
          return [
            ...prevPrefilledProblems.slice(0, problemIndex + 1),
            ...prevPrefilledProblems.slice(problemIndex),
          ].slice(0, MAX_NUMBER_OF_QUESTIONS);
        });
      } else {
        _duplicateProblemContent(problemIndex, problemCollectionType);
      }
    },
    [
      _duplicateProblemContent,
      setPrefilledProblems,
      shouldUsePrefilledProblems,
      problemCollectionType,
    ],
  );

  const problemsCollection = useTransientProblemsCollection
    ? transientProblemsCollection
    : shouldUsePrefilledProblems
    ? prefilledProblems
    : _problemsCollection;

  const scrollableRef = useRef<ScrollableEl | null>(null);

  useScrollListAfterElementAdded(scrollableRef.current, problemsCollection);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination != null && source != null) {
          reorderProblemContent(source.index, destination.index);
        }
      }}
    >
      <Droppable droppableId="problemsCollection">
        {dropProvided => (
          <div
            ref={el => {
              dropProvided.innerRef(el);
              scrollableRef.current = el;
            }}
            className={styles.problemsCollectionWrapper}
            {...dropProvided.droppableProps}
          >
            {problemsCollection.length === 0 && <EmptyView />}

            {problemsCollection.map(([problemContentId], index) => {
              const itemKey = `${problemContentId}-${index}`;
              return (
                <Draggable key={itemKey} draggableId={itemKey} index={index}>
                  {dragProvided => (
                    <div
                      ref={dragProvided.innerRef}
                      className={styles.problemWrapper}
                      {...dragProvided.draggableProps}
                    >
                      {/* This is a hack I've come up with to stop react-beautiful-dnd to throw an error on component mount
                         see https://github.com/atlassian/react-beautiful-dnd/issues/1756 for the issue, that's still open and unresolved,
                         and also see PortalDragger component in local_modules/ms-helpers/ReactBeautifulDragAndDrop/PortalDragger.tsx
                         that's used previously to stop this error being thrown for the old questions view which doesn't work for the new questions view
                         due to moving the drag handle down further into the ProblemItem component
                         which is behind a graphql query that makes drag handle unavailable until the question content is retrieved from the backend,
                         but this hack works for the new question view 😎
                         Also it's notable to mention that the old questions view doesn't need PortalDragger anymore after updating react-beautiful-dnd to v13
                         But the core issue in the react-beautiful-dnd library still exists for drag handles that are not available on component mount */}
                      <div
                        style={{ display: 'none' }}
                        {...dragProvided.dragHandleProps}
                      />
                      <Item
                        dragHandleProps={dragProvided.dragHandleProps}
                        problemContentId={problemContentId}
                        index={index + 1}
                        onRemoveProblem={() => {
                          removeProblemContent(index);
                        }}
                        onDuplicateProblem={() => {
                          duplicateProblemContent(index);
                        }}
                        collapsedInBulk={collapsedInBulk}
                        showTryProblem={showTryProblem}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
