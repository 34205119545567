import type { ReactNode } from 'react';

import ExclamationMarkFilled from 'ms-components/icons/ExclamationMarkFilled';
import {
  TooltipContent,
  TooltipTitle,
} from 'ms-pages/Teacher/components/Tooltip';
import { borderRadiusUI, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { styled } from 'ms-utils/emotion';

export const iconSizes = {
  small: 16,
  medium: 20,
};

const TOOLTIP_MAX_WIDTH = 120;

const Wrapper = styled({
  default: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.brickRed,
    borderRadius: borderRadiusUI,
    color: colors.white,
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    lineHeight: 1,
  },
  small: {
    height: iconSizes.small,
    minWidth: iconSizes.small,
    fontSize: 12,
  },
  medium: {
    height: iconSizes.medium,
    minWidth: iconSizes.medium,
    fontSize: 14,
  },
});

const WithLabel = styled({
  default: {
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    paddingLeft: BASE_UNIT,
    paddingRight: BASE_UNIT,
  },
  medium: {
    paddingLeft: 2 * BASE_UNIT,
    paddingRight: 2 * BASE_UNIT,
  },
});

const Separator = styled({
  flexShrink: 0,
  height: BASE_UNIT,
  width: BASE_UNIT,
});

type Props = {
  size?: 'small' | 'medium' | undefined;
  label?: ReactNode;
  tooltipTitle?: string;
  tooltipContent?: ReactNode;
};

export default function StrugglingIcon({
  size,
  label,
  tooltipTitle,
  tooltipContent,
}: Props) {
  const icon = <ExclamationMarkFilled size={size === 'small' ? 12 : 14} />;
  const children =
    label != null ? (
      <WithLabel small={size === 'small'} medium={size === 'medium'}>
        {icon}
        <Separator />
        {label}
      </WithLabel>
    ) : (
      icon
    );
  return (
    <Wrapper small={size === 'small'} medium={size === 'medium'}>
      {tooltipTitle != null || tooltipContent != null ? (
        <Tooltip
          isInlineFlex
          renderTooltipContent={() => (
            <TooltipContent maxWidth={TOOLTIP_MAX_WIDTH}>
              {tooltipTitle != null && (
                <TooltipTitle>{tooltipTitle}</TooltipTitle>
              )}
              {tooltipContent}
            </TooltipContent>
          )}
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </Wrapper>
  );
}
