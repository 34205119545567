import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M9.40663077,11.4896571 L9.08926041,6.73901955 C9.02975317,5.81335137 9,5.14886383 9,4.74553698 C9,4.19674799 9.14380701,3.76863288 9.43142533,3.4611788 C9.71904366,3.15372473 10.0975701,3 10.5670162,3 C11.1356409,3 11.5158203,3.19670154 11.7075659,3.59011051 C11.8993114,3.98351949 11.9951828,4.55048274 11.9951828,5.29101729 C11.9951828,5.72740371 11.9720414,6.17039541 11.925758,6.62000567 L11.4992916,11.5094928 C11.4530082,12.0913413 11.3538309,12.5376389 11.2017569,12.848399 C11.0496828,13.159159 10.7984338,13.3145367 10.4480023,13.3145367 C10.0909588,13.3145367 9.84301571,13.1641179 9.70416549,12.8632757 C9.56531526,12.5624336 9.46613801,12.1045653 9.40663077,11.4896571 Z M10.5075092,17 C10.1041824,17 9.75210314,16.8694166 9.45126098,16.608246 C9.15041882,16.3470753 9,15.9817724 9,15.5123264 C9,15.1023877 9.14380701,14.7536144 9.43142533,14.465996 C9.71904366,14.1783777 10.0711229,14.0345707 10.4876736,14.0345707 C10.9042242,14.0345707 11.2596094,14.1783777 11.5538396,14.465996 C11.8480699,14.7536144 11.9951828,15.1023877 11.9951828,15.5123264 C11.9951828,15.9751605 11.8464169,16.3388104 11.5488807,16.6032871 C11.2513445,16.8677637 10.9042241,17 10.5075092,17 Z" />
);

const ExclamationMark = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ExclamationMark.displayName = 'ExclamationMark';

export default ExclamationMark;
