import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import HardRedirect from 'ms-helpers/HardRedirect';
import PageTitle from 'ms-helpers/PageTitle';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import SunflowerDetailEmptyView from 'ms-pages/Sunflower/components/SunflowerDetailEmptyView';
import TeacherContextListener from 'ms-pages/Teacher/TeacherContext/TeacherContextListener';
import { useMarkAsDone } from 'ms-pages/Teacher/components/Onboarding/OnboardingChecklistMarkAsDone';
import TeacherSubtopicDetailView from 'ms-pages/Textbooks/TeacherTextbook/components/TeacherSubtopicDetailView';
import ChapterIntroView from 'ms-pages/Textbooks/components/ChapterIntroView';
import DetailEmptyView from 'ms-pages/Textbooks/components/DetailEmptyView';
import MaterialsGuideView from 'ms-pages/Textbooks/components/MaterialsGuideView';
import { LastActiveTextbookContentRedirector } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import SunflowerSubtopicDetailView from 'ms-pages/Textbooks/components/SunflowerSubtopicDetailView/SunflowerSubtopicDetailView';
import { TeacherTextbookTypesLayout } from 'ms-pages/Textbooks/components/TextbookTypesLayout';
import TopicOverview from 'ms-pages/Textbooks/components/TopicOverview';
import SubtopicRedirector from 'ms-pages/Textbooks/routing/SubtopicRedirector';
import {
  contentBrowsingChapterIntroViewPath,
  contentBrowsingMaterialsGuidePath,
  contentBrowsingTopicOverviewPath,
  contentBrowsingEmptyViewPath,
  contentBrowsingSubtopicDetailViewPath,
  contentBrowsingSubtopicRedirectorPath,
  paywallUrl,
  textbooksRedirectorPath,
} from 'ms-utils/urls';

export default function TeacherTextbook() {
  const [doViewTextbookOnboardingActionMarkAsDone] = useMarkAsDone({
    stepType: 'BROWSE_CONTENT',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(doViewTextbookOnboardingActionMarkAsDone, []);

  const {
    featureFlags: { classicUi },
  } = useViewer();

  const history = useHistory();

  const [{ primaryTeacherNotes }] = useFeatureFlags();

  return (
    <>
      <TeacherContextListener
        listener={() => history.push(textbooksRedirectorPath)}
      />
      <PageTitle subtitles={['Textbooks']} />
      <LastActiveTextbookContentRedirector />
      <Switch>
        <Route>
          <TeacherTextbookTypesLayout>
            <Switch>
              <Route
                path={contentBrowsingEmptyViewPath}
                exact
                render={() =>
                  !classicUi ? (
                    <SunflowerDetailEmptyView
                      title="Are you ready to pick a topic and explore our content?"
                      actionDescription="Built by teachers, for teachers! Find a topic for your students, and assign  some adaptive practice."
                    />
                  ) : (
                    <DetailEmptyView
                      title="Select a topic to start exploring"
                      actionDescription="browse, create tasks and templates"
                    />
                  )
                }
              />
              <Route
                path={contentBrowsingChapterIntroViewPath}
                exact
                render={({ match: { params } }) => (
                  <ChapterIntroView syllabusId={params.syllabusId} />
                )}
              />

              <Route
                path={contentBrowsingMaterialsGuidePath}
                exact
                render={({ match: { params } }) =>
                  primaryTeacherNotes ? (
                    <MaterialsGuideView syllabusId={params.syllabusId} />
                  ) : (
                    <HardRedirect to={paywallUrl} />
                  )
                }
              />

              <Route
                path={contentBrowsingTopicOverviewPath}
                exact
                render={({ match: { params } }) =>
                  !classicUi ? (
                    <HardRedirect to={paywallUrl} />
                  ) : (
                    <TopicOverview topicId={params.topicId} />
                  )
                }
              />
              <Route
                path={contentBrowsingSubtopicRedirectorPath}
                exact
                render={({ match: { params } }) => (
                  <SubtopicRedirector
                    syllabusId={params.syllabusId}
                    topicId={params.topicId}
                  />
                )}
              />

              <Route
                path={contentBrowsingSubtopicDetailViewPath}
                render={({ match: { params } }) =>
                  !classicUi ? (
                    <SunflowerSubtopicDetailView
                      subtopicId={params.subtopicId}
                    />
                  ) : (
                    <TeacherSubtopicDetailView subtopicId={params.subtopicId} />
                  )
                }
              />
            </Switch>
          </TeacherTextbookTypesLayout>
        </Route>
      </Switch>
    </>
  );
}
