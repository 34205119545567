import moment from 'moment';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

export type TaskName = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;
export type TargetMastery = number;
export type HelpOptions = boolean;
export type AssignedCheckinSkillIds = Array<string>;

export type State = {
  taskName: TaskName;
  edited: boolean;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  targetMastery: TargetMastery;
  subtopicId: string | null | undefined;
  startsWithLesson: boolean;
  hideHelpOptions: HelpOptions;
  selectedClasses: ReadonlyArray<ClassSelectionPayload>;
  selectedStudents: ReadonlyArray<StudentSelectionPayload>;
  excludedStudents: ReadonlyArray<StudentSelectionPayload>;
  disableCalculator: boolean;
  assignedCheckinSkillIds: AssignedCheckinSkillIds;
};

export type Updater<I> = (value: I) => (state: State) => State;

export const initialState = (): State => ({
  taskName: '',
  edited: false,
  subtopicId: null,
  taskStartDate: moment().seconds(0).milliseconds(0).toDate(),
  taskDueDate: moment()
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .add(7, 'days')
    .toDate(),
  targetMastery: 100,
  hideHelpOptions: true,
  selectedClasses: [],
  selectedStudents: [],
  excludedStudents: [],
  startsWithLesson: false,
  disableCalculator: false,
  assignedCheckinSkillIds: [],
});
