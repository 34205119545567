import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M9 0C6.81196 0 4.71354 0.869194 3.16637 2.41637C1.61919 3.96354 0.75 6.06196 0.75 8.25C0.75 12.6075 8.1 21.825 8.415 22.2225C8.48528 22.3101 8.57433 22.3808 8.67558 22.4294C8.77683 22.4779 8.8877 22.5032 9 22.5032C9.1123 22.5032 9.22317 22.4779 9.32442 22.4294C9.42567 22.3808 9.51472 22.3101 9.585 22.2225C9.9 21.825 17.25 12.6075 17.25 8.25C17.25 6.06196 16.3808 3.96354 14.8336 2.41637C13.2865 0.869194 11.188 0 9 0ZM9 10.5C8.40666 10.5 7.82664 10.3241 7.33329 9.99441C6.83994 9.66476 6.45542 9.19623 6.22836 8.64805C6.0013 8.09987 5.94189 7.49667 6.05764 6.91473C6.1734 6.33279 6.45912 5.79824 6.87868 5.37868C7.29824 4.95912 7.83279 4.6734 8.41473 4.55764C8.99667 4.44189 9.59987 4.5013 10.1481 4.72836C10.6962 4.95542 11.1648 5.33994 11.4944 5.83329C11.8241 6.32664 12 6.90666 12 7.5C12 8.29565 11.6839 9.05871 11.1213 9.62132C10.5587 10.1839 9.79565 10.5 9 10.5Z" />
);

const Location = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={18}
    viewBoxHeight={23}
  >
    {path}
  </Icon>
);

Location.displayName = 'Location';

export default Location;
