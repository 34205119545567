import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  createNewAdaptiveExperienceTaskMutationVariables,
  createNewAdaptiveExperienceTaskMutationResponse,
} from './__generated__/createNewAdaptiveExperienceTaskMutation.graphql';

export type { createNewAdaptiveExperienceTaskMutationVariables };
export const mutation = graphql`
  mutation createNewAdaptiveExperienceTaskMutation(
    $title: String!
    $subtopicId: ID!
    $targetMastery: Int
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $dueDate: DateTime!
    $startDate: DateTime!
    $allowCalculator: Boolean!
    $assignedCheckinSkillIds: [ID!]!
  ) {
    createNewAdaptiveExperienceTask(
      title: $title
      assignedClassIds: $assignedClassIds
      targetMastery: $targetMastery
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      dueDate: $dueDate
      startDate: $startDate
      subtopicId: $subtopicId
      allowCalculator: $allowCalculator
      assignedCheckinSkillIds: $assignedCheckinSkillIds
    ) {
      errors {
        message
      }
      task {
        id
      }
    }
  }
`;
export default function useCreateNewAdaptiveExperienceTask(
  variables: createNewAdaptiveExperienceTaskMutationVariables,
): UseMutationResponse<createNewAdaptiveExperienceTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
