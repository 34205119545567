import { Redirect } from 'react-router-dom';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useSchoolContext from 'ms-pages/Teacher/TeacherContext/SchoolContext';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import {
  useRecentlyUsedTextbooksForUser,
  setRecentlyUsedTextbooksForUser,
} from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import { getContentBrowsingEmptyViewUrl } from 'ms-utils/urls';

type RedirectorProps = { textbookId: string };

export function RedirectToTextbook({ textbookId }: RedirectorProps) {
  const { userId } = useViewer();

  setRecentlyUsedTextbooksForUser(userId, textbookId);

  return (
    <Redirect to={getContentBrowsingEmptyViewUrl({ syllabusId: textbookId })} />
  );
}

export function RedirectToFocusTextbookForTeacher() {
  const syllabusId = useFocusTextbookForTeacher();
  if (syllabusId == null) return null;
  return <RedirectToTextbook textbookId={syllabusId} />;
}

export default function RedirectToLastUsedTextbook() {
  // This is some temporary logic to run an experiment defaulting
  // skills book users to the skills book tab. This will be removed
  // once the experiment is over.
  const {
    skillsBook: { shouldDefaultToSkillsTab, id: skillsBookId },
  } = useViewer();

  const textbookId = useDefaultTeacherTextbook();

  return (
    <RedirectToTextbook
      textbookId={
        shouldDefaultToSkillsTab && skillsBookId !== undefined
          ? skillsBookId
          : textbookId
      }
    />
  );
}

export function useFocusTextbookForTeacher() {
  const { syllabusFocusId } = useViewer();
  const { classId } = useMaybeTeacherContext();
  const { schoolClasses } = useSchoolContext();
  const maybeClass = schoolClasses && schoolClasses.find(c => c.id === classId);

  // For teachers with class context, use the class' textbook focus. Else,
  // default to their profile's textbook focus.
  const defaultSyllabus =
    maybeClass != null ? maybeClass.classSyllabusFocus.id : syllabusFocusId;

  return defaultSyllabus;
}

export function useDefaultTeacherTextbook() {
  const { userId } = useViewer();
  const lastSelectedTextbookId = useRecentlyUsedTextbooksForUser(userId)[0];
  const focusTextbookId = useFocusTextbookForTeacher();
  return lastSelectedTextbookId || focusTextbookId;
}
