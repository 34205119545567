import { useContext, useEffect, useState, type ReactNode } from 'react';

import { useSnowplow } from 'ms-helpers/Snowplow';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { HSpacer, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import { getTeacherDownloadCSVUrlUsingClass } from 'ms-pages/Lantern/utils/urls';
import { useTeacherContext } from 'ms-pages/Lantern/views/Teacher/TeacherContext';
import useMathspaceTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import { StateContext } from 'ms-pages/Teacher/state';
import { fontWeight } from 'ms-styles/base';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';

import { Overview, OverviewMain, Root } from './styles';

type Props = {
  title: string;
  children?: ReactNode | undefined;
  infoIcon?: ReactNode | undefined;
};

export function TeacherReportHeader({ title, infoIcon, children }: Props) {
  return (
    <Root>
      <HeadingL>
        {title}
        {infoIcon != null && (
          <>
            <HSpacer width={8} />
            {infoIcon}
          </>
        )}
      </HeadingL>
      {children != null && (
        <>
          <VSpacer height={8} />
          <Overview>
            {children != null && <OverviewMain>{children}</OverviewMain>}
          </Overview>
        </>
      )}
    </Root>
  );
}

const buttonStyleProps = {
  type: 'secondary',
  color: 'grey',
  isRound: true,
  height: 32,
  padding: 16,
  fontWeight: fontWeight.normal,
} as const;

export function ExportToCSVButton() {
  const { trackStructEvent } = useSnowplow();

  const {
    lanternCurriculum: { id: curriculumId },
    subscription,
  } = useTeacherContext();
  const { classId } = useMathspaceTeacherContext();
  const { growthPeriod } = useContext(StateContext);
  const [
    { canPreviewProblemEventDataInSkills, hasPreviewProblemEventDataInSkills },
  ] = useFeatureFlagsV2();
  const [initiatedDownload, setInitiatedDownload] = useState(false);
  useEffect(() => {
    if (initiatedDownload) {
      const timer = setTimeout(() => setInitiatedDownload(false), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return;
  }, [initiatedDownload]);

  const url = getTeacherDownloadCSVUrlUsingClass({
    curriculumId,
    classId,
    previewingWithProblemData:
      canPreviewProblemEventDataInSkills && hasPreviewProblemEventDataInSkills,
    growthPeriod,
  });

  return (
    <AnchorButton
      {...buttonStyleProps}
      isDisabled={initiatedDownload}
      href={url}
      onClick={() => {
        trackStructEvent({
          category: 'teacher_outcomes',
          action: 'clicked_download_csv',
          property: subscription,
        });
        setInitiatedDownload(true);
      }}
    >
      Export to CSV
    </AnchorButton>
  );
}
