import { css } from '@emotion/css';

import { fontFamily } from 'ms-styles/base';
import { colors, lanternColors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HStack } from 'ms-ui-primitives/Stack';

import pictureRevealThumbnail from './picture-reveal-thumbnail.png';
import tugOfWarThumbnail from './tug-of-war-thumbnail.png';
import type { GameModalContent as TGameModalContent } from '../GameModal';
import { GameModalContent, GameModalTitle } from '../GameModal/primitives';

export function GameSelector({
  onContinue,
}: {
  onContinue: (newContent: TGameModalContent) => void;
}) {
  return (
    <GameModalContent>
      <GameModalTitle>Select a game</GameModalTitle>
      <div className={styles.gameCardDeck}>
        <div className={styles.gameCard}>
          <div className={styles.gameCardBody}>
            <div className={styles.gameCardImageWrapper}>
              <img
                className={styles.gameCardImage}
                src={tugOfWarThumbnail}
                alt=""
              />
            </div>
            <h3 className={styles.gameCardTitle}>Tug of War</h3>
            <p>
              Work in teams to overwhelm the opponent with your skills to win
              the war!
            </p>
          </div>
          <div>
            <Button
              type="primary"
              isRound
              styles={styles.stretchedButton}
              onClick={() => onContinue('TugOfWarInstructions')}
            >
              Continue
            </Button>
          </div>
        </div>
        <div className={styles.gameCard}>
          <div className={styles.gameCardBody}>
            <div className={styles.gameCardImageWrapper}>
              <img
                className={styles.gameCardImage}
                src={pictureRevealThumbnail}
                alt=""
              />
            </div>
            <HStack center>
              <h3
                className={styles.gameCardTitle}
                style={{ color: lanternColors.grey10 }}
              >
                Picture Reveal
              </h3>
              <span
                className={styles.gameCardBadge}
                style={{ marginLeft: 'auto' }}
              >
                Coming soon
              </span>
            </HStack>
            <p>
              Work together as a class to reveal the picture and unlock rewards.
            </p>
          </div>
          <div>
            <Button
              type="primary"
              isDisabled
              isRound
              styles={styles.stretchedButton}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </GameModalContent>
  );
}

const styles = {
  gameCardDeck: css({
    display: 'flex',
    gap: 16,
  }),
  gameCard: css({
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'column',
    gap: 16,
    position: 'relative',
  }),
  gameCardBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }),
  gameCardImageWrapper: css({
    aspectRatio: '277 / 96',
    backgroundColor: colors.ironLight,
    borderRadius: 8,
    boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
    overflow: 'hidden',
    position: 'relative',

    '::after': {
      border: '1px solid rgb(0 0 0 / 10%)',
      borderRadius: 'inherit',
      content: '""',
      inset: 0,
      position: 'absolute',
    },
  }),
  gameCardImage: css({
    height: 'auto',
    objectFit: 'cover',
    width: '100%',
  }),
  gameCardTitle: css({
    color: colors.grey,
    fontFamily: fontFamily.heading,
    fontSize: 15,
    fontWeight: 800,
    lineHeight: 1.6,
  }),
  gameCardBadge: css({
    backgroundColor: colors.serenade,
    borderRadius: 9999,
    color: colors.crusta,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1,
    padding: '4px 8px',
  }),
  stretchedButton: {
    '::after': {
      content: '""',
      inset: 0,
      position: 'absolute',
      zIndex: 1,
    },
  },
} as const;
