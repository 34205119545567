/*
 * Flyout menu is designed to be used similar to a modal. Uses react portals to
 * render on any page. Teacher entrypoint in django has markup that
 * enables it
 */

import { StyleSheet } from 'aphrodite';
import {
  createContext,
  useState,
  useEffect,
  Fragment,
  type ReactNode,
} from 'react';

import Portal from 'ms-helpers/Portal';
import { TRANSITION_DURATION } from 'ms-pages/Teacher/components/Flyout/constants';
import { useTransitionStateMachine } from 'ms-pages/Teacher/components/Flyout/hooks';
import { styled } from 'ms-utils/emotion';

const Overlay = styled({
  default: {
    position: 'absolute',
    transition: `opacity linear ${TRANSITION_DURATION}ms`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    backgroundColor: '#000000',
  },
  isOpen: {
    opacity: 0.3,
  },
});

export const FlyoutBackground = styled({
  default: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    bottom: 0,
    transition: `width ${TRANSITION_DURATION}ms ease-out ,  right ${TRANSITION_DURATION}ms ease-out, left ${TRANSITION_DURATION}ms ease-out`,
    boxShadow: '-22px 0 30px rgba(0,0,0,0.1)',
    maxWidth: '100%',
  },
});

const styles = StyleSheet.create({
  portal: {
    position: 'fixed',
    overflow: 'hidden',
    top: 0,
    bottom: 0,
    width: '100%',
  },
});

type Side = 'left' | 'right';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  initialFlyoutWidth?: number | undefined;
  transparent?: boolean | undefined;
  children: ReactNode;
  side?: Side | undefined;
};

type FlyoutContextType = {
  initialFlyoutWidth: number;
  currentFlyoutWidth: number;
  changeFlyoutWidth: (width: number) => void;
  setFlyoutTranslation: (translation: number) => void;
  closeFlyout: () => void;
};

const initialContext: FlyoutContextType = {
  initialFlyoutWidth: 0,
  currentFlyoutWidth: 0,
  changeFlyoutWidth: () => undefined,
  setFlyoutTranslation: () => {},
  closeFlyout: () => undefined,
};

export const FlyoutContext = createContext<FlyoutContextType>(initialContext);

export default function Flyout({
  children,
  initialFlyoutWidth = 400,
  isOpen,
  onClose,
  side = 'right',
}: Props) {
  const [currentFlyoutWidth, changeFlyoutWidth] = useState(initialFlyoutWidth);
  const [currentTranslation, setFlyoutTranslation] = useState(0);

  const [transition, setTransition] = useTransitionStateMachine(
    isOpen ? 'open' : 'closed',
  );

  // Make possible to update flyout width via props
  useEffect(() => {
    changeFlyoutWidth(initialFlyoutWidth);
  }, [initialFlyoutWidth]);

  useEffect(() => {
    if (isOpen === false) {
      setTransition(t => (t !== 'closed' ? 'closing' : t));
    } else {
      setTransition(t => (t !== 'open' ? 'opening' : t));
    }
  }, [isOpen, setTransition]);

  // there is little overhead in keeping this portal open by default
  // in order to make sure that first open actions are instantaneous.
  return (
    <Portal
      isOpen={transition !== 'closed'}
      portalStyles={[styles.portal]}
      hasScrollPrevention
    >
      <FlyoutContext.Provider
        value={{
          initialFlyoutWidth,
          currentFlyoutWidth,
          changeFlyoutWidth,
          setFlyoutTranslation,
          closeFlyout: onClose,
        }}
      >
        {transition !== 'closed' && (
          <Fragment>
            <Overlay onClick={onClose} isOpen={transition === 'open'} />
            <FlyoutBackground
              style={{
                width: currentFlyoutWidth,
                [side]:
                  currentTranslation -
                  (transition === 'open' ? 0 : currentFlyoutWidth),
              }}
              translated={currentTranslation !== 0}
            >
              {children}
            </FlyoutBackground>
          </Fragment>
        )}
      </FlyoutContext.Provider>
    </Portal>
  );
}
