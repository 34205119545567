import {
  Card,
  CardContent,
  CardEmptyText,
  CardEmptyTitle,
} from 'ms-pages/Teacher/components/Card';

type PossibleCause = 'SLOW_FILTER';

type ContentProps = {
  title: string;
  description: string;
};

type Props = {
  height?: number;
  maxWidth?: number;
  possibleCause?: PossibleCause;
};

const getContent = (cause: PossibleCause | null | undefined): ContentProps => {
  switch (cause) {
    case 'SLOW_FILTER':
      return {
        title: 'The data failed to load',
        description: 'Narrow down your search or try refreshing the page',
      };
    default:
      return {
        title: 'Sorry, something went wrong',
        description:
          'Refreshing the page might help. If this issue persists, please contact support.',
      };
  }
};

export default function ErrorCard({
  height = 240,
  maxWidth = 360,
  possibleCause,
}: Props) {
  const { title, description } = getContent(possibleCause);
  return (
    <Card height={height}>
      <CardContent hasVerticalPadding>
        <CardEmptyText maxWidth={maxWidth}>
          <CardEmptyTitle>{title}</CardEmptyTitle>
          <div>{description}</div>
        </CardEmptyText>
      </CardContent>
    </Card>
  );
}
