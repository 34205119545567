import { StyleSheet } from 'aphrodite';

import { fontFamily, fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

const PAGE_PADDING = 5 * BASE_UNIT;
const FORM_MAX_WIDTH = 600;
const TITLE_TOP_MARGIN = 12 * BASE_UNIT;
const TITLE_BOTTOM_MARGIN = 10 * BASE_UNIT;
const FORM_DESCRIPTION_V_MARGIN = 7 * BASE_UNIT;

const styles = StyleSheet.create({
  root: {
    minHeight: '100%',
    padding: PAGE_PADDING,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    lineHeight: lineHeight.body,
    color: colors.mako,
  },
  formWrapper: {
    maxWidth: FORM_MAX_WIDTH,
    margin: '0 auto',
  },
  title: {
    fontSize: fontSize.sectionTitle,
    fontWeight: fontWeight.semibold,
    color: colors.cloudBurst,
    lineHeight: lineHeight.heading,
    marginTop: TITLE_TOP_MARGIN,
    marginBottom: TITLE_BOTTOM_MARGIN,
  },
  errorsTitle: {
    fontWeight: fontWeight.semibold,
    fontSize: fontSize.medium,
  },
  validationErrorMessages: {
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    marginBottom: FORM_DESCRIPTION_V_MARGIN,
    marginTop: FORM_DESCRIPTION_V_MARGIN,
  },
});

export default styles;
