import type { Preselection } from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import { CreateTaskStateProvider } from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import type { CreateTaskModalContentInnerProps } from 'ms-pages/Teacher/components/CreateTaskModal/CreateTaskModal';
import { CreateTaskModalContentInner } from 'ms-pages/Teacher/components/CreateTaskModal/CreateTaskModal';

type Props = CreateTaskModalContentInnerProps & {
  preselection?: Preselection | null | undefined;
};

export default function CreateTaskModalContent({
  preselection,
  ...props
}: Props) {
  return (
    <CreateTaskStateProvider preselection={preselection}>
      <CreateTaskModalContentInner preselection={preselection} {...props} />
    </CreateTaskStateProvider>
  );
}
