import { borderRadiusUILarge } from 'ms-styles/base';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

export const PROBLEM_ITEM_DEFAULT_BOTTOM_MARGIN = BASE_UNIT * 4;
export const PROBLEM_ITEM_DEFAULT_BORDER_RADIUS = borderRadiusUILarge;

export const PROBLEM_PADDING = 4 * BASE_UNIT;
export const EXPAND_BUTTON_WIDTH = 20;
export const EXPAND_BUTTON_SPACER_WIDTH = 16;

export const ATTRIBUTES_WRAPPER_PADDING_HORIZONTAL = 4 * BASE_UNIT;
export const ATTRIBUTES_WRAPPER_PADDING_VERTICAL = 3 * BASE_UNIT;
export const BORDER_WIDTH = 1;
