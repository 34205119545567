/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type SubtopicRecommendationCard_substrandSubtopicRecommendation = {
    readonly subtopic: {
        readonly id: string;
        readonly title: string;
        readonly topic: {
            readonly id: string;
            readonly badgeBackground: string | null;
            readonly badgeUrl: string;
            readonly syllabus: {
                readonly id: string;
            };
        };
        readonly hasAdaptivePractice: boolean;
        readonly gamifiedMastery: number;
    };
    readonly category: SubstrandSubtopicRecommendationCategory;
    readonly outcomes: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
    }>;
    readonly " $refType": "SubtopicRecommendationCard_substrandSubtopicRecommendation";
};
export type SubtopicRecommendationCard_substrandSubtopicRecommendation$data = SubtopicRecommendationCard_substrandSubtopicRecommendation;
export type SubtopicRecommendationCard_substrandSubtopicRecommendation$key = {
    readonly " $data"?: SubtopicRecommendationCard_substrandSubtopicRecommendation$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubtopicRecommendationCard_substrandSubtopicRecommendation">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubtopicRecommendationCard_substrandSubtopicRecommendation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtopic",
      "kind": "LinkedField",
      "name": "subtopic",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topic",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "badgeBackground",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "badgeUrl",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Syllabus",
              "kind": "LinkedField",
              "name": "syllabus",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasAdaptivePractice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gamifiedMastery",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "category",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternOutcome",
      "kind": "LinkedField",
      "name": "outcomes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SubstrandSubtopicRecommendation",
  "abstractKey": null
};
})();
(node as any).hash = '46929c1af90b07b18423eacf0529f358';
export default node;
