/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LeaderboardModal_student = {
    readonly id: string;
    readonly leaderboard?: {
        readonly currentWeek: ReadonlyArray<{
            readonly points: number;
            readonly rank: number;
            readonly name: string;
            readonly avatarUrl: string;
            readonly studentId: string;
        }>;
        readonly previousWeek: ReadonlyArray<{
            readonly points: number;
            readonly rank: number;
            readonly name: string;
            readonly avatarUrl: string;
            readonly studentId: string;
        }>;
    } | null | undefined;
    readonly user: {
        readonly avatar: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly points: {
            readonly current: number;
        } | null;
    };
    readonly classes: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly hasEnabledLeaderboard: boolean;
            };
        }>;
    };
    readonly leaderboardClass: {
        readonly class: {
            readonly title: string;
            readonly displayName: string | null;
        };
    } | null;
    readonly " $refType": "LeaderboardModal_student";
};
export type LeaderboardModal_student$data = LeaderboardModal_student;
export type LeaderboardModal_student$key = {
    readonly " $data"?: LeaderboardModal_student$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LeaderboardModal_student">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "classId",
    "variableName": "classId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "points",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rank",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "avatarUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studentId",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "classId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchLeaderboard"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "numberOfClasses"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "LeaderboardModal_student",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PointsObject",
          "kind": "LinkedField",
          "name": "points",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "current",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "numberOfClasses"
        }
      ],
      "concreteType": "ClassConnection",
      "kind": "LinkedField",
      "name": "classes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClassEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Class",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasEnabledLeaderboard",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LeaderboardClass",
      "kind": "LinkedField",
      "name": "leaderboardClass",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Class",
          "kind": "LinkedField",
          "name": "class",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchLeaderboard",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Leaderboard",
          "kind": "LinkedField",
          "name": "leaderboard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "CurrentWeekLeaderboardUser",
              "kind": "LinkedField",
              "name": "currentWeek",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "LeaderboardUser",
              "kind": "LinkedField",
              "name": "previousWeek",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Student",
  "abstractKey": null
};
})();
(node as any).hash = '45abc8af5d3a1b170ee63fc319419854';
export default node;
