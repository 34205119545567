import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const TemplateDuplicate = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      d="M14.769 12.975a.777.777 0 0 0 .776-.777V1a1 1 0 0 0-1-1h-9.82a.816.816 0 0 0 0 1.632h8.267a1 1 0 0 1 1 1v9.566c0 .429.347.777.777.777ZM1 15a1 1 0 0 0 1 1h9.636a1 1 0 0 0 1-1V4.025a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1V15Zm6.843-6.343h1.975a.894.894 0 0 1 0 1.786H7.843v1.975a.894.894 0 0 1-1.786 0v-1.975H4.082a.894.894 0 0 1 0-1.786h1.975V6.682a.894.894 0 0 1 1.786 0v1.975Z"
      clipRule="evenodd"
    />
  </Icon>
);

TemplateDuplicate.displayName = 'TemplateDuplicate';

export default TemplateDuplicate;
