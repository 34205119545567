/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type QuestionAnswerResult = "CORRECT" | "INCORRECT" | "SKIPPED";
export type CheckInSessionQuestionResult = {
    readonly id: string;
    readonly result: QuestionAnswerResult;
    readonly checkIn: {
        readonly id: string;
        readonly currentQuestion: {
            readonly " $fragmentRefs": FragmentRefs<"CheckInSessionQuestion">;
        } | null;
        readonly questionsCompleted: number;
        readonly endedAt: string | null;
        readonly student: {
            readonly totalCheckInsCompleted: number;
            readonly totalQuestionsCompleted: number;
        };
    } | null;
    readonly " $refType": "CheckInSessionQuestionResult";
};
export type CheckInSessionQuestionResult$data = CheckInSessionQuestionResult;
export type CheckInSessionQuestionResult$key = {
    readonly " $data"?: CheckInSessionQuestionResult$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInSessionQuestionResult">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckInSessionQuestionResult",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "checkIn",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "currentQuestion",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CheckInSessionQuestion"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionsCompleted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternStudent",
          "kind": "LinkedField",
          "name": "student",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCheckInsCompleted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalQuestionsCompleted",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionAnswerInterface",
  "abstractKey": "__isQuestionAnswerInterface"
};
})();
(node as any).hash = '95ade6ddc5c5f2dc9fd525aae44bba68';
export default node;
