import { BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import Stack from 'ms-ui-primitives/Stack';

export default function ConfirmSaveChangesPrompt({
  isOpen,
  templateAuthorName,
  onClose,
  action,
}: {
  isOpen: boolean;
  templateAuthorName?: string | undefined;
  onClose: () => void;
  action: () => void;
}) {
  return (
    <Modal
      closeOnOverlayTap={false}
      width={480}
      isOpen={isOpen}
      onClose={onClose}
      isOverflowHidden
    >
      <div css={{ padding: 32, paddingTop: 40, display: 'flex' }}>
        <Stack.V width="100%">
          <HeadingM>Save changes?</HeadingM>
          <Stack.Spacer.V height={8} />
          <BodyM>
            Your changes will override the existing template
            {templateAuthorName != null
              ? ` created by ${templateAuthorName}`
              : ''}
          </BodyM>

          <Stack.Spacer.V height={24} />
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="lanternSmall" onClick={onClose}>
              No, cancel
            </Button>
            <Button
              size="lanternSmall"
              isRound
              type="primary"
              onClick={() => {
                onClose();
                action();
              }}
            >
              Yes, save
            </Button>
          </div>
        </Stack.V>
      </div>
    </Modal>
  );
}
