import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M3.72319372,10.6973068 C3.33310897,11.0873915 2.70065663,11.0873915 2.31057188,10.6973068 C1.92048714,10.307222 1.92048714,9.67476967 2.31057188,9.28468492 L9.30269324,2.29256356 C9.69277799,1.90247881 10.3252303,1.90247881 10.7153151,2.29256356 C11.1053998,2.68264831 11.1053998,3.31510065 10.7153151,3.7051854 L3.72319372,10.6973068 Z M3.0168828,8.99212136 L17.0011255,8.99212136 C18.3329582,8.99212136 18.3329582,10.9898703 17.0011255,10.9898703 L3.0168828,10.9898703 C1.68505016,10.9898703 1.68505016,8.99212136 3.0168828,8.99212136 Z M3.72319372,9.28468492 L10.7153151,16.2768063 C11.657063,17.2185542 10.2444411,18.631176 9.30269324,17.6894281 L2.31057188,10.6973068 C1.36882399,9.75555887 2.78144583,8.34293703 3.72319372,9.28468492 Z"
    transform="translate(10.000000, 10.000000) rotate(-90.000000) translate(-10.000000, -10.000000)"
  />
);

const ArrowDown = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ArrowDown.displayName = 'ArrowDown';

export default ArrowDown;
