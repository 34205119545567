export function VSpacer({
  height,
  grow = false,
}: {
  height: number | string;
  grow?: boolean;
}) {
  return (
    <div css={{ height, flexShrink: 0, ...(grow ? { flexGrow: 1 } : {}) }} />
  );
}

export function HSpacer({
  width,
  grow = false,
}: {
  width: number | string;
  grow?: boolean;
}) {
  return (
    <div
      css={{
        width,
        flexShrink: 0,
        display: 'inline-block',
        ...(grow ? { flexGrow: 1 } : {}),
      }}
    />
  );
}

export function HVSpacer({
  width,
  height,
  grow = false,
}: {
  width: number | string;
  height: number | string;
  grow?: boolean;
}) {
  return (
    <div
      css={{
        width,
        height,
        flexShrink: 0,
        display: 'inline-block',
        ...(grow ? { flexGrow: 1 } : {}),
      }}
    />
  );
}
