/**
 * Provides some basic relational operations for working with version strings.
 */
import { reduce, zip } from 'ramda';

// Precondition: Version strings must be of the form '<major>.<minor>.<path>'
const versionStringComparator = (v1: string, v2: string) => {
  const triple1 = v1.split('.').map(Number);
  const triple2 = v2.split('.').map(Number);

  return reduce(
    (result, [a, b]) => {
      if (result !== 0) return result;
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    },
    0,
    zip(triple1, triple2),
  );
};

// A version string must be of the form '<major>.<minor>.<path>'
type Version = string;
type RelationalOperator = (v1: Version, v2: Version) => boolean;

export const lessThan: RelationalOperator = (v1, v2) =>
  versionStringComparator(v1, v2) === -1;

export const lessThanOrEqual: RelationalOperator = (v1, v2) =>
  versionStringComparator(v1, v2) !== 1;

export const equal: RelationalOperator = (v1, v2) =>
  versionStringComparator(v1, v2) === 0;

export const greaterThan: RelationalOperator = (v1, v2) =>
  versionStringComparator(v1, v2) === 1;

export const greaterThanOrEqual: RelationalOperator = (v1, v2) =>
  versionStringComparator(v1, v2) !== -1;
