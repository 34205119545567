import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT, boxShadow } from 'ms-styles/theme/Numero';
import { styled, tappable, onPress } from 'ms-utils/emotion';

import {
  STICKY_WRAPPER_HEIGHT,
  SECONDARY_STICKY_WRAPPER_HEIGHT,
} from '../DetailHeader';

const HEADER_HEIGHT = 15 * BASE_UNIT;
const INDICATOR_COUNTER_HEIGHT = 30;
const ROOT_BORDER_WIDTH = 1;

export const Header = styled({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Title = styled({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semibold,
  marginRight: 2 * BASE_UNIT,
});
const CounterContainer = styled({
  default: {
    position: 'absolute',
    right: `calc(100% + ${ROOT_BORDER_WIDTH}px)`,
    width: 'max-content',
    height: INDICATOR_COUNTER_HEIGHT,
    borderRadius: `${INDICATOR_COUNTER_HEIGHT}px 0 0 ${INDICATOR_COUNTER_HEIGHT}px`,
    background: colors.white,
    top: HEADER_HEIGHT - INDICATOR_COUNTER_HEIGHT / 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.3s transform',
    color: colors.white,
    backgroundColor: colors.grey,
    boxShadow: boxShadow.regular,
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    padding: `0 ${BASE_UNIT * 4}px`,
    ...tappable,
    ...onPress({
      transform: 'scale(.9)',
    }),
    zIndex: 3, // must beat sticky textbook header local_modules/ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader.tsx
  },
  isGreyedOut: {
    backgroundColor: colors.grey10,
  },
});

export function QuestionsCounter({
  open,
  onClick,
  length,
}: {
  open: boolean;
  onClick: () => void;
  length: number;
}) {
  return (
    <CounterContainer
      onClick={onClick}
      isGreyedOut={length === 0 && !open}
      style={{
        top: STICKY_WRAPPER_HEIGHT + SECONDARY_STICKY_WRAPPER_HEIGHT + 8,
      }}
    >
      {open
        ? 'Hide questions'
        : `${length} ${length === 1 ? 'question' : 'questions'}`}
    </CounterContainer>
  );
}
