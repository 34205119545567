import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

import { HEADER_NUMBER_SIZE } from '../layout';

const SIZE = HEADER_NUMBER_SIZE;
const SIZE_SUBPROBLEM = HEADER_NUMBER_SIZE - BASE_UNIT;
const FONT_SIZE = 16;
const FONT_SIZE_SUBPROBLEM = 14;

const styles = StyleSheet.create({
  numberHeader: {
    display: 'flex',
    flexShrink: 0,
    width: SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: FONT_SIZE,
    fontWeight: fontWeight.semibold,
  },
  subproblemCircle: {
    height: SIZE_SUBPROBLEM,
    width: SIZE_SUBPROBLEM,
    backgroundColor: colors.mako,
    color: colors.white,
    borderRadius: '50%',
  },
  numberHeaderDeemphasized: {
    backgroundColor: colors.iron,
    color: colors.santasGray,
    fontSize: FONT_SIZE_SUBPROBLEM,
  },
});

type Props = {
  children: ReactNode;
  subproblem?: boolean | undefined;
  greyed?: boolean | undefined;
};

const NumberHeader = ({
  children,
  greyed = false,
  subproblem = false,
}: Props) => (
  <div
    className={css(
      styles.numberHeader,
      subproblem && styles.subproblemCircle,
      greyed && styles.numberHeaderDeemphasized,
    )}
  >
    {children}
    {!subproblem && '.'}
  </div>
);

export default NumberHeader;
