/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DemoStudentDetailsQueryVariables = {};
export type DemoStudentDetailsQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly lanternStudent?: {
                readonly selfReportedGrade: {
                    readonly id: string;
                    readonly shortTitle: string;
                    readonly curriculum: {
                        readonly id: string;
                        readonly title: string;
                    };
                };
                readonly checkIns: ReadonlyArray<{
                    readonly id: string;
                    readonly strand: {
                        readonly title: string;
                    } | null;
                }>;
            } | undefined;
        };
    };
};
export type DemoStudentDetailsQuery = {
    readonly response: DemoStudentDetailsQueryResponse;
    readonly variables: DemoStudentDetailsQueryVariables;
};



/*
query DemoStudentDetailsQuery {
  viewer {
    profile {
      __typename
      ... on Student {
        lanternStudent {
          selfReportedGrade {
            id
            shortTitle
            curriculum {
              id
              title
            }
          }
          checkIns {
            id
            strand {
              title
              id
            }
          }
          id
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "selfReportedGrade",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LanternCurriculum",
      "kind": "LinkedField",
      "name": "curriculum",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DemoStudentDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternStudent",
                    "kind": "LinkedField",
                    "name": "lanternStudent",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "checkIns",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Strand",
                            "kind": "LinkedField",
                            "name": "strand",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DemoStudentDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternStudent",
                    "kind": "LinkedField",
                    "name": "lanternStudent",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "checkIns",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Strand",
                            "kind": "LinkedField",
                            "name": "strand",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "875786929c0b08276763c31d78106250",
    "id": null,
    "metadata": {},
    "name": "DemoStudentDetailsQuery",
    "operationKind": "query",
    "text": "query DemoStudentDetailsQuery {\n  viewer {\n    profile {\n      __typename\n      ... on Student {\n        lanternStudent {\n          selfReportedGrade {\n            id\n            shortTitle\n            curriculum {\n              id\n              title\n            }\n          }\n          checkIns {\n            id\n            strand {\n              title\n              id\n            }\n          }\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a0beaa7386497f9fac995c6f29058e6c';
export default node;
