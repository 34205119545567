import ChevronLeft from 'ms-components/icons/ChevronLeft';
import ChevronRight from 'ms-components/icons/ChevronRight';
import type { SubtopicProblemsSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector/SubtopicProblems';
import { fontFamily, fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

const Space = styled({
  height: 2 * BASE_UNIT,
  width: 2 * BASE_UNIT,
});
const SubtopicControlArea = styled({
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
});

export const SubtopicTitle = styled({
  lineHeight: lineHeight.title,
  fontWeight: fontWeight.semibold,
});

const ChevronWrapper = styled({
  paddingLeft: 3 * BASE_UNIT,
  paddingRight: 3 * BASE_UNIT,
  paddingBottom: BASE_UNIT,
});

export function SubtopicHeader({
  totalCount,
  subtopic,
  index,
  onBack,
  onNext,
}: {
  subtopic: NonNullable<SubtopicProblemsSubtopic>;
  index: number;
  totalCount: number;
  onBack: () => void;
  onNext: () => void;
}) {
  return (
    <SubtopicControlArea>
      <SubtopicTitle
        data-test-id="title"
        style={{
          fontFamily: fontFamily.heading,
          fontSize: fontSize.large,
          color: colors.neutralGray,
        }}
      >
        {subtopic.title}{' '}
        {totalCount > 1 ? (
          <span data-test-id="index-indicator">
            ({index + 1}/{totalCount})
          </span>
        ) : null}
      </SubtopicTitle>
      {totalCount > 1 && (
        <div style={{ display: 'flex' }}>
          <Button
            data-test-id="back-button"
            onClick={onBack}
            isDisabled={index === 0}
            type="secondary"
            padding={0}
            label="Previous subtopic"
          >
            <ChevronWrapper>
              <ChevronLeft />
            </ChevronWrapper>
          </Button>
          <Space />
          <Button
            data-test-id="next-button"
            isDisabled={index === totalCount - 1}
            onClick={onNext}
            padding={0}
            type="secondary"
            label="Next subtopic"
          >
            <ChevronWrapper>
              <ChevronRight />
            </ChevronWrapper>
          </Button>
        </div>
      )}
    </SubtopicControlArea>
  );
}
