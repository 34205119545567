/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditTaskFlyout_assignments = {
    readonly assignedStudents: ReadonlyArray<{
        readonly id: string;
        readonly user: {
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
        };
    }>;
    readonly assignedClasses: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly displayName: string | null;
        readonly students: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly user: {
                        readonly id: string;
                        readonly firstName: string;
                        readonly lastName: string;
                    };
                };
            }>;
        } | null;
    }>;
    readonly partiallyAssignedClasses: ReadonlyArray<{
        readonly assignedClass: {
            readonly id: string;
            readonly students: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly user: {
                            readonly id: string;
                            readonly firstName: string;
                            readonly lastName: string;
                        };
                    };
                }>;
            } | null;
        };
        readonly assignedStudents: ReadonlyArray<{
            readonly id: string;
            readonly user: {
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
            };
        }>;
    }>;
    readonly individuallyAssignedStudents: ReadonlyArray<{
        readonly id: string;
        readonly user: {
            readonly firstName: string;
            readonly lastName: string;
        };
    }>;
    readonly " $refType": "EditTaskFlyout_assignments";
};
export type EditTaskFlyout_assignments$data = EditTaskFlyout_assignments;
export type EditTaskFlyout_assignments$key = {
    readonly " $data"?: EditTaskFlyout_assignments$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Student",
  "kind": "LinkedField",
  "name": "assignedStudents",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    }
  ],
  "concreteType": "StudentConnection",
  "kind": "LinkedField",
  "name": "students",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StudentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Student",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTaskFlyout_assignments",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Class",
      "kind": "LinkedField",
      "name": "assignedClasses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PartialClassAssignment",
      "kind": "LinkedField",
      "name": "partiallyAssignedClasses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Class",
          "kind": "LinkedField",
          "name": "assignedClass",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Student",
      "kind": "LinkedField",
      "name": "individuallyAssignedStudents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
})();
(node as any).hash = '02b74f724cca7ce00df1f9c3e3e80d99';
export default node;
