import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const RecommendedPractice = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={14}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.26316 1.3206C8.26316 0.768312 7.81684 0.321429 7.26455 0.321429H4.69997C3.76108 0.321429 3 1.07917 3 2.01806V4.5382C3.16298 4.51305 3.32996 4.5 3.5 4.5C5.2953 4.5 6.75 5.9547 6.75 7.75C6.75 8.36693 6.57822 8.94363 6.27988 9.43491C6.7272 9.6171 7.22374 9.879 7.63473 10.2583C8.04473 10.6367 8.45652 11.2209 8.49678 12L11.6842 12C12.2365 12 12.6842 11.5538 12.6842 11.0015L12.6842 6.14132C12.6842 5.58904 12.2365 5.14286 11.6842 5.14286H9.09211C8.57895 5.14286 8.26316 4.82143 8.26316 4.23884V1.3206ZM12.1015 3.85714C12.4478 3.85714 12.6305 3.44684 12.3987 3.18946L10.2235 0.774198C9.97826 0.501838 9.52632 0.675351 9.52632 1.04188V2.35714C9.52632 3.18557 10.1979 3.85714 11.0263 3.85714H12.1015Z"
    />
    <path d="M3.5 9.5C4.46687 9.5 5.25 8.71687 5.25 7.75C5.25 6.78312 4.46687 6 3.5 6C2.53312 6 1.75 6.78312 1.75 7.75C1.75 8.71687 2.53312 9.5 3.5 9.5ZM3.5 10.375C2.33187 10.375 0 10.9612 0 12.125V12.125C0 12.6082 0.391751 13 0.875 13H6.125C6.60825 13 7 12.6082 7 12.125V12.125C7 10.9612 4.66812 10.375 3.5 10.375Z" />
  </Icon>
);

RecommendedPractice.displayName = 'RecommendedPractice';

export default RecommendedPractice;
