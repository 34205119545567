import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const TemplateRemove = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      d="M14.327 11.027A2.017 2.017 0 0 0 14 11h-1.032V3.428a1 1 0 0 0-1-1H4.859A.714.714 0 1 1 4.86 1h8.468a1 1 0 0 1 1 1v9.027ZM11.782 11H10a2 2 0 0 0-2 2v1c0 .364.098.706.268 1H2.6a1 1 0 0 1-1-1V4.647a1 1 0 0 1 1-1h8.182a1 1 0 0 1 1 1V11Zm-7.62-4.388c0-.351.286-.636.637-.636h3.784a.636.636 0 0 1 0 1.273H4.799a.636.636 0 0 1-.636-.637ZM4.8 8.58a.636.636 0 1 0 0 1.273h3.784a.636.636 0 0 0 0-1.273H4.799Z"
    />
    <path d="M13.5 12.5H9.958a.97.97 0 0 0 0 1.938H13.5a.97.97 0 0 0 0-1.938Z" />
  </Icon>
);
TemplateRemove.displayName = 'TemplateRemove';

export default TemplateRemove;
