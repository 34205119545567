import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

import type { Updater, TaskStartDate, TaskDueDate } from '../index';

export const updateTaskStartDate: Updater<[TaskStartDate]> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
  });

export const updateTaskDueDate: Updater<[TaskDueDate]> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
});

export const setAssignedClasses: Updater<[readonly ClassSelectionPayload[]]> =
  klasses => state => ({
    ...state,
    selectedClasses: klasses,
  });

export const setAssignedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  selectedStudents: students,
});

export const setExcludedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  excludedStudents: students,
});
