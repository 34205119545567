import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function FacebookRoundIcon({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={24}
      viewBoxWidth={24}
    >
      <circle cx="12" cy="12" r="12" />
      <path
        d="M10.4639 12.8322C10.4159 12.8322 9.35995 12.8322 8.87995 12.8322C8.62395 12.8322 8.54395 12.7362 8.54395 12.4962C8.54395 11.8562 8.54395 11.2002 8.54395 10.5602C8.54395 10.3042 8.63995 10.2242 8.87995 10.2242H10.4639C10.4639 10.1762 10.4639 9.24821 10.4639 8.81621C10.4639 8.17621 10.5759 7.56821 10.8959 7.00821C11.2319 6.43221 11.7119 6.04821 12.3199 5.82421C12.7199 5.68021 13.1199 5.61621 13.5519 5.61621H15.1199C15.3439 5.61621 15.4399 5.71221 15.4399 5.93621V7.76021C15.4399 7.98421 15.3439 8.08021 15.1199 8.08021C14.6879 8.08021 14.2559 8.08021 13.8239 8.09621C13.3919 8.09621 13.1679 8.30421 13.1679 8.75221C13.1519 9.23221 13.1679 9.69621 13.1679 10.1922H15.0239C15.2799 10.1922 15.3759 10.2882 15.3759 10.5442V12.4802C15.3759 12.7362 15.2959 12.8162 15.0239 12.8162C14.4479 12.8162 13.2159 12.8162 13.1679 12.8162V18.0322C13.1679 18.3042 13.0879 18.4002 12.7999 18.4002C12.1279 18.4002 11.4719 18.4002 10.7999 18.4002C10.5599 18.4002 10.4639 18.3042 10.4639 18.0642C10.4639 16.3842 10.4639 12.8802 10.4639 12.8322Z"
        fill="white"
      />
    </Icon>
  );
}
