import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g>
    <path
      d="M18.6643 23.9974C18.9515 24.1298 19.2595 24.1948 19.5664 24.1948C20.0076 24.1948 20.446 24.0597 20.8158 23.7967C21.4419 23.35 21.7712 22.605 21.6751 21.8511L20.899 15.7603L25.1755 11.2797C25.7049 10.7255 25.8853 9.93247 25.6459 9.21006C25.4065 8.48765 24.786 7.94998 24.0256 7.8066L17.8814 6.64909L14.8605 1.27348C14.4867 0.608418 13.7741 0.194824 12.9996 0.194824C12.2257 0.194824 11.5125 0.608418 11.1387 1.27348L8.11835 6.64854L1.97422 7.8066C1.21379 7.94998 0.593302 8.48765 0.353876 9.21006C0.115012 9.93247 0.294863 10.7249 0.823735 11.2797L5.1008 15.7597L4.32464 21.8511C4.22853 22.605 4.55788 23.35 5.18398 23.7961C5.81009 24.2428 6.63459 24.32 7.33544 23.9974L13.0002 21.3907L18.6643 23.9974Z"
      fill="#0875BE"
    />
    <path
      d="M11.8852 16.3793C11.7752 16.3796 11.6662 16.358 11.5647 16.3159C11.4631 16.2737 11.3709 16.2118 11.2934 16.1337L9.06055 13.9008C8.91264 13.7421 8.83212 13.5321 8.83595 13.3152C8.83977 13.0983 8.92765 12.8913 9.08107 12.7379C9.23448 12.5845 9.44145 12.4966 9.65838 12.4928C9.8753 12.489 10.0852 12.5695 10.244 12.7174L11.8852 14.3574L15.7592 10.4845C15.9179 10.3366 16.1279 10.2561 16.3448 10.2599C16.5617 10.2637 16.7687 10.3516 16.9221 10.505C17.0755 10.6584 17.1634 10.8654 17.1672 11.0823C17.1711 11.2993 17.0906 11.5092 16.9426 11.6679L12.4769 16.1337C12.3994 16.2118 12.3072 16.2737 12.2056 16.3159C12.1041 16.358 11.9951 16.3796 11.8852 16.3793Z"
      fill="white"
    />
  </g>
);

const CorrectFinalStep = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    viewBoxWidth={28}
    viewBoxHeight={28}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CorrectFinalStep.displayName = 'CorrectFinalStep';

export default CorrectFinalStep;
