/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EmptyTemplatesWarningQueryVariables = {
    templateGroupId: string;
    maxGroupToBeAssignable: number;
};
export type EmptyTemplatesWarningQueryResponse = {
    readonly taskTemplateGroup: {
        readonly taskTemplateConnection: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly problemContents: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
            }>;
        };
    } | null;
};
export type EmptyTemplatesWarningQuery = {
    readonly response: EmptyTemplatesWarningQueryResponse;
    readonly variables: EmptyTemplatesWarningQueryVariables;
};



/*
query EmptyTemplatesWarningQuery(
  $templateGroupId: ID!
  $maxGroupToBeAssignable: Int!
) {
  taskTemplateGroup(id: $templateGroupId) {
    taskTemplateConnection(first: $maxGroupToBeAssignable) {
      edges {
        node {
          id
          problemContents {
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxGroupToBeAssignable"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateGroupId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "templateGroupId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "maxGroupToBeAssignable"
    }
  ],
  "concreteType": "TaskTemplateConnection",
  "kind": "LinkedField",
  "name": "taskTemplateConnection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskTemplateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskTemplate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ProblemContent",
              "kind": "LinkedField",
              "name": "problemContents",
              "plural": true,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmptyTemplatesWarningQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TaskTemplateGroup",
        "kind": "LinkedField",
        "name": "taskTemplateGroup",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EmptyTemplatesWarningQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TaskTemplateGroup",
        "kind": "LinkedField",
        "name": "taskTemplateGroup",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21ff2de695581e4e77f80aff2682b0ee",
    "id": null,
    "metadata": {},
    "name": "EmptyTemplatesWarningQuery",
    "operationKind": "query",
    "text": "query EmptyTemplatesWarningQuery(\n  $templateGroupId: ID!\n  $maxGroupToBeAssignable: Int!\n) {\n  taskTemplateGroup(id: $templateGroupId) {\n    taskTemplateConnection(first: $maxGroupToBeAssignable) {\n      edges {\n        node {\n          id\n          problemContents {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '69987acdb63b29dabd93721cf130acbd';
export default node;
