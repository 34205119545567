import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M16.8372093,8.72727273 L10.1627907,8.72727273 L10.1627907,11.4318182 L14.0046512,11.4318182 C13.6465116,13.15 12.1488372,14.1363636 10.1627907,14.1363636 C7.81860465,14.1363636 5.93023256,12.2909091 5.93023256,10 C5.93023256,7.70909091 7.81860465,5.86363636 10.1627907,5.86363636 C11.172093,5.86363636 12.0837209,6.21363636 12.8,6.78636364 L14.8837209,4.75 C13.6139535,3.66818182 11.9860465,3 10.1627907,3 C6.19069767,3 3,6.11818182 3,10 C3,13.8818182 6.19069767,17 10.1627907,17 C13.744186,17 17,14.4545455 17,10 C17,9.58636364 16.9348837,9.14090909 16.8372093,8.72727273" />
);

const Google = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Google.displayName = 'Google';

export default Google;
