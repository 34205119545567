import { StyleSheet, css } from 'aphrodite';

import type { LabelData, SpacingData } from '../index';

const fontSize = 15;

const styles = StyleSheet.create({
  label: {
    cursor: 'default',
    fontSize,
    userSelect: 'none',
  },
});

type Props = {
  labels: LabelData;
  spacing: SpacingData;
};

const Labels = ({ labels, spacing }: Props) => {
  const halfSVGSize = spacing.size / 2;
  return (
    <g>
      <text
        className={css(styles.label)}
        transform={`translate(${halfSVGSize - spacing.padding}, ${
          spacing.padding + fontSize / 2
        })`}
      >
        <tspan textAnchor="middle">{labels.main || ''}</tspan>
      </text>
      <text
        className={css(styles.label)}
        transform={`translate(${halfSVGSize - spacing.padding}, ${
          spacing.size - spacing.padding
        })`}
      >
        <tspan textAnchor="middle">{labels.xAxis || ''}</tspan>
      </text>
      <text
        className={css(styles.label)}
        transform={`translate(${spacing.padding + fontSize / 2}, ${
          halfSVGSize - spacing.padding
        }) rotate(270)`}
      >
        <tspan textAnchor="middle">{labels.yAxis || ''}</tspan>
      </text>
    </g>
  );
};

export default Labels;
