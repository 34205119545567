/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditTaskFlyout_adaptiveTask = {
    readonly id: string;
    readonly title: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly expiryDate: string;
    readonly targetMastery: number;
    readonly minimumQuestionTarget: number;
    readonly areaOfStudy: {
        readonly id: string;
        readonly correctQuestionTarget: number;
    };
    readonly allowCalculator: boolean | null;
    readonly __typename: "AdaptiveTask";
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
    readonly " $refType": "EditTaskFlyout_adaptiveTask";
};
export type EditTaskFlyout_adaptiveTask$data = EditTaskFlyout_adaptiveTask;
export type EditTaskFlyout_adaptiveTask$key = {
    readonly " $data"?: EditTaskFlyout_adaptiveTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_adaptiveTask">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTaskFlyout_adaptiveTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetMastery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumQuestionTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtopic",
      "kind": "LinkedField",
      "name": "areaOfStudy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctQuestionTarget",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowCalculator",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EditTaskFlyout_assignments"
    }
  ],
  "type": "AdaptiveTask",
  "abstractKey": null
};
})();
(node as any).hash = 'c37a568eed8f09711a3cf16e31bfd6f3';
export default node;
