// eslint-disable
import { useCallback, useMemo, useState } from 'react';
import type { ReactElement } from 'react';

import type { NegativeFeedbackOptionType } from 'ms-components/Feedback/FeedbackComponent/FeedbackComponent';
import {
  FeedbackComponentControls,
  FeedbackOptionsModal,
  getFeedbackConfig,
} from 'ms-components/Feedback/FeedbackComponent/FeedbackComponent';
import { useSubmitFeedback } from 'ms-components/Feedback/FeedbackComponent/useSubmitFeedback';
import getDebugInfo from 'ms-components/FeedbackModal/getDebugInfo';
import { useAppEnv } from 'ms-helpers/AppEnv';

import type { FeedbackTypeEnum } from './__generated__/useSubmitFeedbackMutation.graphql';

export type Config = {
  feedbackType: FeedbackTypeEnum;
  isOptionsRandomized?: boolean | undefined;
  subtopicId?: string | undefined;
  questionId?: string | undefined;
  checkInId?: string | undefined;
  workoutId?: string | null | undefined;
  lessonId?: string | null | undefined;
  subproblemId?: string | null | undefined;
  workEventId?: string | null | undefined;
  problemId?: string | null | undefined;
  promotedFeatureId?: string | null | undefined;
  hasWhiteTheme?: boolean | undefined;
  noTitle?: boolean | undefined;
  extraDebugInfo?: object | undefined;
};
export type State = {
  isModalOpen: boolean;
  isFeedbackConfirmationActive: boolean;
  selectedOption: NegativeFeedbackOptionType | null | undefined;
  feedbackText: string;
};
/**
 * Creates an "entangled pair" of components that
 * have all of their corresponding state wired up together via
 * closure captures.
 *
 * This is a "light" context that lets two components share state
 * without relying on a context provider. This hook is intended to be used
 * in situations where the FeedbackComponentControls may be free to unmount while
 * FeedbackComponentModal is still being rendered. This normally occurrs inside of a snackbar
 * where we request feedback and display a modal for additional context. The snackbar may unmount
 * itself while the modal is still being displayed.
 *
 * This returns two elements:
 * feedbackControls
 * feedbackOptionsModal
 *
 * State between these two components is shared implicitly and are expected to be used as
 *
 * const { feedbackControls, feedbackOptionsModal} = useFeedbackComponents({});
 *
 * return (
 *   <>
 *     {feedbackControls}
 *     {feedbackOptionsModal}
 *   </>
 * );
 */
export function useFeedbackComponents(config: Config): {
  feedbackControls: ReactElement<any>;
  feedbackOptionsModal: ReactElement<any>;
} {
  const {
    state: [state, setState],
    submitNegativeFeedback,
    submitPositiveFeedback,
  } = useFeedbackState();
  const controls = useMemo(() => {
    const handleThumbsUpClick = () => {
      // Confirmation active timeout
      setState(state => ({ ...state, isFeedbackConfirmationActive: true }));
      setTimeout(
        () =>
          setState(state => ({
            ...state,
            isFeedbackConfirmationActive: false,
          })),
        3000,
      );
      submitPositiveFeedback(config);
    };
    const title = getFeedbackConfig(config.feedbackType).title;
    return (
      <FeedbackComponentControls
        onThumbsUpClick={handleThumbsUpClick}
        onThumbsDownClick={() =>
          setState(state => ({ ...state, isModalOpen: true }))
        }
        isFeedbackConfirmationActive={state.isFeedbackConfirmationActive}
        title={config.noTitle ? '' : title}
        hasWhiteTheme={config.hasWhiteTheme ?? false}
      />
    );
  }, [
    config,
    setState,
    state.isFeedbackConfirmationActive,
    submitPositiveFeedback,
  ]);
  const modal = useMemo(() => {
    const { feedbackText, selectedOption, isModalOpen } = state;
    return (
      <FeedbackOptionsModal
        feedbackType={config.feedbackType}
        feedbackText={feedbackText}
        selectedOption={selectedOption}
        setSelectedOption={opt =>
          setState(state => ({ ...state, selectedOption: opt }))
        }
        setFeedbackText={text =>
          setState(state => ({ ...state, feedbackText: text }))
        }
        isModalOpen={isModalOpen}
        onClose={() => {
          setState(state => ({
            ...state,
            isModalOpen: false,
            feedbackText: '',
          }));
        }}
        onSubmit={() => {
          setState(state => ({
            ...state,
            isModalOpen: false,
            feedbackText: '',
          }));
          submitNegativeFeedback(config);
        }}
        isOptionsRandomized={config.isOptionsRandomized ?? false}
      />
    );
  }, [config, setState, state, submitNegativeFeedback]);
  return {
    feedbackControls: controls,
    feedbackOptionsModal: modal,
  };
}
export function useFeedbackState(): {
  state: [State, (value: State | ((prevState: State) => State)) => void];
  submitNegativeFeedback: (config: Config) => void;
  submitPositiveFeedback: (config: Config) => void;
} {
  const [state, setState] = useState<State>({
    isModalOpen: false,
    isFeedbackConfirmationActive: false,
    selectedOption: null,
    feedbackText: '',
  });
  const { appEnv } = useAppEnv();
  const [submitFeedback] = useSubmitFeedback();
  const submitNegativeFeedback = useCallback(
    (config: Config) => {
      const {
        feedbackType,
        problemId,
        subproblemId,
        workEventId,
        workoutId,
        checkInId,
        questionId,
        promotedFeatureId,
        extraDebugInfo,
      } = config;
      const { feedbackText, selectedOption } = state;
      const rawDebugInfo = getDebugInfo({
        problemIdStr: problemId,
        workoutId,
        subproblemId,
        workEventId,
        questionId,
        checkInId,
        APP_ENV: appEnv,
        extra: extraDebugInfo,
      });
      submitFeedback({
        feedbackType,
        feedbackSubtype:
          selectedOption != null ? selectedOption.id : 'NEGATIVE',
        score: 'NEGATIVE',
        supportingText:
          (promotedFeatureId
            ? `promotedFeatureId:${promotedFeatureId} - `
            : '') + feedbackText,
        rawDebugInfo,
        problemId,
        subproblemId,
        workEventId,
        workoutId,
        checkinId: checkInId,
        checkinQuestionId: questionId,
      });
    },
    [appEnv, state, submitFeedback],
  );
  const submitPositiveFeedback = useCallback(
    (config: Config) => {
      const {
        feedbackType,
        problemId,
        subproblemId,
        workEventId,
        workoutId,
        checkInId,
        questionId,
        promotedFeatureId,
        extraDebugInfo,
      } = config;
      const rawDebugInfo = getDebugInfo({
        problemIdStr: problemId,
        workoutId,
        subproblemId,
        workEventId,
        questionId,
        checkInId,
        APP_ENV: appEnv,
        extra: extraDebugInfo,
      });
      submitFeedback({
        feedbackType,
        feedbackSubtype: 'POSITIVE',
        score: 'POSITIVE',
        supportingText: promotedFeatureId
          ? `promotedFeatureId:${promotedFeatureId} - `
          : '',
        rawDebugInfo,
        problemId,
        subproblemId,
        workEventId,
        workoutId,
        checkinId: checkInId,
        checkinQuestionId: questionId,
      });
    },
    [appEnv, submitFeedback],
  );
  return {
    state: [state, setState],
    submitNegativeFeedback,
    submitPositiveFeedback,
  };
}
