import { StyleSheet } from 'aphrodite';

export const CONTAINER_PADDING = 10;

const styles = StyleSheet.create({
  container: {
    borderRadius: '4px',
    boxSizing: 'border-box',
    paddingTop: CONTAINER_PADDING,
    paddingBottom: CONTAINER_PADDING,
    position: 'relative',
    transition: 'background-color 150ms ease-out',
    touchAction: 'none',
  },

  hint: {
    cursor: 'default',
    fontSize: '18px',
    fontWeight: 'bold',
    userSelect: 'none',
  },

  itemsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  itemWrap: {
    margin: '2px',
  },
});

export default styles;
