import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay';

import MathContent from 'ms-components/math/MathContent';
import { fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

import type { PreviewProblemInstruction_problemContent$key } from './__generated__/PreviewProblemInstruction_problemContent.graphql';

const Root = styled.div({
  fontWeight: fontWeight.normal,
  fontSize: fontSize.medium,
  lineHeight: lineHeight.body,
  color: colors.shuttleGray,
});
type Props = {
  className?: string | undefined;
  problemContent: PreviewProblemInstruction_problemContent$key;
  width?: number | string | undefined;
};
const FRAGMENT = graphql`
  fragment PreviewProblemInstruction_problemContent on ProblemContent {
    instruction
  }
`;
export default function PreviewProblemInstruction({
  problemContent: problemContentRef,
  className,
  width,
}: Props) {
  const problemContent = useFragment(FRAGMENT, problemContentRef);

  if (problemContent == null) {
    throw new Error('Problem content not found');
  }

  return (
    <Root className={className} style={width != null ? { width } : {}}>
      <MathContent content={problemContent.instruction} />
    </Root>
  );
}
