import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CircleHollow = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={20}
    viewBoxHeight={20}
  >
    <circle cx={10} cy={10} r={7} stroke={color} strokeWidth={3} fill="none" />
  </Icon>
);

CircleHollow.displayName = 'CircleHollow';

export default CircleHollow;
