import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { useFragment, graphql } from 'relay-hooks';

import KebabDropdown from 'ms-components/KebabDropdown';
import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import { Plus, Pencil as PencilIcon } from 'ms-components/icons';
import InternationalisationIcon from 'ms-components/icons/Internationalisation';
import TriangleDownIcon from 'ms-components/icons/TriangleDown';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import { AnchorButton } from 'ms-pages/Lantern/primitives/Button';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import type { Preselection } from 'ms-pages/Teacher/components/CreateTask';
import {
  ADAPTIVE_TASK,
  ENGAGE_TASK,
  LESSON_QUICK_ASSIGN,
  WORKSHEET_QUICK_ASSIGN,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import CreateTaskModal from 'ms-pages/Teacher/components/CreateTaskModal';
import DetailHeader, {
  PrintButton,
  ActionButton,
  ActionAnchorButton,
} from 'ms-pages/Textbooks/components/DetailHeader';
import { LANGUAGE_MAP } from 'ms-pages/Textbooks/components/LanguageSelector/constants';
import ProblemDifficultyFilter from 'ms-pages/Textbooks/components/ProblemDifficultyFilter';
import OutcomePill from 'ms-pages/Textbooks/components/SubtopicDetailView/OutcomePill';
import SubtopicLessonSubtabSwitch from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicLessonSubtabSwitch';
import SubtopicPracticeTabSwitch from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicPracticeTabSwitch';
import SubtopicTabs from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicTabs';
import {
  useSubtopicLessonTabsState,
  useTextbookTabsState,
} from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { getMasteryLevelTitle } from 'ms-utils/masteryLevel';
import {
  getSelfDirectedAdaptiveTaskUrl,
  getEngageActivityPreviewUrl,
  getSubtopicQuestionsWorkedSolutionsPrintUrl,
} from 'ms-utils/urls';
import { getTeacherSelfDirectedAdaptiveTaskRedirectorUrl } from 'ms-utils/urls/sunflower/sunflowerTeacher';

import { getLessonDocuments } from './Lesson';
import type {
  SubtopicDetailHeader_subtopic$data as Subtopic,
  SubtopicDetailHeader_subtopic$key,
} from './__generated__/SubtopicDetailHeader_subtopic.graphql';
import AnswerKeyModal from '../AnswerKeyModal';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useLanguageSelectorContext } from '../LanguageSelector/LanguageSelectorContext';
import type {
  DifficultyLevelTypes,
  SelectedDifficultyLevels,
} from '../ProblemDifficultyFilter/types';
import SolutionModal from '../SolutionModal';

type DifficultyLevelProps = null | {
  selectedDifficultyLevels: SelectedDifficultyLevels;
  toggleDifficultyLevelSelection: (difficulty: DifficultyLevelTypes) => void;
  canShowDifficultyFilter: boolean;
};

type Props = {
  subtopicKey: SubtopicDetailHeader_subtopic$key;
  showLesson: boolean;
  showEngage: boolean;
  showOverview: boolean;
  showPractice: boolean;
  difficultyLevelProps: DifficultyLevelProps;
};

graphql`
  fragment SubtopicDetailHeader_JsxContent on JsxContent @relay(mask: false) {
    id
    transpiledJsx
    locale
    pdfAvailable
    pdfUrl
  }
`;

const SUBTOPIC_DETAIL_HEADER_SUBTOPIC_FRAGMENT = graphql`
  fragment SubtopicDetailHeader_subtopic on Subtopic {
    id
    title
    gamifiedMastery
    hasAdaptivePractice
    hasWorksheet
    hasLessons
    hasLessonsTeacherNotes
    hasWorksheetAnswerKey
    hasExercise
    hasEngageActivity
    reasonForInclusion {
      id
      code
      description
    }
    outcomes {
      id
      code
      description
    }
    topic {
      id
      title
      badgeUrl
      syllabus {
        id
        title
        coverImageUrl
        ...LanguageSelector_availableLanguages
      }
    }

    # Lots of network requests and redundant code only to have the PDF url
    # of the selected content
    # Hopefully will be refactored soon
    # Check  https://mathspace.slack.com/archives/CDL4SBS56/p1713188975177869 and  https://mathspace.slack.com/archives/C1ACKB10T/p1712732110916879
    rslJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    rslTeacherNoteJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    solidifyLessonJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    solidifyTeacherNoteJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    investigationJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    investigationTeacherNoteJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    teacherNoteJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    worksheetJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    answerKeyJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    teacherOverviewJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    engageActivityTeacherNoteJsx {
      ...SubtopicDetailHeader_JsxContent @relay(mask: false)
    }
    problemContents {
      ...SolutionModal_problemContent
    }
  }
`;

const Aside = styled(Stack.H)({
  marginLeft: 'auto',
});

export default function SubtopicDetailHeader({
  subtopicKey,
  showEngage,
  showLesson,
  showOverview,
  showPractice,
  difficultyLevelProps,
}: Props) {
  const subtopic = useFragment(
    SUBTOPIC_DETAIL_HEADER_SUBTOPIC_FRAGMENT,
    subtopicKey,
  );

  const { role } = useViewer();

  const topic = subtopic.topic;
  const syllabus = topic.syllabus;
  const coverImageUrl = syllabus.coverImageUrl;
  const badgeUrl = topic.badgeUrl;
  const outcomes =
    subtopic.outcomes.length > 0
      ? subtopic.outcomes
      : subtopic.reasonForInclusion != null
      ? [subtopic.reasonForInclusion]
      : [];

  const subtitleContent =
    outcomes.length > 0 ? <OutcomesList outcomes={outcomes} /> : null;
  const [{ hasEnabledAccessibilityMode }] = useFeatureFlags();

  const tabs = (
    <>
      <SubtopicTabs
        showLesson={showLesson}
        showEngage={showEngage}
        showOverview={showOverview}
        showPractice={showPractice}
      />
      <Aside
        center
        style={{
          // adjustment to improve vertical alignment that seems off because of the inset border of the sticky wrapper
          transform: 'translateY(-1px)',
        }}
      >
        {role === 'Teacher' && (
          <AssignTaskButtonAndCatfa
            subtopicId={subtopic.id}
            subtopicTitle={subtopic.title}
            syllabusId={topic.syllabus.id}
          />
        )}
        {role === 'Student' &&
          subtopic.hasAdaptivePractice &&
          !hasEnabledAccessibilityMode && (
            <StartAdaptiveTaskButton subtopicId={subtopic.id} />
          )}
      </Aside>
    </>
  );

  const asideContent =
    role === 'Student' && !hasEnabledAccessibilityMode ? (
      <MasteryLevelInfo subtopic={subtopic} />
    ) : null;

  const secondaryActionsContent =
    role === 'Teacher' ? (
      <TeacherSecondaryActions
        subtopic={subtopic}
        difficultyLevelprops={difficultyLevelProps}
      />
    ) : role === 'Student' ? (
      <StudentSecondaryActions subtopic={subtopic} />
    ) : null;

  return (
    <DetailHeader
      coverImageUrl={coverImageUrl}
      badgeUrl={badgeUrl}
      title={subtopic.title}
      subtitleContent={subtitleContent}
      tabs={tabs}
      asideContent={asideContent}
      secondaryActionsContent={secondaryActionsContent}
    />
  );
}

// outcomes and reasonForInclusion are very similar and have indetical types
function OutcomesList({
  outcomes,
}: {
  outcomes: ReadonlyArray<{
    id: string;
    code: string;
    description: string;
  }>;
}) {
  return (
    <Stack.H wrap gap={1}>
      {outcomes.map(({ id, code, description }) => (
        <OutcomePill key={id} code={code} description={description} />
      ))}
    </Stack.H>
  );
}

function AssignTaskButtonAndCatfa({
  subtopicId,
  subtopicTitle,
  syllabusId,
}: {
  subtopicId: string;
  subtopicTitle: string;
  syllabusId: string;
}) {
  const isCreatingTask = useBoolean();
  const { withTrackStructEvent } = useSnowplow();

  return (
    <>
      <Button
        type="primary"
        styles={{ gap: 10 }}
        size="regular"
        padding={24}
        height={36}
        isRound
        onClick={withTrackStructEvent(isCreatingTask.setTrue, {
          category: 'textbook',
          action: 'begin_create_task',
          label: `Subtopic-${subtopicId}`,
          property: 'SUBTOPIC_HEADER_V2',
        })}
      >
        <Plus size={16} />
        Assign task
      </Button>
      <CreateTaskModal
        prefilledSubtopic={[subtopicId, subtopicTitle]}
        prefilledSyllabusId={syllabusId}
        isOpen={isCreatingTask.value}
        onClose={isCreatingTask.setFalse}
        trackingEventOnCreation={{
          category: 'textbook',
          action: 'create_task',
          label: `Subtopic-${subtopicId}`,
          property: 'SUBTOPIC_HEADER_V2',
        }}
      />
    </>
  );
}

function MasteryLevelInfo({
  subtopic,
}: {
  subtopic: {
    gamifiedMastery: number;
  };
}) {
  return (
    <Stack.H center gap={8}>
      <MasteryLevelVolumeIndicator
        masteryLevel={subtopic.gamifiedMastery}
        showTooltip={false}
        noMargin
        inAssignedTaskCard // Temp rename this? It's not in the assigned task card, just to get the smaller size
      />
      <BodyM color="eggplant" bold>
        {getMasteryLevelTitle(subtopic.gamifiedMastery)}
      </BodyM>
    </Stack.H>
  );
}

function StartAdaptiveTaskButton({ subtopicId }: { subtopicId: string }) {
  const { trackStructEvent } = useSnowplow();
  return (
    <AnchorButton
      type="primary"
      size="regular"
      padding={24}
      styles={{ gap: 10 }}
      height={36}
      isRound
      href={getSelfDirectedAdaptiveTaskUrl({ subtopicId })}
      onClick={() => {
        trackStructEvent({
          category: 'student_textbook',
          action: 'clicked_start_adaptive_task',
          label: subtopicId,
        });
      }}
    >
      <PencilIcon size={20} />
      Start adaptive task
    </AnchorButton>
  );
}
const LanguageSelectorAnchor = forwardRef<
  HTMLDivElement,
  { onClick: () => void }
>(function ({ onClick }, ref) {
  const [language] = useLanguageSelectorContext();

  return (
    <div ref={ref}>
      <ActionButton onClick={onClick}>
        <Stack.H center gap={8}>
          <InternationalisationIcon />
          {LANGUAGE_MAP[language].long}
          <Aside center>
            <TriangleDownIcon />
          </Aside>
        </Stack.H>
      </ActionButton>
    </div>
  );
});

function StudentSecondaryActions({ subtopic }: { subtopic: Subtopic }) {
  const [subtopicTab] = useTextbookTabsState();
  const [{ hasEnabledAccessibilityMode }] = useFeatureFlags();
  const isPracticeTab =
    subtopicTab === 'worksheet' || subtopicTab === 'interactive';
  const showPracticeContentSwitch =
    useGetShouldShowPracticeContentSwitch(subtopic);

  const {
    answerKeyJsx: { transpiledJsx: answerKeyTranspiledJsx },
  } = subtopic;

  return (
    <>
      {isPracticeTab &&
        showPracticeContentSwitch &&
        !hasEnabledAccessibilityMode && (
          <SubtopicPracticeTabSwitch
            backgroundColor={colors.porcelain}
            activeColor={colors.eggplant}
            small
          />
        )}

      {subtopicTab === 'worksheet' && answerKeyTranspiledJsx != null && (
        <AnswerKeyButtonAndModal
          answerKeyTranspiledJsx={answerKeyTranspiledJsx}
          subtopicId={subtopic.id}
          answerKeyPdfUrl={null /** no pdf for student*/}
        />
      )}

      <LanguageSelector
        syllabus={subtopic.topic.syllabus}
        Anchor={LanguageSelectorAnchor}
      />
    </>
  );
}

function TeacherSecondaryActions({
  subtopic,
  difficultyLevelprops,
}: {
  subtopic: Subtopic;
  difficultyLevelprops: DifficultyLevelProps;
}) {
  const [subtopicTab] = useTextbookTabsState();

  switch (subtopicTab) {
    case 'overview':
      return <TeacherOverviewActions subtopic={subtopic} />;
    case 'lesson':
      return <TeacherLessonTabActions subtopic={subtopic} />;
    case 'worksheet':
    case 'interactive':
      return (
        <TeacherPracticeTabActions
          subtopic={subtopic}
          difficultyLevelprops={difficultyLevelprops}
        />
      );
    case 'engageActivity':
      return <TeacherEngageTabActions subtopic={subtopic} />;
  }
}

function TeacherLessonTabActions({ subtopic }: { subtopic: Subtopic }) {
  const [subtopicLessonSubtab] = useSubtopicLessonTabsState();
  const { lessonPdfUrl, teacherNotePdfUrl } = getLessonDocuments(subtopic);
  const showLessonContentSwitch = useGetShouldShowLessonContentSwitch(subtopic);
  const pdfUrl =
    subtopicLessonSubtab === 'teacherGuide' ? teacherNotePdfUrl : lessonPdfUrl;
  return (
    <>
      {showLessonContentSwitch && (
        <SubtopicLessonSubtabSwitch
          backgroundColor={colors.porcelain}
          activeColor={colors.eggplant}
          small
        />
      )}

      {pdfUrl != null && <PrintButton pdfUrl={pdfUrl} />}

      {subtopicLessonSubtab === 'content' && (
        <AssignPreselectedFlowTaskButton
          flow="LESSON_TASK"
          subtopic={subtopic}
        />
      )}
    </>
  );
}

function TeacherPracticeTabActions({
  subtopic,
  difficultyLevelprops,
}: {
  subtopic: Subtopic;
  difficultyLevelprops: DifficultyLevelProps;
}) {
  const [subtopicTab] = useTextbookTabsState();

  const showPracticeContentSwitch =
    useGetShouldShowPracticeContentSwitch(subtopic);

  const {
    worksheetJsx: {
      pdfAvailable: worksheetPdfAvailable,
      pdfUrl: worksheetPdfUrlMaybeUrl,
    },
    answerKeyJsx: {
      pdfAvailable: answerKeyPdfAvailable,
      pdfUrl: answerKeyPdfMaybeUrl,
      transpiledJsx: answerKeyTranspiledJsx,
    },
  } = subtopic;

  const [
    {
      // rename it to make it more explicit
      worksheetAnswerKeys: canAccessWorksheetAnswerKeys,
    },
  ] = useFeatureFlags();

  const worksheetPdfUrl =
    worksheetPdfAvailable &&
    worksheetPdfUrlMaybeUrl != null &&
    worksheetPdfUrlMaybeUrl !== ''
      ? worksheetPdfUrlMaybeUrl
      : null;

  const answerKeyPdfUrl =
    answerKeyPdfAvailable &&
    answerKeyPdfMaybeUrl != null &&
    answerKeyPdfMaybeUrl !== ''
      ? answerKeyPdfMaybeUrl
      : null;

  const showAnswerKeyButton =
    canAccessWorksheetAnswerKeys && subtopic.hasWorksheetAnswerKey;

  return (
    <>
      {showPracticeContentSwitch && (
        <SubtopicPracticeTabSwitch
          backgroundColor={colors.porcelain}
          activeColor={colors.eggplant}
          small
        />
      )}

      {subtopicTab === 'interactive' && (
        <>
          <DifficultyFilter difficultyLevelprops={difficultyLevelprops} />

          {subtopic.hasAdaptivePractice && (
            <ActionAnchorButton
              href={getTeacherSelfDirectedAdaptiveTaskRedirectorUrl({
                subtopicId: subtopic.id,
              })}
            >
              Try
            </ActionAnchorButton>
          )}

          {subtopic.hasAdaptivePractice && (
            <AssignPreselectedFlowTaskButton
              flow="ADAPTIVE_TASK"
              subtopic={subtopic}
            />
          )}

          <Aside>
            <WorkedSolutionButtonAndModal subtopic={subtopic} />
          </Aside>
        </>
      )}

      {subtopicTab === 'worksheet' && (
        <>
          {worksheetPdfUrl != null && <PrintButton pdfUrl={worksheetPdfUrl} />}

          {showAnswerKeyButton && answerKeyTranspiledJsx != null && (
            <AnswerKeyButtonAndModal
              answerKeyPdfUrl={answerKeyPdfUrl}
              answerKeyTranspiledJsx={answerKeyTranspiledJsx}
              subtopicId={subtopic.id}
            />
          )}

          <AssignPreselectedFlowTaskButton
            flow="WORKSHEET_TASK"
            subtopic={subtopic}
          />
        </>
      )}
    </>
  );
}

function TeacherEngageTabActions({ subtopic }: { subtopic: Subtopic }) {
  const {
    engageActivityTeacherNoteJsx: { pdfAvailable, pdfUrl },
    hasEngageActivity,
  } = subtopic;
  return (
    <>
      {pdfAvailable && pdfUrl != null && pdfUrl !== '' && (
        <PrintButton pdfUrl={pdfUrl} />
      )}

      {hasEngageActivity && (
        <>
          <ActionAnchorButton
            href={getEngageActivityPreviewUrl({ subtopicId: subtopic.id })}
          >
            Try
          </ActionAnchorButton>
          <AssignPreselectedFlowTaskButton
            flow="ENGAGE_TASK"
            subtopic={subtopic}
          />
        </>
      )}
    </>
  );
}

function TeacherOverviewActions({ subtopic }: { subtopic: Subtopic }) {
  const {
    teacherOverviewJsx: { pdfAvailable, pdfUrl },
  } = subtopic;
  return (
    <>
      {pdfAvailable && pdfUrl != null && pdfUrl !== '' && (
        <PrintButton pdfUrl={pdfUrl} />
      )}
    </>
  );
}

function DifficultyFilter({
  difficultyLevelprops,
}: {
  difficultyLevelprops: DifficultyLevelProps;
}) {
  if (difficultyLevelprops === null) {
    return null;
  }

  const {
    selectedDifficultyLevels,
    toggleDifficultyLevelSelection,
    canShowDifficultyFilter,
  } = difficultyLevelprops;

  return (
    canShowDifficultyFilter && (
      <ProblemDifficultyFilter
        selectedDifficultyLevels={selectedDifficultyLevels}
        toggleDifficultyLevelSelection={toggleDifficultyLevelSelection}
      />
    )
  );
}

export function useGetShouldShowPracticeContentSwitch(subtopic: {
  hasExercise: boolean;
  hasWorksheet: boolean;
  hasWorksheetAnswerKey: boolean;
}) {
  const [
    {
      // rename it to make it more explicit
      worksheetAnswerKeys: canAccessWorksheetAnswerKeys,
    },
  ] = useFeatureFlags();
  const hasInteractiveContent = subtopic.hasExercise;

  // We will show the worksheet tab even if there is only
  // an answer key with no worksheet
  // but the school must have access to it
  const hasWorksheetContent =
    subtopic.hasWorksheet ||
    (canAccessWorksheetAnswerKeys && subtopic.hasWorksheetAnswerKey);

  const showPracticeContentSwitch =
    hasInteractiveContent && hasWorksheetContent;

  return showPracticeContentSwitch;
}

function WorkedSolutionButtonAndModal({ subtopic }: { subtopic: Subtopic }) {
  const { hasExercise, problemContents } = subtopic;

  const workedSolutionsModalOpen = useBoolean();

  if (!hasExercise) return null;
  return (
    <>
      <KebabDropdown
        items={[
          {
            key: 'worked-solutions',
            label: 'Worked Solutions',
            action: workedSolutionsModalOpen.setTrue,
          },
        ]}
      />
      <SolutionModal
        hintsVisibilityStrategy="toggleable"
        open={workedSolutionsModalOpen.value}
        onClose={workedSolutionsModalOpen.setFalse}
        problemContentsKeys={problemContents}
        printViewUrl={getSubtopicQuestionsWorkedSolutionsPrintUrl({
          subtopicId: subtopic.id,
        })}
      />
    </>
  );
}

function AnswerKeyButtonAndModal({
  answerKeyPdfUrl,
  answerKeyTranspiledJsx,
  subtopicId,
}: {
  answerKeyPdfUrl: string | null;
  answerKeyTranspiledJsx: string;
  subtopicId: string;
}) {
  const answerKeyModal = useBoolean();

  return (
    <>
      <ActionButton onClick={answerKeyModal.setTrue}>Answer key</ActionButton>
      <AnswerKeyModal
        isOpen={answerKeyModal.value}
        onClose={answerKeyModal.setFalse}
        answerKeyPdfUrl={answerKeyPdfUrl}
        answerKey={answerKeyTranspiledJsx}
        subtopicId={subtopicId}
      />
    </>
  );
}

export function useGetShouldShowLessonContentSwitch(subtopic: {
  hasLessons: boolean;
  hasLessonsTeacherNotes: boolean;
}) {
  const [
    {
      // rename it to make it more explicit
      primaryTeacherNotes: canAccessPrimaryTeacherNotes,
    },
  ] = useFeatureFlags();
  const { hasLessons, hasLessonsTeacherNotes } = subtopic;

  const showLessonContentSwitch =
    hasLessons && hasLessonsTeacherNotes && canAccessPrimaryTeacherNotes;

  return showLessonContentSwitch;
}

type CatfaFlow =
  | 'ENGAGE_TASK'
  | 'LESSON_TASK'
  | 'WORKSHEET_TASK'
  | 'ADAPTIVE_TASK';
const FlowToCatfaPreselection: Record<CatfaFlow, Preselection> = {
  ENGAGE_TASK,
  LESSON_TASK: LESSON_QUICK_ASSIGN,
  WORKSHEET_TASK: WORKSHEET_QUICK_ASSIGN,
  ADAPTIVE_TASK,
};

const FlowToAssignLabel: Record<CatfaFlow, string> = {
  ENGAGE_TASK: 'engage task',
  LESSON_TASK: 'lesson',
  WORKSHEET_TASK: 'worksheet',
  ADAPTIVE_TASK: 'adaptive task',
};

function AssignPreselectedFlowTaskButton({
  flow,
  subtopic,
}: {
  flow: 'ENGAGE_TASK' | 'LESSON_TASK' | 'WORKSHEET_TASK' | 'ADAPTIVE_TASK';
  subtopic: Subtopic;
}) {
  const assignCatfa = useBoolean();
  const [{ textbookDisableSecondaryAssignCTA }] = useFeatureFlags();
  const { withTrackStructEvent } = useSnowplow();
  return textbookDisableSecondaryAssignCTA ? null : (
    <>
      <ActionButton
        onClick={withTrackStructEvent(assignCatfa.setTrue, {
          category: 'textbook',
          action: 'begin_create_task',
          label: subtopic.id,
          property: flow,
        })}
      >
        Assign {FlowToAssignLabel[flow]}
      </ActionButton>

      <CreateTaskModal
        preselection={FlowToCatfaPreselection[flow]}
        prefilledSubtopic={[subtopic.id, subtopic.title]}
        taskName={subtopic.title}
        isOpen={assignCatfa.value}
        onClose={assignCatfa.setFalse}
        trackingEventOnCreation={{
          category: 'textbook',
          action: 'create_task',
          label: subtopic.id,
          property: flow,
        }}
      />
    </>
  );
}
