import { useContext, useMemo } from 'react';

import Clock from 'ms-components/icons/Clock';
import Separator from 'ms-pages/Teacher/components/Separator';
import { StateContext as ProblemsCollectionState } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { fontFamily, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';
import { formatTaskDuration } from 'ms-utils/time';

// if problemsCollection is not provided, it will use the one from the context
export function useCollectedTaskDuration(
  providedProblemsCollection?: readonly Problem[],
) {
  const { problemsCollection: _problemsCollection } = useContext(
    ProblemsCollectionState,
  );
  const problemsCollection = providedProblemsCollection ?? _problemsCollection;
  return useMemo(
    () => problemsCollection.map(p => p[1]).reduce((acc, x) => acc + x, 0),
    [problemsCollection],
  );
}

const Root = styled({
  default: {
    display: 'flex',
    fontFamily: fontFamily.body,
    fontSize: fontSize.small,
    color: colors.grayChateau,
  },
  hasCATFAPreviewStyles: {
    fontSize: fontSize.medium,
    color: colors.grey90,
    lineHeight: '22px',
  },
});

type Props = {
  className?: string | undefined;
  renderNullWhenZero?: boolean | undefined;
  showClock?: boolean | undefined;
  hasCATFAPreviewStyles?: boolean | undefined;
  problems?: readonly Problem[] | undefined;
};

export function CustomTaskDuration({
  className,
  renderNullWhenZero,
  showClock = true,
  hasCATFAPreviewStyles = false,
  problems,
}: Props) {
  const duration = useCollectedTaskDuration(problems);

  if (renderNullWhenZero && duration === 0) return null;

  return (
    <Root className={className} hasCATFAPreviewStyles={hasCATFAPreviewStyles}>
      {showClock && (
        <>
          <Clock />
          <Separator size={BASE_UNIT} />
        </>
      )}
      {formatTaskDuration(duration)}
    </Root>
  );
}
