/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DifficultyLevel = "EASY" | "HARD" | "MEDIUM";
export type SubproblemType = "ALGEBRAIC" | "BOXPLOT" | "EQUATION" | "GEOMETRY" | "GRAPHPLOT" | "HISTOGRAM" | "INEQUALITY" | "INLINE" | "MULTIPLE_CHOICE" | "NUMBER_BUILDER" | "NUMBER_LINE" | "NUMERIC" | "PROBABILITY_TREE";
export type ProblemItem_problemContent = {
    readonly id: string;
    readonly attachment: string | null;
    readonly hasSolution: boolean;
    readonly difficultyLevel: DifficultyLevel | null;
    readonly estimatedCompletionTime: number;
    readonly previewWorkoutCreationToken: string;
    readonly problemTemplateId: string;
    readonly isStaticQuestion: boolean;
    readonly subproblems: ReadonlyArray<{
        readonly instruction: string | null;
        readonly subproblemType: SubproblemType;
        readonly " $fragmentRefs": FragmentRefs<"PreviewProblemSubproblem_subproblem">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"PreviewProblemInstruction_problemContent" | "PreviewProblemAttachment_problemContent" | "PreviewProblemAttributes_problemContent" | "SolutionModal_problemContent">;
    readonly " $refType": "ProblemItem_problemContent";
};
export type ProblemItem_problemContent$data = ProblemItem_problemContent;
export type ProblemItem_problemContent$key = {
    readonly " $data"?: ProblemItem_problemContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ProblemItem_problemContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemItem_problemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSolution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "difficultyLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedCompletionTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewWorkoutCreationToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problemTemplateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isStaticQuestion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubproblemContent",
      "kind": "LinkedField",
      "name": "subproblems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instruction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subproblemType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PreviewProblemSubproblem_subproblem"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewProblemInstruction_problemContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewProblemAttachment_problemContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewProblemAttributes_problemContent"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SolutionModal_problemContent"
    }
  ],
  "type": "ProblemContent",
  "abstractKey": null
};
(node as any).hash = '14c325b43e3222feb3a5fc46899f2e7e';
export default node;
