/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsolidatedMasteryLevelType = "EMERGING" | "EXPLORING" | "FAMILIAR" | "MASTERED" | "NOT_STARTED" | "PROFICIENT";
export type masteryLevelConsolidatedFragment = {
    readonly masteryLevel: ConsolidatedMasteryLevelType;
    readonly " $refType": "masteryLevelConsolidatedFragment";
};
export type masteryLevelConsolidatedFragment$data = masteryLevelConsolidatedFragment;
export type masteryLevelConsolidatedFragment$key = {
    readonly " $data"?: masteryLevelConsolidatedFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"masteryLevelConsolidatedFragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "masteryLevelConsolidatedFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "masteryLevel",
      "storageKey": null
    }
  ],
  "type": "MasteryLevel",
  "abstractKey": null
};
(node as any).hash = '2d9af516b1a1b2db9cac349c3e80f092';
export default node;
