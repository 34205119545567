import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M12.704 4.75325L10.5564 3.00539C9.95364 2.51482 9.08357 2.55659 8.60998 3.09883L1.37595 11.3814C1.19184 11.5922 1.08751 11.8589 1.07831 12.1424L1.00066 14.5345C0.976927 15.2658 1.57941 15.9189 2.34635 15.9931C2.52155 16.01 2.6962 15.9952 2.86107 15.9494L5.05618 15.3396C5.30027 15.2718 5.5148 15.1383 5.67547 14.9544L12.9396 6.63735C13.4147 6.09343 13.3113 5.25164 12.7088 4.75716C12.7072 4.75585 12.7056 4.75455 12.704 4.75325ZM15.3702 1.78224L13.4868 0.249438C13.0462 -0.109214 12.4098 -0.0773145 12.0655 0.320688C12.039 0.351289 12.0147 0.383615 11.9928 0.417471L11.9182 0.53252C11.625 0.984653 11.7443 1.59922 12.1967 1.96749L14.0801 3.50029C14.5208 3.85894 15.1571 3.82704 15.5014 3.42904C15.5279 3.39844 15.5522 3.36611 15.5742 3.33226L15.6488 3.21721C15.942 2.76508 15.8227 2.15051 15.3702 1.78224Z"
  />
);

const Pencil2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

Pencil2.displayName = 'Pencil2';

export default Pencil2;
