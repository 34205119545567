import Text from '../Text';

// sigma11 is the scaling factor used to differentiate levels of nesting in fractions.
// sigma11 is derived from KaTeX's interpretations of TeX.
// https://github.com/Khan/KaTeX/blob/master/src/fontMetrics.js
const sigma11 = 0.686;

type Props = {
  charAspectRatio?: number | undefined;
  denominator: string;
  numerator: string;
  x?: number | undefined;
  size?: number | undefined;
};

const Fraction = ({
  charAspectRatio = 0.375,
  denominator,
  numerator,
  x = 0,
  size = 16,
}: Props) => {
  // spacing is one fifth of font-size
  const spacing = size / 5;

  const charWidth = Math.max(denominator.length, numerator.length);

  const width = size * charWidth * charAspectRatio;

  return (
    <g transform={`translate(${x})`}>
      <g transform={`translate(0 ${-((size * sigma11) / 2 + spacing / 2)})`}>
        <Text value={numerator} size={size * sigma11} />
      </g>
      <line
        stroke="black"
        strokeWidth={0.5}
        x1={`${-width / 2}`}
        x2={`${width / 2}`}
        y1={-spacing}
        y2={-spacing}
      />
      <g transform={`translate(0 ${(size * sigma11) / 2 + spacing / 2})`}>
        <Text value={denominator} size={size * sigma11} />
      </g>
    </g>
  );
};

export default Fraction;
