import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Activity = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={14}
    viewBoxHeight={14}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0276 7.02743H13.8814L13.0699 5.4392C12.8961 5.10096 12.546 4.89623 12.1489 4.91139C11.7642 4.93322 11.4283 5.18046 11.2932 5.54099L9.71002 9.76387L7.43107 3.02973C7.29803 2.63657 6.92992 2.37095 6.50988 2.36865C6.09674 2.36865 5.72772 2.63083 5.59145 3.02077L3.84214 8.03409L3.53929 7.51157C3.3658 7.21286 3.04343 7.02743 2.69807 7.02743H0.972655C0.43635 7.02743 0 7.46378 0 8.00009C0 8.53639 0.43635 8.97251 0.972655 8.97251H2.13763L3.2215 10.8415C3.41314 11.171 3.78125 11.3645 4.15441 11.3209C4.53125 11.2855 4.85569 11.0313 4.98115 10.6733L6.49563 6.33349L8.74172 12.9711C8.87362 13.359 9.23713 13.6242 9.66268 13.6315C10.0662 13.6315 10.432 13.3778 10.5735 13.0005L12.3405 8.28731L12.4205 8.4431C12.5878 8.76984 12.9196 8.97251 13.2863 8.97251H15.0276C15.5639 8.97251 16 8.53639 16 8.00009C16 7.46378 15.5639 7.02743 15.0276 7.02743Z"
      fill={color}
    />
  </Icon>
);

Activity.displayName = 'Activity';

export default Activity;
