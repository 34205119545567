import styled from '@emotion/styled';

import { StudentCheckInLayout } from 'ms-pages/Lantern/components/StudentLayout';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import Main from 'ms-pages/Lantern/primitives/Main';
import { VStack, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { studentDashboardPath } from 'ms-utils/urls';

import confusedMilo from './confused-milo.svg';

const RootVStack = styled(VStack)({
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  textAlign: 'center',
});

export function CheckInUnavailableError() {
  return (
    <StudentCheckInLayout backgroundColor="almond50">
      <Main>
        <RootVStack>
          <img src={confusedMilo} role="presentation" alt="confused Milo" />
          <VSpacer height={4 * BASE_UNIT} />
          <HeadingM>Oops! This check-in is unavailable</HeadingM>
          <VSpacer height={2 * BASE_UNIT} />
          <BodyM>
            It looks like the check-in you're trying to view is currently
            unavailable.
          </BodyM>
          <VSpacer height={4 * BASE_UNIT} />
          <Button
            size="lanternMedium"
            onClick={() => {
              window.location.assign(studentDashboardPath);
            }}
            type="primary"
          >
            Back to Dashboard
          </Button>
        </RootVStack>
      </Main>
    </StudentCheckInLayout>
  );
}
