import { css } from 'aphrodite';

import type { AspectRatio } from '.';
import { styles, paddingBottom } from './styles';

type Props = {
  id: string;
  autoPlay?: boolean | undefined;
  aspectRatio: AspectRatio;
};

export default function YoutubeVideo({
  id,
  autoPlay = false,
  aspectRatio,
}: Props) {
  // All youtube parameters can be found here:
  // https://developers.google.com/youtube/player_parameters
  const youtubeUrl =
    'https://www.youtube.com/embed/' + // eslint-disable-line prefer-template
    id +
    '?rel=0' +
    '&showinfo=0' +
    `&autoplay=${autoPlay ? '1' : '0'}`;

  return (
    <div
      className={css(styles.paddingWrapper)}
      style={{ paddingBottom: `${paddingBottom(aspectRatio)}%` }}
    >
      <iframe
        className={css(styles.wrapper)}
        src={youtubeUrl}
        title="Youtube video player"
        frameBorder="0"
        scrolling="no"
        allowFullScreen
      />
    </div>
  );
}
