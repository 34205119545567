import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  useResetClassCodeMutationVariables,
  useResetClassCodeMutationResponse,
} from './__generated__/useResetClassCodeMutation.graphql';

const mutation = graphql`
  mutation useResetClassCodeMutation($classId: ID!) {
    resetClassCode(classId: $classId) {
      class {
        id
        code
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useResetClassCode({
  classId,
}: useResetClassCodeMutationVariables): UseMutationResponse<useResetClassCodeMutationResponse> {
  return useMutation({
    mutation,
    variables: {
      classId,
    },
  });
}
