import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

// NB EllipsisVertical2 was created instead to update EllipsisVertical in order not to break other views that implement EllipsisVertical

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M7.84615 3.69231C8.86576 3.69231 9.69231 2.86576 9.69231 1.84615C9.69231 0.826551 8.86576 0 7.84615 0C6.82655 0 6 0.826551 6 1.84615C6 2.86576 6.82655 3.69231 7.84615 3.69231ZM7.84615 9.84615C8.86576 9.84615 9.69231 9.0196 9.69231 8C9.69231 6.9804 8.86576 6.15385 7.84615 6.15385C6.82655 6.15385 6 6.9804 6 8C6 9.0196 6.82655 9.84615 7.84615 9.84615ZM9.69231 14.1538C9.69231 15.1734 8.86576 16 7.84615 16C6.82655 16 6 15.1734 6 14.1538C6 13.1342 6.82655 12.3077 7.84615 12.3077C8.86576 12.3077 9.69231 13.1342 9.69231 14.1538Z"
  />
);

const EllipsisVertical2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

EllipsisVertical2.displayName = 'EllipsisVertical2';

export default EllipsisVertical2;
