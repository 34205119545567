import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    transform="translate(0.6 -1)"
    d="M15.5724 1.6001C10.4124 1.6001 6.21422 5.58819 6.21422 10.4904V15.5679C6.21422 15.6255 6.13927 15.7228 6.08039 15.7414C5.57433 15.9017 5.16334 16.0406 4.78711 16.1784C4.33845 16.3427 4.00006 16.8151 4.00006 17.2772V28.9438C4.00006 29.4027 4.33627 29.8755 4.78208 30.0433C8.23146 31.3418 11.8618 32.0001 15.5724 32.0001C19.2829 32.0001 22.9132 31.3417 26.3628 30.0432C26.8084 29.8754 27.1445 29.4027 27.1445 28.9438V17.2772C27.1445 16.8151 26.8061 16.3427 26.3573 16.1782C25.9809 16.0405 25.5699 15.9015 25.0643 15.7412C25.0056 15.7226 24.9306 15.6253 24.9306 15.5676V10.4902C24.9303 5.58819 20.7324 1.6001 15.5724 1.6001ZM13.0235 20.8304C13.0235 19.4921 14.1647 18.4091 15.5722 18.4091C16.9797 18.4091 18.1209 19.492 18.1209 20.8304C18.1209 21.7172 17.5945 22.4596 16.8465 22.8805V26.8837C16.8465 27.5529 16.2766 28.0943 15.5722 28.0943C14.8677 28.0943 14.2978 27.5529 14.2978 26.8837V22.8805C13.5499 22.4596 13.0235 21.7172 13.0235 20.8304ZM21.7127 10.4902V14.8712C19.6991 14.4595 17.6333 14.2511 15.5732 14.2509C13.5141 14.2509 11.4487 14.4594 9.43196 14.8707V10.4902C9.43196 7.27367 12.1865 4.65696 15.5722 4.65696C18.958 4.6569 21.7127 7.27367 21.7127 10.4902Z"
  />
);

const Lock = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={32}
    viewBoxWidth={32}
  >
    {path}
  </Icon>
);

Lock.displayName = 'Lock';

export default Lock;
