import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

const styles = StyleSheet.create({
  root: {
    padding: 25,
    borderTop: '1px solid #eee',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  centered: {
    justifyContent: 'center',
  },
});

type Props = {
  children: ReactNode;
  isCentered?: boolean;
};

const ModalFooter = ({ children, isCentered = false }: Props) => (
  <div className={css(styles.root, isCentered && styles.centered)}>
    {children}
  </div>
);

export default ModalFooter;
