import styled from '@emotion/styled';
import type { MouseEvent } from 'react';

import useUpdateTeacherPreference from 'ms-pages/Teacher/TeacherContext/UpdateTeacherPreference';
import { NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import {
  Asterisk,
  FieldWrapper,
  FormText,
  Label,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import {
  ErrorMessage,
  UnauthorisedTaskAssigneesError,
} from 'ms-pages/Teacher/components/EditTaskFlyout';
import { Button } from 'ms-pages/Teacher/components/Link';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Input from 'ms-ui-primitives/Input';
import Select from 'ms-ui-primitives/Select';

import type { CombinedState } from '.';

type Props = {
  state: CombinedState;
  errorKeys: string[];
  typename: 'NewAdaptiveExperienceTask' | 'AdaptiveTask';
};

const InlineButton = styled(Button)({ display: 'inline' });

export default function AdaptiveTaskDetailsForm({
  state: { values, updaters },
  errorKeys,
  typename,
}: Props) {
  const {
    updateTaskStartDate,
    updateTaskDueDate,
    updateTaskName,
    updateTargetMastery,
    toggleDisableCalculator,
    updateSelectedClasses,
    updateSelectedStudents,
    updateExcludedStudents,
  } = updaters;

  const [updateTeacherPreference] = useUpdateTeacherPreference();
  const updateTeacherPreferenceTargetMastery = (targetMastery: number) => {
    updateTeacherPreference({
      createTaskTargetMastery: targetMastery,
    });
  };

  const getTargetMasteryOptions = () => {
    const baseTargetMasteryOptions = [
      { label: 'Familiar (50)', value: 50 },
      { label: 'Proficient (75)', value: 75 },
      { label: 'Mastered (100)', value: 100 },
    ];

    if (typename === 'NewAdaptiveExperienceTask') {
      return [
        {
          label: 'No target mastery (Recommended)',
          value: NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE,
        },
        ...baseTargetMasteryOptions,
      ];
    }

    return baseTargetMasteryOptions;
  };

  return (
    <TaskCreationFormWrapper>
      <UnauthorisedTaskAssigneesError errorKeys={errorKeys} />
      <FieldWrapper>
        <Label>Title</Label>
        <Input
          placeholder="Task Name"
          value={values.taskName}
          onChange={e => updateTaskName(e.target.value)}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        {/* It is possible to edit a task after the due date has passed.
            We don't want to suprise the use by automatically changing the due date
            but we need to communicate the invalid state.
         */}
        {values.taskDueDate <= new Date() && (
          <ErrorMessage>Due date cannot be in the past.</ErrorMessage>
        )}
        <TaskDatePicker
          onChange={([startDate, endDate]) => {
            updateTaskStartDate(startDate);
            updateTaskDueDate(endDate);
          }}
          value={[values.taskStartDate, values.taskDueDate]}
          errorKeys={errorKeys}
        />
      </FieldWrapper>
      <FieldWrapper>
        <TaskAssignment
          selectedClasses={values.selectedClasses}
          selectedStudents={values.selectedStudents}
          excludedStudents={values.excludedStudents}
          onChangeClasses={updateSelectedClasses}
          onChangeStudents={updateSelectedStudents}
          onChangeExcludedStudents={updateExcludedStudents}
        />
      </FieldWrapper>
      <FieldWrapper>
        <Label>Target Mastery</Label>
        <Select
          block
          onChange={nextValue => {
            updateTargetMastery(nextValue);
            updateTeacherPreferenceTargetMastery(nextValue);
          }}
          value={values.targetMastery}
          options={getTargetMasteryOptions()}
        />
        <FormText>
          This will soon be removed.{' '}
          <InlineButton
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              window.Intercom?.('show');
            }}
          >
            Contact us
          </InlineButton>{' '}
          to send us feedback.
        </FormText>
      </FieldWrapper>
      <FieldWrapper>
        <Checkbox
          checked={values.disableCalculator}
          onChange={() => {
            toggleDisableCalculator();
          }}
          label="Hide calculator tools"
        />
      </FieldWrapper>
    </TaskCreationFormWrapper>
  );
}
