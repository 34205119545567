import { graphql, useQuery } from 'relay-hooks';

import { PageTimeErrorThrower } from 'ms-helpers/PageTimeTracker';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';

import StudentSkillsMapSubstrandRecommendationsFragment from './StudentSkillsMapSubstrandRecommendationsFragment';
import type { StudentSkillsMapSubstrandRecommendationsQuery } from './__generated__/StudentSkillsMapSubstrandRecommendationsQuery.graphql';

type Props = {
  substrandId: string;
  strandId: string;
  urlToNavigateToWhenOutcomeIsClicked?: string | undefined;
  hideSkillsMapLink?: boolean | undefined;
};
const STUDENT_SKILLS_MAP_SUBSTRAND_RECOMMENDATIONS_QUERY = graphql`
  query StudentSkillsMapSubstrandRecommendationsQuery(
    $substrandId: ID!
    $strandId: ID!
  ) {
    ...StudentSkillsMapSubstrandRecommendationsFragment
  }
`;
export default function StudentSkillsMapSubstrandRecommendations({
  substrandId,
  strandId,
  urlToNavigateToWhenOutcomeIsClicked,
  hideSkillsMapLink = false,
}: Props) {
  const { props, error } =
    useQuery<StudentSkillsMapSubstrandRecommendationsQuery>(
      STUDENT_SKILLS_MAP_SUBSTRAND_RECOMMENDATIONS_QUERY,
      {
        substrandId,
        strandId,
      },
    );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentSkillsMapSubstrandRecommendations"
        componentName="StudentSkillsMapSubstrandRecommendations"
        error={error}
      />
    );
  }
  if (props == null) return <LoadingSpinner />;
  return (
    <StudentSkillsMapSubstrandRecommendationsFragment
      query={props}
      urlToNavigateToWhenOutcomeIsClicked={urlToNavigateToWhenOutcomeIsClicked}
      hideSkillsMapLink={hideSkillsMapLink}
    />
  );
}
