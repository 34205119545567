/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DragHandleAlignment = "CENTER" | "RIGHT";
export type NumberLineMode = "DECIMAL" | "FRACTION" | "IMPROPER" | "SIMPLIFIED_FRACTION";
export type NumberLineSubstatus = "CORRECT" | "INCORRECT" | "UNKNOWN";
export type NumberLineUnit = "NO_UNIT" | "PI";
export type SubproblemType = "ALGEBRAIC" | "BOXPLOT" | "EQUATION" | "GEOMETRY" | "GRAPHPLOT" | "HISTOGRAM" | "INEQUALITY" | "INLINE" | "MULTIPLE_CHOICE" | "NUMBER_BUILDER" | "NUMBER_LINE" | "NUMERIC" | "PROBABILITY_TREE";
export type SubproblemInput_subproblem = {
    readonly subproblemType: SubproblemType;
    readonly initialInputConfiguration: {
        readonly __typename: "LatexAnswerConfig";
        readonly template: string;
    } | {
        readonly __typename: "MultipleChoiceConfig";
        readonly options: ReadonlyArray<{
            readonly optionContent: string;
            readonly optionKey: string;
        }>;
    } | {
        readonly __typename: "NumberLineConfig";
        readonly mode: NumberLineMode;
        readonly unit: NumberLineUnit;
        readonly start: string;
        readonly end: string;
        readonly minorTicks: string;
        readonly majorTicks: string;
        readonly numberLineValues: ReadonlyArray<{
            readonly lowerBound: {
                readonly inclusive: boolean;
                readonly value: string;
            };
            readonly substatus: NumberLineSubstatus;
            readonly upperBound: {
                readonly inclusive: boolean;
                readonly value: string;
            };
        }>;
    } | {
        readonly __typename: "LegacyGraphPlotConfig";
        readonly requestDatum: string;
    } | {
        readonly __typename: "HistogramConfig";
        readonly doesDrawBars: boolean;
        readonly doesDrawLines: boolean;
        readonly dragHandleAlignment: DragHandleAlignment;
        readonly hasGapBetweenBars: boolean;
        readonly mainLabel: string | null;
        readonly histogramValues: ReadonlyArray<{
            readonly title: string;
            readonly value: number;
        }>;
        readonly xAxisLabel: string;
        readonly yAxisLabel: string;
        readonly yIncrement: number;
        readonly yMax: number;
        readonly yMin: number;
    } | {
        /*This will never be '%other', but we need some
        value in case none of the concrete values match.*/
        readonly __typename: "%other";
    };
    readonly " $refType": "SubproblemInput_subproblem";
};
export type SubproblemInput_subproblem$data = SubproblemInput_subproblem;
export type SubproblemInput_subproblem$key = {
    readonly " $data"?: SubproblemInput_subproblem$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubproblemInput_subproblem">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "inclusive",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubproblemInput_subproblem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subproblemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "initialInputConfiguration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "template",
              "storageKey": null
            }
          ],
          "type": "LatexAnswerConfig",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MultipleChoiceOption",
              "kind": "LinkedField",
              "name": "options",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "optionContent",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "optionKey",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "MultipleChoiceConfig",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "start",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "end",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minorTicks",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "majorTicks",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "NumberLineSegment",
              "kind": "LinkedField",
              "name": "numberLineValues",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NumberLinePoint",
                  "kind": "LinkedField",
                  "name": "lowerBound",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "substatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "NumberLinePoint",
                  "kind": "LinkedField",
                  "name": "upperBound",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "NumberLineConfig",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requestDatum",
              "storageKey": null
            }
          ],
          "type": "LegacyGraphPlotConfig",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "doesDrawBars",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "doesDrawLines",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dragHandleAlignment",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasGapBetweenBars",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mainLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HistogramDatum",
              "kind": "LinkedField",
              "name": "histogramValues",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "xAxisLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yAxisLabel",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yIncrement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yMax",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "yMin",
              "storageKey": null
            }
          ],
          "type": "HistogramConfig",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SubproblemContent",
  "abstractKey": null
};
})();
(node as any).hash = '2abe6280c0bfd704809af2510b0c0682';
export default node;
