import { graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import extractNode from 'ms-utils/relay/extractNode';
import { getContentBrowsingSubtopicDetailViewUrl } from 'ms-utils/urls';

import type { SubtopicRedirectorQuery } from './__generated__/SubtopicRedirectorQuery.graphql';

type Props = {
  syllabusId: string;
  topicId: string;
};
export default function SubtopicRedirectorContainer({
  syllabusId,
  topicId,
}: Props) {
  const { props, error } = useQuery<SubtopicRedirectorQuery>(
    graphql`
      query SubtopicRedirectorQuery($topicId: ID!) {
        topic(id: $topicId) {
          subtopics(first: 1) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    `,
    { topicId },
    {
      fetchPolicy: 'store-and-network',
    },
  );

  if (error != null) throw error;
  if (props == null) return <MinorSpinner />;
  const { topic } = props;
  if (topic == null) return `There is not topic with id ${topicId}`;
  const subtopics = extractNode(topic.subtopics);
  const [subtopic] = subtopics;
  if (subtopic == null) {
    return `There are not subtopics in topic with id ${topicId}`;
  }
  const { id: subtopicId } = subtopic;
  return (
    <Redirect
      to={getContentBrowsingSubtopicDetailViewUrl({
        syllabusId,
        topicId,
        subtopicId,
      })}
    />
  );
}
