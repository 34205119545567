import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const UserCheck = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color="none"
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45899 14.1661C9.71251 14.4196 10.0195 14.6057 10.3525 14.7143H0.857143C0.383756 14.7143 0 14.3305 0 13.8571C0 11.0168 2.33018 9.04678 5.1705 9.04678H6.88479C7.17615 9.04678 7.46157 9.06751 7.73919 9.10778C7.71199 9.13246 7.68526 9.15793 7.65901 9.18417C6.78033 10.0628 6.78033 11.4875 7.65901 12.3661L9.45899 14.1661ZM6 1C4.10645 1 2.57143 2.53502 2.57143 4.42857C2.57143 6.32212 4.10645 7.85714 6 7.85714C7.89355 7.85714 9.42857 6.32212 9.42857 4.42857C9.42857 2.53502 7.89355 1 6 1ZM14.3621 7.78168C14.6492 7.37497 15.2116 7.27799 15.6183 7.56508C16.0251 7.85217 16.122 8.41461 15.8349 8.82132L12.5899 13.4184C12.4366 13.6356 12.1952 13.7741 11.9304 13.7967C11.6655 13.8194 11.4041 13.724 11.2161 13.536L9.05276 11.3727C8.70074 11.0206 8.70074 10.4499 9.05276 10.0979C9.40478 9.74587 9.97551 9.74587 10.3275 10.0979L11.7342 11.5046L14.3621 7.78168Z"
      fill={color}
    />
  </Icon>
);
UserCheck.displayName = 'UserCheck';

export default UserCheck;
