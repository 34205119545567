import { borderRadiusUI, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const ErrorMessage = styled({
  padding: `${3 * BASE_UNIT}px ${4 * BASE_UNIT}px`,
  backgroundColor: colors.provincialPink,
  borderRadius: borderRadiusUI,
  color: colors.brickRed,
  lineHeight: lineHeight.title,
});

export default ErrorMessage;
