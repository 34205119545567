/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditTaskFlyout_newAdaptiveExperienceTask = {
    readonly id: string;
    readonly title: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly expiryDate: string;
    readonly newAdaptiveTargetMastery: number | null;
    readonly newAdaptiveMinimumQuestionTarget: number | null;
    readonly areaOfStudy: {
        readonly id: string;
        readonly correctQuestionTarget: number;
    };
    readonly allowCalculator: boolean | null;
    readonly __typename: "NewAdaptiveExperienceTask";
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
    readonly " $refType": "EditTaskFlyout_newAdaptiveExperienceTask";
};
export type EditTaskFlyout_newAdaptiveExperienceTask$data = EditTaskFlyout_newAdaptiveExperienceTask;
export type EditTaskFlyout_newAdaptiveExperienceTask$key = {
    readonly " $data"?: EditTaskFlyout_newAdaptiveExperienceTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_newAdaptiveExperienceTask">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditTaskFlyout_newAdaptiveExperienceTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryDate",
      "storageKey": null
    },
    {
      "alias": "newAdaptiveTargetMastery",
      "args": null,
      "kind": "ScalarField",
      "name": "targetMastery",
      "storageKey": null
    },
    {
      "alias": "newAdaptiveMinimumQuestionTarget",
      "args": null,
      "kind": "ScalarField",
      "name": "minimumQuestionTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtopic",
      "kind": "LinkedField",
      "name": "areaOfStudy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "correctQuestionTarget",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowCalculator",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EditTaskFlyout_assignments"
    }
  ],
  "type": "NewAdaptiveExperienceTask",
  "abstractKey": null
};
})();
(node as any).hash = '1709b1b69e680f78648bb19211849bb0';
export default node;
