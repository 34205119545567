import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  editNewAdaptiveExperienceTaskMutationVariables,
  editNewAdaptiveExperienceTaskMutationResponse,
} from './__generated__/editNewAdaptiveExperienceTaskMutation.graphql';

export type { editNewAdaptiveExperienceTaskMutationVariables };
const mutation = graphql`
  mutation editNewAdaptiveExperienceTaskMutation(
    $taskId: ID!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $title: String!
    $startDate: DateTime!
    $dueDate: DateTime!
    $targetMastery: Int
    $allowCalculator: Boolean!
  ) {
    editNewAdaptiveExperienceTask(
      taskId: $taskId
      title: $title
      startDate: $startDate
      dueDate: $dueDate
      targetMastery: $targetMastery
      allowCalculator: $allowCalculator
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
    ) {
      task {
        id
        title
        startDate
        dueDate
        expiryDate
        allowCalculator
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useEditNewAdaptiveExperienceTask(
  variables: editNewAdaptiveExperienceTaskMutationVariables,
): UseMutationResponse<editNewAdaptiveExperienceTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
