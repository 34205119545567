import type { ReactNode } from 'react';
import { useState } from 'react';

import MultiTextbookSearch from 'ms-components/MultiTextbookSearchModal';
import type { TextbookSearchFilterSubtopicContentEnum } from 'ms-components/MultiTextbookSearchModal/__generated__/MTSSearchResultsQuery.graphql';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import { SubtopicSelectorSidebar } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelectorSideBar';
import type { SelectedFlow } from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  SkillsGrid,
  useGridAnimation,
  SkillsGridPreview,
} from 'ms-pages/Teacher/components/SkillsGrid';
import {
  BookTypeTabsLeft,
  type TabValue,
  TextbookTypeTabsProvider,
  useTextbookTypeTabsContext,
} from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import type { Subtopic } from 'ms-pages/Textbooks/components/TopicSidebar';
import { colors } from 'ms-styles/colors';
import { HStack, VStack } from 'ms-ui-primitives/Stack';

type MultipleSelectionProps =
  | {
      selectedSubtopics: readonly SelectedSubtopic[];
      setSelectedSubtopics: (
        cb: (prev: readonly SelectedSubtopic[]) => SelectedSubtopic[],
      ) => void;
      enableMultipleSelection: true;
    }
  | {
      selectedSubtopics?: never;
      setSelectedSubtopics?: never;
      enableMultipleSelection?: false;
    };

export type TextbookAndSearchTabSyncProps = {
  onSearchTabSearchStringUpdate: (searchString: string) => void;
  setBookTypeTab: (tab: TabValue) => void;
};

export type SubtopicSelectorProps = {
  activeSubtopic: SelectedSubtopic | null | undefined;
  activeTopicId?: string | null | undefined;
  setActiveSubtopic: (subtopic: SelectedSubtopic | null) => void;
  subtopicFilter?: (subtopic: Subtopic) => boolean;
} & MultipleSelectionProps;

type SidebarAndPreviewProps = SubtopicSelectorProps & {
  children: ReactNode;
  noHorizontalSubtopicBodyPadding?: boolean;
};

// We are not using this sidebar for discoveryCheckIn flow of CATFA just yet
// It doesn't have a corresponding search filter enum either
// So we are excluding it from the type
type SidebarAndPreviewTaskTypes = Exclude<SelectedFlow, 'discoveryCheckIn'>;

const taskTypeToSearchFilterEnumMap: Record<
  SidebarAndPreviewTaskTypes,
  TextbookSearchFilterSubtopicContentEnum
> = {
  adaptive: 'HAS_ADAPTIVE_PRACTICE',
  custom: 'HAS_EXERCISE',
  lesson: 'HAS_LESSON',
  worksheet: 'HAS_WORKSHEET',
  taskTemplate: 'HAS_EXERCISE',
  bulkCustom: 'HAS_EXERCISE',
  engageTask: 'HAS_ENGAGE_ACTIVITY',
};

export function SidebarAndPreviewWrapper({
  children,
}: {
  children: ReactNode;
}) {
  return { children };
}

export function SidebarAndPreview(
  props: SidebarAndPreviewProps & {
    syllabusId: string;
    setSyllabusId: (syllabusId: string) => void;
    taskType: SidebarAndPreviewTaskTypes;
    disableSkillsBookTab?: boolean;
  },
) {
  const {
    syllabusId,
    setSyllabusId,
    setActiveSubtopic,
    disableSkillsBookTab = false,
  } = props;

  const [bookTypeTab, setBookTypeTab] = useState<TabValue>('textbook');

  // This is used to keep the search term unchanged when switching between tabs
  const [searchString, setSearchString] = useState('');

  const {
    featureFlags: { showSkillsBookGridView },
  } = useViewer();

  return (
    <VStack
      grow
      style={{
        backgroundColor: colors.white,
        overflowY: 'hidden',
      }}
    >
      <HStack grow style={{ overflowY: 'hidden' }}>
        <TextbookTypeTabsProvider
          selectedTab={bookTypeTab}
          setSelectedTab={setBookTypeTab}
          setSyllabusId={s => {
            if (s === syllabusId) return;
            setSyllabusId(s);
            setActiveSubtopic(null);
          }}
          currentSyllabusId={syllabusId}
          disableSkillsBookTab={disableSkillsBookTab}
        >
          {showSkillsBookGridView && bookTypeTab === 'skillsbook' ? (
            <SkillsGridStyle {...props} />
          ) : bookTypeTab === 'skillsbook' || bookTypeTab === 'textbook' ? (
            <TextbookStyle
              {...props}
              setBookTypeTab={setBookTypeTab}
              onSearchTabSearchStringUpdate={setSearchString}
            />
          ) : null}
          {bookTypeTab === 'search' && (
            <SearchStyle
              {...props}
              searchString={searchString}
              onSearchStringUpdate={setSearchString}
              setBookTypeTab={setBookTypeTab}
            />
          )}
        </TextbookTypeTabsProvider>
      </HStack>
    </VStack>
  );
}

function TextbookStyle(
  props: SidebarAndPreviewProps & TextbookAndSearchTabSyncProps,
) {
  const { children, noHorizontalSubtopicBodyPadding } = props;
  return (
    <>
      <SubtopicSelectorSidebar {...props} />
      <Body
        noFooterSpaceAtBottom
        noHorizontalPadding={noHorizontalSubtopicBodyPadding}
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {children}
      </Body>
    </>
  );
}

function SkillsGridStyle({
  activeSubtopic,
  setActiveSubtopic,
  children,
  enableMultipleSelection = false,
  selectedSubtopics,
  setSelectedSubtopics,
  noHorizontalSubtopicBodyPadding,
}: SidebarAndPreviewProps) {
  const [activeSubtopicId] = activeSubtopic ?? [null];

  const {
    state: previewOpen,
    setState: setPreviewOpen,
    animationMoving: previewMoving,
    fadeOut: previewFadeOut,
  } = useGridAnimation<boolean>(activeSubtopic != null);

  const showPreview = previewOpen && !previewMoving;

  const { currentSyllabusId } = useTextbookTypeTabsContext();
  const { trackStructEvent } = useSnowplow();

  const [searchQueryString, onSearchQueryStringChange] = useState<string>('');

  return (
    <>
      <BookTypeTabsLeft />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          ...(showPreview ? { width: 312 } : { flexGrow: 1 }),
          zIndex: 1,
          flexShrink: 0,
        }}
      >
        <SkillsGrid
          syllabusId={currentSyllabusId}
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
          previewFadeOut={previewFadeOut}
          onClickSubtopic={({ subtopic }) => {
            trackStructEvent({
              category: 'create_task_from_anywhere',
              action: 'textbook_page_view',
              label: currentSyllabusId,
              property: subtopic.id,
            });
            setActiveSubtopic([subtopic.id, subtopic.title]);
          }}
          onSelectSubtopic={
            enableMultipleSelection && setSelectedSubtopics != null
              ? subtopic => {
                  setSelectedSubtopics(prev => {
                    const ids = prev.map(s => s[0]);
                    if (ids.includes(subtopic.id)) {
                      return prev.filter(s => s[0] !== subtopic.id);
                    }
                    return [...prev, [subtopic.id, subtopic.title]];
                  });
                }
              : () => {}
          }
          selectedSubtopicIds={
            selectedSubtopics == null ? null : selectedSubtopics.map(s => s[0])
          }
          activeSubtopicId={activeSubtopicId}
          searchQueryString={searchQueryString}
          onSearchQueryStringChange={onSearchQueryStringChange}
          enableMultipleSelection={enableMultipleSelection}
        />
      </div>
      <SkillsGridPreview shown={showPreview} fadeOut={previewFadeOut}>
        <Body
          noFooterSpaceAtBottom
          noHorizontalPadding={noHorizontalSubtopicBodyPadding}
          style={{
            flexGrow: 1,
            overflowY: 'auto',
            height: '100%',
          }}
        >
          {children}
        </Body>
      </SkillsGridPreview>
    </>
  );
}

function SearchStyle({
  searchString,
  onSearchStringUpdate,
  setSyllabusId,
  setActiveSubtopic,
  setBookTypeTab,
  taskType,
}: SidebarAndPreviewProps & {
  searchString: string;
  onSearchStringUpdate: (searchString: string) => void;
  setSyllabusId: (syllabusId: string) => void;
  setBookTypeTab: (tab: TabValue) => void;
  taskType: SidebarAndPreviewTaskTypes;
}) {
  return (
    <>
      <BookTypeTabsLeft />
      <Body
        noFooterSpaceAtBottom
        isVerticallyScrollable={false}
        isVerticalOverflowHidden
        style={{
          flexGrow: 1,
          height: '100%',
          padding: '16px 16px 0 16px',
        }}
      >
        <MultiTextbookSearch
          searchString={searchString}
          classicUi
          isInCATFA
          renderInsideModal={false}
          onSearchTermUpdate={onSearchStringUpdate}
          onResultClick={({ syllabusId, subtopicId, title }) => {
            setBookTypeTab('textbook');
            setSyllabusId(syllabusId);
            setActiveSubtopic([subtopicId, title]);
          }}
          filterBySubtopicContent={taskTypeToSearchFilterEnumMap[taskType]}
        />
      </Body>
    </>
  );
}
