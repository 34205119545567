import dayjs from 'dayjs';
import { createContext, useContext, useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

type TimeSubscriber = (now: dayjs.Dayjs) => void;
class TimeUpdateManager {
  private subscribers: Set<TimeSubscriber> = new Set();
  private hasControllingComponent = false;
  private hasLoggedWarning = false;

  registerControllingComponent() {
    this.hasControllingComponent = true;
    return this.unregisterControllingComponent.bind(this);
  }

  unregisterControllingComponent() {
    this.hasControllingComponent = false;
  }

  subscribe = (callback: TimeSubscriber) => {
    this.subscribers.add(callback);
    if (!this.hasControllingComponent && !this.hasLoggedWarning) {
      // eslint-disable-next-line no-console
      console.warn(
        `TimeUpdateManager: No controlling component found. Make sure to render a <TimeUpdater /> component somewhere in the tree to ensure time updates are triggered.`,
      );
      this.hasLoggedWarning = true;
    }
    return () => {
      this.unsubscribe(callback);
    };
  };

  unsubscribe = (callback: TimeSubscriber) => {
    this.subscribers.delete(callback);
  };

  notifySubscribers = (now: dayjs.ConfigType = new Date()) => {
    const nowDayjs = dayjs(now);
    if (this.subscribers.size === 0) return;
    unstable_batchedUpdates(() => {
      this.subscribers.forEach(callback => callback(nowDayjs));
    });
  };
}

const TimeContext = createContext(new TimeUpdateManager());

export const useTimeUpdateManager = () => {
  return useContext(TimeContext);
};

export function TimeUpdater() {
  const timeUpdateManager = useTimeUpdateManager();
  useEffect(() => {
    const unsubscribe = timeUpdateManager.registerControllingComponent();
    const intervalId = setInterval(() => {
      timeUpdateManager.notifySubscribers(dayjs());
    }, 1000);
    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, [timeUpdateManager]);
  return null;
}
