/* global __GRAPHQL_ORIGIN__, __RELAY_CONNECTION_MAX__, __RELAY_CACHE_SIZE__, __RELAY_CACHE_TTL__ */
import type { ReactNode } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  QueryResponseCache,
} from 'relay-runtime';
import RelayFeatureFlags from 'relay-runtime/lib/util/RelayFeatureFlags';

import { getCSRFToken } from 'ms-utils/misc/csrfToken';

declare global {
  // Defined in webpack/partial-configs/common.config.js
  var __RELAY_CONNECTION_MAX__: number;
  var __RELAY_CACHE_SIZE__: number;
  var __RELAY_CACHE_TTL__: number;
  var __GRAPHQL_ORIGIN__: string;

  interface Window {
    // Declared in this file
    __relayEnvironment: Environment;
  }
}

/**
 * In the task details view we were running into issues because a fragment defined on an interface
 * type was being mistaken for a request to suspend a component
 *
 * Fragment X on CustomTask {}
 * Query {
 *   task { ...X }
 * }
 * Task is a TaskInterface that can be one of AdaptiveTask, or CustomTask. In cases where the type
 * is AdaptiveTask, the fragment requested by the container would be "empty" this was mistaken for
 * a @defer / @module and the relay environment would attempt to suspend the component without a fallback.
 * This is fixed by disabling suspense (for now)
 */

// @ts-expect-error ENABLE_RELAY_CONTAINERS_SUSPENSE isn't in the .d.ts we have
RelayFeatureFlags.ENABLE_RELAY_CONTAINERS_SUSPENSE = false;

// This is used for queries of the form:
// connectionField(first: 1000) {
//   # ...
// }
// It is intended for cases where we want to attempt to get all data.
const RELAY_CONNECTION_MAX = __RELAY_CONNECTION_MAX__;

/**
 * This caching mechanism is probably temporary. We want to eventually
 * use the actual record store as a cache instead of a query response cache.
 *
 * There are issues around older use of <QueryRenderer /> that don't seem to
 * respect the store when making a request. At the moment we use { useCache: true }
 * as a parameter to fetchPolicy in QueryRenderer. This only operates on the
 * network layer and is probably not what we want.
 *
 * At the moment this is the approach documented in
 * https://relay.dev/docs/v9.0.0/network-layer
 *
 * And is waiting for resolution of this open issue
 * https://github.com/facebook/relay/issues/2232
 *
 * Record Store serialisation is currently undocumented in
 * v9.0.0 at the time of writing
 */
const cache = new QueryResponseCache({
  size: __RELAY_CACHE_SIZE__,
  ttl: __RELAY_CACHE_TTL__,
});

/**
 * When the server renders out HTML for hydration of react,
 * we want to make sure that the data used to make that html
 * is already available in the store to prevent rerenders.
 */
export function hydrateRelayNetworkStore(
  // query id, variables, response
  keys: ReadonlyArray<{ queryID: string; variables: {}; json: {} }>,
) {
  if (keys != null && keys.length > 0)
    for (const query of keys) {
      // TODO remove as any hack once we properly type hydrateRelayNetworkStore
      cache.set(query.queryID, query.variables, query.json as any);
    }
}

const createNetwork = (graphqlEndpointUrl: string) =>
  Network.create((operation, variables, cacheConfig) => {
    // useCache isn't in our .d.ts, it has probably been removed but I doubt
    // there is a correspondence between our legacy relay version and the .d.ts
    // so we'll keep it for now.
    // TODO upgrade our version of relay and get this all synced-up
    // @ts-expect-error
    const { useCache, force } = cacheConfig || {};
    const shouldUseCache = useCache && !force;
    const queryID = operation.text;
    const isMutation = operation.operationKind === 'mutation';
    const isQuery = operation.operationKind === 'query';

    // try to fetch from cache first
    if (cache && shouldUseCache && isQuery && queryID != null) {
      const fromCache = cache.get(queryID, variables);
      if (fromCache != null) return fromCache;
    }

    // any mutation could invalidate any query so we must always clear
    if (cache && isMutation) {
      cache.clear();
    }

    return fetch(graphqlEndpointUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        'X-CSRFToken': getCSRFToken(),
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    })
      .then(response => {
        // TODO we should probably figure out a typing scheme for our errors.
        // Eg. type = 'network' | 'server' | 'graphql'
        // This would allow our UI to handle errors more specifically
        if (response.ok || response.status === 500) {
          // 200 and 500 responses are valid GraphQL responses, and may contain
          // JSON data.
          const contentType = response.headers.get('Content-Type') || '';
          if (contentType !== 'application/json') {
            return Promise.reject(
              new Error(`Unexpected Content-Type: '${contentType}' received.`),
            );
          }

          return response.json();
        } else if (response.status === 401) {
          // We have been logged out and need to redirect to the login page
          // @ts-expect-error the forceGet parameter is a FF-only feature
          window.location.reload(true);
        }

        return Promise.reject(
          new Error(
            `Unexpected server response: ${response.status} ${response.statusText}.`,
          ),
        );
      })
      .then(json => {
        // TODO: REMOVE ME WHEN UPSTREAM RELAY ISSUE IS RESOLVED
        if (json.data == null) {
          return Promise.reject(
            new Error('Placeholder: Relay encountered unhandled GraphQL error'),
          );
        }
        return json;
      })
      .then(json => {
        if (shouldUseCache && isQuery && json && queryID != null) {
          // we only need to create the cache when we have something to put into it
          cache.set(queryID, variables, json);
        }

        return json;
      });
  });

const publicEnvironment = new Environment({
  network: createNetwork(`${__GRAPHQL_ORIGIN__}/graphql/public/`),
  store: new Store(new RecordSource(), {
    // 🚨 This is only temporarily set to 0 to ease the upgrade to
    // relay v11 (default changed from 0 -> 10). Set this to 10
    // in the near future. We must rip out the QueryResponseCache
    // at that time (though the messy ms_ssr stuff seems to have become
    // coupled to this QueryResponseCache thing — so that will have to
    // be solved at the same time) as it has conflicting caching behaviour
    gcReleaseBufferSize: 0,
  }),
});

window.__relayEnvironment = publicEnvironment;

function PublicEnvironmentProvider({ children }: { children: ReactNode }) {
  return (
    <RelayEnvironmentProvider environment={publicEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
}

const anonymousEnvironment = new Environment({
  network: createNetwork(`${__GRAPHQL_ORIGIN__}/graphql/anonymous/`),
  store: new Store(new RecordSource(), {
    // 🚨 This is only temporarily set to 0 to ease the upgrade to
    // relay v11 (default changed from 0 -> 10). Set this to 10
    // in the near future. We must rip out the QueryResponseCache
    // at that time (though the messy ms_ssr stuff seems to have become
    // coupled to this QueryResponseCache thing — so that will have to
    // be solved at the same time) as it has conflicting caching behaviour
    gcReleaseBufferSize: 0,
  }),
});

function AnonymousEnvironmentProvider({ children }: { children: ReactNode }) {
  return (
    <RelayEnvironmentProvider environment={anonymousEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  );
}

export {
  AnonymousEnvironmentProvider,
  publicEnvironment,
  RELAY_CONNECTION_MAX,
  PublicEnvironmentProvider,
};
