import MiloStarEye from 'ms-components/illustrations/MiloStarEye';
import {
  fontFamily,
  fontWeight,
  lineHeight,
  fontSize,
  font,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

type Props = {
  title: string;
  actionDescription?: string | undefined;
  backgroundColor?: string | undefined;
};

const EmptyMessage = styled({
  margin: 'auto',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '400px',
});

const EmptyMessageTitle = styled({
  color: colors.neutralGray,
  marginBottom: 2 * BASE_UNIT,
  marginTop: 10 * BASE_UNIT,
  textAlign: 'center',
  ...font.gilroyLargeHeading,
});

const EmptyMessageDescription = styled({
  textAlign: 'center',
  fontSize: fontSize.headerTitle,
  lineHeight: lineHeight.body,
  color: colors.grey90,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.normal,
});

export default function SunflowerDetailEmptyView({
  title,
  actionDescription,
  backgroundColor = colors.white,
}: Props) {
  return (
    <>
      <EmptyMessage style={{ backgroundColor }}>
        <MiloStarEye sizeRatio={1.2} />
        <EmptyMessageTitle>{title}</EmptyMessageTitle>
        {actionDescription != null && (
          <EmptyMessageDescription>{actionDescription}</EmptyMessageDescription>
        )}
      </EmptyMessage>
    </>
  );
}
