import { useState, useCallback } from 'react';

import { Logger } from 'ms-utils/app-logging';
import { unwrap } from 'ms-utils/typescript-utils';

// use multi state is a "namespaced" state management system where each "sub-state" is a key in an
// object with it's own setState. This allows us to create "keyed" or "iterable" state using only one
// state object.
//
// The type parameter T specifies the shape of every "sub-state". Any sub-state can be any
// random subset of the properties in T.
export function useMultiState<T extends Record<string, any>>(): [
  // getState
  (substateKey: string) => Partial<T> | undefined,
  // createSetState
  (
    substateKey: string,
    defaultState?: Partial<T>,
  ) => (stateOrStateUpdater: StateOrStateUpdater<Partial<T>>) => void,
  // clearState
  () => void,
] {
  const [state, setState] = useState<Record<string, Partial<T>>>({});

  const createBoundSetState = useCallback(
    (key: string, defaultState?: Partial<T>) => {
      // Initialize a new sub-state for the given key if required
      setState(innerState =>
        innerState[key] !== undefined
          ? innerState
          : {
              ...innerState,
              [key]: defaultState ?? {},
            },
      );
      return (stateOrStateUpdater: StateOrStateUpdater<Partial<T>>) => {
        // this state system is not reliable for any kind of enumeration. Bound state creation and
        // updates change the order of the keys in the object
        setState(innerState => {
          // There is zero guarantee that the `key` exists in the state object, since
          // the exposed `clearState` can wipe the key at any time, yet the bound set
          // state function can still be called subsequent to that with a now
          // non-existent key. We make this a no-op and log an error if this occurs.
          if (
            typeof stateOrStateUpdater === 'function' &&
            innerState[key] === undefined
          ) {
            Logger.error(
              `Attempted to update state for key "${key}" but the key does not exist in the multi-state object.`,
            );
            return innerState;
          }

          return {
            ...innerState,
            [key]:
              typeof stateOrStateUpdater === 'function'
                ? // unwrap is safe as we handled the non-existence of the key above.
                  stateOrStateUpdater(unwrap(innerState[key]))
                : stateOrStateUpdater,
          };
        });
      };
    },
    [],
  );

  const clearState = useCallback(() => {
    setState({});
  }, []);

  const getState = useCallback((key: string) => state[key], [state]);

  return [getState, createBoundSetState, clearState];
}

type StateOrStateUpdater<T> = T | ((curState: T) => T);
