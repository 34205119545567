import { useMemo, useRef } from 'react';

import type { CombinedState } from 'ms-pages/Teacher/components/CreateTaskTemplate/state/index';

export default function useIsTaskTemplateChanged({
  taskTemplateFormState,
  problemContentIds,
}: {
  taskTemplateFormState: CombinedState;
  problemContentIds: Array<string>;
}) {
  const previousFormState = useRef(taskTemplateFormState.values);
  const previousProblemContentIds = useRef(problemContentIds);

  return useMemo(() => {
    const { title, description, taskTemplateGroupIds } =
      taskTemplateFormState.values;

    const {
      title: prevTitle,
      description: prevDescription,
      taskTemplateGroupIds: prevTaskTemplateGroupIds,
    } = previousFormState.current;

    const isStateChanged =
      title !== prevTitle ||
      description !== prevDescription ||
      taskTemplateGroupIds[0] !== prevTaskTemplateGroupIds[0];

    const isProblemListChanged =
      // short-circuit if the lengths are different then we can skip the next check
      problemContentIds.length !== previousProblemContentIds.current.length ||
      problemContentIds.some(
        (id, index) => id !== previousProblemContentIds.current[index],
      );

    return isStateChanged || isProblemListChanged;
  }, [problemContentIds, taskTemplateFormState.values]);
}
