import { borderRadiusUI, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { Anchor } from 'ms-ui-primitives/Link';
import { styled } from 'ms-utils/emotion';
import { teacherStudentSettingsUrl } from 'ms-utils/urls';

const CreatedStudent = styled({
  padding: 4 * BASE_UNIT,
  color: colors.cloudBurst,
  fontWeight: fontWeight.semibold,
  backgroundColor: colors.eggplant10,
  borderRadius: borderRadiusUI,
});
const Spacer = styled({
  height: 4 * BASE_UNIT,
});

export type Props = {
  student: {
    readonly id: string;
    readonly user: { readonly firstName: string; readonly lastName: string };
  };
  classes: ReadonlyArray<{ readonly title: string }>;
};

export function StudentConfirmation({ student, classes }: Props) {
  return (
    <>
      <CreatedStudent>
        <Anchor
          target="_blank"
          href={teacherStudentSettingsUrl({ studentId: student.id })}
        >
          {student.user.firstName} {student.user.lastName}
        </Anchor>
        {` added to ${classes.map(c => c.title).join(', ')}`}
      </CreatedStudent>
      <Spacer />
    </>
  );
}
