import { css, StyleSheet } from 'aphrodite';

import { font } from 'ms-styles/theme/Bootstrap';

type Props = {
  size?: number | undefined;
  value: string;
  x?: number | undefined;
};

const Text = ({ size = 16, value, x }: Props) => {
  const styles = StyleSheet.create({
    base: {
      fill: '#222223',
      fontFamily: font.serif,
      fontSize: size,
      pointerEvents: 'none',
      userSelect: 'none',
    },
  });

  return (
    <text x={x} className={css(styles.base)} textAnchor="middle">
      {value}
    </text>
  );
};

export default Text;
