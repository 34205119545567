import React from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import {
  StateContext,
  UpdatersContext,
} from 'ms-pages/Teacher/TaskReport/state';
import Filter from 'ms-pages/Teacher/components/FilterForm/Filter';
import FilterPopover from 'ms-pages/Teacher/components/FilterForm/FilterPopover';
import Button from 'ms-ui-primitives/Button';
import RadioButton from 'ms-ui-primitives/RadioButton';
import Separator from 'ms-ui-primitives/Separator';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { AssignedClassPicker_assignedStudentsClasses$key } from './__generated__/AssignedClassPicker_assignedStudentsClasses.graphql';
// 🚨 TODO unwind this when we use relay-compiler's builtin typescript emitter and
// react-relay's builtin hooks, which will work properly unlike this dodgy 3rd party things.
//
// The 3rd party 'relay-hooks' and the 3rd party typescript emitter for relay-compiler
// are not compatible. The " $data" property in the emitted types looks like this:
// WorkHistory_events$data | undefined
// The | undefined is incompatible with the, relay-hooks package, as must be removed.
type FixFragmentArrayKeyType<T extends readonly any[]> = ReadonlyArray<{
  [P in keyof T[number]]: NonNullable<T[number][P]>;
}>;
type FixedAssignedStudentsClassesKey =
  FixFragmentArrayKeyType<AssignedClassPicker_assignedStudentsClasses$key>;
type Props = {
  assignedStudentsClasses: FixedAssignedStudentsClassesKey;
  taskId: string;
};
export default function AssignedClassPicker({
  assignedStudentsClasses: assignedStudentsClassesKey,
  taskId,
}: Props) {
  const popoverRef = React.useRef(null);
  const isPopoverOpen = useBoolean(false);
  const assignedStudentsClasses = useFragment(
    graphql`
      fragment AssignedClassPicker_assignedStudentsClasses on Class
      @relay(plural: true) {
        id
        title
      }
    `,
    assignedStudentsClassesKey,
  );
  const { withTrackStructEvent } = useSnowplow();
  const { filterByClass } = React.useContext(StateContext);
  const { setFilterByClass } = React.useContext(UpdatersContext);
  const filterByClassTitle = React.useMemo(() => {
    return (
      assignedStudentsClasses.find(
        ({ id: classId }) => classId === filterByClass,
      ) || {}
    ).title;
  }, [assignedStudentsClasses, filterByClass]);
  return (
    <Filter
      label={
        filterByClassTitle == null
          ? 'Filter by class'
          : `Filter by class: ${filterByClassTitle}`
      }
      popoverAnchorRef={popoverRef}
      selected={filterByClass != null}
      popover={
        isPopoverOpen.value && (
          <FilterPopover
            onDismiss={isPopoverOpen.setFalse}
            anchorElementRef={popoverRef}
          >
            <Separator size={4} />
            {assignedStudentsClasses.map(
              ({ id: classId, title: classTitle }) => (
                <React.Fragment key={classId}>
                  <RadioButton
                    checked={filterByClass === classId}
                    onChange={withTrackStructEvent(
                      () => {
                        setFilterByClass(classId);
                      },
                      {
                        category: 'task_report',
                        action: 'changed_filter',
                        label: taskId,
                      },
                    )}
                    label={classTitle}
                  />
                  <Separator size={4} />
                </React.Fragment>
              ),
            )}

            <div>
              <Button
                size="small"
                onClick={withTrackStructEvent(
                  () => {
                    setFilterByClass('');
                  },
                  {
                    category: 'task_report',
                    action: 'changed_filter',
                    label: taskId,
                  },
                )}
              >
                Clear
              </Button>
            </div>
          </FilterPopover>
        )
      }
      onClick={isPopoverOpen.setTrue}
    />
  );
}
