import { css } from 'aphrodite';
import React, { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { graphql, useQuery } from 'relay-hooks';

import ArrowRight from 'ms-components/icons/ArrowRight';
import PlusIcon from 'ms-components/icons/Plus';
import { useSnowplow } from 'ms-helpers/Snowplow';
import ProgressStepper from 'ms-pages/Signup/Sunflower/ProgressStepper';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useCreateStudentForSunflower from 'ms-pages/Signup/Sunflower/useCreateStudentForSunflower';
import useValidateSunflowerUserDetails from 'ms-pages/Signup/Sunflower/useValidateSunflowerUserDetails';
import Button from 'ms-ui-primitives/Button';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Input from 'ms-ui-primitives/Input';
import SearchableSelect from 'ms-ui-primitives/SearchableSelect';
import Separator from 'ms-ui-primitives/Separator';
import { HStack, HSpacer } from 'ms-ui-primitives/Stack';
import { InvariantViolation } from 'ms-utils/app-logging';
import { noop } from 'ms-utils/misc';
import {
  dashboardRedirectUrl,
  signupSunflowerClassCreationPath,
  type SunflowerSchoolSelectorParams,
} from 'ms-utils/urls';

import { Disclaimer } from './Disclaimer';
import { makeErrorList } from './ErrorList';
import { FooterLinks } from './FooterLinks';
import type {
  SchoolFormAvailableSchoolsSunflowerQuery,
  SchoolFormAvailableSchoolsSunflowerQueryResponse,
} from './__generated__/SchoolFormAvailableSchoolsSunflowerQuery.graphql';
import type {
  SchoolFormSunflowerSignupQuery,
  SchoolFormSunflowerSignupQueryResponse,
} from './__generated__/SchoolFormSunflowerSignupQuery.graphql';
import type {
  SchoolFormSunflowerSignupSelectSubdivisionQuery,
  SchoolFormSunflowerSignupSelectSubdivisionQueryResponse,
} from './__generated__/SchoolFormSunflowerSignupSelectSubdivisionQuery.graphql';
import { formStyles } from './styles';
import type {
  Country,
  CountrySubdivision,
  Errors,
  SchoolReference,
  SchoolTitle,
} from './types';

export type Schools =
  SchoolFormAvailableSchoolsSunflowerQueryResponse['availableSchoolReferences'];
export type School = {
  reference: SchoolReference;
  title: SchoolTitle;
};
type AvailableCountries =
  SchoolFormSunflowerSignupQueryResponse['availableCountries'];
type CountryQueryResponse = NonNullable<
  SchoolFormSunflowerSignupSelectSubdivisionQueryResponse['country']
>;
type SubdivisionsQueryResponse = CountryQueryResponse['subdivisions'];
function SchoolForm(props: RouteComponentProps<SunflowerSchoolSelectorParams>) {
  const {
    state: {
      location,
      country,
      countrySubdivision,
      schoolTitle,
      responseErrors,
    },
    dispatch,
  } = useSignupState();
  const [createStudent, { response: studentResponse, errors: studentError }] =
    useCreateStudentForSunflower();
  const [validateUserDetails, { response: validationResponse }] =
    useValidateSunflowerUserDetails();
  const { replace, push } = useHistory();
  const { setUserId, trackStructEvent } = useSnowplow();
  const [hasSubdivisions, setHasSubdivisions] = React.useState<boolean>(false);
  useEffect(() => {
    const errors = validationResponse?.validateSunflowerUserDetails?.errors;
    if (validationResponse != null && errors?.length === 0) {
      props.match.params.origin === 'teacher'
        ? push(signupSunflowerClassCreationPath)
        : createStudent({});
    }
  });
  useEffect(() => {
    const student = studentResponse?.createStudentForSunflower?.student;
    if (student != null) {
      if (student.user.snowplowId != null) {
        setUserId(student.user.snowplowId);
      }
      trackStructEvent({
        category: 'signup_sunflower',
        action: 'created_account',
        label: 'student',
      });
      window.location.href = dashboardRedirectUrl;
    }
  }, [replace, setUserId, studentResponse, trackStructEvent]);
  function handleSchoolChange({ reference, title }: School) {
    dispatch({ type: 'setSchoolTitle', value: title });
    dispatch({ type: 'setSchoolReference', value: reference });
    trackStructEvent({
      category: 'signup_sunflower',
      action: 'submitted_school',
      label: props.match.params.origin,
    });
  }
  function handleCountryChange(value: Country) {
    dispatch({ type: 'setCountry', value });
    dispatch({ type: 'setCountrySubdivision', value: null });
  }
  function handleSubdivisionChange(value: CountrySubdivision) {
    dispatch({ type: 'setCountrySubdivision', value });
  }
  const isSubdivisionRequired =
    country != null && ['US', 'CA', 'AU'].includes(country.id);
  return (
    <>
      <ProgressStepper step={3} />
      <SchoolFormPresentational
        title="Your school's details"
        country={country ?? { id: location, name: '' }}
        countrySubdivision={countrySubdivision}
        hasSubdivisions={hasSubdivisions}
        schoolTitle={schoolTitle}
        isDisabled={country == null || schoolTitle == null}
        onCountryChange={handleCountryChange}
        onSubdivisionChange={handleSubdivisionChange}
        onSchoolChange={handleSchoolChange}
        setHasSubdivisions={setHasSubdivisions}
        errorMessage={
          studentError != null
            ? 'Something went wrong. Please try again'
            : undefined
        }
        actionButtonText={
          props.match.params.origin === 'teacher'
            ? 'Continue'
            : props.match.params.origin === 'parent'
            ? 'Create student account'
            : 'Create Account'
        }
        showDisclaimer={['student', 'parent'].includes(
          props.match.params.origin,
        )}
        showJoinCodeLink={props.match.params.origin === 'student'}
        onContinue={
          props.match.params.origin === 'teacher'
            ? () => validateUserDetails({})
            : () => {
                createStudent({});
              }
        }
        errors={responseErrors}
        trackingIdPrefix="SignupPage/SchoolForm"
        isSubdivisionRequired={isSubdivisionRequired}
      />
    </>
  );
}
function SchoolFormPresentational({
  title,
  country,
  countrySubdivision,
  hasSubdivisions,
  schoolTitle,
  isDisabled,
  actionButtonText,
  onCountryChange,
  onSubdivisionChange,
  onSchoolChange,
  onContinue,
  setHasSubdivisions,
  errorMessage,
  errors,
  showDisclaimer,
  showJoinCodeLink,
  trackingIdPrefix,
  isSubdivisionRequired,
}: {
  title: string;
  country: Country | null | undefined;
  countrySubdivision: CountrySubdivision | null | undefined;
  hasSubdivisions: boolean;
  schoolTitle: string | null | undefined;
  isDisabled: boolean;
  actionButtonText: string;
  onCountryChange: (country: Country) => void;
  onSchoolChange: (school: School) => void;
  onSubdivisionChange: (countrySubdivision: CountrySubdivision) => void;
  onContinue: () => void;
  setHasSubdivisions: (hasSubdivisions: boolean) => void;
  errorMessage?: string | undefined;
  errors: Errors;
  showDisclaimer: boolean;
  showJoinCodeLink: boolean;
  trackingIdPrefix: string;
  isSubdivisionRequired: boolean;
}) {
  return (
    <div className={css(formStyles.container)}>
      <div className={css(formStyles.formContainer)}>
        <div className={css(formStyles.titleContainer)}>
          <div className={css(formStyles.title)}>{title}</div>
        </div>
        <CountrySelectorContainer
          initialCountry={country}
          onSubmit={onCountryChange}
        />
        {country != null ? (
          isSubdivisionRequired ? (
            <SelectSubdivisionContainer
              country={country}
              countrySubdivision={countrySubdivision}
              setHasSubdivisions={setHasSubdivisions}
              onSelect={onSubdivisionChange}
            />
          ) : null
        ) : (
          <SelectFieldGroup
            disabled
            title="Region"
            field="Select Region"
            onChange={noop}
            options={[]}
            value={null}
          />
        )}
        {country != null &&
          (countrySubdivision != null ||
            hasSubdivisions === false ||
            !isSubdivisionRequired) && (
            <SelectSchoolContainer
              onSelect={onSchoolChange}
              countryId={country.id}
              subdivisionId={countrySubdivision?.id}
              schoolTitle={schoolTitle}
            />
          )}
        {errorMessage != null && (
          <div className={css(formStyles.formError)}>{errorMessage}</div>
        )}
        {(errors?.other?.length ?? 0) > 0 && (
          <div className={css(formStyles.formError)}>
            {makeErrorList(errors.other)}
          </div>
        )}
        <div className={css(formStyles.actionWrapper)}>
          <Button
            size="large"
            type="primary"
            isRound
            isDisabled={isDisabled}
            onClick={onContinue}
            label="Continue"
            trackingId={`${trackingIdPrefix}/Continue`}
          >
            <HStack center>
              {actionButtonText}
              <HSpacer width={8} />
              <ArrowRight />
            </HStack>
          </Button>
        </div>
        {showDisclaimer && (
          <>
            <Separator size={8} />
            <Disclaimer trackingIdPrefix={`${trackingIdPrefix}/Disclaimer`} />
          </>
        )}
        {showJoinCodeLink && (
          <FooterLinks
            showLoginLink={false}
            showJoinClassLink
            trackingIdPrefix={trackingIdPrefix}
          />
        )}
      </div>
    </div>
  );
}
function filterSelect(
  term: string,
  data: ReadonlyArray<{
    value: string;
  }>,
) {
  return data.filter(x => x.value.toLowerCase().includes(term.toLowerCase()));
}
function SelectFieldGroup({
  disabled,
  title,
  field,
  options,
  value,
  onChange,
  'data-test-id': dataTestId,
}: {
  disabled?: boolean;
  title: string;
  field: string;
  options: ReadonlyArray<{
    value: string;
  }>;
  value: string | null | undefined;
  onChange: (value: string) => void;
  'data-test-id'?: string;
}) {
  const [searchTerm, setSearchTerm] = React.useState('');
  return (
    <FieldGroup title={title}>
      <div className={css(formStyles.selectWrapper)}>
        <SearchableSelect
          isDisabled={disabled ?? false}
          dataSource={options}
          onOptionChosen={o => onChange(o.value)}
          value={value}
          searchTerm={searchTerm}
          filter={filterSelect}
          onChange={setSearchTerm}
          placeholder={value != null && value.length !== 0 ? value : field}
          forcePopoverPosition="bottom"
          data-test-id={dataTestId}
        />
      </div>
    </FieldGroup>
  );
}
function CountrySelectorContainer({
  initialCountry,
  onSubmit,
}: {
  initialCountry: Country | null | undefined;
  onSubmit: (country: Country) => void;
}) {
  const { props, error } = useQuery<SchoolFormSunflowerSignupQuery>(
    graphql`
      query SchoolFormSunflowerSignupQuery {
        availableCountries {
          id
          code
          name
        }
      }
    `,
    {},
  );
  if (error != null) {
    throw new InvariantViolation(
      `SchoolFormSunflowerSignupQuery failed: ${error.message}`,
    );
  }
  if (props == null)
    return (
      <SelectFieldGroup
        // Disabled prop prevents UI from flickering when you have currently
        // selected a country with subdivisions and you select another country
        // with subdivisions
        disabled
        title="Country"
        field="Country"
        onChange={noop}
        options={[]}
        value={null}
      />
    );
  if (props.availableCountries == null) {
    throw new InvariantViolation('Available countries not found');
  }
  const { availableCountries } = props;
  return (
    <CountrySelector
      availableCountries={availableCountries}
      initialCountry={initialCountry}
      onSubmit={onSubmit}
    />
  );
}
function CountrySelector({
  availableCountries,
  initialCountry,
  onSubmit,
}: {
  availableCountries: AvailableCountries;
  initialCountry: Country | null | undefined;
  onSubmit: (country: Country) => void;
}) {
  function handleRegionChange(value: string) {
    const region = availableCountries.find(c => c.name === value);
    if (region != null) {
      onSubmit({ id: region.id, name: region.name });
    }
  }
  // To ensure that the country initially set in state also has a name
  // attached, as the config location passed down from
  // msproblem/server/core/templatetags/react_app_tags.py:20 to ms-pages/Signup
  // only contains the country id
  useEffect(() => {
    const maybeCountry = availableCountries.find(
      c => c.id === initialCountry?.id,
    );
    if (maybeCountry != null) {
      handleRegionChange(maybeCountry.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <SelectFieldGroup
      title="Country"
      field="Country"
      onChange={value => {
        handleRegionChange(value);
      }}
      options={availableCountries.map(country => ({ value: country.name }))}
      value={initialCountry?.name}
      data-test-id="country-selector"
    />
  );
}
function SelectSubdivisionContainer({
  country,
  countrySubdivision,
  setHasSubdivisions,
  onSelect,
}: {
  country: Country;
  countrySubdivision: CountrySubdivision | null | undefined;
  setHasSubdivisions: (hasSubdivisions: boolean) => void;
  onSelect: (countrySubdivision: CountrySubdivision) => void;
}) {
  const { props, error } =
    useQuery<SchoolFormSunflowerSignupSelectSubdivisionQuery>(
      graphql`
        query SchoolFormSunflowerSignupSelectSubdivisionQuery($id: ID!) {
          country(id: $id) {
            subdivisions {
              id
              code
              name
            }
          }
        }
      `,
      {
        id: country.id,
      },
    );
  useEffect(() => {
    if (props?.country?.subdivisions)
      setHasSubdivisions(props.country.subdivisions.length !== 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  if (error != null) {
    throw new InvariantViolation(
      `SchoolFormSunflowerSignupSelectSubdivisionQuery failed: ${error.message}`,
    );
  }
  if (props == null)
    return (
      <SelectFieldGroup
        // Disabled prop prevents UI from flickering when you have currently
        // selected a country with subdivisions and you select another country
        // with subdivisions
        disabled
        title="Region"
        field="Select Region"
        onChange={noop}
        options={[]}
        value={null}
      />
    );
  if (props.country?.subdivisions == null) {
    throw new InvariantViolation(
      `Subdivisions not found for country ${country.name}`,
    );
  }
  const {
    country: { subdivisions },
  } = props;
  return (
    <SelectSubdivision
      country={country}
      countrySubdivision={countrySubdivision}
      subdivisions={subdivisions}
      onSelect={onSelect}
    />
  );
}
function SelectSubdivision({
  country,
  countrySubdivision,
  subdivisions,
  onSelect,
}: {
  country: Country;
  countrySubdivision: CountrySubdivision | null | undefined;
  subdivisions: SubdivisionsQueryResponse;
  onSelect: (countrySubdivision: CountrySubdivision) => void;
}) {
  if (subdivisions.length === 0) {
    return (
      <FieldGroup title="Region">
        <Input onChange={noop} value={country.name} disabled />
      </FieldGroup>
    );
  }
  return (
    <SelectFieldGroup
      disabled={!(subdivisions.length > 0)}
      title="Region"
      field="Select Region"
      onChange={value => {
        const region = subdivisions.find(s => s.name === value);
        if (region != null) onSelect({ id: region.id, name: region.name });
      }}
      options={subdivisions.map(subdivision => ({ value: subdivision.name }))}
      value={countrySubdivision?.name}
    />
  );
}
function SelectSchoolContainer({
  onSelect,
  countryId,
  subdivisionId,
  schoolTitle,
}: {
  onSelect: (school: School) => void;
  countryId: string;
  subdivisionId: string | null | undefined;
  schoolTitle: SchoolTitle;
}) {
  const { props, error } = useQuery<SchoolFormAvailableSchoolsSunflowerQuery>(
    graphql`
      query SchoolFormAvailableSchoolsSunflowerQuery(
        $countryId: ID!
        $subdivisionId: ID
      ) {
        availableSchoolReferences(
          country: $countryId
          countrySubdivision: $subdivisionId
        ) {
          id
          title
          suburb
          postalCode
        }
      }
    `,
    { countryId, subdivisionId },
  );
  if (props == null) {
    return (
      <SelectFieldGroup
        disabled
        title="School"
        field="Schools loading..."
        onChange={noop}
        options={[]}
        value={null}
      />
    );
  }
  if (error != null) {
    throw new InvariantViolation(
      `SchoolFormAvailableSchoolsSunflowerQuery failed: ${error.message}`,
    );
  }
  const { availableSchoolReferences } = props;
  return (
    <SelectSchool
      schoolTitle={schoolTitle}
      schools={availableSchoolReferences}
      onSelect={onSelect}
    />
  );
}
function SelectSchool({
  schoolTitle,
  schools,
  onSelect,
}: {
  schoolTitle: SchoolTitle;
  schools: Schools;
  onSelect: (school: School) => void;
}) {
  const [searchTerm, setSearchTerm] = React.useState('');
  return (
    <FieldGroup title="School">
      <div className={css(formStyles.selectWrapper)}>
        <SearchableSelect
          dataSource={schools
            .map(school => ({ value: school.title }))
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0,
            )
            .concat(searchTerm !== '' ? [{ value: `${searchTerm}` }] : [])}
          onOptionChosen={e => {
            const school = schools.find(s => s.title === e.value);
            if (school != null) {
              onSelect({ reference: school.id, title: school.title });
            } else if (searchTerm !== '') {
              onSelect({ reference: null, title: searchTerm });
            }
          }}
          // Let's call it special element hint and special element decorator
          specialElementDescription={
            searchTerm !== ''
              ? `Can't find my school, add ${searchTerm} below`
              : null
          }
          specialElementDecoration={searchTerm !== '' ? <PlusIcon /> : null}
          value={schoolTitle}
          searchTerm={searchTerm}
          filter={filterSelect}
          onChange={setSearchTerm}
          placeholder={
            schoolTitle != null && schoolTitle.length !== 0
              ? schoolTitle
              : 'Select School'
          }
          forcePopoverPosition="bottom"
        />
      </div>
    </FieldGroup>
  );
}
export { SchoolForm, SchoolFormPresentational };
