import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import { FeedbackComponent } from 'ms-components/Feedback/FeedbackComponent';
import JsxContent from 'ms-components/JsxContent';
import ContentLayout from 'ms-components/Layout/ContentLayout';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import useTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Lesson from 'ms-pages/Textbooks/components/SubtopicDetailView/Lesson';
import Questions from 'ms-pages/Textbooks/components/SubtopicDetailView/Questions';
import {
  validateLegacyTextbookSubtopicActiveTab,
  type LegacyTextbookSubtopicActiveTab,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Separator from 'ms-ui-primitives/Separator';
import { HStack, HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import { noop } from 'ms-utils/misc';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import ShareButton from './ShareButton';
import SunflowerSubtopicDetailHeader from './SunflowerSubtopicDetailHeader';
import SubtopicDetailViewTabs from './SunflowerSubtopicDetailViewTabs';
import type { SunflowerSubtopicDetailViewQuery } from './__generated__/SunflowerSubtopicDetailViewQuery.graphql';

const MainContentWrapper = styled({
  background: colors.white,
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const Wrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
});
const ContentWrapper = styled({
  // just a stopgap so that spinner/empty  don't render too close to the tabs
  // seems to give a bit of breath to the content too by the way
  marginTop: 4 * BASE_UNIT,
});
const ContentHeader = styled({
  marginTop: 6 * BASE_UNIT,
  marginBottom: 2 * BASE_UNIT,
});
const FeedbackWrapper = styled({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: 16,
  background: colors.seashell,
});
type Props = {
  subtopicId: string;
};
export default function SunflowerSubtopicDetailView({ subtopicId }: Props) {
  const [activeTab] = useQueryParam<LegacyTextbookSubtopicActiveTab>(
    'activeTab',
    'theory',
    validateLegacyTextbookSubtopicActiveTab,
  );
  const { trackStructEvent } = useSnowplow();
  const { schoolId } = useTeacherContext();
  const { role } = useViewer();
  const { width: windowWidth } = useWindowSize();
  const { props: data, error } = useQuery<SunflowerSubtopicDetailViewQuery>(
    graphql`
      query SunflowerSubtopicDetailViewQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          ...SunflowerSubtopicDetailHeaderSubtopic
          ...jsxPrintKebabLinks_subtopic
          title
          rslJsx {
            transpiledJsx
          }
          solidifyLessonJsx {
            transpiledJsx
          }
          worksheetJsx {
            id
            transpiledJsx
          }
          hasExercise
          hasWorksheet
          hasLessons
          hasInvestigations
        }
      }
    `,
    { subtopicId },
  );
  if (error != null) throw error;
  if (data == null) return <MinorSpinner />;
  const { subtopic } = data;
  if (subtopic == null) return <>Subtopic was not found</>;
  const {
    worksheetJsx,
    hasExercise,
    hasWorksheet,
    hasLessons,
    hasInvestigations,
    solidifyLessonJsx,
  } = subtopic;
  const worksheet = worksheetJsx.transpiledJsx;
  const isMobileView = windowWidth != null && windowWidth < breakPoints.small;
  return (
    <MainContentWrapper>
      <ContentLayout gutter={isMobileView ? 0 : 6 * BASE_UNIT}>
        <ContentLayout.Header>
          <SunflowerSubtopicDetailHeader subtopic={subtopic} />
        </ContentLayout.Header>
        <ContentLayout.Main>
          <Wrapper>
            <SubtopicDetailViewTabs
              hasLessons={hasLessons}
              hasInvestigations={hasInvestigations}
              hasEngageActivity={false}
              hasExercise={hasExercise}
              hasWorksheet={hasWorksheet}
              hasTeacherNote={false}
              onSelectTab={tab => {
                const sharedEventData = {
                  category: 'textbook',
                  label: schoolId ?? '',
                } as const;
                if (tab === 'theory') {
                  trackStructEvent({
                    ...sharedEventData,
                    action: 'selected_theory_tab',
                  });
                } else if (tab === 'interactive') {
                  trackStructEvent({
                    ...sharedEventData,
                    action: 'selected_question_tab',
                  });
                } else if (tab === 'worksheet') {
                  trackStructEvent({
                    ...sharedEventData,
                    action: 'selected_worksheet_tab',
                  });
                }
              }}
            />
            <ContentWrapper>
              <ContentHeader>
                <HStack center>
                  <HeadingS>{getContentHeader(activeTab)}</HeadingS>
                  <HSpacer width={24} grow />

                  {(activeTab === 'theory' || activeTab === 'worksheet') &&
                    role === 'Teacher' && (
                      <ShareButton
                        title={`Share ${getContentHeader(
                          activeTab,
                        ).toLowerCase()} link`}
                        eventToTrack={{
                          category: 'sunflower_teacher_textbook',
                          action: 'clicked_share',
                          label: activeTab,
                        }}
                      />
                    )}
                </HStack>
              </ContentHeader>
              {worksheet != null && activeTab === 'worksheet' && (
                <>
                  <Separator size={4} />
                  <JsxContent transpiledJsx={worksheet} />
                  <VSpacer height={40} />
                  <FeedbackWrapper>
                    <FeedbackComponent
                      feedbackType="WORKSHEET"
                      subtopicId={subtopicId}
                    />
                  </FeedbackWrapper>
                  <VSpacer height={85} />
                </>
              )}

              {activeTab === 'interactive' && (
                <>
                  <Questions subtopicId={subtopicId} onLoad={noop} />
                  <VSpacer height={40} />
                  <FeedbackWrapper>
                    <FeedbackComponent
                      feedbackType="PRACTICE_QUESTIONS"
                      subtopicId={subtopicId}
                    />
                  </FeedbackWrapper>
                  <VSpacer height={85} />
                </>
              )}
              {activeTab === 'theory' && (
                <>
                  {solidifyLessonJsx.transpiledJsx ? (
                    <>
                      <JsxContent
                        transpiledJsx={solidifyLessonJsx.transpiledJsx}
                      />
                      <VSpacer height={40} />
                      <FeedbackWrapper>
                        <FeedbackComponent
                          feedbackType="LESSON"
                          subtopicId={subtopicId}
                        />
                      </FeedbackWrapper>
                      <VSpacer height={85} />
                    </>
                  ) : (
                    <>
                      <Lesson subtopicId={subtopicId} onLoad={noop} />
                      <VSpacer height={40} />
                      <FeedbackWrapper>
                        <FeedbackComponent
                          feedbackType="LESSON"
                          subtopicId={subtopicId}
                        />
                      </FeedbackWrapper>
                      <VSpacer height={85} />
                    </>
                  )}
                </>
              )}
            </ContentWrapper>
          </Wrapper>
        </ContentLayout.Main>
      </ContentLayout>
    </MainContentWrapper>
  );
}
function getContentHeader(activeTab: LegacyTextbookSubtopicActiveTab) {
  switch (activeTab) {
    case 'interactive':
      return 'Questions';
    case 'engageActivity':
      return 'Engage Activity';
    case 'teacherNotes':
      return 'Teacher notes';
    case 'theory':
    case null:
      return 'Lesson';
    case 'worksheet':
      return 'Worksheet';
    default: {
      assertUnreachable(activeTab, `Unexpected active tab ${activeTab}`);
    }
  }
}
