import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

const path = (
  <path d="M14.4291 17.606H1.57192C0.980183 17.606 0.500488 17.1263 0.500488 16.5346C0.500488 12.9842 3.41321 10.5216 6.96362 10.5216H9.10647C12.6569 10.5216 15.5005 12.9842 15.5005 16.5346C15.5005 17.1263 15.0208 17.606 14.4291 17.606ZM8.00049 0.463135C5.63355 0.463135 3.71477 2.38191 3.71477 4.74885C3.71477 7.11578 5.63355 9.03456 8.00049 9.03456C10.3674 9.03456 12.2862 7.11578 12.2862 4.74885C12.2862 2.38191 10.3674 0.463135 8.00049 0.463135Z" />
);

export const User = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={18}
  >
    {path}
  </Icon>
);

export default User;
