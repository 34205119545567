import styled from '@emotion/styled';
import { useCallback } from 'react';

import CrossBoldIcon from 'ms-components/icons/CrossBold';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';

const BANNER_BACKGROUND_COLOR = colors.eggplant20;

const Root = styled.div({
  width: '100%',
  padding: 16,
  backgroundColor: BANNER_BACKGROUND_COLOR,
  textAlign: 'center',
  position: 'relative',
});

const CloseButtonWrapper = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
});

export default function FailedJoinClassAppOnSignUpBanner() {
  const {
    shouldShowFailedJoinClassAppOnSignUpBanner,
    hideFailedJoinClassAppOnSignUpBanner,
  } = useFailedJoinClassAppOnSignUpBanner();

  return (
    <>
      {shouldShowFailedJoinClassAppOnSignUpBanner && (
        <Root>
          <VStack>
            <BodyM>
              <Bold>Oh no, this class is already full!</Bold>
            </BodyM>

            <VSpacer height={4} />

            <BodyM>
              Speak to your teacher about joining. You can still get started
              with a discovery check-in.
            </BodyM>
          </VStack>

          <CloseButtonWrapper>
            <Button
              size="lanternSmall"
              onClick={hideFailedJoinClassAppOnSignUpBanner}
              color="grey90"
              label="Hide banner"
              isCircle
            >
              <CrossBoldIcon size={16} />
            </Button>
          </CloseButtonWrapper>
        </Root>
      )}
    </>
  );
}

export function useFailedJoinClassAppOnSignUpBanner() {
  const [
    shouldShowFailedJoinClassAppOnSignUpBanner,
    setShouldShowFailedJoinClassAppOnSignUpBanner,
  ] = useLocalSyncedState({
    dbKey: 'SHOULD_SHOW_FAILED_JOIN_CLASS_APP_ON_SIGN_UP_BANNER',
    fallbackValue: false,
  });

  const hideFailedJoinClassAppOnSignUpBanner = useCallback(() => {
    setShouldShowFailedJoinClassAppOnSignUpBanner(false);
  }, [setShouldShowFailedJoinClassAppOnSignUpBanner]);

  const showFailedJoinClassAppOnSignUpBanner = useCallback(() => {
    setShouldShowFailedJoinClassAppOnSignUpBanner(true);
  }, [setShouldShowFailedJoinClassAppOnSignUpBanner]);

  return {
    shouldShowFailedJoinClassAppOnSignUpBanner,
    showFailedJoinClassAppOnSignUpBanner,
    hideFailedJoinClassAppOnSignUpBanner,
  };
}
