import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M13.0507883,9.93707821 L14.2568769,8.93572831 C14.575568,8.6711364 15.0484123,8.71499244 15.3130042,9.03368351 C15.5775961,9.35237459 15.5337401,9.82521886 15.215049,10.0898108 L12.847534,12.05543 C12.5912159,12.268237 12.2252432,12.2864594 11.9490533,12.100167 L9.45993246,10.4212338 C9.11653385,10.1896085 9.02592367,9.72345952 9.25754895,9.38006091 C9.48917424,9.0366623 9.95532321,8.94605212 10.2987218,9.17767741 L11.5487611,10.0208396 C11.2701264,8.4406304 10.5040429,7.75 9.19451417,7.75 C7.68200434,7.75 6.85440457,9.04534528 6.74953635,12.0263676 C6.73497391,12.4403251 6.38758985,12.7640988 5.97363236,12.7495364 C5.55967486,12.7349739 5.2359012,12.3875899 5.25046365,11.9736324 C5.38011947,8.28798805 6.68219581,6.25 9.19451417,6.25 C11.3632603,6.25 12.6703233,7.55590408 13.0507883,9.93707821 Z M10.5,13 L14.5,13 C14.7761424,13 15,13.2238576 15,13.5 C15,13.7761424 14.7761424,14 14.5,14 L10.5,14 C10.2238576,14 10,13.7761424 10,13.5 C10,13.2238576 10.2238576,13 10.5,13 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M10,17 C13.8659932,17 17,13.8659932 17,10 C17,6.13400675 13.8659932,3 10,3 C6.13400675,3 3,6.13400675 3,10 C3,13.8659932 6.13400675,17 10,17 Z" />
);

const NextStepOutlined = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

NextStepOutlined.displayName = 'NextStepOutlined';

export default NextStepOutlined;
