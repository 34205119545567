import { map, unfold } from 'ramda';
import { graphql } from 'relay-runtime';

import { colors } from 'ms-styles/colors';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { BoxPlotSubstatus } from './__generated__/utilsBoxPlotSubstatus.graphql';
import type { Edge } from './index';
// TODO: Find a nicer pattern for acquiring Flow translations for GraphQL
// schema types.
// eslint-disable-next-line no-unused-expressions
graphql`
  fragment utilsBoxPlotSubstatus on BoxPlotValue {
    substatus
  }
`;
export type Substatus = BoxPlotSubstatus;
export const FILL_PRIMARY = colors.mako;
export const FILL_SECONDARY = colors.dustyGray;
export const FILL_UNKNOWN = colors.matisse;
export const FILL_CORRECT = colors.chateauGreen;
export const FILL_INCORRECT = colors.cinnabar;
export type Value = {
  min: {
    readonly value: number;
    readonly substatus: Substatus;
  };
  q1: {
    readonly value: number;
    readonly substatus: Substatus;
  };
  median: {
    readonly value: number;
    readonly substatus: Substatus;
  };
  q3: {
    readonly value: number;
    readonly substatus: Substatus;
  };
  max: {
    readonly value: number;
    readonly substatus: Substatus;
  };
};
export const range = (min: number, max: number, step: number) =>
  unfold(x => x <= max && [x, x + step], min);
export const scale = (x: number, scaleFactor: number, originValue: number) =>
  x * scaleFactor - originValue;
export const scaleEdge = (
  x: {
    value: number;
    substatus: Substatus;
  },
  scaleFactor: number,
  originValue: number,
) => ({ ...x, value: scale(x.value, scaleFactor, originValue) });
export const scaleTicks = (
  ticks: ReadonlyArray<number>,
  scaleFactor: number,
  originValue: number,
) => map(val => scale(val, scaleFactor, originValue), ticks);
export const getValue = (x: number, scaleFactor: number, originValue: number) =>
  (x + originValue) / scaleFactor;
export const getBoundingValue = (
  targetName: Edge,
  value: Value,
  axisMin: number,
  axisMax: number,
) => {
  switch (targetName) {
    case 'min':
      return { min: axisMin, max: value.q1.value };
    case 'q1':
      return { min: value.min.value, max: value.median.value };
    case 'median':
      return { min: value.q1.value, max: value.q3.value };
    case 'q3':
      return { min: value.median.value, max: value.max.value };
    case 'max':
      return { min: value.q3.value, max: axisMax };
    default:
      assertUnreachable(targetName);
  }
};
export const getSubStatusColor = (substatus: Substatus) => {
  switch (substatus) {
    case 'CORRECT':
      return FILL_CORRECT;
    case 'INCORRECT':
      return FILL_INCORRECT;
    case 'UNKNOWN':
      return FILL_UNKNOWN;
    default:
      return FILL_PRIMARY;
  }
};
export const getScaledPositions = (
  axisMin: number,
  axisMax: number,
  axisMajorTickInterval: number,
  axisMinorTickInterval: number,
  value: Value,
  width: number,
) => {
  const majorTicks = range(axisMin, axisMax, Math.abs(axisMajorTickInterval));
  const minorTicks = range(axisMin, axisMax, Math.abs(axisMinorTickInterval));
  // Convert our data values into svg coordinate space values
  const scaleFactor = width / (axisMax - axisMin);
  const originValue = axisMin * scaleFactor;
  return {
    min: scaleEdge(value.min, scaleFactor, originValue),
    q1: scaleEdge(value.q1, scaleFactor, originValue),
    median: scaleEdge(value.median, scaleFactor, originValue),
    q3: scaleEdge(value.q3, scaleFactor, originValue),
    max: scaleEdge(value.max, scaleFactor, originValue),
    majorTicks: scaleTicks(majorTicks, scaleFactor, originValue),
    minorTicks: scaleTicks(minorTicks, scaleFactor, originValue),
  };
};
export const getQuartileXCoord = (
  scaledQuartileValue: number,
  pixelAdjustment: number,
) => Math.round(scaledQuartileValue + pixelAdjustment);
