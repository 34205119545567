import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';
import { Fragment } from 'react';

import { fontFamily, lineHeight, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Checkbox from 'ms-ui-primitives/Checkbox';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Modal, { ModalBody, ModalFooter } from 'ms-ui-primitives/Modal';
import RadioButton from 'ms-ui-primitives/RadioButton';
import TextArea from 'ms-ui-primitives/TextArea';

import type { Options, OnOptionCheck, PublicIssueNumber } from './types';

const TITLE_MARGIN_BOTTOM = 25;
const DESCRIPTION_MARGIN_BOTTOM = 20;
const FIELD_GROUP_VERTICAL_MARGIN = 25;
const MESSAGE_VERTICAL_PADDING = 20;
const MESSAGE_LATERAL_PADDING = 60;
const MESSAGE_MIN_HEIGHT = 200;
const HELP_TEXT_VERTICAL_MARGIN = 10;
const HELP_TEXT_SPACING = 3;
const ERROR_MESSAGE_MARGIN_BOTTOM = 10;
const CLOSE_BUTTON_PADDING_TOP = 20;
const MAX_TOTAL_CHARACTERS = 2000;

const styles = StyleSheet.create({
  title: {
    fontFamily: fontFamily.body,
    fontSize: fontSize.xxLarge,
    fontWeight: fontWeight.semibold,
    color: colors.mako,
    marginBottom: TITLE_MARGIN_BOTTOM,
  },
  description: {
    fontFamily: fontFamily.body,
    color: colors.nevada,
    fontSize: fontSize.medium,
    marginBottom: DESCRIPTION_MARGIN_BOTTOM,
  },
  fieldGroup: {
    marginTop: FIELD_GROUP_VERTICAL_MARGIN,
    marginBottom: 0,
  },
  optionLabel: {
    fontSize: fontSize.medium,
    color: colors.nevada,
    alignItems: 'center',
  },
  message: {
    fontFamily: fontFamily.body,
    color: colors.dustyGray,
    minHeight: MESSAGE_MIN_HEIGHT,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  helpText: {
    fontSize: fontSize.medium,
    fontFamily: fontFamily.body,
    color: colors.mako,
    marginBottom: HELP_TEXT_VERTICAL_MARGIN,
    marginTop: HELP_TEXT_VERTICAL_MARGIN,
  },
  helpTextStrong: {
    fontWeight: fontWeight.semibold,
    marginRight: HELP_TEXT_SPACING,
  },
  messageParagraph: {
    paddingTop: MESSAGE_VERTICAL_PADDING,
    paddingBottom: MESSAGE_VERTICAL_PADDING,
    paddingLeft: MESSAGE_LATERAL_PADDING,
    paddingRight: MESSAGE_LATERAL_PADDING,
    lineHeight: lineHeight.body,
  },
  errorMessage: {
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
    marginBottom: ERROR_MESSAGE_MARGIN_BOTTOM,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  closeButtonContainer: {
    paddingTop: CLOSE_BUTTON_PADDING_TOP,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

type Props = {
  multiple: boolean | null | undefined;
  isOpen: boolean;
  onClose: () => void;
  options: Options;
  title: string;
  description: string;
  thankYouMessage: string | null | undefined;
  ctaButtonLabel: string;
  checkedOptions: ReadonlyArray<number>;
  onOptionCheck: OnOptionCheck;
  onMultipleSelectionOptionCheck: OnOptionCheck;
  freeTextValue: string;
  freeTextValueChange: (value: string) => void;
  onSend: () => void;
  isSubmitting: boolean;
  isSubmitted: boolean;
  firstName: string;
  canUseIntercom: boolean;
  createdPublicIssueNumber: PublicIssueNumber | null | undefined;
  showErrorMessage: boolean;
  isTextFeedbackRequired?: boolean | undefined;
};

type DescriptionProps = {
  children: ReactNode;
};

const Description = ({ children }: DescriptionProps) => (
  <div className={css(styles.description)}>{children}</div>
);

const openIntercomNewMessage = (issueId: number) => {
  if (window != null && window.Intercom != null)
    window.Intercom(
      'showNewMessage',
      `Feedback regarding the issue #${issueId}`,
    );
};

const FeedbackModal = ({
  isOpen,
  multiple,
  onClose,
  options,
  checkedOptions,
  onOptionCheck,
  onMultipleSelectionOptionCheck,
  freeTextValue,
  freeTextValueChange,
  onSend,
  isSubmitting,
  isSubmitted,
  firstName,
  canUseIntercom,
  createdPublicIssueNumber,
  showErrorMessage,
  title,
  description,
  ctaButtonLabel,
  thankYouMessage,
  isTextFeedbackRequired = false,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    closeOnOverlayTap={false}
    showCloseButton={false}
  >
    <ModalBody>
      {isSubmitted ? (
        <Fragment>
          <div className={css(styles.message)}>
            <div className={css(styles.messageParagraph)}>
              Thank you {firstName}!
            </div>

            <div className={css(styles.messageParagraph)}>
              {thankYouMessage}
            </div>

            <div className={css(styles.closeButtonContainer)}>
              <Button type="primary" onClick={onClose} size="large">
                Close
              </Button>
              {canUseIntercom && createdPublicIssueNumber != null && (
                <Button
                  type="primary"
                  onClick={() => {
                    openIntercomNewMessage(createdPublicIssueNumber);
                    onClose();
                  }}
                  size="large"
                >
                  Contact support about this issue
                </Button>
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={css(styles.title)}>{title}</div>
          <Description>{description}</Description>

          {options.map(({ key, label }, index) => (
            <FieldGroup key={key} aphroditeStyles={[styles.fieldGroup]}>
              {multiple ? (
                <Checkbox
                  onChange={onMultipleSelectionOptionCheck(index)}
                  checked={checkedOptions.includes(index)}
                  label={label}
                  aphroditeStyles={[styles.optionLabel]}
                  disabled={isSubmitting}
                />
              ) : (
                <RadioButton
                  onChange={onOptionCheck(index)}
                  checked={checkedOptions[0] === index}
                  label={label}
                  aphroditeStyles={[styles.optionLabel]}
                  disabled={isSubmitting}
                />
              )}
            </FieldGroup>
          ))}

          {checkedOptions.length > 0 && (
            <FieldGroup key="optional" aphroditeStyles={[styles.fieldGroup]}>
              <div className={css(styles.helpText)}>
                <span className={css(styles.helpTextStrong)}>
                  Tell us a little more
                </span>
                {!isTextFeedbackRequired && <span>(optional)</span>}
              </div>
              <TextArea
                autoFocus
                isMultiline
                autoResize
                minHeight={100}
                maxLength={MAX_TOTAL_CHARACTERS}
                value={freeTextValue}
                onChange={value => {
                  freeTextValueChange(value);
                }}
                disabled={isSubmitting}
              />
            </FieldGroup>
          )}
        </Fragment>
      )}
    </ModalBody>

    {!isSubmitted && (
      <ModalFooter>
        <div className={css(styles.column)}>
          {showErrorMessage && (
            <div className={css(styles.errorMessage)}>
              <div>Something went wrong when submitting your feedback.</div>
              <div>Please try again.</div>
            </div>
          )}
          <div className={css(styles.buttonsRow)}>
            {!isSubmitting && (
              <Button onClick={onClose} size="large">
                Cancel
              </Button>
            )}

            <div style={{ width: 10 }} />

            <Button
              size="large"
              isDisabled={
                checkedOptions.length === 0 ||
                isSubmitting ||
                (isTextFeedbackRequired && freeTextValue.length === 0)
              }
              type="primary"
              onClick={onSend}
            >
              {isSubmitting ? 'Submitting...' : ctaButtonLabel}
            </Button>
          </div>
        </div>
      </ModalFooter>
    )}
  </Modal>
);

export default FeedbackModal;
