import { graphql, useMutation } from 'relay-hooks';

import type { useCreateSubstrandGrowthCheckInMutation } from './__generated__/useCreateSubstrandGrowthCheckInMutation.graphql';

const USE_CREATE_SUBSTRAND_GROWTH_CHECK_IN_MUTATION = graphql`
  mutation useCreateSubstrandGrowthCheckInMutation($substrandId: ID!) {
    createSubstrandGrowthCheckIn(substrandId: $substrandId) {
      checkIn {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateSubstrandGrowthCheckIn() {
  return useMutation<useCreateSubstrandGrowthCheckInMutation>(
    USE_CREATE_SUBSTRAND_GROWTH_CHECK_IN_MUTATION,
  );
}
