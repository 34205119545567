import { useEffect } from 'react';

import { useAppEnv } from 'ms-helpers/AppEnv';
import { isReactNativeAppHost } from 'ms-helpers/AppEnv/utils';
import { InvariantViolation, Logger } from 'ms-utils/app-logging';
import { sendMessageToReactNative } from 'ms-utils/react-native-interface';
import type { ViewerDataChanged } from 'ms-utils/react-native-interface/webRnInterfaceTypes';

type Props = { data: ViewerDataChanged['viewer'] };
export default function SendViewerDataChangeToRN({ data }: Props) {
  const { appEnv } = useAppEnv();
  if (appEnv == null)
    Logger.error(
      new InvariantViolation(
        'Unable to send viewer data to RN. Viewer must have app env context',
      ),
    );
  useEffect(() => {
    if (isReactNativeAppHost(appEnv))
      sendMessageToReactNative(
        { type: 'viewerDataChanged', viewer: data },
        appEnv,
      );
  }, [appEnv, data]);
  return null;
}
