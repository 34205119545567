/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Leaderboard_student = {
    readonly id: string;
    readonly leaderboard?: {
        readonly currentWeek: ReadonlyArray<{
            readonly points: number;
            readonly rank: number;
            readonly name: string;
            readonly avatarUrl: string;
            readonly studentId: string;
        }>;
    } | null | undefined;
    readonly user: {
        readonly avatar: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly points: {
            readonly current: number;
        } | null;
    };
    readonly classes: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly hasEnabledLeaderboard: boolean;
                readonly displayName: string | null;
                readonly id: string;
                readonly title: string;
            };
        }>;
    };
    readonly " $refType": "Leaderboard_student";
};
export type Leaderboard_student$data = Leaderboard_student;
export type Leaderboard_student$key = {
    readonly " $data"?: Leaderboard_student$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"Leaderboard_student">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "classId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchLeaderboard"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "numberOfClasses"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "topN"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Leaderboard_student",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PointsObject",
          "kind": "LinkedField",
          "name": "points",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "current",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "numberOfClasses"
        }
      ],
      "concreteType": "ClassConnection",
      "kind": "LinkedField",
      "name": "classes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClassEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Class",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasEnabledLeaderboard",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "fetchLeaderboard",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Leaderboard",
          "kind": "LinkedField",
          "name": "leaderboard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "classId",
                  "variableName": "classId"
                },
                {
                  "kind": "Variable",
                  "name": "topN",
                  "variableName": "topN"
                }
              ],
              "concreteType": "CurrentWeekLeaderboardUser",
              "kind": "LinkedField",
              "name": "currentWeek",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "points",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rank",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatarUrl",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "studentId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Student",
  "abstractKey": null
};
})();
(node as any).hash = '7bb74d3fc65c3d2e3b401e93d4c98323';
export default node;
