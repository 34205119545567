import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

import { breakPoints, dimensions } from 'ms-styles/base';

type Props = {
  children: ReactNode;
};

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: dimensions.container.margin.default,
    marginRight: dimensions.container.margin.default,

    [`@media (min-width: ${breakPoints.small}px)`]: {
      marginLeft: dimensions.container.margin.small,
      marginRight: dimensions.container.margin.small,
    },

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      marginLeft: dimensions.container.margin.medium,
      marginRight: dimensions.container.margin.medium,
    },

    // Custom breakpoint for the Container layout
    [`@media (min-width: ${
      dimensions.container.maxWidth + 2 * dimensions.container.margin.medium
    }px)`]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: dimensions.container.maxWidth,
    },
  },
});

const Container = ({ children }: Props) => (
  <div className={css(styles.container)}>{children}</div>
);

export default Container;
