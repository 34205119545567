import moment from 'moment';

import type { StudentStrandStatusEnum } from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapCurriculum/__generated__/StudentSkillsMapCurriculumStrandStatus_studentStrandStatus.graphql';
import { assertUnreachable } from 'ms-utils/typescript-utils';

type Grade = {
  order: number;
};
type SubstrandInterface = {
  readonly gradeSubstrands: ReadonlyArray<{
    readonly gradeStrand: {
      readonly grade: {
        readonly order: number;
      };
    };
  }>;
};
// TODO: consider simplifying this method and types by getting
// the gradestrands ids
function getGradeSubstrands<Substrand extends SubstrandInterface>({
  substrand,
  grade,
}: {
  substrand: Substrand;
  grade: Grade;
}): Substrand['gradeSubstrands'] {
  return substrand.gradeSubstrands.filter(
    gs => gs.gradeStrand.grade.order <= grade.order,
  );
}
// If we have a substrand that has no grade substrand matching the current
// self-selected grade, the current grade substrand will be null and nothing
// will render.
function getCurrentGradeSubstrand<Substrand extends SubstrandInterface>({
  substrand,
  grade,
}: {
  substrand: Substrand;
  grade: Grade;
}): Substrand['gradeSubstrands'][number] | undefined {
  const currentGradeSubstrand = substrand.gradeSubstrands.find(
    gs => gs.gradeStrand.grade.order === grade.order,
  );
  return currentGradeSubstrand;
}
function getPriorGradeSubstrands<Substrand extends SubstrandInterface>({
  substrand,
  grade,
}: {
  substrand: Substrand;
  grade: Grade;
}): Substrand['gradeSubstrands'] {
  return substrand.gradeSubstrands.filter(
    gs => gs.gradeStrand.grade.order < grade.order,
  );
}
function isStrandLocked(
  studentStrandStatus: {
    readonly status: StudentStrandStatusEnum;
  },
  hasOptInDiscoveryCheckIns: boolean,
): boolean {
  if (hasOptInDiscoveryCheckIns) {
    return false;
  }
  switch (studentStrandStatus.status) {
    case 'DIAGNOSTIC': {
      return true;
    }
    case 'GROWTH': {
      return false;
    }
    default: {
      assertUnreachable(
        studentStrandStatus.status,
        `Unknown status: ${JSON.stringify(studentStrandStatus.status)}`,
      );
    }
  }
}
function getGrowthStartDate({
  startOfWeek,
  checkInCompletedAt,
}: {
  startOfWeek: string;
  checkInCompletedAt: string | null | undefined;
}): string | undefined {
  // If the student has completed the check-in after the start of the week,
  // then we should use the timestamp of when they completed the check-in rather than timestamp of the start of the week.
  // See https://user-images.githubusercontent.com/12092446/189035482-4babae2f-52eb-4023-b459-69b8fa558b3d.png
  // and https://github.com/mathspace/mathspace/pull/9997
  return checkInCompletedAt !== null &&
    moment(checkInCompletedAt).isAfter(startOfWeek)
    ? checkInCompletedAt
    : startOfWeek;
}
export {
  getGradeSubstrands,
  getCurrentGradeSubstrand,
  getPriorGradeSubstrands,
  isStrandLocked,
  getGrowthStartDate,
};
