/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type StudentSkillsMapCurriculumStrandStatus_studentStrandStatus = {
    readonly status: StudentStrandStatusEnum;
    readonly strand: {
        readonly id: string;
        readonly title: string;
    };
    readonly diagnosticQuestionsCompleted: number;
    readonly diagnosticNumberOfQuestions: number;
    readonly lastWorkedOnDiagnosticCheckIn: {
        readonly id: string;
    } | null;
    readonly " $refType": "StudentSkillsMapCurriculumStrandStatus_studentStrandStatus";
};
export type StudentSkillsMapCurriculumStrandStatus_studentStrandStatus$data = StudentSkillsMapCurriculumStrandStatus_studentStrandStatus;
export type StudentSkillsMapCurriculumStrandStatus_studentStrandStatus$key = {
    readonly " $data"?: StudentSkillsMapCurriculumStrandStatus_studentStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapCurriculumStrandStatus_studentStrandStatus">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapCurriculumStrandStatus_studentStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Strand",
      "kind": "LinkedField",
      "name": "strand",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticQuestionsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticNumberOfQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "lastWorkedOnDiagnosticCheckIn",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "StudentStrandStatus",
  "abstractKey": null
};
})();
(node as any).hash = '4881178ea894d6639061f0500121fceb';
export default node;
