/**
 * Theme styles for the /Work page.
 * The /Work page is currently replicating styles of the old solve page.
 * Because of this, it will be using certain styles that are not part of our
 * proper visual language. We want to document these, but we don't want to pollute
 * our proper theme definition file; hence the purpose of this file.
 */

export const fontSize = Object.freeze({
  actionMedium: 16,
  actionLarge: 22,
  headerTitle: 18,
  problemNumber: 20,
  tooltipTimestamp: 11,
  mathToolbar: 14,
  workoutModal: 24,
  workoutModalMedium: 30,
});

// Color names come from this tool: http://chir.ag/projects/name-that-color
export const colors = Object.freeze({
  doveGray: '#666666',
  gray: '#939393',
  aquaHaze: '#f6f8fa',
  pickledBluewood: '#34495e',
  supernova: '#FAC600',
  greenHaze: '#009D47',
  azureRadiance: '#0077E6',
  monza: '#DE1B0B',
});

// N.B. These z-index values have a counterpart in `ms-styles/base`.
export const zIndex = Object.freeze({
  answerInput: 1,
  subproblemHelp: 2, // beat problem attachment, beat answer input
  sketchpadCanvas: 3, // beat subproblemHelp
  controlPanel: 4, // beat MathQuill's cursor z-index, beat sketchpadCanvas
  problemHeader: 20,
  helpPanelWrapper: 31, // must beat problemHeader
  workoutHeaderWrapper: 30,
  problemSelector: 20,
});
