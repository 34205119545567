import type {
  ReactNode,
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
  FocusEvent as SyntheticFocusEvent,
} from 'react';

import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import {
  borderRadiusUI,
  fontFamily,
  transition as baseTransition,
  fontWeight,
  inputHeight,
} from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { useColorTheme, type SupportedColorName } from 'ms-styles/themes';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import { onPressOrHover, tappable } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';
import type { Values } from 'ms-utils/typescript-utils';

const noop = (): void => {};

export type ButtonSize =
  | 'tiny'
  | 'small'
  | 'medium'
  | 'regular'
  | 'large'
  | 'extraLarge'
  | 'lanternSmall'
  | 'lanternMedium';

export type ButtonStyleProps = {
  type?:
    | 'primary'
    | 'secondary'
    | 'secondary-deemphasized'
    | 'tertiary'
    | undefined;
  color?: SupportedColorName | 'brand' | undefined;
  size?: ButtonSize | undefined | null;
  isBlock?: boolean | undefined;
  isDisabled?: boolean | undefined;
  isRound?: boolean | undefined;
  isCircle?: boolean | undefined;
  isSquare?: boolean | undefined;
  isWide?: boolean | undefined;
  isNowrap?: boolean | undefined;
  styles?: {} | false | null | undefined;
  padding?: number | undefined;
  height?: number | 'auto' | undefined;
  width?: number | undefined;
  borderRadius?: number | undefined;
  isInline?: boolean | undefined;
  borderWidth?: number | undefined;
  fontWeight?: Values<typeof fontWeight> | undefined;
  transition?: number | string | undefined;
};

// Originally we wanted to model this as:
//
// | {
//   children: React.ReactElement<any> | Iterable<any>;
//   label: string;
// }
// | { children: string; label?: void };
//
// But this is causing lots of problems with the generated flow types
// so we're just going to go with the slightly less accurate type
// below.
type ContentProps = { children: ReactNode; label?: string | undefined };

export type CommonProps = ContentProps & {
  onClick?:
    | ((e: SyntheticEvent<HTMLElement>) => void | Promise<any>)
    | undefined;
  onMouseDown?: (e: ReactMouseEvent<any>) => void;
  onFocus?: ((e: SyntheticFocusEvent<HTMLElement>) => void) | undefined;
  onBlur?: ((e: SyntheticFocusEvent<HTMLElement>) => void) | undefined;
  'data-event-label'?: string | undefined;
  'data-test-id'?: string | undefined;
  // To distinguish the element for tracking in Posthog
  trackingId?: string | undefined;
};

export type Props = ButtonStyleProps & CommonProps;

export const sizeToFontSize: { [S in ButtonSize]: number } = {
  tiny: 14,
  small: 14,
  medium: 14,
  regular: 16,
  large: 18,
  extraLarge: 20,
  lanternSmall: 16,
  lanternMedium: 16,
};

export const sizeToHeight: { [S in ButtonSize]: number } = {
  tiny: 28,
  small: 32,
  medium: inputHeight,
  regular: inputHeight,
  large: 44,
  extraLarge: 64,
  lanternSmall: 42,
  lanternMedium: 54,
};

export const sizeToPadding: { [S in ButtonSize]: number } = {
  tiny: 8,
  small: 12,
  medium: 20,
  regular: 16,
  large: 32,
  extraLarge: 40,
  lanternSmall: 24,
  lanternMedium: 32,
};

export function useButtonStyle({
  size = 'medium',
  type,
  color = 'brand',
  isBlock,
  isDisabled,
  isRound = false,
  isCircle,
  isSquare = false,
  isNowrap = false,
  isWide = false,
  padding,
  height: _height,
  width,
  borderRadius,
  isInline = false,
  borderWidth,
  fontWeight: providedFontWeight,
  styles: otherButtonStyles,
  transition: providedTransition,
}: Props) {
  // Required because consumers are passing null to size prop
  size = size ?? 'medium';

  const { primary: primaryColorName } = useColorTheme();

  const viewer = useMaybeViewer();
  const { role } = viewer ?? {};

  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const colorName = color === 'brand' ? primaryColorName : color;
  const buttonColor = colors[colorName];
  const hoverColor = alternateColors[colorName];
  const buttonBorderColor = colors.iron;
  const buttonBorderHoverColor = alternateColors.iron;
  const buttonDeemphasizedBorderColor = colors.transparentWhite;
  const buttonDeemphasizedBorderHoverColor = alternateColors.transparentWhite;
  const textFontSize = sizeToFontSize[size];
  const height = _height ?? sizeToHeight[size];
  const paddingStyle = `0 ${padding ?? sizeToPadding[size]}px`;
  const transition = providedTransition ?? baseTransition;

  const baseStyle = {
    borderRadius: borderRadiusUI,
    boxSizing: 'border-box' as 'border-box',
    backgroundColor: type === 'primary' ? buttonColor : 'transparent',
    borderColor: buttonColor,
    color: buttonColor,
    ...onPressOrHover({
      color: hoverColor,
    }),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontFamily.body,
    fontSize: textFontSize,
    fontWeight: providedFontWeight ?? fontWeight.semibold,
    height,
    width,
    lineHeight: 1,
    transition: `background-color ${transition}, border-color ${transition}, color ${transition}`,
    WebkitFontSmoothing: 'subpixel-antialiased',
    padding: paddingStyle,
    textDecoration: 'none',
    borderStyle: 'none',
    ...tappable,
  };
  const accessibilityModeStyle = {
    ':focus': {
      boxShadow: `0 0 0 2px white, 0 0 0 4px ${colors.cloudBurst}`,
      outline: 'none',
    },
  };
  const secondaryButtonBorderStyle = {
    borderWidth: borderWidth ?? 1,
    borderStyle: 'solid',
    borderColor: buttonBorderColor,
    ...onPressOrHover({
      borderColor: buttonBorderHoverColor,
      color: hoverColor,
    }),
  };

  const deemphasizedSecondaryButtonBorderStyle = {
    borderWidth: borderWidth ?? 1,
    borderStyle: 'solid',
    borderColor: buttonDeemphasizedBorderColor,
    ...onPressOrHover({
      borderColor: buttonDeemphasizedBorderHoverColor,
      color: hoverColor,
    }),
  };
  const filledStyle = {
    backgroundColor: buttonColor,
    color: colors.white,
    ...onPressOrHover({
      backgroundColor: hoverColor,
      borderColor: hoverColor,
      color: colors.white,
    }),
  };

  const isBlockStyle = {
    width: '100%',
  };
  const disabledStyle = {
    color: colors.grayChateau,
    backgroundColor: type === 'primary' ? colors.iron : 'transparent',
    borderColor: colors.grayChateau,
    cursor: 'default',
    pointerEvents: 'none' as 'none',
  };
  const roundStyle = {
    borderRadius: typeof height === 'number' ? height / 2 : undefined,
  };
  const isCircleStyle = {
    borderRadius: '50%',
    borderWidth: borderWidth ?? 2,
    width: height,
    height,
    padding: 0,
    fontSize: typeof height === 'number' ? height - 14 : textFontSize,
  };
  const isWideStyle = {
    paddingLeft: sizeToPadding[size] * 2,
    paddingRight: sizeToPadding[size] * 2,
  };
  const isSquareStyle = {
    width: height,
    padding: 0,
    fontSize: typeof height === 'number' ? height - 20 : textFontSize,
  };
  const isInlineStyle = {
    height: 'auto',
    display: 'inline',
    padding: 0,
  };

  return {
    ...baseStyle,
    ...(type === 'primary' ? filledStyle : {}),
    ...(type === 'secondary' ? secondaryButtonBorderStyle : {}),
    ...(type === 'secondary-deemphasized'
      ? deemphasizedSecondaryButtonBorderStyle
      : {}),
    ...(!!isBlock ? isBlockStyle : {}),
    ...(!!isDisabled ? disabledStyle : {}),
    ...(!!isRound ? roundStyle : {}),
    ...(role === 'Student' ? roundStyle : {}), // all buttons for student UI are rounded
    // NB this depends on the order
    ...(!!isCircle ? isCircleStyle : {}),
    ...(!!isWide ? isWideStyle : {}),
    ...(!!isSquare ? isSquareStyle : {}),
    ...(!!isInline ? isInlineStyle : {}),
    ...(!!isNowrap
      ? { whiteSpace: 'nowrap' as 'nowrap' }
      : { whiteSpace: undefined }),
    ...(hasEnabledAccessibilityMode
      ? accessibilityModeStyle
      : { focus: undefined }),

    // allow to override border radius
    ...(!!borderRadius ? { borderRadius } : {}),
    ...(typeof otherButtonStyles === 'object' ? otherButtonStyles : {}),
  };
}

export function useCommonProps({
  isDisabled,
  onClick,
  onMouseDown,
  'data-test-id': dataTestId,
  'data-event-label': dataEventLabel,
  trackingId,
  label,
  children,
}: Props) {
  return {
    onClick: isDisabled ? noop : onClick,
    onMouseDown: isDisabled ? noop : onMouseDown,
    'data-event-label': dataEventLabel,
    'data-test-id': dataTestId,
    'data-tracking-id': trackingId,
    'aria-disabled': isDisabled,
    'aria-label':
      typeof children === 'string'
        ? typeof label === 'string'
          ? label
          : children
        : label ?? undefined,
  };
}

export default function Button(props: Props) {
  const cssStyles = useButtonStyle(props);
  const commonProps = useCommonProps(props);
  const { children, onClick } = props;

  return (
    <button
      {...commonProps}
      disabled={props.isDisabled}
      // This is required as consumers are passing null to onClick
      onClick={onClick ?? undefined}
      css={cssStyles}
      onKeyDown={keyDownMap({
        SPACE: e => {
          e.stopPropagation();
        },
        ENTER: e => {
          e.stopPropagation();
        },
      })}
    >
      {children}
    </button>
  );
}
