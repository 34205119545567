import DifficultyBase from 'ms-components/icons/DifficultyEasy/DifficultyBase';
import type { Props as DifficultyIconBaseProps } from 'ms-components/icons/DifficultyEasy/DifficultyBase';

const DifficultyMedium = ({
  color,
  ...otherProps
}: DifficultyIconBaseProps) => (
  <DifficultyBase color={color} difficulty="MEDIUM" {...otherProps} />
);

export default DifficultyMedium;
