import styled from '@emotion/styled';
import dayjs from 'dayjs';
import moment from 'moment';
import { type ReactNode, useEffect, useState } from 'react';

import type { ViewerPayload } from 'ms-helpers/Viewer';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { getRelativeTime as getRelativeTimeLong } from 'ms-pages/Lantern/utils/time';
import { getRelativeTime as getRelativeTimeShort } from 'ms-pages/Teacher/utils/time';

import { useTimeUpdateManager } from '../TimeContext';

type CountryCode = NonNullable<ViewerPayload['countryCode']>;
type FullFormat = Record<CountryCode, string>;

const DEFAULT_FULL_FORMAT: FullFormat = {
  US: 'MM/DD/YYYY hh:mm:ss A',
  AU: 'DD/MM/YYYY hh:mm:ss A',
};

type InputDate = dayjs.ConfigType | moment.Moment;

type Props = {
  date: InputDate | null | undefined;
  nullValue?: ReactNode;
  /**
   * Which format to use. Defaults to "long".
   * Short: '<1min ago', '1min ago', '1h ago', '1d ago', '1mo ago', '1y ago', etc.
   * Long: 'less than 1 min ago', '1 min ago', '1 hour ago', '1 day ago', '1 month ago', '1 year ago', etc.
   */
  format?: 'short' | 'long' | undefined;
  /**
   * The full format strings to use for the title text for each supported country code.
   * Refer to https://day.js.org/docs/en/display/format for the syntax.
   * Defaults to { US: 'MM/DD/YYYY hh:mm:ss A', AU: 'DD/MM/YYYY hh:mm:ss A' }
   */
  fullFormat?: FullFormat;
  /**
   * When true (which is the default), will automatically update the text as needed,
   * but in a very efficient manner. Relies on there being a <TimeUpdater> component
   * somewhere in the component tree.
   */
  autoRefresh?: boolean;
  /**
   * When true, times less than 1 minute from now will show the number of seconds (up to 44).
   * Otherwise, it will show "less than 1 min" or "<1min" for short format.
   * Defaults to false, because we rarely want to see the time update every second.
   */
  showSeconds?: boolean | undefined;
  /**
   * When true, the duration will be shown as an absolute value, e.g. "1 minute" instead of "1 minute ago".
   */
  withoutSuffix?: boolean | undefined;
};

const getValue = ({
  date,
  format,
  now,
  nullValue,
  showSeconds,
  withoutSuffix,
}: Pick<
  Props,
  'date' | 'nullValue' | 'format' | 'showSeconds' | 'withoutSuffix'
> & {
  now: dayjs.Dayjs;
}): ReactNode => {
  if (date == null) {
    return nullValue;
  }
  const dt = dayjs(moment.isMoment(date) ? date.toDate() : date);
  if (!dt.isValid()) {
    return nullValue;
  }
  return getRelativeTimestampString({
    date: dt,
    format,
    now,
    showSeconds,
    withoutSuffix,
  });
};

export function getRelativeTimestampString({
  date,
  format,
  now,
  showSeconds,
  withoutSuffix,
}: Pick<Props, 'format' | 'showSeconds' | 'withoutSuffix'> & {
  now: dayjs.Dayjs;
  date: dayjs.Dayjs;
}) {
  return format === 'short'
    ? getRelativeTimeShort(
        date.toISOString(),
        now.toDate(),
        showSeconds,
        withoutSuffix,
      )
    : getRelativeTimeLong(date, now, showSeconds, withoutSuffix);
}

const Time = styled.time({
  textDecoration: 'none',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  ':hover': {
    textDecoration: 'underline dotted',
    cursor: 'help',
  },
});

export default function RelativeTimestamp({
  autoRefresh = true,
  date,
  format = 'long',
  fullFormat = DEFAULT_FULL_FORMAT,
  nullValue = '-',
  withoutSuffix,
}: Props) {
  const [relativeTime, setRelativeTime] = useState(
    getValue({ date, now: dayjs(), nullValue, format, withoutSuffix }),
  );
  const timeUpdateManager = useTimeUpdateManager();
  const { countryCode } = useMaybeViewer() ?? {};

  useEffect(() => {
    if (!autoRefresh) return;
    return timeUpdateManager.subscribe(now => {
      const newRelativeTime = getValue({ date, now, nullValue, format });
      if (newRelativeTime !== relativeTime) {
        setRelativeTime(newRelativeTime);
      }
    });
  }, [autoRefresh, date, format, nullValue, relativeTime, timeUpdateManager]);

  if (relativeTime === nullValue) {
    return relativeTime;
  }
  const fullFormatStr =
    (countryCode && fullFormat[countryCode]) || fullFormat.AU;
  const dayjsDate = dayjs(
    (moment.isMoment(date) ? date.toDate() : date) ?? undefined,
  );
  return (
    <Time
      dateTime={dayjsDate.toISOString()}
      title={dayjsDate.format(fullFormatStr)}
    >
      {relativeTime}
    </Time>
  );
}
