import { Suspense } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import ProblemItem from 'ms-pages/Textbooks/components/ProblemItem/index';
import type { Props as ProblemItemProps } from 'ms-pages/Textbooks/components/ProblemItem/index';
import Center from 'ms-ui-primitives/Center';

import type { ProblemItemStandaloneQuery } from './__generated__/ProblemItemStandaloneQuery.graphql';

type Props = Pick<
  ProblemItemProps,
  | 'showTryProblem'
  | 'index'
  | 'dragHandleProps'
  | 'collapsedInBulk'
  | 'onDuplicateProblem'
  | 'onRemoveProblem'
> & {
  problemContentId: string;
};

export default function ProblemItemStandalone(props: Props) {
  return (
    <ErrorBoundaryWithRetry
      fallback={({ error, retry }) => (
        <Retry retry={retry} message={error.message} />
      )}
      name="ProblemItemStandalone"
    >
      {({ fetchKey }) => (
        <Suspense
          fallback={
            <Center>
              <MinorSpinner />
            </Center>
          }
        >
          <ProblemItemStandaloneInner {...props} fetchKey={fetchKey} />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
}

function ProblemItemStandaloneInner({
  showTryProblem,
  problemContentId,
  index,
  dragHandleProps,
  collapsedInBulk,
  onDuplicateProblem,
  onRemoveProblem,
  fetchKey,
}: Props & { fetchKey: number }) {
  const data = useLazyLoadQuery<ProblemItemStandaloneQuery>(
    graphql`
      query ProblemItemStandaloneQuery($ids: [ID!]!) {
        problemContents(ids: $ids) {
          id
          ...ProblemItem_problemContent
        }
      }
    `,
    { ids: [problemContentId] },
    {
      fetchKey,
      fetchPolicy: 'store-and-network',
    },
  );

  const problemContent = data.problemContents[0];

  if (problemContent == null) {
    throw new Error(
      `ProblemItemStandaloneQuery failed: problemContent is null for ${problemContentId}`,
    );
  }

  return (
    <ProblemItem
      showTryProblem={showTryProblem}
      problemContent={problemContent}
      index={index}
      dragHandleProps={dragHandleProps}
      collapsedInBulk={collapsedInBulk}
      onDuplicateProblem={onDuplicateProblem}
      onRemoveProblem={onRemoveProblem}
      structEventToTrackOnAddToCollection={null}
    />
  );
}
