import * as latex from 'ms-utils/latex';
import type { AstNode } from 'ms-utils/latex';

import Fraction from './Fraction';
import Text from './Text';

function getWidth(node: AstNode, size: number) {
  if (node.type === 'frac') {
    const characterWidth = Math.max(
      node.denominator.value.length,
      node.numerator.value.length,
    );
    return size * characterWidth * 0.375;
  }
  // assume type is text
  return size * node.value.length * 0.375;
}

type Props = {
  children?: string | undefined;
  size?: number | undefined;
  x?: number | undefined;
  y: number;
};

/**
 * THIS COMPONENT IS A STUB!
 *
 * This component is intended to provide a masking layer for code Latex rendering in SVG.  We
 * anticipate replacing the underlying rendering engine with something more robust in the future.
 *
 * Do not use it for any general purpose latex rendering at this time.  It can currently render
 * strings containing numbers and fractions (\\frac{}{}).  Nothing more.
 */
const LatexSvg = ({ children = '', size = 16, x = 0, y }: Props) => {
  const ast = latex.parse(latex.lex(children));

  /** BEGIN Manual Text Reflow */
  const spacing = size / 5;
  const widths = ast.map(node => getWidth(node, size));
  const totalWidth =
    widths.reduce((total, width) => total + width, 0) + spacing * ast.length;
  const positions: number[] = [];
  const leftEdge = -totalWidth / 2;
  widths.forEach((width, i) => {
    const prevWidths = widths.reduce(
      (total, prevWidth, j) => (j < i ? total + prevWidth : total),
      0,
    );
    const position = leftEdge + width / 2 + prevWidths + spacing * i;
    positions.push(position);
  });
  /** END Manual Text Reflow */

  return (
    <g transform={`translate(${x} ${y})`}>
      {ast.map((node, i) => {
        if (node.type === 'frac') {
          return (
            <Fraction
              key={i}
              denominator={node.denominator.value}
              numerator={node.numerator.value}
              size={size}
              x={positions[i]}
            />
          );
        }
        return <Text key={i} value={node.value} size={size} x={positions[i]} />;
      })}
    </g>
  );
};

export default LatexSvg;
