import { useId } from 'react';

import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const PlayingSoundMuted = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => {
  const maskId = useId();

  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxWidth={24}
      viewBoxHeight={24}
    >
      <defs>
        <mask id={maskId}>
          <rect width="24" height="24" fill="white" />
          <line
            x1="4"
            y1="18"
            x2="16"
            y2="6"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </mask>
      </defs>

      {/* speaker */}
      <g>
        <path
          d="M11.38,4.08a1,1,0,0,0-1.09.21L6.59,8H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H6.59l3.7,3.71A1,1,0,0,0,11,20a.84.84,0,0,0,.38-.08A1,1,0,0,0,12,19V5A1,1,0,0,0,11.38,4.08Z"
          fill="currentColor"
          mask={`url(#${maskId})`}
        />
      </g>

      {/* barring line */}
      <line
        x1="4"
        y1="18"
        x2="16"
        y2="6"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      {/* sound waves */}

      <g transform="translate(2)">
        <path d="M18.36,19.36a1,1,0,0,1-.7-.29,1,1,0,0,1,0-1.41,8,8,0,0,0,0-11.32,1,1,0,0,1,1.41-1.41,10,10,0,0,1,0,14.14A1,1,0,0,1,18.36,19.36Z">
          <animate
            attributeName="opacity"
            values="1;0;1"
            dur="2s"
            repeatCount="3"
          />
        </path>
        <path
          d="M15.54,16.54a1,1,0,0,1-.71-.3,1,1,0,0,1,0-1.41,4,4,0,0,0,0-5.66,1,1,0,0,1,1.41-1.41,6,6,0,0,1,0,8.48A1,1,0,0,1,15.54,16.54Z"
          fill="currentColor"
        >
          <animate
            attributeName="opacity"
            values="1;0;1"
            dur="2s"
            repeatCount="3"
          />
        </path>
      </g>
    </Icon>
  );
};

PlayingSoundMuted.displayName = 'PlayingSoundMuted';

export default PlayingSoundMuted;
