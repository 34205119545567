import { useState, useRef, useCallback, useContext, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import CaptureEnter from 'ms-components/CaptureEnter';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { ModalFooter } from 'ms-pages/Sunflower/ui-primitives/Modal';
import useTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import AccordionControl from 'ms-pages/Teacher/components/ClassCreateEdit/components/AccordionControl';
import ErrorCard from 'ms-pages/Teacher/components/ErrorCard';
import FormErrorMessage from 'ms-pages/Teacher/components/FormErrorMessage';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Separator from 'ms-pages/Teacher/components/Separator';
import DuplicateStudents from 'ms-pages/Teacher/components/StudentCreateEdit/components/DuplicateStudents';
import StudentYearLevel from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudenYearLevel';
import { StudentConfirmation } from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentConfirmation';
import type { Props as StudentConfirmationProps } from 'ms-pages/Teacher/components/StudentCreateEdit/components/StudentConfirmation';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { InvariantViolation, Logger } from 'ms-utils/app-logging';
import { styled } from 'ms-utils/emotion';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import extractNode from 'ms-utils/relay/extractNode';
import { extractResponseErrorMessages } from 'ms-utils/relay/extractResponseErrorMessages';

import type {
  TeacherCreateStudentQuery,
  TeacherCreateStudentQueryResponse,
} from './__generated__/TeacherCreateStudentQuery.graphql';
import useCreateStudent from './createStudent';
import StudentClasses from '../components/StudentClasses';
import StudentEmail from '../components/StudentEmail';
import StudentFirstName from '../components/StudentFirstName';
import StudentLastName from '../components/StudentLastName';
import StudentParentEmail from '../components/StudentParentEmail';
import StudentTextbookFocus from '../components/StudentTextbookFocus';
import { StateContext, StateProvider, UpdatersContext } from '../state';
import type {
  Classes,
  SyllabusFocusSelectedClass,
  GradeSelectedClass,
} from '../state/State';
import { EMPTY_ITEM } from '../state/State';
import { Loading, Root } from '../styles';
import { mapItemsToIds } from '../utils';

const query = graphql`
  query TeacherCreateStudentQuery(
    $schoolId: ID!
    $numberOfSyllabuses: Int!
    $numberOfClasses: Int!
  ) {
    school(id: $schoolId) {
      availableSyllabuses(limit: $numberOfSyllabuses) {
        id
        title
      }
      lanternCurriculum {
        grades {
          id
          title
        }
      }
      classes(first: $numberOfClasses) {
        edges {
          node {
            id
            title
            classGrade {
              id
              title
            }
            classSyllabusFocus {
              id
              title
            }
          }
        }
      }
    }
  }
`;
const StudentName = styled({
  display: 'flex',
  alignItems: 'flex-start',
});
const StudentNameInput = styled({
  flex: 1,
});
const AccordionContainer = styled({
  backgroundColor: colors.seashell,
  marginBottom: 6 * BASE_UNIT,
});
const AccordionWrapper = styled({
  default: {
    display: 'block',
    padding: `0px ${4 * BASE_UNIT}px ${BASE_UNIT}px`,
  },
  hidden: {
    display: 'none',
  },
});
type NewStudent = StudentConfirmationProps['student'];
type Props = {
  data: TeacherCreateStudentQueryResponse;
  onStudentCreated: () => void;
  onCancel: () => void;
};
function TeacherCreateStudent({ data, onCancel, onStudentCreated }: Props) {
  const { schoolId } = useTeacherContext();
  const {
    featureFlags: { classicUi },
  } = useViewer();
  const { trackStructEvent } = useSnowplow();
  const {
    firstName,
    lastName,
    classes,
    syllabusFocus,
    selfReportedGradeId,
    email,
    guardianEmails,
    shouldCheckForDuplicates,
    buttonsDisabled,
  } = useContext(StateContext);
  const {
    updateFirstName,
    updateLastName,
    reset,
    checkForDuplicates,
    enableButtons,
    disableButtons,
  } = useContext(UpdatersContext);
  const [studentAddedToClass, setStudentAddedToClass] =
    useState<NewStudent | null>(null);
  const firstNameRef = useRef<HTMLInputElement>(null);
  const [createStudentMutation, { response, loading, errors }] =
    useCreateStudent({
      schoolId,
      firstName,
      lastName,
      // TODO remove useless spread once we upgrade relay. It's only needed
      // because the 3rd party TS type generation emits mutable arrays for
      // mutation inputs erroneously
      classIds: [...mapItemsToIds(classes)],
      syllabusFocusId: syllabusFocus.id,
      email: email !== '' ? email : null,
      guardianEmails: guardianEmails
        .split(',')
        .map(g => g.trim())
        .filter(g => g !== ''),
      parentNotifications: true,
      isSunflowerUi: !classicUi,
      gradeId: selfReportedGradeId,
    });
  const [accordionOpen, setAccordionOpen] = useState(false);
  const createdStudent = response?.createStudent.student;
  const responseErrors = response?.createStudent.errors;
  const errorCount = responseErrors?.length;
  // post submit effects. This effect runs only after the mutation is fired
  useEffect(() => {
    if ((errorCount != null && errorCount > 0) || errors != null) {
      enableButtons();
      if (firstNameRef.current != null) firstNameRef.current.focus();
    }
    if (createdStudent != null) {
      // we immediately reset the form to prepare for new data entry
      reset();
      setStudentAddedToClass(createdStudent);
      if (firstNameRef.current != null) firstNameRef.current.focus();
    }
  }, [createdStudent, enableButtons, errorCount, errors, reset]);
  // Unlike the one above, this effect is idemopotent.
  // It must be separated from the one above that calls `setStudentAddedToClass`
  // so we can correctly display either the last user created or
  // the last user added to class.
  useEffect(() => {
    if (createdStudent != null) {
      onStudentCreated();
    }
  }, [createdStudent, onStudentCreated]);
  const submit = useCallback(() => {
    disableButtons();
    createStudentMutation();
    setStudentAddedToClass(null);
    trackStructEvent({
      category: 'teacher_admin',
      action: 'clicked_create_student',
    });
  }, [createStudentMutation, disableButtons, trackStructEvent]);
  if (data.school == null) return null;
  if (data.school.availableSyllabuses == null) return null;
  if (data.school.classes == null) return null;
  const { availableSyllabuses, lanternCurriculum } = data.school;
  const schoolClasses = extractNode(data.school.classes);
  const availableGrades = lanternCurriculum?.grades ?? [];
  const schoolClassesWithGrade = schoolClasses.filter(
    klass => klass.classGrade != null,
  );
  return (
    <Root>
      <CaptureEnter isDisabled={buttonsDisabled} action={submit}>
        {loading && <Loading>Submitting...</Loading>}
        {studentAddedToClass != null && !loading && (
          <StudentConfirmation
            student={studentAddedToClass}
            classes={classes}
          />
        )}
        {errors != null && (
          <>
            <FormErrorMessage>
              Oops, there was an error. Please try again.
            </FormErrorMessage>
            <Separator size={4 * BASE_UNIT} />
          </>
        )}
        <StudentName>
          <StudentNameInput>
            <StudentFirstName
              ref={firstNameRef}
              errorMessages={extractResponseErrorMessages(responseErrors, [
                'INVALID_FIRST_NAME_LENGTH',
              ])}
              onBlur={checkForDuplicates}
              onChange={updateFirstName}
            />
          </StudentNameInput>
          <Separator size={4 * BASE_UNIT} />
          <StudentNameInput>
            <StudentLastName
              errorMessages={extractResponseErrorMessages(responseErrors, [
                'INVALID_LAST_NAME_LENGTH',
              ])}
              onBlur={checkForDuplicates}
              onChange={updateLastName}
            />
          </StudentNameInput>
        </StudentName>
        <StudentEmail
          errorMessages={extractResponseErrorMessages(responseErrors, [
            'INVALID_EMAIL_LENGTH',
            'INVALID_EMAIL',
            'DUPLICATE_EMAIL',
          ])}
        />
        <StudentClasses
          schoolClasses={schoolClasses}
          requireStudentInClass
          errorMessages={extractResponseErrorMessages(responseErrors, [
            'CLASS_IN_DIFFERENT_SCHOOL',
          ])}
        />

        <StudentYearLevel
          schoolClasses={schoolClassesWithGrade}
          grades={availableGrades}
          errorMessages={extractResponseErrorMessages(responseErrors, [
            'INVALID_STUDENT',
            'INVALID_GRADE',
          ])}
        />

        <AccordionContainer>
          <AccordionControl
            isOpen={accordionOpen}
            label="Advanced options"
            onToggle={() => setAccordionOpen(accordionOpen => !accordionOpen)}
          />
          <AccordionWrapper hidden={!accordionOpen}>
            <StudentTextbookFocus
              schoolClasses={schoolClasses}
              textbooks={availableSyllabuses}
              errorMessages={extractResponseErrorMessages(responseErrors, [
                'INVALID_SYLLABUS',
              ])}
            />

            <StudentParentEmail
              errorMessages={extractResponseErrorMessages(responseErrors, [
                'INVALID_PARENT_EMAIL_LENGTH',
                'INVALID_PARENT_EMAIL',
              ])}
            />
          </AccordionWrapper>
        </AccordionContainer>
        {/* This will not render when you change the input but haven't blurred it yet */}
        {shouldCheckForDuplicates && (
          <DuplicateStudents
            firstName={firstName}
            lastName={lastName}
            schoolId={schoolId}
            onSubmitted={addedStudent => {
              reset();
              setStudentAddedToClass(addedStudent);
              if (firstNameRef.current != null) firstNameRef.current.focus();
            }}
            disableButtons={buttonsDisabled}
            onSubmit={() => {
              setStudentAddedToClass(null);
              disableButtons();
            }}
          />
        )}
        <ModalFooter>
          <Button type="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" isDisabled={buttonsDisabled} onClick={submit}>
            Create student
          </Button>
        </ModalFooter>
      </CaptureEnter>
    </Root>
  );
}
type ExternalProps = {
  initialClasses: Classes;
  initialSyllabusFocusSelectedClass: SyllabusFocusSelectedClass;
  initialGradeSelectedClass: GradeSelectedClass;
  onStudentCreated: () => void;
  onCancel: () => void;
};
export default function TeacherCreateStudentWrapper({
  initialClasses,
  initialSyllabusFocusSelectedClass,
  initialGradeSelectedClass,
  onCancel,
  onStudentCreated,
}: ExternalProps) {
  const { schoolId } = useTeacherContext();
  const { props, error } = useQuery<TeacherCreateStudentQuery>(query, {
    schoolId,
    numberOfSyllabuses: RELAY_CONNECTION_MAX,
    numberOfClasses: RELAY_CONNECTION_MAX,
  });
  if (error != null) return <ErrorCard />;
  if (props == null) return <MinorSpinner />;
  const classes = props.school?.classes;
  if (classes == null) {
    Logger.error(new InvariantViolation('Access is restricted'));
    return null;
  }
  const klass = classes.edges.find(
    c => c.node.id === initialSyllabusFocusSelectedClass.id,
  );
  return (
    <StateProvider
      classes={initialClasses}
      syllabusFocusSelectedClass={initialSyllabusFocusSelectedClass}
      gradeSelectedClass={initialGradeSelectedClass}
      syllabusFocus={klass != null ? klass.node.classSyllabusFocus : null}
      selfReportedGradeId={klass?.node.classGrade?.id ?? EMPTY_ITEM.id}
    >
      <TeacherCreateStudent
        data={props}
        onCancel={onCancel}
        onStudentCreated={onStudentCreated}
      />
    </StateProvider>
  );
}
