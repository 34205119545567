import { useRef, useEffect } from 'react';

export function useUnmountEffect(effect: () => void) {
  // We store a reference to the effect after every commit so that
  // any bindings the effect closes over are current. This ensures
  // that if this ends up being the effect invoked in the unmount
  // effect, the effect will consume the state of the component
  // immediately prior to unmounting.
  const effectRef = useRef(effect);
  useEffect(() => {
    effectRef.current = effect;
  });
  useEffect(() => () => effectRef.current(), []);
}
