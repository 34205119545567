import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import type { ComponentType, ReactNode, ReactElement } from 'react';
import { createContext, useContext } from 'react';

type Direction = 'row' | 'column';

const LayoutContext = createContext<{ layoutDirection: Direction }>({
  layoutDirection: 'row',
});

type FixedProps = {
  children: ReactNode;
  size: number;
  style?: {};
  className?: string;
};

const FixedElement = styled.div({
  flexShrink: 0,
  flexGrow: 0,
  position: 'relative',
  // overflow: 'hidden', // commenting this out in order to support popovers
});

const Fixed = ({ children, size, style, className }: FixedProps) => {
  const { layoutDirection } = useContext(LayoutContext);
  return (
    <FixedElement
      style={{
        ...style,
        width: layoutDirection === 'row' ? size : '100%',
        height: layoutDirection === 'column' ? size : '100%',
      }}
      className={className}
    >
      {children}
    </FixedElement>
  );
};

const Fill = styled.div<{ scrollY?: boolean | undefined }>(
  ({ scrollY = false }) => ({
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
    overflowX: 'hidden',
    overflowY: scrollY ? 'auto' : 'hidden',
    position: 'relative',
  }),
);

type LayoutChild =
  | ReactElement<typeof Fixed>
  | ReactElement<typeof Fill>
  | false
  | undefined;
type LayoutChildren = LayoutChild | ReadonlyArray<LayoutChild>;

type LayoutElForwardedProps = {
  children: LayoutChildren;
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
};

type LayoutSharedStyleProps = {
  overflow?: number;
  translate?: number;
};

type LayoutElStyleProps = LayoutSharedStyleProps & {
  column?: boolean;
  row?: boolean;
};

type LayoutProps = LayoutSharedStyleProps &
  LayoutElForwardedProps & {
    direction: Direction;
  };

type LayoutType = ComponentType<LayoutProps> & {
  Fixed: typeof Fixed;
  Fill: typeof Fill;
};

const LayoutEl = styled('div', {
  shouldForwardProp: (prop): prop is keyof LayoutElForwardedProps =>
    isPropValid(prop) && prop !== 'translate',
})<LayoutElStyleProps>(
  ({ overflow, translate }) => ({
    display: 'flex',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    transition: '.5s width, .5s left',
    width: `calc(100% + ${overflow}px)`,
    left: translate,
  }),
  ({ column }) => column && { flexDirection: 'column' },
  ({ row }) => row && { flexDirection: 'row' },
);

const Layout = ({
  direction,
  overflow = 0,
  translate = 0,
  children,
  className,
  style,
}: LayoutProps) => (
  <LayoutContext.Provider value={{ layoutDirection: direction }}>
    <LayoutEl
      column={direction === 'column'}
      row={direction === 'row'}
      className={className}
      overflow={overflow}
      translate={translate}
      style={style}
    >
      {children}
    </LayoutEl>
  </LayoutContext.Provider>
);
Layout.Fixed = Fixed;
Layout.Fill = Fill;

// Small proof to ensure Layout conforms to LayoutType
function assertIsType<T>(_value: T) {}
assertIsType<LayoutType>(Layout);

export default Layout;
