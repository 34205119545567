import { StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

import Logo from 'ms-components/logos/Logo';
import { breakPoints, fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { onHover, styled } from 'ms-utils/emotion';
import { copyrightText } from 'ms-utils/misc/config';
import { homeUrl } from 'ms-utils/urls';

import { LoginLink } from './LoginLink';

/**
 * The styles have been copied from `ms-pages/JoinClass` and altered slightly.
 */
const PADDING = 16;

const Root = styled({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  fontFamily: fontFamily.body,
  maxWidth: '100%',

  [`@media (min-width: ${breakPoints.extraLarge}px)`]: {
    flexDirection: 'row',
  },
});

const Header = styled({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: 60,
  paddingLeft: PADDING,
  paddingRight: PADDING,
});

const Login = styled({
  marginLeft: 'auto',
});

const linkStyles = StyleSheet.create({
  default: {
    fontSize: 18,
    fontWeight: fontWeight.semibold,
    color: colors.eggplant,
    ...onHover({
      color: alternateColors.eggplant,
    }),
  },
});

const Link = styled(
  {
    display: 'flex',
  },
  'a',
);

const Main = styled({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  maxWidth: '100%',
  flexGrow: 1,
  minHeight: '100vh',
});

const Content = styled({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexShrink: 0,
  padding: PADDING,
});

const Footer = styled({
  padding: `${PADDING}px ${PADDING / 2}px`,
  color: colors.dustyGray,
  fontSize: fontSize.disclaimer,
  textAlign: 'center',

  [`@media (min-width: ${breakPoints.small}px)`]: {
    padding: PADDING,
  },
});

const HiddenInSmallScreen = styled(
  {
    display: 'none',
    [`@media (min-width: ${breakPoints.small}px)`]: {
      display: 'inline',
    },
  },
  'span',
);

type Props = {
  children: ReactNode;
};

function Layout({ children }: Props) {
  return (
    <Root>
      <Main>
        <Header>
          <Link href={homeUrl} data-tracking-id="SignupPage/Header/Logo">
            <Logo hasText />
          </Link>
          <Login>
            <LoginLink
              aphroditeStyles={[linkStyles.default]}
              trackingId="SignupPage/Header/Login"
            >
              <HiddenInSmallScreen>Have an account? </HiddenInSmallScreen>
              Log in
            </LoginLink>
          </Login>
        </Header>
        <Content>{children}</Content>
        <Footer>{copyrightText}</Footer>
      </Main>
    </Root>
  );
}

export { Layout };
