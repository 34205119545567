/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubproblemType = "ALGEBRAIC" | "BOXPLOT" | "EQUATION" | "GEOMETRY" | "GRAPHPLOT" | "HISTOGRAM" | "INEQUALITY" | "INLINE" | "MULTIPLE_CHOICE" | "NUMBER_BUILDER" | "NUMBER_LINE" | "NUMERIC" | "PROBABILITY_TREE";
export type PreviewProblemSubproblem_subproblem = {
    readonly instruction: string | null;
    readonly subproblemType: SubproblemType;
    readonly " $fragmentRefs": FragmentRefs<"SubproblemInput_subproblem">;
    readonly " $refType": "PreviewProblemSubproblem_subproblem";
};
export type PreviewProblemSubproblem_subproblem$data = PreviewProblemSubproblem_subproblem;
export type PreviewProblemSubproblem_subproblem$key = {
    readonly " $data"?: PreviewProblemSubproblem_subproblem$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PreviewProblemSubproblem_subproblem">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviewProblemSubproblem_subproblem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instruction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subproblemType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubproblemInput_subproblem"
    }
  ],
  "type": "SubproblemContent",
  "abstractKey": null
};
(node as any).hash = '49c1e7a8658f2e7219d30b020b5727ab';
export default node;
