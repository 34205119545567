import { useEffect, useState } from 'react';

/**
 * Rerender the component when the user navigates back to the page from the browser's bfcache.
 * Returns a number key that can be used to trigger a refetch or as the key for a child component
 * that needs to be re-rendered from scratch.
 */
export default function useRerenderAfterHistoryNavigation(): number {
  const [rerenderKey, setRerenderKey] = useState(0);
  const triggerRerender = () => setRerenderKey(prev => prev + 1);

  useEffect(() => {
    const onPageShow = (event: PageTransitionEvent) => {
      // The `persisted` property is true when the page is reloaded from the browser's bfcache.
      if (event.persisted) {
        triggerRerender();
      }
    };
    window.addEventListener('pageshow', onPageShow);
    return () => window.removeEventListener('pageshow', onPageShow);
  }, []);

  return rerenderKey;
}
