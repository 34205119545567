import React from 'react';

import { useRouter } from 'ms-utils/hooks/useRouter';
import localStorageDb from 'ms-utils/localStorageDb';
import { serialize, deserialize } from 'ms-utils/urls/queryParams';

const _VIEWS = {
  tasks: 'tasks',
  students: 'students',
  grades: 'grades',
};
const VIEWS = Object.keys(_VIEWS);
export type SelectedTasksView = keyof typeof _VIEWS;

function isSelectedTasksView(value: any): value is SelectedTasksView {
  return typeof value === 'string' && VIEWS.includes(value);
}

const CLASS_TASKS_REPORT_INITIAL_SELECTED_VIEW_DB_KEY =
  'CLASS_TASKS_REPORT_INITIAL_SELECTED_VIEW';

const getSelectedViewFromQueryParams = (
  queryString: string,
): SelectedTasksView | null => {
  const queryObject = deserialize(queryString);
  const maybeSelectedTaskViewQueryParam = queryObject.selectedView;
  if (maybeSelectedTaskViewQueryParam == null) return null;
  return isSelectedTasksView(maybeSelectedTaskViewQueryParam)
    ? maybeSelectedTaskViewQueryParam
    : null;
};

const getSelectedViewFromLocalStorage = (): SelectedTasksView | null => {
  const maybeSelectedTaskViewFromLocalStorage = localStorageDb.get(
    CLASS_TASKS_REPORT_INITIAL_SELECTED_VIEW_DB_KEY,
  );
  return isSelectedTasksView(maybeSelectedTaskViewFromLocalStorage)
    ? maybeSelectedTaskViewFromLocalStorage
    : null;
};

export default function useSelectedViewState() {
  const { history, location } = useRouter();

  let { pathname, search: searchString, state: _state } = location;

  const selectedViewFromQueryParams =
    getSelectedViewFromQueryParams(searchString);
  const selectedViewFromLocalStorage = getSelectedViewFromLocalStorage();

  const initialSelectedView =
    selectedViewFromQueryParams || selectedViewFromLocalStorage || 'tasks';

  const [selectedView, setSelectedView] =
    React.useState<SelectedTasksView>(initialSelectedView);

  const updateSelectedViewQueryParam = React.useCallback(
    (selectedView: SelectedTasksView) => {
      const newSearchString = serialize({
        ...deserialize(searchString),
        selectedView,
      });

      history.replace({
        pathname,
        search: newSearchString,
        state: _state,
      });
    },
    [history, pathname, searchString, _state],
  );

  React.useEffect(() => {
    localStorageDb.set(
      CLASS_TASKS_REPORT_INITIAL_SELECTED_VIEW_DB_KEY,
      selectedView,
    );
    if (selectedView !== selectedViewFromQueryParams) {
      updateSelectedViewQueryParam(selectedView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView]);

  React.useEffect(() => {
    if (
      selectedViewFromQueryParams !== selectedView &&
      selectedViewFromQueryParams != null
    ) {
      setSelectedView(selectedViewFromQueryParams);
      localStorageDb.set(
        CLASS_TASKS_REPORT_INITIAL_SELECTED_VIEW_DB_KEY,
        selectedViewFromQueryParams,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewFromQueryParams]);

  return [selectedView, setSelectedView] as const;
}
