import { useContext, useState, useCallback, useEffect } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import type { StepNumber } from 'ms-pages/Teacher/components/CreateTask';
import CustomTaskDetailsForm, {
  useCreateCustomTaskState,
} from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask';
import {
  Body,
  BODY_PADDING,
} from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import CustomTaskQuestionsHeader from 'ms-pages/Teacher/components/CreateTask/flows/CustomTaskFlow/components/CustomTaskQuestionsHeader';
import { ContentCollectionStepBody } from 'ms-pages/Teacher/components/CreateTask/flows/components/ContentCollectionStep';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';
import useMatchCollectedProblemsWithSubtopic from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useMatchCollectedProblemsWithSubtopic';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import useTaskTemplateStateWithSnackbar from 'ms-pages/Teacher/components/CreateTaskTemplate/state/useCreateTaskTemplateWithSnackbar';
import { assignableSubtopicForExercise } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import ContentList from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/ContentList';
import {
  StateContext as ProblemsCollectionState,
  UpdatersContext as ProblemsCollectionUpdaters,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { colors } from 'ms-styles/colors';
import { styledVerticallyScrollable } from 'ms-utils/emotion';
import useDebouncedValue from 'ms-utils/hooks/useDebouncedValue';

import type { CustomTaskFlowQuery } from './__generated__/CustomTaskFlowQuery.graphql';

const CUSTOM_TASK_FLOW_QUERY = graphql`
  query CustomTaskFlowQuery($syllabusId: ID!) {
    syllabus(id: $syllabusId) {
      topics(first: 1000) {
        edges {
          node {
            id
            subtopics(first: 1000) {
              edges {
                node {
                  id
                  title
                  hasExercise
                }
              }
            }
          }
        }
      }
    }
  }
`;

type Props = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledSubtopic?: SelectedSubtopic | null | undefined;
  prefilledDueDate?: Date | undefined;
  taskName?: string | null | undefined;
  preselectedStepNumber?: StepNumber | undefined;
  taskCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
  canCreateOnlyTemplate?: boolean | undefined;
  prefilledProblems?: readonly Problem[] | undefined;
  sourceTaskTemplateId?: string | null | undefined;
};

export default function CustomTaskFlow({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  taskName,
  taskCreationCallback,
  canCreateOnlyTemplate = true,
  currentSyllabusId,
  setCurrentSyllabusId,
  prefilledProblems,
  sourceTaskTemplateId,
}: Props) {
  const [{ templates }] = useFeatureFlags();
  const [{ catfaEnableSecondarySaveAsTemplate }] = useFeatureFlagsV2();

  const showSaveAsTemplate =
    canCreateOnlyTemplate && templates && catfaEnableSecondarySaveAsTemplate;
  const { stepNumber } = useCreateTaskState();
  const dispatch = useCreateTaskDispatch();
  const { trackStructEvent } = useSnowplow();

  const { problemsCollection: _problemsCollection } = useContext(
    ProblemsCollectionState,
  );

  const shouldUsePrefilledProblems = prefilledProblems != null;
  const [prefilledProblemsState, setPrefilledProblemsState] = useState(
    shouldUsePrefilledProblems ? prefilledProblems : [],
  );

  const problemsCollection = shouldUsePrefilledProblems
    ? prefilledProblemsState
    : _problemsCollection;

  const { clearCart: _clearCart } = useContext(ProblemsCollectionUpdaters);
  const clearCart = useCallback(() => {
    if (shouldUsePrefilledProblems) {
      setPrefilledProblemsState([]);
    } else {
      _clearCart();
    }
  }, [_clearCart, shouldUsePrefilledProblems]);

  const { schoolId } = useMaybeTeacherContext();
  const { enqueueMessage } = useContext(SnackbarContext);
  const [activeSubtopic, setActiveSubtopic] = useState<
    SelectedSubtopic | null | undefined
  >(prefilledSubtopic);

  const [state, [createCustomTask, { response, loading }]] =
    useCreateCustomTaskState({
      // Problem contents from the context are a tuple [id, ..]
      problemContentIds: problemsCollection.map(pc => pc[0]),
      taskName,
      selectedClasses: prefilledClasses != null ? prefilledClasses : [],
      selectedStudents: prefilledStudents != null ? prefilledStudents : [],
      excludedStudents:
        prefilledExcludedStudents != null ? prefilledExcludedStudents : [],
      taskStartDate: prefilledStartDate,
      taskDueDate: prefilledDueDate,
      sourceTaskTemplateId,
    });
  const { taskName: customTaskName } = state.values;

  const customTaskNameDebounced = useDebouncedValue(customTaskName, 500);

  const [taskTemplateFormState, [createTaskTemplate]] =
    useTaskTemplateStateWithSnackbar(
      {
        schoolId,
        problemContentIds: problemsCollection.map(pc => pc[0]),
      },
      taskCreationCallback,
    );
  const { title: taskTemplateTitle } = taskTemplateFormState.values;
  const { setTaskTemplateTitle } = taskTemplateFormState.updaters;

  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();

  const { props } = useQuery<CustomTaskFlowQuery>(CUSTOM_TASK_FLOW_QUERY, {
    syllabusId: currentSyllabusId,
  });

  const matchedSubtopic = useMatchCollectedProblemsWithSubtopic({
    data: props,
    problemsCollection,
  });

  useEffect(() => {
    if (matchedSubtopic != null && activeSubtopic == null) {
      setActiveSubtopic([matchedSubtopic.id, matchedSubtopic.title]);
    }
  }, [activeSubtopic, matchedSubtopic]);

  const classTasksReportUrl = useGetFirstAvailableClassTasksReportUrl(
    state.values.selectedClasses,
  );

  useEffect(
    () => {
      if (
        response != null &&
        response.createCustomTask.task != null &&
        response.createCustomTask.errors.length === 0
      ) {
        enqueueMessage({
          text: 'Task Created Successfully',
          href: classTasksReportUrl,
          actionLabel: 'View tasks',
        });
        taskCreationCallback();
        clearCart();
      } else if (
        response != null &&
        response.createCustomTask.errors.length > 0
      ) {
        enqueueMessage({ text: 'Unable to create task' });
      }
    },
    // A lot of the objects used here don't follow good memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response],
  );

  // If the user is on the preview step and the problems collection is empty,
  // go back to the problem selection step
  useEffect(() => {
    if (
      stepNumber === 1 &&
      problemsCollection.length === 0 &&
      // we don't let users go back to the problem selection step when problems are prefilled
      // because problem selection step only adds problems to the problems collection at the moment
      !shouldUsePrefilledProblems
    ) {
      dispatch({ type: 'backward' });
    }
  }, [
    dispatch,
    problemsCollection.length,
    shouldUsePrefilledProblems,
    stepNumber,
  ]);

  // Updates the task template title whenever the custom task name changes
  // Uses the debounced value of custom task title
  // to avoid updating the task template title on every keystroke
  useEffect(() => {
    if (showSaveAsTemplate && taskTemplateTitle !== customTaskNameDebounced) {
      setTaskTemplateTitle(customTaskNameDebounced);
    }
  }, [
    customTaskNameDebounced,
    setTaskTemplateTitle,
    showSaveAsTemplate,
    taskTemplateTitle,
  ]);

  const stepNames = STEP_NAME_MAPS.custom();

  const nextButtonDisabledStates = {
    question_selector: problemsCollection.length === 0,
    preview_and_task_details:
      loading ||
      !state.values.taskName ||
      (state.values.selectedClasses.length === 0 &&
        state.values.selectedStudents.length === 0),
  };

  const createCustomTaskAction = useCallback(() => {
    // Adds snowplow tracking for save as template checkbox state
    // on custom task creation
    trackStructEvent({
      category: 'create_task_from_anywhere',
      action: 'checked_save_as_template_checkbox',
      label: state.values.saveThisAsTemplate ? 'true' : 'false',
    });
    createCustomTask();
    dispatch({ type: 'create' });
  }, [
    createCustomTask,
    dispatch,
    state.values.saveThisAsTemplate,
    trackStructEvent,
  ]);

  const createTaskTemplateAction = useCallback(() => {
    createTaskTemplate();
    trackStructEvent({
      category: 'create_task_from_anywhere',
      action: 'selected_save_as_template',
    });
    dispatch({ type: 'create', extraEventData: { label: 'template' } });
  }, [createTaskTemplate, dispatch, trackStructEvent]);

  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({ selectedFlow: 'custom' })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={createCustomTaskAction}
        numberOfSelections={problemsCollection.length}
        secondaryLastStepActionLabel={
          showSaveAsTemplate ? 'Save as template' : undefined
        }
        onSecondaryLastStepAction={
          showSaveAsTemplate ? createTaskTemplateAction : undefined
        }
        isSecondaryLastStepActionDisabled={
          !taskTemplateTitle || taskTemplateTitle !== customTaskName
        }
        hideStepNumber={shouldUsePrefilledProblems}
        hidePreviousButtonOnSecondStep={shouldUsePrefilledProblems}
      />

      {stepNumber === 0 && (
        <SidebarAndPreview
          taskType="custom"
          activeSubtopic={activeSubtopic}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          subtopicFilter={assignableSubtopicForExercise}
          noHorizontalSubtopicBodyPadding
        >
          <ContentCollectionStepBody
            selectedSubtopic={activeSubtopic}
            isHeaderSticky
            stickyHeaderTopOffset={-BODY_PADDING}
            hasSelfHorizontalPadding
          />
        </SidebarAndPreview>
      )}
      {stepNumber === 1 && (
        <>
          <Body
            noFooterSpaceAtBottom
            isVerticallyScrollable={false}
            isVerticalOverflowHidden
            whiteBackground={false}
            style={{
              backgroundColor: colors.seashell,
              padding: 0,
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                overflowY: 'hidden',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '55%',
                  borderRight: `1px solid ${colors.ironLight}`,
                  overflowY: 'hidden',
                  backgroundColor: colors.white,
                }}
              >
                <div style={{ padding: 16 }}>
                  <CustomTaskQuestionsHeader
                    numberOfQuestions={problemsCollection.length}
                    problems={problemsCollection}
                    onRemoveAll={clearCart}
                    hideClearAllButton={shouldUsePrefilledProblems}
                    questionsCollapsed={questionsCollapsed}
                    setQuestionsCollapsed={setQuestionsCollapsed}
                  />
                </div>
                <div
                  style={{
                    paddingRight: 16,
                    paddingLeft: 16,
                    overflowY: 'auto',
                  }}
                >
                  <ContentList
                    collapsedInBulk={questionsCollapsed}
                    showTryProblem
                    prefilledProblems={
                      shouldUsePrefilledProblems
                        ? prefilledProblemsState
                        : undefined
                    }
                    setPrefilledProblems={
                      shouldUsePrefilledProblems
                        ? setPrefilledProblemsState
                        : undefined
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  width: '45%',
                  padding: 16,
                  ...styledVerticallyScrollable,
                }}
              >
                <CustomTaskDetailsForm
                  state={state}
                  showSaveAsTemplateOption={templates}
                />
              </div>
            </div>
          </Body>
        </>
      )}
    </>
  );
}
