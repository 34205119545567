import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M14.375 8.75C14.62 8.75 14.8425 8.8925 14.9438 9.11625C15.045 9.34 15.0075 9.6025 14.845 9.78625L10.47 14.7863C10.3512 14.9213 10.18 15 10 15C9.82 15 9.64875 14.9225 9.53 14.7863L5.155 9.78625C4.99375 9.60125 4.95375 9.34 5.05625 9.11625C5.1575 8.89375 5.38 8.75 5.625 8.75H8.125V0.625C8.125 0.28 8.405 0 8.75 0H11.25C11.595 0 11.875 0.28 11.875 0.625V8.75H14.375ZM16.875 17.5V13.75H19.375V18.75C19.375 19.4412 18.8162 20 18.125 20H1.875C1.185 20 0.625 19.4412 0.625 18.75V13.75H3.125V17.5H16.875Z"
    fill="#3E3E4C"
  />
);

const Download = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Download.displayName = 'Download';

export default Download;
