import ArrowRight from 'ms-components/icons/ArrowRight';

const forwardArrowWrapper = {
  marginLeft: 4,
  display: 'flex',
  alignItems: 'center',
};

export const FORWARD_ARROW = (
  <div css={forwardArrowWrapper}>
    <ArrowRight />
  </div>
);

export { default as AnchorButton } from 'ms-ui-primitives/Button/AnchorButton';
export { default as Button } from 'ms-ui-primitives/Button';
export { default as LinkButton } from 'ms-ui-primitives/Button/ReactRouterLinkButton';
