import { Component, type ReactNode, type ErrorInfo } from 'react';

import { Logger } from 'ms-utils/app-logging';

// Basic error boundary that allows showing fallback UI, and logs
// caught exceptions to Sentry.
//
// Usage:
//
// <ErrorBoundary name="Sidebar" fallback={<div>Something went wrong</div>}>
//   <Sidebar />
// </ErrorBoundary>
//
export class ErrorBoundary extends Component<
  {
    children: ReactNode;
    fallback: ReactNode;
    // This is the name that will be shown in Sentry logs when
    // this error boundary catches exceptions
    name: string;
  },
  { hasError: boolean }
> {
  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  override state = { hasError: false };

  override componentDidCatch(error: Error, info: ErrorInfo) {
    Logger.error(
      `ErrorBoundary (${this.props.name}) caught error: ${error.message}`,
      {
        extra: {
          componentStack: info.componentStack,
          errorBoundaryName: this.props.name,
        },
      },
    );
  }

  override render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
