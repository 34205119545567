import styled from '@emotion/styled';

import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

export const GameModalContent = styled.div({
  color: colors.grey90,
  display: 'flex',
  flexDirection: 'column',
  fontFamily: fontFamily.body,
  fontSize: 14,
  gap: 16,
  lineHeight: 1.25,
  padding: '32px 40px',
});

export const GameModalTitle = styled.h2({
  color: colors.grey,
  fontFamily: fontFamily.heading,
  fontSize: 20,
  fontWeight: 800,
});
