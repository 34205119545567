import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CreateNoteIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={21}
    viewBoxHeight={21}
  >
    <g clipPath="url(#clip0)">
      <path d="M11.9989 13.8514C11.7971 14.0531 11.5477 14.2072 11.2775 14.2973L8.66722 15.1674C8.47883 15.2302 8.28237 15.2621 8.08361 15.2621C8.08361 15.2621 8.08361 15.2621 8.08356 15.2621C7.59052 15.2621 7.12704 15.0701 6.77845 14.7215C6.28089 14.2238 6.1101 13.5001 6.33261 12.8327L7.20271 10.2225C7.29274 9.95217 7.44696 9.70264 7.64855 9.50104L13.2864 3.86328H2.25586C1.01198 3.86328 0 4.87526 0 6.11914V19.2441C0 20.488 1.01198 21.5 2.25586 21.5H15.3809C16.6247 21.5 17.6367 20.488 17.6367 19.2441V8.21364L11.9989 13.8514Z" />
      <path d="M8.51856 10.3711C8.45101 10.4387 8.40015 10.521 8.36996 10.6116L7.49986 13.2218C7.42615 13.4429 7.4837 13.6866 7.64846 13.8514C7.81326 14.0162 8.05697 14.0737 8.27805 14L10.8883 13.1299C10.9789 13.0997 11.0612 13.0489 11.1288 12.9813L18.8144 5.29568L16.2042 2.68549L8.51856 10.3711Z" />
      <path d="M19.5397 0.800266C19.1392 0.39983 18.49 0.39983 18.0896 0.800266L17.0745 1.81536L19.6847 4.4256L20.6998 3.4105C21.1002 3.01006 21.1002 2.36083 20.6998 1.96039L19.5397 0.800266Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="21"
          height="21"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </Icon>
);

CreateNoteIcon.displayName = 'CreateNoteIcon';

export default CreateNoteIcon;
