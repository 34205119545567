import Icon from 'ms-ui-primitives/Icon';

import type { Props as IconProps } from '../types';

type Props = IconProps & {
  leftArrowTransform?: string;
  rightArrowTransform?: string;
};

const DoubleRight = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
  leftArrowTransform,
  rightArrowTransform,
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={165}
    viewBoxWidth={337}
  >
    <g>
      <path
        transform={rightArrowTransform}
        d="M109.869 320.385L325.129 104.643C328.208 101.561 329.75 98.0147 329.75 94.0033C329.75 89.9917 328.208 86.4391 325.129 83.3551L109.869 -132.369C106.785 -135.46 103.246 -137 99.242 -137C95.2392 -137 91.6959 -135.455 88.6185 -132.369L65.5287 -109.228C62.4498 -106.142 60.9111 -102.591 60.9111 -98.5795C60.9111 -94.568 62.4498 -91.017 65.5287 -87.9313L247.066 94.0032L65.5174 275.944C62.4384 279.028 60.9095 282.581 60.9095 286.586C60.9095 290.604 62.4481 294.155 65.5174 297.239L88.6169 320.384C91.6942 323.468 95.2392 325 99.2404 325C103.246 325.002 106.79 323.469 109.869 320.385Z"
      />
      <path
        transform={leftArrowTransform}
        d="M-7.13132 320.385L208.129 104.643C211.208 101.561 212.75 98.0147 212.75 94.0033C212.75 89.9917 211.208 86.4391 208.129 83.3551L-7.1313 -132.369C-10.2151 -135.46 -13.7536 -137 -17.758 -137C-21.7608 -137 -25.3041 -135.455 -28.3815 -132.369L-51.4713 -109.228C-54.5502 -106.142 -56.0889 -102.591 -56.0889 -98.5795C-56.0889 -94.568 -54.5502 -91.017 -51.4713 -87.9313L130.066 94.0032L-51.4826 275.944C-54.5616 279.028 -56.0905 282.581 -56.0905 286.586C-56.0905 290.604 -54.5519 294.155 -51.4826 297.239L-28.3831 320.384C-25.3058 323.468 -21.7608 325 -17.7596 325C-13.7536 325.002 -10.2103 323.469 -7.13132 320.385Z"
      />
    </g>
  </Icon>
);

DoubleRight.displayName = 'DoubleRight';

export default DoubleRight;
