import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

export function ThreeDCalculatorIcon() {
  return (
    <Icon size={32} viewBoxWidth={32} viewBoxHeight={32}>
      <rect width="32" height="32" rx="4" fill="currentColor" />
      <path
        fillRule="evenodd"
        d="M16 26C21.5228 26 26 21.5228 26 16C26 10.4772 21.5228 6 16 6C10.4772 6 6 10.4772 6 16C6 21.5228 10.4772 26 16 26ZM16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        fill={colors.white}
      />
      <path
        d="M6.97185 16.6358C6.9718 16.6358 6.97232 16.6348 6.97359 16.633C6.97255 16.6349 6.97191 16.6358 6.97185 16.6358ZM7.12103 16.5H4.99902C4.99902 16.5 4.99902 16.5 4.99902 16.5C4.99902 17.417 5.71824 18.0043 6.27604 18.334C6.90079 18.7032 7.73824 19.0011 8.68542 19.2379C10.5952 19.7154 13.1805 20 15.999 20C18.8176 20 21.4029 19.7154 23.3126 19.2379C24.2598 19.0011 25.0973 18.7032 25.722 18.334C26.2798 18.0043 26.999 17.417 26.999 16.5L24.877 16.5C24.8328 16.5322 24.7763 16.5697 24.7043 16.6122C24.317 16.8412 23.69 17.082 22.8276 17.2976C21.118 17.725 18.7033 18 15.999 18C13.2947 18 10.88 17.725 9.17049 17.2976C8.30803 17.082 7.68101 16.8412 7.2937 16.6122C7.22178 16.5697 7.16524 16.5322 7.12102 16.5C7.12103 16.5 7.12103 16.5 7.12103 16.5ZM25.0262 16.6358C25.0261 16.6358 25.0255 16.6349 25.0245 16.633C25.0257 16.6348 25.0263 16.6358 25.0262 16.6358Z"
        fill={colors.white}
      />
      <path
        d="M7.12203 16.5H4.99902C4.99902 15.703 5.54902 15.1566 6.01769 14.8313C6.47139 14.5163 7.09448 14.6288 7.4094 15.0825C7.72433 15.5362 7.61183 16.1593 7.15813 16.4742C7.1454 16.4831 7.13337 16.4917 7.12203 16.5ZM26.999 16.5L24.876 16.5C24.8647 16.4917 24.8526 16.4831 24.8399 16.4742C24.3862 16.1593 24.2737 15.5362 24.5886 15.0825C24.9036 14.6288 25.5267 14.5163 25.9804 14.8313C26.449 15.1566 26.999 15.703 26.999 16.5ZM15.999 13C15.6278 13 15.2611 13.0049 14.8998 13.0146C14.3478 13.0293 13.9121 13.4888 13.9268 14.0409C13.9416 14.5929 14.401 15.0286 14.9531 15.0139C15.2966 15.0047 15.6455 15 15.999 15C16.3525 15 16.7015 15.0047 17.0449 15.0139C17.597 15.0286 18.0565 14.5929 18.0712 14.0409C18.0859 13.4888 17.6503 13.0293 17.0982 13.0146C16.7369 13.0049 16.3702 13 15.999 13ZM11.836 15.233C12.3845 15.168 12.7764 14.6707 12.7113 14.1222C12.6463 13.5738 12.149 13.1819 11.6005 13.247C10.8118 13.3405 10.072 13.459 9.39719 13.5996C8.85652 13.7122 8.50952 14.2418 8.62216 14.7825C8.73481 15.3232 9.26442 15.6702 9.8051 15.5575C10.4153 15.4304 11.0969 15.3207 11.836 15.233ZM20.3975 13.247C19.8491 13.1819 19.3518 13.5738 19.2867 14.1222C19.2217 14.6707 19.6136 15.168 20.162 15.233C20.9011 15.3207 21.5827 15.4304 22.1929 15.5575C22.7336 15.6702 23.2632 15.3232 23.3759 14.7825C23.4885 14.2418 23.1415 13.7122 22.6009 13.5996C21.9261 13.459 21.1863 13.3405 20.3975 13.247Z"
        fill={colors.white}
      />
    </Icon>
  );
}

export default ThreeDCalculatorIcon;
