import { useSnowplow } from 'ms-helpers/Snowplow';
import SunflowerModal from 'ms-pages/Sunflower/ui-primitives/Modal';
import { TeacherEditStudent } from 'ms-pages/Teacher/components/StudentCreateEdit';

type Props = {
  studentId: string | null | undefined;
  // Anticipate students with no class. In Waypoints, students can have no classes as
  // they can join by using the school join code instead.
  requireStudentInClass?: boolean | undefined;
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  onBack?: (() => void) | null | undefined;
};

const EditStudentModal = ({
  studentId,
  requireStudentInClass = true,
  isOpen,
  closeModal,
  onSubmit,
  onBack,
}: Props) => {
  const { withTrackStructEvent } = useSnowplow();
  return (
    <SunflowerModal
      isOpen={isOpen}
      onClose={closeModal}
      title="Edit student details"
      onBack={onBack}
      noMinHeight
    >
      {studentId != null && (
        <TeacherEditStudent
          studentId={studentId}
          requireStudentInClass={requireStudentInClass}
          onCancel={closeModal}
          onSubmit={withTrackStructEvent(onSubmit, {
            category: 'teacher_admin',
            action: 'clicked_edit_student',
          })}
        />
      )}
    </SunflowerModal>
  );
};

export default EditStudentModal;
