/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { ReactNode } from 'react';

import PopoverInternal from './PopoverInternal';

export const DEFAULT_VERTICAL_OFFSET_FROM_ANCHOR = 10;
export const DEFAULT_HORIZONTAL_OFFSET_FROM_ANCHOR = 0;

export type PopoverPosition =
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center'
  | 'middle-left'
  | 'middle-right'
  | 'middle-center'
  | 'top-left'
  | 'top-right'
  | 'top-center';

export type AnchorOrigin = 'center' | 'left' | 'right';
export type AnchorOriginVertical = 'top' | 'middle' | 'bottom';

export type Props = {
  onDismiss: () => void;
  popoverPosition: PopoverPosition;
  anchorElementRef: { current: HTMLElement | null | undefined };
  children: ReactNode;
  shouldDismissOnTapOut: boolean;

  // Positions on the anchor element (not the popover)
  anchorOrigin?: AnchorOrigin | undefined;
  anchorOriginVertical?: AnchorOriginVertical | undefined;

  shouldDismissOnScroll?: boolean | undefined;
  shouldDismissOnOwnScroll?: boolean | undefined;
  shouldDismissOnEsc?: boolean | undefined;
  shouldDismissOnTab?: boolean | undefined;
  vOffset?: number | undefined;
  hOffset?: number | undefined;
  renderOverlay?: boolean | undefined;
  hasVerticalTransition?: boolean | undefined;

  // HACK: See `PopoverInternal` implementation
  __observeAnchorChanges?: true | undefined;
};

const popoverPositionToNewApiAnchorOriginY = {
  'bottom-left': 'bottom',
  'bottom-right': 'bottom',
  'bottom-center': 'bottom',
  'middle-left': 'middle',
  'middle-right': 'middle',
  'middle-center': 'middle',
  'top-left': 'top',
  'top-right': 'top',
  'top-center': 'top',
} as const;

const popoverPositionToNewApiPopoverOrigin = {
  'bottom-left': ['top', 'right'],
  'bottom-right': ['top', 'left'],
  'bottom-center': ['top', 'center'],
  'middle-left': ['middle', 'right'], // this is breaking previous API but only usage was fixed by removing vertical offset
  'middle-right': ['middle', 'left'], // this is breaking previous API but only usage was fixed by removing vertical offset
  'middle-center': ['middle', 'center'],
  'top-left': ['bottom', 'right'],
  'top-right': ['bottom', 'left'],
  'top-center': ['bottom', 'center'],
} as const;

const popoverPositionToNewApiHOffsetFactor = {
  'bottom-left': -1,
  'bottom-right': 1,
  'bottom-center': 1,
  'middle-left': -1,
  'middle-right': 1,
  'middle-center': 1,
  'top-left': -1,
  'top-right': 1,
  'top-center': 1,
};

const popoverPositionToNewApiVOffsetFactor = {
  'bottom-left': 1,
  'bottom-right': 1,
  'bottom-center': 1,
  'middle-left': -1,
  'middle-right': -1,
  'middle-center': -1,
  'top-left': -1,
  'top-right': -1,
  'top-center': -1,
};

export default function Popover({
  onDismiss,
  shouldDismissOnTapOut,
  shouldDismissOnScroll = true,
  shouldDismissOnOwnScroll = false,
  shouldDismissOnEsc = true,
  shouldDismissOnTab = true,
  popoverPosition,
  anchorOrigin = 'center',
  anchorOriginVertical,
  children,
  anchorElementRef,
  vOffset = DEFAULT_VERTICAL_OFFSET_FROM_ANCHOR,
  hOffset = DEFAULT_HORIZONTAL_OFFSET_FROM_ANCHOR,
  renderOverlay = true,
  hasVerticalTransition = true,
  __observeAnchorChanges,
}: Props) {
  const newApiAnchorOrigin = [
    anchorOriginVertical ||
      popoverPositionToNewApiAnchorOriginY[popoverPosition],
    anchorOrigin,
  ] as const;

  const newApiHOffset =
    popoverPositionToNewApiHOffsetFactor[popoverPosition] * hOffset;
  const newApiVOffset =
    popoverPositionToNewApiVOffsetFactor[popoverPosition] * vOffset;

  const popoverOrigin = popoverPositionToNewApiPopoverOrigin[popoverPosition];

  return (
    <PopoverInternal
      __observeAnchorChanges={__observeAnchorChanges}
      onDismiss={onDismiss}
      shouldDismissOnTapOut={shouldDismissOnTapOut}
      shouldDismissOnScroll={shouldDismissOnScroll}
      shouldDismissOnOwnScroll={shouldDismissOnOwnScroll}
      shouldDismissOnEsc={shouldDismissOnEsc}
      shouldDismissOnTab={shouldDismissOnTab}
      popoverOrigin={popoverOrigin}
      anchorOrigin={newApiAnchorOrigin}
      children={children}
      anchorElementRef={anchorElementRef}
      hOffset={newApiHOffset}
      vOffset={newApiVOffset}
      renderOverlay={renderOverlay}
      hasVerticalTransition={hasVerticalTransition}
    />
  );
}
