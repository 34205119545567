import { css } from 'aphrodite';

import Answer from 'ms-components/math/Answer';
import MathContent from 'ms-components/math/MathContent';

import styles from '../styles';
import type { Subproblem } from '../types';

type Props = {
  subproblem: Subproblem | null | undefined;
};

const SubproblemSolution = ({ subproblem }: Props) => {
  if (subproblem == null) return null;
  return (
    <div>
      {subproblem.solution_steps.map((step, index) => {
        const hints = [step.hint, step.second_hint].filter(hint => hint !== '');
        return (
          <div key={index} className={css(styles.column)}>
            {hints && (
              <div className={css(styles.hints)}>
                {hints.map(hint => (
                  <div key={hint} className={css(styles.hint)}>
                    <MathContent content={hint} />
                  </div>
                ))}
              </div>
            )}
            <Answer datum={step.request_datum} />
          </div>
        );
      })}
    </div>
  );
};

export default SubproblemSolution;
