import styled from '@emotion/styled';

import { InvariantViolation } from 'ms-utils/app-logging';

const { PI: π } = Math;

type ArcProps = {
  cx: number;
  cy: number;
  ρ: number;
  Δ: number; // between 0 and 2 * π
  thickness: number;
  color: string;
  mask?: string | undefined; // optional mask ref
};

const Circle = styled.circle({
  transform: 'rotate(-90deg)',
  transformOrigin: 'center center',
});

export default function Arc({
  cx,
  cy,
  ρ,
  Δ,
  thickness,
  color,
  mask,
}: ArcProps) {
  if (Δ < 0 || Δ > 2 * π) {
    throw new InvariantViolation(`Passed an invalid Δ to Arc: ${Δ}`);
  }
  return (
    <Circle
      cx={cx}
      cy={cy}
      r={ρ}
      strokeWidth={thickness}
      strokeDashoffset={ρ * (2 * π - Δ)}
      strokeDasharray={2 * π * ρ}
      strokeLinecap="round"
      fill="transparent"
      stroke={color}
      mask={mask}
    />
  );
}
