/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type JsxContentType = "ANSWER_KEY" | "ENGAGE_ACTIVITY" | "ENGAGE_ACTIVITY_TEACHER_NOTE" | "GLOSSARY_CONTENT" | "INVESTIGATION" | "INVESTIGATION_TEACHER_NOTE" | "LESSON" | "RETROFITTED_STUDENT_LESSON" | "RETROFITTED_STUDENT_LESSON_TEACHER_NOTE" | "SOLIDIFY_LESSON" | "SOLIDIFY_TEACHER_NOTE" | "STUDENT_OVERVIEW" | "STUDENT_REFERENCE" | "TEACHER_NOTE" | "TEACHER_OVERVIEW" | "TOPIC_ANSWER_KEY" | "TOPIC_ASSESSMENT" | "TOPIC_OVERVIEW" | "WORKSHEET";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type StudyNotesModalQueryVariables = {};
export type StudyNotesModalQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly id?: string | undefined;
            readonly studyNotes?: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly note: string;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly jsxContentType: JsxContentType | null;
                readonly jsxContent: string;
                readonly jsxContentLocale: SyllabusLocale | null;
                readonly subtopic: {
                    readonly id: string;
                } | null;
                readonly highlightedData: string;
            }> | undefined;
        };
    };
};
export type StudyNotesModalQuery = {
    readonly response: StudyNotesModalQueryResponse;
    readonly variables: StudyNotesModalQueryVariables;
};



/*
query StudyNotesModalQuery {
  viewer {
    profile {
      __typename
      ... on Student {
        id
        studyNotes {
          id
          title
          note
          createdAt
          updatedAt
          jsxContentType
          jsxContent
          jsxContentLocale
          subtopic {
            id
          }
          highlightedData
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StudyNote",
      "kind": "LinkedField",
      "name": "studyNotes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jsxContentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jsxContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jsxContentLocale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Subtopic",
          "kind": "LinkedField",
          "name": "subtopic",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "highlightedData",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Student",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudyNotesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StudyNotesModalQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be2608ba02aac594c0bb4989ac69418e",
    "id": null,
    "metadata": {},
    "name": "StudyNotesModalQuery",
    "operationKind": "query",
    "text": "query StudyNotesModalQuery {\n  viewer {\n    profile {\n      __typename\n      ... on Student {\n        id\n        studyNotes {\n          id\n          title\n          note\n          createdAt\n          updatedAt\n          jsxContentType\n          jsxContent\n          jsxContentLocale\n          subtopic {\n            id\n          }\n          highlightedData\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '44a191f2f68f9cb9c4ff04919a60c7c0';
export default node;
