import * as React from 'react';
import { graphql, useMutation } from 'relay-hooks';

import useTrackActiveTimeSpent from 'ms-pages/Textbooks/StudentTextbook/components/useTrackActiveTimeSpent';
import useTrackScrollDepthQuartiles, {
  type Quartile,
} from 'ms-pages/Textbooks/StudentTextbook/components/useTrackScrollDepthQuartiles';
import Lesson from 'ms-pages/Textbooks/components/SubtopicDetailView/Lesson';

import type {
  StudentLessonUpdateLessonWorkoutProgressMutation,
  ProgressQuartile,
} from './__generated__/StudentLessonUpdateLessonWorkoutProgressMutation.graphql';
import type { StudentLessonUpdateLessonWorkoutTimeSpentMutation } from './__generated__/StudentLessonUpdateLessonWorkoutTimeSpentMutation.graphql';

type Props = {
  subtopicId: string;
  scrollingElement: HTMLElement | null | undefined;
  lessonWorkoutIdToTrack: string | null | undefined;
  onLoad: () => void;
};
const quartileToProgressQuartileMap: Record<Quartile, ProgressQuartile> = {
  '0': 'PROGRESS_0',
  '25': 'PROGRESS_25',
  '50': 'PROGRESS_50',
  '75': 'PROGRESS_75',
  '100': 'PROGRESS_100',
};
const PING_INTERVAL = 30 * 1000; // Update time spent every 30 seconds.
const TIMEOUT_INTERVAL = 5 * 60 * 1000; // Timeout, after which we decide user is inactive on the tabl. 5 min in milliseconds.
export default function StudentLesson({
  subtopicId,
  scrollingElement,
  lessonWorkoutIdToTrack,
  onLoad,
}: Props) {
  const [updateLessonWorkoutProgress] =
    useMutation<StudentLessonUpdateLessonWorkoutProgressMutation>(graphql`
      mutation StudentLessonUpdateLessonWorkoutProgressMutation(
        $progress: ProgressQuartile!
        $workoutId: ID!
      ) {
        updateLessonWorkoutProgress(
          progress: $progress
          workoutId: $workoutId
        ) {
          errors {
            key
          }
          workout {
            ... on LessonWorkout {
              progress
            }
          }
        }
      }
    `);
  useTrackScrollDepthQuartiles(
    scrollingElement,
    React.useCallback(
      quartile => {
        if (lessonWorkoutIdToTrack != null) {
          updateLessonWorkoutProgress({
            variables: {
              progress: quartileToProgressQuartileMap[quartile],
              workoutId: lessonWorkoutIdToTrack,
            },
          });
        }
      },
      // Need to redefine this every time subtopicId changes
      // to ensure the correct tracking.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [subtopicId],
    ),
  );
  const [updateLessonWorkoutActiveTimeSpent] =
    useMutation<StudentLessonUpdateLessonWorkoutTimeSpentMutation>(graphql`
      mutation StudentLessonUpdateLessonWorkoutTimeSpentMutation(
        $workoutId: ID!
        $intervalRate: Int!
      ) {
        updateLessonWorkoutTimeSpent(
          workoutId: $workoutId
          intervalRate: $intervalRate
        ) {
          errors {
            key
            message
          }
        }
      }
    `);
  useTrackActiveTimeSpent(
    scrollingElement,
    React.useCallback(
      () => {
        if (lessonWorkoutIdToTrack != null) {
          updateLessonWorkoutActiveTimeSpent({
            variables: {
              intervalRate: PING_INTERVAL / 1000,
              workoutId: lessonWorkoutIdToTrack,
            },
          });
        }
      },
      // Need to redefine this every time subtopicId changes
      // to ensure the correct tracking.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [subtopicId],
    ),
    PING_INTERVAL,
    TIMEOUT_INTERVAL,
  );
  return (
    <>
      <Lesson subtopicId={subtopicId} onLoad={onLoad} />
    </>
  );
}
