import { useRef, useEffect } from 'react';

/**
 * Similar to use effect except it will not run on mount
 */
export function useRerenderEffect(
  // Callback either returns void, or a cleanup function
  callback: () => void | (() => void),
  deps: ReadonlyArray<unknown>,
) {
  const hasMounted = useRef(false);
  useEffect(
    // eslint-disable-next-line consistent-return
    () => {
      if (hasMounted.current === false) {
        hasMounted.current = true;
      } else {
        return callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
}
