import { useCallback } from 'react';

import { FilledTab, LinkTab, TabContent, Tabs } from 'ms-components/Tabs';
import EngageTaskIcon from 'ms-components/icons/EngageTask';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  validateLegacyTextbookSubtopicActiveTab,
  type LegacyTextbookSubtopicActiveTab,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import { colors } from 'ms-styles/colors';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import useTabsFallback from 'ms-utils/hooks/useTabsFallback';

type Props = {
  hasInvestigations: boolean;
  hasLessons: boolean;
  hasEngageActivity: boolean;
  hasWorksheet: boolean;
  hasExercise: boolean;
  hasTeacherNote: boolean;
  onSelectTab?: (arg: LegacyTextbookSubtopicActiveTab) => void;
};

export default function SubtopicDetailViewTabs({
  hasLessons,
  hasInvestigations,
  hasWorksheet,
  hasExercise,
  hasEngageActivity,
  hasTeacherNote,
  onSelectTab,
}: Props) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const [activeTab, _setActiveTab, getTabLink] =
    useQueryParam<LegacyTextbookSubtopicActiveTab>(
      'activeTab',
      'theory',
      validateLegacyTextbookSubtopicActiveTab,
    );
  const {
    role,
    featureFlags: { worksheets, engageTasks },
  } = useViewer();

  const setActiveTab = useCallback(
    (newTab: LegacyTextbookSubtopicActiveTab) => {
      _setActiveTab(newTab);
      if (onSelectTab != null) onSelectTab(newTab);
    },
    [_setActiveTab, onSelectTab],
  );

  const canSetInteractiveTab = !hasEnabledAccessibilityMode && hasExercise;

  const canSetTheoryTab =
    !hasEnabledAccessibilityMode && (hasLessons || hasInvestigations);

  useTabsFallback<LegacyTextbookSubtopicActiveTab>(
    {
      theory: { shouldRender: canSetTheoryTab },
      interactive: { shouldRender: canSetInteractiveTab },
      worksheet: { shouldRender: hasWorksheet },
      teacherNotes: { shouldRender: hasTeacherNote },
      engageActivity: { shouldRender: hasEngageActivity },
    },
    {
      activeTab,
      setActiveTab,
    },
  );

  // In accessibility mode worksheets are the only content available
  // We want to render an empty view in the subtopic detail view without the tabs
  if (hasEnabledAccessibilityMode) {
    return null;
  }

  return (
    <Tabs>
      {hasTeacherNote && (
        <LinkTab
          active={activeTab === 'teacherNotes'}
          to={getTabLink('teacherNotes')}
          trackingId="Textbooks/SunflowerSubtopicDetailView/TeacherNotes"
        >
          <TabContent>Teacher Notes</TabContent>
        </LinkTab>
      )}
      {!hasEnabledAccessibilityMode && canSetTheoryTab && (
        <LinkTab
          active={activeTab === 'theory'}
          to={getTabLink('theory')}
          trackingId="Textbooks/SunflowerSubtopicDetailView/Lesson"
        >
          <TabContent>Lesson</TabContent>
        </LinkTab>
      )}

      {hasWorksheet && worksheets && (
        <LinkTab
          active={activeTab === 'worksheet'}
          to={getTabLink('worksheet')}
          trackingId="Textbooks/SunflowerSubtopicDetailView/Worksheet"
        >
          <TabContent>Worksheet</TabContent>
        </LinkTab>
      )}

      {canSetInteractiveTab && (
        <LinkTab
          active={activeTab === 'interactive'}
          to={getTabLink('interactive')}
          trackingId="Textbooks/SunflowerSubtopicDetailView/Practice"
        >
          <TabContent>Practice</TabContent>
        </LinkTab>
      )}
      {hasEngageActivity && engageTasks && role === 'Teacher' && (
        <div style={{ marginLeft: 'auto' }}>
          <LinkTab
            active={activeTab === 'engageActivity'}
            to={getTabLink('engageActivity')}
            trackingId="Textbooks/SubtopicDetailView/Engage"
          >
            <FilledTab noBackground={activeTab === 'engageActivity'}>
              <EngageTaskIcon color={colors.cloudBurst} />
              Engage
            </FilledTab>
          </LinkTab>
        </div>
      )}
    </Tabs>
  );
}
