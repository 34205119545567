import { useId } from 'react';

export function RobotSvg({ style }: { style?: React.CSSProperties }) {
  const maskId = useId();
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M.366 14.157c0-3.227 4.04-3.425 9.024-3.425 4.984 0 9.024.198 9.024 3.425S14.374 20 9.39 20C4.406 20 .366 17.384.366 14.157Z"
        fill="#D2D2D2"
      />
      <mask
        id={maskId}
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="10"
        width="19"
        height="10"
      >
        <path
          d="M.366 14.157c0-3.227 4.04-3.425 9.024-3.425 4.984 0 9.024.198 9.024 3.425S14.374 20 9.39 20C4.406 20 .366 17.384.366 14.157Z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          d="M4.268 11.273c0-.51 2.293-.541 5.122-.541 2.829 0 5.122.031 5.122.54 0 .51-2.293.923-5.122.923-2.829 0-5.122-.413-5.122-.922Z"
          fill="#ACACAC"
        />
      </g>
      <path
        d="M18.414 6.15c0 3.397-4.04 3.606-9.024 3.606-4.984 0-9.024-.209-9.024-3.605C.366 2.754 4.406 0 9.39 0c4.984 0 9.024 2.754 9.024 6.15Z"
        fill="#D2D2D2"
      />
      <path
        d="M.366 3.475c0-1.112.75-2.091 1.836-2.329C6.352.24 9.218.296 13.15 1.15 14.242 1.387 15 2.37 15 3.487v1.027a2.439 2.439 0 0 1-2.44 2.44H2.806a2.439 2.439 0 0 1-2.44-2.44v-1.04Z"
        fill="#000"
      />
      <path
        d="M1.503 3.902c0 .809.36 1.464 0 1.464-.359 0-.65-.655-.65-1.464 0-.808.291-1.463.65-1.463.36 0 0 .655 0 1.463Z"
        fill="#fff"
        fillOpacity=".302"
      />
      <path
        d="M9.878 2.075c.673 0 1.22.765 1.22 1.708S10.55 5.49 9.877 5.49c-.674 0-1.22-.764-1.22-1.707 0-.943.546-1.708 1.22-1.708ZM4.024 2.075c.674 0 1.22.765 1.22 1.708S4.698 5.49 4.024 5.49c-.673 0-1.22-.764-1.22-1.707 0-.943.547-1.708 1.22-1.708Z"
        fill="#74FFD5"
      />
    </svg>
  );
}
