import styled from '@emotion/styled';

const IMAGE_WIDTH = 68;

const Image = styled('img', {
  shouldForwardProp: prop => prop !== 'width',
})<{ width?: number | undefined }>(({ width }) => ({
  width: width || IMAGE_WIDTH,
}));

export { Image };
