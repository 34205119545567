import type { ReactNode } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';

import { SkillsBookLayout } from './SkillsBookLayout';
import { TeacherTextbookLayout } from './TextbookLayout';
import { useTextbookTypeTabsContext } from './TextbookTypeTabs';

export type Props = {
  children: ReactNode;
};

export function TeacherTextbookTypesLayout({ children }: Props) {
  const {
    featureFlags: { showSkillsBookGridView },
  } = useViewer();

  const { selectedTab } = useTextbookTypeTabsContext();

  return showSkillsBookGridView && selectedTab === 'skillsbook' ? (
    <SkillsBookLayout>{children}</SkillsBookLayout>
  ) : (
    <TeacherTextbookLayout>{children}</TeacherTextbookLayout>
  );
}
