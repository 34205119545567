/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandHeader_lanternStudent = {
    readonly learningFocus: {
        readonly id: string;
        readonly title: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_student">;
    readonly " $refType": "StudentSkillsMapSubstrandHeader_lanternStudent";
};
export type StudentSkillsMapSubstrandHeader_lanternStudent$data = StudentSkillsMapSubstrandHeader_lanternStudent;
export type StudentSkillsMapSubstrandHeader_lanternStudent$key = {
    readonly " $data"?: StudentSkillsMapSubstrandHeader_lanternStudent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandHeader_lanternStudent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandHeader_lanternStudent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "learningFocus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudentSkillsMapSubstrandProficiencyIndicator_student"
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
(node as any).hash = 'f10ed61cb5224db6a2f1887c5c911fe8';
export default node;
