import { useState } from 'react';
import { commitMutation } from 'react-relay';
import type { PayloadError, Environment } from 'relay-runtime';

import { publicEnvironment } from 'ms-utils/relay';

type MaybeEmpty<T> = T | {};

export type UseMutationArgs<V> = {
  environment?: Environment;
  mutation: any; // tagged template literal
  defaultVariables?: MaybeEmpty<V>;
};

export type UseMutationResponse<T, V = any> = [
  (variables?: V) => Promise<T>,
  {
    loading: boolean;
    response: T | null;
    errors: null | Error | readonly PayloadError[];
  },
];

export default function useMutation<T extends {}, V>({
  environment = publicEnvironment,
  mutation,
  defaultVariables = {},
}: UseMutationArgs<V>): UseMutationResponse<T, V> {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<
    null | Error[] | readonly PayloadError[]
  >(null);
  const [response, setResponse] = useState<T | null>(null);
  const mutate = (givenVariables?: V) => {
    const variables = {
      ...defaultVariables,
      ...givenVariables,
    };
    return new Promise<T>((resolve, reject) => {
      setLoading(true);
      setResponse(null);
      setErrors(null);

      // This entire function was incorrectly defined, the type parameter T
      // should have been the MutationParameters type, but has erroneously
      // been defined as the Response type (ie. MutationParameters["response"]).
      // We cannot forward this type through to the commitMutation type parameter
      // because its wrong, so we need to synthetise the correct type which should
      // always have been passed to useMutation. Then we can pass this synthesized
      // type as the type argument. I cannot easily correct this function as ~300
      // callsites are passing the incorrect type to as the type argument to type
      // parameter T.
      type CorrectT = {
        readonly response: T;
        readonly variables: {};
        readonly rawResponse?: {};
      };

      commitMutation<CorrectT>(environment, {
        mutation,
        variables,
        onCompleted(response, errors) {
          setLoading(false);
          if (errors != null) {
            setErrors(errors);
            reject(errors);
          } else {
            setResponse(response);
            resolve(response);
          }
        },
        onError(error) {
          setLoading(false);
          setErrors([error]);
          reject(error);
        },
      });
    });
  };

  return [mutate, { loading, response, errors }];
}
