import { useState, useEffect, useCallback } from 'react';

export default function useFlagTimeout(delay: number) {
  // we use a number to represent a boolean flag, because we want to be able to cancel the timeout even when the boolean value is set from `true` to `true`
  const [_flag, _setFlag] = useState(0);

  useEffect(() => {
    if (_flag === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      _setFlag(0);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay, _flag]);

  const flag = _flag !== 0;

  const setFlag = useCallback((value: boolean) => {
    _setFlag(prev => (value ? prev + 1 : 0));
  }, []);

  return [flag, setFlag] as const;
}
