/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdaptiveTaskTeacherActionQueryVariables = {
    subtopicId: string;
    classId: string;
};
export type AdaptiveTaskTeacherActionQueryResponse = {
    readonly subtopic: {
        readonly mostRecentlyAssignedNewAdaptiveExperienceTask: {
            readonly id: string;
        } | null;
    } | null;
};
export type AdaptiveTaskTeacherActionQuery = {
    readonly response: AdaptiveTaskTeacherActionQueryResponse;
    readonly variables: AdaptiveTaskTeacherActionQueryVariables;
};



/*
query AdaptiveTaskTeacherActionQuery(
  $subtopicId: ID!
  $classId: ID!
) {
  subtopic(id: $subtopicId) {
    mostRecentlyAssignedNewAdaptiveExperienceTask(classId: $classId) {
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "classId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subtopicId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "classId",
      "variableName": "classId"
    }
  ],
  "concreteType": "NewAdaptiveExperienceTask",
  "kind": "LinkedField",
  "name": "mostRecentlyAssignedNewAdaptiveExperienceTask",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdaptiveTaskTeacherActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdaptiveTaskTeacherActionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17754bbbb0f475b20cd1feebecd381c4",
    "id": null,
    "metadata": {},
    "name": "AdaptiveTaskTeacherActionQuery",
    "operationKind": "query",
    "text": "query AdaptiveTaskTeacherActionQuery(\n  $subtopicId: ID!\n  $classId: ID!\n) {\n  subtopic(id: $subtopicId) {\n    mostRecentlyAssignedNewAdaptiveExperienceTask(classId: $classId) {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a8acfbb102154e287017bfe4005a8ba5';
export default node;
