/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useTemporaryLearningFocusQueryVariables = {};
export type useTemporaryLearningFocusQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly lastActiveStrandStatus: {
                readonly strand: {
                    readonly id: string;
                    readonly substrands: ReadonlyArray<{
                        readonly id: string;
                    }>;
                };
                readonly lastWorkedOnSubstrandGrowthCheckIn: {
                    readonly substrand: {
                        readonly id: string;
                    } | null;
                } | null;
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type useTemporaryLearningFocusQuery = {
    readonly response: useTemporaryLearningFocusQueryResponse;
    readonly variables: useTemporaryLearningFocusQueryVariables;
};



/*
query useTemporaryLearningFocusQuery {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        lastActiveStrandStatus {
          strand {
            id
            substrands {
              id
            }
          }
          lastWorkedOnSubstrandGrowthCheckIn {
            substrand {
              id
            }
            id
          }
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "substrands",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTemporaryLearningFocusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "lastActiveStrandStatus",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "lastWorkedOnSubstrandGrowthCheckIn",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useTemporaryLearningFocusQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "lastActiveStrandStatus",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "lastWorkedOnSubstrandGrowthCheckIn",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b775b16cc91f3005e0215b6288c4f62d",
    "id": null,
    "metadata": {},
    "name": "useTemporaryLearningFocusQuery",
    "operationKind": "query",
    "text": "query useTemporaryLearningFocusQuery {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        lastActiveStrandStatus {\n          strand {\n            id\n            substrands {\n              id\n            }\n          }\n          lastWorkedOnSubstrandGrowthCheckIn {\n            substrand {\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '65fcf6c35849364f8dc9acd3056c7b5c';
export default node;
