export function simpleDebounce<Fn extends (...args: unknown[]) => void>(
  fn: Fn,
  delay: number,
): (...args: Parameters<Fn>[]) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  return (...args: Parameters<Fn>[]): void => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => fn(...args), delay);
  };
}
