import { t } from '@lingui/macro';
import { graphql } from 'react-relay';

import { NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import { colors } from 'ms-styles/colors';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type { ConsolidatedMasteryLevelType as MasteryLevelType } from './__generated__/masteryLevelConsolidatedFragment.graphql';

export type { MasteryLevelType };
// eslint-disable-next-line no-unused-expressions
graphql`
  fragment masteryLevelConsolidatedFragment on MasteryLevel {
    masteryLevel
  }
`;
export const masteryColors = {
  deepKoamaru: colors.deepKoamaru,
  governorBay: colors.governorBay,
  melrose: colors.melrose,
  linkWater: colors.linkWater,
  // Not started can be either of the following two colors
  white: colors.white,
  porcelain: colors.porcelain,
  eggplant10: colors.eggplant10,
  eggplant20: colors.eggplant20,
  eggplant90: colors.eggplant90,
  eggplant: colors.eggplant,
  astronaut: colors.astronaut,
};
export type MasteryColor = keyof typeof masteryColors;
type NamedColor = keyof typeof colors;
export const getMasteryLevelProps = (
  level: MasteryLevelType,
  useAlternateBackgroundColor?: boolean,
): {
  title: string;
  score: number;
  achievement: string;
  background: MasteryColor;
  color: NamedColor;
  description: string;
} => {
  switch (level) {
    case 'MASTERED':
      return {
        title: 'Mastered',
        score: 100,
        achievement: 'Mastery',
        background: 'astronaut',
        color: 'white',
        description: '100',
      };
    case 'PROFICIENT':
      return {
        title: 'Proficient',
        score: 75,
        achievement: 'Proficiency',
        background: 'eggplant',
        color: 'white',
        description: '75 - 99',
      };
    case 'FAMILIAR':
      return {
        title: 'Familiar',
        score: 50,
        achievement: 'Familiarity',
        background: 'eggplant90',
        color: 'white',
        description: '50 - 74',
      };
    case 'EMERGING':
      return {
        title: 'Emerging',
        score: 25,
        achievement: 'Emergence',
        background: 'eggplant20',
        color: 'cloudBurst',
        description: '25 - 49',
      };
    case 'EXPLORING':
      return {
        title: 'Exploring',
        // TODO: Is this right? There's no way to differentiate 0 from 0 for EXPLORING and NOT_STARTED.
        // 0.0001 is probably fine here tbh
        score: 0.0001,
        achievement: 'Exploration',
        background: 'eggplant10',
        color: 'cloudBurst',
        description: '0 - 24',
      };
    case 'NOT_STARTED':
      return {
        title: 'No activity',
        score: 0,
        achievement: 'Nothing yet',
        background: useAlternateBackgroundColor ? 'white' : 'porcelain',
        color: 'cloudBurst',
        description: '0',
      };
    default:
      assertUnreachable(level, `Unknown MasteryLevel ${level}`);
  }
};
export const NOT_STARTED_SCORE = getMasteryLevelProps('NOT_STARTED').score;
export const EXPLORING_SCORE = getMasteryLevelProps('EXPLORING').score;
export const FAMILIAR_SCORE = getMasteryLevelProps('FAMILIAR').score;
export const PROFICIENT_SCORE = getMasteryLevelProps('PROFICIENT').score;
export const MASTERED_SCORE = getMasteryLevelProps('MASTERED').score;
export const EMERGING_SCORE = getMasteryLevelProps('EMERGING').score;
export function convertMasteryLevel(gamifiedMastery: number): MasteryLevelType {
  if (gamifiedMastery < EXPLORING_SCORE) return 'NOT_STARTED';
  if (gamifiedMastery < EMERGING_SCORE) return 'EXPLORING';
  if (gamifiedMastery < FAMILIAR_SCORE) return 'EMERGING';
  if (gamifiedMastery < PROFICIENT_SCORE) return 'FAMILIAR';
  if (gamifiedMastery < MASTERED_SCORE) return 'PROFICIENT';
  return 'MASTERED';
}
export function getLastMasteryLevelScore(gamifiedMastery: number): number {
  const level = convertMasteryLevel(gamifiedMastery);
  return getMasteryLevelProps(level).score;
}
export function getMasteryLevelAchievement(gamifiedMastery: number): string {
  const level = convertMasteryLevel(gamifiedMastery);
  return getMasteryLevelProps(level).achievement;
}
export function getMasteryLevelTitle(gamifiedMastery: number): string {
  // This is a special value that is used in the CATFA and Flyout
  if (gamifiedMastery === NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE)
    return 'No target mastery';
  const level = convertMasteryLevel(gamifiedMastery);
  return getMasteryLevelProps(level).title;
}
export function getNextMasteryLevelScore(
  gamifiedMastery: number,
): number | null | undefined {
  const level = convertMasteryLevel(gamifiedMastery);
  const position = MASTERY_LEVELS_DESCENDING.indexOf(level);
  if (position > 0) {
    const level = MASTERY_LEVELS_DESCENDING[position - 1]!;
    return getMasteryLevelProps(level).score;
  }
  return null;
}
/**
 * Consolidated Mastery Level:
 * to be used across Mathspace and Sana reports.
 */
export type MasteryLevel =
  | 'NOT_STARTED'
  | 'EXPLORING'
  | 'EMERGING'
  | 'FAMILIAR'
  | 'PROFICIENT'
  | 'MASTERED';
export const MASTERY_LEVELS_DESCENDING = [
  'MASTERED',
  'PROFICIENT',
  'FAMILIAR',
  'EMERGING',
  'EXPLORING',
  'NOT_STARTED',
] as const;
// TODO: Remove once the mastery is consolidated
export const MASTERY_LEVELS_WITHOUT_MASTERED_DESCENDING = [
  'PROFICIENT',
  'FAMILIAR',
  'EMERGING',
  'EXPLORING',
  'NOT_STARTED',
] as const;
export const MASTERY_LEVEL_TITLES: Record<MasteryLevel, string> = {
  NOT_STARTED: 'No activity',
  EXPLORING: 'Exploring',
  EMERGING: 'Emerging',
  FAMILIAR: 'Familiar',
  PROFICIENT: 'Proficient',
  MASTERED: 'Mastered',
};
/**
 * Different mastery levels descriptions are used across the app,
 * depending on the context, e.g. Student Skills Map, Lantern Teacher Reports
 */
export const SKILLS_MAP_MASTERY_LEVEL_DESCRIPTIONS: Record<
  MasteryLevel,
  string | null
> = {
  NOT_STARTED: null,
  EXPLORING:
    "This is where you'll start. If you're new to the content, go through the lesson and use the hints.",
  EMERGING: "You're starting to show understanding of the content.",
  FAMILIAR: 'You sometimes answer questions on this skill correctly.',
  PROFICIENT: 'You often answer questions on this skill correctly.',
  MASTERED: 'You consistently answer questions on this skill correctly.',
};
export const TEACHER_CURRICULUM_REPORT_MASTERY_LEVEL_DESCRIPTIONS = {
  MASTERED: t`Student has demonstrated all of the skills required for this outcome.`,
  PROFICIENT: t`Student has demonstrated most of the skills required for this outcome.`,
  FAMILIAR: t`Student has demonstrated half of the skills required for this outcome.`,
  EMERGING: t`Student has demonstrated some skills required for this outcome.`,
  EXPLORING: t`Student has not demonstrated the skills required for this outcome yet.`,
  NOT_STARTED: t`Student has not been assessed on this outcome yet.`,
};
export const TEACHER_OVERVIEW_REPORT_MASTERY_LEVEL_DESCRIPTIONS = {
  MASTERED: t`Student has demonstrated proficiency for all outcomes in this grade.`,
  PROFICIENT: t`Student has demonstrated proficiency for most outcomes in this grade.`,
  FAMILIAR: t`Student has demonstrated proficiency for half of the outcomes in this grade.`,
  EMERGING: t`Student has demonstrated proficiency for some outcomes in this grade.`,
  EXPLORING: t`Student has not demonstrated proficiency for the outcomes in this grade yet.`,
  NOT_STARTED: t`Student has not been assessed for outcomes in this grade yet.`,
};
export const TEACHER_REPORT_MASTERY_LEVEL_DESCRIPTIONS = {
  MASTERED: t`Student can apply the concepts to solve more challenging problems.`,
  PROFICIENT: t`Student can apply the concepts to solve most problems.`,
  FAMILIAR: t`Student can apply the concepts to solve simple problems.`,
  EMERGING: t`Student can identify some of the basic concepts.`,
  EXPLORING: t`Student is just beginning to practice.`,
  NOT_STARTED: t`Student has not started.`,
};
export const STUDENT_SKILLS_REPORT_MASTERY_LEVEL_DESCRIPTIONS = {
  MASTERED: 'You consistently answer questions on this skill correctly.',
  PROFICIENT: 'You often answer questions on this skill correctly.',
  FAMILIAR: 'You sometimes answer questions on this skill correctly.',
  EMERGING:
    'You haven’t answered questions relating to this skill correctly yet.',
  EXPLORING:
    'You haven’t answered questions relating to this skill correctly yet.',
  NOT_STARTED: '',
};
/**
 * Sana proficiency levels.
 * The proficiency is a number between 0 and 1.
 * The way proficiency `p` corresponds to the mastery level is:
 * - MASTERED: 0.95 <= p <= 1
 * - PROFICIENT: 0.75 <= p < 0.95
 * - FAMILIAR: 0.5 <= p < 0.75
 * - EMERGING: 0.3 <= p < 0.5 (might be possible to change 0.3 to 0.25 in the future)
 * - EXPLORING: 0 <= p < 0.3
 * - NOT_STARTED: p = `null`
 */
export type SanaProficiencyValue = number | null | undefined;
export const SANA_PROFICIENCY_BANDS = {
  mastered: 0.95,
  proficient: 0.75,
  familiar: 0.5,
  emerging: 0.3,
  exploring: 0,
};
export function mapSanaProficiencyValueToMasteryLevel(
  value: SanaProficiencyValue,
): MasteryLevel | undefined {
  if (value === undefined) return undefined;
  if (value === null) return 'NOT_STARTED';
  if (value < SANA_PROFICIENCY_BANDS.emerging) return 'EXPLORING';
  if (value < SANA_PROFICIENCY_BANDS.familiar) return 'EMERGING';
  if (value < SANA_PROFICIENCY_BANDS.proficient) return 'FAMILIAR';
  if (value < SANA_PROFICIENCY_BANDS.mastered) return 'PROFICIENT';
  return 'MASTERED';
}

export function mapSanaProficiencyValueToMasteryLevelNumeric(
  value: SanaProficiencyValue,
): 0 | 1 | 2 | 3 | 4 {
  if (value == null) return 0;
  if (value < SANA_PROFICIENCY_BANDS.emerging) return 0;
  if (value < SANA_PROFICIENCY_BANDS.familiar) return 1;
  if (value < SANA_PROFICIENCY_BANDS.proficient) return 2;
  if (value < SANA_PROFICIENCY_BANDS.mastered) return 3;
  return 4;
}
