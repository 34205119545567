import type { Value } from 'ms-components/math/LatexAnswer';
import type { subproblemFragments_LatexAnswerConfig } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_LatexAnswerConfig.graphql';
import type { subproblemMutations_AnswerLatexMutationVariables } from 'ms-pages/Work/Subproblem/__generated__/subproblemMutations_AnswerLatexMutation.graphql';
import { unwrap } from 'ms-utils/typescript-utils';

export type LatexAnswerFields = subproblemFragments_LatexAnswerConfig['fields'];
type LatexAnswerVariable =
  subproblemMutations_AnswerLatexMutationVariables['answer'];
export type Props = {
  value: Value;
};
/**
 * Transforms a LatexAnswerConfig into the props for the corresponding
 * `LatexAnswer` component.
 *
 * Input:  [{ answerKey: '1', answerContent: '3x', substatus: 'INCORRECT' }]
 * Output: { value: { '1': { latex: '3x', substatus: 'INCORRECT' } }
 *
 * @param {*} fields Subproblem's answer input config.
 * @returns {*} Props for `LatexAnswer` component.
 */
export function toInternalValueFormat(fields: LatexAnswerFields): Props {
  const answerKeys = fields.map(f => f.answerKey);
  const answerValues = fields.map(
    f =>
      ({
        latex: f.answerContent,
        substatus: f.substatus,
      }) as const,
  );
  const internalValueFormat: Value = {};
  for (let i = 0; i < answerKeys.length; i++) {
    // The arrays are guaranteed to be the same length as they are both
    // derived from a .map over the same array fields.
    const key = unwrap(answerKeys[i]);
    const value = unwrap(answerValues[i]);
    internalValueFormat[key] = value;
  }
  return { value: internalValueFormat };
}
/**
 * Transforms the props for the  `LatexAnswer` component into the shape of the
 * InputObject for the corresponding submitAnswer mutation.
 *
 * Input: { value: { '1': { latex: '3x', substatus: 'INCORRECT' } }
 * Output: [{ answerKey: '1', answerContent: '3x', substatus: 'INCORRECT' }]
 *
 * @param {*} fields Subproblem's answer input config.
 * @returns {*} Props for `LatexAnswer` component.
 */
export function fromInternalValueFormat({ value }: Props): LatexAnswerVariable {
  const keys = Object.keys(value);
  const values = Object.values(value).map(v => ({
    answerContent: v.latex,
    substatus: v.substatus,
  }));
  return keys.map((key, i) => ({ ...unwrap(values[i]), answerKey: key }));
}
