/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SkillsGridQueryVariables = {
    syllabusId: string;
    search: string;
    isSearch: boolean;
};
export type SkillsGridQueryResponse = {
    readonly syllabus?: {
        readonly id: string;
        readonly topics: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly subtopics: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly title: string;
                            };
                        }>;
                    };
                };
            }>;
        };
    } | null | undefined;
    readonly textbookSearch?: {
        readonly id: string;
        readonly topics: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly topic: {
                        readonly id: string;
                        readonly title: string;
                    };
                    readonly subtopics: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly subtopic: {
                                    readonly id: string;
                                    readonly title: string;
                                };
                            };
                        }>;
                    };
                };
            }>;
        };
    } | null | undefined;
};
export type SkillsGridQuery = {
    readonly response: SkillsGridQueryResponse;
    readonly variables: SkillsGridQueryVariables;
};



/*
query SkillsGridQuery(
  $syllabusId: ID!
  $search: String!
  $isSearch: Boolean!
) {
  syllabus(id: $syllabusId) @skip(if: $isSearch) {
    id
    topics(first: 1000) {
      edges {
        node {
          id
          title
          subtopics(first: 1000) {
            edges {
              node {
                id
                title
              }
            }
          }
        }
      }
    }
  }
  textbookSearch(syllabusId: $syllabusId, query: $search) @include(if: $isSearch) {
    id
    topics(first: 1000) {
      edges {
        node {
          topic {
            id
            title
          }
          subtopics(first: 1000) {
            edges {
              node {
                subtopic {
                  id
                  title
                }
                id
              }
            }
          }
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSearch"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "syllabusId"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "condition": "isSearch",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "syllabusId"
        }
      ],
      "concreteType": "Syllabus",
      "kind": "LinkedField",
      "name": "syllabus",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": (v4/*: any*/),
          "concreteType": "TopicConnection",
          "kind": "LinkedField",
          "name": "topics",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TopicEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Topic",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": (v4/*: any*/),
                      "concreteType": "SubtopicConnection",
                      "kind": "LinkedField",
                      "name": "subtopics",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubtopicEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Subtopic",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": (v6/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "subtopics(first:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "topics(first:1000)"
        }
      ],
      "storageKey": null
    }
  ]
},
v8 = [
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "syllabusId",
    "variableName": "syllabusId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Topic",
  "kind": "LinkedField",
  "name": "topic",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Subtopic",
  "kind": "LinkedField",
  "name": "subtopic",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SkillsGridQuery",
    "selections": [
      (v7/*: any*/),
      {
        "condition": "isSearch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "SyllabusSearchResult",
            "kind": "LinkedField",
            "name": "textbookSearch",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TopicSearchResultConnection",
                "kind": "LinkedField",
                "name": "topics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicSearchResultEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopicSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "SubtopicSearchResultConnection",
                            "kind": "LinkedField",
                            "name": "subtopics",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SubtopicSearchResultEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SubtopicSearchResult",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "subtopics(first:1000)"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "topics(first:1000)"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SkillsGridQuery",
    "selections": [
      (v7/*: any*/),
      {
        "condition": "isSearch",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "SyllabusSearchResult",
            "kind": "LinkedField",
            "name": "textbookSearch",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "TopicSearchResultConnection",
                "kind": "LinkedField",
                "name": "topics",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopicSearchResultEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TopicSearchResult",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": (v4/*: any*/),
                            "concreteType": "SubtopicSearchResultConnection",
                            "kind": "LinkedField",
                            "name": "subtopics",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SubtopicSearchResultEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SubtopicSearchResult",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "subtopics(first:1000)"
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "topics(first:1000)"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1fd343a3330d8ed398ad217a8f2a2841",
    "id": null,
    "metadata": {},
    "name": "SkillsGridQuery",
    "operationKind": "query",
    "text": "query SkillsGridQuery(\n  $syllabusId: ID!\n  $search: String!\n  $isSearch: Boolean!\n) {\n  syllabus(id: $syllabusId) @skip(if: $isSearch) {\n    id\n    topics(first: 1000) {\n      edges {\n        node {\n          id\n          title\n          subtopics(first: 1000) {\n            edges {\n              node {\n                id\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n  textbookSearch(syllabusId: $syllabusId, query: $search) @include(if: $isSearch) {\n    id\n    topics(first: 1000) {\n      edges {\n        node {\n          topic {\n            id\n            title\n          }\n          subtopics(first: 1000) {\n            edges {\n              node {\n                subtopic {\n                  id\n                  title\n                }\n                id\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1d5dea70849b322b1658531c37a13853';
export default node;
