import { useState } from 'react';
import type { ReactNode } from 'react';

import { fontFamily } from 'ms-styles/base';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { useClickOutsideRef } from 'ms-ui-primitives/ClickOutside/hook';
import Input from 'ms-ui-primitives/Input';
import { styled } from 'ms-utils/emotion';

import { Dropdown } from './Dropdown';
import Pill from './Pill';
import SelectedPills from './SelectedPills';

const Root = styled({
  fontFamily: fontFamily.body,
});

const PillWrapper = styled({
  ':not(:last-child)': {
    marginBottom: 2 * BASE_UNIT,
  },
});

export type Item = {
  readonly id: string;
  readonly title: string;
  readonly isDisabled?: boolean;
};
export type Items = ReadonlyArray<Item>;

type Props = {
  items: Items;
  selectedItems: Items;
  removeSelectedItem?: (id: string) => void;
  onDropdownSelect: (arg: Item) => void;
  placeholder?: string;
  icon?: ReactNode;
  showErrorState?: boolean;
  children?: ReactNode;
  pillColor?: 'blue' | 'eggplant';
  withSearch?: boolean;
};

const InputSelector = ({
  items,
  selectedItems,
  removeSelectedItem,
  onDropdownSelect,
  placeholder = 'Click here to select from list',
  icon,
  showErrorState,
  children,
  pillColor = 'eggplant',
  withSearch = true,
}: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const clickOutsideRef = useClickOutsideRef(() => setShowDropdown(false));

  const searchedItems = items.filter(({ title }) =>
    title.toLowerCase().includes(searchTerm),
  );

  return (
    <>
      <SelectedPills
        items={selectedItems}
        removeItem={removeSelectedItem}
        icon={icon}
        pillColor={pillColor}
      />
      <Root ref={clickOutsideRef}>
        {withSearch ? (
          <Input
            placeholder={placeholder}
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value.toLowerCase())}
            onFocus={() => setShowDropdown(true)}
            showErrorState={showErrorState}
          />
        ) : (
          <Button
            type="secondary"
            isBlock
            onClick={() => {
              setShowDropdown(isOpen => !isOpen);
            }}
          >
            {showDropdown ? 'Close' : placeholder}
          </Button>
        )}
        {showDropdown &&
          (searchedItems.length > 0 ? (
            <Dropdown>
              {searchedItems.map(({ id, title, isDisabled: _isDisabled }) => {
                const isDisabled =
                  Boolean(_isDisabled) ||
                  selectedItems.find(item => item.id === id) != null;
                return (
                  <PillWrapper key={id}>
                    <Pill
                      isBlue={pillColor === 'blue'}
                      label={title}
                      icon={icon}
                      isDisabled={isDisabled}
                      onClick={
                        isDisabled
                          ? null
                          : () => {
                              onDropdownSelect({ id, title });
                            }
                      }
                    />
                  </PillWrapper>
                );
              })}
              {children}
            </Dropdown>
          ) : (
            <Dropdown>No items to select</Dropdown>
          ))}
      </Root>
    </>
  );
};

export default InputSelector;
