import { graphql } from 'react-relay';

import useMutation from 'ms-pages/Lantern/utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-pages/Lantern/utils/relay/useMutation';

import type {
  useCreateLanternProfileMutationResponse,
  useCreateLanternProfileMutationVariables,
} from './__generated__/useCreateLanternProfileMutation.graphql';

const mutation = graphql`
  mutation useCreateLanternProfileMutation {
    createLanternProfile {
      user {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateLanternProfile(): UseMutationResponse<
  useCreateLanternProfileMutationResponse,
  useCreateLanternProfileMutationVariables
> {
  return useMutation({
    mutation,
  });
}
