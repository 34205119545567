import { useMemo } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  BodyM,
  BodyS,
  Bold,
  HeadingM,
  HeadingS,
} from 'ms-pages/Lantern/primitives/Typography';
import AboutPremiumButton from 'ms-pages/Sunflower/components/AboutPremiumButton';
import {
  ModalFeatureInfoBox,
  SunflowerUpgradeInfoBox,
} from 'ms-pages/Sunflower/components/FeatureInfoBox/FeatureInfoBox';
import {
  TEXTBOOK_INFOBOX_FEATURE,
  SUNFLOWER_CUSTOM_TASKS_FOR_INDIVIDUAL_STUDENTS_INFOBOX_FEATURE,
} from 'ms-pages/Sunflower/components/NewFeatures/features';
import useTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import SubtopicOutcomesAccessibilityMode from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicOutcomesAccessibilityMode';
import { breakPoints, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Separator from 'ms-ui-primitives/Separator';
import {
  HVSpacer,
  VSpacer,
  HSpacer,
  HStack,
  VStack,
} from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import { getMasteryLevelTitle } from 'ms-utils/masteryLevel';

import AdaptiveTaskAction, {
  AdaptiveTaskSecondaryAction,
} from './AdaptiveTaskAction';
import type { SunflowerSubtopicDetailHeaderSubtopic$key } from './__generated__/SunflowerSubtopicDetailHeaderSubtopic.graphql';
import modalImage from './assets/modal_feature.svg';

const HEADER_VERTICAL_PADDING = 15 * BASE_UNIT;
const HEADER_LATERAL_PADDING = 6 * BASE_UNIT;
const HEADER_PADDING_MOBILE = 4 * BASE_UNIT;
const HEADER_TOP_PADDING_MOBILE = 19 * BASE_UNIT - 1;
const HeaderContentWrapper: FunctionComponent<{
  children: ReactNode;
}> = styled({
  default: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top right',
    backgroundColor: 'transparent',
    color: colors.cloudBurst,
  },
});
const HeaderContent: FunctionComponent<{
  children: ReactNode;
}> = styled({
  default: {
    fontFamily: fontFamily.body,
    maxWidth: breakPoints.medium,
    margin: '0 auto',
    paddingLeft: HEADER_LATERAL_PADDING,
    paddingRight: HEADER_LATERAL_PADDING,
    paddingTop: HEADER_VERTICAL_PADDING,
    paddingBottom: 0,
    [`@media(max-width: ${breakPoints.small}px)`]: {
      padding: HEADER_PADDING_MOBILE,
      paddingTop: HEADER_TOP_PADDING_MOBILE,
      paddingBottom: 0,
    },
  },
});
const TopPanelsWrapper = styled({
  display: 'flex',
});
const TOPIC_BADGE_SIZE = 70;
const TOPIC_BADGE_SIZE_MOBILE = 64;
const TopicBadge = styled(
  {
    height: TOPIC_BADGE_SIZE,
    width: TOPIC_BADGE_SIZE,
    [`@media(max-width: ${breakPoints.small}px)`]: {
      height: TOPIC_BADGE_SIZE_MOBILE,
      width: TOPIC_BADGE_SIZE_MOBILE,
    },
  },
  'img',
);
type Props = {
  subtopic: SunflowerSubtopicDetailHeaderSubtopic$key;
};
export default function SunflowerSubtopicDetailHeader({
  subtopic: _subtopic,
}: Props) {
  const subtopic = useFragment(
    graphql`
      fragment SunflowerSubtopicDetailHeaderSubtopic on Subtopic {
        id
        hasAdaptivePractice
        pk
        title
        outcomes {
          id
          code
          description
        }
        gamifiedMastery
        topic {
          id
          pk
          title
          badgeUrl
          syllabus {
            id
            pk
            title
            coverImageUrl
            ...LanguageSelector_availableLanguages
          }
        }
      }
    `,
    _subtopic,
  );
  const { subtopicTitle, topicTitle, outcomes } = useMemo(
    () => ({
      topicTitle: subtopic.topic.title,
      subtopicTitle: subtopic.title,
      outcomes: subtopic.outcomes,
    }),
    [subtopic],
  );
  const { role } = useViewer();
  const {
    teacherPreference: { dismissedOnboardingFeatures },
  } = useTeacherContext();
  const hasTextbookInfoboxFeatureBeenDismissed =
    dismissedOnboardingFeatures.includes(TEXTBOOK_INFOBOX_FEATURE.key);
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth != null && windowWidth < breakPoints.small;
  const TopicTitle = isMobileView ? BodyS : BodyM;
  const SubtopicTitle = isMobileView ? HeadingS : HeadingM;
  return (
    <HeaderContentWrapper>
      <HeaderContent>
        <VStack>
          {role === 'Teacher' && (
            <HStack justify="end">
              <AboutPremiumButton trackingId="SunflowerSubtopicDetailHeader/AboutPremium" />
            </HStack>
          )}
          <HStack>
            <TopicBadge src={subtopic.topic.badgeUrl} role="presentation" />

            <HSpacer width={16} />
            <VStack>
              <TopicTitle color="grey90">
                <Bold translate="no">{topicTitle}</Bold>
              </TopicTitle>
              <VSpacer height={isMobileView ? 4 : 8} grow={!isMobileView} />
              <SubtopicTitle translate="no">{subtopicTitle}</SubtopicTitle>
            </VStack>
          </HStack>
        </VStack>
        {
          /*
          ADAPTIVE TASK ACTIONS
          Available only if `subtopic.hasAdaptivePractice` is true
       */
          subtopic.hasAdaptivePractice && (
            <>
              <Separator size={isMobileView ? 4 : 8} />
              <HStack center>
                <AdaptiveTaskAction
                  subtopicId={subtopic.id}
                  // New feature: task title will be "subtopicTitle < topicTitle"
                  subtopicTitle={`${subtopicTitle} < ${topicTitle}`}
                />

                {role === 'Student' && (
                  <>
                    <HVSpacer width={4} height={4} />
                    <MasteryLevelVolumeIndicator
                      masteryLevel={subtopic.gamifiedMastery}
                      showTooltip
                    />
                    <HVSpacer width={4} height={4} />
                    {getMasteryLevelTitle(subtopic.gamifiedMastery)}
                  </>
                )}

                <HVSpacer width={4} height={4} grow />

                <AdaptiveTaskSecondaryAction subtopicId={subtopic.id} />
              </HStack>
            </>
          )
        }

        {role === 'Teacher' && outcomes.length > 0 && (
          <>
            <Separator size={8} />
            <TopPanelsWrapper>
              <SubtopicOutcomesAccessibilityMode outcomes={outcomes} />
            </TopPanelsWrapper>
          </>
        )}

        <VSpacer height={isMobileView ? 4 : 12} />

        {role === 'Teacher' && (
          <>
            <ModalFeatureInfoBox
              modalImageUrl={modalImage}
              feature={TEXTBOOK_INFOBOX_FEATURE}
              modalDescriptionText={`There are two types of adaptive question sets on Mathspace. Students can complete skills check-ins to monitor growth. These are self-started from the student interface. Students can also complete practice questions via adaptive tasks. These question sets can be self-started, or assigned by teachers. In both cases, questions adapt to the meet the student’s level of understanding.${'\n\n'}Something fun! In adaptive practice, students collect stars for effort. Their topic mastery score updates based on performance.`}
              description="When you assign adaptive practice, questions scale in difficulty according to the individual student."
              helpUrl="https://help.mathspace.co/en/articles/6132375-new-adaptive-tasks"
            />

            {hasTextbookInfoboxFeatureBeenDismissed && (
              <SunflowerUpgradeInfoBox
                title="Create custom tasks for individual students"
                description="Try for free with a trial of the School Plus plan"
                feature={
                  SUNFLOWER_CUSTOM_TASKS_FOR_INDIVIDUAL_STUDENTS_INFOBOX_FEATURE
                }
              />
            )}

            <VSpacer height={12} />
          </>
        )}
      </HeaderContent>
    </HeaderContentWrapper>
  );
}
