import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { captureTeacherSignup } from 'ms-helpers/LinkedInInsightTag';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useValidateSunflowerUserDetails from 'ms-pages/Signup/Sunflower/useValidateSunflowerUserDetails';
import { noop } from 'ms-utils/misc';
import { getSunflowerSchoolSelectionUrl } from 'ms-utils/urls';

import { ProgressStepper } from './ProgressStepper';
import { UserForm } from './UserForm';

export default function TeacherSignup() {
  const { trackStructEvent } = useSnowplow();
  const { state, dispatch } = useSignupState();

  const [validateUserDetails, { response: validationResponse }] =
    useValidateSunflowerUserDetails();

  const { push } = useHistory();

  useEffect(() => {
    const errors = validationResponse?.validateSunflowerUserDetails?.errors;
    if (validationResponse != null && errors?.length === 0) {
      push(getSunflowerSchoolSelectionUrl({ origin: 'teacher' }));
    }
  }, [push, validationResponse]);

  return (
    <>
      <ProgressStepper step={2} />
      <UserForm
        role="teacher"
        title="Teacher sign up"
        firstName={state.firstName}
        lastName={state.lastName}
        email={state.email}
        password={state.password}
        onFirstNameChange={value => dispatch({ type: 'setFirstName', value })}
        onLastNameChange={value => dispatch({ type: 'setLastName', value })}
        onEmailChange={value => dispatch({ type: 'setEmail', value })}
        onPasswordChange={value => dispatch({ type: 'setPassword', value })}
        onAgeConfirmedChange={noop}
        isDisabled={
          state.firstName === '' ||
          state.lastName === '' ||
          state.email === '' ||
          state.password === ''
        }
        onSubmit={() => {
          captureTeacherSignup();
          trackStructEvent({
            category: 'signup_sunflower',
            action: 'submitted_account_details',
            label: 'teacher',
          });
          dispatch({ type: 'clearErrors' });
          validateUserDetails({});
        }}
        submitButtonLabel="Continue"
        errors={state.responseErrors}
        errorMessage={
          state.hasErrors ? 'Please correct the errors to continue.' : undefined
        }
        trackingIdPrefix="SignupPage/TeacherSignup"
      />
    </>
  );
}
