/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type createTaskTemplateMutationVariables = {
    schoolId: string;
    title: string;
    description: string;
    problemContentIds: Array<string>;
    taskTemplateGroupIds: Array<string>;
};
export type createTaskTemplateMutationResponse = {
    readonly createSchoolTaskTemplate: {
        readonly errors: ReadonlyArray<{
            readonly message: string;
        }>;
        readonly taskTemplate: {
            readonly id: string;
        } | null;
    };
};
export type createTaskTemplateMutation = {
    readonly response: createTaskTemplateMutationResponse;
    readonly variables: createTaskTemplateMutationVariables;
};



/*
mutation createTaskTemplateMutation(
  $schoolId: ID!
  $title: String!
  $description: String!
  $problemContentIds: [ID!]!
  $taskTemplateGroupIds: [ID!]!
) {
  createSchoolTaskTemplate(title: $title, description: $description, schoolId: $schoolId, problemContentIds: $problemContentIds, taskTemplateGroupIds: $taskTemplateGroupIds) {
    errors {
      message
    }
    taskTemplate {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "problemContentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "schoolId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskTemplateGroupIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "problemContentIds",
        "variableName": "problemContentIds"
      },
      {
        "kind": "Variable",
        "name": "schoolId",
        "variableName": "schoolId"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateGroupIds",
        "variableName": "taskTemplateGroupIds"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "CreateSchoolTaskTemplateResult",
    "kind": "LinkedField",
    "name": "createSchoolTaskTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplate",
        "kind": "LinkedField",
        "name": "taskTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createTaskTemplateMutation",
    "selections": (v5/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "createTaskTemplateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b0a452aa48339a1b73cffa6e85629ee9",
    "id": null,
    "metadata": {},
    "name": "createTaskTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation createTaskTemplateMutation(\n  $schoolId: ID!\n  $title: String!\n  $description: String!\n  $problemContentIds: [ID!]!\n  $taskTemplateGroupIds: [ID!]!\n) {\n  createSchoolTaskTemplate(title: $title, description: $description, schoolId: $schoolId, problemContentIds: $problemContentIds, taskTemplateGroupIds: $taskTemplateGroupIds) {\n    errors {\n      message\n    }\n    taskTemplate {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '091dd2bb8468e49bb316d5259b4f64d6';
export default node;
