import { useEffect, useCallback, useMemo } from 'react';

import { useRouter } from 'ms-utils/hooks/useRouter';
import { serialize, deserialize } from 'ms-utils/urls/queryParams';

type SetValue<T> = (value: T) => void;
type GetLinkBySetValue<T> = (value: T) => string;
type Validate<T> = (value: T | string | null | undefined) => value is T;

export default function useQueryParam<T extends string | null | undefined>(
  paramName: string,
  defaultValue: T,
  validate: Validate<T>,
): [T, SetValue<T>, GetLinkBySetValue<T>] {
  const {
    history,
    location: { pathname, search },
  } = useRouter();

  const params = useMemo(() => deserialize(search), [search]);
  const deserializedValue = params[paramName];
  const value = validate(deserializedValue) ? deserializedValue : defaultValue;

  const getLinkByNewValue = useCallback(
    (newValue: T) => {
      const newSearch = serialize({
        ...deserialize(search),
        [paramName]: newValue,
      });
      return `${pathname}${newSearch}`;
    },
    [paramName, pathname, search],
  );

  const setQueryParamValue = useCallback(
    (newValue: T) => {
      const newSearch = serialize({
        ...params,
        [paramName]: newValue,
      });
      if (search !== newSearch) {
        history.replace({
          pathname,
          search: newSearch,
        });
      }
    },
    [history, paramName, pathname, params, search],
  );

  useEffect(() => {
    if (
      // we need to be careful with equality as we want to treat null and undefined as the same.
      // We don't want to run setQueryParamValue if value is null and deserializedValue is undefined
      (value == null && deserializedValue != null) ||
      (value != null && value !== deserializedValue)
    ) {
      setQueryParamValue(defaultValue);
    }
  }, [deserializedValue, value, defaultValue, setQueryParamValue]);

  return [value, setQueryParamValue, getLinkByNewValue];
}

// This is used by `useUncheckedQueryParam` for when there is no need to validate the query param
const skipValidationValidator = (
  value: string | null | undefined,
): value is string | null | undefined =>
  value == null || typeof value === 'string';

export function useUncheckedQueryParam(
  paramName: string,
  defaultValue: string | null | undefined = null,
): [
  string | null | undefined,
  SetValue<string | null | undefined>,
  GetLinkBySetValue<string | null | undefined>,
] {
  return useQueryParam(paramName, defaultValue, skipValidationValidator);
}

export function useQueryParamFlag(
  paramName: string,
  defaultValue: boolean = false,
): {
  flag: boolean;
  on: () => void;
  off: () => void;
  toggle: () => void;
  getLinkBySetValue: GetLinkBySetValue<boolean>;
} {
  const [_value, _setValue, _getLinkBySetValue] = useQueryParam<
    'true' | null | undefined
  >(
    paramName,
    defaultValue ? 'true' : null,
    (v): v is 'true' | null | undefined => v === 'true' || v == null,
  );
  const value = _value === 'true';
  const on = useCallback(() => {
    _setValue('true');
  }, [_setValue]);
  const off = useCallback(() => {
    _setValue(null);
  }, [_setValue]);
  const toggle = useCallback(() => {
    if (value) {
      off();
    } else {
      on();
    }
  }, [value, on, off]);
  const getLinkBySetValue = useCallback(
    (newValue: boolean) => {
      return _getLinkBySetValue(newValue ? 'true' : null);
    },
    [_getLinkBySetValue],
  );

  return { flag: value, on, off, toggle, getLinkBySetValue };
}
