import { useEffect, useState } from 'react';

import ConfirmModalUi from 'ms-components/Confirm/ConfirmModalUI';
import ChevronBottom from 'ms-components/icons/ChevronBottom';
import ChevronTop from 'ms-components/icons/ChevronTop';
import ClassIcon from 'ms-components/icons/Class';
import Cross from 'ms-components/icons/Cross';
import Plus from 'ms-components/icons/Plus';
import ProfileIcon from 'ms-components/icons/Profile';
import useSchoolContext from 'ms-pages/Teacher/TeacherContext/SchoolContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import ClassAndStudentSelector from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import {
  ClassPill,
  OuterPill,
  InnerPill,
  PillText,
  PillDescription,
  StudentPill,
  ClassAssigneeIconWrapper,
  ProfileIconWrapper,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector/components';
import { TeacherCreateClassModal } from 'ms-pages/Teacher/components/ClassCreateEdit';
import {
  Asterisk,
  Label,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import {
  zIndex,
  fontFamily,
  fontSize,
  lineHeight,
  fontWeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Checkbox from 'ms-ui-primitives/Checkbox';
import { useClickOutsideRef } from 'ms-ui-primitives/ClickOutside/hook';
import Stack from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

const Header = styled({
  display: 'flex',
  alignItems: 'center',
});

const RightAligned = styled({
  marginLeft: 'auto',
});

const Overlay = styled({
  position: 'absolute',
  background: colors.white,
  padding: 6 * BASE_UNIT,
  paddingTop: BASE_UNIT,
  paddingBottom: 0,
  width: '100%',
  boxShadow: '0px 8px 15px rgba(0,0,0,0.1)',
  borderRadius: `0 0 ${2 * BASE_UNIT}px ${2 * BASE_UNIT}px `,
  zIndex: zIndex.catfaFloatingFooter,
});

const MinHeightWrapper = styled({ minHeight: 10 * BASE_UNIT });

const EmptySelectionText = styled({
  fontFamily: fontFamily.body,
  color: colors.grayChateau,
  fontSize: fontSize.small,
  lineHeight: lineHeight.body,
});

const StudentExcluderExpander = styled({
  color: colors.grey90,
  fontWeight: fontWeight.semibold,
  cursor: 'pointer',
  height: 7 * BASE_UNIT,
  display: 'flex',
  alignItems: 'center',
  gap: 2 * BASE_UNIT,
});

const StudentExcluderList = styled({
  display: 'flex',
  flexDirection: 'column',
});

type Props = {
  selectedClasses: ReadonlyArray<ClassSelectionPayload>;
  selectedStudents: ReadonlyArray<StudentSelectionPayload>;
  excludedStudents: ReadonlyArray<StudentSelectionPayload>;
  onChangeClasses: (p: ReadonlyArray<ClassSelectionPayload>) => void;
  onChangeStudents: (p: ReadonlyArray<StudentSelectionPayload>) => void;
  onChangeExcludedStudents: (p: ReadonlyArray<StudentSelectionPayload>) => void;
};

function StudentExcluder({
  students,
  excludedStudents,
  onChangeExcludedStudents,
}: {
  students: ReadonlyArray<StudentSelectionPayload>;
  excludedStudents: ReadonlyArray<StudentSelectionPayload>;
  onChangeExcludedStudents: (p: ReadonlyArray<StudentSelectionPayload>) => void;
}) {
  const isExpanded = useBoolean(false);
  const classExcludedStudents = excludedStudents.filter(excludedStudent =>
    students.map(s => s.id).includes(excludedStudent.id),
  );
  const someStudentExcluded = classExcludedStudents.length > 0;

  const excludeStudents = (payload: ReadonlyArray<StudentSelectionPayload>) => {
    onChangeExcludedStudents([
      ...excludedStudents.filter(
        student => !payload.map(s => s.id).includes(student.id),
      ),
      ...payload,
    ]);
  };

  const includeStudents = (
    ids: ReadonlyArray<StudentSelectionPayload['id']>,
  ) => {
    onChangeExcludedStudents(
      excludedStudents.filter(student => !ids.includes(student.id)),
    );
  };

  return (
    <>
      <StudentExcluderExpander onClick={isExpanded.toggle}>
        {students.length === 0 ? (
          'Class has no students'
        ) : (
          <>
            Expand to edit ({students.length - classExcludedStudents.length}/
            {students.length} students selected)
            {isExpanded.value ? (
              <ChevronTop size={20} />
            ) : (
              <ChevronBottom size={20} />
            )}
          </>
        )}
      </StudentExcluderExpander>
      {isExpanded.value && (
        <StudentExcluderList>
          <StudentPill>
            <InnerPill>
              <Checkbox
                onChange={() => {
                  if (someStudentExcluded) {
                    includeStudents(students.map(s => s.id));
                  } else {
                    excludeStudents(students);
                  }
                }}
                checked={!someStudentExcluded}
                label={<PillText semibold>Select all students</PillText>}
              />
            </InnerPill>
          </StudentPill>
          {students.map(student => {
            const isExcluded = excludedStudents.some(s => s.id === student.id);
            return (
              <StudentPill key={student.id}>
                <InnerPill>
                  <Checkbox
                    onChange={() => {
                      if (isExcluded) {
                        includeStudents([student.id]);
                      } else {
                        excludeStudents([student]);
                      }
                    }}
                    checked={!isExcluded}
                    label={
                      <>
                        <ProfileIconWrapper>
                          <ProfileIcon size={5 * BASE_UNIT} />
                        </ProfileIconWrapper>
                        <PillText>
                          {student.lastName}, {student.firstName}
                        </PillText>
                      </>
                    }
                  />
                </InnerPill>
              </StudentPill>
            );
          })}
        </StudentExcluderList>
      )}
    </>
  );
}

export default function TaskAssignment({
  onChangeClasses,
  onChangeStudents,
  onChangeExcludedStudents,
  selectedClasses,
  selectedStudents,
  excludedStudents,
}: Props) {
  const schoolContext = useSchoolContext();
  const [isCreatingClass, setIsCreatingClass] = useState<boolean>(false);
  const [selectorOpen, setSelectorOpen] = useState<boolean>();
  const clickOutsideRef = useClickOutsideRef(() => setSelectorOpen(false));
  const removeClass = (id: ClassSelectionPayload['id']) => {
    onChangeClasses(selectedClasses.filter(klass => klass.id !== id));
  };

  const removeStudent = (id: StudentSelectionPayload['id']) => {
    onChangeStudents(selectedStudents.filter(student => student.id !== id));
  };

  const onClassSelect = (payload: ClassSelectionPayload) => {
    onChangeClasses([...selectedClasses, payload]);
  };

  const onStudentSelect = (payload: StudentSelectionPayload) => {
    onChangeStudents([...selectedStudents, payload]);
  };

  const clearAllSelection = () => {
    onChangeClasses([]);
    onChangeStudents([]);
  };

  // If the teacher has only a single class and the list of already
  // selected assignee (classes or students) is empty then we automatically
  // choose the only available class for them.
  // This situation can arise when a teacher with no classes, creates a
  // class as part of the task creation process - since it's the only one
  // availble we can automatically select it.
  useEffect(() => {
    if (
      schoolContext.schoolClasses?.length === 1 &&
      selectedClasses.length === 0 &&
      selectedStudents.length === 0
    ) {
      const [klass] = schoolContext.schoolClasses;
      if (klass != null) {
        onClassSelect(klass);
      }
    }
    // we only ever want to check this again when the teacher's classes change,
    // e.g. when they create a class.
    // Otherwise, the user should be able to add and remove classes as they please.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolContext.schoolClasses]);

  return (
    <>
      <Header>
        <Label>
          Assign to<Asterisk> *</Asterisk>
        </Label>
        <RightAligned>
          {selectedClasses.length + selectedStudents.length > 0 && (
            <ClearAllButtonWithConfirm onClear={clearAllSelection} />
          )}
        </RightAligned>
      </Header>

      <MinHeightWrapper>
        {selectedClasses.map(klass => (
          <ClassPill key={klass.id}>
            <OuterPill>
              <InnerPill>
                <ClassAssigneeIconWrapper>
                  <ClassIcon size={4 * BASE_UNIT} />
                </ClassAssigneeIconWrapper>

                <Stack.V gap={2}>
                  <PillText semibold>{klass.title}</PillText>
                  {klass.displayName != null &&
                    klass.displayName !== klass.title && (
                      <PillDescription>{klass.displayName}</PillDescription>
                    )}
                </Stack.V>
              </InnerPill>
              <Button
                size="tiny"
                height={22}
                onClick={() => removeClass(klass.id)}
                label="Remove class"
              >
                <Cross size={15} color={colors.grey} />
              </Button>
            </OuterPill>
            <StudentExcluder
              students={
                klass.students?.edges.map(({ node: { id, user } }) => ({
                  ...user,
                  id,
                })) ?? []
              }
              excludedStudents={excludedStudents}
              onChangeExcludedStudents={onChangeExcludedStudents}
            />
          </ClassPill>
        ))}
        {selectedStudents.map(student => (
          <StudentPill key={student.id}>
            <OuterPill>
              <InnerPill>
                <ProfileIconWrapper>
                  <ProfileIcon size={5 * BASE_UNIT} />
                </ProfileIconWrapper>
                <PillText style={{ lineHeight: `${5 * BASE_UNIT}px` }}>
                  {student.lastName}, {student.firstName}
                </PillText>
              </InnerPill>
              <Button
                size="tiny"
                height={22}
                onClick={() => removeStudent(student.id)}
                label="Remove student"
              >
                <Cross size={15} color={colors.grey} />
              </Button>
            </OuterPill>
          </StudentPill>
        ))}
        {!selectedClasses.length && !selectedStudents.length && (
          <EmptySelectionText>
            No classes or students selected yet
          </EmptySelectionText>
        )}
      </MinHeightWrapper>
      <div style={{ height: 16 }} />
      <Button
        onClick={() => setSelectorOpen(true)}
        isBlock
        type="secondary"
        label="Add Classes or Students"
      >
        <Plus color={colors.eggplant} />
        {schoolContext.schoolClasses?.length === 0 ? (
          <span
            style={{ marginLeft: 5 }}
            onClick={() => setIsCreatingClass(true)}
          >
            Create a class
          </span>
        ) : (
          <span style={{ marginLeft: 5 }}>Add Classes or Students</span>
        )}
      </Button>

      {selectorOpen && (
        <div ref={clickOutsideRef} style={{ position: 'relative' }}>
          <Overlay>
            <ClassAndStudentSelector
              listHeight={150}
              selectedClasses={selectedClasses.map(k => k.id)}
              selectedStudents={selectedStudents.map(s => s.id)}
              onClassSelect={onClassSelect}
              onStudentSelect={onStudentSelect}
            />
          </Overlay>
        </div>
      )}

      {/* When a teacher has no class, we can't prompt with selecting
          a class so we first prompt with creating a class */}
      <TeacherCreateClassModal
        isOpen={isCreatingClass}
        onClose={() => setIsCreatingClass(false)}
        onClassCreated={() => {
          setIsCreatingClass(false);
          setSelectorOpen(true);
        }}
      />
    </>
  );
}

const ConfirmModalTitle = styled(
  {
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    color: colors.cloudBurst,
    fontSize: 24,
    lineHeight: '28px',
    marginBottom: 16,
  },
  'h3',
);

const ConfirmModalText = styled(
  {
    fontFamily: fontFamily.body,
    color: colors.cloudBurst,
    fontSize: 14,
    lineHeight: '18px',
    marginBottom: 16,
  },
  'p',
);

function ClearAllButtonWithConfirm({ onClear }: { onClear: () => void }) {
  const isClearAllSelectionConfirmModalOpen = useBoolean();

  return (
    <>
      <Button padding={0} onClick={isClearAllSelectionConfirmModalOpen.setTrue}>
        Clear all
      </Button>

      <ConfirmModalUi
        isOpen={isClearAllSelectionConfirmModalOpen.value}
        action={onClear}
        onClose={isClearAllSelectionConfirmModalOpen.setFalse}
        cancelButtonLabel="No, cancel"
        confirmButtonLabel="Yes, clear all"
        isActionDestructive={false}
      >
        <div>
          <ConfirmModalTitle>Are you sure?</ConfirmModalTitle>

          <ConfirmModalText>
            You will clear all assignees from this task
          </ConfirmModalText>
        </div>
      </ConfirmModalUi>
    </>
  );
}
