import { graphql, useFragment } from 'relay-hooks';

import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  TaskBadge_task$key,
  TaskBadge_task$data,
} from './__generated__/TaskBadge_task.graphql';
import checkInTaskBadge from './check-in-task-badge.svg';
import customTaskBadge from './custom-task-badge.svg';

export function getTaskBadge(task: TaskBadge_task$data) {
  const { __typename } = task;
  switch (__typename) {
    case 'CustomTask':
      return customTaskBadge;
    case 'CheckInTask':
      return checkInTaskBadge;
    case 'WorksheetTask':
      return task.subtopic.topic.badgeUrl;
    case 'AdaptiveTask':
      return task.subtopic.topic.badgeUrl;
    case 'NewAdaptiveExperienceTask':
      return task.subtopic.topic.badgeUrl;
    case 'LessonTask':
      return task.subtopic.topic.badgeUrl;
    case 'EngageTask':
      return task.subtopic.topic.badgeUrl;
    default: {
      if (__typename === '%other') throw Error(); // type narrowing
      assertUnreachable(
        task,
        `Unrecognised task __typename in \`getTaskBadge\` in ms-components/TaskBadge: ${__typename}`,
      );
    }
  }
}
export function getTaskBadgeBackground(task: TaskBadge_task$data) {
  const { __typename } = task;
  switch (__typename) {
    case 'CustomTask':
      return customTaskBadge;
    case 'CheckInTask':
      return checkInTaskBadge;
    case 'WorksheetTask':
      return task.subtopic.topic.badgeBackground;
    case 'AdaptiveTask':
      return task.subtopic.topic.badgeBackground;
    case 'NewAdaptiveExperienceTask':
      return task.subtopic.topic.badgeBackground;
    case 'LessonTask':
      return task.subtopic.topic.badgeBackground;
    case 'EngageTask':
      return task.subtopic.topic.badgeBackground;
    default: {
      if (__typename === '%other') throw Error(); // type narrowing
      assertUnreachable(
        task,
        `Unrecognised task __typename in \`getTaskBadgeBackground\` in ms-components/TaskBadge: ${__typename}`,
      );
    }
  }
}
type Props = {
  task: TaskBadge_task$key;
  size?: number | undefined;
};
export default function TaskBadge({
  task: taskRef,
  size = 12 * BASE_UNIT,
}: Props) {
  const task = useFragment(
    graphql`
      fragment TaskBadge_task on TaskInterface @relay(mask: false) {
        __typename
        ... on CustomTask {
          id
        }
        ... on LessonTask {
          subtopic: areaOfStudy {
            topic {
              badgeUrl
              badgeBackground
            }
          }
        }
        ... on WorksheetTask {
          subtopic: areaOfStudy {
            topic {
              badgeUrl
              badgeBackground
            }
          }
        }
        ... on NewAdaptiveExperienceTask {
          subtopic: areaOfStudy {
            topic {
              badgeUrl
              badgeBackground
            }
          }
        }
        ... on AdaptiveTask {
          subtopic: areaOfStudy {
            topic {
              badgeUrl
              badgeBackground
            }
          }
        }
        ... on EngageTask {
          subtopic: areaOfStudy {
            topic {
              badgeUrl
              badgeBackground
            }
          }
        }
        #  we are not fetching any data for the strand, but this makes the getTaskBadge function's switch case exhaustive
        ... on CheckInTask {
          strand: areaOfStudy {
            __typename
          }
        }
      }
    `,
    taskRef,
  );
  return (
    <img src={getTaskBadge(task)} height={size} width={size} alt="task-badge" />
  );
}
