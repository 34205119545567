import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { unwrap } from 'ms-utils/typescript-utils';

type Point = [/* x */ number, /* y */ number];

type TimelineElement = {
  start: number;
  end: number;
  values: ReadonlyArray<number>;
  curve?: ReadonlyArray<Point>;
};

type Timeline = Record<string, TimelineElement>;

export type TriggerType = 'rising' | 'falling' | 'changing' | 'high';

type TimelineConfiguration<T extends Timeline> = {
  timeline: T;
  // Do not automatically start playing the animation
  startPaused?: boolean;
  // When the animation is started (by trigger or otherwise) add a delay to the start
  startDelay?: number;
  // Start with the animations already completed
  startDone?: boolean;
  // Value that will cause the animation to start playing
  trigger?: boolean;
  // If the initial value of the trigger is true, should the animation start
  triggerType?: TriggerType;
};

type OutputTimelineElement = {
  start: number;
  end: number;
  values: ReadonlyArray<number>;
  curve?: ReadonlyArray<Point>;
  value: number;
  t: number;
};

/**
 *  Configurable animation timelines with bezier curves.
 *
 *  Specify multiple properties that you'd like to animate in the form
 *  name: {
 *    start: 0,
 *    end: 500,
 *    values: [0, 1] // These are the values to animate
 *    curve: [[0, 0.5], [0.5, 1]] // Two bezier curve handles. Is transformed to  [0,0] <input> [1, 1]
 *  }
 *
 *  The resulting values will be available as `timeline.name.value`
 *
 *  Triggering:
 *  If your animation must respond to an external boolean pass the boolean value into the
 *  `trigger` configuration.
 */
export function useTimeline<T extends Timeline>(
  config: TimelineConfiguration<T>,
): {
  timeline: Record<keyof T, OutputTimelineElement>;
  done: boolean;
  isPlaying: boolean;
  restart: () => void;
  start: () => void;
  delta: number;
} {
  const runWhenStable = useWaitStableFrames();
  const delta = useRef(0);

  const rerender = useBoolean();

  const [paused, setPaused] = useState(true);

  const maxDuration = useMemo(
    () =>
      Math.max(
        ...Object.keys(config.timeline).map(
          key => unwrap(config.timeline[key]).end,
        ),
      ),
    [config.timeline],
  );

  const restart = useCallback(() => {
    setPaused(false);
    delta.current = 0;
  }, []);

  const start = useCallback(() => {
    if (!paused) return;
    setPaused(false);
    delta.current = 0;
  }, [paused]);

  useChangeTrigger({
    trigger: config.trigger ?? (!config.startPaused && !config.startDone),
    type: config.triggerType ?? 'high',
    cb: () =>
      runWhenStable(() => {
        setTimeout(start, config.startDelay);
      }),
  });

  const timeline = useMemo(() => {
    const timelineKeys = Object.keys(config.timeline);
    const _timeline: Record<string, TimelineElement> = Object.fromEntries(
      timelineKeys.map(key => {
        const value = unwrap(config.timeline[key]);
        // Sentinel just to cause this value to be recomputed every tick
        // @ts-expect-error
        // eslint-disable-next-line
        const unused = rerender.value;
        if (value.start >= delta.current)
          return [
            key,
            {
              ...value,
              value: config.startDone
                ? unwrap(value.values[value.values.length - 1])
                : unwrap(value.values[0]),
              t: config.startDone ? 1 : 0,
            },
          ];
        const duration = value.end - value.start;
        const startDelta = delta.current - value.start;
        const lerp = Math.min(1, startDelta / duration);

        const curve = value.curve || LINEAR;

        const t = deCasteljausBezier(lerp, ...curve);

        const animated = indexedLinearInterpolate(t, ...value.values);

        return [key, { ...value, value: animated, t: lerp }];
      }),
    );
    // Object.fromEntries hardcodes { [k: string]: T } as the return type
    // we need [k: string], to be the narrower [k: keyof T] instead.
    return _timeline as Record<keyof T, OutputTimelineElement>;
  }, [config.startDone, config.timeline, rerender.value]);

  useEffect(() => {
    if (paused) return;

    function animate(dt: number) {
      delta.current += dt;
      rerender.toggle();

      if (delta.current >= maxDuration) {
        setPaused(true);
      }
    }
    requestTick(animate);
    return () => cancelTick(animate);
  }, [maxDuration, paused, rerender]);

  return {
    timeline,
    restart,
    start,
    done: delta.current >= maxDuration,
    isPlaying: !paused,
    delta: delta.current,
  };
}

export function useTrigger(): [boolean, () => void] {
  const trigger = useBoolean();
  useEffect(() => {
    if (trigger.value) requestAnimationFrame(trigger.setFalse);
  }, [trigger]);

  return [trigger.value, trigger.setTrue];
}

export function useChangeTrigger({
  trigger,
  type = 'high',
  cb,
}: {
  trigger: boolean;
  type?: TriggerType;
  cb: () => void;
}) {
  const previous = useRef<boolean | null>(null);
  useEffect(() => {
    let changeTrigger = false;

    switch (type) {
      case 'high':
        changeTrigger =
          trigger && previous.current !== null && previous.current !== trigger;
        break;
      case 'rising':
        changeTrigger = previous.current === false && trigger;
        break;
      case 'falling':
        changeTrigger = previous.current === true && !trigger;
        break;
      case 'changing':
        changeTrigger =
          previous.current !== null && previous.current !== trigger;
        break;
      default:
        break;
    }

    if (
      (previous.current === null && type === 'high' && trigger) ||
      changeTrigger
    ) {
      cb();
    }

    previous.current = trigger;
  }, [cb, trigger, type]);
}
export function useHasChanged<T>(value: T): [() => boolean, T] {
  const previous = useRef(value);

  useEffect(() => {
    previous.current = value;
  });

  return [() => previous.current !== value, value];
}

export function pointInterpolate(t: number, p0: Point, p1: Point): Point {
  return [
    linearInterpolate(t, p0[0], p1[0]),
    linearInterpolate(t, p0[1], p1[1]),
  ];
}

export function linearInterpolate(t: number, v1: number, v2: number): number {
  return (1 - t) * v1 + t * v2;
}

export function indexedLinearInterpolate(
  t: number,
  ...values: ReadonlyArray<number>
): number {
  if (values.length === 1) return unwrap(values[0]);
  if (values.length === 0) return 0;
  if (t === 1) return unwrap(values[values.length - 1]);
  if (t === 0) return unwrap(values[0]);

  const segmentWidth = 1 / (values.length - 1);

  const targetIndex = Math.floor(t * (values.length - 1));
  const factor = targetIndex * segmentWidth;
  const factorWidth = t - factor;
  const nextT = factorWidth / segmentWidth;
  return linearInterpolate(
    nextT,
    unwrap(values[targetIndex]),
    unwrap(values[targetIndex + 1]),
  );
}

export function pairInterpolate1D(
  t: number,
  v0: number,
  v1: number,
  ...values: ReadonlyArray<number>
): ReadonlyArray<number> {
  if (values.length === 0) {
    return [linearInterpolate(t, v0, v1)];
  } else {
    const [head, ...tail] = values;
    return [
      linearInterpolate(t, v0, v1),
      ...pairInterpolate1D(t, v1, unwrap(head), ...tail),
    ];
  }
}
//  given an array of values, interpolate t between every successive pair of elements
export function pairInterpolate2D(
  t: number,
  p0: Point,
  p1: Point,
  ...pn: ReadonlyArray<Point>
): ReadonlyArray<Point> {
  if (pn.length === 0) {
    return [pointInterpolate(t, p0, p1)];
  } else {
    const [head, ...tail] = pn;
    return [
      pointInterpolate(t, p0, p1),
      ...pairInterpolate2D(t, p1, unwrap(head), ...tail),
    ];
  }
}

// Returns a point on the convex hull of a set of points offset by some
// interpolation factor t: [0, 1]
export function deCasteljausBezier(
  t: number,
  ...points: ReadonlyArray<Point>
): number {
  if (points.length === 1 || t === 0) return unwrap(points[0])[1];
  // TODO This isn't type sound, since points can be empty
  if (t === 1) return unwrap(points[points.length - 1])[1];

  // TODO Also not type sound, points is not guaranteed to have length >= 2
  const [p0, p1, ...rest] = points;
  return deCasteljausBezier(
    t,
    ...pairInterpolate2D(t, unwrap(p0), unwrap(p1), ...rest),
  );
}

export const DRAMATIC: readonly Point[] = [
  [0, 0],
  [0, 1],
  [0, 1],
  [1, 1],
];

export const LETHARGIC: readonly Point[] = [
  [0, 0],
  [1, 0],
  [1, 0],
  [1, 1],
];

export const OVEREXCITED: readonly Point[] = [
  [0, 0],
  [0, 1.1],
  [0, 0.82],
  [1, 1],
];

export const EASE_IN_OUT: readonly Point[] = [
  [0, 0],
  [0.5, 0],
  [0.5, 1],
  [1, 1],
];

export const EASE_OUT: readonly Point[] = [
  [0, 0],
  [0.5, 1],
];

export const LINEAR: readonly Point[] = [
  [0, 0],
  [1, 1],
];

let LAST_TICK: number | null = null;
type TickCallback = (delta: number) => void;
let GLOBAL_CALLBACKS: Array<TickCallback> = [];

function tick(now: number) {
  if (LAST_TICK == null) {
    return;
  }
  for (const func of GLOBAL_CALLBACKS) {
    func(now - LAST_TICK);
  }
  LAST_TICK = now;
  requestAnimationFrame(tick);
}

function requestTick(callback: TickCallback) {
  GLOBAL_CALLBACKS.push(callback);
  if (LAST_TICK == null) {
    LAST_TICK = window.performance.now();
    tick(window.performance.now());
  }
}

function cancelTick(callback: TickCallback) {
  GLOBAL_CALLBACKS = GLOBAL_CALLBACKS.filter(c => c !== callback);
  if (GLOBAL_CALLBACKS.length === 0) {
    LAST_TICK = null;
  }
}

// At most 1 second may elapse before we conclude that frames are stable and force the
// runner to start animations. This is to make sure that interactive objects always
// end up rendering on screen
const STABLE_FRAME_TIMEOUT = 60;
const NUMBER_OF_STABLE_FRAMES = 30;
// calls a supplied callback once when the framerate is stable.
// framerate qualifies as stable when 5 or more frames consecutively time below 18ms
// It's worth noting that this intruduces at minimum a 100ms delay when mounting on a new component.
// However, if alreday mounted this should be instantaneous.
function useWaitStableFrames() {
  type SignalCallback = () => void;
  const signals = useRef<SignalCallback[]>([]);
  const latched = useRef(false);
  const stableCount = useRef(0);
  const totalFrames = useRef(0);
  useEffect(() => {
    function testStable(delta: number) {
      if (signals.current.length && latched.current) {
        for (const signal of signals.current) {
          signal();
        }
        signals.current = [];
      } else if (signals.current.length === 0) {
        return;
      }

      totalFrames.current = Math.min(
        STABLE_FRAME_TIMEOUT,
        totalFrames.current + 1,
      );

      if (totalFrames.current >= STABLE_FRAME_TIMEOUT) {
        latched.current = true;
        totalFrames.current = 0;
        return;
      }

      if (stableCount.current === NUMBER_OF_STABLE_FRAMES) {
        latched.current = true;
        stableCount.current = 0;
        return;
      }

      const stable = delta < 18;
      if (stable) {
        stableCount.current = Math.min(
          NUMBER_OF_STABLE_FRAMES,
          stableCount.current + 1,
        );
      } else {
        stableCount.current = 0;
      }
    }

    requestTick(testStable);
    return () => cancelTick(testStable);
  }, []);

  return useCallback((callback: SignalCallback) => {
    signals.current.push(callback);
  }, []);
}

export type AnimationConfiguration = {
  duration: number;
  values: ReadonlyArray<number>;
  curve?: ReadonlyArray<Point>;
  startPaused?: boolean;
  startDelay?: number;
  startDone?: boolean;
};

export type Animation = {
  value: number;
  restart: () => void;
  start: () => void;
  done: boolean;
  paused: boolean;
  loop: boolean;
};

export function useAnimation({
  startPaused = false,
  startDone = false,
  duration,
  startDelay = 0,
  curve,
  values,
}: AnimationConfiguration): { value: number; restart: Function } {
  const { timeline, restart } = useTimeline({
    startDone,
    startDelay,
    startPaused,
    timeline: {
      animation: {
        start: 0,
        end: duration,
        ...(curve === undefined ? {} : { curve }),
        values,
      },
    },
  });

  return { value: unwrap(timeline.animation).value, restart };
}
