import { createContext, useContext, type ReactNode, useMemo } from 'react';

import type {
  QuestionsCollapseType,
  QuestionsCollapseUpdaterType,
} from 'ms-pages/Textbooks/components/ProblemItem/useCollapseQuestionsState';
import {
  QUESTIONS_COLLAPSE_DEFAULT,
  useCollapseQuestionsState,
} from 'ms-pages/Textbooks/components/ProblemItem/useCollapseQuestionsState';

type UserPreferenceContextType = {
  questionsCollapsed: QuestionsCollapseType;
  setQuestionsCollapsed: QuestionsCollapseUpdaterType;
};

const UserPreferenceContext = createContext<UserPreferenceContextType>({
  questionsCollapsed: QUESTIONS_COLLAPSE_DEFAULT,
  setQuestionsCollapsed: () => {},
});

export function useUserPreference() {
  return useContext(UserPreferenceContext);
}

export function UserPreferenceContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [questionsCollapsed, setQuestionsCollapsed] =
    useCollapseQuestionsState();

  const memoizedValue = useMemo(
    () => ({
      questionsCollapsed,
      setQuestionsCollapsed,
    }),
    [questionsCollapsed, setQuestionsCollapsed],
  );

  return (
    <UserPreferenceContext.Provider value={memoizedValue}>
      {children}
    </UserPreferenceContext.Provider>
  );
}
