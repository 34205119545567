import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const BetaBadge = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={70}
    viewBoxHeight={20}
  >
    <text x="10">BETA</text>
  </Icon>
);

BetaBadge.displayName = 'Beta';

export default BetaBadge;
