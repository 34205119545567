import { forwardRef, useContext } from 'react';

import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import { StateContext } from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import Input from 'ms-ui-primitives/Input';

type Props = {
  errorMessages: ErrorMessages;
  onBlur?: (() => void) | undefined;
  onChange: (e: string) => void;
};

function StudentFirstname(
  { errorMessages, onBlur, onChange }: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const { firstName } = useContext(StateContext);
  return (
    <FieldGroup isFieldRequired title="First name">
      <Input
        ref={ref}
        placeholder="First name"
        value={firstName}
        onChange={e => onChange(e.target.value)}
        showErrorState={errorMessages.length > 0}
        onBlur={onBlur}
        blockTracking
      />
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
}

export default forwardRef(StudentFirstname);
