import { css, StyleSheet } from 'aphrodite';
import type { ComponentType } from 'react';
import { useRef, useEffect } from 'react';
import { DragSource } from 'react-dnd';
import type { WrappedConnectorHook } from 'react-dnd';

type DropDataBase = {
  itemData: { value: any };
  itemIndex: number; // position of dropped item in the from container
  itemId: number;
  fromContainer: {
    id: string;
    type: string;
  };
};

type DropDataOffTarget = { droppedOnTarget: false } & DropDataBase;

type DropDataOnTarget = {
  droppedOnTarget: true;
  toContainer: {
    id: string;
    type: string;
  };
} & DropDataBase;

export type ItemDropData = DropDataOffTarget | DropDataOnTarget;

type WrapperProps = {
  value: string | number;
  index: number;
  id: number;
  onItemDropped: (data: ItemDropData) => void;
  itemComponent: ComponentType<any>;
  onItemDropOnZone: (data: ItemDropData) => void;
  onItemDropOutsideZone: (data: ItemDropData) => void;
  dragStartContainer: string;
  dragStartContainerId: string;
  numberBuilderId: number;
};

type CollectProps = {
  connectDragSource: WrappedConnectorHook;
  isDragging: boolean;
};

type Props = WrapperProps & CollectProps;

/**
 * DraggableItem wraps the provided item component, allowing it to be dragged
 * and dropped onto compatible react-dnd drop targets.
 */
function DraggableItem({
  value,
  connectDragSource,
  isDragging,
  itemComponent: Item, // uppercase required for JSX
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);

  // Prevent window panning on touch devices when dragging the item.
  useEffect(() => {
    const rootNode = rootRef.current;
    if (rootNode === null) return;
    const preventWindowPanning = (e: TouchEvent) => e.preventDefault();
    rootNode.addEventListener('touchstart', preventWindowPanning);
    return () => {
      rootNode.removeEventListener('touchstart', preventWindowPanning);
    };
  }, []);

  return connectDragSource(
    <div className={css(styles.item)} style={{ opacity: isDragging ? 0 : 1 }}>
      <div
        // ref can't be used in the top level div because it would interfere with react-dnd
        ref={rootRef}
      >
        <Item value={value} />
      </div>
    </div>,
  );
}

const styles = StyleSheet.create({
  item: {
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
    cursor: 'grab',
    userSelect: 'none',
    ':active': {
      cursor: 'grabbing',
    },
  },
});

const DND_TYPE = 'ITEM';

const enhance = DragSource(
  DND_TYPE,
  {
    beginDrag(props: WrapperProps, _monitor, _component) {
      return {
        value: props.value,
        itemComponent: props.itemComponent,
        numberBuilderId: props.numberBuilderId,
      };
    },
    endDrag(props: WrapperProps, monitor) {
      const dropResult = monitor.getDropResult();

      if (dropResult && dropResult.id === props.dragStartContainerId) return;

      const data = {
        itemData: { value: props.value },
        itemIndex: props.index, // position of dropped item in the from container
        itemId: props.id,
        fromContainer: {
          id: props.dragStartContainerId,
          type: props.dragStartContainer,
        },
      };

      if (monitor.didDrop() && dropResult) {
        props.onItemDropOnZone({
          droppedOnTarget: true,
          ...data,
          toContainer: {
            id: dropResult.id as string,
            type: dropResult.dropContainer as string,
          },
        });
      } else {
        props.onItemDropOutsideZone({
          droppedOnTarget: false,
          ...data,
        });
      }
    },
  },
  (connect, monitor): CollectProps => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
);

export default enhance(DraggableItem);
