// NOTE we can't easily import from 'ms-components/PreviewProblem/types.jsx'
// due to its transitive deps. So we're just going to inline the type for now.
// If the types change in the schema we'll get type errors at the call site
// for this icon, and can easily update the type. Worthwhile tradeoff for now.
// TODO refactor types.jsx and transitive deps, then unwind this hack.
// import type { DifficultyLevel } from 'ms-components/PreviewProblem/types.jsx';
import Icon from 'ms-ui-primitives/Icon';

import type { Props as CommonProps } from '../types';

type DifficultyLevel = 'EASY' | 'HARD' | 'MEDIUM';

export type Props = CommonProps & {
  color: string; // color is not optional for Difficulty icons because we use a function to calculate the rgba color with reduced opacity
  useUpdatedIcon?: boolean | undefined;
};

type DifficultyIconBaseProps = Props & {
  difficulty: DifficultyLevel;
};

const SHADED_QUANTITY = 0.75;
const SHADED_OPACITY = 1 - SHADED_QUANTITY;

export default function DifficultyBase({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
  difficulty,
  useUpdatedIcon = false,
}: DifficultyIconBaseProps) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxWidth={useUpdatedIcon ? 18 : 19}
      viewBoxHeight={useUpdatedIcon ? 14 : 13}
    >
      {useUpdatedIcon ? (
        <>
          <rect y="7.99534" width="5" height="5.97203" rx="1.5" fill={color} />
          <rect
            x="6.40002"
            y="4.01399"
            width="5"
            height="9.95338"
            rx="1.5"
            fill={color}
            style={{
              opacity: difficulty === 'EASY' ? SHADED_OPACITY : 1,
            }}
          />
          <path
            d="M14.3 0.0326385L16.3 0.0326385C17.1285 0.0326385 17.8 0.58312 17.8 1.26217V12.7378C17.8 13.4169 17.1285 13.9674 16.3 13.9674H14.3C13.4716 13.9674 12.8 13.4169 12.8 12.7378L12.8 1.26217C12.8 0.58312 13.4716 0.0326385 14.3 0.0326385Z"
            fill={color}
            style={{
              opacity: difficulty === 'HARD' ? 1 : SHADED_OPACITY,
            }}
          />
        </>
      ) : (
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect fill={color} x="0" y="9" width="5" height="3" rx="1.5" />
          <rect
            fill={color}
            style={{
              opacity: difficulty === 'EASY' ? SHADED_OPACITY : 1,
            }}
            x="7"
            y="5"
            width="5"
            height="7"
            rx="1.5"
          />
          <rect
            fill={color}
            style={{
              opacity: difficulty === 'HARD' ? 1 : SHADED_OPACITY,
            }}
            x="14"
            y="0"
            width="5"
            height="12"
            rx="1.5"
          />
        </g>
      )}
    </Icon>
  );
}
