import type { Options, FeedbackType } from './types';

type Config = Partial<
  Record<
    FeedbackType,
    {
      options: Options;
      title: string;
      description: string | ((multiple: boolean) => string);
      thankYouMessage?: string;
      ctaButtonLabel?: string;
    }
  >
>;

const issueTitle = 'Submit an issue';
const issueDescriptionFn = (multiple: boolean) =>
  `Select the option${multiple ? 's' : ''} that appl${
    multiple ? 'y' : 'ies'
  } to help us understand the issue.`;
const issueThankYouMessage =
  'Our team will review your feedback and make any necessary changes to fix the issue';

const feedbackTitle = 'Submit feedback';
const feedbackDescriptionFn = (multiple: boolean) =>
  `Select the option${multiple ? 's' : ''} that best appl${
    multiple ? 'y' : 'ies'
  } to your feedback.`;
const feedbackThankYouMessage =
  'Our team will review your feedback and make any necessary changes';

const GENERAL_OPTIONS: Options = [
  {
    key: 'CONFUSING_QUESTION',
    label: 'This question is confusing',
  },
  {
    key: 'QUESTION_HAS_MISTAKES',
    label: 'There are mistakes in this question',
  },
  {
    key: 'GRADING_TAKES_TOO_LONG',
    label: 'Grading takes too long after pressing submit',
  },
  {
    key: 'HANDWRITING_PANEL_ISSUES',
    label: 'The handwriting system fails to recognise my input',
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const HINTS_OPTIONS: Options = [
  {
    key: 'CONFUSING_HINT',
    label: 'This hint is confusing or doesn’t make sense',
  },
  {
    key: 'NOT_HELPFUL_HINT',
    label: 'This hint isn’t helpful for my situation',
  },
  {
    key: 'HINT_WITH_MISTAKES',
    label: 'There is a mistake in this hint',
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const STEPS_OPTIONS: Options = [
  {
    key: 'ANSWER_SHOULD_BE_CORRECT',
    label: 'My answer should be marked correct',
  },
  {
    key: 'SUPPLIED_ANSWER_IS_INCORRECT',
    label: 'Your supplied answer is incorrect',
  },
  {
    key: 'HAVING_TROUBLES_ANSWERING',
    label: "I'm having trouble entering my answer",
  },
  {
    key: 'HANDWRITING_PANEL_ISSUES',
    label: 'The handwriting system fails to recognise my input',
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const NWE_OPTOUT_OPTIONS: Options = [
  {
    key: 'NWE_PREFER_OLD_DESIGN',
    label: 'I prefer the old design',
  },
  {
    key: 'NWE_MISSING_FEATURE',
    label: 'A feature I want is missing',
  },
  {
    key: 'NWE_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
];

const LESSONS_OPTIONS: Options = [
  {
    key: 'LESSON_CONFUSING',
    label: 'This lesson is confusing',
  },
  {
    key: 'LESSON_NOT_HELPFUL',
    label: "This lesson doesn't help me answer the questions",
  },
  {
    key: 'LESSON_WITH_MISTAKES',
    label: 'There are mistakes in this lesson',
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const STUDENT_DASHBOARD_OPTIONS: Options = [
  {
    key: 'DASHBOARD_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'DASHBOARD_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const TASK_TEMPLATES_OPTIONS: Options = [
  {
    key: 'TEMPLATE_PREFER_SHARED_EXERCISES',
    label: 'I prefer the old shared exercises',
  },
  {
    key: 'TEMPLATE_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'TEMPLATE_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const TEXTBOOK_OPTIONS: Options = [
  {
    key: 'TEXTBOOK_PREFER_OLD_DESIGN',
    label: 'I prefer the old design',
  },
  {
    key: 'TEXTBOOK_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'TEXTBOOK_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const STUDENT_REPORT_OPTIONS: Options = [
  {
    key: 'STUDENT_REPORT_PREFER_NEW_REPORT',
    label: 'I like the new report',
  },
  {
    key: 'STUDENT_REPORT_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'STUDENT_REPORT_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const NTE_OPTIONS: Options = [
  {
    key: 'NTE_POSITIVE',
    label: 'I am enjoying the new teacher experience',
  },
  {
    key: 'NTE_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'NTE_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const NTE_OPTOUT_OPTIONS: Options = [
  {
    key: 'NTE_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'NTE_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'NTE_NEED_MORE_TIME_TO_ADJUST',
    label: 'I need more time to get used to the new design',
  },
  {
    key: 'OTHER',
    label: 'Other reason (please comment)',
  },
];

const ANSWER_KEY_OPTIONS: Options = [
  {
    key: 'ANSWER_KEY_THERE_IS_AN_ERROR',
    label: 'There is an error',
  },
  {
    key: 'ANSWER_KEY_SOMETHING_NOT_DISPLAYING_CORRECTLY',
    label: 'Something is not displaying correctly',
  },
  {
    key: 'ANSWER_KEY_WANT_TO_MAKE_SUGGESTION',
    label: 'I want to make a suggestion',
  },
  {
    key: 'ANSWER_KEY_OTHER',
    label: 'Other',
  },
];

const WORKSHEET_OPTIONS: Options = [
  {
    key: 'WORKSHEET_THERE_IS_AN_ERROR',
    label: 'There is an error',
  },
  {
    key: 'WORKSHEET_SOMETHING_NOT_DISPLAYING_CORRECTLY',
    label: 'Something is not displaying correctly',
  },
  {
    key: 'WORKSHEET_WANT_TO_MAKE_SUGGESTION',
    label: 'I want to make a suggestion',
  },
  {
    key: 'WORKSHEET_OTHER',
    label: 'Other',
  },
];

const CLASS_MASTERY_SYLLABUS_OPTIONS: Options = [
  {
    key: 'CLASS_MASTERY_SYLLABUS_PREFER_NEW_REPORT',
    label: 'I prefer the new report',
  },
  {
    key: 'CLASS_MASTERY_SYLLABUS_MISSING_FEATURE',
    label: "I can't find something I used to have",
  },
  {
    key: 'CLASS_MASTERY_SYLLABUS_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];
const WORKBOOK_HELP_TIPS_OPTIONS: Options = [
  {
    key: 'WORKBOOK_HELP_TIPS_FOUND_THIS_HELPFUL',
    label: 'I found this helpful',
  },
  {
    key: 'WORKBOOK_HELP_TIPS_ALREADY_KNEW',
    label: 'I already knew this information',
  },
  {
    key: 'WORKBOOK_HELP_TIPS_NOT_RELEVANT',
    label: "This isn't relevant to me",
  },
  {
    key: 'OTHER',
    label: 'Other',
  },
];

const TEMPLATES_NAV_V2_OPT_OUT_OPTIONS: Options = [
  {
    key: 'TEMPLATES_NAV_V2_PREFER_OLD_DESIGN',
    label: 'I prefer the old design',
  },
  {
    key: 'TEMPLATES_NAV_V2_MISSING_FEATURE',
    label: 'A feature I want is missing',
  },
  {
    key: 'TEMPLATES_NAV_V2_PERFORMANCE_ISSUES',
    label: "Something isn't working",
  },
];

const CONFIG: Config = {
  GENERAL: {
    options: GENERAL_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  STEPS: {
    options: STEPS_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  HINTS: {
    options: HINTS_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  LESSONS: {
    options: LESSONS_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  NWE_OPTOUT: {
    options: NWE_OPTOUT_OPTIONS,
    title: 'Switch to Classic Workbook',
    description: 'May we ask why?',
    ctaButtonLabel: 'Switch To Classic',
  },
  STUDENT_DASHBOARD_SUBMIT_ISSUE: {
    options: STUDENT_DASHBOARD_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  TASK_TEMPLATES_SUBMIT_ISSUE: {
    options: TASK_TEMPLATES_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  TEXTBOOK_SUBMIT_ISSUE: {
    options: TEXTBOOK_OPTIONS,
    title: issueTitle,
    description: issueDescriptionFn,
    thankYouMessage: issueThankYouMessage,
  },
  STUDENT_REPORT_SUBMIT_FEEDBACK: {
    options: STUDENT_REPORT_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  NTE_SUBMIT_FEEDBACK: {
    options: NTE_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  NTE_OPTOUT: {
    options: NTE_OPTOUT_OPTIONS,
    title: 'Switch to Classic',
    description: 'May we ask why you are going back to the classic experience?',
    ctaButtonLabel: 'Switch To Classic',
  },
  CLASS_MASTERY_SYLLABUS_SUBMIT_FEEDBACK: {
    options: CLASS_MASTERY_SYLLABUS_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  ANSWER_KEY_SUBMIT_FEEDBACK: {
    options: ANSWER_KEY_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  WORKSHEET_SUBMIT_FEEDBACK: {
    options: WORKSHEET_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  WORKBOOK_HELP_TIPS: {
    options: WORKBOOK_HELP_TIPS_OPTIONS,
    title: feedbackTitle,
    description: feedbackDescriptionFn,
    thankYouMessage: feedbackThankYouMessage,
  },
  TEMPLATES_NAV_V2_SWITCHED_BACK_TO_OLD: {
    options: TEMPLATES_NAV_V2_OPT_OUT_OPTIONS,
    title: "We'd love your feedback!",
    description:
      "Can you tell us a bit more about why you're choosing to go back to the previous version?",
  },
};

export default CONFIG;
