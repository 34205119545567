/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type CheckInSessionQuestionAnswerStrandStatus = {
    readonly checkIn: {
        readonly student: {
            readonly strandStatus?: {
                readonly status: StudentStrandStatusEnum;
                readonly strand: {
                    readonly id: string;
                };
                readonly lastWorkedOnDiagnosticCheckIn: {
                    readonly id: string;
                } | null;
            } | null | undefined;
        };
    } | null;
    readonly " $refType": "CheckInSessionQuestionAnswerStrandStatus";
};
export type CheckInSessionQuestionAnswerStrandStatus$data = CheckInSessionQuestionAnswerStrandStatus;
export type CheckInSessionQuestionAnswerStrandStatus$key = {
    readonly " $data"?: CheckInSessionQuestionAnswerStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInSessionQuestionAnswerStrandStatus">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeStrandStatus"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "strandId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckInSessionQuestionAnswerStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "checkIn",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternStudent",
          "kind": "LinkedField",
          "name": "student",
          "plural": false,
          "selections": [
            {
              "condition": "includeStrandStatus",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "strandId",
                      "variableName": "strandId"
                    }
                  ],
                  "concreteType": "StudentStrandStatus",
                  "kind": "LinkedField",
                  "name": "strandStatus",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Strand",
                      "kind": "LinkedField",
                      "name": "strand",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CheckIn",
                      "kind": "LinkedField",
                      "name": "lastWorkedOnDiagnosticCheckIn",
                      "plural": false,
                      "selections": (v0/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuestionAnswerInterface",
  "abstractKey": "__isQuestionAnswerInterface"
};
})();
(node as any).hash = 'bd6a8022b6175a9b0834ab777b4d80dc';
export default node;
