import MiloOnLargeScreen from 'ms-components/illustrations/MiloOnLargeScreen';
import { MOBILE_BREAKPOINT } from 'ms-pages/Teacher/components/Navbar/SunflowerNavbar/constants';
import { font, fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

type Props = {
  onDismiss: () => void;
};

function LargerScreenPromptForMobile({ onDismiss }: Props) {
  const { width: windowWidth } = useWindowSize();

  const isMobileView = windowWidth != null && windowWidth < MOBILE_BREAKPOINT;

  return (
    <>
      {isMobileView && (
        <Container>
          <MiloOnLargeScreen />
          <VSpacer height={16} />
          <Title>Mathspace works best on a larger screen</Title>
          <VSpacer height={8} />
          <Text>Use a desktop or larger device for a better experience</Text>
          <VSpacer height={16} />
          <Button
            type="primary"
            styles={styles.button}
            isRound
            onClick={onDismiss}
          >
            Ok, got it
          </Button>
        </Container>
      )}
    </>
  );
}

const Container = styled({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 100000,
  backgroundColor: colors.eggplant90,
  width: '100%',
  padding: '32px 40px',
});

const Title = styled({
  ...font.gilroyHeading,
  color: colors.white,
  textAlign: 'center',
});

const Text = styled({
  fontFamily: fontFamily.body,
  fontSize: fontSize.headerTitle,
  fontWeight: fontWeight.semibold,
  lineHeight: '24px',
  color: colors.eggplant20,
  textAlign: 'center',
});

const styles = {
  button: {
    backgroundColor: colors.white,
    color: colors.eggplant,
  },
};

export default LargerScreenPromptForMobile;
