import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.92107 3.99693C1.40568 3.95669 1 3.52572 1 3C1 2.44772 1.44772 2 2 2H3.01129C3.11877 1.03133 3.98007 0 5.02667 0H10.9733C12.0199 0 12.8812 1.03133 12.9887 2H14C14.5523 2 15 2.44772 15 3C15 3.52572 14.5943 3.95669 14.0789 3.99693H1.92107ZM13.851 4.99693L13.1682 14.1488C13.0903 15.1927 12.2206 16 11.1737 16H4.85206C3.80699 16 2.93811 15.1954 2.85795 14.1534L2.15361 4.99693H13.851ZM11.2209 2H4.73227C4.75727 1.46621 4.90369 1.00265 5.50133 1.00265H10.3653C10.963 1.00265 11.1803 1.46621 11.2209 2Z"
  />
);

const Trash2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

Trash2.displayName = 'Trash2';

export default Trash2;
