import {
  compose,
  equals,
  init,
  isEmpty,
  not,
  path as rpath,
  sort,
  update,
  values,
} from 'ramda';
import { useState } from 'react';

import CheckMarkIcon from 'ms-components/icons/CheckMark';
import ChevronLeftIcon from 'ms-components/icons/ChevronLeft';
import ChevronRightIcon from 'ms-components/icons/ChevronRight';
import CrossIcon from 'ms-components/icons/Cross';
import type { Rect } from 'ms-helpers/Measurer';
import Measurer from 'ms-helpers/Measurer';
import { colors } from 'ms-styles/colors';
import { unwrap } from 'ms-utils/typescript-utils';

import {
  AnimationRect,
  Back,
  Close,
  Header,
  ItemInner,
  ItemText,
  ListItem,
  Root,
  Screen,
  SelectionBar,
  Title,
} from '../PresentationalComponents';

const { matisse, dustyGray } = colors;
const BACK_ICON_SIZE = 20;

export type Hierarchy = { [key: string]: Hierarchy | null };

type Comparator = (a: string, b: string) => number;

// sublists :: [a, b, c] -> [[a], [a, b], [a, b, c]]
function sublists<T>(xs: T[]): T[][] {
  return xs.length > 0 ? [...sublists(init(xs)), xs] : [xs];
}

// hasChildren :: Hierarchy -> boolean
const hasChildren = compose(not, isEmpty, values);

// maxDepth :: Hierarchy -> number
function maxDepth(hierarchy: Hierarchy | null): number {
  // this is a leaf node, so depth at node is 0. This is the terminal case.
  if (hierarchy === null || !hasChildren(hierarchy)) return 0;

  // Recurse over subhierarchies to find the max depth of any child hierarchy
  const subHierarchies = Object.values(hierarchy);
  let max = -Infinity;
  for (const subHierarchy of subHierarchies) {
    max = Math.max(max, maxDepth(subHierarchy));
  }

  // Increment the depth by 1 for the current node
  return max + 1;
}

// isPrefix :: ([a], [b]) -> boolean
// Determine if the second list starts with the first, e.g. if the first list is
// [a, b], and the second is [a, b, c], return true.
function isPrefix<T>(xs: T[], ys: T[]): boolean {
  if (xs.length === 0) return false;
  if (xs.length > ys.length) return false;
  for (let i = 0; i < xs.length; i++) {
    const x = unwrap(xs[i]);
    const y = unwrap(ys[i]);
    if (x !== y) return false;
  }
  return true;
}

export function HierarchicalSelector({
  segmentTitles,
  hierarchy,
  selection,
  onSelect,
  onClose,
  comparator,
  width,
}: {
  segmentTitles: string[];
  hierarchy: Hierarchy;
  selection: string[];
  onSelect: (selection: string[]) => void;
  onClose: () => void;
  comparator: Comparator;
  width: number;
}) {
  // the current path into the hierarchy
  const [path, setPath] = useState<string[]>([]);
  // the path along which every node has been rendered
  const [renderPath, setRenderPath] = useState<string[]>([]);
  const [screenHeights, setScreenHeights] = useState<ReadonlyArray<number>>(
    new Array(maxDepth(hierarchy)),
  );
  const [height, setHeight] = useState<'auto' | number>('auto');

  // Go forward by one screen
  function pushSegment(segment: string) {
    const nextPath = path.concat(segment);

    setPath(nextPath);
    setHeight(
      isPrefix(nextPath, renderPath)
        ? unwrap(screenHeights[nextPath.length])
        : height,
    );
    setRenderPath(renderPath.slice(0, path.length).concat(segment));
  }

  // Go back by one screen
  function popSegment() {
    const nextPath = path.slice(0, -1);
    setPath(nextPath);
    setHeight(unwrap(screenHeights[nextPath.length]));
  }

  function handleScreenMeasure(index: number, rect: Rect) {
    setScreenHeights(update(index, rect.height, screenHeights));
    // the active screen determines height
    setHeight(path.length === index ? rect.height : height);
  }

  function renderList(subpath: string[], comparator: Comparator) {
    const listData = rpath<Hierarchy | null>(subpath, hierarchy);
    const containsSelection = equals(
      subpath,
      selection.slice(0, subpath.length),
    );
    const isActiveList = equals(path, subpath);

    return (
      <div>
        {listData &&
          sort(comparator, Object.keys(listData)).map(item => {
            const isLeaf = listData[item] === null;
            const isSelected =
              containsSelection && item === selection[subpath.length];

            return (
              <ListItem
                key={item}
                tabIndex={0}
                role="button"
                onClick={() => {
                  if (!isActiveList) return;
                  if (!isLeaf) pushSegment(item);
                  else onSelect(subpath.concat(item));
                }}
              >
                <SelectionBar
                  style={{ display: isSelected ? 'block' : 'none' }}
                />
                <ItemInner>
                  <ItemText>{item}</ItemText>
                  {!isLeaf && (
                    <ChevronRightIcon
                      {...(isSelected ? { color: matisse } : {})}
                    />
                  )}
                  {isLeaf && isSelected && <CheckMarkIcon color={matisse} />}
                </ItemInner>
              </ListItem>
            );
          })}
      </div>
    );
  }

  return (
    <Root style={{ width, height }}>
      <AnimationRect
        style={{ transform: `translateX(-${path.length * width}px)` }}
      >
        {sublists(renderPath).map((subpath, idx) => (
          <Measurer
            key={subpath.join()}
            onMeasure={rect => handleScreenMeasure(idx, rect)}
          >
            <Screen
              style={{
                width,
                opacity: subpath.length === path.length ? 1 : 0,
              }}
            >
              <Header>
                {!isEmpty(subpath) && (
                  <Back onClick={popSegment}>
                    <ChevronLeftIcon size={BACK_ICON_SIZE} />
                  </Back>
                )}
                <Title>{segmentTitles[subpath.length]}</Title>
                <Close onClick={onClose}>
                  <CrossIcon color={dustyGray} />
                </Close>
              </Header>
              {renderList(subpath, comparator)}
            </Screen>
          </Measurer>
        ))}
      </AnimationRect>
    </Root>
  );
}
