/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SettingsPresentationalSetSelfReportedGradeMutationVariables = {
    gradeId: string;
};
export type SettingsPresentationalSetSelfReportedGradeMutationResponse = {
    readonly setSelfReportedGrade: {
        readonly selfReportedGrade: {
            readonly id: string;
            readonly title: string;
        };
    } | null;
};
export type SettingsPresentationalSetSelfReportedGradeMutation = {
    readonly response: SettingsPresentationalSetSelfReportedGradeMutationResponse;
    readonly variables: SettingsPresentationalSetSelfReportedGradeMutationVariables;
};



/*
mutation SettingsPresentationalSetSelfReportedGradeMutation(
  $gradeId: ID!
) {
  setSelfReportedGrade(gradeId: $gradeId) {
    selfReportedGrade {
      id
      title
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gradeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "gradeId",
    "variableName": "gradeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "selfReportedGrade",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsPresentationalSetSelfReportedGradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LanternStudent",
        "kind": "LinkedField",
        "name": "setSelfReportedGrade",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettingsPresentationalSetSelfReportedGradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LanternStudent",
        "kind": "LinkedField",
        "name": "setSelfReportedGrade",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bb8611ba685f898af4ff8b8156c50f23",
    "id": null,
    "metadata": {},
    "name": "SettingsPresentationalSetSelfReportedGradeMutation",
    "operationKind": "mutation",
    "text": "mutation SettingsPresentationalSetSelfReportedGradeMutation(\n  $gradeId: ID!\n) {\n  setSelfReportedGrade(gradeId: $gradeId) {\n    selfReportedGrade {\n      id\n      title\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a7bbf374eaf34955b99b72f3004bd60d';
export default node;
