import type { SyllabusLocale } from './__generated__/LanguageSelectorContextTypeGeneratorQuery.graphql';

export const LANGUAGE_MAP: Record<
  SyllabusLocale,
  {
    short: string;
    long: string;
  }
> = {
  EN_AU: {
    short: 'EN AU',
    long: 'English (AU)',
  },
  EN_GB: {
    short: 'EN GB',
    long: 'English (GB)',
  },
  EN_US: {
    short: 'EN US',
    long: 'English (US)',
  },
  ES: {
    short: 'ES',
    long: 'Español',
  },
  FR: {
    short: 'FR',
    long: 'Français',
  },
  HT_HT: {
    short: 'HT',
    long: 'kreyòl ayisyen',
  },
  PT: {
    short: 'PT',
    long: 'Português',
  },
};
export const DEFAULT_SYLLABUS_LOCALE = 'EN_US';
