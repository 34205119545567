/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CheckInSessionQuestion = {
    readonly __typename: "MultipleChoiceQuestion";
    readonly __typename: "MultipleChoiceQuestion";
    readonly id: string;
    readonly sanaItemId: string;
    readonly transpiledText: string;
    readonly transpiledOptions: ReadonlyArray<string>;
    readonly numberOfAnswers: number;
} | {
    readonly __typename: "MathExpressionQuestion";
    readonly __typename: "MathExpressionQuestion";
    readonly id: string;
    readonly sanaItemId: string;
    readonly transpiledText: string;
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
};
export type CheckInSessionQuestion$data = CheckInSessionQuestion;
export type CheckInSessionQuestion$key = {
    readonly " $data"?: CheckInSessionQuestion$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInSessionQuestion">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanaItemId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledText",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "CheckInSessionQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transpiledOptions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfAnswers",
          "storageKey": null
        }
      ],
      "type": "MultipleChoiceQuestion",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "type": "MathExpressionQuestion",
      "abstractKey": null
    }
  ],
  "type": "QuestionInterface",
  "abstractKey": "__isQuestionInterface"
};
})();
(node as any).hash = '6f5d41f52458cc73854161b7b6919c4f';
export default node;
