import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FolderRemove = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={15}
  >
    <path d="M8.081 1.834c.174.25.46.399.764.399h5.108a2.05 2.05 0 0 1 1.448.599c.383.384.599.905.599 1.447V9H9a2 2 0 0 0-2 2v2c0 .384.108.742.295 1.046H2.047a2.05 2.05 0 0 1-1.448-.599A2.05 2.05 0 0 1 0 12V2.047C0 .917.916 0 2.047 0h3.688c.669 0 1.296.327 1.679.877l.667.957Z" />
    <path d="M14.772 11h-4.544C9.553 11 9 11.456 9 12s.553 1 1.228 1h4.544c.675 0 1.228-.456 1.228-1s-.553-1-1.228-1Z" />
  </Icon>
);
FolderRemove.displayName = 'FolderRemove';

export default FolderRemove;
