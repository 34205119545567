import {
  useCallback,
  useContext,
  useEffect,
  type KeyboardEvent as SyntheticKeyboardEvent,
} from 'react';

import CaptureEnter from 'ms-components/CaptureEnter';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useAddStudentsToClass from 'ms-pages/Teacher/TeacherClassReport/views/TeacherClassSettings/components/ClassSettingsStudents/ClassSettingsAddStudentsModal/useAddStudentsToClass';
import { StateContext } from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  fontWeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Avatar from 'ms-ui-primitives/Avatar';
import Button from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import { unwrap } from 'ms-utils/typescript-utils';
import {
  teacherStudentInsightsUrl,
  teacherStudentSettingsUrl,
} from 'ms-utils/urls';

import type { StudentFound } from './types';

const Root = styled(
  {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${colors.ironLight}`,
    borderRadius: borderRadiusUI,
    padding: 4 * BASE_UNIT,
    marginBottom: 2 * BASE_UNIT,
    ':last-child': {
      marginBottom: 13 * BASE_UNIT,
    },
  },
  CaptureEnter as any, // ms-utils/emotion suckage
);

const StudentDetails = styled({
  display: 'flex',
  alignItems: 'center',
});

const Column = styled({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 2 * BASE_UNIT,
});

const Label = styled({
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.small,
});

const StudentLink = styled(
  {
    fontFamily: fontFamily.body,
    fontSize: fontSize.small,
    color: colors.eggplant,
    maxWidth: 90 * BASE_UNIT,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
  },
  'a',
);

const AvatarWrapper = styled({
  flexShrink: 0,
});

type Props = {
  student: StudentFound;
  onSubmitted?: (() => void) | undefined;
  disableButtons: boolean;
  onSubmit: () => void;
};

export default function DuplicateStudentCard({
  student,
  onSubmitted,
  disableButtons,
  onSubmit,
}: Props) {
  const { classes } = useContext(StateContext);
  const {
    featureFlags: { classicUi },
  } = useViewer();
  const { trackStructEvent } = useSnowplow();

  const [addStudentsToClassMutation, { response }] = useAddStudentsToClass({
    classId: unwrap(classes[0]).id,
    studentIds: [student.id],
    numberOfStudents: RELAY_CONNECTION_MAX,
    isSunflowerUi: !classicUi,
  });

  const klass = response?.addStudentsToClass.class;

  useEffect(() => {
    if (klass != null) {
      if (onSubmitted != null) {
        onSubmitted();
      } else {
        window.location.assign(
          teacherStudentSettingsUrl({ studentId: student.id }),
        );
      }
    }
  }, [onSubmitted, klass, student.id]);

  useEffect(
    () =>
      trackStructEvent({
        category: 'teacher_admin',
        action: 'duplicate_student_found',
        label: student.id,
      }),
    [student.id, trackStructEvent],
  );

  const submit = useCallback(() => {
    trackStructEvent({
      category: 'teacher_admin',
      action: 'clicked_add_duplicate_student_to_class',
      label: student.id,
    });
    addStudentsToClassMutation();
    onSubmit();
  }, [addStudentsToClassMutation, onSubmit, student.id, trackStructEvent]);
  return (
    <Root
      action={(ev: SyntheticKeyboardEvent<HTMLDivElement>) => {
        // prevent this from triggering the above capture enter
        ev.stopPropagation();
        submit();
      }}
    >
      <StudentDetails>
        <AvatarWrapper>
          <Avatar src={student.user.avatar} size={7 * BASE_UNIT} />
        </AvatarWrapper>
        <Column>
          <Label>We found a similar account</Label>
          <StudentLink
            href={teacherStudentInsightsUrl({ studentId: student.id })}
          >
            {student.user.firstName} {student.user.lastName}
            {student.user.email && ` (${student.user.email})`}
          </StudentLink>
        </Column>
      </StudentDetails>
      <Button isDisabled={disableButtons} padding={0} onClick={submit}>
        ADD STUDENT
      </Button>
    </Root>
  );
}
