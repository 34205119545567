import type { TaskTypes } from 'ms-pages/Tasks/types';
import { assertUnreachable } from 'ms-utils/typescript-utils';

export const taskTypeLabel = (type: TaskTypes) => {
  switch (type) {
    case 'CUSTOM_TASK':
      return 'Custom task';
    case 'ADAPTIVE_TASK':
      return 'Adaptive task';
    case 'LESSON_TASK':
      return 'Lesson';
    case 'WORKSHEET_TASK':
      return 'Worksheet';
    case 'ENGAGE_TASK':
      return 'Engage task';
    case 'CHECK_IN_TASK':
      return 'Check-in task';
    default:
      assertUnreachable(type, `Unknown task type "${type}"`);
  }
};
