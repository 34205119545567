import type {
  TargetMastery,
  TaskDueDate,
  TaskName,
  TaskStartDate,
  Updater,
  SelectedClasses,
  SelectedStudents,
  ExcludedStudents,
} from '../index';

export const updateTaskName: Updater<[TaskName]> = newTaskName => state => ({
  ...state,
  taskName: newTaskName,
});

export const updateTargetMastery: Updater<[TargetMastery]> =
  targetMastery => state => ({
    ...state,
    targetMastery,
  });

export const updateTaskStartDate: Updater<[TaskStartDate]> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
  });

export const updateTaskDueDate: Updater<[TaskDueDate]> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
});

export const toggleDisableCalculator: Updater<[]> = () => state => ({
  ...state,
  disableCalculator: !state.disableCalculator,
});

export const updateSelectedClasses: Updater<[SelectedClasses]> =
  selectedClasses => state => ({
    ...state,
    selectedClasses,
  });

export const updateSelectedStudents: Updater<[SelectedStudents]> =
  selectedStudents => state => ({
    ...state,
    selectedStudents,
  });

export const updateExcludedStudents: Updater<[ExcludedStudents]> =
  excludedStudents => state => ({
    ...state,
    excludedStudents,
  });
