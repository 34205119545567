/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AvatarEnum = "ARTIST" | "ASTRONAUT" | "AVATAR_01" | "AVATAR_02" | "AVATAR_03" | "AVATAR_04" | "AVATAR_05" | "AVATAR_06" | "AVATAR_07" | "AVATAR_08" | "AVATAR_09" | "AVATAR_10" | "AVATAR_11" | "AVATAR_12" | "AVATAR_DEFAULT" | "BASKETBALL" | "BUNNY" | "CAKE" | "CONSTRUCTOR" | "CORGI" | "DOCTORBEAR" | "F1RACER" | "FOOTBALLPLAYER" | "GIRL" | "MUSICIAN" | "PENGUIN" | "PILOT" | "ROBOT" | "SCIENTIST" | "SEAL" | "SOFTWAREENGINEER" | "SPACECAT" | "TEACHER";
export type UpdateUserInput = {
    email?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    timezone?: string | null | undefined;
    country?: string | null | undefined;
    password?: string | null | undefined;
    avatar?: AvatarEnum | null | undefined;
    syllabusFocusId?: number | null | undefined;
    parentEmail?: string | null | undefined;
    guardianEmails?: Array<string> | null | undefined;
};
export type AvatarContainerUpdateAvatarMutationVariables = {
    user: UpdateUserInput;
    pk: number;
};
export type AvatarContainerUpdateAvatarMutationResponse = {
    readonly updateUser: {
        readonly user: {
            readonly avatar: string;
        } | null;
    };
};
export type AvatarContainerUpdateAvatarMutation = {
    readonly response: AvatarContainerUpdateAvatarMutationResponse;
    readonly variables: AvatarContainerUpdateAvatarMutationVariables;
};



/*
mutation AvatarContainerUpdateAvatarMutation(
  $user: UpdateUserInput!
  $pk: Int!
) {
  updateUser(user: $user, pk: $pk) {
    user {
      avatar
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pk"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user"
},
v2 = [
  {
    "kind": "Variable",
    "name": "pk",
    "variableName": "pk"
  },
  {
    "kind": "Variable",
    "name": "user",
    "variableName": "user"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AvatarContainerUpdateAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateUserResult",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AvatarContainerUpdateAvatarMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateUserResult",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "34f9e4a4a9c46a2c02acd410203b0a5b",
    "id": null,
    "metadata": {},
    "name": "AvatarContainerUpdateAvatarMutation",
    "operationKind": "mutation",
    "text": "mutation AvatarContainerUpdateAvatarMutation(\n  $user: UpdateUserInput!\n  $pk: Int!\n) {\n  updateUser(user: $user, pk: $pk) {\n    user {\n      avatar\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f5cc270bba88859d712f952c6c31649e';
export default node;
