import styled from '@emotion/styled';
import parse from 'html-react-parser';

import { BodyM, BodyS, HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { borderRadiusUILarge } from 'ms-styles/base';
import { colors, type ColorName } from 'ms-styles/colors';
import { HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import { tappable } from 'ms-utils/emotion';

import type { MTSSearchResultsQueryResponse } from './__generated__/MTSSearchResultsQuery.graphql';
import ChevronRight from '../icons/ChevronRight';

const SEARCH_RESULT_TOPIC_BADGE_SIZE = 64;

const SearchResult = styled.div<{ background?: ColorName | undefined }>(
  ({ background = 'almond50' }) => ({
    width: '100%',
    background: colors[background],
    padding: '16px 20px',
    borderRadius: borderRadiusUILarge,
    display: 'flex',
    flexDirection: 'column',
    ...tappable,
  }),
);
const SearchResultHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
});
const SearchResultTopicBadge = styled.img({
  height: SEARCH_RESULT_TOPIC_BADGE_SIZE,
  width: SEARCH_RESULT_TOPIC_BADGE_SIZE,
});
const SearchResultHeaderContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

interface Props {
  hit: MTSSearchResultsQueryResponse['multiTextbookSearch']['hits'][number];
  onResultClick?:
    | ((props: {
        textbookName: string;
        syllabusId: string;
        topicId: string;
        subtopicId: string;
        title: string;
      }) => void)
    | undefined;
  backgroundColor?: ColorName;
}

export default function SearchResultHit({
  backgroundColor,
  hit,
  onResultClick,
}: Props) {
  const {
    document: {
      title,
      textbook: textbookName,
      syllabusId,
      topicId,
      subtopicId,
      topicBadgeUrl,
      contentSnippet,
      highlights,
    },
  } = hit;
  let highlightedTitle = null;
  let highlightedContent = null;
  if (highlights != null && highlights.length > 0) {
    const titleHighlight = highlights.filter(
      highlight => highlight.field === 'title',
    )[0];
    if (titleHighlight != null) {
      highlightedTitle = titleHighlight.snippet;
    }
    const contentHighlight = highlights.filter(
      highlight => highlight.field === 'content',
    )[0];
    if (contentHighlight != null) {
      highlightedContent = contentHighlight.snippet;
    }
  }
  return (
    <SearchResult
      background={backgroundColor}
      onClick={() => {
        onResultClick?.({
          textbookName,
          syllabusId,
          subtopicId,
          topicId,
          title,
        });
      }}
    >
      <SearchResultHeader>
        <SearchResultTopicBadge src={topicBadgeUrl} />
        <HSpacer width={24} />
        <SearchResultHeaderContentWrapper>
          <HeadingS color="grey">
            {highlightedTitle ? parse(highlightedTitle) : title}
          </HeadingS>
          <VSpacer height={8} />
          <BodyM bold color="grey90">
            {textbookName}
          </BodyM>
        </SearchResultHeaderContentWrapper>
        <HSpacer width={24} grow />
        <ChevronRight size={20} color={colors.grey} />
      </SearchResultHeader>
      <VSpacer height={12} />
      <BodyS color="grey90">
        {highlightedContent ? parse(highlightedContent) : contentSnippet}
      </BodyS>
    </SearchResult>
  );
}
