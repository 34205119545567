import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g transform="translate(2.000000, 2.000000)">
    <path d="M15.4285607,6.87500764 L6.20457156,6.87500764 C5.89469455,5.54332146 4.65645881,4.73853828 3.43888153,5.07746567 C2.63142674,5.30224539 2.00092494,5.99185552 1.79541169,6.87500764 L0.571439334,6.87500764 C0.255836875,6.87500764 0,7.15482873 0,7.50001831 C0,7.84520789 0.255836875,8.12499236 0.571439334,8.12499236 L1.79544518,8.12499236 C2.10532219,9.45667854 3.34355794,10.2614617 4.56113521,9.92253433 C5.36859001,9.69775461 5.9990918,9.00814448 6.20460505,8.12499236 L15.4285607,8.12499236 C15.7441631,8.12499236 16,7.84517127 16,7.49998169 C16,7.15479211 15.7441631,6.87500764 15.4285607,6.87500764 Z" />
    <path d="M15.4285607,1.87499391 L13.633149,1.87499391 C13.323272,0.543317482 12.0850362,-0.2614598 10.8674589,0.0774651064 C10.0600041,0.302243181 9.42950235,0.991848251 9.2239891,1.87499391 L0.571439334,1.87499391 C0.255836875,1.87499391 0,2.15481295 0,2.5 C0,2.84518705 0.255836875,3.12500609 0.571439334,3.12500609 L9.2239891,3.12500609 C9.53386612,4.45668252 10.7721019,5.2614598 11.9896791,4.92253489 C12.7971339,4.69775682 13.4276357,4.00815175 13.633149,3.12500609 L15.4285607,3.12500609 C15.7441631,3.12500609 16,2.84518705 16,2.5 C16,2.15481295 15.7441631,1.87499391 15.4285607,1.87499391 Z" />
    <path d="M15.4285607,12.8750076 L12.4902703,12.8750076 C12.1803933,11.5433215 10.9421575,10.7385383 9.72458027,11.0774657 C8.91712548,11.3022454 8.28662368,11.9918555 8.08111043,12.8750076 L0.571439334,12.8750076 C0.255836875,12.8750076 0,13.1548287 0,13.4999817 C0,13.8451346 0.255836875,14.1249924 0.571439334,14.1249924 L8.08114392,14.1249924 C8.39102093,15.4566785 9.62925668,16.2614617 10.846834,15.9225343 C11.6542887,15.6977546 12.2847905,15.0081445 12.4903038,14.1249924 L15.4285607,14.1249924 C15.7441631,14.1249924 16,13.8451713 16,13.4999817 C16,13.1547921 15.7441631,12.8750076 15.4285607,12.8750076 Z" />
  </g>
);

const Filter = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Filter.displayName = 'Filter';

export default Filter;
