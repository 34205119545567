import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const PaperSheetThumbsUp = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color="none"
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={12}
    viewBoxHeight={12}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.71105 0.490396C4.71105 0.219044 4.49434 0 4.22588 0H0.727763C0.325067 0 0 0.328566 0 0.588476V11.1173C0 11.2644 0.0226414 11.3952 0.0646899 11.5096C0.179531 11.8218 0.433423 12 0.727763 12H5.43174C5.3167 11.7936 5.25 11.5541 5.25 11.2926V8.53256C5.25 7.68107 5.95717 7.06256 6.7375 7.06256H7.36251C7.44135 7.06256 7.51912 7.0687 7.59525 7.0806C7.61043 7.05667 7.61737 7.04106 7.62027 7.03349C7.62097 6.71928 7.64291 6.2862 7.85235 5.91968C7.98321 5.69067 8.18104 5.49517 8.44371 5.37478C8.65791 5.2766 8.86016 5.25413 9 5.25057V4.84348C9 4.57211 8.78329 4.35309 8.51482 4.35309H5.19623C4.92776 4.35309 4.71105 4.13404 4.71105 3.86269V0.490396ZM6.37465 0.0643589C6.32928 0.0190152 6.27513 0 6.22099 0C6.10832 0 6 0.0877621 6 0.219405V2.78059C5.99857 2.902 6.09808 3 6.21808 3H8.78049C8.9122 3 8.99854 2.89322 9 2.78059C9 2.72648 8.98098 2.67089 8.93561 2.62553L6.37465 0.0643589Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67031 7.05002C8.67031 6.42502 8.79531 6.30002 9.04531 6.30002C9.29531 6.30002 9.85781 6.55002 9.85781 7.30002C9.85781 7.92503 9.67031 8.30003 9.67031 8.30003H10.8503C11.4703 8.30003 11.9403 8.85753 11.8353 9.47004L11.5553 11.095C11.4728 11.5751 11.0578 11.9251 10.5703 11.9251H10.1703C8.95649 11.9251 8.16413 11.6636 7.75465 11.4788C7.68302 11.6172 7.53418 11.7126 7.36256 11.7126H6.73755C6.4963 11.7126 6.30005 11.5242 6.30005 11.2926V8.53258C6.30005 8.30098 6.4963 8.11258 6.73755 8.11258H7.36256C7.5348 8.11258 7.68411 8.20862 7.75543 8.34785C8.12567 8.09615 8.67031 7.628 8.67031 7.05002Z"
      fill={color}
    />
  </Icon>
);
PaperSheetThumbsUp.displayName = 'PaperSheetThumbsUp';

export default PaperSheetThumbsUp;
