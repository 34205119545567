import styled from '@emotion/styled';

import ChevronDown from 'ms-pages/Lantern/components/icons/ChevronDown';

const ChevronWrapper = styled.div<{
  isDown: boolean;
}>(props => ({
  display: 'flex',
  transition: 'transform 250ms ease',
  ...(props.isDown ? {} : { transform: 'rotateX(180deg)' }),
}));

export default function RotatingChevron({
  color,
  size,
  isDown = true,
}: {
  color: string;
  size: number;
  isDown?: boolean | undefined;
}) {
  return (
    <ChevronWrapper isDown={isDown}>
      <ChevronDown size={size} color={color} />
    </ChevronWrapper>
  );
}
