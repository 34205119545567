import type { ReactNode } from 'react';

import { useCollapse } from 'ms-pages/Teacher/components/Collapse/useCollapse';

const styles = {
  display: 'block',
  transition: 'ease 0.4s',
  overflow: 'hidden',
};

type AccordionProps = {
  children: ReactNode;
  isOpen: boolean;
};

export function Accordion({ children, isOpen }: AccordionProps) {
  const [outer, inner] = useCollapse<HTMLDivElement, HTMLDivElement>({
    isOpen,
  });

  return (
    <div ref={outer} css={[styles]}>
      <div ref={inner}>{isOpen && children}</div>
    </div>
  );
}
