import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M16.3636364,16.3363636 C16.3636364,16.8384407 15.9566225,17.2454545 15.4545455,17.2454545 L4.54545455,17.2454545 C4.0433775,17.2454545 3.63636364,16.8384407 3.63636364,16.3363636 C3.63636364,13.3239014 6.07844682,10.8818182 9.09090909,10.8818182 L10.9090909,10.8818182 C13.9215532,10.8818182 16.3636364,13.3239014 16.3636364,16.3363636 L16.3636364,16.3363636 Z M10,2.7 C7.99169182,2.7 6.36363636,4.32805546 6.36363636,6.33636364 C6.36363636,8.34467182 7.99169182,9.97272727 10,9.97272727 C12.0083082,9.97272727 13.6363636,8.34467182 13.6363636,6.33636364 C13.6363636,4.32805546 12.0083082,2.7 10,2.7 L10,2.7 Z M17.2727273,4.51818182 C15.7664961,4.51818182 14.5454545,5.73922341 14.5454545,7.24545455 C14.5454545,8.75168568 15.7664961,9.97272727 17.2727273,9.97272727 C18.7789584,9.97272727 20,8.75168568 20,7.24545455 C20,5.73922341 18.7789584,4.51818182 17.2727273,4.51818182 L17.2727273,4.51818182 Z M2.72727273,4.51818182 C1.22104159,4.51818182 0,5.73922341 0,7.24545455 C0,8.75168568 1.22104159,9.97272727 2.72727273,9.97272727 C4.23350386,9.97272727 5.45454545,8.75168568 5.45454545,7.24545455 C5.45454545,5.73922341 4.23350386,4.51818182 2.72727273,4.51818182 L2.72727273,4.51818182 Z M17.2727273,10.8818182 C16.8446078,10.8836707 16.1319962,11.1191984 15.7540705,11.3203498 C17.1209577,12.6839302 18.180925,14.4056303 18.1818182,16.3363636 C18.1793595,16.6464939 18.1240205,16.9539327 18.0181818,17.2454545 L19.0909091,17.2454545 C19.5929861,17.2454545 20,16.8384407 20,16.3363636 L20,13.6090909 C20,12.1028598 18.7789584,10.8818182 17.2727273,10.8818182 Z M1.81818182,16.3363636 C1.81907504,14.4056303 2.90369082,12.6358209 4.270578,11.2722406 C3.89265232,11.0710892 3.15539219,10.8836707 2.72727273,10.8818182 C1.22104159,10.8818182 4.4408921e-16,12.1028598 4.4408921e-16,13.6090909 L4.4408921e-16,16.3363636 C4.4408921e-16,16.8384407 0.407013864,17.2454545 0.909090909,17.2454545 L1.98181818,17.2454545 C1.8759795,16.9539327 1.82064051,16.6464939 1.81818182,16.3363636 Z" />
);

const Class = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Class.displayName = 'Class';

export default Class;
