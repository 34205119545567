import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function NotAvailableInAccessibilityMode({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxWidth={20}
      viewBoxHeight={20}
      color={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C7.79418 20 5.75517 19.2858 4.1016 18.076L6.98987 15.1878L6.83629 15.5789C6.73384 15.8038 6.75958 16.0664 6.90377 16.2671C7.04796 16.4678 7.28854 16.5761 7.5344 16.5508C7.78025 16.5255 7.9938 16.3706 8.09415 16.1448L9.53808 12.6396L10.0952 12.0824C10.1922 12.1107 10.2733 12.1816 10.3131 12.2781L11.9059 16.1448C12.0622 16.4921 12.4705 16.647 12.8179 16.4907C13.1652 16.3344 13.3201 15.9261 13.1638 15.5788L11.4986 11.3382C11.4305 11.1648 11.3911 10.9817 11.3816 10.796L13.0429 9.13478L14.9334 8.96327C15.1805 8.94628 15.3995 8.79806 15.5072 8.57495C15.6149 8.35184 15.5946 8.08813 15.4541 7.8841C15.3136 7.68007 15.0745 7.56707 14.8276 7.58806L14.5661 7.61153L18.076 4.1016C19.2858 5.75517 20 7.79418 20 10ZM17.3073 3.17329L12.7017 7.77885L12.6907 7.77984L8.09361 12.3769L8.08709 12.3935L3.17329 17.3073C3.17155 17.3057 3.16982 17.3041 3.16808 17.3024L2.20714 18.2634C1.81661 18.6539 1.18345 18.6539 0.792922 18.2634C0.402398 17.8729 0.402398 17.2397 0.792922 16.8492L1.84838 15.7937C0.684507 14.1591 0 12.1595 0 10C0 4.47715 4.47715 0 10 0C12.1595 0 14.1591 0.684507 15.7937 1.84838L17.0564 0.585712C17.4469 0.195188 18.0801 0.195188 18.4706 0.585712C18.8611 0.976236 18.8611 1.6094 18.4706 1.99993L17.3024 3.16808C17.3041 3.16982 17.3057 3.17155 17.3073 3.17329ZM9.1945 7.93099H9.7111L8.3056 9.33648C8.2145 9.27748 8.1083 9.23928 7.99339 9.22885L5.06674 8.96334C4.82107 8.94445 4.60414 8.79593 4.49766 8.57374C4.39118 8.35154 4.41133 8.08941 4.55052 7.8861C4.68971 7.68279 4.92679 7.56917 5.17246 7.58806L8.79384 7.91306C8.92718 7.92501 9.06073 7.93099 9.1945 7.93099ZM10.0001 6.55179C10.9523 6.55179 11.7242 5.77987 11.7242 4.82765C11.7242 3.87544 10.9523 3.10352 10.0001 3.10352C9.04786 3.10352 8.27594 3.87544 8.27594 4.82765C8.27594 5.77987 9.04786 6.55179 10.0001 6.55179Z"
      />
    </Icon>
  );
}
