import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M6.93682409,4.16714853 L15.4519101,8.68845141 C16.0208731,8.99055689 16.1712018,9.59878217 15.7876786,10.0469606 C15.6983843,10.1513083 15.5843796,10.2411111 15.4519101,10.3114491 L6.93682409,14.832752 C6.36786109,15.1348575 5.59571845,15.016442 5.21219523,14.5682635 C5.07388819,14.4066403 5,14.2161667 5,14.0212532 L5,4.9786474 C5,4.43815536 5.55623869,4 6.24239389,4 C6.4898368,4 6.73164331,4.05820254 6.93682409,4.16714853 Z" />
);

const Play = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Play.displayName = 'Play';

export default Play;
