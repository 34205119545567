import { Redirect } from 'react-router-dom';

import { Snackbar } from 'ms-components/Snackbar';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { homeUrl } from 'ms-pages/Lantern/utils/urls';
import { WithStudentOnboarding } from 'ms-pages/StudentOnboarding/WithStudentOnboarding';

import { StudentApp } from './StudentApp';
import { StudentContextProvider } from './StudentContext';
import { StudentWelcome } from './StudentWelcome';

export default function Student() {
  return (
    <WithStudentOnboarding>
      <OnboardedStudent />
    </WithStudentOnboarding>
  );
}

function OnboardedStudent() {
  const { lanternProfileId, role } = useViewer();

  if (role !== 'Student') {
    return <Redirect to={homeUrl} />;
  }

  if (lanternProfileId == null) {
    return <StudentWelcome />;
  }

  return (
    <StudentContextProvider lanternProfileId={lanternProfileId}>
      <StudentApp />
      <Snackbar rightAligned />
    </StudentContextProvider>
  );
}

export { Student };
