import { useState, useContext, useEffect, useMemo, useCallback } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector/index';
import { useCreateNewAdaptiveExperienceTaskState } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import useCreateBulkAdaptiveTaskState from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/state';
import useCreateBulkAdaptiveTask from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/state/useCreateBulkAdaptiveTask';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';

export function useBulkAdaptiveFlowState({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  prefilledTargetMastery,
}: {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic?: SelectedSubtopic | null | undefined;
  prefilledTargetMastery?: number | null | undefined;
}) {
  const selectedSubtopics = useState<ReadonlyArray<SelectedSubtopic>>(
    prefilledSubtopic != null ? [prefilledSubtopic] : [],
  );

  const selectedClasses = prefilledClasses != null ? prefilledClasses : [];
  const selectedStudents = prefilledStudents != null ? prefilledStudents : [];
  const excludedStudents =
    prefilledExcludedStudents != null ? prefilledExcludedStudents : [];
  const { teacherPreference } = useMaybeTeacherContext();
  const lastSelectedTargetMastery = teacherPreference.createTaskTargetMastery;

  const createAdaptiveTask = useCreateNewAdaptiveExperienceTaskState({
    subtopicId: null,
    selectedClasses,
    selectedStudents,
    excludedStudents,
    taskStartDate: prefilledStartDate,
    taskDueDate: prefilledDueDate,
    targetMastery: prefilledTargetMastery || lastSelectedTargetMastery,
    assignedCheckinSkillIds: [],
  });
  // this is is gonna be removed next PR
  const bulkAdaptiveTask = useCreateBulkAdaptiveTaskState(
    createAdaptiveTask[0].values,
  );

  return {
    selectedSubtopics,
    createAdaptiveTask,
    bulkAdaptiveTask,
  };
}

export function useSaveBulkAdaptiveTasks({
  getState,
  selectedSubtopicIds,
  onAllSaved,
}: {
  getState: (
    id: string,
  ) => ReturnType<typeof useCreateNewAdaptiveExperienceTaskState>[0]['values'];
  selectedSubtopicIds: ReadonlyArray<string>;
  onAllSaved: () => void;
}) {
  // a dictionary of subtopicIds to taskIds
  const [saved, setSaved] = useState<Record<string, string>>({});
  const [errored, setErrored] = useState<Record<string, ReadonlyArray<Error>>>(
    {},
  );

  const [save, saving] = useCreateBulkAdaptiveTask({
    // we don't save things twice
    subtopicIds: selectedSubtopicIds.filter(
      selected => saved[selected] == null,
    ),
    getState,
    // we remove each item from the list as it's saved
    onSave: (subtopicId, task) =>
      setSaved(prevSaved => ({ ...prevSaved, [subtopicId]: task.id })),
    onError: (subtopicId, messages) => {
      setErrored(prevSaved => ({ ...prevSaved, [subtopicId]: messages }));
    },
  });

  const { enqueueMessage } = useContext(SnackbarContext);
  const numberOfSavedItems = Object.keys(saved).length;
  const allSaved = numberOfSavedItems === selectedSubtopicIds.length;

  const subtopicsIds = Object.keys(saved);
  const assignedClasses = subtopicsIds
    .map(getState)
    .map(s => s.selectedClasses)
    .flat();

  const classTasksReportUrl =
    useGetFirstAvailableClassTasksReportUrl(assignedClasses);

  useEffect(() => {
    if (saving === false) {
      if (numberOfSavedItems > 0 && allSaved) {
        onAllSaved();

        enqueueMessage({
          text: `${numberOfSavedItems} task${
            numberOfSavedItems > 1 ? 's' : ''
          } created`,
          actionLabel: 'View tasks',
          href: classTasksReportUrl,
        });
      }
    }
    // `onAllSaved` and `enqueueMessage` are functions. They should not change
    // and they should not be part of the dependencies array as their value is not
    // something we need to sync as a result in a change of.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSaved, numberOfSavedItems, saving, classTasksReportUrl]);
  return {
    save,
    saving,
    savedItems: saved,
    erroredItems: errored,
  };
}

export function usePreviewAndTaskDetailsState({
  selectedSubtopics,
  stepName,
}: {
  selectedSubtopics: ReadonlyArray<SelectedSubtopic>;
  stepName: string;
}) {
  // Selected subtopic for preview and task details step
  // This is unrelated to the active subtopic in the subtopic selector
  const [previewActiveSelectedSubtopic, setPreviewActiveSelectedSubtopic] =
    useState<SelectedSubtopic | null | undefined>(selectedSubtopics[0] ?? null);

  const [
    isPreviewStepFloatingButtonEnabled,
    setIsPreviewStepFloatingButtonEnabled,
  ] = useState<boolean>(false);

  const previewActiveSelectedSubtopicIndex = useMemo(() => {
    return selectedSubtopics.findIndex(
      ([subtopicId]) => subtopicId === previewActiveSelectedSubtopic?.[0],
    );
  }, [previewActiveSelectedSubtopic, selectedSubtopics]);

  const handlePreviewActiveSelectedSubtopicIndexIncrease = useCallback(() => {
    if (previewActiveSelectedSubtopicIndex < selectedSubtopics.length - 1) {
      setPreviewActiveSelectedSubtopic(
        selectedSubtopics[previewActiveSelectedSubtopicIndex + 1],
      );
    }
  }, [previewActiveSelectedSubtopicIndex, selectedSubtopics]);

  const handlePreviewActiveSelectedSubtopicIndexDecrease = useCallback(() => {
    if (previewActiveSelectedSubtopicIndex > 0) {
      setPreviewActiveSelectedSubtopic(
        selectedSubtopics[previewActiveSelectedSubtopicIndex - 1],
      );
    }
  }, [previewActiveSelectedSubtopicIndex, selectedSubtopics]);

  // when there's selected subtopics and the preview active subtopic is null
  // set the preview active subtopic to the first selected subtopic
  // also if user goes back to subtopic selector, it resets the preview active subtopic to null
  useEffect(() => {
    if (
      previewActiveSelectedSubtopic == null &&
      selectedSubtopics.length > 0 &&
      stepName === 'preview_and_task_details'
    ) {
      setPreviewActiveSelectedSubtopic(selectedSubtopics[0]);
    } else if (
      stepName === 'subtopic_selector' &&
      previewActiveSelectedSubtopic != null
    ) {
      setPreviewActiveSelectedSubtopic(null);
    }
  }, [previewActiveSelectedSubtopic, selectedSubtopics, stepName]);

  return [
    previewActiveSelectedSubtopic,
    setPreviewActiveSelectedSubtopic,
    isPreviewStepFloatingButtonEnabled,
    setIsPreviewStepFloatingButtonEnabled,
    previewActiveSelectedSubtopicIndex,
    handlePreviewActiveSelectedSubtopicIndexIncrease,
    handlePreviewActiveSelectedSubtopicIndexDecrease,
  ] as const;
}
