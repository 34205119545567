import { css } from 'aphrodite';
import { useEffect } from 'react';

import { makeErrorList } from 'ms-pages/Signup/Sunflower/ErrorList';
import { UserForm } from 'ms-pages/Signup/Sunflower/UserForm';
import type { ErrorEnum } from 'ms-pages/Signup/Sunflower/__generated__/useCreateStudentForSunflowerMutation.graphql';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import useCreateStudentForSunflower from 'ms-pages/Signup/Sunflower/useCreateStudentForSunflower';
import { useFailedJoinClassAppOnSignUpBanner } from 'ms-pages/Sunflower/components/FailedJoinClassAppOnSignUpBanner';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import ButtonGroup from 'ms-ui-primitives/ButtonGroup';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Input from 'ms-ui-primitives/Input';
import { noop } from 'ms-utils/misc';
import {
  dashboardRedirectUrl,
  loginUrl,
  studyJoinClassUrl,
} from 'ms-utils/urls';
import { deserialize } from 'ms-utils/urls/queryParams';

import { formStyles } from './styles';

export function JoinClass() {
  const { state, dispatch } = useSignupState();
  const [
    createStudent,
    { response: studentResponse, errors: studentError, loading: isSubmitting },
  ] = useCreateStudentForSunflower();

  const { showFailedJoinClassAppOnSignUpBanner } =
    useFailedJoinClassAppOnSignUpBanner();
  useEffect(() => {
    const search = window?.location?.search || '';
    const queryParams = deserialize(search);
    if (queryParams.code != null) {
      dispatch({
        type: 'setStudentJoinCode',
        value: queryParams.code,
      });
    }
  }, [dispatch]);
  const student = studentResponse?.createStudentForSunflower?.student;
  const errors: ErrorEnum[] = (
    studentResponse?.createStudentForSunflower.errors ?? []
  ).map(e => e.key);
  // If the class the student is trying to join has reached the limit
  // of 30 students, we let them create the account anyway but we will
  // warn them that they could not join the class and that they should
  // speak to the teacher.
  const couldNotJoinClass = errors.includes(
    'STUDENT_CANNOT_JOIN_FREEMIUM_CLASS_ALREADY_FULL',
  );
  useEffect(() => {
    if (couldNotJoinClass) {
      showFailedJoinClassAppOnSignUpBanner();
    }
  }, [couldNotJoinClass, showFailedJoinClassAppOnSignUpBanner]);
  useEffect(() => {
    if (student != null) {
      window.location.href = dashboardRedirectUrl;
    }
  }, [student]);
  return (
    <>
      {studentError != null && (
        <div className={css(formStyles.formError)}>
          Something went wrong. Please try again.
        </div>
      )}
      <div>
        {/* FORM */}
        <div className={css(formStyles.container)}>
          <div className={css(formStyles.titleContainer)}>
            <div className={css(formStyles.title)}>Join a class</div>
            <div className={css(formStyles.subtitle)}>
              Enter the code provided by your teacher to join your class on all
              Mathspace products.
            </div>
          </div>
          <div className={css(formStyles.classCodeContainer)}>
            <FieldGroup title="Class code">
              <Input
                disabled={isSubmitting}
                errorMessage={makeErrorList(
                  state.responseErrors.studentJoinCode,
                )}
                onChange={event => {
                  dispatch({
                    type: 'setStudentJoinCode',
                    value: event.target.value,
                  });
                }}
                placeholder="Enter your class code here"
                value={state.studentJoinCode || ''}
              />
            </FieldGroup>
          </div>
          <div className={css(formStyles.tabContainer)}>
            <ButtonGroup
              onSelected={
                isSubmitting
                  ? noop
                  : selected =>
                      selected === 0
                        ? window.location.assign(
                            `${loginUrl}/?next=${studyJoinClassUrl}%3Fcode%3D${
                              state.studentJoinCode || ''
                            }`,
                          )
                        : dispatch({
                            type: 'setActiveTab',
                            value: selected,
                          })
              }
              selected={state.activeTab}
              segmentList={['I have an account', 'Create new account']}
            />
          </div>
          {state.activeTab === 1 && (
            <UserForm
              role="student"
              title="Student sign up"
              firstName={state.firstName}
              lastName={state.lastName}
              email={state.email}
              password={state.password}
              ageConfirmed
              showAgeConfirmed={false}
              onFirstNameChange={value =>
                dispatch({ type: 'setFirstName', value })
              }
              onLastNameChange={value =>
                dispatch({ type: 'setLastName', value })
              }
              onEmailChange={value => dispatch({ type: 'setEmail', value })}
              onPasswordChange={value =>
                dispatch({ type: 'setPassword', value })
              }
              onAgeConfirmedChange={noop}
              isDisabled={
                isSubmitting ||
                state.firstName === '' ||
                state.lastName === '' ||
                state.email === '' ||
                state.password === ''
              }
              onSubmit={() => createStudent({})}
              submitButtonLabel={
                isSubmitting ? <MinorSpinner scale={0.5} /> : 'Continue'
              }
              errorMessage={
                state.hasErrors
                  ? 'Please correct the errors to continue.'
                  : undefined
              }
              errors={state.responseErrors}
              trackingIdPrefix="Signup/JoinClass"
            />
          )}
        </div>
      </div>
    </>
  );
}
