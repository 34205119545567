import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Refresh = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
    color={color}
  >
    <path d="M15.8023 0.864625C15.6705 0.732839 15.5142 0.666727 15.3336 0.666727C15.153 0.666727 14.9969 0.732839 14.8648 0.864625L13.5107 2.20857C12.7677 1.5071 11.9169 0.963574 10.9586 0.578249C10.0005 0.192888 9.01406 0.000244141 8.00013 0.000244141C6.09033 0.000244141 4.42373 0.578286 3.00007 1.73462C1.57636 2.89082 0.6389 4.39935 0.1875 6.26059V6.33345C0.1875 6.42372 0.220447 6.50191 0.286449 6.5678C0.352451 6.6338 0.430531 6.66675 0.520796 6.66675H2.59369C2.74653 6.66675 2.85062 6.58685 2.90623 6.42722C3.19785 5.73283 3.38182 5.3266 3.45829 5.20849C3.94435 4.41683 4.59018 3.79526 5.39578 3.34386C6.20146 2.8925 7.06949 2.66672 7.99998 2.66672C9.39596 2.66672 10.6077 3.14246 11.6356 4.09386L10.1978 5.53128C10.0662 5.66325 10 5.81959 10 6.00016C10 6.18069 10.0662 6.33696 10.1978 6.46889C10.33 6.60086 10.4863 6.66679 10.6668 6.66679H15.3336C15.5141 6.66679 15.6704 6.60086 15.8023 6.46889C15.9341 6.33696 16.0001 6.18073 16.0001 6.00016V1.33339C16.0001 1.15282 15.9345 0.99663 15.8023 0.864625Z" />
    <path d="M15.4062 9.33357H13.4062C13.2534 9.33357 13.1492 9.41336 13.0938 9.57313C12.8023 10.2676 12.6181 10.6736 12.5418 10.7918C12.0556 11.5832 11.4097 12.205 10.6043 12.6564C9.79861 13.1079 8.93058 13.3334 8.00006 13.3334C7.32649 13.3334 6.67712 13.2085 6.0522 12.9586C5.4272 12.7084 4.86809 12.3541 4.3751 11.8958L5.80223 10.4687C5.93413 10.3369 6.00013 10.1805 6.00013 10C6.00013 9.81948 5.93413 9.66303 5.80223 9.53128C5.67027 9.39921 5.51407 9.33313 5.3335 9.33313H0.666738C0.486061 9.33313 0.329793 9.39921 0.197861 9.53128C0.0659295 9.66307 0 9.81952 0 10.0001V14.6668C0 14.8472 0.0659295 15.0037 0.197898 15.1355C0.329939 15.2673 0.486207 15.3336 0.666775 15.3336C0.847305 15.3336 1.0035 15.2673 1.13551 15.1355L2.47923 13.7917C3.22234 14.4999 4.06789 15.0451 5.01582 15.4271C5.96376 15.8091 6.94467 15.9999 7.9585 15.9999C9.86141 15.9999 11.5211 15.4218 12.9377 14.2656C14.3544 13.1095 15.285 11.6006 15.7296 9.73958C15.7366 9.72572 15.7399 9.70138 15.7399 9.66679C15.7399 9.57638 15.7068 9.49852 15.6408 9.4323C15.5748 9.36666 15.4966 9.33357 15.4062 9.33357Z" />
  </Icon>
);

Refresh.displayName = 'Refresh';

export default Refresh;
