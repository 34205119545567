import styled from '@emotion/styled';

import { VStack, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import { colors, fonts } from 'ms-pages/Lantern/styles';
import { homeUrl } from 'ms-pages/Lantern/utils/urls';

import notFound from './not_found.svg';

const ImageWrapper = styled.div({
  maxWidth: 560,
});

export default function NotFound() {
  return (
    <VStack
      css={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ImageWrapper>
        <img src={notFound} alt="Not Found" role="presentation" />
      </ImageWrapper>
      <VSpacer height={72} />
      <HeadingL>We couldn't find what you're looking for</HeadingL>
      <VSpacer height={28} />
      <a
        css={{
          color: colors.peachPink,
          ...fonts.bodyBold,
          fontSize: 20,
          textDecoration: 'none',
        }}
        href={homeUrl}
      >
        Take me home
      </a>
      <VSpacer height={72} />
    </VStack>
  );
}
