import { css } from 'aphrodite';
import React, { useEffect } from 'react';
import { graphql } from 'react-relay';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import ArrowRight from 'ms-components/icons/ArrowRight';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { Disclaimer } from 'ms-pages/Signup/Sunflower/Disclaimer';
import ProgressStepper from 'ms-pages/Signup/Sunflower/ProgressStepper';
import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import { formStyles } from 'ms-pages/Signup/Sunflower/styles';
import useCreateTeacherForSunflower from 'ms-pages/Signup/Sunflower/useCreateTeacherForSunflower';
import Button from 'ms-ui-primitives/Button';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Input from 'ms-ui-primitives/Input';
import Popover from 'ms-ui-primitives/Popover';
import Separator from 'ms-ui-primitives/Separator';
import { HStack } from 'ms-ui-primitives/Stack';
import { unwrap } from 'ms-utils/typescript-utils';
import { dashboardRedirectUrl, signupSunflowerTeacherUrl } from 'ms-utils/urls';

import type {
  ClassCreatorAvailableSyllabiQuery,
  ClassCreatorAvailableSyllabiQueryResponse,
} from './__generated__/ClassCreatorAvailableSyllabiQuery.graphql';
import type { ClassCreatorAvailbeSyllabusBySubdivisionQuery } from './__generated__/ClassCreatorAvailbeSyllabusBySubdivisionQuery.graphql';

type Syllabuses = NonNullable<
  ClassCreatorAvailableSyllabiQueryResponse['country']
>['syllabuses'];
const sortSyllabuses = (syllabuses: Syllabuses | undefined) => {
  if (syllabuses) {
    const arrangedSyllabuses = syllabuses.map(syllabus => ({
      ...syllabus,
      order: unwrap(syllabus.curriculumLevels[0]).order,
    }));
    return arrangedSyllabuses.sort((a, b) => a.order - b.order);
  } else {
    return undefined;
  }
};
export default function ClassCreator() {
  const trackingIdPrefix = 'SignupPage/ClassCreator';
  let {
    state: { country, countrySubdivision },
  } = useSignupState();
  if (country == null) country = { id: 'AU', name: 'Australia' };
  if (countrySubdivision == null)
    return (
      <ClassCreatorCountryOnly
        country={country.id}
        trackingIdPrefix={trackingIdPrefix}
      />
    );
  return (
    <ClassCreatorSubdivision
      subdivision={countrySubdivision.id}
      trackingIdPrefix={trackingIdPrefix}
    />
  );
}
function ClassCreatorCountryOnly({
  country,
  trackingIdPrefix,
}: {
  country: string;
  trackingIdPrefix: string;
}) {
  const { props } = useQuery<ClassCreatorAvailableSyllabiQuery>(
    SYLLABUSES_QUERY_FOR_COUNTRY,
    { country },
  );
  return (
    <ClassCreatorPresentational
      availableSyllabuses={sortSyllabuses(props?.country?.syllabuses)}
      trackingIdPrefix={trackingIdPrefix}
    />
  );
}
function ClassCreatorSubdivision({
  subdivision,
  trackingIdPrefix,
}: {
  subdivision: string;
  trackingIdPrefix: string;
}) {
  const { props } = useQuery<ClassCreatorAvailbeSyllabusBySubdivisionQuery>(
    SYLLABUSES_QUERY_FOR_COUNTRY_SUBDIVISION,
    {
      subdivision,
    },
  );
  return (
    <ClassCreatorPresentational
      availableSyllabuses={sortSyllabuses(
        props?.countrySubdivision?.syllabuses,
      )}
      trackingIdPrefix={trackingIdPrefix}
    />
  );
}
function ClassCreatorPresentational({
  availableSyllabuses,
  trackingIdPrefix,
}: {
  availableSyllabuses:
    | ReadonlyArray<{
        id: string;
        title: string;
      }>
    | null
    | undefined;
  trackingIdPrefix: string;
}) {
  const { replace } = useHistory();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);
  const { setUserId, trackStructEvent } = useSnowplow();
  let {
    state: { syllabusFocus, className, hasErrors },
    dispatch,
  } = useSignupState();
  const [createTeacher, { response: teacherResponse }] =
    useCreateTeacherForSunflower();
  useEffect(() => {
    if (hasErrors) {
      replace(signupSunflowerTeacherUrl);
    }
  }, [hasErrors, replace]);
  useEffect(() => {
    const teacher = teacherResponse?.createTeacherForSunflower?.teacher;
    if (teacher != null) {
      if (teacher.user.snowplowId != null) {
        setUserId(teacher.user.snowplowId);
      }
      trackStructEvent({
        category: 'signup_sunflower',
        action: 'created_account',
        label: 'student',
      });
      window.location.href = dashboardRedirectUrl;
    }
  }, [replace, setUserId, teacherResponse, trackStructEvent]);
  if (availableSyllabuses == null) return null;
  return (
    <>
      <ProgressStepper step={3} />
      <div className={css(formStyles.container)}>
        <div className={css(formStyles.formContainer)}>
          <div className={css(formStyles.titleContainer)}>
            <div className={css(formStyles.title)}>Your class details</div>
          </div>
          <FieldGroup
            aphroditeStyles={[formStyles.formRowField]}
            title="Class name"
          >
            <Input
              value={className ?? ''}
              onChange={e => {
                // We don't allow messages over 128 characters
                if (e.target.value.length > 127) return;
                dispatch({ type: 'setClassName', value: e.target.value });
              }}
              placeholder="Class Name"
            />
          </FieldGroup>
          <div className={css(formStyles.dropdownMenuSearch)}>
            <FieldGroup
              aphroditeStyles={[formStyles.formRowField]}
              title="Syllabus Focus"
              infoTooltipText="This will be the default textbook your students will see. Your students will still have access to the other textbooks in this list."
            >
              <div ref={anchorRef}>
                <Input
                  value={syllabusFocus?.title ?? ''}
                  placeholder="Select a syllabus"
                  onFocus={() => setIsOpen(true)}
                />
              </div>
            </FieldGroup>
            {isOpen && (
              <Popover
                anchorElementRef={anchorRef}
                onDismiss={() => setIsOpen(false)}
                shouldDismissOnTapOut
                shouldDismissOnScroll={false}
                anchorOrigin="left"
                popoverPosition="bottom-right"
                vOffset={0}
              >
                <div className={css(formStyles.dropdownMenu)}>
                  {availableSyllabuses.map(syllabus => (
                    <div
                      className={css(formStyles.dropdownMenuItem)}
                      key={syllabus.id}
                      onClick={() => {
                        dispatch({ type: 'setSyllabusFocus', value: syllabus });
                        setIsOpen(false);
                      }}
                    >
                      <div>{syllabus.title}</div>
                    </div>
                  ))}
                </div>
              </Popover>
            )}
          </div>
          <div className={css(formStyles.actionWrapper)}>
            <Button
              size="large"
              type="primary"
              isRound
              isDisabled={
                syllabusFocus == null || className == null || className === ''
              }
              onClick={() => createTeacher({})}
              label="Continue"
              trackingId={`${trackingIdPrefix}/Continue`}
            >
              <HStack center>
                Create Account
                <ArrowRight />
              </HStack>
            </Button>
          </div>
          <Separator size={8} />
          <Disclaimer trackingIdPrefix={`${trackingIdPrefix}/Disclaimer`} />
        </div>
      </div>
    </>
  );
}
const SYLLABUSES_QUERY_FOR_COUNTRY = graphql`
  query ClassCreatorAvailableSyllabiQuery($country: ID!) {
    country(id: $country) {
      syllabuses {
        id
        title
        curriculumLevels {
          order
        }
      }
    }
  }
`;
const SYLLABUSES_QUERY_FOR_COUNTRY_SUBDIVISION = graphql`
  query ClassCreatorAvailbeSyllabusBySubdivisionQuery($subdivision: ID!) {
    countrySubdivision(id: $subdivision) {
      syllabuses {
        id
        title
        curriculumLevels {
          order
        }
      }
    }
  }
`;
