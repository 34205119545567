/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type editTaskTemplateMutationVariables = {
    templateId: string;
    title: string;
    description: string;
    problemContentIds: Array<string>;
    taskTemplateGroupId: string;
};
export type editTaskTemplateMutationResponse = {
    readonly editTaskTemplateDetails: {
        readonly errors: ReadonlyArray<{
            readonly message: string;
        }>;
        readonly taskTemplate: {
            readonly id: string;
        } | null;
    };
};
export type editTaskTemplateMutation = {
    readonly response: editTaskTemplateMutationResponse;
    readonly variables: editTaskTemplateMutationVariables;
};



/*
mutation editTaskTemplateMutation(
  $templateId: ID!
  $title: String!
  $description: String!
  $problemContentIds: [ID!]!
  $taskTemplateGroupId: ID!
) {
  editTaskTemplateDetails(taskTemplateId: $templateId, title: $title, description: $description, problemContentIds: $problemContentIds, taskTemplateGroupId: $taskTemplateGroupId) {
    errors {
      message
    }
    taskTemplate {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "problemContentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskTemplateGroupId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "problemContentIds",
        "variableName": "problemContentIds"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateGroupId",
        "variableName": "taskTemplateGroupId"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateId",
        "variableName": "templateId"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "ManageTaskTemplateResult",
    "kind": "LinkedField",
    "name": "editTaskTemplateDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplate",
        "kind": "LinkedField",
        "name": "taskTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editTaskTemplateMutation",
    "selections": (v5/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editTaskTemplateMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "a97f6d1135e1f0f7dc1cda0c6cf87e95",
    "id": null,
    "metadata": {},
    "name": "editTaskTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation editTaskTemplateMutation(\n  $templateId: ID!\n  $title: String!\n  $description: String!\n  $problemContentIds: [ID!]!\n  $taskTemplateGroupId: ID!\n) {\n  editTaskTemplateDetails(taskTemplateId: $templateId, title: $title, description: $description, problemContentIds: $problemContentIds, taskTemplateGroupId: $taskTemplateGroupId) {\n    errors {\n      message\n    }\n    taskTemplate {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '29ad7440485cedcb75cb82d8e580d23d';
export default node;
