import styled from '@emotion/styled';

import { fonts } from 'ms-pages/Lantern/styles';
import { colors } from 'ms-styles/colors';

import type { TextColors } from './types';

const HeadingL = styled('div', { shouldForwardProp: p => p !== 'color' })<{
  color?: TextColors;
}>(({ color = 'grey' }) => ({
  color: colors[color],
  ...fonts.featureBold,
  fontSize: 32,
  lineHeight: '38px',
}));

const HeadingM = styled('div', { shouldForwardProp: p => p !== 'color' })<{
  color?: TextColors;
}>(({ color = 'grey' }) => ({
  color: colors[color],
  ...fonts.featureBold,
  fontSize: 28,
  lineHeight: '32px',
}));

const HeadingS = styled('div', { shouldForwardProp: p => p !== 'color' })<{
  color?: TextColors;
}>(({ color = 'grey' }) => ({
  color: colors[color],
  ...fonts.featureBold,
  fontSize: 20,
  lineHeight: '24px',
}));

const HeadingSmall = styled.div<{ color?: TextColors }>(
  ({ color = 'grey' }) => ({
    color: colors[color],
    ...fonts.featureBold,
    fontSize: 18,
    lineHeight: '22px',
  }),
);

const HeadingXSGilroy = styled.div<{ color?: TextColors }>(
  ({ color = 'grey' }) => ({
    color: colors[color],
    ...fonts.featureBold,
    fontSize: 16,
    lineHeight: '20px',
  }),
);

const HeadingXS = styled.div<{
  color?: TextColors;
  bold?: boolean;
  isInline?: boolean;
}>(({ color = 'grey', bold = false, isInline = false }) => ({
  ...fonts.bodyBold,
  ...(bold ? { fontWeight: 800 } : {}),
  color: colors[color],
  fontSize: 16,
  lineHeight: isInline ? '20px' : '24px',
}));

const HeadingXXS = styled.div<{
  color?: TextColors;
  bold?: boolean;
}>(({ color = 'grey', bold = true }) => ({
  color: colors[color],
  ...(bold ? fonts.bodyBold : fonts.body),
  fontSize: 14,
  lineHeight: '18px',
}));

export {
  HeadingL,
  HeadingM,
  HeadingS,
  HeadingSmall,
  HeadingXS,
  HeadingXXS,
  HeadingXSGilroy,
};
