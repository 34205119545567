import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="m20 13.4h2.5v7l5.9 3.5-1.3 2-7.1-4.3v-8.2z m1.6-8.4c8.3 0 15 6.7 15 15s-6.7 15-15 15c-4.1 0-7.8-1.6-10.5-4.4l2.3-2.4c2.1 2.1 5 3.4 8.2 3.4 6.5 0 11.8-5.1 11.8-11.6s-5.3-11.6-11.8-11.6-11.6 5.1-11.6 11.6h5l-6.7 6.7-0.2-0.2-6.5-6.5h5c0-8.3 6.8-15 15-15z" />
);

const Restore = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={40}
    viewBoxWidth={40}
  >
    {path}
  </Icon>
);

Restore.displayName = 'Restore';

export default Restore;
