/*
 * Exports a series of functions for mutating a MathQuill instance in order to
 * insert a latex expression. The primary use case is when the user presses an
 * on-screen button in order to insert a math expression (as opposed to
 * manually typing in the latex by hand). Importantly, in order to achieve a
 * statisfactory user experience, we often need to manually control MathQuill's
 * synthetic cursor position using private internals of the MathQuill library.
 *
 * There are 4 broad categories of mutations that occur.
 *
 * 1. Inserting a latex string into the input.
 *
 * 2. Inserting a latex string into the input and manually placing MathQuill's
 *    synthetic cursor somewhere in the inserted latex.
 *
 * 3. Simulating the user typing characters into the input.
 *
 * 4. Executing a MathQuill "command"
 */
import type { IMathField } from 'mathquill';

// Produces the controller for the currently active node in the MathQuill input.
// The controller can be used to manually control the position of MathQuill's
// synthetic cursor.
const activeController = (field: IMathField) =>
  // @ts-expect-error - __controller is not part of the public API
  field.__controller.API.getActiveNode().__controller;

type Inserter = (mathQuillField: IMathField) => void;

export const insertAdd: Inserter = field => {
  field.write('+');
};

export const insertSubtract: Inserter = field => {
  field.write('-');
};

export const insertMultiply: Inserter = field => {
  if (field.__options.multiplicationDisplaySymbol === 'dot') {
    field.write('\\cdot');
  } else {
    field.write('\\times');
  }
};

export const insertDivide: Inserter = field => {
  field.write('\\divide');
};

export const insertEquals: Inserter = field => {
  field.write('=');
};

export const insertNotEquals: Inserter = field => {
  field.write('\\ne');
};

export const insertLessThan: Inserter = field => {
  field.write('<');
};

export const insertGreaterThan: Inserter = field => {
  field.write('>');
};

export const insertLessThanOrEqual: Inserter = field => {
  field.write('\\leq');
};

export const insertGreaterThanOrEqual: Inserter = field => {
  field.write('\\geq');
};

export const insertOpenParenthesis: Inserter = field => {
  field.typedText('(');
};

export const insertCloseParenthesis: Inserter = field => {
  field.typedText(')');
};

export const insertOpenBrace: Inserter = field => {
  field.typedText('{');
};

export const insertCloseBrace: Inserter = field => {
  field.typedText('}');
};

export const insertAbsoluteValueVerticalBar: Inserter = field => {
  field.typedText('|');
};

export const insertExponent: Inserter = field => {
  field.typedText('^');
};

export const insertSubscript: Inserter = field => {
  field.typedText('_');
};

export const insertFraction: Inserter = field => {
  field.typedText('/');
};

export const insertMixedFraction: Inserter = field => {
  field.write('\\frac{}{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
  controller.moveLeft();
};

export const insertSquareRoot: Inserter = field => {
  field.write('\\sqrt{}{}');
  const controller = activeController(field);
  controller.moveLeft();
};

export const insertNthRoot: Inserter = field => {
  field.write('\\sqrt[]{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
};

export const insertSine: Inserter = field => {
  field.write('\\sin');
};

export const insertCosine: Inserter = field => {
  field.write('\\cos');
};

export const insertTangent: Inserter = field => {
  field.write('\\tan');
};

export const insertInverseSine: Inserter = field => {
  field.write('\\sin ^{-1}');
};

export const insertInverseCosine: Inserter = field => {
  field.write('\\cos ^{-1}');
};

export const insertInverseTangent: Inserter = field => {
  field.write('\\tan ^{-1}');
};

export const insertLogarithmBase10: Inserter = field => {
  field.write('\\log');
};

// A logarithm with an arbitrary base B
export const insertLogarithmBaseB: Inserter = field => {
  field.write('\\log_{ }\\left(\\right)');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
  controller.moveLeft();
};

export const insertNaturalLogarithm: Inserter = field => {
  field.write('\\ln');
};

export const insertLowercasePi: Inserter = field => {
  field.write('\\pi');
};

export const insertLowercaseAlpha: Inserter = field => {
  field.write('\\alpha');
};

export const insertLowercaseBeta: Inserter = field => {
  field.write('\\beta');
};

export const insertLowercaseGamma: Inserter = field => {
  field.write('\\gamma');
};

export const insertLowercaseDelta: Inserter = field => {
  field.write('\\delta');
};

export const insertLowercaseTheta: Inserter = field => {
  field.write('\\theta');
};

export const insertLowercaseOmega: Inserter = field => {
  field.write('\\omega');
};

export const insertSummation: Inserter = field => {
  field.write('\\sum');
};

export const insertSimilarEqual: Inserter = field => {
  field.write('\\simeq');
};

export const insertSimilar: Inserter = field => {
  field.write('\\sim');
};

export const insertApproximately: Inserter = field => {
  field.write('\\approx');
};

export const insertEquivalent: Inserter = field => {
  field.write('\\equiv');
};

export const insertCongruent: Inserter = field => {
  field.write('\\cong');
};

export const insertIsCommon: Inserter = field => {
  field.typedText('iscommon');
};

export const insertParallel: Inserter = field => {
  field.write('\\parallel');
};

export const insertPerpendicular: Inserter = field => {
  field.write('\\perpendicular');
};

export const insertDegrees: Inserter = field => {
  field.write('\\deg');
};

export const insertMinutes: Inserter = field => {
  field.write(`'`);
};

export const insertSeconds: Inserter = field => {
  field.write('"');
};

export const insertTriangle: Inserter = field => {
  field.write('\\triangle');
};

export const insertAngle: Inserter = field => {
  field.write('\\angle');
};

export const insertPlusMinus: Inserter = field => {
  field.write('\\pm');
};

export const insertBinomialCoefficient: Inserter = field => {
  field.write('\\binom{}{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
};

export const insertNCrBinomialCoefficient: Inserter = field => {
  field.write('\\nCr{}{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
};

export const insertFactorial: Inserter = field => {
  field.write('!');
};

export const insertRecurringDecimal: Inserter = field => {
  field.cmd('\\overline');
};

export const insertNPrPermutation: Inserter = field => {
  field.write('\\nPr{}{}');
};

export const insertUnion: Inserter = field => {
  field.write('\\cup');
};

export const insertIntersection: Inserter = field => {
  field.write('\\cap');
};

export const insertIndefiniteIntegral: Inserter = field => {
  field.write('\\int {}');
};

export const insertDefiniteIntegral: Inserter = field => {
  field.write('\\int_{}^{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
};

export const insertDefiniteIntegralEvaluationBrackets: Inserter = field => {
  field.write('\\left[ {}\\right]_{}^{}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
  controller.moveLeft();
  controller.moveLeft();
};

export const insertDifferentialOperatorLeibnizNotation: Inserter = field => {
  field.write('\\frac{d}{d{}}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
  controller.moveLeft();
};

export const insertDerivativeOfFLagrangeNotation: Inserter = field => {
  field.write(`f'\\left({}\\right)`);
  const controller = activeController(field);
  controller.moveLeft();
};

export const insertLimit: Inserter = field => {
  field.write('\\lim_{{}\\to {}} {}');
  const controller = activeController(field);
  controller.moveLeft();
  controller.moveLeft();
};

export const insertInfinity: Inserter = field => {
  field.write('\\infty');
};
