import { borderRadiusUILarge, breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT, boxShadow } from 'ms-styles/theme/Numero';
import { hexStringToRgbaString } from 'ms-utils/colors';

export const BACKGROUND_COLOR = colors.porcelain;

/**
 * Default spacing between elements
 */
export const GUTTER = 2 * BASE_UNIT;

/**
 * The default padding for the main content
 */
export const CONTENT_GUTTER = 5 * BASE_UNIT;
export const CONTENT_GUTTER_EXTRA_LARGE = 10 * BASE_UNIT;

export const contentPaddingBase = {
  paddingLeft: CONTENT_GUTTER,
  paddingRight: CONTENT_GUTTER,
};

export const contentPaddingExtraLarge = {
  paddingLeft: CONTENT_GUTTER_EXTRA_LARGE,
  paddingRight: CONTENT_GUTTER_EXTRA_LARGE,
};

export const contentPadding = {
  ...contentPaddingBase,
  [`@media (min-width: ${breakPoints.extraLarge}px)` as const]: {
    ...contentPaddingExtraLarge,
  },
};

/**
 * The problem and subproblem numbers are shown in circles
 * on the left of the problem and subproblem content.
 */
export const HEADER_NUMBER_SIZE = 11 * BASE_UNIT;

/**
 * The left column used for the problem and subproblem numbers
 * and to indicate the progress between the subproblems.
 */
export const LEFT_COLUMN_WIDTH = HEADER_NUMBER_SIZE;
export const leftColumn = {
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  flexDirection: 'column',
  alignItems: 'center',
  width: LEFT_COLUMN_WIDTH,
  marginRight: GUTTER,
} as const;

/**
 * This style is being used to expand the wrapping container of
 * answer input types in AnswerInput and WorkoutHistory and allow
 * them to expand to the full width of their parent containers.
 */
export const answerWrapper = {
  flexGrow: 1,
  minWidth: 200, // to fit the submit issue dropdown
};

/**
 * This style is being used to wrap the work event icon
 * for the answer events and the current answer input.
 */
export const workEventIconWrapper = {
  width: 9 * BASE_UNIT,
  paddingTop: BASE_UNIT,
  flexShrink: 0,
};

/**
 * To add some white space at the bottom of the workbook
 * between the content and the control panel.
 */
export const BOTTOM_WHITE_SPACE_HEIGHT_MOBILE = 100;
export const BOTTOM_WHITE_SPACE_HEIGHT = 80;

/**
 * There is 5px vertical margin on MathContent's paragraphs
 * which affects the spacing of some components that render MathContent.
 */
export const MATH_CONTENT_MARGIN = 5;
export const MATH_CONTENT_LINE_HEIGHT = 1.5;

/**
 * There is a 30px horizontal padding between the help panel and the action
 * buttons in the workbook chat.
 */
export const HELP_PANEL_SPACER_OFFSET = 30;

/**
 * This style is being used for to wrap and position the subproblem actions
 * and problem completion banner at the bottom of the workbook.
 */
export const BOTTOM_PANEL_BACKGROUND_OPACITY = 0.9;
export const BOTTOM_PANEL_BORDER_WIDTH = 1;
export const BOTTOM_PANEL_PADDING_VERTICAL = GUTTER;
export const BOTTOM_PANEL_PADDING_VERTICAL_EXTRA_LARGE = 5 * BASE_UNIT;
export const bottomPanel = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  ...contentPadding,
  paddingTop: BOTTOM_PANEL_PADDING_VERTICAL - BOTTOM_PANEL_BORDER_WIDTH,
  paddingBottom: BOTTOM_PANEL_PADDING_VERTICAL,
  backgroundColor: hexStringToRgbaString(
    BACKGROUND_COLOR,
    BOTTOM_PANEL_BACKGROUND_OPACITY,
  ),
  borderTop: `${BOTTOM_PANEL_BORDER_WIDTH}px solid ${colors.ironLight}`,

  [`@media (min-width: ${breakPoints.extraLarge}px)` as const]: {
    ...contentPadding[`@media (min-width: ${breakPoints.extraLarge}px)`],
    paddingTop:
      BOTTOM_PANEL_PADDING_VERTICAL_EXTRA_LARGE - BOTTOM_PANEL_BORDER_WIDTH,
    paddingBottom: BOTTOM_PANEL_PADDING_VERTICAL_EXTRA_LARGE,
  },
};

export const bottomPanelStyles = {
  backgroundColor: colors.white,
  [`@media (min-width: ${breakPoints.extraLarge}px)` as const]: {
    padding: '16px 25px 16px 24px',
  },
};

export const bottomPanelChatWorkbookStyles = {
  // We assign the same background color with the workbook background color
  // It makes it look like the control panel is transparent
  // But in reality it prevents workbook scrolling elements overlapping with the control panel
  // when they go under the control panel
  backgroundColor: BACKGROUND_COLOR,
  borderTop: 'none',
  marginBottom: 8,
  [`@media (min-width: ${breakPoints.extraLarge}px)` as const]: {
    marginBottom: 0,
  },
};

export const actionSeparatorStyles = {
  height: '26px',
  width: 2,
  backgroundColor: colors.ironLight,
  marginLeft: 9,
};

export const multiActionContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
};

export const viewSolutionArrowWrapperStyles = {
  width: 44,
  height: 48,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto', // to push it to the right
};
export const viewSolutionButtonWrapperStyles = {
  position: 'absolute',
  top: -52,
  left: 43,
  backgroundColor: colors.white,
  borderRadius: borderRadiusUILarge,
  width: 165,
  filter: `drop-shadow(${boxShadow.tiny})`,
};

export const pointerContainerStyles = {
  position: 'absolute',
  right: 11,
  bottom: -13,
};
