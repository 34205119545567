import { useMemo } from 'react';
import { graphql, useFragment, useQuery } from 'relay-hooks';

import { PageTimeErrorThrower } from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { useStudentContext } from 'ms-pages/Lantern/views/Student';
import { InvariantViolation } from 'ms-utils/app-logging';
import { getStartOfWeek } from 'ms-utils/time';

import SubstrandProficiencyIndicatorWithLearningFocus from './SubstrandProficiencyIndicatorWithLearningFocus';
import type { StudentSkillsMapCurriculumStrandSubstrandsUserStatusesQuery } from './__generated__/StudentSkillsMapCurriculumStrandSubstrandsUserStatusesQuery.graphql';
import type { StudentSkillsMapCurriculumStrandSubstrands_strand$key } from './__generated__/StudentSkillsMapCurriculumStrandSubstrands_strand.graphql';
import type { StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus$key } from './__generated__/StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus.graphql';
import { Grid, GridItem, GridItemTitle } from '../styles';
import {
  getGradeSubstrands,
  getGrowthStartDate,
  isStrandLocked as getIsStrandLocked,
} from '../utils';

type Props = {
  studentStrandStatus: StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus$key;
  strand: StudentSkillsMapCurriculumStrandSubstrands_strand$key;
};
export const PROFICIENCY_INDICATOR_SIZE = 88;
const STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_STUDENT_STRAND_STATUS_FRAGMENT = graphql`
  fragment StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus on StudentStrandStatus {
    status
    lastWorkedOnDiagnosticCheckIn {
      endedAt
      hasFinishedDiagnosticPhase
    }
  }
`;
const STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_STRAND_FRAGMENT = graphql`
  fragment StudentSkillsMapCurriculumStrandSubstrands_strand on Strand {
    id
    title
    substrands {
      id
      title
      badgeUrl
      gradeSubstrands {
        id
        gradeStrand {
          grade {
            id
            order
          }
        }
      }
    }
  }
`;
const STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_QUERY = graphql`
  query StudentSkillsMapCurriculumStrandSubstrandsUserStatusesQuery(
    $filters: [UserStatusFilterInput!]!
    $timestamp: DateTime
    $isStrandLocked: Boolean!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) @skip(if: $isStrandLocked) {
            progress
            trueProficiency
            userStatusFilter {
              curriculumNodeIds
            }
          }
          previousStatuses: userStatuses(
            filters: $filters
            timestamp: $timestamp
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) @skip(if: $isStrandLocked) {
            trueProficiency
          }
        }
      }
    }
  }
`;
function StudentSkillsMapCurriculumStrandSubstrands({
  studentStrandStatus: studentStrandStatusRef,
  strand: strandRef,
}: Props) {
  const { selfReportedGradeOrder } = useStudentContext();
  const studentStrandStatus = useFragment(
    STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_STUDENT_STRAND_STATUS_FRAGMENT,
    studentStrandStatusRef,
  );
  const diagnosticCheckInCompleteTimestamp =
    studentStrandStatus.lastWorkedOnDiagnosticCheckIn?.endedAt;
  const startOfWeek = useMemo(getStartOfWeek, []);
  const previousStatusTimestamp = getGrowthStartDate({
    startOfWeek,
    checkInCompletedAt: diagnosticCheckInCompleteTimestamp,
  });
  const {
    learningFocus,
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();
  const strand = useFragment(
    STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_STRAND_FRAGMENT,
    strandRef,
  );
  const substrands = strand.substrands.filter(
    substrand =>
      getGradeSubstrands({
        substrand,
        grade: { order: selfReportedGradeOrder },
      }).length > 0,
  );

  const isStrandLocked = getIsStrandLocked(
    studentStrandStatus,
    enableOptInDiscoveryCheckIn,
  );

  const { props, error } =
    useQuery<StudentSkillsMapCurriculumStrandSubstrandsUserStatusesQuery>(
      STUDENT_SKILLS_MAP_CURRICULUM_STRAND_SUBSTRANDS_QUERY,
      {
        filters: substrands.map(substrand => ({
          curriculumNodeIds: getGradeSubstrands({
            substrand,
            grade: { order: selfReportedGradeOrder },
          }).map(gradeSubstrand => gradeSubstrand.id),
        })),
        timestamp: previousStatusTimestamp,
        isStrandLocked,
        previewingWithProblemData: false,
        growthPeriod: 120,
      },
    );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentSkillsMapSubstrand"
        componentName="StudentSkillsMapSubstrandSkills"
        error={error}
      />
    );
  }
  const viewer = props?.lantern?.viewer; // `lantern?` because props is an empty object if the query is skipped
  if (viewer != null && viewer.__typename !== 'LanternStudent') {
    throw new InvariantViolation('The logged in user must be a LanternStudent');
  }
  return (
    <>
      <VSpacer height={40} />
      <Grid>
        {substrands.map((substrand, index) => {
          const currentProficiency =
            viewer?.userStatuses?.[index]?.trueProficiency ?? 0;
          const pastProficiency =
            viewer?.previousStatuses?.[index]?.trueProficiency ?? 0;
          const isLearningFocus = substrand.id === learningFocus?.id;
          return (
            <GridItem key={substrand.id}>
              <SubstrandProficiencyIndicatorWithLearningFocus
                locked={isStrandLocked}
                currentProficiency={currentProficiency}
                pastProficiency={pastProficiency}
                size={PROFICIENCY_INDICATOR_SIZE}
                badgeUrl={substrand.badgeUrl}
                isLearningFocus={isLearningFocus}
                substrandId={substrand.id}
                strandId={strand.id}
                substrandTitle={substrand.title}
                currentLearningFocusTitle={learningFocus?.title ?? ''}
              />

              <VSpacer height={12} />
              <GridItemTitle>
                <BodyM color="shuttleGray">{substrand.title}</BodyM>
              </GridItemTitle>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
}
export default StudentSkillsMapCurriculumStrandSubstrands;
