/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SelfDirectedTaskRedirectorQueryVariables = {
    subtopicId: string;
};
export type SelfDirectedTaskRedirectorQueryResponse = {
    readonly subtopic: {
        readonly workoutCreationToken: string;
    } | null;
};
export type SelfDirectedTaskRedirectorQuery = {
    readonly response: SelfDirectedTaskRedirectorQueryResponse;
    readonly variables: SelfDirectedTaskRedirectorQueryVariables;
};



/*
query SelfDirectedTaskRedirectorQuery(
  $subtopicId: ID!
) {
  subtopic(id: $subtopicId) {
    workoutCreationToken
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subtopicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workoutCreationToken",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelfDirectedTaskRedirectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelfDirectedTaskRedirectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec3874a6c5155fe8e1cac6f682e6d098",
    "id": null,
    "metadata": {},
    "name": "SelfDirectedTaskRedirectorQuery",
    "operationKind": "query",
    "text": "query SelfDirectedTaskRedirectorQuery(\n  $subtopicId: ID!\n) {\n  subtopic(id: $subtopicId) {\n    workoutCreationToken\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '581da400261e74d2c237c8ff3231e16a';
export default node;
