/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PartiallyAssignedClassInput = {
    classId: string;
    studentIds: Array<string>;
};
export type AssignDiscoveryCheckInFlowMutationVariables = {
    assignedClassIds: Array<string>;
    strandId: string;
    assignedStudentIds: Array<string>;
    partiallyAssignedClasses: Array<PartiallyAssignedClassInput>;
    startDate: string;
    dueDate: string;
};
export type AssignDiscoveryCheckInFlowMutationResponse = {
    readonly createStrandDiagnosticCheckInTask: {
        readonly errors: ReadonlyArray<{
            readonly __typename: "InvalidClassError";
            readonly message: string;
        } | {
            readonly __typename: "InvalidStudentError";
            readonly message: string;
        } | {
            readonly __typename: "InvalidStrandError";
            readonly message: string;
        } | {
            readonly __typename: "PermissionError";
            readonly message: string;
        } | {
            readonly __typename: "NoAssigneesError";
            readonly message: string;
        } | {
            readonly __typename: "StrandDiagnosticAlreadyExistsError";
            readonly message: string;
        } | {
            readonly __typename: "DueDateInThePastError";
            readonly message: string;
        } | {
            readonly __typename: "DueDateBeforeStartDateError";
            readonly message: string;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }>;
    };
};
export type AssignDiscoveryCheckInFlowMutation = {
    readonly response: AssignDiscoveryCheckInFlowMutationResponse;
    readonly variables: AssignDiscoveryCheckInFlowMutationVariables;
};



/*
mutation AssignDiscoveryCheckInFlowMutation(
  $assignedClassIds: [ID!]!
  $strandId: ID!
  $assignedStudentIds: [ID!]!
  $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
  $startDate: DateTime!
  $dueDate: DateTime!
) {
  createStrandDiagnosticCheckInTask(strandId: $strandId, assignedClassIds: $assignedClassIds, assignedStudentIds: $assignedStudentIds, partiallyAssignedClasses: $partiallyAssignedClasses, startDate: $startDate, dueDate: $dueDate) {
    errors {
      __typename
      ... on InvalidClassError {
        message
      }
      ... on InvalidStudentError {
        message
      }
      ... on InvalidStrandError {
        message
      }
      ... on PermissionError {
        message
      }
      ... on NoAssigneesError {
        message
      }
      ... on StrandDiagnosticAlreadyExistsError {
        message
      }
      ... on DueDateInThePastError {
        message
      }
      ... on DueDateBeforeStartDateError {
        message
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignedClassIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignedStudentIds"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dueDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partiallyAssignedClasses"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "strandId"
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "assignedClassIds",
        "variableName": "assignedClassIds"
      },
      {
        "kind": "Variable",
        "name": "assignedStudentIds",
        "variableName": "assignedStudentIds"
      },
      {
        "kind": "Variable",
        "name": "dueDate",
        "variableName": "dueDate"
      },
      {
        "kind": "Variable",
        "name": "partiallyAssignedClasses",
        "variableName": "partiallyAssignedClasses"
      },
      {
        "kind": "Variable",
        "name": "startDate",
        "variableName": "startDate"
      },
      {
        "kind": "Variable",
        "name": "strandId",
        "variableName": "strandId"
      }
    ],
    "concreteType": "CreateCheckInTaskResult",
    "kind": "LinkedField",
    "name": "createStrandDiagnosticCheckInTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "InvalidClassError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "InvalidStudentError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "InvalidStrandError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "PermissionError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "NoAssigneesError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "StrandDiagnosticAlreadyExistsError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "DueDateInThePastError",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v6/*: any*/),
            "type": "DueDateBeforeStartDateError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignDiscoveryCheckInFlowMutation",
    "selections": (v7/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignDiscoveryCheckInFlowMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "494aed25c58145602ca0e21636b6bdde",
    "id": null,
    "metadata": {},
    "name": "AssignDiscoveryCheckInFlowMutation",
    "operationKind": "mutation",
    "text": "mutation AssignDiscoveryCheckInFlowMutation(\n  $assignedClassIds: [ID!]!\n  $strandId: ID!\n  $assignedStudentIds: [ID!]!\n  $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!\n  $startDate: DateTime!\n  $dueDate: DateTime!\n) {\n  createStrandDiagnosticCheckInTask(strandId: $strandId, assignedClassIds: $assignedClassIds, assignedStudentIds: $assignedStudentIds, partiallyAssignedClasses: $partiallyAssignedClasses, startDate: $startDate, dueDate: $dueDate) {\n    errors {\n      __typename\n      ... on InvalidClassError {\n        message\n      }\n      ... on InvalidStudentError {\n        message\n      }\n      ... on InvalidStrandError {\n        message\n      }\n      ... on PermissionError {\n        message\n      }\n      ... on NoAssigneesError {\n        message\n      }\n      ... on StrandDiagnosticAlreadyExistsError {\n        message\n      }\n      ... on DueDateInThePastError {\n        message\n      }\n      ... on DueDateBeforeStartDateError {\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0e5f9653cffc4a36dc13b97a20351172';
export default node;
