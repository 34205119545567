import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CaretTopAndBottom = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={9}
    viewBoxHeight={13}
  >
    <path d="m8.863 8.056-3.699 4.645c-.247.31-.745.392-1.112.183a.751.751 0 0 1-.216-.183l-3.7-4.645c-.246-.31-.15-.731.217-.94A.907.907 0 0 1 .801 7h7.398c.443 0 .801.303.801.678a.607.607 0 0 1-.137.378ZM.137 4.944 3.836.299c.247-.31.745-.392 1.112-.183.085.049.158.11.216.183l3.7 4.645c.246.31.15.731-.217.94A.907.907 0 0 1 8.199 6H.801C.358 6 0 5.697 0 5.322c0-.135.048-.267.137-.378Z" />
  </Icon>
);

CaretTopAndBottom.displayName = 'CaretTopAndBottom';

export default CaretTopAndBottom;
