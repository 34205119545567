import { Logger } from 'ms-utils/app-logging';

type Getter = (key: string) => any;
type Setter = (key: string, value: any) => void;
type Deleter = (key: string) => void;
export type Db = { get: Getter; set: Setter; delete: Deleter };
type Storage = typeof localStorage | typeof sessionStorage;

const makeGet =
  (storage: Storage): Getter =>
  key => {
    try {
      const value = JSON.parse(storage.getItem(key) || 'null');
      return value;
    } catch (err) {
      return null;
    }
  };

const makeSet =
  (storage: Storage): Setter =>
  (key, value) => {
    try {
      storage.setItem(key, JSON.stringify(value));
    } catch (err) {
      Logger.error('localStorageDb: Impossible to set value', {
        extra: { key, value, error: err },
      });
    }
  };

const makeDelete =
  (storage: Storage): Deleter =>
  key => {
    try {
      storage.removeItem(key);
    } catch (err) {
      Logger.error('localStorageDb: Impossible to delete value', {
        extra: {
          key,
          error: err,
        },
      });
    }
  };

const makeDb = (storage: Storage): Db => ({
  get: makeGet(storage),
  set: makeSet(storage),
  delete: makeDelete(storage),
});

export default makeDb(localStorage);
export const sessionStorageDb = makeDb(sessionStorage);

/**
 * Check if the Web Storage API is supported and available.
 *
 * @returns {boolean} True if both supported and available, false if not.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API}
 */
export function isLocalStorageAvailable() {
  try {
    const storage = window.localStorage;
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
