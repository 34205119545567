import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M8.5842002,10.0349741 L6.14034967,12.4788246 C5.74982537,12.8693489 5.74982537,13.5025139 6.14034967,13.8930382 C6.53087396,14.2835624 7.16403894,14.2835624 7.55456323,13.8930382 L9.99841376,11.4491876 L12.4422643,13.8930382 C12.8327886,14.2835624 13.4659536,14.2835624 13.8564779,13.8930382 C14.2470022,13.5025139 14.2470022,12.8693489 13.8564779,12.4788246 L11.4126273,10.0349741 L13.8564779,7.59112352 C14.2470022,7.20059923 14.2470022,6.56743425 13.8564779,6.17690996 C13.4659536,5.78638567 12.8327886,5.78638567 12.4422643,6.17690996 L9.99841376,8.6207605 L7.55456323,6.17690996 C7.16403894,5.78638567 6.53087396,5.78638567 6.14034967,6.17690996 C5.74982537,6.56743425 5.74982537,7.20059923 6.14034967,7.59112352 L8.5842002,10.0349741 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z" />
);

const CrossFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CrossFilled.displayName = 'CrossFilled';

export default CrossFilled;
