/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type HighlightingNoteEditorQueryVariables = {
    subtopicId: string;
    locale?: SyllabusLocale | null | undefined;
    isWorksheet: boolean;
    isOverview: boolean;
    isSolidify: boolean;
    isRsl: boolean;
    isInvestigation: boolean;
};
export type HighlightingNoteEditorQueryResponse = {
    readonly subtopic: {
        readonly worksheetJsx?: {
            readonly transpiledJsx: string | null;
        } | undefined;
        readonly solidifyLessonJsx?: {
            readonly transpiledJsx: string | null;
        } | undefined;
        readonly studentOverviewJsx?: {
            readonly transpiledJsx: string | null;
        } | undefined;
        readonly rslJsx?: {
            readonly transpiledJsx: string | null;
        } | undefined;
        readonly investigationJsx?: {
            readonly transpiledJsx: string | null;
        } | undefined;
    } | null;
};
export type HighlightingNoteEditorQuery = {
    readonly response: HighlightingNoteEditorQueryResponse;
    readonly variables: HighlightingNoteEditorQueryVariables;
};



/*
query HighlightingNoteEditorQuery(
  $subtopicId: ID!
  $locale: SyllabusLocale
  $isWorksheet: Boolean!
  $isOverview: Boolean!
  $isSolidify: Boolean!
  $isRsl: Boolean!
  $isInvestigation: Boolean!
) {
  subtopic(id: $subtopicId, syllabusLocale: $locale) {
    worksheetJsx @include(if: $isWorksheet) {
      transpiledJsx
      id
    }
    solidifyLessonJsx @include(if: $isSolidify) {
      transpiledJsx
      id
    }
    studentOverviewJsx @include(if: $isOverview) {
      transpiledJsx
      id
    }
    rslJsx @include(if: $isRsl) {
      transpiledJsx
      id
    }
    investigationJsx @include(if: $isInvestigation) {
      transpiledJsx
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isInvestigation"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isOverview"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRsl"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSolidify"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isWorksheet"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subtopicId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  },
  {
    "kind": "Variable",
    "name": "syllabusLocale",
    "variableName": "locale"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v8/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HighlightingNoteEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          {
            "condition": "isWorksheet",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Worksheet",
                "kind": "LinkedField",
                "name": "worksheetJsx",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isSolidify",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SolidifyLesson",
                "kind": "LinkedField",
                "name": "solidifyLessonJsx",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isOverview",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentOverview",
                "kind": "LinkedField",
                "name": "studentOverviewJsx",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isRsl",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetrofittedStudentLesson",
                "kind": "LinkedField",
                "name": "rslJsx",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isInvestigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Investigation",
                "kind": "LinkedField",
                "name": "investigationJsx",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HighlightingNoteEditorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          {
            "condition": "isWorksheet",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Worksheet",
                "kind": "LinkedField",
                "name": "worksheetJsx",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isSolidify",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SolidifyLesson",
                "kind": "LinkedField",
                "name": "solidifyLessonJsx",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isOverview",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StudentOverview",
                "kind": "LinkedField",
                "name": "studentOverviewJsx",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isRsl",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RetrofittedStudentLesson",
                "kind": "LinkedField",
                "name": "rslJsx",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ]
          },
          {
            "condition": "isInvestigation",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Investigation",
                "kind": "LinkedField",
                "name": "investigationJsx",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c65f2a6050c89e1039165486e1a2d87a",
    "id": null,
    "metadata": {},
    "name": "HighlightingNoteEditorQuery",
    "operationKind": "query",
    "text": "query HighlightingNoteEditorQuery(\n  $subtopicId: ID!\n  $locale: SyllabusLocale\n  $isWorksheet: Boolean!\n  $isOverview: Boolean!\n  $isSolidify: Boolean!\n  $isRsl: Boolean!\n  $isInvestigation: Boolean!\n) {\n  subtopic(id: $subtopicId, syllabusLocale: $locale) {\n    worksheetJsx @include(if: $isWorksheet) {\n      transpiledJsx\n      id\n    }\n    solidifyLessonJsx @include(if: $isSolidify) {\n      transpiledJsx\n      id\n    }\n    studentOverviewJsx @include(if: $isOverview) {\n      transpiledJsx\n      id\n    }\n    rslJsx @include(if: $isRsl) {\n      transpiledJsx\n      id\n    }\n    investigationJsx @include(if: $isInvestigation) {\n      transpiledJsx\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '09e8e7a3957bdd6d74a4eae21912e9ce';
export default node;
