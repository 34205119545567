import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FolderDistrict = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={20}
    viewBoxWidth={20}
  >
    <path
      fillRule="evenodd"
      d="M10.101 3.543c.218.312.574.498.955.498h6.386c.678 0 1.33.27 1.81.749.478.48.748 1.13.748 1.809v9.651c0 .678-.27 1.33-.749 1.81-.48.478-1.131.748-1.81.748H2.559c-.678 0-1.33-.27-1.81-.749A2.563 2.563 0 0 1 0 16.25V3.808A2.558 2.558 0 0 1 2.558 1.25h4.61c.837 0 1.62.41 2.099 1.096l.834 1.197Zm3.941 12.107a.6.6 0 0 1-.6.6h-1.358a.6.6 0 0 1-.6-.6V9.819a.6.6 0 0 0-.6-.6H8.57a.6.6 0 0 1-.6-.6V6.85a.6.6 0 0 1 .6-.6h4.873a.6.6 0 0 1 .6.6v8.8Zm-.586-4.409a.293.293 0 0 0-.293-.293h-.8a.293.293 0 0 0 0 .586h.8a.293.293 0 0 0 .293-.293Zm0 1.572a.293.293 0 0 0-.293-.293h-.8a.293.293 0 0 0 0 .586h.8a.293.293 0 0 0 .293-.293Zm0 1.572a.293.293 0 0 0-.293-.293h-.8a.293.293 0 0 0 0 .586h.8a.293.293 0 0 0 .293-.293Zm-.293-4.394a.293.293 0 1 0 0-.586h-.8a.293.293 0 1 0 0 .586h.8ZM9.227 7.675a.293.293 0 0 0 0 .586h3.814a.293.293 0 1 0 0-.586H9.227Zm-3.602 7.99a.586.586 0 0 0 1.172 0v-.573a.6.6 0 0 1 .6-.6h1.73a.6.6 0 0 1 .6.6v.572a.586.586 0 0 0 1.171.003v-5.265a.598.598 0 0 0-.6-.597H6.225a.6.6 0 0 0-.6.6v5.26Zm1.172-3.81c0-.161.131-.293.293-.293h2.344a.293.293 0 0 1 0 .586H7.09a.293.293 0 0 1-.293-.293Zm0 1.172c0-.161.131-.293.293-.293h2.344a.293.293 0 1 1 0 .586H7.09a.293.293 0 0 1-.293-.293Zm.637 3.023c0 .11.09.2.2.2h1.4a.2.2 0 0 0 .2-.2v-.8a.2.2 0 0 0-.2-.2h-1.4a.2.2 0 0 0-.2.2v.8Z"
    />
  </Icon>
);

FolderDistrict.displayName = 'FolderDistrict';

export default FolderDistrict;
