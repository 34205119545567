import { useState } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import {
  FeedbackComponent,
  FeedbackWrapper,
} from 'ms-components/Feedback/FeedbackComponent';
import JsxContent from 'ms-components/JsxContent';
import { PageTimeRecorder } from 'ms-helpers/PageTimeTracker';
import useFeatureFlags from 'ms-helpers/useFeatureFlags';
import { Base as CustomEmptyMessage } from 'ms-pages/Teacher/components/Empty';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import AnswerKeyModal from 'ms-pages/Textbooks/components/AnswerKeyModal';
import useDifficultyLevelFilters from 'ms-pages/Textbooks/components/ProblemDifficultyFilter/hooks';
import Lesson from 'ms-pages/Textbooks/components/SubtopicDetailView/Lesson';
import Questions from 'ms-pages/Textbooks/components/SubtopicDetailView/Questions';
import SubtopicDetailHeader from 'ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader';
import type { SubtopicTab } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import {
  TheoryContentWrapper,
  ContentTitle,
  ContentHeading,
} from 'ms-pages/Textbooks/components/elements';
import useTextbookTabs from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Stack from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { TeacherSubtopicDetailViewQuery } from './__generated__/TeacherSubtopicDetailViewQuery.graphql';

const MainContentWrapper = styled({
  background: colors.white,
  width: '100%',
  height: '100%',
  overflow: 'auto',
});
const Wrapper = styled({
  paddingLeft: 4 * BASE_UNIT,
  paddingRight: 4 * BASE_UNIT,
  paddingBottom: 4 * BASE_UNIT,
  maxWidth: breakPoints.medium,
  margin: 'auto',
});

const ContentWrapper = styled({
  // just a stopgap so that spinner/empty  don't render too close to the tabs
  // seems to give a bit of breath to the content too by the way
  marginTop: 4 * BASE_UNIT,
});
const TEACHER_SUBTOPIC_DETAIL_VIEW_QUERY = graphql`
  query TeacherSubtopicDetailViewQuery($subtopicId: ID!) {
    subtopic(id: $subtopicId) {
      id
      ...SubtopicDetailHeader_subtopic
      worksheetJsx {
        pdfAvailable
        transpiledJsx
      }
      answerKeyJsx {
        pdfAvailable
        transpiledJsx
      }
      teacherOverviewJsx {
        transpiledJsx
      }
      engageActivityTeacherNoteJsx {
        transpiledJsx
      }
      problemContents {
        difficultyLevel
      }
      hasLessons
      hasLessonsTeacherNotes
      hasEngageActivity
      hasEngageActivityTeacherNote
      title
      hasExercise
      hasWorksheet
      hasWorksheetAnswerKey
    }
  }
`;
type Props = {
  subtopicId: string;
};

export default function TeacherSubtopicDetailView({ subtopicId }: Props) {
  const { props: data, error } = useQuery<TeacherSubtopicDetailViewQuery>(
    TEACHER_SUBTOPIC_DETAIL_VIEW_QUERY,
    { subtopicId },
    {
      fetchPolicy: 'store-or-network',
      networkCacheConfig: {
        // @ts-expect-error
        // I don't trust the TS relay types, I don't think they are accurate
        // for our version so leaving this here until we update to latest
        // version of relay.
        useCache: true,
        ttl: Infinity,
      },
    },
  );
  if (error != null) throw error;
  if (data == null) return <MinorSpinner />;
  const { subtopic } = data;
  if (subtopic == null) return <>Subtopic was not found</>;
  const worksheet = subtopic.worksheetJsx.transpiledJsx;
  const answerKey = subtopic.answerKeyJsx.transpiledJsx;
  const engageActivityTeacherNote =
    subtopic.engageActivityTeacherNoteJsx.transpiledJsx;
  return (
    <TeacherSubtopicDetailViewUi
      worksheet={worksheet}
      answerKey={answerKey}
      engageActivityTeacherNote={engageActivityTeacherNote}
      subtopic={subtopic}
    />
  );
}
type Subtopic = NonNullable<
  TeacherSubtopicDetailViewQuery['response']['subtopic']
>;
type TeacherSubtopicDetailViewUiProps = {
  engageActivityTeacherNote: string | null | undefined;
  answerKey: string | null | undefined;
  worksheet: string | null | undefined;
  subtopic: Subtopic;
};

function TeacherSubtopicDetailViewUi({
  engageActivityTeacherNote,
  answerKey,
  worksheet,
  subtopic,
}: TeacherSubtopicDetailViewUiProps) {
  const [isAnswerKeyModalOpen, setAnswerKeyModalOpen] = useState(false);
  const showOverviewTab = subtopic.teacherOverviewJsx.transpiledJsx != null;

  const [
    {
      // rename it to make it more explicit
      engageTasks: canAccessEngageTasks,

      // rename it to make it more explicit
      worksheetAnswerKeys: canAccessWorksheetAnswerKeys,

      // rename it to make it more explicit
      primaryTeacherNotes: canAccessPrimaryTeacherNotes,
    },
  ] = useFeatureFlags();

  const {
    hasLessons,
    hasLessonsTeacherNotes,
    hasEngageActivity,
    hasEngageActivityTeacherNote,
  } = subtopic;

  const {
    selectedDifficultyLevels,
    toggleDifficultyLevelSelection,
    selectedDifficultyFilters,
    canShowDifficultyFilter,
  } = useDifficultyLevelFilters({
    problemContents: subtopic.problemContents,
  });

  const hasInteractiveContent = subtopic.hasExercise;

  // We will show the worksheet tab even if there is only
  // an answer key with no worksheet
  // but the teachers must have access to it
  const hasWorksheetContent =
    subtopic.hasWorksheet ||
    (canAccessWorksheetAnswerKeys && subtopic.hasWorksheetAnswerKey);

  const showPracticeTab = hasInteractiveContent || hasWorksheetContent;

  // Show teacher notes if
  // - there are teacher notes AND
  // - the teacher has access to them
  const showTeacherNotes =
    hasLessonsTeacherNotes && canAccessPrimaryTeacherNotes;

  // Display the lesson tab if
  //   - there are lessons, OR
  //   - there are teacher notes AND the teacher has access to them
  const showLessonTab = hasLessons || showTeacherNotes;

  // Display the engage tab if
  //  - there is at least one of the engage task or the engage task teacher note, AND
  // - the teacher has access to engage tasks
  const hasAnyEngageContent = hasEngageActivity || hasEngageActivityTeacherNote;
  const showEngageTab = hasAnyEngageContent && canAccessEngageTasks;

  const [activeTab] = useTextbookTabs({
    overview: {
      shouldRender: subtopic.teacherOverviewJsx.transpiledJsx !== null,
    },
    lesson: {
      shouldRender: showLessonTab,
    },
    interactive: {
      shouldRender: hasInteractiveContent,
      fallbacks: ['worksheet'],
    },
    worksheet: {
      shouldRender: hasWorksheetContent,
      fallbacks: ['interactive'],
    },
    engageActivity: {
      shouldRender: showEngageTab,
    },
  });

  const hasNoContent =
    !showOverviewTab && !showPracticeTab && !showLessonTab && !showEngageTab;

  // These are used to hide the feedback component when lesson or questions tab are selected. They are changed through a callback on the Lesson and Questions components
  const hasLessonLoaded = useBoolean();
  const hasQuestionsLoaded = useBoolean();

  const showFeedback =
    activeTab === 'overview' ||
    activeTab === 'engageActivity' ||
    activeTab === 'worksheet' ||
    (activeTab === 'interactive' && hasQuestionsLoaded.value) ||
    (activeTab === 'lesson' && hasLessonLoaded.value);

  return (
    <MainContentWrapper>
      <SubtopicDetailHeader
        subtopicKey={subtopic}
        showLesson={showLessonTab}
        showEngage={showEngageTab}
        showOverview={showOverviewTab}
        showPractice={showPracticeTab}
        difficultyLevelProps={{
          selectedDifficultyLevels,
          toggleDifficultyLevelSelection,
          canShowDifficultyFilter,
        }}
      />

      <Wrapper>
        <ContentWrapper>
          {hasNoContent && (
            <CustomEmptyMessage label="There is no content for this subtopic" />
          )}
          {activeTab === 'overview' && (
            <>
              <ContentHeading>
                <ContentTitle>Subtopic overview</ContentTitle>
              </ContentHeading>
              {subtopic.teacherOverviewJsx.transpiledJsx !== null && (
                <JsxContent
                  transpiledJsx={subtopic.teacherOverviewJsx.transpiledJsx}
                />
              )}
            </>
          )}
          {activeTab === 'lesson' && (
            <Lesson onLoad={hasLessonLoaded.setTrue} subtopicId={subtopic.id} />
          )}
          {activeTab === 'engageActivity' && canAccessEngageTasks && (
            <>
              {engageActivityTeacherNote != null ? (
                <JsxContent transpiledJsx={engageActivityTeacherNote} />
              ) : (
                'No task overview available'
              )}
            </>
          )}
          {answerKey != null && (
            <AnswerKeyModal
              subtopicId={subtopic.id}
              answerKey={answerKey}
              isOpen={isAnswerKeyModalOpen}
              onClose={() => {
                setAnswerKeyModalOpen(false);
              }}
            />
          )}

          {(activeTab === 'interactive' || activeTab === 'worksheet') && (
            <>
              <ContentHeading>
                <ContentTitle>Practice</ContentTitle>
              </ContentHeading>

              {activeTab === 'interactive' ? (
                <Questions
                  subtopicId={subtopic.id}
                  canCollectProblemItems
                  difficultyFilters={selectedDifficultyFilters}
                  onLoad={hasQuestionsLoaded.setTrue}
                />
              ) : null}
              {worksheet != null && activeTab === 'worksheet' && (
                <>
                  <PageTimeRecorder
                    componentName="Worksheet"
                    pageName="Textbooks"
                  >
                    <TheoryContentWrapper>
                      <JsxContent transpiledJsx={worksheet} />
                    </TheoryContentWrapper>
                  </PageTimeRecorder>
                </>
              )}
            </>
          )}

          <Stack.Spacer.V height={40} />

          {showFeedback && (
            <FeedbackWrapper>
              <FeedbackComponent
                feedbackType={getFeedbackTypeFromActiveTab(activeTab)}
                subtopicId={subtopic.id}
              />
            </FeedbackWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </MainContentWrapper>
  );
}

function getFeedbackTypeFromActiveTab(activeTab: SubtopicTab) {
  switch (activeTab) {
    case 'interactive':
      return 'PRACTICE_QUESTIONS';
    case 'worksheet':
      return 'WORKSHEET';
    case 'lesson':
      return 'LESSON';
    default:
      return 'GENERAL';
  }
}
