import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';

import RecordLastStudentViewUrl from 'ms-components/RecordLastStudentViewUrl';
import PageTitle from 'ms-helpers/PageTitle';
import { StudentLayout } from 'ms-pages/Lantern/components/StudentLayout';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import {
  checkInPath,
  checkInBasePath,
  checkInStrandPath,
  checkInSubstrandGrowthPath,
  checkInStrandDiagnosticPath,
  checkInStrandGrowthPath,
  studentPath,
  studentSkillsPath,
  tutorialStrandPath,
  checkInSkillPath,
  checkInPreTopicTestPath,
} from 'ms-pages/Lantern/utils/urls';
import type {
  CheckInSubstrandGrowthPathParams,
  CheckInSkillPathParams,
} from 'ms-pages/Lantern/utils/urls';
import StartSkillCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/CheckInSkill/StartSkillCheckIn';
import {
  CheckInStrand,
  CheckInStartDiagnostic,
} from 'ms-pages/Lantern/views/Student/CheckIn/CheckInStrand';
import StartSubstrandGrowthCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/CheckInSubstrand/StartSubstrandGrowthCheckIn';

import StartPreTopicTestCheckIn from './CheckIn/CheckInPreTopicTest/StartPreTopicTestCheckIn';

const CheckIn = lazy(() => import('./CheckIn/CheckIn'));
const NotFound = lazy(() => import('ms-pages/Lantern/components/NotFound'));
const StudentSkillsMap = lazy(() => import('./StudentSkillsMap'));
const TutorialStrand = lazy(() => import('./Tutorial/TutorialStrand'));
function StudentApp() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Switch>
        <Route exact path={tutorialStrandPath} children={<TutorialStrand />} />

        <Route path={checkInBasePath}>
          <PageTitle subtitles={['Check-in']} />
          <Switch>
            <Route
              exact
              path={checkInStrandPath}
              children={<CheckInStrand />}
            />
            <Route
              path={checkInStrandDiagnosticPath}
              children={<CheckInStartDiagnostic />}
            />
            {/* Strand growth check-ins are already deprecated. Instead of showing students
            a calculator page when they access this URL, we want to redirect them to their
            discovery check-in completion screen which shows a snippet of their current skills
            page for the strand. */}
            <Route
              path={checkInStrandGrowthPath}
              children={<CheckInStrand />}
            />
            <Route
              path={checkInSubstrandGrowthPath}
              render={({
                match: {
                  params: { substrandId },
                },
              }: RouteComponentProps<CheckInSubstrandGrowthPathParams>) => (
                <StartSubstrandGrowthCheckIn substrandId={substrandId} />
              )}
            />
            <Route
              path={checkInSkillPath}
              render={({
                match: {
                  params: { strandId, skillId },
                },
              }: RouteComponentProps<
                Omit<CheckInSkillPathParams, 'skipWelcome'>
              >) => <StartSkillCheckIn strandId={strandId} skillId={skillId} />}
            />
            <Route
              path={checkInPreTopicTestPath}
              render={({
                match: {
                  params: { skillIds },
                },
              }: RouteComponentProps<{ skillIds: string }>) => {
                const deserialisedSkillIds: string[] =
                  decodeURIComponent(skillIds).split(',');

                return (
                  <StartPreTopicTestCheckIn skillIds={deserialisedSkillIds} />
                );
              }}
            />
            <Route exact path={checkInPath} children={<CheckIn />} />
          </Switch>
        </Route>
        <Route
          path={[studentPath, studentSkillsPath]}
          children={
            <>
              <RecordLastStudentViewUrl />
              <StudentSkillsMap />
            </>
          }
        />
        <Route
          path="*"
          children={
            <StudentLayout>
              <NotFound />
            </StudentLayout>
          }
        />
      </Switch>
    </Suspense>
  );
}

export { StudentApp };
