import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M10.6315 0.159816L3.16044 7.6314C3.05358 7.73815 3.00006 7.86096 3.00006 7.99989C3.00006 8.13881 3.05358 8.26185 3.16044 8.36865L10.6315 15.8396C10.7385 15.9467 10.8614 16 11.0003 16C11.1393 16 11.2622 15.9465 11.369 15.8396L12.1704 15.0382C12.2773 14.9313 12.3307 14.8083 12.3307 14.6694C12.3307 14.5305 12.2773 14.4075 12.1704 14.3007L5.86978 7.99989L12.1708 1.6989C12.2777 1.59209 12.3308 1.46906 12.3308 1.33036C12.3308 1.19121 12.2773 1.06823 12.1708 0.961422L11.3691 0.159873C11.2623 0.0530668 11.1393 -3.55776e-07 11.0004 -3.49706e-07C10.8614 -5.56567e-05 10.7384 0.0530106 10.6315 0.159816Z" />
);

const ChevronLeftThick = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

ChevronLeftThick.displayName = 'ChevronLeftThick';

export default ChevronLeftThick;
