import type { Config } from 'mathquill';

import { getMathquillKeystroke, getMathquillCommand } from './mathShortcuts';

export const MATHQUILL_CONFIG: Config = {
  commands: [
    {
      name: 'div',
      latex: '\\div ',
      commands: getMathquillCommand('DIVIDE'),
      keystroke: getMathquillKeystroke('DIVIDE'),
      htmlEntity: '÷​',
    },
    {
      name: 'plusmin',
      commands: getMathquillCommand('PLUS_MINUS'),
      latex: '\\pm ',
      htmlEntity: '±',
    },
    {
      name: 'mixed',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('MIXED_FRACTION'),
      keystroke: getMathquillKeystroke('MIXED_FRACTION'),
    },
    {
      name: 'nthroot',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('NTH_ROOT'),
    },
    {
      name: 'sqrt',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('SQUARE_ROOT'),
    },
    {
      name: 'cup',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('UNION'),
    },
    {
      name: 'cap',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('INTERSECTION'),
    },
    {
      name: 'angle',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('ANGLE'),
    },
    {
      name: 'parallel',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('PARALLEL'),
    },
    {
      name: 'triangle',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('TRIANGLE'),
    },
    {
      name: 'isCommon',
      commands: getMathquillCommand('IS_COMMON'),
      latex: '\\text{is common}',
      htmlEntity: 'is common ',
    },
    {
      name: 'degree',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('DEGREES'),
    },
    {
      name: 'binom',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('BINOMIAL_COEFFICIENT'),
    },
    {
      name: 'nCr',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('NCR_BINOMIAL_COEFFICIENT'),
    },
    {
      name: 'nPr',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('NPR_PERMUTATION'),
    },
    {
      name: 'defint',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('DEFINITE_INTEGRAL'),
    },
    {
      name: 'integral',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('INDEFINITE_INTEGRAL'),
    },
    {
      name: 'and',
      useInternalSymbolDef: true,
      commands: ['andword'],
    },
    {
      name: 'or',
      useInternalSymbolDef: true,
      commands: ['orword'],
    },
    {
      name: 'infinity',
      useInternalSymbolDef: true,
      match: ['∞'],
      commands: getMathquillCommand('INFINITY'),
    },
    {
      name: 'simeq',
      useInternalSymbolDef: true,
      commands: getMathquillCommand('SIMILAR_EQUAL'),
    },
  ],
  ignoredCharacters: ['$', '∏', 'µ', 'σ', '≈'],
  // TODO: Dedupe configs?
  // see: /msproblem/static/jsmvc/math_components/latex_editor/latex_editor.js
  // see: /msproblem/static/jsmvc/widgets/math_input.js
  autoCommands: [
    'alpha',
    'and',
    'angle',
    'beta',
    'delta',
    'gamma',
    'lim',
    'omega',
    'or',
    'phi',
    'pi',
    'sum',
    'theta',
    'triangle',
  ].join(' '),
  autoCommandsMapping: {
    and: 'andword',
    or: 'orword',
  },
  autoOperatorNames: [
    'arccos',
    'arcsin',
    'arctan',
    'cis',
    'cos',
    'cosec',
    'cot',
    'csc',
    'ln',
    'log',
    'sec',
    'sin',
    'tan',
  ].join(' '),
  charsThatBreakOutOfSupSub: '=<>',
  preventBackslash: true,
  spaceBehavesLikeTab: true,
  supSubsRequireOperand: true,
  multiplicationDisplaySymbol: 'cross',
};
