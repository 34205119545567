import type { Value } from 'ms-components/math/ProbabilityTree';
import type { subproblemFragments_ProbabilityTreeConfig } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_ProbabilityTreeConfig.graphql';
import genUniqueId from 'ms-utils/id-generator';

export const ROW_HEIGHT = 70;
export const PADDING = 26;
export const WIDTH = 400;
type Latex = string;
type Props = {
  value: Value;
};
type InputValue = {
  tree: subproblemFragments_ProbabilityTreeConfig['tree'];
};
type InputValueTree = {
  id: number;
  label: string;
  probability: Latex | null | undefined;
  subtree: Array<InputValueTree>;
};
const transformTreeToHaveIds = (tree: InputValueTree): InputValueTree => ({
  id: genUniqueId(),
  label: tree.label,
  probability: tree.probability,
  subtree: tree.subtree.map(subtree => transformTreeToHaveIds(subtree)),
});
const transformTreeToInternalValue = (tree: InputValueTree): Value => ({
  meta: {
    probability: tree.probability || '',
    label: tree.label,
    id: tree.id,
  },
  children: tree.subtree.map(subtree => transformTreeToInternalValue(subtree)),
});
const transformTreeFromInternalValue = (tree: Value): InputValueTree => ({
  id: tree.meta.id,
  label: tree.meta.label,
  probability: tree.meta.probability,
  subtree: tree.children.map(subtree =>
    transformTreeFromInternalValue(subtree),
  ),
});
/**
 * Prepares the input value to be consumed on the front-end.
 * In particular, we need to add persistent keys to the tree nodes.
 * @param {InputValue} inputValue Subproblem's answer input config.
 * @returns {InputValue} Enhanced answer input value.
 */
export const prepareInputValue = (inputValue: InputValue): InputValue => ({
  tree: JSON.stringify(transformTreeToHaveIds(JSON.parse(inputValue.tree))),
});
/**
 * Transforms the input value state for ProbabilityTree subproblem
 * into the props for the corresponding `ProbabilityTree` component.
 *
 * @param {InputValue} inputValue Subproblem's answer input value.
 * @returns {Props} Props for `ProbabilityTree` component.
 */
export const toInternalValueFormat = (inputValue: InputValue): Props => ({
  value: transformTreeToInternalValue(JSON.parse(inputValue.tree)),
});
/**
 * Transforms the props of `ProbabilityTree` component into
 * the shape of the input value state of the subproblem.
 *
 * @param {Props} props The subset of `ProbabilityTree` component props.
 * @returns {InputValue} The input config.
 */
export const fromInternalValueFormat = (props: Props): InputValue => ({
  tree: JSON.stringify(transformTreeFromInternalValue(props.value)),
});
