import { createContext, useContext, useState } from 'react';

import type {
  SubtopicTab,
  SubtopicLessonTab,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import {
  MTS_DEFAULT_SUBTOPIC_TAB,
  DEFAULT_SUBTOPIC_LESSON_TAB,
} from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';

const MultiTextbookSearchModalContext = createContext<{
  isInsideMultiTextbookSearchModal: boolean;
  activeSubtopicTab: SubtopicTab;
  activeSubtopicLessonTab: SubtopicLessonTab;
  setActiveSubtopicTab: (tab: SubtopicTab) => void;
  setActiveSubtopicLessonTab: (tab: SubtopicLessonTab) => void;
}>({
  isInsideMultiTextbookSearchModal: false,
  activeSubtopicTab: MTS_DEFAULT_SUBTOPIC_TAB,
  activeSubtopicLessonTab: DEFAULT_SUBTOPIC_LESSON_TAB,
  setActiveSubtopicTab: () => {},
  setActiveSubtopicLessonTab: () => {},
});

export function useMultiTextbookSearchModalContext() {
  return useContext(MultiTextbookSearchModalContext);
}

export function MultiTextbookSearchModalContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeSubtopicTab, setActiveSubtopicTab] = useState<SubtopicTab>(
    MTS_DEFAULT_SUBTOPIC_TAB,
  );

  const [activeSubtopicLessonTab, setActiveSubtopicLessonTab] =
    useState<SubtopicLessonTab>(DEFAULT_SUBTOPIC_LESSON_TAB);

  return (
    <MultiTextbookSearchModalContext.Provider
      value={{
        isInsideMultiTextbookSearchModal: true,
        activeSubtopicTab,
        activeSubtopicLessonTab,
        setActiveSubtopicTab,
        setActiveSubtopicLessonTab,
      }}
    >
      {children}
    </MultiTextbookSearchModalContext.Provider>
  );
}
