/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WithStudentOnboardingQueryVariables = {};
export type WithStudentOnboardingQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly " $fragmentRefs": FragmentRefs<"WithStudentOnboardingFragment_profile">;
        };
    };
};
export type WithStudentOnboardingQuery = {
    readonly response: WithStudentOnboardingQueryResponse;
    readonly variables: WithStudentOnboardingQueryVariables;
};



/*
query WithStudentOnboardingQuery {
  viewer {
    profile {
      __typename
      ...WithStudentOnboardingFragment_profile
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}

fragment WithStudentOnboardingFragment_profile on Profile {
  __isProfile: __typename
  __typename
  ... on Student {
    hasCompletedOnboarding
    onboardingChecklist {
      status
      stepType
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithStudentOnboardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WithStudentOnboardingFragment_profile"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WithStudentOnboardingQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isProfile"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasCompletedOnboarding",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StudentOnboardingStep",
                    "kind": "LinkedField",
                    "name": "onboardingChecklist",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "stepType",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v1/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1473eee4c61a03734e939e4906c20cb1",
    "id": null,
    "metadata": {},
    "name": "WithStudentOnboardingQuery",
    "operationKind": "query",
    "text": "query WithStudentOnboardingQuery {\n  viewer {\n    profile {\n      __typename\n      ...WithStudentOnboardingFragment_profile\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment WithStudentOnboardingFragment_profile on Profile {\n  __isProfile: __typename\n  __typename\n  ... on Student {\n    hasCompletedOnboarding\n    onboardingChecklist {\n      status\n      stepType\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'efc81000bdd6ff99ee315f3203056acb';
export default node;
