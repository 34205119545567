import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M8.449 5.417l2.537 8.627 3.533-1.657a.475.475 0 0 0 .134-.766L8.449 5.417zM2.045 17.307a.475.475 0 0 0 .632.632l3.488-1.635-1.407-4.783-2.713 5.786zM5.367 10.223l1.668 5.673 3.08-1.444L7.52 5.629zM12.806 7.764a.474.474 0 0 0 .336-.14l.874-.873a.475.475 0 0 0-.672-.672l-.874.873a.475.475 0 0 0 .336.812zM14.91 5.66a.474.474 0 0 0 .335-.139l.874-.873a.475.475 0 0 0-.672-.672l-.874.873a.475.475 0 0 0 .336.812zM10.758 5.67a.473.473 0 0 0 .672 0 2.47 2.47 0 0 0 .728-1.757 2.47 2.47 0 0 0-.728-1.758.475.475 0 0 0-.672.672c.29.29.45.676.45 1.086 0 .41-.16.796-.45 1.086a.475.475 0 0 0 0 .672zM17.83 8.555a2.49 2.49 0 0 0-3.517 0 .475.475 0 0 0 .672.672 1.538 1.538 0 0 1 2.172 0 .475.475 0 1 0 .673-.672z" />
);

const Confetti = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Confetti.displayName = 'Confetti';

export default Confetti;
