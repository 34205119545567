import invariant from 'invariant';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';

import type { StudentContextLanternQuery as StudentContextLanternQueryType } from './__generated__/StudentContextLanternQuery.graphql';

type StudentContextType = {
  // As opposed to `ViewerPayload` in `ms-helpers/Viewer/ViewerProvider`,
  // `lanternProfileId` is not nullable here since we want to ensure
  // that we have a lantern profile whenever we use this context.
  // See also `./Student.jsx`.
  lanternProfileId: string;
  sanaUserId: string;
  avatar: string; // TODO: remove once we use Mathspace's navbar
  selfReportedGradeId: string;
  selfReportedGradeOrder: number;
  selfReportedGradeTitle: string;
  schoolId: string;
  curriculum: {
    id: string;
    sanaCatalogId: string;
    title: string;
    strands: readonly {
      id: string;
      title: string;
    }[];
    countryCode: string;
  };
};
const StudentContext = createContext<StudentContextType | null>(null);
function StudentContextProvider({
  children,
  lanternProfileId,
}: {
  children: ReactNode;
  lanternProfileId: string;
}) {
  const { props, error } = useQuery<StudentContextLanternQueryType>(
    graphql`
      query StudentContextLanternQuery {
        viewer {
          avatar
        }
        lantern {
          viewer {
            __typename
            ... on LanternStudent {
              sanaUserId
              school {
                id
              }
              curriculum {
                id
                sanaCatalogId
                title
                strands {
                  id
                  title
                }
                country {
                  code
                }
              }
              selfReportedGrade {
                id
                order
                title
              }
            }
          }
        }
      }
    `,
    {},
  );
  if (error != null)
    throw new Error(`StudentContextLanternQuery failed: ${error.message}`);
  if (props == null) return <LoadingSpinner />;
  const {
    lantern: { viewer: lanternViewer },
    viewer,
  } = props;
  invariant(viewer != null && lanternViewer != null, 'User not found');
  invariant(
    lanternViewer.__typename === 'LanternStudent',
    'User is not a student',
  );
  const { curriculum } = lanternViewer;
  return (
    <StudentContext.Provider
      value={{
        lanternProfileId,
        avatar: viewer.avatar,
        sanaUserId: lanternViewer.sanaUserId,
        selfReportedGradeId: lanternViewer.selfReportedGrade.id,
        selfReportedGradeOrder: lanternViewer.selfReportedGrade.order,
        selfReportedGradeTitle: lanternViewer.selfReportedGrade.title,
        schoolId: lanternViewer.school.id,
        curriculum: {
          id: curriculum.id,
          sanaCatalogId: curriculum.sanaCatalogId,
          title: curriculum.title,
          strands: curriculum.strands.map(s => ({ id: s.id, title: s.title })),
          countryCode: curriculum.country.code,
        },
      }}
    >
      {children}
    </StudentContext.Provider>
  );
}
function useStudentContext() {
  const context = useContext(StudentContext);
  if (context == null) {
    throw new Error('Missing `StudentContextProvider`.');
  }
  return context;
}
export { StudentContext, StudentContextProvider, useStudentContext };
