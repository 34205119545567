/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SuggestedLearningFocusNotification_learningFocus = {
    readonly title: string;
    readonly " $refType": "SuggestedLearningFocusNotification_learningFocus";
};
export type SuggestedLearningFocusNotification_learningFocus$data = SuggestedLearningFocusNotification_learningFocus;
export type SuggestedLearningFocusNotification_learningFocus$key = {
    readonly " $data"?: SuggestedLearningFocusNotification_learningFocus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SuggestedLearningFocusNotification_learningFocus">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuggestedLearningFocusNotification_learningFocus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Substrand",
  "abstractKey": null
};
(node as any).hash = '555e10e0a510945a456a5add909f3f57';
export default node;
