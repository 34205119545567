import type {
  BasePageTimingEvent,
  PageTimingEvent,
  PageTimingErrorEvent,
} from 'ms-helpers/Snowplow/Types/pageTiming';

export function createPageTimingEvent(
  event: BasePageTimingEvent,
): PageTimingEvent {
  return {
    ...event,
    category: 'page_timing',
  };
}

export function createPageTimingErrorEvent(
  event: BasePageTimingEvent,
): PageTimingErrorEvent {
  return {
    ...event,
    category: 'page_timing_error',
  };
}
