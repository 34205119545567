type Palette = {
  name: string;
  grade: string;
  topic: string;
};

type Grouping = {
  title: string;
  affix: string;
};

const palettes = [
  {
    name: 'purple',
    grade: '#7547D8',
    topic: '#E1E2F1',
  },
  {
    name: 'indigo',
    grade: '#3B4DBA',
    topic: '#CED2EE',
  },
  {
    name: 'blue',
    grade: '#0875BE',
    topic: '#D5E8F9',
  },
  {
    name: 'teal',
    grade: '#0694A4',
    topic: '#CBE8EC',
  },
  {
    name: 'green',
    grade: '#03A887',
    topic: '#D1EEE7',
  },
];

const augmentWithColor = (grouping: Grouping, index: number) => ({
  ...grouping,
  colors: palettes[index % palettes.length] as Palette,
});

export const grades = [
  {
    title: 'Grade 6',
    affix: 'Grade 6',
  },
  {
    title: 'Grade 7',
    affix: 'Grade 7',
  },
  {
    title: 'Grade 8',
    affix: 'Grade 8',
  },
  {
    title: 'Algebra 1',
    affix: 'A1',
  },
  {
    title: 'Geometry',
    affix: 'G',
  },
  {
    title: 'Algebra 2',
    affix: 'A2',
  },
].map(augmentWithColor);

export const domains = [
  {
    title: 'Number & Number Sense',
    affix: 'Number & Number Sense',
  },
  {
    title: 'Computation & Estimation',
    affix: 'Computation & Estimation',
  },
  {
    title: 'Measurement & Geometry',
    affix: 'Measurement & Geometry',
  },
  {
    title: 'Probability & Statistics',
    affix: 'Probability & Statistics',
  },
  {
    title: 'Patterns, Functions, & Algebra',
    affix: 'Patterns, Functions, & Algebra',
  },
  {
    title: 'Expressions and Operations',
    affix: 'Expressions and Operations',
  },
  {
    title: 'Equations and Inequalities',
    affix: 'Equations and Inequalities',
  },
  {
    title: 'Functions',
    affix: 'Functions',
  },
  {
    title: 'Statistics',
    affix: 'Statistics',
  },
  {
    title: 'Reasoning, Lines and Transformations',
    affix: 'Reasoning, Lines and Transformations',
  },
  {
    title: 'Triangles',
    affix: 'Triangles',
  },
  {
    title: 'Polygons and Circles',
    affix: 'Polygons and Circles',
  },
  {
    title: 'Two- and Three-Dimensional Figures',
    affix: 'Two- and Three-Dimensional Figures',
  },
].map(augmentWithColor);
