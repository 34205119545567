import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay';

import MathContent from 'ms-components/math/MathContent';

import type { PreviewProblemAttachment_problemContent$key } from './__generated__/PreviewProblemAttachment_problemContent.graphql';

const Root = styled.div({
  overflowX: 'auto',
});
type Props = {
  problemContent: PreviewProblemAttachment_problemContent$key;
  noInternalOverflow?: boolean | undefined;
};
const FRAGMENT = graphql`
  fragment PreviewProblemAttachment_problemContent on ProblemContent {
    attachment
  }
`;
export default function PreviewProblemAttachment({
  problemContent: problemContentRef,

  // Be sure to use this prop carefully,
  // as it can cause the component to not scroll when needed
  // when the wrapper is not wide enough.
  // Graph plot attachments are taking their width from the viewable area of the wrapper
  // And they scroll internally even if their wrapper can be scrolled horizontally, beyond the viewable area
  // So they are creating a horizontal scroll bar internally and unnecessarily
  // The intention is, if the wrapper is wide enough beyond the viewable area,
  // we don't want the attachment to scroll horizontally and individually
  // removing "overflowX: auto" from the Root style won't work
  // because MathContent has another "overflow:auto" style buried in its own styles
  // which we cannot control from here
  // The solution is
  // we are letting the root element to take its width
  // so, it wouldn't scroll internally
  noInternalOverflow = false,
}: Props) {
  const problemContent = useFragment(FRAGMENT, problemContentRef);

  if (problemContent == null) {
    throw new Error('Problem content not found');
  }

  const { attachment } = problemContent;

  return attachment ? (
    <Root style={noInternalOverflow ? { minWidth: 'min-content' } : {}}>
      <MathContent content={attachment} />
    </Root>
  ) : null;
}
