import { useState, useEffect } from 'react';

// inspired by https://dev.to/link2twenty/react-custom-hook-for-media-queries-2bn4
export default function useMediaQuery(mediaQuery: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const onChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };
    const mql = window.matchMedia(mediaQuery);
    setMatches(mql.matches);
    mql.addEventListener('change', onChange);
    return () => {
      mql.removeEventListener('change', onChange);
    };
  }, [mediaQuery]);

  return matches;
}
