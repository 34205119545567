import type { CSSProperties, ReactNode } from 'react';

import { colors } from 'ms-styles/colors';

type CSS = {
  height?: CSSProperties['height'];
  backgroundColor?: CSSProperties['backgroundColor'];
};

const taskPreviewCollapsedWidth = 180;

function TaskPreviewDrawer({
  open,
  styles,
  children,
}: {
  open: boolean;
  styles?: CSS;
  children: ReactNode;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderRight: `1px solid ${colors.ironLight}`,
        ...(open
          ? { flexGrow: 1, width: '55%' }
          : { width: taskPreviewCollapsedWidth }),
        ...styles,
      }}
    >
      {children}
    </div>
  );
}

export { TaskPreviewDrawer };
