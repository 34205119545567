import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M5.30968114,9.29448316 L12.2817485,2.31022272 C13.2207954,1.3695336 14.6293657,2.78056729 13.6903189,3.72125641 L6.71825146,10.7055168 C5.77920458,11.646206 4.37063426,10.2351723 5.30968114,9.29448316 Z M6.71825146,9.29448316 L13.6903189,16.2787436 C14.6293657,17.2194327 13.2207954,18.6304664 12.2817485,17.6897773 L5.30968114,10.7055168 C4.37063426,9.76482772 5.77920458,8.35379403 6.71825146,9.29448316 Z" />
);

const ChevronLeft = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ChevronLeft.displayName = 'ChevronLeft';

export default ChevronLeft;
