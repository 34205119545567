import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Calculator = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={15}
    viewBoxHeight={15}
  >
    <g clipPath="url(#clip0_3829_3127)">
      <path
        d="M1.73047 7.021H5.85938C6.53788 7.021 7.08984 6.46904 7.08984 5.79053V1.66162C7.08984 0.983113 6.53788 0.431152 5.85938 0.431152H1.73047C1.05196 0.431152 0.5 0.983113 0.5 1.66162V5.79053C0.5 6.46904 1.05196 7.021 1.73047 7.021ZM2.57812 3.32959H3.39844V2.50928C3.39844 2.28257 3.58189 2.09912 3.80859 2.09912C4.0353 2.09912 4.21875 2.28257 4.21875 2.50928V3.32959H5.03906C5.26577 3.32959 5.44922 3.51304 5.44922 3.73975C5.44922 3.96645 5.26577 4.1499 5.03906 4.1499H4.21875V4.97021C4.21875 5.19692 4.0353 5.38037 3.80859 5.38037C3.58189 5.38037 3.39844 5.19692 3.39844 4.97021V4.1499H2.57812C2.35142 4.1499 2.16797 3.96645 2.16797 3.73975C2.16797 3.51304 2.35142 3.32959 2.57812 3.32959Z"
        fill={color}
      />
      <path
        d="M13.2695 0.431152H9.14062C8.46212 0.431152 7.91016 0.983113 7.91016 1.66162V5.79053C7.91016 6.46904 8.46212 7.021 9.14062 7.021H13.2695C13.948 7.021 14.5 6.46904 14.5 5.79053V1.66162C14.5 0.983113 13.948 0.431152 13.2695 0.431152ZM12.4492 4.1499H9.96094C9.73423 4.1499 9.55078 3.96645 9.55078 3.73975C9.55078 3.51304 9.73423 3.32959 9.96094 3.32959H12.4492C12.6759 3.32959 12.8594 3.51304 12.8594 3.73975C12.8594 3.96645 12.6759 4.1499 12.4492 4.1499Z"
        fill={color}
      />
      <path
        d="M13.2695 7.84131H9.14062C8.46212 7.84131 7.91016 8.39327 7.91016 9.07178V13.2007C7.91016 13.8792 8.46212 14.4312 9.14062 14.4312H13.2695C13.948 14.4312 14.5 13.8792 14.5 13.2007V9.07178C14.5 8.39327 13.948 7.84131 13.2695 7.84131ZM12.4492 12.353H9.96094C9.73423 12.353 9.55078 12.1696 9.55078 11.9429C9.55078 11.7162 9.73423 11.5327 9.96094 11.5327H12.4492C12.6759 11.5327 12.8594 11.7162 12.8594 11.9429C12.8594 12.1696 12.6759 12.353 12.4492 12.353ZM12.4492 10.7124H9.96094C9.73423 10.7124 9.55078 10.529 9.55078 10.3022C9.55078 10.0755 9.73423 9.89209 9.96094 9.89209H12.4492C12.6759 9.89209 12.8594 10.0755 12.8594 10.3022C12.8594 10.529 12.6759 10.7124 12.4492 10.7124Z"
        fill={color}
      />
      <path
        d="M7.08984 13.2007V9.07178C7.08984 8.39327 6.53788 7.84131 5.85938 7.84131H1.73047C1.05196 7.84131 0.5 8.39327 0.5 9.07178V13.2007C0.5 13.8792 1.05196 14.4312 1.73047 14.4312H5.85938C6.53788 14.4312 7.08984 13.8792 7.08984 13.2007ZM4.96857 11.7025C5.12878 11.8628 5.12878 12.1223 4.96857 12.2825C4.80836 12.4427 4.54879 12.4427 4.38858 12.2825L3.80859 11.7025L3.22861 12.2825C3.0684 12.4427 2.80882 12.4427 2.64862 12.2825C2.48841 12.1223 2.48841 11.8628 2.64862 11.7025L3.22861 11.1226L2.64862 10.5426C2.48841 10.3824 2.48841 10.1228 2.64862 9.96258C2.80882 9.80237 3.0684 9.80237 3.22861 9.96258L3.80859 10.5426L4.38858 9.96258C4.54879 9.80237 4.80836 9.80237 4.96857 9.96258C5.12878 10.1228 5.12878 10.3824 4.96857 10.5426L4.38858 11.1226L4.96857 11.7025Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_3829_3127">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0.5 0.431152)"
        />
      </clipPath>
    </defs>
  </Icon>
);

Calculator.displayName = 'Calculator';

export default Calculator;
