/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CustomMilo_student = {
    readonly id: string;
    readonly leaderboard?: {
        readonly currentWeek: ReadonlyArray<{
            readonly points: number;
            readonly studentId: string;
        }>;
    } | null | undefined;
    readonly " $refType": "CustomMilo_student";
};
export type CustomMilo_student$data = CustomMilo_student;
export type CustomMilo_student$key = {
    readonly " $data"?: CustomMilo_student$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CustomMilo_student">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "classId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "fetchLeaderboard"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "numberOfClasses"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomMilo_student",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "condition": "fetchLeaderboard",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Leaderboard",
          "kind": "LinkedField",
          "name": "leaderboard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "classId",
                  "variableName": "classId"
                }
              ],
              "concreteType": "CurrentWeekLeaderboardUser",
              "kind": "LinkedField",
              "name": "currentWeek",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "points",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "studentId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Student",
  "abstractKey": null
};
(node as any).hash = 'daad62d77fad96557f9d0021926adf63';
export default node;
