type UserStatuses = readonly {
  readonly trueProficiency: number | null | undefined;
  readonly userStatusFilter: {
    readonly curriculumNodeIds: readonly string[];
  };
}[];

type Props = {
  userStatuses: UserStatuses;
  outcomeId: string;
};

// We rely on the fact that we are querying for one node id (outcome id)
// in each item in the filter array.
export default function getProficiencyForOutcome({
  userStatuses,
  outcomeId,
}: Props): number | null | undefined {
  const node = userStatuses.find(
    status => status.userStatusFilter.curriculumNodeIds[0] === outcomeId,
  );

  // This can't really happen as the backend should return the same
  // `curriculumNodeIds` as the ones used in the filter variable.
  if (node == null)
    throw new Error(
      `getProficiencyForOutcome: the backend did not return the same curriculumNodeIds as the ones used in the filter variable. outcomeId: ${outcomeId}`,
    );

  return node.trueProficiency;
}
