import styled from '@emotion/styled';
import { useRef } from 'react';

import StudyNotesModal from 'ms-components/StudyNotesModal/StudyNotesModal';
import type { SyllabusLocale } from 'ms-components/StudyNotesModal/__generated__/HighlightingNoteEditorQuery.graphql';
import type { JsxContentType } from 'ms-components/StudyNotesModal/__generated__/StudyNotesModalQuery.graphql';
import NoteIcon from 'ms-components/icons/NoteIcon';
import { DEFAULT_SYLLABUS_LOCALE } from 'ms-pages/Textbooks/components/LanguageSelector/constants';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import DropdownMenu from 'ms-ui-primitives/DropdownMenu';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

const NoteIconText = styled.span({
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  color: colors.neutralGray,
  fontWeight: fontWeight.normal,
  lineHeight: '18px',
  marginRight: 2 * BASE_UNIT,
});
type Props = {
  subtopicId: string;
  jsxContentType: JsxContentType;
  locale?: SyllabusLocale | null | undefined;
};
export default function NotesModalLauncher({
  subtopicId,
  jsxContentType,
  locale,
}: Props) {
  const studyNotesModal = useBoolean();
  const studyNotesModalEditingMode = useBoolean();
  const notesMenu = useBoolean();
  const notesMenuAnchorRef = useRef<HTMLDivElement | null>(null);
  if (locale == null) locale = DEFAULT_SYLLABUS_LOCALE;
  return (
    <>
      <div ref={notesMenuAnchorRef}>
        <Button
          onClick={notesMenu.setTrue}
          type="tertiary"
          label="Create note"
          padding={0}
        >
          <NoteIconText>Notes</NoteIconText>
          <NoteIcon size={14} color={colors.eggplant} />
        </Button>

        {notesMenu.value && (
          <DropdownMenu
            anchorRef={notesMenuAnchorRef}
            menuPosition="bottom-left"
            onDismiss={notesMenu.setFalse}
            items={[
              {
                key: 'create-study-notes',
                label: 'Create study notes',
                action: studyNotesModal.setTrue,
              },
              {
                key: 'view-latest',
                label: 'View latest study notes',
                action: studyNotesModalEditingMode.setTrue,
              },
            ]}
          />
        )}
      </div>

      <StudyNotesModal
        isOpen={studyNotesModalEditingMode.value}
        onClose={studyNotesModalEditingMode.setFalse}
        editorMode="highlightNote"
        editingMode="editing"
        subtopicId={subtopicId}
        jsxContentType={jsxContentType}
        locale={locale}
      />

      <StudyNotesModal
        isOpen={studyNotesModal.value}
        onClose={studyNotesModal.setFalse}
        editorMode="highlightNote"
        editingMode="creating"
        subtopicId={subtopicId}
        jsxContentType={jsxContentType}
        locale={locale}
      />
    </>
  );
}
