import styled from '@emotion/styled';
import { useEffect } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import {
  StudentCheckInLayout,
  StudentLayout,
} from 'ms-pages/Lantern/components/StudentLayout';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import Main from 'ms-pages/Lantern/primitives/Main';
import {
  HSpacer,
  HStack,
  VSpacer,
  VStack,
} from 'ms-pages/Lantern/primitives/Stack';
import { BodyL, HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import useStartSubstrandGrowthCheckIn from 'ms-pages/Lantern/views/Student/CheckIn/useStartSubstrandGrowthCheckIn';
import { NotFoundError } from 'ms-utils/app-logging';
import { studentDashboardPath } from 'ms-utils/urls';

import type {
  LanternCheckInType,
  StartSubstrandGrowthCheckInQuery,
} from './__generated__/StartSubstrandGrowthCheckInQuery.graphql';
import { CheckInUnavailableError } from '../CheckInUnavailableError';
import SubstrandCheckInWelcomeScreen from '../CheckInWelcomeScreen';

const RootVStack = styled(VStack)({
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  textAlign: 'center',
});
const SUBSTRAND_GROWTH_CHECKIN_NUMBER_OF_QUESTIONS = 5;
const START_SUBSTRAND_GROWTH_CHECK_IN_QUERY = graphql`
  query StartSubstrandGrowthCheckInQuery($substrandId: ID!) {
    lantern {
      substrand(id: $substrandId) {
        id
        title
        badgeUrl
      }
      viewer {
        __typename
        ... on LanternStudent {
          checkIns {
            id
            type
          }
        }
      }
    }
  }
`;
type Props = {
  substrandId: string;
};
export default function StartSubstrandGrowthCheckIn({ substrandId }: Props) {
  const { loading, errors, start } = useStartSubstrandGrowthCheckIn({
    substrandId,
    replace: true,
  });
  const { trackStructEvent } = useSnowplow();
  const { props, error } = useQuery<StartSubstrandGrowthCheckInQuery>(
    START_SUBSTRAND_GROWTH_CHECK_IN_QUERY,
    { substrandId },
  );
  if (error != null) {
    // we can fail silently if this query doesn't resolve and don't block the UX
    return <JustStartCheckIn start={start} />;
  }
  if (props == null) return <LoadingSpinner />;
  if (
    props.lantern.viewer == null ||
    props.lantern.viewer.__typename !== 'LanternStudent'
  ) {
    throw new Error('User is not logged in or is not a student');
  }
  if (errors.find(e => e.key === 'CHECK_IN_NOT_AVAILABLE') !== undefined) {
    return <CheckInUnavailableError />;
  }
  if (errors.length > 0) {
    return (
      <StudentLayout>
        <Main>
          <RootVStack>
            <HeadingL>There was an error loading this page</HeadingL>
            <VSpacer height={24} />
            <BodyL color="peachPink">
              {errors.map(e => e.message).join('')}
            </BodyL>
            <VSpacer height={40} />
            <HStack>
              <Button
                size="lanternMedium"
                onClick={start}
                type="primary"
                isDisabled={loading}
              >
                Try again
              </Button>
              <HSpacer width={32} />
              <Button
                size="lanternMedium"
                onClick={() => {
                  if (window.history.length > 1) window.history.back();
                  else window.location.assign(studentDashboardPath);
                }}
                type="primary"
              >
                Go back
              </Button>
            </HStack>
          </RootVStack>
        </Main>
      </StudentLayout>
    );
  }
  const { substrand } = props.lantern;
  if (substrand == null) {
    throw new NotFoundError(`Substrand with id ${substrandId}  not found`);
  }
  return (
    <StudentCheckInLayout backgroundColor="almond50">
      <SubstrandCheckInWelcomeScreen
        checkInTitle={substrand.title}
        isLoading={loading}
        onSubmit={start}
        checkInType="SUBSTRAND_GROWTH_CHECKIN"
        numberOfQuestions={SUBSTRAND_GROWTH_CHECKIN_NUMBER_OF_QUESTIONS}
        substrands={[substrand]}
        onClose={() => {
          trackStructEvent({
            category: 'student_check_in',
            action: 'exit_checkin',
            value: 0,
          });
          if (window.history.length > 1) window.history.back();
          else window.location.assign(studentDashboardPath);
        }}
      />
    </StudentCheckInLayout>
  );
}
type JustStartCheckInProps = {
  start: () => void;
};
export function JustStartCheckIn({ start }: JustStartCheckInProps) {
  // `start` also redirects to the newly created check-in
  useEffect(start, []); // eslint-disable-line react-hooks/exhaustive-deps
  return <LoadingSpinner />;
}
export function isFirstCheckInOfThisType(
  checkIns: ReadonlyArray<{
    readonly type: LanternCheckInType;
  }>,
  checkInType: LanternCheckInType,
) {
  return checkIns.filter(checkIn => checkIn.type === checkInType).length === 0;
}
