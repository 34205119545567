import { css } from '@emotion/css';
import { find, isEmpty } from 'ramda';
import { useEffect, useState } from 'react';
import { graphql, useMutation } from 'react-relay';

import { withSnackbar, type SnackbarContext } from 'ms-components/Snackbar';
import SyllabusSelector from 'ms-components/SyllabusSelector';
import Table, { TableRow, TableCell } from 'ms-components/Table';
import PencilIcon from 'ms-components/icons/Pencil';
import PlusIcon from 'ms-components/icons/Plus';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { fontFamily, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import FieldGroup from 'ms-ui-primitives/FieldGroup';
import Input from 'ms-ui-primitives/Input';
import Modal, { ModalBody, ModalFooter } from 'ms-ui-primitives/Modal';
import Select from 'ms-ui-primitives/Select';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';
import { partialMask } from 'ms-utils/email';
import { noop } from 'ms-utils/misc';
import errorMessageFor, {
  type KeyedErrors,
} from 'ms-utils/misc/errorMessageFor';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import extractNode from 'ms-utils/relay/extractNode';

import type { SettingsPresentationalSetSelfReportedGradeMutation } from './__generated__/SettingsPresentationalSetSelfReportedGradeMutation.graphql';
import type { SettingsPresentationalUpdateStudentLeaderboardClassMutation } from './__generated__/SettingsPresentationalUpdateStudentLeaderboardClassMutation.graphql';
import type { SettingsPresentationalUpdateUserMutation } from './__generated__/SettingsPresentationalUpdateUserMutation.graphql';
import type { SettingsPresentational_createManageSubscriptionMutation } from './__generated__/SettingsPresentational_createManageSubscriptionMutation.graphql';
import AddOnSubscriptionDetails from '../AddOnSubscriptionDetails';
import GradeLevelSelectForm from '../GradeLevelSelectForm';
import SectionTitle from '../SectionTitle';
import SubscriptionDetails from '../SubscriptionDetails';
import SubsectionTitle from '../SubsectionTitle';
import type {
  User,
  Syllabus,
  AvailableSyllabuses,
  AvailableTimezones,
  Grades,
  SelfReportedGrade,
} from '../types';

type Props = SnackbarContext & {
  user: User;
  availableSyllabuses: AvailableSyllabuses;
  availableTimezones: AvailableTimezones;
  onChangePasswordClick: () => void;
  // Lantern-specific fields
  grades: Grades | null | undefined;
  selfReportedGrade: SelfReportedGrade | null | undefined;
};
type LeaderboardClassState = { errorMessage: string | null };
type StudentProfile = Extract<
  Props['user']['profile'],
  { __typename: 'Student' }
>;
const extractGuardianEmailsFromProfile = (
  user: Omit<User, ' $fragmentRefs'>,
) => {
  return user.profile?.__typename === 'Student'
    ? user.profile?.guardians
        .filter(g => g.receiveNotifications)
        .map(g => g.email)
        .join(', ')
    : '';
};
function SettingsPresentational(props: Props) {
  const {
    availableTimezones,
    availableSyllabuses,
    enqueueMessage,
    onChangePasswordClick,
    grades,
    selfReportedGrade,
  } = props;

  const [
    commitCreateManageSubscriptionMutation,
    manageSubscriptionUrlIsLoading,
  ] = useMutation<SettingsPresentational_createManageSubscriptionMutation>(
    graphql`
      mutation SettingsPresentational_createManageSubscriptionMutation(
        $backUrl: String!
      ) {
        createManageSubscription(backUrl: $backUrl) {
          url
        }
      }
    `,
  );

  const [commitUpdateUserMutation, isUpdateUserInFlight] =
    useMutation<SettingsPresentationalUpdateUserMutation>(graphql`
      mutation SettingsPresentationalUpdateUserMutation(
        $pk: Int!
        $user: UpdateUserInput
        $numClasses: Int
      ) {
        updateUser(pk: $pk, user: $user) {
          user {
            ...SettingsContainerFragment @relay(mask: false)
          }
          errors {
            key
            message
          }
        }
      }
    `);

  const [
    commitUpdateSelfReportedGradeMutation,
    isUpdateSelfReportedGradeInFlight,
  ] = useMutation<SettingsPresentationalSetSelfReportedGradeMutation>(graphql`
    mutation SettingsPresentationalSetSelfReportedGradeMutation($gradeId: ID!) {
      setSelfReportedGrade(gradeId: $gradeId) {
        selfReportedGrade {
          id
          title
        }
      }
    }
  `);

  const [
    updateStudentLeaderboardClassMutation,
    isUpdateStudentLeaderboardClassInFlight,
  ] = useMutation<SettingsPresentationalUpdateStudentLeaderboardClassMutation>(
    graphql`
      mutation SettingsPresentationalUpdateStudentLeaderboardClassMutation(
        $studentId: ID!
        $classId: ID
      ) {
        updateStudentLeaderboardClass(
          studentId: $studentId
          classId: $classId
        ) {
          errors {
            key
            message
          }
        }
      }
    `,
  );

  const [user, setUser] = useState(props.user);
  const [isGradeModalOpen, setIsGradeModalOpen] = useState(false);
  const [hasGradeError, setHasGradeError] = useState(false);
  const [isParentModalOpen, setIsParentModalOpen] = useState(false);
  const [parentForm, setParentForm] = useState({
    guardianEmails: extractGuardianEmailsFromProfile(props.user),
  });
  const [syllabusIsOpen, setSyllabusIsOpen] = useState(false);
  const [errors, setErrors] = useState<KeyedErrors>([]);
  const [, setSnackbarToShow] = useState<'parents' | 'syllabus' | undefined>(
    undefined,
  );
  const [manageSubscriptionUrl, setManageSubscriptionUrl] = useState<
    string | null
  >(null);
  const [leaderboardClassState, setLeaderboardClassState] =
    useState<LeaderboardClassState>({
      errorMessage: null,
    });
  const { refetch: refetchViewer } = useViewer();
  if (user == null) {
    throw new InvariantViolation(
      '<Settings /> should never return GraphQL responses without a user set!',
    );
  }
  const { activeSubscription, addOnSubscriptions } = user;

  useEffect(() => {
    if (!addOnSubscriptions.length) return;
    commitCreateManageSubscriptionMutation({
      variables: { backUrl: window.location.href },
      onCompleted: response => {
        const url = response.createManageSubscription?.url ?? null;
        setManageSubscriptionUrl(url);
      },
      // TODO: Add error handling/retry logic
    });
  }, [addOnSubscriptions.length, commitCreateManageSubscriptionMutation]);
  const isClassLinkOrClever =
    user.userType === 'student.classlink' || user.userType === 'student.clever';
  const isExternalApplicationUser =
    user.userType === 'user.external_application';
  const availableTimezonesOptions = availableTimezones.map(timezone => ({
    label: timezone.displayName,
    value: timezone.name,
  }));
  const userProfile = user.profile;
  const student = userProfile?.__typename === 'Student' ? userProfile : null;
  // We only want to show guardians that have subscribed to notifications
  const subscribedGuardians = (student?.guardians ?? []).filter(
    g => g.receiveNotifications,
  );
  const hasSubscribedGuardians = subscribedGuardians.length > 0;
  const classes = student != null ? extractNode(student.classes) : null;
  const leaderboardClass = student?.leaderboardClass?.class ?? null;
  const leaderboardClassOptions = classes
    ?.filter(c => c.hasEnabledLeaderboard)
    .map(c => ({
      label: c.displayName ?? c.title,
      value: c.id,
    }));

  function setLeaderboardClass(
    student: StudentProfile,
    classId: string | null,
  ) {
    setUser({
      ...user,
      profile: {
        ...student,
        leaderboardClass: classId !== null ? { class: { id: classId } } : null,
      },
    });
  }

  return (
    <>
      <SectionTitle>General Settings</SectionTitle>
      <SubsectionTitle>Personal Details</SubsectionTitle>
      <FieldGroup title="Username">
        <Input onChange={noop} value={user.username} disabled />
      </FieldGroup>
      <FieldGroup title="First name">
        <Input
          value={user.firstName}
          onChange={e => setUser({ ...user, firstName: e.target.value })}
          errorMessage={errorMessageFor('firstName', errors)}
          disabled={user.userType.startsWith('student') || isUpdateUserInFlight}
          blockTracking
        />
      </FieldGroup>
      <FieldGroup title="Last name">
        <Input
          value={user.lastName}
          onChange={e => setUser({ ...user, lastName: e.target.value })}
          errorMessage={errorMessageFor('lastName', errors)}
          disabled={user.userType.startsWith('student') || isUpdateUserInFlight}
          blockTracking
        />
      </FieldGroup>
      <FieldGroup title="Email">
        <Input
          value={user.email}
          type="email"
          onChange={e => setUser({ ...user, email: e.target.value })}
          errorMessage={errorMessageFor('email', errors)}
          disabled={isClassLinkOrClever || isUpdateUserInFlight}
          blockTracking
        />
      </FieldGroup>
      <FieldGroup title="Password">
        <Button type="secondary" onClick={onChangePasswordClick}>
          Change password
        </Button>
      </FieldGroup>
      <FieldGroup title="Timezone">
        <Select
          block
          options={availableTimezonesOptions}
          value={user.timezone}
          onChange={value => setUser({ ...user, timezone: value })}
          disabled={isUpdateUserInFlight}
        />
      </FieldGroup>
      <Button
        type="primary"
        onClick={() => {
          if (isUpdateUserInFlight) return;

          if (user == null) {
            throw new InvariantViolation(
              '<Settings /> should never return GraphQL responses without a user set!',
            );
          }
          commitUpdateUserMutation({
            variables: {
              pk: user.pk,
              user: {
                firstName: user.firstName,
                lastName: user.lastName,
                // Students may have an empty string as an email, so we want to keep the email field optional.
                // However, it is not possible to clear the non-empty email with this form.
                // See `resolve_update_user` for more details.
                email:
                  props.user.email === '' && user.email === ''
                    ? null
                    : user.email,
                timezone: user.timezone,
              },
              numClasses: RELAY_CONNECTION_MAX,
            },
            onCompleted: response => {
              const mutationErrors = response.updateUser.errors ?? [];
              setErrors(mutationErrors);
              if (isEmpty(mutationErrors)) {
                enqueueMessage({ text: 'Personal details saved' });
              }
            },
            onError: error => {
              Logger.error(error, {
                tags: { component: 'Settings' },
              });
            },
          });
        }}
      >
        {isUpdateUserInFlight ? 'Saving...' : 'Save personal details'}
      </Button>
      {grades != null && selfReportedGrade != null && (
        <>
          <SubsectionTitle>Skills map</SubsectionTitle>
          <FieldGroup
            title="Your grade level"
            description="Your grade level will determine what skills are shown in your skills map and your check-ins."
          >
            <Button
              type="secondary"
              onClick={() => setIsGradeModalOpen(true)}
              label="Grade"
            >
              {selfReportedGrade.title}
            </Button>
            <GradeLevelSelectForm
              isOpen={isGradeModalOpen}
              hasError={hasGradeError}
              handleClose={() => setIsGradeModalOpen(false)}
              isSubmitting={isUpdateSelfReportedGradeInFlight}
              grades={grades}
              defaultGradeId={selfReportedGrade.id}
              onSubmit={selectedGradeId => {
                commitUpdateSelfReportedGradeMutation({
                  variables: {
                    gradeId: selectedGradeId,
                  },
                  onCompleted: response => {
                    if (response.setSelfReportedGrade != null) {
                      setIsGradeModalOpen(false);
                    } else {
                      setHasGradeError(true);
                    }
                  },
                  onError: error => {
                    Logger.error(error, {
                      tags: { component: 'Settings' },
                    });
                    setHasGradeError(true);
                  },
                });
              }}
            />
          </FieldGroup>
        </>
      )}
      {!user.userType.startsWith('parent') && !isExternalApplicationUser && (
        <div>
          <SubsectionTitle>Math Content Settings</SubsectionTitle>
          <FieldGroup
            title="Textbook focus"
            description="The content you will see throughout Mathspace is based on this setting"
          >
            <Button
              type="secondary"
              onClick={() => setSyllabusIsOpen(true)}
              label={user.syllabusFocus.title || 'Select a textbook'}
            >
              <PencilIcon size={20} />
              <span style={{ marginLeft: 5 }}>
                {user.syllabusFocus.title || 'Select a textbook'}
              </span>
            </Button>
            <SyllabusSelector
              isOpen={syllabusIsOpen}
              selection={user.syllabusFocus}
              syllabusList={availableSyllabuses}
              viewer={{
                cohortType: user.cohortType,
                accountType: user.accountType,
              }}
              onSelect={id => {
                commitUpdateUserMutation({
                  variables: {
                    pk: user.pk,
                    user: { syllabusFocusId: id },
                    numClasses: RELAY_CONNECTION_MAX,
                  },
                  onCompleted: response => {
                    const mutationErrors = response.updateUser.errors ?? [];
                    setErrors(mutationErrors);
                    setSnackbarToShow('syllabus');
                  },
                  onError: error => {
                    Logger.error(error, {
                      tags: { component: 'Settings' },
                    });
                  },
                });
                const syllabusFocus = find(
                  s => s.pk === id,
                  availableSyllabuses,
                ) as any as Syllabus; // a syllabus was selected from `data.availableSyllabuses`, then we are guaranteed to find it through its id
                setSyllabusIsOpen(false);
                setUser({
                  ...user,
                  syllabusFocus,
                });
              }}
              onClose={() => setSyllabusIsOpen(false)}
            />
          </FieldGroup>
        </div>
      )}

      {/* Gamification settings */}
      {student != null &&
        leaderboardClassOptions != null &&
        leaderboardClassOptions.length > 1 && (
          <>
            <SubsectionTitle>Gamification Settings</SubsectionTitle>
            <FieldGroup
              title="Leaderboard class"
              description="Looks like you&rsquo;re part of a few classes, so we need you to pick one for the leaderboard and gamification features."
            >
              <Select
                block
                options={leaderboardClassOptions}
                value={leaderboardClass?.id}
                onChange={value => {
                  const prevLeaderboardClass = leaderboardClass;
                  setLeaderboardClass(student, value);
                  setLeaderboardClassState({
                    errorMessage: null,
                  });
                  updateStudentLeaderboardClassMutation({
                    variables: { studentId: student.id, classId: value },
                    onCompleted: response => {
                      if (
                        response.updateStudentLeaderboardClass.errors.length > 0
                      ) {
                        setLeaderboardClass(
                          student,
                          prevLeaderboardClass?.id ?? null,
                        );
                        setLeaderboardClassState({
                          errorMessage:
                            errors.length === 1 && errors[0] != null
                              ? errors[0].message
                              : 'Something went wrong. Please try again.',
                        });
                        return;
                      }
                      setLeaderboardClass(student, value);
                      setLeaderboardClassState({
                        errorMessage: null,
                      });
                      refetchViewer();
                    },
                    onError: error => {
                      setLeaderboardClass(
                        student,
                        prevLeaderboardClass?.id ?? null,
                      );
                      setLeaderboardClassState({
                        errorMessage: error.message,
                      });
                      Logger.error(error, {
                        tags: { component: 'Settings' },
                      });
                    },
                  });
                }}
                disabled={isUpdateStudentLeaderboardClassInFlight}
              />

              {leaderboardClassState.errorMessage && (
                <div className={styles.errorMessage}>
                  {leaderboardClassState.errorMessage}
                </div>
              )}
            </FieldGroup>
          </>
        )}

      {student && (
        <div>
          <SubsectionTitle>Parents & Guardians</SubsectionTitle>
          <FieldGroup
            title="People following your progress"
            description="Parents and guardians following your progress will receive a weekly email with details about the tasks you've worked on and points you've earned."
          >
            <Table
              action={
                <Button
                  type="secondary"
                  onClick={() => setIsParentModalOpen(true)}
                  isBlock
                  label={`${
                    hasSubscribedGuardians ? 'Edit' : 'Add a'
                  } parent/guardian(s)`}
                >
                  <PlusIcon />
                  <span style={{ marginLeft: 5 }}>
                    {hasSubscribedGuardians ? 'Edit' : 'Add a'}{' '}
                    parent/guardian(s)
                  </span>
                </Button>
              }
            >
              {hasSubscribedGuardians ? (
                subscribedGuardians.map(guardian => (
                  <TableRow key={guardian.id}>
                    {guardian.firstName && (
                      <TableCell>
                        {guardian.firstName} {guardian.lastName}
                      </TableCell>
                    )}
                    <TableCell>{partialMask(guardian.email)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    You do not have anyone following your progress.
                  </TableCell>
                </TableRow>
              )}
            </Table>
            <Modal
              isOpen={isParentModalOpen}
              title={`${
                hasSubscribedGuardians ? 'Edit' : 'Add a'
              } parent/guardian(s)`}
              onClose={() => setIsParentModalOpen(false)}
            >
              <ModalBody>
                <FieldGroup
                  title="Email(s)"
                  description="Comma separated list of parent/guardian emails"
                >
                  <Input
                    value={parentForm.guardianEmails}
                    onChange={e =>
                      setParentForm({
                        ...parentForm,
                        guardianEmails: e.target.value,
                      })
                    }
                    type="email"
                    errorMessage={
                      errorMessageFor('INVALID_PARENT_EMAIL', errors) ||
                      errorMessageFor('INVALID_PARENT_EMAIL_LENGTH', errors) ||
                      errorMessageFor('TOO_MANY_GUARDIANS', errors)
                    }
                  />
                </FieldGroup>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setIsParentModalOpen(false)}>
                  Cancel
                </Button>
                <Button
                  isDisabled={isUpdateUserInFlight}
                  type="primary"
                  onClick={() => {
                    commitUpdateUserMutation({
                      variables: {
                        pk: user.pk,
                        user: {
                          guardianEmails: parentForm.guardianEmails
                            .split(',')
                            .map(e => e.trim())
                            .filter(e => e !== ''),
                        },
                        numClasses: RELAY_CONNECTION_MAX,
                      },
                      onCompleted: response => {
                        const mutationErrors = response.updateUser.errors ?? [];
                        setErrors(mutationErrors);
                        const userProfile = response.updateUser.user?.profile;
                        const student =
                          userProfile?.__typename === 'Student'
                            ? userProfile
                            : null;
                        if (!isEmpty(mutationErrors)) {
                        } else {
                          setIsParentModalOpen(false);
                          setSnackbarToShow('parents');
                          setUser({
                            ...user,
                            profile: {
                              ...user.profile,
                              // TODO redo the type modelling as things don't fit together.
                              // It is mis-assuming we are working with a student profile
                              // when that's not the case. The profile may be missing entirely.
                              // @ts-expect-error
                              guardians: student?.guardians,
                            },
                          });
                          setParentForm({
                            guardianEmails:
                              response.updateUser.user != null
                                ? extractGuardianEmailsFromProfile(
                                    response.updateUser.user,
                                  )
                                : '',
                          });
                        }
                      },
                      onError: error => {
                        Logger.error(error, {
                          tags: { component: 'Settings' },
                        });
                      },
                    });
                  }}
                >
                  {isUpdateUserInFlight
                    ? 'Updating...'
                    : `${
                        hasSubscribedGuardians ? 'Edit' : 'Add'
                      } parent/guardian(s)`}
                </Button>
              </ModalFooter>
            </Modal>
          </FieldGroup>
        </div>
      )}

      {(activeSubscription != null || addOnSubscriptions.length > 0) &&
        !isExternalApplicationUser && (
          <>
            <SectionTitle>Subscription Settings</SectionTitle>
            {activeSubscription != null && (
              <>
                <SubsectionTitle>Subscription</SubsectionTitle>
                <SubscriptionDetails subscriptionKey={activeSubscription} />
              </>
            )}
            {activeSubscription == null && addOnSubscriptions.length > 0 && (
              <VSpacer height={16} />
            )}
            <AddOnSubscriptionDetails
              subscriptionsKey={addOnSubscriptions}
              manageSubscriptionUrl={manageSubscriptionUrl}
              manageSubscriptionUrlIsLoading={manageSubscriptionUrlIsLoading}
            />
          </>
        )}

      {/* TODO leave commented until the Snackbar issues are fixed  */}
      {/* {(() => {
          // TODO if you try to render snackbars while there is already
          // one displaying things are breaking :(
          switch (path(['snackbarToShow'], data)) {
            case 'details': return (
              <Snackbar
                // key="details"
                text="Personal details saved"
                onDismiss={() => setState({ snackbarToShow: undefined })}
              />
            );
            case 'syllabus': return (
              <Snackbar
                // key="syllabus"
                text="Textbook focus saved"
                onDismiss={() => setState({ snackbarToShow: undefined })}
              />
            );
            case 'parents': return (
              <Snackbar
                // key="parents"
                text="New parent/guardian added"
                onDismiss={() => setState({ snackbarToShow: undefined })}
              />
            );
            default: return null;
          }
        })()} */}
    </>
  );
}
export default withSnackbar(SettingsPresentational);

const styles = {
  errorMessage: css({
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    marginTop: 8,
    textAlign: 'right',
  }),
} as const;
