/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type CheckInCompleteQueryVariables = {
    checkInId: string;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type CheckInCompleteQueryResponse = {
    readonly lantern: {
        readonly checkIn: {
            readonly type: LanternCheckInType;
            readonly numberOfQuestions: number;
            readonly questionsCompleted: number;
            readonly questionsCorrect: number;
            readonly endedAt: string | null;
            readonly startedAt: string;
            readonly strand: {
                readonly id: string;
                readonly title: string;
                readonly substrands: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                    readonly badgeUrl: string;
                    readonly strand: {
                        readonly id: string;
                    };
                    readonly gradeSubstrands: ReadonlyArray<{
                        readonly id: string;
                        readonly gradeStrand: {
                            readonly grade: {
                                readonly id: string;
                                readonly order: number;
                            };
                        };
                        readonly outcomes: ReadonlyArray<{
                            readonly id: string;
                            readonly title: string;
                        }>;
                    }>;
                }>;
            } | null;
            readonly substrand: {
                readonly id: string;
                readonly badgeUrl: string;
                readonly title: string;
                readonly strand: {
                    readonly id: string;
                };
            } | null;
            readonly gradedSkill: {
                readonly skill: {
                    readonly id: string;
                    readonly title: string;
                    readonly substrand: {
                        readonly id: string;
                        readonly title: string;
                        readonly badgeUrl: string;
                    };
                    readonly outcome: {
                        readonly id: string;
                        readonly gradeSubstrand: {
                            readonly substrand: {
                                readonly id: string;
                                readonly strand: {
                                    readonly id: string;
                                };
                            };
                        };
                    };
                };
                readonly proficiencyAfter: number;
            } | null;
            readonly skillsDemonstrated: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
            readonly skillsLevelledUp: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
        } | null;
    };
};
export type CheckInCompleteQuery = {
    readonly response: CheckInCompleteQueryResponse;
    readonly variables: CheckInCompleteQueryVariables;
};



/*
query CheckInCompleteQuery(
  $checkInId: ID!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    checkIn(id: $checkInId) {
      type
      numberOfQuestions
      questionsCompleted
      questionsCorrect
      endedAt
      startedAt
      strand {
        id
        title
        substrands {
          id
          title
          badgeUrl
          strand {
            id
          }
          gradeSubstrands {
            id
            gradeStrand {
              grade {
                id
                order
              }
              id
            }
            outcomes {
              id
              title
            }
          }
        }
      }
      substrand {
        id
        badgeUrl
        title
        strand {
          id
        }
      }
      gradedSkill(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
        skill {
          id
          title
          substrand {
            id
            title
            badgeUrl
          }
          outcome {
            id
            gradeSubstrand {
              substrand {
                id
                strand {
                  id
                }
              }
              id
            }
          }
        }
        proficiencyAfter
      }
      skillsDemonstrated {
        id
        title
      }
      skillsLevelledUp(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
        id
        title
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkInId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "checkInId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfQuestions",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionsCompleted",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionsCorrect",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "badgeUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "grade",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  (v10/*: any*/),
  (v11/*: any*/)
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternOutcome",
  "kind": "LinkedField",
  "name": "outcomes",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v12/*: any*/),
    (v11/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v18 = [
  {
    "kind": "Variable",
    "name": "growthPeriod",
    "variableName": "growthPeriod"
  },
  {
    "kind": "Variable",
    "name": "previewingWithProblemData",
    "variableName": "previewingWithProblemData"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "proficiencyAfter",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skillsDemonstrated",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": (v18/*: any*/),
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skillsLevelledUp",
  "plural": true,
  "selections": (v15/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Strand",
                "kind": "LinkedField",
                "name": "strand",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Substrand",
                    "kind": "LinkedField",
                    "name": "substrands",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GradeSubstrand",
                        "kind": "LinkedField",
                        "name": "gradeSubstrands",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GradeStrand",
                            "kind": "LinkedField",
                            "name": "gradeStrand",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "GradedSkill",
                "kind": "LinkedField",
                "name": "gradedSkill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "skill",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternOutcome",
                        "kind": "LinkedField",
                        "name": "outcome",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GradeSubstrand",
                            "kind": "LinkedField",
                            "name": "gradeSubstrand",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheckInCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Strand",
                "kind": "LinkedField",
                "name": "strand",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Substrand",
                    "kind": "LinkedField",
                    "name": "substrands",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GradeSubstrand",
                        "kind": "LinkedField",
                        "name": "gradeSubstrands",
                        "plural": true,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GradeStrand",
                            "kind": "LinkedField",
                            "name": "gradeStrand",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v17/*: any*/),
              {
                "alias": null,
                "args": (v18/*: any*/),
                "concreteType": "GradedSkill",
                "kind": "LinkedField",
                "name": "gradedSkill",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Skill",
                    "kind": "LinkedField",
                    "name": "skill",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LanternOutcome",
                        "kind": "LinkedField",
                        "name": "outcome",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "GradeSubstrand",
                            "kind": "LinkedField",
                            "name": "gradeSubstrand",
                            "plural": false,
                            "selections": [
                              (v20/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v22/*: any*/),
              (v23/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b3eca456bc03fdd5ffc045e5f71fbec",
    "id": null,
    "metadata": {},
    "name": "CheckInCompleteQuery",
    "operationKind": "query",
    "text": "query CheckInCompleteQuery(\n  $checkInId: ID!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    checkIn(id: $checkInId) {\n      type\n      numberOfQuestions\n      questionsCompleted\n      questionsCorrect\n      endedAt\n      startedAt\n      strand {\n        id\n        title\n        substrands {\n          id\n          title\n          badgeUrl\n          strand {\n            id\n          }\n          gradeSubstrands {\n            id\n            gradeStrand {\n              grade {\n                id\n                order\n              }\n              id\n            }\n            outcomes {\n              id\n              title\n            }\n          }\n        }\n      }\n      substrand {\n        id\n        badgeUrl\n        title\n        strand {\n          id\n        }\n      }\n      gradedSkill(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n        skill {\n          id\n          title\n          substrand {\n            id\n            title\n            badgeUrl\n          }\n          outcome {\n            id\n            gradeSubstrand {\n              substrand {\n                id\n                strand {\n                  id\n                }\n              }\n              id\n            }\n          }\n        }\n        proficiencyAfter\n      }\n      skillsDemonstrated {\n        id\n        title\n      }\n      skillsLevelledUp(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n        id\n        title\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd2193478c67609c3883d6b902a6204f3';
export default node;
