export const PAGE_PADDING = 32;
export const LARGE_PAGE_PADDING = 72;
export const MOBILE_PAGE_PADDING = 16;

export const colors = {
  // Old colors
  // grayscale
  white: '#FFFFFF',
  black: '#000000',
  iron: '#D4D7D9',
  grayChateau: '#99A4AF',
  shuttleGray: '#5E6D7F',
  cloudBurst: '#21374D',
  seashell: '#F8F8F8',
  // error
  cinnabar: '#E74C3C',
  // purples
  snuff: '#E1E2F1',
  // greens
  mountainMeadow: '#1ABC9C',

  // Brand colours
  almond: '#E0DBCF',
  almond60: '#F3EDDF',
  almond50: '#F9F5EE',
  grey: '#3E3E4C',
  grey90: '#6F6F82',
  grey10: '#C7C5C6',
  peachPink: '#D25564',
  peachPink90: '#E18C8A',
  peachPink20: '#F0CDC9',
  peachPink10: '#F1E8E5',

  // Supporting colours
  rose: '#CB7E6B',
  rose20: '#F0CEC6',
  wood: '#774D46',
  wood90: '#AD825E',
  gold: '#DFA830',
  gold90: '#E6BF80',
  gold50: '#E6CFA3',
  gold20: '#EADBBD',
  pine: '#4A7068',
  pine90: '#6C8680',
  pine50: '#8AB4AA',
  pine20: '#A4C0BB',
  pine10: '#D9DBD4',
  eggplant: '#5D51AE',
  eggplant90: '#8C83C7',
  eggplant20: '#D7D6E8',
  eggplant15: '#CFCEE3',
  eggplant10: '#EFEEF7',

  // Mastery tile colours
  deepKoamaru: '#121966',
  governorBay: '#3B4DBA',
  melrose: '#AAB6FE',
  linkWater: '#D6DBF4',
  porcelain: '#F1F2F3',
};

export const PAGE_BACKGROUND_COLOR = colors.almond50;

// It doesn't fix all the possible issues on all browsers or devices, feel free to add rules
export const tappable = {
  cursor: 'pointer',
  userSelect: 'none', // to prevent the text selection (e.g. selecting text triggers the copy-paste popup menu on iOS and Android)
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', // to prevent the default tap highlight colour being applied
  WebkitTouchCallout: 'none', // to prevent the context menu from showing, at least on iOS for anchor elements
} as const;

const fontFamily = {
  body: '"Proxima Nova", Helvetica, Arial, sans-serif',
  feature: 'Gilroy, Avenir, system-ui, Arial, sans-serif',
};

// Font weights should match the ones in `static/css/fonts.css`
export const fonts = {
  body: {
    fontFamily: fontFamily.body,
    fontWeight: 400,
  },
  bodyBold: {
    fontFamily: fontFamily.body,
    fontWeight: 600,
  },
  featureBold: {
    fontFamily: fontFamily.feature,
    fontWeight: 800,
  },
};

export const breakpoints = {
  medium: 768,
  large: 1024,
};

export const zIndex = {
  modal: 43,
  popoverOverlay: 40,
  popover: 41,
  dropdownMenu: 41,
  dropdownMenuItem: 42,
  // `CartesianPlane` from `jsx-content` has children that use `z-index: 1` and `z-index: 2`
  // TODO: Need a better solution in `jsx-content` package
  // or JSX Content wrapper? (consider using `isolation` property)
  checkInActions: 2,
  checkInFeedbackForm: 2,
};

export type Color = keyof typeof colors;
