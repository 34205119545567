import styled from '@emotion/styled';
import { useFragment, graphql } from 'relay-hooks';

import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import TextbookTeacherIcon from 'ms-components/icons/TextbookTeacher';
import { useSnowplow } from 'ms-helpers/Snowplow';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  useGetBadgeBackground,
} from 'ms-pages/Lantern/components/Card';
import type { SubstrandSubtopicRecommendationCategory } from 'ms-pages/Textbooks/StudentTextbook/components/SelfDirectedTaskRedirector/__generated__/useCreateSelfDirectedTaskMutation.graphql';
import { colors } from 'ms-styles/colors';
import { HStack, HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import {
  getContentBrowsingSubtopicDetailViewUrl,
  getSelfDirectedAdaptiveTaskUrl,
} from 'ms-utils/urls';

import type { OutcomeSubtopicRecommendationCard_subtopic$key } from './__generated__/OutcomeSubtopicRecommendationCard_subtopic.graphql';
import {
  mapCategoryToMessage,
  RecommendationCategoryButton,
} from '../SubtopicRecommendations/SubtopicRecommendationCard/SubtopicRecommendationCard';

type Props = {
  subtopic: OutcomeSubtopicRecommendationCard_subtopic$key;
  category?: SubstrandSubtopicRecommendationCategory | undefined;
  isNotInteractive?: boolean | undefined;
};
// This is a hack: we reuse `RecommendationCategoryButton` for styling,
// however we don't need it to be interactive.
const RecommendationCategoryButtonWrapper = styled.div({
  pointerEvents: 'none',
});
const SUBTOPIC_RECOMMENDATION_CARD_SUBSTRAND_SUBTOPIC_RECOMMENDATION_FRAGMENT = graphql`
  fragment OutcomeSubtopicRecommendationCard_subtopic on Subtopic {
    id
    title
    topic {
      id
      badgeUrl
      badgeBackground
      syllabus {
        id
      }
    }
    hasAdaptivePractice
    gamifiedMastery
  }
`;
export default function OutcomeSubtopicRecommendationCard({
  subtopic: subtopicRef,
  category = 'PRIOR_SKILLS_MASTERED',
  isNotInteractive = false,
}: Props) {
  const subtopic = useFragment(
    SUBTOPIC_RECOMMENDATION_CARD_SUBSTRAND_SUBTOPIC_RECOMMENDATION_FRAGMENT,
    subtopicRef,
  );
  const {
    id: subtopicId,
    title,
    topic: {
      id: topicId,
      badgeUrl,
      badgeBackground,
      syllabus: { id: syllabusId },
    },
    hasAdaptivePractice,
    gamifiedMastery,
  } = subtopic;
  const { trackStructEvent } = useSnowplow();
  const url = hasAdaptivePractice
    ? getSelfDirectedAdaptiveTaskUrl({
        subtopicId,
        isSubtopicRecommendation: true,
        recommendationCategory: 'PRIOR_SKILLS_MASTERED',
      })
    : getContentBrowsingSubtopicDetailViewUrl({
        syllabusId,
        topicId,
        subtopicId,
      });
  return (
    <>
      <Card
        isNotInteractive={isNotInteractive}
        href={url}
        backgroundOnHover="seashell"
        onClick={() => {
          trackStructEvent({
            category: 'student_skills_map',
            action: 'started_recommendation',
            label: 'skill_popover',
          });
        }}
      >
        <CardHeader
          style={{
            backgroundImage: `url(${badgeUrl})`,
            backgroundColor: useGetBadgeBackground({
              badgeUrl,
              badgeBackground,
            }),
          }}
        />
        <CardBody>
          <CardTitle title={title} />
          <VSpacer height={8} grow />
          <HStack center>
            <RecommendationCategoryButtonWrapper>
              <RecommendationCategoryButton
                title={mapCategoryToMessage(category)}
              />
            </RecommendationCategoryButtonWrapper>
            <HSpacer width={8} grow />
            <HStack center>
              {hasAdaptivePractice ? (
                <MasteryLevelVolumeIndicator
                  inAssignedTaskCard
                  masteryLevel={gamifiedMastery}
                  showTooltip
                />
              ) : (
                <TextbookTeacherIcon size={24} color={colors.grey90} />
              )}
            </HStack>
          </HStack>
        </CardBody>
      </Card>
    </>
  );
}
