import { Katex } from 'ms-components/Katex';
import MathContent from 'ms-components/math/MathContent';
import { ChapterLink } from 'ms-components/math/MathContent/ChapterLink';
import type { NodeRendererMap } from 'ms-components/math/MathContent/renderNode';

type SoupHtml = string;

// Precondition: chapterContent comes from a Chapter whose chapterType field
// is equal to 'INVESTIGATION'.
export function InvestigationChapter({
  chapterContent,
  nodeRenderers,
}: {
  chapterContent: SoupHtml;
  nodeRenderers?: Partial<NodeRendererMap> | undefined;
}) {
  return (
    <MathContent
      content={chapterContent}
      nodeRenderers={{
        CHAPTER_LINK: ChapterLink,
        // Investigation chapter entries are being phased out. They are being
        // replaced by a new jsx-content document called Investigation. During
        // the transition phase, there is a small amount of chapter entries
        // which cannot yet be converted to the new Investigation document.
        // One of pressing requirements for migrating to jsx-content is that
        // the documents are accessible to screenreaders. One of the big pieces
        // of this is that all jsx-content is written using latex for
        // consumption by Katex; which is screen-reader-accessible. MathQuill,
        // however, is not. To meet the accessibility requirement, for this
        // small set of investigations which will remain as chapter entries,
        // we will be switching out MathQuill for Katex when rendering static
        // latex expressions encoded in SoupHtml. All the latex will be
        // manually reviewed to ensure its Katex-compatiable (MathQuill latex
        // is not broadly Katex-compatible). This is why the Katex replacment
        // is locally-scoped to only these investigations as wholesale replacing
        // MathQuill with Katex will break the majority of SoupHtml-encoded latex.
        LATEX_EXPRESSION: Katex,
        ...nodeRenderers,
      }}
    />
  );
}
