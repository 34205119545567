import { useMutation, graphql } from 'relay-hooks';

import type { useEditTaskDatesMutation } from './__generated__/useEditTaskDatesMutation.graphql';

const mutation = graphql`
  mutation useEditTaskDatesMutation(
    $dueDate: DateTime!
    $startDate: DateTime!
    $taskId: ID!
  ) {
    editTaskDates(dueDate: $dueDate, startDate: $startDate, taskId: $taskId) {
      errors {
        key
        message
      }
      task {
        id
        title
        startDate
        dueDate
        expiryDate
      }
    }
  }
`;
export default function useEditTaskDates() {
  return useMutation<useEditTaskDatesMutation>(mutation);
}
