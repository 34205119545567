import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import Star from 'ms-components/icons/Star';
import { TooltipContent } from 'ms-pages/Teacher/components/Tooltip';
import { colors } from 'ms-styles/colors';
import { VSpacer } from 'ms-ui-primitives/Stack';
import Tooltip from 'ms-ui-primitives/Tooltip';
import {
  EXPLORING_SCORE,
  FAMILIAR_SCORE,
  PROFICIENT_SCORE,
  MASTERED_SCORE,
  EMERGING_SCORE,
  NOT_STARTED_SCORE,
  getLastMasteryLevelScore,
  getMasteryLevelTitle,
} from 'ms-utils/masteryLevel';
import { unwrap } from 'ms-utils/typescript-utils';

type IndicatorContainerProps = {
  'data-mastery': number;
  isDisabled?: boolean | undefined;
  alignLeft?: boolean | undefined;
  alignRight?: boolean | undefined;
  noMargin?: boolean | undefined;
  children: ReactNode;
};

const IndicatorContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' &&
    prop !== 'alignLeft' &&
    prop !== 'alignRight' &&
    prop !== 'noMargin',
})<IndicatorContainerProps>(({ alignLeft, alignRight, noMargin }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: alignRight ? 'flex-start' : alignLeft ? 'flex-end' : 'center',
  margin: noMargin ? 0 : '0px 8px',
}));

const INDICATOR_WIDTH = 14;
const INDICATOR_WIDTH_IN_ASSIGNED_TASK_CARD = 11.67;

const INDICATOR_LATERAL_MARGIN = 2;
const INDICATOR_LATERAL_MARGIN_IN_ASSIGNED_TASK_CARD = 1;

const INDICATOR_SMALL_HEIGHT = 10;
const INDICATOR_MEDIUM_HEIGHT = 14;
const INDICATOR_TALL_HEIGHT = 18;
const INDICATOR_SMALL_HEIGHT_IN_ASSIGNED_TASK_CARD = 6.7;
const INDICATOR_MEDIUM_HEIGHT_IN_ASSIGNED_TASK_CARD = 12.17;
const INDICATOR_TALL_HEIGHT_IN_ASSIGNED_TASK_CARD = 15.65;

type IndicatorProps = {
  isDisabled?: boolean | undefined;
  outline?: boolean | undefined;
  inAssignedTaskCard?: boolean | undefined;
  size: 'small' | 'medium' | 'tall';
};
const Indicator = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isDisabled' &&
    prop !== 'size' &&
    prop !== 'outline' &&
    prop !== 'inAssignedTaskCard',
})<IndicatorProps>(({ inAssignedTaskCard, outline, isDisabled, size }) => ({
  display: 'block',
  width: inAssignedTaskCard
    ? INDICATOR_WIDTH_IN_ASSIGNED_TASK_CARD
    : INDICATOR_WIDTH,
  background: outline
    ? 'transparent'
    : isDisabled
    ? colors.iron
    : colors.eggplant,
  margin: `0px ${
    inAssignedTaskCard
      ? INDICATOR_LATERAL_MARGIN_IN_ASSIGNED_TASK_CARD
      : INDICATOR_LATERAL_MARGIN
  }px`,
  borderRadius: '2px',
  border: outline ? '2px solid' : 'none',
  borderColor: outline ? colors.iron : 'none',
  height:
    size === 'small'
      ? inAssignedTaskCard
        ? INDICATOR_SMALL_HEIGHT_IN_ASSIGNED_TASK_CARD
        : INDICATOR_SMALL_HEIGHT
      : size === 'medium'
      ? inAssignedTaskCard
        ? INDICATOR_MEDIUM_HEIGHT_IN_ASSIGNED_TASK_CARD
        : INDICATOR_MEDIUM_HEIGHT
      : size === 'tall'
      ? inAssignedTaskCard
        ? INDICATOR_TALL_HEIGHT_IN_ASSIGNED_TASK_CARD
        : INDICATOR_TALL_HEIGHT
      : 0, // cannot get here as size is either 'small', 'medium' or 'tall'
}));

const Stack = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const TooltipText = styled.span({
  color: colors.white,
});

const MasteryLevelTitleWrapper = styled.span({
  display: 'inline-block',
  textDecoration: 'underline',
});

function getBarMapping(masteryLevel: number) {
  const threshhold = getLastMasteryLevelScore(masteryLevel);
  const mapping = {
    [MASTERED_SCORE]: {
      bars: [true, true, true],
      star: true,
      outline: false,
    },
    [PROFICIENT_SCORE]: {
      bars: [true, true, true],
      star: false,
      outline: false,
    },
    [FAMILIAR_SCORE]: {
      bars: [true, true, false],
      star: false,
      outline: false,
    },
    [EMERGING_SCORE]: {
      bars: [true, false, false],
      star: false,
      outline: false,
    },
    [EXPLORING_SCORE]: {
      bars: [false, false, false],
      star: false,
      outline: false,
    },
    [NOT_STARTED_SCORE]: {
      bars: [false, false, false],
      star: false,
      outline: true,
    },
  };
  return unwrap(mapping[threshhold]);
}

type Props = {
  masteryLevel: number;
  alignRight?: boolean | undefined;
  noMargin?: boolean | undefined;
  // TODO: swap this out for an align property if required.
  alignLeft?: boolean | undefined;
  inAssignedTaskCard?: boolean;
  showTooltip?: boolean;
};

// TODO: Replace this whole thing with consolidated mastery levels maybe. Don't take numbers.
function MasteryLevelVolumeIndicator({
  masteryLevel,
  alignRight,
  alignLeft,
  noMargin,
  inAssignedTaskCard = false,
  showTooltip = false,
}: Props) {
  const mapping = getBarMapping(masteryLevel);
  const content = (
    <IndicatorContainer
      data-mastery={masteryLevel}
      alignRight={alignRight}
      alignLeft={alignLeft}
      noMargin={noMargin || inAssignedTaskCard}
    >
      <Indicator
        outline={mapping.outline}
        size="small"
        isDisabled={!mapping.bars[0]}
        inAssignedTaskCard={inAssignedTaskCard}
      />
      <Indicator
        outline={mapping.outline}
        size="medium"
        isDisabled={!mapping.bars[1]}
        inAssignedTaskCard={inAssignedTaskCard}
      />
      <Stack>
        {mapping.star && (
          <>
            <Star
              color={colors.saffron}
              size={inAssignedTaskCard ? 10.5 : '1em'}
            />
            {inAssignedTaskCard && <VSpacer height={1} />}
          </>
        )}
        <Indicator
          outline={mapping.outline}
          size="tall"
          isDisabled={!mapping.bars[2]}
          inAssignedTaskCard={inAssignedTaskCard}
        />
      </Stack>
    </IndicatorContainer>
  );

  return showTooltip ? (
    <Tooltip
      renderTooltipContent={() => (
        <TooltipContent>
          <TooltipText>
            Mastery level:{' '}
            <MasteryLevelTitleWrapper>
              {getMasteryLevelTitle(masteryLevel)}
            </MasteryLevelTitleWrapper>
          </TooltipText>
        </TooltipContent>
      )}
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
}

export { MasteryLevelVolumeIndicator };
