/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type StudentSkillsMapSubstrandRecommendationsFragment = {
    readonly lantern: {
        readonly substrand: {
            readonly strand: {
                readonly id: string;
            };
            readonly studentSubtopicRecommendations: ReadonlyArray<{
                readonly id: string;
                readonly category: SubstrandSubtopicRecommendationCategory;
                readonly " $fragmentRefs": FragmentRefs<"SubtopicRecommendationCard_substrandSubtopicRecommendation">;
            }>;
        } | null;
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly strandStatus: {
                readonly status: StudentStrandStatusEnum;
                readonly lastWorkedOnDiagnosticCheckIn: {
                    readonly id: string;
                } | null;
                readonly strand: {
                    readonly id: string;
                };
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
    readonly " $refType": "StudentSkillsMapSubstrandRecommendationsFragment";
};
export type StudentSkillsMapSubstrandRecommendationsFragment$data = StudentSkillsMapSubstrandRecommendationsFragment;
export type StudentSkillsMapSubstrandRecommendationsFragment$key = {
    readonly " $data"?: StudentSkillsMapSubstrandRecommendationsFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandRecommendationsFragment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "strandId"
    },
    {
      "kind": "RootArgument",
      "name": "substrandId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandRecommendationsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Lantern",
      "kind": "LinkedField",
      "name": "lantern",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "substrandId"
            }
          ],
          "concreteType": "Substrand",
          "kind": "LinkedField",
          "name": "substrand",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SubstrandSubtopicRecommendation",
              "kind": "LinkedField",
              "name": "studentSubtopicRecommendations",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "category",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SubtopicRecommendationCard_substrandSubtopicRecommendation"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "viewer",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "strandId",
                      "variableName": "strandId"
                    }
                  ],
                  "concreteType": "StudentStrandStatus",
                  "kind": "LinkedField",
                  "name": "strandStatus",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CheckIn",
                      "kind": "LinkedField",
                      "name": "lastWorkedOnDiagnosticCheckIn",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "LanternStudent",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '48219bd72d97f5128168e71fb2e8b668';
export default node;
