import { useCallback } from 'react';

import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import { Asterisk } from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import FolderSelector from 'ms-pages/Teacher/components/CreateTaskTemplate/components/FolderSelector';
import type { CombinedState } from 'ms-pages/Teacher/components/CreateTaskTemplate/state';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import TextArea from 'ms-ui-primitives/TextArea';
import { styled } from 'ms-utils/emotion';

import TaskNameInput from '../CreateTask/components/TaskNameInput';
import type { SelectedFolderId } from '../CreateTask/types';

const FieldWrapper = styled({
  fontFamily: fontFamily.body,
  marginBottom: 6 * BASE_UNIT,
});

const FieldLabel = styled({
  fontWeight: fontWeight.semibold,
  fontSize: fontSize.medium,
  color: colors.neutralGray,
  marginBottom: 4 * BASE_UNIT,
});

type Props = {
  state: CombinedState;
  templateAuthorName?: string | undefined;
};

export function CreateTaskTemplate({ state, templateAuthorName }: Props) {
  const { values, updaters } = state;
  const { schoolId } = useMaybeTeacherContext();

  const onUpdateTemplateGroupId = useCallback(
    (id: SelectedFolderId) =>
      updaters.setTaskTemplateGroupIds(id != null ? [id] : []),
    [updaters],
  );

  return (
    <>
      {templateAuthorName != null && (
        <FieldWrapper>
          <FieldLabel>Template created by {templateAuthorName}</FieldLabel>
        </FieldWrapper>
      )}
      <FieldWrapper>
        <FieldLabel>
          Template name<Asterisk> *</Asterisk>
        </FieldLabel>
        <TaskNameInput
          value={values.title}
          onChange={updaters.setTaskTemplateTitle}
          type="template"
        />
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>Template description</FieldLabel>
        <TextArea
          isMultiline
          autoResize
          minHeight={100}
          value={values.description}
          onChange={value => {
            updaters.setTaskTemplateDescription(value);
          }}
        />
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>Add to template folder</FieldLabel>
        <FolderSelector
          templateGroupId={values.taskTemplateGroupIds[0]}
          updateTemplateGroupId={onUpdateTemplateGroupId}
          schoolId={schoolId}
        />
      </FieldWrapper>
    </>
  );
}
