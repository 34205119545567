import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { styled } from 'ms-utils/emotion';

const TextOverflow = styled({
  default: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textDecoration: 'inherit', // for the link's underline to work
  },
  insideTableCell: {
    position: 'absolute',
  },
});

const TextOverflowWrapperForTableCell = styled({
  position: 'relative',
  textDecoration: 'inherit', // for the link's underline to work
});

type Props = {
  children: ReactNode;
  maxWidth?: number | string | undefined;
  isInsideTableCell?: boolean | undefined;
};

const TruncateText = ({ children, maxWidth, isInsideTableCell }: Props) => {
  const style = useMemo(
    () => ({
      maxWidth,
    }),
    [maxWidth],
  );
  return isInsideTableCell ? (
    <TextOverflowWrapperForTableCell>
      <TextOverflow insideTableCell style={style}>
        {children}
      </TextOverflow>
      {/* Make it possible to correctly align absolutely positioned content */}
      &nbsp;
    </TextOverflowWrapperForTableCell>
  ) : (
    <TextOverflow style={style}>{children}</TextOverflow>
  );
};

export default TruncateText;
