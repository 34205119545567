import { graphql } from 'relay-hooks';

import useMutation from 'ms-utils/relay/useMutationWithArgs';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';

import type {
  useUpdateUserTextbookFocusMutationVariables,
  useUpdateUserTextbookFocusMutationResponse,
} from './__generated__/useUpdateUserTextbookFocusMutation.graphql';

const mutation = graphql`
  mutation useUpdateUserTextbookFocusMutation(
    $userPk: Int!
    $user: UpdateUserInput!
  ) {
    updateUser(pk: $userPk, user: $user) {
      user {
        syllabusFocus {
          id
          title
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useUpdateUserTextbookFocus(): UseMutationResponse<
  useUpdateUserTextbookFocusMutationVariables,
  useUpdateUserTextbookFocusMutationResponse
> {
  return useMutation({ mutation });
}
