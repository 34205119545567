import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

export const FLOATING_FOOTER_HEIGHT = 19 * BASE_UNIT;

export const FloatingFooter = styled({
  default: {
    height: FLOATING_FOOTER_HEIGHT,
    display: 'flex',
    padding: 4 * BASE_UNIT,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    // must render above the flyout body
    zIndex: 1,
  },
  largePadding: {
    padding: 6 * BASE_UNIT,
  },
});
