import styled from '@emotion/styled';

const NoWrap = styled.div<{ minWidth: number }>`
  ${props =>
    props.minWidth
      ? `@media (min-width: ${props.minWidth}px) {
            white-space: nowrap;
            }`
      : 'white-space: nowrap;'}
`;

export { NoWrap };
