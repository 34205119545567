/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapCurriculumStrandQueryVariables = {
    strandId: string;
};
export type StudentSkillsMapCurriculumStrandQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly strandStatus: {
                readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapCurriculumStrandStatus_studentStrandStatus" | "StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus">;
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
        readonly strand: {
            readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapCurriculumStrandSubstrands_strand">;
        } | null;
    };
};
export type StudentSkillsMapCurriculumStrandQuery = {
    readonly response: StudentSkillsMapCurriculumStrandQueryResponse;
    readonly variables: StudentSkillsMapCurriculumStrandQueryVariables;
};



/*
query StudentSkillsMapCurriculumStrandQuery(
  $strandId: ID!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        strandStatus(strandId: $strandId) {
          ...StudentSkillsMapCurriculumStrandStatus_studentStrandStatus
          ...StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus
          id
        }
      }
      id
    }
    strand(id: $strandId) {
      ...StudentSkillsMapCurriculumStrandSubstrands_strand
      id
    }
  }
}

fragment StudentSkillsMapCurriculumStrandStatus_studentStrandStatus on StudentStrandStatus {
  status
  strand {
    id
    title
  }
  diagnosticQuestionsCompleted
  diagnosticNumberOfQuestions
  lastWorkedOnDiagnosticCheckIn {
    id
  }
}

fragment StudentSkillsMapCurriculumStrandSubstrands_strand on Strand {
  id
  title
  substrands {
    id
    title
    badgeUrl
    gradeSubstrands {
      id
      gradeStrand {
        grade {
          id
          order
        }
        id
      }
    }
  }
}

fragment StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus on StudentStrandStatus {
  status
  lastWorkedOnDiagnosticCheckIn {
    endedAt
    hasFinishedDiagnosticPhase
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "strandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "strandId",
    "variableName": "strandId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "strandId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentSkillsMapCurriculumStrandQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "StudentSkillsMapCurriculumStrandStatus_studentStrandStatus"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Strand",
            "kind": "LinkedField",
            "name": "strand",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StudentSkillsMapCurriculumStrandSubstrands_strand"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StudentSkillsMapCurriculumStrandQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Strand",
                        "kind": "LinkedField",
                        "name": "strand",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diagnosticQuestionsCompleted",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diagnosticNumberOfQuestions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CheckIn",
                        "kind": "LinkedField",
                        "name": "lastWorkedOnDiagnosticCheckIn",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasFinishedDiagnosticPhase",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Strand",
            "kind": "LinkedField",
            "name": "strand",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Substrand",
                "kind": "LinkedField",
                "name": "substrands",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "badgeUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GradeSubstrand",
                    "kind": "LinkedField",
                    "name": "gradeSubstrands",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GradeStrand",
                        "kind": "LinkedField",
                        "name": "gradeStrand",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LanternGrade",
                            "kind": "LinkedField",
                            "name": "grade",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "order",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecf819d951577f0b645ced22dc4e567e",
    "id": null,
    "metadata": {},
    "name": "StudentSkillsMapCurriculumStrandQuery",
    "operationKind": "query",
    "text": "query StudentSkillsMapCurriculumStrandQuery(\n  $strandId: ID!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        strandStatus(strandId: $strandId) {\n          ...StudentSkillsMapCurriculumStrandStatus_studentStrandStatus\n          ...StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus\n          id\n        }\n      }\n      id\n    }\n    strand(id: $strandId) {\n      ...StudentSkillsMapCurriculumStrandSubstrands_strand\n      id\n    }\n  }\n}\n\nfragment StudentSkillsMapCurriculumStrandStatus_studentStrandStatus on StudentStrandStatus {\n  status\n  strand {\n    id\n    title\n  }\n  diagnosticQuestionsCompleted\n  diagnosticNumberOfQuestions\n  lastWorkedOnDiagnosticCheckIn {\n    id\n  }\n}\n\nfragment StudentSkillsMapCurriculumStrandSubstrands_strand on Strand {\n  id\n  title\n  substrands {\n    id\n    title\n    badgeUrl\n    gradeSubstrands {\n      id\n      gradeStrand {\n        grade {\n          id\n          order\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus on StudentStrandStatus {\n  status\n  lastWorkedOnDiagnosticCheckIn {\n    endedAt\n    hasFinishedDiagnosticPhase\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '80a7e4373e61f23c2f53681134593cb4';
export default node;
