/**
 * This module contains serializers specific to the NumberLineIntervals
 * component.
 * @module numberLine
 */
import { keys } from 'ramda';

import deserializer from 'ms-utils/math/deserializer';
import { toFraction } from 'ms-utils/math/number';

import type { NumberLineSegmentData } from '../components/NumberLine';

/**
 * Algorithm for translating between the old NumberLine value syntax and the
 * new.
 *
 * @param {Array} value Old value format
 * @returns {Array} New value format
 */
export function fromOldNumberLineValue(
  value: ReadonlyArray<any>,
): ReadonlyArray<NumberLineSegmentData> {
  return value.map(oldSegment => [
    {
      position: [oldSegment.data.lowerBound.value],
      meta: {
        inclusive: oldSegment.data.lowerBound.inclusive,
        status: oldSegment.status,
      },
    },
    {
      position: [oldSegment.data.upperBound.value],
      meta: {
        inclusive: oldSegment.data.upperBound.inclusive,
        status: oldSegment.status,
      },
    },
  ]);
}

/**
 * Algorithm for translating between the new NumberLine value syntax and the
 * old.
 *
 * @param {Array} value New value format
 * @returns {Array} Old value format
 */
export function toOldNumberLineValue(value: NumberLineSegmentData[]): Object[] {
  return value.map(newSegment => ({
    type: 'segment',
    status: newSegment[0].meta.status,
    data: {
      lowerBound: {
        value: newSegment[0].position[0],
        inclusive: newSegment[0].meta.inclusive,
      },
      upperBound: {
        value: newSegment[1].position[0],
        inclusive: newSegment[1].meta.inclusive,
      },
    },
  }));
}

/**
 * Transform a number into a string for communication with the server.
 *
 * @param {*} value The value in javascript number format
 * @returns {String} A string encoding of value
 */
export function serializeCoordinateValues(value: number): string {
  if (value === Infinity) {
    return 'infinity';
  } else if (value === -Infinity) {
    return '-infinity';
  } else if (typeof value === 'number') {
    return toFraction(value).join('/');
  }
  return value;
}

/**
 * Recover a javascript number from the serialized value.
 *
 * @param {*} value The value in string format
 * @returns {number} A javascript number equivalent to the serialized value
 */
export function deserializeCoordinateValues(value: string): number {
  if (value === 'infinity') {
    return Infinity;
  } else if (value === '-infinity') {
    return -Infinity;
  } else if (typeof value === 'string') {
    // deserializer signature is imprecise. The return type will be number when
    // we pass 'number' as the 2nd argument.
    return deserializer(value, 'number') as number;
  }
  return value;
}

/**
 * Map an object recursively, and apply a function to all keys that match
 * `value`.
 *
 * @param {Object} obj
 *   The object to be traversed.
 * @param {Function} visit
 *   The visitor function.
 * @returns {Object} an object that has been recursively transformed.
 */
export function mapValueRecursive<A, B>(obj: any, visit: (node: A) => B) {
  const newObj: Record<keyof any, any> = {};
  keys(obj).forEach(key => {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      newObj[key] = mapValueRecursive(obj[key], visit);
    } else if (key === 'value') {
      newObj[key] = visit(obj[key]);
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

/**
 * Translate a `'point'` to a `'segment'` of length zero.
 *
 * @param {Object} element
 * Any element
 * @returns {Object}
 * A `'segment'` element.
 */
export function pointToSegment(element: any): any {
  if (element.type === 'point') {
    return {
      type: 'segment',
      status: element.status,
      data: {
        lowerBound: {
          value: element.data.value,
          inclusive: true,
        },
        upperBound: {
          value: element.data.value,
          inclusive: true,
        },
      },
    };
  }
  return element;
}

/**
 * Translate a `'segment'` of length zero to a `'point'`.
 *
 * @param {Object} element
 * Any element
 * @returns {Object}
 * A `'point'` element.
 */
export function segmentToPoint(element: any): any {
  if (
    element.type === 'segment' &&
    element.data.lowerBound.value === element.data.upperBound.value
  ) {
    return {
      type: 'point',
      status: element.status,
      data: {
        value: element.data.lowerBound.value,
        inclusive: true,
      },
    };
  }
  return element;
}
