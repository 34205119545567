import type { ReactElement, ComponentType } from 'react';
import { cloneElement } from 'react';

import { colors } from 'ms-styles/colors';

export type MoreThanOneElement<T> = ReadonlyArray<
  ReactElement<T> | null | false
>;
export type OneOrMoreElements<T> =
  | MoreThanOneElement<T>
  | ReactElement<T>
  | null
  | false;

const matchChild =
  (required: ComponentType<any>) =>
  (candidate: OneOrMoreElements<any> | null | undefined): boolean => {
    if (candidate == null) return false;
    // TODO the original implementation was not type sound. There
    // is only a .type property on a single ReactElement, but that's
    // not what this function accepts as input...
    // I'm surpressing the type error for now, as I don't want to
    // redo the implementation of this file as part of the ts refactor
    // @ts-expect-error
    const type = candidate.type;
    return type === required;
  };

export const findReactChildByType = (
  children: OneOrMoreElements<any> | null | undefined,
  constructor: ComponentType<any>,
): ReactElement<any> | null | undefined =>
  children && Array.isArray(children)
    ? children.find(matchChild(constructor)) || null
    : matchChild(constructor)(children)
    ? children || null
    : null;

export const findReactChildrenByType = (
  children: OneOrMoreElements<any> | null | undefined,
  constructor: ComponentType<any>,
): OneOrMoreElements<any> | null | undefined =>
  children && Array.isArray(children)
    ? children.filter(matchChild(constructor))
    : (matchChild(constructor)(children) && children) || null;

export const injectProps =
  (props: Object) =>
  (
    maybeComponent: ReactElement<any> | null | undefined,
  ): ReactElement<any> | null =>
    maybeComponent ? cloneElement(maybeComponent, props) : null;

export type StyleValue = string | number;

export type NamedColor = keyof typeof colors;

export const backgroundToStyles = (
  background: NamedColor | null | undefined,
) => ({
  backgroundColor: background != null ? colors[background] : undefined,
});

export const borderColorToStyles = (border: NamedColor | null | undefined) => ({
  borderColor: border != null && colors[border],
});
