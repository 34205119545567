import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  updateStudentMutationVariables,
  updateStudentMutationResponse,
} from './__generated__/updateStudentMutation.graphql';

export type { updateStudentMutationVariables };
export const mutation = graphql`
  mutation updateStudentMutation(
    $studentId: ID!
    $firstName: String
    $lastName: String
    $classIds: [ID!]
    $syllabusFocusId: ID
    $email: String
    $guardianEmails: [String!]
    $parentNotifications: Boolean
    $gradeId: ID
    $isSunflowerUi: Boolean!
  ) {
    updateStudent(
      studentId: $studentId
      firstName: $firstName
      lastName: $lastName
      classIds: $classIds
      syllabusFocusId: $syllabusFocusId
      email: $email
      guardianEmails: $guardianEmails
      parentNotifications: $parentNotifications
      gradeId: $gradeId
    ) {
      errors {
        key
        message
      }
      student {
        ...TeacherStudentApp_student
        ...StudentNavStudentFragment
        ...TeacherStudentSettings_student
        ...ClassSettingsStudents_student
      }
    }
  }
`;
export default function useUpdateStudent(
  variables: updateStudentMutationVariables,
): UseMutationResponse<updateStudentMutationResponse> {
  return useMutation({ mutation, variables });
}
