import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const SkillsMap = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
    color={color}
  >
    <path d="M14.5355 1.46447C16.4881 3.41709 16.4881 6.58294 14.5355 8.53555C12.5829 10.4882 9.41707 10.4882 7.46446 8.53555C5.51185 6.58294 5.51185 3.41709 7.46446 1.46447C9.41707 -0.48814 12.5829 -0.488176 14.5355 1.46447Z" />
    <path d="M5.97488 9.02513C7.34172 10.392 7.34172 12.608 5.97488 13.9749C4.60804 15.3417 2.39198 15.3417 1.02513 13.9749C-0.341711 12.608 -0.341711 10.392 1.02513 9.02513C2.39198 7.65829 4.60804 7.65829 5.97488 9.02513Z" />
    <path d="M12.4143 12.5858C13.1953 13.3668 13.1953 14.6332 12.4143 15.4142C11.6332 16.1952 10.3669 16.1952 9.58584 15.4142C8.8048 14.6332 8.8048 13.3668 9.58584 12.5858C10.3669 11.8047 11.6332 11.8047 12.4143 12.5858Z" />
  </Icon>
);

export default SkillsMap;
