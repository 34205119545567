import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';

export default function useSidebarCollapsiness() {
  const {
    userId,
    featureFlags: { classicUi },
  } = useViewer();

  const [isLeftSidebarCollapsed, setLeftSidebarCollapsed] = useLocalSyncedState(
    {
      dbKey: `${userId}_NTE_SIDEBAR_IS_COLLAPSED`,
      fallbackValue: false,
    },
  );

  return {
    isLeftSidebarCollapsed: isLeftSidebarCollapsed && classicUi,
    setLeftSidebarCollapsed,
  };
}
