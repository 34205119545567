import styled from '@emotion/styled';
import {
  useRef,
  useEffect,
  useCallback,
  useState,
  forwardRef,
  type ReactNode,
  type ReactElement,
  type RefObject,
} from 'react';
import { useHistory } from 'react-router-dom';

import { type AppEnv, useAppEnv } from 'ms-helpers/AppEnv';
import { isReactNativeAppHost } from 'ms-helpers/AppEnv/utils';
import {
  borderRadiusUILarge,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  zIndex,
} from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import { BASE_UNIT, boxShadow } from 'ms-styles/theme/Numero';
import { useColorTheme } from 'ms-styles/themes';
import type { AnchorOrigin, PopoverPosition } from 'ms-ui-primitives/Popover';
import Popover from 'ms-ui-primitives/Popover';
import Tooltip from 'ms-ui-primitives/Tooltip';
import {
  useAccessibilityMode,
  accessibilityModeStyle,
} from 'ms-utils/accessibility';
import { hexStringToRgbaString } from 'ms-utils/colors';
import { onPressOrHover, tappable } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';

export type MenuPosition = PopoverPosition;

export type MenuItem = {
  key: string;
  label: string;
  icon?: ReactElement<any> | undefined;
  action?: () => void | null | undefined;
  link?: string | null | undefined;
  to?: string | undefined;
  openLinkInNewTab?: boolean | undefined;
  isDisabled?: boolean | undefined;
  dataTestId?: string | undefined;
  trackingId?: string | undefined;
  tooltip?: string | undefined;
  windowFeatures?: string | undefined;
};

type DividerType = 'divider';

export type Props = {
  items: ReadonlyArray<MenuItem | DividerType | false>;
  menuPosition: MenuPosition;
  anchorRef: RefObject<HTMLElement>;
  vOffset?: number | undefined;
  hOffset?: number | undefined;
  anchorOrigin?: AnchorOrigin | undefined;
  onDismiss: () => void;
  header?: ReactNode | undefined;
  footer?: ReactNode | undefined;
  menuMaxHeight?: number | undefined; // in pixels
  minWidth?: number | undefined;
  wordWrapEnabled?: boolean | undefined;
};

const DEFAULT_VERTICAL_OFFSET_FROM_ANCHOR = 10;
const DEFAULT_HORIZONTAL_OFFSET_FROM_ANCHOR = 0;
export const MENU_PADDING = 3 * BASE_UNIT;
const MENU_PADDING_ACCESSIBILITY_MODE = 4 * BASE_UNIT;
export const MENU_ITEM_PADDING_HORIZONTAL = 3 * BASE_UNIT;
const MENU_ITEM_PADDING_VERTICAL = 2 * BASE_UNIT;
const MENU_ITEM_PADDING_ACCESSIBILITY_MODE = 2 * BASE_UNIT;
const BORDER_RADIUS = borderRadiusUILarge;

const menuPaddingOffsetAccessibilityMode = {
  marginLeft: -MENU_PADDING_ACCESSIBILITY_MODE,
  marginRight: -MENU_PADDING_ACCESSIBILITY_MODE,
};

const MenuHeader = styled.div<{
  accessibilityMode: boolean;
  hasTopBorderRadius?: boolean | undefined;
  hasBottomBorderRadius?: boolean | undefined;
}>(
  {
    padding: MENU_PADDING,
    paddingBottom: 2 * BASE_UNIT,
  },
  ({ hasTopBorderRadius }) =>
    hasTopBorderRadius && {
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
    },
  ({ hasBottomBorderRadius }) =>
    hasBottomBorderRadius && {
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  ({ accessibilityMode }) =>
    accessibilityMode && {
      ...menuPaddingOffsetAccessibilityMode,
      padding: `${MENU_PADDING_ACCESSIBILITY_MODE}px ${
        MENU_PADDING_ACCESSIBILITY_MODE + MENU_ITEM_PADDING_ACCESSIBILITY_MODE
      }px`,
    },
);

const MenuFooter = MenuHeader;

const Divider = styled.div<{
  accessibilityMode: boolean;
}>(
  {
    height: 1,
    backgroundColor: colors.ironLight,
  },
  ({ accessibilityMode }) =>
    accessibilityMode && {
      ...menuPaddingOffsetAccessibilityMode,
      marginTop: MENU_PADDING_ACCESSIBILITY_MODE,
      marginBottom: MENU_PADDING_ACCESSIBILITY_MODE,
    },
);

const MenuElement = styled.div<{
  accessibilityMode: boolean;
  hasMaxHeight: boolean;
  isInline?: boolean | undefined;
}>(
  {
    borderRadius: BORDER_RADIUS,
    fontFamily: fontFamily.body,
    background: colors.white,
    lineHeight: lineHeight.heading,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default', // override `pointer` on the dropdown toggle
    boxShadow: boxShadow.regular,
    ':focus': {
      outline: 'none',
    },
  },
  ({ accessibilityMode }) =>
    accessibilityMode && {
      padding: MENU_PADDING_ACCESSIBILITY_MODE,
      minWidth: 300,
    },
  ({ hasMaxHeight }) =>
    hasMaxHeight && {
      overflow: 'auto',
      overscrollBehavior: 'contain', // Only works in Chrome, Firefox, and latest Edge
    },
  ({ isInline }) =>
    isInline && {
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: 'none',
    },
);

type ItemElementStyleProps = {
  isDisabled?: boolean | undefined;
  isEnabled?: boolean | undefined;
  hasTopBorderRadius?: boolean | undefined;
  hasBottomBorderRadius?: boolean | undefined;
  accessibilityMode: boolean;
  color: string;
  alternateColor: string;
};

const itemStyle = [
  {
    display: 'flex',
    alignItems: 'center',
    padding: `${MENU_ITEM_PADDING_VERTICAL}px ${MENU_ITEM_PADDING_HORIZONTAL}px`,
    fontSize: fontSize.medium,
    lineHeight: '22px',
    fontWeight: fontWeight.semibold,
    whiteSpace: 'nowrap',
    zIndex: zIndex.dropdownMenuItem,
    textDecoration: 'none',
  },
  ({ hasTopBorderRadius }: ItemElementStyleProps) =>
    hasTopBorderRadius && {
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
    },
  ({ hasBottomBorderRadius }: ItemElementStyleProps) =>
    hasBottomBorderRadius && {
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
  ({ isEnabled, color, alternateColor }: ItemElementStyleProps) =>
    isEnabled && {
      color,
      ...tappable,
      ...onPressOrHover({
        color: alternateColor,
        backgroundColor: colors.porcelain,
      }),
      ':focus': {
        outline: 'none',
        backgroundColor: colors.porcelain,
      },
    },

  ({ accessibilityMode }: ItemElementStyleProps) =>
    accessibilityMode && {
      ...accessibilityModeStyle,
      padding: MENU_ITEM_PADDING_ACCESSIBILITY_MODE,
      fontSize: fontSize.medium,
      ':focus': {
        ...accessibilityModeStyle[':focus'],
        background: 'transparent',
      },
    },

  ({ isDisabled }: ItemElementStyleProps) =>
    isDisabled && {
      color: colors.grayChateau,
      ':focus': {
        outline: 'none',
        backgroundColor: hexStringToRgbaString(colors.ironLight, 0.5),
      },
    },
];

const ItemElementNotLink = styled.div<ItemElementStyleProps>(itemStyle);
const ItemElementLink = styled.a<ItemElementStyleProps>(itemStyle);

const Label = styled.div<{ wordWrapEnabled: boolean }>(
  {
    display: 'flex',
    flexShrink: 0,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  ({ wordWrapEnabled }) =>
    wordWrapEnabled && {
      maxWidth: 300, // an arbitrary number which would make sense on most of the screen sizes
      whiteSpace: 'break-spaces',
    },
);

const ICON_SIZE = 4 * BASE_UNIT;
const ICON_MARGIN_RIGHT = 3 * BASE_UNIT;

const IconWrapper = styled.div({
  flexShrink: 0,
  flexGrow: 0,
  fontSize: ICON_SIZE,
  display: 'flex',
  marginRight: ICON_MARGIN_RIGHT,
});

type ItemProps = {
  item: MenuItem;
  close: () => void;
  isFocused: boolean;
  onFocus: () => void;
  wordWrapEnabled?: boolean | undefined;
  hasTopBorderRadius?: boolean | undefined;
  hasBottomBorderRadius?: boolean | undefined;
  appEnv: AppEnv;
};

function Item({
  item,
  close,
  isFocused,
  onFocus,
  wordWrapEnabled = false,
  hasTopBorderRadius = false,
  hasBottomBorderRadius = false,
  appEnv,
}: ItemProps) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const isLink = item.link != null;
  const anchorRef = useRef<HTMLAnchorElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  useEffect(() => {
    if (isFocused) {
      const element = isLink ? anchorRef.current : divRef.current;
      if (element != null) {
        element.focus();
      }
    }
  }, [isFocused, isLink]);
  const isReactNativeHost = isReactNativeAppHost(appEnv);

  const action = useCallback(() => {
    if (item.isDisabled) return;

    if (item.action != null) {
      item.action();
    }
    if (item.to != null) {
      // Possibly just ignore this on iPad.  That would be the simplest solution.
      // We want to check if we're running in an app environment.
      // If browser chrome is available, we don't have the problem.  And so
      // many of the _blank-targetted links are not problematic, because they're not
      // designed to appear in the apps.

      // isReactNativeAppHost(appEnv) &&... (be great if this was a hook.)
      if (item.openLinkInNewTab && !isReactNativeHost) {
        const windowFeatures = item?.windowFeatures ?? '';
        window.open(item.to, '_blank', windowFeatures)?.focus();
      } else {
        history.push(item.to);
      }
    }
    if (item.link != null) {
      if (item.openLinkInNewTab && !isReactNativeHost) {
        const windowFeatures = item?.windowFeatures ?? '';
        window.open(item.link, '_blank', windowFeatures)?.focus();
      } else {
        window.location.assign(item.link);
      }
    }
    close();
  }, [close, history, isReactNativeHost, item]);

  const { primary: primaryColorName } = useColorTheme();

  // make props object:
  const props = {
    children: (
      <>
        {/* Make sure IconWrapper doesn't create empty space when there's no icon */}
        {item.icon != null && <IconWrapper>{item.icon}</IconWrapper>}
        <Label wordWrapEnabled={wordWrapEnabled}>{item.label}</Label>
      </>
    ),
    role: 'menuitem',
    tabIndex: -1,
    key: item.key,
    accessibilityMode: hasEnabledAccessibilityMode,
    onFocus,
    onMouseOver: onFocus,
    isDisabled: item.isDisabled,
    isEnabled: !item.isDisabled,
    color: colors[primaryColorName],
    alternateColor: alternateColors[primaryColorName],
    target: item.openLinkInNewTab ? '_blank' : undefined,
    'data-test-id': item.dataTestId,
    'data-tracking-id': item.trackingId || '',
    onKeyDown: keyDownMap({
      ENTER: action,
      SPACE: [action, { preventDefault: true }], // prevent the default scroll on SPACEBAR press
      ESC: close,
    }),
    hasTopBorderRadius,
    hasBottomBorderRadius,
  };

  return isLink ? (
    <ItemElementLink
      ref={anchorRef}
      href={item.link ?? undefined}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        action();
      }}
      {...props}
    />
  ) : (
    <ItemElementNotLink
      ref={divRef}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        action();
      }}
      {...props}
    />
  );
}

type MenuProps = {
  items: ReadonlyArray<MenuItem | DividerType | false>;
  close: () => void;
  header?: ReactNode | undefined;
  footer?: ReactNode | undefined;
  maxHeight?: number | undefined;
  minWidth?: number | undefined;
  wordWrapEnabled?: boolean | undefined;
  isInline?: boolean | undefined;
};

export const Menu = forwardRef<HTMLElement, MenuProps>(
  (
    {
      items,
      close,
      header,
      footer,
      maxHeight,
      minWidth,
      wordWrapEnabled,
      isInline,
    }: MenuProps,
    forwardedRef,
  ) => {
    const [currentFocusedItemIndex, setCurrentFocusedItemIndex] = useState<
      number | null
    >(null);
    const { appEnv } = useAppEnv();

    const focusNext = useCallback(() => {
      setCurrentFocusedItemIndex(i => {
        let nextFocusedItemIndex = (i == null ? 0 : i + 1) % items.length;
        while (
          // This code was always wrong given the types. cbf fixing it now
          // @ts-expect-error
          items[nextFocusedItemIndex].isDisabled ||
          items[nextFocusedItemIndex] === 'divider'
        ) {
          nextFocusedItemIndex = (nextFocusedItemIndex + 1) % items.length;
        }
        return nextFocusedItemIndex;
      });
    }, [items]);

    const focusPrev = useCallback(() => {
      setCurrentFocusedItemIndex(i => {
        let nextFocusedItemIndex =
          (i == null ? 0 : i - 1 + items.length) % items.length;
        while (
          // This code was always wrong given the types. cbf fixing it now
          // @ts-expect-error
          items[nextFocusedItemIndex]?.isDisabled ||
          items[nextFocusedItemIndex] === 'divider'
        ) {
          nextFocusedItemIndex =
            (nextFocusedItemIndex - 1 + items.length) % items.length;
        }
        return nextFocusedItemIndex;
      });
    }, [items]);

    const focusElementAtIndex = useCallback(
      (index: number) => () => {
        setCurrentFocusedItemIndex(index);
      },
      [],
    );

    // focus the first item on mount
    useEffect(() => {
      setCurrentFocusedItemIndex(0);
    }, []);
    const [hasEnabledAccessibilityMode] = useAccessibilityMode();

    return (
      <MenuElement
        role="menu"
        onKeyDown={keyDownMap({
          ESC: close,
          ARROW_DOWN: [focusNext, { preventDefault: true }],
          ARROW_UP: [focusPrev, { preventDefault: true }],
        })}
        tabIndex={0}
        accessibilityMode={hasEnabledAccessibilityMode}
        style={{ maxHeight, minWidth }}
        hasMaxHeight={maxHeight != null}
        isInline={isInline}
        ref={(ref: HTMLDivElement | null) => {
          if (forwardedRef != null && typeof forwardedRef === 'object') {
            forwardedRef.current = ref;
          }
        }}
      >
        {header != null && (
          <>
            <MenuHeader
              accessibilityMode={hasEnabledAccessibilityMode}
              hasTopBorderRadius
            >
              {header}
            </MenuHeader>
            <Divider accessibilityMode={hasEnabledAccessibilityMode} />
          </>
        )}
        {items.map((item, index) => {
          if (item === 'divider') {
            return (
              <Divider
                accessibilityMode={hasEnabledAccessibilityMode}
                key={`divider-${index}`}
              />
            );
          } else if (item === false) {
            return null;
          } else {
            const showTooltip = item.tooltip != null;
            const extraProps = showTooltip ? {} : { key: item.key };
            const itemElement = (
              <Item
                appEnv={appEnv}
                item={item}
                close={close}
                isFocused={currentFocusedItemIndex === index}
                onFocus={focusElementAtIndex(index)}
                wordWrapEnabled={wordWrapEnabled}
                hasTopBorderRadius={index === 0 && header == null}
                hasBottomBorderRadius={
                  index === items.length - 1 && footer == null
                }
                {...extraProps}
              />
            );

            return showTooltip ? (
              <Tooltip key={item.key} title={item.tooltip}>
                {itemElement}
              </Tooltip>
            ) : (
              itemElement
            );
          }
        })}
        {footer != null && (
          <>
            <Divider accessibilityMode={hasEnabledAccessibilityMode} />
            <MenuFooter
              accessibilityMode={hasEnabledAccessibilityMode}
              hasBottomBorderRadius
            >
              {footer}
            </MenuFooter>
          </>
        )}
      </MenuElement>
    );
  },
);

const DropdownMenu = forwardRef<HTMLElement, Props>(function DropdownMenu(
  {
    items,
    vOffset = DEFAULT_VERTICAL_OFFSET_FROM_ANCHOR,
    hOffset = DEFAULT_HORIZONTAL_OFFSET_FROM_ANCHOR,
    menuPosition,
    anchorRef,
    anchorOrigin,
    onDismiss,
    header,
    footer,
    menuMaxHeight,
    minWidth,
    wordWrapEnabled = false,
  }: Props,
  forwardedRef,
) {
  return (
    <Popover
      onDismiss={onDismiss}
      shouldDismissOnTapOut
      vOffset={vOffset}
      hOffset={hOffset}
      popoverPosition={menuPosition}
      anchorElementRef={anchorRef}
      anchorOrigin={anchorOrigin}
      hasVerticalTransition={false}
    >
      <Menu
        ref={forwardedRef}
        items={items}
        close={onDismiss}
        header={header}
        footer={footer}
        maxHeight={menuMaxHeight}
        minWidth={minWidth}
        wordWrapEnabled={wordWrapEnabled}
      />
    </Popover>
  );
});

export default DropdownMenu;
