import { css } from '@emotion/css';
import type { MutableRefObject, ReactNode } from 'react';
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from 'ms-ui-primitives/Button';
import Checkbox from 'ms-ui-primitives/Checkbox';
import SearchInput from 'ms-ui-primitives/SearchInput/controlled';
import Separator from 'ms-ui-primitives/Separator';
import { verticallyScrollable } from 'ms-utils/emotion';

import { Filter, FilterPopover } from './FilterForm';
import MinorSpinner from './MinorSpinner';
import TruncateText from './TruncateText';

export type Option = {
  key: string;
  label: string;
};

export default function ScrollableCheckBoxFilter({
  options,
  suspendable,
  label,
  prevSelectedKeys,
  submitSelection,
  containerBottom,
  icon,
  maxWidth,
  searchable = false,
}: (
  | { options: Option[]; suspendable?: never }
  | { options: Option[] | undefined; suspendable: true }
) & {
  label: string;
  prevSelectedKeys: readonly string[] | undefined;
  submitSelection: (keys: readonly string[]) => void;
  containerBottom?: number;
  icon?: ReactNode;
  maxWidth?: number;
  searchable?: boolean;
}) {
  const prevSelectedLabels = useMemo(() => {
    if (prevSelectedKeys === undefined) {
      return undefined;
    }
    return (options ?? [])
      .filter(({ key }) =>
        prevSelectedKeys.some(selectedKey => selectedKey === key),
      )
      .map(({ label }) => label);
  }, [options, prevSelectedKeys]);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverAnchor = useRef(null);

  // There's some wacky state management going on here.
  // The main cause is scrolling of the list of checkboxes in the popover.
  // The onDismiss callback has to be included at this level but for it to be
  // useful we need access to the new selection.
  // Keeping that in state causes rerenders of the entire component, mounting
  // and unmounting the popover each time, reseting the scroll position.

  // We store the selected keys in a ref so that we can update them
  // without causing a re-render of the entire component.
  const selectedKeysRef = useRef<readonly string[] | undefined>(
    prevSelectedKeys,
  );

  // This is passed to the ScrollableOptions component as either the current
  // selection on the page or a cleared selection when the Clear button is clicked.
  // Note: clearing this state does trigger a re-render of the scroll container,
  // but jumping to the top of the list makes sense for that interaction.
  const [initialSelectedKeys, setInitialSelectedKeys] = useState<
    readonly string[]
  >(prevSelectedKeys ?? []);

  const onDismiss = useCallback(() => {
    setPopoverOpen(false);
    setInitialSelectedKeys(selectedKeysRef.current ?? []);
    submitSelection(selectedKeysRef.current ?? []);
  }, [setPopoverOpen, submitSelection]);

  // This copy is tied to the page's load state - it updates once a selection is made
  const selectionDescription = useMemo(() => {
    if (prevSelectedLabels === undefined) {
      return 'None';
    }
    if (prevSelectedLabels.length === 0) {
      return 'All';
    }

    return prevSelectedLabels.join(', ');
  }, [prevSelectedLabels]);

  return (
    <Filter
      label={
        <>
          {icon !== undefined && icon}
          <TruncateText maxWidth={maxWidth}>
            {`${label}: ${selectionDescription}`}
          </TruncateText>
        </>
      }
      selected={prevSelectedKeys !== undefined && prevSelectedKeys.length > 0}
      onClick={() => {
        setPopoverOpen(true);
      }}
      popoverAnchorRef={popoverAnchor}
      popover={
        popoverOpen && (
          <FilterPopover
            anchorElementRef={popoverAnchor}
            onDismiss={onDismiss}
            containerBottom={containerBottom}
          >
            {/* We need to seperate the state here, otherwise the popover 
            content is remounted each time the state changes. */}
            <PopoverContent
              {...{
                initialSelectedKeys,
                setInitialSelectedKeys,
                onDismiss,
                selectedKeysRef,
                options,
                searchable,
                suspendable,
              }}
            />
          </FilterPopover>
        )
      }
    />
  );
}

const PopoverContent = ({
  initialSelectedKeys,
  setInitialSelectedKeys,
  selectedKeysRef,
  onDismiss,
  options,
  searchable,
  suspendable,
}: {
  initialSelectedKeys: readonly string[];
  setInitialSelectedKeys: (keys: readonly string[]) => void;
  selectedKeysRef: MutableRefObject<readonly string[] | undefined>;
  onDismiss: () => void;
  options: Option[] | undefined;
  searchable: boolean;
  suspendable: true | undefined;
}) => {
  const updateSelectedKeys = useCallback(
    (keys: string[]) => {
      selectedKeysRef.current = keys;
    },
    [selectedKeysRef],
  );

  const [searchString, setSearchString] = useState('');

  const [selectedKeys, setSelectedKeys] = useState<readonly string[]>([]);

  useEffect(() => {
    setSelectedKeys(initialSelectedKeys ?? []);
  }, [initialSelectedKeys]);

  const toggleKeySelection = useCallback(
    (key: string) => {
      setSelectedKeys((prevSelectedKeys = []) => {
        if (key === undefined) {
          return [];
        }
        const updatedKeys = prevSelectedKeys.includes(key)
          ? prevSelectedKeys.filter(id => id !== key)
          : [...prevSelectedKeys, key];

        updateSelectedKeys(updatedKeys);
        return updatedKeys;
      });
    },
    [updateSelectedKeys],
  );

  const { enabledOptions, disabledOptions } = useMemo(() => {
    if (options === undefined) {
      return {
        enabledOptions: [],
        disabledOptions: [],
      };
    }
    if (searchString === '') {
      return { enabledOptions: options, disabledOptions: [] };
    }
    return options.reduce<{
      enabledOptions: Option[];
      disabledOptions: Option[];
    }>(
      (acc, option) => {
        if (option.label.toLowerCase().includes(searchString.toLowerCase())) {
          acc.enabledOptions.push(option);
        } else {
          acc.disabledOptions.push(option);
        }
        return acc;
      },
      { enabledOptions: [], disabledOptions: [] },
    );
  }, [options, searchString]);

  const scrollableOptionsRef = useRef<HTMLDivElement>(null);

  const onChangeSearch = useCallback(
    (search: string) => {
      setSearchString(search);
      if (scrollableOptionsRef.current !== null) {
        scrollableOptionsRef.current.scrollTop = 0;
      }
    },
    [setSearchString],
  );

  return suspendable && options === undefined ? (
    <MinorSpinner />
  ) : (
    <>
      {searchable && (
        <>
          <SearchInput
            placeholder="Search"
            value={searchString}
            onChange={onChangeSearch}
          />
          <Separator size={4} />
        </>
      )}
      <div
        ref={scrollableOptionsRef}
        className={css({
          flex: 1,
          minHeight: 0,
          ...verticallyScrollable,
        })}
      >
        {enabledOptions.length > 0 ? (
          enabledOptions.map(({ key, label }, idx) => (
            <Fragment key={key}>
              {idx !== 0 && <Separator size={4} />}
              <Checkbox
                label={label}
                checked={selectedKeys.some(selectedKey => selectedKey === key)}
                onChange={() => {
                  toggleKeySelection(key);
                }}
              />
            </Fragment>
          ))
        ) : (
          <div style={{ height: 20 }}>No unselected options match search</div>
        )}
        {disabledOptions
          .filter(({ key }) =>
            selectedKeys.some(selectedKey => selectedKey === key),
          )
          .map(({ key, label }) => (
            <Fragment key={key}>
              <Separator size={4} />
              <Checkbox label={label} checked disabled />
            </Fragment>
          ))}
      </div>
      <Separator size={4} />
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        })}
      >
        <Button
          isInline
          onClick={() => {
            setInitialSelectedKeys([]);
            updateSelectedKeys([]);
          }}
        >
          Clear
        </Button>
        <Button isInline padding={0} onClick={onDismiss}>
          Save
        </Button>
      </div>
    </>
  );
};
