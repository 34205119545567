import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  editCheckInTaskMutationVariables,
  editCheckInTaskMutationResponse,
} from './__generated__/editCheckInTaskMutation.graphql';

export type { editCheckInTaskMutationVariables };
const mutation = graphql`
  mutation editCheckInTaskMutation(
    $taskId: ID!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $startDate: DateTime!
    $dueDate: DateTime!
    $first: Int!
  ) {
    editCheckInTask(
      taskId: $taskId
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      startDate: $startDate
      dueDate: $dueDate
    ) {
      task {
        startDate
        dueDate
        expiryDate
        assignedTasks(first: 1000, orderBy: STUDENT_NAME_ASCENDING) {
          edges {
            node {
              id
              status
            }
          }
        }
        ...EditTaskFlyout_assignments @arguments(first: $first)
      }
      errors {
        key
      }
    }
  }
`;
export default function useEditCheckInTask(
  variables: editCheckInTaskMutationVariables,
): UseMutationResponse<editCheckInTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
