import { styled } from 'ms-utils/emotion';

const SeparatorStyle = styled({
  default: {
    flexShrink: 0,
  },
  grow: {
    flexGrow: 1,
  },
  inline: {
    display: 'inline-flex',
  },
});

type Props = {
  size: number;
  grow?: boolean;
  inline?: boolean;
};

export default function Separator({
  size,
  grow = false,
  inline = false,
}: Props) {
  return (
    <SeparatorStyle
      grow={grow}
      inline={inline}
      style={{ height: size, width: size }}
    />
  );
}
