import { useContext } from 'react';

import TextbookIcon from 'ms-components/icons/TextbookTeacher';
import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector, {
  type Items,
} from 'ms-pages/Teacher/components/InputSelector';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

import { StateContext, UpdatersContext } from '../state';

const updateSyllabusFocusItem = (
  items: Items,
  syllabusFocusId: string,
): Items =>
  syllabusFocusId === ''
    ? items
    : items.map(item =>
        item.id === syllabusFocusId
          ? {
              ...item,
              title: `${item.title} (Focus)`,
              isDisabled: true,
            }
          : item,
      );

const filterSyllabusFocusItem = (
  items: Items,
  syllabusFocusId: string,
): Items =>
  syllabusFocusId === '' ? items : items.filter(t => t.id !== syllabusFocusId);

type Props = {
  items: Items;
  errorMessages: ErrorMessages;
};

const Textbooks = ({ items, errorMessages }: Props) => {
  const { textbooks, syllabusFocus } = useContext(StateContext);
  const { addTextbook, removeTextbook } = useContext(UpdatersContext);
  const otherTextbooks = filterSyllabusFocusItem(textbooks, syllabusFocus.id);

  return (
    <FieldGroup title="Other textbooks used in this class">
      <InputSelector
        items={updateSyllabusFocusItem(items, syllabusFocus.id)}
        selectedItems={otherTextbooks}
        removeSelectedItem={removeTextbook}
        onDropdownSelect={addTextbook}
        icon={<TextbookIcon size={3.5 * BASE_UNIT} />}
        placeholder="Select from the list"
        showErrorState={errorMessages.length > 0}
      />
      <FormFieldError messages={errorMessages} />
    </FieldGroup>
  );
};

export default Textbooks;
