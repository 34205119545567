import { useRef, useEffect } from 'react';

import {
  useObservedRect,
  usePrevious,
} from 'ms-pages/Debug/VideoRecordingEnvironment/utilityHooks';

type Props = {
  isOpen: boolean;
};

// Just for backwards-compatibility with Flow-generated libdefs
type Ref<T> = { current: T };

export function useCollapse<
  T extends HTMLElement = HTMLElement,
  U extends HTMLElement = HTMLElement,
>({ isOpen }: Props) {
  const outer: Ref<T | null> = useRef(null);
  const firstRender = typeof usePrevious(isOpen) === 'undefined';

  const inner: Ref<U | null> = useRef(null);
  const innerRect = useObservedRect(inner);

  useEffect(() => {
    if (firstRender) return;
    if (outer.current != null) {
      if (isOpen === true) {
        outer.current.style.overflow = 'visible';
        outer.current.style.height = `${innerRect.height}px`;
      } else {
        outer.current.style.height = '0px';
        outer.current.style.overflow = 'hidden';
      }
    }
  }, [firstRender, innerRect, isOpen]);

  return [outer, inner] as const;
}
