import type { ReactNode } from 'react';

import { colors } from 'ms-styles/colors';

type Props = {
  children: string;
  highlights: ReadonlyArray<string>;
  color?: keyof typeof colors;
  backgroundColor?: string;
};

export default function Highlighter({
  children,
  highlights: highlights_,
  color = 'gamboge',
  backgroundColor = 'rgba(255, 255, 255, .1)',
}: Props) {
  const highlights = highlights_.map(h => ({
    length: h.length,
    matchIndex: children.toLowerCase().indexOf(h.toLowerCase()),
  }));
  const indexesOfHighlighted = children
    .split('')
    .map((_, index) =>
      highlights.some(
        h =>
          h.matchIndex > -1 &&
          h.matchIndex <= index &&
          index < h.matchIndex + h.length,
      ),
    );
  return (
    // Useless fragment required as TS React types are incorrect
    <>
      {children.split('').map<ReactNode>((letter, index) => (
        <span
          translate="no"
          key={index}
          style={{
            color: indexesOfHighlighted[index] ? colors[color] : undefined,
            background: indexesOfHighlighted[index]
              ? backgroundColor
              : undefined,
          }}
        >
          {letter}
        </span>
      ))}
    </>
  );
}
