// This is a rudimentary event multi send multi receive subscription bus
// This will operate as a singleton and the constructor is not exported
class TaskRefetchBus {
  listeners: (() => void | Promise<void>)[] = [];

  listen(listener: () => void | Promise<void>) {
    this.listeners.push(listener);
    return () => this.deregister(listener);
  }

  deregister(listener: () => void | Promise<void>) {
    this.listeners = this.listeners.filter(l => l !== listener);
  }

  async signal() {
    for (const listener of this.listeners) {
      try {
        await listener();
        // this will trigger requests serially to prevent rate limiting from triggering
        // this can be customised to handle rate limiting / buffering / adaptive cancel and retry
      } catch (e) {}
    }
  }
}

export const taskRefetchBus = new TaskRefetchBus();
