import { map } from 'ramda';
import { useState, useMemo } from 'react';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { EditTaskFlyout_assignments as Assignments } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_assignments.graphql';
import type { EditTaskFlyout_checkInTask as CheckInTask } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_checkInTask.graphql';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import editCheckInTask from './state/editCheckInTask';
import type { editCheckInTaskMutationVariables } from './state/editCheckInTask';
import * as updaters from './state/updaters';
import { excludedStudents } from '../';

export type TaskId = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;

export type State = {
  taskId: TaskId;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  selectedClasses: readonly ClassSelectionPayload[];
  selectedStudents: readonly StudentSelectionPayload[];
  excludedStudents: readonly StudentSelectionPayload[];
};
type Updaters = typeof updaters;
export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;
type CallbackUpdater<I extends readonly any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};
export type CombinedState = {
  values: State;
  updaters: TransformedUpdaters;
};
const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );
function getEditCheckInTaskVariables({
  taskId,
  taskDueDate,
  taskStartDate,
  selectedClasses,
  selectedStudents,
  excludedStudents,
}: State): editCheckInTaskMutationVariables {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  return {
    taskId,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    startDate: taskStartDate.toISOString(),
    first: RELAY_CONNECTION_MAX,
  };
}
export function useEditCheckInTaskState(
  task: CheckInTask,
  assignments: Assignments,
) {
  const [state, setState] = useState<State>({
    taskId: task.id,
    taskDueDate: new Date(task.dueDate),
    taskStartDate: new Date(task.startDate),
    selectedClasses: assignments.assignedClasses,
    selectedStudents: assignments.individuallyAssignedStudents.map(student => ({
      id: student.id,
      ...student.user,
    })),
    excludedStudents: excludedStudents(assignments.partiallyAssignedClasses),
  });
  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);
  const mutation = editCheckInTask(getEditCheckInTaskVariables(state));
  return [{ values: state, updaters: transformedUpdaters }, mutation] as const;
}
