/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type LanguageSelector_availableLanguages = {
    readonly availableLocales: ReadonlyArray<SyllabusLocale>;
    readonly " $refType": "LanguageSelector_availableLanguages";
};
export type LanguageSelector_availableLanguages$data = LanguageSelector_availableLanguages;
export type LanguageSelector_availableLanguages$key = {
    readonly " $data"?: LanguageSelector_availableLanguages$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LanguageSelector_availableLanguages">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LanguageSelector_availableLanguages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableLocales",
      "storageKey": null
    }
  ],
  "type": "Syllabus",
  "abstractKey": null
};
(node as any).hash = '07cf61b730cb389f9ada7a6c523f3906';
export default node;
