import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M4.31818 0H17.6818C19.7905 0 21.5 1.53502 21.5 3.42857V20.5714C21.5 22.465 19.7905 24 17.6818 24H4.31818C2.20946 24 0.5 22.465 0.5 20.5714V3.42857C0.5 1.53502 2.20946 0 4.31818 0ZM9.05763 6.70589C9.39499 6.64241 9.6842 6.58867 9.9349 6.54337L5.17875 10.2801C5.05202 10.3797 4.95247 10.5085 4.889 10.655C4.12462 12.4193 4.99218 13.5781 6.74051 13.2122C7.54392 13.0441 7.9325 12.8814 10.5161 11.7191C10.7341 11.621 10.9192 11.5384 11.0958 11.4605C13.273 10.5004 14.5983 10.1652 14.8169 10.351C14.937 10.4531 14.9224 10.4934 14.2266 11.021C13.4832 11.5847 13.2761 11.6997 10.1279 13.3681C7.30086 14.8663 5.87298 15.7944 5.00552 16.9351C3.57979 18.81 5.03689 20.2318 7.59262 20.0521C9.89642 19.89 13.3408 18.7078 17.479 16.5492C17.958 16.2993 18.1396 15.7163 17.8845 15.2471C17.6294 14.7778 17.0343 14.6 16.5553 14.8499C12.6381 16.8932 9.43604 17.9922 7.45187 18.1318C6.91756 18.1694 6.56173 18.1265 6.45627 18.1702C6.48593 18.1904 6.51471 18.1732 6.58163 18.0852C7.20829 17.2612 8.51545 16.4115 11.063 15.0614C14.3799 13.3037 14.556 13.2059 15.4296 12.5434C16.9619 11.3815 17.4485 10.0392 16.1051 8.89704C14.9725 7.93411 13.4069 8.33002 10.289 9.70494C10.1074 9.78504 9.91795 9.8696 9.69579 9.96955C8.60851 10.4587 7.93205 10.7602 7.46097 10.955L12.7186 6.82427L12.8895 6.65148C13.3407 6.06304 13.4906 5.4309 12.9844 4.86899C12.6345 4.48056 12.1874 4.38588 11.5711 4.39533C10.9475 4.40488 10.4035 4.49219 8.68686 4.81523C8.57197 4.83683 8.57197 4.83683 8.45734 4.85829C7.38914 5.05792 6.07932 4.51168 5.77015 4.30977C5.31858 4.01487 4.70847 4.13441 4.40743 4.57676C3.50051 5.90937 4.18825 7.02787 5.67443 7.11839C6.38856 7.16189 7.23052 7.04752 8.82564 6.74942C8.9419 6.72766 8.9419 6.72766 9.05763 6.70589Z" />
);

const Sketchpad = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={24}
    viewBoxWidth={22}
  >
    {path}
  </Icon>
);

Sketchpad.displayName = 'Sketchpad';

export default Sketchpad;
