import { compose, findIndex, find, update, omit, equals } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Point } from 'react-dnd';

import DnDRoot from 'ms-components/DnDRoot';
import Counter from 'ms-components/math/NumberBuilder/Counter';
import CountingBlock from 'ms-components/math/NumberBuilder/CountingBlock';
import type { ItemDropData } from 'ms-components/math/NumberBuilder/DraggableItem';
import ItemsBank from 'ms-components/math/NumberBuilder/ItemsBank';
import ItemsDropZone from 'ms-components/math/NumberBuilder/ItemsDropZone';
import genUniqueId from 'ms-utils/id-generator';
import { getInitializedDragDropContext } from 'ms-utils/react-dnd/dnd-backend';
import { unwrap } from 'ms-utils/typescript-utils';

const itemTypeToComponent = {
  blocks: CountingBlock,
  counter: Counter,
};

export type DropZone = {
  readonly id: string;
};

export type ItemBankItem = {
  readonly value: number;
  readonly shouldRespawn: boolean;
  readonly isUsed?: boolean;
};

export type DropZoneItem = {
  data: {
    value: number;
  };
  id?: number;
};

// NOTE TS can't express type spread in a backwards compatible way
// with Flow so we are manually writing out the whole type.
export type DropZoneItemWithId = {
  data: {
    value: number;
  };
  id: number;
};

export type Value = {
  itemsBankItems: ReadonlyArray<ItemBankItem>;
  values: { [dropZoneId: string]: ReadonlyArray<DropZoneItem> };
};

type ValueWithIds = {
  itemsBankItems: ReadonlyArray<ItemBankItem>;
  values: { [dropZoneId: string]: ReadonlyArray<DropZoneItemWithId> };
};

// We were originally going to support different kinds of
// items (eg. could be coins, banknotes, animals, etc.) but
// this never eventuated so it's no longer a union type.
export type ItemType = 'blocks';

export type DroppedItem = {
  value: number;
  dropOffset: Point;
};

export type Props = {
  readonly dropZones: ReadonlyArray<DropZone>;
  readonly itemType: ItemType;
  readonly value: Value;
  readonly onChange: (value: Value) => void;
  readonly isReadOnly?: boolean;
};

function addMissingIds(values: Value['values']) {
  let nextValues = {} as ValueWithIds['values'];
  for (const [dropZoneId, items] of Object.entries(values)) {
    nextValues[dropZoneId] = items.map(item => ({
      ...item,
      id: typeof item.id === 'number' ? item.id : genUniqueId(),
    }));
  }
  return nextValues;
}

function stripIds(values: {
  [dropZoneId: string]: ReadonlyArray<DropZoneItemWithId>;
}) {
  let nextValues = {} as Value['values'];
  for (const [dropZoneId, items] of Object.entries(values)) {
    nextValues[dropZoneId] = items.map(omit(['id']));
  }
  return nextValues;
}

function NumberBuilder({
  dropZones,
  itemType,
  value,
  onChange,
  isReadOnly = false,
}: Props) {
  const [values, setValues] = useState<{
    [dropZoneId: string]: ReadonlyArray<DropZoneItemWithId>;
  }>(addMissingIds(value.values));
  // The most recently dropped item.
  const [droppedItem, setDroppedItem] = useState<DroppedItem | undefined>(
    undefined,
  );
  const id = useRef(genUniqueId()).current;

  // Ensure that all the dropzone values have ids.
  useEffect(() => {
    // If values aren't changed do not update ids as this would break
    // handleZoneDropOutsideZone
    if (equals(value.values, stripIds(values))) return;
    setValues(addMissingIds(value.values));
  }, [value.values, values]);

  const handleValueChange = useCallback(
    (value: ValueWithIds) => {
      const values = stripIds(value.values);

      const nextValue: Value = {
        ...value,
        values: {
          ...values,
          // @ts-ignore Something whacky for the Configuror by the looks
          numberbuilder: values.numberbuilder?.map(j => ({
            data: { value: parseInt(j.data.value as any as string, 10) || 1 },
          })),
        },
      };

      onChange(nextValue);
    },
    [onChange],
  );

  const handleZoneDropOutsideZone = useCallback(
    (data: ItemDropData) => {
      const { itemId, itemData, fromContainer } = data;

      const itemIdxInBank = findIndex(
        item => item.value === itemData.value,
        value.itemsBankItems,
      );

      const newValue = {
        values: {
          ...values,
          // Remove the item from its container of origin.
          [fromContainer.id]: unwrap(values[fromContainer.id]).filter(
            ({ id }) => id !== itemId,
          ),
        },
        // Add the item back to the items bank.
        itemsBankItems: update(
          itemIdxInBank,
          { ...unwrap(value.itemsBankItems[itemIdxInBank]), isUsed: false },
          value.itemsBankItems,
        ),
      };

      handleValueChange(newValue);
    },
    [handleValueChange, value.itemsBankItems, values],
  );

  const handleZoneDropOnZone = useCallback(
    (data: ItemDropData) => {
      if (!data.droppedOnTarget) return;
      const { itemId, fromContainer, toContainer } = data;

      const movedItem = unwrap(
        find(v => v.id === itemId, unwrap(values[fromContainer.id])),
      );

      // Move the item from its container of origin, to its destination container.
      const nextValue = {
        ...value,
        values: {
          ...values,
          [fromContainer.id]: unwrap(values[fromContainer.id]).filter(
            ({ id }) => id !== itemId,
          ),
          [toContainer.id]: unwrap(values[toContainer.id]).concat(movedItem),
        },
      };

      handleValueChange(nextValue);
    },
    [handleValueChange, value, values],
  );

  const handleBankDropOnZone = useCallback(
    (data: ItemDropData) => {
      if (!data.droppedOnTarget) return;
      const { itemIndex, itemData, toContainer } = data;

      const newItem = {
        id: genUniqueId(),
        data: itemData,
      };

      // Add the item to its destination container
      let nextValue = {
        ...value,
        values: {
          ...values,
          [toContainer.id]: unwrap(values[toContainer.id]).concat(newItem),
        },
      };

      // Remove item from the bank if it doesn't respawn
      if (!unwrap(nextValue.itemsBankItems[itemIndex]).shouldRespawn) {
        nextValue = {
          ...nextValue,
          itemsBankItems: update(
            itemIndex,
            { ...unwrap(nextValue.itemsBankItems[itemIndex]), isUsed: true },
            nextValue.itemsBankItems,
          ),
        };
      }

      handleValueChange(nextValue);
    },
    [handleValueChange, value, values],
  );

  const handleZoneDrop = useCallback((droppedItem: DroppedItem) => {
    setDroppedItem(droppedItem);
  }, []);

  const itemComponent = useMemo(
    () => itemTypeToComponent[itemType],
    [itemType],
  );

  return (
    <div>
      {dropZones.map(zone => (
        <ItemsDropZone
          key={zone.id}
          id={zone.id}
          values={values[zone.id]}
          onItemDropOnZone={handleZoneDropOnZone}
          onItemDropOutsideZone={handleZoneDropOutsideZone}
          onZoneDrop={handleZoneDrop}
          itemType={itemType}
          itemComponent={itemComponent}
          isReadOnly={isReadOnly}
          numberBuilderId={id}
          droppedItem={droppedItem}
        />
      ))}

      <ItemsBank
        items={value.itemsBankItems}
        onItemDropOnZone={handleBankDropOnZone}
        itemComponent={itemComponent}
        isReadOnly={isReadOnly}
        numberBuilderId={id}
      />
    </div>
  );
}

const enhance = compose(getInitializedDragDropContext(), DnDRoot);

export { NumberBuilder as OnlyForTestsNumberBuilder };

// This HOC stuff is a mess and is breaking the types, just going to
// declare the interface as our wrapped component which is all we want
// @ts-ignore
export default enhance(NumberBuilder) as typeof NumberBuilder;
