import { EgyptianCatSvg } from 'ms-experiments/gamification/EgyptianCatSvg';
import { FoxSvg } from 'ms-experiments/gamification/FoxSvg';

import type { MonthYear } from './types';

const ITEM_STYLE = { width: 61, height: 61 };

export const DESTINATION_CONFIG = {
  Tokyo: {
    longName: 'Tokyo, Japan',
    backgroundName: 'Tokyo streets',
    itemName: 'Kitsune',
    itemJsxElement: <FoxSvg style={ITEM_STYLE} />,
  },
  Cairo: {
    longName: 'Cairo, Egypt',
    backgroundName: 'Giza landscape',
    itemName: 'Bastet',
    itemJsxElement: <EgyptianCatSvg style={ITEM_STYLE} />,
  },
};

export type Destination = keyof typeof DESTINATION_CONFIG;

export const DESTINATIONS_MONTH_MAP: Partial<Record<MonthYear, Destination>> = {
  '11-2024': 'Tokyo',
  '12-2024': 'Cairo',
};
