import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Folder = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={20}
    viewBoxWidth={20}
  >
    <path d="M11.056 4.04c-.38 0-.737-.185-.955-.497l-.834-1.197A2.558 2.558 0 0 0 7.168 1.25h-4.61A2.558 2.558 0 0 0 0 3.808V16.25c0 .678.27 1.33.749 1.81.48.478 1.131.748 1.81.748h14.883c.678 0 1.33-.27 1.81-.749.478-.48.748-1.13.748-1.809V6.599c0-.678-.27-1.33-.749-1.81a2.563 2.563 0 0 0-1.81-.748h-6.385Z" />
  </Icon>
);

Folder.displayName = 'Folder';

export default Folder;
