import InfoIcon from 'ms-pages/Lantern/components/icons/Info';
import { colors } from 'ms-pages/Lantern/styles';
import Button from 'ms-ui-primitives/Button';

function InfoButton({ onClick }: { onClick: () => void }) {
  return (
    <Button onClick={onClick} label="Info" size="small" isCircle>
      <InfoIcon color={colors.grey} size={16} />
    </Button>
  );
}

export { InfoButton };
