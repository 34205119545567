import styled from '@emotion/styled';
import { t, Trans } from '@lingui/macro';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { graphql, useFragment, useQuery } from 'relay-hooks';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import LanternIcon from 'ms-components/icons/Lantern';
import LockIcon from 'ms-components/icons/Lock2';
import PencilIcon from 'ms-components/icons/Pencil';
import { PageTimeErrorThrower } from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  SkillProficiencyIndicator,
  SkillProficiencyIndicatorLoading,
  SkillProficiencyIndicatorLocked,
} from 'ms-pages/Lantern/components/SkillProficiencyIndicator';
import Star from 'ms-pages/Lantern/components/icons/Star';
import { AnchorButton, Button } from 'ms-pages/Lantern/primitives/Button';
import { HSpacer, HStack, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import {
  BodyM,
  Bold,
  HeadingXXS,
} from 'ms-pages/Lantern/primitives/Typography';
import {
  getCheckInSkillUrl,
  STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME,
} from 'ms-pages/Lantern/utils/urls';
import { useStudentContext } from 'ms-pages/Lantern/views/Student';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import SubstrandAdaptivePracticeModal from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapCurriculum/SubstrandAdaptivePracticeModal';
import { colors } from 'ms-styles/colors';
import { InlinePopoverAnchor } from 'ms-ui-primitives/Popover/InlinePopover';
import { InvariantViolation } from 'ms-utils/app-logging';
import { tappable } from 'ms-utils/emotion';
import getProficiencyForOutcome from 'ms-utils/getProficiencyForOutcome';
import { useUncheckedQueryParam } from 'ms-utils/hooks/useQueryParam';
import { mapSanaProficiencyValueToMasteryLevel } from 'ms-utils/masteryLevel';
import { noop } from 'ms-utils/misc';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import SkillPopover from './SkillPopover';
import type {
  StudentSkillsMapSubstrandSkillsQuery,
  StudentSkillsMapSubstrandSkillsQueryResponse,
} from './__generated__/StudentSkillsMapSubstrandSkillsQuery.graphql';
import type { StudentSkillsMapSubstrandSkills_skillsBadge$key } from './__generated__/StudentSkillsMapSubstrandSkills_skillsBadge.graphql';
import type {
  StudentSkillsMapSubstrandSkills_studentStrandStatus$data,
  StudentSkillsMapSubstrandSkills_studentStrandStatus$key,
  StudentStrandStatusEnum,
} from './__generated__/StudentSkillsMapSubstrandSkills_studentStrandStatus.graphql';
import type {
  StudentSkillsMapSubstrandSkills_substrand$data,
  StudentSkillsMapSubstrandSkills_substrand$key,
} from './__generated__/StudentSkillsMapSubstrandSkills_substrand.graphql';
import Header from '../Header';
import SkillBlockPopover from '../SkillBlockPopover';
import { RecommendationCategoryButton } from '../SubtopicRecommendations/SubtopicRecommendationCard/SubtopicRecommendationCard';
import { GridItemButton, GridItemTitle, GroupVStack } from '../styles';
import {
  getCurrentGradeSubstrand,
  isStrandLocked as getIsStrandLocked,
  getPriorGradeSubstrands,
} from '../utils';
// This is a hack: we reuse `RecommendationCategoryButton` for styling,
// however we don't need it to be interactive.
const RecommendationCategoryButtonWrapper = styled.div<{}>({
  pointerEvents: 'none',
});
type Props = {
  substrandId: string;
  strandId: string;
  studentStrandStatus: StudentSkillsMapSubstrandSkills_studentStrandStatus$key;
  substrand: StudentSkillsMapSubstrandSkills_substrand$key;
};
const STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_STUDENTSTRANDSTATUS_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandSkills_studentStrandStatus on StudentStrandStatus {
    status
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_SUBSTRAND_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandSkills_substrand on Substrand {
    gradeSubstrands {
      id
      gradeStrand {
        grade {
          id
          order
        }
      }
      outcomes {
        id
        title
        code
        skill {
          id
          canStartCheckIn
          description
        }
        ...StudentSkillsMapSubstrandSkills_skillsBadge
        ...SkillBlockPopover_outcome
      }
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_QUERY = graphql`
  query StudentSkillsMapSubstrandSkillsQuery(
    $filters: [UserStatusFilterInput!]!
    $substrandId: ID!
    $previewingWithProblemData: Boolean!
    $growthPeriod: Int!
  ) {
    lantern {
      viewer {
        __typename
        ... on LanternStudent {
          userStatuses(
            filters: $filters
            previewingWithProblemData: $previewingWithProblemData
            growthPeriod: $growthPeriod
          ) {
            trueProficiency
            userStatusFilter {
              curriculumNodeIds
            }
          }
        }
      }
      substrand(id: $substrandId) {
        studentSubtopicRecommendations {
          category
          outcomes {
            id
            code
          }
        }
      }
    }
  }
`;
function StudentSkillsMapSubstrandSkills({
  substrandId,
  studentStrandStatus: studentStrandStatusRef,
  substrand: substrandRef,
  strandId,
}: Props) {
  const { selfReportedGradeOrder, selfReportedGradeTitle } =
    useStudentContext();
  const {
    featureFlags: { demoCheckin },
  } = useViewer();
  const substrand = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_SUBSTRAND_FRAGMENT,
    substrandRef,
  );
  const studentStrandStatus = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_STUDENTSTRANDSTATUS_FRAGMENT,
    studentStrandStatusRef,
  );
  const currentGradeSubstrand = getCurrentGradeSubstrand({
    substrand,
    grade: { order: selfReportedGradeOrder },
  });
  const priorGradeSubstrands = getPriorGradeSubstrands({
    substrand,
    grade: { order: selfReportedGradeOrder },
  });
  const outcomes = getOutcomesFromGradeSubtrands({
    gradeSubstrands: [
      ...(currentGradeSubstrand == null ? [] : [currentGradeSubstrand]),
      ...priorGradeSubstrands,
    ],
  });
  const { props, error } = useQuery<StudentSkillsMapSubstrandSkillsQuery>(
    STUDENT_SKILLS_MAP_SUBSTRAND_SKILLS_QUERY,
    {
      filters: outcomes.map(outcome => ({ curriculumNodeIds: [outcome] })),
      substrandId,
      previewingWithProblemData: false,
      growthPeriod: 120,
    },
  );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentSkillsMapSubstrand"
        componentName="StudentSkillsMapSubstrandSkills"
        error={error}
      />
    );
  }
  if (props == null) {
    return null;
  }
  const lantern = props?.lantern;
  // `lantern?` because props is an empty object if the query is skipped
  const viewer = lantern?.viewer;
  if (
    (lantern != null && viewer == null) ||
    (viewer != null && viewer.__typename !== 'LanternStudent')
  ) {
    throw new InvariantViolation('The logged in user must be a LanternStudent');
  }
  if (lantern != null && lantern.substrand == null) {
    throw new InvariantViolation('Substrand not found'); // substrand must exist at this point
  }
  const localisedYear = t`year`;
  return (
    <>
      {currentGradeSubstrand != null && (
        <>
          <Header
            title={`${selfReportedGradeTitle}`}
            tooltipContent={
              <BodyM color="white">
                Your <Bold>{localisedYear} level skills</Bold> that you're
                working towards mastering this year
              </BodyM>
            }
          />
          <GradeSubstrandSkillHeader />
          <GradeSubstrandSkillsList
            key={currentGradeSubstrand.id}
            strandId={strandId}
            gradeSubstrand={currentGradeSubstrand}
            viewer={viewer}
            studentStrandStatus={studentStrandStatus}
            dataTrackingId={
              demoCheckin
                ? 'DemoSkillsMap/SubstrandSkills/CurrentYear'
                : 'StudentSkillsMap/SubstrandSkills/CurrentYear'
            }
          />
        </>
      )}

      {currentGradeSubstrand != null && priorGradeSubstrands.length > 0 && (
        <VSpacer height={12} />
      )}

      {priorGradeSubstrands.length > 0 && (
        <>
          <Header
            title={`Prior ${localisedYear} levels`}
            tooltipContent={
              <BodyM color="white">
                Mastering these skills first will help you work towards
                mastering your <Bold>{localisedYear} level skills</Bold>
              </BodyM>
            }
          />
          <GradeSubstrandSkillHeader />
          {priorGradeSubstrands
            .slice()
            .reverse()
            .map(gradeSubstrand => (
              <Fragment key={gradeSubstrand.id}>
                <GradeSubstrandSkillsList
                  gradeSubstrand={gradeSubstrand}
                  viewer={viewer}
                  strandId={strandId}
                  studentStrandStatus={studentStrandStatus}
                  dataTrackingId={
                    demoCheckin
                      ? 'DemoSkillsMap/SubstrandSkills/PriorYears'
                      : 'StudentSkillsMap/SubstrandSkills/PriorYears'
                  }
                />
                <VSpacer height={24} />
              </Fragment>
            ))}
        </>
      )}
    </>
  );
}
function getDataId(outcomeId: string) {
  return `${outcomeId}-popover`;
}
function tryAndScrollToSkill(outcomeId: string) {
  const skillPopover = window?.document?.querySelector(
    `[data-id=${getDataId(outcomeId)}]`,
  );
  if (skillPopover != null) {
    scrollIntoView(skillPopover, {
      scrollMode: 'always',
      block: 'center',
      inline: 'center',
    });
  }
}
const BUTTON_WIDTH = 56;
const SKILL_BADGE_WIDTH = 40;
const HeaderRow = styled(HStack)({
  alignItems: 'center',
  width: '100%',
  padding: '0px 24px 16px',
});
const HeaderCell = styled.div<{
  width: number;
}>(({ width }) => ({
  width,
}));
const Row = styled(HStack)({
  alignItems: 'center',
  width: '100%',
  marginBottom: 8,
});
const ToolTipGroup = styled.div({
  display: 'flex',
  alignItems: 'center',
});
const ButtonGroup = styled.div({
  display: 'flex',
  margin: '0px 8px',
});
const ProficiencyItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  ...tappable,
});

const CheckInUnavailable = styled.div({
  width: 56,
  height: 38,
  backgroundColor: colors.porcelain,
  color: colors.grey10,
  borderRadius: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'not-allowed',
});

function CheckInUnavailableButton({
  version,
}: {
  version: 'mastered' | 'locked' | 'cooldown';
}) {
  const { icon, tooltipContent } = useMemo(() => {
    switch (version) {
      case 'mastered':
        return {
          icon: <Star color={colors.grey10} size={16} />,
          tooltipContent: "You've already mastered this skill",
        };
      case 'locked':
        return {
          icon: <LockIcon size={16} />,
          tooltipContent:
            'This skill is locked. Complete the discovery check-in to unlock it.',
        };
      case 'cooldown':
        return {
          icon: <LanternIcon size={16} />,
          tooltipContent: START_CHECK_IN_DISABLED_TOOLTIP_CONTENT,
        };
      default:
        assertUnreachable(version);
    }
  }, [version]);
  return (
    <Tooltip content={tooltipContent}>
      <CheckInUnavailable>{icon}</CheckInUnavailable>
    </Tooltip>
  );
}

const GradeSubstrandSkillHeader = () => (
  <HeaderRow>
    <HeaderCell width={SKILL_BADGE_WIDTH}>
      <HeadingXXS color="grey90">{'Skill'}</HeadingXXS>
    </HeaderCell>
    <HSpacer grow />
    <HeadingXXS color="grey90">
      <Trans>{'Outcome'}</Trans>
    </HeadingXXS>
    <HSpacer width={8} />
    <ButtonGroup>
      <HeaderCell width={BUTTON_WIDTH}>
        <HeadingXXS color="grey90">{'Practice'}</HeadingXXS>
      </HeaderCell>
      <HSpacer width={8} />
      <HeaderCell width={BUTTON_WIDTH}>
        <HeadingXXS color="grey90">{'Check-in'}</HeadingXXS>
      </HeaderCell>
    </ButtonGroup>
  </HeaderRow>
);
function GradeSubstrandSkillRow({
  skillId,
  strandId,
  viewer,
  studentStrandStatus,
  outcome,
  dataTrackingId,
}: {
  skillId: string;
  strandId: string;
  viewer: StudentSkillsMapSubstrandSkillsQueryResponse['lantern']['viewer'];
  studentStrandStatus: StudentSkillsMapSubstrandSkills_studentStrandStatus$data;
  outcome: StudentSkillsMapSubstrandSkills_substrand$data['gradeSubstrands'][number]['outcomes'][number];
  dataTrackingId: string;
}) {
  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();
  const proficiency = useMemo(() => {
    if (viewer === null) return null;
    if (!('userStatuses' in viewer)) return null;
    if (viewer.userStatuses === null) return null;
    return getProficiencyForOutcome({
      userStatuses: viewer.userStatuses,
      outcomeId: outcome.id,
    });
  }, [outcome.id, viewer]);
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(proficiency);
  const hasMasteredSkill = masteryLevel === 'MASTERED';
  const isStrandLocked = useMemo(
    () => getIsStrandLocked(studentStrandStatus, enableOptInDiscoveryCheckIn),
    [studentStrandStatus, enableOptInDiscoveryCheckIn],
  );
  const canStartCheckIn = outcome.skill.canStartCheckIn;
  const checkInUnavailable =
    isStrandLocked || !canStartCheckIn || hasMasteredSkill;

  const checkInUnavailableButton = (
    <CheckInUnavailableButton
      version={
        hasMasteredSkill ? 'mastered' : isStrandLocked ? 'locked' : 'cooldown'
      }
    />
  );
  const checkInButton = (
    <AnchorButton
      borderRadius={8}
      width={BUTTON_WIDTH}
      type="secondary"
      label="Check-in"
      trackingId={`${dataTrackingId}/checkin`}
      href={getCheckInSkillUrl({ skillId, strandId })}
    >
      <LanternIcon size={16} />
    </AnchorButton>
  );
  let [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <Row>
      <InlinePopoverAnchor>
        <ProficiencyItem
          onClick={() => {
            setIsPopoverOpen(true);
          }}
        >
          <Tooltip content={outcome.skill.description} maxWidth={350}>
            <ToolTipGroup>
              {viewer == null ? (
                <SkillProficiencyIndicatorLoading width={SKILL_BADGE_WIDTH} />
              ) : isStrandLocked && proficiency == null ? (
                <SkillProficiencyIndicatorLocked
                  hidePadlock
                  width={SKILL_BADGE_WIDTH}
                />
              ) : (
                <SkillProficiencyIndicator
                  value={proficiency}
                  width={SKILL_BADGE_WIDTH}
                />
              )}{' '}
            </ToolTipGroup>
          </Tooltip>
          {isPopoverOpen && (
            <SkillBlockPopover
              onDismiss={() => {
                setIsPopoverOpen(false);
              }}
              outcome={outcome}
              isStrandLocked={isStrandLocked}
              proficiency={proficiency}
              omitTrianglePointer
            />
          )}
        </ProficiencyItem>
      </InlinePopoverAnchor>
      <HSpacer width={16} />
      <BodyM color="shuttleGray">{outcome.title}</BodyM>
      <HSpacer grow />
      <BodyM color="grey90">{outcome.code}</BodyM>
      <HSpacer width={8} />
      <ButtonGroup>
        <Button
          borderRadius={8}
          width={BUTTON_WIDTH}
          type="secondary"
          label="Practice"
          onClick={() => setIsModalOpen(true)}
          trackingId={`${dataTrackingId}/practice`}
        >
          <PencilIcon size={16} color={colors.eggplant} />
        </Button>
        <HSpacer width={8} />
        {checkInUnavailable ? checkInUnavailableButton : checkInButton}
      </ButtonGroup>

      {isModalOpen && (
        <SubstrandAdaptivePracticeModal
          outcome={outcome.id}
          onDismiss={() => setIsModalOpen(false)}
        />
      )}
    </Row>
  );
}
function GradeSubstrandSkillsList({
  strandId,
  gradeSubstrand,
  viewer,
  studentStrandStatus,
  dataTrackingId,
}: {
  strandId: string;
  gradeSubstrand: StudentSkillsMapSubstrandSkills_substrand$data['gradeSubstrands'][number];
  viewer: StudentSkillsMapSubstrandSkillsQueryResponse['lantern']['viewer'];
  studentStrandStatus: StudentSkillsMapSubstrandSkills_studentStrandStatus$data;
  dataTrackingId: string;
}) {
  return (
    <GroupVStack>
      {gradeSubstrand.outcomes.map((outcome, index, arr) => (
        <Fragment key={outcome.id}>
          <GradeSubstrandSkillRow
            skillId={outcome.skill.id}
            strandId={strandId}
            viewer={viewer}
            studentStrandStatus={studentStrandStatus}
            outcome={outcome}
            dataTrackingId={dataTrackingId}
          />
          {index !== arr.length - 1}
        </Fragment>
      ))}
    </GroupVStack>
  );
}
const SKIlLS_BADGE_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandSkills_skillsBadge on LanternOutcome {
    id
    title
    code
    skill {
      id
    }
    ...SkillPopover_outcome
  }
`;
export function SkillBadge({
  outcome: outcomeRef,
  viewer,
  studentStrandStatus,
  recommendationCategory,
  onClick,
}: {
  outcome: StudentSkillsMapSubstrandSkills_skillsBadge$key;
  // TODO this poorly implemented. Should be passed a fragment key
  viewer:
    | {
        readonly userStatuses:
          | ReadonlyArray<{
              readonly trueProficiency: number | null | undefined;
              readonly userStatusFilter: {
                readonly curriculumNodeIds: ReadonlyArray<string>;
              };
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  studentStrandStatus: {
    readonly status: StudentStrandStatusEnum;
  };
  recommendationCategory: string | null | undefined;
  onClick?: (() => void) | undefined;
}) {
  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();

  const [skillPopoverOpenForOutcomeId, setSkillPopoverOpenForOutcomeId] =
    useUncheckedQueryParam(
      STUDENT_SKILLS_MAP_SKILL_TOOLTIP_SHOWN_QUERY_PARAM_NAME,
    );
  const outcome = useFragment(SKIlLS_BADGE_FRAGMENT, outcomeRef);
  const isPopoverOpen = skillPopoverOpenForOutcomeId === outcome.id;
  useEffect(() => {
    if (
      skillPopoverOpenForOutcomeId != null &&
      skillPopoverOpenForOutcomeId === outcome.id
    ) {
      tryAndScrollToSkill(skillPopoverOpenForOutcomeId);
    }
  }, [outcome.id, skillPopoverOpenForOutcomeId]);
  const proficiency = useMemo(() => {
    if (viewer == null) return null;
    if (!('userStatuses' in viewer)) return null;
    if (viewer.userStatuses == null) return null;
    return getProficiencyForOutcome({
      userStatuses: viewer.userStatuses,
      outcomeId: outcome.id,
    });
  }, [outcome.id, viewer]);
  const isStrandLocked = useMemo(
    () => getIsStrandLocked(studentStrandStatus, enableOptInDiscoveryCheckIn),
    [studentStrandStatus, enableOptInDiscoveryCheckIn],
  );
  return (
    <InlinePopoverAnchor>
      <GridItemButton
        onClick={onClick ?? (() => setSkillPopoverOpenForOutcomeId(outcome.id))}
      >
        {viewer == null ? (
          <SkillProficiencyIndicatorLoading />
        ) : isStrandLocked && proficiency == null ? (
          <SkillProficiencyIndicatorLocked />
        ) : (
          <SkillProficiencyIndicator value={proficiency} />
        )}

        {isPopoverOpen && (
          <SkillPopover
            onDismiss={() => setSkillPopoverOpenForOutcomeId(null)}
            outcome={outcome}
            isStrandLocked={isStrandLocked}
            proficiency={proficiency}
            dataId={getDataId(outcome.id)}
          />
        )}

        <VSpacer height={12} />
        <GridItemTitle>
          <BodyM color="shuttleGray">{outcome.title}</BodyM>
        </GridItemTitle>
        {recommendationCategory != null && (
          <RecommendationCategoryButtonWrapper>
            <VSpacer height={8} />
            <RecommendationCategoryButton
              title={recommendationCategory}
              onClick={noop}
            />
          </RecommendationCategoryButtonWrapper>
        )}
      </GridItemButton>
    </InlinePopoverAnchor>
  );
}
function getOutcomesFromGradeSubtrands({
  gradeSubstrands,
}: {
  gradeSubstrands: StudentSkillsMapSubstrandSkills_substrand$data['gradeSubstrands'];
}): ReadonlyArray<string> {
  return gradeSubstrands.flatMap(gradeSubstrand =>
    gradeSubstrand.outcomes.map(outcome => outcome.id),
  );
}
export default StudentSkillsMapSubstrandSkills;
