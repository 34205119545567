import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

export function StatisticsCalculatorIcon() {
  return (
    <Icon size={32} viewBoxWidth={32} viewBoxHeight={32}>
      <rect width="32" height="32" rx="4" fill="currentColor" />
      <path
        d="M8.96875 15.375C9.44147 15.375 9.86932 15.1853 10.1855 14.8808L11.92 15.7479C11.9113 15.8186 11.8984 15.888 11.8984 15.9609C11.8984 16.9302 12.687 17.7188 13.6562 17.7188C14.6255 17.7188 15.4141 16.9302 15.4141 15.9609C15.4141 15.6904 15.3475 15.4371 15.2378 15.2079L17.5907 12.855C17.8199 12.9647 18.0732 13.0312 18.3438 13.0312C19.313 13.0312 20.1016 12.2427 20.1016 11.2734C20.1016 11.0909 20.0657 10.9184 20.0138 10.7528L22.0582 9.21991C22.3371 9.40622 22.6714 9.51562 23.0312 9.51562C24.0005 9.51562 24.7891 8.72705 24.7891 7.75781C24.7891 6.78857 24.0005 6 23.0312 6C22.062 6 21.2734 6.78857 21.2734 7.75781C21.2734 7.94031 21.3093 8.11288 21.3612 8.27844L19.3168 9.81134C19.0379 9.62503 18.7036 9.51562 18.3438 9.51562C17.3745 9.51562 16.5859 10.3042 16.5859 11.2734C16.5859 11.544 16.6525 11.7973 16.7622 12.0265L14.4093 14.3794C14.1801 14.2697 13.9268 14.2031 13.6562 14.2031C13.1835 14.2031 12.7557 14.3928 12.4395 14.6974L10.705 13.8302C10.7137 13.7596 10.7266 13.6901 10.7266 13.6172C10.7266 12.6479 9.93799 11.8594 8.96875 11.8594C7.99951 11.8594 7.21094 12.6479 7.21094 13.6172C7.21094 14.5864 7.99951 15.375 8.96875 15.375V15.375Z"
        fill={colors.white}
      />
      <path
        d="M25.4141 24.8281H24.7891V12.4453C24.7891 12.1215 24.5269 11.8594 24.2031 11.8594H21.8594C21.5356 11.8594 21.2734 12.1215 21.2734 12.4453V24.8281H20.1016V15.9609C20.1016 15.6371 19.8394 15.375 19.5156 15.375H17.1719C16.8481 15.375 16.5859 15.6371 16.5859 15.9609V24.8281H15.4141V20.6484C15.4141 20.3246 15.1519 20.0625 14.8281 20.0625H12.4844C12.1606 20.0625 11.8984 20.3246 11.8984 20.6484V24.8281H10.7266V18.3047C10.7266 17.9809 10.4644 17.7188 10.1406 17.7188H7.79688C7.47308 17.7188 7.21094 17.9809 7.21094 18.3047V24.8281H6.58594C6.26215 24.8281 6 25.0903 6 25.4141C6 25.7379 6.26215 26 6.58594 26H25.4141C25.7379 26 26 25.7379 26 25.4141C26 25.0903 25.7379 24.8281 25.4141 24.8281Z"
        fill={colors.white}
      />
    </Icon>
  );
}
export default StatisticsCalculatorIcon;
