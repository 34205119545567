/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubproblemType = "ALGEBRAIC" | "BOXPLOT" | "EQUATION" | "GEOMETRY" | "GRAPHPLOT" | "HISTOGRAM" | "INEQUALITY" | "INLINE" | "MULTIPLE_CHOICE" | "NUMBER_BUILDER" | "NUMBER_LINE" | "NUMERIC" | "PROBABILITY_TREE";
export type SolutionModal_problemContent = {
    readonly id: string;
    readonly instruction: string;
    readonly subproblems: ReadonlyArray<{
        readonly subproblemType: SubproblemType;
        readonly id: string;
        readonly instruction: string | null;
        readonly solutionSteps: ReadonlyArray<{
            readonly hint: string | null;
            readonly secondHint: string | null;
            readonly answer: {
                readonly " $fragmentRefs": FragmentRefs<"AnswerAttempt_data">;
            };
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"SubproblemInput_subproblem">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"PreviewProblemAttachment_problemContent">;
    readonly " $refType": "SolutionModal_problemContent";
};
export type SolutionModal_problemContent$data = SolutionModal_problemContent;
export type SolutionModal_problemContent$key = {
    readonly " $data"?: SolutionModal_problemContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SolutionModal_problemContent">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "instruction",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SolutionModal_problemContent",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SubproblemContent",
      "kind": "LinkedField",
      "name": "subproblems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subproblemType",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SolutionStep",
          "kind": "LinkedField",
          "name": "solutionSteps",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hint",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "secondHint",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "answer",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AnswerAttempt_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SubproblemInput_subproblem"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewProblemAttachment_problemContent"
    }
  ],
  "type": "ProblemContent",
  "abstractKey": null
};
})();
(node as any).hash = '364a07be6837730f505fe4aa758be511';
export default node;
