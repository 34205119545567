import styled from '@emotion/styled';
import { Fragment, Suspense, useEffect } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { SearchModeEmptyState } from 'ms-pages/Textbooks/components/SearchModeEmptyState';
import { colors } from 'ms-styles/colors';
import { VSpacer } from 'ms-ui-primitives/Stack';
import type { SetState } from 'ms-utils/typescript-utils';

import SearchResultHit from './SearchResultHit';
import type { TextbookOptionsArray } from './TextbookFilter';
import type {
  MTSSearchResultsQuery,
  TextbookSearchFilterSubtopicContentEnum,
} from './__generated__/MTSSearchResultsQuery.graphql';
import {
  FILTER_HEIGHT,
  SEARCH_INPUT_AND_RESULTS_SPACER_HEIGHT,
  SEARCH_INPUT_HEIGHT,
} from './constants';

const SearchResultsWrapper = styled.div({
  height: `calc(100% - ${
    SEARCH_INPUT_HEIGHT +
    2 * SEARCH_INPUT_AND_RESULTS_SPACER_HEIGHT +
    FILTER_HEIGHT +
    8 // buffer for CATFA modal - (borderRadius = 16) / 2
  }px)`,
  width: '100%',
  overflowY: 'auto',
  paddingRight: 15,
  // Overriding the default highlight style of HTML
  // only for elements under the search results
  mark: {
    background: 'none',
    color: colors.tangerine,
    fontWeight: 'bold',
  },
});

const MULTI_TEXTBOOK_SEARCH_QUERY = graphql`
  query MTSSearchResultsQuery(
    $search: String!
    $filterBySubtopicContent: TextbookSearchFilterSubtopicContentEnum
    $syllabusIds: [ID!]
  ) {
    multiTextbookSearch(
      q: $search
      filterSubtopicContent: $filterBySubtopicContent
      syllabusIds: $syllabusIds
    ) {
      found
      searchQuery
      hits {
        document {
          subtopicId
          topicId
          textbook
          syllabusId: textbookId
          title
          topicBadgeUrl
          contentSnippet
          highlights {
            field
            matchedTokens
            snippet
          }
        }
      }
    }
  }
`;

type Props = {
  searchTerm: string;
  selectedTextbookIds: TextbookOptionsArray;
  filterBySubtopicContent?: TextbookSearchFilterSubtopicContentEnum | undefined;
  setActiveTextbookData: SetState<
    | {
        syllabusId: string;
        subtopicId: string;
        topicId: string;
        textbookName: string;
      }
    | null
    | undefined
  >;
  setSnowplowSearchData: SetState<
    | {
        numberOfResults: number;
        searchTerm: string;
      }
    | null
    | undefined
  >;
  onResultClick?:
    | (({
        syllabusId,
        subtopicId,
        title,
      }: {
        syllabusId: string;
        subtopicId: string;
        title: string;
      }) => void)
    | undefined;
};

const MTSSearchResults = (props: Props) => {
  return (
    <ErrorBoundaryWithRetry
      fallback={({ error, retry }) => (
        <Retry retry={retry} message={error.message} />
      )}
      name="MultiTextbookSearchModalSearchResults"
    >
      {({ fetchKey, retry }) => (
        <Suspense fallback={<MinorSpinner />}>
          <MTSSearchResultsInnerWrapper
            {...props}
            fetchKey={fetchKey}
            retry={retry}
          />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
};

const MTSSearchResultsInnerWrapper = (
  props: Props & { fetchKey: number; retry: () => void },
) => {
  const { selectedTextbookIds, retry } = props;

  // We need to refetch the search results/update the fetchKey
  // whenever the selected textbooks change to trigger an update.
  // While this isn't ideal, it looks like, sometimes,
  // useLazyLoadQuery holds onto the old data and doesn't refetch
  // when the query variables change.
  // See: https://github.com/facebook/relay/issues/3502
  // Do not put this inside the below MTSSearchResultsInner component
  // otherwise it would cause re-rendering twice
  useEffect(() => {
    retry();
  }, [retry, selectedTextbookIds]);
  return <MTSSearchResultsInner {...props} />;
};

const MTSSearchResultsInner = ({
  searchTerm,
  selectedTextbookIds,
  filterBySubtopicContent,
  setActiveTextbookData,
  setSnowplowSearchData,
  onResultClick,
  fetchKey,
}: Props & { fetchKey: number }) => {
  const data = useLazyLoadQuery<MTSSearchResultsQuery>(
    MULTI_TEXTBOOK_SEARCH_QUERY,
    {
      search: searchTerm,
      syllabusIds: [...selectedTextbookIds],
      ...(filterBySubtopicContent != null ? { filterBySubtopicContent } : {}),
    },
    { fetchKey, fetchPolicy: 'network-only' },
  );

  const multiTextbookSearch = data.multiTextbookSearch;
  const { hits } = multiTextbookSearch;

  useEffect(() => {
    if (searchTerm !== '') {
      setSnowplowSearchData({
        // sending back the real number of results found to the snowplow
        // instead of the number of hits returned which is limited
        numberOfResults: multiTextbookSearch.found,
        searchTerm: multiTextbookSearch.searchQuery,
      });
    }
  }, [multiTextbookSearch, searchTerm, setSnowplowSearchData]);

  return (
    <>
      {hits.length > 0 && (
        <>
          <VSpacer height={SEARCH_INPUT_AND_RESULTS_SPACER_HEIGHT} />
          <SearchResultsWrapper>
            {hits.map((hit, index) => {
              return (
                <Fragment key={index}>
                  <SearchResultHit
                    hit={hit}
                    onResultClick={props => {
                      setActiveTextbookData(props);
                      onResultClick?.(props);
                    }}
                  />
                  {index < hits.length - 1 && <VSpacer height={16} />}
                </Fragment>
              );
            })}
          </SearchResultsWrapper>
        </>
      )}
      {hits.length === 0 && (
        <SearchModeEmptyState
          searchString={searchTerm}
          isCentered
          isSearchModal
          enableMultiTextbookSearch
        />
      )}
    </>
  );
};

export default MTSSearchResults;
