import React from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { getSelfDirectedAdaptiveTaskUrl } from 'ms-utils/urls';
import { getTeacherSelfDirectedAdaptiveTaskRedirectorUrl } from 'ms-utils/urls/sunflower/sunflowerTeacher';

import AdaptiveTaskTeacherAction from './AdaptiveTaskTeacherAction';

type Props = {
  subtopicId: string;
  subtopicTitle: string;
};

export default function AdaptiveTaskAction({
  subtopicId,
  subtopicTitle,
}: Props) {
  const { role } = useViewer();

  return (
    <>
      {role === 'Teacher' ? (
        <AdaptiveTaskTeacherAction
          subtopicId={subtopicId}
          subtopicTitle={subtopicTitle}
        />
      ) : (
        <>
          <AnchorButton
            type="primary"
            size="lanternSmall"
            href={getSelfDirectedAdaptiveTaskUrl({
              subtopicId,
              isSubtopicRecommendation: false,
            })}
          >
            Start Practicing
          </AnchorButton>
        </>
      )}
    </>
  );
}

type AdaptiveTaskSecondaryActionProps = { subtopicId: string };

export function AdaptiveTaskSecondaryAction({
  subtopicId,
}: AdaptiveTaskSecondaryActionProps) {
  const { role } = useViewer();
  return (
    <React.Suspense fallback={null}>
      {role === 'Teacher' && (
        <AnchorButton
          isRound
          type="secondary"
          size="lanternSmall"
          href={getTeacherSelfDirectedAdaptiveTaskRedirectorUrl({
            subtopicId,
          })}
          trackingId="Textbooks/SunflowerSubtopicDetailView/PreviewPractice"
        >
          Preview practice
        </AnchorButton>
      )}
    </React.Suspense>
  );
}
