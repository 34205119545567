import styled from '@emotion/styled';
import { type ReactNode, useMemo } from 'react';

import * as Icons from 'ms-components/icons';
import type { Color } from 'ms-pages/Lantern/styles';
import {
  borderRadiusCircle,
  fontFamily,
  fontSize,
  fontWeight,
  transition,
} from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable, onPressOrHover } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';
import { noop } from 'ms-utils/misc';

const customProps = ['selected', 'isTappable', 'small', 'large', 'disabled'];
const FilterStyle = styled('div', {
  shouldForwardProp: prop => !customProps.includes(prop),
})<{
  selected: boolean;
  isTappable: boolean;
  small: boolean;
  large: boolean;
  disabled: boolean;
}>(({ selected, isTappable, small, large, disabled }) => ({
  display: 'inline-flex',
  height: small ? 28 : large ? 40 : 32,
  justifyContent: 'center',
  gap: 8,
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: `${2 * BASE_UNIT}px ${4 * BASE_UNIT}px`,
  paddingLeft: small ? 2 * BASE_UNIT : undefined,
  paddingRight: small ? 2 * BASE_UNIT : undefined,
  borderRadius: borderRadiusCircle,
  fontFamily: fontFamily.body,
  fontSize: small ? 13 : fontSize.small,
  transition: `background-color ${transition}, color ${transition}`,
  whiteSpace: 'nowrap',
  borderWidth: 1,
  borderStyle: 'solid',
  ...(isTappable ? tappable : {}),
  fontWeight: selected ? fontWeight.semibold : fontWeight.normal,
  backgroundColor: selected ? colors.eggplant10 : undefined,
  borderColor: selected ? colors.eggplant90 : colors.iron,
  color: disabled
    ? colors.iron
    : selected
    ? colors.eggplant
    : colors.cloudBurst,
  ...(disabled
    ? null
    : onPressOrHover({
        backgroundColor: selected ? alternateColors.eggplant10 : undefined,
        borderColor: selected
          ? alternateColors.eggplant90
          : alternateColors.iron,
        color: selected ? alternateColors.eggplant : alternateColors.cloudBurst,
      })),
  ...(disabled ? { cursor: 'not-allowed' } : {}),
}));

const PopoverAnchor = styled.div({
  position: 'relative',
});

type Props = {
  label: ReactNode;
  selected: boolean;
  onClick?: (() => void) | undefined;
  popover?: ReactNode | undefined;
  popoverAnchorRef?: { current: HTMLDivElement | null } | undefined;
  isSmall?: boolean | undefined;
  isLarge?: boolean | undefined;
  disabled?: boolean;
  arrowIconColor?: Color | undefined;
  style?: React.CSSProperties;
};

const Filter = ({
  label,
  selected,
  onClick,
  popover,
  popoverAnchorRef,
  isSmall = false,
  isLarge = false,
  disabled = false,
  arrowIconColor,
  style,
}: Props) => {
  const hasPopover = popover != null && popoverAnchorRef != null;

  // we cannot pass icon props as undefined,
  // so we need to omit them if they are not set
  const iconProps = {
    ...(arrowIconColor != null && { color: colors[arrowIconColor] }),
  };

  const action = disabled || onClick == null ? noop : onClick;

  const onKeyDown = useMemo(
    () =>
      keyDownMap({
        ENTER: [action, { preventDefault: true }],
        SPACE: [action, { preventDefault: true }],
      }),
    [action],
  );

  const filter = (
    <FilterStyle
      style={style}
      onClick={action}
      selected={selected}
      isTappable={onClick != null}
      small={isSmall}
      large={isLarge}
      disabled={disabled}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {label}
      {hasPopover && <Icons.TriangleDown {...iconProps} />}
    </FilterStyle>
  );

  return hasPopover ? (
    <PopoverAnchor ref={popoverAnchorRef}>
      {filter}
      {popover}
    </PopoverAnchor>
  ) : (
    filter
  );
};

export default Filter;
