import styled from '@emotion/styled';

import { SkillProficiencyIndicator } from 'ms-pages/Lantern/components/SkillProficiencyIndicator';
import { VStack, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-pages/Lantern/styles';
import { borderRadiusUIExtraLarge } from 'ms-styles/base';
import {
  mapSanaProficiencyValueToMasteryLevel,
  type MasteryLevel,
} from 'ms-utils/masteryLevel';

const CenterText = styled.div({
  textAlign: 'center',
});

const Card = styled(VStack, {
  shouldForwardProp: prop => prop !== 'hasOffset',
})<{ hasOffset?: boolean }>(({ hasOffset }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 208,
  padding: 24,
  backgroundColor: colors.white,
  borderRadius: borderRadiusUIExtraLarge,
  textAlign: 'left', // CheckInComplete parent has `text-align: 'center'`

  ...(hasOffset && { marginLeft: 24 }),
}));

type Props = { skillTitle: string; proficiency: number };

function ProficiencyCard({ skillTitle, proficiency }: Props) {
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(proficiency);
  const animatedMasteryLevels: MasteryLevel[] = [
    'MASTERED',
    'PROFICIENT',
    'FAMILIAR',
  ];
  const shouldAnimate =
    masteryLevel === undefined
      ? false
      : animatedMasteryLevels.includes(masteryLevel);
  return (
    <Card>
      <SkillProficiencyIndicator
        width={68}
        shouldAnimate={shouldAnimate}
        didGrow
        value={proficiency}
      />
      <VSpacer height={4} />
      <CenterText>
        <BodyM>{skillTitle}</BodyM>
      </CenterText>
    </Card>
  );
}

export { ProficiencyCard };
