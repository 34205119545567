import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Resize = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
    color={color}
  >
    <rect
      x="15.5355"
      y="9.87866"
      width="8"
      height="2"
      rx="1"
      transform="rotate(135 15.5355 9.87866)"
      fill={color}
    />
    <rect
      x="0.221802"
      y="14.364"
      width="20"
      height="2"
      rx="1"
      transform="rotate(-45 0.221802 14.364)"
      fill={color}
    />
  </Icon>
);

Resize.displayName = 'Resize';

export default Resize;
