import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M14.9080879,9.28328819 L17.1018909,7.46189264 C17.6815709,6.9806158 18.5416463,7.06038731 19.0229232,7.6400673 C19.5042,8.21974729 19.4244285,9.07982273 18.8447485,9.56109957 L14.5383804,13.1364433 C14.0721531,13.5235266 13.4064708,13.5566721 12.9040978,13.2178172 L8.37653596,10.1639382 C7.75191445,9.74262564 7.58709995,8.89472857 8.00841248,8.27010705 C8.42972501,7.64548554 9.27762209,7.48067104 9.9022436,7.90198357 L12.1759903,9.43564509 C11.6691703,6.56133917 10.2757103,5.30512381 7.89375597,5.30512381 C5.14259111,5.30512381 3.63723665,7.66127936 3.44648765,13.0835806 C3.41999943,13.8365446 2.78812863,14.4254695 2.03516471,14.3989812 C1.2822008,14.372493 0.693275886,13.7406222 0.719764107,12.9876583 C0.955600299,6.28369188 3.32399924,2.57671358 7.89375597,2.57671358 C11.8385754,2.57671358 14.2160448,4.95207495 14.9080879,9.28328819 Z M10.2683566,14.8545596 L17.5441172,14.8545596 C18.0464037,14.8545596 18.4535873,15.2617432 18.4535873,15.7640297 C18.4535873,16.2663162 18.0464037,16.6734998 17.5441172,16.6734998 L10.2683566,16.6734998 C9.76607012,16.6734998 9.3588865,16.2663162 9.3588865,15.7640297 C9.3588865,15.2617432 9.76607012,14.8545596 10.2683566,14.8545596 Z" />
);

const NextStep = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

NextStep.displayName = 'NextStep';

export default NextStep;
