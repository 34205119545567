import { css } from '@emotion/css';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { VStack } from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString } from 'ms-utils/colors';

import type { TugOfWarInstructions_task$key } from './__generated__/TugOfWarInstructions_task.graphql';
import type { GameModalContent as TGameModalContent } from '../GameModal';
import { GameModalContent, GameModalTitle } from '../GameModal/primitives';

export function TugOfWarInstructions({
  taskKey,
  onContinue,
}: {
  taskKey: TugOfWarInstructions_task$key;
  onContinue: (newContent: TGameModalContent) => void;
}) {
  const { assignedStudentsClasses } = useFragment(
    graphql`
      fragment TugOfWarInstructions_task on TaskInterface {
        ... on CustomTask {
          assignedStudentsClasses {
            id
          }
        }
      }
    `,
    taskKey,
  );
  const taskIsAssignedToMultipleClasses =
    assignedStudentsClasses != null && assignedStudentsClasses.length > 1;

  return (
    <GameModalContent>
      <GameModalTitle>Start Tug of War</GameModalTitle>
      <VStack gap={8}>
        <p>How It Works:</p>
        <ol className={styles.list}>
          <li>Students will be divided into two teams.</li>
          <li>Project the game screen onto a classroom projector.</li>
          <li>Ask students to navigate to the task.</li>
          <li>Start the timer.</li>
          <li>Watch as activities come through.</li>
        </ol>
      </VStack>
      <div>
        <Button
          type="primary"
          isDisabled={taskIsAssignedToMultipleClasses}
          isRound
          onClick={() => onContinue('GameSetup')}
        >
          Continue
        </Button>
      </div>

      {taskIsAssignedToMultipleClasses && (
        <div className={styles.errorMessage}>
          ⚠️ Oops! Tug of War tasks can only be assigned to <u>one</u> class.
          Please assign this task to a single class and try again.
        </div>
      )}
    </GameModalContent>
  );
}

const styles = {
  list: css({
    counterReset: 'list',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    listStyle: 'none',

    '> li': {
      alignItems: 'center',
      counterIncrement: 'list',
      display: 'flex',
      gap: 8,

      '::before': {
        border: `2px solid ${colors.porcelain}`,
        borderRadius: 8,
        boxSizing: 'border-box',
        color: colors.grey,
        content: 'counter(list)',
        fontFamily: fontFamily.heading,
        fontSize: 16,
        fontWeight: 800,
        height: 24,
        lineHeight: '20px',
        textAlign: 'center',
        width: 24,
      },
    },
  }),
  errorMessage: css({
    backgroundColor: hexStringToRgbaString(colors.brickRed, 0.1),
    borderRadius: 8,
    color: colors.brickRed,
    fontWeight: 600,
    padding: '12px 16px',
  }),
} as const;
