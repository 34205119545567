import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const NoteIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={14}
    viewBoxHeight={14}
  >
    <g clipPath="url(#clip0_4092_126467)">
      <path
        d="M12.32 13.9998H0.732574C0.376596 13.9998 0.0888672 13.7115 0.0888672 13.3561C0.0888672 13.0008 0.376596 12.7124 0.732574 12.7124H12.32C12.676 12.7124 12.9637 13.0008 12.9637 13.3561C12.9637 13.7115 12.676 13.9998 12.32 13.9998Z"
        fill={color}
      />
      <path
        d="M13.3636 2.04687L11.881 0.564373C11.1645 -0.152768 10.0058 -0.190724 9.24556 0.474907L3.1904 5.73241C3.05532 5.84958 2.9754 6.01755 2.96903 6.19589C2.96514 6.31105 3.0018 6.41926 3.05591 6.51898L2.05033 8.26811C1.90487 8.52047 1.94742 8.8385 2.15335 9.04442L2.37872 9.26979L0.682407 10.9705C0.498289 11.1546 0.443596 11.4315 0.543317 11.6716C0.643155 11.9123 0.878077 12.0687 1.13822 12.0687H3.71317C3.86322 12.0687 4.00868 12.016 4.12455 11.9206L4.61891 11.51L4.88412 11.7752C5.00777 11.8994 5.17315 11.9638 5.33994 11.9638C5.44933 11.9638 5.56013 11.9361 5.66055 11.8782L7.40826 10.8733C7.50162 10.9241 7.60205 10.9596 7.71014 10.9596H7.73277C7.91099 10.9531 8.07908 10.8733 8.19625 10.7381L13.4525 4.68499C14.1187 3.92211 14.08 2.76342 13.3636 2.04687ZM5.44555 10.5161L3.74476 8.81528C3.74405 8.81457 3.74346 8.81339 3.74275 8.81268C3.74217 8.81209 3.74087 8.81139 3.74016 8.8108L3.41188 8.4824L3.97968 7.49556L6.43298 9.94886L5.44555 10.5161Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_4092_126467">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

NoteIcon.displayName = ' NoteIcon';

export default NoteIcon;
