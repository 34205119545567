import { Fragment, Suspense, useContext, useEffect } from 'react';
import { graphql, useLazyLoadQuery, useFragment } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import {
  PageTimeRecorder,
  PageTimeErrorRecorder,
} from 'ms-helpers/PageTimeTracker';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { Base as CustomEmptyMessage } from 'ms-pages/Teacher/components/Empty';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { COLLECTED_ITEM_COUNT_INDICATOR_BUBBLE_ID } from 'ms-pages/Teacher/components/Navbar/NavbarLeftWhite/CollectedItemCountIndicatorBubble';
import {
  UpdatersContext as ProblemsCollectionUpdaters,
  StateContext,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import { MAX_NUMBER_OF_QUESTIONS } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';
import type { DifficultyLevelTypes } from 'ms-pages/Textbooks/components/ProblemDifficultyFilter/types';
import ProblemItem from 'ms-pages/Textbooks/components/ProblemItem';
import Button from 'ms-ui-primitives/Button';

import type { QuestionsQuery } from './__generated__/QuestionsQuery.graphql';
import type { Questions_subtopic$key } from './__generated__/Questions_subtopic.graphql';
import { ContentWrapper } from '../elements';

type ExternalProps = {
  canCollectProblemItems?: boolean | undefined;
  shouldShowProblemMetaData?: boolean | undefined;
  shouldShowTryProblem?: boolean | undefined;
  difficultyFilters?: DifficultyLevelTypes[] | undefined;
  onLoad: () => void;
};

type FragmentProps = {
  subtopicKey: Questions_subtopic$key;
};

type UiProps = ExternalProps & FragmentProps;

function QuestionsUi({
  subtopicKey,
  canCollectProblemItems,
  shouldShowProblemMetaData = true,
  shouldShowTryProblem = true,
  difficultyFilters = [],
  onLoad,
}: UiProps) {
  const subtopic = useFragment(
    graphql`
      fragment Questions_subtopic on Subtopic {
        id
        problemContents {
          id
          estimatedCompletionTime
          problemTemplateId
          previewWorkoutCreationToken
          isStaticQuestion
          difficultyLevel
          ...ProblemItem_problemContent
        }
      }
    `,
    subtopicKey,
  );

  if (subtopic == null) {
    throw new Error('Subtopic not found');
  }

  useEffect(onLoad, [onLoad, subtopic.id]);

  const { role } = useMaybeViewer() || { role: 'Other' };

  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();

  const { problemsCollection } = useContext(StateContext);
  const { addMultipleProblemContents } = useContext(ProblemsCollectionUpdaters);

  const { trackStructEvent } = useSnowplow();

  const { problemContents: questions } = subtopic;

  const filteredQuestions = questions.filter(problemContent => {
    const isStaticQuestion = problemContent.isStaticQuestion;
    if (difficultyFilters.length === 0) {
      return !isStaticQuestion;
    }

    return (
      difficultyFilters.includes(
        problemContent.difficultyLevel ?? 'UNSPECIFIED',
      ) && !isStaticQuestion
    );
  });

  return (
    <PageTimeRecorder componentName="Questions" pageName="Textbooks">
      {filteredQuestions.length === 0 ? (
        <div style={{ height: '100%' }}>
          <CustomEmptyMessage label="There are no interactive problems for this subtopic" />
        </div>
      ) : (
        <>
          <div
            css={{
              flex: '1 1 100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 12,
              marginBottom: 16,
            }}
          >
            <Button
              size="regular"
              type="tertiary"
              onClick={() => {
                trackStructEvent({
                  category: 'textbook',
                  action: `clicked_${
                    questionsCollapsed ? 'expand' : 'collapse'
                  }_all_questions`,
                  label: 'subtopic_questions_list',
                  value: subtopic.id,
                });
                setQuestionsCollapsed(s => !s);
              }}
              padding={0}
              height={22}
            >
              {questionsCollapsed ? 'Expand All' : 'Collapse All'}
            </Button>

            {role === 'Teacher' && canCollectProblemItems && (
              <Button
                size="regular"
                type="tertiary"
                padding={0}
                height={22}
                isDisabled={
                  problemsCollection.length >= MAX_NUMBER_OF_QUESTIONS
                }
                styles={{ marginLeft: 'auto' }}
                onClick={() => {
                  trackStructEvent({
                    category: 'textbook',
                    action: 'clicked_add_all_questions',
                    label: subtopic.id,
                  });
                  addMultipleProblemContents(
                    filteredQuestions.map(p => [
                      p.id,
                      p.estimatedCompletionTime,
                      p.problemTemplateId,
                      p.previewWorkoutCreationToken,
                      subtopic.id,
                    ]),
                  );
                }}
              >
                {`Add all (${filteredQuestions.length})`}
              </Button>
            )}
          </div>
          {filteredQuestions.map((problemContent, index) => (
            <Fragment key={problemContent.id}>
              <ContentWrapper style={{ marginTop: index === 0 ? 8 : 0 }}>
                <ProblemItem
                  shouldShowProblemMetaData={shouldShowProblemMetaData}
                  showTryProblem={shouldShowTryProblem}
                  problemContent={problemContent}
                  showAddButton={canCollectProblemItems}
                  collapsedInBulk={questionsCollapsed}
                  addToCollectionAnimationTarget={
                    COLLECTED_ITEM_COUNT_INDICATOR_BUBBLE_ID
                  }
                  structEventToTrackOnAddToCollection={{
                    category: 'textbook',
                    action: 'clicked_add_question',
                    label: problemContent.id,
                  }}
                />
              </ContentWrapper>
            </Fragment>
          ))}
        </>
      )}
    </PageTimeRecorder>
  );
}

type Props = ExternalProps & {
  subtopicId: string;
};

export default function Questions(props: Props) {
  return (
    <ErrorBoundaryWithRetry
      fallback={({ error, retry }) => (
        <PageTimeErrorRecorder componentName="Questions" pageName="Textbooks">
          <Retry retry={retry} message={error.message} />
        </PageTimeErrorRecorder>
      )}
      name="Questions"
    >
      {({ fetchKey }) => (
        <Suspense fallback={<MinorSpinner />}>
          <QuestionsInner {...props} fetchKey={fetchKey} />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
}

function QuestionsInner({
  subtopicId,
  fetchKey,
  ...otherExternalProps
}: Props & { fetchKey: number }) {
  const data = useLazyLoadQuery<QuestionsQuery>(
    graphql`
      query QuestionsQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          ...Questions_subtopic
        }
      }
    `,
    { subtopicId },
    {
      fetchPolicy: 'store-and-network',
      fetchKey,
    },
  );

  const { subtopic } = data;

  if (subtopic == null) {
    throw new Error(
      `QuestionsQuery failed: Subtopic not found for ${subtopicId}`,
    );
  }

  return <QuestionsUi subtopicKey={subtopic} {...otherExternalProps} />;
}
