import styled from '@emotion/styled';

import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable as tappableStyle } from 'ms-utils/emotion';

export const AVATAR_SIZE = 10 * BASE_UNIT;

const Img = styled.img<{ tappable: boolean }>(
  {
    backgroundColor: colors.porcelain,
    borderRadius: '50%',
    flexShrink: 0,
  },
  ({ tappable }) => tappable && tappableStyle,
);

type Props = {
  size?: string | number | undefined;
  src: string;
  onClick?: (() => void) | undefined;
};

export default function Avatar({ size, src, onClick }: Props) {
  return (
    <Img
      style={{ width: size || AVATAR_SIZE, height: size || AVATAR_SIZE }}
      src={src}
      onClick={onClick}
      tappable={Boolean(onClick)}
      role="presentation"
    />
  );
}
