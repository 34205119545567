import { css, StyleSheet } from 'aphrodite';

import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { useColorTheme } from 'ms-styles/themes';

type Props = {
  selected?: number | null | undefined;
  segmentList: string[];
  onSelected: (nextSelected: number) => void;
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    width: '100%',
  },
  button: {
    borderLeft: 'none',
    cursor: 'pointer',
    flexBasis: 0,
    flexGrow: 1,
    fontFamily: fontFamily.body,
    fontSize: fontSize.input,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.body,
    margin: 0,
    outline: 'none',
    padding: '8px 10px',
    transition: `background-color ${transition}`,
    WebkitFontSmoothing: 'subpixel-antialiased',
    ':focus': {
      boxShadow: `0 0 4px 1px rgba(0,0,0,0.3) inset`,
    },
    ':first-child': {
      borderTopLeftRadius: borderRadiusUI,
      borderBottomLeftRadius: borderRadiusUI,
    },
    ':last-child': {
      borderTopRightRadius: borderRadiusUI,
      borderBottomRightRadius: borderRadiusUI,
    },
  },
});

function useStyleFactory() {
  const { primary: primaryColorName } = useColorTheme();
  return ({ selected }: { selected: boolean }) => {
    let style: {
      color: string;
      border: string;
      backgroundColor: string;
      fontWeight?: number;
    } = {
      color: colors[primaryColorName],
      border: `1px solid ${colors[primaryColorName]}`,
      backgroundColor: 'transparent',
    };

    if (selected === true) {
      style = {
        ...style,
        color: colors.white,
        backgroundColor: colors[primaryColorName],
        fontWeight: fontWeight.semibold,
      };
    }
    return style;
  };
}

export default function ButtonGroup(props: Props) {
  const { selected, segmentList, onSelected } = props;
  const styleFactory = useStyleFactory();
  const { primary: primaryColorName } = useColorTheme();

  const { firstButtonBorderLeftStyle } = StyleSheet.create({
    firstButtonBorderLeftStyle: {
      ':first-child': {
        borderLeft: `1px solid ${colors[primaryColorName]}`,
      },
    },
  });
  return (
    <div className={css(styles.root)}>
      {segmentList.map((value, index) => {
        const isSelected = index === selected;
        return (
          <button
            style={styleFactory({ selected: isSelected })}
            key={index}
            className={css(styles.button, firstButtonBorderLeftStyle)}
            onClick={() => {
              onSelected(index);
            }}
          >
            {value}
          </button>
        );
      })}
    </div>
  );
}
