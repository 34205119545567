import styled from '@emotion/styled';

import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const VStack = styled.div({ display: 'flex', flexDirection: 'column' });

export const Panel = styled(VStack)({
  backgroundColor: 'white',
  borderRadius: 8,
  boxShadow: '0 1px 3px rgb(0 0 0 / 15%), 0 0 .5px rgb(0 0 0 / 30%)',
  color: colors.neutralGray,
  fontFamily: fontFamily.body,
  fontSize: 14,
  gap: 8,
  lineHeight: 1.5,
  padding: 8,
});

export const PanelTitle = styled.h2({ fontSize: 16 });
export const PanelBody = styled(VStack)({ gap: 16 });
export const PanelSection = styled(VStack)({ gap: 4 });
export const PanelSectionTitle = styled.h3();
