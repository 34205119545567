import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

const styles = StyleSheet.create({
  root: {
    display: 'table-row',
  },
});

type Props = {
  children: ReactNode;
};

const TableRow = ({ children }: Props) => (
  <div className={css(styles.root)}>{children}</div>
);

export default TableRow;
