import MultipleChoice from 'ms-components/math/MultipleChoice';

type Html = string;
type Value = { [key: string]: { value: Html; selected: boolean } };

type Props = {
  isAnswerAttempt?: boolean | undefined;
  isLastAnswer?: boolean | undefined;
  hasMultipleAnswers?: boolean | undefined;
  correctAnswerFoundByStudent?: boolean | undefined;
  value: Value;
};

const MultipleChoiceReadOnly = ({
  value,
  isAnswerAttempt = false,
  isLastAnswer = false,
  hasMultipleAnswers = false,
  correctAnswerFoundByStudent = false,
}: Props) => (
  // NB as long as the UI is not affected, `hasMultipleAnswers` affects only the behavior,
  // thus its value for `readOnly` version is irrelevant
  <MultipleChoice
    value={value}
    readOnly
    isAnswerAttempt={isAnswerAttempt}
    isLastAnswer={isLastAnswer}
    correctAnswerFoundByStudent={correctAnswerFoundByStudent}
    hasMultipleAnswers={hasMultipleAnswers}
  />
);

export default MultipleChoiceReadOnly;
