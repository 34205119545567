/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type SubstrandRecommendationsQueryVariables = {
    substrandId: string;
};
export type SubstrandRecommendationsQueryResponse = {
    readonly lantern: {
        readonly substrand: {
            readonly studentSubtopicRecommendations: ReadonlyArray<{
                readonly category: SubstrandSubtopicRecommendationCategory;
                readonly subtopic: {
                    readonly id: string;
                    readonly hasAdaptivePractice: boolean;
                    readonly topic: {
                        readonly id: string;
                        readonly syllabus: {
                            readonly id: string;
                        };
                    };
                    readonly gamifiedMastery: number;
                    readonly " $fragmentRefs": FragmentRefs<"OutcomeSubtopicRecommendationCard_subtopic">;
                };
            }>;
        } | null;
    };
};
export type SubstrandRecommendationsQuery = {
    readonly response: SubstrandRecommendationsQueryResponse;
    readonly variables: SubstrandRecommendationsQueryVariables;
};



/*
query SubstrandRecommendationsQuery(
  $substrandId: ID!
) {
  lantern {
    substrand(id: $substrandId) {
      studentSubtopicRecommendations {
        category
        subtopic {
          id
          hasAdaptivePractice
          topic {
            id
            syllabus {
              id
            }
          }
          gamifiedMastery
          ...OutcomeSubtopicRecommendationCard_subtopic
        }
        id
      }
      id
    }
  }
}

fragment OutcomeSubtopicRecommendationCard_subtopic on Subtopic {
  id
  title
  topic {
    id
    badgeUrl
    badgeBackground
    syllabus {
      id
    }
  }
  hasAdaptivePractice
  gamifiedMastery
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "substrandId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "substrandId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasAdaptivePractice",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Syllabus",
  "kind": "LinkedField",
  "name": "syllabus",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gamifiedMastery",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubstrandRecommendationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubstrandSubtopicRecommendation",
                "kind": "LinkedField",
                "name": "studentSubtopicRecommendations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subtopic",
                    "kind": "LinkedField",
                    "name": "subtopic",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OutcomeSubtopicRecommendationCard_subtopic"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubstrandRecommendationsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Substrand",
            "kind": "LinkedField",
            "name": "substrand",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubstrandSubtopicRecommendation",
                "kind": "LinkedField",
                "name": "studentSubtopicRecommendations",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Subtopic",
                    "kind": "LinkedField",
                    "name": "subtopic",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Topic",
                        "kind": "LinkedField",
                        "name": "topic",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeUrl",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "badgeBackground",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "833b59bf41a8e65798391aa799378852",
    "id": null,
    "metadata": {},
    "name": "SubstrandRecommendationsQuery",
    "operationKind": "query",
    "text": "query SubstrandRecommendationsQuery(\n  $substrandId: ID!\n) {\n  lantern {\n    substrand(id: $substrandId) {\n      studentSubtopicRecommendations {\n        category\n        subtopic {\n          id\n          hasAdaptivePractice\n          topic {\n            id\n            syllabus {\n              id\n            }\n          }\n          gamifiedMastery\n          ...OutcomeSubtopicRecommendationCard_subtopic\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment OutcomeSubtopicRecommendationCard_subtopic on Subtopic {\n  id\n  title\n  topic {\n    id\n    badgeUrl\n    badgeBackground\n    syllabus {\n      id\n    }\n  }\n  hasAdaptivePractice\n  gamifiedMastery\n}\n"
  }
};
})();
(node as any).hash = '202ab71aa32f31299f78233a767c56d5';
export default node;
