import { css } from '@emotion/css';
import moment from 'moment';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { fontWeight } from 'ms-styles/base';
import { titleCase } from 'ms-utils/string';
import { unwrap } from 'ms-utils/typescript-utils';

import type { SubscriptionDetails_subscription$key } from './__generated__/SubscriptionDetails_subscription.graphql';
import SubscriptionCard, {
  SubscriptionCardText,
  SubscriptionCardTitle,
} from '../SubscriptionCard';

type Props = {
  subscriptionKey: SubscriptionDetails_subscription$key;
};
export default function SubscriptionDetails({ subscriptionKey }: Props) {
  const subscriptionData = useFragment(
    graphql`
      fragment SubscriptionDetails_subscription on SubscriptionInterface {
        type
        ... on StudentSchoolPaidSubscription {
          startDate
          endDate
          plan {
            school {
              title
            }
          }
        }
        ... on StudentSelfPaidSubscription {
          startDate
          endDate
          plan {
            school {
              title
            }
          }
        }
      }
    `,
    subscriptionKey,
  );
  // All student users
  if (
    subscriptionData.type === 'STUDENT_SELF_PAID' ||
    subscriptionData.type === 'STUDENT_SCHOOL_PAID'
  ) {
    // plan and startDate are guaranteed to be present by the fragment
    const plan = unwrap(subscriptionData.plan);
    const startDate = unwrap(subscriptionData.startDate);
    const endDate = subscriptionData.endDate;
    return (
      <SubscriptionCard>
        <SubscriptionCardTitle as="h5">
          Student — {plan.school.title}
        </SubscriptionCardTitle>
        <SubscriptionCardText>
          Access period:{' '}
          <strong className={css({ fontWeight: fontWeight.semibold })}>
            {moment(startDate).format('LL')}–
            {endDate && moment(endDate).format('LL')}
          </strong>
        </SubscriptionCardText>
      </SubscriptionCard>
    );
  }
  // Fallback for any other types of subscriptions we have
  return (
    <SubscriptionCard>
      <SubscriptionCardTitle as="h5">
        {titleCase(subscriptionData.type)} subscription
      </SubscriptionCardTitle>
    </SubscriptionCard>
  );
}
