import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const NotLoadedImagePlaceholder = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={25}
    viewBoxWidth={22}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6381 22.7142C21.6618 22.7411 21.6832 22.7696 21.7022 22.7998L21.7022 22.8001C21.8095 22.9597 21.8557 23.1526 21.8326 23.3435C21.8097 23.5343 21.7187 23.7105 21.5765 23.8401C21.4298 23.9722 21.2394 24.0455 21.0419 24.046C20.8133 24.0462 20.5954 23.949 20.4431 23.7786H20.4429L19.5099 22.7359C19.1997 22.8359 18.876 22.8874 18.5502 22.8883H3.36984C2.51885 22.8883 1.703 22.5503 1.10136 21.9485C0.499539 21.3469 0.161621 20.531 0.161621 19.6801V4.49972C0.161812 3.63756 0.510945 2.81197 1.12934 2.21128L0.378176 1.36908C0.194858 1.15443 0.137793 0.85942 0.227867 0.591991C0.317907 0.32474 0.541895 0.124264 0.817712 0.0644008C1.09332 0.00435167 1.38034 0.0936807 1.57317 0.299566L20.8841 21.872L21.6381 22.7142ZM3.34093 15.998C3.52746 16.1647 3.7695 16.2562 4.01977 16.2549L13.6979 16.2549L9.93901 12.0574L8.69568 13.83L8.33482 13.3246L7.05678 11.5388C6.8673 11.2723 6.56056 11.114 6.23344 11.114C5.90631 11.114 5.59956 11.2723 5.4099 11.5388H5.41008L4.14809 13.314L3.19622 14.6508C3.04926 14.8533 2.98269 15.1032 3.00935 15.3522C3.03602 15.6009 3.15425 15.831 3.34093 15.998ZM13.4078 7.92461C13.081 7.92461 12.7746 8.08294 12.5855 8.34944L11.8451 9.38699L4.59179 1.29147H18.5528C19.4038 1.29147 20.2197 1.62944 20.8215 2.23122C21.4231 2.83285 21.761 3.64895 21.761 4.4997V19.6853C21.7612 19.9182 21.7351 20.1504 21.6836 20.3777L17.9941 16.2552C18.231 16.2333 18.4525 16.1289 18.62 15.9601C18.7873 15.7912 18.8898 15.5687 18.9092 15.3317C18.9288 15.0946 18.8641 14.8583 18.7267 14.6644L16.6359 11.7234L14.2298 8.34944C14.0407 8.08294 13.7343 7.92461 13.4078 7.92461Z"
      fill={color}
    />
  </Icon>
);

NotLoadedImagePlaceholder.displayName = 'NotLoadedImagePlaceholder';

export default NotLoadedImagePlaceholder;
