import { useState, useCallback, useMemo } from 'react';

export default function useToggle(initialState: boolean = false) {
  const [state, setState] = useState(initialState);

  const setOn = useCallback(() => {
    setState(true);
  }, []);
  const setOff = useCallback(() => {
    setState(false);
  }, []);

  const toggle = useCallback(() => {
    setState(s => !s);
  }, []);

  return useMemo(
    () => [state, setOn, setOff, toggle] as const,
    [state, setOn, setOff, toggle],
  );
}
