import Icon from 'ms-pages/Lantern/primitives/Icon';

import type { Props } from './types';

const path = (
  <path d="M6.984 8.875L.705 2.596c-.94-.94.47-2.351 1.411-1.41l6.985 6.983a.998.998 0 010 1.412l-6.985 6.984c-.94.94-2.351-.47-1.41-1.411l6.278-6.279zm9.1 6.279c.942.94-.47 2.352-1.41 1.41L7.69 9.582a.998.998 0 010-1.412l6.984-6.984c.94-.94 2.352.47 1.41 1.411L9.807 8.875l6.279 6.279z" />
);

const Cross = ({ color, size, title, titleId }: Props) => (
  <Icon
    color={color}
    size={size}
    title={title}
    titleId={titleId}
    viewBoxHeight={17}
    viewBoxWidth={17}
  >
    {path}
  </Icon>
);

Cross.displayName = 'Cross';

export default Cross;
