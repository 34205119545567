import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M2.93 3.284a1.022 1.022 0 0 1 1.413 0c.39.379.39.992 0 1.37C2.833 6.12 2 8.067 2 10.138c0 2.072.832 4.019 2.343 5.483.39.379.39.992 0 1.37-.195.19-.45.285-.707.285-.256 0-.512-.095-.707-.284C1.04 15.16 0 12.726 0 10.137 0 7.55 1.04 5.115 2.93 3.284zm12.727 0a1.022 1.022 0 0 1 1.414 0C18.96 5.114 20 7.549 20 10.137c0 2.59-1.04 5.023-2.93 6.854-.194.19-.45.284-.706.284-.256 0-.512-.095-.707-.284a.948.948 0 0 1 0-1.37C17.167 14.155 18 12.208 18 10.136c0-2.07-.832-4.018-2.343-5.482a.948.948 0 0 1 0-1.371zm-9.9 2.742a1.022 1.022 0 0 1 1.414 0c.391.378.391.991 0 1.37a3.797 3.797 0 0 0 0 5.483c.391.378.391.992 0 1.37-.195.19-.45.284-.707.284-.256 0-.511-.094-.707-.284a5.696 5.696 0 0 1 0-8.223zm7.072 0a1.022 1.022 0 0 1 1.414 0 5.696 5.696 0 0 1 0 8.223c-.196.19-.451.284-.707.284-.256 0-.512-.094-.707-.284a.948.948 0 0 1 0-1.37 3.797 3.797 0 0 0 0-5.483.948.948 0 0 1 0-1.37zM10 8.199c1.104 0 2 .868 2 1.938 0 1.071-.896 1.939-2 1.939-1.105 0-2-.868-2-1.939 0-1.07.895-1.938 2-1.938z" />
);

const Live = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Live.displayName = 'Live';

export default Live;
