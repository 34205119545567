import { css, StyleSheet } from 'aphrodite';

import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import type { Leaderboard_student } from 'ms-pages/StudentDashboard/Leaderboard/__generated__/Leaderboard_student.graphql';
import { breakPoints, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Avatar from 'ms-ui-primitives/Avatar';
import Select from 'ms-ui-primitives/Select';
import Separator from 'ms-ui-primitives/Separator';
import { styled } from 'ms-utils/emotion';
import _ from 'ms-utils/relay/extractNode';

type Leader = NonNullable<
  Leaderboard_student['leaderboard']
>['currentWeek'][number];
type Props = {
  studentId: string;
  leaders: ReadonlyArray<Leader>;
  user: Leaderboard_student['user'];
  classes: Leaderboard_student['classes'];
  selectedClassId: string | null | undefined;
  onSelectClass: (selectedClass: string) => void;
};
const styles = StyleSheet.create({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    [`@media (min-width: ${breakPoints.large}px)`]: {
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
  },
  leader: {
    marginBottom: 3 * BASE_UNIT,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
});
const Rank = styled({
  marginRight: 12,
  flexShrink: 0,
  flexBasis: 12,
  color: colors.grayChateau,
  fontWeight: fontWeight.semibold,
});
const Description = styled({
  textAlign: 'center',
});
const Name = styled({
  default: {
    marginLeft: 12,
    marginRight: 16,
    flexShrink: 1,
    // ensure that long names don't push the points off the edge of the modal
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  bold: {
    fontWeight: fontWeight.semibold,
  },
});
const Points = styled({
  default: {
    marginLeft: 'auto',
    flex: 0,
  },
  bold: {
    fontWeight: fontWeight.semibold,
  },
});
function LeaderRow({
  leader,
  userId,
  index,
}: {
  userId: string;
  index?: number;
  leader: Leader;
}) {
  return (
    <div className={css(styles.leader)}>
      {index != null && <Rank>{index}</Rank>}
      <Avatar size={40} src={leader.avatarUrl} />
      <Name bold={leader.studentId === userId}>
        {leader.name} {leader.studentId === userId && ' (you)'}
      </Name>
      <Points bold={leader.studentId === userId}>
        {leader.points}&nbsp;points
      </Points>
    </div>
  );
}
export default function Leaderboard({
  leaders,
  user,
  classes,
  onSelectClass,
  selectedClassId,
  studentId,
}: Props) {
  const userInLeaderboard = !!leaders.find(
    leader => leader.studentId === studentId,
  );
  const classesWithLeaderboardEnabled = _(classes).filter(
    klass => klass.hasEnabledLeaderboard,
  );
  return (
    <div className={css(styles.root)}>
      <Description>
        <BodyM color="grey90">
          Earn points by working on assigned and recommended practice
        </BodyM>
      </Description>

      <Separator size={6} />

      {classesWithLeaderboardEnabled.length > 1 && (
        <>
          <Select
            block
            onChange={onSelectClass}
            options={classesWithLeaderboardEnabled.map(klass => ({
              label: klass.displayName ?? klass.title,
              value: klass.id,
            }))}
            value={selectedClassId}
          />
          <Separator size={2 * BASE_UNIT} />
        </>
      )}
      {!userInLeaderboard && (
        <>
          <LeaderRow
            userId={studentId}
            leader={{
              name: `${user.firstName} ${user.lastName}`,
              points: user.points?.current || 0,
              avatarUrl: user.avatar,
              rank: 0,
              studentId,
            }}
          />
          <div
            style={{
              borderTop: `1px solid ${colors.iron}`,
              width: '100%',
              height: 6 * BASE_UNIT,
              marginTop: 3 * BASE_UNIT,
            }}
          />
        </>
      )}
      {leaders.map(leader => (
        <LeaderRow
          key={leader.studentId}
          index={leader.rank}
          leader={leader}
          userId={studentId}
        />
      ))}
    </div>
  );
}
export type { Leader };
