import { useId } from 'react';

export function CactusSvg({ style }: { style?: React.CSSProperties }) {
  const maskId = useId();
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.39 15.3c5.556 0 10.06 1.275 10.06 2.85 0 1.574-4.504 2.85-10.06 2.85C4.834 21 .33 19.724.33 18.15c0-1.575 4.504-2.85 10.06-2.85Z"
        fill="#E5E6E8"
        fillOpacity=".51"
      />
      <path
        d="M8.586 8.78c0-.67 1.509-.67 1.509 0"
        stroke="#3B1C0C"
        strokeWidth="2"
      />
      <path
        d="m15.752 5.08-.797.265"
        stroke="#74A524"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.724 2.207v1.127l.376-.422"
        stroke="#5F7504"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m9.348 1.465-.915 1.191-.253-.904M4.649 5.4l-.751-.376"
        stroke="#74A524"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.274 3.099s3.02.212 3.02 3.146c0 2.347-1.173 3.52-1.173 3.52h-2.054l.207-6.666Z"
        fill="#74A524"
      />
      <path
        d="M9.72 2.724h1.173c.942 0 2.214.641 2.443 1.555a6.117 6.117 0 0 1-.288 3.836l-.688 1.65h-2.054L9.72 2.725Z"
        fill="#8DC236"
      />
      <path
        d="M7.164 3.099s-3.02.212-3.02 3.146c0 2.347 1.174 3.52 1.174 3.52h2.054L7.164 3.1Z"
        fill="#74A524"
      />
      <path
        d="M9.72 2.724H8.545c-.943 0-2.215.641-2.443 1.555a6.117 6.117 0 0 0 .287 3.836l.688 1.65h2.054l.587-7.041Z"
        fill="#8DC236"
      />
      <path
        d="M11.442 4.92c-.125-.998-.525-2.196-1.723-2.196S8.122 3.922 7.997 4.92a6.52 6.52 0 0 0 .054 1.878l.495 2.968h2.347l.495-2.968c.103-.62.132-1.253.054-1.878Z"
        fill="#74A524"
      />
      <path
        d="M8.593 7.465h1.502v1.127a.751.751 0 1 1-1.502 0V7.465Z"
        fill="#C11B39"
      />
      <mask
        id={maskId}
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="7"
        width="3"
        height="3"
      >
        <path
          d="M8.593 7.465h1.502v1.127a.751.751 0 1 1-1.502 0V7.465Z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${maskId})`}>
        <path
          d="M8.593 8.389c0-.095.077-.172.172-.172h1.158c.095 0 .172.077.172.172v.203a.751.751 0 1 1-1.502 0V8.39Z"
          fill="#8F011B"
        />
      </g>
      <path
        d="M11.597 5.588c.415 0 .751.42.751.939 0 .518-.336.938-.75.938-.416 0-.752-.42-.752-.938 0-.52.336-.94.751-.94ZM6.782 5.588c.415 0 .75.42.75.939 0 .518-.335.938-.75.938s-.751-.42-.751-.938c0-.52.336-.94.75-.94Z"
        fill="#3B1C0C"
      />
      <path
        d="M6.154 16.862c.025.139.147.24.288.24h6.554c.142 0 .263-.101.289-.24l1.423-5.042H4.73l1.423 5.042Z"
        fill="#9C5F20"
      />
      <path
        d="M4.144 10.06v1.466c0 .162.132.294.294.294H15a.293.293 0 0 0 .293-.293v-1.468a.293.293 0 0 0-.293-.293H4.438a.294.294 0 0 0-.294.293Z"
        fill="#9C5F20"
      />
      <path
        d="M6.154 16.862c.025.139.147.24.288.24h6.554c.142 0 .263-.101.289-.24l1.422-5.042a9.375 9.375 0 0 1-7.309 4.913l-1.244.129Z"
        fill="#8A541C"
      />
      <path d="m14.625 12.113.083-.293H4.73l.083.293h9.81Z" fill="#8A541C" />
      <path
        d="M13.844 1a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#C11B39"
      />
      <path
        d="M14.515 2.341a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#C11B39"
      />
      <path
        d="M13.509 3.347a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#C11B39"
      />
      <path
        d="M12.167 2.677a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#C11B39"
      />
      <path
        d="M12.503 1.335a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#C11B39"
      />
      <path
        d="M13.509 2.006a.838.838 0 1 1 0 1.677.838.838 0 0 1 0-1.677Z"
        fill="#8F011B"
      />
    </svg>
  );
}
