import { EMPTY_ITEM } from 'ms-pages/Teacher/components/StudentCreateEdit/state/State';
import type {
  Email,
  Klass,
  GuardianEmails,
  State,
  StudentName,
  SyllabusFocus,
  SyllabusFocusSelectedClass,
  Updater,
  SelfReportedGradeId,
  GradeSelectedClass,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state/State';
import {
  addItem,
  removeItem,
} from 'ms-pages/Teacher/components/StudentCreateEdit/utils';

// determines if state values are valid or not
function checkFormValid(state: State) {
  return (
    state.firstName !== '' &&
    state.lastName !== '' &&
    state.classes.length > 0 &&
    state.syllabusFocus.id !== EMPTY_ITEM.id &&
    state.selfReportedGradeId !== EMPTY_ITEM.id
  );
}

export const updateFirstName: Updater<[StudentName]> = firstName => state => ({
  ...state,
  firstName,
  // if the name changes to an empty string or is no longer the same as the previous
  // value, we should disable the check
  shouldCheckForDuplicates: !(
    firstName === '' || firstName !== state.firstName
  ),
  // we should always disable the buttons if the form gets into a state that is invalid
  buttonsDisabled: !checkFormValid({ ...state, firstName }),
});

export const updateLastName: Updater<[StudentName]> = lastName => state => ({
  ...state,
  lastName,
  // if the name changes to an empty string or is no longer the same as the previous
  // value, we should disable the check
  shouldCheckForDuplicates: !(lastName === '' || lastName !== state.lastName),
  // we should always disable the buttons if the form gets into a state that is invalid
  buttonsDisabled: !checkFormValid({ ...state, lastName }),
});

export const updateSyllabusFocus: Updater<[SyllabusFocus]> =
  syllabusFocus => state => ({
    ...state,
    syllabusFocus,
    // we should always disable the buttons if the form gets into a state that is invalid
    buttonsDisabled: !checkFormValid({ ...state, syllabusFocus }),
  });

export const updateEmail: Updater<[Email]> = email => state => ({
  ...state,
  email,
});

export const addClass: Updater<[Klass]> = klass => state => {
  const newState = {
    ...state,
    classes: addItem({ items: state.classes, item: klass }),
  };
  return {
    ...newState,
    // We need to do this check as the teacher could be adding to an empty list of classes
    // and we need to re-enable the buttons
    buttonsDisabled: !checkFormValid(newState),
  };
};

export const removeClass: Updater<[Klass['id']]> = classId => state => {
  const newState = {
    ...state,
    classes: removeItem({ items: state.classes, itemId: classId }),
  };
  return {
    ...newState,
    // in the event this drops to zero, we should prevent a duplicate check as it relies on this being populated
    shouldCheckForDuplicates: newState.classes.length > 0,
    // we should always disable the buttons if the form gets into a state that is invalid
    buttonsDisabled: !checkFormValid(newState),
  };
};

export const updateGuardianEmails: Updater<[GuardianEmails]> =
  guardianEmails => state => ({
    ...state,
    guardianEmails,
  });

export const updateSyllabusFocusSelectedClass: Updater<
  [SyllabusFocus, SyllabusFocusSelectedClass]
> = (syllabusFocus, syllabusFocusSelectedClass) => state => ({
  ...state,
  syllabusFocus,
  syllabusFocusSelectedClass,
  // we should always disable the buttons if the form gets into a state that is invalid
  buttonsDisabled: !checkFormValid({ ...state, syllabusFocus }),
});

export const removeSyllabusFocusSelectedClass: Updater<[]> = () => state => ({
  ...state,
  syllabusFocus: EMPTY_ITEM,
  syllabusFocusSelectedClass: EMPTY_ITEM,
  // this always makes the form disabled
  buttonsDisabled: true,
});

export const toggleUseClassSyllabusFocus: Updater<[]> = () => state => ({
  ...state,
  useClassSyllabusFocus: !state.useClassSyllabusFocus,
});

export const updateGradeSelectedClass: Updater<
  [SelfReportedGradeId, GradeSelectedClass]
> = (selfReportedGradeId, gradeSelectedClass) => state => ({
  ...state,
  selfReportedGradeId,
  gradeSelectedClass,
  // we should always disable the buttons if the form gets into a state that is invalid
  buttonsDisabled: !checkFormValid({ ...state, selfReportedGradeId }),
});

export const removeGradeSelectedClass: Updater<[]> = () => state => ({
  ...state,
  selfReportedGradeId: EMPTY_ITEM.id,
  gradeSelectedClass: EMPTY_ITEM,
  // this always makes the form disabled
  buttonsDisabled: true,
});

export const toggleUseClassGrade: Updater<[]> = () => state => ({
  ...state,
  useClassGrade: !state.useClassGrade,
});

export const disableButtons: Updater<[]> = () => state => ({
  ...state,
  buttonsDisabled: true,
});

export const enableButtons: Updater<[]> = () => state => ({
  ...state,
  buttonsDisabled: false,
});

export const checkForDuplicates: Updater<[]> = () => state => {
  if (state.firstName !== '' && state.lastName !== '')
    return {
      ...state,
      shouldCheckForDuplicates: true,
    };
  else return state;
};

export const updateSelfReportedGradeId: Updater<[SelfReportedGradeId]> =
  selfReportedGradeId => state => ({
    ...state,
    selfReportedGradeId,
    buttonsDisabled: !checkFormValid({ ...state, selfReportedGradeId }),
  });

export const reset: Updater<[]> = () => state => ({
  ...state,
  firstName: '',
  lastName: '',
  email: '',
  guardianEmails: '',
  shouldCheckForDuplicates: false,
  buttonsDisabled: true,
});
