import { css } from '@emotion/css';
import { useRef, useEffect, Fragment } from 'react';

import ChevronBottomIcon from 'ms-components/icons/ChevronBottom';
import ChevronRightIcon from 'ms-components/icons/ChevronRight';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import type { CombinedState } from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask/state';
import {
  Asterisk,
  FieldWrapper,
  Label,
  Row,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import FolderSelector from 'ms-pages/Teacher/components/CreateTaskTemplate/components/FolderSelector';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Input from 'ms-ui-primitives/Input';
import RadioButton from 'ms-ui-primitives/RadioButton';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';

import TaskNameInput from '../components/TaskNameInput';

export { useCreateCustomTaskState } from './state';

const RightAligned = styled({
  marginLeft: 'auto',
});

const AdvancedOptionsButton = styled({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 10 * BASE_UNIT,
  color: colors.cloudBurst,
  fontSize: fontSize.small,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
});

const AdavancedOptionsSeparator = styled({
  borderTop: `1px solid ${colors.ironLight}`,
});

const styles = {
  folderSelectorWrapper: css({
    marginTop: 3 * BASE_UNIT,
  }),
};

type Props = {
  state: CombinedState;
  showSaveAsTemplateOption: boolean;
  noTitleField?: boolean | undefined;
};

export default function CustomTaskDetailsForm({
  state,
  showSaveAsTemplateOption = true,
  noTitleField,
}: Props) {
  const { schoolId } = useMaybeTeacherContext();

  const {
    taskName,
    taskStartDate,
    taskDueDate,
    templateGroupId,
    isAdvancedOptionsVisible,
    extensionDays,
    hideHelpOptions,
    hideCalculator,
    hideRetry,
    questionVariation,
    saveThisAsTemplate,
    selectedStudents,
    selectedClasses,
    excludedStudents,
  } = state.values;
  const {
    updateTaskName,
    updateTaskStartDate,
    updateTaskDueDate,
    updateTemplateGroupId,
    toggleAdvancedOptions,
    updateExtensionDays,
    updateHideHelpOptions,
    updateHideCalculator,
    updateHideRetry,
    updateQuestionVariation,
    updateSaveThisAsTemplate,
    setSelectedClasses,
    setSelectedStudents,
    setExcludedStudents,
  } = state.updaters;

  const taskNameInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const taskNameInputElement = taskNameInputRef.current;
    if (taskNameInputElement != null) {
      taskNameInputElement.focus();
      taskNameInputElement.select();
    }
  }, []);

  return (
    <TaskCreationFormWrapper>
      {noTitleField !== true && (
        <FieldWrapper>
          <Label>
            Task name<Asterisk> *</Asterisk>
          </Label>
          <TaskNameInput
            ref={taskNameInputRef}
            value={taskName}
            onChange={updateTaskName}
          />
        </FieldWrapper>
      )}

      <FieldWrapper>
        <Label>
          Start date / Due date<Asterisk> *</Asterisk>
        </Label>
        <TaskDatePicker
          value={[taskStartDate, taskDueDate]}
          onChange={([startDate, endDate]) => {
            if (startDate != null) updateTaskStartDate(startDate);
            if (endDate != null) updateTaskDueDate(endDate);
          }}
        />
      </FieldWrapper>

      <FieldWrapper>
        <TaskAssignment
          selectedClasses={selectedClasses}
          selectedStudents={selectedStudents}
          excludedStudents={excludedStudents}
          onChangeClasses={setSelectedClasses}
          onChangeStudents={setSelectedStudents}
          onChangeExcludedStudents={setExcludedStudents}
        />
      </FieldWrapper>

      {showSaveAsTemplateOption && (
        <FieldWrapper>
          <Checkbox
            checked={saveThisAsTemplate}
            onChange={() => {
              updateSaveThisAsTemplate(!saveThisAsTemplate);
            }}
            label="Also save task as a template"
          />
          {saveThisAsTemplate && (
            <div className={styles.folderSelectorWrapper}>
              <FolderSelector
                schoolId={schoolId}
                templateGroupId={templateGroupId}
                updateTemplateGroupId={updateTemplateGroupId}
              />
            </div>
          )}
        </FieldWrapper>
      )}
      <AdavancedOptionsSeparator />
      <FieldWrapper>
        <AdvancedOptionsButton onClick={toggleAdvancedOptions}>
          Advanced Options
          <RightAligned>
            {isAdvancedOptionsVisible ? (
              <ChevronBottomIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </RightAligned>
        </AdvancedOptionsButton>
      </FieldWrapper>

      {isAdvancedOptionsVisible && (
        <Fragment>
          <FieldWrapper>
            <Label>Extension days</Label>
            <Row>
              <div style={{ width: 70 }}>
                <Input
                  type="number"
                  min={0}
                  max={999}
                  value={extensionDays.toString()}
                  onChange={e => {
                    updateExtensionDays(parseInt(e.target.value, 10));
                  }}
                />
              </div>
              <div style={{ marginLeft: 10 }}>days</div>
            </Row>
          </FieldWrapper>

          <FieldWrapper>
            <Label>Help options</Label>
            <Checkbox
              checked={hideHelpOptions}
              onChange={() => {
                updateHideHelpOptions(!hideHelpOptions);
              }}
              label="Hide videos, hints and examples"
            />
            <VSpacer height={8} />
            <Checkbox
              checked={hideRetry}
              onChange={() => {
                updateHideRetry(!hideRetry);
              }}
              label="Hide retry questions"
            />
            <VSpacer height={8} />
            <Checkbox
              checked={hideCalculator}
              onChange={() => {
                updateHideCalculator(!hideCalculator);
              }}
              label="Hide calculator tools"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Question Variation</Label>
            <FieldWrapper>
              <RadioButton
                onChange={() => {
                  updateQuestionVariation('varyForEachStudent');
                }}
                checked={questionVariation === 'varyForEachStudent'}
                label="Numbers vary for each student"
              />
            </FieldWrapper>
            <FieldWrapper>
              <RadioButton
                onChange={() => {
                  updateQuestionVariation('sameForEveryStudent');
                }}
                checked={questionVariation === 'sameForEveryStudent'}
                label="Same for every student"
              />
            </FieldWrapper>
          </FieldWrapper>
        </Fragment>
      )}
    </TaskCreationFormWrapper>
  );
}
