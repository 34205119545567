import { css } from 'aphrodite';
import { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import ClassCreator from 'ms-pages/Signup/Sunflower/ClassCreator';
import ParentSignup from 'ms-pages/Signup/Sunflower/ParentSignup';
import { SchoolForm } from 'ms-pages/Signup/Sunflower/SchoolForm';
import { StudentSignup } from 'ms-pages/Signup/Sunflower/StudentSignup';
import { StudentSignupContactTeacher } from 'ms-pages/Signup/Sunflower/StudentSignupContactTeacher';
import { TeacherSignupContactUs } from 'ms-pages/Signup/Sunflower/TeacherSignupContactUs';
import {
  getSignupSunflowerSelectorUrl,
  signupSunflowerClassCreationPath,
  signupSunflowerPath,
  signupSunflowerSchoolSelectionPath,
  signupSunflowerSelectorPath,
  signupSunflowerStudentContactTeacherPath,
  signupSunflowerStudentPath,
  signupSunflowerParentPath,
  signupSunflowerTeacherPath,
} from 'ms-utils/urls';

import { RoleSelector } from './RoleSelector';
import TeacherSignup from './TeacherSignup';
import { formStyles, styles } from './styles';

const DISABLE_TEACHER_SIGNUP = false;

/**
 * SAFETY: When mounting this component make sure that the closes parent
 * route includes signupSunflowerTeacherUrl & signupSunflowerStudentUrl in the
 * path definition. Will result in infinite loops otherwise.
 *
 * Also do not mount this component as <Route component={Signup} />.
 * This component shouldn't be re-created on navigation.
 */
function Signup() {
  const { replace } = useHistory();
  const { isExact } = useRouteMatch();

  useEffect(() => {
    if (isExact) {
      return;
    }

    // Page refreshes should immediately return the user
    // to the start screen. We don't want any partial data being sent
    // through to the backend.
    replace(getSignupSunflowerSelectorUrl());
    // We don't want this effect re-running when location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={css(styles.content, formStyles.container)}>
      <Switch>
        <Route path={signupSunflowerSelectorPath} component={RoleSelector} />
        {DISABLE_TEACHER_SIGNUP ? (
          <Route
            exact
            path={signupSunflowerTeacherPath}
            component={TeacherSignupContactUs}
          />
        ) : (
          <Route
            exact
            path={signupSunflowerTeacherPath}
            component={TeacherSignup}
          />
        )}
        <Route
          exact
          path={signupSunflowerStudentPath}
          component={StudentSignup}
        />
        <Route
          exact
          path={signupSunflowerParentPath}
          component={ParentSignup}
        />

        <Route
          exact
          path={signupSunflowerStudentContactTeacherPath}
          component={StudentSignupContactTeacher}
        />
        <Route
          exact
          path={signupSunflowerSchoolSelectionPath}
          component={SchoolForm}
        />
        <Route
          exact
          path={signupSunflowerClassCreationPath}
          component={ClassCreator}
        />
        <Route exact path={signupSunflowerPath}>
          <Redirect to={getSignupSunflowerSelectorUrl()} />
        </Route>
      </Switch>
    </div>
  );
}

export { Signup };
