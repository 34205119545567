import { useMemo } from 'react';

import type { CustomTaskFlowQueryResponse } from 'ms-pages/Teacher/components/CreateTask/flows/CustomTaskFlow/__generated__/CustomTaskFlowQuery.graphql';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import _ from 'ms-utils/relay/extractNode';

type Props = {
  data: CustomTaskFlowQueryResponse | null | undefined;
  problemsCollection: ReadonlyArray<Problem>;
};
export default function useMatchCollectedProblemsWithSubtopic({
  data,
  problemsCollection,
}: Props) {
  const subtopics = useMemo(() => {
    if (data != null && data.syllabus != null) {
      return _(data.syllabus.topics)
        .map(topic => {
          return _(topic.subtopics)
            .filter(subtopic => subtopic.hasExercise)
            .map(subtopic => {
              return {
                id: subtopic.id,
                title: subtopic.title,
                topicId: topic.id,
              };
            });
        })
        .flat();
    }
    return [];
  }, [data]);
  return useMemo(() => {
    if (subtopics.length > 0 && problemsCollection.length > 0) {
      // not all problems will have subtopic ids
      // only the ones that are added directly from the textbook will have subtopic ids
      const collectedProblemsWithSubtopicIds = problemsCollection.filter(
        problem => problem?.[4] != null,
      );
      if (collectedProblemsWithSubtopicIds.length > 0) {
        const collectedProblemsSubtopicIds =
          collectedProblemsWithSubtopicIds.map(problem => problem[4]);
        // to prevent duplicate subtopic ids to make loops expensive to run
        const collectedProblemsSubtopicIdsSet = new Set(
          collectedProblemsSubtopicIds,
        );
        // We start from the subtopic id of the last problem in the problem collection
        // and traverse backwards to find the first subtopic id that matches
        const reversedCollectedProblemsSubtopicIds = Array.from(
          collectedProblemsSubtopicIdsSet,
        ).reverse();
        const subtopicIds = subtopics.map(subtopic => subtopic.id);
        let matchedSubtopicInner = null;
        for (const collectedProblemSubtopicId of reversedCollectedProblemsSubtopicIds) {
          if (
            collectedProblemSubtopicId != null &&
            subtopicIds.findIndex(id => id === collectedProblemSubtopicId) !==
              -1
          ) {
            matchedSubtopicInner = subtopics.find(
              subtopic => subtopic.id === collectedProblemSubtopicId,
            );
            break;
          }
        }
        return matchedSubtopicInner;
      }
    }
    return null;
  }, [problemsCollection, subtopics]);
}
