/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TeacherSkillsReportQueryVariables = {
    studentId: string;
};
export type TeacherSkillsReportQueryResponse = {
    readonly lantern: {
        readonly student: {
            readonly id: string;
            readonly sanaUserId: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly mathspaceStudent: {
                readonly id: string;
            };
            readonly curriculum: {
                readonly sanaCatalogId: string;
                readonly grades: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                }>;
                readonly strands: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                    readonly gradeStrands: ReadonlyArray<{
                        readonly id: string;
                        readonly grade: {
                            readonly id: string;
                        };
                    }>;
                }>;
                readonly outcomes: ReadonlyArray<{
                    readonly id: string;
                    readonly code: string;
                    readonly sanaTopicId: string;
                    readonly skill: {
                        readonly description: string;
                    };
                    readonly gradeStrand: {
                        readonly id: string;
                    };
                }>;
            };
        } | null;
    };
};
export type TeacherSkillsReportQuery = {
    readonly response: TeacherSkillsReportQueryResponse;
    readonly variables: TeacherSkillsReportQueryVariables;
};



/*
query TeacherSkillsReportQuery(
  $studentId: ID!
) {
  lantern {
    student(id: $studentId) {
      id
      sanaUserId
      firstName
      lastName
      mathspaceStudent {
        id
      }
      curriculum {
        sanaCatalogId
        grades {
          id
          title
        }
        strands {
          id
          title
          gradeStrands {
            id
            grade {
              id
            }
          }
        }
        outcomes {
          id
          code
          sanaTopicId
          skill {
            description
            id
          }
          gradeStrand {
            id
          }
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "studentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "studentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanaUserId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Student",
  "kind": "LinkedField",
  "name": "mathspaceStudent",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanaCatalogId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "grades",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strands",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GradeStrand",
      "kind": "LinkedField",
      "name": "gradeStrands",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternGrade",
          "kind": "LinkedField",
          "name": "grade",
          "plural": false,
          "selections": (v6/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanaTopicId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "GradeStrand",
  "kind": "LinkedField",
  "name": "gradeStrand",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherSkillsReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternOutcome",
                    "kind": "LinkedField",
                    "name": "outcomes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skill",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeacherSkillsReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "curriculum",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternOutcome",
                    "kind": "LinkedField",
                    "name": "outcomes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skill",
                        "plural": false,
                        "selections": [
                          (v14/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c064b6729e93529528275886a13063d8",
    "id": null,
    "metadata": {},
    "name": "TeacherSkillsReportQuery",
    "operationKind": "query",
    "text": "query TeacherSkillsReportQuery(\n  $studentId: ID!\n) {\n  lantern {\n    student(id: $studentId) {\n      id\n      sanaUserId\n      firstName\n      lastName\n      mathspaceStudent {\n        id\n      }\n      curriculum {\n        sanaCatalogId\n        grades {\n          id\n          title\n        }\n        strands {\n          id\n          title\n          gradeStrands {\n            id\n            grade {\n              id\n            }\n          }\n        }\n        outcomes {\n          id\n          code\n          sanaTopicId\n          skill {\n            description\n            id\n          }\n          gradeStrand {\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '33dddd793dfa9cb090a5d76491759ded';
export default node;
