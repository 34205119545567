/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type StudentSkillsMapSubstrandHeader_studentStrandStatus = {
    readonly status: StudentStrandStatusEnum;
    readonly strand: {
        readonly id: string;
    };
    readonly diagnosticQuestionsCompleted: number;
    readonly diagnosticNumberOfQuestions: number;
    readonly lastWorkedOnDiagnosticCheckIn: {
        readonly id: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus">;
    readonly " $refType": "StudentSkillsMapSubstrandHeader_studentStrandStatus";
};
export type StudentSkillsMapSubstrandHeader_studentStrandStatus$data = StudentSkillsMapSubstrandHeader_studentStrandStatus;
export type StudentSkillsMapSubstrandHeader_studentStrandStatus$key = {
    readonly " $data"?: StudentSkillsMapSubstrandHeader_studentStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandHeader_studentStrandStatus">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandHeader_studentStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Strand",
      "kind": "LinkedField",
      "name": "strand",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticQuestionsCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "diagnosticNumberOfQuestions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "lastWorkedOnDiagnosticCheckIn",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus"
    }
  ],
  "type": "StudentStrandStatus",
  "abstractKey": null
};
})();
(node as any).hash = '2f11338eb91fd0f8f9fca67ceefdded9';
export default node;
