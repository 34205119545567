import type {
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
} from 'react';

import type { Dimensions } from 'ms-utils/math/cartesian';

import Elements from '../Elements';
import Segment from '../Segment';
import type { NumberLineSegmentData } from '../index';

type Props = {
  axisYPos: number;
  dimensions: Dimensions;
  dragging: boolean;
  dx: number;
  margin: number;
  newElement: NumberLineSegmentData | null | undefined;
  onDragEnd: (id: number, data: NumberLineSegmentData) => void;
  onDragStart: (event: SyntheticTouchEvent | SyntheticMouseEvent) => void;
  padding: number;
  readOnly: boolean;
  value: ReadonlyArray<NumberLineSegmentData>;
};

export default function Slider({
  axisYPos,
  dimensions,
  dragging,
  dx,
  margin,
  newElement,
  onDragEnd,
  onDragStart,
  padding,
  readOnly,
  value,
}: Props) {
  return (
    <g transform={`translate(${margin + padding}, ${axisYPos})`}>
      <Elements
        dimensions={dimensions}
        dragging={dragging}
        dx={dx}
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        readOnly={readOnly}
        value={value}
      />
      {newElement != null ? (
        <Segment data={newElement} dimensions={dimensions} dx={dx} temp />
      ) : null}
    </g>
  );
}
