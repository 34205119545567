import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M4.02641 11.8801L9.62972 6.27645C9.70986 6.19639 9.75 6.10428 9.75 6.00008C9.75 5.89589 9.70986 5.80361 9.62972 5.72351L4.02641 0.120283C3.94614 0.0400098 3.85403 -2.57721e-07 3.74979 -2.62277e-07C3.6456 -2.66832e-07 3.55336 0.0401362 3.47326 0.120283L2.87222 0.721361C2.79208 0.801507 2.75202 0.893741 2.75202 0.997936C2.75202 1.10213 2.79208 1.19436 2.87222 1.27451L7.59771 6.00008L2.87193 10.7258C2.79178 10.8059 2.75198 10.8982 2.75198 11.0022C2.75198 11.1066 2.79203 11.1988 2.87193 11.2789L3.47322 11.8801C3.55332 11.9602 3.6456 12 3.74975 12C3.85403 12 3.94626 11.9602 4.02641 11.8801Z" />
);

const ChevronRightBold = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={12}
    viewBoxHeight={12}
  >
    {path}
  </Icon>
);

ChevronRightBold.displayName = 'ChevronRightBold';

export default ChevronRightBold;
