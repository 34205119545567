import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

import { fontSize, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { onHover } from 'ms-utils/emotion';

const LATERAL_ROW_PADDING = BASE_UNIT * 4;
const HEADER_HEIGHT = BASE_UNIT * 12;
const ROW_HEIGHT = BASE_UNIT * 18;

const styles = StyleSheet.create({
  root: {
    display: 'grid',
  },
  common: {
    gridGap: BASE_UNIT * 2,
    fontFamily: fontFamily.body,
    paddingLeft: LATERAL_ROW_PADDING,
    paddingRight: LATERAL_ROW_PADDING,
  },
  header: {
    backgroundColor: colors.athensGray,
    color: colors.hitGray,
    fontSize: fontSize.xSmall,
    height: HEADER_HEIGHT,
    alignItems: 'center',
  },
  row: {
    alignItems: 'center',
    color: colors.cloudBurst,
    fontSize: fontSize.small,
    transition: '0.3s',
    minHeight: ROW_HEIGHT,
    ...onHover({
      background: colors.porcelain,
    }),
    ':not(:last-child)': {
      borderBottom: `1px solid ${colors.porcelain}`,
    },
  },
  cell: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  textOverflowEllipsis: {
    wordBreak: 'break-word',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const TextOverflowEllipsis = ({
  children,
}: {
  children?: ReactNode;
}) => <div className={css(styles.textOverflowEllipsis)}>{children}</div>;

type CellProps = {
  children?: ReactNode;
  truncate?: boolean;
};

export const Cell = ({ children, truncate }: CellProps) => (
  <div className={css(styles.cell)}>
    {truncate ? (
      <TextOverflowEllipsis>{children}</TextOverflowEllipsis>
    ) : (
      children
    )}
  </div>
);

export const createListHeader = (...widths: ReadonlyArray<string | number>) => {
  const Row = createConstantWidthColumns(...widths);
  return ({ children }: { children: ReactNode }) => (
    <Row className={css(styles.common, styles.header)}>{children}</Row>
  );
};

export const createListRow = (...widths: ReadonlyArray<string | number>) => {
  const Row = createConstantWidthColumns(...widths);
  return ({
    children,
    className,
  }: {
    children: ReactNode;
    className?: string;
  }) => (
    <Row
      className={`${css(styles.common, styles.row)} ${className || ''}`.trim()}
    >
      {children}
    </Row>
  );
};

export const createListComponents = (
  ...widths: ReadonlyArray<string | number>
) => ({
  Row: createListRow(...widths),
  Header: createListHeader(...widths),
  Cell,
});

// TODO: This component is really dumb, let's get rid of this abstraction
const createConstantWidthColumns = (
  ...args: ReadonlyArray<string | number>
) => {
  const gridTemplateColumns = args
    .map(item => (typeof item === 'string' ? item : `${item}fr`))
    .join(' ');
  return ({
    children,
    className,
  }: {
    children: ReactNode;
    className?: string;
  }) => (
    <div
      style={{ gridTemplateColumns }}
      className={`${(className && className) || ' '} ${css(
        styles.root,
      )}`.trim()}
    >
      {children}
    </div>
  );
};
