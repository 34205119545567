/* eslint-disable react/sort-comp */
import { head } from 'ramda';
import { useCallback, useEffect, useRef, useState, cloneElement } from 'react';
import type React from 'react';
import type { ReactElement } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { Logger } from 'ms-utils/app-logging';

export type Dimensions = {
  width: number;
  height: number;
};

type Props = {
  onResize?: (dimensions: Dimensions) => void;
  // While techincally other elements are allowed by Resize Observer spec, we
  // we restrict the API of ResizeDetector to DIV elements
  // https://wicg.github.io/ResizeObserver/#dom-resizeobserver-observationtargets
  render?:
    | ((dimensions: Dimensions) => React.JSX.IntrinsicElements['div'])
    | undefined;
  children?: ReactElement<any, 'div'> | undefined;
};

function ResizeDetector({ children, render, onResize }: Props) {
  const ro = useRef<ResizeObserver | null>(null);
  const root = useRef<HTMLDivElement | null>(null);
  const oldRoot = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const roInstance = (ro.current = new ResizeObserver(entries => {
      const entry = head(entries); // we only observe one element at a time
      if (!entry) return;
      const { width, height } = entry.contentRect;
      setWidth(width);
      setHeight(height);
      onResize?.({ width, height });
    }));

    if (!root.current) return;

    if (!(root.current instanceof window.HTMLDivElement)) {
      Logger.error('You can only use ResizeDetector on Div Elements');
      return;
    }
    // We need to cache the current ref as it may change at each update
    oldRoot.current = root.current;
    roInstance.observe(root.current);
  }, [onResize]);

  useEffect(() => {
    // If new children are passed, we might end up with a different DOM tree.
    // If this is the case we need to unobserve the old root element, and observe
    // the new root element.
    if (
      root.current !== oldRoot.current &&
      root.current != null &&
      oldRoot.current != null &&
      ro.current != null
    ) {
      if (!(root.current instanceof window.HTMLDivElement)) {
        Logger.error('You can only use ResizeDetector on Divs Elements');
        return;
      }
      ro.current.unobserve(oldRoot.current);
      ro.current.observe(root.current);
      // We need to cache the current ref as it may change at each update
      oldRoot.current = root.current;
    }
  }, [children]);

  useEffect(() => {
    return () => {
      if (ro.current != null) {
        ro.current.disconnect();
      }
    };
  }, []);

  const composeRef = useCallback(
    (children: React.JSX.IntrinsicElements['div']) =>
      (node: HTMLDivElement | null) => {
        root.current = node;
        const { ref } = children;
        if (ref != null) {
          if (typeof ref === 'function') {
            ref(node);
          } else {
            Logger.error(
              'Using ResizeDetector on an element that implement ref without a function. This would break the composition and your expectations',
            );
          }
        }
      },
    [],
  );

  if (children) return cloneElement(children, { ref: composeRef(children) });
  if (render) {
    const renderedChildren = render({
      width,
      height,
    });
    // @ts-expect-error Typescript's React types are incompatible with cloneElement. sigh
    return cloneElement(renderedChildren, {
      ref: composeRef(renderedChildren),
    });
  }
  return null;
}

export default ResizeDetector;
