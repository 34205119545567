/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type CheckInStrandStatus = {
    readonly status: StudentStrandStatusEnum;
    readonly strand: {
        readonly id: string;
    };
    readonly lastWorkedOnDiagnosticCheckIn: {
        readonly id: string;
    } | null;
    readonly " $refType": "CheckInStrandStatus";
};
export type CheckInStrandStatus$data = CheckInStrandStatus;
export type CheckInStrandStatus$key = {
    readonly " $data"?: CheckInStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInStrandStatus">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CheckInStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Strand",
      "kind": "LinkedField",
      "name": "strand",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "lastWorkedOnDiagnosticCheckIn",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "StudentStrandStatus",
  "abstractKey": null
};
})();
(node as any).hash = '36bd1fe3ab37a351d46c78c45bbfabb5';
export default node;
