import { graphql } from 'react-relay';

import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';
import useMutation from 'ms-utils/relay/useMutationWithArgs';

import type {
  useSubmitFeedbackMutationResponse,
  useSubmitFeedbackMutationVariables,
} from './__generated__/useSubmitFeedbackMutation.graphql';

const mutation = graphql`
  mutation useSubmitFeedbackMutation(
    $feedbackType: FeedbackTypeEnum!
    $feedbackSubtype: FeedbackSubtypeEnum!
    $score: FeedbackScore
    $supportingText: String
    $rawDebugInfo: String
    $workoutId: ID
    $workEventId: ID
    $problemId: ID
    $subproblemId: ID
    $checkinId: ID
    $checkinQuestionId: ID
    $subtopicId: ID
  ) {
    submitFeedback(
      feedback: {
        feedbackType: $feedbackType
        feedbackSubtype: $feedbackSubtype
        score: $score
        supportingText: $supportingText
        rawDebugInfo: $rawDebugInfo
        workoutId: $workoutId
        workEventId: $workEventId
        problemId: $problemId
        subproblemId: $subproblemId
        checkinId: $checkinId
        checkinQuestionId: $checkinQuestionId
        subtopicId: $subtopicId
      }
    ) {
      errors {
        key
        message
      }
    }
  }
`;
function useSubmitFeedback(): UseMutationResponse<
  useSubmitFeedbackMutationVariables,
  useSubmitFeedbackMutationResponse
> {
  return useMutation({
    mutation,
  });
}
export { useSubmitFeedback };
