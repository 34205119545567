import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const CaretBottom = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={12}
    viewBoxHeight={12}
  >
    <path d="M10.363 4.056 6.664 8.701c-.247.31-.745.392-1.112.183a.752.752 0 0 1-.216-.183l-3.7-4.645c-.246-.31-.15-.731.217-.94A.907.907 0 0 1 2.301 3h7.398c.443 0 .801.303.801.678a.606.606 0 0 1-.137.378Z" />
  </Icon>
);

CaretBottom.displayName = 'CaretBottom';

export default CaretBottom;
