export type MathShortcut =
  | 'ADD'
  | 'SUBTRACT'
  | 'MULTIPLY'
  | 'DIVIDE'
  | 'EQUALS'
  | 'NOT_EQUALS'
  | 'LESS_THAN'
  | 'GREATER_THAN'
  | 'LESS_THAN_OR_EQUAL'
  | 'GREATER_THAN_OR_EQUAL'
  | 'OPEN_PARENTHESIS'
  | 'CLOSE_PARENTHESIS'
  | 'OPEN_BRACE'
  | 'CLOSE_BRACE'
  | 'ABSOLUTE_VALUE_VERTICAL_BAR'
  | 'EXPONENT'
  | 'SUBSCRIPT'
  | 'FRACTION'
  | 'MIXED_FRACTION'
  | 'SQUARE_ROOT'
  | 'NTH_ROOT'
  | 'SINE'
  | 'COSINE'
  | 'TANGENT'
  | 'INVERSE_SINE'
  | 'INVERSE_COSINE'
  | 'INVERSE_TANGENT'
  | 'LOGARITHM_BASE_10'
  | 'LOGARITHM_BASE_B'
  | 'NATURAL_LOGARITHM'
  | 'LOWERCASE_PI'
  | 'LOWERCASE_ALPHA'
  | 'LOWERCASE_BETA'
  | 'LOWERCASE_GAMMA'
  | 'LOWERCASE_DELTA'
  | 'LOWERCASE_THETA'
  | 'LOWERCASE_OMEGA'
  | 'SUMMATION'
  | 'SIMILAR_EQUAL'
  | 'SIMILAR'
  | 'APPROXIMATELY'
  | 'EQUIVALENT'
  | 'CONGRUENT'
  | 'IS_COMMON'
  | 'PARALLEL'
  | 'PERPENDICULAR'
  | 'DEGREES'
  | 'MINUTES'
  | 'SECONDS'
  | 'TRIANGLE'
  | 'ANGLE'
  | 'PLUS_MINUS'
  | 'BINOMIAL_COEFFICIENT'
  | 'NCR_BINOMIAL_COEFFICIENT'
  | 'FACTORIAL'
  | 'RECURRING_DECIMAL'
  | 'NPR_PERMUTATION'
  | 'UNION'
  | 'INTERSECTION'
  | 'INDEFINITE_INTEGRAL'
  | 'DEFINITE_INTEGRAL'
  | 'DEFINITE_INTEGRAL_EVALUATION_BRACKETS'
  | 'DIFFERENTIAL_OPERATOR_LEIBNIZ_NOTATION'
  | 'DERIVATIVE_OF_F_LAGRANGE_NOTATION'
  | 'LIMIT'
  | 'INFINITY';

export type CommonKeystrokeConfig = {
  // Determines if this should be displayed as a hint on the toolbar
  // This is effectively a "secret" shortcut
  shouldNotDisplay?: boolean | undefined;
};

export type SingleCharacterKeystroke = CommonKeystrokeConfig & {
  pressedKey: string;
};

export type TypedWordKeystroke = CommonKeystrokeConfig & {
  typedWord: string;
  // some configurations have secret "alternates"
  alternates?: ReadonlyArray<string> | undefined;
};

export type MultipleSingleCharacterKeystroke = CommonKeystrokeConfig & {
  pressedKeys: ReadonlyArray<string>;
};

export type SequentialCharacterKeystroke = CommonKeystrokeConfig & {
  // eslint-disable-next-line no-use-before-define
  characterSequence: ReadonlyArray<KeystrokeConfig>;
};

export type ModifiedSingleCharacterKeystroke = CommonKeystrokeConfig & {
  modifiers: ReadonlyArray<'ctrl' | 'alt' | 'shift'>;
  pressedKey: string;
  /** The actual keystroke configuration
   * Mathquill has a very specific keystroke "parser" that transforms keystrokes
   * into strings. The unfortunate thing is that it's a pretty leaky abstraction.
   * To capture 'ctrl shift /' for example, we have to use 'Ctrl-Shift-¿'
   */
  mathQuillKeystroke?: string | undefined;
};

export type KeystrokeTypes =
  | 'SINGLE_CHARACTER'
  | 'TYPED_WORD'
  | 'MULTIPLE_SINGLE_CHARACTER'
  | 'SEQUENTIAL_CHARACTERS'
  | 'MODIFIED_SINGLE_CHARACTER';

export type KeystrokeConfig =
  | SingleCharacterKeystroke
  | TypedWordKeystroke
  | MultipleSingleCharacterKeystroke
  | SequentialCharacterKeystroke
  | ModifiedSingleCharacterKeystroke;

/* Used as a source of truth between MATHQUILL_CONFIG in this file and
 * BUTTONS in MathToolbar/config.jsx. We want to make sure that if we
 * change the implementation of a keyboard shortcut, we don't
 * forget to change the corresponding help text.
 */
export const KEYSTROKE_CONFIG: Partial<
  Record<MathShortcut, ReadonlyArray<KeystrokeConfig>>
> = {
  ADD: [{ pressedKey: '+' }],
  SUBTRACT: [{ pressedKey: '-' }],
  MULTIPLY: [{ pressedKey: '*' }],
  DIVIDE: [
    {
      modifiers: ['ctrl', 'shift'],
      pressedKey: '/',
      mathQuillKeystroke: 'Ctrl-Shift-¿',
    },
    {
      typedWord: 'div',
      shouldNotDisplay: true,
    },
  ],
  PLUS_MINUS: [{ typedWord: 'pm' }],
  EQUALS: [{ pressedKey: '=' }],
  LESS_THAN: [{ pressedKey: '<' }],
  GREATER_THAN: [{ pressedKey: '>' }],
  LESS_THAN_OR_EQUAL: [
    { characterSequence: [{ pressedKey: '<' }, { pressedKey: '=' }] },
  ],
  GREATER_THAN_OR_EQUAL: [
    { characterSequence: [{ pressedKey: '>' }, { pressedKey: '=' }] },
  ],
  OPEN_PARENTHESIS: [{ pressedKey: '(' }],
  CLOSE_PARENTHESIS: [{ pressedKey: ')' }],
  ABSOLUTE_VALUE_VERTICAL_BAR: [{ pressedKey: '|' }],
  EXPONENT: [{ pressedKey: '^' }],
  SUBSCRIPT: [{ pressedKey: '_' }],
  FRACTION: [{ pressedKey: '/' }],
  MIXED_FRACTION: [
    { typedWord: 'mixed' },
    {
      modifiers: ['shift'],
      pressedKey: '/',
      mathQuillKeystroke: 'Shift-¿',
      shouldNotDisplay: true,
    },
  ],
  SQUARE_ROOT: [{ typedWord: 'sqrt' }],
  NTH_ROOT: [{ typedWord: 'nthrt' }],
  UNION: [{ typedWord: 'cup' }],
  INTERSECTION: [{ typedWord: 'cap' }],
  SUMMATION: [{ typedWord: 'sum' }],
  LOWERCASE_PI: [{ typedWord: 'pi' }],
  LOWERCASE_ALPHA: [{ typedWord: 'alpha' }],
  LOWERCASE_BETA: [{ typedWord: 'beta' }],
  LOWERCASE_GAMMA: [{ typedWord: 'gamma' }],
  LOWERCASE_DELTA: [{ typedWord: 'delta' }],
  LOWERCASE_THETA: [{ typedWord: 'theta' }],
  LOWERCASE_OMEGA: [{ typedWord: 'omega' }],
  INFINITY: [{ typedWord: 'infinity' }],
  INDEFINITE_INTEGRAL: [{ typedWord: 'integ' }],
  DEFINITE_INTEGRAL: [{ typedWord: 'defint' }],
  DEFINITE_INTEGRAL_EVALUATION_BRACKETS: [{ pressedKeys: ['[', ']'] }],
  LIMIT: [{ typedWord: 'lim' }],
  SINE: [{ typedWord: 'sin' }],
  COSINE: [{ typedWord: 'cos' }],
  TANGENT: [{ typedWord: 'tan' }],
  INVERSE_SINE: [
    {
      characterSequence: [
        { typedWord: 'sin' },
        { pressedKey: '^' },
        { typedWord: '-1' },
      ],
    },
  ],
  INVERSE_COSINE: [
    {
      characterSequence: [
        { typedWord: 'cos' },
        { pressedKey: '^' },
        { typedWord: '-1' },
      ],
    },
  ],
  INVERSE_TANGENT: [
    {
      characterSequence: [
        { typedWord: 'tan' },
        { pressedKey: '^' },
        { typedWord: '-1' },
      ],
    },
  ],
  LOGARITHM_BASE_10: [{ typedWord: 'log' }],
  LOGARITHM_BASE_B: [
    {
      characterSequence: [{ typedWord: 'log' }, { pressedKey: '_' }],
    },
  ],
  NATURAL_LOGARITHM: [{ typedWord: 'ln' }],
  DEGREES: [{ typedWord: 'deg' }],
  MINUTES: [{ pressedKey: "'" }],
  SECONDS: [{ pressedKey: '"' }],
  ANGLE: [{ typedWord: 'angle' }],
  EQUIVALENT: [
    { characterSequence: [{ pressedKey: '=' }, { pressedKey: '=' }] },
  ],
  CONGRUENT: [
    { characterSequence: [{ pressedKey: '~' }, { pressedKey: '=' }] },
  ],
  SIMILAR_EQUAL: [{ typedWord: 'simeq' }],
  SIMILAR: [{ pressedKey: '~' }],
  PARALLEL: [{ typedWord: 'parallel' }],
  TRIANGLE: [{ typedWord: 'triangle' }],
  IS_COMMON: [{ typedWord: 'iscommon' }],
  BINOMIAL_COEFFICIENT: [{ typedWord: 'binom' }],
  NCR_BINOMIAL_COEFFICIENT: [{ typedWord: 'comb', alternates: ['nCr'] }],
  NPR_PERMUTATION: [{ typedWord: 'perm', alternates: ['nPr'] }],
  FACTORIAL: [{ pressedKey: '!' }],
  OPEN_BRACE: [{ pressedKey: '{' }],
  CLOSE_BRACE: [{ pressedKey: '}' }],
} as const;

export const groupConfigurationByType = (
  keystrokeConfig: ReadonlyArray<KeystrokeConfig>,
): Partial<Record<KeystrokeTypes, KeystrokeConfig>> => {
  // Coercion required as TS can't build up data structures soundly
  const final = {} as Partial<Record<KeystrokeTypes, KeystrokeConfig>>;
  keystrokeConfig.forEach(config => {
    switch (true) {
      case 'modifiers' in config:
        final.MODIFIED_SINGLE_CHARACTER = config;
        return;
      case 'pressedKey' in config:
        final.SINGLE_CHARACTER = config;
        return;
      case 'pressedKeys' in config:
        final.MULTIPLE_SINGLE_CHARACTER = config;
        return;
      case 'characterSequence' in config:
        final.SEQUENTIAL_CHARACTERS = config;
        return;
      case 'typedWord' in config:
        final.TYPED_WORD = config;
        return;
      default:
        return final;
    }
  });

  return final;
};

export const getDisplayableConfiguration = (
  configs: ReadonlyArray<KeystrokeConfig>,
) => groupConfigurationByType(configs.filter(item => !item.shouldNotDisplay));

export const getConfigurationType = (
  config: ReadonlyArray<KeystrokeConfig>,
  type: KeystrokeTypes,
) => {
  const groups = groupConfigurationByType(config);
  return groups[type];
};

export const getMathquillKeystroke = (
  key: MathShortcut,
): string | undefined => {
  const configurations = KEYSTROKE_CONFIG[key];
  if (configurations == null) return undefined;

  const configuration = getConfigurationType(
    configurations,
    'MODIFIED_SINGLE_CHARACTER',
  );

  if (configuration == null) return undefined;

  return 'mathQuillKeystroke' in configuration
    ? configuration.mathQuillKeystroke
    : undefined;
};

export const getMathquillCommand = (
  key: MathShortcut,
): ReadonlyArray<string> | undefined => {
  const configurations = KEYSTROKE_CONFIG[key];
  if (configurations == null) return undefined;
  const configuration = getConfigurationType(configurations, 'TYPED_WORD');

  if (configuration === undefined || !('typedWord' in configuration)) {
    throw new Error('invariant: configuration has no typedWord');
  }

  const typedWord = [configuration.typedWord];
  return configuration.alternates
    ? typedWord.concat(configuration.alternates)
    : typedWord;
};
