import { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnowplow } from 'ms-helpers/Snowplow';
import type {
  DifficultyLevelTypes,
  SelectedDifficultyLevels,
  ProblemContents,
} from 'ms-pages/Textbooks/components/ProblemDifficultyFilter/types';
import type { Entries } from 'ms-utils/typescript-utils';

export default function useDifficultyLevelFilters({
  problemContents,
}: {
  problemContents: ProblemContents;
}) {
  const { trackStructEvent } = useSnowplow();

  // We are checking the types of the difficulty levels in the problem contents
  // to create the initial state, we only include the difficulty levels that are
  // present in the problem contents.
  const initialDifficultyLabels = useMemo(() => {
    const difficultyLevels: SelectedDifficultyLevels = {};

    for (const problemContent of problemContents) {
      if (
        !difficultyLevels.hasOwnProperty(
          problemContent.difficultyLevel ?? 'UNSPECIFIED',
        )
      ) {
        difficultyLevels[problemContent.difficultyLevel ?? 'UNSPECIFIED'] =
          false;
      }
    }
    return difficultyLevels;
  }, [problemContents]);

  const [selectedDifficultyLevels, setSelectedDifficultyLevels] =
    useState<SelectedDifficultyLevels>(initialDifficultyLabels);

  const toggleDifficultyLevelSelection = useCallback(
    (difficultyLevel: DifficultyLevelTypes) => {
      setSelectedDifficultyLevels(prev => ({
        ...prev,
        [difficultyLevel]: !prev[difficultyLevel],
      }));
    },
    [],
  );

  // Providing selected difficulty levels as an array of strings
  // to be used easily in filtering the problems
  const selectedDifficultyFilters = useMemo(() => {
    const selectedDifficultyLevelsArr = Object.entries(
      selectedDifficultyLevels,
    ) as Entries<SelectedDifficultyLevels>;

    return selectedDifficultyLevelsArr
      .filter(([, isSelected]) => isSelected)
      .map(([difficulty]) => difficulty);
  }, [selectedDifficultyLevels]);

  useEffect(() => {
    if (selectedDifficultyFilters.length === 0) return;

    trackStructEvent({
      category: 'textbook',
      action: 'used_difficulty_filter',
      label: selectedDifficultyFilters.join(', '),
    });
  }, [selectedDifficultyFilters, trackStructEvent]);

  // This is to make sure that the difficulty levels are set and ready,
  // And it's also an indirect way of checking if the subtopic has problems
  // before showing the difficulty filter
  const canShowDifficultyFilter =
    'EASY' in selectedDifficultyLevels ||
    'MEDIUM' in selectedDifficultyLevels ||
    'HARD' in selectedDifficultyLevels ||
    'UNSPECIFIED' in selectedDifficultyLevels;

  return {
    selectedDifficultyLevels,
    toggleDifficultyLevelSelection,
    selectedDifficultyFilters,
    canShowDifficultyFilter,
  };
}
