import styled from '@emotion/styled';

import { fontFamily, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

const ForbiddenView = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: colors.white,
  backgroundColor: colors.cloudBurst,
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  padding: 5 * BASE_UNIT,
});

// This component is currently a placeholder for a link to a pricing page on the website
// that is still to be created.  When that page is available, the copy will be updated
// and a button linking to that page will be provided.
//
// For now, we just want a simple component that can be embedded in all the required places in
// the user interface.
function ForbiddenProductSlice() {
  return (
    <ForbiddenView>
      Your current subscription plan does not provide access to this feature
    </ForbiddenView>
  );
}

export default ForbiddenProductSlice;
