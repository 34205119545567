import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import type {
  State,
  TargetMastery,
  TaskDueDate,
  TaskName,
  TaskStartDate,
  Updater,
  AssignedCheckinSkillIds,
} from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/state';

export const updateTaskName: Updater<TaskName> = newTaskName => state => ({
  ...state,
  taskName: newTaskName,
  edited: true,
});

export const updateTargetMastery: Updater<TargetMastery> =
  targetMastery => state => ({
    ...state,
    targetMastery,
    edited: true,
  });

export const updateHideHelpOptions: Updater<boolean> =
  hideHelpOptions => state => ({
    ...state,
    hideHelpOptions,
    edited: true,
  });

export const updateTaskStartDate: Updater<TaskStartDate> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
    edited: true,
  });

export const updateTaskDueDate: Updater<TaskDueDate> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
  edited: true,
});

export const setSelectedClasses: Updater<
  ReadonlyArray<ClassSelectionPayload>
> = klasses => state => ({
  ...state,
  selectedClasses: klasses,
  edited: true,
});

export const setSelectedStudents: Updater<
  ReadonlyArray<StudentSelectionPayload>
> = students => state => ({
  ...state,
  selectedStudents: students,
  edited: true,
});

export const setExcludedStudents: Updater<
  ReadonlyArray<StudentSelectionPayload>
> = students => state => ({
  ...state,
  excludedStudents: students,
  edited: true,
});

export const toggleHideCalculator: Updater<void> = () => state => ({
  ...state,
  disableCalculator: !state.disableCalculator,
});

export const resetState: Updater<State> = nextState => () => nextState;

export const setStartsWithLesson: Updater<boolean> =
  startsWithLesson => state => ({
    ...state,
    startsWithLesson,
  });

export const setAssignedCheckinSkillIds: Updater<AssignedCheckinSkillIds> =
  assignedCheckinSkillIds => state => ({
    ...state,
    assignedCheckinSkillIds,
  });
