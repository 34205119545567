/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SubtopicRedirectorQueryVariables = {
    topicId: string;
};
export type SubtopicRedirectorQueryResponse = {
    readonly topic: {
        readonly subtopics: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                };
            }>;
        };
    } | null;
};
export type SubtopicRedirectorQuery = {
    readonly response: SubtopicRedirectorQueryResponse;
    readonly variables: SubtopicRedirectorQueryVariables;
};



/*
query SubtopicRedirectorQuery(
  $topicId: ID!
) {
  topic(id: $topicId) {
    subtopics(first: 1) {
      edges {
        node {
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "topicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "topicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "SubtopicConnection",
  "kind": "LinkedField",
  "name": "subtopics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SubtopicEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Subtopic",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "subtopics(first:1)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubtopicRedirectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubtopicRedirectorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1835482c689a9be74112f630ba5ad6a2",
    "id": null,
    "metadata": {},
    "name": "SubtopicRedirectorQuery",
    "operationKind": "query",
    "text": "query SubtopicRedirectorQuery(\n  $topicId: ID!\n) {\n  topic(id: $topicId) {\n    subtopics(first: 1) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '69fb9a2b3a976d2548fe2ddb5f347c5c';
export default node;
