/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserStatusFilterInput = {
    curriculumNodeIds: Array<string>;
};
export type useProgressQueryVariables = {
    studentId: string;
    filters: Array<UserStatusFilterInput>;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type useProgressQueryResponse = {
    readonly lantern: {
        readonly student: {
            readonly userStatuses: ReadonlyArray<{
                readonly trueProficiency: number | null;
                readonly userStatusFilter: {
                    readonly curriculumNodeIds: ReadonlyArray<string>;
                };
            }> | null;
        } | null;
    };
};
export type useProgressQuery = {
    readonly response: useProgressQueryResponse;
    readonly variables: useProgressQueryVariables;
};



/*
query useProgressQuery(
  $studentId: ID!
  $filters: [UserStatusFilterInput!]!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    student(id: $studentId) {
      userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
        trueProficiency
        userStatusFilter {
          curriculumNodeIds
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studentId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "studentId"
  }
],
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filters",
      "variableName": "filters"
    },
    {
      "kind": "Variable",
      "name": "growthPeriod",
      "variableName": "growthPeriod"
    },
    {
      "kind": "Variable",
      "name": "previewingWithProblemData",
      "variableName": "previewingWithProblemData"
    }
  ],
  "concreteType": "UserStatus",
  "kind": "LinkedField",
  "name": "userStatuses",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trueProficiency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserStatusFilter",
      "kind": "LinkedField",
      "name": "userStatusFilter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "curriculumNodeIds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useProgressQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "LanternStudent",
            "kind": "LinkedField",
            "name": "student",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af981bb7a4651158720def2a7081d04a",
    "id": null,
    "metadata": {},
    "name": "useProgressQuery",
    "operationKind": "query",
    "text": "query useProgressQuery(\n  $studentId: ID!\n  $filters: [UserStatusFilterInput!]!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    student(id: $studentId) {\n      userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n        trueProficiency\n        userStatusFilter {\n          curriculumNodeIds\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '96cb0f199d5b07d1f0fa9eec1b1b2644';
export default node;
