import { useState, useRef, useContext } from 'react';

import InfoFilled from 'ms-components/icons/InfoFilled';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import useSelectedViewState from 'ms-pages/Teacher/TeacherClassReport/views/TeacherClassReportTasks/ClassReportTasksFilter/useSelectedViewState';
import Filter from 'ms-pages/Teacher/components/FilterForm/Filter';
import FilterPopover from 'ms-pages/Teacher/components/FilterForm/FilterPopover';
import {
  StateContext as FiltersContext,
  UpdatersContext as FiltersUpdatersContext,
} from 'ms-pages/Teacher/state';
import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Separator from 'ms-ui-primitives/Separator';
import { styled } from 'ms-utils/emotion';

const FiltersDisabledDisclaimer = styled(
  {
    lineHeight: '22px',
  },
  'p',
);

export default function TaskTypeFilter() {
  const { taskTypeSelector } = useContext(FiltersContext);
  const { toggleTaskTypeSelection } = useContext(FiltersUpdatersContext);
  const [popoverOpen, setpopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const [selectedView] = useSelectedViewState();
  const {
    featureFlags: { engageTasks, canAssignDiscoveryCheckIn },
  } = useViewer();

  const selected = (
    Object.keys(taskTypeSelector) as Array<keyof typeof taskTypeSelector>
  )
    .filter(taskType => taskTypeSelector[taskType])
    .some(Boolean);

  return (
    <Filter
      label="Task Type"
      popoverAnchorRef={popoverRef}
      selected={selected}
      popover={
        popoverOpen && (
          <FilterPopover
            onDismiss={() => setpopoverOpen(false)}
            anchorElementRef={popoverRef}
          >
            <div style={{ maxWidth: 250 }}>
              <p
                style={{
                  color: colors.cloudBurst,
                  fontWeight: fontWeight.semibold,
                  fontSize: fontSize.medium,
                }}
              >
                Task Type
              </p>
              <Separator size={4} />
              <Checkbox
                label="Adaptive Tasks"
                checked={taskTypeSelector.ADAPTIVE_TASK}
                onChange={() => toggleTaskTypeSelection('ADAPTIVE_TASK')}
              />
              <Separator size={4} />
              <Checkbox
                checked={taskTypeSelector.CUSTOM_TASK}
                onChange={() => toggleTaskTypeSelection('CUSTOM_TASK')}
                label="Custom Tasks"
              />
              <Separator size={4} />
              <Checkbox
                checked={taskTypeSelector.LESSON_TASK}
                onChange={() => toggleTaskTypeSelection('LESSON_TASK')}
                label="Lessons"
                disabled={selectedView === 'grades'}
              />
              <Separator size={4} />
              <Checkbox
                checked={taskTypeSelector.WORKSHEET_TASK}
                onChange={() => toggleTaskTypeSelection('WORKSHEET_TASK')}
                label="Worksheets"
                disabled={selectedView === 'grades'}
              />
              {engageTasks && (
                <>
                  <Separator size={4} />
                  <Checkbox
                    checked={taskTypeSelector.ENGAGE_TASK}
                    onChange={() => toggleTaskTypeSelection('ENGAGE_TASK')}
                    label="Engage Tasks"
                    disabled={selectedView === 'grades'}
                  />
                </>
              )}
              {canAssignDiscoveryCheckIn && (
                <>
                  <Separator size={4} />
                  <Checkbox
                    checked={taskTypeSelector.CHECK_IN_TASK}
                    onChange={() => toggleTaskTypeSelection('CHECK_IN_TASK')}
                    label="Check-in Tasks"
                    disabled={selectedView === 'grades'}
                  />
                </>
              )}
              <Separator size={4} />
              {selectedView === 'grades' && (
                <div style={{ display: 'flex' }}>
                  <InfoFilled color={colors.cloudBurst} />
                  <Separator size={2} />
                  <FiltersDisabledDisclaimer>
                    Only Adaptive and Custom tasks are included in the Grades
                    report
                  </FiltersDisabledDisclaimer>
                </div>
              )}
            </div>
          </FilterPopover>
        )
      }
      onClick={() => setpopoverOpen(true)}
    />
  );
}
