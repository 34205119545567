import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path d="M6.76105 2.72793L14.502 8.89334C15.0193 9.30531 15.1559 10.1347 14.8073 10.7459C14.7261 10.8881 14.6225 11.0106 14.502 11.1065L6.76105 17.2719C6.24382 17.6839 5.54187 17.5224 5.19321 16.9113C5.06748 16.6909 5.00031 16.4311 5.00031 16.1653L5.00031 3.83452C5.00031 3.09748 5.50598 2.5 6.12975 2.5C6.3547 2.5 6.57453 2.57937 6.76105 2.72793Z" />
  </>
);
const SortRight = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

SortRight.displayName = 'SortRight';

export default SortRight;
