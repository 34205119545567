import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M16.3662553,3.63459831 C12.851218,0.121800562 7.15103064,0.121800562 3.6371315,3.63459831 C0.120956166,7.14739606 0.120956166,12.8498229 3.6371315,16.3648602 C5.3914561,18.1191848 7.69034273,18.9972098 9.98996362,19 L10.0149651,19 C12.314586,18.9966224 14.6130688,18.1180467 16.3661084,16.3648602 C19.8812925,12.8522827 19.8812925,7.14739606 16.3662553,3.63459831 Z M9.36683604,4.11168209 L11.2312993,4.11168209 C11.7916478,4.11168209 11.8739581,4.43816966 11.8739581,4.63329858 L11.8692588,4.80247149 L11.8551611,4.99738013 L11.4722825,11.8312243 C11.4170297,12.3445804 11.1903277,12.4445863 10.8132498,12.4445863 L9.81946904,12.4445863 C9.44352923,12.4445863 9.21440419,12.3436626 9.15911461,11.8184482 L8.77733745,4.99983989 L8.76569947,4.80247149 L8.75875749,4.63329858 C8.75817333,4.43816966 8.83695915,4.11168209 9.36683604,4.11168209 Z M10.325299,17.24138 C9.345616,17.24138 8.54898338,16.4519064 8.54898338,15.4816219 C8.54898338,14.4853814 9.32905849,13.7053063 10.325299,13.7053063 C11.2958038,13.7053063 12.0850571,14.5017186 12.0850571,15.4816219 C12.0852407,16.4353489 11.2769701,17.24138 10.325299,17.24138 Z" />
);

const ExclamationMarkFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ExclamationMarkFilled.displayName = 'ExclamationMarkFilled';

export default ExclamationMarkFilled;
