import type { ReactNode } from 'react';

import CheckMarkThick from 'ms-components/icons/CheckMarkThick';
import MathspaceLogo from 'ms-components/logos/Logo';
import Milo from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/Milo';
import {
  borderRadiusUILarger,
  font,
  fontSize,
  fontWeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Bold from 'ms-ui-primitives/Bold';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Separator from 'ms-ui-primitives/Separator';
import { styled } from 'ms-utils/emotion';

import classesBadge from './assets/classesBadge.svg';
import greenDots from './assets/greenDots.svg';
import hatImg from './assets/hat.svg';
import heartImg from './assets/heart.svg';
import noteImg from './assets/note.svg';
import redDots from './assets/redDots.svg';
import schoolsBadge from './assets/schoolsBadge.svg';
import schoolsPlusBadge from './assets/schoolsPlusBadge.svg';
import scribbleImg from './assets/scribble.svg';
import type { Role } from './types';

const LOGO_SIZE = 64;
const bookDemoUrl = 'https://calendly.com/d/g63-tjb-bw8';
const WIDTH = 320;

const Root = styled({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.seashell,
  width: WIDTH,
  minHeight: 475,
  padding: '32px 16px',
  borderRadius: borderRadiusUILarger,
  position: 'relative',
});

const Title = styled({
  ...font.gilroyHeading,
  color: colors.grey,
});

const Subtitle = styled({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semibold,
  fontFamily: 'inherit',
  color: colors.grey90,
});

const DemoText = styled({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semibold,
  fontFamily: 'inherit',
  color: colors.grey,
  textAlign: 'center',
});

const BadgeText = styled({
  fontSize: fontSize.small,
  fontWeight: fontWeight.semibold,
  fontFamily: 'inherit',
  color: colors.grey,
});

const IconContainer = styled({
  display: 'flex',
  alignItems: 'center',
  width: 64,
  height: 64,
});

const BadgeListContainer = styled({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
});

const BadgeItemContainer = styled({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: 100,
});

const BadgeContainer = styled({
  display: 'flex',
  alignItems: 'center',
  width: 48,
  height: 48,
});

const DecorationContainer = styled({
  default: {
    bottom: 0,
    zIndex: 3,
  },
  negativeMargin: {
    marginBottom: -110,
  },
});

const DemoContainer = styled({
  zIndex: 3,
});

const Divider = styled({
  height: 1,
  width: '100%',
  backgroundColor: colors.iron,
});

const FeatureBackgroundStyle = styled({
  position: 'absolute',
  bottom: 0,
  borderRadius: 3 * BASE_UNIT,
  overflow: 'hidden',
  height: 123,
  zIndex: 1,
});

const List = styled({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: 264,
});

const ListItemContainer = styled({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  ':not(:last-child)': {
    paddingBottom: 2 * BASE_UNIT,
  },
});

const GreenCheckMark = () => (
  <CheckMarkThick color={colors.mountainMeadow} size={16} />
);

const ListItem = ({ children }: { children: ReactNode }) => (
  <ListItemContainer>
    <GreenCheckMark />
    <Separator size={2} />
    <div>{children}</div>
  </ListItemContainer>
);

const FeatureBackground = () => (
  <FeatureBackgroundStyle>
    <img alt="scribble" width={WIDTH} role="presentation" src={scribbleImg} />
  </FeatureBackgroundStyle>
);

function SignupInfoPanel({ selectedRole }: { selectedRole: Role | null }) {
  return (
    <Root>
      {selectedRole === null && (
        <>
          <MathspaceLogo width={LOGO_SIZE} height={LOGO_SIZE} />
          <Separator size={2} />
          <Title>Mathspace Personal</Title>
          <Separator size={1} />
          <Subtitle>Free forever</Subtitle>
          <Separator size={4} />
          <List>
            <ListItem>
              <Bold>Free</Bold> for all students
            </ListItem>
            <ListItem>
              <Bold>One class</Bold> free for teachers
            </ListItem>
            <ListItem>
              <Bold>Learn</Bold> with interactive textbooks
            </ListItem>
            <ListItem>
              <Bold>Practice</Bold> with adaptive tasks
            </ListItem>
            <ListItem>
              <Bold>Review</Bold> with diagnostic check-ins
            </ListItem>
          </List>
          <DecorationContainer negativeMargin>
            <img alt="mathspace" role="presentation" src={greenDots} />
          </DecorationContainer>
        </>
      )}
      {selectedRole === 'student' && (
        <>
          <IconContainer>
            <img alt="mathspace" role="presentation" src={hatImg} height={64} />
          </IconContainer>
          <Separator size={2} />
          <Title>Mathspace Personal</Title>
          <Separator size={1} />
          <Subtitle>For students</Subtitle>
          <Separator size={4} />
          <List>
            <ListItem>
              <Bold>Free</Bold> for your whole school career
            </ListItem>
            <ListItem>
              <Bold>Learn</Bold> with interactive textbooks
            </ListItem>
            <ListItem>
              <Bold>Practice</Bold> with adaptive tasks
            </ListItem>
            <ListItem>
              <Bold>Grow skills</Bold> with check-ins
            </ListItem>
          </List>
          <Separator size={8} />
          <DecorationContainer>
            <Milo variant="pencil" />
          </DecorationContainer>
        </>
      )}
      {selectedRole === 'teacher' && (
        <>
          <IconContainer>
            <img
              alt="mathspace"
              role="presentation"
              src={noteImg}
              height={64}
            />
          </IconContainer>
          <Separator size={2} />
          <Title>Mathspace Personal</Title>
          <Separator size={1} />
          <Subtitle>For teachers</Subtitle>
          <Separator size={4} />
          <List>
            <ListItem>
              <Bold>Free</Bold> for one class of 30 students
            </ListItem>
            <ListItem>
              <Bold>Learn</Bold> with interactive textbooks
            </ListItem>
            <ListItem>
              <Bold>Practice</Bold> with adaptive tasks
            </ListItem>
            <ListItem>
              <Bold>Review</Bold> with diagnostic check-ins
            </ListItem>
          </List>
          <DemoContainer>
            <Separator size={6} />
            <Divider />
            <Separator size={6} />
            <div>
              <DemoText>
                <AnchorButton
                  type="tertiary"
                  size="regular"
                  href={bookDemoUrl}
                  target="_blank"
                  isInline
                >
                  Book a demo
                </AnchorButton>{' '}
                to explore paid plans for schools
              </DemoText>
            </div>
            <Separator size={4} />
            <BadgeListContainer>
              <BadgeItemContainer>
                <BadgeContainer>
                  <img alt="mathspace" role="presentation" src={classesBadge} />
                </BadgeContainer>
                <Separator size={1} />
                <BadgeText>Classes</BadgeText>
              </BadgeItemContainer>
              <BadgeItemContainer>
                <BadgeContainer>
                  <img alt="mathspace" role="presentation" src={schoolsBadge} />
                </BadgeContainer>
                <Separator size={1} />
                <BadgeText>Schools</BadgeText>
              </BadgeItemContainer>
              <BadgeItemContainer>
                <BadgeContainer>
                  <img
                    alt="mathspace"
                    role="presentation"
                    src={schoolsPlusBadge}
                  />
                </BadgeContainer>
                <Separator size={1} />
                <BadgeText>Schools Plus</BadgeText>
              </BadgeItemContainer>
            </BadgeListContainer>
          </DemoContainer>
        </>
      )}
      {selectedRole === 'parent' && (
        <>
          <IconContainer>
            <img
              alt="mathspace"
              role="presentation"
              src={heartImg}
              height={64}
            />
          </IconContainer>
          <Separator size={2} />
          <Title>Mathspace Personal</Title>
          <Separator size={1} />
          <Subtitle>For parents</Subtitle>
          <Separator size={4} />
          <List>
            <ListItem>
              <Bold>Free</Bold> for your child's school career
            </ListItem>
            <ListItem>
              <Bold>Learn</Bold> with interactive textbooks
            </ListItem>
            <ListItem>
              <Bold>Practice</Bold> with adaptive tasks
            </ListItem>
            <ListItem>
              <Bold>Track growth</Bold> with weekly emails
            </ListItem>
          </List>
          <Separator size={4} />
          <DecorationContainer negativeMargin>
            <img alt="mathspace" role="presentation" src={redDots} />
          </DecorationContainer>
        </>
      )}
      <FeatureBackground />
    </Root>
  );
}

export { SignupInfoPanel };
