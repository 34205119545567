/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSelector_teacher = {
    readonly studentSearch: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly user: {
                    readonly id: string;
                    readonly firstName: string;
                    readonly lastName: string;
                    readonly avatar: string;
                };
            };
        }>;
    } | null;
    readonly " $refType": "StudentSelector_teacher";
};
export type StudentSelector_teacher$data = StudentSelector_teacher;
export type StudentSelector_teacher$key = {
    readonly " $data"?: StudentSelector_teacher$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSelector_teacher">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "studentSearch"
        ]
      }
    ]
  },
  "name": "StudentSelector_teacher",
  "selections": [
    {
      "alias": "studentSearch",
      "args": [
        {
          "kind": "Variable",
          "name": "searchQuery",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "StudentConnection",
      "kind": "LinkedField",
      "name": "__StudentSelectorFragment_studentSearch_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Student",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Teacher",
  "abstractKey": null
};
})();
(node as any).hash = '4f6998cfdc0707866225bb3f02d8b4f0';
export default node;
