export default function convertElementStyleToStyleObject(
  style: CSSStyleDeclaration,
): object {
  return Object.fromEntries(
    Array.from(style).map(propertyName => [
      camelize(propertyName),
      style.getPropertyValue(propertyName),
    ]),
  );
}

function camelize(string: string) {
  return string.replace(/-(.)/g, (_, char) => char.toUpperCase());
}
