import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <g clipPath="url(#clip0)">
      <path d="M14.443 19.344a1.678 1.678 0 0 0 1.687-.16c.491-.353.75-.943.674-1.54l-.609-4.821 3.354-3.547a1.638 1.638 0 0 0 .37-1.639 1.662 1.662 0 0 0-1.271-1.111l-4.82-.916-2.369-4.256A1.67 1.67 0 0 0 10 .5a1.67 1.67 0 0 0-1.459.854L6.171 5.609l-4.819.917a1.662 1.662 0 0 0-1.27 1.111A1.64 1.64 0 0 0 .45 9.276l3.355 3.546-.61 4.823a1.643 1.643 0 0 0 .675 1.54 1.68 1.68 0 0 0 1.687.159L10 17.28l4.443 2.064z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </>
);
const Star = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Star.displayName = 'Star';

export default Star;
