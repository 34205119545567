/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SunflowerSubtopicDetailViewQueryVariables = {
    subtopicId: string;
};
export type SunflowerSubtopicDetailViewQueryResponse = {
    readonly subtopic: {
        readonly title: string;
        readonly rslJsx: {
            readonly transpiledJsx: string | null;
        };
        readonly solidifyLessonJsx: {
            readonly transpiledJsx: string | null;
        };
        readonly worksheetJsx: {
            readonly id: string | null;
            readonly transpiledJsx: string | null;
        };
        readonly hasExercise: boolean;
        readonly hasWorksheet: boolean;
        readonly hasLessons: boolean;
        readonly hasInvestigations: boolean;
        readonly " $fragmentRefs": FragmentRefs<"SunflowerSubtopicDetailHeaderSubtopic" | "jsxPrintKebabLinks_subtopic">;
    } | null;
};
export type SunflowerSubtopicDetailViewQuery = {
    readonly response: SunflowerSubtopicDetailViewQueryResponse;
    readonly variables: SunflowerSubtopicDetailViewQueryVariables;
};



/*
query SunflowerSubtopicDetailViewQuery(
  $subtopicId: ID!
) {
  subtopic(id: $subtopicId) {
    ...SunflowerSubtopicDetailHeaderSubtopic
    ...jsxPrintKebabLinks_subtopic
    title
    rslJsx {
      transpiledJsx
      id
    }
    solidifyLessonJsx {
      transpiledJsx
      id
    }
    worksheetJsx {
      id
      transpiledJsx
    }
    hasExercise
    hasWorksheet
    hasLessons
    hasInvestigations
    id
  }
}

fragment LanguageSelector_availableLanguages on Syllabus {
  availableLocales
}

fragment SunflowerSubtopicDetailHeaderSubtopic on Subtopic {
  id
  hasAdaptivePractice
  pk
  title
  outcomes {
    id
    code
    description
  }
  gamifiedMastery
  topic {
    id
    pk
    title
    badgeUrl
    syllabus {
      id
      pk
      title
      coverImageUrl
      ...LanguageSelector_availableLanguages
    }
  }
}

fragment jsxPrintKebabLinks_subtopic on Subtopic {
  id
  answerKeyJsx {
    pdfAvailable
    id
  }
  worksheetJsx {
    pdfAvailable
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subtopicId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "subtopicId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledJsx",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasExercise",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasWorksheet",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLessons",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasInvestigations",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdfAvailable",
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SunflowerSubtopicDetailViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RetrofittedStudentLesson",
            "kind": "LinkedField",
            "name": "rslJsx",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SolidifyLesson",
            "kind": "LinkedField",
            "name": "solidifyLessonJsx",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Worksheet",
            "kind": "LinkedField",
            "name": "worksheetJsx",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SunflowerSubtopicDetailHeaderSubtopic"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "jsxPrintKebabLinks_subtopic"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SunflowerSubtopicDetailViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subtopic",
        "kind": "LinkedField",
        "name": "subtopic",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAdaptivePractice",
            "storageKey": null
          },
          (v10/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Outcome",
            "kind": "LinkedField",
            "name": "outcomes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gamifiedMastery",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Topic",
            "kind": "LinkedField",
            "name": "topic",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v10/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "badgeUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Syllabus",
                "kind": "LinkedField",
                "name": "syllabus",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coverImageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableLocales",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AnswerKey",
            "kind": "LinkedField",
            "name": "answerKeyJsx",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Worksheet",
            "kind": "LinkedField",
            "name": "worksheetJsx",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RetrofittedStudentLesson",
            "kind": "LinkedField",
            "name": "rslJsx",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SolidifyLesson",
            "kind": "LinkedField",
            "name": "solidifyLessonJsx",
            "plural": false,
            "selections": (v12/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f4490d3b7a0fa69a30f0e9642a63056c",
    "id": null,
    "metadata": {},
    "name": "SunflowerSubtopicDetailViewQuery",
    "operationKind": "query",
    "text": "query SunflowerSubtopicDetailViewQuery(\n  $subtopicId: ID!\n) {\n  subtopic(id: $subtopicId) {\n    ...SunflowerSubtopicDetailHeaderSubtopic\n    ...jsxPrintKebabLinks_subtopic\n    title\n    rslJsx {\n      transpiledJsx\n      id\n    }\n    solidifyLessonJsx {\n      transpiledJsx\n      id\n    }\n    worksheetJsx {\n      id\n      transpiledJsx\n    }\n    hasExercise\n    hasWorksheet\n    hasLessons\n    hasInvestigations\n    id\n  }\n}\n\nfragment LanguageSelector_availableLanguages on Syllabus {\n  availableLocales\n}\n\nfragment SunflowerSubtopicDetailHeaderSubtopic on Subtopic {\n  id\n  hasAdaptivePractice\n  pk\n  title\n  outcomes {\n    id\n    code\n    description\n  }\n  gamifiedMastery\n  topic {\n    id\n    pk\n    title\n    badgeUrl\n    syllabus {\n      id\n      pk\n      title\n      coverImageUrl\n      ...LanguageSelector_availableLanguages\n    }\n  }\n}\n\nfragment jsxPrintKebabLinks_subtopic on Subtopic {\n  id\n  answerKeyJsx {\n    pdfAvailable\n    id\n  }\n  worksheetJsx {\n    pdfAvailable\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6f10c92c4833a055d402dd215932ecf2';
export default node;
