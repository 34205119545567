/*
 * State model here would have to be able to accept a list of subtopics and a seed state.
 * It will map over the seed state and instantiate multiple "adaptive task states"
 * with their own getters and setters
 *
 * The use case must cover multiple instantiation. If a user goes back and adds or removes
 * a subtopic or makes changes to the bulk form, it should propagate changes selectively
 * (not overrride user input) if possible
 *
 * State system creates a dictionary with shape {[string]: combinedState}
 * Each mapped transform will only modify it's slice of the object.
 * */
import { useCallback } from 'react';

import type { CombinedState } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import { transformUpdaters } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import type { State } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/state';
import { useMultiState } from 'ms-utils/hooks/useMultiState';

export type BulkAdaptiveTaskStateFactory = (
  subtopicId: string,
  // initial state value
  initialState: Partial<State>,
) => CombinedState;

// this takes a seed state and emits a helper to create combined states
export default function useCreateBulkAdaptiveTaskState(
  seedState: State,
): [(key: string) => State, BulkAdaptiveTaskStateFactory, () => void] {
  // eslint-disable-next-line
  const [getState, createSetState, clearState] = useMultiState<State>();

  // [readSubtopicState, createSubtopicState]
  return [
    key => ({ ...seedState, ...(getState(key) || {}) }),
    useCallback(
      (subtopicId: string, initialState: Partial<State>) => ({
        values: {
          // internal state will overwrite seedState as required
          ...seedState,
          ...getState(subtopicId),
        },
        updaters: transformUpdaters(
          // @ts-expect-error
          // useMultiState and transformUpdaters are incompatible. The linchpin of
          // the mistake in the initial implementation seems to be that a substate
          // within the multistate object can be initialized to be the empty object.
          // This is done both when no defaultState is provided, but also by callsites
          // providing an empty object for the defaultState. This necessitates that
          // substates within a multistate object are Partial<T> (where T is the
          // type argument to useMultiState) since {} is not assignable to T. But
          // transformUpdaters expects T, not Partial<T>. Hence these things are
          // incompatible.
          createSetState(subtopicId, initialState),
        ),
      }),
      [createSetState, getState, seedState],
    ),
    clearState,
  ];
}
