import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const NewFolder2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path d="M8.081 2.834c.174.25.46.399.764.399h5.108a2.05 2.05 0 0 1 1.448.599c.383.384.599.905.599 1.447v4.06a2.638 2.638 0 0 0-1.172-.272h-.395v-.395c0-1.5-1.236-2.672-2.683-2.672-1.447 0-2.683 1.172-2.683 2.672v.395h-.395C7.172 9.067 6 10.303 6 11.75c0 1.447 1.172 2.683 2.672 2.683h.395v.395c0 .074.003.147.008.219H2.047a2.05 2.05 0 0 1-1.448-.6A2.05 2.05 0 0 1 0 13V3.047C0 1.917.916 1 2.047 1h3.688c.669 0 1.296.327 1.679.877l.667.957Zm6.63 12.067a2.05 2.05 0 0 0 1.16-1.186c-.364.383-.761.788-1.16 1.186Z" />
    <path d="M14.923 10.82h-2.144V8.678a.97.97 0 0 0-1.938 0v2.144H8.698a.97.97 0 0 0 0 1.938h2.143v2.143a.97.97 0 0 0 1.938 0V12.76h2.144a.97.97 0 0 0 0-1.938Z" />
  </Icon>
);
NewFolder2.displayName = 'NewFolder2';

export default NewFolder2;
