import { commitMutation } from 'relay-runtime';
import type {
  Environment,
  GraphQLTaggedNode,
  MutationParameters,
} from 'relay-runtime';
import 'react-relay';

import { publicEnvironment } from 'ms-utils/relay/index';

export function promiseMutation<MutationType extends MutationParameters>({
  mutation,
  variables,
  environment = publicEnvironment,
}: {
  mutation: GraphQLTaggedNode; // tagged template literal
  variables: MutationType['variables'];
  environment?: Environment;
}): Promise<MutationType['response']> {
  return new Promise<MutationType['response']>((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted(response, errors) {
        if (errors != null) return reject(errors);
        return resolve(response);
      },
      onError(e) {
        reject(e);
      },
    });
  });
}
