import { useId } from 'react';

export function FirecrackerSvg({ style }: { style?: React.CSSProperties }) {
  const maskId = useId();
  return (
    <svg
      width="13"
      height="20"
      viewBox="0 0 13 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.357 13.733s.51.364.62 1.142c.108.778-.467 1.71-1.452 1.873-.985.163-2.727-.654-3.017-2.53-.29-1.876-.29-3.12-2.12-3.583"
        stroke="#BDC3C7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12.16 19.98-.057.011a.52.52 0 0 1-.606-.416l-1.167-6.087 1.08-.2 1.166 6.086a.52.52 0 0 1-.417.607Z"
        fill="#B19046"
      />
      <path
        d="M7.322 2.488 5.23 5.36l.306 1.646.319 1.725.266 1.427-.001.002.32 1.72.282 1.516.116.624a.52.52 0 0 0 .607.418l1.098-.204 3.273-.609a.52.52 0 0 0 .417-.606l-.16-.865-.32-1.722-.282-1.514-.319-1.725-.265-1.428-.262-1.407-3.303-1.87Z"
        fill="#F0C419"
      />
      <mask
        id={maskId}
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="2"
        width="8"
        height="13"
      >
        <path
          d="M7.322 2.488 5.23 5.36l.306 1.646.319 1.725.266 1.427-.001.002.32 1.72.282 1.516.116.624a.52.52 0 0 0 .607.418l1.098-.204 3.273-.609a.52.52 0 0 0 .417-.606l-.16-.865-.32-1.722-.282-1.514-.319-1.725-.265-1.428-.262-1.407-3.303-1.87Z"
          fill="#fff"
        />
      </mask>
      <g mask={`url(#${maskId})`} fill="#FF5364">
        <path d="m11.754 10.432.32 1.722-3.53 2.08-1.1.204a.52.52 0 0 1-.606-.418l-.116-.624 5.032-2.964ZM11.152 7.194l.319 1.724-5.03 2.962-.322-1.72.002-.002 5.031-2.964ZM10.624 4.359l.263 1.406L5.855 8.73l-.32-1.725 4.22-2.484.87-.163Z" />
      </g>
      <path
        d="M11.523 3.735 7.25.063a.274.274 0 0 0-.414.077L4.167 5.1a.274.274 0 0 0 .29.404l6.941-1.288a.274.274 0 0 0 .125-.481Z"
        fill="#C03A2B"
      />
      <path
        d="M.744 11.3a.26.26 0 0 0-.181.32l.07.25-.251.07a.26.26 0 0 0 .139.502l.25-.07.07.25a.26.26 0 1 0 .502-.139l-.07-.25.25-.07a.26.26 0 0 0-.138-.501l-.251.07-.07-.251a.26.26 0 0 0-.32-.181ZM2.154 9.619a.26.26 0 0 0-.296-.379l-.251.07-.07-.251a.26.26 0 0 0-.501.14l.07.25-.251.07a.26.26 0 1 0 .139.5l.25-.07.07.252a.26.26 0 1 0 .502-.14l-.07-.25.25-.07a.26.26 0 0 0 .158-.122ZM4.587 9.85l-.07-.25a.26.26 0 0 0-.5.14l.07.25-.252.07a.26.26 0 0 0 .14.5l.25-.069.07.251a.26.26 0 1 0 .501-.14l-.07-.25.251-.07a.26.26 0 1 0-.139-.5l-.25.069Z"
        fill="#F0C419"
      />
    </svg>
  );
}
