import { useMemo, useContext } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import { StateContext } from 'ms-pages/Teacher/state';
import { unwrap } from 'ms-utils/typescript-utils';

import type { useProgressQuery } from './__generated__/useProgressQuery.graphql';
import type {
  Grades,
  Outcomes,
  Progress,
  ProgressByGradeLevelAndStrand,
  Strands,
} from './types';

type GradeLevelId = string;
function groupProgressByGradeLevelAndStrand(
  proficiencyForNodes: Record<string, number | null>,
  strands: Strands,
  outcomes: Outcomes,
  grades: Grades,
): ProgressByGradeLevelAndStrand {
  let currentProgress: Record<GradeLevelId, Progress> = {};
  // Prefill currentProgress with required structure
  grades.forEach(gl => {
    currentProgress = {
      ...currentProgress,
      [gl.id]: {
        gradeLevelId: gl.id,
        gradeLevelTitle: gl.title,
        gradeStrands: [],
      },
    };
  });
  // Obtain all outcome proficiencies
  const outcomeResults = outcomes.map(o => {
    // The original code was built off the assumption that this access
    // pattern always produces a numeric value for true_proficiency.
    // The types don't lead me to believe this was ever a correct assumption.
    // The sana UserState type clearly states that true_proficiency can be null.
    const proficiency = proficiencyForNodes[o.id];
    // TODO: Add a manual Sentry error if this coercion is not safe to make.
    // If after a few weeks the error hasn't been hit, swap this out for
    // a TS assertion that proficiency is of type number.
    const unsafelyCoercedProficiency = proficiency!;
    return {
      id: o.id,
      code: o.code,
      description: o.skill.description,
      proficiency: unsafelyCoercedProficiency,
      gradeStrandId: o.gradeStrand.id,
    };
  });
  // For each gradeStrand add the outcome proficiencies to currentProgress
  strands.forEach(strand => {
    return strand.gradeStrands.forEach(gs => {
      const filteredOutcomeResults = outcomeResults
        .filter(o => o.gradeStrandId === gs.id)
        .sort((a, b) => (a.code > b.code ? 1 : -1));
      currentProgress = {
        ...currentProgress,
        [gs.grade.id]: {
          ...unwrap(currentProgress[gs.grade.id]),
          gradeStrands: [
            ...unwrap(currentProgress[gs.grade.id]).gradeStrands,
            {
              strandId: strand.id,
              strandTitle: strand.title,
              outcomes: filteredOutcomeResults,
            },
          ],
        },
      };
    });
  });
  return grades.map(gl => unwrap(currentProgress[gl.id]));
}
export function useProgress({
  studentId,
  strands,
  outcomes,
  grades,
  fetchKey,
}: {
  studentId: string;
  strands: Strands;
  outcomes: Outcomes;
  grades: Grades;
  fetchKey: number;
}) {
  const { growthPeriod } = useContext(StateContext);
  const [
    { canPreviewProblemEventDataInSkills, hasPreviewProblemEventDataInSkills },
  ] = useFeatureFlagsV2();
  const data = useLazyLoadQuery<useProgressQuery>(
    graphql`
      query useProgressQuery(
        $studentId: ID!
        $filters: [UserStatusFilterInput!]!
        $previewingWithProblemData: Boolean!
        $growthPeriod: Int!
      ) {
        lantern {
          student(id: $studentId) {
            userStatuses(
              filters: $filters
              previewingWithProblemData: $previewingWithProblemData
              growthPeriod: $growthPeriod
            ) {
              trueProficiency
              userStatusFilter {
                curriculumNodeIds
              }
            }
          }
        }
      }
    `,
    {
      studentId,
      filters: outcomes.map(({ id }) => ({ curriculumNodeIds: [id] })),
      previewingWithProblemData:
        canPreviewProblemEventDataInSkills &&
        hasPreviewProblemEventDataInSkills,
      growthPeriod,
    },
    { fetchKey },
  );
  const proficiencyForNodes = useMemo(() => {
    const userStatuses = data.lantern.student?.userStatuses;
    if (userStatuses == null) {
      throw new Error('StudentOutcomesRowMainQuery: userStatuses is null');
    }
    return userStatuses.reduce<Record<string, number | null>>(
      (acc, { trueProficiency, userStatusFilter: { curriculumNodeIds } }) => {
        if (curriculumNodeIds.length !== 1) {
          throw new Error(
            `StudentOutcomesRowMainQuery: expected exactly one curriculumNodeIds, got ${curriculumNodeIds.length}`,
          );
        }
        const id = unwrap(curriculumNodeIds[0]);
        return { ...acc, [id]: trueProficiency };
      },
      {},
    );
  }, [data]);
  const progress =
    proficiencyForNodes === null
      ? []
      : groupProgressByGradeLevelAndStrand(
          proficiencyForNodes,
          strands,
          outcomes,
          grades,
        );
  return { progress };
}
