import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';

import { Anchor } from 'ms-ui-primitives/Link';
import { loginUrl } from 'ms-utils/urls';

type Props = {
  children: ReactNode;
  aphroditeStyles?: CSSInputTypes[];
  trackingId?: string;
};

function LoginLink({ children, aphroditeStyles = [], trackingId }: Props) {
  return (
    <Anchor
      href={loginUrl}
      aphroditeStyles={aphroditeStyles}
      data-tracking-id={trackingId}
    >
      {children}
    </Anchor>
  );
}

export { LoginLink };
