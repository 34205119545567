import { StyleSheet, css } from 'aphrodite';
import { useRef } from 'react';

import LatexInput from 'ms-components/LatexInput';

type Latex = string;

export function LatexProbabilityInput(props: {
  id: number;
  onChange: (id: number, value: Latex) => void;
  value: Latex;
  x: number;
  y: number;
  disabled: boolean;
}) {
  const inputRef = useRef<LatexInput>(null);

  return (
    <span
      data-testid="probability-tree-latex-probability-input"
      className={css(styles.inputWrapper)}
      style={{ transform: `translate(${props.x}px, ${props.y}px)` }}
    >
      <span className={css(styles.input)}>
        <LatexInput
          ref={inputRef}
          value={props.value}
          disabled={props.disabled}
          onChange={(latex: Latex) => {
            props.onChange(props.id, latex);
          }}
        />
      </span>
    </span>
  );
}

const styles = StyleSheet.create({
  inputWrapper: {
    position: 'absolute',
    // Need to be set explicitly for Safari 10
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  input: {
    fontSize: 12,
    display: 'inline-block',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'auto',
  },
});
