import locked from './assets/locked.svg';
import lockedWithoutPadlock from './assets/lockedWithoutPadlock.svg';
import { Image } from './styles';

function SkillProficiencyIndicatorLocked({
  hidePadlock,
  width,
}: {
  hidePadlock?: true | undefined;
  width?: number | undefined;
}) {
  const _hidePadlock = hidePadlock ?? false;
  return (
    <Image
      src={_hidePadlock ? lockedWithoutPadlock : locked}
      alt="This skill is locked"
      width={width}
    />
  );
}

export { SkillProficiencyIndicatorLocked };
