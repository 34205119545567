import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path d="M1.4569 1.08395L7.86249 7.15462C7.95412 7.24135 8 7.34113 8 7.45401C8 7.56689 7.95412 7.66685 7.86249 7.75363L1.4569 13.8238C1.36513 13.9108 1.25984 13.9541 1.14068 13.9541C1.02156 13.9541 0.916122 13.9106 0.824548 13.8238L0.137455 13.1726C0.0458335 13.0858 4.67723e-05 12.9859 4.67772e-05 12.873C4.67821e-05 12.7601 0.0458335 12.6602 0.137456 12.5734L5.53954 7.45401L0.137119 2.33446C0.0454969 2.24768 -4.38718e-07 2.14771 -4.33802e-07 2.03502C-4.28871e-07 1.92196 0.0457857 1.82204 0.137119 1.73526L0.824501 1.084C0.916075 0.997219 1.02156 0.954101 1.14063 0.954101C1.25984 0.954056 1.36528 0.997172 1.4569 1.08395Z" />
    <path d="M7.4569 1.08395L13.8625 7.15462C13.9541 7.24135 14 7.34113 14 7.45401C14 7.56689 13.9541 7.66685 13.8625 7.75363L7.4569 13.8238C7.36513 13.9108 7.25984 13.9541 7.14068 13.9541C7.02156 13.9541 6.91612 13.9106 6.82455 13.8238L6.13746 13.1726C6.04583 13.0858 6.00005 12.9859 6.00005 12.873C6.00005 12.7601 6.04583 12.6602 6.13746 12.5734L11.5395 7.45401L6.13712 2.33446C6.0455 2.24768 6 2.14771 6 2.03502C6 1.92196 6.04579 1.82204 6.13712 1.73526L6.8245 1.084C6.91607 0.997218 7.02156 0.954101 7.14063 0.954101C7.25984 0.954056 7.36528 0.997172 7.4569 1.08395Z" />
  </>
);

const DoubleRightChevron = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={14}
    viewBoxHeight={14}
  >
    {path}
  </Icon>
);

DoubleRightChevron.displayName = 'DoubleRightChevron';

export default DoubleRightChevron;
