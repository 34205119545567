import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FlyoutToggle = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={24}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447716 2 0 1.55228 0 1ZM10 15C10 14.4477 10.4477 14 11 14H23C23.5523 14 24 14.4477 24 15C24 15.5523 23.5523 16 23 16H11C10.4477 16 10 15.5523 10 15ZM1 7C0.447715 7 0 7.44772 0 8C0 8.55229 0.447716 9 1 9H23C23.5523 9 24 8.55229 24 8C24 7.44772 23.5523 7 23 7H1Z"
    />
  </Icon>
);

FlyoutToggle.displayName = 'FlyoutToggle';

export default FlyoutToggle;
