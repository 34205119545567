import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M9.0099668,14 C8.73975502,14 8.50387698,13.9119295 8.30232558,13.735786 C8.10077419,13.5596424 8,13.313268 8,12.9966555 C8,12.7157177 8.09745196,12.4793767 8.2923588,12.2876254 C8.48726565,12.0958742 8.72646591,12 9.0099668,12 C9.2890379,12 9.5238086,12.0958742 9.7142857,12.2876254 C9.9047629,12.4793767 10,12.7157177 10,12.9966555 C10,13.3088087 9.9003332,13.5540682 9.7009967,13.7324415 C9.5016601,13.9108147 9.2713191,14 9.0099668,14 Z M8.28836155,10.0204266 L8.06329888,6.65151966 C8.02109941,5.99508357 8,5.52386331 8,5.23784472 C8,4.8486719 8.10198051,4.54507476 8.30594458,4.3270442 C8.50990865,4.10901364 8.77834009,4 9.111247,4 C9.5144863,4 9.7840899,4.13949058 9.920066,4.41847591 C10.056042,4.69746125 10.124029,5.09952233 10.124029,5.6246712 C10.124029,5.93413393 10.1076184,6.24828077 10.0747966,6.56712116 L9.7723686,10.034493 C9.7395468,10.44711 9.6692154,10.7636012 9.5613723,10.9839762 C9.4535292,11.2043512 9.2753564,11.314537 9.0268485,11.314537 C8.77365169,11.314537 8.59782323,11.2078677 8.49935781,10.994526 C8.4008924,10.7811843 8.33056102,10.4564877 8.28836155,10.0204266 Z M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z M9,16 C12.8659932,16 16,12.8659932 16,9 C16,5.13400675 12.8659932,2 9,2 C5.13400675,2 2,5.13400675 2,9 C2,12.8659932 5.13400675,16 9,16 Z"
    transform="translate(1.000000, 1.000000)"
  />
);

const ExclamationMarkOutlined = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ExclamationMarkOutlined.displayName = 'ExclamationMarkOutlined';

export default ExclamationMarkOutlined;
