import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import { useStudentContext } from 'ms-pages/Lantern/views/Student/StudentContext';
import { getGradeSubstrands } from 'ms-pages/Lantern/views/Student/StudentSkillsMap/utils';

import type { useSubstrandProficiencyGradeStrandQuery } from './__generated__/useSubstrandProficiencyGradeStrandQuery.graphql';
import type { useSubstrandProficiencyUserStatusQuery } from './__generated__/useSubstrandProficiencyUserStatusQuery.graphql';
/**
 * Will take a substrand and attempt to provide a proficiency level for it.
 * The query path for this is a cascade of two queries. while both queries are in flight,
 * the returned value will be null.
 *
 * Expected usage:
 * <ProficiencuProvider substrandId={substrandId} >
 *  {
 *      proficiency => <ProficiencyIndicator currentProficiency={proficiency} />
 *  }
 *  </ProficiencyProvider>
 *
 *  This is expected to be used within the student context.
 */
type MaybeProficiency = number | null;
export function useSubstrandProficiency(
  substrandId: string,
  pastProficiencyTimestamp?: string,
): {
  proficiency: MaybeProficiency;
  pastProficiency: MaybeProficiency;
} {
  const { props: gradeStrandProps } =
    useQuery<useSubstrandProficiencyGradeStrandQuery>(
      graphql`
        query useSubstrandProficiencyGradeStrandQuery($substrandId: ID!) {
          lantern {
            substrand(id: $substrandId) {
              gradeSubstrands {
                id
                gradeStrand {
                  id
                  grade {
                    order
                  }
                }
              }
            }
          }
        }
      `,
      {
        substrandId,
      },
    );
  const { selfReportedGradeOrder } = useStudentContext();
  const gradeStrands = getGradeSubstrands({
    substrand: gradeStrandProps?.lantern?.substrand ?? { gradeSubstrands: [] },
    grade: {
      order: selfReportedGradeOrder,
    },
  });
  const { props: proficiencyProps } =
    useQuery<useSubstrandProficiencyUserStatusQuery>(
      graphql`
        query useSubstrandProficiencyUserStatusQuery(
          $filters: [UserStatusFilterInput!]!
          $timestamp: DateTime
          $fetchPastProficiency: Boolean!
          $previewingWithProblemData: Boolean!
          $growthPeriod: Int!
        ) {
          lantern {
            viewer {
              ... on LanternStudent {
                userStatuses(
                  filters: $filters
                  previewingWithProblemData: $previewingWithProblemData
                  growthPeriod: $growthPeriod
                ) {
                  trueProficiency
                }
                pastProficiency: userStatuses(
                  filters: $filters
                  timestamp: $timestamp
                  previewingWithProblemData: $previewingWithProblemData
                  growthPeriod: $growthPeriod
                ) @include(if: $fetchPastProficiency) {
                  trueProficiency
                }
              }
            }
          }
        }
      `,
      {
        filters: [
          {
            curriculumNodeIds: gradeStrands?.map(g => g.id) ?? [],
          },
        ],
        fetchPastProficiency: Boolean(pastProficiencyTimestamp),
        timestamp: pastProficiencyTimestamp,
        previewingWithProblemData: false,
        growthPeriod: 120,
      },
      { skip: gradeStrandProps == null },
    );
  const proficiency =
    proficiencyProps?.lantern?.viewer?.userStatuses?.[0]?.trueProficiency ??
    null;
  const pastProficiency =
    proficiencyProps?.lantern?.viewer?.pastProficiency?.[0]?.trueProficiency ??
    null;
  return { proficiency, pastProficiency };
}
