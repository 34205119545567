import EmptyIllustration from 'ms-components/illustrations/Empty';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import FreeTextbooksCTANavbar from 'ms-pages/FreeTextbooks/FreeTextbooksCTANavbar';
import { DesktopOnly } from 'ms-pages/FreeTextbooks/Layout/Layout';
import { fontFamily, fontWeight, lineHeight, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

type Props = {
  title: string;
  actionDescription?: string | undefined;
  backgroundColor?: string | undefined;
};

const EmptyMessage = styled({
  margin: 'auto',
  color: colors.grey,
  fontFamily: fontFamily.body,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

const EmptyMessageTitle = styled({
  fontWeight: fontWeight.semibold,
  fontSize: 5 * BASE_UNIT,
  marginBottom: 2 * BASE_UNIT,
  marginTop: 10 * BASE_UNIT,
  textAlign: 'center',
  lineHeight: lineHeight.title,
});

const EmptyMessageDescription = styled({
  textAlign: 'center',
  fontSize: fontSize.small,
  lineHeight: lineHeight.body,
});

const Strong = styled({
  display: 'inline',
  fontWeight: fontWeight.semibold,
});

export default function DetailEmptyView({
  title,
  actionDescription,
  backgroundColor = colors.porcelain,
}: Props) {
  let viewer = useMaybeViewer();

  return (
    <>
      {viewer == null && (
        <DesktopOnly>
          <div style={{ display: 'flex', margin: 24 }}>
            <div
              style={{
                display: 'flex',
                marginLeft: 'auto',
                whiteSpace: 'nowrap',
              }}
            >
              <FreeTextbooksCTANavbar context="subtopic_detail" />
            </div>
          </div>
        </DesktopOnly>
      )}
      <EmptyMessage style={{ backgroundColor }}>
        <EmptyIllustration />
        <EmptyMessageTitle>{title}</EmptyMessageTitle>
        {actionDescription != null && (
          <EmptyMessageDescription>
            You will be able to <Strong>{actionDescription}</Strong>
          </EmptyMessageDescription>
        )}
      </EmptyMessage>
    </>
  );
}
