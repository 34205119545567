/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type OutcomeSubtopicRecommendationCard_subtopic = {
    readonly id: string;
    readonly title: string;
    readonly topic: {
        readonly id: string;
        readonly badgeUrl: string;
        readonly badgeBackground: string | null;
        readonly syllabus: {
            readonly id: string;
        };
    };
    readonly hasAdaptivePractice: boolean;
    readonly gamifiedMastery: number;
    readonly " $refType": "OutcomeSubtopicRecommendationCard_subtopic";
};
export type OutcomeSubtopicRecommendationCard_subtopic$data = OutcomeSubtopicRecommendationCard_subtopic;
export type OutcomeSubtopicRecommendationCard_subtopic$key = {
    readonly " $data"?: OutcomeSubtopicRecommendationCard_subtopic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"OutcomeSubtopicRecommendationCard_subtopic">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OutcomeSubtopicRecommendationCard_subtopic",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badgeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badgeBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Syllabus",
          "kind": "LinkedField",
          "name": "syllabus",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAdaptivePractice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamifiedMastery",
      "storageKey": null
    }
  ],
  "type": "Subtopic",
  "abstractKey": null
};
})();
(node as any).hash = '102630701db47d8fdab4f5c400ed43b9';
export default node;
