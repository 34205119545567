/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserStatusFilterInput = {
    curriculumNodeIds: Array<string>;
};
export type StudentSkillsMapSubstrandProficiencyIndicatorQueryVariables = {
    filters: Array<UserStatusFilterInput>;
    timestamp?: string | null | undefined;
    isLocked: boolean;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type StudentSkillsMapSubstrandProficiencyIndicatorQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly userStatuses?: ReadonlyArray<{
                readonly trueProficiency: number | null;
            }> | null | undefined;
            readonly previousStatuses?: ReadonlyArray<{
                readonly trueProficiency: number | null;
            }> | null | undefined;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type StudentSkillsMapSubstrandProficiencyIndicatorQuery = {
    readonly response: StudentSkillsMapSubstrandProficiencyIndicatorQueryResponse;
    readonly variables: StudentSkillsMapSubstrandProficiencyIndicatorQueryVariables;
};



/*
query StudentSkillsMapSubstrandProficiencyIndicatorQuery(
  $filters: [UserStatusFilterInput!]!
  $timestamp: DateTime
  $isLocked: Boolean!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) @skip(if: $isLocked) {
          trueProficiency
        }
        previousStatuses: userStatuses(filters: $filters, timestamp: $timestamp, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) @skip(if: $isLocked) {
          trueProficiency
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLocked"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timestamp"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v7 = {
  "kind": "Variable",
  "name": "growthPeriod",
  "variableName": "growthPeriod"
},
v8 = {
  "kind": "Variable",
  "name": "previewingWithProblemData",
  "variableName": "previewingWithProblemData"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "trueProficiency",
    "storageKey": null
  }
],
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "condition": "isLocked",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "concreteType": "UserStatus",
          "kind": "LinkedField",
          "name": "userStatuses",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": "previousStatuses",
          "args": [
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "kind": "Variable",
              "name": "timestamp",
              "variableName": "timestamp"
            }
          ],
          "concreteType": "UserStatus",
          "kind": "LinkedField",
          "name": "userStatuses",
          "plural": true,
          "selections": (v9/*: any*/),
          "storageKey": null
        }
      ]
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentSkillsMapSubstrandProficiencyIndicatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentSkillsMapSubstrandProficiencyIndicatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4dc5e165484768a8a98adfc2994c8369",
    "id": null,
    "metadata": {},
    "name": "StudentSkillsMapSubstrandProficiencyIndicatorQuery",
    "operationKind": "query",
    "text": "query StudentSkillsMapSubstrandProficiencyIndicatorQuery(\n  $filters: [UserStatusFilterInput!]!\n  $timestamp: DateTime\n  $isLocked: Boolean!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) @skip(if: $isLocked) {\n          trueProficiency\n        }\n        previousStatuses: userStatuses(filters: $filters, timestamp: $timestamp, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) @skip(if: $isLocked) {\n          trueProficiency\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '246001bd17cc5fb6bc5e1b9025b56e69';
export default node;
