import { Route, Switch } from 'react-router-dom';

import PageNotFoundView from 'ms-components/PageNotFoundView';
import JoinClass from 'ms-pages/Signup/Sunflower/JoinClass';
import { SignupStateProvider } from 'ms-pages/Signup/Sunflower/state';
import {
  signupSunflowerJoinClassUrl,
  signupSunflowerParentPath,
  signupSunflowerPath,
  signupSunflowerStudentPath,
  signupSunflowerTeacherPath,
} from 'ms-utils/urls';

import { Layout } from './Layout';
import { Signup } from './Signup';

type Props = {
  config: { location: string };
};

function Sunflower({ config }: Props) {
  // Maybe we need to pull state management up to here, if we want it to be shared
  // between JoinClass and Signup.
  //
  // These are two separate flows that gather some data and then ultimately call one
  // of two mutations, depending on the flow.  The mutation creates a new user (and it's
  // implicit that a Lantern profile is created for that user, in this context.)
  //
  // ie. SignupStateProvider, which is currenly in `Signup`.
  // This also mediates access to the mutations.
  return (
    <Layout>
      <SignupStateProvider location={config.location}>
        <Switch>
          <Route
            path={signupSunflowerJoinClassUrl}
            component={() => <JoinClass />}
          />
          <Route
            path={[
              // The order of these are critical. Sunflower base path must be last.
              // These are used to direct linking and reload safety.
              // If a user is in either of the first two paths, a refresh must keep them there
              // the third path must always redirect to the selector.
              signupSunflowerStudentPath,
              signupSunflowerTeacherPath,
              signupSunflowerParentPath,
              signupSunflowerPath,
            ]}
            component={() => <Signup />}
          />
          <Route component={PageNotFoundView} />
        </Switch>
      </SignupStateProvider>
    </Layout>
  );
}

export default Sunflower;
