import type { ReactNode } from 'react';

import CheckMarkIcon from 'ms-components/icons/CheckMark';
import CrossIcon from 'ms-components/icons/Cross';
import { colors } from 'ms-styles/colors';

import {
  Close,
  Header,
  ItemInner,
  ItemText,
  ListItem,
  Root,
  Screen,
  SelectionBar,
  Title,
} from '../PresentationalComponents';

type Props = {
  title: string;
  items: ReactNode[];
  onSelect: (index: number) => void;
  selectedIndex: number | null | undefined;
  onClose: () => void;
  width: number;
};

const Flat = (props: Props) => (
  <Root>
    <Screen>
      <Header>
        <Title>{props.title}</Title>
        <Close onClick={props.onClose}>
          <CrossIcon color={colors.dustyGray} />
        </Close>
      </Header>
      {props.items.map((item, index) => (
        <ListItem
          key={index}
          role="button"
          tabIndex={0}
          onClick={() => props.onSelect(index)}
        >
          <SelectionBar
            style={{
              display: index === props.selectedIndex ? 'block' : 'none',
            }}
          />
          <ItemInner>
            <ItemText>{item}</ItemText>
            {index === props.selectedIndex && (
              <CheckMarkIcon color={colors.matisse} />
            )}
          </ItemInner>
        </ListItem>
      ))}
    </Screen>
  </Root>
);

export default Flat;
