import { formatPathname } from './utils';

declare global {
  interface Window {
    ga?: (...args: any[]) => void;
  }
}

type AnalyticsEvent = {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string | null | undefined;
  eventValue?: number | null | undefined;
};

type PageEvent = {
  // pathname only
  page: string;
  // page title
  title?: string | null | undefined;
};

// https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings
type UserTimingsFields = {
  timingCategory: string;
  timingVar: string;
  timingValue: number; // Must be an integer
  timingLabel?: string;
};

const PROMISE_TIMEOUT = 1000;

/**
 * A factory function which takes a "promise executor" function and returns a
 * new promise.  This promise behaves identically to a standard promise defined
 * with the same executor, **but will resolve early** if the executor fails to
 * resolve before the timeout.
 *
 * @param {*} executor — A callback function which defines the behaviour of
 * this promise.
 * @returns {*} A new promise
 */
const createPromiseWithTimeout = (
  executor: (resolve: () => void, reject: (e: any) => void) => void,
): Promise<void> =>
  new Promise((resolve, reject) => {
    let isPending = true;

    const resolveOnce = () => {
      if (isPending) {
        isPending = false;
        resolve();
      }
    };

    const rejectOnce = (e: any) => {
      if (isPending) {
        isPending = false;
        reject(e);
      }
    };

    window.setTimeout(resolveOnce, PROMISE_TIMEOUT);
    executor(resolveOnce, rejectOnce);
  });

/**
 * Sends an event to Google Analytics in the following format:
 * window.ga('send', 'event', 'CTA', 'clicked', 'https://mathspace.co/accounts/choose-plan#signup1');
 *
 * @param {*} eventLabel The Google Analytics event label
 * @param {*} eventAction Optional. The Google Analytics event action
 * @param {*} eventCategory Optional. The Google Analytics event category
 * @returns {*} A promise which resolve on both failure and success with undefined.
 */
export const sendCTAClickEvent = (
  eventLabel: string,
  eventAction: string = 'clicked',
  eventCategory: string = 'CTA',
): Promise<void> =>
  createPromiseWithTimeout(resolve => {
    const label = `${formatPathname(window.location.pathname)}#${eventLabel}`;

    const ga = window.ga;
    if (ga != null) {
      ga('send', 'event', {
        eventCategory,
        eventAction,
        eventLabel: label,
        hitCallback: () => {
          resolve();
        },
      });
    } else {
      resolve();
    }
  });

/**
 * Sends an arbitrary event to Google Analytics.
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/events}
 *
 * @param {*} eventObject The event object, passed directly to ga()
 * @returns {*} A promise which resolves on successful communication to Google
 * Analytics servers
 */
export const sendAnalyticsEvent = (
  eventObject: AnalyticsEvent,
): Promise<void> =>
  createPromiseWithTimeout(resolve => {
    if (window.ga) {
      window.ga('send', 'event', {
        ...eventObject,
        hitCallback: () => {
          resolve();
        },
      });
    }

    resolve();
  });

/**
 * Sends a pageview hit to Google Analytics.
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/pages}
 *
 * @param {*} pageObject The page descriptor, passed directly to ga()
 * @returns {*} A promise which resolves on successful communication to Google
 * Analytics servers
 */
export const sendPageView = (pageObject: PageEvent): Promise<void> =>
  createPromiseWithTimeout(resolve => {
    if (window.ga) {
      window.ga('send', 'pageview', {
        ...pageObject,
        hitCallback: () => {
          resolve();
        },
      });
    }
    resolve();
  });

/**
 * Sends a user timings hit to Google Analytics.
 *
 * @see {@link https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings}
 *
 * @param {*} fieldsObject The user timings fields delegated to ga sdk.
 * @returns {*} A promise which resolves on successful communication to Google
 * Analytics servers
 */
export const sendUserTimingHit = (
  fieldsObject: UserTimingsFields,
): Promise<void> =>
  createPromiseWithTimeout(resolve => {
    if (window.ga) {
      window.ga('send', 'timing', {
        ...fieldsObject,
        hitCallback: () => {
          resolve();
        },
      });
    }
    resolve();
  });
