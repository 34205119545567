export default function getVerticallyScrollableParent(
  node: Element | null | undefined,
): Element | null {
  if (node == null) {
    return null;
  }
  const isElement = node instanceof HTMLElement;
  const overflowY = isElement ? window.getComputedStyle(node).overflowY : '';
  const isScrollable = !(
    overflowY.includes('hidden') || overflowY.includes('visible')
  );

  if (isScrollable && node.scrollHeight >= node.clientHeight) {
    return node;
  }

  if (node.parentElement != null) {
    return getVerticallyScrollableParent(node.parentElement);
  } else {
    return document.scrollingElement || document.body;
  }
}
