/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UserStatusFilterInput = {
    curriculumNodeIds: Array<string>;
};
export type RecommendationSkillPopoverQueryVariables = {
    filters: Array<UserStatusFilterInput>;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type RecommendationSkillPopoverQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly userStatuses: ReadonlyArray<{
                readonly trueProficiency: number | null;
                readonly userStatusFilter: {
                    readonly curriculumNodeIds: ReadonlyArray<string>;
                };
            }> | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type RecommendationSkillPopoverQuery = {
    readonly response: RecommendationSkillPopoverQueryResponse;
    readonly variables: RecommendationSkillPopoverQueryVariables;
};



/*
query RecommendationSkillPopoverQuery(
  $filters: [UserStatusFilterInput!]!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
          trueProficiency
          userStatusFilter {
            curriculumNodeIds
          }
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "growthPeriod",
          "variableName": "growthPeriod"
        },
        {
          "kind": "Variable",
          "name": "previewingWithProblemData",
          "variableName": "previewingWithProblemData"
        }
      ],
      "concreteType": "UserStatus",
      "kind": "LinkedField",
      "name": "userStatuses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "trueProficiency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStatusFilter",
          "kind": "LinkedField",
          "name": "userStatusFilter",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "curriculumNodeIds",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecommendationSkillPopoverQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecommendationSkillPopoverQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d305c4f7a74365822851e55ed3839c41",
    "id": null,
    "metadata": {},
    "name": "RecommendationSkillPopoverQuery",
    "operationKind": "query",
    "text": "query RecommendationSkillPopoverQuery(\n  $filters: [UserStatusFilterInput!]!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n          trueProficiency\n          userStatusFilter {\n            curriculumNodeIds\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1965a57075bab7b060b79f985959b816';
export default node;
