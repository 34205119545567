import styled from '@emotion/styled';
import { createRef, useState, useEffect, useRef, useCallback } from 'react';

import { colors } from 'ms-styles/colors';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';

type Segment = 'first' | 'middle' | 'last' | null | undefined;

export type Props = {
  key?: number | undefined;
  height: number;
  width: number | string;
  progress: number; // percent in range [0, 100]
  color: string;
  backgroundColor?: string | undefined;
  borderColor?: string | undefined;
  borderWidth?: number | undefined;
  animated?: boolean | undefined;
  animatedOnMount?: boolean | undefined;
  animationTime?: number | null | undefined; // milliseconds
  animationSpeed?: number | undefined; // percents/second
  animationTiming?: string | undefined; // only allowed transition-timing-function values https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function

  // if it's part of segmented progress bar, to style the border radia
  segment?: Segment;
};

const nodeRef: { readonly current: HTMLInputElement | null } = createRef();

const calculateTranslation = ({
  progress,
  height,
  width: _width,
}: {
  progress: number;
  height: number;
  width: number | string;
}): string => {
  if (progress === 0) return '0';
  if (progress >= 100) return '100%';
  const nodeElement = nodeRef.current;
  const width =
    typeof _width === 'number'
      ? _width
      : nodeElement != null
      ? nodeElement.clientWidth
      : null;
  if (width != null && (progress / 100) * width < height) {
    // to display at least a circle if progress > 0
    // cannot do it if width is not express in pixels though
    return `${height}px`;
  }
  return `${progress}%`;
};

const SegmentedProgressBarContainer = styled.div({
  display: 'flex',
});

const getSegmentStyles = (segment: Segment, height: number) => {
  switch (segment) {
    case 'first':
      return {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: height / 2,
        borderBottomLeftRadius: height / 2,
      };
    case 'middle':
      return { borderRadius: 0 };
    case 'last':
      return {
        borderTopRightRadius: height / 2,
        borderBottomRightRadius: height / 2,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      };
    default:
      return {};
  }
};

const ProgressBarEl = styled.div({
  position: 'relative',
  overflow: 'hidden',
  // FIX FOR SAFARI: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
  WebkitMaskImage: '-webkit-radial-gradient(white, black)',
});

const CompletedProgressBarEl = styled.div({
  bottom: 0,
  left: '-100%',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
  willChange: 'transform',
  transform: 'translated3d(0, 0, 0)',
});

const progressBarRenderer = ({
  width,
  progress,
  color,
  height,
  backgroundColor = colors.seashell,
  borderColor,
  borderWidth = 1,
  animated = false,
  animationTime = 1000,
  animationTiming = 'ease-in',
  segment,
}: Props) => {
  if (typeof progress !== 'number' || progress < 0 || progress > 100) {
    Logger.error(
      new InvariantViolation(
        `ProgressBar component 'progress' prop should be a a number between '0' and '100' included. Received ${progress}`,
      ),
    );
  }

  return (
    <ProgressBarEl
      style={{
        width,
        height,
        borderRadius: height / 2,
        backgroundColor,
        ...(borderColor != null && {
          boxShadow: `${borderColor} 0 0 0 ${borderWidth}px inset`,
        }),
        ...getSegmentStyles(segment, height),
      }}
      ref={nodeRef}
    >
      <CompletedProgressBarEl
        style={{
          height,
          borderRadius: height / 2,
          transform: `translate3d(${calculateTranslation({
            progress,
            width,
            height,
          })}, 0, 0)`,
          visibility: progress === 0 ? 'hidden' : 'visible',
          background: color,
          ...(animated && {
            transitionProperty: 'transform',
            transitionDuration: `${animationTime}ms`,
            transitionTimingFunction: animationTiming,
          }),
          ...getSegmentStyles(segment, height),
        }}
      />
    </ProgressBarEl>
  );
};

function ProgressBar(props: Props) {
  const {
    width,
    progress: progressProp,
    animated,
    animatedOnMount,
    animationSpeed,
  } = props;

  const isFirstMount = useRef(true);

  const [progressState, setProgressState] = useState(
    (animated && animatedOnMount) || typeof width === 'string' // if width is not numeric, we want to re-render so that nodeRef.current will be defined in calculateTranslation
      ? 0
      : progressProp,
  );
  const [animationTimeState, setAnimationTimeState] = useState<number | null>(
    null,
  );

  const updateProgress = useCallback(
    (
      prevProgress: number,
      nextProgress: number,
      animationSpeed?: number | null | undefined,
    ) => {
      setProgressState(nextProgress);
      setAnimationTimeState(
        animationSpeed != null
          ? (Math.abs(prevProgress - nextProgress) / animationSpeed) * 1000
          : null,
      );
    },
    [],
  );

  useEffect(() => {
    if (!isFirstMount.current) return;
    const prevProgress = progressState;
    const nextProgress = progressProp;
    if (nextProgress !== prevProgress) {
      if (animated && animatedOnMount) {
        updateProgress(prevProgress, nextProgress, animationSpeed);
      }
      if (typeof width === 'string') {
        // if width is not numeric, we want to re-render so that nodeRef.current will be defined in calculateTranslation
        // NB this would work anyway if `animated === true` but we need to support the non animated case
        updateProgress(prevProgress, nextProgress);
      }
    }
    isFirstMount.current = false;
  }, [
    animated,
    animatedOnMount,
    animationSpeed,
    progressProp,
    progressState,
    updateProgress,
    width,
  ]);

  useEffect(() => {
    if (isFirstMount.current) return;
    if (progressProp !== progressState) {
      updateProgress(progressState, progressProp, animationSpeed);
    }
  }, [animationSpeed, progressProp, progressState, updateProgress]);

  return progressBarRenderer({
    ...props,
    progress: progressState,
    ...(animationTimeState != null
      ? { animationTime: animationTimeState }
      : null),
  });
}

export default ProgressBar;

type SegmentedProgressBarProps = {
  numberOfSegments: number;
  progress: number; // percent in range [0, 100],
  currentSegmentBorderWidth: number;
  segmentsGap?: number;

  // from down below, they are passed as is to ProgressBar
  height: number;
  width: number | string;
  color: string;
  backgroundColor?: string | undefined;
  borderColor?: string | undefined;
  borderWidth?: number | undefined;
  animated?: boolean | undefined;
  animatedOnMount?: boolean | undefined;
  animationTime?: number | undefined; // milliseconds
  animationSpeed?: number | undefined; // percents/second
  animationTiming?: string | undefined; // only allowed transition-timing-function values https://developer.mozilla.org/en-US/docs/Web/CSS/transition-timing-function
};

export function SegmentedProgressBar({
  numberOfSegments,
  progress,
  currentSegmentBorderWidth,
  segmentsGap = 8,
  height,
  width,
  color,
  backgroundColor,
  borderColor,
  borderWidth,
  animated,
  animationSpeed,
  animationTiming,
}: SegmentedProgressBarProps) {
  const segmentSize = 100 / numberOfSegments;
  const currentSegment = Math.floor(progress / segmentSize);
  const currentSegmentProgress = ((progress % segmentSize) / segmentSize) * 100;
  return (
    <SegmentedProgressBarContainer
      style={{
        columnGap: segmentsGap,
      }}
    >
      {new Array(numberOfSegments).fill(0).map((_, i) => {
        const isCurrentSegment = i === currentSegment;
        const isPreviousSegment = i < currentSegment;
        const isFirstSegment = i === 0 && numberOfSegments > 1;
        const isLastSegment =
          i === numberOfSegments - 1 && numberOfSegments > 1;
        const isMiddleSegment =
          !isFirstSegment && !isLastSegment && numberOfSegments > 2;

        return (
          <ProgressBar
            key={i}
            width={width}
            progress={
              isCurrentSegment
                ? currentSegmentProgress
                : isPreviousSegment
                ? 100
                : 0
            }
            height={height}
            backgroundColor={backgroundColor}
            borderWidth={
              isCurrentSegment ? currentSegmentBorderWidth : borderWidth
            }
            borderColor={borderColor}
            color={color}
            animated={
              // for now, no support for animating all segments one after another
              animated && isCurrentSegment
            }
            animationTiming={animationTiming}
            animationSpeed={animationSpeed}
            segment={
              isFirstSegment
                ? 'first'
                : isMiddleSegment
                ? 'middle'
                : isLastSegment
                ? 'last'
                : null
            }
          />
        );
      })}
    </SegmentedProgressBarContainer>
  );
}
