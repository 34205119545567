import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type { useDeleteTaskMutationResponse } from './__generated__/useDeleteTaskMutation.graphql';

const mutation = graphql`
  mutation useDeleteTaskMutation($taskId: ID!) {
    deleteTask(taskId: $taskId) {
      taskId
    }
  }
`;
type Arguments = {
  taskId: string;
};
export default function useDeleteTask({
  taskId,
}: Arguments): UseMutationResponse<useDeleteTaskMutationResponse> {
  return useMutation({ mutation, variables: { taskId } });
}
