import { useMultiTextbookSearchModalContext } from 'ms-components/MultiTextbookSearchModal/MultiTextbookSearchModalContext';
import { ClickableTab, LinkTab, TabContent, Tabs } from 'ms-components/Tabs';
import {
  TeacherOverview as TeacherOverviewIcon,
  TextbookTeacher as LessonIcon,
  Practice as PracticeIcon,
  EngageTask as EngageIcon,
} from 'ms-components/icons';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { useTextbookTabsState } from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import Stack from 'ms-ui-primitives/Stack';
import { useAccessibilityMode } from 'ms-utils/accessibility';

export { TAB_HEIGHT_V2 as TAB_HEIGHT } from 'ms-components/Tabs';
type Props = {
  showLesson: boolean;
  showEngage: boolean;
  showOverview: boolean;
  showPractice: boolean;
};

export default function SubtopicTabs({
  showLesson,
  showEngage,
  showOverview,
  showPractice,
}: Props) {
  const { role } = useMaybeViewer() || { role: 'Other' };
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const { isInsideMultiTextbookSearchModal } =
    useMultiTextbookSearchModalContext();
  const [activeTab, setActiveTab, getTabLink] = useTextbookTabsState();

  return (
    <Tabs borderWidth={0} v2>
      {showOverview && (
        <Tab
          noNavigation={isInsideMultiTextbookSearchModal}
          active={activeTab === 'overview'}
          to={getTabLink('overview')}
          onClick={() => setActiveTab('overview')}
        >
          <SubtopicTabContent>
            <TeacherOverviewIcon />
            Overview
          </SubtopicTabContent>
        </Tab>
      )}

      {showLesson && (
        <Tab
          noNavigation={isInsideMultiTextbookSearchModal}
          active={activeTab === 'lesson'}
          to={getTabLink('lesson')}
          onClick={() => setActiveTab('lesson')}
        >
          <SubtopicTabContent>
            <LessonIcon />
            Lesson
          </SubtopicTabContent>
        </Tab>
      )}

      {showEngage && role === 'Teacher' && (
        <Tab
          noNavigation={isInsideMultiTextbookSearchModal}
          active={activeTab === 'engageActivity'}
          to={getTabLink('engageActivity')}
          onClick={() => setActiveTab('engageActivity')}
        >
          <SubtopicTabContent>
            <EngageIcon />
            Engage
          </SubtopicTabContent>
        </Tab>
      )}

      {showPractice && (
        <Tab
          noNavigation={isInsideMultiTextbookSearchModal}
          active={activeTab === 'interactive' || activeTab === 'worksheet'}
          to={getTabLink(
            hasEnabledAccessibilityMode ? 'worksheet' : 'interactive',
          )}
          onClick={() =>
            setActiveTab(
              hasEnabledAccessibilityMode ? 'worksheet' : 'interactive',
            )
          }
        >
          <SubtopicTabContent>
            <PracticeIcon />
            Practice
          </SubtopicTabContent>
        </Tab>
      )}
    </Tabs>
  );
}

const Tab = ({
  noNavigation,
  active,
  to,
  onClick,
  children,
}: {
  noNavigation: boolean;
  active: boolean;
  to: string;
  onClick: () => void;
  children: React.ReactNode;
}) =>
  noNavigation ? (
    <ClickableTab v2 active={active} onClick={onClick}>
      {children}
    </ClickableTab>
  ) : (
    <LinkTab v2 active={active} to={to}>
      {children}
    </LinkTab>
  );

function SubtopicTabContent({ children }: { children: React.ReactNode }) {
  return (
    <TabContent v2>
      <Stack.H center gap={8}>
        {children}
      </Stack.H>
    </TabContent>
  );
}
