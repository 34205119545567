import { useState, useLayoutEffect, useEffect } from 'react';

import { TRANSITION_DURATION } from 'ms-pages/Teacher/components/Flyout/constants';

// We need to dynamically switch the effect hook depending on SSR
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

type Transitions = 'opening' | 'closing' | 'open' | 'closed';

export function useTransitionStateMachine(initialState: Transitions) {
  // eslint-disable-next-line
  const [transition, setTransition] = useState<Transitions>(initialState);

  useIsomorphicLayoutEffect(() => {
    switch (transition) {
      case 'open':
        break;
      case 'opening':
        setTimeout(() => {
          setTransition('open');
        }, 34); // two frames
        break;
      case 'closing': {
        const timeoutId = setTimeout(() => {
          setTransition('closed');
        }, TRANSITION_DURATION);
        return () => clearTimeout(timeoutId);
      }
      case 'closed':
        break;
      default:
    }
    return () => {};
  }, [setTransition, transition]);

  return [transition, setTransition] as const;
}
