import { css, StyleSheet } from 'aphrodite';
import { memo } from 'react';

import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

const WIDTH = 50 * BASE_UNIT;
const WIDTH_COMPACT = 88.4;
const HEIGHT = 5 * BASE_UNIT;
const ANIMATION_DURATION = 3000; // milliseconds

const opacityAnimation = {
  '0%': {
    opacity: 0.1,
  },
  '40%': {
    opacity: 1,
  },
  '60%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.1,
  },
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    padding: BASE_UNIT * 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noPadding: {
    padding: 0,
  },
  dot: {
    animationName: opacityAnimation,
    animationDuration: `${ANIMATION_DURATION}ms`,
    animationIterationCount: 'infinite',
  },
  firstDot: {
    animationDelay: '500ms',
  },
  secondDot: {
    animationDelay: '700ms',
  },
  thirdDot: {
    animationDelay: '900ms',
  },
});

type Props = {
  scale?: number;
  color?: string;
  noPadding?: boolean;
  isCompact?: boolean;
};

const MinorSpinner = ({
  scale = 1,
  color = colors.grayChateau,
  noPadding = false,
  // The legacy path is wider than necessary,
  // even if you don't assign any paddings,
  // it has a lot of empty space around it.
  // That makes it harder to use this spinner in a small spaces.
  // The new path is trimmed down version in width to the minimum necessary.
  // Enable noPadding and isCompact props
  // to get a spinner without any paddings or empty space around it.
  isCompact = false,
}: Props) => {
  const width = isCompact ? WIDTH_COMPACT : WIDTH;

  return (
    <div
      className={css(styles.root, noPadding && styles.noPadding)}
      style={{ color }}
    >
      <svg
        width={width * scale}
        height={HEIGHT * scale}
        viewBox={`0 0 ${width} ${HEIGHT}`}
      >
        <circle
          className={css(styles.dot, styles.firstDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 12.2 : 68}
          cy="10"
        />
        <circle
          className={css(styles.dot, styles.secondDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 44.2 : 100}
          cy="10"
        />
        <circle
          className={css(styles.dot, styles.thirdDot)}
          fill="currentColor"
          style={{ opacity: 0.1 }}
          r="8"
          cx={isCompact ? 76.2 : 132}
          cy="10"
        />
      </svg>
    </div>
  );
};

export default memo(MinorSpinner);
