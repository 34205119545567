import { useReducer } from 'react';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { EditWorksheetTaskFlyoutMutationVariables } from 'ms-pages/Teacher/components/EditTaskFlyout/EditWorksheetTaskFlyout/__generated__/EditWorksheetTaskFlyoutMutation.graphql';
import type { EditWorksheetTaskFlyout_worksheetTask as WorksheetTask } from 'ms-pages/Teacher/components/EditTaskFlyout/EditWorksheetTaskFlyout/__generated__/EditWorksheetTaskFlyout_worksheetTask.graphql';
import type { EditTaskFlyout_assignments as Assignments } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_assignments.graphql';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import { excludedStudents } from '../../';

type State = {
  startDate: Date;
  dueDate: Date;
  title: string;
  taskID: string;
  assignedClasses: readonly ClassSelectionPayload[];
  assignedStudents: readonly StudentSelectionPayload[];
  excludedStudents: readonly StudentSelectionPayload[];
};
type Action =
  | {
      type: 'UPDATE_TITLE';
      title: string;
    }
  | {
      type: 'UPDATE_DUEDATE';
      dueDate: Date;
    }
  | {
      type: 'UPDATE_STARTDATE';
      startDate: Date;
    }
  | {
      type: 'UPDATE_ASSIGNEDCLASSES';
      assignedClasses: readonly ClassSelectionPayload[];
    }
  | {
      type: 'UPDATE_ASSIGNEDSTUDENTS';
      assignedStudents: readonly StudentSelectionPayload[];
    }
  | {
      type: 'UPDATE_EXCLUDEDSTUDENTS';
      excludedStudents: readonly StudentSelectionPayload[];
    };
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'UPDATE_TITLE':
      return { ...state, title: action.title };
    case 'UPDATE_DUEDATE':
      return { ...state, dueDate: action.dueDate };
    case 'UPDATE_STARTDATE':
      return { ...state, startDate: action.startDate };
    case 'UPDATE_ASSIGNEDCLASSES':
      return { ...state, assignedClasses: action.assignedClasses };
    case 'UPDATE_ASSIGNEDSTUDENTS':
      return { ...state, assignedStudents: action.assignedStudents };
    case 'UPDATE_EXCLUDEDSTUDENTS':
      return { ...state, excludedStudents: action.excludedStudents };
    default:
      return state;
  }
}
export function createVariables({
  taskID,
  title,
  dueDate,
  startDate,
  assignedClasses,
  assignedStudents,
  excludedStudents,
}: State): EditWorksheetTaskFlyoutMutationVariables {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    assignedClasses,
    excludedStudents,
  );
  return {
    taskID,
    title,
    dueDate: dueDate.toISOString(),
    startDate: startDate.toISOString(),
    assignedClassIds: completeClassIds,
    assignedStudentIds: assignedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    first: RELAY_CONNECTION_MAX,
  };
}
export function useEditWorksheetTaskState(
  task: WorksheetTask,
  assignments: Assignments,
) {
  const [state, dispatch] = useReducer(reducer, {
    title: task.title,
    taskID: task.id,
    startDate: new Date(task.startDate),
    dueDate: new Date(task.dueDate),
    assignedClasses: assignments.assignedClasses,
    assignedStudents: assignments.individuallyAssignedStudents.map(student => ({
      id: student.id,
      ...student.user,
    })),
    excludedStudents: excludedStudents(assignments.partiallyAssignedClasses),
  });
  return [state, dispatch] as const;
}
