import { StyleSheet } from 'aphrodite';

import { breakPoints, fontSize, fontWeight, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const GUTTER_HEIGHT = 10;
const GUTTER_WIDTH = 20;
const GUTTER_WIDTH_MEDIUM = 40;
const HINT_PADDING_HORIZONTAL = 12.5;
const HINT_PADDING_VERTICAL = 2.5;
const INSTRUCTION_MARGIN_BOTTOM = 15;
const LIST_ITEM_MARGIN_TOP = 15;
const LIST_PADDING_LEFT = 26;
const NOTINTERACTIVE_PADDING_LATERAL = 20;

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.seashell,
    padding: `${GUTTER_HEIGHT}px 0 0`,
  },
  column: {
    display: 'inline-flex', // wrap the content if it is wider than 100%
    flexDirection: 'column',
    minWidth: '100%',
  },
  container: {
    padding: `${GUTTER_HEIGHT / 2}px ${GUTTER_WIDTH}px`,

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      paddingLeft: GUTTER_WIDTH_MEDIUM,
      paddingRight: GUTTER_WIDTH_MEDIUM,
    },
  },
  hints: {
    marginBottom: GUTTER_HEIGHT / 2,
  },
  hint: {
    backgroundColor: colors.polar,
    boxSizing: 'border-box',
    margin: `${GUTTER_HEIGHT}px 0`,
    padding: `${HINT_PADDING_VERTICAL}px ${HINT_PADDING_HORIZONTAL}px ${HINT_PADDING_VERTICAL}px`,
  },
  instruction: {
    marginBottom: INSTRUCTION_MARGIN_BOTTOM,
  },
  list: {
    margin: 0,
    padding: 0,
  },
  listItem: {
    marginBottom: LIST_ITEM_MARGIN_TOP / 2,
  },
  listItemTopMargin: {
    marginTop: LIST_ITEM_MARGIN_TOP,
  },
  listWithStyleType: {
    listStyleType: 'lower-latin',
    paddingLeft: LIST_PADDING_LEFT,
  },
  listWithoutStyleType: {
    listStyleType: 'none',
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    boxSizing: 'border-box',
    // prevent inline items from taking up extra vertical space
    lineHeight: 0,
  },
  solution: {
    backgroundColor: colors.ironLight,
    padding: `${GUTTER_HEIGHT}px 0`,
  },
  spacer: {
    display: 'inline-block',
    width: '1em',
  },
  notInteractive: {
    paddingLeft: NOTINTERACTIVE_PADDING_LATERAL,
    paddingRight: NOTINTERACTIVE_PADDING_LATERAL,
    color: colors.dustyGray,
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
    fontFamily: fontFamily.body,
    cursor: 'default',
  },
});

export default styles;
