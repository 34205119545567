import { StyleSheet, css } from 'aphrodite';

import type { SpacingData } from '../index';

const styles = StyleSheet.create({
  axis: {
    stroke: '#000',
    strokeWidth: 1,
  },
});

type Props = {
  spacing: SpacingData;
};

const Axis = ({ spacing }: Props) => (
  <g>
    <line
      className={css(styles.axis)}
      x1={spacing.minBound - 0.5}
      y1={spacing.minBound - 0.5}
      x2={spacing.minBound - 0.5}
      y2={spacing.maxBound - 0.5}
    />
    <line
      className={css(styles.axis)}
      x1={spacing.minBound - 0.5}
      y1={spacing.maxBound - 0.5}
      x2={spacing.maxBound - 0.5}
      y2={spacing.maxBound - 0.5}
    />
  </g>
);

export default Axis;
