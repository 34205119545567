import { graphql } from 'react-relay';

import useMutation from 'ms-pages/Lantern/utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-pages/Lantern/utils/relay/useMutation';

import type {
  useResetSuggestedLearningFocusMutationResponse,
  useResetSuggestedLearningFocusMutationVariables,
} from './__generated__/useResetSuggestedLearningFocusMutation.graphql';

const mutation = graphql`
  mutation useResetSuggestedLearningFocusMutation {
    resetSuggestedLearningFocus {
      errors {
        key
      }
      student {
        suggestedLearningFocus {
          id
        }
      }
    }
  }
`;
export default function useResetSuggestedLearningFocus(): UseMutationResponse<
  useResetSuggestedLearningFocusMutationResponse,
  useResetSuggestedLearningFocusMutationVariables
> {
  return useMutation({
    mutation,
  });
}
