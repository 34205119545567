import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Print = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <path d="M14.194 1.121C14.194.503 13.69 0 13.073 0H2.927c-.618 0-1.12.503-1.12 1.121v2.75h12.387v-2.75Zm.943 3.782H.863a.605.605 0 0 0-.605.605v6.016c0 .334.271.605.605.605h2.234v-1.976c0-.903.734-1.637 1.637-1.637h6.532c.903 0 1.637.734 1.637 1.637v1.976h2.234a.605.605 0 0 0 .605-.605V5.508a.605.605 0 0 0-.605-.605Zm-1.46 2.323h-.774a.516.516 0 1 1 0-1.032h.774a.516.516 0 1 1 0 1.032Zm-2.41 2.322H4.733a.605.605 0 0 0-.605.605v5.242c0 .334.271.605.605.605h6.532a.606.606 0 0 0 .605-.605v-5.242a.605.605 0 0 0-.605-.605Zm-1.719 4.904H6.452a.516.516 0 1 1 0-1.033h3.096a.516.516 0 1 1 0 1.033Zm0-2.323H6.452a.516.516 0 1 1 0-1.032h3.096a.516.516 0 1 1 0 1.032Z" />
  </Icon>
);
Print.displayName = 'Print';

export default Print;
