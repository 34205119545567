import emotionStyled from '@emotion/styled';
import type { ComponentType } from 'react';
import {
  useCallback,
  useLayoutEffect,
  useState,
  useMemo,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import { useQuery, graphql } from 'relay-hooks';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { MasteryLevelVolumeIndicator } from 'ms-components/MasteryLevelVolumeIndicator';
import MultiTextbookSearchModal from 'ms-components/MultiTextbookSearchModal';
import { MultiTextbookSearchModalContextProvider } from 'ms-components/MultiTextbookSearchModal/MultiTextbookSearchModalContext';
import Retry from 'ms-components/Retry';
import EngageTaskIcon from 'ms-components/icons/EngageTask';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import {
  ACCORDION_ANIMATION_DURATION,
  AccordionItem,
} from 'ms-pages/Teacher/components/Accordion';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  hasSubtopics,
  hasAssignedLessonTask,
  hasSubtopicsWithAssignedLessonTask,
  hasAssignedUnstartedLessonTask,
  hasSubtopicsWithUnstartedAssignedLessonTask,
  hasAssignedWorksheetTask,
  hasSubtopicsWithAssignedWorksheetTask,
  hasAssignedUnstartedWorksheetTask,
  hasSubtopicsWithUnstartedAssignedWorksheetTask,
} from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import { useStudentTextbookContext } from 'ms-pages/Textbooks/StudentTextbook/StudentTextbookContext';
import {
  getGeneralMaterials,
  getProductVisionId,
  textbookMaterials,
} from 'ms-pages/Textbooks/components/MaterialsGuideView/CoreTextbookMappings';
import {
  SearchModeEmptyState,
  EmptyStateSyllabusWithNoTopics,
  EmptyStateNoFilteredSubtopics,
  SearchOtherTextbooksButton,
} from 'ms-pages/Textbooks/components/SearchModeEmptyState';
import { TextbookRedirector } from 'ms-pages/Textbooks/components/TextbookRedirector';
import type { TabValue } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import TextbookSelectorButton from 'ms-pages/Textbooks/components/TopicSidebar/TextbookSelectorButton';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Checkbox from 'ms-ui-primitives/Checkbox';
import HighLighter from 'ms-ui-primitives/Highlighter';
import SearchInput from 'ms-ui-primitives/SearchInput';
import { HStack, HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import {
  styled,
  styledVerticallyScrollable,
  multilineTextOverflow,
  onHover,
} from 'ms-utils/emotion';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import { noop } from 'ms-utils/misc';
import type { CallExtractNode } from 'ms-utils/relay/extractNode';
import _ from 'ms-utils/relay/extractNode';

import type { TopicSidebarQuery } from './__generated__/TopicSidebarQuery.graphql';

const VERTICAL_PADDING = BASE_UNIT * 5;
const VERTICAL_PADDING_SMALL = BASE_UNIT * 2;
const HORIZONTAL_PADDING = BASE_UNIT * 4;
const HORIZONTAL_PADDING_SMALL = BASE_UNIT * 3;
const TOPICS_WRAPPER_TOP_PADDING = VERTICAL_PADDING / 2;
const SEARCH_OTHER_TEXTBOOKS_BUTTON_HEIGHT = 38;
const SEARCH_OTHER_TEXTBOOKS_BUTTON_PADDING = 24;
const OFFSET_FOR_SEARCH_OTHER_TEXTBOOKS_BUTTON =
  SEARCH_OTHER_TEXTBOOKS_BUTTON_HEIGHT +
  2 * SEARCH_OTHER_TEXTBOOKS_BUTTON_PADDING;
const TEXT_BASE_COLOR = 'grey90';
const TEXT_ACTIVE_COLOR = 'eggplant';
const SUBTOPIC_BACKGROUND_COLOR = colors.seashell;
const SUBTOPIC_BACKGROUND_COLOR_HOVER = colors.porcelain;
const SUBTOPIC_BACKGROUND_COLOR_ACTIVE = colors.eggplant10;
// Yucky type hack to consolidate the two different types of syllabus objects
//
// We are feeding a syllabus object into the TopicSidebar, switching depending
// on whether we want search results. the info either comes from a query that
// pulls in all the syllabus data (inc. things like topic overviews) or a query
// based on a search that returns similar (but not exactly the same) shaped
// syllbus info filtered by the search. The latter is pushed into the standard
// shape, but it lacks some of the fields the other one has, and vice-versa -
//  cos there're not required in that code path and there're not even
// available on the GQL type (i.e. overviews).
type Syllabus = Partial<
  TopicSidebarQuery['response']['syllabus'] &
    NonNullable<TopicSidebarQuery['response']['textbookSearch']>['syllabus']
>;
type Topic = CallExtractNode<
  NonNullable<TopicSidebarQuery['response']['syllabus']>['topics']
>[number];
export type Subtopic = CallExtractNode<Topic['subtopics']>[number];
type EventHandlers = {
  onSelectSyllabus: (textbookId: string) => void;
  onChangeSearchString: (value: string) => void;
  onClickChapterIntro?: () => void;
  onClickMaterialsGuide?: () => void;
  onClickTopic: (params: {
    topic: Topic;
    shouldRenderTopicIntroduction: boolean;
  }) => void;
  onClickTopicOverview?: (topic: Topic) => void;
  onClickSubtopic: (params: { topic: Topic; subtopic: Subtopic }) => void;
  onSelectSubtopic?: (subtopic: Subtopic) => void;
  onSetBookTypeTab?: (tab: TabValue) => void;
  onSearchTabSearchStringUpdate?: (searchString: string) => void;
};
export type Props = {
  syllabusId: string;
  activeTopicId?: string | null;
  activeSubtopicId: string | null;
  activeElementType?:
    | 'subtopic'
    | 'materials-guide'
    | 'chapter-intro'
    | 'topic-overview';
  /* I'm generally against boolean props like this, but there aren't easy ways
   * to customize the rendering of this sidebar without tearing it apart and
   * restructuring it into common components just to avoid rendering a single
   * line in the sidebar.
   * This flag is used as differentiation between the teacher and student
   * experience
   */
  subtopicsOnly?: boolean;
  eventHandlers: EventHandlers;
  isInCATFA: boolean;
  searchString: string;
  disableMultiTextbookSearch?: boolean;
  subtopicFilter?: (subtopic: Subtopic) => boolean;
  enableMultipleSelection?: boolean;
  selectedSubtopicIds?: readonly string[] | null;
  popoverBottomEdgeBuffer?: number;
};
const TopicsWrapper = styled({
  paddingLeft: HORIZONTAL_PADDING,
  paddingRight: HORIZONTAL_PADDING_SMALL,
  paddingBottom: VERTICAL_PADDING,
  paddingTop: TOPICS_WRAPPER_TOP_PADDING,
  flexGrow: 1,
  marginRight: 2, // to leave a small whitespace between the scrollbar and sidebar edge
  ...styledVerticallyScrollable,
});
const SearchWrapper = styled({
  marginTop: VERTICAL_PADDING,
  paddingLeft: HORIZONTAL_PADDING,
  paddingRight: HORIZONTAL_PADDING,
});
const TextbookSelectorButtonWrapper = styled({
  default: {
    paddingLeft: HORIZONTAL_PADDING,
    paddingRight: HORIZONTAL_PADDING,
    paddingTop: VERTICAL_PADDING_SMALL,
    paddingBottom: VERTICAL_PADDING_SMALL,
  },
  sunflower: {
    paddingLeft: '16px',
  },
  mobile: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});
const BADGE_SIZE = 6;
const HasAssignedLessonTaskBadge: ComponentType<{
  isUnstarted: boolean;
}> = styled({
  default: {
    backgroundColor: colors.santasGray,
    borderRadius: '50%',
    height: BADGE_SIZE,
    width: BADGE_SIZE,
    display: 'inline-block',
    marginLeft: 2 * BASE_UNIT,
  },
  isUnstarted: {
    backgroundColor: colors.crusta,
  },
});
const HasAssignedWorksheetTaskBadge: ComponentType<{
  isUnstarted: boolean;
}> = styled({
  default: {
    backgroundColor: colors.santasGray,
    borderRadius: '50%',
    height: BADGE_SIZE,
    width: BADGE_SIZE,
    display: 'inline-block',
    marginLeft: 2 * BASE_UNIT,
  },
  isUnstarted: {
    backgroundColor: colors.crusta,
  },
});
const SUBTOPIC_ITEM_BORDER_RIGHT_RADIUS = 8;
const SUBTOPIC_ITEM_BORDER_LEFT_RADIUS = 2;
const SUBTOPICS_ITEM_PADDING = 12;
const SubtopicsWrapperComponent = styled({
  borderTopRightRadius: SUBTOPIC_ITEM_BORDER_RIGHT_RADIUS,
  borderBottomRightRadius: SUBTOPIC_ITEM_BORDER_RIGHT_RADIUS,
  borderTopLeftRadius: SUBTOPIC_ITEM_BORDER_LEFT_RADIUS,
  borderBottomLeftRadius: SUBTOPIC_ITEM_BORDER_LEFT_RADIUS,
  background: SUBTOPIC_BACKGROUND_COLOR,
});
const Divider = styled({
  height: 1,
  backgroundColor: colors.ironLight,
});
const SUBTOPIC_ITEM_HEIGHT = 68;
const SubtopicItemWrapper = emotionStyled(HStack, {
  shouldForwardProp: prop => prop !== 'isActive',
})<{
  isActive: boolean;
}>(({ isActive }) => ({
  height: SUBTOPIC_ITEM_HEIGHT,
  ':first-of-type': {
    borderTopLeftRadius: SUBTOPIC_ITEM_BORDER_LEFT_RADIUS,
    borderTopRightRadius: SUBTOPIC_ITEM_BORDER_RIGHT_RADIUS,
  },
  ':last-child': {
    borderBottomLeftRadius: SUBTOPIC_ITEM_BORDER_LEFT_RADIUS,
    borderBottomRightRadius: SUBTOPIC_ITEM_BORDER_RIGHT_RADIUS,
  },
  ...onHover({
    backgroundColor: SUBTOPIC_BACKGROUND_COLOR_HOVER,
  }),
  borderLeft: `3px solid transparent`,
  ...(isActive
    ? {
        borderLeftColor: colors[TEXT_ACTIVE_COLOR],
        backgroundColor: SUBTOPIC_BACKGROUND_COLOR_ACTIVE,
        ...onHover({
          backgroundColor: SUBTOPIC_BACKGROUND_COLOR_ACTIVE,
        }),
      }
    : {}),
}));
const SubtopicItemTitleWrapper = emotionStyled(HStack)({
  ...multilineTextOverflow(2),
  width: 200,
  flexGrow: 1,
  textAlign: 'left',
});
const SearchOtherTextbooksButtonWrapper = styled({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: colors.white,
  paddingBottom: VERTICAL_PADDING_SMALL,
  paddingLeft: HORIZONTAL_PADDING,
  paddingRight: HORIZONTAL_PADDING,
});

function getShowEngageActivityBadge(
  subtopic: Subtopic,
  canAccessEngageActivities: boolean,
  role: string | null,
) {
  return (
    // will show the badge if the user
    // - is a teacher AND
    // - has access to the engage activities AND
    //   -  the subtopic has an engage activity OR
    //   - the subtopic has an engage activity teacher note

    role === 'Teacher' &&
    canAccessEngageActivities &&
    (subtopic.hasEngageActivity || subtopic.hasEngageActivityTeacherNote)
  );
}

type SubtopicItemProps = {
  dataId: string;
  isActive: boolean;
  searchTerms: string[];
  subtopic: Subtopic | null;
  title: string;
  onClick: () => void;
  onSelect?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected?: boolean | null | undefined;
  canAccessEngageActivities: boolean;
  role: string | null;
  enableMultipleSelection?: boolean;
};

function SubtopicItem({
  dataId,
  isActive,
  searchTerms,
  subtopic,
  title,
  onClick,
  onSelect = noop,
  isSelected = false,
  canAccessEngageActivities,
  role,
  enableMultipleSelection = false,
}: SubtopicItemProps) {
  const studentTextbookContext = useStudentTextbookContext();
  const { width: windowWidth } = useWindowSize();
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isActive && scrollRef.current != null) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [isActive]);
  const { setSidebarCollapsed } = studentTextbookContext || {};
  // medium breakpoint is used here rather than small to make textbook layout better
  const isMobileView = windowWidth != null && windowWidth < breakPoints.medium;
  return (
    <SubtopicItemWrapper
      center
      data-id={dataId}
      isActive={isActive}
      ref={scrollRef}
    >
      <Button
        size="lanternSmall"
        padding={SUBTOPICS_ITEM_PADDING}
        height={SUBTOPIC_ITEM_HEIGHT}
        borderRadius={0}
        label={title}
        isBlock
        color={isActive ? TEXT_ACTIVE_COLOR : TEXT_BASE_COLOR}
        onClick={() => {
          onClick();
          if (setSidebarCollapsed != null && isMobileView) {
            setSidebarCollapsed(true);
          }
        }}
      >
        <HStack center width="100%">
          {enableMultipleSelection && (
            <Checkbox onChange={onSelect} checked={!!isSelected} />
          )}
          <SubtopicItemTitleWrapper title={title}>
            <BodyM color="inherit">
              <Bold>
                <HighLighter
                  highlights={searchTerms}
                  color="tangerine"
                  backgroundColor="none"
                >
                  {title}
                </HighLighter>
              </Bold>
              {subtopic != null && hasAssignedLessonTask(subtopic) && (
                <HasAssignedLessonTaskBadge
                  isUnstarted={hasAssignedUnstartedLessonTask(subtopic)}
                />
              )}
              {subtopic != null && hasAssignedWorksheetTask(subtopic) && (
                <HasAssignedWorksheetTaskBadge
                  isUnstarted={hasAssignedUnstartedWorksheetTask(subtopic)}
                />
              )}
            </BodyM>
          </SubtopicItemTitleWrapper>

          <HSpacer width={8} grow />

          {role === 'Student' && subtopic != null && (
            <MasteryLevelVolumeIndicator
              masteryLevel={subtopic.gamifiedMastery}
              showTooltip
              noMargin
              inAssignedTaskCard // Temp rename this? It's not in the assigned task card, just to get the smaller size
            />
          )}
          {subtopic != null &&
            getShowEngageActivityBadge(
              subtopic,
              canAccessEngageActivities,
              role,
            ) && <EngageTaskIcon color={colors.grey90} />}
        </HStack>
      </Button>
    </SubtopicItemWrapper>
  );
}

const TopicItemWrapper = emotionStyled(HStack)({ padding: ` ${10}px 0` });
const TopicItemTitleWrapper = emotionStyled(HStack)({
  ...multilineTextOverflow(2),
  textAlign: 'left',
});
type TopicItemProps = {
  dataId: string;
  isActive: boolean;
  topic: Topic | null;
  searchTerms: string[];
  title: string;
  onClick: (() => void) | undefined;
};

function TopicItem({
  isActive,
  topic,
  searchTerms,
  title,
  onClick,
  dataId,
}: TopicItemProps) {
  const commonButtonProps = {
    size: 'lanternSmall',
    padding: 4,
    onClick,
    label: title,
    isBlock: true,
    color: isActive ? TEXT_ACTIVE_COLOR : TEXT_BASE_COLOR,
    height: 'auto',
  } as const;
  const buttonChildren = (
    <HStack center width="100%">
      <TopicItemTitleWrapper title={title} width="100%">
        <BodyM color="inherit">
          <Bold>
            <HighLighter
              highlights={searchTerms}
              color="tangerine"
              backgroundColor="none"
            >
              {title}
            </HighLighter>
          </Bold>
          {topic != null && hasSubtopicsWithAssignedLessonTask(topic) && (
            <HasAssignedLessonTaskBadge
              isUnstarted={hasSubtopicsWithUnstartedAssignedLessonTask(topic)}
            />
          )}
          {topic != null && hasSubtopicsWithAssignedWorksheetTask(topic) && (
            <HasAssignedWorksheetTaskBadge
              isUnstarted={hasSubtopicsWithUnstartedAssignedWorksheetTask(
                topic,
              )}
            />
          )}
        </BodyM>
      </TopicItemTitleWrapper>
    </HStack>
  );
  return (
    <TopicItemWrapper center width="100%" data-id={dataId}>
      <Button {...commonButtonProps}>{buttonChildren}</Button>
    </TopicItemWrapper>
  );
}

const TOPIC_SIDEBAR_QUERY = graphql`
  query TopicSidebarQuery(
    $syllabusId: ID!
    $search: String!
    $isSearch: Boolean!
    $isClassicSearch: Boolean!
    $isTypesenseSearch: Boolean!
  ) {
    syllabus(id: $syllabusId) @skip(if: $isSearch) {
      id
      title
      overview {
        id
      }
      thumbnailImageUrl
      topics(first: 1000) {
        edges {
          node {
            ...TopicSubtopicTree_topic @relay(mask: false)
            subtopics(first: 1000) {
              edges {
                node {
                  ...TopicSubtopicTree_subtopic @relay(mask: false)
                }
              }
            }
          }
        }
      }
    }
    syllabusSearchResult(syllabusId: $syllabusId, query: $search)
      @include(if: $isClassicSearch) {
      id
      syllabus {
        id
      }
      topics(first: 1000) {
        edges {
          node {
            topic {
              ...TopicSubtopicTree_topic @relay(mask: false)
            }
            subtopics(first: 1000) {
              edges {
                node {
                  subtopic {
                    ...TopicSubtopicTree_subtopic @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      }
    }
    textbookSearch(syllabusId: $syllabusId, query: $search)
      @include(if: $isTypesenseSearch) {
      id
      syllabus {
        id
      }
      topics(first: 1000) {
        edges {
          node {
            topic {
              ...TopicSubtopicTree_topic @relay(mask: false)
            }
            subtopics(first: 1000) {
              edges {
                node {
                  subtopic {
                    ...TopicSubtopicTree_subtopic @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      }
    }
    multiTextbookSearch(q: $search) @include(if: $isSearch) {
      hits {
        document {
          id
        }
      }
    }
  }
`;
export default function TopicSidebar({
  activeTopicId = null,
  activeSubtopicId,
  activeElementType = 'subtopic',
  disableMultiTextbookSearch = false,
  eventHandlers,
  isInCATFA,
  searchString: _searchString,
  subtopicsOnly = false,
  syllabusId,
  subtopicFilter = () => true,
  enableMultipleSelection = false,
  selectedSubtopicIds,
  popoverBottomEdgeBuffer = 0,
}: Props) {
  const { trackStructEvent } = useSnowplow();
  const {
    featureFlags: {
      classicUi,
      engageTasks,
      textbookOverviews,
      primaryTeacherNotes,
    },
    role,
  } = useMaybeViewer() || {
    // It must be maybe viewer because we support also the public textbook
    featureFlags: {
      classicUi: true,
      engageTasks: false,
      textbookOverviews: false,
    },
    role: null,
  };
  const [searchString, setSearchString] = useState(_searchString);
  const onSubmitTextbookSearch = (value: string) => {
    if (value !== '') {
      trackStructEvent({
        action: 'used_search',
        category: isInCATFA ? 'create_task_from_anywhere' : 'textbook',
        label: value,
        property: enableMultiTextbookSearch
          ? 'multi_textbook_search'
          : 'classic_search',
      });
    }
    setSearchString(
      // String tokenisation + search results in search strings with trailing
      // whitespace matching everything; we trim the search string to avoid that.
      value
        .split(' ')
        .filter(s => s !== '')
        .join(' '),
    );
  };
  const { version: searchVersion, enableMultiTextbookSearch } =
    role === 'Teacher'
      ? {
          version: 'typesense',
          enableMultiTextbookSearch: !disableMultiTextbookSearch,
        }
      : {
          version: 'classic',
          enableMultiTextbookSearch: false,
        };
  const queryParams = useMemo(() => {
    const isSearch = !!searchString;
    return {
      syllabusId,
      isSearch,
      isClassicSearch: isSearch && searchVersion === 'classic',
      isTypesenseSearch: isSearch && searchVersion === 'typesense',
      search: searchString,
    };
  }, [searchString, searchVersion, syllabusId]);
  const [expandedTopicId, setExpandedTopicId] = useState<string | null>(
    activeTopicId,
  );
  const onTopicSelect = (topic: Topic) => {
    setExpandedTopicId(prev => {
      return prev === topic.id ? null : topic.id;
    });
  };
  const { props, error, retry } = useQuery<TopicSidebarQuery>(
    TOPIC_SIDEBAR_QUERY,
    queryParams,
    { fetchPolicy: 'store-and-network' },
  );
  const elementIdToScrollTo =
    activeSubtopicId != null && activeElementType === 'subtopic'
      ? activeSubtopicId
      : activeTopicId != null && activeElementType === 'topic-overview'
      ? activeTopicId
      : activeElementType;
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      tryAndScrollToElementId(elementIdToScrollTo);
    }, ACCORDION_ANIMATION_DURATION);
    return () => {
      clearTimeout(timeout);
    };
  }, [elementIdToScrollTo]);
  const {
    syllabus: syllabusObject,
    textbookSearch,
    syllabusSearchResult,
  } = props ?? {};
  const searchResults =
    searchVersion === 'typesense' ? textbookSearch : syllabusSearchResult;
  const additionalTextbookSearchResults = props?.multiTextbookSearch;
  const [syllabus, topics] = useMemo((): [Syllabus, readonly Topic[]] => {
    if (searchString === '' && syllabusObject != null) {
      return [syllabusObject, _(syllabusObject.topics)];
    } else if (searchString !== '' && searchResults != null) {
      return [
        searchResults.syllabus,
        _(searchResults.topics).map(({ topic, subtopics }) => ({
          ...topic,
          subtopics: {
            edges: _(subtopics).map(({ subtopic }) => ({
              node: subtopic,
            })),
          },
        })),
      ];
    }
    return [{}, []];
  }, [searchResults, searchString, syllabusObject]);
  const filteredTopicTree = topics
    .filter(hasSubtopics)
    .map(topic => {
      return {
        topic,
        filteredSubtopics: _(topic.subtopics).filter(s => subtopicFilter(s)),
      };
    })
    .filter(topic => topic.filteredSubtopics.length > 0);
  const [isMultiTextbookSearchModalOpen, setIsMultiTextbookSearchModalOpen] =
    useState(false);
  useEffect(() => {
    if (activeSubtopicId !== null && expandedTopicId === null) {
      const activeTopic = topics.find(topic => {
        return topic.subtopics.edges.some(
          edge => edge.node.id === activeSubtopicId,
        );
      });
      if (activeTopic != null) {
        setExpandedTopicId(activeTopic.id);
      }
    }
  }, [activeSubtopicId, expandedTopicId, topics]);

  const {
    onSelectSyllabus,
    onChangeSearchString,
    onClickChapterIntro,
    onClickMaterialsGuide,
    onClickTopic,
    onClickTopicOverview,
    onClickSubtopic,
    onSelectSubtopic,
    onSetBookTypeTab,
    onSearchTabSearchStringUpdate,
  } = eventHandlers;

  const onSearchOtherTextbooks = useCallback(() => {
    if (isInCATFA) {
      onSearchTabSearchStringUpdate?.(searchString);
      onSetBookTypeTab?.('search');
    } else {
      setIsMultiTextbookSearchModalOpen(true);
    }
  }, [
    isInCATFA,
    onSearchTabSearchStringUpdate,
    onSetBookTypeTab,
    searchString,
  ]);

  if (syllabusId == null) return <TextbookRedirector />;
  const shouldRenderIntroduction =
    !subtopicsOnly && classicUi && role === 'Teacher';
  const showSearchOtherTextbooksButton =
    enableMultiTextbookSearch && !!searchString && topics.length > 0;
  const shouldRenderMaterialsGuide =
    primaryTeacherNotes &&
    !subtopicsOnly &&
    role === 'Teacher' &&
    isThereAnyMaterialsGuide(syllabusId);
  const shouldRenderTopicIntroduction = (topic: { hasOverview: boolean }) =>
    // we render the topic overview item in the sidebar if
    // the topic has at least one overview document, AND
    topic.hasOverview &&
    // - it's not a subtopic only view, AND
    !subtopicsOnly &&
    // - the user has access to it, AND
    textbookOverviews &&
    // the user is a teacher
    role === 'Teacher';

  return (
    <>
      <SearchWrapper>
        <SearchInput
          searchString={_searchString}
          placeholder="Search"
          onSubmit={(currentValue, debouncedValue) => {
            onChangeSearchString(currentValue);
            onSubmitTextbookSearch(debouncedValue ?? currentValue);
          }}
          searchIconOnLeft
          whiteBackground
          withBorder
          height={42}
          borderColor={colors.iron}
          placeholderColor={colors.grey90}
          trackingId={
            classicUi
              ? 'Textbooks/TopicSidebar/SearchInput'
              : 'Textbooks/SunflowerTopicSidebar/SearchInput'
          }
          type={searchVersion === 'typesense' ? 'dynamic' : 'static'}
        />
      </SearchWrapper>

      <TextbookSelectorButtonWrapper>
        <TextbookSelectorButton
          syllabusId={syllabusId}
          onSelectSyllabus={onSelectSyllabus}
          popoverBottomEdgeBuffer={popoverBottomEdgeBuffer}
        />
      </TextbookSelectorButtonWrapper>

      {enableMultiTextbookSearch && isMultiTextbookSearchModalOpen && (
        <MultiTextbookSearchModalContextProvider>
          <MultiTextbookSearchModal
            searchString={searchString}
            onClose={() => setIsMultiTextbookSearchModalOpen(false)}
            classicUi={classicUi}
            isInCATFA={false}
          />
        </MultiTextbookSearchModalContextProvider>
      )}

      {showSearchOtherTextbooksButton && (
        <SearchOtherTextbooksButtonWrapper>
          <SearchOtherTextbooksButton onClick={onSearchOtherTextbooks}>
            {additionalTextbookSearchResults?.hits.length ?? 0} results in all
            textbooks
          </SearchOtherTextbooksButton>
        </SearchOtherTextbooksButtonWrapper>
      )}

      <TopicsWrapper>
        {syllabus.overview != null &&
          shouldRenderIntroduction &&
          searchString === '' && (
            <>
              <TopicItem
                dataId="chapter-intro"
                title="Introduction"
                isActive={activeElementType === 'chapter-intro'}
                topic={null}
                searchTerms={[]}
                onClick={onClickChapterIntro}
              />
            </>
          )}

        {shouldRenderMaterialsGuide && searchString === '' && (
          <>
            <TopicItem
              dataId="materials-guide"
              isActive={activeElementType === 'materials-guide'}
              title="Materials Guide"
              topic={null}
              searchTerms={[]}
              onClick={onClickMaterialsGuide}
            />
          </>
        )}

        {props != null &&
          (topics.length === 0 ? (
            // empty states
            searchString !== '' ? (
              // if in search mode, legitimate "not found" message
              <SearchModeEmptyState
                searchString={searchString}
                onClick={onSearchOtherTextbooks}
                enableMultiTextbookSearch={enableMultiTextbookSearch}
              />
            ) : (
              // if not in search mode, it's the unexpected case of a syllabus with no topics
              <EmptyStateSyllabusWithNoTopics />
            )
          ) : (
            filteredTopicTree.length === 0 && <EmptyStateNoFilteredSubtopics />
          ))}

        {topics.length === 0 && props == null && <MinorSpinner />}
        {error != null && <Retry retry={retry} />}

        {filteredTopicTree.map(({ topic, filteredSubtopics }) => {
          return (
            <Fragment key={topic.id}>
              <TopicItem
                dataId={topic.id}
                isActive={topic.id === activeTopicId}
                onClick={() => {
                  onClickTopic({
                    topic,
                    shouldRenderTopicIntroduction:
                      shouldRenderTopicIntroduction(topic),
                  });
                  onTopicSelect(topic);
                }}
                title={topic.title}
                searchTerms={searchString.split(' ')}
                topic={topic}
              />
              <AccordionItem
                isOpen={expandedTopicId === topic.id || searchString !== ''}
              >
                <SubtopicsWrapperComponent>
                  {/* Topic Overview things */}
                  {shouldRenderTopicIntroduction(topic) &&
                    searchString === '' && (
                      <>
                        <SubtopicItem
                          dataId={`topic-overview-${topic.id}`}
                          role={role}
                          subtopic={null}
                          isActive={
                            activeTopicId === topic.id &&
                            activeElementType === 'topic-overview'
                          }
                          onClick={() => {
                            onClickTopicOverview && onClickTopicOverview(topic);
                          }}
                          searchTerms={[]}
                          title="Topic Overview"
                          canAccessEngageActivities={engageTasks}
                        />
                        <Divider />
                      </>
                    )}

                  {filteredSubtopics.map(
                    (subtopic, subtopicIndex, subtopicsArray) => (
                      <Fragment key={subtopic.id}>
                        <SubtopicItem
                          dataId={subtopic.id}
                          role={role}
                          onClick={() => {
                            onClickSubtopic({ subtopic, topic });
                          }}
                          isActive={activeSubtopicId === subtopic.id}
                          subtopic={subtopic}
                          searchTerms={searchString.split(' ')}
                          title={subtopic.title}
                          canAccessEngageActivities={engageTasks}
                          onSelect={() => {
                            onSelectSubtopic && onSelectSubtopic(subtopic);
                          }}
                          isSelected={
                            selectedSubtopicIds &&
                            selectedSubtopicIds.includes(subtopic.id)
                          }
                          enableMultipleSelection={enableMultipleSelection}
                        />

                        {subtopicIndex < subtopicsArray.length - 1 && (
                          <Divider />
                        )}
                      </Fragment>
                    ),
                  )}
                </SubtopicsWrapperComponent>
              </AccordionItem>
            </Fragment>
          );
        })}
        {showSearchOtherTextbooksButton && (
          <VSpacer height={OFFSET_FOR_SEARCH_OTHER_TEXTBOOKS_BUTTON} />
        )}
      </TopicsWrapper>
    </>
  );
}

function tryAndScrollToElementId(elementId: string) {
  const element = window?.document?.querySelector(`[data-id=${elementId}]`);
  if (element != null) {
    scrollIntoView(element, {
      scrollMode: 'if-needed',
      block: 'start',
      inline: 'nearest',
      skipOverflowHiddenElements: true,
      boundary: parent => {
        // This is to make sure that we don't scroll the body
        if (document && document.body) {
          return parent !== document.body;
        }
        return true;
      },
    });
  }
}

function isThereAnyMaterialsGuide(syllabusId: string) {
  const textbookGuides = textbookMaterials[syllabusId] ?? [];
  const generalGuides = getGeneralMaterials(syllabusId);
  const productVisionId = getProductVisionId(syllabusId);
  return (
    textbookGuides.length > 0 ||
    generalGuides.length > 0 ||
    productVisionId != null
  );
}
