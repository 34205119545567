import { useHistory } from 'react-router-dom';

export const usePushToHistoryPreservingSearch = () => {
  const history = useHistory();
  return (link: string | null) => {
    if (link == null) return;
    history.push({
      pathname: link,
      search: history.location.search,
    });
  };
};
