import styled from '@emotion/styled';
import { useState, useCallback, useRef } from 'react';

import { Eye } from 'ms-components/icons/Eye';
import View from 'ms-components/icons/View';
import IconButton from 'ms-pages/Work/WorkoutHeader/IconButton';
import { inputHeight } from 'ms-styles/base';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import type { Props } from 'ms-ui-primitives/Input';
import Input from 'ms-ui-primitives/Input';

const EYE_ICON_SIZE = inputHeight / 1.8;

const PasswordInputWrapper = styled.div({
  position: 'relative',
});

const IconWrapper = styled.div({
  position: 'absolute',
  top: (inputHeight - EYE_ICON_SIZE) / 2,
  right: BASE_UNIT,
});

const PasswordInput = (props: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const toggleVisibility = useCallback(() => {
    setIsPasswordVisible(prevState => {
      if (inputRef.current) {
        inputRef.current.type = prevState ? 'password' : 'text';
        return !prevState;
      } else {
        return prevState;
      }
    });
  }, []);
  return (
    <PasswordInputWrapper>
      <Input {...props} ref={inputRef} type="password" />
      <IconWrapper>
        <IconButton
          icon={isPasswordVisible ? View : Eye}
          iconSize={EYE_ICON_SIZE}
          onClick={toggleVisibility}
          trackingId={`${String(props.trackingId)}/ToggleVisibility`}
        />
      </IconWrapper>
    </PasswordInputWrapper>
  );
};

export default PasswordInput;
