import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M4.00789395,15.1262544 C4.00268442,15.0849028 4,15.0427649 4,15 L4,4 C4,3.44771525 4.44771525,3 5,3 L15,3 C15.5522847,3 16,3.44771525 16,4 L16,15 C16,15.5522847 15.5522847,16 15,16 L8,16 L8,16.9186715 L15.4593357,16.9186715 C15.7579364,16.9186715 16,17.1607351 16,17.4593357 C16,17.7579364 15.7579364,18 15.4593357,18 L8,18 L8,19 L7,18.0758611 L6,19 L6,18 L5.47719832,18 C4.49026735,18 3.92569311,17.3926153 4.00789395,16.4192813 L4.00789395,15.1262544 Z M5.06566324,16 L5.06558323,16.4670384 C5.03544404,16.8326866 5.06082768,16.9186715 5.47719832,16.9186715 L6,16.9186715 L6,16 L5.06566324,16 Z M6,5 L6,7 L14,7 L14,5 L6,5 Z" />
);

const Textbook = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Textbook.displayName = 'Textbook';

export default Textbook;
