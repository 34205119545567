import { unwrap } from 'ms-utils/typescript-utils';

const { floor } = Math;
const LETTERS = 'abcdefghijklmnopqrstuvwxyz';
const NUMBER_OF_LETTERS = LETTERS.length;

export const getLetterIndex = (index: number): string => {
  if (index < NUMBER_OF_LETTERS) {
    return unwrap(LETTERS[index]);
  }
  const prefixIndex = floor(index / NUMBER_OF_LETTERS) - 1;
  const suffixIndex = index % NUMBER_OF_LETTERS;

  return `${getLetterIndex(prefixIndex)}${getLetterIndex(suffixIndex)}`;
};

export const getProblemIndex = (index: number): number => index + 1;

export const getSubproblemIndex = (index: number): string =>
  getLetterIndex(index);
