import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g transform="translate(0.850000, 3.000000)">
    <path d="M2.81516968,0.0030362589 C2.81467396,0.0030362589 2.81411628,0.0030362589 2.81362056,0.0030362589 C2.63956238,0.0030362589 2.47585225,0.0708873505 2.35223314,0.19419664 C2.22725081,0.318869148 2.15840829,0.484871956 2.15840829,0.661594617 L2.15840829,10.5365619 C2.15840829,10.8986823 2.45416469,11.1940049 2.81777218,11.1949344 C4.35064917,11.1985903 6.91882848,11.5180791 8.69051653,13.3721179 L8.69051653,3.03768408 C8.69051653,2.91493247 8.65916251,2.79961659 8.59998645,2.70419132 C7.14586629,0.362430169 4.35151668,0.006630198 2.81516968,0.0030362589 Z" />
    <path d="M16.2450385,10.5365619 L16.2450385,0.661532652 C16.2450385,0.484809992 16.176196,0.318807184 16.0512136,0.194134676 C15.9275945,0.0708253861 15.7637605,0.00297429443 15.5898882,0.00297429443 C15.5893305,0.00297429443 15.5887728,0.00297429443 15.5882771,0.00297429443 C14.0519921,0.006630198 11.2576424,0.362430169 9.80346033,2.70419132 C9.74428426,2.79961659 9.71299221,2.91493247 9.71299221,3.03768408 L9.71299221,13.372056 C11.4846803,11.5180171 14.0528596,11.1985283 15.5857366,11.1948724 C15.9492821,11.193943 16.2450385,10.8986203 16.2450385,10.5365619 Z" />
    <path d="M17.7449504,2.28035436 L17.2674522,2.28035436 L17.2674522,10.5365619 C17.2674522,11.4611338 16.5141502,12.2150554 15.5881532,12.2173481 C14.2879528,12.2204463 12.1441061,12.4746865 10.6257908,13.9117045 C13.251721,13.2687612 16.0199216,13.6867115 17.5975369,14.0462294 C17.794522,14.0910916 17.9980752,14.0447422 18.1558987,13.9189543 C18.3131645,13.7934763 18.4033848,13.6059099 18.4033848,13.4045873 L18.4033848,2.93878879 C18.4033848,2.57573897 18.1080002,2.28035436 17.7449504,2.28035436 Z" />
    <path d="M1.13593261,10.5365619 L1.13593261,2.28035436 L0.658434429,2.28035436 C0.29544658,2.28035436 0,2.57573897 0,2.93878879 L0,13.4044014 C0,13.6057859 0.0902202643,13.7932904 0.247486082,13.9187685 C0.405185651,14.0444944 0.608553033,14.0910297 0.805847897,14.0460435 C2.38346323,13.6864637 5.15172581,13.2685753 7.77753207,13.9115186 C6.25927869,12.4745626 4.11543206,12.2203844 2.81523164,12.2172861 C1.88929661,12.2150554 1.13593261,11.4611338 1.13593261,10.5365619 Z" />
  </g>
);

const TextbookTeacher = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);
TextbookTeacher.displayName = 'TextbookTeacher';

export default TextbookTeacher;
