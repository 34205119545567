import styled from '@emotion/styled';
import { mathMod } from 'ramda';
import { useCallback, useState } from 'react';

import Pagination from 'ms-components/Pagination';
import Icons from 'ms-components/icons';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HStack, HSpacer, VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { unwrap } from 'ms-utils/typescript-utils';

type Item = { key: string; node: React.ReactNode };
type SliderProps = {
  items: ReadonlyArray<Item>;
};

const HStackFadeOut = styled(HStack, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>(({ active }) => ({
  opacity: active ? 1 : 0,
  width: active ? '100%' : 0,
  height: active ? '100%' : 0,
  visibility: active ? 'visible' : 'hidden',
  overflow: active ? 'visible' : 'hidden',
  transition: 'opacity .5s ease-in-out',
  justifyContent: 'center',
}));

const VStackFlexGrow = styled(HStack)({
  flexGrow: 1,
});

export default function Slider({ items }: SliderProps) {
  const [index, setIndex] = useState(0);

  const incrementIndex = useCallback(() => {
    setIndex(prevIndex => mathMod(prevIndex + 1, items.length));
  }, [items.length]);

  const decrementIndex = useCallback(() => {
    setIndex(prevIndex => mathMod(prevIndex - 1, items.length));
  }, [items.length]);

  if (items.length === 0) return null;

  if (items.length === 1)
    return <HStackFadeOut active>{unwrap(items[0]).node}</HStackFadeOut>;

  return (
    <VStack>
      <HStack center>
        <VStackFlexGrow>
          {items.map((item, itemIndex) => (
            <HStackFadeOut active={itemIndex === index} key={item.key}>
              {item.node}
            </HStackFadeOut>
          ))}
        </VStackFlexGrow>
      </HStack>

      <VSpacer height={8} />

      <HStack center>
        <Button isCircle onClick={decrementIndex} label="Go back">
          <Icons.ChevronLeft size={20} />
        </Button>

        <HSpacer width={8} grow />

        <Pagination
          paginationId="NavbarPopover"
          activePage={index}
          numberOfPages={items.length}
          onSelect={setIndex}
          activeColor={colors.eggplant}
          inactiveColor={colors.eggplant20}
        />

        <HSpacer width={8} grow />

        <Button isCircle onClick={incrementIndex} label="Go next">
          <Icons.ChevronRight size={20} />
        </Button>
      </HStack>
    </VStack>
  );
}
