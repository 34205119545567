import styled from '@emotion/styled';
import { useLingui } from '@lingui/react';
import { Fragment } from 'react';

import { ProficiencyIndicatorPresentational } from 'ms-pages/Lantern/components/ProficiencyIndicator';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import { ModalContent, ModalFooter } from 'ms-pages/Lantern/primitives/Modal';
import {
  HSpacer,
  HStack,
  VSpacer,
  VStack,
} from 'ms-pages/Lantern/primitives/Stack';
import { BodyS, Bold, HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import Modal from 'ms-ui-primitives/Modal';
import {
  MASTERY_LEVEL_TITLES,
  MASTERY_LEVELS_DESCENDING,
  TEACHER_CURRICULUM_REPORT_MASTERY_LEVEL_DESCRIPTIONS,
  TEACHER_OVERVIEW_REPORT_MASTERY_LEVEL_DESCRIPTIONS,
  STUDENT_SKILLS_REPORT_MASTERY_LEVEL_DESCRIPTIONS,
} from 'ms-utils/masteryLevel';
import type { MasteryLevel } from 'ms-utils/masteryLevel';

const PROFICIENCY_INDICATOR_HEIGHT = 32;
const PROFICIENCY_INDICATOR_CIRCLE_WIDTH = 32;
const PROFICIENCY_INDICATOR_SQUARE_WIDTH = 75;

const Row = styled(HStack)({
  alignItems: 'center',
});

const ProficiencyIndicatorWrapper = styled.div({
  flexShrink: 0,
});

type ColorExplanationRowProps = {
  masteryLevel: MasteryLevel;
  description: string;
  title: string;
  circle: boolean;
};

function ColorExplanationRow({
  masteryLevel,
  description,
  title,
  circle = false,
}: ColorExplanationRowProps) {
  return (
    <Row>
      <ProficiencyIndicatorWrapper
        style={{
          width: circle
            ? PROFICIENCY_INDICATOR_CIRCLE_WIDTH
            : PROFICIENCY_INDICATOR_SQUARE_WIDTH,
        }}
      >
        <ProficiencyIndicatorPresentational
          masteryLevel={masteryLevel}
          height={PROFICIENCY_INDICATOR_HEIGHT}
          circle={circle}
        />
      </ProficiencyIndicatorWrapper>
      <HSpacer width={24} />
      <VStack>
        <Bold>{title}</Bold>
        <BodyS>{description}</BodyS>
      </VStack>
    </Row>
  );
}

function OutcomeColorLegend({ legendShape }: { legendShape: LegendShape }) {
  const { i18n } = useLingui();
  return (
    <>
      {MASTERY_LEVELS_DESCENDING.map(masteryLevel => (
        <Fragment key={masteryLevel}>
          <ColorExplanationRow
            key={masteryLevel}
            masteryLevel={masteryLevel}
            title={i18n._(MASTERY_LEVEL_TITLES[masteryLevel])}
            description={i18n._(
              TEACHER_CURRICULUM_REPORT_MASTERY_LEVEL_DESCRIPTIONS[
                masteryLevel
              ],
            )}
            circle={legendShape === 'Circle'}
          />
          <VSpacer height={24} />
        </Fragment>
      ))}
    </>
  );
}

function StrandColorLegend({ legendShape }: { legendShape: LegendShape }) {
  const { i18n } = useLingui();
  return (
    <>
      {MASTERY_LEVELS_DESCENDING.map(masteryLevel => (
        <Fragment key={masteryLevel}>
          <ColorExplanationRow
            key={masteryLevel}
            masteryLevel={masteryLevel}
            title={i18n._(MASTERY_LEVEL_TITLES[masteryLevel])}
            description={i18n._(
              TEACHER_OVERVIEW_REPORT_MASTERY_LEVEL_DESCRIPTIONS[masteryLevel],
            )}
            circle={legendShape === 'Circle'}
          />
          <VSpacer height={24} />
        </Fragment>
      ))}
    </>
  );
}

function SkillColorLegend({ legendShape }: { legendShape: LegendShape }) {
  const { i18n } = useLingui();
  return (
    <>
      {MASTERY_LEVELS_DESCENDING.map(masteryLevel => (
        <Fragment key={masteryLevel}>
          <ColorExplanationRow
            key={masteryLevel}
            masteryLevel={masteryLevel}
            title={i18n._(MASTERY_LEVEL_TITLES[masteryLevel])}
            description={i18n._(
              STUDENT_SKILLS_REPORT_MASTERY_LEVEL_DESCRIPTIONS[masteryLevel],
            )}
            circle={legendShape === 'Circle'}
          />
          <VSpacer height={24} />
        </Fragment>
      ))}
    </>
  );
}

type LegendShape = 'Circle' | 'Square';

type InfoModalProps = {
  isOpen: boolean;
  variant: 'StrandColors' | 'OutcomeColors' | 'SkillColors';
  closeModal: () => void;
  legendShape?: LegendShape | undefined;
};

function InfoModal({
  isOpen,
  variant,
  closeModal,
  legendShape = 'Square',
}: InfoModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalContent>
        <HeadingL>Mastery levels</HeadingL>
        <VSpacer height={24} />
        {variant === 'OutcomeColors' && (
          <OutcomeColorLegend legendShape={legendShape} />
        )}
        {variant === 'StrandColors' && (
          <StrandColorLegend legendShape={legendShape} />
        )}
        {variant === 'SkillColors' && (
          <SkillColorLegend legendShape={legendShape} />
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={closeModal}
          type="primary"
          color="grey"
          size="lanternSmall"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export { InfoModal };
