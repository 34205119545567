import styled from '@emotion/styled';
import { useEffect } from 'react';

import LanternLogo from 'ms-components/logos/LanternLogo';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { StudentLayout } from 'ms-pages/Lantern/components/StudentLayout';
import useCreateLanternProfile from 'ms-pages/Lantern/mutations/useCreateLanternProfile';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import Main from 'ms-pages/Lantern/primitives/Main';
import { VStack, VSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { HeadingL, BodyXL, Bold } from 'ms-pages/Lantern/primitives/Typography';
import { homeUrl } from 'ms-pages/Lantern/utils/urls';
import { unwrap } from 'ms-utils/typescript-utils';

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

function WayspaceWelcome({
  onSubmit,
  isDisabled,
}: {
  onSubmit: () => void;
  isDisabled: boolean;
}) {
  return (
    <Main>
      <VSpacer height={16} grow />
      <Container>
        <VStack
          css={{
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            textAlign: 'center',
            maxWidth: 500,
          }}
        >
          <VSpacer height={16} grow />
          <LanternLogo width={60} />
          <VSpacer height={40} />
          <HeadingL>Your first check-in</HeadingL>
          <VSpacer height={24} />
          <BodyXL>
            Doing regular check-ins helps you track your{' '}
            <Bold>growth across mathematical skills</Bold>
          </BodyXL>
          <VSpacer height={40} />
          <Button
            size="lanternMedium"
            onClick={onSubmit}
            isDisabled={isDisabled}
            type="primary"
          >
            Continue
          </Button>
          <VSpacer height={16} grow />
        </VStack>
      </Container>
      <VSpacer height={16} grow />
    </Main>
  );
}

function StudentWelcome() {
  const [createLanternProfile, { loading, response, errors }] =
    useCreateLanternProfile();
  const responseErrors = response?.createLanternProfile?.errors;
  const { trackStructEvent } = useSnowplow();

  useEffect(() => {
    if (response?.createLanternProfile?.user?.id != null) {
      window.location.assign(homeUrl);
    }
  }, [response]);

  useEffect(() => {
    if (responseErrors != null && responseErrors.length > 0) {
      throw new Error(unwrap(responseErrors[0]).message);
    }
    if (errors != null) {
      if (errors instanceof Error) {
        throw new Error(errors.message);
      } else if (errors.length > 0) {
        throw new Error(unwrap(errors[0]).message);
      }
    }
  }, [errors, responseErrors]);

  const onSubmit = () => {
    trackStructEvent({
      category: 'student_onboarding',
      action: 'created_profile',
    });
    createLanternProfile();
  };

  return (
    <StudentLayout>
      <WayspaceWelcome onSubmit={onSubmit} isDisabled={loading} />
    </StudentLayout>
  );
}

export { StudentWelcome, WayspaceWelcome };
