import React from 'react';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Popover from 'ms-ui-primitives/Popover/PopoverInternal'; // PopoverInternal has better API for positioning
import { onPressOrHover, styled, tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import keyDownMap from 'ms-utils/keyDownMap';

const OutcomesWrapper = styled({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  fontFamily: fontFamily.body,
});

const OUTCOME_PILL_HEIGHT = 28;
const OutcomePill = styled({
  default: {
    fontSize: 14,
    lineHeight: 1.2,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    fontWeight: fontWeight.semibold,
    color: colors.shuttleGray,
    backgroundColor: colors.seashell,
    padding: 2 * BASE_UNIT,
    borderRadius: OUTCOME_PILL_HEIGHT / 2,
    marginRight: 2,
    marginLeft: 2,
    border: `2px solid transparent`,
    ...tappable,
    ...onPressOrHover({
      background: colors.iron,
      color: alternateColors.shuttleGray,
    }),
    ':focus': {
      outline: 'none',
      border: `2px solid ${colors.cloudBurst}`,
    },
  },
  selected: {
    background: colors.iron,
    color: alternateColors.shuttleGray,
    border: `2px solid ${colors.cloudBurst}`,
  },
});

const OutcomesLabel = styled({
  color: colors.shuttleGray,
  fontWeight: fontWeight.semibold,
  fontSize: 14,
  lineHeight: 1.2,
  marginRight: 2,
});

const OutcomeDescriptionWrapper = styled({
  padding: 20,
  boxShadow: '0px 8.75px 49px rgba(62, 62, 76, 0.15)',
  borderRadius: 8,
  background: colors.white,
  maxWidth: 320,
});

const OutcomeDescriptionTitle = styled({
  fontSize: 18,
  lineHeight: '1.375em',
  color: colors.cloudBurst,
  fontWeight: fontWeight.semibold,
  marginBottom: 12,
});

const OutcomeDescription = styled({
  fontSize: 16,
  lineHeight: '1.375em',
  color: colors.shuttleGray,
  fontWeight: fontWeight.normal,
});

type OutcomeType = {
  readonly id: string;
  readonly code: string;
  readonly description: string;
};

type Props = {
  outcomes: readonly OutcomeType[];
};

export default function SubtopicOutcomesAccessibilityMode({ outcomes }: Props) {
  return (
    <OutcomesWrapper>
      <OutcomesLabel>Outcomes</OutcomesLabel>
      {outcomes.map(outcome => (
        <Outcome key={outcome.id} outcome={outcome} />
      ))}
    </OutcomesWrapper>
  );
}

function Outcome({ outcome }: { outcome: OutcomeType }) {
  const popoverAnchorRef = React.useRef<HTMLDivElement | null>(null);
  const isDescriptionOpen = useBoolean();
  return (
    <>
      <OutcomePill
        ref={popoverAnchorRef}
        key={outcome.id}
        tabIndex="0"
        onKeyDown={keyDownMap({
          ENTER: [isDescriptionOpen.setTrue, { preventDefault: true }],
          SPACE: [isDescriptionOpen.setTrue, { preventDefault: true }],
        })}
        onClick={isDescriptionOpen.setTrue}
        selected={isDescriptionOpen.value}
      >
        {outcome.code}
      </OutcomePill>
      {isDescriptionOpen.value && (
        <Popover
          anchorElementRef={popoverAnchorRef}
          onDismiss={isDescriptionOpen.setFalse}
          popoverOrigin={['top', 'left']}
          anchorOrigin={['bottom', 'left']}
          hOffset={10}
          vOffset={10}
          shouldDismissOnTapOut
          shouldDismissOnScroll
          shouldDismissOnOwnScroll
          shouldDismissOnEsc
          shouldDismissOnTab
          renderOverlay
        >
          <OutcomeDescriptionWrapper>
            <OutcomeDescriptionTitle>{outcome.code}</OutcomeDescriptionTitle>
            <OutcomeDescription>{outcome.description}</OutcomeDescription>
          </OutcomeDescriptionWrapper>
        </Popover>
      )}
    </>
  );
}
