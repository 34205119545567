import type { SortDir } from 'ms-pages/Teacher/components/Table';

import type {
  Updater,
  StudentTabSortingField,
  CustomTaskQuestionTabSortingField,
  AdaptiveTaskQuestionTabSortingField,
  GridViewSortingField,
} from './State';

export const setFilterByClass: Updater<[string]> = classId => state => ({
  ...state,
  filterByClass: classId === '' ? null : classId,
});

export const setStudentTabSorting: Updater<[StudentTabSortingField, SortDir]> =
  (studentsSortBy, sortDir) => state => ({
    ...state,
    studentsSortBy,
    sortDir,
  });

export const setGridViewSorting: Updater<[GridViewSortingField, SortDir]> =
  (gridViewSortBy, sortDir) => state => ({
    ...state,
    gridViewSortBy,
    sortDir,
  });

export const setAdaptiveTaskQuestionTabSorting: Updater<
  [AdaptiveTaskQuestionTabSortingField, SortDir]
> = (adaptiveTaskquestionsSortBy, sortDir) => state => ({
  ...state,
  adaptiveTaskquestionsSortBy,
  sortDir,
});

export const setCustomTaskQuestionTabSorting: Updater<
  [CustomTaskQuestionTabSortingField, SortDir]
> = (customTaskquestionsSortBy, sortDir) => state => ({
  ...state,
  customTaskquestionsSortBy,
  sortDir,
});
