import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import MiloSearch from 'ms-components/MultiTextbookSearchModal/assets/MiloSearch';
import Search from 'ms-components/icons/Search';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { BodyM, BodyXL } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import { noop } from 'ms-utils/misc';

const EmptyTitle = styled.div({
  textAlign: 'center',
  padding: '0 19px',
});

const EmptyDescription = styled.div({
  textAlign: 'center',
  padding: '0 14px',
});

const EmptyContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isCentered',
})<{ isCentered?: boolean | undefined }>(({ isCentered = false }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  ...(isCentered
    ? {
        paddingTop: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    : {}),
}));

export function EmptyStateSyllabusWithNoTopics() {
  return (
    <EmptyContainer>
      <EmptyDescription>
        <BodyM>This syllabus does not contain any topics</BodyM>
      </EmptyDescription>
    </EmptyContainer>
  );
}

export function EmptyStateNoFilteredSubtopics() {
  return (
    <EmptyContainer>
      <EmptyDescription>
        <BodyM>We couldn't find that type of task in this textbook.</BodyM>
        <BodyM>Please select a different textbook or task type.</BodyM>
      </EmptyDescription>
    </EmptyContainer>
  );
}

export function SearchOtherTextbooksButton({
  onClick,
  children,
}: {
  children?: ReactNode | undefined;
  onClick: () => void;
}) {
  const { trackStructEvent } = useSnowplow();

  return (
    <Button
      size="regular"
      type="secondary"
      borderRadius={100}
      styles={{ width: '100%' }}
      label="search-other-textbooks-button"
      onClick={() => {
        trackStructEvent({
          category: 'textbook',
          action: 'opened_multi_textbook_search_modal',
        });
        onClick();
      }}
    >
      <Search color={colors.eggplant} size={16} />
      <HSpacer width={8} />
      {children}
    </Button>
  );
}

export function SearchModeEmptyState({
  searchString,
  isSearchModal = false,
  onClick,
  enableMultiTextbookSearch,
  isCentered = false,
}: {
  searchString: string;
  isSearchModal?: boolean;
  onClick?: () => void;
  enableMultiTextbookSearch: boolean;
  isCentered?: boolean | undefined;
}) {
  const isEmptyState = searchString === '';
  const showMultiTextbookCopy = enableMultiTextbookSearch && !isSearchModal;
  return (
    <EmptyContainer isCentered={isCentered}>
      <VSpacer height={isCentered ? 0 : 24} />
      <MiloSearch />
      <VSpacer height={24} />
      <EmptyTitle>
        <BodyXL
          bold
          color="neutralGray"
          style={isEmptyState ? { maxWidth: '230px' } : {}}
        >
          {isEmptyState ? (
            'Search by topic, standard or outcome code'
          ) : (
            <>
              Nothing found for "{searchString}"
              {showMultiTextbookCopy && ' in this textbook'}
            </>
          )}
        </BodyXL>
      </EmptyTitle>
      {showMultiTextbookCopy && (
        <>
          <VSpacer height={24} />
          <SearchOtherTextbooksButton
            onClick={onClick != null ? onClick : noop}
          >
            Search other textbooks
          </SearchOtherTextbooksButton>
        </>
      )}
    </EmptyContainer>
  );
}
