import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

export const Footer = styled({
  padding: 4 * BASE_UNIT,
  backgroundColor: colors.athensGray,
  // must render above the flyout body
  zIndex: 1,
});
