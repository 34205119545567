import styled from '@emotion/styled';
import { useState } from 'react';
import { graphql, useFragment } from 'relay-hooks';

import { PageTimeRecorder } from 'ms-helpers/PageTimeTracker';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { VSpacer } from 'ms-ui-primitives/Stack';

import StudentSkillsMapSubstrandRecommendationsEmpty from './StudentSkillsMapSubstrandRecommendationsEmpty';
import type { StudentSkillsMapSubstrandRecommendationsFragment$key } from './__generated__/StudentSkillsMapSubstrandRecommendationsFragment.graphql';
import SubtopicRecommendationCard from '../SubtopicRecommendations/SubtopicRecommendationCard';
import { CONTAINER_WIDTH } from '../styles';

const GRID_GAP = 20;
const CARD_WIDTH = (CONTAINER_WIDTH - 10 * GRID_GAP) / 3;
// Can't use grid because the last item in the last row
// needs to be centered as well
const Grid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: -GRID_GAP / 2,
});
const GridItem = styled.div({
  width: CARD_WIDTH,
  margin: GRID_GAP / 2,
});
const TITLE = 'Your recommended practice';
const STUDENT_SKILLS_MAP_SUBSTRAND_RECOMMENDATIONS_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandRecommendationsFragment on Query {
    lantern {
      substrand(id: $substrandId) {
        strand {
          id
        }
        studentSubtopicRecommendations {
          id
          ...SubtopicRecommendationCard_substrandSubtopicRecommendation
          category
        }
      }
      viewer {
        __typename
        ... on LanternStudent {
          strandStatus(strandId: $strandId) {
            status
            lastWorkedOnDiagnosticCheckIn {
              id
            }
            strand {
              id
            }
          }
        }
      }
    }
  }
`;
type Props = {
  query: StudentSkillsMapSubstrandRecommendationsFragment$key;
  urlToNavigateToWhenOutcomeIsClicked?: string | undefined;
  hideSkillsMapLink?: boolean | undefined;
  inDashboard?: boolean | undefined;
};
export default function StudentSkillsMapSubstrandRecommendationsFragment({
  query,
  urlToNavigateToWhenOutcomeIsClicked,
  inDashboard = false,
  hideSkillsMapLink = false,
}: Props) {
  const {
    featureFlags: { demoCheckin },
  } = useViewer();
  const [
    indexOfPriorSkillsMasteredRecommendationToShow,
    shufflePriorSkillsMastered,
  ] = useState(0);
  const [
    indexOfSkillAlmostMasteredRecommendationToShow,
    shuffleSkillAlmostMastered,
  ] = useState(0);
  const [indexOfWorkedOnRecentlyRecommendationToShow, shuffleWorkedOnRecently] =
    useState(0);
  const data = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_RECOMMENDATIONS_FRAGMENT,
    query,
  );
  if (data == null) return <LoadingSpinner />;
  const recommendations =
    data.lantern.substrand?.studentSubtopicRecommendations ?? [];
  const priorSkillsMasteredRecommendations = recommendations.filter(
    r => r.category === 'PRIOR_SKILLS_MASTERED',
  );
  const priorSkillsMasteredRecommendation =
    priorSkillsMasteredRecommendations[
      indexOfPriorSkillsMasteredRecommendationToShow %
        priorSkillsMasteredRecommendations.length
    ];
  const skillAlmostMasteredRecommendations = recommendations.filter(
    r => r.category === 'SKILL_ALMOST_MASTERED',
  );
  const skillAlmostMasteredRecommendation =
    skillAlmostMasteredRecommendations[
      indexOfSkillAlmostMasteredRecommendationToShow %
        skillAlmostMasteredRecommendations.length
    ];
  const workedOnRecentlyRecommendations = recommendations.filter(
    r => r.category === 'SKILL_WORKED_ON_RECENTLY',
  );
  const workedOnRecentlyRecommendation =
    workedOnRecentlyRecommendations[
      indexOfWorkedOnRecentlyRecommendationToShow %
        workedOnRecentlyRecommendations.length
    ];
  const lanternStudent =
    data.lantern.viewer?.__typename === 'LanternStudent'
      ? data.lantern.viewer
      : null;
  const strandStatus = lanternStudent?.strandStatus;
  const hasFinishedDiagnosticPhase = strandStatus?.status === 'GROWTH';
  const isCheckInToBeResumed =
    strandStatus?.lastWorkedOnDiagnosticCheckIn != null;
  // As the grid can contain only 3 items, we need to remove the last item if there is the tutorial task card and all the categories have more than 0 recommendations
  const hideLastItem =
    priorSkillsMasteredRecommendations.length > 0 &&
    skillAlmostMasteredRecommendations.length > 0 &&
    workedOnRecentlyRecommendations.length > 0;
  return (
    <PageTimeRecorder
      pageName="StudentSkillsMapSubstrandRecommendations"
      componentName="StudentSkillsMapSubstrandRecommendations"
    >
      {data.lantern.substrand?.studentSubtopicRecommendations &&
      data.lantern.substrand.studentSubtopicRecommendations.length > 0 ? (
        <>
          <HeadingS>{TITLE}</HeadingS>
          <VSpacer height={24} />

          <Grid>
            {priorSkillsMasteredRecommendation != null && (
              <GridItem
                key={priorSkillsMasteredRecommendation.id}
                data-tracking-id={
                  demoCheckin
                    ? 'DemoSkillsMap/Recommendation/Mastered'
                    : inDashboard
                    ? 'StudentDashboard/Recommendation/Mastered'
                    : 'SkillsMap/Recommendation/Mastered'
                }
              >
                <SubtopicRecommendationCard
                  substrandSubtopicRecommendation={
                    priorSkillsMasteredRecommendation
                  }
                  isShuffleable={priorSkillsMasteredRecommendations.length > 1}
                  onShuffle={() => {
                    shufflePriorSkillsMastered(s => s + 1);
                  }}
                  urlToNavigateToWhenOutcomeIsClicked={
                    urlToNavigateToWhenOutcomeIsClicked
                  }
                  inDashboard={inDashboard}
                />
              </GridItem>
            )}

            {skillAlmostMasteredRecommendation != null && (
              <GridItem
                key={skillAlmostMasteredRecommendation.id}
                data-tracking-id={
                  demoCheckin
                    ? 'DemoSkillsMap/Recommendation/AlmostMastered'
                    : inDashboard
                    ? 'StudentDashboard/Recommendation/AlmostMastered'
                    : 'SkillsMap/Recommendation/AlmostMastered'
                }
              >
                <SubtopicRecommendationCard
                  substrandSubtopicRecommendation={
                    skillAlmostMasteredRecommendation
                  }
                  isShuffleable={skillAlmostMasteredRecommendations.length > 1}
                  onShuffle={() => {
                    shuffleSkillAlmostMastered(s => s + 1);
                  }}
                  urlToNavigateToWhenOutcomeIsClicked={
                    urlToNavigateToWhenOutcomeIsClicked
                  }
                  inDashboard={inDashboard}
                />
              </GridItem>
            )}

            {!hideLastItem && workedOnRecentlyRecommendation != null && (
              <GridItem
                key={workedOnRecentlyRecommendation.id}
                data-tracking-id={
                  demoCheckin
                    ? 'DemoSkillsMap/Recommendation/WorkedOnRecently'
                    : inDashboard
                    ? 'StudentDashboard/Recommendation/WorkedOnRecently'
                    : 'SkillsMap/Recommendation/WorkedOnRecently'
                }
              >
                <SubtopicRecommendationCard
                  substrandSubtopicRecommendation={
                    workedOnRecentlyRecommendation
                  }
                  isShuffleable={workedOnRecentlyRecommendations.length > 1}
                  onShuffle={() => {
                    shuffleWorkedOnRecently(s => s + 1);
                  }}
                  urlToNavigateToWhenOutcomeIsClicked={
                    urlToNavigateToWhenOutcomeIsClicked
                  }
                  inDashboard={inDashboard}
                />
              </GridItem>
            )}
          </Grid>
        </>
      ) : (
        <StudentSkillsMapSubstrandRecommendationsEmpty
          strandId={data.lantern.substrand?.strand?.id}
          isCheckInToBeResumed={isCheckInToBeResumed}
          hasFinishedDiagnosticPhase={hasFinishedDiagnosticPhase}
          hideSkillsMapLink={hideSkillsMapLink}
        />
      )}
    </PageTimeRecorder>
  );
}
