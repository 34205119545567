import { useSnowplow } from 'ms-helpers/Snowplow';
import ErrorCard from 'ms-pages/Teacher/components/ErrorCard';
import { Anchor } from 'ms-pages/Teacher/components/Link';
import { ModalFooter, ModalTitle } from 'ms-pages/Teacher/components/Modal';
import Separator from 'ms-pages/Teacher/components/Separator';
import { borderRadiusUI, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';
import { helpUrlAddStudents, studentJoinClassUrl } from 'ms-utils/urls';

import CopyToClipboardButton from './CopyToClipboardButton';
import ResetClassCode from './ResetClassCode';
import type { ClassCodeModalQueryResponse } from './__generated__/ClassCodeModalQuery.graphql';

const Bold = styled({
  display: 'inline',
  fontWeight: fontWeight.semibold,
});
const CodeWrapper = styled({
  display: 'flex',
  alignItems: 'center',
});
const Code = styled({
  display: 'inline-flex',
  alignItems: 'center',
  height: 12 * BASE_UNIT,
  padding: `0 ${4 * BASE_UNIT}px`,
  border: `2px dashed ${colors.ironLight}`,
  borderRadius: borderRadiusUI,
});
const CodeTitle = styled({
  fontSize: 18,
  fontWeight: fontWeight.semibold,
});
const CodeDivider = styled({
  height: '100%',
  margin: `0 ${4 * BASE_UNIT}px`,
  borderLeft: `2px dashed ${colors.ironLight}`,
});
const CodeUrl = styled({
  textDecoration: 'underline',
});
type Props = {
  class: NonNullable<ClassCodeModalQueryResponse['class']>;
  closeModal: () => void;
};
export default function ClassCodeModal({ class: klass, closeModal }: Props) {
  const { trackStructEvent } = useSnowplow();
  if (klass.code == null) return <ErrorCard />;
  const deepLink = `${window.location.origin}${studentJoinClassUrl}?code=${klass.code}`;
  return (
    <>
      <ModalTitle large>Join class code</ModalTitle>
      <Separator size={2 * BASE_UNIT} />
      <div>
        To add students to your class, <Bold>share this code</Bold>. For more
        information,{' '}
        <Anchor
          href={helpUrlAddStudents}
          target="_blank"
          onClick={() =>
            trackStructEvent({
              category: 'teacher_admin',
              action: 'clicked_join_code_more_information',
            })
          }
        >
          click here
        </Anchor>
        .
      </div>
      <Separator size={3 * BASE_UNIT} />
      <CodeWrapper>
        <Code>
          <CodeTitle>{klass.code}</CodeTitle>
          <CodeDivider />
          <CodeUrl>{`https://mathspace.co${studentJoinClassUrl}`}</CodeUrl>
        </Code>
        <Separator size={4 * BASE_UNIT} />
        <CopyToClipboardButton link={deepLink} />
      </CodeWrapper>
      <ResetClassCode classId={klass.id} />
      <ModalFooter>
        <Button type="primary" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
}
