import EmptyIllustration from 'ms-pages/Textbooks/components/ContentCollection/components/EmptyIllustration';
import { fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const Root = styled({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: 6 * BASE_UNIT,
});

const Title = styled({
  fontSize: 18,
  color: colors.grayChateau,
  marginTop: 40,
  marginBottom: 20,
  textAlign: 'center',
  fontWeight: fontWeight.semibold,
});

const Description = styled({
  fontSize: 14,
  color: colors.grayChateau,
  textAlign: 'center',
  lineHeight: lineHeight.body,
});

export default function EmptyView() {
  return (
    <Root>
      <EmptyIllustration />

      <Title>Start adding questions</Title>

      <Description>
        Add questions from the textbook on the left by selecting the ‘+’ next to
        the question
      </Description>
    </Root>
  );
}
