/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type NavbarPopoverSkillsCollectedStartCheckInButtonQueryVariables = {
    strandId: string;
};
export type NavbarPopoverSkillsCollectedStartCheckInButtonQueryResponse = {
    readonly lantern: {
        readonly student: ({
            readonly __typename: "LanternStudent";
            readonly strandStatus: {
                readonly diagnosticQuestionsCompleted: number;
                readonly status: StudentStrandStatusEnum;
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type NavbarPopoverSkillsCollectedStartCheckInButtonQuery = {
    readonly response: NavbarPopoverSkillsCollectedStartCheckInButtonQueryResponse;
    readonly variables: NavbarPopoverSkillsCollectedStartCheckInButtonQueryVariables;
};



/*
query NavbarPopoverSkillsCollectedStartCheckInButtonQuery(
  $strandId: ID!
) {
  lantern {
    student: viewer {
      __typename
      ... on LanternStudent {
        strandStatus(strandId: $strandId) {
          diagnosticQuestionsCompleted
          status
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "strandId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "strandId",
    "variableName": "strandId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "diagnosticQuestionsCompleted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavbarPopoverSkillsCollectedStartCheckInButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": "student",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavbarPopoverSkillsCollectedStartCheckInButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": "student",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "StudentStrandStatus",
                    "kind": "LinkedField",
                    "name": "strandStatus",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "68fa28072ce2026e730069cf9db8e4d9",
    "id": null,
    "metadata": {},
    "name": "NavbarPopoverSkillsCollectedStartCheckInButtonQuery",
    "operationKind": "query",
    "text": "query NavbarPopoverSkillsCollectedStartCheckInButtonQuery(\n  $strandId: ID!\n) {\n  lantern {\n    student: viewer {\n      __typename\n      ... on LanternStudent {\n        strandStatus(strandId: $strandId) {\n          diagnosticQuestionsCompleted\n          status\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '97c39e8926ca06c188124ce7767c28d4';
export default node;
