/**
 * @module deserializer
 * @memberof math
 */

import { unwrap } from 'ms-utils/typescript-utils';

type RendererType = 'latex' | 'number';

type AstIntegerNode = {
  type: 'integer';
  value: number;
};

type AstFractionNode = {
  type: 'fraction';
  numerator: number;
  denominator: number;
};

type AstNode = AstIntegerNode | AstFractionNode;

/**
 * Tokenize a string
 * @param {string} source - A source string to be tokenized
 * @return {Array} Array of tokens (strings)
 */
function lex(source: string) {
  const sanitized = source.trim();
  const tokens = sanitized.split('/');

  if (tokens.length > 2 || tokens.length < 1) {
    throw new TypeError(
      `Only integers and fractions are supported at this time. You entered: ${source}`,
    );
  }

  return tokens;
}

/**
 * Parse an array of tokens as an abstract syntax tree
 * @param {Array} tokens = An array of tokens (strings)
 * @return {Object} An abstract syntax tree
 */
function parse(tokens: string[]): AstNode {
  // Map the tokens to an Abstract Syntax Tree.
  const ast = tokens.map(token => {
    const value = parseInt(token, 10);
    if (isNaN(value) || value % 1 !== 0) {
      throw new TypeError(
        'Fraction compilation only supports integer numerators and denominators at this time.',
      );
    }
    return value;
  });

  if (ast.length === 1) {
    return {
      type: 'integer',
      value: unwrap(ast[0]),
    };
  } else if (ast.length === 2) {
    return {
      type: 'fraction',
      numerator: unwrap(ast[0]),
      denominator: unwrap(ast[1]),
    };
  }
  throw new TypeError(
    `The tokens array ${tokens.join()} doesn't resemble an integer or a fraction.`,
  );
}

/**
 * Lookup table for rendering engines
 * @name RENDERERS
 */
const RENDERERS = {
  latex: (ast: AstNode): string => {
    if (ast.type === 'integer') {
      return `${ast.value}`;
    } else if (ast.type === 'fraction') {
      return `\\frac{${ast.numerator}}{${ast.denominator}}`;
    }
    throw new TypeError(
      `The Abstract Syntax Tree was not in a supported format:\n ${ast}`,
    );
  },

  number: (ast: AstNode): number => {
    if (ast.type === 'integer') {
      return ast.value;
    } else if (ast.type === 'fraction') {
      return ast.numerator / ast.denominator;
    }
    throw new TypeError(
      `The Abstract Syntax Tree was not in a supported format:\n${ast}`,
    );
  },
};

/**
 * Compile Fractions to Latex or JS number.
 *
 * This is a compilation engine for interpreting string-format numbers.
 *
 * At this time, the numbers supported are only integers and fractions with
 * integer numerator and denominators.  Decimal values are not accepted.
 *
 * e.g. "0", "1", "10", "12345", "1/2", "1/1", "0/1", "1/10", "12/3"
 *
 * @param {string} string - Source string to be deserialized
 * @param {string} renderer - The name of the rendering engine to use (from RENDERERS)
 * @return {number|string} The rendered interpretation of the source string
 */
function deserializer(string: string, renderer: RendererType): number | string {
  if (!{}.hasOwnProperty.call(RENDERERS, renderer)) {
    throw new TypeError(
      `Please select one of the available rendering engines: ${Object.keys(
        RENDERERS,
      ).join(' | ')}`,
    );
  }

  const tokens = lex(string);
  const ast = parse(tokens);
  const output = RENDERERS[renderer](ast);

  return output;
}

export default deserializer;
