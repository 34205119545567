import { StyleSheet, css } from 'aphrodite';
import { replace } from 'ramda';
import { useState } from 'react';

import type { Coordinate } from 'ms-utils/coordinate';
import type { Dataset } from 'ms-utils/dataset';
import genUniqueId from 'ms-utils/id-generator';
import * as tree from 'ms-utils/tree';
import type { Tree } from 'ms-utils/tree';
import { unwrap } from 'ms-utils/typescript-utils';

import { Menu } from './Menu';
import * as utils from './utils';
import type { Meta } from './utils';

export type Value = Tree<Meta>;
type Latex = string;

export type Props = {
  value?: Value | undefined;
  events?: ReadonlyArray<string> | undefined;
  width?: number | undefined;
  padding?: number | undefined;
  rowHeight?: number | undefined;
  inEqualProbability?: boolean | undefined;
  useLatexProbabilityInput?: boolean | undefined;
  readOnly?: boolean | undefined;
  onChange?: ((value: Tree<Meta>) => void) | undefined;
};

export default function ProbabilityTree({
  value = {
    meta: {
      id: genUniqueId(),
      label: '',
      probability: '',
    },
    children: [],
  },
  events = ['N'],
  width = 400,
  padding = 30,
  rowHeight = 60,
  inEqualProbability = false,
  useLatexProbabilityInput = false,
  readOnly = false,
  onChange,
}: Props) {
  // This state is used to model the behaviour of tapping on specific node
  // in the probability tree in order to display a menu for that specific node.
  type NodeMenu = { visible: true; nodeId: number } | { visible: false };
  const [nodeMenu, setNodeMenu] = useState<NodeMenu>({ visible: false });

  function showNodeMenu(nodeId: number) {
    setNodeMenu({ visible: true, nodeId });
  }

  function hideNodeMenu() {
    setNodeMenu({ visible: false });
  }

  function addNode(nodeId: number, option: string) {
    const updatedTree = tree.update(
      value,
      node => node.meta.id === nodeId,
      node => {
        const child = tree.create({
          id: genUniqueId(),
          label: option,
          probability: '',
          position: {
            position: [0, 0],
            meta: {},
          },
        });
        return {
          meta: node.meta,
          children: node.children.concat([child]),
        };
      },
    );
    onChange?.(updatedTree);
  }

  function removeNode(nodeId: number) {
    const updatedTree = tree.filter(value, node => node.meta.id !== nodeId);
    onChange?.(updatedTree);
  }

  function onProbabilityInputChange(
    nodeId: number,
    inputValue: string | Latex,
  ) {
    const updatedState = tree.update(
      value,
      node => node.meta.id === nodeId,
      node => ({
        children: node.children,
        meta: {
          ...node.meta,
          probability: useLatexProbabilityInput
            ? inputValue
            : replace(/[()]/g, '', inputValue), // an old way to clean the probability value
        },
      }),
    );
    onChange?.(updatedState);
  }

  const dimensions = tree.getDimensions(value);
  const height =
    rowHeight * (dimensions.meta.leafNodeCount - 1 || 1) + padding * 2;
  const colWidth = Math.round(
    (width - padding * 2) / (dimensions.meta.maxDepth - 1 || 1),
  );

  const nodes = utils.layoutNodes(value, colWidth, rowHeight);
  const edges: Dataset<Coordinate<any>> = utils.layoutEdges(nodes);
  const midpoints: Dataset<Coordinate<any>> = utils.layoutMidpoints(nodes);
  const nodeDataset: Dataset<Coordinate<any>> = tree.toDataset(nodes);

  // TODO this bespoke Menu component which is only consumed by this component
  // has a very poorly thought out API that forces all this gross unecessary
  // work. Push all this logic into the component, so we can just create
  // the element directly, inline, without all these shenanigans.
  let nodeMenuElement: JSX.Element | null = null;
  if (!readOnly && nodeMenu.visible) {
    const activeNode = utils.getNodeById(nodes, nodeMenu.nodeId);
    if (activeNode != null) {
      nodeMenuElement = (
        <Menu
          id={nodeMenu.nodeId}
          onAddNode={addNode}
          onHide={hideNodeMenu}
          onRemoveNode={removeNode}
          options={events}
          showRemove={nodeMenu.nodeId !== value.meta.id}
          x={unwrap(activeNode.meta.position[0])}
          y={unwrap(activeNode.meta.position[1])}
        />
      );
    }
  }

  const styles = StyleSheet.create({
    container: {
      position: 'relative',
      margin: padding,
    },
    svg: {
      WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
      height,
      width,
      margin: -padding, // offset the margin on the container
    },
  });

  return (
    <div className={css(styles.container)}>
      <svg className={css(styles.svg)}>
        <g transform={`translate(${padding},${padding})`}>
          {utils.renderTreeEdges(edges)}
          {utils.renderNodeLabels(nodeDataset)}
          {utils.renderTreeNodes(
            nodeDataset,
            nodeMenu.visible,
            readOnly,
            nodeMenu.visible ? nodeMenu.nodeId : undefined,
            showNodeMenu,
          )}
        </g>
      </svg>
      {inEqualProbability
        ? utils.renderProbability(
            midpoints,
            readOnly,
            useLatexProbabilityInput,
            onProbabilityInputChange,
          )
        : null}
      {nodeMenuElement}
    </div>
  );
}
