import MultiSubtopicDisplay from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/MultiSubtopicDisplay';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';

type ContentCollectionStepBodyProps = {
  selectedSubtopic: SelectedSubtopic | null | undefined;
  useTransientProblemsCollection?: boolean | undefined;
  isHeaderSticky?: boolean | undefined;
  stickyHeaderTopOffset?: number | undefined;
  hasSelfHorizontalPadding?: boolean | undefined;
};

export function ContentCollectionStepBody({
  selectedSubtopic,
  ...rest
}: ContentCollectionStepBodyProps) {
  return (
    <MultiSubtopicDisplay
      canCollectProblems
      selectedSubtopics={selectedSubtopic != null ? [selectedSubtopic[0]] : []}
      {...rest}
    />
  );
}
