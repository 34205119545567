import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const TeacherOverview = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={17}
    viewBoxHeight={16}
  >
    <g clipPath="url(#clip0_3233_23849)">
      <path d="M16.5 12V10.6667H12.1C12.1667 10.6 12.2 10.5667 12.2667 10.5C13.1333 9.56667 13.7 8.4 13.8 7.36667C13.9 6.33333 13.6 5.33333 12.9333 4.53333C12.2667 3.73333 11.3333 3.23333 10.3333 3.13333C9.93333 3.1 9.56667 3.13333 9.16667 3.2C9.23333 2.16667 10.1 1.33333 11.1667 1.33333V0C9.36667 0 7.9 1.43333 7.83333 3.23333C7.43333 3.16667 7.06667 3.13333 6.66667 3.16667C5.63333 3.26667 4.7 3.76667 4.06667 4.56667C3.43333 5.36667 3.1 6.33333 3.2 7.36667C3.3 8.36667 3.86667 9.53333 4.73333 10.5L4.9 10.6667H2.5C1.4 10.6667 0.5 11.5667 0.5 12.6667V14C0.5 15.1 1.4 16 2.5 16H16.5V14.6667C15.7667 14.6667 15.1667 14.0667 15.1667 13.3333C15.1667 12.6 15.7667 12 16.5 12ZM13.9333 14C14 14.2333 14.1 14.4667 14.2 14.6667H4.5V12H14.2C14.0667 12.2 14 12.4333 13.9333 12.6667H10.5V14H13.9333Z" />
    </g>
    <defs>
      <clipPath id="clip0_3233_23849">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);

export default TeacherOverview;
