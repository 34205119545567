import { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import {
  Body,
  BODY_PADDING,
} from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import CustomTaskQuestionsHeader from 'ms-pages/Teacher/components/CreateTask/flows/CustomTaskFlow/components/CustomTaskQuestionsHeader';
import ConfirmSaveChangesPrompt from 'ms-pages/Teacher/components/CreateTask/flows/TaskTemplateFlow/components/ConfirmSaveChangesPrompt';
import { ContentCollectionStepBody } from 'ms-pages/Teacher/components/CreateTask/flows/components/ContentCollectionStep';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import useMatchCollectedProblemsWithSubtopic from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useMatchCollectedProblemsWithSubtopic';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { CreateTaskTemplate } from 'ms-pages/Teacher/components/CreateTaskTemplate';
import useTaskTemplateStateWithSnackbar from 'ms-pages/Teacher/components/CreateTaskTemplate/state/useCreateTaskTemplateWithSnackbar';
import useEditTaskTemplateWithSnackbar from 'ms-pages/Teacher/components/CreateTaskTemplate/state/useEditTaskTemplateWithSnackbar';
import useIsTaskTemplateChanged from 'ms-pages/Teacher/components/CreateTaskTemplate/state/useIsTaskTemplateChanged';
import { assignableSubtopicForExercise } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import ContentList from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/ContentList';
import {
  StateContext as ProblemsCollectionState,
  UpdatersContext as ProblemsCollectionUpdaters,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { colors } from 'ms-styles/colors';
import { styledVerticallyScrollable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { TaskTemplateFlowQuery } from './__generated__/TaskTemplateFlowQuery.graphql';

const TASK_TEMPLATE_FLOW_QUERY = graphql`
  query TaskTemplateFlowQuery($syllabusId: ID!) {
    syllabus(id: $syllabusId) {
      topics(first: 1000) {
        edges {
          node {
            id
            subtopics(first: 1000) {
              edges {
                node {
                  id
                  title
                  hasExercise
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function TaskTemplateFlowInner({
  taskTemplateCreationCallback,
  currentSyllabusId,
  setCurrentSyllabusId,
  prefilledProblems,
  prefilledTaskTemplateTitle,
  prefilledTaskTemplateDescription,
  prefilledTaskTemplateGroupId,
  prefilledTaskTemplateId,
  prefilledTaskTemplateAuthorName,
  prefilledTaskTemplateAuthorId,
  prefilledSubtopic,
  isEditingTemplate = false,
  isDuplicatingTemplate = false,
  onSetShouldShowDiscardChangesPromptOnAbort,
}: {
  taskTemplateCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
  prefilledProblems?: readonly Problem[] | undefined;
  prefilledTaskTemplateTitle?: string | null | undefined;
  prefilledTaskTemplateDescription?: string | null | undefined;
  prefilledTaskTemplateGroupId?: string | undefined;
  prefilledTaskTemplateId?: string | undefined;
  prefilledTaskTemplateAuthorId?: string | undefined;
  prefilledTaskTemplateAuthorName?: string | undefined;
  prefilledSubtopic?: SelectedSubtopic | null | undefined;
  isEditingTemplate?: boolean | undefined;
  isDuplicatingTemplate?: boolean | undefined;
  onSetShouldShowDiscardChangesPromptOnAbort: (
    shouldShowDiscardChangesPrompt: boolean,
  ) => void;
}) {
  const { userId } = useViewer();
  const isTeacherOriginalAuthorOfEditedTemplate =
    prefilledTaskTemplateAuthorId === userId;

  const isSaveChangesPromptOpen = useBoolean(false);

  const shouldUsePrefilledProblems = prefilledProblems != null;
  const shouldUseTransientProblemsCollection =
    isEditingTemplate || isDuplicatingTemplate;
  // When using transient collection,
  // we want to preserve the problem contents of the main cart
  // if user "saves as new" or "creates" a template
  // after the template is created
  // Adding this constant specifically to be more verbose about this condition
  const shouldPreserveMainCartAfterCreation =
    shouldUseTransientProblemsCollection;
  const [prefilledProblemsState, setPrefilledProblemsState] = useState(
    shouldUsePrefilledProblems ? prefilledProblems : [],
  );

  const {
    problemsCollection: _problemsCollection,
    transientProblemsCollection,
  } = useContext(ProblemsCollectionState);
  const problemsCollection = shouldUseTransientProblemsCollection
    ? transientProblemsCollection
    : shouldUsePrefilledProblems
    ? prefilledProblemsState
    : _problemsCollection;

  const { clearCart: _clearCart } = useContext(ProblemsCollectionUpdaters);
  const clearCart = useCallback(() => {
    if (shouldUsePrefilledProblems) {
      setPrefilledProblemsState([]);
    } else {
      _clearCart(shouldUseTransientProblemsCollection ? 'transient' : 'main');
    }
  }, [
    _clearCart,
    shouldUseTransientProblemsCollection,
    shouldUsePrefilledProblems,
  ]);

  const { stepNumber } = useCreateTaskState();
  const dispatch = useCreateTaskDispatch();

  const [activeSubtopic, setActiveSubtopic] = useState<
    SelectedSubtopic | null | undefined
  >(prefilledSubtopic);

  const { schoolId } = useMaybeTeacherContext();

  const problemContentIds = useMemo(
    () => problemsCollection.map(pc => pc[0]),
    [problemsCollection],
  );

  const [taskTemplateFormState, [createTaskTemplate]] =
    useTaskTemplateStateWithSnackbar(
      {
        schoolId,
        problemContentIds,
        initialTaskTemplatedGroupId: prefilledTaskTemplateGroupId,
        initialTaskTemplateTitle: prefilledTaskTemplateTitle,
        initialTaskTemplateDescription: prefilledTaskTemplateDescription,
      },
      taskTemplateCreationCallback,
      shouldPreserveMainCartAfterCreation,
    );

  const { editTaskTemplate } = useEditTaskTemplateWithSnackbar({
    taskTemplateFormState,
    problemContentIds,
    templateId: prefilledTaskTemplateId || '',
    taskTemplateCreationCallback,
  });

  const isTemplateChanged = useIsTaskTemplateChanged({
    taskTemplateFormState,
    problemContentIds,
  });

  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();

  const { props } = useQuery<TaskTemplateFlowQuery>(TASK_TEMPLATE_FLOW_QUERY, {
    syllabusId: currentSyllabusId,
  });

  const matchedSubtopic = useMatchCollectedProblemsWithSubtopic({
    data: props,
    problemsCollection,
  });

  useEffect(() => {
    if (matchedSubtopic != null && activeSubtopic == null) {
      setActiveSubtopic([matchedSubtopic.id, matchedSubtopic.title]);
    }
  }, [activeSubtopic, matchedSubtopic]);

  // If the user is on the preview step and the problems collection is empty,
  // go back to the problem selection step
  useEffect(() => {
    if (
      stepNumber === 1 &&
      problemsCollection.length === 0 &&
      // we don't let users go back to the problem selection step when problems are prefilled
      // because problem selection step only adds problems to the problems collection at the moment
      !shouldUsePrefilledProblems
    ) {
      dispatch({ type: 'backward' });
    }
  }, [
    dispatch,
    problemsCollection.length,
    shouldUsePrefilledProblems,
    stepNumber,
  ]);

  useEffect(() => {
    if (shouldUseTransientProblemsCollection) {
      onSetShouldShowDiscardChangesPromptOnAbort(isTemplateChanged);
    }
  }, [
    isTemplateChanged,
    onSetShouldShowDiscardChangesPromptOnAbort,
    shouldUseTransientProblemsCollection,
  ]);

  const stepNames = STEP_NAME_MAPS.taskTemplate();

  const isTitleMissing = !taskTemplateFormState.values.title;

  const nextButtonDisabledStates = {
    question_selector: problemsCollection.length === 0,
    preview_and_task_details: isEditingTemplate
      ? !isTemplateChanged || (isTemplateChanged && isTitleMissing)
      : isTitleMissing,
  };

  const createTaskTemplateAction = useCallback(() => {
    createTaskTemplate();
    dispatch({ type: 'create' });
  }, [createTaskTemplate, dispatch]);

  const editTemplateAction = useCallback(() => {
    editTaskTemplate();
    dispatch({ type: 'create' });
  }, [editTaskTemplate, dispatch]);

  const editTemplateActionWithPrompt = useCallback(() => {
    if (isTeacherOriginalAuthorOfEditedTemplate) {
      editTemplateAction();
    } else {
      isSaveChangesPromptOpen.setTrue();
    }
  }, [
    editTemplateAction,
    isSaveChangesPromptOpen,
    isTeacherOriginalAuthorOfEditedTemplate,
  ]);

  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({
          selectedFlow: 'taskTemplate',
          isEditingTemplate,
        })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={
          isEditingTemplate
            ? editTemplateActionWithPrompt
            : createTaskTemplateAction
        }
        numberOfSelections={problemsCollection.length}
        hideStepNumber={shouldUsePrefilledProblems}
        hidePreviousButtonOnSecondStep={shouldUsePrefilledProblems}
        hidePreviousButtonOnFirstStep={shouldUseTransientProblemsCollection}
        onSecondaryLastStepAction={
          isEditingTemplate ? createTaskTemplate : undefined
        }
        secondaryLastStepActionLabel={
          isEditingTemplate ? 'Save as new' : undefined
        }
        hasLargeButtonOnFirstStep={isEditingTemplate}
      />
      {stepNumber === 0 && (
        <SidebarAndPreview
          taskType="taskTemplate"
          activeSubtopic={activeSubtopic}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          subtopicFilter={assignableSubtopicForExercise}
          noHorizontalSubtopicBodyPadding
        >
          <ContentCollectionStepBody
            selectedSubtopic={activeSubtopic}
            useTransientProblemsCollection={
              shouldUseTransientProblemsCollection
            }
            isHeaderSticky
            stickyHeaderTopOffset={-BODY_PADDING}
            hasSelfHorizontalPadding
          />
        </SidebarAndPreview>
      )}
      {stepNumber === 1 && (
        <Body
          isVerticallyScrollable={false}
          isVerticalOverflowHidden
          whiteBackground={false}
          style={{
            backgroundColor: colors.seashell,
            padding: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '55%',
                borderRight: `1px solid ${colors.ironLight}`,
                overflowY: 'hidden',
                backgroundColor: colors.white,
              }}
            >
              <div style={{ padding: 16 }}>
                <CustomTaskQuestionsHeader
                  numberOfQuestions={problemsCollection.length}
                  problems={problemsCollection}
                  onRemoveAll={clearCart}
                  hideClearAllButton={shouldUsePrefilledProblems}
                  questionsCollapsed={questionsCollapsed}
                  setQuestionsCollapsed={setQuestionsCollapsed}
                />
              </div>
              <div
                style={{
                  paddingRight: 16,
                  paddingLeft: 16,
                  overflowY: 'auto',
                }}
              >
                <ContentList
                  collapsedInBulk={questionsCollapsed}
                  showTryProblem
                  prefilledProblems={
                    shouldUsePrefilledProblems
                      ? prefilledProblemsState
                      : undefined
                  }
                  setPrefilledProblems={
                    shouldUsePrefilledProblems
                      ? setPrefilledProblemsState
                      : undefined
                  }
                  useTransientProblemsCollection={
                    shouldUseTransientProblemsCollection
                  }
                />
              </div>
            </div>
            <div
              style={{
                width: '45%',
                padding: 16,
                ...styledVerticallyScrollable,
              }}
            >
              <CreateTaskTemplate
                state={taskTemplateFormState}
                templateAuthorName={prefilledTaskTemplateAuthorName}
              />
            </div>
          </div>
        </Body>
      )}
      <ConfirmSaveChangesPrompt
        isOpen={isSaveChangesPromptOpen.value}
        onClose={isSaveChangesPromptOpen.setFalse}
        action={editTemplateAction}
        templateAuthorName={prefilledTaskTemplateAuthorName}
      />
    </>
  );
}
