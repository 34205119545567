import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import localStorageDb from 'ms-utils/localStorageDb';
import { dashboardRedirectUrl } from 'ms-utils/urls';

const getLastStudentViewKey = (userId: string) =>
  `lastStudentView_v0_${userId}`;
// we use this to record the last student main views (Dashboard, tasks page, skills map (see ms-pages/Lantern/views/Student/StudentApp) and textbook) url so that we can redirect to it when they close the workbook or a check-in
export default function RecordLastStudentViewUrl() {
  const { userId } = useViewer();
  const { pathname } = useLocation();

  useEffect(() => {
    localStorageDb.set(getLastStudentViewKey(userId), pathname);
  }, [pathname, userId]);
  return null;
}

export function useRecordedLastStudentViewUrl() {
  const { userId } = useViewer();
  return (
    localStorageDb.get(getLastStudentViewKey(userId)) ?? dashboardRedirectUrl
  );
}
