import { css, StyleSheet } from 'aphrodite';
import { max } from 'ramda';

type Props = {
  className: string;
  height: number;
  stroke: string;
  strokeWidth?: number | undefined;
  transform?: string | undefined;
  width: number;
  x: number;
  y: number;
  fill?: string | undefined;
};

/**
 * This is an abstraction of the svg <rect> tag, which allows us to get more
 * desirable rendering behaviour of strokes.
 *
 * By default, in SVGs, strokes are centered on the boundary of a path.  In
 * this component, our stroke is inset from the path defined by the input
 * props.
 */
const Rect = ({
  className,
  height,
  stroke,
  strokeWidth = 0,
  transform = '',
  width,
  x,
  y,
  fill,
}: Props) => {
  const rectHeight = max(height - strokeWidth * 2, 0);
  const rectWidth = max(width - strokeWidth * 2, 0);

  const styles = StyleSheet.create({
    outer: {
      height,
      width,
    },
    inner: {
      height: rectHeight,
      width: rectWidth,
    },
  });

  return (
    <g transform={transform}>
      <rect
        className={css(styles.outer)}
        fill={stroke}
        height={height}
        width={width}
        x={x}
        y={y}
      />
      <rect
        className={`${className} ${css(styles.inner)}`}
        height={rectHeight}
        width={rectWidth}
        x={x + strokeWidth}
        y={y + strokeWidth}
        fill={fill}
      />
    </g>
  );
};

export default Rect;
