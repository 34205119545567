// https://emailregex.com/
const VALID_EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (candidate: string) =>
  VALID_EMAIL_REGEX.test(candidate);

/**
 * Partially masks the email address by obscuring all but the first two characters
 *
 * @param email {string} - The email address to partially mask
 */
export function partialMask(email: string): string {
  return email.substring(0, 2) + email.substring(2).replace(/./g, '*');
}
