import { useState, useEffect, Fragment } from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { ClickableTab, TabContent, Tabs } from 'ms-components/Tabs';
import { useSnowplow } from 'ms-helpers/Snowplow';
import ClassSelector, {
  type Props as ClassSelectorProps,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector/ClassSelector';
import StudentSelector, {
  type Props as StudentSelectorProps,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector/StudentSelector';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Input from 'ms-ui-primitives/Input';
import { styled } from 'ms-utils/emotion';

import type {
  SelectorsWithSearch_teacher$data,
  SelectorsWithSearch_teacher$key,
} from './__generated__/SelectorsWithSearch_teacher.graphql';

type ExternalProps = {
  listHeight: number;
  onClassSelect: ClassSelectorProps['onClick'];
  onStudentSelect: StudentSelectorProps['onClick'];
  selectedStudents: ReadonlyArray<string>;
  selectedClasses: ReadonlyArray<string>;
};
type FragmentProps = {
  teacher: SelectorsWithSearch_teacher$data;
};
type Props = ExternalProps & FragmentProps;
const SearchInput = styled({
  marginBottom: 2 * BASE_UNIT,
  marginTop: 2 * BASE_UNIT,
});
const SelectorsWithSearch = ({
  teacher,
  listHeight,
  selectedStudents,
  selectedClasses,
  onClassSelect,
  onStudentSelect,
}: Props) => {
  const { trackStructEvent } = useSnowplow();
  const [searchTerm, setSearch] = useState('');
  const [selectedTab, selectTab] = useState<'classes' | 'students'>('classes');
  useEffect(() => {
    setSearch('');
  }, [selectedTab]);
  return (
    <Fragment>
      <Tabs>
        <ClickableTab
          active={selectedTab === 'classes'}
          onClick={() => selectTab('classes')}
          evenlyFillSpace
        >
          <TabContent>Classes</TabContent>
        </ClickableTab>
        <ClickableTab
          active={selectedTab === 'students'}
          onClick={() => selectTab('students')}
          evenlyFillSpace
        >
          <TabContent>All students</TabContent>
        </ClickableTab>
      </Tabs>
      <SearchInput>
        <Input
          placeholder={
            selectedTab === 'classes'
              ? 'Search for a class'
              : 'Search for a student'
          }
          value={searchTerm}
          onChange={e => {
            setSearch(e.target.value);
            trackStructEvent(
              selectedTab === 'classes'
                ? {
                    category: 'create_task_from_anywhere',
                    action: 'searched_for_class',
                    label: e.target.value,
                  }
                : {
                    category: 'create_task_from_anywhere',
                    action: 'searched_for_student',
                    label: e.target.value,
                  },
            );
          }}
        />
      </SearchInput>
      {selectedTab === 'students' ? (
        <StudentSelector
          selectedStudents={selectedStudents}
          onClick={onStudentSelect}
          listHeight={listHeight}
          searchTerm={searchTerm}
          teacher={teacher}
        />
      ) : (
        <ClassSelector
          selectedClasses={selectedClasses}
          onClick={onClassSelect}
          listHeight={listHeight}
          searchTerm={searchTerm}
          teacher={teacher}
        />
      )}
    </Fragment>
  );
};
export default function SelectorsWithSearchContainer(
  props: ExternalProps & {
    teacher: SelectorsWithSearch_teacher$key;
  },
) {
  const data = useFragment(
    graphql`
      fragment SelectorsWithSearch_teacher on Teacher
      @argumentDefinitions(searchTerm: { type: "String", defaultValue: "" }) {
        ...ClassSelector_teacher
        ...StudentSelector_teacher @arguments(searchTerm: $searchTerm)
      }
    `,
    props.teacher,
  );
  return (
    <SelectorsWithSearch
      teacher={data}
      listHeight={props.listHeight}
      selectedStudents={props.selectedStudents}
      selectedClasses={props.selectedClasses}
      onClassSelect={props.onClassSelect}
      onStudentSelect={props.onStudentSelect}
    />
  );
}
