import { useId } from 'react';

export function TrophySvg({ style }: { style?: React.CSSProperties }) {
  const filterId = useId();
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M21.4399 27.7621C21.8668 27.7621 22.2849 27.6157 22.6213 27.3408C23.0552 26.9863 23.307 26.4555 23.307 25.8952V6.36281C23.307 5.33174 22.4711 4.49588 21.44 4.49588H3.14895C2.16765 4.49588 1.26039 4.94093 0.659946 5.71707C0.0593033 6.49312 -0.143925 7.48305 0.102264 8.43297L2.25936 16.755C3.40448 21.1723 6.86351 24.613 11.2868 25.7342C11.3156 25.7415 11.3446 25.7481 11.3737 25.754L21.0681 27.7247C21.1914 27.7498 21.316 27.7621 21.4399 27.7621ZM19.5729 8.22974V23.6105L12.1632 22.1043C9.08083 21.3072 6.67308 18.9021 5.87368 15.8181L3.90671 8.22984L19.5729 8.22974Z"
        fill="#FFC250"
      />
      <path
        d="M30.5602 27.762C30.6841 27.762 30.8085 27.7497 30.9321 27.7246L40.6265 25.7539C40.6555 25.748 40.6845 25.7413 40.7132 25.7341C45.1366 24.6127 48.5957 21.1722 49.7406 16.7548L51.8978 8.43286C52.1441 7.48304 51.941 6.49311 51.3403 5.71707C50.7398 4.94092 49.8325 4.49577 48.8512 4.49577H30.5602C29.5291 4.49577 28.6932 5.33164 28.6932 6.3627V25.8951C28.6932 26.4554 28.9449 26.9862 29.3789 27.3407C29.715 27.6156 30.1333 27.762 30.5602 27.762ZM39.8368 22.1041L32.427 23.6104V8.22963H48.0932L46.1262 15.8179C45.3269 18.902 42.9191 21.307 39.8368 22.1041Z"
        fill="#FFC250"
      />
      <path
        d="M19.6073 42.0009C24.4527 40.4015 27.8298 35.4196 28.009 29.6871C27.8137 28.7813 27.7115 27.8447 27.7115 26.8896V20.5533H29.0733V26.8896C29.0733 32.7975 32.982 37.9927 38.6587 39.6297V43.4688H18.4312V42.3892L19.6073 42.0009Z"
        fill="#FFC250"
      />
      <path
        d="M14.5172 39.2906C19.4973 37.8545 22.9264 33.2968 22.9264 28.1137V20.5533H29.0732V26.8896C29.0732 27.8448 29.1754 28.7813 29.3707 29.6871C29.1915 35.4196 25.8144 40.4015 20.969 42.0009L19.7928 42.3892V43.4688H13.341V39.6297L14.5172 39.2906Z"
        fill="#FFC250"
      />
      <path
        d="M17.0038 39.5887H40.8623C41.7927 39.5887 42.547 40.3429 42.547 41.2734V49.7325H15.3192V41.2734C15.3191 40.3429 16.0734 39.5887 17.0038 39.5887Z"
        fill="#FFCE30"
      />
      <path
        d="M11.1374 39.5887H17.8781C16.9477 39.5887 16.1934 40.343 16.1934 41.2734V49.7325H9.45267V41.2734C9.45277 40.343 10.207 39.5887 11.1374 39.5887Z"
        fill="#FFCE31"
      />
      <g filter={`url(#${filterId})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.711 29.8572C26.7337 30.6828 24.4699 30.5669 22.5731 29.5095C16.0331 25.8632 11.9794 18.9624 11.9794 11.4746V2.34767H18.7354L40.0204 2.34768V11.4746C40.0204 18.9624 35.9668 25.8634 29.4269 29.5095C29.1934 29.6396 28.9544 29.7555 28.711 29.8572Z"
          fill="#FFCE30"
        />
      </g>
      <path
        d="M11.9564 51.1292V50.0744C11.9564 49.043 12.7922 48.2069 13.8234 48.2064H44.0602C45.0918 48.2064 45.9282 49.0427 45.9282 50.0744C45.9282 51.1061 45.0918 51.9425 44.0602 51.9425H12.8309L11.9564 51.1292Z"
        fill="#FEC250"
      />
      <path
        d="M7.93945 48.2059H14.6987C13.667 48.2059 12.8306 49.0422 12.8306 50.0739V51.942H7.93945C6.90778 51.942 6.0714 51.1056 6.0714 50.0739C6.0714 49.0422 6.90778 48.2059 7.93945 48.2059Z"
        fill="#FFC250"
      />
      <path
        d="M8.0976 2.05301V0.713217L9.85291 0.0573425H41.3275C42.8134 0.0573425 44.0179 0.950779 44.0179 2.05291C44.0179 3.15505 42.8134 4.04849 41.3275 4.04849H10.788C9.30225 4.04867 8.0976 3.15514 8.0976 2.05301Z"
        fill="#FFB816"
      />
      <path
        d="M16.1936 44.8785H35.8063C36.2453 44.8785 36.6012 44.5226 36.6012 44.0835C36.6012 43.6445 36.2453 43.2886 35.8063 43.2886H16.1936C15.7546 43.2886 15.3987 43.6445 15.3987 44.0835C15.3987 44.5226 15.7546 44.8785 16.1936 44.8785Z"
        fill="#F4FBFF"
      />
      <path
        d="M25.3925 9.93729L24.22 13.1492C24.1298 13.3963 23.8988 13.5642 23.6358 13.5737L20.2187 13.6962C19.6162 13.7179 19.3685 14.4798 19.8433 14.8514L22.5358 16.9591C22.743 17.1212 22.8312 17.3928 22.7589 17.6457L21.8196 20.9334C21.6539 21.5132 22.302 21.9841 22.8023 21.6474L25.6388 19.7381C25.8571 19.5912 26.1426 19.5912 26.3608 19.7381L29.1974 21.6474C29.6976 21.9841 30.3458 21.5132 30.1801 20.9334L29.2408 17.6457C29.1685 17.3927 29.2567 17.1211 29.4639 16.9591L32.1563 14.8514C32.6311 14.4797 32.3836 13.7179 31.781 13.6962L28.3639 13.5737C28.1009 13.5642 27.87 13.3964 27.7797 13.1492L26.6071 9.93729C26.4005 9.37088 25.5993 9.37088 25.3925 9.93729Z"
        fill="#F4FBFF"
      />
      <defs>
        <filter
          id={filterId}
          x="11.9794"
          y="-0.652328"
          width="28.041"
          height="31.0525"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.802181 0 0 0 0 0.481309 0 0 0 0 0 0 0 0 0.43 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_0_2654"
          />
        </filter>
      </defs>
    </svg>
  );
}
