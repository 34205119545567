import type { ReactNode } from 'react';

import FieldTitle from 'ms-pages/Teacher/components/FormFieldTitle';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const Root = styled({
  marginBottom: 6 * BASE_UNIT,
});

type Props = {
  children: ReactNode;
  isFieldRequired?: boolean | undefined;
  title?: string | undefined;
  validationAdvice?: string | undefined;
  description?: ReactNode | undefined;
  infoTooltipText?: string | undefined;
};

const FieldGroup = ({
  children,
  isFieldRequired = false,
  title,
  validationAdvice,
  description,
  infoTooltipText,
}: Props) => (
  <Root>
    {title && (
      <FieldTitle
        isFieldRequired={isFieldRequired}
        title={title}
        validationAdvice={validationAdvice}
        description={description}
        infoTooltipText={infoTooltipText}
      />
    )}
    {children}
  </Root>
);

export default FieldGroup;
