import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import BoxPlotReadOnly from 'ms-components/math/BoxPlotReadOnly';
import GeometryReasonReadOnly from 'ms-components/math/GeometryReasonReadOnly';
import GraphPlotReadOnly from 'ms-components/math/GraphPlotReadOnly';
import HistogramReadOnly from 'ms-components/math/HistogramReadOnly';
import LatexAnswerReadOnly from 'ms-components/math/LatexAnswerReadOnly';
import MultipleChoiceReadOnly from 'ms-components/math/MultipleChoiceReadOnly';
import type { ItemType } from 'ms-components/math/NumberBuilder/index';
import NumberBuilderReadOnly from 'ms-components/math/NumberBuilderReadOnly';
import NumberLineIntervalsReadOnly from 'ms-components/math/NumberLineIntervalsReadOnly';
import ProbabilityTreeReadOnly from 'ms-components/math/ProbabilityTreeReadOnly';
import { deserializeCoordinateValues } from 'ms-components/math/private-shared/legacyTransformers/numberLine';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import type {
  AnswerAttempt_data,
  AnswerAttempt_data$key,
} from './__generated__/AnswerAttempt_data.graphql';
import { toInternalValueFormat as toGraphPlotInternalValueFormat } from '../utils/GraphPlot';
import { toInternalValueFormat as toLatexAnswerInternalValueFormat } from '../utils/LatexAnswer';
import { toInternalValueFormat as toMultipleChoiceInternalValueFormat } from '../utils/MultipleChoice';
import { toInternalValueFormat as toNumberBuilderInternalValueFormat } from '../utils/NumberBuilder';
import {
  toInternalValueFormat as toNumberLineInternalValueFormat,
  toInternalMode,
  graphQlUnitEnumToLatex,
} from '../utils/NumberLine';
import {
  ROW_HEIGHT as PROBABILITY_TREE_ROW_HEIGHT,
  PADDING as PROBABILITY_TREE_PADDING,
  WIDTH as PROBABILITY_TREE_WIDTH,
  toInternalValueFormat as toProbabilityTreeInternalValueFormat,
  prepareInputValue as prepareProbabilityTreeValue,
} from '../utils/ProbabilityTree';

function assertIsItemType(value: string): asserts value is ItemType {
  if (value !== 'blocks' && value !== 'counter') {
    throw Error(
      `Invalid ItemType, received ${value} but expected 'blocks' or 'counter'`,
    );
  }
}
const AnswerAttemptPresentational = ({
  data,
  isLastAnswer,
  correctAnswerFoundByStudent,
}: {
  data: AnswerAttempt_data;
  isLastAnswer?: boolean | undefined;
  correctAnswerFoundByStudent?: boolean | undefined;
}) => {
  const { __typename } = data;
  switch (__typename) {
    case 'LegacyGraphPlotConfig': {
      return (
        <GraphPlotReadOnly
          datum={toGraphPlotInternalValueFormat(data.requestDatum)}
        />
      );
    }
    case 'LatexAnswerConfig': {
      return (
        <LatexAnswerReadOnly
          template={data.template}
          {...toLatexAnswerInternalValueFormat(data.fields)}
        />
      );
    }
    case 'MultipleChoiceConfig': {
      // Here we have the data for the answer attempt, which will tell us
      // if we have a marked answer.  From that, we can determine
      // whether we must show text about correct answers or not, and
      // which answers to highlight, and then pass that down.
      //
      // A MarkedAnswer with correctness of CORRECT_FINAL, or an SuppliedAnswer:
      // We should give data indicating that we have a final answer.
      const isAnswerAttempt = !isLastAnswer;
      return (
        <MultipleChoiceReadOnly
          isAnswerAttempt={isAnswerAttempt}
          isLastAnswer={isLastAnswer}
          correctAnswerFoundByStudent={correctAnswerFoundByStudent}
          hasMultipleAnswers={data.hasMultipleAnswers}
          {...toMultipleChoiceInternalValueFormat(data)}
        />
      );
    }
    case 'NumberBuilderConfig': {
      // TODO data.value should never be null for a MarkedAnswer/SuppliedAnswer
      // but I think because we are sharing the same NumberBuilderConfig type
      // all over the place the value field is nullable. Need to verify with Yen
      // whether this is the case, and if there's something we can do about it.
      if (data.value == null) return null;
      assertIsItemType(data.itemType);
      return (
        <NumberBuilderReadOnly
          {...toNumberBuilderInternalValueFormat(data)}
          dropZones={data.dropZones}
          itemType={data.itemType}
        />
      );
    }
    case 'HistogramConfig': {
      return (
        <HistogramReadOnly
          value={data.histogramValues}
          doesDrawBars={data.doesDrawBars}
          hasGapBetweenBars={data.hasGapBetweenBars}
          doesDrawLines={data.doesDrawLines}
          // TODO: Update histogram dragHandleAlignment to use upercase
          dragHandleAlignment={
            data.dragHandleAlignment === 'CENTER' ? 'center' : 'right'
          }
          labels={{
            main: data.mainLabel,
            xAxis: data.xAxisLabel,
            yAxis: data.yAxisLabel,
          }}
          increment={{
            min: data.yMin,
            max: data.yMax,
            step: data.yIncrement,
            // NOTE: this needs to be static for now. Related to yTickMultiplier bug
            tick: 5,
          }}
        />
      );
    }
    case 'NumberLineConfig': {
      return (
        <NumberLineIntervalsReadOnly
          {...toNumberLineInternalValueFormat(data.numberLineValues)}
          start={deserializeCoordinateValues(data.start)}
          end={deserializeCoordinateValues(data.end)}
          mode={toInternalMode[data.mode]}
          majorTicks={deserializeCoordinateValues(data.majorTicks)}
          minorTicks={deserializeCoordinateValues(data.minorTicks)}
          unit={graphQlUnitEnumToLatex(data.unit)}
        />
      );
    }
    case 'BoxPlotConfig': {
      return (
        <BoxPlotReadOnly
          axisTitle={data.axisTitle}
          axisMin={data.axisMin}
          axisMax={data.axisMax}
          axisMajorTickInterval={data.axisMajorTickInterval}
          axisMinorTickInterval={data.axisMinorTickInterval}
          value={{
            min: data.min,
            q1: data.q1,
            median: data.median,
            q3: data.q3,
            max: data.max,
          }}
        />
      );
    }
    case 'ProbabilityTreeConfig': {
      return (
        <ProbabilityTreeReadOnly
          {...toProbabilityTreeInternalValueFormat(
            prepareProbabilityTreeValue({ tree: data.tree }),
          )}
          events={data.availableNodeLabels}
          inEqualProbability={data.areProbabilitiesEditable}
          rowHeight={PROBABILITY_TREE_ROW_HEIGHT}
          padding={PROBABILITY_TREE_PADDING}
          width={PROBABILITY_TREE_WIDTH}
          useLatexProbabilityInput
        />
      );
    }
    case 'GeometryReasonConfig': {
      return (
        <GeometryReasonReadOnly
          selectedReason={data.selectedReason}
          statement={data.statement}
        />
      );
    }
    default:
      if (__typename === '%other') throw Error(); // type narrowing
      assertUnreachable(__typename);
  }
};
function AnswerAttempt({
  data: dataKey,
  isLastAnswer,
  correctAnswerFoundByStudent,
}: {
  data: AnswerAttempt_data$key;
  isLastAnswer?: boolean | undefined;
  correctAnswerFoundByStudent?: boolean | undefined;
}) {
  const data = useFragment(
    graphql`
      fragment AnswerAttempt_data on AnswerInputConfig {
        ...subproblemFragments_FullInputConfiguration @relay(mask: false)
      }
    `,
    dataKey,
  );
  return (
    <AnswerAttemptPresentational
      data={data}
      isLastAnswer={isLastAnswer}
      correctAnswerFoundByStudent={correctAnswerFoundByStudent}
    />
  );
}
export { AnswerAttemptPresentational };
export default AnswerAttempt;
