import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import Layout from 'ms-components/Layout/Layout';
import StudentNavbar from 'ms-components/StudentNavbar';
import { colors } from 'ms-styles/colors';
import { HEADER_SIZE } from 'ms-styles/theme/Numero';

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
  backgroundColor: colors.almond50,
});

type Props = {
  children: ReactNode;
  isOnboarding?: boolean;
};

export function StudentLayout({ children, isOnboarding = false }: Props) {
  return (
    <>
      <Layout direction="column">
        {!isOnboarding && (
          <Layout.Fixed size={HEADER_SIZE}>
            <StudentNavbar />
          </Layout.Fixed>
        )}
        <Layout.Fill>
          <Wrapper>{children}</Wrapper>
        </Layout.Fill>
      </Layout>
    </>
  );
}
