import { StyleSheet, css } from 'aphrodite';
import { map, multiply, range } from 'ramda';

import type { IncrementData, SpacingData } from '../index';
import { getYScreenValue } from '../utils';

const fontSize = 15;

const styles = StyleSheet.create({
  guideline: {
    stroke: '#bbb',
    strokeWidth: 1,
  },

  tickMark: {
    stroke: '#7e7e7e',
    strokeWidth: 1,
  },

  label: {
    cursor: 'default',
    fontSize,
    userSelect: 'none',
  },
});

type Props = {
  spacing: SpacingData;
  increment: IncrementData;
};

const Increments = ({ increment, spacing }: Props) => {
  const tickMarkWidth = 10;

  return (
    <g transform={`translate(${-tickMarkWidth},${spacing.minBound})`}>
      {map(
        multiply(increment.step),
        range(
          increment.min / increment.step + 1,
          increment.max / increment.step + 1,
        ),
      ).map(i => {
        const yScreenValue = getYScreenValue(i, increment, spacing);
        return i % increment.tick === 0 ? (
          <g key={`increment_${i}`}>
            <text
              className={css(styles.label)}
              textAnchor="middle"
              transform={`translate(${spacing.minBound - tickMarkWidth},${
                yScreenValue + fontSize / 3
              })`}
            >
              {i}
            </text>
            <line
              className={css(styles.tickMark)}
              x1={spacing.minBound}
              y1={yScreenValue - 0.5}
              x2={spacing.minBound + tickMarkWidth}
              y2={yScreenValue - 0.5}
            />
            <line
              className={css(styles.guideline)}
              x1={spacing.minBound + tickMarkWidth}
              y1={yScreenValue - 0.5}
              x2={spacing.maxBound + tickMarkWidth}
              y2={yScreenValue - 0.5}
            />
          </g>
        ) : (
          <g key={`increment_${i}`}>
            <line
              className={css(styles.tickMark)}
              x1={spacing.minBound}
              y1={yScreenValue - 0.5}
              x2={spacing.minBound + tickMarkWidth}
              y2={yScreenValue - 0.5}
            />
          </g>
        );
      })}
    </g>
  );
};

export default Increments;
