import { fontFamily, font } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

export const CONTENT_VERTICAL_MARGIN = 6 * BASE_UNIT;

export const ContentHeading = styled({
  alignItems: 'center',
  display: 'flex',
});

export const SwitchWrapper = styled({
  alignItems: 'center',
  display: 'flex',
  paddingTop: 2 * BASE_UNIT,
  paddingBottom: 2 * BASE_UNIT,
});

export const ContentTitle = styled({
  ...font.gilroyHeading,
  color: colors.cloudBurst,
  lineHeight: '38px',
});

export const ContentHeadingRight = styled({
  marginLeft: 'auto',
  display: 'flex',
});

export const Separator = styled({
  width: 4 * BASE_UNIT,
});

export const ContentWrapper = styled({
  display: 'flex',
});

export const TheoryContentWrapper = styled({
  display: 'flex',
  flexDirection: 'column',
});

export const Label = styled({
  fontFamily: fontFamily.body,
  color: colors.grayChateau,
});
