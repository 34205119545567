import { css } from '@emotion/css';
import moment from 'moment';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { VStack } from 'ms-ui-primitives/Stack';

import type { AddOnSubscriptionDetails_subscription$key } from './__generated__/AddOnSubscriptionDetails_subscription.graphql';
import SubscriptionCard, {
  SubscriptionCardText,
  SubscriptionCardTitle,
} from '../SubscriptionCard';
// 🚨 TODO unwind this when we use relay-compiler's builtin typescript emitter and
// react-relay's builtin hooks, which will work properly unlike this dodgy 3rd party things.
//
// The 3rd party 'relay-hooks' and the 3rd party typescript emitter for relay-compiler
// are not compatible. The " $data" property in the emitted types looks like this:
// WorkHistory_events$data | undefined
// The | undefined is incompatible with the, relay-hooks package, as must be removed.
type FixFragmentArrayKeyType<T extends readonly any[]> = ReadonlyArray<{
  [P in keyof T[number]]: NonNullable<T[number][P]>;
}>;
type Props = {
  subscriptionsKey: FixFragmentArrayKeyType<AddOnSubscriptionDetails_subscription$key>;
  manageSubscriptionUrl: string | null;
  manageSubscriptionUrlIsLoading: boolean;
};
export default function AddOnSubscriptionDetails({
  subscriptionsKey,
  manageSubscriptionUrl,
  manageSubscriptionUrlIsLoading,
}: Props) {
  const addOnSubscriptions = useFragment(
    graphql`
      fragment AddOnSubscriptionDetails_subscription on AddOnSubscription
      @relay(plural: true) {
        addOnKey
        startDate
        endDate
        cancelAt
        subscriptionState
        stripeSubscriptionId
      }
    `,
    subscriptionsKey,
  );
  const shouldRenderManageSubscriptionButton =
    manageSubscriptionUrl || manageSubscriptionUrlIsLoading;
  return (
    <VStack gap={16}>
      {addOnSubscriptions.map((subscription, idx) => {
        const {
          addOnKey,
          startDate,
          endDate,
          cancelAt,
          subscriptionState,
          stripeSubscriptionId,
        } = subscription;
        const formattedStartDate = moment(startDate).format('LL');
        const formattedEndDate =
          endDate != null ? moment(endDate).format('LL') : null;

        // An active subscription state doesn't actually tell us anything about
        // whether a subscription has been cancelled or not, i.e. a subscription
        // can be both active and cancelled at the same time, which is why we
        // need to check for the cancelAt field as well.
        // See: https://docs.stripe.com/api/subscriptions/object#subscription_object-cancel_at
        const studentIsSelfPaid = stripeSubscriptionId != null;
        const shouldRenderRenewalMessage =
          studentIsSelfPaid &&
          subscriptionState === 'ACTIVE' &&
          cancelAt == null;

        return (
          <SubscriptionCard key={`AddOnSubscription-${idx}`}>
            <SubscriptionCardTitle as="h5">{addOnKey}</SubscriptionCardTitle>
            <div>
              <SubscriptionCardText>
                Access period:{' '}
                <strong className={css({ fontWeight: fontWeight.semibold })}>
                  {formattedStartDate}–{formattedEndDate}
                </strong>
              </SubscriptionCardText>

              {shouldRenderRenewalMessage && (
                <SubscriptionCardText>
                  <span className={css({ color: colors.dustyGray })}>
                    Will automatically renew monthly.
                    {endDate && (
                      <> Next renewal coming up on {formattedEndDate}</>
                    )}
                  </span>
                </SubscriptionCardText>
              )}
            </div>

            {studentIsSelfPaid && shouldRenderManageSubscriptionButton && (
              <AnchorButton
                type="secondary"
                isDisabled={manageSubscriptionUrlIsLoading}
                href={manageSubscriptionUrl ?? ''}
                target="_blank"
                rel="noreferrer"
              >
                Manage subscription
              </AnchorButton>
            )}
          </SubscriptionCard>
        );
      })}
    </VStack>
  );
}
