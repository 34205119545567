import { StyleSheet, css } from 'aphrodite';
import type {
  MouseEvent as ReactMouseEvent,
  TouchEvent as ReactTouchEvent,
} from 'react';

import type { Edge } from '..';

type Props = {
  name: Edge;
  onInteract: (
    name: Edge,
    event: ReactMouseEvent<SVGRectElement> | ReactTouchEvent<SVGRectElement>,
  ) => void;
  xCoord: number;
  yCoord: number;
  width: number;
  height: number;
  fill: string;
  readOnly?: boolean | undefined;
};

const styles = StyleSheet.create({
  interactiveEdgeClickTarget: {
    cursor: 'ew-resize',
    fill: 'transparent',
  },
});

const InteractiveEdge = ({
  name,
  onInteract,
  xCoord,
  yCoord,
  width,
  height,
  fill,
  readOnly,
}: Props) => {
  const hitBoxWidth = width * 10;
  return (
    <g key={name}>
      <line
        x1={xCoord}
        y1={yCoord}
        x2={xCoord}
        y2={yCoord + height}
        strokeWidth={width}
        stroke={fill}
      />
      {!readOnly && (
        <rect
          onMouseDown={event => onInteract(name, event)}
          onTouchStart={event => onInteract(name, event)}
          className={css(styles.interactiveEdgeClickTarget)}
          x={xCoord - (width + hitBoxWidth / 2)}
          y={yCoord}
          height={height}
          width={hitBoxWidth}
        />
      )}
    </g>
  );
};

export default InteractiveEdge;
