import { useContext } from 'react';

import { TeacherContext } from 'ms-pages/Teacher/TeacherContext/index';
import type { StaticTeacherContext } from 'ms-pages/Teacher/TeacherContext/types';
import { useRerenderEffect } from 'ms-utils/hooks/useRerenderEffect';

type Listener = (context: StaticTeacherContext) => void;

type Props = {
  listener: Listener;
};

export function useTeacherContextListener(listener: Listener) {
  const { classId, schoolId, schoolList, teacherId, teacherPreference } =
    useContext(TeacherContext);

  useRerenderEffect(() => {
    listener({
      classId,
      schoolId,
      schoolList,
      teacherId,
      teacherPreference,
    });
  }, [classId, schoolId]);
}

export default function TeacherContextListener({ listener }: Props) {
  useTeacherContextListener(listener);
  return null;
}
