import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutationWithArgs';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';

import type {
  UpdateTeacherPreferenceMutationVariables,
  // eslint-disable-next-line no-unused-vars
  UpdateTeacherPreferenceMutationResponse,
} from './__generated__/UpdateTeacherPreferenceMutation.graphql';

export type { UpdateTeacherPreferenceMutationVariables };
export const updateTeacherPreferenceMutation = graphql`
  mutation UpdateTeacherPreferenceMutation(
    $createTaskTargetMastery: Int
    $onboardingSetup: TeacherOnboardingSetupEnum
    $dismissOnboardingFeature: String
  ) {
    updateTeacherPreference(
      createTaskTargetMastery: $createTaskTargetMastery
      onboardingSetup: $onboardingSetup
      dismissOnboardingFeature: $dismissOnboardingFeature
    ) {
      errors {
        key
        message
      }
      teacherPreference {
        id
        createTaskTargetMastery
        onboardingSetup
        dismissedOnboardingFeatures
      }
    }
  }
`;
export default function useUpdateTeacherPreference(): UseMutationResponse<
  UpdateTeacherPreferenceMutationVariables,
  UpdateTeacherPreferenceMutationResponse
> {
  return useMutation({ mutation: updateTeacherPreferenceMutation });
}
