import {
  Component,
  Suspense,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import type { ErrorInfo, ReactNode, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';

import type { TabCategory } from 'ms-helpers/Snowplow/Types/lantern/teacher';
import useFeatureFlagsV2 from 'ms-helpers/useFeatureFlagsV2';
import DiagnosePhaseIcon from 'ms-pages/Lantern/components/DiagnosePhaseIcon';
import {
  nameCellContentStyle,
  nameCellModalLinkStyle,
  nameStyle,
  outcomesTableStyles,
} from 'ms-pages/Lantern/components/OutcomesTable';
import TeacherStudentSkillsReportModal from 'ms-pages/Lantern/components/TeacherStudentSkillsReportModal';
import { useSnowplowForLanternTeacher } from 'ms-pages/Lantern/helpers/Snowplow';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { BodyS, BodyXS } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-pages/Lantern/styles';
import type { Student as GradeStrandStudent } from 'ms-pages/Lantern/views/Teacher/TeacherGradeStrand/types';
import type { Student as GrowthStudent } from 'ms-pages/Lantern/views/Teacher/TeacherGrowth/types';
import type { Student as StrandStudent } from 'ms-pages/Lantern/views/Teacher/TeacherStrand/types';
import CreateTaskModal from 'ms-pages/Teacher/components/CreateTaskModal';
import {
  Link as ModalLink,
  useModalLocation,
} from 'ms-pages/Teacher/components/Link/Modal';
import Button from 'ms-ui-primitives/Button';
import { HStack, HSpacer, VStack, VSpacer } from 'ms-ui-primitives/Stack';
import { Logger } from 'ms-utils/app-logging';
import { tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import type { SetState } from 'ms-utils/typescript-utils';
import { teacherStudentSkillsUrl } from 'ms-utils/urls';

type Student = GradeStrandStudent | GrowthStudent | StrandStudent;

type ErrorProps = {
  children: (props: { fetchKey: number }) => React.ReactNode;
  queryName: string;
};

function ErrorFallback({ retry }: { retry: () => void }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: 'fit-content',
          backgroundColor: colors.iron,
          padding: 16,
          borderRadius: 8,
        }}
      >
        Data failed to load<Button onClick={retry}>Try again</Button>
      </div>
    </div>
  );
}

class LanternTeacherQueryErrorBoundary extends Component<
  ErrorProps,
  { hasError: boolean; fetchKey: number }
> {
  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  override state = { hasError: false, fetchKey: 0 };

  override componentDidCatch(error: Error, info: ErrorInfo) {
    // Note for anyone investigating issues traced back to this component:
    // this will catch and log any errors in the component's subtree even
    // if it has nothing to do with the query failing.
    Logger.error(`${this.props.queryName} failed: ${error.message}`, {
      extra: {
        componentStack: info.componentStack,
        errorBoundaryName: `LanternTeacherQuery:${this.props.queryName}`,
      },
    });
  }

  _retry = () => {
    this.setState(prev => ({ hasError: false, fetchKey: prev.fetchKey + 1 }));
  };

  override render() {
    if (this.state.hasError) {
      return <ErrorFallback retry={this._retry} />;
    }
    return this.props.children({ fetchKey: this.state.fetchKey });
  }
}

export function LanternTeacherQueryWrapper({
  children,
  fallback,
  queryName,
}: {
  children: (props: { fetchKey: number }) => React.ReactNode;
  fallback: React.ReactNode;
  queryName: string;
}) {
  return (
    <Suspense fallback={fallback}>
      <LanternTeacherQueryErrorBoundary queryName={queryName}>
        {({ fetchKey }) => children({ fetchKey })}
      </LanternTeacherQueryErrorBoundary>
    </Suspense>
  );
}

export function DiscoveryCheckInCTA({
  showAssignButton,
  setShowAssignButton,
  student,
  strandId,
}: {
  showAssignButton: boolean;
  setShowAssignButton: SetState<boolean>;
  student: Student;
  strandId: string | null | undefined;
}) {
  const [taskCreationOpen, setTaskCreationOpen] = useState(false);

  const studentPayload = useMemo(() => {
    const {
      id,
      user: { firstName, lastName },
    } = student;
    return {
      id,
      firstName,
      lastName,
    };
  }, [student]);

  const tooltipContent = useMemo(
    () => (
      <VStack style={{ padding: '12px 16px' }}>
        <BodyS color="white" bold>
          Discovery check-in pending
        </BodyS>
        <VSpacer height={8} />
        <BodyXS color="white">
          Grade level will be estimated once the student completes the discovery
          check-in for this strand.
        </BodyXS>
      </VStack>
    ),
    [],
  );

  const buttonStyles = useMemo(
    () => ({
      transition: 'opacity 0.2s ease-in-out',
      ...(!showAssignButton ? { opacity: 0 } : {}),
    }),
    [showAssignButton],
  );

  const handleAssignButtonClick = useCallback(
    (e: SyntheticEvent<HTMLElement>) => {
      // to prevent button click to trigger row click
      e.stopPropagation();
      setShowAssignButton(false);
      setTaskCreationOpen(true);
    },
    [setShowAssignButton],
  );

  const handleCloseCreateTask = useCallback(
    () => setTaskCreationOpen(false),
    [],
  );
  return (
    <HStack center>
      <Tooltip backgroundColor={colors.grey} content={tooltipContent}>
        <TooltipInner>
          <DiagnosePhaseIcon height={20} width={20} />
          <HSpacer width={12} />

          <Button
            styles={buttonStyles}
            type="secondary"
            size="small"
            isRound
            onClick={handleAssignButtonClick}
          >
            Assign
          </Button>
        </TooltipInner>
      </Tooltip>
      <CreateTaskModal
        isOpen={taskCreationOpen}
        onClose={handleCloseCreateTask}
        preselection={['discoveryCheckIn', 0]}
        prefilledStudents={[studentPayload]}
        prefilledStrandId={strandId}
        trackingEventOnCreation={{
          category: 'skill_report',
          action: 'assign_discovery_check_in',
        }}
      />
    </HStack>
  );
}

const TooltipInner = forwardRef<HTMLDivElement, { children: ReactNode }>(
  function TooltipInner(props, ref) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} ref={ref}>
        {props.children}
      </div>
    );
  },
);

export const ClassSkillsStudentName = ({
  studentLastName,
  studentFirstName,
  studentId,
  lanternStudentId,
  eventLabel,
}: {
  studentLastName: string;
  studentFirstName: string;
  studentId: string;
  lanternStudentId: string;
  eventLabel: TabCategory;
}) => {
  const [{ skillsReportsEnableStudentSkillsUpdate }] = useFeatureFlagsV2();
  const { trackStructEvent } = useSnowplowForLanternTeacher();
  const isSkillsReportModalOpen = useBoolean(false);

  const nameCell = (
    <div css={nameCellContentStyle}>
      <div css={nameStyle}>
        <div
          css={{
            textDecoration: 'none',
            color: colors.cloudBurst,
            ...tappable,
          }}
          onClick={() => {
            if (!skillsReportsEnableStudentSkillsUpdate) {
              trackStructEvent({
                category: 'teacher_skills',
                action: 'clicked_view_student_skills_report',
                label: eventLabel,
              });
              isSkillsReportModalOpen.setTrue();
            }
          }}
        >
          {studentLastName}, {studentFirstName}
        </div>
        {!skillsReportsEnableStudentSkillsUpdate && (
          <TeacherStudentSkillsReportModal
            studentId={lanternStudentId}
            isOpen={isSkillsReportModalOpen.value}
            closeModal={isSkillsReportModalOpen.setFalse}
          />
        )}
      </div>
    </div>
  );

  const studentSkillsUrl = useMemo(
    () => teacherStudentSkillsUrl({ studentId }),
    [studentId],
  );

  const handleOnClick = useCallback(
    // Both row click and name click opens the student skills modal
    // so that name can be a link that users can use context menu and link properties.
    // This is to prevent name click to trigger row click.
    (e: SyntheticEvent<HTMLElement>) => e.stopPropagation(),
    [],
  );

  return skillsReportsEnableStudentSkillsUpdate ? (
    <ModalLink
      className={nameCellModalLinkStyle}
      enter
      to={studentSkillsUrl}
      onClick={handleOnClick}
    >
      {nameCell}
    </ModalLink>
  ) : (
    nameCell
  );
};

export const ClassSkillsStudentTableRow = ({
  children,
  studentId,
  eventLabel,
  onMouseEnter,
  onMouseLeave,
}: {
  children: ReactNode;
  studentId: string;
  eventLabel: TabCategory;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}) => {
  const [{ skillsReportsEnableStudentSkillsUpdate }] = useFeatureFlagsV2();
  const { trackStructEvent } = useSnowplowForLanternTeacher();
  const history = useHistory();

  const studentSkillsReportLocation = useModalLocation(
    teacherStudentSkillsUrl({ studentId }),
    true,
  );

  const handleRowClick = useCallback(() => {
    if (skillsReportsEnableStudentSkillsUpdate) {
      trackStructEvent({
        category: 'teacher_skills',
        action: 'clicked_view_student_skills_report',
        label: eventLabel,
      });
      history.push(studentSkillsReportLocation);
    }
  }, [
    eventLabel,
    history,
    skillsReportsEnableStudentSkillsUpdate,
    studentSkillsReportLocation,
    trackStructEvent,
  ]);

  return (
    <tr
      className={
        skillsReportsEnableStudentSkillsUpdate
          ? outcomesTableStyles.tr
          : undefined
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleRowClick}
    >
      {children}
    </tr>
  );
};
