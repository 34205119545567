import type { ReactNode } from 'react';

import ChevronLeftIcon from 'ms-components/icons/ChevronLeft';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { hexStringToRgbaString } from 'ms-utils/colors';
import { styled } from 'ms-utils/emotion';

const TOGGLE_SIZE = 36;
const BOX_SHADOW_SIZE = 48;

const sidebarToggleWrapperStyle = {
  background: colors.white,
};

export const SidebarWrapper = styled({
  default: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
    boxShadow: `0 0 ${BOX_SHADOW_SIZE}px ${hexStringToRgbaString(
      colors.cloudBurst,
      0.15,
    )}`,
    // to let the drop shadow go over the main content
    // and to cover topics button over
    zIndex: 3, // must beat sticky textbook header local_modules/ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader.tsx
    backgroundColor: 'white',
  },
  noShadow: {
    boxShadow: 'none',
  },
});

const SidebarToggleWrapper = styled({
  position: 'absolute',
  top: 40,
  right: -TOGGLE_SIZE / 2,
  zIndex: 3, // must beat sticky textbook header local_modules/ms-pages/Textbooks/components/SubtopicDetailView/SubtopicDetailHeader.tsx
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const IconWrapper = styled({
  default: {
    display: 'flex',
    alignItems: 'center',
    transition: '.5s transform',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
});

function SidebarToggle({
  isOpen,
  onOpen,
  onClose,
}: Pick<Props, 'isOpen' | 'onOpen' | 'onClose'>) {
  return (
    <SidebarToggleWrapper>
      <Button
        styles={sidebarToggleWrapperStyle}
        label="Toggle sidebar"
        type="secondary"
        isCircle
        onClick={isOpen ? onClose : onOpen}
      >
        <IconWrapper rotated={!isOpen}>
          <ChevronLeftIcon size={16} />
        </IconWrapper>
      </Button>
    </SidebarToggleWrapper>
  );
}

type Props = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  children: ReactNode;
  noShadow?: boolean | undefined;
  noControl?: boolean | undefined;
};

export default function CollapsibleSidebar({
  isOpen,
  onOpen,
  onClose,
  children,
  noShadow = false,
  noControl = false,
}: Props) {
  return (
    <SidebarWrapper noShadow={noShadow}>
      {!noControl && (
        <SidebarToggle isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      )}
      {children}
    </SidebarWrapper>
  );
}
