/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BackgroundEnum = "ABSTRACT_FORESTGREEN" | "ABSTRACT_LOCHMARA" | "ABSTRACT_LOCHMARADARK" | "ABSTRACT_MULTICOLOURDOTS" | "ABSTRACT_PINKBLOBS" | "ABSTRACT_PINKGREY" | "BACKGROUND_DEFAULT" | "CAIRO_EGYPT" | "CHRISTMAS_LIGHT" | "FANTASY_CINDERELLA" | "GRADIENT_CLAY" | "GRADIENT_LIGHTBLUE" | "GRADIENT_PEACH" | "HOLIDAY_APRIL_FOOLS_DAY" | "HOLIDAY_EARTH_DAY" | "HOLIDAY_EASTER" | "HOLIDAY_EID_AL_FITR" | "HOLIDAY_HALLOWEEN" | "HOLIDAY_HANUKKAH" | "HOLIDAY_OLYMPICS_2024" | "HOLIDAY_PI_DAY" | "HOLIDAY_RAMADAN" | "HOLIDAY_SAINT_PATRICKS_DAY" | "HOLIDAY_TALK_LIKE_A_PIRATE_DAY" | "HOLIDAY_THANKSGIVING" | "HOLIDAY_YOUTH_DAY" | "INDOORS_LIBRARY" | "LANDSCAPE_BEACH" | "LANDSCAPE_BOAT" | "LUNAR_NEW_YEAR" | "SEASCAPE_SHARK" | "TOKYO_STREETS";
export type HowToUnlock = "COMPLETE_A_DISCOVERY_CHECKIN" | "COMPLETE_CLASS_MONTHLY_EXPEDITION_CHALLENGE_AND_COLLECT";
export type MonthlyExpeditionUnlockBackgroundStudentPreference = {
    readonly availableDashboardBackgrounds: ReadonlyArray<{
        readonly name: BackgroundEnum;
        readonly url: string;
        readonly type: string;
        readonly isUnlocked: boolean;
        readonly howToUnlock: HowToUnlock | null;
    }>;
};
export type MonthlyExpeditionUnlockBackgroundStudentPreference$data = MonthlyExpeditionUnlockBackgroundStudentPreference;
export type MonthlyExpeditionUnlockBackgroundStudentPreference$key = {
    readonly " $data"?: MonthlyExpeditionUnlockBackgroundStudentPreference$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"MonthlyExpeditionUnlockBackgroundStudentPreference">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "MonthlyExpeditionUnlockBackgroundStudentPreference",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DashboardBackground",
      "kind": "LinkedField",
      "name": "availableDashboardBackgrounds",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "howToUnlock",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudentPreference",
  "abstractKey": null
};
(node as any).hash = 'b5f340fd47004032a975c6c1ef559b90';
export default node;
