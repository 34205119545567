import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  createClassMutationVariables,
  createClassMutationResponse,
} from './__generated__/createClassMutation.graphql';

export type { createClassMutationVariables };
export const mutation = graphql`
  mutation createClassMutation(
    $schoolId: ID!
    $title: String!
    $displayName: String!
    $syllabusIds: [ID!]!
    $teacherId: ID!
    $teacherIds: [ID!]
    $classSyllabusFocusId: ID!
    $classGradeId: ID!
    $hasEnabledLeaderboard: Boolean!
    $numberOfClasses: Int!
    $numberOfStudents: Int!
  ) {
    createClass(
      schoolId: $schoolId
      title: $title
      displayName: $displayName
      syllabusIds: $syllabusIds
      teacherIds: $teacherIds
      classSyllabusFocusId: $classSyllabusFocusId
      classGradeId: $classGradeId
      hasEnabledLeaderboard: $hasEnabledLeaderboard
    ) {
      errors {
        key
        message
      }
      class {
        id
        ...SchoolContext_class @relay(mask: false)
        # Below fields are needed for the class list on the NTE dashboard
        classSyllabusFocus {
          id
          thumbnailImageUrl
        }
        classGrade {
          id
          title
        }
      }
      user {
        profile {
          ... on Teacher {
            id
            isHeadTeacher
            school {
              id
              title
              ownedClasses: classes(
                first: $numberOfClasses
                teacherId: $teacherId
              ) @connection(key: "Teacher_ownedClasses") {
                edges {
                  node {
                    id
                  }
                }
              }
              schoolClasses: classes(first: $numberOfClasses)
                @connection(key: "School_schoolClasses") {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default function useCreateClass(
  variables: createClassMutationVariables,
): UseMutationResponse<createClassMutationResponse> {
  return useMutation({
    mutation,
    variables,
  });
}
