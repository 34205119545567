/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSelectorQueryVariables = {
    searchTerm: string;
    cursor?: string | null | undefined;
};
export type StudentSelectorQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly " $fragmentRefs": FragmentRefs<"StudentSelector_teacher">;
        };
    };
};
export type StudentSelectorQuery = {
    readonly response: StudentSelectorQueryResponse;
    readonly variables: StudentSelectorQueryVariables;
};



/*
query StudentSelectorQuery(
  $searchTerm: String!
  $cursor: ID
) {
  viewer {
    profile {
      __typename
      ... on Teacher {
        ...StudentSelector_teacher_3CuKn3
        id
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    id
  }
}

fragment StudentSelector_teacher_3CuKn3 on Teacher {
  studentSearch(first: 10, searchQuery: $searchTerm, after: $cursor) {
    edges {
      node {
        id
        user {
          id
          firstName
          lastName
          avatar
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchTerm"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StudentSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "cursor",
                        "variableName": "cursor"
                      },
                      {
                        "kind": "Variable",
                        "name": "searchTerm",
                        "variableName": "searchTerm"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "StudentSelector_teacher"
                  }
                ],
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "StudentSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "StudentConnection",
                    "kind": "LinkedField",
                    "name": "studentSearch",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StudentEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Student",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "avatar",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": [
                      "searchQuery"
                    ],
                    "handle": "connection",
                    "key": "StudentSelectorFragment_studentSearch",
                    "kind": "LinkedHandle",
                    "name": "studentSearch"
                  },
                  (v4/*: any*/)
                ],
                "type": "Teacher",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2b8aefa932ca92d5f2c96ab7c10f0cb",
    "id": null,
    "metadata": {},
    "name": "StudentSelectorQuery",
    "operationKind": "query",
    "text": "query StudentSelectorQuery(\n  $searchTerm: String!\n  $cursor: ID\n) {\n  viewer {\n    profile {\n      __typename\n      ... on Teacher {\n        ...StudentSelector_teacher_3CuKn3\n        id\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment StudentSelector_teacher_3CuKn3 on Teacher {\n  studentSearch(first: 10, searchQuery: $searchTerm, after: $cursor) {\n    edges {\n      node {\n        id\n        user {\n          id\n          firstName\n          lastName\n          avatar\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'b6fc244cc84fe690b349f9feae626a46';
export default node;
