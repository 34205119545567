// Small utility components for vertical/horizontal stacks.
// Naming is inspired by Apple's SwiftUI
// See: https://developer.apple.com/documentation/swiftui/view_layout_and_presentation

export function HStack(props: any) {
  return <div css={{ display: 'flex' }} {...props} />;
}

export function VStack(props: any) {
  return <div css={{ display: 'flex', flexDirection: 'column' }} {...props} />;
}

export function VSpacer({
  height = 30,
  grow = false,
}: {
  height?: number;
  grow?: boolean;
}) {
  return (
    <div
      css={{
        height,
        flexShrink: 0,
        ...(grow ? { flexGrow: 1 } : { flexGrow: undefined }),
      }}
    />
  );
}

export function HSpacer({
  width = 30,
  grow = false,
}: {
  width?: number;
  grow?: boolean;
}) {
  return (
    <div
      css={{
        width,
        flexShrink: 0,
        display: 'inline-block',
        ...(grow ? { flexGrow: 1 } : {}),
      }}
    />
  );
}
