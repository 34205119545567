import { useContext } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { SunflowerUpgradeInfoBox } from 'ms-pages/Sunflower/components/FeatureInfoBox/FeatureInfoBox';
import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector, {
  type Items,
} from 'ms-pages/Teacher/components/InputSelector';

import { StateContext, UpdatersContext } from '../state';

type Props = {
  items: Items;
  errorMessages: ErrorMessages;
};

const OtherTeachers = ({ items, errorMessages }: Props) => {
  const { teachers } = useContext(StateContext);
  const { addTeacher, removeTeacher } = useContext(UpdatersContext);
  const {
    featureFlags: { allowMultipleTeachersInClass },
  } = useViewer();

  return (
    <FieldGroup title="Other teachers with access to this class">
      {allowMultipleTeachersInClass ? (
        <InputSelector
          items={items}
          selectedItems={teachers}
          removeSelectedItem={removeTeacher}
          onDropdownSelect={addTeacher}
          placeholder="Select from the list"
          showErrorState={errorMessages.length > 0}
        />
      ) : (
        <SunflowerUpgradeInfoBox
          title="This plan is currently limited to one teacher per class"
          description="Upgrade to add teachers to your class"
          layout="horizontal"
        />
      )}
      <FormFieldError messages={errorMessages} />
    </FieldGroup>
  );
};

export default OtherTeachers;
