import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M1.42129 4.49695C0.905799 4.45681 0.5 4.0258 0.5 3.5C0.5 2.94772 0.947715 2.5 1.5 2.5H2.51129C2.61877 1.53133 3.48007 0.5 4.52667 0.5H10.4733C11.5199 0.5 12.3812 1.53133 12.4887 2.5H13.5C14.0523 2.5 14.5 2.94772 14.5 3.5C14.5 4.0258 14.0942 4.45681 13.5787 4.49695H1.42129ZM13.351 5.49695L12.6682 14.6488C12.5903 15.6927 11.7206 16.5 10.6737 16.5H4.35206C3.30699 16.5 2.43811 15.6954 2.35795 14.6534L1.65361 5.49695H13.351ZM10.7209 2.5H4.23227C4.25727 1.96621 4.40369 1.50265 5.00133 1.50265H9.86533C10.463 1.50265 10.6803 1.96621 10.7209 2.5Z"
  />
);

const Trash = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Trash.displayName = 'Trash';

export default Trash;
