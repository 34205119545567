import { css, StyleSheet } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ChangeEvent, ReactNode } from 'react';

import CheckMarkThickIcon from 'ms-components/icons/CheckMarkThick';
import {
  borderRadiusUI,
  fontFamily,
  fontSize,
  lineHeight,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { useColorTheme } from 'ms-styles/themes';

const MARGIN = 10;
const CHECKBOX_SIZE = 20;

const styles = StyleSheet.create({
  root: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    marginRight: MARGIN,
    fontFamily: fontFamily.body,
    fontSize: fontSize.small,
    lineHeight: lineHeight.body,
    cursor: 'pointer',
    userSelect: 'none',
  },
  disabled: {
    cursor: 'default',
  },
  checkIcon: {
    display: 'block',
    height: '1em',
    width: '1em',
  },
  input: {
    display: 'none',
    /*
      We're adding in these pseudo-selectors to fix the behaviour
      of the uncontrolled version of the checkbox (no unchanged
      prop passed in).
    */
    // Show and hide the CheckIcon
    ':checked + div > svg': {
      visibility: 'visible',
    },
    ':not(:checked) + div > svg': {
      visibility: 'hidden',
    },
  },
  displayedCheckBox: {
    background: colors.white,
    border: `2px solid ${colors.shuttleGray}`,
    transition: `background-color ${transition}, border-color ${transition}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: borderRadiusUI,
    marginRight: MARGIN,
    height: CHECKBOX_SIZE,
    width: CHECKBOX_SIZE,
    flexShrink: 0,
  },
  noMarginRight: {
    marginRight: 0,
  },
  displayedCheckBoxDisabled: {
    background: colors.iron,
    border: `2px solid ${colors.iron}`,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    minHeight: CHECKBOX_SIZE,
    color: colors.cloudBurst,
  },
  labelDisabled: {
    color: colors.shuttleGray,
  },
});

type Props = {
  label?: string | ReactNode;
  checked?: boolean | undefined;
  disabled?: boolean | undefined;
  aphroditeStyles?: CSSInputTypes[] | undefined;
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  noMarginRight?: boolean | undefined;
  trackingId?: string | undefined;
};

const Checkbox = ({
  label,
  disabled = false,
  aphroditeStyles = [],
  onChange,
  checked,
  noMarginRight,
  trackingId,
}: Props) => {
  // const noMarginRisght = _noMarginRight || label == null;
  const { primary: primaryColorName } = useColorTheme();
  const { checkedStyle } = StyleSheet.create({
    checkedStyle: {
      // Styles for checked/unchecked
      ':checked + div': {
        backgroundColor: colors[primaryColorName],
        borderColor: colors[primaryColorName],
      },
    },
  });
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label
      className={css(
        styles.root,
        disabled && styles.disabled,
        !!noMarginRight && styles.noMarginRight,
        ...aphroditeStyles,
      )}
      data-tracking-id={trackingId}
    >
      <input
        checked={checked}
        type="checkbox"
        className={css(styles.input, checkedStyle)}
        disabled={disabled}
        onChange={onChange}
      />
      <div
        className={css(
          styles.displayedCheckBox,
          disabled && styles.displayedCheckBoxDisabled,
          (!!noMarginRight || label == null) && styles.noMarginRight,
        )}
      >
        <CheckMarkThickIcon
          color={disabled ? colors.iron : colors.white}
          aphroditeStyles={[styles.checkIcon]}
        />
      </div>
      {label && (
        <div className={css(styles.label, disabled && styles.labelDisabled)}>
          {label}
        </div>
      )}
    </label>
  );
};

export default Checkbox;
