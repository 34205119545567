import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

import { borderRadiusUI, shadowColors } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

export const WIDTH = 300;

const styles = StyleSheet.create({
  subscriptionCard: {
    width: WIDTH,
    backgroundColor: colors.white,
    borderRadius: `0 0 ${borderRadiusUI}px ${borderRadiusUI}px`,
    overflow: 'hidden',
    textAlign: 'center',
    padding: '65px 35px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    boxSizing: 'border-box',
  },
  subscriptionCardHighlighted: {
    boxShadow: `0 0 21px ${shadowColors.subscriptionCard}`,
    position: 'relative',
    zIndex: 1,
  },
  subscriptionCardDeemphasized: {
    backgroundColor: 'rgb(237, 238, 238)',
  },
  subscriptionCardStudent: {
    width: '100%',
  },
  accentBar: {
    width: '100%',
    height: 4,
    backgroundColor: 'rgb(212, 215, 217)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  accentBarHighlighed: {
    backgroundColor: colors.mountainMeadow,
  },
});

type Props = {
  raised?: boolean | undefined;
  highlighted?: boolean | undefined;
  deemphasized?: boolean | undefined;
  fullWidth?: boolean | undefined;
  hasAccentBar?: boolean | undefined;
  children?: ReactNode | undefined;
};

const PlanCard = ({
  raised = true,
  highlighted = false,
  deemphasized = false,
  fullWidth = false,
  hasAccentBar = true,
  children,
}: Props) => (
  <div
    className={css(
      styles.subscriptionCard,
      raised && styles.subscriptionCardHighlighted,
      deemphasized && styles.subscriptionCardDeemphasized,
      fullWidth && styles.subscriptionCardStudent,
    )}
  >
    {hasAccentBar && (
      <div
        className={css(
          styles.accentBar,
          highlighted && styles.accentBarHighlighed,
        )}
      />
    )}
    {children}
  </div>
);

export default PlanCard;
