import ConfirmationPrompt from './ConfirmationPrompt';

type Props = {
  noteTitle: string;
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

export default function DeleteNote({
  noteTitle,
  isOpen,
  onDelete,
  onCancel,
}: Props) {
  return (
    <ConfirmationPrompt
      isOpen={isOpen}
      onConfirm={onDelete}
      onCancel={onCancel}
      onClose={onCancel}
      header="Delete note"
      body={`Are you sure you want to delete “${noteTitle}”?`}
      confirmLabel="Delete"
    />
  );
}
