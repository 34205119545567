import styled from '@emotion/styled';

import { colors, fonts, tappable } from 'ms-pages/Lantern/styles';

const CELL_PADDING = 32;

export const accordionButtonStyle = {
  marginLeft: 'auto',
} as const;

export const gradeLevelTitleStyle = {
  color: colors.grey90,
  ...fonts.bodyBold,
  fontSize: 16,
  lineHeight: 1.375,
} as const;

export const gradeLevelRowStyle = {
  display: 'flex',
  borderBottom: `2px solid ${colors.almond}`,
  alignItems: 'center',
  paddingTop: 8,
  paddingBottom: 20,
  ...tappable,
} as const;

export const skillsReportTable = {
  table: {
    borderSpacing: 0,
  },
  th: {
    color: colors.grey,
    ...fonts.bodyBold,
    fontSize: 14,
    lineHeight: 1.5,
    paddingTop: 24,
    textAlign: 'left',
    textTransform: 'uppercase',

    ':not(:first-of-type)': {
      paddingLeft: CELL_PADDING,
    },

    ':not(:last-of-type)': {
      paddingRight: CELL_PADDING,
    },
  },
  td: {
    color: colors.grey90,
    ...fonts.body,
    fontSize: 14,
    lineHeight: 1.5,
    verticalAlign: 'top',
    paddingTop: 20,

    ':not(:first-of-type)': {
      paddingLeft: CELL_PADDING,
    },

    ':not(:last-of-type)': {
      paddingRight: CELL_PADDING,
    },
  },
  tr: {
    alignItems: 'center',
  },
} as const;

export const proficiencyIndicatorWrapper = {
  marginTop: -2, // visually align the indicator with the other text in the table
};

export const DropdownMenuButton = styled.div({
  ...tappable,
});
