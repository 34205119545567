import { css, cx } from '@emotion/css';
import type { ReactNode } from 'react';

import CrossBoldIcon from 'ms-components/icons/CrossBold';
import Separator from 'ms-pages/Teacher/components/Separator';
import { borderRadiusUIExtraLarge, fontFamily } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import { onPressOrHover, tappable } from 'ms-utils/emotion';

const PILL_HEIGHT = 8 * BASE_UNIT;

const CROSS_ICON_SIZE = 3 * BASE_UNIT;

const styles = {
  root: {
    default: css({
      maxWidth: '100%',
      display: 'inline-flex',
      alignItems: 'center',
      padding: `${1.5 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
      borderRadius: borderRadiusUIExtraLarge,
      fontFamily: fontFamily.body,
      overflow: 'hidden',
      color: colors.eggplant,
      backgroundColor: colors.eggplant10,
      cursor: 'default',
    }),
    fixedHeight: css({
      height: PILL_HEIGHT,
    }),
    isGrey: css({
      color: colors.grey,
    }),
    isBlue: css({
      color: colors.grey,
      backgroundColor: colors.tropicalBlue,
    }),
    isDisabled: css({
      color: colors.shuttleGray,
      backgroundColor: colors.porcelain,
    }),
    tappable: css({
      ...tappable,
      ...onPressOrHover({
        color: alternateColors.eggplant,
        backgroundColor: colors.porcelain,
      }),
    }),
  },
  label: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: `${PILL_HEIGHT}px`, // make sure not to cut off the text
  }),
};

type Props = {
  label: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  onClick?: null | (() => void);
  onClose?: (() => void) | null | undefined;
  isGrey?: boolean;
  isBlue?: boolean;
  noFixedHeight?: boolean;
  noTextOverflowEllipsis?: boolean;
  closeButtonHeight?: number;
};

const Pill = ({
  label,
  icon,
  isDisabled,
  onClick,
  onClose,
  isGrey,
  isBlue,
  noFixedHeight = false,
  noTextOverflowEllipsis = false,
  closeButtonHeight,
}: Props) => (
  <div
    className={cx(
      styles.root.default,
      !noFixedHeight && styles.root.fixedHeight,
      isDisabled && styles.root.isDisabled,
      onClick != null && !isDisabled && styles.root.tappable,
      isGrey && styles.root.isGrey,
      isBlue && styles.root.isBlue,
    )}
    onClick={() => onClick?.()}
  >
    {icon != null && (
      <>
        {icon}
        <Separator size={2 * BASE_UNIT} />
      </>
    )}
    <div className={cx(!noTextOverflowEllipsis && styles.label)}>{label}</div>
    {onClose != null && (
      <>
        <Separator size={4 * BASE_UNIT} />
        <Button
          padding={0}
          onClick={onClose}
          height={closeButtonHeight ?? undefined}
          label="Remove"
          color={isBlue ? 'grey' : 'eggplant'}
        >
          <CrossBoldIcon size={CROSS_ICON_SIZE} />
        </Button>
      </>
    )}
  </div>
);

export default Pill;
