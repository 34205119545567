import { css } from '@emotion/css';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, usePaginationFragment } from 'react-relay';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import Modal from 'ms-ui-primitives/Modal';
import useDebouncedValue from 'ms-utils/hooks/useDebouncedValue';
import useWhenInViewport from 'ms-utils/hooks/useWhenInViewport';
import useWindowSize from 'ms-utils/hooks/useWindowSize';

import type { SelectAFolderModalQuery } from './__generated__/SelectAFolderModalQuery.graphql';
import type { SelectAFolderModal_school$key } from './__generated__/SelectAFolderModal_school.graphql';
import type {
  FolderId,
  NullableFolderId,
  FolderTitle,
  AncestorTitles,
  Folder,
  ParentGroupId,
} from './types';
import { getFolderTitleWithPath } from './utils';
import ModalActionsHeader from '../ModalActionsHeader';
import ModalFooter from '../ModalFooter';
import ModalSearchInput from '../ModalSearchInput';
import ModalSelectAFolderTable from '../ModalSelectAFolderTable';
import {
  ModalTableLoading,
  ModalTableErrorFallback,
} from '../ModalTableComponents';
import { Wrapper, ContentWrapper } from '../modalSharedStyles';

const PAGE_SIZE = 15;

const query = graphql`
  query SelectAFolderModalQuery(
    $schoolId: ID!
    $searchTerm: String!
    $pageSize: Int!
    $parentGroupId: String
  ) {
    school(id: $schoolId) {
      ...SelectAFolderModal_school
        @arguments(
          searchTerm: $searchTerm
          pageSize: $pageSize
          parentGroupId: $parentGroupId
        )
    }
  }
`;

const fragment = graphql`
  fragment SelectAFolderModal_school on School
  @argumentDefinitions(
    searchTerm: { type: "String!" }
    pageSize: { type: "Int!" }
    parentGroupId: { type: "String" }
    cursor: { type: "ID" }
  )
  @refetchable(queryName: "SelectAFolderModalRefetchQuery") {
    taskTemplatesAndGroups(
      searchQuery: $searchTerm
      first: $pageSize
      after: $cursor
      authorship: TEACHER_CREATED
      parentGroupId: $parentGroupId
      typeFilter: FOLDERS
    ) @connection(key: "SelectAFolderModal_taskTemplatesAndGroups") {
      edges {
        node {
          __typename
          ... on TaskTemplateGroup {
            id
            title
            taskTemplateCount
            isDistrictShared
            taskTemplateGroupCount
            owningSchool {
              id
            }
            ancestorGroups {
              id
              title
            }
          }
        }
      }
    }
  }
`;

const SelectAFolderModal = ({
  isOpen,
  onClose,
  schoolId,
  onSubmit,
}: {
  isOpen: boolean;
  onClose: () => void;
  schoolId: string;
  onSubmit: (p: { id: FolderId; titleWithPath: FolderTitle }) => void;
}) => {
  const { height: _windowHeight } = useWindowSize();
  const windowHeight = _windowHeight ?? 0;
  const [searchString, setSearchString] = useState('');
  const debouncedSearchString = useDebouncedValue(searchString, 500);
  const [selectedFolderId, setSelectedFolderId] =
    useState<NullableFolderId>(null);
  const [selectedFolderTitle, setSelectedFolderTitle] = useState('');
  const [selectedFolderAncestorIds, setSelectedFolderAncestorIds] = useState<
    FolderId[]
  >([]);
  const [selectedFolderAncestorTitles, setSelectedFolderAncestorTitles] =
    useState<AncestorTitles>([]);
  const [parentGroupId, setParentGroupId] = useState<ParentGroupId>(null);
  const [fetchKey, setFetchKey] = useState(0);

  const hasSearchTerm = searchString !== '';
  const isSearchInProgress = searchString !== debouncedSearchString;

  const handleFolderClick = useCallback(
    ({ id, title, ancestorIds, ancestorTitles }: Folder) => {
      setSelectedFolderId(id);
      setSelectedFolderTitle(title);
      setSelectedFolderAncestorIds(ancestorIds);
      setSelectedFolderAncestorTitles(ancestorTitles);
    },
    [],
  );

  const resetFolderSelection = useCallback(
    () =>
      handleFolderClick({
        id: null,
        title: '',
        ancestorTitles: [],
        ancestorIds: [],
      }),
    [handleFolderClick],
  );

  // We should empty search input and reset the selected folder
  // when parent group changes.
  const onParentGroupChange = useCallback(
    (id: ParentGroupId) => {
      resetFolderSelection();
      setSearchString('');
      setParentGroupId(id);
    },
    [resetFolderSelection],
  );

  // We should reset the selected folder and parent group
  // when search string changes.
  const onSearchStringUpdate = useCallback(
    (value: string) => {
      resetFolderSelection();
      setParentGroupId(null);
      setSearchString(value);
    },
    [resetFolderSelection],
  );

  const onSubmitFolderSelection = useCallback(() => {
    if (selectedFolderId != null) {
      const titleWithPath = getFolderTitleWithPath({
        ancestorTitles: selectedFolderAncestorTitles,
        title: selectedFolderTitle,
      });
      onSubmit({ id: selectedFolderId, titleWithPath });
      onClose();
    }
  }, [
    onClose,
    onSubmit,
    selectedFolderAncestorTitles,
    selectedFolderId,
    selectedFolderTitle,
  ]);

  // We trigger a refetch when a folder is created
  const onFolderCreation = useCallback(() => setFetchKey(prev => prev + 1), []);

  // This makes sure selectedFolderId is reset
  // in every session of the modal,
  // so that folder id isn't transferred into other sessions.
  // Not resetting it can create situations like a folder is selected
  // while that folder isn't in the list of folders.
  useEffect(() => resetFolderSelection(), [isOpen, resetFolderSelection]);

  return (
    <Modal
      closeOnOverlayTap
      width={650}
      height={windowHeight * 0.75}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton
      isOverflowHidden
      isScrollable={false}
    >
      <Wrapper>
        {/* Specifically a flex-basis of 0 not 0% because in some layout calculations
              0% will act like 'auto', size the element based on its content and end up
              pushing the footer out of view */}
        <ContentWrapper className={css({ flex: '1 1 0' })}>
          <HeadingS>Add to template folder</HeadingS>
          <ModalSearchInput
            searchString={searchString}
            onSearchStringUpdate={onSearchStringUpdate}
          />
          {/* For a stable behavior and look, we should keep ModalActionsHeader out of
          the below table wrapper component so that it wouldn't disappear and appear again
          when the table data is loading */}
          {!hasSearchTerm && (
            <ModalActionsHeader
              parentGroupId={parentGroupId}
              ancestorGroupIds={selectedFolderAncestorIds}
              onParentGroupChange={onParentGroupChange}
              onFolderCreation={onFolderCreation}
            />
          )}
          <SelectAFolderModalTableWrapper
            schoolId={schoolId}
            handleFolderClick={handleFolderClick}
            selectedFolderId={selectedFolderId}
            debouncedSearchString={debouncedSearchString}
            isSearchInProgress={isSearchInProgress}
            searchString={searchString}
            parentGroupId={parentGroupId}
            onParentGroupChange={onParentGroupChange}
            fetchKey={fetchKey}
          />
        </ContentWrapper>
        <ModalFooter
          onCancel={onClose}
          onSubmit={onSubmitFolderSelection}
          submitButtonLabel="Select folder"
          isDisabled={selectedFolderId == null}
        />
      </Wrapper>
    </Modal>
  );
};

type TableCommon = {
  handleFolderClick: (p: Folder) => void;
  selectedFolderId: NullableFolderId;
  searchString: string;
  onParentGroupChange: (id: ParentGroupId) => void;
};

type Table = TableCommon & {
  schoolId: string;
  parentGroupId: ParentGroupId;
  debouncedSearchString: string;
  isSearchInProgress: boolean;
  fetchKey: number;
};

type TableInner = TableCommon & {
  schoolKey: SelectAFolderModal_school$key;
};

const SelectAFolderModalTableWrapper = (props: Table) => (
  <ErrorBoundary
    name="SelectAFolderModalTableWrapper"
    fallback={<ModalTableErrorFallback />}
  >
    <Suspense fallback={<ModalTableLoading />}>
      <SelectAFolderModalTable {...props} />
    </Suspense>
  </ErrorBoundary>
);

const SelectAFolderModalTable = ({
  schoolId,
  debouncedSearchString,
  isSearchInProgress,
  parentGroupId,
  fetchKey,
  ...rest
}: Table) => {
  const data = useLazyLoadQuery<SelectAFolderModalQuery>(
    query,
    {
      schoolId,
      searchTerm: debouncedSearchString,
      pageSize: PAGE_SIZE,
      parentGroupId,
    },
    // If modal is opened again, we should refetch the data,
    // in case user created a folder while the modal was closed.
    // So, we need to use network-only fetch policy here.
    { fetchKey, fetchPolicy: 'network-only' },
  );

  if (data.school == null) {
    throw new Error('SelectAFolderModalQuery failed');
  }

  return isSearchInProgress ? (
    <ModalTableLoading />
  ) : (
    <SelectAFolderModalTableInner schoolKey={data.school} {...rest} />
  );
};

const SelectAFolderModalTableInner = ({
  schoolKey,
  handleFolderClick,
  selectedFolderId,
  searchString,
  onParentGroupChange,
}: TableInner) => {
  const {
    data: school,
    loadNext,
    hasNext,
  } = usePaginationFragment(fragment, schoolKey);

  const ref = useWhenInViewport(
    useCallback(() => {
      if (hasNext) loadNext(PAGE_SIZE);
    }, [hasNext, loadNext]),
  );

  return (
    <ModalSelectAFolderTable
      data={school}
      searchString={searchString}
      selectedFolderId={selectedFolderId}
      handleFolderClick={handleFolderClick}
      onParentGroupChange={onParentGroupChange}
      hasNext={hasNext}
      nextRef={ref}
    />
  );
};

export default SelectAFolderModal;
