import moment from 'moment';

import type { Period } from './types';

export const formatDate = (date: Date): string =>
  moment(date.toISOString()).format('D MMM YYYY');

export const makeStartDateForPeriod = ({
  endDate,
  period,
}: {
  endDate: Date;
  period: Period;
}): Date =>
  moment(endDate)
    .subtract(period - 1, 'days')
    .toDate();
