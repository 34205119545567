import type { ReactNode } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { InvariantViolation } from 'ms-utils/app-logging';

import View from './WithStudentOnboardingFragment';
import type { WithStudentOnboardingQuery } from './__generated__/WithStudentOnboardingQuery.graphql';

const QUERY = graphql`
  query WithStudentOnboardingQuery {
    viewer {
      profile {
        ...WithStudentOnboardingFragment_profile
      }
    }
  }
`;
/**
 * `Component` - A React component that performs it's own query
 * to get it's data
 */
function WithStudentOnboarding({ children }: { children: ReactNode }) {
  const { props, error } = useQuery<WithStudentOnboardingQuery>(QUERY, {});
  if (error != null) return <>{children}</>;
  if (props == null) return <LoadingSpinner />;
  if (props.viewer == null || props.viewer.profile == null)
    throw new InvariantViolation('User is not a student');
  return <View profile={props.viewer.profile}>{children}</View>;
}
export { WithStudentOnboarding };
