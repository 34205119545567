import { createContext, useContext, useMemo, type ReactNode } from 'react';
import { graphql } from 'react-relay';

import useSessionSyncedState from 'ms-utils/hooks/useSessionSyncedState';

import type { SyllabusLocale } from './__generated__/LanguageSelectorContextTypeGeneratorQuery.graphql';
import { DEFAULT_SYLLABUS_LOCALE } from './constants';
// This is only used to generate flow types and is not used in any actual query
graphql`
  query LanguageSelectorContextTypeGeneratorQuery(
    $id: ID!
    $locale: SyllabusLocale
  ) {
    subtopic(id: $id, syllabusLocale: $locale) {
      id
    }
  }
`;
const LanguageSelectorContext = createContext<{
  selectedLanguage: SyllabusLocale;
  setLanguage: (locale: SyllabusLocale) => void;
}>({ selectedLanguage: DEFAULT_SYLLABUS_LOCALE, setLanguage() {} });
export function useLanguageSelectorContext(): [
  SyllabusLocale,
  (locale: SyllabusLocale) => void,
] {
  const context = useContext(LanguageSelectorContext);
  return useMemo(
    () => [context.selectedLanguage, context.setLanguage],
    [context.selectedLanguage, context.setLanguage],
  );
}
export function LanguageContextProvider({ children }: { children: ReactNode }) {
  const [language, setLanguage] = useSessionSyncedState<SyllabusLocale>({
    dbKey: 'textbook_locale',
    fallbackValue: 'EN_US',
  });
  return (
    <LanguageSelectorContext.Provider
      value={{ selectedLanguage: language, setLanguage }}
    >
      {children}
    </LanguageSelectorContext.Provider>
  );
}
