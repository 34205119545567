import { StyleSheet, css } from 'aphrodite';

export function ProbabilityInput({
  id,
  value,
  x,
  y,
  disabled,
  onChange,
}: {
  id: number;
  value: string;
  x: number;
  y: number;
  disabled: boolean;
  onChange: (id: number, value: string) => void;
}) {
  return (
    <input
      type="text"
      value={value}
      disabled={disabled}
      className={css(styles.base)}
      style={{ left: x, top: y }}
      onChange={event => {
        onChange(id, event.target.value);
      }}
    />
  );
}

const styles = StyleSheet.create({
  base: {
    background: '#F5F5F5',
    border: '1px dashed #CCC',
    fontSize: 13,
    lineHeight: 1,
    outline: 'none',
    position: 'absolute',
    textAlign: 'center',
    width: 35,
    transform: 'translate(-50%, -50%)',
  },
});
