import { useContext, useCallback, useEffect } from 'react';
import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import JsxContent from 'ms-components/JsxContent';
import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import EyeIcon from 'ms-components/icons/Eye';
import { useSnowplow } from 'ms-helpers/Snowplow';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import WorksheetTaskDetailsForm, {
  useCreateWorksheetTaskState,
} from 'ms-pages/Teacher/components/CreateTask/CreateWorksheetTask';
import { Body } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import SubtopicEmptyView from 'ms-pages/Teacher/components/CreateTask/components/SubtopicEmptyView';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import { SidebarAndPreview } from 'ms-pages/Teacher/components/CreateTask/flows/components/SidebarAndPreview';
import useGetFirstAvailableClassTasksReportUrl from 'ms-pages/Teacher/components/CreateTask/flows/hooks/useGetFirstAvailableClassTasksReportUrl';
import {
  STEP_NAME_MAPS,
  getStepHeaderInfo,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import {
  useCreateTaskDispatch,
  useCreateTaskState,
} from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import CreateTaskModalHeader from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import { assignableSubtopicForWorksheet } from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { styledVerticallyScrollable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { WorksheetFlow_WorksheetQuery } from './__generated__/WorksheetFlow_WorksheetQuery.graphql';
import { TaskPreviewDrawer } from './components/TaskPreviewDrawer';

type Props = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic: SelectedSubtopic | null | undefined;
  taskName: string | null | undefined;
  taskCreationCallback: () => void;
  currentSyllabusId: string;
  setCurrentSyllabusId: (syllabusId: string) => void;
};
function WorksheetView({ subtopicId }: { subtopicId: string }) {
  const { props } = useQuery<WorksheetFlow_WorksheetQuery>(
    graphql`
      query WorksheetFlow_WorksheetQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          worksheetJsx {
            transpiledJsx
          }
        }
      }
    `,
    { subtopicId },
  );
  const worksheet = props?.subtopic?.worksheetJsx.transpiledJsx;
  return <>{worksheet != null && <JsxContent transpiledJsx={worksheet} />}</>;
}

export default function WorksheetFlow({
  prefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledSubtopic,
  taskName,
  taskCreationCallback,
  currentSyllabusId,
  setCurrentSyllabusId,
}: Props) {
  const { enqueueMessage } = useContext(SnackbarContext);
  const { stepNumber } = useCreateTaskState();
  const dispatch = useCreateTaskDispatch();
  const [
    state,
    [createWorksheetTask, { data, loading, error: mutationError }],
  ] = useCreateWorksheetTaskState({
    taskName,
    selectedClasses: prefilledClasses != null ? prefilledClasses : [],
    selectedStudents: prefilledStudents != null ? prefilledStudents : [],
    excludedStudents:
      prefilledExcludedStudents != null ? prefilledExcludedStudents : [],
    taskStartDate: prefilledStartDate,
    taskDueDate: prefilledDueDate,
    activeSubtopic: prefilledSubtopic,
  });
  const { activeSubtopic } = state.values;
  const { setActiveSubtopic } = state.updaters;
  const classTasksReportUrl = useGetFirstAvailableClassTasksReportUrl(
    state.values.selectedClasses,
  );
  useEffect(
    () => {
      if (
        data != null &&
        data.createWorksheetTask.task != null &&
        data.createWorksheetTask.errors.length === 0
      ) {
        enqueueMessage({
          text: 'Task Created Successfully',
          href: classTasksReportUrl,
          actionLabel: 'View tasks',
        });
        taskCreationCallback();
      } else if (
        mutationError != null ||
        (data != null && data.createWorksheetTask.errors.length > 0)
      ) {
        enqueueMessage({ text: 'Unable to create task' });
      }
    },
    // A lot of the objects used here don't follow good memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );
  const stepNames = STEP_NAME_MAPS.worksheet();
  const nextButtonDisabledStates = {
    subtopic_selector: activeSubtopic == null,
    preview_and_task_details:
      loading ||
      !state.values.taskName ||
      (state.values.selectedClasses.length === 0 &&
        state.values.selectedStudents.length === 0),
  };
  const createWorksheetTaskAction = useCallback(() => {
    createWorksheetTask();
    dispatch({ type: 'create' });
  }, [createWorksheetTask, dispatch]);

  const isTaskPreviewOpen = useBoolean(false);
  const { trackStructEvent } = useSnowplow();

  return (
    <>
      <CreateTaskModalHeader
        stepTitles={getStepHeaderInfo({ selectedFlow: 'worksheet' })}
        steps={stepNames}
        nextButtonDisabledStates={nextButtonDisabledStates}
        onLastStepAction={createWorksheetTaskAction}
      />
      {stepNumber === 0 && (
        <SidebarAndPreview
          taskType="worksheet"
          activeSubtopic={activeSubtopic}
          setActiveSubtopic={setActiveSubtopic}
          syllabusId={currentSyllabusId}
          setSyllabusId={setCurrentSyllabusId}
          subtopicFilter={assignableSubtopicForWorksheet}
          disableSkillsBookTab
        >
          {activeSubtopic == null ? (
            <SubtopicEmptyView />
          ) : (
            <WorksheetView subtopicId={activeSubtopic[0]} />
          )}
        </SidebarAndPreview>
      )}
      {stepNumber === 1 && (
        <Body
          noFooterSpaceAtBottom
          isVerticallyScrollable={false}
          isVerticalOverflowHidden
          whiteBackground={false}
          style={{
            backgroundColor: colors.seashell,
            padding: 0,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              overflowY: 'hidden',
            }}
          >
            <TaskPreviewDrawer open={isTaskPreviewOpen.value}>
              {activeSubtopic != null && (
                <div
                  css={{
                    paddingTop: 16,
                    ...styledVerticallyScrollable,
                    // usually not a good practice to override styles of child
                    // elements but, we need it here since jsx-content comes
                    // with a fixed width
                    '& .jsx-content > div': {
                      width: 'unset !important',
                    },
                  }}
                >
                  <Button
                    styles={{ gap: 8, marginLeft: 16, marginBottom: 16 }}
                    isRound
                    type="secondary"
                    onClick={() => {
                      trackStructEvent({
                        category: 'create_task_from_anywhere',
                        action: 'toggled_questions_preview',
                        label: isTaskPreviewOpen.value
                          ? 'hide_questions'
                          : 'show_questions',
                      });
                      isTaskPreviewOpen.toggle();
                    }}
                  >
                    <EyeIcon size={12} />
                    {isTaskPreviewOpen.value ? 'Hide task' : 'Preview task'}
                  </Button>
                  <div
                    style={{
                      display: isTaskPreviewOpen.value ? 'block' : 'none',
                      ...styledVerticallyScrollable,
                    }}
                  >
                    <WorksheetView subtopicId={activeSubtopic[0]} />
                  </div>
                </div>
              )}
            </TaskPreviewDrawer>
            <div
              style={{
                flexGrow: 1,
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  padding: '24px 16px',
                  ...styledVerticallyScrollable,
                  width: isTaskPreviewOpen.value ? '100%' : '50%',
                }}
              >
                <WorksheetTaskDetailsForm state={state} />
              </div>
            </div>
          </div>
        </Body>
      )}
    </>
  );
}
