import { Base64 } from 'js-base64';
import type { ReactElement } from 'react';
import { renderToString } from 'react-dom/server';

// it takes a svg React Element (or any React.Element that renders to svg)
// outputs a css cursor property value in a url with a base64 string
// optional arguments are `x` and `y` coordinates (from top left) of the hotspot (the pixel that matches the pointer)
// resulting svg string must have an `xmlns` attribute set to "http://www.w3.org/2000/svg" in order for the cursor to be rendered properly
// Browser support: Chrome, Safari, Firefox. Falls back to auto in Edge/IE11

const cssSvgCursor = (
  element: ReactElement<'svg'> | ReactElement<any>,
  x: number = 0,
  y: number = 0,
) =>
  `url(data:image/svg+xml;base64,${Base64.encode(
    renderToString(element),
  )}) ${x} ${y}, auto`;

export default cssSvgCursor;
