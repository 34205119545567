import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M8.5709377,9.98197139 L2.29219411,3.7032278 C1.35150498,2.76253867 2.76253867,1.35150498 3.7032278,2.29219411 L10.6874882,9.27645454 C11.0771344,9.66610073 11.0771344,10.297842 10.6874882,10.6874882 L3.7032278,17.6717487 C2.76253867,18.6124378 1.35150498,17.2014041 2.29219411,16.260715 L8.5709377,9.98197139 Z M17.6717487,16.260715 C18.6124378,17.2014041 17.2014041,18.6124378 16.260715,17.6717487 L9.27645454,10.6874882 C8.88680835,10.297842 8.88680835,9.66610073 9.27645454,9.27645454 L16.260715,2.29219411 C17.2014041,1.35150498 18.6124378,2.76253867 17.6717487,3.7032278 L11.3930051,9.98197139 L17.6717487,16.260715 Z" />
);

const Cross = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Cross.displayName = 'Cross';

export default Cross;
