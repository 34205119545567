import styled from '@emotion/styled';

const PIZZA_SIZE = 194;

const RelativePositionWrapper = styled.div({
  position: 'relative',
});

type Props = {
  width: number;
  height: number;
};

const Position = styled.div({
  display: 'flex',
  position: 'absolute',
});

function BackgroundPattern({ width, height }: Props) {
  const pizzaTransform = `translate(${width - PIZZA_SIZE / 2}, ${-(
    PIZZA_SIZE / 2
  )})`;
  return (
    <RelativePositionWrapper>
      <Position>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          height={height}
          fill="none"
        >
          {/* Blueberry. */}
          <g fill="#8C83C7" transform="translate(-60, 24)">
            <path d="M110.954 43.473c-.379-1.348-.794-2.344-1.606-3.11-.222-.209-.466-.478-.768-.206-.143.127-.212.625-.195.632 1.203.443.625 2.588 2.569 2.684zM22.662 98.939l-.51.336c.69 2.529 1.797 4.816 3.364 6.844-.337-1.21-.92-2.286-1.406-3.412-.53-1.231-.969-2.511-1.448-3.768zm64.982-43.367c2.133 2.964 3.817 6.297 6.745 8.48-1.29-3.706-4.126-6.02-6.745-8.48zM52.765 92.728c-1.554-2.613-2.364-5.875-5.371-7.256 1.257 2.86 2.69 5.57 5.371 7.256zm59.497-46.067c2.089 5.822 3.095 12.075 5.81 17.65-1.022-6.247-2.471-12.323-5.81-17.65zm-65.348 57.393l-10.93-17.936c3.676 10.336 7.145 15.929 10.93 17.936zm45.133-62.432c.06.264.039.576.171.684 4.961 4.08 7.646 9.769 10.019 15.775 1.179 2.983 3.302 5.42 5.482 8.425.266-7.118-9.728-22.536-15.672-24.884zm-71.15 9.39c8.74-24.284 27.269-36.555 50.06-36.465 13.076.052 23.863 5.658 32.665 15.032 8.891 9.469 14.625 21.018 16.181 34.604.65 5.675.556 11.488.609 17.248.021 2.246-.881 4.315-2.302 6.185-1.803 2.372-3.148 5.095-4.022 7.944-1.94 6.315-5.72 11.258-10.464 15.414-3.198 2.801-6.107 6.011-9.714 8.264-11.21 7.004-23.216 10.407-35.99 10.299-4.815-.039-9.061-2.054-13.279-3.997-2.43-1.12-4.559-3.026-6.83-4.275-1.766-.972-2.567-3.08-3.864-3.378-1.96-.453-2.874-1.692-3.915-2.959-9.79-11.924-12.72-26.363-12.252-42.033.166-5.55.607-11.085 1.663-16.566.408-2.117 1.137-4.18 1.455-5.318z" />
          </g>
          {/* Pizza. */}
          <g fill="#8C83C7" transform={pizzaTransform}>
            <path d="M59.167 97.412c-5.19 15.267-8.485 30.697-12.181 46.114l-.129.838a1.006 1.006 0 001.027 1.156c2.947-.092 6.079-.103 8.908.136 2.913.246 5.256-1.323 8.159-2.191 3.446-1.032 6.874-2.154 10.341-2.726 4.924-.812 10.223-3.101 15.066-5.078 4.782-1.953 9.703-3.34 14.365-5.779 10.294-5.385 20.517-10.147 30.988-14.895 10.998-4.988 22.143-10.1 33.403-13.571 4.643-1.43 22.034-6.485 17.904-13.829-2.139-3.803-6.416-6.42-9.783-8.973-5.597-4.242-10.839-8.974-16.444-13.223-3.604-2.731-7.018-5.945-10.254-9.12-3.762-3.69-8.194-6.614-11.946-10.36-7.48-7.467-16.516-13.43-24.796-19.932-9.615-7.55-17.978-16.658-27.729-24.05-4.41-3.343-5.56 3.03-5.992 6.822-.808 7.093-2.188 14.218-3.366 21.313-1.718 10.336-4.802 20.362-6.987 30.69-2.557 12.08-6.495 24.72-10.554 36.658z" />
          </g>
        </svg>
      </Position>
    </RelativePositionWrapper>
  );
}

export { BackgroundPattern };
