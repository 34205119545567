import { useMemo } from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import ClassIcon from 'ms-components/icons/Class';
import { ClassAssigneeIconWrapper } from 'ms-pages/Teacher/components/ClassAndStudentSelector/components';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Stack from 'ms-ui-primitives/Stack';
import { styled, tappable, styledVerticallyScrollable } from 'ms-utils/emotion';

import type {
  ClassSelector_teacher$key,
  ClassSelector_teacher$data,
} from './__generated__/ClassSelector_teacher.graphql';

type Klass = NonNullable<
  ClassSelector_teacher$data['classes']
>['edges'][number]['node'];
type ExternalProps = {
  searchTerm: string;
  onClick: (selectedClass: Klass) => void;
  // used to filter out already selected classes
  selectedClasses: ReadonlyArray<string>;
  listHeight: number;
};
type FragmentProps = {
  teacher: ClassSelector_teacher$data;
};
export type Props = FragmentProps & ExternalProps;
const ClassItem = styled({
  backgroundColor: colors.tropicalBlue,
  padding: `${2 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
  borderRadius: 2 * BASE_UNIT,
  marginBottom: BASE_UNIT,
  marginTop: BASE_UNIT,
  display: 'inline-flex',
  alignItems: 'flex-start',
  width: '100%',
  ...tappable,
});

const ClassTitle = styled({
  fontWeight: fontWeight.semibold,
  color: colors.grey,
  fontSize: fontSize.medium,
});

const ClassDisplayName = styled({
  fontSize: fontSize.small,
  color: colors.grey,
  fontWeight: fontWeight.normal,
});
const Container = styled({
  paddingRight: BASE_UNIT,
  ...styledVerticallyScrollable,
});
/**
 * This is a valid case in which a fragment container needs to take in props that are nullable
 * Whenever a search is made the queryrender that is calling this will rerender and props will
 * return to null. We want to prevent flashing of content by keeping this component rendered.
 */
const ClassSelector = ({
  teacher,
  searchTerm,
  listHeight,
  onClick,
  selectedClasses,
}: Props) => {
  const edges = teacher?.classes?.edges;
  const selectedclassesIndex = useMemo(() => {
    const index: Record<string, number> = {};
    selectedClasses.forEach((classId, position) => (index[classId] = position));
    return index;
  }, [selectedClasses]);
  return (
    <Container style={{ maxHeight: listHeight }}>
      {edges == null ? (
        <MinorSpinner />
      ) : (
        edges
          .filter(
            edge =>
              selectedclassesIndex[edge.node.id] == null &&
              edge.node.title.toLowerCase().includes(searchTerm.toLowerCase()),
          )
          .map(({ node: klass }) => (
            <div key={klass.id}>
              <ClassItem onClick={() => onClick(klass)}>
                <ClassAssigneeIconWrapper>
                  <ClassIcon size={4 * BASE_UNIT} />
                </ClassAssigneeIconWrapper>

                <Stack.V gap={2}>
                  <ClassTitle>{klass.title}</ClassTitle>
                  {klass.displayName != null &&
                    klass.displayName !== klass.title && (
                      <ClassDisplayName>{klass.displayName}</ClassDisplayName>
                    )}
                </Stack.V>
              </ClassItem>
            </div>
          ))
      )}
    </Container>
  );
};
export default function ClassSelectorContainer({
  teacher,
  ...rest
}: ExternalProps & {
  teacher: ClassSelector_teacher$key;
}) {
  const data = useFragment(
    graphql`
      fragment ClassSelector_teacher on Teacher
      @argumentDefinitions(pageSize: { type: "Int", defaultValue: 1000 }) {
        classes(first: $pageSize) {
          edges {
            node {
              id
              title
              displayName
              students(first: $pageSize) {
                edges {
                  node {
                    id
                    user {
                      id
                      firstName
                      lastName
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    teacher,
  );
  return <ClassSelector teacher={data} {...rest} />;
}
