import useMediaQuery from 'ms-utils/hooks/useMediaQuery';

export default function useIsExclusivelyTouchScreen() {
  // There are several approaches to determine if a client is a touch device
  // (see https://gist.github.com/esedic/39a16a7521d42ae205203e3d40dc19f5)

  // In this case we use the matchMedia API for "pointer: coarse"
  // because we want to know if the device doesn't support a "fine" pointer
  // (e.g. a mouse or a trackpad)
  // In fact, some devices that support a "fine" pointer might also support
  // a "coarse" pointer (e.g. an iPad with a magic trackpad)
  return useMediaQuery('(pointer: coarse)');
}
