import type { KeyboardEvent as SyntheticKeyboardEvent } from 'react';

// In browser keyboard events, the keycodes for many special characters do not
// match their ascii key codes. This maps those special keycodes to their
// corresponding character.
// Implementation inspired by: https://github.com/avocode/combokeys/blob/master/helpers/special-characters-map.js
const keyCodeToChar = new Map([
  [106, '*'],
  [107, 'plus'],
  [109, 'minus'],
  [110, '.'],
  [111, '/'],
  [186, ';'],
  [187, '='],
  [188, '],'],
  [189, '-'],
  [190, '.'],
  [191, '/'],
  [192, '`'],
  [219, '['],
  [220, '\\'],
  [221, ']'],
  [222, "'"],
]);

const keyFromEvent = (event: SyntheticKeyboardEvent<any>) =>
  keyCodeToChar.get(event.which) ||
  String.fromCharCode(event.which).toLowerCase();

const xnor = (a: boolean | undefined, b: boolean) => (a && b) || (!a && !b);

type Shortcut = {
  key: string;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  metaKey?: boolean;
};

const matchesShortcut = (
  shortcut: Shortcut,
  event: SyntheticKeyboardEvent<any>,
): boolean => {
  const { ctrlKey, altKey, shiftKey, metaKey } = event;
  const key = keyFromEvent(event);
  return (
    xnor(shortcut.ctrlKey, ctrlKey) &&
    xnor(shortcut.altKey, altKey) &&
    xnor(shortcut.metaKey, metaKey) &&
    xnor(shortcut.shiftKey, shiftKey) &&
    shortcut.key === key
  );
};

export { matchesShortcut };
