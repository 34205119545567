import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M9.55959 5.32098C9.98344 5.24122 10.3468 5.17371 10.6617 5.11679L4.68646 9.81136C4.52725 9.93645 4.40217 10.0983 4.32244 10.2823C3.36213 12.4988 4.45206 13.9547 6.64854 13.495C7.65788 13.2838 8.14607 13.0795 11.3919 11.6192C11.6657 11.496 11.8983 11.3921 12.1202 11.2943C14.8555 10.0881 16.5205 9.66704 16.7951 9.90045C16.946 10.0288 16.9277 10.0793 16.0535 10.7422C15.1196 11.4503 14.8594 11.5948 10.9042 13.6909C7.35252 15.5731 5.55864 16.7391 4.46883 18.1722C2.67765 20.5276 4.50824 22.314 7.71907 22.0881C10.6134 21.8846 14.9406 20.3994 20.1396 17.6874C20.7414 17.3734 20.9695 16.641 20.649 16.0515C20.3285 15.462 19.5809 15.2385 18.9791 15.5525C14.0579 18.1195 10.035 19.5003 7.54224 19.6756C6.87098 19.7228 6.42393 19.669 6.29145 19.7239C6.3287 19.7493 6.36486 19.7277 6.44893 19.6171C7.23623 18.5818 8.87844 17.5144 12.079 15.8183C16.2461 13.6099 16.4674 13.4871 17.5649 12.6548C19.4899 11.1951 20.1013 9.50875 18.4135 8.07377C16.9906 6.86402 15.0237 7.36142 11.1066 9.08876C10.8784 9.18939 10.6404 9.29562 10.3613 9.42119C8.99536 10.0358 8.1455 10.4145 7.55367 10.6592L14.159 5.46969L14.3736 5.25261C14.9405 4.51334 15.1289 3.71917 14.4929 3.01322C14.0534 2.52523 13.4916 2.40628 12.7173 2.41815C11.9339 2.43015 11.2505 2.53983 9.0938 2.94568C8.94945 2.97282 8.94945 2.97282 8.80544 2.99978C7.46343 3.25058 5.81787 2.56432 5.42945 2.31066C4.86214 1.94017 4.09564 2.09034 3.71743 2.64608C2.57805 4.32027 3.44206 5.72548 5.30919 5.83921C6.20637 5.89385 7.26415 5.75016 9.26814 5.37566C9.4142 5.34832 9.4142 5.34832 9.55959 5.32098Z" />
);

const SketchpadBasic = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={24}
    viewBoxWidth={24}
  >
    {path}
  </Icon>
);

SketchpadBasic.displayName = 'SketchpadBasic';

export default SketchpadBasic;
