import { useEffect, useState } from 'react';

import SnackbarComponent from 'ms-components/Snackbar/SnackbarComponent';
import TextbookIcon from 'ms-components/icons/TextbookTeacher';
import { useSnowplow } from 'ms-helpers/Snowplow';
import FormErrorMessage from 'ms-pages/Teacher/components/FormErrorMessage';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector, {
  type Item,
  type Items,
} from 'ms-pages/Teacher/components/InputSelector';
import { ModalFooter, ModalTitle } from 'ms-pages/Teacher/components/Modal';
import Separator from 'ms-pages/Teacher/components/Separator';
import { fontWeight, transition } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button, { sizeToFontSize } from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

import type { Student } from '.';
import useChangeStudentTextbookFocus from './useChangeStudentTextbookFocus';

const Loading = styled({
  default: {
    color: colors.grayChateau,
    fontSize: sizeToFontSize.medium,
    fontWeight: fontWeight.semibold,
    visibility: 'hidden',
  },
  visible: {
    transition: `visibility ${transition} 0.5s`, // with a delay
    visibility: 'visible',
  },
});

const Bold = styled({
  display: 'inline',
  fontWeight: fontWeight.semibold,
});

type Props = {
  items: Items;
  student: Student;
  closeModal: () => void;
};

const ChangeStudentTextbookFocus = ({ items, student, closeModal }: Props) => {
  const { withTrackStructEvent } = useSnowplow();

  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title: string;
  } | null>({
    id: student.user.syllabusFocus.id,
    title: student.user.syllabusFocus.title,
  });

  const [mutation, { response, loading, errors }] =
    useChangeStudentTextbookFocus({
      studentId: student.id,
      syllabusFocusId: selectedItem != null ? selectedItem.id : '',
    });

  const responseErrors = response?.updateStudent.errors;
  const responseSuccessful = Boolean(response?.updateStudent.student);
  const isSubmitting = loading || responseSuccessful;
  const isFormValid = selectedItem != null;

  useEffect(() => {
    if (responseSuccessful) {
      closeModal();
    }
  }, [closeModal, responseSuccessful]);

  const onDropdownSelect = ({ id, title }: Item) =>
    setSelectedItem({ id, title });

  const removeSelectedItem = () => setSelectedItem(null);

  return responseSuccessful ? (
    <SnackbarComponent
      isOpen
      message={`Textbook focus updated for ${student.user.firstName} ${student.user.lastName}`}
    />
  ) : (
    <>
      <ModalTitle>Change textbook focus</ModalTitle>
      <Separator size={2 * BASE_UNIT} />
      <div>
        Select a new textbook focus for{' '}
        <Bold>
          {student.user.firstName} {student.user.lastName}
        </Bold>
      </div>
      {errors != null && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            Oops, there was an error. Please try again.
          </FormErrorMessage>
        </>
      )}
      {responseErrors != null && responseErrors.length > 0 && (
        <>
          <Separator size={4 * BASE_UNIT} />
          <FormErrorMessage>
            {responseErrors.map(({ key, message }) => (
              <div key={key}>{message}</div>
            ))}
          </FormErrorMessage>
        </>
      )}
      <Separator size={4 * BASE_UNIT} />
      <FieldGroup>
        <InputSelector
          items={items}
          selectedItems={selectedItem !== null ? [selectedItem] : []}
          removeSelectedItem={removeSelectedItem}
          onDropdownSelect={onDropdownSelect}
          icon={<TextbookIcon size={3.5 * BASE_UNIT} />}
        />
      </FieldGroup>
      <ModalFooter>
        <Loading visible={isSubmitting}>Submitting...</Loading>
        <Button isDisabled={isSubmitting} type="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          isDisabled={!isFormValid || isSubmitting}
          type="primary"
          onClick={withTrackStructEvent(mutation, {
            category: 'teacher_admin',
            action: 'clicked_change_textbook_focus',
          })}
        >
          Update
        </Button>
      </ModalFooter>
    </>
  );
};

export default ChangeStudentTextbookFocus;
