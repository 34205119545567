type Props = {
  size?: number;
};
// This file does not allow changes to color
export function MathspacePremium({ size = 22 }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M0 3.39679C0 2.08982 1.29011 1.18516 2.49953 1.64407C2.66316 1.70616 2.80605 1.81388 2.91167 1.95476L8.75717 9.75149C9.55508 10.8158 11.122 10.858 11.9748 9.83824L17.6015 3.1096C17.782 2.89384 18.0038 2.71741 18.2537 2.59102L19.0152 2.20577C20.3865 1.51196 22 2.521 22 4.0724V13.913C22 15.0656 21.0766 16 19.9375 16H2.0625C0.923416 16 0 15.0656 0 13.913V3.39679Z"
          fill="#F8C983"
        />
        <path
          d="M11 9.34115C11 8.7127 11.2799 8.11771 11.7619 7.72146L18.6369 2.06927C19.9848 0.961137 22 1.9317 22 3.68898V13.913C22 15.0657 21.0766 16 19.9375 16H13.0625C11.9234 16 11 15.0657 11 13.913V9.34115Z"
          fill="#FDF0D5"
        />
        <path
          d="M17.3807 1.00101C18.4399 -0.100433 20.1571 -0.100433 21.2163 1.00101C22.2754 2.10245 22.2754 3.88824 21.2163 4.98968L13.1027 13.4273C12.0435 14.5287 10.3263 14.5287 9.2671 13.4273C8.208 12.3258 8.208 10.54 9.2671 9.43859L17.3807 1.00101Z"
          fill="#F4B42E"
        />
        <path
          d="M0.794605 5.01855C-0.264543 3.91711 -0.264543 2.13132 0.794605 1.02988C1.85375 -0.071564 3.57097 -0.071564 4.63012 1.02988L12.8941 9.62383C13.9532 10.7253 13.9532 12.5111 12.8941 13.6125C11.8349 14.7139 10.1177 14.7139 9.05859 13.6125L0.794605 5.01855Z"
          fill="#F4B42E"
        />
        <path
          d="M11 7.65216L15.7291 12.4373C17.0285 13.7521 16.1082 16 14.2708 16H7.72933C5.89187 16 4.97165 13.7521 6.27095 12.4373L11 7.65216Z"
          fill="#FDDFB3"
        />
        <path
          d="M11 7.65216L14.8891 11.5874L13.4306 13.0631C12.0882 14.4214 9.91172 14.4214 8.5693 13.0631L7.11084 11.5874L11 7.65216Z"
          fill="#E89D00"
        />
        <path
          d="M1.20997e-05 3.13043L5.81014 3.87317L2.65607 6.95513L-0.389771 3.76366L1.20997e-05 3.13043Z"
          fill="#F4B42E"
        />
        <path
          d="M20.3057 1.01877L17.875 3.47827L20.3057 5.93777L22 4.22336V2.73318L20.3057 1.01877Z"
          fill="#F4B42E"
        />
      </g>
    </svg>
  );
}

MathspacePremium.displayName = 'MathspacePremium';

export default MathspacePremium;
