import styled from '@emotion/styled';
import type { ReactNode } from 'react';

const IconSvg = styled.svg({
  display: 'inline-block',
  verticalAlign: 'middle',
  transition: 'fill 0.25s easy',
  flexShrink: 0,
});

type Props = {
  color?: string | undefined;
  size?: number | string | undefined;
  title?: string | undefined;
  titleId?: string | undefined;
  children: ReactNode;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
};

const VIEWBOX_WIDTH = 20;
const VIEWBOX_HEIGHT = 20;

const getTitleId = (titleId: string): string => `${titleId}-icon-title`;

const Icon = ({
  color = 'currentColor',
  size = '1em',
  title,
  titleId,
  children,
  viewBoxWidth,
  viewBoxHeight,
}: Props) =>
  children ? (
    <IconSvg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox={`0 0 ${viewBoxWidth || VIEWBOX_WIDTH} ${
        viewBoxHeight || VIEWBOX_HEIGHT
      }`}
      aria-labelledby={title && titleId ? getTitleId(titleId) : undefined}
    >
      {title && titleId && <title id={getTitleId(titleId)}>{title}</title>}
      {children}
    </IconSvg>
  ) : null;

export default Icon;
