import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Templates2 = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path
      fillRule="evenodd"
      d="M14.327 11.673a.68.68 0 0 1-1.36 0V3.428a1 1 0 0 0-1-1H4.86A.714.714 0 1 1 4.86 1h8.468a1 1 0 0 1 1 1v9.673ZM2.6 15a1 1 0 0 1-1-1V4.647a1 1 0 0 1 1-1h8.182a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H2.6Zm2.2-9.024a.636.636 0 0 0 0 1.273h3.783a.636.636 0 1 0 0-1.273H4.799Zm-.637 3.24c0-.352.285-.637.636-.637h3.784a.636.636 0 1 1 0 1.273H4.799a.636.636 0 0 1-.636-.637Z"
    />
  </Icon>
);
Templates2.displayName = 'Templates2';

export default Templates2;
