/* eslint-disable class-methods-use-this */
import type { CSSInputTypes } from 'aphrodite';
import { useState, useEffect, forwardRef } from 'react';

import Portal from 'ms-helpers/Portal';

import Modal, {
  exitAnimationDuration,
  type Props as InnerProps,
} from './Modal';

export type Props = InnerProps & {
  isOpen: boolean;
  onExitAnimationEnd?: () => void;
  portalStyles?: CSSInputTypes[];
};

const ModalPortal = forwardRef<HTMLDivElement, Props>(function ModalPortal(
  {
    isOpen,
    onExitAnimationEnd,
    portalStyles = [],
    showCloseButton = true,
    ...rest
  },
  ref,
) {
  const [_isOpen, setIsOpen] = useState(false);
  const [animateClose, setAnimateClose] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isOpen) {
      setIsOpen(true);
      setAnimateClose(false);
    } else {
      setAnimateClose(true);
      timeoutId = setTimeout(() => {
        setAnimateClose(false);
        setIsOpen(false);
        onExitAnimationEnd?.();
      }, exitAnimationDuration);
    }
    return () => clearTimeout(timeoutId);
    // Call sites aren't memoizing onExitAnimationEnd as this component
    // was ported from a Class component to a hook.
    // TODO Fix call sites, add onExitAnimationEnd dep
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Portal portalStyles={portalStyles} isOpen={_isOpen} hasScrollPrevention>
      <Modal
        {...rest}
        showCloseButton={showCloseButton}
        animateClose={animateClose}
        ref={ref}
      />
    </Portal>
  );
});

export default ModalPortal;
export {
  default as ModalBody,
  MODAL_LATERAL_PADDING,
  MODAL_VERTICAL_PADDING,
  MODAL_VERTICAL_PADDING_MEDIUM,
  MODAL_VERTICAL_PADDING_LARGE,
} from './ModalBody';
export { default as ModalFooter } from './ModalFooter';
export { default as ModalHeader } from './ModalHeader';
