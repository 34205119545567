import styled from '@emotion/styled';
import React, { type ReactNode } from 'react';

import CrossThickIcon from 'ms-components/icons/CrossThick';
import Portal from 'ms-helpers/Portal';
import { fontFamily, fontWeight, lineHeight, zIndex } from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { Logger } from 'ms-utils/app-logging';
import { onPressOrHover, tappable } from 'ms-utils/emotion';

type SnackbarId = string;
type SnackbarStack = ReadonlyArray<SnackbarId>;
type SnackbarContextType = {
  stack: SnackbarStack;
  addSnackbar: (id: SnackbarId) => void;
  removeSnackbar: (id: SnackbarId) => void;
};

const DISPLAY_DURATION = 5000;
const MAX_MESSAGE_LENGTH = 80;
const TRANSITION_DURATION = 300;

const SnackbarContext = React.createContext<SnackbarContextType>({
  stack: [],
  addSnackbar: () => {
    throw new Error('SnackbarContextProvider not found');
  },
  removeSnackbar: () => {
    throw new Error('SnackbarContextProvider not found');
  },
});

type SnackbarContextProviderProps = {
  children: ReactNode;
};

export function SnackbarContextProvider({
  children,
}: SnackbarContextProviderProps) {
  const [stack, updateStack] = React.useState<SnackbarStack>([]);

  const addSnackbar = React.useCallback(
    (snackbarId: SnackbarId) => {
      if (stack.indexOf(snackbarId) !== -1) {
        throw new Error('Snackbar already in the stack');
      }
      updateStack(prevStack => [...prevStack, snackbarId]);
    },
    [stack],
  );

  const removeSnackbar = React.useCallback(
    (snackbarId: SnackbarId) => {
      if (stack.indexOf(snackbarId) === -1) {
        throw new Error('Snackbar is not in the stack');
      }
      updateStack(prevStack => prevStack.filter(s => s !== snackbarId));
    },
    [stack],
  );

  return (
    <SnackbarContext.Provider
      value={{
        stack,
        addSnackbar,
        removeSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}

const Wrapper = styled.div({
  bottom: 0,
  position: 'fixed',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  transition: `transform ${TRANSITION_DURATION}ms`,
  zIndex: zIndex.snackbar,
});

const SNACKBAR_HEIGHT = 48;
const SnackbarElement = styled.div({
  background: colors.cloudBurst,
  color: colors.athensGray,
  display: 'flex',
  fontFamily: `${fontFamily.body}`,
  pointerEvents: 'auto',
  alignItems: 'center',
  height: SNACKBAR_HEIGHT,
  borderRadius: (SNACKBAR_HEIGHT / 3) * 2,
  marginBottom: 24,
  paddingLeft: 20,
  paddingRight: 20,
  // These sizes are derived from Google's Material Design snackbar spec
  // https://material.io/guidelines/components/snackbars-toasts.html#
  maxWidth: 560,
  minWidth: 280,
});

const Actions = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
});

const Action = styled.a({
  fontWeight: fontWeight.semibold,
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  padding: 8,
  marginLeft: 12,
  ':focus': {
    outline: 'none',
  },
  ...tappable,
  color: colors.white,
  ...onPressOrHover({
    color: alternateColors.white,
  }),
});

const Message = styled.div({
  color: colors.white,
  fontSize: 16,
  lineHeight: lineHeight.body,
  fontWeight: fontWeight.normal,
  cursor: 'default',
  flexGrow: 1,
  textAlign: 'center',
});

type Props = {
  isOpen: boolean;
  id: SnackbarId;
  message: string;
  actionLabel?: string | undefined;
  handleAction?: (() => void) | undefined;
  actionHref?: string | undefined;
  dismissable?: boolean | undefined;
  persistent?: boolean | undefined;
  onHideCallback?: (() => unknown) | undefined;
  displayDuration?: number | undefined;
  actionComponent?: ReactNode | undefined;
};

export function Snackbar({
  isOpen,
  id,
  message,
  actionLabel,
  handleAction,
  actionHref,
  onHideCallback,
  displayDuration = DISPLAY_DURATION,
  dismissable = false,
  persistent = false,
  actionComponent,
}: Props) {
  if (message.length > MAX_MESSAGE_LENGTH) {
    Logger.error(
      `<Snackbar> Message should be shorter than ${MAX_MESSAGE_LENGTH} characters, but was ${message.length}.`,
      { extra: { message } },
    );
  }

  const { stack, addSnackbar, removeSnackbar } =
    React.useContext(SnackbarContext);

  const [shouldRender, setShouldRender] = React.useState(isOpen);
  const show = React.useCallback(() => {
    setShouldRender(true);
  }, []);

  const hide = React.useCallback(() => {
    const timeout = setTimeout(() => {
      setShouldRender(false);
      clearTimeout(timeout);
    }, TRANSITION_DURATION);
  }, []);

  const dismiss = React.useCallback(() => {
    if (stack.indexOf(id) !== -1) {
      removeSnackbar(id);
      hide();
      if (onHideCallback != null) {
        onHideCallback();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stack]);

  React.useEffect(() => {
    if (isOpen && stack.indexOf(id) === -1) {
      show();
      const timeout = setTimeout(() => {
        addSnackbar(id);
        clearTimeout(timeout);
      });
    }
    if (!isOpen && stack.indexOf(id) !== -1) {
      removeSnackbar(id);
      hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (!persistent) {
      const timeout = setTimeout(dismiss, displayDuration);
      return () => {
        clearTimeout(timeout);
      };
    } else {
      return () => {}; // Keep TS happy with consistent return type
    }
  }, [dismiss, displayDuration, persistent]);

  // TS has incorrect React types and doesn't allow false as a return value
  if (!shouldRender) return null;

  return (
    <Portal isOpen>
      <Wrapper
        style={{
          transform: `translateY(${-stack.indexOf(id) * 100}%)`,
        }}
      >
        <SnackbarElement>
          <Message>{message}</Message>

          <Actions>
            {actionLabel != null && (
              <Action
                role="button"
                tabIndex={0}
                onClick={handleAction}
                href={actionHref}
                target="_blank"
                rel="noopener noreferrer"
              >
                {actionLabel}
              </Action>
            )}

            {dismissable && (
              <Action role="button" tabIndex={0} onClick={dismiss}>
                <CrossThickIcon size={20} />
              </Action>
            )}
          </Actions>
          {actionComponent}
        </SnackbarElement>
      </Wrapper>
    </Portal>
  );
}
