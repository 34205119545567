/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateOrEditFolderModalEditFolderMutationVariables = {
    taskTemplateGroupId: string;
    title: string;
    description: string;
    isDistrictShared?: boolean | null | undefined;
};
export type CreateOrEditFolderModalEditFolderMutationResponse = {
    readonly editTaskTemplateGroupDetails: {
        readonly taskTemplateGroup: {
            readonly id: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly message: string;
        }>;
    };
};
export type CreateOrEditFolderModalEditFolderMutation = {
    readonly response: CreateOrEditFolderModalEditFolderMutationResponse;
    readonly variables: CreateOrEditFolderModalEditFolderMutationVariables;
};



/*
mutation CreateOrEditFolderModalEditFolderMutation(
  $taskTemplateGroupId: ID!
  $title: String!
  $description: String!
  $isDistrictShared: Boolean
) {
  editTaskTemplateGroupDetails(taskTemplateGroupId: $taskTemplateGroupId, title: $title, description: $description, isDistrictShared: $isDistrictShared) {
    taskTemplateGroup {
      id
    }
    errors {
      message
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "description"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDistrictShared"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskTemplateGroupId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "isDistrictShared",
        "variableName": "isDistrictShared"
      },
      {
        "kind": "Variable",
        "name": "taskTemplateGroupId",
        "variableName": "taskTemplateGroupId"
      },
      {
        "kind": "Variable",
        "name": "title",
        "variableName": "title"
      }
    ],
    "concreteType": "ManageTaskTemplateGroupResult",
    "kind": "LinkedField",
    "name": "editTaskTemplateGroupDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplateGroup",
        "kind": "LinkedField",
        "name": "taskTemplateGroup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MutationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOrEditFolderModalEditFolderMutation",
    "selections": (v4/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateOrEditFolderModalEditFolderMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "f57a5ef06793a622e38750f5fce64563",
    "id": null,
    "metadata": {},
    "name": "CreateOrEditFolderModalEditFolderMutation",
    "operationKind": "mutation",
    "text": "mutation CreateOrEditFolderModalEditFolderMutation(\n  $taskTemplateGroupId: ID!\n  $title: String!\n  $description: String!\n  $isDistrictShared: Boolean\n) {\n  editTaskTemplateGroupDetails(taskTemplateGroupId: $taskTemplateGroupId, title: $title, description: $description, isDistrictShared: $isDistrictShared) {\n    taskTemplateGroup {\n      id\n    }\n    errors {\n      message\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9c395d07e862b2632452529b65371b1d';
export default node;
