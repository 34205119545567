import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const StudyNotesIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={13}
    viewBoxHeight={15}
  >
    <path d="M11.1668 0.500061H2.16683C1.1555 0.500061 0.333496 1.32206 0.333496 2.33339V13.1667C0.333496 14.1781 1.1555 15.0001 2.16683 15.0001H11.1668C12.1782 15.0001 13.0002 14.1781 13.0002 13.1667V2.33339C13.0002 1.32206 12.1782 0.500061 11.1668 0.500061ZM3.66683 4.33339H7.00016C7.36817 4.33339 7.66683 4.63206 7.66683 5.00006C7.66683 5.36806 7.36817 5.66673 7.00016 5.66673H3.66683C3.29883 5.66673 3.00016 5.36806 3.00016 5.00006C3.00016 4.63206 3.29883 4.33339 3.66683 4.33339ZM10.3335 11.0001H3.66683C3.29883 11.0001 3.00016 10.7014 3.00016 10.3334C3.00016 9.96539 3.29883 9.66673 3.66683 9.66673H10.3335C10.7015 9.66673 11.0002 9.96539 11.0002 10.3334C11.0002 10.7014 10.7015 11.0001 10.3335 11.0001ZM10.3335 8.33339H3.66683C3.29883 8.33339 3.00016 8.03473 3.00016 7.66673C3.00016 7.29873 3.29883 7.00006 3.66683 7.00006H10.3335C10.7015 7.00006 11.0002 7.29873 11.0002 7.66673C11.0002 8.03473 10.7015 8.33339 10.3335 8.33339Z" />
  </Icon>
);

StudyNotesIcon.displayName = 'StudyNotesIcon';

export default StudyNotesIcon;
