import { css } from '@emotion/css';
import styled from '@emotion/styled';
import React from 'react';

import {
  CheckMark as CheckMarkIcon,
  Copy as CopyIcon,
} from 'ms-components/icons';
import { BodyM, BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import { boxShadow } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';
import { ControlledTooltip } from 'ms-ui-primitives/TooltipNew';
import { copyToClipboard } from 'ms-utils/clipboard';
import { tappable, onPressOrHover } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import keyDownMap from 'ms-utils/keyDownMap';

const PILL_PADDING_X = 6;
const PILL_PADDING_Y = 4;
type PillProps = { selected: boolean };
const Pill = styled.div<PillProps>(({ selected }) => ({
  padding: `${PILL_PADDING_Y}px ${PILL_PADDING_X}px`,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: selected ? colors.grey90 : 'transparent',
  ...tappable,
  ...onPressOrHover({
    backgroundColor: colors.porcelain,
  }),
  ':focus': {
    outline: 'none',
    borderColor: selected ? colors.grey90 : colors.grey10,
  },
}));

const TooltipContent = styled(Stack.V)({
  borderRadius: 8,
  padding: 12,
  backgroundColor: colors.grey,
  width: 304,
  boxShadow: boxShadow.slight,
});

export default function OutcomePill({
  code,
  description,
}: {
  code: string;
  description: string;
}) {
  const tooltip = useBoolean();

  const isCopied = useBoolean();
  React.useEffect(() => {
    // Re-enable CopyToClipboardButton after 2 seconds
    if (isCopied.value) {
      const timer = setTimeout(isCopied.setFalse, 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return;
  }, [isCopied.value, isCopied.setFalse]);

  return (
    <ControlledTooltip
      isTooltipVisible={tooltip.value}
      onShowTooltip={tooltip.setTrue}
      onHideTooltip={tooltip.setFalse}
      onToggleTooltip={tooltip.toggle}
      styledContent={
        <div className={css({ padding: 8 })}>
          <TooltipContent gap={2}>
            <Stack.H center>
              <BodyM bold color="white">
                {code}
              </BodyM>

              <Stack.Spacer.H width={4} grow />

              <Button
                isCircle
                onClick={e => {
                  // On touch devices, click on tooltip toggles itself. This is to prevent to close it when clicking on the copy button
                  e.stopPropagation();
                  isCopied.setTrue();
                  copyToClipboard(code);
                }}
              >
                {isCopied.value ? (
                  <CheckMarkIcon color={colors.white} size={16} />
                ) : (
                  <CopyIcon color={colors.white} size={16} />
                )}
              </Button>
            </Stack.H>
            <BodyM color="white">{description}</BodyM>
          </TooltipContent>
        </div>
      }
    >
      <Pill
        tabIndex={0}
        onKeyDown={keyDownMap({
          ENTER: [tooltip.toggle, { preventDefault: true }],
          SPACE: [tooltip.toggle, { preventDefault: true }],
          TAB: tooltip.setFalse,
        })}
        selected={tooltip.value}
      >
        <BodyS bold lineHeight="14px" color="grey90">
          {code}
        </BodyS>
      </Pill>
    </ControlledTooltip>
  );
}
