import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled, styledVerticallyScrollable } from 'ms-utils/emotion';

export const FlyoutBody = styled({
  default: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    padding: 6 * BASE_UNIT,
    flexGrow: 1,
    ...styledVerticallyScrollable,
  },
  noPadding: {
    padding: 0,
  },
  notScrollable: {
    overflow: 'hidden',
  },
});
