import { useViewer } from 'ms-helpers/Viewer/Renderer';
import type {
  FeatureFlags,
  UpdateFeatureFlags,
} from 'ms-helpers/Viewer/ViewerProvider';

export default function useFeatureFlags(): readonly [
  FeatureFlags,
  UpdateFeatureFlags,
] {
  const { featureFlags, updateFeatureFlags } = useViewer();
  return [featureFlags, updateFeatureFlags];
}
