import styled from '@emotion/styled';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { InfoFilled } from 'ms-components/icons';
import LanternLogo from 'ms-components/logos/LanternLogo';
import { HStack, HSpacer } from 'ms-pages/Lantern/primitives/Stack';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import { colors, tappable } from 'ms-pages/Lantern/styles';
import { font, fontSize, fontWeight } from 'ms-styles/base';
import Bold from 'ms-ui-primitives/Bold';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { signupSunflowerPath } from 'ms-utils/urls';

const CheckInHeaderWrapper = styled(HStack)({
  alignItems: 'center',
});

const CurriculumInfo = styled(HStack)<{
  onClick?: (() => void) | void;
  'data-tracking-id': string;
}>({
  alignItems: 'center',
  color: colors.eggplant,
  fontSize: fontSize.headerTitle,
  fontWeight: fontWeight.semibold,
  ...tappable,
});

const InfoTitle = styled('h1')({
  ...font.gilroyHeading,
});

export type CheckinCurriculumInformation = {
  strandName: string;
  gradeLevel: string;
  curriculumTitle: string;
};

type Props = {
  title: string;
  description: string;

  // This data is used purely for the demo account. When present, it will display
  // the name of the curriculum with a modal on click that prompts the user to sign up
  curriculumInformation?: CheckinCurriculumInformation | undefined;
};

function CheckInHeader({ title, description, curriculumInformation }: Props) {
  return (
    <CheckInHeaderWrapper>
      <LanternLogo width={24} />
      <HSpacer width={12} />
      <Tooltip backgroundColor={colors.grey} content={description}>
        <HeadingM>{title}</HeadingM>
      </Tooltip>
      <HSpacer grow width={12} />
      {curriculumInformation != null && (
        <CheckinInformationDetails {...curriculumInformation} />
      )}
    </CheckInHeaderWrapper>
  );
}

function CheckinInformationDetails({
  strandName,
  gradeLevel,
  curriculumTitle,
}: CheckinCurriculumInformation) {
  const modal = useBoolean();
  return (
    <>
      <CurriculumInfo
        onClick={modal.setTrue}
        data-tracking-id="DemoStudent/CheckIn/CurriculumInfo"
      >
        Grade {gradeLevel} {strandName}
        <HSpacer width={12} />
        <InfoFilled size={16} />
      </CurriculumInfo>

      <Modal
        theme="dark"
        onClose={modal.setFalse}
        isOpen={modal.value}
        showCloseButton={false}
        width={400}
      >
        <ModalBody>
          <InfoTitle>This demo check-in covers the following:</InfoTitle>
          <VSpacer height={12} />
          <div>
            <Datum label="Grade">{gradeLevel}</Datum>
            <Datum label="Strand">{strandName}</Datum>
            <Datum label="Curriculum">{curriculumTitle}</Datum>
          </div>
          <VSpacer height={12} />
          <div>
            <Link
              to={signupSunflowerPath}
              data-tracking-id="DemoStudent/CheckIn/CurriculumInfo/SignUp"
            >
              Sign up for a free account
            </Link>{' '}
            to try other topics
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

function Datum({ label, children }: { label: string; children: ReactNode }) {
  return (
    <p>
      {label}: <Bold>{children}</Bold>
    </p>
  );
}

export { CheckInHeader };
