/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ClassCodeModalQueryVariables = {
    classId: string;
};
export type ClassCodeModalQueryResponse = {
    readonly class: {
        readonly pk: number;
        readonly id: string;
        readonly code: string | null;
    } | null;
};
export type ClassCodeModalQuery = {
    readonly response: ClassCodeModalQueryResponse;
    readonly variables: ClassCodeModalQueryVariables;
};



/*
query ClassCodeModalQuery(
  $classId: ID!
) {
  class(id: $classId) {
    pk
    id
    code
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "classId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "classId"
      }
    ],
    "concreteType": "Class",
    "kind": "LinkedField",
    "name": "class",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClassCodeModalQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClassCodeModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8a442d0e88a05d172fe7559864c2342",
    "id": null,
    "metadata": {},
    "name": "ClassCodeModalQuery",
    "operationKind": "query",
    "text": "query ClassCodeModalQuery(\n  $classId: ID!\n) {\n  class(id: $classId) {\n    pk\n    id\n    code\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fd810e0f47ef497b9e2caf3fe2e8dc55';
export default node;
