import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const District = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <>
      <path d="M3.89375 15.68C3.89375 15.8567 4.03702 16 4.21375 16H6.45375C6.63048 16 6.77375 15.8571 6.77375 15.6804C6.77375 15.3195 6.77375 14.7607 6.77375 14.3996C6.77375 14.2229 6.63048 14.08 6.45375 14.08H4.21375C4.03702 14.08 3.89375 14.2233 3.89375 14.4V15.68Z" />
      <path d="M1 15.0625C1 15.5803 1.41973 16 1.9375 16C2.45527 16 2.875 15.5805 2.875 15.0627C2.875 14.7674 2.875 14.4433 2.875 14.1473C2.875 13.6171 3.30481 13.1875 3.835 13.1875H6.6025C7.13269 13.1875 7.5625 13.6173 7.5625 14.1475V15.0625C7.5625 15.5803 7.98223 16 8.5 16C9.01777 16 9.4375 15.5845 9.4375 15.0668C9.4375 13.1067 9.4375 8.62834 9.4375 6.64327C9.4375 6.11307 9.00769 5.6875 8.4775 5.6875H1.96C1.42981 5.6875 1 6.11731 1 6.6475V15.0625ZM2.875 8.96875C2.875 8.70987 3.08487 8.5 3.34375 8.5H7.09375C7.35263 8.5 7.5625 8.70987 7.5625 8.96875C7.5625 9.22763 7.35263 9.4375 7.09375 9.4375H3.34375C3.08487 9.4375 2.875 9.22763 2.875 8.96875ZM2.875 10.8438C2.875 10.5849 3.08487 10.375 3.34375 10.375H7.09375C7.35263 10.375 7.5625 10.5849 7.5625 10.8438C7.5625 11.1026 7.35263 11.3125 7.09375 11.3125H3.34375C3.08487 11.3125 2.875 11.1026 2.875 10.8438Z" />
      <path d="M13.5075 16C14.0377 16 14.4675 15.5702 14.4675 15.04V0.96C14.4675 0.429807 14.0377 0 13.5075 0H5.71C5.17981 0 4.75 0.429807 4.75 0.96V3.79C4.75 4.32019 5.17981 4.75 5.71 4.75H9.415C9.94519 4.75 10.375 5.17981 10.375 5.71V15.04C10.375 15.5702 10.8048 16 11.335 16H13.5075ZM13.0612 7.5175C13.3201 7.5175 13.53 7.72737 13.53 7.98625C13.53 8.24513 13.3201 8.455 13.0612 8.455H11.7812C11.5224 8.455 11.3125 8.24513 11.3125 7.98625C11.3125 7.72737 11.5224 7.5175 11.7812 7.5175H13.0612ZM13.0612 10.0325C13.3201 10.0325 13.53 10.2424 13.53 10.5012C13.53 10.7601 13.3201 10.97 13.0612 10.97H11.7812C11.5224 10.97 11.3125 10.7601 11.3125 10.5012C11.3125 10.2424 11.5224 10.0325 11.7812 10.0325H13.0612ZM13.0612 12.5475C13.3201 12.5475 13.53 12.7574 13.53 13.0162C13.53 13.2751 13.3201 13.485 13.0612 13.485H11.7812C11.5224 13.485 11.3125 13.2751 11.3125 13.0162C11.3125 12.7574 11.5224 12.5475 11.7812 12.5475H13.0612ZM13.53 5.51625C13.53 5.77513 13.3201 5.985 13.0612 5.985H11.7812C11.5224 5.985 11.3125 5.77513 11.3125 5.51625C11.3125 5.25737 11.5224 5.0475 11.7812 5.0475H13.0612C13.3201 5.0475 13.53 5.25737 13.53 5.51625ZM6.29375 2.74875C6.29375 2.48987 6.50362 2.28 6.7625 2.28H12.865C13.1239 2.28 13.3337 2.48987 13.3337 2.74875C13.3337 3.00763 13.1239 3.2175 12.865 3.2175H6.7625C6.50362 3.2175 6.29375 3.00763 6.29375 2.74875Z" />
    </>
  </Icon>
);

District.displayName = 'District';

export default District;
