import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import { mobileFirst } from 'ms-helpers/style/breakpoints';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled, tappable } from 'ms-utils/emotion';
import { getTeacherWorksheetTemplateGroupsDetailUrl } from 'ms-utils/urls';

import type { TaskTemplateGroupList_templateGroups$key } from './__generated__/TaskTemplateGroupList_templateGroups.graphql';
// 🚨 TODO unwind this when we use relay-compiler's builtin typescript emitter and
// react-relay's builtin hooks, which will work properly unlike this dodgy 3rd party things.
//
// The 3rd party 'relay-hooks' and the 3rd party typescript emitter for relay-compiler
// are not compatible. The " $data" property in the emitted types looks like this:
// WorkHistory_events$data | undefined
// The | undefined is incompatible with the, relay-hooks package, as must be removed.
type FixFragmentArrayKeyType<T extends readonly any[]> = ReadonlyArray<{
  [P in keyof T[number]]: NonNullable<T[number][P]>;
}>;
type FixedTemplateGroupsKey =
  FixFragmentArrayKeyType<TaskTemplateGroupList_templateGroups$key>;
const ITEM_PER_ROW = 2;
const TaskTemplateGroupListWrapper = styled({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.normal,
  marginBottom: 3 * BASE_UNIT,
});
const TaskTemplateGroupContainer = styled({
  display: 'flex',
  flexDirection: 'column',
  width: `calc(${(100 / ITEM_PER_ROW).toFixed(3)}% - ${2 * BASE_UNIT}px)`,
  marginRight: 4 * BASE_UNIT,
  marginBottom: 4 * BASE_UNIT,
  overflow: 'hidden',
  ':nth-child(even)': {
    marginRight: 0,
  },
});
const TaskTemplateGroupLink = styled(
  {
    textDecoration: 'none',
    ...tappable,
  },
  'a',
); // styled also leaks an any type.
const TaskTemplateGroupCover = styled(
  mobileFirst({
    default: {
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      width: '100%',
      height: 16 * BASE_UNIT,
      borderRadius: BASE_UNIT,
      backgroundColor: colors.athensGray,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      padding: 3 * BASE_UNIT,
    },
    desktop1280: {
      height: 21 * BASE_UNIT,
      padding: 4 * BASE_UNIT,
    },
  }),
);
const TaskTemplateGroupCaption = styled({
  fontSize: fontSize.card,
  marginTop: BASE_UNIT * 2,
  ':empty': {
    borderRadius: BASE_UNIT,
    backgroundColor: colors.athensGray,
  },
});
const TaskTemplateGroupTitle = styled({
  fontWeight: fontWeight.semibold,
  textAlign: 'left',
  color: colors.white,
  flex: '0 1 auto',
  paddingRight: BASE_UNIT * 2,
  wordBreak: 'break-word',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
const TaskTemplateGroupDescription = styled({
  fontWeight: fontWeight.normal,
  fontSize: fontSize.small,
  color: colors.white,
  marginTop: BASE_UNIT * 2,
});
export default function TaskTemplateGroupList({
  templateGroups: templateGroupsKey,
}: {
  templateGroups: FixedTemplateGroupsKey;
}) {
  const templateGroups = useFragment(
    graphql`
      fragment TaskTemplateGroupList_templateGroups on TaskTemplateGroup
      @relay(plural: true) {
        id
        title
        thumbnailImageUrl
        taskTemplateCount
      }
    `,
    templateGroupsKey,
  );
  return (
    <TaskTemplateGroupListWrapper>
      {templateGroups.map(item => (
        <TaskTemplateGroupContainer key={item.id}>
          <TaskTemplateGroupLink
            href={getTeacherWorksheetTemplateGroupsDetailUrl({
              worksheetTemplateGroupId: item.id,
            })}
          >
            <TaskTemplateGroupCover
              style={{
                background: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("${item.thumbnailImageUrl}")`,
              }}
            >
              <TaskTemplateGroupCaption>
                <TaskTemplateGroupTitle>{item.title}</TaskTemplateGroupTitle>
                <TaskTemplateGroupDescription>
                  {item.taskTemplateCount} templates
                </TaskTemplateGroupDescription>
              </TaskTemplateGroupCaption>
            </TaskTemplateGroupCover>
          </TaskTemplateGroupLink>
        </TaskTemplateGroupContainer>
      ))}
    </TaskTemplateGroupListWrapper>
  );
}
