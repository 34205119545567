import {
  lineHeight,
  fontFamily,
  fontSize,
  fontWeight,
  inputHeight,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { sizeToFontSize } from 'ms-ui-primitives/Button';
import { styled } from 'ms-utils/emotion';

export const Title = styled({
  color: colors.cloudBurst,
  fontFamily: fontFamily.body,
  fontSize: fontSize.large,
  fontWeight: fontWeight.semibold,
  lineHeight: lineHeight.title,
});

export const Root = styled({
  margin: '0 auto',
  maxWidth: 640,
  fontFamily: fontFamily.body,
  color: colors.cloudBurst,
});

export const Loading = styled({
  default: {
    color: colors.grayChateau,
    fontSize: sizeToFontSize.medium,
    fontWeight: fontWeight.semibold,
    visibility: 'hidden',
  },
  visible: {
    transition: `visibility ${transition} 0.5s`, // with a delay
    visibility: 'visible',
  },
});

export const Footer = styled({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gridGap: 4 * BASE_UNIT,
  minHeight: inputHeight,
});
