import type { ReactNode } from 'react';

import { fontSize, fontWeight, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { PADDING_X, PADDING_Y } from 'ms-ui-primitives/Tooltip';
import { styled } from 'ms-utils/emotion';

const TooltipContentStyle = styled({
  fontSize: fontSize.xSmall,
  fontWeight: 'initial',
  lineHeight: lineHeight.body,
  textAlign: 'left',
  whiteSpace: 'normal',
  textTransform: 'none',
});

export const TooltipTitle = styled({
  default: {
    fontSize: fontSize.xSmall,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.body,

    ':not(:last-child)': {
      marginBottom: BASE_UNIT,
    },
  },
  isUppercase: {
    textTransform: 'uppercase',
  },
});

export const TooltipDivider = styled({
  height: 1,
  margin: `${PADDING_Y}px -${PADDING_X}px`,
  backgroundColor: colors.shuttleGray,
});

type Props = {
  children: ReactNode;
  minWidth?: number | string | undefined;
  maxWidth?: number | string | undefined;
};

export const TooltipContent = ({ children, minWidth, maxWidth }: Props) => (
  <TooltipContentStyle style={{ minWidth, maxWidth }}>
    {children}
  </TooltipContentStyle>
);
