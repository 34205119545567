import styled from '@emotion/styled';
import React, { Suspense, useEffect } from 'react';
import { useLazyLoadQuery, graphql } from 'react-relay';

import { MAX_GROUP_TO_BE_ASSIGNABLE } from 'ms-pages/Teacher/TaskTemplates/hooks/constants';
import { EmptyTemplatesWarningWrapper } from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow/components/EmptyTemplatesWarning/EmptyTemplatesWarningWrapper';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import extractNode from 'ms-utils/relay/extractNode';

import type { EmptyTemplatesWarningQuery } from './__generated__/EmptyTemplatesWarningQuery.graphql';

const query = graphql`
  query EmptyTemplatesWarningQuery(
    $templateGroupId: ID!
    $maxGroupToBeAssignable: Int!
  ) {
    taskTemplateGroup(id: $templateGroupId) {
      taskTemplateConnection(first: $maxGroupToBeAssignable) {
        edges {
          node {
            id
            problemContents {
              id
            }
          }
        }
      }
    }
  }
`;

const LoaderWrapper = styled.div({
  width: '100%',
});

export default function EmptyTemplatesWarning({
  templateIds,
  templateGroupId,
  hasEmptyTemplates,
  setHasEmptyTemplates,
}: {
  templateIds: string[];
  templateGroupId: string;
  hasEmptyTemplates: boolean;
  setHasEmptyTemplates: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const data = useLazyLoadQuery<EmptyTemplatesWarningQuery>(query, {
    templateGroupId,
    maxGroupToBeAssignable: MAX_GROUP_TO_BE_ASSIGNABLE,
  });

  if (data.taskTemplateGroup == null) {
    // If query fails, it means we couldn't confirm that
    // all templates are non-empty, so we set the state to true
    // which prevents bulk custom assignment
    setHasEmptyTemplates(true);
    // This will be caught by the ErrorBoundary
    throw new Error('EmptyTemplatesWarningQuery failed');
  }

  useEffect(() => {
    // We don't want to set the state if the data is not yet available
    // to prevent flickering of the empty template warning message
    if (data?.taskTemplateGroup != null) {
      const templates = extractNode(
        data.taskTemplateGroup.taskTemplateConnection,
      );
      const templateObjects = templates.filter(template =>
        templateIds.includes(template.id),
      );
      const newHasEmptyTemplates = templateObjects.some(
        template => template.problemContents.length === 0,
      );
      if (newHasEmptyTemplates !== hasEmptyTemplates) {
        setHasEmptyTemplates(newHasEmptyTemplates);
      }
    }
  }, [
    hasEmptyTemplates,
    data,
    data.taskTemplateGroup,
    templateIds,
    setHasEmptyTemplates,
  ]);

  return (
    <Suspense
      fallback={
        <LoaderWrapper>
          <MinorSpinner scale={0.5} />
        </LoaderWrapper>
      }
    >
      {hasEmptyTemplates && (
        <EmptyTemplatesWarningWrapper message="Empty template(s) detected. Please remove empty template(s)." />
      )}
    </Suspense>
  );
}
