import { graphql } from 'react-relay';

import useMutation from 'ms-pages/Lantern/utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-pages/Lantern/utils/relay/useMutation';

import type {
  useNewStyleSkipQuestionMutationResponse,
  useNewStyleSkipQuestionMutationVariables,
} from './__generated__/useNewStyleSkipQuestionMutation.graphql';

const mutation = graphql`
  mutation useNewStyleSkipQuestionMutation(
    $checkInId: ID!
    $duration: Int!
    $questionId: ID!
    $strandId: ID!
    $includeStrandStatus: Boolean!
  ) {
    newStyleSkipQuestion(
      checkInId: $checkInId
      duration: $duration
      questionId: $questionId
    ) {
      questionAnswer {
        ...CheckInSessionQuestionResult @relay(mask: false)
        ...CheckInSessionQuestionAnswerStrandStatus
          @arguments(
            strandId: $strandId
            includeStrandStatus: $includeStrandStatus
          )
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useNewStyleSkipQuestion(): UseMutationResponse<
  useNewStyleSkipQuestionMutationResponse,
  useNewStyleSkipQuestionMutationVariables
> {
  return useMutation({
    mutation,
  });
}
