/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PreviewProblemAttachment_problemContent = {
    readonly attachment: string | null;
    readonly " $refType": "PreviewProblemAttachment_problemContent";
};
export type PreviewProblemAttachment_problemContent$data = PreviewProblemAttachment_problemContent;
export type PreviewProblemAttachment_problemContent$key = {
    readonly " $data"?: PreviewProblemAttachment_problemContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PreviewProblemAttachment_problemContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviewProblemAttachment_problemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachment",
      "storageKey": null
    }
  ],
  "type": "ProblemContent",
  "abstractKey": null
};
(node as any).hash = '4ea4cbb12b9abbac88d46dd8ab099a91';
export default node;
