import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import SelectorsWithSearch from 'ms-pages/Teacher/components/ClassAndStudentSelector/SelectorsWithSearch';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Center from 'ms-ui-primitives/Center';

import type { ClassAndStudentSelectorQuery } from './__generated__/ClassAndStudentSelectorQuery.graphql';
import type { ClassSelector_teacher } from './__generated__/ClassSelector_teacher.graphql';

const DEFAULT_LIST_HEIGHT = 200;
type ClassStudents = NonNullable<
  ClassSelector_teacher['classes']
>['edges'][0]['node']['students'];
export type ClassSelectionPayload = {
  readonly id: string;
  readonly title: string;
  readonly displayName: string | null;
  readonly students: ClassStudents;
};
export type StudentSelectionPayload = {
  id: string;
  firstName: string;
  lastName: string;
};
type Props = {
  onClassSelect: (klass: ClassSelectionPayload) => void;
  onStudentSelect: (student: StudentSelectionPayload) => void;
  // Used to filter out already selected items
  selectedStudents: ReadonlyArray<string>;
  selectedClasses: ReadonlyArray<string>;
  listHeight?: number;
};
export default function ClassAndStudentSelector({
  listHeight = DEFAULT_LIST_HEIGHT,
  selectedClasses,
  selectedStudents,
  onClassSelect,
  onStudentSelect,
}: Props) {
  const { props, error } = useQuery<ClassAndStudentSelectorQuery>(
    graphql`
      query ClassAndStudentSelectorQuery {
        viewer {
          profile {
            ... on Teacher {
              ...SelectorsWithSearch_teacher
            }
          }
        }
      }
    `,
    {},
    {
      networkCacheConfig: {
        // TODO fix this once we upgrade relay and are sure the types are sound
        // @ts-expect-error
        useCache: true,
        ttl: Infinity,
      },
    },
  );

  if (error != null)
    throw new Error(`ClassAndStudentSelectorQuery failed: ${error.message}`);

  return props?.viewer?.profile ? (
    <SelectorsWithSearch
      selectedClasses={selectedClasses}
      selectedStudents={selectedStudents}
      onClassSelect={onClassSelect}
      onStudentSelect={onStudentSelect}
      listHeight={listHeight}
      teacher={props?.viewer?.profile}
    />
  ) : (
    <Center>
      <MinorSpinner />
    </Center>
  );
}
