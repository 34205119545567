import type { ReactNode, ComponentType } from 'react';

import InfoFilled from 'ms-components/icons/InfoFilled';
import { Placeholder } from 'ms-pages/Teacher/components/Placeholder';
import Separator from 'ms-pages/Teacher/components/Separator';
import { fontWeight, lineHeight, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { styled } from 'ms-utils/emotion';

const ContentTitle = styled({
  color: colors.shuttleGray,
  fontWeight: fontWeight.semibold,
  lineHeight: lineHeight.title,
});

const SettingsModalInfoItemContentTitle = styled({
  color: colors.grey90,
  fontWeight: fontWeight.normal,
  fontSize: fontSize.small,
});

const Content = styled({
  lineHeight: lineHeight.title,
});
const SettingsModalContent = styled({
  height: 60,
  display: 'flex',
  flexDirection: 'column',
});

const LargeSettingsModalContent = styled({
  display: 'flex',
  flexDirection: 'column',
});

export const InfoGroup = styled({
  display: 'flex',
});

export const InfoItem = ({
  label,
  children,
  infoTooltipText,
}: {
  label: string;
  children: ReactNode;
  infoTooltipText?: string | undefined;
}) => (
  <Content>
    <ContentTitle>
      {label}

      {infoTooltipText != null && (
        <Tooltip isInlineFlex title={infoTooltipText} maxWidth={260}>
          &nbsp;
          <InfoFilled color={colors.shuttleGray} />
        </Tooltip>
      )}
    </ContentTitle>
    <Separator size={2 * BASE_UNIT} />
    <div>{children == null ? <Placeholder /> : children}</div>
  </Content>
);

export function SettingsModalInfoItem({
  label,
  children,
}: {
  label: string;
  children: ReactNode;
}) {
  return (
    <SettingsModalContent>
      <SettingsModalInfoItemContentTitle>
        {label}
      </SettingsModalInfoItemContentTitle>
      <div>{children}</div>
    </SettingsModalContent>
  );
}

export function LargeInfoItem({
  label,
  children,
  itemCount,
}: {
  label: string;
  children: ReactNode;
  itemCount?: number | undefined;
}) {
  return (
    <LargeSettingsModalContent>
      <SettingsModalInfoItemContentTitle>
        {label} {itemCount != null ? `(${itemCount})` : ''}
      </SettingsModalInfoItemContentTitle>
      <div>{children}</div>
    </LargeSettingsModalContent>
  );
}

export const InfoItemSeparator: ComponentType<{}> = () => (
  <Separator size={4 * BASE_UNIT} />
);
