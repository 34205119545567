import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Retry = ({
  color = 'D4D7D9',
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={12}
    viewBoxHeight={12}
  >
    <path d="M10.6185 2.23758V3.34225C10.5391 3.20468 10.4537 3.07029 10.3618 2.93963C10.0403 2.48233 9.65094 2.07985 9.20436 1.74334C8.75246 1.40282 8.25272 1.13802 7.71905 0.956312C7.1671 0.768378 6.58914 0.673096 6.00129 0.673096C5.38743 0.673096 4.78534 0.776797 4.2118 0.981333C3.65751 1.17899 3.14236 1.46605 2.68064 1.83455C2.22349 2.1994 1.83162 2.63368 1.51596 3.12531C1.1944 3.62615 0.960936 4.17274 0.822058 4.74986C0.732827 5.12071 0.961107 5.49368 1.33196 5.58291C1.38627 5.59597 1.44061 5.60224 1.4941 5.60224C1.80594 5.60224 2.08884 5.38956 2.16501 5.07302C2.36852 4.22725 2.85764 3.46056 3.54227 2.91415C3.88432 2.64117 4.26566 2.42861 4.67572 2.28239C5.09996 2.1311 5.54594 2.0544 6.00129 2.0544C6.86521 2.0544 7.68538 2.32831 8.37312 2.84652C8.83848 3.19718 9.22216 3.6511 9.49313 4.16328H8.72795C8.34653 4.16328 8.03731 4.47249 8.03731 4.85392C8.03731 5.23535 8.34653 5.54456 8.72795 5.54456H11.3091C11.6906 5.54456 11.9998 5.23535 11.9998 4.85392V2.23758C11.9998 1.85615 11.6906 1.54694 11.3091 1.54694C10.9277 1.54694 10.6185 1.85615 10.6185 2.23758Z" />
    <path d="M0.690643 10.4531C1.07208 10.4531 1.38129 10.1439 1.38129 9.76245V8.65314C1.79271 9.36928 2.36557 9.98121 3.06059 10.4421C3.93206 11.0201 4.94726 11.326 5.99676 11.3269C5.99734 11.3269 5.9979 11.3269 5.99847 11.3269C5.99893 11.3269 5.9994 11.3269 5.99986 11.3269C6.0004 11.3269 6.00095 11.3269 6.00149 11.3269C6.00223 11.3269 6.00293 11.3269 6.00368 11.3269C6.61571 11.3263 7.21601 11.2226 7.78794 11.0187C8.34223 10.821 8.85739 10.534 9.31911 10.1655C9.77627 9.80064 10.1681 9.36634 10.4838 8.87472C10.8053 8.37389 11.0388 7.82731 11.1777 7.25018C11.2669 6.87935 11.0387 6.50638 10.6678 6.41715C10.2969 6.32792 9.92398 6.5562 9.83475 6.92704C9.63123 7.77282 9.1421 8.53951 8.45747 9.0859C8.11544 9.35887 7.7341 9.57146 7.32403 9.71765C6.90021 9.86877 6.45471 9.94547 5.99988 9.94563C5.2217 9.94532 4.46933 9.71898 3.82405 9.291C3.26758 8.92196 2.81686 8.42269 2.50844 7.83678H3.27181C3.65325 7.83678 3.96245 7.52756 3.96245 7.14614C3.96245 6.76469 3.65324 6.4555 3.27181 6.4555H0.690643C0.309211 6.4555 0 6.76469 0 7.14614V9.76245C0 10.1439 0.309211 10.4531 0.690643 10.4531Z" />
  </Icon>
);

Retry.displayName = 'Retry';

export default Retry;
