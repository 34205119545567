import Star from 'ms-pages/Lantern/components/icons/Star';
import { BodyM, Bold } from 'ms-pages/Lantern/primitives/Typography';
import type { ColorProp } from 'ms-pages/Lantern/primitives/Typography/Body';
import type { MasteryLevel } from 'ms-utils/masteryLevel';
import { assertUnreachable } from 'ms-utils/typescript-utils';

// This manual version of Extract is just to support Flow backwards compatibility
type MasteryLevelWithColorMapping =
  | 'EMERGING'
  | 'FAMILIAR'
  | 'PROFICIENT'
  | 'MASTERED';
type ProficiencyColorMap = Record<MasteryLevelWithColorMapping, ColorProp>;

// Can't use this until Flow backwards compat not required
// type ProficiencyColorMap = Record<
//   Extract<MasteryLevel, 'EMERGING' | 'FAMILIAR' | 'PROFICIENT' | 'MASTERED'>,
//   ColorProp
// >;

type Props = {
  masteryLevel: MasteryLevel;
  proficiencyColorMap?: Partial<ProficiencyColorMap> | undefined;
  size: number;
};

export function ProficiencyIcon({
  masteryLevel,
  proficiencyColorMap,
  size,
}: Props) {
  // We can't use this as a default param because
  // if the param was supplied we'd lose these values.
  // Instead we provide a blank map and merge any supplied values
  let defaultColorMap: ProficiencyColorMap = {
    EMERGING: 'grey90',
    FAMILIAR: 'eggplant',
    PROFICIENT: 'white',
    MASTERED: 'white',
  };

  let colorMap = Object.assign(defaultColorMap, proficiencyColorMap ?? {});

  switch (masteryLevel) {
    case 'NOT_STARTED':
    case 'EXPLORING': {
      return null;
    }
    case 'EMERGING': {
      return (
        <BodyM color={colorMap[masteryLevel]}>
          <Bold>1</Bold>
        </BodyM>
      );
    }
    case 'FAMILIAR': {
      return (
        <BodyM color={colorMap[masteryLevel]}>
          <Bold>2</Bold>
        </BodyM>
      );
    }
    case 'PROFICIENT': {
      return (
        <BodyM color={colorMap[masteryLevel]}>
          <Bold>3</Bold>
        </BodyM>
      );
    }
    case 'MASTERED': {
      return <Star size={size} />;
    }
    default: {
      assertUnreachable(masteryLevel);
    }
  }
}
