/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GameStatus = "COUNTING_DOWN" | "ENDED" | "INITIAL" | "IN_PROGRESS" | "PENDING_RESULTS" | "RESULT_DETERMINED" | "STARTED";
export type GameType = "TUG_OF_WAR";
export type TugOfWarQueryVariables = {
    taskId: string;
    startDate: string;
    endDate: string;
    numberOfTransactions: number;
    after?: string | null | undefined;
};
export type TugOfWarQueryResponse = {
    readonly task: {
        readonly games?: ReadonlyArray<{
            readonly id: string;
            readonly gameType: GameType;
            readonly gameStatus: GameStatus;
            readonly duration: number;
            readonly startedAt: string | null;
            readonly teams: ReadonlyArray<{
                readonly students: ReadonlyArray<{
                    readonly id: string;
                    readonly user: {
                        readonly firstName: string;
                        readonly avatar: string;
                    };
                }>;
            }>;
        }> | undefined;
        readonly assignedProblems?: ReadonlyArray<{
            readonly id: string;
        }> | undefined;
        readonly assignedStudentsClasses?: ReadonlyArray<{
            readonly gamification: {
                readonly points: {
                    readonly transactions: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly amount: number;
                                readonly student: {
                                    readonly studentId: string | null;
                                };
                                readonly problemId: string | null;
                            };
                        }>;
                        readonly pageInfo: {
                            readonly endCursor: string | null;
                        };
                    };
                };
            } | null;
        }> | undefined;
    } | null;
};
export type TugOfWarQuery = {
    readonly response: TugOfWarQueryResponse;
    readonly variables: TugOfWarQueryVariables;
};



/*
query TugOfWarQuery(
  $taskId: ID!
  $startDate: DateTime!
  $endDate: DateTime!
  $numberOfTransactions: Int!
  $after: ID
) {
  task(id: $taskId) {
    __typename
    ... on CustomTask {
      games {
        id
        gameType
        gameStatus
        duration
        startedAt
        teams {
          students {
            id
            user {
              firstName
              avatar
              id
            }
          }
        }
      }
      assignedProblems {
        id
      }
      assignedStudentsClasses {
        gamification {
          points(taskIds: [$taskId], sourceTypes: [ANSWERED_QUESTION], startDate: $startDate, endDate: $endDate) {
            transactions(first: $numberOfTransactions, after: $after, orderBy: TIMESTAMP_ASCENDING) {
              edges {
                node {
                  id
                  amount
                  student {
                    studentId
                  }
                  problemId
                }
              }
              pageInfo {
                endCursor
              }
            }
          }
        }
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfTransactions"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startDate"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "taskId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gameType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gameStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AssignedProblem",
  "kind": "LinkedField",
  "name": "assignedProblems",
  "plural": true,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "ClassGamification",
  "kind": "LinkedField",
  "name": "gamification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "endDate",
          "variableName": "endDate"
        },
        {
          "kind": "Literal",
          "name": "sourceTypes",
          "value": [
            "ANSWERED_QUESTION"
          ]
        },
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        },
        {
          "items": [
            {
              "kind": "Variable",
              "name": "taskIds.0",
              "variableName": "taskId"
            }
          ],
          "kind": "ListValue",
          "name": "taskIds"
        }
      ],
      "concreteType": "ClassPointsEarned",
      "kind": "LinkedField",
      "name": "points",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "after"
            },
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "numberOfTransactions"
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "TIMESTAMP_ASCENDING"
            }
          ],
          "concreteType": "PointsTransactionConnection",
          "kind": "LinkedField",
          "name": "transactions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PointsTransactionEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PointsTransaction",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "amount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StudentBasicInfo",
                      "kind": "LinkedField",
                      "name": "student",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "studentId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "problemId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TugOfWarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Game",
                "kind": "LinkedField",
                "name": "games",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Student",
                        "kind": "LinkedField",
                        "name": "students",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Class",
                "kind": "LinkedField",
                "name": "assignedStudentsClasses",
                "plural": true,
                "selections": [
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CustomTask",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TugOfWarQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Game",
                "kind": "LinkedField",
                "name": "games",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Team",
                    "kind": "LinkedField",
                    "name": "teams",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Student",
                        "kind": "LinkedField",
                        "name": "students",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Class",
                "kind": "LinkedField",
                "name": "assignedStudentsClasses",
                "plural": true,
                "selections": [
                  (v14/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CustomTask",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1df6d03338a6e54446ff3d6842537f60",
    "id": null,
    "metadata": {},
    "name": "TugOfWarQuery",
    "operationKind": "query",
    "text": "query TugOfWarQuery(\n  $taskId: ID!\n  $startDate: DateTime!\n  $endDate: DateTime!\n  $numberOfTransactions: Int!\n  $after: ID\n) {\n  task(id: $taskId) {\n    __typename\n    ... on CustomTask {\n      games {\n        id\n        gameType\n        gameStatus\n        duration\n        startedAt\n        teams {\n          students {\n            id\n            user {\n              firstName\n              avatar\n              id\n            }\n          }\n        }\n      }\n      assignedProblems {\n        id\n      }\n      assignedStudentsClasses {\n        gamification {\n          points(taskIds: [$taskId], sourceTypes: [ANSWERED_QUESTION], startDate: $startDate, endDate: $endDate) {\n            transactions(first: $numberOfTransactions, after: $after, orderBy: TIMESTAMP_ASCENDING) {\n              edges {\n                node {\n                  id\n                  amount\n                  student {\n                    studentId\n                  }\n                  problemId\n                }\n              }\n              pageInfo {\n                endCursor\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f0bf1b1e77f1d85e08315dca70cfcdec';
export default node;
