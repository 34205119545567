export default function EmptyIllustration() {
  return (
    <svg
      width="53px"
      height="45px"
      viewBox="0 0 53 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle id="Oval" fill="#F1F1F3" cx="20" cy="20" r="20" />
        <path
          d="M19.0753041,20.4852815 L14.7952714,16.2052488 C13.8552868,15.2652642 15.2652637,13.8552873 16.2052483,14.7952719 L21.1902694,19.7802931 C21.5796238,20.1696475 21.5796238,20.8009157 21.1902694,21.19027 L16.2052483,26.1752912 C15.2652637,27.1152758 13.8552868,25.7052989 14.7952714,24.7653143 L19.0753041,20.4852815 Z M21.8952579,20.4852815 L26.1752906,24.7653143 C27.1152752,25.7052989 25.7052983,27.1152758 24.7653137,26.1752912 L19.7802925,21.19027 C19.3909382,20.8009157 19.3909382,20.1696475 19.7802925,19.7802931 L24.7653137,14.7952719 C25.7052983,13.8552873 27.1152752,15.2652642 26.1752906,16.2052488 L21.8952579,20.4852815 Z"
          stroke="#D4D7D9"
          strokeWidth="2"
          fillRule="nonzero"
          transform="translate(20.485281, 20.485282) rotate(-45.000000) translate(-20.485281, -20.485282) "
        />
        <g
          transform="translate(28.000000, 20.000000)"
          fill="#9A9DAB"
          stroke="#FFFFFF"
          strokeWidth="2"
        >
          <path
            d="M7.83734206,12.2907747 L7.83734206,3.92911531 C7.83734206,2.71631089 8.81519512,1.72630787 10.0252969,1.72630787 C11.2354738,1.72630787 12.2134327,2.7163444 12.2133157,3.92911531 L12.2133157,6.21692149 C12.4141798,6.15561231 12.6273176,6.12262412 12.848194,6.12262412 C13.6738684,6.12262412 14.3914287,6.58351451 14.763984,7.26158527 C15.0401532,7.13457199 15.3472141,7.06371787 15.6708991,7.06371787 C16.574823,7.06371787 17.3492111,7.61618589 17.6832246,8.40120972 C17.9337924,8.30035002 18.2072816,8.24482253 18.4937962,8.24482253 C19.7039393,8.24482253 20.681687,9.23511987 20.681687,10.4479501 L20.681687,15.3710227 C20.681687,16.6434088 19.9503875,19.545944 19.1787643,22.0362153 L18.9606178,22.7402428 L8.53228326,22.7402428 L8.25992959,22.1731935 C8.18197951,22.0108989 7.83745127,21.2824403 7.77109316,21.1431795 C7.75229264,21.1037397 7.75229264,21.1037397 7.73349964,21.0643828 C7.47138045,20.515696 7.28692031,20.1465217 7.18051036,19.9628472 C6.84924346,19.3916938 6.51376541,18.8672218 5.66097197,17.5671577 C4.42176236,15.6787807 3.82745527,14.7398021 3.14193096,13.5358105 C2.47332936,12.3601347 2.55221023,11.3021731 3.2572271,10.5966625 C3.66150291,10.1914344 4.2083891,9.96618579 4.79902555,9.95940572 L4.81257725,9.95942147 C5.37403696,9.96257311 5.91036403,10.1772824 6.35037022,10.5951126 L6.41597726,10.6648745 L7.83734206,12.2907747 Z"
            transform="translate(11.681687, 12.233275) rotate(-30.000000) translate(-11.681687, -12.233275) "
          />
        </g>
      </g>
    </svg>
  );
}
