import styled from '@emotion/styled';

import { fonts } from 'ms-pages/Lantern/styles';
import { colors } from 'ms-styles/colors';

import type { TextColors } from './types';

export type ColorProp = TextColors | 'inherit';

type Size = 'XL' | 'L' | 'M' | 'S' | 'XS';

function mapSizeToFontSize(size: Size) {
  switch (size) {
    case 'XL':
      return 20;
    case 'L':
      return 18;
    case 'M':
      return 16;
    case 'S':
      return 14;
    case 'XS':
      return 12;
    default:
      return 16;
  }
}

function mapSizeToLineHeight(size: Size) {
  switch (size) {
    case 'XL':
      return '28px';
    case 'L':
      return '24px';
    case 'M':
      return '24px';
    case 'S':
      return '20px';
    case 'XS':
      return '16px';
    default:
      return '24px';
  }
}

type BodyProps = {
  color?: ColorProp;
  inline?: boolean;
  bold?: boolean;
  center?: boolean;
  lineHeight?: string;
};

// TODO remove ComponentType once Flow backwards compat not required
function makeBodyComponent(size: Size) {
  return styled('div', {
    shouldForwardProp: p =>
      p !== 'color' &&
      p !== 'inline' &&
      p !== 'bold' &&
      p !== 'center' &&
      p !== 'lineHeight',
  })<BodyProps>(
    ({ color = 'grey', inline = false, bold = false, center, lineHeight }) => ({
      color: color === 'inherit' ? 'inherit' : colors[color],
      ...fonts.body,
      ...(bold ? fonts.bodyBold : {}),
      fontSize: mapSizeToFontSize(size),
      lineHeight: lineHeight ?? mapSizeToLineHeight(size),
      display: inline ? 'inline' : 'block',
      ...(center ? { textAlign: 'center' } : {}),
    }),
  );
}

const BodyXL = makeBodyComponent('XL');
const BodyL = makeBodyComponent('L');
const BodyM = makeBodyComponent('M');
const BodyS = makeBodyComponent('S');
const BodyXS = makeBodyComponent('XS');

export { BodyXL, BodyL, BodyM, BodyS, BodyXS };
