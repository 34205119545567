import { useEffect } from 'react';

import { unsafeKeys } from 'ms-utils/typescript-utils';

type TabConfig<Tab> = {
  shouldRender: boolean;
  fallbacks?: Tab[];
};

export default function useTabsFallback<Tab extends string>(
  tabs: Record<Tab, TabConfig<Tab>>,
  {
    activeTab,
    setActiveTab,
    handleNoAvailableTabs,
    fallbacks = [],
  }: {
    activeTab: Tab;
    setActiveTab: (tab: Tab) => void;
    handleNoAvailableTabs?: (() => void) | undefined;
    fallbacks?: Tab[] | void;
  },
) {
  useEffect(() => {
    // if the active tab is available, all good!
    if (tabs[activeTab].shouldRender) return;

    // if no tabs are available and there's a handler for that, call it
    if (
      handleNoAvailableTabs != null &&
      Object.entries<TabConfig<Tab>>(tabs).every(
        ([, tabConfig]) => !tabConfig.shouldRender,
      )
    ) {
      handleNoAvailableTabs();
      return;
    }

    // if the active tab is not available, switch to
    // - the first available tab in the tab config fallbacks, if any
    // - to the first available tab in the general fallbacks, if any
    // - to the first tab in the fallback fallbacks

    let nextTab: Tab | void;

    nextTab = tabs[activeTab].fallbacks?.find(tab => tabs[tab].shouldRender);
    if (nextTab != null) {
      setActiveTab(nextTab);
      return;
    }

    nextTab = fallbacks?.find(tab => tabs[tab].shouldRender);
    if (nextTab != null) {
      setActiveTab(nextTab);
      return;
    }

    const exhaustiveFallbacks = unsafeKeys(tabs);

    nextTab = exhaustiveFallbacks.find(tab => tabs[tab].shouldRender);
    if (nextTab != null) {
      setActiveTab(nextTab);
      return;
    }
  }, [activeTab, setActiveTab, tabs, handleNoAvailableTabs, fallbacks]);
}
