import { css, StyleSheet } from 'aphrodite';
import * as React from 'react';

import MiloStarEye from 'ms-components/illustrations/MiloStarEye';
import { useSnowplow } from 'ms-helpers/Snowplow';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Separator from 'ms-ui-primitives/Separator';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { styled } from 'ms-utils/emotion';
import { signupSunflowerStudentUrl } from 'ms-utils/urls';

import PipeformModal from './PipeformModal';
import { formStyles } from './styles';

const Text = styled({
  fontSize: 18,
  textAlign: 'center',
});

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
});

// The data team wants to track how many teachers
// click the link to the student form
const SIGNUP_SUNFLOWER_STUDENT_URL = `${signupSunflowerStudentUrl}?from_teacher_signup`;

function TeacherSignupContactUs() {
  const { trackStructEvent } = useSnowplow();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    trackStructEvent({
      category: 'signup_sunflower',
      action: 'viewed_contact_us',
      label: 'teacher',
    });
    // Only send the event on initial render (it's effectively a page view,
    // but dashboard builders find it more convenient to consider as an event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={css(formStyles.container, styles.container)}>
      <MiloStarEye />
      <VSpacer height={32} />
      <div className={css(formStyles.titleContainer)}>
        <div className={css(formStyles.title)}>Free trial for Teachers</div>
      </div>
      <Separator size={3} />
      <Text>
        Contact us for a free trial of our school packages. You can also{' '}
        <AnchorButton isInline size="large" href={SIGNUP_SUNFLOWER_STUDENT_URL}>
          sign up for a student account
        </AnchorButton>{' '}
        to try out the student side.
      </Text>
      <VSpacer height={32} />
      <Button
        type="primary"
        size="large"
        isRound
        onClick={() => setIsModalOpen(true)}
      >
        Contact us
      </Button>
      <PipeformModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export { TeacherSignupContactUs };
