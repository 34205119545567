import { TeacherSkillsReport } from 'ms-pages/Lantern/views/Teacher/TeacherSkillsReport';
import Modal from 'ms-pages/Sunflower/ui-primitives/Modal';

export default function TeacherStudentSkillsReportModal({
  studentId,
  closeModal,
  isOpen,
}: {
  studentId: string;
  closeModal: () => void;
  isOpen: boolean;
}) {
  return (
    <Modal title="Student Skills Report" isOpen={isOpen} onClose={closeModal}>
      <TeacherSkillsReport studentId={studentId} inModal />
    </Modal>
  );
}
