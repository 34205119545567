import { StyleSheet } from 'aphrodite';

const ITEM_WRAP_GUTTER = 10;

const itemWrap = {
  marginLeft: ITEM_WRAP_GUTTER,
  marginRight: ITEM_WRAP_GUTTER,
};

const styles = StyleSheet.create({
  shrinkwrap: {
    display: 'inline-block',
    paddingTop: '20px',
    touchAction: 'none',
  },

  container: {
    display: 'flex',
    marginLeft: -ITEM_WRAP_GUTTER,
    marginRight: -ITEM_WRAP_GUTTER,
  },

  itemWrap,

  itemWrapTransparent: {
    ...itemWrap,
    opacity: 0,
  },

  itemWrapReadOnly: {
    ...itemWrap,
    cursor: 'not-allowed',
  },
});

export default styles;
