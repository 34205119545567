/**
 * Copy of `ms-pages/JoinClass/styles.jsx` with some amendments
 */

import { StyleSheet } from 'aphrodite';

import {
  borderRadiusUI,
  breakPoints,
  font,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  transition,
  zIndex,
} from 'ms-styles/base';
import { alternateColors, colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import {
  onHover,
  styledVerticallyScrollable,
  tappable,
} from 'ms-utils/emotion';

const FORM_WIDTH = 320;
const WIDTH = FORM_WIDTH + 24;

export const styles = StyleSheet.create({
  content: {
    minHeight: 420, // TODO: Update when "I have a join code" button is added
  },
});

export const formStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    color: colors.mako,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    // TODO: these styles don't make sense:
    // the width will be limited by `maxWidth` value.
    // Consider redoing the layout of the forms.
    maxWidth: WIDTH,
    width: '100%',
    [`@media (min-width: 650px)`]: {
      // width: 650,
    },
  },
  roleSelectorContainer: {
    alignItems: 'flex-start',
    color: colors.mako,
    display: 'flex',
    flexDirection: 'row',
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 4 * BASE_UNIT,
    maxWidth: FORM_WIDTH * 2 + 10 * BASE_UNIT,
    width: '100%',
    [`@media (max-width: ${breakPoints.large}px)`]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  infoPanelContainer: {
    maxWidth: FORM_WIDTH,
    width: '100%',
    height: '100%',
    marginRight: 10 * BASE_UNIT,
    marginTop: 0,
    [`@media (max-width: ${breakPoints.large}px)`]: {
      order: 2,
      marginRight: 0,
      marginTop: 6 * BASE_UNIT,
    },
  },
  progressBar: {
    textAlign: 'center',
    marginBottom: 15 * BASE_UNIT,
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    ...font.gilroyLargerHeading,
    color: colors.mako,
    marginBottom: 16,
    marginTop: 16,
  },
  titleSecondary: {
    fontSize: 24,
    fontWeight: fontWeight.semibold,
    marginBottom: 16,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: fontWeight.semibold,
  },
  text: {
    fontSize: 16,
    lineHeight: lineHeight.body,
    margin: '0 auto',
    maxWidth: WIDTH,
    textAlign: 'center',
  },
  strong: {
    fontWeight: fontWeight.semibold,
  },
  checkboxContainer: {
    display: 'flex',
    marginTop: 2 * BASE_UNIT,
  },
  classCodeContainer: {
    maxWidth: '100%',
    width: WIDTH,
    marginTop: 25,
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: fontWeight.semibold,
    lineHeight: lineHeight.body,
  },
  tabContainer: {
    maxWidth: '100%',
    width: WIDTH,
    marginTop: 3 * BASE_UNIT,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: FORM_WIDTH,
    width: '100%',

    [`@media (max-width: ${breakPoints.large}px)`]: {
      order: 1,
    },
  },
  formError: {
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    textAlign: 'center',
    lineHeight: lineHeight.body,
    marginBottom: 5 * BASE_UNIT,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [`@media (min-width: ${breakPoints.small}px)`]: {
      flexDirection: 'row',
    },
  },
  formRowField: {
    [`@media (min-width: ${breakPoints.small}px)`]: {
      flexBasis: '48%',
    },
  },
  formRowLink: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
    marginTop: 4 * BASE_UNIT,
    textAlign: 'right',
  },
  forgotPasswordLink: {
    marginTop: 2 * BASE_UNIT,
    textAlign: 'right',
  },
  disclaimer: {
    color: colors.mako,
    fontSize: 14,
    lineHeight: lineHeight.body,
    margin: 'auto',
    maxWidth: 371,
    textAlign: 'center',
  },
  actionWrapper: {
    marginTop: 6 * BASE_UNIT,
    display: 'flex',
    justifyContent: 'center',
  },
  selectWrapper: {
    minWidth: 200,
    zIndex: zIndex.popover,
  },
  infoIcon: {
    fontSize: 15,
  },
  linkStyle: {
    cursor: 'pointer',
    textDecoration: 'underline',
    transition: `color ${transition}`,
    fontFamily: fontFamily.body,
    color: 'inherit',
  },
  footerLinks: {
    marginTop: 8 * BASE_UNIT,
    textAlign: 'center',
  },
  footerLink: {
    fontSize: 16,
    fontWeight: fontWeight.semibold,
    color: colors.eggplant,
    ...onHover({
      color: alternateColors.eggplant,
    }),
  },
  dropdownMenu: {
    marginTop: BASE_UNIT,
    padding: 2 * BASE_UNIT,
    maxHeight: 150,
    width: FORM_WIDTH,
    borderRadius: borderRadiusUI,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.16)',
    backgroundColor: colors.white,
    color: colors.shuttleGray,
    ...styledVerticallyScrollable,
  },
  dropdownMenuItem: {
    zIndex: zIndex.dropdownMenuItem,
    padding: 2 * BASE_UNIT,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    color: colors.cloudBurst,
    ...tappable,
  },
  dropdownMenuItemInfo: {
    fontSize: fontSize.small,
    color: colors.shuttleGray,
  },
  dropdownMenuSearch: {
    zIndex: zIndex.dropdownMenuSearch,
  },
});
