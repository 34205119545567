type Grade = {
  id: string;
  title: string;
};

export function titleForGradeSelector(
  grade: Grade | null | undefined,
  allGrades: readonly Grade[] | null | undefined,
): string {
  if (!grade || !allGrades || allGrades.length === 0) return '';

  // Per https://app.clickup.com/t/6924803/OCTO-9900, we want to show the
  // postfix "and above" for the last grade in a list when showing grade selectors
  // to make it clear to teachers and students that they should pick that option
  // if they are in a higher grade than we officially support.

  const lastGrade = allGrades[allGrades.length - 1];
  const isLastGrade = grade.id != null && grade.id === lastGrade!.id;

  return `${grade.title}${isLastGrade ? ' & above' : ''}`;
}
