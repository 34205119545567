import { css } from '@emotion/css';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from 'react';
import { graphql } from 'react-relay';
import { useFragment } from 'relay-hooks';

import Internationalisation from 'ms-components/icons/Internationalisation';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { useLanguageSelectorContext } from 'ms-pages/Textbooks/components/LanguageSelector/LanguageSelectorContext';
import { LANGUAGE_MAP } from 'ms-pages/Textbooks/components/LanguageSelector/constants';
import { borderRadiusUILarge, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Popover from 'ms-ui-primitives/Popover';
import Separator from 'ms-ui-primitives/Separator';
import { onPressOrHover, tappable, styled } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';

import type { LanguageSelector_availableLanguages$key } from './__generated__/LanguageSelector_availableLanguages.graphql';

const PopoverBody = styled({
  background: 'white',
  borderRadius: borderRadiusUILarge,
  overflow: 'hidden',
  fontFamily: fontFamily.body,
  border: `1px solid ${colors.iron}`,
});
const MenuElementBase = styled({
  padding: 16,
  color: colors.cloudBurst,
});
const fragment = graphql`
  fragment LanguageSelector_availableLanguages on Syllabus {
    availableLocales
  }
`;
type Props = {
  syllabus: LanguageSelector_availableLanguages$key;
  // component with ref
  Anchor?: React.ComponentType<
    { onClick: () => void } & React.RefAttributes<HTMLDivElement>
  >;
};

const DefaultAnchor = forwardRef<HTMLDivElement, { onClick: () => void }>(
  function ({ onClick }, ref) {
    const [language] = useLanguageSelectorContext();

    return (
      <Button color="iron" onClick={onClick} label="Select language">
        {LANGUAGE_MAP[language].short}
        <Separator size={2} />
        <div ref={ref}>
          <Internationalisation size={21} color={colors.white} />
        </div>
      </Button>
    );
  },
);
export default function LanguageSelector({
  syllabus,
  Anchor = DefaultAnchor,
}: Props) {
  const { role } = useMaybeViewer() || { role: 'Other' };
  const [open, setOpen] = useState(false);
  const { availableLocales } = useFragment(fragment, syllabus);
  const languageSet: Set<string> = useMemo(() => {
    return new Set(availableLocales);
  }, [availableLocales]);
  const [language, setLanguage] = useLanguageSelectorContext();
  // This is an edge case where navigating between textbooks that have differing
  // language availabilities causes issues. It will cause a single reload, a better UX is desired here
  // in the future
  useEffect(() => {
    if (!languageSet.has(language)) {
      // There is an edge case here where this array could be [].
      // We set the language to EN_US as that's the default when
      // no language has been explicitly set.
      setLanguage(availableLocales[0] ?? 'EN_US');
    }
  }, [availableLocales, language, languageSet, setLanguage]);
  const anchor = useRef<HTMLDivElement>(null);
  if (languageSet.size <= 1 || role !== 'Student') return null;
  return (
    <>
      <Anchor
        onClick={() => {
          setOpen(true);
        }}
        ref={anchor}
      />
      {open && (
        <Popover
          onDismiss={() => {
            setOpen(false);
          }}
          popoverPosition="bottom-right"
          shouldDismissOnTapOut
          anchorElementRef={anchor}
          anchorOrigin="left"
        >
          <PopoverBody>
            <MenuElementBase>Select a translation language</MenuElementBase>
            <LanguageSelectorElements
              languageSet={languageSet}
              selectedLanguage={language}
              onSelectLanguage={language => {
                setLanguage(language);
                setOpen(false);
              }}
            />
          </PopoverBody>
        </Popover>
      )}
    </>
  );
}
type Language = ReturnType<typeof useLanguageSelectorContext>[0];
function LanguageSelectorElements({
  languageSet,
  selectedLanguage,
  onSelectLanguage,
}: {
  languageSet: Set<string>;
  selectedLanguage: Language;
  onSelectLanguage: (language: Language) => void;
}) {
  const languageOptions = useMemo(
    () =>
      Object.keys(LANGUAGE_MAP)
        .filter(key => languageSet.has(key))
        .map(key => ({
          languageValue: key as keyof typeof LANGUAGE_MAP,
          key: LANGUAGE_MAP[key as keyof typeof LANGUAGE_MAP].short,
          value: LANGUAGE_MAP[key as keyof typeof LANGUAGE_MAP].long,
        }))
        .map(({ key, value, languageValue }) => {
          return (
            <LanguageSelectorElement
              key={key}
              onSelect={() => onSelectLanguage(languageValue)}
              short={key}
              selected={selectedLanguage}
              value={value}
              className={css({
                ...tappable,
                ...onPressOrHover({
                  backgroundColor: colors.ironLight,
                }),
              })}
            />
          );
        }),
    [languageSet, onSelectLanguage, selectedLanguage],
  );
  const esc = useCallback(() => {
    onSelectLanguage(selectedLanguage);
  }, [onSelectLanguage, selectedLanguage]);
  return (
    <div
      role="menu"
      tabIndex={0}
      onKeyDown={keyDownMap({
        // focus handling was removed as it was broken
        // this element will be refactored in https://app.clickup.com/t/6924803/OCTO-10700
        // ARROW_DOWN: [focusNext, { preventDefault: true }],
        // ARROW_UP: [focusPrev, { preventDefault: true }],
        ESC: esc,
      })}
    >
      {languageOptions}
    </div>
  );
}
function LanguageSelectorElement({
  selected,
  short: key,
  value,
  onSelect,
  className,
}: {
  selected: Language;
  short: string;
  value: string;
  onSelect: () => void;
  className?: string;
}) {
  const ref = useRef<HTMLElement | null>(null);
  return (
    <MenuElementBase
      ref={ref}
      role="button"
      tabIndex={0}
      onClick={onSelect}
      onKeyDown={keyDownMap({
        ENTER: onSelect,
        SPACE: onSelect,
      })}
      style={{
        borderTop: `1px solid ${colors.iron}`,
        borderLeft:
          key === LANGUAGE_MAP[selected].short
            ? `5px solid ${colors.governorBay}`
            : '',
      }}
      className={className}
    >
      {value}
    </MenuElementBase>
  );
}
