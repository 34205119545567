let id = 0;

/**
 * Generates an id that will be unique for the given JS runtime environment.
 * The typical use case is for generating unique ids for React components.
 * Since these ids are only for identifying UI elements on the page they don't
 * need to be globally unique nor consistent across page reloads/sessions/etc.
 * @returns {number} A unique id.
 */
export default function genUniqueId(): number {
  const result = id;
  // We will assume that id will never exceed Number.MAX_SAFE_INTEGER.
  id += 1;
  return result;
}
