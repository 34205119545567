import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.3083 0.0126953C1.84333 0.0127164 1.46641 0.389669 1.46643 0.854641L1.46706 15.1483C1.46708 15.6133 1.84403 15.9902 2.309 15.9902C2.77398 15.9902 3.15089 15.6132 3.15087 15.1483L3.15054 7.51087L6.76274 7.50058L6.76988 10.0463C6.76995 10.0837 6.77729 10.1208 6.79147 10.1554C6.80566 10.19 6.82642 10.2214 6.85256 10.2478C6.8787 10.2742 6.9097 10.2951 6.94381 10.3093C6.97791 10.3236 7.01443 10.3308 7.05128 10.3306L14.8472 10.3082C14.8578 10.3083 14.8684 10.3077 14.879 10.3064C14.9256 10.3008 14.9701 10.2834 15.0085 10.2558C15.0468 10.2282 15.0777 10.1913 15.0983 10.1484C15.119 10.1056 15.1287 10.0581 15.1266 10.0104C15.1246 9.96272 15.1108 9.91632 15.0865 9.87546L13.495 7.19623L15.0719 4.50785C15.0973 4.46445 15.1109 4.41497 15.1114 4.36445C15.1119 4.31393 15.0992 4.26419 15.0746 4.22029C15.05 4.17643 15.0144 4.14 14.9714 4.11472C14.9283 4.08943 14.8795 4.07619 14.8298 4.07636L10.3769 4.0892L10.3699 1.54351C10.3698 1.50607 10.3625 1.46899 10.3484 1.43443C10.3342 1.39986 10.3134 1.36848 10.2873 1.34209C10.2612 1.31561 10.2302 1.29465 10.196 1.28042C10.1619 1.26618 10.1253 1.25896 10.0885 1.25917L3.15026 1.27926L3.15024 0.854564C3.15022 0.389592 2.77327 0.0126742 2.3083 0.0126953Z"
  />
);

const Lantern = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

Lantern.displayName = 'Lantern';

export default Lantern;
