import styled from '@emotion/styled';

import InfoFilledIcon from 'ms-components/icons/InfoFilled';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import { CustomTaskDuration } from 'ms-pages/Teacher/components/CreateTask/components/CustomTaskDuration';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { MAX_NUMBER_OF_QUESTIONS } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import Stack, { HSpacer, VSpacer } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

type Props = {
  numberOfQuestions: number;
  onRemoveAll: () => void;
  hideClearAllButton?: boolean | undefined;
  questionsCollapsed?: boolean | undefined;
  setQuestionsCollapsed?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  problems?: readonly Problem[] | undefined;
};

const TooManyQuestionsWarningBanner = styled(Stack.H)({
  padding: 12,
  borderRadius: 8,
  backgroundColor: colors.eggplant10,
});

export default function CustomTaskQuestionsHeader({
  numberOfQuestions,
  onRemoveAll,
  hideClearAllButton = false,
  questionsCollapsed,
  setQuestionsCollapsed,
  problems,
}: Props) {
  const confirmRemoveAllPrompt = useBoolean();
  const { trackStructEvent } = useSnowplow();
  const showCollapseAllButton =
    numberOfQuestions > 0 &&
    questionsCollapsed != null &&
    setQuestionsCollapsed != null;
  return (
    <Stack.V gap={8}>
      <Stack.H center width="100%">
        <BodyM bold>
          {numberOfQuestions} question{numberOfQuestions === 1 ? '' : 's'}
        </BodyM>
        <HSpacer width={8} />
        <CustomTaskDuration
          renderNullWhenZero
          showClock={false}
          hasCATFAPreviewStyles
          problems={problems}
        />
      </Stack.H>

      {numberOfQuestions >= MAX_NUMBER_OF_QUESTIONS && (
        <TooManyQuestionsWarningBanner gap={8} width="100%" center>
          <InfoFilledIcon color={colors.grey} />
          <BodyM bold>
            You've reached the maximum of {MAX_NUMBER_OF_QUESTIONS} questions
            for a task
          </BodyM>
        </TooManyQuestionsWarningBanner>
      )}
      {/* The gap on vertical stack already creates space, but we need to double the gap, this is for doubling the gap */}
      <VSpacer height={0} />

      <Stack.H center width="100%">
        {showCollapseAllButton && (
          <Button
            size="regular"
            type="tertiary"
            onClick={() => {
              trackStructEvent({
                category: 'create_task_from_anywhere',
                action: `clicked_${
                  questionsCollapsed ? 'expand' : 'collapse'
                }_all_questions`,
                label: 'custom_task_questions_header',
              });
              setQuestionsCollapsed(s => !s);
            }}
            padding={0}
            height={22}
          >
            {questionsCollapsed ? 'Expand All' : 'Collapse All'}
          </Button>
        )}
        <HSpacer width={0} grow />
        {!hideClearAllButton && numberOfQuestions > 0 && (
          <Button
            size="regular"
            color="eggplant"
            type="tertiary"
            height={22}
            padding={0}
            onClick={confirmRemoveAllPrompt.setTrue}
          >
            Clear all
          </Button>
        )}

        <ConfirmRemoveAllQuestionsPrompt
          isOpen={confirmRemoveAllPrompt.value}
          action={onRemoveAll}
          onClose={confirmRemoveAllPrompt.setFalse}
        />
      </Stack.H>
    </Stack.V>
  );
}

function ConfirmRemoveAllQuestionsPrompt({
  isOpen,
  onClose,
  action,
}: {
  isOpen: boolean;
  onClose: () => void;
  action: () => void;
}) {
  return (
    <Modal
      closeOnOverlayTap={false}
      width={480}
      isOpen={isOpen}
      onClose={onClose}
      isOverflowHidden
    >
      <div css={{ padding: 32, paddingTop: 40, display: 'flex' }}>
        <Stack.V width="100%">
          <HeadingM>Clear all questions?</HeadingM>
          <Stack.Spacer.V height={8} />
          <BodyM>You will clear all the questions you've added.</BodyM>

          <Stack.Spacer.V height={24} />
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button size="lanternSmall" onClick={onClose}>
              No, cancel
            </Button>
            <Button
              size="lanternSmall"
              isRound
              type="primary"
              onClick={() => {
                onClose();
                action();
              }}
            >
              Yes, clear all
            </Button>
          </div>
        </Stack.V>
      </div>
    </Modal>
  );
}
