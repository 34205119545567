/**
 * This is a function used to shuffle array elements in a random order.
 *
 * @module shuffleArray
 * @memberof array
 *
 */

const shuffleArray = <T>(array: T[]): T[] =>
  array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

export default shuffleArray;
