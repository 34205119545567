import type {
  TaskTypes,
  DateRangeState,
  TaskTypeSelector,
  SyllabusIds,
  Updater,
  SkillsSortBy,
} from 'ms-pages/Teacher/state/State';
import {
  serializeBool,
  deserializeBool,
  serializeBoolToPositiveFlag,
  deserializeBoolFromPositiveFlag,
} from 'ms-utils/urls/queryParams';
import type { QueryStringInt } from 'ms-utils/urls/queryParams';

export { TaskTypes };

export type TaskAssigneeType = 'CLASS' | 'STUDENTS';

export const updateDateRange: Updater<[DateRangeState]> =
  dateRangeState => state => ({
    ...state,
    ...dateRangeState,
  });

export const updatePeriod: Updater<[QueryStringInt]> = period => state => ({
  ...state,
  period,
});

export const setSyllabusIds: Updater<[SyllabusIds]> = syllabusIds => state => ({
  ...state,
  syllabusIds,
});

export const toggleWorkedOn: Updater<[]> = () => state => ({
  ...state,
  workedOn: serializeBool(!deserializeBool(state.workedOn)),
});

export const toggleTaskTypeSelection: Updater<[TaskTypes]> =
  taskType => state => {
    const taskTypeSelector: TaskTypeSelector = {
      ...state.taskTypeSelector,
      [taskType]: !state.taskTypeSelector[taskType],
    };

    return {
      ...state,
      taskTypeSelector,
    };
  };

export const setTaskAssigneeFilter: Updater<[TaskAssigneeType]> =
  selectedAssignees => state => ({
    ...state,
    selectedAssignees,
  });

export const clearAssigneeFilter: Updater<[]> = () => state => ({
  ...state,
  selectedAssignees: null,
});

export const toggleShowActivityChart: Updater<[]> = () => state => ({
  ...state,
  showActivityChart: serializeBoolToPositiveFlag(
    !deserializeBoolFromPositiveFlag(state.showActivityChart),
  ),
});

export const setGrowthPeriod: Updater<[string]> = growthPeriod => state => ({
  ...state,
  growthPeriod,
});

export const setSkillsSortBy: Updater<[SkillsSortBy]> =
  skillsSortBy => state => ({
    ...state,
    skillsSortBy,
  });
