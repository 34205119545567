import { StyleSheet, css } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
});

type Props = {
  children?: ReactNode;
  aphroditeStyles?: Array<CSSInputTypes>;
};

/*
 * The main purpose of the Page component at this time is to ensure that page
 * content does not end up sitting behind our fixed <Header /> component.
 */
const Page = ({ children, aphroditeStyles = [] }: Props) => (
  <div className={css(styles.page, ...aphroditeStyles)}>{children}</div>
);

export default Page;
