import { useEffect, useRef, type ReactNode } from 'react';

type InterceptorProps = {
  children: ReactNode;
  // Returning true will prevent the click from propagating
  onNavigate: (href: string | null | undefined) => boolean;
};

/**
 * Hijacker that intercepts downstream anchor click events and provides a callback
 * to intercept and abort the click event depending on the href of that node.
 *
 * This is used to show specific CTAs or prevent navigation to specific areas of the
 * app.
 */
export function DbCooper({ children, onNavigate }: InterceptorProps) {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = container.current;

    function walk(ev: MouseEvent) {
      let parent = ev.target;

      while (parent instanceof HTMLElement) {
        if (parent.tagName === 'A' && parent instanceof HTMLAnchorElement) {
          if (onNavigate(parent.getAttribute('href'))) {
            ev.preventDefault();
            break;
          }
        }
        parent = parent.parentElement;
      }
    }

    if (node == null) return;
    node.addEventListener('click', walk);

    return () => {
      node.removeEventListener('click', walk);
    };
    // SAFETY: Does global handling only. No dependencies.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onNavigate]);

  return (
    <div ref={container} style={{ display: 'contents' }}>
      {children}
    </div>
  );
}
