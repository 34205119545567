import { css, StyleSheet } from 'aphrodite';
import type { ComponentType } from 'react';
import { DragLayer } from 'react-dnd';

import CountingBlock from 'ms-components/math/NumberBuilder/CountingBlock';

type Item = {
  value: any;
  itemComponent: ComponentType<any>;
};

type Props = {
  item: Item;
  itemType: string;
  currentOffset: {
    x: number;
    y: number;
  };
  isDragging: boolean;
};

const styles = StyleSheet.create({
  layer: {
    cursor: 'pointer',
    height: '100%',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: 100,
  },

  componentWrapper: {
    display: 'inline-block',
    boxShadow: '0 3px 20px rgba(33, 55 , 77, .5)',
    cursor: 'grabbing',
  },
});

function getItemStyles(props: Props): Object {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

function renderItem(_itemType: string, item: Item) {
  let extraProps = {};
  if (item.itemComponent === CountingBlock) {
    extraProps = { hasPadding: false };
  }

  const Component = item.itemComponent;
  return (
    <div className={css(styles.componentWrapper)}>
      <Component value={item.value} {...extraProps} />
    </div>
  );
}

function DnDCustomDragLayer(props: Props) {
  const { item, itemType, isDragging } = props;

  if (!isDragging) {
    return null;
  }

  return (
    <div className={css(styles.layer)}>
      <div style={getItemStyles(props)}>{renderItem(itemType, item)}</div>
    </div>
  );
}

const enhance = DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}));

export default enhance(DnDCustomDragLayer);
