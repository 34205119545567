import { css, StyleSheet } from 'aphrodite';

import LoadingSpinner, {
  DEFAULT_SIZE as SPINNER_SIZE,
} from 'ms-ui-primitives/LoadingSpinner';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: SPINNER_SIZE / 4, // avoid LoadingSpinner appearing too low
  },
});

type Props = Record<string, never>;

const MajorSpinner = ({ ...style }: Props) => (
  <div className={css(styles.root)} style={style}>
    <LoadingSpinner />
  </div>
);

export default MajorSpinner;
