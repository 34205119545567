import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

export function ChatBubble({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={16}
      viewBoxWidth={16}
    >
      <path d="M12.447 2.814a6.992 6.992 0 0 0-9.894 0 6.995 6.995 0 0 0-1.314 8.072l-.72 3.17a.575.575 0 0 0 .687.688l3.17-.721c4.609 2.301 10.12-1.03 10.12-6.261a6.95 6.95 0 0 0-2.049-4.948ZM8.96 9.504H4.58a.575.575 0 0 1 0-1.15H8.96a.575.575 0 0 1 0 1.15Zm1.46-2.335H4.58a.575.575 0 0 1 0-1.15h5.838a.575.575 0 1 1 0 1.15Z" />
    </Icon>
  );
}

export default ChatBubble;
