import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getPathMatch } from 'ms-utils/urls/getPathMatch';

/* Takes a path and returns an object that contains the matched keys
 * of a path for a particular route.
 *
 * ex.
 * const {schoolId, classId} = usePathMatch(teacherBasePath, {exact: true);
 */
export function usePathMatch<Path extends string>(
  path: Path,
  options?: { exact: boolean } | null | undefined,
) {
  const { pathname } = useLocation();
  return useMemo(
    () => getPathMatch(path, pathname, options),
    [options, path, pathname],
  );
}
