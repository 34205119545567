import type { Item, Items } from 'ms-pages/Teacher/components/InputSelector';

export type SyllabusFocus = Item;
export type SchoolId = string;
export type StudentName = string;
export type Email = string;
export type Klass = Item;
export type Classes = Items;
export type GuardianEmails = string; // emails are comma separated
export type UseClassSyllabusFocus = boolean;
export type SyllabusFocusSelectedClass = Item;
export type SelfReportedGradeId = string;
export type UseClassGrade = boolean;
export type GradeSelectedClass = Item;

export const EMPTY_ITEM = { id: '', title: '' };

export type State = {
  firstName: StudentName;
  lastName: StudentName;
  shouldCheckForDuplicates: boolean;
  buttonsDisabled: boolean;
  syllabusFocus: SyllabusFocus;
  email: Email;
  classes: Classes;
  guardianEmails: GuardianEmails;
  useClassSyllabusFocus: UseClassSyllabusFocus;
  syllabusFocusSelectedClass: SyllabusFocusSelectedClass;
  selfReportedGradeId: SelfReportedGradeId;
  useClassGrade: UseClassGrade;
  gradeSelectedClass: GradeSelectedClass;
};

export type Updater<I extends any[]> = (...args: I) => (s: State) => State;

export const initialState: State = {
  firstName: '',
  lastName: '',
  shouldCheckForDuplicates: false,
  buttonsDisabled: true,
  syllabusFocus: EMPTY_ITEM,
  email: '',
  classes: [],
  guardianEmails: '',
  useClassSyllabusFocus: true,
  syllabusFocusSelectedClass: EMPTY_ITEM,
  selfReportedGradeId: '',
  useClassGrade: true,
  gradeSelectedClass: EMPTY_ITEM,
};
