import styled from '@emotion/styled';
import { StyleSheet } from 'aphrodite';
import { useState, useEffect, useMemo, useCallback } from 'react';

import CrossIcon from 'ms-components/icons/Cross';
import SearchIcon from 'ms-components/icons/Search';
import { type ColorName, colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Input from 'ms-ui-primitives/Input';
import useDebouncedValue from 'ms-utils/hooks/useDebouncedValue';

const ICON_SIZE = 4 * BASE_UNIT;
const ICON_BUTTON_SIZE = ICON_SIZE + BASE_UNIT;

const inputStyles = StyleSheet.create({
  searchInput: {
    border: '1px solid transparent',
    backgroundColor: colors.porcelain,
    //  find where the default border and color is applied from
  },
  searchInputWithLeftSearchButton: {
    paddingLeft: ICON_BUTTON_SIZE + 3 * BASE_UNIT,
  },
  withBorder: {
    border: `1px solid ${colors.ironLight}`,
  },
  whiteBackground: {
    background: colors.white,
  },
  seaShellBackground: {
    background: colors.seashell,
  },
  clearButtonPresent: {
    paddingRight: ICON_BUTTON_SIZE + 4 * BASE_UNIT,
  },
  searchButtonPresent: {
    paddingRight: 2 * ICON_BUTTON_SIZE + 5 * BASE_UNIT,
  },
  fullBorderRadius: {
    borderRadius: '9999px',
  },
});

const buttonStyles = {
  inlineButton: {
    position: 'absolute',
    top: '50%',
    marginTop: -ICON_BUTTON_SIZE / 2,
    width: ICON_BUTTON_SIZE,
    height: ICON_BUTTON_SIZE,
  },
  clearButton: {
    right: 2 * BASE_UNIT,
  },
  searchButton: {
    right: BASE_UNIT + ICON_BUTTON_SIZE + 2 * BASE_UNIT,
  },
  searchButtonLeft: {
    left: 2 * BASE_UNIT,
  },
};

const Wrapper = styled.div({
  display: 'flex',
});
const InputWrapper = styled.div({
  position: 'relative',
  flex: '0 0 100%',
});

type CoreOnlyProps = {
  onSubmit: (currentValue: string, debouncedValue?: string) => void;
};

type CoreSharedProps = {
  searchString: string;
  placeholder: string;
  whiteBackground?: boolean | undefined;
  seaShellBackground?: boolean | undefined;
  searchIconOnLeft?: boolean | undefined;
  withBorder?: boolean | undefined;
  borderColor?: string | undefined;
  height?: number | undefined;
  placeholderColor?: string | undefined;
  borderWidth?: number | undefined;
  trackingId?: string | undefined;
  autoFocus?: boolean | undefined;
  leftSearchIconColor?: ColorName | undefined;
  hasFullBorderRadius?: boolean | undefined;
};

type CoreProps = CoreOnlyProps & CoreSharedProps;

type SearchInputCoreAdditionalProps = {
  onSubmit: (value: string) => void;
  currentValue: string;
  updateValue: (value: string) => void;
};

type SearchInputCoreProps = CoreSharedProps & SearchInputCoreAdditionalProps;

type ExternalControlSearchInputAdditionalProps = {
  injectedSearchString: string;
  onSearchStringUpdate: (value: string) => void;
};

type ExternalSearchInputAdditionalProps =
  | {
      type?: 'static' | 'dynamic';
      injectedSearchString?: never;
      onSearchStringUpdate?: never;
    }
  | ({ type: 'external-control' } & ExternalControlSearchInputAdditionalProps);

type ExternalSearchInputProps = CoreProps & ExternalSearchInputAdditionalProps;

function SearchInputCore({
  searchString,
  onSubmit,
  placeholder,
  whiteBackground = false,
  searchIconOnLeft = false,
  seaShellBackground = false,
  withBorder = false,
  borderColor = undefined,
  height,
  placeholderColor,
  borderWidth,
  trackingId,
  currentValue,
  updateValue,
  autoFocus = false,
  leftSearchIconColor,
  hasFullBorderRadius = false,
}: SearchInputCoreProps) {
  const renderStyles = useMemo(
    () =>
      StyleSheet.create({
        // All these are invalid use, but are gated by a check at the Input
        // callsite which prevents this stuff from propagating.
        // @ts-expect-error
        height: { height },
        // @ts-expect-error
        borderColor: { borderColor },
        // @ts-expect-error
        borderWidth: { borderWidth },
      }),
    [borderColor, borderWidth, height],
  );

  return (
    <Wrapper>
      <InputWrapper>
        {searchIconOnLeft && (
          <Button
            isCircle
            styles={{
              ...buttonStyles.inlineButton,
              ...buttonStyles.searchButtonLeft,
            }}
            onClick={() => {
              onSubmit(currentValue);
            }}
            label="Search"
            trackingId={trackingId}
          >
            <SearchIcon
              size={ICON_SIZE}
              color={colors[leftSearchIconColor ?? 'grey90']}
            />
          </Button>
        )}
        <Input
          aphroditeStyles={[
            inputStyles.searchInput,
            currentValue !== '' && inputStyles.clearButtonPresent,
            currentValue !== searchString &&
              !searchIconOnLeft &&
              inputStyles.searchButtonPresent,
            whiteBackground && inputStyles.whiteBackground,
            searchIconOnLeft && inputStyles.searchInputWithLeftSearchButton,
            seaShellBackground && inputStyles.seaShellBackground,
            withBorder && inputStyles.withBorder,
            height != null && renderStyles.height,
            borderColor != null && renderStyles.borderColor,
            borderWidth != null && renderStyles.borderWidth,
            hasFullBorderRadius && inputStyles.fullBorderRadius,
          ]}
          placeholder={placeholder}
          placeholderColor={placeholderColor}
          value={currentValue}
          onChange={({ target: { value } }) => {
            updateValue(value);
          }}
          onEnter={() => {
            onSubmit(currentValue);
          }}
          trackingId={trackingId}
          autoFocus={autoFocus}
        />

        {currentValue !== searchString && !searchIconOnLeft && (
          <Button
            isCircle
            styles={{
              ...buttonStyles.inlineButton,
              ...buttonStyles.searchButton,
            }}
            onClick={() => {
              onSubmit(currentValue);
            }}
            label="Search"
          >
            <SearchIcon size={ICON_SIZE} />
          </Button>
        )}

        {currentValue !== '' && (
          /** TODO do something here for the grey*/
          <Button
            isCircle
            styles={{
              ...buttonStyles.inlineButton,
              ...buttonStyles.clearButton,
            }}
            onClick={() => {
              updateValue('');
              if (searchString !== '') onSubmit('');
            }}
            label="Clear"
          >
            <CrossIcon size={ICON_SIZE} />
          </Button>
        )}
      </InputWrapper>
    </Wrapper>
  );
}

function StaticSearchInput({ searchString, onSubmit, ...props }: CoreProps) {
  const [currentValue, updateValue] = useState(searchString);

  return (
    <SearchInputCore
      {...props}
      onSubmit={onSubmit}
      currentValue={currentValue}
      updateValue={updateValue}
      searchString={searchString}
    />
  );
}

function DynamicSearchInput({ searchString, onSubmit, ...props }: CoreProps) {
  const [currentValue, updateValue] = useState(searchString);
  const debouncedValue = useDebouncedValue(currentValue, 500);

  // submitting only the debounced value
  // to avoid excessive requests to backend
  const handleOnSubmit = useCallback(() => {
    onSubmit(
      currentValue,
      // we need this so the clear input button works
      currentValue === '' ? '' : debouncedValue,
    );
  }, [currentValue, debouncedValue, onSubmit]);

  useEffect(() => {
    handleOnSubmit();
  }, [currentValue, handleOnSubmit, onSubmit]);

  return (
    <SearchInputCore
      {...props}
      currentValue={currentValue}
      updateValue={updateValue}
      searchString={searchString}
      onSubmit={onSubmit}
    />
  );
}

function ExternalControlSearchInput({
  injectedSearchString,
  onSearchStringUpdate,
  ...props
}: CoreProps & ExternalControlSearchInputAdditionalProps) {
  return (
    <SearchInputCore
      {...props}
      currentValue={injectedSearchString}
      updateValue={onSearchStringUpdate}
    />
  );
}

export default function SearchInput({
  type = 'static',
  injectedSearchString,
  onSearchStringUpdate,
  ...props
}: ExternalSearchInputProps) {
  const isExternallyControlled =
    type === 'external-control' &&
    injectedSearchString != null &&
    onSearchStringUpdate != null;

  return isExternallyControlled ? (
    <ExternalControlSearchInput
      {...props}
      injectedSearchString={injectedSearchString}
      onSearchStringUpdate={onSearchStringUpdate}
    />
  ) : type === 'dynamic' ? (
    <DynamicSearchInput {...props} />
  ) : (
    <StaticSearchInput {...props} />
  );
}
