/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type JsxContentType = "ANSWER_KEY" | "ENGAGE_ACTIVITY" | "ENGAGE_ACTIVITY_TEACHER_NOTE" | "GLOSSARY_CONTENT" | "INVESTIGATION" | "INVESTIGATION_TEACHER_NOTE" | "LESSON" | "RETROFITTED_STUDENT_LESSON" | "RETROFITTED_STUDENT_LESSON_TEACHER_NOTE" | "SOLIDIFY_LESSON" | "SOLIDIFY_TEACHER_NOTE" | "STUDENT_OVERVIEW" | "STUDENT_REFERENCE" | "TEACHER_NOTE" | "TEACHER_OVERVIEW" | "TOPIC_ANSWER_KEY" | "TOPIC_ASSESSMENT" | "TOPIC_OVERVIEW" | "WORKSHEET";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type StudyNotesModalQuery_studyNote = {
    readonly id: string;
    readonly title: string;
    readonly note: string;
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly jsxContentType: JsxContentType | null;
    readonly jsxContent: string;
    readonly jsxContentLocale: SyllabusLocale | null;
    readonly subtopic: {
        readonly id: string;
    } | null;
    readonly highlightedData: string;
};
export type StudyNotesModalQuery_studyNote$data = StudyNotesModalQuery_studyNote;
export type StudyNotesModalQuery_studyNote$key = {
    readonly " $data"?: StudyNotesModalQuery_studyNote$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudyNotesModalQuery_studyNote">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "StudyNotesModalQuery_studyNote",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsxContentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsxContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jsxContentLocale",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Subtopic",
      "kind": "LinkedField",
      "name": "subtopic",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlightedData",
      "storageKey": null
    }
  ],
  "type": "StudyNote",
  "abstractKey": null
};
})();
(node as any).hash = 'fe6a9895994ab8246bbba1cdfc872e87';
export default node;
