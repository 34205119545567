/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type UserStatusFilterInput = {
    curriculumNodeIds: Array<string>;
};
export type CheckInCompleteSubstrandsUserStatusesQueryVariables = {
    filters: Array<UserStatusFilterInput>;
    timestamp?: string | null | undefined;
    strandId: string;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type CheckInCompleteSubstrandsUserStatusesQueryResponse = {
    readonly lantern: {
        readonly viewer: ({
            readonly __typename: "LanternStudent";
            readonly strandStatus: {
                readonly id: string;
                readonly status: StudentStrandStatusEnum;
            } | null;
            readonly userStatuses: ReadonlyArray<{
                readonly trueProficiency: number | null;
                readonly userStatusFilter: {
                    readonly curriculumNodeIds: ReadonlyArray<string>;
                };
            }> | null;
            readonly previousStatuses: ReadonlyArray<{
                readonly trueProficiency: number | null;
            }> | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
};
export type CheckInCompleteSubstrandsUserStatusesQuery = {
    readonly response: CheckInCompleteSubstrandsUserStatusesQueryResponse;
    readonly variables: CheckInCompleteSubstrandsUserStatusesQueryVariables;
};



/*
query CheckInCompleteSubstrandsUserStatusesQuery(
  $filters: [UserStatusFilterInput!]!
  $timestamp: DateTime
  $strandId: ID!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        strandStatus(strandId: $strandId) {
          id
          status
        }
        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
          trueProficiency
          userStatusFilter {
            curriculumNodeIds
          }
        }
        previousStatuses: userStatuses(filters: $filters, timestamp: $timestamp, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
          trueProficiency
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "strandId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timestamp"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v8 = {
  "kind": "Variable",
  "name": "growthPeriod",
  "variableName": "growthPeriod"
},
v9 = {
  "kind": "Variable",
  "name": "previewingWithProblemData",
  "variableName": "previewingWithProblemData"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trueProficiency",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "strandId",
          "variableName": "strandId"
        }
      ],
      "concreteType": "StudentStrandStatus",
      "kind": "LinkedField",
      "name": "strandStatus",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "concreteType": "UserStatus",
      "kind": "LinkedField",
      "name": "userStatuses",
      "plural": true,
      "selections": [
        (v10/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserStatusFilter",
          "kind": "LinkedField",
          "name": "userStatusFilter",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "curriculumNodeIds",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "previousStatuses",
      "args": [
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "kind": "Variable",
          "name": "timestamp",
          "variableName": "timestamp"
        }
      ],
      "concreteType": "UserStatus",
      "kind": "LinkedField",
      "name": "userStatuses",
      "plural": true,
      "selections": [
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInCompleteSubstrandsUserStatusesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheckInCompleteSubstrandsUserStatusesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f6adb0156ee49cc66c1dc92ae734d623",
    "id": null,
    "metadata": {},
    "name": "CheckInCompleteSubstrandsUserStatusesQuery",
    "operationKind": "query",
    "text": "query CheckInCompleteSubstrandsUserStatusesQuery(\n  $filters: [UserStatusFilterInput!]!\n  $timestamp: DateTime\n  $strandId: ID!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        strandStatus(strandId: $strandId) {\n          id\n          status\n        }\n        userStatuses(filters: $filters, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n          trueProficiency\n          userStatusFilter {\n            curriculumNodeIds\n          }\n        }\n        previousStatuses: userStatuses(filters: $filters, timestamp: $timestamp, previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n          trueProficiency\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5bf6cce7da0aaa0965a996f8729fe975';
export default node;
