import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Toolbox = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={18}
    viewBoxHeight={18}
  >
    <path d="M10.3168 10.5427C9.76449 10.5427 9.31677 10.9904 9.31677 11.5427V17C9.31677 17.5523 9.76448 18 10.3168 18H15.7702C16.3225 18 16.7702 17.5523 16.7702 17V11.5427C16.7702 10.9904 16.3225 10.5427 15.7702 10.5427H10.3168ZM0 17C0 17.5523 0.447715 18 1 18H6.45342C7.0057 18 7.45342 17.5523 7.45342 17V11.5427C7.45342 10.9904 7.0057 10.5427 6.45342 10.5427H1C0.447716 10.5427 0 10.9904 0 11.5427V17ZM1 1.22113C0.447716 1.22113 0 1.66884 0 2.22113V7.6784C0 8.23069 0.447715 8.6784 1 8.6784H6.45342C7.0057 8.6784 7.45342 8.23069 7.45342 7.6784V2.22113C7.45342 1.66884 7.0057 1.22113 6.45342 1.22113H1ZM13.4334 0.705782C13.0429 0.315847 12.4105 0.315846 12.02 0.705781L8.16122 4.55978C7.77035 4.95016 7.77007 5.58352 8.1606 5.97425L12.0194 9.83507C12.41 10.2258 13.0434 10.2258 13.434 9.83507L17.2928 5.97425C17.6833 5.58352 17.6831 4.95016 17.2922 4.55978L13.4334 0.705782Z" />
  </Icon>
);

Toolbox.displayName = 'Toolbox';

export default Toolbox;
