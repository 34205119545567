import { useCallback, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useRouter } from 'ms-utils/hooks/useRouter';
import type { Action } from 'ms-utils/keyDownMap';
import keyDownMap from 'ms-utils/keyDownMap';
import { deserialize, serialize } from 'ms-utils/urls/queryParams';

import type { ButtonStyleProps, CommonProps } from '.';
import { useButtonStyle, useCommonProps } from '.';

type LocationShape = {
  pathname?: string;
  search?: string;
  hash?: string;
  state?: Record<string, any>;
};

export type Props = ButtonStyleProps &
  CommonProps & {
    to: string | LocationShape;
    replace?: boolean;
    preserveQueryString?: boolean;
  };

const ReactRouterLinkButton = (props: Props) => {
  const cssStyles = useButtonStyle(props);
  const commonProps = useCommonProps(props);
  const {
    to: _to,
    preserveQueryString,
    children,
    isDisabled,
    onClick,
    replace = false,
  } = props;

  const {
    history,
    location: { search },
  } = useRouter();

  const to = useMemo(
    () =>
      preserveQueryString
        ? typeof _to === 'string'
          ? {
              pathname: _to,
              search,
            }
          : {
              pathname: _to.pathname,
              search: serialize({
                ...deserialize(search),
                ...deserialize(_to.search ?? ''),
              }),
              state: _to.state,
            }
        : _to,
    [preserveQueryString, _to, search],
  );

  const action = useCallback<Action<HTMLAnchorElement>>(
    e => {
      if (isDisabled) return;

      if (onClick != null) {
        onClick(e);
      }

      history[replace ? 'replace' : 'push'](to);
    },
    [history, isDisabled, onClick, replace, to],
  );

  return (
    <Link
      {...commonProps}
      onKeyDown={keyDownMap({
        SPACE: [action, { preventDefault: true, stopPropagation: true }],
        ENTER: [action, { stopPropagation: true }],
      })}
      css={cssStyles}
      to={to}
      role="button"
    >
      {children}
    </Link>
  );
};

export default memo<Props>(ReactRouterLinkButton);
