/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { CSSObject } from '@emotion/react';
import styled from '@emotion/styled';
import { useState, useRef, useEffect, useCallback } from 'react';

import EllipsisHorizontalIcon from 'ms-components/icons/EllipsisHorizontal';
import EllipsisVerticalIcon from 'ms-components/icons/EllipsisVertical';
import EllipsisVertical2 from 'ms-components/icons/EllipsisVertical2';
import TriangleDown from 'ms-components/icons/TriangleDown/index';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button, { sizeToHeight } from 'ms-ui-primitives/Button';
import DropdownMenu, {
  type Props as DropdownMenuProps,
} from 'ms-ui-primitives/DropdownMenu';
import getVerticallyScrollableParent from 'ms-utils/scroll/scrollableParent';

export type KebabDropdownProps = {
  items: DropdownMenuProps['items'];
  iconSize?: number | undefined;
  reverseMenuPosition?: boolean | undefined;
  horizontal?: boolean | undefined; // render EllipsisHorizontalIcon instead
  color?: string | undefined;
  activeColor?: string | undefined;
  hasArrowDropdownStyles?: boolean | undefined;
  isWrapperInline?: boolean | undefined;
  hasUpdatedVerticalEllipsisIcon?: boolean | undefined;
  noButtonMargin?: boolean | undefined;
  dropdownMenuWidth?: number | undefined;
  dropdownMenuVOffset?: number | undefined;
  buttonHeight?: number | undefined;
  buttonBorderRadius?: number | undefined;
  isButtonSquare?: boolean | undefined;
  dropdownOpenButtonStyles?: CSSObject | false | null | undefined;
  buttonStyles?: CSSObject | false | null | undefined;
};

const ICON_TAP_AREA_MEDIUM = sizeToHeight.medium; // big enough for tapping// big enough for tapping
const ICON_TAP_AREA_SMALL = 6 * BASE_UNIT;
export const DEFAULT_ICON_SIZE = 6 * BASE_UNIT;
const ICON_CONTENT_HEIGHT_RATIO = 0.65; // for 24px icon the actual dots have height of 15px
const ICON_CONTENT_WIDTH_RATIO = 0.2; // for 24px icon the actual dots have width of 4px

const DEFAULT_MENU_HEIGHT = 150;
const MENU_OFFSET_BOTTOM = 10;

const Wrapper = styled.div({
  position: 'relative',
  display: 'flex',
});

const KebabDropdown = ({
  iconSize = DEFAULT_ICON_SIZE,
  items,
  reverseMenuPosition = false,
  horizontal = false,
  color = colors.hitGray,
  activeColor = colors.matisse,
  hasArrowDropdownStyles = false,
  isWrapperInline = false,
  hasUpdatedVerticalEllipsisIcon = false,
  noButtonMargin = false,
  dropdownMenuWidth,
  dropdownMenuVOffset,
  buttonHeight,
  buttonBorderRadius,
  isButtonSquare = false,
  dropdownOpenButtonStyles,
  buttonStyles,
}: KebabDropdownProps) => {
  const iconTapArea = hasArrowDropdownStyles
    ? ICON_TAP_AREA_SMALL
    : ICON_TAP_AREA_MEDIUM;

  const ICON_VERTICAL_OFFSET =
    -(iconTapArea - ICON_CONTENT_HEIGHT_RATIO * iconSize) / 2;
  const ICON_HORIZONTAL_OFFSET =
    -(iconTapArea - ICON_CONTENT_WIDTH_RATIO * iconSize) / 2;

  const dropdownToggle = useRef<HTMLDivElement | null>(null);
  const scrollableParent = useRef<Element | null>(null);
  const dropdownMenu = useRef<HTMLElement | null>(null);
  const [isDropdownMenuOpen, setDropdown] = useState(false);
  const [shouldReverseMenuPosition, setShouldReverseMenuPosition] =
    useState(false);
  const [dropdownMenuHeight, setDropdownMenuHeight] =
    useState(DEFAULT_MENU_HEIGHT);

  // checks if there is any available space at the bottom of the dropdown toggle
  const hasAvailableSpaceAtBottom = useCallback(() => {
    const dropdownToggleBottom =
      dropdownToggle.current?.getBoundingClientRect().bottom;

    const scrollableParentBottom =
      scrollableParent.current?.getBoundingClientRect().bottom;

    if (dropdownToggleBottom == null || scrollableParentBottom == null) {
      return true;
    }

    return (
      scrollableParentBottom - dropdownToggleBottom >
      dropdownMenuHeight + MENU_OFFSET_BOTTOM
    );
  }, [dropdownMenuHeight]);

  useEffect(() => {
    if (!dropdownToggle.current || scrollableParent.current) return;

    scrollableParent.current = getVerticallyScrollableParent(
      dropdownToggle.current,
    );
  }, [dropdownToggle, scrollableParent]);

  useEffect(() => {
    if (!dropdownToggle.current || !scrollableParent.current) return;

    setShouldReverseMenuPosition(!hasAvailableSpaceAtBottom());
  }, [dropdownToggle, hasAvailableSpaceAtBottom, scrollableParent]);

  // detects scroll events on the scrollable parent and updates the menu position
  useEffect(() => {
    if (!dropdownToggle.current || !scrollableParent.current) return;
    const handleScroll = () => {
      setShouldReverseMenuPosition(!hasAvailableSpaceAtBottom());
    };
    scrollableParent.current.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollableParent.current) {
        scrollableParent.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dropdownToggle, scrollableParent, hasAvailableSpaceAtBottom]);

  // sets the dropdown menu height when it is opened
  useEffect(() => {
    if (dropdownMenu.current) {
      setDropdownMenuHeight(dropdownMenu.current.clientHeight);
    }
  }, [dropdownMenu, isDropdownMenuOpen]);

  if (items.length === 0) return null;

  const Ellipsis = horizontal
    ? EllipsisHorizontalIcon
    : hasUpdatedVerticalEllipsisIcon
    ? EllipsisVertical2
    : EllipsisVerticalIcon;

  return (
    <Wrapper
      ref={dropdownToggle}
      style={{ ...(isWrapperInline && { display: 'inline-block' }) }}
    >
      <Button
        {...(hasArrowDropdownStyles ? { type: 'secondary' } : {})}
        onClick={e => {
          e.stopPropagation();
          setDropdown(true);
        }}
        label="Open menu"
        padding={0}
        height={buttonHeight}
        borderRadius={buttonBorderRadius}
        isSquare={isButtonSquare}
        styles={{
          ...(isButtonSquare && buttonHeight != null
            ? {}
            : { width: iconTapArea }),
          ...(hasArrowDropdownStyles
            ? { height: iconTapArea }
            : noButtonMargin
            ? {}
            : {
                margin: `${ICON_VERTICAL_OFFSET}px ${ICON_HORIZONTAL_OFFSET}px`,
              }),
          ...(typeof dropdownOpenButtonStyles === 'object' && isDropdownMenuOpen
            ? dropdownOpenButtonStyles
            : {}),
          ...(typeof buttonStyles === 'object' ? buttonStyles : {}),
        }}
      >
        {hasArrowDropdownStyles ? (
          <TriangleDown
            color={isDropdownMenuOpen ? activeColor : color}
            size={iconSize}
            title="Arrow menu"
          />
        ) : (
          <Ellipsis
            color={isDropdownMenuOpen ? activeColor : color}
            size={iconSize}
            title="Kebab menu"
          />
        )}
      </Button>
      {isDropdownMenuOpen && (
        <DropdownMenu
          minWidth={dropdownMenuWidth}
          anchorRef={dropdownToggle}
          anchorOrigin="right"
          menuPosition={
            reverseMenuPosition && shouldReverseMenuPosition
              ? 'top-left'
              : 'bottom-left'
          }
          onDismiss={() => setDropdown(false)}
          items={items}
          ref={dropdownMenu}
          vOffset={
            dropdownMenuVOffset != null
              ? dropdownMenuVOffset
              : hasArrowDropdownStyles
              ? 2
              : undefined
          }
        />
      )}
    </Wrapper>
  );
};

export default KebabDropdown;
