/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AvatarContainerQueryVariables = {};
export type AvatarContainerQueryResponse = {
    readonly viewer: {
        readonly pk: number;
        readonly avatar: string;
    };
};
export type AvatarContainerQuery = {
    readonly response: AvatarContainerQueryResponse;
    readonly variables: AvatarContainerQueryVariables;
};



/*
query AvatarContainerQuery {
  viewer {
    pk
    avatar
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pk",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AvatarContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AvatarContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b06fda3abd47faca44d80efdf65cfc42",
    "id": null,
    "metadata": {},
    "name": "AvatarContainerQuery",
    "operationKind": "query",
    "text": "query AvatarContainerQuery {\n  viewer {\n    pk\n    avatar\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4f241bc9d68723aae0f5234041086991';
export default node;
