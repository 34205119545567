import { css, StyleSheet } from 'aphrodite';
import { isNil } from 'ramda';
import { Fragment } from 'react';

import Heading from 'ms-components/curriculum-mapping/Heading';
import Logo from 'ms-components/curriculum-mapping/Logo';
import ArrowLeftIcon from 'ms-components/icons/ArrowLeft';
import { PrintModeConsumer } from 'ms-helpers/PrintMode';
import { breakPoints, fontWeight } from 'ms-styles/base';
import { colors as baseColors } from 'ms-styles/colors';
import { colors, fontFamily } from 'ms-styles/theme/CurriculumMapping';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { sendCTAClickEvent } from 'ms-utils/ga';
import {
  calendlyUrlAU,
  calendlyUrlGB,
  calendlyUrlUS,
  loginUrl,
} from 'ms-utils/urls';

// GA Tracking event labels
const LOGIN = 'login';
const BOOK_PD = 'book-pd';

const CALENDLY_URLS: Record<string, string> = {
  AU: calendlyUrlAU,
  GB: calendlyUrlGB,
  US: calendlyUrlUS,
};

export const HEADER_SIZE = 64;
const PADDING_HORIZONTAL = 16;
const PADDING_VERTICAL = 12;
const BUTTON_GUTTER = PADDING_VERTICAL;
const BACK_BUTTON_GUTTER = 8;
const BACK_BUTTON_TOUCH_TARGET = 36;
const BACK_BUTTON_SIZE = 24;
const BACK_BUTTON_TOUCH_TARGET_OFFSET =
  -(BACK_BUTTON_TOUCH_TARGET - BACK_BUTTON_SIZE) / 2;

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    height: HEADER_SIZE,
    alignItems: 'center',
    padding: `${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px`,
    backgroundColor: colors.astronaut,
    color: baseColors.white,
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    zIndex: 1,
  },
  headerPlaceholder: {
    height: HEADER_SIZE,
  },
  sticky: {
    position: 'fixed', // Use 'fixed' and not 'sticky' because of its wider support by browsers
    top: 0,
    left: 0,
    width: '100%',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: BACK_BUTTON_TOUCH_TARGET,
    width: BACK_BUTTON_TOUCH_TARGET,
    marginRight: BACK_BUTTON_GUTTER,
    marginLeft: BACK_BUTTON_TOUCH_TARGET_OFFSET,
    fontSize: BACK_BUTTON_SIZE,
  },
  title: {
    display: 'none',

    [`@media (min-width: ${breakPoints.medium}px)`]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  buttons: {
    display: 'flex',
    flexGrow: 1,

    [`@media (min-width: ${breakPoints.small}px)`]: {
      maxWidth: 260,
      marginLeft: 'auto',
    },
  },
  buttonSeparator: {
    flexShrink: 0,
    height: BUTTON_GUTTER,
    width: BUTTON_GUTTER,
  },
});

type Props = {
  title: string;
  countryCode: string;
  viewer: { pk: number } | null | undefined;
  backButtonUrl?: string | undefined;
  sticky?: boolean | undefined;
};

const Header = ({
  title,
  backButtonUrl,
  countryCode,
  viewer,
  sticky = false,
}: Props) => (
  <PrintModeConsumer>
    {printMode =>
      printMode ? (
        <Fragment>
          <Logo />
          <Heading variant="xxlarge" text={title} />
        </Fragment>
      ) : (
        <Fragment>
          {sticky && <div className={css(styles.headerPlaceholder)} />}
          <div className={css(styles.header, sticky && styles.sticky)}>
            {backButtonUrl && (
              <a href={backButtonUrl} className={css(styles.backButton)}>
                <ArrowLeftIcon color={baseColors.white} />
              </a>
            )}
            <div className={css(styles.title)}>{title}</div>
            {isNil(viewer) && (
              <div className={css(styles.buttons)}>
                <AnchorButton
                  href={loginUrl}
                  color="iron"
                  type="secondary"
                  isBlock
                  data-event-label={LOGIN}
                  onClick={event => {
                    event.preventDefault();
                    sendCTAClickEvent(LOGIN).then(() =>
                      window.location.assign(loginUrl),
                    );
                  }}
                >
                  Login
                </AnchorButton>
                <div className={css(styles.buttonSeparator)} />
                <AnchorButton
                  href={CALENDLY_URLS[countryCode] ?? ''}
                  type="primary"
                  isBlock
                  data-event-label={BOOK_PD}
                  onClick={event => {
                    event.preventDefault();
                    sendCTAClickEvent(BOOK_PD).then(() =>
                      window.location.assign(CALENDLY_URLS[countryCode] ?? ''),
                    );
                  }}
                >
                  Book a PD
                </AnchorButton>
              </div>
            )}
          </div>
        </Fragment>
      )
    }
  </PrintModeConsumer>
);

export default Header;
