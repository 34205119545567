import { StyleSheet } from 'aphrodite';
import { useCallback, useRef, useState } from 'react';

import DifficultyEasyIcon from 'ms-components/icons/DifficultyEasy';
import DifficultyHardIcon from 'ms-components/icons/DifficultyHard';
import DifficultyMediumIcon from 'ms-components/icons/DifficultyMedium';
import { BodyM, BodyS } from 'ms-pages/Lantern/primitives/Typography';
import Filter from 'ms-pages/Teacher/components/FilterForm/Filter';
import FilterPopover from 'ms-pages/Teacher/components/FilterForm/FilterPopover';
import { colors } from 'ms-styles/colors';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Stack, { HSpacer, HStack, VStack } from 'ms-ui-primitives/Stack';
import { assertUnreachable, type Entries } from 'ms-utils/typescript-utils';

import type { SelectedDifficultyLevels, DifficultyLevelTypes } from './types';

const ICON_SIZE = 16;
const ICON_COLOR = colors.neutralGray;

const styles = StyleSheet.create({
  label: {
    alignItems: 'center',
  },
});

const getLabel = (difficulty: DifficultyLevelTypes, isShort?: boolean) => {
  switch (difficulty) {
    case 'EASY':
      return 'Easy';
    case 'MEDIUM':
      return isShort ? 'Med' : 'Medium';
    case 'HARD':
      return 'Hard';
    case 'UNSPECIFIED':
      return 'Unspecified';
    default: {
      assertUnreachable(difficulty, `Unknown DifficultyLevel ${difficulty}`);
    }
  }
};

function getProblemDifficultyIcon(difficulty: DifficultyLevelTypes) {
  switch (difficulty) {
    case 'EASY':
      return DifficultyEasyIcon;
    case 'MEDIUM':
      return DifficultyMediumIcon;
    case 'HARD':
      return DifficultyHardIcon;
    case 'UNSPECIFIED':
      return null;
    default: {
      assertUnreachable(difficulty, `Unknown DifficultyLevel ${difficulty}`);
    }
  }
}

const getProblemDifficultyLabel = (
  difficulty: DifficultyLevelTypes,
  isShort: boolean = false,
) => {
  const difficultyIcon = (() => {
    const iconProps = {
      color: ICON_COLOR,
      useUpdatedIcon: true,
      size: ICON_SIZE,
    };
    const Icon = getProblemDifficultyIcon(difficulty);
    if (Icon) {
      return <Icon {...iconProps} />;
    }
    return null;
  })();

  return (
    <HStack center>
      {difficulty === 'UNSPECIFIED' ? null : (
        <>
          {difficultyIcon}
          <HSpacer width={8} />
        </>
      )}
      <BodyM>{getLabel(difficulty, isShort)}</BodyM>
    </HStack>
  );
};

type Props = {
  selectedDifficultyLevels: SelectedDifficultyLevels;
  toggleDifficultyLevelSelection: (difficulty: DifficultyLevelTypes) => void;
};

export default function ProblemDifficultyFilter({
  selectedDifficultyLevels,
  toggleDifficultyLevelSelection,
}: Props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const hasSelections = Object.values(selectedDifficultyLevels).some(Boolean);

  const getSelectedFilterLabel = useCallback(() => {
    const difficultyLevelsArray = Object.entries(
      selectedDifficultyLevels,
    ) as Entries<SelectedDifficultyLevels>;

    const selectedDifficultyLevelsArray = difficultyLevelsArray.filter(
      ([, isSelected]) => isSelected,
    );

    const selectionLabel = selectedDifficultyLevelsArray
      .map(([difficulty, isSelected]) =>
        isSelected ? getLabel(difficulty, true) : null,
      )
      .filter(Boolean)
      .join(', ');

    if (selectedDifficultyLevelsArray.length === 1) {
      // we print the "Difficulty: " label and the corresponding icon only if there is a single selection
      const selectedDifficulty = selectedDifficultyLevelsArray[0];
      if (selectedDifficulty != null) {
        const selectedDifficultyValue = selectedDifficulty[0];
        const DifficultyIcon = getProblemDifficultyIcon(
          selectedDifficultyValue,
        );
        const difficultyLabel = getLabel(selectedDifficultyValue, true);
        return (
          <Stack.H center gap={9}>
            Difficulty:{' '}
            {DifficultyIcon && (
              <DifficultyIcon color="currentColor" size={16} />
            )}
            <BodyS bold color="inherit">
              {difficultyLabel}
            </BodyS>
          </Stack.H>
        );
      }
    }
    return selectionLabel;
  }, [selectedDifficultyLevels]);

  return (
    <Filter
      style={{ width: 176, justifyContent: 'space-between' }}
      label={
        hasSelections ? (
          getSelectedFilterLabel()
        ) : (
          <Stack.H center gap={8}>
            <DifficultyMediumIcon color="currentColor" size={16} />
            <BodyS color="inherit">Difficulty</BodyS>
          </Stack.H>
        )
      }
      selected={hasSelections}
      popoverAnchorRef={popoverRef}
      onClick={() => setPopoverOpen(true)}
      popover={
        popoverOpen && (
          <FilterPopover
            onDismiss={() => setPopoverOpen(false)}
            anchorElementRef={popoverRef}
          >
            <VStack style={{ maxWidth: 250 }} gap={16}>
              {'EASY' in selectedDifficultyLevels && (
                <Checkbox
                  label={getProblemDifficultyLabel('EASY')}
                  checked={selectedDifficultyLevels.EASY}
                  onChange={() => toggleDifficultyLevelSelection('EASY')}
                  aphroditeStyles={[styles.label]}
                />
              )}
              {'MEDIUM' in selectedDifficultyLevels && (
                <Checkbox
                  label={getProblemDifficultyLabel('MEDIUM')}
                  checked={selectedDifficultyLevels.MEDIUM}
                  onChange={() => toggleDifficultyLevelSelection('MEDIUM')}
                  aphroditeStyles={[styles.label]}
                />
              )}
              {'HARD' in selectedDifficultyLevels && (
                <Checkbox
                  label={getProblemDifficultyLabel('HARD')}
                  checked={selectedDifficultyLevels.HARD}
                  onChange={() => toggleDifficultyLevelSelection('HARD')}
                  aphroditeStyles={[styles.label]}
                />
              )}
              {'UNSPECIFIED' in selectedDifficultyLevels && (
                <Checkbox
                  label={getProblemDifficultyLabel('UNSPECIFIED')}
                  checked={selectedDifficultyLevels.UNSPECIFIED}
                  onChange={() => toggleDifficultyLevelSelection('UNSPECIFIED')}
                  aphroditeStyles={[styles.label]}
                />
              )}
            </VStack>
          </FilterPopover>
        )
      }
    />
  );
}
