/**
 * Consumes a string which represents a single word, and returns the same word
 * in title case.
 *
 * N.B. Only handles the single word case for now.
 *
 * @example
 * titleCase('tom') // => 'Tom'
 * @param {string} word A single word, in string form.
 * @returns {string}
 * The same string, but forces the first character to uppercase, and the rest
 * to lowercase.
 */
export function titleCase(word: string): string {
  return word
    .split('')
    .map((l, index) => (index === 0 ? l.toUpperCase() : l.toLowerCase()))
    .join('');
}

export function kebabCase(input: string): string {
  return input
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
}
