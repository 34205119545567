import styled from '@emotion/styled';
import { useState } from 'react';

import NotLoadedImagePlaceholder from 'ms-components/icons/NotLoadedImagePlaceholder';
import { fontSize, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { VSpacer } from 'ms-ui-primitives/Stack';
import { Logger } from 'ms-utils/app-logging';
import { tappable } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});
const Link = styled.div({
  color: colors.eggplant,
  fontFamily: fontFamily.body,
  fontSize: fontSize.headerTitle,
  fontWeight: fontWeight.semibold,
  ...tappable,
});

type Props = {
  src: string;
  alt: string;
};

function ReloadableImage({ src, alt, ...rest }: Props) {
  const isImageLoaded = useBoolean(true);
  const isUserInitiatedReload = useBoolean(false);
  const [key, setKey] = useState(1);
  return (
    <>
      {isImageLoaded.value ? (
        <img
          {...rest}
          key={key}
          src={src}
          alt={alt}
          onError={error => {
            isImageLoaded.setFalse();
            Logger.error('Error loading <img>', {
              tags: {
                isUserInitiatedReload: isUserInitiatedReload.value.toString(),
                imageURL: src,
                errorType: error.type,
              },
            });
          }}
        />
      ) : (
        <Wrapper>
          <NotLoadedImagePlaceholder size={24} color={colors.neutralGray} />
          <VSpacer height={8} />
          <Link
            onClick={() => {
              isUserInitiatedReload.setTrue();
              setKey(key => key + 1);
              Logger.error(`Clicked reload image, image url: ${src}`);
              isImageLoaded.setTrue();
            }}
          >
            Reload the image
          </Link>
        </Wrapper>
      )}
    </>
  );
}

export default ReloadableImage;
