import { graphql } from 'react-relay';
import { useMutation } from 'relay-hooks';

import type { editTaskTemplateMutation } from './__generated__/editTaskTemplateMutation.graphql';

const editTaskTemplate = graphql`
  mutation editTaskTemplateMutation(
    $templateId: ID!
    $title: String!
    $description: String!
    $problemContentIds: [ID!]!
    $taskTemplateGroupId: ID!
  ) {
    editTaskTemplateDetails(
      taskTemplateId: $templateId
      title: $title
      description: $description
      problemContentIds: $problemContentIds
      taskTemplateGroupId: $taskTemplateGroupId
    ) {
      errors {
        message
      }
      taskTemplate {
        id
      }
    }
  }
`;

export default function useEditTaskTemplateMutation() {
  return useMutation<editTaskTemplateMutation>(editTaskTemplate);
}
