import { isValidDate } from 'ms-utils/datetime';

import { formatDate } from './helpers';
import type { Value, Period, CustomDate } from './types';

export const formatPeriodLabel = ({ period }: { period: Period }) =>
  `Last ${period} days`;

export const formatCustomLabel = ({
  startDate,
  endDate,
  usePrefix = true,
}: {
  startDate: CustomDate | null;
  endDate: CustomDate | null;
  usePrefix?: boolean | undefined;
}) => {
  if (
    startDate == null ||
    endDate == null ||
    !isValidDate(startDate) ||
    !isValidDate(endDate)
  )
    return 'Custom';

  return `${usePrefix ? 'Custom: ' : ''}${formatDate(startDate)} - ${formatDate(
    endDate,
  )}`;
};

export const formatLabel = ({ value }: { value: Value }) => {
  const { useCustomPeriod, customStartDate, customEndDate } = value;
  if (!useCustomPeriod && value.period != null)
    return formatPeriodLabel({ period: value.period });

  return formatCustomLabel({
    startDate: customStartDate,
    endDate: customEndDate,
    usePrefix: false,
  });
};
