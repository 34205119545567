import { css, StyleSheet } from 'aphrodite';

import { fontSize, fontWeight, fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

import emptyGallery from './empty-gallery.svg';

type BaseProps = {
  label: string;
};

const styles = StyleSheet.create({
  base: {
    color: colors.santasGray,
    width: '100%',
    height: '100%',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centered: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    opacity: 0.8,
    fontFamily: fontFamily.body,
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semibold,
    padding: fontSize.medium / 2,
    whiteSpace: 'nowrap',
  },
});

export const Base = ({ label }: BaseProps) => (
  <div className={css(styles.root, styles.base)}>
    <div className={css(styles.message)}>{label}</div>
  </div>
);

export const Empty = () => (
  <div className={css(styles.centered)}>
    <div className={css(styles.root)}>
      <img role="presentation" src={emptyGallery} alt="empty" />
      <Base label="No results here" />
    </div>
  </div>
);

export const MissingContent = () => <Base label="content does not exist" />;
