import { StyleSheet } from 'aphrodite';

import type { AspectRatio } from '.';

export const styles = StyleSheet.create({
  paddingWrapper: {
    position: 'relative', // to be used with paddingBottom()
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },
  player: {
    height: '100%',
    width: '100%',
  },
});

// Bottom padding should be the reciprocal of the aspect ratio, given as a %.
export const paddingBottom = (aspectRatio: AspectRatio): number => {
  switch (aspectRatio) {
    case '4:3':
      return (3 / 4) * 100;
    case '16:9':
      return (9 / 16) * 100;
    case '9:16':
      return (16 / 9) * 100;
    default:
      return 100;
  }
};
