import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g>
    <polygon points="0 0 20 0 20 20 0 20" fill="none" />
    <path d="M16.1201436,10.78 C16.1510014,10.53 16.1715733,10.27 16.1715733,10 C16.1715733,9.73 16.1510014,9.47 16.1098576,9.22 L17.8481841,7.9 C18.0024734,7.78 18.0436173,7.56 17.9510437,7.39 L16.3052908,4.62 C16.2024312,4.44 15.9864261,4.38 15.8012789,4.44 L13.7543738,5.24 C13.3223637,4.92 12.8697816,4.66 12.3657698,4.46 L12.0571911,2.34 C12.0263332,2.14 11.851472,2 11.6457529,2 L8.35424711,2 C8.148528,2 7.98395271,2.14 7.95309484,2.34 L7.64451617,4.46 C7.14050435,4.66 6.67763635,4.93 6.25591217,5.24 L4.20900701,4.44 C4.02385981,4.37 3.80785474,4.44 3.70499519,4.62 L2.05924229,7.39 C1.95638274,7.57 1.99752656,7.78 2.16210185,7.9 L3.90042834,9.22 C3.85928452,9.47 3.82842665,9.74 3.82842665,10 C3.82842665,10.26 3.84899857,10.53 3.89014239,10.78 L2.15181589,12.1 C1.99752656,12.22 1.95638274,12.44 2.04895634,12.61 L3.69470923,15.38 C3.79756879,15.56 4.01357385,15.62 4.19872105,15.56 L6.24562621,14.76 C6.67763635,15.08 7.13021839,15.34 7.63423022,15.54 L7.94280888,17.66 C7.98395271,17.86 8.148528,18 8.35424711,18 L11.6457529,18 C11.851472,18 12.0263332,17.86 12.0469052,17.66 L12.3554838,15.54 C12.8594957,15.34 13.3223637,15.07 13.7440878,14.76 L15.790993,15.56 C15.9761402,15.63 16.1921453,15.56 16.2950048,15.38 L17.9407577,12.61 C18.0436173,12.43 18.0024734,12.22 17.8378981,12.1 L16.1201436,10.78 Z M10,13 C8.30281733,13 6.91421333,11.65 6.91421333,10 C6.91421333,8.35 8.30281733,7 10,7 C11.6971827,7 13.0857867,8.35 13.0857867,10 C13.0857867,11.65 11.6971827,13 10,13 Z" />
  </g>
);

const SettingsCog = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

SettingsCog.displayName = 'SettingsCog';

export default SettingsCog;
