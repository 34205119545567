/**
 * Copies text to a user's clipboard using the clipboard API, or by
 * falling back creating a dummy textarea element if the API is not available
 * (e.g. in Internet Explorer, older versions of Edge etc.)
 *
 * In the fallback method, textarea is used instead of input
 * to support copying multiline text and to prevent flattening the text
 * into a single line.
 *
 * The two alternatives are provided to give broader browser support:
 * `execCommand` is now deprecated and unsupported in some required
 * environments (e.g. mobile browsers).
 * @param {string} text - Text to be copied to clipboard
 */
const copyToClipboard = (text: string) => {
  if (navigator.clipboard != null) {
    navigator.clipboard.writeText(text);
  } else {
    const element = document.createElement('textarea');
    if (document.body != null) document.body.appendChild(element);
    element.setAttribute('value', text);
    element.select();
    document.execCommand('copy');
    if (document.body != null) document.body.removeChild(element);
  }
};

export { copyToClipboard };
