import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function LinkedinIcon({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={24}
      viewBoxWidth={24}
    >
      <circle cx="12" cy="12" r="12" />
      <path
        d="M7.73344 6.60938C6.9085 6.60938 6.36914 7.15107 6.36914 7.86305C6.36914 8.55931 6.89243 9.11645 7.70178 9.11645H7.71744C8.55851 9.11645 9.08194 8.55931 9.08194 7.86305C9.06621 7.15107 8.55851 6.60938 7.73344 6.60938V6.60938Z"
        fill="white"
      />
      <path
        d="M6.51172 10.1069H8.92336V17.3624H6.51172V10.1069Z"
        fill="white"
      />
      <path
        d="M14.8427 9.93701C13.5417 9.93701 12.6693 11.1595 12.6693 11.1595V10.1073H10.2576V17.3628H12.6691V13.3111C12.6691 13.0941 12.6849 12.8776 12.7486 12.7225C12.9229 12.2893 13.3197 11.8406 13.9859 11.8406C14.8586 11.8406 15.2076 12.506 15.2076 13.4813V17.3628H17.619V13.2026C17.619 10.9741 16.4292 9.93701 14.8427 9.93701V9.93701Z"
        fill="white"
      />
    </Icon>
  );
}
