import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="m23.4 20v-3.4h-6.8v3.4h6.8z m0 6.6v-3.2h-6.8v3.2h6.8z m10-13.2v3.2h-3.6c0.1 0.6 0.2 1.2 0.2 1.8v1.6h3.4v3.4h-3.4v1.6c0 0.5-0.1 1.1-0.2 1.6h3.6v3.4h-4.7c-1.7 3-5 5-8.7 5s-7-2-8.7-5h-4.7v-3.4h3.6c-0.1-0.5-0.2-1.1-0.2-1.6v-1.6h-3.4v-3.4h3.4v-1.6c0-0.6 0.1-1.2 0.2-1.8h-3.6v-3.2h4.7c0.8-1.4 1.8-2.5 3.1-3.3l-2.8-2.8 2.4-2.3 3.7 3.6c0.7-0.2 1.5-0.2 2.3-0.2s1.6 0 2.3 0.2l3.7-3.6 2.4 2.3-2.8 2.8c1.3 0.8 2.3 1.9 3.1 3.3h4.7z" />
);

const BugReport = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={40}
    viewBoxWidth={40}
  >
    {path}
  </Icon>
);

BugReport.displayName = 'BugReport';

export default BugReport;
