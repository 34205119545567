import { assertUnreachable } from 'ms-utils/typescript-utils';

type StudentId = string;
type SubtopicId = string;
type SyllabusId = string;
type TopicId = string;

export const teacherStudentBasePath = '/teacher/students/:studentId/';
export const getTeacherStudentBaseUrl = ({
  studentId,
}: {
  studentId: string;
}) => `/teacher/students/${studentId}/`;

export const teacherStudentRedirectPath = '/teacher/students/';

export const teacherStudentTypePath =
  '/teacher/students/:studentId/:view(activity|insights|mastery|skills|tasks|settings)/';

export const teacherStudentActivityPath =
  '/teacher/students/:studentId/activity/';

export const teacherStudentActivityRedirectorPath =
  '/teacher/students/activity';

export const teacherStudentInsightsPath =
  '/teacher/students/:studentId/insights/';

export const teacherStudentInsightsRedirectorPath =
  '/teacher/students/insights';

export const teacherStudentMasteryActivityPath =
  '/teacher/students/:studentId/mastery/mastery-activity/:subtopicId/';

export const teacherStudentProgressPath =
  '/teacher/students/:studentId/mastery/';

export const teacherStudentProgressRedirectorPath = '/teacher/students/mastery';

export const teacherStudentProgressSyllabusPath =
  '/teacher/students/:studentId/mastery/syllabuses/:syllabusId/';

export const teacherStudentProgressTopicPath =
  '/teacher/students/:studentId/mastery/syllabuses/:syllabusId/topics/:topicId/';

export const teacherStudentSkillsPath = '/teacher/students/:studentId/skills/';

export const teacherStudentSkillsRedirectorPath = '/teacher/students/skills';

export const teacherStudentSkillsReportPath =
  '/teacher/students/:studentId/skills/:strandId';

export const teacherStudentSkillsReportRedirectorPath =
  '/teacher/students/skills/:strandId';

export const teacherStudentTasksPath = '/teacher/students/:studentId/tasks/';

export const teacherStudentTasksRedirectorPath = '/teacher/students/tasks';

export const teacherStudentSettingsPath =
  '/teacher/students/:studentId/settings/';

export type TeacherStudentUrlParams = {
  studentId: StudentId;
};

export const teacherStudentRedirectUrl = () => '/teacher/students/';

export const teacherStudentBaseUrl = ({ studentId }: TeacherStudentUrlParams) =>
  `/teacher/students/${studentId}/`;

export const teacherStudentActivityUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/activity/`;

export const teacherStudentInsightsUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/insights/`;

export type TeacherStudentMasteryActivityUrlParams = {
  studentId: StudentId;
  subtopicId: SubtopicId;
};

export const getTeacherStudentMasteryActivityUrl = ({
  studentId,
  subtopicId,
}: TeacherStudentMasteryActivityUrlParams) =>
  `/teacher/students/${studentId}/mastery/mastery-activity/${subtopicId}/`;

export const teacherStudentProgressUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/mastery/`;

type TeacherStudentProgressSyllabusUrlParams = {
  studentId: StudentId;
  syllabusId: SyllabusId;
};

export const teacherStudentProgressSyllabusUrl = ({
  studentId,
  syllabusId,
}: TeacherStudentProgressSyllabusUrlParams) =>
  `/teacher/students/${studentId}/mastery/syllabuses/${syllabusId}/`;

type TeacherStudentProgressTopicUrlParams = {
  studentId: StudentId;
  syllabusId: SyllabusId;
  topicId: TopicId;
};

export const teacherStudentProgressTopicUrl = ({
  studentId,
  syllabusId,
  topicId,
}: TeacherStudentProgressTopicUrlParams) =>
  `/teacher/students/${studentId}/mastery/syllabuses/${syllabusId}/topics/${topicId}/`;

export const teacherStudentProgressExportUrl = ({
  studentId,
  syllabusId,
}: TeacherStudentProgressSyllabusUrlParams) =>
  `/teacher/students/${studentId}/mastery/syllabuses/${syllabusId}/download/`;

export const teacherStudentSkillsUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/skills/`;

export const teacherStudentSkillsReportUrl = ({
  studentId,
  strandId,
}: TeacherStudentUrlParams & { strandId: string }) =>
  `/teacher/students/${studentId}/skills/${strandId}`;

export const teacherStudentTasksUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/tasks/`;

export const teacherStudentTasksExportUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/tasks/download/`;

export const teacherStudentSettingsUrl = ({
  studentId,
}: TeacherStudentUrlParams) => `/teacher/students/${studentId}/settings/`;

type TeacherStudentViewType =
  | 'activity'
  | 'insights'
  | 'mastery'
  | 'tasks'
  | 'skills'
  | 'settings';

type TeacherStudentUrl = (p: TeacherStudentUrlParams) => string;

export const makeTeacherStudentUrl = ({
  view,
}: {
  view: TeacherStudentViewType | undefined | null;
}): TeacherStudentUrl => {
  if (view == null) return teacherStudentBaseUrl;
  switch (view) {
    case 'activity':
      return teacherStudentActivityUrl;
    case 'insights':
      return teacherStudentInsightsUrl;
    case 'mastery':
      return teacherStudentProgressUrl;
    case 'tasks':
      return teacherStudentTasksUrl;
    case 'skills':
      return teacherStudentSkillsUrl;
    case 'settings':
      return teacherStudentSettingsUrl;
    default:
      assertUnreachable(view, `Incorrect view for Teacher Student ${view}`);
  }
};
