import { graphql } from 'relay-hooks';

import useMutation from 'ms-utils/relay/useMutationWithArgs';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';

import type {
  useUpdateDashboardBackgroundSelectionMutationVariables,
  useUpdateDashboardBackgroundSelectionMutationResponse,
} from './__generated__/useUpdateDashboardBackgroundSelectionMutation.graphql';

const mutation = graphql`
  mutation useUpdateDashboardBackgroundSelectionMutation(
    $background: BackgroundEnum!
  ) {
    updateStudentBackground(background: $background) {
      student {
        background {
          url
          type
          name
          isUnlocked
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useUpdateDashboardBackgroundSelection(): UseMutationResponse<
  useUpdateDashboardBackgroundSelectionMutationVariables,
  useUpdateDashboardBackgroundSelectionMutationResponse
> {
  return useMutation({ mutation });
}
