import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    transform="translate(2.000000, 3.000000)"
    d="M12.366.847a2 2 0 0 1 3.268 2.306l-7.2 10.2a2 2 0 0 1-3.048.261l-4.8-4.8a2 2 0 0 1 2.828-2.828l3.121 3.12 5.83-8.26z"
    fillRule="nonzero"
  />
);

const CheckMarkThick = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CheckMarkThick.displayName = 'CheckMarkThick';

export default CheckMarkThick;
