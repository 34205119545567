import styled from '@emotion/styled';
import { useState } from 'react';

import { BackgroundPattern } from 'ms-pages/Lantern/components/BackgroundPattern';
import { HeadingM, BodyS } from 'ms-pages/Lantern/primitives/Typography';
import { colors, fonts } from 'ms-pages/Lantern/styles';
import Button from 'ms-ui-primitives/Button';
import Modal, { ModalBody } from 'ms-ui-primitives/Modal';
import { HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import { useAccessibilityMode } from 'ms-utils/accessibility';
import keyDownMap from 'ms-utils/keyDownMap';

const CenterVStack = styled(VStack)({
  alignItems: 'center',
});

const GRADE_LIST_GAP = 20;

const GradeList = styled(HStack)({
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: 500,
  margin: -GRADE_LIST_GAP / 2,
});

const BackgroundOpacityWrapper = styled.div<{ opacity: number }>(props => ({
  opacity: props.opacity,
  transition: 'opacity 0.25s ease',
}));

const GradeListItem = styled.div<{
  isSelected: boolean;
  accessibilityMode: boolean;
}>(props => ({
  display: 'flex',
  boxSizing: 'content-box',
  height: 56,
  minWidth: 66,
  borderRadius: 8,
  cursor: 'pointer',
  textDecoration: 'none',
  overflow: 'hidden',
  margin: GRADE_LIST_GAP / 2,
  fontSize: 20,
  color: props.isSelected ? colors.almond50 : colors.grey,
  backgroundColor: props.isSelected
    ? props.accessibilityMode
      ? colors.cloudBurst
      : colors.eggplant
    : colors.seashell,
  ...fonts.bodyBold,
}));

const GradeListItemLabel = styled.div({
  position: 'relative',
  width: '100%',
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

type Props = {
  grades: ReadonlyArray<{ readonly id: string; readonly shortTitle: string }>;
  defaultGradeId: string;
  isSubmitting: boolean;
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (value: string) => void;
  hasError: boolean;
};

function GradeLevelSelectForm(props: Props) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();

  const {
    grades,
    defaultGradeId,
    isSubmitting,
    isOpen,
    handleClose,
    onSubmit,
    hasError,
  } = props;

  const [selectedGradeId, setSelectedGradeId] = useState(defaultGradeId);

  const resetAndClose = () => {
    handleClose();
    setSelectedGradeId(defaultGradeId);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetAndClose}
      showCloseButton={false}
      closeOnOverlayTap
    >
      <ModalBody>
        <CenterVStack>
          <HeadingM>What grade level are you in at school?</HeadingM>
          <VSpacer height={24} />
          <GradeList>
            {grades.map(grade => {
              const isSelected = grade.id === selectedGradeId;

              return (
                <GradeListItem
                  key={grade.id}
                  role="button"
                  tabIndex={0}
                  isSelected={isSelected}
                  accessibilityMode={hasEnabledAccessibilityMode}
                  onClick={() => {
                    setSelectedGradeId(grade.id);
                  }}
                  onKeyDown={keyDownMap({
                    SPACE: () => setSelectedGradeId(grade.id),
                    ENTER: () => setSelectedGradeId(grade.id),
                  })}
                >
                  <BackgroundOpacityWrapper opacity={isSelected ? 1 : 0}>
                    {!hasEnabledAccessibilityMode && (
                      <BackgroundPattern width={200} height={56} />
                    )}
                  </BackgroundOpacityWrapper>
                  <GradeListItemLabel>{grade.shortTitle}</GradeListItemLabel>
                </GradeListItem>
              );
            })}
          </GradeList>
          <VSpacer height={24} />
          {hasError && (
            <>
              <BodyS color="cinnabar">
                Something went wrong. Refresh the page and try again.
              </BodyS>
              <VSpacer height={24} />
            </>
          )}
          <Button
            isDisabled={isSubmitting || selectedGradeId === defaultGradeId}
            type="primary"
            onClick={() => onSubmit(selectedGradeId)}
          >
            {isSubmitting ? 'Submitting...' : 'Confirm'}
          </Button>
          <VSpacer height={20} />
          <Button onClick={resetAndClose} isInline>
            Cancel
          </Button>
        </CenterVStack>
      </ModalBody>
    </Modal>
  );
}

export default GradeLevelSelectForm;
