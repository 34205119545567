import { graphql, useMutation } from 'relay-hooks';

import type { useCreateSkillCheckInMutation } from './__generated__/useCreateSkillCheckInMutation.graphql';

const USE_CREATE_SKILL_CHECK_IN_MUTATION = graphql`
  mutation useCreateSkillCheckInMutation($skillId: ID!, $strandId: ID!) {
    createSkillCheckIn(skillId: $skillId) {
      checkIn {
        id
        student {
          strandStatus(strandId: $strandId) {
            id
          }
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateSkillCheckIn() {
  return useMutation<useCreateSkillCheckInMutation>(
    USE_CREATE_SKILL_CHECK_IN_MUTATION,
  );
}
