import { css } from 'aphrodite';
import { useState } from 'react';

import { type SnackbarContext, useSnackbar } from 'ms-components/Snackbar';
import Button from 'ms-ui-primitives/Button';
import Separator from 'ms-ui-primitives/Separator';
import type { RelayCommitFunction } from 'ms-utils/typescript-utils';
import { AVATAR_PATH, AVATAR_PERMALINK_URL } from 'ms-utils/urls';

import AvatarCircle from './AvatarCircle';
import type { Title, Variant } from './AvatarContainer';
import type { AvatarContainerQueryResponse } from './__generated__/AvatarContainerQuery.graphql';
import type {
  AvatarContainerUpdateAvatarMutation,
  AvatarEnum,
} from './__generated__/AvatarContainerUpdateAvatarMutation.graphql';
import { defaultStyles, modalStyles } from './styles';

type EnqueueMessage = SnackbarContext['enqueueMessage'];
type Viewer = NonNullable<AvatarContainerQueryResponse['viewer']>;
type PublicProps = {
  doUpdateAvatar: RelayCommitFunction<AvatarContainerUpdateAvatarMutation>;
  isAvatarLoading: boolean;
  onSubmit: () => void;
  isSubmitDisabled?: boolean | undefined;
  submitButtonLabel?: string | undefined;
  forceLoadingState?: boolean | undefined;
  viewer: Viewer;
  title: Title;
  variant: Variant;
};
type Props = PublicProps & {
  enqueueMessage: EnqueueMessage;
};
type RenderProps = Props;
const AVATARS_N: AvatarEnum[] = [
  'ARTIST',
  'ASTRONAUT',
  'BASKETBALL',
  'BUNNY',
  'CAKE',
  'CONSTRUCTOR',
  'CORGI',
  'DOCTORBEAR',
  'F1RACER',
  'FOOTBALLPLAYER',
  'GIRL',
  'MUSICIAN',
  'PENGUIN',
  'PILOT',
  'ROBOT',
  'SCIENTIST',
  'SEAL',
  'SOFTWAREENGINEER',
  'SPACECAT',
  'TEACHER',
];
const AVATAR_DEFAULT = 'AVATAR_DEFAULT';
const AVATARS = AVATARS_N.concat(AVATAR_DEFAULT);
export const avatarToUrl = (avatar: string) =>
  `${AVATAR_PERMALINK_URL}${AVATAR_PATH}${
    avatar === AVATAR_DEFAULT
      ? 'avatar_placeholder'
      : `pictures/${avatar.toLowerCase().replace(/_/, '')}`
  }.png`;
const avatarUrlToName = (avatarUrl: string) =>
  AVATARS.find(a => avatarUrl.includes(a.toLowerCase())) || AVATAR_DEFAULT;
const updateAvatar = ({
  doUpdateAvatar,
  onSubmit,
  avatarName,
  viewer,
  enqueueMessage,
}: {
  doUpdateAvatar: RenderProps['doUpdateAvatar'];
  onSubmit: RenderProps['onSubmit'];
  avatarName: AvatarEnum;
  viewer: RenderProps['viewer'];
  enqueueMessage: RenderProps['enqueueMessage'];
}) => {
  doUpdateAvatar({
    variables: {
      user: { avatar: avatarName },
      pk: viewer.pk,
    },
    onCompleted: () => {
      onSubmit();
    },
    onError: error => {
      enqueueMessage({ text: error.message });
    },
  });
};
const AvatarPresentational = ({
  viewer,
  doUpdateAvatar,
  isAvatarLoading,
  onSubmit,
  isSubmitDisabled,
  forceLoadingState,
  enqueueMessage,
  submitButtonLabel = 'Save',
  title,
  variant = 'default',
}: RenderProps) => {
  const [selectedAvatar, setSelectedAvatar] = useState(
    avatarUrlToName(viewer.avatar),
  );
  const isLoading = (isAvatarLoading || forceLoadingState) ?? false;
  const styles = variant === 'default' ? defaultStyles : modalStyles;
  return (
    <div className={css(styles.avatarSelectorWrapper)}>
      <div className={css(styles.column)}>
        <div className={css(styles.title)}>
          {isLoading ? 'Updating your avatar...' : title}
        </div>
        <Separator size={8} />
        <div className={css(styles.selectedAvatarWrapper)}>
          <AvatarCircle
            variant={variant}
            avatarUrl={avatarToUrl(selectedAvatar)}
            isAvatarLoading={isLoading}
          />
        </div>
        <Separator size={8} />
      </div>

      <div className={css(styles.row, styles.avatarList)}>
        {AVATARS.map(avatarName => (
          <AvatarCircle
            variant={variant}
            size="small"
            key={avatarName}
            avatarUrl={avatarToUrl(avatarName)}
            isAvatarLoading={isLoading}
            onClick={() => setSelectedAvatar(avatarName)}
            hasBorder={selectedAvatar === avatarName}
          />
        ))}
      </div>
      <Separator size={8} />
      <Button
        type="primary"
        size={variant === 'modal' ? 'medium' : 'lanternMedium'}
        isDisabled={isLoading || isSubmitDisabled}
        onClick={() => {
          updateAvatar({
            doUpdateAvatar,
            onSubmit,
            avatarName: selectedAvatar,
            viewer,
            enqueueMessage,
          });
        }}
      >
        {submitButtonLabel}
      </Button>
    </div>
  );
};

export default function Avatar(props: PublicProps) {
  const { enqueueMessage } = useSnackbar();

  return <AvatarPresentational {...props} enqueueMessage={enqueueMessage} />;
}
