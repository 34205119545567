import { useState, useCallback, useEffect, useRef } from 'react';

import { unwrap } from 'ms-utils/typescript-utils';

// refactor this to use IntersectionObserver
export function checkIfElementIsInViewport(element: Element) {
  const bounding = element.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    // The fallback case here is 0 because we never want to fire a callback
    // when we are in an unexpected or unsupported browser
    bounding.right <=
      (window.innerWidth ||
        (document.documentElement
          ? document.documentElement.clientWidth
          : 0)) &&
    bounding.bottom <=
      (window.innerHeight ||
        (document.documentElement ? document.documentElement.clientHeight : 0))
  );
}

const DEBOUNCE_PERIOD = 500;
/**
 * This function returns a ref that can be bound to a domnode. It will
 * call function provided when that element is in view.
 *
 * Be warned that this hook will call your function multiple times once for
 * each time it comes in and out of view. The element must be COMPLETELY in view for the
 * callback to fire.
 *
 * Its primary purpose is to detect when when a list should request more items. A placeholder
 * div can be set at the bottom of the list as a "detector"
 *
 * Example Usage:
 * const ref = useWhenInViewport(React.useCallback(() => alert('hello world'), []))
 * ...
 * <div ref={ref} />
 */
export default function useWhenInViewport(callback: () => void) {
  const [elementVisible, setElementVisible] = useState(false);
  // We want the element in state because we want to unbind the event listeners when the target
  // gets unmounted or is undefined
  const [_ref, setRef] = useState<Element | null>(null);
  const checkElement = useCallback(() => {
    if (_ref === null) return;
    setElementVisible(checkIfElementIsInViewport(_ref));
  }, [_ref]);

  const ref = useCallback((element: Element | null) => setRef(element), []);

  useEffect(() => {
    if (_ref === null) return;
    const interval = setInterval(checkElement, 500);
    return () => {
      clearInterval(interval);
    };
  }, [_ref, checkElement]);

  useEffect(() => {
    if (elementVisible) {
      const timeoutId = setTimeout(callback, DEBOUNCE_PERIOD);
      return () => clearTimeout(timeoutId);
    } else {
      // Appease TS wanting consistent return type
      return () => {};
    }
  }, [callback, elementVisible]);

  return ref;
}

export function useIsVisible(rootElRef: { current: HTMLElement | null }) {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const element = rootElRef.current;
    if (element === null) return () => {};
    const ob = new IntersectionObserver(entries => {
      const entry = unwrap(entries[0]);
      setVisible(entry.isIntersecting);
    });
    ob.observe(element);
    return () => {
      ob.unobserve(element);
    };
  }, [rootElRef]);
  return visible;
}

export function useWhenVisible(
  callback: () => void,
): [{ current: HTMLElement | null }, boolean] {
  const ref = useRef<HTMLElement | null>(null);
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (!isVisible) return;
    const timeout = setTimeout(callback, DEBOUNCE_PERIOD);
    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible, callback]);

  return [ref, isVisible];
}
