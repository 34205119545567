import { StyleSheet, css } from 'aphrodite';
import { zip, tail } from 'ramda';

import type { SpacingData, CategoryData, IncrementData } from '../index';
import { getYScreenValue, getXScreenValue, calculateBinWidth } from '../utils';

type Props = {
  spacing: SpacingData;
  categories: CategoryData;
  increment: IncrementData;
  dragHandleAlignment?: 'center' | 'right' | undefined;
  dragHandleSize: number;
  hasGapBetweenBars: boolean;
};

const styles = StyleSheet.create({
  connector: {
    stroke: '#000',
    strokeWidth: 1,
  },
});

const Edges = ({
  spacing,
  categories,
  increment,
  dragHandleAlignment,
  dragHandleSize,
  hasGapBetweenBars,
}: Props) => {
  const halfDragHandleHeight = dragHandleSize / 2;
  const binWidth = calculateBinWidth(spacing, categories, hasGapBetweenBars);
  const axisYScreenPosition =
    getYScreenValue(increment.min, increment, spacing) + halfDragHandleHeight;
  const axisGap = hasGapBetweenBars ? 0 : binWidth / 2;
  const axisSpacing = -spacing.minBound - axisGap;
  const axisLength = hasGapBetweenBars
    ? categories.length
    : categories.length + 1;

  // Compute the coordinate for each category
  let coordinatesList = categories.map((category, index) => ({
    x:
      getXScreenValue(index, axisLength, spacing.availableSpace) +
      (dragHandleAlignment === 'right' ? binWidth / 2 : 0),
    y:
      getYScreenValue(category.value, increment, spacing) +
      halfDragHandleHeight,
  }));

  // Add coords to ensure line begins and ends on the x-axis
  coordinatesList = [
    { x: spacing.minBound + axisSpacing, y: axisYScreenPosition },
    ...coordinatesList,
    {
      x: getXScreenValue(
        categories.length - (hasGapBetweenBars ? 0.5 : 0),
        axisLength,
        spacing.availableSpace,
      ),
      y: axisYScreenPosition,
    },
  ];

  const coordPairs = zip(coordinatesList, tail(coordinatesList));

  return (
    <g transform={`translate(0,${spacing.minBound - halfDragHandleHeight})`}>
      {coordPairs.map((coordPair, index) => (
        <line
          key={`edge_${index}`}
          className={css(styles.connector)}
          x1={coordPair[0].x}
          y1={coordPair[0].y}
          x2={coordPair[1].x}
          y2={coordPair[1].y}
        />
      ))}
    </g>
  );
};

export default Edges;
