import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

export function GraphingCalculatorIcon() {
  return (
    <Icon size={32} viewBoxWidth={32} viewBoxHeight={32}>
      <rect width="32" height="32" rx="4" fill="currentColor" />
      <path
        d="M24.6707 19.8563C24.4038 19.5512 23.9292 19.5512 23.6623 19.8563V19.8563C23.4413 20.1089 23.4413 20.486 23.6623 20.7386L24.0902 21.2275H18.4137H16.6294V6.7541L16.9391 7.10799C17.206 7.41302 17.6805 7.41302 17.9474 7.10798V7.10798C18.1685 6.85539 18.1685 6.47824 17.9474 6.22565L16.3763 4.43004C16.1771 4.20238 15.8229 4.20238 15.6237 4.43004L14.0526 6.22565C13.8315 6.47824 13.8315 6.85539 14.0526 7.10798V7.10798C14.3195 7.41302 14.794 7.41302 15.0609 7.10799L15.3706 6.7541V21.2275H13.5863H7.90984L8.33766 20.7386C8.55866 20.486 8.55866 20.1089 8.33765 19.8563V19.8563C8.07074 19.5512 7.59619 19.5512 7.32928 19.8563L5.78809 21.6177C5.62314 21.8062 5.62315 22.0877 5.78809 22.2762L7.32929 24.0375C7.5962 24.3426 8.07073 24.3426 8.33764 24.0375V24.0375C8.55865 23.785 8.55865 23.4078 8.33764 23.1552L7.90984 22.6663H15.3705V25.2459L15.0609 24.892C14.794 24.5869 14.3194 24.5869 14.0525 24.892V24.892C13.8315 25.1446 13.8315 25.5217 14.0525 25.7743L15.6237 27.5699C15.8229 27.7976 16.177 27.7976 16.3763 27.5699L17.9474 25.7743C18.1684 25.5217 18.1684 25.1446 17.9474 24.892V24.892C17.6805 24.587 17.206 24.587 16.9391 24.892L16.6295 25.2459V22.6663H24.0902L23.6623 23.1552C23.4413 23.4078 23.4413 23.785 23.6623 24.0376V24.0376C23.9292 24.3426 24.4038 24.3426 24.6707 24.0376L26.2119 22.2762C26.3769 22.0877 26.3769 21.8062 26.2119 21.6177L24.6707 19.8563Z"
        fill={colors.white}
      />
      <path
        d="M7.60059 5.5C14.2468 27.084 17.8495 27.9121 24.1006 5.5"
        stroke={colors.white}
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </Icon>
  );
}

export default GraphingCalculatorIcon;
