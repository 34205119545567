import { StyleSheet, css } from 'aphrodite';
import type { ReactNode } from 'react';

import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const MESSAGE_PADDING = 20;

const styles = StyleSheet.create({
  message: {
    padding: MESSAGE_PADDING,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    fontFamily: fontFamily.body,
  },
});

type Props = {
  children: ReactNode;
  isError?: boolean;
};

// A simple helper component for displaying loading/error messages.
const LoadingMessage = ({ children, isError = false }: Props) => (
  <div
    className={css(styles.message)}
    style={{
      color: isError ? colors.cinnabar : colors.mako,
    }}
  >
    {children}
  </div>
);

export default LoadingMessage;
