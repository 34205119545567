import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M5.71907658,4.29124667 C6.44842913,4.34224797 6.93627779,4.82369519 7.18565283,5.19837016 C7.3336932,5.44398783 7.51461004,5.75384223 7.77033355,7.15019352 C8.02605706,8.5465448 7.97062353,9.66811856 7.85466122,10.7514741 C7.77735302,11.473711 7.64748193,12.442405 7.46504795,13.657556 L4.28972639,14.3300863 C3.17511253,12.1076747 2.73601403,10.1662487 2.97243089,8.50580817 C3.10617807,7.56645371 3.52290929,6.02548576 4.09136322,5.24973025 C4.52773117,4.65422933 4.98972403,4.24024537 5.71907658,4.29124667 Z M7.89676707,14.5823151 C9.06413471,17.4367442 8.87453686,19.075116 7.32797349,19.4974307 C5.78141013,19.9197454 5.00861904,18.5530941 5.00960022,15.3974767 L7.89676707,14.5823151 Z M14.2432954,0.655377696 C14.972648,0.604376397 15.4346408,1.01836036 15.8710088,1.61386128 C16.4394627,2.38961678 16.8561939,3.93058473 16.9899411,4.8699392 C17.226358,6.53037969 16.7872595,8.47180573 15.6726456,10.6942173 L12.4973241,10.021687 C12.3148901,8.80653604 12.185019,7.83784207 12.1077108,7.11560508 C11.9917485,6.03224959 11.9363149,4.91067583 12.1920384,3.51432454 C12.447762,2.11797326 12.6286788,1.80811886 12.7767192,1.56250119 C13.0260942,1.18782622 13.5139429,0.706378994 14.2432954,0.655377696 Z M12.0656049,10.9464462 L14.9527718,11.7616078 C14.953753,14.9172251 14.1809619,16.2838765 12.6343985,15.8615618 C11.0878351,15.439247 10.8982373,13.8008752 12.0656049,10.9464462 Z"
    id="Combined-Shape"
  />
);

const WorkedSolution = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

WorkedSolution.displayName = 'WorkedSolution';

export default WorkedSolution;
