import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const EllipsisHorizontal = ({
  color,
  size,
  title,
  titleId,
  useUpdatedPath = false,
  aphroditeStyles = [],
}: Props & { useUpdatedPath?: boolean | undefined }) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={useUpdatedPath ? 16 : undefined}
    viewBoxWidth={useUpdatedPath ? 16 : undefined}
  >
    {useUpdatedPath ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3077 8.14852C12.3077 9.16812 13.1342 9.99468 14.1538 9.99468C15.1734 9.99468 16 9.16812 16 8.14852C16 7.12892 15.1734 6.30237 14.1538 6.30237C13.1342 6.30237 12.3077 7.12892 12.3077 8.14852ZM6.15385 8.14852C6.15385 9.16812 6.9804 9.99468 8 9.99468C9.0196 9.99468 9.84615 9.16812 9.84615 8.14852C9.84615 7.12892 9.0196 6.30237 8 6.30237C6.9804 6.30237 6.15385 7.12892 6.15385 8.14852ZM1.84615 9.99468C0.826551 9.99468 -1.25266e-07 9.16812 -8.0698e-08 8.14852C-3.61297e-08 7.12892 0.826551 6.30237 1.84615 6.30237C2.86576 6.30237 3.69231 7.12892 3.69231 8.14852C3.69231 9.16812 2.86576 9.99468 1.84615 9.99468Z"
      />
    ) : (
      <g>
        <circle cx="5" cy="10" r="1.5" />
        <circle cx="10" cy="10" r="1.5" />
        <circle cx="15" cy="10" r="1.5" />
      </g>
    )}
  </Icon>
);

EllipsisHorizontal.displayName = 'EllipsisHorizontal';

export default EllipsisHorizontal;
