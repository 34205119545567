/* eslint-disable react/no-danger, jsx-a11y/no-static-element-interactions, react/sort-comp */
import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';
import MathQuill, { type IMathField } from 'mathquill';
import { isEmpty, test } from 'ramda';
import {
  createRef,
  Component,
  Fragment,
  type KeyboardEvent as SyntheticKeyboardEvent,
  type SyntheticEvent,
} from 'react';
import 'mathquill/build/mathquill-mathspace.css';

import { borderRadiusUI, mathquillStyles, transition } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { Logger, InvariantViolation } from 'ms-utils/app-logging';
import { MATHQUILL_CONFIG } from 'ms-utils/misc/latexConfig/mathquill';
import { assertUnreachable } from 'ms-utils/typescript-utils';

import Disabled from './Disabled';

type Latex = string;

type RequiredMathDisplayFeaturesType = {
  multiplicationNotation: 'CROSS' | 'DOT';
};

type Props = {
  aphroditeStyles: Array<CSSInputTypes>;
  block?: boolean | undefined;
  disabled?: boolean | undefined;
  onBlur?: ((event: Event) => void) | null | undefined;
  onChange: (latex: Latex) => void;
  onClick?: ((event: SyntheticEvent<any>) => void) | undefined;
  onFocus?: ((event: Event) => void) | null | undefined;
  readOnly?: boolean | undefined;
  requiredMathDisplayFeatures: RequiredMathDisplayFeaturesType;
  tabIndex: number;
  value: Latex;
  onKeyDown?: ((event: SyntheticKeyboardEvent<any>) => void) | undefined;
};

type DefaultProps = {
  aphroditeStyles: Array<CSSInputTypes>;
  disabled: boolean;
  readOnly: boolean;
  tabIndex: number;
  requiredMathDisplayFeatures: RequiredMathDisplayFeaturesType;
};

// MathQuill internally supports a \editable{} macro, which changes the
// behaviour of a MathField.
// See class comment for LatexMultiInput below.
const MATHQUILL_EDITABLE_REGEX = /\\editable\{.*?\}/g;

const styles = StyleSheet.create({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  block: {
    display: 'block',
    width: '100%',
  },
  mathField: {
    border: 'none',
  },
  disabled: {
    display: 'none',
  },
});

const isMultiInputExpression = test(MATHQUILL_EDITABLE_REGEX);

/**
 * This component is a declarative wrapper for the MathQuill.MathField API.
 *
 * LatexMultiInput provides an interface for MathQuill's implicit \editable{}
 * sub-fields.  This component should never be used to render a latex string
 * without the \editable{} macro.
 */
class LatexMultiInput extends Component<Props, {}> {
  static defaultProps: DefaultProps;

  textareas: ReadonlyArray<HTMLTextAreaElement> = [];
  MQ: IMathField | null = null;

  override componentDidMount() {
    if (this.node.current == null) return;

    const multiplicationDisplaySymbol = getMultiplicationDisplaySymbol(
      this.props.requiredMathDisplayFeatures.multiplicationNotation,
    );

    const config = {
      ...MATHQUILL_CONFIG,
      multiplicationDisplaySymbol,
      handlers: {
        // During initialisation, we don't want to trigger onChange.
        // eslint-disable-next-line no-unused-vars
        edit: (_mathField: IMathField) => {},
      },
    };

    const MQ = MathQuill.MathField(this.node.current, config);

    // Set the initial value.
    MQ.latex(this.props.value);

    // We have to repeat this check because flow is paranoid of side effects.
    if (this.node.current == null) return;
    const textareas = (this.textareas = Array.from(
      this.node.current.querySelectorAll('textarea'),
    ));

    // When any "sub-editable" textarea is activated, trigger this component's
    // focus and blur handlers.
    textareas.forEach(textarea => {
      textarea.setAttribute('tabindex', String(this.props.tabIndex));
      textarea.addEventListener('blur', this.handleBlur);
      textarea.addEventListener('focus', this.handleFocus);
    });
    // We never want to be able to tab into the root textarea for a LatexMultiInput.
    if (textareas[0] != null) {
      textareas[0].setAttribute('tabindex', '-1');
    }

    this.handleReadOnlyPropChange();

    config.handlers.edit = mathField => {
      this.props.onChange(mathField.latex());
    };

    // Retain the MQ reference for later use.
    this.MQ = MQ;
  }

  override componentDidUpdate(prevProps: Props) {
    if (this.props.readOnly !== prevProps.readOnly) {
      this.handleReadOnlyPropChange();
    }
    if (!isMultiInputExpression(this.props.value)) {
      Logger.error(
        new InvariantViolation(
          'LatexInput received latex without \\editable{}',
        ),
        {
          tags: { component: 'LatexInput' },
          extra: { latex: this.props.value },
        },
      );
    }

    // If the incoming latex value doesn't match what's currently rendered in
    // the MathField, update it.
    if (
      this.MQ != null &&
      this.props.value !== prevProps.value &&
      this.props.value !== this.MQ.latex()
    ) {
      this.MQ.latex(this.props.value);
    }

    // If brackets or other variable size characters are rendered while in
    // disabled mode, we need to reflow to resize them correctly.
    if (
      this.MQ != null &&
      prevProps.disabled === true &&
      this.props.disabled === false
    ) {
      this.MQ.reflow();
    }
  }

  override componentWillUnmount() {
    this.blur();
  }

  node: {
    readonly current: HTMLDivElement | null;
  } = createRef<HTMLDivElement>();

  blur = () => {
    if (this.textareas[1] != null) this.textareas[1].blur();
  };

  focus = () => {
    if (this.textareas[1] != null) this.textareas[1].focus();
  };

  select = () => {
    // Mathquill doesn't provide a good hook for performing "select" on a
    // sub-editable field.
    // We need this stub, just to provide a consistent interface with
    // <LatexInput>, but we will not support it at this time.
  };

  handleBlur = (event: Event) => {
    if (this.props.onBlur != null) this.props.onBlur(event);
  };

  handleFocus = (event: Event) => {
    if (this.props.onFocus != null) this.props.onFocus(event);
  };

  handleReadOnlyPropChange = () => {
    const textareas = this.textareas;
    if (isEmpty(textareas)) return;

    textareas.forEach(textarea => {
      if (this.props.readOnly) {
        textarea.setAttribute('readonly', '');
      } else {
        textarea.removeAttribute('readonly');
      }
    });
  };

  override render() {
    const { aphroditeStyles, block, disabled, onClick, value } = this.props;

    const mathFieldClass = css(
      styles.mathField,
      Boolean(block) && styles.block,
      ...aphroditeStyles,
    );

    return (
      <Fragment>
        {disabled && (
          <Disabled
            aphroditeStyles={aphroditeStyles}
            block={block}
            value={value}
          />
        )}
        <div
          onClick={onClick}
          className={css(
            styles.root,
            Boolean(block) && styles.block,
            Boolean(disabled) && styles.disabled,
          )}
        >
          <style type="text/css">{`

            .${mathFieldClass}.mq-editable-field, .${mathFieldClass} .mq-editable-field {
              border: none;
              display: inline-block;
            }
            .${mathFieldClass}.mq-editable-field > .mq-root-block {
              border: 1px solid ${colors.iron};
              border-radius: ${borderRadiusUI}px;
              background: ${colors.athensGray};
            }
            .${mathFieldClass}.mq-editable-field .mq-inner-editable > .mq-root-block {
              background: ${colors.white};
            }
            .${mathFieldClass}.mq-editable-field .mq-root-block {
              border-radius: ${borderRadiusUI}px;
              padding: ${mathquillStyles.padding.shorthand};
              transition: border-color ${transition};
            }

            .${mathFieldClass}.mq-editable-field .mq-inner-editable {
              border: 1px solid ${colors.iron};
              border-radius: ${borderRadiusUI}px;
              transition: border-color ${transition};
            }
            .${mathFieldClass}.mq-editable-field .mq-inner-editable.mq-focused {
              border-color: ${colors.lochmara};
            }
          `}</style>
          <div
            className={`${mathFieldClass} mq-editable-field mq-math-mode`}
            ref={this.node}
            onKeyDown={event => {
              if (this.props.onKeyDown) this.props.onKeyDown(event);
            }}
          />
        </div>
      </Fragment>
    );
  }
}

LatexMultiInput.defaultProps = {
  aphroditeStyles: [],
  disabled: false,
  readOnly: false,
  tabIndex: 0,
  requiredMathDisplayFeatures: {
    multiplicationNotation: 'CROSS',
  },
};

function getMultiplicationDisplaySymbol(
  notation: RequiredMathDisplayFeaturesType['multiplicationNotation'],
): (typeof MATHQUILL_CONFIG)['multiplicationDisplaySymbol'] {
  switch (notation) {
    case 'CROSS':
      return 'cross';
    case 'DOT':
      return 'dot';
    default:
      assertUnreachable(notation);
  }
}

export default LatexMultiInput;
export type { RequiredMathDisplayFeaturesType };
