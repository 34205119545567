const suffixes = new Map([
  [1, 'st'],
  [2, 'nd'],
  [3, 'rd'],
]);

export function formatOrdinals(n: number) {
  const lastTwoDigits = n % 100;
  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${n}th`;
  }
  return `${n}${suffixes.get(n % 10) || 'th'}`;
}
