import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const TemplateMove = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    <path d="M14.327 7.699a3.663 3.663 0 0 1-.021-.022l-.005-.005a2.59 2.59 0 0 0-1.333-.72V3.427a1 1 0 0 0-1-1H4.859A.714.714 0 1 1 4.86 1h8.468a1 1 0 0 1 1 1v5.699Zm-2.545-.711v-2.34a1 1 0 0 0-1-1H2.6a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h7.274a2.628 2.628 0 0 1-.002-.171h-.666A2.611 2.611 0 0 1 6.6 12.213c0-1.032.6-1.938 1.483-2.361H4.799a.636.636 0 1 1 0-1.273h3.784a.636.636 0 0 1 .507 1.02l.116-.002h.64a2.618 2.618 0 0 1 1.936-2.61Zm-7.62-.376c0-.351.286-.636.637-.636h3.784a.636.636 0 0 1 0 1.273H4.799a.636.636 0 0 1-.636-.637Z" />
    <path d="M11.499 9.517c0 .24.09.481.273.665l.98.99H9.067a.937.937 0 0 0-.932.94c0 .52.417.941.932.941h3.685l-.955.965a.944.944 0 0 0 0 1.33.925.925 0 0 0 1.318 0l.203-.204.495-.5c.213-.217.425-.431.639-.646.21-.21.416-.429.63-.638.206-.204.408-.41.602-.627a.916.916 0 0 0 .144-1.055c-.117-.21-.31-.376-.476-.545-.202-.207-.408-.41-.613-.616-.244-.245-.486-.492-.728-.738-.207-.206-.413-.414-.619-.621l-.297-.301-.005-.005a.926.926 0 0 0-1.318 0 .938.938 0 0 0-.273.665Z" />
  </Icon>
);

TemplateMove.displayName = 'TemplateMove';

export default TemplateMove;
