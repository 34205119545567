import { css, StyleSheet } from 'aphrodite';

import { fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import {
  fontFamily,
  fontSize,
  lineHeight,
} from 'ms-styles/theme/CurriculumMapping';

type Props = {
  text: string;
  variant: 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small';
};

const MARGIN_LARGE = 16;
const MARGIN_MEDIUM = 8;
const MARGIN_SMALL = 4;

const styles = StyleSheet.create({
  heading: {
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.heading,
  },
  xxlarge: {
    marginBottom: MARGIN_LARGE,
    color: colors.mako,
    fontSize: fontSize.xxlarge,
    fontWeight: fontWeight.semibold,
  },
  xlarge: {
    marginBottom: MARGIN_LARGE,
    color: colors.mako,
    fontSize: fontSize.xlarge,
    fontWeight: fontWeight.semibold,
  },
  large: {
    marginBottom: MARGIN_LARGE,
    color: colors.mako,
    fontSize: fontSize.large,
    fontWeight: fontWeight.semibold,
  },
  medium: {
    marginBottom: MARGIN_MEDIUM,
    marginTop: MARGIN_MEDIUM,
    color: colors.nevada,
    fontWeight: fontWeight.semibold,
  },
  small: {
    marginBottom: MARGIN_SMALL,
    color: colors.dustyGray,
    fontSize: fontSize.xsmall,
    fontWeight: fontWeight.semibold,
    textTransform: 'uppercase',
  },
});

const Heading = ({ text, variant }: Props) => (
  <div className={css(styles.heading, styles[variant])}>{text}</div>
);

export default Heading;
