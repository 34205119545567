import type { CSSInputTypes } from 'aphrodite';
import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { colors, alternateColors } from 'ms-styles/colors';
import { useColorTheme } from 'ms-styles/themes';
import { onHover } from 'ms-utils/emotion';

import styles from '../styles';

type Props = {
  children: ReactNode;
  aphroditeStyles?: CSSInputTypes[] | undefined;
  to: string;
};

/**
 * Wraps ReactRouter's link component with our custom styles.
 * The API is intended to be identical.
 *
 * @see https://reacttraining.com/react-router/web/api/Link
 * @param {*} props
 */
export default function Link({
  children,
  aphroditeStyles = [],
  to,
  ...rest
}: Props) {
  const { primary: primaryColorName } = useColorTheme();
  const { linkColor: linkColorStyles } = StyleSheet.create({
    linkColor: {
      color: colors[primaryColorName],
      ...onHover({
        color: alternateColors[primaryColorName],
      }),
    },
  });

  return (
    <ReactRouterLink
      {...rest}
      className={css(styles.link, linkColorStyles, ...aphroditeStyles)}
      to={to}
    >
      {children}
    </ReactRouterLink>
  );
}
