import platform from 'platform';
import { match, test } from 'ramda';

declare global {
  interface Window {
    // Defined in react_app.html
    SITE_VERSION: string;
  }
}

/* 
  NOTE: This types and structure of the AppEnv are also used by the server in 
  msproblem/server/app_environment.py
  As such when changing the structure of the AppEnv we need to ensure that the
  server is also updated to match.
*/

export type RuntimeType =
  | 'ANDROID_WEBVIEW'
  | 'IOS_WEBVIEW'
  | 'WIN8_WEBVIEW'
  | 'RN_IOS_WEBVIEW'
  | 'RN_ANDROID_WEBVIEW'
  | 'RN_WINDOWS_WEBVIEW'
  | 'CHROME'
  | 'EDGE'
  | 'FIREFOX'
  | 'IE'
  | 'SAFARI'
  | 'OTHER';

export type Runtime = {
  TYPE: RuntimeType;
  VERSION?: string | undefined;
};

type DeviceType = 'PHONE' | 'TABLET' | 'OTHER';
type Platform = 'IOS' | 'ANDROID' | 'WIN';

export type Device = {
  TYPE: DeviceType;
  PLATFORM?: Platform;
  OS?: string;
};

export type AppEnv = {
  DEVICE: Device;
  RUNTIME: Runtime;
  WEB_APP_VERSION: string;
  REACT_NATIVE_APP?: {
    binaryVersion: string;
    codePushVersion?: string;
  };
  APPLICATION_ENVIRONMENT: string;
};

export const privateHelperGetDevice = (): Device => {
  const OS = `${platform.os?.family} ${platform.os?.version}`;
  const userAgent = window.navigator.userAgent;

  // We don't have any better information available to us for device, so
  // we fall back to user agent sniffing
  const isAndroid = test(/android/i, userAgent);
  const isIPad =
    test(/iPad/, userAgent) ||
    (test(/Mac/, userAgent) && 'ontouchend' in document);
  const isIPhone = test(/iPhone/, userAgent);
  const isWin8TabletUserAgent = test(/Touch/, userAgent);

  if (isIPhone) {
    return { OS, TYPE: 'PHONE', PLATFORM: 'IOS' };
  }

  if (isIPad) {
    return { OS, TYPE: 'TABLET', PLATFORM: 'IOS' };
  }

  if (isWin8TabletUserAgent) {
    return { OS, TYPE: 'TABLET', PLATFORM: 'WIN' };
  }

  if (isAndroid && window.screen != null) {
    // We don't have enough information to really know if this is an Android
    // tablet or phone.
    // Here we simply match the behaviour of our Android native app.
    // https://github.com/mathspace/mathspace-android/blob/releases/v1.4.8/main/res/values-sw600dp/attrs.xml
    // https://developer.android.com/guide/practices/screens_support.html#NewQualifiers
    const smallestWidth = Math.min(window.screen.height, window.screen.width);
    if (smallestWidth >= 600) {
      return { OS, TYPE: 'TABLET', PLATFORM: 'ANDROID' };
    }
    return { OS, TYPE: 'PHONE', PLATFORM: 'ANDROID' };
  }

  return { OS, TYPE: 'OTHER' };
};

// The list of browser names detected by `platform` package:
// https://github.com/bestiejs/platform.js/blob/master/platform.js#L354
const getRuntimeTypeFromBrowserName = (
  browserName: string | undefined,
): RuntimeType | null => {
  switch (browserName) {
    case 'Chrome':
    case 'Chrome Mobile':
      return 'CHROME';
    case 'Microsoft Edge':
      return 'EDGE';
    case 'Firefox':
      return 'FIREFOX';
    case 'IE':
      return 'IE';
    case 'Safari':
      return 'SAFARI';
    default:
      return null;
  }
};

export const privateHelperGetRuntime = (): Runtime => {
  const userAgent = window.navigator.userAgent;

  // These user agent strings are provided via our native app implementations.
  const isAndroidWebview = test(/MathspaceAND/i, userAgent);
  const isIOSWebview = test(/MathspaceIOS/, userAgent);
  const isWin8Webview = test(/WebView|MSAppHost/, userAgent);

  if (isAndroidWebview) {
    return {
      TYPE: 'ANDROID_WEBVIEW',
      VERSION: match(/MathspaceAND\/([\d.]+)$/, userAgent)[1],
    };
  }

  if (isIOSWebview) {
    return {
      TYPE: 'IOS_WEBVIEW',
      VERSION: match(/MathspaceIOS\/([\d.]+)$/, userAgent)[1],
    };
  }

  if (isWin8Webview) {
    return {
      TYPE: 'WIN8_WEBVIEW',
    };
  }

  const { name, version } = platform.parse();
  const runtimeType = getRuntimeTypeFromBrowserName(name);

  if (runtimeType && version) {
    return {
      TYPE: runtimeType,
      VERSION: version,
    };
  }

  return {
    TYPE: 'OTHER',
  };
};

export const isNativeAppHost = (appEnv: AppEnv): boolean =>
  [
    'ANDROID_WEBVIEW',
    'IOS_WEBVIEW',
    'WIN8_WEBVIEW',
    'RN_IOS_WEBVIEW',
    'RN_ANDROID_WEBVIEW',
    'RN_WINDOWS_WEBVIEW',
  ].includes(appEnv.RUNTIME.TYPE);

export const isLegacyNativeAppHost = (appEnv: AppEnv): boolean =>
  ['ANDROID_WEBVIEW', 'IOS_WEBVIEW', 'WIN8_WEBVIEW'].includes(
    appEnv.RUNTIME.TYPE,
  );

export const isLegacyIosAppHost = (appEnv: AppEnv): boolean =>
  appEnv.RUNTIME.TYPE === 'IOS_WEBVIEW';

export const isReactNativeAppHost = (appEnv: AppEnv): boolean =>
  ['RN_IOS_WEBVIEW', 'RN_ANDROID_WEBVIEW', 'RN_WINDOWS_WEBVIEW'].includes(
    appEnv.RUNTIME.TYPE,
  );

export const isIosAppHost = (appEnv: AppEnv): boolean =>
  ['RN_IOS_WEBVIEW'].includes(appEnv.RUNTIME.TYPE);

export const isAndroidAppHost = (appEnv: AppEnv): boolean =>
  ['RN_ANDROID_WEBVIEW'].includes(appEnv.RUNTIME.TYPE);

/**
 * Get the web app version as injected via Django.
 *
 * @see /msproblem/templates/elements/react_app.html
 * @example
 * privateHelperGetWebAppVersion(); // => 'r2134'
 * @returns {string} the release tag for the current version of Mathspace
 */
export const privateHelperGetWebAppVersion = (): string =>
  window.SITE_VERSION || '';

// Consider rewriting this without using `RUNTIME.TYPE` once
// `REACT_NATIVE_APP` is set by the RN app
// and the legacy apps are no longer supported.
const defaultAppName = 'Mathspace Web';
export const getAppName = (appEnv: AppEnv): string => {
  if (isLegacyNativeAppHost(appEnv))
    return `Legacy Mathspace (${platform.os?.family})`;

  if (isReactNativeAppHost(appEnv))
    return `Mathspace for Students (${platform.os?.family})`;

  return defaultAppName;
};
