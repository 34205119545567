import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { HEADER_SIZE } from 'ms-components/curriculum-mapping/Header';
import CrossThickIcon from 'ms-components/icons/CrossThick';
import Portal from 'ms-helpers/Portal';
import { font, fontSize, fontFamily } from 'ms-styles/base';
import { colors, lanternColors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Overlay from 'ms-ui-primitives/Overlay';

const Centred = styled.div({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
});
const DialogContainer = styled.div({
  width: 450,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: 12,
  background: 'white',
  boxShadow: '0px 8.75px 49px rgba(62, 62, 76, 0.15)',
});
const Header = styled.div({
  height: HEADER_SIZE,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '20px 32px 0',
  position: 'relative',
});

const HeaderTitle = styled.div({
  ...font.gilroyHeading,
  color: lanternColors.grey,
});
const CloseButtonWrapper = styled.div({
  marginLeft: 'auto',
});

const Body = styled.div({
  fontFamily: fontFamily.body,
  fontSize: fontSize.medium,
  lineHeight: '1.25',
  color: colors.shuttleGray,
  padding: '20px 32px',
  width: '100%',
});

const ActionContainer = styled.div({
  padding: '20px 32px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const Half = styled.div({
  flex: '0 0 50%', // this seems to be ignored, that's why we use width: 50% below
  width: '50%',
  ':first-child': {
    paddingRight: 8,
  },
  ':last-child': {
    paddingLeft: 8,
  },
});
type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  confirmLabel: string;
  cancelLabel?: string | undefined;
  header: string;
  body: ReactNode;
};

export default function DeleteNote({
  isOpen,
  onConfirm,
  onCancel,
  onClose,
  confirmLabel,
  cancelLabel = 'Cancel',
  header,
  body,
}: Props) {
  if (!isOpen) return null;
  return (
    <Portal isOpen={isOpen} hasScrollPrevention>
      <Overlay animateClose={false} theme="dark" onClick={onClose}>
        <Centred>
          <DialogContainer>
            <Header>
              <HeaderTitle>{header}</HeaderTitle>
              <CloseButtonWrapper onClick={onClose}>
                <Button onClick={onClose} color="cloudBurst" label="Close">
                  <CrossThickIcon size={22} />
                </Button>
              </CloseButtonWrapper>
            </Header>

            <Body>{body}</Body>

            <ActionContainer>
              <Half>
                <Button
                  onClick={onCancel}
                  type="secondary"
                  isRound
                  isBlock
                  size="regular"
                >
                  {cancelLabel}
                </Button>
              </Half>

              <Half>
                <Button
                  onClick={onConfirm}
                  type="primary"
                  isRound
                  isBlock
                  size="regular"
                >
                  {confirmLabel}
                </Button>
              </Half>
            </ActionContainer>
          </DialogContainer>
        </Centred>
      </Overlay>
    </Portal>
  );
}
