import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g>
    <circle cx="10" cy="5" r="1.5" />
    <circle cx="10" cy="10" r="1.5" />
    <circle cx="10" cy="15" r="1.5" />
  </g>
);

const EllipsisVertical = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

EllipsisVertical.displayName = 'EllipsisVertical';

export default EllipsisVertical;
