import { css, cx } from '@emotion/css';
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  type ReactNode,
  Suspense,
} from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import ErrorBoundaryWithRetry from 'ms-components/ErrorBoundaryWithRetry';
import Retry from 'ms-components/Retry';
import { useSnowplow } from 'ms-helpers/Snowplow';
import { useUserPreference } from 'ms-helpers/UserPreferenceContext';
import {
  BODY_PADDING,
  FLYOUT_CONTENT_PADDING_RIGHT,
} from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import { PRIMARY_CATFA_ACTION_BUTTON_ID } from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import {
  UpdatersContext as ProblemsCollectionUpdaters,
  StateContext,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type { Problem as ProblemContentInCollectionTuple } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { MAX_NUMBER_OF_QUESTIONS } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';
import type { CollectionType } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/updaters';
import ProblemDifficultyFilter from 'ms-pages/Textbooks/components/ProblemDifficultyFilter';
import useDifficultyLevelFilters from 'ms-pages/Textbooks/components/ProblemDifficultyFilter/hooks';
import ProblemItem from 'ms-pages/Textbooks/components/ProblemItem';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';

import type {
  SubtopicProblemsQuery,
  SubtopicProblemsQueryResponse,
} from './__generated__/SubtopicProblemsQuery.graphql';

export type { ProblemContentInCollectionTuple };
export type SubtopicProblemsSubtopic =
  SubtopicProblemsQueryResponse['subtopic'];

type Props = {
  subtopicId: string;
  canCollectProblems?: boolean | undefined;
  // This is a temporary key. It will be removed once bulk custom task has been merged.
  renderAdaptiveSubtopicTitle?:
    | ((subtopic: SubtopicProblemsSubtopic) => ReactNode)
    | undefined;
  hideExpandButton?: boolean | undefined;
  questionsCollapsed?: boolean | undefined;
  useTransientProblemsCollection?: boolean | undefined;
  hideDifficultyFilter?: boolean | undefined;
  isHeaderSticky?: boolean | undefined;
  stickyHeaderTopOffset?: number | undefined;
  hasSelfHorizontalPadding?: boolean | undefined;
};

const styles = {
  header: {
    default: css({
      flex: '1 1 100%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 12,
      paddingBottom: 16,
      gap: 16,
    }),
    isSticky: css({
      position: 'sticky',
      zIndex: 1,
      backgroundColor: colors.white,
    }),
  },
  problemContainer: {
    default: css({
      position: 'relative',
    }),
  },
  commonHorizontalPadding: css({
    paddingLeft: BODY_PADDING,
    paddingRight: FLYOUT_CONTENT_PADDING_RIGHT,
  }),
};

export default function SubtopicProblems(props: Props) {
  return (
    <ErrorBoundaryWithRetry
      fallback={({ error, retry }) => (
        <Retry retry={retry} message={error.message} />
      )}
      name="SubtopicProblems"
    >
      {({ fetchKey }) => (
        <Suspense fallback={<MinorSpinner />}>
          <SubtopicProblemsInner {...props} fetchKey={fetchKey} />
        </Suspense>
      )}
    </ErrorBoundaryWithRetry>
  );
}

/*
 * Given a subtopic ID, renders all of the problem contents associated with the problem
 * content */
function SubtopicProblemsInner({
  subtopicId,
  fetchKey,
  ...rest
}: Props & { fetchKey: number }) {
  const data = useLazyLoadQuery<SubtopicProblemsQuery>(
    graphql`
      query SubtopicProblemsQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          title
          problemContents {
            id
            difficultyLevel
            estimatedCompletionTime
            isStaticQuestion
            problemTemplateId
            previewWorkoutCreationToken
            ...ProblemItem_problemContent
          }
        }
      }
    `,
    { subtopicId },
    {
      fetchPolicy: 'store-and-network',
      fetchKey,
    },
  );

  const { subtopic } = data;

  if (subtopic == null) {
    throw new Error('Subtopic not found');
  }

  return (
    <SubtopicProblemsUi subtopic={subtopic} subtopicId={subtopicId} {...rest} />
  );
}

function SubtopicProblemsUi({
  subtopic,
  subtopicId,
  canCollectProblems,
  renderAdaptiveSubtopicTitle,
  hideExpandButton = false,
  questionsCollapsed: _questionsCollapsed,
  useTransientProblemsCollection = false,
  hideDifficultyFilter = false,
  isHeaderSticky = false,
  stickyHeaderTopOffset = 0,
  hasSelfHorizontalPadding = false,
}: Props & {
  subtopic: NonNullable<SubtopicProblemsSubtopic>;
}) {
  const {
    problemsCollection: _problemsCollection,
    transientProblemsCollection,
  } = useContext(StateContext);

  const problemsCollection = useTransientProblemsCollection
    ? transientProblemsCollection
    : _problemsCollection;

  const problemCollectionType: CollectionType = useTransientProblemsCollection
    ? 'transient'
    : 'main';

  const { addMultipleProblemContents } = useContext(ProblemsCollectionUpdaters);
  const { questionsCollapsed, setQuestionsCollapsed } = useUserPreference();
  const { trackStructEvent } = useSnowplow();

  const toggleQuestionsCollapsed = useCallback(() => {
    trackStructEvent({
      category: 'create_task_from_anywhere',
      action: questionsCollapsed
        ? 'clicked_expand_all_questions'
        : 'clicked_collapse_all_questions',
      label: 'subtopic_problems',
      value: subtopicId,
    });
    setQuestionsCollapsed(s => !s);
  }, [trackStructEvent, questionsCollapsed, subtopicId, setQuestionsCollapsed]);

  // Makes sure that when questionsCollapsed prop is received,
  // the questionsCollapsed state is in sync with it
  useEffect(() => {
    if (
      _questionsCollapsed != null &&
      _questionsCollapsed !== questionsCollapsed
    ) {
      setQuestionsCollapsed(_questionsCollapsed);
    }
  }, [_questionsCollapsed, questionsCollapsed, setQuestionsCollapsed]);

  const {
    selectedDifficultyLevels,
    toggleDifficultyLevelSelection,
    selectedDifficultyFilters,
    canShowDifficultyFilter,
  } = useDifficultyLevelFilters({
    problemContents: subtopic.problemContents,
  });

  const filteredQuestions = subtopic.problemContents.filter(problemContent => {
    const isStaticQuestion = problemContent.isStaticQuestion;
    if (selectedDifficultyFilters.length === 0) {
      return !isStaticQuestion;
    }

    return (
      !isStaticQuestion &&
      selectedDifficultyFilters.includes(
        problemContent.difficultyLevel ?? 'UNSPECIFIED',
      )
    );
  });

  return (
    <Fragment>
      {renderAdaptiveSubtopicTitle != null ? (
        <div
          className={cx(
            hasSelfHorizontalPadding && styles.commonHorizontalPadding,
          )}
        >
          {renderAdaptiveSubtopicTitle(subtopic)}
        </div>
      ) : null}
      {/* To prevent getting an extra empty space we also check for !hideExpandButton and canCollectProblems if none of them is going to be rendered */}
      {(!hideExpandButton || canCollectProblems || !hideDifficultyFilter) && (
        <div
          className={cx(
            styles.header.default,
            hasSelfHorizontalPadding && styles.commonHorizontalPadding,
            isHeaderSticky && styles.header.isSticky,
          )}
          style={{
            ...(isHeaderSticky && { top: stickyHeaderTopOffset }),
          }}
        >
          {!hideDifficultyFilter && canShowDifficultyFilter && (
            <ProblemDifficultyFilter
              selectedDifficultyLevels={selectedDifficultyLevels}
              toggleDifficultyLevelSelection={toggleDifficultyLevelSelection}
            />
          )}
          {!hideExpandButton && (
            <Button
              size="regular"
              type="tertiary"
              onClick={toggleQuestionsCollapsed}
              padding={0}
              height={22}
            >
              {questionsCollapsed ? 'Expand All' : 'Collapse All'}
            </Button>
          )}
          <Stack.Spacer.H width={16} grow />
          {canCollectProblems && (
            <Button
              size="regular"
              type="tertiary"
              padding={0}
              height={22}
              isDisabled={problemsCollection.length >= MAX_NUMBER_OF_QUESTIONS}
              onClick={() => {
                addMultipleProblemContents(
                  filteredQuestions.map(p => [
                    p.id,
                    p.estimatedCompletionTime,
                    p.problemTemplateId,
                    p.previewWorkoutCreationToken,
                    subtopicId,
                  ]),
                  problemCollectionType,
                );
              }}
            >
              {`Add all (${filteredQuestions.length})`}
            </Button>
          )}
        </div>
      )}
      {filteredQuestions.map((pc, idx) => (
        <div
          key={idx}
          className={cx(
            styles.problemContainer.default,
            hasSelfHorizontalPadding && styles.commonHorizontalPadding,
          )}
        >
          <ProblemItem
            addToCollectionAnimationTarget={PRIMARY_CATFA_ACTION_BUTTON_ID}
            showTryProblem
            problemContent={pc}
            showAddButton={canCollectProblems}
            collapsedInBulk={questionsCollapsed}
            useTransientProblemsCollection={useTransientProblemsCollection}
            structEventToTrackOnAddToCollection={{
              category: 'create_task_from_anywhere',
              action: 'added_question_to_cart',
              label: subtopicId,
              property: pc.problemTemplateId,
            }}
          />
        </div>
      ))}
    </Fragment>
  );
}
