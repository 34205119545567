/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TaskTemplateFlowQueryVariables = {
    syllabusId: string;
};
export type TaskTemplateFlowQueryResponse = {
    readonly syllabus: {
        readonly topics: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly subtopics: {
                        readonly edges: ReadonlyArray<{
                            readonly node: {
                                readonly id: string;
                                readonly title: string;
                                readonly hasExercise: boolean;
                            };
                        }>;
                    };
                };
            }>;
        };
    } | null;
};
export type TaskTemplateFlowQuery = {
    readonly response: TaskTemplateFlowQueryResponse;
    readonly variables: TaskTemplateFlowQueryVariables;
};



/*
query TaskTemplateFlowQuery(
  $syllabusId: ID!
) {
  syllabus(id: $syllabusId) {
    topics(first: 1000) {
      edges {
        node {
          id
          subtopics(first: 1000) {
            edges {
              node {
                id
                title
                hasExercise
              }
            }
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "syllabusId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "syllabusId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "TopicConnection",
  "kind": "LinkedField",
  "name": "topics",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TopicEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": (v2/*: any*/),
              "concreteType": "SubtopicConnection",
              "kind": "LinkedField",
              "name": "subtopics",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SubtopicEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Subtopic",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "hasExercise",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "subtopics(first:1000)"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "topics(first:1000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskTemplateFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskTemplateFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Syllabus",
        "kind": "LinkedField",
        "name": "syllabus",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "30524d3c1b0a3e1f41d1784f45ba1dcc",
    "id": null,
    "metadata": {},
    "name": "TaskTemplateFlowQuery",
    "operationKind": "query",
    "text": "query TaskTemplateFlowQuery(\n  $syllabusId: ID!\n) {\n  syllabus(id: $syllabusId) {\n    topics(first: 1000) {\n      edges {\n        node {\n          id\n          subtopics(first: 1000) {\n            edges {\n              node {\n                id\n                title\n                hasExercise\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bc6ede5770005c273b2886977b2ac2c1';
export default node;
