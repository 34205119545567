import { graphql } from 'react-relay';
import { Redirect } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import {
  getCheckInStrandDiagnosticUrl,
  getCheckInUrl,
  getStudentSkillsSubstrandUrl,
} from 'ms-pages/Lantern/utils/urls';
import MajorSpinner from 'ms-pages/Teacher/components/MajorSpinner';
import { unwrap } from 'ms-utils/typescript-utils';

import type { DemoStudentWelcomeCheckinQuery } from './__generated__/DemoStudentWelcomeCheckinQuery.graphql';

export default function DemoStudentWelcome() {
  const { props: checkinProps } = useQuery<DemoStudentWelcomeCheckinQuery>(
    CHECK_IN_QUERY,
    {},
  );
  if (checkinProps == null) return <MajorSpinner />;
  const strand = checkinProps.viewer?.profile?.school?.defaultStrand;
  if (strand == null) throw new Error('No strand id found for student');
  // find(Boolean) is a hack to find the first item in a list.
  const checkIn =
    checkinProps.viewer?.profile?.lanternStudent?.checkIns?.find(Boolean);
  if (checkIn?.endedAt != null) {
    // we try the student's learning focus first, if there isn't one we try the checkin's strand
    // and if there isn't one we take the first substrand from the selected strand
    const substrand =
      checkinProps.viewer?.profile?.lanternStudent?.learningFocus ??
      checkIn?.substrand ??
      unwrap(strand.substrands[0]);
    return (
      <Redirect
        to={getStudentSkillsSubstrandUrl({
          strandId: substrand.strand.id,
          substrandId: substrand.id,
        })}
      />
    );
  }
  const checkInId = checkIn?.id;
  if (checkInId != null) return <Redirect to={getCheckInUrl({ checkInId })} />;
  return (
    <Redirect to={getCheckInStrandDiagnosticUrl({ strandId: strand.id })} />
  );
}
const CHECK_IN_QUERY = graphql`
  query DemoStudentWelcomeCheckinQuery {
    viewer {
      profile {
        ... on Student {
          lanternStudent {
            learningFocus {
              id
              strand {
                id
              }
            }
            checkIns {
              id
              substrand {
                id
                strand {
                  id
                }
              }
              type
              endedAt
            }
          }
          school {
            defaultStrand {
              id
              title
              substrands {
                id
                strand {
                  id
                }
              }
              curriculum {
                id
              }
            }
          }
        }
      }
    }
  }
`;
