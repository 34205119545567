import styled from '@emotion/styled';
import { Trans } from '@lingui/macro';
import { graphql, useFragment } from 'relay-hooks';

import { CheckMarkThick } from 'ms-components/icons';
import LockIcon from 'ms-components/icons/Lock2';
import PencilIcon from 'ms-components/icons/Pencil';
import WaypointsIcon from 'ms-components/icons/Waypoints';
import { ProficiencyIndicatorPresentational } from 'ms-pages/Lantern/components/ProficiencyIndicator';
import ChevronDownIcon from 'ms-pages/Lantern/components/icons/ChevronDown';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import {
  Bold,
  BodyM,
  HeadingXSGilroy,
} from 'ms-pages/Lantern/primitives/Typography';
import { getCheckInSkillUrl } from 'ms-pages/Lantern/utils/urls';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import SubstrandAdaptivePracticeModal from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapCurriculum/SubstrandAdaptivePracticeModal';
import { AccordionItem } from 'ms-pages/Teacher/components/Accordion';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import InlinePopover from 'ms-ui-primitives/Popover/InlinePopover';
import { VStack, HStack, VSpacer, HSpacer } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import {
  mapSanaProficiencyValueToMasteryLevel,
  MASTERY_LEVEL_TITLES,
} from 'ms-utils/masteryLevel';
import { randomIntFromString } from 'ms-utils/random';

import MasteredMilo from './StudentSkillsMapCurriculum/MasteredMilo';
import type {
  SkillBlockPopover_outcome$key,
  SkillBlockPopover_outcome,
} from './__generated__/SkillBlockPopover_outcome.graphql';

type SkillBlockSharedProps = {
  isStrandLocked: boolean;
  omitTrianglePointer?: boolean | undefined;
};

type SkillBlockInnerProps = SkillBlockSharedProps & {
  outcome: SkillBlockPopover_outcome;
  masteryLevelTitle: string | null;
};

type SkillBlockWrapperProps = SkillBlockSharedProps & {
  onDismiss: () => void;
  outcome: SkillBlockPopover_outcome$key;
  proficiency: number | null | undefined;
  dataId?: string | undefined;
};

const WIDTH = 280;
const BASE_PADDING = 8;
const VERTICAL_PADDING = 3 * BASE_PADDING;
const LATERAL_PADDING = 3 * BASE_PADDING;
const TRIANGLE_POINTER_WIDTH = 24;
const TRIANGLE_POINTER_HEIGHT = 18;
const TOOLTIP_BACKGROUND_COLOR = colors.neutralGray;
// We need TRIANGLE_POINTER_OFFSET to position the triangle pointer because
// we need to hide base of the triangle svg which has rounded borders at each corner
const TRIANGLE_POINTER_OFFSET = 8;
const PopoverContentRoot = styled(VStack)({
  width: WIDTH,
  cursor: 'default', // reset the parent's style
  textAlign: 'left',
  position: 'relative',
});
const PopoverTrianglePointerWrapper = styled.div({
  position: 'absolute',
  width: TRIANGLE_POINTER_WIDTH,
  height: TRIANGLE_POINTER_HEIGHT,
  top: -TRIANGLE_POINTER_HEIGHT + TRIANGLE_POINTER_OFFSET,
  right: '50%',
  transform: 'translateX(50%)',
});

const TopMatterWrapper = styled.div<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    backgroundColor,
    padding: `${VERTICAL_PADDING}px ${LATERAL_PADDING}px 0 ${LATERAL_PADDING}px`,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    position: 'relative',
  }),
);

const BottomMatterWrapper = styled.div({
  padding: `0 ${LATERAL_PADDING}px ${VERTICAL_PADDING}px ${LATERAL_PADDING}px`,
  backgroundColor: TOOLTIP_BACKGROUND_COLOR,
  borderBottomLeftRadius: 16,
  borderBottomRightRadius: 16,
});

function TriangleShape({ color }: { color: string }) {
  return (
    <PopoverTrianglePointerWrapper>
      <svg
        width={TRIANGLE_POINTER_WIDTH}
        height={TRIANGLE_POINTER_HEIGHT}
        viewBox={`0 0 ${TRIANGLE_POINTER_WIDTH} ${TRIANGLE_POINTER_HEIGHT}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.46447 1.59187C10.4171 -0.360752 13.5829 -0.360752 15.5355 1.59187L22.5208 8.57716C25.6707 11.727 23.4398 17.1127 18.9853 17.1127L5.01471 17.1127C0.56019 17.1127 -1.67065 11.727 1.47917 8.57717L8.46447 1.59187Z"
          fill={color}
        />
      </svg>
    </PopoverTrianglePointerWrapper>
  );
}
const ChevronIconWrapper = styled.div<{
  isRotated: boolean;
}>(({ isRotated }) => ({
  transform: `rotate(${isRotated ? 180 : 0}deg)`,
  display: 'inline-flex', // ensure the wrapper has the size of the child icon
  // center vertically
  alignSelf: 'center',
  position: 'relative',
  top: 0.5,
}));
const SKILL_BLOCK_POPOVER_OUTCOME_FRAGMENT = graphql`
  fragment SkillBlockPopover_outcome on LanternOutcome {
    id
    title
    code
    skill {
      id
      description
      canStartCheckIn
      strand {
        id
      }
    }
    subtopics {
      id
    }
  }
`;
function getDataId(outcomeId: string) {
  return `${outcomeId}-skill-block-popover`;
}

export default function SkillBlockPopover({
  onDismiss,
  outcome: outcomeRef,
  isStrandLocked,
  proficiency,
  dataId,
  omitTrianglePointer,
}: SkillBlockWrapperProps) {
  const outcome = useFragment<SkillBlockPopover_outcome$key>(
    SKILL_BLOCK_POPOVER_OUTCOME_FRAGMENT,
    outcomeRef,
  );
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(proficiency);
  const hasMasteredSkill = masteryLevel === 'MASTERED';
  const masteryLevelTitle =
    masteryLevel != null ? MASTERY_LEVEL_TITLES[masteryLevel] : null;
  return (
    <InlinePopover
      onDismiss={onDismiss}
      top={`calc(100% + ${omitTrianglePointer ? 8 : 12}px)`}
    >
      <PopoverContentRoot data-id={dataId || getDataId(outcome.id)}>
        {!isStrandLocked && hasMasteredSkill ? (
          <MasteredSkillBlockInner
            outcome={outcome}
            isStrandLocked={isStrandLocked}
            masteryLevelTitle={masteryLevelTitle}
            omitTrianglePointer={omitTrianglePointer}
          />
        ) : (
          <GeneralSkillBlockInner
            outcome={outcome}
            isStrandLocked={isStrandLocked}
            masteryLevelTitle={masteryLevelTitle}
            omitTrianglePointer={omitTrianglePointer}
          />
        )}
      </PopoverContentRoot>
    </InlinePopover>
  );
}

function GeneralSkillBlockInner({
  outcome,
  isStrandLocked,
  masteryLevelTitle,
  omitTrianglePointer,
}: SkillBlockInnerProps) {
  const canStartCheckIn = outcome.skill.canStartCheckIn;
  const skillId = outcome.skill.id;
  const strandId = outcome.skill.strand.id;
  const checkInButton = (
    <AnchorButton
      size="lanternSmall"
      color="eggplant"
      type="primary"
      label="Start check-in"
      isBlock
      isDisabled={isStrandLocked || !canStartCheckIn}
      href={getCheckInSkillUrl({ skillId, strandId })}
      trackingId="StudentSkillsMap/ClickCheckIn"
    >
      <>
        {isStrandLocked ? <LockIcon /> : <WaypointsIcon />}
        <HSpacer width={BASE_PADDING} />
        Start check-in
      </>
    </AnchorButton>
  );
  return (
    <>
      {!omitTrianglePointer && (
        <TriangleShape color={TOOLTIP_BACKGROUND_COLOR} />
      )}
      <TopMatterWrapper backgroundColor={TOOLTIP_BACKGROUND_COLOR}>
        <HeadingXSGilroy color="white">{outcome.title}</HeadingXSGilroy>

        <VSpacer height={BASE_PADDING} />
        <BodyM bold color="white">
          {masteryLevelTitle}
        </BodyM>
      </TopMatterWrapper>
      <BottomMatterWrapper>
        <VSpacer height={2 * BASE_PADDING} />
        <MoreDetail outcome={outcome} />
        <VSpacer height={2 * BASE_PADDING} />
        {outcome.subtopics.length > 0 && (
          <PracticeSkillButton outcomeId={outcome.id} />
        )}
        <VSpacer height={2 * BASE_PADDING} />
        {!canStartCheckIn ? (
          <Tooltip content={START_CHECK_IN_DISABLED_TOOLTIP_CONTENT}>
            <div>{checkInButton}</div>
          </Tooltip>
        ) : (
          checkInButton
        )}
      </BottomMatterWrapper>
    </>
  );
}

function MasteredSkillBlockInner({
  outcome,
  omitTrianglePointer,
}: SkillBlockInnerProps) {
  const rand = randomIntFromString(outcome.title);
  // Pick one of the three versions of Milo
  const miloIndex = rand(5);
  return (
    <>
      {!omitTrianglePointer && <TriangleShape color={colors.eggplant90} />}
      <TopMatterWrapper backgroundColor={colors.eggplant90}>
        <HStack justify="space-between">
          <HeadingXSGilroy color="white">{outcome.title}</HeadingXSGilroy>
          <HSpacer width={BASE_PADDING} />
          <ProficiencyIndicatorPresentational
            height={36}
            width={36}
            circle
            masteryLevel="MASTERED"
          />
        </HStack>

        <VSpacer height={BASE_PADDING} />
        <HStack center>
          <CheckMarkThick color="white" />
          <HSpacer width={BASE_PADDING} />
          <BodyM bold color="white">
            {MASTERY_LEVEL_TITLES['MASTERED']}
          </BodyM>
        </HStack>
        <VSpacer height={3 * BASE_PADDING} />
        <MasteredMilo index={miloIndex} />
      </TopMatterWrapper>
      <BottomMatterWrapper>
        <VSpacer height={2 * BASE_PADDING} />
        <MoreDetail outcome={outcome} />
        <VSpacer height={2 * BASE_PADDING} />
        {outcome.subtopics.length > 0 && (
          <PracticeSkillButton outcomeId={outcome.id} />
        )}
      </BottomMatterWrapper>
    </>
  );
}

function MoreDetail({ outcome }: { outcome: SkillBlockPopover_outcome }) {
  const moreDetailHidden = useBoolean(true);
  return (
    <>
      <Button
        isInline
        color="grey10"
        size="lanternSmall"
        onClick={moreDetailHidden.toggle}
        label="Show more detail"
      >
        <HStack>
          Show more detail
          <HSpacer width={BASE_PADDING} />
          <ChevronIconWrapper isRotated={!moreDetailHidden.value}>
            <ChevronDownIcon size={12} color={colors.grey10} />
          </ChevronIconWrapper>
        </HStack>
      </Button>

      <AccordionItem isOpen={!moreDetailHidden.value}>
        <VSpacer height={BASE_PADDING} />
        <BodyM color="white">{outcome.skill.description}</BodyM>
        <VSpacer height={BASE_PADDING} />
        <BodyM color="white">
          <Bold>
            <Trans>Outcome code</Trans>:{' '}
          </Bold>
          {outcome.code}
        </BodyM>
      </AccordionItem>
    </>
  );
}

function PracticeSkillButton({ outcomeId }: { outcomeId: string }) {
  const practiceModal = useBoolean();

  return (
    <>
      <Button
        size="lanternSmall"
        color="white"
        type="secondary"
        label="Practice skill"
        isBlock
        onClick={practiceModal.setTrue}
        trackingId="StudentSkillsMap/ClickPractice"
      >
        <>
          <PencilIcon />
          <HSpacer width={BASE_PADDING} />
          Practice skill
        </>
      </Button>
      {practiceModal.value && (
        <SubstrandAdaptivePracticeModal
          outcome={outcomeId}
          onDismiss={practiceModal.setFalse}
        />
      )}
    </>
  );
}
