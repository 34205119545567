import type { Adapter } from 'ms-pages/Teacher/TeacherContext/adapters/types';
import { InvariantViolation, Logger } from 'ms-utils/app-logging';
import localStorageDb from 'ms-utils/localStorageDb';
import extractNode from 'ms-utils/relay/extractNode';

const STORAGE_KEY = 'selected_class';
const STORAGE_VERSION = 0;

const getStorageKey = (version: number) => `v${version}:${STORAGE_KEY}`;

if (STORAGE_VERSION > 0) {
  for (let version = STORAGE_VERSION; version >= 0; version -= 1) {
    localStorageDb.delete(getStorageKey(version));
  }
}

// boot-up cleaning. This is in the case that there are older versions of the local storage keys
// lying around. We want to clean those up

/*
 * Session storage backend for the teacher context;
 */
export const localStorageAdapter: Adapter = () => ({
  getter: ({ schoolList, classId, schoolId }) => {
    const localIds = localStorageDb.get(getStorageKey(STORAGE_VERSION));
    if (localIds == null) return { classId, schoolId };
    const { localClassId, localSchoolId } = localIds;
    if (localClassId == null || localSchoolId == null)
      return { classId, schoolId };
    const schoolClassNodes = schoolList.find(s => s.id === localSchoolId)
      ?.classes;
    const schoolClasses = schoolClassNodes && extractNode(schoolClassNodes);
    const klass =
      schoolClasses && schoolClasses.find(c => c.id === localClassId);
    if (klass == null) {
      Logger.error(
        new InvariantViolation(
          `Stored session class: ${localClassId}, school: ${localSchoolId}  does not correspond to any classes for user`,
        ),
      );
      localStorageDb.delete(getStorageKey(STORAGE_VERSION));
      return { classId, schoolId };
    }
    return { classId: localClassId, schoolId: localSchoolId };
  },
  setter: ({ schoolList, schoolId, classId }) => {
    const schoolClassNodes = schoolList.find(s => s.id === schoolId)?.classes;
    const schoolClasses = schoolClassNodes && extractNode(schoolClassNodes);
    if (!(schoolClasses && schoolClasses.some(c => c.id === classId))) return;
    localStorageDb.set(getStorageKey(STORAGE_VERSION), {
      localClassId: classId,
      localSchoolId: schoolId,
    });
  },
});
