import { fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

export const Pill = styled({
  fontSize: fontSize.medium,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  // minHeight: 7 * BASE_UNIT,
  marginBottom: 2 * BASE_UNIT,
  marginRight: BASE_UNIT,
});

export const OuterPill = styled({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const InnerPill = styled({
  display: 'flex',
  alignItems: 'flex-start',
});

export const ClassAssigneeIconWrapper = styled({
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: 2 * BASE_UNIT,
  fontSize: 20,
});

export const ProfileIconWrapper = styled({
  marginRight: BASE_UNIT,
});

/* Example Usage
<ClassPill key={klass.id}>
  <InnerPill>
    <ClassAssigneIconWrapper><ClassAssigneIcon /></ClassAssigneIconWrapper>
    <PillText>{klass.title}</PillText>
  </InnerPill>
</ClassPill>
*/
export const ClassPill = styled(
  {
    backgroundColor: colors.tropicalBlue,
    color: colors.grey,
    borderRadius: 2 * BASE_UNIT,
    marginBottom: 2 * BASE_UNIT,
    padding: `${2 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
  },
  Pill,
);

/* Example Usage
<StudentPill key={student.id}>
  <InnerPill>
    <Avatar src={student.avatar} />
    <PillText>
      {student.lastName}, {student.firstName}
    </PillText>
  </InnerPill>
</StudentPill>
*/
export const StudentPill = styled(
  {
    backgroundColor: colors.eggplant20,
    color: colors.grey,
    borderRadius: 4 * BASE_UNIT,
    marginBottom: BASE_UNIT,
    padding: `${1.5 * BASE_UNIT}px ${3 * BASE_UNIT}px`,
  },
  Pill,
);

export const PillText = styled(
  {
    default: { marginRight: 2 * BASE_UNIT, fontSize: fontSize.medium },
    semibold: { fontWeight: fontWeight.semibold },
  },
  'span',
);

export const PillDescription = styled(
  {
    fontSize: fontSize.small,
    color: colors.grey90,
  },
  'span',
);

export const Avatar = styled(
  {
    // 11 is a special snowflake to align the pills together
    // this is intetional.
    height: 11,
    width: 11,
    borderRadius: 4 * BASE_UNIT,
    marginRight: 2 * BASE_UNIT,
    flexShrink: 0,
  },
  'img',
);
