/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BoxPlotSubstatus = "CORRECT" | "INCORRECT" | "UNKNOWN";
export type utilsBoxPlotSubstatus = {
    readonly substatus: BoxPlotSubstatus;
    readonly " $refType": "utilsBoxPlotSubstatus";
};
export type utilsBoxPlotSubstatus$data = utilsBoxPlotSubstatus;
export type utilsBoxPlotSubstatus$key = {
    readonly " $data"?: utilsBoxPlotSubstatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"utilsBoxPlotSubstatus">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "utilsBoxPlotSubstatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "substatus",
      "storageKey": null
    }
  ],
  "type": "BoxPlotValue",
  "abstractKey": null
};
(node as any).hash = '8a83c535783b891062fda6101e6b49ef';
export default node;
