import { useContext, useMemo, useEffect } from 'react';
import { graphql, useFragment, useMutation } from 'relay-hooks';

import SnackbarComponent from 'ms-components/Snackbar/SnackbarComponent';
import { taskRefetchBus } from 'ms-helpers/Tasks/signaling';
import {
  Asterisk,
  FieldWrapper,
  Label,
  TaskCreationFormWrapper,
} from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import TaskAssignment from 'ms-pages/Teacher/components/CreateTask/components/TaskAssignment';
import TaskDatePicker from 'ms-pages/Teacher/components/CreateTask/components/TaskDatePicker';
import {
  ErrorMessage,
  UnauthorisedTaskAssigneesError,
  EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT,
} from 'ms-pages/Teacher/components/EditTaskFlyout';
import { useEditLessonTaskState } from 'ms-pages/Teacher/components/EditTaskFlyout/EditLessonTaskFlyout/state';
import { FlyoutContext } from 'ms-pages/Teacher/components/Flyout';
import { FlyoutBody } from 'ms-pages/Teacher/components/Flyout/components/FlyoutBody';
import { Footer } from 'ms-pages/Teacher/components/Flyout/components/Footer';
import { BackHeader } from 'ms-pages/Teacher/components/Flyout/components/Header';
import { Root } from 'ms-pages/Teacher/components/Flyout/components/Root';
import Button from 'ms-ui-primitives/Button';

import type {
  EditLessonTaskFlyoutMutation,
  ErrorEnum,
} from './__generated__/EditLessonTaskFlyoutMutation.graphql';
import type { EditLessonTaskFlyout_lessonTask$key } from './__generated__/EditLessonTaskFlyout_lessonTask.graphql';
import { createLessonTaskVariables } from './state/useEditLessonTaskState';
import TaskNameInput from '../../CreateTask/components/TaskNameInput';
import type { EditTaskFlyout_assignments$key } from '../__generated__/EditTaskFlyout_assignments.graphql';
import useConfirmRemovedStudents, {
  ConfirmRemovedStudentsModal,
} from '../useConfirmRemovedStudents';

const DUE_DATE_IN_THE_PAST_ERROR_KEY: ErrorEnum = 'DUE_DATE_IN_THE_PAST';
type Props = {
  lessonTask: EditLessonTaskFlyout_lessonTask$key;
};
const mutation = graphql`
  mutation EditLessonTaskFlyoutMutation(
    $taskID: ID!
    $title: String!
    $dueDate: DateTime!
    $startDate: DateTime!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $first: Int!
  ) {
    editLessonTask(
      title: $title
      dueDate: $dueDate
      startDate: $startDate
      taskId: $taskID
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
    ) {
      errors {
        key
        message
      }
      task {
        id
        startDate
        dueDate
        title
        ...EditTaskFlyout_assignments @arguments(first: $first)
      }
    }
  }
`;

const EDIT_LESSON_TASK_FLYOUTE_LESSON_TASK_FRAGMENT = graphql`
  fragment EditLessonTaskFlyout_lessonTask on LessonTask
  @argumentDefinitions(first: { type: "Int!" }) {
    id
    title
    startDate
    dueDate
    ...EditTaskFlyout_assignments @arguments(first: $first)
  }
`;

export default function EditLessonTaskFlyout({
  lessonTask: lessonTaskKey,
}: Props) {
  const { closeFlyout } = useContext(FlyoutContext);
  const lessonTask = useFragment(
    EDIT_LESSON_TASK_FLYOUTE_LESSON_TASK_FRAGMENT,
    lessonTaskKey,
  );

  const lessonTaskAssignments = useFragment<EditTaskFlyout_assignments$key>(
    EDIT_TASK_FLYOUT_ASSIGNMENTS_FRAGMENT,
    lessonTask,
  );
  const [state, dispatch] = useEditLessonTaskState(
    lessonTask,
    lessonTaskAssignments,
  );
  const [editLessonTask, { data, error, loading }] =
    useMutation<EditLessonTaskFlyoutMutation>(mutation);
  const errorKeys =
    data != null ? data.editLessonTask.errors.map(e => e.key) : [];
  // outcomes
  const thereIsUnexptedError = useMemo(() => error != null, [error]);
  const wasUnableToEditTask = useMemo(
    () => errorKeys.length > 0,
    [errorKeys.length],
  );
  const taskWasSuccessfullyEdited = useMemo(
    () => data != null && data.editLessonTask.task != null,
    [data],
  );
  useEffect(() => {
    if (taskWasSuccessfullyEdited) {
      taskRefetchBus.signal();
      closeFlyout();
    }
  }, [closeFlyout, taskWasSuccessfullyEdited]);
  const {
    studentsThatWereRemoved,
    resetTaskAssignment,
    isConfirmOpen,
    openConfirm,
    closeConfirm,
  } = useConfirmRemovedStudents({
    originalAssignedStudents: lessonTaskAssignments.assignedStudents.map(s => ({
      id: s.id,
      firstName: s.user.firstName,
      lastName: s.user.lastName,
    })),
    originalAssignedClasses: lessonTaskAssignments.assignedClasses,
    originalIndividuallyAssignedStudents:
      lessonTaskAssignments.individuallyAssignedStudents.map(s => ({
        id: s.id,
        firstName: s.user.firstName,
        lastName: s.user.lastName,
      })),
    originalPartialClasses: lessonTaskAssignments.partiallyAssignedClasses,
    currentAssignedClasses: state.assignedClasses,
    currentIndividualAssignedStudents: state.assignedStudents,
    currentExcludedStudents: state.excludedStudents,
    setIndividualAssignedStudents: assignedStudents => {
      dispatch({ type: 'UPDATE_ASSIGNEDSTUDENTS', assignedStudents });
    },
    setExcludedStudents: excludedStudents => {
      dispatch({ type: 'UPDATE_EXCLUDEDSTUDENTS', excludedStudents });
    },
    setAssignedClasses: assignedClasses => {
      dispatch({ type: 'UPDATE_ASSIGNEDCLASSES', assignedClasses });
    },
  });
  return (
    <Root>
      <SnackbarComponent
        isOpen={thereIsUnexptedError}
        message="An unexpected error occured"
      />

      <SnackbarComponent
        isOpen={taskWasSuccessfullyEdited}
        message="Task edited successfully"
      />

      <SnackbarComponent
        isOpen={wasUnableToEditTask}
        message={
          errorKeys.includes(DUE_DATE_IN_THE_PAST_ERROR_KEY)
            ? "Due date can't be in the past"
            : 'Unable to edit task'
        }
      />

      <BackHeader title="Edit task" onClick={closeFlyout} />
      <FlyoutBody>
        <TaskCreationFormWrapper>
          <UnauthorisedTaskAssigneesError errorKeys={errorKeys} />
          <FieldWrapper>
            <Label>Task name</Label>
            <TaskNameInput
              value={state.title}
              onChange={newValue => {
                dispatch({ type: 'UPDATE_TITLE', title: newValue });
              }}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>
              Start date / Due date<Asterisk> *</Asterisk>
            </Label>
            {/* It is possible to edit a task after the due date has passed.
        We don't want to suprise the use by automatically changing the due date
        but we need to communicate the invalid state.
     */}
            {state.dueDate <= new Date() && (
              <ErrorMessage>Due date cannot be in the past.</ErrorMessage>
            )}
            <TaskDatePicker
              value={[state.startDate, state.dueDate]}
              onChange={([startDate, endDate]) => {
                if (startDate != null)
                  dispatch({ type: 'UPDATE_STARTDATE', startDate });
                if (endDate != null)
                  dispatch({ type: 'UPDATE_DUEDATE', dueDate: endDate });
              }}
              errorKeys={errorKeys}
            />
          </FieldWrapper>
          <FieldWrapper>
            <TaskAssignment
              selectedClasses={state.assignedClasses}
              selectedStudents={state.assignedStudents}
              excludedStudents={state.excludedStudents}
              onChangeClasses={assignedClasses =>
                dispatch({ type: 'UPDATE_ASSIGNEDCLASSES', assignedClasses })
              }
              onChangeStudents={assignedStudents =>
                dispatch({ type: 'UPDATE_ASSIGNEDSTUDENTS', assignedStudents })
              }
              onChangeExcludedStudents={excludedStudents =>
                dispatch({ type: 'UPDATE_EXCLUDEDSTUDENTS', excludedStudents })
              }
            />
          </FieldWrapper>
        </TaskCreationFormWrapper>
      </FlyoutBody>
      <Footer>
        <Button
          isBlock
          type="primary"
          isDisabled={
            loading ||
            (state.assignedClasses.length === 0 &&
              state.assignedStudents.length === 0) ||
            state.dueDate <= new Date()
          }
          onClick={
            studentsThatWereRemoved.length > 0
              ? openConfirm
              : () =>
                  editLessonTask({
                    variables: createLessonTaskVariables(state),
                  })
          }
        >
          Save
        </Button>
      </Footer>

      <ConfirmRemovedStudentsModal
        studentsThatWereRemoved={studentsThatWereRemoved}
        isConfirmOpen={isConfirmOpen}
        closeConfirm={closeConfirm}
        resetTaskAssignment={resetTaskAssignment}
        editTask={() => {
          editLessonTask({ variables: createLessonTaskVariables(state) });
        }}
      />
    </Root>
  );
}
