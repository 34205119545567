import styled from '@emotion/styled';

import { colors } from 'ms-styles/colors';
import DonutProgressBar from 'ms-ui-primitives/DonutProgressBar';
import { InvariantViolation } from 'ms-utils/app-logging';
import { hexStringToRgbaString } from 'ms-utils/colors';

const THICKNESS_RATIO = 10.18;
const PADDING_RATIO = 14;
const PROGRESS_COLOR = colors.eggplant;
const GROWTH_COLOR = colors.mountainMeadow;
const DONUT_COLOR = colors.eggplant20;
const INNER_COLOR = hexStringToRgbaString(colors.ironLight, 0.8);
const LOCKED_DONUT_COLOR = INNER_COLOR;
const LOCKED_PROGRESS_COLOR = colors.eggplant40;
const LOCKED_GROWTH_COLOR = hexStringToRgbaString(GROWTH_COLOR, 0.4);

type Props = {
  currentProficiency: number;
  pastProficiency?: number | undefined;
  size: number;
  locked: boolean;
  badgeUrl: string;
  animated?: boolean | undefined;
};

export default function SubstrandProficiencyIndicator({
  currentProficiency,
  pastProficiency,
  locked,
  size,
  badgeUrl,
  animated = false,
}: Props) {
  if (currentProficiency < 0 || currentProficiency > 1) {
    throw new InvariantViolation(
      `Passed an invalid proficiency to SubstrandProficiencyIndicator: ${currentProficiency}`,
    );
  }
  if (
    pastProficiency !== undefined &&
    (pastProficiency < 0 || pastProficiency > 1)
  ) {
    throw new InvariantViolation(
      `Passed an invalid proficiency to SubstrandProficiencyIndicator: ${pastProficiency}`,
    );
  }

  const thickness = size / THICKNESS_RATIO;
  const padding = size / PADDING_RATIO;
  const innerSize = size - thickness * 2 - padding * 2;
  const badgeSize = innerSize + 3; // TEMPORARY: "zoom in" a bit to avoid bad clipplings due to the unwanted padding on the badges svg. Remove once designers have fixed the padding issue.
  const innerOffset = thickness + padding;

  const Inner = styled.div({
    borderRadius: innerSize / 2,
    overflow: 'hidden',
    height: innerSize,
    width: innerSize,
    background: INNER_COLOR,
    position: 'relative',
    top: innerOffset,
    left: innerOffset,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const BadgeImg = styled.img({
    borderRadius: badgeSize / 2,
    width: badgeSize,
    height: badgeSize,
    position: 'absolute',
  });

  const progress =
    pastProficiency !== undefined &&
    currentProficiency > pastProficiency &&
    pastProficiency > 0
      ? pastProficiency
      : currentProficiency;
  const secondaryProgress = currentProficiency;

  return (
    <DonutProgressBar
      size={size}
      thickness={thickness}
      backgroundColor={locked ? LOCKED_DONUT_COLOR : DONUT_COLOR}
      progress={{
        value: progress * 100,
        color: locked ? LOCKED_PROGRESS_COLOR : PROGRESS_COLOR,
      }}
      secondaryProgress={
        !!pastProficiency
          ? {
              value: secondaryProgress * 100,
              color: locked ? LOCKED_GROWTH_COLOR : GROWTH_COLOR,
            }
          : undefined
      }
      animated={animated}
      withTransparentPadding={false}
    >
      <Inner>
        <BadgeImg src={badgeUrl} alt="" />
      </Inner>
    </DonutProgressBar>
  );
}
