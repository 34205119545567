import { StyleSheet } from 'aphrodite';

import { fontFamily, lineHeight } from 'ms-styles/base';
import { colors as baseColors } from 'ms-styles/colors';

const ERROR_VIEW_PADDING = 10;
const ERROR_MESSAGE_MARGIN_BOTTOM = 30;

export default StyleSheet.create({
  pageErrorMessageView: {
    width: '100vw',
    height: '100vh',
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: ERROR_VIEW_PADDING,
  },
  errorMessage: {
    textAlign: 'center',
    color: baseColors.cinnabar,
    marginBottom: ERROR_MESSAGE_MARGIN_BOTTOM,
  },
});
