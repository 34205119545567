export default function MiloExporerWithSkills() {
  return (
    <svg
      width="232"
      height="175"
      viewBox="0 0 232 175"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M129.138 24.6358L125.316 23.7186L122.42 32.8345C122.262 33.3319 122.68 33.8097 123.218 33.7476L126.028 33.4238C126.215 33.4024 126.401 33.447 126.551 33.5493L128.868 35.1242C129.29 35.411 129.893 35.2054 130.047 34.7228L132.959 25.553L129.138 24.6358Z"
        fill="#8C83C7"
      />
      <path
        d="M132.959 25.5534L130.047 34.7232C129.893 35.2059 129.29 35.4114 128.868 35.1248L128.319 34.7512C128.472 34.6597 128.596 34.5168 128.654 34.3333L131.551 25.2153L132.959 25.5534Z"
        fill="#5D51AE"
      />
      <path
        d="M137.544 22.384L140.395 19.6789L147.461 26.1255C147.846 26.4772 147.724 27.0997 147.226 27.3151L144.631 28.4398C144.459 28.5144 144.32 28.6462 144.24 28.81L143.022 31.332C142.8 31.7913 142.174 31.9151 141.8 31.5738L134.693 25.089L137.544 22.384Z"
        fill="#8C83C7"
      />
      <path
        d="M147.226 27.3165L146.565 27.6029C146.545 27.4494 146.473 27.3005 146.344 27.1828L139.28 20.7372L140.395 19.6798L147.461 26.1268C147.846 26.4787 147.724 27.1008 147.226 27.3165Z"
        fill="#5D51AE"
      />
      <path
        d="M144.102 13.1577C144.462 14.5003 143.38 15.9703 143.324 17.2836C143.267 18.6447 144.204 20.243 143.749 21.485C143.289 22.7395 141.569 23.2674 140.754 24.309C139.948 25.3402 139.812 27.1933 138.708 27.9261C137.643 28.6332 135.94 28.0182 134.643 28.3658C133.345 28.7135 132.178 30.0974 130.902 30.0178C129.579 29.9352 128.535 28.3984 127.321 27.9084C126.095 27.4135 124.341 27.8167 123.316 26.9601C122.301 26.1119 122.313 24.2592 121.583 23.1092C120.878 21.9996 119.206 21.2674 118.847 19.9247C118.487 18.5821 119.568 17.1121 119.624 15.7988C119.682 14.4377 118.744 12.8394 119.2 11.5974C119.659 10.3429 121.38 9.81501 122.194 8.77344C123 7.7422 123.136 5.88909 124.24 5.1563C125.306 4.44922 127.008 5.06425 128.306 4.7166C129.603 4.36896 130.77 2.98504 132.047 3.06466C133.369 3.14721 134.413 4.68405 135.627 5.17404C136.853 5.66894 138.607 5.26569 139.633 6.12235C140.648 6.97055 140.635 8.82318 141.366 9.9732C142.07 11.0828 143.742 11.815 144.102 13.1577Z"
        fill="#4F41AB"
      />
      <path
        d="M134.08 25.2227C138.979 23.9101 141.895 18.9081 140.593 14.0503C139.292 9.19257 134.265 6.31863 129.367 7.6312C124.468 8.94377 121.552 13.9458 122.854 18.8035C124.156 23.6613 129.182 26.5352 134.08 25.2227Z"
        fill="white"
      />
      <path
        d="M140.593 14.0499C141.895 18.908 138.979 23.9095 134.08 25.222C132.573 25.626 131.053 25.6335 129.634 25.3089C130.604 25.3677 131.601 25.2736 132.593 25.0078C137.491 23.6953 140.407 18.6937 139.105 13.8356C138.204 10.4726 135.518 8.06047 132.324 7.33005C136.147 7.56286 139.555 10.1753 140.593 14.0499Z"
        fill="#F5F5F5"
      />
      <path
        d="M131.734 10.1681C131.069 9.34127 129.685 9.64491 129.417 10.6765C129.147 11.7147 128.831 12.764 128.509 13.7951C127.482 14.2362 126.449 14.7048 125.411 15.183C124.349 15.6718 124.297 17.1043 125.324 17.611C126.131 18.0094 126.98 18.4579 127.833 18.9359C127.968 20.1564 128.209 21.3228 128.599 22.4186L129.632 22.6673L135.561 19.3582L131.734 10.1681Z"
        fill="#F4C55F"
      />
      <path
        d="M131.734 10.1678C132.403 11 133.119 11.8229 133.832 12.627C134.947 12.5981 136.078 12.5919 137.219 12.5918C138.385 12.5918 139.016 13.8743 138.288 14.7662C137.715 15.4675 137.125 16.2316 136.543 17.0244C136.918 18.1924 137.174 19.3555 137.265 20.517C137.338 21.4509 136.42 22.1911 135.477 21.9639C134.708 21.7784 133.831 21.5187 132.879 21.2212C132.135 21.8908 131.441 22.4952 130.817 22.9866C130.051 23.5884 128.913 23.2995 128.599 22.4184C132.779 20.4524 134.485 16.5278 131.734 10.1678Z"
        fill="#EEBB4C"
      />
      <path
        d="M147.619 30.498L144.547 28.0465L138.058 35.0736C137.704 35.457 137.879 36.0667 138.393 36.2388L141.076 37.1375C141.253 37.1971 141.403 37.3165 141.496 37.473L142.926 39.8816C143.186 40.3203 143.82 40.3902 144.163 40.0181L150.691 32.9495L147.619 30.498Z"
        fill="#8C83C7"
      />
      <path
        d="M150.69 32.95L144.163 40.0187C143.819 40.3906 143.186 40.3208 142.925 39.8823L142.587 39.3112C142.765 39.2935 142.937 39.2163 143.067 39.0746L149.558 32.0464L150.69 32.95Z"
        fill="#5D51AE"
      />
      <path
        d="M156.187 32.0238L159.916 30.7833L163.58 39.6182C163.78 40.1003 163.405 40.6119 162.863 40.5961L160.036 40.5137C159.848 40.5083 159.667 40.5687 159.525 40.6835L157.352 42.4506C156.957 42.7724 156.338 42.6192 156.143 42.1514L152.457 33.2642L156.187 32.0238Z"
        fill="#8C83C7"
      />
      <path
        d="M162.864 40.597L162.143 40.5759C162.19 40.4283 162.188 40.263 162.121 40.1015L158.458 31.2684L159.916 30.7836L163.58 39.619C163.78 40.1012 163.405 40.6126 162.864 40.597Z"
        fill="#5D51AE"
      />
      <path
        d="M166.038 26.4505C165.794 27.819 164.191 28.6914 163.584 29.8572C162.955 31.0654 163.125 32.9103 162.186 33.8421C161.238 34.7832 159.456 34.5314 158.277 35.1293C157.109 35.7212 156.2 37.3417 154.889 37.537C153.625 37.7255 152.344 36.4464 151.021 36.2109C149.699 35.9754 148.055 36.7337 146.933 36.1203C145.77 35.4846 145.476 33.6499 144.585 32.6913C143.685 31.7232 141.925 31.3443 141.36 30.1337C140.801 28.935 141.598 27.2626 141.424 25.9113C141.256 24.6075 140.053 23.2354 140.297 21.8669C140.54 20.4984 142.143 19.626 142.751 18.4603C143.38 17.252 143.209 15.4072 144.148 14.4754C145.097 13.5342 146.879 13.786 148.058 13.1882C149.225 12.5962 150.134 10.9757 151.445 10.7804C152.71 10.5919 153.991 11.8711 155.313 12.1066C156.636 12.342 158.28 11.5838 159.402 12.1971C160.564 12.8329 160.858 14.6676 161.75 15.6261C162.65 16.5943 164.409 16.9731 164.975 18.1838C165.534 19.3824 164.737 21.0549 164.911 22.4061C165.078 23.7099 166.282 25.0821 166.038 26.4505Z"
        fill="#4F41AB"
      />
      <path
        d="M151.845 33.1257C156.838 34.0147 161.6 30.7217 162.482 25.7704C163.364 20.8192 160.031 16.0847 155.038 15.1957C150.045 14.3066 145.283 17.5997 144.401 22.5509C143.52 27.5022 146.852 32.2366 151.845 33.1257Z"
        fill="white"
      />
      <path
        d="M162.482 25.7702C161.6 30.7218 156.838 34.0143 151.845 33.1253C150.309 32.8517 148.929 32.2139 147.782 31.318C148.636 31.7828 149.578 32.1203 150.589 32.3003C155.581 33.1893 160.344 29.8967 161.225 24.9451C161.836 21.5173 160.426 18.1935 157.844 16.1776C161.207 18.0099 163.185 21.8211 162.482 25.7702Z"
        fill="#F5F5F5"
      />
      <path
        d="M156.106 18.4962C155.855 17.4654 154.473 17.1535 153.792 17.9739C153.107 18.7995 152.376 19.6156 151.647 20.4131C150.53 20.377 149.396 20.3633 148.253 20.3558C147.084 20.3481 146.429 21.6233 147.144 22.5177C147.706 23.2209 148.284 23.987 148.854 24.7816C148.459 25.9442 148.183 27.1027 148.071 28.2607L148.901 28.9239L155.674 28.4419L156.106 18.4962Z"
        fill="#F4C55F"
      />
      <path
        d="M156.106 18.4966C156.359 19.5341 156.658 20.5828 156.963 21.6134C157.985 22.0602 159.012 22.5344 160.045 23.0182C161.101 23.5127 161.129 24.942 160.091 25.4407C159.275 25.8328 158.417 26.2746 157.553 26.7458C157.397 27.9623 157.135 29.1242 156.725 30.2147C156.396 31.0915 155.25 31.3723 154.493 30.7669C153.875 30.2727 153.191 29.6652 152.455 28.9924C151.497 29.2829 150.613 29.5362 149.839 29.7162C148.89 29.9367 147.982 29.1923 148.072 28.2612C152.691 28.2536 155.901 25.4233 156.106 18.4966Z"
        fill="#EEBB4C"
      />
      <path
        d="M151.263 46.2002L148.138 43.8162L141.804 50.9829C141.458 51.3739 141.647 51.9796 142.164 52.1406L144.866 52.9806C145.045 53.0363 145.197 53.1525 145.293 53.3069L146.775 55.6838C147.045 56.1167 147.68 56.1727 148.015 55.7933L154.387 48.5842L151.263 46.2002Z"
        fill="#8C83C7"
      />
      <path
        d="M154.387 48.5843L148.016 55.7935C147.68 56.1729 147.045 56.1168 146.775 55.6841L146.424 55.1205C146.601 55.0989 146.772 55.018 146.899 54.8735L153.235 47.7056L154.387 48.5843Z"
        fill="#5D51AE"
      />
      <path
        d="M159.862 47.5383L163.563 46.2169L167.419 54.9699C167.629 55.4475 167.266 55.9673 166.724 55.9633L163.895 55.9425C163.707 55.9411 163.527 56.0054 163.388 56.1233L161.254 57.9373C160.866 58.2677 160.243 58.1279 160.039 57.6645L156.16 48.8596L159.862 47.5383Z"
        fill="#8C83C7"
      />
      <path
        d="M166.724 55.9644L166.004 55.959C166.047 55.8104 166.042 55.6452 165.971 55.4852L162.116 46.7339L163.563 46.2175L167.419 54.9709C167.63 55.4487 167.266 55.9682 166.724 55.9644Z"
        fill="#5D51AE"
      />
      <path
        d="M169.589 41.7537C169.376 43.1272 167.792 44.0343 167.21 45.213C166.607 46.4347 166.818 48.2754 165.9 49.2274C164.972 50.189 163.185 49.976 162.019 50.5994C160.865 51.2166 159.991 52.8566 158.685 53.0804C157.425 53.2963 156.116 52.0454 154.789 51.8388C153.462 51.6321 151.835 52.426 150.7 51.8372C149.523 51.227 149.189 49.3991 148.277 48.4602C147.356 47.5119 145.589 47.1714 144.998 45.9734C144.412 44.7872 145.172 43.0978 144.969 41.7506C144.773 40.4508 143.541 39.1052 143.754 37.7317C143.968 36.3583 145.552 35.4511 146.134 34.2725C146.737 33.0508 146.526 31.2101 147.444 30.2581C148.372 29.2965 150.159 29.5094 151.325 28.886C152.479 28.2688 153.353 26.6289 154.659 26.4051C155.919 26.1891 157.228 27.4401 158.555 27.6467C159.882 27.8533 161.509 27.0594 162.644 27.6482C163.82 28.2585 164.154 30.0864 165.066 31.0253C165.987 31.9736 167.755 32.314 168.346 33.5121C168.932 34.6982 168.171 36.3877 168.374 37.7348C168.57 39.0347 169.803 40.3803 169.589 41.7537Z"
        fill="#4F41AB"
      />
      <path
        d="M155.546 48.7378C160.557 49.5179 165.246 46.122 166.02 41.1527C166.793 36.1835 163.358 31.5227 158.347 30.7426C153.336 29.9625 148.647 33.3585 147.873 38.3277C147.1 43.297 150.535 47.9577 155.546 48.7378Z"
        fill="white"
      />
      <path
        d="M166.019 41.1517C165.246 46.1214 160.556 49.5168 155.546 48.7368C154.003 48.4967 152.61 47.8891 151.444 47.0184C152.307 47.4644 153.257 47.7813 154.271 47.9393C159.282 48.7193 163.972 45.3239 164.745 40.3542C165.281 36.914 163.799 33.6216 161.174 31.6624C164.576 33.421 166.636 37.1883 166.019 41.1517Z"
        fill="#F5F5F5"
      />
      <path
        d="M159.487 34.018C159.213 32.993 157.825 32.7112 157.162 33.5462C156.495 34.3866 155.782 35.2184 155.071 36.0316C153.953 36.0198 152.819 36.0308 151.676 36.0482C150.507 36.0659 149.88 37.3551 150.615 38.2337C151.192 38.9245 151.787 39.6778 152.374 40.4599C152.004 41.6308 151.753 42.7951 151.667 43.9552L152.511 44.6002L159.271 43.9708L159.487 34.018Z"
        fill="#F4C55F"
      />
      <path
        d="M159.486 34.0187C159.762 35.0503 160.084 36.0923 160.411 37.1161C161.442 37.5404 162.479 37.9922 163.523 38.4534C164.589 38.9248 164.648 40.3531 163.622 40.8743C162.814 41.2841 161.966 41.7445 161.113 42.2344C160.983 43.454 160.747 44.6213 160.361 45.7205C160.05 46.6042 158.911 46.9099 158.141 46.3212C157.512 45.8405 156.815 45.2481 156.065 44.5915C155.113 44.9027 154.235 45.1753 153.465 45.3721C152.521 45.6131 151.597 44.8887 151.666 43.9559C156.284 43.8477 159.432 40.9481 159.486 34.0187Z"
        fill="#EEBB4C"
      />
      <path
        d="M133.226 37.1645L130.065 34.8299L123.844 42.0953C123.505 42.4918 123.703 43.0944 124.223 43.2472L126.937 44.0447C127.117 44.0976 127.271 44.2113 127.369 44.3642L128.888 46.7175C129.165 47.1461 129.801 47.1922 130.13 46.8075L136.388 39.4991L133.226 37.1645Z"
        fill="#8C83C7"
      />
      <path
        d="M136.388 39.4987L130.13 46.8072C129.801 47.1917 129.165 47.1457 128.888 46.7173L128.528 46.1593C128.705 46.1349 128.874 46.0513 128.999 45.9049L135.222 38.6382L136.388 39.4987Z"
        fill="#5D51AE"
      />
      <path
        d="M141.845 38.3678L145.525 36.9884L149.518 45.6796C149.736 46.1539 149.381 46.6793 148.839 46.6838L146.01 46.7075C145.822 46.7091 145.643 46.7762 145.506 46.8962L143.401 48.7436C143.018 49.08 142.393 48.9501 142.182 48.4899L138.165 39.7472L141.845 38.3678Z"
        fill="#8C83C7"
      />
      <path
        d="M148.839 46.6835L148.119 46.6894C148.16 46.5401 148.152 46.3751 148.078 46.2162L144.087 37.5266L145.525 36.9875L149.519 45.6792C149.736 46.1536 149.381 46.6787 148.839 46.6835Z"
        fill="#5D51AE"
      />
      <path
        d="M151.481 32.4295C151.289 33.8061 149.72 34.738 149.156 35.9257C148.573 37.1567 148.813 38.9939 147.909 39.9602C146.997 40.9363 145.207 40.7515 144.051 41.3931C142.906 42.0284 142.058 43.6819 140.756 43.9262C139.499 44.1619 138.171 42.9317 136.841 42.746C135.51 42.5603 133.896 43.3796 132.752 42.8088C131.566 42.2171 131.204 40.3947 130.277 39.4702C129.341 38.5365 127.569 38.2239 126.959 37.0353C126.355 35.8585 127.088 34.1573 126.864 32.8135C126.648 31.5169 125.394 30.1909 125.586 28.8142C125.778 27.4376 127.347 26.5057 127.91 25.318C128.494 24.087 128.254 22.2498 129.158 21.2835C130.07 20.3074 131.86 20.4922 133.016 19.8506C134.161 19.2153 135.008 17.5618 136.311 17.3175C137.568 17.0818 138.896 18.312 140.226 18.4977C141.556 18.6834 143.171 17.8641 144.315 18.4349C145.501 19.0266 145.863 20.849 146.79 21.7735C147.726 22.7072 149.498 23.0198 150.108 24.2084C150.712 25.3852 149.979 27.0864 150.203 28.4302C150.419 29.7268 151.673 31.0528 151.481 32.4295Z"
        fill="#4F41AB"
      />
      <path
        d="M137.548 39.6329C142.571 40.3341 147.206 36.8648 147.902 31.884C148.597 26.9032 145.089 22.297 140.067 21.5958C135.044 20.8946 130.408 24.3639 129.713 29.3447C129.018 34.3255 132.526 38.9317 137.548 39.6329Z"
        fill="white"
      />
      <path
        d="M147.902 31.8854C147.206 36.8665 142.571 40.3353 137.548 39.6342C136.003 39.4184 134.6 38.8327 133.42 37.9805C134.29 38.4129 135.245 38.7148 136.262 38.8568C141.284 39.558 145.92 36.0892 146.615 31.108C147.096 27.6597 145.563 24.3911 142.907 22.4734C146.337 24.1783 148.456 27.9127 147.902 31.8854Z"
        fill="#F5F5F5"
      />
      <path
        d="M141.257 24.8536C140.967 23.833 139.575 23.5731 138.926 24.4184C138.272 25.2692 137.572 26.1122 136.874 26.9364C135.756 26.9422 134.622 26.971 133.479 27.0064C132.311 27.0425 131.704 28.3414 132.453 29.2083C133.041 29.89 133.647 30.6338 134.246 31.4066C133.895 32.5831 133.662 33.7512 133.595 34.9126L134.448 35.5442L141.198 34.8086L141.257 24.8536Z"
        fill="#F4C55F"
      />
      <path
        d="M141.258 24.854C141.549 25.8812 141.888 26.918 142.231 27.9365C143.269 28.3446 144.313 28.7799 145.363 29.2247C146.437 29.6792 146.519 31.1064 145.5 31.6437C144.699 32.0662 143.858 32.5399 143.013 33.0431C142.903 34.2646 142.685 35.4354 142.316 36.5406C142.019 37.4291 140.885 37.7527 140.106 37.1761C139.47 36.7054 138.763 36.1241 138.003 35.4793C137.056 35.8055 136.182 36.0918 135.415 36.3007C134.476 36.5566 133.541 35.8468 133.595 34.913C138.21 34.7322 141.312 31.7835 141.258 24.854Z"
        fill="#EEBB4C"
      />
      <path
        d="M114.575 36.4279L110.959 34.8874L106.586 43.394C106.348 43.8582 106.68 44.3988 107.221 44.4272L110.046 44.5757C110.233 44.5856 110.409 44.6606 110.54 44.7865L112.562 46.725C112.93 47.0781 113.56 46.9758 113.791 46.5254L118.19 37.9685L114.575 36.4279Z"
        fill="#8C83C7"
      />
      <path
        d="M118.19 37.9678L113.792 46.5248C113.56 46.9752 112.931 47.0773 112.562 46.7245L112.083 46.2648C112.25 46.2001 112.395 46.0797 112.483 45.9084L116.858 37.3999L118.19 37.9678Z"
        fill="#5D51AE"
      />
      <path
        d="M123.238 35.6069L126.5 33.4142L132.394 40.947C132.715 41.3581 132.491 41.9514 131.965 42.0811L129.218 42.758C129.036 42.8029 128.877 42.9097 128.772 43.0581L127.15 45.342C126.855 45.758 126.218 45.7759 125.906 45.3771L119.977 37.7996L123.238 35.6069Z"
        fill="#8C83C7"
      />
      <path
        d="M131.965 42.0809L131.265 42.2532C131.271 42.0985 131.225 41.9398 131.117 41.8022L125.224 34.2706L126.5 33.4135L132.394 40.9468C132.715 41.3579 132.491 41.951 131.965 42.0809Z"
        fill="#5D51AE"
      />
      <path
        d="M131.24 27.6017C131.372 28.9855 130.06 30.2549 129.787 31.5406C129.504 32.8732 130.162 34.6052 129.506 35.7541C128.844 36.9147 127.059 37.1487 126.083 38.0402C125.117 38.9228 124.674 40.7275 123.463 41.2662C122.295 41.7861 120.719 40.8962 119.381 41.023C118.044 41.1499 116.663 42.3202 115.418 42.0293C114.128 41.7277 113.354 40.0385 112.238 39.3533C111.112 38.6612 109.315 38.7668 108.447 37.7514C107.587 36.7461 107.908 34.9214 107.379 33.6658C106.869 32.4544 105.342 31.4541 105.211 30.0703C105.08 28.6865 106.391 27.4171 106.664 26.1314C106.948 24.7988 106.29 23.0668 106.945 21.9179C107.607 20.7574 109.392 20.5233 110.368 19.6318C111.335 18.7492 111.777 16.9445 112.988 16.4058C114.156 15.8859 115.733 16.7758 117.07 16.649C118.407 16.5221 119.788 15.3518 121.033 15.6427C122.324 15.9443 123.098 17.6336 124.213 18.3188C125.339 19.0108 127.136 18.9052 128.004 19.9206C128.864 20.9259 128.544 22.7506 129.072 24.0062C129.583 25.2176 131.109 26.2179 131.24 27.6017Z"
        fill="#4F41AB"
      />
      <path
        d="M119.35 37.832C124.399 37.3532 128.107 32.9064 127.632 27.8997C127.157 22.8931 122.679 19.2226 117.631 19.7014C112.582 20.1802 108.874 24.627 109.349 29.6337C109.824 34.6403 114.302 38.3109 119.35 37.832Z"
        fill="white"
      />
      <path
        d="M127.632 27.8998C128.107 32.9068 124.399 37.3532 119.35 37.832C117.797 37.9794 116.297 37.7338 114.951 37.1775C115.898 37.3969 116.897 37.47 117.919 37.3731C122.967 36.8943 126.675 32.4479 126.201 27.4409C125.872 23.9748 123.624 21.1491 120.597 19.8973C124.328 20.7632 127.253 23.9065 127.632 27.8998Z"
        fill="#F5F5F5"
      />
      <path
        d="M119.543 22.5944C119.025 21.6684 117.61 21.7375 117.174 22.71C116.735 23.6888 116.248 24.6708 115.76 25.634C114.673 25.898 113.577 26.1882 112.473 26.4868C111.345 26.792 111.055 28.1959 111.983 28.8664C112.713 29.3937 113.475 29.9772 114.237 30.5905C114.167 31.8164 114.21 33.0066 114.413 34.1522L115.39 34.5693L121.787 32.2933L119.543 22.5944Z"
        fill="#F4C55F"
      />
      <path
        d="M119.543 22.5952C120.064 23.5271 120.633 24.4576 121.202 25.3692C122.306 25.5263 123.423 25.7085 124.548 25.8984C125.697 26.0924 126.106 27.4622 125.24 28.2203C124.558 28.8165 123.849 29.4718 123.144 30.1567C123.318 31.3706 123.377 32.5601 123.274 33.7206C123.19 34.6537 122.162 35.2307 121.27 34.8498C120.543 34.5389 119.721 34.1367 118.832 33.685C117.986 34.2213 117.202 34.7019 116.504 35.0824C115.65 35.5485 114.576 35.0741 114.413 34.153C118.861 32.9102 121.197 29.3244 119.543 22.5952Z"
        fill="#EEBB4C"
      />
      <path
        d="M151.746 67.4997C155.696 67.4145 159.117 70.2259 159.799 74.1178L164.092 98.6301C164.194 99.2126 164.231 99.8047 164.202 100.395L163.639 112.071C163.507 114.828 161.962 117.321 159.554 118.668L145.475 126.542C143.786 127.487 141.811 127.787 139.918 127.387L139.1 127.214C135.164 126.383 132.457 122.758 132.779 118.747L136.286 75.0235C136.614 70.9326 139.985 67.7535 144.088 67.665L151.746 67.4997Z"
        fill="#186C75"
      />
      <path
        d="M166.468 76.6732C166.55 77.4015 166.55 77.8544 166.55 77.8544V90.6086C166.38 91.5994 166.38 92.6119 166.55 93.6027C166.55 93.6027 170.331 96.6479 170.331 103.788V109.676C170.345 111.294 169.956 112.889 169.199 114.318C168.279 116.014 163.061 117.491 160.23 117.78L159.83 111.665C159.83 111.665 164.215 100.114 159.385 93.6038C159.467 92.8291 159.452 92.0471 159.339 91.2763L159.092 78.9029C159.091 78.3235 159.056 77.7446 158.987 77.1692C161.422 76.5013 163.967 76.3326 166.468 76.6732Z"
        fill="#0694A4"
      />
      <path
        d="M166.468 76.6747L158.984 77.1425C158.604 74.4746 157.817 71.881 156.649 69.4527L162.923 68.6348C165.524 71.1421 166.26 74.8108 166.468 76.6747Z"
        fill="#186C75"
      />
      <path
        d="M169.416 46.8458C169.605 46.1506 169.025 45.4887 168.312 45.5933C165.255 46.0415 158.133 47.0916 152.479 47.9683C143.712 49.3276 135.884 50.7087 125.754 51.0513L126.7 72.9392L131.395 72.4131C131.395 72.4131 154.735 72.7242 165.51 71.5598C165.983 71.5088 166.342 71.1265 166.38 70.6525L167.261 59.4339L167.9 52.4035L169.416 46.8458Z"
        fill="#0694A4"
      />
      <path
        d="M114.735 51.0096C117.248 51.107 119.612 51.1438 121.859 51.1295C121.664 56.273 122.109 65.4171 126.7 72.9396L118.442 73.8649C116.736 74.0557 115.012 73.7145 113.507 72.888C112.001 72.0615 110.788 70.7901 110.033 69.2477C107.277 63.6138 107.547 58.8489 108.443 55.5707C108.824 54.2127 109.653 53.0232 110.795 52.1953C111.937 51.3674 113.326 50.9496 114.735 51.0096Z"
        fill="#186C75"
      />
      <path
        d="M148.757 52.3766C148.757 52.3766 147.478 63.7907 151.656 71.4906C151.814 71.7694 151.899 72.0835 151.905 72.404C151.91 72.7245 151.834 73.0411 151.685 73.3248C151.536 73.6084 151.318 73.8501 151.051 74.0275C150.784 74.2049 150.476 74.3124 150.157 74.3402C149.187 74.406 148.213 74.3615 147.253 74.2078C146.848 74.1355 146.465 73.9708 146.133 73.7265C145.802 73.4823 145.532 73.1649 145.343 72.7992C144.1 70.4467 138.33 58.9089 138.5 49.207L148.757 52.3766Z"
        fill="#186C75"
      />
      <path
        d="M166.55 90.6094V93.6035H159.385L159.339 91.276L166.55 90.6094Z"
        fill="#186C75"
      />
      <path
        d="M168.539 50.4861L148.726 53.6817C148.347 51.0138 150.534 50.997 149.365 48.5687L168.539 45.373C171.14 47.8804 168.331 48.6222 168.539 50.4861Z"
        fill="#186C75"
      />
      <path
        d="M83.8554 93.6307C83.8554 93.6307 76.8323 98.8212 70.8827 94.46C70.8827 94.46 69.1457 93.6713 67.4144 88.0864C65.6832 82.5016 73.064 86.5032 73.064 86.5032C73.064 86.5032 74.5067 92.2446 78.3731 90.3308C82.2396 88.417 84.4613 85.1113 84.4613 85.1113L89.3434 86.4974L88.4201 93.1088L83.8554 93.6307Z"
        fill="#C17F50"
      />
      <path
        d="M80.41 73.5356C78.7019 74.1533 77.0781 74.9604 75.5823 75.9351C75.0514 76.272 74.6338 76.7282 74.3816 77.2467C74.1294 77.7651 74.0537 78.3229 74.164 78.8504C75.0525 83.0218 77.4474 94.1528 79.5666 103.02C79.7119 103.628 80.0869 104.161 80.6352 104.538C81.1835 104.916 81.8755 105.118 82.607 105.114L83.2306 105.112C83.2306 105.112 85.2469 86.7295 82.5698 74.4848C82.5265 74.2867 82.4338 74.1026 82.298 73.9447C82.1622 73.7868 81.9864 73.6587 81.7821 73.5689C81.5779 73.4792 81.3499 73.4298 81.1134 73.424C80.8768 73.4183 80.637 73.4563 80.41 73.5356Z"
        fill="#0A5F68"
      />
      <path
        d="M124.847 125.25L124.793 141.769C124.793 141.769 135.628 139.762 134.249 145.325C133.025 150.254 118.922 150.409 118.31 146.739C117.308 140.766 117.907 126.193 117.907 126.193L124.847 125.25Z"
        fill="#C17F50"
      />
      <path
        d="M104.531 125.338L103.905 141.891C103.905 141.891 93.1853 140.51 94.3354 145.911C95.3576 150.699 109.433 149.824 110.2 146.059C111.451 139.945 111.451 125.405 111.451 125.405L104.531 125.338Z"
        fill="#C17F50"
      />
      <path
        d="M142.859 120.945C150.597 121.892 165.026 116.748 165.636 103.843C165.735 101.716 162.97 101.567 161.853 103.379C159.59 107.032 154.075 114.205 144.476 112.956C129.247 110.976 133.05 119.744 142.859 120.945Z"
        fill="#C17F50"
      />
      <path
        d="M94.7088 34.4993C93.6384 34.1098 88.5912 31.6365 82.4606 34.311C76.3236 36.9921 73.3394 51.6826 78.393 59.096C83.4532 66.516 91.2964 45.535 92.1657 43.1851C93.035 40.8351 96.4279 35.1225 94.7088 34.4993Z"
        fill="#C17F50"
      />
      <path
        d="M135.04 36.0088C127.318 28.2732 96.8901 27.6229 88.958 36.5845C87.3052 38.4541 84.7109 41.0214 83.39 44.8282C81.6966 49.7053 80.7889 63.7473 81.1411 63.8082H81.1276C80.904 76.4616 81.4324 118.025 89.4254 125.605C97.1272 132.914 129.214 133.964 138.969 125.923C152.035 115.153 141.346 42.3287 135.04 36.0088Z"
        fill="#E3A263"
      />
      <path
        d="M155.59 88.4816C154.879 89.3577 154 90.0315 153.048 90.5537C151.62 91.3344 150.02 91.7889 148.393 91.9942C146.767 92.1944 145.109 92.1434 143.541 91.806C142.313 91.533 141.53 90.3188 141.795 89.0848C142.06 87.8507 143.27 87.067 144.5 87.3347C145.255 87.5006 146.128 87.5683 147.01 87.5237C147.667 87.4918 148.33 87.4011 148.95 87.2503C149.879 87.0294 150.708 86.6768 151.295 86.2749C151.588 86.0766 151.824 85.8675 152.005 85.6603C152.183 85.4583 152.299 85.2614 152.379 85.0621C152.457 84.8682 152.5 84.6719 152.508 84.4251C152.516 84.0565 152.436 83.5605 152.126 82.8796C151.819 82.2062 151.273 81.3689 150.419 80.4064C149.582 79.463 149.664 78.0202 150.598 77.1797C151.537 76.3412 152.976 76.4249 153.815 77.3632C155.264 78.994 156.23 80.5693 156.72 82.1646C156.966 82.9596 157.081 83.7625 157.063 84.5481C157.045 85.3336 156.881 86.0902 156.604 86.777C156.344 87.4092 156 87.9771 155.59 88.4816Z"
        fill="#C17F50"
      />
      <path
        d="M79.9839 63.6868C80.3158 63.7546 80.6613 63.8223 81.0135 63.8765H81C81 63.8833 81 63.8968 81 63.9036H93.4569V45.2148C93.4569 45.2148 68.6988 45.54 68.875 53.743C68.9156 55.8767 70.1958 61.6276 79.9839 63.6868Z"
        fill="#E3A263"
      />
      <path
        d="M89.5951 53.2543C89.629 55.7335 85.5444 60.5361 80.4438 60.597C75.3364 60.658 71.1435 55.9638 71.1096 53.4846C71.0757 51.0054 75.1941 49.2442 80.3015 49.1833C85.409 49.1223 89.568 50.7751 89.5951 53.2543Z"
        fill="#3B1C0C"
      />
      <path
        d="M80.4916 51.1754C80.4983 51.8393 79.3265 52.3879 77.8633 52.4083C76.407 52.4286 75.2148 51.907 75.208 51.2432C75.2013 50.5793 76.3731 50.0307 77.8363 50.0103C79.2994 49.99 80.4848 50.5116 80.4916 51.1754Z"
        fill="#6C442F"
      />
      <path
        d="M130.166 38.2868C131.045 37.5618 134.974 33.543 141.646 34.0134C148.326 34.4877 156.052 47.3345 153.768 56.0111C151.481 64.696 137.073 47.546 135.467 45.6221C133.862 43.6981 128.754 39.4491 130.166 38.2868Z"
        fill="#C17F50"
      />
      <path
        d="M81.8064 65.8022C81.8469 65.8224 83.1018 66.4364 85.0988 67.0571C87.0959 67.6711 89.8486 68.2918 92.8982 68.2918C94.2745 68.2918 95.7116 68.1636 97.1621 67.8465C98.2484 67.6103 99.3481 67.2662 100.434 66.794C102.06 66.0856 103.653 65.0735 105.137 63.7039C106.621 62.3276 107.984 60.5936 109.171 58.4347C109.482 57.8747 109.273 57.173 108.713 56.8627C108.153 56.5523 107.451 56.7615 107.141 57.3214C106.183 59.0689 105.117 60.4722 103.983 61.5989C103.133 62.449 102.249 63.1372 101.345 63.7039C99.9823 64.554 98.5655 65.1208 97.1419 65.4783C95.7183 65.8359 94.2813 65.9776 92.8982 65.9776C90.1657 65.9776 87.6289 65.4109 85.787 64.8441C84.8627 64.5608 84.1205 64.2774 83.6078 64.0683C83.3514 63.9603 83.1558 63.8726 83.0276 63.8186C82.9601 63.7916 82.9129 63.7647 82.8859 63.7512C82.8724 63.7444 82.8589 63.7377 82.8521 63.7377H82.8454L82.6767 64.075L82.8454 63.7377L82.6767 64.075L82.8454 63.7377L82.535 64.3719L82.8454 63.7377L82.535 64.3719L82.8454 63.7377L82.3394 64.7564L82.8454 63.7377L82.3394 64.7564L82.8454 63.7377C82.2719 63.4543 81.577 63.6904 81.2936 64.2639C80.9968 64.8239 81.2329 65.5188 81.8064 65.8022Z"
        fill="#C17F50"
      />
      <path
        d="M65.758 103.446C65.7232 103.446 65.6883 103.444 65.6536 103.441C65.3844 103.413 65.1371 103.28 64.966 103.07C64.795 102.861 64.7142 102.591 64.7415 102.322L64.8042 101.704C65.0211 99.5076 64.358 97.3149 62.9604 95.6068C61.6937 94.0693 60.9001 92.1976 60.6756 90.2182L60.5325 88.9626C60.5018 88.6936 60.5792 88.4234 60.7477 88.2116C60.9163 87.9997 61.162 87.8634 61.431 87.8327C61.7 87.8021 61.9702 87.8795 62.182 88.048C62.3939 88.2166 62.5302 88.4623 62.5609 88.7313L62.7037 89.9873C62.8836 91.5755 63.5203 93.0775 64.5365 94.3112C66.2786 96.4405 67.1051 99.1738 66.8346 101.912L66.7717 102.53C66.7458 102.781 66.628 103.013 66.4408 103.183C66.2536 103.352 66.0104 103.446 65.758 103.446Z"
        fill="#3B1C0C"
      />
      <path
        d="M71.0862 115.168C70.8007 113.798 70.5709 112.393 70.3887 110.972C70.2808 110.2 70.3347 109.414 70.547 108.664C70.7592 107.914 71.1252 107.217 71.6216 106.616C72.189 105.94 72.9148 105.415 73.7343 105.088C73.8824 105.029 74.0071 104.923 74.0892 104.787C74.1712 104.65 74.2061 104.49 74.1883 104.332L73.7585 100.279C73.7457 100.156 73.7019 100.038 73.6312 99.9372C73.5605 99.8361 73.4652 99.7546 73.3543 99.7004C72.8248 99.4468 72.3258 99.1338 71.8671 98.7673C71.2278 98.2411 70.7228 97.5702 70.394 96.8103C70.0653 96.0503 69.9221 95.2229 69.9764 94.3967C70.2103 90.1127 70.6751 85.8445 71.3688 81.6106C72.4005 72.6875 70.4805 62.9277 69.4681 58.6467C69.318 58.0135 68.9583 57.4496 68.4474 57.0464C67.9366 56.6433 67.3045 56.4246 66.6538 56.4258H65.2561C65.0216 56.4257 64.7902 56.4789 64.5792 56.5812C64.3682 56.6836 64.1832 56.8325 64.0382 57.0167C63.8931 57.201 63.7917 57.4157 63.7417 57.6448C63.6917 57.8739 63.6943 58.1114 63.7494 58.3393C64.7382 62.4442 66.7804 72.4679 65.7236 81.6095C65.7236 81.6095 62.3701 100.385 65.4349 115.141C66.0261 117.919 66.4595 120.728 66.7328 123.555C67.2422 129.099 67.6574 137.791 66.5259 146.457C66.3018 148.173 67.6096 149.739 69.3403 149.739C70.685 149.739 71.8422 148.776 72.0345 147.445C73.3215 138.54 72.914 129.479 72.3926 123.716C72.1215 120.844 71.6854 117.99 71.0862 115.168Z"
        fill="#6C442F"
      />
      <path
        d="M71.6787 88.645C74.1789 87.172 75.0087 83.9405 73.532 81.4273C72.0554 78.9141 68.8315 78.0709 66.3312 79.5439C63.831 81.0168 63.0013 84.2483 64.4779 86.7615C65.9546 89.2747 69.1785 90.118 71.6787 88.645Z"
        fill="#3B1C0C"
      />
      <path
        d="M143.613 69.2078C140.757 69.6136 140.241 73.7452 139.139 76.8773C136.229 85.2068 137.274 93.932 141.894 102.764C141.983 102.87 142.074 102.975 142.166 103.08C142.693 103.681 143.382 104.16 144.192 104.488C145.954 105.2 147.493 104.003 147.604 102.106L147.651 101.297C147.651 101.297 141.59 93.6141 147.312 77.4702C147.968 75.6217 147.622 73.538 146.29 72.0989L143.613 69.2078Z"
        fill="#0A5F68"
      />
      <path
        d="M156.075 77.3099C156.831 74.5981 155.247 71.7865 152.537 71.03C149.826 70.2735 147.016 71.8586 146.26 74.5704C145.504 77.2821 147.088 80.0937 149.799 80.8502C152.509 81.6067 155.319 80.0216 156.075 77.3099Z"
        fill="#3B1C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.8834 38.3073C85.331 37.9086 86.017 37.9482 86.4157 38.3957L90.3603 42.8233C90.6288 43.1247 90.7078 43.5503 90.5654 43.9281C90.423 44.3058 90.0827 44.5733 89.682 44.6225L82.9132 45.4529C82.3183 45.5259 81.7768 45.1027 81.7038 44.5078C81.6308 43.9129 82.0539 43.3714 82.6489 43.2984L87.3614 42.7203L84.795 39.8396C84.3963 39.3921 84.4359 38.706 84.8834 38.3073Z"
        fill="#3B1C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.046 39.5185C112.761 38.9908 112.103 38.7935 111.575 39.0778L104.252 43.0233C103.897 43.2148 103.677 43.5877 103.682 43.9914C103.687 44.3951 103.915 44.7628 104.275 44.9459L112.8 49.2862C113.334 49.5581 113.987 49.3456 114.259 48.8114C114.531 48.2772 114.319 47.6237 113.785 47.3518L107.106 43.9515L112.605 40.9887C113.133 40.7044 113.33 40.0462 113.046 39.5185Z"
        fill="#3B1C0C"
      />
      <path
        d="M231.473 136.313V138.28C231.473 139.432 230.539 140.368 229.384 140.368H188.526C186.317 140.368 184.526 138.577 184.526 136.368V124.315C184.526 122.995 185.049 121.729 185.979 120.793L189.322 117.429L190.76 115.985C193.515 113.214 195.271 113.02 195.271 113.02H208.263L228.059 130.108L229.635 131.468C231.272 132.671 231.473 134.446 231.473 136.313Z"
        fill="#F3EDDF"
      />
      <path
        d="M230.092 128.291C230.972 129.097 231.474 130.235 231.474 131.428V138.02C231.474 136.076 230.153 134.379 228.268 133.902L215.305 130.621C214.742 130.479 214.258 130.121 213.957 129.625L207.571 119.111C206.314 117.043 204.069 115.78 201.648 115.78H195.356C193.631 115.78 191.976 116.468 190.759 117.693L195.435 112.989C196.372 112.046 197.646 111.516 198.975 111.516H218.986C221.612 111.516 223.741 113.644 223.741 116.271V121.178C223.741 122.008 224.09 122.799 224.702 123.359L230.092 128.291Z"
        fill="#E0DBCF"
      />
      <path
        d="M199.952 141.645V146.68C199.952 147.835 199.016 148.771 197.863 148.771H164.617C163.319 148.771 162.337 147.602 162.557 146.325L164.236 136.635C164.557 134.776 165.958 133.29 167.795 132.861L194.461 137.613L198.603 138.35C199.466 139.23 199.952 140.412 199.952 141.645Z"
        fill="#F3EDDF"
      />
      <path
        d="M198.602 138.35L181.28 138.027C180.302 138.009 179.337 137.801 178.438 137.417L167.795 132.862L185.892 128.638C187.485 128.267 189.159 128.752 190.307 129.919L198.602 138.35Z"
        fill="#E0DBCF"
      />
      <path
        d="M0.525909 151.645V156.68C0.525909 157.835 1.46176 158.771 2.61491 158.771H35.8607C37.1587 158.771 38.1409 157.602 37.9207 156.325L36.2417 146.635C35.9201 144.776 34.5192 143.29 32.6823 142.861L6.01641 147.613L1.87463 148.35C1.01122 149.23 0.525909 150.412 0.525909 151.645Z"
        fill="#F3EDDF"
      />
      <path
        d="M1.8755 148.35L18.5259 149.516C19.5037 149.497 22.1267 148.4 23.0259 148.016L32.6821 142.862L14.5852 138.638C12.9924 138.267 11.3189 138.752 10.1705 139.919L1.8755 148.35Z"
        fill="#E0DBCF"
      />
      <path
        d="M200.624 160.915H209.09C212.076 160.794 211.482 156.567 209.12 155.841L203.898 153.749C201.737 152.733 198.495 155.229 198.558 157.5C198.412 159.036 198.701 160.887 200.624 160.915Z"
        fill="#E0DBCF"
      />
      <path
        d="M72.0175 167.915H63.552C60.5658 167.794 61.1596 163.567 63.5216 162.841L68.7439 160.749C70.905 159.733 74.147 162.229 74.0832 164.5C74.2295 166.036 73.9405 167.887 72.0175 167.915Z"
        fill="#E0DBCF"
      />
      <path
        d="M180.769 174.81H166.062C160.874 174.601 161.906 167.256 166.009 165.996L175.082 162.361C178.837 160.595 184.469 164.932 184.358 168.876C184.612 171.547 184.11 174.762 180.769 174.81Z"
        fill="#E0DBCF"
      />
      <path
        d="M193.215 166.902C193.82 166.902 194.31 166.412 194.31 165.808C194.31 165.203 193.82 164.713 193.215 164.713C192.611 164.713 192.121 165.203 192.121 165.808C192.121 166.412 192.611 166.902 193.215 166.902Z"
        fill="#E0DBCF"
      />
    </svg>
  );
}
