import katex from 'katex';
import * as React from 'react';
import 'katex/dist/katex.css';

// Renders the provided latex string in an inline element using Katex.
//
// 🚨 Warning 🚨 This component should not be used broadly. It exists
// only to serve an accessibility requirement for a small subset of
// investigation chapter entries whose latex has been manually verified to
// be Katex-compatible latex. Any latex that exists outside of our modern
// JsxContent world has been designed for MathQuill, not Katex. The latex
// designed for MathQuill heavily relies upon Katex-incompatible latex
// constructs, which have no analog in Katex (as they don't represent math
// notation). \editable{} is a canonical example of this.
export function Katex({ latex }: { latex: string }) {
  // We want to render display style latex, but in an inline context.
  // Thus, we use latex \displaystyle command as a prefix rather than
  // { displayMode: true } in the katex options (as the latter will
  // make the katex node use block formatting; which we don't want)
  const displayLatex = `\\displaystyle ${latex}`;
  const ref = React.useRef(null);
  React.useLayoutEffect(() => {
    if (ref.current === null) return;
    katex.render(displayLatex, ref.current, { throwOnError: false });
  }, [displayLatex]);
  // NOTE Katex renders at 1.21em
  return <span ref={ref}>{displayLatex}</span>;
}
