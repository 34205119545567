import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M3,9.94415471 C5.58419861,10.2409681 7.38445183,9.78768755 8.40075967,8.58431292 C9.92522144,6.77925097 10.1417105,5.59615345 10.675218,4.56820006 C11.2087255,3.54024667 11.0200151,2.49339604 12.625,3.2689893 C14.2299849,4.04458255 11.8753232,7.52219557 12.8157182,7.52219557 C13.7561132,7.52219557 17.6586869,7.00664589 17.3,9.04921519 C17.1810778,9.54103861 16.912822,9.96039736 16.4952327,10.3072914 C16.7562806,11.3119309 16.6701628,12.0776661 16.2368791,12.6044969 C16.2947374,12.9698948 16.8868046,14.3283698 15.7853909,15.0740992 C15.7563158,15.0934749 17.2355865,17.9277732 11.4095412,16.9321798 C7.52551094,16.2684509 4.72233055,15.926868 3,15.9074311 L3,9.94415471 Z"
    transform="translate(10.161607, 10.072055) scale(1, -1) translate(-10.161607, -10.072055) "
  />
);

const ThumbsDown = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ThumbsDown.displayName = 'ThumbsDown';

export default ThumbsDown;
