import { StyleSheet, css } from 'aphrodite';
import type { ReactElement, ReactNode } from 'react';

import { fontFamily } from 'ms-styles/base';

const tableStyles = StyleSheet.create({
  root: {
    display: 'inline-block',
    lineHeight: 1,
    width: '100%',
  },
  bodyWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  body: {
    display: 'table',
    fontFamily: fontFamily.body,
    width: '100%',
    // Aphrodite hack to remove the border from cells in the first row.
    ':nth-of-type(n) > :first-child > *': {
      borderTop: 'none !important',
    },
  },
  footer: {
    marginTop: 30,
  },
});

type Props = {
  children: ReactNode;
  action?: ReactElement<any> | null | undefined;
};

const Table = ({ children, action }: Props) => (
  <div className={css(tableStyles.root)}>
    <div className={css(tableStyles.bodyWrapper)}>
      <div className={css(tableStyles.body)}>{children}</div>
    </div>
    {action && <div className={css(tableStyles.footer)}>{action}</div>}
  </div>
);

export default Table;
export { default as TableRow } from './TableRow';
export { default as TableCell } from './TableCell';
