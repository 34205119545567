import { find, pathEq } from 'ramda';

/**
 * Extract the error message associated with a key, if present.
 * Intended for use with the LocalState container component.
 *
 * @param {*} key The key to look up the error by.
 * @param {*} errors The list of errors to look in.
 * @returns {string} The error message, or '', if no error present.


 */
export type KeyedError = { readonly key?: string; readonly message: string };
export type KeyedErrors = ReadonlyArray<KeyedError | null | undefined>;
const errorMessageFor = (key: string, errors: KeyedErrors): string => {
  const error = find(pathEq(['key'], key), errors || []) as
    | KeyedError
    | null
    | undefined;
  if (error == null || error.message == null) return '';
  return error.message;
};

export default errorMessageFor;
