import styled from '@emotion/styled';
import { useState, Fragment } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import CrossBold from 'ms-components/icons/CrossBold';
import PencilIcon from 'ms-components/icons/Pencil';
import { AnchorButton } from 'ms-pages/Lantern/primitives/Button';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import OutcomeSubtopicRecommendationCard from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapSubstrand/OutcomeSubtopicRecommendationCard';
import { fontFamily, font, fontSize } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { HSpacer, HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import { InvariantViolation } from 'ms-utils/app-logging';
import { tappable } from 'ms-utils/emotion';
import {
  getContentBrowsingSubtopicDetailViewUrl,
  getSelfDirectedAdaptiveTaskUrl,
} from 'ms-utils/urls';

import type { SubstrandAdaptivePracticeModalQuery } from './__generated__/SubstrandAdaptivePracticeModalQuery.graphql';

const QUERY = graphql`
  query SubstrandAdaptivePracticeModalQuery($outcome: ID!) {
    lantern {
      outcome(id: $outcome) {
        title
        subtopics {
          id
          hasAdaptivePractice
          topic {
            id
            syllabus {
              id
            }
          }
          ...OutcomeSubtopicRecommendationCard_subtopic
        }
      }
    }
  }
`;
export default function SubstrandAdaptivePracticeModal({
  outcome,
  onDismiss,
}: {
  outcome: string;
  onDismiss: () => void;
}) {
  const { props } = useQuery<SubstrandAdaptivePracticeModalQuery>(QUERY, {
    outcome,
  });
  const [selectedSubtopicIndex, setSelectedSubtopicIndex] = useState(0);
  const selectedSubtopic =
    props?.lantern.outcome?.subtopics[selectedSubtopicIndex];
  if (props != null && props.lantern.outcome == null) {
    throw new InvariantViolation(`No such outcome ${outcome}`);
  }
  return (
    <Panel>
      {props == null ? (
        <LoadingSpinner />
      ) : (
        <>
          <DismissAction onClick={onDismiss}>
            <CrossBold size={24} color={colors.grey90} />
          </DismissAction>
          <Title>Select a subtopic to practice your skill</Title>
          <Subtitle>
            These subtopics will help you practice the skill{' '}
            <strong>“{props.lantern.outcome?.title}”</strong>
          </Subtitle>
          <HStack center>
            {props.lantern.outcome?.subtopics
              .slice(0, 3)
              .map((reco, index, arr) => (
                <Fragment key={reco.id}>
                  <div
                    style={{
                      border:
                        selectedSubtopic?.id === reco.id
                          ? `3px solid ${colors.eggplant}`
                          : `3px solid transparent`,
                      borderRadius: 20,
                      display: 'inline-block',
                    }}
                    onClickCapture={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelectedSubtopicIndex(index);
                    }}
                    role="button"
                  >
                    <OutcomeSubtopicRecommendationCard
                      isNotInteractive
                      subtopic={reco}
                    />
                  </div>

                  {index < arr.length - 1 && <HSpacer width={20} />}
                </Fragment>
              )) ?? <></>}
          </HStack>
          <VSpacer height={40} />
          <VStack>
            <AnchorButton
              height={48}
              type="primary"
              isWide
              isDisabled={selectedSubtopic == null}
              href={
                selectedSubtopic
                  ? selectedSubtopic.hasAdaptivePractice
                    ? getSelfDirectedAdaptiveTaskUrl({
                        subtopicId: selectedSubtopic?.id,
                        isSubtopicRecommendation: true,
                        recommendationCategory: 'PRIOR_SKILLS_MASTERED',
                      })
                    : getContentBrowsingSubtopicDetailViewUrl({
                        syllabusId: selectedSubtopic?.topic.syllabus.id,
                        topicId: selectedSubtopic?.topic.id,
                        subtopicId: selectedSubtopic?.id,
                      })
                  : '#'
              }
              label="Start practicing"
            >
              <>
                <PencilIcon size={20} />
                <HSpacer width={15} />
                <span style={{ fontSize: fontSize.medium }}>
                  Start practicing
                </span>
              </>
            </AnchorButton>
            <VSpacer height={12} />
          </VStack>
        </>
      )}
    </Panel>
  );
}
const Panel = styled.div<{}>({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: colors.almond50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 100,
});
const Title = styled.h1({
  ...font.gilroyLargeHeading,
  color: colors.neutralGray,
  margin: 0,
  marginBottom: 24,
  lineHeight: 1,
});
const Subtitle = styled.p({
  fontFamily: fontFamily.body,
  color: colors.neutralGray,
  margin: 0,
  marginBottom: 40,
  lineHeight: 1,
});
const DismissAction = styled.div<{}>({
  ...tappable,
  position: 'fixed',
  top: 16,
  right: 16,
});
