import { graphql, useQuery } from 'relay-hooks';

import { FeedbackComponent } from 'ms-components/Feedback/FeedbackComponent';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import PageTitle from 'ms-helpers/PageTitle';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import NotFound from 'ms-pages/Lantern/components/NotFound';
import LoadingSpinner from 'ms-pages/Lantern/primitives/LoadingSpinner';
import { InvariantViolation } from 'ms-utils/app-logging';

import StudentSkillsMapSubstrandHeader from './StudentSkillsMapSubstrandHeader';
import StudentSkillsMapSubstrandRecommendations from './StudentSkillsMapSubstrandRecommendations';
import StudentSkillsMapSubstrandSkills from './StudentSkillsMapSubstrandSkills';
import type { StudentSkillsMapSubstrandQuery } from './__generated__/StudentSkillsMapSubstrandQuery.graphql';
import { ContainerVStack, RootVStack, SectionVStack } from '../styles';
import { isStrandLocked } from '../utils';

type Props = {
  strandId: string;
  substrandId: string;
  hideSkillsMapLink?: boolean | undefined;
};
export default function StudentSkillsMapSubstrand({
  strandId,
  substrandId,
  hideSkillsMapLink = false,
}: Props) {
  const {
    featureFlags: { enableOptInDiscoveryCheckIn },
  } = useViewer();

  const { props, error } = useQuery<StudentSkillsMapSubstrandQuery>(
    graphql`
      query StudentSkillsMapSubstrandQuery($strandId: ID!, $substrandId: ID!) {
        lantern {
          substrand(id: $substrandId) {
            id
            title
            ...StudentSkillsMapSubstrandHeader_substrand
            ...StudentSkillsMapSubstrandSkills_substrand
          }
          viewer {
            __typename
            ... on LanternStudent {
              ...StudentSkillsMapSubstrandHeader_lanternStudent
              strandStatus(strandId: $strandId) {
                ...StudentSkillsMapSubstrandHeader_studentStrandStatus
                ...StudentSkillsMapSubstrandSkills_studentStrandStatus
                status
              }
            }
          }
        }
      }
    `,
    {
      strandId,
      substrandId,
    },
  );
  if (error != null) {
    return (
      <PageTimeErrorThrower
        pageName="StudentSkillsMapSubstrand"
        componentName="StudentSkillsMapSubstrand"
        error={error}
      />
    );
  }
  if (props == null) return <LoadingSpinner />;
  if (props.lantern.substrand == null) return <NotFound />;
  if (
    props.lantern.viewer == null ||
    props.lantern.viewer.__typename !== 'LanternStudent'
  ) {
    throw new InvariantViolation('User is not a student');
  }
  const {
    lantern: {
      viewer: { strandStatus },
      viewer: lanternStudent,
      substrand,
    },
  } = props;
  if (strandStatus == null) return <NotFound />;
  return (
    <PageTimeRecorder
      pageName="StudentSkillsMapCurriculum"
      componentName="StudentSkillsMapSubstrand"
    >
      <PageTitle subtitles={[substrand.title, 'Skills']} />
      <RootVStack>
        <SectionVStack hasWhiteBackground>
          <ContainerVStack isCentered={false}>
            <StudentSkillsMapSubstrandHeader
              strandId={strandId}
              studentStrandStatus={strandStatus}
              substrand={substrand}
              lanternStudent={lanternStudent}
              hideSkillsMapLink={hideSkillsMapLink}
            />
          </ContainerVStack>
        </SectionVStack>
        {!isStrandLocked(strandStatus, enableOptInDiscoveryCheckIn) && (
          <SectionVStack hasWhiteBackground hasShadow>
            <ContainerVStack isCentered>
              <StudentSkillsMapSubstrandRecommendations
                substrandId={substrandId}
                strandId={strandId}
                hideSkillsMapLink={hideSkillsMapLink}
              />
            </ContainerVStack>
          </SectionVStack>
        )}
        <SectionVStack>
          <ContainerVStack isCentered>
            <StudentSkillsMapSubstrandSkills
              substrandId={substrandId}
              studentStrandStatus={strandStatus}
              substrand={substrand}
              strandId={strandId}
            />
            <FeedbackComponent feedbackType="SKILLS_MAP" />
          </ContainerVStack>
        </SectionVStack>
      </RootVStack>
    </PageTimeRecorder>
  );
}
