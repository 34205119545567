/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LearningFocusStatusEnum = "INVALID" | "UNSET" | "VALID";
export type SubstrandSubtopicRecommendationCategory = "PRIOR_SKILLS_MASTERED" | "SKILL_ALMOST_MASTERED" | "SKILL_WORKED_ON_RECENTLY";
export type NavbarPopoverContentQueryVariables = {
    startOfWeek: string;
    now: string;
    previewingWithProblemData: boolean;
    growthPeriod: number;
};
export type NavbarPopoverContentQueryResponse = {
    readonly lantern: {
        readonly lanternStudent: ({
            readonly __typename: "LanternStudent";
            readonly learningFocus: {
                readonly id: string;
                readonly canStartCheckIn: boolean;
                readonly strand: {
                    readonly id: string;
                };
                readonly studentSubtopicRecommendations: ReadonlyArray<{
                    readonly category: SubstrandSubtopicRecommendationCategory;
                    readonly subtopic: {
                        readonly id: string;
                    };
                }>;
            } | null;
            readonly learningFocusStatus: LearningFocusStatusEnum;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        }) | null;
    };
    readonly viewer: {
        readonly profile: {
            readonly __typename: "Student";
            readonly activitySummary: {
                readonly skillsDemonstrated: ReadonlyArray<{
                    readonly id: string;
                    readonly outcome: {
                        readonly id: string;
                        readonly title: string;
                        readonly gradeSubstrand: {
                            readonly substrand: {
                                readonly id: string;
                                readonly strand: {
                                    readonly id: string;
                                };
                            };
                        };
                    };
                }>;
                readonly checkInsCompleted: number;
            } | null;
        } | {
            /*This will never be '%other', but we need some
            value in case none of the concrete values match.*/
            readonly __typename: "%other";
        };
    };
};
export type NavbarPopoverContentQuery = {
    readonly response: NavbarPopoverContentQueryResponse;
    readonly variables: NavbarPopoverContentQueryVariables;
};



/*
query NavbarPopoverContentQuery(
  $startOfWeek: DateTime!
  $now: DateTime!
  $previewingWithProblemData: Boolean!
  $growthPeriod: Int!
) {
  lantern {
    lanternStudent: viewer {
      __typename
      ... on LanternStudent {
        learningFocus {
          id
          canStartCheckIn
          strand {
            id
          }
          studentSubtopicRecommendations {
            category
            subtopic {
              id
            }
            id
          }
        }
        learningFocusStatus
      }
      id
    }
  }
  viewer {
    profile {
      __typename
      ... on Student {
        activitySummary(startDate: $startOfWeek, endDate: $now) {
          skillsDemonstrated(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {
            id
            outcome {
              id
              title
              gradeSubstrand {
                substrand {
                  id
                  strand {
                    id
                  }
                }
                id
              }
            }
          }
          checkInsCompleted
          id
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
      ... on Teacher {
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "growthPeriod"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "now"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "previewingWithProblemData"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startOfWeek"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canStartCheckIn",
  "storageKey": null
},
v7 = [
  (v5/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Subtopic",
  "kind": "LinkedField",
  "name": "subtopic",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "learningFocusStatus",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "endDate",
    "variableName": "now"
  },
  {
    "kind": "Variable",
    "name": "startDate",
    "variableName": "startOfWeek"
  }
],
v13 = [
  {
    "kind": "Variable",
    "name": "growthPeriod",
    "variableName": "growthPeriod"
  },
  {
    "kind": "Variable",
    "name": "previewingWithProblemData",
    "variableName": "previewingWithProblemData"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Substrand",
  "kind": "LinkedField",
  "name": "substrand",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "checkInsCompleted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavbarPopoverContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": "lanternStudent",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Substrand",
                    "kind": "LinkedField",
                    "name": "learningFocus",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubstrandSubtopicRecommendation",
                        "kind": "LinkedField",
                        "name": "studentSubtopicRecommendations",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "StudentActivitySummary",
                    "kind": "LinkedField",
                    "name": "activitySummary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v13/*: any*/),
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skillsDemonstrated",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LanternOutcome",
                            "kind": "LinkedField",
                            "name": "outcome",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GradeSubstrand",
                                "kind": "LinkedField",
                                "name": "gradeSubstrand",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NavbarPopoverContentQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": "lanternStudent",
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Substrand",
                    "kind": "LinkedField",
                    "name": "learningFocus",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SubstrandSubtopicRecommendation",
                        "kind": "LinkedField",
                        "name": "studentSubtopicRecommendations",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "type": "LanternStudent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "StudentActivitySummary",
                    "kind": "LinkedField",
                    "name": "activitySummary",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v13/*: any*/),
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skillsDemonstrated",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "LanternOutcome",
                            "kind": "LinkedField",
                            "name": "outcome",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v14/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "GradeSubstrand",
                                "kind": "LinkedField",
                                "name": "gradeSubstrand",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Student",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "Teacher",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "154b6c2ff6a4a2046404e8505cd69731",
    "id": null,
    "metadata": {},
    "name": "NavbarPopoverContentQuery",
    "operationKind": "query",
    "text": "query NavbarPopoverContentQuery(\n  $startOfWeek: DateTime!\n  $now: DateTime!\n  $previewingWithProblemData: Boolean!\n  $growthPeriod: Int!\n) {\n  lantern {\n    lanternStudent: viewer {\n      __typename\n      ... on LanternStudent {\n        learningFocus {\n          id\n          canStartCheckIn\n          strand {\n            id\n          }\n          studentSubtopicRecommendations {\n            category\n            subtopic {\n              id\n            }\n            id\n          }\n        }\n        learningFocusStatus\n      }\n      id\n    }\n  }\n  viewer {\n    profile {\n      __typename\n      ... on Student {\n        activitySummary(startDate: $startOfWeek, endDate: $now) {\n          skillsDemonstrated(previewingWithProblemData: $previewingWithProblemData, growthPeriod: $growthPeriod) {\n            id\n            outcome {\n              id\n              title\n              gradeSubstrand {\n                substrand {\n                  id\n                  strand {\n                    id\n                  }\n                }\n                id\n              }\n            }\n          }\n          checkInsCompleted\n          id\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      ... on Teacher {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd94ceaa7696ecdc5fa57b9e3ea025fe4';
export default node;
