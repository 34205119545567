import { css } from 'aphrodite';
import type { ComponentType, TouchEvent as SyntheticTouchEvent } from 'react';

import type { ItemBankItem } from 'ms-components/math/NumberBuilder';
import DraggableItem from 'ms-components/math/NumberBuilder/DraggableItem';
import type { ItemDropData } from 'ms-components/math/NumberBuilder/DraggableItem';

import styles from './styles';

function noop() {}

const shouldHide = (item: ItemBankItem) => !item.shouldRespawn && item.isUsed;
const disableTouchScroll = (e: SyntheticTouchEvent<any>) => e.preventDefault();

type Props = {
  items: ReadonlyArray<ItemBankItem>;
  onItemDropped?: (data: ItemDropData) => void;
  itemComponent: ComponentType<any>;
  onItemDropOnZone?: (data: ItemDropData) => void;
  onItemDropOutsideZone?: (data: ItemDropData) => void;
  isReadOnly?: boolean;
  numberBuilderId: number;
};

const ItemsBank = ({
  items,
  onItemDropped = noop,
  itemComponent: Item,
  onItemDropOnZone = noop,
  onItemDropOutsideZone = noop,
  isReadOnly = false,
  numberBuilderId,
}: Props) => (
  <div className={css(styles.shrinkwrap)} onTouchMove={disableTouchScroll}>
    <div className={css(styles.container)}>
      {items.map((item, idx) => {
        if (shouldHide(item)) {
          return (
            <div key={idx} className={css(styles.itemWrapTransparent)}>
              <Item value={item.value} />
            </div>
          );
        }

        if (isReadOnly) {
          return (
            <div key={idx} className={css(styles.itemWrapReadOnly)}>
              <Item value={item.value} isDisabled />
            </div>
          );
        }

        return (
          <div key={idx} className={css(styles.itemWrap)}>
            <DraggableItem
              value={item.value}
              index={idx}
              onItemDropped={onItemDropped}
              onItemDropOnZone={onItemDropOnZone}
              onItemDropOutsideZone={onItemDropOutsideZone}
              itemComponent={Item}
              dragStartContainer="shelf"
              dragStartContainerId={null} // ItemsBank has no id
              numberBuilderId={numberBuilderId}
            />
          </div>
        );
      })}
    </div>
  </div>
);

export default ItemsBank;
