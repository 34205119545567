import type { ComponentType } from 'react';
import { useMemo } from 'react';

import {
  EASE_IN_OUT,
  useTimeline,
  useTrigger,
} from 'ms-utils/animation/animation';

import type { Props, Variant } from './types';

const ASPECT_RATIO = 126 / 146;

type MiloParts = {
  Background: ComponentType;
  RightEar: ComponentType;
  LeftEar: ComponentType<{ rotation: number }>;
  RightFoot: ComponentType;
  LeftFoot: ComponentType;
  RightHand: ComponentType;
  LeftHand: ComponentType;
  Tail: ComponentType<{ rotation: number; translateY: number }>;
  Body: ComponentType;
  Snoot: ComponentType;
  Eyes: ComponentType<{ blink: boolean }>;
  Foreground: ComponentType;
};

export function MiloDynamic({ variant = 'regular', height = 146 }: Props) {
  const width = ASPECT_RATIO * height;

  const [trigger, fire] = useTrigger();

  const { timeline } = useTimeline({
    startPaused: true,
    startDone: true,
    trigger,
    triggerType: 'rising',
    timeline: {
      blink: {
        values: [1, 0, 1, 0],
        start: 0,
        end: 2000,
      },
      tailTransY: {
        values: [0, -10, 0],
        start: 0,
        end: 1200,
        curve: [
          // Hover curve
          [0, 0],
          [0, 1],
          [1, 0],
          [1, 1],
        ],
      },
      tailRot: {
        values: [0, 15, -15, 0, 15, -15, 0],
        start: 500,
        end: 1000,
      },
      earRot: {
        values: [0, 15, -5, 0],
        start: 0,
        end: 1000,
        curve: EASE_IN_OUT,
      },
    },
  });

  const {
    Background,
    RightEar,
    LeftEar,
    RightFoot,
    LeftFoot,
    RightHand,
    LeftHand,
    Tail,
    Body,
    Snoot,
    Eyes,
    Foreground,
  } = useMiloParts(variant);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 126 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={fire}
      onMouseDown={fire}
    >
      <Background />
      {/* ORDER HERE IS IMPORTANT. Earlier layers will be occluded by later layers*/}
      <RightEar />
      <Tail
        rotation={timeline.tailRot.value}
        translateY={timeline.tailTransY.value}
      />
      <LeftFoot />
      <RightFoot />
      <RightHand />
      <Body />
      <Snoot />
      <Eyes blink={timeline.blink.value > 0.5} />
      <LeftEar rotation={timeline.earRot.value} />
      <LeftHand />
      <Foreground />
    </svg>
  );
}

function useMiloParts(variant: Variant): MiloParts {
  return useMemo(() => {
    switch (variant) {
      //  Pencil and regular case are identical
      // explorer currently has no animation.
      case 'regular':
      case 'pencil':
      case 'celebrating':
      case 'collecting':
      case 'explorer':
      case 'waving':
        return {
          Background: Nothing,
          RightEar,
          LeftEar,
          RightFoot,
          LeftFoot,
          LeftHand,
          RightHand: WavingRightHand,
          Tail,
          Body,
          Snoot,
          Eyes,
          Foreground: Nothing,
        };
      case 'withFlag':
        return {
          Background: Nothing,
          RightEar,
          LeftEar,
          RightFoot,
          LeftFoot,
          LeftHand: FlagLeftHand,
          RightHand,
          Tail,
          Body,
          Snoot,
          Eyes,
          Foreground: Nothing,
        };
      default:
        throw new TypeError(`No such milo variant: ${variant}`);
    }
  }, [variant]);
}

function Nothing() {
  return null;
}

const Eyes = ({ blink }: { blink: boolean }) => (
  <>
    {blink ? (
      <>
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M43.7926 23.8384C43.5377 23.8384 43.2823 23.7468 43.0797 23.5612C42.6922 23.2063 41.8554 22.8049 40.6686 22.7427C39.4826 22.6827 38.6075 22.9926 38.1849 23.3053C37.7167 23.6517 37.057 23.5538 36.7102 23.0878C36.3631 22.6217 36.4607 21.9631 36.9281 21.6169C37.8703 20.9191 39.2688 20.5633 40.7789 20.6427C42.2837 20.7216 43.6423 21.221 44.5062 22.0128C44.9349 22.4057 44.9633 23.0708 44.5692 23.4983C44.3613 23.724 44.0775 23.8384 43.7926 23.8384Z"
          fill="#3B1C0C"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M60.5837 25.4531C60.8362 25.4885 61.1018 25.4332 61.3283 25.2775C61.7616 24.9799 62.6463 24.6985 63.8302 24.8016C65.0131 24.9069 65.8364 25.3352 66.2112 25.7035C66.6265 26.1116 67.2935 26.1061 67.7019 25.6929C68.1107 25.2794 68.106 24.6137 67.6914 24.206C66.8557 23.3842 65.5205 22.8377 64.014 22.7068C62.5129 22.576 61.0978 22.882 60.1318 23.5462C59.6524 23.8757 59.5315 24.5305 59.8621 25.0085C60.0365 25.2609 60.3015 25.4135 60.5837 25.4531Z"
          fill="#3B1C0C"
        />
      </>
    ) : (
      <>
        <path
          d="M69.5185 24.0378C69.5556 26.7925 67.8424 29.0523 65.699 29.0819C63.5557 29.1114 61.7906 26.8959 61.7535 24.1412C61.7164 21.3866 63.4296 19.1267 65.573 19.0972C67.7163 19.0676 69.4814 21.2832 69.5185 24.0378Z"
          fill="#3B1C0C"
        />
        <path
          d="M46.6241 22.4279C46.6537 24.924 45.1111 26.9623 43.168 26.9919C41.2249 27.014 39.6304 25.0127 39.6007 22.5165C39.5637 20.0203 41.1137 17.9746 43.0568 17.9525C44.9925 17.9303 46.5944 19.9317 46.6241 22.4279Z"
          fill="#3B1C0C"
        />
      </>
    )}
  </>
);

const LeftHand = () => (
  <g
    style={{
      transformOrigin: '91.86px 62.86px',
    }}
  >
    <path
      d="M108.712 73.0371 C 108.267 71.6003 107.513 70.329 106.592 69.2081 C105.212 67.5306 103.454 66.169 101.537 65.1008C99.6209 64.0401 97.5385 63.2728 95.426 62.9042C93.7661 62.6259 92.1893 63.7317 91.9026 65.3866C91.6158 67.0416 92.7249 68.6138 94.3848 68.8997C95.4033 69.0727 96.52 69.4263 97.5913 69.9227C98.3911 70.2914 99.1682 70.7352 99.8623 71.2317C100.903 71.9689 101.756 72.8189 102.284 73.6088C102.548 74 102.737 74.3761 102.858 74.7221C102.978 75.0607 103.024 75.3616 103.024 75.6474C103.024 75.9258 102.978 76.189 102.865 76.4975C102.692 76.9563 102.345 77.5281 101.62 78.2126C100.903 78.8896 99.8095 79.6494 98.2704 80.4092C96.7614 81.1539 96.1427 82.9744 96.8821 84.4789C97.6291 85.9834 99.4549 86.6003 100.964 85.8631C103.574 84.5767 105.559 83.1173 106.962 81.3947C107.664 80.5371 108.207 79.6043 108.577 78.6264C108.946 77.6484 109.12 76.6329 109.12 75.6474C109.112 74.7372 108.969 73.8646 108.712 73.0371Z"
      fill="#C17F50"
    />
    <path
      d="M103.584 86.5026C104.451 83.4053 102.635 80.194 99.5284 79.3299C96.4219 78.4659 93.2011 80.2763 92.3345 83.3736C91.4679 86.4709 93.2837 89.6823 96.3902 90.5463C99.4966 91.4103 102.717 89.5999 103.584 86.5026Z"
      fill="#C17F50"
    />
  </g>
);

const FlagLeftHand = () => (
  <g
    style={{
      transformOrigin: '91.86px 62.86px',
    }}
  >
    <path
      d="M131.066 21.0719C130.951 21.0005 130.819 20.9608 130.684 20.957C130.548 20.9532 130.414 20.9854 130.295 21.0503L118.961 27.2307C118.872 27.2781 118.794 27.3429 118.731 27.4211C118.668 27.4993 118.621 27.5894 118.593 27.686C118.564 27.7825 118.555 27.8837 118.566 27.9837C118.577 28.0838 118.607 28.1807 118.656 28.2689L121.924 34.2638L115.262 37.8968C115.174 37.9443 115.096 38.009 115.032 38.0872C114.969 38.1654 114.922 38.2554 114.894 38.352C114.866 38.4485 114.857 38.5497 114.868 38.6497C114.878 38.7498 114.909 38.8467 114.957 38.9349L118.593 45.6021C118.641 45.6904 118.706 45.7683 118.784 45.8315C118.862 45.8946 118.952 45.9417 119.049 45.9701C119.145 45.9984 119.246 46.0075 119.346 45.9967C119.446 45.986 119.543 45.9556 119.631 45.9073L138.299 35.7283C138.324 35.7147 138.349 35.6995 138.373 35.6827C138.477 35.609 138.562 35.5102 138.619 35.3953C138.675 35.2805 138.702 35.1532 138.697 35.0253C138.692 34.8973 138.654 34.7727 138.589 34.6629C138.523 34.5531 138.43 34.4616 138.32 34.3967L131.08 30.1437L131.427 21.7539C131.433 21.6184 131.402 21.484 131.339 21.3642C131.275 21.2444 131.181 21.1435 131.066 21.0719V21.0719Z"
      fill="#3F4694"
    />
    <path
      d="M123.458 33.9853C123.34 33.8854 123.194 33.8235 123.041 33.8078C122.887 33.7922 122.732 33.8234 122.596 33.8976L115.262 37.8961C115.174 37.9436 115.096 38.0083 115.032 38.0865C114.969 38.1647 114.922 38.2548 114.895 38.3514C114.866 38.4478 114.857 38.549 114.868 38.6491C114.878 38.7491 114.909 38.846 114.957 38.9342L118.593 45.6014C118.664 45.7314 118.771 45.838 118.902 45.9083C119.032 45.9785 119.18 46.0094 119.328 45.9972C119.475 45.985 119.616 45.9302 119.733 45.8394C119.85 45.7487 119.939 45.6259 119.987 45.4859L123.686 34.8202C123.737 34.6743 123.742 34.5164 123.701 34.3674C123.661 34.2183 123.576 34.0851 123.458 33.9853V33.9853Z"
      fill="#36A6B2"
    />
    <path
      d="M115.909 20.5001C115.812 20.4716 115.711 20.4624 115.611 20.4732C115.511 20.4839 115.414 20.5144 115.326 20.5628L97.9909 30.0153C97.9026 30.0633 97.8247 30.1283 97.7615 30.2065C97.6984 30.2847 97.6513 30.3746 97.6229 30.471C97.5946 30.5674 97.5855 30.6685 97.5963 30.7685C97.6071 30.8684 97.6375 30.9652 97.6857 31.0534L104.957 44.3869C105.005 44.4751 105.07 44.5531 105.148 44.6162C105.226 44.6794 105.316 44.7265 105.412 44.7548C105.509 44.7832 105.61 44.7923 105.71 44.7815C105.81 44.7707 105.907 44.7403 105.995 44.6921L123.329 35.2409C123.355 35.2273 123.379 35.212 123.403 35.1952C123.557 35.0872 123.666 34.9263 123.708 34.7432C123.751 34.5601 123.725 34.3677 123.634 34.2028L116.364 20.868C116.316 20.7797 116.251 20.7018 116.173 20.6386C116.095 20.5755 116.005 20.5284 115.909 20.5001V20.5001Z"
      fill="#398984"
    />
    <rect
      x="93.055"
      y="28.8229"
      width="4.59245"
      height="42.8628"
      rx="2.29622"
      transform="rotate(-28.4426 93.055 28.8229)"
      fill="#1ABC9C"
    />
    <path
      d="M116.703 71.2545C115.769 72.402 114.614 73.2848 113.364 73.9694C111.49 74.9929 109.389 75.5895 107.253 75.8602C105.119 76.124 102.943 76.0591 100.886 75.6194C99.2745 75.2635 98.2483 73.6752 98.5978 72.0598C98.9472 70.4444 100.535 69.4172 102.15 69.7662C103.14 69.9825 104.286 70.0701 105.443 70.0106C106.306 69.9681 107.176 69.8486 107.99 69.6506C109.209 69.3604 110.297 68.898 111.069 68.3713C111.453 68.1113 111.763 67.8374 112.001 67.566C112.235 67.3015 112.387 67.0436 112.493 66.7827C112.595 66.5288 112.651 66.2719 112.662 65.9489C112.673 65.4664 112.569 64.8172 112.162 63.9265C111.76 63.0454 111.046 61.9503 109.926 60.6915C108.829 59.4576 108.937 57.5692 110.164 56.468C111.398 55.3696 113.286 55.4775 114.386 56.7046C116.285 58.8374 117.552 60.898 118.193 62.9854C118.514 64.0257 118.664 65.0764 118.64 66.1046C118.616 67.1327 118.399 68.1231 118.036 69.0223C117.693 69.8501 117.241 70.5937 116.703 71.2545Z"
      fill="#C17F50"
    />
    <path
      d="M117.004 57.0836C116.65 60.2282 113.815 62.4913 110.672 62.1385C107.529 61.7857 105.268 58.9505 105.622 55.8059C105.976 52.6613 108.811 50.3982 111.954 50.751C115.097 51.1038 117.358 53.939 117.004 57.0836Z"
      fill="#C17F50"
    />
  </g>
);

const LeftEar = ({ rotation = 0 }) => (
  <g
    style={{
      transformOrigin: '85.79px 8.01px',
      transform: `rotate(${rotation}deg)`,
    }}
  >
    <path
      d="M82.7503 14.3239C83.7573 13.4958 88.2611 8.90559 95.9085 9.44284C103.565 9.9846 112.42 24.6579 109.802 34.5681C107.181 44.4879 90.6664 24.8995 88.8266 22.702C86.9868 20.5045 81.1322 15.6514 82.7503 14.3239Z"
      fill="#C17F50"
    />
  </g>
);

const Snoot = () => (
  <>
    <path
      d="M27.3218 45.75C27.3682 45.7731 28.8065 46.4744 31.0955 47.1833C33.3845 47.8846 36.5396 48.5935 40.0349 48.5935C41.6125 48.5935 43.2596 48.4471 44.9222 48.0849C46.1672 47.8152 47.4277 47.4222 48.6728 46.8828C50.5364 46.0736 52.3614 44.9177 54.0627 43.3534C55.764 41.7814 57.326 39.8009 58.6871 37.335C59.0428 36.6954 58.8031 35.8939 58.1612 35.5395C57.5194 35.185 56.7151 35.4239 56.3594 36.0635C55.2613 38.0593 54.0395 39.6622 52.7403 40.9491C51.766 41.9201 50.7529 42.7061 49.7167 43.3534C48.1546 44.3244 46.5307 44.9717 44.899 45.3801C43.2673 45.7885 41.6202 45.9503 40.0349 45.9503C36.903 45.9503 33.9954 45.303 31.8843 44.6557C30.8248 44.3321 29.9742 44.0084 29.3865 43.7695C29.0926 43.6462 28.8684 43.5461 28.7215 43.4844C28.6441 43.4536 28.59 43.4228 28.5591 43.4073C28.5436 43.3996 28.5281 43.3919 28.5204 43.3919H28.5127L28.3193 43.7772L28.5127 43.3919L28.3193 43.7772L28.5127 43.3919L28.1569 44.1163L28.5127 43.3919L28.1569 44.1163L28.5127 43.3919L27.9327 44.5555L28.5127 43.3919L27.9327 44.5555L28.5127 43.3919C27.8554 43.0683 27.0588 43.338 26.7341 43.993C26.3938 44.6326 26.6645 45.4263 27.3218 45.75Z"
      fill="#C17F50"
    />

    <path
      d="M25.2324 43.3334C25.6129 43.4108 26.0088 43.4881 26.4126 43.55H26.397C26.397 43.5578 26.397 43.5732 26.397 43.581H40.6749V22.2351C40.6749 22.2351 12.2977 22.6065 12.4996 31.9758C12.5462 34.4128 14.0136 40.9814 25.2324 43.3334Z"
      fill="#E3A263"
    />
    <path
      d="M36.249 31.4187C36.2878 34.2504 31.6062 39.7358 25.76 39.8054C19.906 39.8751 15.1001 34.5134 15.0613 31.6818C15.0225 28.8501 19.7429 26.8385 25.5969 26.7689C31.4509 26.6993 36.218 28.587 36.249 31.4187Z"
      fill="#3B1C0C"
    />
    <path
      d="M25.8144 29.0435C25.8222 29.8017 24.479 30.4284 22.802 30.4516C21.1328 30.4748 19.7663 29.8791 19.7586 29.1209C19.7508 28.3627 21.094 27.736 22.771 27.7128C24.448 27.6896 25.8067 28.2853 25.8144 29.0435Z"
      fill="#6C442F"
    />
  </>
);

const WavingRightHand = () => (
  <g
    style={{
      transformOrigin: '29.76px 62.864px',
      transform: 'translateX(4px)',
    }}
  >
    <path
      d="M6.59912 40.9753C7.642 40.2772 9.05295 40.5676 9.75565 41.6175C9.80584 41.6956 10.6758 42.9577 12.137 44.4208C12.8676 45.1524 13.7487 45.9342 14.7358 46.649C15.7229 47.3638 16.8216 48.006 17.9927 48.4751C19.0802 48.9107 20.2291 49.2011 21.4337 49.2793C22.6885 49.3631 23.6421 50.4464 23.5641 51.7029C23.486 52.9594 22.4096 53.9144 21.1548 53.8306C19.4093 53.7189 17.7864 53.2945 16.3141 52.7081C14.6801 52.0492 13.2301 51.1836 11.9697 50.2677C10.0791 48.8884 8.6068 47.3862 7.5918 46.2134C6.57681 45.0407 6.01354 44.2031 5.96893 44.1416C5.27739 43.0862 5.55623 41.6733 6.59912 40.9753Z"
      fill="#C17F50"
    />
    <path
      d="M9.98845 46.0986C12.0618 44.8771 12.7498 42.1975 11.5253 40.1134C10.3008 38.0293 7.62738 37.33 5.55406 38.5515C3.48075 39.773 2.79267 42.4527 4.01719 44.5367C5.24172 46.6208 7.91514 47.3201 9.98845 46.0986Z"
      fill="#C17F50"
    />
  </g>
);

const RightHand = () => {
  return (
    <g style={{ transformOrigin: '29.76px 62.864px' }}>
      <path
        d="M12.9058 73.0371C13.3509 71.6003 14.1054 70.329 15.0259 69.2081C16.4066 67.5306 18.1645 66.169 20.0809 65.1008C21.9973 64.0401 24.0796 63.2728 26.1922 62.9042C27.852 62.6259 29.4289 63.7317 29.7156 65.3866C30.0023 67.0416 28.8932 68.6138 27.2334 68.8997C26.2148 69.0727 25.0982 69.4263 24.0268 69.9227C23.2271 70.2914 22.45 70.7352 21.7558 71.2317C20.7147 71.9689 19.8621 72.8189 19.334 73.6088C19.0699 74 18.8813 74.3761 18.7606 74.7221C18.6398 75.0607 18.5946 75.3616 18.5946 75.6474C18.5946 75.9258 18.6398 76.189 18.753 76.4975C18.9265 76.9563 19.2736 77.5281 19.9979 78.2126C20.7147 78.8896 21.8087 79.6494 23.3478 80.4092C24.8568 81.1539 25.4754 82.9744 24.736 84.4789C23.9891 85.9834 22.1633 86.6003 20.6543 85.8631C18.0438 84.5767 16.0595 83.1173 14.6562 81.3947C13.9545 80.5371 13.4113 79.6043 13.0416 78.6264C12.6719 77.6484 12.4984 76.6329 12.4984 75.6474C12.5059 74.7372 12.6493 73.8646 12.9058 73.0371Z"
        fill="#C17F50"
      />
      <path
        d="M18.0341 86.5026C17.1675 83.4053 18.9833 80.194 22.0898 79.3299C25.1962 78.4659 28.4171 80.2763 29.2837 83.3736C30.1503 86.4709 28.3345 89.6823 25.228 90.5463C22.1215 91.4103 18.9007 89.5999 18.0341 86.5026Z"
        fill="#C17F50"
      />
    </g>
  );
};

const RightEar = () => (
  <path
    d="M42.1097 9.99587C40.8828 9.55099 35.0978 6.72603 28.0711 9.78084C21.0369 12.8431 17.6165 29.6223 23.4089 38.0897C29.2087 46.5646 38.1985 22.6007 39.1949 19.9166C40.1913 17.2325 44.0801 10.7077 42.1097 9.99587Z"
    fill="#C17F50"
  />
);

const LeftFoot = () => (
  <path
    d="M76.6541 113.651L76.5924 132.519C76.5924 132.519 89.0105 130.227 87.4303 136.58C86.0274 142.211 69.8631 142.388 69.1616 138.196C68.0131 131.373 68.6991 114.728 68.6991 114.728L76.6541 113.651Z"
    fill="#C17F50"
  />
);

const RightFoot = () => (
  <path
    d="M53.3675 113.751L52.6506 132.658C52.6506 132.658 40.3636 131.081 41.6817 137.249C42.8534 142.718 58.9868 141.718 59.8656 137.419C61.2993 130.435 61.2993 113.828 61.2993 113.828L53.3675 113.751Z"
    fill="#C17F50"
  />
);

const Tail = ({ rotation = 0, translateX = 0, translateY = 0 }) => (
  <g
    style={{
      transformOrigin: '86.15px 91.59px',
      transform: `rotate(${rotation}deg) translateX(${translateX}px) translateY(${translateY}px)`,
    }}
  >
    <path
      d="M97.2987 108.733C106.168 109.815 122.707 103.94 123.406 89.1998C123.519 86.7703 120.35 86.5997 119.069 88.6697C116.476 92.8422 110.155 101.034 99.1524 99.6083C81.6972 97.3464 86.0565 107.361 97.2987 108.733Z"
      fill="#C17F50"
    />
  </g>
);

const Body = () => (
  <path
    d="M88.3369 11.7208C79.486 2.88535 44.6105 2.14262 35.5189 12.3784C33.6245 14.5138 30.6509 17.446 29.137 21.7941C27.196 27.3646 26.1556 43.403 26.5593 43.4727H26.5438C26.2876 57.925 26.8932 105.398 36.0546 114.056C44.8822 122.404 81.6599 123.603 92.84 114.419C107.817 102.118 95.5651 18.9392 88.3369 11.7208Z"
    fill="#E3A263"
  />
);
