import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';
import { useRef } from 'react';

export type Theme = 'light' | 'dark' | 'twilight' | 'transparent';

type Props = {
  animateClose?: boolean | undefined;
  children?: ReactNode | undefined;
  onClick?: () => void | undefined;
  theme?: Theme | undefined;
};

export const exitAnimationDuration = 300; // milliseconds

const overlayEnterKeyframes = {
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
};

const overlayExitKeyframes = {
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
};

const styles = StyleSheet.create({
  overlay: {
    height: '100vh',
    position: 'fixed',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    animationName: overlayEnterKeyframes,
    animationDuration: '300ms',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
    overflow: 'hidden',
  },
  light: {
    backgroundColor: 'rgba(255, 255, 255, 0.87)',
  },
  dark: {
    backgroundColor: 'rgba(74, 74, 74, 0.8)',
  },
  // new and more vivid looking
  // overlay color from the designs
  twilight: {
    backgroundColor: 'rgba(62, 62, 76, 0.8)',
  },
  transparent: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  overlayExit: {
    animationName: overlayExitKeyframes,
    animationDuration: `${exitAnimationDuration}ms`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-out',
  },
});

const Overlay = ({
  animateClose,
  children,
  onClick,
  theme = 'light',
}: Props) => {
  // ref to node
  const root = useRef<HTMLDivElement | null>(null);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={root}
      className={css(
        styles.overlay,
        theme === 'light' && styles.light,
        theme === 'dark' && styles.dark,
        theme === 'twilight' && styles.twilight,
        theme === 'transparent' && styles.transparent,
        Boolean(animateClose) && styles.overlayExit,
      )}
      onClick={event => {
        if (event.target === root.current && onClick) onClick();
      }}
    >
      {children}
    </div>
  );
};

export default Overlay;
