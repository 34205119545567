import { useContext } from 'react';

import { InvariantViolation } from 'ms-utils/app-logging';

import { ViewerContext, type ViewerPayload } from '../ViewerProvider';

export type Props = {
  children: (p: ViewerPayload) => JSX.Element;
};

export default function Renderer({ children }: Props) {
  const payload = useContext(ViewerContext);
  if (payload == null) return null;
  return children(payload);
}

export function useViewer(): ViewerPayload {
  const viewerContext = useContext(ViewerContext);
  if (viewerContext == null) {
    throw new InvariantViolation(
      'useViewer context must be used within a Provider',
    );
  }
  return viewerContext;
}

// It fails gracefully in not authenticated views
export function useMaybeViewer(): ViewerPayload | null | undefined {
  const viewerContext = useContext(ViewerContext);
  return viewerContext;
}
