import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g>
    <path
      d="M3,11 C2.44771525,11 2,10.5522847 2,10 C2,9.44771525 2.44771525,9 3,9 L17,9 C17.5522847,9 18,9.44771525 18,10 C18,10.5522847 17.5522847,11 17,11 L3,11 Z"
      transform="translate(10.000000, 10.000000) rotate(-180.000000) translate(-10.000000, -10.000000)"
    />
    <path
      d="M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 L17,4 C17.5522847,4 18,4.44771525 18,5 C18,5.55228475 17.5522847,6 17,6 L3,6 Z"
      transform="translate(10.000000, 5.000000) rotate(-180.000000) translate(-10.000000, -5.000000)"
    />
    <path
      d="M3,16 C2.44771525,16 2,15.5522847 2,15 C2,14.4477153 2.44771525,14 3,14 L17,14 C17.5522847,14 18,14.4477153 18,15 C18,15.5522847 17.5522847,16 17,16 L3,16 Z"
      transform="translate(10.000000, 15.000000) rotate(-180.000000) translate(-10.000000, -15.000000)"
    />
  </g>
);

const Hamburger = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Hamburger.displayName = 'Hamburger';

export default Hamburger;
