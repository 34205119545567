import { useFragment, graphql } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import type { TrackStructEvent } from 'ms-helpers/Snowplow/Types';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import type { FeatureFlags } from 'ms-helpers/Viewer/ViewerProvider';
import type { MenuItem } from 'ms-ui-primitives/DropdownMenu';
import { isNotNull } from 'ms-utils/typescript-utils';

import type {
  jsxPrintKebabLinks_subtopic$key,
  jsxPrintKebabLinks_subtopic$data,
} from './__generated__/jsxPrintKebabLinks_subtopic.graphql';

const fragment = graphql`
  fragment jsxPrintKebabLinks_subtopic on Subtopic {
    id
    answerKeyJsx {
      pdfAvailable
    }
    worksheetJsx {
      pdfAvailable
    }
  }
`;
function createUrlLink(
  subtopicId: string,
  contentType: 'AnswerKey' | 'Worksheet',
) {
  return `/jsx_pdf/subtopic/${subtopicId}/document_type/${contentType}/`;
}
export function useJsxPrintKebabLinks(
  fragmentRef: jsxPrintKebabLinks_subtopic$key,
): ReadonlyArray<MenuItem> {
  const viewer = useViewer();
  const { trackStructEvent } = useSnowplow();
  const response = useFragment(fragment, fragmentRef);
  return jsxPrintKebabLinks(
    response,
    viewer.featureFlags,
    trackStructEvent,
    viewer.role,
  );
}
export function jsxPrintKebabLinks(
  {
    worksheetJsx,
    answerKeyJsx,
    id: subtopicId,
  }: jsxPrintKebabLinks_subtopic$data,
  featureFlags: Partial<FeatureFlags>,
  trackStructEvent: TrackStructEvent,
  role: 'Teacher' | 'Student' | 'Other',
): ReadonlyArray<MenuItem> {
  const canDownloadWorksheet =
    featureFlags.printableWorksheets && role === 'Teacher';
  const canDownloadAnswerKey =
    featureFlags.worksheetAnswerKeys && role === 'Teacher';
  return [
    worksheetJsx.pdfAvailable && canDownloadWorksheet
      ? {
          key: 'worksheet',
          label: 'Download worksheet',
          dataTestId: 'request-resource-handler-worksheet',
          action: () => {
            trackStructEvent({
              category: 'jsx_worksheet',
              action: 'clicked_worksheet_pdf_link',
            });
            window.open(createUrlLink(subtopicId, 'Worksheet'), '_blank');
          },
        }
      : null,
    answerKeyJsx.pdfAvailable && canDownloadAnswerKey
      ? {
          key: 'answer-key',
          label: 'Download answer key',
          dataTestId: 'request-resource-handler-answerkey',
          action: () => {
            trackStructEvent({
              category: 'jsx_worksheet',
              action: 'clicked_answerkey_pdf_link',
            });
            window.open(createUrlLink(subtopicId, 'AnswerKey'), '_blank');
          },
        }
      : null,
  ].filter(isNotNull);
}
