import { PERMALINK_URL } from 'ms-utils/urls';

export const floridaSyllabusIds = [
  'Syllabus-1044',
  'Syllabus-1052',
  'Syllabus-1056',
];
export const tennesseeSyllabusIds = [
  'Syllabus-1097',
  'Syllabus-1098',
  'Syllabus-1099',
  'Syllabus-1110',
  'Syllabus-1111',
  'Syllabus-1112',
];
export const ccagaSyllabusIds = [
  'Syllabus-1139',
  'Syllabus-1140',
  'Syllabus-1141',
];
export const ccssSyllabusIds = [
  'Syllabus-1167',
  'Syllabus-1168',
  'Syllabus-1169',
];

export type GuideLink = {
  title: string;
  link: string;
  thumbnailUrl: string;
};

type TextbookMaterial = {
  [key: string]: Array<GuideLink>;
};

const THUMBNAIL_URL = `${PERMALINK_URL}/pdfs/textbooks/thumbnails`;
const THUMBNAIL_TEAM_BIOS = `${THUMBNAIL_URL}/team-bios.svg`;
const THUMBNAIL_SCOPE_AND_SEQUENCE = `${THUMBNAIL_URL}/scope-and-sequence.svg`;
const THUMBNAIL_ABOUT_MATHSPACE = `${THUMBNAIL_URL}/about-mathspace.svg`;
const THUMBNAIL_SUPPORTING_EVERY_STUDENT = `${THUMBNAIL_URL}/supporting-every-student.svg`;
const THUMBNAIL_CORRELATIONS_AND_ALIGNMENT = `${THUMBNAIL_URL}/correlations-and-alignment.svg`;
const THUMBNAIL_INSTRUCTIONAL_APPROACH = `${THUMBNAIL_URL}/instructional-approach.svg`;
const THUMBNAIL_CURRICULUM_MAP = `${THUMBNAIL_URL}/curriculum-map.svg`;
const THUMBNAIL_LESSON_STRUCTURE = `${THUMBNAIL_URL}/lesson-structure.svg`;
const THUMBNAIL_RESEARCH_BASIS = `${THUMBNAIL_URL}/research-basis.svg`;
const THUMBNAIL_PROFESSIONAL_DEVELOPMENT = `${THUMBNAIL_URL}/professional-development-options.svg`;

export const textbookMaterials: TextbookMaterial = {
  'Syllabus-1044': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1044/Algebra 1 - Correlations & Alignment.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1044/Algebra 1 - Scope and Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Coherence Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1044/Algebra 1 - Coherence map.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1052': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1052/Geometry - Correlations & Alignment.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1052/Geometry - Scope and Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Coherence Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1052/Geometry - Coherence map.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1056': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1056/Algebra 2 - Correlations & Alignment.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1056/Algebra 2 - Scope and Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Coherence Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1056/Algebra 2 - Coherence map.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1097': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1097/Algebra I - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1097/Algebra I - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1097/Curriculum Map - Algebra I.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1098': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1098/Geometry - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1098/Geometry - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1098/Curriculum Map - Geometry.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1099': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1099/Algebra II - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1099/Algebra II - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1099/Curriculum Map - Algebra II.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1110': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1110/Math I - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1110/Math I - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1110/Curriculum Map - Math I.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1111': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1111/Math II - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1111/Math II - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1111/Curriculum Map - Math II.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1112': [
    {
      title: 'Correlations and Alignment Documents',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1112/Math III - Correlations & Alignments.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1112/Math III - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1112/Curriculum Map - Math III.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1139': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1139/Scope and Sequence - Mathspace Algebra 1.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Correlations and Alignment',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1139/Correlations and Alignment - Mathspace Algebra 1.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1139/Curriculum Map - Mathspace Algebra 1.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1140': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1140/Scope and Sequence - Mathspace Algebra 2.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Correlations and Alignment',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1140/Correlations and Alignment - Mathspace Algebra 2.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1140/Curriculum Map - Mathspace Algebra 2.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1141': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1141/Scope and Sequence - Mathspace Geometry.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Correlations and Alignment',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1141/Correlations and Alignment - Mathspace Geometry.pdf`,
      thumbnailUrl: THUMBNAIL_CORRELATIONS_AND_ALIGNMENT,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1141/Curriculum Map - Mathspace Geometry.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1167': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1167/Math I - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1167/Curriculum Map - Math I.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1168': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1168/Math II - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1168/Curriculum Map - Math II.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
  'Syllabus-1169': [
    {
      title: 'Scope and Sequence',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1169/Math III - Scope & Sequence.pdf`,
      thumbnailUrl: THUMBNAIL_SCOPE_AND_SEQUENCE,
    },
    {
      title: 'Curriculum Map',
      link: `${PERMALINK_URL}/pdfs/textbooks/syllabus/1169/Curriculum Map - Math III.pdf`,
      thumbnailUrl: THUMBNAIL_CURRICULUM_MAP,
    },
  ],
};

export const getGeneralMaterials = (syllabusId: string): Array<GuideLink> => {
  if (floridaSyllabusIds.includes(syllabusId)) {
    return [
      {
        title: 'About Mathspace',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/About mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_ABOUT_MATHSPACE,
      },
      {
        title: 'Mathspace lesson structure',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/Mathspace lesson structure.pdf`,
        thumbnailUrl: THUMBNAIL_LESSON_STRUCTURE,
      },
      {
        title: 'Supporting every student',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/Supporting every student.pdf`,
        thumbnailUrl: THUMBNAIL_SUPPORTING_EVERY_STUDENT,
      },
      {
        title: 'Research supporting instructional approach',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/Research Support for Instructional Approach.pdf`,
        thumbnailUrl: THUMBNAIL_INSTRUCTIONAL_APPROACH,
      },
      {
        title: 'Professional development options',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/Implementation support.pdf`,
        thumbnailUrl: THUMBNAIL_PROFESSIONAL_DEVELOPMENT,
      },
      {
        title: 'Authoring team bios',
        link: `${PERMALINK_URL}/pdfs/textbooks/florida/materials_guide/Authoring team bios.pdf`,
        thumbnailUrl: THUMBNAIL_TEAM_BIOS,
      },
    ];
  } else if (tennesseeSyllabusIds.includes(syllabusId)) {
    return [
      {
        title: 'About Mathspace',
        link: `${PERMALINK_URL}/pdfs/textbooks/tennessee/materials_guide/About mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_ABOUT_MATHSPACE,
      },
      {
        title: 'Mathspace lesson structure',
        link: `${PERMALINK_URL}/pdfs/textbooks/tennessee/materials_guide/Mathspace lesson structure.pdf`,
        thumbnailUrl: THUMBNAIL_LESSON_STRUCTURE,
      },
      {
        title: 'Supporting every student',
        link: `${PERMALINK_URL}/pdfs/textbooks/tennessee/materials_guide/Supporting every student.pdf`,
        thumbnailUrl: THUMBNAIL_SUPPORTING_EVERY_STUDENT,
      },
      {
        title: 'Research supporting instructional approach',
        link: `${PERMALINK_URL}/pdfs/textbooks/tennessee/materials_guide/Research Support for Instructional Approach.pdf`,
        thumbnailUrl: THUMBNAIL_INSTRUCTIONAL_APPROACH,
      },
      {
        title: 'Authoring team bios',
        link: `${PERMALINK_URL}/pdfs/textbooks/tennessee/materials_guide/Authoring team bios.pdf`,
        thumbnailUrl: THUMBNAIL_TEAM_BIOS,
      },
    ];
  } else if (ccagaSyllabusIds.includes(syllabusId)) {
    return [
      {
        title: 'About Mathspace',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccaga/materials_guide/About Mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_ABOUT_MATHSPACE,
      },
      {
        title: 'Instructional Model',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccaga/materials_guide/Mathspace Instructional Model.pdf`,
        thumbnailUrl: THUMBNAIL_INSTRUCTIONAL_APPROACH,
      },
      {
        title: 'Supporting Every Student',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccaga/materials_guide/Supporting Every Student with Mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_SUPPORTING_EVERY_STUDENT,
      },
      {
        title: 'Research Basis',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccaga/materials_guide/Mathspace Research Basis.pdf`,
        thumbnailUrl: THUMBNAIL_RESEARCH_BASIS,
      },
      {
        title: 'Authoring Team Bios',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccaga/materials_guide/Mathspace Authoring Team Bios.pdf`,
        thumbnailUrl: THUMBNAIL_TEAM_BIOS,
      },
    ];
  } else if (ccssSyllabusIds.includes(syllabusId)) {
    return [
      {
        title: 'About Mathspace',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccss/materials_guide/About Mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_ABOUT_MATHSPACE,
      },
      {
        title: 'Instructional Model',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccss/materials_guide/Mathspace Instructional Model.pdf`,
        thumbnailUrl: THUMBNAIL_INSTRUCTIONAL_APPROACH,
      },
      {
        title: 'Supporting Every Student',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccss/materials_guide/Supporting Every Student with Mathspace.pdf`,
        thumbnailUrl: THUMBNAIL_SUPPORTING_EVERY_STUDENT,
      },
      {
        title: 'Research Basis',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccss/materials_guide/Mathspace Research Basis.pdf`,
        thumbnailUrl: THUMBNAIL_RESEARCH_BASIS,
      },
      {
        title: 'Authoring Team Bios',
        link: `${PERMALINK_URL}/pdfs/textbooks/ccss/materials_guide/Mathspace Authoring Team Bios.pdf`,
        thumbnailUrl: THUMBNAIL_TEAM_BIOS,
      },
    ];
  } else {
    return [];
  }
};

export const getProductVisionId = (
  syllabusId: string,
): string | null | undefined => {
  if (floridaSyllabusIds.includes(syllabusId)) return 'j0zyzssnbf';
  else if (tennesseeSyllabusIds.includes(syllabusId)) return 'vrrms6croa';
  return null;
};
