import styled from '@emotion/styled';
import { range } from 'ramda';
import { useEffect } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { tappable } from 'ms-utils/emotion';

const DOT_SIZE = BASE_UNIT * 2;
const PaginationDot = styled.div({
  height: DOT_SIZE,
  width: DOT_SIZE,
  borderRadius: '50%',
});

const DotTappableArea = styled.div({
  ...tappable,
  paddingLeft: DOT_SIZE / 2,
  paddingRight: DOT_SIZE / 2,
  paddingTop: DOT_SIZE / 2,
  paddingBottom: DOT_SIZE / 2,
  marginLeft: DOT_SIZE / 4,
  marginRight: DOT_SIZE / 4,
});

const PaginationElement = styled.div({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
});

type PaginationProps = {
  // unique key for each pagination element
  // useful to identify the pagination dot in the DOM
  // and feed it to `scrollIntoView`

  paginationId: string;
  activePage: number;
  numberOfPages: number;
  onSelect: (value: number) => void;
  activeColor?: string | undefined;
  inactiveColor?: string | undefined;
};

export default function Pagination({
  paginationId,
  numberOfPages,
  activePage,
  onSelect,
  activeColor = colors.cloudBurst,
  inactiveColor = colors.iron,
}: PaginationProps) {
  useEffect(() => {
    const activeDot = window?.document?.querySelector(
      `[data-id=${getDataId(paginationId, activePage)}]`,
    );

    if (activeDot != null)
      scrollIntoView(activeDot, {
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'center',
        // skipOverflowHiddenElements: true,
        // boundary: parent => {
        //   // This is to make sure that we don't scroll the body
        //   if (document && document.body) {
        //     return parent !== document.body;
        //   }
        //   return true;
      });
  }, [activePage, paginationId]);
  return (
    <PaginationElement>
      {range(0, numberOfPages).map(dot => (
        <DotTappableArea key={dot} onClick={() => onSelect(dot)}>
          <PaginationDot
            data-id={getDataId(paginationId, dot)}
            style={{
              backgroundColor: dot === activePage ? activeColor : inactiveColor,
            }}
          />
        </DotTappableArea>
      ))}
    </PaginationElement>
  );
}

function getDataId(paginationId: string, activePage: number) {
  return `${paginationId}-${activePage}`;
}
