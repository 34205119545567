import { StyleSheet, css } from 'aphrodite';
import { useState, useEffect, useRef, useCallback } from 'react';

import {
  fontFamily,
  fontSize,
  borderRadiusUI,
  transition,
  lineHeight,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const styles = StyleSheet.create({
  root: {
    width: '100%',
  },
  input: {
    boxSizing: 'border-box', // force a consistent way to calculate height
    color: colors.mako,
    fontFamily: fontFamily.body,
    fontSize: fontSize.input,
    lineHeight: lineHeight.body,
    borderRadius: borderRadiusUI,
    border: `1px solid ${colors.iron}`,
    padding: '8px 12px',
    width: '100%',
    transition: `border-color ${transition}`,
    resize: 'none',
    ':focus': {
      outline: 'none',
      borderColor: colors.matisse,
    },
    ':disabled': {
      background: colors.athensGray,
      color: colors.iron,
    },
  },
  error: {
    border: `1px solid ${colors.cinnabar}`,
    ':focus': {
      border: `1px solid ${colors.cinnabar}`,
    },
  },
  errorMessage: {
    textAlign: 'right',
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    marginTop: 8,
  },
  isCode: {
    fontFamily: 'monospace',
  },
});

type Props = {
  id?: string;
  value: string;
  onChange: (value: string) => void;
  // If we want to show error state without an errorMessage
  showErrorState?: boolean;
  errorMessage?: string;
  isCode?: boolean;
  minHeight?: number;
  autoResize?: boolean;
  isMultiline?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  rows?: number;
};

export default function TextArea(props: Props) {
  const {
    value,
    onChange,
    showErrorState,
    errorMessage,
    isCode,
    minHeight = 0,
    autoResize = true,
    isMultiline = false,
    autoFocus,
    maxLength,
    disabled,
    placeholder,
    onFocus,
    onBlur,
    rows = 1,
    ...rest
  } = props;
  const [height, setHeight] = useState<number | string>(minHeight || 'auto');
  const ref = useRef<HTMLTextAreaElement | null>(null);

  const updateHeight = useCallback(() => {
    if (ref.current === null) return;

    const textAreaDOM = ref.current;
    const textAreaStyle = ref.current.style;

    const cs = getComputedStyle(textAreaDOM);
    const borderTopWidth = parseInt(cs.borderTopWidth, 10);
    const borderBottomWidth = parseInt(cs.borderBottomWidth, 10);

    const curHeight = textAreaStyle.height;
    textAreaStyle.height = '0px'; // make scrollHeight shrink to actual content
    textAreaStyle.overflowY = 'hidden'; // prevent scrollbar from affecting when text wraps
    const contentHeight =
      textAreaDOM.scrollHeight + borderTopWidth + borderBottomWidth;
    textAreaStyle.height = curHeight;
    textAreaStyle.overflowY = 'auto';

    setHeight(Math.max(minHeight, contentHeight));
  }, [minHeight]);

  const handleResize = useCallback(() => {
    if (autoResize) updateHeight();
  }, [autoResize, updateHeight]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Ensure aphrodite styles have flushed to DOM
    // see https://github.com/Khan/aphrodite#style-injection-and-buffering
    setTimeout(() => {
      if (autoResize) {
        updateHeight();
      }
    });

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, updateHeight, autoResize]);

  return (
    <div className={css(styles.root)}>
      <textarea
        {...rest}
        ref={ref}
        value={value}
        autoFocus={autoFocus}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        rows={rows}
        style={{ height }}
        className={css(
          styles.input,
          Boolean(isCode) && styles.isCode,
          (Boolean(showErrorState) || Boolean(errorMessage)) && styles.error,
        )}
        onChange={event => {
          if (autoResize) updateHeight();
          onChange(event.target.value);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && !isMultiline) event.preventDefault();
        }}
      />
      {errorMessage && (
        <div className={css(styles.errorMessage)}>{errorMessage}</div>
      )}
    </div>
  );
}
