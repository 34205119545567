export default function MiloSearch({
  size = 105,
}: {
  size?: number | undefined;
}) {
  const width = size;
  const height = size * (107 / 103); // keeping the aspect ratio of the original svg
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 103 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="48" cy="105" fill="#3E3E4C" opacity=".1" rx="28" ry="2" />
      <path
        fill="#C17F50"
        d="M5.081 41.407a2.27 2.27 0 0 1 3.148.641c.05.078.918 1.337 2.375 2.796.728.73 1.607 1.509 2.592 2.222.984.713 2.08 1.353 3.248 1.82 1.084.435 2.23.725 3.431.803A2.282 2.282 0 0 1 22 52.106a2.259 2.259 0 0 1-2.403 2.122c-1.74-.112-3.36-.535-4.828-1.12-1.629-.657-3.075-1.52-4.332-2.433a26.524 26.524 0 0 1-4.366-4.044c-1.012-1.17-1.574-2.005-1.618-2.066a2.288 2.288 0 0 1 .628-3.157Z"
      />
      <path
        fill="#C17F50"
        d="M8.166 46.517c2.068-1.218 2.754-3.89 1.533-5.969-1.222-2.078-3.888-2.776-5.955-1.558C1.676 40.21.99 42.88 2.21 44.96c1.221 2.078 3.887 2.775 5.955 1.557ZM54.65 84.159l-.044 13.66s8.96-1.659 7.82 2.941c-1.013 4.077-12.675 4.205-13.181 1.17-.83-4.94-.334-16.992-.334-16.992l5.74-.78ZM37.849 84.231l-.517 13.689s-8.866-1.142-7.914 3.325c.845 3.959 12.485 3.235 13.12.122 1.034-5.057 1.034-17.08 1.034-17.08l-5.723-.056ZM60.802 88.113c3.971 5.079 15.417 10.509 23.32 3.319 1.301-1.186-.228-2.89-1.941-2.484-3.46.813-10.879 1.782-15.762-4.56-7.747-10.064-10.65-2.713-5.617 3.725ZM29.727 9.108c-.885-.322-5.059-2.367-10.129-.156-5.075 2.218-7.543 14.367-3.364 20.497 4.185 6.136 10.671-11.215 11.39-13.158.72-1.943 3.525-6.667 2.103-7.183Z"
      />
      <path
        fill="#E3A263"
        d="M63.08 10.357c-6.386-6.397-31.549-6.935-38.109.476-1.366 1.546-3.512 3.67-4.604 6.818-1.4 4.033-2.151 15.645-1.86 15.696h-.011c-.185 10.464.252 44.836 6.862 51.105 6.37 6.044 32.905 6.912 40.971.263 10.806-8.907 1.967-69.132-3.249-74.358Z"
      />
      <path
        fill="#E3A263"
        d="M17.549 33.246c.274.056.56.112.852.157h-.012v.022h10.302V17.97s-20.475.269-20.329 7.052c.034 1.765 1.092 6.52 9.187 8.224Z"
      />
      <path
        fill="#3B1C0C"
        d="M25.638 24.686c.028 2.05-3.35 6.022-7.568 6.073-4.224.05-7.691-3.832-7.72-5.882-.027-2.05 3.379-3.507 7.602-3.557 4.224-.05 7.664 1.316 7.686 3.366Z"
      />
      <path
        fill="#F7F5F1"
        d="M17.97 22.9c.005.548-.964 1.002-2.174 1.019-1.204.017-2.19-.415-2.196-.964-.005-.549.964-1.002 2.174-1.02 1.21-.016 2.19.415 2.196.964Z"
      />
      <path
        fill="#C17F50"
        d="M62.035 9.108c.885-.322 5.059-2.367 10.129-.156 5.075 2.218 7.543 14.367 3.363 20.497-4.184 6.136-10.67-11.215-11.39-13.158-.718-1.943-3.524-6.667-2.102-7.183Z"
      />
      <path
        fill="#3B1C0C"
        d="M48.472 17.889c.028 2.08-1.26 3.788-2.873 3.81-1.613.023-2.94-1.651-2.969-3.732-.028-2.081 1.261-3.789 2.874-3.811 1.612-.022 2.94 1.651 2.968 3.733ZM31.248 16.672c.022 1.886-1.139 3.426-2.6 3.448-1.462.017-2.662-1.495-2.684-3.38-.028-1.887 1.138-3.432 2.6-3.449 1.456-.017 2.661 1.495 2.684 3.381Z"
      />
      <path
        fill="#C17F50"
        d="M19.057 34.995c.033.017 1.071.525 2.723 1.038 1.651.508 3.927 1.021 6.45 1.021 1.138 0 2.326-.106 3.526-.368.898-.195 1.807-.48 2.706-.87a13.93 13.93 0 0 0 3.889-2.556c1.227-1.138 2.354-2.572 3.336-4.357a.957.957 0 1 0-1.68-.92c-.792 1.444-1.673 2.605-2.61 3.537a12.337 12.337 0 0 1-2.182 1.74 12.092 12.092 0 0 1-3.476 1.468 14.415 14.415 0 0 1-3.51.413c-2.26 0-4.357-.47-5.88-.938a21.24 21.24 0 0 1-1.802-.642c-.213-.089-.374-.161-.48-.206-.056-.022-.095-.044-.117-.056a.093.093 0 0 0-.028-.01h-.006l-.14.278.14-.279-.14.28.14-.28-.257.525.257-.525-.257.525.257-.525-.419.843.419-.843-.419.843.419-.843a.958.958 0 0 0-1.283.435.935.935 0 0 0 .424 1.273Z"
      />
      <path
        fill="#D5E8F9"
        d="M45.226 7.689c-6.437.993-10.843 7.025-9.845 13.455.999 6.437 7.025 10.85 13.462 9.85 6.437-.998 10.842-7.03 9.845-13.46-.999-6.437-7.025-10.844-13.462-9.845Z"
        opacity=".7"
      />
      <path
        fill="#656D78"
        d="m60.74 30.756 1.326-1.814.666-.904 11.244 8.214c1 .732 1.218 2.136.486 3.136a2.24 2.24 0 0 1-3.14.486L60.078 31.66l.661-.904Z"
      />
      <path
        fill="#CCD1D9"
        d="m62.066 28.942-1.327 1.814-3.526-2.583c.247-.277.479-.575.699-.876.225-.308.433-.619.623-.938l3.53 2.583Z"
      />
      <path
        fill="#E6E9ED"
        d="M39.08 30.22c-6.008-4.399-7.315-12.827-2.923-18.833 4.392-6.011 12.826-7.32 18.833-2.922 5.699 4.167 7.171 11.974 3.544 17.895-.189.32-.396.63-.622.938-.22.3-.453.599-.7.876-4.543 5.25-12.434 6.213-18.132 2.046Zm-3.699-9.076c1 6.438 7.026 10.85 13.462 9.85 6.437-.998 10.842-7.03 9.845-13.46-.999-6.437-7.025-10.844-13.461-9.845-6.438.993-10.843 7.025-9.846 13.455Z"
      />
      <path
        fill="#656D78"
        d="M60.498 31.764a.56.56 0 0 1-.539-.886l2.32-3.173a.56.56 0 1 1 .906.663l-2.32 3.173a.561.561 0 0 1-.367.223Z"
      />
      <path
        fill="#C17F50"
        d="M78.784 44.89c-.311 1.008-.838 1.9-1.481 2.685-.965 1.176-2.193 2.13-3.532 2.88-1.338.743-2.793 1.28-4.269 1.54a2.136 2.136 0 0 1-2.461-1.741 2.129 2.129 0 0 1 1.734-2.463 9.03 9.03 0 0 0 2.24-.717 9.798 9.798 0 0 0 1.586-.918c.728-.516 1.323-1.112 1.692-1.666a3.44 3.44 0 0 0 .4-.78c.085-.238.117-.449.117-.65 0-.194-.032-.379-.111-.595-.121-.322-.364-.723-.87-1.202-.5-.475-1.265-1.008-2.34-1.54a2.136 2.136 0 0 1-.97-2.853 2.134 2.134 0 0 1 2.852-.97c1.823.901 3.21 1.924 4.19 3.132a6.96 6.96 0 0 1 1.128 1.94c.258.686.38 1.398.38 2.089a6.358 6.358 0 0 1-.285 1.83Z"
      />
      <path
        fill="#C17F50"
        d="M75.201 35.451a4.079 4.079 0 1 1-7.859 2.194 4.08 4.08 0 1 1 7.859-2.194Z"
      />
    </svg>
  );
}
