import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

import { initialState as getInitialState } from './State';
import type {
  Updater,
  TaskName,
  TaskStartDate,
  TaskDueDate,
  ExtensionDays,
  HelpOptions,
  HideCalculator,
  QuestionVariation,
  SaveThisAsTemplate,
  State,
  HideRetry,
} from './State';
import type { SelectedFolderId } from '../../types';

export const updateTaskName: Updater<[TaskName]> = newTaskName => state => ({
  ...state,
  taskName: newTaskName,
  edited: true,
});

export const updateTaskStartDate: Updater<[TaskStartDate]> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
    edited: true,
  });

export const updateTaskDueDate: Updater<[TaskDueDate]> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
  edited: true,
});

export const toggleAdvancedOptions: Updater<[]> = () => state => ({
  ...state,
  isAdvancedOptionsVisible: !state.isAdvancedOptionsVisible,
  edited: true,
});

export const updateExtensionDays: Updater<[ExtensionDays]> =
  newValue => state => ({
    ...state,
    extensionDays: newValue,
    edited: true,
  });

export const updateHideHelpOptions: Updater<[HelpOptions]> =
  newValue => state => ({
    ...state,
    hideHelpOptions: newValue,
    edited: true,
  });

export const updateHideCalculator: Updater<[HideCalculator]> =
  newValue => state => ({
    ...state,
    hideCalculator: newValue,
    edited: true,
  });

export const updateHideRetry: Updater<[HideRetry]> = newValue => state => ({
  ...state,
  hideRetry: newValue,
  edited: true,
});

export const updateQuestionVariation: Updater<[QuestionVariation]> =
  newValue => state => ({
    ...state,
    questionVariation: newValue,
    edited: true,
  });

export const updateSaveThisAsTemplate: Updater<[SaveThisAsTemplate]> =
  newValue => state => ({
    ...state,
    saveThisAsTemplate: newValue,
    edited: true,
  });

export const selectClass: Updater<[ClassSelectionPayload]> =
  klass => state => ({
    ...state,
    selectedClasses: [...state.selectedClasses, klass],
    edited: true,
  });

export const selectStudent: Updater<[StudentSelectionPayload]> =
  student => state => ({
    ...state,
    selectedStudents: [...state.selectedStudents, student],
    edited: true,
  });

export const setSelectedClasses: Updater<[readonly ClassSelectionPayload[]]> =
  klasses => state => ({
    ...state,
    selectedClasses: klasses,
    edited: true,
  });

export const setSelectedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  selectedStudents: students,
  edited: true,
});

export const setExcludedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  excludedStudents: students,
  edited: true,
});

export const updateTemplateGroupId: Updater<[SelectedFolderId]> =
  newTemplateGroupId => state => ({
    ...state,
    templateGroupId: newTemplateGroupId,
  });

export const removeClass: Updater<[string]> = classId => state => ({
  ...state,
  selectedClasses: state.selectedClasses.filter(s => s.id !== classId),
  edited: true,
});

export const removeStudent: Updater<[string]> = studentId => state => ({
  ...state,
  selectedStudents: state.selectedStudents.filter(s => s.id !== studentId),
  edited: true,
});

export const resetState: Updater<[State]> = nextState => () => nextState;
export const clearState: Updater<[]> = () => () => getInitialState();
