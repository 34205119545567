import { useCallback, useState } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { useSnowplow } from 'ms-helpers/Snowplow';
import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import useSchoolContext from 'ms-pages/Teacher/TeacherContext/SchoolContext';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import { FlowSelector } from 'ms-pages/Teacher/components/CreateTask/FlowSelector';
import { Root } from 'ms-pages/Teacher/components/CreateTask/components/CreateTaskLayout';
import type { SelectedSubtopic } from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector';
import AssignDiscoveryCheckInFlow from 'ms-pages/Teacher/components/CreateTask/flows/AssignDiscoveryCheckInFlow';
import BulkAdaptiveFlow from 'ms-pages/Teacher/components/CreateTask/flows/BulkAdaptiveFlow';
import type { TaskTemplateTuple } from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow';
import BulkCustomFlow from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow/index';
import CustomTaskFlow from 'ms-pages/Teacher/components/CreateTask/flows/CustomTaskFlow';
import EngageTaskFlow from 'ms-pages/Teacher/components/CreateTask/flows/EngageTaskFlow';
import LessonFlow from 'ms-pages/Teacher/components/CreateTask/flows/LessonFlow';
import TaskTemplateFlow from 'ms-pages/Teacher/components/CreateTask/flows/TaskTemplateFlow';
import WorksheetFlow from 'ms-pages/Teacher/components/CreateTask/flows/WorksheetFlow';
import type { Preselection } from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';
import { useCreateTaskState } from 'ms-pages/Teacher/components/CreateTask/state/useTaskState';
import {
  assignableSubtopicForAdaptivePractice,
  assignableSubtopicForTheory,
  assignableSubtopicForEngageTask,
  assignableSubtopicForWorksheet,
  assignableSubtopicForExercise,
} from 'ms-pages/Teacher/components/TopicSubtopicTree/subtopicFilters';
import { useDefaultTeacherTextbook } from 'ms-pages/Textbooks/TeacherTextbook/components/TeacherTextbookRedirector';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { usePathMatch } from 'ms-utils/hooks/usePathMatch';
import useStartPosthogRecording from 'ms-utils/hooks/useStartPosthogRecording';
import { contentBrowsingSubtopicDetailViewPath } from 'ms-utils/urls';

import type { CreateTaskContentQuery } from './__generated__/CreateTaskContentQuery.graphql';

/*
 * This file will eventually replace index.jsx after bulk assignment flows
 * are merged into the open beta group.
 */
export function CreateTaskContent({
  prefilledClasses: providedPrefilledClasses,
  prefilledStudents,
  prefilledExcludedStudents,
  prefilledStartDate,
  prefilledDueDate,
  prefilledProblems,
  // There are currently two ways of prefilling a subtopic:
  //
  // The first relies on the caller passing in a tuple of the shape `[ subtopic.id, subtopic.title ]`. This
  // method necessitates that the caller perform the proper checks to ensure a subtopic
  // for whatever task type is selected exists. The `prefilledSubtopic` is usually used in conjunction with the
  // `preselection` prop and this method of prefilling so far is only used on a single task type.
  //
  // The second form is not exposed as a prop/props and is automatic. The component attempts to retrieve
  // a subtopic id from the current URL, and query for the complete subtopic. Subsequent task types are then
  // prefilled with either the subtopic or null depending on whether the subtopic fulfills the requirements of
  // the predicate
  prefilledSubtopic,
  prefilledSyllabusId,
  prefilledTargetMastery,
  prefilledStrandId,
  taskCreationCallback: _taskCreationCallback,
  taskName,
  taskTemplates = [],
  onAbort,
  canCreateOnlyTemplate,
  trackingEventOnCreation,
  onCloseModal,
  prefilledTaskTemplateTitle,
  prefilledTaskTemplateDescription,
  sourceTaskTemplateId,
  prefilledTaskTemplateGroupId,
  prefilledTaskTemplateId,
  prefilledTaskTemplateAuthorId,
  prefilledTaskTemplateAuthorName,
  isEditingTemplate,
  isDuplicatingTemplate,
  onSetShouldShowDiscardChangesPromptOnAbort,
}: CreateTaskContentProps) {
  const { selectedFlow, extraCreateEventData } = useCreateTaskState();
  const { trackStructEvent } = useSnowplow();
  const defaultTeacherTextbook = useDefaultTeacherTextbook();
  const [currentSyllabusId, setCurrentSyllabusId] = useState(
    prefilledSyllabusId ?? defaultTeacherTextbook,
  );
  const taskCreationCallback = useCallback(() => {
    onCloseModal?.();

    if (_taskCreationCallback != null) _taskCreationCallback();
    if (trackingEventOnCreation != null)
      trackStructEvent({
        ...trackingEventOnCreation,
        ...extraCreateEventData,
      });
  }, [
    _taskCreationCallback,
    extraCreateEventData,
    onCloseModal,
    trackStructEvent,
    trackingEventOnCreation,
  ]);

  const { classId } = useMaybeTeacherContext();
  const { schoolClasses } = useSchoolContext();
  const maybeClass = schoolClasses?.find(c => c.id === classId);

  const prefilledClassPayload =
    maybeClass == null || classId == null
      ? []
      : [
          {
            id: classId,
            title: maybeClass.title,
            students: maybeClass.students,
            displayName: maybeClass.displayName,
          },
        ];

  // If there's no prefilling of classes or students,
  // we prefill CATFA with the current class
  const prefilledClasses =
    providedPrefilledClasses != null || prefilledStudents != null
      ? providedPrefilledClasses
      : prefilledClassPayload;

  // Automatically extract a possible `subtopic id` from the URL
  // and use for prefilling
  // We're using `usePathMatch` instead of `useParams` because the latter
  // works only if the component is mounted within a `Route` component
  // matching a given path, but we're mounting CATFA also in the teacher Navbar
  // that renders also outside of the textbooks routes.
  // With `usePathMatch`, instead, we can manually pass the path to match.
  const { subtopicId } =
    usePathMatch(contentBrowsingSubtopicDetailViewPath) ?? {};

  const { props, error } = useQuery<CreateTaskContentQuery>(
    graphql`
      query CreateTaskContentQuery($subtopicId: ID!) {
        subtopic(id: $subtopicId) {
          id
          title
          hasExercise
          hasAdaptivePractice
          hasLessons
          hasInvestigations
          hasWorksheet
          hasEngageActivity
          gamifiedMastery
          earliestDueAssignedLessonTask {
            id
            status
            workout {
              status
            }
          }
          earliestDueAssignedWorksheetTask {
            id
            status
            workout {
              status
            }
          }
        }
      }
    `,
    {
      // We use this unsafe coercion as TS can't detect we've checked the nature of subtopicId
      // in the logic of the useQuery hook and we can't use an early return because
      // we can't call hooks conditionally.
      // We also can't use the `unwrap` utility as there are  canonical situations
      // where `subtopicId` is undefined and it will throw an extraneous error.
      // TODO: Refactor this so we don't have to rely on backdoors in the typing system.
      subtopicId: subtopicId as string,
    },
    { skip: subtopicId === undefined },
  );
  useStartPosthogRecording({
    isEnabled: true,
    deps: [],
    eventName: 'ViewedCATFAv2',
  });
  if (error != null) throw error;
  if (props == null) return null;
  const subtopic = props.subtopic;

  return (
    <Root fullWidth>
      {selectedFlow == null && <FlowSelector onClose={onAbort} />}

      {selectedFlow === 'adaptive' && (
        <BulkAdaptiveFlow
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          prefilledStartDate={prefilledStartDate}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForAdaptivePractice(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          prefilledDueDate={prefilledDueDate}
          prefilledTargetMastery={prefilledTargetMastery}
          taskCreationCallback={taskCreationCallback}
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
        />
      )}

      {selectedFlow === 'custom' && (
        <CustomTaskFlow
          taskName={taskName}
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          prefilledStartDate={prefilledStartDate}
          prefilledDueDate={prefilledDueDate}
          taskCreationCallback={taskCreationCallback}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForExercise(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
          canCreateOnlyTemplate={canCreateOnlyTemplate}
          prefilledProblems={prefilledProblems}
          sourceTaskTemplateId={sourceTaskTemplateId}
        />
      )}

      {selectedFlow === 'bulkCustom' &&
        taskTemplates.length > 0 &&
        prefilledTaskTemplateGroupId != null && (
          <BulkCustomFlow
            taskTemplates={taskTemplates}
            prefilledTaskTemplateGroupId={prefilledTaskTemplateGroupId}
            taskCreationCallback={taskCreationCallback}
          />
        )}

      {selectedFlow === 'taskTemplate' && (
        <TaskTemplateFlow
          taskTemplateCreationCallback={taskCreationCallback}
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForExercise(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          prefilledProblems={prefilledProblems}
          prefilledTaskTemplateTitle={prefilledTaskTemplateTitle}
          prefilledTaskTemplateDescription={prefilledTaskTemplateDescription}
          prefilledTaskTemplateGroupId={prefilledTaskTemplateGroupId}
          prefilledTaskTemplateId={prefilledTaskTemplateId}
          prefilledTaskTemplateAuthorId={prefilledTaskTemplateAuthorId}
          prefilledTaskTemplateAuthorName={prefilledTaskTemplateAuthorName}
          isEditingTemplate={isEditingTemplate}
          isDuplicatingTemplate={isDuplicatingTemplate}
          onSetShouldShowDiscardChangesPromptOnAbort={
            onSetShouldShowDiscardChangesPromptOnAbort
          }
        />
      )}

      {selectedFlow === 'lesson' && (
        <LessonFlow
          taskName={taskName}
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          prefilledStartDate={prefilledStartDate}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForTheory(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          prefilledDueDate={prefilledDueDate}
          taskCreationCallback={taskCreationCallback}
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
        />
      )}

      {selectedFlow === 'worksheet' && (
        <WorksheetFlow
          taskName={taskName}
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          prefilledStartDate={prefilledStartDate}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForWorksheet(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          prefilledDueDate={prefilledDueDate}
          taskCreationCallback={taskCreationCallback}
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
        />
      )}

      {selectedFlow === 'engageTask' && (
        <EngageTaskFlow
          taskName={taskName}
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          prefilledStartDate={prefilledStartDate}
          prefilledSubtopic={
            prefilledSubtopic ??
            (!!subtopic && assignableSubtopicForEngageTask(subtopic)
              ? [subtopic.id, subtopic.title]
              : null)
          }
          prefilledDueDate={prefilledDueDate}
          taskCreationCallback={taskCreationCallback}
          currentSyllabusId={currentSyllabusId}
          setCurrentSyllabusId={setCurrentSyllabusId}
        />
      )}

      {selectedFlow === 'discoveryCheckIn' && (
        <AssignDiscoveryCheckInFlow
          prefilledClasses={prefilledClasses}
          prefilledStudents={prefilledStudents}
          prefilledExcludedStudents={prefilledExcludedStudents}
          taskCreationCallback={taskCreationCallback}
          prefilledStrandId={prefilledStrandId}
        />
      )}
    </Root>
  );
}

type SharedProps = {
  prefilledClasses?: ReadonlyArray<ClassSelectionPayload> | undefined;
  prefilledStudents?: ReadonlyArray<StudentSelectionPayload> | undefined;
  prefilledExcludedStudents?:
    | ReadonlyArray<StudentSelectionPayload>
    | undefined;
  prefilledProblems?: readonly Problem[] | undefined;
  prefilledStartDate?: Date | undefined;
  prefilledDueDate?: Date | undefined;
  prefilledSubtopic?: SelectedSubtopic | null | undefined;
  prefilledSyllabusId?: string | null | undefined;
  prefilledTargetMastery?: number | null | undefined;
  prefilledStrandId?: string | null | undefined;
  taskName?: string | null | undefined;
  taskTemplates?: ReadonlyArray<TaskTemplateTuple> | undefined;
  taskCreationCallback?: (() => void) | undefined;
  trackingEventOnCreation: SupportedStructEvent;
  canCreateOnlyTemplate?: boolean | undefined;
  sourceTaskTemplateId?: string | null | undefined;
  prefilledTaskTemplateGroupId?: string | undefined;
  isEditingTemplate?: boolean | undefined;
  isDuplicatingTemplate?: boolean | undefined;
  prefilledTaskTemplateTitle?: string | null | undefined;
  prefilledTaskTemplateDescription?: string | null | undefined;
  prefilledTaskTemplateId?: string | undefined;
  prefilledTaskTemplateAuthorId?: string | undefined;
  prefilledTaskTemplateAuthorName?: string | undefined;
};
export type CreateTaskContentProps = SharedProps & {
  onAbort: () => void;
  onCloseModal?: (() => void) | undefined;
  onSetShouldShowDiscardChangesPromptOnAbort: (
    shouldShowDiscardChangesPrompt: boolean,
  ) => void;
};
export type CreateTaskProps = SharedProps & {
  isOpen: boolean;
  onClose: () => void;
  preselection?: Preselection | null | undefined;
};
