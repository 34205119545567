/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CheckInStartDiagnosticQueryVariables = {
    strandId: string;
};
export type CheckInStartDiagnosticQueryResponse = {
    readonly lantern: {
        readonly viewer: {
            readonly totalCheckInsCompleted?: number | undefined;
        } | null;
        readonly strand: {
            readonly outcomes: ReadonlyArray<{
                readonly id: string;
                readonly skill: {
                    readonly id: string;
                    readonly title: string;
                    readonly substrand: {
                        readonly id: string;
                    };
                };
            }>;
        } | null;
    };
};
export type CheckInStartDiagnosticQuery = {
    readonly response: CheckInStartDiagnosticQueryResponse;
    readonly variables: CheckInStartDiagnosticQueryVariables;
};



/*
query CheckInStartDiagnosticQuery(
  $strandId: ID!
) {
  lantern {
    viewer {
      __typename
      ... on LanternStudent {
        totalCheckInsCompleted
      }
      id
    }
    strand(id: $strandId) {
      outcomes(limit: 1) {
        id
        skill {
          id
          title
          substrand {
            id
          }
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "strandId"
  }
],
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCheckInsCompleted",
      "storageKey": null
    }
  ],
  "type": "LanternStudent",
  "abstractKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "strandId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 1
    }
  ],
  "concreteType": "LanternOutcome",
  "kind": "LinkedField",
  "name": "outcomes",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Skill",
      "kind": "LinkedField",
      "name": "skill",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Substrand",
          "kind": "LinkedField",
          "name": "substrand",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "outcomes(limit:1)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInStartDiagnosticQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Strand",
            "kind": "LinkedField",
            "name": "strand",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckInStartDiagnosticQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Strand",
            "kind": "LinkedField",
            "name": "strand",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c04032899ef033478614349dbc972e06",
    "id": null,
    "metadata": {},
    "name": "CheckInStartDiagnosticQuery",
    "operationKind": "query",
    "text": "query CheckInStartDiagnosticQuery(\n  $strandId: ID!\n) {\n  lantern {\n    viewer {\n      __typename\n      ... on LanternStudent {\n        totalCheckInsCompleted\n      }\n      id\n    }\n    strand(id: $strandId) {\n      outcomes(limit: 1) {\n        id\n        skill {\n          id\n          title\n          substrand {\n            id\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f68511e1598ef5157e23ed9e8ed4bf6a';
export default node;
