import React, { type MutableRefObject } from 'react';

import { hexStringToRgbaString } from 'ms-utils/colors';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import cssSvgCursor from 'ms-utils/misc/cssSvgCursor';
import { assertUnreachable, unwrap } from 'ms-utils/typescript-utils';

const THICKNESS = 16 * 1.3;
const HIGHLIGHTER_CURSOR_SIZE = 48;

const EVENT_OPTS: AddEventListenerOptions = { passive: true };
const START_EVENT_OPTS: AddEventListenerOptions = { passive: false };

type DrawingTool = 'draw' | 'lines' | 'rectangle';
export type Tool = 'hand' | DrawingTool;

type PointType = [number, number];
type Points = ReadonlyArray<PointType>;
type PointPair = [PointType, PointType];
type PointPairs = ReadonlyArray<PointPair>;
type Color = string;
type DrawingType = {
  points: PointType[];
  color: Color;
  tool: DrawingTool;
};

type Drawings = ReadonlyArray<DrawingType>;
export type CanvasState = {
  drawings: Drawings;
  undoes: Drawings;
};

type Props = {
  width: number;
  height: number;
  state: CanvasState;
  onChange: (updater: (state: CanvasState) => CanvasState) => void;
  selectedColor: Color;
  style: {};
  selectedTool: Tool;
};

function collectPoint(
  event: DrawingEvent,
  canvasRef: MutableRefObject<SVGSVGElement | null>,
): PointType {
  if (event instanceof TouchEvent) {
    return collectPointTouch(event, canvasRef);
  }
  return collectPointCursor(event);
}

function collectPointTouch(
  event: TouchEvent,
  canvasRef: MutableRefObject<SVGSVGElement | null>,
): PointType {
  const canvas = canvasRef.current;
  const touch = unwrap(event.touches[0]);
  if (
    canvas !== null &&
    Number.isFinite(touch.pageX) &&
    Number.isFinite(touch.pageY)
  ) {
    const targetRect = canvas.getBoundingClientRect();
    return [touch.pageX - targetRect.left, touch.pageY - targetRect.top];
  }
  throw new Error('Something is wrong in collectPointTouch');
}

function collectPointCursor(event: MouseEvent): PointType {
  try {
    const { offsetX, offsetY } = event;
    return [offsetX, offsetY];
  } catch (error) {
    throw new Error('Something is wrong in collectPointCursor');
  }
}

type DrawingEvent = TouchEvent | MouseEvent;

export default function Canvas({
  width,
  height,
  state,
  onChange,
  selectedColor,
  style,
  selectedTool,
}: Props) {
  const canvasRef = React.useRef<SVGSVGElement | null>(null);
  const isDrawing = useBoolean();

  const cursorUrl = React.useMemo(
    () =>
      cssSvgCursor(
        <svg
          width={HIGHLIGHTER_CURSOR_SIZE}
          height={HIGHLIGHTER_CURSOR_SIZE}
          viewBox="0 0 16 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.0317 4.26433L13.3373 2.57005C12.5185 1.75046 11.1942 1.70708 10.3253 2.4678L3.40517 8.47638C3.25079 8.61028 3.15945 8.80225 3.15218 9.00607C3.14773 9.13768 3.18963 9.26135 3.25146 9.37532L2.10223 11.3743C1.936 11.6627 1.98463 12.0262 2.21997 12.2615L2.47754 12.5191L0.538892 14.4627C0.328472 14.6732 0.265965 14.9896 0.379932 15.264C0.494033 15.5391 0.762515 15.7178 1.05983 15.7178H4.00262C4.17411 15.7178 4.34034 15.6576 4.47277 15.5486L5.03775 15.0793L5.34085 15.3824C5.48217 15.5244 5.67117 15.598 5.86179 15.598C5.9868 15.598 6.11343 15.5663 6.2282 15.5002L8.22559 14.3517C8.33228 14.4098 8.44705 14.4503 8.57058 14.4503H8.59645C8.80013 14.4429 8.99223 14.3517 9.12614 14.1972L15.1332 7.27933C15.8946 6.40747 15.8504 5.08324 15.0317 4.26433V4.26433ZM5.98249 13.9434L4.03872 11.9996C4.03791 11.9988 4.03724 11.9975 4.03643 11.9967C4.03576 11.996 4.03428 11.9952 4.03347 11.9945L3.65829 11.6192L4.3072 10.4914L7.11097 13.2952L5.98249 13.9434Z"
            fill={hexStringToRgbaString(selectedColor, 0.3)}
          />
        </svg>,
        // these values will depend on the particular image used
        0.27 * HIGHLIGHTER_CURSOR_SIZE,
        0.85 * HIGHLIGHTER_CURSOR_SIZE,
      ),
    [selectedColor],
  );

  const onDrawStart = React.useCallback(
    (e: DrawingEvent) => {
      if (selectedTool === 'hand') return;

      if (e instanceof TouchEvent) {
        e.preventDefault(); // prevent scrolling on touch devices
      }

      const point = collectPoint(e, canvasRef);
      onChange(state => ({
        undoes: [],
        drawings: [
          ...state.drawings,
          { points: [point], color: selectedColor, tool: selectedTool },
        ],
      }));
      isDrawing.setTrue();
    },
    [onChange, selectedColor, selectedTool, isDrawing],
  );

  const onDraw = React.useCallback(
    (e: DrawingEvent) => {
      const point = collectPoint(e, canvasRef);
      onChange(state => {
        const { drawings } = state;
        const previousDrawings = drawings.slice(0, -1);
        const lastDrawing = drawings[drawings.length - 1];
        if (lastDrawing == null) {
          throw new Error('onDraw should not be called at this point');
        }
        const lastDrawingPreviousPoints = lastDrawing.points;
        const lastDrawingNextPoints =
          selectedTool === 'draw'
            ? [...lastDrawingPreviousPoints, point]
            : [unwrap(lastDrawing.points[0]), point];
        return {
          undoes: [],
          drawings: [
            ...previousDrawings,
            {
              ...lastDrawing,
              points: lastDrawingNextPoints,
            },
          ],
        };
      });
    },
    [onChange, selectedTool],
  );

  React.useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas !== null && selectedTool !== 'hand') {
      if (!isDrawing.value) {
        canvas.addEventListener('mousedown', onDrawStart, START_EVENT_OPTS);
        canvas.addEventListener('touchstart', onDrawStart, START_EVENT_OPTS);
        return () => {
          canvas.removeEventListener(
            'mousedown',
            onDrawStart,
            START_EVENT_OPTS,
          );
          canvas.removeEventListener(
            'touchstart',
            onDrawStart,
            START_EVENT_OPTS,
          );
        };
      } else {
        canvas.addEventListener('mousemove', onDraw, EVENT_OPTS);
        canvas.addEventListener('touchmove', onDraw, EVENT_OPTS);
        canvas.addEventListener('mouseup', isDrawing.setFalse, EVENT_OPTS);
        canvas.addEventListener('touchend', isDrawing.setFalse, EVENT_OPTS);
        canvas.addEventListener('mouseleave', isDrawing.setFalse, EVENT_OPTS);
        canvas.addEventListener('touchcancel', isDrawing.setFalse, EVENT_OPTS);

        return () => {
          canvas.removeEventListener('mousemove', onDraw, EVENT_OPTS);
          canvas.removeEventListener('touchmove', onDraw, EVENT_OPTS);
          canvas.removeEventListener('mouseup', isDrawing.setFalse, EVENT_OPTS);
          canvas.removeEventListener(
            'touchend',
            isDrawing.setFalse,
            EVENT_OPTS,
          );
          canvas.removeEventListener(
            'mouseleave',
            isDrawing.setFalse,
            EVENT_OPTS,
          );
          canvas.removeEventListener(
            'touchcancel',
            isDrawing.setFalse,
            EVENT_OPTS,
          );
        };
      }
    } else {
      // Every code branch must return same type to appease TS
      return () => {};
    }
  }, [canvasRef, isDrawing, onDraw, onDrawStart, selectedTool]);

  return (
    <svg
      style={{ ...style, cursor: selectedTool !== 'hand' ? cursorUrl : 'grab' }}
      ref={canvasRef}
      width={width}
      height={height}
    >
      {state.drawings.map((drawing, index) => (
        <Drawing
          key={index} // Replace with string ids if we need to implement undoes and redoes
          points={drawing.points}
          color={drawing.color}
          simple={false}
          tool={drawing.tool}
        />
      ))}
    </svg>
  );
}

function Drawing({
  points,
  color,
  simple,
  tool,
}: {
  points: Points;
  color: Color;
  simple: boolean;
  tool: DrawingTool;
}) {
  const [P0, P1] = points;
  if (P0 == null) {
    return null;
  }
  if (P1 == null) {
    const [x0, y0] = P0;
    return <Point x0={x0} y0={y0} color={color} />;
  }

  if (tool === 'draw')
    return (
      <path
        fill="transparent"
        stroke={color || 'black'}
        strokeWidth={THICKNESS}
        strokeLinecap="round"
        strokeLinejoin="round"
        d={pointsToD(points, simple)}
      />
    );

  if (tool === 'lines') return <Line points={points} color={color} />;

  if (tool === 'rectangle') return <Rectangle color={color} points={points} />;

  assertUnreachable(tool);
}

const pToString = ([x, y]: PointType) => `${x} ${y}`;
const psToString = ([[x1, y1], [x2, y2]]: PointPair) =>
  `${x1} ${y1} ${x2} ${y2}`;

const pointsToD = (points: Points, simple: boolean) =>
  points.length > 3 && !simple
    ? betterPointsToD(points)
    : `M${points.map(pToString).join(' L ')}`;

const betterPointsToD = (points: Points) =>
  `M${pToString(unwrap(points[0]))} Q ${addMidPoints(points.slice(1, -1))
    .map(psToString)
    .join(' Q ')} Q ${points.slice(-2).map(pToString).join(' ')}`;

function addMidPoints(points: Points): PointPairs {
  // @ts-expect-error TODO fix this code as its hostile to TS
  return points
    .map((curPoint, curIndex) => {
      const nextPoint = points[curIndex + 1];
      return curIndex === points.length - 1
        ? null //  we remove this null with the final slice(0, -1)
        : [curPoint, midPoint(curPoint, unwrap(nextPoint))];
    })
    .slice(0, -1);
}

const midPoint = ([x1, y1]: PointType, [x2, y2]: PointType): PointType => [
  (x1 + x2) / 2,
  (y1 + y2) / 2,
];

export const stringPathToPoints = (stringPath: string) => {
  if (stringPath[0] !== 'M') throw new Error('stringPath should start with M');
};

function Point({ x0, y0, color }: { x0: number; y0: number; color: Color }) {
  return (
    <ellipse
      cx={x0}
      cy={y0}
      rx={THICKNESS / 2}
      ry={THICKNESS / 2}
      fill={color}
    />
  );
}

function Line({ points, color }: { points: Points; color: Color }) {
  const [p1, p2] = points;
  const [x1, y1] = unwrap(p1);
  const [x2] = unwrap(p2);
  return (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y1}
      fill="transparent"
      strokeWidth={THICKNESS}
      stroke={color}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  );
}

const { min, abs } = Math;
function Rectangle({ points, color }: { points: Points; color: Color }) {
  const [p1, p2] = points;
  const [x0, y0] = unwrap(p1);
  const [x1, y1] = unwrap(p2);
  return (
    <rect
      x={min(x0, x1)}
      y={min(y0, y1)}
      width={abs(x1 - x0) || 1}
      height={abs(y1 - y0) || 1}
      strokeWidth={THICKNESS}
      fill={color}
      strokeLinecap="round"
    />
  );
}
