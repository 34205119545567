import styled from '@emotion/styled';
import { Route, Switch } from 'react-router-dom';

import {
  BookTypeTabsTop,
  useTextbookTypeTabsContext,
} from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookTypeTabs';
import type { Props as TopicSidebarProps } from 'ms-pages/Textbooks/components/TopicSidebar';
import TopicSidebar from 'ms-pages/Textbooks/components/TopicSidebar';
import { VStack } from 'ms-ui-primitives/Stack';
import { usePushToHistoryPreservingSearch } from 'ms-utils/hooks/usePushToHistoryPreservingSearch';
import useQueryParam from 'ms-utils/hooks/useQueryParam';
import {
  contentBrowsingBasePath,
  contentBrowsingEmptyViewPath,
  contentBrowsingChapterIntroViewPath,
  contentBrowsingSubtopicDetailViewPath,
  contentBrowsingTopicOverviewPath,
  contentBrowsingMaterialsGuidePath,
  getContentBrowsingChapterIntroUrl,
  getContentBrowsingTopicOverviewUrl,
  getContentBrowsingMaterialsGuideUrl,
  getContentBrowsingSubtopicDetailViewUrl,
  getContentBrowsingSubtopicRedirectorUrl,
} from 'ms-utils/urls';

type ActiveElementType =
  | 'chapter-intro'
  | 'materials-guide'
  | 'topic-overview'
  | 'subtopic';

const FullHeightContainer = styled(VStack)({
  height: '100%',
  // Without using `overflowY: clip`, the popover for the "New skills view"
  // will be cut-off where it overflows the right-hand-edge of the sidebar,
  // which includes the close icon.
  overflowY: 'clip',
});
const RemainingHeightContainer = styled(VStack)({
  flexGrow: 1,
  // But for the main content area, we need to use `overflowY: hidden` so that
  // the child scrollable div takes up the correct height. We can't use
  // `overflowY: clip` or some of the content gets cut off and becomes inaccessible.
  overflowY: 'hidden',
});

export default function LeftSidebar() {
  const pushToHistoryPreservingSearch = usePushToHistoryPreservingSearch();

  const [searchString, onSearchQueryStringChange] = useQueryParam<string>(
    'searchString',
    '',
    (v): v is string => typeof v === 'string',
  );

  const { setSyllabusId } = useTextbookTypeTabsContext();

  return (
    <Switch>
      <Route
        path={[
          contentBrowsingBasePath,
          contentBrowsingEmptyViewPath,
          contentBrowsingChapterIntroViewPath,
          contentBrowsingTopicOverviewPath,
          contentBrowsingSubtopicDetailViewPath,
          contentBrowsingMaterialsGuidePath,
        ]}
        exact
        render={({ match: { path, params } }) => {
          const syllabusId =
            'syllabusId' in params ? params.syllabusId : undefined;
          const topicId = 'topicId' in params ? params.topicId : undefined;
          const subtopicId =
            'subtopicId' in params ? params.subtopicId : undefined;

          // 🚨🚨🚨 WARNING 🚨🚨🚨
          // This code was authored incorrectly. It assumed syllabusId, topicId, and subtopicId
          // are guaranteed to be strings. That is patently not the case if you look at the
          // path param of the Route. This very unsafe hack recapitulates the original
          // incorrect assumptions as a stopgap solution. This whole component and subtree
          // needs to be rewritten to correctly handle the reality of the situation.
          const fakeNonUndefinedSyllabusId = syllabusId as string;
          const fakeNonUndefinedTopicId = topicId as string;
          const fakeNonUndefinedSubtopicId = subtopicId as string;

          const activeElementType = getActiveElementType({
            path,
          });

          const eventHandlers: TopicSidebarProps['eventHandlers'] = {
            onSelectSyllabus: setSyllabusId,
            onChangeSearchString: (value: string) => {
              onSearchQueryStringChange(value);
            },
            onClickChapterIntro: () => {
              pushToHistoryPreservingSearch(
                getContentBrowsingChapterIntroUrl({
                  syllabusId: fakeNonUndefinedSyllabusId,
                }),
              );
            },

            onClickMaterialsGuide: () => {
              pushToHistoryPreservingSearch(
                getContentBrowsingMaterialsGuideUrl({
                  syllabusId: fakeNonUndefinedSyllabusId,
                }),
              );
            },

            onClickTopic: ({ topic, shouldRenderTopicIntroduction }) => {
              const link = (() => {
                // This is to stop collapsing a group from potentially navigating to a different topic

                if (topic.id === fakeNonUndefinedTopicId) return null;
                if (
                  topic.syllabus.textbookType === 'CORE_TEXTBOOK' &&
                  shouldRenderTopicIntroduction
                ) {
                  return getContentBrowsingTopicOverviewUrl({
                    syllabusId: fakeNonUndefinedSyllabusId,
                    topicId: topic.id,
                  });
                }

                if (topic.subtopics.edges[0] != null) {
                  const subtopicId = topic.subtopics.edges[0].node.id;
                  return getContentBrowsingSubtopicDetailViewUrl({
                    syllabusId: fakeNonUndefinedSyllabusId,
                    topicId: topic.id,
                    subtopicId,
                  });
                }

                return getContentBrowsingSubtopicRedirectorUrl({
                  syllabusId: fakeNonUndefinedSyllabusId,
                  topicId: topic.id,
                });
              })();
              if (link !== null) {
                pushToHistoryPreservingSearch(link);
              }
            },

            onClickTopicOverview: topic => {
              pushToHistoryPreservingSearch(
                getContentBrowsingTopicOverviewUrl({
                  syllabusId: fakeNonUndefinedSyllabusId,
                  topicId: topic.id,
                }),
              );
            },

            onClickSubtopic: ({ subtopic, topic }) => {
              pushToHistoryPreservingSearch(
                getContentBrowsingSubtopicDetailViewUrl({
                  syllabusId: fakeNonUndefinedSyllabusId,
                  topicId: topic.id,
                  subtopicId: subtopic.id,
                }),
              );
            },
          };

          return (
            <FullHeightContainer>
              <BookTypeTabsTop />
              <RemainingHeightContainer>
                <TopicSidebar
                  activeTopicId={fakeNonUndefinedTopicId}
                  activeSubtopicId={fakeNonUndefinedSubtopicId}
                  activeElementType={activeElementType}
                  eventHandlers={eventHandlers}
                  isInCATFA={false}
                  searchString={searchString}
                  syllabusId={fakeNonUndefinedSyllabusId}
                />
              </RemainingHeightContainer>
            </FullHeightContainer>
          );
        }}
      />
    </Switch>
  );
}

function getActiveElementType({ path }: { path: string }): ActiveElementType {
  switch (path) {
    case contentBrowsingChapterIntroViewPath:
      return 'chapter-intro';
    case contentBrowsingMaterialsGuidePath:
      return 'materials-guide';
    case contentBrowsingTopicOverviewPath:
      return 'topic-overview';
    default:
      return 'subtopic';
  }
}
