import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { useAboutPremiumUrl } from 'ms-utils/urls/sunflower/sunflowerTeacher';

type Props = {
  trackingId: string;
};

export default function AboutPremiumButton({ trackingId }: Props) {
  const aboutPremiumUrl = useAboutPremiumUrl();

  return (
    <AnchorButton
      href={aboutPremiumUrl}
      isRound
      type="primary"
      size="medium"
      trackingId={trackingId}
      target="_blank"
    >
      About premium
    </AnchorButton>
  );
}
