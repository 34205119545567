/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LanternCheckInType = "ALL_STRANDS_DIAGNOSTIC_CHECKIN" | "HISTORIC_DIAGNOSTIC_CHECKIN" | "HISTORIC_GROWTH_CHECKIN" | "PRE_TOPIC_TEST_CHECKIN" | "SKILL_CHECKIN" | "STRAND_DIAGNOSTIC_CHECKIN" | "STRAND_GROWTH_CHECKIN" | "SUBSTRAND_GROWTH_CHECKIN";
export type CheckInQueryVariables = {
    checkInId: string;
};
export type CheckInQueryResponse = {
    readonly lantern: {
        readonly checkIn: {
            readonly id: string;
            readonly type: LanternCheckInType;
            readonly strand: {
                readonly id: string;
                readonly title: string;
            } | null;
            readonly substrand: {
                readonly title: string;
            } | null;
            readonly currentQuestion: ({
                readonly __typename: "MultipleChoiceQuestion";
                readonly __typename: "MultipleChoiceQuestion";
                readonly id: string;
                readonly sanaItemId: string;
                readonly transpiledText: string;
                readonly transpiledOptions: ReadonlyArray<string>;
                readonly numberOfAnswers: number;
            } | {
                readonly __typename: "MathExpressionQuestion";
                readonly __typename: "MathExpressionQuestion";
                readonly id: string;
                readonly sanaItemId: string;
                readonly transpiledText: string;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            }) | null;
            readonly endedAt: string | null;
            readonly numberOfQuestions: number;
            readonly questionsCompleted: number;
        } | null;
    };
};
export type CheckInQuery = {
    readonly response: CheckInQueryResponse;
    readonly variables: CheckInQueryVariables;
};



/*
query CheckInQuery(
  $checkInId: ID!
) {
  lantern {
    checkIn(id: $checkInId) {
      id
      type
      strand {
        id
        title
      }
      substrand {
        title
        id
      }
      currentQuestion {
        __typename
        __isQuestionInterface: __typename
        ... on MultipleChoiceQuestion {
          __typename
          id
          sanaItemId
          transpiledText
          transpiledOptions
          numberOfAnswers
        }
        ... on MathExpressionQuestion {
          __typename
          id
          sanaItemId
          transpiledText
        }
        id
      }
      endedAt
      numberOfQuestions
      questionsCompleted
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkInId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "checkInId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strand",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sanaItemId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledText",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transpiledOptions",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfAnswers",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endedAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfQuestions",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "questionsCompleted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Substrand",
                "kind": "LinkedField",
                "name": "substrand",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "currentQuestion",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "type": "MultipleChoiceQuestion",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "type": "MathExpressionQuestion",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckInQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Substrand",
                "kind": "LinkedField",
                "name": "substrand",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "currentQuestion",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isQuestionInterface"
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "type": "MultipleChoiceQuestion",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "type": "MathExpressionQuestion",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d310e93953204504431355cfd65c582e",
    "id": null,
    "metadata": {},
    "name": "CheckInQuery",
    "operationKind": "query",
    "text": "query CheckInQuery(\n  $checkInId: ID!\n) {\n  lantern {\n    checkIn(id: $checkInId) {\n      id\n      type\n      strand {\n        id\n        title\n      }\n      substrand {\n        title\n        id\n      }\n      currentQuestion {\n        __typename\n        __isQuestionInterface: __typename\n        ... on MultipleChoiceQuestion {\n          __typename\n          id\n          sanaItemId\n          transpiledText\n          transpiledOptions\n          numberOfAnswers\n        }\n        ... on MathExpressionQuestion {\n          __typename\n          id\n          sanaItemId\n          transpiledText\n        }\n        id\n      }\n      endedAt\n      numberOfQuestions\n      questionsCompleted\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '234c34a9d993c8627c62cdb19414c2e7';
export default node;
