import styled from '@emotion/styled';
import { useState, useMemo } from 'react';
import type { ComponentType, CSSProperties } from 'react';

import CrossBold from 'ms-components/icons/CrossBold';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import { HStack, VStack } from 'ms-ui-primitives/Stack';

import miloPeeking from './milo-peeking.svg';

type RelativePosition = 'top' | 'bottom' | 'left' | 'right';

const DEFAULT_OFFSET = 10;

export const DissmissablePopoverContainer: ComponentType = styled.div({
  position: 'relative',
  width: 'fit-content',
});

const Tail = styled('div', {
  shouldForwardProp: p => p !== 'relativePositionStyles',
})<{ relativePositionStyles: CSSProperties | undefined }>(
  ({ relativePositionStyles }) => ({
    position: 'absolute',
    width: 15,
    height: 15,
    backgroundColor: 'inherit',
    transform: 'rotate(45deg)',
    margin: 'auto',
    borderRadius: 4,
    ...relativePositionStyles,
  }),
);

const DEFAULT_WIDTH = 240;
export function StudentDismissablePopover({
  children,
  relativePosition,
  fineTuneOffset,
  title,
  width = DEFAULT_WIDTH,
  onDismiss = () => {},
  styles = {},
}: {
  children: string | string[];
  relativePosition: RelativePosition;
  fineTuneOffset?: number;
  title?: string;
  width?: number;
  onDismiss?: () => void;
  styles?: Record<string, unknown>;
}) {
  const offSet = DEFAULT_OFFSET + (fineTuneOffset ?? 0);

  const relativePositionStyles = useMemo(() => {
    switch (relativePosition) {
      case 'top':
        return {
          main: {
            bottom: `calc(100% + ${offSet}px)`,
            left: '50%',
            transform: 'translateX(-50%)',
          },
          tail: {
            bottom: -4,
            left: 0,
            right: 0,
          },
        };
      case 'bottom':
        return {
          main: {
            top: `calc(100% + ${offSet}px)`,
            left: '50%',
            transform: 'translateX(-50%)',
          },
          tail: {
            top: -4,
            left: 0,
            right: 0,
          },
        };
      case 'left':
        return {
          main: {
            top: '50%',
            transform: 'translateY(-50%)',
            right: `calc(100% + ${offSet}px)`,
          },
          tail: {
            top: 0,
            bottom: 0,
            right: -4,
          },
        };
      case 'right':
        return {
          main: {
            top: '50%',
            transform: 'translateY(-50%)',
            left: `calc(100% + ${offSet}px)`,
          },
          tail: {
            top: 0,
            bottom: 0,
            left: -4,
          },
        };
      default:
        return {};
    }
  }, [offSet, relativePosition]);
  const [isPopoverDismissed, setIsPopoverDismissed] = useState<boolean>(false);
  return isPopoverDismissed ? null : (
    <div
      style={{
        textAlign: 'left',
        position: 'absolute',
        height: 'min-content',
        width,
        backgroundColor: colors.eggplant,
        borderRadius: 12,
        margin: 'auto',
        zIndex: 1,
        isolation: 'isolate',
        padding: '16px 16px 16px 10px',
        ...relativePositionStyles.main,
        ...styles,
      }}
    >
      <Tail relativePositionStyles={relativePositionStyles.tail} />

      <HStack center css={{ gap: 10 }}>
        <img style={{ flexGrow: 1 }} src={miloPeeking} alt="" />
        <VStack>
          {title && (
            <BodyM bold color="eggplant20">
              {title}
            </BodyM>
          )}
          <BodyM bold color="white">
            {children}
          </BodyM>
        </VStack>
        <DismissCross
          onClick={() => {
            setIsPopoverDismissed(true);
            onDismiss();
          }}
        />
      </HStack>
    </div>
  );
}

function DismissCross({ onClick = () => {} }: { onClick?: () => void }) {
  return (
    <div
      style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
      onClick={onClick}
    >
      <CrossBold size={12} color={colors.white} title="Dismiss button" />
    </div>
  );
}
