export function FoxSvg({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.975 18.346c3.823 0 6.923.37 6.923.827 0 .457-3.1.827-6.923.827-3.824 0-6.923-.37-6.923-.827 0-.456 3.1-.826 6.923-.826Z"
        fill="#000"
      />
      <path
        d="M15.215 14.493c.3 3.45-1.438 4.457-3.673 4.65-2.235.194-4.12-.498-4.42-3.947-.3-3.45 1.1-8.35 3.335-8.544 2.235-.194 4.459 4.392 4.758 7.841Z"
        fill="#F49958"
      />
      <path
        d="M10.287 8.89c.403.456.676 1.012.79 1.61.224 1.228.3 3.258-.859 5.047-.176.273-.56 1.157-.6 1.789-.027.423-.115 1.202 1.13 1.792 0 0-4.013.183-6-2.542-2.503-3.434 2.805-3.991 2.907-6.086.767-2.69 0-2.403 2.632-1.61Z"
        fill="#000"
      />
      <path
        d="M10.566 1.87S9.964.419 8.65.013a.283.283 0 0 0-.269.056c-.326.287-1.199 1.224-1.126 2.992l3.31-1.191Z"
        fill="#F49958"
      />
      <path
        d="M9.975 1.92S9.518.852 8.677.55a.222.222 0 0 0-.248.068c-.212.262-.685.975-.64 2.09l2.186-.788Z"
        fill="#4F2F1D"
      />
      <path
        d="M14.237 2.671s1.152-1.069 2.515-.89a.282.282 0 0 1 .22.164c.178.396.581 1.613-.221 3.19L14.237 2.67Z"
        fill="#F49958"
      />
      <path
        d="M14.757 2.963s.859-.78 1.752-.705a.222.222 0 0 1 .195.166c.085.326.218 1.172-.288 2.166l-1.659-1.627Z"
        fill="#4F2F1D"
      />
      <path
        d="M18.492 7.614c-.68 2.657-4.634 3.148-7.893 2.316C7.339 9.098 4.25 6.8 4.93 4.145c.008-.033 1.094-.135 1.512-.62 1.326-1.54 3.766-2.243 6.245-1.61 2.444.626 4.23 2.373 4.685 4.329.152.652 1.128 1.337 1.12 1.37Z"
        fill="#F49958"
      />
      <path
        d="M8.408 4.055c-1.75-.348-2.37 2.219-2.546 3.233.96 1.081 2.657 2.064 4.285 2.577.44-1.121.258-2.625.258-2.625-.318-3.065-1.997-3.185-1.997-3.185ZM15.097 5.515s-2.14-.624-3.311 1.946c0 0-.71 1.377-.819 2.549 1.524.503 3.628.376 4.992-.052.266-.971.945-4.015-.862-4.443Z"
        fill="#F9E5C5"
      />
      <path
        d="M11.586 9.918c-.086.335-.617.65-1.14.516-.521-.134-.836-.664-.75-.998.085-.333.539-.346 1.06-.212.523.134.915.361.83.694Z"
        fill="#4F2F1D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.486 7.453a.383.383 0 0 1-.289.458l-1.982.449a.383.383 0 0 1-.17-.748l1.983-.448a.383.383 0 0 1 .458.29ZM9.391 7.75a.383.383 0 0 1-.537.071L7.239 6.587a.383.383 0 0 1 .466-.609l1.614 1.234c.169.129.2.37.072.538Z"
        fill="#4F2F1D"
      />
      <path
        d="M.363 10.42c1.018-.779 3.522-2.212 6.753-.668a3.398 3.398 0 0 1 1.367 1.162c.694 1.036 1.574 2.867 1.225 4.97-.052.321-.053 1.285.163 1.88.144.399.374 1.148 1.75 1.193 0 0-3.606 1.768-6.515.062-3.665-2.151-1.86-5.235-2.602-7.197-.308-.813-1.19-1.118-1.999-1.08-.2.009-.294-.206-.142-.322Z"
        fill="#F49958"
      />
      <path
        d="M3.96 9.394c.258-.138.825-.302.825-.302-.096-.042-.61-.031-.816-.02-1.682.076-2.951.847-3.606 1.348-.15.116-.057.33.143.322.58-.028 1.197.123 1.614.511.429-.123 1.21.397 1.21.397.045-.397-.064-1.034-.064-1.034.368-.101 1.05.002 1.05.002-.078-.271-.674-.636-.674-.636.316-.073 1.008-.157 1.008-.157-.226-.29-.69-.43-.69-.43Z"
        fill="#F9E5C5"
      />
    </svg>
  );
}
