type Props = {
  sizeRatio?: number | undefined;
};

export default function MiloStarEye({ sizeRatio = 1 }: Props) {
  return (
    <svg
      width={139 * sizeRatio}
      height={160 * sizeRatio}
      viewBox="0 0 139 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.3078 124.549L84.2404 145.226C84.2404 145.226 97.8018 142.714 96.0761 149.677C94.544 155.847 76.8915 156.041 76.1255 151.447C74.8712 143.97 75.6204 125.729 75.6204 125.729L84.3078 124.549Z"
        fill="#C17F50"
      />
      <path
        d="M58.8772 124.659L58.0944 145.378C58.0944 145.378 44.6762 143.65 46.1156 150.41C47.3952 156.403 65.0139 155.308 65.9736 150.596C67.5393 142.942 67.5393 124.743 67.5393 124.743L58.8772 124.659Z"
        fill="#C17F50"
      />
      <path
        d="M106.853 119.159C116.539 120.345 134.6 113.907 135.364 97.7534C135.487 95.0909 132.026 94.904 130.628 97.1724C127.796 101.745 120.892 110.723 108.877 109.16C89.8151 106.681 94.5757 117.656 106.853 119.159Z"
        fill="#C17F50"
      />
      <path
        d="M46.583 10.9541C45.2431 10.4666 38.9256 7.37075 31.2519 10.7185C23.5702 14.0743 19.8349 32.4625 26.1605 41.7419C32.4943 51.0294 42.3117 24.7676 43.3998 21.8261C44.4879 18.8847 48.7348 11.7342 46.583 10.9541Z"
        fill="#C17F50"
      />
      <path
        d="M14.6905 80.0407C15.1766 78.4661 16.0006 77.0729 17.0058 75.8445C18.5136 74.0061 20.4334 72.514 22.5262 71.3434C24.619 70.181 26.893 69.3401 29.2001 68.9361C31.0128 68.6311 32.7348 69.843 33.0479 71.6566C33.361 73.4703 32.1498 75.1933 30.3371 75.5065C29.2248 75.6961 28.0054 76.0836 26.8354 76.6277C25.962 77.0317 25.1133 77.518 24.3553 78.0621C23.2183 78.87 22.2872 79.8016 21.7105 80.6672C21.4221 81.0959 21.2161 81.5081 21.0843 81.8873C20.9524 82.2583 20.903 82.588 20.903 82.9013C20.903 83.2063 20.9524 83.4949 21.076 83.8329C21.2655 84.3357 21.6445 84.9623 22.4355 85.7125C23.2183 86.4544 24.413 87.2871 26.0938 88.1197C27.7417 88.9358 28.4173 90.9309 27.6099 92.5797C26.7942 94.2284 24.8002 94.9044 23.1524 94.0965C20.3015 92.6868 18.1346 91.0875 16.602 89.1997C15.8358 88.2598 15.2425 87.2376 14.8388 86.1659C14.4351 85.0942 14.2456 83.9813 14.2456 82.9013C14.2538 81.9038 14.4104 80.9475 14.6905 80.0407Z"
        fill="#C17F50"
      />
      <path
        d="M20.2909 94.7972C19.3445 91.4029 21.3274 87.8836 24.7199 86.9368C28.1124 85.9899 31.6297 87.9739 32.5761 91.3682C33.5225 94.7625 31.5395 98.2818 28.1471 99.2287C24.7546 100.176 21.2373 98.1915 20.2909 94.7972Z"
        fill="#C17F50"
      />
      <path
        d="M97.0659 12.8449C87.4001 3.16219 49.3138 2.34824 39.3853 13.5656C37.3165 15.9057 34.0691 19.1191 32.4158 23.8841C30.2961 29.9888 29.16 47.5651 29.6008 47.6414H29.5839C29.3041 63.4797 29.9654 115.505 39.9703 124.993C49.6106 134.141 89.7742 135.455 101.984 125.391C118.339 111.91 104.96 20.7555 97.0659 12.8449Z"
        fill="#E3A263"
      />
      <path
        d="M28.1518 47.4886C28.5673 47.5734 28.9997 47.6582 29.4406 47.726H29.4236C29.4236 47.7345 29.4236 47.7514 29.4236 47.7599H45.016V24.3672C45.016 24.3672 14.0263 24.7742 14.2468 35.0419C14.2976 37.7127 15.9001 44.9111 28.1518 47.4886Z"
        fill="#E3A263"
      />
      <path
        d="M40.1828 34.4312C40.2251 37.5344 35.1125 43.5458 28.728 43.6221C22.3351 43.6985 17.0868 37.8227 17.0444 34.7195C17.002 31.6163 22.157 29.4118 28.55 29.3355C34.9429 29.2592 40.1488 31.328 40.1828 34.4312Z"
        fill="#3B1C0C"
      />
      <path
        d="M28.7876 31.8287C28.7961 32.6596 27.3293 33.3464 25.4979 33.3718C23.675 33.3972 22.1827 32.7444 22.1742 31.9135C22.1658 31.0826 23.6326 30.3958 25.464 30.3703C27.2954 30.3449 28.7791 30.9978 28.7876 31.8287Z"
        fill="#6C442F"
      />
      <path
        d="M90.965 15.6974C92.0647 14.7899 96.9832 9.75958 105.335 10.3484C113.696 10.9421 123.366 27.0224 120.508 37.8829C117.645 48.7539 99.6099 27.2872 97.6007 24.879C95.5916 22.4708 89.1979 17.1522 90.965 15.6974Z"
        fill="#C17F50"
      />
      <path
        d="M30.4335 50.1368C30.4842 50.1622 32.055 50.9307 34.5547 51.7076C37.0544 52.4761 40.4999 53.253 44.3171 53.253C46.0399 53.253 47.8386 53.0926 49.6543 52.6957C51.014 52.4001 52.3905 51.9694 53.7501 51.3783C55.7854 50.4915 57.7784 49.2248 59.6363 47.5104C61.4942 45.7877 63.2001 43.6173 64.6864 40.9149C65.0749 40.214 64.8131 39.3357 64.1122 38.9472C63.4112 38.5588 62.5329 38.8205 62.1445 39.5215C60.9453 41.7087 59.611 43.4653 58.1922 44.8756C57.1281 45.9397 56.0218 46.8011 54.8902 47.5104C53.1843 48.5745 51.4109 49.2839 49.629 49.7315C47.8471 50.1791 46.0483 50.3564 44.3171 50.3564C40.8969 50.3564 37.7215 49.647 35.4161 48.9376C34.2591 48.583 33.3301 48.2283 32.6883 47.9665C32.3674 47.8314 32.1225 47.7216 31.9621 47.654C31.8776 47.6202 31.8185 47.5865 31.7847 47.5696C31.7678 47.5611 31.7509 47.5527 31.7425 47.5527H31.734L31.5229 47.9749L31.734 47.5527L31.5229 47.9749L31.734 47.5527L31.3456 48.3465L31.734 47.5527L31.3456 48.3465L31.734 47.5527L31.1007 48.8279L31.734 47.5527L31.1007 48.8279L31.734 47.5527C31.0162 47.198 30.1464 47.4936 29.7917 48.2114C29.4201 48.9123 29.7157 49.7821 30.4335 50.1368Z"
        fill="#C17F50"
      />
      <path
        d="M119.317 80.0407C118.831 78.4661 118.007 77.0729 117.002 75.8445C115.494 74.0061 113.574 72.514 111.482 71.3434C109.389 70.181 107.115 69.3401 104.808 68.9361C102.995 68.6311 101.273 69.843 100.96 71.6566C100.647 73.4703 101.858 75.1933 103.671 75.5065C104.783 75.6961 106.002 76.0836 107.172 76.6277C108.046 77.0317 108.894 77.518 109.652 78.0621C110.789 78.87 111.721 79.8016 112.297 80.6672C112.586 81.0959 112.792 81.5081 112.923 81.8873C113.055 82.2583 113.105 82.588 113.105 82.9013C113.105 83.2063 113.055 83.4949 112.932 83.8329C112.742 84.3357 112.363 84.9623 111.572 85.7125C110.789 86.4544 109.595 87.2871 107.914 88.1197C106.266 88.9358 105.59 90.9309 106.398 92.5797C107.214 94.2284 109.208 94.9044 110.855 94.0965C113.706 92.6868 115.873 91.0875 117.406 89.1997C118.172 88.2598 118.765 87.2376 119.169 86.1659C119.573 85.0942 119.762 83.9813 119.762 82.9013C119.754 81.9038 119.597 80.9475 119.317 80.0407Z"
        fill="#C17F50"
      />
      <path
        d="M113.717 94.7972C114.663 91.4029 112.68 87.8836 109.288 86.9368C105.895 85.9899 102.378 87.9739 101.432 91.3682C100.485 94.7625 102.468 98.2818 105.861 99.2287C109.253 100.176 112.77 98.1915 113.717 94.7972Z"
        fill="#C17F50"
      />
      <path
        d="M36.2935 14.1792L38.5253 19.2665L43.8424 20.8419L38.7551 23.0738L37.1797 28.3909L34.9478 23.3036L29.6307 21.7281L34.7181 19.4963L36.2935 14.1792Z"
        fill="#3B1C0C"
      />
      <path
        d="M62.0745 16.1577L64.3063 21.245L69.6234 22.8205L64.5361 25.0523L62.9607 30.3694L60.7288 25.2821L55.4117 23.7066L60.4991 21.4748L62.0745 16.1577Z"
        fill="#3B1C0C"
      />
    </svg>
  );
}
