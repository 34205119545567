import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'videojs-http-source-selector';
import 'video.js/dist/video-js.css';
import './videojs-http-source-selector.css';

import { Logger } from 'ms-utils/app-logging';
import { sessionStorageDb } from 'ms-utils/localStorageDb';

import type { AspectRatio } from '.';

type Player = ReturnType<typeof videojs>;

type Props = {
  id: string;
  aspectRatio: AspectRatio;
  autoPlay?: boolean | undefined; // true is not guaranteed to autoplay, see: https://videojs.com/blog/autoplay-best-practices-with-video-js/
  silentAutoPlay?: boolean | undefined;
  resumableWith?: { resumeToken: string } | undefined;
  onVideoEnd?: (() => void) | undefined;
};

/**
 * A video player component that uses Video.js as the player, and bunny.net as a hosting service.
 *
 * See: https://bunny.net/stream/
 * See: https://videojs.com/
 */
export default function BunnyVideo({
  id,
  aspectRatio,
  autoPlay = false,
  silentAutoPlay = false,
  resumableWith,
  onVideoEnd,
}: Props) {
  const cdnHost = 'https://videos.mathspace.co';
  const resumeToken = resumableWith?.resumeToken ?? null;

  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);

  // Initialize the player on mount
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      containerRef.current?.appendChild(videoElement);

      // See: https://videojs.com/guides/options/
      playerRef.current = videojs(videoElement, {
        controls: true,
        crossorigin: 'anonymous', // Required for captions to fetch with CORS
        playbackRates: [0.5, 1, 1.5, 2],
        preload: 'metadata',
        responsive: true,
        html5: {
          // vhs stands for videojs http streaming.
          // It's the part responsible for adaptively choosing an appropriate
          // quality stream based on bandwidth, video dimensions, etc.
          vhs: {
            // The dimensions of the HTMLVideoElement DOM node affect which
            // quality level is chosen. But by default they stupidly use the
            // CSS pixels, not the actual pixels to determine this, which means
            // they choose the wrong stream 100% of the time on High DPI displays.
            // This means the video is always blurry. Here we configure it to use
            // the CSS pixel dimensions after being scaled by the devicePixelRatio,
            // which gives a rough approximation of the actual pixels used to
            // render the video.
            useDevicePixelRatio: true,
          },
        },
        plugins: {
          httpSourceSelector: {
            default: 'auto',
          },
        },
      });
      // Initialises the videojs-http-source-selector plugin
      // @ts-ignore
      playerRef.current.httpSourceSelector();
    }

    return () => {
      const player = playerRef.current;
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    playerRef.current?.aspectRatio(aspectRatio);
  }, [aspectRatio]);

  useEffect(() => {
    // Not guaranteed to autoplay, see: https://videojs.com/blog/autoplay-best-practices-with-video-js/
    playerRef.current?.autoplay(autoPlay);
  }, [autoPlay]);

  useEffect(() => {
    playerRef.current?.muted(silentAutoPlay && autoPlay);
  }, [autoPlay, silentAutoPlay]);

  useEffect(() => {
    const handleEnded = () => {
      if (onVideoEnd) {
        onVideoEnd();
      }
    };
    playerRef.current?.on('ended', handleEnded);
    return () => {
      playerRef.current?.off('ended', handleEnded);
    };
  }, [onVideoEnd]);

  useEffect(() => {
    const token = `${id}-${resumeToken}`;
    let requestId: number;
    const handleTimeUpdate = () => {
      if (resumeToken !== null) {
        if (requestId) {
          cancelAnimationFrame(requestId);
        }
        requestId = requestAnimationFrame(() => {
          sessionStorageDb.set(token, playerRef.current?.currentTime());
        });
      }
    };
    if (resumeToken !== null) {
      const lastTime = sessionStorageDb.get(token);
      if (lastTime !== null) {
        playerRef.current?.currentTime(parseFloat(lastTime));
      }
    }
    playerRef.current?.on('timeupdate', handleTimeUpdate);
    return () => {
      playerRef.current?.off('timeupdate', handleTimeUpdate);
    };
  }, [id, resumeToken]);

  useEffect(() => {
    const abortController = new AbortController();

    // Add the video to the player
    playerRef.current?.src({
      src: `${cdnHost}/${id}/playlist.m3u8`,
      type: 'application/x-mpegURL',
    });

    // If captions exist, add them to the player
    const languages = [
      { lang: 'en', label: 'English', default: true },
      { lang: 'es', label: 'Español', default: false },
    ];
    const fetchCaptions = (language: (typeof languages)[number]) => {
      const url = `${cdnHost}/${id}/captions/${language.lang}.vtt`;
      return fetch(url, { signal: abortController.signal })
        .then(response => {
          if (response.ok) {
            return { url, language };
          }
          if (response.status === 404) {
            // We expect a 404 when the video has no captions available
            return null;
          }
          throw Error(`Failed to fetch ${url}: ${response.status}`);
        })
        .catch(error => {
          if (error instanceof DOMException && error.name === 'AbortError') {
            // We expect to be aborted when the component unmounts
            return null;
          }
          throw Error(`Failed to fetch ${url}: ${error}`);
        });
    };

    Promise.all(languages.map(lang => fetchCaptions(lang)))
      .then(results => {
        results.forEach(result => {
          if (result) {
            const { url, language } = result;
            playerRef.current?.addRemoteTextTrack({
              kind: 'captions',
              label: language.label,
              srclang: language.lang,
              src: url,
              default: language.default,
            });
          }
        });
      })
      .catch(error => {
        Logger.error(error, { tags: { component: 'BunnyVideo' } });
      });

    return () => {
      abortController.abort();
    };
  }, [id]);

  return <div ref={containerRef} />;
}
