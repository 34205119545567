import { Fragment, type ReactNode } from 'react';

import { VSpacer } from 'ms-pages/Lantern/primitives/Stack';

export function ModalFooter({
  children,
  isCentered = false,
  hasSpacer = true,
}: {
  children: ReactNode;
  isCentered?: boolean;
  hasSpacer?: boolean;
}) {
  return (
    <Fragment>
      {hasSpacer && <VSpacer height={40} />}
      <div
        css={[
          { padding: '0 40px 40px 40px' },
          {
            display: 'flex',
            justifyContent: isCentered ? 'center' : 'flex-start',

            '> a, > button': {
              minWidth: 164,
            },
          },
        ]}
      >
        {children}
      </div>
    </Fragment>
  );
}

export default ModalFooter;
