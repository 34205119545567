import { StyleSheet } from 'aphrodite';
import { useRef } from 'react';

import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Input from 'ms-ui-primitives/Input';
import { styled } from 'ms-utils/emotion';

const HEIGHT = 10 * BASE_UNIT;

const styles = StyleSheet.create({
  // this is just for the segmented input/button UI and it might be temporary
  segmentedInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
    height: HEIGHT,
  },
});

const segmentedButtonStyle = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: HEIGHT,
};

const Row = styled({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

type Props = {
  fullTextBookLink: string;
};

export default function CopyLinkToClipboard({ fullTextBookLink }: Props) {
  const inputWithLinkRef = useRef<HTMLInputElement>(null);

  return (
    <Row>
      <Input
        readOnly
        value={fullTextBookLink}
        aphroditeStyles={[styles.segmentedInput]}
        ref={inputWithLinkRef}
      />

      <Button
        styles={segmentedButtonStyle}
        type="secondary"
        onClick={() => {
          const input = inputWithLinkRef.current;

          if (input != null) {
            input.select();
            document.execCommand('copy');
          }
        }}
      >
        Copy
      </Button>
    </Row>
  );
}
