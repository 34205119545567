/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus = {
    readonly status: StudentStrandStatusEnum;
    readonly lastWorkedOnDiagnosticCheckIn: {
        readonly endedAt: string | null;
        readonly hasFinishedDiagnosticPhase: boolean;
    } | null;
    readonly " $refType": "StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus";
};
export type StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus$data = StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus;
export type StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus$key = {
    readonly " $data"?: StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapCurriculumStrandSubstrands_studentStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CheckIn",
      "kind": "LinkedField",
      "name": "lastWorkedOnDiagnosticCheckIn",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasFinishedDiagnosticPhase",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StudentStrandStatus",
  "abstractKey": null
};
(node as any).hash = 'c82c8b1f3c4ece94968ce003acacade3';
export default node;
