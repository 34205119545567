import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M15.4592 3.15143L10.6113 7.99985L15.4592 12.848C16.1803 13.5694 16.1803 14.738 15.4592 15.4593C15.0989 15.8196 14.6265 15.9999 14.1544 15.9999C13.6814 15.9999 13.209 15.8199 12.849 15.4593L8.00004 10.6106L3.15143 15.4593C2.79118 15.8196 2.31876 15.9999 1.8462 15.9999C1.37378 15.9999 0.901677 15.8199 0.541103 15.4593C-0.18 14.7382 -0.18 13.5696 0.541103 12.848L5.38888 7.9998L0.540827 3.15143C-0.180276 2.43033 -0.180276 1.26152 0.540827 0.540414C1.26179 -0.180138 2.42992 -0.180138 3.15116 0.540414L7.99999 5.38883L12.8485 0.540414C13.5698 -0.180138 14.7381 -0.180138 15.4589 0.540414C16.1803 1.26152 16.1803 2.43033 15.4592 3.15143Z" />
);

const CrossBold = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

CrossBold.displayName = 'CrossBold';

export default CrossBold;
