import { map } from 'ramda';
import { useState, useMemo } from 'react';

import createNewAdaptiveExperienceTask from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state/createNewAdaptiveExperienceTask';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { CreateAdaptiveTaskVariables } from 'ms-pages/Teacher/components/CreateTask/types';

import type { State } from './state';
import { initialState as getInitialState } from './state';
import * as updaters from './updaters';

type Updaters = typeof updaters;

type TransformedUpdaters = {
  [K in keyof Updaters]: (arg: Parameters<Updaters[K]>[0]) => void;
};

export const transformUpdaters = (
  setState: (update: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

// New adaptive experience has a recommended value where we don't set a target mastery for the task.
// Right now the bulk CATFA flow expects that a target mastery be set. We use -1 to be outside the
// range of standard positive numbers to indicate that the target mastery should not be set.
export const NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE = -1;

export function createNewAdaptiveExperienceTaskVariables({
  selectedStudents,
  selectedClasses,
  excludedStudents,
  subtopicId,
  targetMastery,
  taskDueDate,
  taskName,
  taskStartDate,
  disableCalculator,
  assignedCheckinSkillIds,
}: State) {
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    selectedClasses,
    excludedStudents,
  );
  const variables = {
    title: taskName,
    // This is checked at runtime, we can't reasonably guarantee that this will be
    // populated when this hook initializes. Big TODO here.
    subtopicId: subtopicId as string,
    assignedClassIds: completeClassIds,
    assignedStudentIds: selectedStudents.map(s => s.id),
    partiallyAssignedClasses: partialClasses,
    dueDate: taskDueDate.toISOString(),
    startDate: taskStartDate.toISOString(),
    allowCalculator: !disableCalculator,
    assignedCheckinSkillIds,
  };

  if (targetMastery !== NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE) {
    return {
      ...variables,
      targetMastery,
    };
  }

  return variables;
}

export const roundMastery = (mastery: number) => {
  if (mastery === NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE)
    return mastery;
  if (mastery <= 50) return 50;
  if (mastery <= 75) return 75;
  return 100;
};

export type CombinedState = {
  readonly values: State;
  readonly updaters: TransformedUpdaters;
};

/*
 * This returns the mutation hooks as well as managing
 * internal state for the entire form.
 *
 * Usage:
 * const [state, [createTask, {response, error}]] = useCreateAdaptiveTaskState({...})
 * Pass a subtopicId into the hook
 * Pass the state variable into the form component you need to render
 * Call createTask to fire the mutation
 */
export function useCreateNewAdaptiveExperienceTaskState({
  subtopicId,
  targetMastery = 100,
  selectedClasses = [],
  selectedStudents = [],
  excludedStudents = [],
  taskStartDate: providedTaskStartDate,
  taskDueDate: providedTaskDueDate,
  taskName: providedTaskName,
  assignedCheckinSkillIds,
}: CreateAdaptiveTaskVariables) {
  const { taskStartDate, taskDueDate, taskName, ...initialState } =
    getInitialState();

  const [state, setState] = useState({
    ...initialState,
    subtopicId,
    taskStartDate:
      providedTaskStartDate != null ? providedTaskStartDate : taskStartDate,
    taskDueDate:
      providedTaskDueDate != null ? providedTaskDueDate : taskDueDate,
    taskName: providedTaskName != null ? providedTaskName : taskName,
    targetMastery: roundMastery(targetMastery),
    selectedClasses,
    selectedStudents,
    excludedStudents,
    assignedCheckinSkillIds,
  });

  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);

  const mutation = createNewAdaptiveExperienceTask({
    ...createNewAdaptiveExperienceTaskVariables(state),
    // subtopicid is a special snowflake and we want this to update on the fly
    // @ts-expect-error if ain't broke dont' fix it 🤷‍♂️
    subtopicId: subtopicId != null ? subtopicId : undefined,
  });

  return [{ values: state, updaters: transformedUpdaters }, mutation] as const;
}
