import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';

import type {
  Updater,
  TaskName,
  TaskStartDate,
  TaskDueDate,
  ExtensionDays,
} from '../index';

export const updateTaskName: Updater<[TaskName]> = newTaskName => state => ({
  ...state,
  taskName: newTaskName,
});

export const updateTaskStartDate: Updater<[TaskStartDate]> =
  newDate => state => ({
    ...state,
    taskStartDate: newDate,
  });

export const updateTaskDueDate: Updater<[TaskDueDate]> = newDate => state => ({
  ...state,
  taskDueDate: newDate,
});

export const toggleAdvancedOptions: Updater<[]> = () => state => ({
  ...state,
  isAdvancedOptionsVisible: !state.isAdvancedOptionsVisible,
});

export const updateExtensionDays: Updater<[ExtensionDays]> =
  newValue => state => ({
    ...state,
    extensionDays: newValue,
  });

export const toggleDisableHelp: Updater<[]> = () => state => ({
  ...state,
  disableHelp: !state.disableHelp,
});

export const toggleDisableCalculator: Updater<[]> = () => state => ({
  ...state,
  disableCalculator: !state.disableCalculator,
});

export const toggleDisableRetry: Updater<[]> = () => state => ({
  ...state,
  disableRetry: !state.disableRetry,
});

export const setAssignedClasses: Updater<[readonly ClassSelectionPayload[]]> =
  klasses => state => ({
    ...state,
    selectedClasses: klasses,
  });

export const setAssignedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  selectedStudents: students,
});

export const setExcludedStudents: Updater<
  [readonly StudentSelectionPayload[]]
> = students => state => ({
  ...state,
  excludedStudents: students,
});
