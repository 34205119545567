import { css } from 'aphrodite';
import { findIndex, path, pathEq, uniqBy } from 'ramda';
import {
  type FocusEvent,
  type KeyboardEvent as SyntheticKeyboardEvent,
  memo,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import CheckMarkIcon from 'ms-components/icons/CheckMark';
import CrossIcon from 'ms-components/icons/Cross';
import TriangleDownIcon from 'ms-components/icons/TriangleDown';
import ResizeDetector from 'ms-helpers/ResizeDetector';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import SearchInput from 'ms-ui-primitives/SearchInput/controlled';
import { InvariantViolation } from 'ms-utils/app-logging';

import Option from './Option';
import getStyles from './styles';

export type Theme = 'light' | 'dark';

export type OptionLabel = ReactNode;
export type OptionType<T> = {
  label: OptionLabel;
  value: T;
  isDisabled?: boolean;
};

type SearchProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  emptyResultsMessage?: string;
};

export type Props<T> = {
  block?: boolean | undefined;
  disabled?: boolean | undefined;
  options: ReadonlyArray<OptionType<T>>;
  placeholder?: string | undefined;
  noWrap?: boolean | undefined;
  theme?: Theme | undefined;
  optionItemsShown?: number | undefined; // This will default to 6
  renderOptionsLoadingIndicator?: undefined | (() => ReactNode);
  onOptionListOpen?: undefined | (() => void);
  onOptionListClose?: undefined | (() => void);
  withSearch?: SearchProps | undefined;
  onClear?: () => void;
  optionHeight?: number | undefined;
} & (
  | {
      multi?: false | undefined;
      value: T | undefined | null;
      onChange: (value: T) => void;
      renderSelectedValue?: (values: T | null | undefined) => ReactNode;
    }
  | {
      onSelect: (values: ReadonlyArray<T>) => void;
      multi: true;
      value: ReadonlyArray<T>;
      renderSelection?: (values: ReadonlyArray<T>) => ReactNode;
    }
);

export type State = {
  isFocused: boolean;
  activeIndex: number | undefined;
  width: number | undefined;
};

export const initialState: State = {
  isFocused: false,
  activeIndex: undefined,
  width: undefined,
};

function Select<T extends string | number>(props: Props<T>) {
  const {
    block,
    disabled,
    multi,
    onClear,
    onOptionListClose,
    onOptionListOpen,
    options,
    placeholder = '-',
    renderOptionsLoadingIndicator,
    theme,
    withSearch = false,
    value,
  } = props;
  let onChange: ((value: T) => void) | undefined = undefined;
  let onSelect: ((values: ReadonlyArray<T>) => void) | undefined = undefined;
  if (multi === true) {
    onSelect = props.onSelect;
  } else {
    onChange = props.onChange;
  }

  const [isFocused, setIsFocused] = useState(initialState.isFocused);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    initialState.activeIndex,
  );
  const [width, setWidth] = useState<number | undefined>(initialState.width);

  const inputRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const optionsListRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const syncWidthsTimerId: MutableRefObject<number> = useRef(0);

  const syncWidths = useCallback(() => {
    // Ensure Aphrodite has flushed CSS to DOM
    // see https://github.com/Khan/aphrodite#style-injection-and-buffering
    window.clearTimeout(syncWidthsTimerId.current);
    syncWidthsTimerId.current = window.setTimeout(() => {
      if (inputRef.current != null && optionsListRef.current != null) {
        const newWidth = block
          ? inputRef.current.getBoundingClientRect().width
          : optionsListRef.current.getBoundingClientRect().width;
        if (newWidth !== width) {
          setWidth(newWidth);
        }
      }
    }, 0);
  }, [block, width]);

  const closeOptionsList = useCallback(() => {
    setActiveIndex(undefined);
    setIsFocused(false);
    onOptionListClose?.();
    if (withSearch) {
      withSearch.onChange('');
    }
  }, [onOptionListClose, withSearch]);

  const blur = useCallback(() => {
    if (inputRef.current != null) {
      inputRef.current.blur();
      closeOptionsList();
    }
  }, [closeOptionsList]);

  const focus = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  // NB: Remember that focus-related events in React bubble.
  // https://react.dev/reference/react-dom/components/common#handling-focus-events

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      // Only handle blur when the focus has moved outside the subtree.
      if (!event.currentTarget.contains(event.relatedTarget)) {
        closeOptionsList();
      }
    },
    [closeOptionsList],
  );

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (disabled) return;
      setIsFocused(true);
      if (event.currentTarget.contains(event.relatedTarget)) {
        // We don't want to do anything when swapping focus between children
        return;
      }
      // Only set the active index if the select is focused by clicking on it
      const newActiveIndex = value
        ? findIndex(pathEq(['value'], value), options)
        : 0;
      setActiveIndex(newActiveIndex);
      onOptionListOpen?.();
    },
    [disabled, onOptionListOpen, options, value],
  );

  const onArrowDown = useCallback(() => {
    setActiveIndex(activeIndex => {
      return findNextActiveIndex(options, activeIndex, +1);
    });
  }, [options]);

  const onArrowUp = useCallback(() => {
    setActiveIndex(activeIndex => {
      return findNextActiveIndex(options, activeIndex, -1);
    });
  }, [options]);

  const onEnter = useCallback(() => {
    if (!isFocused) return;
    if (activeIndex === undefined) {
      blur();
    } else {
      const activeOption = options[activeIndex];
      if (activeOption !== undefined && !activeOption.isDisabled) {
        if (!multi && onChange) {
          onChange(activeOption.value);
          // Close the dropdown after selecting an option if it's a single select
          blur();
        } else if (multi === true && onSelect) {
          const selectedValues = value as ReadonlyArray<T>;
          const newSelectedValues = selectedValues.includes(activeOption.value)
            ? selectedValues.filter(v => v !== activeOption.value)
            : [...selectedValues, activeOption.value];
          onSelect(newSelectedValues);
        }
      }
    }
  }, [activeIndex, blur, isFocused, multi, options, onChange, onSelect, value]);

  const onEscape = blur;

  const handleKeyDown = useCallback(
    (event: SyntheticKeyboardEvent<HTMLDivElement>) => {
      let handler: VoidFunction | undefined;
      switch (event.key) {
        case 'ArrowDown':
          handler = onArrowDown;
          break;
        case 'ArrowUp':
          handler = onArrowUp;
          break;
        case 'Enter':
          handler = onEnter;
          break;
        case 'Escape':
          handler = onEscape;
          break;
      }
      if (handler === undefined) return;
      event.preventDefault();
      handler();
    },
    [onArrowDown, onArrowUp, onEnter, onEscape],
  );

  useEffect(() => {
    syncWidths();
    const timerId = syncWidthsTimerId.current;
    return () => {
      // clear any timeouts that haven't fired yet, to prevent memory leaks
      window.clearTimeout(timerId);
    };
  }, [syncWidths]);

  if (uniqBy(path(['value']), props.options).length < props.options.length) {
    throw new InvariantViolation(
      '<Select> component has received option values which are not distinct',
    );
  }

  const styles = getStyles(
    {
      activeIndex,
      isFocused,
      width,
    },
    props,
  );

  // by default, we render "X item[s] selected" for multi-selects
  const renderSelection =
    'renderSelection' in props
      ? props.renderSelection
      : (items: ReadonlyArray<T>) => {
          const n = items?.length ?? 0;
          return `${n} item${n === 1 ? '' : 's'} selected`;
        };

  // by default, we render the selected item's label for single-selects
  // the `prop` renderSelectedValue` can be used to override this
  // It's particularly useful when using the search because if the options
  // are filtered, the selected value might not be in the options list and
  // the default renderSelectedValue would not work
  const renderSelectedValue =
    'renderSelectedValue' in props
      ? props.renderSelectedValue
      : (itemValue: T | null | undefined) =>
          props.options.find(option => option.value === itemValue)?.label ??
          null;

  const input = isUnselected(value)
    ? placeholder
    : multi
    ? renderSelection(value)
    : renderSelectedValue(value);

  // Button comes preloaded with too many behaviours. Firefox prevents
  // scrolling in nested elements, for instance.
  return (
    <div
      className={css(styles.input)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      ref={inputRef}
      role="button"
      tabIndex={0}
    >
      <ResizeDetector onResize={syncWidths}>
        <div
          className={css(styles.option)}
          onMouseDown={e => {
            if (isFocused) {
              // close the options list
              blur();
              // prevent the default browser behaviour, which would re-focus the option list and cause the control to re-open
              e.preventDefault();
            } else {
              focus();
            }
          }}
        >
          <span
            className={css(
              styles.optionText,
              styles.optionTextInput,
              styles.optionTextOverflow,
            )}
          >
            {input}
          </span>

          {!multi && onClear != null && value != null && (
            <Button
              height={0}
              padding={8}
              label="Clear"
              color="grey"
              onMouseDown={e => {
                // prevent the input from receiving the mouse down event
                // when clicking the clear button, otherwise the focused state will be toggled.
                e.stopPropagation();
                // and prevent this event from causing the select to gain focus
                e.preventDefault();
              }}
              onClick={() => {
                onClear();
                closeOptionsList();
              }}
            >
              <CrossIcon size={16} />
            </Button>
          )}

          <TriangleDownIcon
            aphroditeStyles={[styles.icon, isFocused && styles.iconOpen]}
            color={
              props.disabled
                ? colors.iron
                : props.theme === 'dark'
                ? 'currentColor'
                : colors.shuttleGray
            }
          />
        </div>
      </ResizeDetector>
      <div className={css(styles.optionListWrapper)}>
        {withSearch && (
          <div className={css(styles.searchWrapper)}>
            <SearchInput
              placeholder={withSearch.placeholder ?? 'Search...'}
              value={withSearch.value}
              onChange={newValue => withSearch.onChange(newValue)}
            />
          </div>
        )}
        <div
          ref={optionsListRef}
          className={css(styles.optionList)}
          onMouseLeave={() => {
            setActiveIndex(undefined);
          }}
          onTouchStart={e => e.stopPropagation()}
          // This is very important. Chrome 127 has introduced dodgy functionality
          // which forces any scroll view to be focusable (as if you had set an explicit
          // tab index of >= 0). You cannot opt of out this. To prevent this scroll
          // view from gaining focus (and thus our inputRef element losing focus) we
          // prevent default on all mouse down events in the subtree.
          // https://developer.chrome.com/blog/keyboard-focusable-scrollers
          onMouseDown={e => e.preventDefault()}
        >
          {props.options.map((option, i) => (
            <Option
              multi={multi}
              key={i}
              active={i === activeIndex}
              disabled={disabled || option.isDisabled}
              icon={theme === 'dark' || multi ? undefined : CheckMarkIcon}
              index={i}
              onClick={() => {
                if (!multi && 'onChange' in props && !option.isDisabled) {
                  props.onChange(option.value);
                  // Close the dropdown and reset the search input after selecting an option if it's a single select
                  blur();
                  if (props.withSearch) {
                    props.withSearch.onChange('');
                  }
                } else if (multi && 'onSelect' in props) {
                  const selectedValues = value as ReadonlyArray<T>;
                  const newSelectedValues = selectedValues.includes(
                    option.value,
                  )
                    ? selectedValues.filter(v => v !== option.value)
                    : [...selectedValues, option.value];
                  props.onSelect(newSelectedValues);
                }
              }}
              onMouseEnter={() => {
                if (!option.isDisabled) {
                  setActiveIndex(i);
                }
              }}
              selected={
                props.multi
                  ? props.value.includes(option.value)
                  : option.value === props.value
              }
              styles={styles}
              label={option.label}
              value={option.value}
            />
          ))}

          {withSearch &&
            withSearch.value !== '' &&
            props.options.length === 0 &&
            !renderOptionsLoadingIndicator && (
              <div className={css(styles.emptySearch)}>
                <BodyM color="grey90">
                  {withSearch.emptyResultsMessage ?? 'No results found'}
                </BodyM>
              </div>
            )}

          {renderOptionsLoadingIndicator && (
            <div className={css(styles.loadingIndicatorWrapper)}>
              {renderOptionsLoadingIndicator()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function propsAreEqual<T>(prevProps: Props<T>, nextProps: Props<T>): boolean {
  return (
    prevProps.disabled === nextProps.disabled &&
    prevProps.value === nextProps.value &&
    prevProps.withSearch?.value === nextProps.withSearch?.value &&
    prevProps.renderOptionsLoadingIndicator ===
      nextProps.renderOptionsLoadingIndicator &&
    prevProps.options === nextProps.options &&
    prevProps.placeholder === nextProps.placeholder
  );
}

export default memo(Select, propsAreEqual) as typeof Select;

function findNextActiveIndex<T>(
  options: ReadonlyArray<{ isDisabled?: boolean; value: T }>,
  activeIndex: State['activeIndex'],
  increment: number,
) {
  const activeValue = options[activeIndex ?? -1]?.value;
  const enabledOptionsValues = options
    .filter(o => !o.isDisabled)
    .map(o => o.value);
  const activeIndexAmongEnabledOptions = enabledOptionsValues.findIndex(
    value => value === activeValue,
  );

  const nextActiveValue =
    enabledOptionsValues[
      modulo(
        activeIndexAmongEnabledOptions + increment,
        enabledOptionsValues.length,
      )
    ];
  const nextActiveIndex = options.findIndex(o => o.value === nextActiveValue);

  return nextActiveIndex > -1 ? nextActiveIndex : undefined;
}

function modulo(a: number, b: number) {
  return ((a % b) + b) % b;
}

function isUnselected<T>(value: T | readonly T[] | null | undefined) {
  return value == null || (Array.isArray(value) && value.length === 0);
}
