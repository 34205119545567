import styled from '@emotion/styled';
import { StyleSheet } from 'aphrodite';
import {
  useCallback,
  type FocusEvent as SyntheticFocusEvent,
  useRef,
} from 'react';

import CrossIcon from 'ms-components/icons/Cross';
import SearchIcon from 'ms-components/icons/Search';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Input from 'ms-ui-primitives/Input';
import Stack from 'ms-ui-primitives/Stack';

const ICON_BUTTON_SIZE = 4 * BASE_UNIT;

const inputStyles = StyleSheet.create({
  searchInput: {
    border: `1px solid ${colors.ironLight}`,
    background: colors.white,
    // leave space for the clear button and the search icon
    paddingRight: 2 * ICON_BUTTON_SIZE + 8 * BASE_UNIT,
  },
  flat: {
    borderColor: 'transparent',
    background: colors.ironLight,
    color: colors.cloudBurst,
  },
});

const Wrapper = styled.div({
  display: 'flex',
});

const InputWrapper = styled.div({
  position: 'relative',
  flex: '0 0 100%',
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  flat?: boolean;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  onFocus?: (e: SyntheticFocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticFocusEvent<HTMLInputElement>) => void;
};

export default function SearchInput({
  flat,
  value,
  onChange,
  placeholder,
  onFocus,
  onBlur,
}: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const clear = useCallback(() => {
    onChange('');
    // Focus the search input after clearing it, so that the user can resume typing
    // in it to search for something else. Made as part of the changes to our
    // Select primitive component.
    inputRef.current?.focus();
  }, [onChange]);

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          aphroditeStyles={[
            inputStyles.searchInput,
            !!flat && inputStyles.flat,
          ]}
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
        />

        <Stack.H
          gap={2}
          center
          css={{ position: 'absolute', right: 4 * BASE_UNIT }}
        >
          {value !== '' && (
            <Button isCircle onClick={clear} label="Clear">
              <CrossIcon size={ICON_BUTTON_SIZE} />
            </Button>
          )}

          <SearchIcon size={ICON_BUTTON_SIZE} color={colors.grey} />
        </Stack.H>
      </InputWrapper>
    </Wrapper>
  );
}
