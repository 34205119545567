import MathInput from './MathInput';
import JsxContent from '../../../../ms-components/JsxContent';
import { colors } from '../../styles';
// eslint-disable-next-line
import type { TranspiledJsxContent, Latex } from '../../types';

export type { Latex as MathExpressionQuestionAnswer };

type Props = {
  text: TranspiledJsxContent;
  onChange: (latex: Latex) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  autoFocusMathInput: boolean;
  accessibilityModeConfig: {
    borderColor: string;
    borderWidth: number;
  } | null;
};

export default function MathExpressionQuestion({
  text,
  onChange,
  onKeyDown,
  autoFocusMathInput,
  accessibilityModeConfig,
}: Props) {
  return (
    <>
      {text !== '' ? (
        <JsxContent transpiledJsx={text} />
      ) : (
        <div style={{ color: colors.peachPink }}>
          Question text failed to render
        </div>
      )}
      <div style={{ height: 30 }} />
      <MathInput
        onChange={onChange}
        onKeyDown={onKeyDown}
        autoFocus={autoFocusMathInput}
        accessibilityModeConfig={accessibilityModeConfig}
      />
    </>
  );
}
