import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const School = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <path d="M0.46875 10.5437C0.209875 10.5437 0 10.7535 0 11.0125V15.5312C0 15.79 0.209875 16 0.46875 16H2.075V10.5437H0.46875Z" />
    <path d="M15.5312 10.5437H13.925V16H15.5312C15.7901 16 16 15.79 16 15.5312V11.0125C16 10.7535 15.7901 10.5437 15.5312 10.5437Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46874 3.17937L13.7169 5.56494C13.9525 5.67206 14.0567 5.94994 13.9496 6.18566C13.8711 6.35841 13.7007 6.46056 13.5226 6.46056C13.4577 6.46056 13.3918 6.447 13.3289 6.41841L12.9875 6.26319V15.5312C12.9875 15.7901 12.7776 16 12.5187 16H9.97496V12.5188C9.97496 12.2599 9.76508 12.05 9.50621 12.05H6.49377C6.23489 12.05 6.02502 12.2599 6.02502 12.5188V16H3.48126C3.22239 16 3.01251 15.7901 3.01251 15.5312V6.26316L2.67108 6.41838C2.43545 6.52547 2.15751 6.42131 2.05039 6.18563C1.94323 5.94994 2.04745 5.67206 2.28314 5.56494L7.53124 3.17937V0.46875C7.53124 0.209875 7.74111 0 7.99999 0H10.5C10.7589 0 10.9688 0.209875 10.9688 0.46875V1.53125C10.9688 1.79013 10.7589 2 10.5 2H8.46874V3.17937ZM8 9.5C8.82843 9.5 9.5 8.82843 9.5 8C9.5 7.17157 8.82843 6.5 8 6.5C7.17157 6.5 6.5 7.17157 6.5 8C6.5 8.82843 7.17157 9.5 8 9.5Z"
    />
  </Icon>
);

School.displayName = 'School';

export default School;
