import { css, StyleSheet } from 'aphrodite';

import LogoImage from 'ms-components/logos/Logo';

const GUTTER = 24;

const styles = StyleSheet.create({
  logo: {
    marginBottom: GUTTER,
  },
  link: {
    outline: 'none',
  },
});

const Logo = () => (
  <div className={css(styles.logo)}>
    <a href="/" className={css(styles.link)}>
      <LogoImage hasText />
    </a>
  </div>
);

export default Logo;
