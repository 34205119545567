import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay';

import { SUBPROBLEM_INPUT_VERTICAL_MARGIN } from 'ms-components/PreviewProblem/components/constants';
import type { LegacyDatum } from 'ms-components/math/GraphPlot';
import GraphPlotReadOnly from 'ms-components/math/GraphPlotReadOnly';
import HistogramReadOnly from 'ms-components/math/HistogramReadOnly';
import MathContent from 'ms-components/math/MathContent';
import MultipleChoiceReadOnly from 'ms-components/math/MultipleChoiceReadOnly';
import NumberLineIntervalsReadOnly from 'ms-components/math/NumberLineIntervalsReadOnly';
import { deserializeCoordinateValues } from 'ms-components/math/private-shared/legacyTransformers/numberLine';
import { toInternalValueFormat as toGraphPlotInternalValueFormat } from 'ms-pages/Work/utils/GraphPlot';
import {
  graphQlUnitEnumToLatex,
  toInternalMode,
  toInternalValueFormat as toNumberLineInternalValueFormat,
} from 'ms-pages/Work/utils/NumberLine';
import { InvariantViolation } from 'ms-utils/app-logging';

import type {
  SubproblemInput_subproblem$key,
  SubproblemType,
} from './__generated__/SubproblemInput_subproblem.graphql';

export const InputWrapper = styled.div({
  marginBottom: SUBPROBLEM_INPUT_VERTICAL_MARGIN,
});
export default function SubproblemInput({
  subproblemKey,
}: {
  subproblemKey: SubproblemInput_subproblem$key;
}) {
  const subproblem = useFragment(
    graphql`
      fragment SubproblemInput_subproblem on SubproblemContent {
        subproblemType
        initialInputConfiguration {
          __typename
          ... on LatexAnswerConfig {
            template
          }
          ... on MultipleChoiceConfig {
            options {
              optionContent
              optionKey
            }
          }
          ... on NumberLineConfig {
            mode
            unit
            start
            end
            minorTicks
            majorTicks
            numberLineValues {
              lowerBound {
                inclusive
                value
              }
              substatus
              upperBound {
                inclusive
                value
              }
            }
          }
          ... on LegacyGraphPlotConfig {
            requestDatum
          }
          ... on HistogramConfig {
            doesDrawBars
            doesDrawLines
            dragHandleAlignment
            hasGapBetweenBars
            mainLabel
            histogramValues {
              title
              value
            }
            xAxisLabel
            yAxisLabel
            yIncrement
            yMax
            yMin
          }
        }
      }
    `,
    subproblemKey,
  );

  if (subproblem == null) {
    throw new Error('Subproblem not found');
  }

  const { subproblemType } = subproblem;
  switch (subproblemType) {
    case 'INLINE': {
      return (
        <>
          {subproblem.initialInputConfiguration.__typename ===
            'LatexAnswerConfig' &&
            subproblem.initialInputConfiguration.template != null && (
              <MathContent
                content={subproblem.initialInputConfiguration.template}
              />
            )}
        </>
      );
    }
    case 'MULTIPLE_CHOICE': {
      return (
        <>
          {subproblem.initialInputConfiguration.__typename ===
            'MultipleChoiceConfig' &&
            subproblem.initialInputConfiguration.options != null && (
              <MultipleChoiceReadOnly
                value={subproblem.initialInputConfiguration.options.reduce(
                  (acc, opt) => ({
                    ...acc,
                    [opt.optionKey]: {
                      value: opt.optionContent,
                      selected: false,
                    },
                  }),
                  {},
                )}
              />
            )}
        </>
      );
    }
    case 'NUMBER_LINE': {
      const data =
        subproblem.initialInputConfiguration.__typename ===
          'NumberLineConfig' && subproblem.initialInputConfiguration;
      return !data ? null : (
        <NumberLineIntervalsReadOnly
          {...toNumberLineInternalValueFormat(data.numberLineValues)}
          start={deserializeCoordinateValues(data.start)}
          end={deserializeCoordinateValues(data.end)}
          mode={toInternalMode[data.mode]}
          majorTicks={deserializeCoordinateValues(data.majorTicks)}
          minorTicks={deserializeCoordinateValues(data.minorTicks)}
          unit={graphQlUnitEnumToLatex(data.unit)}
        />
      );
    }
    case 'GRAPHPLOT': {
      const data =
        subproblem.initialInputConfiguration.__typename ===
          'LegacyGraphPlotConfig' && subproblem.initialInputConfiguration;
      return !data ? null : (
        <GraphPlotReadOnly
          datum={{
            // Asserting that `toGraphPlotInternalValueFormat` is of type `LegacyDatum`
            // could obviously fail at runtime if the incoming string is not well formed
            // JSON in the shape of `LegacyDatum`
            ...(toGraphPlotInternalValueFormat(
              data.requestDatum,
            ) as LegacyDatum),
            type: 'PlottableGraph',
          }}
        />
      );
    }
    case 'HISTOGRAM': {
      const data =
        subproblem.initialInputConfiguration.__typename === 'HistogramConfig' &&
        subproblem.initialInputConfiguration;
      return !data ? null : (
        <HistogramReadOnly
          value={data.histogramValues}
          doesDrawBars={data.doesDrawBars}
          hasGapBetweenBars={data.hasGapBetweenBars}
          doesDrawLines={data.doesDrawLines}
          // TODO: Update histogram dragHandleAlignment to use upercase
          dragHandleAlignment={
            data.dragHandleAlignment === 'CENTER' ? 'center' : 'right'
          }
          labels={{
            main: data.mainLabel,
            xAxis: data.xAxisLabel,
            yAxis: data.yAxisLabel,
          }}
          increment={{
            min: data.yMin,
            max: data.yMax,
            step: data.yIncrement,
            // NOTE: this needs to be static for now. Related to yTickMultiplier bug
            tick: 5,
          }}
        />
      );
    }
    case 'ALGEBRAIC':
    case 'BOXPLOT':
    case 'EQUATION':
    case 'GEOMETRY':
    case 'INEQUALITY':
    case 'NUMBER_BUILDER':
    case 'NUMERIC':
    case 'PROBABILITY_TREE':
      return null;
    default: {
      throw new InvariantViolation(
        `Subproblem type not supported: ${subproblemType}`,
      );
    }
  }
}

export function isThereInputToRender(subproblemType: SubproblemType) {
  switch (subproblemType) {
    case 'ALGEBRAIC':
    case 'BOXPLOT':
    case 'EQUATION':
    case 'GEOMETRY':
    case 'INEQUALITY':
    case 'NUMBER_BUILDER':
    case 'NUMERIC':
    case 'PROBABILITY_TREE':
      return false;
    default:
      return true;
  }
}
