import type { ReactElement } from 'react';
import { Link } from 'react-router-dom';

import MathContent from 'ms-components/math/MathContent/index';
import Anchor from 'ms-ui-primitives/Link/Anchor';
import { getTextbookTheoryUrl } from 'ms-utils/urls';

type ChapterLinkProps = {
  contents: string;
  syllabusId: string;
  topicId: string;

  // ...yes it is subtopicGuid. Confused? So would I.
  // this props are coming from the HTMLSoup (Chapter.content)
  // and legacy textbooks are using the provided `subtopicId` which is
  // actually the subtopic pk so we could not override it.
  // Read msproblem/content/cms/chapter/api.py for more context
  subtopicGuid: string;
};

// The chapterlink overrides here are defined to consolidate all of the hax that have
// been put into place to make chatpter links work again
export function ChapterLink({
  contents,
  syllabusId,
  topicId,
  subtopicGuid,
}: ChapterLinkProps): ReactElement<any> {
  return (
    <Link
      to={getTextbookTheoryUrl({
        syllabusId,
        topicId,
        subtopicId: subtopicGuid,
      })}
    >
      <MathContent content={contents} inline />
    </Link>
  );
}

export function ChapterAnchor({
  contents,
  syllabusId,
  topicId,
  subtopicGuid,
}: ChapterLinkProps): ReactElement<any> {
  return (
    <Anchor
      href={getTextbookTheoryUrl({
        syllabusId,
        topicId,
        subtopicId: subtopicGuid,
      })}
    >
      <MathContent content={contents} inline />
    </Anchor>
  );
}

export function NoopChapterLink({
  contents,
}: ChapterLinkProps): ReactElement<any> {
  return <MathContent content={contents} inline />;
}
