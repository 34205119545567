import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M5.99999 0C2.68629 0 0 2.68629 0 5.99999C0 9.31369 2.68629 12 5.99999 12C9.31369 12 12 9.31369 12 5.99999C11.9965 2.68777 9.31223 0.00354073 5.99999 0ZM6.42857 5.99999C6.42857 6.23669 6.23669 6.42857 5.99999 6.42857H2.57143C2.33472 6.42857 2.14285 6.23669 2.14285 5.99999C2.14285 5.76329 2.33472 5.57141 2.57143 5.57141H5.57143V2.57143C5.57143 2.33472 5.76331 2.14285 6.00001 2.14285C6.23671 2.14285 6.42857 2.33472 6.42857 2.57143V5.99999Z" />
);

const ClockFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={12}
    viewBoxWidth={12}
  >
    {path}
  </Icon>
);

ClockFilled.displayName = 'ClockFilled';

export default ClockFilled;
