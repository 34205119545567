import { colors } from 'ms-styles/colors';
import Icon from 'ms-ui-primitives/Icon';

export function ScientificCalculatorIcon() {
  return (
    <Icon size={32} viewBoxWidth={32} viewBoxHeight={32}>
      <rect width="32" height="32" rx="4" fill="currentColor" />
      <path
        d="M7.75781 15.4141H13.6563C14.6255 15.4141 15.4141 14.6255 15.4141 13.6563V7.75781C15.4141 6.78852 14.6255 6 13.6563 6H7.75781C6.78852 6 6 6.78852 6 7.75781V13.6563C6 14.6255 6.78852 15.4141 7.75781 15.4141ZM8.96875 10.1406H10.1406V8.96875C10.1406 8.64488 10.4027 8.38281 10.7266 8.38281C11.0504 8.38281 11.3125 8.64488 11.3125 8.96875V10.1406H12.4844C12.8082 10.1406 13.0703 10.4027 13.0703 10.7266C13.0703 11.0504 12.8082 11.3125 12.4844 11.3125H11.3125V12.4844C11.3125 12.8082 11.0504 13.0703 10.7266 13.0703C10.4027 13.0703 10.1406 12.8082 10.1406 12.4844V11.3125H8.96875C8.64488 11.3125 8.38281 11.0504 8.38281 10.7266C8.38281 10.4027 8.64488 10.1406 8.96875 10.1406Z"
        fill={colors.white}
      />
      <path
        d="M24.2422 6H18.3438C17.3745 6 16.5859 6.78852 16.5859 7.75781V13.6563C16.5859 14.6255 17.3745 15.4141 18.3438 15.4141H24.2422C25.2115 15.4141 26 14.6255 26 13.6563V7.75781C26 6.78852 25.2115 6 24.2422 6ZM23.0703 11.3125H19.5156C19.1918 11.3125 18.9297 11.0504 18.9297 10.7266C18.9297 10.4027 19.1918 10.1406 19.5156 10.1406H23.0703C23.3942 10.1406 23.6562 10.4027 23.6562 10.7266C23.6562 11.0504 23.3942 11.3125 23.0703 11.3125Z"
        fill={colors.white}
      />
      <path
        d="M24.2422 16.5859H18.3438C17.3745 16.5859 16.5859 17.3745 16.5859 18.3438V24.2422C16.5859 25.2115 17.3745 26 18.3438 26H24.2422C25.2115 26 26 25.2115 26 24.2422V18.3438C26 17.3745 25.2115 16.5859 24.2422 16.5859ZM23.0703 23.0312H19.5156C19.1918 23.0312 18.9297 22.7692 18.9297 22.4453C18.9297 22.1214 19.1918 21.8594 19.5156 21.8594H23.0703C23.3942 21.8594 23.6562 22.1214 23.6562 22.4453C23.6562 22.7692 23.3942 23.0312 23.0703 23.0312ZM23.0703 20.6875H19.5156C19.1918 20.6875 18.9297 20.4254 18.9297 20.1016C18.9297 19.7777 19.1918 19.5156 19.5156 19.5156H23.0703C23.3942 19.5156 23.6562 19.7777 23.6562 20.1016C23.6562 20.4254 23.3942 20.6875 23.0703 20.6875Z"
        fill={colors.white}
      />
      <path
        d="M15.4141 24.2422V18.3438C15.4141 17.3745 14.6255 16.5859 13.6563 16.5859H7.75781C6.78852 16.5859 6 17.3745 6 18.3438V24.2422C6 25.2115 6.78852 26 7.75781 26H13.6563C14.6255 26 15.4141 25.2115 15.4141 24.2422ZM12.3837 22.102C12.6125 22.3309 12.6125 22.7017 12.3837 22.9305C12.1548 23.1594 11.784 23.1594 11.5551 22.9305L10.7266 22.102L9.89801 22.9305C9.66914 23.1594 9.29832 23.1594 9.06945 22.9305C8.84059 22.7017 8.84059 22.3309 9.06945 22.102L9.89801 21.2734L9.06945 20.4449C8.84059 20.216 8.84059 19.8452 9.06945 19.6163C9.29832 19.3875 9.66914 19.3875 9.89801 19.6163L10.7266 20.4449L11.5551 19.6163C11.784 19.3875 12.1548 19.3875 12.3837 19.6163C12.6125 19.8452 12.6125 20.216 12.3837 20.4449L11.5551 21.2734L12.3837 22.102Z"
        fill={colors.white}
      />
    </Icon>
  );
}
export default ScientificCalculatorIcon;
