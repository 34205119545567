import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const HandPalm = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={17}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.3738 5.0566C12.8836 5.0566 13.2968 4.64333 13.2968 4.13353C13.2968 3.62372 12.8836 3.21045 12.3738 3.21045C11.864 3.21045 11.4507 3.62372 11.4507 4.13353C11.4507 4.64333 11.864 5.0566 12.3738 5.0566Z"
        fill="#21374D"
      />
      <path
        d="M9.91185 3.21066C10.4216 3.21066 10.8349 2.79738 10.8349 2.28758C10.8349 1.77778 10.4216 1.3645 9.91185 1.3645C9.40204 1.3645 8.98877 1.77778 8.98877 2.28758C8.98877 2.79738 9.40204 3.21066 9.91185 3.21066Z"
        fill="#21374D"
      />
      <path
        d="M7.45042 2.59518C7.96022 2.59518 8.3735 2.1819 8.3735 1.6721C8.3735 1.1623 7.96022 0.749023 7.45042 0.749023C6.94062 0.749023 6.52734 1.1623 6.52734 1.6721C6.52734 2.1819 6.94062 2.59518 7.45042 2.59518Z"
        fill="#21374D"
      />
      <path
        d="M4.98899 3.82589C5.4988 3.82589 5.91207 3.41261 5.91207 2.90281C5.91207 2.39301 5.4988 1.97974 4.98899 1.97974C4.47919 1.97974 4.06592 2.39301 4.06592 2.90281C4.06592 3.41261 4.47919 3.82589 4.98899 3.82589Z"
        fill="#21374D"
      />
      <path
        d="M11.4505 4.13366V8.13366C11.4505 8.3035 11.3126 8.44135 11.1428 8.44135C10.9729 8.44135 10.8351 8.3035 10.8351 8.13366V2.2875H8.98893V7.51827C8.98893 7.68812 8.85109 7.82597 8.68124 7.82597C8.51139 7.82597 8.37355 7.68812 8.37355 7.51827V1.67212H6.5274V7.51827C6.5274 7.68812 6.38955 7.82597 6.2197 7.82597C6.04986 7.82597 5.91201 7.68812 5.91201 7.51827V2.90289H4.06586V10.5952L2.8197 8.88997C2.45047 8.32073 1.72986 8.12997 1.20186 8.45612C0.675703 8.78966 0.545242 9.51704 0.909549 10.0844C0.909549 10.0844 2.9194 13.1263 3.77601 14.4284C4.63263 15.7306 6.02032 16.749 8.61539 16.749C12.912 16.749 13.2966 13.4309 13.2966 12.4413C13.2966 11.4518 13.2966 4.13366 13.2966 4.13366H11.4505Z"
        fill="#21374D"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.749023)"
        />
      </clipPath>
    </defs>
  </Icon>
);

HandPalm.displayName = 'HandPalm';

export default HandPalm;
