import { StyleSheet, css } from 'aphrodite';
import type { CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';

import { fontSize, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';

const styles = StyleSheet.create({
  root: {
    display: 'table-cell',
    fontSize: fontSize.tableCell,
    color: colors.mako,
    borderTop: '1px solid rgba(0, 0, 0, 0.08)',
    padding: 12,
    verticalAlign: 'middle',
    lineHeight: lineHeight.body,
    height: 30,
    boxSizing: 'content-box',
    ':first-of-type': {
      paddingLeft: 20,
    },
    ':last-of-type': {
      paddingRight: 20,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  wrapText: {
    whiteSpace: 'normal',
  },
});

type Props = {
  children: ReactNode;
  className?: string | undefined;
  align?: 'left' | 'right' | undefined; // How to align content cell content
  aphroditeStyles?: CSSInputTypes[] | undefined;
  textWrap?: boolean | undefined;
};

const TableCell = ({
  children,
  align = 'left',
  aphroditeStyles = [],
  textWrap = false,
}: Props) => (
  <div
    className={css(
      styles.root,
      align === 'right' && styles.alignRight,
      textWrap && styles.wrapText,
      ...aphroditeStyles,
    )}
  >
    {children}
  </div>
);

export default TableCell;
