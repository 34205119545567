import type { ReactElement } from 'react';

import { toScreen } from 'ms-utils/math/cartesian';
import type { Dimensions } from 'ms-utils/math/cartesian';
import type { Mode } from 'ms-utils/math/toLatex';

import TickMark from '../TickMark';

export type ScaleIncrements = {
  increment: number;
  major: Array<number>;
  minor: Array<number>;
};

type Props = {
  color?: string | undefined;
  dimensions: Dimensions;
  mode?: Mode | undefined;
  scale: ScaleIncrements;
  unit?: string | undefined;
};

export default function Scale(props: Props) {
  const ticks: ReactElement[] = [];

  props.scale.major.forEach((value: number): void => {
    ticks.push(
      <TickMark
        color={props.color}
        increment={props.scale.increment}
        key={value}
        label={value}
        major
        mode={props.mode}
        unit={props.unit}
        x={toScreen(value, props.dimensions)}
      />,
    );
  });

  props.scale.minor.forEach((value: number): void => {
    ticks.push(
      <TickMark
        color={props.color}
        key={value}
        major={false}
        mode={props.mode}
        x={toScreen(value, props.dimensions)}
      />,
    );
  });

  return <g transform={`translate(${props.dimensions.padding})`}>{ticks}</g>;
}
