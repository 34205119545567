import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path d="M20.668 19.422v-2.149a.645.645 0 00-.645-.644h-6.875c-.44 0-.85.133-1.19.36.195-.381.477-.718.85-1.015.79-.63 1.995-1.07 3.483-1.273 1.262-.172 2.471-.14 3.181-.054a.644.644 0 10.157-1.28c-1.684-.205-5.446-.137-7.624 1.599A4.51 4.51 0 0011 16.069a4.51 4.51 0 00-1.005-1.103c-2.178-1.736-5.94-1.804-7.624-1.598a.644.644 0 10.157 1.28c.71-.088 1.919-.12 3.182.053 1.488.203 2.692.643 3.482 1.273.373.297.655.634.851 1.015a2.138 2.138 0 00-1.191-.36H1.977a.644.644 0 00-.645.644v2.149h19.336zM21.355 22H.645a.645.645 0 010-1.29h20.71a.645.645 0 010 1.29zM4.99 7.304h-.012a.645.645 0 01-.628-.572c-.038-.324-.24-1.348-.666-1.774-.43-.429-1.45-.63-1.773-.665a.644.644 0 01-.02-1.279c.509-.075 1.447-.322 1.793-.668C4.03 2 4.277 1.062 4.352.55a.645.645 0 01.638-.55h.005a.644.644 0 01.634.56c.067.498.307 1.424.668 1.785.36.36 1.286.6 1.785.667a.645.645 0 01.012 1.276c-.552.084-1.475.347-1.797.67-.356.355-.605 1.363-.67 1.796a.645.645 0 01-.637.55zM11 12.832h-.012a.645.645 0 01-.628-.573c-.025-.206-.114-.475-.18-.54-.072-.073-.358-.16-.54-.18a.645.645 0 01-.021-1.279c.214-.032.485-.12.56-.182.067-.081.156-.374.183-.561A.645.645 0 0111 8.964h.005a.645.645 0 01.634.561c.029.21.12.485.182.553.067.062.342.152.553.181a.646.646 0 01.012 1.276c-.217.034-.488.122-.566.183-.065.083-.154.376-.183.564a.645.645 0 01-.637.55zM18.284 6.931a.645.645 0 01-.644-.644v-.43a.645.645 0 011.289 0v.43a.645.645 0 01-.645.644zM18.284 10.799a.645.645 0 01-.644-.645v-.43a.645.645 0 011.289 0v.43a.645.645 0 01-.645.645zM20.433 8.65h-.43a.645.645 0 010-1.289h.43a.645.645 0 010 1.29zM16.565 8.65h-.43a.645.645 0 010-1.289h.43a.644.644 0 110 1.29z" />
  </>
);
const SpellBook = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

SpellBook.displayName = 'SpellBook';

export default SpellBook;
