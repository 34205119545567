import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useContext, useCallback, useEffect } from 'react';

import ClassIcon from 'ms-components/icons/Class';
import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import InputSelector from 'ms-pages/Teacher/components/InputSelector';
import type { Items, Item } from 'ms-pages/Teacher/components/InputSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import {
  StateContext,
  UpdatersContext,
  EMPTY_ITEM,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import type { SchoolClasses } from 'ms-pages/Teacher/components/StudentCreateEdit/types';
import { getClassGrade } from 'ms-pages/Teacher/components/StudentCreateEdit/utils';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Select from 'ms-ui-primitives/Select';
import { titleForGradeSelector } from 'ms-utils/grades';
import { unwrap } from 'ms-utils/typescript-utils';

const ClassInputSelector = ({
  availableClasses,
  errorMessages,
}: {
  availableClasses: SchoolClasses;
  errorMessages: ErrorMessages;
}) => {
  const { gradeSelectedClass } = useContext(StateContext);
  const { removeGradeSelectedClass, updateGradeSelectedClass } =
    useContext(UpdatersContext);

  const onDropdownSelect = useCallback(
    (klass: Item) => {
      const grade = getClassGrade(availableClasses, klass);
      updateGradeSelectedClass(grade.id, klass);
    },
    [availableClasses, updateGradeSelectedClass],
  );

  const removeSelectedItem = useCallback(() => {
    removeGradeSelectedClass();
  }, [removeGradeSelectedClass]);

  return (
    <InputSelector
      items={availableClasses.map(({ id, title }) => ({ id, title }))}
      selectedItems={
        gradeSelectedClass.id !== EMPTY_ITEM.id ? [gradeSelectedClass] : []
      }
      removeSelectedItem={removeSelectedItem}
      onDropdownSelect={onDropdownSelect}
      icon={<ClassIcon size={3.5 * BASE_UNIT} />}
      showErrorState={errorMessages.length > 0}
    />
  );
};

type Props = {
  schoolClasses?: SchoolClasses | undefined;
  grades: Items;
  errorMessages: ErrorMessages;
};

const StudentYearLevel = ({ schoolClasses, grades, errorMessages }: Props) => {
  const {
    selfReportedGradeId,
    useClassGrade,
    classes: allClasses,
    gradeSelectedClass,
  } = useContext(StateContext);

  const {
    updateSelfReportedGradeId,
    removeGradeSelectedClass,
    toggleUseClassGrade,
    updateGradeSelectedClass,
  } = useContext(UpdatersContext);
  const { i18n } = useLingui();
  const localisedYearLevel = t`Year level`;
  const availableClasses = (schoolClasses ?? []).filter(c =>
    allClasses.map(c => c.id).includes(c.id),
  );

  // set a default value for the grade
  useEffect(() => {
    // if the grade is inherited from a class, and there are classes available, select the first class grade as default
    if (useClassGrade && availableClasses.length > 0) {
      if (gradeSelectedClass.id === EMPTY_ITEM.id) {
        const klass = availableClasses[0];
        if (klass?.classGrade != null) {
          // this is always true because `availableClasses` is filtered by `klass.classGrade != null`
          updateGradeSelectedClass(klass.classGrade.id, {
            id: klass.id,
            title: klass.title,
          });
        }
      }
    }
    // if the grade is not inherited from a class or there are no classes available, select the first grade as default
    else {
      if (selfReportedGradeId === EMPTY_ITEM.id && grades.length > 0) {
        updateSelfReportedGradeId(unwrap(grades[0]).id);
      }
    }
  }, [
    availableClasses,
    availableClasses.length,
    gradeSelectedClass.id,
    grades,
    selfReportedGradeId,
    updateGradeSelectedClass,
    updateSelfReportedGradeId,
    useClassGrade,
  ]);

  return (
    <FieldGroup
      title={localisedYearLevel}
      isFieldRequired
      description={`The ${i18n._(
        t`year`,
      )} level will determine what skills are shown in the student's skills map and check-ins`}
    >
      {availableClasses.length > 0 && (
        <>
          <Separator size={2 * BASE_UNIT} />
          <Checkbox
            label={`Apply the ${i18n._(
              t`year`,
            )} level from one of the classes above`}
            checked={useClassGrade}
            onChange={() => {
              toggleUseClassGrade();
              removeGradeSelectedClass();
            }}
          />
          <Separator size={3 * BASE_UNIT} />
        </>
      )}
      {useClassGrade && availableClasses.length > 0 ? (
        <ClassInputSelector
          availableClasses={availableClasses}
          errorMessages={errorMessages}
        />
      ) : (
        <Select
          block
          options={grades.map(grade => ({
            label: titleForGradeSelector(grade, grades),
            value: grade.id,
          }))}
          value={selfReportedGradeId}
          onChange={value => {
            updateSelfReportedGradeId(value);
          }}
          optionItemsShown={5}
        />
      )}
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
};
export default StudentYearLevel;
