import styled from '@emotion/styled';
import React from 'react';
import { graphql, useQuery } from 'relay-hooks';

import JsxContent from 'ms-components/JsxContent';
import Retry from 'ms-components/Retry';
import FreehandDrawIcon from 'ms-components/icons/FreehandDraw';
import HandPalmIcon from 'ms-components/icons/HandPalm';
import RectangleBorderedFilledIcon from 'ms-components/icons/RectangleBorderedFilled';
import RedoIcon from 'ms-components/icons/Redo';
import StraightHorizontalLineIcon from 'ms-components/icons/StraightHorizontalLine';
import UndoIcon from 'ms-components/icons/Undo';
import Portal from 'ms-helpers/Portal';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { breakPoints } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Separator from 'ms-ui-primitives/Separator';
import {
  useAccessibilityMode,
  accessibilityModeStyle,
} from 'ms-utils/accessibility';
import { hexStringToRgbaString } from 'ms-utils/colors';
import { onPressOrHover, tappable, maxBreak } from 'ms-utils/emotion';
import keyDownMap from 'ms-utils/keyDownMap';
import { unwrap } from 'ms-utils/typescript-utils';

import Canvas from './Canvas';
import type { Tool, CanvasState } from './Canvas';
import type {
  SyllabusLocale,
  HighlightingNoteEditorQuery,
} from './__generated__/HighlightingNoteEditorQuery.graphql';
import type { JsxContentType } from './__generated__/StudyNotesModalQuery.graphql';
// this must be 26 as it's padding expected to display a JSX doc at full width
export const MAIN_CONTENT_PADDING = 26;
const JSX_DOC_WIDTH = 716;
export const WIDTH = JSX_DOC_WIDTH + 2 * MAIN_CONTENT_PADDING;
const HIGHLIGHTING_NOTE_EDITOR_QUERY = graphql`
  query HighlightingNoteEditorQuery(
    $subtopicId: ID!
    $locale: SyllabusLocale
    # TODO replace these ones below with proper JsxContentType enum param
    $isWorksheet: Boolean!
    $isOverview: Boolean!
    $isSolidify: Boolean!
    $isRsl: Boolean!
    $isInvestigation: Boolean!
  ) {
    subtopic(id: $subtopicId, syllabusLocale: $locale) {
      worksheetJsx @include(if: $isWorksheet) {
        transpiledJsx
      }
      solidifyLessonJsx @include(if: $isSolidify) {
        transpiledJsx
      }
      studentOverviewJsx @include(if: $isOverview) {
        transpiledJsx
      }
      rslJsx @include(if: $isRsl) {
        transpiledJsx
      }
      investigationJsx @include(if: $isInvestigation) {
        transpiledJsx
      }
    }
  }
`;
type CanvasProps = {
  state: CanvasState;
  onChange: (updater: (state: CanvasState) => CanvasState) => void;
};
type HighlightingNoteEditorInnerProps = CanvasProps & {
  jsxContent: string;
};
type HighlightingNoteEditorNewNoteProps = CanvasProps & {
  subtopicId: string;
  jsxContentType: JsxContentType;
  locale: SyllabusLocale;
};
type Props = HighlightingNoteEditorNewNoteProps & {
  jsxContent?: string;
};
const Toolbar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  right: 0,
  top: 100,
  padding: 8,
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  backgroundColor: 'white',
  boxShadow: '0px 1px 7px rgba(62, 62, 76, 0.62)',
  // For iPads, create an horizontal toolbar at the bottom of the screen.
  // use large (1024px) because experience is degraded a bit before reaching this point
  ...maxBreak(breakPoints.large, {
    flexDirection: 'row',
    justifyContent: 'center',
    top: 'unset',
    bottom: 0,
    width: '100%',
  }),
});
const TOOL_ITEM_SIZE = 40;
const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  margin: `0 -${MAIN_CONTENT_PADDING}px`,
  padding: `0 ${MAIN_CONTENT_PADDING}px`,
});
const ToolItem = styled.div<{
  selected: boolean;
  disabled: boolean;
  accessibilityMode: boolean;
}>(({ selected, disabled, accessibilityMode }) => ({
  width: TOOL_ITEM_SIZE,
  height: TOOL_ITEM_SIZE,
  borderRadius: TOOL_ITEM_SIZE / 2,
  border: '3px solid white',
  display: 'flex',
  color: colors.cloudBurst,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 32, // icon size
  ...tappable,
  ...onPressOrHover({
    opacity: 0.8,
  }),
  ...(selected && {
    boxShadow: '0px 1px 7px rgba(62, 62, 76, 0.62)',
  }),
  ...(disabled && {
    pointerEvents: 'none',
    color: colors.iron,
  }),
  ...(accessibilityMode && {
    ...accessibilityModeStyle,
    // override accessibilityModeStyle border radius
    borderRadius: TOOL_ITEM_SIZE / 2,
  }),
}));
const COLORS = [colors.lochmara, colors.saffron, '#F87F8D', colors.niagra]; // .map(c => hexStringToRgbaString(c, 0.3));
const _TOOLS: Tool[] = ['hand', 'lines', 'draw', 'rectangle'];
const toolIconMap = {
  hand: HandPalmIcon,
  lines: StraightHorizontalLineIcon,
  draw: FreehandDrawIcon,
  rectangle: RectangleBorderedFilledIcon,
};
const TOOLS =
  'ontouchstart' in document.documentElement
    ? _TOOLS
    : // if it is not a touch device, hide the hand tool
      _TOOLS.filter(t => t !== 'hand');
export default function HighlightingNoteEditor({
  subtopicId,
  jsxContentType,
  locale,
  jsxContent,
  state,
  onChange,
}: Props) {
  if (jsxContent == null) {
    return (
      <HighlightingNoteEditorNewNote
        subtopicId={subtopicId}
        locale={locale}
        jsxContentType={jsxContentType}
        state={state}
        onChange={onChange}
      />
    );
  } else {
    return (
      <HighlightingNoteEditorInner
        state={state}
        onChange={onChange}
        jsxContent={jsxContent}
      />
    );
  }
}

function HighlightingNoteEditorNewNote({
  state,
  onChange,
  subtopicId,
  locale,
  jsxContentType,
}: HighlightingNoteEditorNewNoteProps) {
  const { props, error, retry } = useQuery<HighlightingNoteEditorQuery>(
    HIGHLIGHTING_NOTE_EDITOR_QUERY,
    {
      subtopicId,
      locale,
      isWorksheet: jsxContentType === 'WORKSHEET',
      isOverview: jsxContentType === 'STUDENT_OVERVIEW',
      isSolidify: jsxContentType === 'SOLIDIFY_LESSON',
      isRsl: jsxContentType === 'RETROFITTED_STUDENT_LESSON',
      isInvestigation: jsxContentType === 'INVESTIGATION',
    },
    { fetchPolicy: 'store-and-network' },
  );
  if (error != null) return <Retry retry={retry} />;
  if (props == null)
    return (
      <Wrapper>
        <MinorSpinner />
      </Wrapper>
    );
  const subtopic = props.subtopic;
  if (subtopic == null) return <>No subtopic found</>;
  let resource;
  if (jsxContentType === 'WORKSHEET') {
    resource = subtopic.worksheetJsx?.transpiledJsx;
  }
  if (jsxContentType === 'SOLIDIFY_LESSON') {
    resource = subtopic.solidifyLessonJsx?.transpiledJsx;
  }
  if (jsxContentType === 'STUDENT_OVERVIEW') {
    resource = subtopic.studentOverviewJsx?.transpiledJsx;
  }
  if (jsxContentType === 'RETROFITTED_STUDENT_LESSON') {
    resource = subtopic.rslJsx?.transpiledJsx;
  }
  if (jsxContentType === 'INVESTIGATION') {
    resource = subtopic.investigationJsx?.transpiledJsx;
  }
  if (resource == null) return <>No {jsxContentType} found</>;
  return (
    <HighlightingNoteEditorInner
      state={state}
      onChange={onChange}
      jsxContent={resource}
    />
  );
}

function HighlightingNoteEditorInner({
  state,
  onChange,
  jsxContent,
}: HighlightingNoteEditorInnerProps) {
  const [hasEnabledAccessibilityMode] = useAccessibilityMode();
  const [selectedColor, setSelectedColor] = React.useState(unwrap(COLORS[0]));
  const [selectedTool, setSelectedTool] = React.useState(unwrap(TOOLS[0]));
  const canvasStyle = React.useMemo(
    () => ({
      zIndex: 3, // Must beat zIndex on CartesianPlane on content-editor/jsx-content https://github.com/mathspace/content-editor/blob/master/jsx-content/CartesianPlane.js#L431
      position: 'absolute',
      top: 0,
      opacity: 0.3,
      // acts as max height
      height: '100%',
      // extend to the margin of the jsx doc
      width: '100%',
      marginLeft: -MAIN_CONTENT_PADDING,
      marginRight: -MAIN_CONTENT_PADDING,
    }),
    [],
  );
  const undo = React.useCallback(() => {
    onChange(state => {
      const { drawings, undoes } = state;
      const lastDrawing = drawings[drawings.length - 1];
      if (lastDrawing == null) {
        throw new Error('Undo should not be called if there are not drawings');
      }
      return {
        undoes: [...undoes, lastDrawing],
        drawings: drawings.slice(0, -1),
      };
    });
  }, [onChange]);
  const redo = React.useCallback(() => {
    onChange(state => {
      const { drawings, undoes } = state;
      const lastUndo = undoes[undoes.length - 1];
      if (lastUndo == null) {
        throw new Error('Redo should not be called if there are not undoes');
      }
      return {
        undoes: undoes.slice(0, -1),
        drawings: [...drawings, lastUndo],
      };
    });
  }, [onChange]);
  return (
    <Wrapper>
      <Canvas
        selectedColor={selectedColor}
        selectedTool={selectedTool}
        width={JSX_DOC_WIDTH + 2 * MAIN_CONTENT_PADDING}
        height={5000}
        onChange={onChange}
        state={state}
        style={canvasStyle}
      />
      <JsxContent transpiledJsx={jsxContent} />

      <Portal isOpen>
        <Toolbar>
          {COLORS.map((color, index) => {
            const selectColor = () => {
              setSelectedColor(color);
            };
            return (
              <React.Fragment key={color}>
                <ToolItem
                  key={color}
                  style={{ backgroundColor: hexStringToRgbaString(color, 0.3) }}
                  selected={selectedColor === color}
                  disabled={false}
                  onClick={selectColor}
                  tabIndex={0}
                  onKeyDown={keyDownMap({
                    ENTER: [selectColor, { preventDefault: true }],
                    SPACE: [selectColor, { preventDefault: true }],
                  })}
                  accessibilityMode={hasEnabledAccessibilityMode}
                />
                {index < COLORS.length - 1 && <Separator size={3} />}
              </React.Fragment>
            );
          })}

          <Separator size={6} />

          {TOOLS.map((tool, index) => {
            const selectTool = () => {
              setSelectedTool(tool);
            };
            const Icon = toolIconMap[tool];
            return (
              <React.Fragment key={tool}>
                <ToolItem
                  key={tool}
                  selected={selectedTool === tool}
                  disabled={false}
                  onClick={selectTool}
                  tabIndex={0}
                  onKeyDown={keyDownMap({
                    ENTER: [selectTool, { preventDefault: true }],
                    SPACE: [selectTool, { preventDefault: true }],
                  })}
                  accessibilityMode={hasEnabledAccessibilityMode}
                >
                  <Icon color={colors.cloudBurst} />
                </ToolItem>
                {index < TOOLS.length - 1 && <Separator size={5} />}
              </React.Fragment>
            );
          })}

          <Separator size={6} />

          <ToolItem
            key="undo"
            selected={false}
            disabled={state.drawings.length === 0}
            onClick={undo}
            tabIndex={0}
            onKeyDown={keyDownMap({
              ENTER: [undo, { preventDefault: true }],
              SPACE: [undo, { preventDefault: true }],
            })}
            accessibilityMode={hasEnabledAccessibilityMode}
          >
            <UndoIcon />
          </ToolItem>

          <ToolItem
            key="redo"
            selected={false}
            disabled={state.undoes.length === 0}
            onClick={redo}
            tabIndex={0}
            onKeyDown={keyDownMap({
              ENTER: [redo, { preventDefault: true }],
              SPACE: [redo, { preventDefault: true }],
            })}
            accessibilityMode={hasEnabledAccessibilityMode}
          >
            <RedoIcon />
          </ToolItem>
        </Toolbar>
      </Portal>
    </Wrapper>
  );
}
