import { css, StyleSheet } from 'aphrodite';
import {
  useState,
  useEffect,
  useRef,
  useMemo,
  type MouseEvent as SyntheticMouseEvent,
  type TouchEvent as SyntheticTouchEvent,
} from 'react';
import TrashIcon from 'react-icons/lib/fa/trash';

import Axis from 'ms-components/math/private-shared/components/NumberLine/Axis';
import type { ScaleIncrements } from 'ms-components/math/private-shared/components/NumberLine/Scale';
import Slider from 'ms-components/math/private-shared/components/NumberLine/Slider';
import Button from 'ms-ui-primitives/Button';
import type { Segment as SegmentElement } from 'ms-utils/coordinate';
import { fromScreen, snapValue } from 'ms-utils/math/cartesian';
import type { Dimensions } from 'ms-utils/math/cartesian';
import * as msnumber from 'ms-utils/math/number';
import type { Mode } from 'ms-utils/math/toLatex';

export type NumberLineSegmentData = SegmentElement<{
  inclusive?: boolean;
  status?: 'correct' | 'incorrect' | 'unknown';
}>;

const styles = StyleSheet.create({
  base: { touchAction: 'none' },
  svg: { maxWidth: '100%' },
});

/**
 * This is the root (abstract) number line component.  It relies on a
 * parent environment to manage its value prop.
 *
 * Events
 * ======
 *
 * Mouse and Touch interaction handling.
 * NumberLine is responsible for managing the current "value" of each
 * component.  When in dragging mode, this component calculates a dx
 * value tracking the current horizontal delta of the mouse while it is
 * being dragged.  This dx value is passed down to the component which
 * is responsible for its own state update and rendering.
 *
 * On MouseUp, the component validates and sends its new state up to the
 * numberline which records it.
 *
 * This gives us a natural user experience.  Clicking on a point that
 * is on top of another will naturally grab the highest element in the
 * DOM.  MouseUp and drag events are handled by the parent component,
 * to allow us to observe the cursor even when it escapes the
 * originally clicked DOM element.
 *
 * Rendering
 * =========
 *
 * A <NumberLine> consists of an <Axis> and a set of elements.  The
 * <Axis> has a scale defined by the props we pass to it.
 *
 * @example
 * <NumberLine
 *   onAddSegment={this.onAddSegment}
 *   onResetElements={this.onResetElements}
 *   onDragEnd={this.onDragEnd}
 *   readOnly={false}
 *   value={[{ type: 'point', value: 0 }]}
 * />
 */

export type Props = {
  readOnly?: boolean | undefined;

  axisYPos?: number | undefined;
  height?: number | undefined;
  margin?: number | undefined;
  padding?: number | undefined;
  width?: number | undefined;

  end?: number | undefined;
  majorTicks?: number | undefined;
  minorTicks?: number | undefined;
  mode?: Mode | undefined;
  snapIncrement?: number | undefined;
  start?: number | undefined;
  unit?: string | undefined;

  // 🚨 Ensure this is memoized in the parent
  onAddSegment?: (start: number, end: number) => void;
  onResetElements?: (() => void) | undefined;
  onDragEnd?: (id: number, data: NumberLineSegmentData) => void;

  value: ReadonlyArray<NumberLineSegmentData>;
};

export default function NumberLine({
  readOnly = true,
  axisYPos = 75,
  height = 125,
  margin = 10,
  padding = 25,
  width = 460,
  end = 20,
  majorTicks = 5,
  minorTicks = 1,
  mode,
  snapIncrement,
  start = -20,
  unit,
  onAddSegment,
  onResetElements = () => {},
  onDragEnd,
  value,
}: Props) {
  const rootRef = useRef<HTMLDivElement | null>(null);
  // TODO This state isn't modelled particularly well, only half the values
  // are actually projected in the render output, rest are just for event
  // handler computations.
  type State = {
    dragging: boolean; // ✅ (projected)
    dx: number; // ✅ (projected)
    x0: number;
    axisSelected: boolean;
    axisLeft: number;
    newElement: NumberLineSegmentData | null | undefined; // ✅ (projected)
  };
  const [state, setState] = useState<State>({
    dragging: false,
    dx: 0,
    x0: 0,
    axisSelected: false,
    axisLeft: 0,
    newElement: null,
  });

  const dimensions = useMemo(() => {
    return getDimensions({
      end,
      margin,
      padding,
      snapIncrement,
      start,
      width,
      minorTicks,
    });
  }, [end, margin, padding, snapIncrement, start, width, minorTicks]);

  // We additionally store the drag state on a ref so that our effects for
  // mouseup/touchend/touchmove don't have to execute at 60fps as
  // we are dragging.
  const dragStateRef = useRef({
    dragging: state.dragging,
    dx: state.dx,
    x0: state.x0,
    axisSelected: state.axisSelected,
    axisLeft: state.axisLeft,
  });
  useEffect(() => {
    dragStateRef.current = {
      dragging: state.dragging,
      dx: state.dx,
      x0: state.x0,
      axisSelected: state.axisSelected,
      axisLeft: state.axisLeft,
    };
  }, [state]);

  // We listen for mouseup/touchend events on the window as otherwise we
  // can miss when a user gesture ends (eg. mouseup outside the browser
  // window) and end up in a messed up state where the user thinks they
  // have finished the gesture, but we still think the gesture is ongoing.
  useEffect(() => {
    function onAxisMouseUp(event: MouseEvent | TouchEvent) {
      const { dx, x0, axisSelected, axisLeft } = dragStateRef.current;
      if (!isPrimaryClick(event) || !axisSelected) return;
      event.preventDefault();

      const { margin, padding } = dimensions;
      const pageOffset = axisLeft + margin + padding;

      // calculate user value of click positions
      const startX = x0 - pageOffset;
      const endX = x0 + dx - pageOffset;
      let startValue = fromScreen(startX, dimensions);
      let endValue = fromScreen(endX, dimensions);

      // snap user values to grid
      startValue = snapValue(startValue, dimensions);
      endValue = snapValue(endValue, dimensions);

      if (onAddSegment) onAddSegment(startValue, endValue);

      setState(state => ({ ...state, newElement: null, axisSelected: false }));
      _onDragEnd();
    }

    window.addEventListener('mouseup', onAxisMouseUp);
    window.addEventListener('touchend', onAxisMouseUp);

    return () => {
      window.removeEventListener('mouseup', onAxisMouseUp);
      window.removeEventListener('touchend', onAxisMouseUp);
    };
  }, [dimensions, onAddSegment]);

  // React's event system has been messed up since iOS 11.3 was released, and
  // they still haven't fixed it. Specifically, you cannot configure the
  // `passive` option in React event handlers, which means you cannot prevent
  // default on touchmove events. This means if you are trying to do a drag
  // operation on the numberline, you will simultaneously scroll the root
  // scrollview of the browser. Thus we have to use the native event system
  // to .preventDefault() touchmove events when a user is dragging.
  useEffect(() => {
    const rootDiv = rootRef.current;
    if (rootDiv === null) return;
    function preventWindowPanning(event: TouchEvent) {
      if (dragStateRef.current.dragging) event.preventDefault();
    }
    rootDiv.addEventListener('touchmove', preventWindowPanning, {
      passive: false,
    });

    return () => {
      // Typescript has incorrect types for the options object passed to
      // removeEventListener. They are missing the passive option, which *DOES*
      // affect which event listener gets removed. If we did not pass
      // { passive: false } then the default value { passive: true } would be
      // used, and our non-passive event listener would not be removed.
      // @ts-expect-error
      rootDiv.removeEventListener('touchmove', preventWindowPanning, {
        passive: false,
      });
    };
  }, []);

  // This function is used to abstract over drag start occurring on either
  // the Slider or the Axis, but done in a way where we don't trigger
  // multiple set states (hence returning the partial next state)
  function handleDragStart(
    event: SyntheticTouchEvent | SyntheticMouseEvent,
  ): { dragging: true; x0: number } | {} {
    if (isPrimaryClick(event.nativeEvent)) {
      const x0 = getClientX(event.nativeEvent);
      event.preventDefault();
      return { dragging: true, x0 };
    } else {
      return {};
    }
  }

  function onSliderDragStart(event: SyntheticTouchEvent | SyntheticMouseEvent) {
    const nextDragState = handleDragStart(event);
    setState(state => ({ ...state, ...nextDragState }));
  }

  // When we are dragging on the svg (either the Slider or the Axis)
  // events bubble up to the svg, which we handle here.
  function onSvgDragMove(event: SyntheticTouchEvent | SyntheticMouseEvent) {
    const dx = getClientX(event.nativeEvent) - state.x0;
    setState(state => ({ ...state, dx }));
  }

  function onSliderDragEnd(id: number, data: NumberLineSegmentData) {
    if (onDragEnd) onDragEnd(id, data);
    _onDragEnd();
  }

  // When dragging ends on either the Slider or the Axis we invoked this
  function _onDragEnd() {
    setState(state => ({
      ...state,
      dragging: false,
      dx: 0,
      x0: 0,
    }));
  }

  function onAxisMouseDown(event: SyntheticTouchEvent | SyntheticMouseEvent) {
    const nextDragState = handleDragStart(event);
    const axisLeft = event.currentTarget.getBoundingClientRect().left;
    const { margin, padding } = dimensions;
    const pageOffset = axisLeft + margin + padding;

    // calculate user value of click position
    const startX = getClientX(event.nativeEvent) - pageOffset;
    let startValue = fromScreen(startX, dimensions);

    // snap user value to grid
    startValue = snapValue(startValue, dimensions);

    // Begin drawing new element
    setState(state => ({
      ...state,
      ...nextDragState,
      axisSelected: true,
      axisLeft,
      newElement: [
        { position: [startValue], meta: { inclusive: true } },
        { position: [startValue], meta: { inclusive: true } },
      ],
    }));
  }

  const scale = buildScale({ start, end, minorTicks, majorTicks });
  const svgHeight = height + margin * 2;
  const svgWidth = width + margin * 2;
  return (
    <div className={css(styles.base)} ref={rootRef}>
      <div>
        <svg
          className={css(styles.svg)}
          onMouseMove={state.dragging ? onSvgDragMove : undefined}
          onTouchMove={state.dragging ? onSvgDragMove : undefined}
          viewBox={`0, 0, ${svgWidth}, ${svgHeight}`}
          width={svgWidth}
        >
          <Axis
            dimensions={dimensions}
            mode={mode}
            onMouseDown={
              state.dragging || readOnly ? undefined : onAxisMouseDown
            }
            readOnly={readOnly}
            scale={scale}
            unit={unit}
            width={width}
            y={axisYPos}
          />
          <Slider
            axisYPos={axisYPos}
            dimensions={dimensions}
            dragging={state.dragging}
            dx={state.dx}
            margin={margin}
            newElement={state.newElement}
            onDragEnd={onSliderDragEnd}
            onDragStart={onSliderDragStart}
            padding={padding}
            readOnly={readOnly}
            value={value}
          />
        </svg>
      </div>
      {readOnly ? null : (
        <div>
          {/** TODO implement type="danger" ? */}
          <Button
            onClick={onResetElements}
            type="primary"
            color="cinnabar"
            label="Reset"
          >
            <TrashIcon color="inherit" style={{ marginRight: 5 }} />
            Reset
          </Button>
        </div>
      )}
    </div>
  );
}

function getDimensions({
  end,
  margin,
  padding,
  snapIncrement,
  start,
  width,
  minorTicks,
}: {
  end: number;
  margin: number;
  padding: number;
  snapIncrement: number | undefined;
  start: number;
  width: number;
  minorTicks: number;
}): Dimensions {
  const _minorTicks = Math.abs(minorTicks);
  const contentWidth = width - padding * 2;
  const numIncrements = (end - start) / _minorTicks;
  const incrementWidth = contentWidth / numIncrements;

  return {
    contentWidth,
    end,
    lowerBound: -(padding - 3),
    margin,
    padding,
    scalingFactor: incrementWidth / _minorTicks,
    snapIncrement: snapIncrement === undefined ? _minorTicks : snapIncrement,
    start,
    upperBound: contentWidth + (padding - 3),
    zeroOffset: incrementWidth * (-start / _minorTicks),
  };
}

function buildScale({
  start,
  end,
  majorTicks,
  minorTicks,
}: {
  start: number;
  end: number;
  majorTicks: number;
  minorTicks: number;
}): ScaleIncrements {
  const majorList: number[] = [];
  const minorList: number[] = [];

  const _majorTicks = Math.abs(majorTicks);
  const _minorTicks = Math.abs(minorTicks);

  if (_minorTicks !== 0) {
    // build Axis lists
    for (let i = start; i < end || msnumber.equal(i, end); i += _minorTicks) {
      if (
        msnumber.equal(i % _majorTicks, 0) ||
        msnumber.equal(i % _majorTicks, _majorTicks) ||
        msnumber.equal(i % _majorTicks, -_majorTicks)
      ) {
        majorList.push(i);
      } else {
        minorList.push(i);
      }
    }
  }

  return {
    major: majorList,
    minor: minorList,
    increment: _minorTicks,
  };
}

function isPrimaryClick(event: MouseEvent | TouchEvent) {
  if (event instanceof MouseEvent) {
    return event.button === 0; // left click
  } else {
    // NOTE this logic doesn't really make sense. In touch world the only
    // analagous check would be that the finger that began the interaction
    // is updating (ie. to prevent dragging the point with other fingers).
    // I'm just keeping the old behaviour for now.
    return event.changedTouches.length !== 0;
  }
}

function getClientX(event: MouseEvent | TouchEvent): number {
  if (event instanceof MouseEvent) {
    return event.clientX;
  } else {
    const touch = event.changedTouches[0];
    if (touch === undefined) throw Error('Cannot retrieve clientX');
    return touch.clientX;
  }
}
