import styled from '@emotion/styled';

import { TAB_HEIGHT_V2 as TAB_HEIGHT } from 'ms-components/Tabs';
// import PrintIcon from 'ms-components/icons/Print';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { breakPoints, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import Stack from 'ms-ui-primitives/Stack';

const Cover = styled.div({
  height: 48,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'top right',
});

const CONTAINER_PADDING_X = 16;
const CONTAINER_PADDING_Y = 16;
const Container = styled.div({
  margin: '0 auto',
  width: '100%',
  maxWidth: breakPoints.medium,
  padding: `${CONTAINER_PADDING_Y}px ${CONTAINER_PADDING_X}px`,
});

const BADGE_SIZE = 52;
const Badge = styled.img({
  height: BADGE_SIZE,
  width: BADGE_SIZE,
  borderRadius: 8,
});

export const STICKY_WRAPPER_HEIGHT = TAB_HEIGHT;
const StickyWrapper = styled.div({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 2, // must beat switch option z-index local_modules/ms-pages/Teacher/components/Switch/index.tsx
  borderBottom: `2px solid ${colors.porcelain}`,
  height: STICKY_WRAPPER_HEIGHT,
});

const StickyWrapperContainer = styled.div({
  margin: '0 auto',
  width: '100%',
  maxWidth: breakPoints.medium,
  padding: `0 ${CONTAINER_PADDING_X}px`,
});

const Aside = styled(Stack.H)({
  marginLeft: 'auto',
});

export const SECONDARY_STICKY_WRAPPER_HEIGHT = 48;
const SecondaryStickyWrapper = styled.div<{ top: number }>(({ top }) => ({
  position: 'sticky',
  top,
  backgroundColor: 'white',
  zIndex: 2, // must beat switch option z-index local_modules/ms-pages/Teacher/components/Switch/index.tsx
}));

const SECONDARY_STICKY_WRAPPER_CONTAINER_PADDING_Y = 8;
const SecondaryStickyWrapperContainer = styled(Stack.H)({
  margin: '0 auto',
  width: '100%',
  maxWidth: breakPoints.medium,
  padding: `${SECONDARY_STICKY_WRAPPER_CONTAINER_PADDING_Y}px ${CONTAINER_PADDING_X}px`,
  height: SECONDARY_STICKY_WRAPPER_HEIGHT,
  borderBottom: `1px solid ${colors.porcelain}`,
  ':empty': {
    display: 'none',
  },
});

type Props = {
  coverImageUrl: string | null;
  badgeUrl?: string;
  title: string;
  subtitleContent?: React.ReactNode | null; // outcomes in subtopic teacher view
  asideContent?: React.ReactNode | null;
  tabs: React.ReactNode;
  secondaryActionsContent?: React.ReactNode | null; // secondary sticky header
};

export default function DetailHeader({
  coverImageUrl,
  badgeUrl,
  title,
  subtitleContent,
  asideContent,
  tabs,
  secondaryActionsContent,
}: Props) {
  return (
    // It's fundamental to use a Fragment here instead of a div to avoid breaking to allow the stickiness of the StickyWrapper
    <>
      <Cover style={{ backgroundImage: `url(${coverImageUrl})` }} />

      <Container>
        <Stack.H gap={16}>
          {badgeUrl != null && <Badge src={badgeUrl} />}

          <Stack.V gap={2} justify="center">
            <HeadingS>{title}</HeadingS>
            {subtitleContent}
          </Stack.V>

          <Aside style={{ alignSelf: 'end' }}>{asideContent}</Aside>
        </Stack.H>
      </Container>

      {tabs != null && (
        <StickyWrapper>
          <StickyWrapperContainer>
            <Stack.H center>{tabs}</Stack.H>
          </StickyWrapperContainer>
        </StickyWrapper>
      )}
      {secondaryActionsContent != null && (
        <SecondaryStickyWrapper top={tabs == null ? 0 : STICKY_WRAPPER_HEIGHT}>
          <SecondaryStickyWrapperContainer center gap={10}>
            {secondaryActionsContent}
          </SecondaryStickyWrapperContainer>
        </SecondaryStickyWrapper>
      )}
    </>
  );
}

export function PrintButton({ pdfUrl }: { pdfUrl: string }) {
  return (
    <AnchorButton
      color="cloudBurst"
      size="small"
      isRound
      type="secondary"
      href={pdfUrl}
      target="_blank"
      rel="noopener noreferrer"
      fontWeight={fontWeight.normal}
    >
      <Stack.H center gap={8}>
        {/*
        Hide the icon for now to optimise space. Leave it commented as it is likely subject to change.
        <PrintIcon /> */}
        Print
      </Stack.H>
    </AnchorButton>
  );
}

export function ActionButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <Button
      color="cloudBurst"
      isRound
      size="small"
      type="secondary"
      onClick={onClick}
      fontWeight={fontWeight.normal}
    >
      {children}
    </Button>
  );
}

export function ActionAnchorButton({
  children,
  href,
  highlighted,
}: {
  children: React.ReactNode;
  href: string;
  highlighted?: boolean;
}) {
  return (
    <AnchorButton
      color={highlighted ? 'eggplant' : 'cloudBurst'}
      size="small"
      isRound
      type="secondary"
      href={href}
      target="_blank"
      fontWeight={fontWeight.normal}
    >
      {children}
    </AnchorButton>
  );
}
