import moment from 'moment';
import { map } from 'ramda';
import { useState, useMemo } from 'react';

import type {
  ClassSelectionPayload,
  StudentSelectionPayload,
} from 'ms-pages/Teacher/components/ClassAndStudentSelector';
import {
  roundMastery,
  NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE,
} from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import { parseCompleteAndPartialClasses } from 'ms-pages/Teacher/components/CreateTask/helpers';
import type { EditTaskFlyout_assignments as Assignments } from 'ms-pages/Teacher/components/EditTaskFlyout/__generated__/EditTaskFlyout_assignments.graphql';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import editAdaptiveTask from './state/editAdaptiveTask';
import editNewAdaptiveExperienceTask from './state/editNewAdaptiveExperienceTask';
import * as updaters from './state/updaters';
import { excludedStudents } from '../';
import type { EditTaskFlyout_adaptiveTask$data } from '../__generated__/EditTaskFlyout_adaptiveTask.graphql';
import type { EditTaskFlyout_newAdaptiveExperienceTask$data } from '../__generated__/EditTaskFlyout_newAdaptiveExperienceTask.graphql';

type AdaptiveTask =
  | EditTaskFlyout_adaptiveTask$data
  | EditTaskFlyout_newAdaptiveExperienceTask$data;

export type TaskId = string;
export type TaskName = string;
export type TaskStartDate = Date;
export type TaskDueDate = Date;
export type TargetMastery = number;
export type CorrectQuestionTarget = number | null | undefined;
export type SubtopicId = string;
export type DisableCalculator = boolean;
export type SelectedClasses = readonly ClassSelectionPayload[];
export type SelectedStudents = readonly StudentSelectionPayload[];
export type ExcludedStudents = readonly StudentSelectionPayload[];
type State = {
  taskName: TaskName;
  taskStartDate: TaskStartDate;
  taskDueDate: TaskDueDate;
  targetMastery: TargetMastery;
  minimumQuestionTarget: number;
  correctQuestionTarget: CorrectQuestionTarget;
  subtopicId: SubtopicId;
  disableCalculator: DisableCalculator;
  selectedClasses: readonly ClassSelectionPayload[];
  selectedStudents: readonly StudentSelectionPayload[];
  excludedStudents: readonly StudentSelectionPayload[];
};
type Updaters = typeof updaters;
export type Updater<I extends readonly any[]> = (
  ...args: I
) => (state: State) => State;
type CallbackUpdater<I extends readonly any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};
export type CombinedState = {
  values: State;
  updaters: TransformedUpdaters;
};
const transformUpdaters = (
  setState: (cb: (state: State | null) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );
export function useEditAdaptiveTaskState(
  task: AdaptiveTask,
  assignments: Assignments,
) {
  const [state, setState] = useState<State | null>(
    task.__typename === 'AdaptiveTask'
      ? {
          taskName: task.title,
          targetMastery: roundMastery(task.targetMastery),
          taskStartDate: new Date(task.startDate),
          taskDueDate: new Date(task.dueDate),
          minimumQuestionTarget: task.minimumQuestionTarget,
          subtopicId: task.areaOfStudy.id,
          correctQuestionTarget: task.areaOfStudy.correctQuestionTarget,
          disableCalculator: !task.allowCalculator,
          selectedClasses: assignments.assignedClasses,
          selectedStudents: assignments.individuallyAssignedStudents.map(
            student => ({
              id: student.id,
              ...student.user,
            }),
          ),
          excludedStudents: excludedStudents(
            assignments.partiallyAssignedClasses,
          ),
        }
      : task.__typename === 'NewAdaptiveExperienceTask'
      ? {
          taskName: task.title,
          targetMastery: roundMastery(
            task.newAdaptiveTargetMastery ??
              NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE,
          ),
          taskStartDate: new Date(task.startDate),
          taskDueDate: new Date(task.dueDate),
          minimumQuestionTarget: task.newAdaptiveMinimumQuestionTarget ?? 0,
          subtopicId: task.areaOfStudy.id,
          correctQuestionTarget: task.areaOfStudy.correctQuestionTarget,
          disableCalculator: !task.allowCalculator,
          selectedClasses: assignments.assignedClasses,
          selectedStudents: assignments.individuallyAssignedStudents.map(
            student => ({
              id: student.id,
              ...student.user,
            }),
          ),
          excludedStudents: excludedStudents(
            assignments.partiallyAssignedClasses,
          ),
        }
      : null,
  );
  const transformedUpdaters = useMemo(() => transformUpdaters(setState), []);
  const stateAndUpdaters = {
    values: state,
    updaters: transformedUpdaters,
  };
  if (state === null) return null;
  const { completeClassIds, partialClasses } = parseCompleteAndPartialClasses(
    state?.selectedClasses || [],
    state?.excludedStudents || [],
  );
  if (task.__typename === 'NewAdaptiveExperienceTask') {
    return [
      stateAndUpdaters,
      editNewAdaptiveExperienceTask({
        taskId: task.id,
        title: state.taskName,
        startDate: moment(state.taskStartDate).format(),
        dueDate: moment(state.taskDueDate).format(),
        allowCalculator: !state.disableCalculator,
        assignedClassIds: completeClassIds,
        assignedStudentIds: state?.selectedStudents.map(s => s.id),
        partiallyAssignedClasses: partialClasses,
        targetMastery:
          state.targetMastery !==
          NEW_ADAPTIVE_EXPERIENCE_NO_TARGET_MASTERY_VALUE
            ? state.targetMastery
            : undefined,
      }),
    ] as const;
  } else if (task.__typename === 'AdaptiveTask') {
    return [
      stateAndUpdaters,
      editAdaptiveTask({
        taskId: task.id,
        title: state.taskName,
        targetMastery: state.targetMastery,
        startDate: moment(state.taskStartDate).format(),
        dueDate: moment(state.taskDueDate).format(),
        minimumQuestionTarget: state.minimumQuestionTarget,
        allowCalculator: !state.disableCalculator,
        assignedClassIds: completeClassIds,
        assignedStudentIds: state?.selectedStudents.map(s => s.id),
        partiallyAssignedClasses: partialClasses,
        first: RELAY_CONNECTION_MAX,
      }),
    ] as const;
  } else {
    return null;
  }
}
