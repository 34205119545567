import ChevronLeftThick from 'ms-components/icons/ChevronLeftThick';
import ChevronRightThick from 'ms-components/icons/ChevronRightThick';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { alternateColors, colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import { HSpacer, HStack } from 'ms-ui-primitives/Stack';
import { onPressOrHover } from 'ms-utils/emotion';

const styles = {
  button: {
    active: {
      border: `1px solid ${colors.eggplant}`,
      ...onPressOrHover({
        borderColor: alternateColors.eggplant,
        background: alternateColors.eggplant10,
      }),
    },
    disabled: {
      border: `1px solid ${colors.ironLight}`,
      background: 'transparent',
    },
  },
};

type Props = {
  activeSubtopicIndex: number;
  numberOfTasks: number;
  onRightArrowClick: () => void;
  onLeftArrowClick: () => void;
};

export default function SubtopicNavbar({
  activeSubtopicIndex,
  numberOfTasks,
  onRightArrowClick,
  onLeftArrowClick,
}: Props) {
  const isLeftArrowDisabled = activeSubtopicIndex === 0;
  const isRightArrowDisabled = activeSubtopicIndex === numberOfTasks - 1;

  return (
    <HStack
      center
      style={{
        padding: '8px 16px',
        borderBottom: `1px solid ${colors.ironLight}`,
        backgroundColor: colors.white,
      }}
    >
      <HeadingS>
        Task {activeSubtopicIndex + 1} of {numberOfTasks}
      </HeadingS>
      <HSpacer width={0} grow />
      <HStack center>
        <Button
          size="small"
          color="eggplant10"
          isDisabled={isLeftArrowDisabled}
          type="primary"
          styles={
            isLeftArrowDisabled ? styles.button.disabled : styles.button.active
          }
          onClick={onLeftArrowClick}
        >
          <ChevronLeftThick
            size={14}
            color={isLeftArrowDisabled ? colors.grey10 : colors.eggplant}
          />
        </Button>
        <HSpacer width={8} />
        <Button
          size="small"
          color="eggplant10"
          isDisabled={isRightArrowDisabled}
          type="primary"
          styles={
            isRightArrowDisabled ? styles.button.disabled : styles.button.active
          }
          onClick={onRightArrowClick}
        >
          <ChevronRightThick
            size={14}
            color={isRightArrowDisabled ? colors.grey10 : colors.eggplant}
          />
        </Button>
      </HStack>
    </HStack>
  );
}
