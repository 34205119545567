import { append, head, tail } from 'ramda';
import type { ReactNode } from 'react';
import { createContext, useState, useMemo, useCallback } from 'react';

export type Message = {
  text: string;
  href?: string | undefined;
  actionLabel?: string | undefined;
  handleAction?: (() => void) | undefined;
  persistent?: boolean | undefined;
  dismissable?: boolean | undefined;
};
type Messages = ReadonlyArray<Message>;
type DequeueMessage = () => Message | null | undefined;
type EnqueueMessage = (message: Message) => void;

export type SnackbarContextType = {
  messages: Messages;
  dequeueMessage: DequeueMessage;
  enqueueMessage: EnqueueMessage;
};

const defaultSnackbarContext: SnackbarContextType = {
  messages: [],
  enqueueMessage: () => {},
  dequeueMessage: () => undefined,
};

export const SnackbarContext = createContext<SnackbarContextType>(
  defaultSnackbarContext,
);

export default function Provider({ children }: { children: ReactNode }) {
  const [messages, setMessages] = useState<Messages>([]);

  const dequeueMessage: DequeueMessage = useCallback(() => {
    let firstMessage: Message | undefined;
    setMessages(prevMessages => {
      firstMessage = head(prevMessages);
      return tail(prevMessages);
    });
    return firstMessage;
  }, []);

  const enqueueMessage: EnqueueMessage = useCallback(message => {
    setMessages(prevMessages => append(message, prevMessages));
  }, []);

  const value = useMemo(
    () => ({ messages, dequeueMessage, enqueueMessage }),
    [dequeueMessage, enqueueMessage, messages],
  );

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}
