import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M5.80968114,8.79448316 L12.7817485,1.81022272 C13.7207954,0.869533596 15.1293657,2.28056729 14.1903189,3.22125641 L7.21825146,10.2055168 C6.27920458,11.146206 4.87063426,9.73517228 5.80968114,8.79448316 Z M7.21825146,8.79448316 L14.1903189,15.7787436 C15.1293657,16.7194327 13.7207954,18.1304664 12.7817485,17.1897773 L5.80968114,10.2055168 C4.87063426,9.26482772 6.27920458,7.85379403 7.21825146,8.79448316 Z"
    transform="translate(10.000000, 9.500000) scale(1, -1) rotate(90.000000) translate(-10.000000, -9.500000)"
  />
);

const ChevronBottom = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

ChevronBottom.displayName = 'ChevronBottom';

export default ChevronBottom;
