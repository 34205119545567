import { assertUnreachable } from 'ms-utils/typescript-utils';

// TODO refine type with acceptable pairs
type Offset = {
  top: number;
  left: number;
};

type OriginHorizontal = 'left' | 'center' | 'right';
type OriginVertical = 'top' | 'middle' | 'bottom';

type Origin = readonly [OriginVertical, OriginHorizontal];
export type PopoverOrigin = Origin;
export type AnchorOrigin = Origin;

type Measurements = {
  anchorLeft: number;
  anchorRight: number;
  anchorTop: number;
  anchorBottom: number;
  anchorWidth: number;
  anchorHeight: number;
  popoverWidth: number;
  popoverHeight: number;
};

type CalculateOffsetArgs = Measurements & {
  hOffset: number;
  vOffset: number;
  popoverOrigin: PopoverOrigin;
  anchorOrigin: AnchorOrigin;
};

export const calculateOffset = ({
  anchorLeft,
  anchorRight,
  anchorTop,
  anchorBottom,
  anchorWidth,
  anchorHeight,
  popoverWidth,
  popoverHeight,

  hOffset,
  vOffset,
  popoverOrigin,
  anchorOrigin,
}: CalculateOffsetArgs): Offset => {
  const [anchorY, anchorX] = anchorOrigin;
  const left = (() => {
    const [, popX] = popoverOrigin;
    switch (popX) {
      case 'left': {
        switch (anchorX) {
          case 'left':
            return anchorLeft + hOffset;
          case 'right':
            return anchorRight + hOffset;
          case 'center':
            return anchorLeft + anchorWidth / 2 + hOffset;
          default: {
            assertUnreachable(
              anchorX,
              `not possible, should be either left or right or center, got ${anchorX}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'right': {
        switch (anchorX) {
          case 'left':
            return anchorLeft - popoverWidth + hOffset;
          case 'right':
            return anchorRight - popoverWidth + hOffset;
          case 'center':
            return anchorLeft + anchorWidth / 2 - popoverWidth + hOffset;
          default: {
            assertUnreachable(
              anchorX,
              `not possible, should be either left or right or center, got ${anchorX}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'center': {
        switch (anchorX) {
          case 'left':
            return anchorLeft - popoverWidth / 2 + hOffset;
          case 'right':
            return anchorRight - popoverWidth / 2 + hOffset;
          case 'center':
            return anchorLeft + anchorWidth / 2 - popoverWidth / 2 + hOffset;
          default: {
            assertUnreachable(
              anchorX,
              `not possible, should be either left or right or center, got ${anchorX}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        assertUnreachable(
          popX,
          `not possible, should be either left or right or center, got ${popX}`,
        );
      }
    }
  })();

  const top = (() => {
    const [popY] = popoverOrigin;
    switch (popY) {
      case 'top': {
        switch (anchorY) {
          case 'top':
            return anchorTop + vOffset;
          case 'bottom':
            return anchorBottom + vOffset;
          case 'middle':
            return anchorTop + anchorHeight / 2 + vOffset;
          default: {
            assertUnreachable(
              anchorY,
              `not possible, should be either top or bottom or middle, got ${anchorY}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'bottom': {
        switch (anchorY) {
          case 'top':
            return anchorTop - popoverHeight + vOffset;
          case 'bottom':
            return anchorBottom - popoverHeight + vOffset;
          case 'middle':
            return anchorTop + anchorHeight / 2 - popoverHeight + vOffset;
          default: {
            assertUnreachable(
              anchorY,
              `not possible, should be either top or bottom or middle, got ${anchorY}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      case 'middle': {
        switch (anchorY) {
          case 'top':
            return anchorTop - popoverHeight / 2 + vOffset;
          case 'bottom':
            return anchorBottom - popoverHeight / 2 + vOffset;
          case 'middle':
            return anchorTop + anchorHeight / 2 - popoverHeight / 2 + vOffset;
          default: {
            assertUnreachable(
              anchorY,
              `not possible, should be either top or bottom or middle, got ${anchorY}`,
            );
          }
        }
      }
      // eslint-disable-next-line no-fallthrough
      default: {
        assertUnreachable(
          popY,
          `not possible, should be either top or bottom or middle, got ${popY}`,
        );
      }
    }
  })();
  return { left, top };
};

type ElementNodes = {
  anchorElement: HTMLElement;
  popoverElement: HTMLElement;
};

export const getMeasurements = ({
  anchorElement,
  popoverElement,
}: ElementNodes): Measurements => {
  let {
    left: anchorLeft,
    right: anchorRight,
    top: anchorTop,
    bottom: anchorBottom,
    width: anchorWidth,
    height: anchorHeight,
  } = anchorElement.getBoundingClientRect();

  // We want to get the current offset parent and subtract it from the positions of the bounding client rect
  // There are cases where the popover may be inside an element with a transform or will-change (like the modal)
  // that causes the containing block to not be the viewport
  // In browsers that are not firefox, this can be nullable if the containing parent is display:none or is the
  // <body> or <html> elements themselves. In those cases, it's safe to assume that the offsets are 0
  const popoverOffsetParent = anchorElement.offsetParent;

  if (popoverOffsetParent != null) {
    const computedStyle = getComputedStyle(popoverOffsetParent);
    // https://developer.mozilla.org/en-US/docs/Web/CSS/Containing_block
    if (
      computedStyle.willChange === 'transform' ||
      computedStyle.willChange === 'perspective' ||
      computedStyle.transform !== 'none' ||
      computedStyle.filter !== 'none' ||
      computedStyle.contain === 'paint'
    ) {
      const popoverOffsetRect = popoverOffsetParent.getBoundingClientRect();
      anchorLeft -= popoverOffsetRect?.left ?? 0;
      anchorTop -= popoverOffsetRect?.top ?? 0;
      anchorRight -= popoverOffsetRect?.left ?? 0;
      anchorBottom -= popoverOffsetRect?.top ?? 0;
    }
  }

  const { width: popoverWidth, height: popoverHeight } =
    popoverElement.getBoundingClientRect();

  return {
    anchorLeft,
    anchorRight,
    anchorTop,
    anchorBottom,
    anchorWidth,
    anchorHeight,
    popoverWidth,
    popoverHeight,
  };
};
