import { useEffect } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';

// Static recurring period of 90 days
const RECURRING_PERIOD = 60 * 60 * 24 * 90;

type DelightedType = {
  survey: (arg: {
    initialDelay: number;
    email: string;
    properties: Object;
    recurringPeriod: number;
  }) => void;
};
type Props = {
  delighted: DelightedType;
  initialDelay?: number;
};

const Delighted = ({ delighted, initialDelay = 0 }: Props) => {
  const { accountType, userPk, userEmail } = useViewer();
  useEffect(() => {
    if (delighted != null)
      delighted.survey({
        initialDelay,
        email: accountType === 'STUDENT' ? '' : userEmail,
        properties: {
          userId: userPk,
          appId: window.DELIGHTED_APP_ID,
        },
        recurringPeriod: RECURRING_PERIOD,
      });
  }, [accountType, delighted, initialDelay, userEmail, userPk]);
  return null;
};

export default Delighted;
