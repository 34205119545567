import type {
  Subtopic,
  Topic,
} from 'ms-pages/Teacher/components/TopicSubtopicTree/types';

function getNodes<T>(connection: {
  readonly edges: readonly {
    readonly node: T;
  }[];
}) {
  return connection.edges.map(edge => edge.node);
}

export function assignableSubtopicForExercise(subtopic: Subtopic): boolean {
  return subtopic.hasExercise;
}

export function assignableSubtopicForAdaptivePractice(
  subtopic: Subtopic,
): boolean {
  return subtopic.hasAdaptivePractice;
}

export function assignableSubtopicForTheory(subtopic: Subtopic): boolean {
  return subtopic.hasLessons || subtopic.hasInvestigations;
}

export function assignableSubtopicForEngageTask(subtopic: Subtopic): boolean {
  return subtopic.hasEngageActivity;
}

export function assignableSubtopicForWorksheet(subtopic: Subtopic): boolean {
  return subtopic.hasWorksheet;
}

export function assignableTopicForExercise(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(assignableSubtopicForExercise);
}

export function assignableTopicForAdaptivePractice(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(assignableSubtopicForAdaptivePractice);
}

export function assignableTopicForTheory(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(assignableSubtopicForTheory);
}

export function assignableTopicForEngageTask(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(assignableSubtopicForEngageTask);
}

export function assignableTopicForWorksheet(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(assignableSubtopicForWorksheet);
}

export function hasSubtopics(topic: Topic) {
  return getNodes(topic.subtopics).length > 0;
}

export function hasAssignedLessonTask(subtopic: Subtopic): boolean {
  return subtopic.earliestDueAssignedLessonTask != null;
}

export function hasAssignedUnstartedLessonTask(subtopic: Subtopic): boolean {
  return (
    subtopic.earliestDueAssignedLessonTask != null &&
    subtopic.earliestDueAssignedLessonTask.status === 'NOT_STARTED'
  );
}

export function hasSubtopicsWithAssignedLessonTask(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(hasAssignedLessonTask);
}

export function hasSubtopicsWithUnstartedAssignedLessonTask(
  topic: Topic,
): boolean {
  return getNodes(topic.subtopics).some(hasAssignedUnstartedLessonTask);
}

export function hasAssignedWorksheetTask(subtopic: Subtopic): boolean {
  return subtopic.earliestDueAssignedWorksheetTask != null;
}

export function hasAssignedUnstartedWorksheetTask(subtopic: Subtopic): boolean {
  return (
    subtopic.earliestDueAssignedWorksheetTask != null &&
    subtopic.earliestDueAssignedWorksheetTask.status === 'NOT_STARTED'
  );
}

export function hasSubtopicsWithAssignedWorksheetTask(topic: Topic): boolean {
  return getNodes(topic.subtopics).some(hasAssignedWorksheetTask);
}

export function hasSubtopicsWithUnstartedAssignedWorksheetTask(
  topic: Topic,
): boolean {
  return getNodes(topic.subtopics).some(hasAssignedUnstartedWorksheetTask);
}
