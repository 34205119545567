import styled from '@emotion/styled';

import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { InvariantViolation } from 'ms-utils/app-logging';

const SeparatorStyle = styled.div<{ grow: boolean; inline: boolean }>(
  {
    flexShrink: 0,
  },
  ({ grow }) =>
    grow && {
      flexGrow: 1,
    },
  ({ inline }) =>
    inline && {
      display: 'inline-flex',
    },
);

type Props = {
  size: number;
  grow?: boolean;
  inline?: boolean;
};

export default function Separator({
  size,
  grow = false,
  inline = false,
}: Props) {
  if (Number.isInteger(size) || size === 0.5)
    return (
      <SeparatorStyle
        grow={grow}
        inline={inline}
        style={{ height: size * BASE_UNIT, width: size * BASE_UNIT }}
      />
    );

  throw new InvariantViolation(
    `The size ${size} provided for Separator is not valid. It must be an integer or 0.5`,
  );
}
