import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    transform="translate(3.000000, 2.500000)"
    d="M7.129 4.791l3.297-3.296a2 2 0 0 1 2.828 2.828L9.957 7.62l3.297 3.296a2 2 0 1 1-2.828 2.829l-3.297-3.297-3.297 3.297a2 2 0 1 1-2.828-2.829L4.301 7.62 1.004 4.323a2 2 0 0 1 2.828-2.828L7.13 4.79z"
    fillRule="nonzero"
  />
);

const CrossThick = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

CrossThick.displayName = 'CrossThick';

export default CrossThick;
