import { createContext, useReducer, useContext, type ReactNode } from 'react';

import {
  type Preselection,
  type Action,
  createInitialState,
  initialState,
  useTrackingAugmentedReducer,
  type State,
} from 'ms-pages/Teacher/components/CreateTask/state/createTaskState';

const CreateTaskContext = createContext<State>(initialState);

const CreateTaskDispatch = createContext<(p: Action) => void>(() => {});

export function CreateTaskStateProvider({
  children,
  preselection,
}: {
  children: ReactNode;
  preselection?: Preselection | null | undefined;
}) {
  const [state, dispatch] = useReducer(
    useTrackingAugmentedReducer(),
    createInitialState({
      preselection,
    }),
  );

  return (
    <CreateTaskContext.Provider value={state}>
      <CreateTaskDispatch.Provider value={dispatch}>
        {children}
      </CreateTaskDispatch.Provider>
    </CreateTaskContext.Provider>
  );
}

export function useCreateTaskDispatch(): (p: Action) => void {
  return useContext(CreateTaskDispatch);
}

export function useCreateTaskState(): State {
  return useContext(CreateTaskContext);
}
