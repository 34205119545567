import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M4,3 L16,3 C17.1045695,3 18,3.8954305 18,5 L18,15 C18,16.1045695 17.1045695,17 16,17 L4,17 C2.8954305,17 2,16.1045695 2,15 L2,5 C2,3.8954305 2.8954305,3 4,3 Z M8.42521764,6.60636725 C8.30312659,6.53703798 8.15924173,6.5 8.01200297,6.5 C7.60371228,6.5 7.27272727,6.77882614 7.27272727,7.12277562 L7.27272727,12.8771611 C7.27272727,13.001197 7.3166938,13.1224075 7.3989922,13.2252586 C7.62720437,13.5104631 8.08666114,13.5858184 8.42521764,13.3935695 L13.4920457,10.5163767 C13.5708705,10.4716162 13.638708,10.4144689 13.6918418,10.3480658 C13.9200539,10.0628614 13.8306022,9.67580893 13.4920457,9.48355999 L8.42521764,6.60636725 Z" />
);

const Video = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Video.displayName = 'Video';

export default Video;
