import { reduce, toPairs } from 'ramda';

import type { Value } from 'ms-components/math/MultipleChoice';
import type { subproblemFragments_MultipleChoiceConfig } from 'ms-pages/Work/Subproblem/__generated__/subproblemFragments_MultipleChoiceConfig.graphql';
import type { Writable } from 'ms-utils/typescript-utils';

type Props = {
  value: Value;
};
type InputValue = {
  readonly options: subproblemFragments_MultipleChoiceConfig['options'];
};
/**
 * Transforms the input value state for MultipleChoice subproblem
 * into the props for the corresponding `MultipleChoice` component.
 *
 * @param {InputValue} inputValue Subproblem's input value state.
 * @returns {Props} Props for `MultipleChoice` component.
 */
export const toInternalValueFormat = (inputValue: InputValue): Props => ({
  value:
    inputValue && inputValue.options
      ? reduce(
          (acc, edge) =>
            edge
              ? {
                  ...acc,
                  [edge.optionKey]: {
                    value: edge.optionContent,
                    selected: edge.selected,
                  },
                }
              : acc,
          {},
          inputValue.options,
        )
      : {},
});
/**
 * Transforms the props of `MultipleChoice` component into
 * the shape of the input value state of the subproblem.
 *
 * @param {Props} props The subset of `MultipleChoice` component props.
 * @returns {InputValue} The input config.
 */
export const fromInternalValueFormat = (props: Props): InputValue => {
  const options: Writable<InputValue['options']> = [];
  for (const [key, value] of toPairs(props.value)) {
    options.push({
      optionKey: key,
      optionContent: value.value,
      selected: value.selected,
    });
  }
  return { options };
};
