/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaskBadge_task = {
    readonly __typename: "CustomTask";
    readonly id: string;
} | {
    readonly __typename: "LessonTask";
    readonly subtopic: {
        readonly topic: {
            readonly badgeUrl: string;
            readonly badgeBackground: string | null;
        };
    };
} | {
    readonly __typename: "WorksheetTask";
    readonly subtopic: {
        readonly topic: {
            readonly badgeUrl: string;
            readonly badgeBackground: string | null;
        };
    };
} | {
    readonly __typename: "NewAdaptiveExperienceTask";
    readonly subtopic: {
        readonly topic: {
            readonly badgeUrl: string;
            readonly badgeBackground: string | null;
        };
    };
} | {
    readonly __typename: "AdaptiveTask";
    readonly subtopic: {
        readonly topic: {
            readonly badgeUrl: string;
            readonly badgeBackground: string | null;
        };
    };
} | {
    readonly __typename: "EngageTask";
    readonly subtopic: {
        readonly topic: {
            readonly badgeUrl: string;
            readonly badgeBackground: string | null;
        };
    };
} | {
    readonly __typename: "CheckInTask";
    readonly strand: {
        readonly __typename: string;
    };
} | {
    /*This will never be '%other', but we need some
    value in case none of the concrete values match.*/
    readonly __typename: "%other";
};
export type TaskBadge_task$data = TaskBadge_task;
export type TaskBadge_task$key = {
    readonly " $data"?: TaskBadge_task$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"TaskBadge_task">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = [
  {
    "alias": "subtopic",
    "args": null,
    "concreteType": "Subtopic",
    "kind": "LinkedField",
    "name": "areaOfStudy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "topic",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "badgeUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "badgeBackground",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "TaskBadge_task",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "type": "CustomTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "LessonTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "WorksheetTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "NewAdaptiveExperienceTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "AdaptiveTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v1/*: any*/),
      "type": "EngageTask",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "strand",
          "args": null,
          "concreteType": "Strand",
          "kind": "LinkedField",
          "name": "areaOfStudy",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "CheckInTask",
      "abstractKey": null
    }
  ],
  "type": "TaskInterface",
  "abstractKey": "__isTaskInterface"
};
})();
(node as any).hash = 'cac3b075837fcff4f6b462e4090194c4';
export default node;
