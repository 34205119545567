import { StyleSheet, css } from 'aphrodite';
import { useEffect, useRef } from 'react';
import TrashIcon from 'react-icons/lib/fa/trash';

import { borderRadiusUI, fontSize, zIndex } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';

export function Menu({
  id,
  options,
  x,
  y,
  showRemove,
  onAddNode,
  onRemoveNode,
  onHide,
}: {
  id: number;
  onAddNode?: ((id: number, option: string) => void) | undefined;
  onHide?: (() => void) | undefined;
  onRemoveNode?: ((id: number) => void) | undefined;
  options: ReadonlyArray<string>;
  showRemove?: boolean | undefined;
  x: number;
  y: number;
}) {
  const menuRef = useRef<HTMLDivElement>(null);

  // If the user clicks outside the menu, hide it
  useEffect(() => {
    function onClick(event: MouseEvent) {
      if (menuRef.current === null) return;
      if (!(event.target instanceof Node)) return;
      if (!menuRef.current.contains(event.target)) {
        onHide?.();
      }
    }
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  }, [onHide]);

  return (
    <div
      ref={menuRef}
      className={css(styles.base)}
      style={{ top: y, left: x + 10 }}
    >
      {options.map((option, i) => (
        <div key={i} className={css(styles.buttonContainer)}>
          <Button
            type="primary"
            isBlock
            onClick={() => onAddNode?.(id, option)}
          >
            {option}
          </Button>
        </div>
      ))}
      {showRemove && (
        <div key="remove" className={css(styles.buttonContainer)}>
          <Button
            onClick={() => {
              onRemoveNode?.(id);
              onHide?.();
            }}
            type="primary"
            isBlock
            color="iron"
            label="Remove"
          >
            <TrashIcon color={colors.black} size={fontSize.large} />
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  base: {
    backgroundColor: colors.ironLight,
    borderRadius: borderRadiusUI,
    position: 'absolute',
    zIndex: zIndex.probabilityTreeMenu,
  },
  buttonContainer: {
    margin: 5,
    minWidth: 42,
    whiteSpace: 'nowrap',
  },
});
