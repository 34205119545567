import type { ReactNode } from 'react';
import { graphql, useMutation } from 'react-relay';
import { useQuery } from 'relay-hooks';

import PageErrorMessageView from 'ms-components/PageErrorMessageView';
import Root from 'ms-components/Root';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import Center from 'ms-ui-primitives/Center';
import LoadingSpinner from 'ms-ui-primitives/LoadingSpinner';
import { noop } from 'ms-utils/misc';

import Avatar from './Avatar';
import type { AvatarContainerQuery } from './__generated__/AvatarContainerQuery.graphql';
import type { AvatarContainerUpdateAvatarMutation } from './__generated__/AvatarContainerUpdateAvatarMutation.graphql';

export type Variant = 'default' | 'modal';
export type Title = ReactNode;
type Props = {
  onSubmit?: (() => void) | undefined;
  isSubmitDisabled?: boolean | undefined;
  forceLoadingState?: boolean | undefined;
  submitButtonLabel?: string | undefined;
  title?: Title;
  variant?: Variant | undefined;
};
const query = graphql`
  query AvatarContainerQuery {
    viewer {
      pk
      avatar
    }
  }
`;
const updateAvatarMutation = graphql`
  mutation AvatarContainerUpdateAvatarMutation(
    $user: UpdateUserInput!
    $pk: Int!
  ) {
    updateUser(user: $user, pk: $pk) {
      user {
        avatar
      }
    }
  }
`;

const Loading = () => (
  <Root>
    <Center>
      <LoadingSpinner />
    </Center>
  </Root>
);
export const AvatarContainer = ({
  onSubmit = noop,
  isSubmitDisabled = false,
  forceLoadingState = false,
  submitButtonLabel,
  title = 'Choose your avatar',
  variant = 'default',
}: Props) => {
  const { props, error } = useQuery<AvatarContainerQuery>(query, {});

  const [doUpdateAvatar, isAvatarLoading] =
    useMutation<AvatarContainerUpdateAvatarMutation>(updateAvatarMutation);

  if (error)
    return (
      <PageTimeErrorThrower
        pageName="Avatar"
        componentName="Avatar"
        error={error}
      />
    );
  if (!props) {
    return <Loading />;
  }
  const { viewer } = props;
  if (viewer === null) {
    // TODO: Instead redirect to login? Actually, graphql seems to throw an exceptions if the user is not logged in
    // In fact, as long as the route is handled by python, the logged out user will be redirected "server-side" to login
    return (
      <PageErrorMessageView>
        You must be logged in to access this page
      </PageErrorMessageView>
    );
  }
  return (
    <PageTimeRecorder pageName="Avatar" componentName="Avatar">
      <Avatar
        doUpdateAvatar={doUpdateAvatar}
        isAvatarLoading={isAvatarLoading}
        forceLoadingState={forceLoadingState}
        onSubmit={onSubmit}
        isSubmitDisabled={isSubmitDisabled}
        submitButtonLabel={submitButtonLabel}
        viewer={viewer}
        title={title}
        variant={variant}
      />
    </PageTimeRecorder>
  );
};
