import { css, StyleSheet } from 'aphrodite';

import { fontFamily, fontSize, lineHeight, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

type Props = {
  title: string;
  description?: string | undefined;
};

const styles = StyleSheet.create({
  title: {
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    fontSize: fontSize.subsectionTitle,
    lineHeight: lineHeight.heading,
    marginTop: 0,
    marginBottom: 0,
    color: colors.mako,
  },
  subtitle: {
    color: colors.dustyGray,
    fontFamily: fontFamily.body,
    fontWeight: fontWeight.semibold,
    fontSize: fontSize.errorMessage,
    lineHeight: lineHeight.body,
    margin: `${BASE_UNIT}px 0 0`,
  },
});

const FieldTitle = ({ title, description, ...rest }: Props) => (
  <div {...rest}>
    <h5 className={css(styles.title)}>{title}</h5>
    {description && <p className={css(styles.subtitle)}>{description}</p>}
  </div>
);

export default FieldTitle;
