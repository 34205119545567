import Switch from 'ms-pages/Teacher/components/Switch';
import type { SubtopicLessonTab } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import { SUBTOPIC_LESSON_TABS } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import { useSubtopicLessonTabsState } from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';

const SUBTOPIC_LESSON_TABS_LABELS: Record<SubtopicLessonTab, string> = {
  content: 'Student lesson',
  teacherGuide: 'Teacher guide',
};

const SWITCH_OPTIONS = SUBTOPIC_LESSON_TABS.map(subtab => ({
  label: SUBTOPIC_LESSON_TABS_LABELS[subtab],
  value: subtab,
}));

export default function SubtopicLessonSubtabSwitch({
  small,
  activeColor,
  backgroundColor,
}: {
  small?: boolean;
  activeColor?: string;
  backgroundColor?: string;
}) {
  const [subtopicLessonTab, setSubtopicLessonTab] =
    useSubtopicLessonTabsState();

  return (
    <Switch<SubtopicLessonTab>
      backgroundColor={backgroundColor}
      activeColor={activeColor}
      width={284}
      small={small}
      options={SWITCH_OPTIONS}
      value={subtopicLessonTab}
      onChange={setSubtopicLessonTab}
    />
  );
}
