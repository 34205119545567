import type { Item, Items } from 'ms-pages/Teacher/components/InputSelector';
import { EMPTY_ITEM } from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import type { SchoolClasses } from 'ms-pages/Teacher/components/StudentCreateEdit/types';

export const mapClassesToItems = (classes: SchoolClasses): Items =>
  classes.map(({ id, title }) => ({
    id,
    title,
  }));

export const addItem = ({
  items,
  item,
}: {
  items: Items;
  item: Item;
}): Items =>
  items.find(i => i.id === item.id) == null ? [...items, item] : items;

export const removeItem = ({
  items,
  itemId,
}: {
  items: Items;
  itemId: Item['id'];
}): Items => items.filter(i => i.id !== itemId);

export const mapItemsToIds = (items: Items): ReadonlyArray<string> =>
  items.map(s => s.id);

export const getClassSyllabusFocus = (classes: SchoolClasses, klass: Item) => {
  const klass_ = classes.find(
    c => c.id === klass.id && c.classSyllabusFocus != null,
  );
  const syllabusFocus =
    klass_ != null && klass_.classSyllabusFocus != null
      ? klass_.classSyllabusFocus
      : EMPTY_ITEM;

  return syllabusFocus;
};

export const getClassGrade = (classes: SchoolClasses, klass: Item) => {
  const klass_ = classes.find(c => c.id === klass.id && c.classGrade != null);
  const grade =
    klass_ != null && klass_.classGrade != null
      ? klass_.classGrade
      : EMPTY_ITEM;

  return grade;
};
