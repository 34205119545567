import styled from '@emotion/styled';
import { createContext, useEffect, useMemo } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import JsxContent from 'ms-components/JsxContent';
import MathContent from 'ms-components/math/MathContent';
import { ChapterLink } from 'ms-components/math/MathContent/ChapterLink';
import type { NodeRendererMap } from 'ms-components/math/MathContent/renderNode';
import {
  PageTimeErrorThrower,
  PageTimeRecorder,
} from 'ms-helpers/PageTimeTracker';
import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';
import { Base as CustomEmptyMessage } from 'ms-pages/Teacher/components/Empty';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import NotesModalLauncher from 'ms-pages/Textbooks/StudentTextbook/components/NotesModalLauncher';
import { InvestigationChapter } from 'ms-pages/Textbooks/components/InvestigationChapter';
import { useLanguageSelectorContext } from 'ms-pages/Textbooks/components/LanguageSelector/LanguageSelectorContext';
import { useSubtopicLessonTabs } from 'ms-pages/Textbooks/utils/hooks/useTextbookTabs';
import { HStack } from 'ms-ui-primitives/Stack';
import { useCanAccessNotes } from 'ms-utils/hooks/useCanAccessToolbox';

import NoLanguageAvailable from './NoLanguageAvailable';
import type {
  LessonQuery,
  SyllabusLocale,
} from './__generated__/LessonQuery.graphql';
import {
  ContentTitle,
  ContentHeading,
  TheoryContentWrapper,
} from '../elements';

const ChapterContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const RightAligned = styled.div({
  marginLeft: 'auto',
});

// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_positioned_layout/Understanding_z-index/Stacking_context
const JsxContentIsolatingContextWrapper = styled.div({
  isolation: 'isolate',
});

export const SubtopicPkContext = createContext<number | undefined>(undefined);
type Props = {
  subtopicId: string;
  nodeRenderers?: Partial<NodeRendererMap>;
  showNotesModalLauncherInJsxContent?: boolean;
  onLoad: () => void;
};
export default function LessonContainer({ subtopicId, ...otherProps }: Props) {
  const [locale] = useLanguageSelectorContext();
  const { error, props } = useQuery<LessonQuery>(
    graphql`
      query LessonQuery($subtopicId: ID!, $locale: SyllabusLocale!) {
        subtopic(id: $subtopicId, syllabusLocale: $locale) {
          id
          title
          hasLessons
          hasLessonsTeacherNotes
          rslJsx {
            id
            pdfAvailable
            pdfUrl
            transpiledJsx
            locale
          }
          rslTeacherNoteJsx {
            id
            transpiledJsx
            locale
            pdfAvailable
            pdfUrl
          }
          investigationJsx {
            id
            transpiledJsx
            locale
            pdfAvailable
            pdfUrl
          }
          investigationTeacherNoteJsx {
            id
            transpiledJsx
            locale
            pdfAvailable
            pdfUrl
          }
          solidifyLessonJsx {
            id
            locale
            pdfAvailable
            transpiledJsx
            pdfUrl
          }
          solidifyTeacherNoteJsx {
            transpiledJsx
            pdfAvailable
            pdfUrl
            locale
          }
          teacherNoteJsx {
            id
            transpiledJsx
            pdfAvailable
            pdfUrl
            locale
          }
          hasInvestigations
          pk
          chapters(first: 1000) {
            edges {
              node {
                id
                content
                chapterType
                title
              }
            }
          }
        }
      }
    `,
    useMemo(() => ({ subtopicId, locale }), [subtopicId, locale]),
    {
      networkCacheConfig: {
        // I don't trust the relay types we're using for v9, so I'm leaving this until
        // we upgrade properly to a modern version of relay.
        // @ts-expect-error
        useCache: true,
        ttl: Infinity,
      },
    },
  );
  if (error != null)
    return (
      <PageTimeErrorThrower
        componentName="Lesson"
        pageName="Textbooks"
        error={error}
      />
    );
  if (props == null) return <MinorSpinner />;
  const { subtopic } = props;
  if (subtopic == null) return <>'subtopic not found'</>;

  return (
    <LessonInner subtopic={subtopic} subtopicId={subtopic.id} {...otherProps} />
  );
}

type Subtopic = NonNullable<LessonQuery['response']['subtopic']>;

function LessonInner({
  subtopic,
  subtopicId,
  nodeRenderers,
  showNotesModalLauncherInJsxContent,
  onLoad,
}: Props & {
  subtopic: Subtopic;
}) {
  useEffect(onLoad, [onLoad, subtopicId]);

  const {
    rslJsx,
    hasLessons,
    hasLessonsTeacherNotes,
    rslTeacherNoteJsx,
    investigationJsx,
    investigationTeacherNoteJsx,
    solidifyLessonJsx,
    solidifyTeacherNoteJsx,
    teacherNoteJsx,
  } = subtopic;
  const filteredChapters = subtopic.chapters.edges
    .map(edge => edge.node)
    .filter(c => ['LESSON', 'INVESTIGATION'].includes(c.chapterType));

  const canAccessNotes = useCanAccessNotes();

  const { documentJsx, teacherNoteDocumentJsx } = getLessonDocuments({
    rslJsx,
    investigationJsx,
    rslTeacherNoteJsx,
    investigationTeacherNoteJsx,
    solidifyLessonJsx,
    solidifyTeacherNoteJsx,
    teacherNoteJsx,
  });

  const {
    role,
    featureFlags: {
      // rename it to make it more explicit
      primaryTeacherNotes: canAccessPrimaryTeacherNotes,
    },
  } = useMaybeViewer() || {
    role: 'Other',
    featureFlags: {
      primaryTeacherNotes: false,
    },
  };

  const shouldRenderNotes =
    canAccessPrimaryTeacherNotes &&
    hasLessonsTeacherNotes &&
    role === 'Teacher';
  const shouldRenderContent = hasLessons;

  const [lessonTab] = useSubtopicLessonTabs({
    content: { shouldRender: shouldRenderContent },
    teacherGuide: {
      shouldRender: shouldRenderNotes,
    },
  });

  return (
    <PageTimeRecorder componentName="Lesson" pageName="Textbooks">
      {role === 'Student' && documentJsx != null && (
        <NoLanguageAvailable locale={documentJsx.locale} />
      )}

      {role === 'Student' &&
        canAccessNotes &&
        showNotesModalLauncherInJsxContent &&
        documentJsx != null && (
          <HStack center>
            <RightAligned>
              <NotesModalLauncher
                subtopicId={subtopicId}
                jsxContentType={
                  rslJsx != null
                    ? 'RETROFITTED_STUDENT_LESSON'
                    : investigationJsx != null
                    ? 'INVESTIGATION'
                    : 'SOLIDIFY_LESSON'
                }
                locale={documentJsx.locale}
              />
            </RightAligned>
          </HStack>
        )}

      <>
        <ContentHeading>
          <ContentTitle>Lesson</ContentTitle>
        </ContentHeading>

        {(lessonTab === 'content' || role === 'Other') &&
          (documentJsx != null ? (
            <JsxContentIsolatingContextWrapper>
              <JsxContent transpiledJsx={documentJsx.transpiledJsx} />
            </JsxContentIsolatingContextWrapper>
          ) : (
            <TheoryContentWrapper>
              {filteredChapters.length === 0 ? (
                <CustomEmptyMessage label="There is no lesson content for this subtopic" />
              ) : (
                filteredChapters.map(c => (
                  <ChapterContainer key={c.id}>
                    <SubtopicPkContext.Provider value={subtopic.pk}>
                      {c.chapterType === 'INVESTIGATION' ? (
                        <InvestigationChapter
                          chapterContent={c.content}
                          nodeRenderers={nodeRenderers}
                        />
                      ) : (
                        <MathContent
                          content={c.content}
                          nodeRenderers={{
                            CHAPTER_LINK: ChapterLink,
                            ...nodeRenderers,
                          }}
                        />
                      )}
                    </SubtopicPkContext.Provider>
                  </ChapterContainer>
                ))
              )}
            </TheoryContentWrapper>
          ))}
        {teacherNoteDocumentJsx != null &&
          lessonTab === 'teacherGuide' &&
          role === 'Teacher' && (
            <JsxContent transpiledJsx={teacherNoteDocumentJsx.transpiledJsx} />
          )}
      </>
    </PageTimeRecorder>
  );
}

type JsxDocument = {
  id?: string | null | undefined;
  transpiledJsx?: string | null | undefined;
  pdfAvailable?: boolean | null | undefined;
  pdfUrl?: string | null | undefined;
  locale?: SyllabusLocale | null | undefined;
};

export function getLessonDocuments({
  rslJsx,
  investigationJsx,
  rslTeacherNoteJsx,
  investigationTeacherNoteJsx,
  solidifyLessonJsx,
  solidifyTeacherNoteJsx,
  teacherNoteJsx,
}: {
  rslJsx: JsxDocument;
  investigationJsx: JsxDocument;
  rslTeacherNoteJsx: JsxDocument;
  investigationTeacherNoteJsx: JsxDocument;
  solidifyLessonJsx: JsxDocument;
  solidifyTeacherNoteJsx: JsxDocument;
  teacherNoteJsx: JsxDocument;
}) {
  const documentJsx =
    rslJsx.transpiledJsx != null
      ? rslJsx
      : investigationJsx.transpiledJsx != null
      ? investigationJsx
      : solidifyLessonJsx.transpiledJsx != null
      ? solidifyLessonJsx
      : null;
  const teacherNoteDocumentJsx =
    rslTeacherNoteJsx.transpiledJsx != null
      ? rslTeacherNoteJsx
      : investigationTeacherNoteJsx.transpiledJsx != null
      ? investigationTeacherNoteJsx
      : solidifyTeacherNoteJsx.transpiledJsx != null
      ? solidifyTeacherNoteJsx
      : teacherNoteJsx.transpiledJsx != null
      ? teacherNoteJsx
      : null;
  const lessonPdfAvailable = documentJsx?.pdfAvailable ?? false;
  const lessonPdfUrl =
    lessonPdfAvailable &&
    documentJsx?.pdfUrl != null &&
    documentJsx?.pdfUrl !== ''
      ? documentJsx.pdfUrl
      : null;
  const teacherNotePdfAvailable = teacherNoteDocumentJsx?.pdfAvailable ?? false;
  const teacherNotePdfUrl =
    teacherNotePdfAvailable &&
    teacherNoteDocumentJsx?.pdfUrl != null &&
    teacherNoteDocumentJsx?.pdfUrl !== ''
      ? teacherNoteDocumentJsx.pdfUrl
      : null;

  return {
    documentJsx,
    teacherNoteDocumentJsx,
    lessonPdfUrl,
    teacherNotePdfUrl,
  };
}
