/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandProficiencyIndicator_substrand = {
    readonly id: string;
    readonly badgeUrl: string;
    readonly gradeSubstrands: ReadonlyArray<{
        readonly id: string;
        readonly gradeStrand: {
            readonly grade: {
                readonly id: string;
                readonly order: number;
            };
        };
    }>;
    readonly " $refType": "StudentSkillsMapSubstrandProficiencyIndicator_substrand";
};
export type StudentSkillsMapSubstrandProficiencyIndicator_substrand$data = StudentSkillsMapSubstrandProficiencyIndicator_substrand;
export type StudentSkillsMapSubstrandProficiencyIndicator_substrand$key = {
    readonly " $data"?: StudentSkillsMapSubstrandProficiencyIndicator_substrand$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandProficiencyIndicator_substrand">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandProficiencyIndicator_substrand",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "badgeUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GradeSubstrand",
      "kind": "LinkedField",
      "name": "gradeSubstrands",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GradeStrand",
          "kind": "LinkedField",
          "name": "gradeStrand",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LanternGrade",
              "kind": "LinkedField",
              "name": "grade",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "order",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Substrand",
  "abstractKey": null
};
})();
(node as any).hash = 'bd376bf4b69a91912312ab139440da4e';
export default node;
