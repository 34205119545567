import { max, update } from 'ramda';
import type {
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
} from 'react';

import { roundTo } from 'ms-utils/math/number';
import { unwrap } from 'ms-utils/typescript-utils';

import type { CategoryData, IncrementData, SpacingData } from '.';

/**
 * Gets the screen position for objects sitting on the X axis of the graph.
 * Used by DragHandles, Categories, Edges and Bins
 * @param {number} position - the position of the object in the categories array
 * @param {number} numColumns - how many columns fit on the axis
 * @param {number} contentWidth - available space on the X axis
 * @return {number} calculated X screen position for an object
 */
export function getXScreenValue(
  position: number,
  numColumns: number,
  contentWidth: number,
): number {
  const columnWidth = Math.floor(contentWidth / numColumns);
  return position * (columnWidth - 1) + Math.round(columnWidth / 2);
}

/**
 * Gets the screen position for increments sitting on the Y axis of the graph
 * @param {number} increment - increment data for for the histogram.
 * @param {number} spacing - the calculated spacing data for the graph.
 * @return {number} calculated Y screen position for an object
 */
export function getYScreenValue(
  value: number,
  increment: IncrementData,
  spacing: SpacingData,
): number {
  return Math.round(
    ((increment.max - value) / (increment.max - increment.min)) *
      (spacing.maxBound - spacing.minBound),
  );
}

/**
 * Given a new value and an array position, it will update a category with a new value.
 * @param {CategoryData} categories - List of all categories.
 * @param {number} categoryNumber - Position of target category to update.
 * @param {number} newValue - New value to assign to target category.
 * @return {CategoryData} an array of categories in deserialized form.
 */
export function updateValue(
  categories: CategoryData,
  categoryNumber: number,
  newValue: number,
): CategoryData {
  const result = [...categories];
  if (categoryNumber < 0 || categoryNumber > categories.length - 1)
    return result;
  const target = unwrap(result[categoryNumber]);
  return update(
    categoryNumber,
    {
      title: target.title,
      value: newValue,
    },
    result,
  );
}

/**
 *
 * @param {number} screenCoord
 * @param {number} incrementSpace
 * @param {number} incrementMax
 * @return {number}
 */
export function fromScreen(
  screenCoord: number,
  incrementSpace: number,
  incrementMax: number,
) {
  return incrementMax - Math.round(screenCoord / incrementSpace);
}

/**
 * Returns the new value of a category when a user moves a bin
 * @param {SyntheticEvent<*>} event - event object passed in from mouse down event
 * @param {SpacingData} SpacingData - Spacing data for the histogram
 * @param {IncrementData} increment - Increment data passed into histogram
 * @return {number} new value of moved category
 */
export function getNewCategoryValue(
  event: SyntheticMouseEvent<any> | SyntheticTouchEvent<any>,
  spacing: SpacingData,
  increment: IncrementData,
  currentValue: number,
  y0: number,
) {
  const dy = getClientY(event) - y0;
  const valueChange = -dy / spacing.incrementHeight;
  return roundTo(currentValue + valueChange, increment.step);
}

/**
 * Calculates the width of the bins for a histogram graph
 * @param {SpacingData} spacing - Spacing data for the histogram
 * @param {CategoryData} categoires - Category data that contains values and titles
 * @param {boolean} hasGapBetweenBars - Determines if bins will have space between them
 * @return {number} the width of all the bins on a histogram graph
 */
export function calculateBinWidth(
  spacing: SpacingData,
  categories: CategoryData,
  hasGapBetweenBars: boolean,
): number {
  const categoriesLength = hasGapBetweenBars
    ? categories.length
    : categories.length + 1;
  // widthModifier reduces the width of the bins to allow gaps between the bins
  const widthModifier = hasGapBetweenBars ? 0.75 : 1;
  const binWidth = Math.floor(
    (spacing.availableSpace / categoriesLength) * widthModifier,
  );
  return max(5, binWidth); // don't let bins get smaller than 5px
}

export function getClientY(
  event: SyntheticMouseEvent<any> | SyntheticTouchEvent<any>,
) {
  const { nativeEvent } = event;
  if (nativeEvent instanceof MouseEvent) {
    return nativeEvent.clientY;
  } else {
    const touch = nativeEvent.changedTouches[0];
    if (touch === undefined)
      throw Error(`Couldn't get clientY for touch event`);
    return touch.clientY;
  }
}
