import { StyleSheet, css } from 'aphrodite';

import { color } from 'ms-styles/theme/Bootstrap';

import Rect from '../Rect';
import type { CategoryData, IncrementData, SpacingData } from '../index';
import { getXScreenValue, getYScreenValue, calculateBinWidth } from '../utils';

type Props = {
  categories: CategoryData;
  increment: IncrementData;
  readOnly?: boolean | undefined;
  spacing: SpacingData;
  hasGapBetweenBars: boolean;
};

const Bins = ({
  categories,
  increment,
  readOnly,
  spacing,
  hasGapBetweenBars,
}: Props) => {
  const binWidth = calculateBinWidth(spacing, categories, hasGapBetweenBars);
  const contentWidth = spacing.maxBound - spacing.minBound;

  const styles = StyleSheet.create({
    bin: {
      fill: readOnly ? 'hsl(0, 0%, 85%)' : color.interactive,
    },
  });

  return (
    <g transform={`translate(${0}, ${spacing.minBound})`}>
      {categories.map((category, index) => {
        const categoryLength = hasGapBetweenBars
          ? categories.length
          : categories.length + 1;
        const xScreenValue = getXScreenValue(
          index,
          categoryLength,
          contentWidth,
        );
        const binHeight =
          spacing.maxBound -
          spacing.minBound -
          getYScreenValue(category.value, increment, spacing) +
          1;
        const yScreenValue = spacing.availableSpace - binHeight;
        return (
          <Rect
            key={`bin_${index}`}
            className={css(styles.bin)}
            height={binHeight}
            width={binWidth}
            stroke="#333"
            strokeWidth={1}
            x={xScreenValue - Math.round(binWidth / 2)}
            y={yScreenValue}
          />
        );
      })}
    </g>
  );
};

export default Bins;
