import moment from 'moment';
import { last } from 'ramda';

/**
 * Segments total time spent into days, hours, minutes and seconds
 *
 * @param {number} duration - Total duration in seconds
 * @param {string} format - The format in which duration should be displayed
 * Send through a string that includes 'h' (hours), 'm' (minutes) and/or 's' (seconds)
 * The accepted formats are: ['h m s', 'm s', 'h m', 'h', 'm', 's', 'h min', 'h min sec']
 * @return {string} The formatted duration
 * @example formatDuration(213, 'h m s'); -> '0h 3m 33s'. See ./test.jsx for further examples
 */

type AcceptedFormat =
  | 'h m s'
  | 'm s'
  | 'h m'
  | 'h'
  | 'm'
  | 's'
  | 'h min'
  | 'h min sec';

export function formatDuration(
  duration: number,
  _format: AcceptedFormat,
): string {
  const format = _format.split(' ');
  const mostGranularUnit = last(format);
  const hours = format.includes('h') ? Math.floor(duration / 3600) : 0;
  const minutes =
    format.includes('m') || format.includes('min')
      ? Math.floor(duration / 60 - hours * 60)
      : 0;
  const seconds =
    format.includes('s') || format.includes('sec')
      ? Math.floor(duration - hours * 3600 - minutes * 60)
      : 0;

  if (hours === 0 && minutes === 0 && seconds === 0 && mostGranularUnit != null)
    return `0${mostGranularUnit}`;

  const h = hours > 0 ? `${hours}h` : null;
  const m =
    (hours > 0 && seconds > 0) || minutes > 0
      ? `${minutes}${format.includes('min') ? 'min' : 'm'}`
      : null;
  const s =
    seconds > 0 ? `${seconds}${format.includes('sec') ? 'sec' : 's'}` : null;

  return [h, m, s].filter(value => value != null).join(' ');
}

/**
 * Formats the date for display to users
 *
 * @param {string} date - Date string
 * @return {string} The formatted date
 * @example formatDate('2018-04-18T07:00:43.767080+00:00'); -> 'Apr 18 2018 5:00pm'. See ./test.jsx for further examples
 */
export function formatDate(date: string): string {
  return moment(date).format('D MMM YYYY h:mma');
}

/**
 * Formats the timestamp to a day, or if recent enough, today and yesterday
 *
 * @param {string} timestamp Date string
 * @return {string} The formatted date
 * @example getDayTitle('2018-04-18T07:00:43.767080+00:00'); -> '18 Apr 2018'. See ./test.jsx for further examples
 */
export const getDayTitle = (timestamp: string): string => {
  const m = moment(timestamp);
  if (m.isSame(moment(), 'day')) return 'Today';
  if (m.isSame(moment().subtract({ days: 1 }), 'day')) return 'Yesterday';
  return m.format('D MMM YYYY');
};

/**
 * Returns a timestamp from now using moment. The returned timestamp is also abbreviated
 * @param {string} timestamp Date string
 * @param {moment.MomentInput} now The current time
 * @param {boolean} showSeconds Whether to show number of seconds if < 1 minute or not. Defaults to false.
 * @return {string} The relative time of that timestamp from today
 */
export const getRelativeTime = (
  timestamp: string,
  now: moment.MomentInput = new Date(),
  showSeconds: boolean = false,
  withoutSuffix: boolean = false,
): string => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s(number) {
        if (number === 0) return 'now';
        return showSeconds ? `${number}s` : '<1min';
      },
      m: '1min',
      mm: '%dmin',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
  });

  return moment(timestamp).from(now, withoutSuffix);
};
