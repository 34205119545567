import { css, StyleSheet, type CSSInputTypes } from 'aphrodite';
import type { ReactNode } from 'react';
import { useEffect, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { zIndex } from 'ms-styles/base';
import { usePrevious } from 'ms-utils/hooks/usePrevious';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  children?: ReactNode;
  // Some of the components that use Portal require scroll prevention
  // when open (Modal, Flyout, MainMenu). It is also possible for a few of them
  // to be open at the same time (e.g. Modal inside Flyout or nested Modals).
  // Therefore we should be careful not to remove scroll prevention until
  // all of the components that require scroll prevent unmount - we keep track
  // of these by adding `portalWithScrollPreventionClass` to their corresponding
  // nodes and checking if any of them is present in the DOM.
  // TODO: a better solution is needed for handling the scroll prevention logic,
  // possibly the one that:
  // * isolates the scroll prevention logic from Portal
  // * is represented by a single boolean in the app state
  // * avoids using DOM for checks
  hasScrollPrevention?: boolean;
  portalStyles?: CSSInputTypes[];
};

const portalWithScrollPreventionClass =
  'mathspace-portal-with-scroll-prevention-f2e1bc';

const removeAllClasses = (node: HTMLElement) => {
  // IE11 doesn't support the variadic version of .remove() so we have to
  // manually call it once for each class in the list.
  Array.from(node.classList).forEach(c => {
    node.classList.remove(c);
  });
};

// Sets the classes on node to be exactly equal to the provided class values.
// This makes it easy for us to have our class list be a pure function of the
// passed classes, which is important for interacting nicely with React.
const setClassList = (node: HTMLElement, ...classValues: Array<string>) => {
  removeAllClasses(node);
  // IE11 doesn't support the variadic version of .add() so we have to
  // manually call it once for each class in the list.
  classValues.forEach(c => {
    node.classList.add(c);
  });
};

const styles = StyleSheet.create({
  portal: {
    position: 'absolute',
    zIndex: zIndex.portal,
  },
  preventScroll: {
    overflow: 'hidden',
  },
});

const Portal = ({
  isOpen,
  onClose,
  children,
  hasScrollPrevention = false,
  portalStyles = [],
}: Props) => {
  const [portalNode, setPortalNode] = useState<HTMLDivElement | null>(null);
  const rootScrollingElement = useRef<HTMLElement | null>(null);
  const prevIsOpen = usePrevious(isOpen);

  const updatePortalNode = useCallback(() => {
    if (!portalNode) return;
    setClassList(
      portalNode,
      ...(hasScrollPrevention ? [portalWithScrollPreventionClass] : []),
      css(styles.portal, ...portalStyles),
    );
    if (hasScrollPrevention && rootScrollingElement.current != null) {
      rootScrollingElement.current.classList.add(css(styles.preventScroll));
    }
  }, [hasScrollPrevention, portalNode, portalStyles]);

  const addPortalNode = useCallback(() => {
    const node = document.createElement('div');
    if (document.body) document.body.appendChild(node);
    setPortalNode(node);
    updatePortalNode();
  }, [updatePortalNode]);

  const removePortalNode = useCallback(() => {
    // We have triggers for removal of portalNode from the DOM on two places
    // in this component to make sure the DOM is cleaned up properly.
    // Depending on the usage of Portal, only one or both of these will be triggered.
    // The reasons for this are:
    // 1. We remove the Portal component in JSX, component is unmounted entirely
    // and only cleanup useEffect runs, component doesn't receive isOpen prop change to false ever.
    // 2. Component is unmounted but there's an animation/delay going on (mainly Flyouts) in closing,
    // so it's not removed from the DOM entirely yet, cleanup useEffect runs first,
    // when isOpen prop changes to false, removePortalNode is called again.
    // As a result of this, we need to check if the portalNode is still in the DOM
    // before attempting to remove it.
    if (document.body && portalNode && document.body.contains(portalNode)) {
      document.body.removeChild(portalNode);
    }
    setPortalNode(null);
    if (
      hasScrollPrevention &&
      document.querySelectorAll(`.${portalWithScrollPreventionClass}`)
        .length === 0 &&
      rootScrollingElement.current != null
    ) {
      rootScrollingElement.current.classList.remove(css(styles.preventScroll));
    }
    onClose?.();
  }, [hasScrollPrevention, onClose, portalNode]);

  useEffect(() => {
    if (!document.documentElement) return; // flow refinement

    // Legacy Mathspace pages force scrolling on the <html> element instead of the <body>
    const isLegacyPage =
      getComputedStyle(document.documentElement).overflowY === 'auto';

    if (!document.documentElement || !document.body) return; // flow refinement

    rootScrollingElement.current = isLegacyPage
      ? document.documentElement
      : document.body;
  }, []);

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      addPortalNode();
    } else if (prevIsOpen && !isOpen) {
      removePortalNode();
    } else {
      updatePortalNode();
    }
  }, [
    addPortalNode,
    isOpen,
    portalNode,
    prevIsOpen,
    removePortalNode,
    updatePortalNode,
  ]);

  useEffect(() => {
    return () => {
      if (isOpen && portalNode) {
        removePortalNode();
      }
    };
  }, [isOpen, portalNode, removePortalNode]);

  return isOpen && portalNode && children != null
    ? ReactDOM.createPortal(children, portalNode)
    : null;
};

export default Portal;
