import { memo } from 'react';

const MasteredMilo = memo(({ index }: { index: number }) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        height: 90,
        width: 83,
      }}
    >
      {miloVersions[index]}
    </div>
  );
});

export default MasteredMilo;

const miloVersions = [
  <svg
    width="83"
    height="90"
    viewBox="0 0 83 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <g clipPath="url(#clip0_2076_31246)">
      <path
        d="M9.2418 71.1182C10.4719 71.0231 11.5243 72.032 11.5999 73.3684C11.6037 73.4668 11.716 75.0865 12.2299 77.2022C12.4869 78.26 12.8466 79.4432 13.3294 80.6203C13.8121 81.7975 14.4254 82.9664 15.186 84.0139C15.8922 84.9866 16.7224 85.8578 17.705 86.5628C18.7274 87.2993 18.9992 88.7984 18.3104 89.9149C17.6216 91.0313 16.2358 91.3455 15.2134 90.609C13.7887 89.5889 12.623 88.3452 11.6653 87.0314C10.6063 85.5657 9.8058 84.0064 9.19095 82.5005C8.27145 80.2364 7.76747 78.0789 7.48796 76.4653C7.20846 74.8516 7.14594 73.784 7.13855 73.7039C7.07519 72.3683 8.01168 71.2132 9.2418 71.1182Z"
        fill="#C17F50"
      />
      <path
        d="M9.54099 77.5857C11.9047 77.5672 13.8032 75.4898 13.7815 72.9455C13.7598 70.4013 11.8261 68.3538 9.46244 68.3723C7.09876 68.3907 5.2002 70.4682 5.22189 73.0124C5.24358 75.5566 7.17731 77.6041 9.54099 77.5857Z"
        fill="#C17F50"
      />
      <path
        d="M29.3971 41.0748C28.6971 40.422 25.5832 36.8259 20.1351 37.0785C14.6803 37.3344 8.10092 48.3515 9.7706 55.9693C11.4433 63.5944 23.5641 48.9836 24.9147 47.3443C26.2653 45.7049 30.522 42.1213 29.3971 41.0748Z"
        fill="#C17F50"
      />
      <path
        d="M59.809 44.4591C53.5244 37.7041 28.7612 37.1362 22.3057 44.9619C20.9606 46.5945 18.8492 48.8363 17.7743 52.1606C16.3961 56.4195 15.6574 68.6816 15.944 68.7348H15.933C15.7511 79.7842 16.1811 116.079 22.6861 122.698C28.9541 129.081 55.068 129.998 63.0064 122.976C73.6405 113.571 64.9413 49.978 59.809 44.4591Z"
        fill="#E3A263"
      />
      <path
        d="M56.0975 43.4622C56.764 42.728 59.6647 38.7559 65.2745 38.5161C70.8843 38.2762 78.5206 48.9574 77.4148 56.915C76.309 64.8727 62.7143 51.0201 61.1974 49.469C59.6805 47.9179 58.0117 51.3079 54.6947 49.4384C51.3788 47.5631 55.0256 44.6369 56.0975 43.4622Z"
        fill="#C17F50"
      />
      <path
        d="M46.3746 51.6658C46.4021 53.8456 45.1313 55.6338 43.5414 55.6571C41.9515 55.6805 40.6422 53.9274 40.6147 51.7477C40.5872 49.5679 41.858 47.7797 43.4479 47.7564C45.0378 47.733 46.3471 49.4861 46.3746 51.6658Z"
        fill="#3B1C0C"
      />
      <path
        d="M29.092 50.2973C29.1137 52.2893 27.9891 53.916 26.5726 53.9395C25.1562 53.9572 23.9938 52.36 23.9722 50.368C23.9451 48.3759 25.0751 46.7434 26.4916 46.7257C27.9026 46.708 29.0704 48.3052 29.092 50.2973Z"
        fill="#3B1C0C"
      />
      <path
        d="M39.2293 55.379L19.5739 60.4773C19.5739 60.4773 29.77 80.7583 39.6878 80.0845C49.6113 79.4166 39.2293 55.379 39.2293 55.379Z"
        fill="#C17F50"
      />
      <path
        d="M43.4755 69.3031C44.4334 73.5939 44.7618 79.978 40.2248 80.3866C36.9173 80.685 31.679 77.668 29.8991 75.2554C33.1583 71.0984 38.3768 69.1497 43.4755 69.3031Z"
        fill="#F3725F"
      />
      <path
        d="M20.1812 64.12C20.4291 64.2542 20.6877 64.3916 20.9546 64.5193L20.9439 64.5161C20.9424 64.5218 20.9395 64.533 20.9381 64.5387C29.3693 66.9017 37.8908 61.9872 40.0739 53.5026L40.1427 53.2354C40.1427 53.2354 15.1478 46.363 13.5294 53.2354C13.1043 55.0218 12.8841 60.111 20.1812 64.12Z"
        fill="#E3A263"
      />
      <path
        d="M29.9719 57.7177C29.4619 59.811 25.2188 62.901 21.205 61.7663C17.1859 60.6299 14.9133 55.7063 15.4233 53.613C15.9333 51.5197 19.5448 50.9959 23.5638 52.1322C27.5829 53.2685 30.4873 55.6259 29.9719 57.7177Z"
        fill="#3B1C0C"
      />
      <path
        d="M54.7516 70.7076C55.9817 70.6126 57.0341 71.6215 57.1097 72.9579C57.1135 73.0562 57.2258 74.676 57.7397 76.7916C57.9966 77.8494 58.3564 79.0327 58.8391 80.2098C59.3219 81.387 59.9352 82.5559 60.6957 83.6034C61.402 84.5761 62.2322 85.4473 63.2148 86.1523C64.2372 86.8888 64.509 88.3879 63.8202 89.5044C63.1314 90.6208 61.7456 90.935 60.7232 90.1985C59.2985 89.1784 58.1328 87.9347 57.175 86.6209C56.1161 85.1552 55.3156 83.5959 54.7007 82.09C53.7812 79.8259 53.2772 77.6684 52.9977 76.0548C52.7182 74.4411 52.6557 73.3735 52.6483 73.2934C52.585 71.9578 53.5214 70.8027 54.7516 70.7076Z"
        fill="#C17F50"
      />
      <path
        d="M55.0508 77.1752C57.4144 77.1567 59.313 75.0793 59.2913 72.535C59.2696 69.9908 57.3359 67.9433 54.9722 67.9618C52.6085 67.9802 50.71 70.0577 50.7317 72.6019C50.7534 75.1461 52.6871 77.1936 55.0508 77.1752Z"
        fill="#C17F50"
      />
    </g>
    <defs>
      <clipPath id="clip0_2076_31246">
        <rect width="83" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg
    width="83"
    height="90"
    viewBox="0 0 83 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={1}
  >
    <g clipPath="url(#clip0_2076_31488)">
      <path
        d="M12.7207 41.1247C13.9446 41.1502 14.8968 42.1844 14.8553 43.4323C14.8505 43.524 14.8206 45.0384 15.1444 47.0504C15.3063 48.0564 15.5589 49.1883 15.9335 50.326C16.3082 51.4637 16.8126 52.6059 17.473 53.6489C18.0863 54.6174 18.8309 55.5032 19.7406 56.2487C20.6868 57.0272 20.825 58.4443 20.0473 59.4173C19.2695 60.3902 17.8729 60.5536 16.9266 59.7751C15.6075 58.6961 14.5638 57.4334 13.7316 56.1248C12.8126 54.6659 12.1572 53.144 11.6806 51.6888C10.9689 49.5014 10.6585 47.4515 10.5227 45.9273C10.3868 44.4031 10.4179 43.406 10.4175 43.331C10.4711 42.0849 11.4969 41.0991 12.7207 41.1247Z"
        fill="#C17F50"
      />
      <path
        d="M10.7096 45.4792C13.0469 45.6808 15.1036 43.9276 15.3034 41.5632C15.5033 39.1988 13.7705 37.1187 11.4332 36.917C9.09592 36.7154 7.03918 38.4687 6.83935 40.833C6.63952 43.1974 8.37228 45.2776 10.7096 45.4792Z"
        fill="#C17F50"
      />
      <path
        d="M37.8634 36.5921C37.3499 35.8243 35.2327 31.785 29.9489 30.6131C24.6577 29.4424 15.6911 37.6767 15.4531 44.9678C15.2162 52.2663 30.3626 42.2182 32.0531 41.0884C33.7435 39.9586 38.6893 37.8238 37.8634 36.5921Z"
        fill="#C17F50"
      />
      <path
        d="M66.2019 47.4529C61.8077 39.7535 38.2051 32.8809 30.1276 38.2722C28.444 39.3973 25.8788 40.8744 24.0458 43.5929C21.6966 47.0753 18.0287 57.9314 18.2907 58.053L18.2801 58.0502C15.4387 67.9569 7.09025 100.763 11.7288 108.396C16.1971 115.755 41.0103 123.289 50.3153 119.004C62.7799 113.263 69.7901 53.7427 66.2019 47.4529Z"
        fill="#E3A263"
      />
      <path
        d="M76.0947 69.8279C75.057 68.6846 73.2703 68.5658 72.1 69.5536C72.0154 69.6279 70.5782 70.8072 68.3677 72.0286C67.2625 72.6393 65.9607 73.2596 64.5522 73.7682C63.1437 74.2769 61.6236 74.6657 60.0699 74.8308C58.6272 74.9842 57.1558 74.9499 55.683 74.6522C54.1482 74.3448 52.6657 75.2905 52.3702 76.7689C52.0747 78.2472 53.0731 79.6985 54.6079 80.0059C56.7415 80.4391 58.8245 80.4712 60.7767 80.2674C62.9458 80.0318 64.9576 79.4979 66.757 78.8447C69.4578 77.8586 71.6938 76.5956 73.2777 75.5663C74.8616 74.537 75.7985 73.7497 75.8712 73.693C77.0299 72.6952 77.1324 70.9713 76.0947 69.8279Z"
        fill="#C17F50"
      />
      <path
        d="M70.2048 74.3365C68.143 72.2381 68.1783 68.856 70.2838 66.7825C72.3892 64.7089 75.7674 64.729 77.8292 66.8275C79.8911 68.9259 79.8557 72.308 77.7503 74.3816C75.6448 76.4551 72.2666 76.435 70.2048 74.3365Z"
        fill="#C17F50"
      />
      <path
        d="M67.7177 63.4455C65.1334 63.3819 68.8271 67.5328 69.5737 70.2709L74.6691 68.2201C73.3345 66.4576 70.3021 63.509 67.7177 63.4455Z"
        fill="#C17F50"
      />
      <path
        d="M62.8846 45.6015C63.7007 45.1113 67.4403 42.2783 72.8761 43.5033C78.3119 44.7283 83.0543 56.3115 80.0735 63.1958C77.0926 70.0802 67.4035 54.1094 66.3237 52.3225C65.2439 50.5356 62.8258 53.1607 60.0972 50.6246C57.371 48.0835 61.5734 46.3843 62.8846 45.6015Z"
        fill="#C17F50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4036 39.7601C32.7943 39.5637 33.2703 39.7211 33.4667 40.1118L35.4105 43.9768C35.5428 44.24 35.5181 44.5548 35.3464 44.7941C35.1748 45.0334 34.8844 45.1577 34.5928 45.1167L29.6658 44.4237C29.2328 44.3628 28.9311 43.9624 28.992 43.5293C29.0529 43.0963 29.4534 42.7946 29.8864 42.8555L33.3165 43.338L32.0519 40.8233C31.8555 40.4326 32.0129 39.9566 32.4036 39.7601Z"
        fill="#3B1C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.0217 45.9326C51.921 45.5071 51.4943 45.2437 51.0688 45.3444L45.1632 46.7421C44.8765 46.81 44.6513 47.0313 44.5783 47.3166C44.5054 47.602 44.5969 47.9043 44.8159 48.1013L50.0041 52.7697C50.3292 53.0622 50.8299 53.0358 51.1224 52.7107C51.4149 52.3857 51.3885 51.885 51.0634 51.5925L46.9988 47.9351L51.4335 46.8855C51.8591 46.7848 52.1224 46.3582 52.0217 45.9326Z"
        fill="#3B1C0C"
      />
      <path
        d="M42.6665 53.1777L24.0992 45.535C24.0992 45.535 21.1424 66.7811 29.4686 72.0687C37.796 77.3642 42.6665 53.1777 42.6665 53.1777Z"
        fill="#C17F50"
      />
      <path
        d="M38.428 66.1553C36.8404 69.9495 33.5983 74.9538 29.7343 72.6106C26.9171 70.9026 24.3716 65.5674 24.2685 62.7087C29.1656 61.4797 34.422 63.0603 38.428 66.1553Z"
        fill="#F3725F"
      />
      <path
        d="M23.4684 54.9846C23.6736 55.1692 23.8884 55.3594 24.1134 55.5429L24.1039 55.5373C24.1011 55.542 24.0956 55.5515 24.0929 55.5562L24.5726 55.8304C31.8173 59.9717 41.0197 57.5115 45.2319 50.3072C45.2319 50.3072 22.9291 37.6959 19.7187 43.4709C18.88 44.971 17.4405 49.4988 23.4684 54.9846Z"
        fill="#E3A263"
      />
      <path
        d="M34.4001 51.7323C33.4059 53.487 28.5923 55.1806 25.0183 53.1273C21.4396 51.0713 20.4494 46.0523 21.4436 44.2976C22.4378 42.5429 26.0264 42.9987 29.6051 45.0548C33.1837 47.1108 35.399 49.9804 34.4001 51.7323Z"
        fill="#3B1C0C"
      />
      <path
        d="M28.9832 46.5138C28.7198 46.9756 27.6483 46.853 26.5791 46.2405C25.5148 45.6308 24.8612 44.7597 25.1246 44.2979C25.3879 43.8361 26.4595 43.9587 27.5286 44.5712C28.5978 45.1838 29.2466 46.052 28.9832 46.5138Z"
        fill="#F7F5F1"
      />
    </g>
    <defs>
      <clipPath id="clip0_2076_31488">
        <rect width="83" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg
    width="83"
    height="90"
    viewBox="0 0 83 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={2}
  >
    <g clipPath="url(#clip0_2076_31875)">
      <path
        d="M20.6709 31.9752C18.0841 37.7437 16.461 58.6922 28.0816 63.7578"
        stroke="#C17F50"
        strokeWidth="4"
      />
      <path
        d="M39.373 39.145C38.6834 38.5144 35.6305 35.0584 30.1472 35.1638C24.6569 35.2722 17.8024 45.4693 19.3158 52.6709C20.8321 59.8795 33.3364 46.4384 34.73 44.9301C36.1236 43.4217 40.4814 40.1561 39.373 39.145Z"
        fill="#C17F50"
      />
      <path
        d="M71.5057 52.6147C68.1431 44.3026 45.3699 34.0727 36.5182 38.3001C34.6731 39.1827 31.8941 40.2906 29.6812 42.7421C26.8453 45.8823 21.677 56.1937 21.9236 56.3525L21.9134 56.3481C17.7051 65.8301 4.8355 97.3804 8.45254 105.662C11.9361 113.645 35.8351 124.708 45.7689 121.759C59.0758 117.81 74.2511 59.4048 71.5057 52.6147Z"
        fill="#E3A263"
      />
      <path
        d="M64.9335 52.2861C65.6898 51.9125 69.2041 49.6829 73.9339 51.1525C78.669 52.6263 82.0585 63.1915 78.9405 69.056C75.8183 74.9259 68.3414 60.1277 67.5107 58.4695C66.68 56.8113 63.7186 52.8842 64.9335 52.2861Z"
        fill="#C17F50"
      />
      <path
        d="M22.7302 53.3568C22.9469 53.5308 23.1736 53.7098 23.4102 53.8816L23.4004 53.8765C23.3978 53.8814 23.3928 53.8913 23.3902 53.8962L32.4767 58.5719L39.4697 44.9425C39.4697 44.9425 21.2886 35.8868 18.3477 41.9352C17.579 43.5066 16.3609 48.1811 22.7302 53.3568Z"
        fill="#E3A263"
      />
      <path
        d="M33.7376 49.4798C32.8347 51.3006 28.0582 53.2699 24.3148 51.3998C20.5665 49.5273 19.2645 44.5301 20.1674 42.7093C21.0704 40.8886 24.7335 41.15 28.4818 43.0226C32.2302 44.8952 34.6455 47.6616 33.7376 49.4798Z"
        fill="#3B1C0C"
      />
      <path
        d="M27.7821 44.4234C27.5387 44.91 26.4786 44.8703 25.4037 44.3359C24.3338 43.8041 23.6593 42.9763 23.9028 42.4896C24.1462 42.003 25.2063 42.0427 26.2812 42.577C27.356 43.1114 28.0255 43.9367 27.7821 44.4234Z"
        fill="#F7F5F1"
      />
      <path
        d="M42.3117 44.9589C41.4781 46.6321 39.7577 47.4634 38.4582 46.8196C37.1612 46.1709 36.7873 44.293 37.6209 42.6198C38.4496 40.9441 40.1774 40.1104 41.4744 40.7592C42.7664 41.4053 43.1453 43.2857 42.3117 44.9589Z"
        fill="#3B1C0C"
      />
      <path
        d="M23.2682 55.5839C23.2902 55.6139 23.9758 56.5327 25.2003 57.7349C26.4273 58.9322 28.2029 60.4181 30.4274 61.5628C31.4313 62.0794 32.5276 62.5253 33.7043 62.8385C34.585 63.074 35.5159 63.2358 36.485 63.2991C37.9361 63.3928 39.4762 63.2524 41.0714 62.8107C42.6691 62.3641 44.312 61.6111 45.986 60.4823C46.422 60.1904 46.5319 59.6002 46.2396 59.1637C45.9472 58.7271 45.3572 58.6163 44.9213 58.9082C43.5686 59.823 42.2659 60.4463 41.0175 60.8425C40.0793 61.1434 39.1771 61.3135 38.3056 61.3875C36.9934 61.4959 35.7479 61.3774 34.5756 61.1039C33.4034 60.8303 32.3022 60.3942 31.2933 59.8751C29.3001 58.8495 27.6618 57.484 26.5303 56.3793C25.9621 55.8258 25.5268 55.3405 25.231 54.9955C25.0844 54.8206 24.9745 54.6832 24.9012 54.5957C24.8621 54.5507 24.8377 54.5133 24.8231 54.4933C24.8158 54.4833 24.8084 54.4734 24.8035 54.4708L24.7986 54.4683L24.5493 54.651L24.7986 54.4683L24.5493 54.651L24.7986 54.4683L24.3349 54.8143L24.7986 54.4683L24.3349 54.8143L24.7986 54.4683L24.0483 55.0213L24.7986 54.4683L24.0483 55.0213L24.7986 54.4683C24.4863 54.0464 23.8911 53.9578 23.4698 54.2696C23.0437 54.5666 22.9559 55.162 23.2682 55.5839Z"
        fill="#C17F50"
      />
      <path
        d="M24.1628 33.5776C26.7254 32.3321 27.7903 29.2356 26.5414 26.6612C25.2924 24.0869 22.2026 23.0097 19.64 24.2551C17.0774 25.5006 16.0125 28.5972 17.2614 31.1715C18.5103 33.7458 21.6002 34.8231 24.1628 33.5776Z"
        fill="#C17F50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.8904 49.4189C55.8066 48.9883 55.3896 48.7072 54.9591 48.791L48.984 49.954C48.694 50.0104 48.4593 50.2232 48.3748 50.5062C48.2902 50.7893 48.3697 51.0959 48.5812 51.3022L53.593 56.1899C53.907 56.4962 54.4098 56.4899 54.7161 56.1758C55.0223 55.8618 55.0161 55.359 54.702 55.0527L50.7757 51.2235L55.2625 50.3502C55.6931 50.2664 55.9742 49.8495 55.8904 49.4189Z"
        fill="#3B1C0C"
      />
      <path
        d="M60.0423 83.3136C59.0727 83.8427 58.7156 85.0576 59.2446 86.0272C59.7737 86.9968 60.9886 87.3539 61.9583 86.8249L60.0423 83.3136ZM77.6612 85.8155C78.0176 84.9295 78.0754 83.9315 78.0204 83.0647C77.9633 82.1646 77.7728 81.2261 77.488 80.3624C77.2069 79.5098 76.8034 78.6376 76.2654 77.9205C75.7669 77.2559 74.909 76.4066 73.6545 76.26L73.1903 80.233C72.8341 80.1914 72.8236 79.9981 73.0657 80.3208C73.2683 80.591 73.4988 81.0374 73.6892 81.6149C73.876 82.1814 73.9946 82.7845 74.0284 83.3179C74.0643 83.8845 73.9949 84.2117 73.9502 84.3229L77.6612 85.8155ZM73.6545 76.26C72.024 76.0695 70.6271 76.2302 69.3519 76.7041C68.1045 77.1677 67.0908 77.8893 66.1879 78.6311C64.3212 80.1646 62.8607 81.7757 60.0423 83.3136L61.9583 86.8249C65.2186 85.0458 67.258 82.9287 68.7271 81.7218C69.492 81.0933 70.1052 80.6915 70.7455 80.4535C71.3579 80.2259 72.1096 80.1067 73.1903 80.233L73.6545 76.26Z"
        fill="#C17F50"
      />
      <path
        d="M78 87.5C78 89.9853 75.9853 91 73.5 91C71.0147 91 69 89.9853 69 87.5C69 85.0147 71.0147 83 73.5 83C75.9853 83 78 85.0147 78 87.5Z"
        fill="#C17F50"
      />
      <path
        opacity="0.34"
        d="M8.9651 75.6881C7.08541 71.9946 6.34367 67.6566 6.1509 63.5404C6.04237 61.2229 5.826 58.2673 6.56391 56"
        stroke="#E5E6E8"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2076_31875">
        <rect width="83" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg
    width="83"
    height="90"
    viewBox="0 0 83 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={3}
  >
    <g clipPath="url(#clip0_2263_56865)">
      <path
        d="M13.4588 78.0434C14.5371 77.7039 15.676 78.3271 16.0086 79.4312C16.0315 79.5129 16.4532 80.8467 17.3314 82.5112C17.7705 83.3435 18.3262 84.2592 18.9904 85.1438C19.6546 86.0284 20.4336 86.8786 21.3198 87.5958C22.1428 88.2619 23.0561 88.8167 24.0724 89.2C25.1305 89.6013 25.6704 90.8003 25.2777 91.8815C24.8849 92.9627 23.7112 93.5187 22.6532 93.1174C21.18 92.5633 19.8935 91.7672 18.7784 90.8684C17.5431 89.8637 16.5196 88.7259 15.6724 87.5936C14.4029 85.8903 13.5252 84.1886 12.9557 82.8952C12.3861 81.6018 12.1185 80.7202 12.096 80.6547C11.7744 79.5487 12.3805 78.3828 13.4588 78.0434Z"
        fill="#C17F50"
      />
      <path
        d="M12.9875 82.4478C15.0921 81.9331 16.3732 79.791 15.8489 77.6633C15.3246 75.5355 13.1935 74.2279 11.089 74.7426C8.9844 75.2573 7.70333 77.3994 8.2276 79.5271C8.75187 81.6548 10.883 82.9624 12.9875 82.4478Z"
        fill="#C17F50"
      />
      <path
        d="M31.4 51.1363C30.7971 50.5892 28.1268 47.5895 23.3481 47.6897C18.5633 47.7923 12.6179 56.6675 13.9572 62.9257C15.299 69.1899 26.1593 57.4859 27.3697 56.1725C28.58 54.8592 32.3688 52.0135 31.4 51.1363Z"
        fill="#C17F50"
      />
      <path
        d="M68.7613 107.895C74.2677 107.11 83.2673 100.879 81.3191 91.8637C80.9966 90.3782 79.0639 90.7893 78.6267 92.247C77.7379 95.1875 75.2536 101.16 68.4096 102.084C57.5516 103.55 61.7817 108.89 68.7613 107.895Z"
        fill="#C17F50"
      />
      <path
        d="M60.909 60.4353C55.379 56.3088 32.5168 62.6796 26.8364 67.4601C25.6527 68.4574 23.7949 69.8269 22.8489 71.8577C21.6362 74.4593 20.9862 81.95 21.2384 81.9825H21.2287C21.0686 88.7324 21.447 110.904 27.1711 114.948C32.6866 118.847 55.6653 119.407 62.6506 115.118C72.008 109.372 65.4252 63.8067 60.909 60.4353Z"
        fill="#E3A263"
      />
      <path
        d="M59.5449 51.971C53.9661 46.5174 32.2063 46.2819 26.602 52.7263C25.4342 54.0707 23.5985 55.9192 22.6825 58.6414C21.5082 62.1289 20.964 72.1402 21.2163 72.181L21.2066 72.1811C21.1412 81.198 19.973 85.7644 25.7443 91.105C31.3054 96.2548 54.2547 96.7625 61.1687 90.9609C70.4306 83.1895 64.1009 56.4266 59.5449 51.971Z"
        fill="#E3A263"
      />
      <path
        d="M26.2712 74.7608C26.509 74.8066 26.7565 74.8523 27.0088 74.8882L26.9991 74.8883C26.9991 74.8931 26.9992 74.9028 26.9993 74.9076L34.1614 74.291C37.6575 73.99 40.331 71.0456 40.2943 67.5368C40.2539 63.6844 36.9822 60.6652 33.1703 61.224C26.7025 62.1721 18.1378 64.1167 18.2544 67.7592C18.2994 69.2791 19.2577 73.3669 26.2712 74.7608Z"
        fill="#E3A263"
      />
      <path
        d="M33.1886 67.4624C33.2313 69.2285 30.3468 72.6808 26.7004 72.7625C23.0492 72.8441 20.0164 69.531 19.9737 67.7649C19.9309 65.9988 22.8624 64.7132 26.5136 64.6315C30.1648 64.5498 33.1507 65.6962 33.1886 67.4624Z"
        fill="#3B1C0C"
      />
      <path
        d="M26.5422 66.3262C26.5519 66.7991 25.7182 67.1988 24.6722 67.2243C23.6311 67.2497 22.7749 66.887 22.7651 66.4141C22.7553 65.9412 23.589 65.5415 24.635 65.516C25.6809 65.4906 26.5324 65.8533 26.5422 66.3262Z"
        fill="#F7F5F1"
      />
      <path
        d="M25.4697 74.8713C36.21 79.9757 44.6694 70.0296 44.6694 70.0296"
        stroke="#C17F50"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M43.0585 60.6541C43.0744 60.3014 43.1388 59.9655 43.2337 59.6617C43.3804 59.2043 43.6127 58.8131 43.8819 58.5211C44.0164 58.3751 44.1611 58.255 44.3118 58.1605C44.4625 58.066 44.6146 58.0008 44.769 57.9567C44.9147 57.9159 45.0664 57.9007 45.2204 57.9065C45.4116 57.9158 45.5911 57.9614 45.7675 58.0402C45.8995 58.1023 46.0259 58.1806 46.15 58.2837C46.3365 58.4342 46.506 58.6332 46.6509 58.8715C46.7959 59.1099 46.9164 59.3877 47.0011 59.6914C47.0977 60.0461 47.145 60.4381 47.1238 60.8487C47.1041 61.2428 47.4115 61.5753 47.8026 61.5904C48.1974 61.61 48.5306 61.3031 48.5457 60.9128C48.5721 60.3567 48.5091 59.8175 48.3716 59.3173C48.269 58.937 48.1185 58.5772 47.9319 58.2475C47.6517 57.755 47.2815 57.3334 46.8303 57.021C46.6069 56.8629 46.3598 56.736 46.1009 56.6456C45.8379 56.5547 45.5636 56.4961 45.2768 56.4823C44.9693 56.4665 44.6696 56.5015 44.3827 56.5793C43.9843 56.6885 43.6118 56.8793 43.2841 57.1284C42.7942 57.5042 42.3969 58.0095 42.1127 58.6001C41.8285 59.1908 41.6537 59.8623 41.6208 60.5803C41.6011 60.9744 41.9085 61.3069 42.2996 61.322C42.7061 61.351 43.0388 61.0482 43.0585 60.6541Z"
        fill="#3B1C0C"
      />
      <path
        d="M26.3632 60.909C26.3791 60.5562 26.4435 60.2204 26.5384 59.9166C26.685 59.4591 26.9174 59.0679 27.1865 58.7759C27.3211 58.6299 27.4658 58.5099 27.6165 58.4154C27.7672 58.3209 27.9193 58.2557 28.0737 58.2115C28.2194 58.1708 28.3711 58.1555 28.5251 58.1614C28.7163 58.1706 28.8957 58.2163 29.0722 58.295C29.2042 58.3572 29.3306 58.4355 29.4547 58.5386C29.6412 58.6891 29.8107 58.888 29.9556 59.1264C30.1006 59.3648 30.2211 59.6425 30.3058 59.9462C30.4024 60.301 30.4497 60.6929 30.4285 61.1035C30.4088 61.4976 30.7162 61.8301 31.1073 61.8453C31.5021 61.8649 31.8352 61.5579 31.8504 61.1676C31.8767 60.6116 31.8138 60.0724 31.6763 59.5721C31.5737 59.1918 31.4232 58.8321 31.2366 58.5024C30.9564 58.0098 30.5862 57.5882 30.1349 57.2759C29.9116 57.1178 29.6644 56.9909 29.4056 56.9004C29.1426 56.8095 28.8683 56.751 28.5815 56.7371C28.274 56.7214 27.9743 56.7563 27.6874 56.8341C27.289 56.9433 26.9165 57.1341 26.5887 57.3832C26.0989 57.7591 25.7015 58.2643 25.4174 58.855C25.1332 59.4456 24.9584 60.1172 24.9255 60.8351C24.9058 61.2292 25.2132 61.5617 25.6043 61.5768C26.0108 61.6059 26.3435 61.3031 26.3632 60.909Z"
        fill="#3B1C0C"
      />
      <path
        d="M58.0807 50.6675C58.5758 50.0341 60.6651 46.6708 65.3345 45.9762C70.0038 45.2815 77.2896 52.9291 77.0374 59.2413C76.7852 65.5536 64.2504 55.9691 62.8513 54.8955C61.4522 53.8218 60.3436 56.6192 57.4127 55.4597C54.4824 54.2957 57.2838 51.6815 58.0807 50.6675Z"
        fill="#C17F50"
      />
      <path
        d="M22.9732 51.3362L21.6149 52.9442C21.5198 53.0567 21.4031 53.1491 21.2718 53.2159C21.1405 53.2826 20.9971 53.3224 20.8501 53.3329C20.7032 53.3434 20.5556 53.3244 20.4161 53.277C20.2766 53.2296 20.148 53.1548 20.0378 53.0569L19.399 52.49L13.3714 47.1387C13.1557 46.9471 13.0235 46.6787 13.0028 46.3909C12.9822 46.1031 13.0748 45.8186 13.2609 45.5981L13.9848 44.7427L14.3407 44.3217C14.5268 44.1011 14.7919 43.9622 15.0791 43.9345C15.3664 43.9068 15.6531 43.9926 15.878 44.1736L22.8229 49.7624C22.9376 49.8548 23.0327 49.9692 23.1025 50.0989C23.1724 50.2286 23.2156 50.371 23.2296 50.5176C23.2436 50.6643 23.2281 50.8122 23.1841 50.9528C23.14 51.0934 23.0683 51.2237 22.9732 51.3362Z"
        fill="#F87F8D"
      />
      <path
        d="M55.8584 44.3044C55.8585 44.6986 55.7417 45.084 55.5228 45.4119C55.3039 45.7397 54.9927 45.9953 54.6285 46.1462C54.2644 46.2972 53.8636 46.3368 53.4769 46.26C53.0903 46.1832 52.7351 45.9934 52.4563 45.7147C52.1774 45.4361 51.9875 45.081 51.9105 44.6944C51.8335 44.3077 51.8728 43.907 52.0236 43.5427C52.1743 43.1785 52.4297 42.8671 52.7574 42.648C53.0852 42.4289 53.4705 42.3119 53.8647 42.3118C54.009 42.3113 54.1528 42.3272 54.2935 42.3593C54.7371 42.4564 55.1342 42.7022 55.4188 43.056C55.7035 43.4098 55.8586 43.8503 55.8584 44.3044Z"
        fill="#934CD7"
      />
      <path
        d="M23.4512 32.9926C23.4513 33.3869 23.3345 33.7722 23.1156 34.1001C22.8967 34.4279 22.5855 34.6835 22.2213 34.8344C21.8571 34.9854 21.4564 35.025 21.0697 34.9482C20.6831 34.8714 20.3279 34.6816 20.049 34.403C19.7702 34.1243 19.5803 33.7692 19.5033 33.3826C19.4262 32.996 19.4656 32.5952 19.6164 32.2309C19.7671 31.8667 20.0225 31.5553 20.3502 31.3362C20.6779 31.1171 21.0633 31.0001 21.4575 31C21.6018 30.9995 21.7456 31.0154 21.8863 31.0475C22.3299 31.1446 22.727 31.3904 23.0116 31.7442C23.2963 32.098 23.4514 32.5385 23.4512 32.9926Z"
        fill="#FD7B33"
      />
      <path
        d="M34.0794 39.3574L32.6007 42.1911C32.5067 42.371 32.3783 42.5307 32.2226 42.661C32.067 42.7912 31.8872 42.8896 31.6935 42.9503C31.4998 43.0111 31.2961 43.0331 31.0939 43.0151C30.8917 42.9971 30.6951 42.9394 30.5152 42.8453L27.6815 41.3666C27.4393 41.2402 27.235 41.0518 27.0896 40.8205C26.9441 40.5892 26.8628 40.3234 26.8538 40.0504C26.843 39.7832 26.9029 39.5179 27.0273 39.2812L28.506 36.4475C28.6958 36.0842 29.0222 35.8112 29.4133 35.6885C29.8043 35.5658 30.2281 35.6035 30.5915 35.7932L32.8836 36.9901L33.4251 37.272C33.7884 37.4618 34.0613 37.7882 34.184 38.1792C34.3067 38.5703 34.2691 38.9941 34.0794 39.3574Z"
        fill="#66B1E2"
      />
      <path
        d="M61.7062 66.1788L59.9915 66.6698C59.7938 66.7264 59.5854 66.7351 59.3837 66.6953C59.1819 66.6555 58.9925 66.5683 58.8311 66.4409C58.6697 66.3135 58.5409 66.1495 58.4554 65.9625C58.3699 65.7755 58.33 65.5708 58.3392 65.3654C58.3511 65.0955 58.4471 64.836 58.6136 64.6233C58.7802 64.4105 59.009 64.2551 59.2682 64.1788L64.5817 62.6123L64.0853 64.0267L61.7062 66.1788Z"
        fill="#ACE3D9"
      />
      <path
        d="M65.4665 49.3169L67.1811 48.8265C67.3787 48.77 67.587 48.7612 67.7887 48.801C67.9904 48.8408 68.1798 48.928 68.3411 49.0554C68.5025 49.1827 68.6313 49.3466 68.7169 49.5336C68.8024 49.7205 68.8423 49.9251 68.8332 50.1305C68.8213 50.4004 68.7253 50.6599 68.5588 50.8726C68.3922 51.0854 68.1634 51.2408 67.9042 51.3172L62.5908 52.8836L63.0871 51.4692L65.4665 49.3169Z"
        fill="#ACE3D9"
      />
      <path
        d="M65.263 64.4998C64.9062 64.7949 64.5199 65.0526 64.1104 65.2687C63.3488 65.6678 62.5419 65.9733 61.707 66.1788C61.707 66.1788 62.951 65.2559 62.951 62.9439C62.951 61.8306 62.6625 60.3963 61.809 58.5881C59.1813 53.0215 61.911 50.9958 61.911 50.9958C62.7705 50.2767 63.9895 49.6952 65.4669 49.3168C65.4669 49.3168 65.0923 49.5947 64.7547 50.2276C64.476 50.748 64.2229 51.5086 64.2229 52.5525C64.2229 53.665 64.5115 55.1001 65.3649 56.9083C67.9926 62.4741 65.263 64.4998 65.263 64.4998Z"
        fill="#ACE3D9"
      />
      <path
        d="M64.1737 43.7224L65.3609 43.6301C65.4977 43.6194 65.6301 43.5764 65.747 43.5045C65.864 43.4326 65.9622 43.334 66.0335 43.2167C66.1048 43.0994 66.1472 42.9668 66.1573 42.8299C66.1673 42.693 66.1446 42.5557 66.0911 42.4293C66.0208 42.2632 65.9003 42.1232 65.7466 42.0288C65.5929 41.9345 65.4135 41.8905 65.2335 41.9031L61.5439 42.1601L62.1838 42.9296L64.1737 43.7224Z"
        fill="#FFDC5E"
      />
      <path
        d="M57.894 34.0466L56.7069 34.1389C56.57 34.1496 56.4377 34.1926 56.3207 34.2645C56.2038 34.3364 56.1056 34.435 56.0343 34.5523C55.9629 34.6696 55.9205 34.8021 55.9105 34.939C55.9005 35.0759 55.9232 35.2133 55.9767 35.3397C56.047 35.5058 56.1674 35.6458 56.3212 35.7401C56.4749 35.8345 56.6543 35.8784 56.8342 35.8659L60.5238 35.6089L59.884 34.8394L57.894 34.0466Z"
        fill="#FFDC5E"
      />
      <path
        d="M57.8936 34.0466C58.9062 33.9392 59.8044 34.0192 60.5099 34.2689C60.5099 34.2689 62.6896 34.9012 62.3412 38.9959C61.9933 43.09 64.1724 43.7223 64.1724 43.7223C63.1597 43.8297 62.2616 43.7497 61.5563 43.5C61.5563 43.5 59.3767 42.8677 59.7251 38.773C60.073 34.6788 57.8936 34.0466 57.8936 34.0466Z"
        fill="#FFDC5E"
      />
      <path
        d="M75.1755 72.7093C73.7709 72.4633 72.4005 73.3888 72.1065 74.7723C72.0869 74.8743 71.7067 76.5487 70.7864 78.6918C70.3263 79.7633 69.7281 80.9533 68.989 82.1222C68.2498 83.2911 67.3614 84.4359 66.3217 85.4365C65.3564 86.3657 64.2637 87.1742 63.0209 87.7914C61.7273 88.4367 61.1818 89.9663 61.8038 91.212C62.4257 92.4576 63.976 92.9505 65.2697 92.3053C67.0713 91.4125 68.6087 90.2559 69.9171 89.0013C71.3657 87.6007 72.5306 86.0715 73.473 84.5758C74.8842 82.3265 75.7995 80.1386 76.3717 78.4905C76.9439 76.8425 77.1812 75.7375 77.2021 75.655C77.4819 74.2708 76.5801 72.9554 75.1755 72.7093Z"
        fill="#C17F50"
      />
      <path
        d="M73.4328 79.3346C70.7402 78.9721 68.85 76.4853 69.2109 73.7802C69.5719 71.0751 72.0472 69.176 74.7398 69.5384C77.4324 69.9009 79.3226 72.3877 78.9617 75.0929C78.6008 77.798 76.1254 79.6971 73.4328 79.3346Z"
        fill="#C17F50"
      />
      <path
        d="M65.4492 72.7999C63.5282 74.2176 68.5738 75.1526 70.6695 76.7267L73.2241 72.3444C71.2522 71.8149 67.3701 71.3822 65.4492 72.7999Z"
        fill="#C17F50"
      />
    </g>
    <defs>
      <clipPath id="clip0_2263_56865">
        <rect width="83" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  <svg
    width="83"
    height="90"
    viewBox="0 0 83 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={5}
  >
    <g clipPath="url(#clip0_2263_58375)">
      <path
        d="M62.2713 107.688C68.677 108.481 80.6217 104.175 81.1266 93.3724C81.2081 91.5919 78.9194 91.4669 77.9947 92.9839C76.1217 96.0419 71.5562 102.046 63.6102 101.001C51.0036 99.3429 54.152 106.682 62.2713 107.688Z"
        fill="#C17F50"
      />
      <path
        d="M22.4129 35.3257C21.5268 34.9997 17.3488 32.9293 12.2739 35.1681C7.19368 37.4124 4.72338 49.7095 8.90678 55.9151C13.0955 62.1261 19.5881 44.5635 20.3078 42.5964C21.0274 40.6293 23.836 35.8474 22.4129 35.3257Z"
        fill="#C17F50"
      />
      <path
        d="M6.55765 80.8421C6.261 79.7818 6.21791 78.7033 6.33523 77.6531C6.51268 76.0801 7.04442 74.5553 7.78842 73.15C8.53539 71.7493 9.49922 70.4651 10.6389 69.4154C11.5388 68.5975 12.9348 68.6665 13.7629 69.5766C14.5909 70.4867 14.5371 71.889 13.6402 72.7115C13.0889 73.2147 12.5492 73.8676 12.0935 74.5911C11.7525 75.1299 11.455 75.7063 11.2288 76.283C10.8865 77.1434 10.7036 78 10.6942 78.6931C10.6881 79.0374 10.7218 79.343 10.7851 79.6036C10.8453 79.8596 10.9366 80.063 11.0495 80.2395C11.1595 80.4113 11.291 80.5562 11.4816 80.7026C11.7684 80.9183 12.2054 81.1362 12.9164 81.2769C13.6198 81.4159 14.5855 81.4592 15.8219 81.3292C17.0341 81.2017 18.1295 82.0847 18.2738 83.3013C18.4136 84.5209 17.5464 85.6123 16.3372 85.7445C14.241 85.9664 12.4575 85.8377 10.9233 85.3204C10.1578 85.064 9.45888 84.6996 8.84773 84.2398C8.23658 83.7799 7.72993 83.2205 7.34072 82.6121C6.98582 82.0473 6.7284 81.4528 6.55765 80.8421Z"
        fill="#C17F50"
      />
      <path
        d="M55.7985 36.5899C49.4062 30.1146 24.2183 29.5703 17.6522 37.0718C16.284 38.6368 14.1364 40.7858 13.043 43.9724C11.6412 48.0549 10.8898 59.8091 11.1814 59.8601H11.1702C10.9851 70.4519 11.4225 105.244 18.0391 111.589C24.4146 117.707 50.9762 118.586 59.0507 111.855C69.8672 102.84 61.0189 41.8801 55.7985 36.5899Z"
        fill="#E3A263"
      />
      <path
        d="M18.2524 65.108C18.5271 65.1647 18.8131 65.2214 19.1047 65.2668H19.0935C19.0935 65.2724 19.0935 65.2838 19.0935 65.2894H29.4052V49.6456C29.4052 49.6456 8.91065 49.9177 9.05644 56.7843C9.09008 58.5703 10.1499 63.3843 18.2524 65.108Z"
        fill="#E3A263"
      />
      <path
        d="M26.2097 56.3761C26.2377 58.4513 22.8565 62.4714 18.6342 62.5225C14.4063 62.5735 10.9354 58.6441 10.9074 56.5688C10.8794 54.4936 14.2886 53.0193 18.5165 52.9683C22.7444 52.9173 26.1872 54.3008 26.2097 56.3761Z"
        fill="#3B1C0C"
      />
      <path
        d="M18.6735 54.6353C18.6791 55.1909 17.7091 55.6502 16.4979 55.6672C15.2923 55.6842 14.3054 55.2476 14.2998 54.692C14.2942 54.1363 15.2643 53.677 16.4755 53.66C17.6866 53.643 18.6679 54.0796 18.6735 54.6353Z"
        fill="#6C442F"
      />
      <path
        d="M51.7637 38.4976C52.4909 37.8907 55.7437 34.5267 61.2668 34.9204C66.7967 35.3175 73.1916 46.0712 71.3013 53.3342C69.4078 60.6041 57.4808 46.2483 56.1521 44.6378C54.8234 43.0273 50.595 39.4705 51.7637 38.4976Z"
        fill="#C17F50"
      />
      <path
        d="M19.7614 66.8792C19.7949 66.8961 20.8337 67.4101 22.4869 67.9296C24.14 68.4436 26.4187 68.9631 28.9431 68.9631C30.0824 68.9631 31.272 68.8558 32.4728 68.5904C33.372 68.3927 34.2823 68.1047 35.1815 67.7094C36.5275 67.1164 37.8456 66.2692 39.0743 65.1228C40.303 63.9707 41.4311 62.5192 42.4141 60.712C42.671 60.2433 42.4979 59.6559 42.0343 59.3961C41.5708 59.1363 40.9899 59.3114 40.733 59.7802C39.9399 61.2429 39.0575 62.4176 38.1192 63.3607C37.4155 64.0723 36.6839 64.6484 35.9355 65.1228C34.8073 65.8344 33.6345 66.3088 32.4561 66.6081C31.2776 66.9074 30.088 67.026 28.9431 67.026C26.6812 67.026 24.5812 66.5516 23.0565 66.0772C22.2914 65.84 21.677 65.6028 21.2526 65.4277C21.0403 65.3374 20.8784 65.264 20.7723 65.2188C20.7164 65.1962 20.6773 65.1736 20.655 65.1623C20.6438 65.1567 20.6326 65.151 20.627 65.151H20.6215C20.1467 64.9138 19.5715 65.1115 19.3369 65.5915C19.0912 66.0603 19.2867 66.642 19.7614 66.8792Z"
        fill="#C17F50"
      />
      <path
        d="M42.2081 48.2917C42.2348 50.3105 40.9975 51.9667 39.4496 51.9884C37.9016 52.01 36.6268 50.3863 36.6 48.3675C36.5733 46.3486 37.8106 44.6924 39.3585 44.6708C40.9065 44.6491 42.1813 46.2729 42.2081 48.2917Z"
        fill="#3B1C0C"
      />
      <path
        d="M25.6735 47.1119C25.6949 48.9413 24.5808 50.4351 23.1774 50.4568C21.7741 50.473 20.6225 49.0063 20.6011 47.1769C20.5743 45.3475 21.6937 43.8482 23.0971 43.832C24.4951 43.8158 25.652 45.2825 25.6735 47.1119Z"
        fill="#3B1C0C"
      />
      <path
        d="M37.6864 88.7219C38.5976 88.0585 39.3168 87.2196 39.8891 86.3018C40.745 84.926 41.2857 83.3642 41.5801 81.7637C41.8691 80.1651 41.91 78.5225 41.6609 76.9567C41.4571 75.7291 40.292 74.8992 39.0492 75.105C39.1726 75.8588 37.3005 79.3209 37.2074 80.1909C37.1392 80.8399 37.0123 81.4915 36.828 82.0981C36.5568 83.0061 36.1601 83.8101 35.7276 84.3728C35.5141 84.6531 35.2929 84.8772 35.0767 85.0464C34.8659 85.2137 34.6634 85.3192 34.4607 85.3893C34.2634 85.4576 34.0657 85.4906 33.8197 85.4872C33.4524 85.4786 32.9631 85.3765 32.3027 85.0386C31.6494 84.7041 30.8463 84.1265 29.9356 83.238C29.0429 82.3668 27.6025 82.3814 26.7144 83.2669C25.828 84.1577 25.8319 85.5842 26.7192 86.4572C28.2622 87.9647 29.7765 88.9925 31.3371 89.5499C32.1148 89.8296 32.9075 89.9801 33.6903 89.9983C34.4731 90.0166 35.2351 89.8886 35.9339 89.6469C36.5775 89.4183 37.1616 89.1041 37.6864 88.7219Z"
        fill="#C17F50"
      />
      <g opacity="0.05">
        <path
          d="M24.2547 87.5665C24.1105 87.7705 23.9254 87.9368 23.7154 88.058C23.4134 88.2324 23.06 88.3131 22.703 88.2792C22.098 88.2209 21.5782 87.8412 21.3461 87.2873L19.4706 82.812L14.7733 81.4353C14.192 81.2652 13.7552 80.7938 13.6336 80.2048C13.512 79.6158 13.7242 79.0026 14.188 78.604L17.9356 75.384L17.879 70.5113C17.872 69.9085 18.1963 69.3438 18.7264 69.0378C19.2561 68.732 19.9076 68.7333 20.4262 69.0407L24.6172 71.5259L29.2798 69.8907C29.8569 69.6884 30.494 69.8112 30.9433 70.211C31.3922 70.611 31.5823 71.2245 31.4395 71.8131L30.2825 76.5692L33.2207 80.4315C33.5843 80.9094 33.6533 81.5494 33.4011 82.1022C33.1491 82.6553 32.6153 83.0339 32.0081 83.09L27.1012 83.5443L24.2547 87.5665Z"
          fill="#6C442F"
        />
      </g>
      <g filter="url(#filter0_d_2263_58375)">
        <path
          d="M22.1285 84.3923C21.9956 84.5802 21.8251 84.7333 21.6317 84.845C21.3536 85.0055 21.0281 85.0799 20.6993 85.0487C20.1421 84.995 19.6634 84.6453 19.4496 84.1351L17.7223 80.0135L13.3962 78.7456C12.8608 78.5889 12.4585 78.1547 12.3465 77.6123C12.2346 77.0698 12.43 76.5051 12.8571 76.138L16.3087 73.1724L16.2565 68.6847C16.2501 68.1295 16.5488 67.6095 17.037 67.3276C17.5248 67.046 18.1248 67.0471 18.6024 67.3303L22.4623 69.6192L26.7565 68.1132C27.288 67.9268 27.8747 68.0399 28.2885 68.4081C28.702 68.7766 28.877 69.3415 28.7455 69.8837L27.68 74.264L30.386 77.821C30.7209 78.2612 30.7844 78.8507 30.5521 79.3597C30.32 79.8691 29.8284 80.2178 29.2693 80.2695L24.7501 80.6879L22.1285 84.3923Z"
          fill="#FFC74E"
        />
      </g>
      <path
        d="M18.4697 86.8106C20.4128 85.3839 20.8534 82.6613 19.4537 80.7295C18.0541 78.7977 15.3443 78.3883 13.4011 79.815C11.458 81.2417 11.0174 83.9643 12.4171 85.8961C13.8167 87.8279 16.5266 88.2373 18.4697 86.8106Z"
        fill="#C17F50"
      />
      <path
        d="M26.9503 86.0971C29.356 85.9424 31.1943 83.8864 31.0563 81.5048C30.9183 79.1233 28.8563 77.3181 26.4506 77.4728C24.0449 77.6274 22.2066 79.6834 22.3446 82.065C22.4826 84.4465 24.5446 86.2517 26.9503 86.0971Z"
        fill="#C17F50"
      />
      <circle cx="25" cy="72" r="1" fill="white" />
      <circle cx="23.5" cy="70.5" r="0.5" fill="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_2263_58375"
        x="-3.68457"
        y="55.1172"
        width="50.375"
        height="49.9385"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.885138 0 0 0 0 0.589779 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2263_58375"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2263_58375"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_2263_58375">
        <rect width="83" height="90" fill="white" />
      </clipPath>
    </defs>
  </svg>,
];
