import moment from 'moment';

import type { CombinedState } from 'ms-pages/Teacher/components/CreateTask/CreateAdaptiveTask/state';
import {
  Title,
  ReviewCard,
  Detail,
  DetailArea,
  EditButton,
  ErrorMessage,
} from 'ms-pages/Teacher/components/CreateTask/components/BulkTaskAssignment/ReviewCard';
import { getMasteryLevelTitle } from 'ms-utils/masteryLevel';

type Props = {
  onEdit: () => void;
  state: CombinedState;
  errorMessages: ReadonlyArray<Error>;
};

export default function SubtopicReviewCard({
  onEdit,
  state,
  errorMessages,
}: Props) {
  const selectedClassCount = state.values.selectedClasses.length;
  const selectedStudentCount = state.values.selectedStudents.length;
  return (
    <>
      <ReviewCard error={errorMessages.length > 0}>
        <Title>{state.values.taskName}</Title>
        <DetailArea>
          <div>
            <Detail>
              Start date:{' '}
              {moment(state.values.taskStartDate).format('DD MMM YY, hh:mma')}
            </Detail>
            <Detail>
              End date:{' '}
              {moment(state.values.taskDueDate).format('DD MMM YY, hh:mma')}
            </Detail>
            <Detail>
              Assigned To:{' '}
              {selectedClassCount > 0
                ? `${selectedClassCount} class${
                    selectedClassCount > 1 ? 'es' : ''
                  }`
                : null}
              {selectedClassCount > 0 && selectedStudentCount > 0
                ? ' and '
                : null}
              {selectedStudentCount > 0
                ? `${selectedStudentCount} student${
                    selectedStudentCount > 1 ? 's' : ''
                  } `
                : null}
            </Detail>
            <Detail>
              Target mastery: {getMasteryLevelTitle(state.values.targetMastery)}
            </Detail>
          </div>
          <EditButton onClick={onEdit} isFilled={state.values.edited} />
        </DetailArea>
      </ReviewCard>
      {errorMessages.map(error => (
        <ErrorMessage key={error.message}>{error.message}</ErrorMessage>
      ))}
    </>
  );
}
