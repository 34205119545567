import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FacebookSimple = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={11}
    viewBoxHeight={20}
  >
    <path
      fill={color}
      d="M9.06823 3.46146H10.8941V0.281458C10.5791 0.238125 9.49574 0.140625 8.23407 0.140625C5.60157 0.140625 3.79823 1.79646 3.79823 4.83979V7.64062H0.893234V11.1956H3.79823V20.1406H7.3599V11.1965H10.1474L10.5899 7.64146H7.35907V5.19229C7.3599 4.16479 7.63657 3.46146 9.06823 3.46146Z"
    />
  </Icon>
);

FacebookSimple.displayName = 'FacebookSimple';

export default FacebookSimple;
