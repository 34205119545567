// Small utility components for vertical/horizontal stacks.
// Naming is inspired by Apple's SwiftUI
// See: https://developer.apple.com/documentation/swiftui/view_layout_and_presentation

import { HSpacer, HVSpacer, VSpacer } from './Spacer';
import { HStack, VStack } from './Stack';

export { HSpacer, HVSpacer, VSpacer } from './Spacer';
export { HStack, VStack, type StackProps } from './Stack';

export default {
  H: HStack,
  V: VStack,
  Spacer: {
    HV: HVSpacer,
    H: HSpacer,
    V: VSpacer,
  },
};
