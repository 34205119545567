type Props = {
  minorTicks: ReadonlyArray<number>;
  majorTicks: ReadonlyArray<number>;
  height: number;
  width: number;
  primaryFill: string;
  secondaryFill: string;
};

const AXIS_STROKE_WIDTH = 1;
const TICK_WIDTH = 1;
const MINOR_TICK_HEIGHT = 10;
const MAJOR_TICK_HEIGHT = 20;

const Axis = ({
  minorTicks,
  majorTicks,
  height,
  width,
  primaryFill,
  secondaryFill,
}: Props) => (
  <g>
    {minorTicks.map(tick => (
      <rect
        key={tick}
        x={tick}
        y={height - MINOR_TICK_HEIGHT}
        width={TICK_WIDTH}
        height={MINOR_TICK_HEIGHT}
        fill={secondaryFill}
      />
    ))}
    {majorTicks.map(tick => (
      <rect
        key={tick}
        x={tick}
        y={height - MAJOR_TICK_HEIGHT}
        width={TICK_WIDTH}
        height={MAJOR_TICK_HEIGHT}
        fill={primaryFill}
      />
    ))}
    <rect
      x={0}
      y={height - AXIS_STROKE_WIDTH}
      width={width}
      height={AXIS_STROKE_WIDTH}
      fill={primaryFill}
    />
  </g>
);

export default Axis;
