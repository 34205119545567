import type { ReactNode } from 'react';
import { graphql, useFragment } from 'relay-hooks';

import HardRedirect from 'ms-helpers/HardRedirect';
import { InvariantViolation } from 'ms-utils/app-logging';
import { studentOnboardingUrl } from 'ms-utils/urls';

import type {
  StudentOnboardingStepType,
  WithStudentOnboardingFragment_profile$key,
  WithStudentOnboardingFragment_profile$data,
} from './__generated__/WithStudentOnboardingFragment_profile.graphql';

type OnboardingChecklist = Extract<
  WithStudentOnboardingFragment_profile$data,
  {
    __typename: 'Student';
  }
>['onboardingChecklist'];
const isOnboardingStepCompleted = (
  onboardingChecklist: OnboardingChecklist,
  stepType: StudentOnboardingStepType,
) =>
  Boolean(
    onboardingChecklist.find(
      step => step.stepType === stepType && step.status === 'COMPLETED',
    ),
  );
type Props = {
  profile: WithStudentOnboardingFragment_profile$key;
  children: ReactNode;
};
/**
 * `View` – A component that takes expects a query reference via props for its data
 * i.e. some other component is executing the top-level query
 */
export default function View(props: Props) {
  const profile = useFragment(
    graphql`
      fragment WithStudentOnboardingFragment_profile on Profile {
        __typename
        ... on Student {
          hasCompletedOnboarding
          onboardingChecklist {
            status
            stepType
          }
        }
      }
    `,
    props.profile,
  );
  if (profile.__typename !== 'Student') {
    throw new InvariantViolation('User is not a student');
  }
  const hasSeenWelcomeMessage = isOnboardingStepCompleted(
    profile.onboardingChecklist,
    'SEE_WELCOME_MESSAGE',
  );
  return profile.hasCompletedOnboarding || hasSeenWelcomeMessage ? (
    <>{props.children}</>
  ) : (
    <HardRedirect to={studentOnboardingUrl} />
  );
}
