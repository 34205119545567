import {
  Suspense,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { useQuery, graphql } from 'relay-hooks';

import Retry from 'ms-components/Retry';
import { StudentDismissablePopover } from 'ms-components/StudentDismissablePopover';
import { SkillsMap, TextbookTeacher, Search } from 'ms-components/icons';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { fonts } from 'ms-pages/Lantern/styles';
import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import MajorSpinner from 'ms-pages/Teacher/components/MajorSpinner';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { useFocusTextbookForTeacher } from 'ms-pages/Textbooks/TeacherTextbook/components/TeacherTextbookRedirector';
import { ASIDE_WIDTH } from 'ms-pages/Textbooks/components/TextbookTypesLayout/TextbookLayout';
import { colors } from 'ms-styles/colors';
import { useLocalSyncedState } from 'ms-utils/hooks/useSessionSyncedState';
import { useRecentlyUsedTextbooksForUser } from 'ms-utils/localStorageDb/syncRecentlyUsedTextbooks';
import useMutation from 'ms-utils/relay/useMutationWithArgs';
import { assertUnreachable, unwrap } from 'ms-utils/typescript-utils';

import type {
  TextbookTypeTabsQuery,
  TextbookTypeTabsQueryResponse,
} from './__generated__/TextbookTypeTabsQuery.graphql';
import type {
  TextbookTypeTabs_updateHasDefaultedToSkillsTabMutationResponse,
  TextbookTypeTabs_updateHasDefaultedToSkillsTabMutationVariables,
} from './__generated__/TextbookTypeTabs_updateHasDefaultedToSkillsTabMutation.graphql';

type TeacherBook = NonNullable<
  NonNullable<TextbookTypeTabsQueryResponse['school']>['availableSyllabuses']
>[number];
type StudentBook = NonNullable<
  NonNullable<
    NonNullable<TextbookTypeTabsQueryResponse['viewer']>['profile']
  >['availableSyllabuses']
>[number];
export type Book = TeacherBook & StudentBook;
export type TabValue = 'textbook' | 'skillsbook' | 'search';
type State = {
  selectedTab: TabValue;
  setSelectedTab: (tabValue: TabValue) => void;
  setSyllabusId: (syllabusId: string) => void;
  currentSyllabusId: string;
  availableTextbooks: readonly Book[];
  availableSkillsBooks: readonly Book[];
  tabList: readonly TabValue[];
  disableSkillsBookTab: boolean;
};
type Props = {
  children: ReactNode;
  selectedTab: TabValue;
  setSelectedTab: (tabValue: TabValue) => void;
  setSyllabusId: (syllabusId: string) => void;
  currentSyllabusId: string;
  disableSkillsBookTab?: boolean;
};
const TextbookTypeTabsContext = createContext<State>({
  selectedTab: 'textbook',
  setSelectedTab: () => {},
  currentSyllabusId: '',
  setSyllabusId: () => {},
  availableTextbooks: [],
  availableSkillsBooks: [],
  tabList: ['textbook'],
  disableSkillsBookTab: false,
});
export const useTextbookTypeTabsContext = () =>
  useContext(TextbookTypeTabsContext);
export function TextbookTypeTabsProvider(props: Props) {
  const { currentSyllabusId } = props;
  const { role } = useViewer();
  const { schoolId } = useMaybeTeacherContext();
  const {
    props: data,
    error,
    retry,
  } = useQuery<TextbookTypeTabsQuery>(
    graphql`
      query TextbookTypeTabsQuery(
        $schoolId: ID!
        $isTeacher: Boolean!
        $isStudent: Boolean!
        $currentSyllabusId: ID!
      ) {
        school(id: $schoolId) @include(if: $isTeacher) {
          availableSyllabuses {
            id
            title
            textbookType
          }
        }
        viewer @include(if: $isStudent) {
          profile {
            ... on Student {
              availableSyllabuses {
                id
                title
                textbookType
              }
            }
          }
        }
        currentSyllabus: syllabus(id: $currentSyllabusId) {
          textbookType
        }
      }
    `,
    {
      schoolId,
      isTeacher: role === 'Teacher',
      isStudent: role === 'Student',
      currentSyllabusId,
    },
  );
  return (
    <Suspense fallback={<MajorSpinner />}>
      {error != null && <Retry retry={retry} />}
      {data == null ? (
        <MinorSpinner scale={0.5} noPadding />
      ) : (
        <TextbookTypeTabsProviderInner {...props} data={data} />
      )}
    </Suspense>
  );
}
function TextbookTypeTabsProviderInner({
  children,
  selectedTab,
  setSelectedTab: _setSelectedTab,
  setSyllabusId,
  currentSyllabusId,
  disableSkillsBookTab = false,
  data,
}: Props & {
  data: TextbookTypeTabsQueryResponse;
}) {
  const {
    role,
    userId,
    featureFlags: { enableMTSInCATFA },
  } = useViewer();
  const availableSyllabuses = (() => {
    switch (role) {
      case 'Teacher':
        return data.school?.availableSyllabuses ?? [];
      case 'Student':
        return data.viewer?.profile?.availableSyllabuses ?? [];
      default:
        return [];
    }
  })();
  const availableTextbooks =
    role === 'Student'
      ? availableSyllabuses
      : availableSyllabuses.filter(
          syllabus => syllabus.textbookType !== 'SKILLS_BOOK',
        );
  const availableSkillsBooks = useMemo(() => {
    return role === 'Student'
      ? []
      : availableSyllabuses.filter(
          syllabus => syllabus.textbookType === 'SKILLS_BOOK',
        );
  }, [availableSyllabuses, role]);
  const tabValues: Record<TabValue, TabValue> = {
    textbook: 'textbook',
    skillsbook: 'skillsbook',
    search: 'search',
  };
  const tabList = [
    ...(availableTextbooks.length > 0 ? [tabValues.textbook] : []),
    ...(availableSkillsBooks.length > 0 ? [tabValues.skillsbook] : []),
    ...(enableMTSInCATFA ? [tabValues.search] : []),
  ];
  const recentlyUsedBooks = useRecentlyUsedTextbooksForUser(userId);
  const initialRecentBooks = useMemo(() => {
    return {
      textbookId:
        recentlyUsedBooks.find(book => {
          return availableTextbooks.some(textbook => textbook.id === book);
        }) ?? null,
      skillsbookId:
        recentlyUsedBooks.find(book => {
          return availableSkillsBooks.some(textbook => textbook.id === book);
        }) ?? null,
    };
  }, [availableSkillsBooks, availableTextbooks, recentlyUsedBooks]);
  const [mostRecentBooks, setMostRecentBooks] = useState<{
    textbookId: string | null;
    skillsbookId: string | null;
  }>(initialRecentBooks);
  useEffect(() => {
    if (selectedTab === 'textbook' || selectedTab === 'skillsbook') {
      const currentSyllabusType = data.currentSyllabus?.textbookType;
      if (currentSyllabusType === 'SKILLS_BOOK') {
        _setSelectedTab('skillsbook');
        setMostRecentBooks(prev => ({
          ...prev,
          skillsbookId: currentSyllabusId,
        }));
      } else {
        _setSelectedTab('textbook');
        setMostRecentBooks(prev => ({
          ...prev,
          textbookId: currentSyllabusId,
        }));
      }
    }
  }, [_setSelectedTab, currentSyllabusId, data, selectedTab]);
  const focusTextbookId = useFocusTextbookForTeacher();
  // we check that the focus textbook is not a skills book,
  // otherwise we have to default to the first available textbook.
  const defaultTextbook = availableSkillsBooks.some(
    ({ id }) => id === focusTextbookId,
  )
    ? availableTextbooks.length > 0
      ? unwrap(availableTextbooks[0]).id
      : undefined
    : focusTextbookId;
  const defaultSkillsBook = availableSkillsBooks?.[0]?.id;
  const setMostRecentSyllabusOnTabChange = useCallback<
    (tabValue: TabValue) => void
  >(
    tab => {
      const { textbookId, skillsbookId } = mostRecentBooks;
      const newBookId =
        tab === 'skillsbook'
          ? skillsbookId ?? defaultSkillsBook
          : textbookId ?? defaultTextbook;
      if (newBookId == null) {
        return;
      }
      setSyllabusId(newBookId);
    },
    [defaultSkillsBook, defaultTextbook, mostRecentBooks, setSyllabusId],
  );
  const setSelectedTab = useCallback<(tabValue: TabValue) => void>(
    tab => {
      _setSelectedTab(tab);
      if (tab === 'textbook' || tab === 'skillsbook') {
        setMostRecentSyllabusOnTabChange(tab);
      }
    },
    [_setSelectedTab, setMostRecentSyllabusOnTabChange],
  );
  return (
    <TextbookTypeTabsContext.Provider
      value={{
        tabList,
        selectedTab,
        setSelectedTab,
        setSyllabusId,
        currentSyllabusId,
        availableTextbooks,
        availableSkillsBooks,
        disableSkillsBookTab,
      }}
    >
      {children}
    </TextbookTypeTabsContext.Provider>
  );
}
function TabDisplay({
  tabValue,
  fontSize,
  iconSize,
}: {
  tabValue: TabValue;
  fontSize: number;
  iconSize: number;
}) {
  const [Icon, title] = (() => {
    switch (tabValue) {
      case 'textbook':
        return [TextbookTeacher, 'Textbooks'];
      case 'skillsbook':
        return [SkillsMap, 'Skills'];
      case 'search':
        return [Search, 'Search'];
      default: {
        assertUnreachable(tabValue);
      }
    }
  })();
  return (
    <>
      <Icon size={iconSize} />
      <div style={{ fontSize, lineHeight: `${fontSize}px`, ...fonts.bodyBold }}>
        {title}
      </div>
    </>
  );
}
function Tab({
  tabStyle,
  tabValue,
  fontSize,
  iconSize,
}: {
  tabStyle: CSSProperties;
  tabValue: TabValue;
  fontSize: number;
  iconSize: number;
}) {
  const selectedStyle = {
    color: colors.eggplant,
    borderColor: colors.eggplant,
  };
  const disabledStyle = {
    color: colors.grey10,
    cursor: 'default',
  };
  const { selectedTab, setSelectedTab, disableSkillsBookTab } =
    useTextbookTypeTabsContext();
  const selected = selectedTab === tabValue;
  const disabled = disableSkillsBookTab && tabValue === 'skillsbook';
  // We will swap back to the normal textbook in
  // cases where the skills book should be disabled
  useEffect(() => {
    if (disabled && selected) {
      setSelectedTab('textbook');
    }
  }, [disabled, selected, setSelectedTab]);
  return (
    <div
      style={{
        ...tabStyle,
        ...(disabled ? disabledStyle : {}),
        ...(selected ? selectedStyle : {}),
      }}
      onClick={() => {
        if (!disabled) setSelectedTab(tabValue);
      }}
    >
      <TabDisplay tabValue={tabValue} fontSize={fontSize} iconSize={iconSize} />
    </div>
  );
}
function BookTypeTabsWrapper({
  children,
  type,
}: {
  children: ReactNode;
  type: 'top' | 'left';
}) {
  const { tabList } = useTextbookTypeTabsContext();
  let relevantTabList =
    type === 'left' ? tabList : tabList.filter(t => t !== 'search');
  if (relevantTabList.length <= 1) {
    return null;
  }
  return <>{children}</>;
}
const BOOK_TYPE_TAB_TOP_FONT_SIZE = 16;
const BOOK_TYPE_TAB_TOP_ICON_SIZE = 16;
export function BookTypeTabsTop() {
  const {
    skillsBook: { shouldDefaultToSkillsTab },
  } = useViewer();
  const { selectedTab, tabList } = useTextbookTypeTabsContext();
  const [dismissed, setIsDismissed] = useLocalSyncedState({
    dbKey: 'NewFeature_SkillsTab_Top',
    fallbackValue: false,
  });
  const style: CSSProperties = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.grey90,
    padding: 8,
    cursor: 'pointer',
    borderColor: 'transparent',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    transform: 'translateY(1px)',
    height: 44,
  };
  // The inclusion of either the top or left version of the tabs is currently the main way
  // we can determine the difference between the textbook view and CATFA.
  // Since we only want to fire this mutation in the textbook view, we only include it here.
  const [recordHasDefaultedToSkillsTab, { loading }] = useMutation<
    TextbookTypeTabs_updateHasDefaultedToSkillsTabMutationResponse,
    TextbookTypeTabs_updateHasDefaultedToSkillsTabMutationVariables
  >({
    mutation: graphql`
      mutation TextbookTypeTabs_updateHasDefaultedToSkillsTabMutation {
        updateTeacherPreference(hasDefaultedToSkillsTab: true) {
          teacherPreference {
            hasDefaultedToSkillsTab
          }
          errors {
            key
            message
          }
        }
      }
    `,
  });
  useEffect(() => {
    if (!loading && selectedTab === 'skillsbook' && shouldDefaultToSkillsTab) {
      recordHasDefaultedToSkillsTab({});
    }
  }, [
    loading,
    recordHasDefaultedToSkillsTab,
    selectedTab,
    shouldDefaultToSkillsTab,
  ]);
  return (
    <BookTypeTabsWrapper type="top">
      <div
        style={{
          paddingTop: 8,
          paddingRight: 16,
          paddingLeft: 16,
        }}
      >
        <div
          style={{
            borderBottom: `solid 1px ${colors.ironLight}`,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              width: ASIDE_WIDTH - 2 * 16,
            }}
          >
            {tabList.includes('textbook') && (
              <Tab
                tabStyle={style}
                tabValue="textbook"
                fontSize={BOOK_TYPE_TAB_TOP_FONT_SIZE}
                iconSize={BOOK_TYPE_TAB_TOP_ICON_SIZE}
              />
            )}
            {tabList.includes('skillsbook') && (
              <div style={{ position: 'relative', flex: 1 }}>
                <Tab
                  tabStyle={style}
                  tabValue="skillsbook"
                  fontSize={BOOK_TYPE_TAB_TOP_FONT_SIZE}
                  iconSize={BOOK_TYPE_TAB_TOP_ICON_SIZE}
                />
                {!dismissed && (
                  <StudentDismissablePopover
                    relativePosition="bottom"
                    title="New skills view"
                    styles={{ zIndex: 2 }}
                    onDismiss={() => setIsDismissed(true)}
                  >
                    Browse questions by Standards
                  </StudentDismissablePopover>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </BookTypeTabsWrapper>
  );
}
export const BOOK_TYPE_TABS_WIDTH = 68;
const BOOK_TYPE_TAB_LEFT_FONT_SIZE = 10;
const BOOK_TYPE_TAB_LEFT_ICON_SIZE = 20;
export function BookTypeTabsLeft() {
  const [dismissed, setIsDismissed] = useLocalSyncedState({
    dbKey: 'NewFeature_SkillsTab_Left',
    fallbackValue: false,
  });
  const { tabList } = useTextbookTypeTabsContext();
  const style: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.grey90,
    cursor: 'pointer',
    padding: '12px 0',
    width: '100%',
    borderColor: 'transparent',
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
  };
  return (
    <BookTypeTabsWrapper type="left">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: 0,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          padding: '12px 0',
          borderRight: `solid 1px ${colors.ironLight}`,
          width: BOOK_TYPE_TABS_WIDTH,
          flexShrink: 0,
        }}
      >
        {tabList.includes('textbook') && (
          <Tab
            tabStyle={style}
            tabValue="textbook"
            iconSize={BOOK_TYPE_TAB_LEFT_ICON_SIZE}
            fontSize={BOOK_TYPE_TAB_LEFT_FONT_SIZE}
          />
        )}
        {tabList.includes('skillsbook') && (
          <div style={{ position: 'relative', width: '100%' }}>
            <Tab
              tabStyle={style}
              tabValue="skillsbook"
              iconSize={BOOK_TYPE_TAB_LEFT_ICON_SIZE}
              fontSize={BOOK_TYPE_TAB_LEFT_FONT_SIZE}
            />
            {!dismissed && (
              <StudentDismissablePopover
                relativePosition="right"
                title="New skills view"
                styles={{ zIndex: 2 }}
                onDismiss={() => setIsDismissed(true)}
              >
                Browse questions by Standards
              </StudentDismissablePopover>
            )}
          </div>
        )}
        {tabList.includes('search') && (
          <Tab
            tabStyle={style}
            tabValue="search"
            iconSize={BOOK_TYPE_TAB_LEFT_ICON_SIZE}
            fontSize={BOOK_TYPE_TAB_LEFT_FONT_SIZE}
          />
        )}
      </div>
    </BookTypeTabsWrapper>
  );
}
