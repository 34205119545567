/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TextbookSearchFilterSubtopicContentEnum = "HAS_ADAPTIVE_PRACTICE" | "HAS_ENGAGE_ACTIVITY" | "HAS_EXERCISE" | "HAS_LESSON" | "HAS_WORKSHEET";
export type MTSSearchResultsQueryVariables = {
    search: string;
    filterBySubtopicContent?: TextbookSearchFilterSubtopicContentEnum | null | undefined;
    syllabusIds?: Array<string> | null | undefined;
};
export type MTSSearchResultsQueryResponse = {
    readonly multiTextbookSearch: {
        readonly found: number;
        readonly searchQuery: string;
        readonly hits: ReadonlyArray<{
            readonly document: {
                readonly subtopicId: string;
                readonly topicId: string;
                readonly textbook: string;
                readonly syllabusId: string;
                readonly title: string;
                readonly topicBadgeUrl: string;
                readonly contentSnippet: string;
                readonly highlights: ReadonlyArray<{
                    readonly field: string;
                    readonly matchedTokens: ReadonlyArray<string>;
                    readonly snippet: string;
                }> | null;
            };
        }>;
    };
};
export type MTSSearchResultsQuery = {
    readonly response: MTSSearchResultsQueryResponse;
    readonly variables: MTSSearchResultsQueryVariables;
};



/*
query MTSSearchResultsQuery(
  $search: String!
  $filterBySubtopicContent: TextbookSearchFilterSubtopicContentEnum
  $syllabusIds: [ID!]
) {
  multiTextbookSearch(q: $search, filterSubtopicContent: $filterBySubtopicContent, syllabusIds: $syllabusIds) {
    found
    searchQuery
    hits {
      document {
        subtopicId
        topicId
        textbook
        syllabusId: textbookId
        title
        topicBadgeUrl
        contentSnippet
        highlights {
          field
          matchedTokens
          snippet
        }
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterBySubtopicContent"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "syllabusIds"
},
v3 = [
  {
    "kind": "Variable",
    "name": "filterSubtopicContent",
    "variableName": "filterBySubtopicContent"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "syllabusIds",
    "variableName": "syllabusIds"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "found",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "searchQuery",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtopicId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textbook",
  "storageKey": null
},
v9 = {
  "alias": "syllabusId",
  "args": null,
  "kind": "ScalarField",
  "name": "textbookId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicBadgeUrl",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentSnippet",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "DocumentHighlight",
  "kind": "LinkedField",
  "name": "highlights",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "matchedTokens",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "snippet",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MTSSearchResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MultitextbookSearchResult",
        "kind": "LinkedField",
        "name": "multiTextbookSearch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MultitextbookSearchHit",
            "kind": "LinkedField",
            "name": "hits",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubtopicDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "MTSSearchResultsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "MultitextbookSearchResult",
        "kind": "LinkedField",
        "name": "multiTextbookSearch",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MultitextbookSearchHit",
            "kind": "LinkedField",
            "name": "hits",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SubtopicDocument",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c0083aa59d2123a073f207b903ede9a",
    "id": null,
    "metadata": {},
    "name": "MTSSearchResultsQuery",
    "operationKind": "query",
    "text": "query MTSSearchResultsQuery(\n  $search: String!\n  $filterBySubtopicContent: TextbookSearchFilterSubtopicContentEnum\n  $syllabusIds: [ID!]\n) {\n  multiTextbookSearch(q: $search, filterSubtopicContent: $filterBySubtopicContent, syllabusIds: $syllabusIds) {\n    found\n    searchQuery\n    hits {\n      document {\n        subtopicId\n        topicId\n        textbook\n        syllabusId: textbookId\n        title\n        topicBadgeUrl\n        contentSnippet\n        highlights {\n          field\n          matchedTokens\n          snippet\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'abbda5f404a8d02d5645983ead936784';
export default node;
