import { useState, useEffect } from 'react';

import { SubtopicHeader } from 'ms-pages/Teacher/components/CreateTask/CreateBulkAdaptiveTask/MultiSubtopicDisplay/SubtopicHeader';
import SubtopicEmptyView from 'ms-pages/Teacher/components/CreateTask/components/SubtopicEmptyView';
import SubtopicProblems from 'ms-pages/Teacher/components/CreateTask/components/SubtopicSelector/SubtopicProblems';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

type Props = {
  selectedSubtopics: ReadonlyArray<string>;
  canCollectProblems?: boolean | undefined;
  hideExpandButton?: boolean | undefined;
  hideSubtopicHeader?: boolean | undefined;
  questionsCollapsed?: boolean | undefined;
  useTransientProblemsCollection?: boolean | undefined;
  hideDifficultyFilter?: boolean | undefined;
  isHeaderSticky?: boolean | undefined;
  stickyHeaderTopOffset?: number | undefined;
  hasSelfHorizontalPadding?: boolean | undefined;
};

export default function MultiSubtopicDisplay({
  selectedSubtopics,
  canCollectProblems,
  hideSubtopicHeader = false,
  hideExpandButton = false,
  questionsCollapsed,
  useTransientProblemsCollection = false,
  hideDifficultyFilter = false,
  isHeaderSticky,
  stickyHeaderTopOffset,
  hasSelfHorizontalPadding,
}: Props) {
  const [visibleSubtopicIndex, setVisibleSubtopicIndex] = useState(0);

  const numberOfSelectedSubtopics = selectedSubtopics.length;

  useEffect(() => {
    if (
      numberOfSelectedSubtopics > 0 &&
      visibleSubtopicIndex >= numberOfSelectedSubtopics
    ) {
      setVisibleSubtopicIndex(numberOfSelectedSubtopics - 1);
    }
  }, [numberOfSelectedSubtopics, visibleSubtopicIndex]);

  return (
    <>
      {selectedSubtopics.length > 0 ? (
        selectedSubtopics.map((subtopicId, index) => (
          <div
            data-test-id="selected-subtopic-item"
            key={subtopicId}
            style={{
              display: visibleSubtopicIndex !== index ? 'none' : 'block',
            }}
          >
            <SubtopicProblems
              canCollectProblems={canCollectProblems}
              hideExpandButton={hideExpandButton}
              questionsCollapsed={questionsCollapsed}
              useTransientProblemsCollection={useTransientProblemsCollection}
              hideDifficultyFilter={hideDifficultyFilter}
              isHeaderSticky={isHeaderSticky}
              stickyHeaderTopOffset={stickyHeaderTopOffset}
              hasSelfHorizontalPadding={hasSelfHorizontalPadding}
              renderAdaptiveSubtopicTitle={subtopic =>
                subtopic != null ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: hideSubtopicHeader ? 0 : 4 * BASE_UNIT,
                    }}
                  >
                    {!hideSubtopicHeader && (
                      <SubtopicHeader
                        subtopic={subtopic}
                        index={index}
                        totalCount={selectedSubtopics.length}
                        onBack={() =>
                          setVisibleSubtopicIndex(selected => selected - 1)
                        }
                        onNext={() =>
                          setVisibleSubtopicIndex(selected => selected + 1)
                        }
                      />
                    )}
                  </div>
                ) : null
              }
              subtopicId={subtopicId}
            />
          </div>
        ))
      ) : (
        <SubtopicEmptyView />
      )}
    </>
  );
}
