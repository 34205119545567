import styled from '@emotion/styled';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { useMemo, useState } from 'react';
import { graphql, useFragment } from 'relay-hooks';

import LockIcon from 'ms-components/icons/Lock2';
import WaypointsIcon from 'ms-components/icons/Waypoints';
import { useSnowplow } from 'ms-helpers/Snowplow';
import ChevronDownIcon from 'ms-pages/Lantern/components/icons/ChevronDown';
import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { Bold, BodyL, BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { getCheckInSkillUrl } from 'ms-pages/Lantern/utils/urls';
import { START_CHECK_IN_DISABLED_TOOLTIP_CONTENT } from 'ms-pages/Lantern/views/Student/CheckIn/utils';
import { AccordionItem } from 'ms-pages/Teacher/components/Accordion';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import InlinePopover from 'ms-ui-primitives/Popover/InlinePopover';
import { VStack, HStack, VSpacer, HSpacer } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import {
  mapSanaProficiencyValueToMasteryLevel,
  MASTERY_LEVEL_TITLES,
} from 'ms-utils/masteryLevel';
import { noop } from 'ms-utils/misc';

import OutcomeSubtopicRecommendationCard from './OutcomeSubtopicRecommendationCard';
import SkillsMapMasteryLevelsModal from './SkillsMapMasteryLevelsModal';
import Slider from './Slider';
import type {
  SkillPopover_outcome$key,
  SkillPopover_outcome$data,
} from './__generated__/SkillPopover_outcome.graphql';

type Props = {
  onDismiss: () => void;
  outcome: SkillPopover_outcome$key;
  isStrandLocked: boolean;
  proficiency: number | null | undefined;
  dataId: string;
};
const MAX_RECOMMENDED_SUBTOPICS_TO_SHOW = 4;
const WIDTH = 380;
const BASE_PADDING = 8;
const VERTICAL_PADDING = 3 * BASE_PADDING;
const LATERAL_PADDING = 3 * BASE_PADDING;
const TRIANGLE_POINTER_WIDTH = 40;
const TRIANGLE_POINTER_HEIGHT = 23;
const TOOLTIP_BACKGROUND_COLOR = colors.neutralGray;
// We need TRIANGLE_POINTER_OFFSET to position the triangle pointer because
// we need to hide base of the triangle svg which has rounded borders at each corner
const TRIANGLE_POINTER_OFFSET = 8;
const PopoverContentRoot = styled(VStack)({
  width: WIDTH,
  cursor: 'default', // reset the parent's style
  background: TOOLTIP_BACKGROUND_COLOR,
  borderRadius: 8,
  padding: `${VERTICAL_PADDING}px ${LATERAL_PADDING}px`,
  textAlign: 'left',
  position: 'relative',
});
const PopoverTrianglePointerWrapper = styled.div({
  position: 'absolute',
  width: TRIANGLE_POINTER_WIDTH,
  height: TRIANGLE_POINTER_HEIGHT,
  top: -TRIANGLE_POINTER_HEIGHT + TRIANGLE_POINTER_OFFSET,
  right: '50%',
  transform: 'translateX(50%)',
});
const triangleShape = (
  <svg
    width={TRIANGLE_POINTER_WIDTH}
    height={TRIANGLE_POINTER_HEIGHT}
    viewBox={`0 0 ${TRIANGLE_POINTER_WIDTH} ${TRIANGLE_POINTER_HEIGHT}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2858 1.50727C18.8184 0.0915567 21.1816 0.0915581 22.7142 1.50727L38.4699 16.0618C41.1441 18.5321 39.3962 23 35.7557 23H4.24427C0.603751 23 -1.14408 18.5321 1.53007 16.0618L17.2858 1.50727Z"
      fill={TOOLTIP_BACKGROUND_COLOR}
    />
  </svg>
);
const ChevronIconWrapper = styled.div<{
  isRotated: boolean;
}>(({ isRotated }) => ({
  transform: `rotate(${isRotated ? 180 : 0}deg)`,
  display: 'inline-flex', // ensure the wrapper has the size of the child icon
  // center vertically
  alignSelf: 'center',
  position: 'relative',
  top: 0.5,
}));
const Divider = styled.div({
  height: 1,
  backgroundColor: colors.grey90,
  margin: `0 -${LATERAL_PADDING}px`,
});
const SKILL_POPOVER_OUTCOME_FRAGMENT = graphql`
  fragment SkillPopover_outcome on LanternOutcome {
    id
    title
    code
    curriculum {
      id
    }
    skill {
      id
      description
      canStartCheckIn
      strand {
        id
      }
    }
    gradeStrand {
      grade {
        sanaTopicId
      }
    }
    subtopics {
      id
      hasAdaptivePractice
      ...OutcomeSubtopicRecommendationCard_subtopic
    }
  }
`;
export default function SkillPopover({
  onDismiss,
  outcome: outcomeRef,
  isStrandLocked,
  proficiency,
  dataId,
}: Props) {
  const { trackStructEvent } = useSnowplow();
  const outcome = useFragment(SKILL_POPOVER_OUTCOME_FRAGMENT, outcomeRef);
  const [isMasteryLevelsModalOpen, setMasteryLevelsModalOpen] = useState(false);
  const masteryLevel = mapSanaProficiencyValueToMasteryLevel(proficiency);
  const masteryLevelTitle =
    masteryLevel != null ? MASTERY_LEVEL_TITLES[masteryLevel] : null;
  const skillId = outcome.skill.id;
  const strandId = outcome.skill.strand.id;
  const canStartCheckIn = outcome.skill.canStartCheckIn;
  const checkInButton = (
    <AnchorButton
      size="lanternSmall"
      color="white"
      type="secondary"
      label="Start check-in"
      isBlock
      isDisabled={isStrandLocked || !canStartCheckIn}
      trackingId="StudentSkillsMap/SkillPopover/CheckIn"
      href={getCheckInSkillUrl({ skillId, strandId })}
      onClick={() => {
        trackStructEvent({
          category: 'student_skills_map_substrand',
          action: 'clicked_start_check_in',
          label: 'skill',
        });
      }}
    >
      <>
        {isStrandLocked ? <LockIcon /> : <WaypointsIcon />}
        <HSpacer width={BASE_PADDING} />
        Start check-in
      </>
    </AnchorButton>
  );
  return (
    <InlinePopover onDismiss={isMasteryLevelsModalOpen ? noop : onDismiss}>
      <PopoverContentRoot data-id={dataId}>
        <PopoverTrianglePointerWrapper>
          {triangleShape}
        </PopoverTrianglePointerWrapper>

        <HStack center>
          <BodyM color="white">Mastery:</BodyM>
          <HSpacer width={4} />
          {masteryLevelTitle != null && (
            <Button
              isInline
              size="regular"
              color="white"
              onClick={() => setMasteryLevelsModalOpen(true)}
            >
              {masteryLevelTitle}
            </Button>
          )}
        </HStack>

        <VSpacer height={BASE_PADDING} />

        <BodyM color="grey10">{outcome.skill.description}</BodyM>

        <VSpacer height={2 * BASE_PADDING} />

        <StandardCode outcome={outcome} />

        <VSpacer height={2 * BASE_PADDING} />

        {!canStartCheckIn ? (
          <Tooltip content={START_CHECK_IN_DISABLED_TOOLTIP_CONTENT}>
            <div>{checkInButton}</div>
          </Tooltip>
        ) : (
          checkInButton
        )}

        {outcome.subtopics.length > 0 && (
          <>
            <VSpacer height={3 * BASE_PADDING} />
            <Divider />
            <VSpacer height={3 * BASE_PADDING} />

            <BodyL color="white" style={{ textAlign: 'center' }}>
              <Bold>Recommended practice</Bold>
            </BodyL>

            <VSpacer height={2 * BASE_PADDING} />

            <Slider
              onChangeCallback={() => {
                trackStructEvent({
                  category: 'student_skills_map',
                  action: 'shuffled_recommendation',
                  label: 'skill_popover',
                });
              }}
              items={outcome.subtopics
                .slice(0, MAX_RECOMMENDED_SUBTOPICS_TO_SHOW)
                .map(subtopic => ({
                  key: subtopic.id,
                  node: (
                    <OutcomeSubtopicRecommendationCard subtopic={subtopic} />
                  ),
                }))}
            />
          </>
        )}
      </PopoverContentRoot>

      <SkillsMapMasteryLevelsModal
        isOpen={isMasteryLevelsModalOpen}
        onClose={() => setMasteryLevelsModalOpen(false)}
      />
    </InlinePopover>
  );
}
function StandardCode({ outcome }: { outcome: SkillPopover_outcome$data }) {
  const standardCodeHidden = useBoolean(true);
  const standardCodeToggleButtonLabel: string = useMemo(
    () =>
      `${standardCodeHidden.value ? 'Show' : 'Hide'}  ${i18n._(
        t`outcome`,
      )} code`,
    [standardCodeHidden.value],
  );
  return (
    <>
      <Button
        isInline
        color="grey10"
        size="lanternSmall"
        onClick={standardCodeHidden.toggle}
        label={standardCodeToggleButtonLabel}
      >
        <HStack>
          {standardCodeToggleButtonLabel}
          <HSpacer width={BASE_PADDING} />
          <ChevronIconWrapper isRotated={!standardCodeHidden.value}>
            <ChevronDownIcon size={12} color={colors.grey10} />
          </ChevronIconWrapper>
        </HStack>
      </Button>

      <AccordionItem isOpen={!standardCodeHidden.value}>
        <VSpacer height={BASE_PADDING} />
        <BodyM color="grey10">
          <Trans>Outcome</Trans>: {outcome.code}
        </BodyM>
      </AccordionItem>
    </>
  );
}
