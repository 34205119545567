import styled from '@emotion/styled';
import { useFragment, graphql } from 'relay-hooks';

import Tooltip from 'ms-pages/Lantern/primitives/Tooltip';
import { BodyL, BodyM, HeadingM } from 'ms-pages/Lantern/primitives/Typography';
import { getStudentSkillsUrl } from 'ms-pages/Lantern/utils/urls';
import useTemporaryLearningFocus from 'ms-pages/StudentDashboard/SunflowerStudentDashboard/useTemporaryLearningFocus';
import Button from 'ms-ui-primitives/Button';
import { HSpacer, HStack, VSpacer, VStack } from 'ms-ui-primitives/Stack';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import { CheckInButton } from './CheckInButton';
import { StudentSkillsMapSubstrandProficiencyIndicator } from './StudentSkillsMapSubstrandProficiencyIndicator';
import type { StudentSkillsMapSubstrandHeader_lanternStudent$key } from './__generated__/StudentSkillsMapSubstrandHeader_lanternStudent.graphql';
import type { StudentSkillsMapSubstrandHeader_studentStrandStatus$key } from './__generated__/StudentSkillsMapSubstrandHeader_studentStrandStatus.graphql';
import type { StudentSkillsMapSubstrandHeader_substrand$key } from './__generated__/StudentSkillsMapSubstrandHeader_substrand.graphql';
import ConfirmUpdateLearningFocusModal from '../ConfirmUpdateLearningFocusModal';
import learningFocusBadge from '../assets/learningFocusBadge.svg';
import learningFocusBadgeInactive from '../assets/learningFocusBadgeInactive.svg';

const LearningFocusBadgeImg = styled.img({
  height: 29,
  // visually align the image with the text (because of the extra div)
  verticalAlign: 'middle',
});
type Props = {
  strandId: string;
  studentStrandStatus: StudentSkillsMapSubstrandHeader_studentStrandStatus$key;
  substrand: StudentSkillsMapSubstrandHeader_substrand$key;
  lanternStudent: StudentSkillsMapSubstrandHeader_lanternStudent$key;
  hideSkillsMapLink?: boolean | undefined;
};
const STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_SUBSTRAND_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandHeader_substrand on Substrand {
    id
    title
    description
    canStartCheckIn
    ...StudentSkillsMapSubstrandProficiencyIndicator_substrand
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_STUDENTSTRANDSTATUS_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandHeader_studentStrandStatus on StudentStrandStatus {
    ...StudentSkillsMapSubstrandProficiencyIndicator_studentStrandStatus
    status
    strand {
      id
    }
    diagnosticQuestionsCompleted
    diagnosticNumberOfQuestions
    lastWorkedOnDiagnosticCheckIn {
      id
    }
  }
`;
const STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_LANTERN_STUDENT_FRAGMENT = graphql`
  fragment StudentSkillsMapSubstrandHeader_lanternStudent on LanternStudent {
    learningFocus {
      id
      title
    }
    ...StudentSkillsMapSubstrandProficiencyIndicator_student
  }
`;
export default function StudentSkillsMapSubstrandHeader({
  strandId,
  studentStrandStatus: studentStrandStatusRef,
  substrand: substrandRef,
  lanternStudent: lanternStudentRef,
  hideSkillsMapLink,
}: Props) {
  const temporaryLearningFocus = useTemporaryLearningFocus();
  const substrand = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_SUBSTRAND_FRAGMENT,
    substrandRef,
  );
  const studentStrandStatus = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_STUDENTSTRANDSTATUS_FRAGMENT,
    studentStrandStatusRef,
  );
  const lanternStudent = useFragment(
    STUDENT_SKILLS_MAP_SUBSTRAND_HEADER_LANTERN_STUDENT_FRAGMENT,
    lanternStudentRef,
  );
  const confirmUpdateLearningFocusModal = useBoolean();
  // We want to match the substrand which is used as the learning focus
  // on Sunflower Dashboard.
  // See the comment for `useTemporaryLearningFocus` hook for more context.
  const learningFocusId =
    lanternStudent.learningFocus != null
      ? lanternStudent.learningFocus.id
      : temporaryLearningFocus?.substrandId;
  return (
    <VStack>
      <HStack>
        {!hideSkillsMapLink && (
          <Button
            isInline
            onClick={() => window.location.assign(getStudentSkillsUrl({}))}
            size="lanternSmall"
          >
            Go to skills map
          </Button>
        )}
      </HStack>
      <VSpacer height={16} />
      <HStack>
        <VStack>
          <HStack>
            {substrand.id === learningFocusId ? (
              <>
                <LearningFocusBadgeImg
                  src={learningFocusBadge}
                  alt="skills focus"
                />
                <HSpacer width={12} />
              </>
            ) : (
              <>
                <Tooltip content="Make this my skills focus">
                  <div>
                    <Button
                      isInline
                      onClick={confirmUpdateLearningFocusModal.setTrue}
                      label="Make this my skills focus"
                    >
                      <LearningFocusBadgeImg
                        src={learningFocusBadgeInactive}
                        alt="skills focus inactive"
                      />
                    </Button>
                  </div>
                </Tooltip>
                <HSpacer width={12} />
              </>
            )}
            <ConfirmUpdateLearningFocusModal
              isOpen={confirmUpdateLearningFocusModal.value}
              onClose={confirmUpdateLearningFocusModal.setFalse}
              currentLearningFocusTitle={lanternStudent.learningFocus?.title}
              nextLearningFocusTitle={substrand.title}
              nextSubstrandId={substrand.id}
            />

            <HeadingM>{substrand.title}</HeadingM>
          </HStack>

          {substrand.description !== '' && (
            <>
              <VSpacer height={8} />
              <BodyL>{substrand.description}</BodyL>
            </>
          )}

          <VSpacer height={16} />

          {studentStrandStatus != null && (
            <HStack center wrap>
              <CheckInButton
                studentStrandStatus={studentStrandStatus}
                strandId={strandId}
                substrandId={substrand.id}
                canStartCheckIn={substrand.canStartCheckIn}
              />
              {studentStrandStatus.status === 'DIAGNOSTIC' &&
                studentStrandStatus.lastWorkedOnDiagnosticCheckIn != null && (
                  <>
                    <HSpacer width={16} />
                    <BodyM color="grey90">{`${studentStrandStatus.diagnosticQuestionsCompleted} of ${studentStrandStatus.diagnosticNumberOfQuestions} questions completed`}</BodyM>
                  </>
                )}
            </HStack>
          )}
        </VStack>
        <HSpacer width={50} grow />
        {studentStrandStatus != null && (
          <StudentSkillsMapSubstrandProficiencyIndicator
            student={lanternStudent}
            studentStrandStatus={studentStrandStatus}
            substrand={substrand}
          />
        )}
      </HStack>
    </VStack>
  );
}
