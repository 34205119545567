import { css, cx } from '@emotion/css';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';

import CaretBottomIcon from 'ms-components/icons/CaretBottom';
import CaretTopIcon from 'ms-components/icons/CaretTop';
import CaretTopAndBottom from 'ms-components/icons/CaretTopAndBottom';
import { colors, fonts, tappable } from 'ms-pages/Lantern/styles';
import {
  CaretSpace,
  CaretStyled,
} from 'ms-pages/Teacher/components/Table/components/Header';
import { fontWeight } from 'ms-styles/base';
import { onHover, onPressOrHover } from 'ms-utils/emotion';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

const CELL_PADDING = 16;
const SORT_ICON_SIZE = 14;

export const outcomesTableStyles = {
  table: {
    borderSpacing: 0,
    borderCollapse: 'collapse',
    color: colors.grey90,
    isolation: 'isolate',
    ...fonts.body,
  },
  thead: {
    verticalAlign: 'middle',
  },
  th: {
    padding: `8px ${2 * CELL_PADDING}px 8px 0`,
    color: colors.grey,
    ...fonts.featureBold,
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: 'left',
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
  },
  tr: css({
    position: 'relative', // for z-index to work
    backgroundColor: colors.white,
    ...tappable,
    transition: 'box-shadow .1s ease-in-out',
    ...onHover({
      // There is "position: sticky" on td elements in sticky columns
      // This is to make sure that the box shadow doesn't get cut off
      zIndex: 1,
      boxShadow: '0px 8.75px 49px rgba(33, 55, 77, 0.15)',
    }),
  }),
  td: {
    padding: `${CELL_PADDING}px ${2 * CELL_PADDING}px ${CELL_PADDING}px 0`,
    lineHeight: 1.375,
    borderBottom: `2px solid ${colors.almond}`,
    verticalAlign: 'middle',
  },
  centered: {
    textAlign: 'center',
  },
  headerActions: {
    display: 'flex',
    gap: 8,
  },
} as const;

type OutcomesTableHeaderProps = {
  children: ReactNode;
};

export function OutcomesTableHeader({ children }: OutcomesTableHeaderProps) {
  return (
    <div css={{ ...fonts.body, '> *': { marginRight: 8 } }}>{children}</div>
  );
}

type SortableHeaderCellProps = {
  label: string;
  onClick: (() => void) | undefined;
  sortDir: 'asc' | 'desc' | null;
  className?: string;
  styles?: {};
};

// TODO: This component is meant to be used only in skills report tables temporarily.
// We will be consolidating and replacing skills report tables (hopefully) soon
// with our Table components in local_modules/ms-pages/Teacher/components/Table
// When we do, instead of this component make sure to use
// HeaderCell and HeaderCellWithSorting from
// ms-pages/Teacher/components/Table/components/Header.tsx
// instead of this component for sortable headers.
export const SortableHeaderCell = forwardRef<
  HTMLTableCellElement,
  SortableHeaderCellProps
>(function SortableHeaderCell(
  { label, onClick, sortDir, className, styles },
  ref,
) {
  const isHovered = useBoolean();

  return (
    <th
      ref={ref}
      className={cx(
        sortableHeaderStyles.default,
        isHovered.value && sortableHeaderStyles.hovered,
        className,
      )}
      onClick={onClick}
      onMouseEnter={isHovered.setTrue}
      onMouseLeave={isHovered.setFalse}
      style={styles}
    >
      <div className={sortableHeaderStyles.contentWrapper}>
        {label}

        <CaretSpace>
          {sortDir == null && isHovered.value && (
            <CaretStyled>
              <CaretTopAndBottom size={SORT_ICON_SIZE} />
            </CaretStyled>
          )}
          {sortDir != null && (
            <CaretStyled>
              {sortDir === 'desc' ? (
                <CaretBottomIcon size={SORT_ICON_SIZE} />
              ) : (
                <CaretTopIcon size={SORT_ICON_SIZE} />
              )}
            </CaretStyled>
          )}
        </CaretSpace>
      </div>
    </th>
  );
});

const sortableHeaderStyles = {
  default: css(tappable),
  contentWrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  hovered: css({
    ...onPressOrHover({
      backgroundColor: colors.porcelain,
    }),
  }),
};

export const nameCellContentStyle = {
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'nowrap',
} as const;

export const nameStyle = {
  flexGrow: 1,

  'a, a:visited': {
    color: colors.grey,
  },
} as const;

export const tableWrapperStyle = {
  width: 'fit-content',
  padding: 32,
} as const;

export const stickyHeaderStyle = {
  position: 'sticky',
  backgroundColor: colors.white,
} as const;

export const columnHeaderStyle = {
  zIndex: 1,
};
export const stickyColumnHeaderStyle = {
  top: 0,
} as const;

export const nameRowHeaderStyle = {
  left: 0,
  paddingLeft: 32,
} as const;

export const nameCellModalLinkStyle = css({
  fontWeight: fontWeight.normal,
});
