import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M13.3356564,3.21717533 C14.3938647,4.12729741 14.9988049,5.42459942 14.9999991,6.78637993 C15.0006898,7.89497754 14.5964069,8.968911 13.8571421,9.82225511 C13.8552359,9.8222175 13.8533961,9.82292573 13.852048,9.82421632 C13.8506998,9.82550684 13.8499599,9.82726793 13.8499992,9.82909266 C13.7701359,9.92731232 13.6842716,10.0209224 13.5928564,10.1094325 C12.6488095,11.0548381 12.1284165,12.3161645 12.1428565,13.6239366 C12.1417149,14.0011109 11.8225861,14.3065995 11.4285709,14.3076923 L8.57142826,14.3076923 C8.177413,14.3065995 7.85828425,14.0011109 7.8571426,13.6239366 C7.85520975,13.2938164 7.82414689,12.9644425 7.76428547,12.6393285 C7.63532598,12.0041659 7.38077908,11.3984388 7.01433554,10.8547262 C6.8339477,10.5787013 6.62319057,10.321968 6.38567131,10.0889198 L6.38567131,10.0820823 C6.30719988,10.0068691 6.23571418,9.92481846 6.16428561,9.84276778 C6.15714275,9.83593022 6.15714275,9.82909266 6.1499999,9.82225511 C5.40771082,8.96743498 5.000917,7.89112472 5,6.77954237 C5.00039964,6.5230503 5.02192828,6.26698784 5.06438571,6.01373602 C5.43376975,3.9051011 7.21089716,2.2818531 9.42857104,2.02744046 C9.62075323,2.008111 9.81390586,1.99898029 10.0071424,2.00009023 C11.2359185,1.99666201 12.4221896,2.43042699 13.3356564,3.21717533 Z M10.4980736,4.05086438 C12.20104,4.34528377 12.9809908,5.19197189 12.9809908,6.70732608 C12.9809908,7.00138198 13.2230949,7.23976138 13.5217454,7.23976138 C13.8203959,7.23976138 14.0625,7.00138198 14.0625,6.70732608 C14.0625,4.65695852 12.8890157,3.38306378 10.6851117,3.00203919 C10.3909612,2.95118464 10.1106353,3.14474639 10.0589861,3.43437146 C10.0073369,3.72399654 10.2039231,4.00000982 10.4980736,4.05086438 Z M10.9442777,18 L9.08087421,18 C8.87376745,18 8.125,17.4489658 8.125,16.7692308 C8.125,16.0894958 8.125,15.5384615 8.125,15.5384615 L11.875,15.5384615 C11.875,15.5384615 11.875,16.0894958 11.875,16.7692308 C11.875,17.4489658 11.1513845,18 10.9442777,18 Z" />
);

const Investigation = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Investigation.displayName = 'Investigation';

export default Investigation;
