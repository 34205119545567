import type { ReactNode, ComponentType } from 'react';
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { SchoolContextProvider } from 'ms-pages/Teacher/TeacherContext/SchoolContext';
import TeacherContextListener from 'ms-pages/Teacher/TeacherContext/TeacherContextListener';
import type {
  Adapter,
  AdapterPayload,
} from 'ms-pages/Teacher/TeacherContext/adapters/types';
import {
  extractSchoolListFromResponse,
  extractTeacherIdFromResponse,
  extractTeacherPreferenceFromResponse,
} from 'ms-pages/Teacher/TeacherContext/helpers';
import type { TeacherContext as TeacherContextType } from 'ms-pages/Teacher/TeacherContext/types';
import { useTeacherContextData } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import MajorSpinner from 'ms-pages/Teacher/components/MajorSpinner';
import { InvariantViolation, Logger } from 'ms-utils/app-logging';

import type { useTeacherContextDataQueryResponse } from './__generated__/useTeacherContextDataQuery.graphql';

const initialContext: TeacherContextType = {
  schoolId: '',
  classId: '',
  // CALLBACKS
  setStateByClassId: () => {},
  setStateBySchoolId: () => {},
  // RESOURCES
  schoolList: [],
  teacherId: '',
  teacherPreference: {
    id: '',
    createTaskTargetMastery: 100,
    onboardingSetup: null,
    dismissedOnboardingFeatures: [],
  },
};
export const TeacherContext = createContext<TeacherContextType>(initialContext);
type ExternalProps = {
  // It is safe to assume that all pages will require teacher context before they can render.
  // if you need to provide a bespoke render page do so here.
  renderLoadingState?: () => ReactNode;
  adapters?: ReadonlyArray<Adapter> | undefined;
  children: ReactNode;
};
// this is a combination of the props provided by the data layer and the external props to the provider
type DataProps = {
  response: useTeacherContextDataQueryResponse;
  adapters?: ReadonlyArray<Adapter> | undefined;
  children: ReactNode;
};
function TeacherContextProviderInner({
  response,
  children,
  adapters: _adapters = [],
}: DataProps) {
  // This value should never change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const adapters = useMemo(() => _adapters, []);
  const schoolList = useMemo(
    () => extractSchoolListFromResponse(response),
    [response],
  );
  const teacherId = useMemo(
    () => extractTeacherIdFromResponse(response),
    [response],
  );
  const teacherPreference = useMemo(
    () => extractTeacherPreferenceFromResponse(response),
    [response],
  );
  if (schoolList.length === 0)
    throw new InvariantViolation(
      `User ${response?.viewer?.profile?.id} does not have any schools`,
    );
  const initializedAdapters: ReadonlyArray<ReturnType<Adapter>> = adapters.map(
    adapter => adapter(),
  );
  const firstClass: AdapterPayload = useMemo(() => {
    const school = schoolList[0];
    if (school == null) throw new InvariantViolation(`No schools returned`);
    const klass = school.classes?.edges[0]?.node;
    return {
      schoolId: school.id,
      classId: (klass || {}).id,
    };
  }, [schoolList]);
  const initialValue = initializedAdapters.reduce(
    (prev, curr) =>
      curr.getter({
        schoolList,
        teacherId,
        teacherPreference,
        classId: prev.classId,
        schoolId: prev.schoolId,
      }),
    firstClass,
  );
  const [classId, setClassId] = useState(initialValue.classId);
  const [schoolId, _setSchoolId] = useState(initialValue.schoolId);
  const setSchoolId = useCallback(
    (nextSchoolId: string) => {
      const school = schoolList.find(s => s.id === nextSchoolId);
      if (school == null)
        throw new InvariantViolation(
          `School ${nextSchoolId || ''} is not in this teacher's school list`,
        );
      _setSchoolId(nextSchoolId);
      const klass = school.classes?.edges[0]?.node;
      if (klass != null) {
        setClassId(klass.id);
      } else {
        // If the school has no classes we set set the class context to be null
        setClassId(null);
      }
    },
    [schoolList],
  );
  const listenerAdapters = initializedAdapters.filter(a => a.listener != null);
  for (const adapter of listenerAdapters) {
    // this is enforced by a check in React.memo below
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
      // eslint-disable-next-line consistent-return
      () => {
        if (adapter.listener != null) {
          return adapter.listener({
            context: {
              classId,
              schoolId,
              schoolList,
              teacherId,
              teacherPreference,
            },
            callback: ({ classId: nextClassId }) => setClassId(nextClassId),
          });
        }
        return;
      },
      [adapter, classId, schoolId, schoolList, teacherId, teacherPreference],
    );
  }
  // immediately call all of the setters once after we've synchronized everything
  useEffect(() => {
    initializedAdapters.forEach(adapter =>
      adapter.setter({
        classId,
        schoolId,
        schoolList,
        teacherId,
        teacherPreference,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TeacherContext.Provider
      value={{
        classId,
        schoolId,
        schoolList,
        teacherId,
        teacherPreference,
        setStateByClassId: setClassId,
        setStateBySchoolId: setSchoolId,
      }}
    >
      {initializedAdapters.map((adapter, i) => (
        <TeacherContextListener key={i} listener={adapter.setter} />
      ))}
      <SchoolContextProvider>{children}</SchoolContextProvider>
    </TeacherContext.Provider>
  );
}
// wraps the entire consumer in the data provider
function TeacherContextProvider({
  renderLoadingState,
  children,
  adapters,
}: ExternalProps) {
  const { props } = useTeacherContextData();

  if (props == null) {
    return renderLoadingState != null ? renderLoadingState() : <MajorSpinner />;
  }
  return (
    <TeacherContextProviderInner
      adapters={adapters}
      response={props}
      children={children}
    />
  );
}
// Added type `ComponentType` to fix error in generated Flow type
const memoizedTeacherContext: ComponentType<ExternalProps> =
  memo<ExternalProps>(
    TeacherContextProvider,
    (prevProps: ExternalProps, nextProps: ExternalProps) => {
      if (prevProps.adapters !== nextProps.adapters) {
        Logger.warn(
          `Adapters cannot change at runtime. Ensure that all adapters are staticly defined. The change in adapters will be ignored`,
        );
      }
      return true;
    },
  );
export default memoizedTeacherContext;
