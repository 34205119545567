import { colors } from 'ms-styles/colors';
import SearchInput from 'ms-ui-primitives/SearchInput';
import { noop } from 'ms-utils/misc';

import { SearchInputWrapper } from './modalSharedStyles';

const ModalSearchInput = ({
  searchString,
  onSearchStringUpdate,
}: {
  searchString: string;
  onSearchStringUpdate: (searchString: string) => void;
}) => (
  <SearchInputWrapper>
    <SearchInput
      type="external-control"
      searchString=""
      placeholder="Search for a folder"
      injectedSearchString={searchString}
      onSearchStringUpdate={onSearchStringUpdate}
      onSubmit={noop}
      searchIconOnLeft
      whiteBackground
      withBorder
      placeholderColor={colors.grey90}
    />
  </SearchInputWrapper>
);

export default ModalSearchInput;
