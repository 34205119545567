/**
 * Modal Links
 * ========================================================
 *
 * The modal is implemented via react router and relies on two particular features:
 *
 *  - `Switch` components, in addition to operating on the "current URL" also
 *    accept a location object
 *
 *  - `Routes` can be rendered anywhere. In our case we have two sets:
 *    - The first set are goverened by an explicit `location` object (not the current URL) and become
 *      the modal `background`
 *    - The second set govern the modal itself and should be activated when the `location.state.background`
 *      field (populated by Link functions within this file) is set. The group of routes represent
 *      the possible content of the modal and read their routes from the current URL.
 *
 * All functions within are essentially thin wrappers around React Router components Link, NavLink etc
 * that under certain circumstances will augment the current location state in order to facilitate
 * the mechanism which enables the modal
 */

import type { ReactNode, SyntheticEvent, ComponentType } from 'react';
import { useLocation } from 'react-router-dom';
import * as ReactRouter from 'react-router-dom';

import * as Tabs from 'ms-components/Tabs';

import * as Teacher from '../';
import type { LinkColor } from '../';

export type LocationShape = {
  pathname?: string;
  search?: string;
  hash?: string;
  state?: any;
  [prop: string]: any;
};

function parse(to: string): LocationShape {
  let pathname = to || '/';
  let search = '';
  let hash = '';
  let hashIndex = pathname.indexOf('#');

  if (hashIndex !== -1) {
    hash = pathname.substr(hashIndex);
    pathname = pathname.substr(0, hashIndex);
  }

  let searchIndex = pathname.indexOf('?');

  if (searchIndex !== -1) {
    search = pathname.substr(searchIndex);
    pathname = pathname.substr(0, searchIndex);
  }

  return {
    pathname,
    search: search === '?' ? '' : search,
    hash: hash === '#' ? '' : hash,
    state: {},
  };
}

function useModalLocation(
  to: string | LocationShape,
  enter: boolean,
): LocationShape {
  let location = useLocation<{ background?: any }>();
  let loc: LocationShape;

  loc = typeof to === 'string' ? parse(to) : Object.assign({ state: {} }, to);

  // Links provide two functions
  // 1) Opening modals - in which case we set the `location` which will act
  //    as the backdrop (see note at top of file)
  // 2) Navigating within a modal in which case we simply want to propagate
  //    an existing backdrop via location's `state` field so as to stay in the modal

  // Here we're only concerned with links that can open modals. Given
  // our link is of that type (`enter`), and we don't have a modal open already
  // - specified here as the absence of a `background` state property, then
  // we'll open the given `to` as a modal
  // Note: `state` could be non-existent too so we need to check that too
  if (enter && !location.state?.background) {
    loc.state.background = {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      origin: loc.pathname,
    };

    return loc;
  }

  // If not an "opening" link then we're preserving the current mode
  // which is either regular link functionality or if we're already in a modal
  // then open the next link in a modal too
  return {
    pathname: loc.pathname,
    search: loc.search,
    hash: loc.hash,
    state: location.state,
  } as LocationShape;
}

type RedirectProps = {
  to: string | LocationShape;
};

function Redirect({ to, ...props }: RedirectProps) {
  let _to = useModalLocation(to, false);

  return <ReactRouter.Redirect to={_to} {...props} />;
}

type LinkProps = {
  // `enter` describes a link which should open a modal. By
  // default links only navigate - preserving the in-use method.
  //   Without differentiating between navigating and opening/navigatng,
  // every link would also open a modal which is not what we want given
  // some routes should only be navigable within a modal and not act
  // as openers of the modal.
  enter?: boolean;
  // Regular React Router props
  to: string | LocationShape;
  exact?: boolean;
  replace?: boolean;
  component?: ComponentType<any> | undefined;
  isActive?: boolean;
  activeClassName?: string;
  children: ReactNode;
  style?: any;
  className?: string;
  onClick?: ((event: SyntheticEvent<HTMLElement>) => void) | undefined;
  color?: LinkColor | undefined;
};

function Link({ to, children, enter = false, ...props }: LinkProps) {
  let _to = useModalLocation(to, enter);

  return (
    <Teacher.Link to={_to} {...props}>
      {children}
    </Teacher.Link>
  );
}

export type NavLinkProps = {
  // `enter` describes a link which should open a modal. By
  // default links only navigate - preserving the in-use method.
  //   Without differentiating between navigating and opening/navigatng,
  // every link would also open a modal which is not what we want given
  // some routes should only be navigable within a modal and not act
  // as openers of the modal.
  enter?: boolean | undefined;
  // Regular React Router props
  to: string | LocationShape;
  exact?: boolean | undefined;
  isActive?: ((route: any) => boolean) | undefined;
  activeClassName: string;
  children: ReactNode;
  className: string;
};

function NavLink({
  to,
  children,
  enter = false,
  isActive,
  ...props
}: NavLinkProps) {
  let _to = useModalLocation(to, enter);

  // ReactRouter.NavLink types are missing | undefined just for the isActive prop
  // so we coerce the type to non-nullable here.
  const fixedIsActive = isActive!;

  return (
    <ReactRouter.NavLink to={_to} isActive={fixedIsActive} {...props}>
      {children}
    </ReactRouter.NavLink>
  );
}

type LinkTabProps = {
  to: string | LocationShape;
  children: ReactNode;
  active?: boolean;
  enter?: boolean;
  onClick?: ((event: SyntheticEvent<HTMLAnchorElement>) => void) | undefined;
  trackingId?: string | undefined;
};

function LinkTab({
  to,
  children,
  active = false,
  enter = false,
  ...props
}: LinkTabProps) {
  let _to = useModalLocation(to, enter);

  return (
    <Tabs.LinkTab to={_to} active={active} {...props}>
      {children}
    </Tabs.LinkTab>
  );
}

type NavLinkTabProps = {
  to: string | LocationShape;
  children: ReactNode;
  enter?: boolean;
};

function NavLinkTab({
  to,
  children,
  enter = false,
  ...props
}: NavLinkTabProps) {
  let _to = useModalLocation(to, enter);

  return (
    <Tabs.NavLinkTab to={_to} {...props}>
      {children}
    </Tabs.NavLinkTab>
  );
}

export { Link, LinkTab, NavLink, NavLinkTab, Redirect, useModalLocation };
