import { map } from 'ramda';
import * as React from 'react';
import { useMemo, createContext, useState } from 'react';

import type {
  State,
  Title,
  DisplayName,
  Teachers,
  Textbooks,
  SyllabusFocus,
  ClassGrade,
  ApplyClassSyllabusFocus,
  ApplyClassGrade,
  UseTitleForDisplayName,
  ShowLeaderboardToStudents,
} from './State';
import { initialState, EMPTY_SYLLABUS_FOCUS, EMPTY_CLASS_GRADE } from './State';
import * as updaters from './updaters';

export { EMPTY_SYLLABUS_FOCUS, EMPTY_CLASS_GRADE };

type Updaters = typeof updaters;
type CallbackUpdater<I extends any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};

const transformUpdaters = (
  setState: (cb: (state: State) => State) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export const StateContext = createContext<State>(initialState);

export const UpdatersContext = createContext<TransformedUpdaters>(
  transformUpdaters(() => {}),
);

type Props = {
  title?: Title;
  displayName?: DisplayName | null | undefined;
  teachers?: Teachers;
  textbooks?: Textbooks;
  syllabusFocus?: SyllabusFocus | null | undefined;
  classGrade?: ClassGrade | null | undefined;
  applyClassSyllabusFocus?: ApplyClassSyllabusFocus;
  applyClassGrade?: ApplyClassGrade;
  useTitleForDisplayName?: UseTitleForDisplayName;
  showLeaderboardToStudents?: ShowLeaderboardToStudents;
  children: React.ReactNode;
};

export function StateProvider({
  title,
  displayName,
  teachers,
  textbooks,
  syllabusFocus,
  classGrade,
  applyClassSyllabusFocus,
  applyClassGrade,
  useTitleForDisplayName,
  showLeaderboardToStudents,
  children,
}: Props) {
  const i = initialState; // aliasing for brevity
  const [state, setState] = useState<State>({
    title: title != null ? title : i.title,
    displayName: displayName != null ? displayName : i.displayName,
    teachers: teachers != null ? teachers : i.teachers,
    textbooks: textbooks != null ? textbooks : i.textbooks,
    syllabusFocus: syllabusFocus != null ? syllabusFocus : i.syllabusFocus,
    classGrade: classGrade != null ? classGrade : i.classGrade,
    applyClassSyllabusFocus:
      applyClassSyllabusFocus != null
        ? applyClassSyllabusFocus
        : i.applyClassSyllabusFocus,
    applyClassGrade:
      applyClassGrade != null ? applyClassGrade : i.applyClassGrade,
    useTitleForDisplayName:
      useTitleForDisplayName != null
        ? useTitleForDisplayName
        : i.useTitleForDisplayName,
    showLeaderboardToStudents:
      showLeaderboardToStudents != null
        ? showLeaderboardToStudents
        : i.showLeaderboardToStudents,
  });

  return (
    <StateContext.Provider value={state}>
      <UpdatersContext.Provider
        value={useMemo(() => transformUpdaters(setState), [])}
      >
        {children}
      </UpdatersContext.Provider>
    </StateContext.Provider>
  );
}
