import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Path = ({
  color,
  size = 16,
}: {
  color: string | undefined;
  size: string | number | undefined;
}) => {
  return (
    <>
      <g clipPath="url(#clip0_3100_13926)">
        <path
          d="M14.5714 14.2857C14.931 14.2857 15.2427 14.167 15.5076 13.9325L10.9745 9.39926C10.8658 9.47714 10.7603 9.55287 10.6608 9.62488C10.3215 9.87482 10.0462 10.0699 9.83479 10.2096C9.6234 10.3497 9.34215 10.4925 8.99107 10.6382C8.63975 10.7842 8.31245 10.8569 8.00883 10.8569H7.99995H7.99106C7.68741 10.8569 7.36011 10.7842 7.00882 10.6382C6.65754 10.4925 6.37628 10.3497 6.1651 10.2096C5.95371 10.0699 5.67853 9.87485 5.33912 9.62488C5.24454 9.55554 5.13964 9.47946 5.02624 9.39804L0.49231 13.9325C0.757207 14.167 1.06905 14.2857 1.42867 14.2857H14.5714Z"
          fill={color}
        />
        <path
          d="M0.901882 6.54471C0.562678 6.31856 0.261876 6.05956 0 5.76782V12.6648L3.99542 8.66934C3.19611 8.11132 2.16624 7.40392 0.901882 6.54471Z"
          fill={color}
        />
        <path
          d="M15.1072 6.54471C13.8911 7.36784 12.8574 8.07646 12.0062 8.67094L16 12.6649V5.76782C15.744 6.05369 15.4464 6.31252 15.1072 6.54471Z"
          fill={color}
        />
        <path
          d="M14.5713 1.71426H1.4286C0.970095 1.71426 0.617586 1.86909 0.370641 2.17844C0.123493 2.48792 0.00012207 2.87497 0.00012207 3.33914C0.00012207 3.71408 0.163842 4.12033 0.491136 4.55801C0.81843 4.99551 1.1667 5.33916 1.53576 5.58913C1.73809 5.73208 2.34823 6.15625 3.3662 6.8615C3.91571 7.2423 4.3936 7.57422 4.80426 7.86064C5.1543 8.10453 5.45618 8.31575 5.70542 8.49103C5.73403 8.5111 5.77903 8.54329 5.83875 8.58599C5.9031 8.63221 5.98452 8.69086 6.08497 8.76339C6.27841 8.90329 6.43911 9.01638 6.5671 9.10277C6.69491 9.18919 6.84977 9.28572 7.03144 9.39291C7.21297 9.49996 7.38418 9.58045 7.54488 9.63399C7.70561 9.6875 7.8544 9.71434 7.99128 9.71434H8.00017H8.00905C8.1459 9.71434 8.29472 9.6875 8.45548 9.63399C8.61615 9.58045 8.78722 9.50013 8.96889 9.39291C9.15039 9.28572 9.30504 9.18899 9.43327 9.10277C9.56126 9.01638 9.72195 8.90332 9.91542 8.76339C10.0157 8.69086 10.0971 8.63218 10.1614 8.58613C10.2212 8.54326 10.2662 8.51128 10.2949 8.49103C10.4891 8.35592 10.7917 8.14558 11.1985 7.86311C11.9387 7.3488 13.0289 6.59181 14.4735 5.58913C14.908 5.28565 15.271 4.91943 15.5627 4.49099C15.8539 4.06255 15.9999 3.61313 15.9999 3.14289C15.9999 2.75 15.8584 2.41382 15.5759 2.13382C15.2931 1.85416 14.9582 1.71426 14.5713 1.71426Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3100_13926">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </>
  );
};

const Mail = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color="none"
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxHeight={16}
    viewBoxWidth={16}
  >
    <Path color={color} size={size} />
  </Icon>
);

Mail.displayName = 'Mail';

export default Mail;
