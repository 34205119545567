import type { ReactNode } from 'react';
import { Component } from 'react';

import PageErrorView from 'ms-components/PageErrorView';
import { Logger } from 'ms-utils/app-logging';

type Props = {
  children: ReactNode;
};

type State = {
  error: Error | undefined;
};

class RootErrorBoundary extends Component<Props, State> {
  override state: State = { error: undefined };

  override componentDidCatch(error: Error) {
    Logger.error(error);
    this.setState(state => ({ ...state, error }));
  }

  override render() {
    if (this.state.error !== undefined) {
      return <PageErrorView />;
    }
    return this.props.children;
  }
}

export default RootErrorBoundary;
