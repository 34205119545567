/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapCurriculumStrandSubstrands_strand = {
    readonly id: string;
    readonly title: string;
    readonly substrands: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly badgeUrl: string;
        readonly gradeSubstrands: ReadonlyArray<{
            readonly id: string;
            readonly gradeStrand: {
                readonly grade: {
                    readonly id: string;
                    readonly order: number;
                };
            };
        }>;
    }>;
    readonly " $refType": "StudentSkillsMapCurriculumStrandSubstrands_strand";
};
export type StudentSkillsMapCurriculumStrandSubstrands_strand$data = StudentSkillsMapCurriculumStrandSubstrands_strand;
export type StudentSkillsMapCurriculumStrandSubstrands_strand$key = {
    readonly " $data"?: StudentSkillsMapCurriculumStrandSubstrands_strand$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapCurriculumStrandSubstrands_strand">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapCurriculumStrandSubstrands_strand",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Substrand",
      "kind": "LinkedField",
      "name": "substrands",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badgeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GradeSubstrand",
          "kind": "LinkedField",
          "name": "gradeSubstrands",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "GradeStrand",
              "kind": "LinkedField",
              "name": "gradeStrand",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LanternGrade",
                  "kind": "LinkedField",
                  "name": "grade",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Strand",
  "abstractKey": null
};
})();
(node as any).hash = '7abe628a68baf18de080287df3f985f8';
export default node;
