import { useEffect } from 'react';

import { useViewer } from 'ms-helpers/Viewer/Renderer';
import { getTextbookTheoryUrl } from 'ms-utils/urls';

const URL = `${getTextbookTheoryUrl({
  syllabusId: 'Syllabus-47',
  topicId: 'Topic-4217',
  subtopicId: 'Subtopic-106577',
})}&lessonSharingPopoverOpen=true`;

const URL_US = `${getTextbookTheoryUrl({
  syllabusId: 'Syllabus-227',
  topicId: 'Topic-5214',
  subtopicId: 'Subtopic-72797',
})}&lessonSharingPopoverOpen=true`;

export default function PromoteFeatureLessonSharingRedirector() {
  const { countryCode } = useViewer();

  useEffect(() => {
    if (countryCode === 'US') {
      window.history.replaceState(null, '', URL_US);
    } else {
      window.history.replaceState(null, '', URL);
    }
    window.location.reload();
  }, [countryCode]);

  return null;
}
