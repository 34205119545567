import { useEffect, useMemo, useRef, useState } from 'react';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import type { BulkCustomTaskStateFactory } from 'ms-pages/Teacher/components/CreateTask/CreateBulkCustomTask/state';
import CustomTaskDetailsForm from 'ms-pages/Teacher/components/CreateTask/CreateCustomTask';
import { FieldWrapper } from 'ms-pages/Teacher/components/CreateTask/components/FormComponents';
import type {
  TaskTemplateTuple,
  BulkCustomFlowProps,
} from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow';
import EmptyTemplatesWarning from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow/components/EmptyTemplatesWarning';
import { EmptyTemplatesWarningWrapper } from 'ms-pages/Teacher/components/CreateTask/flows/BulkCustomFlow/components/EmptyTemplatesWarning/EmptyTemplatesWarningWrapper';
import CheckboxUi from 'ms-ui-primitives/Checkbox/index';

type Props = {
  activeTemplate: TaskTemplateTuple;
  templates: BulkCustomFlowProps['taskTemplates'];
  templateGroupId: string;
  stateFactory: BulkCustomTaskStateFactory;
  onFormCompletionChange?: ((isCompleted: boolean) => void) | undefined;
  hasEmptyTemplates: boolean;
  setHasEmptyTemplates: React.Dispatch<React.SetStateAction<boolean>>;
};

export function MultipleCustomTaskDetailsForm({
  activeTemplate,
  templates,
  templateGroupId,
  stateFactory,
  onFormCompletionChange,
  hasEmptyTemplates,
  setHasEmptyTemplates,
}: Props) {
  const [useSameDetailsForAllTasks, setUseSameDetailsForAllTasks] =
    useState(true);

  const bulkState = useMemo(() => {
    return templates.map(template => {
      return stateFactory(template[0], {
        taskName: template[1],
        templateId: template[0],
        edited: false,
      });
    });
  }, [templates, stateFactory]);

  const activeTemplateState = useMemo(() => {
    return bulkState.length > 0
      ? bulkState.find(state => state.values.templateId === activeTemplate[0])
      : null;
  }, [activeTemplate, bulkState]);

  useEffect(() => {
    if (onFormCompletionChange != null) {
      const areFormsComplete =
        templates.length > 0 &&
        activeTemplateState != null &&
        bulkState.every(templateState => {
          return (
            templateState.values.taskName != null &&
            templateState.values.taskName.length > 0 &&
            (templateState.values.selectedClasses.length > 0 ||
              templateState.values.selectedStudents.length > 0)
          );
        });

      onFormCompletionChange(areFormsComplete);
    }
  }, [
    activeTemplateState,
    bulkState,
    onFormCompletionChange,
    templates.length,
  ]);

  // This is for keeping the previous state of the active template
  // so that we can use it to stop infinite loops when we update
  // the other templates with the same details
  // We only set this to the current active template state in the below useEffect
  const activeTemplateStateRef = useRef<
    ReturnType<BulkCustomTaskStateFactory> | null | undefined
  >(null);

  const templateIds = useMemo(() => templates.map(([id]) => id), [templates]);

  // This is for updating the other subtopics with the same details
  useEffect(() => {
    if (
      useSameDetailsForAllTasks &&
      activeTemplateState != null &&
      bulkState != null &&
      bulkState.length > 1 &&
      activeTemplateStateRef.current != null &&
      JSON.stringify(activeTemplateStateRef.current.values) !==
        JSON.stringify(activeTemplateState.values)
    ) {
      bulkState.forEach(state => {
        if (state.values.templateId !== activeTemplateState.values.templateId) {
          state.updaters.updateTaskStartDate(
            activeTemplateState.values.taskStartDate,
          );
          state.updaters.updateTaskDueDate(
            activeTemplateState.values.taskDueDate,
          );
          state.updaters.setSelectedClasses(
            activeTemplateState.values.selectedClasses,
          );
          state.updaters.setSelectedStudents(
            activeTemplateState.values.selectedStudents,
          );
          state.updaters.setExcludedStudents(
            activeTemplateState.values.excludedStudents,
          );
          state.updaters.updateExtensionDays(
            activeTemplateState.values.extensionDays,
          );
          state.updaters.updateHideHelpOptions(
            activeTemplateState.values.hideHelpOptions,
          );
          state.updaters.updateHideCalculator(
            activeTemplateState.values.hideCalculator,
          );
          state.updaters.updateQuestionVariation(
            activeTemplateState.values.questionVariation,
          );
        }
      });
      activeTemplateStateRef.current = activeTemplateState;
    }
  }, [activeTemplateState, bulkState, useSameDetailsForAllTasks]);

  // Sets the initial value of the activeTemplateStateRef only when it's null
  useEffect(() => {
    if (activeTemplateStateRef.current == null) {
      activeTemplateStateRef.current = activeTemplateState;
    }
  }, [activeTemplateState]);

  return (
    <>
      <ErrorBoundary
        name="CATFA:BulkCustomFlow:EmptyTemplatesWarning"
        fallback={
          <EmptyTemplatesWarningWrapper message="Could not confirm all templates are non-empty" />
        }
      >
        <EmptyTemplatesWarning
          templateIds={templateIds}
          templateGroupId={templateGroupId}
          hasEmptyTemplates={hasEmptyTemplates}
          setHasEmptyTemplates={setHasEmptyTemplates}
        />
      </ErrorBoundary>
      {templates.length > 1 && (
        <FieldWrapper>
          <CheckboxUi
            checked={useSameDetailsForAllTasks}
            onChange={() => setUseSameDetailsForAllTasks(prev => !prev)}
            label="Use same details for all tasks (excluding title)"
          />
        </FieldWrapper>
      )}
      {activeTemplateState && (
        <CustomTaskDetailsForm
          state={activeTemplateState}
          showSaveAsTemplateOption={false}
        />
      )}
    </>
  );
}
