import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { unwrap } from 'ms-utils/typescript-utils';

// We need to dynamically switch the effect hook depending on SSR
const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
    ? useLayoutEffect
    : useEffect;

type Ref<T> = { current: T };

// Produces the previous *committed* value of `value`.
// Main use case is for detecting when a particular state transition has occurred.
function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
// Produces the previous non null value of `value`.
function usePreviousNonNull<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);
  useEffect(() => {
    if (value != null) ref.current = value;
  }, [value]);
  return ref.current;
}

// Observes the element provided in the `ref` and returns the *content box*
// dimensions for that element whenever they change.
// Warning: Changes to the *border box* are not detectable by ResizeObservers
function useObservedRect(ref: Ref<Element | null | undefined>): {
  width: number;
  height: number;
} {
  const [rect, setRect] = useState({ width: 0, height: 0 });
  // useLayoutEffect is very intentional: we want to synchronously re-render with
  // the initial values of the rect before the browser has a chance to paint.
  useIsomorphicLayoutEffect(() => {
    if (ref.current == null) return () => {};
    const ro = new ResizeObserver(entries => {
      const entry = unwrap(entries[0]);
      const { width, height } = entry.target.getBoundingClientRect();
      setRect(r => {
        if (r.width === width && r.height === height) return r;
        return { width, height };
      });
    });
    ro.observe(ref.current);
    return () => {
      ro.disconnect();
    };
  });
  return rect;
}

export { usePrevious, usePreviousNonNull, useObservedRect };
