import ChevronLeft from 'ms-components/icons/ChevronLeft';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled, tappable } from 'ms-utils/emotion';

const LATERAL_PADDING = 6 * BASE_UNIT;

const HEADER_TEXT_SIZE = fontSize.medium;

export const FlyoutHeaderWrapper = styled({
  fontFamily: fontFamily.body,
  background: colors.white,
  paddingRight: LATERAL_PADDING,
  paddingLeft: LATERAL_PADDING,
  borderBottom: `1px solid ${colors.porcelain}`,
  height: 55,
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  boxShadow: '0 0 2px 0 rgba(0,0,0,0.12)',
});

export const FlyoutHeaderTitle = styled({
  fontWeight: fontWeight.semibold,
  color: colors.cloudBurst,
  fontSize: HEADER_TEXT_SIZE,
});

export const TappableWrapper = styled({
  ...tappable,
  display: 'flex',
  alignItems: 'center',
});

type Props = {
  title: string;
};

export default function Header({ title }: Props) {
  return (
    <FlyoutHeaderWrapper>
      <FlyoutHeaderTitle>{title}</FlyoutHeaderTitle>
    </FlyoutHeaderWrapper>
  );
}

type BackHeaderProps = Props & {
  onClick: () => void;
};

const ChevronWrapper = styled({
  paddingRight: 2 * BASE_UNIT,
});

export function BackHeader({ title, onClick }: BackHeaderProps) {
  return (
    <FlyoutHeaderWrapper>
      <TappableWrapper onClick={onClick}>
        <ChevronWrapper>
          <ChevronLeft color={colors.eggplant} size={HEADER_TEXT_SIZE} />
        </ChevronWrapper>
        <FlyoutHeaderTitle>{title}</FlyoutHeaderTitle>
      </TappableWrapper>
    </FlyoutHeaderWrapper>
  );
}
