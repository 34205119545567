import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const FreehandDraw = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={30}
    viewBoxHeight={30}
  >
    <path
      fill="transparent"
      stroke={color}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 22.5 Q 6 7.5 10.5 15 Q 15 22.5 25 5"
    ></path>
  </Icon>
);

FreehandDraw.displayName = 'FreehandDraw';

export default FreehandDraw;
