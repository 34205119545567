import { graphql, useMutation } from 'react-relay';

import type { sendFeedbackMutation } from './__generated__/sendFeedbackMutation.graphql';

export function useSendFeedbackMutation() {
  return useMutation<sendFeedbackMutation>(graphql`
    mutation sendFeedbackMutation($feedback: FeedbackInput!) {
      submitFeedback(feedback: $feedback) {
        feedback {
          publicIssueNumber
        }
        errors {
          key
          message
        }
      }
    }
  `);
}
