import styled from '@emotion/styled';
import { useState } from 'react';

import CrossIcon from 'ms-pages/Lantern/components/icons/Cross';
import StopIcon from 'ms-pages/Lantern/components/icons/Stop';
import { HStack, HSpacer } from 'ms-pages/Lantern/primitives/Stack';
import { HeadingS } from 'ms-pages/Lantern/primitives/Typography';
import { colors, tappable } from 'ms-pages/Lantern/styles';

const COLOR = colors.almond50;

const ErrorMessageRoot = styled(HStack)({
  display: 'flex',
  position: 'absolute',
  transform: 'translateY(-100%)',
  top: -20,
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 20px',
  background: colors.grey,
  borderRadius: 8,
  color: COLOR,
});

const ColorFix = styled.div({
  color: COLOR,
});

const IconButton = styled.div({
  ...tappable,
});

function ErrorMessage({ text }: { text: string }) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen && (
        <ErrorMessageRoot>
          <StopIcon size={40} />
          <HSpacer width={16} />
          <HeadingS>
            <ColorFix>
              {/* TODO: Decide what to do with colours for typography primitives */}
              {text}
            </ColorFix>
          </HeadingS>
          <HSpacer width={16} />
          <IconButton onClick={() => setIsOpen(false)}>
            <CrossIcon size={16} />
          </IconButton>
        </ErrorMessageRoot>
      )}
    </>
  );
}

export { ErrorMessage };
