import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Dictionary = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={32}
    viewBoxHeight={32}
  >
    <g clipPath="url(#clip0)">
      <path d="M13.0791 12.8562H14.5456L13.7988 11.1843L13.0791 12.8562Z" />
      <path d="M25.9281 0.0390625H9.30811V31.9609H25.9281C27.4751 31.9609 28.7338 30.7022 28.7338 29.1553V2.84469C28.7338 1.2977 27.4751 0.0390625 25.9281 0.0390625ZM16.894 11.8901C16.894 11.3736 17.3127 10.9549 17.8292 10.9549H19.2426C19.7589 10.9549 20.1778 11.3736 20.1778 11.8901C20.1778 12.4064 19.7589 12.8253 19.2426 12.8253H17.8292C17.3127 12.8253 16.894 12.4064 16.894 11.8901ZM16.8747 15.7788C16.4032 15.9893 15.8503 15.7779 15.6395 15.3062L15.3809 14.7267H12.2743L12.03 15.2945C11.8776 15.6485 11.5327 15.8601 11.1705 15.8601C11.047 15.8601 10.9217 15.8355 10.8013 15.7837C10.3269 15.5795 10.1077 15.0295 10.3119 14.5551L12.9241 8.48556C13.0708 8.14441 13.4053 7.92249 13.7767 7.92015H13.7831C14.1517 7.92015 14.4864 8.13681 14.6369 8.47387L17.3476 14.5436C17.558 15.0151 17.3466 15.5682 16.8747 15.7788ZM19.957 14.3934L23.1821 9.7859H20.531C20.0143 9.7859 19.5958 9.367 19.5958 8.85069C19.5958 8.33418 20.0143 7.91548 20.531 7.91548H24.9785C25.3271 7.91548 25.6468 8.10934 25.8079 8.41874C25.9691 8.72794 25.9447 9.10124 25.7448 9.38707L22.5195 13.9941H25.0459C25.5624 13.9941 25.9811 14.413 25.9811 14.9294C25.9811 15.4459 25.5624 15.8646 25.0459 15.8646H20.7233C20.3746 15.8646 20.0548 15.6707 19.8939 15.3613C19.7328 15.0521 19.7571 14.679 19.957 14.3934Z" />
      <path d="M7.43767 0.0390625H5.94796C4.40097 0.0390625 3.14233 1.2977 3.14233 2.84469V29.1553C3.14233 30.7022 4.40097 31.9609 5.94796 31.9609H7.43767V0.0390625Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="31.9218"
          height="31.9218"
          fill="white"
          transform="translate(0 0.0390625)"
        />
      </clipPath>
    </defs>
  </Icon>
);

Dictionary.displayName = 'Dictionary';

export default Dictionary;
