/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BackgroundEnum = "ABSTRACT_FORESTGREEN" | "ABSTRACT_LOCHMARA" | "ABSTRACT_LOCHMARADARK" | "ABSTRACT_MULTICOLOURDOTS" | "ABSTRACT_PINKBLOBS" | "ABSTRACT_PINKGREY" | "BACKGROUND_DEFAULT" | "CAIRO_EGYPT" | "CHRISTMAS_LIGHT" | "FANTASY_CINDERELLA" | "GRADIENT_CLAY" | "GRADIENT_LIGHTBLUE" | "GRADIENT_PEACH" | "HOLIDAY_APRIL_FOOLS_DAY" | "HOLIDAY_EARTH_DAY" | "HOLIDAY_EASTER" | "HOLIDAY_EID_AL_FITR" | "HOLIDAY_HALLOWEEN" | "HOLIDAY_HANUKKAH" | "HOLIDAY_OLYMPICS_2024" | "HOLIDAY_PI_DAY" | "HOLIDAY_RAMADAN" | "HOLIDAY_SAINT_PATRICKS_DAY" | "HOLIDAY_TALK_LIKE_A_PIRATE_DAY" | "HOLIDAY_THANKSGIVING" | "HOLIDAY_YOUTH_DAY" | "INDOORS_LIBRARY" | "LANDSCAPE_BEACH" | "LANDSCAPE_BOAT" | "LUNAR_NEW_YEAR" | "SEASCAPE_SHARK" | "TOKYO_STREETS";
export type HowToUnlock = "COMPLETE_A_DISCOVERY_CHECKIN" | "COMPLETE_CLASS_MONTHLY_EXPEDITION_CHALLENGE_AND_COLLECT";
export type MonthlyExpeditionUnlockCairoEgyptBackgroundMutationVariables = {};
export type MonthlyExpeditionUnlockCairoEgyptBackgroundMutationResponse = {
    readonly updateStudentPreference: {
        readonly studentPreference: {
            readonly hasUnlockedCairoEgyptBackground: boolean;
            readonly availableDashboardBackgrounds: ReadonlyArray<{
                readonly name: BackgroundEnum;
                readonly url: string;
                readonly type: string;
                readonly isUnlocked: boolean;
                readonly howToUnlock: HowToUnlock | null;
            }>;
        } | null;
    };
};
export type MonthlyExpeditionUnlockCairoEgyptBackgroundMutation = {
    readonly response: MonthlyExpeditionUnlockCairoEgyptBackgroundMutationResponse;
    readonly variables: MonthlyExpeditionUnlockCairoEgyptBackgroundMutationVariables;
};



/*
mutation MonthlyExpeditionUnlockCairoEgyptBackgroundMutation {
  updateStudentPreference(hasUnlockedCairoEgyptBackground: true) {
    studentPreference {
      hasUnlockedCairoEgyptBackground
      availableDashboardBackgrounds {
        name
        url
        type
        isUnlocked
        howToUnlock
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "hasUnlockedCairoEgyptBackground",
    "value": true
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasUnlockedCairoEgyptBackground",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "DashboardBackground",
  "kind": "LinkedField",
  "name": "availableDashboardBackgrounds",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "howToUnlock",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MonthlyExpeditionUnlockCairoEgyptBackgroundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "UpdateStudentPreferenceResult",
        "kind": "LinkedField",
        "name": "updateStudentPreference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentPreference",
            "kind": "LinkedField",
            "name": "studentPreference",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "updateStudentPreference(hasUnlockedCairoEgyptBackground:true)"
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MonthlyExpeditionUnlockCairoEgyptBackgroundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "UpdateStudentPreferenceResult",
        "kind": "LinkedField",
        "name": "updateStudentPreference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StudentPreference",
            "kind": "LinkedField",
            "name": "studentPreference",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "updateStudentPreference(hasUnlockedCairoEgyptBackground:true)"
      }
    ]
  },
  "params": {
    "cacheID": "2b57d8998463ed0485d1a89973a8e418",
    "id": null,
    "metadata": {},
    "name": "MonthlyExpeditionUnlockCairoEgyptBackgroundMutation",
    "operationKind": "mutation",
    "text": "mutation MonthlyExpeditionUnlockCairoEgyptBackgroundMutation {\n  updateStudentPreference(hasUnlockedCairoEgyptBackground: true) {\n    studentPreference {\n      hasUnlockedCairoEgyptBackground\n      availableDashboardBackgrounds {\n        name\n        url\n        type\n        isUnlocked\n        howToUnlock\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6412b05dbf9e09bce70565023e4f6641';
export default node;
