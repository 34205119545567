import { StyleSheet } from 'aphrodite';

import {
  breakPoints,
  font,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  transition,
} from 'ms-styles/base';
import { colors, alternateColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { onHover } from 'ms-utils/emotion';

const formWidth = 430;

export const formStyles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: fontFamily.body,
    lineHeight: lineHeight.body,
    minWidth: 0,
    minHeight: 420,
    marginTop: 40,
  },
  titleContainer: {
    textAlign: 'center',
  },
  title: {
    ...font.gilroyExtraLargeHeading,
    color: colors.neutralGray,
    marginBottom: 32,
  },
  subtitle: {
    color: colors.neutralGray,
    fontSize: fontSize.small,
    marginBottom: 32,
    maxWidth: formWidth,
  },
  strong: {
    fontWeight: fontWeight.semibold,
  },
  classCodeContainer: {
    maxWidth: '100%',
    width: formWidth,
    marginTop: 25,
  },
  tabContainer: {
    maxWidth: '100%',
    width: formWidth,
    marginTop: 3 * BASE_UNIT,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: formWidth,
    marginTop: 8 * BASE_UNIT,
  },
  formError: {
    color: colors.cinnabar,
    fontFamily: fontFamily.body,
    fontSize: fontSize.errorMessage,
    textAlign: 'center',
    lineHeight: lineHeight.body,
    marginBottom: 5 * BASE_UNIT,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    [`@media (min-width: ${breakPoints.small}px)`]: {
      flexDirection: 'row',
    },
  },
  formRowField: {
    [`@media (min-width: ${breakPoints.small}px)`]: {
      flexBasis: '48%',
    },
  },
  formRowLink: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
    marginTop: 4 * BASE_UNIT,
    textAlign: 'right',
  },
  forgotPasswordLink: {
    marginTop: 2 * BASE_UNIT,
    textAlign: 'right',
  },
  disclaimer: {
    fontSize: fontSize.small,
    color: colors.neutralGray,
    lineHeight: lineHeight.body,
    margin: 'auto',
    marginTop: 34,
    maxWidth: 320,
    textAlign: 'center',
  },
  actionWrapper: {
    marginTop: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoIcon: {
    fontSize: 15,
  },
  linkStyle: {
    cursor: 'pointer',
    textDecoration: 'none',
    transition: `color ${transition}`,
    fontFamily: fontFamily.body,
    color: colors.eggplant,
    ...onHover({
      color: alternateColors.eggplant,
    }),
  },
});
