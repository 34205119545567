import { graphql } from 'react-relay';

import useMutation from 'ms-pages/Lantern/utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-pages/Lantern/utils/relay/useMutation';

import type {
  useNewStyleSubmitCorrectAnswerMutationResponse,
  useNewStyleSubmitCorrectAnswerMutationVariables,
} from './__generated__/useNewStyleSubmitCorrectAnswerMutation.graphql';

const mutation = graphql`
  mutation useNewStyleSubmitCorrectAnswerMutation(
    $checkInId: ID!
    $questionId: ID!
    $strandId: ID!
    $includeStrandStatus: Boolean!
  ) {
    newStyleSubmitCorrectAnswer(
      checkInId: $checkInId
      questionId: $questionId
    ) {
      questionAnswer {
        ...CheckInSessionQuestionResult @relay(mask: false)
        ...CheckInSessionQuestionAnswerStrandStatus
          @arguments(
            strandId: $strandId
            includeStrandStatus: $includeStrandStatus
          )
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useNewStyleSubmitCorrectAnswer(): UseMutationResponse<
  useNewStyleSubmitCorrectAnswerMutationResponse,
  useNewStyleSubmitCorrectAnswerMutationVariables
> {
  return useMutation({
    mutation,
  });
}
