/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TeacherContextLanternQueryVariables = {
    classId: string;
};
export type TeacherContextLanternQueryResponse = {
    readonly lantern: {
        readonly viewer: {
            readonly __typename: string;
        } | null;
    };
    readonly class: {
        readonly classGrade: {
            readonly id: string;
        } | null;
        readonly school: {
            readonly lanternCurriculum: {
                readonly " $fragmentRefs": FragmentRefs<"TeacherContext_lanternCurriculum">;
            } | null;
        };
    } | null;
};
export type TeacherContextLanternQuery = {
    readonly response: TeacherContextLanternQueryResponse;
    readonly variables: TeacherContextLanternQueryVariables;
};



/*
query TeacherContextLanternQuery(
  $classId: ID!
) {
  lantern {
    viewer {
      __typename
      id
    }
  }
  class(id: $classId) {
    classGrade {
      id
    }
    school {
      lanternCurriculum {
        ...TeacherContext_lanternCurriculum
        id
      }
      id
    }
    id
  }
}

fragment TeacherContext_lanternCurriculum on LanternCurriculum {
  id
  sanaCatalogId
  title
  strands {
    id
    title
    substrands {
      id
      title
    }
  }
  grades {
    id
  }
  country {
    code
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "classId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "classId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "LanternGrade",
  "kind": "LinkedField",
  "name": "classGrade",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherContextLanternQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Class",
        "kind": "LinkedField",
        "name": "class",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "School",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "lanternCurriculum",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TeacherContext_lanternCurriculum"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeacherContextLanternQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Class",
        "kind": "LinkedField",
        "name": "class",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "School",
            "kind": "LinkedField",
            "name": "school",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LanternCurriculum",
                "kind": "LinkedField",
                "name": "lanternCurriculum",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sanaCatalogId",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Strand",
                    "kind": "LinkedField",
                    "name": "strands",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Substrand",
                        "kind": "LinkedField",
                        "name": "substrands",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LanternGrade",
                    "kind": "LinkedField",
                    "name": "grades",
                    "plural": true,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "country",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82faeea7a9a85805cc90d56cda7ec041",
    "id": null,
    "metadata": {},
    "name": "TeacherContextLanternQuery",
    "operationKind": "query",
    "text": "query TeacherContextLanternQuery(\n  $classId: ID!\n) {\n  lantern {\n    viewer {\n      __typename\n      id\n    }\n  }\n  class(id: $classId) {\n    classGrade {\n      id\n    }\n    school {\n      lanternCurriculum {\n        ...TeacherContext_lanternCurriculum\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment TeacherContext_lanternCurriculum on LanternCurriculum {\n  id\n  sanaCatalogId\n  title\n  strands {\n    id\n    title\n    substrands {\n      id\n      title\n    }\n  }\n  grades {\n    id\n  }\n  country {\n    code\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '4a2d7f6b394fa9b0456b1945dec167bf';
export default node;
