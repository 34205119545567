import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';

import type {
  OnboardingChecklistMarkAsDoneMutationVariables,
  OnboardingChecklistMarkAsDoneMutationResponse,
} from './__generated__/OnboardingChecklistMarkAsDoneMutation.graphql';

export const useMarkAsDone = (
  variables: OnboardingChecklistMarkAsDoneMutationVariables,
) =>
  useMutation<
    OnboardingChecklistMarkAsDoneMutationResponse,
    OnboardingChecklistMarkAsDoneMutationVariables
  >({
    mutation: graphql`
      mutation OnboardingChecklistMarkAsDoneMutation(
        $stepType: TeacherOnboardingStepType!
      ) {
        completeOnboardingStep(stepType: $stepType) {
          errors {
            key
            message
          }
          viewer {
            ...OnboardingChecklist_viewer
          }
        }
      }
    `,
    variables,
  });
