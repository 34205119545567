import { pipe, prepend, take, omit, uniq } from 'ramda';
import { useMemo } from 'react';

import localStorageDb from 'ms-utils/localStorageDb';

const recentlyUsedTextbooksKey = 'RECENTLY_USED_TEXTBOOKS_v2';
const NUM_RECENTLY_USED = 6;

export function useRecentlyUsedTextbooksForUser(
  userId: string,
): ReadonlyArray<string> {
  const recentlyUsedTextbooksbyUser = useMemo(() => {
    const userTextbooks: { id: string }[] =
      (localStorageDb.get(recentlyUsedTextbooksKey) || {})[userId] || [];
    return userTextbooks.map(t => t.id);
  }, [userId]);

  return recentlyUsedTextbooksbyUser;
}

export function setRecentlyUsedTextbooksForUser(
  userId: string,
  textbookId: string,
) {
  const recentlyUsedTextbooks =
    localStorageDb.get(recentlyUsedTextbooksKey) || {};

  const recentBooks = recentlyUsedTextbooks[userId] || [];
  recentlyUsedTextbooks[userId] = pipe(
    prepend({ id: textbookId }),
    uniq,
    take(NUM_RECENTLY_USED),
  )(recentBooks);
  localStorageDb.set(recentlyUsedTextbooksKey, recentlyUsedTextbooks);
}

export function deleteRecentlyUsedTextbookForUser(userId: string) {
  const recentlyUsedTextbooks =
    localStorageDb.get(recentlyUsedTextbooksKey) || {};

  const newRecentlyUsedTextbooks = omit([userId])(recentlyUsedTextbooks);

  localStorageDb.set(recentlyUsedTextbooksKey, newRecentlyUsedTextbooks);
}

export const anonymousUserId = 'anonymous_user';
