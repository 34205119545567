import type { ReactElement } from 'react';
import { useLayoutEffect, useState } from 'react';

/**
 * 🚨 Deprecated 🚨
 * @TODO Refactor all call sites and delete this file
 * @deprecated You should be using useMediaQuery instead of this HOC pattern these days
 */
export default function Media({
  query,
  children,
}: {
  query: string;
  children?: (matches: boolean) => ReactElement<any> | null;
}) {
  const [matches, setMatches] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    const mql = window.matchMedia(query);
    setMatches(mql.matches);
    const onChange = () => setMatches(mql.matches);
    mql.addEventListener('change', onChange);
    return () => mql.removeEventListener('change', onChange);
  }, [query]);

  // Don't render anything until we've evaluated the media query at least once
  if (matches === null) return null;
  if (children === undefined) return null;

  return <>{children(matches)}</>;
}
