export const observeAndSetWidth = ({
  ref,
  widthSetter,
}: {
  ref: React.MutableRefObject<HTMLElement | null>;
  widthSetter?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  if (ref.current == null) return;
  const handleChange = () => {
    if (ref.current != null) {
      const { width } = ref.current.getBoundingClientRect();
      if (widthSetter != null) {
        widthSetter(width);
      }
    }
  };
  const resizeObserver = new ResizeObserver(handleChange);
  resizeObserver.observe(ref.current);
  return () => resizeObserver.disconnect();
};
