/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentSkillsMapSubstrandSkills_substrand = {
    readonly gradeSubstrands: ReadonlyArray<{
        readonly id: string;
        readonly gradeStrand: {
            readonly grade: {
                readonly id: string;
                readonly order: number;
            };
        };
        readonly outcomes: ReadonlyArray<{
            readonly id: string;
            readonly title: string;
            readonly code: string;
            readonly skill: {
                readonly id: string;
                readonly canStartCheckIn: boolean;
                readonly description: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandSkills_skillsBadge" | "SkillBlockPopover_outcome">;
        }>;
    }>;
    readonly " $refType": "StudentSkillsMapSubstrandSkills_substrand";
};
export type StudentSkillsMapSubstrandSkills_substrand$data = StudentSkillsMapSubstrandSkills_substrand;
export type StudentSkillsMapSubstrandSkills_substrand$key = {
    readonly " $data"?: StudentSkillsMapSubstrandSkills_substrand$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandSkills_substrand">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandSkills_substrand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GradeSubstrand",
      "kind": "LinkedField",
      "name": "gradeSubstrands",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "GradeStrand",
          "kind": "LinkedField",
          "name": "gradeStrand",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LanternGrade",
              "kind": "LinkedField",
              "name": "grade",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "order",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LanternOutcome",
          "kind": "LinkedField",
          "name": "outcomes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Skill",
              "kind": "LinkedField",
              "name": "skill",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "canStartCheckIn",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StudentSkillsMapSubstrandSkills_skillsBadge"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SkillBlockPopover_outcome"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Substrand",
  "abstractKey": null
};
})();
(node as any).hash = 'c3d6ef316fbfbeec6f5dc180c98cb6b6';
export default node;
