import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path d="M7.42388257,8.44861293 L11.5365656,8.44861293 C11.810128,8.44861293 12.0318941,8.67037903 12.0318941,8.94394138 C12.0318941,9.21750373 11.810128,9.43926983 11.5365656,9.43926983 L7.42388257,9.43926983 C7.15032022,9.43926983 6.92855412,9.21750373 6.92855412,8.94394138 C6.92855412,8.67037903 7.15032022,8.44861293 7.42388257,8.44861293 Z M12.889505,14.4407713 C12.4909093,14.0538957 12.4909093,13.4266464 12.889505,13.0397708 C13.2881006,12.6528953 13.9343518,12.6528953 14.3329475,13.0397708 L17.7010533,16.3088429 C18.0996489,16.6957185 18.0996489,17.3229677 17.7010533,17.7098433 C17.3024576,18.0967189 16.6562064,18.0967189 16.2576107,17.7098433 L12.889505,14.4407713 Z M9.61843955,15.5192975 C5.97006608,15.5192975 3,12.7236754 3,9.25964877 C3,5.79562213 5.97006608,3 9.61843955,3 C13.266813,3 16.2368791,5.79562213 16.2368791,9.25964877 C16.2368791,12.7236754 13.266813,15.5192975 9.61843955,15.5192975 Z M9.61843955,13.5379837 C12.1531938,13.5379837 14.1955431,11.6155899 14.1955431,9.25964877 C14.1955431,6.90370769 12.1531938,4.98131379 9.61843955,4.98131379 C7.08368529,4.98131379 5.04133599,6.90370769 5.04133599,9.25964877 C5.04133599,11.6155899 7.08368529,13.5379837 9.61843955,13.5379837 Z" />
);

const SearchMinus = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

SearchMinus.displayName = 'SearchMinus';

export default SearchMinus;
