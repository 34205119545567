import { Suspense, useCallback, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';

import { ErrorBoundary } from 'ms-components/ErrorBoundary/ErrorBoundary';
import Folder from 'ms-components/icons/Folder';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import SelectAFolderModal from 'ms-pages/Teacher/TaskTemplates/components/SelectAFolderModal';
import type { FolderId } from 'ms-pages/Teacher/TaskTemplates/components/SelectAFolderModal/types';
import { getFolderTitleWithPath } from 'ms-pages/Teacher/TaskTemplates/components/SelectAFolderModal/utils';
import Pill from 'ms-pages/Teacher/components/InputSelector/Pill';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import Button from 'ms-ui-primitives/Button';
import { useBoolean } from 'ms-utils/hooks/useBoolean';

import type { FolderSelectorQuery } from './__generated__/FolderSelectorQuery.graphql';
import type { SelectedFolderId } from '../../CreateTask/types';

const query = graphql`
  query FolderSelectorQuery($taskTemplateGroupId: ID!) {
    taskTemplateGroup(id: $taskTemplateGroupId) {
      id
      title
      ancestorGroups {
        title
      }
    }
  }
`;

type Props = {
  schoolId: string;
  templateGroupId: SelectedFolderId;
  updateTemplateGroupId: (id: SelectedFolderId) => void;
};

type FolderTitle = string | undefined;

const FolderSelector = ({
  schoolId,
  templateGroupId,
  updateTemplateGroupId,
}: Props) => {
  const isSelectAFolderModalOpen = useBoolean(false);
  const [selectedFolderTitleWithPath, setSelectedFolderTitleWithPath] =
    useState<FolderTitle>(undefined);

  const hasSelectedFolder = templateGroupId != null && templateGroupId !== '';

  const onRemoveFolder = useCallback(
    () => updateTemplateGroupId(null),
    [updateTemplateGroupId],
  );

  const onSelectFolder = useCallback(
    ({
      id,
      titleWithPath,
    }: {
      id: FolderId;
      titleWithPath: NonNullable<FolderTitle>;
    }) => {
      updateTemplateGroupId(id);
      setSelectedFolderTitleWithPath(titleWithPath);
    },
    [updateTemplateGroupId],
  );

  return (
    <>
      {hasSelectedFolder ? (
        <SelectedFolder
          title={selectedFolderTitleWithPath}
          onRemove={onRemoveFolder}
          selectedFolderId={templateGroupId}
        />
      ) : (
        <Button
          type="secondary"
          color="eggplant"
          width={220}
          height={40}
          isRound
          onClick={isSelectAFolderModalOpen.setTrue}
          styles={{ fontSize: 16 }}
        >
          Select folder
        </Button>
      )}
      {isSelectAFolderModalOpen.value && (
        <SelectAFolderModal
          isOpen={isSelectAFolderModalOpen.value}
          onClose={isSelectAFolderModalOpen.setFalse}
          schoolId={schoolId}
          onSubmit={onSelectFolder}
        />
      )}
    </>
  );
};

const SelectedFolder = ({
  title,
  onRemove,
  selectedFolderId,
}: {
  title: FolderTitle;
  onRemove: () => void;
  selectedFolderId: string;
}) => {
  return title != null ? (
    <SelectedFolderPill title={title} onRemove={onRemove} />
  ) : (
    <ErrorBoundary
      name="FolderSelector:PreselectedFolder"
      fallback={<BodyM color="peachPink">Could not load current folder</BodyM>}
    >
      <Suspense fallback={<MinorSpinner noPadding scale={0.5} />}>
        <PreselectedFolder
          selectedFolderId={selectedFolderId}
          onRemove={onRemove}
        />
      </Suspense>
    </ErrorBoundary>
  );
};

const PreselectedFolder = ({
  selectedFolderId,
  onRemove,
}: {
  onRemove: () => void;
  selectedFolderId: string;
}) => {
  const data = useLazyLoadQuery<FolderSelectorQuery>(query, {
    taskTemplateGroupId: selectedFolderId,
  });

  if (data.taskTemplateGroup == null) {
    throw new Error('FolderSelectorQuery failed');
  }

  const { title, ancestorGroups } = data.taskTemplateGroup;
  const ancestorTitles = ancestorGroups.map(({ title }) => title);
  const titleWithPath = getFolderTitleWithPath({ ancestorTitles, title });

  return <SelectedFolderPill title={titleWithPath} onRemove={onRemove} />;
};

const SelectedFolderPill = ({
  title,
  onRemove,
}: {
  title: string;
  onRemove: () => void;
}) => (
  <Pill
    isGrey
    label={title}
    icon={<Folder />}
    onClose={onRemove}
    noFixedHeight
    noTextOverflowEllipsis
    closeButtonHeight={22}
  />
);

export default FolderSelector;
