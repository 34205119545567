import { StyleSheet, css } from 'aphrodite';

import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';

import type { Variant } from './AvatarContainer';

type AvatarSize = 'small' | 'tiny';
type AvatarCircleProps = {
  variant?: Variant;
  size?: AvatarSize;
  avatarUrl: string;
  onClick?: () => any;
  hasBorder?: boolean;
  isAvatarLoading: boolean;
  noLeftMargin?: boolean;
};
const DEFAULT_AVATAR_SIZE = 112;
export const DEFAULT_AVATAR_SIZE_SMALL = 48;
const MODAL_AVATAR_SIZE = 112;
const MODAL_AVATAR_SIZE_SMALL = 48;
const DEFAULT_AVATAR_SIZE_TINY = 32;

export const AVATAR_MARGIN = 2 * BASE_UNIT;

const defaultStyles = StyleSheet.create({
  avatarCircle: {
    width: DEFAULT_AVATAR_SIZE,
    height: DEFAULT_AVATAR_SIZE,
    borderRadius: '50%',
    backgroundColor: colors.porcelain,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: AVATAR_MARGIN,
    flexShrink: 0,
  },
  avatarBorder: {
    boxShadow: `${colors.eggplant} 0px 0px 0 4px inset`,
  },
  small: {
    width: DEFAULT_AVATAR_SIZE_SMALL,
    height: DEFAULT_AVATAR_SIZE_SMALL,
  },
  tiny: {
    width: DEFAULT_AVATAR_SIZE_TINY,
    height: DEFAULT_AVATAR_SIZE_TINY,
  },
  noLeftMargin: {
    marginLeft: 0,
  },
});

const modalStyles = StyleSheet.create({
  avatarCircle: {
    width: MODAL_AVATAR_SIZE,
    height: MODAL_AVATAR_SIZE,
    borderRadius: '50%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    margin: AVATAR_MARGIN,
    flexShrink: 0,
  },
  avatarBorder: {
    boxShadow: `${colors.eggplant} 0px 0px 0 4px inset`,
  },
  small: {
    width: MODAL_AVATAR_SIZE_SMALL,
    height: MODAL_AVATAR_SIZE_SMALL,
  },
  tiny: {
    width: DEFAULT_AVATAR_SIZE_TINY,
    height: DEFAULT_AVATAR_SIZE_TINY,
  },
  noLeftMargin: {
    marginLeft: 0,
  },
});

const AvatarCircle = ({
  variant = 'default',
  size,
  avatarUrl,
  onClick,
  hasBorder = false,
  isAvatarLoading,
  noLeftMargin = false,
}: AvatarCircleProps) => {
  const styles = variant === 'default' ? defaultStyles : modalStyles;
  return (
    <div
      className={css(
        styles.avatarCircle,
        hasBorder && styles.avatarBorder,
        size != null && styles[size],
        noLeftMargin && styles.noLeftMargin,
      )}
      style={{
        opacity: isAvatarLoading ? 0.3 : 1,
        backgroundImage: `url(${avatarUrl})`,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={isAvatarLoading ? undefined : onClick}
    />
  );
};

export default AvatarCircle;
