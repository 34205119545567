import { useContext, useEffect } from 'react';

import { SnackbarContext } from 'ms-components/Snackbar/Provider';
import { useCreateTaskTemplateState } from 'ms-pages/Teacher/components/CreateTaskTemplate/state';
import type { ExternalVariables } from 'ms-pages/Teacher/components/CreateTaskTemplate/state';
import { UpdatersContext as ProblemsCollectionUpdaters } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import { getTeacherWorksheetTemplatesDetailUrl } from 'ms-utils/urls';

/*
 * Provides the task template form state
 * and snackbar calls on various success or failure conditions
 * together with both textbook right sidebar and catfa state reset
 * Returns the task template state hook
 */
export default function useTaskTemplateStateWithSnackbar(
  variables: ExternalVariables,
  taskTemplateCreationCallback: () => void,
  preserveCartAfterCreation: boolean | undefined = false,
) {
  const { clearCart } = useContext(ProblemsCollectionUpdaters);

  const hook = useCreateTaskTemplateState(variables);
  const [taskTemplateFormState, [, { response, errors }]] = hook;
  const { enqueueMessage } = useContext(SnackbarContext);

  useEffect(
    () => {
      if (
        response != null &&
        response.createSchoolTaskTemplate.taskTemplate != null
      ) {
        enqueueMessage({
          text: 'Template has been created',
          actionLabel: 'View',
          persistent: true,
          dismissable: true,
          href: getTeacherWorksheetTemplatesDetailUrl({
            worksheetTemplateId:
              response.createSchoolTaskTemplate.taskTemplate.id,
          }),
        });
        taskTemplateFormState.updaters.reset();
        if (!preserveCartAfterCreation) {
          clearCart();
        }
        taskTemplateCreationCallback();
      }
    },
    //  Many of the methods in the function above cannot be guaranteed constant.
    // we have to disable exhaustive deps check here just to be certain
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [response],
  );

  useEffect(() => {
    if (
      errors != null ||
      (response != null && response.createSchoolTaskTemplate.errors.length > 0)
    ) {
      enqueueMessage({
        text: 'The template has not been created. Try again',
      });
    }
  }, [response, errors, enqueueMessage]);

  return hook;
}
