import { equals } from 'ramda';
import type { ReactElement } from 'react';
import { cloneElement, useEffect, useRef } from 'react';

export type Rect = {
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
};

// Transforms a DOMRect object into a plain object where the rect dimensions
// are own properties (as opposed to getters on the prototype).
const rectAsPlainObject = (rect: DOMRect): Rect => {
  const { width, height, top, right, bottom, left } = rect;
  return { width, height, top, right, bottom, left };
};

export default function Measurer({
  onMeasure,
  children,
}: {
  onMeasure: (rect: Rect) => void;
  children?: ReactElement<any>;
}) {
  const element = useRef<HTMLElement | null>(null);
  const prevRect = useRef<Rect | null>(null);

  useEffect(() => {
    // Timeout ensures Aphrodite styles have been flushed to the DOM.
    // see https://github.com/Khan/aphrodite#style-injection-and-buffering
    const timeout = setTimeout(() => {
      if (element.current === null) return;
      const rect = rectAsPlainObject(element.current.getBoundingClientRect());
      if (equals(rect, prevRect.current)) return;
      prevRect.current = rect;
      onMeasure(rect);
    }, 0);
    return () => {
      clearTimeout(timeout);
    };
  });

  if (children === undefined) return null;

  return cloneElement(children, {
    ref: (node: HTMLElement | null) => {
      element.current = node;
      if (
        // @ts-expect-error TS doesn't model elements correctly
        typeof children.ref === 'function'
      ) {
        // @ts-expect-error TS doesn't model elements correctly
        children.ref(node);
      }
    },
  });
}
