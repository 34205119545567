/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SyllabusLocale = "EN_AU" | "EN_GB" | "EN_US" | "ES" | "FR" | "HT_HT" | "PT";
export type SubtopicDetailHeader_subtopic = {
    readonly id: string;
    readonly title: string;
    readonly gamifiedMastery: number;
    readonly hasAdaptivePractice: boolean;
    readonly hasWorksheet: boolean;
    readonly hasLessons: boolean;
    readonly hasLessonsTeacherNotes: boolean;
    readonly hasWorksheetAnswerKey: boolean;
    readonly hasExercise: boolean;
    readonly hasEngageActivity: boolean;
    readonly reasonForInclusion: {
        readonly id: string;
        readonly code: string;
        readonly description: string;
    } | null;
    readonly outcomes: ReadonlyArray<{
        readonly id: string;
        readonly code: string;
        readonly description: string;
    }>;
    readonly topic: {
        readonly id: string;
        readonly title: string;
        readonly badgeUrl: string;
        readonly syllabus: {
            readonly id: string;
            readonly title: string;
            readonly coverImageUrl: string;
            readonly " $fragmentRefs": FragmentRefs<"LanguageSelector_availableLanguages">;
        };
    };
    readonly rslJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly rslTeacherNoteJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly solidifyLessonJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly solidifyTeacherNoteJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly investigationJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly investigationTeacherNoteJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly teacherNoteJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly worksheetJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly answerKeyJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly teacherOverviewJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly engageActivityTeacherNoteJsx: {
        readonly id?: string | null | undefined;
        readonly transpiledJsx?: string | null | undefined;
        readonly locale?: SyllabusLocale | null | undefined;
        readonly pdfAvailable?: boolean | undefined;
        readonly pdfUrl?: string | null | undefined;
    };
    readonly problemContents: ReadonlyArray<{
        readonly " $fragmentRefs": FragmentRefs<"SolutionModal_problemContent">;
    }>;
    readonly " $refType": "SubtopicDetailHeader_subtopic";
};
export type SubtopicDetailHeader_subtopic$data = SubtopicDetailHeader_subtopic;
export type SubtopicDetailHeader_subtopic$key = {
    readonly " $data"?: SubtopicDetailHeader_subtopic$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubtopicDetailHeader_subtopic">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "InlineFragment",
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "transpiledJsx",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locale",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pdfAvailable",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pdfUrl",
        "storageKey": null
      }
    ],
    "type": "JsxContent",
    "abstractKey": "__isJsxContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubtopicDetailHeader_subtopic",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gamifiedMastery",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasAdaptivePractice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasWorksheet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLessons",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasLessonsTeacherNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasWorksheetAnswerKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEngageActivity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubtopicReasonForInclusion",
      "kind": "LinkedField",
      "name": "reasonForInclusion",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Outcome",
      "kind": "LinkedField",
      "name": "outcomes",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "badgeUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Syllabus",
          "kind": "LinkedField",
          "name": "syllabus",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coverImageUrl",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LanguageSelector_availableLanguages"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RetrofittedStudentLesson",
      "kind": "LinkedField",
      "name": "rslJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RetrofittedStudentLessonTeacherNote",
      "kind": "LinkedField",
      "name": "rslTeacherNoteJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SolidifyLesson",
      "kind": "LinkedField",
      "name": "solidifyLessonJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SolidifyTeacherNote",
      "kind": "LinkedField",
      "name": "solidifyTeacherNoteJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Investigation",
      "kind": "LinkedField",
      "name": "investigationJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvestigationTeacherNote",
      "kind": "LinkedField",
      "name": "investigationTeacherNoteJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeacherNote",
      "kind": "LinkedField",
      "name": "teacherNoteJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Worksheet",
      "kind": "LinkedField",
      "name": "worksheetJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerKey",
      "kind": "LinkedField",
      "name": "answerKeyJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeacherOverview",
      "kind": "LinkedField",
      "name": "teacherOverviewJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EngageActivityTeacherNote",
      "kind": "LinkedField",
      "name": "engageActivityTeacherNoteJsx",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProblemContent",
      "kind": "LinkedField",
      "name": "problemContents",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SolutionModal_problemContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subtopic",
  "abstractKey": null
};
})();
(node as any).hash = 'ace2bb134d6bc2313f66792de495aab0';
export default node;
