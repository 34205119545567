import { css } from '@emotion/css';
import { EventType } from '@rive-app/react-canvas';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { graphql, useMutation } from 'react-relay';
import { useQuery } from 'relay-hooks';

import { useSnackbar } from 'ms-components/Snackbar';
import Icons from 'ms-components/icons';
import { useSnowplow } from 'ms-helpers/Snowplow';
import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import useEditTaskDates from 'ms-pages/Teacher/TeacherClassReport/views/TeacherClassReportPlanner/ClassReportPlanner/PlannerCalendarView/useEditTaskDates';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { fontFamily } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import { HStack, VStack } from 'ms-ui-primitives/Stack';
import { Logger } from 'ms-utils/app-logging';
import { usePrevious } from 'ms-utils/hooks/usePrevious';
import useWindowSize from 'ms-utils/hooks/useWindowSize';
import { noop } from 'ms-utils/misc';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';
import extractNode from 'ms-utils/relay/extractNode';
import { useRiveEventNotifier } from 'ms-utils/rive/useRiveEventNotifier';
import { useRiveGeneratedEventListener } from 'ms-utils/rive/useRiveGeneratedEventListener';
import { useTextRunEffect } from 'ms-utils/rive/useTextRunEffect';
import { useTypedRive } from 'ms-utils/rive/useTypedRive';
import { assert, unwrap } from 'ms-utils/typescript-utils';

import type { TugOfWarMutation } from './__generated__/TugOfWarMutation.graphql';
import type {
  TugOfWarQuery,
  TugOfWarQueryResponse,
} from './__generated__/TugOfWarQuery.graphql';
import tugOfWarRiv from './tug_of_war.riv';
import {
  DebugPanelCollapse,
  PanelBody,
  PanelButton,
  PanelInput,
  PanelSection,
  PanelSectionTitle,
  PanelTitle,
} from '../DebugPanel';
import { LightningBoltSvg } from '../LightningBoltSvg';
import { RiveDebugPanel, RiveDebuggerContent } from '../RiveDebugger';

dayjs.extend(isBetween);

type Task = NonNullable<TugOfWarQueryResponse['task']>;

type Game = NonNullable<Task['games']>[number];

export type GameState =
  | 'INITIAL'
  // Start button has been pressed
  // | 'STARTED'
  // Timer has started
  | 'IN_PROGRESS'
  // Timer has ended, and we're waiting for the result
  | 'PENDING_RESULT'
  // Result has been determined, i.e. red, blue, or tie
  | 'RESULT_DETERMINED'
  | 'ENDED';

// These numbers represent the possible positions we can move the flag to in
// Rive.
type FlagPosition = -3 | -1 | 0 | 1 | 3;

type ActivityLogEntry = {
  id: number;
  studentId: string;
  studentName: string;
  team: TugOfWarTeam;
  points: number;
  problemId: string;
  avatar?: string;
};

export type TugOfWarTeam = 'teamA' | 'teamB';

export type TugOfWarTeamConfig = {
  name: string;
  color: (typeof colors)[keyof typeof colors];
};

export const TUG_OF_WAR_WIDTH = 1024;
export const TUG_OF_WAR_HEIGHT = 650;

// This is an approximation based on the pull animations in the Rive file, which
// have varying lengths.
const AVG_PULL_DURATION_MS = 650;

const POLL_INTERVAL_MS = 2000;

export const teamConfig: Record<TugOfWarTeam, TugOfWarTeamConfig> = {
  teamA: {
    name: 'Red',
    color: colors.brickRed,
  },
  teamB: {
    name: 'Blue',
    color: colors.lochmara,
  },
};

const tugOfWarMutation = graphql`
  mutation TugOfWarMutation($gameId: ID!) {
    startGame(gameId: $gameId) {
      errors {
        key
        message
      }
    }
  }
`;

const TugOfWarContext = createContext<{
  entries: ActivityLogEntry[];
  write: (event: Omit<ActivityLogEntry, 'id'>) => void;
}>({ entries: [], write: noop });

export function TugOfWar({
  gameId,
  gameDurationMs,
  taskId,
  taskStartDate,
  onQuit,
}: {
  gameId: string;
  gameDurationMs: number;
  taskId: string;
  taskStartDate: string;
  onQuit: (gameState: GameState) => void;
}) {
  const { rive, RiveComponent } = useTypedRive({
    src: tugOfWarRiv,
    artboard: 'PARENT ARTBOARD - Tug of War',
    stateMachine: 'Tug of War game',
    autoplay: true,
  });
  const [timer, setTimer] = useState(gameDurationMs);
  const [gameState, setGameState] = useState<GameState>('INITIAL');
  const prevGameState = usePrevious(gameState);
  const [gameIsCountingDown, setGameIsCountingDown] = useState(false);
  const [flagPos, setFlagPos] = useState<FlagPosition>(0);
  const flagPosRef = useRef<FlagPosition>(flagPos);
  const [entries, setEntries] = useState<ActivityLogEntry[]>([]);
  const [result, setResult] = useState<TugOfWarTeam | null>(null);
  const [alertDialogIsOpen, setAlertDialogIsOpen] = useState(false);
  const timerIntervalId = useRef<number>();
  const pollIntervalId = useRef<number>();
  const entryId = useRef(1);
  const { ref: containerRef, isFullscreen, toggleFullscreen } = useFullscreen();
  const { enqueueMessage } = useSnackbar();
  const { trackStructEvent } = useSnowplow();
  const viewportSize = useWindowSize();
  const [startGame] = useMutation<TugOfWarMutation>(tugOfWarMutation);
  const [editTaskDates] = useEditTaskDates();

  // Query for live data
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [transactionsCursor, setTransactionsCursor] = useState<string | null>(
    null,
  );
  const { props, error, retry } = useQuery<TugOfWarQuery>(
    graphql`
      query TugOfWarQuery(
        $taskId: ID!
        $startDate: DateTime!
        $endDate: DateTime!
        $numberOfTransactions: Int!
        $after: ID
      ) {
        task(id: $taskId) {
          # Only custom tasks support Tug of War at this stage
          ... on CustomTask {
            games {
              id
              gameType
              gameStatus
              duration
              startedAt
              teams {
                students {
                  id
                  user {
                    firstName
                    avatar
                  }
                }
              }
            }
            assignedProblems {
              id
            }
            assignedStudentsClasses {
              gamification {
                points(
                  taskIds: [$taskId]
                  sourceTypes: [ANSWERED_QUESTION]
                  startDate: $startDate
                  endDate: $endDate
                ) {
                  transactions(
                    first: $numberOfTransactions
                    after: $after
                    orderBy: TIMESTAMP_ASCENDING
                  ) {
                    edges {
                      node {
                        id
                        amount
                        student {
                          studentId
                        }
                        problemId
                      }
                    }
                    pageInfo {
                      endCursor
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      taskId,
      startDate: dayjs(startDate ?? undefined).format(),
      endDate: dayjs(endDate ?? undefined).format(),
      numberOfTransactions: RELAY_CONNECTION_MAX,
      after: transactionsCursor,
    },
    {
      skip:
        gameState !== 'IN_PROGRESS' || startDate === null || endDate === null,
    },
  );

  // Derived state
  const latestEntries = useMemo(() => getLatestEntries(entries), [entries]);
  const { teamA: teamAPoints, teamB: teamBPoints } = useMemo(
    () => tallyTeamPoints(entries),
    [entries],
  );
  const winnerPoints = useMemo(
    () =>
      result !== null ? (result === 'teamA' ? teamAPoints : teamBPoints) : null,
    [result, teamAPoints, teamBPoints],
  );
  const winnerQuestionsAnswered = useMemo(
    () => (result !== null ? tallyQuestionsAnswered(entries, result) : 0),
    [entries, result],
  );

  const write = useCallback((entry: Omit<ActivityLogEntry, 'id'>) => {
    setEntries(prevEntries => [
      ...prevEntries,
      { id: entryId.current++, ...entry },
    ]);
  }, []);

  const endGame = useCallback((team: TugOfWarTeam) => {
    setResult(team);
    setGameState('RESULT_DETERMINED');
  }, []);

  const determineResult = useCallback(() => {
    if (teamAPoints > teamBPoints) {
      endGame('teamA');
    } else if (teamBPoints > teamAPoints) {
      endGame('teamB');
    } else {
      // In the event of a tie, randomly choose a winner
      endGame(Math.random() < 0.5 ? 'teamA' : 'teamB');
    }
  }, [endGame, teamAPoints, teamBPoints]);

  // Start the timer when the game starts
  useEffect(() => {
    if (gameState === 'IN_PROGRESS') {
      timerIntervalId.current = window.setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer > 0) {
            return prevTimer - 1000;
          } else {
            window.clearInterval(timerIntervalId.current);
            return prevTimer;
          }
        });
      }, 1000);
    } else {
      window.clearInterval(timerIntervalId.current);
    }
    return () => {
      window.clearInterval(timerIntervalId.current);
    };
  }, [gameState]);

  // Poll for new data every 2 seconds
  useEffect(() => {
    if (gameState === 'IN_PROGRESS') {
      pollIntervalId.current = window.setInterval(() => {
        retry();
      }, POLL_INTERVAL_MS);
    } else {
      window.clearInterval(pollIntervalId.current);
    }
    return () => {
      window.clearInterval(pollIntervalId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState]);

  // When new data is received, write transactions to the activity log
  useEffect(() => {
    if (props?.task?.games == null) return;

    const activeGame = getActiveGame(props.task.games);
    if (activeGame == null) return;

    if (props.task.assignedStudentsClasses == null) return;

    const taskClass = props.task.assignedStudentsClasses[0];
    const transactions = taskClass?.gamification?.points.transactions;
    const transactionNodes =
      transactions != null ? extractNode(transactions) : [];

    transactionNodes.forEach(transaction => {
      if (transaction.problemId == null) return;

      const { studentId } = transaction.student;
      if (studentId == null) return;

      const student = activeGame.teams
        .flatMap(t => t.students)
        .find(s => s.id === studentId);
      if (student == null) return;

      const team = getStudentTeam(student.id, activeGame.teams);
      if (team == null) return;

      if (student != null) {
        write({
          studentId: student.id,
          studentName: student.user.firstName,
          team,
          points: transaction.amount,
          problemId: transaction.problemId,
          avatar: student.user.avatar,
        });
        trackStructEvent({
          category: 'gamification_tug_of_war',
          action: 'wrote_to_tug_of_war_activity_log',
          label: student.id,
          property: transaction.amount.toString(),
          value: transaction.problemId,
        });
      }
    });

    // Update the cursor
    if (transactions?.pageInfo.endCursor != null) {
      setTransactionsCursor(transactions.pageInfo.endCursor);
    }
  }, [
    props?.task?.assignedStudentsClasses,
    props?.task?.games,
    trackStructEvent,
    write,
  ]);

  // End game early if all students have answered all questions correctly or
  // partially correctly.
  useEffect(() => {
    if (gameState !== 'IN_PROGRESS') return;
    if (props?.task?.assignedProblems == null) return;
    if (props.task.games == null) return;

    const activeGame = getActiveGame(props.task.games);
    if (activeGame == null) return;

    const questionsAnswered = tallyQuestionsAnswered(entries);
    const totalQuestions =
      props.task.assignedProblems.length *
      activeGame.teams.flatMap(t => t.students).length;

    if (questionsAnswered >= totalQuestions) {
      determineResult();
    }
  }, [
    determineResult,
    entries,
    gameState,
    props?.task?.assignedProblems,
    props?.task?.games,
  ]);

  // Expire the task when the game ends so that the student will no longer see
  // it in the Student Dashboard.
  //
  // 15 seconds have been added to the expiry date to side-step any errors
  // relating to setting an expiry date in the past... but why 15 seconds? We
  // need to find a balance between the potential for a student to see the task
  // in the Student Dashboard and the potential for a backend error. I'm hoping
  // 15s is the sweet spot.
  //
  // Finally, in the event of an error - at least for now - we accept that the
  // task will remain in the Student Dashboard.
  useEffect(() => {
    if (gameState !== 'RESULT_DETERMINED') return;
    if (endDate == null) return;

    editTaskDates({
      variables: {
        taskId,
        startDate: dayjs(taskStartDate).format(),
        dueDate: dayjs(endDate).add(15, 's').format(),
      },
      onCompleted: ({ editTaskDates: { errors } }) => {
        if (errors.length > 0) {
          Logger.error('Failed to edit task dates', { extra: { errors } });
        }
      },
      onError: Logger.error,
    });
  }, [editTaskDates, endDate, gameState, taskId, taskStartDate]);

  // Show any query errors as a toast
  useEffect(() => {
    if (error !== null) {
      enqueueMessage({ text: error.message });
    }
  }, [enqueueMessage, error]);

  // Listen for Rive events and update the game state accordingly
  useRiveGeneratedEventListener(
    rive,
    EventType.RiveEvent,
    useCallback(
      (event, rive) => {
        // Bail early if we're not receiving a Rive event
        if (typeof event.data !== 'object' || !('name' in event.data)) return;
        switch (event.data.name) {
          case 'GAME_START':
            startGame({
              variables: { gameId },
              onCompleted: ({ startGame: { errors } }) => {
                if (errors.length > 0) {
                  // For MVP, we accept that all game state is ephemeral and
                  // doesn't require persistence at this stage. However, in the
                  // future we'll need to handle loading and error states to
                  // ensure game state can be persisted. This would include
                  // providing feedback to the user if the game fails to start,
                  // most likely by way of adding these states to the Rive file
                  // itself.
                  Logger.error('Failed to start game', { extra: { errors } });
                }
              },
              onError: Logger.error,
            });
            const startDate = dayjs().toDate();
            const endDate = dayjs(startDate).add(gameDurationMs, 'ms').toDate();
            setStartDate(startDate);
            setEndDate(endDate);
            setGameState('IN_PROGRESS');
            rive.setTextRunValue('TIMER', formatTimeLeft(timer));
            break;
          case 'COUNTDOWN_END':
            determineResult();
            break;
          case 'GAME_END':
            setGameState('ENDED');
            break;
          default:
          // Do nothing
        }
      },
      [determineResult, gameDurationMs, gameId, startGame, timer],
    ),
  );

  // Update Rive animations based on timer state
  useEffect(() => {
    switch (timer) {
      case 10_000:
        rive?.value('TIME_IS_EXPIRING', true);
        break;
      case 3_000:
        rive?.fire('START_COUNTDOWN');
        setGameIsCountingDown(true);
        break;
      case 0:
        rive?.value('TIME_IS_EXPIRING', false);
        setGameIsCountingDown(false);
        setGameState('PENDING_RESULT');
        break;
      default:
      // Do nothing
    }
  }, [rive, timer]);

  // Update progress bar when points change
  useEffect(() => {
    if (rive === null) return;
    const value = (teamAPoints / (teamAPoints + teamBPoints)) * 100;
    rive.value('PROGRESS_BAR', value);
  }, [teamBPoints, teamAPoints, rive]);

  // Set the flag position based on the ratio of points between the two teams
  useEffect(() => {
    if (rive == null) return;
    if (teamAPoints === 0 && teamBPoints === 0) return;

    const teamAPointRatio = teamAPoints / (teamAPoints + teamBPoints);
    let nextFlagPos: FlagPosition = 0;

    // The higher the ratio, the more the flag should be pulled to the left. The
    // reason 3 and -3 were chosen was to maintain an even spacing between the
    // flag positions, i.e. if the gap between -1 and 1 is 2, then the gap
    // between 1 and 3 should also be 2. So when we get the diff between current
    // and next flag positions, we divide it by 2 to know how many positions the
    // flag needs to move.
    //
    // We want as much flag movement as possible, and therefore - based on the
    // assumption that games will be close - we've chosen ratios that are fairly
    // close together.
    if (teamAPointRatio > 0.6) {
      nextFlagPos = -3;
    } else if (teamAPointRatio > 0.5) {
      nextFlagPos = -1;
    } else if (teamAPointRatio > 0.4) {
      nextFlagPos = 1;
    } else {
      nextFlagPos = 3;
    }

    const prevFlagPos = flagPosRef.current;
    let diff = nextFlagPos - prevFlagPos;

    // If the flag is at the center position, the diff is always going to be odd
    // in number, and therefore we need to add 1 so that it divides nicely by 2
    // to give us the correct number of iterations.
    const numIterations =
      prevFlagPos === 0 ? (Math.abs(diff) + 1) / 2 : Math.abs(diff) / 2;
    const inputName = diff > 0 ? 'PULL_RIGHT' : 'PULL_LEFT';
    const timeoutIds: number[] = [];

    // If the flag stays as is, animate the hands only
    if (numIterations === 0) {
      rive.fire('PULL_LEFT_MUTED');
      rive.fire('PULL_RIGHT_MUTED');
    }

    for (let i = 0; i < numIterations; i++) {
      const timeoutId = window.setTimeout(() => {
        rive.fire(inputName);
      }, i * AVG_PULL_DURATION_MS);
      timeoutIds.push(timeoutId);
    }

    setFlagPos(nextFlagPos);
    flagPosRef.current = nextFlagPos;

    return () => {
      timeoutIds.forEach(clearTimeout);
    };
  }, [rive, teamAPoints, teamBPoints]);

  // Trigger win animation when a result is determined
  useEffect(() => {
    if (result !== null) {
      rive?.fire(result === 'teamA' ? 'RED_WINS' : 'BLUE_WINS');
    }
  }, [result, rive]);

  useTextRunEffect(rive, 'TIMER', formatTimeLeft(timer));
  useTextRunEffect(rive, 'RED_POINTS', teamAPoints.toString());
  useTextRunEffect(rive, 'BLUE_POINTS', teamBPoints.toString());
  useTextRunEffect(rive, 'WINNER_POINTS', winnerPoints?.toString());
  useTextRunEffect(
    rive,
    'WINNER_QUESTIONS_ANSWERED',
    winnerQuestionsAnswered?.toString(),
  );
  useRiveEventNotifier(rive);

  // Track game state changes
  useEffect(() => {
    if (gameState === prevGameState) return;
    let event: SupportedStructEvent;
    if (gameState === 'RESULT_DETERMINED') {
      assert(
        result !== null,
        'gameState is RESULT_DETERMINED but result is null',
      );
      event = {
        category: 'gamification_tug_of_war',
        action: 'tug_of_war_game_state_changed',
        label: 'RESULT_DETERMINED',
        property: result,
      };
    } else {
      event = {
        category: 'gamification_tug_of_war',
        action: 'tug_of_war_game_state_changed',
        label: gameState,
      };
    }
    trackStructEvent(event);
  }, [gameState, prevGameState, result, trackStructEvent]);

  const contextValue = useMemo(() => ({ entries, write }), [entries, write]);
  const fontSize = useMemo(() => {
    if (!isFullscreen) return;
    if (viewportSize.width == null || viewportSize.height == null) return;

    // Note: The higher the number, the wider the screen.
    const viewportAspectRatio = viewportSize.width / viewportSize.height;
    const gameAspectRatio = TUG_OF_WAR_WIDTH / TUG_OF_WAR_HEIGHT;

    // Viewport is taller than the game. This is the more common case observed
    // thus far. In this case, we use `vw` because we know that 100vw is equal
    // to the width of the game.
    // See: https://css-tricks.com/viewport-sized-typography/
    if (viewportAspectRatio <= gameAspectRatio) {
      return `calc(1600vw / ${TUG_OF_WAR_WIDTH})`;
    }

    // Viewport is wider, which renders `vw` somewhat useless. Therefore we use
    // `vh` instead.
    return `calc(1600vh / ${TUG_OF_WAR_HEIGHT})`;
  }, [isFullscreen, viewportSize]);

  return (
    <TugOfWarContext.Provider value={contextValue}>
      <div
        ref={containerRef}
        style={{
          borderRadius: 'inherit',
          // Font size is used to size the game's React-based UI. For example,
          // if the game has been scaled up to 200% of its original size, the
          // font size will follow suit, so that we can use relative units (e.g.
          // em) to size/space the game's UI.
          fontSize,
          height: '100%',
        }}
      >
        <div
          // Without this element, Rive will extend the canvas to fill the
          // available space, making it difficult to layer the game's React-based
          // UI over the top of the actual game.
          style={{
            aspectRatio: `${TUG_OF_WAR_WIDTH} / ${TUG_OF_WAR_HEIGHT}`,
            // We need to inherit border-radius from the modal due to this
            // element's absolute positioning.
            borderRadius: isFullscreen ? undefined : 'inherit',
            inset: 0,
            // Allows for the spinner to sit under the Rive canvas
            isolation: 'isolate',
            margin: 'auto',
            maxHeight: '100%',
            maxWidth: '100%',
            overflow: 'hidden',
            position: 'absolute',
          }}
        >
          {/* Faux loading state till we have a real one. */}
          {/* TODO: Make real loading state. */}
          <div style={{ inset: 0, position: 'absolute', zIndex: -1 }}>
            <MinorSpinner />
          </div>

          <RiveComponent />

          {gameState === 'IN_PROGRESS' && !gameIsCountingDown && (
            <HStack
              style={{
                height: '40%',
                inset: 0,
                padding: `${pxToEm(112)} ${pxToEm(36)} ${pxToEm(32)}`,
                position: 'absolute',
                top: 'auto',
              }}
            >
              <VStack
                style={{
                  flex: '0 0 auto',
                  gap: pxToEm(6),
                  paddingLeft: pxToEm(36),
                  paddingRight: pxToEm(36),
                  width: '50%',
                }}
              >
                <ActivityLog entries={latestEntries.teamA} />
              </VStack>
              <VStack
                style={{
                  flex: '0 0 auto',
                  gap: pxToEm(6),
                  paddingLeft: pxToEm(36),
                  paddingRight: pxToEm(36),
                  width: '50%',
                }}
              >
                <ActivityLog entries={latestEntries.teamB} />
              </VStack>
            </HStack>
          )}

          {/* Controls */}
          <div className={styles.controls}>
            <button
              className={styles.controlButton}
              onClick={() => {
                toggleFullscreen();
                trackStructEvent({
                  category: 'gamification_tug_of_war',
                  action: 'toggled_tug_of_war_fullscreen_mode',
                  label: isFullscreen ? 'windowed' : 'fullscreen',
                });
              }}
            >
              {isFullscreen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ width: pxToEm(16), height: 'auto' }}
                >
                  <path
                    fillRule="evenodd"
                    d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707M15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  style={{ width: pxToEm(16), height: 'auto' }}
                >
                  <path
                    fillRule="evenodd"
                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707m4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707"
                  />
                </svg>
              )}
            </button>

            {/* TODO: Fix button not working in fullscreen mode. */}
            {!isFullscreen && (
              <button
                className={styles.controlButton}
                onClick={() => {
                  if (gameState === 'ENDED') {
                    // No logging required because onQuit() logs an event itself
                    onQuit(gameState);
                  } else {
                    setAlertDialogIsOpen(true);
                    trackStructEvent({
                      category: 'gamification_tug_of_war',
                      action: 'requested_to_quit_tug_of_war',
                      label: gameState,
                    });
                  }
                }}
              >
                <Icons.Cross size={pxToEm(16)} />
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={alertDialogIsOpen}
        showCloseButton={false}
        width={384}
        onClose={noop}
      >
        <div className={styles.alertDialog}>
          <div className={styles.alertDialogBody}>
            <h2 className={styles.alertDialogTitle}>Quit</h2>
            <p>
              Are you sure? All players will lose their progress if you exit the
              game.
            </p>
          </div>
          <footer className={styles.alertDialogFooter}>
            <Button
              type="secondary"
              onClick={() => {
                setAlertDialogIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                onQuit(gameState);
              }}
            >
              Quit
            </Button>
          </footer>
        </div>
      </Modal>

      {rive && (
        <RiveDebugPanel>
          <HStack center>
            <PanelTitle>Tug of War Debugger</PanelTitle>
            <DebugPanelCollapse />
          </HStack>
          <PanelBody>
            <RiveDebuggerContent
              rive={rive}
              onReset={() => {
                setGameState('INITIAL');
                setEntries([]);
              }}
              onTrigger={inputName => {
                if (inputName === 'RED_WINS' || inputName === 'BLUE_WINS') {
                  determineResult();
                }
              }}
            />
            <div style={{ backgroundColor: colors.ironLight, height: 1 }} />
            <RiveDebuggerActivityLogger
              team="teamA"
              defaultStudentName="Olivia"
              isDisabled={gameState !== 'IN_PROGRESS'}
            />
            <RiveDebuggerActivityLogger
              team="teamB"
              defaultStudentName="Ethan"
              isDisabled={gameState !== 'IN_PROGRESS'}
            />
          </PanelBody>
        </RiveDebugPanel>
      )}
    </TugOfWarContext.Provider>
  );
}

function ActivityLog({ entries }: { entries: ActivityLogEntry[] }) {
  const positiveEntries = entries.filter(entry => entry.points > 0);
  return (
    <AnimatePresence mode="popLayout">
      {positiveEntries.map(entry => (
        <motion.div
          key={entry.id}
          className={styles.activityLogEntry}
          initial={{ opacity: 0, y: '100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{
            opacity: 0,
            y: '-100%',
            scale: 1.05,
            transition: { ease: 'easeOut', duration: 0.1 },
          }}
        >
          <div
            className={styles.avatar}
            style={{
              backgroundImage: entry.avatar
                ? `url(${entry.avatar})`
                : undefined,
            }}
          />
          <div
            style={{
              lineHeight: 1.5,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {entry.studentName} completed a question
          </div>
          <span className={styles.pointsBadge} style={{ marginLeft: 'auto' }}>
            <LightningBoltSvg
              style={{ width: pxToEm(17, 18), height: 'auto' }}
            />
            {entry.points}
          </span>
        </motion.div>
      ))}
    </AnimatePresence>
  );
}

function RiveDebuggerActivityLogger({
  team,
  defaultStudentName = '',
  isDisabled,
}: {
  team: TugOfWarTeam;
  defaultStudentName?: string;
  isDisabled?: boolean;
}) {
  const [studentName, setStudentName] = useState(defaultStudentName);
  const [points, setPoints] = useState(10);
  const { write } = useContext(TugOfWarContext);
  const problemCounter = useRef(1);

  function handleSubmit(e: React.FormEvent<HTMLDivElement>) {
    e.preventDefault();
    write({
      studentId: `MockStudent-${team}`,
      studentName,
      points,
      team,
      problemId: `MockProblem-${problemCounter.current++}`,
    });
  }

  return (
    <PanelSection as="form" onSubmit={handleSubmit}>
      <PanelSectionTitle>{team} team</PanelSectionTitle>
      <HStack gap={4}>
        <div style={{ flex: '1 1 0%' }}>
          <PanelInput
            placeholder="Student name"
            value={studentName}
            onChange={e => setStudentName(e.target.value)}
          />
        </div>
        <div style={{ width: 64 }}>
          <PanelInput
            type="number"
            value={points.toString()}
            onChange={e => setPoints(parseInt(e.target.value, 10))}
          />
        </div>
      </HStack>
      <PanelButton
        color={team === 'teamA' ? 'brickRed' : 'lochmara'}
        isDisabled={isDisabled}
      >
        Log activity
      </PanelButton>
    </PanelSection>
  );
}

function formatTimeLeft(ms: number) {
  const totalSeconds = Math.floor(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  // Pad with leading zeros
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
}

type GameParam = Pick<
  Game,
  'id' | 'gameType' | 'gameStatus' | 'duration' | 'startedAt'
>;

export function getActiveGame<T extends GameParam>(
  games: ReadonlyArray<T>,
): T | null {
  if (games.length === 0) return null;

  const gamesDesc = games.slice().reverse();
  const activeGame = gamesDesc.find(isActiveGame);

  // If no active game is found, it's possible that there was an error when
  // starting the game on the backend. In this case, we return the last game in
  // the list, which _should_ be active, but may not be...
  // TODO: Update data model to explicitly identify the active game and
  // student's team to avoid this flawed search logic.
  if (activeGame === undefined) {
    const lastGame = gamesDesc[0];
    Logger.info('No active game found');
    return lastGame ?? null;
  }

  return activeGame;
}

function getLatestEntries(
  entries: ActivityLogEntry[],
): Record<TugOfWarTeam, ActivityLogEntry[]> {
  return {
    teamA: entries.filter(entry => entry.team === 'teamA').slice(-3),
    teamB: entries.filter(entry => entry.team === 'teamB').slice(-3),
  };
}

function getStudentTeam(
  studentId: string,
  teams: Game['teams'],
): TugOfWarTeam | null {
  const teamIndex = teams.findIndex(t =>
    t.students.some(s => s.id === studentId),
  );
  return teamIndex === 0 ? 'teamA' : teamIndex === 1 ? 'teamB' : null;
}

export function getTeamConfigFromName(teamName: string) {
  const uppercaseName = teamName.toUpperCase();
  return Object.values(teamConfig).find(
    config => config.name.toUpperCase() === uppercaseName,
  );
}

function isActiveGame(game: GameParam) {
  // Check that it's a game of Tug of War
  if (game.gameType !== 'TUG_OF_WAR') return false;

  // Check that the game is in progress
  // TODO: Fix up the modelling so that we have a dedicated field for
  // determining if a game is active, i.e. checking against two statuses is not
  // ideal.
  if (game.gameStatus !== 'IN_PROGRESS' && game.gameStatus !== 'COUNTING_DOWN')
    return false;

  // Check that the game has started
  if (game.startedAt === null) return false;

  // Tug of War does not currently persist on the backend whether a game has
  // been completed. This means that we cannot use the completedAt field (as
  // it'll always be null), and must instead use the startedAt and duration
  // fields to determine if the game is still active.
  if (
    !dayjs().isBetween(
      dayjs(game.startedAt),
      dayjs(game.startedAt).add(game.duration, 's'),
    )
  ) {
    return false;
  }

  return true;
}

function pxToEm(px: number, baseFontSize = 16) {
  return `${px / baseFontSize}em`;
}

function tallyTeamPoints(
  entries: ActivityLogEntry[],
): Record<TugOfWarTeam, number> {
  return entries.reduce(
    (acc, entry) => {
      if (entry.team === 'teamA') {
        acc.teamA += entry.points;
      } else if (entry.team === 'teamB') {
        acc.teamB += entry.points;
      }
      return acc;
    },
    { teamA: 0, teamB: 0 },
  );
}

function tallyQuestionsAnswered(
  entries: ActivityLogEntry[],
  team?: TugOfWarTeam,
) {
  const studentProblemsMap: Record<string, Set<string>> = {};

  entries.forEach(entry => {
    if (team != null && entry.team !== team) return;

    if (studentProblemsMap[entry.studentId] == null) {
      studentProblemsMap[entry.studentId] = new Set();
    }

    unwrap(studentProblemsMap[entry.studentId]).add(entry.problemId);
  });

  return Object.values(studentProblemsMap).reduce(
    (total, problems) => total + problems.size,
    0,
  );
}

function useFullscreen<T extends HTMLElement = HTMLDivElement>() {
  const ref = useRef<T>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const enterFullscreen = useCallback(() => {
    ref.current?.requestFullscreen();
  }, []);

  const exitFullscreen = useCallback(() => {
    document.exitFullscreen();
  }, []);

  const toggleFullscreen = useCallback(() => {
    if (isFullscreen) {
      exitFullscreen();
    } else {
      enterFullscreen();
    }
  }, [enterFullscreen, exitFullscreen, isFullscreen]);

  useEffect(() => {
    function handleFullscreenChange() {
      setIsFullscreen(document.fullscreenElement === ref.current);
    }
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  return {
    ref,
    isFullscreen,
    enterFullscreen,
    exitFullscreen,
    toggleFullscreen,
  };
}

const styles = {
  activityLogEntry: css({
    alignItems: 'center',
    color: colors.neutralGray,
    display: 'flex',
    fontFamily: fontFamily.heading,
    fontSize: pxToEm(18),
    fontWeight: 800,
    gap: pxToEm(4),
  }),
  avatar: css({
    aspectRatio: '1 / 1',
    background: `center/cover ${colors.ironLight}`,
    borderRadius: 9999,
    flexShrink: 0,
    width: pxToEm(32, 18),
  }),
  pointsBadge: css({
    alignItems: 'center',
    backgroundColor: '#fcaa0a',
    color: 'white',
    borderRadius: 9999,
    display: 'flex',
    padding: `${pxToEm(3, 18)} ${pxToEm(6, 18)}`,
  }),
  controls: css({
    display: 'flex',
    gap: pxToEm(8),
    position: 'absolute',
    right: pxToEm(16),
    top: pxToEm(12),
  }),
  controlButton: css({
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderStyle: 'none',
    color: 'white',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: pxToEm(16),
    height: pxToEm(28),
    justifyContent: 'center',
    lineHeight: 1,
    padding: `0 ${pxToEm(8)}`,
    transition: 'color .15s',

    ':hover, :active': {
      color: 'rgb(255 255 255 / 70%)',
    },
  }),
  alertDialog: css({
    color: colors.grey90,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fontFamily.body,
    fontSize: 14,
    gap: 16,
    lineHeight: 1.25,
    padding: '16px 20px',
  }),
  alertDialogBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  }),
  alertDialogTitle: css({
    color: colors.grey,
    fontFamily: fontFamily.heading,
    fontSize: 20,
    fontWeight: 800,
  }),
  alertDialogFooter: css({
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  }),
} as const;
