import styled from '@emotion/styled';

import { fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';

const CenteredErrorMessage = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 2 * BASE_UNIT,
  color: colors.cinnabar,
  fontWeight: fontWeight.semibold,
  fontFamily: fontFamily.body,
});

type Props = { retry: (() => void) | null | undefined; message?: string };

export default function Retry({ retry, message }: Props) {
  return (
    <CenteredErrorMessage>
      <>{message ?? 'An error occurred'}</>
      <Button
        onClick={() => {
          retry?.();
        }}
      >
        Retry
      </Button>
    </CenteredErrorMessage>
  );
}
