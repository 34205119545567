import styled from '@emotion/styled';

import type { DifficultyLevel } from 'ms-components/PreviewProblem/types';
import DifficultyEasyIcon from 'ms-components/icons/DifficultyEasy';
import DifficultyHardIcon from 'ms-components/icons/DifficultyHard';
import DifficultyMediumIcon from 'ms-components/icons/DifficultyMedium';
import { fontFamily, fontSize, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { assertUnreachable } from 'ms-utils/typescript-utils';

const getLabel = (difficulty: DifficultyLevel) => {
  switch (difficulty) {
    case 'EASY':
      return 'Easy';
    case 'MEDIUM':
      return 'Medium';
    case 'HARD':
      return 'Hard';
    default: {
      assertUnreachable(difficulty, `Unknown DifficultyLevel ${difficulty}`);
    }
  }
};

type Props = {
  difficulty: DifficultyLevel;
  iconColor?: string | undefined;
  textColor?: string | undefined;
  iconSize?: number | undefined;
  useUpdatedIcon?: boolean | undefined;
  hasBoldLabel?: boolean | undefined;
  iconTextSpacing?: number | undefined;
  textFontSize?: number | undefined;
};

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontFamily: fontFamily.body,
});
const IconWrapper = styled.div({});
const LabelWrapper = styled.div({});

const ProblemDifficultyLabel = ({
  difficulty,
  iconColor = colors.santasGray,
  textColor = colors.shuttleGray,
  iconSize = 6 * BASE_UNIT,
  useUpdatedIcon = false,
  hasBoldLabel = true,
  iconTextSpacing = 2 * BASE_UNIT,
  textFontSize = fontSize.xSmall,
}: Props) => {
  const DifficultyIcon = (() => {
    switch (difficulty) {
      case 'EASY':
        return DifficultyEasyIcon;
      case 'MEDIUM':
        return DifficultyMediumIcon;
      case 'HARD':
        return DifficultyHardIcon;
      default: {
        assertUnreachable(difficulty, `Unknown DifficultyLevel ${difficulty}`);
      }
    }
  })();
  return (
    <Wrapper
      style={{
        fontWeight: hasBoldLabel ? fontWeight.semibold : fontWeight.normal,
        color: textColor,
      }}
    >
      <IconWrapper style={{ marginRight: iconTextSpacing }}>
        <DifficultyIcon
          color={iconColor}
          size={iconSize}
          useUpdatedIcon={useUpdatedIcon}
        />
      </IconWrapper>
      <LabelWrapper style={{ fontSize: textFontSize }}>
        {getLabel(difficulty)}
      </LabelWrapper>
    </Wrapper>
  );
};

export default ProblemDifficultyLabel;
