import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Shuffle = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={16}
    viewBoxHeight={15}
  >
    <path d="M0 3.63149C0 3.0792 0.447719 2.63149 1 2.63149H2.62019C3.72653 2.63149 4.71366 3.08102 5.55416 3.96758C5.93413 4.36839 5.91725 5.00133 5.51644 5.3813C5.32307 5.56464 5.0756 5.65558 4.8286 5.65558C4.56369 5.65558 4.29935 5.55099 4.10272 5.34358C3.64844 4.86416 3.1635 4.63149 2.62019 4.63149H1C0.447719 4.63149 0 4.18377 0 3.63149H0ZM10.9421 4.63149H12.5858L12.2777 4.93961C11.8871 5.33014 11.8871 5.9633 12.2777 6.35383C12.4729 6.54911 12.7288 6.64674 12.9848 6.64674C13.2407 6.64674 13.4966 6.54911 13.6919 6.35383L15.7071 4.33858C16.0976 3.94805 16.0976 3.31489 15.7071 2.92436L13.6919 0.909109C13.3014 0.518578 12.6682 0.518578 12.2777 0.909109C11.8871 1.29964 11.8871 1.9328 12.2777 2.32333L12.5858 2.63149H10.9422C10.1976 2.63149 9.49788 2.8398 8.86247 3.25064C8.27069 3.63327 7.73453 4.19583 7.26891 4.92267L4.60938 9.07405C4.01878 9.99593 3.36813 10.4441 2.62019 10.4441H1C0.447719 10.4441 0 10.8918 0 11.4441C0 11.9964 0.447719 12.4441 1 12.4441H2.62019C3.36478 12.4441 4.06447 12.2358 4.69988 11.8249C5.29166 11.4423 5.82782 10.8797 6.29347 10.1529L8.95297 6.00149C9.54354 5.07961 10.1942 4.63149 10.9421 4.63149ZM13.6919 8.72171C13.3014 8.33117 12.6682 8.33117 12.2777 8.72171C11.8871 9.11224 11.8871 9.74539 12.2777 10.1359L12.5858 10.4441H10.9421C10.3988 10.4441 9.91385 10.2111 9.4596 9.73196C9.07963 9.33117 8.44669 9.31427 8.04588 9.69424C7.64507 10.0742 7.62819 10.7071 8.00816 11.108C8.84866 11.9945 9.83579 12.4441 10.9421 12.4441H12.5858L12.2776 12.7522C11.8871 13.1427 11.8871 13.7759 12.2776 14.1664C12.4729 14.3617 12.7288 14.4593 12.9847 14.4593C13.2406 14.4593 13.4966 14.3617 13.6918 14.1664L15.7071 12.1512C16.0976 11.7606 16.0976 11.1275 15.7071 10.737L13.6919 8.72171Z" />
  </Icon>
);

Shuffle.displayName = 'Shuffle';

export default Shuffle;
