import BookDemoButton from 'ms-pages/FreeTextbooks/FreeTextbooksBookDemoButton';
import AnchorButton from 'ms-ui-primitives/Button/AnchorButton';
import { loginUrl, signupSunflowerUrl } from 'ms-utils/urls';

export default function FreeTextbooksCTANavbar({
  context,
  hideAuthbuttons,
}: {
  context: string;
  hideAuthbuttons?: boolean;
}) {
  return (
    <div style={{ display: 'flex' }}>
      {!hideAuthbuttons && (
        <>
          <AnchorButton
            trackingId={`FreeTextbook/${context}/LogIn`}
            href={loginUrl}
            isRound
            type="secondary"
          >
            Log in
          </AnchorButton>
          <div style={{ width: 8 }} />
          <AnchorButton
            trackingId={`FreeTextbook/${context}/SignUp`}
            href={signupSunflowerUrl}
            isRound
            type="secondary"
          >
            Sign up
          </AnchorButton>
          <div style={{ width: 8 }} />
        </>
      )}
      <BookDemoButton
        trackingId={`FreeTextbook/${context}/BookDemo`}
        type="primary"
        snowplowEventLabel={context}
      />
    </div>
  );
}
