/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SelectAFolderModal_school = {
    readonly taskTemplatesAndGroups: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: "TaskTemplateGroup";
                readonly id: string;
                readonly title: string;
                readonly taskTemplateCount: number;
                readonly isDistrictShared: boolean;
                readonly taskTemplateGroupCount: number;
                readonly owningSchool: {
                    readonly id: string;
                } | null;
                readonly ancestorGroups: ReadonlyArray<{
                    readonly id: string;
                    readonly title: string;
                }>;
            } | {
                /*This will never be '%other', but we need some
                value in case none of the concrete values match.*/
                readonly __typename: "%other";
            };
        }>;
    };
    readonly id: string;
    readonly " $refType": "SelectAFolderModal_school";
};
export type SelectAFolderModal_school$data = SelectAFolderModal_school;
export type SelectAFolderModal_school$key = {
    readonly " $data"?: SelectAFolderModal_school$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SelectAFolderModal_school">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "taskTemplatesAndGroups"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "parentGroupId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SelectAFolderModalRefetchQuery.graphql.ts'),
      "identifierField": "id"
    }
  },
  "name": "SelectAFolderModal_school",
  "selections": [
    {
      "alias": "taskTemplatesAndGroups",
      "args": [
        {
          "kind": "Literal",
          "name": "authorship",
          "value": "TEACHER_CREATED"
        },
        {
          "kind": "Variable",
          "name": "parentGroupId",
          "variableName": "parentGroupId"
        },
        {
          "kind": "Variable",
          "name": "searchQuery",
          "variableName": "searchTerm"
        },
        {
          "kind": "Literal",
          "name": "typeFilter",
          "value": "FOLDERS"
        }
      ],
      "concreteType": "TaskTemplateOrGroupConnection",
      "kind": "LinkedField",
      "name": "__SelectAFolderModal_taskTemplatesAndGroups_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskTemplateOrGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskTemplateCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isDistrictShared",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskTemplateGroupCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "School",
                      "kind": "LinkedField",
                      "name": "owningSchool",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskTemplateGroup",
                      "kind": "LinkedField",
                      "name": "ancestorGroups",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "TaskTemplateGroup",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "type": "School",
  "abstractKey": null
};
})();
(node as any).hash = '8974f37889b1cc84c4c4fbf240cba7f4';
export default node;
