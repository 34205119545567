import { useMemo } from 'react';
import Helmet from 'react-helmet';

import { useSSRContext } from 'ms-helpers/SSR/SSRContext';

const SEPARATOR = ' :: ';

type Props = {
  subtitles: ReadonlyArray<string>;
  separator?: string | undefined;
};

export default function PageTitle({
  subtitles: _subtitles,
  separator = SEPARATOR,
}: Props) {
  const subtitles = useMemo(() => [..._subtitles, 'Mathspace'], [_subtitles]);

  const [setSSRData] = useSSRContext();

  setSSRData(context => ({
    ...context,
    meta: {
      ...context.meta,
      pageTitle: subtitles.join(separator),
    },
  }));
  return (
    <Helmet defer={false}>
      <title>{subtitles.join(separator)}</title>
    </Helmet>
  );
}
