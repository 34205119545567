/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AssignDiscoveryCheckInFlowQueryVariables = {
    schoolId: string;
};
export type AssignDiscoveryCheckInFlowQueryResponse = {
    readonly school: {
        readonly lanternCurriculum: {
            readonly strands: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
        } | null;
    } | null;
};
export type AssignDiscoveryCheckInFlowQuery = {
    readonly response: AssignDiscoveryCheckInFlowQueryResponse;
    readonly variables: AssignDiscoveryCheckInFlowQueryVariables;
};



/*
query AssignDiscoveryCheckInFlowQuery(
  $schoolId: ID!
) {
  school(id: $schoolId) {
    lanternCurriculum {
      strands {
        id
        title
      }
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "schoolId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "schoolId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Strand",
  "kind": "LinkedField",
  "name": "strands",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignDiscoveryCheckInFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssignDiscoveryCheckInFlowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "School",
        "kind": "LinkedField",
        "name": "school",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LanternCurriculum",
            "kind": "LinkedField",
            "name": "lanternCurriculum",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c48535912ed3ee8ceef2b0172af003bb",
    "id": null,
    "metadata": {},
    "name": "AssignDiscoveryCheckInFlowQuery",
    "operationKind": "query",
    "text": "query AssignDiscoveryCheckInFlowQuery(\n  $schoolId: ID!\n) {\n  school(id: $schoolId) {\n    lanternCurriculum {\n      strands {\n        id\n        title\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2fa09d741da1f698add46dae9d79030b';
export default node;
