import { useContext } from 'react';

import FormFieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import { getShortTitle } from 'ms-pages/Teacher/components/Navbar/SunflowerNavbar/SunflowerClassSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import { fontFamily, fontSize, fontWeight, inputHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Checkbox from 'ms-ui-primitives/Checkbox';
import Input from 'ms-ui-primitives/Input';
import { styled } from 'ms-utils/emotion';

import { StateContext, UpdatersContext } from '../state';

const InputWrapper = styled({
  default: {
    maxWidth: 320,
    position: 'relative',
  },
  fullWidth: {
    maxWidth: '100%',
  },
});

const ClassNameIcon = styled({
  position: 'absolute',
  width: 0.85 * inputHeight,
  height: 0.85 * inputHeight,
  borderRadius: '50%',
  backgroundColor: colors.snuff,
  top: '50%',
  right: 4,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: fontFamily.body,
  fontSize: fontSize.small,
  fontWeight: fontWeight.semibold,
  lineHeight: '22px',
  color: colors.blueGem,
  transform: 'translateY(-50%)',
});

type Props = {
  errorMessages: ErrorMessages;
  isFullWidth?: boolean;
  isUnsyncedClass?: boolean;
};

export default function ClassTitle({
  errorMessages,
  isFullWidth = false,
  isUnsyncedClass = false,
}: Props) {
  const { title, useTitleForDisplayName } = useContext(StateContext);
  const { updateTitle, updateDisplayName, toggleUseTitleForDisplayName } =
    useContext(UpdatersContext);

  return (
    <FieldGroup
      isFieldRequired
      title="Class name"
      infoTooltipText={
        isUnsyncedClass
          ? 'The class will not be linked with the educational platforms.'
          : undefined
      }
    >
      <InputWrapper fullWidth={isFullWidth}>
        <Input
          placeholder="e.g. 8A"
          value={title}
          onChange={e => {
            updateTitle(e.target.value);
            if (useTitleForDisplayName) updateDisplayName(e.target.value);
          }}
          showErrorState={errorMessages.length > 0}
        />
        {title.length >= 2 && (
          <ClassNameIcon>
            <span>{getShortTitle(title)}</span>
          </ClassNameIcon>
        )}
      </InputWrapper>
      <FormFieldError messages={errorMessages} />
      <Separator size={4 * BASE_UNIT} />
      <Checkbox
        label="Use for class display name"
        checked={useTitleForDisplayName}
        onChange={() => {
          if (useTitleForDisplayName) {
            updateDisplayName('');
          } else {
            updateDisplayName(title);
          }
          toggleUseTitleForDisplayName();
        }}
      />
    </FieldGroup>
  );
}
