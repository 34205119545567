import { useEffect, useRef } from 'react';

// Produces the previous *committed* value of `value`.
// Main use case is for detecting when a particular state transition has occurred.
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T | undefined>(undefined);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
