import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <rect x={1} y={2} width={18} height={6} rx={1} />
    <rect x={1} y={12} width={18} height={6} rx={1} />
  </>
);

const TwoHorizontalBars = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

TwoHorizontalBars.displayName = 'TwoHorizontalBars';

export default TwoHorizontalBars;
