/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type QuestionSetCompleteQueryVariables = {
    checkInId: string;
};
export type QuestionSetCompleteQueryResponse = {
    readonly lantern: {
        readonly checkIn: {
            readonly skillsDemonstrated: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
            }>;
        } | null;
    };
};
export type QuestionSetCompleteQuery = {
    readonly response: QuestionSetCompleteQueryResponse;
    readonly variables: QuestionSetCompleteQueryVariables;
};



/*
query QuestionSetCompleteQuery(
  $checkInId: ID!
) {
  lantern {
    checkIn(id: $checkInId) {
      skillsDemonstrated {
        id
        title
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "checkInId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "checkInId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skillsDemonstrated",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuestionSetCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuestionSetCompleteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Lantern",
        "kind": "LinkedField",
        "name": "lantern",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "CheckIn",
            "kind": "LinkedField",
            "name": "checkIn",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8a05e45f7e438d8c08e9ca424e42c949",
    "id": null,
    "metadata": {},
    "name": "QuestionSetCompleteQuery",
    "operationKind": "query",
    "text": "query QuestionSetCompleteQuery(\n  $checkInId: ID!\n) {\n  lantern {\n    checkIn(id: $checkInId) {\n      skillsDemonstrated {\n        id\n        title\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '61651be51d7ba457d89299763b953ece';
export default node;
