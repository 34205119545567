import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  createStudentMutationVariables,
  createStudentMutationResponse,
} from './__generated__/createStudentMutation.graphql';

export type { createStudentMutationVariables };
export const mutation = graphql`
  mutation createStudentMutation(
    $schoolId: ID!
    $firstName: String!
    $lastName: String!
    $classIds: [ID!]!
    $syllabusFocusId: ID!
    $email: String
    $guardianEmails: [String!]
    $parentNotifications: Boolean
    $isSunflowerUi: Boolean!
    $gradeId: ID!
  ) {
    createStudent(
      schoolId: $schoolId
      firstName: $firstName
      lastName: $lastName
      classIds: $classIds
      syllabusFocusId: $syllabusFocusId
      gradeId: $gradeId
      email: $email
      guardianEmails: $guardianEmails
      parentNotifications: $parentNotifications
    ) {
      errors {
        key
        message
      }
      student {
        id
        user {
          firstName
          lastName
        }
        ...ClassSettingsStudents_student
      }
    }
  }
`;
export default function useCreateStudent(
  variables: createStudentMutationVariables,
): UseMutationResponse<createStudentMutationResponse> {
  return useMutation({ mutation, variables });
}
