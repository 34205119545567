import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import Layout from 'ms-components/Layout/Layout';
import Logo from 'ms-components/logos/Logo';
import { LinkButton } from 'ms-pages/Lantern/primitives/Button';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import { HEADER_SIZE } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Container from 'ms-ui-primitives/Container';
import { HSpacer, HStack, VSpacer } from 'ms-ui-primitives/Stack';
import { signupSunflowerPath } from 'ms-utils/urls';

const Wrapper = styled.div<{}>({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
});

export default function DemoStudentLayout({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <Layout direction="column">
      <Layout.Fixed size={HEADER_SIZE}>
        <Container>
          <VSpacer height={20} />
          <HStack center>
            <Logo />
            <HSpacer width={0} grow />
            <BodyM>Sign up to create your class</BodyM>
            <LinkButton
              to={signupSunflowerPath}
              label="Sign up for free"
              trackingId="DemoStudent/Header/SignUpForFree"
            >
              <Button type="primary" color="eggplant">
                Sign up for free
              </Button>
            </LinkButton>
          </HStack>
        </Container>
      </Layout.Fixed>
      <Layout.Fill>
        <Wrapper>{children}</Wrapper>
      </Layout.Fill>
    </Layout>
  );
}
