import styled from '@emotion/styled';
import { contains, toPairs, map, sortBy, head } from 'ramda';
import { useEffect, useCallback, useState } from 'react';

import { fontSize, fontFamily, fontWeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { unwrap } from 'ms-utils/typescript-utils';

import Option, { MULTI_CHOICE_OPTION_MIN_WIDTH } from './Option';

type Html = string;
// keys should be in the format A, B, C, etc.
// The Html reaching this component is expected to have gone through a
// transformation step after being pulled from the database, which has
// removed all CKEditor-specific tags.
export type Value = Record<string, { value: Html; selected: boolean }>;
type Props = {
  value: Value;
  onChange?: ((value: Value) => void) | undefined;
  readOnly?: boolean | undefined;
  hasMultipleAnswers: boolean;
  isAnswerAttempt?: boolean | undefined;
  isLastAnswer?: boolean | undefined;
  correctAnswerFoundByStudent?: boolean | undefined;
};

const VERTICAL_PADDING = 2 * BASE_UNIT;

function toSortedPairs(value: Value) {
  // TS inference can't even handle sortBy(head, toPairs(value)). sigh
  const asPairs = toPairs(value);
  return sortBy(head, asPairs);
}

const GRID_GAP = 2 * BASE_UNIT;

const Wrapper = styled.div({
  alignItems: 'flex-start', // do not stretch children
  display: 'grid',
  gridAutoRows: '1fr',
  gridRowGap: GRID_GAP,
  gridColumnGap: GRID_GAP,
});

const OptionWrapper = styled.div({
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'stretch',
  height: '100%',
});

const AnswerExplanation = styled.div({
  fontSize: fontSize.medium,
  fontFamily: fontFamily.body,
  fontWeight: fontWeight.semibold,
  paddingTop: VERTICAL_PADDING,
  paddingBottom: VERTICAL_PADDING,
});

function MultipleChoice({
  value,
  onChange,
  hasMultipleAnswers,
  isAnswerAttempt,
  isLastAnswer,
  readOnly,
  correctAnswerFoundByStudent,
}: Props) {
  const [minimumOptionWidth, setMinimumOptionWidth] = useState(
    MULTI_CHOICE_OPTION_MIN_WIDTH,
  );

  const handleMinimumOptionWidth = useCallback<(width: number) => void>(
    width => {
      // We are only interested in the maximum minimumOptionWidth of
      // all the options for consistency
      if (width > minimumOptionWidth) {
        setMinimumOptionWidth(width);
      }
    },
    [minimumOptionWidth],
  );

  const handleOptionToggled = useCallback(
    (label: string) => {
      const newValue = {
        ...map<Value, Value>(
          o => ({ ...o, selected: hasMultipleAnswers ? o.selected : false }),
          value,
        ),
        [label]: {
          ...unwrap(value[label]),
          selected: hasMultipleAnswers ? !unwrap(value[label]).selected : true,
        },
      };

      if (onChange != null) onChange(newValue);
    },
    [hasMultipleAnswers, onChange, value],
  );

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      // Keydown events without a key property are commonly triggered by non-
      // physical keyboards (e.g. when autocorrect or suggestions occur)
      // We don't consider keyboard shortcuts on mobile devices to be of high
      // value, so bail out.
      if (event.key == null) return;

      const key = event.key.toUpperCase();

      if (contains(key, Object.keys(value))) {
        handleOptionToggled(key);
      }
    },
    [handleOptionToggled, value],
  );

  useEffect(() => {
    if (document != null) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (document != null) {
        document.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [handleKeydown]);

  const selectionColor = isAnswerAttempt
    ? colors.iron
    : isLastAnswer
    ? colors.mountainMeadow
    : colors.bayOfMany;

  // Get all of the options for this multiple choice question in the
  // more pragmatic form: [[label, optionDetails]]
  // where `label` is the option key (A, B, C,...), and optionDetails describes
  // the option for the student, as well as indicating whether it's selected currently.
  const sortedPairs = toSortedPairs(value);

  // The multiple choice options could be presented in multiple contexts.
  // If it's presented as an answer attempt for inline marking, as a step, we only want to
  // show the options the student had selected, to give the student context.
  //
  // Otherwise, we want to show all options (potentially with some selection indication).
  const optionList = sortedPairs
    .filter(pair => {
      if (isAnswerAttempt && !isLastAnswer) {
        return pair[1].selected;
      }
      return true;
    })
    .map(([key, option]) => (
      <OptionWrapper key={key}>
        <Option
          disabled={readOnly}
          label={key}
          value={option.value}
          selected={option.selected}
          onToggle={readOnly || !onChange ? null : handleOptionToggled}
          hasMultipleAnswers={hasMultipleAnswers}
          selectionColor={selectionColor}
          opacity={isLastAnswer && !option.selected ? 0.5 : 1}
          minWidth={minimumOptionWidth}
          onMinWidthChange={handleMinimumOptionWidth}
        />
      </OptionWrapper>
    ));

  const correctAnswers = sortedPairs
    .filter(pair => pair[1].selected)
    .map(pair => pair[0])
    .join(', ');
  const explanation = `The correct ${
    hasMultipleAnswers ? 'selection' : 'option'
  } was ${correctAnswers}`;

  return (
    <div>
      {isLastAnswer && (
        <AnswerExplanation
          style={{
            color: correctAnswerFoundByStudent
              ? colors.mountainMeadow
              : colors.cinnabar,
          }}
        >
          {explanation}
        </AnswerExplanation>
      )}

      <Wrapper
        style={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${minimumOptionWidth}px, max-content))`,
        }}
      >
        {optionList}
      </Wrapper>
    </div>
  );
}

export default MultipleChoice;
