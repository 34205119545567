import { css } from 'aphrodite';
import type { ReactNode, ComponentType } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { useSnowplow } from 'ms-helpers/Snowplow';
import ProgressStepper from 'ms-pages/Signup/Sunflower/ProgressStepper';
import { borderRadiusUILarge, fontWeight, transition } from 'ms-styles/base';
import { colors, lanternColors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Separator from 'ms-ui-primitives/Separator';
import { styled, tappable } from 'ms-utils/emotion';
import { assertUnreachable } from 'ms-utils/typescript-utils';
import {
  getSignupSunflowerSelectorUrl,
  loginUrl,
  signupSunflowerStudentUrl,
  signupSunflowerTeacherUrl,
  signupSunflowerParentUrl,
} from 'ms-utils/urls';

import { SignupInfoPanel } from './SignupInfoPanel';
import blackboardImg from './assets/blackboard.svg';
import hatImg from './assets/hat.svg';
import heartImg from './assets/heart.svg';
import parentImg from './assets/miloWithHeart.svg';
import selectedBackground from './assets/selectedBackground.svg';
import studentImg from './assets/student.svg';
import teacherImg from './assets/teacher.svg';
import { formStyles } from './styles';
import type { Role } from './types';

const Root = styled({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SwitchRoot = styled({
  display: 'flex',
  flexDirection: 'column',
});

const SwitchButton: ComponentType<{
  children: ReactNode;
  'aria-label': string;
  selected: boolean;
  onClick: () => void;
  'data-tracking-id': string;
}> = styled(
  {
    default: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      flexGrow: 1,
      margin: 0, // reset margin in Safari
      padding: 0,
      backgroundColor: 'inherit',
      color: lanternColors.grey,
      borderStyle: 'none',
      fontFamily: 'inherit',
      fontSize: 16,
      fontWeight: fontWeight.semibold,
      whiteSpace: 'nowrap',
      borderRadius: borderRadiusUILarge,
      boxShadow: '0px 1px 7px rgba(33, 55, 77, 0.12)',
      width: 320,
      ...tappable,
      height: 20 * BASE_UNIT,
      overflow: 'hidden',
    },
    selected: {
      height: 140,
      pointerEvents: 'none',
    },
  },
  'button',
);

const SwitchSeparator = styled({
  marginTop: 3 * BASE_UNIT,
});

const ImgWrapper = styled({
  default: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: 100,
  },
  selected: {
    backgroundImage: `url(${selectedBackground})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  },
});

const RadioButtonDisplay = styled(
  {
    default: {
      height: 20,
      width: 20,
      border: `2px solid ${colors.cloudBurst}`,
      margin: '0 8px 0 17px !important',
      transition: `background-color ${transition}, border-color ${transition}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      borderRadius: '50%',
    },
    checked: {
      boxShadow: `inset 0 0 0 3px ${colors.white}`,
      backgroundColor: colors.cloudBurst,
    },
  },
  'div',
);

function Switch({
  selectedRole,
  onRoleClick,
}: {
  selectedRole: Role | null;
  onRoleClick: (role: Role) => void;
}) {
  return (
    <SwitchRoot>
      <SwitchButton
        aria-label="I am a student"
        onClick={() => {
          onRoleClick('student');
        }}
        selected={selectedRole === 'student'}
        data-tracking-id="SignupPage/RoleSelector/IAmAStudent"
      >
        <RadioButtonDisplay checked={selectedRole === 'student'} />
        I'm a Student
        <ImgWrapper selected={selectedRole === 'student'}>
          <img
            alt="mathspace"
            role="presentation"
            src={selectedRole === 'student' ? studentImg : hatImg}
            height={selectedRole === 'student' ? 140 : 54}
          />
        </ImgWrapper>
      </SwitchButton>

      <SwitchSeparator />

      <SwitchButton
        aria-label="I am a teacher"
        onClick={() => {
          onRoleClick('teacher');
        }}
        selected={selectedRole === 'teacher'}
        data-tracking-id="SignupPage/RoleSelector/IAmATeacher"
      >
        <RadioButtonDisplay checked={selectedRole === 'teacher'} />
        I'm a Teacher
        <ImgWrapper selected={selectedRole === 'teacher'}>
          <img
            alt="mathspace"
            role="presentation"
            src={selectedRole === 'teacher' ? teacherImg : blackboardImg}
            height={selectedRole === 'teacher' ? 140 : 71}
          />
        </ImgWrapper>
      </SwitchButton>

      <SwitchSeparator />

      <SwitchButton
        aria-label="I am a parent"
        onClick={() => {
          onRoleClick('parent');
        }}
        selected={selectedRole === 'parent'}
        data-tracking-id="SignupPage/RoleSelector/IAmAParent"
      >
        <RadioButtonDisplay checked={selectedRole === 'parent'} />
        I'm a Parent
        <ImgWrapper selected={selectedRole === 'parent'}>
          <img
            alt="mathspace"
            role="presentation"
            src={selectedRole === 'parent' ? parentImg : heartImg}
            height={selectedRole === 'parent' ? 150 : 71}
          />
        </ImgWrapper>
      </SwitchButton>
    </SwitchRoot>
  );
}

function RoleSelector(props: RouteComponentProps<{ type?: Role }>) {
  const { trackStructEvent } = useSnowplow();
  const type = props.match.params.type || null;
  const { replace, push } = useHistory();
  return (
    <Root>
      <ProgressStepper step={1} />
      <div className={css(formStyles.roleSelectorContainer)}>
        <div className={css(formStyles.infoPanelContainer)}>
          <SignupInfoPanel selectedRole={type} />
        </div>
        <div className={css(formStyles.formContainer)}>
          <div className={css(formStyles.titleContainer)}>
            <div className={css(formStyles.title)}>Join Mathspace Personal</div>
          </div>
          <Switch
            selectedRole={type}
            onRoleClick={role => {
              replace(getSignupSunflowerSelectorUrl(role));
            }}
          />
          <Separator size={10} />
          <div className={css(formStyles.loginContainer)}>
            Already using Mathspace?
            <Button
              onClick={() => {
                trackStructEvent({
                  category: 'signup_sunflower',
                  action: 'clicked_login',
                  label: type || 'unspecified',
                });
                window.location.assign(loginUrl);
              }}
              trackingId="SignupPage/RoleSelector/Login"
              size="regular"
              type="tertiary"
            >
              Log into your account
            </Button>
          </div>
          <div className={css(formStyles.actionWrapper)}>
            <Button
              onClick={() => {
                if (type == null) return;
                trackStructEvent({
                  category: 'signup_sunflower',
                  action: 'clicked_signup',
                  label: type,
                });
                push(getSignupurlForUserType(type));
              }}
              trackingId="SignupPage/RoleSelector/Continue"
              size="large"
              type="primary"
              isRound
              isDisabled={type == null}
            >
              Continue &rsaquo;
            </Button>
          </div>
        </div>
      </div>
    </Root>
  );
}

export { RoleSelector, Switch };

function getSignupurlForUserType(userType: Role) {
  switch (userType) {
    case 'student':
      return signupSunflowerStudentUrl;
    case 'teacher':
      return signupSunflowerTeacherUrl;
    case 'parent':
      return signupSunflowerParentUrl;
    default: {
      assertUnreachable(userType, `Unexptected user type: ${userType}`);
    }
  }
}
