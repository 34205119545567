import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = <circle cx="10" cy="10" r="8" />;

const Circle = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

Circle.displayName = 'Circle';

export default Circle;
