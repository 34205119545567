import moment from 'moment-timezone';
import { useEffect } from 'react';
import { graphql } from 'react-relay';

import { useSignupState } from 'ms-pages/Signup/Sunflower/state';
import { InvariantViolation } from 'ms-utils/app-logging';
import useMutation from 'ms-utils/relay/useMutationWithArgs';
import type { UseMutationResponse } from 'ms-utils/relay/useMutationWithArgs';

import type {
  useCreateTeacherForSunflowerMutationResponse,
  useCreateTeacherForSunflowerMutationVariables,
} from './__generated__/useCreateTeacherForSunflowerMutation.graphql';

const mutation = graphql`
  mutation useCreateTeacherForSunflowerMutation(
    $country: ID!
    $countrySubdivision: ID
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $schoolReference: ID
    $schoolTitle: String
    $teacherInviteCode: ID
    $timezone: String!
    $syllabusFocus: ID!
    $classTitle: String!
  ) {
    createTeacherForSunflower(
      country: $country
      countrySubdivision: $countrySubdivision
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      schoolReference: $schoolReference
      schoolTitle: $schoolTitle
      teacherInviteCode: $teacherInviteCode
      timezone: $timezone
      classTitle: $classTitle
      syllabusFocus: $syllabusFocus
    ) {
      teacher {
        id
        user {
          snowplowId
        }
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateTeacherForSunflower(): UseMutationResponse<
  {},
  useCreateTeacherForSunflowerMutationResponse
> {
  const { dispatch, state } = useSignupState();
  const [mutate, results] = useMutation<
    useCreateTeacherForSunflowerMutationResponse,
    useCreateTeacherForSunflowerMutationVariables
  >({
    mutation,
  });
  useEffect(() => {
    if (
      results.response &&
      results.response.createTeacherForSunflower?.errors
    ) {
      dispatch({
        type: 'setErrors',
        value: results.response.createTeacherForSunflower?.errors,
      });
    }
  }, [dispatch, results.response]);
  return [
    () => {
      const { syllabusFocus, className } = state;
      if (syllabusFocus == null || className == null) {
        throw new InvariantViolation(
          'Cannot create teacher without class name and syllabus focus',
        );
      }
      mutate({
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        password: state.password,
        timezone: moment.tz.guess(),
        countrySubdivision: state.countrySubdivision?.id,
        country: state.country?.id ?? state.location,
        ...(state.schoolReference != null
          ? { schoolReference: state.schoolReference }
          : { schoolTitle: state.schoolTitle }),
        syllabusFocus: syllabusFocus.id,
        classTitle: className,
      });
    },
    results,
  ];
}
