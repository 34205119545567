import LatexSvg from 'ms-components/LatexSvg';
import toLatex from 'ms-utils/math/toLatex';
import type { Mode } from 'ms-utils/math/toLatex';

type Props = {
  color?: string | undefined;
  increment?: number | undefined;
  label?: number | undefined;
  major?: boolean | undefined;
  mode?: Mode | undefined;
  unit?: string | undefined;
  x: number;
};

export default function NumberLineTickMark({
  color = '#888',
  increment,
  label,
  major = false,
  mode,
  unit = '',
  x,
}: Props) {
  const height = major ? 25 : 17;
  return (
    <g transform={`translate(${x},${-height / 2})`}>
      <line stroke={color} strokeWidth={1} x1={0} x2={0} y1={0} y2={height} />
      {label !== undefined ? (
        <LatexSvg y={height + 20}>
          {`${toLatex(label, mode, increment ? 1 / increment : 10)}${
            unit || ''
          }`}
        </LatexSvg>
      ) : null}
    </g>
  );
}
