import { graphql } from 'react-relay';
import { useQuery } from 'relay-hooks';

import useTeacherContext from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import ErrorCard from 'ms-pages/Teacher/components/ErrorCard';
import MinorSpinner from 'ms-pages/Teacher/components/MinorSpinner';
import { ModalBody } from 'ms-pages/Teacher/components/Modal';
import Modal from 'ms-ui-primitives/Modal';
import { InvariantViolation } from 'ms-utils/app-logging';
import { RELAY_CONNECTION_MAX } from 'ms-utils/relay';

import ChangeStudentTextbookFocus from './ChangeStudentTextbookFocus';
import type {
  ChangeStudentTextbookFocusModalQuery,
  ChangeStudentTextbookFocusModalQueryResponse,
} from './__generated__/ChangeStudentTextbookFocusModalQuery.graphql';

export type Student = NonNullable<
  ChangeStudentTextbookFocusModalQueryResponse['student']
>;
const query = graphql`
  query ChangeStudentTextbookFocusModalQuery(
    $schoolId: ID!
    $studentId: ID!
    $numberOfSyllabuses: Int!
  ) {
    school(id: $schoolId) {
      availableSyllabuses(limit: $numberOfSyllabuses) {
        id
        title
      }
    }
    student(id: $studentId) {
      id
      user {
        firstName
        lastName
        syllabusFocus {
          id
          title
        }
      }
    }
  }
`;
export default function ChangeStudentTextbookFocusModal({
  isOpen,
  closeModal,
  studentId,
}: {
  isOpen: boolean;
  closeModal: () => void;
  studentId: string | null | undefined;
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayTap={false}
      theme="dark"
    >
      {studentId != null && (
        <ModalContent closeModal={closeModal} studentId={studentId} />
      )}
    </Modal>
  );
}
function ModalContent({
  closeModal,
  studentId,
}: {
  closeModal: () => void;
  studentId: string;
}) {
  const { schoolId } = useTeacherContext();
  const { props, error } = useQuery<ChangeStudentTextbookFocusModalQuery>(
    query,
    {
      schoolId,
      studentId,
      numberOfSyllabuses: RELAY_CONNECTION_MAX,
    },
  );
  if (error != null) return <ErrorCard />;
  if (props == null) return <MinorSpinner />;
  if (props.school == null) {
    throw new InvariantViolation(`The school does not exist`);
  }
  const { availableSyllabuses } = props.school;
  if (availableSyllabuses == null) {
    throw new InvariantViolation(`The school has no available syllabuses`);
  }
  if (props.student == null) {
    throw new InvariantViolation(`The student does not exist`);
  }
  return (
    <ModalBody>
      <ChangeStudentTextbookFocus
        items={availableSyllabuses}
        student={props.student}
        closeModal={closeModal}
      />
    </ModalBody>
  );
}
