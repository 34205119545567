import { graphql } from 'react-relay';

import type { UseMutationResponse } from 'ms-utils/relay/useMutation';
import useMutation from 'ms-utils/relay/useMutation';

import type {
  createCustomTaskFromTaskTemplateMutationResponse,
  createCustomTaskFromTaskTemplateMutationVariables,
} from './__generated__/createCustomTaskFromTaskTemplateMutation.graphql';

export const customTaskFromTaskTemplateMutation = graphql`
  mutation createCustomTaskFromTaskTemplateMutation(
    $title: String!
    $assignedClassIds: [ID!]!
    $assignedStudentIds: [ID!]!
    $partiallyAssignedClasses: [PartiallyAssignedClassInput!]!
    $dueDate: DateTime!
    $enableHelp: Boolean!
    $enableCalculator: Boolean!
    $enableRetry: Boolean!
    $extensionDays: Int!
    $randomise: Boolean!
    $startDate: DateTime!
    $sourceTaskTemplateId: ID!
  ) {
    createCustomTaskFromTaskTemplate(
      title: $title
      assignedClassIds: $assignedClassIds
      assignedStudentIds: $assignedStudentIds
      partiallyAssignedClasses: $partiallyAssignedClasses
      dueDate: $dueDate
      enableHelp: $enableHelp
      enableCalculator: $enableCalculator
      enableRetry: $enableRetry
      extensionDays: $extensionDays
      randomiseQuestions: $randomise
      startDate: $startDate
      taskTemplateId: $sourceTaskTemplateId
    ) {
      task {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;
export default function useCreateCustomTaskFromTaskTemplateMutation(
  variables: createCustomTaskFromTaskTemplateMutationVariables,
): UseMutationResponse<createCustomTaskFromTaskTemplateMutationResponse> {
  return useMutation({
    mutation: customTaskFromTaskTemplateMutation,
    variables,
  });
}
