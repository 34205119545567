import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

export function Eye({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxHeight={16}
      viewBoxWidth={16}
    >
      <g clipPath="url(#clip0)">
        <path d="M8.50034 10.4636C9.86074 10.4636 10.9636 9.36074 10.9636 8.00034C10.9636 6.63993 9.86074 5.53711 8.50034 5.53711C7.13993 5.53711 6.03711 6.63993 6.03711 8.00034C6.03711 9.36074 7.13993 10.4636 8.50034 10.4636Z" />
        <path d="M16.2551 7.31859C14.3617 5.03056 11.4955 2.82812 8.50052 2.82812C5.50492 2.82812 2.63812 5.0321 0.745964 7.31859C0.418663 7.71394 0.418663 8.28787 0.745964 8.68322C1.22168 9.25808 2.21898 10.3678 3.55097 11.3374C6.90555 13.7794 10.0881 13.7848 13.4501 11.3374C14.7821 10.3678 15.7794 9.25808 16.2551 8.68322C16.5814 8.28864 16.5831 7.71523 16.2551 7.31859ZM8.50052 4.55239C10.4021 4.55239 11.949 6.09929 11.949 8.00091C11.949 9.90252 10.4021 11.4494 8.50052 11.4494C6.59891 11.4494 5.052 9.90252 5.052 8.00091C5.052 6.09929 6.59891 4.55239 8.50052 4.55239Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default Eye;
