import { StyleSheet, css } from 'aphrodite';
import { clamp } from 'ramda';
import {
  useState,
  useCallback,
  type MouseEvent as SyntheticMouseEvent,
  type TouchEvent as SyntheticTouchEvent,
  useEffect,
  useRef,
} from 'react';

import { unwrap } from 'ms-utils/typescript-utils';

import Axis from './Axis';
import Bins from './Bins';
import Categories from './Categories';
import DragHandles from './DragHandles';
import Edges from './Edges';
import Increments from './Increments';
import Labels from './Labels';
import ValueTracker from './ValueTracker';
import {
  getNewCategoryValue,
  updateValue,
  calculateBinWidth,
  getClientY,
} from './utils';

function noop() {}

export type CategoryData = ReadonlyArray<{
  readonly title: string;
  readonly value: number;
}>;

export type IncrementData = {
  max: number;
  min: number;
  step: number;
  tick: number;
};

export type LabelData = {
  main: string | null | undefined;
  xAxis: string;
  yAxis: string;
};

export type SpacingData = {
  availableSpace: number;
  incrementHeight: number;
  maxBound: number;
  minBound: number;
  padding: number;
  size: number;
};

type Props = {
  value?: CategoryData;
  size?: number;
  padding?: number;
  labels?: { main: string | null | undefined; xAxis: string; yAxis: string };
  increment?: IncrementData;
  doesDrawBars?: boolean;
  doesDrawLines?: boolean;
  dragHandleAlignment?: 'center' | 'right';
  hasGapBetweenBars?: boolean;
  readOnly?: boolean;
  onChange?: (value: CategoryData) => void;
};

const styles = StyleSheet.create({
  svg: {
    cursor: 'default',
    userSelect: 'none',
    touchAction: 'none',
  },
});

export default function Histogram(props: Props) {
  const {
    value = [{ title: '', value: 0 }],
    size = 500,
    padding = 10,
    labels = {
      main: '',
      xAxis: '',
      yAxis: '',
    },
    increment = {
      max: 10,
      min: 0,
      step: 1,
      tick: 5,
    },
    doesDrawBars = false,
    doesDrawLines = false,
    dragHandleAlignment = 'center',
    hasGapBetweenBars = false,
    readOnly = false,
    onChange = noop,
  } = props;

  const [targetBin, setTargetBin] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [y0, setY0] = useState<number>(0);
  const [currentValue, setCurrentValue] = useState<number>(0);

  const rootRef = useRef<SVGSVGElement | null>(null);

  const preventWindowPanning = useCallback(
    (event: TouchEvent) => {
      if (isDragging) event.preventDefault();
    },
    [isDragging],
  );

  const handleDrag = useCallback(
    (
      e: SyntheticMouseEvent<any> | SyntheticTouchEvent<any>,
      spacing: SpacingData,
      increment: IncrementData,
      value: CategoryData,
    ) => {
      if (!isDragging) return;
      const newValue = clamp(
        increment.min,
        increment.max,
        getNewCategoryValue(e, spacing, increment, currentValue, y0),
      );
      const newCategoryList = updateValue(value, targetBin, newValue);
      onChange(newCategoryList);
    },
    [onChange, currentValue, isDragging, targetBin, y0],
  );

  const handleDragEnd = useCallback(() => {
    setTargetBin(0);
    setIsDragging(false);
    setY0(0);
    setCurrentValue(0);
    window.removeEventListener('mouseup', handleDragEnd);
    window.removeEventListener('touchend', handleDragEnd);
    window.removeEventListener('touchcancel', handleDragEnd);
  }, []);

  const handleDragStart = useCallback(
    (
      position: number,
      e: SyntheticMouseEvent<any> | SyntheticTouchEvent<any>,
    ) => {
      e.preventDefault(); // Prevent touch scroll/panning while dragging
      window.addEventListener('mouseup', handleDragEnd);
      window.addEventListener('touchend', handleDragEnd);
      window.addEventListener('touchcancel', handleDragEnd);

      const y0 = getClientY(e);
      const currentValue = unwrap(value[position]).value;
      setTargetBin(position);
      setIsDragging(true);
      setY0(y0);
      setCurrentValue(currentValue);
    },
    [handleDragEnd, value],
  );

  useEffect(
    () => {
      const root = rootRef.current;

      if (root != null) {
        // We need to use raw DOM event listeners due to the iOS >= 11.3 bug
        // where you cannot cancel touchmove events when using React's synthetic
        // event system because document event listeners are passive by default now
        root.addEventListener('touchmove', preventWindowPanning);
      }
      return () => {
        if (root != null) {
          root.removeEventListener('touchmove', preventWindowPanning);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const minBound = 50;
  const maxBound = size - padding * 2 - minBound;
  const incrementHeight =
    (maxBound - minBound) / (increment.max - increment.min);
  const spacing = {
    availableSpace: maxBound - minBound,
    incrementHeight,
    maxBound,
    minBound,
    padding,
    size,
  };
  const graphDimensions = {
    height: size,
    width: size,
  };
  const targetValue = value[targetBin]?.value;
  const dragHandleSize = 10;

  const axisPadding = Math.round(
    hasGapBetweenBars
      ? 0
      : calculateBinWidth(spacing, value, hasGapBetweenBars) / 2,
  );

  return (
    <svg
      ref={rootRef}
      style={graphDimensions}
      className={css(styles.svg)}
      onMouseMove={e => {
        handleDrag(e, spacing, increment, value);
      }}
      onTouchMove={e => {
        handleDrag(e, spacing, increment, value);
      }}
      onTouchEnd={handleDragEnd}
      viewBox={`0,0,${graphDimensions.width},${graphDimensions.height}`}
    >
      <Labels spacing={spacing} labels={labels} />
      <g transform={`translate(${spacing.padding},0)`}>
        <Increments increment={increment} spacing={spacing} />
        <Axis spacing={spacing} />

        <g transform={`translate(${spacing.minBound + axisPadding},0)`}>
          <Categories
            categories={value}
            spacing={spacing}
            hasGapBetweenBars={hasGapBetweenBars}
          />
          {doesDrawBars && (
            <Bins
              categories={value}
              increment={increment}
              readOnly={readOnly}
              spacing={spacing}
              hasGapBetweenBars={hasGapBetweenBars}
            />
          )}
          {doesDrawLines && (
            <Edges
              categories={value}
              increment={increment}
              spacing={spacing}
              dragHandleAlignment={dragHandleAlignment}
              dragHandleSize={dragHandleSize}
              hasGapBetweenBars={hasGapBetweenBars}
            />
          )}
        </g>
        {isDragging && targetValue !== undefined && (
          <ValueTracker
            increment={increment}
            spacing={spacing}
            targetValue={targetValue}
          />
        )}
        <g transform={`translate(${spacing.minBound + axisPadding},0)`}>
          {!readOnly && (
            <DragHandles
              categories={value}
              increment={increment}
              spacing={spacing}
              dragHandleAlignment={dragHandleAlignment}
              dragHandleSize={dragHandleSize}
              hasGapBetweenBars={hasGapBetweenBars}
              onInteract={handleDragStart}
            />
          )}
        </g>
      </g>
    </svg>
  );
}
