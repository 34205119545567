import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g transform="translate(5.000000, 3.000000)">
    <path d="M3.66666667,0.102447353 C6.01242175,0.451998481 8.61239677,1.17059065 10.2471082,0.550832839 C10.5288187,0.444029728 11,0.363639789 11,0.88602466 C11,1.60626931 11,3.80381336 11,7.4786568 C10.3888889,8.57142857 8.86111111,8.84462151 6.41666667,8.29823563 C3.97222222,7.75184974 1.83333333,7.75184974 0,8.29823563 L0,0.922026181 C0.611111111,0.102447353 1.83333333,-0.170745589 3.66666667,0.102447353 Z" />
    <rect x="0" y="4.14285714" width="2" height="11" rx="0.5" />
  </g>
);

const Flag = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);
Flag.displayName = 'Flag';

export default Flag;
