import { useCallback, useContext } from 'react';

import ClassIcon from 'ms-components/icons/Class';
import FieldError, {
  type ErrorMessages,
} from 'ms-pages/Teacher/components/FormFieldError';
import FieldGroup from 'ms-pages/Teacher/components/FormFieldGroup';
import { Description } from 'ms-pages/Teacher/components/FormFieldTitle';
import InputSelector, {
  type Item,
} from 'ms-pages/Teacher/components/InputSelector';
import Separator from 'ms-pages/Teacher/components/Separator';
import {
  StateContext,
  UpdatersContext,
} from 'ms-pages/Teacher/components/StudentCreateEdit/state';
import type { SchoolClasses } from 'ms-pages/Teacher/components/StudentCreateEdit/types';
import {
  getClassSyllabusFocus,
  mapClassesToItems,
} from 'ms-pages/Teacher/components/StudentCreateEdit/utils';
import { fontWeight } from 'ms-styles/base';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import { styled } from 'ms-utils/emotion';

const FieldDescription = styled({
  marginTop: BASE_UNIT,
  display: 'flex',
});

const Bold = styled({
  fontWeight: fontWeight.semibold,
});

type Props = {
  schoolClasses: SchoolClasses;
  // Anticipate students with no class. In Waypoints, students can have no classes as
  // they can join by using the school join code instead.
  requireStudentInClass: boolean;
  errorMessages: ErrorMessages;
};

const StudentClasses = ({
  schoolClasses,
  requireStudentInClass,
  errorMessages,
}: Props) => {
  const { classes, syllabusFocusSelectedClass, useClassSyllabusFocus } =
    useContext(StateContext);
  const {
    addClass,
    removeSyllabusFocusSelectedClass,
    removeClass,
    updateSyllabusFocusSelectedClass,
  } = useContext(UpdatersContext);

  const onDropdownSelect = useCallback(
    (klass: Item) => {
      if (useClassSyllabusFocus && classes.length === 0) {
        const syllabusFocus = getClassSyllabusFocus(schoolClasses, klass);
        updateSyllabusFocusSelectedClass(syllabusFocus, klass);
      }
      addClass(klass);
    },
    [
      addClass,
      schoolClasses,
      classes.length,
      updateSyllabusFocusSelectedClass,
      useClassSyllabusFocus,
    ],
  );

  const removeSelectedItem = useCallback(
    (classId: string) => {
      if (useClassSyllabusFocus && classId === syllabusFocusSelectedClass.id)
        removeSyllabusFocusSelectedClass();
      removeClass(classId);
    },
    [
      removeClass,
      removeSyllabusFocusSelectedClass,
      syllabusFocusSelectedClass.id,
      useClassSyllabusFocus,
    ],
  );

  return (
    <FieldGroup
      isFieldRequired={requireStudentInClass}
      title="Classes"
      description={
        <FieldDescription>
          <Description>
            The student will be added to the following classes
          </Description>
          {classes.length > 0 && (
            <>
              <Separator size={2 * BASE_UNIT} grow />
              <Description>
                <Bold>
                  {classes.length} {classes.length === 1 ? 'class' : 'classes'}
                </Bold>
              </Description>
            </>
          )}
        </FieldDescription>
      }
    >
      <InputSelector
        items={mapClassesToItems(schoolClasses)}
        selectedItems={classes}
        removeSelectedItem={removeSelectedItem}
        onDropdownSelect={onDropdownSelect}
        icon={<ClassIcon size={3.5 * BASE_UNIT} />}
        showErrorState={errorMessages.length > 0}
      />
      <FieldError messages={errorMessages} />
    </FieldGroup>
  );
};

export default StudentClasses;
