import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g fill="none">
    <rect
      x="1"
      y="1"
      width="18"
      height="12"
      rx="6"
      stroke="currentcolor"
      strokeWidth="2"
    />
    <rect
      x="5"
      y="5"
      width="18"
      height="12"
      rx="6"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </g>
);

const Link = ({ color, size, title, titleId, aphroditeStyles = [] }: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={24}
    viewBoxHeight={16}
  >
    {path}
  </Icon>
);

Link.displayName = 'Link';

export default Link;
