/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StudentStrandStatusEnum = "DIAGNOSTIC" | "GROWTH";
export type StudentSkillsMapSubstrandSkills_studentStrandStatus = {
    readonly status: StudentStrandStatusEnum;
    readonly " $refType": "StudentSkillsMapSubstrandSkills_studentStrandStatus";
};
export type StudentSkillsMapSubstrandSkills_studentStrandStatus$data = StudentSkillsMapSubstrandSkills_studentStrandStatus;
export type StudentSkillsMapSubstrandSkills_studentStrandStatus$key = {
    readonly " $data"?: StudentSkillsMapSubstrandSkills_studentStrandStatus$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"StudentSkillsMapSubstrandSkills_studentStrandStatus">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StudentSkillsMapSubstrandSkills_studentStrandStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "StudentStrandStatus",
  "abstractKey": null
};
(node as any).hash = 'f0c08b543c8a4fbca5cd991f6e5a74b2';
export default node;
