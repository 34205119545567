import { lazy, Suspense } from 'react';
import { graphql } from 'react-relay';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import type { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

import { Snackbar } from 'ms-components/Snackbar';
import { useViewer } from 'ms-helpers/Viewer/Renderer';
import {
  CheckInSignup,
  CheckInSignupRecommendation,
} from 'ms-pages/DemoStudent/CTAModals';
import DemoStudentWelcome from 'ms-pages/DemoStudent/DemoStudentWelcome';
import DemoStudentLayout from 'ms-pages/DemoStudent/Layout';
import { DbCooper } from 'ms-pages/DemoStudent/utils';
import {
  checkInPath,
  checkInStrandDiagnosticPath,
  studentSkillsSubstrandPath,
} from 'ms-pages/Lantern/utils/urls';
import type { StudentSkillSubstrandUrlParams } from 'ms-pages/Lantern/utils/urls';
import type { DemoInformation } from 'ms-pages/Lantern/views/Student/CheckIn/CheckIn';
import { StudentContextProvider } from 'ms-pages/Lantern/views/Student/StudentContext';
import StudentSkillsMapSubstrand from 'ms-pages/Lantern/views/Student/StudentSkillsMap/StudentSkillsMapSubstrand';
import Sunflower from 'ms-pages/Signup/Sunflower';
import MajorSpinner from 'ms-pages/Teacher/components/MajorSpinner';
import { InvariantViolation } from 'ms-utils/app-logging';
import { useBoolean } from 'ms-utils/hooks/useBoolean';
import { signupSunflowerPath } from 'ms-utils/urls';

import type { DemoStudentDetailsQuery } from './__generated__/DemoStudentDetailsQuery.graphql';

const CheckIn = lazy(
  async () => import('ms-pages/Lantern/views/Student/CheckIn'),
);
const CheckInStartDiagnostic = lazy(async () => {
  const { CheckInStartDiagnostic } = await import(
    'ms-pages/Lantern/views/Student/CheckIn/CheckInStrand'
  );
  return { default: CheckInStartDiagnostic };
});
export const RECOMMENDATION_MODAL_TRIGGER = 'self-directed-task';
type Props = {
  config: {
    location: string;
  };
};
/**
 * Demo students are part of the "try mathspace without an account" set of features where
 * we do actually make an account in the background. These users (marked by a feature flag via
 * packages) have a very restricted view of the mathspace app. They are able to do check-ins
 * and view the skills map.
 *
 * A lantern profile is guaranteed to exist for this user and it is considered an invariant
 * to not have one available.
 */
function DemoStudentInner(props: Props) {
  const checkinSignup = useBoolean();
  const recommendationSignup = useBoolean();
  const { props: studentData } = useQuery<DemoStudentDetailsQuery>(
    DEMO_STUDENT_QUERY,
    {},
  );
  if (studentData == null) return <MajorSpinner />;
  const demoInformation: DemoInformation = {
    curriculum: {
      curriculumTitle:
        studentData.viewer?.profile?.lanternStudent?.selfReportedGrade
          ?.curriculum?.title ?? '',
      gradeLevel:
        studentData.viewer?.profile?.lanternStudent?.selfReportedGrade
          ?.shortTitle ?? '',
      strandName:
        studentData.viewer?.profile?.lanternStudent?.checkIns?.find(() => true)
          ?.strand?.title ?? '',
    },
  };
  return (
    <Suspense fallback={<MajorSpinner />}>
      <Switch>
        <Route
          path={checkInStrandDiagnosticPath}
          children={<CheckInStartDiagnostic />}
        />
        <Route
          exact
          path={checkInPath}
          component={() => <CheckIn demoInformation={demoInformation} />}
        />
        <Route
          exact
          path={studentSkillsSubstrandPath}
          render={({
            match,
          }: RouteComponentProps<StudentSkillSubstrandUrlParams>) => (
            <DemoStudentLayout>
              <DbCooper
                onNavigate={href => {
                  // TODO: Check if the url being navigated to is a reco url
                  // and toggle the other modal instead
                  if (
                    href != null &&
                    href.includes(RECOMMENDATION_MODAL_TRIGGER)
                  ) {
                    recommendationSignup.setTrue();
                    return true;
                  }
                  if (href != null && href.includes('check-in')) {
                    checkinSignup.setTrue();
                    return true;
                  }
                  return false;
                }}
              >
                <StudentSkillsMapSubstrand
                  strandId={match.params.strandId}
                  substrandId={match.params.substrandId}
                  hideSkillsMapLink
                />
              </DbCooper>
            </DemoStudentLayout>
          )}
        />
        <Route
          path={[signupSunflowerPath]}
          children={<Sunflower config={props.config} />}
        />
        <Route>
          <DemoStudentWelcome />
        </Route>
      </Switch>
      <CheckInSignup
        onClose={() => {
          checkinSignup.setFalse();
        }}
        isOpen={checkinSignup.value}
      />
      <CheckInSignupRecommendation
        onClose={recommendationSignup.setFalse}
        isOpen={recommendationSignup.value}
      />
      <Snackbar rightAligned />
    </Suspense>
  );
}
export default function DemoStudent(props: Props) {
  const { lanternProfileId, role } = useViewer();
  if (role !== 'Student') {
    throw new InvariantViolation('Demo student is not a student');
  }
  if (lanternProfileId == null) {
    throw new InvariantViolation('Demo users must have a lantern profile');
  }
  return (
    <BrowserRouter basename="/demo/student">
      <StudentContextProvider lanternProfileId={lanternProfileId}>
        <DemoStudentInner config={props.config} />
      </StudentContextProvider>
    </BrowserRouter>
  );
}
const DEMO_STUDENT_QUERY = graphql`
  query DemoStudentDetailsQuery {
    viewer {
      profile {
        ... on Student {
          lanternStudent {
            selfReportedGrade {
              id
              shortTitle
              curriculum {
                id
                title
              }
            }
            checkIns {
              id
              strand {
                title
              }
            }
          }
        }
      }
    }
  }
`;
