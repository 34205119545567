/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SchoolFormAvailableSchoolsSunflowerQueryVariables = {
    countryId: string;
    subdivisionId?: string | null | undefined;
};
export type SchoolFormAvailableSchoolsSunflowerQueryResponse = {
    readonly availableSchoolReferences: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly suburb: string;
        readonly postalCode: string;
    }>;
};
export type SchoolFormAvailableSchoolsSunflowerQuery = {
    readonly response: SchoolFormAvailableSchoolsSunflowerQueryResponse;
    readonly variables: SchoolFormAvailableSchoolsSunflowerQueryVariables;
};



/*
query SchoolFormAvailableSchoolsSunflowerQuery(
  $countryId: ID!
  $subdivisionId: ID
) {
  availableSchoolReferences(country: $countryId, countrySubdivision: $subdivisionId) {
    id
    title
    suburb
    postalCode
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subdivisionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "country",
        "variableName": "countryId"
      },
      {
        "kind": "Variable",
        "name": "countrySubdivision",
        "variableName": "subdivisionId"
      }
    ],
    "concreteType": "SchoolReference",
    "kind": "LinkedField",
    "name": "availableSchoolReferences",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "suburb",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolFormAvailableSchoolsSunflowerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolFormAvailableSchoolsSunflowerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8e0009ea5da994a210d8a4a5cd0e462c",
    "id": null,
    "metadata": {},
    "name": "SchoolFormAvailableSchoolsSunflowerQuery",
    "operationKind": "query",
    "text": "query SchoolFormAvailableSchoolsSunflowerQuery(\n  $countryId: ID!\n  $subdivisionId: ID\n) {\n  availableSchoolReferences(country: $countryId, countrySubdivision: $subdivisionId) {\n    id\n    title\n    suburb\n    postalCode\n  }\n}\n"
  }
};
})();
(node as any).hash = '46262142445d528fb51d1bb01c2d2279';
export default node;
