/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CheckInSessionMultipleChoiceQuestion = {
    readonly id: string;
    readonly sanaItemId: string;
    readonly transpiledText: string;
    readonly transpiledOptions: ReadonlyArray<string>;
    readonly numberOfAnswers: number;
    readonly __typename: "MultipleChoiceQuestion";
};
export type CheckInSessionMultipleChoiceQuestion$data = CheckInSessionMultipleChoiceQuestion;
export type CheckInSessionMultipleChoiceQuestion$key = {
    readonly " $data"?: CheckInSessionMultipleChoiceQuestion$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"CheckInSessionMultipleChoiceQuestion">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "CheckInSessionMultipleChoiceQuestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sanaItemId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transpiledText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transpiledOptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfAnswers",
      "storageKey": null
    }
  ],
  "type": "MultipleChoiceQuestion",
  "abstractKey": null
};
(node as any).hash = 'c29f00406591e3f69c78122b4e3c00a6';
export default node;
