import type { QueryParamState as SubtopicDetailViewQueryParamState } from 'ms-pages/Textbooks/components/SubtopicDetailView/helpers';
import { serialize } from 'ms-utils/urls/queryParams';

export const textbooksBasePath = '/textbooks';
export const getTextbookBaseUrl = '/textbooks';
export const textbooksLegacyToRedirectBasePath = '/textbooks';
export const contentBrowsingBasePath = `${textbooksBasePath}/syllabuses`;
export const textbooksRedirectorPath = textbooksBasePath;

export type ContentBrowsingEmptyViewUrlParams = {
  syllabusId: string;
};
export const contentBrowsingEmptyViewPath = `${contentBrowsingBasePath}/:syllabusId/`;

export const getContentBrowsingEmptyViewUrl = ({
  syllabusId,
}: ContentBrowsingEmptyViewUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/`;

export type ContentBrowsingChapterIntroViewUrlParams = {
  syllabusId: string;
};
export const contentBrowsingChapterIntroViewPath = `${contentBrowsingBasePath}/:syllabusId/intro`;

export const getContentBrowsingChapterIntroUrl = ({
  syllabusId,
}: ContentBrowsingChapterIntroViewUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/intro`;

/* Topic overview URL */
export type ContentBrowsingTopicOverviewUrlParams = {
  syllabusId: string;
  topicId: string;
};
export const contentBrowsingTopicOverviewPath = `${contentBrowsingBasePath}/:syllabusId/topics/:topicId/overview`;

export const getContentBrowsingTopicOverviewUrl = ({
  syllabusId,
  topicId,
}: ContentBrowsingTopicOverviewUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/topics/${topicId}/overview`;

/* Materials Guide URL */
export type ContentBrowsingMaterialsGuideUrlParams = {
  syllabusId: string;
};
export const contentBrowsingMaterialsGuidePath = `${contentBrowsingBasePath}/:syllabusId/materialsguide`;

export const getContentBrowsingMaterialsGuideUrl = ({
  syllabusId,
}: ContentBrowsingMaterialsGuideUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/materialsguide`;

export const contentBrowsingSubtopicRedirectorPath = `${contentBrowsingBasePath}/:syllabusId/topics/:topicId`;
export type ContentBrowsingSubtopicRedirectorUrlParams = {
  syllabusId: string;
  topicId: string;
};

export const getContentBrowsingSubtopicRedirectorUrl = ({
  syllabusId,
  topicId,
}: ContentBrowsingSubtopicRedirectorUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/topics/${topicId}/`;

export const contentBrowsingSubtopicDetailViewPath = `${contentBrowsingBasePath}/:syllabusId/topics/:topicId/subtopics/:subtopicId/`;

export const contentBrowsingSyllabusRedirectorUrl = () =>
  `${textbooksBasePath}`;

export type ContentBrowsingSubtopicDetailViewUrlParams = {
  syllabusId: string;
  topicId: string;
  subtopicId: string;
};

export const getContentBrowsingSubtopicDetailViewUrl = ({
  syllabusId,
  topicId,
  subtopicId,
}: ContentBrowsingSubtopicDetailViewUrlParams) =>
  `${contentBrowsingBasePath}/${syllabusId}/topics/${topicId}/subtopics/${subtopicId}/`;

export const getTextbookTheoryUrl = (
  params: ContentBrowsingSubtopicDetailViewUrlParams,
) => {
  const queryParams: SubtopicDetailViewQueryParamState = {
    subtopicTab: 'lesson',
  };
  return `${getContentBrowsingSubtopicDetailViewUrl(params)}${serialize(
    queryParams,
  )}`;
};

export const getTextbookWorksheetUrl = (
  params: ContentBrowsingSubtopicDetailViewUrlParams,
) => {
  const queryParams: SubtopicDetailViewQueryParamState = {
    subtopicTab: 'worksheet',
  };
  return `${getContentBrowsingSubtopicDetailViewUrl(params)}${serialize(
    queryParams,
  )}`;
};

export const textbooksLegacyToRedirectEmptyViewPath = `${textbooksLegacyToRedirectBasePath}/:syllabusId`;
export const textbooksLegacyToRedirectChapterIntroViewPath = `${textbooksLegacyToRedirectBasePath}/:syllabusId/intro`;
export const textbooksLegacyToRedirectSubtopicRedirectorPath = `${textbooksLegacyToRedirectBasePath}/:syllabusId/topics/:topicId`;
export const textbooksLegacyToRedirectSubtopicDetailViewPath = `${textbooksLegacyToRedirectBasePath}/:syllabusId/topics/:topicId/subtopics/:subtopicId`;

// IMPORTANT: in the route declaration, this MUST come before `contentBrowsingEmptyViewPath`
export const promoteFeatureLessonSharingRoutePath =
  '/textbooks/promote-lesson-sharing/';
