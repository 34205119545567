/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SubscriptionType = "NO_SUBSCRIPTION" | "PACKAGE" | "PARENT" | "PARTNER" | "SERVICE" | "STAFF" | "STUDENT_SCHOOL_PAID" | "STUDENT_SELF_PAID" | "TEACHER";
export type SubscriptionDetails_subscription = {
    readonly type: SubscriptionType;
    readonly startDate?: string | undefined;
    readonly endDate?: string | null | undefined;
    readonly plan?: {
        readonly school: {
            readonly title: string;
        };
    } | undefined;
    readonly " $refType": "SubscriptionDetails_subscription";
};
export type SubscriptionDetails_subscription$data = SubscriptionDetails_subscription;
export type SubscriptionDetails_subscription$key = {
    readonly " $data"?: SubscriptionDetails_subscription$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SubscriptionDetails_subscription">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "School",
    "kind": "LinkedField",
    "name": "school",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionDetails_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentSchoolPaidPlan",
          "kind": "LinkedField",
          "name": "plan",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "StudentSchoolPaidSubscription",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "StudentSelfPaidPlan",
          "kind": "LinkedField",
          "name": "plan",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "type": "StudentSelfPaidSubscription",
      "abstractKey": null
    }
  ],
  "type": "SubscriptionInterface",
  "abstractKey": "__isSubscriptionInterface"
};
})();
(node as any).hash = '8d52d7c8b7f625cd1f05996460d74e5d';
export default node;
