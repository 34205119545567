/* eslint-disable jsx-a11y/no-static-element-interactions */
import { StyleSheet, css } from 'aphrodite';
import { useState } from 'react';
import PlayCircleOutline from 'react-icons/lib/md/play-circle-outline';

import { fontFamily, fontSize, lineHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { Anchor } from 'ms-ui-primitives/Link';

const styles = StyleSheet.create({
  geogebra: {
    position: 'relative',
    fontFamily: fontFamily.body,
    color: colors.mako,
    fontSize: fontSize.small,
    lineHeight: lineHeight.body,
  },
  cover: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${colors.iron}`,
    cursor: 'pointer',
    outline: 'none',
  },
  metadata: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    padding: '5px 10px',
    boxSizing: 'border-box',
    cursor: 'default',
  },
  iframe: {
    border: 'none',
  },
});

type Props = {
  embedId: string;
  width: number;
  height: number;
  attributionText?: string | undefined;
  // URL to the dedicated geogebra page for this embedded content
  attributionUrl?: string | undefined;
};

function Geogebra({
  embedId,
  width,
  height,
  attributionText,
  attributionUrl,
}: Props) {
  const [isCoverVisible, setIsCoverVisible] = useState(true);
  return (
    <div style={{ width, height }} className={css(styles.geogebra)}>
      {isCoverVisible && (
        <div
          onClick={() => setIsCoverVisible(false)}
          className={css(styles.cover)}
          role="button"
          tabIndex={0}
        >
          <PlayCircleOutline color={colors.mako} width={150} height={150} />
          <div
            className={css(styles.metadata)}
            onClick={e => e.stopPropagation()}
          >
            <div>
              Created with{' '}
              <Anchor href="http://www.geogebra.org">Geogebra</Anchor>
            </div>
            {attributionText ? (
              attributionUrl ? (
                <Anchor href={attributionUrl}>{attributionText}</Anchor>
              ) : (
                <span>{attributionText}</span>
              )
            ) : null}
          </div>
        </div>
      )}
      <iframe
        title="geogebra"
        src={`https://www.geogebra.org/material/iframe/id/${embedId}/width/${width}/height/${height}/border/888888/rc/false/ai/false/sdz/false/smb/false/stb/false/stbh/true/ld/false/sri/true/at/preferhtml5`}
        width={width}
        height={height}
        className={css(styles.iframe)}
      />
    </div>
  );
}

export default Geogebra;
