import { graphql } from 'react-relay';

import type { ErrorEnum } from './__generated__/extractResponseErrorMessagesMutation.graphql';

type Key = ErrorEnum;
type Message = string;
export type ResponseErrors = ReadonlyArray<{
  readonly key: Key;
  readonly message: Message;
}>;
// `actOnPromotedFeature` is just a simple parameter-less mutation
// chosen to let relay-compiler generate the type enum for the error keys
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const mutation = graphql`
  mutation extractResponseErrorMessagesMutation {
    actOnPromotedFeature {
      errors {
        key
      }
    }
  }
`;
export const extractResponseErrorMessages = (
  responseErrors: ResponseErrors | null | undefined,
  errorKeys: ReadonlyArray<Key>,
): ReadonlyArray<Message> =>
  responseErrors != null
    ? responseErrors.reduce(
        (acc, e) => (errorKeys.includes(e.key) ? [...acc, e.message] : acc),
        [] as ReadonlyArray<Message>,
      )
    : [];
