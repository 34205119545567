/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditEngageTaskFlyout_engageTask = {
    readonly id: string;
    readonly title: string;
    readonly startDate: string;
    readonly dueDate: string;
    readonly " $fragmentRefs": FragmentRefs<"EditTaskFlyout_assignments">;
    readonly " $refType": "EditEngageTaskFlyout_engageTask";
};
export type EditEngageTaskFlyout_engageTask$data = EditEngageTaskFlyout_engageTask;
export type EditEngageTaskFlyout_engageTask$key = {
    readonly " $data"?: EditEngageTaskFlyout_engageTask$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EditEngageTaskFlyout_engageTask">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEngageTaskFlyout_engageTask",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueDate",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "EditTaskFlyout_assignments"
    }
  ],
  "type": "EngageTask",
  "abstractKey": null
};
(node as any).hash = '3faeec86878bc840f709efc5fa10c42f';
export default node;
