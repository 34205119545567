import { map } from 'ramda';
import type { ReactNode } from 'react';
import { useMemo, createContext } from 'react';

import { validateDateRangeState } from 'ms-pages/Teacher/components/DateRangeInput/queryParamState';
import { useQueryParamSessionSyncedState } from 'ms-utils/hooks/useQueryParamSessionSyncedState';
import localStorageDb from 'ms-utils/localStorageDb';

import type { SerializedState, State } from './State';
import { initialState, serializeState, deserializeState } from './State';
import { getStorageKey } from './localStorage';
import * as updaters from './updaters';

type Updaters = typeof updaters;
type CallbackUpdater<I extends readonly any[]> = (...args: I) => void;
type TransformedUpdaters = {
  [K in keyof Updaters]: CallbackUpdater<Parameters<Updaters[K]>>;
};

const transformUpdaters = (
  setState: (cb: (state: SerializedState) => SerializedState) => void,
): TransformedUpdaters =>
  map(
    (updater: any) =>
      (...inputs: any[]) => {
        setState(updater(...inputs));
      },
    { ...updaters },
  );

export const StateContext = createContext<State>(initialState);

export const UpdatersContext = createContext<TransformedUpdaters>(
  transformUpdaters(() => {}),
);

type Props = {
  children: ReactNode;
  teacherId: string;
  pickKeys: string[];
};

export function StateProvider({ children, teacherId, pickKeys }: Props) {
  // TODO encode task selector in query para ms. ATM it is is ignored as it is an object defining whether
  // each task type is selected. This is not currently supported by our query param serializer.
  const [localStorageState, setState] = useQueryParamSessionSyncedState({
    db: localStorageDb,
    dbKey: getStorageKey({ teacherId }),
    fallbackValue: serializeState(initialState),
    pickKeys,
  });

  const deserializedLocalStorageState = deserializeState(localStorageState);
  const state: State = {
    ...deserializedLocalStorageState,
    ...validateDateRangeState(deserializedLocalStorageState, initialState),
  };
  return (
    <StateContext.Provider value={state}>
      <UpdatersContext.Provider
        value={useMemo(() => transformUpdaters(setState), [setState])}
      >
        {children}
      </UpdatersContext.Provider>
    </StateContext.Provider>
  );
}
