import { StateMachineInputType } from '@rive-app/react-canvas';
import { groupBy } from 'ramda';
import { useEffect, useId, useState } from 'react';

import Checkbox from 'ms-ui-primitives/Checkbox';
import { HStack } from 'ms-ui-primitives/Stack';
import type { StateMachineInput, TypedRive } from 'ms-utils/rive/useTypedRive';
import { kebabCase } from 'ms-utils/string';
import { assertUnreachable, unsafeKeys } from 'ms-utils/typescript-utils';

import {
  DebugPanel,
  DebugPanelCollapse,
  DebugPanelToggler,
  PanelBody,
  PanelButton,
  PanelInput,
  PanelSection,
  PanelSectionTitle,
  PanelTitle,
} from '../DebugPanel';
import { RiveIconSvg } from '../RiveIconSvg';

export const DEBUG_RIVE =
  process.env.NODE_ENV === 'development' ||
  localStorage.getItem('DEBUG_RIVE') === 'true';

export function RiveDebugPanel(props: { children: React.ReactNode }) {
  if (!DEBUG_RIVE) return;
  return (
    <DebugPanel
      renderToggler={() => (
        <DebugPanelToggler>
          <RiveIconSvg style={{ height: 16, width: 16 }} />
        </DebugPanelToggler>
      )}
      {...props}
    />
  );
}

export function RiveDebugger({ rive }: { rive: TypedRive }) {
  return (
    <RiveDebugPanel>
      <HStack center>
        <PanelTitle>Debug</PanelTitle>
        <DebugPanelCollapse />
      </HStack>
      <PanelBody>
        <RiveDebuggerContent rive={rive} />
      </PanelBody>
    </RiveDebugPanel>
  );
}

export function RiveDebuggerContent({
  rive,
  onPlay,
  onPause,
  onStop,
  onReset,
  onInput,
  onTrigger,
}: {
  rive: TypedRive;
  onPlay?: VoidFunction;
  onPause?: VoidFunction;
  onStop?: VoidFunction;
  onReset?: VoidFunction;
  onInput?: (inputName: string, value: number | boolean) => void;
  onTrigger?: (inputName: string) => void;
}) {
  const [inputs, setInputs] = useState<readonly StateMachineInput[]>([]);
  const groupedInputs = groupBy<
    StateMachineInput,
    keyof typeof StateMachineInputType
  >(i => getStateMachineInputTypeName(i.type), inputs);
  const id = useId();

  useEffect(() => {
    setInputs(rive.stateMachineInputs());
  }, [rive]);

  return (
    <>
      <PanelSection>
        <PanelSectionTitle>Playback</PanelSectionTitle>
        <PanelButton
          onClick={() => {
            rive.play();
            onPlay?.();
          }}
        >
          Play
        </PanelButton>
        <PanelButton
          onClick={() => {
            rive.pause();
            onPause?.();
          }}
        >
          Pause
        </PanelButton>
        <PanelButton
          onClick={() => {
            rive.stop();
            onStop?.();
          }}
        >
          Stop
        </PanelButton>
        <PanelButton
          onClick={() => {
            rive.reset({ autoplay: true });
            onReset?.();
            setInputs(rive.stateMachineInputs());
          }}
        >
          Reset
        </PanelButton>
      </PanelSection>

      {unsafeKeys(groupedInputs).map(key => (
        <PanelSection key={key}>
          <PanelSectionTitle>{key} inputs</PanelSectionTitle>
          {/* eslint-disable-next-line array-callback-return */}
          {groupedInputs[key].map(input => {
            switch (input.type) {
              case StateMachineInputType.Number:
                const inputId = `${kebabCase(input.name)}-${id}`;
                return (
                  <HStack key={input.name} center gap={4}>
                    <label htmlFor={inputId}>{input.name}</label>
                    <div style={{ marginLeft: 'auto', width: 64 }}>
                      <PanelInput
                        type="number"
                        defaultValue="0"
                        id={inputId}
                        onChange={event => {
                          const value = parseInt(event.target.value, 10);
                          rive.value(input.name, value);
                          onInput?.(input.name, value);
                        }}
                      />
                    </div>
                  </HStack>
                );
              case StateMachineInputType.Trigger:
                return (
                  <PanelButton
                    key={input.name}
                    onClick={() => {
                      rive.fire(input.name);
                      onTrigger?.(input.name);
                    }}
                  >
                    {input.name}
                  </PanelButton>
                );
              case StateMachineInputType.Boolean:
                return (
                  <Checkbox
                    key={input.name}
                    label={input.name}
                    onChange={event => {
                      const { checked } = event.target;
                      rive.value(input.name, checked);
                      onInput?.(input.name, checked);
                    }}
                  />
                );
              default:
                assertUnreachable(input.type);
            }
          })}
        </PanelSection>
      ))}
    </>
  );
}

function getStateMachineInputTypeName(
  type: StateMachineInputType,
): keyof typeof StateMachineInputType {
  switch (type) {
    case StateMachineInputType.Boolean:
      return 'Boolean';
    case StateMachineInputType.Number:
      return 'Number';
    case StateMachineInputType.Trigger:
      return 'Trigger';
    default:
      assertUnreachable(type);
  }
}
