import { useEffect, useTransition } from 'react';
import { useRefetchableFragment } from 'react-relay';
import type { KeyType } from 'react-relay/relay-hooks/helpers';
import type { GraphQLTaggedNode, OperationType } from 'relay-runtime';

import { taskRefetchBus } from 'ms-helpers/Tasks/signaling';
import { simpleDebounce } from 'ms-utils/function';

export function useTaskSignalRefetchableFragment<
  TFragment extends OperationType,
  TFragmentRef extends KeyType,
>(fragment: GraphQLTaggedNode, fragmentRef: TFragmentRef) {
  const [data, refetch] = useRefetchableFragment<TFragment, TFragmentRef>(
    fragment,
    fragmentRef,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startTransition] = useTransition();

  useEffect(() => {
    const debouncedRefetch = simpleDebounce(() => {
      new Promise((res, rej) => {
        startTransition(() => {
          try {
            refetch({}, { onComplete: res, fetchPolicy: 'network-only' });
          } catch (e) {
            rej(e);
          }
        });
      });
    }, 10);
    return taskRefetchBus.listen(debouncedRefetch);
  }, [refetch]);

  return data;
}
