import { css } from 'aphrodite';
import { always, clamp, findLastIndex, isEmpty, take } from 'ramda';
import {
  useMemo,
  useCallback,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import type {
  ReactElement,
  ReactNode,
  KeyboardEvent as SyntheticKeyboardEvent,
  MouseEvent as SyntheticMouseEvent,
  ChangeEvent as SyntheticInputEvent,
} from 'react';

import ChevronBottomIcon from 'ms-components/icons/ChevronBottom';
import { unwrap } from 'ms-utils/typescript-utils';

import styles, { BORDER_WIDTH, INPUT_HEIGHT } from './styles';

type Option = { value: string };

type OptionList = ReadonlyArray<Option>;

type Props = {
  dataSource: OptionList;
  searchTerm: string;
  value?: string | null | undefined;
  filter: (searchTerm: string, dataSource: OptionList) => OptionList;
  onChange: (searchTerm: string) => void;
  onFocus?: (() => void) | null | undefined;
  onOptionChosen: (option: Option) => void;
  placeholder: string;
  maxAutocompletionOptions?: number;
  maxHeight?: number;
  isDisabled?: boolean;
  icon?: ReactElement<any>;
  forcePopoverPosition?: 'top' | 'bottom';
  specialElementDescription?: string | null | undefined;
  specialElementDecoration?: ReactNode | null | undefined;
  'data-test-id'?: string | null | undefined;
};

export type SearchableSelectImperativeHandle = {
  focus: () => void;
  blur: () => void;
  select: () => void;
};

const DEFAULT_NUM_VISIBLE_OPTIONS = 6;

const getFilteredData = ({
  filter,
  searchTerm,
  dataSource,
}: Pick<Props, 'filter' | 'searchTerm' | 'dataSource'>) =>
  filter(searchTerm, dataSource);

const SearchableSelect = forwardRef<SearchableSelectImperativeHandle, Props>(
  function SearchableSelect(
    {
      dataSource,
      searchTerm,
      value,
      filter,
      onChange,
      onFocus,
      onOptionChosen,
      placeholder = '',
      maxAutocompletionOptions = Infinity,
      maxHeight = (INPUT_HEIGHT - BORDER_WIDTH * 2) *
        DEFAULT_NUM_VISIBLE_OPTIONS,
      isDisabled = false,
      icon,
      forcePopoverPosition,
      specialElementDescription,
      specialElementDecoration,
      'data-test-id': dataTestId,
    },
    ref,
  ) {
    const [isFocused, setIsFocused] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number | undefined>(
      undefined,
    );
    const inputRef = useRef<HTMLInputElement | null>(null);

    // Custom instance properties
    const lastChosenOption = useRef<Option | undefined>(undefined);
    const shouldDiscardNextBlurEvent = useRef<boolean | undefined>(undefined);

    const resetState = useCallback(() => {
      setIsFocused(false);
      setActiveIndex(undefined);
    }, []);

    const blur = useCallback(() => inputRef?.current?.blur(), []);
    const focus = useCallback(() => inputRef?.current?.focus(), []);
    const select = useCallback(() => inputRef?.current?.select(), []);

    useImperativeHandle(ref, () => ({
      focus,
      blur,
      select,
    }));

    const closePopover = useCallback(() => {
      resetState();
      blur();
    }, [blur, resetState]);

    // Produces the subset of the data source that should populate the dropdown.
    const getOptions = useCallback(() => {
      return take(
        maxAutocompletionOptions,
        getFilteredData({ filter, searchTerm, dataSource }),
      );
    }, [dataSource, filter, maxAutocompletionOptions, searchTerm]);

    const handleOptionChosen = useCallback(
      (idx: number) => {
        const selectedOption = unwrap(
          getFilteredData({ filter, searchTerm, dataSource })[idx],
        );
        lastChosenOption.current = selectedOption;
        shouldDiscardNextBlurEvent.current = true;
        onOptionChosen(selectedOption);
        closePopover();
      },
      [closePopover, dataSource, filter, onOptionChosen, searchTerm],
    );

    const onArrowDown = useCallback(() => {
      setActiveIndex(prevIndex =>
        prevIndex === undefined
          ? 0
          : clamp(0, findLastIndex(always(true), getOptions()), prevIndex + 1),
      );
    }, [getOptions]);

    const onArrowUp = useCallback(
      () =>
        setActiveIndex(prevIndex =>
          prevIndex === undefined
            ? undefined
            : clamp(
                0,
                findLastIndex(always(true), getOptions()),
                prevIndex - 1,
              ),
        ),
      [getOptions],
    );

    const onEnter = useCallback(() => {
      if (isEmpty(getFilteredData({ filter, searchTerm, dataSource }))) return;
      if (activeIndex === undefined) return;
      handleOptionChosen(activeIndex);
    }, [activeIndex, dataSource, filter, handleOptionChosen, searchTerm]);

    const onEscape = useCallback(() => {
      if (lastChosenOption.current) {
        onChange(lastChosenOption.current.value);
      } else {
        onChange('');
      }

      shouldDiscardNextBlurEvent.current = true;
      closePopover();
    }, [closePopover, onChange]);

    const mapKeysToHandlers: Record<string, () => void> = useMemo(
      () => ({
        ArrowDown: onArrowDown,
        ArrowUp: onArrowUp,
        Enter: onEnter,
        Escape: onEscape,
      }),
      [onArrowDown, onArrowUp, onEnter, onEscape],
    );

    const handleKeyDown = useCallback(
      (event: SyntheticKeyboardEvent<any>) => {
        const handler = mapKeysToHandlers[event.key];
        if (!handler) return;
        event.preventDefault();
        handler();
      },
      [mapKeysToHandlers],
    );

    const activateOption = useCallback((index: number) => {
      setActiveIndex(index);
    }, []);

    const toggleFocus = useCallback(
      (event: SyntheticMouseEvent<any>) => {
        event.preventDefault();
        if (!isFocused) {
          focus();
        } else {
          closePopover();
        }
      },
      [closePopover, focus, isFocused],
    );

    const handleBlur = useCallback(() => {
      if (shouldDiscardNextBlurEvent.current) {
        shouldDiscardNextBlurEvent.current = false;
        return;
      }

      resetState();

      // Clear out any text in the input field UNLESS we had previously chosen
      // an option, and we haven't modified the text in any way.
      if (
        !lastChosenOption.current ||
        lastChosenOption.current.value !== searchTerm
      ) {
        onChange('');
      }
    }, [onChange, resetState, searchTerm]);

    const handleFocus = useCallback(() => {
      onFocus?.();
      const filteredData = getFilteredData({ filter, searchTerm, dataSource });
      setIsFocused(true);
      setActiveIndex(isEmpty(filteredData) ? undefined : 0);
    }, [dataSource, filter, onFocus, searchTerm]);

    const handleChange = useCallback(
      (event: SyntheticInputEvent<HTMLInputElement>) =>
        onChange(event.target.value),
      [onChange],
    );

    const options = getOptions();

    // Invert the direction the option list popover opens when opened near the
    // bottom of the window.
    // TODO: Consider UX improvements for dealing with the case that the page
    // is scrolled while the select option list is already open.
    const windowHeight = window.innerHeight;
    const inputBoundingRect: DOMRect | undefined =
      inputRef.current?.getBoundingClientRect();
    const inputBottomY = inputBoundingRect ? inputBoundingRect.bottom : 0;
    const renderPopoverOnTop =
      forcePopoverPosition === 'top' ||
      inputBottomY + (maxHeight ?? 0) >= windowHeight ||
      forcePopoverPosition !== 'bottom';

    // OK, so somewhere here, there is the dropdown portion rendered.
    // We need to have a prop that passes in two additional components here:
    // - the icon to render on the RHS (a + in this instance)
    // - a header element to describe the below element
    //
    // If these two are present, we should render them (so it's the choice of the client as
    // to whether this chrome is displayed)
    return (
      <div className={css(styles.root)}>
        <div className={css(styles.inputWrapper)}>
          <input
            ref={inputRef}
            value={value != null && !isFocused ? value : searchTerm}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            disabled={isDisabled}
            className={css(
              styles.input,
              isFocused && styles.inputFocused,
              isFocused && isEmpty(options) && styles.noResults,
            )}
            data-test-id={dataTestId}
          />
          {!(isFocused && isEmpty(options)) && (
            <div className={css(styles.iconWrapper)} onMouseDown={toggleFocus}>
              {icon != null ? (
                icon
              ) : (
                <ChevronBottomIcon
                  aphroditeStyles={[
                    styles.arrow,
                    isFocused && styles.arrowOpen,
                  ]}
                />
              )}
            </div>
          )}
        </div>
        {isFocused && !isEmpty(options) && (
          // Options are rendered here when isFocussed.
          // So we can render option extras here.
          <div
            className={css(styles.popover)}
            style={
              renderPopoverOnTop && forcePopoverPosition !== 'bottom'
                ? { bottom: INPUT_HEIGHT }
                : { top: INPUT_HEIGHT }
            }
          >
            {specialElementDescription != null && options.length === 1 && (
              <div
                className={css(styles.listItemDisabled)}
                style={{ maxHeight }}
              >
                {specialElementDescription}
              </div>
            )}
            <div className={css(styles.list)} style={{ maxHeight }}>
              {/* eslint-disable jsx-a11y/no-static-element-interactions */}
              {options.map((data, idx) => (
                <div
                  key={idx}
                  className={css(
                    styles.listItem,
                    idx === activeIndex && styles.listItemActive,
                  )}
                  onMouseDown={(event: SyntheticMouseEvent<any>) => {
                    // Mousedown here shouldn't steal focus from input.
                    event.preventDefault();
                  }}
                  onClick={() => handleOptionChosen(idx)}
                  onMouseOver={() => activateOption(idx)}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>{data.value}</div>
                    {specialElementDecoration != null &&
                      options.length === 1 &&
                      specialElementDecoration}
                  </div>
                </div>
              ))}
              {/* eslint-enable jsx-a11y/no-static-element-interactions */}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default SearchableSelect;
