import type { ReactNode, ComponentType } from 'react';

import ArrowLeftIcon from 'ms-components/icons/ArrowLeft';
import CrossBoldIcon from 'ms-components/icons/CrossBold';
import InfoFilled from 'ms-components/icons/InfoFilled';
import { font, inputHeight } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Button from 'ms-ui-primitives/Button';
import Modal from 'ms-ui-primitives/Modal';
import Separator from 'ms-ui-primitives/Separator';
import { HSpacer } from 'ms-ui-primitives/Stack';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { styled } from 'ms-utils/emotion';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onBack?: null | undefined | (() => void);
  title: string;
  titleInfoTooltipText?: string | undefined;
  width?: number | string | undefined;
  fullWidth?: boolean | undefined;
  noMinHeight?: boolean | undefined;
  isOverflowHidden?: boolean | undefined;
};

const HORIZONTAL_PADDING = 40;
const VERTICAL_PADDING = 20;
const MODAL_WIDTH = 904 + 2 * HORIZONTAL_PADDING;
const HEADER_HEIGHT = 64;
const ARROW_LEFT_SIZE = 22;
const CROSS_ICON_SIZE = 16;

const ModalHeader = styled({
  borderBottom: `1px solid ${colors.seashell}`,
  height: HEADER_HEIGHT,
  color: colors.cloudBurst,
  ...font.gilroyHeading,
  display: 'flex',
  alignItems: 'center',
});

const RightAligned = styled({
  marginLeft: 'auto',
});

const ModalBody = styled({
  padding: `${VERTICAL_PADDING}px ${HORIZONTAL_PADDING}px`,
});

const ContentContainer: ComponentType<{
  fullWidth: boolean;
  noMinHeight: boolean;
  children: ReactNode;
}> = styled({
  default: {
    maxWidth: 716,
    margin: 'auto',
    minHeight: '80vh',
  },
  fullWidth: {
    maxWidth: '100%',
  },
  noMinHeight: {
    minHeight: 0,
  },
});

export const ModalFooter: ComponentType<{
  children: ReactNode;
  center?: boolean;
}> = styled({
  default: {
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gridGap: 4 * BASE_UNIT,
    minHeight: inputHeight,
    paddingTop: 6 * BASE_UNIT,
  },
  center: {
    justifyContent: 'center',
  },
});

export default function SunflowerModal({
  children,
  isOpen,
  onClose,
  onBack,
  title,
  titleInfoTooltipText,
  width = MODAL_WIDTH,
  fullWidth = false,
  noMinHeight = false,
  isOverflowHidden = true,
}: Props) {
  return (
    <Modal
      showCloseButton={false}
      closeOnOverlayTap
      width={width}
      isOpen={isOpen}
      onClose={onClose}
      isOverflowHidden={isOverflowHidden}
      renderHeader={() => (
        <ModalHeader>
          {onBack != null ? (
            <Button onClick={onBack} label="Go back">
              <ArrowLeftIcon size={ARROW_LEFT_SIZE} />
            </Button>
          ) : (
            // If there is no arrow left, leave 40px left padding
            <Separator size={10} />
          )}

          {title}
          {titleInfoTooltipText && (
            <>
              <HSpacer width={7} />
              <Tooltip title={titleInfoTooltipText} maxWidth={250} isInlineFlex>
                <HSpacer width={2} />
                <InfoFilled color={colors.grey10} />
              </Tooltip>
            </>
          )}

          <RightAligned>
            <Button onClick={onClose} label="Close" color="grey90">
              <CrossBoldIcon size={CROSS_ICON_SIZE} />
            </Button>
          </RightAligned>
        </ModalHeader>
      )}
    >
      <ModalBody>
        <ContentContainer fullWidth={fullWidth} noMinHeight={noMinHeight}>
          {children}
        </ContentContainer>
      </ModalBody>
    </Modal>
  );
}
