/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useDeleteTaskMutationVariables = {
    taskId: string;
};
export type useDeleteTaskMutationResponse = {
    readonly deleteTask: {
        readonly taskId: string | null;
    };
};
export type useDeleteTaskMutation = {
    readonly response: useDeleteTaskMutationResponse;
    readonly variables: useDeleteTaskMutationVariables;
};



/*
mutation useDeleteTaskMutation(
  $taskId: ID!
) {
  deleteTask(taskId: $taskId) {
    taskId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskId",
        "variableName": "taskId"
      }
    ],
    "concreteType": "TaskDeletionResult",
    "kind": "LinkedField",
    "name": "deleteTask",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDeleteTaskMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDeleteTaskMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4b1ba20c762a11b261ec76dd1544a3bb",
    "id": null,
    "metadata": {},
    "name": "useDeleteTaskMutation",
    "operationKind": "mutation",
    "text": "mutation useDeleteTaskMutation(\n  $taskId: ID!\n) {\n  deleteTask(taskId: $taskId) {\n    taskId\n  }\n}\n"
  }
};
})();
(node as any).hash = '25fd55c3e8eff82fe6c10d2f72b31a27';
export default node;
