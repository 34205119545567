import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <g transform="translate(3.000000, 2.300000)">
    <path d="M14.1176471,11.8389384 L14.1176471,1 C14.1176471,0.44771525 13.6699318,-9.77853931e-15 13.1176471,-1.0658141e-14 L3.61529182,-1.0658141e-14 C3.17801352,-1.0466792e-14 2.82352941,0.354484106 2.82352941,0.791762412 C2.82352941,1.22904072 3.17801352,1.58352482 3.61529182,1.58352482 L11.6096324,1.58352482 C12.1619171,1.58352482 12.6096324,2.03124007 12.6096324,2.58352482 L12.6096324,11.8389384 C12.6096324,12.2553652 12.947213,12.5929458 13.3636397,12.5929458 C13.7800665,12.5929458 14.1176471,12.2553652 14.1176471,11.8389384 Z" />
    <path d="M1,15.5294164 L10.2941176,15.5294164 C10.8464024,15.5294164 11.2941176,15.0817011 11.2941176,14.5294164 L11.2941176,3.93647059 C11.2941176,3.38418584 10.8464024,2.93647059 10.2941176,2.93647059 L1,2.93647059 C0.44771525,2.93647059 -5.39670589e-15,3.38418584 -5.32907052e-15,3.93647059 L-5.32907052e-15,14.5294164 C-5.26143514e-15,15.0817011 0.44771525,15.5294164 1,15.5294164 Z M3.54849998,5.51976562 L7.74561767,5.51976562 C8.13546572,5.51976562 8.45150002,5.83579991 8.45150002,6.22564797 C8.45150002,6.61549603 8.13546572,6.93153032 7.74561767,6.93153032 L3.54849998,6.93153032 C3.15865192,6.93153032 2.84261763,6.61549603 2.84261763,6.22564797 C2.84261763,5.83579991 3.15865192,5.51976562 3.54849998,5.51976562 Z M3.54849998,8.40694163 L7.74561767,8.40694163 C8.13546572,8.40694163 8.45150002,8.72297593 8.45150002,9.11282399 C8.45150002,9.50267204 8.13546572,9.81870634 7.74561767,9.81870634 L3.54849998,9.81870634 C3.15865192,9.81870634 2.84261763,9.50267204 2.84261763,9.11282399 C2.84261763,8.72297593 3.15865192,8.40694163 3.54849998,8.40694163 Z M3.54849998,11.2941176 L7.74561767,11.2941176 C8.13546572,11.2941176 8.45150002,11.6101519 8.45150002,12 C8.45150002,12.3898481 8.13546572,12.7058824 7.74561767,12.7058824 L3.54849998,12.7058824 C3.15865192,12.7058824 2.84261763,12.3898481 2.84261763,12 C2.84261763,11.6101519 3.15865192,11.2941176 3.54849998,11.2941176 Z" />
  </g>
);

const Templates = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);
Templates.displayName = 'Templates';

export default Templates;
