/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TeacherOnboardingSetupEnum = "BASIC" | "FULL";
export type useTeacherContextDataQueryVariables = {
    numberOfSchools: number;
    numberOfClasses: number;
};
export type useTeacherContextDataQueryResponse = {
    readonly viewer: {
        readonly profile: {
            readonly id?: string | undefined;
            readonly schools?: ReadonlyArray<{
                readonly id: string;
                readonly title: string;
                readonly district: {
                    readonly id: string;
                    readonly schoolsCount: number;
                } | null;
                readonly classes: {
                    readonly edges: ReadonlyArray<{
                        readonly node: {
                            readonly id: string;
                        };
                    }>;
                } | null;
            }> | null | undefined;
            readonly teacherPreference?: {
                readonly id: string;
                readonly createTaskTargetMastery: number;
                readonly onboardingSetup: TeacherOnboardingSetupEnum | null;
                readonly dismissedOnboardingFeatures: ReadonlyArray<string>;
            } | null | undefined;
        };
    };
};
export type useTeacherContextDataQuery = {
    readonly response: useTeacherContextDataQueryResponse;
    readonly variables: useTeacherContextDataQueryVariables;
};



/*
query useTeacherContextDataQuery(
  $numberOfSchools: Int!
  $numberOfClasses: Int!
) {
  viewer {
    profile {
      __typename
      ... on Teacher {
        id
        schools(limit: $numberOfSchools) {
          id
          title
          district {
            id
            schoolsCount
          }
          classes(first: $numberOfClasses) {
            edges {
              node {
                id
              }
            }
          }
        }
        teacherPreference {
          id
          createTaskTargetMastery
          onboardingSetup
          dismissedOnboardingFeatures
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfClasses"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numberOfSchools"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "limit",
          "variableName": "numberOfSchools"
        }
      ],
      "concreteType": "School",
      "kind": "LinkedField",
      "name": "schools",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "District",
          "kind": "LinkedField",
          "name": "district",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "schoolsCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "numberOfClasses"
            }
          ],
          "concreteType": "ClassConnection",
          "kind": "LinkedField",
          "name": "classes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClassEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Class",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TeacherPreference",
      "kind": "LinkedField",
      "name": "teacherPreference",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createTaskTargetMastery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "onboardingSetup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dismissedOnboardingFeatures",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Teacher",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTeacherContextDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useTeacherContextDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "589ea5874a80f10f681fabd88c0c9ac7",
    "id": null,
    "metadata": {},
    "name": "useTeacherContextDataQuery",
    "operationKind": "query",
    "text": "query useTeacherContextDataQuery(\n  $numberOfSchools: Int!\n  $numberOfClasses: Int!\n) {\n  viewer {\n    profile {\n      __typename\n      ... on Teacher {\n        id\n        schools(limit: $numberOfSchools) {\n          id\n          title\n          district {\n            id\n            schoolsCount\n          }\n          classes(first: $numberOfClasses) {\n            edges {\n              node {\n                id\n              }\n            }\n          }\n        }\n        teacherPreference {\n          id\n          createTaskTargetMastery\n          onboardingSetup\n          dismissedOnboardingFeatures\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '16d270711b8358e90b5735dbd362f669';
export default node;
