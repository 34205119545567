import type { ReactNode, KeyboardEvent } from 'react';

type Props = {
  action: (event: KeyboardEvent<HTMLDivElement>) => void;
  children?: ReactNode;
  isDisabled?: boolean;
  // make this component styleable
  className?: string;
};

const ENTER_KEYCODE = 13;

const CaptureEnter = ({ action, children, isDisabled, className }: Props) => (
  // We're trying to simulate form affordances, we don't want this element to
  // be focusable or visible to accessibility devices.
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div
    className={className}
    onKeyPress={event => {
      if (event.which === ENTER_KEYCODE && !isDisabled) action(event);
    }}
  >
    {children}
  </div>
);

export default CaptureEnter;
