import { css, StyleSheet } from 'aphrodite';
import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

const Center = ({ children }: Props) => (
  <div className={css(styles.root)}>{children}</div>
);

export default Center;
