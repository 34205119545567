import { css } from 'aphrodite';
import { useEffect } from 'react';
import type { ReactNode } from 'react';

import Button from 'ms-ui-primitives/Button';
import { Logger } from 'ms-utils/app-logging';

import errorStyles from './styles';

// Conventionally, this component should be used only in QueryRenderers' render,
// usually in response to "expected" errors, such resources not found
export default function PageErrorMessageView({
  children,
}: {
  children: ReactNode;
}) {
  useEffect(() => {
    // TODO consider to add a props prefix to the logger
    // TODO consider to remove the logger at all, as this component now is
    // rather used for expected errors
    if (typeof children === 'string') {
      Logger.error(children);
    }
  }, [children]);

  // The `Go back` link helps the users to escape from the error view (esp. non
  // browser users)
  function handleClick() {
    window.history.back();
  }

  return (
    <div className={css(errorStyles.pageErrorMessageView)}>
      <div className={css(errorStyles.errorMessage)}>{children}</div>

      <Button onClick={handleClick} type="secondary">
        Go back
      </Button>
    </div>
  );
}
