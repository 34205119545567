import { useMaybeTeacherContext } from 'ms-pages/Teacher/TeacherContext/useTeacherContext';
import {
  teacherClassTasksUrl,
  teacherClassTasksRedirectorPath,
} from 'ms-utils/urls';

// Find the first class that has been assigned to build a link to
// the class task report
// If only students are selected, link to the currently active class
// If there is no active class, link to the class tasks redirector

export default function useGetFirstAvailableClassTasksReportUrl(
  assignedClasses: readonly { id: string }[],
) {
  const { classId: currentActiveClassId } = useMaybeTeacherContext();

  const classIdToRedirectTo: string | null | undefined =
    assignedClasses.map(c => c.id)?.[0] ?? currentActiveClassId;

  return classIdToRedirectTo != null
    ? teacherClassTasksUrl({ classId: classIdToRedirectTo })
    : teacherClassTasksRedirectorPath;
}
