import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { useMaybeViewer } from 'ms-helpers/Viewer/Renderer';

type Props = {
  children: ReactNode;
};

const NoPageBreak = styled.div({ breakInside: 'avoid' });

function MaybeNoPageBreak({ children }: Props) {
  const {
    featureFlags: { printEnablePageBreaksAfterPTs = false },
  } = useMaybeViewer() || { featureFlags: {} };
  if (!printEnablePageBreaksAfterPTs) {
    return <div>{children}</div>;
  }
  return <NoPageBreak>{children}</NoPageBreak>;
}

export default MaybeNoPageBreak;
