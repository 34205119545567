import type { Props } from 'ms-components/icons/types';
import Icon from 'ms-ui-primitives/Icon';

export default function CreateTemplateIcon(props: Props) {
  return (
    <Icon viewBoxHeight={21} viewBoxWidth={22} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7194 14.025V18.8516C14.7194 20.1802 13.5252 21.2572 12.052 21.2572H2.7164C1.24327 21.2572 0.0490723 20.1802 0.0490723 18.8516V6.82362C0.0490723 5.49505 1.24327 4.41803 2.7164 4.41803H6.8744C6.81576 4.81843 6.7854 5.22801 6.7854 5.64468C6.7854 6.47201 6.90511 7.27139 7.12816 8.02642H4.93917C4.60157 8.02642 4.3279 8.43031 4.3279 8.92852C4.3279 9.42673 4.60157 9.83062 4.93917 9.83062H7.90219C8.28508 10.4947 8.75627 11.1014 9.29972 11.6348H4.93917C4.60157 11.6348 4.3279 12.0387 4.3279 12.5369C4.3279 13.0351 4.60157 13.439 4.93917 13.439H12.06C12.8893 13.7711 13.784 13.9746 14.7194 14.025ZM3.71664 16.1453C3.71664 16.6435 3.30614 17.0474 2.79975 17.0474C2.29336 17.0474 1.88286 16.6435 1.88286 16.1453C1.88286 15.6471 2.29336 15.2432 2.79975 15.2432C3.30614 15.2432 3.71664 15.6471 3.71664 16.1453ZM2.79975 13.439C3.30614 13.439 3.71664 13.0351 3.71664 12.5369C3.71664 12.0387 3.30614 11.6348 2.79975 11.6348C2.29336 11.6348 1.88286 12.0387 1.88286 12.5369C1.88286 13.0351 2.29336 13.439 2.79975 13.439ZM3.71664 8.92852C3.71664 9.42673 3.30614 9.83062 2.79975 9.83062C2.29336 9.83062 1.88286 9.42673 1.88286 8.92852C1.88286 8.43031 2.29336 8.02642 2.79975 8.02642C3.30614 8.02642 3.71664 8.43031 3.71664 8.92852ZM4.93917 15.2432C4.60157 15.2432 4.3279 15.6471 4.3279 16.1453C4.3279 16.6435 4.60157 17.0474 4.93917 17.0474H12.2743C12.6119 17.0474 12.8856 16.6435 12.8856 16.1453C12.8856 15.6471 12.6119 15.2432 12.2743 15.2432H4.93917Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1779 11.1073C18.1955 11.1073 20.6417 8.6611 20.6417 5.64353C20.6417 2.62597 18.1955 0.179749 15.1779 0.179749C12.1603 0.179749 9.71411 2.62597 9.71411 5.64353C9.71411 8.6611 12.1603 11.1073 15.1779 11.1073ZM18.908 5.63963C18.9074 5.23748 18.614 4.9075 18.1752 4.9069L15.9165 4.90384L15.9134 2.64511C15.9128 2.20633 15.5829 1.91293 15.1807 1.91238C14.773 1.91183 14.4338 2.21323 14.4344 2.6587L14.4374 4.90183L12.1943 4.89878C11.7488 4.89818 11.4474 5.23737 11.448 5.6451C11.4485 6.04726 11.7419 6.37724 12.1807 6.37783L14.4394 6.3809L14.4425 8.63962C14.4431 9.0784 14.7731 9.37181 15.1752 9.37236C15.583 9.37291 15.9222 9.0715 15.9216 8.62604L15.9185 6.38291L18.1616 6.38595C18.6071 6.38656 18.9085 6.04736 18.908 5.63963Z"
      />
    </Icon>
  );
}
