/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PreviewProblemInstruction_problemContent = {
    readonly instruction: string;
    readonly " $refType": "PreviewProblemInstruction_problemContent";
};
export type PreviewProblemInstruction_problemContent$data = PreviewProblemInstruction_problemContent;
export type PreviewProblemInstruction_problemContent$key = {
    readonly " $data"?: PreviewProblemInstruction_problemContent$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PreviewProblemInstruction_problemContent">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviewProblemInstruction_problemContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instruction",
      "storageKey": null
    }
  ],
  "type": "ProblemContent",
  "abstractKey": null
};
(node as any).hash = 'e5138d9ce83c498b5c334bd2d9b462f5';
export default node;
