import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

export default function AdaptiveTaskIcon({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) {
  return (
    <Icon
      color={color}
      size={size}
      aphroditeStyles={aphroditeStyles}
      title={title}
      titleId={titleId}
      viewBoxWidth={20}
      viewBoxHeight={21}
    >
      <g clipPath="url(#clip0_1828_96730)">
        <path d="M9.08537 1.16563C8.26524 0.345269 6.90331 0.29469 6.01299 1.16563L0.636312 6.54378C-0.212103 7.39243 -0.212103 8.76836 0.636312 9.61701C1.45645 10.4374 2.81832 10.4976 3.7087 9.61701L4.0927 9.23266L7.29878 12.4396C6.33158 15.058 6.97914 18.2893 9.08537 20.3733C9.31274 20.5983 9.66267 20.569 9.85347 20.3733L14.4623 15.7631L17.9185 19.2209C18.1306 19.433 18.4745 19.433 18.6866 19.2209C18.8824 19.025 18.9168 18.6847 18.6866 18.4526L15.2302 14.9949L19.8387 10.3853C20.0345 10.1895 20.0722 9.85054 19.8387 9.61701C17.7837 7.56144 14.6624 6.82572 11.9077 7.82938L8.70167 4.62243L9.08537 4.23886C9.96081 3.35867 9.90551 1.98599 9.08537 1.16563Z" />
      </g>
      <defs>
        <clipPath id="clip0_1828_96730">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.53125)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
