/* global __PAGE_ORIGIN__, __CONTENT_ORIGIN__ */
import { StyleSheet, css } from 'aphrodite';
import { equals } from 'ramda';
import { createRef, Component, Fragment } from 'react';
import VisibilityIcon from 'react-icons/lib/md/visibility';
import ZoomInIcon from 'react-icons/lib/md/zoom-in';
import ZoomOutIcon from 'react-icons/lib/md/zoom-out';

import {
  fontFamily,
  fontSize,
  fontWeight,
  borderRadiusUI,
  transition,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Modal from 'ms-ui-primitives/Modal';
import genUniqueId from 'ms-utils/id-generator';

import Action from './Action';

/**
 * GraphPlot
 *
 * This is NOT a proper React implementation like all of the other math
 * components. This uses an iframe to sandbox the old canjs code that runs
 * the graph plot. This component simply orchestrates that iframe via
 * message passing. Below is an overview of the message passing that occurs.
 *
 *     +-------------+                       +-------------+
 *     |  GraphPlot  |                       |   Iframe    |
 *     +------+------+                       +------+------+
 *            |                                     |
 *            |                                     |
 *            +--------{ type: 'mount' }----------> |
 *            |                                     |
 *            |                                     |
 *            | <--{ type: 'dynamicStateUpdated' }--+
 *            |                                     |
 *            |                                     |
 *            +--------{ type: 'update' }---------> |
 *            |                                     |
 *            |                                     |
 *            v                                     v
 *
 * One ramification of this is that we are not going to bother defining a nice
 * props interface, as the data requirements/shape won't be clear until we do
 * a ground-up reimplementation of graph plot in React. As such, we will just
 * take the stupid legacy "datum" as the value prop, and delegate this directly
 * to the existing canjs graphplot. This also bleeds into the GraphQL API
 * where we have decided to just send this datum as a JSON blob rather than
 * trying to provide a nice GraphQL type until we know what data we really want.
 */

const ACTION_BAR_HEIGHT = 60;
const ACTION_ICON_SIZE = 50;
const VIEWPORT_GUTTER = 15;
// The CanJS GraphPlot will reduce the dimensions of the GraphPlot by this
// amount when there exists any inequality plot types in the graph.
// See graphplot_util.js
const INEQUALITY_PLOT_DIMENSION_REDUCTION = 39;

const styles = StyleSheet.create({
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  mainIframeBox: {
    position: 'relative',
    border: `1px solid ${colors.nevada}`,
    borderRadius: borderRadiusUI,
    overflow: 'hidden',
  },
  iframe: {
    display: 'block',
  },
  loadingMessage: {
    background: colors.athensGray,
    boxShadow: `inset 0 0 1px ${colors.ironLight}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontFamily.body,
    fontSize: fontSize.large,
    color: colors.mako,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    transition: `opacity ${transition}`,
  },
  modal: {
    opacity: 1,
    transition: `opacity ${transition}`,
  },
  transparent: {
    opacity: 0,
    // We need to prevent the iframe from capturing our mouse events.
    pointerEvents: 'none',
  },
  zoomInButton: {
    alignSelf: 'flex-end',
    marginTop: 3,
    border: 'none',
    color: colors.white,
    padding: 0,
    outline: 'none',
    background: colors.matisse,
    borderRadius: `${borderRadiusUI}px`,
    cursor: 'pointer',
  },
  actionsBar: {
    display: 'flex',
    width: '100%',
    height: ACTION_BAR_HEIGHT,
  },
  submitAnswerButton: {
    background: colors.chateauGreen,
    color: colors.white,
    flexGrow: 1,
    border: 'none',
    outline: 'none',
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semibold,
  },
  iconButton: {
    flexGrow: 0,
  },
});

// Sets up an event listener that will fire once, and then stop listening.
const once = (
  eventName: string,
  eventTarget: EventTarget,
  listener: (event: Event) => void,
) => {
  const handleEvent = (event: Event) => {
    listener(event);
    eventTarget.removeEventListener(eventName, handleEvent);
  };
  eventTarget.addEventListener(eventName, handleEvent);
};

// Determines if the legacy datum contains an inequality plot that will cause
// the GraphPlot to render inequality controls, and thus shrink the graph size.
const containsInteractiveInequalityPlot = (value: LegacyDatum) =>
  value.interactivity.interactive_layer.some(
    layer => layer.plots && layer.plots.some(plot => 'inequality_type' in plot),
  );

const getModalIframeDimensions = (viewport: {
  width: number;
  height: number;
}) => {
  const maxWidth = viewport.width - 2 * VIEWPORT_GUTTER;
  const maxHeight = viewport.height - ACTION_BAR_HEIGHT - 2 * VIEWPORT_GUTTER;
  const size = Math.min(maxWidth, maxHeight);

  return {
    width: size,
    height: size,
  };
};

// When there is an inequality plot the GraphPlot width gets shrunk a bit
// unfortunately, so we need to account for that change in dimensions.
const reduceWidth = (value: LegacyDatum, width: number) =>
  containsInteractiveInequalityPlot(value)
    ? width - INEQUALITY_PLOT_DIMENSION_REDUCTION
    : width;

const MESSAGE_TARGET_ORIGIN = __PAGE_ORIGIN__;
const IFRAME_SRC = `${__CONTENT_ORIGIN__}/graphplot_iframe/interactive/`;

const postMessageTo = (
  iframe: HTMLIFrameElement | null,
  message: ReturnType<typeof mountMessage> | ReturnType<typeof updateMessage>,
) => {
  iframe?.contentWindow?.postMessage(message, MESSAGE_TARGET_ORIGIN);
};

// Creates a message to tell the iframe to mount a GraphPlot
const mountMessage = (
  componentId: number,
  datum: LegacyDatum,
  dimensions: { width?: number; height?: number } = {},
) => ({
  type: 'mount',
  componentId,
  // We need to tell our iframe which origin to post messages back to.
  parentOrigin: window.location.origin,
  datum: {
    ...datum,
    eye_candy: {
      ...datum.eye_candy,
      ...dimensions,
    },
    interactivity: {
      ...datum.interactivity,
      // This enforces that the GraphPlot will not be read-only
      unlock: true,
    },
  },
});

// Creates a message to tell the iframe to update a GraphPlot
const updateMessage = (datum: LegacyDatum) => ({
  type: 'update',
  datum,
});

// TODO not sure what shape interactivity is meant to have. Leaving as any for now.
const updateDynamicDatumState = (interactivity: any, value: LegacyDatum) => ({
  ...value,
  interactivity: {
    ...value.interactivity,
    ...interactivity,
  },
});

// Updater functions
const mainIframeLoaded = (state: State) => ({
  ...state,
  hasMainIframeLoaded: true,
});

const openModal =
  (viewport: { width: number; height: number }) => (state: State) => ({
    ...state,
    isModalOpen: true,
    viewport,
  });

const closeModal = (state: State) => ({
  ...state,
  isModalOpen: false,
});

const makeModalInvisible = (state: State) => ({
  ...state,
  isModalVisible: false,
});

const makeModalVisible = (state: State) => ({
  ...state,
  isModalVisible: true,
});

// We are just getting back a JSON blob from GraphQL so we will provide a
// minimal type definition for the shape we will be working with.
export type LegacyDatum = {
  eye_candy: {
    axes: { [key: string]: unknown };
    axis_intersection?: string;
    background_layer?: Array<{ [key: string]: unknown }>;
    foreground_layer?: Array<{ [key: string]: unknown }>;
    tickLabelFormat?: string;
    width?: number;
    height?: number;
  };
  interactivity: {
    interactive_layer: Array<{
      [key: string]: unknown;
      plots?: Array<{ [key: string]: unknown }>;
    }>;
    unlock?: boolean; // client-local property
  };
  type: 'PlottableGraph';
};

type Props = {
  value: LegacyDatum;
  onChange: (value: LegacyDatum) => void;
  onSubmitAnswer: () => void;
  disabled?: boolean;
  config?:
    | {
        // Determines if users can open the zoomed-in modal overlay of the GraphPlot
        allowZooming: boolean;
        width: number;
        height: number;
      }
    | undefined;
  isSubmissionDisabled?: boolean;
};

type State = {
  isModalOpen: boolean;
  isModalVisible: boolean;
  hasMainIframeLoaded: boolean;
  viewport: {
    width: number;
    height: number;
  };
};

class GraphPlot extends Component<Props, State> {
  static defaultProps = {
    config: { allowZooming: true, width: 350, height: 350 },
  };

  override state: State = {
    isModalOpen: false,
    // We need to be able to temporarily toggle the visibility of the modal
    // without actually closing/opening the modal.
    isModalVisible: true,
    hasMainIframeLoaded: false,
    viewport: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };

  override componentDidMount() {
    window.addEventListener('message', this.handleMessage);
  }

  override componentDidUpdate(prevProps: Props) {
    // Notify both iframes if our datum has changed.
    if (!equals(prevProps.value, this.props.value)) {
      const message = updateMessage(this.props.value);
      postMessageTo(this.mainIframe.current, message);
      postMessageTo(this.modalIframe.current, message);
    }
  }

  override componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  mainIframe: { readonly current: HTMLIFrameElement | null } = createRef();
  modalIframe: { readonly current: HTMLIFrameElement | null } = createRef();

  // We need a unique identifier for the component instance that we can pass
  // to the iframes, so that we can identify messages in the global event
  // bus that are coming from our iframes.
  instanceId = genUniqueId();

  handleMessage = (event: MessageEvent) => {
    const { data } = event;
    // We are dealing with a global event bus, so we need to ensure that we only
    // deal with one of the messages with the shape we expect from our iframe.
    if (
      !(data instanceof Object) ||
      data.type == null ||
      data.type !== 'dynamicStateUpdated' ||
      data.componentId == null ||
      data.componentId !== this.instanceId ||
      data.interactivity == null
    )
      return;

    this.props.onChange(
      updateDynamicDatumState(data.interactivity, this.props.value),
    );
  };

  handleMainIframeLoad = () => {
    postMessageTo(
      this.mainIframe.current,
      mountMessage(this.instanceId, this.props.value, {
        // Remove value coercion when refactored into function component
        width: this.props.config?.width ?? GraphPlot.defaultProps.config.width,
        height:
          this.props.config?.height ?? GraphPlot.defaultProps.config.height,
      }),
    );
    this.setState(mainIframeLoaded);
  };

  handleModalIframeLoad =
    (iframeDimensions: { width: number; height: number }) => () => {
      postMessageTo(
        this.modalIframe.current,
        mountMessage(this.instanceId, this.props.value, iframeDimensions),
      );
    };

  override render() {
    const { value, config, isSubmissionDisabled } = this.props;
    const modalIframeDimensions = getModalIframeDimensions(this.state.viewport);

    return (
      <div className={css(styles.root)}>
        <div className={css(styles.mainIframeBox)}>
          <iframe
            title="graph-plot"
            className={css(styles.iframe)}
            // Remove value coercion when refactored into function component
            width={reduceWidth(
              value,
              config?.width ?? GraphPlot.defaultProps.config.width,
            )}
            height={config?.height ?? GraphPlot.defaultProps.config.height}
            ref={this.mainIframe}
            onLoad={this.handleMainIframeLoad}
            src={IFRAME_SRC}
          />
          <div
            className={css(styles.loadingMessage)}
            style={{
              opacity: this.state.hasMainIframeLoaded ? 0 : 1,
            }}
          >
            Loading Graph...
          </div>
        </div>

        {config?.allowZooming && (
          <Fragment>
            <button
              className={css(styles.zoomInButton)}
              style={{
                opacity: this.state.hasMainIframeLoaded ? 1 : 0,
              }}
              onClick={() =>
                this.setState(
                  openModal({
                    width: window.innerWidth,
                    height: window.innerHeight,
                  }),
                )
              }
            >
              <ZoomInIcon width={ACTION_ICON_SIZE} height={ACTION_ICON_SIZE} />
            </button>

            <Modal
              isOpen={this.state.isModalOpen}
              onClose={() => this.setState(closeModal)}
              showCloseButton={false}
              sizeToContent
              portalStyles={[
                styles.modal,
                !this.state.isModalVisible && styles.transparent,
              ]}
            >
              <iframe
                title="graph-plot"
                className={css(styles.iframe)}
                width={reduceWidth(value, modalIframeDimensions.width)}
                height={modalIframeDimensions.height}
                ref={this.modalIframe}
                onLoad={this.handleModalIframeLoad(modalIframeDimensions)}
                src={IFRAME_SRC}
              />
              <div className={css(styles.actionsBar)}>
                <Action
                  color="matisse"
                  aphroditeStyles={[styles.iconButton]}
                  onMouseDown={() => {
                    // NOTE this callback will trigger for IE11 on hybrid devices
                    this.setState(makeModalInvisible);

                    // We need to track the mouseup on the entire window as
                    // otherwise you can drag your cursor outside of the browser
                    // and release it without us being able to detect it.
                    once('mouseup', window, () => {
                      this.setState(makeModalVisible);
                    });

                    // Disable long-press context menu on IE11 from showing
                    once('contextmenu', window, e => {
                      e.preventDefault();
                    });
                  }}
                  onTouchStart={event => {
                    event.preventDefault();
                    this.setState(makeModalInvisible);

                    once('touchend', window, () => {
                      this.setState(makeModalVisible);
                    });
                    once('touchcancel', window, () => {
                      this.setState(makeModalVisible);
                    });

                    // Tap and hold triggers a context menu by default so we need
                    // to prevent that.
                    once('contextmenu', window, e => {
                      e.preventDefault();
                    });
                  }}
                >
                  <VisibilityIcon
                    width={ACTION_ICON_SIZE}
                    height={ACTION_ICON_SIZE}
                  />
                </Action>
                <Action
                  color="shamrock"
                  disabled={this.props.disabled || isSubmissionDisabled} // this feels so wrong
                  onClick={this.props.onSubmitAnswer}
                >
                  Submit answer
                </Action>
                <Action
                  color="matisse"
                  aphroditeStyles={[styles.iconButton]}
                  onClick={() => this.setState(closeModal)}
                >
                  <ZoomOutIcon
                    width={ACTION_ICON_SIZE}
                    height={ACTION_ICON_SIZE}
                  />
                </Action>
              </div>
            </Modal>
          </Fragment>
        )}
      </div>
    );
  }
}

export default GraphPlot;
