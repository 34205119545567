import BoxPlot from 'ms-components/math/BoxPlot';
import type { Value } from 'ms-components/math/BoxPlot/utils';

type Props = {
  width?: number | undefined;
  height?: number | undefined;
  axisTitle: string;
  axisMin: number;
  axisMax: number;
  axisMajorTickInterval: number;
  axisMinorTickInterval: number;

  value: Value;
};

const BoxPlotReadOnly = ({
  width,
  height,
  axisTitle,
  axisMin,
  axisMax,
  axisMajorTickInterval,
  axisMinorTickInterval,
  value,
}: Props) => (
  <BoxPlot
    width={width}
    height={height}
    axisTitle={axisTitle}
    axisMin={axisMin}
    axisMax={axisMax}
    axisMajorTickInterval={axisMajorTickInterval}
    axisMinorTickInterval={axisMinorTickInterval}
    value={value}
    readOnly
  />
);

export default BoxPlotReadOnly;
