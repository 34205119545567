import type { ReactElement, ReactNode } from 'react';

import {
  TooltipContent,
  TooltipTitle,
} from 'ms-pages/Teacher/components/Tooltip';
import {
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  borderRadiusUI,
} from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import { BASE_UNIT } from 'ms-styles/theme/Numero';
import Tooltip from 'ms-ui-primitives/Tooltip';
import { styled, tappable } from 'ms-utils/emotion';

const TOOLTIP_MIN_WIDTH = 20 * BASE_UNIT;
export const ROUNDED_BORDER_WIDTH = 4.5;

const BarGraph = styled({
  display: 'flex',
  fontFamily: fontFamily.body,
  lineHeight: lineHeight.title,
  overflow: 'hidden',
});

const Segment = styled({
  default: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    fontSize: fontSize.small,
    fontWeight: fontWeight.semibold,
  },
  tappable,
});

const SegmentText = styled({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `0 ${BASE_UNIT}px`,
});

const BarAxis = styled({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 2 * BASE_UNIT,
});

const BarAxisLabel = styled({
  fontFamily: fontFamily.body,
  color: colors.cloudBurst,
  fontSize: fontSize.xSmall,
  lineHeight: lineHeight.body,
});

type NamedColor = keyof typeof colors;

type BarSegmentProps = {
  background: NamedColor;
  widthProportion: number;
  height: number | string;
  color?: NamedColor;
  children?: ReactNode;
  tooltipTitle?: string;
  tooltipContent?: string;
  tooltipMinWidth?: number;
  borderRight?: boolean;
  borderRightRadius?: number;
  borderRightWidth?: number;
  borderRightColor?: NamedColor;
  outerBorderRight?: boolean;
  outerBorderRightWidth?: number;
  outerBorderRightRadius?: number;
  outerBorderRightColor?: NamedColor;
  onClick?: () => void;
};

export const BarSegment = ({
  children,
  color = 'white',
  background,
  widthProportion,
  height,
  tooltipTitle,
  tooltipContent,
  tooltipMinWidth = TOOLTIP_MIN_WIDTH,
  borderRight,
  borderRightWidth = ROUNDED_BORDER_WIDTH,
  borderRightRadius = borderRadiusUI,
  borderRightColor = background,
  outerBorderRight,
  outerBorderRightWidth = ROUNDED_BORDER_WIDTH,
  outerBorderRightRadius = borderRadiusUI,
  outerBorderRightColor = 'white',
  onClick,
}: BarSegmentProps) => {
  if (widthProportion === 0) return null; // BarSegment should be empty when proportion is 0
  const content = (
    <Segment
      style={{
        color: colors[color],
        background: colors[background],
        ...(borderRight
          ? {
              borderRight: `${borderRightWidth}px solid ${colors[borderRightColor]}`,
              borderTopRightRadius: borderRightRadius,
              borderBottomRightRadius: borderRightRadius,
            }
          : {}),
      }}
      onClick={onClick}
      tappable={Boolean(onClick)}
    >
      {tooltipTitle != null || tooltipContent != null ? (
        <Tooltip
          renderTooltipContent={() => (
            <TooltipContent minWidth={tooltipMinWidth}>
              {tooltipTitle != null && (
                <TooltipTitle>{tooltipTitle}</TooltipTitle>
              )}
              {tooltipContent != null && <div>{tooltipContent}</div>}
            </TooltipContent>
          )}
        >
          <SegmentText style={{ height }}>{children}</SegmentText>
        </Tooltip>
      ) : (
        <SegmentText style={{ height }}>{children}</SegmentText>
      )}
    </Segment>
  );

  return (
    <div
      style={{
        flexGrow: widthProportion,
        ...(outerBorderRight
          ? {
              backgroundColor: colors[outerBorderRightColor],
              borderRight: `${outerBorderRightWidth}px solid ${colors[outerBorderRightColor]}`,
              borderTopRightRadius: outerBorderRightRadius,
              borderBottomRightRadius: outerBorderRightRadius,
            }
          : {}),
      }}
    >
      {content}
    </div>
  );
};

type BarProps = {
  height: number | string;
  children: ReactElement<typeof BarSegment> | ReactElement<typeof BarSegment>[];
  width?: number | string;
  background?: NamedColor;
  axisLabels?: readonly (number | string)[];
  borderRadius?: number;
};

const Bar = ({
  height,
  children,
  width,
  background = 'white',
  axisLabels,
  borderRadius = borderRadiusUI,
}: BarProps) => (
  <>
    <BarGraph
      style={{ height, width, background: colors[background], borderRadius }}
    >
      {children}
    </BarGraph>
    {axisLabels != null && (
      <BarAxis>
        {axisLabels.map((label, index) => (
          <BarAxisLabel key={index}>{label}</BarAxisLabel>
        ))}
      </BarAxis>
    )}
  </>
);

export default Bar;
