import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const Clock = (props: Props) => (
  <Icon {...props} viewBoxWidth={18} viewBoxHeight={18}>
    <path
      d="M10,1a9,9,0,1,0,9,9A9,9,0,0,0,10,1Zm0,16a7,7,0,1,1,7-7A7,7,0,0,1,10,17Z"
      transform="translate(-1 -1)"
    />
    <path
      d="M14,9.63H11V6.28a1,1,0,1,0-2,0v4.35a1,1,0,0,0,1,1H14a1,1,0,0,0,0-2Z"
      transform="translate(-1 -1)"
    />
  </Icon>
);

export default Clock;
