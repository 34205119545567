import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 6.66516L3 6.66516C2.73478 6.66516 2.48043 6.77052 2.29289 6.95805C2.10536 7.14559 2 7.39994 2 7.66516L2 9.99848C2 10.2637 2.10536 10.5181 2.29289 10.7056C2.48043 10.8931 2.73478 10.9985 3 10.9985L6.66667 10.9985L6.66667 6.66516Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8967 3.17183C14.7576 3.07771 14.5971 3.02013 14.4299 3.0044C14.2627 2.98867 14.0942 3.0153 13.94 3.08183L7.33337 6.45516L7.33337 11.2118L13.9367 14.6052C14.256 14.7033 14.6012 14.6709 14.8967 14.5152C15.0313 14.4234 15.1415 14.3001 15.2176 14.156C15.2937 14.0119 15.3334 13.8514 15.3334 13.6885L15.3334 3.9985C15.3334 3.83556 15.2937 3.67507 15.2176 3.53099C15.1415 3.38692 15.0313 3.26362 14.8967 3.17183Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85007 11.6652L4.15674 11.6652L5.09007 14.9385C5.1496 15.148 5.2759 15.3323 5.44978 15.4635C5.62366 15.5947 5.8356 15.6655 6.0534 15.6652L6.66673 15.6652C6.819 15.6585 6.96792 15.6183 7.10281 15.5474C7.23771 15.4765 7.35525 15.3766 7.447 15.2549C7.53875 15.1332 7.60244 14.9927 7.63352 14.8435C7.66459 14.6943 7.66227 14.54 7.62673 14.3918L6.85007 11.6652Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7.2616L16 10.2616C16.2918 10.1684 16.5445 9.9959 16.7235 9.7678C16.9026 9.5397 16.9991 9.26714 17 8.9875L17 8.53569C16.9991 8.25605 16.9026 7.98349 16.7235 7.75539C16.5445 7.52729 16.2918 7.35481 16 7.2616Z"
    />
  </>
);

const Megaphone = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={18}
    viewBoxHeight={18}
  >
    {path}
  </Icon>
);

export default Megaphone;
