import { css } from '@emotion/css';
import { motion } from 'framer-motion';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import MinusIcon from 'ms-components/icons/Minus';
import PlusIcon from 'ms-components/icons/Plus';
import { useSnowplow } from 'ms-helpers/Snowplow';
import type { SupportedStructEvent } from 'ms-helpers/Snowplow/Types';
import { BodyM } from 'ms-pages/Lantern/primitives/Typography';
import type { PRIMARY_CATFA_ACTION_BUTTON_ID } from 'ms-pages/Teacher/components/CreateTaskModal/components/CreateTaskModalHeader';
import type { COLLECTED_ITEM_COUNT_INDICATOR_BUBBLE_ID } from 'ms-pages/Teacher/components/Navbar/NavbarLeftWhite/CollectedItemCountIndicatorBubble';
import {
  UpdatersContext as ProblemsCollectionUpdaters,
  StateContext as ProblemsCollectionState,
} from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state';
import type { Problem } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/State';
import { MAX_NUMBER_OF_QUESTIONS } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/helpers';
import type { CollectionType } from 'ms-pages/Textbooks/components/ContentCollection/ProblemsCollection/state/updaters';
import { zIndex } from 'ms-styles/base';
import { colors } from 'ms-styles/colors';
import Button, { sizeToHeight } from 'ms-ui-primitives/Button';
import Stack from 'ms-ui-primitives/Stack';
import { hexStringToRgbaString } from 'ms-utils/colors';
import useAnimationOnChange from 'ms-utils/hooks/useAnimationOnChange';

// the consuming component must pass one of the supported dom id
export type SupportedAnimationTarget =
  | typeof PRIMARY_CATFA_ACTION_BUTTON_ID
  | typeof COLLECTED_ITEM_COUNT_INDICATOR_BUBBLE_ID;

type Props = {
  problemContent: Problem;
  animationTarget: SupportedAnimationTarget;
  useTransientProblemsCollection?: boolean | undefined;
  structEventToTrackOnAdd: SupportedStructEvent;
};

const ADD_BUTTON_SIZE_PROP = 'tiny';
const COLOR_NAME = 'eggplant';
const ANIMATING_COLOR_NAME = 'crusta';
const HEIGHT = sizeToHeight[ADD_BUTTON_SIZE_PROP];
const BORDER_RADIUS = HEIGHT / 2;
const GAP = 4;
const PROBLEM_COUNT_WIDTH = 16;

// this is to move the plus button 1px to the right so that,
// when it's disabled, the purple color of its container is not visible
// behind it
const PLUS_BUTTON_X_TRANSLATION = 1;
const PLUS_BUTTON_TRANSLATE_CLASSNAME = css({
  transform: `translateX(${PLUS_BUTTON_X_TRANSLATION}px)`,
});

export const ADD_BUTTON_MAX_WIDTH =
  sizeToHeight[ADD_BUTTON_SIZE_PROP] * 2 +
  GAP * 2 +
  PROBLEM_COUNT_WIDTH +
  PLUS_BUTTON_X_TRANSLATION;

const ANIMATION_TIME = 500;

const PLUS_BUTTON_PARTICLE_COLOR = hexStringToRgbaString(
  colors[ANIMATING_COLOR_NAME],
  0.5,
);

const PLUS_BUTTON_PARTICLE_SIZE = HEIGHT;

const PLUS_BUTTON_PARTICLE_CLASSNAME = css({
  position: 'fixed',
  zIndex: zIndex.plusButtonParticle,
  backgroundColor: PLUS_BUTTON_PARTICLE_COLOR,
  color: colors.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: PLUS_BUTTON_PARTICLE_SIZE,
  width: PLUS_BUTTON_PARTICLE_SIZE,
  borderRadius: PLUS_BUTTON_PARTICLE_SIZE / 2,
});

const PLUS_BUTTON_TRANSITION_CONFIG = {
  duration: ANIMATION_TIME / 1000,
  ease: 'easeInOut',
} as const;

type Cohordinates = { x: number; y: number };

function PlusButtonParticle({
  animationTarget,
  animationStartRef,
}: {
  animationTarget: SupportedAnimationTarget;
  animationStartRef: { current: HTMLElement | null };
}) {
  const [animationTargetCohords, setAnimationEndTargetCohors] =
    useState<Cohordinates | null>(null);
  const [animationStartCohords, setAnimationStartTargetCohors] =
    useState<Cohordinates | null>(null);

  useEffect(() => {
    // get cohordinates of the animation end cohordinates: the target element by the id provided via props
    // We are assuming, as it currently is the case for the supported targets, that they are not moving with respect to the screen (i.e. they have position fixed or are tied to a fixed positioned containers)
    const targetElement = document.getElementById(animationTarget);
    if (targetElement != null) {
      const { x, y } = targetElement.getBoundingClientRect();
      setAnimationEndTargetCohors({ x, y });
    }
    const startElement = animationStartRef.current;
    if (startElement != null) {
      const { x, y } = startElement.getBoundingClientRect();
      setAnimationStartTargetCohors({ x, y });
    }
  }, [animationTarget, animationStartRef]);

  return (
    animationTargetCohords != null &&
    animationStartCohords != null && (
      <motion.div
        className={PLUS_BUTTON_PARTICLE_CLASSNAME}
        initial={{
          left: animationStartCohords.x,
          top: animationStartCohords.y,
          scale: 1,
          opacity: 1,
        }}
        animate={{
          left: animationTargetCohords.x,
          top: animationTargetCohords.y,
          scale: 1.2,
          opacity: 0.8,
        }}
        transition={PLUS_BUTTON_TRANSITION_CONFIG}
      >
        <PlusIcon size={8} />
      </motion.div>
    )
  );
}

export default function AddProblemToCartButton({
  problemContent,
  problemContent: [problemContentId],
  animationTarget,
  useTransientProblemsCollection = false,
  structEventToTrackOnAdd,
}: Props) {
  const {
    problemsCollection: _problemsCollection,
    transientProblemsCollection,
  } = useContext(ProblemsCollectionState);
  const { addProblemContent, removeProblemContentById } = useContext(
    ProblemsCollectionUpdaters,
  );

  const problemsCollection = useTransientProblemsCollection
    ? transientProblemsCollection
    : _problemsCollection;

  const problemCollectionType: CollectionType = useTransientProblemsCollection
    ? 'transient'
    : 'main';

  const problemCount = useMemo(
    () => problemsCollection.filter(([id]) => id === problemContentId).length,
    [problemContentId, problemsCollection],
  );

  // has been collected at least once
  const hasProblemBeenCollected = problemCount > 0;

  const isPlusButtonDisabled = useMemo(
    () => problemsCollection.length >= MAX_NUMBER_OF_QUESTIONS,
    [problemsCollection],
  );

  const [enableAnimation, setEnableAnimation] = useState(false);

  const { trackStructEvent } = useSnowplow();

  const onAddProblemToCart = useCallback(() => {
    addProblemContent(problemContent, problemCollectionType);
    trackStructEvent(structEventToTrackOnAdd);
    setEnableAnimation(true);
    const timeout = setTimeout(() => {
      setEnableAnimation(false);
      clearTimeout(timeout);
    }, ANIMATION_TIME);
  }, [
    addProblemContent,
    problemCollectionType,
    problemContent,
    structEventToTrackOnAdd,
    trackStructEvent,
  ]);

  const onRemoveProblemFromCart = useCallback(() => {
    removeProblemContentById(problemContentId, problemCollectionType);
  }, [problemCollectionType, problemContentId, removeProblemContentById]);

  const isAnimating = useAnimationOnChange({
    animationTime: ANIMATION_TIME,
    observedValue: problemCount,
    turnOnWhen: valueIncreases,
    turnOffWhen: valueDecreases,
  });

  const backgroundColorName = isAnimating ? ANIMATING_COLOR_NAME : COLOR_NAME;

  const plusButtonRef = useRef<HTMLDivElement>(null);

  return (
    <Stack.H
      center
      gap={GAP}
      height={HEIGHT}
      style={{
        position: 'relative',
        backgroundColor: hasProblemBeenCollected
          ? colors[backgroundColorName]
          : 'transparent',
        borderRadius: BORDER_RADIUS,
        boxShadow: isAnimating ? `0 0 0 8px ${colors.lighPeach}` : 'none',
      }}
    >
      {isAnimating && enableAnimation && (
        <PlusButtonParticle
          animationStartRef={plusButtonRef}
          animationTarget={animationTarget}
        />
      )}

      {hasProblemBeenCollected && (
        <>
          <Button
            // disable transitions to button to avoid flickering
            // when the container color changes
            transition={0}
            isCircle
            type="primary"
            color={backgroundColorName}
            size={ADD_BUTTON_SIZE_PROP}
            onClick={onRemoveProblemFromCart}
            label="Remove problem from cart"
          >
            <MinusIcon />
          </Button>

          <Stack.H center justify="center" width={PROBLEM_COUNT_WIDTH}>
            <BodyM
              bold
              color="white"
              className={css({
                // this does not seem to work as I'd anticipate :/
                fontVariantNumeric: ' tabular-nums',
              })}
            >
              {problemCount}
            </BodyM>
          </Stack.H>
        </>
      )}
      <div ref={plusButtonRef} className={PLUS_BUTTON_TRANSLATE_CLASSNAME}>
        <Button
          // disable transitions to button to avoid flickering
          // when the container color changes
          transition={0}
          isCircle
          color={backgroundColorName}
          type={hasProblemBeenCollected ? 'primary' : 'secondary'}
          isDisabled={isPlusButtonDisabled}
          size={ADD_BUTTON_SIZE_PROP}
          onClick={onAddProblemToCart}
          label="Add problem to cart"
        >
          <PlusIcon />
        </Button>
      </div>
    </Stack.H>
  );
}

function valueIncreases(curN: number, prevN: number) {
  return curN > prevN;
}

function valueDecreases(curN: number, prevN: number) {
  return curN < prevN;
}

export function useAnimateOnCartItemsIncreasing() {
  const { problemsCollection } = useContext(ProblemsCollectionState);
  const numberOfProblemsInCart = problemsCollection.length;

  return useAnimationOnChange({
    animationTime: ANIMATION_TIME,
    observedValue: numberOfProblemsInCart,
    turnOnWhen: valueIncreases,
    turnOffWhen: valueDecreases,
  });
}
