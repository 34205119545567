import { css, StyleSheet } from 'aphrodite';
import { identity, sort, findLast, groupWith, equals, isEmpty } from 'ramda';
import type { ComponentType } from 'react';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { DropTarget } from 'react-dnd';
import type { WrappedConnectorHook, Point } from 'react-dnd';

import type {
  DroppedItem,
  DropZoneItemWithId,
  ItemType,
} from 'ms-components/math/NumberBuilder';
import DraggableItem from 'ms-components/math/NumberBuilder/DraggableItem';
import type { ItemDropData } from 'ms-components/math/NumberBuilder/DraggableItem';
import { colors } from 'ms-styles/colors';
import { usePrevious } from 'ms-utils/hooks/usePrevious';
import { unwrap } from 'ms-utils/typescript-utils';

import styles, { CONTAINER_PADDING } from './styles';

// Sort DropZoneItems in decending order of their values.
const descendingValues = sort<DropZoneItemWithId>(
  (a, b) => b.data.value - a.data.value,
);

function groupBlocks(values: DropZoneItemWithId[]) {
  // We want to show 100s, then 10s, then 1s in a left-to-right fashion,
  // so we sort the items in the drop zone in descending order of their value.
  const sorted = descendingValues(values);

  // Partition the list by the place value, so that we have sublists
  // of 100s, 10s, and 1s.
  const placeValueGroups = groupWith(
    (a, b) => equals(a.data.value, b.data.value),
    sorted,
  );

  // For the 1s group, we need to chunk the list into sublists
  // of length 10. We do this so we can render ten 1 blocks in a way
  // that takes up the same space as one 10 block.
  const withOnesGrouped = placeValueGroups.map(group => {
    if (group[0]?.data?.value !== 1) return group;
    const groupsOf10 = [];
    for (let i = 0; i < group.length; i += 10) {
      groupsOf10.push(group.slice(i, i + 10));
    }
    return groupsOf10;
  });

  return withOnesGrouped;
}

// Produces an x/y point given a rect (such as that returned by getBoundingClientRect)
const rectToOffset = (rect: DOMRect): Point => ({ x: rect.left, y: rect.top });

type Value = DropZoneItemWithId;

type Props = {
  id: string;
  values: Array<Value>;
  isOver: boolean;
  connectDropTarget: WrappedConnectorHook;
  itemType: ItemType;
  itemComponent: ComponentType<any>;
  onItemDropped: (data: ItemDropData) => void;
  onItemDropOutsideZone: (data: ItemDropData) => void;
  onItemDropOnZone: (data: ItemDropData) => void;
  onZoneDrop: (droppedItem: DroppedItem) => void;
  isReadOnly: boolean;
  canDrop: boolean;
  numberBuilderId: number;
  // The most recently dropped item.
  droppedItem: DroppedItem | undefined;
};

function ItemsDropZone(props: Props) {
  // Underlying DOM node for each item. Keyed by render position.
  const itemNodesRef = useRef<HTMLElement[]>([]);
  const prevProps = usePrevious(props);
  const {
    id,
    values,
    isOver,
    connectDropTarget,
    itemComponent: Item,
    onItemDropped,
    onItemDropOutsideZone,
    onItemDropOnZone,
    isReadOnly,
    canDrop,
    numberBuilderId,
    droppedItem,
  } = props;

  useLayoutEffect(() => {
    // Ensure there is a dropped item to animate.
    if (
      !droppedItem ||
      (prevProps != null && prevProps.values.length >= values.length)
    )
      return;

    const { value, dropOffset } = droppedItem;

    const destinationData = findLast(
      v => v.data.value === value,
      descendingValues(values),
    );
    if (!destinationData) return;

    const node = unwrap(itemNodesRef.current[destinationData.id]);
    const endOffset = rectToOffset(node.getBoundingClientRect());
    const dx = dropOffset.x - endOffset.x;
    const dy = dropOffset.y - endOffset.y;

    node.style.transform = `translateX(${dx}px) translateY(${dy}px)`;

    window.setTimeout(() => {
      node.style.transition = 'all 300ms ease-out';
      node.style.transform = 'translateX(0px) translateY(0px)';
    });
  }, [droppedItem, prevProps, values]);

  // Renders a single drop zone item
  const renderItem = useCallback(
    (value: DropZoneItemWithId, wrapStyles = {}) => {
      const dynamicStyles = StyleSheet.create({
        itemWrap: {
          cursor: isReadOnly ? 'not-allowed' : 'inherit',
          ...wrapStyles,
        },
      });

      if (isReadOnly) {
        return (
          <div
            key={value.id}
            className={css(styles.itemWrap, dynamicStyles.itemWrap)}
          >
            <Item value={value.data.value} isDisabled />
          </div>
        );
      }

      return (
        <div
          key={value.id}
          className={css(styles.itemWrap, dynamicStyles.itemWrap)}
          ref={node => {
            if (node) itemNodesRef.current[value.id] = node;
          }}
        >
          <DraggableItem
            id={value.id}
            value={value.data.value}
            onItemDropped={onItemDropped}
            itemComponent={Item}
            onItemDropOnZone={onItemDropOnZone}
            onItemDropOutsideZone={onItemDropOutsideZone}
            dragStartContainer="zone"
            dragStartContainerId={id}
            numberBuilderId={numberBuilderId}
          />
        </div>
      );
    },
    [
      Item,
      id,
      isReadOnly,
      numberBuilderId,
      onItemDropOnZone,
      onItemDropOutsideZone,
      onItemDropped,
    ],
  );

  const renderBlocks = useCallback(
    (values: DropZoneItemWithId[]) => {
      const blockItemStyles = { marginTop: 0, marginBottom: 2 };
      const placeValueGroups = groupBlocks(values);

      const blocksOrOnesGroupElements: JSX.Element[] = [];
      let key = 0;
      placeValueGroups.forEach(group => {
        group.forEach(itemOrOnesGroup => {
          if (Array.isArray(itemOrOnesGroup)) {
            // The 1s group, in sublits of 10 items
            blocksOrOnesGroupElements.push(
              <div
                key={key++}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {itemOrOnesGroup.map(item => renderItem(item, blockItemStyles))}
              </div>,
            );
          } else {
            // Either a 100 block or a 10 block
            blocksOrOnesGroupElements.push(
              renderItem(itemOrOnesGroup, blockItemStyles),
            );
          }
        });
      });

      return (
        <div className={css(styles.itemsContainer)}>
          {blocksOrOnesGroupElements}
        </div>
      );
    },
    [renderItem],
  );

  let backgroundColor;
  if (isOver && !canDrop) backgroundColor = colors.wispPink;
  else if (isOver) backgroundColor = colors.tropicalBlue;
  else backgroundColor = colors.ironLight;

  let borderColor;
  if (isOver && !canDrop) borderColor = colors.cinnabar;
  else if (isReadOnly) borderColor = colors.athensGray;
  else if (isOver) borderColor = colors.lochmara;
  else borderColor = colors.santasGray;

  let color; // i.e. text color
  if (isOver && !canDrop) color = colors.cinnabar;
  else if (isReadOnly) color = colors.athensGray;
  else if (isOver) color = colors.lochmara;
  else color = colors.santasGray;

  const dynamicStyles = StyleSheet.create({
    container: {
      backgroundColor,
      borderColor,
      ...(!isReadOnly
        ? {
            borderStyle: 'dashed',
            borderWidth: 2,
            display: 'flex', // to center the hint within
            minHeight: 110,
            paddingLeft: CONTAINER_PADDING,
            paddingRight: CONTAINER_PADDING,
          }
        : {}),
    },
    hint: {
      color,
      ...(!isReadOnly
        ? { alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' } // center vertically and horizontally
        : {}),
    },
  });

  let hintText;
  if (isOver && !canDrop) hintText = 'You cannot drop that item here';
  else if (isReadOnly) hintText = 'No items were dropped';
  else hintText = 'Drop items here';

  const maybeConnectDropTarget = isReadOnly ? identity : connectDropTarget;
  return maybeConnectDropTarget(
    <div className={css(styles.container, dynamicStyles.container)}>
      {isEmpty(values) && (
        <div className={css(styles.hint, dynamicStyles.hint)}>{hintText}</div>
      )}
      {renderBlocks(values)}
    </div>,
  );
}

const enhance = DropTarget(
  'ITEM',
  {
    // Don't use the `component` arg, because it'll always be null
    // See: https://github.com/react-dnd/react-dnd/blob/v2.5.4/docs/01%20Top%20Level%20API/DropTarget.md#specification-method-parameters
    drop(props: Props, monitor, _functionComponentIsAlwaysNull) {
      const value = monitor.getItem().value;
      const dropOffset = monitor.getSourceClientOffset();

      if (typeof value === 'number' && dropOffset != null) {
        props.onZoneDrop({ value, dropOffset });
      }

      return {
        id: props.id,
        dropContainer: 'zone',
      };
    },
    canDrop(props, monitor) {
      return props.numberBuilderId === monitor.getItem().numberBuilderId;
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
);

export default enhance(ItemsDropZone);
