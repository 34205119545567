import { graphql } from 'react-relay';

import useMutation from 'ms-utils/relay/useMutation';
import type { UseMutationResponse } from 'ms-utils/relay/useMutation';

import type {
  useCreateSelfDirectedTaskMutationVariables,
  useCreateSelfDirectedTaskMutationResponse,
} from './__generated__/useCreateSelfDirectedTaskMutation.graphql';

export type { useCreateSelfDirectedTaskMutationVariables };
const mutation = graphql`
  mutation useCreateSelfDirectedTaskMutation(
    $token: ID!
    $recommendationCategory: SubstrandSubtopicRecommendationCategory
  ) {
    createWorkoutFromToken(
      token: $token
      recommendationCategory: $recommendationCategory
    ) {
      workout {
        id
      }
    }
  }
`;
export default function useCreateSelfDirectedTask(
  variables: useCreateSelfDirectedTaskMutationVariables,
): UseMutationResponse<useCreateSelfDirectedTaskMutationResponse> {
  return useMutation({ mutation, variables });
}
