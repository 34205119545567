import { useState, useEffect } from 'react';

import Button from 'ms-ui-primitives/Button';
import type { ButtonSize } from 'ms-ui-primitives/Button';
import { copyToClipboard } from 'ms-utils/clipboard';

type Props = {
  link: string;
  isFilled?: boolean | undefined;
  isBlock?: boolean | undefined;
  size?: ButtonSize | undefined;
  removeLateralPadding?: boolean | undefined;
};

const CopyToClipboardButton = ({
  isFilled = false,
  isBlock = true,
  size,
  link,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleButtonClick = () => {
    copyToClipboard(link);
    setIsCopied(true);
  };

  // Re-enable CopyToClipboardButton after 2 seconds
  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
    return;
  }, [isCopied]);

  return (
    <Button
      isBlock={isBlock}
      type={isFilled || isCopied ? 'primary' : 'secondary'}
      onClick={handleButtonClick}
      size={size}
    >
      {isCopied ? 'Copied' : 'Copy'}
    </Button>
  );
};

export default CopyToClipboardButton;
