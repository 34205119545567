import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const HighlighterIcon = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
    viewBoxWidth={17}
    viewBoxHeight={16}
  >
    <g clipPath="url(#clip0)">
      <path d="M14.1987 15.9999H0.95588C0.549048 15.9999 0.220215 15.6704 0.220215 15.2642C0.220215 14.8582 0.549048 14.5286 0.95588 14.5286H14.1987C14.6055 14.5286 14.9343 14.8582 14.9343 15.2642C14.9343 15.6704 14.6055 15.9999 14.1987 15.9999Z" />
      <path d="M15.3915 2.33931L13.6971 0.645029C12.8783 -0.174562 11.5541 -0.217939 10.6852 0.542782L3.76503 6.55136C3.61065 6.68527 3.51932 6.87723 3.51204 7.08105C3.5076 7.21267 3.54949 7.33633 3.61133 7.4503L2.46209 9.4493C2.29586 9.73772 2.34449 10.1012 2.57983 10.3365L2.8374 10.5941L0.898756 12.5377C0.688335 12.7481 0.625828 13.0646 0.739795 13.339C0.853896 13.6141 1.12238 13.7928 1.41969 13.7928H4.36248C4.53397 13.7928 4.70021 13.7326 4.83263 13.6236L5.39761 13.1543L5.70072 13.4574C5.84203 13.5994 6.03103 13.6729 6.22165 13.6729C6.34666 13.6729 6.47329 13.6413 6.58807 13.5751L8.58545 12.4267C8.69214 12.4848 8.80692 12.5253 8.93045 12.5253H8.95631C9.16 12.5179 9.3521 12.4267 9.486 12.2722L15.4931 5.35431C16.2545 4.48245 16.2103 3.15822 15.3915 2.33931ZM6.34235 12.0184L4.39859 10.0746C4.39778 10.0738 4.3971 10.0725 4.3963 10.0717C4.39562 10.071 4.39414 10.0702 4.39333 10.0695L4.01816 9.6942L4.66707 8.56639L7.47084 11.3702L6.34235 12.0184Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.118652)"
        />
      </clipPath>
    </defs>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

HighlighterIcon.displayName = 'HighlighterIcon';

export default HighlighterIcon;
