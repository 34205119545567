import styled from '@emotion/styled';

import {
  StudentLayout,
  StudentCheckInLayout as Layout,
} from 'ms-pages/Lantern/components/StudentLayout';
import { Button } from 'ms-pages/Lantern/primitives/Button';
import Main from 'ms-pages/Lantern/primitives/Main';
import {
  HStack,
  HSpacer,
  VStack,
  VSpacer,
} from 'ms-pages/Lantern/primitives/Stack';
import { BodyL, HeadingL } from 'ms-pages/Lantern/primitives/Typography';
import { studentDashboardPath } from 'ms-utils/urls';

import { CheckInUnavailableError } from '../CheckInUnavailableError';
import { PreTopicTestCheckInWelcomeScreen } from '../CheckInWelcomeScreen';
import useStartPreTopicTestCheckIn from '../useStartPreTopicTestCheckIn';

const PRE_TOPIC_TEST_CHECKIN_NUMBER_OF_QUESTIONS = 15;
const RootVStack = styled(VStack)({
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  textAlign: 'center',
});

type Props = {
  skillIds: string[];
};
export default function StartPreTopicTestCheckIn({ skillIds }: Props) {
  const { loading, errors, start } = useStartPreTopicTestCheckIn({
    skillIds,
  });

  if (errors.find(e => e.key === 'CHECK_IN_NOT_AVAILABLE') !== undefined) {
    return <CheckInUnavailableError />;
  }
  if (errors.length > 0) {
    return (
      <StudentLayout>
        <Main>
          <RootVStack>
            <HeadingL>There was an error loading this page</HeadingL>
            <VSpacer height={24} />
            <BodyL color="peachPink">
              {errors.map(e => e.message).join('')}
            </BodyL>
            <VSpacer height={40} />
            <HStack>
              <Button
                size="lanternMedium"
                onClick={start}
                type="primary"
                isDisabled={loading}
              >
                Try again
              </Button>
              <HSpacer width={32} />
              <Button
                size="lanternMedium"
                onClick={() => {
                  if (window.history.length > 1) window.history.back();
                  else window.location.assign(studentDashboardPath);
                }}
                type="primary"
              >
                Go back
              </Button>
            </HStack>
          </RootVStack>
        </Main>
      </StudentLayout>
    );
  }

  return (
    <Layout backgroundColor="almond50">
      <PreTopicTestCheckInWelcomeScreen
        skillIds={skillIds}
        isLoading={loading}
        onSubmit={() => {
          start();
        }}
        onClose={() => {
          window.location.assign(studentDashboardPath);
        }}
        checkInType="PRE_TOPIC_TEST_CHECKIN"
        numberOfQuestions={PRE_TOPIC_TEST_CHECKIN_NUMBER_OF_QUESTIONS}
      />
    </Layout>
  );
}
