import Icon from 'ms-ui-primitives/Icon';

import type { Props } from '../types';

const path = (
  <path
    d="M13.6570435,2.34295652 C10.5328696,-0.780869565 5.46747826,-0.781217391 2.34295652,2.34295652 C-0.781217391,5.46713043 -0.780869565,10.5325217 2.34295652,13.6570435 C5.46713043,16.7805217 10.5325217,16.7808696 13.6570435,13.6570435 C16.7808696,10.5325217 16.7805217,5.46747826 13.6570435,2.34295652 Z M9.04313043,11.4782609 C9.04313043,12.0546087 8.576,12.5217391 7.99965217,12.5217391 C7.42330435,12.5217391 6.95617391,12.0546087 6.95617391,11.4782609 L6.95617391,7.30434783 C6.95617391,6.728 7.42330435,6.26086957 7.99965217,6.26086957 C8.576,6.26086957 9.04313043,6.728 9.04313043,7.30434783 L9.04313043,11.4782609 Z M7.98121739,5.52069565 C7.38017391,5.52069565 6.97947826,5.09495652 6.992,4.5693913 C6.97947826,4.01843478 7.38017391,3.60556522 7.9933913,3.60556522 C8.60695652,3.60556522 8.99513043,4.01878261 9.008,4.5693913 C9.00765217,5.09495652 8.60730435,5.52069565 7.98121739,5.52069565 Z"
    transform="translate(1.10000, 1.10000) scale(1.11)"
  />
);

const InfoFilled = ({
  color,
  size,
  title,
  titleId,
  aphroditeStyles = [],
}: Props) => (
  <Icon
    color={color}
    size={size}
    aphroditeStyles={aphroditeStyles}
    title={title}
    titleId={titleId}
  >
    {path}
  </Icon>
);

InfoFilled.displayName = 'InfoFilled';

export default InfoFilled;
